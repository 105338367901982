import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form, Grid } from 'semantic-ui-react';
import Loader from '../../../../components/modal/loaderModal';
import InputWithLabel from '../../../../components/input/inputWithLabel';
import ButtonClose from '../../../../components/button/buttonCloseModal';
import styles from '../../Styles.js';
const ModalArea = props => {
  return (
    <Modal
      dimmer="blurring"
      open={props.open}
      onClose={props.handleClose}
      style={styles.modalArea}
      size="small"
    >
      <Modal.Header>
        {props.header} <ButtonClose onClose={props.handleClose} />
      </Modal.Header>
      <Modal.Content>
        <Loader loading={props.loading} />
        <Grid>
          <Grid.Column>
            <Form>
              <Form.Group widths="equal">
                <InputWithLabel
                  label="Area Name"
                  inline={false}
                  type="text"
                  name="Name"
                  placeholder="Area Name"
                  value={props.contentField.Name}
                  handleChange={props.handleChange}
                  required
                  fluid={true}
                  validate={props.errorInformation}
                />
              </Form.Group>
            </Form>
            <Form>
              <Form.Group widths="equal">
                <InputWithLabel
                  label="City"
                  inline={false}
                  type="select"
                  name="CityId"
                  placeholder="CityId"
                  options={props.listCity}
                  value={props.contentField.CityId}
                  handleChange={props.handleChange}
                  required
                  fluid={true}
                  validate={props.errorInformation}
                />
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={props.saveArea}>
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

ModalArea.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  listCity: PropTypes.array,
  handleChange: PropTypes.func,
  contentField: PropTypes.shape({
    Name: PropTypes.string,
    Id: PropTypes.string,
    CityId: PropTypes.string,
  }),
  imagePreview: PropTypes.string,
  saveArea: PropTypes.func,
  errorInformation: PropTypes.shape({
    NameError: PropTypes.string,
    CityIdError: PropTypes.string,
  }),
  loading: PropTypes.bool,
};
export default ModalArea;
