import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Button, Form } from 'semantic-ui-react';
import ButtonClose from './../button/buttonCloseModal';
import {
  getCompanyCommissionById,
  postCompanyCommission,
  putCompanyCommission,
} from './../../actions/companyAction';
import { getCustomerProfileListAction } from '../../pages/shared/actions/customerProfileAction';
import { getCurrenciesAction } from './../../actions/currenciesAction';
import Input from './../input/inputWithLabel';
import Options from './../../scripts/convertOption';
import InputDate from './../input/inputDate';
import ModalALert from './modalAlert';
import Loader from './../loader/loaderInModal';
import InputCurrencyFormat from '../input/inputCurrencyFormat';
// import { getTotalDays } from './../../scripts/moment';

const DEFAULT_COMPANY_COMMISSION = {
  Id: '',
  MinimumPax: '',
  CommBase: '',
  CommValue: '',
  StartDate: new Date(),
  EndDate: new Date(),
  CurrencyId: '',
  CompanyCode: '',
};

const OPTION_COMMISSION_BASE = [
  {
    key: 0,
    value: 0,
    text: 'Percentage',
  },
  {
    key: 1,
    value: 1,
    text: 'Commission Value',
  },
];

// const DEFAULT_ERROR_FIELD = {
//   error: false,
//   message: '',
// };

// const DEFAULT_ERROR_COMPANY_COMMISSION = {
//   MinimumPax: DEFAULT_ERROR_FIELD,
//   CommBase: DEFAULT_ERROR_FIELD,
//   CommValue: DEFAULT_ERROR_FIELD,
//   StartDate: DEFAULT_ERROR_FIELD,
//   EndDate: DEFAULT_ERROR_FIELD,
//   CurrencyId: DEFAULT_ERROR_FIELD,
// };

class modalCommission extends React.Component {
  state = {
    companyCommission: DEFAULT_COMPANY_COMMISSION,
    openModalAlert: false,
    headerModalAlert: '',
    messageModalAlert: '',
    errors: {
      MinimumPax: {
        error: false,
        message: '',
      },
      CommBase: {
        error: false,
        message: '',
      },
      CommValue: {
        error: false,
        message: '',
      },
      StartDate: {
        error: false,
        message: '',
      },
      EndDate: {
        error: false,
        message: '',
      },
      CurrencyId: {
        error: false,
        message: '',
      },
    },
  };
  componentDidMount() {
    this.props.getCustomerProfileListAction('Customer');
    this.props.getCurrenciesAction();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open && this.props.open === true) {
      if (this.props.id !== null) {
        this.props
          .getCompanyCommissionById(this.props.id)
          .then(() => {
            const { companyCommission } = this.props;
            this.setState({
              ...this.state,
              companyCommission,
            });
          })
          .catch(() =>
            this.setState({
              openModalAlert: true,
              headerModalAlert: 'Failed',
              messageModalAlert: 'Failed Get Company Commission',
            })
          );
      } else {
        this.setState({
          ...this.state,
          companyCommission: {
            ...DEFAULT_COMPANY_COMMISSION,
            CompanyCode: this.props.companyCode,
          },
        });
      }
    }
  }

  handleOnchange = (e, { name, objectname, value }) => {
    let companyCommission = { ...this.state[objectname] };
    let errors = { ...this.state.errors };
    companyCommission[name] = value;
    errors[name].error = value === '';
    this.setState({
      ...this.state,
      companyCommission,
      errors,
    });
  };

  handleChangeCommissionValue = (value, name) => {
    let companyCommission = { ...this.state.companyCommission };
    let errors = { ...this.state.errors };
    // companyCommission[name] = value;
    if (companyCommission.CommBase === 1) {
      companyCommission[name] = value < 0 ? 0 : value;
    } else {
      companyCommission[name] = value < 0 ? 0 : value > 100 ? 100 : value;
    }
    errors[name].error = value === '';
    this.setState({
      ...this.state,
      companyCommission,
      errors,
    });
  };

  handleDate = (date, name) => {
    let companyCommission = { ...this.state.companyCommission };
    companyCommission[name] = date;
    this.setState({
      ...this.state,
      companyCommission,
    });
  };

  validation = companyCommission => {
    let isError = false;
    let errors = { ...this.state.errors };
    if (companyCommission.CurrencyId === '') {
      errors.CurrencyId.error = true;
      errors.CurrencyId.message = 'Please Choose Currency';
      isError = true;
    } else {
      errors.CurrencyId.error = false;
    }

    if (companyCommission.MinimumPax === '') {
      errors.MinimumPax.error = true;
      errors.MinimumPax.message = 'Please Fill Minimum Pax';
      isError = true;
    } else {
      errors.MinimumPax.error = false;
    }

    if (companyCommission.CommBase === '') {
      errors.CommBase.error = true;
      errors.CommBase.message = 'Please Choose Commission Base';
      isError = true;
    } else {
      errors.CommBase.error = false;
    }
    if (companyCommission.CommValue === '') {
      errors.CommValue.error = true;
      errors.CommValue.message = 'Please Fill Commission Value';
      isError = true;
    } else {
      errors.CommValue.error = false;
    }
    // if (
    //   getTotalDays(
    //     companyCommission.StartDate,
    //     companyCommission.EndDate,
    //     false
    //   ) > 0
    // ) {
    //   errors.EndDate.error = true;
    //   errors.EndDate.message = 'Please Change End Date';
    //   isError = true;
    // }
    return { isError, errors };
  };

  saveCommission = async () => {
    const { type, id } = this.props;
    const { companyCommission } = this.state;
    const error = this.validation(companyCommission);
    if (!error.isError) {
      if (type === 'add') {
        this.props
          .postCompanyCommission(companyCommission)
          .then(async () => {
            await this.openAlert('Success', 'Success Add Commission');
            this.props.resetListCommissions();
          })
          .catch(() => this.openAlert('Failed', 'Failed Add Commission'));
      } else {
        this.props
          .putCompanyCommission(companyCommission, id)
          .then(async () => {
            await this.openAlert('Success', 'Success Edit Commission');
            this.props.resetListCommissions();
          })
          .catch(() => this.openAlert('Failed', 'Failed Edit Commission'));
      }
    } else {
      this.setState({
        ...this.state,
        errors: error.errors,
      });
    }
  };
  openAlert = (header, message) =>
    this.setState({
      openModalAlert: true,
      headerModalAlert: header,
      messageModalAlert: message,
    });

  closeModalAlert = () =>
    this.setState({ ...this.state, openModalAlert: false });

  render() {
    const {
      open,
      type,
      handleClose,
      loadingGetCompanyCommissionById,
      listCompany,
      loadingGetCompany,
      loadingGetCurrency,
      listCurrencies,
      loadingPostCompanyCommission,
      loadingPutCompanyCommission,
    } = this.props;
    const {
      companyCommission,
      openModalAlert,
      headerModalAlert,
      messageModalAlert,
      errors,
    } = this.state;
    const isAddCommission = type === 'add';
    return (
      <div>
        <Modal
          dimmer="blurring"
          inverted
          open={open}
          closeOnDimmerClick={true}
          style={{ width: '30em' }}
        >
          <Modal.Header>
            {isAddCommission ? 'Add Commission' : 'Edit Commission'}
            <ButtonClose onClose={handleClose} />
          </Modal.Header>
          <Modal.Content>
            <Loader
              loading={
                loadingPostCompanyCommission ||
                loadingPutCompanyCommission ||
                loadingGetCompanyCommissionById
              }
            />
            <Form>
              <Form.Group widths="equal">
                <Form.Field>
                  <Input
                    inline={false}
                    label="Company"
                    type="select"
                    fluid={true}
                    required
                    name="CompanyCode"
                    value={companyCommission.CompanyCode}
                    placeholder="Company"
                    handleChange={this.handleOnchange}
                    objectname="companyCommission"
                    options={listCompany}
                    // validate={errors}
                    loading={loadingGetCompany}
                    disabled={true}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <Input
                    inline={false}
                    label="Currency"
                    type="select"
                    fluid={true}
                    required
                    name="CurrencyId"
                    value={companyCommission.CurrencyId}
                    placeholder="Currency"
                    handleChange={this.handleOnchange}
                    objectname="companyCommission"
                    validate={errors}
                    loading={loadingGetCurrency}
                    options={listCurrencies}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    inline={false}
                    label="Minimum Pax"
                    type="number"
                    fluid={true}
                    required
                    name="MinimumPax"
                    value={companyCommission.MinimumPax}
                    placeholder="Minimum Pax"
                    handleChange={this.handleOnchange}
                    objectname="companyCommission"
                    validate={errors}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <InputDate
                    label="Start Date"
                    name="StartDate"
                    value={companyCommission.StartDate}
                    setDate={this.handleDate}
                    labelStyle={{
                      display: 'block',
                      fontWeight: 'bold',
                      marginBottom: '4px',
                    }}
                    //   iconStyle={styles.primaryColor}
                    readonly={false}
                  />
                </Form.Field>
                <Form.Field>
                  <InputDate
                    label="End Date"
                    name="EndDate"
                    value={companyCommission.EndDate}
                    setDate={this.handleDate}
                    labelStyle={{
                      display: 'block',
                      fontWeight: 'bold',
                      marginBottom: '4px',
                    }}
                    //   iconStyle={styles.primaryColor}
                    readonly={false}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field>
                  <Input
                    inline={false}
                    label="Commission Base"
                    type="select"
                    fluid={true}
                    required
                    name="CommBase"
                    value={companyCommission.CommBase}
                    placeholder="Commission Base"
                    handleChange={this.handleOnchange}
                    objectname="companyCommission"
                    options={OPTION_COMMISSION_BASE}
                    validate={errors}
                  />
                </Form.Field>
                <Form.Field required={true} error={errors.CommValue.error}>
                  <>
                    <label>
                      <strong>Commission Value</strong>
                    </label>
                    <InputCurrencyFormat
                      name="CommValue"
                      value={companyCommission.CommValue}
                      handelChangePrice={this.handleChangeCommissionValue}
                      errors={errors.CommValue.message}
                    />
                  </>

                  {/* <Input
                      inline={false}
                      label="Commission Value"
                      type="number"
                      fluid={true}
                      required
                      name="CommValue"
                      value={companyCommission.CommValue}
                      placeholder="Commission Value"
                      handleChange={this.handleOnchange}
                      objectname="companyCommission"
                      validate={errors}
                    /> */}
                </Form.Field>
              </Form.Group>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={this.saveCommission}>
              Save
            </Button>
          </Modal.Actions>
        </Modal>

        {/* openModalAlert,
        headerModalAlert,
        messageModalAlert, */}
        <ModalALert
          openModal={openModalAlert}
          handleClose={this.closeModalAlert}
          header={headerModalAlert}
          content={messageModalAlert}
          //   nameButton: PropTypes.string,
        />
      </div>
    );
  }
}

modalCommission.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.string,
  handleClose: PropTypes.func,
  id: PropTypes.number,
  getCompanyCommissionById: PropTypes.func,
  companyCommission: PropTypes.object,
  loadingGetCompanyCommissionById: PropTypes.bool,
  getCustomerProfileListAction: PropTypes.func,
  listCompany: PropTypes.array,
  loadingGetCompany: PropTypes.bool,
  getCurrenciesAction: PropTypes.func,
  loadingGetCurrency: PropTypes.bool,
  listCurrencies: PropTypes.array,
  postCompanyCommission: PropTypes.func,
  putCompanyCommission: PropTypes.func,
  resetListCommissions: PropTypes.func,
  loadingPostCompanyCommission: PropTypes.bool,
  loadingPutCompanyCommission: PropTypes.bool,
  companyCode: PropTypes.string,
};
// export default modalCommission;
function mapStateToProps(state) {
  const listCompany = state.CustomerProfileStore.getCustomerProfileList.map(e =>
    Options(e.Code, e.Code, e.Name)
  );

  const listCurrencies = state.currenciesStore.currencies.map(e =>
    Options(e.Id, e.Id, e.Id)
  );
  return {
    loadingGetCompanyCommissionById:
      state.CompanyStore.loadingGetCompanyCommissionById,
    companyCommission: state.CompanyStore.companyCommission,
    listCompany,
    loadingGetCompany: state.CustomerProfileStore.loadingGetCustomerProfile,
    loadingGetCurrency: state.currenciesStore.loading,
    listCurrencies,
    loadingPostCompanyCommission:
      state.CompanyStore.loadingPostCompanyCommission,
    loadingPutCompanyCommission: state.CompanyStore.loadingPutCompanyCommission,
  };
}

export default connect(mapStateToProps, {
  getCompanyCommissionById,
  getCustomerProfileListAction,
  getCurrenciesAction,
  postCompanyCommission,
  putCompanyCommission,
})(modalCommission);
