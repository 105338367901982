import React from 'react';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';
// import { connect } from 'react-redux';
import { Switch, Redirect } from 'react-router-dom';
import { Segment, Sidebar } from 'semantic-ui-react';
import { WIDTH_PUSHER, MIN_HEIGTH } from './assets/styles/size';
// import { resetUserAccessAction } from './actions/userAccessAction';
import Home from './pages/home/Home';
import Logout from './pages/login/components/Logout';
// import Accomodation from './pages/accomodationPage';
import Accomodation from './pages/accommodation/Accommodation';
// import AccomodationProfile from './pages/accomodationProfilePage';
import AccomodationProfile from './pages/accommodation/accommodationProfile/AccommodationProfile';
// import AccomodationItem from './pages/accomodationItemPage';
import AccomodationItem from './pages/accommodation/accommodationProfile/accommodationItem/AccommodationItem';
import Transportation from './pages/transportation/transportationList/Transportation';
import Excursion from './pages/excurtion/excursionList/Excursion'; //'./pages/excursionPage';
import ExcursionProfile from './pages/excurtion/excursionProfile/ExcursionProfile';
import CustomerProfile from './pages/customer/customerList/CustomerList';
import SidebarMenu from './components/sidebar/sidebar';
// import UserAccess from './pages/userAccess';
import UserAccess from './pages/userList/UserList';
// import ExchangeRate from './pages/exchangeRatePage';
import ExchangeRate from './pages/exchangeRate/ExchangeRate';
// import SystemMargin from './pages/systemMarginPage';
import SystemMargin from './pages/systemMargin/SystemMargin';
// import SystemMargin from './pages/systemMargin/SystemMargin';
import CompanyCommission from './pages/companyCommission/CompanyCommission';
// import PartnerProfile from './pages/partnerProfilePage';
import PartnerProfile from './pages/partnerProfile/PartnerProfile';
//import TransactionDetailsPage from './pages/transactionDetailsPage';
import TransportationProfile from './pages/transportation/transportationProfile/TransportationProfile';
import TransportationUnit from './pages/transportation/transportationProfile/transportationUnit/TransportationUnit';
import RestaurantList from './pages/restaurant/restaurantList/RestaurantList';
import RestaurantProfile from './pages/restaurant/restaurantProfile/RestaurantProfile';
import RestaurantItem from './pages/restaurant/restaurantItem/RestaurantItem';
// import AreaList from './pages/areaPage';
import AreaList from './pages/area/Area';
import CityList from './pages/city/City';
import CountryList from './pages/country/Country';
import CustomerProfileDetailPage from './pages/customer/customerProfile/CustomerProfile';
// import PartnerProfileDetailPage from './pages/partnerProfileDetailPage';
import PartnerProfileDetailPage from './pages/partnerProfile/partnerProfileDetail/PartnerProfileDetail';
import CityGroupList from './pages/cityGroup/CityGroup';
import BalanceDetail from './pages/balanceDetail/BalanceDetail';
// import PackageDetails from './pages/packageDetailsPage';
// new series package
import PackageDetails from './pages/packageMangement/FixedPackageDetailPage';
// end new series package
// import PackageDetailsOld from './pages/packageMangement/FixedPackageDetailPageOld';
import PackageReadyDetails from './pages/readyPackage/readyPackageDetail/ReadyPackageDetail';
import UploadMasterData from './pages/uploadMasterData/UploadDataMasterPage';
// import ReadyPackageList from './pages/readyPackage/readyPackageList/ReadyPackageList';
// import ReadyPackageDetail from './pages/readyPackage/readyPackageDetail/TestReadyPackageDetail';
// import UserRolesListPage from './pages/userRolesListPage';
// import UserRolesListPage from './pages/userRolesListPage';
import MasterDataReference from './pages/uploadMasterDataReference/UploadMasterDataReference';
import PackageList from './pages/packageMangement/PackageListPage';
import TourTransaction from './pages/tourTransaction/transactionList/TourTransaction';
import CreateTransaction from './pages/tourTransaction/transactionDetail/CreateTourTransaction';
import ComingSoon from './pages/comingSoon/ComingSoon';
import RoomConfirmation from './pages/roomConfirmation/RoomConfirmation';
// import AccommodationAvailbility from './pages/accommodationAvailabilityPage';
import AccommodationAvailbility from './pages/accommodation/accommodationProfile/accommodationAvailability/AccommodationAvailability';
import TourOperatorDetail from './pages/tourOperatorDetail/TourOperatorDetail';
import TourGuide from './pages/tourGuide/tourGuideList/TourGuideList';
import TourGuideDetail from './pages/tourGuide/tourGuideDetail/TourGuideDetail';
import PlaceList from './pages/place/Place';
import RegionList from './pages/region/Region';
import PaymentMethod from './pages/paymentMethod/PaymentMethod';
import PaymentScheme from './pages/paymentScheme/PaymentScheme';
import PaymentSchemeDetail from './pages/paymentScheme/paymentSchemeDetail/PaymentSchemeDetail';
import NotAllowedCountryForCountry from './pages/notAllowedForCompany/NotAllowedForCompany';
import UploadMasterImage from './pages/uploadMasterImage/UploadMasterImage';
// import DataTransferLogs from './pages/dataTransferLog/DataTransferLogs';
import Employee from './pages/customer/employee/Employee';
import DataTransferLogs from './pages/dataTransferLog/DataTransferLogs';
import UserProfile from './pages/userProfile/UserProfile';
import CompanyProfileList from './pages/companyProfile/companyProfileList/CompanyProfileList';
import CompanyProfileDetail from './pages/companyProfile/companyProfileDetail/CompanyProfileDetail';
import PlaceDetail from './pages/place/placeDetail/PlaceDetail';
import AccountStatement from './pages/accountStatement/AccountStatmentPage';
import AdditionalServices from './pages/additionalServices/AdditionalServices';
import AdditionalServiceDetail from './pages/additionalServices/components/AdditionalServiceDetail';
import TransportationAdditionalCost from './pages/transportation/additionalCost/AdditionalCost';
import TransportationAdditionalCostDetail from './pages/transportation/additionalCost/components/AdditionalCostDetail';
import RequestRoomHistoryDetail from './pages/requestRoomHistoryDetail/RequestRoomHistoryDetail';
// '/New-Customer-Sales'
import PackageDetailForOperation from './pages/packageMangement/PackageDetailForOperation';
import ResourceBookingDetail from './pages/resourceBooking/ResourceBookingDetail';
// import ResourceBooking from './pages/resourceBooking/resourceBookingList/ResourceBookingList';
import ResourceBooking from './pages/resourceBooking/ResourceBooking';
import ResourceDetail from './pages/resourceBooking/ResourceDetail';
import PaymentRequestDetail from './pages/paymentRequestDetail/PaymentRequestDetail';
import PaymentRequestList from './pages/paymentRequestList/PaymentRequestList';
import TransactionDetailForFinance from './pages/tourTransaction/transactionDetail/TransactionDetailForFinance';
import AccountStatementSummary from './pages/accountStatement/AccountStatementSummaryPage';
import SimplePackageInformation from './pages/packageMangement/SimplePackageInformation';

import PropTypes from 'prop-types';
const Styles = {
  Pushable: {
    minHeight: MIN_HEIGTH,
  },
  Pusher: {
    width: `${WIDTH_PUSHER}px`,
    minHeight: MIN_HEIGTH,
    marginLeft: '18em',
  },
};
const HOMEWITHROUTER = withRouter(Home);
const SYSTEMMARGINWITHROUTER = withRouter(SystemMargin);
const COMPANYCOMMISSIONSWITHROUTER = withRouter(CompanyCommission);
const USERACCESSWITHROUTER = withRouter(UserAccess);
const LOGOUTWITHROUTER = withRouter(Logout);
const ACCOMODATIONWITHROUTER = withRouter(Accomodation);
const ACCOMODATIONPROFILEWITHROUTER = withRouter(AccomodationProfile);
const ACCOMODATIONITEMWITHROUTER = withRouter(AccomodationItem);
const TRANSPORTATIONWITHROUTER = withRouter(Transportation);
const EXCURSIONWITHROUTER = withRouter(Excursion);
const EXCURSIONPROFILEWITHROUTER = withRouter(ExcursionProfile);
const CUSTOMERPROFILEWITHROUTER = withRouter(CustomerProfile);
const EXCHANGERATEWITHROUTER = withRouter(ExchangeRate);
const PARTNERPROFILEWITHROUTER = withRouter(PartnerProfile);
//const TRANSACTIONDETAILSWITHROUTER = withRouter(TransactionDetailsPage);
const TRANSPORTATIONPROFILEWITHROUTER = withRouter(TransportationProfile);
const TRANSPORTATIONUNITWITHROUTER = withRouter(TransportationUnit);
const RESTAURANTWITHROUTER = withRouter(RestaurantList);
const RESTAURANTPROFILEWITHROUTER = withRouter(RestaurantProfile);
const RESTAURANTITEMWITHROUTER = withRouter(RestaurantItem);
const AREAWITHROUTER = withRouter(AreaList);
const PLACEWITHROUTER = withRouter(PlaceList);
const CITYWITHROUTER = withRouter(CityList);
const REGIONWITHROUTER = withRouter(RegionList);
const COUNTRYWITHROUTER = withRouter(CountryList);
const CUSTOMERPROFILEDETAILWITHROUTER = withRouter(CustomerProfileDetailPage);
const PARTNERPROFILEDETAILWITHROUTER = withRouter(PartnerProfileDetailPage);
const CITYGROUPWITHROUTER = withRouter(CityGroupList);
const BALANCEDETAILWITHROUTER = withRouter(BalanceDetail);
const PACKAGEDETAILS = withRouter(PackageDetails);
// const PACKAGEDETAILSOLD = withRouter(PackageDetailsOld);
const PACKAGEREADYDETAILS = withRouter(PackageReadyDetails);
const UPLOADMASTERDATAWITHROUTER = withRouter(UploadMasterData);
const UPLOADMASTERIMAGEWITHROUTER = withRouter(UploadMasterImage);
// const READYPACKAGEWITHROUTER = withRouter(ReadyPackageList);
// const READYPACKAGEDETAILWITHROUTER = withRouter(ReadyPackageDetail);
// const USERROLESLISTPAGE = withRouter(UserRolesListPage);
const MASTERDATAREFERENCEWITHROUTER = withRouter(MasterDataReference);
const PAYMENTMETHODWITHROUTER = withRouter(PaymentMethod);
const PACKAGELISTWITHROUTER = withRouter(PackageList);
const TOURTRANSACTIONWITHROUTER = withRouter(TourTransaction);
const CREATETRANSACTIONWITHROUTER = withRouter(CreateTransaction);
const COMINGSONWITHROUTER = withRouter(ComingSoon);
const NOTALLOWEDCOUNTRYFORCOMPANYWITHROUTER = withRouter(
  NotAllowedCountryForCountry
);
const PAYMENTSCHEMEWITHROUTER = withRouter(PaymentScheme);
const PAYMENTSCHEMEDETAILWITHROUTER = withRouter(PaymentSchemeDetail);
const ROOMCONFIRMATIONROUTER = withRouter(RoomConfirmation);
const ACCOMMODATIONAVAIBILITYROUTER = withRouter(AccommodationAvailbility);
const TOUROPERATORDETAILWITHROUTER = withRouter(TourOperatorDetail);
const TOURGUIDEWITHROUTER = withRouter(TourGuide);
const TOURGUIDEDETAILWITHROUTER = withRouter(TourGuideDetail);
// const DATATRANSFERLOGSROUTER = withRouter(DataTransferLogs);
const EMPLOYEEWITHROUTER = withRouter(Employee);
const DATATRANSFERLOGSROUTER = withRouter(DataTransferLogs);
const USERPROFILEROUTER = withRouter(UserProfile);
const COMPANYPROFILELISTWITHROUTER = withRouter(CompanyProfileList);
const COMPANYPROFILEDETAILWITHROUTER = withRouter(CompanyProfileDetail);
const PLACEDETAILWITHROUTER = withRouter(PlaceDetail);
const ACCOUNTSTATEMENTWITHROUTER = withRouter(AccountStatement);
const ADDITIONALSERVICESWITHROUTER = withRouter(AdditionalServices);
const ADDITIONALSERVICEDETAILWITHROUTER = withRouter(AdditionalServiceDetail);
const TRANSPORTATIONADDITIONALCOSTWITHROUTER = withRouter(
  TransportationAdditionalCost
);
const TRANSPORTATIONADDITIONALCOSTDETAILWITHROUTER = withRouter(
  TransportationAdditionalCostDetail
);
const REQUESTROOMHISTORYDETAILWITHROUTER = withRouter(RequestRoomHistoryDetail);
const PACKAGEDETAILFOROPERATIONWITHROUTER = withRouter(
  PackageDetailForOperation
);
const RESOURCEBOOKINGDETAILWITHROUTER = withRouter(ResourceBookingDetail);
const RESOURCEBOOKINGWITHROUTER = withRouter(ResourceBooking);
const RESOURCEDETAILWITHROUTER = withRouter(ResourceDetail);
const PAYMENTREQUESTDETAILWITHROUTER = withRouter(PaymentRequestDetail);
const PAYMENTREQUESTLISTWITHROUTER = withRouter(PaymentRequestList);
const TRANSACTIONDETAILFINANCEWITHROUTER = withRouter(
  TransactionDetailForFinance
);
const ACCOUNTSTATEMENTSUMMARYWITHROUTER = withRouter(AccountStatementSummary);
const SIMPLEPACKAGEINFORMATIONWITHROUTER = withRouter(SimplePackageInformation);

class App extends React.Component {
  state = {
    isSales: null,
    isAdminCustomer: false,
    userRoles: [],
    roleOperation: null,
  };
  static propTypes = {
    location: PropTypes.object,
    // getUserRoleByBearerStatus: PropTypes.string,
    // getUserRoleByBearer: PropTypes.object,
    // resetUserAccessAction: PropTypes.func,
  };
  checkToken = () => {
    const getToken = localStorage.getItem('tokenadmin');
    const expired = new Date(
      localStorage.getItem('expiredtokenadmin')
    ).getTime();
    const today = new Date().getTime();
    if (!getToken || (getToken && today > expired)) {
      return true;
    } else {
      return false;
    }
  };

  componentDidMount = () => {
    this.checkToken();
  };
  // componentDidUpdate() {
  //   if (this.props.getUserRoleByBearerStatus === 'success') {
  //     this.checkUser(this.props.getUserRoleByBearer.Roles);
  //     this.props.resetUserAccessAction();
  //   }
  // }

  // checkUser = code => {
  //   if (code.length !== 0) {
  //     code.some(e => e === 'Package Sales')
  //       ? this.setState({ ...this.state, isSales: true })
  //       : code.some(e => e === 'Administrator') &&
  //         this.setState({ ...this.state, isSales: false });
  //   }
  // };
  render() {
    const { isSales, roleOperation } = this.state;
    const getToken = localStorage.getItem('tokenadmin');
    let isGotoRoomConfirm =
      this.props.location.pathname.substring(0, 18) === '/Room-Confirmation';
    let homePage = this.props.location.pathname.substring(0, 5) === '/Home';
    if (!isGotoRoomConfirm) {
      if (!getToken) {
        return <Redirect to="/Login" />;
      } else if (this.checkToken()) {
        return <Redirect to="/Login" />;
      } else if (
        !this.checkToken() &&
        this.props.location.pathname === '/Login'
      ) {
        if (isSales) {
          return <Redirect to="/tour-transaction" />;
        } else {
          return <Redirect to="/" />;
        }
      }
    }
    if (!homePage) {
      if (!getToken) {
        return <Redirect to="/Login" />;
      } else if (this.checkToken()) {
        return <Redirect to="/Login" />;
      } else if (
        !this.checkToken() &&
        this.props.location.pathname === '/Login'
      ) {
        if (roleOperation) {
          return <Redirect to="/Home" />;
        } else {
          return <Redirect to="/" />;
        }
      }
    }

    return (
      <Sidebar.Pushable as={Segment} style={Styles.Pushable}>
        {(!isGotoRoomConfirm && <SidebarMenu sales={isSales} />) ||
          (!homePage && <SidebarMenu operation={roleOperation} />)}
        <Sidebar.Pusher
          style={
            !isGotoRoomConfirm ? Styles.Pusher : !homePage ? Styles.Pusher : {}
          }
        >
          <Segment basic>
            <Switch>
              <Route exact path="/" component={HOMEWITHROUTER} />
              <Route exact path="/Home" component={HOMEWITHROUTER} />
              <Route exact path="/Logout" component={LOGOUTWITHROUTER} />
              <Route
                exact
                path="/Accomodation"
                component={ACCOMODATIONWITHROUTER}
              />
              <Route
                exact
                path="/Accomodation-Profile"
                component={ACCOMODATIONPROFILEWITHROUTER}
              />
              <Route
                exact
                path="/Accomodation/:Type/:ProfileId"
                component={ACCOMODATIONPROFILEWITHROUTER}
              />
              <Route
                exact
                path="/Accomodation-Item"
                component={ACCOMODATIONITEMWITHROUTER}
              />
              <Route
                exact
                path="/Accomodation-Item/:ProfileId"
                component={ACCOMODATIONITEMWITHROUTER}
              />
              <Route
                exact
                path="/Accomodation-Item/:Type/:ProfileId/:ServiceTypeId"
                component={ACCOMODATIONITEMWITHROUTER}
              />
              <Route
                exact
                path="/Transportation"
                component={TRANSPORTATIONWITHROUTER}
              />
              <Route exact path="/Excursion" component={EXCURSIONWITHROUTER} />
              <Route
                exact
                path="/Excursion-Profile/:Type/:Id"
                component={EXCURSIONPROFILEWITHROUTER}
              />
              <Route
                exact
                path="/Excursion-Profile"
                component={EXCURSIONPROFILEWITHROUTER}
              />
              <Route
                exact
                path="/Customer-Profile"
                component={CUSTOMERPROFILEWITHROUTER}
              />
              <Route exact path="/User-List" component={USERACCESSWITHROUTER} />
              <Route
                exact
                path="/Exchange-Rate"
                component={EXCHANGERATEWITHROUTER}
              />
              <Route
                exact
                path="/System-Margin/:Type/:ProfileId"
                component={SYSTEMMARGINWITHROUTER}
              />
              <Route
                exact
                path="/System-Margin"
                component={SYSTEMMARGINWITHROUTER}
              />
              <Route
                exact
                path="/Company-Commissions/:Id"
                component={COMPANYCOMMISSIONSWITHROUTER}
              />
              <Route
                exact
                path="/Partner-Profile"
                component={PARTNERPROFILEWITHROUTER}
              />
              <Route
                exact
                path="/Transaction-Details/:Id"
                component={COMINGSONWITHROUTER}
              />
              <Route
                exact
                path="/Transportation-Profile/:Type/:ProfileId"
                component={TRANSPORTATIONPROFILEWITHROUTER}
              />
              <Route
                exact
                path="/Transportation-Profile"
                component={TRANSPORTATIONPROFILEWITHROUTER}
              />
              <Route
                exact
                path="/Transportation-Unit/:Type/by-ProfileId=:ProfileId/by-Country=:CountryId/by-UnitId=:UnitID"
                component={TRANSPORTATIONUNITWITHROUTER}
              />
              <Route
                exact
                path="/Transportation-Unit/by-ProfileId=:ProfileId/by-Country=:CountryId"
                component={TRANSPORTATIONUNITWITHROUTER}
              />
              <Route
                exact
                path="/Transportation-Unit"
                component={TRANSPORTATIONUNITWITHROUTER}
              />
              <Route
                exact
                path="/Restaurant"
                component={RESTAURANTWITHROUTER}
              />
              <Route
                exact
                path="/Restaurant-Profile"
                component={RESTAURANTPROFILEWITHROUTER}
              />
              <Route
                exact
                path="/Restaurant-Profile/:Type/:ProfileId"
                component={RESTAURANTPROFILEWITHROUTER}
              />
              <Route
                exact
                path="/Restaurant-Item"
                component={RESTAURANTITEMWITHROUTER}
              />
              <Route
                exact
                path="/Restaurant-Item/:ProfileId"
                component={RESTAURANTITEMWITHROUTER}
              />
              <Route
                exact
                path="/Restaurant-Item/:Type/:ProfileId/:ItemId"
                component={RESTAURANTITEMWITHROUTER}
              />
              <Route exact path="/Area" component={AREAWITHROUTER} />
              <Route exact path="/City" component={CITYWITHROUTER} />
              <Route exact path="/Region" component={REGIONWITHROUTER} />
              <Route exact path="/Place" component={PLACEWITHROUTER} />
              <Route exact path="/Country" component={COUNTRYWITHROUTER} />
              <Route
                exact
                path="/Customer-Profile-Detail/:Type"
                component={CUSTOMERPROFILEDETAILWITHROUTER}
              />
              <Route
                exact
                path="/Customer-Profile-Detail/:Type/:ProfileId"
                component={CUSTOMERPROFILEDETAILWITHROUTER}
              />
              <Route
                exact
                path="/Partner-Profile-Detail/:Type"
                component={PARTNERPROFILEDETAILWITHROUTER}
              />
              <Route
                exact
                path="/Partner-Profile-Detail/:Type/:ProfileId"
                component={PARTNERPROFILEDETAILWITHROUTER}
              />
              {/* <Route
                exact
                path="/Partner-Profile-Detail/:ProfileId"
                component={PARTNERPROFILEDETAILWITHROUTER}
              /> */}
              <Route exact path="/City-Group" component={CITYGROUPWITHROUTER} />
              <Route
                exact
                path="/Balance-Detail"
                component={BALANCEDETAILWITHROUTER}
              />
              <Route
                exact
                path="/Balance-Detail/:ProfileId"
                component={BALANCEDETAILWITHROUTER}
              />
              {/* <Route
                exact
                path="/Package-Details/Fixed/:packageId"
                component={PACKAGEDETAILSOLD}
              /> */}
              {/* new package management series package */}
              <Route
                exact
                path="/Package-Details/Fixed/:TypeTransaction/:Id"
                component={PACKAGEDETAILS}
              />
              {/* new package management series package */}
              {/* PACKAGEREADYDETAILS */}
              {/* <Route
                exact
                path="/Package-Details/Ready/:TypeTransaction/:Id"
                component={PACKAGEREADYDETAILS}
              /> */}
              <Route
                exact
                path="/Package-Details/:TypePackage/:TypeTransaction/:Id"
                component={PACKAGEREADYDETAILS}
              />
              {/* <Route
                exact
                path="/Package-Details/:/:TypeTransaction/:Id"
                component={PACKAGEDETAILS}
              /> */}
              <Route
                exact
                path="/Upload-Master-Data/:Type"
                component={UPLOADMASTERDATAWITHROUTER}
              />
              <Route
                exact
                path="/Upload-Master-Image"
                component={UPLOADMASTERIMAGEWITHROUTER}
              />
              {/* <Route
                exact
                path="/Ready-Package"
                component={READYPACKAGEWITHROUTER}
              /> */}
              {/* <Route
                exact
                path="/Ready-Package-Detail"
                component={READYPACKAGEDETAILWITHROUTER}
              />
              <Route
                exact
                path="/Ready-Package-Detail/:ProfileId"
                component={READYPACKAGEDETAILWITHROUTER}
              /> */}
              {/* <Route exact path="/User-Roles" component={USERROLESLISTPAGE} /> */}
              <Route
                exact
                path="/Master-Data-Reference"
                component={MASTERDATAREFERENCEWITHROUTER}
              />
              <Route
                exact
                path="/Payment-Method"
                component={PAYMENTMETHODWITHROUTER}
              />
              <Route
                exact
                path="/PackageList/:Type"
                component={PACKAGELISTWITHROUTER}
              />
              <Route
                exact
                path="/tour-transaction"
                component={TOURTRANSACTIONWITHROUTER}
              />
              <Route
                exact
                path="/tour-transaction/:TypeTransaction/:PackageType/:Id"
                component={CREATETRANSACTIONWITHROUTER}
              />
              <Route exact path="/ComingSoon" component={COMINGSONWITHROUTER} />
              <Route
                exact
                path="/Allowed-Country-for-Companies"
                component={NOTALLOWEDCOUNTRYFORCOMPANYWITHROUTER}
              />
              <Route
                exact
                path="/Payment-Terms-Scheme"
                component={PAYMENTSCHEMEWITHROUTER}
              />
              <Route
                exact
                path="/Payment-Terms-Scheme/:SchemeId"
                component={PAYMENTSCHEMEDETAILWITHROUTER}
              />
              <Route
                exact
                path="/Room-Confirmation/:transactionId/:token"
                component={ROOMCONFIRMATIONROUTER}
              />
              <Route
                exact
                path="/Room-Confirmation//:transactionId/:token"
                component={ROOMCONFIRMATIONROUTER}
              />
              <Route
                exact
                path="/Accommodation-Avaibility/:accommodationId/"
                component={ACCOMMODATIONAVAIBILITYROUTER}
              />
              <Route
                exact
                path="/tour-operator-detail/:Type/:profileId/:id/"
                component={TOUROPERATORDETAILWITHROUTER}
              />
              <Route exact path="/Tour-Guide" component={TOURGUIDEWITHROUTER} />
              <Route
                exact
                path="/Tour-Guide-Detail/:Type/:TourGuideId"
                component={TOURGUIDEDETAILWITHROUTER}
              />
              <Route
                exact
                path="/Data-Transfer-Log"
                component={DATATRANSFERLOGSROUTER}
              />
              <Route
                exact
                path="/Employee/:Type/:CompanyId/:EmployeeId"
                component={EMPLOYEEWITHROUTER}
              />
              <Route
                exact
                path="/company-profile-list"
                component={COMPANYPROFILELISTWITHROUTER}
              />
              <Route
                exact
                path="/company-profile-detail/:Type/:ProfileId"
                component={COMPANYPROFILEDETAILWITHROUTER}
              />
              <Route exact path="/My-Account" component={USERPROFILEROUTER} />
              <Route
                exact
                path="/Place/:id"
                component={PLACEDETAILWITHROUTER}
              />
              <Route
                exact
                path="/account-statement"
                component={ACCOUNTSTATEMENTWITHROUTER}
              />
              <Route
                exact
                path="/additional-services"
                component={ADDITIONALSERVICESWITHROUTER}
              />
              <Route
                exact
                path="/additional-services/detail/:id"
                component={ADDITIONALSERVICEDETAILWITHROUTER}
              />
              <Route
                exact
                path="/transportation-additional-cost"
                component={TRANSPORTATIONADDITIONALCOSTWITHROUTER}
              />
              <Route
                exact
                path="/transportation-additional-cost/detail/:origin?/:destination?/:type?"
                component={TRANSPORTATIONADDITIONALCOSTDETAILWITHROUTER}
              />
              <Route
                exact
                path="/request-room-history/detail/:id"
                component={REQUESTROOMHISTORYDETAILWITHROUTER}
              />
              <Route
                exact
                path="/Simple-Package-Detail/:type?/:id?"
                component={PACKAGEDETAILFOROPERATIONWITHROUTER}
              />
              <Route
                exact
                path="/Resource-Booking-Detail/:id"
                component={RESOURCEBOOKINGDETAILWITHROUTER}
              />
              <Route
                exact
                path="/resource-booking"
                component={RESOURCEBOOKINGWITHROUTER}
              />
              <Route
                exact
                path="/Resource-Detail/:type/:id"
                component={RESOURCEDETAILWITHROUTER}
              />
              <Route
                exact
                path="/Resource-Detail/:type/:id/:resourceId"
                component={RESOURCEDETAILWITHROUTER}
              />
              <Route
                path="/Request-Payment-Detail/:id"
                component={PAYMENTREQUESTDETAILWITHROUTER}
              />
              <Route
                path="/request-payment-list"
                component={PAYMENTREQUESTLISTWITHROUTER}
              />
              <Route
                path="/Simple-Transaction-Detail/:Id" //:type?
                component={TRANSACTIONDETAILFINANCEWITHROUTER}
              />
              <Route
                path="/account-statement-summary"
                component={ACCOUNTSTATEMENTSUMMARYWITHROUTER}
              />
              <Route
                path="/simple-package-information/:PackageType/:Id"
                component={SIMPLEPACKAGEINFORMATIONWITHROUTER}
              />

              <Redirect to="/ComingSoon" />
            </Switch>
          </Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
    // }
  }
}

export default App;
// function mapStateToProps(state) {
//   return {
//     getUserRoleByBearer: state.UserAccessStore.getUserRoleByBearer,
//     getUserRoleByBearerStatus: state.UserAccessStore.getUserRoleByBearerStatus,
//   };
// }
// export default connect(
//   mapStateToProps,
//   {
//     resetUserAccessAction,
//   }
// )(App);
