import { useState, useEffect } from 'react';

const useApiCallSalesReport = (api, from, to) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState('');

  const fetchApi = (from, to) => {
    setIsLoading(true);
    api(from, to)
      .then(result => {
        setIsLoading(false);
        setFetchedData(result.data);
        setErrorMessage('');
        // console.log(optionsData);
      })
      .catch(err => {
        //eslint-disable-next-line
        console.log(err);
        setErrorMessage('Failed Get Report');
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchApi(from, to);
    // eslint-disable-next-line
    }, []);

  const loadData = (from, to) => {
    fetchApi(from, to);
  };

  return [isLoading, fetchedData, loadData, errorMessage];
};

export default useApiCallSalesReport;
