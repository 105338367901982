import { useState, useEffect } from 'react';

const useApiCallPaymentRequest = (api, startDate = null, endDate = null) => {
  const getInitialStartDate = () => {
    return startDate;
  };

  const getInitialEndDate = () => {
    return endDate;
  };

  const [isLoading, setIsLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState(null);
  const [today, setToday] = useState(getInitialStartDate());
  const [nextMonth, setNextMonth] = useState(getInitialEndDate());

  const fetchApi = (id = null) => {
    setIsLoading(true);
    api(id && id)
      .then(result => {
        setIsLoading(false);
        setFetchedData(result.data);
      })
      .catch(() => {
        //eslint-disable-next-line
        setIsLoading(false);
      });
  };

  const handleSubmit = (from, to) => {
    setToday(from);
    setNextMonth(to);
    fetchApi(from, to);
  };

  const loadData = () => {
    fetchApi(today, nextMonth);
  };

  useEffect(() => {
    fetchApi(today, nextMonth);
    //eslint-disable-next-line
  }, []);

  return [isLoading, fetchedData, handleSubmit, loadData, today, nextMonth];
};

export default useApiCallPaymentRequest;
