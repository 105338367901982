import React from 'react';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const ActionButtonList = props => {
  return (
    <Button.Group>
      <Button
        name={props.name1}
        value={props.id}
        positive
        onClick={props.handleClickEdit}
        content={props.content1}
        disabled={props.disablePage}
      />
      <Button.Or />
      <Button
        name={props.name2}
        value={props.id}
        negative
        onClick={props.handleClickDelete}
        content={props.content2}
        disabled={props.disablePage}
      />
    </Button.Group>
  );
};
ActionButtonList.propTypes = {
  id: PropTypes.number,
  handleClickEdit: PropTypes.func,
  handleClickDelete: PropTypes.func,
  name1: PropTypes.string,
  name2: PropTypes.string,
  content1: PropTypes.string,
  content2: PropTypes.string,
  disablePage: PropTypes.bool,
};
export default ActionButtonList;
