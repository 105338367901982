import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Icon, Form, Grid } from 'semantic-ui-react';
import Loader from '../modal/loaderModal';
import InputWithLabel from '../input/inputWithLabel';
import InputBase64 from '../input/inputBase64';

const modalContractingFile = props => {
  return (
    <Modal
      dimmer="blurring"
      closeIcon
      inverted
      open={props.open}
      onClose={props.handleClose}
      style={{ width: '25em' }}
    >
      <Loader loading={props.loading} />
      <Modal.Header>{props.header}</Modal.Header>
      <Modal.Content>
        <Grid padded="vertically">
          <Grid.Column>
            <Form>
              <InputWithLabel
                inline={false}
                label="Start Date"
                type="date"
                required
                name="StartDate"
                value={props.contentField.StartDate}
                placeholder="Name"
                handleChange={props.handleChange}
                // validate={props.errorInformation}
              />
            </Form>
            <Form>
              <InputWithLabel
                inline={false}
                label="End Date"
                type="date"
                required
                name="EndDate"
                value={props.contentField.EndDate}
                placeholder="End Date"
                handleChange={props.handleChange}
                //validate={props.errorInformation}
              />
            </Form>
            <Form>
              <InputWithLabel
                inline={false}
                label="Description"
                type="textArea"
                name="Description"
                //value={props.contentField.Description}
                placeholder="Description"
                handleChange={props.handleChange}
                //validate={props.errorInformation}
              />
            </Form>
            <Form>
              <Form.Field required>
                <label>Upload File</label>
                <InputBase64
                  multiple={true}
                  accept="/pdf, /docx, /doc, /txt"
                  onDone={props.handleChangeFile}
                />
              </Form.Field>
            </Form>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button positive inverted onClick={props.saveContracting}>
          <Icon name="save" /> Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

modalContractingFile.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  handleChange: PropTypes.func,
  contentField: PropTypes.shape({
    StartDate: PropTypes.string,
    EndDate: PropTypes.string,
  }),

  saveContracting: PropTypes.func,
  errorInformation: PropTypes.shape({
    StartDate: PropTypes.string,
    EndDate: PropTypes.string,
  }),
  loading: PropTypes.bool,
  handleChangeFile: PropTypes.func,
};
export default modalContractingFile;
