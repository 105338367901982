import apiClient from '../apiClient';
import { GET_PAYMENT_METHOD_IN_COUNTRY_URL } from '../apiUrl';

const getPaymentMethodInCountryApi = (countryId, paymentMethod) => {
  return apiClient.get(
    GET_PAYMENT_METHOD_IN_COUNTRY_URL(countryId, paymentMethod)
  );
};

export default getPaymentMethodInCountryApi;
