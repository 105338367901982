const setManualPaymentObject = bookingDetailSum => {
  let manualPaymentObject = {};
  if (bookingDetailSum.PackageType === 'Custom') {
    manualPaymentObject = {
      PaymentChoice: 0,
      CustomValue: 0,
      Id: bookingDetailSum.Id,
      Confirmation: 0,
      SupplementPayment: [],
      Customer: bookingDetailSum.CreatedBy.CompanyName,
      TourName: bookingDetailSum.Title,
      TourPrice: bookingDetailSum.TourTotalPrice,
      TourPaid: bookingDetailSum.TotalPayed,
      CurrencyId: bookingDetailSum.CurrencyId,
      PaymentTermCompany: bookingDetailSum.PaymentTerms
        ? bookingDetailSum.PaymentTerms
        : [],
      Payment: bookingDetailSum.PaymentTerms.map(data => {
        return {
          PaymentPercentage: data.PaymentPercentage,
          IsPayed: data.IsPayed,
        };
      }),
      Status: bookingDetailSum.Status,
      CompanyCode: bookingDetailSum.CreatedBy.CompanyCode,
    };
  } else {
    manualPaymentObject = {
      PaymentChoice: 0,
      CustomValue: 0,
      Id: bookingDetailSum.Id,
      Confirmation: 0,
      SupplementPayment: [],
      Customer: bookingDetailSum.CreatedBy.CompanyName,
      TourName: bookingDetailSum.Title,
      TourPrice: bookingDetailSum.FixedPackage
        ? bookingDetailSum.FixedPackage.BookingCommission.TotalPriceForCustomer
        : bookingDetailSum.TourTotalPrice,
      TourPaid: bookingDetailSum.TotalPayed,
      CurrencyId: bookingDetailSum.CurrencyId,
      PaymentTermCompany: bookingDetailSum.PaymentTerms
        ? bookingDetailSum.PaymentTerms
        : [],
      Payment: bookingDetailSum.PaymentTerms.map(data => {
        return {
          PaymentPercentage: data.PaymentPercentage,
          IsPayed: data.IsPayed,
        };
      }),

      Status: bookingDetailSum.Status,
      CompanyCode: bookingDetailSum.CreatedBy.CompanyCode,
    };
  }
  return manualPaymentObject;
};

const setConfirmTransactionData = (additonalItemPay, customValue, id) => {
  let paymentSupplement = additonalItemPay.map(pay => {
    let newData = {
      PaymentValue: 0,
      Id: pay.Id,
      Qty: pay.Qty,
    };
    return newData;
  });
  let data = {
    PaymentChoice: 'Deposit',
    Id: id,
    Confirmation: 'Confirm',
    SupplementPayment: paymentSupplement,
    CustomValue: customValue,
  };
  return data;
};
export { setManualPaymentObject, setConfirmTransactionData };
