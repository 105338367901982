import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Loader, Header } from 'semantic-ui-react';
import ResourceItemByType from './ResourcecItemByType';
import styles from '../../../../../../assets/styles/styleCreateTourTransaction';

const BookingItem = props => {
  const { resourceBooking } = props;
  return resourceBooking.length ? (
    resourceBooking.map((data, idx) => {
      return (
        <Grid
          key={idx}
          verticalAlign="middle"
          // key={idDays}
          style={{ paddingTop: '10px' }}
        >
          <div style={{ width: '100%' }}>
            <Loader active={false} inverted={true} />

            <ResourceItemByType data={data} />
          </div>
        </Grid>
      );
    })
  ) : (
    <Grid verticalAlign="middle">
      <Grid.Row style={styles.marginDailyProgramDays}>
        <Grid.Column width={12}>
          <Header as="h4" style={{ marginTop: '10px' }}>
            Resource Status Empty
          </Header>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

BookingItem.propTypes = {
  resourceBooking: PropTypes.array,
};

export default BookingItem;
