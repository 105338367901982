import { shortFormatDate } from '../../../../scripts/moment';

const getListDate = state => {
  let listDate;
  listDate =
    state && state.length > 0
      ? state.map(item => {
          return shortFormatDate(item.AllotmentDate);
        })
      : [];
  return listDate;
};

export default getListDate;
