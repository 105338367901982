const searchFilterManualConfirmationNumber = (data, searchInput) => {
  return data.filter(item => {
    return (
      (item.RequestItemId !== null &&
        item.RequestItemId.toLowerCase().indexOf(searchInput.toLowerCase()) !==
          -1) ||
      (item.Text !== null &&
        item.Text.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1) ||
      (item.CheckInDate !== null &&
        item.CheckInDate.toLowerCase().indexOf(searchInput.toLowerCase()) !==
          -1) ||
      (item.CheckOutDate !== null &&
        item.CheckOutDate.toLowerCase().indexOf(searchInput.toLowerCase()) !==
          -1) ||
      (item.ExpiredDate !== null &&
        item.ExpiredDate.toLowerCase().indexOf(searchInput.toLowerCase()) !==
          -1) ||
      (item.AccommodationName !== null &&
        item.AccommodationName.toLowerCase().indexOf(
          searchInput.toLowerCase()
        ) !== -1)
    );
  });
};

const searchFilterPendingConfirmationNumber = (data, searchInput) => {
  return (
    data.length &&
    data.filter(item => {
      return (
        (item &&
          item.RequestItemId.toLowerCase().indexOf(
            searchInput.toLowerCase()
          ) !== -1) ||
        (item.Text !== null &&
          item.Text.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1) ||
        (item.CheckInDate !== null &&
          item.CheckInDate.toLowerCase().indexOf(searchInput.toLowerCase()) !==
            -1) ||
        (item.CheckOutDate !== null &&
          item.CheckOutDate.toLowerCase().indexOf(searchInput.toLowerCase()) !==
            -1) ||
        (item.ExpiredDate !== null &&
          item.ExpiredDate.toLowerCase().indexOf(searchInput.toLowerCase()) !==
            -1) ||
        (item.AccommodationName !== null &&
          item.AccommodationName.toLowerCase().indexOf(
            searchInput.toLowerCase()
          ) !== -1)
      );
    })
  );
};

const searchFilterResourceBooking = (data, searchInput) => {
  return (
    data.length &&
    data.filter(item => {
      return (
        item.ResourceBookingCode.toLowerCase().indexOf(
          searchInput.toLowerCase()
        ) !== -1 ||
        (item.Name !== null &&
          item.Name.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1) ||
        (item.PackageType !== null &&
          item.PackageType.toLowerCase().indexOf(searchInput.toLowerCase()) !==
            -1) ||
        (item.StartTour !== null &&
          item.StartTour.toLowerCase().indexOf(searchInput.toLowerCase()) !==
            -1) ||
        // eslint-disable-next-line
        item.Id == searchInput
      );
    })
  );
};

const searchFilterPaymentRequest = (data, searchInput) => {
  return (
    data.length &&
    data.filter(item => {
      return (
        (item &&
          item.ResouceBookingCode.toLowerCase().indexOf(
            searchInput.toLowerCase()
          ) !== -1) ||
        (item.OwnerShip.Text !== null &&
          item.OwnerShip.Text.toLowerCase().indexOf(
            searchInput.toLowerCase()
          ) !== -1) ||
        (item.PaymentStatus === 0 &&
          item.PaymentStatus.toLowerCase().indexOf(
            searchInput.toLowerCase()
          ) !== -1) ||
        (item.DueDate !== null &&
          item.DueDate.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1)
      );
    })
  );
};

const searchFilterResourceItemList = (data, searchInput) => {
  return (
    data.length &&
    data.filter(item => {
      return (
        (item &&
          item.ResourceName.toLowerCase().indexOf(searchInput.toLowerCase()) !==
            -1) ||
        (item.ResourceType !== null &&
          item.ResourceType.toLowerCase().indexOf(searchInput.toLowerCase()) !==
            -1)
      );
    })
  );
};

const searchFilterCustomerPayment = (data, searchInput) => {
  return (
    data.length &&
    data.filter(item => {
      return (
        (item &&
          item.TransactionId &&
          item.TransactionId.toLowerCase().indexOf(
            searchInput.toLowerCase()
          ) !== -1) ||
        (item &&
          item.TransactionName &&
          item.TransactionName.toLowerCase().indexOf(
            searchInput.toLowerCase()
          ) !== -1) ||
        (item &&
          item.CompanyCode &&
          item.CompanyCode.toLowerCase().indexOf(searchInput.toLowerCase()) !==
            -1) ||
        (item &&
          item.CompanyName &&
          item.CompanyName.toLowerCase().indexOf(searchInput.toLowerCase()) !==
            -1) ||
        (item &&
          item.BillDescription &&
          item.BillDescription.toLowerCase().indexOf(
            searchInput.toLowerCase()
          ) !== -1) ||
        (item &&
          item.DueDate !== null &&
          item.DueDate.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1)
      );
    })
  );
};

export {
  searchFilterManualConfirmationNumber,
  searchFilterPendingConfirmationNumber,
  searchFilterResourceBooking,
  searchFilterPaymentRequest,
  searchFilterResourceItemList,
  searchFilterCustomerPayment,
};
