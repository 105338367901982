const Styles = {
  Segment: { marginTop: '30px' },
  BorderBlueBackgrounWhite: {
    background: 'white',
    border: 'solid 2px #2680EB',
    color: '#2680EB',
    padding: '9px 15px',
  },
  paddingTop20: {
    paddingTop: '20px',
  },
  inputStyle: {
    borderBottom: '1px solid black',
    width: '275px',
  },
  inputStyleInner: {
    border: '0px',
    borderRadius: 'none',
  },
};
export default Styles;
