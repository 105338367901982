import React from 'react';
import { Grid, Container } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from './styles';

const GuestTable = props => {
  const { guests, countryList, identityTypeList, guestTitleList } = props;
  const findById = (data, id) => {
    // eslint-disable-next-line
    const result = data.find(e => e.Id == id);
    return result.Name;
  };
  return (
    guests &&
    countryList &&
    identityTypeList &&
    guestTitleList && (
      <Grid columns={6} style={styles.marginTop0}>
        {guests.map((guest, index) => {
          return (
            <Grid.Row key={index}>
              <Grid.Column>
                <Container
                  content={
                    guest.Title !== null
                      ? findById(guestTitleList, guest.Title)
                      : '-'
                  }
                />
              </Grid.Column>
              <Grid.Column>
                <Container content={guest.FirstName} />
              </Grid.Column>
              <Grid.Column>
                <Container content={guest.LastName} />
              </Grid.Column>
              <Grid.Column>
                <Container
                  content={
                    guest.IdentityType !== null
                      ? findById(identityTypeList, guest.IdentityType)
                      : '-'
                  }
                />
              </Grid.Column>
              <Grid.Column>
                <Container content={guest.IdentityNbr} />
              </Grid.Column>
              <Grid.Column>
                <Container
                  content={
                    guest.CountryId !== null
                      ? countryList && findById(countryList, guest.CountryId)
                      : '-'
                  }
                />
              </Grid.Column>
            </Grid.Row>
          );
        })}
      </Grid>
    )
  );
};

GuestTable.propTypes = {
  guests: PropTypes.object,
  countryList: PropTypes.array,
  identityTypeList: PropTypes.array,
  guestTitleList: PropTypes.array,
};

export default React.memo(GuestTable);
