import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Input, Grid } from 'semantic-ui-react';
import {
  TexfieldWithDropdownFluidOnPage,
  TextAreaFluidOnPage,
} from '../../../../components/common/fieldWithError';
import CurrencyFormat from 'react-currency-format';
import { StyleModal } from '../../../../assets/styles/size';
import { StylesTextfieldWithError } from '../../../../components/common/styleCommon';
const ModalBalanceDetail = props => {
  return (
    <Modal
      dimmer="blurring"
      closeIcon
      inverted
      open={props.open}
      onClose={props.handleClose}
      size="small"
      style={StyleModal.modalWidth}
    >
      <Modal.Header>Add New {props.header}</Modal.Header>
      <Modal.Content>
        <label>
          Add <strong>{props.header}</strong> For
          <strong>
            {'     '}
            {props.contentField.Name ? props.contentField.Name : ''}
            {'     '}
            {props.contentField.CompanyCode
              ? props.contentField.CompanyCode
              : ''}
          </strong>
        </label>
        <TexfieldWithDropdownFluidOnPage
          placeholder1="Date Time"
          placeholder2="Curr"
          name1="DateTime"
          name2="CurrencyId"
          title1="Date Time"
          title2="Currency"
          handleChange={props.handleChange}
          handleChangeDropdown={props.handleChange}
          data={props.contentField.DateTime}
          option={props.currencies}
          valueDropDown={props.contentField.CurrencyId}
          requiredInput={true}
          requiredDropdown={true}
          styleDropdown={StylesTextfieldWithError.styleDropdownWithoutWidth}
          styleInput={StylesTextfieldWithError.textfieldDateBalanceDetail}
          type="date"
          fluidDropdown={true}
        />
        <Grid.Row style={{ marginLeft: '18.5em', marginTop: '-3.7em' }}>
          <Grid.Column>
            <CurrencyFormat
              // thousandSeparator={true}
              thousandSeparator={'.'}
              decimalSeparator={','}
              decimalScale={2}
              value={props.contentField.Value}
              textAlign="right"
              name="Value"
              customInput={Input}
              onValueChange={e => props.handleChangeAmount(e.value, 'Value')}
            />
            <label style={{ color: 'red' }}>
              {props.errorInformation.ValueError}
            </label>
          </Grid.Column>
        </Grid.Row>
        <TextAreaFluidOnPage
          placeholder="Note"
          name="Description"
          title="Note"
          handleChange={props.handleChange}
          style={StylesTextfieldWithError.widthNoteBalanceDetail}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          positive
          onClick={props.saveBalanceDetail}
          name={props.nameButton}
          icon="save"
          content={props.contentButton}
          loading={props.loading}
        />
      </Modal.Actions>
    </Modal>
  );
};

ModalBalanceDetail.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  handleChange: PropTypes.func,
  contentField: PropTypes.shape({
    Name: PropTypes.string,
    CompanyCode: PropTypes.number,
    DateTime: PropTypes.string,
    Value: PropTypes.number,
    CurrencyId: PropTypes.string,
  }),
  errorInformation: PropTypes.shape({
    ValueError: PropTypes.string,
  }),
  saveBalanceDetail: PropTypes.func,
  handleClickPaymentGateway: PropTypes.func,
  nameButton: PropTypes.string,
  contentButton: PropTypes.string,
  loading: PropTypes.bool,
  currencies: PropTypes.array,
  handleChangeAmount: PropTypes.func,
};
export default ModalBalanceDetail;
