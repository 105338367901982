import React from 'react';
import { Image, Grid, Header, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Style } from '../../../../assets/styles/styleCustomerProfile';

const ImageCustomerAndCompanyLogo = props => (
  <div>
    <Grid centered>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as="h4" content="Trade License Picture" />
        </Grid.Column>
        <Grid.Column width={8}>
          <Header as="h4" content="Company Logo" />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Image
            src={
              props.companyImage ||
              // .ImageContent ||
              // props.companyImage.ImagePreview
              require('../../../../assets/Icons/no_image.jpg')
            }
            size="small"
          />
          <Button
            content={props.contentImage}
            positive
            onClick={props.modalImage}
            style={Style.buttonAddImageLeft}
            id="LicensePicture"
            disabled={props.disable1}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <Image
            src={
              props.companyLogo ||
              //props.companyLogo.ImagePreview ||
              require('../../../../assets/Icons/no_image.jpg')
            }
            size="small"
          />

          <Button
            content={props.contentLogo}
            positive
            onClick={props.modalImage}
            // style={Style.buttonAddImageRight}
            style={Style.buttonAddImageLeft}
            id="CompanyLogo"
            disabled={props.disable2}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row></Grid.Row>
    </Grid>
  </div>
);

ImageCustomerAndCompanyLogo.propTypes = {
  companyImage: PropTypes.string,
  companyLogo: PropTypes.string,
  modalImage: PropTypes.func,
  contentLogo: PropTypes.string,
  contentImage: PropTypes.string,
  disable1: PropTypes.bool,
  disable2: PropTypes.bool,
};
export default ImageCustomerAndCompanyLogo;
