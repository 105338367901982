import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import styles from './styles';
import { converNumberToK } from '../../../../scripts/convertNumber';
// import { formatCurrencyWithDecimal } from '../../../../components/genericFunction/convertFormat';

const LeftSummary = props => {
  const { report } = props;
  return (
    <>
      <Grid.Row columns={2}>
        <Grid.Column>
          <p style={styles.marginBottom0}>Sales Achievement</p>
          <h3 style={styles.marginTop5}>{`${
            report ? converNumberToK(report.SalesAchievement) : '0'
          } ${report ? report.Currency : ''}`}</h3>
        </Grid.Column>
        <Grid.Column>
          <p style={styles.marginBottom0}>Booking want to be confirmed</p>
          <h3 style={styles.marginTop5}>{`${
            report ? report.BookingWaitToBeConfirmed : '0'
          } Bookings`}</h3>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns={2}>
        <Grid.Column>
          <p style={styles.marginBottom0}>Best Performance</p>
          <h3 style={styles.marginTop5}>{`${
            report ? report.BestPerformance : '-'
          }`}</h3>
        </Grid.Column>
        <Grid.Column>
          <p style={styles.marginBottom0}>Booking has confirmed</p>
          <h3 style={styles.marginTop5}>{`${
            report ? report.BookingHasConfirmed : '0'
          } Bookings`}</h3>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns={2}>
        <Grid.Column>
          <p style={styles.marginBottom0}>Poor Performance</p>
          <h3 style={styles.marginTop5}>{`${
            report ? report.PoorPerformance : '-'
          }`}</h3>
        </Grid.Column>
        <Grid.Column />
      </Grid.Row>
    </>
  );
};

LeftSummary.propTypes = {
  report: PropTypes.object,
};

export default LeftSummary;
