import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Button } from 'semantic-ui-react';
// import AccomodationItemPriceList from '../../table/accomodation/accomodationItem/tableAccomodationItemPriceList';
import AccomodationItemPriceList from './TableAccomodationItemPriceList';
// import AccomodationItemPriceList from '../../../components';
import { DropdownFluidOnPage } from '../../../../../components/common/fieldWithError';
import { StylesTextfieldWithError } from '../../../../../components/common/styleCommon';
import ModalSupplierPrice from '../../../../../components/modal/modalSupplierPrice';

const AccommodationPrice = props => {
  return (
    <Grid columns="equal">
      <Grid.Row stretched>
        <Grid.Column width={8}>
          <Header as="h4" content="Accommodation Price" />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Grid columns="3">
            <Grid.Row>
              <Grid.Column>
                <DropdownFluidOnPage
                  title="Service Type"
                  name="serviceType"
                  placeholder="Service Type"
                  handleChange={props.handleChangeServiceType}
                  valueDropDown={props.serviceType}
                  option={props.itemServiceTypeData}
                  errors={props.errors.ServicePriceType}
                  style={StylesTextfieldWithError.sizeDoubleComponent}
                  required={true}
                />
              </Grid.Column>
              <Grid.Column />
              <Grid.Column />
            </Grid.Row>
          </Grid>
        </Grid.Column>
        <Grid.Column width={8}>
          <Header as="h4" />
          <Grid columns="3">
            <Grid.Row>
              <Grid.Column />
              <Grid.Column />
              <Grid.Column>
                <Button
                  positive
                  content="Add Row"
                  id="price"
                  onClick={props.addPrice}
                  style={{ marginLeft: '3em' }}
                  disabled={props.disablePage}
                />
                <ModalSupplierPrice
                  openModalPrice={props.openModalPrice}
                  handleClose={props.handleClose}
                  listServiceTypesData={props.itemServiceTypeData}
                  addPriceDetails={props.addPriceDetails}
                  accomodationPrice={props.accomodationPrice}
                  currenciesList={props.currenciesList}
                  handleChangeCostPriceDetails={
                    props.handleChangeCostPriceDetails
                  }
                  handleChangePriceDetails={props.handleChangePriceDetails}
                  handleChangePrice={props.handleChangePrice}
                  handleChangePriceDate={props.handleChangePriceDate}
                  rankType={props.rankType}
                  addPriceToList={props.addPriceToList}
                  deletePrice={props.deletePrice}
                  errors={props.errors}
                  header={props.header}
                  itemName={props.itemName}
                  servicePriceName={props.servicePriceName}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={15}>
          <AccomodationItemPriceList
            listData={props.accomodationPriceList}
            editPrice={props.editPrice}
            deletePrice={props.deletePrice}
            disabled={props.disablePage}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

AccommodationPrice.propTypes = {
  itemServiceTypeData: PropTypes.array,
  addPrice: PropTypes.func,
  openModalPrice: PropTypes.bool,
  handleClose: PropTypes.func,
  addPriceDetails: PropTypes.func,
  currenciesList: PropTypes.array,
  accomodationPrice: PropTypes.object,
  handleChangeCostPriceDetails: PropTypes.func,
  handleChangePriceDetails: PropTypes.func,
  handleChangePrice: PropTypes.func,
  handleChangePriceDate: PropTypes.func,
  rankType: PropTypes.array,
  serviceType: PropTypes.string,
  handleChangeServiceType: PropTypes.func,
  accomodationPriceList: PropTypes.array,
  addPriceToList: PropTypes.func,
  editPrice: PropTypes.func,
  deletePrice: PropTypes.func,
  errors: PropTypes.object,
  disablePage: PropTypes.bool,
  header: PropTypes.string,
  itemName: PropTypes.string,
  servicePriceName: PropTypes.string,
};
export default AccommodationPrice;
