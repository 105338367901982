const getNumberFromTo = (listData, activePage, numberOfData) => {
  let numbersTo =
    listData && listData.length
      ? listData.length === numberOfData
        ? activePage * listData.length
        : (activePage - 1) * numberOfData + listData.length
      : 0;
  let numbersFrom = numbersTo > 0 ? numbersTo - (listData.length - 1) : 0;

  return { numbersFrom: numbersFrom, numbersTo: numbersTo };
};

const getInitialNumberInTable = (activePage, numberOfData) => {
  return (activePage - 1) * numberOfData;
};
export { getNumberFromTo, getInitialNumberInTable };
