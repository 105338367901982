const copyObject = obj => {
  let newObj = JSON.stringify(obj);
  return JSON.parse(newObj);
};

const setTourInformation = packageDetail => {
  let groupType = packageDetail.BookingDetailSum.GroupType;
  let tourType = packageDetail.BookingDetailSum.TourCategory;
  let tourCategory = packageDetail.BookingDetailSum.TourPaxType;
  let isPublished = packageDetail.isPublished;
  let expiredOn = packageDetail.BookingDetailSum.ExpiredOn;
  let startTour = packageDetail.BookingDetailSum.StartDate;
  let tourNote = packageDetail.TourNote;
  let minPax = packageDetail.MinPax;

  return {
    tourName: packageDetail.BookingDetailSum.Title,
    firstDestination: packageDetail.BookingDetailSum.City
      ? packageDetail.BookingDetailSum.City.Id
      : '',
    groupCapacity: groupType,
    tourType: tourType ? tourType.Id : '',
    tourCategory: tourCategory ? tourCategory.Id : '',
    isPublished: isPublished ? isPublished : false,
    expiredOn: expiredOn ? expiredOn : new Date(),
    startTour: startTour ? startTour : new Date(),
    tourNote: tourNote ? tourNote : '-',
    minPax: minPax ? minPax : '',
  };
};

const setGuests = (packageDetail, status) => {
  let isEdit = status === 'edit';
  return {
    adults: isEdit ? packageDetail.BookingDetailSum.AdultQty : 0,
    childs: isEdit ? packageDetail.BookingDetailSum.ChildQty : 0,
    infants: isEdit ? packageDetail.BookingDetailSum.InfantQty : 0,
  };
};

const setOnBehalf = (packageDetail, status) => {
  let { CreatedBy, Company } = packageDetail.BookingDetailSum;
  let isCreate = status === 'create';
  if (!isCreate) {
    return {
      customerCode: CreatedBy.CompanyCode,
      customerName: CreatedBy.CompanyName,
      customerTelephone: Company && Company.Phone ? Company.Phone : '',
      customerEmail: Company && Company.Email ? Company.Email : '',
      userId: CreatedBy.UserId,
      userName: CreatedBy.UserName,
      firstName: CreatedBy.FirstName,
      lastName: CreatedBy.LastName,
    };
  } else {
    return {};
  }
};

const getRoomPax = (rooms, name) => {
  return rooms
    ? rooms[name].FoCPax
      ? rooms[name].Pax + rooms[name].FoCPax
      : rooms[name].Pax
    : 0;
};

const setRoomAllocation = (packageDetail, type, status) => {
  let roomAllocation = {};
  let { TourPriceSum } = packageDetail;
  let isEdit = status === 'edit';
  if (type === 'Fixed') {
    roomAllocation = {
      ChildExtraBedQty: isEdit
        ? getRoomPax(TourPriceSum, 'ChildExtraBedSum')
        : 0,
      ExtraBedQty: isEdit ? getRoomPax(TourPriceSum, 'ExtraBedSum') : 0,
      NoBed: isEdit ? getRoomPax(TourPriceSum, 'NoBedSum') : 0,
      SharingBedQty: isEdit ? getRoomPax(TourPriceSum, 'SharingBedSum') : 0,
      SharingRoomQty: isEdit ? getRoomPax(TourPriceSum, 'SharingRoomSum') : 0,
      SingleRoomQty: isEdit ? getRoomPax(TourPriceSum, 'SingleRoomSum') : 0,
    };
  } else {
    roomAllocation = {
      ChildExtraBedQty: isEdit
        ? getRoomPax(TourPriceSum, 'ChildExtraBedSum')
        : 0,
      ChildSharingRoomQty: isEdit
        ? getRoomPax(TourPriceSum, 'ChildSharingRoomSum')
        : 0,
      ChildSingleRoomQty: isEdit
        ? getRoomPax(TourPriceSum, 'ChildSingleRoomSum')
        : 0,
      ExtraBedQty: isEdit ? getRoomPax(TourPriceSum, 'ExtraBedSum') : 0,
      BabyCrib: 0,
      NoBed: isEdit ? getRoomPax(TourPriceSum, 'NoBedSum') : 0,
      SharingBedQty: isEdit ? getRoomPax(TourPriceSum, 'SharingBedSum') : 0,
      SharingRoomQty: isEdit ? getRoomPax(TourPriceSum, 'SharingRoomSum') : 0,
      SingleRoomQty: isEdit ? getRoomPax(TourPriceSum, 'SingleRoomSum') : 0,
    };
  }
  return roomAllocation;
};

const setRoomPrices = price => {
  const roomPrices = [
    {
      Id: 'SHR',
      Currency: price.CurrencyId,
      Room: 'Sharing Room',
      Price: price.SharingRoomPrice,
    },
    {
      Id: 'SIR',
      Currency: price.CurrencyId,
      Room: 'Single Room',
      Price: price.SingleRoomPrice,
    },
    {
      Id: 'AEB',
      Currency: price.CurrencyId,
      Room: 'Adult Extra Bed',
      Price: price.AdultExtraBedPrice,
    },
    {
      Id: 'CEB',
      Currency: price.CurrencyId,
      Room: 'Child Extra Bed',
      Price: price.ChildExtraBedPrice,
    },
    {
      Id: 'SBW',
      Currency: price.CurrencyId,
      Room: 'Sharing Bed With Parents',
      Price: price.SharingBedPrice,
    },
    {
      Id: 'BCN',
      Currency: price.CurrencyId,
      Room: 'Baby Crib / No Bed',
      Price: price.NoBedPrice,
    },
  ];
  return roomPrices;
};

const setCommision = (packageDetail, type, status) => {
  const isEdit = status === 'edit';
  const isFixedPackage = status === 'Fixed';
  let commission = {
    splitCommision: null,
    printCommision: 0,
  };
  if (isFixedPackage) {
    const { IsSplitStaffCommission } = packageDetail.BookingDetailSum;
    if (isEdit) {
      if (IsSplitStaffCommission) {
        commission.splitCommision = 1;
      } else if (IsSplitStaffCommission === null) {
        commission.splitCommision = 0;
        commission.printCommision = 1;
      } else {
        commission.splitCommision = 0;
      }
    }
  }
  return commission;
};

const setAdditionalServices = additionalServices => {
  let newAdditionalService = additionalServices.reduce((res, cur) => {
    let obj = {
      Amount: cur.Amount,
      CurrencyId: cur.Currency,
      AdditionalServiceCountryId: cur.OriginalCountryId,
      IsMandatory: cur.IsMandatory,
      Quantity: cur.Quantity,
      IsInfantCount: cur.IsInfantCount,
    };
    res = res.concat(obj);
    return res;
  }, []);
  return newAdditionalService;
};

const convertDataBookNowFixed = (
  splitCommision,
  printCommision,
  guests,
  roomAllocation,
  supplements,
  listGuests,
  additionalServices
  // tourNote
) => {
  let selectCommission =
    splitCommision === 1 ? true : printCommision === 0 ? null : false;
  let data = {
    // TourNote:  tourNote,
    Supplements: getSupplement(supplements),
    IsQuotation: false,
    AdultPax: guests.adults,
    ChildPax: guests.childs,
    InfantPax: guests.infants,
    RoomAllocation: roomAllocation,
    Guests: getGuests(listGuests),
    AdditionalServices: setAdditionalServices(
      additionalServices ? additionalServices : []
    ),
  };
  selectCommission !== null && (data.IsSplitStaffCommission = selectCommission);
  return data;
};

const getSupplement = supplements => {
  let copySupplement = copyObject(supplements);
  let newSupplements = [];
  copySupplement.map(item => {
    return newSupplements.push({
      BookingTemplateId: item.bookingTemplateId,
      BookingTemplateSupplementId: item.bookingTemplateSupplementId,
      CurrencyId: item.currencyId,
      Qty: item.qty,
    });
  });
  return newSupplements;
};

const getGuests = listGuests => {
  let copyListGuests = copyObject(listGuests);
  let newListGuests = [];
  copyListGuests.map(item => {
    return (
      checkGuest(item) &&
      newListGuests.push({
        CountryId: item.CountryId,
        FirstName: item.FirstName,
        GuestCategory: item.GuestCategory,
        GuestTitle: item.GuestTitle,
        GuestType: item.GuestType,
        IdentityNbr: item.IdentityNbr,
        IdentityType: item.IdentityType,
        LastName: item.LastName,
      })
    );
  });
  return newListGuests;
};

const checkGuest = guest => {
  return (
    guest.CountryId !== '' &&
    guest.FirstName !== '' &&
    guest.GuestCategory !== '' &&
    guest.GuestTitle !== '' &&
    guest.GuestType !== '' &&
    guest.IdentityNbr !== '' &&
    guest.IdentityType !== '' &&
    guest.LastName !== ''
  );
};

const getNowPaymentTerm = packageDetail => {
  let { BookingDetailSum } = packageDetail;
  return BookingDetailSum
    ? BookingDetailSum.FixedPackage
      ? BookingDetailSum.FixedPackage.PaymentTerms.length !== 0
        ? BookingDetailSum.FixedPackage.PaymentTerms.filter(
            paid => !paid.IsPayed
          )[0]
        : {}
      : {}
    : {};
};

const getPaymentTerms = packageDetail => {
  let { BookingDetailSum } = packageDetail;
  return BookingDetailSum
    ? BookingDetailSum.FixedPackage
      ? BookingDetailSum.FixedPackage.PaymentTerms
      : []
    : [];
};

const convertTourPrice = tourPriceSum => {
  tourPriceSum = [
    {
      name: 'Sharing Room',
      data: tourPriceSum ? tourPriceSum.SharingRoomSum : {},
    },
    {
      name: 'Child Sharing Room',
      data: tourPriceSum ? tourPriceSum.ChildSharingRoomSum : {},
    },
    {
      name: 'Single Room',
      data: tourPriceSum ? tourPriceSum.SingleRoomSum : {},
    },
    {
      name: 'Child Single Room',
      data: tourPriceSum ? tourPriceSum.ChildSingleRoomSum : {},
    },
    {
      name: 'Extra Bed',
      data: tourPriceSum ? tourPriceSum.ExtraBedSum : {},
    },
    {
      name: 'Child Extra Bed',
      data: tourPriceSum ? tourPriceSum.ChildExtraBedSum : {},
    },
    {
      name: 'Sharing Bed with Parents',
      data: tourPriceSum ? tourPriceSum.SharingBedSum : {},
    },
    {
      name: 'Baby Crib / No Bed',
      data: tourPriceSum ? tourPriceSum.NoBedSum : {},
    },
  ];
  return tourPriceSum;
};

const priceSum = (currencyId, pax, pricePerPax) => {
  return {
    Currency: currencyId,
    Pax: pax,
    PricePerPax: pricePerPax,
    TotalPrice: pax * pricePerPax,
  };
};
const convertTourPriceCreateFixed = (roomAllocation, price) => {
  let tourPriceSum = [
    {
      name: 'Sharing Room',
      data: priceSum(
        price.CurrencyId,
        roomAllocation.SharingRoomQty,
        price.SharingRoomPrice
      ),
    },
    {
      name: 'Single Room',
      data: priceSum(
        price.CurrencyId,
        roomAllocation.SingleRoomQty,
        price.SingleRoomPrice
      ),
    },
    {
      name: 'Extra Bed',
      data: priceSum(
        price.CurrencyId,
        roomAllocation.ExtraBedQty,
        price.AdultExtraBedPrice
      ),
    },
    {
      name: 'Child Extra Bed',
      data: priceSum(
        price.CurrencyId,
        roomAllocation.ChildExtraBedQty,
        price.AdultExtraBedPrice
      ),
    },
    {
      name: 'Sharing with parents',
      data: priceSum(
        price.CurrencyId,
        roomAllocation.SharingBedQty,
        price.SharingBedPrice
      ),
    },
    {
      name: 'Baby Crib / No Bed',
      data: priceSum(price.CurrencyId, roomAllocation.NoBed, price.NoBedPrice),
    },
  ];
  return tourPriceSum;
};

const getCommissionPrice = (guests, commission, commissionsPackage) => {
  let commissionPrice = [];
  let totalPax = guests.adults + guests.childs;
  if (commission.splitCommision === 1) {
    let commPerPax = commissionsPackage.filter(
      e => e.Category === 'Agent_Commission'
    )[0].Value;
    commissionPrice.push({
      Description: 'Agent Commission',
      TotalPax: totalPax,
      TotalPrice: totalPax * commPerPax,
      UnitPrice: commPerPax,
    });
  } else {
    let commPerPax = commissionsPackage.reduce((acc, cur) => {
      return acc + cur.Value;
    }, 0);
    commissionPrice.push({
      Description: 'Commission',
      TotalPax: totalPax,
      TotalPrice: totalPax * commPerPax,
      UnitPrice: commPerPax,
    });
  }
  return commissionPrice;
};

const getTotalTourPrice = (tourPriceSum, supplements) => {
  let totalRoomPrice = tourPriceSum.reduce((acc, cur) => {
    return acc + cur.data.TotalPrice;
  }, 0);
  let totalSupplementPrice = supplements.reduce((acc, cur) => {
    return acc + cur.totalCost;
  }, 0);
  return totalRoomPrice + totalSupplementPrice;
};

const getTotalPayable = (totalRoomPrice, commissionsPrice) => {
  let totalComPrice = commissionsPrice.reduce((acc, cur) => {
    return acc + cur.TotalPrice;
  }, 0);
  return totalRoomPrice - totalComPrice;
};
export {
  copyObject,
  setTourInformation,
  setGuests,
  setRoomAllocation,
  setRoomPrices,
  setCommision,
  convertDataBookNowFixed,
  getNowPaymentTerm,
  getPaymentTerms,
  convertTourPrice,
  convertTourPriceCreateFixed,
  getCommissionPrice,
  getTotalTourPrice,
  getTotalPayable,
  setOnBehalf,
  getGuests,
};
