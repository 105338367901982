import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form } from 'semantic-ui-react';
import InputDate from '../../../components/input/inputDate';
import InputWithLabel from '../../../components/input/inputWithLabel';

const ModalExchangeRate = props => (
  <Modal
    closeIcon
    inverted
    open={props.openModal}
    onClose={props.handleClose}
    size="tiny"
    closeOnDimmerClick={false}
  >
    <Modal.Header>{props.header}</Modal.Header>
    <Modal.Content>
      <h5>{props.content}</h5>
      <Form>
        <Form.Group widths={2}>
          <InputWithLabel
            inline={false}
            label="Currency From"
            type="select"
            fluid={true}
            required
            name="currencyFrom"
            value={props.objectState.exchangeRateData.currencyFrom}
            placeholder="Currency From"
            handleChange={props.handleChangeRate}
            options={props.currencies}
            validate={props.errors}
          />
          <InputWithLabel
            inline={false}
            label="Currency To"
            type="select"
            fluid={true}
            required
            name="currencyTo"
            value={props.objectState.exchangeRateData.currencyTo}
            placeholder="Currency To"
            handleChange={props.handleChangeRate}
            options={props.currencies}
            validate={props.errors}
          />
        </Form.Group>
        <Form.Group widths={2}>
          <Form.Field>
            <InputDate
              label="Effective Date"
              name="startDate"
              value={props.objectState.exchangeRateData.startDate}
              setDate={props.handleChangeDate}
              labelStyle={{
                display: 'block',
                fontWeight: 'bold',
                marginBottom: '4px',
              }}
              readonly={false}
            />
          </Form.Field>
          <Form.Field>
            <InputWithLabel
              inline={false}
              label="Value"
              type="text"
              fluid={true}
              required
              name="valueRate"
              value={props.objectState.exchangeRateData.valueRate}
              placeholder="0"
              handleChange={props.handleChangeRate}
              validate={props.errors}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button content="Save" positive onClick={props.addRate} />
    </Modal.Actions>
  </Modal>
);

ModalExchangeRate.propTypes = {
  handleChangeCostPriceDetails: PropTypes.func,
  handleChangeRate: PropTypes.func,
  currenciesList: PropTypes.array,
  openModal: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  content: PropTypes.string,
  addPrice: PropTypes.func,
  handleChangeDropdown: PropTypes.func,
  handleChange: PropTypes.func,
  rankType: PropTypes.array,
  addRate: PropTypes.func,
  accomodationPrice: PropTypes.shape({
    PriceDetails: PropTypes.array,
    RankValue: PropTypes.number,
    RankType: PropTypes.string,
    StartDate: PropTypes.string,
    EndDate: PropTypes.string,
    CutOfDate: PropTypes.string,
    CutOfDay: PropTypes.number,
    MinimumNight: PropTypes.number,
    Currency: PropTypes.string,
  }),
  handleChangePrice: PropTypes.func,
  handleChangePriceDate: PropTypes.func,
  addPriceToList: PropTypes.func,
  deletePrice: PropTypes.func,
  errors: PropTypes.object,
  currencies: PropTypes.array,
  objectState: PropTypes.object,
  handleChangeDate: PropTypes.func,
};

export default ModalExchangeRate;
