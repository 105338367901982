import React from 'react';
// import { Dimmer, Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
// import ButtonDropdown from '../common/buttonDropdown';
// import ButtonDropdown from '../../../../../components/common/buttonDropdown';
import {
  DROPDOWN_LIST_ACTIVITY,
  DROPDOWN_LIST_ACTIVITY_BEFORE_CHECKIN_DAY1,
} from '../../../../../scripts/itineraryBuilderCreateFixedPackage/constData';
import {
  // isFirstDayNoAccom,
  selectActivity,
} from '../../../../../scripts/itineraryBuilderCreateFixedPackage/dailyProgramFixedPackage';
import { Modal, Button, Grid } from 'semantic-ui-react';
import {
  ARRIVAL,
  EAT,
  CHECKIN,
  CHECKOUT,
  DAYEND,
  FREETIME,
  DRIVING,
  RECREATION,
  DAYSTART,
  QUEUETIME,
  DEPARTURE,
  FLIGHTTIME,
  FREETIMELOCKED,
} from '../../../../shared/components/tourTransaction/dailyProgram/constant/activityType';
// props
class ButtonAddActivity extends React.Component {
  state = {
    openModalActivity: false,
  };
  openModal = (e, { name }) => {
    this.setState({ ...this.state, openModalActivity: false }, () => {
      this.props.handleOpenModalActivities(
        name,
        this.props.movement,
        this.props.activity.Movements,
        this.props.index,
        this.props.day,
        'ADD',
        this.props.position
      );
    });
  };
  // selectListActivity = (day, index, activity, position) => {
  //   if (day === 0) {
  //     let idxDeparture = activity.Movements.findIndex(
  //       e => e.MovementName === DEPARTURE
  //     );
  //     let listActivity = [];
  //     if (idxDeparture !== -1) {
  //       listActivity =
  //         idxDeparture > index
  //           ? DROPDOWN_LIST_ACTIVITY_BEFORE_ARRIVAL_DAY1
  //           : idxDeparture === index && position === 'top'
  //             ? DROPDOWN_LIST_ACTIVITY_BEFORE_ARRIVAL_DAY1
  //             : DROPDOWN_LIST_ACTIVITY;
  //     } else {
  //       let idxArrival = activity.Movements.findIndex(
  //         e => e.MovementName === ARRIVAL
  //       );
  //       listActivity =
  //         idxArrival !== -1
  //           ? idxArrival > index
  //             ? DROPDOWN_LIST_ACTIVITY_BEFORE_ARRIVAL_DAY1
  //             : idxArrival === index && position === 'top'
  //               ? DROPDOWN_LIST_ACTIVITY_BEFORE_ARRIVAL_DAY1
  //               : DROPDOWN_LIST_ACTIVITY
  //           : DROPDOWN_LIST_ACTIVITY;
  //     }
  //     return listActivity;
  //   } else {
  //     return DROPDOWN_LIST_ACTIVITY;
  //   }
  // };
  openCloseModalActivity = () => {
    this.setState({
      ...this.state,
      openModalActivity: !this.state.openModalActivity,
    });
  };
  render() {
    const { openModalActivity } = this.state;
    const {
      activity,
      index,
      movement,
      position,
      day,
      // dayFirstCheckIn,
      dailyProgram,
      isPublished,
    } = this.props;
    // const listActivity = isFirstDayNoAccom(
    //   day,
    //   index,
    //   activity,
    //   position,
    //   dayFirstCheckIn
    // )
    //   ? DROPDOWN_LIST_ACTIVITY_BEFORE_CHECKIN_DAY1
    //   : DROPDOWN_LIST_ACTIVITY;
    const listActivity = selectActivity(
      dailyProgram,
      day,
      index,
      position,
      DROPDOWN_LIST_ACTIVITY_BEFORE_CHECKIN_DAY1,
      DROPDOWN_LIST_ACTIVITY
    );
    const nowMovementName = movement ? movement.MovementName : '';
    const nextMovement = activity.Movements[index + 1];
    const nextMovementName = nextMovement ? nextMovement.MovementName : '';
    const nextMoveFreeOrQueue =
      nextMovement && [FREETIME, QUEUETIME].indexOf(nextMovementName) !== -1;
    const isMoveFreeOrQueue =
      [FREETIME, QUEUETIME].indexOf(nowMovementName) !== -1;
    const isArrayNull = position === 'top' && activity.Movements.length === 0;
    const isFirstCheckin =
      position === 'top' &&
      [CHECKIN, DEPARTURE, ARRIVAL, FREETIMELOCKED].indexOf(nowMovementName) !==
        -1 &&
      index === 0;
    const isActivity =
      position === 'bottom' &&
      (nowMovementName === EAT ||
        (nowMovementName === CHECKIN && nextMovementName === CHECKOUT) ||
        (nowMovementName === CHECKIN && nextMovementName === DAYEND) ||
        (isMoveFreeOrQueue && nextMovementName === DRIVING) ||
        (nowMovementName === RECREATION && nextMovementName === DRIVING) ||
        (nowMovementName === DAYSTART && nextMovementName === CHECKOUT) ||
        (nowMovementName === DAYSTART && nextMovementName === DAYEND) ||
        (nowMovementName === ARRIVAL && nextMovementName === DRIVING) ||
        (nowMovementName === CHECKOUT && nextMovementName === CHECKIN) ||
        (nowMovementName === CHECKOUT && nextMovementName === DRIVING) ||
        (nowMovementName === RECREATION && nextMoveFreeOrQueue) ||
        (nowMovementName === EAT && nextMoveFreeOrQueue) ||
        (isMoveFreeOrQueue && nextMovementName === DAYEND) ||
        (nowMovementName === QUEUETIME && nextMovementName === ARRIVAL) ||
        (nowMovementName === QUEUETIME && nextMovementName === CHECKIN) ||
        (nowMovementName === DEPARTURE && nextMovementName === CHECKIN) ||
        (nowMovementName === FLIGHTTIME && nextMovementName === CHECKIN) ||
        (nowMovementName === CHECKOUT && nextMovementName === '') ||
        (nowMovementName === RECREATION && nextMovementName === '') ||
        (nowMovementName === DEPARTURE && nextMovementName === '') ||
        (nowMovementName === EAT && nextMovementName === '') ||
        (nowMovementName === FREETIME && nextMovementName === '') ||
        (nowMovementName === QUEUETIME && nextMovementName === '') ||
        (nowMovementName === FLIGHTTIME && nextMovementName === '') ||
        (nowMovementName === FLIGHTTIME && nextMovementName === DRIVING) ||
        (nowMovementName === CHECKOUT && nextMovementName === ARRIVAL) ||
        (nowMovementName === CHECKOUT && nextMovementName === DAYEND) ||
        (nowMovementName === DEPARTURE && nextMovementName === DAYEND) ||
        (nowMovementName === FREETIME && nextMovementName === CHECKIN) ||
        (nowMovementName === FREETIME && nextMovementName === FREETIME));
    if (isArrayNull || isFirstCheckin || isActivity) {
      return (
        <div style={{ width: '100%' }}>
          <Button
            style={{
              background: '#16ab39',
              color: 'white',
              marginLeft: '7em',
              marginTop: '1em',
              marginBottom: '1em',
            }}
            disabled={isPublished}
            onClick={this.openCloseModalActivity}
          >
            Add New Activities
          </Button>
          <Modal
            style={{ width: '250px' }}
            open={openModalActivity}
            onClose={this.openCloseModalActivity}
          >
            <Modal.Header>Select Activity</Modal.Header>
            <Modal.Content>
              {listActivity.map((e, i) => (
                <Grid key={i}>
                  <Grid.Row style={{ padding: '5px 0px', margin: '0px' }}>
                    <Grid.Column width={16}>
                      <Button
                        name={e.value}
                        onClick={this.openModal}
                        primary
                        style={{ width: '100%' }}
                      >
                        {e.title}
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              ))}
            </Modal.Content>
          </Modal>
        </div>

        // <ButtonDropdown
        //   dropdowns={listActivity}
        //   title="Add New Activities"
        //   handleClick={this.openModal}
        //   dropdownStyles={{
        //     background: '#16ab39',
        //     color: 'white',
        //     marginLeft: '7em',
        //     marginTop: '1em',
        //     marginBottom: '1em',
        //   }}
        //   icon="plus"
        //   disabled={isPublished}
        // />
      );
    } else {
      return null;
    }
  }
}
ButtonAddActivity.propTypes = {
  movement: PropTypes.object,
  index: PropTypes.number,
  position: PropTypes.string,
  activity: PropTypes.object,
  handleOpenModalActivities: PropTypes.func,
  day: PropTypes.number,
  // dayFirstCheckIn: PropTypes.number,
  dailyProgram: PropTypes.array,
  isPublished: PropTypes.bool,
};
export default ButtonAddActivity;
