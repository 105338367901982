import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Grid, Image } from 'semantic-ui-react';
import noBookingImage from '../../../../assets/images/image-no-booking.png';
import styles from './styles';

const SegmentNoBooking = props => {
  const { dateClick } = props;
  return (
    <Segment style={styles.styleSegment}>
      <Grid>
        <Grid.Row textAlign="center">
          <Grid.Column textAlign="center">
            <Image src={noBookingImage} centered style={{ height: '200px' }} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row textAlign="center">
          <Grid.Column textAlign="center">
            No Booking In {dateClick}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

SegmentNoBooking.propTypes = {
  dateClick: PropTypes.string,
};

export default SegmentNoBooking;
