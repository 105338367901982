import { convertDateFormat } from '../../../../scripts/moment';
import moment from 'moment';

const filterItem = (date, filter) => {
  // filter (dateRange)      ||--------------------------------------------------||
  //
  // case                                               ||
  if (
    // case
    // moment(date).isSameOrAfter(filter.from) &&
    moment(date).isSameOrBefore(filter.to)
  ) {
    return true;
  } else return false;
};

const filterByDateRange = (data, filter) => {
  let filterFrom = filter.from
    ? convertDateFormat(filter.from, 'YYYY-MM-DD')
    : '';
  let filterTo = filter.to ? convertDateFormat(filter.to, 'YYYY-MM-DD') : '';
  let newFilter = {
    from: filterFrom,
    to: filterTo,
  };
  let newData =
    filter !== ''
      ? data.filter(item => {
          let effectiveDate = convertDateFormat(
            item.EffectiveDate,
            'YYYY-MM-DD'
          );
          return filterItem(effectiveDate, newFilter);
        })
      : data;
  return newData;
  //   console.log(filterTo);
};

export { filterByDateRange };
