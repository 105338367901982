import * as types from '../../../actions/actionTypes';

const initialState = {
  getAdditionalItemTypeList: [],
  getAdditionalItemTypeListStatus: '',
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ADDITIONAL_ITEM_TYPE:
      return { ...state };
    case types.GET_ADDITIONAL_ITEM_TYPE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getAdditionalItemTypeListStatus: 'failed',
      };
    case types.GET_ADDITIONAL_ITEM_TYPE_PENDING:
      return { ...state, loading: true };
    case types.GET_ADDITIONAL_ITEM_TYPE_FULFILLED:
      return {
        ...state,
        getAdditionalItemTypeList: action.payload.data.map(item => {
          return {
            key: item.Id,
            value: item.Id,
            text: item.Name,
          };
        }),
        loading: false,
        getAdditionalItemTypeListStatus: 'success',
      };

    case types.RESET_ADDITIONAL_ITEM_TYPE:
      return {
        ...state,
        getAdditionalItemTypeListStatus: '',
        getAdditionalItemTypeList: '',
      };

    default:
      return state;
  }
};

export default reducer;
