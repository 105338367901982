import { RESET_STATUS_TRANSPORTATION } from './transportationList/constant/actionTypes';

import {
  GET_EXPORT_TRANSPORTATION_PROFILE,
  GET_EXPORT_TRANSPORTATION_PROFILE_FULFILLED,
  GET_EXPORT_TRANSPORTATION_PROFILE_PENDING,
  GET_EXPORT_TRANSPORTATION_PROFILE_REJECTED,
  GET_TRANSPORTATION_UNIT_BY_PROFILE_ID,
  GET_TRANSPORTATION_UNIT_BY_PROFILE_ID_REJECTED,
  GET_TRANSPORTATION_UNIT_BY_PROFILE_ID_PENDING,
  GET_TRANSPORTATION_UNIT_BY_PROFILE_ID_FULFILLED,
  GET_TRANSPORTATION_CATAGORIES,
  GET_TRANSPORTATION_CATAGORIES_REJECTED,
  GET_TRANSPORTATION_CATAGORIES_PENDING,
  GET_TRANSPORTATION_CATAGORIES_FULFILLED,
  POST_TRANSPORTATION,
  POST_TRANSPORTATION_REJECTED,
  POST_TRANSPORTATION_PENDING,
  POST_TRANSPORTATION_FULFILLED,
  PUT_TRANSPORTATION,
  PUT_TRANSPORTATION_REJECTED,
  PUT_TRANSPORTATION_PENDING,
  PUT_TRANSPORTATION_FULFILLED,
  DELETE_TRANSPORTATION_BY_PROFILE_ID,
  DELETE_TRANSPORTATION_BY_PROFILE_ID_REJECTED,
  DELETE_TRANSPORTATION_BY_PROFILE_ID_PENDING,
  DELETE_TRANSPORTATION_BY_PROFILE_ID_FULFILLED,
} from './transportationProfile/constant/actionTypes';

import {
  GET_TRANSPORTATION_UNIT_BY_HEADER_ID,
  GET_TRANSPORTATION_UNIT_BY_HEADER_ID_PENDING,
  GET_TRANSPORTATION_UNIT_BY_HEADER_ID_FULFILLED,
  GET_TRANSPORTATION_UNIT_BY_HEADER_ID_REJECTED,
  GET_EXPORT_TRANSPORTATION_UNIT_DATA,
  GET_EXPORT_TRANSPORTATION_UNIT_DATA_FULFILLED,
  GET_EXPORT_TRANSPORTATION_UNIT_DATA_PENDING,
  GET_EXPORT_TRANSPORTATION_UNIT_DATA_REJECTED,
  POST_TRANSPORTATION_UNIT_HEADER,
  POST_TRANSPORTATION_UNIT_HEADER_FULFILLED,
  POST_TRANSPORTATION_UNIT_HEADER_PENDING,
  POST_TRANSPORTATION_UNIT_HEADER_REJECTED,
  POST_TRANSPORTATION_UNIT,
  POST_TRANSPORTATION_UNIT_FULFILLED,
  POST_TRANSPORTATION_UNIT_PENDING,
  POST_TRANSPORTATION_UNIT_REJECTED,
  PUT_TRANSPORTATION_UNIT_HEADER,
  PUT_TRANSPORTATION_UNIT_HEADER_FULFILLED,
  PUT_TRANSPORTATION_UNIT_HEADER_PENDING,
  PUT_TRANSPORTATION_UNIT_HEADER_REJECTED,
  PUT_TRANSPORTATION_UNIT,
  PUT_TRANSPORTATION_UNIT_FULFILLED,
  PUT_TRANSPORTATION_UNIT_PENDING,
  PUT_TRANSPORTATION_UNIT_REJECTED,
  DELETE_TRANSPORTATION_UNIT,
  DELETE_TRANSPORTATION_UNIT_FULFILLED,
  DELETE_TRANSPORTATION_UNIT_PENDING,
  DELETE_TRANSPORTATION_UNIT_REJECTED,
  DELETE_TRANSPORTATION_UNIT_HEADER,
  DELETE_TRANSPORTATION_UNIT_HEADER_FULFILLED,
  DELETE_TRANSPORTATION_UNIT_HEADER_PENDING,
  DELETE_TRANSPORTATION_UNIT_HEADER_REJECTED,
  DELETE_IMAGE_TRANSPORTATION_UNIT,
  DELETE_IMAGE_TRANSPORTATION_UNIT_FULFILLED,
  DELETE_IMAGE_TRANSPORTATION_UNIT_PENDING,
  DELETE_IMAGE_TRANSPORTATION_UNIT_REJECTED,
  GET_EXPORT_DATA_ITEM_BY_ID,
  GET_EXPORT_DATA_ITEM_BY_ID_FULFILLED,
  GET_EXPORT_DATA_ITEM_BY_ID_PENDING,
  GET_EXPORT_DATA_ITEM_BY_ID_REJECTED,
} from './transportationProfile/transportationUnit/constant/actionTypes';

const initialState = {
  loading: false,
  transportationUnitByHeader: [],
  getTransportationProfileExport: '',
  getTransportationProfileExportStatus: '',
  getTransportationUnitExport: '',
  getTransportationUnitExportStatus: '',
  getTransportationUnitHeaderByIdStatus: '',
  getTransportationUnitByProfileId: [],
  getTransportationUnitByProfileIdStatus: '',
  getTransportationCatagoriesList: [],
  postTransportation: [],
  postTransportationStatus: '',
  postTransportationHeader: [],
  postTransportationHeaderStatus: '',
  postTransportationUnit: [],
  postTransportationUnitStatus: '',
  putTransportationStatus: '',
  putTransportationHeader: [],
  putTransportationHeaderStatus: '',
  putTransportationUnit: [],
  putTransportationUnitStatus: '',
  deleteTransportationStatus: '',
  deleteTransportationUnitStatus: '',
  deleteTransportationHeaderStatus: '',
  errors: {},
  getExportDataItemById: '',
  getExportDataItemByIdStatus: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_STATUS_TRANSPORTATION:
      return {
        ...state,
        getTransportationUnitExportStatus: '',
        getTransportationUnitHeaderByIdStatus: '',
        getTransportationProfileExportStatus: '',
        getTransportationUnitByProfileIdStatus: '',
        postTransportationStatus: '',
        postTransportationHeaderStatus: '',
        postTransportationUnitStatus: '',
        putTransportationStatus: '',
        putTransportationHeaderStatus: '',
        putTransportationUnitStatus: '',
        deleteTransportationStatus: '',
        deleteTransportationUnitStatus: '',
        deleteTransportationHeaderStatus: '',
        getExportDataItemByIdStatus: null,
      };

    case GET_TRANSPORTATION_UNIT_BY_HEADER_ID: {
      return { ...state };
    }
    case GET_TRANSPORTATION_UNIT_BY_HEADER_ID_PENDING: {
      return { ...state, loading: true };
    }
    case GET_TRANSPORTATION_UNIT_BY_HEADER_ID_FULFILLED: {
      return {
        ...state,
        loading: false,
        transportationUnitByHeader: action.payload.data,
        getTransportationUnitHeaderByIdStatus: 'success',
      };
    }
    case GET_TRANSPORTATION_UNIT_BY_HEADER_ID_REJECTED: {
      return {
        ...state,
        loading: false,
        getTransportationUnitHeaderByIdStatus: 'failed',
      };
    }
    case GET_EXPORT_TRANSPORTATION_UNIT_DATA:
      return { ...state };
    case GET_EXPORT_TRANSPORTATION_UNIT_DATA_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getTransportationUnitExportStatus: 'failed',
      };
    case GET_EXPORT_TRANSPORTATION_UNIT_DATA_PENDING:
      return { ...state, loading: true };
    case GET_EXPORT_TRANSPORTATION_UNIT_DATA_FULFILLED:
      return {
        ...state,
        getTransportationUnitExport: action.payload.data,
        loading: false,
        getTransportationUnitExportStatus: 'success',
      };

    case GET_EXPORT_TRANSPORTATION_PROFILE:
      return { ...state };
    case GET_EXPORT_TRANSPORTATION_PROFILE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getTransportationProfileExportStatus: 'failed',
      };
    case GET_EXPORT_TRANSPORTATION_PROFILE_PENDING:
      return { ...state, loading: true };
    case GET_EXPORT_TRANSPORTATION_PROFILE_FULFILLED:
      return {
        ...state,
        getTransportationProfileExport: action.payload.data,
        loading: false,
        getTransportationProfileExportStatus: 'success',
      };
    case GET_TRANSPORTATION_UNIT_BY_PROFILE_ID:
      return { ...state };
    case GET_TRANSPORTATION_UNIT_BY_PROFILE_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getTransportationUnitByProfileIdStatus: 'failed',
      };
    case GET_TRANSPORTATION_UNIT_BY_PROFILE_ID_PENDING:
      return { ...state, loading: true };
    case GET_TRANSPORTATION_UNIT_BY_PROFILE_ID_FULFILLED:
      return {
        ...state,
        getTransportationUnitByProfileId: action.payload.data,
        loading: false,
        getTransportationUnitByProfileIdStatus: 'success',
      };
    case GET_TRANSPORTATION_CATAGORIES:
      return { ...state };
    case GET_TRANSPORTATION_CATAGORIES_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getTransportationCatagoriesListStatus: 'failed',
      };
    case GET_TRANSPORTATION_CATAGORIES_PENDING:
      return { ...state, loading: true };
    case GET_TRANSPORTATION_CATAGORIES_FULFILLED:
      return {
        ...state,
        getTransportationCatagoriesList: action.payload.data,
        loading: false,
      };
    case POST_TRANSPORTATION:
      return { ...state };
    case POST_TRANSPORTATION_REJECTED:
      return {
        ...state,
        postTransportationStatus: 'failed',
        errors: { global: action.payload.response.data.Message },
        loading: false,
      };
    case POST_TRANSPORTATION_PENDING:
      return { ...state, loading: true };
    case POST_TRANSPORTATION_FULFILLED:
      return {
        ...state,
        postTransportationStatus: 'success',
        postTransportation: action.payload.data,
        loading: false,
      };
    case POST_TRANSPORTATION_UNIT_HEADER:
      return { ...state };
    case POST_TRANSPORTATION_UNIT_HEADER_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        postTransportationHeaderStatus: 'failed',
      };
    case POST_TRANSPORTATION_UNIT_HEADER_PENDING:
      return { ...state, loading: true };
    case POST_TRANSPORTATION_UNIT_HEADER_FULFILLED:
      return {
        ...state,
        postTransportationHeaderStatus: 'success',
        postTransportationHeader: action.payload.data,
        loading: false,
      };
    case PUT_TRANSPORTATION:
      return { ...state };
    case PUT_TRANSPORTATION_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        putTransportationStatus: 'failed',
      };
    case PUT_TRANSPORTATION_PENDING:
      return { ...state, loading: true };
    case PUT_TRANSPORTATION_FULFILLED:
      return {
        ...state,
        putTransportationStatus: 'success',
        loading: false,
      };
    case PUT_TRANSPORTATION_UNIT_HEADER:
      return { ...state };
    case PUT_TRANSPORTATION_UNIT_HEADER_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        putTransportationHeaderStatus: 'failed',
      };
    case PUT_TRANSPORTATION_UNIT_HEADER_PENDING:
      return { ...state, loading: true };
    case PUT_TRANSPORTATION_UNIT_HEADER_FULFILLED:
      return {
        ...state,
        putTransportationHeaderStatus: 'success',
        putTransportationHeader: action.payload.data,
        loading: false,
      };

    case POST_TRANSPORTATION_UNIT:
      return { ...state };
    case POST_TRANSPORTATION_UNIT_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        postTransportationUnitStatus: 'failed',
      };
    case POST_TRANSPORTATION_UNIT_PENDING:
      return { ...state, loading: true };
    case POST_TRANSPORTATION_UNIT_FULFILLED:
      return {
        ...state,
        postTransportationUnitStatus: 'success',
        postTransportationUnit: action.payload.data,
        loading: false,
      };

    case PUT_TRANSPORTATION_UNIT:
      return { ...state };
    case PUT_TRANSPORTATION_UNIT_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        putTransportationUnitStatus: 'failed',
      };
    case PUT_TRANSPORTATION_UNIT_PENDING:
      return { ...state, loading: true };
    case PUT_TRANSPORTATION_UNIT_FULFILLED:
      return {
        ...state,
        putTransportationUnitStatus: 'success',
        putTransportationUnit: action.payload.data,
        loading: false,
      };
    case DELETE_TRANSPORTATION_BY_PROFILE_ID:
      return { ...state };
    case DELETE_TRANSPORTATION_BY_PROFILE_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        deleteTransportationStatus: 'failed',
      };
    case DELETE_TRANSPORTATION_BY_PROFILE_ID_PENDING:
      return { ...state, loading: true };
    case DELETE_TRANSPORTATION_BY_PROFILE_ID_FULFILLED:
      return {
        ...state,
        deleteTransportationStatus: 'success',
        loading: false,
      };
    case DELETE_TRANSPORTATION_UNIT:
      return { ...state };
    case DELETE_TRANSPORTATION_UNIT_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
      };
    case DELETE_TRANSPORTATION_UNIT_PENDING:
      return { ...state, loading: true };
    case DELETE_TRANSPORTATION_UNIT_FULFILLED:
      return {
        ...state,
        deleteTransportationUnitStatus: 'success',
        loading: false,
      };
    case DELETE_TRANSPORTATION_UNIT_HEADER:
      return { ...state };
    case DELETE_TRANSPORTATION_UNIT_HEADER_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
      };
    case DELETE_TRANSPORTATION_UNIT_HEADER_PENDING:
      return { ...state, loading: true };
    case DELETE_TRANSPORTATION_UNIT_HEADER_FULFILLED:
      return {
        ...state,
        deleteTransportationHeaderStatus: 'success',
        loading: false,
      };
    case DELETE_IMAGE_TRANSPORTATION_UNIT:
      return { ...state };
    case DELETE_IMAGE_TRANSPORTATION_UNIT_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        deleteImageTransportationUnitStatus: 'failed',
      };
    case DELETE_IMAGE_TRANSPORTATION_UNIT_PENDING:
      return { ...state, loading: true };
    case DELETE_IMAGE_TRANSPORTATION_UNIT_FULFILLED:
      return {
        ...state,
        deleteImageTransportationUnitStatus: 'success',
        loading: false,
      };

    case GET_EXPORT_DATA_ITEM_BY_ID:
      return { ...state };
    case GET_EXPORT_DATA_ITEM_BY_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getExportDataItemByIdStatus: false,
      };
    case GET_EXPORT_DATA_ITEM_BY_ID_PENDING:
      return { ...state, loading: true };
    case GET_EXPORT_DATA_ITEM_BY_ID_FULFILLED:
      return {
        ...state,
        getExportDataItemById: action.payload.data,
        loading: false,
        getExportDataItemByIdStatus: true,
      };
    default:
      return state;
  }
};

export default reducer;
