import DatePicker from 'react-datepicker';
// import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';

import React from 'react';
import PropTypes from 'prop-types';

// const GOLD_COLOR = '#e6ca6b';

class InputDate extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      // startDate: props.value ? moment(props.value) : moment(),
      startDate: new Date(),
    };
    this.handleChange = this.handleChange.bind(this);
  }
  // componentDidUpdate() {
  //   if (this.state.startDate !== moment(this.props.value))
  //     this.setState({ ...this.state, startDate: moment(this.props.value) });
  // }
  handleChange = date => {
    // this.context.setState({
    //   startDate: date,
    // });
    //this.setState({ startDate: date });
    this.props.setDate(date, this.props.name);
  };

  render() {
    const {
      value,
      customInput,
      minDate,
      maxDate,
      labelStyle,
      label,
      readonly,
      // inputStyles,
      validate,
      name,
      disabled,
      includeDates,
    } = this.props;
    let startDate = value ? new Date(value) : new Date(); //value ? moment(value) : moment();
    let maximalDate = maxDate ? new Date(maxDate) : null;
    let minimalDate = minDate ? new Date(minDate) : null;

    const validation = { error: false, message: '' };
    const textStyles = { color: 'black' };
    const newLabelStyles = { ...labelStyle };
    // const newInputStyles = { ...inputStyles };
    if (name && validate) {
      if (validate[name] !== undefined) {
        validation.error = validate[name].error;
        validation.message = validate[name].message;
        if (validation.error) {
          textStyles['color'] = '#9f3a38';
          newLabelStyles['color'] = '#9f3a38';
          // newInputStyles['borderColor'] = '#e0b4b4';
          // newInputStyles['background'] = '#fff6f6';
          // newInputStyles['color'] = '#9f3a38';
        }
      }
    }
    return (
      <div className="datepickerWrap">
        <label style={newLabelStyles}>{label}</label>
        <DatePicker
          minDate={minimalDate}
          maxDate={maximalDate}
          selected={startDate}
          onChange={this.handleChange}
          context={this}
          dateFormat="dd MMM yyyy"
          customInput={customInput}
          readOnly={readonly}
          className={validation.error ? 'warningDatePicker' : ''}
          disabled={disabled}
          includeDates={includeDates}
        />
        {validation.error ? (
          <span style={textStyles}>{validation.message}</span>
        ) : null}
      </div>
    );
  }
}
// customInput.propTypes = {
//   value: PropTypes.string,
//   onClick: PropTypes.func,
// };
InputDate.propTypes = {
  value: PropTypes.string,
  customInput: PropTypes.object,
  setDate: PropTypes.func,
  label: PropTypes.string,
  labelStyle: PropTypes.string,
  name: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  readonly: PropTypes.bool,
  // inputStyles: PropTypes.object,
  validate: PropTypes.object,
  disabled: PropTypes.bool,
  includeDates: PropTypes.array,
};
export default InputDate;
