import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Modal,
  Table,
  Pagination,
  Button,
  Grid,
  Container,
} from 'semantic-ui-react';
import Loader from '../../../../../components/loader';
import InputSearch from '../../../../../components/input/inputSearch';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../../../scripts/pagination';
import {
  rangeDate,
  dateNow,
  convertDateFormat,
  addDate,
} from '../../../../../scripts/moment';
import { getGenerateResouceBookingAction } from '../../../../shared/actions/resourceBookingAction';
import { connect } from 'react-redux';
import ModalAlert from '../../../../shared/components/modal/ModalAlert';
import ModalConfirm from '../../../../../../src/components/modal/modalConfirmationTwoButton';
import ButtonClose from '../../../../shared/components/common/button/ButtonCloseModal';
import ModalDateRange from '../../../../shared/components/modal/ModalDateRange';
import { getTransactionHistoryByOperation } from './../../../../../actions/tourTransactionAction';
import { copyObject } from '../../../../../scripts/itineraryBuilder/itineraryBuilder';
class ModalPackageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTitle: '',
      activePage: 1,
      gotoNext: false,
      linkGoto: '',
      openModalAlert: false,
      contentAlert: '',
      headerAlert: '',
      linkAlert: '',
      openModalConfirm: false,
      messageModalConfirm: '',
      nameModalConfirm: '',
      // untuk filter date
      filterDate: {
        tourStartDate: convertDateFormat(dateNow, 'YYYY-MM-DD'),
        tourEndDate: convertDateFormat(addDate(dateNow, 30, 'd'), 'YYYY-MM-DD'),
        createStartDate: '',
        createEndDate: '',
      },
      openModalFilterBooking: false,
      radioValueDateRange: '1',
      // end untuk filter date
      packages: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(copyObject(prevProps.packages)) !==
      JSON.stringify(copyObject(this.props.packages))
    ) {
      this.setState({ ...this.state, packages: this.props.packages });
    }
  }

  handleGenerate = () => {
    let { isCreateByPackage } = this.props;
    this.props
      .getGenerateResouceBookingAction({
        tourTransactionCode: isCreateByPackage
          ? 0
          : this.state.selectedIdGenerate,
        bookingTemplateIdRef: isCreateByPackage
          ? this.state.selectedIdGenerate
          : 0,
        resourceBookingType: isCreateByPackage ? 1 : 0,
      })
      .then(() => {
        this.setState({
          openModalAlert: true,
          contentAlert: 'Generated resource successfully',
          headerAlert: 'Successfully',
          linkAlert:
            '/Resource-Booking-Detail/' +
            this.props.resourceBooking.ResourceBookingId,
        });
      })
      .catch(() => {
        this.setState({
          openModalAlert: true,
          contentAlert: this.props.errorResourceBooking
            ? this.props.errorResourceBooking.global
            : 'Failed generate resource',
          headerAlert: 'Failed',
        });
      });
    this.setState({ ...this.state, openModalConfirm: false });
  };

  handleConfirm = (e, { value }) => {
    this.setState({
      ...this.state,
      openModalConfirm: true,
      messageModalConfirm: 'Are you sure want generate resource?',
      nameModalConfirm: 'Generate Resource',
      selectedIdGenerate: value,
    });
  };

  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  filtered = listData => {
    let { searchTitle } = this.state;
    return listData
      ? listData.filter(
          data =>
            data.BookingId.toLowerCase().indexOf(searchTitle.toLowerCase()) !==
              -1 ||
            data.BookingName.toLowerCase().indexOf(
              searchTitle.toLowerCase()
            ) !== -1 ||
            data.PackageType.toLowerCase().indexOf(
              searchTitle.toLowerCase()
            ) !== -1 ||
            (data.StartDate !== null &&
              data.StartDate.toLowerCase().indexOf(
                searchTitle.toLowerCase()
              ) !== -1) ||
            (data.EndDate !== null &&
              data.EndDate.toLowerCase().indexOf(searchTitle.toLowerCase()) !==
                -1)
        )
      : [];
  };
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  handleCloseGenerate = () => {
    this.setState({
      ...this.state,
      openModalConfirm: false,
      headerAlert: '',
      contentAlert: '',
    });
  };
  handleCloseAlert = async () => {
    if (this.state.linkAlert !== '') {
      await this.setState({
        gotoNext: true,
        linkGoto: this.state.linkAlert,
      });
    }
    this.setState({
      ...this.state,
      openModalAlert: false,
      headerAlert: '',
      contentAlert: '',
      linkAlert: '',
    });
  };
  openModalDateRange = (e, { id, name }) =>
    this.setState({
      ...this.state,
      openModalFilterBooking: true,
      filterDate: {
        ...this.state.filterDate,
        idFilter: 'Filter ' + id + '',
        statusFilter: name,
      },
    });
  handleCloseFilterBooking = () => {
    this.setState({
      openModalFilterBooking: false,
    });
  };
  actionDateRange = async id => {
    const value = this.state.radioValueDateRange;
    const { from, to } = id;
    // const { statusFilter } = this.state.dateRange;
    await this.setState({
      ...this.state,
      openModalFilterBooking: false,
    });
    if (value === '1') {
      this.setState(
        {
          filterDate: {
            ...this.state.dateRange,
            createStartDate: '',
            createEndDate: '',
            tourStartDate: convertDateFormat(from, 'YYYY-MM-DD'),
            tourEndDate: convertDateFormat(to, 'YYYY-MM-DD'),
          },
        },
        () => this.getListBooking()
      );
    } else {
      await this.setState(
        {
          filterDate: {
            ...this.state.dateRange,
            createStartDate: convertDateFormat(from, 'YYYY-MM-DD'),
            createEndDate: convertDateFormat(to, 'YYYY-MM-DD'),
            tourStartDate: '',
            tourEndDate: '',
          },
        },
        () => this.getListBooking()
      );
    }
  };
  getListBooking = () => {
    let {
      tourStartDate,
      tourEndDate,
      createStartDate,
      createEndDate,
    } = this.state.filterDate;
    this.props
      .getTransactionHistoryByOperation(
        tourStartDate,
        tourEndDate,
        createStartDate,
        createEndDate,
        'After_DP'
      )
      .then(() => {
        this.setState({ ...this.state, packages: this.props.listTransaction });
      });
  };
  handleRadioDateRange = value => {
    this.setState({
      ...this.state,
      radioValueDateRange: value,
    });
  };

  render() {
    let {
      openModal,
      closeModal,
      header,
      loading,
      isCreateByPackage,
      loadingGenerate,
      loadingTransaction,
    } = this.props;
    let {
      packages,
      searchTitle,
      gotoNext,
      linkGoto,
      openModalAlert,
      headerAlert,
      contentAlert,
      openModalConfirm,
      messageModalConfirm,
      nameModalConfirm,
      filterDate,
      openModalFilterBooking,
      radioValueDateRange,
    } = this.state;
    if (gotoNext) {
      return <Redirect to={linkGoto} />;
    }
    let filterData = this.filtered(packages);
    let pagedData = datasetPagination(filterData, 5);
    let listData = pagedData[this.state.activePage - 1];
    let { numbersFrom, numbersTo } = getNumberFromTo(
      listData,
      this.state.activePage,
      5
    );
    return (
      <Modal
        inverted
        open={openModal}
        onClose={closeModal}
        size="medium"
        closeOnDimmerClick={false}
      >
        <Loader
          loading={loading || loadingGenerate || loadingTransaction}
          inverted={true}
          page={true}
        />
        <Modal.Header>
          {header} <ButtonClose onClose={closeModal} />
        </Modal.Header>
        <Modal.Content>
          <Grid columns="equal">
            <Grid.Row stretched>
              <Grid.Column width={7}>
                <InputSearch
                  value={searchTitle}
                  handleChange={this.handleChange}
                  placeholder={`Search by ${
                    isCreateByPackage ? 'package no' : 'booking no'
                  } or Tour Name`}
                  inputStyle={{ borderBottom: '1px solid black' }}
                  inputInnerStyle={{ border: '0px', borderRadius: 'none' }}
                />
              </Grid.Column>
              <Grid.Column width={12}></Grid.Column>
            </Grid.Row>
            {isCreateByPackage ? null : (
              <Grid.Row>
                <Grid.Column width={8}>
                  <div
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    <Container
                      content={
                        'Start tour range date : ' +
                        filterDate.tourStartDate +
                        ' - ' +
                        filterDate.tourEndDate
                      }
                    />
                    <Container
                      content={
                        'Created tour range date : ' +
                        filterDate.createStartDate +
                        ' - ' +
                        filterDate.createEndDate
                      }
                    />
                    {/* )} */}
                  </div>
                </Grid.Column>
                <Grid.Column width={8} textAlign="right">
                  <Button
                    icon="filter"
                    basic
                    size="small"
                    floated="right"
                    positive
                    id="Booking"
                    onClick={this.openModalDateRange}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
          <Table basic="very" striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  {isCreateByPackage ? 'Package No' : 'Booking No'}{' '}
                </Table.HeaderCell>
                <Table.HeaderCell>Tour Name</Table.HeaderCell>
                <Table.HeaderCell>Package Type</Table.HeaderCell>
                <Table.HeaderCell>Tour Date</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {listData ? (
                listData.map((item, index) => (
                  <Table.Row key={'list' + index}>
                    <Table.Cell>{item.BookingId}</Table.Cell>
                    <Table.Cell>{item.BookingName}</Table.Cell>
                    <Table.Cell>
                      {item.PackageType === 'FixedDateVariable'
                        ? 'Ready Fixed Price'
                        : item.PackageType === 'Fixed'
                        ? 'Series'
                        : item.PackageType}
                    </Table.Cell>
                    <Table.Cell>
                      {rangeDate(item.StartDate, item.EndDate)}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {item.IsGenerated ? (
                        <Button disabled={true}>Already Generated</Button>
                      ) : !isCreateByPackage &&
                        item.PackageType === 'Fixed' ? null : (
                        <Button
                          color="green"
                          name={item.PackageType}
                          value={item.BookingId}
                          onClick={this.handleConfirm}
                          id={!item.IsGenerated}
                        >
                          Generate Resource
                        </Button>
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colSpan="6" textAlign="center">
                    No Data
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
            <Table.Row>
              <Table.HeaderCell colSpan="6">
                {pagedData && pagedData.length > 1 && (
                  <Pagination
                    totalPages={pagedData.length}
                    onPageChange={this.pageChange}
                    activePage={this.state.activePage}
                    style={{ float: 'right' }}
                  />
                )}
                {pagedData && pagedData.length > 1 && (
                  <p>
                    Showing {numbersTo} to {numbersFrom} of {pagedData.length}
                  </p>
                )}
              </Table.HeaderCell>
            </Table.Row>
          </Table>
          <ModalConfirm
            openModal={openModalConfirm}
            size="mini"
            close={this.handleCloseGenerate}
            confirm={this.handleGenerate}
            message={messageModalConfirm}
            nameConfirm={nameModalConfirm}
            header={nameModalConfirm}
          />
          <ModalAlert
            openModal={openModalAlert}
            handleClose={this.handleCloseAlert}
            header={headerAlert}
            content={contentAlert}
          />
          <ModalDateRange
            openModal={openModalFilterBooking}
            handleClose={this.handleCloseFilterBooking}
            dateRange={filterDate}
            actionDateRange={this.actionDateRange}
            contentHeader={filterDate.idFilter}
            value={radioValueDateRange}
            handleRadioDateRange={this.handleRadioDateRange}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

ModalPackageList.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  header: PropTypes.string,
  loading: PropTypes.bool,
  loadingGenerate: PropTypes.bool,
  packages: PropTypes.array,
  packageType: PropTypes.bool,
  isCreateByPackage: PropTypes.bool,
  resourceBooking: PropTypes.object,
  errorResourceBooking: PropTypes.object,
  getGenerateResouceBookingAction: PropTypes.func,
  getTransactionHistoryByOperation: PropTypes.func,
  listTransaction: PropTypes.array,
  loadingTransaction: PropTypes.bool,
};
const mapStateToProps = state => {
  return {
    resourceBooking: state.ResourceBooking.generateResourceBooking,
    errorResourceBooking: state.ResourceBooking.errors,
    loadingGenerate: state.ResourceBooking.loadingGenerate,
    listTransaction: state.TourTransactionStore.listTransaction,
    loadingTransaction: state.TourTransactionStore.loading,
  };
};

export default connect(mapStateToProps, {
  getGenerateResouceBookingAction,
  getTransactionHistoryByOperation,
})(ModalPackageList);
