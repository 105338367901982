import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import getRoomPrices from '../services/getRoomPrice';

const TableRoomAllocationPrice = props => {
  let dataRoomPrice = getRoomPrices(props.priceRoom);
  return (
    <Table basic>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Room Type</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Price</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {dataRoomPrice.map((item, idx) => {
          return (
            <Table.Row key={idx}>
              <Table.Cell>{item.Room}</Table.Cell>
              <Table.Cell textAlign="right">
                {item.Currency}{' '}
                {typeof item.Price !== 'undefined'
                  ? item.Price.toLocaleString('id')
                  : ''}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

TableRoomAllocationPrice.propTypes = {
  priceRoom: PropTypes.array,
};

export default TableRoomAllocationPrice;
