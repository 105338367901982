//POST _IMAGE_TRANSPORTATION_PROFILE
const POST_UPLOAD_IMAGE_TRANSPORTATION_PROFILE =
  'POST_UPLOAD_IMAGE_TRANSPORTATION_PROFILE';
const POST_UPLOAD_IMAGE_TRANSPORTATION_PROFILE_FULFILLED =
  'POST_UPLOAD_IMAGE_TRANSPORTATION_PROFILE_FULFILLED';
const POST_UPLOAD_IMAGE_TRANSPORTATION_PROFILE_PENDING =
  'POST_UPLOAD_IMAGE_TRANSPORTATION_PROFILE_PENDING';
const POST_UPLOAD_IMAGE_TRANSPORTATION_PROFILE_REJECTED =
  'POST_UPLOAD_IMAGE_TRANSPORTATION_PROFILE_REJECTED';

//Get Transportation By Profile ID
const GET_TRANSPORTATION_BY_PROFILE_ID = 'GET_TRANSPORTATION_BY_PROFILE_ID';
const GET_TRANSPORTATION_BY_PROFILE_ID_FULFILLED =
  'GET_TRANSPORTATION_BY_PROFILE_ID_FULFILLED';
const GET_TRANSPORTATION_BY_PROFILE_ID_PENDING =
  'GET_TRANSPORTATION_BY_PROFILE_ID_PENDING';
const GET_TRANSPORTATION_BY_PROFILE_ID_REJECTED =
  'GET_TRANSPORTATION_BY_PROFILE_ID_REJECTED';

//Delete Transportation By Profile Id
const DELETE_TRANSPORTATION_BY_PROFILE_ID =
  'DELETE_TRANSPORTATION_BY_PROFILE_ID';
const DELETE_TRANSPORTATION_BY_PROFILE_ID_FULFILLED =
  'DELETE_TRANSPORTATION_BY_PROFILE_ID_FULFILLED';
const DELETE_TRANSPORTATION_BY_PROFILE_ID_PENDING =
  'DELETE_TRANSPORTATION_BY_PROFILE_ID_PENDING';
const DELETE_TRANSPORTATION_BY_PROFILE_ID_REJECTED =
  'DELETE_TRANSPORTATION_BY_PROFILE_ID_REJECTED';

//Post Transportation
const POST_TRANSPORTATION = 'POST_TRANSPORTATION';
const POST_TRANSPORTATION_FULFILLED = 'POST_TRANSPORTATION_FULFILLED';
const POST_TRANSPORTATION_PENDING = 'POST_TRANSPORTATION_PENDING';
const POST_TRANSPORTATION_REJECTED = 'POST_TRANSPORTATION_REJECTED';

//Get Transportation By Profile ID
const GET_TRANSPORTATION_UNIT_BY_PROFILE_ID =
  'GET_TRANSPORTATION_UNIT_BY_PROFILE_ID';
const GET_TRANSPORTATION_UNIT_BY_PROFILE_ID_FULFILLED =
  'GET_TRANSPORTATION_UNIT_BY_PROFILE_ID_FULFILLED';
const GET_TRANSPORTATION_UNIT_BY_PROFILE_ID_PENDING =
  'GET_TRANSPORTATION_BY_PROFILE_ID_PENDING';
const GET_TRANSPORTATION_UNIT_BY_PROFILE_ID_REJECTED =
  'GET_TRANSPORTATION_UNIT_BY_PROFILE_ID_REJECTED';

//Post Transportation
const PUT_TRANSPORTATION = 'PUT_TRANSPORTATION';
const PUT_TRANSPORTATION_FULFILLED = 'PUT_TRANSPORTATION_FULFILLED';
const PUT_TRANSPORTATION_PENDING = 'PUT_TRANSPORTATION_PENDING';
const PUT_TRANSPORTATION_REJECTED = 'PUT_TRANSPORTATION_REJECTED';

//GET TRANSPORTATION CATAGORIES
const GET_TRANSPORTATION_CATAGORIES = 'GET_TRANSPORTATION_CATAGORIES';
const GET_TRANSPORTATION_CATAGORIES_FULFILLED =
  'GET_TRANSPORTATION_CATAGORIES_FULFILLED';
const GET_TRANSPORTATION_CATAGORIES_PENDING =
  'GET_TRANSPORTATION_CATAGORIES_PENDING';
const GET_TRANSPORTATION_CATAGORIES_REJECTED =
  'GET_TRANSPORTATION_CATAGORIES_REJECTED';

const GET_TEMPLATE_TRANSPORTATION_PROFILE_DATA =
  'GET_TEMPLATE_TRANSPORTATION_PROFILE_DATA';
const GET_TEMPLATE_TRANSPORTATION_PROFILE_DATA_FULFILLED =
  'GET_TEMPLATE_TRANSPORTATION_PROFILE_DATA_FULFILLED';
const GET_TEMPLATE_TRANSPORTATION_PROFILE_DATA_PENDING =
  'GET_TEMPLATE_TRANSPORTATION_PROFILE_DATA_PENDING';
const GET_TEMPLATE_TRANSPORTATION_PROFILE_DATA_REJECTED =
  'GET_TEMPLATE_TRANSPORTATION_PROFILE_DATA_REJECTED';

//GET_EXPORT_TRANSPORTATION_PROFILE
const GET_EXPORT_TRANSPORTATION_PROFILE = 'GET_EXPORT_TRANSPORTATION_PROFILE';
const GET_EXPORT_TRANSPORTATION_PROFILE_FULFILLED =
  'GET_EXPORT_TRANSPORTATION_PROFILE_FULFILLED';
const GET_EXPORT_TRANSPORTATION_PROFILE_PENDING =
  'GET_EXPORT_TRANSPORTATION_PROFILE_PENDING';
const GET_EXPORT_TRANSPORTATION_PROFILE_REJECTED =
  'GET_EXPORT_TRANSPORTATION_PROFILE_REJECTED';

export {
  POST_UPLOAD_IMAGE_TRANSPORTATION_PROFILE,
  POST_UPLOAD_IMAGE_TRANSPORTATION_PROFILE_FULFILLED,
  POST_UPLOAD_IMAGE_TRANSPORTATION_PROFILE_PENDING,
  POST_UPLOAD_IMAGE_TRANSPORTATION_PROFILE_REJECTED,
  GET_TRANSPORTATION_BY_PROFILE_ID,
  GET_TRANSPORTATION_BY_PROFILE_ID_FULFILLED,
  GET_TRANSPORTATION_BY_PROFILE_ID_PENDING,
  GET_TRANSPORTATION_BY_PROFILE_ID_REJECTED,
  DELETE_TRANSPORTATION_BY_PROFILE_ID,
  DELETE_TRANSPORTATION_BY_PROFILE_ID_FULFILLED,
  DELETE_TRANSPORTATION_BY_PROFILE_ID_PENDING,
  DELETE_TRANSPORTATION_BY_PROFILE_ID_REJECTED,
  POST_TRANSPORTATION,
  POST_TRANSPORTATION_FULFILLED,
  POST_TRANSPORTATION_PENDING,
  POST_TRANSPORTATION_REJECTED,
  GET_TRANSPORTATION_UNIT_BY_PROFILE_ID,
  GET_TRANSPORTATION_UNIT_BY_PROFILE_ID_FULFILLED,
  GET_TRANSPORTATION_UNIT_BY_PROFILE_ID_PENDING,
  GET_TRANSPORTATION_UNIT_BY_PROFILE_ID_REJECTED,
  PUT_TRANSPORTATION,
  PUT_TRANSPORTATION_FULFILLED,
  PUT_TRANSPORTATION_PENDING,
  PUT_TRANSPORTATION_REJECTED,
  GET_TRANSPORTATION_CATAGORIES,
  GET_TRANSPORTATION_CATAGORIES_FULFILLED,
  GET_TRANSPORTATION_CATAGORIES_PENDING,
  GET_TRANSPORTATION_CATAGORIES_REJECTED,
  GET_TEMPLATE_TRANSPORTATION_PROFILE_DATA,
  GET_TEMPLATE_TRANSPORTATION_PROFILE_DATA_FULFILLED,
  GET_TEMPLATE_TRANSPORTATION_PROFILE_DATA_PENDING,
  GET_TEMPLATE_TRANSPORTATION_PROFILE_DATA_REJECTED,
  GET_EXPORT_TRANSPORTATION_PROFILE,
  GET_EXPORT_TRANSPORTATION_PROFILE_FULFILLED,
  GET_EXPORT_TRANSPORTATION_PROFILE_PENDING,
  GET_EXPORT_TRANSPORTATION_PROFILE_REJECTED,
};
