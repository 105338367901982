import React from 'react';
import { Segment, Grid, Image, Container } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import InputBase64 from '../input/inputBase64';

const panelChooseImage = props => {
  return (
    <Segment>
      <InputBase64
        multiple={true}
        accept="image/jpg, image/jpeg, image/png"
        onDone={props.handleChange}
      />
      <Container
        content="*maximum image size 4MB"
        style={{ color: 'grey', fontStyle: 'italic' }}
      />
      <Grid column={3}>
        <Grid.Row width={16}>
          {props.image.map((img, i) => {
            return (
              <Grid.Column
                key={i}
                width={5}
                style={{ marginBottom: '2em', marginTop: '2em' }}
              >
                <Image
                  key={i}
                  size="medium"
                  src={img.ImageUrl ? img.ImageUrl : img.ImagePreview}
                  style={{ maxHeight: '250px' }}
                />
              </Grid.Column>
            );
          })}
        </Grid.Row>
      </Grid>
    </Segment>
  );
};
panelChooseImage.propTypes = {
  handleChange: PropTypes.func,
  image: PropTypes.array,
};
export default panelChooseImage;
