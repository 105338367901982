import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Form } from 'semantic-ui-react';
import InputTime from '../../../../components/input/inputTime';
import { Styles } from '../../Styles';
const RestaurantOperationForm = props => {
  return (
    <div>
      <Grid columns="equal">
        <Grid.Row stretched>
          <Grid.Column width={15}>
            <Header as="h3" content="Opening Hour" />
            <Form>
              <Form.Group>
                <Form.Field>
                  <InputTime
                    labelText="Open Hour"
                    labelStyles={Styles.LabelStyle}
                    inputStyles={Styles.InputStyle}
                    value={
                      !props.dataRestaurantProfile.OperationStartTime ||
                      props.dataRestaurantProfile.OperationStartTime ===
                        'Invalid date'
                        ? '11:00'
                        : props.dataRestaurantProfile.OperationStartTime
                    }
                    setTime={props.setStartTime}
                    disable={props.disablePage}
                    name="OperationStartTime"
                  />
                </Form.Field>
                <Form.Field>
                  <InputTime
                    labelText="Close Hour"
                    labelStyles={Styles.LabelStyle}
                    inputStyles={Styles.InputStyle}
                    value={
                      !props.dataRestaurantProfile.OperationEndTime ||
                      props.dataRestaurantProfile.OperationEndTime ===
                        'Invalid date'
                        ? '21:00'
                        : props.dataRestaurantProfile.OperationEndTime
                    }
                    setTime={props.setEndTime}
                    disable={props.disablePage}
                    name="OperationEndTime"
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

RestaurantOperationForm.propTypes = {
  handleChange: PropTypes.func,
  dataRestaurantProfile: PropTypes.object,
  errors: PropTypes.shape({
    OperationStartTimeError: PropTypes.string,
    OperationEndTimeError: PropTypes.string,
  }),
  setStartTime: PropTypes.func,
  setEndTime: PropTypes.func,
  disablePage: PropTypes.bool,
  defaultStartTime: PropTypes.string,
  defaultEndTime: PropTypes.string,
};
export default RestaurantOperationForm;
