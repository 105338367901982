import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Container, Image } from 'semantic-ui-react';
import moment from 'moment';
import imageWait from '../../../../assets/Icons/spinner_orange.png';
import styles from '../../styles';

const SegmentManualConfirm = props => {
  const { data } = props;
  return (
    <React.Fragment>
      <Grid.Row>
        <Grid.Column width={4}>
          <Header
            as="h3"
            //            icon="check circle outline"
            content={`Request ID: ${data.RequestItemId}`}
            color="black"
          />
          {/* <Container
            style={{ fontSize: '16px', fontWeight: 'bold' }}
            content={`Request Item ID: ${data.RequestItemId}`}
          /> */}
          <Container
            content={`Request On: ${moment(data.OrderDate).format(
              'D MMM YYYY'
            )} `}
          />
        </Grid.Column>
        <Grid.Column width={6}>
          <Header as="h3" color="yellow">
            <Image size="mini" circular src={imageWait} /> Waiting for
            confirmation
          </Header>
          <Container
            style={styles.colorRed}
            content={`Must confirm before: ${
              data.ExpiredDate
                ? moment(data.ExpiredDate).format('D MMM YYYY')
                : '  -'
            }`}
          />
        </Grid.Column>
      </Grid.Row>
    </React.Fragment>
  );
};

SegmentManualConfirm.propTypes = {
  data: PropTypes.object,
  status: PropTypes.string,
};

export default React.memo(SegmentManualConfirm);
