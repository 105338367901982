import moment from 'moment';
import { convertDateFormat } from '../../../scripts/moment';

const filterItem = (item, dateRange) => {
  // filter (dateRange)      ||--------------------------------------------------||

  // case 1          ||------------------||
  // case 2                                  ||------------------||
  // case 3                                                           ||------------------||
  // case 4        ||------------------------------------------------------------------------||

  if (
    // case 1
    (moment(item.StartDate).isSameOrBefore(dateRange.from) &&
      moment(item.StartDate).isSameOrBefore(dateRange.to) &&
      moment(item.EndDate).isSameOrAfter(dateRange.from) &&
      moment(item.EndDate).isSameOrBefore(dateRange.to)) ||
    // case 2
    (moment(item.StartDate).isSameOrAfter(dateRange.from) &&
      moment(item.StartDate).isSameOrBefore(dateRange.to) &&
      moment(item.EndDate).isSameOrAfter(dateRange.from) &&
      moment(item.EndDate).isSameOrBefore(dateRange.to)) ||
    // case 3
    (moment(item.StartDate).isSameOrAfter(dateRange.from) &&
      moment(item.StartDate).isSameOrBefore(dateRange.to) &&
      moment(item.EndDate).isSameOrAfter(dateRange.from) &&
      moment(item.EndDate).isSameOrAfter(dateRange.to)) ||
    // case 4
    (moment(item.StartDate).isSameOrBefore(dateRange.from) &&
      moment(item.StartDate).isSameOrBefore(dateRange.to) &&
      moment(item.EndDate).isSameOrAfter(dateRange.from) &&
      moment(item.EndDate).isSameOrAfter(dateRange.to))
  ) {
    return true;
  } else return false;
};

const filterByDateRange = (data, dateRange) => {
  let dateRangeFrom = dateRange.from
    ? convertDateFormat(dateRange.from, 'YYYY-MM-DD')
    : '';
  let dateRangeTo = dateRange.to
    ? convertDateFormat(dateRange.to, 'YYYY-MM-DD')
    : '';
  let newDateRange = {
    from: dateRangeFrom,
    to: dateRangeTo,
  };
  let newData =
    dateRange !== ''
      ? data.filter(item => {
          let startdate = convertDateFormat(item.StartDate, 'YYYY-MM-DD');
          let enddate = convertDateFormat(item.EndDate, 'YYYY-MM-DD');
          let newItem = {
            StartDate: startdate,
            EndDate: enddate,
          };
          return filterItem(newItem, newDateRange);
        })
      : data;
  return newData;
};

export { filterByDateRange };
