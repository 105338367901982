import {
  GET_GENERATE_RESOURCE_BOOKING,
  GET_DOWNLOAD_RESOURCE_BOOKING_DETAIL,
  PUT_RESOURCE_BOOKING_DETAIL_UPDATE,
  GET_RESOURCE_BOOKING_WITH_SUMMARY,
  GET_DOWNLAOD_RESOURCE_BOOKING_DETAIL_DOCUMENT,
  DELETE_RESOURCE_BOOKING_COLLECTION,
  GET_RESOURCE_LIST,
  POST_RESOURCE,
  PUT_RESOURCE,
  GET_RESOURCE_DETAIL_BY_ID,
  GET_RESOURCE_BOOKING_RESERVATION_BY_RESOURCE_ID,
  POST_RESOURCE_BOOKING_RESERVATION,
  PUT_RESOURCE_BOOKING_RESERVATION,
  DELETE_RESOURCE_BOOKING_RESERVATION,
  POST_RESOURCE_BOOKING_RESERVATION_PAYMENT,
  PUT_RESOURCE_BOOKING_RESERVATION_PAYMENT,
  PUT_READY_TO_USE_QTY,
  PUT_PAY_RESOURCE_BOOKING,
  SAVE_START_DATE_END_DATE,
  POST_CANCEL_RESOURCE,
  GET_RESOURCE_TYPE_ENUM,
  SAVE_DATA_CANCEL_RESOURCE,
  SAVE_ALLOTMENT,
  RESET_DOWNLOAD_RESOURCE_BOOKING_DETAIL,
  RESET_STATUS_RESOURCE,
  RESET_DOWNLOAD_RESOURCE_DETAIL,
  GET_RESOURCE_BOOKING_BY_TRANSACTION_CODE,
} from '../constants/actionTypes';
import {
  getGenerateResouceBookingApi,
  getDownloadResourceBookingDetailApi,
  putResourceBookingDetailUpdateApi,
  getResourceBookingWithSummaryApi,
  getDownloadResouceBookingDetailDocumentApi,
  deleteResourceBookingCollectionApi,
  getResourceListApi,
  postResourceApi,
  putResourceApi,
  getResourceDetailByIdApi,
  getResourceBookingReservationByResourceIdApi,
  postResourceBookingReservationApi,
  putResourceBookingReservationApi,
  deleteResourceBookingReservationApi,
  postResourceBookingReservationPaymentApi,
  putResourceBookingReservationPaymentApi,
  putPayResourceBookingApi,
  putReadyToUseQtyApi,
  postCancelResourceApi,
  getResourceTypeEunumApi,
} from './../../../api/resourceBookingApi';
import { getResourceBookingByTourTransactionCodeApi } from '../../../api/resourceBooking/resourceBookingApi';
const getGenerateResouceBookingAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_GENERATE_RESOURCE_BOOKING,
      payload: getGenerateResouceBookingApi(Id),
    });
  };
};
const getDownloadResourceBookingDetail = id => {
  return dispatch => {
    return dispatch({
      type: GET_DOWNLOAD_RESOURCE_BOOKING_DETAIL,
      payload: getDownloadResourceBookingDetailApi(id),
    });
  };
};
const putResourceBookingDetailUpdate = id => {
  return dispatch => {
    return dispatch({
      type: PUT_RESOURCE_BOOKING_DETAIL_UPDATE,
      payload: putResourceBookingDetailUpdateApi(id),
    });
  };
};
const getResourceBookingWithSummary = id => {
  return dispatch => {
    return dispatch({
      type: GET_RESOURCE_BOOKING_WITH_SUMMARY,
      payload: getResourceBookingWithSummaryApi(id),
    });
  };
};

const getDownloadResouceBookingDetailDocument = id => {
  return dispatch => {
    return dispatch({
      type: GET_DOWNLAOD_RESOURCE_BOOKING_DETAIL_DOCUMENT,
      payload: getDownloadResouceBookingDetailDocumentApi(id),
    });
  };
};
const deleteResourceBookingCollection = id => {
  return dispatch => {
    return dispatch({
      type: DELETE_RESOURCE_BOOKING_COLLECTION,
      payload: deleteResourceBookingCollectionApi(id),
    });
  };
};
const getResourceList = id => {
  return dispatch => {
    return dispatch({
      type: GET_RESOURCE_LIST,
      payload: getResourceListApi(id),
    });
  };
};

const postResource = param => {
  return dispatch => {
    return dispatch({
      type: POST_RESOURCE,
      payload: postResourceApi(param),
    });
  };
};
const putResource = param => {
  return dispatch => {
    return dispatch({
      type: PUT_RESOURCE,
      payload: putResourceApi(param),
    });
  };
};

const putReadyToUseQty = param => {
  return dispatch => {
    return dispatch({
      type: PUT_READY_TO_USE_QTY,
      payload: putReadyToUseQtyApi(param),
    });
  };
};
const getResourceDetailById = id => {
  return dispatch => {
    return dispatch({
      type: GET_RESOURCE_DETAIL_BY_ID,
      payload: getResourceDetailByIdApi(id),
    });
  };
};

const getResourceBookingReservationByResourceId = id => {
  return dispatch => {
    return dispatch({
      type: GET_RESOURCE_BOOKING_RESERVATION_BY_RESOURCE_ID,
      payload: getResourceBookingReservationByResourceIdApi(id),
    });
  };
};
const postResourceBookingReservation = param => {
  return dispatch => {
    return dispatch({
      type: POST_RESOURCE_BOOKING_RESERVATION,
      payload: postResourceBookingReservationApi(param),
    });
  };
};
const putResourceBookingReservation = param => {
  return dispatch => {
    return dispatch({
      type: PUT_RESOURCE_BOOKING_RESERVATION,
      payload: putResourceBookingReservationApi(param),
    });
  };
};
const deleteResourceBookingReservation = id => {
  return dispatch => {
    return dispatch({
      type: DELETE_RESOURCE_BOOKING_RESERVATION,
      payload: deleteResourceBookingReservationApi(id),
    });
  };
};
const postResourceBookingReservationPayment = param => {
  return dispatch => {
    return dispatch({
      type: POST_RESOURCE_BOOKING_RESERVATION_PAYMENT,
      payload: postResourceBookingReservationPaymentApi(param),
    });
  };
};

const putResourceBookingReservationPayment = param => {
  return dispatch => {
    return dispatch({
      type: PUT_RESOURCE_BOOKING_RESERVATION_PAYMENT,
      payload: putResourceBookingReservationPaymentApi(param),
    });
  };
};

const putPayResourceBooking = param => {
  return dispatch => {
    return dispatch({
      type: PUT_PAY_RESOURCE_BOOKING,
      payload: putPayResourceBookingApi(param),
    });
  };
};
const saveStartDateEndDate = param => {
  return dispatch => {
    return dispatch({
      type: SAVE_START_DATE_END_DATE,
      data: param,
    });
  };
};
const resetStatusResource = () => {
  return dispatch => {
    return dispatch({
      type: RESET_STATUS_RESOURCE,
    });
  };
};
const saveDataCancelResource = param => {
  return dispatch => {
    return dispatch({
      type: SAVE_DATA_CANCEL_RESOURCE,
      data: param,
    });
  };
};
const saveAllotment = param => {
  return dispatch => {
    return dispatch({
      type: SAVE_ALLOTMENT,
      data: param,
    });
  };
};
const resetDownloadResourceBookingDetail = () => {
  return dispatch => {
    return dispatch({
      type: RESET_DOWNLOAD_RESOURCE_BOOKING_DETAIL,
    });
  };
};
const resetDownloadResourceDetail = () => {
  return dispatch => {
    return dispatch({
      type: RESET_DOWNLOAD_RESOURCE_DETAIL,
    });
  };
};
const postCancelResource = param => {
  return dispatch => {
    return dispatch({
      type: POST_CANCEL_RESOURCE,
      payload: postCancelResourceApi(param),
    });
  };
};

const getResourceTypeEunum = () => {
  return dispatch => {
    return dispatch({
      type: GET_RESOURCE_TYPE_ENUM,
      payload: getResourceTypeEunumApi(),
    });
  };
};
const getResourceBookingByTourTransactionCode = transactionId => {
  return dispatch => {
    return dispatch({
      type: GET_RESOURCE_BOOKING_BY_TRANSACTION_CODE,
      payload: getResourceBookingByTourTransactionCodeApi(transactionId),
    });
  };
};
export {
  putResource,
  getGenerateResouceBookingAction,
  getDownloadResourceBookingDetail,
  putResourceBookingDetailUpdate,
  getResourceBookingWithSummary,
  getDownloadResouceBookingDetailDocument,
  deleteResourceBookingCollection,
  getResourceList,
  postResource,
  getResourceDetailById,
  getResourceBookingReservationByResourceId,
  postResourceBookingReservation,
  putResourceBookingReservation,
  deleteResourceBookingReservation,
  postResourceBookingReservationPayment,
  putReadyToUseQty,
  saveStartDateEndDate,
  putPayResourceBooking,
  putResourceBookingReservationPayment,
  postCancelResource,
  getResourceTypeEunum,
  saveDataCancelResource,
  saveAllotment,
  resetStatusResource,
  resetDownloadResourceBookingDetail,
  resetDownloadResourceDetail,
  getResourceBookingByTourTransactionCode,
};
