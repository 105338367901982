import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import CustomerPick from './SegmentCustomerPick';
import {
  getCustomerProfileListForSalesAction,
  getUserDataByCodeForSalesAction,
  postCreateCustomerOnBehalfAction,
} from '../../../../actions/customerProfileAction';
import { connect } from 'react-redux';
import ModalRegisCompany from '../modal/ModalRegisOnBehalf';
import ModalAlert from '../../../../../../components/modal/modalAlert';
import DetailOnBehalf from './SegmentDetailCustOnBehalf';
import ModalListCompanies from '../modal/ModalCompanies';
import ModalListUser from '../modal/ModalUsers';
class SegmentCustomerOnBehalf extends Component {
  state = {
    openCollapse: true,
    tourOperator: {
      Name: '',
      Telephone: '',
      Email: '',
      ImageUrl: '',
    },
    search: '',
    openModalNewCustomer: false,
    modalAlert: {
      isOpenModalAlert: false,
      messageModalAlert: '',
      headerModalAlert: '',
    },
    openModalCustomer: false,
    openModalUser: false,
  };

  componentDidMount = () => {
    this.props.getCustomerProfileListForSalesAction('Customer');
  };

  handleChangePickCustomerProfile = (e, { value }) => {
    this.props.getUserDataByCodeForSalesAction(value.Code);
    let onBehalf = {
      customerCode: value.Code,
      customerName: value.Name,
      customerTelephone: value.Telephone,
      customerEmail: value.Email,
    };
    this.setState({
      ...this.state,
      onBehalf,
      openModalUser: true,
    });
  };

  handleChoose = (e, { value }) => {
    let onBehalf = {
      ...this.state.onBehalf,
      userId: value.Id,
      userName: value.Username,
      firstName: value.FirstName,
      lastName: value.LastName,
      employeeTelephone: value.Telephone,
      employeeEmail: value.Email,
    };
    this.setState(
      {
        ...this.state,
        openModalCustomer: false,
        openModalUser: false,
      },
      () => this.props.handleOnbehalf(onBehalf)
    );
  };
  handleClickModalCustomer = () => {
    this.setState({
      openModalCustomer: true,
    });
  };
  handleClickNewCustomer = () => {
    this.setState({
      openModalNewCustomer: !this.state.openModalNewCustomer,
    });
  };
  handleCloseModalCustomerOnBehalf = () => {
    this.setState({
      openModalCustomer: false,
      openModalUser: false,
    });
  };

  handleChangeSearch = (e, { name, value }) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  filterUser = (listUser, searchName) => {
    return listUser.filter(e => {
      return e.Username.toLowerCase().indexOf(searchName.toLowerCase());
    });
  };

  simpleRegisterCompany = dataSimpleCompany => {
    this.props
      .postCreateCustomerOnBehalfAction(dataSimpleCompany)
      .then(() => {
        let onBehalf = {
          customerCode: this.props.registerSimpleCustomer.Code,
          customerName: dataSimpleCompany.CompanyName,
          customerTelephone: dataSimpleCompany.CompanyPhone,
          customerEmail: dataSimpleCompany.Email,
          userId: '',
          userName: '',
        };
        this.setState(
          {
            ...this.state,
            openModalNewCustomer: false,
          },
          () => {
            this.props.handleOnbehalf(onBehalf);
            this.props.getCustomerProfileListForSalesAction('Customer');
          }
        );
      })
      .catch(() => {
        let modalAlert = {
          isOpenModalAlert: true,
          messageModalAlert: this.props.errors.ModelState
            ? this.props.errors.ModelState.message0[0]
            : this.props.errors.Message,
          headerModalAlert: 'Failed',
        };
        this.setState({ ...this.state, modalAlert });
      });
  };

  handleModalAlert = () => {
    let modalAlert = {
      isOpenModalAlert: !this.state.modalAlert.isOpenModalAlert,
      messageModalAlert: '',
      headerModalAlert: '',
    };
    this.setState({
      ...this.state,
      modalAlert,
    });
  };

  render() {
    let { modalAlert } = this.state;
    let { currentPackage, isQuotation } = this.props;
    return (
      <Grid>
        {currentPackage.status === 'create' && (
          <CustomerPick
            onBehalf={this.props.onBehalf}
            handleClickModalCustomer={this.handleClickModalCustomer}
            handleClickModaNewCustomer={this.handleClickNewCustomer}
            currentPackage={currentPackage}
            isQuotation={isQuotation}
          />
        )}
        <DetailOnBehalf onBehalf={this.props.onBehalf} />
        <ModalListCompanies
          isOpen={this.state.openModalCustomer}
          onClose={this.handleCloseModalCustomerOnBehalf}
          companies={this.props.listCustomerProfile}
          handleButtonPick={this.handleChangePickCustomerProfile}
        />
        <ModalListUser
          isOpen={this.state.openModalUser}
          onClose={this.handleCloseModalCustomerOnBehalf}
          loading={this.props.loadingGetUserByCode}
          userProfileByCompany={this.props.getUserDataByCode}
          handleButtonPick={this.handleChoose}
        />
        <ModalRegisCompany
          isOpen={this.state.openModalNewCustomer}
          onClose={this.handleClickNewCustomer}
          simpleRegisterCompany={this.simpleRegisterCompany}
          loadingCreateOnBehalf={this.props.loadingCreateOnBehalf}
        />
        <ModalAlert
          openModal={modalAlert.isOpenModalAlert}
          handleClose={this.handleModalAlert}
          header={modalAlert.headerModalAlert}
          content={modalAlert.messageModalAlert}
          nameButton="OK"
        />
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    listCustomerProfile: state.CustomerProfileStore.getCustomerProfileList,
    getTourOperatorByIdStatus:
      state.TourTransactionStore.getTourOperatorByIdStatus,
    getTourOperatorById: state.TourTransactionStore.getTourOperatorById,
    getUserDataByCode: state.CustomerProfileStore.getUserDataByCode,
    loadingGetCustomerProfile:
      state.CustomerProfileStore.loadingGetCustomerProfile,
    loadingGetUserByCode: state.CustomerProfileStore.loadingGetUserByCode,
    loadingCreateOnBehalf: state.CustomerProfileStore.loadingCreateOnBehalf,
    errors: state.CustomerProfileStore.errors,
    registerSimpleCustomer: state.CustomerProfileStore.registerSimpleCustomer,
  };
};

SegmentCustomerOnBehalf.propTypes = {
  supplements: PropTypes.array,
  splitCommision: PropTypes.number,
  printCommision: PropTypes.number,
  commissions: PropTypes.array,
  handleChangeRadio: PropTypes.func,
  getUserDataByCodeForSalesAction: PropTypes.func,
  getCustomerProfileListForSalesAction: PropTypes.func,
  listCustomerProfile: PropTypes.array,
  getUserDataByCode: PropTypes.array,
  loadingGetCustomerProfile: PropTypes.bool,
  loadingGetUserByCode: PropTypes.bool,
  postCreateCustomerOnBehalfAction: PropTypes.func,
  loadingCreateOnBehalf: PropTypes.bool,
  errors: PropTypes.object,
  handleOnbehalf: PropTypes.func,
  onBehalf: PropTypes.object,
  registerSimpleCustomer: PropTypes.object,
  currentPackage: PropTypes.object,
  isQuotation: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  {
    getCustomerProfileListForSalesAction,
    getUserDataByCodeForSalesAction,
    postCreateCustomerOnBehalfAction,
  }
)(SegmentCustomerOnBehalf);
