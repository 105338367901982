//RESET STATUS TRANSPORTATION
const RESET_STATUS_TRANSPORTATION = 'RESET_STATUS_TRANSPORTATION';

//POST PREVIEW TRANSPORTATION_PROFILE
const POST_UPLOAD_PREVIEW_TRANSPORTATION_PROFILE =
  'POST_UPLOAD_PREVIEW_TRANSPORTATION_PROFILE';
const POST_UPLOAD_PREVIEW_TRANSPORTATION_PROFILE_FULFILLED =
  'POST_UPLOAD_PREVIEW_TRANSPORTATION_PROFILE_FULFILLED';
const POST_UPLOAD_PREVIEW_TRANSPORTATION_PROFILE_PENDING =
  'POST_UPLOAD_PREVIEW_TRANSPORTATION_PROFILE_PENDING';
const POST_UPLOAD_PREVIEW_TRANSPORTATION_PROFILE_REJECTED =
  'POST_UPLOAD_PREVIEW_TRANSPORTATION_PROFILE_REJECTED';

//POST COMMIT TRANSPORTATION_PROFILE
const POST_UPLOAD_COMMIT_TRANSPORTATION_PROFILE =
  'POST_UPLOAD_COMMIT_TRANSPORTATION_PROFILE';
const POST_UPLOAD_COMMIT_TRANSPORTATION_PROFILE_FULFILLED =
  'POST_UPLOAD_COMMIT_TRANSPORTATION_PROFILE_FULFILLED';
const POST_UPLOAD_COMMIT_TRANSPORTATION_PROFILE_PENDING =
  'POST_UPLOAD_COMMIT_TRANSPORTATION_PROFILE_PENDING';
const POST_UPLOAD_COMMIT_TRANSPORTATION_PROFILE_REJECTED =
  'POST_UPLOAD_COMMIT_TRANSPORTATION_PROFILE_REJECTED';

//POST PREVIEW TRANSPORTATION_UNIT
const POST_UPLOAD_PREVIEW_TRANSPORTATION_UNIT =
  'POST_UPLOAD_PREVIEW_TRANSPORTATION_UNIT';
const POST_UPLOAD_PREVIEW_TRANSPORTATION_UNIT_FULFILLED =
  'POST_UPLOAD_PREVIEW_TRANSPORTATION_UNIT_FULFILLED';
const POST_UPLOAD_PREVIEW_TRANSPORTATION_UNIT_PENDING =
  'POST_UPLOAD_PREVIEW_TRANSPORTATION_UNIT_PENDING';
const POST_UPLOAD_PREVIEW_TRANSPORTATION_UNIT_REJECTED =
  'POST_UPLOAD_PREVIEW_TRANSPORTATION_UNIT_REJECTED';

//POST COMMIT TRANSPORTATION_UNIT
const POST_UPLOAD_COMMIT_TRANSPORTATION_UNIT =
  'POST_UPLOAD_COMMIT_TRANSPORTATION_UNIT';
const POST_UPLOAD_COMMIT_TRANSPORTATION_UNIT_FULFILLED =
  'POST_UPLOAD_COMMIT_TRANSPORTATION_UNIT_FULFILLED';
const POST_UPLOAD_COMMIT_TRANSPORTATION_UNIT_PENDING =
  'POST_UPLOAD_COMMIT_TRANSPORTATION_UNIT_PENDING';
const POST_UPLOAD_COMMIT_TRANSPORTATION_UNIT_REJECTED =
  'POST_UPLOAD_COMMIT_TRANSPORTATION_UNIT_REJECTED';

const GET_LANGUAGE_ALL = 'GET_LANGUAGE_ALL';
const GET_LANGUAGE_ALL_PENDING = 'GET_LANGUAGE_ALL_PENDING';
const GET_LANGUAGE_ALL_FULFILLED = 'GET_LANGUAGE_ALL_FULFILLED';
const GET_LANGUAGE_ALL_REJECTED = 'GET_LANGUAGE_ALL_REJECTED';

export {
  RESET_STATUS_TRANSPORTATION,
  POST_UPLOAD_PREVIEW_TRANSPORTATION_PROFILE,
  POST_UPLOAD_PREVIEW_TRANSPORTATION_PROFILE_FULFILLED,
  POST_UPLOAD_PREVIEW_TRANSPORTATION_PROFILE_PENDING,
  POST_UPLOAD_PREVIEW_TRANSPORTATION_PROFILE_REJECTED,
  POST_UPLOAD_COMMIT_TRANSPORTATION_PROFILE,
  POST_UPLOAD_COMMIT_TRANSPORTATION_PROFILE_FULFILLED,
  POST_UPLOAD_COMMIT_TRANSPORTATION_PROFILE_PENDING,
  POST_UPLOAD_COMMIT_TRANSPORTATION_PROFILE_REJECTED,
  POST_UPLOAD_PREVIEW_TRANSPORTATION_UNIT,
  POST_UPLOAD_PREVIEW_TRANSPORTATION_UNIT_FULFILLED,
  POST_UPLOAD_PREVIEW_TRANSPORTATION_UNIT_PENDING,
  POST_UPLOAD_PREVIEW_TRANSPORTATION_UNIT_REJECTED,
  POST_UPLOAD_COMMIT_TRANSPORTATION_UNIT,
  POST_UPLOAD_COMMIT_TRANSPORTATION_UNIT_FULFILLED,
  POST_UPLOAD_COMMIT_TRANSPORTATION_UNIT_PENDING,
  POST_UPLOAD_COMMIT_TRANSPORTATION_UNIT_REJECTED,
  GET_LANGUAGE_ALL,
  GET_LANGUAGE_ALL_PENDING,
  GET_LANGUAGE_ALL_FULFILLED,
  GET_LANGUAGE_ALL_REJECTED,
};
