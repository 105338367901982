const COLOR_WHITE = '#fefefe';
const COLOR_RED = '#f44336';
const Styles = {
  inputSelectionStyle: {
    // border: 'none',
    // borderBottom: '1px solid grey',
    // borderRadius: '0px',
  },
  inputTextStyle: {
    // padding: '.638500em 1em',
    // border: 'none',
    // borderBottom: '1px solid grey',
  },
  segmentNoPadding: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    margin: '5px 0px 20px 0px',
  },
  noBorder: {
    border: 'none',
  },
  buttonDelete: {
    padding: '15px',
    color: COLOR_RED,
    background: COLOR_WHITE,
  },
  marginContentInsideCard: {
    margin: '1em',
  },
  marginHeader: {
    marginTop: '10px',
    marginBottom: '10px',
  },
};

export default Styles;
