import apiClient from './apiClient';
import { GET_RESTAURANT_ESTIMATE_PRICE } from './apiUrl';

const getRestaurantEstimatePriceApi = param => {
  return apiClient.post(
    `${GET_RESTAURANT_ESTIMATE_PRICE}?CityId=${param.CityId}&RatingIds=${param.RatingIds}&LocationIds=${param.LocationIds}&SpecializationId=${param.SpecializationId}&MenuClassId=${param.MenuClassId}&FacilityIds=${param.FacilityIds}&RequestedDate=${param.RequestedDate}&Pax=${param.Pax}`,
    param.bodyParam
  );
};

export { getRestaurantEstimatePriceApi };
