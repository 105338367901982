const style = {
  marginTop30: { marginTop: '30px' },
  marginTopForbutton: {
    marginTop: '1.65em',
  },
  noPaddingLeft: {
    paddingLeft: '0px',
  },
  marginRight14: { marginRight: '14px' },
  textRight: { textAlign: 'right' },
  marginTop20: { marginTop: '20px' },
};
export default style;
