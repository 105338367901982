import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  Button,
  Dropdown,
  Form,
  Loader,
  // Input,
} from 'semantic-ui-react';
import { headerTableOriginCountry } from '../../constants/HeaderTable';
import InputCurrencyFormat from '../../../../components/input/inputCurrencyFormat';

const TableOriginCountry = props => {
  const {
    listData,
    countryList,
    countryOptionsList,
    currencyList,
    loading,
    handleChangeInputTable,
    handleChangeInputAmount,
    handleDelete,
    disable,
  } = props;
  return (
    <Table selectable>
      <Table.Header>
        <Table.Row>
          {headerTableOriginCountry.map((header, index) => {
            return (
              <Table.HeaderCell
                key={index}
                textAlign={header === 'Action' ? 'center' : ''}
              >
                {header}
              </Table.HeaderCell>
            );
          })}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {loading ? (
          <Table.Row>
            <Table.Cell colSpan="4" textAlign="center">
              {loading ? <Loader active inline="centered" /> : 'No Record Data'}
            </Table.Cell>
          </Table.Row>
        ) : listData ? (
          listData.map((item, idx) => {
            return (
              <Table.Row key={idx}>
                <Table.Cell width={5}>
                  <Form>
                    <Form.Field>
                      <Dropdown
                        idx={idx}
                        name="OriginalCountryId"
                        placeholder="select country"
                        options={item.Value ? countryList : countryOptionsList}
                        fluid
                        selection
                        search
                        value={item.Value}
                        loading={loading}
                        onChange={handleChangeInputTable}
                        disabled={disable || item.Value === 'DEF'}
                      />
                    </Form.Field>
                  </Form>
                </Table.Cell>
                <Table.Cell width={3}>
                  <Form>
                    <Form.Field>
                      <Dropdown
                        idx={idx}
                        name="Currency"
                        placeholder="currency"
                        options={currencyList}
                        fluid
                        selection
                        search
                        value={item.Currency}
                        loading={loading}
                        onChange={handleChangeInputTable}
                        disabled={disable}
                      />
                    </Form.Field>
                  </Form>
                </Table.Cell>
                <Table.Cell width={5}>
                  {/* <Input
                    idx={idx}
                    name="Amount"
                    type="number"
                    fluid
                    value={item.Cost}
                    onChange={handleChangeInputTable}
                  /> */}
                  <Form>
                    <Form.Field>
                      <InputCurrencyFormat
                        value={item.Cost}
                        name="Amount"
                        index={idx}
                        disabled={disable}
                        handelChangePrice={handleChangeInputAmount}
                      />
                    </Form.Field>
                  </Form>
                </Table.Cell>
                <Table.Cell width={3} textAlign="center">
                  {item.Value === 'DEF' || (
                    <Button
                      idx={idx}
                      content="Delete"
                      negative
                      onClick={handleDelete}
                      disabled={disable}
                    />
                  )}
                </Table.Cell>
              </Table.Row>
            );
          })
        ) : (
          <Table.Row>
            <Table.Cell colSpan={4} textAlign="center">
              No Record Data
            </Table.Cell>
          </Table.Row>
        )}
        {}
      </Table.Body>
    </Table>
  );
};

TableOriginCountry.propTypes = {
  listData: PropTypes.array,
  countryList: PropTypes.array,
  countryOptionsList: PropTypes.array,
  currencyList: PropTypes.array,
  loading: PropTypes.bool,
  handleChangeInputTable: PropTypes.func,
  handleChangeInputAmount: PropTypes.func,
  handleDelete: PropTypes.func,
  disable: PropTypes.bool,
};

export default TableOriginCountry;
