import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const LoaderModal = props => (
  <Dimmer active={props.loading} inverted>
    <Loader inverted active={props.loading} inline="centered" />
  </Dimmer>
);
LoaderModal.propTypes = {
  loading: PropTypes.bool,
};
export default LoaderModal;
