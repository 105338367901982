import React from 'react';
import { Table, Button, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Pagination from '../../shared/components/common/pagination/Pagination';
import { headerTablePaymentRequest } from '../constants/headerTable';

const TablePaymentRequest = props => {
  const {
    activePage,
    data,
    pagedData,
    listData,
    numbersFrom,
    numbersTo,
    pageChange,
    handleOpenPay,
    handleDetail,
  } = props;

  return (
    <Table basic="very" selectable>
      <Table.Header>
        <Table.Row>
          {headerTablePaymentRequest.map((headTableContent, idx) => {
            return (
              <Table.HeaderCell
                textAlign={headTableContent === 'Action' ? 'center' : ''}
                key={idx}
                style={{ maxWidth: '90px' }}
              >
                {headTableContent}
              </Table.HeaderCell>
            );
          })}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {listData ? (
          listData.map((item, idx) => {
            return (
              <Table.Row key={idx}>
                <Table.Cell>{item.Id}</Table.Cell>
                <Table.Cell>{item.ResouceBookingCode}</Table.Cell>
                <Table.Cell>{item.PackageNo}</Table.Cell>
                <Table.Cell>
                  {item.PackageType === 'FixedDateVariable'
                    ? 'Ready Fixed Price'
                    : item.PackageType === 'Fixed'
                    ? 'Series'
                    : item.PackageType}
                </Table.Cell>
                <Table.Cell>
                  {item.PaymentStatus === 'UNPAID'
                    ? 'Unpaid'
                    : item.PaymentStatus === 'PARTIALPAYMENT'
                    ? 'Partial Payment'
                    : item.PaymentStatus === 'FULLPAYMENT'
                    ? 'Full Payment'
                    : item.PaymentStatus === 'CANCELED'
                    ? 'Canceled'
                    : null}
                </Table.Cell>
                <Table.Cell>{item.OwnerShip.Text}</Table.Cell>
                <Table.Cell>
                  {item.Currency +
                    ' ' +
                    new Intl.NumberFormat('de-DE').format(item.Value)}
                </Table.Cell>
                <Table.Cell>{item.Date}</Table.Cell>
                <Table.Cell>{item.DueDate}</Table.Cell>
                <Table.Cell>
                  {item.Priority === 'LOW'
                    ? 'Low'
                    : item.Priority === 'MEDIUM'
                    ? 'Medium'
                    : item.Priority === 'HIGH'
                    ? 'High'
                    : null}
                </Table.Cell>
                <Table.Cell>
                  <Grid columns="equal" textAlign="center">
                    <Grid.Column>
                      <Button
                        //name={item.OwnerShip}
                        id={item.Id}
                        content={
                          item.PaymentStatus === 'FULLPAYMENT' ? 'Paid' : 'Pay'
                        }
                        disabled={item.PaymentStatus === 'FULLPAYMENT'}
                        onClick={handleOpenPay}
                        primary
                      />
                      <Link to={`/Request-Payment-Detail/${item.Id}`}>
                        <Button
                          id={item.Id}
                          // content={item.Qty}
                          onClick={handleDetail}
                          primary
                          style={{ marginTop: '5px' }}
                        >
                          Detail
                        </Button>
                      </Link>
                    </Grid.Column>
                  </Grid>
                </Table.Cell>
              </Table.Row>
            );
          })
        ) : (
          <Table.Row>
            <Table.Cell colSpan="12" textAlign="center">
              No Data
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
      <Table.Row>
        <Table.HeaderCell colSpan="12">
          <Pagination
            pagedData={pagedData}
            numbersTo={numbersTo}
            numbersFrom={numbersFrom}
            data={data}
            pageChange={pageChange}
            activePage={activePage}
          />
        </Table.HeaderCell>
      </Table.Row>
    </Table>
  );
};
TablePaymentRequest.propTypes = {
  activePage: PropTypes.number,
  pagedData: PropTypes.array,
  listData: PropTypes.array,
  numbersFrom: PropTypes.number,
  numbersTo: PropTypes.number,
  pageChange: PropTypes.func,
  data: PropTypes.array,
  handleOpenPay: PropTypes.func,
  handleDetail: PropTypes.func,
};
export default React.memo(TablePaymentRequest);
