import React from 'react';
import { Dimmer, Confirm } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { getCityAction, resetCityAction } from '../shared/actions/cityAction';
import {
  ResetCityPageAction,
  DeleteCityAction,
  GetCityByIdAction,
  PostCityAction,
  PutCityAction,
} from './cityAction';
import ModalAlert from '../../components/modal/modalAlert';
import Loader from '../../components/loader';
import CityList from './components/CityListComponent';
import PropTypes from 'prop-types';
import { getRegionListAction } from '../shared/actions/regionAction';
import { getCountryListAction } from '../shared/actions/countryAction';
import ModalCity from './components/modal/ModalCity';
import ModalConfirm from '../../components/modal/modalConfirmationTwoButton';
import { setCityState } from './services/data/setDataState';
class CityPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cityList: [],
      cityById: {
        Id: '',
        ImageContent: '',
        ImageName: '',
        Name: '',
        RegionId: '',
        UTC: '',
        CountryId: '',
      },
      loadingCity: true,
      failedCity: false,
      modalOpen: false,
      modalCity: false,
      modalConfirmation: '',
      error: {},
      openConfirm: false,
      loadingModal: false,
    };
  }
  static propTypes = {
    getCityAction: PropTypes.func,
    ResetCityPageAction: PropTypes.func,
    resetCityAction: PropTypes.func,
    getCityStatus: PropTypes.string,
    getCity: PropTypes.array,
    listCountry: PropTypes.array,
    listRegion: PropTypes.array,
    DeleteCityAction: PropTypes.func,
    GetCityByIdAction: PropTypes.func,
    PostCityAction: PropTypes.func,
    PutCityAction: PropTypes.func,
    getCityByIdStatus: PropTypes.string,
    postCityStatus: PropTypes.string,
    putCityStatus: PropTypes.string,
    deleteCityStatus: PropTypes.string,
    getCityById: PropTypes.array,
    getRegionListAction: PropTypes.func,
    getCountryListAction: PropTypes.func,
    errorMessage: PropTypes.string,
    errorPageMessage: PropTypes.string,
    loading: PropTypes.bool,
    loadingPage: PropTypes.bool,
  };

  handleOpen = () => this.setState({ modalOpen: true });
  handleClose = () =>
    this.setState({
      modalOpen: false,
      modalConfirmation: false,
      failedCity: false,
    });
  handleCloseModal = () => this.setState({ modalCity: false });
  handleCloseConfirm = () =>
    this.setState({ openConfirm: false, loadingCity: false });
  componentDidMount = () => {
    this.props.getCityAction();
    this.props.getCountryListAction();
  };
  onChange = (e, { value, name }) => {
    const cityById = this.state.cityById;
    cityById[name] = value;
    this.setState({ cityById: cityById });
    const getRegion = name === 'CountryId';
    if (getRegion) {
      this.props.getRegionListAction(value);
    }
    this.validate();
  };
  componentDidUpdate() {
    let errorMessage = this.props.errorMessage
      ? this.props.errorMessage
      : this.props.errorPageMessage;
    if (this.props.getCityStatus === 'success') {
      this.setState({
        cityList: this.props.getCity,
        loadingCity: false,
      });
      this.props.ResetCityPageAction();
      this.props.resetCityAction();
    } else if (this.props.getCityStatus === 'failed') {
      this.setState({
        loadingCity: false,
        failedCity: true,
        headerAlert: 'Failed',
        contentAlert: errorMessage,
      });
      this.props.ResetCityPageAction();
      this.props.resetCityAction();
    }
    if (this.props.getCityByIdStatus === 'success') {
      this.setStateCity(this.props.getCityById);
      this.setState({
        loadingModal: false,
      });
      this.props.ResetCityPageAction();
      this.props.resetCityAction();
    } else if (this.props.getCityByIdStatus === 'failed') {
      this.props.ResetCityPageAction();
      this.props.resetCityAction();
    }
    if (this.props.postCityStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Saving City Succesfully',
        modalConfirmation: true,
        loadingModal: false,
        modalCity: false,
      });
      this.props.ResetCityPageAction();
      this.props.resetCityAction();
      this.props.getCityAction();
    } else if (this.props.postCityStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: errorMessage,
        modalConfirmation: true,
        loadingModal: false,
      });
      this.props.ResetCityPageAction();
      this.props.resetCityAction();
      this.props.getCityAction();
    }
    if (this.props.putCityStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Edit City Succesfully',
        modalConfirmation: true,
        loadingModal: false,
        modalCity: false,
      });
      this.props.ResetCityPageAction();
      this.props.resetCityAction();
      this.props.getCityAction();
    } else if (this.props.putCityStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: errorMessage,
        modalConfirmation: true,
        loadingModal: false,
      });
      this.props.ResetCityPageAction();
      this.props.resetCityAction();
      this.props.getCityAction();
    }
    if (this.props.deleteCityStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Delete City Succesfully',
        modalConfirmation: true,
        openConfirm: false,
      });
      this.props.ResetCityPageAction();
      this.props.resetCityAction();
      this.props.getCityAction();
    } else if (this.props.deleteCityStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: errorMessage,
        modalConfirmation: true,
        openConfirm: false,
      });
      this.props.ResetCityPageAction();
      this.props.resetCityAction();
      this.props.getCityAction();
    }
  }

  setStateCity = data => {
    const { Country } = data;
    let cityById = setCityState(data);
    this.props.getRegionListAction(Country.Id ? Country.Id : '');
    this.setState({ cityById: cityById, loadingModal: false });
  };
  newCity = () => {
    this.setState({
      modalCity: true,
      headerModalCity: ' Add New City',
      cityById: {
        Id: '',
        ImageContent: '',
        ImageName: '',
        Name: '',
        RegionId: '',
        UTC: '',
        CountryId: '',
        ImagePreview: '',
      },
      error: {},
    });
    this.props.getRegionListAction();
  };
  editCity = e => {
    this.setState({
      modalCity: true,
      headerModalCity: ' Edit City',
      error: {},
      cityById: {
        Id: '',
        ImageContent: '',
        ImageName: '',
        Name: '',
        RegionId: '',
        UTC: '',
        CountryId: '',
        ImagePreview: '',
      },
      loadingModal: true,
    });
    this.props
      .GetCityByIdAction(e.target.value)
      .then(res => {
        this.setStateCity(res.value.data);
      })
      .catch(() => {
        this.setState({ loadingModal: false });
      });
  };

  deleteCity = (e, { value, name }) => {
    this.setState({
      openConfirm: true,
      cityId: value,
      contentConfirm: 'Are you sure want to delete ' + name + '?',
    });
  };
  handleConfirm = () => {
    const { cityId } = this.state;
    this.props.DeleteCityAction(cityId);
    this.setState({
      openConfirm: false,
    });
  };

  saveCity = () => {
    const err = this.validate();
    if (!err) {
      const Id = this.state.cityById.Id;
      const data = this.state.cityById;
      if (Id === '') {
        this.props.PostCityAction(data);
      } else {
        this.props.PutCityAction(Id, data);
      }
      this.setState({ loadingModal: true });
    }
  };

  validate = () => {
    let isError = false;
    const errors = {
      Name: { error: false, message: '' },
      RegionId: { error: false, message: '' },
      UTC: { error: false, message: '' },
      CountryId: { error: false, message: '' },
    };
    if (this.state.cityById.Name.length < 1) {
      isError = true;
      errors.Name.error = true;
      errors.Name.message = "City Name Can't Be Empty";
    }
    if (this.state.cityById.RegionId.length < 1) {
      isError = true;
      errors.RegionId.error = true;
      errors.RegionId.message = "Region Can't Be Empty";
    }
    if (this.state.cityById.UTC.length < 1) {
      isError = true;
      errors.UTC.error = true;
      errors.UTC.message = "UTC Can't Be Empty";
    }
    if (this.state.cityById.CountryId.length < 1) {
      isError = true;
      errors.CountryId.error = true;
      errors.CountryId.message = "Country Can't Be Empty";
    }
    this.setState({
      error: {
        ...errors,
      },
    });
    return isError;
  };
  handleChangeImage = e => {
    let city = this.state.cityById;
    let reader = new FileReader();
    let files = e.target.files[0];
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      let image = {
        ...city,
        ImageName: files.name,
        ImageContent: reader.result.split(',')[1],
        ImagePreview: reader.result,
      };
      this.setState({
        cityById: image,
      });
    };
  };
  render() {
    let { loadingCity, failedCity, modalConfirmation } = this.state;
    let loading = loadingCity || this.props.loading || this.props.loadingPage;
    let openModal = failedCity || modalConfirmation;
    return (
      <div>
        <Dimmer.Dimmable blurring dimmed={loading}>
          <Loader loading={loading} />
          <CityList
            data={this.state.cityList}
            type="cityList"
            filterBy="Name"
            buttonNew={this.newCity}
            butttonEdit={this.editCity}
            buttonDelete={this.deleteCity}
          />
          <ModalAlert
            openModal={openModal}
            handleClose={this.handleClose}
            header={this.state.headerAlert}
            content={this.state.contentAlert}
            nameButton="OK"
          />
          <ModalCity
            open={this.state.modalCity}
            handleClose={this.handleCloseModal}
            header={this.state.headerModalCity}
            listCountry={this.props.listCountry}
            listRegion={this.props.listRegion}
            handleChange={this.onChange}
            contentField={this.state.cityById}
            saveCity={this.saveCity}
            errorInformation={this.state.error}
            loading={this.state.loadingModal}
            handleChangeImage={this.handleChangeImage}
          />
          <Confirm
            dimmer="blurring"
            open={this.state.openConfirm}
            onCancel={this.handleCloseConfirm}
            onConfirm={this.handleConfirm}
            content={this.state.contentConfirm}
            size="mini"
          />
          <ModalConfirm
            openModal={this.state.openConfirm}
            size="mini"
            close={this.handleCloseConfirm}
            confirm={this.handleConfirm}
            message={this.state.contentConfirm}
            header="Confirmation"
          />
        </Dimmer.Dimmable>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    getCity: state.CityStore.getCity,
    getCityStatus: state.CityStore.getCityStatus,
    listCountry: state.CountryStore.getCountryList.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    listRegion: state.RegionStore.getRegionList.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    getCityById: state.CityPageStore.getCityById,
    getCityByIdStatus: state.CityStore.getCityByIdStatus,
    postCityStatus: state.CityPageStore.postCityStatus,
    putCityStatus: state.CityPageStore.putCityStatus,
    deleteCityStatus: state.CityPageStore.deleteCityStatus,
    errorMessage: state.CityStore.errors.global,
    errorPageMessage: state.CityStore.errors.global,
    loading: state.CityPageStore.loading,
    loadingPage: state.CityPageStore.loading,
  };
}
export default connect(
  mapStateToProps,
  {
    getCityAction,
    ResetCityPageAction,
    resetCityAction,
    getRegionListAction,
    getCountryListAction,
    DeleteCityAction,
    GetCityByIdAction,
    PostCityAction,
    PutCityAction,
  }
)(CityPage);
