import {
  RESET_CITY_GROUP,
  GET_CITY_GROUP_LIST,
  DELETE_CITY_GROUP,
  GET_CITY_GROUP_BY_ID,
  POST_CITY_GROUP,
  PUT_CITY_GROUP,
  POST_CITY_GROUP_DETAIL,
  POST_CITY_GROUP_DETAIL_FULLADD,
} from './constant/actionTypes';
import { GET_CITY_IN_COUNTRY } from '../../actions/actionTypes';
import deleteCityGroupApi from '../../api/cityGroup/deleteCityGroupApi';
import getCityGroupByIdApi from '../../api/cityGroup/getCityGroupByIdApi';
import getCityGroupListApi from '../../api/cityGroup/getCityGroupListApi';
import postCityGroupApi from '../../api/cityGroup/postCityGroupApi';
import putCityGroupApi from '../../api/cityGroup/putCityGroupApi';
import getCityInCountryApi from '../../api/city/getCityInCountryApi';
import postCityGroupDetailApi from '../../api/cityGroup/postCityGroupDetailApi';
import postCitiGroupDetailFullAddApi from '../../api/cityGroup/postCitiGroupDetailFullAddApi';

const resetCityGroupAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_CITY_GROUP,
    });
  };
};

const getCityGroupAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_CITY_GROUP_LIST,
      payload: getCityGroupListApi(),
    });
  };
};

const deleteCityGroupAction = Id => {
  return dispatch => {
    return dispatch({
      type: DELETE_CITY_GROUP,
      payload: deleteCityGroupApi(Id),
    });
  };
};

const getCityGroupByIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_CITY_GROUP_BY_ID,
      payload: getCityGroupByIdApi(Id),
    });
  };
};

const postCityGroupAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_CITY_GROUP,
      payload: postCityGroupApi(data),
    });
  };
};

const putCityGroupAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_CITY_GROUP,
      payload: putCityGroupApi(id, data),
    });
  };
};

const getCityInCountryAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_CITY_IN_COUNTRY,
      payload: getCityInCountryApi(Id),
    });
  };
};

const postCityGroupDetailAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_CITY_GROUP_DETAIL,
      payload: postCityGroupDetailApi(data),
    });
  };
};

const postCityGroupDetailFullAddAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: POST_CITY_GROUP_DETAIL_FULLADD,
      payload: postCitiGroupDetailFullAddApi(id, data),
    });
  };
};

export {
  resetCityGroupAction,
  getCityGroupAction,
  deleteCityGroupAction,
  postCityGroupAction,
  putCityGroupAction,
  getCityGroupByIdAction,
  getCityInCountryAction,
  postCityGroupDetailAction,
  postCityGroupDetailFullAddAction,
};
