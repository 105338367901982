export const searchFilterAdditionalServices = (data, searchInput) => {
  // console.log(data);
  return data.filter(item => {
    return (
      (item.AdditionalServicesName !== null &&
        item.AdditionalServicesName.toLowerCase().indexOf(
          searchInput.toLowerCase()
        ) !== -1) ||
      (item.Description !== null &&
        item.Description.toLowerCase().indexOf(searchInput.toLowerCase()) !==
          -1) ||
      (item.DestinationCountry.Text !== null &&
        item.DestinationCountry.Text.toLowerCase().indexOf(
          searchInput.toLowerCase()
        ) !== -1) ||
      (item.AdditionalServiceType.TypeName !== null &&
        item.AdditionalServiceType.TypeName.toLowerCase().indexOf(
          searchInput.toLowerCase()
        ) !== -1)
    );
  });
};
