import * as types from '../actions/actionTypes';

const initialState = {
  loading: false,
  listTransaction: [],
  listTransactionStatus: '',
  listTransactionByStatusOnhold: [],
  listTransactionByStatusComplete: [],
  listTransactionByStatusDownPayment: [],
  listTourType: [],
  loadingGetTourType: false,
  listTourPaxType: [],
  listGroupType: [],
  transactionByStatusOnHold: '',
  transactionByStatusDownpayment: '',
  transactionByStatusComplete: '',
  listTransactionByPeriode: [],
  listTransactionByPeriodeStatus: '',
  listTransactionDetailsStatus: '',
  listTransactionDetails: [],
  listAdditionalPayable: [],
  getTransactionPayableStatus: '',
  postDemoManualPaymentStatus: '',
  postDemoManualPayment: [],
  postManualPaymentStatus: '',
  postManualPayment: [],
  getPayableSupplemetItemStatus: '',
  getPayableSupplemetItem: [],
  getTransactionPaymentTermByTransactionId: [],
  getTransactionPaymentTermByTransactionIdStatus: '',
  errors: '',
  getInvoiceStatusUsingPaymentGateway: '',
  postTransactionConfirmation: [],
  postTransactionConfirmationStatus: '',
  getSpecialAdjusmentByTransactionId: [],
  getSpecialAdjusmentByTransactionIdStatus: '',
  getTourTransactionBilled: [],
  getTourTransactionBilledStatus: '',
  getTourTransactionBills: [],
  getTourTransactionBillsStatus: '',
  //======
  getTransactionAllByStatusAfterDP: [],
  getTransactionAllByStatusAfterDPStatus: '',
  getTransactionAllByStatusOnHold: [],
  getTransactionAllByStatusOnHoldStatus: '',
  getTransactionAllByStatusCancel: [],
  getTransactionAllByStatusCancelStatus: '',
  getTransactionAllByStatusComplete: [],
  getTransactionAllByStatusCompleteStatus: '',
  getTransactionAllByStatusCreated: [],
  getTransactionAllByStatusCreatedStatus: '',
  transactionAllByStatus: [],
  getTransactionAllByStatusStatus: '',
  postSpecialAdjusment: [],
  postSpecialAdjusmentStatus: '',
  getScheduleTourTransaction: '',
  getScheduleTourTransactionStatus: '',
  getInvoiceByTransactionIdStatus: '',
  getInvoiceByTransactionId: [],
  getPrintPaymentReceiptStatus: '',
  getPrintPaymentReceipt: [],
  getInvoiceByCompanyCodeStatus: '',
  getInvoiceByCompanyCode: [],
  postAcceptQuotationStatus: '',
  postAcceptQuotation: [],
  postEditQuotationStatus: '',
  postEditQuotation: [],
  supplementItemByFixedPackageId: [],
  getSupplementItemByFixedPackageIdStatus: '',
  joinTourOnBeHalf: {},
  postJoinTourOnBeHalfStatus: '',
  loadingJoinTourOnBeHalf: false,
  loadingHistory: false,
  getTourOperatorByIdStatus: '',
  getTourOperatorById: [],
  loadingTourOperator: [],
  getTourTransactionsCommissionPayment: [],
  getTourTransactionsCommissionPaymentStatus: '',
  // ========== tour operator ==========
  loadingListTourOperator: false,
  getListTourOperatorStatus: '',
  listTourOperator: [],
  // ========== end tour operator ==========
  postCreateTourTransaction: [],
  postCreateTourTransactionStatus: '',
  demoPrice: {},
  loadingPostDemoPrice: false,
  demoJoinTour: {},
  loadingPostDemoJoinTour: false,
  postDemoJoinTourStatus: '',
  // ========== resend data tour transaction ==========
  postResendDataTourTransactionStatus: '',
  loadingResendDataTourTransaction: false,
  // ========== end resend data tour transaction ==========
  doValidation: false,
  isError: true,
  loadingGetTransactionInvoiceWithStatus: false,
  listTransactionInvoiceWithStatus: [],
  loadingPostCommission: false,
  getDownloadScheduleStatus: '',
  getDownloadSchedule: {},
  editSpecialAdjusmentStatus: '',
  editSpecialAdjusment: {},
  postAdditionalItems: [],
  postAdditionalItemsStatus: '',
  loadingSendEmail: false,
  listTransactionHistoryByTransaction: [],
  postDemoPriceStatus: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TRANSACTION:
      return { ...state };
    case types.GET_TRANSACTION_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
        },
        loading: false,
        listTransactionStatus: 'failed',
      };
    case types.GET_TRANSACTION_PENDING:
      return { ...state, loading: true };
    case types.GET_TRANSACTION_FULFILLED:
      return {
        ...state,
        listTransaction: action.payload.data,
        loading: false,
        listTransactionStatus: 'success',
      };
    case types.GET_TRANSACTION_BY_ID:
      return { ...state };
    case types.GET_TRANSACTION_BY_ID_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
        },
        loading: false,
        listTransactionDetailsStatus: 'failed',
      };
    case types.GET_TRANSACTION_BY_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_TRANSACTION_BY_ID_FULFILLED:
      return {
        ...state,
        listTransactionDetails: action.payload.data,
        loading: false,
        listTransactionDetailsStatus: 'success',
      };
    case types.GET_TRANSACTION_BY_PERIODE:
      return { ...state };
    case types.GET_TRANSACTION_BY_PERIODE_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
        },
        loading: false,
        listTransactionStatus: 'failed',
      };
    case types.GET_TRANSACTION_BY_PERIODE_PENDING:
      return { ...state, loading: true };
    case types.GET_TRANSACTION_BY_PERIODE_FULFILLED:
      return {
        ...state,
        listTransaction: action.payload.data,
        loading: false,
        listTransactionStatus: 'success',
      };
    case types.RESET_STATUS_TRANSACTION:
      return {
        ...state,
        listTransactionStatus: '',
        transactionByStatusOnHold: '',
        transactionByStatusComplete: '',
        transactionByStatusDownpayment: '',
        getTransactionPayableStatus: '',
        listTransactionDetailsStatus: '',
        postDemoManualPaymentStatus: '',
        postManualPaymentStatus: '',
        getPayableSupplemetItemStatus: '',
        getTransactionPaymentTermByTransactionIdStatus: '',
        getInvoiceStatusUsingPaymentGateway: '',
        postTransactionConfirmationStatus: '',
        getSpecialAdjusmentByTransactionIdStatus: '',
        getTourTransactionBillsStatus: '',
        getTourTransactionBilledStatus: '',
        getTransactionAllByStatusAfterDPStatus: '',
        getTransactionAllByStatusOnHoldStatus: '',
        getTransactionAllByStatusCancelStatus: '',
        getTransactionAllByStatusCompleteStatus: '',
        getTransactionAllByStatusCreatedStatus: '',
        getTransactionAllByStatusStatus: '',
        postSpecialAdjusmentStatus: '',
        getScheduleTourTransactionStatus: '',
        getInvoiceByTransactionIdStatus: '',
        getPrintPaymentReceiptStatus: '',
        getInvoiceByCompanyCodeStatus: '',
        postAcceptQuotationStatus: '',
        getTourOperatorByIdStatus: '',
        postCreateTourTransactionStatus: '',
        postResendDataTourTransactionStatus: '',
        getDownloadScheduleStatus: '',
        editSpecialAdjusmentStatus: '',
      };
    case types.GET_TRANSACTION_BY_STATUS_ON_HOLD_LIST:
      return { ...state };
    case types.GET_TRANSACTION_BY_STATUS_ON_HOLD_LIST_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
        },
        loading: false,
        transactionByStatusOnHold: 'failed',
      };
    case types.GET_TRANSACTION_BY_STATUS_ON_HOLD_LIST_PENDING:
      return { ...state, loading: true };
    case types.GET_TRANSACTION_BY_STATUS_ON_HOLD_LIST_FULFILLED:
      return {
        ...state,
        listTransactionByStatusOnhold: action.payload.data,
        loading: false,
        transactionByStatusOnHold: 'success',
      };

    case types.GET_TRANSACTION_BY_STATUS_COMPLETE_LIST:
      return { ...state };
    case types.GET_TRANSACTION_BY_STATUS_COMPLETE_LIST_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
        },
        loading: false,
        transactionByStatusComplete: 'failed',
      };
    case types.GET_TRANSACTION_BY_STATUS_COMPLETE_LIST_PENDING:
      return { ...state, loading: true };
    case types.GET_TRANSACTION_BY_STATUS_COMPLETE_LIST_FULFILLED:
      return {
        ...state,
        listTransactionByStatusComplete: action.payload.data,
        loading: false,
        transactionByStatusComplete: 'success',
      };
    case types.GET_TRANSACTION_BY_STATUS_CREATED_LIST:
      return { ...state };
    case types.GET_TRANSACTION_BY_STATUS_CREATED_LIST_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
        },
        loading: false,
        transactionByStatusDownpayment: 'failed',
      };
    case types.GET_TRANSACTION_BY_STATUS_CREATED_LIST_PENDING:
      return { ...state, loading: true };
    case types.GET_TRANSACTION_BY_STATUS_CREATED_LIST_FULFILLED:
      return {
        ...state,
        listTransactionByStatusDownPayment: action.payload.data,
        loading: false,
        transactionByStatusDownpayment: 'success',
      };
    case types.GET_TRANSACTION_PAYABLE: {
      return { ...state };
    }
    case types.GET_TRANSACTION_PAYABLE_FULFILLED: {
      return {
        ...state,
        loading: false,
        listAdditionalPayable: action.payload.data,
        getTransactionPayableStatus: 'success',
      };
    }
    case types.GET_TRANSACTION_PAYABLE_PENDING: {
      return { ...state, loading: true };
    }
    case types.GET_TRANSACTION_PAYABLE_REJECTED: {
      return {
        ...state,
        loading: false,
        getTransactionPayableStatus: 'success',
      };
    }

    case types.GET_TOUR_PAX_TYPE:
      return { ...state };
    case types.GET_TOUR_PAX_TYPE_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
        },
        loading: false,
      };
    case types.GET_TOUR_PAX_TYPE_PENDING:
      return { ...state, loading: true };
    case types.GET_TOUR_PAX_TYPE_FULFILLED:
      return { ...state, listTourPaxType: action.payload.data, loading: false };

    case types.GET_TOUR_TYPE:
      return { ...state };
    case types.GET_TOUR_TYPE_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
        },
        loading: false,
        loadingGetTourType: false,
      };
    case types.GET_TOUR_TYPE_PENDING:
      return { ...state, loading: true, loadingGetTourType: true };
    case types.GET_TOUR_TYPE_FULFILLED:
      return {
        ...state,
        listTourType: action.payload.data,
        loading: false,
        loadingGetTourType: false,
      };

    case types.GET_GROUP_TYPE_LIST:
      return { ...state };
    case types.GET_GROUP_TYPE_LIST_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
        },
        loading: false,
      };
    case types.GET_GROUP_TYPE_LIST_PENDING:
      return { ...state, loading: true };
    case types.GET_GROUP_TYPE_LIST_FULFILLED:
      return { ...state, listGroupType: action.payload.data, loading: false };

    case types.POST_MANUAL_PAYMENT:
      return { ...state };
    case types.POST_MANUAL_PAYMENT_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
        },
        loading: false,
        postManualPaymentStatus: 'failed',
      };
    case types.POST_MANUAL_PAYMENT_PENDING:
      return { ...state, loading: true };
    case types.POST_MANUAL_PAYMENT_FULFILLED:
      return {
        ...state,
        postManualPaymentStatus: 'success',
        postManualPayment: action.payload.data,
        loading: false,
      };

    case types.POST_DEMO_MANUAL_PAYMENT:
      return { ...state };
    case types.POST_DEMO_MANUAL_PAYMENT_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
        },
        loading: false,
        postDemoManualPaymentStatus: 'failed',
      };
    case types.POST_DEMO_MANUAL_PAYMENT_PENDING:
      return { ...state, loading: true };
    case types.POST_DEMO_MANUAL_PAYMENT_FULFILLED:
      return {
        ...state,
        postDemoManualPaymentStatus: 'success',
        postDemoManualPayment: action.payload.data,
        loading: false,
      };
    case types.GET_PAYABLE_SUPPLEMENT_ITEM:
      return { ...state };
    case types.GET_PAYABLE_SUPPLEMENT_ITEM_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
        },
        loading: false,
        getPayableSupplemetItemStatus: 'failed',
      };
    case types.GET_PAYABLE_SUPPLEMENT_ITEM_PENDING:
      return { ...state, loading: true };
    case types.GET_PAYABLE_SUPPLEMENT_ITEM_FULFILLED:
      return {
        ...state,
        getPayableSupplemetItem: action.payload.data,
        loading: false,
        getPayableSupplemetItemStatus: 'success',
      };

    case types.GET_TRANSACTION_PAYMENT_TERM_BY_TRANSACTION_ID:
      return { ...state };
    case types.GET_TRANSACTION_PAYMENT_TERM_BY_TRANSACTION_ID_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
        },
        loading: false,
        getTransactionPaymentTermByTransactionIdStatus: 'failed',
      };
    case types.GET_TRANSACTION_PAYMENT_TERM_BY_TRANSACTION_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_TRANSACTION_PAYMENT_TERM_BY_TRANSACTION_ID_FULFILLED:
      return {
        ...state,
        getTransactionPaymentTermByTransactionId: action.payload.data,
        loading: false,
        getTransactionPaymentTermByTransactionIdStatus: 'success',
      };

    case types.GET_INVOICE_STATUS_USING_PAYMENT_GATEWAY:
      return { ...state };
    case types.GET_INVOICE_STATUS_USING_PAYMENT_GATEWAY_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
        },
        loading: false,
        getInvoiceStatusUsingPaymentGateway: 'failed',
      };
    case types.GET_INVOICE_STATUS_USING_PAYMENT_GATEWAY_PENDING:
      return { ...state, loading: true };
    case types.GET_INVOICE_STATUS_USING_PAYMENT_GATEWAY_FULFILLED:
      return {
        ...state,
        loading: false,
        getInvoiceStatusUsingPaymentGateway: 'success',
      }; //listTransactionByStatusComplete: action.payload.data,

    case types.POST_TOUR_TRANSACTION_CONFIRMATION:
      return { ...state };
    case types.POST_TOUR_TRANSACTION_CONFIRMATION_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
          message: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
        },
        loading: false,
        postTransactionConfirmationStatus: 'failed',
      };
    case types.POST_TOUR_TRANSACTION_CONFIRMATION_PENDING:
      return { ...state, loading: true };
    case types.POST_TOUR_TRANSACTION_CONFIRMATION_FULFILLED:
      return {
        ...state,
        postTransactionConfirmation: action.payload.data,
        loading: false,
        postTransactionConfirmationStatus: 'success',
      };

    case types.GET_SPECIAL_ADJUSMENT_BY_TRANSACTION_ID:
      return { ...state };
    case types.GET_SPECIAL_ADJUSMENT_BY_TRANSACTION_ID_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
        },
        loading: false,
        getSpecialAdjusmentByTransactionIdStatus: 'failed',
      };
    case types.GET_SPECIAL_ADJUSMENT_BY_TRANSACTION_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_SPECIAL_ADJUSMENT_BY_TRANSACTION_ID_FULFILLED:
      return {
        ...state,
        getSpecialAdjusmentByTransactionId: action.payload.data,
        loading: false,
        getSpecialAdjusmentByTransactionIdStatus: 'success',
      };

    case types.GET_TOUR_TRANSACTION_BILLED_BY_TRANSACTION_ID:
      return { ...state };
    case types.GET_TOUR_TRANSACTION_BILLED_BY_TRANSACTION_ID_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
        },
        loading: false,
        getTourTransactionBilledStatus: 'failed',
      };
    case types.GET_TOUR_TRANSACTION_BILLED_BY_TRANSACTION_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_TOUR_TRANSACTION_BILLED_BY_TRANSACTION_ID_FULFILLED:
      return {
        ...state,
        getTourTransactionBilled: action.payload.data,
        loading: false,
        getTourTransactionBilledStatus: 'success',
      };

    case types.GET_TOUR_TRANSACTION_BILLS_BY_TRANSACTION_ID:
      return { ...state };
    case types.GET_TOUR_TRANSACTION_BILLS_BY_TRANSACTION_ID_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
        },
        loading: false,
        getTourTransactionBillsStatus: 'failed',
      };
    case types.GET_TOUR_TRANSACTION_BILLS_BY_TRANSACTION_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_TOUR_TRANSACTION_BILLS_BY_TRANSACTION_ID_FULFILLED:
      return {
        ...state,
        getTourTransactionBills: action.payload.data,
        loading: false,
        getTourTransactionBillsStatus: 'success',
      };
    //==========================================
    case types.GET_TRANSACTION_ALL_BY_STATUS_AFTER_DP_LIST:
      return { ...state };
    case types.GET_TRANSACTION_ALL_BY_STATUS_AFTER_DP_LIST_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
        },
        loading: false,
        getTransactionAllByStatusAfterDPStatus: 'failed',
      };
    case types.GET_TRANSACTION_ALL_BY_STATUS_AFTER_DP_LIST_PENDING:
      return { ...state, loading: true };
    case types.GET_TRANSACTION_ALL_BY_STATUS_AFTER_DP_LIST_FULFILLED:
      return {
        ...state,
        getTransactionAllByStatusAfterDP: action.payload.data,
        loading: false,
        getTransactionAllByStatusAfterDPStatus: 'success',
      };
    case types.GET_TRANSACTION_ALL_BY_STATUS_ON_HOLD_LIST:
      return { ...state };
    case types.GET_TRANSACTION_ALL_BY_STATUS_ON_HOLD_LIST_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
        },
        loading: false,
        getTransactionAllByStatusOnHoldStatus: 'failed',
      };
    case types.GET_TRANSACTION_ALL_BY_STATUS_ON_HOLD_LIST_PENDING:
      return { ...state, loading: true };
    case types.GET_TRANSACTION_ALL_BY_STATUS_ON_HOLD_LIST_FULFILLED:
      return {
        ...state,
        getTransactionAllByStatusOnHold: action.payload.data,
        loading: false,
        getTransactionAllByStatusOnHoldStatus: 'success',
      };
    case types.GET_TRANSACTION_ALL_BY_STATUS_CANCEL_LIST:
      return { ...state };
    case types.GET_TRANSACTION_ALL_BY_STATUS_CANCEL_LIST_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
        },
        loading: false,
        getTransactionAllByStatusCancelStatus: 'failed',
      };
    case types.GET_TRANSACTION_ALL_BY_STATUS_CANCEL_LIST_PENDING:
      return { ...state, loading: true };
    case types.GET_TRANSACTION_ALL_BY_STATUS_CANCEL_LIST_FULFILLED:
      return {
        ...state,
        getTransactionAllByStatusCancel: action.payload.data,
        loading: false,
        getTransactionAllByStatusCancelStatus: 'success',
      };

    case types.GET_TRANSACTION_ALL_BY_STATUS_COMPLETE_LIST:
      return { ...state };
    case types.GET_TRANSACTION_ALL_BY_STATUS_COMPLETE_LIST_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
        },
        loading: false,
        getTransactionAllByStatusCompleteStatus: 'failed',
      };
    case types.GET_TRANSACTION_ALL_BY_STATUS_COMPLETE_LIST_PENDING:
      return { ...state, loading: true };
    case types.GET_TRANSACTION_ALL_BY_STATUS_COMPLETE_LIST_FULFILLED:
      return {
        ...state,
        getTransactionAllByStatusComplete: action.payload.data,
        loading: false,
        getTransactionAllByStatusCompleteStatus: 'success',
      };

    case types.GET_TRANSACTION_ALL_BY_STATUS_CREATED_LIST:
      return { ...state };
    case types.GET_TRANSACTION_ALL_BY_STATUS_CREATED_LIST_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
        },
        loading: false,
        getTransactionAllByStatusCreatedStatus: 'failed',
      };
    case types.GET_TRANSACTION_ALL_BY_STATUS_CREATED_LIST_PENDING:
      return { ...state, loading: true };
    case types.GET_TRANSACTION_ALL_BY_STATUS_CREATED_LIST_FULFILLED:
      return {
        ...state,
        getTransactionAllByStatusCreated: action.payload.data,
        loading: false,
        getTransactionAllByStatusCreatedStatus: 'success',
      };

    case types.GET_TRANSACTION_ALL_BY_STATUS:
      return { ...state };
    case types.GET_TRANSACTION_ALL_BY_STATUS_PENDING:
      return { ...state, loadingHistory: true };
    case types.GET_TRANSACTION_ALL_BY_STATUS_REJECTED:
      return {
        ...state,
        transactionAllByStatus: [],
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
        },
        loadingHistory: false,
        getTransactionAllByStatusStatus: 'failed',
      };
    case types.GET_TRANSACTION_ALL_BY_STATUS_FULFILLED:
      return {
        ...state,
        transactionAllByStatus: action.payload.data,
        loadingHistory: false,
        getTransactionAllByStatusStatus: 'success',
      };

    case types.POST_SPECIAL_ADJUSMENT:
      return { ...state };
    case types.POST_SPECIAL_ADJUSMENT_REJECTED:
      return {
        ...state,
        errors: { message: action.payload.response.data.Message },
        loading: false,
        postSpecialAdjusmentStatus: 'failed',
      };
    case types.POST_SPECIAL_ADJUSMENT_PENDING:
      return { ...state, loading: true };
    case types.POST_SPECIAL_ADJUSMENT_FULFILLED:
      return {
        ...state,
        postSpecialAdjusmentStatus: 'success',
        postSpecialAdjusment: action.payload.data,
        loading: false,
      };
    case types.EDIT_SPECIAL_ADJUSMENT:
      return { ...state };
    case types.EDIT_SPECIAL_ADJUSMENT_REJECTED:
      return {
        ...state,
        errors: { message: action.payload.response.data.Message },
        loading: false,
        editSpecialAdjusmentStatus: 'failed',
      };
    case types.EDIT_SPECIAL_ADJUSMENT_PENDING:
      return { ...state, loading: true };
    case types.EDIT_SPECIAL_ADJUSMENT_FULFILLED:
      return {
        ...state,
        editSpecialAdjusmentStatus: 'success',
        editSpecialAdjusment: action.payload.data,
        loading: false,
      };
    case types.GET_SCHEDULE_TOUR_TRANSACTION:
      return { ...state };
    case types.GET_SCHEDULE_TOUR_TRANSACTION_REJECTED:
      return {
        ...state,
        errors: { message: action.payload.response.data.Message },
        loading: false,
        getScheduleTourTransactionStatus: 'failed',
      };
    case types.GET_SCHEDULE_TOUR_TRANSACTION_PENDING:
      return { ...state, loading: true };
    case types.GET_SCHEDULE_TOUR_TRANSACTION_FULFILLED:
      return {
        ...state,
        getScheduleTourTransactionStatus: 'success',
        getScheduleTourTransaction: action.payload.data,
        loading: false,
      };

    case types.GET_INVOICE_BY_TRANSACTION_ID:
      return { ...state };
    case types.GET_INVOICE_BY_TRANSACTION_ID_REJECTED:
      return {
        ...state,
        errors: { message: action.payload.response.data.Message },
        loading: false,
        getInvoiceByTransactionIdStatus: 'failed',
      };
    case types.GET_INVOICE_BY_TRANSACTION_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_INVOICE_BY_TRANSACTION_ID_FULFILLED:
      return {
        ...state,
        getInvoiceByTransactionIdStatus: 'success',
        getInvoiceByTransactionId: action.payload.data,
        loading: false,
      };

    case types.GET_PRINT_PAYMENT_RECEIPT:
      return { ...state };
    case types.GET_PRINT_PAYMENT_RECEIPT_REJECTED:
      return {
        ...state,
        errors: { message: action.payload.response.data.Message },
        loading: false,
        getPrintPaymentReceiptStatus: 'failed',
      };
    case types.GET_PRINT_PAYMENT_RECEIPT_PENDING:
      return { ...state, loading: true };
    case types.GET_PRINT_PAYMENT_RECEIPT_FULFILLED:
      return {
        ...state,
        getPrintPaymentReceiptStatus: 'success',
        getPrintPaymentReceipt: action.payload.data,
        loading: false,
      };

    case types.GET_INVOICE_BY_COMPANY_CODE:
      return { ...state };
    case types.GET_INVOICE_BY_COMPANY_CODE_REJECTED:
      return {
        ...state,
        errors: { message: action.payload.response.data.Message },
        loading: false,
        getInvoiceByCompanyCodeStatus: 'failed',
      };
    case types.GET_INVOICE_BY_COMPANY_CODE_PENDING:
      return { ...state, loading: true };
    case types.GET_INVOICE_BY_COMPANY_CODE_FULFILLED:
      return {
        ...state,
        getInvoiceByCompanyCodeStatus: 'success',
        getInvoiceByCompanyCode: action.payload.data,
        loading: false,
      };

    case types.GET_TOUR_TRANSACTIONS_COMMISSION_PAYMENT:
      return { ...state };
    case types.GET_TOUR_TRANSACTIONS_COMMISSION_PAYMENT_REJECTED:
      return {
        ...state,
        errors: { message: action.payload.response.data.Message },
        loading: false,
        getTourTransactionsCommissionPaymentStatus: 'failed',
      };
    case types.GET_TOUR_TRANSACTIONS_COMMISSION_PAYMENT_PENDING:
      return { ...state, loading: true };
    case types.GET_TOUR_TRANSACTIONS_COMMISSION_PAYMENT_FULFILLED:
      return {
        ...state,
        getTourTransactionsCommissionPaymentStatus: 'success',
        getTourTransactionsCommissionPayment: action.payload.data,
        loading: false,
      };

    case types.POST_EDIT_QUOTATION:
      return { ...state };
    case types.POST_EDIT_QUOTATION_REJECTED:
      return {
        ...state,
        errors: { message: action.payload.response.data.Message },
        loading: false,
        postEditQuotationStatus: 'failed',
      };
    case types.POST_EDIT_QUOTATION_PENDING:
      return { ...state, loading: true };
    case types.POST_EDIT_QUOTATION_FULFILLED:
      return {
        ...state,
        postEditQuotationStatus: 'success',
        postEditQuotation: action.payload.data,
        loading: false,
      };
    case types.POST_ACCEPT_QUOTATION:
      return { ...state };
    case types.POST_ACCEPT_QUOTATION_REJECTED:
      return {
        ...state,
        errors: { message: action.payload.response.data.Message },
        loading: false,
        postAcceptQuotationStatus: 'failed',
      };
    case types.POST_ACCEPT_QUOTATION_PENDING:
      return { ...state, loading: true };
    case types.POST_ACCEPT_QUOTATION_FULFILLED:
      return {
        ...state,
        postAcceptQuotationStatus: 'success',
        postAcceptQuotation: action.payload.data,
        loading: false,
      };
    case types.GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID:
      return { ...state };
    case types.GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID_FULFILLED:
      return {
        ...state,
        getSupplementItemByFixedPackageIdStatus: 'success',
        supplementItemByFixedPackageId: action.payload.data,
        loading: false,
      };
    case types.GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID_REJECTED:
      return {
        ...state,
        loading: false,
        errors: action.payload.response.data,
        supplementItemByFixedPackageId: [],
        getSupplementItemByFixedPackageIdStatus: 'failed',
      };

    case types.GET_TOUR_OPERATOR_PROFILE_BY_ID:
      return { ...state };
    case types.GET_TOUR_OPERATOR_PROFILE_BY_ID_REJECTED:
      return {
        ...state,
        errors: { message: action.payload.response.data.Message },
        loadingTourOperator: false,
        getTourOperatorByIdStatus: 'failed',
      };
    case types.GET_TOUR_OPERATOR_PROFILE_BY_ID_PENDING:
      return { ...state, loadingTourOperator: true };
    case types.GET_TOUR_OPERATOR_PROFILE_BY_ID_FULFILLED:
      return {
        ...state,
        getTourOperatorByIdStatus: 'success',
        getTourOperatorById: action.payload.data,
        loadingTourOperator: false,
      };

    case types.GET_LIST_TOUR_OPERATOR:
      return { ...state };
    case types.GET_LIST_TOUR_OPERATOR_REJECTED:
      return {
        ...state,
        errors: { message: action.payload.response.data.Message },
        loadingListTourOperator: false,
        getListTourOperatorStatus: 'failed',
      };
    case types.GET_LIST_TOUR_OPERATOR_PENDING:
      return { ...state, loadingListTourOperator: true };
    case types.GET_LIST_TOUR_OPERATOR_FULFILLED:
      return {
        ...state,
        getListTourOperatorStatus: 'success',
        listTourOperator: action.payload.data,
        loadingListTourOperator: false,
      };

    case types.POST_JOIN_TOUR_ON_BE_HALF:
      return { ...state };
    case types.POST_JOIN_TOUR_ON_BE_HALF_PENDING:
      return { ...state, loadingJoinTourOnBeHalf: true };
    case types.POST_JOIN_TOUR_ON_BE_HALF_FULFILLED:
      return {
        ...state,
        joinTourOnBeHalf: action.payload.data,
        errors: {},
        loadingJoinTourOnBeHalf: false,
        postJoinTourOnBeHalfStatus: 'success',
      };
    case types.POST_JOIN_TOUR_ON_BE_HALF_REJECTED:
      return {
        ...state,
        joinTourOnBeHalf: {},
        errors: action.payload.response.data,
        loadingJoinTourOnBeHalf: false,
        postJoinTourOnBeHalfStatus: 'failed',
      };
    case types.POST_DEMO_PRICE:
      return { ...state };
    case types.POST_DEMO_PRICE_PENDING:
      return { ...state, loadingPostDemoPrice: true };
    case types.POST_DEMO_PRICE_FULFILLED:
      return {
        ...state,
        demoPrice: action.payload.data,
        errors: {},
        loadingPostDemoPrice: false,
        postDemoPriceStatus: 'success',
      };
    case types.POST_DEMO_PRICE_REJECTED:
      return {
        ...state,
        demoPrice: {},
        errors: action.payload.response.data,
        loadingPostDemoPrice: false,
        postDemoPriceStatus: 'failed',
      };

    case types.POST_CREATE_TOUR_TRANSACTION:
      return { ...state };
    case types.POST_CREATE_TOUR_TRANSACTION_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
        },
        loadingCreateTour: false,
        postCreateTourTransactionStatus: 'failed',
      };
    case types.POST_CREATE_TOUR_TRANSACTION_PENDING:
      return { ...state, loadingCreateTour: true };
    case types.POST_CREATE_TOUR_TRANSACTION_FULFILLED:
      return {
        ...state,
        postCreateTourTransaction: action.payload.data,
        loadingCreateTour: false,
        postCreateTourTransactionStatus: 'success',
      };

    case types.POST_DEMO_JOIN_TOUR:
      return { ...state };
    case types.POST_DEMO_JOIN_TOUR_PENDING:
      return { ...state, loadingPostDemoJoinTour: true };
    case types.POST_DEMO_JOIN_TOUR_FULFILLED:
      return {
        ...state,
        demoJoinTour: action.payload.data,
        errors: {},
        loadingPostDemoJoinTour: false,
        postDemoJoinTourStatus: 'success',
      };
    case types.POST_DEMO_JOIN_TOUR_REJECTED:
      return {
        ...state,
        demoJoinTour: {},
        errors: action.payload.response.data,
        loadingPostDemoJoinTour: false,
        postDemoJoinTourStatus: 'failed',
      };
    case types.GET_PAYMENT_RECEIPT_BY_TRANSACTION_ID:
      return { ...state };
    case types.GET_PAYMENT_RECEIPT_BY_TRANSACTION_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_PAYMENT_RECEIPT_BY_TRANSACTION_ID_FULFILLED:
      return {
        ...state,
        errors: {},
        loading: false,
        getPaymentReceiptByTransactionIdStatus: 'success',
        getPaymentReceiptByTransactionId: action.payload.data,
      };
    case types.GET_PAYMENT_RECEIPT_BY_TRANSACTION_ID_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
        },
        loading: false,
        getPaymentReceiptByTransactionIdStatus: 'failed',
      };
    case types.GET_TRANSACTION_INVOICE_WITH_STATUS:
      return { ...state };
    case types.GET_TRANSACTION_INVOICE_WITH_STATUS_PENDING:
      return { ...state, loadingGetTransactionInvoiceWithStatus: true };
    case types.GET_TRANSACTION_INVOICE_WITH_STATUS_FULFILLED:
      return {
        ...state,
        errors: {},
        loadingGetTransactionInvoiceWithStatus: false,
        listTransactionInvoiceWithStatus: action.payload.data,
      };
    case types.GET_TRANSACTION_INVOICE_WITH_STATUS_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
        },
        loadingGetTransactionInvoiceWithStatus: false,
        listTransactionInvoiceWithStatus: [],
      };
    case types.POST_COMMISSION_PAYMENT:
      return { ...state };
    case types.POST_COMMISSION_PAYMENT_PENDING:
      return { ...state, loadingPostCommission: true };
    case types.POST_COMMISSION_PAYMENT_FULFILLED:
      return {
        ...state,
        errors: {},
        loadingPostCommission: false,
      };
    case types.POST_COMMISSION_PAYMENT_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
        },
        loadingPostCommission: false,
      };

    case types.POST_RESEND_DATA_TOUR_TRANSACTION:
      return { ...state };
    case types.POST_RESEND_DATA_TOUR_TRANSACTION_PENDING:
      return { ...state, loadingResendDataTourTransaction: true };
    case types.POST_RESEND_DATA_TOUR_TRANSACTION_FULFILLED:
      return {
        ...state,
        errors: {},
        loadingResendDataTourTransaction: false,
        postResendDataTourTransactionStatus: 'success',
      };
    case types.POST_RESEND_DATA_TOUR_TRANSACTION_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
        },
        loadingResendDataTourTransaction: false,
        postResendDataTourTransactionStatus: 'failed',
      };

    case types.GET_DOWNLOAD_SCHEDULE_BY_TRANSACTION_ID:
      return { ...state };
    case types.GET_DOWNLOAD_SCHEDULE_BY_TRANSACTION_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_DOWNLOAD_SCHEDULE_BY_TRANSACTION_ID_FULFILLED:
      return {
        ...state,
        errors: {},
        loading: false,
        getDownloadScheduleStatus: 'success',
        getDownloadSchedule: action.payload.data,
      };
    case types.GET_DOWNLOAD_SCHEDULE_BY_TRANSACTION_ID_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
        },
        loading: false,
        getDownloadScheduleStatus: 'failed',
      };
    case types.POST_ADDITIONAL_ITEMS:
      return { ...state };
    case types.POST_ADDITIONAL_ITEMS_PENDING:
      return { ...state, loading: true };
    case types.POST_ADDITIONAL_ITEMS_FULFILLED:
      return {
        ...state,
        errors: {},
        loading: false,
        postAdditionalItemsStatus: 'success',
        postAdditionalItems: action.payload.data,
      };
    case types.POST_ADDITIONAL_ITEMS_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
        },
        loading: false,
        postAdditionalItemsStatus: 'failed',
      };
    case types.RESET_ERROR_MESSAGE:
      return {
        ...state,
        errors: '',
      };
    case types.POST_EMAIL_SEND_CONFIRMATION:
      return { ...state };
    case types.POST_EMAIL_SEND_CONFIRMATION_PENDING:
      return { ...state, loadingSendEmail: true };
    case types.POST_EMAIL_SEND_CONFIRMATION_FULFILLED:
      return {
        ...state,
        loadingSendEmail: false,
      };
    case types.POST_EMAIL_SEND_CONFIRMATION_REJECTED:
      return {
        ...state,
        loadingSendEmail: false,
      };
    case types.GET_TRANSACTION_HISTORY_BY_TRANSACTION:
      return { ...state };
    case types.GET_TRANSACTION_HISTORY_BY_TRANSACTION_PENDING:
      return { ...state, loading: true };
    case types.GET_TRANSACTION_HISTORY_BY_TRANSACTION_FULFILLED:
      return {
        ...state,
        errors: {},
        loading: false,
        listTransactionHistoryByTransaction: action.payload.data,
      };
    case types.GET_TRANSACTION_HISTORY_BY_TRANSACTION_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : action.payload.message,
        },
        loading: false,
        listTransactionHistoryByTransaction: [],
      };
    default:
      return state;
  }
};

export default reducer;
