import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Button } from 'semantic-ui-react';
import TableImageData from '../../pages/shared/components/table/tableImage/TableImages'; //'../table/tableImage';
// import Pagination from '../common/pagination';
const image = props => {
  return (
    <div>
      <Grid
        columns="equal"
        style={{ marginTop: '0.5em' }}
        verticalAlign="middle"
      >
        <Grid.Row>
          <Grid.Column>
            <Header as="h4" content={props.headerContent} />
          </Grid.Column>
          <Grid.Column />
          <Grid.Column floated="right">
            <Button
              positive
              content={props.buttonContent}
              onClick={props.addData}
              floated="right"
              id={props.idButton}
              disabled={props.disablePage}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <TableImageData
              listData={props.listData}
              profileImageList={props.profileImageList}
              pagedData={props.pagedData}
              pageChange={props.pageChange}
              activePage={props.activePage}
              deleteData={props.deleteData}
              headerTable={props.headerTable}
              value={props.idButton}
              openDetail={props.openDetail}
              handleChangePrimaryImage={props.handleChangePrimaryImage}
              disabled={props.disablePage}
              pattern={props.pattern}
              numbersTo={props.numbersTo}
              numbersFrom={props.numbersFrom}
              handleDeleteImages={props.handleDeleteImages}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

image.propTypes = {
  // listImage: PropTypes.array,
  profileImageList: PropTypes.array,
  item: PropTypes.array,
  addData: PropTypes.func,
  buttonContent: PropTypes.string,
  headerContent: PropTypes.string,
  deleteData: PropTypes.func,
  headerTable: PropTypes.array,
  listData: PropTypes.array,
  idButton: PropTypes.string,
  viewDataBy: PropTypes.string,
  openDetail: PropTypes.func,
  handleChangePrimaryImage: PropTypes.func,
  disablePage: PropTypes.bool,
  pattern: PropTypes.string,
  pagedData: PropTypes.array,
  pageChange: PropTypes.func,
  activePage: PropTypes.string,
  numbersFrom: PropTypes.number,
  numbersTo: PropTypes.number,
  handleDeleteImages: PropTypes.func,
};
export default image;
