import React from 'react';
import ModalAlert from '../../components/modal/modalAlert';
//import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { postLoginAction, resetStatusLoginAction } from './loginAction';
import { getUserRoleByBearerAction } from '../../actions/userAccessAction';
import { getUserInfoAction } from '../../actions/userAction';
import { MIN_HEIGTH } from '../../assets/styles/size';
import SegmentLogin from './components/Login';
import PropTypes from 'prop-types';
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login: {
        idCompany: '',
        username: '',
        password: '',
      },
      //      idCompanyErrorText: '',
      usernameErrorText: '',
      passwordErrorText: '',
      //      idCompanyError: false,
      usernameError: false,
      passwordError: false,
      modalOpen: false,
      headerAlert: '',
      contentAlert: '',
      loadingActionLogin: false,
      typePassword: 'password',
      passwordIcon: 'eye slash',
    };
  }

  static propTypes = {
    postLoginAction: PropTypes.func,
    loginStatus: PropTypes.string,
    resetStatusLoginAction: PropTypes.func,
    errorsLogin: PropTypes.string,
    getUserInfoAction: PropTypes.func,
    getUserRoleByBearerAction: PropTypes.func,
    loading: PropTypes.bool,
  };

  componentDidMount = () => {
    // const getToken = localStorage.getItem('tokenadmin');
    // if (getToken) {
    //   this.props.history.push('/');
    // }
    this.isExpired();
  };
  handleChange = e => {
    const field = e.target.name;
    const login = this.state.login;
    login[field] = e.target.value;
    this.setState({ login: login });
    this.validate();
  };
  handleOpen = () =>
    this.setState({
      modalOpen: true,
      contentAlert: this.props.errorsLogin.global,
      headerAlert: 'Failed',
    });
  handleClose = () =>
    this.setState({
      modalOpen: false,
    });
  componentDidUpdate = () => {
    if (
      this.props.loginStatus === 'success' &&
      localStorage.getItem('tokenadmin')
    ) {
      const getToken = localStorage.getItem('tokenadmin');
      const { idCompany } = this.state.login;
      if (getToken) {
        localStorage.setItem('code', idCompany);
        window.location.reload();
        // eslint-disable-next-line
        this.props.history.push('/');
      }
      this.setState({
        modalOpen: true,
        contentAlert: 'Login Successfully',
        headerAlert: 'Success',
        loadingActionLogin: false,
      });
      this.props.resetStatusLoginAction();
    } else if (this.props.loginStatus === 'failed') {
      this.handleOpen();
      this.setState({
        modalOpen: true,
        contentAlert: this.props.errorsLogin.global,
        headerAlert: 'Failed',
        loadingActionLogin: false,
      });
      this.props.resetStatusLoginAction();
    }
  };
  validate = () => {
    let isError = false;
    const errors = {
      //      idCompanyErrorText: '',
      usernameErrorText: '',
      passwordErrorText: '',
      //      idCompanyError: false,
      usernameError: false,
      passwordError: false,
    };
    const message = 'Please fill out this field';
    // if (this.state.login.idCompany.length < 1) {
    //   if (this.state.login.username === 'admin') {
    //     isError = false;
    //     errors.idCompanyError = false;
    //     errors.idCompanyErrorText = '';
    //   } else {
    //     isError = true;
    //     errors.idCompanyError = true;
    //     errors.idCompanyErrorText = message;
    //   }
    // }
    if (this.state.login.username.length < 1) {
      isError = true;
      errors.usernameError = true;
      errors.usernameErrorText = message;
    }
    if (this.state.login.password.length < 1) {
      isError = true;
      errors.passwordError = true;
      errors.passwordErrorText = message;
    }
    this.setState({
      ...this.state,
      ...errors,
    });
    return isError;
  };
  handleClick = () => {
    const err = this.validate();
    this.setState({ loadingActionLogin: true });
    if (!err) {
      this.setState({ loadingActionLogin: true });
      let companyId = this.state.login.username === 'admin' ? '' : '30000';
      const data = {
        CompanyCode: companyId,
        Username: this.state.login.username,
        Password: this.state.login.password,
      };
      this.props.postLoginAction(data);
    }
  };
  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };
  isExpired = () => {
    if (this.isTokenExpired()) {
      this.setState({
        modalOpen: true,
        contentAlert: 'Your Login session has been expired',
        headerAlert: '',
      });
    }
  };
  isTokenExpired = () => {
    const getToken = localStorage.getItem('tokenadmin');
    const expires = new Date(
      localStorage.getItem('expiredtokenadmin')
    ).getTime();
    const today = new Date().getTime();
    if (getToken && today > expires) {
      return true;
    } else {
      return false;
    }
  };

  onClickPassIcon = (e, { name }) => {
    let icon = `${name}Icon`;
    let show = `${name}Show`;
    if (this.state[show]) {
      this.setState({
        [icon]: 'eye slash',
        [show]: false,
        typePassword: 'password',
      });
    } else {
      this.setState({
        [icon]: 'eye',
        [show]: true,
        typePassword: 'text',
      });
    }
  };
  render() {
    const {
      headerAlert,
      contentAlert,
      modalOpen,
      typePassword,
      passwordIcon,
    } = this.state;
    const { loading } = this.props;
    return (
      <div
        style={{ margin: '0px', minHeight: MIN_HEIGTH }}
        className="background-login-image"
      >
        <SegmentLogin
          handleChange={this.handleChange}
          handleClick={this.handleClick}
          errors={this.state}
          loading={loading}
          typePassword={typePassword}
          onClickPassIcon={this.onClickPassIcon}
          passwordIcon={passwordIcon}
        />
        <ModalAlert
          openModal={modalOpen}
          handleClose={this.handleClose}
          header={headerAlert}
          content={contentAlert}
          nameButton="OK"
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    loginStatus: state.LoginStore.loginAdminStatus,
    errorsLogin: state.LoginStore.errors,
    loading: state.LoginStore.loading,
  };
}
export default connect(mapStateToProps, {
  postLoginAction,
  resetStatusLoginAction,
  getUserInfoAction,
  getUserRoleByBearerAction,
})(Login);
