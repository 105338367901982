import React from 'react';
import { Grid, Segment, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import GeneralInformation from '../form/GeneralInformation'; //RestaurantProfile';
import ContactInformation from '../../../shared/components/contactInfo/ContactInformation';
import RestaurantItemList from './RestaurantItemList';
import AddingDataWithTable from '../../../../components/globalListVew/addingDataComponent';
import {
  headerTableAccessibleCity,
  headerTableImageAccomodationProfile,
} from '../constant/tableHeader';
import ImageListComponent from '../../../../components/image/image';
import TimeOperation from '../form/RestaurantOperationForm';
import HeaderTitle from '../../../shared/components/common/headerSegment/HeaderTitle';
import { Styles } from '../../Styles';
const SegmentRestraurantProfile = props => (
  <div>
    <Segment style={Styles.Segment}>
      <Grid columns={2} padded="vertically">
        <Grid.Column>
          <HeaderTitle title={props.title} />
          <GeneralInformation
            dataRestaurantProfile={props.dataRestaurantProfile}
            handleChange={props.handleChange}
            listRestaurantClass={props.listRestaurantClass}
            listRestaurantSpecialty={props.listRestaurantSpecialty}
            errors={props.errors}
            disablePage={props.disablePage}
          />
          <ContactInformation
            contactObject={props.dataRestaurantProfile.AddressObject}
            listCountry={props.listCountry}
            listRegion={props.listRegion}
            listCity={props.listCityByRegionForContactInfo}
            listArea={props.listArea}
            handleChange={props.handleChangeAddress}
            onChangeDropdown={props.handleChangeAddress}
            errors={props.errors}
            disablePage={props.disablePage}
          />
        </Grid.Column>
        <Grid.Column>
          <div style={Styles.SaveBackButton}>
            <Button
              primary
              onClick={props.backPage}
              name={'Back'}
              icon="angle left"
              content="Back"
            />
            <Button
              primary
              onClick={props.saveRestaurant}
              name={props.contentButton}
              content={props.contentButton}
            />
          </div>
          <ImageListComponent
            profileImageList={props.RestaurantProfileImages}
            addData={props.modalItem}
            buttonContent="Add Image"
            headerContent="Image"
            deleteData={props.deleteState}
            headerTable={headerTableImageAccomodationProfile}
            listData={props.listDataImage}
            idButton="Image"
            openDetail={props.openDetail}
            handleChangePrimaryImage={props.handleChangePrimaryImage}
            disablePage={props.disablePage}
            pattern="Restaurants/"
            activePage={props.activePage}
            pagedData={props.pagedData}
            pageChange={props.pageChange}
          />
          <TimeOperation
            handleChange={props.handleChange}
            dataRestaurantProfile={props.dataRestaurantProfile}
            errors={props.errors}
            setEndTime={props.setEndTime}
            setStartTime={props.setStartTime}
            disablePage={props.disablePage}
          />
          <AddingDataWithTable
            addingDataList={props.filterDataAccessCity}
            buttonContent="Add City"
            headerContent="Accessible City"
            headerTable={headerTableAccessibleCity}
            idButton="Accesible City"
            listData={props.dataRestaurantProfile.AccessibleFrom}
            addData={props.addData}
            deleteData={props.deleteData}
            disablePage={props.disablePage}
            listAddingData={props.listDataAccessCity}
            pageAddingData={props.pagedDataAccessCity}
            activePage={props.activePage}
            pageChange={props.pageChange}
          />
        </Grid.Column>
      </Grid>
      <RestaurantItemList
        restaurantItemList={props.restaurantItemList}
        buttonNew={props.newRestaurant}
        buttonUploadData={props.buttonUploadData}
        butttonExport={props.butttonExport}
        buttonDownloadTemplate={props.buttonDownloadTemplate}
        handleClickEdit={props.handleClickEdit}
        idProfile={props.idProfile}
        handleClickDelete={props.handleClickDelete}
        disablePage={props.disablePage}
      />
    </Segment>
  </div>
);

SegmentRestraurantProfile.propTypes = {
  RestaurantProfileImages: PropTypes.array,
  filterDataAccessCity: PropTypes.array,
  dataRestaurantProfile: PropTypes.object,
  handleChange: PropTypes.func,
  listRestaurantClass: PropTypes.array,
  listRestaurantSpecialty: PropTypes.array,
  listRegion: PropTypes.array,
  listCity: PropTypes.array,
  listArea: PropTypes.array,
  listCountry: PropTypes.array,
  restaurantItemList: PropTypes.array,
  newRestaurant: PropTypes.func,
  handleChangeAddress: PropTypes.func,
  saveRestaurant: PropTypes.func,
  title: PropTypes.string,
  errors: PropTypes.shape({
    NameError: PropTypes.string,
    DescriptionError: PropTypes.string,
    LongDescriptionError: PropTypes.string,
    AddressError: PropTypes.string,
    PhoneNbrError: PropTypes.string,
    EmailError: PropTypes.string,
    OperationStartTimeError: PropTypes.string,
    OperationEndTimeError: PropTypes.string,
    CompanyCode: PropTypes.string,
    RestaurantTypeError: PropTypes.string,
    RestaurantSpecialityError: PropTypes.string,
    CityIdError: PropTypes.string,
    RegionIdError: PropTypes.string,
    CountryIdError: PropTypes.string,
    SpecializationIdError: PropTypes.string,
    AreaIdError: PropTypes.string,
  }),
  backPage: PropTypes.func,
  modalItem: PropTypes.func,
  deleteState: PropTypes.func,
  openDetail: PropTypes.func,
  handleChangePrimaryImage: PropTypes.func,
  setStartTime: PropTypes.func,
  setEndTime: PropTypes.func,
  addData: PropTypes.func,
  accessCity: PropTypes.array,
  deleteData: PropTypes.func,
  buttonUploadData: PropTypes.func,
  butttonExport: PropTypes.func,
  buttonDownloadTemplate: PropTypes.func,
  handleClickEdit: PropTypes.func,
  contentButton: PropTypes.string,
  disablePage: PropTypes.bool,
  activePage: PropTypes.string,
  pagedData: PropTypes.array,
  pageChange: PropTypes.func,
  listDataImage: PropTypes.array,
  idProfile: PropTypes.object,
  listDataAccessCity: PropTypes.array,
  pagedDataAccessCity: PropTypes.array,
  handleClickDelete: PropTypes.func,
  listCityByRegionForContactInfo: PropTypes.array,
};
export default SegmentRestraurantProfile;
