import * as types from './constant/actionTypes';

const initialState = {
  loading: false,
  errors: {},
  excurtionTypePrice: [],
  getExcurtionTypePriceStatus: '',
  getExursionObjectExport: '',
  getExursionObjectExportStatus: '',
  getExursionPriceExport: '',
  getExursionPriceExportStatus: '',
  postExcurtion: {},
  postExcurtionStatus: '',
  postUploadImageExcursion: [],
  postUploadImageExcursionStatus: '',
  deleteExcursionObjectStatus: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_EXCURSION_PRICE_TYPE: {
      return { ...state };
    }
    case types.GET_EXCURSION_PRICE_TYPE_PENDING: {
      return { ...state, loading: true };
    }
    case types.GET_EXCURSION_PRICE_TYPE_FULFILLED: {
      return {
        ...state,
        loading: false,
        getExcurtionTypePriceStatus: 'success',
        excurtionTypePrice: action.payload.data,
      };
    }
    case types.GET_EXCURSION_PRICE_TYPE_REJECTED: {
      return {
        ...state,
        loading: false,
        getExcurtionTypePriceStatus: 'failed',
        //errors: { global: action.payload.response.data.Message },
      };
    }

    case types.POST_EXCURSION_PROFILE: {
      return { ...state };
    }
    case types.POST_EXCURSION_PROFILE_FULFILLED: {
      return {
        ...state,
        loading: false,
        postExcurtion: action.payload.data,
        postExcurtionStatus: 'success',
      };
    }
    case types.POST_EXCURSION_PROFILE_PENDING: {
      return { ...state, loading: true };
    }
    case types.POST_EXCURSION_PROFILE_REJECTED: {
      return {
        ...state,
        postExcurtionStatus: 'failed',
        errors: { global: action.payload.response.data.Message },
        loading: false,
      };
    }

    case types.RESET_STATUS_EXCURSION_PAGE: {
      return {
        ...state,
        getExursionObjectExportStatus: '',
        getExursionPriceExportStatus: '',
        postUploadImageExcursionStatus: '',
        postExcurtionStatus: '',
      };
    }

    case types.GET_EXCURSION_OBJECT_EXPORT_DATA:
      return { ...state };
    case types.GET_EXCURSION_OBJECT_EXPORT_DATA_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getExursionObjectExportStatus: 'failed',
      };
    case types.GET_EXCURSION_OBJECT_EXPORT_DATA_PENDING:
      return { ...state, loading: true };
    case types.GET_EXCURSION_OBJECT_EXPORT_DATA_FULFILLED:
      return {
        ...state,
        getExursionObjectExport: action.payload.data,
        loading: false,
        getExursionObjectExportStatus: 'success',
      };

    case types.GET_EXCURSION_PRICE_EXPORT_DATA:
      return { ...state };
    case types.GET_EXCURSION_PRICE_EXPORT_DATA_REJECTED: {
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getExursionPriceExportStatus: 'failed',
      };
    }
    case types.GET_EXCURSION_PRICE_EXPORT_DATA_PENDING: {
      return { ...state, loading: true };
    }
    case types.GET_EXCURSION_PRICE_EXPORT_DATA_FULFILLED: {
      return {
        ...state,
        getExursionPriceExport: action.payload.data,
        loading: false,
        getExursionPriceExportStatus: 'success',
        postExcurtionStatus: '',
      };
    }

    case types.DELETE_EXCURSION_OBJECT:
      return { ...state };
    case types.DELETE_EXCURSION_OBJECT_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        deleteExcursionObjectStatus: 'failed',
        loading: false,
      };
    case types.DELETE_EXCURSION_OBJECT_PENDING:
      return { ...state, loading: true };
    case types.DELETE_EXCURSION_OBJECT_FULFILLED:
      return {
        ...state,
        loading: false,
        deleteExcursionObjectStatus: 'success',
      };

    default:
      return state;
  }
};

export default reducer;
