import React from 'react';
import { Table, Checkbox } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { HeaderTableModalccessibleCity } from '../headerTabel';
import Loader from './../../loader/loaderInModal';
const tableModalAccessibleCity = props => (
  <div>
    <br />
    <Table basic="very" selectable>
      <Table.Header>
        <Table.Row>
          {HeaderTableModalccessibleCity.map((headTableContent, index) => {
            return (
              <Table.HeaderCell
                key={index}
                textAlign={index === 1 ? 'left' : 'center'}
              >
                {headTableContent}
              </Table.HeaderCell>
            );
          })}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {!props.loading && props.listData ? (
          props.listData.map((data, idx) => {
            return (
              <Table.Row key={idx}>
                <Table.Cell textAlign="center">
                  {(props.activePage - 1) * 7 + idx + 1}
                </Table.Cell>
                <Table.Cell>{data.Name}</Table.Cell>
                <Table.Cell textAlign="center">
                  <Checkbox
                    value={data.Id}
                    onChange={props.handleChangeAccessCity}
                    name={data.Id}
                    checked={data.Status}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })
        ) : props.loading ? (
          <Table.Row>
            <Table.Cell colSpan="3" textAlign="center">
              <Loader loading={true} />
            </Table.Cell>
          </Table.Row>
        ) : (
          <Table.Row>
            <Table.Cell colSpan="3" textAlign="center">
              No City
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  </div>
);

tableModalAccessibleCity.propTypes = {
  listData: PropTypes.array,
  deleteState: PropTypes.func,
  handleChangeAccessCity: PropTypes.func,
  loading: PropTypes.bool,
  activePage: PropTypes.number,
};
export default tableModalAccessibleCity;
