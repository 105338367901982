import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Button, Grid, Form, Header } from 'semantic-ui-react';
import Input from '../../../components/input/inputWithLabel';
import ButtonClose from '../../../components/button/buttonCloseModal';
import {
  validationPaymentReq,
  ERRORS_PAYMENT_REQUEST,
} from './../scripts/resourceDetail';
import {
  convertDateFormat,
  dateNow,
  subtractDate,
} from '../../../scripts/moment';
import InputDate from '../../../components/input/inputDate';
import styles from '../Styles';
import { copyObject } from '../../../scripts/itineraryBuilder/itineraryBuilder';
import ModalConfirm from '../../../components/modal/modalConfirmationTwoButton';
import options from '../../../scripts/convertOption';
const LIST_PRIORITY = [
  { key: 'LOW', value: 'LOW', text: 'Low' },
  { key: 'MEDIUM', value: 'MEDIUM', text: 'Medium' },
  { key: 'HIGH', value: 'HIGH', text: 'High' },
];
const ModalPaymentRequest = props => {
  const [paymentRequest, setPaymentRequest] = useState({
    // totalCost: 0,
    // date: convertDateFormat(dateNow, 'YYYY-MM-DD'),
    // note: '',

    Currency: '',
    Date: convertDateFormat(dateNow, 'YYYY-MM-DDThh:mm:ss'),
    DueDate: convertDateFormat(dateNow, 'YYYY-MM-DDThh:mm:ss'),
    Id: '',
    Note: '',
    PaymentStatus: 'UNPAID',
    Value: 0,
    Priority: '',
    isEdit: false,
  });
  const [errors, setErrors] = useState(copyObject(ERRORS_PAYMENT_REQUEST));

  const [modalConfirm, setModalConfirm] = useState({
    isOpen: false,
    message: '',
    header: '',
  });
  const currencies = useSelector(state => {
    let data = state.MasterDataReferenceStore.getMasterReferenceList;
    return data ? data.map(e => options(e.Id, e.Id, e.Description)) : [];
  });
  const loadingGetCurrencies = useSelector(
    state => state.MasterDataReferenceStore.loading
  );
  useEffect(() => {
    if (props.openModal)
      if (props.selectedPaymentReq) {
        setPaymentRequest({
          ...paymentRequest,
          Currency: props.selectedPaymentReq.Currency,
          Date: convertDateFormat(
            props.selectedPaymentReq.Date,
            'YYYY-MM-DDThh:mm:ss'
          ),
          DueDate: convertDateFormat(
            props.selectedPaymentReq.DueDate,
            'YYYY-MM-DDThh:mm:ss'
          ),
          Id: props.selectedPaymentReq.Id,
          Note: props.selectedPaymentReq.Note,
          PaymentStatus: props.selectedPaymentReq.PaymentStatus,
          Value: props.selectedPaymentReq.Value,
          isEdit: true,
          Priority: props.selectedPaymentReq.Priority,
        });
      } else {
        setPaymentRequest({
          ...paymentRequest,
          DueDate: convertDateFormat(
            subtractDate(props.useDate, 7, 'd'),
            'YYYY-MM-DDThh:mm:ss'
          ),
          Currency: props.Currency,
          Value: props.unitCost * props.qtyBookingItem,
        });
      }
    // eslint-disable-next-line
  }, [props.openModal]);
  const resetPaymentRequest = () => {
    setPaymentRequest({
      Currency: '',
      Date: convertDateFormat(dateNow, 'YYYY-MM-DDThh:mm:ss'),
      DueDate: convertDateFormat(dateNow, 'YYYY-MM-DDThh:mm:ss'),
      Id: '',
      Note: '',
      PaymentStatus: 'UNPAID',
      Value: 0,
      Priority: '',
      isEdit: false,
    });
  };
  const handleClose = () => {
    resetPaymentRequest();
    props.handleClose();
  };
  const handleDate = date => {
    setPaymentRequest({ ...paymentRequest, DueDate: date });
  };
  const handlePaymentRequest = (e, { name, value, readOnly }) => {
    if (!readOnly) {
      if (name === 'Value') {
        value = value ? parseInt(value.replace(/\D/g, ''), 10) : 0;
      }
      setPaymentRequest({ ...paymentRequest, [name]: value });
    }
  };
  const handleSavePaymentReq = () => {
    let validation = validationPaymentReq(paymentRequest);
    if (validation.isError) setErrors(validation.errors);
    else {
      openModalConfirm(
        'Confirmation',
        `Are you sure you want to make a payment request?`
      );
    }
  };
  const openModalConfirm = (header, message) => {
    setModalConfirm({
      isOpen: true,
      message,
      header,
    });
  };
  const closeModalConfirm = () => {
    setModalConfirm({
      isOpen: false,
      message: '',
      header: '',
    });
  };
  const handleModalConfirm = () => {
    resetPaymentRequest();
    closeModalConfirm();
    props.handleAddPaymentRequest(paymentRequest);
  };
  let {
    openModal,
    itemName,
    useDate,
    qtyBookingItem,
    supplierName,
    supplierContact,
    supplierEmail,
  } = props;
  return (
    <Modal
      dimmer="blurring"
      inverted
      open={openModal}
      onClose={handleClose}
      size="tiny"
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        Payment Request <ButtonClose onClose={handleClose} />
      </Modal.Header>
      <Modal.Content>
        <Grid.Column width={16}>
          <Header as="h4">General Information</Header>
          <Grid>
            <Grid.Row>
              <Grid.Column width={4}>Item Name</Grid.Column>
              <Grid.Column width={12}>: {itemName}</Grid.Column>
              <Grid.Column width={4}>Qty</Grid.Column>
              <Grid.Column width={12}>: {qtyBookingItem}</Grid.Column>
              <Grid.Column width={4}>Use Date</Grid.Column>
              <Grid.Column width={12}>
                : {convertDateFormat(useDate, 'DD MMM YYYY')}
              </Grid.Column>
              <Grid.Column width={4}>Supplier Name</Grid.Column>
              <Grid.Column width={12}>: {supplierName}</Grid.Column>
              <Grid.Column width={4}>Contact</Grid.Column>
              <Grid.Column width={12}>: {supplierContact}</Grid.Column>
              <Grid.Column width={4}>Email</Grid.Column>
              <Grid.Column width={12}>: {supplierEmail}</Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
        <Form style={styles.paddingTop20}>
          <Input
            type="select"
            label="Currency"
            placeholder="Currency"
            value={paymentRequest.Currency}
            options={currencies}
            name="Currency"
            handleChange={handlePaymentRequest}
            validate={errors}
            loading={loadingGetCurrencies}
            readonly={paymentRequest.isEdit}
          />
          <Form.Group widths="equal">
            <Form.Field>
              <Input
                type="text"
                label="Total Cost"
                placeholder="Total Cost"
                value={
                  paymentRequest.Value
                    ? paymentRequest.Value.toLocaleString('id')
                    : 0
                }
                name="Value"
                handleChange={handlePaymentRequest}
                validate={errors}
                readonly={paymentRequest.isEdit}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <InputDate
                label="Due Date"
                name="DueDate"
                value={paymentRequest.DueDate}
                setDate={handleDate}
                labelStyle={{
                  display: 'block',
                  fontWeight: 'bold',
                  marginBottom: '4px',
                }}
                readonly={false}
              />
            </Form.Field>
          </Form.Group>
          <Input
            type="select"
            label="Priority"
            placeholder="Priority"
            value={paymentRequest.Priority}
            options={LIST_PRIORITY}
            name="Priority"
            handleChange={handlePaymentRequest}
            validate={errors}
          />
          <Form.Group widths="equal">
            <Form.Field>
              <Input
                type="textArea"
                label="Note"
                placeholder="Note"
                value={paymentRequest.Note}
                name="Note"
                handleChange={handlePaymentRequest}
                validate={errors}
              />
            </Form.Field>
          </Form.Group>
        </Form>
        <ModalConfirm
          openModal={modalConfirm.isOpen}
          size="mini"
          close={closeModalConfirm}
          confirm={handleModalConfirm}
          message={modalConfirm.message}
          header={modalConfirm.header}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button positive onClick={handleSavePaymentReq} content="Submit" />
      </Modal.Actions>
    </Modal>
  );
};

ModalPaymentRequest.propTypes = {
  openModal: PropTypes.bool,
  handleClose: PropTypes.func,
  handleAddResource: PropTypes.func,
  handleAddPaymentRequest: PropTypes.func,
  DueDate: PropTypes.string,
  Currency: PropTypes.string,
  itemName: PropTypes.string,
  useDate: PropTypes.string,
  qtyBookingItem: PropTypes.number,
  supplierName: PropTypes.string,
  supplierContact: PropTypes.string,
  supplierEmail: PropTypes.string,
  unitCost: PropTypes.number,
  selectedPaymentReq: PropTypes.object,
};

export default ModalPaymentRequest;
