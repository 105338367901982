import {
  GET_CITY_BY_REGION,
  RESET_CITY,
  GET_CITY_LIST,
  GET_DEST_CITY_BY_REGION,
  GET_ORIGIN_CITY_BY_REGION,
  GET_CITY_IN_COUNTRY,
} from '../constants/actionTypes';
import getCityByRegionApi from '../../../api/city/getCityByRegionApi';
import getCityListApi from '../../../api/city/getCityListApi';
import getCityInCountryApi from '../../../api/city/getCityInCountryAllApi';

const getCityListAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_CITY_BY_REGION,
      payload: getCityByRegionApi(Id),
    });
  };
};
const getDestCityListAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_DEST_CITY_BY_REGION,
      payload: getCityByRegionApi(Id),
    });
  };
};
const getOriginCityListAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_ORIGIN_CITY_BY_REGION,
      payload: getCityByRegionApi(Id),
    });
  };
};
const resetCityAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_CITY,
    });
  };
};

const getCityAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_CITY_LIST,
      payload: getCityListApi(),
    });
  };
};

const getCityInCountry = () => {
  return dispatch => {
    return dispatch({
      type: GET_CITY_IN_COUNTRY,
      payload: getCityInCountryApi(),
    });
  };
};
export {
  getCityListAction,
  resetCityAction,
  getCityAction,
  getDestCityListAction,
  getOriginCityListAction,
  getCityInCountry,
};
