const existingPriceValidation = (list, price) => {
  let isExist = list.find(
    data =>
      data.ServicePriceType === price.ServicePriceType &&
      data.StartDate === price.StartDate &&
      data.EndDate === price.EndDate &&
      data.RankType === price.RankType
  );
  return isExist ? true : false;
};

const minPaxGroupping = list => {
  return list.reduce((res, data) => {
    // eslint-disable-next-line
    let existingIndex = res.findIndex(item => item.value == data.MinPax);
    if (existingIndex >= 0) {
      res[existingIndex].count = res[existingIndex].count + 1;
    } else {
      res.push({
        value: data.MinPax,
        count: 1,
      });
    }
    return res;
  }, []);
};
const existingMinPaxValidation = priceDetailList => {
  let groupedMinPax = minPaxGroupping(priceDetailList);
  let multiMinPax = groupedMinPax.find(item => item.count > 1);
  return multiMinPax ? true : false;
};

const zeroPriceValidation = priceDetailList => {
  let zeroValue = priceDetailList.find(item => item.Price === 0);
  return zeroValue ? true : false;
};
export {
  existingPriceValidation,
  existingMinPaxValidation,
  zeroPriceValidation,
};
