import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import InputWithLabel from '../../../../components/input/inputWithLabel';
import styles from './Styles';

class GuestForm extends Component {
  render() {
    let {
      loadingCountry,
      loadingIdentityType,
      loadingTitleType,
      handleChange,
      identityType,
      identityNbr,
      countryId,
      guestTitle,
      firstName,
      lastName,
      id,
      validate,
      disabled,
    } = this.props;
    return (
      <Form>
        <Form.Group widths="equal">
          <Form.Field>
            <InputWithLabel
              label="Identity Type"
              inline={false}
              type="select"
              placeholder="Select Type"
              labelStyles={{ fontWeight: 'bold' }}
              inputStyles={styles.inputSelectionStyle}
              options={this.props.listIdentityType}
              fluid={true}
              loading={loadingIdentityType}
              name="identityType"
              objectname={id}
              value={identityType}
              handleChange={handleChange}
              validate={validate}
              disabled={disabled}
            />
          </Form.Field>
          <Form.Field>
            <InputWithLabel
              label="Identity no."
              type="text"
              labelStyles={{ display: 'block', fontWeight: 'bold' }}
              inputStyles={styles.inputTextStyle}
              fluid={true}
              name="identityNbr"
              objectname={id}
              value={identityNbr}
              handleChange={handleChange}
              validate={validate}
              disabled={disabled}
            />
          </Form.Field>
          <Form.Field>
            <InputWithLabel
              label="Home Country"
              inline={false}
              type="select"
              placeholder="Select City"
              labelStyles={{ fontWeight: 'bold' }}
              inputStyles={styles.inputSelectionStyle}
              options={this.props.listCountry}
              fluid={true}
              loading={loadingCountry}
              name="countryId"
              objectname={id}
              value={countryId}
              handleChange={handleChange}
              validate={validate}
              disabled={disabled}
            />
          </Form.Field>
          <Form.Field>
            <InputWithLabel
              label="Title"
              inline={false}
              type="select"
              placeholder="Mr,Ms,etc"
              labelStyles={{ fontWeight: 'bold' }}
              inputStyles={styles.inputSelectionStyle}
              options={this.props.listTitleType}
              fluid={true}
              loading={loadingTitleType}
              name="guestTitle"
              objectname={id}
              value={guestTitle}
              handleChange={handleChange}
              validate={validate}
              disabled={disabled}
            />
          </Form.Field>
          <Form.Field>
            <InputWithLabel
              label="First Name"
              type="text"
              labelStyles={{ display: 'block', fontWeight: 'bold' }}
              inputStyles={styles.inputTextStyle}
              fluid={true}
              name="firstName"
              objectname={id}
              value={firstName}
              handleChange={handleChange}
              validate={validate}
              disabled={disabled}
            />
          </Form.Field>
          <Form.Field>
            <InputWithLabel
              label="Last Name"
              type="text"
              labelStyles={{ display: 'block', fontWeight: 'bold' }}
              inputStyles={styles.inputTextStyle}
              fluid={true}
              name="lastName"
              objectname={id}
              value={lastName}
              handleChange={handleChange}
              validate={validate}
              disabled={disabled}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }
}
GuestForm.propTypes = {
  listCountry: PropTypes.array,
  listTitleType: PropTypes.array,
  listIdentityType: PropTypes.array,
  loadingCountry: PropTypes.bool,
  loadingIdentityType: PropTypes.bool,
  loadingTitleType: PropTypes.bool,
  identityType: PropTypes.string,
  identityNbr: PropTypes.string,
  countryId: PropTypes.string,
  guestTitle: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  id: PropTypes.number,
  handleChange: PropTypes.func,
  validate: PropTypes.object,
  disabled: PropTypes.bool,
};
export default GuestForm;
