import React, { Component } from 'react';
import { Collapse } from 'react-collapse';
import { Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import HeaderRoomCard from './headerRoomCard';
import DescRoomCard from './descriptionRoomCard';
import styles from './../../styles';

class roomCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDesc: false,
    };
  }
  static propTypes = {
    desc: PropTypes.string,
    amenities: PropTypes.array,
    name: PropTypes.string,
    type: PropTypes.string,
    capacity: PropTypes.number,
    image: PropTypes.string,
    images: PropTypes.array,
    serviceType: PropTypes.array,
    isPromo: PropTypes.bool,
    room: PropTypes.object,
    onClose: PropTypes.func,
    closeModalAccommodation: PropTypes.func,
    setAccommodationRoom: PropTypes.func,
    isInstantConfirmation: PropTypes.bool,
  };
  handleChange = () => {
    this.setState({ openDesc: !this.state.openDesc });
  };
  render() {
    let { openDesc } = this.state;
    let {
      desc,
      amenities,
      name,
      type,
      capacity,
      image,
      images,
      serviceType,
      isPromo,
      room,
      onClose,
      closeModalAccommodation,
      setAccommodationRoom,
      isInstantConfirmation,
    } = this.props;
    return (
      <Segment.Group style={styles.segmentNoPadding}>
        <HeaderRoomCard
          handleChange={this.handleChange}
          openDesc={openDesc}
          name={name}
          type={type}
          capacity={capacity}
          image={image}
          images={images}
          isPromo={isPromo}
          isInstantConfirmation={isInstantConfirmation}
        />
        <Collapse isOpened={openDesc} style={{ margin: '0px' }}>
          <DescRoomCard
            desc={desc}
            amenities={amenities}
            service={serviceType}
            room={room}
            onClose={onClose}
            closeModalAccommodation={closeModalAccommodation}
            setAccommodationRoom={setAccommodationRoom}
          />
        </Collapse>
      </Segment.Group>
    );
  }
}
export default roomCard;
