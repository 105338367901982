import React, { useState } from 'react';
// import { useDispatch } from 'react-redux';
import { Card, Header, Button, Loader, Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import styles from './styles';
// import { setBreadcrumbDashboard } from '../../../shared/actions/breadcrumbAction';
import SegmentNoBooking from './SegmentNoBooking';
import { rangeDate } from '../../../../scripts/moment';

const CardBookingCalendar = props => {
  // const dispatch = useDispatch();
  //=======================initial state=======================
  const { loading, items, dateClick } = props;
  const [changePage, setChangePage] = useState({ isChange: false, url: '' });

  //=======================handler=======================
  const handleClickButton = (e, { id, name }) => {
    setChangePage({
      isChange: true,
      url: `/tour-transaction/edit/${name}/${id}`,
    });
  };
  return changePage.isChange ? (
    <Redirect to={changePage.url} />
  ) : items ? (
    items.map((data, idx) => {
      return (
        <Card style={styles.styleCard} fluid key={idx}>
          <Loader active={loading} />
          <Card.Content>
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column>
                  <Header
                    as="h5"
                    content={`Booking Code: ${data.BookingCode}`}
                    className="anuanuan"
                  />
                </Grid.Column>
                <Grid.Column textAlign="right">
                  <Header
                    as="h5"
                    content={`${
                      data.PackageType === 'Fixed'
                        ? 'Series Package'
                        : data.PackageType === 'FixedDateVariable'
                        ? 'Ready Fixed Price Package'
                        : data.PackageType + ' Package'
                    }`}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Header as="h3" content={data.Title} />
            <Card.Description>
              Tour Date : {rangeDate(data.StartDate, data.EndDate) + ' '}
              ||{' '}
              {' ' + data.TotalPax + (data.TotalPax > 1 ? ' guests' : ' guest')}
            </Card.Description>
            <Button
              floated="right"
              content="See Detail"
              style={styles.styleButton}
              size="big"
              id={data.BookingCode}
              name={data.PackageType}
              onClick={handleClickButton}
            />
          </Card.Content>
        </Card>
      );
    })
  ) : (
    !loading && <SegmentNoBooking dateClick={dateClick} />
  );
};

CardBookingCalendar.propTypes = {
  loading: PropTypes.bool,
  items: PropTypes.array,
  dateClick: PropTypes.string,
};
export default withRouter(React.memo(CardBookingCalendar));
