const headerTableAccessibleCity = ['City', 'Region', 'Action'];
const headerTableImageAccomodationProfile = [
  'File Name',
  'Thumbnail',
  'Action',
];

const headerRestaurantItemList = [
  'Item Name',
  'Description',
  'Menu Type',
  'Menu Class ',
  'Food Presentation',
  'Action',
];
export {
  headerTableAccessibleCity,
  headerTableImageAccomodationProfile,
  headerRestaurantItemList,
};
