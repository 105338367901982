// import { '#fefefe' } from '../../../../../constant/colour';

const style = {
  textCenter: { textAlign: 'center' },
  timeLineIconClock: {
    width: '70px',
    height: '20px',
    left: '6%',
    marginLeft: '-10px',
    marginTop: '27px', //'35px',
    background: '#fefefe',
    borderRadius: '10px',
    boxShadow: '0 0 0 transparent',
  },
  timeLineAddActivityIcon: {
    width: '40px',
    height: '40px',
    left: '8.5%',
    marginLeft: '-10px',
    marginTop: '7px', //'20px',
    background: '#fefefe',
    boxShadow: '0 0 0 transparent',
  },
  iconPlusStyle: { marginTop: '10px', marginLeft: '11px' },
  backgroundGold: {
    background: '#fefefe',
  },
  timeLineIcon: {
    width: '20px',
    height: '20px',
    left: '10%',
    marginLeft: '-10px',
    marginTop: '20px',
    background: '#fefefe',
  },
  noMarginBot: { marginBottom: 0 },
  boxTransparent: { boxShadow: '0 0 0 transparent', padding: '5px' },
  marginTopMinFive: { marginTop: '-5px' },
  noPadding: { padding: 0 },
  noPaddingTop: { paddingTop: 0 },
  noPaddingBottom: { paddingBottom: 0 },
  noMargin: { margin: 0 },
  tourGuideStyle: {
    marginLeft: 90,
    paddingTop: 0,
    paddingBottom: 0,
    height: 64,
    width: 450,
  },
  noDisplay: { display: 'none' },
  addActivitiesStyle: {
    background: '#fefefe',
    zIndex: 1,
  },
  boxShadowNone: { boxShadow: 'none', color: 'black' },
  widthPopup: { width: 350 },
  paddingPopup: {
    paddingBottom: 7,
    paddingTop: 7,
  },
  font11: {
    fontSize: '11px',
  },
  colorBlack: {
    color: 'black',
  },
  separatorDateStyle: {
    position: 'absolute',
    top: '-20px',
    left: '-5px',
    background: '#9AD6C9',
    color: 'black',
  },
  separatorLineStyle: {
    border: '2px dashed #38af95',
    marginTop: '5px',
    marginBottom: '15px',
  },
};

export default style;
