import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Form } from 'semantic-ui-react';
import InputWithLabel from '../../../../../components/input/inputWithLabel';
const generalInfoTransportationUnit = props => {
  return (
    <div>
      <Grid columns="equal">
        <Grid.Row stretched>
          <Grid.Column width={15}>
            <Header as="h4" content="General Information" />
            <Form>
              <Form.Group>
                <Form.Field width="8">
                  <InputWithLabel
                    label="Unit Name"
                    type="text"
                    name="Name"
                    value={props.transportationHeader.Name}
                    handleChange={props.handleChangeUnitHeader}
                    required
                    fluid={true}
                    validate={props.errors}
                    placeholder="Unit Name"
                    disabled={props.disablePage}
                  />
                </Form.Field>
                <Form.Field width="8">
                  <InputWithLabel
                    label="Rating"
                    type="select"
                    name="TransportationRatingId"
                    value={props.transportationHeader.TransportationRatingId}
                    handleChange={props.handleChangeUnitHeader}
                    required
                    options={props.rating}
                    fluid={true}
                    validate={props.errors}
                    placeholder="Rating"
                    disabled={props.disablePage}
                    inline={false}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Field>
                <InputWithLabel
                  label="Description"
                  placeholder="Description"
                  name="Description"
                  type="textArea"
                  value={props.transportationHeader.Description}
                  handleChange={props.handleChangeUnitHeader}
                  required
                  fluid={true}
                  validate={props.errors}
                  disabled={props.disablePage}
                />
              </Form.Field>
              <Form.Group>
                <Form.Field width="8">
                  <InputWithLabel
                    label="Code"
                    type="text"
                    name="Code"
                    value={props.transportationHeader.Code}
                    handleChange={props.handleChangeUnitHeader}
                    required
                    fluid={true}
                    validate={props.errors}
                    placeholder="Code"
                    disabled={props.disablePage}
                  />
                </Form.Field>
                <Form.Field width="8">
                  <InputWithLabel
                    label="Capacities"
                    type="text"
                    name="TransportationSeatTypeId"
                    value={props.transportationHeader.TransportationSeatTypeId}
                    handleChange={props.handleChangeUnitHeader}
                    required
                    fluid={true}
                    validate={props.errors}
                    placeholder="Capacities"
                    disabled={props.disablePage}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Field>
                <InputWithLabel
                  label="Transportation Type"
                  type="select"
                  name="TransportationTypeId"
                  value={props.transportationHeader.TransportationTypeId}
                  handleChange={props.handleChangeUnitHeader}
                  required
                  options={props.listTransportationType}
                  fluid={true}
                  validate={props.errors}
                  placeholder="Transportation Type"
                  disabled={props.disablePage}
                  inline={false}
                />
              </Form.Field>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
generalInfoTransportationUnit.propTypes = {
  handleChange: PropTypes.func,
  transportationHeader: PropTypes.shape({
    Name: PropTypes.string,
    TransportationRatingId: PropTypes.number,
    Description: PropTypes.string,
    Code: PropTypes.string,
    TransportationTypeId: PropTypes.number,
    TransportationSeatTypeId: PropTypes.number,
  }),
  rating: PropTypes.array,
  errors: PropTypes.object,
  handleChangeUnitHeader: PropTypes.func,
  listTransportationType: PropTypes.array,
  disablePage: PropTypes.bool,
};
export default generalInfoTransportationUnit;
