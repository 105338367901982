export const setDataList = data => {
  let newData =
    data &&
    data.map(item => {
      return {
        Destination: `${item.DestinationCity.Text}, ${item.DestinationCountry.Text}`,
        Origin: `${item.OriginalCity.Text}, ${item.OriginalCountry.Text}`,
        TransportationType: item.TransportationType.Text,
        Id: {
          origin: item.OriginalCity.Value,
          destination: item.DestinationCity.Value,
          type: item.TransportationType.Value,
        },
      };
    });
  return newData;
};

export const setDataSave = data => {
  let resData = data;
  let detailCost = resData.DetailCost.map(item => {
    return {
      ...item,
      TransportationAdditionalCostTypeId:
        item.TransportationAdditionalCostType.Value,
      TrasportationAdditionalCostName: item.TransportationAdditionalCostName,
    };
  });
  resData.DetailCost = detailCost;
  return resData;
};

export const setNewId = data => {
  let id = {
    origin: data.OriginalCity.Value,
    destination: data.DestinationCity.Value,
    type: data.TransportationType.Value,
  };
  return id;
};
