import React from 'react';
import { Dimmer } from 'semantic-ui-react';
import { connect } from 'react-redux';
import ModalAlert from '../../shared/components/modal/ModalAlert';
import Loader from '../../../components/loader';
import PropTypes from 'prop-types';
import {
  getRestaurantByProfileIdAction,
  getRestaurantItemByProfileIdAction,
  resetRestaurantAction,
  getRestaurantClassAction,
  getRestaurantTypeAction,
  postRestaurantProfileAction,
  putRestaurantProfileAction,
  getTemplateRestaurantItemAction,
  getExportDataItemByIdAction,
  getRestaurantListAction,
  postUploadImageRestaurantProfileAction,
  getRestaurantProfileSpecializationsAction,
  getMenuClassesAction,
  getRestaurantRatingAction,
  deleteRestaurantItemByIdAction,
} from '../../../actions/restaurantAction';
import SegmentRestaurantProfile from './components/SegmentRestraurantProfile';
import {
  getCityListAction,
  getCityInCountry,
} from '../../shared/actions/cityAction';
import {
  getRegionListAction,
  getRegionAction,
} from '../../shared/actions/regionAction';
import { getCountryListAction } from '../../shared/actions/countryAction';
import { getAreaListAction } from '../../shared/actions/areaAction';
import ModalImage from '../../../components/modal/modalImage';
import { getHourAndMinute } from '../../../components/genericFunction/moment';
import moment from 'moment';
import ModalAccessibleCity from '../../../components/modal/modalAccessibleCity';
import { datasetPagination } from '../../../scripts/pagination';
import FilterData from '../../../scripts/filterDataTable';
import ModalConfirmation from '../../../components/modal/modalConfirmationTwoButton';
import handleValidation from './services/validation/emptyFieldValidation';
import {
  restautantProfileIdField,
  addressObjectField,
} from './constant/fieldToValidate';
import _ from 'lodash';
import {
  setRestaurantDataObject,
  setRestaurantRequestData,
} from './services/data/setRestaurantData';
import {
  uploadMasterDataPath,
  restaurantPath,
  restaurantItemPath,
} from './constant/pathName';
import { setImageData } from './services/data/setImageData';
import { convertOptions } from './services/helper/convertOptions';
class RestaurantProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      restaurantProfiledId: {
        Id: '',
        Name: '',
        Description: '',
        LongDescription: '',
        Address: '',
        PhoneNbr: '',
        Email: '',
        OperationStartTime: getHourAndMinute(moment()),
        OperationEndTime: getHourAndMinute(moment()),
        RestaurantType: '',
        RestaurantSpeciality: '',
        CityId: '',
        RegionId: '',
        CountryId: '',
        SpecializationId: '',
        AddressObject: {
          Address1: '',
          Address2: '',
          Address3: '',
          AreaId: '',
          PostalCode: '',
          LandMark: '',
          CityId: '',
          RegionId: '',
          CountryId: '',
          PhoneNbr: '',
          Email: '',
        },
        AccessibleFrom: [],
        RestaurantProfileImages: [],
        RatingId: '',
        Type: '',
        SpecialityId: '',
        ProfileSpecializations: [],
      },
      restaurantItemByProfileId: [],
      loadingRestaurant: true,
      loadingRestaurantItem: true,
      failedRestaurant: false,
      failedRestaurantItem: false,
      idRestaurantProfile: '',
      errors: {},
      image: [],
      listStepImage: [
        {
          Tittle: 'Choose Image',
          Active: true,
        },
        {
          Tittle: 'Select Thumbnail Image',
          Active: false,
        },
        {
          Tittle: 'Edit Thumbnail Image',
          Active: false,
        },
      ],
      indexActive: 0,
      imageUrl: null,
      openModalImage: false,
      openModalAccCity: false,
      openModalItem: false,
      activePage: 1,
      listAccessCity: [],
      searchInput: '',
      accessCity: [],
      disablePage: true,
      openModalDeleteCity: false,
      messageDeleteCity: '',
      indexDeleteCity: 0,
      regionForAccessibleCity: '',
      listCityByRegionForContactInfo: [],
      activePageModalAccessibleCity: 1,
    };
  }
  handleClose = () =>
    this.setState({
      openModalConfirmation: false,
      failedRestaurant: false,
      failedRestaurantItem: false,
      openModalImage: false,
      openModalAccCity: false,
      openModalItem: false,
    });
  static propTypes = {
    getRestaurantByProfileIdAction: PropTypes.func,
    getRestaurantItemByProfileIdAction: PropTypes.func,
    resetRestaurantAction: PropTypes.func,
    getCityListAction: PropTypes.func,
    getRegionListAction: PropTypes.func,
    getCountryListAction: PropTypes.func,
    getAreaListAction: PropTypes.func,
    getRestaurantByProfileIdStatus: PropTypes.string,
    getRestaurantByProfileId: PropTypes.object,
    listCountry: PropTypes.array,
    listRegion: PropTypes.array,
    listCity: PropTypes.array,
    listArea: PropTypes.array,
    getRestaurantItemByProfileIdStatus: PropTypes.string,
    getRestaurantItemByProfileId: PropTypes.array,
    getRestaurantTypeAction: PropTypes.func,
    getRestaurantClassAction: PropTypes.func,
    listRestaurantClass: PropTypes.array,
    listRestaurantSpecialty: PropTypes.array,
    postRestaurantProfileAction: PropTypes.func,
    putRestaurantProfileAction: PropTypes.func,
    getRestaurantItemTemplateStatus: PropTypes.string,
    getRestaurantItemTemplate: PropTypes.array,
    getRestaurantItemExportStatus: PropTypes.string,
    getRestaurantItemExport: PropTypes.array,
    getTemplateRestaurantItemAction: PropTypes.func,
    getExportDataItemByIdAction: PropTypes.func,
    getCityInCountry: PropTypes.func,
    getCityInCountryList: PropTypes.array,
    postRestaurantStatus: PropTypes.string,
    putRestaurantStatus: PropTypes.string,
    errors: PropTypes.object,
    getRestaurantListAction: PropTypes.func,
    postRestaurant: PropTypes.func,
    postUploadImageRestaurantProfileAction: PropTypes.func,
    listAccessCity: PropTypes.array,
    getRestaurantProfileSpecializationsAction: PropTypes.func,
    restaurantSpecializations: PropTypes.array,
    getMenuClassesAction: PropTypes.func,
    menuClasses: PropTypes.array,
    getRestaurantRatingAction: PropTypes.func,
    restaurantRating: PropTypes.array,
    deleteRestaurantItemByIdAction: PropTypes.func,
    deleteRestaurantItemByIdStatus: PropTypes.string,
    getRegionAction: PropTypes.func,
    listAllRegions: PropTypes.array,
    loadingGetCityByRegion: PropTypes.bool,
    match: PropTypes.shape({
      params: PropTypes.shape({
        ProfileId: PropTypes.string,
        Type: PropTypes.string,
      }),
    }),
  };
  onChange = (e, { value, name }) => {
    const restaurantProfiledId = this.state.restaurantProfiledId;
    restaurantProfiledId[name] = value;
    this.setState({ restaurantProfiledId: restaurantProfiledId });
    const getRegion = name === 'CountryId';
    const getCity = name === 'RegionId';
    const getArea = name === 'CityId';
    if (getRegion) {
      this.props.getRegionListAction(value);
      this.props.getCityInCountry(value);
    } else if (getCity) {
      this.props.getCityListAction(value).then(() =>
        this.setState({
          ...this.state,
          listCityByRegionForContactInfo: this.props.listCity,
        })
      );
    } else if (getArea) {
      this.props.getAreaListAction(value);
    }
    this.setState({
      restaurantProfiledId: {
        ...this.state.restaurantProfiledId,
        AddressObject: {
          ...this.state.restaurantProfiledId.AddressObject,
          AreaId: this.state.restaurantProfiledId.AddressObject.AreaId,
        },
      },
    });
    this.validate();
  };

  validate = () => {
    let isError = false;
    let { restaurantProfiledId } = this.state;
    let error1 = handleValidation(
      restaurantProfiledId,
      restautantProfileIdField
    );
    if (restaurantProfiledId['RestaurantType']) {
      let isExist = this.props.restaurantSpecializations.find(
        item => item.value === restaurantProfiledId['RestaurantType']
      );
      if (!isExist) {
        error1['RestaurantType'] = {
          error: true,
          message: `Field can't be empty!!`,
        };
      }
    }
    let error2 = handleValidation(
      restaurantProfiledId.AddressObject,
      addressObjectField
    );
    let errors = { ...error1, ...error2 };
    isError = !_.isEmpty(errors);
    this.setState({
      errors: {
        ...errors,
      },
    });
    return isError;
  };
  onChangeAddress = (e, { value, name }) => {
    const addressObject = this.state.restaurantProfiledId.AddressObject;
    addressObject[name] = value;
    this.setState({ addressObject: addressObject });
    const getRegion = name === 'CountryId';
    const getCity = name === 'RegionId';
    const getArea = name === 'CityId';
    if (getRegion) {
      this.props.getRegionListAction(value);
      this.props.getCityInCountry(value);
    } else if (getCity) {
      this.props.getCityListAction(value).then(() =>
        this.setState({
          ...this.state,
          listCityByRegionForContactInfo: this.props.listCity,
        })
      );
    } else if (getArea) {
      this.props.getAreaListAction(value);
    }
    this.validate();
  };
  componentDidMount() {
    this.props.getCountryListAction();
    this.getRestaurantProfileId();
    this.props.getRestaurantTypeAction();
    this.props.getRestaurantClassAction();
    this.props.getRestaurantProfileSpecializationsAction();
    this.props.getMenuClassesAction();
    this.props.getRestaurantRatingAction();
    this.props.getRegionAction();
  }
  getRestaurantProfileId = () => {
    // eslint-disable-next-line
    let { ProfileId, Type } = this.props.match.params;
    let type = Type === 'Detail' ? 'Edit' : 'Save';
    if (ProfileId === undefined) {
      this.setState({
        loadingRestaurant: false,
        loadingRestaurantItem: false,
        titleHeader: 'Add Restaurant',
        idRestaurantProfile: '',
        disablePage: false,
        contentButton: type,
      });
    } else {
      return this.getDataRestaurant(ProfileId, type);
    }
  };

  getDataRestaurant = (id, type) => {
    this.props.getRestaurantByProfileIdAction(id);
    this.props.getRestaurantItemByProfileIdAction(id);
    // eslint-disable-next-line
    let { Type } = this.props.match.params;
    this.setState({
      idRestaurantProfile: id,
      titleHeader: Type === 'Detail' ? 'Detail Restaurant' : 'Edit Restaurant',
      disablePage: type === 'Save' ? false : true,
      contentButton: type,
    });
  };
  componentDidUpdate() {
    if (this.props.getRestaurantByProfileIdStatus === 'success') {
      this.setStateRestaurantProfile(this.props.getRestaurantByProfileId);
      this.setState({
        idRestaurantProfile: this.props.getRestaurantByProfileId.Id,
      });
      this.setState({ loadingRestaurant: false });
      this.props.resetRestaurantAction();
    } else if (this.props.getRestaurantByProfileIdStatus === 'failed') {
      this.setState({ failedRestaurant: true });
      this.setState({ loadingRestaurant: false });
      this.props.resetRestaurantAction();
    }
    if (this.props.postRestaurantStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Restaurant Profile is Successfully Saved',
        openModalConfirmation: true,
        contentButton: 'Edit',
        disablePage: true,
        loadingRestaurant: false,
        titleHeader: 'Detail Restaurant',
      });

      const { Id } = this.props.postRestaurant;
      this.props.getRestaurantByProfileIdAction(Id);
      // RestaurantProfileImages.map(data => {
      //   return data.onChange
      //     ? this.props.postUploadImageRestaurantProfileAction(
      //         Id,
      //         setImageRequestData(Id, data)
      //       )
      //     : null;
      // });
      // eslint-disable-next-line
      this.props.history.push(`/Restaurant-Profile/Detail/${Id}`);
      this.props.resetRestaurantAction();
    } else if (this.props.postRestaurantStatus === 'failed') {
      let errorMessage = this.props.errors;
      let descAlert = '';
      if (errorMessage.includes('Duplicate data')) {
        descAlert = 'Your restaurant name is already exist in our system';
      } else {
        descAlert =
          'Save Restaurant Profile Failed, please check your data first';
      }
      this.setState({
        headerAlert: 'Failed',
        contentAlert: descAlert,
        openModalConfirmation: true,
        loadingRestaurant: false,
      });
      this.props.resetRestaurantAction();
    }
    if (this.props.putRestaurantStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Edit Restaurant Profile Successfully ',
        openModalConfirmation: true,
        disablePage: true,
        contentButton: 'Edit',
        loadingRestaurant: false,
      });
      let id = this.props.match.params.ProfileId;
      this.getRestaurantProfileId(id);
      this.props.resetRestaurantAction();
    } else if (this.props.putRestaurantStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: 'Edit Restaurant Profile Failed ',
        openModalConfirmation: true,
        loadingRestaurant: false,
      });
      this.props.resetRestaurantAction();
    }

    if (this.props.getRestaurantItemByProfileIdStatus === 'success') {
      this.setState({
        restaurantItemByProfileId: this.props.getRestaurantItemByProfileId,
      });
      this.setState({ loadingRestaurantItem: false });
      this.props.resetRestaurantAction();
    } else if (this.props.getRestaurantItemByProfileIdStatus === 'failed') {
      this.setState({ failedRestaurantItem: true });
      this.setState({ loadingRestaurantItem: false });
      this.props.resetRestaurantAction();
    }

    if (this.props.getRestaurantItemTemplateStatus === 'success') {
      this.handleDownload(
        this.props.getRestaurantItemTemplate,
        'Restaurant Item Template.txt'
      );
      this.props.resetRestaurantAction();
    } else if (this.props.getRestaurantItemTemplateStatus === 'failed') {
      this.props.resetRestaurantAction();
    }

    if (this.props.getRestaurantItemExportStatus) {
      this.handleDownload(
        this.props.getRestaurantItemExport,
        'Restaurant Item Export.txt'
      );
      this.props.resetRestaurantAction();
      this.setState({ loadingRestaurant: false });
    } else if (this.props.getRestaurantItemExportStatus) {
      this.props.resetRestaurantAction();
      this.setState({ loadingRestaurant: false });
    }

    if (this.props.deleteRestaurantItemByIdStatus === 'success') {
      const { ProfileId } = this.props.match.params;
      this.getRestaurantProfileId(ProfileId);
      this.setState({ loadingRestaurantItem: false });
      this.props.resetRestaurantAction();
    }
  }
  handleDownload = (exportFile, name) => {
    var elementDownload = document.createElement('a');
    var fileData = new Blob([exportFile], {
      type: 'text/plain',
    });
    elementDownload.href = URL.createObjectURL(fileData);
    elementDownload.download = name;
    elementDownload.click();
  };
  setStateRestaurantProfile = data => {
    const { Cities, AddressObject, RestaurantProfileImages } = data;
    // const accessibleFromDestructure = Cities;
    const imageDestructure = RestaurantProfileImages.map(image => {
      return {
        ImageId: image.ImageId,
        ImageContent: image.ImageUrl,
        ImageName: image.ImageName,
        IsPrimaryImage: image.IsPrimaryImage,
        ImageUrl: image.ImageUrl,
        imagePreview: image.ImageUrl,
      };
    });
    let restaurantProfiledId = setRestaurantDataObject(
      data,
      Cities,
      imageDestructure
    );
    this.props.getRegionListAction(
      AddressObject.Country.Id ? AddressObject.Country.Id : ''
    );
    this.props.getCityInCountry(
      AddressObject.Country.Id ? AddressObject.Country.Id : ''
    );
    this.props.getCityListAction(AddressObject.Region.Id).then(() =>
      this.setState({
        ...this.state,
        listCityByRegionForContactInfo: this.props.listCity,
      })
    );
    this.props.getAreaListAction(AddressObject.City.Id);
    this.setState({
      restaurantProfiledId: restaurantProfiledId,
      regionForAccessibleCity: AddressObject.Region.Id,
    });
  };

  saveRestaurant = (e, { name }) => {
    if (name === 'Edit') {
      this.setState({
        titleHeader: 'Edit Restaurant ',
        contentButton: 'Save',
        disablePage: false,
      });
    } else {
      const err = this.validate();

      let data = setRestaurantRequestData(this.state.restaurantProfiledId);

      if (!err) {
        const Id = this.state.restaurantProfiledId.Id;
        this.setState({
          loadingRestaurant: true,
        });
        if (Id === '') {
          this.props.postRestaurantProfileAction(data);
        } else {
          this.props.putRestaurantProfileAction(Id, data);
          // RestaurantProfileImages.map(data => {
          //   return data.onChange
          //     ? this.props.postUploadImageRestaurantProfileAction(
          //         Id,
          //         setImageRequestData(Id, data)
          //       )
          //     : null;
          // });
        }
        this.setState({ failedRestaurant: false });
      }
      //console.log(data);
    }
  };

  ButtonUploadDataItem = () => {
    // eslint-disable-next-line
    this.props.history.push(`${uploadMasterDataPath}/${'RestaurantItem'}`);
  };
  buttonOnBackPage = () => {
    // eslint-disable-next-line
    this.props.history.push(restaurantPath);
  };
  ButtonDownloadTemplate = () => {
    this.props.getTemplateRestaurantItemAction();
  };
  ButtonExport = () => {
    // eslint-disable-next-line
    let { ProfileId } = this.props.match.params;
    this.props.getExportDataItemByIdAction('RestaurantItems', ProfileId);
    this.setState({ loadingRestaurant: true });
  };
  newRestaurant = () => {
    if (this.state.idRestaurantProfile === '') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: 'Please Save Restaurant First',
        openModalConfirmation: true,
      });
    } else {
      // eslint-disable-next-line
      this.props.history.push(
        `${restaurantItemPath}/${this.state.idRestaurantProfile}`
      );
    }
  };
  handleClickEdit = (e, { name }) => {
    const { ProfileId } = this.props.match.params;
    // eslint-disable-next-line
    this.props.history.push(`${restaurantItemPath}/Edit/${ProfileId}/${name}`);
  };
  // modal Image
  addImage = () => {
    let objectImage = this.state.restaurantProfiledId;
    let image = this.state.image;
    objectImage.RestaurantProfileImages.push(image);
    let listStepImage = this.state.listStepImage;
    listStepImage.map((step, i) => {
      step['Active'] = i === 0 ? true : false;
      return listStepImage;
    });
    this.setState({
      restaurantProfiledId: objectImage,
      openModalImage: false,
      listStepImage: listStepImage,
      indexActive: 0,
    });
  };
  changeImage = e => {
    let imageObject = this.state.restaurantProfiledId;
    e.map(base => {
      let image = setImageData(base);
      return imageObject.RestaurantProfileImages.push(image);
    });
    this.setState({
      restaurantProfiledId: imageObject,
    });
  };
  openDetail = (e, { value }) => {
    const { RestaurantProfileImages } = this.state.restaurantProfiledId;
    let listStepImage = this.state.listStepImage;
    listStepImage.map((step, i) => {
      step['Active'] = i === 0 ? true : false;
      return listStepImage;
    });
    let image = RestaurantProfileImages[value];
    this.setState({
      image: image,
      indexActive: 0,
      listStepImage: listStepImage,
      openModalImage: true,
      // imageUrl: image.ImageContent //ImageUrl
      //   ? image.ImageContent //ImageUrl
      //   : URL.createObjectURL(e.target.files[0]),
    });
  };
  handleChangePrimaryImage = (e, { value }) => {
    let listImage = this.state.restaurantProfiledId;
    let newList = [];
    listImage.RestaurantProfileImages.map((img, i) => {
      img['IsPrimaryImage'] = i === value ? true : false;
      return newList.push(img);
    });
    this.setState({
      restaurantProfiledId: {
        ...this.state.restaurantProfiledId,
        RestaurantProfileImages: newList,
      },
    });
  };
  handleNext = () => {
    let indexActive = this.state.indexActive;
    indexActive = indexActive + 1;
    let listStepImage = this.state.listStepImage;
    listStepImage.map((step, i) => {
      step['Active'] = i === indexActive ? true : false;
      return listStepImage;
    });
    if (indexActive === 2) {
      let listImage = this.state.restaurantProfiledId;
      listImage.RestaurantProfileImages.map(img => {
        return img.IsPrimaryImage && this.setState({ image: img });
      });
    }
    if (indexActive === 3) {
      return this.setState({ indexActive: 0, openModalImage: false });
    }
    this.setState({ listStepImage: listStepImage, indexActive: indexActive });
  };
  handleBackStep = () => {
    let indexActive = this.state.indexActive;
    indexActive = indexActive - 1;
    let listStepImage = this.state.listStepImage;
    listStepImage.map((step, i) => {
      step['Active'] = i === indexActive ? true : false;
      return listStepImage;
    });
    this.setState({ listStepImage: listStepImage, indexActive: indexActive });
  };
  modalItemAction = e => {
    return e.target.id === 'Accesible City'
      ? this.setState({
          contentModal: 'Accesible City',
          openModalItem: true,
          listAccessCity: this.props.getCityInCountryList,
        })
      : this.setState({
          contentModal: 'Image',
          openModalImage: true,
          dataItem: [],
        });
  };
  //======================
  // Set Time
  setStartTime = (contex, value, name) => {
    let restaurant = this.state.restaurantProfiledId;
    restaurant[name] = value;
    this.setState({ restaurantProfiledId: restaurant });
  };
  setEndTime = (contex, value, name) => {
    let restaurant = this.state.restaurantProfiledId;
    restaurant[name] = value;
    this.setState({ restaurantProfiledId: restaurant });
  };
  //=======================
  // Accesible City
  onClickModalAccCity = () => {
    const { RegionId } = this.state.restaurantProfiledId.AddressObject;
    let errors = {
      RegionId: '',
    };
    if (!RegionId) {
      errors.RegionId = 'Please Select Region';
      this.setState({
        errors: {
          ...errors,
        },
      });
    } else {
      this.showListAccessibleCityOnModal(RegionId);
      // this.showListAccessibleCityOnModal(RegionId);
      // let listCity = this.props.listAccessCity;
      // let cityAccess = this.state.restaurantProfiledId.AccessibleFrom;
      // listCity.map(city => {
      //   cityAccess.map(acce => {
      //     if (city.Id === acce.Id) {
      //       city['Status'] = true;
      //       return listCity;
      //     }
      //     return listCity;
      //   });
      //   return listCity;
      // });
      // this.setState({ openModalAccCity: true, listAccessCity: listCity });
    }
  };
  handleChangeAccessCity = (e, { name, checked }) => {
    let list = this.state.listAccessCity;
    list.map(city => {
      if (city.Id === name) {
        city['Status'] = checked;
        city['onChange'] = true;
        return list;
      }
      return list;
    });
    this.setState({ listAccessCity: list });
  };
  pageChangeModalAccessibleCity = (e, { activePage }) => {
    this.setState({ activePageModalAccessibleCity: activePage });
  };
  pageChangeCity = (e, { activePage }) => {
    this.setState({ activePage });
  };
  addAccessCity = () => {
    let { listAccessCity, restaurantProfiledId, regionForAccessibleCity } = {
      ...this.state,
    };
    //accessibleCityDiffRegion ==> list city yang beda region dengan region yang dipilih
    let accessibleCityDiffRegion = restaurantProfiledId.AccessibleFrom.filter(
      e => e.Region.Id !== regionForAccessibleCity
    );
    //updateAccessibleCity ===> list city yang sama dengan region yang dipilih. lakukan update di city ini.
    listAccessCity.map(city => {
      if (city.Status === true) {
        accessibleCityDiffRegion.push({
          ...city,
          Region: city.CompleteData.Region,
        });
      }
      return city;
    });
    restaurantProfiledId.AccessibleFrom = accessibleCityDiffRegion;
    this.setState({
      AccessibleFrom: accessibleCityDiffRegion,
      openModalAccCity: false,
    });
    // let listCity = this.state.listAccessCity;
    // let newListCity = [];
    // listCity.map(city => {
    //   if (city.Status === true) {
    //     newListCity.push(city);
    //   }
    //   return newListCity;
    // });
    // this.setState({
    //   restaurantProfiledId: {
    //     ...this.state.restaurantProfiledId,
    //     AccessibleFrom: newListCity,
    //   },
    //   openModalAccCity: false,
    // });
  };
  confirmDelete = () => {
    let listCity = this.state.restaurantProfiledId.AccessibleFrom;
    let id = this.state.indexDeleteCity;
    listCity.filter(item => item.Id !== id);
    let listCity1 = this.state.listAccessCity;
    listCity1.map(city => {
      if (city.Id === id) {
        city['Status'] = false;
      }
      return listCity1;
    });

    this.setState({
      restaurantProfiledId: {
        ...this.state.restaurantProfiledId,
        AccessibleFrom: listCity.filter(item => item.Id !== id),
        activePage: 1,
      },
      listAccessCity: listCity1,
      activePage: 1,
      openModalDeleteCity: false,
    });
  };
  closeDeleteConfirm = () => {
    this.setState({
      openModalDeleteCity: false,
      openModalRestaurantItem: false,
    });
  };
  deleteAccessCity = (e, { id }) => {
    this.setState({
      openModalDeleteCity: true,
      messageDeleteCity: 'Are you sure want to delete the city?',
      indexDeleteCity: id,
    });
  };
  deleteState = value => {
    //(e, { name })
    let { restaurantProfiledId } = this.state;
    // let objectImage = [];
    // restaurantProfiledId.RestaurantProfileImages.map((img, i) => {
    //   if (Number(name) !== i) {
    //     objectImage.push(img);
    //   }
    //   return img;
    // });
    let objectImage = restaurantProfiledId.RestaurantProfileImages.filter(
      (item, i) => i !== value
    );
    restaurantProfiledId.RestaurantProfileImages = objectImage;
    this.setState({
      restaurantProfiledId: restaurantProfiledId,
      activePage: 1,
    });
  };
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  handleSearchAccesibleCity = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  handleClickDelete = () => {
    const { idRestaurantItem } = this.state;
    this.props.deleteRestaurantItemByIdAction(idRestaurantItem);
    this.setState({
      openModalRestaurantItem: false,
      loadingRestaurantItem: true,
    });
  };

  confirmDeleteRestaurantItem = (e, { name, value }) => {
    this.setState({
      openModalRestaurantItem: true,
      messageDeleteRestaurantItem: 'Are you sure want to delete ' + name + ' ?',
      idRestaurantItem: value,
    });
  };
  // ============ HANDLE ACCESSIBLE REGIONS ======================
  handleChangeRegionAccessibleCity = (e, { value }) => {
    this.setState({
      ...this.state,
      regionForAccessibleCity: value,
    });
    this.props
      .getCityListAction(value)
      .then(() => this.showListAccessibleCityOnModal(value));
  };

  showListAccessibleCityOnModal = regionId => {
    let listCity = this.props.listAccessCity;
    let cityAccess = this.state.restaurantProfiledId.AccessibleFrom;
    listCity.map(city => {
      cityAccess.map(acce => {
        if (city.Id === acce.Id) {
          city['Status'] = true;
          return listCity;
        }
        return listCity;
      });
      return listCity;
    });
    this.setState({
      openModalAccCity: true,
      listAccessCity: listCity,
      regionForAccessibleCity: regionId,
      activePageModalAccessibleCity: 1,
    });
  };
  // ============ END HANDLE ACCESSIBLE REGIONS ======================

  render() {
    let {
      loadingRestaurant,
      loadingRestaurantItem,
      failedRestaurant,
      failedRestaurantItem,
      openModalConfirmation,
      activePage,
      listAccessCity,
      searchInput,
      restaurantProfiledId,
      regionForAccessibleCity,
      activePageModalAccessibleCity,
    } = this.state;
    let { listAllRegions } = this.props;
    let loading = loadingRestaurant || loadingRestaurantItem;
    let openModal =
      failedRestaurant || failedRestaurantItem || openModalConfirmation;
    let filterData = FilterData(listAccessCity, 'Name', searchInput);
    let pageDataCity = datasetPagination(filterData, 7);
    let listDataCity = pageDataCity[activePageModalAccessibleCity - 1];
    // let pagedData = datasetPagination(
    //   restaurantProfiledId.RestaurantProfileImages,
    //   3
    // );
    // let listDataImage = pagedData[activePage - 1];
    let filterDataAccessCity = restaurantProfiledId.AccessibleFrom;
    // FilterData(
    //   restaurantProfiledId.AccessibleFrom,
    //   'Name',
    //   searchInput
    // );
    let pagedDataAccessCity = datasetPagination(filterDataAccessCity, 3);
    let listDataAccessCity = pagedDataAccessCity[this.state.activePage - 1];
    return (
      <div>
        <Dimmer.Dimmable blurring dimmed={loading}>
          <Loader loading={loading} />
          <SegmentRestaurantProfile
            RestaurantProfileImages={
              restaurantProfiledId.RestaurantProfileImages
            }
            filterDataAccessCity={filterDataAccessCity}
            handleChange={this.onChange}
            handleChangeAddress={this.onChangeAddress}
            newRestaurant={this.newRestaurant}
            listCountry={this.props.listCountry}
            listArea={this.props.listArea}
            listRegion={this.props.listRegion}
            listCity={this.props.listCity}
            dataRestaurantProfile={restaurantProfiledId}
            listRestaurantClass={this.props.restaurantRating}
            listRestaurantSpecialty={this.props.restaurantSpecializations}
            restaurantItemList={this.state.restaurantItemByProfileId}
            saveRestaurant={this.saveRestaurant}
            errors={this.state.errors}
            buttonUploadData={this.ButtonUploadDataItem}
            butttonExport={this.ButtonExport}
            buttonDownloadTemplate={this.ButtonDownloadTemplate}
            title={this.state.titleHeader}
            backPage={this.buttonOnBackPage}
            modalItem={this.modalItemAction}
            deleteState={this.deleteState}
            openDetail={this.openDetail}
            handleChangePrimaryImage={this.handleChangePrimaryImage}
            setStartTime={this.setStartTime}
            setEndTime={this.setEndTime}
            addData={this.onClickModalAccCity}
            accessCity={this.state.accessCity}
            deleteData={this.deleteAccessCity}
            handleClickEdit={this.handleClickEdit}
            contentButton={this.state.contentButton}
            disablePage={this.state.disablePage}
            activePage={activePage}
            // pagedData={pagedData}
            pageChange={this.pageChangeCity}
            listDataImage={restaurantProfiledId.RestaurantProfileImages}
            idProfile={this.state.restaurantProfiledId}
            listDataAccessCity={listDataAccessCity}
            pagedDataAccessCity={pagedDataAccessCity}
            handleClickDelete={this.confirmDeleteRestaurantItem}
            listCityByRegionForContactInfo={
              this.state.listCityByRegionForContactInfo
            }
          />
          <ModalAlert
            openModal={openModal}
            handleClose={this.handleClose}
            header={this.state.headerAlert}
            content={this.state.contentAlert}
            nameButton="OK"
          />
          <ModalImage
            openModal={this.state.openModalImage}
            imagePreview={this.state.imageUrl}
            handleChange={this.changeImage}
            addImage={this.addImage}
            handleClose={this.handleClose}
            header="Upload Image"
            image={this.state.restaurantProfiledId.RestaurantProfileImages}
            listStepImage={this.state.listStepImage}
            handleNext={this.handleNext}
            handleBackStep={this.handleBackStep}
            indexActive={this.state.indexActive}
            handleChangePrimaryImage={this.handleChangePrimaryImage}
            imageRatio={this.state.image}
          />
          <ModalAccessibleCity
            header="Accessible City"
            openModal={this.state.openModalAccCity}
            handleClose={this.handleClose}
            listData={listDataCity}
            handleChangeAccessCity={this.handleChangeAccessCity}
            pageChange={this.pageChangeModalAccessibleCity}
            activePage={this.state.activePageModalAccessibleCity}
            pageData={pageDataCity}
            addAccessCity={this.addAccessCity}
            searchInput={searchInput}
            handleChange={this.handleSearchAccesibleCity}
            regionForAccessibleCity={regionForAccessibleCity}
            listAllRegions={listAllRegions}
            handleChangeRegionAccessibleCity={
              this.handleChangeRegionAccessibleCity
            }
            listCityByRegionForAccessibleCity={this.props.listCity}
            loadingGetCityByRegion={this.props.loadingGetCityByRegion}
          />
          <ModalConfirmation
            openModal={this.state.openModalDeleteCity}
            close={this.closeDeleteConfirm}
            confirm={this.confirmDelete}
            message={this.state.messageDeleteCity}
            size="tiny"
          />
          <ModalConfirmation
            openModal={this.state.openModalRestaurantItem}
            close={this.closeDeleteConfirm}
            confirm={this.handleClickDelete}
            message={this.state.messageDeleteRestaurantItem}
            size="tiny"
          />
        </Dimmer.Dimmable>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    getRestaurantByProfileId: state.RestaurantStore.getRestaurantByProfileId,
    getRestaurantByProfileIdStatus:
      state.RestaurantStore.getRestaurantByProfileIdStatus,
    getRestaurantItemByProfileId:
      state.RestaurantStore.getRestaurantItemByProfileId,
    getRestaurantItemByProfileIdStatus:
      state.RestaurantStore.getRestaurantItemByProfileIdStatus,
    listCountry: convertOptions(state.CountryStore.getCountryList),
    listRegion: convertOptions(state.RegionStore.getRegionList),
    listCity: state.CityStore.getCityList.map(type => {
      return {
        key: type.Id,
        text: type.Name,
        value: type.Id,
        completeData: type,
      };
    }),
    listArea: convertOptions(state.AreaStore.getAreaList),
    listRestaurantClass: convertOptions(
      state.RestaurantStore.getRestaurantClass
    ),
    listRestaurantSpecialty: convertOptions(
      state.RestaurantStore.getRestaurantType
    ),
    getRestaurantItemTemplate: state.RestaurantStore.getRestaurantItemTemplate,
    getRestaurantItemExport: state.RestaurantStore.getExportDataItemById,
    getRestaurantItemExportStatus:
      state.RestaurantStore.getExportDataItemByIdStatus,
    getRestaurantItemTemplateStatus:
      state.RestaurantStore.getRestaurantItemTemplateStatus,
    getCityInCountryList: state.CityStore.getCityInCountryList,
    postRestaurantStatus: state.RestaurantStore.postRestaurantStatus,
    putRestaurantStatus: state.RestaurantStore.putRestaurantStatus,
    errors: state.RestaurantStore.errors,
    listAccessCity: state.CityStore.getCityList.map(city => {
      return {
        Id: city.Id,
        Name: city.Name,
        Status: false,
        CompleteData: city,
      };
    }),
    postRestaurant: state.RestaurantStore.postRestaurant,
    restaurantSpecializations: state.RestaurantStore.restaurantSpecializations.map(
      type => {
        return { text: type.Name, value: type.Id, key: type.Id };
      }
    ),
    menuClasses: state.RestaurantStore.menuClasses.map(type => {
      return { text: type, value: type, key: type };
    }),
    restaurantRating: state.RestaurantStore.restaurantRating.map(dataObject => {
      return {
        text: dataObject.Name,
        value: dataObject.Id,
        key: dataObject.Id,
      };
    }),
    deleteRestaurantItemByIdStatus:
      state.RestaurantStore.deleteRestaurantItemByIdStatus,
    listAllRegions: state.RegionStore.getRegion.map((region, i) => {
      return {
        key: i,
        text: region.Name,
        value: region.Id,
        completeData: region,
      };
    }),
    loadingGetCityByRegion: state.CityStore.loading,
  };
}
export default connect(mapStateToProps, {
  getRestaurantByProfileIdAction,
  getRestaurantItemByProfileIdAction,
  resetRestaurantAction,
  getCountryListAction,
  getRegionListAction,
  getAreaListAction,
  getCityListAction,
  getRestaurantClassAction,
  getRestaurantTypeAction,
  postRestaurantProfileAction,
  putRestaurantProfileAction,
  getTemplateRestaurantItemAction,
  getExportDataItemByIdAction,
  getCityInCountry,
  getRestaurantListAction,
  postUploadImageRestaurantProfileAction,
  getRestaurantProfileSpecializationsAction,
  getMenuClassesAction,
  getRestaurantRatingAction,
  deleteRestaurantItemByIdAction,
  getRegionAction,
})(RestaurantProfile);
