import React from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Loader } from 'semantic-ui-react';
import { headerTablePrice } from '../../constants/headerTable';
import { formatCurrency } from '../../../../../components/genericFunction/convertFormat';

const TablePrice = props => {
  const {
    data,
    listData,
    // loading,
    handleEdit,
    handleDelete,
    additionalCostLoading,
    saveLoading,
    activePage,
    dataPerPage,
  } = props;
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          {headerTablePrice.map((header, index) => {
            return (
              <Table.HeaderCell
                key={index}
                textAlign={header === 'Action' ? 'center' : ''}
              >
                {header}
              </Table.HeaderCell>
            );
          })}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {listData && listData.length ? (
          listData.map((item, idx) => {
            return (
              <Table.Row key={idx}>
                <Table.Cell width={4}>
                  {item.TransportationAdditionalCostName}
                </Table.Cell>
                <Table.Cell width={4}>
                  {item.TransportationAdditionalCostType.Value === '1'
                    ? 'Return'
                    : 'Per-Destination'}
                </Table.Cell>
                <Table.Cell width={4}>
                  {` ${data &&
                    data.Currency &&
                    data.Currency.Value} ${formatCurrency(item.Amount)}`}
                </Table.Cell>
                <Table.Cell width={4} textAlign="center">
                  <Button
                    idx={dataPerPage * (activePage - 1) + idx}
                    content="Edit"
                    positive
                    onClick={handleEdit}
                  />
                  <Button
                    idx={dataPerPage * (activePage - 1) + idx}
                    content="Delete"
                    negative
                    onClick={handleDelete}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })
        ) : (
          <Table.Row>
            <Table.Cell colSpan="4" textAlign="center">
              {additionalCostLoading || saveLoading ? (
                <Loader active inline="centered" />
              ) : (
                'No Record Data'
              )}
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};

TablePrice.propTypes = {
  data: PropTypes.object,
  listData: PropTypes.array,
  loading: PropTypes.bool,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  additionalCostLoading: PropTypes.bool,
  saveLoading: PropTypes.bool,
  activePage: PropTypes.number,
  dataPerPage: PropTypes.number,
};

export default React.memo(TablePrice);
