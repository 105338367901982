import React, { Component } from 'react';
import {
  Segment,
  Grid,
  Input,
  Pagination,
  Button,
  Container,
} from 'semantic-ui-react';
import TableBookingStatusList from './table/TableBookingStatusList';
import {
  getListConfirmRoomAllotments,
  getResendRequestConfirmation,
} from '../../../pages/shared/actions/accommodationAction';
// from '../../../actions/accomodationAction';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ModalAlert from '../../shared/components/modal/ModalAlert';
import ModalConfirm from '../../shared/components/modal/ModalConfirmationAlert';
import HeaderTitle from '../../shared/components/common/headerSegment/HeaderTitle';
import ModalDateRange from '../../shared/components/modal/ModalDateRange';
import { convertDateFormat, addDate } from '../../../scripts/moment';
import moment from 'moment';
import { getNumberFromTo } from '../../../scripts/pagination';
let TODAY = new Date();
class SegmentBookingStatusList extends Component {
  state = {
    activePage: 1,
    modalAlertOpen: false,
    modalAlertHeader: '',
    modalAlertMessage: '',
    modalConfirmOpen: false,
    modalConfirmHeader: '',
    modalConfirmMessage: '',
    modalFilterDateOpen: false,
    dataPost: {},
    actionType: '',
    searchInput: '',
    filterByDate: {
      from: TODAY,
      to: addDate(TODAY, 30, 'd'),
      tourStartDate: moment(new Date()).format('YYYY-MM-DD'),
      tourUntilDate: moment()
        .add(30, 'd')
        .format('YYYY-MM-DD'),
      createdStartDate: '',
      createdUntilDate: '',
    },
    radioValueDateRange: '1',
  };

  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });

  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  componentDidMount() {
    let { tourStartDate, tourUntilDate } = this.state.filterByDate;
    this.props.getListConfirmRoomAllotments(
      convertDateFormat(tourStartDate, 'YYYY-MM-DD'),
      convertDateFormat(tourUntilDate, 'YYYY-MM-DD'),
      '',
      ''
    );
  }
  handleOpenModalAlert = (e, { value }) =>
    this.setState({
      ...this.state,
      modalAlertOpen: true,
      modalAlertHeader: value && value.header,
      modalAlertMessage: value && value.message,
      dataPost: value && value.post,
    });

  handleOpenModalConfirm = (e, { value }) =>
    this.setState({
      ...this.state,
      modalConfirmOpen: true,
      modalConfirmHeader: value && value.header,
      modalConfirmMessage: value && value.message,
      dataPost: value && value.post,
      actionType: value && value.type,
    });

  handleCloseAlert = () =>
    this.setState({ ...this.state, modalAlertOpen: false });

  handleDeclineConfirm = () =>
    this.setState({ ...this.state, modalConfirmOpen: false });

  handleAcceptConfirm = () => {
    const { dataPost } = this.state;
    const { TransactionId, Token } = dataPost;
    this.props
      .getResendRequestConfirmation(TransactionId, Token)
      .then(async () => {
        await this.openModalAlert('Success', 'Please check email');
        this.handleDeclineConfirm();
      })
      .catch(async () => {
        await this.openModalAlert('Failed', 'Failed resend room confirmation');
        this.handleDeclineConfirm();
      });
  };

  openModalAlert = (modalAlertHeader, modalAlertMessage) =>
    this.setState({
      ...this.state,
      modalAlertOpen: true,
      modalAlertHeader,
      modalAlertMessage,
    });

  pagination = (data, perPage) => {
    let sets = [],
      chunks = data.length / perPage;
    for (let i = 0, j = 0; i < chunks; i++, j += perPage)
      sets[i] = data.slice(j, j + perPage);
    return sets;
  };
  filterData = (listData, searchInput) => {
    if (listData.length !== 0) {
      return listData.filter(
        e =>
          e.AccommodationProfileName.toLowerCase().indexOf(
            searchInput.toLowerCase()
          ) !== -1 ||
          e.AccommodationItemName.toLowerCase().indexOf(
            searchInput.toLowerCase()
          ) !== -1 ||
          e.TransactionId.toLowerCase().indexOf(searchInput) !== -1
      );
    } else {
      return [];
    }
  };

  handleModalFilter = () =>
    this.setState(state => ({
      ...state,
      modalFilterDateOpen: !state.modalFilterDateOpen,
    }));
  applyFilter = id => {
    const value = this.state.radioValueDateRange;
    const { from, to } = id;
    let filterByDate = { ...this.state.filterByDate };
    if (value === '1') {
      this.props.getListConfirmRoomAllotments(
        convertDateFormat(from, 'YYYY-MM-DD'),
        convertDateFormat(to, 'YYYY-MM-DD'),
        '',
        ''
      );
      filterByDate.tourStartDate = convertDateFormat(from, 'YYYY-MM-DD');
      filterByDate.tourUntilDate = convertDateFormat(to, 'YYYY-MM-DD');
      filterByDate.createdStartDate = '';
      filterByDate.createdUntilDate = '';
    } else if (value === '2') {
      this.props.getListConfirmRoomAllotments(
        '',
        '',
        convertDateFormat(from, 'DD MMM YYYY'),
        convertDateFormat(to, 'DD MMM YYYY')
      );
      filterByDate.tourStartDate = '';
      filterByDate.tourUntilDate = '';
      filterByDate.createdUntilDate = convertDateFormat(to, 'YYYY-MM-DD');
      filterByDate.createdStartDate = convertDateFormat(from, 'YYYY-MM-DD');
    }
    this.setState({ ...this.state, filterByDate, activePage: 1 }, async () => {
      await this.handleModalFilter();
    });
  };
  handleRadioDateRange = value => {
    this.setState({ radioValueDateRange: value });
  };

  render() {
    const {
      listConfirmRoomAllotment,
      loadingGetListConfirmRoomAllotment,
      loadingGetResendRequestConfirm,
    } = this.props;
    const {
      activePage,
      modalAlertOpen,
      modalAlertHeader,
      modalAlertMessage,
      modalConfirmOpen,
      modalConfirmHeader,
      modalConfirmMessage,
      modalFilterDateOpen,
      searchInput,
      filterByDate,
    } = this.state;
    const sumRow = 5;
    let listConfirmRoomAllotmentFilter =
      searchInput !== ''
        ? this.filterData(listConfirmRoomAllotment, searchInput, filterByDate)
        : listConfirmRoomAllotment;
    let pagedData = this.pagination(listConfirmRoomAllotmentFilter, sumRow);
    let listData = pagedData.length !== 0 ? pagedData[activePage - 1] : [];

    let { numbersFrom, numbersTo } = getNumberFromTo(
      listData,
      activePage,
      sumRow
    );
    let createdUntilDate =
      filterByDate.createdUntilDate === ''
        ? ''
        : moment(filterByDate.createdUntilDate).format('DD MMM YYYY');
    let createdStartDate =
      filterByDate.createdStartDate === ''
        ? ''
        : moment(filterByDate.createdStartDate).format('DD MMM');
    let tourStartDate =
      filterByDate.tourStartDate === ''
        ? ''
        : moment(filterByDate.tourStartDate).format('DD MMM');
    let tourUntilDate =
      filterByDate.tourUntilDate === ''
        ? ''
        : moment(filterByDate.tourUntilDate).format('DD MMM YYYY');
    return (
      <div style={{ margin: '25px 0px' }}>
        <Grid columns="equal">
          <Grid.Row stretched>
            <Grid.Column width={15}>
              <Segment>
                <HeaderTitle title="Item Booking Status List" />
                {/* Room Request */}
                <Input
                  icon="search"
                  placeholder="Search"
                  name="searchInput"
                  value={searchInput}
                  onChange={this.handleChange}
                />
                <Button
                  icon="filter"
                  basic
                  size="small"
                  floated="right"
                  positive
                  onClick={this.handleModalFilter}
                />
                <div
                  style={{
                    marginTop: '-2.9em',
                    marginRight: '5em',
                    fontWeight: 'bold',
                  }}
                >
                  <Container
                    content={
                      'Start tour range date : ' +
                      tourStartDate +
                      ' - ' +
                      tourUntilDate
                    }
                    textAlign="right"
                  />
                  <Container
                    content={
                      'Created tour range date : ' +
                      createdStartDate +
                      ' - ' +
                      createdUntilDate
                    }
                    textAlign="right"
                  />
                </div>
                <TableBookingStatusList
                  listConfirmRoomAllotment={listData}
                  loading={loadingGetListConfirmRoomAllotment}
                  handleOpenModalAlert={this.handleOpenModalAlert}
                  handleOpenModalConfirm={this.handleOpenModalConfirm}
                />
                {pagedData && pagedData.length > 1 && (
                  <Pagination
                    totalPages={pagedData.length}
                    onPageChange={this.pageChange}
                    activePage={activePage}
                    style={{ float: 'right' }}
                  />
                )}
                {pagedData && pagedData.length > 1 && (
                  <p>
                    Showing {numbersTo} to {numbersFrom} of
                    {listConfirmRoomAllotment.length}
                  </p>
                )}
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ModalAlert
          openModal={modalAlertOpen}
          handleClose={this.handleCloseAlert}
          header={modalAlertHeader}
          content={modalAlertMessage}
        />
        <ModalConfirm
          openModal={modalConfirmOpen}
          size="mini"
          close={this.handleDeclineConfirm}
          confirm={this.handleAcceptConfirm}
          message={modalConfirmMessage}
          header={modalConfirmHeader}
          loading={loadingGetResendRequestConfirm}
        />
        <ModalDateRange
          actionDateRange={this.applyFilter}
          openModal={modalFilterDateOpen}
          handleClose={this.handleModalFilter}
          dateRange={this.state.filterByDate}
          contentHeader="Filter List Of Item Booking Status"
          value={this.state.radioValueDateRange}
          handleRadioDateRange={this.handleRadioDateRange}
        />
      </div>
    );
  }
}
SegmentBookingStatusList.propTypes = {
  listConfirmRoomAllotment: PropTypes.array,
  getListConfirmRoomAllotments: PropTypes.func,
  loadingGetListConfirmRoomAllotment: PropTypes.bool,
  getResendRequestConfirmation: PropTypes.func,
  loadingGetResendRequestConfirm: PropTypes.bool,
};
function mapStateToProps(state) {
  return {
    loadingGetListConfirmRoomAllotment:
      state.AccomodationStore.loadingGetListConfirmRoomAllotment,
    listConfirmRoomAllotment: state.AccomodationStore.listConfirmRoomAllotment,
    loadingGetResendRequestConfirm:
      state.AccomodationStore.loadingGetResendRequestConfirm,
  };
}
export default connect(mapStateToProps, {
  getListConfirmRoomAllotments,
  getResendRequestConfirmation,
})(SegmentBookingStatusList);
