import {
  GET_EXPORT_TRANSPORTATION_UNIT_DATA,
  GET_TRANSPORTATION_UNIT_BY_HEADER_ID,
  POST_TRANSPORTATION_UNIT_HEADER,
  PUT_TRANSPORTATION_UNIT_HEADER,
  PUT_TRANSPORTATION_UNIT,
  POST_TRANSPORTATION_UNIT,
  DELETE_TRANSPORTATION_UNIT_HEADER,
  DELETE_TRANSPORTATION_UNIT,
  DELETE_IMAGE_TRANSPORTATION_UNIT,
  GET_EXPORT_DATA_ITEM_BY_ID,
} from './transportationProfile/transportationUnit/constant/actionTypes';

import {
  GET_TRANSPORTATION_UNIT_BY_PROFILE_ID,
  GET_TRANSPORTATION_CATAGORIES,
  GET_EXPORT_TRANSPORTATION_PROFILE,
  DELETE_TRANSPORTATION_BY_PROFILE_ID,
  POST_TRANSPORTATION,
  PUT_TRANSPORTATION,
} from './transportationProfile/constant/actionTypes';

import deleteTransportationProfileIdApi from '../../api/transportationApi/deleteTransportationProfileIdApi';
import postTransportationProfileApi from '../../api/transportationApi/postTransportationProfileApi';
import getTransportationUnitListApi from '../../api/transportationApi/getTransportationUnitListApi';
import getTransportationCatagoriesApi from '../../api/transportationApi/getTransportationCatagoriesApi';
import putTransportationProfileApi from '../../api/transportationApi/putTransportationProfileApi';
import getExportTransportationUnitApi from '../../api/transportationApi/getExportTransportationUnitApi';
import getTransportationUnitByHeaderIdApi from '../../api/transportationApi/getTransportationUnitByHeaderIdApi';
import getExportTransportationProfileApi from '../../api/transportationApi/getExportTransportationProfileApi';
import postTransportationUnitHeaderApi from '../../api/transportationApi/postTransportationUnitHeaderApi';
import putTransporationUnitHeaderApi from '../../api/transportationApi/putTransporationUnitHeaderApi';
import postTransportationUnitApi from '../../api/transportationApi/postTransportationUnitApi';
import putTransportationUnitApi from '../../api/transportationApi/putTransportationUnitApi';
import deleteTransportationUnitHeaderApi from '../../api/transportationApi/deleteTransportationUnitHeaderApi';
import deleteTrasportationUnitApi from '../../api/transportationApi/deleteTrasportationUnitApi';
import deleteImageTransportationUnitApi from '../../api/transportationApi/deleteImageTransportationUnitApi';
import getExportDataItemByIdApi from '../../api/supplier/getExportDataItemByIdApi';

const deleteTransportationProfileIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: DELETE_TRANSPORTATION_BY_PROFILE_ID,
      payload: deleteTransportationProfileIdApi(Id),
    });
  };
};

const postTransportationProfileAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_TRANSPORTATION,
      payload: postTransportationProfileApi(data),
    });
  };
};

const getTransportationUnitByTransportationUnitIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSPORTATION_UNIT_BY_PROFILE_ID,
      payload: getTransportationUnitListApi(Id),
    });
  };
};

const getTransportationCatagoriesAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSPORTATION_CATAGORIES,
      payload: getTransportationCatagoriesApi(),
    });
  };
};
const putTransportationProfileAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_TRANSPORTATION,
      payload: putTransportationProfileApi(id, data),
    });
  };
};
const getExportTransportationUnitAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_EXPORT_TRANSPORTATION_UNIT_DATA,
      payload: getExportTransportationUnitApi,
    });
  };
};

const getTransportationUnitByHeaderIdAction = id => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSPORTATION_UNIT_BY_HEADER_ID,
      payload: getTransportationUnitByHeaderIdApi(id),
    });
  };
};

const getExportTransportationProfileAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_EXPORT_TRANSPORTATION_PROFILE,
      payload: getExportTransportationProfileApi,
    });
  };
};

const postTransportationUnitHeaderAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_TRANSPORTATION_UNIT_HEADER,
      payload: postTransportationUnitHeaderApi(data),
    });
  };
};

const putTransporationUnitHeaderAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_TRANSPORTATION_UNIT_HEADER,
      payload: putTransporationUnitHeaderApi(id, data),
    });
  };
};

const postTransportationUnitAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_TRANSPORTATION_UNIT,
      payload: postTransportationUnitApi(data),
    });
  };
};

const putTransportationUnitAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_TRANSPORTATION_UNIT,
      payload: putTransportationUnitApi(id, data),
    });
  };
};

const deleteTransportationUnitHeaderAction = Id => {
  return dispatch => {
    return dispatch({
      type: DELETE_TRANSPORTATION_UNIT_HEADER,
      payload: deleteTransportationUnitHeaderApi(Id),
    });
  };
};

const deleteTrasportationUnitAction = Id => {
  return dispatch => {
    return dispatch({
      type: DELETE_TRANSPORTATION_UNIT,
      payload: deleteTrasportationUnitApi(Id),
    });
  };
};

const deleteImageTransportationUnitAction = Id => {
  return dispatch => {
    return dispatch({
      type: DELETE_IMAGE_TRANSPORTATION_UNIT,
      payload: deleteImageTransportationUnitApi(Id),
    });
  };
};

const getExportDataItemByIdAction = (supplierType, profileId) => {
  return dispatch => {
    return dispatch({
      type: GET_EXPORT_DATA_ITEM_BY_ID,
      payload: getExportDataItemByIdApi(supplierType, profileId),
    });
  };
};

export {
  deleteTransportationProfileIdAction,
  postTransportationProfileAction,
  getTransportationUnitByTransportationUnitIdAction,
  getTransportationCatagoriesAction,
  putTransportationProfileAction,
  getExportTransportationUnitAction,
  getTransportationUnitByHeaderIdAction,
  getExportTransportationProfileAction,
  postTransportationUnitHeaderAction,
  putTransporationUnitHeaderAction,
  putTransportationUnitAction,
  postTransportationUnitAction,
  deleteTransportationUnitHeaderAction,
  deleteTrasportationUnitAction,
  deleteImageTransportationUnitAction,
  getExportDataItemByIdAction,
};
