import React from 'react';
import { Dimmer } from 'semantic-ui-react';
import { connect } from 'react-redux';
import ModalAlert from '../../../shared/components/modal/ModalAlert';
import Loader from '../../../../components/loader';
import AccomodationItemForm from './components/AccommodationItemForm';
import ModalItem from '../../../shared/components/modal/accommodation/ModalItemAccomodationProfile';
import ModalImage from '../../../../components/modal/modalImage';
import ModalConfirm from '../../../../components/modal/modalConfirmationTwoButton';
import {
  getServicePriceTypeAction,
  getServicePriceAction,
  postServicePriceAction,
  putServicePriceAction,
  deleteServicePriceAction,
  resetServicePriceAction,
} from '../../../../actions/servicePriceAction';
import { datasetPagination } from '../../../../scripts/pagination';
import {
  getAccomodationItemTypesAction,
  getAccomodationItemServiceTypeAction,
  postAccomodationItemAction,
  putAccomodationItemAction,
  getAccomodationFacilitiesAction,
} from '../../accommodationAction';
import {
  getAccomodationItemByServiceTypeIdAction, //
  resetAccomodationAction, //
  getAccomodationByProfiledIdAction, //
} from '../../../shared/actions/accommodationAction';
import { getCurrenciesAction } from '../../../../actions/currenciesAction';
import PropTypes from 'prop-types';
import moment from 'moment';
import headerTableLocationAccomodationItemPrice from './constant/tableHeader';
import { getTime } from '../../../../scripts/moment';
import {
  groupingSupplierPriceDetails,
  filterByService,
} from './services/helper/dataGrouping';
import {
  existingPriceValidation,
  existingMinPaxValidation,
  zeroPriceValidation,
} from './services/validation/priceValidation';
class AccomodationItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accomodationItem: {
        AccommodationItemImages: [],
        SimpleAccommodationItemFacilities: [],
        imageUrl: '',
        Desc: '',
        Description: '',
        AccommodationItemTypeId: '',
        ExtraBedCapacity: '',
        MinDays: '',
        Explanation: '',
        IsPromo: false,
      },
      openModalFacilities: false,
      facilitiesAccomodation: [],
      imageAccomodation: [],
      indexActive: 0,
      imageUrl: '',
      openModalImage: false,
      dataItem: [],
      accomodationPrice: {},
      accomodationPriceList: [],
      listAccomodationPrice: [],
      serviceType: 'With_breakfast',
      loadingAccomodationItem: true,
      failedAccomodationItem: false,
      openModalPrice: false,
      editIndex: -1,
      listItemTypeData: [],
      rankType: [
        {
          key: 1,
          text: 'Standard',
          value: 1,
        },
        {
          key: 2,
          text: 'Hight Priority',
          value: 2,
        },
        {
          key: 3,
          text: 'Promo',
          value: 3,
        },
        {
          key: '4',
          text: 'Others',
          value: 4,
        },
      ],
      listStepImage: [
        {
          Tittle: 'Choose Image',
          Active: true,
        },
        {
          Tittle: 'Select Thumbnail Image',
          Active: false,
        },
        {
          Tittle: 'Edit Thumbnail Image',
          Active: false,
        },
      ],
      errors: {},
      modalConfirmation: false,
      disablePage: true,
      activePage: 1,
      deletedPrice: [],
      tempDeletedPrice: [],
    };
  }

  static propTypes = {
    getAccomodationItemByServiceTypeIdAction: PropTypes.func,
    resetAccomodationAction: PropTypes.func,
    statusAccomodationItemByServiceType: PropTypes.string,
    listAccomodationItemByServiceType: PropTypes.object,
    getAccomodationItemTypesAction: PropTypes.func,
    getAccomodationItemServiceTypeAction: PropTypes.func,
    listItemTypeData: PropTypes.array,
    listServiceTypesData: PropTypes.array,
    getServicePriceTypeAction: PropTypes.func,
    currencies: PropTypes.array,
    getCurrenciesAction: PropTypes.func,
    match: PropTypes.shape({
      params: PropTypes.string,
    }),
    getServicePriceAction: PropTypes.func,
    getSupplierPriceStatus: PropTypes.string,
    supplierPriceList: PropTypes.array,
    resetServicePriceAction: PropTypes.func,
    getAccomodationItemTypesStatus: PropTypes.string,
    getAccomodationItemTypes: PropTypes.array,
    putServicePriceAction: PropTypes.func,
    postServicePriceAction: PropTypes.func,
    deleteServicePriceAction: PropTypes.func,
    postAccomodationItemAction: PropTypes.func,
    putAccomodationItemAction: PropTypes.func,
    getAccomodationFacilitiesAction: PropTypes.func,
    listAccomodationFacilities: PropTypes.array,
    postAccomodationItemStatus: PropTypes.string,
    putAccomodationItemStatus: PropTypes.string,
    errorsAccomodationItem: PropTypes.object,
    putSupplierPriceStatus: PropTypes.string,
    errorsServicePrice: PropTypes.object,
    postSupplierPriceStatus: PropTypes.string,
    postAccomodationItem: PropTypes.array,
    deleteSupplierPriceStatus: PropTypes.string,
    getAccomodationByProfiledIdAction: PropTypes.func,
  };
  handleCloseModalItem = () => {
    this.setState({
      openModalFacilities: false,
      openModalImage: false,
    });
  };
  componentDidMount() {
    this.getAccomodationProfileId();
  }

  getAccomodationProfileId = () => {
    // eslint-disable-next-line
    const { ServiceTypeId, ProfileId } = this.props.match.params;
    this.props
      .getAccomodationByProfiledIdAction(ProfileId)
      .then(res =>
        this.setState({ accomodationProfileName: res.value.data.Name })
      )
      .catch(() => this.handleFailedMessage());
    this.props.getAccomodationItemTypesAction();
    this.props.getAccomodationItemServiceTypeAction();
    this.props.getCurrenciesAction();
    this.props.getServicePriceTypeAction('AccommodationItem');
    this.props.getServicePriceAction(ServiceTypeId);
    this.props.getAccomodationFacilitiesAction();
    if (ServiceTypeId === undefined) {
      this.setState({
        loadingAccomodationItem: false,
        titleHeader: 'Add Accomodation Item',
        contentButton: 'Save',
        disablePage: false,
        accomodationItem: {
          ...this.state.accomodationItem,
          AccommodationProfileId: ProfileId,
          ServiceItemId: '',
        },
      });
    } else {
      return this.getAllAccomodationItem(ServiceTypeId);
    }
  };

  redirectFunction = (data, Id) => {
    const { ServiceTypeId, ProfileId } = this.props.match.params;
    if (data === 'POST') {
      this.getAllAccomodationItem(Id);
      //eslint-disable-next-line
      this.props.history.push(`/Accomodation-Item/Edit/${ProfileId}/${Id}`);
    } else {
      this.getAllAccomodationItem(ServiceTypeId);
      this.props.getServicePriceAction(ServiceTypeId);
      this.setState({ serviceType: 'With_breakfast' });
    }
  };

  onChangeCheckbox = (e, { name, checked }) => {
    const accomodationItem = this.state.accomodationItem;
    accomodationItem[name] = checked;
    this.setState({
      accomodationItem: accomodationItem,
    });
  };
  onChange = (e, { value, name }) => {
    const accomodationItem = this.state.accomodationItem;
    accomodationItem[name] = value;
    accomodationItem['onChange'] = true;
    var filterType = {};

    if (name === 'AccommodationItemTypeId') {
      let listFilter = this.props.listItemTypeData;
      listFilter.map(type => {
        if (type.value === value) {
          filterType = type.key;
        }
        return (accomodationItem['ExtraBedCapacity'] = filterType);
      });
    }
    if (name === 'IsPromo') {
      this.handleCheckClick();
    } else {
      this.setState({
        // accomodationItem: accomodationItem,
        accomodationItem: accomodationItem,
      });
    }
  };

  handleCheckClick = () => {
    this.setState({
      accomodationItem: {
        ...this.state.accomodationItem,
        IsPromo: !this.state.accomodationItem.IsPromo,
      },
    });
  };

  getAllAccomodationItem = id => {
    this.props.getAccomodationItemByServiceTypeIdAction(id);
    const { Type } = this.props.match.params;
    if (Type === 'Detail') {
      this.setState({
        titleHeader: 'Detail Accommodation Item',
        serviceType: 'With_breakfast',
        contentButton: 'Edit',
        disablePage: true,
        accomodationItem: {
          ...this.state.accomodationItem,
          ServiceItemId: id,
        },
      });
    } else {
      this.setState({
        titleHeader: 'Edit Accommodation Item',
        serviceType: 'With_breakfast',
        contentButton: 'Save',
        disablePage: false,
        accomodationItem: {
          ...this.state.accomodationItem,
          ServiceItemId: id,
        },
      });
    }
  };

  componentDidUpdate() {
    if (this.props.getAccomodationItemTypesStatus === 'success') {
      this.setState({ listItemTypeData: this.props.listItemTypeData });
      this.props.resetAccomodationAction();
    }
    if (this.props.statusAccomodationItemByServiceType === 'success') {
      this.setStateAccomodationItem(
        this.props.listAccomodationItemByServiceType
      );
      this.setState({
        loadingAccomodationItem: false,
      });
      this.props.resetAccomodationAction();
    }
    if (this.props.statusAccomodationItemByServiceType === 'failed') {
      this.setState({
        failedAccomodationItem: true,
        headerAlert: 'Failed',
        contentAlert:
          'Please check your internet connection and Refresh Page [Ctrl+R]',
        loadingAccomodationItem: false,
      });
      this.props.resetAccomodationAction();
    }
    if (this.props.getSupplierPriceStatus === 'success') {
      // this.filterSupplierPriceDetails(this.filterSupplierPrice());
      this.filterSupplierPriceDetails();
      this.props.resetServicePriceAction();
    }
    if (this.props.postAccomodationItemStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Saving Accomodation Item Successfully',
        modalConfirmation: true,
        loadingAccomodationItem: false,
        disablePage: true,
      });
      this.redirectFunction(
        'POST',
        this.props.postAccomodationItem.ServiceItemId
      );
      this.props.resetAccomodationAction();
    } else if (this.props.postAccomodationItemStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorsAccomodationItem.message,
        modalConfirmation: true,
        loadingAccomodationItem: false,
      });
      this.props.resetAccomodationAction();
    }
    if (this.props.putAccomodationItemStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Saving Accomodation Item Successfully',
        modalConfirmation: true,
        loadingAccomodationItem: true,
      });
      this.redirectFunction();
      this.props.resetAccomodationAction();
    } else if (this.props.putAccomodationItemStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorsAccomodationItem.message,
        modalConfirmation: true,
      });
      this.props.resetAccomodationAction();
    }

    if (this.props.putSupplierPriceStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Edit Price Successfully',
        modalConfirmation: true,
        loadingAccomodationItem: false,
      });
      this.redirectFunction();
      this.props.resetServicePriceAction();
    } else if (this.props.putSupplierPriceStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorsServicePrice.global,
        modalConfirmation: true,
      });
      this.props.resetServicePriceAction();
    }
    if (this.props.postSupplierPriceStatus === 'success') {
      this.setState({
        // headerAlert: 'Success',
        // contentAlert: 'Saving Price Successfully',
        // modalConfirmation: true,
        loadingAccomodationItem: false,
      });
      this.redirectFunction();
      this.props.resetServicePriceAction();
    } else if (this.props.postSupplierPriceStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorsServicePrice.global,
        modalConfirmation: true,
      });
      this.props.resetServicePriceAction();
    }

    if (this.props.deleteSupplierPriceStatus === 'success') {
      this.setState({
        // headerAlert: 'Success',
        // contentAlert: 'Delete Price Successfully',
        // modalConfirmation: true,
        loadingAccomodationItem: false,
      });
      this.redirectFunction();
      this.props.resetServicePriceAction();
    } else if (this.props.deleteSupplierPriceStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorsServicePrice.global,
        modalConfirmation: true,
      });
      this.props.resetServicePriceAction();
    }
  }
  filterSupplierPriceDetails = () => {
    let groupingPrice = groupingSupplierPriceDetails(
      this.props.supplierPriceList
    );
    let groupingPriceByService = filterByService(
      groupingPrice,
      'With_breakfast'
    );
    this.setState({
      accomodationPriceList: groupingPrice,
      listAccomodationPrice: groupingPriceByService,
    });
  };
  // filterSupplierPriceDetails = () => {
  //   let groupingPrice = this.props.supplierPriceList.reduce(function(
  //     p,
  //     v,
  //     index
  //   ) {
  //     let existing = p.filter(function(item) {
  //       return (
  //         item.ServiceType === v.ServiceType &&
  //         item.StartDate === convertDateFormat(v.StartDate, 'MM/DD/YYYY') &&
  //         item.EndDate === convertDateFormat(v.EndDate, 'MM/DD/YYYY') &&
  //         item.RankType === v.Rank
  //       );
  //     })[0];
  //     if (existing) {
  //       existing.PriceDetails.push({
  //         Id: v.Id,
  //         MinPax: v.CalculationBase.Code,
  //         Price: v.Cost,
  //       });
  //       existing.CustomId.push(v.Id);
  //     } else {
  //       p.push({
  //         Currency: v.Currency,
  //         CutOfDate: convertDateFormat(v.CutOffDate, 'MM/DD/YYYY'),
  //         CutOfDay: v.CutOffDay,
  //         EndDate: convertDateFormat(v.EndDate, 'MM/DD/YYYY'),
  //         CustomId: [v.Id],
  //         Id: v.Id,
  //         MinimumNight: v.MinDays,
  //         RankType: v.Rank,
  //         RankValue: v.Rank,
  //         ServiceItemId: v.ServiceItemId,
  //         ServiceType: v.ServiceType,
  //         ServicePriceType: v.ServiceType,
  //         StartDate: convertDateFormat(v.StartDate, 'MM/DD/YYYY'),
  //         Status: 'Edit',
  //         onChange: false,
  //         PriceDetails: [
  //           { Id: v.Id, MinPax: v.CalculationBase.Code, Price: v.Cost },
  //         ],
  //         index: index,
  //       });
  //     }
  //     return p;
  //   },
  //   []);

  //   let result = groupingPrice.filter(obj => {
  //     return obj.ServicePriceType === 'With_breakfast';
  //   });
  //   this.setState({
  //     accomodationPriceList: groupingPrice,
  //     listAccomodationPrice: result,
  //   });
  // };
  filterSupplierPrice = () => {
    const supplierPrice = this.props.supplierPriceList;
    let newSupplier = [];
    supplierPrice.filter(function(item) {
      var i = newSupplier.findIndex(
        x =>
          x.ServicePriceType === item.ServiceType &&
          x.StartDate === item.StartDate &&
          x.EndDate === item.EndDate &&
          x.RankType === item.Rank
      );
      if (i <= -1) {
        newSupplier.push({
          Id: item.Id,
          RankType: item.Rank,
          RankValue: item.Rank,
          ServicePriceType: item.ServiceType,
          ServiceItemId: item.ServiceItemId,
          MinimumNight: item.MinDays,
          StartDate: moment(item.StartDate).format('MM/DD/YYYY'),
          EndDate: moment(item.EndDate).format('MM/DD/YYYY'),
          CutOfDate: item.CutOffDate,
          CutOfDay: item.CutOffDay,
          Currency: item.Currency,
          PriceDetails: [],
          onChange: false,
          Status: 'Edit',
        });
      }
      return newSupplier;
    });
    return newSupplier;
  };
  setStateAccomodationItem = data => {
    const {
      AccommodationType,
      Name,
      ImageUrl,
      ServiceItemId,
      MinDays,
      AccommodationProfileId,
      SimpleAccommodationItemFacilities,
      //AccommodationItemServiceTypes,
      ItemImages,
      IsPromo,
      Explanation,
      ExtrabedCapacity,
      Currency,
    } = data;
    const facilitiesDestructure = SimpleAccommodationItemFacilities.map(
      facilities => {
        return {
          FacilityId: facilities.AccommodationFacilityId,
          Name: facilities.AccommodationFacilityName,
        };
      }
    );
    //const { Capacity, Id } = AccommodationType;

    /* const imageDestructure = ItemImages.map(image => {
      return {
        ImageId: image.ImageId,
        ImageContent: image.ImageUrl,
        ImageName: image.Name,
        IsPrimaryImage: image.IsPrimaryImage,
      };
    }); */
    let accomodationItem = {
      /*  itemName: Name ? Name : '',
      itemType: Id,
      description: Explanation,
      capacities: Capacity,
      promotion: IsPromo,
      minimumNight: MinDays ? MinDays : '',
      image: imageDestructure ? imageDestructure : [],
      facilities: [],
      imageUrl: ImageUrl ? ImageUrl : null,
      accommodationProfileId: AccommodationProfileId
        ? AccommodationProfileId
        : '',
      serviceItemId: ServiceItemId ? ServiceItemId : 0,
      accommodationItemServiceTypes: AccommodationItemServiceTypes
        ? AccommodationItemServiceTypes
        : '', */
      Desc: Name ? Name : '',
      ServiceItemId: ServiceItemId ? ServiceItemId : 0,
      //Qty: 2,
      IsPromo: IsPromo ? IsPromo : false,
      AccommodationProfileId: AccommodationProfileId
        ? AccommodationProfileId
        : 0,
      AccommodationItemTypeId: AccommodationType.Id ? AccommodationType.Id : 0,
      //Desc: 'sample string 5',
      Explanation: Explanation ? Explanation : '',
      ImageName: '',
      ImageContent: ImageUrl ? ImageUrl : '',
      ExtraBedCapacity: ExtrabedCapacity ? ExtrabedCapacity : 0,
      AccommodationItemImages: ItemImages ? ItemImages : [],
      SimpleAccommodationItemFacilities: facilitiesDestructure
        ? facilitiesDestructure
        : [],
      Currency: Currency,
      MinDays: MinDays ? MinDays : 0,
      //ExtraBedCapacity: AccommodationType.Capacity ? AccommodationType.Capacity : 0,
    };
    this.setState({
      accomodationItem: accomodationItem,
      facilitiesAccomodation: facilitiesDestructure,
    });
  };
  buttonBack = () => {
    let idProfile = this.props.match.params.ProfileId;
    // eslint-disable-next-line
    this.props.history.push(`/Accomodation/Edit/${idProfile}`);
  };

  messageErrorPriceDetails = () => {
    this.setState({
      contentAlert: 'Please fill in the price list',
      modalConfirmation: true,
      headerAlert: 'Information',
    });
  };

  addPriceToList = () => {
    const { accomodationPrice, editIndex, serviceType } = this.state;
    let accomodationPriceList = [...this.state.accomodationPriceList];
    const err = this.validatePrice();
    // let valid = false;
    // let validPrice = false;
    // let validPriceList = false;
    // let errorMinPax = false;

    const { RankType, PriceDetails, RankValue } = accomodationPrice;
    let existPriceValidation = existingPriceValidation(
      accomodationPriceList,
      accomodationPrice
    );
    // let validPrice = false;
    // let validPriceList = false;
    let minPaxValidation = existingMinPaxValidation(PriceDetails);
    let rankValidation = RankValue < 4 && RankType === 4;
    let priceDetailEmptyValidation = PriceDetails.length === 0;
    let zeroPriceDetailValidation = zeroPriceValidation(PriceDetails);
    if (!err) {
      if (priceDetailEmptyValidation) {
        this.messageErrorPriceDetails();
      } else if (editIndex < 0 && existPriceValidation) {
        this.setState({
          contentAlert:
            'The price with the service type and date you fill in already exists',
          modalConfirmation: true,
          headerAlert: 'Information',
        });
      } else if (zeroPriceDetailValidation) {
        this.setState({
          contentAlert: 'the price you enter cannot be 0',
          modalConfirmation: true,
          headerAlert: 'Information',
        });
      } else if (rankValidation) {
        this.setState({
          contentAlert:
            'If the rank type you choose is other, it must fill the rank value above 3',
          modalConfirmation: true,
          headerAlert: 'Information',
        });
      } else if (minPaxValidation) {
        this.setState({
          contentAlert:
            'There is an error in the price, because it has the same minimum pax',
          modalConfirmation: true,
          headerAlert: 'Information',
        });
      } else {
        if (editIndex >= 0) {
          let accomodationPriceindex = accomodationPrice;
          accomodationPriceList[editIndex] = accomodationPriceindex;
        } else {
          accomodationPriceList.push(accomodationPrice);
        }

        let result = accomodationPriceList.filter(obj => {
          return obj.ServicePriceType === serviceType;
        });
        this.setState({
          // ...this.state,
          accomodationPriceList: accomodationPriceList,
          listAccomodationPrice: result,
          openModalPrice: false,
        });
      }
    }
    this.setState({
      deletedPrice: [
        ...this.state.deletedPrice,
        ...this.state.tempDeletedPrice,
      ],
    });
    // if (!err) {
    //   if (Status === 'New') {
    //     PriceDetails.length === 0 && this.messageErrorPriceDetails();
    //     accomodationPriceList.map(data => {
    //       if (
    //         data.ServicePriceType === ServicePriceType &&
    //         data.StartDate === StartDate &&
    //         data.EndDate === EndDate &&
    //         data.RankType === RankType
    //       ) {
    //         valid = true;
    //       }
    //       return data;
    //     });
    //   }
    //   PriceDetails.map((data, i) => {
    //     let currentIndex = i + 1;
    //     if (PriceDetails.length > 1) {
    //       if (PriceDetails.length !== currentIndex) {
    //         if (data.MinPax === PriceDetails[currentIndex].MinPax) {
    //           errorMinPax = true;
    //         }
    //       }
    //     }
    //     return data;
    //   });
    //   validPriceList = PriceDetails.length;
    //   if (!valid) {
    //     if (validPriceList === 0) {
    //       this.messageErrorPriceDetails();
    //     } else if (errorMinPax) {
    //       this.setState({
    //         contentAlert:
    //           'There is an error in the price, because it has the same minimum pax',
    //         modalConfirmation: true,
    //         headerAlert: 'Information',
    //       });
    //     } else if (this.state.editIndex >= 0) {
    //       if (
    //         this.state.accomodationPrice.RankValue < 4 &&
    //         this.state.accomodationPrice.RankType === 4
    //       ) {
    //         this.setState({
    //           contentAlert:
    //             'If the rank type you choose is other, it must fill the rank value above 3',
    //           modalConfirmation: true,
    //           headerAlert: 'Information',
    //         });
    //       } else {
    //         if (!validPrice) {
    //           let accomodationPriceindex = this.state.accomodationPrice;
    //           accomodationPriceList[
    //             this.state.editIndex
    //           ] = accomodationPriceindex;
    //           let result = accomodationPriceList.filter(obj => {
    //             return obj.ServicePriceType === this.state.serviceType;
    //           });
    //           this.setState({
    //             ...this.state,
    //             accomodationPriceList: accomodationPriceList,
    //             listAccomodationPrice: result,
    //             openModalPrice: false,
    //           });
    //         } else {
    //           this.setState({
    //             contentAlert: 'the price you enter cannot be 0',
    //             modalConfirmation: true,
    //             headerAlert: 'Information',
    //           });
    //         }
    //       }
    //     } else {
    //       if (
    //         this.state.accomodationPrice.RankValue < 4 &&
    //         this.state.accomodationPrice.RankType === 4
    //       ) {
    //         this.setState({
    //           contentAlert:
    //             'If the rank type you choose is other, it must fill the rank value above 3',
    //           modalConfirmation: true,
    //           headerAlert: 'Information',
    //         });
    //       } else {
    //         if (!validPrice) {
    //           accomodationPriceList.push(this.state.accomodationPrice);
    //           let result = accomodationPriceList.filter(obj => {
    //             return obj.ServicePriceType === this.state.serviceType;
    //           });
    //           this.setState({
    //             ...this.state,
    //             accomodationPriceList: accomodationPriceList,
    //             listAccomodationPrice: result,
    //             openModalPrice: false,
    //           });
    //         } else {
    //           this.setState({
    //             contentAlert: 'the price you enter cannot be 0',
    //             modalConfirmation: true,
    //             headerAlert: 'Information',
    //           });
    //         }
    //       }
    //     }
    //   } else {
    //     errorMinPax
    //       ? this.setState({
    //           contentAlert:
    //             'There is an error in the price, because it has the same minimum pax',
    //           modalConfirmation: true,
    //           headerAlert: 'Information',
    //         })
    //       : this.setState({
    //           contentAlert:
    //             'The price with the service type and date you fill in already exists',
    //           modalConfirmation: true,
    //           headerAlert: 'Information',
    //         });
    //   }
    // }
  };
  deletePrice = (e, { id, value }) => {
    this.setState({
      openConfirm: true,
      messageConfirm: 'Are you sure to delete this price ?',
      idWantToDelete: value,
      indexWantToDelete: id,
    });
  };
  addPrice = () => {
    const { ServiceTypeId } = this.props.match.params;
    const { serviceType } = this.state;
    const errors = {
      ServicePriceType: '',
    };
    if (ServiceTypeId === undefined) {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: 'Please Save Accommodation Item First',
        modalConfirmation: true,
      });
    } else {
      let price = {
        RankType: '',
        RankValue: '',
        ServicePriceType: serviceType,
        ServiceItemId: ServiceTypeId,
        StartDate: moment(new Date()).format('MM/DD/YYYY'),
        EndDate: moment(new Date()).format('MM/DD/YYYY'),
        CutOfDate: moment(new Date()).format('MM/DD/YYYY'),
        CutOfDay: '',
        MinimumNight: '',
        PriceDetails: [],
        onChange: true,
        Status: 'New',
        Currency: '',
      };
      if (price.ServicePriceType) {
        this.setState({
          accomodationPrice: price,
          editIndex: -1,
          openModalPrice: true,
        });
      } else {
        if (price.ServicePriceType < 1) {
          errors.ServicePriceType = 'Field Required';
        }
        this.setState({
          errors: {
            ...errors,
          },
        });
      }
    }
  };
  editPrice = id => {
    // let accommodationPrice = this.state.accomodationPriceList[name];
    let object = this.state.accomodationPriceList.find(data => data.Id === id);
    let priceModify = {
      ...object,
      onChange: true,
      Status: 'Edit',
    };
    // priceModify.onChange = true;
    // priceModify.Status = 'Edit';
    this.setState({
      accomodationPrice: priceModify,
      editIndex: object.index,
      // editIndex: name,
      openModalPrice: true,
    });
  };
  handleClose = () => {
    this.setState({
      openModalPrice: false,
      failedAccomodationItem: false,
      openModalImage: false,
      modalConfirmation: false,
      tempDeletedPrice: [],
    });
    //window.location.reload(1);
  };
  addPriceDetails = () => {
    let accomodationPrice = this.state.accomodationPrice;
    let priceDetails = accomodationPrice.PriceDetails;
    let newPrice = {
      Price: 0,
      MinPax: 0,
      Id: 0,
    };
    priceDetails.push(newPrice);
    this.setState({
      accomodationPrice: {
        ...this.state.accomodationPrice,
        PriceDetails: priceDetails,
      },
    });
  };
  handleChangePriceDetails = (e, { value, name }) => {
    let accomodationPrice = this.state.accomodationPrice;
    let priceDetails = accomodationPrice.PriceDetails;
    let i = name.split(',')[1];
    let newName = name.split(',')[0];
    priceDetails[i] = { ...priceDetails[i], [newName]: value };
    this.setState({
      accomodationPrice: {
        ...this.state.accomodationPrice,
        PriceDetails: priceDetails,
        onChange: true,
      },
    });
  };
  saveAccommodationItem = (e, { name }) => {
    if (name === 'Edit') {
      this.setState({
        titleHeader: 'Edit Accommodation Item',
        contentButton: 'Save',
        disablePage: false,
      });
    } else {
      const err = this.validate();
      const data = this.state.accomodationItem;
      const { ServiceItemId } = data;
      //if (data.onChange) {
      if (!err) {
        this.setState({ loadingAccomodationItem: true });
        if (ServiceItemId === '') {
          this.props.postAccomodationItemAction(data);
        } else {
          this.props.putAccomodationItemAction(data);
        }
      }
      //}
      this.saveAccommodationPrice();
    }
  };
  saveAccommodationPrice = () => {
    let { accomodationPriceList, accomodationItem, deletedPrice } = this.state;

    accomodationPriceList.map(price => {
      if (price.onChange) {
        if (price) {
          price.PriceDetails.map(details => {
            let data = {
              Id: details.Id,
              CutOffDate: price.CutOfDate,
              CutOffDay: price.CutOfDay,
              MinDays: price.MinimumNight,
              Cost: details.Price,
              StartDate: price.StartDate,
              EndDate: price.EndDate,
              UomId: 'PCS',
              TaxTypeId: 0,
              Currency: price.Currency,
              Rank: price.RankValue,
              ServiceItemId: price.ServiceItemId,
              ServicePriceType: price.ServicePriceType,
              MinimumPax: details.MinPax,
              Description: accomodationItem.Explanation,
            };
            return details.Id
              ? this.props.putServicePriceAction('Accommodation', data.Id, data)
              : this.props.postServicePriceAction('Accommodation', data);
          });
        }
      }

      return price;
    });
    deletedPrice.forEach(data => {
      this.setState({
        loadingAccomodationItem: true,
      });
      this.props.deleteServicePriceAction(data);
    });
  };
  handleChangeCostPriceDetails = (value, i, name) => {
    let accomodationPrice = this.state.accomodationPrice;
    let priceDetails = accomodationPrice.PriceDetails;
    priceDetails[i] = { ...priceDetails[i], [name]: value };
    this.setState({
      accomodationPrice: {
        ...this.state.accomodationPrice,
        PriceDetails: priceDetails,
        onChange: true,
      },
    });
  };
  handleChangePrice = (e, { value, name }) => {
    let accomodationPrice = this.state.accomodationPrice;
    if (name === 'RankType') {
      accomodationPrice['RankValue'] = value;
    }
    accomodationPrice[name] = value;
    accomodationPrice['onChange'] = true;
    this.validatePrice();
    this.setState({ accomodationPrice: accomodationPrice });
  };
  handleChangePriceDate = (value, name) => {
    const { accomodationPrice, errors } = this.state;
    let accommodationPrice = accomodationPrice;
    let valueConvert = getTime(value);
    // let valueConvert = moment(value).format('MM/DD/YYYY');
    //let today = moment(new Date()).format('MM/DD/YYYY');
    if (name === 'StartDate') {
      accommodationPrice[name] = moment(value).format('MM/DD/YYYY');
      accommodationPrice['onChange'] = true;
      this.setState({
        errors: {
          ...errors,
          StartDate: '',
        },
      });
      //Date in the past
    } else if (name === 'EndDate') {
      if (accomodationPrice.Status === 'New') {
        if (valueConvert <= getTime(accomodationPrice.StartDate)) {
          this.setState({
            errors: {
              ...errors,
              EndDate: 'Date must be in the future of start date',
            },
          });
        } else {
          accommodationPrice[name] = moment(value).format('MM/DD/YYYY');
          accommodationPrice['onChange'] = true;
          this.setState({
            errors: {
              ...errors,
              EndDate: '',
            },
          });
        }
      } else {
        accommodationPrice[name] = moment(value).format('MM/DD/YYYY');
        accommodationPrice['onChange'] = true;
        this.setState({
          errors: {
            ...errors,
            EndDate: '',
          },
        });
      }
    } else if (name === 'CutOfDate') {
      if (accomodationPrice.Status === 'New') {
        let accommodationtime = getTime(accomodationPrice.EndDate);
        if (
          valueConvert >= accommodationtime
          // moment(accomodationPrice.StartDate).format('MM/DD/YYYY')
        ) {
          this.setState({
            errors: {
              ...errors,
              CutOfDate: 'Date must be in the past of end date',
            },
          });
        } else {
          accommodationPrice[name] = moment(value).format('MM/DD/YYYY');
          accommodationPrice['onChange'] = true;
          this.setState({
            errors: {
              ...errors,
              CutOfDate: '',
            },
          });
        }
      } else {
        accommodationPrice[name] = moment(value).format('MM/DD/YYYY');
        accommodationPrice['onChange'] = true;
        this.setState({
          errors: {
            ...errors,
            CutOfDate: '',
          },
        });
      }
    }
    this.setState({
      accommodationPrice: accommodationPrice,
    });
  };
  handleChangeServiceType = (e, { value }) => {
    let listDataPrice = this.state.accomodationPriceList;
    let priceList = listDataPrice;
    let result = priceList.filter(obj => {
      return obj.ServicePriceType === value;
    });
    this.setState({ serviceType: value, listAccomodationPrice: result });
  };
  //=========== Modal Item
  modalItemAction = e => {
    //const { SimpleAccommodationItemFacilities } = this.state.accomodationItem;
    return e.target.id === 'Facilities'
      ? this.setState({
          contentModal: 'Facilities',
          openModalFacilities: true,
          dataItem: this.props.listAccomodationFacilities,
        })
      : this.setState({
          contentModal: 'Image',
          openModalImage: true,
          dataItem: [],
        });
  };
  addState = () => {
    if (this.state.contentModal === 'Facilities') {
      this.setState({
        openModalFacilities: false,
        accomodationItem: {
          ...this.state.accomodationItem,
          SimpleAccommodationItemFacilities: this.state.facilitiesAccomodation,
          onChange: true,
        },
      });
    } else {
      this.setState({
        openModalImage: false,
        accomodationItem: {
          ...this.state.accomodationItem,
          AccommodationProfileImages: this.state.imageAccomodation,
          onChange: true,
        },
      });
    }
  };
  handleCheckLocation = (e, { name, checked }) => {
    let list = this.state.dataItem;
    // let value = e.target.name;
    // let Name = e.target.id;
    list.map(location => {
      if (location.Id === name) {
        location['Status'] = checked;
        return list;
      }
      return list;
    });
    this.setState({ dataItem: list });
    if (this.state.contentModal === 'Facilities') {
      let newFeatures = list.reduce((total, state) => {
        if (state.Status === true) {
          total.push({ FacilityId: state.Id, Name: state.Name });
        }
        return total;
      }, []);
      this.setState({ facilitiesAccomodation: newFeatures });
    } /* else {
      this.setState(prevState => ({
        AccommodationItemImages: [
          ...prevState.AccommodationItemImages,
          {
            LocationId: value,
            Name: Name,
          },
        ],
      }));
    } */
  };
  deleteState = (e, { value, id }) => {
    if (value === 'Facilities') {
      const { SimpleAccommodationItemFacilities } = this.state.accomodationItem;
      let listFacilities = SimpleAccommodationItemFacilities.filter(
        item => item.FacilityId !== id
      );
      let list = this.state.dataItem;
      list.map(location => {
        if (location.Id === id) {
          location['Status'] = false;
          return list;
        }
        return list;
      });
      this.setState({
        dataItem: list,
        accomodationItem: {
          ...this.state.accomodationItem,
          SimpleAccommodationItemFacilities: listFacilities,
          onChange: true,
        },
        facilitiesAccomodation: listFacilities,
        activePage: 1,
      });
    } else {
      this.setState({
        activePage: 1,
        accomodationItem: {
          ...this.state.accomodationItem,
          AccommodationItemImages: this.state.accomodationItem.AccommodationItemImages.filter(
            (item, i) => i !== value
          ),
        },
      });
    }

    // return e.target.value === 'Facilities'
    //   ? this.setState({
    //       accomodationItem: {
    //         ...this.state.accomodationItem,
    //         SimpleAccommodationItemFacilities: this.state.accomodationItem.SimpleAccommodationItemFacilities.filter(
    //           item => item.FacilityId !== e.target.id
    //         ),
    //       },
    //     })
    //   : e.target.value === 'Image'
    //     ? this.setState({
    //         accomodationItem: {
    //           ...this.state.accomodationItem,
    //           AccommodationItemImages: this.state.accomodationItem.AccommodationItemImages.filter(
    //             item => item.ImageName !== e.target.id
    //           ),
    //         },
    //       })
    //     : null;
  };
  deleteImage = value => {
    this.setState({
      activePage: 1,
      accomodationItem: {
        ...this.state.accomodationItem,
        AccommodationItemImages: this.state.accomodationItem.AccommodationItemImages.filter(
          (item, i) => i !== value
        ),
      },
    });
  };
  //=============================================================

  // modal Image
  addImage = () => {
    let objectImage = this.state.accomodationItem;
    let image = this.state.image;
    objectImage.AccommodationItemImages.push(image);
    let listStepImage = this.state.listStepImage;
    listStepImage.map((step, i) => {
      step['Active'] = i === 0 ? true : false;
      return listStepImage;
    });
    this.setState({
      accomodationItem: objectImage,
      openModalImage: false,
      listStepImage: listStepImage,
      indexActive: 0,
    });
  };
  changeImage = e => {
    let imageObject = this.state.accomodationItem.AccommodationItemImages;
    e.map(base => {
      let image = {
        ImageId: 0,
        ImageContent: base.base64.split(',')[1],
        ImagePreview: base.base64,
        ImageName: base.file.name,
        IsPrimaryImage: false,
      };
      return imageObject.push(image);
    });
    this.setState({
      accomodationItem: {
        ...this.state.accomodationItem,
        imageObject,
        onChange: true,
      },
    });
  };
  openDetail = (e, { value }) => {
    const { AccommodationItemImages } = this.state.accomodationItem;
    let listStepImage = this.state.listStepImage;
    listStepImage.map((step, i) => {
      step['Active'] = i === 0 ? true : false;
      return listStepImage;
    });
    let image = AccommodationItemImages[value];
    this.setState({
      image: image,
      indexActive: 0,
      listStepImage: listStepImage,
      openModalImage: true,
      contentModal: 'Image',
      imageUrl: image.ImageContent //ImageUrl
        ? image.ImageContent //ImageUrl
        : URL.createObjectURL(e.target.files[0]),
    });
  };
  handleChangePrimaryImage = (e, { value }) => {
    let listImage = this.state.accomodationItem;
    let newList = [];
    listImage.AccommodationItemImages.map((img, i) => {
      img['IsPrimaryImage'] = i === value ? true : false;
      return newList.push(img);
    });
    this.setState({
      accomodationItem: {
        ...this.state.accomodationItem,
        AccommodationItemImages: newList,
      },
    });
  };
  handleNext = () => {
    let indexActive = this.state.indexActive;
    indexActive = indexActive + 1;
    let listStepImage = this.state.listStepImage;
    listStepImage.map((step, i) => {
      step['Active'] = i === indexActive ? true : false;
      return listStepImage;
    });
    if (indexActive === 2) {
      let listImage = this.state.accomodationItem;
      listImage.AccommodationItemImages.map(img => {
        return img.IsPrimaryImage && this.setState({ image: img });
      });
    }
    if (indexActive === 3) {
      return this.setState({ indexActive: 0, openModalImage: false });
    }
    this.setState({ listStepImage: listStepImage, indexActive: indexActive });
  };
  handleBackStep = () => {
    let indexActive = this.state.indexActive;
    indexActive = indexActive - 1;
    let listStepImage = this.state.listStepImage;
    listStepImage.map((step, i) => {
      step['Active'] = i === indexActive ? true : false;
      return listStepImage;
    });
    this.setState({ listStepImage: listStepImage, indexActive: indexActive });
  };
  //---------------====---
  validate = () => {
    let isError = false;
    const errors = {
      Desc: '',
      Description: '',
      AccommodationItemTypeId: '',
      ExtraBedCapacity: '',
    };
    if (this.state.accomodationItem.Desc.length < 1) {
      isError = true;
      errors.Desc = "Item Name Can't Be Empty";
    }
    if (this.state.accomodationItem.Explanation.length < 1) {
      isError = true;
      errors.Description = "Description Can't Be Empty";
    }
    if (this.state.accomodationItem.AccommodationItemTypeId.length < 1) {
      isError = true;
      errors.AccommodationItemTypeId = "Item Type Can't Be Empty";
    }
    if (this.state.accomodationItem.ExtraBedCapacity.length < 1) {
      isError = true;
      errors.ExtraBedCapacity = "Extra Bed Capacity Can't Be Empty";
    }
    this.setState({
      errors: {
        ...errors,
      },
    });
    return isError;
  };
  validatePrice = () => {
    let isError = false;
    const errors = {
      RankType: { error: false, message: '' },
      RankValue: { error: false, message: '' },
      MinimumNight: { error: false, message: '' },
      Currency: { error: false, message: '' },
      CutOfDay: { error: false, message: '' },
    };
    if (this.state.accomodationPrice.RankType.length < 1) {
      isError = true;
      errors.RankType.error = true;
      errors.RankType.message = "Rank Type Can't Be Empty";
    }
    if (this.state.accomodationPrice.RankValue.length < 1) {
      isError = true;
      errors.RankValue.error = true;
      errors.RankValue.message = "Rank Value Can't Be Empty";
    }
    if (this.state.accomodationPrice.MinimumNight.length < 1) {
      isError = true;
      errors.MinimumNight.error = true;
      errors.MinimumNight.message = "Minimum Night Can't Be Empty";
    }
    if (this.state.accomodationPrice.Currency.length < 1) {
      isError = true;
      errors.Currency.error = true;
      errors.Currency.message = "Currency Can't Be Empty";
    }
    if (this.state.accomodationPrice.CutOfDay.length < 1) {
      isError = true;
      errors.CutOfDay.error = true;
      errors.CutOfDay.message = "Cut Of Days Can't Be Empty";
    }
    this.setState({
      errors: {
        ...errors,
      },
    });
    return isError;
  };

  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  handleCloseAlert = () => {
    this.setState({ modalConfirmation: false, failedAccomodationItem: false });
  };
  handleCloseConfirm = () => {
    this.setState({
      openConfirm: false,
    });
  };
  confirmDelete = () => {
    const {
      idWantToDelete,
      indexWantToDelete,
      accomodationPrice,
      deletedPrice,
      accomodationPriceList,
      serviceType,
      tempDeletedPrice,
    } = this.state;
    let accomodationPriceObject = { ...accomodationPrice };
    let accomodationPriceDetails = [...accomodationPrice.PriceDetails];
    // let newDeletedPrice = [...deletedPrice];
    let newTempDeletePrice = [...tempDeletedPrice];
    if (idWantToDelete > 0) {
      accomodationPriceDetails.splice(indexWantToDelete, 1);
      accomodationPriceObject.PriceDetails = accomodationPriceDetails;
      // newDeletedPrice.push(idWantToDelete);
      newTempDeletePrice.push(idWantToDelete);
    } else {
      accomodationPriceDetails.splice(indexWantToDelete, 1);
      accomodationPriceObject.PriceDetails = accomodationPriceDetails;
    }
    if (accomodationPriceDetails.length === 0) {
      let x = accomodationPriceList.filter(
        data => data.index !== accomodationPriceObject.index
      );
      let result = x.filter(obj => {
        return obj.ServicePriceType === serviceType;
      });
      this.setState({
        accomodationPrice: accomodationPriceObject,
        openModalPrice: false,
        openConfirm: false,
        accomodationPriceList: x,
        listAccomodationPrice: result,
        deletedPrice: [...deletedPrice, ...newTempDeletePrice],
        tempDeletedPrice: [],
      });
    } else {
      this.setState({
        accomodationPrice: accomodationPriceObject,
        openConfirm: false,
        // accomodationPriceList: x,
        // listAccomodationPrice: result,
        // deletedPrice: newDeletedPrice,
        tempDeletedPrice: newTempDeletePrice,
      });
    }
    // let x = accomodationPriceList.filter(
    //   data => data.index !== accomodationPriceObject.index
    // );
    // let result = x.filter(obj => {
    //   return obj.ServicePriceType === serviceType;
    // });
  };

  render() {
    let {
      loadingAccomodationItem,
      failedAccomodationItem,
      modalConfirmation,
      headerAlert,
      contentAlert,
      activePage,
      accomodationItem,
      listAccomodationPrice,
      accomodationProfileName,
      serviceType,
    } = this.state;
    let {
      SimpleAccommodationItemFacilities,
      AccommodationItemImages,
    } = accomodationItem;
    let pageFacilities = datasetPagination(
      SimpleAccommodationItemFacilities,
      3
    );
    let listDataFacilities = pageFacilities[activePage - 1];
    // let pageImage = datasetPagination(AccommodationItemImages, 3);
    // let listImage = pageImage[activePage - 1];
    let loading = loadingAccomodationItem;
    let openModal = failedAccomodationItem || modalConfirmation;
    let serviceTypeModify = serviceType.replace('_', ' ');
    return (
      <div>
        <Dimmer.Dimmable blurring dimmed={loading}>
          <Loader loading={loading} />
          <AccomodationItemForm
            SimpleAccommodationItemFacilities={
              SimpleAccommodationItemFacilities
            }
            AccommodationItemImages={AccommodationItemImages}
            accomodationItem={this.state.accomodationItem}
            itemTypeData={this.state.listItemTypeData}
            itemTypeList={this.props.listItemTypeData}
            itemServiceTypeData={this.props.listServiceTypesData}
            handleChange={this.onChange}
            backPage={this.buttonBack}
            openModalPrice={this.state.openModalPrice}
            addPrice={this.addPrice}
            handleClose={this.handleClose}
            addPriceDetails={this.addPriceDetails}
            accomodationPrice={this.state.accomodationPrice}
            currenciesList={this.props.currencies}
            handleChangeCostPriceDetails={this.handleChangeCostPriceDetails}
            handleChangePriceDetails={this.handleChangePriceDetails}
            handleChangePrice={this.handleChangePrice}
            handleChangePriceDate={this.handleChangePriceDate}
            rankType={this.state.rankType}
            handleChangeServiceType={this.handleChangeServiceType}
            serviceType={this.state.serviceType}
            accomodationPriceList={listAccomodationPrice}
            addPriceToList={this.addPriceToList}
            headerTable={headerTableLocationAccomodationItemPrice}
            editPrice={this.editPrice}
            deletePrice={this.deletePrice}
            onChangeCheckbox={this.onChangeCheckbox}
            saveAccommodationItem={this.saveAccommodationItem}
            title={this.state.titleHeader}
            modalItem={this.modalItemAction}
            listData={this.state.dataItem}
            deleteData={this.deleteState}
            deleteImage={this.deleteImage}
            errors={this.state.errors}
            contentButton={this.state.contentButton}
            disablePage={this.state.disablePage}
            activePage={this.state.activePage}
            listImage={AccommodationItemImages}
            // pageImage={pageImage}
            pageChange={this.pageChange}
            listDataFacilities={listDataFacilities}
            pageFacilities={pageFacilities}
            accomodationProfileName={accomodationProfileName}
            serviceTypeModify={serviceTypeModify}
            handleChangePrimaryImage={this.handleChangePrimaryImage}
            openDetail={this.openDetail}
          />
          <ModalAlert
            openModal={openModal}
            handleClose={this.handleCloseAlert}
            header={headerAlert}
            content={contentAlert}
            nameButton="OK"
          />
          <ModalItem
            openModal={this.state.openModalFacilities}
            handleClose={this.handleCloseModalItem}
            content={this.state.contentModal}
            onChangeItem={this.handleCheckLocation}
            addItem={this.addState}
            data={this.state.dataItem}
          />
          <ModalImage
            openModal={this.state.openModalImage}
            imagePreview={this.state.imageUrl}
            handleChange={this.changeImage}
            addImage={this.addImage}
            handleClose={this.handleClose}
            header="Upload Image"
            image={this.state.accomodationItem.AccommodationItemImages}
            listStepImage={this.state.listStepImage}
            handleNext={this.handleNext}
            handleBackStep={this.handleBackStep}
            indexActive={this.state.indexActive}
            handleChangePrimaryImage={this.handleChangePrimaryImage}
            imageRatio={this.state.image}
          />
          <ModalConfirm
            openModal={this.state.openConfirm}
            size="mini"
            close={this.handleCloseConfirm}
            confirm={this.confirmDelete}
            message={this.state.messageConfirm}
          />
        </Dimmer.Dimmable>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    listAccomodationItemByServiceType:
      state.AccomodationStore.getAccomodationItemByServiceTypeId,
    statusAccomodationItemByServiceType:
      state.AccomodationStore.getAccomodationItemByServiceTypeStatus,
    listItemTypeData: state.AccomodationPageStore.getAccomodationItemTypes.map(
      type => {
        return { text: type.Name, value: type.Id, key: type.Capacity };
      }
    ),
    listServiceTypesData: state.AccomodationPageStore.getAccomodationItemServiceType.map(
      type => {
        return { text: type.replace('_', ' '), value: type, key: type };
      }
    ),
    currencies: state.currenciesStore.currencies.map((cur, i) => {
      return { key: i, text: cur.Id, value: cur.Id };
    }),
    supplierPriceList: state.SupplierPriceStore.supplierPriceList,
    getSupplierPriceStatus: state.SupplierPriceStore.getSupplierPriceStatus,
    getAccomodationItemTypesStatus:
      state.AccomodationPageStore.getAccomodationItemTypesStatus,
    listAccomodationFacilities:
      state.AccomodationPageStore.getAccomodationFacilities,
    postAccomodationItemStatus:
      state.AccomodationPageStore.postAccomodationItemStatus,
    postAccomodationItem: state.AccomodationPageStore.postAccomodationItem,
    putAccomodationItemStatus:
      state.AccomodationPageStore.putAccomodationItemStatus,
    putAccomodationItem: state.AccomodationPageStore.putAccomodationItem,

    errorsAccomodationItem: state.AccomodationPageStore.errors,
    putSupplierPriceStatus: state.SupplierPriceStore.putSupplierPriceStatus,
    postSupplierPriceStatus: state.SupplierPriceStore.postSupplierPriceStatus,
    errorsServicePrice: state.SupplierPriceStore.errors,
    deleteSupplierPriceStatus:
      state.SupplierPriceStore.deleteSupplierPriceStatus,
  };
}
export default connect(mapStateToProps, {
  getAccomodationItemByServiceTypeIdAction,
  resetAccomodationAction,
  getAccomodationItemTypesAction,
  getAccomodationItemServiceTypeAction,
  getServicePriceTypeAction,
  getCurrenciesAction,
  getServicePriceAction,
  resetServicePriceAction,
  postServicePriceAction,
  putServicePriceAction,
  deleteServicePriceAction,
  putAccomodationItemAction,
  postAccomodationItemAction,
  getAccomodationFacilitiesAction,
  getAccomodationByProfiledIdAction,
})(AccomodationItem);
