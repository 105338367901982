import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ActionButton from '../../../../components/common/buttonEditAndDelete';
const TableCityList = props => (
  <Table basic="very" selectable fixed>
    <Table.Header>
      <Table.Row>
        {props.headerTable.map((headTableContent, index) => {
          return (
            <Table.HeaderCell key={index}>{headTableContent}</Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {props.listData ? (
        props.listData.map((city, idx) => {
          return (
            <Table.Row key={idx}>
              <Table.Cell component="th" scope="row" width={1}>
                {city.Id}
              </Table.Cell>
              <Table.Cell>{city.Name}</Table.Cell>
              <Table.Cell>{city.Region.Name}</Table.Cell>
              {/* <Table.Cell>{city.Cities.map(e => e.Text).join(',')}</Table.Cell> */}
              <Table.Cell>{city.Country.Name}</Table.Cell>
              <Table.Cell>{city.UTC}</Table.Cell>
              <ActionButton
                id={city.Id}
                handleClickEdit={props.butttonEdit}
                handleClickDelete={props.buttonDelete}
                name1="Edit"
                name2={city.Name}
                content1="Edit"
                content2="Delete"
              />
            </Table.Row>
          );
        })
      ) : (
        <Table.Row>
          <Table.Cell colSpan="6" textAlign="center">
            No Data
          </Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  </Table>
);

TableCityList.propTypes = {
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  actionButton: PropTypes.func,
  butttonEdit: PropTypes.func,
  buttonDelete: PropTypes.func,
};
export default TableCityList;
