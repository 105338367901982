const formatCurrency = value => {
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};

const formatCurrencyWithDecimal = value => {
  let valString = value.toString();
  valString = valString.replace('.', ',');
  valString = valString.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  return valString;
};

export { formatCurrency, formatCurrencyWithDecimal };
