import {
  GET_ACCOMODATION_LIST,
  RESET_STATUS_ACCOMODATION,
  GET_ACCOMODATION_BY_PROFILE_ID,
  GET_ACCOMODATION_ITEM_BY_PROFILE_ID,
  GET_ACCOMODATION_ITEM_BY_SERVICE_TYPE_ID,
  GET_TEMPLATE_ACCOMODATION,
  GET_TEMPLATE_ACCOMODATION_ITEM_DATA,
  POST_UPLOAD_IMAGE_ACCOMODATION_PROFILE,
  POST_UPLOAD_IMAGE_ACCOMODATION_ITEM,
  GET_ACCOMODATION_ITEM_ALL,
  GET_ACCOMMODATION_BY_CITY,
  GET_ACCOMODATION_ITEM_BY_PROFILE_ID_AND_REQUEST_DATE,
  POST_SET_PRIMARY_IMAGE,
  GET_ACCOMMODATION_WITH_FILTER,
  GET_ROOM_ALLOTMENT_BY_PROFILE_ID,
  POST_ROOM_ALLOTMENT_DAY,
  POST_ROOM_ALLOTMENT_PERIOD,
  GET_REQUEST_ROOM_ALLOTMENT,
  POST_CONFIRM_ROOM_ALLOTMENT,
  GET_LIST_CONFIRM_ROOM_ALLOTMENTS,
  GET_RESEND_REQUEST_CONFIRMATION,
  GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID,
  GET_STOCK_ROOM_ALLOTMENT,
  GET_EXPORT_STOCK_ROOM_ALLOTMENT,
  GET_SUPPLIER_LIST,
  GET_EXPORT_DATA_ITEM_BY_ID,
  GET_ACCOMMODATION_ESTIMATE_PRICE,
  GET_ACCOMMODATION_ITEM_ESTIMATE_PRICE,
} from '../constants/actionTypes';
import getAccomodationListApi from '../../../api/getAccomodationListApi';
import getAccomodationByProfiledIdApi from '../../../api/accomodation/getAccomodationByProfiledIdApi';
import getAccomodationItemByProfiledIdApi from '../../../api/accomodation/getAccomodationItemByProfiledIdApi';
import getAccomodationByServiceTypeIdApi from '../../../api/accomodation/getAccomodationByServiceTypeIdApi';
import getTemplateAccomodationProfileApi from '../../../api/accomodation/getTemplateAccomodationProfileApi';
import getTemplateAccomodationItemApi from '../../../api/accomodation/getTemplateAccomodationItemApi';
import postUploadImageAccomodationProfileApi from '../../../api/accomodation/postUploadImageAccomodationProfileApi';
import postUploadImageAccomodationItemApi from '../../../api/accomodation/postUploadImageAccomodationItemApi';
import getAccomodationItemAllApi from '../../../api/accomodation/getAccomodationItemAllApi';

import getAccommodationByCityApi from '../../../api/accomodation/getAccommodationByCityApi';
import getAccomodationItemByProfiledIdAndRequestDateApi from '../../../api/accomodation/getAccomodationItemByProfiledIdAndRequestDateApi';
import postSetPrimaryImageAccomodationProfileApi from '../../../api/accomodation/postSetPrimaryImageAccomodationProfileApi';
import postSetPrimaryImageAccomodationItemApi from '../../../api/accomodation/postSetPrimaryImageAccomodationItemApi';
import {
  getAccommodationsWithFilterApi,
  getRoomAllotmentByProfileIdApi,
  postRoomAllotmentDayApi,
  postRoomAllotmentPeriodApi,
  getRequestRoomAllotmentApi,
  postConfirmRoomAllotmentApi,
  getListConfirmRoomAllotmentsApi,
  getResendRequestConfirmationApi,
  getListRoomAllotmentByProfileIdApi,
  getStockRoomAllotmentApi,
  getExportStockRoomAllotmentApi,
  getListByCodeConfirmRoomAllotmentApi,
  getAccomodationEstimatePriceApi,
  getAccomodationItemEstimatePriceApi,
} from '../../../api/accomodation/accommodationApi';
import getSupplierListApi from '../../../api/supplier/getSupplierListApi';
import getExportDataItemByIdApi from '../../../api/supplier/getExportDataItemByIdApi';

const getAccomodationListAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_ACCOMODATION_LIST,
      payload: getAccomodationListApi(),
    });
  };
};

const resetAccomodationAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_STATUS_ACCOMODATION,
    });
  };
};

const getAccomodationByProfiledIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_ACCOMODATION_BY_PROFILE_ID,
      payload: getAccomodationByProfiledIdApi(Id),
    });
  };
};

const getAccomodationItemByProfiledIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_ACCOMODATION_ITEM_BY_PROFILE_ID,
      payload: getAccomodationItemByProfiledIdApi(Id),
    });
  };
};

const getAccomodationItemByProfiledIdAndRequestDateAction = data => {
  return dispatch => {
    return dispatch({
      type: GET_ACCOMODATION_ITEM_BY_PROFILE_ID_AND_REQUEST_DATE,
      payload: getAccomodationItemByProfiledIdAndRequestDateApi(data),
    });
  };
};

const getAccomodationItemByServiceTypeIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_ACCOMODATION_ITEM_BY_SERVICE_TYPE_ID,
      payload: getAccomodationByServiceTypeIdApi(Id),
    });
  };
};

const getTemplateAccomodationProfileAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_TEMPLATE_ACCOMODATION,
      payload: getTemplateAccomodationProfileApi,
    });
  };
};

const getTemplateAccomodationItemAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_TEMPLATE_ACCOMODATION_ITEM_DATA,
      payload: getTemplateAccomodationItemApi,
    });
  };
};

const postUploadImageAccomodationProfileAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_UPLOAD_IMAGE_ACCOMODATION_PROFILE,
      payload: postUploadImageAccomodationProfileApi(data),
    });
  };
};

const postUploadImageAccomodationItemAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: POST_UPLOAD_IMAGE_ACCOMODATION_ITEM,
      payload: postUploadImageAccomodationItemApi(id, data),
    });
  };
};

const getAccomodationItemAllAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_ACCOMODATION_ITEM_ALL,
      payload: getAccomodationItemAllApi(),
    });
  };
};

const getAccommodationByCityAction = id => {
  return dispatch => {
    return dispatch({
      type: GET_ACCOMMODATION_BY_CITY,
      payload: getAccommodationByCityApi(id),
    });
  };
};

const postSetPrimaryImageAccomodationProfileAction = id => {
  return dispatch => {
    return dispatch({
      type: POST_SET_PRIMARY_IMAGE,
      payload: postSetPrimaryImageAccomodationProfileApi(id),
    });
  };
};

const postSetPrimaryImageAccomodationItemAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: POST_SET_PRIMARY_IMAGE,
      payload: postSetPrimaryImageAccomodationItemApi(id, data),
    });
  };
};

const getAccommodationsWithFilter = data => {
  return dispatch => {
    return dispatch({
      type: GET_ACCOMMODATION_WITH_FILTER,
      payload: getAccommodationsWithFilterApi(data),
    });
  };
};

const getRoomAllotmentByProfileId = (profileId, startDate, endDate) => {
  return dispatch => {
    return dispatch({
      type: GET_ROOM_ALLOTMENT_BY_PROFILE_ID,
      payload: getRoomAllotmentByProfileIdApi(profileId, startDate, endDate),
    });
  };
};

const postRoomAllotmentDay = data => {
  return dispatch => {
    return dispatch({
      type: POST_ROOM_ALLOTMENT_DAY,
      payload: postRoomAllotmentDayApi(data),
    });
  };
};

const postRoomAllotmentPeriod = data => {
  return dispatch => {
    return dispatch({
      type: POST_ROOM_ALLOTMENT_PERIOD,
      payload: postRoomAllotmentPeriodApi(data),
    });
  };
};

const getRequestRoomAllotment = (transactionId, token) => {
  return dispatch => {
    return dispatch({
      type: GET_REQUEST_ROOM_ALLOTMENT,
      payload: getRequestRoomAllotmentApi(transactionId, token),
    });
  };
};

const postConfirmRoomAllotment = data => {
  return dispatch => {
    return dispatch({
      type: POST_CONFIRM_ROOM_ALLOTMENT,
      payload: postConfirmRoomAllotmentApi(data),
    });
  };
};

const getListConfirmRoomAllotments = (
  tourStartDate,
  tourUntilDate,
  createdStartDate,
  createdUntilDate
) => {
  return dispatch => {
    return dispatch({
      type: GET_LIST_CONFIRM_ROOM_ALLOTMENTS,
      payload: getListConfirmRoomAllotmentsApi(
        tourStartDate,
        tourUntilDate,
        createdStartDate,
        createdUntilDate
      ),
    });
  };
};

const getResendRequestConfirmation = (transactionId, token) => {
  return dispatch => {
    return dispatch({
      type: GET_RESEND_REQUEST_CONFIRMATION,
      payload: getResendRequestConfirmationApi(transactionId, token),
    });
  };
};

const getListRoomAllotmentByProfileId = profileId => {
  return dispatch => {
    return dispatch({
      type: GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID,
      payload: getListRoomAllotmentByProfileIdApi(profileId),
    });
  };
};

const getStockRoomAllotment = (
  profileId,
  startDate,
  endDate,
  serviceItemId
) => {
  return dispatch => {
    return dispatch({
      type: GET_STOCK_ROOM_ALLOTMENT,
      payload: getStockRoomAllotmentApi(
        profileId,
        startDate,
        endDate,
        serviceItemId
      ),
    });
  };
};

const getExportStockRoomAllotment = (
  profileId,
  startDate,
  endDate,
  serviceItemId
) => {
  return dispatch => {
    return dispatch({
      type: GET_EXPORT_STOCK_ROOM_ALLOTMENT,
      payload: getExportStockRoomAllotmentApi(
        profileId,
        startDate,
        endDate,
        serviceItemId
      ),
    });
  };
};

const getListByCodeConfirmRoomAllotmentAction = Code => {
  return dispatch => {
    return dispatch({
      // type: GET_LIST_CONFIRM_ROOM_ALLOTMENTS,
      payload: getListByCodeConfirmRoomAllotmentApi(Code),
    });
  };
};

const getSupplierListAction = supplierType => {
  return dispatch => {
    return dispatch({
      type: GET_SUPPLIER_LIST,
      payload: getSupplierListApi(supplierType),
    });
  };
};

const getExportDataItemByIdAction = (supplierType, profileId) => {
  return dispatch => {
    return dispatch({
      type: GET_EXPORT_DATA_ITEM_BY_ID,
      payload: getExportDataItemByIdApi(supplierType, profileId),
    });
  };
};

const getAccomodationEstimatePrice = param => {
  return dispatch => {
    return dispatch({
      type: GET_ACCOMMODATION_ESTIMATE_PRICE,
      payload: getAccomodationEstimatePriceApi(param),
    });
  };
};
const getAccommodationItemEstimatePrice = param => {
  return dispatch => {
    return dispatch({
      type: GET_ACCOMMODATION_ITEM_ESTIMATE_PRICE,
      payload: getAccomodationItemEstimatePriceApi(param),
    });
  };
};
export {
  getAccomodationListAction,
  resetAccomodationAction,
  getAccomodationByProfiledIdAction,
  getAccomodationItemByProfiledIdAction,
  getAccomodationItemByServiceTypeIdAction,
  getTemplateAccomodationProfileAction,
  getTemplateAccomodationItemAction,
  postUploadImageAccomodationProfileAction,
  postUploadImageAccomodationItemAction,
  getAccomodationItemAllAction,
  getAccommodationByCityAction,
  getAccomodationItemByProfiledIdAndRequestDateAction,
  postSetPrimaryImageAccomodationItemAction,
  postSetPrimaryImageAccomodationProfileAction,
  getAccommodationsWithFilter,
  getRoomAllotmentByProfileId,
  postRoomAllotmentDay,
  postRoomAllotmentPeriod,
  getRequestRoomAllotment,
  postConfirmRoomAllotment,
  getListConfirmRoomAllotments,
  getResendRequestConfirmation,
  getListRoomAllotmentByProfileId,
  getStockRoomAllotment,
  getExportStockRoomAllotment,
  getListByCodeConfirmRoomAllotmentAction,
  getSupplierListAction,
  getExportDataItemByIdAction,
  getAccomodationEstimatePrice,
  getAccommodationItemEstimatePrice,
};
