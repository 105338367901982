import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Segment,
  Button,
  // Icon,
  Input,
  Container,
} from 'semantic-ui-react';
import HeaderTitle from '../../shared/components/common/headerSegment/HeaderTitle';
import TablePendingConfirmationTable from './table/TablePendingConfirmationNumber';
import styles from '../styles';
import ModalAcceptRoom from './modal/ModalAcceptRoom';
import ModalDateRange from '../../shared/components/modal/ModalDateRangeCheckIn';
import {
  getPendingConfirmationListApi,
  postConfirmationNumberApi,
} from '../../../api/dashboardApi';
import useApiCall from '../hooks/useApiCallPartner';
import PaginationScript from '../../../components/genericFunction/pagination';
import moment from 'moment';
import { searchFilterPendingConfirmationNumber } from '../../../scripts/filterData';
import ModalInformation from './modal/ModalInformation';
import { getNumberFromTo } from '../../../components/genericFunction/getPaginationNumberFrom';
import { addDate, convertDateFormat } from '../../../scripts/moment';

const TODAY = new Date();

const PendingConfirmationNumber = props => {
  //=======================initial state=======================
  // const { isRefreshPending, setIsReloadUpcoming } = props;
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openDateRange, setOpenDateRange] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [activePage, setActivePage] = useState(1);
  const [requestItemId, setRequestItemId] = useState('');
  const [roomName, setRoomName] = useState('');
  const [qty, setQty] = useState('');
  const [loading, setLoading] = useState(false);
  const [openModalInformation, setOpenModalInformation] = useState(false);
  const [contentAlert, setContentAlert] = useState('');
  const [headerAlert, setHeaderAlert] = useState('');
  // const [openReject, setOpenReject] = useState(false);

  const [
    pendingConfirmLoading,
    pendingConfirmList,
    handleDateRange,
    loadData,
    startDate,
    endDate,
  ] = useApiCall(
    getPendingConfirmationListApi,
    convertDateFormat(TODAY, 'YYYY-MM-DD'),
    convertDateFormat(addDate(TODAY, 30, 'd'), 'YYYY-MM-DD')
  );

  //=======================lifecycle=======================
  // useEffect(() => {
  //   isRefreshPending && loadData();
  //   // eslint-disable-next-line
  // }, [isRefreshPending]); // Only re-run the effect if isRefreshPending is true

  //=======================helper=======================
  const confirmAllotment = (data, isAccept) => {
    setLoading(true);
    postConfirmationNumberApi(data)
      .then(() => {
        setLoading(false);
        setOpenModalInformation(true);
        // setIsReloadUpcoming(true);
        setHeaderAlert(
          isAccept ? 'Confirmation Success' : 'You Rejected The Request'
        );
        setContentAlert(
          isAccept
            ? data.ConfirmationNumber === null
              ? `Request ID ${data.RequestItemId} has been successfully Confirm`
              : `Request ID ${data.RequestItemId} has been successfully given a confirmation number ${data.ConfirmationNumber}`
            : `We understand your situation, thank you for responding to this request.`
          // Request ID {{RequestID}} has been
        );
        props.reloadUpcomingForHome(true);
      })
      .catch(err => {
        setLoading(false);
        setOpenModalInformation(true);
        setHeaderAlert('Failed');
        setContentAlert(
          err.response && err.response.data
            ? err.response.data.ModelState
              ? err.response.data.ModelState['model.ConfirmationNumber']
                ? err.response.data.ModelState['model.ConfirmationNumber']
                : err.response.data.Message
              : err.response.data.Message
            : isAccept
            ? 'Confirmation failed'
            : 'Reject request failed'
        );
      });
  };

  const actionDateRange = id => {
    let from = moment(id.from).format('YYYY-MM-DD');
    let to = moment(id.to).format('YYYY-MM-DD');
    handleClose();
    handleDateRange(from, to);
  };

  const filteredData = (data, searchInput) => {
    return searchFilterPendingConfirmationNumber(data, searchInput);
  };

  const pageChange = (e, { activePage }) => {
    setActivePage(activePage);
  };

  //=======================handler=======================
  const handleChangeSearch = e => {
    setSearchInput(e.target.value);
    setActivePage(1);
  };

  const handleOpenConfirm = (e, { name, id, content }) => {
    setRequestItemId(id);
    setRoomName(name);
    setQty(content);
    setOpenConfirmation(true);
  };
  const handleClose = () => {
    setOpenConfirmation(false);
    setOpenDateRange(false);
  };
  const handleCloseModalInformation = () => {
    setHeaderAlert('');
    setContentAlert('');
    setOpenModalInformation(false);
    setActivePage(1);
    loadData();
  };
  const handleOpenDateRange = () => {
    setOpenDateRange(true);
  };

  const handleConfirm = data => {
    // setPostData(data);
    confirmAllotment(data, 1);
  };

  //=======================pagination data=======================
  let filterData = filteredData(
    pendingConfirmList !== null ? pendingConfirmList : [],
    searchInput
  );
  let pagedData = PaginationScript(filterData, 5);
  let listData = pagedData[activePage - 1];
  let { numbersFrom, numbersTo } = getNumberFromTo(listData, activePage, 5);

  let checkInUntilDate = !endDate ? '' : moment(endDate).format('DD MMM YYYY');
  let checkInStartDate = !startDate ? '' : moment(startDate).format('DD MMM');

  return (
    <Grid columns="equal" style={styles.styleGrid}>
      <Grid.Row stretched>
        <Grid.Column width={15}>
          <Segment loading={pendingConfirmLoading || loading}>
            <HeaderTitle title="Pending Confirmation No." />
            <Input
              icon="search"
              placeholder="Search"
              name="searchInput"
              value={searchInput}
              onChange={handleChangeSearch}
            />
            <Button
              icon="filter"
              basic
              size="small"
              floated="right"
              positive
              onClick={handleOpenDateRange}
            />
            <div
              style={{
                marginTop: '-2.9em',
                marginRight: '5em',
                fontWeight: 'bold',
              }}
            >
              {/* {(filterByDate.tourStartDate !== '' ||
                    filterByDate.tourUntilDate !== '') && ( */}
              <Container
                content={
                  'Check-in range date : ' +
                  checkInStartDate +
                  ' - ' +
                  checkInUntilDate
                }
                textAlign="right"
              />
            </div>
            <Grid.Row style={styles.styleRowTable}>
              <TablePendingConfirmationTable
                activePage={activePage}
                data={pendingConfirmList}
                pagedData={pagedData}
                listData={listData}
                numbersFrom={numbersFrom}
                numbersTo={numbersTo}
                pageChange={pageChange}
                handleClose={handleClose}
                handleOpenConfirm={handleOpenConfirm}
              />
            </Grid.Row>
          </Segment>
        </Grid.Column>
      </Grid.Row>
      <ModalAcceptRoom
        // message={messageConfirm}
        handleConfirm={handleConfirm}
        openModal={openConfirmation}
        close={handleClose}
        requestItemId={requestItemId}
        roomName={roomName}
        qty={qty}
        // pending={true}
      />
      <ModalDateRange
        contentHeader="Pending Confirmation Number"
        openModal={openDateRange}
        close={handleClose}
        actionDateRange={actionDateRange}
        startDate={startDate}
        endDate={endDate}
      />
      <ModalInformation
        close={handleClose}
        open={openModalInformation}
        content={contentAlert}
        modalHeader={headerAlert}
        onClickButton={handleCloseModalInformation}
        size="mini"
        contentButton="OK"
      />
    </Grid>
  );
};

PendingConfirmationNumber.propTypes = {
  reloadUpcomingForHome: PropTypes.func,
};

export default React.memo(PendingConfirmationNumber);
