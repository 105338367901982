import React from 'react';
import { Grid, Segment, Button, Container } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import TabelExchangeRateList from './TableExchangeRate';
import { HeaderTabelExchangeRate } from '../constant/TableHeader';
// import InputDate from '../../../components/input/inputDate';
import HeaderTitle from '../../../components/common/headerTitle';
import Pagination from '../../../components/common/pagination';
import { Styles } from '../Styles';
import moment from 'moment';

const SegmentExchangeRate = props => (
  <div>
    <Grid columns="equal">
      <Grid.Row stretched>
        <Grid.Column>
          <Segment style={Styles.Segment}>
            <HeaderTitle iconName="money" title={props.title} />
            <Grid style={Styles.Grid}>
              <Grid.Row columns="4">
                <Grid.Column />
                <Grid.Column width={12}>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Button
                          icon="plus"
                          content="Add New"
                          positive
                          floated="right"
                          onClick={props.openNewRate}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Button
                          icon="filter"
                          basic
                          size="small"
                          floated="right"
                          positive
                          onClick={props.openModalDateRange}
                        />
                        <div
                          style={{
                            marginTop: '-2px',
                            marginRight: '5em',
                            fontWeight: 'bold',
                            // marginBottom: '3em',
                          }}
                        >
                          <Container
                            content={'Filter by valid date'}
                            textAlign="right"
                          />
                          <Container
                            content={
                              props.dateRange
                                ? `${
                                    props.dateRange
                                      ? moment(props.dateRange.from).format(
                                          'DD MMM YYYY'
                                        )
                                      : ''
                                  } ${props.dateRange ? '-' : ''} ${
                                    props.dateRange
                                      ? moment(props.dateRange.to).format(
                                          'DD MMM YYYY'
                                        )
                                      : ''
                                  }`
                                : '-'
                            }
                            textAlign="right"
                          />
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <TabelExchangeRateList
              data={props.data}
              headerTable={HeaderTabelExchangeRate}
              pageChange={props.pageChange}
              listData={props.listData}
              pagedData={props.pagedData}
              editExchangeRate={props.editExchangeRate}
              deleteExchangeRate={props.buttonDelete}
              activePage={props.activePage}
            />
            <Pagination
              pagedData={props.pagedData}
              numbersTo={props.numbersTo}
              numbersFrom={props.numbersFrom}
              data={props.data}
              pageChange={props.pageChange}
              activePage={props.activePage}
            />
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
);

SegmentExchangeRate.propTypes = {
  listData: PropTypes.array,
  pagedData: PropTypes.array,
  title: PropTypes.string,
  pageChange: PropTypes.func,
  activePage: PropTypes.number,
  // searchInput: PropTypes.string,
  handleChange: PropTypes.func,
  data: PropTypes.array,
  openNewRate: PropTypes.func,
  editExchangeRate: PropTypes.func,
  sortValue: PropTypes.number,
  handleSorting: PropTypes.func,
  buttonDelete: PropTypes.func,
  numbersFrom: PropTypes.number,
  numbersTo: PropTypes.number,
  //
  openModalDateRange: PropTypes.func,
  searchInput: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dateRange: PropTypes.object,
};
export default SegmentExchangeRate;
