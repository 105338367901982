import React from 'react';
import { Image, Grid, Header, Button, Container } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { StyleButton } from '../../styles';

const LogoSupplierTransportationProfile = props => {
  let myImageInput = React.createRef();
  return (
    <div>
      <Grid columns="equal">
        <Grid.Row stretched>
          <Grid.Column width={15}>
            <Header as="h4" content={props.headerContent} />
            <Image
              src={
                props.imagePreview.ImageUrl
                  ? props.imagePreview.ImageUrl
                  : props.imagePreview.ImagePreview ||
                    require('../../../../assets/Icons/no_image.jpg')
              }
              size="medium"
              circular
              style={StyleButton.styleImagePreview}
            />
          </Grid.Column>
          {/* <div style={StyleButton.buttonLogoSupplier}> */}
          <Container textAlign="center" style={{ marginTop: '20px' }}>
            <Button
              content="Change"
              positive
              id="SupplierLogo"
              onClick={() => myImageInput.click()}
              //onClick={props.modalImage}
              disabled={props.disablePage}
            />
            {/* <Button
              content="Remove"
              negative
              onClick={props.removeImage}
              id={0}
              disabled={props.disablePage}
            /> */}
            <input
              hidden
              type="file"
              onChange={props.onImageSelect}
              ref={imageInput => (myImageInput = imageInput)}
              accept="image/gif,image/jpeg,image/jpg,image/png"
            />
          </Container>
          {/* </div> */}
        </Grid.Row>
      </Grid>
    </div>
  );
};
LogoSupplierTransportationProfile.propTypes = {
  modalImage: PropTypes.func,
  removeImage: PropTypes.func,
  imagePreview: PropTypes.string,
  headerContent: PropTypes.string,
  disablePage: PropTypes.bool,
  onImageSelect: PropTypes.func,
};
export default LogoSupplierTransportationProfile;
