import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Table,
  Grid,
  // Pagination,
  Checkbox,
  Button,
} from 'semantic-ui-react';
import ButtonClose from '../../../components/button/buttonCloseModal';
import Input from '../../../components/input/inputWithLabel';
import { connect } from 'react-redux';
import { resetNotAllowedCountryAction } from '../notAllowedCompanyAction';
import { putAllowedForCompanyAction } from '../notAllowedCompanyAction';
import Loader from '../../../components/modal/loaderModal';
import Pagination from '../../../components/common/pagination';
import { getNumberFromTo } from '../../../scripts/pagination';

class ModalNotAllowedCountryForCompany extends Component {
  state = {
    searchNotAllowed: '',
    activePage: 1,
    notAllowedCountry: [],
  };
  pagination = (data, perPage) => {
    let sets = [],
      chunks = data.length / perPage;
    for (let i = 0, j = 0; i < chunks; i++, j += perPage)
      sets[i] = data.slice(j, j + perPage);
    return sets;
  };
  handleChange = (e, { name, value }) =>
    this.setState({ ...this.state, [name]: value, activePage: 1 });
  pageChange = (e, { activePage }) => {
    this.setState({ ...this.state, activePage });
  };
  componentDidUpdate() {
    const {
      getNotAllowedCountryForCountryByCompanyCodeStatus,
      getNotAllowedCountryForCountryByCompanyCode,
      resetNotAllowedCountryAction,
      handleOpenModalAllowed,
    } = this.props;
    if (getNotAllowedCountryForCountryByCompanyCodeStatus === 'success') {
      const { countryList } = this.props;
      const listCountry = getNotAllowedCountryForCountryByCompanyCode;
      let newList = countryList.map(listItem => {
        listCountry.map(listAllowed => {
          if (listItem.Id === listAllowed.Country.Id) {
            listItem['IsAllowed'] = !listAllowed.IsAllowed;
            listItem['onChange'] = false;
            return listAllowed;
          }
          return listAllowed;
        });
        return listItem;
      });
      this.setState({
        activePage: 1,
        notAllowedCountry: newList,
        listAllowed: listCountry,
      });
      resetNotAllowedCountryAction();
      handleOpenModalAllowed();
    }
  }

  handleChangeCheckBox = (e, { name, checked }) => {
    let list = this.state.notAllowedCountry;
    list.map(location => {
      if (location.Id === name) {
        location['IsAllowed'] = checked;
        location['onChange'] = true;
      }
      return location;
    });
    this.setState({ notAllowedCountry: list });
  };

  handleSaveNotAllowedCompany = () => {
    const { notAllowedCountry } = this.state;
    const {
      CompanyCode,
      putAllowedForCompanyAction,
      reloadPage,
      handleMessageError,
    } = this.props;
    let data = notAllowedCountry.filter(data => data.onChange === true);
    let changedData = data.reduce((acc, rec) => {
      let newRec = {
        IsAllowed: rec.IsAllowed === true ? false : true,
        CountryId: rec.Id,
        CompanyCode: CompanyCode,
      };
      acc.push(newRec);
      return acc;
    }, []);

    putAllowedForCompanyAction(CompanyCode, changedData)
      .then(() => reloadPage())
      .catch(() => handleMessageError());
  };
  render() {
    // const props = this.props;
    let { isOpen, onClose, loading } = this.props;
    let { searchNotAllowed, activePage, notAllowedCountry } = this.state;
    let filterCompanies = notAllowedCountry.filter(
      e =>
        searchNotAllowed
          ? e.Name.toLowerCase().indexOf(searchNotAllowed.toLowerCase()) !== -1
          : true
    );
    let pagedData = this.pagination(filterCompanies, 7);
    let listData = pagedData[activePage - 1];

    let { numbersFrom, numbersTo } = getNumberFromTo(listData, activePage, 7);

    return (
      <Modal open={isOpen} onClose={onClose} size="small" closeOnDimmerClick>
        <Loader loading={loading} />
        <Modal.Header>
          Showing List Country <ButtonClose onClose={onClose} />
        </Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column width={6}>
                <Input
                  type="text"
                  placeholder="Search company by name"
                  icon="search"
                  iconPosition="left"
                  inputStyles={{ width: '100%' }}
                  handleChange={this.handleChange}
                  value={searchNotAllowed}
                  name="searchNotAllowed"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Table basic="very" selectable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Code</Table.HeaderCell>
                      <Table.HeaderCell>Country</Table.HeaderCell>
                      <Table.HeaderCell>Not Allowed Country</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {listData &&
                      listData.map((country, index) => (
                        <Table.Row key={index}>
                          <Table.Cell>{country.Id}</Table.Cell>
                          <Table.Cell>{country.Name}</Table.Cell>
                          <Table.Cell>
                            <Checkbox
                              checked={country.IsAllowed}
                              name={country.Id}
                              onChange={this.handleChangeCheckBox}
                            />
                          </Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Column width={16} style={{ paddingTop: '10px' }}>
                {/* {pagedData &&
                  pagedData.length > 1 && ( */}
                <Pagination
                  // totalPages={pagedData.length}
                  // onPageChange={this.pageChange}
                  // activePage={activePage}
                  // floated="right"
                  pagedData={pagedData}
                  numbersTo={numbersTo}
                  numbersFrom={numbersFrom}
                  data={filterCompanies}
                  pageChange={this.pageChange}
                  activePage={activePage}
                />
                {/* )}
                {pagedData &&
                  pagedData.length > 1 && (
                    <p>
                      Showing {numbersTo} to {numbersFrom} of
                      {notAllowedCountry.length}
                    </p>
                  )} */}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            content="Save"
            onClick={this.handleSaveNotAllowedCompany}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    getNotAllowedCountryForCountryByCompanyCodeStatus:
      state.NotAllowedCountryStore
        .getNotAllowedCountryForCountryByCompanyCodeStatus,
    getNotAllowedCountryForCountryByCompanyCode:
      state.NotAllowedCountryStore.getNotAllowedCountryForCountryByCompanyCode,
    loading: state.NotAllowedCountryStore.loading,
    errorMessage: state.NotAllowedCountryStore.errors,
  };
}

ModalNotAllowedCountryForCompany.propTypes = {
  listData: PropTypes.array,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  companies: PropTypes.array,
  handleButtonPick: PropTypes.func,
  getNotAllowedCountryForCountryByCompanyCodeStatus: PropTypes.string,
  getNotAllowedCountryForCountryByCompanyCode: PropTypes.array,
  resetNotAllowedCountryAction: PropTypes.func,
  countryList: PropTypes.array,
  CompanyCode: PropTypes.string,
  putAllowedForCompanyAction: PropTypes.func,
  reloadPage: PropTypes.func,
  loading: PropTypes.bool,
  handleOpenModalAllowed: PropTypes.func,
  handleMessageError: PropTypes.func,
};

export default connect(
  mapStateToProps,
  {
    resetNotAllowedCountryAction,
    putAllowedForCompanyAction,
  }
)(ModalNotAllowedCountryForCompany);
