import { convertDateFormat } from '../../../../../scripts/moment';
// import SetNewDataPrice from './setNewDataPrice';
const setDataPrice = (ServiceItemId, state, language) => {
  let currentData = state[0] ? state[0] : [];
  let data = {};
  if (state.length > 0) {
    data = {
      Id: currentData.Id,
      CutOffDay: currentData.CutOffDay ? currentData.CutOffDay : 0,
      Hours: currentData.CalculationBase.Code
        ? currentData.CalculationBase.Code
        : 0,
      Cost: currentData.Cost ? currentData.Cost : 0,
      StartDate: convertDateFormat(currentData.StartDate, 'YYYY-MM-DD'),
      EndDate: convertDateFormat(currentData.EndDate, 'YYYY-MM-DD'),
      UomId: 'PAX',
      TaxTypeId: 7,
      Currency: currentData.Currency ? currentData.Currency : 0,
      Rank: 1,
      // ServiceItemId: currentData.Id ? currentData.Id : ServiceItemId,
      ServiceItemId: ServiceItemId,
      ServicePriceType: 501,
      MinimumPax: 0,
      Description: '',
      TourGuideLanguage: language,
      postStatus: false,
    };
  } else {
    data = {
      CutOffDay: 0,
      Hours: 0,
      Cost: 0,
      StartDate: convertDateFormat(new Date(), 'YYYY-MM-DD'),
      EndDate: convertDateFormat(new Date(), 'YYYY-MM-DD'),
      UomId: 'PAX',
      TaxTypeId: 7,
      Currency: '',
      Rank: 1,
      ServiceItemId: 0,
      ServicePriceType: 501,
      MinimumPax: 0,
      Description: '',
      TourGuideLanguage: language,
      LanguageId: '',
      postStatus: true,
      Action: 2,
    };
  }
  return data;
};

export default setDataPrice;
