const setDataCompanyProfile = (id, object) => {
  let data = {};
  if (id === 0) {
    data = {
      CompanyId: '',
      Name: '',
      Address: '',
      Telephone: '',
      CountryId: '',
      CurrencyId: '',
      CompanyBusiness: 'Supplier',
      ImageContent: '',
      ImagePreview: '',
      CompanyLogo: {
        ImageName: '',
        ImageContent: '',
        ImagePreview: '',
      },
      Email: '',
      FaxNbr: '',
      AccommodationProfileId: '',
    };
  } else {
    const {
      Name,
      ImageName,
      ImageUrl,
      CountryId,
      Address,
      Email,
      Telephone,
      FaxNbr,
      Code,
      Currency,
      AccommodationProfile,
      CompanyLogo,
    } = object;
    data = {
      CompanyId: Code ? Code : '',
      Name: Name ? Name : '',
      Address: Address ? Address : '',
      Telephone: Telephone ? Telephone : '',
      Email: Email ? Email : '',
      FaxNbr: FaxNbr ? FaxNbr : '',
      CountryId: CountryId ? CountryId : '',
      CurrencyId: Currency ? Currency : '',
      ImageName: ImageName ? ImageName : '',
      ImageContent: ImageUrl ? ImageUrl : '',
      ImagePreview: ImageUrl ? ImageUrl : '',
      CompanyBusiness: 'Supplier',
      CompanyLogo: {
        ImageContent: CompanyLogo ? CompanyLogo.ImageUrl : '',
        ImageName: CompanyLogo ? CompanyLogo.ImageName : '',
        ImagePreview: CompanyLogo ? CompanyLogo.ImageUrl : '',
      },
      AccommodationProfileId: AccommodationProfile
        ? AccommodationProfile.Id
        : '',
    };
  }
  return data;
};

export default setDataCompanyProfile;
