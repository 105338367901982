import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form } from 'semantic-ui-react';
import Input from './../input/inputWithLabel';
import InputDate from './../input/inputDate';
import moment from 'moment';
import { connect } from 'react-redux';
import { copyPackageAction } from './../../actions/packageAction';
import ModalAlert from './modalAlert';
import Loader from './../loader/loaderInModal';
import { Redirect } from 'react-router-dom';
import ButtonClose from './../button/buttonCloseModal';
class ModalCopyPackage extends React.Component {
  state = {
    startDate: '',
    header: '',
    message: '',
    nextPage: null,
    openAlert: false,
    goNextPage: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({ ...this.state, startDate: this.props.startDate });
    }
  }

  handleDate = (date, name) => {
    this.setState({
      ...this.state,
      [name]: date,
    });
  };

  copyPackage = () => {
    let { type, id } = this.props;
    let startDate = moment(this.state.startDate).format('MM/DD/YYYY');
    // let isVariableDate = type === 'FixedDateVariable';
    this.props
      .copyPackageAction(
        type === 'FixedDateVariable' ? 'VariableDate' : type,
        id,
        startDate
      )
      .then(() => {
        let link =
          type === 'FixedDateVariable'
            ? `/Package-Details/FixedDateVariable/edit/${this.props.copyPackage}`
            : `/Package-Details/${type}/edit/${this.props.copyPackage}`;
        this.openAlert(
          'Success',
          `Copy package successfully created with id ${this.props.copyPackage} `,
          link
        );
      })
      .catch(() => this.openAlert('Failed', `Failed Copy Package`, null));
  };

  openAlert = (header, message, nextPage) =>
    this.setState({
      ...this.state,
      header,
      message,
      nextPage,
      openAlert: true,
    });
  handleCloseAlert = () => {
    this.setState({ ...this.state, openAlert: false, goNextPage: true }, () =>
      window.location.reload()
    );
  };
  render() {
    let { open, handleClose, type, id, loading } = this.props;
    let {
      startDate,
      header,
      message,
      nextPage,
      openAlert,
      goNextPage,
    } = this.state;
    if (goNextPage) {
      return <Redirect to={nextPage} />;
    }
    return (
      <Modal
        dimmer="blurring"
        inverted
        open={open}
        onClose={handleClose}
        // size="mini"
        style={{ width: '300px' }}
        closeOnDimmerClick={false}
      >
        <Loader loading={loading} />
        <Modal.Header>
          Copy {type} Package
          <ButtonClose onClose={handleClose} />{' '}
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Input
              placeholder="Id Package"
              value={id}
              type="text"
              disabled
              label="Id Package"
            />
            <InputDate
              label="Start Date"
              name="startDate"
              value={startDate}
              setDate={this.handleDate}
              labelStyle={{
                display: 'block',
                fontWeight: 'bold',
                marginBottom: '4px',
              }}
              readonly={false}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Copy Package"
            positive
            onClick={this.copyPackage}
            inverted
          />
        </Modal.Actions>
        <ModalAlert
          openModal={openAlert}
          handleClose={this.handleCloseAlert}
          header={header}
          content={message}
        />
      </Modal>
    );
  }
}

ModalCopyPackage.propTypes = {
  open: PropTypes.bool,
  id: PropTypes.number,
  type: PropTypes.string,
  startDate: PropTypes.string,
  handleClose: PropTypes.func,
  copyPackageAction: PropTypes.func,
  copyPackage: PropTypes.object,
  loading: PropTypes.bool,
  isVariableDate: PropTypes.bool,
};
function mapStateToProps(state) {
  return {
    copyPackage: state.PackageStore.copyPackage,
    loading: state.PackageStore.loading,
  };
}
export default connect(
  mapStateToProps,
  {
    copyPackageAction,
  }
)(ModalCopyPackage);
