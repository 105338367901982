import apiClient from './apiClient';
import {
  GET_ACTIVITY_LIST_URL,
  GET_DRIVING_DURATION_URL,
  GET_FIXED_PACKAGE,
  POST_PACKAGE_URL,
  POST_PUBLISH_PACKAGE_URL,
  PUT_PACKAGE_URL,
  GET_PACKAGES_URL,
  GET_TOUR_OPERATOR_PROFILE_URL,
  GET_PUBLISHED_PACKAGE_URL,
  DELETE_PACKAGE_URL,
  COPY_PACKAGE_URL,
  UPLOAD_BROCHURE_PACKAGE_URL,
  PUT_GENERAL_INFO_PACKAGE_URL,
  PUT_ALLOWED_PAX_PACKAGE_URL,
  GET_TOUR_OPERATOR_PROFILE_BY_CODE_URL,
  GET_TOUR_OPERATOR_PROFILE_DETAIL_BY_ID_URL,
  DELETE_TOUR_OPERATOR_URL,
  UPDATE_TOUR_OPERATOR_PROFILE_URL,
  GET_SERIES_VARIABLE_DATE_URL,
  BOOKINGTEMPLATEIMAGES_URL,
  PUT_ADDITIONAL_INFO_PACKAGE_OPERATION_URL,
  GET_DOWNLOAD_SCHEDULE_BY_BOOKING_TEMPLATE_URL,
} from './apiUrl';

const getActivityListApi = () => {
  return apiClient.get(GET_ACTIVITY_LIST_URL);
};
const getDurationAddresstoIdApi = (from, to) => {
  return apiClient.get(
    `${GET_DRIVING_DURATION_URL}?originAddress=${from}&destinationServiceItemId=${to}&mode=DRIVING`
  );
};
const getDurationIdtoAddressApi = (from, to) => {
  return apiClient.get(
    `${GET_DRIVING_DURATION_URL}?originServiceItemId=${from}&destinationAddress=${to}&mode=DRIVING`
  );
};
const getDurationIdtoIdApi = (from, to) => {
  return apiClient.get(
    `${GET_DRIVING_DURATION_URL}?originServiceItemId=${from}&destinationServiceItemId=${to}&mode=DRIVING`
  );
};
const getDurationAddresstoAddressApi = (from, to) => {
  return apiClient.get(
    `${GET_DRIVING_DURATION_URL}?originAddress=${from}&destinationAddress=${to}&mode=DRIVING`
  );
};
const postPublishPackageApi = (typePackage, typeAction, id) => {
  return apiClient.post(POST_PUBLISH_PACKAGE_URL(typePackage, typeAction, id));
};
const getPackageApi = (type, id) => {
  return apiClient.get(GET_FIXED_PACKAGE(type, id));
};
const getPackagesApi = type => {
  return apiClient.get(GET_PACKAGES_URL(type));
};

const getSeriesVariableDateApi = () => {
  return apiClient.get(GET_SERIES_VARIABLE_DATE_URL);
};
const postPackageApi = (type, data) => {
  return apiClient.post(POST_PACKAGE_URL(type), data);
};
const putPackageApi = (id, data) => {
  return apiClient.put(PUT_PACKAGE_URL(id), data);
};
const getTourOperatorProfileApi = () => {
  return apiClient.get(GET_TOUR_OPERATOR_PROFILE_URL);
};
const getTourOperatorProfileByCodeApi = id => {
  return apiClient.get(GET_TOUR_OPERATOR_PROFILE_BY_CODE_URL(id));
};
const getPackagePublishedApi = () => {
  return apiClient.get(GET_PUBLISHED_PACKAGE_URL);
};
const deletePackageApi = id => {
  return apiClient.delete(DELETE_PACKAGE_URL(id));
};
const copyPackageApi = (type, id, startDate) => {
  return apiClient.post(COPY_PACKAGE_URL(type, id, startDate));
};
const uploadBrochurePackageApi = (id, data) => {
  return apiClient.post(UPLOAD_BROCHURE_PACKAGE_URL(id), data);
};
const putGeneralInfoPackageApi = (id, data) => {
  return apiClient.put(PUT_GENERAL_INFO_PACKAGE_URL(id), data);
};
const putAllowedPaxPackageApi = (id, data) => {
  return apiClient.post(PUT_ALLOWED_PAX_PACKAGE_URL(id), data);
};
const getTourOperatorProfileDetailByIdApi = id => {
  return apiClient.get(GET_TOUR_OPERATOR_PROFILE_DETAIL_BY_ID_URL(id));
};
const deleteTourOperatorApi = id => {
  return apiClient.delete(DELETE_TOUR_OPERATOR_URL(id));
};
const putTourOperatorApi = (id, data) => {
  return apiClient.put(UPDATE_TOUR_OPERATOR_PROFILE_URL(id), data);
};

const putAdditionalInfoOperationApi = data => {
  return apiClient.put(
    `${PUT_ADDITIONAL_INFO_PACKAGE_OPERATION_URL}/${data.packageType}/${data.id}`,
    data.data
  );
};

const deleteBookingTemplateImagesApi = id => {
  return apiClient.delete(BOOKINGTEMPLATEIMAGES_URL(id));
};
const putBookingTemplateImagesApi = id => {
  return apiClient.put(BOOKINGTEMPLATEIMAGES_URL(id));
};

const getDownloadScheduleByBookingTemplateApi = templateId => {
  return apiClient.get(
    `${GET_DOWNLOAD_SCHEDULE_BY_BOOKING_TEMPLATE_URL}?bookingTemplateId=${templateId}`
  );
};
export {
  getActivityListApi,
  getDurationAddresstoIdApi,
  getDurationIdtoAddressApi,
  getDurationIdtoIdApi,
  getDurationAddresstoAddressApi,
  getPackageApi,
  getPackagesApi,
  postPackageApi,
  postPublishPackageApi,
  putPackageApi,
  getTourOperatorProfileApi,
  getPackagePublishedApi,
  deletePackageApi,
  copyPackageApi,
  uploadBrochurePackageApi,
  putGeneralInfoPackageApi,
  putAllowedPaxPackageApi,
  getTourOperatorProfileByCodeApi,
  getTourOperatorProfileDetailByIdApi,
  deleteTourOperatorApi,
  putTourOperatorApi,
  getSeriesVariableDateApi,
  deleteBookingTemplateImagesApi,
  putBookingTemplateImagesApi,
  putAdditionalInfoOperationApi,
  getDownloadScheduleByBookingTemplateApi,
};
