import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TableAddittionalItemPackage, ModalAddittionalItem } from './';
import { HeaderTableAdditionalItem } from '../../../../components/table/headerTabel';
import { Button } from 'semantic-ui-react';
import ModalConfirm from '../../../../components/modal/modalConfirmationTwoButton';

const styleModalAdditional = {
  marginBottom: '2em',
};
class TabAddittionalItemPacakge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openConfrim: false,
      messageConfirm: '',
      openModalAddittionalItem: false,
      additionalItemObject: {},
    };
  }
  handleOpenModalAdditionalItem = () => {
    this.setState({
      openModalAddittionalItem: true,
      additionalItemObject: {},
      indexEdit: -1,
    });
  };
  editAdditionalItem = (e, { name }) => {
    const { listAdditionalItem } = this.props;
    const { Index } = name;
    let additionalItemObject = listAdditionalItem[Index];
    this.setState({
      openModalAddittionalItem: true,
      additionalItemObject: additionalItemObject,
      indexEdit: Index,
    });
  };
  deleteAdditionalItem = () => {
    const { indexAdditionalItem } = this.state;
    let { setAddittionalItem, listAdditionalItem } = this.props;
    listAdditionalItem.splice(indexAdditionalItem, 1);
    this.setState({ openConfrim: false });
    setAddittionalItem(listAdditionalItem);
  };
  handleClose = () => {
    this.setState({
      openModalAddittionalItem: false,
      additionalItemObject: {},
      openConfrim: false,
    });
  };

  handleChangeAddittionalItem = (e, { name, value }) => {
    let { additionalItemObject } = this.state;
    additionalItemObject = { ...additionalItemObject, [name]: value };
    this.setState({ additionalItemObject: additionalItemObject });
  };

  handleChangeAddittionalItemPrice = (value, name) => {
    let { additionalItemObject } = this.state;
    additionalItemObject = { ...additionalItemObject, [name]: value };
    this.setState({ additionalItemObject: additionalItemObject });
  };
  handleChangeAddittionalItemCheckBox = (e, { name, checked }) => {
    let { additionalItemObject } = this.state;
    additionalItemObject = { ...additionalItemObject, [name]: checked };
    if (name === 'IsMandatory' && checked === false) {
      additionalItemObject = { ...additionalItemObject, IsInfantCount: false };
    }
    this.setState({ additionalItemObject: additionalItemObject });
  };

  handleIsActive = async () => {
    let { additionalItemObject } = this.state;
    await this.setState({
      additionalItemObject: {
        ...additionalItemObject,
        IsHiddenData: !additionalItemObject.IsHiddenData,
      },
    });
  };

  addAddittionalItem = () => {
    let { additionalItemObject, indexEdit } = this.state;
    let { setAddittionalItem, listAdditionalItem } = this.props;
    if (indexEdit !== -1) {
      listAdditionalItem[indexEdit] = additionalItemObject;
    } else {
      listAdditionalItem.push(additionalItemObject);
    }
    setAddittionalItem(listAdditionalItem);
    this.setState({
      openModalAddittionalItem: false,
    });
  };
  confirmDelete = (e, { name, id }) => {
    const { Index } = name;
    this.setState({
      openConfrim: true,
      messageConfirm: 'Are you sure to delete ' + id + '?',
      additionalItemName: id,
      indexAdditionalItem: Index,
    });
  };
  render() {
    const props = this.props;
    const {
      openModalAddittionalItem,
      additionalItemObject,
      openConfrim,
      messageConfirm,
    } = this.state;
    return (
      <div>
        <Button
          positive
          content="Add New"
          icon="plus"
          onClick={this.handleOpenModalAdditionalItem}
          floated="right"
          style={styleModalAdditional}
          disabled={props.isPublished || props.isOperation}
        />
        <TableAddittionalItemPackage
          listData={props.listAdditionalItem}
          headerTable={HeaderTableAdditionalItem}
          editAdditionalItem={this.editAdditionalItem}
          deleteAdditionalItem={this.confirmDelete}
          isPublished={props.isPublished}
          isOperation={props.isOperation}
        />
        <ModalAddittionalItem
          openModal={openModalAddittionalItem}
          addAddittionalItem={this.addAddittionalItem}
          handleClose={this.handleClose}
          additionalItemObject={additionalItemObject}
          handleChange={this.handleChangeAddittionalItem}
          handleChangeAddittionalItemPrice={
            this.handleChangeAddittionalItemPrice
          }
          handleChangeAddittionalItemCheckBox={
            this.handleChangeAddittionalItemCheckBox
          }
          handleIsActive={this.handleIsActive}
        />
        <ModalConfirm
          openModal={openConfrim}
          size="mini"
          close={this.handleClose}
          confirm={this.deleteAdditionalItem}
          message={messageConfirm}
        />
      </div>
    );
  }
}

TabAddittionalItemPacakge.propTypes = {
  setAddittionalItem: PropTypes.func,
  listAdditionalItem: PropTypes.array,
  isOperation: PropTypes.bool,
  isPublished: PropTypes.bool,
};
export default TabAddittionalItemPacakge;
