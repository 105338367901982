import {
  GET_EXCURSION_PROFILES,
  GET_EXCURSION_PROFILE,
  RESET_STATUS_EXCURSION,
  GET_EXCURSION_TYPE,
  GET_TEMPLATE_EXCURSION_OBJECT_DATA,
  GET_TEMPLATE_EXCURSION_PRICE_DATA,
  POST_UPLOAD_IMAGE_EXCURSION_OBJECT,
  GET_EXCURSION_PROFILE_BY_CITY,
  POST_SET_PRIMARY_IMAGE,
  GET_EXPORT_DATA_ITEM_BY_ID,
} from '../../shared/constants/actionTypes';
import { GET_ATTRACTION_ESTIMATE_PRICE } from '../../../actions/actionTypes';
import getExcursionListApi from '../../../api/excurtion/getExcursionListApi';
import getExcursionApi from '../../../api/excurtion/getExcurtionApi';
import getExcursionTypeApi from '../../../api/excurtion/getExcursionTypeApi';
import getTemplateExcursionPriceApi from '../../../api/excurtion/getTemplateExcursionPriceApi';
import getTemplateExcursionObjectApi from '../../../api/excurtion/getTemplateExcursionObjectApi';
import postUploadImageExcursionObjectApi from '../../../api/excurtion/postUploadImageExcursionObjectApi';
import getExcurtionByCityApi from '../../../api/excurtion/getExcurtionByCityApi';
import postSetPrimaryExcursionObjectApi from '../../../api/excurtion/postSetPrimaryExcursionObjectApi';
import {
  getExcursionByCityWithFilterApi,
  getAttractionEstimatePriceApi,
} from '../../../api/excurtion/excursionApi';
import getExportDataItemByIdApi from '../../../api/supplier/getExportDataItemByIdApi';
//
const getExcursionListAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_EXCURSION_PROFILES,
      payload: getExcursionListApi(),
    });
  };
};
//
const getExcursionAction = id => {
  return dispatch => {
    return dispatch({
      type: GET_EXCURSION_PROFILE,
      payload: getExcursionApi(id),
    });
  };
};
//
const getExcursionByCityAction = id => {
  return dispatch => {
    return dispatch({
      type: GET_EXCURSION_PROFILE_BY_CITY,
      payload: getExcurtionByCityApi(id),
    });
  };
};
//
const getExcursionTypeAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_EXCURSION_TYPE,
      payload: getExcursionTypeApi(),
    });
  };
};
//
const resetExcursionAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_STATUS_EXCURSION,
    });
  };
};
//
const getTemplateExcursionObjectAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_TEMPLATE_EXCURSION_OBJECT_DATA,
      payload: getTemplateExcursionObjectApi,
    });
  };
};
//
const getTemplateExcursionPriceAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_TEMPLATE_EXCURSION_PRICE_DATA,
      payload: getTemplateExcursionPriceApi,
    });
  };
};
//
const postUploadImageExcursionObjectAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: POST_UPLOAD_IMAGE_EXCURSION_OBJECT,
      payload: postUploadImageExcursionObjectApi(id, data),
    });
  };
};
//
const postSetPrimaryExcursionObjectAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: POST_SET_PRIMARY_IMAGE,
      payload: postSetPrimaryExcursionObjectApi(id, data),
    });
  };
};
//
const getExcursionByCityWithFilterAction = (
  cityId,
  attractionTypeId,
  requestedDate,
  pax
) => {
  return dispatch => {
    return dispatch({
      type: GET_EXCURSION_PROFILE_BY_CITY,
      payload: getExcursionByCityWithFilterApi(
        cityId,
        attractionTypeId,
        requestedDate,
        pax
      ),
    });
  };
};

const getExportDataItemByIdAction = (supplierType, profileId) => {
  return dispatch => {
    return dispatch({
      type: GET_EXPORT_DATA_ITEM_BY_ID,
      payload: getExportDataItemByIdApi(supplierType, profileId),
    });
  };
};
const getAttractionEstimatePrice = param => {
  return dispatch => {
    return dispatch({
      type: GET_ATTRACTION_ESTIMATE_PRICE,
      payload: getAttractionEstimatePriceApi(param),
    });
  };
};
export {
  getExcursionListAction,
  resetExcursionAction,
  getExcursionTypeAction,
  getExcursionAction,
  getTemplateExcursionPriceAction,
  getTemplateExcursionObjectAction,
  postUploadImageExcursionObjectAction,
  getExcursionByCityAction,
  postSetPrimaryExcursionObjectAction,
  getExcursionByCityWithFilterAction,
  getExportDataItemByIdAction,
  getAttractionEstimatePrice,
};
