const setContentPaymentTerm = obj => {
  let contentPaymentTerm = {
    Id: obj.Id,
    IntervalDays: obj.IntervalDays,
    Percentage: obj.Percentage,
    Sequence: obj.Sequence,
    isLockGuest: obj.isLockGuest,
    Description: obj.Description,
    IsAfterBookingBased: obj.IsAfterBookingBased,
    PaymentSchemeId: obj.PaymentSchemeId,
  };
  return contentPaymentTerm;
};

export { setContentPaymentTerm };
