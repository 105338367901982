import apiClient from '../apiClient';
import { GET_NOT_ALLOWED_COUNTRY_FOR_COMPANY_BY_COMPANY_CODE_URL } from '../apiUrl';

const getAllowedForCompanyByCompanyCodeApi = Id => {
  return apiClient.get(
    GET_NOT_ALLOWED_COUNTRY_FOR_COMPANY_BY_COMPANY_CODE_URL(Id)
  );
};

export default getAllowedForCompanyByCompanyCodeApi;
