import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Container, Form } from 'semantic-ui-react';
import InputWithLabel from '../../../../components/input/inputWithLabel';
// const gender = [
//   { key: 'm', text: 'Male', value: 'male' },
//   { key: 'f', text: 'Female', value: 'female' },
// ];

const GeneralInformationGuide = props => {
  return (
    <React.Fragment>
      <Container as="h3" content="General Information" />
      <Grid columns={2} padded="vertically">
        <Grid.Column>
          <Form>
            <InputWithLabel
              inline={false}
              label="First Name"
              type="text"
              required
              name="FirstName"
              value={props.tourGuideObject.FirstName}
              placeholder="First Name"
              handleChange={props.handleChange}
              validate={props.errorFormTourGuide}
              disabled={props.disabled}
            />

            <InputWithLabel
              inline={false}
              label="Started Job"
              type="date"
              required
              name="StartedJobAt"
              value={props.tourGuideObject.StartedJobAt}
              placeholder="Started Job"
              handleChange={props.handleChange}
              validate={props.errorFormTourGuide}
              disabled={props.disabled}
            />

            {/* <Form>
            <InputWithLabel
              inline={false}
              label="Gender"
              type="select"
              required
              name="Gender"
              value={props.tourGuideObject.NationalityId}
              placeholder="Gender"
              handleChange={props.handleChange}
              options={gender}
              validate={props.errorFormTourGuide}
              disabled={props.disabled}
            />
          </Form> */}

            <InputWithLabel
              inline={false}
              label="Email"
              type="text"
              required
              name="EmailAddress"
              value={props.tourGuideObject.EmailAddress}
              placeholder="Email"
              handleChange={props.handleChange}
              validate={props.errorFormTourGuide}
              disabled={props.disabled}
            />
          </Form>
        </Grid.Column>
        <Grid.Column>
          <Form>
            <InputWithLabel
              inline={false}
              label="Last Name"
              type="text"
              required
              name="LastName"
              value={props.tourGuideObject.LastName}
              placeholder="Last Name"
              handleChange={props.handleChange}
              validate={props.errorFormTourGuide}
              disabled={props.disabled}
            />

            <InputWithLabel
              inline={false}
              label="Identity Number"
              type="number"
              required
              name="IdentityNbr"
              value={props.tourGuideObject.IdentityNbr}
              placeholder="Identity Number"
              handleChange={props.handleChange}
              validate={props.errorFormTourGuide}
              disabled={props.disabled}
            />

            <InputWithLabel
              inline={false}
              label="Nationality"
              type="select"
              required
              name="NationalityId"
              value={props.tourGuideObject.NationalityId}
              placeholder="Nationality"
              handleChange={props.handleChange}
              options={props.listCountry}
              validate={props.errorFormTourGuide}
              disabled={props.disabled}
            />
          </Form>
        </Grid.Column>
      </Grid>
      {/* <Form>
        <InputWithLabel
          inline={false}
          label="Email"
          type="text"
          required
          name="EmailAddress"
          value={props.tourGuideObject.EmailAddress}
          placeholder="Email"
          handleChange={props.handleChange}
          validate={props.errorFormTourGuide}
          disabled={props.disabled}
        />
      </Form> */}
      {/* <Form>
        <InputWithLabel
          inline={false}
          label="Tour Guide Language"
          type="select"
          required
          name="TourGuideLanguage"
          value={props.tourGuideObject.TourGuideLanguage}
          placeholder="Tour Guide Language"
          handleChange={props.handleChange}
          options={props.listLanguage}
          // validate={props.errorInformation}
          disabled={props.disabled}
          multiple
          fluid
        />
      </Form> */}
      {/* <Form>
        <Form.Field>
          <InputWithLabel
            inline={false}
            label="Region Coverages"
            type="select"
            required
            name="RegionCoverages"
            value={props.tourGuideObject.RegionCoverages}
            placeholder="Region Coverages"
            handleChange={props.handleChange}
            options={props.listRegion}
            validate={props.errorFormTourGuide}
            disabled={props.disabled}
            multiple
            fluid
          />
        </Form.Field>
      </Form> */}
    </React.Fragment>
  );
};

GeneralInformationGuide.propTypes = {
  tourGuideObject: PropTypes.object,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
  listCountry: PropTypes.array,
  listRegion: PropTypes.array,
  listLanguage: PropTypes.array,
  errorFormTourGuide: PropTypes.object,
};
export default GeneralInformationGuide;
