import {
  RESET_CITY_PAGE,
  DELETE_CITY,
  GET_CITY_BY_ID,
  POST_CITY,
  PUT_CITY,
} from './constant/actionTypes';

import deleteCityApi from '../../api/city/deleteCityApi';
import getCityByIdApi from '../../api/city/getCityByIdApi';
import putCityApi from '../../api/city/putCityApi';
import postCityApi from '../../api/city/postCityApi';

const ResetCityPageAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_CITY_PAGE,
    });
  };
};

const DeleteCityAction = Id => {
  return dispatch => {
    return dispatch({
      type: DELETE_CITY,
      payload: deleteCityApi(Id),
    });
  };
};

const GetCityByIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_CITY_BY_ID,
      payload: getCityByIdApi(Id),
    });
  };
};

const PostCityAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_CITY,
      payload: postCityApi(data),
    });
  };
};

const PutCityAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_CITY,
      payload: putCityApi(id, data),
    });
  };
};
export {
  ResetCityPageAction,
  DeleteCityAction,
  GetCityByIdAction,
  PostCityAction,
  PutCityAction,
};
