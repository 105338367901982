import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Table,
  Button,
  Grid,
  Pagination,
  Segment,
} from 'semantic-ui-react';
// import styles from './../styles';
import ButtonClose from '../../../../../../components/button/buttonCloseModal';
import Input from '../../../../../../components/input/inputWithLabel';
import Loader from '../../../../../../components/loader/loaderInModal';

class ModalUsers extends Component {
  state = {
    searchUser: '',
    activePage: 1,
  };
  pagination = (data, perPage) => {
    let sets = [],
      chunks = data.length / perPage;
    for (let i = 0, j = 0; i < chunks; i++, j += perPage)
      sets[i] = data.slice(j, j + perPage);
    return sets;
  };
  handleChange = (e, { name, value }) =>
    this.setState({ ...this.state, [name]: value, activePage: 1 });
  pageChange = (e, { activePage }) => {
    this.setState({ ...this.state, activePage });
  };
  render() {
    let {
      isOpen,
      onClose,
      //   activePage,
      //   pageChange,
      userProfileByCompany,
      handleButtonPick,
      //   searchUser,
      //   handleChange,
      loading,
    } = this.props;
    let { searchUser, activePage } = this.state;
    let filterUsers = userProfileByCompany.filter(
      e =>
        searchUser
          ? e.Username.toLowerCase().indexOf(searchUser.toLowerCase()) !== -1
          : true
    );
    let pagedData = this.pagination(filterUsers, 10);
    let listUserProfileByCompany = pagedData[activePage - 1];
    return (
      <Modal open={isOpen} onClose={onClose} size="small" closeOnDimmerClick>
        <Modal.Header>
          Showing List Employee <ButtonClose onClose={onClose} />
        </Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column width={6}>
                <Input
                  type="text"
                  placeholder="Search user by username"
                  icon="search"
                  iconPosition="left"
                  inputStyles={{ width: '100%' }}
                  handleChange={this.handleChange}
                  value={searchUser}
                  name="searchUser"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Table basic="very" selectable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>No</Table.HeaderCell>
                      <Table.HeaderCell>Username</Table.HeaderCell>
                      <Table.HeaderCell>Address</Table.HeaderCell>
                      <Table.HeaderCell>Telephone</Table.HeaderCell>
                      <Table.HeaderCell>Email</Table.HeaderCell>
                      <Table.HeaderCell>Action</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {!loading &&
                      listUserProfileByCompany &&
                      listUserProfileByCompany.map((user, index) => (
                        <Table.Row key={index}>
                          <Table.Cell>{index + 1}</Table.Cell>
                          <Table.Cell>{user.Username}</Table.Cell>
                          <Table.Cell>{user.Address}</Table.Cell>
                          <Table.Cell>{user.Telephone}</Table.Cell>
                          <Table.Cell>{user.Email}</Table.Cell>
                          <Table.Cell>
                            <Button
                              primary
                              value={user}
                              onClick={handleButtonPick}
                              name="selectedUser"
                            >
                              Pick
                            </Button>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Column width={16}>
                {loading && (
                  <Segment
                    style={{
                      border: 'none',
                      background: 'transparent',
                      minHeight: '50px',
                    }}
                  >
                    <Loader loading={loading} />
                  </Segment>
                )}
              </Grid.Column>
              <Grid.Column
                textAlign="center"
                width={16}
                style={{ paddingTop: '10px' }}
              >
                {!loading &&
                  pagedData &&
                  pagedData.length > 1 && (
                    <Pagination
                      totalPages={pagedData.length}
                      onPageChange={this.pageChange}
                      activePage={activePage}
                    />
                  )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

ModalUsers.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  userProfileByCompany: PropTypes.array,
  loading: PropTypes.bool,
  handleButtonPick: PropTypes.func,
};

export default ModalUsers;
