import { timeDuration } from '../genericFunction/moment';

const checkCustomToReturnTimeLine = data => {
  let description = '';
  if (data.MovementName === 'DRIVING') {
    description =
      ', Durations ' +
      timeDuration(data.Duration) +
      (data.DestinationName ? ' at ' + data.DestinationName : '') +
      (data.Item.ServiceItemId
        ? ' With ' +
          data.Item.Name +
          ' - ' +
          data.Item.ServiceType.split('_').join(' ')
        : '');
    // ' to ' +
    // data.DestinationName;
  } else if (
    data.MovementName === 'ARRIVAL' ||
    data.MovementName === 'DEPARTURE'
  ) {
    description = ', ' + data.Item.Name;
  } else if (data.MovementName === 'CHECKIN') {
    description =
      ', ' +
      'at ' +
      data.Item.Name +
      // ' - ' +
      // data.Item.ProfileDesc +
      ', ' +
      data.DestinationName;
  } else if (data.MovementName === 'CHECKOUT') {
    description =
      ', ' +
      'at ' +
      data.Item.Name +
      // ' - ' +
      // data.Item.ProfileDesc +
      ', ' +
      data.DestinationName;
  } else if (data.MovementName === 'DAYEND') {
    description = ', at ' + data.Item.Name + ', ' + data.DestinationName;
  } else if (data.MovementName === 'DAYSTART') {
    description = ', at ' + data.Item.Name + ', ' + data.DestinationName;
  } else if (data.MovementName === 'RECREATION') {
    description =
      ', at ' + data.Item.Name + ', Durations ' + timeDuration(data.Duration);
  } else if (data.MovementName === 'EAT') {
    description =
      ', at ' +
      data.Item.Name +
      ', Durations ' +
      timeDuration(data.Duration) +
      (data.Note ? ', Note: ' + data.Note : '');
  } else if (
    data.MovementName === 'FREETIME' ||
    data.MovementName === 'FREETIMELOCKED'
  ) {
    description =
      ', at ' +
      data.DestinationName +
      ', Durations ' +
      timeDuration(data.Duration) +
      (data.Note ? '. Note: ' + data.Note : '');
  } else {
    description = ', at ' + data.Item.Name + ', ' + data.DestinationName;
  }
  return description;
};

const checkFixedToReturnTimeLine = data => {
  // let description = '';
  // if (data.MovementName === 'DRIVING') {
  //   description =
  //     ', Durations ' + data.DurationText + ' to ' + data.DestinationName;
  // } else if (data.MovementName === 'ARRIVAL') {
  //   description = ', ' + data.Item.Desc;
  // } else {
  //   description =
  //     ', at ' +
  //     data.Item.Name +
  //     ' - ' +
  //     data.Item.Desc +
  //     ', ' +
  //     data.DestinationName;
  // }
  // return description;
  let description = '';
  if (data.MovementName === 'DRIVING') {
    description =
      ', Durations ' +
      timeDuration(data.Duration) +
      (data.DestinationName ? ' at ' + data.DestinationName : '');
    // ' to ' +
    // data.DestinationName;
  } else if (
    data.MovementName === 'ARRIVAL' ||
    data.MovementName === 'DEPARTURE'
  ) {
    description = ', ' + data.Item.Name;
  } else if (data.MovementName === 'CHECKIN') {
    description =
      ', ' +
      'at ' +
      data.Item.Name +
      // ' - ' +
      // data.Item.ProfileDesc +
      ', ' +
      data.DestinationName;
  } else if (data.MovementName === 'CHECKOUT') {
    description =
      ', ' +
      'at ' +
      data.Item.Name +
      // ' - ' +
      // data.Item.ProfileDesc +
      ', ' +
      data.DestinationName;
  } else if (data.MovementName === 'DAYEND') {
    description = ', at ' + data.DestinationName + ', ' + data.DestinationName;
  } else if (data.MovementName === 'DAYSTART') {
    description = ', at ' + data.Item.Name + ', ' + data.DestinationName;
  } else if (data.MovementName === 'RECREATION') {
    description =
      ', at ' + data.Item.Name + ', Durations ' + timeDuration(data.Duration);
  } else if (data.MovementName === 'EAT') {
    description =
      ', at ' + data.Item.Name + ', Durations ' + timeDuration(data.Duration);
  } else if (
    data.MovementName === 'FREETIME' ||
    data.MovementName === 'FREETIMELOCKED'
  ) {
    description =
      ', at ' +
      data.DestinationName +
      ', Durations ' +
      timeDuration(data.Duration);
  } else {
    description =
      ', at ' + data.Item ? data.Item.Name + ', ' : '' + data.DestinationName;
    //description = 'test';
  }
  return description;
};

//========================= Button Activities ===================================
const dropdowns = [
  { value: 'EAT', title: 'Meal' },
  { value: 'RECREATION', title: 'Excursion' },
  { value: 'FREETIME', title: 'Free Time' },
];
const ListDestination = [
  { value: 'eat', text: 'Meal' },
  { value: 'recreation', text: 'Excursion' },
  { value: 'freetime', text: 'Free Time' },
];

export {
  checkCustomToReturnTimeLine,
  checkFixedToReturnTimeLine,
  dropdowns,
  ListDestination,
};
