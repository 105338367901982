import * as types from './constant/actionTypes';

const initialState = {
  loading: false,
  deleteMasterReferenceStatus: '',
  postMasterReferenceStatus: '',
  putMasterReferenceStatus: '',
  errors: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.POST_MASTER_REFERENCE:
      return { ...state };
    case types.POST_MASTER_REFERENCE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        postMasterReferenceStatus: 'failed',
      };
    case types.POST_MASTER_REFERENCE_PENDING:
      return { ...state, loading: true };
    case types.POST_MASTER_REFERENCE_FULFILLED:
      return {
        ...state,
        loading: false,
        postMasterReferenceStatus: 'success',
      };

    case types.PUT_MASTER_REFERENCE:
      return { ...state };
    case types.PUT_MASTER_REFERENCE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        putMasterReferenceStatus: 'failed',
      };
    case types.PUT_MASTER_REFERENCE_PENDING:
      return { ...state, loading: true };
    case types.PUT_MASTER_REFERENCE_FULFILLED:
      return {
        ...state,
        loading: false,
        putMasterReferenceStatus: 'success',
      };

    case types.DELETE_MASTER_REFERENCE:
      return { ...state };
    case types.DELETE_MASTER_REFERENCE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        deleteMasterReferenceStatus: 'failed',
      };
    case types.DELETE_MASTER_REFERENCE_PENDING:
      return { ...state, loading: true };
    case types.DELETE_MASTER_REFERENCE_FULFILLED:
      return {
        ...state,
        loading: false,
        deleteMasterReferenceStatus: 'success',
      };
    case types.RESET_MASTER_REFERENCE_PAGE:
      return {
        ...state,
        deleteMasterReferenceStatus: '',
        postMasterReferenceStatus: '',
        putMasterReferenceStatus: '',
      };
    default:
      return state;
  }
};

export default reducer;
