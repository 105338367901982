import React from 'react';
import PropTypes from 'prop-types';
import { Message, Grid, Button, Segment, Image } from 'semantic-ui-react';
import styleTourGuide from './styles';
import ModalTourGuide from '../../modal/modalTourGuide/ModalTourGuide';
import { connect } from 'react-redux';
import options from '../../../../../components/genericFunction/selectOptionConverter';
import tourGuideIcon from '../../../../../assets/Icons/tourguide.png';
import { getLanguageAll } from '../../../actions/languageAction';
import PopupTourGuide from './PopupTourGuide';

class TimeLineGuide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalGuideOpen: false,
    };
  }
  componentDidMount = () => {
    this.props.getLanguageAll();
  };
  openModalGuide = () => this.setState({ ...this.state, modalGuideOpen: true });
  closeModalGuide = () =>
    this.setState({ ...this.state, modalGuideOpen: false });

  getGuideQty = data => {
    return data.reduce((res, cur) => {
      res = res.Qty + cur.Qty;
      return res;
    });
  };

  getGuidesByCity = data => {
    return data.reduce((res, cur) => {
      if (res.length === 0) {
        res.push({ cityName: cur.CityName, cityId: cur.CityId, qty: cur.Qty });
      } else {
        if (!res.find(itm => itm.cityId === cur.CityId)) {
          res.push({
            cityName: cur.CityName,
            cityId: cur.CityId,
            qty: cur.Qty,
          });
        } else {
          res[res.length - 1].qty = res[res.length - 1].qty + cur.Qty;
        }
      }
      return res;
    }, []);
  };

  getGuidesByOrderedItem = data => {
    return data.reduce((res, cur) => {
      if (cur.ItemType === 'TourGuide') {
        if (res.length === 0) {
          res.push({
            cityName: cur.CityName,
            cityId: cur.CityId,
            qty: cur.Qty,
          });
        } else {
          if (!res.find(itm => itm.cityId === cur.CityId)) {
            res.push({
              cityName: cur.CityName,
              cityId: cur.CityId,
              qty: cur.Qty,
            });
          } else {
            let idx = res.findIndex(item => item.cityId === cur.CityId);
            res[idx].qty = res[idx].qty + cur.Qty;
          }
        }
      }
      return res;
    }, []);
  };

  render() {
    const {
      dailyData,
      language,
      guests,
      setDailyProgram,
      day,
      dailyProgram,
      isStatic,
      currentPackage,
      isPublished,
    } = this.props;
    const { modalGuideOpen } = this.state;
    let tourGuide = dailyData.TourGuides
      ? this.getGuidesByCity(dailyData.TourGuides)
      : dailyData.OrderedItems
      ? this.getGuidesByOrderedItem(dailyData.OrderedItems)
      : null;
    return (
      <div>
        <Segment basic style={styleTourGuide.noPaddingTop}>
          <Message positive style={styleTourGuide.tourGuideStyle}>
            <Grid>
              <Grid.Row
                // style={styles.marginDailyProgramDays}
                verticalAlign="middle"
              >
                <Grid.Column style={styleTourGuide.noPadding} width={2}>
                  <Image size="mini" centered src={tourGuideIcon} />
                </Grid.Column>
                <Grid.Column style={styleTourGuide.noPadding} width={10}>
                  {tourGuide && tourGuide.length > 0 ? (
                    <span style={styleTourGuide.colorBlack}>
                      Day {dailyData.Day} have{' '}
                      {tourGuide.map((guide, i) => (
                        <span key={`${guide.cityId}${dailyData.Day}`}>
                          {`${i !== 0 ? ' and ' : ''}`}
                          <strong> {`${guide.qty} `}</strong>
                          tour guide for {`${guide.cityName}`}
                        </span>
                      ))}
                    </span>
                  ) : (
                    <span
                      size="large"
                      basic
                      style={styleTourGuide.boxShadowNone}
                    >
                      No Tour Guide selected in Day {dailyData.Day}
                    </span>
                  )}
                </Grid.Column>
                <Grid.Column
                  style={styleTourGuide.noPadding}
                  width={4}
                  textAlign="right"
                >
                  {typeof currentPackage !== 'undefined' &&
                  currentPackage.status === 'edit' &&
                  currentPackage.type !== 'Quotation' ? (
                    <PopupTourGuide dailyData={dailyData} />
                  ) : (
                    isStatic || (
                      <Button
                        circular
                        icon={
                          tourGuide && tourGuide.length > 0
                            ? 'edit outline'
                            : 'plus'
                        }
                        onClick={this.openModalGuide}
                        // style={styles.backgroundGold}
                        color="green"
                        disabled={isPublished}
                      />
                    )
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Message>
        </Segment>
        <ModalTourGuide
          open={modalGuideOpen}
          onClose={this.closeModalGuide}
          dailyData={dailyData}
          language={language}
          guests={guests}
          setDailyProgram={setDailyProgram}
          day={day}
          dailyProgram={dailyProgram}
          // setTourOperator={this.props.setTourOperator}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    language: state.LanguageStore.languageList.map(item => {
      return options(item.Id, item.Id, item.EnglishName);
    }),
  };
};
TimeLineGuide.propTypes = {
  dailyData: PropTypes.object,
  language: PropTypes.array,
  guests: PropTypes.object,
  setDailyProgram: PropTypes.func,
  dailyProgram: PropTypes.array,
  day: PropTypes.number,
  isStatic: PropTypes.bool,
  getLanguageAll: PropTypes.func,
  setTourOperator: PropTypes.func,
  currentPackage: PropTypes.object,
  isPublished: PropTypes.bool,
};
export default connect(
  mapStateToProps,
  {
    getLanguageAll,
  }
)(TimeLineGuide);
