import { useState, useEffect } from 'react';

const useApiCallDetail = (api, origin = '', dest = '', type = '') => {
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState(undefined);

  const fetchApi = (origin, dest, type) => {
    setIsLoading(true);
    api(origin, dest, type)
      .then(result => {
        let optionsData = result.data[0];
        setIsLoading(false);
        setFetchedData(optionsData);
        // console.log(optionsData);
      })
      .catch(err => {
        //eslint-disable-next-line
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (origin && dest && type) {
      fetchApi(origin, dest, type);
    }
    // eslint-disable-next-line
  }, []);

  const loadData = (origin = '', dest = '', type = '') => {
    if (origin && dest && type) {
      fetchApi(origin, dest, type);
    }
  };

  return [isLoading, fetchedData, setFetchedData, loadData];
};

export default useApiCallDetail;
