import { copyObject } from './dailyProgram';
import { convertDateFormat, SumSecond, getNumberOfSecond } from './timeHelper';
import { getTotalDays } from '../moment';
import { getCities } from '../../components/genericFunction/tourGuide';
const object = {
  Title: null,
  AdultPaxQty: null,
  ChildPaxQty: null,
  InfantPaxQty: null,
  RoomAllocation: {
    SharingRoomQty: null,
    SingleRoomQty: null,
    ExtraBedQty: null,
    ChildExtraBedQty: null,
    ChildSharingRoomQty: null,
    ChildSingleRoomQty: null,
    SharingBedQty: null,
    BabyCrib: null,
    NoBed: null,
  },
  FoC: {
    Qty: null,
    Description: '',
  },
  StartDate: null,
  EndDate: null,
  CityDestinationId: null,
  RegionDestinationId: null,
  TourCategoryId: null,
  TourPaxTypeId: null,
  GroupType: null,
  Attractions: null,
  Transportations: null, //this.getTransport() ? this.getTransport() : [],
  Accommodations: null,
  Movements: null,
  Guests: null,
  Restaurants: null,
  TourNote: null,
  AdditionalItems: null,
  // TourGuides: null,
  AdditionalServices: null,
};

const objectPackageManagement = {
  Id: null,
  Title: null,
  RegionDestinationId: null,
  CityDestinationId: null,
  PackageType: null,
  TourCategoryId: null,
  TourPaxTypeId: 3,
  StartDate: null,
  EndDate: null,
  ExpiredDate: null,
  GracePeriod: null,
  MinimumGuest: 1,
  MaximumGuest: 100,
  Description: null,
  AdultPaxQty: 1,
  ChildPaxQty: 0,
  InfantPaxQty: 0,
  ImageName: '',
  ImageContent: '',
  TourOperatorId: null,
  IsTourOperatorFixedPackage: false,
  Prices: {
    SharingRoomPrice: 0,
    SingleRoomPrice: 0,
    AdultExtraBedPrice: 0,
    ChildExtraBedPrice: 0,
    NoBedPrice: 0,
    SharingBedPrice: 0,
    SytemMargin: 0,
    TourOperatorMargin: 0,
    CurrencyId: 'IDR',
  },
  Attractions: null,
  Transportations: null,
  Accommodations: null,
  Movements: null,
  BookingTemplateDescriptions: null,
  BookingTemplateImages: null,
  Restaurants: null,
  BookingTemplateSharedPaxId: null,
  //----------- Variable Date ------------//
  BookingTemplatePeriodDates: null,
  IsAllotment: null,
  //----------- Variable Date ------------//
};
const accommodation = {
  AccommodationItemServiceType: null,
  DateTime: null,
  EndTime: null,
  ServiceItemId: null,
  SharingRoomQty: null,
  SingleRoomQty: null,
  ExtraBedQty: null,
  ChildExtraBedQty: null,
  ChildSharingRoomQty: null,
  ChildSingleRoomQty: null,
  SharingBedQty: null,
  BabyCrib: null,
  NoBed: null,
};
const objMovement = {
  DateTime: null,
  ServiceItemId: null,
  CityId: null,
  Duration: null,
  SeqNumber: null,
  ServiceItemRefId: null,
  PlaceId: null,
  Note: '',
  PartOfAnotherDate: null,
};

const objectAccomodationPackageManagement = {
  Day: null,
  TotalDays: null,
  Date: null,
  Destination: null,
  LeavingDate: null,
  ServiceItemId: null,
  Accommodation: null,
  AccommodationItem: null,
  ServiceType: null,
  City: null,
  Region: null,
};

const objectSetAccommodationToMainProgram = {
  Date: null,
  Day: null,
  LeavingDate: null,
  Note: null,
  Region: null,
  TotalDays: null,
  City: null,
  AccommodationSummary: null,
};

const objectAccomodationSummary = {
  AccommodationProfileId: null,
  Address: null,
  Allocations: null,
  DateTime: null,
  CheckIn: null,
  CheckOut: null,
  Name: null,
  RoomName: null,
  RoomService: null,
  RoomType: null,
  SeqNumber: null,
  ServiceItemId: null,
};

const objectSeriesPackage = {
  Id: null,
  Title: null,
  RegionDestinationId: null,
  CityDestinationId: null,
  PackageType: null,
  TourCategoryId: null,
  TourPaxTypeId: 3,
  StartDate: null,
  EndDate: null,
  ExpiredDate: null,
  GracePeriod: null,
  MinimumGuest: null,
  MaximumGuest: null,
  Description: null,
  ImageName: '',
  ImageContent: '',
  TourOperatorId: null,
  IsTourOperatorFixedPackage: false,
  Prices: {
    SharingRoomPrice: 0,
    SingleRoomPrice: 0,
    AdultExtraBedPrice: 0,
    ChildExtraBedPrice: 0,
    NoBedPrice: 0,
    SharingBedPrice: 0,
    SytemMargin: 0,
    TourOperatorMargin: 0,
    CurrencyId: 'IDR',
  },
  Attractions: null,
  Transportations: null,
  Accommodations: null,
  Movements: null,
  BookingTemplateDescriptions: null,
  BookingTemplateImages: null,
  Restaurants: null,
  BookingTemplateSharedPaxId: null,
  AgentCommission: null,
  ReferenceId: 0,
  MinPax: 0,
  PaymentTerms: null,
  StaffCommission: null,
  Brochures: null,
};
const setPax = (obj, guests) => {
  obj.AdultPaxQty = guests.adults;
  obj.ChildPaxQty = guests.childs;
  obj.InfantPaxQty = guests.infants;
  return obj;
};
const setRoomAllocation = (obj, rooms) => {
  obj.RoomAllocation = rooms;
  return obj;
};
const setGeneralInfo = (
  obj,
  tourName,
  groupCapacity,
  firstDestination,
  tourType,
  tourCategory,
  tourNote
) => {
  obj.Title = tourName;
  obj.CityDestinationId = firstDestination;
  obj.TourCategoryId = tourType;
  obj.TourPaxTypeId = tourCategory;
  obj.GroupType = groupCapacity;
  obj.TourNote = tourNote;
  return obj;
};

const setGeneralInfoPackageManagement = (
  obj,
  tourName,
  firstDestination,
  tourType,
  packageType,
  description,
  expiredDate,
  graceDate
) => {
  obj.Title = tourName;
  obj.CityDestinationId = firstDestination;
  obj.TourCategoryId = tourType;
  obj.PackageType = packageType;
  obj.Description = description;
  obj.ExpiredDate = expiredDate;
  obj.GracePeriod = graceDate;
  return obj;
};

const setGeneralInfoSeriesPackage = (obj, packageType, object) => {
  obj.Title = object.tourName;
  obj.CityDestinationId = object.destination;
  obj.TourCategoryId = object.tourType;
  obj.PackageType = packageType;
  obj.Description = object.description;
  obj.ExpiredDate = object.expiredDate;
  obj.GracePeriod = object.graceDate;
  obj.ReferenceId = object.referenceId;
  obj.MinPax = object.minPax;
  obj.MinimumGuest = object.minGuest;
  obj.MaximumGuest = object.maxGuest;
  return obj;
};

const setStartEndTime = (obj, start, end) => {
  obj.StartDate = start;
  obj.EndDate = end;
  return obj;
};
const setAccommodation = (obj, summaryProgram) => {
  let array = summaryProgram.reduce((result, current) => {
    // eslint-disable-next-line
    if (current.hasOwnProperty('AccommodationSummary')) {
      let room = current.AccommodationSummary.Allocations;
      let newObj = copyObject(accommodation);
      newObj.AccommodationItemServiceType =
        current.AccommodationSummary.RoomService;
      newObj.DateTime = current.AccommodationSummary.CheckIn;
      newObj.EndTime = current.AccommodationSummary.CheckOut;
      newObj.ServiceItemId = current.AccommodationSummary.ServiceItemId;
      newObj.SharingRoomQty = room.SharingRoomPax;
      newObj.SingleRoomQty = room.SingleRoomPax;
      newObj.ExtraBedQty = room.ExtraBedPax;
      newObj.ChildExtraBedQty = room.ChildExtraBedPax;
      newObj.ChildSharingRoomQty = room.ChildSharingRoomPax;
      newObj.ChildSingleRoomQty = room.ChildSingleRoomPax;
      newObj.SharingBedQty = room.SharingBedPax;
      newObj.BabyCrib = room.BabyCrib;
      newObj.NoBed = room.NoBedPax ? room.NoBedPax : 0;
      result = result.concat(newObj);
    }
    return result;
  }, []);
  obj.RegionDestinationId = summaryProgram[0].Region;
  obj.Accommodations = array;
  return obj;
};
const setAttraction = (obj, dailyProgram) => {
  let array = dailyProgram.reduce((result, current) => {
    let items = current.Movements.reduce((move, currentMove) => {
      if (currentMove.MovementName === 'RECREATION') {
        const objAttr = {
          ServiceItemId: currentMove.Item.ServiceItemId,
          DateTime: currentMove.DateTime,
          Duration: currentMove.Duration,
          CityId: currentMove.Destination,
        };
        move = move.concat(objAttr);
      }
      return move;
    }, []);
    result = result.concat(items);
    return result;
  }, []);
  obj.Attractions = array;
  return obj;
};
const setMovements = (obj, dailyProgram) => {
  let array = dailyProgram.reduce((result, current) => {
    let items = current.Movements.reduce((move, currentMove, idx) => {
      let item = copyObject(objMovement);
      item.DateTime = currentMove.DateTime;
      item.ServiceItemId = currentMove.ServiceItemId
        ? currentMove.ServiceItemId
        : null;
      item.CityId = currentMove.Destination;
      item.Duration = currentMove.Duration;
      // item.SeqNumber = currentMove.SeqNumber;
      item.SeqNumber = idx + 1;
      item.ServiceItemRefId = currentMove.Item.ServiceItemId
        ? currentMove.Item.ServiceItemId
        : null;
      item.PlaceId = currentMove.Item.PlaceId;
      item.Note = currentMove.Note;
      item.Item = currentMove.Item;
      item.PartOfAnotherDate =
        convertDateFormat(current.Date, 'YYYY-MM-DD') !==
        convertDateFormat(currentMove.DateTime, 'YYYY-MM-DD')
          ? current.Date
          : null;
      move = move.concat(item);

      return move;
    }, []);
    result = result.concat(items);
    return result;
  }, []);
  obj.Movements = array;
  return obj;
};
const setRestaurant = (obj, dailyProgram) => {
  let array = dailyProgram.reduce((result, current) => {
    let items = current.Movements.reduce((move, currentMove) => {
      if (currentMove.MovementName === 'EAT') {
        const item = {
          DateTime: currentMove.DateTime,
          ServiceItemId: currentMove.Item.ServiceItemId,
          CityId: currentMove.Destination,
          Duration: currentMove.Duration,
        };
        move = move.concat(item);
      }
      return move;
    }, []);
    result = result.concat(items);
    return result;
  }, []);
  obj.Restaurants = array;
  return obj;
};
const setGuestList = (obj, guestList) => {
  let arrays = guestList.reduce((result, current) => {
    if (
      current.CountryId !== '' &&
      current.FirstName !== '' &&
      current.IdentityNbr !== '' &&
      current.IdentityType !== '' &&
      current.LastName !== '' &&
      current.GuestTitle !== ''
    ) {
      result = result.concat(current);
    }
    return result;
  }, []);
  obj.Guests = arrays;
  return obj;
};
const getDriving = dailyProgram => {
  let array = dailyProgram.reduce((result, current) => {
    let items = current.Movements.reduce((move, currentMove) => {
      if (currentMove.MovementName === 'DRIVING') {
        move = move.concat(currentMove);
      }
      return move;
    }, []);
    result = result.concat(items);
    return result;
  }, []);
  return array;
};
const setTransport = dailyProgram => {
  let drivings = getDriving(dailyProgram);
  let result = drivings.reduce((trans, car) => {
    if (car.Item.ServiceItemId) {
      let idx = trans.findIndex(
        itm =>
          itm.ServiceItemId === car.Item.ServiceItemId &&
          itm.CityId === car.Destination &&
          convertDateFormat(itm.DateTime, 'YYYY-MM-DD') ===
            convertDateFormat(car.DateTime, 'YYYY-MM-DD')
      );
      if (trans.length === 0 || idx === -1) {
        let objT = {
          CityId: car.Destination,
          DateTime: car.DateTime,
          EndTime: SumSecond(car.DateTime, car.Duration),
          Hours: car.Item.Hours,
          SeqNumber: car.SeqNumber,
          ServiceItemId: car.Item.ServiceItemId,
          TransportationItemServiceType: car.Item.ServiceType,
        };
        trans = trans.concat(objT);
      } else {
        trans[idx].SeqNumber = car.SeqNumber;
        trans[idx].EndTime = SumSecond(car.DateTime, car.Duration);
      }
    }
    return trans;
  }, []);
  return result;
};
const setCustomPrice = customPrices => {
  let copyCustomPrice = copyObject(customPrices);
  let Prices = customPrices.Prices.reduce((acc, e) => {
    if (e.IsDelete) {
      return acc;
    } else if (customPrices.IsTourOperatorFixedPackage) {
      delete e.Cost;
      delete e.IsAdd;
      delete e.IsEdit;
      delete e.IsDelete;
      acc.push(e);
    } else if (!customPrices.IsTourOperatorFixedPackage) {
      e = {
        ...e.Cost,
        MinimumPax: e.MinimumPax,
        CurrencyId: e.CurrencyId,
      };
      acc.push(e);
    }
    return acc;
  }, []);
  return {
    Prices,
    SystemMargin: copyCustomPrice.SystemMargin,
    TourOperatorMargin: copyCustomPrice.TourOperatorMargin,
  };
};
const getOvertimeTransport = transport => {
  let maxDuration = transport.Hours * 3600;
  let currentDuration = getNumberOfSecond(
    transport.DateTime,
    transport.EndTime
  );
  let overtime = 0;
  if (maxDuration < currentDuration) {
    overtime = Math.ceil((currentDuration - maxDuration) / 3600);
  }
  return overtime;
};
const setTourGuide = (obj, dailyProgram) => {
  let tourGuides = dailyProgram.reduce((result, current) => {
    if (current.TourGuides) {
      let cities = getCities(current);
      current.TourGuides = current.TourGuides.map(itm => {
        let city = cities.find(item => item.CityId === itm.CityId);
        if (city) {
          itm.DateTime = city.DateTime;
          itm.EndTime = city.EndTime;
        }
        itm.LanguageId = itm.Language.Id;
        return itm;
      });
      result = result.concat(current.TourGuides);
    }
    return result;
  }, []);
  obj.TourGuides = tourGuides;
  return obj;
};

const setAdditionalServices = (obj, additionalServices) => {
  let newAdditionalService = additionalServices.reduce((res, cur) => {
    let obj = {
      Amount: cur.Amount,
      CurrencyId: cur.Currency,
      AdditionalServiceCountryId: cur.OriginalCountryId,
      IsMandatory: cur.IsMandatory,
      Quantity: cur.Quantity,
      IsInfantCount: cur.IsInfantCount,
    };
    res = res.concat(obj);
    return res;
  }, []);
  obj.AdditionalServices = newAdditionalService;
  return obj;
};

const setSpecialAdjusments = (obj, specialAdjusments) => {
  let specialAdj = specialAdjusments.map(item => {
    return {
      ...item,
      UnitCost: parseFloat(item.UnitCost),
      // AdditionalItemTypeId: item.AdditionalItemType,
    };
  });
  specialAdj = specialAdj.filter(item => !item.IsDelete);
  obj.AdditionalItems = specialAdj;
  return obj;
};
const generateDemoPrice = (
  guests,
  rooms,
  tourName,
  groupCapacity,
  firstDestination,
  tourType,
  tourCategory,
  headLine,
  dailyProgram,
  guestList,
  foc,
  tourNote,
  additionalServices,
  specialAdjusments
) => {
  let demo = copyObject(object);
  demo = setPax(demo, guests);
  demo = setRoomAllocation(demo, rooms);
  demo = setFoc(demo, foc);
  demo = setGeneralInfo(
    demo,
    tourName,
    groupCapacity,
    firstDestination,
    tourType,
    tourCategory,
    tourNote
  );
  let start = headLine.Departures[0].Date;
  let end = headLine.Returns[headLine.Returns.length - 1].Date;
  let summaryProgram = headLine.MainPrograms;
  demo = setStartEndTime(demo, start, end);
  demo = setAccommodation(demo, summaryProgram);
  demo = setAttraction(demo, dailyProgram);
  demo = setMovements(demo, dailyProgram);
  demo = setRestaurant(demo, dailyProgram);
  demo = setGuestList(demo, guestList);
  demo = setTourGuide(demo, dailyProgram);
  demo.Transportations = setTransport(dailyProgram);
  demo = setAdditionalServices(demo, additionalServices);
  demo = setSpecialAdjusments(demo, specialAdjusments);
  return demo;
};

const generatePackageManagement = (
  id,
  tourName,
  firstDestination,
  packageType,
  tourType,
  headLine,
  dailyProgram,
  images,
  description,
  additionalInfo,
  expiredDate,
  periodeDates,
  price,
  isRoomAllotmetNeeded,
  activeDate,
  minPax,
  // availableDay
  // eslint-disable-next-line
  tourOperator,
  additionalServices,
  customPrices
) => {
  let demo = copyObject(objectPackageManagement);
  demo = setGeneralInfoPackageManagement(
    demo,
    tourName,
    firstDestination,
    tourType,
    packageType,
    description,
    expiredDate
  );
  let start = headLine.Departures[0].Date;
  let end = headLine.Returns[headLine.Returns.length - 1].Date;
  let summaryProgram = headLine.MainPrograms;
  demo = setStartEndTime(demo, start, end);
  demo = setAccommodation(demo, summaryProgram);
  demo = setAttraction(demo, dailyProgram);
  demo = setMovements(demo, dailyProgram);
  demo = setRestaurant(demo, dailyProgram);
  demo.Transportations = setTransport(dailyProgram);
  demo.BookingTemplateImages = images;
  demo.BookingTemplateDescriptions = additionalInfo;
  demo.Id = id;
  // ready fix price
  demo.Prices = packageType === 'FixedDateVariable' ? price : demo.Prices;
  demo.CustomPrices =
    packageType === 'FixedDateVariable'
      ? setCustomPrice(customPrices)
      : {
          Prices: [],
          SystemMargin: 0,
          TourOperatorMargin: 0,
        };

  demo.IsTourOperatorFixedPackage = customPrices.IsTourOperatorFixedPackage;
  demo.BookingTemplatePeriodDates =
    packageType === 'FixedDateVariable' ? periodeDates : null;
  demo.ActiveDate = packageType === 'FixedDateVariable' ? activeDate : null;
  demo.IsAllotment = isRoomAllotmetNeeded;
  demo.BookingTemplatePeriodDates =
    packageType === 'FixedDateVariable'
      ? setAllowedDays(periodeDates, id)
      : null;
  demo.MinPax = packageType === 'FixedDateVariable' ? minPax : null;
  //end
  demo = setAdditionalServices(demo, additionalServices);
  return demo;
};

const setAccommodationPackageManagement = summaryProgram => {
  let array = summaryProgram.reduce((result, current) => {
    let obj = copyObject(objectAccomodationPackageManagement);
    // eslint-disable-next-line
    if (current.hasOwnProperty('AccommodationSummary')) {
      obj.Day = current.Day;
      obj.TotalDays = current.TotalDays;
      obj.Date = current.Date;
      obj.Destination = current.City;
      obj.LeavingDate = current.LeavingDate;
      obj.ServiceItemId = current.AccommodationSummary.ServiceItemId;
      obj.Accommodation = {
        Id: current.AccommodationSummary.AccommodationProfileId,
        Name: current.AccommodationSummary.Name,
      };
      obj.AccommodationItem = {
        Id: current.AccommodationSummary.ServiceItemId,
        Name: current.AccommodationSummary.RoomType,
      };
      obj.ServiceType = {
        Id: current.AccommodationSummary.RoomService,
        Name: current.AccommodationSummary.RoomService,
      };
      obj.City = current.City;
      obj.Region = current.Region;
      result = result.concat(obj);
    }
    return result;
  }, []);
  return array;
};

const setAccommodationPackageManagementToMainProgram = accomodationObject => {
  let demo = copyObject(objectSetAccommodationToMainProgram);
  demo.AccommodationSummary = setAccomodationSummary(
    objectAccomodationSummary,
    accomodationObject
  );
  demo.Date = accomodationObject.Date;
  demo.LeavingDate = accomodationObject.LeavingDate;
  demo.Region = accomodationObject.Region;
  demo.City = accomodationObject.Destination;
  demo.Region = accomodationObject.RegionId;
  demo.TotalDays = getTotalDays(
    accomodationObject.Date,
    accomodationObject.LeavingDate,
    true
  );
  return demo;
};

const setAccomodationSummary = (obj, accomodation) => {
  obj.AccommodationProfileId = accomodation.Accommodation.Id;
  obj.Address = null;
  obj.Allocations = {
    SharingRoomQty: 0,
    SingleRoomQty: 0,
    ExtraBedQty: 0,
    ChildExtraBedQty: 0,
    ChildSharingRoomQty: 0,
    ChildSingleRoomQty: 0,
    SharingBedQty: 0,
    BabyCrib: 0,
    NoBed: 0,
  };
  obj.DateTime = accomodation.Date;
  obj.Name = accomodation.Accommodation.Name;
  obj.RoomName = accomodation.AccommodationItem.Name;
  obj.RoomService = accomodation.ServiceType.Id;
  obj.RoomType = accomodation.AccommodationItem.Name;
  obj.SeqNumber = accommodation.ServiceItemId;
  obj.ServiceItemId = accommodation.ServiceItemId;
  obj.CheckIn = accomodation.Date;
  obj.CheckOut = accomodation.LeavingDate;
  return obj;
};

const generateSeriesPackage = (
  id,
  generalInformation,
  packageType,
  headLine,
  dailyProgram,
  images,
  additionalInfo,
  tourOperator,
  commissions,
  paymentTerms,
  price,
  tourBrochure,
  listAdditionalItem,
  periodeDates
) => {
  let demo = copyObject(objectSeriesPackage);
  demo = setGeneralInfoSeriesPackage(demo, packageType, generalInformation);
  // let start = headLine.Departures[0].Date;
  // let end = headLine.Returns[0].Date;
  let start = dailyProgram[0].Movements[0].DateTime;
  let lastDayMove = dailyProgram[dailyProgram.length - 1].Movements;
  let end =
    lastDayMove.length > 0
      ? lastDayMove[lastDayMove.length - 1].DateTime
      : dailyProgram[dailyProgram.length - 1].Date;
  let summaryProgram = headLine.MainPrograms;
  demo = setStartEndTime(demo, start, end);
  demo = setAccommodation(demo, summaryProgram);
  demo = setAttraction(demo, dailyProgram);
  demo = setMovements(demo, dailyProgram);
  demo = setRestaurant(demo, dailyProgram);
  demo.Transportations = setTransport(dailyProgram);
  demo.AgentCommission =
    packageType === 'FixedDateVariable'
      ? null
      : commissions.length > 0
      ? commissions[0]
      : null;
  demo.StaffCommission =
    packageType === 'FixedDateVariable'
      ? null
      : commissions.length > 0
      ? commissions[1]
      : null;
  demo.BookingTemplateImages = images;
  demo.BookingTemplateDescriptions = additionalInfo;
  demo.Id = id;
  demo.BookingTemplateSharedPaxId =
    generalInformation.BookingTemplateSharedPaxId ||
    generalInformation.BookingTemplateSharedPaxId !== 0
      ? generalInformation.BookingTemplateSharedPaxId
      : null;
  demo.TourOperatorId = tourOperator.TourOperator;
  demo.PaymentTerms = paymentTerms;
  demo.Prices = price;
  demo.Prices.CurrencyId = generalInformation.currency;
  demo.Prices.SystemMargin = tourOperator.BaseMarginPercentage;
  demo.Prices.TourOperatorMargin = tourOperator.TourOperatorMarginPercentage;
  // demo.TourGuides = [];
  demo.Brochures = tourBrochure.length > 0 ? tourBrochure[0] : null;
  demo.BookingTemplateSupplements = setSupplement(
    listAdditionalItem,
    generalInformation.currency
  );
  demo.IsTourOperatorFixedPackage = tourOperator.IsTourOperatorFixedPackage;
  demo.BookingTemplatePeriodDates = periodeDates;
  demo.IsAllotment = generalInformation.isRoomAllotmetNeeded;
  demo.IsFeatured = generalInformation.isFeatured;
  return demo;
};
const setSupplement = (listAdditionalItem, currency) => {
  return listAdditionalItem.map(e => {
    return {
      ...e,
      CurrencyId: currency,
      UnitCost: e.IsDeduction ? -Math.abs(e.UnitCost) : e.UnitCost,
    };
  });
};
const setFoc = (obj, foc) => {
  obj.FoC = foc && foc.Qty ? foc : null;
  return obj;
};

const filterDataPostSeries = (dataPost, selectedObj) => {
  let dataPostTemp = {};
  selectedObj.map(e => {
    if (e === 'IsFeatured') {
      dataPostTemp = { ...dataPostTemp, [e]: dataPost[e] };
    } else {
      dataPostTemp = dataPost[e]
        ? { ...dataPostTemp, [e]: dataPost[e] }
        : dataPostTemp;
    }
    return e;
  });
  return dataPostTemp;
};

const setAllowedDays = data => {
  let coba = [];
  data.map(e => {
    return coba.push({
      StartDate: e.StartDate,
      EndDate: e.EndDate,
      AllowedDays: e.AllowedDays.map(x => {
        //eslint-disable-next-line
        return e.hasOwnProperty('AllowedDates') ? { Day: Number(x.Value) } : x;
      }),
    });
  });

  return coba;
};

export {
  generateDemoPrice,
  getOvertimeTransport,
  setTransport,
  generatePackageManagement,
  setAccommodationPackageManagement,
  setAccommodationPackageManagementToMainProgram,
  generateSeriesPackage,
  filterDataPostSeries,
};
