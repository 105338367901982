import { POST_SET_PRIMARY_IMAGE } from '../../../actions/actionTypes';

import {
  GET_TRANSPORTATION_BY_PROFILE_ID,
  POST_UPLOAD_IMAGE_TRANSPORTATION_PROFILE,
  GET_TEMPLATE_TRANSPORTATION_PROFILE_DATA,
} from '../../transportation/transportationProfile/constant/actionTypes';

import {
  GET_TRANSPORTATION_LIST,
  RESET_STATUS_TRANSPORTATION,
} from '../../transportation/transportationList/constant/actionTypes';

import {
  POST_UPLOAD_IMAGE_TRANSPORTATION_UNIT,
  GET_TRANSPORTATION_UNIT_HEADER_BY_ID,
  GET_TRANSPORTATION_UNIT_ALL,
  GET_TRANSPORTATION_UNIT_BY_SERVICE_ITEM_ID,
  GET_TEMPLATE_TRANSPORTATION_UNIT_DATA,
  GET_TRANSPORTATION_BY_FROM_TO_DATE,
  GET_TRANSPORTATION_ESTIMATE_PRICE,
} from '../../transportation/transportationProfile/transportationUnit/constant/actionTypes';

import getTransportationListApi from '../../../api/getTransaportationApi';
import getTransportationProfileIdApi from '../../../api/transportationApi/getTransportationProfileIdApi';
import postUploadImageTransportationProfileApi from '../../../api/transportationApi/postUploadImageTransportationProfileApi';
import getTransportationUnitHeaderByIdApi from '../../../api/transportationApi/getTransportationUnitHeaderByIdApi';
import postUploadImageTransportationUnitApi from '../../../api/transportationApi/postUploadImageTransportationUnitApi';
import getTransportationUnitAll from '../../../api/transportationApi/getTransportationUnitAll';
import getTransportationUnitByServiceItemIdApi from '../../../api/transportationApi/getTransportationUnitByServiceItemIdApi';
import postSetPrimaryImageTransportationProfileApi from '../../../api/transportationApi/postSetPrimaryImageTransportationProfileApi';
import postSetPrimaryImageTransportaionUnitHeaderApi from '../../../api/transportationApi/postSetPrimaryImageTransportaionUnitHeaderApi.';
import getTemplateTransportationUnitApi from '../../../api/transportationApi/getTemplateTransportationUnitApi';
import getTemplateTransportationProfileApi from '../../../api/transportationApi/getTemplateTransportationProfileApi';
import getTransportationByFromToDateApi from '../../../api/transportationApi/getTransportationByFromToDateApi';
import getTransportationUnitByFilterApi from '../../../api/transportationApi/getTransportationUnitByFilterApi';
import getTransportationEstimatePriceApi from '../../../api/transportationApi';

const getTransportationListAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSPORTATION_LIST,
      payload: getTransportationListApi(),
    });
  };
};

const resetTransportationAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_STATUS_TRANSPORTATION,
    });
  };
};

const getTransportationProfileIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSPORTATION_BY_PROFILE_ID,
      payload: getTransportationProfileIdApi(Id),
    });
  };
};

const postUploadImageTransportationProfileAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: POST_UPLOAD_IMAGE_TRANSPORTATION_PROFILE,
      payload: postUploadImageTransportationProfileApi(id, data),
    });
  };
};

const postUploadImageTransportationUnitAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: POST_UPLOAD_IMAGE_TRANSPORTATION_UNIT,
      payload: postUploadImageTransportationUnitApi(id, data),
    });
  };
};

const getTransportationUnitAllAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSPORTATION_UNIT_ALL,
      payload: getTransportationUnitAll(),
    });
  };
};

const getTransportationUnitByServiceItemIdAction = id => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSPORTATION_UNIT_BY_SERVICE_ITEM_ID,
      payload: getTransportationUnitByServiceItemIdApi(id),
    });
  };
};

const postSetPrimaryImageTransportaionUnitHeaderAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: POST_SET_PRIMARY_IMAGE,
      payload: postSetPrimaryImageTransportaionUnitHeaderApi(id, data),
    });
  };
};

const postSetPrimaryImageTransportationProfileAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: POST_SET_PRIMARY_IMAGE,
      payload: postSetPrimaryImageTransportationProfileApi(id, data),
    });
  };
};

const getTransportationUnitHeaderByIdAction = id => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSPORTATION_UNIT_HEADER_BY_ID,
      payload: getTransportationUnitHeaderByIdApi(id),
    });
  };
};

const getTemplateTransportationUnitAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_TEMPLATE_TRANSPORTATION_UNIT_DATA,
      payload: getTemplateTransportationUnitApi,
    });
  };
};

const getTemplateTransportationProfileAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_TEMPLATE_TRANSPORTATION_PROFILE_DATA,
      payload: getTemplateTransportationProfileApi,
    });
  };
};

const getTransportationyFromToDateAction = (from, to, date) => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSPORTATION_BY_FROM_TO_DATE,
      payload: getTransportationByFromToDateApi(from, to, date),
    });
  };
};

const getTransportationUnitByFilterAction = data => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSPORTATION_BY_FROM_TO_DATE,
      payload: getTransportationUnitByFilterApi(data),
    });
  };
};
const getTransportationEstimatePrice = param => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSPORTATION_ESTIMATE_PRICE,
      payload: getTransportationEstimatePriceApi(param),
    });
  };
};
export {
  getTransportationListAction,
  resetTransportationAction,
  getTransportationProfileIdAction,
  postUploadImageTransportationProfileAction,
  getTransportationUnitHeaderByIdAction,
  postUploadImageTransportationUnitAction,
  getTransportationUnitAllAction,
  getTransportationUnitByServiceItemIdAction,
  postSetPrimaryImageTransportaionUnitHeaderAction,
  postSetPrimaryImageTransportationProfileAction,
  getTemplateTransportationUnitAction,
  getTemplateTransportationProfileAction,
  getTransportationyFromToDateAction,
  getTransportationUnitByFilterAction,
  getTransportationEstimatePrice,
};
