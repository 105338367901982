import {
  RESET_STATUS_PACKAGE,
  RESET_ACTIVITY_PACKAGE,
  GET_FIXED_PACKAGE,
  GET_PACKAGES,
  GET_ACTIVITY_LIST,
  GET_TOUR_OPERATOR_PROFILE,
  GET_TOUR_OPERATOR_PROFILE_BY_CODE,
  POST_PACKAGE,
  PUT_PACKAGE,
  GET_DURATION_ID_TO_ADDRESS,
  GET_DURATION_ID_TO_ADDRESS_FULFILLED,
  GET_DURATION_ID_TO_ADDRESS_REJECTED,
  GET_DURATION_ID_TO_ID_REJECTED,
  GET_DURATION_ID_TO_ID_FULFILLED,
  GET_DURATION_ID_TO_ID,
  GET_DURATION_ADDRESS_TO_ID,
  GET_DURATION_ADDRESS_TO_ID_FULFILLED,
  GET_DURATION_ADDRESS_TO_ID_REJECTED,
  GET_DURATION_ADDRESS_TO_ADDRESS,
  GET_DURATION_ADDRESS_TO_ADDRESS_FULFILLED,
  GET_DURATION_ADDRESS_TO_ADDRESS_REJECTED,
  POST_PUBLISH_PACKAGE,
  DELETE_PACKAGE,
  COPY_PACKAGE,
  UPLOAD_BROCHURE_PACKAGE,
  RESET_STATUS_GET_TOUR_OPERATOR_BY_CODE,
  GET_TOUR_OPERATOR_PROFILE_DETAIL_BY_ID,
  DELETE_TOUR_OPERATOR,
  RESET_STATUS_DELETE_TOUR_OPERATOR,
  PUT_TOUR_OPERATOR,
  POST_IMAGE_TOUR_OPERATOR,
  DELETE_IMAGE_TOUR_OPERATOR,
  POST_TOUR_OPERATOR,
  GET_ALL_SHARED_PACKAGE,
  GET_FILTER_SHARED_PACKAGE,
  GET_PACKAGES_VARIABLE_DATE,
  PUT_ADDITIONAL_INFO_PACKAGE_OPERATION,
  GET_DOWNLOAD_SCHEDULE_BY_BOOKING_TEMPLATE,
  // GET_SHARED_PACKAGE_BY_ID,
} from './actionTypes';
import {
  postPublishPackageApi,
  getDurationAddresstoIdApi,
  getDurationIdtoIdApi,
  getDurationIdtoAddressApi,
  getDurationAddresstoAddressApi,
  putPackageApi,
  postPackageApi,
  getTourOperatorProfileApi,
  getActivityListApi,
  getPackageApi,
  getPackagesApi,
  deletePackageApi,
  copyPackageApi,
  uploadBrochurePackageApi,
  putGeneralInfoPackageApi,
  putAllowedPaxPackageApi,
  getTourOperatorProfileByCodeApi,
  getTourOperatorProfileDetailByIdApi,
  deleteTourOperatorApi,
  putTourOperatorApi,
  getSeriesVariableDateApi,
  deleteBookingTemplateImagesApi,
  putAdditionalInfoOperationApi,
  getDownloadScheduleByBookingTemplateApi,
} from '../api/packageApi';
import postImageTourOperatorApi from '../api/partnerProfile/postImageTourOperatorApi';
import deleteImageTourOperatorApi from '../api/partnerProfile/deleteImageTourOperatorApi';
import postTourOperatorApi from '../api/partnerProfile/postTourOperatorApi';
import getAllSharedPackageApi from '../api/package/getAllSharedPackageApi';
import getFilterSharedpackageApi from '../api/package/getFilterSharedpackageApi';
// import getSharedPackageByIdApi from '../api/package/getSharedPackageByIdApi';

const resetPackageAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_STATUS_PACKAGE,
    });
  };
};

const resetActivityListAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_ACTIVITY_PACKAGE,
    });
  };
};
const getFixedPackage = (type, id) => {
  return dispatch => {
    return dispatch({
      type: GET_FIXED_PACKAGE,
      payload: getPackageApi(type, id),
    });
  };
};
const getFixedPackages = type => {
  return dispatch => {
    return dispatch({
      type: GET_PACKAGES,
      payload: getPackagesApi(type),
    });
  };
};
const getActivityListAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_ACTIVITY_LIST,
      payload: getActivityListApi(),
    });
  };
};
const getTourOperatorProfileAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_TOUR_OPERATOR_PROFILE,
      payload: getTourOperatorProfileApi(),
    });
  };
};
const getTourOperatorProfileByCodeAction = id => {
  return dispatch => {
    return dispatch({
      type: GET_TOUR_OPERATOR_PROFILE_BY_CODE,
      payload: getTourOperatorProfileByCodeApi(id),
    });
  };
};
const postPackageAction = (type, data) => {
  return dispatch => {
    return dispatch({
      type: POST_PACKAGE,
      payload: postPackageApi(type, data),
    });
  };
};
const putPackageAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_PACKAGE,
      payload: putPackageApi(id, data),
    });
  };
};
const putGeneralInfoPackageAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_PACKAGE,
      payload: putGeneralInfoPackageApi(id, data),
    });
  };
};
const putAllowedPaxPackageAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_PACKAGE,
      payload: putAllowedPaxPackageApi(id, data),
    });
  };
};
const getDurationIdtoAddress = (from, to, day, index) => {
  return dispatch => {
    return dispatch({
      type: GET_DURATION_ID_TO_ADDRESS,
      payload: getDurationIdtoAddressApi(from, to),
    })
      .then(response =>
        dispatch({
          type: GET_DURATION_ID_TO_ADDRESS_FULFILLED,
          payload: response.value.data,
          data: {
            from: from,
            to: to,
            day: day,
            index: index,
          },
        })
      )
      .catch(error => {
        dispatch({
          type: GET_DURATION_ID_TO_ADDRESS_REJECTED,
          payload: error,
          data: {
            from: from,
            to: to,
            day: day,
            index: index,
          },
        });
      });
  };
};
const getDurationIdtoId = (from, to, day, index) => {
  return dispatch => {
    return dispatch({
      type: GET_DURATION_ID_TO_ID,
      payload: getDurationIdtoIdApi(from, to),
    })
      .then(response =>
        dispatch({
          type: GET_DURATION_ID_TO_ID_FULFILLED,
          payload: response.value.data,
          data: {
            from: from,
            to: to,
            day: day,
            index: index,
          },
        })
      )
      .catch(error => {
        dispatch({
          type: GET_DURATION_ID_TO_ID_REJECTED,
          payload: error,
          data: {
            from: from,
            to: to,
            day: day,
            index: index,
          },
        });
      });
  };
};
const getDurationAddresstoId = (from, to, day, index) => {
  return dispatch => {
    return dispatch({
      type: GET_DURATION_ADDRESS_TO_ID,
      payload: getDurationAddresstoIdApi(from, to),
    })
      .then(response =>
        dispatch({
          type: GET_DURATION_ADDRESS_TO_ID_FULFILLED,
          payload: response.value.data,
          data: {
            from: from,
            to: to,
            day: day,
            index: index,
          },
        })
      )
      .catch(error => {
        dispatch({
          type: GET_DURATION_ADDRESS_TO_ID_REJECTED,
          payload: error,
          data: {
            from: from,
            to: to,
            day: day,
            index: index,
          },
        });
      });
  };
};
const getDurationAddresstoAddress = (from, to, day, index) => {
  return dispatch => {
    return dispatch({
      type: GET_DURATION_ADDRESS_TO_ADDRESS,
      payload: getDurationAddresstoAddressApi(from, to),
    })
      .then(response =>
        dispatch({
          type: GET_DURATION_ADDRESS_TO_ADDRESS_FULFILLED,
          payload: response.value.data,
          data: {
            from: from,
            to: to,
            day: day,
            index: index,
          },
        })
      )
      .catch(error => {
        dispatch({
          type: GET_DURATION_ADDRESS_TO_ADDRESS_REJECTED,
          payload: error,
          data: {
            from: from,
            to: to,
            day: day,
            index: index,
          },
        });
      });
  };
};
const postPublishPackageAction = (typePackage, typeAction, id) => {
  return dispatch => {
    return dispatch({
      type: POST_PUBLISH_PACKAGE,
      payload: postPublishPackageApi(typePackage, typeAction, id),
    });
  };
};
const deletePackageAction = id => {
  return dispatch => {
    return dispatch({
      type: DELETE_PACKAGE,
      payload: deletePackageApi(id),
    });
  };
};
const copyPackageAction = (type, id, startDate) => {
  return dispatch => {
    return dispatch({
      type: COPY_PACKAGE,
      payload: copyPackageApi(type, id, startDate),
    });
  };
};
const uploadBrochurePackageAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: UPLOAD_BROCHURE_PACKAGE,
      payload: uploadBrochurePackageApi(id, data),
    });
  };
};
const resetGetTourOperatorByCodeAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_STATUS_GET_TOUR_OPERATOR_BY_CODE,
    });
  };
};
const getTourOperatorProfileDetailByIdAction = id => {
  return dispatch => {
    return dispatch({
      type: GET_TOUR_OPERATOR_PROFILE_DETAIL_BY_ID,
      payload: getTourOperatorProfileDetailByIdApi(id),
    });
  };
};
const deleteTourOperatorAction = id => {
  return dispatch => {
    return dispatch({
      type: DELETE_TOUR_OPERATOR,
      payload: deleteTourOperatorApi(id),
    });
  };
};
const resetStatusDeleteTourOperatorAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_STATUS_DELETE_TOUR_OPERATOR,
    });
  };
};
const putTourOperatorAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_TOUR_OPERATOR,
      payload: putTourOperatorApi(id, data),
    });
  };
};

const postImageTourOperatorAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: POST_IMAGE_TOUR_OPERATOR,
      payload: postImageTourOperatorApi(id, data),
    });
  };
};

const deleteImageTourOperatorAction = id => {
  return dispatch => {
    return dispatch({
      type: DELETE_IMAGE_TOUR_OPERATOR,
      payload: deleteImageTourOperatorApi(id),
    });
  };
};

const postTourOperatorAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_TOUR_OPERATOR,
      payload: postTourOperatorApi(data),
    });
  };
};

const getAllSharedPackageAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_ALL_SHARED_PACKAGE,
      payload: getAllSharedPackageApi(),
    });
  };
};

const getFilterSharedpackageAction = (
  tourType,
  duration,
  starDate,
  destination
) => {
  return dispatch => {
    return dispatch({
      type: GET_FILTER_SHARED_PACKAGE,
      payload: getFilterSharedpackageApi(
        tourType,
        duration,
        starDate,
        destination
      ),
    });
  };
};

const getSeriesPackageVariableDateAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_PACKAGES_VARIABLE_DATE,
      payload: getSeriesVariableDateApi(),
    });
  };
};

const putAdditionalInfoOperation = data => {
  return dispatch => {
    return dispatch({
      type: PUT_ADDITIONAL_INFO_PACKAGE_OPERATION,
      payload: putAdditionalInfoOperationApi(data),
    });
  };
};
// const getSharedPackageByIdAction = Id => {
//   return dispatch => {
//     return dispatch({
//       type: GET_PACKAGES_VARIABLE_DATE,
//       payload: getSeriesVariableDateApi(),
//     });
//   };
// };

const deleteBookingTemplateImagesAction = id => {
  return dispatch => {
    return dispatch({
      // type: UPLOAD_BROCHURE_PACKAGE,
      payload: deleteBookingTemplateImagesApi(id),
    });
  };
};
// const deleteBookingTemplateImagesAction = id => {
//   return dispatch => {
//     return dispatch({
//       // type: UPLOAD_BROCHURE_PACKAGE,
//       payload: deleteBookingTemplateImagesApi(id),
//     });
//   };
// };
const getDownloadScheduleByBookingTemplate = templateId => {
  return dispatch => {
    return dispatch({
      type: GET_DOWNLOAD_SCHEDULE_BY_BOOKING_TEMPLATE,
      payload: getDownloadScheduleByBookingTemplateApi(templateId),
    });
  };
};
export {
  resetPackageAction,
  getFixedPackage,
  getFixedPackages,
  getActivityListAction,
  getTourOperatorProfileAction,
  resetActivityListAction,
  postPackageAction,
  putPackageAction,
  getDurationIdtoAddress,
  getDurationIdtoId,
  getDurationAddresstoId,
  getDurationAddresstoAddress,
  postPublishPackageAction,
  deletePackageAction,
  copyPackageAction,
  uploadBrochurePackageAction,
  putGeneralInfoPackageAction,
  putAllowedPaxPackageAction,
  getTourOperatorProfileByCodeAction,
  resetGetTourOperatorByCodeAction,
  getTourOperatorProfileDetailByIdAction,
  deleteTourOperatorAction,
  resetStatusDeleteTourOperatorAction,
  putTourOperatorAction,
  postImageTourOperatorAction,
  deleteImageTourOperatorAction,
  postTourOperatorAction,
  getAllSharedPackageAction,
  getFilterSharedpackageAction,
  getSeriesPackageVariableDateAction,
  // getSharedPackageByIdAction,
  // getSeriesPackageVariableDateAction,
  deleteBookingTemplateImagesAction,
  putAdditionalInfoOperation,
  getDownloadScheduleByBookingTemplate,
};
