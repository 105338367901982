import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header } from 'semantic-ui-react';
import { convertDateFormat } from '../../../components/genericFunction/moment';
const PaymentInformation = props => (
  <Grid divided="vertically" style={{ marginTop: '5px' }}>
    <Grid.Row columns={2}>
      <Grid.Column width={8}>
        <Grid divided="vertically">
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header content="Booking Code" as="h5" />
            </Grid.Column>
            <Grid.Column>
              <Header
                content={`: ${
                  props.contentDetail.PackageNo
                    ? props.contentDetail.PackageNo
                    : '-'
                }`}
                as="h5"
              />
            </Grid.Column>

            <Grid.Column>
              <Header content="Description" as="h5" />
            </Grid.Column>
            <Grid.Column>
              <Header
                content={`: ${
                  props.contentDetail.PackageName
                    ? props.contentDetail.PackageName
                    : '-'
                }`}
                as="h5"
              />
            </Grid.Column>

            <Grid.Column>
              <Header content="Tour Date" as="h5" />
            </Grid.Column>
            <Grid.Column>
              <Header
                content={`: ${
                  props.contentDetail.TourStartDate
                    ? `${props.contentDetail.TourStartDate} - ${props.contentDetail.TourEndDate}`
                    : '-'
                }`}
                as="h5"
              />
            </Grid.Column>

            <Grid.Column>
              <Header content="Resource Booking No." as="h5" />
            </Grid.Column>
            <Grid.Column>
              <Header
                content={`: ${
                  props.contentDetail.ResouceBookingCode
                    ? props.contentDetail.ResouceBookingCode
                    : '-'
                }`}
                as="h5"
              />
            </Grid.Column>
            <Grid.Column>
              <Header content="Resource Name" as="h5" />
            </Grid.Column>
            <Grid.Column>
              <Header
                content={`: ${
                  props.contentDetail.ResourceName
                    ? props.contentDetail.ResourceName
                    : '-'
                }`}
                as="h5"
              />
            </Grid.Column>
            <Grid.Column>
              <Header content="Request Date" as="h5" />
            </Grid.Column>
            <Grid.Column>
              <Header
                content={`: ${
                  props.contentDetail.Date
                    ? convertDateFormat(props.contentDetail.Date, 'DD MMM YYYY')
                    : '-'
                }`}
                as="h5"
              />
            </Grid.Column>
            <Grid.Column>
              <Header content="Due Date" as="h5" />
            </Grid.Column>
            <Grid.Column>
              <Header
                content={`: ${
                  props.contentDetail.DueDate
                    ? convertDateFormat(
                        props.contentDetail.DueDate,
                        'DD MMM YYYY'
                      )
                    : '-'
                }`}
                as="h5"
              />
            </Grid.Column>
            <Grid.Column>
              <Header content="Note" as="h5" />
            </Grid.Column>
            <Grid.Column>
              <Header
                content={`: ${
                  props.contentDetail.Note ? props.contentDetail.Note : '-'
                }`}
                as="h5"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
      <Grid.Column width={2} />
      <Grid.Column width={4}>
        <Grid divided="vertically">
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header content="Ownership" as="h5" />
            </Grid.Column>
            <Grid.Column>
              <Header
                content={`: ${
                  props.contentDetail.OwnerShip.Text !== null
                    ? 'Touress' //props.contentDetail.Status.replace(/_/g, ' ')
                    : props.contentDetail.OwnerShip.Text
                }`}
                as="h5"
              />
            </Grid.Column>
            <Grid.Column>
              <Header content="Priority" as="h5" />
            </Grid.Column>
            <Grid.Column>
              <Header
                content={`: ${
                  props.contentDetail.Priority === 'LOW'
                    ? 'Low'
                    : props.contentDetail.Priority === 'MEDIUM'
                    ? 'Medium'
                    : props.contentDetail.Priority === 'HIGH'
                    ? 'High'
                    : '-'
                }`}
                as="h5"
              />
            </Grid.Column>
            <Grid.Column>
              <Header content="Status" as="h5" />
            </Grid.Column>
            <Grid.Column>
              <Header
                content={`: ${
                  props.contentDetail.PaymentStatus === 'UNPAID'
                    ? 'Unpaid'
                    : props.contentDetail.PaymentStatus === 'PARTIALPAYMENT'
                    ? 'Partial Payment'
                    : props.contentDetail.PaymentStatus === 'FULLPAYMENT'
                    ? 'Full Payment'
                    : props.contentDetail.PaymentStatus === 'CANCELED'
                    ? 'Canceled'
                    : null
                }`}
                as="h5"
              />
            </Grid.Column>
            <Grid.Column>
              <Header content="Total Cost" as="h5" />
            </Grid.Column>
            <Grid.Column>
              <Header
                content={`: ${
                  props.contentDetail.Currency
                    ? props.contentDetail.Currency
                    : ''
                } ${
                  props.contentDetail.Value
                    ? new Intl.NumberFormat('de-DE').format(
                        props.contentDetail.Value
                      )
                    : 0
                }`}
                as="h5"
              />
            </Grid.Column>
            <Grid.Column>
              <Header content="Total Paid" as="h5" />
            </Grid.Column>
            <Grid.Column>
              <Header
                content={`: ${
                  props.contentDetail.Currency
                    ? props.contentDetail.Currency
                    : ''
                } ${
                  props.contentDetail.Value
                    ? new Intl.NumberFormat('de-DE').format(
                        props.contentDetail.Value -
                          props.contentDetail.BalanceDue
                      )
                    : 0
                }`}
                as="h5"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

PaymentInformation.propTypes = {
  contentDetail: PropTypes.object,
};

export default PaymentInformation;
