import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Input,
  Pagination,
  Grid,
  Segment,
  Button,
  Container,
} from 'semantic-ui-react';
import TableBookingConfirmation from './table/TableBookingConfirmation';
import moment from 'moment';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../scripts/pagination';
import {
  resetStatusTransactionAction,
  getTransactionByStatusActionAfterDPAction,
} from '../../../actions/tourTransactionAction';
import HeaderTitle from '../../shared/components/common/headerSegment/HeaderTitle';
import ModalDateRange from '../../shared/components/modal/ModalDateRange';
class BookingConfirmation extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
      headerTable: [
        'Booking Number',
        'Tour Name ',
        'Company Name',
        'Status',
        'Total Price',
        'Total Paid',
        'Payment Detail',
        'Action',
      ],
      bookingList: [],
      dateRangeConfirm: {
        value: '',
        createStartDate: '',
        createEndDate: '',
        tourStartDate: moment(new Date()).format('YYYY-MM-DD'),
        tourEndDate: moment()
          .add(30, 'd')
          .format('YYYY-MM-DD'),
      },
      loading: true,
      radioValueDateRange: '1',
    };
  }
  handleRadioDateRange = value => {
    this.setState({ radioValueDateRange: value });
  };

  componentDidMount() {
    const {
      createStartDate,
      createEndDate,
      tourStartDate,
      tourEndDate,
    } = this.state.dateRangeConfirm;
    this.loadDataWithDate(
      createStartDate,
      createEndDate,
      tourStartDate,
      tourEndDate,
      'After_DP'
    );
  }

  loadDataWithDate = (
    createStartDate,
    createEndDate,
    tourStartDate,
    tourEndDate,
    status
  ) => {
    this.setState({ loading: true });
    this.props.getTransactionByStatusActionAfterDPAction(
      tourStartDate,
      tourEndDate,
      createStartDate,
      createEndDate,
      status
    );
  };

  openModalDateRange = () =>
    this.setState({
      openModalFilterBooking: true,
      dateRangeConfirm: {
        createStartDate: '',
        createEndDate: '',
        tourStartDate: '',
        tourEndDate: '',
      },
    });

  handleCloseFilterBooking = () => {
    const { openModalFilterBooking } = this.state;
    this.setState({
      openModalFilterBooking: !openModalFilterBooking,
    });
  };

  actionDateRange = id => {
    const { radioValueDateRange } = this.state;
    const value = radioValueDateRange;
    const { from, to } = id;
    // const { statusFilter } = dateRangeConfirm;
    this.setState({
      openModalFilterBooking: false,
      activePage: 1,
    });
    let setDate = {};
    if (value === '2') {
      this.loadDataWithDate(
        moment(from).format('YYYY-MM-DD'),
        moment(to).format('YYYY-MM-DD'),
        '',
        '',
        'After_DP'
      );
      setDate = {
        createStartDate: from,
        createEndDate: to,
        tourStartDate: '',
        tourEndDate: '',
        value: 'After_DP',
      };
      this.setState({ dateRangeConfirm: setDate });
    } else {
      this.loadDataWithDate(
        '',
        '',
        moment(from).format('YYYY-MM-DD'),
        moment(to).format('YYYY-MM-DD'),
        'After_DP'
      );
      setDate = {
        createStartDate: '',
        createEndDate: '',
        tourStartDate: from,
        tourEndDate: to,
        value: 'After_DP',
      };
      this.setState({ dateRangeConfirm: setDate });
    }
    this.props.handleSetDateRange(setDate);
  };

  componentDidUpdate() {
    if (this.props.statusBookingConfirmation === 'success') {
      this.setState({
        bookingList: this.props.listBookingConfirmation.map(data => {
          return {
            Id: data.BookingId,
            Title: data.BookingName,
            CompanyName: data.CompanyName,
            Status: data.TransactionStatus,
            TourTotalPrice: data.TotalPrice,
            TotalPayed: data.TotalPaid,
            CurrencyId: data.Currency,
            PackageType: data.PackageType,
            BookingTemplateRefId: data.BookingTemplateRefId,
            CompanyCode: data.CompanyCode,
          };
        }),
        loading: false,
      });
      this.props.resetStatusTransactionAction();
    }
  }

  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  filteredData = data =>
    data.filter(data => {
      let { searchInput } = this.state;
      return (
        data.Title.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1 ||
        data.Id.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1
      );
    });
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  render() {
    const {
      dateRangeConfirm,
      activePage,
      headerTable,
      searchInput,
      bookingList,
      loading,
      radioValueDateRange,
    } = this.state;
    let filterData = this.filteredData(bookingList);
    let pagedData = datasetPagination(filterData, 5);
    let listData = pagedData[activePage - 1];
    const props = this.props;

    let { numbersFrom, numbersTo } = getNumberFromTo(listData, activePage, 5);
    let createEndDate =
      dateRangeConfirm.createEndDate === ''
        ? ''
        : moment(dateRangeConfirm.createEndDate).format('DD MMM YYYY');
    let createStartDate =
      dateRangeConfirm.createStartDate === ''
        ? ''
        : moment(dateRangeConfirm.createStartDate).format('DD MMM');
    let tourStartDate =
      dateRangeConfirm.tourStartDate === ''
        ? ''
        : moment(dateRangeConfirm.tourStartDate).format('DD MMM');
    let tourEndDate =
      dateRangeConfirm.tourEndDate === ''
        ? ''
        : moment(dateRangeConfirm.tourEndDate).format('DD MMM YYYY');
    return (
      <div>
        <Grid columns="equal" style={{ marginTop: '30px' }}>
          <Grid.Row stretched>
            <Grid.Column width={15}>
              <Segment>
                <HeaderTitle title="Booking Confirmation" />
                <Input
                  icon="search"
                  placeholder="search"
                  name="searchInput"
                  value={searchInput}
                  onChange={this.handleChange}
                />
                <Button
                  icon="filter"
                  basic
                  size="small"
                  floated="right"
                  positive
                  id="Booking Confirmation"
                  name="After_DP"
                  onClick={this.openModalDateRange}
                />
                <div
                  style={{
                    marginTop: '-2.9em',
                    marginRight: '5em',
                    fontWeight: 'bold',
                    marginBottom: '3em',
                  }}
                >
                  {/* {(tourStartDate !== '' || tourEndDate !== '') && ( */}
                  <Container
                    content={
                      'Start tour range date : ' +
                      tourStartDate +
                      ' - ' +
                      tourEndDate
                    }
                    textAlign="right"
                  />
                  {/* )}
                  {(createStartDate !== '' || createEndDate !== '') && ( */}
                  <Container
                    content={
                      'Created tour range date : ' +
                      createStartDate +
                      ' - ' +
                      createEndDate
                    }
                    textAlign="right"
                  />
                  {/* )} */}
                </div>
                <TableBookingConfirmation
                  data={bookingList}
                  headerTable={headerTable}
                  pageChange={this.pageChange}
                  listData={listData}
                  pagedData={pagedData}
                  actionButton={props.actionBookingConfirmation}
                  loading={loading}
                  actionModalDetailBooking={props.actionModalDetailBooking}
                />
                {pagedData && pagedData.length > 1 && (
                  <Pagination
                    totalPages={pagedData.length}
                    onPageChange={this.pageChange}
                    activePage={activePage}
                    style={{ float: 'right' }}
                  />
                )}
                {pagedData && pagedData.length > 1 && (
                  <p>
                    Showing {numbersTo} to {numbersFrom} of
                    {bookingList.length}
                  </p>
                )}
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <ModalDateRange
            openModal={this.state.openModalFilterBooking}
            handleClose={this.handleCloseFilterBooking}
            dateRange={dateRangeConfirm}
            actionDateRange={this.actionDateRange}
            contentHeader="Booking Confirmation"
            value={radioValueDateRange}
            handleRadioDateRange={this.handleRadioDateRange}
          />
        </Grid>
      </div>
    );
  }
}

BookingConfirmation.propTypes = {
  data: PropTypes.array,
  actionBookingConfirmation: PropTypes.func,
  handleSelectDateRange: PropTypes.func,
  handleChangeDateRange: PropTypes.func,
  dateRange: PropTypes.object,
  actionDateRange: PropTypes.func,
  loading: PropTypes.bool,
  statusBookingConfirmation: PropTypes.string,
  listBookingConfirmation: PropTypes.array,
  resetStatusTransactionAction: PropTypes.func,
  getTransactionByStatusActionAfterDPAction: PropTypes.func,
  handleSetDateRange: PropTypes.func,
  actionModalDetailBooking: PropTypes.func,
};
function mapStateToProps(state) {
  return {
    listBookingConfirmation: state.TourTransactionStore.listTransaction,
    statusBookingConfirmation: state.TourTransactionStore.listTransactionStatus,
    // loading: state.TourTransactionStore.loading,
  };
}

export default connect(
  mapStateToProps,
  {
    resetStatusTransactionAction,
    getTransactionByStatusActionAfterDPAction,
  }
)(BookingConfirmation);
