import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import styles from '../../../../../assets/styles/styleCreateTourTransaction';
import TimeLineDailyProgram from './TimeLineDailyProgram';
import ModalActivity from './modal/ModalActivityTourTransaction';
import {
  checkCustomToReturnTimeLine,
  checkFixedToReturnTimeLine,
} from '../../../../../components/tourTransaction/funtionDailyProgram';
import {
  // getExcursionByCityAction,
  getExcursionByCityWithFilterAction,
  resetExcursionAction,
  getExcursionTypeAction,
} from '../../../actions/excursionAction';
import {
  getRestaurantByCityDateAndPaxAction,
  resetRestaurantAction,
  getRestaurantByFilterAction,
  getRestaurantProfileSpecializationsAction,
  getMenuClassesAction,
} from '../../../../../actions/restaurantAction';
import {
  resetTransportationAction,
  getTransportationyFromToDateAction,
  getTransportationUnitByFilterAction,
} from '../../../actions/transportationAction';
import { getMasterReferenceAction } from '../../../actions/uploadMasterDataReferenceAction';
import { convertDateFormat } from '../../../../../components/genericFunction/moment';
//import ModalTransportation from '../modal/modalTourTransaction/modalTransaportationTourTransaction';
import Loader from '../../../../../components/loader/loader';
// import ModalTransportation from '../modal/modalTourTransaction/modalTransaportationTourTransaction';
// import Loader from './../loader/loader';
import ModalTransportation from '../../../../../components/modal/modalListTransportation';
import { isThereExcursionMeal } from '../../../../../scripts/itineraryBuilder/dailyProgramValidation';
// import TimeLineGuideInfo from './TimeLineGuideInfo';
class DailyProgram extends Component {
  state = {
    openModalActivity: false,
    activityType: '',
    activity: null,
    index: 0,
    movements: null,
    day: null,
    openModalTransportationUnits: false,
    dataTransportation: null,
    listItem: [],
    isThereActivity: false,
  };

  checkItemName = (data, type) => {
    let description = '';
    if (type === 'Custom') {
      description = checkCustomToReturnTimeLine(data);
    } else if (type === 'Fixed') {
      description = checkFixedToReturnTimeLine(data);
    } else {
      description = checkCustomToReturnTimeLine(data);
    }
    return description;
  };
  handleAddActivities = (e, { name }) => {
    this.setState({
      openModalActivity: true,
      activityType: name,
    });
  };
  openModalSetActivity = (
    activityType,
    activity,
    movements,
    index,
    day,
    action
  ) => {
    const { guests } = this.props;
    let totalPax = guests ? guests.adults + guests.childs : 0;
    if (totalPax === 0) {
      totalPax = 1;
    }
    if (activityType === 'EAT') {
      const dataRestaurant = {
        cityId: activity.Destination,
        ratingIds: '',
        locationIds: '',
        specializationId: '',
        menuClassId: '',
        facilityIds: '',
        requestedDate: convertDateFormat(activity.DateTime, 'YYYY-MM-DD'),
        pax: totalPax,
      };
      this.props.getRestaurantByFilterAction(dataRestaurant);
      this.props.getRestaurantProfileSpecializationsAction();
      this.props.getMenuClassesAction();
      this.setState({
        ...this.state,
        openModalActivity: true,
        activityType: activityType,
        activity: activity,
        index: index,
        movements: movements,
        day: day,
        action: action,
      });
    } else if (activityType === 'RECREATION') {
      this.props.getExcursionByCityWithFilterAction(
        activity.Destination,
        '',
        convertDateFormat(activity.DateTime, 'YYYY-MM-DD'),
        totalPax
      );
      this.props.getExcursionTypeAction();
      this.setState({
        ...this.state,
        openModalActivity: true,
        activityType: activityType,
        activity: activity,
        index: index,
        movements: movements,
        day: day,
        action: action,
      });
    } else {
      this.setState({
        ...this.state,
        openModalActivity: true,
        activityType: activityType,
        activity: activity,
        index: index,
        movements: movements,
        day: day,
        action: action,
      });
    }
  };

  componentDidUpdate() {
    const {
      getExcurtionProfileByCityStatus,
      getExcurtionProfileByCity,
      getRestaurantByCityDateAndPaxStatus,
      restaurantByCityDateAndPax,
      getTrasnportationByFromToDateStatus,
      transportationByFromToDate,
    } = this.props;
    if (getExcurtionProfileByCityStatus === 'success') {
      let listItem = this.state.listItem;
      listItem = getExcurtionProfileByCity.map((data, i) => {
        return {
          ...getExcurtionProfileByCity,
          key: i,
          text: data.Name,
          value: data.ServiceItemId,
          Name: data.Name,
          OperationStartTime: data.OperationStartTime,
          OptimumDuration: data.OptimumDuration,
          OperationEndTime: data.OperationEndTime,
          IsSolidStartTime: data.IsSolidStartTime,
          ServiceItemId: data.ServiceItemId,
        };
      });
      this.setState({
        listItem: listItem,
      });
      this.props.resetExcursionAction();
    }
    if (getRestaurantByCityDateAndPaxStatus === 'success') {
      let listItem = this.state.listItem;
      listItem = restaurantByCityDateAndPax.map((data, i) => {
        return {
          ...restaurantByCityDateAndPax,
          key: i,
          text: data.Name,
          value: data.ServiceItemId,
          Name: data.Name,
          OperationStartTime: data.OperationStartTime,
          OptimumDuration: data.OptimumDuration,
          OperationEndTime: data.OperationEndTime,
          IsSolidStartTime: data.IsSolidStartTime,
          ServiceItemId: data.ServiceItemId,
        };
      });
      this.setState({
        listItem: listItem,
      });
      this.props.resetRestaurantAction();
    }
    if (getTrasnportationByFromToDateStatus === 'success') {
      let listItem = this.state.listItem;
      listItem = transportationByFromToDate.map((data, i) => {
        return {
          ...data,
          key: i,
          text: data.Name,
          value: data.ServiceItemId,
        };
      });
      this.setState({
        listItem: listItem,
      });
      this.props.resetTransportationAction();
    }
  }

  closeModalSetActivity = () => {
    this.setState({
      ...this.state,
      openModalActivity: false,
      openModalTransportationUnits: false,
    });
  };

  openTransportationModal = (from, to, date, index, day) => {
    let dest = this.getDestinations(day, index);
    let dataTransportation = {
      fromCity: from,
      toCities: dest ? dest : to,
      RatingId: '',
      seatTypeId: '',
      typeId: '',
      requestedDate: convertDateFormat(date, 'YYYY-MM-DD'),
      currentDestination: to,
    };
    //this.props.getTransportationyFromToDateAction(from, to, date);
    this.props.getTransportationUnitByFilterAction(dataTransportation);
    this.getTransportationDataForFilter();
    let isThereActivity = this.isThereExcursionMeal(day, index);
    this.setState({
      ...this.state,
      dataTransportation: dataTransportation,
      openModalTransportationUnits: true,
      index: index,
      day: day,
      isThereActivity: isThereActivity,
    });
  };
  isThereExcursionMeal = (day, index) => {
    return isThereExcursionMeal(this.props.dailyProgram, day, index);
  };
  setTransportation = (transport, service, hour) => {
    let { index, day } = this.state;
    this.props.setTransportItem(day, index, transport, service, hour);
  };

  async getTransportationDataForFilter() {
    const array = [
      'TransportationTypes',
      'TransportationSeatTypes',
      'TransportationProfileCategories',
    ];
    const context = this;
    for (var i = 0; i < array.length; i++) {
      if (array[i] === 'TransportationTypes') {
        await context.props.getMasterReferenceAction(array[i]).then(res =>
          this.setState({
            transportationTypesList: res.value.data,
          })
        );
      } else if (array[i] === 'TransportationSeatTypes') {
        await context.props.getMasterReferenceAction(array[i]).then(res =>
          this.setState({
            transportationSeatTypeList: res.value.data,
          })
        );
      } else if (array[i] === 'TransportationProfileCategories') {
        await context.props.getMasterReferenceAction(array[i]).then(res =>
          this.setState({
            transportationProfileCatagories: res.value.data,
          })
        );
      }
    }
  }
  getDestinations = (day, moveIdx) => {
    let movements = this.props.dailyProgram[day].Movements;
    let dest = [...movements].reduce((res, item, i, arr) => {
      if (item.MovementName === 'DEPARTURE' && moveIdx <= i) {
        //moveIdx >= i
        res.indexOf(item.OriginDestination) === -1 &&
          res.push(item.OriginDestination);
        arr.splice(1);
      } else if (item.MovementName === 'ARRIVAL' && moveIdx >= i) {
        //&& moveIdx >= i
        res = [item.OriginDestination];
      } else {
        res.indexOf(item.OriginDestination) === -1 &&
          res.push(item.OriginDestination);
      }
      // res.push(item.Destination);
      return res;
    }, []);
    return dest.join();
  };
  render() {
    let {
      activityType,
      openModalActivity,
      activity,
      index,
      movements,
      day,
      action,
      openModalTransportationUnits,
      dataTransportation,
      transportationTypesList,
      transportationSeatTypeList,
      transportationProfileCatagories,
      isThereActivity,
    } = this.state;
    const {
      loadingExcursion,
      loadingRestaurant,
      setDailyProgramAfterAdd,
      dailyProgram,
      typePackage,
      deleteActivity,
      deleteTransportItem,
      loadingTransporation,
      loadingPackage,
      transportStore,
      getExcurtionProfileByCity,
      isPublished,
      setDailyProgram,
      currentPackage,
      guests,
      isPackageMangement,
      isReadyFixedPrice,
      isSales,
      isAdministrator,
    } = this.props;
    const loadingItem =
      loadingExcursion || loadingRestaurant || loadingTransporation;
    const isAllowedEdit = isSales || isAdministrator;
    return (
      <div>
        {dailyProgram ? (
          dailyProgram.map((data, idDays) => {
            // console.log(setDailyProgramAfterAdd);
            return (
              <Grid
                verticalAlign="middle"
                key={idDays}
                style={{ paddingTop: '15px' }}
              >
                <div style={{ width: '100%' }}>
                  {/* <Segment style={{ border: 'none' }}> */}
                  <Loader active={loadingPackage} inverted={true} />

                  <TimeLineDailyProgram
                    data={data}
                    checkItemName={this.checkItemName}
                    handleAddActivities={this.handleAddActivities}
                    buttonAddActivity={this.buttonAddActivity}
                    type={typePackage}
                    key={'move' + idDays}
                    day={idDays}
                    handleOpenModalActivities={this.openModalSetActivity}
                    index={idDays}
                    deleteActivity={deleteActivity}
                    deleteTransportItem={deleteTransportItem}
                    openTransportationModal={this.openTransportationModal}
                    transportStore={transportStore}
                    dayDate={data.Date}
                    dailyProgram={dailyProgram}
                    isPublished={isPublished}
                    setDailyProgram={setDailyProgram}
                    guests={guests}
                    isStatic={currentPackage && currentPackage.disabledEdit}
                    currentPackage={currentPackage}
                    isPackageMangement={isPackageMangement}
                    isReadyFixedPrice={isReadyFixedPrice}
                    isAllowedEdit={isAllowedEdit}
                  />
                  {/* </Segment> */}
                </div>
              </Grid>
            );
          })
        ) : (
          <Grid verticalAlign="middle">
            <Grid.Row style={styles.marginDailyProgramDays}>
              <Grid.Column width={12}>
                <Header as="h4" style={{ marginTop: '10px' }}>
                  Daily Program Empty
                </Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
        <ModalActivity
          openModal={openModalActivity}
          handleClose={this.closeModalSetActivity}
          activityType={activityType}
          activity={activity}
          closeModal={this.closeModalSetActivity}
          dailyProgram={dailyProgram}
          movements={movements}
          action={action}
          index={index}
          day={day}
          // listItem={listItem}
          listItem={getExcurtionProfileByCity}
          loadingItem={loadingItem}
          setDailyProgramAfterAdd={setDailyProgramAfterAdd}
        />
        <ModalTransportation
          // openModal={openModalTransportationUnits}
          // handleClose={this.closeModalSetActivity}
          // activityType={activityType}
          // activity={dataTransportation}
          // dailyProgram={dailyProgram}
          // movements={dailyProgram[day]}
          // action={action}
          // index={index}
          // day={day}
          // listItem={listItem}
          // loadingItem={loadingItem}
          // setDailyProgramAfterAdd={setDailyProgramAfterAdd}
          // setTransportItem={this.setTransportation}
          open={openModalTransportationUnits}
          onClose={this.closeModalSetActivity}
          name="Transportation"
          from={dataTransportation ? dataTransportation.fromCity : null}
          to={dataTransportation ? dataTransportation.currentDestination : null}
          date={dataTransportation ? dataTransportation.requestedDate : null}
          setTransportItem={this.setTransportation}
          //=======================field filter===============
          transportationTypesList={transportationTypesList}
          transportationSeatTypeList={transportationSeatTypeList}
          transportationProfileCatagories={transportationProfileCatagories}
          isThereActivity={isThereActivity}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    getRestaurantByCityDateAndPaxStatus:
      state.RestaurantStore.getRestaurantByCityDateAndPaxStatus,
    getExcurtionProfileByCity: state.ExcursionStore.getExcurtionProfileByCity,
    getExcurtionProfileByCityStatus:
      state.ExcursionStore.getExcurtionProfileByCityStatus,
    restaurantByCityDateAndPax:
      state.RestaurantStore.restaurantByCityDateAndPax,
    loadingExcursion: state.ExcursionStore.loading,
    loadingRestaurant: state.RestaurantStore.loading,
    transportationByFromToDate:
      state.TransportationStore.transportationByFromToDate,
    getTrasnportationByFromToDateStatus:
      state.TransportationStore.getTrasnportationByFromToDateStatus,
    loadingTransporation: state.TransportationStore.loading,
    loadingPackage: state.PackageStore.loadingGetDuration,
    isSales: state.UserAccessStore.isSales,
    isAdministrator: state.UserAccessStore.isAdministrator,
  };
};

DailyProgram.propTypes = {
  getExcursionByCityWithFilterAction: PropTypes.func,
  getExcurtionProfileByCityStatus: PropTypes.string,
  getExcurtionProfileByCity: PropTypes.array,
  resetExcursionAction: PropTypes.func,
  loadingExcursion: PropTypes.bool,
  dailyProgram: PropTypes.array,
  getRestaurantByFilterAction: PropTypes.func,
  getRestaurantByCityDateAndPaxStatus: PropTypes.string,
  restaurantByCityDateAndPax: PropTypes.array,
  resetRestaurantAction: PropTypes.func,
  loadingRestaurant: PropTypes.bool,
  setDailyProgramAfterAdd: PropTypes.func,
  typePackage: PropTypes.string,
  deleteActivity: PropTypes.func,
  deleteTransportItem: PropTypes.func,
  loadingTransporation: PropTypes.func,
  setTransportItem: PropTypes.func,
  getTrasnportationByFromToDateStatus: PropTypes.string,
  transportationByFromToDate: PropTypes.array,
  resetTransportationAction: PropTypes.func,
  getTransportationyFromToDateAction: PropTypes.func,
  transportStore: PropTypes.array,
  loadingPackage: PropTypes.bool,
  getTransportationUnitByFilterAction: PropTypes.func,
  getRestaurantProfileSpecializationsAction: PropTypes.func,
  getMenuClassesAction: PropTypes.func,
  guests: PropTypes.object,
  getExcursionTypeAction: PropTypes.func,
  isStatic: PropTypes.bool,
  setDailyProgram: PropTypes.func,
  setTourOperator: PropTypes.func,
  currentPackage: PropTypes.object,
  isPublished: PropTypes.bool,
  isPackageMangement: PropTypes.bool,
  isReadyFixedPrice: PropTypes.bool,
  isSales: PropTypes.bool,
  isAdministrator: PropTypes.bool,
};
export default connect(mapStateToProps, {
  getExcursionByCityWithFilterAction,
  resetExcursionAction,
  getRestaurantByCityDateAndPaxAction,
  resetRestaurantAction,
  getTransportationyFromToDateAction,
  getTransportationUnitByFilterAction,
  resetTransportationAction,
  getRestaurantByFilterAction,
  getMasterReferenceAction,
  getRestaurantProfileSpecializationsAction,
  getMenuClassesAction,
  getExcursionTypeAction,
})(DailyProgram);
