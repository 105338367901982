import React, { Component } from 'react';
import { Grid, Segment, Container, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import TableSystemMargin from './TableSystemMargin';
import { HeaderTabelSystemMargin } from '../constant/headerTable';
// import InputDate from '../../../components/input/inputDate';
import HeaderTitle from '../../../components/common/headerTitle';
import Pagination from '../../../components/common/pagination';
import { getNumberFromTo } from '../../../scripts/pagination';
import moment from 'moment';

class SegmentSystemMargin extends Component {
  static propTypes = {
    data: PropTypes.array,
  };
  handleChange = (e, { name, value }) => this.setState({ [name]: value });
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  render() {
    const props = this.props;

    let { numbersFrom, numbersTo } = getNumberFromTo(
      props.listData,
      props.activePage,
      7
    );
    return (
      <Segment style={{ marginTop: '30px', marginRight: '10px' }}>
        <HeaderTitle iconName="money" title={props.title} />
        <Grid columns="equal">
          <Grid.Row columns={3}>
            <Grid.Column width="8" textAlign="left">
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Container>
                      <label>
                        Set Margin for
                        <strong>
                          {'     '}
                          {props.content.companyName}
                          {'     '}
                          {props.content.companyCode}
                        </strong>
                      </label>
                    </Container>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column width="8" textAlign="right">
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Button onClick={props.buttonNew} primary floated="right">
                      Add New Margin
                    </Button>
                    {!props.disableButton && (
                      <Button
                        onClick={props.buttonBack}
                        primary
                        floated="right"
                        disabled={props.disableButton}
                        content="Back"
                      />
                    )}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Button
                      icon="filter"
                      basic
                      size="small"
                      floated="right"
                      positive
                      onClick={props.openModalDateRange}
                    />
                    <div
                      style={{
                        marginTop: '-2px',
                        marginRight: '5em',
                        fontWeight: 'bold',
                        // marginBottom: '3em',
                      }}
                    >
                      <Container
                        content={'Filter by valid date'}
                        textAlign="right"
                      />
                      <Container
                        content={
                          props.dateRange
                            ? `${
                                props.dateRange
                                  ? moment(props.dateRange.from).format(
                                      'DD MMM YYYY'
                                    )
                                  : ''
                              } ${props.dateRange ? '-' : ''} ${
                                props.dateRange
                                  ? moment(props.dateRange.to).format(
                                      'DD MMM YYYY'
                                    )
                                  : ''
                              }`
                            : '-'
                        }
                        textAlign="right"
                      />
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row stretched>
            <Grid.Column>
              <TableSystemMargin
                headerTable={HeaderTabelSystemMargin}
                pageChange={props.pageChange}
                listData={props.listData}
                pagedData={props.pagedData}
                butttonEdit={props.butttonEdit}
                buttonDelete={props.buttonDelete}
              />
              <Pagination
                pagedData={props.pagedData}
                numbersTo={numbersTo}
                numbersFrom={numbersFrom}
                data={props.systemMarginList}
                pageChange={props.pageChange}
                activePage={props.activePage}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
SegmentSystemMargin.propTypes = {
  systemMarginList: PropTypes.array,
  filterData: PropTypes.array,
  listData: PropTypes.array,
  pagedData: PropTypes.array,
  title: PropTypes.string,
  content: PropTypes.object,
  pageChange: PropTypes.func,
  activePage: PropTypes.number,
  // searchInput: PropTypes.string,
  handleChange: PropTypes.func,
  data: PropTypes.array,
  buttonNew: PropTypes.func,
  butttonEdit: PropTypes.func,
  buttonDelete: PropTypes.func,
  buttonBack: PropTypes.func,
  disableButton: PropTypes.bool,
  numbersFrom: PropTypes.number,
  numbersTo: PropTypes.number,
  openModalDateRange: PropTypes.func,
  searchInput: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dateRange: PropTypes.object,
};
export default SegmentSystemMargin;
