import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form, Input } from 'semantic-ui-react';
import InputCurrencyFormat from '../../../../components/input/inputCurrencyFormat';

const ModalAddittionalItem = props => (
  <Modal
    closeIcon
    inverted
    open={props.openModal}
    onClose={props.handleClose}
    size="tiny"
  >
    <Modal.Header>
      <h3>Additional Item</h3>
    </Modal.Header>
    <Modal.Content>
      <Form>
        <Form.Field
          label="Additional Name"
          placeholder="Name"
          control={Input}
          name="Name"
          value={props.additionalItemObject.Name}
          onChange={props.handleChange}
        />
        <Form.Field
          label="Description"
          name="Description"
          control={Input}
          placeholder="Description"
          value={props.additionalItemObject.Description}
          onChange={props.handleChange}
        />
        <Form.Field>
          <Form.Checkbox
            label="Cost as deduction value"
            fluid
            name="IsDeduction"
            checked={props.additionalItemObject.IsDeduction}
            onChange={props.handleChangeAddittionalItemCheckBox}
          />
        </Form.Field>
        <Form.Field>
          <label>
            {props.additionalItemObject.IsDeduction ? 'Cost Deduction' : 'Cost'}
          </label>
          <InputCurrencyFormat
            value={
              props.additionalItemObject.UnitCost
                ? props.additionalItemObject.UnitCost
                : 0
            }
            name={'UnitCost'}
            id={'UnitCost'}
            disabled={false}
            handelChangePrice={props.handleChangeAddittionalItemPrice}
          />
        </Form.Field>
        <Form.Field>
          <Form.Checkbox
            label="Mandatory"
            fluid
            name="IsMandatory"
            checked={props.additionalItemObject.IsMandatory}
            onChange={props.handleChangeAddittionalItemCheckBox}
          />
          <Form.Checkbox
            label="Infant Count"
            fluid
            name="IsInfantCount"
            checked={props.additionalItemObject.IsInfantCount}
            onChange={props.handleChangeAddittionalItemCheckBox}
            disabled={!props.additionalItemObject.IsMandatory}
          />
        </Form.Field>
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button content="Save" onClick={props.addAddittionalItem} primary />
    </Modal.Actions>
  </Modal>
);
ModalAddittionalItem.propTypes = {
  openModal: PropTypes.bool,
  handleClose: PropTypes.func,
  addAddittionalItem: PropTypes.func,
  additionalItemObject: PropTypes.shape({
    UnitCost: PropTypes.number,
    Name: PropTypes.string,
    Description: PropTypes.string,
    CurrencyId: PropTypes.string,
    IsMandatory: PropTypes.bool,
    IsInfantCount: PropTypes.bool,
    IsDeduction: PropTypes.bool,
  }),
  handleChange: PropTypes.func,
  handleChangeAddittionalItemCheckBox: PropTypes.func,
  handleChangeAddittionalItemPrice: PropTypes.func,
  currencies: PropTypes.array,
};
export default ModalAddittionalItem;
