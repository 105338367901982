import {
  GET_ACCOMODATION_TYPES,
  GET_ACCOMODATION_FACILITIES,
  GET_ACCOMODATION_LOCATION,
  GET_ACCOMODATION_ITEM_TYPES,
  GET_ACCOMODATION_ITEM_SERVICE_TYPE_LIST,
  POST_ACCOMODATION,
  DELETE_ACCOMODATION_BY_PROFILE_ID,
  GET_EXPORT_ACCOMODATION,
  PUT_ACCOMODATION,
  POST_ACCOMODATION_ITEM,
  PUT_ACCOMODATION_ITEM,
  GET_ACCOMODATION_ITEM_EXPORT_DATA,
  DELETE_ACCOMODATION_ITEM,
  DELETE_IMAGE,
  DELETE_CONTRACTING_FILE,
  POST_CONTRACTING_FILE,
  RESET_STATUS_ACCOMODATION_PAGE,
} from './constant/actionTypes';
import getAccomodationTypesApi from '../../api/accomodation/getAccomodationTypesApi';
import getAccomodationFacilitiesApi from '../../api/accomodation/getAccomodationFacilitiesApi';
import getAccomodationLocationApi from '../../api/accomodation/getAccomodationLocationApi';
import getAccomodationItemTypesApi from '../../api/accomodation/getAccomodationItemTypesApi';
import getAccomodationItemServiceTypeListApi from '../../api/accomodation/getAccomodationItemServiceTypeListApi';
import postAccomodationProfileApi from '../../api/accomodation/postAccomodationProfileApi';
import deleteAccomodationByProfileIdApi from '../../api/accomodation/deleteAccomodationByProfileIdApi';
import getExportDataAccomodationProfileApi from '../../api/accomodation/getExportDataAccomodationProfileApi';
import putAccomodationProfileApi from '../../api/accomodation/putAccomodationProfileApi';
import postAccomodationItemApi from '../../api/accomodation/postAccomodationItemApi';
import putAccomodationItemApi from '../../api/accomodation/putAccomodationItemApi';
import getExportAccomodationItemApi from '../../api/accomodation/getExportAccomodationItemApi';

import deleteAccomodationItemApi from '../../api/accomodation/deleteAccomodationItemApi';
import deleteImageAccomodationProfileApi from '../../api/accomodation/deleteImageAccomodationProfileApi';
import deleteContactingFileApi from '../../api/accomodation/deleteContactingFileApi';
import postContractingFileApi from '../../api/accomodation/postContractingFileApi';

//
const resetAccomodationPageAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_STATUS_ACCOMODATION_PAGE,
    });
  };
};

const getAccomodationTypesAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_ACCOMODATION_TYPES,
      payload: getAccomodationTypesApi,
    });
  };
};

const getAccomodationFacilitiesAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_ACCOMODATION_FACILITIES,
      payload: getAccomodationFacilitiesApi,
    });
  };
};

const getAccomodationLocationAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_ACCOMODATION_LOCATION,
      payload: getAccomodationLocationApi,
    });
  };
};

const getAccomodationItemTypesAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_ACCOMODATION_ITEM_TYPES,
      payload: getAccomodationItemTypesApi,
    });
  };
};

const getAccomodationItemServiceTypeAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_ACCOMODATION_ITEM_SERVICE_TYPE_LIST,
      payload: getAccomodationItemServiceTypeListApi,
    });
  };
};

const postAccomodationProfileAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_ACCOMODATION,
      payload: postAccomodationProfileApi(data),
    });
  };
};
const putAccomodationItemAction = data => {
  return dispatch => {
    return dispatch({
      type: PUT_ACCOMODATION_ITEM,
      payload: putAccomodationItemApi(data),
    });
  };
};
const postAccomodationItemAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_ACCOMODATION_ITEM,
      payload: postAccomodationItemApi(data),
    });
  };
};

const deleteAccomodationByProfileIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: DELETE_ACCOMODATION_BY_PROFILE_ID,
      payload: deleteAccomodationByProfileIdApi(Id),
    });
  };
};

const getExportDataAccomodationProfileAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_EXPORT_ACCOMODATION,
      payload: getExportDataAccomodationProfileApi,
    });
  };
};

const putAccomodationProfileAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_ACCOMODATION,
      payload: putAccomodationProfileApi(id, data),
    });
  };
};

const getExportAccomodationItemAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_ACCOMODATION_ITEM_EXPORT_DATA,
      payload: getExportAccomodationItemApi,
    });
  };
};

const deleteAccomodationItemAction = Id => {
  return dispatch => {
    return dispatch({
      type: DELETE_ACCOMODATION_ITEM,
      payload: deleteAccomodationItemApi(Id),
    });
  };
};

const deleteImageAccomodationProfileAction = Id => {
  return dispatch => {
    return dispatch({
      type: DELETE_IMAGE,
      payload: deleteImageAccomodationProfileApi(Id),
    });
  };
};

const deleteContactingFileAction = (fileId, ProfileId) => {
  return dispatch => {
    return dispatch({
      type: DELETE_CONTRACTING_FILE,
      payload: deleteContactingFileApi(fileId, ProfileId),
    });
  };
};

const postContractingFileAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_CONTRACTING_FILE,
      payload: postContractingFileApi(data),
    });
  };
};

export {
  resetAccomodationPageAction,
  getAccomodationTypesAction,
  getAccomodationFacilitiesAction,
  getAccomodationLocationAction,
  getAccomodationItemTypesAction,
  getAccomodationItemServiceTypeAction,
  postAccomodationProfileAction,
  deleteAccomodationByProfileIdAction,
  getExportDataAccomodationProfileAction,
  putAccomodationProfileAction,
  postAccomodationItemAction,
  putAccomodationItemAction,
  getExportAccomodationItemAction,
  deleteAccomodationItemAction,
  deleteImageAccomodationProfileAction,
  deleteContactingFileAction,
  postContractingFileAction,
};
