import React from 'react';
import { Table, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ButtonActionDetailList from '../../../../../components/common/buttonEditAndDelete';
import { Link } from 'react-router-dom';
const TableItem = props => {
  let {
    listData,
    handleClickDelete,
    handleClickEdit,
    disablePage,
    header,
    useInResourceBookingList,
    handleChooseItemForRBL,
  } = props;
  return (
    <Table basic="very" selectable fixed>
      <Table.Header>
        <Table.Row>
          {header.map((headTableContent, index) => {
            return (
              <Table.HeaderCell key={index}>
                {headTableContent}
              </Table.HeaderCell>
            );
          })}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {listData && listData.length > 0 ? (
          listData.map((accomodationItem, idx) => {
            return (
              <Table.Row key={idx}>
                <Table.Cell component="th" scope="row" width={1}>
                  <Link
                    to={`/Accomodation-Item/Detail/${accomodationItem.AccommodationProfileId}/${accomodationItem.ServiceItemId}`}
                  >
                    {accomodationItem.Name}{' '}
                    {accomodationItem.IsPromo && '(Promotion Item)'}
                  </Link>
                </Table.Cell>
                <Table.Cell />
                <Table.Cell>
                  {accomodationItem.AccommodationType.Name}
                </Table.Cell>
                <Table.Cell>
                  {accomodationItem.AccommodationType.Capacity}
                </Table.Cell>
                <Table.Cell> {accomodationItem.MinDays}</Table.Cell>
                {useInResourceBookingList ? (
                  <Table.Cell>
                    {accomodationItem.EstimatedTotalPrice.CurrencyId}
                    {accomodationItem.EstimatedTotalPrice.Price.toLocaleString(
                      'id'
                    )}
                  </Table.Cell>
                ) : null}{' '}
                <Table.Cell>
                  {useInResourceBookingList ? (
                    <Button
                      content="Choose"
                      primary
                      onClick={handleChooseItemForRBL}
                      value={accomodationItem}
                    />
                  ) : (
                    <ButtonActionDetailList
                      id={accomodationItem.ServiceItemId}
                      handleClickDelete={handleClickDelete}
                      handleClickEdit={handleClickEdit}
                      content1="Edit"
                      content2="Delete"
                      disablePage={disablePage}
                    />
                  )}
                </Table.Cell>
              </Table.Row>
            );
          })
        ) : (
          <Table.Row>
            <Table.Cell colSpan={header.length} textAlign="center">
              No Data
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};
TableItem.propTypes = {
  listData: PropTypes.array,
  header: PropTypes.array,
  handleClickEdit: PropTypes.func,
  handleClickDelete: PropTypes.func,
  disablePage: PropTypes.bool,
  useInResourceBookingList: PropTypes.bool,
  handleChooseItemForRBL: PropTypes.func,
};
export default TableItem;
