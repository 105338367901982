import {
  DELETE_PAYMENT_METHOD_IN_COUNTRY,
  GET_PAYMENT_METHOD_IN_COUNTRY,
  POST_PAYMENT_METHOD_IN_COUNTRY,
} from './constant/actionTypes';
import deletePaymentMethodInCountryApi from '../../api/customEnum/deletePaymentMethodInCountryApi';
import getPaymentMethodInCountryApi from '../../api/customEnum/getPaymentMethodInCountryApi';
import postPaymentMethodInCountryApi from '../../api/customEnum/postPaymentMethodInCountryApi';

export const deletePaymentMethodInCountryAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: DELETE_PAYMENT_METHOD_IN_COUNTRY,
      payload: deletePaymentMethodInCountryApi(id, data),
    });
  };
};

export const getPaymentMethodInCountryAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: GET_PAYMENT_METHOD_IN_COUNTRY,
      payload: getPaymentMethodInCountryApi(id, data),
    });
  };
};

export const postPaymentMethodInCountryAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_PAYMENT_METHOD_IN_COUNTRY,
      payload: postPaymentMethodInCountryApi(data),
    });
  };
};
