import {
  GET_AIRPORT_BY_CITY,
  RESET_AIRPORT,
  GET_PLACE_BY_PLACE_TYPE_ID,
} from './actionTypes';
import getAirportByCityApi from '../api/airport/getAirportByCityApi';
import { getPlaceByPlaceTypeIdApi } from '../api/place/getPlaceListApi';
const getAirportAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_AIRPORT_BY_CITY,
      payload: getAirportByCityApi(Id),
    });
  };
};
const resetAiportAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_AIRPORT,
    });
  };
};

const getPlaceByPlaceTypeIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_PLACE_BY_PLACE_TYPE_ID,
      payload: getPlaceByPlaceTypeIdApi(Id),
    });
  };
};
export { getAirportAction, resetAiportAction, getPlaceByPlaceTypeIdAction };
