// var decodedString = '';

const decodeString = (string, char) => {
  return char === '%3C'
    ? string.replace(/%3C/g, '<')
    : char === '%21'
      ? string.replace(/%21/g, '!')
      : char === '%2F'
        ? string.replace(/%2F/g, '/')
        : char === '%3E'
          ? string.replace(/%3E/g, '>')
          : char === '%0A'
            ? string.replace(/%0A/g, '') // The ASCII control characters %00-%1F were originally designed to control hardware devices.
            : char === '%0D'
              ? string.replace(/%0D/g, '')
              : char === '%22'
                ? string.replace(/%22/g, '"')
                : char === '%23'
                  ? string.replace(/%23/g, '#')
                  : char === '%25'
                    ? string.replace(/%25/g, '%')
                    : char === '%26'
                      ? string.replace(/%26/g, '&')
                      : char === '%27'
                        ? string.replace(/%27/g, "'")
                        : char === '%28'
                          ? string.replace(/%28/g, '(')
                          : char === '%29'
                            ? string.replace(/%29/g, ')')
                            : char === '%2A'
                              ? string.replace(/%2A/g, '*')
                              : char === '%2C'
                                ? string.replace(/%2C/g, ',')
                                : char === '%3A'
                                  ? string.replace(/%3A/g, ':')
                                  : char === '%3B'
                                    ? string.replace(/%3B/g, ';')
                                    : char === '%3D'
                                      ? string.replace(/%3D/g, '=')
                                      : char === '%40'
                                        ? string.replace(/%40/g, '@')
                                        : char === '%7B'
                                          ? string.replace(/%7B/g, '{')
                                          : char === '%7D'
                                            ? string.replace(/%7D/g, '}')
                                            : string;
};

const decodeOtherChar = (string, char) => {
  return char === '+'
    ? string.replace(/\+/g, ' ')
    : char === '&'
      ? string.replace(/&/g, '\n')
      : char === '\r'
        ? string.replace(/\r/g, '')
        : char === '\n'
          ? string.replace(/\n/g, '')
          : char === '\\'
            ? string.replace(/\\/g, '')
            : string;
};

const decodeXML = async string => {
  let decodedString = string;
  await (decodedString = decodeString(decodedString, '%3C'));
  await (decodedString = decodeString(decodedString, '%21'));
  await (decodedString = decodeString(decodedString, '%2F'));
  await (decodedString = decodeString(decodedString, '%3E'));
  await (decodedString = decodeString(decodedString, '%0A'));
  await (decodedString = decodeString(decodedString, '%0D'));
  await (decodedString = decodeString(decodedString, '%22'));
  await (decodedString = decodeString(decodedString, '%23'));
  await (decodedString = decodeString(decodedString, '%25'));
  await (decodedString = decodeString(decodedString, '%26'));
  await (decodedString = decodeString(decodedString, '%27'));
  await (decodedString = decodeString(decodedString, '%28'));
  await (decodedString = decodeString(decodedString, '%29'));
  await (decodedString = decodeString(decodedString, '%2A'));
  await (decodedString = decodeString(decodedString, '%2C'));
  await (decodedString = decodeString(decodedString, '%3A'));
  await (decodedString = decodeString(decodedString, '%3B'));
  await (decodedString = decodeString(decodedString, '%3D'));
  await (decodedString = decodeString(decodedString, '%3E'));
  await (decodedString = decodeString(decodedString, '%7B'));
  await (decodedString = decodeString(decodedString, '%7D'));

  // non url encode
  await (decodedString = decodeOtherChar(decodedString, '+'));
  // await (decodedString = decodeOtherChar(decodedString, '&'));
  // await (decodedString = decodeOtherChar(decodedString, '\\'));
  // await (decodedString = decodeOtherChar(decodedString, '\r'));
  // await (decodedString = decodeOtherChar(decodedString, '\n'));
  return decodedString;
};

export { decodeXML, decodeOtherChar, decodeString };
