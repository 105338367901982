import * as types from '../../../actions/actionTypes';

const initialState = {
  getCityList: [], //
  getCityListStatus: '', //
  getCity: [], //
  getCityStatus: '', //
  originCityList: [], //
  getOriginCityStatus: '', //
  destCityList: [], //
  getDestCityStatus: '', //
  getCityInCountryList: [], //
  errors: {},
  loading: false,
  getCityInCountry: [], //
  getCityInCountryStatus: '', //
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //
    case types.GET_CITY_BY_REGION: {
      return { ...state };
    }
    case types.GET_CITY_BY_REGION_REJECTED: {
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getCityListStatus: 'failed',
        loading: false,
      };
    }
    case types.GET_CITY_BY_REGION_PENDING:
      return { ...state, loading: true };
    case types.GET_CITY_BY_REGION_FULFILLED: {
      return {
        ...state,
        getCityList: action.payload.data,
        loading: false,
        getCityListStatus: 'success',
      };
    }
    case types.RESET_CITY: {
      return {
        ...state,
        getCityListStatus: '',
        getCityStatus: '',
        getOriginCityStatus: '',
        getCityByIdStatus: '',
        getCityInCountryStatus: '',
      };
    }

    //
    case types.GET_CITY_LIST: {
      return { ...state };
    }
    case types.GET_CITY_LIST_REJECTED: {
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getCityStatus: 'failed',
        loading: false,
      };
    }
    case types.GET_CITY_LIST_PENDING: {
      return { ...state, loading: true };
    }
    case types.GET_CITY_LIST_FULFILLED: {
      return {
        ...state,
        getCity: action.payload.data,
        loading: false,
        getCityStatus: 'success',
      };
    }
    //
    case types.GET_ORIGIN_CITY_BY_REGION: {
      return { ...state };
    }
    case types.GET_ORIGIN_CITY_BY_REGION_PENDING: {
      return { ...state, loading: true };
    }
    case types.GET_ORIGIN_CITY_BY_REGION_FULFILLED: {
      return {
        ...state,
        loading: false,
        originCityList: action.payload.data,
        getOriginCityStatus: 'success',
      };
    }
    case types.GET_ORIGIN_CITY_BY_REGION_REJECTED: {
      return { ...state, loading: false, getOriginCityStatus: 'failed' };
    }

    //
    case types.GET_DEST_CITY_BY_REGION: {
      return { ...state };
    }
    case types.GET_DEST_CITY_BY_REGION_PENDING: {
      return { ...state, loading: true };
    }
    case types.GET_DEST_CITY_BY_REGION_FULFILLED: {
      return {
        ...state,
        loading: false,
        destCityList: action.payload.data,
        getDestCityStatus: 'success',
      };
    }
    case types.GET_DEST_CITY_BY_REGION_REJECTED: {
      return { ...state, loading: false, getDestCityStatus: 'failed' };
    }

    //
    case types.GET_CITY_IN_COUNTRY: {
      return { ...state };
    }
    case types.GET_CITY_IN_COUNTRY_REJECTED: {
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
      };
    }
    case types.GET_CITY_IN_COUNTRY_PENDING:
      return { ...state, loading: true };
    case types.GET_CITY_IN_COUNTRY_FULFILLED: {
      return {
        ...state,
        getCityInCountryList: action.payload.data,
        getCityInCountryStatus: 'success',
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default reducer;
