import React from 'react';
import { Grid, Table, Button, Segment, Container } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {
  getCompanyCommissionsByCompanyCode,
  deleteCompanyCommission,
} from '../../actions/companyAction';
import {
  getCustomerProfileByCodeAction,
  getCustomerProfileByCodeManageBySalesAction,
} from '../shared/actions/customerProfileAction';
import PropTypes from 'prop-types';
import ModalCommission from '../../components/modal/modalCommission';
import HeaderTitle from '../../components/common/headerTitle';
import ModalConfirm from '../../components/modal/modalConfirmationTwoButton';
import ModalALert from '../../components/modal/modalAlert';
import { rangeDate } from '../../scripts/moment';
import { formatCurrency } from '../../components/genericFunction/convertFormat';
import { getUserRoleByBearerAction } from '../../actions/userAccessAction';
import { checkUser } from './../../scripts/authorization';

class CompanyCommission extends React.Component {
  state = {
    openModalCommission: false,
    typeModalCommission: '',
    idSelected: null,
    openModalConfirm: false,
    headerModalConfirm: '',
    messageModalConfirm: '',
    openModalAlert: false,
    headerModalAlert: '',
    messageModalAlert: '',
    isSales: null,
  };
  componentDidMount = () => {
    this.props.getUserRoleByBearerAction().then(() => {
      let authorization = checkUser(this.props.userRoles.Roles);
      this.setState(
        {
          ...this.state,
          isSales: authorization.isSales,
        },
        () => {
          this.state.isSales
            ? this.props.getCustomerProfileByCodeManageBySalesAction(
                this.props.match.params.Id
              )
            : this.props.getCustomerProfileByCodeAction(
                this.props.match.params.Id
              );
        }
      );
    });
    this.props.getCompanyCommissionsByCompanyCode(this.props.match.params.Id);
  };
  openModalCommission = (e, { value, id }) => {
    this.setState({
      ...this.state,
      openModalCommission: true,
      typeModalCommission: value,
      idSelected: id,
    });
  };
  closeModalCommission = () =>
    this.setState({ ...this.state, openModalCommission: false });

  resetListCommissions = async () => {
    await this.closeModalCommission();
    this.props.getCompanyCommissionsByCompanyCode(this.props.match.params.Id);
  };

  openModalConfirm = (e, { value }) => {
    this.setState({
      openModalConfirm: true,
      headerModalConfirm: 'Confirmation',
      messageModalConfirm: 'Are you sure to delete ?',
      idSelected: value,
    });
  };
  handleCloseModalConfirm = () =>
    this.setState({
      ...this.state,
      openModalConfirm: false,
    });

  deleteCommission = () => {
    this.props
      .deleteCompanyCommission(this.state.idSelected)
      .then(async () => {
        await this.handleCloseModalConfirm();
        await this.openAlert('Success', 'Success Delete Commission');
        this.resetListCommissions();
      })
      .catch(async () => {
        await this.handleCloseModalConfirm();
        this.openAlert('Failed', 'Failed Delete Commission');
      });
  };

  openAlert = (header, message) =>
    this.setState({
      openModalAlert: true,
      headerModalAlert: header,
      messageModalAlert: message,
    });

  closeModalAlert = () =>
    this.setState({
      ...this.state,
      openModalAlert: false,
    });
  handleBack = () => {
    // eslint-disable-next-line
    this.props.history.push(
      `/Customer-Profile-Detail/detail/${this.props.match.params.Id}`
    );
  };
  render() {
    const {
      companyCommissions,
      loadingGetCompanyCommissions,
      loadingDeleteCommission,
      getCustomerProfileByCode,
    } = this.props;
    const {
      openModalCommission,
      typeModalCommission,
      idSelected,
      openModalConfirm,
      headerModalConfirm,
      messageModalConfirm,
      openModalAlert,
      headerModalAlert,
      messageModalAlert,
    } = this.state;
    return (
      <Segment
        style={{ marginTop: '30px', marginRight: '10px' }}
        loading={loadingGetCompanyCommissions}
      >
        <HeaderTitle iconName="money" title="Commission" />
        <Grid>
          <Grid.Row>
            <Grid.Column width={4} floated="left" textAlign="left">
              <Container style={{ marginTop: '0.5em' }}>
                Set Commission for{' '}
                <strong>{getCustomerProfileByCode.Name}</strong>
              </Container>
            </Grid.Column>
            <Grid.Column width={6} floated="right" textAlign="right">
              <Button primary onClick={this.handleBack}>
                Back
              </Button>
              <Button
                positive
                onClick={this.openModalCommission}
                id={null}
                value="add"
              >
                Add Commission
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Table basic="very" selectable fixed>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Valid Date</Table.HeaderCell>
                    <Table.HeaderCell>Currency</Table.HeaderCell>
                    <Table.HeaderCell>Commission Base</Table.HeaderCell>
                    <Table.HeaderCell>Commission</Table.HeaderCell>
                    <Table.HeaderCell>Min Pax</Table.HeaderCell>
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {companyCommissions &&
                    companyCommissions.map((commission, idx) => {
                      return (
                        <Table.Row key={idx}>
                          <Table.Cell>
                            {rangeDate(
                              commission.StartDate,
                              commission.EndDate
                            )}
                          </Table.Cell>
                          <Table.Cell>{commission.CurrencyId} </Table.Cell>
                          <Table.Cell>
                            {commission.CommBase === 0
                              ? 'Percentage (%)'
                              : 'Value'}
                          </Table.Cell>
                          <Table.Cell>
                            {formatCurrency(commission.CommValue)}
                          </Table.Cell>
                          <Table.Cell>{commission.MinimumPax} </Table.Cell>
                          <Table.Cell>
                            <Button
                              icon="edit"
                              primary
                              onClick={this.openModalCommission}
                              id={commission.Id}
                              value="edit"
                            />
                            <Button
                              icon="close"
                              negative
                              onClick={this.openModalConfirm}
                              value={commission.Id}
                            />
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  {companyCommissions && companyCommissions.length === 0 && (
                    <Table.Row>
                      <Table.Cell colSpan="6" textAlign="center">
                        No Data
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ModalCommission
          open={openModalCommission}
          type={typeModalCommission}
          handleClose={this.closeModalCommission}
          id={idSelected}
          resetListCommissions={this.resetListCommissions}
          companyCode={this.props.match.params.Id}
        />
        <ModalConfirm
          openModal={openModalConfirm}
          close={this.handleCloseModalConfirm}
          confirm={this.deleteCommission}
          message={messageModalConfirm}
          header={headerModalConfirm}
          loading={loadingDeleteCommission}
        />
        <ModalALert
          openModal={openModalAlert}
          handleClose={this.closeModalAlert}
          header={headerModalAlert}
          content={messageModalAlert}
          //   nameButton: PropTypes.string,
        />
      </Segment>
    );
  }
}
CompanyCommission.propTypes = {
  getCompanyCommissionsByCompanyCode: PropTypes.func,
  loadingGetCompanyCommissions: PropTypes.bool,
  companyCommissions: PropTypes.array,
  match: PropTypes.shape({
    params: PropTypes.shape({
      Id: PropTypes.string,
    }),
  }),
  loadingDeleteCommission: PropTypes.bool,
  deleteCompanyCommission: PropTypes.func,
  getCustomerProfileByCodeAction: PropTypes.func,
  getCustomerProfileByCode: PropTypes.object,
  getUserRoleByBearerAction: PropTypes.func,
  userRoles: PropTypes.object,
  getCustomerProfileByCodeManageBySalesAction: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    loadingGetCompanyCommissions:
      state.CompanyStore.loadingGetCompanyCommissions,
    companyCommissions: state.CompanyStore.companyCommissions,
    loadingDeleteCommission: state.CompanyStore.loadingDeleteCommission,
    getCustomerProfileByCode:
      state.CustomerProfileStore.getCustomerProfileByCode,
    userRoles: state.UserAccessStore.getUserRoleByBearer,
  };
}
export default connect(mapStateToProps, {
  getCompanyCommissionsByCompanyCode,
  deleteCompanyCommission,
  getCustomerProfileByCodeAction,
  getUserRoleByBearerAction,
  getCustomerProfileByCodeManageBySalesAction,
})(CompanyCommission);
