const newGuest = {
  FirstName: '',
  LastName: '',
  IdentityNbr: '',
  IdentityType: '',
  GuestTitle: '',
  GuestType: '',
  GuestCategory: '',
  CountryId: '',
  Id: '',
};

const copyObject = obj => {
  let newObj = JSON.stringify(obj);
  return JSON.parse(newObj);
};

const searchMaxId = guests => {
  let maxId =
    guests.length === 0
      ? 0
      : Math.max.apply(
          Math,
          guests.map(e => {
            return e.Id;
          })
        );
  return maxId;
};
const addGuest = (guests, category, type) => {
  let newGuests = copyObject(guests);
  let addedGuest = copyObject(newGuest);
  addedGuest.Id = searchMaxId(guests) + 1;
  addedGuest.GuestType = type;
  addedGuest.GuestCategory = category;
  newGuests.push(addedGuest);
  return newGuests;
};

const deleteGuest = (guests, index) => {
  let copyGuest = copyObject(guests);
  return copyGuest.filter((g, i) => i !== index);
};

const changeFirstName = (guests, index, val) => {
  let copyGuest = copyObject(guests);
  return copyGuest.map(guest => {
    if (guest.Id !== index) return guest;
    else return { ...guest, FirstName: val };
  });
};

const changeLastName = (guests, index, val) => {
  let copyGuest = copyObject(guests);
  return copyGuest.map(guest => {
    if (guest.Id !== index) return guest;
    else return { ...guest, LastName: val };
  });
};

const changeCountryId = (guests, index, val) => {
  let copyGuest = copyObject(guests);
  return copyGuest.map(guest => {
    if (guest.Id !== index) return guest;
    else return { ...guest, CountryId: val };
  });
};

const changeIdentityNo = (guests, index, val) => {
  let copyGuest = copyObject(guests);
  return copyGuest.map(guest => {
    if (guest.Id !== index) return guest;
    else return { ...guest, IdentityNbr: val };
  });
};

const changeIdentityType = (guests, index, val) => {
  let copyGuest = copyObject(guests);
  return copyGuest.map(guest => {
    if (guest.Id !== index) return guest;
    else return { ...guest, IdentityType: val };
  });
};

const changeTitleType = (guests, index, val) => {
  let copyGuest = copyObject(guests);
  return copyGuest.map(guest => {
    if (guest.Id !== index) return guest;
    else return { ...guest, GuestTitle: val };
  });
};
const changeIsDelete = (guests, index, val) => {
  let copyGuest = copyObject(guests);
  return copyGuest.map(guest => {
    if (guest.Id !== index) return guest;
    else return { ...guest, IsDelete: val };
  });
};
const addFewGuest = (guestList, category, total) => {
  let newGuests = copyObject(guestList);
  for (var i = 0; i < total; i++) {
    newGuests = addGuest(newGuests, category, 'TOURMEMBER');
  }
  return newGuests;
};
const removeFewGuest = (guestList, category, total) => {
  let removed = guestList.filter(g => g.GuestCategory === category);
  let rest = guestList.filter(g => g.GuestCategory !== category);
  removed.length = removed.length - total;
  return rest.concat(removed);
};
const generateGuest = (guestList, guests) => {
  let newguestList = copyObject(guestList);
  let adult = guestList.filter(g => g.GuestCategory === 'ADULT').length;
  let child = guestList.filter(g => g.GuestCategory === 'CHILDREN').length;
  let infant = guestList.filter(g => g.GuestCategory === 'INFANT').length;
  if (adult > guests.adults) {
    newguestList = removeFewGuest(newguestList, 'ADULT', adult - guests.adults);
  } else if (adult < guests.adults) {
    if (adult === 0) {
      newguestList = addGuest(newguestList, 'ADULT', 'TOURLEADER');
      newguestList = addFewGuest(
        newguestList,
        'ADULT',
        guests.adults - adult - 1
      );
    } else
      newguestList = addFewGuest(newguestList, 'ADULT', guests.adults - adult);
  }
  if (child > guests.childs) {
    newguestList = removeFewGuest(
      newguestList,
      'CHILDREN',
      child - guests.childs
    );
  } else if (child < guests.childs) {
    newguestList = addFewGuest(newguestList, 'CHILDREN', guests.childs - child);
  }
  if (infant > guests.infants) {
    newguestList = removeFewGuest(
      newguestList,
      'INFANT',
      infant - guests.infants
    );
  } else if (infant < guests.infants) {
    newguestList = addFewGuest(newguestList, 'INFANT', guests.infants - infant);
  }
  return newguestList;
};
const generateGuestTBA = list => {
  let newList = list.map(item => {
    if (item.GuestType === 'TOURLEADER') {
      item.FirstName = item.FirstName ? item.FirstName : 'TBA';
      item.LastName = item.LastName ? item.LastName : 'TBA';
      item.IdentityNbr = item.IdentityNbr ? item.IdentityNbr : 'TBA';
      item.IdentityType = item.IdentityType
        ? item.IdentityType
        : item.IdentityType;
      item.GuestTitle = item.GuestTitle ? item.GuestTitle : item.GuestTitle;
      item.GuestType = item.GuestType ? item.GuestType : item.GuestType;
      item.GuestCategory = item.GuestCategory
        ? item.GuestCategory
        : item.GuestCategory;
      item.CountryId = item.CountryId ? item.CountryId : item.CountryId;
    }
    return item;
  });
  return newList;
};
const changeGuest = (type, listGuest, index, value) => {
  let newListGuest = [];
  switch (type) {
    case 'identityType':
      newListGuest = changeIdentityType(listGuest, index, value);
      break;
    case 'identityNbr':
      newListGuest = changeIdentityNo(listGuest, index, value);
      break;
    case 'countryId':
      newListGuest = changeCountryId(listGuest, index, value);
      break;
    case 'guestTitle':
      newListGuest = changeTitleType(listGuest, index, value);
      break;
    case 'firstName':
      newListGuest = changeFirstName(listGuest, index, value);
      break;
    case 'lastName':
      newListGuest = changeLastName(listGuest, index, value);
      break;
    case 'IsDelete':
      newListGuest = changeIsDelete(listGuest, index, !value);
      break;
    default:
      break;
  }
  return newListGuest;
};
const sumValuesObject = obj => {
  return Object.values(obj).reduce((sum, current) => sum + current);
};
export {
  addGuest,
  deleteGuest,
  changeFirstName,
  changeLastName,
  changeCountryId,
  changeIdentityNo,
  changeIdentityType,
  changeTitleType,
  changeIsDelete,
  addFewGuest,
  removeFewGuest,
  generateGuest,
  copyObject,
  generateGuestTBA,
  changeGuest,
  sumValuesObject,
};
