import React from 'react';
import { Dimmer } from 'semantic-ui-react';
import { connect } from 'react-redux';
import ModalAlert from '../../../../components/modal/modalAlert';
import Loader from '../../../../components/loader';
import PropTypes from 'prop-types';
import TransportationUnit from './components/TransportationUnitForm';
import {
  getTransportationUnitHeaderByIdAction,
  resetTransportationAction,
  postUploadImageTransportationUnitAction,
  postSetPrimaryImageTransportaionUnitHeaderAction,
} from '../../../shared/actions/transportationAction';
import {
  getTransportationUnitByHeaderIdAction,
  postTransportationUnitHeaderAction,
  putTransporationUnitHeaderAction,
  getTransportationCatagoriesAction,
  postTransportationUnitAction,
  putTransportationUnitAction,
  deleteTrasportationUnitAction,
  deleteImageTransportationUnitAction,
} from '../../transportationAction';
import {
  getServicePriceAction,
  resetServicePriceAction,
  getServicePriceTypeAction,
  postServicePriceAction,
  putServicePriceAction,
  deleteServicePriceAction,
} from '../../../../actions/servicePriceAction';
import { getCurrenciesAction } from '../../../../actions/currenciesAction';
import { getCountryByIdAction } from '../../../shared/actions/countryAction';
import {
  getRegionListAction,
  getRegionAction,
} from '../../../shared/actions/regionAction';
import {
  getOriginCityListAction,
  getDestCityListAction,
} from '../../../shared/actions/cityAction';
import moment from 'moment';
import ModalImage from '../../../../components/modal/modalImage';
import { getMasterReferenceAction } from '../../../shared/actions/uploadMasterDataReferenceAction';
import ModalConfirm from '../../../../components/modal/modalConfirmationTwoButton';
// import { datasetPagination } from '../../../../scripts/pagination';
import ModalTransportationPriceDetail from './components/modal/modalTransportationPriceDetail';
import setDataPriceDetail from './services/data/setDataPriceDetail';
import {
  fieldToValidateTransportItem,
  fieldToValidatePriceDetail,
} from './constant/fieldToValidate';
import handleValidation from '../../../shared/services/validation/emptyFieldValidation';
import _ from 'lodash';
class TransportationUnitPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transportationUnit: {
        unitName: '',
        rating: '',
        description: '',
        code: '',
        capacities: '',
        transportationType: '',
        image: [],
      },
      transportationUnitTest: {},
      transportationUnitPriceList: [],
      loadingTransportationUnit: true,
      failedTransportationUnit: false,
      loadingTransportationUnitPrice: true,
      failedTransportationUnitPrice: false,
      modalConfirmation: false,
      transportationHeader: {
        Name: '',
        TransportationRatingId: '',
        Description: '',
        Code: '',
        TransportationSeatTypeId: '',
        TransportationTypeId: '',
        UnitImages: [],
      },
      openModalPrice: false,
      transportationPrice: {},
      transportationUnitByHeader: [],
      indexEdit: -1,
      errors: {},
      disablePage: false,
      listStepImage: [
        {
          Tittle: 'Choose Image',
          Active: true,
        },
        {
          Tittle: 'Select Thumbnail Image',
          Active: false,
        },
        {
          Tittle: 'Edit Thumbnail Image',
          Active: false,
        },
      ],
      indexActive: 0,
      image: [],
      activePageImage: 1,
      deleteImageList: [],
      tranportationPriceDetailTemporary: {
        Type: '',
        MinPax: '',
        Cost: '',
        Description: '',
        CutOffDay: '',
        Currency: '',
      },
      errorsPrice: {},
      activePage: 1,
      deletedTransportationUnit: [],
      deletedTransaportationPrice: [],
    };
  }
  static propTypes = {};

  handleChange = (e, { name, value }) => {
    let formFilter = this.state.formFilter;
    formFilter[name] = value;
    return this.setState({ formFilter });
  };
  handleChangePrice = async (e, { name, value }) => {
    name === 'OriginRegion' && this.props.getOriginCityListAction(value);
    name === 'DestinationRegion' && this.props.getDestCityListAction(value);
    let findCity =
      value !== 'ANYWHERE' &&
      (name === 'OriginRegion' || name === 'DestinationRegion')
        ? await this.props.listRegion.find(data => data.value === value)
        : await this.props.originCityList.find(data => data.value === value);
    let newValue = {
      Id: findCity ? findCity.value : value,
      Name: findCity ? findCity.text : 'Anywhere in Region',
    };
    let transportationPrice = this.state.transportationPrice;
    transportationPrice[name] = newValue;
    transportationPrice['onChange'] = true;
    this.setState({ transportationPrice: transportationPrice });
  };
  onChange = (e, { value, name }) => {
    const transportationUnit = this.state.transportationUnit;
    transportationUnit[name] = value;
    this.setState({ transportationUnit: transportationUnit });
  };
  handleClose = () =>
    this.setState({
      //openModalPrice: false,
      modalConfirmation: false,
      openConfirm: false,
      openModalImage: false,
      openConfirmImages: false,
    });

  handleCloseModalPrice = () => this.setState({ openModalPrice: false });

  renderingWithId = UnitID => {
    let { Type } = this.props.match.params;
    this.props.getTransportationUnitHeaderByIdAction(UnitID);
    this.props.getTransportationUnitByHeaderIdAction(UnitID);
    this.setState({
      ...this.state.transportationHeader,
      idTransportationItem: UnitID,
      titleHeader:
        Type === 'Detail'
          ? 'Detail Transportation Unit'
          : 'Edit Transportation Unit',
      contentButton: Type === 'Detail' ? 'Edit' : 'Save',
      disablePage: Type === 'Detail',
    });
  };
  componentDidMount = () => {
    this.props.getMasterReferenceAction('TransportationTypes');
    this.props.getTransportationCatagoriesAction();
    let { UnitID, ProfileId, CountryId } = this.props.match.params;
    this.props
      .getCountryByIdAction(CountryId)
      .then(res => this.setState({ countryName: res.value.data.Name }));
    if (UnitID) {
      this.renderingWithId(UnitID);
    } else {
      this.setState({
        idTransportationItem: '',
        titleHeader: 'Add Transportation Unit',
        contentButton: 'Save',
        transportationHeader: {
          ...this.state.transportationHeader,
          TransportationProfileId: ProfileId,
        },
        loadingTransportationUnit: false,
        loadingTransportationUnitPrice: false,
        disablePage: false,
      });
    }
    this.props.getServicePriceTypeAction('Transportation');
    this.props.getCurrenciesAction();
  };
  componentDidUpdate = () => {
    if (this.props.getTransportationUnitByHeaderIdStatus === 'success') {
      const unit = this.props.transportationHeader;
      this.setState({
        transportationHeader: unit,
        loadingTransportationUnit: false,
      });

      this.props.resetTransportationAction();
    }
    if (this.props.getTransportationUnitHeaderByIdStatus === 'success') {
      this.setState({ loadingTransportationUnitPrice: false });
      this.setTransportationUnit(this.props.transportationUnitByHeader);
      this.props.resetTransportationAction();
    }
    // if (this.props.getSupplierPriceStatus === 'success') {
    //   const priceList = this.props.supplierPriceList;
    //   let dataPrice = [];
    //   priceList.map(price => {
    //     let data = {
    //       Id: price.Id,
    //       Type: price.ServiceType,
    //       // ServicePriceType: price.ServiceType,
    //       ServiceDescription: price.CalculationBase.Name,
    //       Description: price.CalculationBase.Name,
    //       MinPax: price.CalculationBase.Code,
    //       //MinPax: price.CalculationBase.Code,
    //       ServiceItemId: price.ServiceItemId,
    //       Currency: price.Currency,
    //       //Price: price.Cost,
    //       Cost: price.Cost,
    //       StartDate: moment(price.StartDate).format('YYYY-MM-DD'),
    //       EndDate: moment(price.EndDate).format('YYYY-MM-DD'),
    //       CutOffDay: price.CutOffDay,
    //       UomId: 'unt',
    //       Rank: 1,
    //       MinimumPax: 0,
    //       TaxTypeId: 0,
    //     };
    //     return dataPrice.push(data);
    //   });
    //   this.setState({
    //     transportationPrice: {
    //       ...this.state.transportationPrice,
    //       PriceDetails: dataPrice,
    //     },
    //   });
    //   this.setState({ openModalPrice: true });
    //   this.props.resetServicePriceAction();
    // }

    if (this.props.postTransportationUnitStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Saving Transportation Unit Successfully',
        modalConfirmation: true,
        loadingTransportationUnit: false,
        transportationPrice: {
          ServiceItemId: this.props.postTransportationUnit.ServiceItemId,
        },
      });
      let { CountryId, UnitID } = this.props.match.params;
      //eslint-disable-next-line
      this.props.history.push(
        `/Transportation-Unit/Edit/by-ProfileId=${
          this.props.postTransportationUnit.TransportationProfileId
        }/by-Country=${CountryId}/by-UnitId=${this.props.postTransportationUnit
          .Id || UnitID}`
      );
      this.props.resetTransportationAction();
    } else if (this.props.postTransportationUnitStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorsTransportation.global,
        modalConfirmation: true,
        loadingTransportationUnit: false,
      });

      this.props.resetTransportationAction();
    }

    if (this.props.putTransportationUnitStatus === 'success') {
      let { UnitID } = this.props.match.params;
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Saving Transportation Unit Successfully',
        modalConfirmation: true,
        loadingTransportationUnit: false,
      });
      this.props.resetTransportationAction();
      this.renderingWithId(UnitID);
    } else if (this.props.putTransportationUnitStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: 'Failed Edit Transportation',
        modalConfirmation: true,
        loadingTransportationUnit: false,
      });
      this.props.resetTransportationAction();
    }
    if (this.props.postSupplierPriceStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Saving Price Successfully',
        modalConfirmation: true,
        loadingTransportationUnit: false,
      });
      let { UnitID } = this.props.match.params;
      this.renderingWithId(UnitID);
      this.props.resetServicePriceAction();
    } else if (this.props.postSupplierPriceStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: 'Failed Edit Transportation',
        modalConfirmation: true,
        loadingTransportationUnit: false,
      });
      this.props.resetServicePriceAction();
    }
    if (this.props.postTransportationHeaderStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Saving Transportation Successfully',
        modalConfirmation: true,
        loadingTransportationUnit: false,
      });
      const {
        TransportationProfileId,
        Id,
      } = this.props.postTransportationHeader;
      const { CountryId } = this.props.match.params;
      const { transportationHeader } = this.state;
      this.props.history.push(
        `/Transportation-Unit/Edit/by-ProfileId=${TransportationProfileId}/by-Country=${CountryId}/by-UnitId=${Id}`
      );
      if (transportationHeader.UnitImages.length > 0) {
        transportationHeader.UnitImages.map(data => {
          return data.onChange
            ? this.props
                .postUploadImageTransportationUnitAction(Id, {
                  ImageContent: data.ImageContent,
                  ImageName: data.ImageName,
                  TransportationUnitHeaderId: Id,
                  IsPrimaryImage: data.IsPrimaryImage,
                })
                .then(this.renderingWithId(Id))
                .catch(() => this.handleFailedMessage())
            : null;
        });
      }
      // this.reloadPage();
      this.props.resetTransportationAction();
    } else if (this.props.postTransportationHeaderStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: 'Failed Saving Transportation',
        modalConfirmation: true,
        loadingTransportationUnit: false,
      });
      this.props.resetTransportationAction();
    }
    if (this.props.putTransportationHeaderStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Saving Transportation Successfully',
        modalConfirmation: true,
        loadingTransportationUnit: false,
      });
      let { UnitID } = this.props.match.params;
      this.renderingWithId(UnitID);
      this.props.resetTransportationAction();
    } else if (this.props.putTransportationHeaderStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: 'Failed Edit Transportation',
        modalConfirmation: true,
        loadingTransportationUnit: false,
      });
      this.props.resetTransportationAction();
    }
    if (this.props.putSupplierPriceStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Edit Price Successfully',
        modalConfirmation: true,
        loadingTransportationUnit: false,
      });
      let { UnitID } = this.props.match.params;
      this.props.getTransportationUnitHeaderByIdAction(UnitID);
      this.props.getTransportationUnitByHeaderIdAction(UnitID);
      this.props.resetServicePriceAction();
    } else if (this.props.putSupplierPriceStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorMessage,
        modalConfirmation: true,
        loadingTransportationUnit: false,
      });
      this.props.resetServicePriceAction();
    }
    // if (this.props.deleteSupplierPriceStatus === 'success') {
    //   this.setState({
    //     headerAlert: 'Success',
    //     contentAlert: 'the price of the service was successfully deleted',
    //     modalConfirmation: true,
    //     loadingTransportationUnit: false,
    //   });
    //   let { UnitID } = this.props.match.params;
    //   this.props.getTransportationUnitHeaderByIdAction(UnitID);
    //   this.props.getTransportationUnitByHeaderIdAction(UnitID);
    //   //this.props.getServicePriceAction(data.ServiceItemId);
    //   this.props.resetServicePriceAction();
    // } else if (this.props.deleteSupplierPriceStatus === 'failed') {
    //   this.setState({
    //     headerAlert: 'Failed',
    //     contentAlert: this.props.errorMessage,
    //     modalConfirmation: true,
    //     loadingTransportationUnit: false,
    //   });
    //   this.props.resetServicePriceAction();
    // }
    if (this.props.deleteTransportationUnitStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        // contentAlert: 'transportation unit was successfully deleted',
        contentAlert: 'Saving Transportation Successfully',
        modalConfirmation: true,
        loadingTransportationUnit: false,
        deletedTransportationUnit: [],
      });
      let { UnitID } = this.props.match.params;
      this.props.getTransportationUnitHeaderByIdAction(UnitID);
      this.props.getTransportationUnitByHeaderIdAction(UnitID);
      this.props.resetTransportationAction();
    } else if (this.props.deleteTransportationUnitStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorMessage,
        modalConfirmation: true,
        loadingTransportationUnit: false,
      });
      this.props.resetTransportationAction();
    }
  };
  setTransportationUnit = unit => {
    let unitPrice = [];
    unit.map(newUnit => {
      let newObject = {
        DestinationRegion: newUnit.DestinationRegion,
        DestinationCity: newUnit.DestinationCity,
        OriginRegion: newUnit.OriginRegion,
        OriginCity: newUnit.OriginCity,
        PriceDetails: [],
        ServiceItemId: newUnit.ServiceItemId,
        onChange: false,
      };
      return unitPrice.push(newObject);
      // return unitPrice = this.setPriceDetail(newObject, serviceItemId )
    });
    this.setPriceDetail(unitPrice);
    // this.setState({ transportationUnitByHeader: unitPrice });
  };

  setPriceDetail = async object => {
    // let newObject = object;
    let priceDetails = {};
    await object.map(data => {
      this.props.getServicePriceAction(data.ServiceItemId).then(res => {
        res.value.data.map(price => {
          // priceDetails =
          // res.value.data.length > 0 ? setDataPriceDetail(res.value.data) : [];
          priceDetails = setDataPriceDetail(price);
          if (data.ServiceItemId === priceDetails.ServiceItemId) {
            data.PriceDetails.push(priceDetails);
          }
          return price;
        });
      });
      return data;
    });
    this.setState({ transportationUnitByHeader: object });
  };
  onClickBack = () => {
    // eslint-disable-next-line
    this.props.history.push('/Transportation');
  };
  addPriceDetails = () => {
    let transportationPrice = this.state.transportationPrice;
    this.setState({ transportationPrice: transportationPrice });
    let priceDetails = this.state.transportationPrice.PriceDetails;
    const price = {
      //Id: 0,
      Type: '',
      //ServicePriceType: '',
      ServiceDescription: '',
      MinPax: '',
      Cost: 0,
      Description: '',
      //MinPax: 0,
      ServiceItemId: transportationPrice.ServiceItemId,
      Currency: '',
      //Price: 0,
      StartDate: moment(),
      EndDate: moment(),
      CutOffDay: 0,
      UomId: 'UNT',
      Rank: 1,
      MinimumPax: 0,
      TaxTypeId: 0,
      onChange: true,
      post: true,
    };
    priceDetails.push(price);
    this.setState({
      transportationPrice: {
        ...this.state.transportationPrice,
        PriceDetails: priceDetails,
      },
    });
  };
  handleClickEdit = (e, { name, id }) => {
    this.props.getRegionAction();
    // const unitDetails = this.state.transportationUnitByHeader[name];
    const unitDetails = this.state.transportationUnitByHeader.find(
      data => data.ServiceItemId === id
    );
    const {
      DestinationRegion,
      OriginRegion,
      // ServiceItemId,
      // PriceDetails,
    } = unitDetails;
    this.props.getDestCityListAction(DestinationRegion.Id);
    this.props.getOriginCityListAction(OriginRegion.Id);
    this.setState({
      transportationPrice: unitDetails,
      indexEdit: name,
      headerModal: 'Edit',
      openModalPrice: true,
    });
    // if (ServiceItemId !== undefined) {
    //   this.props.getServicePriceAction(ServiceItemId);
    // } else {
    //   this.setTransportationPrice(PriceDetails);
    // }
    // this.setTransportationPrice(PriceDetails);
  };
  addPriceToList = () => {
    let price = this.state.transportationPrice;
    let header = this.state.transportationUnitByHeader;
    let index = this.state.indexEdit;
    let editField = index >= 0;
    let isValid = false;
    const { ServiceItemId, Add } = price;
    header.map(data => {
      if (
        data.OriginRegion.Id === price.OriginRegion.Id &&
        data.DestinationRegion.Id === price.DestinationRegion.Id &&
        data.DestinationCity.Id === price.DestinationCity.Id &&
        data.OriginCity.Id === price.OriginCity.Id
      ) {
        // Add === undefined ? (isValid = false) : (isValid = true);
        Add === undefined
          ? (isValid = false)
          : editField
          ? (isValid = false)
          : (isValid = true);
      }
      return data;
    });
    if (price.PriceDetails.length > 0) {
      if (ServiceItemId) {
        if (price.DestinationRegion === '' || price.OriginRegion === '') {
          this.setState({
            modalConfirmation: true,
            headerAlert: 'Information',
            contentAlert:
              'Please fill in the origin region and destination region',
          });
        } else {
          if (isValid) {
            this.setState({
              modalConfirmation: true,
              headerAlert: 'Information',
              contentAlert: 'the route you entered already exists',
            });
          } else {
            header[index] = price;
            this.setState({
              openModalPrice: false,
              transportationUnitByHeader: header,
            });
          }
        }
      } else {
        if (price.DestinationRegion === '' || price.OriginRegion === '') {
          this.setState({
            modalConfirmation: true,
            headerAlert: 'Information',
            contentAlert:
              'Please fill in the origin region and destination region',
          });
        } else {
          if (isValid) {
            this.setState({
              modalConfirmation: true,
              headerAlert: 'Information',
              contentAlert: 'the data you entered already exists',
            });
          } else {
            if (editField) {
              header[index] = price;
            } else {
              header.push(price);
            }
            this.setState({
              transportationUnitByHeader: header,
              openModalPrice: false,
            });
          }
        }
      }
    } else {
      this.setState({
        modalConfirmation: true,
        headerAlert: 'Information',
        contentAlert: 'Please fill minimum 1 of data in a  price table ',
      });
    }
  };
  addNew = () => {
    // const { CountryId } = this.props.match.params;
    // const { ServiceItemId } = this.state.transportationPrice;
    // this.props.getRegionListAction(CountryId);
    this.props.getRegionAction();
    let destinationDestructure = [
      {
        Id: 'ANYWHERE',
        Name: 'Anywhere in region',
      },
    ];

    const price = {
      OriginRegion: '',
      //OriginCity: '',
      DestinationRegion: '',
      //DestinationCity: '',
      DestinationCity: destinationDestructure,
      OriginCity: destinationDestructure,
      PriceDetails: [],
      // ServiceItemId: ServiceItemId,
      onChange: true,
      Add: true,
    };
    this.setState({
      transportationPrice: price,
      headerModal: 'Add New',
      openModalPrice: true,
      indexEdit: -1,
    });
  };
  handleChangePriceDetails = (e, { value, name }) => {
    let transportationPrice = this.state.transportationPrice;
    let priceDetails = transportationPrice.PriceDetails;
    let i = name.split(',')[1];
    let newName = name.split(',')[0];
    priceDetails[i] = { ...priceDetails[i], [newName]: value, onChange: true };
    this.setState({
      transportationPrice: {
        ...this.state.transportationPrice,
        PriceDetails: priceDetails,
        onChange: true,
      },
    });
  };

  handleChangeCostPriceDetails = (value, i, name) => {
    let transportationPrice = this.state.transportationPrice;
    let priceDetails = transportationPrice.PriceDetails;
    priceDetails[i] = { ...priceDetails[i], [name]: value, onChange: true };
    this.setState({
      transportationPrice: {
        ...this.state.transportationPrice,
        PriceDetails: priceDetails,
        onChange: true,
      },
    });
  };

  // modal Image
  addImage = () => {
    let objectImage = this.state.transportationHeader;
    let image = this.state.image;
    objectImage.UnitImages.push(image);
    let listStepImage = this.state.listStepImage;
    listStepImage.map((step, i) => {
      step['Active'] = i === 0 ? true : false;
      return listStepImage;
    });
    this.setState({
      transportationHeader: objectImage,
      openModalImage: false,
      listStepImage: listStepImage,
      indexActive: 0,
    });
  };
  changeImage = e => {
    let { UnitID } = this.props.match.params;
    let { transportationHeader } = this.state;
    let imageList = transportationHeader.UnitImages;
    e.map(base => {
      let image = {
        Id: 0,
        ImageContent: base.base64.split(',')[1],
        ImagePreview: base.base64,
        ImageName: base.file.name,
        IsPrimaryImage: false,
        TransportationUnitHeaderId: UnitID,
        onChange: true,
      };
      return imageList.push(image);
    });
    this.setState({
      transportationHeader: {
        ...transportationHeader,
        UnitImages: imageList,
        onChange: true,
      },
    });
  };
  openDetail = (e, { value }) => {
    const { UnitImages } = this.state.transportationHeader;
    let listStepImage = this.state.listStepImage;
    listStepImage.map((step, i) => {
      step['Active'] = i === 0 ? true : false;
      return listStepImage;
    });
    let image = UnitImages[value];
    this.setState({
      image: image,
      indexActive: 0,
      listStepImage: listStepImage,
      openModalImage: true,
      // imageUrl: image.ImageContent //ImageUrl
      //   ? image.ImageContent //ImageUrl
      //   : URL.createObjectURL(e.target.files[0]),
    });
  };
  reloadPage = () => {
    window.location.reload();
  };
  handleChangePrimaryImage = (e, { name }) => {
    let listImage = this.state.transportationHeader;
    let newList = [];
    listImage.UnitImages.map((img, i) => {
      img['IsPrimaryImage'] = i === name ? true : false;
      img['onChange'] = true;
      return newList.push(img);
    });
    this.setState({
      transportationHeader: {
        ...this.state.transportationHeader,
        UnitImages: newList,
        onChange: true,
      },
    });
  };
  handleNext = () => {
    let indexActive = this.state.indexActive;
    indexActive = indexActive + 1;
    let listStepImage = this.state.listStepImage;
    listStepImage.map((step, i) => {
      step['Active'] = i === indexActive ? true : false;
      return listStepImage;
    });
    if (indexActive === 2) {
      let listImage = this.state.transportationHeader;
      listImage.UnitImages.map(img => {
        return img.IsPrimaryImage && this.setState({ image: img });
      });
    }
    if (indexActive === 3) {
      return this.setState({ indexActive: 0, openModalImage: false });
    }
    this.setState({ listStepImage: listStepImage, indexActive: indexActive });
  };
  handleBackStep = () => {
    let indexActive = this.state.indexActive;
    indexActive = indexActive - 1;
    let listStepImage = this.state.listStepImage;
    listStepImage.map((step, i) => {
      step['Active'] = i === indexActive ? true : false;
      return listStepImage;
    });
    this.setState({ listStepImage: listStepImage, indexActive: indexActive });
  };
  //---------------====---
  handleChangeUnitHeader = (e, { value, name }) => {
    const transportationHeader = this.state.transportationHeader;
    transportationHeader[name] = value;
    transportationHeader['onChange'] = true;
    let errors = handleValidation(
      transportationHeader,
      fieldToValidateTransportItem
    );
    this.setState({
      transportationHeader: transportationHeader,
      errors: errors,
    });
  };
  validate = () => {
    let isError = false;
    const errors = handleValidation(
      this.state.transportationHeader,
      fieldToValidateTransportItem
    );
    isError = !_.isEmpty(errors);
    this.setState({
      errors: {
        ...errors,
      },
    });
    return isError;
  };

  saveTransportationHeader = (e, { name }) => {
    if (name === 'Edit') {
      this.setState({
        titleHeader: 'Edit Transportation',
        contentButton: 'Save',
        disablePage: false,
      });
    } else {
      const {
        transportationHeader,
        // transportationPrice,
        deleteImageList,
        transportationUnitByHeader,
        deletedTransaportationPrice,
        deletedTransportationUnit,
      } = this.state;
      let { UnitID, ProfileId } = this.props.match.params;
      const err = this.validate();
      if (transportationHeader.onChange) {
        if (!err) {
          this.setState({ loadingTransportationUnit: true });
          if (UnitID) {
            this.props.putTransporationUnitHeaderAction(
              UnitID,
              transportationHeader
            );
            if (transportationHeader.UnitImages.length > 0) {
              transportationHeader.UnitImages.map(data => {
                return data.onChange
                  ? Number(data.Id) === 0
                    ? this.props
                        .postUploadImageTransportationUnitAction(UnitID, {
                          ImageContent: data.ImageContent,
                          ImageName: data.ImageName,
                          TransportationUnitHeaderId: UnitID,
                          IsPrimaryImage: data.IsPrimaryImage,
                        })
                        .then(
                          this.setState({
                            headerAlert: 'Success',
                            contentAlert: 'Saving Transportation Successfully',
                            modalConfirmation: true,
                          })
                        )
                        .catch(() => this.handleFailedMessage())
                    : data.IsPrimaryImage &&
                      this.handleSetPrimaryImageAction(data.Id, UnitID, data)
                  : null;
              });
            }
          } else {
            this.props.postTransportationUnitHeaderAction(transportationHeader);
          }
        }
      }
      transportationUnitByHeader.map(price => {
        if (price.onChange) {
          price.PriceDetails.map(data => {
            if (data.onChange) {
              let transportationUnit = {
                ServiceItemId: price.ServiceItemId,
                Qty: 1,
                TransportationUnitImages: [],
                Code: transportationHeader.Code,
                Name: transportationHeader.Name,
                Description: transportationHeader.Description,
                TransportationRatingId:
                  transportationHeader.TransportationRatingId,
                TransportationTypeId: transportationHeader.TransportationTypeId,
                TransportationSeatTypeId:
                  transportationHeader.TransportationSeatTypeId,
                TransportationProfileId: ProfileId,
                ImageName: null,
                ImageContent: null,
                RegionId: price.DestinationRegion.Id
                  ? price.DestinationRegion.Id
                  : null,
                CityId: price.DestinationCity.Id,
                OriginRegionId: price.OriginRegion.Id
                  ? price.OriginRegion.Id
                  : null,
                OriginCityId: price.OriginCity.Id,
              };
              if (!err) {
                this.setState({ loadingTransportationUnit: true });
                if (price.ServiceItemId) {
                  this.props.putTransportationUnitAction(
                    price.ServiceItemId,
                    transportationUnit
                  );
                  this.saveTransportationPrice(price.ServiceItemId);
                } else {
                  this.state.transportationUnitByHeader.map(data => {
                    let test = {
                      ServiceItemId: data.ServiceItemId,
                      Qty: 1,
                      TransportationUnitImages: [],
                      Code: transportationHeader.Code,
                      Name: transportationHeader.Name,
                      Description: transportationHeader.Description,
                      TransportationRatingId:
                        transportationHeader.TransportationRatingId,
                      TransportationTypeId:
                        transportationHeader.TransportationTypeId,
                      TransportationSeatTypeId:
                        transportationHeader.TransportationSeatTypeId,
                      TransportationProfileId: ProfileId,
                      ImageName: null,
                      ImageContent: null,
                      RegionId: data.DestinationRegion.Id
                        ? data.DestinationRegion.Id
                        : null,
                      CityId: data.DestinationCity.Id,
                      OriginRegionId: data.OriginRegion.Id
                        ? data.OriginRegion.Id
                        : null,
                      OriginCityId: data.OriginCity.Id,
                    };
                    if (data.ServiceItemId === undefined) {
                      this.props
                        .postTransportationUnitAction(test)
                        .then(res =>
                          this.saveTransportationPrice(
                            res.value.data.ServiceItemId
                          )
                        );
                    }
                    return data;
                  });
                  // this.props.postTransportationUnitAction(transportationUnit);
                }
              }
            }
            return data;
          });
        }
        return price;
      });

      if (deleteImageList.length > 0) {
        deleteImageList.map(values =>
          this.props
            .deleteImageTransportationUnitAction(values)
            .then(() =>
              this.setState({
                headerAlert: 'Success',
                contentAlert: 'Saving Transportation Successfully',
                modalConfirmation: true,
                deleteImageList: [],
              })
            )
            .catch(() => this.handleFailedMessage())
        );
      }

      deletedTransportationUnit.length > 0 &&
        deletedTransportationUnit.map(data => {
          this.props.deleteTrasportationUnitAction(data);
          return data;
        });

      deletedTransaportationPrice.length > 0 &&
        deletedTransaportationPrice.map(data => {
          this.props
            .deleteServicePriceAction(data)
            .then(res => this.handleResponseDeleteServicePrice(res.value.data))
            .catch(() =>
              this.setState({
                headerAlert: 'Failed',
                contentAlert: this.props.errorMessage,
                modalConfirmation: true,
                loadingTransportationUnit: false,
              })
            );
          return data;
        });
    }
  };

  handleFailedMessage = () => {
    this.setState({
      headerAlert: 'Failed',
      contentAlert: 'transportation unit failed to save',
      modalConfirmation: true,
    });
  };

  saveTransportationPrice = ServiceItemId => {
    // const { transportationPrice } = this.state;
    // transportationPrice.PriceDetails.map(data => {
    const { transportationUnitByHeader } = this.state;
    let dataDesctructure = {};
    transportationUnitByHeader.map(data => {
      if (data.onChange) {
        data.PriceDetails.map(price => {
          dataDesctructure = {
            ...price,
            ServiceItemId: ServiceItemId,
            Hours: price.MinPax,
            ServiceDescription: price.Description,
            ServicePriceType: price.Type,
          };
          if (dataDesctructure.post) {
            this.setState({ loadingTransportationUnit: true });
            this.props.postServicePriceAction(
              'Transportation',
              dataDesctructure
            );
          } else {
            this.setState({ loadingTransportationUnit: true });
            this.props.putServicePriceAction(
              'Transportation',
              price.Id,
              dataDesctructure
            );
          }
          return price;
        });
        // let dataDesctructure = {
        //   ...data.PriceDetails,
        //   ServiceItemId: ServiceItemId,
        //   Hours: data.MinPax,
        //   ServiceDescription: data.Description,
        //   ServicePriceType: data.Type,
        // };
        // if (dataDesctructure.post) {
        //   this.setState({ loadingTransportationUnit: true });
        //   this.props.postServicePriceAction('Transportation', dataDesctructure);
        // } else {
        //   this.setState({ loadingTransportationUnit: true });
        //   this.props.putServicePriceAction(
        //     'Transportation',
        //     data.Id,
        //     dataDesctructure
        //   );
        // }
      }
      return data;
    });
  };
  onClickBack = () => {
    // eslint-disable-next-line
    const { ProfileId } = this.props.match.params;
    this.props.history.push(`/Transportation-Profile/Edit/${ProfileId}`);
  };
  handleClickDelete = () => {
    const {
      IdDeleteServicePrice,
      deleted,
      transportationUnitByHeader,
      indexDeleteTransportationHeader,
      indexDeleteTransportationPrice,
      transportationPrice,
      deletedTransaportationPrice,
      deletedTransportationUnit,
    } = this.state;
    this.setState({
      //loadingTransportationUnit: true,
      openConfirm: false,
      activePage: 1,
    });
    if (deleted === 'unit') {
      if (IdDeleteServicePrice === undefined) {
        transportationUnitByHeader.splice(indexDeleteTransportationHeader, 1);
      } else {
        this.setState({});
        let modif = transportationUnitByHeader.filter(
          data => data.ServiceItemId !== IdDeleteServicePrice
        );
        // transportationUnitByHeader.splice(indexDeleteTransportationHeader, 1);
        deletedTransportationUnit.push(IdDeleteServicePrice);
        // this.props.deleteTrasportationUnitAction(IdDeleteServicePrice);
        this.setState({
          transportationUnitByHeader: modif,
          loadingTransportationUnit: true,
        });
      }
    } else {
      if (IdDeleteServicePrice === undefined) {
        transportationPrice.PriceDetails.splice(
          indexDeleteTransportationPrice,
          1
        );
      } else {
        this.setState({
          loadingTransportationUnit: true,
        });
        transportationPrice.PriceDetails.splice(
          indexDeleteTransportationPrice,
          1
        );
        deletedTransaportationPrice.push(IdDeleteServicePrice);
        // this.props
        //   .deleteServicePriceAction(IdDeleteServicePrice)
        //   .then(res => this.handleResponseDeleteServicePrice(res.value.data))
        //   .catch(() =>
        //     this.setState({
        //       headerAlert: 'Failed',
        //       contentAlert: this.props.errorMessage,
        //       modalConfirmation: true,
        //       loadingTransportationUnit: false,
        //     })
        //   );
      }
    }
  };
  handleResponseDeleteServicePrice = () => {
    this.setState({
      // headerAlert: 'Success',
      // contentAlert: 'The price of the service was successfully deleted',
      headerAlert: 'Success',
      contentAlert: 'Saving Transportation Successfully',
      modalConfirmation: true,
      loadingTransportationUnit: false,
      activePage: 1,
      deletedTransaportationPrice: [],
    });
    let { UnitID } = this.props.match.params;
    this.props.getTransportationUnitHeaderByIdAction(UnitID);
    this.props.getTransportationUnitByHeaderIdAction(UnitID);
    // this.props.getServicePriceAction(data.ServiceItemId);
    // this.setPriceDetail;
  };
  handleConfirmDelete = (e, { id, value, name }) => {
    // let data = this.state.transportationUnitByHeader.find(data => {
    //   if (data.ServiceItemId == id) {
    //     return data.PriceDetails;
    //   }
    // });
    if (
      this.state.transportationUnitByHeader.length === 0 &&
      this.state.transportationPrice.PriceDetails.length === 0
    ) {
      this.setState({
        IdDeleteServicePrice: id,
        openConfirm: true,
        messageConfirm:
          id === undefined
            ? 'Are you sure to delete transportation unit ?'
            : 'Are you sure to delete transportation unit with route  ',
        deleted: value,
        indexDeleteTransportationHeader: name,
      });
    } else {
      this.setState({
        IdDeleteServicePrice: id,
        openConfirm: true,
        // messageConfirm:
        //   id === undefined
        //     ? 'Are you sure to delete transportation price ?'
        //     : 'Are you sure to delete transportation price with type  ' +
        //       data.Type +
        //       ' and cost ' +
        //       data.Cost +
        //       ' ?',
        messageConfirm: 'Are you sure to delete this transportation price ?',
        deleted: value,
        indexDeleteTransportationPrice: name,
      });
    }
  };

  // setTransportationPrice = data => {
  //   const priceList = data;
  //   let dataPrice = [];
  //   priceList.map(price => {
  //     let data = {
  //       Id: price.Id,
  //       Type: price.Type,
  //       // ServicePriceType: price.ServiceType,
  //       //ServiceDescription: price.CalculationBase.Name,
  //       Description: price.Description,
  //       MinPax: price.MinPax,
  //       //MinPax: price.CalculationBase.Code,
  //       //ServiceItemId: price.ServiceItemId,
  //       Currency: price.Currency,
  //       //Price: price.Cost,
  //       Cost: price.Cost,
  //       StartDate: moment(price.StartDate).format('YYYY-MM-DD'),
  //       EndDate: moment(price.EndDate).format('YYYY-MM-DD'),
  //       CutOffDay: price.CutOffDay,
  //       UomId: 'unt',
  //       Rank: 1,
  //       MinimumPax: 0,
  //       TaxTypeId: 0,
  //     };
  //     return dataPrice.push(data);
  //   });
  //   this.setState({
  //     transportationPrice: {
  //       ...this.state.transportationPrice,
  //       PriceDetails: dataPrice,
  //     },
  //   });
  //   this.setState({ openModalPrice: true });
  // };

  modalItemAction = () => {
    this.setState({
      contentModal: 'Image',
      openModalImage: true,
      dataItem: [],
    });
  };

  deleteConfirmation = (e, { name }) => {
    let { UnitImages } = this.state.transportationHeader;
    let id = UnitImages[name].Id ? UnitImages[name].Id : 0;
    let imageName = UnitImages[name].ImageName
      ? UnitImages[name].ImageName
      : '';
    this.setState({
      ImageId: id,
      indexImageDelete: name,
      openConfirmImages: true,
      messageConfirm: 'Are you sure to delete image  ' + imageName + ' ?',
      isDeleteImage: true,
    });
  };
  pageChangeImage = (e, { activePage }) => {
    this.setState({ activePageImage: activePage });
  };
  deleteImageTransportation = idx => {
    const { transportationHeader, deleteImageList } = this.state;
    let unitImages = [...transportationHeader.UnitImages];
    let imageId = transportationHeader.UnitImages[idx].Id
      ? transportationHeader.UnitImages[idx].Id
      : 0;
    if (imageId) deleteImageList.push(imageId);
    unitImages = unitImages.filter((item, i) => i !== idx);
    transportationHeader.UnitImages = unitImages;
    this.setState({
      //transportationHeader: transportationHeader,
      transportationHeader: {
        ...transportationHeader,
        UnitImages: unitImages,
        onChange: true,
      },
      activePageImage: 1,
      openConfirmImages: false,
      deleteImageList: deleteImageList,
    });
  };

  openModalPriceDetailFunc = () => {
    let transportationPrice = this.state.transportationPrice;
    const price = {
      Type: '',
      ServiceDescription: '',
      MinPax: '',
      Cost: 0,
      Description: '',
      ServiceItemId: transportationPrice.ServiceItemId,
      Currency: '',
      StartDate: moment(new Date()).format('YYYY-MM-DD'),
      EndDate: moment(new Date()).format('YYYY-MM-DD'),
      CutOffDay: 0,
      UomId: 'UNT',
      Rank: 1,
      MinimumPax: 0,
      TaxTypeId: 0,
      onChange: true,
      post: true,
    };
    this.setState({
      openModalPriceDetail: true,
      tranportationPriceDetailTemporary: price,
      editPrice: false,
    });
  };

  handlCloseModalPriceDetail = () => {
    this.setState({ openModalPriceDetail: false });
  };

  onChangeModalPriceDetails = async (e, { value, name }) => {
    const { tranportationPriceDetailTemporary } = this.state;
    let transportationPrice = { ...tranportationPriceDetailTemporary };
    transportationPrice[name] = value;
    transportationPrice['onChange'] = true;
    await this.setState({
      tranportationPriceDetailTemporary: transportationPrice,
    });
    this.validatePrice();
  };

  handleChangePriceDate = (value, name) => {
    const { tranportationPriceDetailTemporary, errors } = this.state;
    let transportationPrice = tranportationPriceDetailTemporary;
    let valueConvert = moment(value).format('YYYY-MM-DD');
    // let today = moment(new Date()).format('YYYY-MM-DD');
    let startDate = moment(transportationPrice.StartDate).format('YYYY-MM-DD');
    if (name === 'StartDate') {
      transportationPrice[name] = moment(value).format('YYYY-MM-DD');
      transportationPrice['onChange'] = true;
    } else if (name === 'EndDate') {
      if (moment(valueConvert).isSameOrBefore(startDate)) {
        this.setState({
          errors: {
            ...errors,
            EndDate: 'Date must be in the future Start Date',
          },
        });
      } else {
        transportationPrice[name] = moment(value).format('YYYY-MM-DD');
        transportationPrice['onChange'] = true;
        this.setState({
          errors: {
            ...errors,
            EndDate: '',
          },
        });
      }
      transportationPrice[name] = moment(value).format('YYYY-MM-DD');
      transportationPrice['onChange'] = true;
    } else {
      transportationPrice[name] = value;
      transportationPrice['onChange'] = true;
    }
    this.setState({
      tranportationPriceDetailTemporary: transportationPrice,
    });
  };

  messageErrorPriceDetails = () => {
    this.setState({
      contentAlert: 'Please fill in the price list',
      modalConfirmation: true,
      headerAlert: 'Information',
    });
  };

  savePriceDetail = () => {
    const {
      indexEditPrice,
      editPrice,
      tranportationPriceDetailTemporary,
      transportationPrice,
    } = this.state;
    let temporaryPriceDetail = tranportationPriceDetailTemporary;
    let priceDetails = transportationPrice.PriceDetails;
    const err = this.validatePrice();
    let valid = false;
    let message = '';
    let validPrice = false;
    if (!err) {
      transportationPrice.PriceDetails.map(data => {
        let StartDate = moment(data.StartDate).format('YYYY-MM-DD');
        let EndDate = moment(data.EndDate).format('YYYY-MM-DD');
        let Cost = data.Cost.toString();
        let MinPax = data.MinPax.toString();
        if (
          Cost === temporaryPriceDetail.Cost.toString() &&
          StartDate === temporaryPriceDetail.StartDate &&
          EndDate === temporaryPriceDetail.EndDate &&
          data.Type === temporaryPriceDetail.Type &&
          MinPax === temporaryPriceDetail.MinPax.toString()
        ) {
          if (temporaryPriceDetail.post) {
            valid = true;
          }
        }
        return data;
      });
      // if (temporaryPriceDetail.Cost === '0') {
      //   validPrice = true;
      //   message = 'the price you enter cannot be 0';
      // }
      if (temporaryPriceDetail.Cost.length > 1) {
        let check = Math.sign(temporaryPriceDetail.Cost);
        if (check < 0) {
          validPrice = true;
          message = 'the price cannot be negative';
        }
      } else if (temporaryPriceDetail.MinPax === '0') {
        validPrice = true;
        message = 'the price you enter cannot be 0';
      }

      // if (temporaryPriceDetail.Cost.length > 1) {
      //   let check = Math.sign(temporaryPriceDetail.Cost);
      //   if (check < 0) {
      //     validPrice = true;
      //     message = check < 0 ? 'price can only be positive' : '';
      //   }
      // }

      if (!valid) {
        if (validPrice) {
          this.setState({
            contentAlert: message,
            modalConfirmation: true,
            headerAlert: 'Information',
          });
        } else {
          if (editPrice) {
            priceDetails[indexEditPrice] = temporaryPriceDetail;
          } else {
            priceDetails.push(temporaryPriceDetail);
          }
          this.setState({
            transportationPrice: {
              ...this.state.transportationPrice,
              PriceDetails: priceDetails,
              onChange: true,
            },
            openModalPriceDetail: false,
          });
        }
      } else {
        this.setState({
          contentAlert:
            'The price with the service type and date you fill in already exists',
          modalConfirmation: true,
          headerAlert: 'Information',
        });
      }
    }
  };

  handleEditPriceDetail = (e, { name }) => {
    const unitDetails = this.state.transportationPrice.PriceDetails[name];
    let data = {
      ...unitDetails,
      post: false,
    };
    this.setState({
      tranportationPriceDetailTemporary: data,
      openModalPriceDetail: true,
      editPrice: true,
      indexEditPrice: name,
    });
  };

  validatePrice = () => {
    let isError = false;
    const errors = handleValidation(
      this.state.tranportationPriceDetailTemporary,
      fieldToValidatePriceDetail
    );
    isError = !_.isEmpty(errors);
    this.setState({
      errorsPrice: {
        ...errors,
      },
    });
    return isError;
  };

  setActivePage = activePage => {
    this.setState({ activePage });
  };

  handleSetPrimaryImageAction = (imageId, id, data) => {
    imageId > 0 &&
      this.props.postSetPrimaryImageTransportaionUnitHeaderAction(
        imageId,
        data
      );
  };

  render() {
    let {
      failedTransportationUnitPrice,
      failedTransportationUnit,
      modalConfirmation,
      contentAlert,
      headerAlert,
      transportationHeader,
      activePageImage,
      countryName,
      headerModal,
      openModalPriceDetail,
    } = this.state;
    let loading = this.props.loadingTransportation || this.props.loadingPrice;
    let openModal =
      failedTransportationUnitPrice ||
      failedTransportationUnit ||
      modalConfirmation;
    // let pageImage = datasetPagination(transportationHeader.UnitImages, 3);
    // let listImage = pageImage[activePageImage - 1];
    let destinationDestructure = [
      {
        text: 'Anywhere in region',
        value: 'ANYWHERE',
        key: 'ANYWHERE',
      },
    ];
    let originCityOptions = destinationDestructure.concat(
      this.props.originCityList
    );
    let destCityOptions = destinationDestructure.concat(
      this.props.destCityList
    );
    return (
      <div>
        <Dimmer.Dimmable blurring dimmed={loading}>
          <Loader loading={loading} />
          <TransportationUnit
            transportationUnitByHeader={this.state.transportationUnitByHeader}
            transportationHeader={this.state.transportationHeader}
            addNew={this.addNew}
            openModalPrice={this.state.openModalPrice}
            handleClose={this.handleCloseModalPrice}
            listRegion={this.props.listRegion}
            // listDestCity={this.props.destCityList}
            // listOriginCity={this.props.originCityList}
            listDestCity={destCityOptions}
            listOriginCity={originCityOptions}
            handleChangePrice={this.handleChangePrice}
            handleChangePriceDetails={this.handleChangePriceDetails}
            handleChangeCostPriceDetails={this.handleChangeCostPriceDetails}
            transportationPrice={this.state.transportationPrice}
            addPriceDetails={this.addPriceDetails}
            handleClickEdit={this.handleClickEdit}
            servicePriceType={this.props.servicePriceType}
            currencies={this.props.currencies}
            addPriceToList={this.addPriceToList}
            saveTransportation={this.saveTransportationHeader}
            title={this.state.titleHeader}
            rating={this.props.listTransportationCatagories}
            listTransportationType={this.props.getMasterReferenceList}
            handleChangeUnitHeader={this.handleChangeUnitHeader}
            errors={this.state.errors}
            contentButton={this.state.contentButton}
            disablePage={this.state.disablePage}
            backPage={this.onClickBack}
            handleClickDelete={this.handleConfirmDelete}
            modalItem={this.modalItemAction}
            openDetail={this.openDetail}
            deleteData={this.deleteImageTransportation}
            listImage={transportationHeader.UnitImages}
            activePageImage={activePageImage}
            // pageImage={pageImage}
            pageChangeImage={this.pageChangeImage}
            handleChangePrimaryImage={this.handleChangePrimaryImage}
            countryName={countryName}
            headerModal={headerModal}
            openModalPriceDetail={this.openModalPriceDetailFunc}
            handleEditPriceDetail={this.handleEditPriceDetail}
            //==========================
            activePage={this.state.activePage}
            setActivePage={this.setActivePage}
          />
          <ModalAlert
            openModal={openModal}
            handleClose={this.handleClose}
            header={headerAlert}
            content={contentAlert}
            nameButton="OK"
          />
          <ModalImage
            openModal={this.state.openModalImage}
            imagePreview={this.state.imageUrl}
            handleChange={this.changeImage}
            addImage={this.addImage}
            handleClose={this.handleClose}
            header="Upload Image"
            image={this.state.transportationHeader.UnitImages}
            listStepImage={this.state.listStepImage}
            handleNext={this.handleNext}
            handleBackStep={this.handleBackStep}
            indexActive={this.state.indexActive}
            handleChangePrimaryImage={this.handleChangePrimaryImage}
            imageRatio={this.state.image}
          />
          <ModalConfirm
            openModal={this.state.openConfirm}
            size="mini"
            close={this.handleClose}
            confirm={this.handleClickDelete}
            message={this.state.messageConfirm}
          />
          <ModalConfirm
            openModal={this.state.openConfirmImages}
            size="mini"
            close={this.handleClose}
            confirm={this.deleteImageTransportation}
            message={this.state.messageConfirm}
          />
          <ModalTransportationPriceDetail
            openModalPrice={openModalPriceDetail}
            handleClose={this.handlCloseModalPriceDetail}
            header="Add New Price"
            servicePriceType={this.props.servicePriceType}
            handleChangePrice={this.onChangeModalPriceDetails}
            handleChangePriceDate={this.handleChangePriceDate}
            currenciesList={this.props.currencies}
            data={this.state.tranportationPriceDetailTemporary}
            savePriceDetail={this.savePriceDetail}
            errors={this.state.errorsPrice}
            errorsDate={this.state.errors}
            headerTransportationUnit={
              this.state.transportationHeader.TransportationProfileName
            }
            countryName={countryName}
            transportationOriginCity={this.state.transportationPrice.OriginCity}
            transportationOriginRegion={
              this.state.transportationPrice.OriginRegion
            }
          />
        </Dimmer.Dimmable>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    transportationHeader: state.TransportationStore.transportationHeader,
    getTransportationUnitByHeaderIdStatus:
      state.TransportationStore.getTransportationUnitByHeaderIdStatus,
    transportationUnitByHeader:
      state.TransportationPageStore.transportationUnitByHeader, //Page
    getTransportationUnitHeaderByIdStatus:
      state.TransportationPageStore.getTransportationUnitHeaderByIdStatus, //Page
    listRegion: state.RegionStore.getRegion.map(type => {
      return { text: type.Name, value: type.Id };
    }),
    originCityList: state.CityStore.originCityList.map(type => {
      return { text: type.Name, value: type.Id };
    }),
    destCityList: state.CityStore.destCityList.map(type => {
      return { text: type.Name, value: type.Id };
    }),
    supplierPriceList: state.SupplierPriceStore.supplierPriceList,
    getSupplierPriceStatus: state.SupplierPriceStore.getSupplierPriceStatus,
    servicePriceType: state.SupplierPriceStore.servicePriceType.map(type => {
      return { text: type.replace('_', ' '), value: type };
    }),
    currencies: state.currenciesStore.currencies.map((cur, i) => {
      return { key: i, text: cur.Id, value: cur.Id };
    }),
    getMasterReferenceList: state.MasterDataReferenceStore.getMasterReferenceList.map(
      data => {
        return { key: data.Id, text: data.Name, value: data.Id };
      }
    ),
    getMasterReferenceStatus:
      state.MasterDataReferenceStore.getMasterReferenceStatus,
    postTransportationHeaderStatus:
      state.TransportationPageStore.postTransportationHeaderStatus, //Page
    postTransportationHeader:
      state.TransportationPageStore.postTransportationHeader, //Page
    putTransportationHeaderStatus:
      state.TransportationPageStore.putTransportationHeaderStatus, //Page
    putTransportationHeader:
      state.TransportationPageStore.putTransportationHeader, //Page
    listTransportationCatagories: state.TransportationPageStore.getTransportationCatagoriesList.map(
      //Page
      type => {
        return { text: type.Name, value: type.Id, key: type.Id };
      }
    ),
    postTransportationUnitStatus:
      state.TransportationPageStore.postTransportationUnitStatus, //Page
    postTransportationUnit:
      state.TransportationPageStore.postTransportationUnit, //Page
    putTransportationUnitStatus:
      state.TransportationPageStore.putTransportationUnitStatus, //Page
    putTransportationUnit: state.TransportationPageStore.putTransportationUnit, //Page
    postSupplierPriceStatus: state.SupplierPriceStore.postSupplierPriceStatus,
    putSupplierPriceStatus: state.SupplierPriceStore.putSupplierPriceStatus,
    // errorsTransportation: state.TransportationStore.errors,
    errorsTransportation: state.TransportationPageStore.errors,
    errorMessage: state.SupplierPriceStore.errors.global,
    deleteTransportationUnitStatus:
      state.TransportationPageStore.deleteTransportationUnitStatus, //Page
    deleteSupplierPriceStatus:
      state.SupplierPriceStore.deleteSupplierPriceStatus,
    loadingTransportation: state.TransportationStore.loading,
    loadingPrice: state.SupplierPriceStore.loading,
  };
}
TransportationUnitPage.propTypes = {
  getTransportationUnitHeaderByIdAction: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.string,
  }),
  getTransportationUnitByHeaderIdAction: PropTypes.func,
  transportationHeader: PropTypes.object,
  resetTransportationAction: PropTypes.func,
  getTransportationUnitByHeaderIdStatus: PropTypes.string,
  transportationUnitByHeader: PropTypes.array,
  getRegionListAction: PropTypes.func,
  listRegion: PropTypes.array,
  destCityList: PropTypes.array,
  originCityList: PropTypes.array,
  getOriginCityListAction: PropTypes.func,
  getDestCityListAction: PropTypes.func,
  getTransportationUnitHeaderByIdStatus: PropTypes.string,
  getServicePriceAction: PropTypes.func,
  getSupplierPriceStatus: PropTypes.string,
  resetServicePriceAction: PropTypes.func,
  supplierPriceList: PropTypes.array,
  getServicePriceTypeAction: PropTypes.func,
  servicePriceType: PropTypes.array,
  getCurrenciesAction: PropTypes.func,
  currencies: PropTypes.array,
  getMasterReferenceAction: PropTypes.func,
  postTransportationUnitHeaderAction: PropTypes.func,
  getMasterReferenceList: PropTypes.array,
  putTransportationHeaderStatus: PropTypes.string,
  putTransportationHeader: PropTypes.array,
  putTransporationUnitHeaderAction: PropTypes.func,
  postServicePriceAction: PropTypes.func,
  putServicePriceAction: PropTypes.func,
  deleteServicePriceAction: PropTypes.func,
  getTransportationCatagoriesAction: PropTypes.func,
  listTransportationCatagories: PropTypes.array,
  postTransportationUnitStatus: PropTypes.string,
  postTransportationUnit: PropTypes.object,
  postTransportationUnitAction: PropTypes.func,
  putTransportationUnitAction: PropTypes.func,
  postSupplierPriceStatus: PropTypes.string,
  putTransportationUnitStatus: PropTypes.string,
  errorsTransportation: PropTypes.object,
  postTransportationHeaderStatus: PropTypes.string,
  putSupplierPriceStatus: PropTypes.string,
  errorMessage: PropTypes.string,
  deleteTrasportationUnitAction: PropTypes.func,
  deleteSupplierPriceStatus: PropTypes.string,
  deleteTransportationUnitStatus: PropTypes.string,
  postUploadImageTransportationUnitAction: PropTypes.func,
  deleteImageTransportationUnitAction: PropTypes.func,
  loadingTransportation: PropTypes.bool,
  postTransportationHeader: PropTypes.object,
  getCountryByIdAction: PropTypes.func,
  getRegionAction: PropTypes.func,
  loadingPrice: PropTypes.bool,
  postSetPrimaryImageTransportaionUnitHeaderAction: PropTypes.func,
};
export default connect(
  mapStateToProps,
  {
    getTransportationUnitHeaderByIdAction,
    getTransportationUnitByHeaderIdAction,
    resetTransportationAction,
    getRegionListAction,
    getRegionAction,
    getOriginCityListAction,
    getDestCityListAction,
    getServicePriceAction,
    resetServicePriceAction,
    getServicePriceTypeAction,
    getCurrenciesAction,
    getMasterReferenceAction,
    postTransportationUnitHeaderAction,
    putTransporationUnitHeaderAction,
    postServicePriceAction,
    putServicePriceAction,
    deleteServicePriceAction,
    getTransportationCatagoriesAction,
    postTransportationUnitAction,
    putTransportationUnitAction,
    deleteTrasportationUnitAction,
    postUploadImageTransportationUnitAction,
    deleteImageTransportationUnitAction,
    getCountryByIdAction,
    postSetPrimaryImageTransportaionUnitHeaderAction,
  }
)(TransportationUnitPage);
