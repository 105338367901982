import React from 'react';
import { Dimmer } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {
  deleteCountryAction,
  postCountryAction,
  putCountryAction,
  resetCountryPageAction,
} from './countryAction';
import {
  getCountryAction,
  getCountryByIdAction,
} from '../shared/actions/countryAction';
import ModalAlert from './../../components/modal/modalAlert';
// import Loader from './../components/loader';
import CountryList from './components/CountryListComponent';
import PropTypes from 'prop-types';
import ModalCountry from './components/modal/ModalCountry';
import ModalConfirm from '../../components/modal/modalConfirmationTwoButton';
import {
  setCountryState,
  setPostCountryState,
} from './services/data/setDataState';
class CountryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countryList: [],
      countryById: {},
      errors: {},
      loadingCountry: true,
      failedCountry: false,
      modalOpen: false,
      modalCountry: false,
      modalConfirmation: false,
      openConfirm: false,
      loadingModal: false,
    };
  }
  static propTypes = {
    getCountryAction: PropTypes.func,
    resetCountryPageAction: PropTypes.func,
    getCountryStatus: PropTypes.string,
    getCountry: PropTypes.array,
    deleteCountryAction: PropTypes.func,
    getCountryByIdAction: PropTypes.func,
    deleteCountryStatus: PropTypes.string,
    postCountryStatus: PropTypes.string,
    putCountryStatus: PropTypes.string,
    getCountryById: PropTypes.array,
    getCountryByIdStatus: PropTypes.string,
    postCountryAction: PropTypes.func,
    putCountryAction: PropTypes.func,
    errorMessageCountry: PropTypes.string,
    loading: PropTypes.bool,
  };
  handleClose = () =>
    this.setState({ modalOpen: false, modalConfirmation: false });
  handleCloseModal = () => this.setState({ modalCountry: false });
  handleCloseConfirm = () =>
    this.setState({ openConfirm: false, loadingCountry: false });
  componentDidMount = () => {
    this.props.getCountryAction();
  };
  componentDidUpdate() {
    if (this.props.getCountryStatus === 'success') {
      this.setState({
        countryList: this.props.getCountry,
        loadingCountry: false,
      });
      this.props.resetCountryPageAction();
    } else if (this.props.getCountryStatus === 'failed') {
      this.setState({ failedCountry: true, loadingCountry: false });
      this.props.resetCountryPageAction();
    }
    if (this.props.getCountryByIdStatus === 'success') {
      this.setStateCountry(this.props.getCountryById);
      this.setState({
        loadingModal: false,
      });
      this.props.resetCountryPageAction();
    } else if (this.props.getCountryByIdStatus === 'failed') {
      this.props.resetCountryPageAction();
    }
    if (this.props.postCountryStatus === 'success') {
      this.setState({
        headerAlert: ' Success',
        contentAlert: ' Success Saving Country',
        modalConfirmation: true,
        loadingModal: false,
        modalCountry: false,
      });
      this.props.resetCountryPageAction();
      this.props.getCountryAction();
    } else if (this.props.postCountryStatus === 'failed') {
      this.setState({
        headerAlert: ' Failed',
        contentAlert: this.props.errorMessageCountry,
        modalConfirmation: true,
        loadingModal: false,
      });
      this.props.resetCountryPageAction();
      this.props.getCountryAction();
    }
    if (this.props.putCountryStatus === 'success') {
      this.setState({
        headerAlert: ' Success',
        contentAlert: ' Success Edit Country',
        modalConfirmation: true,
        loadingModal: false,
        modalCountry: false,
      });
      this.props.resetCountryPageAction();
      this.props.getCountryAction();
    } else if (this.props.putCountryStatus === 'failed') {
      this.setState({
        headerAlert: ' Failed',
        contentAlert: this.props.errorMessageCountry,
        modalConfirmation: true,
        loadingModal: false,
      });
      this.props.resetCountryPageAction();
      this.props.getCountryAction();
    }
    if (this.props.deleteCountryStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Delete Country Succesfully',
        modalConfirmation: true,
        openConfirm: false,
        loadingCountry: false,
      });
      this.props.resetCountryPageAction();
      this.props.getCountryAction();
    } else if (this.props.deleteCountryStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorMessageCountry,
        modalConfirmation: true,
        openConfirm: false,
        loadingCountry: false,
      });
      this.props.resetCountryPageAction();
      this.props.getCountryAction();
    }
  }
  onChange = (e, { value, name }) => {
    const countryById = this.state.countryById;
    countryById[name] = value;
    this.setState({ countryById: countryById });
  };

  onChange = (e, { value, name }) => {
    const countryById = this.state.countryById;
    countryById[name] = value;
    this.setState({ countryById: countryById });
  };

  handleCheckClick = () => {
    this.setState({
      countryById: {
        ...this.state.countryById,
        IsTourRelevant: !this.state.countryById.IsTourRelevant,
      },
    });
  };
  setStateCountry = data => {
    let countryById = setCountryState(data);
    this.setState({ countryById: countryById });
  };
  newCountry = () => {
    this.setState({
      modalCountry: true,
      headerModal: 'Add New Country',
      errors: {},
      countryById: {},
      disabledButtonDelete: true,
    });
  };
  edit = e => {
    this.props.getCountryByIdAction(e.target.value);
    this.setState({
      modalCountry: true,
      headerModal: 'Edit Country',
      errors: {},
      disabledButtonDelete: false,
      loadingModal: true,
    });
  };
  delete = (e, { value, name }) => {
    this.setState({
      openConfirm: true,
      countryId: value,
      contentConfirm: 'Are you sure want to delete ' + name + '?',
      loadingCountry: true,
    });
  };
  handleConfirm = () => {
    const { countryId } = this.state;
    this.props.deleteCountryAction(countryId);
    this.setState({
      loadingCountry: true,
      openConfirm: false,
    });
  };

  saveCountry = () => {
    const err = this.validate();
    if (!err) {
      const id = this.state.countryById.CountryId;
      const data = this.state.countryById;
      let postCountry = setPostCountryState(id, data);
      if (data.savingStatus === 'Edit') {
        this.props.putCountryAction(id, data);
      } else {
        this.props.postCountryAction(postCountry);
      }
      this.setState({ loadingModal: true });
    }
  };

  validate = () => {
    let isError = false;
    const errors = {
      CountryIdError: '',
      NameError: '',
    };
    if (this.state.countryById.Name.length < 1) {
      isError = true;
      errors.NameError = true;
      errors.NameError = "Country Name Can't Be Empty";
    }
    if (this.state.countryById.CountryId.length < 1) {
      isError = true;
      errors.CountryIdError = true;
      errors.CountryIdError = "Country Can't Be Empty";
    }
    this.setState({
      errors: {
        ...errors,
      },
    });
    return isError;
  };

  render() {
    let { loadingCountry, failedCountry, modalConfirmation } = this.state;
    let loading = loadingCountry || this.props.loading;
    let openModal = failedCountry || modalConfirmation;
    return (
      <div>
        <Dimmer.Dimmable blurring dimmed={loading}>
          {/* <Loader loading={loading} /> */}
          <CountryList
            data={this.state.countryList}
            type="countryList"
            filterBy="Name"
            buttonNew={this.newCountry}
            butttonEdit={this.edit}
            buttonDelete={this.delete}
          />
          <ModalAlert
            openModal={openModal}
            handleClose={this.handleClose}
            header={this.state.headerAlert}
            content={this.state.contentAlert}
            nameButton="OK"
          />
          <ModalCountry
            open={this.state.modalCountry}
            handleClose={this.handleCloseModal}
            header={this.state.headerModal}
            handleChange={this.onChange}
            contentField={this.state.countryById}
            saveCountry={this.saveCountry}
            handleCheckClick={this.handleCheckClick}
            errorInformation={this.state.errors}
            disabled={this.state.disabledButtonDelete}
            loading={this.state.loadingModal}
          />
          <ModalConfirm
            openModal={this.state.openConfirm}
            size="mini"
            close={this.handleCloseConfirm}
            confirm={this.handleConfirm}
            message={this.state.contentConfirm}
            header="Confirmation"
          />
        </Dimmer.Dimmable>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    getCountry: state.CountryPageStore.getCountry,
    getCountryStatus: state.CountryPageStore.getCountryStatus,
    getCountryById: state.CountryPageStore.getCountryById,
    getCountryByIdStatus: state.CountryPageStore.getCountryByIdStatus,
    deleteCountryStatus: state.CountryPageStore.deleteCountryStatus,
    postCountryStatus: state.CountryPageStore.postCountryStatus,
    putCountryStatus: state.CountryPageStore.putCountryStatus,
    errorMessageCountry: state.CountryPageStore.errors.global,
    loading: state.CountryPageStore.loading,
  };
}
export default connect(
  mapStateToProps,
  {
    getCountryAction,
    resetCountryPageAction,
    deleteCountryAction,
    getCountryByIdAction,
    postCountryAction,
    putCountryAction,
  }
)(CountryPage);
