import * as types from './constant/actionTypes';

const initialState = {
  getCityGroupList: [],
  getCityGroupListStatus: '',
  getCityGroup: [],
  getCityGroupStatus: '',
  deleteCityGroupStatus: '',
  postCityGroupStatus: '',
  putCityGroupStatus: '',
  getCityGroupById: [],
  getCityGroupByIdStatus: '',
  getCityInCountry: [],
  getCityInCountryStatus: '',
  postCityGroupDetailStatus: '',
  postCityGroupDetailFullAddStatus: '',
  getCityGroupId: [],
  errors: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_CITY_GROUP:
      return {
        ...state,
        getCityGroupListStatus: '',
        getCityGroupStatus: '',
        deleteCityGroupStatus: '',
        getCityGroupByIdStatus: '',
        postCityGroupStatus: '',
        putCityGroupStatus: '',
        postCityGroupDetailStatus: '',
        postCityGroupDetailFullAddStatus: '',
        getCityInCountryStatus: '',
      };
    case types.GET_CITY_GROUP_LIST:
      return { ...state };
    case types.GET_CITY_GROUP_LIST_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        getCityGroupListStatus: 'failed',
      };
    case types.GET_CITY_GROUP_LIST_PENDING:
      return { ...state, loading: true };
    case types.GET_CITY_GROUP_LIST_FULFILLED:
      return {
        ...state,
        getCityGroupList: action.payload.data,
        loading: false,
        getCityGroupListStatus: 'success',
      };
    case types.DELETE_CITY_GROUP:
      return { ...state };
    case types.DELETE_CITY_GROUP_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        deleteCityGroupStatus: 'failed',
      };
    case types.DELETE_CITY_GROUP_PENDING:
      return { ...state, loading: true };
    case types.DELETE_CITY_GROUP_FULFILLED:
      return {
        ...state,
        loading: false,
        deleteCityGroupStatus: 'success',
      };
    case types.GET_CITY_GROUP_BY_ID:
      return { ...state };
    case types.GET_CITY_GROUP_BY_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        getCityGroupByIdStatus: 'failed',
      };
    case types.GET_CITY_GROUP_BY_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_CITY_GROUP_BY_ID_FULFILLED:
      return {
        ...state,
        getCityGroupById: action.payload.data,
        loading: false,
        getCityGroupByIdStatus: 'success',
      };
    case types.POST_CITY_GROUP:
      return { ...state };
    case types.POST_CITY_GROUP_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        postCityGroupStatus: 'failed',
      };
    case types.POST_CITY_GROUP_PENDING:
      return { ...state, loading: true };
    case types.POST_CITY_GROUP_FULFILLED:
      return {
        ...state,
        loading: false,
        postCityGroupStatus: 'success',
        getCityGroupId: action.payload.data,
      };
    case types.PUT_CITY_GROUP:
      return { ...state };
    case types.PUT_CITY_GROUP_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        putCityGroupStatus: 'failed',
      };
    case types.PUT_CITY_GROUP_PENDING:
      return { ...state, loading: true };
    case types.PUT_CITY_GROUP_FULFILLED:
      return {
        ...state,
        loading: false,
        putCityGroupStatus: 'success',
      };
    case types.GET_CITY_IN_COUNTRY:
      return { ...state };
    case types.GET_CITY_IN_COUNTRY_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
      };
    case types.GET_CITY_IN_COUNTRY_PENDING:
      return { ...state, loading: true };
    case types.GET_CITY_IN_COUNTRY_FULFILLED:
      return {
        ...state,
        getCityInCountry: action.payload.data,
        getCityInCountryStatus: 'success',
        loading: false,
      };

    case types.POST_CITY_GROUP_DETAIL:
      return { ...state };
    case types.POST_CITY_GROUP_DETAIL_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        postCityGroupDetailStatus: 'failed',
      };
    case types.POST_CITY_GROUP_DETAIL_PENDING:
      return { ...state, loading: true };
    case types.POST_CITY_GROUP_DETAIL_FULFILLED:
      return {
        ...state,
        loading: false,
        postCityGroupDetailStatus: 'success',
      };

    case types.POST_CITY_GROUP_DETAIL_FULLADD:
      return { ...state };
    case types.POST_CITY_GROUP_DETAIL_FULLADD_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        postCityGroupDetailFullAddStatus: 'failed',
      };
    case types.POST_CITY_GROUP_DETAIL_FULLADD_PENDING:
      return { ...state, loading: true };
    case types.POST_CITY_GROUP_DETAIL_FULLADD_FULFILLED:
      return {
        ...state,
        loading: false,
        postCityGroupDetailFullAddStatus: 'success',
      };
    default:
      return state;
  }
};

export default reducer;
