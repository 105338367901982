// import * as types from '../../../actions/actionTypes';
import * as types from '../../shared/constants/actionTypes';

const initialState = {
  loadingGetUserByCode: false,
  loading: false,
  loadingGetCustomerProfile: false,
  getCustomerProfileList: [],
  getCustomerProfileListStatus: '',
  getCustomerProfileByCode: [],
  getCustomerProfileByCodeStatus: '',
  getUserDataByCode: [],
  getUserDataByCodeStatus: '',
  getHistoryTransactionByCode: [],
  getHistoryTransactionByCodeStatus: '',
  putCustomerProfileStatus: '',
  deletCustomerProfileStatus: '',
  getBalanceHistory: [],
  getBalanceHistoryStatus: '',
  postBalanceDetailStatus: '',
  getPaymentSchemeList: [],
  postCustomerProfileStatus: '',
  postCustomerProfile: {},
  getAccountManagerStatus: '',
  getAccountManager: [],
  getAccountManagerByCompany: [],
  getAccountManagerByCompanyStatus: '',
  errors: {},
  loadingCreateOnBehalf: false,
  registerSimpleCustomer: {},
  postRegisterSimpleCustomerStatus: '',
  getPaymentSchemeByIdStatus: '',
  postPaymentSchemeStatus: '',
  putPaymentSchemeStatus: '',
  getPaymentSchemeById: {},
  getPaymentSchemeListStatus: '',
  loadingGetListTotalEmployee: false,
  listTotalEmployee: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CUSTOMER_PROFILE_LIST:
      return { ...state };
    case types.GET_CUSTOMER_PROFILE_LIST_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loadingGetCustomerProfile: false,
        getCustomerProfileListStatus: 'failed',
      };
    case types.GET_CUSTOMER_PROFILE_LIST_PENDING:
      return { ...state, loadingGetCustomerProfile: true };
    case types.GET_CUSTOMER_PROFILE_LIST_FULFILLED:
      return {
        ...state,
        getCustomerProfileList: action.payload.data,
        loadingGetCustomerProfile: false,
        getCustomerProfileListStatus: 'success',
      };
    //
    case types.GET_CUSTOMER_PROFILE_FOR_SALES:
      return { ...state };
    case types.GET_CUSTOMER_PROFILE_FOR_SALES_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loadingGetCustomerProfile: false,
        getCustomerProfileListStatus: 'failed',
      };
    case types.GET_CUSTOMER_PROFILE_FOR_SALES_PENDING:
      return { ...state, loadingGetCustomerProfile: true };
    case types.GET_CUSTOMER_PROFILE_FOR_SALES_FULFILLED:
      return {
        ...state,
        getCustomerProfileList: action.payload.data,
        loadingGetCustomerProfile: false,
        getCustomerProfileListStatus: 'success',
      };
    //
    case types.RESET_STATUS_CUSTOMER_PROFILE:
      return {
        ...state,
        getCustomerProfileListStatus: '',
        getHistoryTransactionByCodeStatus: '',
        putCustomerProfileStatus: '',
        deletCustomerProfileStatus: '',
        getUserDataByCodeStatus: '',
        getCustomerProfileByCodeStatus: '',
        getBalanceHistoryStatus: '',
        postBalanceDetailStatus: '',
        postCustomerProfileStatus: '',
        getAccountManagerStatus: '',
        getAccountManagerByCompanyStatus: '',
        getPaymentSchemeByIdStatus: '',
        postPaymentSchemeStatus: '',
        putPaymentSchemeStatus: '',
        getPaymentSchemeListStatus: '',
      };
    case types.GET_CUSTOMER_PROFILE_BY_CODE:
      return { ...state };
    case types.GET_CUSTOMER_PROFILE_BY_CODE_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.message,
        },
        getCustomerProfileByCodeStatus: 'failed',
      };
    case types.GET_CUSTOMER_PROFILE_BY_CODE_PENDING:
      return { ...state, loading: true };
    case types.GET_CUSTOMER_PROFILE_BY_CODE_FULFILLED:
      return {
        ...state,
        getCustomerProfileByCode: action.payload.data,
        loading: false,
        getCustomerProfileByCodeStatus: 'success',
      };
    case types.GET_USER_DATA_BY_CODE:
      return { ...state };
    case types.GET_USER_DATA_BY_CODE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        getUserDataByCodeStatus: 'failed',
        loadingGetUserByCode: false,
      };
    case types.GET_USER_DATA_BY_CODE_PENDING:
      return { ...state, loadingGetUserByCode: true };
    case types.GET_USER_DATA_BY_CODE_FULFILLED:
      return {
        ...state,
        getUserDataByCode: action.payload.data,
        loadingGetUserByCode: false,
        getUserDataByCodeStatus: 'success',
      };
    //
    case types.GET_USER_DATA_BY_CODE_FOR_SALES:
      return { ...state };
    case types.GET_USER_DATA_BY_CODE_FOR_SALES_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        getUserDataByCodeStatus: 'failed',
        loadingGetUserByCode: false,
      };
    case types.GET_USER_DATA_BY_CODE_FOR_SALES_PENDING:
      return { ...state, loadingGetUserByCode: true };
    case types.GET_USER_DATA_BY_CODE_FOR_SALES_FULFILLED:
      return {
        ...state,
        getUserDataByCode: action.payload.data,
        loadingGetUserByCode: false,
        getUserDataByCodeStatus: 'success',
      };
    // case types.GET_HISTORY_TRANSACTION_BY_CODE:
    //   return { ...state };
    // case types.GET_HISTORY_TRANSACTION_BY_CODE_REJECTED:
    //   return {
    //     ...state,
    //     errors: { global: action.payload.message },
    //     getHistoryTransactionByCodeStatus: 'failed',
    //   };
    // case types.GET_HISTORY_TRANSACTION_BY_CODE_PENDING:
    //   return { ...state, loading: true };
    // case types.GET_HISTORY_TRANSACTION_BY_CODE_FULFILLED:
    //   return {
    //     ...state,
    //     getHistoryTransactionByCode: action.payload.data,
    //     loading: false,
    //     getHistoryTransactionByCodeStatus: 'success',
    //   };
    case types.PUT_CUSTOMER_PROFILE:
      return { ...state };
    case types.PUT_CUSTOMER_PROFILE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        putCustomerProfileStatus: 'failed',
      };
    case types.PUT_CUSTOMER_PROFILE_PENDING:
      return { ...state, loading: true };
    case types.PUT_CUSTOMER_PROFILE_FULFILLED:
      return {
        ...state,
        loading: false,
        putCustomerProfileStatus: 'success',
      };
    case types.GET_BALANCE_HISTORY_BY_CODE:
      return { ...state };
    case types.GET_BALANCE_HISTORY_BY_CODE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        getBalanceHistoryStatus: 'failed',
      };
    case types.GET_BALANCE_HISTORY_BY_CODE_PENDING:
      return { ...state, loading: true };
    case types.GET_BALANCE_HISTORY_BY_CODE_FULFILLED:
      return {
        ...state,
        getBalanceHistory: action.payload.data,
        loading: false,
        getBalanceHistoryStatus: 'success',
      };
    case types.POST_BALANCE_DETAIL:
      return { ...state };
    case types.POST_BALANCE_DETAIL_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        postBalanceDetailStatus: 'failed',
      };
    case types.POST_BALANCE_DETAIL_PENDING:
      return { ...state, loading: true };
    case types.POST_BALANCE_DETAIL_FULFILLED:
      return {
        ...state,
        loading: false,
        postBalanceDetailStatus: 'success',
      };
    case types.GET_PAYMENT_SCHEME:
      return { ...state };
    case types.GET_PAYMENT_SCHEME_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        getPaymentSchemeListStatus: 'failed',
      };
    case types.GET_PAYMENT_SCHEME_PENDING:
      return { ...state, loading: true };
    case types.GET_PAYMENT_SCHEME_FULFILLED:
      return {
        ...state,
        getPaymentSchemeList: action.payload.data,
        getPaymentSchemeListStatus: 'success',
        loading: false,
      };
    case types.POST_CUSTOMER_PROFILE:
      return { ...state };
    case types.POST_CUSTOMER_PROFILE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        postCustomerProfileStatus: 'failed',
        postCustomerProfile: {},
      };
    case types.POST_CUSTOMER_PROFILE_PENDING:
      return { ...state, loading: true };
    case types.POST_CUSTOMER_PROFILE_FULFILLED:
      return {
        ...state,
        loading: false,
        postCustomerProfileStatus: 'success',
        postCustomerProfile: action.payload.data,
      };
    case types.GET_USER_PROFILE_ACCOUNT_MANAGER:
      return { ...state };
    case types.GET_USER_PROFILE_ACCOUNT_MANAGER_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getAccountManagerStatus: 'failed',
        loadingAccountManager: false,
      };
    case types.GET_USER_PROFILE_ACCOUNT_MANAGER_PENDING:
      return { ...state, loadingAccountManager: true };
    case types.GET_USER_PROFILE_ACCOUNT_MANAGER_FULFILLED:
      return {
        ...state,
        loadingAccountManager: false,
        getAccountManagerStatus: 'success',
        getAccountManager: action.payload.data,
      };
    case types.GET_USER_PROFILE_ACCOUNT_MANAGER_BY_COMPANY:
      return { ...state };
    case types.GET_USER_PROFILE_ACCOUNT_MANAGER_BY_COMPANY_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getAccountManagerByCompanyStatus: 'failed',
        loadingAccountManagerByCompany: false,
      };
    case types.GET_USER_PROFILE_ACCOUNT_MANAGER_BY_COMPANY_PENDING:
      return { ...state, loadingAccountManager: true };
    case types.GET_USER_PROFILE_ACCOUNT_MANAGER_BY_COMPANY_FULFILLED:
      return {
        ...state,
        loadingAccountManagerByCompany: false,
        getAccountManagerByCompanyStatus: 'success',
        getAccountManagerByCompany: action.payload.data,
      };
    case types.POST_CREATE_CUSTOMER_ON_BE_HALF:
      return { ...state };
    case types.POST_CREATE_CUSTOMER_ON_BE_HALF_PENDING:
      return { ...state, loadingCreateOnBehalf: true };
    case types.POST_CREATE_CUSTOMER_ON_BE_HALF_FULFILLED:
      return {
        ...state,
        registerSimpleCustomer: action.payload.data,
        errors: {},
        loadingCreateOnBehalf: false,
        postRegisterSimpleCustomerStatus: 'success',
      };
    case types.POST_CREATE_CUSTOMER_ON_BE_HALF_REJECTED:
      return {
        ...state,
        registerSimpleCustomer: {},
        errors: action.payload.response.data,
        loadingCreateOnBehalf: false,
        postRegisterSimpleCustomerStatus: 'failed',
      };

    case types.DELETE_USER_PROFILE:
      return { ...state };
    case types.DELETE_USER_PROFILE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        deleteUserProfile: 'failed',
        loading: false,
      };
    case types.DELETE_USER_PROFILE_PENDING:
      return { ...state, loading: true };
    case types.DELETE_USER_PROFILE_FULFILLED:
      return {
        ...state,
        loading: false,
        deleteUserProfile: 'success',
      };

    case types.POST_USER_PROFILE_ACTIVATE:
      return { ...state };
    case types.POST_USER_PROFILE_ACTIVATE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        postUserProfileActivateStatus: 'failed',
        loading: false,
      };
    case types.POST_USER_PROFILE_ACTIVATE_PENDING:
      return { ...state, loading: true };
    case types.POST_USER_PROFILE_ACTIVATE_FULFILLED:
      return {
        ...state,
        loading: false,
        postUserProfileActivateStatus: 'success',
      };

    case types.GET_PAYMENT_SCHEME_BY_ID:
      return { ...state };
    case types.GET_PAYMENT_SCHEME_BY_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getPaymentSchemeByIdStatus: 'failed',
        loading: false,
      };
    case types.GET_PAYMENT_SCHEME_BY_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_PAYMENT_SCHEME_BY_ID_FULFILLED:
      return {
        ...state,
        loading: false,
        getPaymentSchemeByIdStatus: 'success',
        getPaymentSchemeById: action.payload.data,
      };

    case types.POST_PAYMENT_SCHEME:
      return { ...state };
    case types.POST_PAYMENT_SCHEME_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        postPaymentSchemeStatus: 'failed',
        loading: false,
      };
    case types.POST_PAYMENT_SCHEME_PENDING:
      return { ...state, loading: true };
    case types.POST_PAYMENT_SCHEME_FULFILLED:
      return {
        ...state,
        loading: false,
        postPaymentSchemeStatus: 'success',
        postPaymentScheme: action.payload.data,
      };

    case types.PUT_PAYMENT_SCHEME:
      return { ...state };
    case types.PUT_PAYMENT_SCHEME_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        putPaymentSchemeStatus: 'failed',
        loading: false,
      };
    case types.PUT_PAYMENT_SCHEME_PENDING:
      return { ...state, loading: true };
    case types.PUT_PAYMENT_SCHEME_FULFILLED:
      return {
        ...state,
        loading: false,
        putPaymentSchemeStatus: 'success',
        putPaymentScheme: action.payload.data,
      };

    case types.POST_PAYMENT_SCHEME_DETAIL:
      return { ...state };
    case types.POST_PAYMENT_SCHEME_DETAIL_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        postPaymentSchemeStatus: 'failed',
        loading: false,
      };
    case types.POST_PAYMENT_SCHEME_DETAIL_PENDING:
      return { ...state, loading: true };
    case types.POST_PAYMENT_SCHEME_DETAIL_FULFILLED:
      return {
        ...state,
        loading: false,
        // postPaymentSchemeStatus: 'success',
        // postPaymentScheme: action.payload.data,
      };

    case types.PUT_PAYMENT_SCHEME_DETAIL:
      return { ...state };
    case types.PUT_PAYMENT_SCHEME_DETAIL_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        // putPaymentSchemeStatus: 'failed',
        loading: false,
      };
    case types.PUT_PAYMENT_SCHEME_DETAIL_PENDING:
      return { ...state, loading: true };
    case types.PUT_PAYMENT_SCHEME_DETAIL_FULFILLED:
      return {
        ...state,
        loading: false,
      };
    case types.DELETE_PAYMENT_SCHEME_DETAIL:
      return { ...state };
    case types.DELETE_PAYMENT_SCHEME_DETAIL_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        deletePaymentSchemeDetail: 'failed',
        loading: false,
      };
    case types.DELETE_PAYMENT_SCHEME_DETAIL_PENDING:
      return { ...state, loading: true };
    case types.DELETE_PAYMENT_SCHEME_DETAIL_FULFILLED:
      return {
        ...state,
        loading: false,
        deletePaymentSchemeDetail: 'success',
      };
    case types.GET_LIST_TOTAL_EMPLOYEE:
      return { ...state };
    case types.GET_LIST_TOTAL_EMPLOYEE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        listTotalEmployee: [],
        loadingGetListTotalEmployee: false,
      };
    case types.GET_LIST_TOTAL_EMPLOYEE_PENDING:
      return { ...state, loadingGetListTotalEmployee: true };
    case types.GET_LIST_TOTAL_EMPLOYEE_FULFILLED:
      return {
        ...state,
        loadingGetListTotalEmployee: false,
        listTotalEmployee: action.payload.data,
      };
    default:
      return state;
  }
};

export default reducer;
