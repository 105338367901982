import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Segment, Header, Grid } from 'semantic-ui-react';
import Card from './card';
import Input from './../../input/inputWithLabel';
import LoaderModal from './../loaderModal';
import ButtonClose from '../../button/buttonCloseModal';
import styles from './../styles';

class modalDetailListMenu extends Component {
  state = {
    searchName: '',
  };
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  filterData = (listmenu, searchName) => {
    return listmenu.filter(
      menu => menu.Name.toLowerCase().indexOf(searchName.toLowerCase()) !== -1
    );
  };
  render() {
    let { searchName } = this.state;
    let { open, onClose, listDetailData, loading, setRestaurant } = this.props;
    listDetailData = this.filterData(listDetailData, searchName);
    return (
      <Modal
        open={open}
        onClose={onClose}
        style={{ width: '30em' }}
        closeOnDimmerClick
      >
        <Modal.Header style={styles.backgroundModal}>
          Restaurant&#39;s Menus <ButtonClose onClose={onClose} />
        </Modal.Header>
        <Modal.Content style={styles.backgroundModal}>
          <Modal.Description>
            <Grid>
              <Grid.Row>
                <Grid.Column width={8} verticalAlign="middle">
                  <Header as="h5">
                    Showing {listDetailData.length} Restaurant&#39;s Menus
                  </Header>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Segment style={styles.segmentSearch}>
                    <Input
                      name="searchName"
                      type="input"
                      icon="search"
                      iconPosition="left"
                      transparent={true}
                      inputStyles={styles.inputWithPaddingNine}
                      labelStyles={styles.textTransparent}
                      value={searchName}
                      handleChange={this.handleChange}
                      placeholder="Search by name"
                    />
                  </Segment>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  {loading && (
                    <Segment
                      style={{
                        border: 'none',
                        background: 'transparent',
                        minHeight: '50px',
                      }}
                    >
                      <LoaderModal loading={loading} />
                    </Segment>
                  )}{' '}
                  {/* // <Segment key={index} style={styles.segmentNoPadding}></Segment> */}
                  {/* <Segment.Group style={styles.segmentNoPadding}> */}
                  {listDetailData && !loading
                    ? listDetailData.map((data, index) => (
                        <Card
                          key={index}
                          id={data.Id}
                          images={data.RestaurantImages}
                          image={data.ImageUrl}
                          header={data.Name}
                          menuClass={data.MenuClass}
                          category={data.Category}
                          typeMenu={data.TypeOfMenu}
                          description={data.Description}
                          data={data}
                          setRestaurant={setRestaurant}
                        />
                      ))
                    : !loading && (
                        <Header textAlign="center" as="h4">
                          There is no result of menu for your restaurant
                        </Header>
                      )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {/* </Segment.Group> */}
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}
modalDetailListMenu.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  listDetailData: PropTypes.array,
  loading: PropTypes.bool,
  setRestaurant: PropTypes.func,
};
export default modalDetailListMenu;
