import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Button, Form } from 'semantic-ui-react';
import Input from '../../../components/input/inputWithLabel';
import InputDate from '../../../components/input/inputDate';
import ButtonClose from '../../../components/button/buttonCloseModal';
import { convertDateFormat, dateNow } from '../../../scripts/moment';
import {
  validationAddResource,
  generateResource,
  generatForCallApi,
} from '../scripts/resourceBookingDetail';
import {
  postResource,
  putResource,
} from '../../shared/actions/resourceBookingAction';
import LoaderModal from '../../../components/loader/loaderInModal';
import ModalAlert from '../../shared/components/modal/ModalAlert';
import { getTourOperatorProfileByCodeAction } from '../../../actions/packageAction';
import options from '../../../scripts/convertOption';
import ModalConfirm from '../../../components/modal/modalConfirmationTwoButton';

const ModalAddResource = props => {
  // ========= state =====================
  const [resource, setResource] = useState({
    AllowedDelete: true,
    Type: 'other',
    ResourceName: '',
    Qty: 0,
    UnitCost: 0,
    Date: convertDateFormat(dateNow, 'YYYY-MM-DD'),
    DueDate: convertDateFormat(dateNow, 'YYYY-MM-DD'),
    CompanyId: 0,
    TourOperatorProfileId: '',
    Currency: '',
    Description: '',
  });
  const [errors, setErrors] = useState({
    ResourceName: {
      error: false,
      message: '',
    },
    Qty: {
      error: false,
      message: '',
    },
    UnitCost: {
      error: false,
      message: '',
    },
    Date: {
      error: false,
      message: '',
    },
    DueDate: {
      error: false,
      message: '',
    },
    TourOperatorProfileId: {
      error: false,
      message: '',
    },
    CompanyId: {
      error: false,
      message: '',
    },
    Currency: {
      error: false,
      message: '',
    },
    Description: {
      error: false,
      message: '',
    },
  });
  const [modalAlert, setModalAlert] = useState({
    isOpen: false,
    header: '',
    message: '',
  });
  const [modalConfirm, setModalConfirm] = useState({
    isOpen: false,
    type: '',
    message: '',
    header: '',
  });
  // ========== end state ============
  // ========== action ===============

  const dispatch = useDispatch();
  const postResourceAction = param => dispatch(postResource(param));
  const getTourOperatorProfileByCode = param =>
    dispatch(getTourOperatorProfileByCodeAction(param));
  const putResourceAction = param => dispatch(putResource(param));
  // ========== end action ===========
  // ========== reducer ==============
  const loadingPostResource = useSelector(
    state => state.ResourceBooking.loadingPostResource
  );
  const tourOperatorListByCode = useSelector(state =>
    state.PackageStore.tourOperatorListByCode.map(e =>
      options(e.Id, e.Id, e.Name)
    )
  );
  const loadingGetTourOperator = useSelector(
    state => state.PackageStore.loading
  );
  const loadingPutResource = useSelector(
    state => state.ResourceBooking.loadingPutResource
  );
  // ========== end reducer ==========
  useEffect(() => {
    if (props.openModal === true && props.mode === 'EDIT') {
      setResource(generateResource(props.data));
    }
    // eslint-disable-next-line
  }, [props.openModal]);
  const resetResource = () => {
    setResource({
      ResourceName: '',
      Qty: 0,
      UnitCost: 0,
      Date: convertDateFormat(dateNow, 'YYYY-MM-DD'),
      DueDate: convertDateFormat(dateNow, 'YYYY-MM-DD'),
      CompanyId: 0,
      TourOperatorProfileId: '',
      Currency: '',
      Description: '',
    });
  };

  const handleClose = () => {
    resetResource();
    props.handleClose();
  };
  const handleDate = (date, name) => {
    setResource({ ...resource, [name]: date });
  };
  const handleResource = (e, { name, value }) => {
    if (name === 'UnitCost') {
      value = value ? parseInt(value.replace(/\D/g, ''), 10) : 0;
    } else if (name === 'CompanyId') {
      if (value === 0) {
        setResource({ ...resource, TourOperatorProfileId: '' });
      }
      getTourOperatorProfileByCode(value);
    }
    setResource({ ...resource, [name]: value });
  };

  const handleAddResource = () => {
    let validation = validationAddResource(resource);
    if (validation.isError) setErrors(validation.errors);
    else {
      openModalConfirm(
        'Add',
        'Confirmation',
        `Are You sure want to ${
          props.mode === 'EDIT' ? 'update' : 'add'
        } resource?`
      );
    }
  };

  const openModalAlert = (header, message) => {
    setModalAlert({
      isOpen: true,
      header: header,
      message: message,
    });
  };
  const closeModalAlert = () => {
    setModalAlert({
      isOpen: false,
      header: '',
      message: '',
    });
  };
  const openModalConfirm = (type, header, message) => {
    setModalConfirm({
      isOpen: true,
      type,
      message,
      header,
    });
  };
  const closeModalConfirm = () => {
    setModalConfirm({
      isOpen: false,
      type: '',
      message: '',
      header: '',
    });
  };
  const handleModalConfirm = () => {
    closeModalConfirm();
    let data = generatForCallApi(resource, props.idResourceBookingDetail);
    if (props.mode === 'EDIT') {
      data.Id = resource.Id;
      putResourceAction(data)
        .then(() => {
          resetResource();
          props.handleAddResource(resource);
        })
        .catch(err => {
          openModalAlert('Failed', err.response.data.Message);
        });
    } else {
      postResourceAction([data])
        .then(() => {
          resetResource();
          props.handleAddResource(resource);
        })
        .catch(err => {
          openModalAlert('Failed', err.response.data.Message);
        });
    }
  };
  let {
    openModal,
    currencies,
    loadingGetCurrencies,
    loadingGetCompanies,
  } = props;
  return (
    <Modal
      dimmer="blurring"
      inverted
      open={openModal}
      onClose={handleClose}
      size="tiny"
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        Add Resource <ButtonClose onClose={handleClose} />
      </Modal.Header>
      <Modal.Content>
        <LoaderModal
          loading={
            loadingPostResource ||
            props.loadingGetCompanies ||
            loadingPutResource
          }
        />
        <Form>
          <Input
            type="text"
            label="Resource Name"
            placeholder="resource name"
            value={resource.ResourceName}
            name="ResourceName"
            handleChange={handleResource}
            validate={errors}
          />
          <Input
            type="textArea"
            label="Description"
            placeholder="Description Tour"
            value={resource.Description}
            name="Description"
            handleChange={handleResource}
            validate={errors}
          />
          <Form.Group widths="equal">
            <Input type="text" label="Type" value="other" readonly />
            <Input
              type="number"
              label="Qty"
              placeholder="Qty"
              value={resource.Qty}
              name="Qty"
              handleChange={handleResource}
              validate={errors}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Input
              type="select"
              label="Currency"
              placeholder="Currency"
              value={resource.Currency}
              options={currencies}
              name="Currency"
              handleChange={handleResource}
              validate={errors}
              loading={loadingGetCurrencies}
            />
            <Input
              type="text"
              label="Price per Qty"
              placeholder="Price per Qty"
              value={resource.UnitCost.toLocaleString('id')}
              name="UnitCost"
              handleChange={handleResource}
              validate={errors}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <InputDate
                label="Use Date"
                name="Date"
                value={resource.Date}
                setDate={handleDate}
                labelStyle={{
                  display: 'block',
                  fontWeight: 'bold',
                  marginBottom: '4px',
                }}
                readonly={false}
              />
            </Form.Field>
            <Form.Field>
              <InputDate
                label="Due Date"
                name="DueDate"
                value={resource.DueDate}
                setDate={handleDate}
                labelStyle={{
                  display: 'block',
                  fontWeight: 'bold',
                  marginBottom: '4px',
                }}
                readonly={false}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Input
              type="select"
              label="Ownership"
              placeholder="Touress"
              value={resource.CompanyId}
              name="CompanyId"
              handleChange={handleResource}
              validate={errors}
              options={props.companies}
              loading={loadingGetCompanies}
            />
            <Input
              type="select"
              label="Tour Operator"
              placeholder="Tour Operator"
              value={resource.TourOperatorProfileId}
              name="TourOperatorProfileId"
              handleChange={handleResource}
              validate={errors}
              options={tourOperatorListByCode}
              disabled={resource.CompanyId === 0}
              loading={loadingGetTourOperator}
            />
          </Form.Group>
        </Form>
        <ModalAlert
          openModal={modalAlert.isOpen}
          handleClose={closeModalAlert}
          header={modalAlert.header}
          content={modalAlert.message}
        />
        <ModalConfirm
          openModal={modalConfirm.isOpen}
          size="mini"
          close={closeModalConfirm}
          confirm={handleModalConfirm}
          message={modalConfirm.message}
          nameConfirm={modalConfirm.type}
          header={modalConfirm.header}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button positive onClick={handleAddResource} content="Add resource" />
      </Modal.Actions>
    </Modal>
  );
};

ModalAddResource.propTypes = {
  openModal: PropTypes.bool,
  handleClose: PropTypes.func,
  handleAddResource: PropTypes.func,
  companies: PropTypes.array,
  loadingGetCompanies: PropTypes.bool,
  loadingGetCurrencies: PropTypes.bool,
  currencies: PropTypes.array,
  idResourceBookingDetail: PropTypes.number,
  mode: PropTypes.string,
  data: PropTypes.oneOfType('object', 'string'),
};

export default ModalAddResource;
