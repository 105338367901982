import React from 'react';
import { connect } from 'react-redux';
import { Grid, Segment, Button } from 'semantic-ui-react';
import {
  getPlaceByIdAction,
  putPlaceAction,
  postPlaceAction,
  getPlaceTypeAction,
  getPlaceCitiesListByPlaceIdAction,
  postPlaceCitiesAction,
  deletePlaceCitiesByIdAction,
} from '../placeAction';
import PropTypes from 'prop-types';
import { getCityListAction } from '../../shared/actions/cityAction';
import {
  getRegionListAction,
  getRegionAction,
} from '../../shared/actions/regionAction';
import { getCountryListAction } from '../../shared/actions/countryAction';
import { getAreaListAction } from '../../shared/actions/areaAction';
import { setPlaceIdObject } from '../services/data/setDataState';
import fieldToValidate from '../constant/fieldToValidate';
import handleValidation from '../services/validation/emptyFieldValidation';
import HeaderTitle from '../../../components/common/headerTitle';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import PlaceForm from '../form/PlaceForm';
import ModalAlert from '../../../components/modal/modalAlert';
import AddingDataWithTable from '../../../components/globalListVew/addingDataComponent';
import { HeaderTableAccessibleCity } from './../constant/HeaderTable';
import { datasetPagination } from '../../../scripts/pagination';
import ModalAccessibleCity from '../../../components/modal/modalAccessibleCity';
import FilterData from '../../../scripts/filterDataTable';
import ModalConfirmation from '../../../components/modal/modalConfirmationTwoButton';

class PlaceDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      placeList: [],
      placeById: {
        Id: '',
        Name: '',
        Address: '',
        Code: '',
        CityId: '',
        PlaceType: '',
        AddressObject: {
          Address1: '',
          Address2: '',
          Address3: '',
          AreaId: '',
          PostalCode: '',
          LandMark: '',
          Country: '',
          City: '',
          Region: '',
        },
      },
      errors: {},
      accessibleCity: [],
      activePageAccessibleCity: 1,
      listAccessCityForModal: [],
      regionForAccessibleCity: '',
      openModalAccCity: false,
      activePageForModalAccessibleCity: 1,
      searchInput: '',
      listCityByRegionForContactInfo: [],
      openModalDeleteCity: false,
      messageDeleteCity: '',
      indexDeleteCity: '',
    };
  }

  componentDidMount = () => {
    const { id } = this.props.match.params;
    if (id === 'new') {
      this.setInitialNewPlace();
    } else {
      this.props
        .getPlaceByIdAction(id)
        .then(res => this.setStatePlace(res.value.data));
      this.props.getPlaceCitiesListByPlaceIdAction(id).then(() =>
        this.setState({
          accessibleCity: this.props.placeCities,
        })
      );
    }
    this.props.getCountryListAction();
    this.props.getPlaceTypeAction();
    this.props.getRegionAction();
  };

  //=====helper=====
  setInitialNewPlace = () => {
    this.setState({
      placeById: {
        Id: '',
        Name: '',
        Address: '',
        Code: '',
        CityId: '',
        PlaceType: '',
        AddressObject: {
          Address1: '',
          Address2: '',
          Address3: '',
          AreaId: '',
          PostalCode: '',
          LandMark: '',
          Country: '',
          City: '',
          Region: '',
        },
      },
      errors: {},
      openModalAlert: false,
      headerAlert: '',
      contentAlert: '',
    });
  };

  setStatePlace = data => {
    const { Country, City, Region } = data;
    let placeById = setPlaceIdObject(data);
    this.props.getRegionListAction(Country.Id ? Country.Id : '');
    this.props.getCityListAction(Region.Id ? Region.Id : '').then(() =>
      this.setState({
        ...this.state,
        listCityByRegionForContactInfo: this.props.listCity,
      })
    );
    this.props.getAreaListAction(City.Id ? City.Id : '');
    this.setState({ placeById: placeById });
  };
  //=============handler===========
  handleChange = (e, { value, name }) => {
    const placeById = this.state.placeById;
    placeById[name] = value;
    this.setState({ placeById: placeById });
  };
  handleChangeAddress = (e, { value, name }) => {
    const AddresObjectPlace = this.state.placeById.AddressObject;
    AddresObjectPlace[name] = value;
    this.setState({
      AddresObjectPlace: AddresObjectPlace,
    });
    const getRegion = name === 'Country';
    const getCity = name === 'Region';
    const getArea = name === 'City';
    if (getRegion) {
      this.props.getRegionListAction(value);
    } else if (getCity) {
      this.props.getCityListAction(value).then(() =>
        this.setState({
          ...this.state,
          regionForAccessibleCity: value,
          listCityByRegionForContactInfo: this.props.listCity,
        })
      );
    } else if (getArea) {
      this.props.getAreaListAction(value);
    }
    this.setState({
      placeById: {
        ...this.state.placeById,
        CityId: this.state.placeById.AddressObject.City,
        Address: this.state.placeById.AddressObject.Address1,
      },
    });
  };
  savePlace = () => {
    const err = this.validate();
    if (!err) {
      const Id = this.state.placeById.Id;
      const data = this.state.placeById;
      if (Id === '') {
        this.props
          .postPlaceAction(data)
          .then(res => {
            let actionPlaceCities = this.setActionPlaceCities(
              res.value.data.Id,
              true
            );
            // eslint-disable-next-line
            Promise.all(actionPlaceCities)
              .then(() => {
                window.location = `/Place/${res.value.data.Id}`;
                this.handleOpenModalAlert('Success', 'Save Place Succesfully');
              })
              .catch(err =>
                this.handleOpenModalAlert('Failed', err.response.data.Message)
              );
          })
          .catch(err =>
            this.handleOpenModalAlert('Failed', err.response.data.Message)
          );
      } else {
        let actionPlaceCities = this.setActionPlaceCities(Id, false);
        actionPlaceCities.push(this.props.putPlaceAction(Id, data));
        // eslint-disable-next-line
        Promise.all(actionPlaceCities)
          .then(() => {
            window.location = `/Place/${Id}`;
            this.handleOpenModalAlert('Success', 'Edit Place Succesfully');
          })
          .catch(err =>
            this.handleOpenModalAlert('Failed', err.response.data.Message)
          );
        // this.props
        // .putPlaceAction(Id, data)
        // .then(() => {
        //   this.handleOpenModalAlert('Success', 'Edit Place Succesfully');
        // })
        // .catch(err =>
        //   this.handleOpenModalAlert('Failed', err.response.data.Message)
        // );
      }
    }
  };
  validate = () => {
    let isError = false;
    let { placeById } = this.state;
    let error1 = handleValidation(placeById, fieldToValidate);
    let error2 = handleValidation(placeById.AddressObject, fieldToValidate);
    let errors = { ...error1, ...error2 };
    isError = !_.isEmpty(errors);
    this.setState({
      errors: {
        ...errors,
      },
    });
    return isError;
  };

  handleOpenModalAlert = (header, content) =>
    this.setState({
      ...this.state,
      openModalAlert: true,
      headerAlert: header,
      contentAlert: content,
    });
  handleCloseModalAlert = () =>
    this.setState({
      ...this.state,
      openModalAlert: false,
    });
  //===============================================================================
  // ========================= accessible city ===================================
  pageChangeCity = (e, { activePage }) => {
    this.setState({ activePageAccessibleCity: activePage });
  };
  onClickModalAccCity = () => {
    const { Region } = this.state.placeById.AddressObject;
    let errors = {
      Region: {},
    };
    if (!Region) {
      errors.Region.Message = 'Please Select Region';
      errors.Region.error = true;
      this.setState({
        errors: {
          ...errors,
        },
      });
    } else {
      this.props
        .getCityListAction(Region)
        .then(() => this.showListAccessibleCityOnModal(Region));
    }
  };
  showListAccessibleCityOnModal = regionId => {
    let { listAccessCity } = this.props;
    let cityAccess = this.state.accessibleCity;
    listAccessCity.map(city => {
      cityAccess.map(acce => {
        if (city.Id === acce.Id) {
          city['Status'] = true;
          city['IdPlaceCity'] = acce.IdPlaceCity;
          // city = { ...city, ...acce, Status: true };
          return listAccessCity;
        }
        return listAccessCity;
      });
      return listAccessCity;
    });
    this.setState({
      openModalAccCity: true,
      listAccessCityForModal: listAccessCity,
      regionForAccessibleCity: regionId,
    });
  };
  handleClose = () =>
    this.setState({
      openModalAccCity: false,
    });
  handleChangeAccessCity = (e, { name, checked }) => {
    let list = this.state.listAccessCityForModal;
    list.map(city => {
      if (city.Id === name) {
        city['Status'] = checked;
        city['onChange'] = true;
        return list;
      }
      return list;
    });
    this.setState({ listAccessCityForModal: list });
  };
  pageChangeModalCity = (e, { activePage }) => {
    this.setState({ activePageForModalAccessibleCity: activePage });
  };
  addAccessCity = () => {
    let { listAccessCityForModal, accessibleCity, regionForAccessibleCity } = {
      ...this.state,
    };
    //accessibleCityDiffRegion ==> list city yang beda region dengan region yang dipilih
    let accessibleCityDiffRegion = accessibleCity.filter(
      e => e.Region.Id !== regionForAccessibleCity
    );
    //updateAccessibleCity ===> list city yang sama dengan region yang dipilih. lakukan update di city ini.
    listAccessCityForModal.map(city => {
      if (city.Status === true) {
        accessibleCityDiffRegion.push({
          ...city,
          Region: city.CompleteData.Region,
        });
      }
      return city;
    });
    this.setState({
      accessibleCity: accessibleCityDiffRegion,
      openModalAccCity: false,
    });
  };
  handleSearchAccesibleCity = (e, { name, value }) =>
    this.setState({ [name]: value, activePageForModalAccessibleCity: 1 });

  handleChangeRegionAccessibleCity = (e, { value }) => {
    this.setState({
      ...this.state,
      regionForAccessibleCity: value,
    });
    this.props
      .getCityListAction(value)
      .then(() => this.showListAccessibleCityOnModal(value));
  };
  deleteAccessCity = (e, { id }) => {
    this.setState({
      openModalDeleteCity: true,
      messageDeleteCity: 'Are you sure want to delete the city?',
      indexDeleteCity: id,
    });
  };
  closeDeleteConfirm = () => {
    this.setState({
      openModalDeleteCity: false,
    });
  };
  confirmDelete = () => {
    let listCity = this.state.accessibleCity;
    let id = this.state.indexDeleteCity;
    listCity.filter(item => item.Id !== id);
    let listCity1 = this.state.listAccessCityForModal;
    listCity1.map(city => {
      if (city.Id === id) {
        city['Status'] = false;
      }
      return listCity1;
    });
    this.setState({
      accessibleCity: listCity.filter(item => item.Id !== id),
      activePageAccessibleCity: 1,
      listAccessCityForModal: listCity1,
      openModalDeleteCity: false,
    });
  };
  setActionPlaceCities = (PlaceId, isCreatePlace) => {
    let { accessibleCity } = this.state;
    let { placeCities } = this.props;
    let actionPlace = [];
    // post
    accessibleCity.map(e => {
      if (!e.IdPlaceCity) {
        actionPlace.push(
          this.props.postPlaceCitiesAction({
            PlaceId,
            CityId: e.Id,
          })
        );
      }
      return e;
    });
    // delete
    if (!isCreatePlace) {
      placeCities.map(e => {
        if (e.IdPlaceCity) {
          let isDeleteCity =
            accessibleCity.filter(city => city.Id === e.City.Value).length ===
            0;
          isDeleteCity &&
            actionPlace.push(
              this.props.deletePlaceCitiesByIdAction(e.IdPlaceCity)
            );
        }
        return e;
      });
    }
    return actionPlace;
  };
  // ========================= accessible city ===================================
  render() {
    const {
      listCountry,
      listRegion,
      listPlaceType,
      listCity,
      listArea,
      listAllRegions,
      loadingGetCityByRegion,
    } = this.props;
    const {
      placeById,
      errors,
      headerAlert,
      contentAlert,
      openModalAlert,
      accessibleCity,
      activePageAccessibleCity,
      openModalAccCity,
      listAccessCityForModal,
      activePageForModalAccessibleCity,
      searchInput,
      regionForAccessibleCity,
      listCityByRegionForContactInfo,
      openModalDeleteCity,
      messageDeleteCity,
    } = this.state;
    // ================== table accessible city ===============================
    let pagedDataAccessCity = datasetPagination(accessibleCity, 3);
    let listDataAccessCity = pagedDataAccessCity[activePageAccessibleCity - 1];
    // ================== modal accessible city ===============================
    let filterData = FilterData(listAccessCityForModal, 'Name', searchInput);
    let pageDataCity = datasetPagination(filterData, 7);
    let listDataCity = pageDataCity[activePageForModalAccessibleCity - 1];
    return (
      <div>
        <Grid columns="equal" style={{ marginTop: '30px' }}>
          <Grid.Row stretched>
            <Grid.Column>
              <Segment loading={this.props.loading}>
                <HeaderTitle title="Place Detail" />
                <Grid columns="equal">
                  <Grid.Row>
                    <Grid.Column textAlign="right">
                      <Link to="/Place">
                        <Button negative>Back</Button>
                      </Link>
                      <Button onClick={this.savePlace} primary>
                        Save
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <PlaceForm
                        listCountry={listCountry}
                        listRegion={listRegion}
                        handleChange={this.handleChange}
                        handleChangeAddress={this.handleChangeAddress}
                        contentField={placeById}
                        errorInformation={errors}
                        listPlaceType={listPlaceType}
                        listCity={listCityByRegionForContactInfo}
                        listArea={listArea}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <AddingDataWithTable
                        addingDataList={accessibleCity}
                        buttonContent="Add City"
                        headerContent="Accessible City"
                        headerTable={HeaderTableAccessibleCity}
                        idButton="Accesible City"
                        listData={accessibleCity}
                        addData={this.onClickModalAccCity}
                        deleteData={this.deleteAccessCity}
                        // disablePage={props.disablePage}
                        listAddingData={listDataAccessCity}
                        pageAddingData={pagedDataAccessCity}
                        // activePage={pagedDataAccessCity}
                        activePage={activePageAccessibleCity}
                        pageChange={this.pageChangeCity}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ModalAlert
          openModal={openModalAlert}
          handleClose={this.handleCloseModalAlert}
          header={headerAlert}
          content={contentAlert}
          nameButton="OK"
        />
        <ModalAccessibleCity
          header="Accessible City"
          openModal={openModalAccCity}
          handleClose={this.handleClose}
          // listData={listAccessCityForModal}
          listData={listDataCity}
          handleChangeAccessCity={this.handleChangeAccessCity}
          pageChange={this.pageChangeModalCity}
          activePage={activePageForModalAccessibleCity}
          pageData={pageDataCity}
          addAccessCity={this.addAccessCity}
          searchInput={searchInput}
          handleChange={this.handleSearchAccesibleCity}
          regionForAccessibleCity={regionForAccessibleCity}
          listAllRegions={listAllRegions}
          handleChangeRegionAccessibleCity={
            this.handleChangeRegionAccessibleCity
          }
          listCityByRegionForAccessibleCity={listCity}
          loadingGetCityByRegion={loadingGetCityByRegion}
        />
        <ModalConfirmation
          openModal={openModalDeleteCity}
          close={this.closeDeleteConfirm}
          confirm={this.confirmDelete}
          message={messageDeleteCity}
          size="tiny"
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    listPlaceType: state.PlaceStore.getPlaceType.map(type => {
      return { text: type, value: type, key: type };
    }),
    listCountry: state.CountryStore.getCountryList.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    listRegion: state.RegionStore.getRegionList.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    listCity: state.CityStore.getCityList.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    listArea: state.AreaStore.getAreaList.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    loading: state.PlaceStore.loading,
    errors: state.PlaceStore.errors,
    listAccessCity: state.CityStore.getCityList.map(city => {
      return {
        Id: city.Id,
        Name: city.Name,
        Status: false,
        CompleteData: city,
      };
    }),
    listAllRegions: state.RegionStore.getRegion.map((region, i) => {
      return {
        key: i,
        text: region.Name,
        value: region.Id,
        completeData: region,
      };
    }),
    loadingGetCityByRegion: state.CityStore.loading,
    placeCities: state.PlaceStore.placeCities.map(e => {
      return {
        ...e,
        Id: e.City.Value,
        Name: e.City.Text,
        IdPlaceCity: e.Id,
      };
    }),
  };
};

PlaceDetail.propTypes = {
  getPlaceByIdAction: PropTypes.func,
  putPlaceAction: PropTypes.func,
  postPlaceAction: PropTypes.func,
  listPlaceType: PropTypes.array,
  getRegionListAction: PropTypes.func,
  getCityListAction: PropTypes.func,
  getAreaListAction: PropTypes.func,
  getCountryListAction: PropTypes.func,
  getPlaceTypeAction: PropTypes.func,
  listCountry: PropTypes.array,
  listRegion: PropTypes.array,
  listCity: PropTypes.array,
  listArea: PropTypes.array,
  loading: PropTypes.bool,
  errors: PropTypes.object,
  match: PropTypes.object,
  listAccessCity: PropTypes.array,
  listAllRegions: PropTypes.array,
  getRegionAction: PropTypes.func,
  loadingGetCityByRegion: PropTypes.bool,
  getPlaceCitiesListByPlaceIdAction: PropTypes.func,
  placeCities: PropTypes.array,
  postPlaceCitiesAction: PropTypes.func,
  deletePlaceCitiesByIdAction: PropTypes.func,
};
export default connect(mapStateToProps, {
  getPlaceByIdAction,
  putPlaceAction,
  postPlaceAction,
  getCityListAction,
  getRegionListAction,
  getCountryListAction,
  getAreaListAction,
  getPlaceTypeAction,
  getRegionAction,
  getPlaceCitiesListByPlaceIdAction,
  postPlaceCitiesAction,
  deletePlaceCitiesByIdAction,
})(PlaceDetail);
