import React from 'react';
import { Dimmer } from 'semantic-ui-react';
import { connect } from 'react-redux';
import AccomodationList from './accommodationList/components/AccommodationList';
import {
  resetAccomodationPageAction,
  deleteAccomodationByProfileIdAction,
  getExportDataAccomodationProfileAction,
  getExportAccomodationItemAction,
} from './accommodationAction';
import {
  // getAccomodationListAction,
  resetAccomodationAction,
  getTemplateAccomodationProfileAction,
  getSupplierListAction,
} from '../shared/actions/accommodationAction';
import ModalAlert from '../../components/modal/modalAlert';
import Loader from '../../components/loader';
import PropTypes from 'prop-types';
import ModalConfirm from '../../components/modal/modalConfirmationTwoButton';
class Accomodation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accomodationList: [],
      loadingAccomodation: true,
      failedAccomodation: false,
      modalOpen: false,
      accomodationPageNew: false,
      accomodationPageEdit: false,
      modalDeleteConfirmation: false,
      modalInformation: '',
      modalHeader: '',
      accommodationId: null,
      openModalConfirm: false,
      messageConfirm: '',
    };
  }

  static propTypes = {
    // getAccomodationListAction: PropTypes.func,
    statusAccomodation: PropTypes.string,
    listAccomodationProfile: PropTypes.array,
    resetAccomodationPageAction: PropTypes.func,
    resetAccomodationAction: PropTypes.func,
    deleteAccomodationByProfileIdAction: PropTypes.func,
    getTemplateAccomodationProfileAction: PropTypes.func,
    getExportDataAccomodationProfileAction: PropTypes.func,
    deleteAccomodationStatus: PropTypes.string,
    downloadAccomodationStatus: PropTypes.string,
    resultDownloadAccomodation: PropTypes.string,
    getAccomodationExportStatus: PropTypes.string,
    getAccomodationExport: PropTypes.string,
    loading: PropTypes.bool,
    loadingPage: PropTypes.bool,
    errors: PropTypes.object,
    errorsPage: PropTypes.object,
    getSupplierListAction: PropTypes.func,
    getAccomodationItemExportStatus: PropTypes.string,
    getAccomodationItemExport: PropTypes.string,
    getExportAccomodationItemAction: PropTypes.func,
  };
  handleClose = () =>
    this.setState({
      modalOpen: false,
      modalDeleteConfirmation: false,
      failedAccomodation: false,
    });
  componentDidMount = () => {
    // this.props.getAccomodationListAction();
    this.props.getSupplierListAction('AccommodationProfiles');
  };
  componentDidUpdate() {
    if (this.props.statusAccomodation === 'success') {
      this.setState({
        accomodationList: this.props.listAccomodationProfile,
      });
      this.setState({ loadingAccomodation: false });
      this.props.resetAccomodationAction();
      this.props.resetAccomodationPageAction();
    } else if (this.props.statusAccomodation === 'failed') {
      this.setState({ failedAccomodation: true });
      this.setState({ loadingAccomodation: false });
      this.props.resetAccomodationAction();
      this.props.resetAccomodationPageAction();
    }

    if (this.props.deleteAccomodationStatus === 'success') {
      this.setState({
        modalDeleteConfirmation: true,
        modalInformation: 'Success Delete Accomodation ',
        modalHeader: 'Success',
      });
      this.props.resetAccomodationAction();
      this.props.resetAccomodationPageAction();
      // this.props.getAccomodationListAction();
      this.props.getSupplierListAction('AccommodationProfiles');
    } else if (this.props.deleteAccomodationStatus === 'failed') {
      this.setState({
        modalDeleteConfirmation: true,
        modalInformation: 'Delete Accomodation Failed ',
        modalHeader: 'Failed',
      });
      this.props.resetAccomodationAction();
      this.props.resetAccomodationPageAction();
    }

    if (this.props.downloadAccomodationStatus === 'success') {
      var templateAccomodation = this.props.resultDownloadAccomodation;
      var elemenTemplateAccomodation = document.createElement('a');
      var TemplateAccomodation = new Blob([templateAccomodation], {
        type: 'text/plain',
      });
      elemenTemplateAccomodation.href = URL.createObjectURL(
        TemplateAccomodation
      );
      elemenTemplateAccomodation.download = 'Accomodation Template.txt';
      elemenTemplateAccomodation.click();
      this.setState({ loadingAccomodation: false });
      this.props.resetAccomodationAction();
      this.props.resetAccomodationPageAction();
    } else if (this.props.downloadAccomodationStatus === 'failed') {
      this.setState({ loadingAccomodation: false });
      this.props.resetAccomodationAction();
      this.props.resetAccomodationPageAction();
    }

    if (this.props.getAccomodationExportStatus === 'success') {
      var exportAccomodation = this.props.getAccomodationExport;
      var elementAccomodation = document.createElement('a');
      var fileAccomdation = new Blob([exportAccomodation], {
        type: 'text/plain',
      });
      elementAccomodation.href = URL.createObjectURL(fileAccomdation);
      elementAccomodation.download = 'Accomodation Export.txt';
      elementAccomodation.click();
      this.setState({ loadingAccomodation: false });
      this.props.resetAccomodationAction();
      this.props.resetAccomodationPageAction();
    } else if (this.props.getAccomodationExportStatus === 'failed') {
      this.setState({ loadingAccomodation: false });
      this.props.resetAccomodationAction();
      this.props.resetAccomodationPageAction();
    }

    if (this.props.getAccomodationItemExportStatus === 'success') {
      exportAccomodation = this.props.getAccomodationItemExport;
      elementAccomodation = document.createElement('a');
      fileAccomdation = new Blob([exportAccomodation], {
        type: 'text/plain',
      });
      elementAccomodation.href = URL.createObjectURL(fileAccomdation);
      elementAccomodation.download = 'Accomodation Item Export.txt';
      elementAccomodation.click();
      this.setState({ loadingAccomodation: false });
      this.props.resetAccomodationAction();
    } else if (this.props.getAccomodationItemExportStatus === 'failed') {
      this.props.resetAccomodationAction();
    }
  }
  ButtonNewAccomodation = () => {
    // eslint-disable-next-line
    this.props.history.push('/Accomodation-Profile');
  };
  ButttonEditAccomodation = e => {
    // eslint-disable-next-line
    this.props.history.push(`/Accomodation/Edit/${e.target.value}`);
  };
  ButtonUploadDataAccomodation = () => {
    // eslint-disable-next-line
    this.props.history.push(`/Upload-Master-Data/${'Accomodation'}`);
  };
  ButtonDownloadTemplate = () => {
    this.props.getTemplateAccomodationProfileAction();
    this.setState({ loadingAccomodation: true });
  };
  ButtonExportAccomodation = () => {
    this.props.getExportDataAccomodationProfileAction();
    this.setState({ loadingAccomodation: true });
  };
  ButtonDeleteAccomodation = () => {
    this.props.deleteAccomodationByProfileIdAction(this.state.accommodationId);
    this.closeModalConfirm();
  };
  handleDeleteAccommodation = e => {
    let name = this.state.accomodationList.find(
      // eslint-disable-next-line
      item => item.Id == e.target.value
    ).Name;
    this.setState({
      accommodationId: e.target.value,
      openModalConfirm: true,
      message: `Are you sure want to delete '${name}'?`,
    });
  };
  closeModalConfirm = () => {
    this.setState({ openModalConfirm: false });
  };

  buttonExportItem = () => {
    this.props.getExportAccomodationItemAction();
    this.setState({ loadingAccomodation: true });
  };
  render() {
    let { loadingAccomodation, failedAccomodation } = this.state;
    let loading =
      loadingAccomodation || this.props.loading || this.props.loadingPage;
    let openModal = failedAccomodation;
    //
    let errors = this.props.errors ? this.props.errors : this.props.errorsPage;
    return (
      <div>
        <Dimmer.Dimmable blurring dimmed={loading}>
          <Loader loading={loading} />
          <AccomodationList
            data={this.state.accomodationList}
            type="accomodation"
            filterBy="Name"
            buttonNew={this.ButtonNewAccomodation}
            butttonExport={this.ButtonExportAccomodation}
            buttonUploadData={this.ButtonUploadDataAccomodation}
            buttonDownloadTemplate={this.ButtonDownloadTemplate}
            butttonEdit={this.ButttonEditAccomodation}
            buttonDelete={this.handleDeleteAccommodation}
            buttonExportItem={this.buttonExportItem}
          />
          <ModalAlert
            openModal={openModal}
            handleClose={this.handleClose}
            header="Failed"
            content={errors.global}
            nameButton="OK"
          />
          <ModalAlert
            openModal={this.state.modalDeleteConfirmation}
            handleClose={this.handleClose}
            header={this.state.modalHeader}
            content={this.state.modalInformation}
            nameButton="OK"
          />
          <ModalConfirm
            openModal={this.state.openModalConfirm}
            size="mini"
            close={this.closeModalConfirm}
            confirm={this.ButtonDeleteAccomodation}
            message={this.state.message}
            header="Confirmation"
          />
        </Dimmer.Dimmable>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    // listAccomodationProfile: state.AccomodationStore.getAccomodationList,
    // statusAccomodation: state.AccomodationStore.getAccomodationListStatus,
    listAccomodationProfile: state.AccomodationStore.getSupplierList,
    statusAccomodation: state.AccomodationStore.getSupplierListStatus,
    deleteAccomodationStatus: state.AccomodationStore.deleteAccomodationStatus,
    downloadAccomodationStatus:
      state.AccomodationStore.getAccomodationTemplateStatus,
    resultDownloadAccomodation: state.AccomodationStore.getAccomodationTemplate,
    getAccomodationExport: state.AccomodationStore.getAccomodationExport,
    getAccomodationExportStatus:
      state.AccomodationStore.getAccomodationExportStatus,
    loading: state.AccomodationStore.loading,
    loadingPage: state.AccomodationPageStore.loading,
    errors: state.AccomodationStore.errors,
    errorsPage: state.AccomodationPageStore.errors,
    // getExportDataItem: state.AccomodationStore.getExportDataItemById,
    // getExportDataItemStatus:
    //   state.AccomodationStore.getExportDataItemByIdStatus,
    getAccomodationItemExport:
      state.AccomodationPageStore.getAccomodationItemExport,
    getAccomodationItemExportStatus:
      state.AccomodationPageStore.getAccomodationItemExportStatus,
  };
}
export default connect(
  mapStateToProps,
  {
    // getAccomodationListAction,
    resetAccomodationAction,
    resetAccomodationPageAction,
    deleteAccomodationByProfileIdAction,
    getTemplateAccomodationProfileAction,
    getExportDataAccomodationProfileAction,
    getSupplierListAction,
    getExportAccomodationItemAction,
  }
)(Accomodation);
