import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form, Input, Grid } from 'semantic-ui-react';
import ButtonClose from '../../../../../../components/button/buttonCloseModal';
import CurrencyFormat from 'react-currency-format';
import InputWithLabel from '../../../../../../components/input/inputWithLabel';
import InputDate from '../../../../../../components/input/inputDate';
const modalSupplierPrice = props => (
  <Modal open={props.openModalPrice} size="tiny">
    <Modal.Header>
      Price for {' ' + props.headerTransportationUnit}
      {props.transportationOriginRegion
        ? ' in ' + props.transportationOriginRegion.Name
        : ''}
      <ButtonClose onClose={props.handleClose} />
    </Modal.Header>
    <Modal.Content>
      <Grid columns={2} padded="vertically">
        <Grid.Column>
          <Form>
            <InputWithLabel
              inline={false}
              label="Type"
              placeholder="Type"
              type="select"
              required
              name={props.type === 'excursion' ? 'ServicePriceType' : 'Type'}
              value={
                props.type === 'excursion'
                  ? props.data.ServicePriceType
                  : props.data.Type
              }
              options={props.servicePriceType}
              handleChange={props.handleChangePrice}
              validate={props.errors}
            />
          </Form>
          <Form>
            <InputDate
              label="Start Date"
              name="StartDate"
              value={props.data.StartDate}
              setDate={props.handleChangePriceDate}
              labelStyle={{
                display: 'block',
                fontWeight: 'bold',
                marginBottom: '4px',
              }}
              validate={props.errorsDate}
              required={true}
              readonly={false}
            />
          </Form>
          <Form>
            <InputWithLabel
              inline={false}
              type="select"
              label="Currency"
              placeholder="Currency"
              name="Currency"
              required
              value={props.data.Currency}
              options={props.currenciesList}
              handleChange={props.handleChangePrice}
              validate={props.errors}
            />
          </Form>
          <Form>
            <InputWithLabel
              inline={false}
              type="number"
              label={props.type === 'excursion' ? 'Minimum Pax' : 'Hour'}
              required
              value={
                props.type === 'excursion'
                  ? props.data.MinimumPax
                  : props.data.MinPax
              }
              name={props.type === 'excursion' ? 'MinimumPax' : 'MinPax'}
              placeholder={props.type === 'excursion' ? 'Minimum Pax' : 'Hour'}
              handleChange={props.handleChangePrice}
              validate={props.errors}
            />
          </Form>
        </Grid.Column>
        <Grid.Column>
          <Form>
            <InputWithLabel
              inline={false}
              type="type"
              label="Description"
              required
              value={props.data.Description}
              name="Description"
              placeholder="Description"
              handleChange={props.handleChangePrice}
              validate={props.errors}
            />
          </Form>
          <Form>
            <InputDate
              label="End Date"
              name="EndDate"
              value={props.data.EndDate}
              setDate={props.handleChangePriceDate}
              labelStyle={{
                display: 'block',
                fontWeight: 'bold',
                marginBottom: '4px',
              }}
              validate={props.errorsDate}
              readonly={false}
            />
          </Form>
          <Form>
            <Form.Field>
              <label>Price</label>
              <CurrencyFormat
                // thousandSeparator={true}
                thousandSeparator={'.'}
                decimalSeparator={','}
                decimalScale={2}
                value={props.data.Cost}
                textAlign="right"
                name={'Cost'}
                id={'Cost'}
                customInput={Input}
                onValueChange={e =>
                  props.handleChangePriceDate(e.value, 'Cost')
                }
              />
            </Form.Field>
          </Form>
          <Form>
            <InputWithLabel
              inline={false}
              type="number"
              name="CutOffDay"
              label="Cut Off Day"
              value={props.data.CutOffDay}
              required
              placeholder="Cut Off Day"
              handleChange={props.handleChangePrice}
              validate={props.errors}
            />
          </Form>
        </Grid.Column>
      </Grid>
    </Modal.Content>
    {/* <label style={StylesTextfieldWithError.labelRequired}>
              {props.errors.StartDate}
            </label> */}
    {/* <label style={StylesTextfieldWithError.labelRequired}>
              {props.errors.EndDate}
            </label> */}
    <Modal.Actions>
      <Button content="Save" positive onClick={props.savePriceDetail} />
    </Modal.Actions>
  </Modal>
);

modalSupplierPrice.propTypes = {
  openModalPrice: PropTypes.func,
  header: PropTypes.string,
  handleClose: PropTypes.func,
  content: PropTypes.string,
  data: PropTypes.object,
  servicePriceType: PropTypes.array,
  handleChangePrice: PropTypes.object,
  handleChangePriceDate: PropTypes.object,
  errors: PropTypes.object,
  savePriceDetail: PropTypes.func,
  currenciesList: PropTypes.array,
  headerTransportationUnit: PropTypes.string,
  transportationOriginRegion: PropTypes.string,
  type: PropTypes.bool,
  errorsDate: PropTypes.object,
};

export default modalSupplierPrice;
