import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Form } from 'semantic-ui-react';
import InputWithLabel from '../../../../components/input/inputWithLabel';

const GeneralInfoTransportationProfile = props => {
  return (
    <div>
      <Grid columns="equal">
        <Grid.Row stretched>
          <Grid.Column width={15}>
            <Header as="h3" content="General Information" />
            <Form>
              <Form.Field>
                <InputWithLabel
                  label="Transportation Partner Name"
                  type="text"
                  name="Name"
                  value={props.transportationData.Name}
                  handleChange={props.handleChange}
                  required
                  fluid={true}
                  validate={props.errors}
                  placeholder="Transportation Partner Name"
                  disabled={props.disablePage}
                />
              </Form.Field>
              <Form.Group>
                <Form.Field width={8}>
                  <InputWithLabel
                    label="Category"
                    type="select"
                    name="TransportationProfileCategoryId"
                    value={
                      props.transportationData.TransportationProfileCategoryId
                    }
                    handleChange={props.handleChange}
                    required
                    options={props.listTransportationCatagories}
                    fluid={true}
                    validate={props.errors}
                    placeholder="Category"
                    disabled={props.disablePage}
                    inline={false}
                  />
                </Form.Field>
                <Form.Field width={8}>
                  <InputWithLabel
                    label="Rating"
                    type="select"
                    name="TransportationProfileRatingId"
                    value={
                      props.transportationData.TransportationProfileRatingId
                    }
                    handleChange={props.handleChange}
                    required
                    options={props.rating}
                    fluid={true}
                    validate={props.errors}
                    placeholder="Rating"
                    disabled={props.disablePage}
                    inline={false}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
            {/* <TextFieldFluidOnPage
              title="Transportation Partner Name"
              name="Name"
              handleChange={props.handleChange}
              placeholder="Transportation Partner Name"
              data={props.transportationData.Name}
              errors={props.errors.Name}
              required={true}
              style={StylesTextfieldWithError.widthTextFieldonFluid}
              disable={props.disablePage}
            /> */}
            {/* <DoubleDropdownWithError
              placeholder1="Category"
              placeholder2="Rating"
              name1="TransportationProfileCategoryId"
              name2="TransportationProfileRatingId"
              title1="Category"
              title2="Rating"
              handleChange={props.handleChange}
              option2={props.rating}
              option1={props.listTransportationCatagories}
              valueDropDown1={
                props.transportationData.TransportationProfileCategoryId
              }
              valueDropDown2={
                props.transportationData.TransportationProfileRatingId
              }
              error1={props.errors.TransportationProfileCategoryId}
              error2={props.errors.TransportationProfileRatingId}
              disable={props.disablePage}
            /> */}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

GeneralInfoTransportationProfile.propTypes = {
  handleChange: PropTypes.func,
  rating: PropTypes.array,
  transportationData: PropTypes.object,
  listTransportationCatagories: PropTypes.array,
  errors: PropTypes.shape({
    Name: PropTypes.string,
    TransportationProfileCategoryId: PropTypes.string,
    TransportationProfileRatingId: PropTypes.string,
  }),
  disablePage: PropTypes.bool,
};
export default GeneralInfoTransportationProfile;
