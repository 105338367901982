import * as types from '../../../actions/actionTypes';

const initialState = {
  loading: false,
  listEmailSettings: [],
  emailSettingById: {},
  getListEmailSettingsStatus: '',
  getEmailSettingByIdStatus: '',
  postEmailSettingByIdStatus: '',
  putEmailSettingByIdStatus: '',
  deleteEmailSettingByIdStatus: '',
  listPartnerEmployee: [],
  listPartnerEmployeeStatus: '',
  errors: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TOUR_OPERATOR_EMAIL_SETTING_ALL:
      return { ...state };

    case types.GET_TOUR_OPERATOR_EMAIL_SETTING_ALL_PENDING:
      return { ...state, loading: true };
    case types.GET_TOUR_OPERATOR_EMAIL_SETTING_ALL_FULFILLED:
      return {
        ...state,
        loading: false,
        listEmailSettings: action.payload.data,
      };
    case types.GET_TOUR_OPERATOR_EMAIL_SETTING_ALL_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data },
        loading: false,
      };

    case types.GET_TOUR_OPERATOR_EMAIL_SETTING_BY_ID:
      return { ...state };
    case types.GET_TOUR_OPERATOR_EMAIL_SETTING_BY_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_TOUR_OPERATOR_EMAIL_SETTING_BY_ID_FULFILLED:
      return {
        ...state,
        loading: false,
        emailSettingById: action.payload.data,
      };
    case types.GET_TOUR_OPERATOR_EMAIL_SETTING_BY_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data },
        loading: false,
      };

    case types.POST_TOUR_OPERATOR_EMAIL_SETTING:
      return { ...state };
    case types.POST_TOUR_OPERATOR_EMAIL_SETTING_PENDING:
      return { ...state, loading: true };
    case types.POST_TOUR_OPERATOR_EMAIL_SETTING_FULFILLED:
      return { ...state, loading: false };
    case types.POST_TOUR_OPERATOR_EMAIL_SETTING_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data },
        loading: false,
      };

    case types.PUT_TOUR_OPERATOR_EMAIL_SETTING:
      return { ...state };
    case types.PUT_TOUR_OPERATOR_EMAIL_SETTING_PENDING:
      return { ...state, loading: true };
    case types.PUT_TOUR_OPERATOR_EMAIL_SETTING_FULFILLED:
      return { ...state, loading: false };
    case types.PUT_TOUR_OPERATOR_EMAIL_SETTING_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data },
        loading: false,
      };

    case types.DELETE_TOUR_OPERATOR_EMAIL_SETTING:
      return { ...state };
    case types.DELETE_TOUR_OPERATOR_EMAIL_SETTING_PENDING:
      return { ...state, loading: true };
    case types.DELETE_TOUR_OPERATOR_EMAIL_SETTING_FULFILLED:
      return { ...state, loading: false };
    case types.DELETE_TOUR_OPERATOR_EMAIL_SETTING_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data },
        loading: false,
      };
    case types.GET_TOUR_OPERATOR_EMAIL_SETTING_BY_OPERATOR:
      return { ...state };
    case types.GET_TOUR_OPERATOR_EMAIL_SETTING_BY_OPERATOR_PENDING:
      return { ...state, loading: true };
    case types.GET_TOUR_OPERATOR_EMAIL_SETTING_BY_OPERATOR_FULFILLED:
      return {
        ...state,
        loading: false,
        listEmailSettings: action.payload.data,
      };
    case types.GET_TOUR_OPERATOR_EMAIL_SETTING_BY_OPERATOR_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data },
        loading: false,
      };

    case types.RESET_TOUR_OPERATOR_EMAIL_SETTING_STATUS:
      return {
        ...state,
        getListEmailSettingsStatus: '',
        getEmailSettingByIdStatus: '',
        postEmailSettingByIdStatus: '',
        putEmailSettingByIdStatus: '',
        deleteEmailSettingByIdStatus: '',
      };
    default:
      return state;
  }
};

export default reducer;
