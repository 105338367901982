import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import Pagination from '../../../../../components/common/pagination';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../../../scripts/pagination';
import { convertDateFormat } from '../../../../../scripts/moment';
const TablePaymentHistory = props => {
  const [activePage, setActivePage] = useState(1);
  const pageChange = (e, { activePage }) => {
    setActivePage(activePage);
  };
  let { listHistoryTransaction } = props;
  let pagedData = datasetPagination(listHistoryTransaction, 3);
  let listData = pagedData[activePage - 1];
  let { numbersFrom, numbersTo } = getNumberFromTo(listData, activePage, 3);
  return (
    <>
      <Table basic="very" selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Payment Date</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Value</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {listData && listData.length > 0 ? (
            listData.map((e, idx) => (
              <Table.Row key={idx}>
                <Table.Cell>
                  {convertDateFormat(e.PaymentDate, 'DD MMM YY')}
                </Table.Cell>
                <Table.Cell>{e.Description}</Table.Cell>
                <Table.Cell>
                  {e.CurrencyId}{' '}
                  {e.PaymentValue ? e.PaymentValue.toLocaleString('ID') : 0}
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan={3} textAlign="center">
                No Data
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <Pagination
        // totalPages={pagedData.length}
        // onPageChange={this.pageChange}
        // activePage={this.state.activePage}
        pagedData={pagedData}
        numbersTo={numbersTo}
        numbersFrom={numbersFrom}
        data={listHistoryTransaction}
        pageChange={pageChange}
        activePage={activePage}
      />
    </>
  );
};
TablePaymentHistory.propTypes = {
  listHistoryTransaction: PropTypes.array,
};
export default React.memo(TablePaymentHistory);
