import React, { Component } from 'react';
import { Table, Grid, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../../../../scripts/pagination';
import { ucwords } from '../../../../../../scripts/convertWord';
import {
  HeaderTableSpecialAdjusment,
  HeaderTableSpecialAdjusmentWithoutCost,
} from '../../../../../tourTransaction/transactionDetail/constant/HeaderTable';
import Loader from '../../../../../../components/loader/loaderInModal';
import Pagination from '../../../../../../components/common/pagination';
import ModalSpecialAdjusment from '../../../../../shared/components/modal/ModalSpecialAdjusment';

class TableSpecialAdjustment extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      activePage: 1,
      pageLength: 3,
      openModal: false,
      // specialAdjusments: [],
      IsHiddenData: false,
      selectedSpecialAdjusment: null,
    };
  }

  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };

  openCloseModal = () => {
    this.setState(state => ({
      ...state,
      openModal: !state.openModal,
      selectedSpecialAdjusment: null,
    }));
  };

  editSpecialAdjusment = (e, { name }) => {
    let { listData } = this.props;
    let selectedSpecialAdjusment = { ...listData[name], idx: name };
    this.setState({ ...this.state, selectedSpecialAdjusment, openModal: true });
  };
  deleteSpecialAdjusment = (e, { name }) => {
    let specialAdjusments = [...this.props.listData];
    specialAdjusments[name].IsDelete = !specialAdjusments[name].IsDelete;
    this.props.handleSpecialAdjustment(specialAdjusments);
  };

  saveSpecialAdjusment = async specialAdjusment => {
    let specialAdjusments = [...this.props.listData];
    if (specialAdjusment.idx === null) {
      specialAdjusments.push({
        ...specialAdjusment,
        ActionType: 'Add',
        Idx: specialAdjusments.length,
      });
    } else {
      specialAdjusment =
        specialAdjusment.ActionType === 'Add'
          ? specialAdjusment
          : { ...specialAdjusment, ActionType: 'Update' };
      specialAdjusments[specialAdjusment.idx] = specialAdjusment;
    }
    await this.props.handleSpecialAdjustment(specialAdjusments);
    this.openCloseModal();
  };

  render() {
    let {
      currentPackage,
      CurrencyId,
      additionalItemTypeList,
      listData,
      idTransaction,
      isSales,
      isAdministrator,
      isOperation,
      isAcceptedQuotation,
    } = this.props;
    let { activePage } = this.state;
    listData = listData.filter(e => !e.IsDelete);
    let dataPerPage = 3;
    let pagedData = datasetPagination(listData, dataPerPage);
    listData = pagedData[activePage - 1];
    const props = this.props;

    let { numbersFrom, numbersTo } = getNumberFromTo(
      listData,
      activePage,
      dataPerPage
    );
    let disableSpecialAdjustment =
      this.props.statusPackage === 'create' && CurrencyId
        ? false
        : this.props.typePackage === 'Quotation'
        ? false
        : currentPackage
        ? currentPackage.disabled
        : false;
    disableSpecialAdjustment =
      this.props.typePackage === 'Quotation' && isAcceptedQuotation
        ? true
        : disableSpecialAdjustment;
    let isAllowedEdit =
      this.props.typePackage === 'Quotation' && isAcceptedQuotation
        ? false
        : isSales || isAdministrator;
    let isHidePrice = isOperation;
    let headerTable = isHidePrice
      ? HeaderTableSpecialAdjusmentWithoutCost
      : HeaderTableSpecialAdjusment;

    return (
      <div>
        <Loader loading={props.loading} />
        <Grid>
          <Grid.Row>
            <Grid.Column width={12} />
            <Grid.Column width={4}>
              {isAllowedEdit && (
                <Button
                  primary
                  onClick={this.openCloseModal}
                  disabled={disableSpecialAdjustment}
                >
                  Add Special Adjusment
                </Button>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Table basic="very" selectable fixed>
          <Table.Header>
            <Table.Row>
              {headerTable.map((headTableContent, index) => {
                return (
                  <Table.HeaderCell key={index}>
                    {headTableContent}
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {listData ? (
              listData.map(data => {
                return (
                  data.Qty > 0 && (
                    <Table.Row key={data.Idx}>
                      <Table.Cell>
                        {data.AdditionalItemType
                          ? data.AdditionalItemType.Text
                          : '-'}
                      </Table.Cell>
                      <Table.Cell>{data.Description}</Table.Cell>
                      <Table.Cell>
                        {data.IsHiddenData ? 'Yes' : 'No'}
                      </Table.Cell>
                      <Table.Cell>
                        {data.PriceEffect ? ucwords(data.PriceEffect) : '-'}
                      </Table.Cell>
                      <Table.Cell>{data.Qty}</Table.Cell>
                      {isHidePrice || (
                        <>
                          <Table.Cell>
                            <span
                              style={data.UnitCost < 0 ? { color: 'red' } : {}}
                            >
                              {data.CurrencyId}{' '}
                              {data.UnitCost < 0 ? (
                                <span>
                                  ({(data.UnitCost * -1).toLocaleString('id')})
                                </span>
                              ) : (
                                data.UnitCost.toLocaleString('id')
                              )}
                            </span>
                          </Table.Cell>
                          <Table.Cell>
                            <span
                              style={data.TotalCost < 0 ? { color: 'red' } : {}}
                            >
                              {data.CurrencyId}{' '}
                              {data.TotalCost < 0 ? (
                                <span>
                                  ({(data.TotalCost * -1).toLocaleString('id')})
                                </span>
                              ) : (
                                data.TotalCost.toLocaleString('id')
                              )}
                            </span>
                          </Table.Cell>
                          <Table.Cell>
                            <Button
                              name={data.Idx}
                              primary
                              onClick={this.editSpecialAdjusment}
                              value={data.Id}
                              icon="edit"
                              disabled={
                                data.IsDelete ? true : disableSpecialAdjustment
                              }
                            />
                            <Button
                              name={data.Idx}
                              negative
                              onClick={this.deleteSpecialAdjusment}
                              value={data.Id}
                              icon="delete"
                              disabled={disableSpecialAdjustment}
                            />
                          </Table.Cell>
                        </>
                      )}
                    </Table.Row>
                  )
                );
              })
            ) : (
              <Table.Row>
                <Table.Cell colSpan={headerTable.length} textAlign="center">
                  There is no special adjustments
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        <Pagination
          pagedData={pagedData}
          numbersTo={numbersTo}
          numbersFrom={numbersFrom}
          data={listData}
          pageChange={this.pageChange}
          activePage={this.state.activePage}
        />
        <ModalSpecialAdjusment
          openModal={this.state.openModal}
          handleClose={this.openCloseModal}
          header="Detail Special Adjustment"
          selectedSpecialAdjusment={this.state.selectedSpecialAdjusment}
          currencyId={this.props.CurrencyId}
          currenciesList={this.props.currencies}
          saveSpecialAdjusment={this.saveSpecialAdjusment}
          disableCurrency={this.props.CurrencyId ? true : false}
          additionalItemTypeList={additionalItemTypeList}
          idTransaction={idTransaction}
        />
      </div>
    );
  }
}

TableSpecialAdjustment.propTypes = {
  listData: PropTypes.array,
  currencies: PropTypes.array,
  handleSpecialAdjustment: PropTypes.func,
  CurrencyId: PropTypes.string,
  currentPackage: PropTypes.object,
  loading: PropTypes.bool,
  statusPackage: PropTypes.string,
  additionalItemTypeList: PropTypes.array,
  // changeSpecialAdjustment: PropTypes.func,
  typePackage: PropTypes.string,
  idTransaction: PropTypes.string,
  isSales: PropTypes.bool,
  isAdministrator: PropTypes.bool,
  isOperation: PropTypes.bool,
  isAcceptedQuotation: PropTypes.bool,
};
export default TableSpecialAdjustment;
