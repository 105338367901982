export const ListDropdownImage = [
  {
    key: 0,
    text: 'Choose Type ',
    value: 0,
  },
  {
    key: 'AccomodationProfile',
    text: 'Accommodation Profile',
    value: 'AccomodationProfile',
  },
  {
    key: 'AccomodationRoom',
    text: 'Accommodation Room',
    value: 'AccomodationRoom',
  },
  { key: 'Excursion', text: 'Excursion', value: 'Excursion' },
  {
    key: 'RestaurantProfile',
    text: 'Restaurant Profile',
    value: 'RestaurantProfile',
  },
  { key: 'RestaurantMenu', text: 'Restaurant Menu', value: 'RestaurantMenu' },
  {
    key: 'TransportationProfile',
    text: 'Transportation Profile',
    value: 'TransportationProfile',
  },
  {
    key: 'TransportationUnit',
    text: 'Transportation Unit',
    value: 'TransportationUnit',
  },
];
