import apiClient from '../apiClient';
import { GET_ACCOMODATION_ITEM_BYPROFILE_ID_REQUEST_DATE_URL } from '../apiUrl';

const getAccomodationItemByProfileIdAndRequestDateApi = data => {
  return apiClient.get(
    `${GET_ACCOMODATION_ITEM_BYPROFILE_ID_REQUEST_DATE_URL}?profileId=${
      data.profileId
    }&requestedDate=${data.requestedDate}&endDate=${
      data.checkOutDate
    }&useExtraBed=${data.useExtraBed}&useChildExtraBed=${
      data.useChildExtraBed
    }&useSharingBed=${data.useSharingBed}&sharingRoomPax=${
      data.sharingRoomPax
    }&singleRoomPax=${data.singleRoomPax}`
  );
};

export default getAccomodationItemByProfileIdAndRequestDateApi;
