const setExchangeRateData = (value, currentData) => {
  let objectData = {
    id: value,
    currencyFrom: currentData.CurrencyFromId,
    currencyTo: currentData.CurrencyToId,
    valueRate: currentData.Value,
    startDate: currentData.EffectiveDate,
  };
  return objectData;
};

const setPostData = (id, dataRate) => {
  let objectRequest = {
    Id: id,
    EffectiveDate: dataRate.startDate,
    Value: dataRate.valueRate,
    CurrencyFromId: dataRate.currencyFrom,
    CurrencyToId: dataRate.currencyTo,
  };
  return objectRequest;
};

export { setExchangeRateData, setPostData };
