import { WIDTH_SIDE_BAR } from '../../assets/styles/size';
const GRAY_COLOR = '#6b82e6';
const styles = {
  sidebar: {
    width: `${WIDTH_SIDE_BAR}px`,
  },
  header: {
    color: 'white',
    textAlign: 'left',
  },
  divider: {
    marginTop: '10px',
    marginBottom: '10px',
    color: 'white',
  },
  accordionTitle: {
    textAlign: 'left',
    width: '228px',
  },
  accordionTitleLevel2: {
    textAlign: 'left',
    width: '200px',
  },
  iconDropdown: {
    float: 'right',
  },
  iconDropdownProfile: {
    float: 'right',
    marginTop: '15px',
    marginRight: '10px',
  },
  headerTopListMenu: {
    paddingTop: '10px',
    paddingBottom: '0px',
  },
  topListMenu: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  active: {
    backgroundColor: GRAY_COLOR,
  },
  menu: {
    margin: '0px',
    color: 'white',
    textAlign: 'left',
  },
  submenu: {
    textAlign: 'left',
    color: 'white',
    marginLeft: '12px',
    marginTop: '10px',
  },
  submenuIcon: {
    marginRight: '27px',
  },
  iconProfile: {
    marginLeft: '10px',
    marginRight: '20px',
  },
};

export default styles;
