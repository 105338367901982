import React from 'react';
import PropTypes from 'prop-types';
import { Form, Grid } from 'semantic-ui-react';
import InputAmount from '../../../../../../components/input/inputAmount';
import styles from '../../Styles';
const FormFreeOfCharge = props => (
  <div>
    {props.capacity === 'Large' ? (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column width={11} verticalAlign="middle">
              <span>Amount Free of Charge (FOC) Person from your guests:</span>
            </Grid.Column>
            <Grid.Column width={5} style={{ paddingLeft: '8px' }}>
              <Form>
                <Form.Group>
                  <Form.Field style={{ width: '100%', paddingLeft: '4px' }}>
                    <InputAmount
                      label="Foc Amount"
                      labelStyle={styles.textAlignCenter}
                      inputStyle={styles.borderBottom}
                      objectname="foc"
                      name="Qty"
                      value={props.foc.Qty}
                      handleIncrease={props.handleIncrease}
                      handleDecrease={props.handleDecrease}
                      handleOnChange={props.handleChange}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    ) : null}
  </div>
);

FormFreeOfCharge.propTypes = {
  foc: PropTypes.object,
  capacity: PropTypes.string,
  handleIncrease: PropTypes.func,
  handleDecrease: PropTypes.func,
  handleChange: PropTypes.func,
};

export default FormFreeOfCharge;
