const headerTableLocationAccomodationItemPrice = [
  'Service Type',
  'Rank Type',
  'Rank Value',
  'Minimum Night',
  'Start Date',
  'End Date',
  'Cut off Date',
  'Cut off Day',
  'Action',
];

export default headerTableLocationAccomodationItemPrice;
