import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Message, Divider, Grid } from 'semantic-ui-react';
import { StyleModal } from '../../../../assets/styles/size';
import LoaderModal from '../../../../components/modal/loaderModal';
import DescriptionManualPayment from './ModalDescriptionManualPayment';
import TableManualPayment from './ModalTableManualPayment';
import ContentCalculate from './ModalCalculate';
import { connect } from 'react-redux';
import {
  resetStatusTransactionAction,
  getTourTransactioBillsAction,
  getTourTransactionBilledAction,
  postDemoManualPaymentAction,
  getPayableSupplemetItemAction,
  getTransactionByIdAction,
  postConfirmTransaction,
  postEmailSendConfirmation,
} from '../../../../actions/tourTransactionAction';
import { getCurrenciesAction } from '../../../../actions/currenciesAction';
import { getCustomerProfileByCodeAction } from '../../actions/customerProfileAction';
import ModalAlert from '../../../../components/modal/modalAlert';
import ModalBalanceDetail from '../../../../components/modal/modalTourTransaction/modalTouressWallet';
import moment from 'moment';
import ModalManualSendEmail from './ModalManualSendEmail';
class ModalPaymentTransaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentDetail: {
        Id: 0,
        Customer: '',
        TourName: '',
        CurrencyId: '',
        TourPrice: '',
        TourPaid: '',
      },
      tourTransactionConfirmationObject: {
        CustomValue: 0,
      },
      additonalItemPay: [],
      totalPayableValue: 0,
      balanceDetail: {
        CompanyCode: '',
        CashFlowType: 'Credit',
        Name: '',
      },
      openSendManualEmail: false,
      isChooseSendEmail: false,
      isSendMail: false,
      // totalMandatory: 0,
    };
  }
  setConfirmationDemo = data => {
    this.setState({
      customerBalance: data.CompanyBalance,
      manualPaymentDemo: data,
    });
  };
  handleCloseModalAlert = () => {
    const { reloadPage, handleClose } = this.props;
    this.setState({ openModalAlert: false }, () => {
      handleClose();
      reloadPage();
    });
  };
  handleCloseTouressWallet = () => {
    this.setState({ openModalTouressWallet: false });
  };
  setTransactionBilled = data => {
    this.setState({
      totalPayableValue: data.RestOfTheBill ? data.RestOfTheBill : 0,
      tourTransactionConfirmationObject: {
        ...this.state.tourTransactionConfirmationObject,
        tourTransactionBilled: data.RestOfTheBill ? data.RestOfTheBill : 0,
        tourTransactionBilledStatic: data.RestOfTheBill
          ? data.RestOfTheBill
          : 0,
        // tourTransactionBilled: data.RestOfTheBill ? data.RestOfTheBill : 0,
        // tourTransactionBilledStatic: data.RestOfTheBill
        //   ? data.RestOfTheBill
        //   : 0,
      },
      transactionBilled: data,
      RestOfTheBillWithoutSupplement: data.RestOfTheBillWithoutSupplement,
      RestOfTheBillWithoutSupplementStatic: data.RestOfTheBillWithoutSupplement,
    });
  };
  setTransactionBills = data => {
    const transactionBills = data[data.length - 1];
    this.setState({
      tourTransactionConfirmationObject: {
        ...this.state.tourTransactionConfirmationObject,
        tourTransactionBillWithoutSupplement:
          transactionBills.RestOfTheBillWithoutSupplement,
        tourTransactionBill: transactionBills.RestOfTheBill,
      },
    });
  };
  setAdditionalInfoPrice = data => {
    let total = data.reduce((total, num) => {
      return total + num.MinimumPayable;
    }, 0);
    return this.setState({
      totalPayableAdditionalItem: total,
      additionalItem: data,
      additonalItemPay: data,
    });
  };
  callFunctionPaymentTransaction = async (Id, confirmationDemo) => {
    this.props.getPayableSupplemetItemAction(Id).then(res => {
      this.setAdditionalInfoPrice(res.value.data);
      // this.getTotalMandatory(res.value.data);
    });
    await this.props
      .getTourTransactioBillsAction(Id)
      .then(res => this.setTransactionBills(res.value.data));

    await this.props
      .getTourTransactionBilledAction(Id)
      .then(res => this.setTransactionBilled(res.value.data));

    await this.props
      .postDemoManualPaymentAction(confirmationDemo)
      .then(res => this.setConfirmationDemo(res.value.data));
  };
  setContentPayment = data => {
    const { BookingDetailSum } = data;
    let contentDetail = {};
    let confirmationDemo = {};
    if (BookingDetailSum.PackageType === 'Custom') {
      contentDetail = {
        Id: BookingDetailSum.Id,
        Customer: BookingDetailSum.CreatedBy.CompanyName,
        TourName: BookingDetailSum.Title,
        TourPrice: BookingDetailSum.TourTotalPrice,
        TourPaid: BookingDetailSum.TotalPayed,
        CurrencyId: BookingDetailSum.CurrencyId,
        tourTransactionStatus: BookingDetailSum.Status,
        CompanyCode: BookingDetailSum.CreatedBy.CompanyCode,
      };
      confirmationDemo = {
        PaymentChoice: 0,
        CustomValue: 0,
        Id: BookingDetailSum.Id,
        Confirmation: 0,
        SupplementPayment: [],
      };
      this.setState({
        contentDetail: contentDetail,
      });
      this.callFunctionPaymentTransaction(
        BookingDetailSum.Id,
        confirmationDemo
      );
    } else {
      contentDetail = {
        Id: BookingDetailSum.Id,
        Customer: BookingDetailSum.CreatedBy.CompanyName,
        TourName: BookingDetailSum.Title,
        TourPrice: BookingDetailSum.FixedPackage
          ? BookingDetailSum.FixedPackage.BookingCommission
              .TotalPriceForCustomer
          : 0,
        TourPaid: BookingDetailSum.TotalPayed,
        CurrencyId: BookingDetailSum.CurrencyId,
        tourTransactionStatus: BookingDetailSum.Status,
        CompanyCode: BookingDetailSum.CreatedBy.CompanyCode,
      };
      confirmationDemo = {
        PaymentChoice: 0,
        CustomValue: 0,
        Id: BookingDetailSum.Id,
        Confirmation: 0,
        SupplementPayment: [],
      };
      this.setState({
        contentDetail: contentDetail,
      });
      this.callFunctionPaymentTransaction(
        BookingDetailSum.Id,
        confirmationDemo
      );
    }
  };

  // componentDidUpdate = () => {
  //   const { transactionDetail, transactionDetailStatus } = this.props;
  //   if (transactionDetailStatus === 'success') {
  //     this.setContentPayment(transactionDetail);
  //     this.props.resetStatusTransactionAction();
  //   }
  // };
  shouldComponentUpdate = nextProps => {
    const { transactionDetail, transactionDetailStatus } = nextProps;
    if (
      transactionDetailStatus === 'success' &&
      this.props.transactionDetailStatus !== transactionDetailStatus
    ) {
      this.setContentPayment(transactionDetail);
      this.props.resetStatusTransactionAction();
    }
    return true;
  };
  handlePayTransaction = data => {
    // const { reloadPage, handleClose } = this.props;
    this.props
      .postConfirmTransaction(data)
      .then(() => {
        this.setState({
          openModalAlert: true,
          headerAlert: 'Success',
          contentAlert: 'the transaction has been successfully paid ',
        });
        if (this.state.isSendMail) {
          this.props.postEmailSendConfirmation({
            tourTransactionId: this.state.contentDetail.Id,
            emailSendConfirmed: true,
          });
        }
        // handleClose();
        // reloadPage();
      })
      .catch(err =>
        this.setState({
          openModalAlert: true,
          headerAlert: 'Failed',
          contentAlert: err.response
            ? err.response.data
              ? err.response.data.Message
              : 'Payment failed'
            : 'Payment failed',
        })
      );
  };

  handleOpenSendManualEmail = () => {
    let { getTourTransactionBills, transactionDetail } = this.props;
    let { totalPayableValue } = this.state;
    if (
      getTourTransactionBills.reduce((acc, e) => acc + e.RestOfTheBill, 0) ===
        totalPayableValue &&
      transactionDetail.TourGuestSum.find(e => e.FirstName === null) !==
        undefined
    ) {
      this.setState({
        ...this.state,
        openModalAlert: true,
        headerAlert: 'Warning',
        contentAlert:
          'You must fill out the guest list before making the final payment. Please contact your sales to fill out the guest list',
      });
    } else
      this.setState({ openSendManualEmail: true, isChooseSendEmail: true });
  };

  handleConfirmEmail = () => {
    this.setState({ openSendManualEmail: false }, () => {
      this.handleSubmitTourTransactionConfirmation();
    });
  };

  handleCloseSendEmail = () => {
    this.setState({ openSendManualEmail: false, isChooseSendEmail: false });
  };

  handleSendManualEmail = value => {
    // this.setState({ isSendMail: !this.state.isSendMail });
    this.setState({ isSendMail: value === 1 ? true : false });
  };

  handleSubmitTourTransactionConfirmation = () => {
    const {
      additonalItemPay,
      manualPaymentDemo,
      contentDetail,
      totalPayableValue,
    } = this.state;
    // const customValue =
    //   tourTransactionConfirmationObject.tourTransactionBilled +
    //   totalPayableAdditionalItem;
    // const payable =
    //   tourTransactionConfirmationObject.tourTransactionBilled +
    //   TotalPayableAdditionalItem;
    const balance = manualPaymentDemo.CompanyBalance;
    let paymentSupplement = [];
    if (
      totalPayableValue === '0' ||
      totalPayableValue <= 0 ||
      totalPayableValue === ''
    ) {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: 'Amount invalid',
        openModalAlert: true,
      });
    } else if (totalPayableValue <= balance) {
      additonalItemPay.map(pay => {
        let newData = {
          // PaymentValue: pay.MinimumPayable,
          PaymentValue: 0,
          Id: pay.Id,
          Qty: pay.Qty,
        };
        return paymentSupplement.push(newData);
      });
      let data = {
        // ...tourTransactionConfirmationObject,
        PaymentChoice: 'Deposit',
        Id: contentDetail.Id,
        Confirmation: 'Confirm',
        SupplementPayment: paymentSupplement,
        CustomValue: totalPayableValue,
      };
      this.handlePayTransaction(data);
    } else if (balance < totalPayableValue) {
      this.openModalBalanceDetail();
    }
  };
  //====================== Add Balance Detail Segment  ===============================
  openModalBalanceDetail = () => {
    const { CompanyCode } = this.state.contentDetail;
    this.props
      .getCustomerProfileByCodeAction(CompanyCode)
      .then(res => this.setStateBalanceDetail(res.value.data));
    this.props.getCurrenciesAction();
    this.setState({ openModalTouressWallet: true });
  };

  setStateBalanceDetail = data => {
    const { Code, Currency, Name, Balance, CountryId } = data;
    let balanceDetail = {
      CompanyCode: Code ? Code : '',
      CashFlowType: 'Credit',
      CurrencyId: Currency ? Currency : '',
      Value: 0,
      Reference: '',
      Description: '',
      PaymentMethod: 0,
      Name: Name ? Name : '',
      Balance: Balance ? Balance : 0,
      DateTime: moment(new Date()).format('YYYY-MM-DD'),
      CountryId: CountryId ? CountryId : 0,
    };
    this.setState({ balanceDetail: balanceDetail });
  };

  //==================================================================================
  //====================== on Change Payable  ===============================
  handleChangePayable = value => {
    this.setState({
      totalPayableValue: Number(value),
    });
  };
  //=============================================================================
  // getTotalMandatory = data => {
  //   let total = data.reduce((total, data) => {
  //     return data.IsMandatory ? total + data.MinimumPayable : total;
  //   }, 0);
  //   return this.setState({ totalMandatory: total });
  // };
  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open && this.props.open === true) {
      let { transactionId } = this.props;
      this.props.getTransactionByIdAction(transactionId);
      // this.props.getTourTransactionBilledAction(transactionId);
      // this.props.getPayableSupplemetItemAction(transactionId);
      // this.props.getPayableSupplemetItemAction(transactionId);
      // this.props.getTourTransactioBillsAction(transactionId);
    }
  }

  render() {
    const props = this.props;
    const {
      contentDetail,
      customerBalance,
      totalPayableAdditionalItem,

      RestOfTheBillWithoutSupplementStatic,
      tourTransactionConfirmationObject,
      openModalAlert,
      headerAlert,
      contentAlert,
      openModalTouressWallet,
      totalPayableValue,
      balanceDetail,
      // totalMandatory,
      openSendManualEmail,
      isChooseSendEmail,
      isSendMail,
    } = this.state;
    const {
      getPayableSupplemetItemStatic,
      loadingModal,
      transactionId,
      getTourTransactionBilled,
    } = this.props;
    const amountValue =
      tourTransactionConfirmationObject.tourTransactionBilled +
      totalPayableAdditionalItem;
    const totalPayable =
      RestOfTheBillWithoutSupplementStatic + getPayableSupplemetItemStatic;
    const isError = amountValue > customerBalance;
    return (
      <div>
        <Modal
          closeIcon
          inverted
          open={props.open}
          onClose={props.handleClose}
          style={StyleModal.modalWidthManualPayment}
        >
          <Modal.Header>Details Confirmation Transaction</Modal.Header>
          <LoaderModal loading={loadingModal} />
          <Modal.Content>
            <DescriptionManualPayment contentDetail={contentDetail} />
            <Divider />
            <TableManualPayment
              contentDetail={contentDetail}
              getPayableSupplemetItemStatic={props.getPayableSupplemetItem}
              TourPrice={
                tourTransactionConfirmationObject.tourTransactionBilled
              }
              // payableMandatory={totalMandatory}
              tourTransactionBilledStatic={getTourTransactionBilled}
            />

            <ContentCalculate
              contentDetail={contentDetail}
              customerBalance={customerBalance}
              openModalBalanceDetail={this.openModalBalanceDetail}
              totalPayable={totalPayable}
              customValue={amountValue}
              BalancePayment={
                tourTransactionConfirmationObject.tourTransactionBill
              }
              totalPayableValue={totalPayableValue}
              handleChangePayable={this.handleChangePayable}
            />
          </Modal.Content>
          <Modal.Actions>
            <Grid columns={2} padded="vertically">
              <Grid.Column>
                {isError && (
                  <Message
                    negative
                    size="small"
                    style={{ textAlign: 'center' }}
                  >
                    <Message.Header>
                      Your balance is insufficient
                    </Message.Header>
                  </Message>
                )}
              </Grid.Column>
              <Grid.Column>
                <Button
                  positive
                  inverted
                  // onClick={this.handleSubmitTourTransactionConfirmation}
                  onClick={this.handleOpenSendManualEmail}
                  content="Pay"
                  disabled={props.disabled}
                />
              </Grid.Column>
            </Grid>
          </Modal.Actions>
        </Modal>
        <ModalAlert
          openModal={openModalAlert}
          handleClose={this.handleCloseModalAlert}
          header={headerAlert}
          content={contentAlert}
          nameButton={'Ok'}
        />
        <ModalBalanceDetail
          open={openModalTouressWallet}
          handleClose={this.handleCloseTouressWallet}
          header="Credit"
          contentButton="Top Up"
          transactionId={transactionId}
          balanceDetail={balanceDetail}
        />
        <ModalManualSendEmail
          openModal={openSendManualEmail}
          size="tiny"
          close={this.handleCloseSendEmail}
          confirm={this.handleConfirmEmail}
          // message={messageConfirmation}
          isChooseSendEmail={isChooseSendEmail}
          handleSendManualEmail={this.handleSendManualEmail}
          isSendMail={isSendMail}
        />
      </div>
    );
  }
}

ModalPaymentTransaction.propTypes = {
  getPayableSupplemetItemStatic: PropTypes.object,
  getTourTransactioBillsAction: PropTypes.func,
  getTourTransactionBilledAction: PropTypes.func,
  postDemoManualPaymentAction: PropTypes.func,
  getPayableSupplemetItemAction: PropTypes.func,
  transactionDetail: PropTypes.object,
  transactionDetailStatusr: PropTypes.string,
  getTourTransactionBillsStatus: PropTypes.string,
  getTourTransactionBills: PropTypes.array,
  getTourTransactionBilled: PropTypes.object,
  resetStatusTransactionAction: PropTypes.func,
  transactionDetailStatus: PropTypes.string,
  getTourTransactionBilledStatus: PropTypes.string,
  postConfirmTransaction: PropTypes.func,
  reloadPage: PropTypes.func,
  handleClose: PropTypes.func,
  getCustomerProfileByCodeAction: PropTypes.func,
  getCurrenciesAction: PropTypes.func,
  loadingModal: PropTypes.bool,
  transactionId: PropTypes.number,
  open: PropTypes.bool,
  disabled: PropTypes.bool,
  getPayableSupplemetItem: PropTypes.array,
  postEmailSendConfirmation: PropTypes.func,
  getTransactionByIdAction: PropTypes.func,
};
const mapStateToProps = state => {
  return {
    transactionDetail: state.TourTransactionStore.listTransactionDetails,
    transactionDetailStatus:
      state.TourTransactionStore.listTransactionDetailsStatus,
    getTourTransactionBilled:
      state.TourTransactionStore.getTourTransactionBilled,
    getPayableSupplemetItem: state.TourTransactionStore.getPayableSupplemetItem,
    getPayableSupplemetItemStatic: state.TourTransactionStore.getPayableSupplemetItem.reduce(
      (total, num) => {
        return total + num.MinimumPayable;
      },
      0
    ),
    getTourTransactionBills: state.TourTransactionStore.getTourTransactionBills,
    getTourTransactionBillsStatus:
      state.TourTransactionStore.getTourTransactionBillsStatus,
    getTourTransactionBilledStatus:
      state.TourTransactionStore.getTourTransactionBilledStatus,
    loadingModal: state.TourTransactionStore.loading,
  };
};

export default connect(mapStateToProps, {
  getCurrenciesAction,
  resetStatusTransactionAction,
  getTourTransactioBillsAction,
  getTourTransactionBilledAction,
  postDemoManualPaymentAction,
  getTransactionByIdAction,
  getPayableSupplemetItemAction,
  postConfirmTransaction,
  getCustomerProfileByCodeAction,
  postEmailSendConfirmation,
})(ModalPaymentTransaction);
