const defaultCommission = [
  {
    Description: 'Agent',
    Value: 0,
    Category: 'Agent_Commission',
  },
  {
    Description: 'Staff',
    Value: 0,
    Category: 'Staff_Commission',
  },
];

export default defaultCommission;
