import { useState, useEffect } from 'react';

const useApiCall = (api, id = null) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState(null);

  const fetchApi = id => {
    setIsLoading(true);
    api(id)
      .then(result => {
        setIsLoading(false);
        setFetchedData(result.data);
      })
      .catch(() => {
        //eslint-disable-next-line
        // console.log(err);
        setIsLoading(false);
      });
  };

  const handleSubmit = id => {
    fetchApi(id);
  };

  const loadData = () => {
    fetchApi(id);
  };

  useEffect(() => {
    fetchApi(id);
    //eslint-disable-next-line
  }, []);

  return [isLoading, fetchedData, handleSubmit, loadData];
};

export default useApiCall;

// import { useState, useEffect } from 'react';
// import { setDataTourTransactionForFinance } from '../services/data/setDataTourTransactionForFinance';

// const useApiCall = (api, startDate = null, endDate = null) => {
//   const getInitialStartDate = () => {
//     return startDate;
//   };

//   const getInitialEndDate = () => {
//     return endDate;
//   };

//   const setInitialData = resultData => {
//     let modifiedData = setDataTourTransactionForFinance(resultData);
//     return modifiedData;
//   };

//   const [isLoading, setIsLoading] = useState(false);
//   const [fetchedData, setFetchedData] = useState(null);
//   const [today, setToday] = useState(getInitialStartDate());
//   const [nextMonth, setNextMonth] = useState(getInitialEndDate());

//   const fetchApi = (from, to) => {
//     setIsLoading(true);
//     api(from, to)
//       .then(result => {
//         let optionsData = setInitialData(result.data);
//         setIsLoading(false);
//         setFetchedData(optionsData);
//       })
//       .catch(() => {
//         //eslint-disable-next-line
//         setIsLoading(false);
//       });
//   };

//   const handleSubmit = (from, to) => {
//     setToday(from);
//     setNextMonth(to);
//     fetchApi(from, to);
//   };

//   const loadData = () => {
//     fetchApi(today, nextMonth);
//   };

//   useEffect(() => {
//     fetchApi(today, nextMonth);
//     //eslint-disable-next-line
//   }, []);

//   return [isLoading, fetchedData, handleSubmit, loadData, today, nextMonth];
// };

// export default useApiCall;
