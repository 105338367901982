import React from 'react';
import { TablePackageList } from './';
import { Grid, Input, Button, Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../../scripts/pagination';
// import FilterData from '../../../../scripts/filterDataTable';
import filterListByTitleOrId from '../../services/helper/filterHelper';
class TabListPackageDraft extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      packageListDraft,
      searchInput,
      activePage,
      isOperation,
      isSales,
    } = this.props;
    let filterData = filterListByTitleOrId(packageListDraft, searchInput);
    let pagedData = datasetPagination(filterData, 7);
    let listData = pagedData[activePage - 1];

    let { numbersFrom, numbersTo } = getNumberFromTo(listData, activePage, 7);
    return (
      <div>
        <Grid>
          <Grid.Row columns="5">
            <Grid.Column>
              <Input
                icon="search"
                placeholder="search"
                name="searchInput"
                value={this.props.searchInput}
                onChange={this.props.handleChange}
              />
            </Grid.Column>
            <Grid.Column width={1}>
              <Popup
                content="Filter package by date"
                trigger={
                  <Button
                    icon="filter"
                    onClick={this.props.handleOnClickFilterDate}
                    positive
                    basic
                  />
                }
              />
            </Grid.Column>

            {isOperation || isSales || (
              <>
                <Grid.Column width={4}>
                  <Button
                    content="Publish All"
                    primary
                    disabled={this.props.statusCheckDraft}
                    onClick={this.props.postActionPublishAll}
                  />
                  <Button
                    content="Delete All"
                    negative
                    onClick={this.props.deletePackageAllDraft}
                    disabled={this.props.statusCheckDraft}
                  />
                </Grid.Column>
                <Grid.Column floated="right" textAlign="right">
                  <Button
                    content="Add New"
                    positive
                    icon="plus"
                    value="New"
                    onClick={this.props.openDetailPackage}
                  />
                </Grid.Column>
              </>
            )}
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <TablePackageList
                listData={listData}
                openDetail={this.props.openDetailPackage}
                pagedData={pagedData}
                pageChange={this.props.pageChange}
                handleChangeCheck={this.props.handleChangeCheck}
                postActionPublish={this.props.postActionPublish}
                deletePackage={this.props.deletePackage}
                copyPackage={this.props.copyPackage}
                numbersFrom={numbersFrom}
                numbersTo={numbersTo}
                data={this.props.packageListDraft}
                popupContent={{
                  publish: 'Publish',
                  copy: 'Copy',
                  edit: 'Edit',
                  delete: 'Delete',
                }}
                typePackage={this.props.typePackage}
                activePage={this.props.activePage}
                loading={this.props.loading}
                isOperation={isOperation}
                isSales={isSales}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

TabListPackageDraft.propTypes = {
  packageListDraft: PropTypes.array,
  openDetailPackage: PropTypes.func,
  pagedData: PropTypes.array,
  pageChange: PropTypes.func,
  handleChange: PropTypes.func,
  searchInput: PropTypes.string,
  handleChangeCheck: PropTypes.func,
  statusCheckDraft: PropTypes.bool,
  postActionPublishAll: PropTypes.func,
  postActionPublish: PropTypes.func,
  deletePackage: PropTypes.func,
  deletePackageAllDraft: PropTypes.func,
  copyPackage: PropTypes.func,
  typePackage: PropTypes.string,
  activePage: PropTypes.number,
  loading: PropTypes.bool,
  handleOnClickFilterDate: PropTypes.func,
  isOperation: PropTypes.bool,
  isSales: PropTypes.bool,
};
export default TabListPackageDraft;
