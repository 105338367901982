export const RESET_PAYMENT_METHOD = 'RESET_PAYMENT_METHOD';

export const DELETE_PAYMENT_METHOD_IN_COUNTRY =
  'DELETE_PAYMENT_METHOD_IN_COUNTRY';
export const DELETE_PAYMENT_METHOD_IN_COUNTRY_FULFILLED =
  'DELETE_PAYMENT_METHOD_IN_COUNTRY_FULFILLED';
export const DELETE_PAYMENT_METHOD_IN_COUNTRY_PENDING =
  'DELETE_PAYMENT_METHOD_IN_COUNTRY_PENDING';
export const DELETE_PAYMENT_METHOD_IN_COUNTRY_REJECTED =
  'DELETE_PAYMENT_METHOD_IN_COUNTRY_REJECTED';

export const GET_PAYMENT_METHOD_IN_COUNTRY = 'GET_PAYMENT_METHOD_IN_COUNTRY';
export const GET_PAYMENT_METHOD_IN_COUNTRY_FULFILLED =
  'GET_PAYMENT_METHOD_IN_COUNTRY_FULFILLED';
export const GET_PAYMENT_METHOD_IN_COUNTRY_PENDING =
  'GET_PAYMENT_METHOD_IN_COUNTRY_PENDING';
export const GET_PAYMENT_METHOD_IN_COUNTRY_REJECTED =
  'GET_PAYMENT_METHOD_IN_COUNTRY_REJECTED';

export const POST_PAYMENT_METHOD_IN_COUNTRY = 'POST_PAYMENT_METHOD_IN_COUNTRY';
export const POST_PAYMENT_METHOD_IN_COUNTRY_FULFILLED =
  'POST_PAYMENT_METHOD_IN_COUNTRY_FULFILLED';
export const POST_PAYMENT_METHOD_IN_COUNTRY_PENDING =
  'POST_PAYMENT_METHOD_IN_COUNTRY_PENDING';
export const POST_PAYMENT_METHOD_IN_COUNTRY_REJECTED =
  'POST_PAYMENT_METHOD_IN_COUNTRY_REJECTED';
