import { useState, useEffect } from 'react';
import setDataResourceBooking from '../services/helper/setDataResourceBooking';
// import setDataResourceBooking from '../services/helper/setDataResourceBooking';

const useApiCall = (api, data) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState([]);

  const fetchApi = data => {
    setIsLoading(true);
    api(data)
      .then(result => {
        let optionsData = setDataResourceBooking(result.data);
        setIsLoading(false);
        setFetchedData(optionsData);
      })
      .catch(() => {
        //eslint-disable-next-line
        setIsLoading(false);
        setFetchedData([]);
      });
  };

  const loadData = data => {
    fetchApi(data);
  };

  useEffect(() => {
    fetchApi(data);
    //eslint-disable-next-line
  }, []);

  return [isLoading, fetchedData, loadData];
};

export default useApiCall;
