import React, { useState } from 'react';
import { Grid, Segment, Input, Button, Container } from 'semantic-ui-react';
import styles from './styles';
import ModalPaymentRequest from '../../components/modal/modalPaymentRequest';
import ModalDateRange from '../shared/components/modal/ModalDateRangeCheckIn';
import useApiCall from './hooks/useApiCallPaymentRequest';
import { getPaymentRequestListApi } from '../../api/dashboardApi';
import { searchFilterPaymentRequest } from '../../scripts/filterData';
import PaginationScript from '../../components/genericFunction/pagination';
import { getNumberFromTo } from '../../components/genericFunction/getPaginationNumberFrom';
import HeaderTitle from '../shared/components/common/headerSegment/HeaderTitle';
import TablePaymentRequest from './components/TablePaymentRequest';
import ModalInformation from '../home/component/modal/ModalInformation';
import moment from 'moment';
import { addDate, convertDateFormat } from '../../scripts/moment';
const TODAY = new Date();

const PaymentRequestList = () => {
  //=======================initial state=======================
  const [openPay, setOpenPay] = useState(false);
  const [openDateRange, setOpenDateRange] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [activePage, setActivePage] = useState(1);
  const [openModalInformation, setOpenModalInformation] = useState(false);
  const [contentAlert, setContentAlert] = useState('');
  const [headerAlert, setHeaderAlert] = useState('');
  const [selectedPaymentRequest, setSelectedPaymentRequest] = useState(null);

  const [
    paymentRequestLoading,
    paymentRequestList,
    handleDateRange,
    loadData,
    startDate,
    endDate,
  ] = useApiCall(
    getPaymentRequestListApi,
    convertDateFormat(TODAY, 'YYYY-MM-DD'),
    convertDateFormat(addDate(TODAY, 30, 'd'), 'YYYY-MM-DD')
  );

  const actionDateRange = id => {
    let from = moment(id.from).format('YYYY-MM-DD');
    let to = moment(id.to).format('YYYY-MM-DD');
    handleClose();
    handleDateRange(from, to);
  };

  const filteredData = (data, searchInput) => {
    return searchFilterPaymentRequest(data, searchInput);
  };

  const pageChange = (e, { activePage }) => {
    setActivePage(activePage);
  };

  //=======================handler=======================
  const handleChangeSearch = e => {
    setSearchInput(e.target.value);
    setActivePage(1);
  };

  const handleOpenPay = (e, { id }) => {
    let findPaymentRequest = paymentRequestList.find(e => e.Id === id);
    setSelectedPaymentRequest(
      findPaymentRequest ? findPaymentRequest.CompleteData : null
    );
    setOpenPay(true);
  };
  const handleClose = () => {
    setOpenPay(false);
    setOpenDateRange(false);
  };
  const handleCloseModalInformation = () => {
    setHeaderAlert('');
    setContentAlert('');
    setOpenModalInformation(false);
    setActivePage(1);
    loadData();
  };
  const handleOpenDateRange = () => {
    setOpenDateRange(true);
  };

  //=======================pagination data=======================
  let filterData = filteredData(
    paymentRequestList !== null ? paymentRequestList : [],
    searchInput
  );
  let pagedData = PaginationScript(filterData, 5);
  let listData = pagedData[activePage - 1];
  let { numbersFrom, numbersTo } = getNumberFromTo(listData, activePage, 5);
  let dueEndDate = !endDate ? '' : moment(endDate).format('DD MMM YYYY');
  let dueStartDate = !startDate ? '' : moment(startDate).format('DD MMM');

  return (
    <Grid columns="equal" style={styles.styleRow}>
      <Grid.Row stretched>
        <Grid.Column width={15}>
          <Segment loading={paymentRequestLoading}>
            <HeaderTitle title="Payment Request List" />
            <Input
              icon="search"
              placeholder="Search by Resource Booking No. or Due Date"
              name="searchInput"
              value={searchInput}
              onChange={handleChangeSearch}
              style={{
                width: '330px',
                borderRadius: 'none',
              }}
            />
            <Button
              icon="filter"
              basic
              size="small"
              floated="right"
              positive
              onClick={handleOpenDateRange}
            />
            <div
              style={{
                marginTop: '-2.9em',
                marginRight: '5em',
                fontWeight: 'bold',
              }}
            >
              <Container
                content={'Due date : ' + dueStartDate + ' - ' + dueEndDate}
                textAlign="right"
              />
            </div>
            <Grid.Row style={styles.styleRowTable}>
              <TablePaymentRequest
                activePage={activePage}
                data={paymentRequestList}
                pagedData={pagedData}
                listData={listData}
                numbersFrom={numbersFrom}
                numbersTo={numbersTo}
                pageChange={pageChange}
                handleClose={handleClose}
                handleOpenPay={handleOpenPay}
              />
            </Grid.Row>
          </Segment>
        </Grid.Column>
      </Grid.Row>
      <ModalPaymentRequest
        openModal={openPay}
        handleClose={handleClose}
        selectedPaymentRequest={selectedPaymentRequest}
      />
      <ModalDateRange
        contentHeader="Payment Request List"
        openModal={openDateRange}
        close={handleClose}
        actionDateRange={actionDateRange}
        startDate={startDate}
        endDate={endDate}
      />
      <ModalInformation
        close={handleClose}
        open={openModalInformation}
        content={contentAlert}
        modalHeader={headerAlert}
        onClickButton={handleCloseModalInformation}
        size="mini"
        contentButton="OK"
      />
    </Grid>
  );
};

export default React.memo(PaymentRequestList);
