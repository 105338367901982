import React, { Component } from 'react';
import { Grid, Header, Icon, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from './styles';
import TablePackageDetail from '../table/tablePackageDetail';
import { convertTourPrice } from './../genericFunction/convertPackage';
import CollapseButton from '../button/collapseButton';
import { Collapse } from 'react-collapse';
import Loader from './../loader/loader';
import { initialAdditionalServices } from '../../pages/shared/services/additionalServices/processData';
class segmentCustomerOnBehalf extends Component {
  state = {
    tourPriceSum: [],
    commissionsPrice: [],
    totalTourPrice: 0,
    totalPayable: 0,
    currency: null,
    openCollapse: false,
    supplements: [],
    splitCommission: false,
    additionalServices: [],
  };
  componentDidUpdate = prevProps => {
    if (
      (JSON.stringify(prevProps.demoPrice) !==
        JSON.stringify(this.props.demoPrice) ||
        JSON.stringify(prevProps.specialAdjusments) !==
          JSON.stringify(this.props.specialAdjusments)) &&
      this.props.demoPrice
    ) {
      this.setPackageSummaryDemoPrice(prevProps.demoPrice);
    }
  };
  setPackageSummaryDemoPrice = () => {
    let { demoPrice, currentPackage, specialAdjusments } = this.props;
    let commissions =
      currentPackage.type === 'Fixed'
        ? demoPrice.BookingDetailSum.FixedPackage.BookingCommission
        : {};
    let tourPriceSum = convertTourPrice(demoPrice.TourPriceSum);
    let supplements =
      currentPackage.type === 'Fixed' ? demoPrice.TransactionSupplements : [];
    let commissionsPrice =
      currentPackage.type === 'Fixed'
        ? commissions
          ? commissions.ApplicableCommission
          : []
        : [];

    let totalPayable =
      currentPackage.type === 'Fixed'
        ? commissions
          ? commissions.TotalPriceForCustomer
          : 0
        : 0;
    let currency = demoPrice.BookingDetailSum.CurrencyId;
    let splitCommission = demoPrice.BookingDetailSum.IsSplitStaffCommission;
    let additionalItemOld = demoPrice.AdditionalItems;
    let additionalItemNew = specialAdjusments.filter(e => !e.IsDelete);
    // hitung total price
    let totalTourPrice = demoPrice.BookingDetailSum.TourTotalPrice;
    let totalOldSpecialAdjusment = additionalItemOld.reduce(
      (acc, e) => acc + parseFloat(e.TotalCost, 10),
      0
    );
    let totalNewSpecialAdjusment = additionalItemNew.reduce(
      (acc, e) => acc + parseFloat(e.TotalCost, 0),
      0
    );
    // additional services
    let additionalServices = initialAdditionalServices(
      demoPrice && demoPrice.AdditionalServices
    );
    // end additional services
    totalTourPrice =
      totalTourPrice - totalOldSpecialAdjusment + totalNewSpecialAdjusment;
    // end hitung total price
    // hitung total payable
    totalPayable =
      totalPayable !== 0
        ? totalPayable - totalOldSpecialAdjusment + totalNewSpecialAdjusment
        : 0;
    // end hitung total payable
    this.setState({
      ...this.state,
      tourPriceSum,
      supplements,
      commissionsPrice,
      totalPayable,
      currency,
      splitCommission,
      additionalItem: specialAdjusments,
      totalTourPrice,
      additionalServices,
    });
  };

  handleCollapse = (e, { name, value }) =>
    this.setState({ ...this.state, [name]: !value });
  render() {
    let { currentPackage, demoPrice } = this.props;
    let {
      tourPriceSum,
      totalTourPrice,
      openCollapse,
      additionalItem,
      additionalServices,
    } = this.state;
    const { loading } = this.props;
    return (
      <Segment raised fluid>
        <div style={{ margin: '1em' }}>
          <Grid verticalAlign="middle">
            <Grid.Row style={styles.noPaddingBottom}>
              <Grid.Column width={14}>
                <Header as="h3">
                  <Icon name="money" style={{ color: '#38af95' }} />
                  Package Price Detail
                </Header>
              </Grid.Column>
              <Grid.Column width={2} textAlign="left">
                <CollapseButton
                  isCollapse={openCollapse}
                  handleCollapse={this.handleCollapse}
                  name="openCollapse"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Collapse isOpened={openCollapse} style={{ margin: '0px' }}>
            <Grid>
              {loading ? (
                <Loader active={true} inline={true} />
              ) : (
                <Grid.Row>
                  <Grid.Column width={16}>
                    {demoPrice ? (
                      <TablePackageDetail
                        tourPriceSum={tourPriceSum}
                        isFixedPackage={currentPackage.type === 'Fixed'}
                        totalTourPrice={totalTourPrice}
                        currency={this.state.currency}
                        supplements={this.state.supplements}
                        commissions={this.state.commissionsPrice}
                        totalPayable={this.state.totalPayable}
                        splitCommission={this.state.splitCommission}
                        additionalItem={additionalItem}
                        additionalServices={additionalServices}
                      />
                    ) : (
                      <h4 style={{ textAlign: 'center' }}>
                        Please complete the arrangement beside and choose tour
                        operators
                      </h4>
                    )}
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
          </Collapse>
        </div>
      </Segment>
    );
  }
}
segmentCustomerOnBehalf.propTypes = {
  currentPackage: PropTypes.object,
  demoPrice: PropTypes.object,
  loading: PropTypes.bool,
  specialAdjusments: PropTypes.array,
};
export default segmentCustomerOnBehalf;
