import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import SegmentAdditionalServiceDetail from './SegmentAdditionalServiceDetail';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Redirect, withRouter } from 'react-router-dom';
import useApiCall from '../hooks/useApiCall';
import useApiCallForDetail from '../hooks/useApiCallForDetail';
import {
  getAdditionalServiceByIdApi,
  putAdditionalServiceApi,
  postAdditionalServiceApi,
  getAdditionalServiceTypeApi,
  getRestrictedCountryByAdditionalServiceTypeApi,
} from '../../../api/additionalServices/additionalServicesApi';
import getCurrenciesApi from '../../../api/getCurrenciesApi';
import getCountriesApi from '../../../api/country/getCountryApi';
import ModalAlert from '../../../components/modal/modalAlert';
import emptyFieldValidation from '../services/emptyFieldValidation';
import { fieldToValidateAdditionalService } from '../constants/fieldToValidate';
import saveValidation from '../../shared/services/validation/saveValidation';
import _ from 'lodash';

const AdditionalServiceDetail = props => {
  // ----------------- initial -----------------
  // const { loading } = props;
  const id = props.match.params.id;
  const [goTo, setGoTo] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [openModalAlert, setOpenModalAlert] = useState(false);
  const [headerModal, setHeaderModal] = useState('');
  const [contentModal, setContentModal] = useState('');
  const [errors, setErrors] = useState({});
  const [newId, setNewId] = useState(0);
  const [noDefValue, setNoDefValue] = useState(false);
  const [disable, setDisable] = useState(false);

  // ----------------- custom hooks -----------------
  const [
    additionalServiceLoading,
    additionalServiceDetail,
    setAdditionalServiceDetail,
    loadData,
  ] = useApiCallForDetail(getAdditionalServiceByIdApi, id);
  const [currenciesLoading, currencies] = useApiCall(getCurrenciesApi);
  const [countriesLoading, countries] = useApiCall(getCountriesApi);
  const [additionalServiceTypeLoading, additionalServiceTypeList] = useApiCall(
    getAdditionalServiceTypeApi
  );
  const [
    restrictedCountriesLoading,
    restrictedCountries,
    // eslint-disable-next-line
    setRestrictedCountries,
    loadDataRestrictedCountries,
  ] = useApiCall(getRestrictedCountryByAdditionalServiceTypeApi);

  // ----------------- helper -----------------
  const filterCountriesByArrayId = (dataCountries, arrayId) => {
    let result = dataCountries.filter(item => !arrayId.includes(item.Id));
    return result;
  };

  const filterCountries = (dataCountries = [], listOriginCountries = []) => {
    let listOriginCountriesId = listOriginCountries.map(item => {
      return item.Value;
    });
    let result = filterCountriesByArrayId(dataCountries, listOriginCountriesId);
    return result;
  };

  const addOriginCountry = () => {
    let editedAdditionalService = { ...additionalServiceDetail };
    let originCountries = editedAdditionalService.OriginalCountries
      ? editedAdditionalService.OriginalCountries
      : [];
    editedAdditionalService.OriginalCountries = originCountries;
    let seq = Math.max.apply(
      Math,
      editedAdditionalService.OriginalCountries.map(o => {
        return o.Sequance ? o.Sequance : 0;
      })
    );
    let _seq = isFinite(seq) ? seq : 0;
    let data = {
      Id: `NEWROW${_seq + 1}`,
      Amount: '',
      Cost: '',
      Currency: '',
      OriginalCountryId: null,
      Text: '',
      Value: originCountries.length === 0 ? 'DEF' : noDefValue ? 'DEF' : '',
      Sequance: _seq + 1,
    };
    // let data2 = {
    //   Amount: 1300000,
    //   Currency: 'IDR',
    //   Value: 'JP',
    // };
    editedAdditionalService.OriginalCountries.unshift(data);
    setAdditionalServiceDetail(editedAdditionalService);
  };

  const openModal = (isOpen, header, content) => {
    setHeaderModal(header);
    setContentModal(content);
    setOpenModalAlert(isOpen);
  };

  const setSuccess = (header = null, content = null) => {
    setLoadingSave(false);
    openModal(
      true,
      header ? header : 'Success',
      content ? content : 'Success save additional service'
    );
  };

  const setFailed = (header = null, content = null) => {
    setLoadingSave(false);
    openModal(
      true,
      header ? header : 'Failed',
      content ? content : 'Failed save additional service'
    );
  };

  const validation = (data = null) => {
    let errors = emptyFieldValidation(
      data ? data : additionalServiceDetail,
      fieldToValidateAdditionalService
    );
    setErrors(errors);
  };

  const checkOriginCountryTable = (data, isError) => {
    // ------ check origin country table null ------
    let nullOriginCountry = false;
    if (!isError) {
      nullOriginCountry =
        typeof data.OriginalCountries === 'undefined' ||
        data.OriginalCountries.length === 0;
      if (nullOriginCountry) {
        openModal(true, 'Failed', 'Table Origin Country Must Be Fill');
      }
    }

    // ------ check currency & country in origin country table not fill ------
    let notFillOriginCountry = false;
    //// chek whether there is an originalCountry property
    let propOriginCountry =
      data && typeof data.OriginalCountries === 'undefined';
    if (!isError && !nullOriginCountry && !propOriginCountry) {
      let emptyOriginCountry = data.OriginalCountries.filter(
        item => item.Value === '' || item.Currency === '' || item.Amount === ''
      );
      notFillOriginCountry = emptyOriginCountry.length ? true : false;
      if (notFillOriginCountry) {
        openModal(
          true,
          'Failed',
          'Origin Country, Currency, and Price Must Be Fill'
        );
      }
    }

    // ------ check is there default value in origin country ------
    let nullDefaultOriginCountry = false;
    if (!isError && !nullOriginCountry && !notFillOriginCountry) {
      let defVal =
        data.OriginalCountries &&
        data.OriginalCountries.length &&
        data.OriginalCountries.find(item => item.Value === 'DEF');
      if (!defVal) {
        nullDefaultOriginCountry = true;
        setNoDefValue(true);
        openModal(
          true,
          'Failed',
          'Origin Country must have default value (ZALLCOUNTRY)'
        );
      }
    }

    return (
      nullOriginCountry || notFillOriginCountry || nullDefaultOriginCountry
    );
  };

  const validateSave = (state = null) => {
    // ------ check general info empty field ------
    state = state ? state : additionalServiceDetail;
    let errors = saveValidation(state, fieldToValidateAdditionalService);
    let isError = !_.isEmpty(errors);
    setErrors(errors);

    let checkTable = checkOriginCountryTable(state, isError);

    return isError || checkTable;
  };

  const restructureSavedData = data => {
    let newData = { ...data };
    let newOriginCountries = data.OriginalCountries.map(item => {
      return {
        ...item,
        Amount: item.Cost,
      };
    });
    newData.AdditionalServiceType.Value = newData.AdditionalServiceType.Id;
    newData.OriginalCountries = newOriginCountries;
    return newData;
  };

  const getRestrictedCountry = typeId => {
    loadDataRestrictedCountries(typeId);
  };

  const isTypeRestrictedCountry = typeId => {
    return additionalServiceTypeList.find(
      item => item.Id === typeId && item.IsRestrictedCountry
    );
  };

  // --------------------- handler ---------------------
  const handleBack = () => {
    setGoTo(`/additional-services`);
    setRedirect(true);
  };

  const handleChange = (e, { name, value }) => {
    let newData = { ...additionalServiceDetail };
    if (name === 'DestinationCountry') {
      newData.DestinationCountry = {};
      newData.DestinationCountry.Value = value;
    } else if (name === 'AdditionalServiceType') {
      newData.AdditionalServiceType = {};
      newData.AdditionalServiceType.Id = value;
      newData.AdditionalServiceType.Value = value;
      if (isTypeRestrictedCountry(value) && id > 0) {
        newData.DestinationCountry = {
          Value: '',
        };
      }
      if (value) getRestrictedCountry(value);
    } else if (name === 'Margin') {
      value =
        value > 1000
          ? '1000'
          : value < 0
          ? value < -100
            ? '-100'
            : value
          : value;
      newData.Margin = value.replace(/,/g, '.');
    } else {
      newData[name] = value;
    }
    setAdditionalServiceDetail(newData);
    validation(newData);
  };

  const handleChangeInputTable = (e, { name, value, idx }) => {
    let newData = { ...additionalServiceDetail };
    if (name === 'OriginalCountryId') {
      newData.OriginalCountries[idx].Value = value;
    }
    if (name === 'Currency') {
      newData.OriginalCountries[idx].Currency = value;
    }
    setAdditionalServiceDetail(newData);
  };

  const handleChangeInputAmount = (value, name, idx) => {
    let newData = { ...additionalServiceDetail };
    newData.OriginalCountries[idx].Amount = value;
    newData.OriginalCountries[idx].Cost = value;
    setAdditionalServiceDetail(newData);
  };

  const handleDelete = (e, { idx }) => {
    let newData = { ...additionalServiceDetail };
    let newRow = newData.OriginalCountries.filter((item, i) => i !== idx);
    newData.OriginalCountries = newRow;
    setAdditionalServiceDetail(newData);
  };

  const handleSave = () => {
    let validateField = validateSave();
    if (!validateField) {
      let newData = restructureSavedData(additionalServiceDetail);
      setLoadingSave(true);
      if (id !== '0') {
        putAdditionalServiceApi(id, newData)
          .then(() => {
            setSuccess();
          })
          .catch(err => {
            // setFailed();
            setFailed(
              'Failed',
              err &&
                err.response &&
                err.response.data &&
                err.response.data.Message
                ? err.response.data.Message
                : null
            );
          });
      } else {
        postAdditionalServiceApi(newData)
          .then(result => {
            setNewId(result.data.Id);
            setSuccess();
          })
          .catch(err => {
            // console.log(err.response);
            setFailed(
              'Failed',
              err &&
                err.response &&
                err.response.data &&
                err.response.data.Message
                ? err.response.data.Message
                : null
            );
          });
      }
    }
  };

  const handleClose = () => {
    if (newId) {
      setNewId(0);
      setGoTo(`/additional-services/detail/${newId}`);
      setRedirect(true);
    } else if (noDefValue) {
      addOriginCountry('DEF');
      openModal(false, '', '');
      setNoDefValue(false);
    } else {
      openModal(false, '', '');
      if (headerModal !== 'Failed') {
        loadData(id);
      }
    }
  };

  // --------------------- lifecycle ---------------------
  useEffect(() => {
    // eslint-disable-next-line
    if (id == 0) {
      setDisable(true);
      addOriginCountry();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    openModal(false, '', '');
    setRedirect(false);
    if (id > 0) loadData(id);
    // else setDisable(true);
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (restrictedCountries) {
      setDisable(false);
    }
    // jika yg dipilih
    // eslint-disable-next-line
  }, [restrictedCountries]);

  // ----------------- const -----------------
  const countryOptions = filterCountries(
    countries && countries,
    additionalServiceDetail && additionalServiceDetail.OriginalCountries
  );

  const currencyList =
    currencies &&
    currencies.map(item => {
      return {
        key: item.Id,
        text: item.Id,
        value: item.Id,
      };
    });

  const countryList =
    countries &&
    countries.map(item => {
      return {
        key: item.Id,
        text: item.Name,
        value: item.Id,
      };
    });

  const countryOptionsList =
    countryOptions &&
    countryOptions.map(item => {
      return {
        key: item.Id,
        text: item.Name,
        value: item.Id,
      };
    });

  const additionalserviceTypeOptionList =
    additionalServiceTypeList &&
    additionalServiceTypeList.map(item => {
      return {
        key: item.Id,
        text: item.TypeName,
        value: item.Id,
      };
    });

  const restrictedCountryList =
    restrictedCountries &&
    restrictedCountries.map(item => {
      return {
        key: item.Id,
        text: item.Name,
        value: item.Id,
      };
    });

  let loading =
    additionalServiceLoading ||
    currenciesLoading ||
    countriesLoading ||
    loadingSave ||
    additionalServiceTypeLoading ||
    restrictedCountriesLoading;

  return redirect ? (
    <Redirect to={goTo} />
  ) : (
    <>
      <Dimmer.Dimmable blurring dimmed={false}>
        <Loader loading={loading} />
        <SegmentAdditionalServiceDetail
          data={additionalServiceDetail}
          countryList={countryList}
          countryOptionsList={countryOptionsList}
          currencyList={currencyList}
          handleBack={handleBack}
          loading={loading}
          addOriginCountry={addOriginCountry}
          handleChange={handleChange}
          handleChangeInputTable={handleChangeInputTable}
          handleChangeInputAmount={handleChangeInputAmount}
          handleDelete={handleDelete}
          handleSave={handleSave}
          errors={errors}
          additionalserviceTypeOptionList={additionalserviceTypeOptionList}
          id={id}
          disable={disable}
          restrictedCountryList={restrictedCountryList}
        />
        <ModalAlert
          openModal={openModalAlert}
          handleClose={handleClose}
          header={headerModal}
          content={contentModal}
          nameButton="OK"
        />
      </Dimmer.Dimmable>
    </>
  );
};

AdditionalServiceDetail.propTypes = {
  loading: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.string,
  }),
};

export default withRouter(AdditionalServiceDetail);
