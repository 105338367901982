import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Form } from 'semantic-ui-react';
import InputWithLabel from '../../../shared/components/common/input/InputWithLabel';

const GeneralInformation = props => {
  const {
    data,
    countryList,
    loading,
    handleChange,
    errors,
    additionalserviceTypeOptionList,
    disable,
    restrictedCountryList,
  } = props;
  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Header as="h3" content="General Information" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Form>
                    <Form.Field>
                      <InputWithLabel
                        label="Type"
                        type="select"
                        name="AdditionalServiceType"
                        required
                        fluid={true}
                        placeholder="Additional Service Type"
                        options={additionalserviceTypeOptionList}
                        value={
                          data &&
                          data.AdditionalServiceType &&
                          data.AdditionalServiceType.Id
                        }
                        loading={loading}
                        handleChange={handleChange}
                        validate={errors}
                      />
                    </Form.Field>
                  </Form>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Form>
                    <Form.Field>
                      <InputWithLabel
                        label="Additional Service Name"
                        type="text"
                        name="AdditionalServicesName"
                        required
                        fluid={true}
                        placeholder="Additional Service Name"
                        value={data && data.AdditionalServicesName}
                        loading={loading}
                        handleChange={handleChange}
                        validate={errors}
                        disabled={disable}
                      />
                    </Form.Field>
                  </Form>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Form>
                    <Form.Field>
                      <InputWithLabel
                        label="Destination"
                        type="select"
                        name="DestinationCountry"
                        required
                        fluid={true}
                        placeholder="Destination"
                        options={
                          restrictedCountryList
                            ? restrictedCountryList
                            : countryList
                        }
                        value={
                          data &&
                          data.DestinationCountry &&
                          data.DestinationCountry.Value
                        }
                        loading={loading}
                        handleChange={handleChange}
                        validate={errors}
                        disabled={disable}
                      />
                    </Form.Field>
                  </Form>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Form>
                    <Form.Field>
                      <InputWithLabel
                        label="Margin"
                        type="number"
                        name="Margin"
                        required
                        fluid={true}
                        placeholder="Margin"
                        value={data && data.Margin}
                        loading={loading}
                        handleChange={handleChange}
                        validate={errors}
                        labelInput={{ content: '%' }}
                        labelPosition="right"
                        disabled={disable}
                      />
                    </Form.Field>
                  </Form>
                </Grid.Column>
                <Grid.Column></Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>

          <Grid.Column>
            <Form>
              <Form.Field>
                <InputWithLabel
                  label="Description"
                  type="textArea"
                  name="Description"
                  required
                  fluid={true}
                  placeholder="Description"
                  value={data && data.Description}
                  loading={loading}
                  handleChange={handleChange}
                  validate={errors}
                  disabled={disable}
                />
              </Form.Field>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

GeneralInformation.propTypes = {
  data: PropTypes.object,
  countryList: PropTypes.array,
  loading: PropTypes.bool,
  handleChange: PropTypes.func,
  errors: PropTypes.object,
  additionalserviceTypeOptionList: PropTypes.array,
  disable: PropTypes.bool,
  restrictedCountryList: PropTypes.array,
};

export default GeneralInformation;
