import React from 'react';
import { Dimmer } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {
  getExportExcursionObjectAction,
  deleteExcursionObjectAction,
  resetExcursionPageAction,
  getExportExcursionPriceAction,
} from '../excursionAction';
import {
  // getExcursionListAction,
  resetExcursionAction,
  getTemplateExcursionObjectAction,
} from '../../shared/actions/excursionAction';
import {
  getSupplierListAction,
  resetAccomodationAction,
} from '../../shared/actions/accommodationAction';
import ModalAlert from '../../../components/modal/modalAlert';
import Loader from '../../../components/loader';
import ExcursionList from './components/ExcursionList';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import ModalConfirm from '../../../components/modal/modalConfirmationTwoButton';
import ListHelper from '../../shared/components/listHelper/ListHelper';
class Excursion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      excursionList: [],
      loadingExcursion: true,
      failedExcursion: false,
      modalOpen: false,
      headerAlert: '',
      messageAlert: '',
      redirect: false,
      serviceItemId: 0,
      linkNew: false,
      openModalConfirm: false,
      message: '',
      actionConfirmType: '',
      searchInput: '',
      activePage: 1,
    };
  }
  handleOpen = (header, message) =>
    this.setState({
      modalOpen: true,
      headerAlert: header,
      messageAlert: message,
    });
  handleClose = () => this.setState({ modalOpen: false });
  componentDidMount = () => {
    // this.props.getExcursionListAction();
    this.props.getSupplierListAction('AttractionObjects');
  };
  componentDidUpdate() {
    if (this.props.statusExcursion === 'success') {
      this.setState({
        excursionList: this.props.listExcursion,
      });
      this.setState({ loadingExcursion: false });
      // this.props.resetExcursionAction();
      this.props.resetAccomodationAction();
      this.props.resetExcursionPageAction();
    } else if (this.props.statusExcursion === 'failed') {
      this.setState({
        modalOpen: true,
        loadingExcursion: false,
        headerAlert: 'Error',
        messageAlert: this.props.errors.global,
      });
      this.props.resetExcursionAction();
      this.props.resetExcursionPageAction();
    }
    if (this.props.getExursionObjectTemplateStatus === 'success') {
      var templateExcursion = this.props.getExursionObjectTemplate;
      var elemenTemplateExcursion = document.createElement('a');
      var TemplateExcursion = new Blob([templateExcursion], {
        type: 'text/plain',
      });
      elemenTemplateExcursion.href = URL.createObjectURL(TemplateExcursion);
      elemenTemplateExcursion.download = 'Excursion Template.txt';
      elemenTemplateExcursion.click();
      this.props.resetExcursionAction();
      this.props.resetExcursionPageAction();
    } else if (this.props.getExursionObjectTemplateStatus === 'failed') {
      this.props.resetExcursionAction();
      this.props.resetExcursionPageAction();
    }

    if (this.props.getExursionObjectExportStatus === 'success') {
      var exportExcursion = this.props.getExursionObjectExport;
      var elementExcursion = document.createElement('a');
      var fileAccomdation = new Blob([exportExcursion], {
        type: 'text/plain',
      });
      elementExcursion.href = URL.createObjectURL(fileAccomdation);
      elementExcursion.download = 'Excursion Export.txt';
      elementExcursion.click();
      this.props.resetExcursionAction();
      this.props.resetExcursionPageAction();
    } else if (this.props.getExursionObjectExportStatus === 'failed') {
      this.props.resetExcursionAction();
      this.props.resetExcursionPageAction();
    }
    if (this.props.getExursionPriceExportStatus === 'success') {
      this.handleDownload(
        this.props.getExursionPriceExport,
        'Excursion Item Export.txt'
      );
      this.props.resetExcursionAction();
      this.props.resetExcursionPageAction();
    } else if (this.props.getExursionPriceExportStatus === 'failed') {
      this.props.resetExcursionAction();
      this.props.resetExcursionPageAction();
    }
  }
  editExcurtionProfile = (e, { value }) => {
    //eslint-disable-next-line
    // console.log('Masuk');
    this.setState({ redirect: true, serviceItemId: value });
  };
  openModalConfirm = message => {
    this.setState({ ...this.state, openModalConfirm: true, message: message });
  };
  closeModalConfirm = () => {
    this.setState({ ...this.state, openModalConfirm: false });
  };
  renderRedirect = () => {
    if (this.state.redirect) {
      //eslint-disable-next-line
      // console.log(this.state.serviceItemId);
      return (
        <Redirect to={'/Excursion-Profile/Edit/' + this.state.serviceItemId} />
      );
    }
  };
  ButtonUploadData = () => {
    // eslint-disable-next-line
    this.props.history.push(`/Upload-Master-Data/${'Excursion'}`);
  };
  ButtonDownloadTemplate = () => {
    this.props.getTemplateExcursionObjectAction();
  };
  ButtonExport = () => {
    this.props.getExportExcursionObjectAction();
  };
  buttonNew = () => {
    this.setState({ linkNew: true });
  };
  deleteExcursion = (e, { value }) => {
    let excursion = this.state.excursionList.find(
      item => item.ServiceItemId === value
    );
    this.setState({
      openModalConfirm: true,
      message: `Are you sure want to delete excursion "${excursion.Name}"?`,
      serviceItemId: value,
      actionConfirmType: 'DELETE',
    });
  };
  handleConfirmModal = () => {
    this.closeModalConfirm();
    let type = this.state.actionConfirmType;
    if (type === 'DELETE') {
      this.deleteData();
    }
  };
  deleteData = () => {
    let id = this.state.serviceItemId;
    let context = this;
    this.props
      .deleteExcursionObjectAction(id)
      .then(() => {
        context.handleOpen('Success', 'Successfully delete excursion');
        // context.props.getExcursionListAction();
        context.props.getSupplierListAction('AttractionObjects');
      })
      .catch(() => {
        context.handleOpen('Failed', 'Failed delete excursion');
      });
  };

  redirectLink = () => {
    if (this.state.linkNew) {
      return <Redirect to="/Excursion-Profile" />;
    }
  };

  buttonExportItem = () => {
    this.props.getExportExcursionPriceAction();
  };

  handleDownload = (exportFile, name) => {
    var elementDownload = document.createElement('a');
    var fileData = new Blob([exportFile], {
      type: 'text/plain',
    });
    elementDownload.href = URL.createObjectURL(fileData);
    elementDownload.download = name;
    elementDownload.click();
  };

  render() {
    let {
      loadingExcursion,
      openModalConfirm,
      message,
      messageAlert,
      modalOpen,
      headerAlert,
    } = this.state;
    let loading = loadingExcursion || this.props.loading;
    return (
      <div>
        <Dimmer.Dimmable blurring dimmed={loading}>
          {this.renderRedirect()}
          {this.redirectLink()}
          <Loader loading={loading} />
          <ListHelper
            data={this.state.excursionList}
            type="excursion"
            filterBy="EXCURTION"
            butttonEdit={this.editExcurtionProfile}
            buttonUploadData={this.ButtonUploadData}
            buttonDownloadTemplate={this.ButtonDownloadTemplate}
            butttonExport={this.ButtonExport}
            buttonNew={this.buttonNew}
            buttonDelete={this.deleteExcursion}
            title="Excursion List"
            buttonExportItem={this.buttonExportItem}
          >
            <ExcursionList />
          </ListHelper>
          <ModalAlert
            openModal={modalOpen}
            handleClose={this.handleClose}
            header={headerAlert}
            content={messageAlert}
            nameButton="OK"
          />
          <ModalConfirm
            close={this.closeModalConfirm}
            openModal={openModalConfirm}
            size="mini"
            confirm={this.handleConfirmModal}
            message={message}
            header="Confirmation"
          />
        </Dimmer.Dimmable>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    // listExcursion: state.ExcursionStore.getExcursionList,
    // statusExcursion: state.ExcursionStore.getExcursionListStatus,
    listExcursion: state.AccomodationStore.getSupplierList,
    statusExcursion: state.AccomodationStore.getSupplierListStatus,
    getExursionObjectExport: state.ExcursionPageStore.getExursionObjectExport,
    getExursionObjectTemplate: state.ExcursionStore.getExursionObjectTemplate,
    getExursionObjectExportStatus:
      state.ExcursionPageStore.getExursionObjectExportStatus,
    getExursionObjectTemplateStatus:
      state.ExcursionStore.getExursionObjectTemplateStatus,
    loading: state.ExcursionStore.loading
      ? state.ExcursionStore.loading
      : state.ExcursionPageStore.loading,
    errors: state.ExcursionStore.errors
      ? state.ExcursionStore.errors
      : state.ExcursionPageStore.errors,
    getExursionPriceExportStatus:
      state.ExcursionPageStore.getExursionPriceExportStatus,
    getExursionPriceExport: state.ExcursionPageStore.getExursionPriceExport,
  };
};
Excursion.propTypes = {
  // getExcursionListAction: PropTypes.func,
  statusExcursion: PropTypes.string,
  listExcursion: PropTypes.object,
  resetExcursionAction: PropTypes.func,
  resetExcursionPageAction: PropTypes.func,
  getExportExcursionObjectAction: PropTypes.func,
  getTemplateExcursionObjectAction: PropTypes.func,
  getExursionObjectExport: PropTypes.array,
  getExursionObjectExportStatus: PropTypes.string,
  getExursionObjectTemplate: PropTypes.array,
  getExursionObjectTemplateStatus: PropTypes.string,
  deleteExcursionObjectAction: PropTypes.func,
  loading: PropTypes.bool,
  errors: PropTypes.object,
  getSupplierListAction: PropTypes.func,
  resetAccomodationAction: PropTypes.func,
  getExportExcursionPriceAction: PropTypes.func,
  getExursionPriceExportStatus: PropTypes.string,
  getExursionPriceExport: PropTypes.string,
};
export default connect(
  mapStateToProps,
  {
    // getExcursionListAction,
    resetExcursionAction,
    resetExcursionPageAction,
    getTemplateExcursionObjectAction,
    getExportExcursionObjectAction,
    deleteExcursionObjectAction,
    getSupplierListAction,
    resetAccomodationAction,
    getExportExcursionPriceAction,
  }
)(Excursion);
