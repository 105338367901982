import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Grid, Form } from 'semantic-ui-react';
import InputWithLabel from '../../../../shared/components/common/input/InputWithLabel';
import styles from '../../styles';
import { fieldToValidateCostPrice } from '../../constants/fieldToValidate';
import saveValidation from '../../../../shared/services/validation/saveValidation';
import emptyFieldValidation from '../../services/emptyFieldValidation';
import _ from 'lodash';
import InputCurrencyFormat from '../../../../../components/input/inputCurrencyFormat';
import ButtonClose from '../../../../../components/button/buttonCloseModal';
import { StylesTextfieldWithError } from '../../../../../components/common/styleCommon';

const ModalPrice = props => {
  // --------------------- initial ---------------------
  const {
    data,
    costType,
    openModal,
    closeModal,
    handleSavePrice,
    isEdit,
    setIsErrorPrice,
  } = props;
  const [detailPrice, setDetailPrice] = useState({});
  const [errors, setErrors] = useState({});

  // --------------------- helper ---------------------
  const setDetailData = () => {
    // console.log(data);
    let object = {
      idx: -1,
    };
    setDetailPrice(!isEdit ? object : data);
  };

  const validation = (data = null) => {
    let errors = emptyFieldValidation(
      data ? data : detailPrice,
      fieldToValidateCostPrice
    );
    setErrors(errors);
  };

  const validateSave = (state = null) => {
    state = state ? state : detailPrice;
    let errors = saveValidation(state, fieldToValidateCostPrice);
    let isError = !_.isEmpty(errors);
    setErrors(errors);
    return isError;
  };

  // --------------------- handler ---------------------

  const handleChange = (e, { name, value }) => {
    // console.log(name, value);
    let dataPrice = { ...detailPrice };
    if (name === 'TransportationAdditionalCostType') {
      if (dataPrice.TransportationAdditionalCostType) {
        dataPrice.TransportationAdditionalCostType.Value = value;
        dataPrice.TransportationAdditionalCostType.Text =
          value === '0' ? 'Per-Destination' : 'Return';
      } else {
        dataPrice.TransportationAdditionalCostType = {
          Value: value,
          Text: value === '0' ? 'Per-Destination' : 'Return',
        };
      }
      dataPrice.TransportationAdditionalCostTypeId = value;
    } else if (name === 'Amount') {
      let parsed = parseFloat(value);
      let newVal = parsed > 1000000000 ? parsed / 10 : parsed;
      dataPrice[name] = parseFloat(newVal);
    } else if (name === 'TransportationAdditionalCostName') {
      let lengthString = 50;
      let trimmedString = value.substring(0, lengthString);
      dataPrice.TrasportationAdditionalCostName = trimmedString;
      dataPrice[name] = value;
    } else if (name === 'Description') {
      let lengthString = 500;
      let trimmedString = value.substring(0, lengthString);
      dataPrice.Description = trimmedString;
    } else {
      dataPrice[name] = value;
    }
    setDetailPrice(dataPrice);
    validation(dataPrice);
  };

  const handleSave = () => {
    let validateField = validateSave();
    if (!validateField) {
      handleSavePrice(detailPrice);
      !setIsErrorPrice &&
        setDetailPrice({
          TransportationAdditionalCostName: '',
          TransportationAdditionalCostType: '',
          Amount: 0,
          Description: '',
        });
    }
  };

  const handleCloseModal = () => {
    setDetailPrice({});
    closeModal();
  };

  const handleChangePrice = (value, name) => {
    let dataPrice = { ...detailPrice };
    let parsed = parseFloat(value);
    let newVal = parsed > 1000000000 ? parsed / 10 : parsed;
    dataPrice[name] = parseFloat(newVal);
    setDetailPrice(dataPrice);
    validation(dataPrice);
  };

  return (
    <Modal
      dimmer="blurring"
      inverted
      size="tiny"
      open={openModal}
      // closeIcon
      onClose={handleCloseModal}
      onMount={setDetailData}
    >
      <Modal.Header>
        {`${isEdit ? 'Edit' : 'Add'} Cost Price`}{' '}
        <ButtonClose onClose={handleCloseModal} />
      </Modal.Header>
      <Modal.Content>
        <Grid>
          <Grid.Row style={styles.paddingRow} columns="equal">
            <Grid.Column>
              <Form>
                <Form.Field>
                  <InputWithLabel
                    label="Cost Name"
                    type="text"
                    name="TransportationAdditionalCostName"
                    required
                    fluid={true}
                    placeholder="Cost Name"
                    value={
                      detailPrice &&
                      detailPrice.TransportationAdditionalCostName
                    }
                    handleChange={handleChange}
                    validate={errors}
                  />
                </Form.Field>
              </Form>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={2} style={styles.paddingRow}>
            <Grid.Column width={6}>
              <Form>
                <Form.Field>
                  <InputWithLabel
                    label="Cost Type"
                    type="select"
                    name="TransportationAdditionalCostType"
                    required
                    fluid={true}
                    placeholder="Cost Type"
                    options={costType}
                    value={
                      detailPrice &&
                      detailPrice.TransportationAdditionalCostType &&
                      detailPrice.TransportationAdditionalCostType.Value
                    }
                    handleChange={handleChange}
                    validate={errors}
                  />
                </Form.Field>
              </Form>
            </Grid.Column>
            <Grid.Column width={10}>
              <Form>
                <Form.Field required>
                  <label
                    style={
                      errors && errors.Amount && errors.Amount.message
                        ? StylesTextfieldWithError.Red
                        : StylesTextfieldWithError.bold
                    }
                  >
                    Price
                  </label>
                  <InputCurrencyFormat
                    value={detailPrice && detailPrice.Amount}
                    name="Amount"
                    disabled={false}
                    handelChangePrice={handleChangePrice}
                    errors={errors && errors.Amount && errors.Amount.message}
                  />
                </Form.Field>
              </Form>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={styles.paddingRow}>
            <Grid.Column>
              <Form>
                <Form.Field>
                  <InputWithLabel
                    label="Description"
                    type="textArea"
                    name="Description"
                    required
                    fluid={true}
                    placeholder="Description"
                    value={detailPrice && detailPrice.Description}
                    handleChange={handleChange}
                    validate={errors}
                  />
                </Form.Field>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions style={{ textAlign: 'right' }}>
        <Button
          positive
          name="Save"
          content="Save"
          onClick={handleSave}
          //   floated="right"
        />
      </Modal.Actions>
    </Modal>
  );
};

ModalPrice.propTypes = {
  data: PropTypes.object,
  costType: PropTypes.array,
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  handleSavePrice: PropTypes.func,
  isEdit: PropTypes.bool,
  setIsErrorPrice: PropTypes.bool,
};

export default React.memo(ModalPrice);
