import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { HeaderAdditionalItemDetail } from '../../table/headerTabel';
const tableAdditionalItemInDetailBooking = props => (
  <div style={{ marginTop: '2em' }}>
    <Table basic="very" selectable>
      <Table.Header>
        <Table.Row>
          {HeaderAdditionalItemDetail.map((headTableContent, index) => {
            return (
              <Table.HeaderCell key={index}>
                {headTableContent}
              </Table.HeaderCell>
            );
          })}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {props.listData.length > 0 ? (
          props.listData.map((data, idx) => {
            return (
              data.Qty > 0 && (
                <Table.Row key={idx}>
                  <Table.Cell>
                    {data.Name}{' '}
                    {data.IsMandatory
                      ? '(Mandatory)'
                      : '(Paid at the last payment)'}
                  </Table.Cell>
                  <Table.Cell>
                    {data.CurrencyId +
                      '           ' +
                      new Intl.NumberFormat('de-DE').format(data.Paid)}
                  </Table.Cell>
                  <Table.Cell>{data.Qty}</Table.Cell>
                  <Table.Cell
                    style={
                      Number(data.UnitCost) < 0
                        ? { color: 'red', fontWeight: 'bold' }
                        : {}
                    }
                  >
                    {data.UnitCost < 0
                      ? ` ( ${data.CurrencyId} ${new Intl.NumberFormat(
                          'de-DE'
                        ).format(Math.abs(data.UnitCost))} ) `
                      : `${data.CurrencyId} ${new Intl.NumberFormat(
                          'de-DE'
                        ).format(Math.abs(data.UnitCost))}`}
                  </Table.Cell>
                  <Table.Cell
                    style={
                      Number(data.Payable) < 0
                        ? { color: 'red', fontWeight: 'bold' }
                        : {}
                    }
                  >
                    {data.Payable < 0
                      ? ` ( ${data.CurrencyId} ${new Intl.NumberFormat(
                          'de-DE'
                        ).format(Math.abs(data.Payable))} ) `
                      : `${data.CurrencyId} ${new Intl.NumberFormat(
                          'de-DE'
                        ).format(Math.abs(data.Payable))}`}
                  </Table.Cell>
                </Table.Row>
              )
            );
          })
        ) : (
          <Table.Row>
            <Table.Cell colSpan="5" textAlign="center">
              No Data
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  </div>
);

tableAdditionalItemInDetailBooking.propTypes = {
  listData: PropTypes.array,
};
export default tableAdditionalItemInDetailBooking;
