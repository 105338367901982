import { copyObject } from '../itineraryBuilder/guest';

const setOperator = (
  TourOperator,
  BaseMarginPercentage,
  TourOperatorMarginPercentage,
  IsTourOperatorFixedPackage
) => {
  let tourOperator = {
    TourOperator: TourOperator.Id,
    BaseMarginPercentage: BaseMarginPercentage,
    TourOperatorMarginPercentage: TourOperatorMarginPercentage,
    IsTourOperatorFixedPackage: IsTourOperatorFixedPackage,
  };
  return tourOperator;
};

const setPrice = (
  Price,
  IsTourOperatorFixedPackage,
  SystemMargin,
  TourOperatorMargin
) => {
  let packagePrice = {
    SharingRoomPrice: 0,
    SharingBedPrice: 0,
    SingleRoomPrice: 0,
    AdultExtraBedPrice: 0,
    ChildExtraBedPrice: 0,
    NoBedPrice: 0,
  };
  if (IsTourOperatorFixedPackage) {
    packagePrice = {
      SingleRoomPrice: Price.SingleRoomPrice,
      SharingRoomPrice: Price.SharingRoomPrice,
      AdultExtraBedPrice: Price.AdultExtraBedPrice,
      SharingBedPrice: Price.SharingBedPrice,
      NoBedPrice: Price.NoBedPrice,
      ChildExtraBedPrice: Price.ChildExtraBedPrice,
      CurrencyId: Price.CurrencyId,
    };
  } else {
    packagePrice = setPackagePriceFunc(
      Price,
      copyObject(Price),
      SystemMargin,
      TourOperatorMargin
    );
  }
  return packagePrice;
};

const setCost = (
  price,
  BaseMarginPercentage,
  TourOperatorMarginPercentage,
  IsTourOperatorFixedPackage
) => {
  let packageCost = {
    SharingRoomPrice: 0,
    SharingBedPrice: 0,
    SingleRoomPrice: 0,
    AdultExtraBedPrice: 0,
    ChildExtraBedPrice: 0,
    NoBedPrice: 0,
  };
  if (IsTourOperatorFixedPackage) {
    packageCost.SharingRoomPrice = calculateCost(
      price.SharingRoomPrice,
      BaseMarginPercentage,
      TourOperatorMarginPercentage
    );
    packageCost.SingleRoomPrice = calculateCost(
      price.SingleRoomPrice,
      BaseMarginPercentage,
      TourOperatorMarginPercentage
    );
    packageCost.AdultExtraBedPrice = calculateCost(
      price.AdultExtraBedPrice,
      BaseMarginPercentage,
      TourOperatorMarginPercentage
    );
    packageCost.SharingBedPrice = calculateCost(
      price.SharingBedPrice,
      BaseMarginPercentage,
      TourOperatorMarginPercentage
    );
    packageCost.ChildExtraBedPrice = calculateCost(
      price.ChildExtraBedPrice,
      BaseMarginPercentage,
      TourOperatorMarginPercentage
    );
    packageCost.NoBedPrice = calculateCost(
      price.NoBedPrice,
      BaseMarginPercentage,
      TourOperatorMarginPercentage
    );
  } else {
    packageCost.SharingRoomPrice = price.SharingRoomPrice;
    packageCost.SingleRoomPrice = price.SingleRoomPrice;
    packageCost.AdultExtraBedPrice = price.AdultExtraBedPrice;
    packageCost.SharingBedPrice = price.SharingBedPrice;
    packageCost.ChildExtraBedPrice = price.ChildExtraBedPrice;
    packageCost.NoBedPrice = price.NoBedPrice;
  }
  return packageCost;
};

const calculateCost = (value, baseMargin, tourMargin) => {
  let newCost = Number(value) / (1 + Number(baseMargin / 100));
  let costBaseMargin = Number(value) - Number(Math.round(newCost));
  let newValue = value - costBaseMargin;
  let newCost1 = Number(newValue) / (1 + Number(tourMargin) / 100);
  let costTourMargin = Number(newValue) - Number(Math.round(newCost1));
  return Number(value) - Number(costBaseMargin) - Number(costTourMargin);
};

const calculatePrice = (value, baseMargin, tourMargin) => {
  let newBaseMargin =
    (Number(value) * Number(baseMargin ? baseMargin : 0)) / 100;
  let systemMargin =
    ((Number(newBaseMargin) + Number(value)) *
      Number(tourMargin ? tourMargin : 0)) /
    100;
  return Number(Number(value) + Number(newBaseMargin) + Number(systemMargin));
};
const calculateCostMargin = (value, baseMargin) => {
  let newBaseMargin = (Number(value) * Number(baseMargin)) / 100;
  return value - newBaseMargin;
};

const setPackagePriceFunc = (
  packageCost,
  packagePrice,
  baseMargin,
  tourMargin
) => {
  packagePrice.SharingRoomPrice = calculatePrice(
    packageCost.SharingRoomPrice,
    baseMargin,
    tourMargin
  );
  packagePrice.SingleRoomPrice = calculatePrice(
    packageCost.SingleRoomPrice,
    baseMargin,
    tourMargin
  );
  packagePrice.SharingBedPrice = calculatePrice(
    packageCost.SharingBedPrice,
    baseMargin,
    tourMargin
  );
  packagePrice.AdultExtraBedPrice = calculatePrice(
    packageCost.AdultExtraBedPrice,
    baseMargin,
    tourMargin
  );
  packagePrice.ChildExtraBedPrice = calculatePrice(
    packageCost.ChildExtraBedPrice,
    baseMargin,
    tourMargin
  );
  packagePrice.NoBedPrice = calculatePrice(
    packageCost.NoBedPrice,
    baseMargin,
    tourMargin
  );
  return packagePrice;
};
// bener
const setPackageCostFunc = (packageCost, packagePrice, baseMargin) => {
  packageCost.SharingRoomPrice = calculateCostMargin(
    packagePrice.SharingRoomPrice,
    baseMargin
  );
  packageCost.SingleRoomPrice = calculateCostMargin(
    packagePrice.SingleRoomPrice,
    baseMargin
  );
  packageCost.SharingBedPrice = calculateCostMargin(
    packagePrice.SharingBedPrice,
    baseMargin
  );
  packageCost.AdultExtraBedPrice = calculateCostMargin(
    packagePrice.AdultExtraBedPrice,
    baseMargin
  );
  packageCost.ChildExtraBedPrice = calculateCostMargin(
    packagePrice.ChildExtraBedPrice,
    baseMargin
  );
  packageCost.NoBedPrice = calculateCostMargin(
    packagePrice.NoBedPrice,
    baseMargin
  );
  return packageCost;
};

const setBrochure = state => {
  let data = state.length > 0 ? state[0] : {};
  let image = {
    ImageId: data.ImageId,
    ImageUrl: data.ImageUrl,
    TinyImageUrl: data.TinyImageUrl,
    ImageName: data.ImageName,
    IsPrimaryImage: data.IsPrimaryImage,
    ImagePreview: data.ImageUrl,
  };
  return image;
};
export {
  setOperator,
  setPrice,
  calculateCost,
  calculatePrice,
  calculateCostMargin,
  setCost,
  setPackagePriceFunc,
  setPackageCostFunc,
  setBrochure,
};
