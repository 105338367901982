import React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import ModalAlert from '../../../../components/modal/modalAlert';
import SegmentAdditionalCostDetail from './SegmentAdditionalCostDetail';
import { getMasterDataReferenceApi } from '../../../../api/uploadMasterDataReferenceApi/getMasterDataReferenceApi';
import getCountryApi from '../../../../api/country/getCountryApi';
import getCityInCountryApi from '../../../../api/city/getCityInCountryApi';
import getCurrenciesApi from '../../../../api/getCurrenciesApi';
import {
  getAdditionalCostTypeApi,
  getAdditionalCostByCityAndTypeApi,
  postAdditionalCostDetailApi,
  putAdditionalCostDetailApi,
} from '../../../../api/transportationAdditionalCostApi/transportationAdditionalCostApi';
import ModalPrice from './modal/ModalPrice';
import useApiCall from '../hooks/useApiCall';
import useApiCallDetail from '../hooks/useApiCallDetail';
import { setDataSave, setNewId } from '../services/setData';
import _ from 'lodash';
import emptyFieldValidation from '../services/emptyFieldValidation';
import { fieldToValidateAdditionalCost } from '../constants/fieldToValidate';
import saveValidation from '../../../shared/services/validation/saveValidation';
import ModalConfirmationTwoButton from '../../../../components/modal/modalConfirmationTwoButton';
const AdditionalCostDetail = props => {
  // --------------------- inital ---------------------
  const { origin, destination, type } = props.match.params;
  const [goTo, setGoTo] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [openModalPrice, setOpenModalPrice] = useState(false);
  const [openModalAlert, setOpenModalAlert] = useState(false);
  const [detailPrice, setDetailPrice] = useState({});
  const [saveLoading, setSaveLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isChangeCountry, setIsChangeCountry] = useState('All');
  const [
    additionalCostLoading,
    additionalCostDetail,
    setAdditionalCostDetail,
    loadData,
  ] = useApiCallDetail(
    getAdditionalCostByCityAndTypeApi,
    origin,
    destination,
    type
  );

  const [transportationTypeLoading, transportationTypeList] = useApiCall(
    getMasterDataReferenceApi,
    'TransportationTypes'
  );
  const [countriesLoading, countriesList] = useApiCall(getCountryApi);
  const [
    cityInOriginCountryLoading,
    cityInOriginCountryList,
    loadOriginCity,
  ] = useApiCall(
    getCityInCountryApi,
    additionalCostDetail &&
      additionalCostDetail.OriginalCountry &&
      additionalCostDetail.OriginalCountry.Value
  );
  const [
    cityInDestinationCountryLoading,
    cityInDestinationCountryList,
    loadDestinationCity,
  ] = useApiCall(
    getCityInCountryApi,
    additionalCostDetail &&
      additionalCostDetail.DestinationCountry &&
      additionalCostDetail.DestinationCountry.Value
  );
  const [currenciesLoading, currenciesList] = useApiCall(getCurrenciesApi);
  const [additionalCostTypeLoading, additionalCostTypeList] = useApiCall(
    getAdditionalCostTypeApi
  );
  const [id, setId] = useState({});
  const [headerModal, setHeaderModal] = useState('');
  const [contentModal, setContentModal] = useState('');
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [contentModalConfirm, setContentModalConfirm] = useState('');
  const [indexDeletePrice, setIndexDeletePrice] = useState(null);
  const [isEdit, setIsEdit] = useState(null);
  const [isErrorPrice, setIsErrorPrice] = useState(null);
  // --------------------- helper ---------------------
  const destructureOption = data => {
    let newData = data.map(item => {
      return {
        key: item.Id,
        value: item.Id,
        text: item.Name,
      };
    });
    return newData;
  };

  const getIndexofInArrayObject = (arr, prop, val) => {
    let arrId = arr.map(item => {
      return item[prop];
    });
    let index = arrId.indexOf(val);
    return index;
  };

  const openModal = (isOpen, header, content) => {
    setHeaderModal(header);
    setContentModal(content);
    setOpenModalAlert(isOpen);
  };

  const setSuccess = (header = null, content = null) => {
    setSaveLoading(false);
    openModal(
      true,
      header ? header : 'Success',
      content ? content : 'Success save additional cost'
    );
  };

  const setFailed = (header = null, content = null) => {
    setSaveLoading(false);
    openModal(
      true,
      header ? header : 'Failed',
      content ? content : 'Failed save additional cost'
    );
  };

  const checkPriceTable = (data, isError) => {
    let nullPrice = false;
    if (!isError) {
      nullPrice =
        typeof data.DetailCost === 'undefined' || data.DetailCost.length === 0;
      if (nullPrice) {
        openModal(true, 'Failed', 'Table Cost Price Must Be Fill');
      }
    }

    return nullPrice;
  };

  const validation = (data = null) => {
    let errors = emptyFieldValidation(
      data ? data : additionalCostDetail,
      fieldToValidateAdditionalCost
    );
    setErrors(errors);
  };

  const validateSave = (state = null) => {
    state = state ? state : additionalCostDetail;
    let errors = saveValidation(state, fieldToValidateAdditionalCost);
    let isError = !_.isEmpty(errors);
    setErrors(errors);
    let checkTable = checkPriceTable(state, isError);
    return isError || checkTable;
  };

  // --------------------- handler ---------------------
  const handleBack = () => {
    setGoTo(`/transportation-additional-cost`);
    setRedirect(true);
  };

  const handleChangeInput = (e, { name, value }) => {
    let addCostDetail = { ...additionalCostDetail };
    if (origin && destination && type) {
      addCostDetail[name].Value =
        typeof value === 'number' ? value.toString() : value;
    } else {
      addCostDetail[name] = {
        Value: typeof value === 'number' ? value.toString() : value,
      };
    }
    if (name === 'OriginalCountry' && addCostDetail.OriginalCity) {
      addCostDetail.OriginalCity.Value = '';
    } else if (name === 'DestinationCountry' && addCostDetail.DestinationCity) {
      addCostDetail.DestinationCity.Value = '';
    }
    setIsChangeCountry(name);
    setAdditionalCostDetail(addCostDetail);
    validation(addCostDetail);
  };

  const handleCloseModal = () => {
    setOpenModalPrice(false);
    setOpenModalAlert(false);
    // setDetailPrice({});
  };

  const handleAddPrice = () => {
    if (
      additionalCostDetail &&
      additionalCostDetail.Currency &&
      additionalCostDetail.Currency.Value
    ) {
      let data = {
        idx: -1,
      };
      setDetailPrice(data);
      setOpenModalPrice(true);
      setIsEdit(false);
    } else {
      openModal(true, 'Failed', 'Please fill Additional Cost Setting First');
    }
  };

  const handleEditPrice = (e, { idx }) => {
    let detailCost = { ...additionalCostDetail };
    let data = detailCost.DetailCost[idx];
    data.idx = idx;
    setDetailPrice(data);
    setOpenModalPrice(true);
    setIsEdit(true);
  };

  const handleDeletePrice = () => {
    let dataDetail = { ...additionalCostDetail };
    let detailCost = [...dataDetail.DetailCost];
    detailCost.splice(indexDeletePrice, 1);
    dataDetail.DetailCost = detailCost;
    setAdditionalCostDetail(dataDetail);
    setOpenModalConfirm(false);
  };

  const handleCloseModalConfirmation = () => {
    setOpenModalConfirm(false);
  };

  const handleConfirmationDeletePrice = idx => {
    setOpenModalConfirm(true);
    setContentModalConfirm(
      `Are you sure to delete ${additionalCostDetail.DetailCost[idx].TransportationAdditionalCostName} price ?`
    );
    setIndexDeletePrice(idx);
  };
  const handleSavePrice = data => {
    let dataDetail = { ...additionalCostDetail };
    let detailCost = dataDetail.DetailCost ? [...dataDetail.DetailCost] : [];
    let samePrice =
      !isEdit && detailCost
        ? detailCost.find(
            item =>
              item.TransportationAdditionalCostName.toLowerCase() ===
                data.TransportationAdditionalCostName.toLowerCase() &&
              item.TransportationAdditionalCostType.Value ===
                data.TransportationAdditionalCostType.Value
          )
        : false;
    if (!samePrice) {
      let indexID =
        detailCost && getIndexofInArrayObject(detailCost, 'Id', data.Id);
      let indexArray =
        detailCost && getIndexofInArrayObject(detailCost, 'idx', data.idx);
      data.idx = detailCost.length;
      if (data.Id) {
        detailCost[indexID] = data;
      } else {
        if (indexArray === -1) detailCost.push(data);
        else detailCost[indexArray] = data;
      }
      dataDetail.DetailCost = detailCost;
      setAdditionalCostDetail(dataDetail);
      setDetailPrice({});
      setOpenModalPrice(false);
    } else {
      // setDetailPrice({});
      openModal(
        true,
        'Failed',
        'Additional Cost Name & Cost Type can not be the same'
      );
      setIsErrorPrice(true);
    }
  };

  const handleSave = () => {
    let validateField = validateSave();
    if (!validateField) {
      let data = { ...additionalCostDetail };
      let resData = setDataSave(data);
      setSaveLoading(true);
      if (!origin && !destination && !type) {
        postAdditionalCostDetailApi(resData)
          .then(() => {
            let newId = setNewId(resData);
            setId(newId);
            setSuccess();
          })
          .catch(error => {
            setFailed('Failed', error.response.data.Message);
          });
      } else {
        putAdditionalCostDetailApi(resData)
          .then(() => {
            setNewId(resData);
            setSuccess();
          })
          .catch(error => {
            setFailed('Failed', error.response.data.Message);
          });
      }
    }
  };

  const handleClose = () => {
    if (!_.isEmpty(id)) {
      setGoTo(
        `/transportation-additional-cost/detail/${id.origin}/${id.destination}/${id.type}`
      );
      setRedirect(true);
    } else {
      openModal(false, '', '');
      if (headerModal !== 'Failed') {
        loadData(id.origin, id.destination, id.type);
      }
    }
  };

  // --------------------- lifecycle ---------------------
  useEffect(() => {
    openModal(false, '', '');
    setRedirect(false);
    loadData(origin, destination, type);
    // eslint-disable-next-line
  }, [origin]);

  useEffect(() => {
    (isChangeCountry === 'OriginalCountry' || isChangeCountry === 'All') &&
      additionalCostDetail &&
      additionalCostDetail.OriginalCountry &&
      additionalCostDetail.OriginalCountry.Value &&
      loadOriginCity(
        additionalCostDetail &&
          additionalCostDetail.OriginalCountry &&
          additionalCostDetail.OriginalCountry.Value
      );
    (isChangeCountry === 'DestinationCountry' || isChangeCountry === 'All') &&
      additionalCostDetail &&
      additionalCostDetail.DestinationCountry &&
      additionalCostDetail.DestinationCountry.Value &&
      loadDestinationCity(
        additionalCostDetail &&
          additionalCostDetail.DestinationCountry &&
          additionalCostDetail.DestinationCountry.Value
      );
    // eslint-disable-next-line
  }, [additionalCostDetail, isChangeCountry]);
  // --------------------- variable / const ---------------------
  let loading =
    additionalCostLoading ||
    transportationTypeLoading ||
    countriesLoading ||
    cityInOriginCountryLoading ||
    cityInDestinationCountryLoading ||
    currenciesLoading ||
    additionalCostTypeLoading ||
    saveLoading;

  let countriesListOption = countriesList && destructureOption(countriesList);

  let cityDestinationOption =
    cityInDestinationCountryList &&
    destructureOption(cityInDestinationCountryList);

  let cityOriginOption =
    cityInOriginCountryList && destructureOption(cityInOriginCountryList);

  let transportTypeOption =
    transportationTypeList && destructureOption(transportationTypeList);

  let costTypeOption =
    additionalCostTypeList &&
    additionalCostTypeList.map(type => {
      return {
        key: type.Value,
        value: type.Value,
        text: type.Text === 'OnReturn' ? 'Return' : 'Per-destination',
      };
    });

  let currenciesOption =
    currenciesList &&
    currenciesList.map(currency => {
      return {
        key: currency.Id,
        value: currency.Id,
        text: currency.Id,
      };
    });

  return redirect ? (
    <Redirect to={goTo} />
  ) : (
    <>
      <Dimmer.Dimmable dimmed={loading}>
        <Loader loading={loading} />
        <SegmentAdditionalCostDetail
          data={additionalCostDetail}
          transportationTypeList={transportTypeOption}
          countriesList={countriesListOption}
          cityInOriginCountryList={cityOriginOption}
          cityInDestinationCountryList={cityDestinationOption}
          currenciesList={currenciesOption}
          handleBack={handleBack}
          handleChangeInput={handleChangeInput}
          handleAddPrice={handleAddPrice}
          handleEditPrice={handleEditPrice}
          handleDeletePrice={handleConfirmationDeletePrice}
          handleSave={handleSave}
          errors={errors}
          loading={loading}
          additionalCostLoading={additionalCostLoading}
          transportationTypeLoading={transportationTypeLoading}
          countriesLoading={countriesLoading}
          cityInOriginCountryLoading={cityInOriginCountryLoading}
          cityInDestinationCountryLoading={cityInDestinationCountryLoading}
          currenciesLoading={currenciesLoading}
          additionalCostTypeLoading={additionalCostTypeLoading}
          saveLoading={saveLoading}
        />

        <ModalAlert
          openModal={openModalAlert}
          handleClose={handleClose}
          header={headerModal}
          content={contentModal}
          nameButton="OK"
        />
        <ModalPrice
          data={detailPrice}
          openModal={openModalPrice}
          closeModal={handleCloseModal}
          costType={costTypeOption}
          handleSavePrice={handleSavePrice}
          isEdit={isEdit}
          isErrorPrice={isErrorPrice}
        />
        <ModalConfirmationTwoButton
          openModal={openModalConfirm}
          close={handleCloseModalConfirmation}
          confirm={handleDeletePrice}
          message={contentModalConfirm}
          size="mini"
        />
      </Dimmer.Dimmable>
    </>
  );
};

AdditionalCostDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.string,
  }),
};

export default withRouter(AdditionalCostDetail);
