import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Header,
  Divider,
  Segment,
  Button,
  Label,
  Icon,
} from 'semantic-ui-react';
import Style from '../Styles';
import PaymentInformation from './PaymentInformation';
import PaymentHistory from './PaymentHistory';
import LoaderModal from '../../../components/modal/loaderModal';

const SegmentPaymentRequestDetail = props => {
  const {
    data,
    loadingModal,
    handleOpenModalPay,
    handleBack,
    handleSeePaymentProof,
  } = props;

  return data ? (
    <Segment style={{ marginTop: '30px' }}>
      <Label
        as="h3"
        size="huge"
        style={{
          position: 'absolute',
          top: '-25px',
          background: '#38af95',
          color: 'white',
        }}
      >
        <Icon name="clipboard list" /> Payment Request Detail
      </Label>
      <Grid columns={2}>
        <Grid.Column style={{ marginTop: '30px' }}>
          <Header as="h3">Payment Information</Header>
        </Grid.Column>
        <Grid.Column textAlign="right">
          <Button
            style={Style.BorderBlueBackgrounWhite}
            content="Back"
            size="small"
            onClick={handleBack}
          />
          <Button
            primary
            content={data.PaymentStatus === 'FULLPAYMENT' ? 'Paid' : 'Pay'}
            disabled={data.PaymentStatus === 'FULLPAYMENT'}
            size="small"
            onClick={handleOpenModalPay}
          />
        </Grid.Column>
      </Grid>
      <Grid.Column>
        <LoaderModal loading={loadingModal} />
        <PaymentInformation contentDetail={data} />
        <Grid.Row>
          <Grid.Column width={16}>
            <Header as="h3" content="Payment History" />
          </Grid.Column>
        </Grid.Row>
        <Divider style={{ borderColor: 'black' }} />
        <PaymentHistory
          listData={data.PaymentHistories}
          currency={data.Currency}
          handleSeePaymentProof={handleSeePaymentProof}
        />
        <Divider style={{ borderColor: 'black' }} />
      </Grid.Column>
    </Segment>
  ) : (
    <Segment loading={true} />
  );
};

SegmentPaymentRequestDetail.propTypes = {
  data: PropTypes.object,
  actionBookingConfirmation: PropTypes.func,
  loadingModal: PropTypes.bool,
  loadingInvoice: PropTypes.bool,
  handleDownloadInvoice: PropTypes.func,
  totalPayableValue: PropTypes.number,
  handleChangePayable: PropTypes.func,
  handleOpenModalPay: PropTypes.func,
  handleBack: PropTypes.func,
  handleSeePaymentProof: PropTypes.func,
};

export default SegmentPaymentRequestDetail;
