const setImageData = base => {
  let imageObj = {
    ImageId: 0,
    ImageContent: base.base64.split(',')[1],
    ImagePreview: base.base64,
    ImageName: base.file.name,
    IsPrimaryImage: false,
    onChange: true,
  };
  return imageObj;
};

const setImageRequestData = (Id, data) => {
  let imageData = {
    restaurantProfileId: Id,
    imageUrl: data.ImageContent,
    tinyImageUrl: data.ImageContent,
    ImageName: data.ImageName,
    ImageContent: data.ImageContent,
  };
  return imageData;
};

export { setImageData, setImageRequestData };
