import * as types from './constant/actionTypes';

const initialState = {
  loading: false,
  postCompanyProfileStatus: null,
  postCompanyProfile: {},
  putAccommodationSupplier: {},
  putAccommodationSupplierStatus: null,
  errors: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_SUPPLIER:
      return {
        ...state,
        postCompanyProfileStatus: null,
        putAccommodationSupplierStatus: null,
      };

    case types.POST_SUPPLIER:
      return { ...state };
    case types.POST_SUPPLIER_REJECTED:
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.message },
        postCompanyProfileStatus: false,
      };
    case types.POST_SUPPLIER_PENDING:
      return { ...state, loading: true };
    case types.POST_SUPPLIER_FULFILLED:
      return {
        ...state,
        postCompanyProfile: action.payload.data,
        loading: false,
        postCompanyProfileStatus: true,
      };

    case types.PUT_ACCOMMODATION_SUPPLIER:
      return { ...state };
    case types.PUT_ACCOMMODATION_SUPPLIER_REJECTED:
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.message },
        putAccommodationSupplierStatus: false,
      };
    case types.PUT_ACCOMMODATION_SUPPLIER_PENDING:
      return { ...state, loading: true };
    case types.PUT_ACCOMMODATION_SUPPLIER_FULFILLED:
      return {
        ...state,
        putAccommodationSupplier: action.payload.data,
        loading: false,
        putAccommodationSupplierStatus: true,
      };

    default:
      return state;
  }
};

export default reducer;
