import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header } from 'semantic-ui-react';
//import TimelineDailyOrderedItem from './TimelineDailyOrderedItem';
import styles from './styles';
import { convertDateFormat } from '../../../../../../scripts/moment';
import { ucwords } from '../../../../../../scripts/convertWord';

const HeaderItem = props => (
  <Grid.Row style={styles.paddingTop15px}>
    <Grid.Column width={16} style={styles.padding5px35pxMarginTop15px}>
      <Header as="h4">
        DAY {props.day} - {convertDateFormat(props.date, 'DD MMM YYYY')}
      </Header>
    </Grid.Column>
  </Grid.Row>
);

const Item = props => {
  let style = props.desc.indexOf('overtime') !== -1 ? styles.redColor : {};
  return (
    <Grid.Row style={props.style}>
      <Grid.Column width={7} style={styles.noPaddingNoMargin}>
        {props.title}
      </Grid.Column>
      <Grid.Column width={4} style={styles.noPaddingNoMargin}>
        <span style={style}>{props.qty}</span>
      </Grid.Column>
      <Grid.Column width={4} style={styles.noPaddingNoMargin}>
        <span style={style}>{props.desc}</span>
      </Grid.Column>
    </Grid.Row>
  );
};
const setIndex = (item, index, overtimeTransport) => {
  return item.ItemType === 'Accommodation'
    ? index + 1
    : item.ItemType === 'Attraction'
    ? index + 1
    : item.ItemType === 'Restaurant'
    ? index + 1
    : item.ItemType === 'Transportation' &&
      item.Description.indexOf('overtime') === -1 &&
      overtimeTransport === 0
    ? index + 1
    : item.ItemType === 'Transportation' &&
      item.Description.indexOf('overtime') !== -1 &&
      overtimeTransport !== 0 &&
      index + 2;
};

const DailyOrderedItems = props => {
  /* const { resourceBooking } = props;

  return resourceBooking.length ? (
    resourceBooking.map((data, idDays) => {
      return (
        <Grid
          verticalAlign="middle"
          key={idDays}
          style={{ paddingTop: '10px' }}
        >
          <div style={{ width: '100%' }}>
            <Loader active={false} inverted={true} />

            <TimelineDailyOrderedItem data={data} />
          </div>
        </Grid>
      );
    })
  ) : (
    <Grid verticalAlign="middle">
      <Grid.Row style={styles.marginDailyProgramDays}>
        <Grid.Column width={12}>
          <Header as="h4" style={{ marginTop: '10px' }}>
            Ordered Items Empty
          </Header>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  ); */
  let { orderedItem } = props;
  return (
    <Grid.Row>
      {orderedItem &&
        orderedItem.map(day => {
          let overtimeTransport = day.items
            ? day.items.filter(e => e.Description.indexOf('overtime') !== -1)
                .length
            : 0;
          let index = 0;
          return (
            <Grid.Column key={day.day} width={16}>
              <Grid>
                <HeaderItem day={day.day} date={day.date} />
                {day.items.map(item => {
                  index = setIndex(item, index, overtimeTransport);
                  let style =
                    index % 2 === 0
                      ? styles.orderedItemEven
                      : styles.orderedItemOdd;
                  return item.ItemType === 'Accommodation' ? (
                    <Item
                      key={index}
                      style={style}
                      title={item.ItemName.split('-')[0]}
                      qty={`${item.Qty} ${item.Qty > 1 ? 'Rooms' : 'Room'}`}
                      desc={
                        item.ItemName.split('-')[1] + ', ' + item.Description
                      }
                    />
                  ) : item.ItemType === 'Attraction' ? (
                    <Item
                      key={index}
                      style={style}
                      title={item.ItemName}
                      qty={`For ${item.Qty} ${
                        item.Qty > 1 ? 'Tickets' : 'Ticket'
                      }`}
                      desc={item.Description.split('_')[1]}
                    />
                  ) : item.ItemType === 'Restaurant' ? (
                    <Item
                      key={index}
                      style={style}
                      title={item.ItemName.replace('_', ' ')}
                      qty={`For ${item.Qty} ${
                        item.Qty > 1 ? 'Guests' : 'Guest'
                      }`}
                      desc={item.Description.split('_')[1]}
                    />
                  ) : item.ItemType === 'Transportation' &&
                    item.Description.toLowerCase().indexOf('overtime') ===
                      -1 ? (
                    <Grid
                      key={index}
                      style={styles.noMarginPadding10px0pxWidth100percent}
                    >
                      <Item
                        style={style}
                        title={item.ItemName.split('-')[0]}
                        qty={`For ${item.Qty} ${
                          item.Qty > 1 ? 'Unit' : 'Units'
                        }`}
                        desc={
                          item.Description + ', ' + item.ItemName.split('-')[1]
                        }
                      />
                      {overtimeTransport !== 0 && (
                        <Item
                          style={style}
                          title={item.ItemName.split('-')[0]}
                          qty={overtimeTransport + ' x 1 Hour'}
                          desc={'Transportation overtime'}
                        />
                      )}
                    </Grid>
                  ) : item.ItemType === 'Flight_Ticket' ? (
                    <Item
                      style={style}
                      title={item.ItemName}
                      qty={`For ${item.Qty} Guest`}
                      desc={ucwords(
                        item.Description.toString()
                          .split('_')
                          .join(' ')
                      )}
                    />
                  ) : (
                    item.ItemType === 'TourGuide' && (
                      <Item
                        style={style}
                        title={item.Description}
                        qty={`${item.Qty} Person for ${item.CityName}`}
                        desc={`Service Language in ${item.Language.Text}`}
                      />
                    )
                  );
                })}
              </Grid>
            </Grid.Column>
          );
        })}
    </Grid.Row>
  );
};

DailyOrderedItems.propTypes = {
  orderedItem: PropTypes.array,
};

Item.propTypes = {
  style: PropTypes.object,
  qty: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
};

HeaderItem.propTypes = {
  day: PropTypes.number,
  date: PropTypes.string,
};
export default DailyOrderedItems;
