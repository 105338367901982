import React, { Component } from 'react';
import { Table, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../../../scripts/pagination';
import { HeaderTableCommissionHistory } from '../../constant/HeaderTable';
import Loader from '../../../../../components/loader/loaderInModal';
import { convertDateFormat } from '../../../../../scripts/moment';
import { formatCurrency } from '../../../../../components/genericFunction/convertFormat';
import Pagination from '../../../../../components/common/pagination';
import ModalPay from '../../../../../components/modal/modalPayCommission';
class TableCommissionHistory extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
      pageLength: 3,
      openModalPay: false,
      cashBack: 0,
      tourTransactionId: '',
    };
  }
  static propTypes = {
    data: PropTypes.array,
  };
  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  handleModalPay = (e, { value, id }) => {
    this.setState({
      openModalPay: !this.state.openModalPay,
      cashBack: value,
      tourTransactionId: id,
    });
  };
  handleCloseModal = () => {
    this.setState({
      openModalPay: false,
    });
  };
  filterDateRangeFunc = () => {
    this.props.getCommissions();
  };
  render() {
    let pagedData = datasetPagination(this.props.listData, 3);
    let listData = pagedData[this.state.activePage - 1];
    const props = this.props;

    let { numbersFrom, numbersTo } = getNumberFromTo(
      listData,
      this.state.activePage,
      3
    );
    return (
      <div>
        <Loader loading={props.loading} />
        <Table basic="very" selectable fixed>
          <Table.Header>
            <Table.Row>
              {HeaderTableCommissionHistory.map((headTableContent, index) => {
                return (
                  <Table.HeaderCell key={index}>
                    {headTableContent}
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {listData ? (
              listData.map((commision, idx) => {
                const totalPax =
                  commision.AdultPax + commision.ChildPax + commision.InfantPax;
                return (
                  <Table.Row key={idx}>
                    <Table.Cell>
                      {convertDateFormat(commision.CreateDate, ' DD MMM YYYY')}
                    </Table.Cell>
                    <Table.Cell>
                      {commision.Currency +
                        ' ' +
                        formatCurrency(commision.TotalPaid)}
                    </Table.Cell>
                    <Table.Cell>
                      {' '}
                      {commision.Currency +
                        ' ' +
                        formatCurrency(
                          totalPax * commision.OutstandingStaffCashback
                        )}
                    </Table.Cell>
                    <Table.Cell>
                      {commision.IsStaffCashbackPaid ? 'Yes' : 'No'}
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        primary
                        content={
                          commision.IsStaffCashbackPaid ? 'Already Paid' : 'Pay'
                        }
                        disabled={commision.IsStaffCashbackPaid}
                        onClick={this.handleModalPay}
                        value={totalPax * commision.OutstandingStaffCashback}
                        id={commision.BookingId}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })
            ) : (
              <Table.Row>
                <Table.Cell colSpan="3" textAlign="center">
                  No Data
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        {/* {pagedData &&
            pagedData.length > 1 && (
              <Table.Footer> */}
        <Pagination
          // totalPages={pagedData.length}
          // onPageChange={this.pageChange}
          // activePage={this.state.activePage}
          pagedData={pagedData}
          numbersTo={numbersTo}
          numbersFrom={numbersFrom}
          data={props.listData}
          pageChange={this.pageChange}
          activePage={this.state.activePage}
        />
        <ModalPay
          openModal={this.state.openModalPay}
          handleClose={this.handleCloseModal}
          cashBack={this.state.cashBack}
          tourTransactionId={this.state.tourTransactionId}
          filterDateRangeFunc={this.filterDateRangeFunc}
        />
        {/* </Table.Footer>
            )} */}
      </div>
    );
  }
}

TableCommissionHistory.propTypes = {
  listData: PropTypes.array,
  handleChoose: PropTypes.func,
  loadingGetUserByCode: PropTypes.bool,
  getCommissions: PropTypes.func,
};
export default TableCommissionHistory;
