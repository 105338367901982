import React from 'react';
import { useState, useEffect } from 'react';
import { Grid, Segment, Form } from 'semantic-ui-react';

// ---- component ----
import HeaderTitle from '../../shared/components/common/headerSegment/HeaderTitle';
import LeftPanel from './reportSales/LeftPanel';
import RightPanel from './reportSales/RightPanel';
import InputWithLabel from '../../shared/components/common/input/InputWithLabel';
import ModalCalendar from '../../../components/modal/modalCalendar';

// ---- API ----
import { getReportSalesApi } from '../../../api/reportSales/reportSalesApi';

// ---- helper ----
import {
  rangeDate,
  convertDateFormat,
  // subtractDate,
} from '../../../scripts/moment';
import useApiCallSalesReport from '../hooks/useApiCallSalesReport';
import ModalAlert from '../../shared/components/modal/ModalAlert';

let TODAY = new Date();
// let STARTDATE = subtractDate(TODAY, 1, 'M');
let STARTDATE = new Date(TODAY.getFullYear(), TODAY.getMonth(), 1); // tgl satu di setiap bulan
let ENDDATE = TODAY;

const ReportSales = () => {
  // =================== initial ===================
  const [modalCalendarOpen, setModalCalendarOpen] = useState(false);
  const [startDate, setStartDate] = useState(STARTDATE);
  const [endDate, setEndDate] = useState(ENDDATE);
  const [openModalAlert, setOpenModalAlert] = useState(false);

  const [
    reportLoading,
    report,
    loadReport,
    errorMessage,
  ] = useApiCallSalesReport(
    getReportSalesApi,
    convertDateFormat(startDate, 'YYYY-MM-DD'),
    convertDateFormat(endDate, 'YYYY-MM-DD')
    // convertDateFormat(endDate, 'YYYY-MM-DD'),
    // convertDateFormat(startDate, 'YYYY-MM-DD')
  );

  // =================== handler ===================
  const handleModalCalendar = () => {
    setModalCalendarOpen(!modalCalendarOpen);
  };

  // eslint-disable-next-line
  const handleDateRange = (id, name) => {
    const { from, to } = id;
    setStartDate(from);
    setEndDate(to);
    // setStartDate(to);
    // setEndDate(from);
    loadReport(
      convertDateFormat(from, 'YYYY-MM-DD'),
      convertDateFormat(to, 'YYYY-MM-DD')
      // convertDateFormat(to, 'YYYY-MM-DD'),
      // convertDateFormat(from, 'YYYY-MM-DD')
    );
    handleModalCalendar();
  };

  const handleCloseAlert = () => setOpenModalAlert(false);

  // =================== lifecycle ===================
  useEffect(() => {
    if (errorMessage) {
      setOpenModalAlert(true);
    } else {
      setOpenModalAlert(false);
    }
    // eslint-disable-next-line
  }, [errorMessage])

  return (
    <div style={{ margin: '30px 0px' }}>
      <Grid columns="equal">
        <Grid.Row stretched>
          <Grid.Column width={15}>
            <Segment loading={reportLoading}>
              <HeaderTitle title="Report Sales" />
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Form>
                      <Form.Field>
                        <InputWithLabel
                          label="Periode Date"
                          type="text"
                          inline={false}
                          fluid={false}
                          value={rangeDate(startDate, endDate)}
                          name="date"
                          handleClick={handleModalCalendar}
                        />
                      </Form.Field>
                    </Form>
                  </Grid.Column>
                  <Grid.Column />
                </Grid.Row>
                <Grid.Row columns={2}>
                  {/* panel kiri */}
                  <LeftPanel report={report} />
                  {/* panel kanan */}
                  <RightPanel report={report} />
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <ModalCalendar
        actionDateRange={handleDateRange}
        openModal={modalCalendarOpen}
        handleClose={handleModalCalendar}
        contentHeader="Select Date Periode"
        startDate={startDate}
        endDate={endDate}
      />

      <ModalAlert
        openModal={openModalAlert}
        handleClose={handleCloseAlert}
        content={errorMessage}
      />
    </div>
  );
};

export default ReportSales;
