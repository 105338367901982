export const HeaderTablePlaceList = [
  'ID',
  'Place Name',
  'Code',
  'City',
  'Type',
  'Action',
];

export const HeaderTableAccessibleCity = ['City', 'Region', 'Action'];
