import React from 'react';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Style } from '../../assets/styles/styleAccomodation';

const buttonBackAndSave = props => {
  return (
    <div style={Style.buttonBackAndSave}>
      <Button
        primary
        onClick={props.backPage}
        name={props.nameBack}
        icon="angle left"
        content={props.nameBack}
      />
      <Button
        primary
        onClick={props.save}
        name={props.nameSave}
        content={props.nameSave}
      />
    </div>
  );
};
buttonBackAndSave.propTypes = {
  save: PropTypes.func,
  backPage: PropTypes.func,
  nameBack: PropTypes.string,
  nameSave: PropTypes.string,
};

export default buttonBackAndSave;
