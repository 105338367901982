import {
  SumDays,
  changeTime,
  SumSecond,
  convertToStringDate,
  getNumberOfDays,
  convertToStringTime,
} from '../../components/genericFunction/timeHelper';
import {
  getOldDailyProgram,
  checkDayEnd,
  // setDailyProgramTempIfNull,
  fixDateAndSequenceNum,
  AddDrivingNull,
  AddMovmentFlight,
  addDayEndDriving,
  addCheckInAco,
  addDayStartDriving,
  addDayEnd,
  addDayStart,
  setConnectionFlight,
  setSummaryProgram,
} from '../itineraryBuilder/dailyProgram';
import { getTime, addDate, convertDateFormat } from '../moment';
import {
  ARRIVAL,
  DEPARTURE,
  RECREATION,
  EAT,
  FREETIME,
  QUEUETIME,
  CHECKIN,
  CHECKOUT,
  DAYSTART,
  DAYEND,
} from '../../pages/shared/components/tourTransaction/dailyProgram/constant/activityType';
import { getTotalDays } from './../moment';

export class Guest {
  constructor(GuestCategory) {
    this.FirstName = null;
    this.LastName = null;
    this.CountryId = null;
    this.IdentityNbr = null;
    this.IdentityType = null;
    this.GuestType = 'TOURMEMBER';
    this.GuestCategory = GuestCategory;
    this.GuestTitle = null;
  }
}
export const DailyProgram = {
  Date: null,
  Day: null,
  Movements: [],
  TourDestinations: [],
};

export const ItemMov = {
  Address: null,
  CityId: null,
  Desc: null,
  ImageName: null,
  ImageUrl: null,
  Name: null,
  PlaceId: null,
  ProfileDesc: null,
  ServiceItemId: null,
  ServiceType: null,
  OperationStartTime: null,
  OperationEndTime: null,
  IsSolidStartTime: false,
  MapsString: null,
};
export const Movement = {
  DateTime: null,
  Destination: null,
  DestinationName: null,
  Duration: 0,
  DurationText: '',
  Id: null,
  Item: Object.assign({}, ItemMov),
  MovementName: null,
  MovementDescription: null,
  Note: null,
  SeqNumber: null,
  ServiceItemId: null,
  TypeMovement: true,
  TypeVirtual: false,
};
export const Airport = {
  Address: null,
  City: { Name: null },
  Date: null,
  Day: null,
  Note: null,
  Place: null,
  PlaceId: null,
  PlaceType: null,
  Region: null,
  TransferType: '',
  NextDay: false,
};
export const SummaryProgram = {
  AccommodationSummary: {
    CheckIn: null,
    CheckOut: null,
    Address: null,
    AccommodationProfileId: null,
    Allocations: {
      ChildExtraBedPax: 0,
      ChildSharingRoomPax: 0,
      ChildSingleRoomPax: 0,
      ExtraBedPax: 0,
      NoBedPax: 0,
      SharingBedPax: 0,
      SharingRoomPax: 0,
      SingleRoomPax: 0,
    },
    DateTime: null,
    Name: null,
    RoomName: null,
    RoomService: null,
    RoomType: null,
    SeqNumber: null,
    ServiceItemId: null,
    ImageUrl: null,
  },
  City: { Name: null },
  Date: null,
  Day: null,
  LeavingDate: null,
  Note: null,
  Region: null,
  TotalDays: 2,
  NextDay: false,
};

export const copyObject = par => {
  let temp = JSON.stringify(par);
  return JSON.parse(temp);
};

export const initialSetFixedPackage = (hlProgram, roomAllocation) => {
  let headlineProgram = { ...hlProgram };
  headlineProgram.Departures = [
    setInitialAirport([], null, 1, 'Arrival', new Date(), 'Fixed'),
  ];
  headlineProgram.MainPrograms = [setSummaryProgram(null, roomAllocation)];
  headlineProgram.Returns = [
    setInitialAirport(
      [],
      null,
      2,
      'Departure',
      headlineProgram.MainPrograms[0].LeavingDate,
      'Custom'
    ),
  ];

  let dP = dailyProgramNull(
    headlineProgram.Departures[0],
    headlineProgram.MainPrograms,
    headlineProgram.Returns[0]
  );
  let result = { headlineProgram: headlineProgram, dailyProgram: dP };
  return result;
};

//untuk custom and ready
//item : untuk custom masukan array airport, untuk custom masukan object airport arrival
//city : masukan object kota yg berisi region (bisa filter dari balikan API)
//day : masukan int hari ke-
//lastDateSP : masukan Leaving Date object terakhir Summarry program  untuk departure pada ready program
export const setInitialAirport = (item, city, day, type, date, status) => {
  item = copyObject(item);
  let typeMov = type === 'Arrival' ? 'Movement_arrival' : 'Movement_departure';
  let air = copyObject(Airport);
  air.City = '';
  air.Region = '';
  air.Day = '';
  air.PlaceType = 'Airport';
  air.TransferType = typeMov;
  air.NextDay = false;
  air.Address = '';
  air.Place = '';
  air.PlaceId = '';
  if (status === 'Custom') {
    air.Date =
      type === 'Arrival'
        ? convertToStringDate(SumDays(date, 1)) + 'T10:00:00'
        : convertToStringDate(changeTime(date)) + 'T14:00:00';
  } else if (status === 'Ready' || status === 'Similar') {
    air.Date =
      type === 'Arrival'
        ? convertToStringDate(SumDays(date, 1)) + convertToStringTime(item.Date)
        : convertToStringDate(changeTime(date)) +
          convertToStringTime(item.Date);
  } else if (status === 'Quotation') {
    air.Date = item.Date;
  } else {
    air.Date =
      type === 'Arrival' ? SumSecond(date, 7200) : SumSecond(date, 7200);
  }

  // if (item != null)
  //   if (item.length > 0) {
  //     air.Address = '';
  //     air.Place = '';
  //     air.PlaceId = '';
  //   } else if (item.length === undefined) {
  //     air.Address = item.Address;
  //     air.Place = item.Place;
  //     air.PlaceId = item.PlaceId;
  //   }
  return air;
};

//initial Daily Program with date and Set Destination
//Arrival :  object Arrival
//SummaryProgram: Array of object Summary program
//Departure: object Departure
export const dailyProgramNull = (Arrival, SummaryProgram, Departure) => {
  //set date
  let DP = [];
  let tgl1 = convertToStringDate(Arrival.Date) + 'T00:00:00';
  let days = getNumberOfDays(Arrival.Date, Departure.Date);
  for (let i = 0; i < days; i++) {
    let DailyPrograms = copyObject(DailyProgram);
    DP.push(DailyPrograms);
  }

  DP.map((obj, index) => {
    let tgl = SumDays(tgl1, index);
    obj.Day = index + 1;
    obj.Date = tgl;
    return obj;
  });
  //Initial Destination Arrival
  let DestinationArrival = {
    Destination: Arrival.City ? Arrival.City.Id : null,
  };
  //Initial Destination Departure
  let DestinationDeparture = {
    Destination: Departure.City ? Departure.City.Id : null,
  };
  //Set Destination
  DestinationArrival.Destination &&
    DP[0].TourDestinations.push(DestinationArrival);

  SummaryProgram.map(obj => {
    let DateFrom = convertToStringDate(obj.Date);
    let DateTo = null;
    let newDP = [];
    if (obj.AccommodationSummary !== undefined) {
      DateTo = convertToStringDate(obj.LeavingDate);
      newDP = DP.filter(
        item =>
          convertToStringDate(item.Date) >= DateFrom &&
          convertToStringDate(item.Date) <= DateTo
      );
    } else {
      newDP = DP.filter(item => convertToStringDate(item.Date) === DateFrom);
    }
    let item = {
      Destination: obj.City ? (obj.City.Name ? obj.City.Id : null) : null,
    };

    newDP.map(x => {
      if (
        x.TourDestinations.findIndex(
          td => td.Destination === item.Destination
        ) < 0
      ) {
        x.TourDestinations.push(item);
      }
      return x;
    });
    return obj;
  });

  if (
    DP[DP.length - 1].TourDestinations.findIndex(
      item => item.Destination === DestinationDeparture.Destination
    ) < 0
  ) {
    DP[DP.length - 1].TourDestinations.push(DestinationDeparture);
  }
  return DP;
};

//initial Daily Program with date and Set Destination
//Arrival :  object Arrival
//DailyProgram: Array of object Daily program
//Departure: object Departure
//movementList : array of object type movement
//type :  stirng with value "ARRIVAL" or "DEPARTURE"
export const setMovementAirportDailyProgram = (
  DailyProgram,
  ArrivalDeparture,
  MovementList,
  type
) => {
  let DP = copyObject(DailyProgram);
  type === 'ARRIVAL'
    ? DP[0].Movements.push(
        setAirportMovement(ArrivalDeparture, MovementList, type)
      )
    : DP[DP.length - 1].Movements.push(
        setAirportMovement(ArrivalDeparture, MovementList, type)
      );
  return DP;
};

//Untuk setup airport movement di daily program
//item : object arrival / departure
//movementList : array of object type movement
//type :  stirng with value "ARRIVAL" or "DEPARTURE"
export const setAirportMovement = (item, movementList, type) => {
  let mov = copyObject(Movement);
  let typeMov = movementList.filter(mov => mov.Name === type);
  mov.DateTime = changeTime(item.Date);
  // mov.Destination = item.City.Id;
  // mov.DestinationName = item.City.Name;
  mov.Destination = '';
  mov.DestinationName = '';
  mov.Item = addItemArrivalDeparture(item, type);
  mov.MovementName = typeMov[0].Name;
  mov.MovementDescription = typeMov[0].Description;
  mov.Note = item.Note;
  mov.SeqNumber = 1;
  mov.ServiceItemId = typeMov[0].ServiceItemId;
  return mov;
};

//Untuk setup item movement airport di daily program
//item : object arrival / departure
//type :  stirng with value "ARRIVAL" or "DEPARTURE"
export const addItemArrivalDeparture = (item, type) => {
  let it = copyObject(ItemMov);
  it.Address = item.Address;
  it.CityId = item.City ? item.City.Id : null;
  it.Desc = item.PlaceType;
  it.Name = item.Place;
  it.PlaceId = item.PlaceId;
  if (type === 'ARRIVAL') it.ServiceType = 'Movement_arrival';
  else it.ServiceType = 'Movement_departure';
  return it;
};

//untuk set movement accomdasi
//SummaryProgram: Array of object Summary program
//DailyProgram: Array of object Daily program
//movementList : array of object type movement
export const setCheckoutinAccomodasi = (
  SummaryProgram,
  DailyProgram,
  MovementList
) => {
  let SP = copyObject(SummaryProgram);
  let DP = copyObject(DailyProgram);
  let i = 0;
  SP = SP.filter(item => item.AccommodationSummary !== undefined);
  let no = SP.length;
  for (let j = 0; j < DP.length; j++) {
    //checkout
    if (
      convertToStringDate(SP[i].LeavingDate) === convertToStringDate(DP[j].Date)
    ) {
      if (DP[j].Movements.length === 0) {
        DP[j].Movements.push(
          setAccomodationtMovement(SP[i], MovementList, 'CHECKOUT')
        );
      } else {
        // eslint-disable-next-line
        if (j != 0)
          DP[j].Movements.splice(
            0,
            0,
            setAccomodationtMovement(SP[i], MovementList, 'CHECKOUT')
          );
        else
          DP[j].Movements.push(
            setAccomodationtMovement(SP[i], MovementList, 'CHECKOUT')
          );
      }
      if (convertToStringDate(SP[i].Date) === convertToStringDate(DP[j].Date)) {
        // eslint-disable-next-line
        if (j != 0)
          DP[j].Movements.splice(
            0,
            0,
            setAccomodationtMovement(SP[i], MovementList, 'CHECKIN')
          );
        else
          DP[j].Movements.splice(
            DP[j].Movements.length - 1,
            0,
            setAccomodationtMovement(SP[i], MovementList, 'CHECKIN')
          );
      }
      i++;
    }
    if (i === no) break;
    //checkin
    if (convertToStringDate(SP[i].Date) === convertToStringDate(DP[j].Date)) {
      DP[j].Movements.push(
        setAccomodationtMovement(SP[i], MovementList, 'CHECKIN')
      );
      if (
        convertToStringDate(SP[i].LeavingDate) ===
        convertToStringDate(DP[j].Date)
      ) {
        DP[j].Movements.push(
          setAccomodationtMovement(SP[i], MovementList, 'CHECKOUT')
        );
        i++;
      }
    }
    if (i === no) break;
  }
  return DP;
};

//untuk set accomodation movement
//item : Object Summary program accomodation
//movementList : array of object type movement
//type : string "CHECKIN" or "CHECKOUT"
export const setAccomodationtMovement = (item, MovementList, type) => {
  let aco = copyObject(Movement);
  let typeMov = MovementList.filter(item => item.Name === type);
  if (type === 'CHECKIN') {
    aco.DateTime = item.AccommodationSummary.CheckIn;
    aco.TypeMovement = false;
  } else {
    aco.DateTime = item.AccommodationSummary.CheckOut;
  }
  aco.Destination = item.City ? item.City.Id : null;
  aco.DestinationName = item.City ? item.City.Name : null;
  aco.Item = addItemHotel(aco.Destination, item.AccommodationSummary);
  aco.MovementName = typeMov[0].Name;
  aco.MovementDescription = typeMov[0].Description;
  aco.ServiceItemId = typeMov[0].ServiceItemId;
  return aco;
};

//untuk set item accomodation movement
//Destination : id of City
//AccommodationSummary
export const addItemHotel = (Destination, AccommodationSummary) => {
  let it = copyObject(ItemMov);
  if (AccommodationSummary !== null) {
    it.Address = AccommodationSummary.Address;
    it.CityId = Destination;
    it.ProfileDesc = AccommodationSummary.RoomName;
    it.ImageName = AccommodationSummary.ImageName;
    it.ImageUrl = AccommodationSummary.ImageUrl;
    it.Name = AccommodationSummary.Name;
    it.ServiceItemId = AccommodationSummary.ServiceItemId;
    it.ServiceType = AccommodationSummary.RoomService;
  }
  return it;
};

//menambahkan driving null di antara activity
export const addedDriving = (newDailyProgram, MovementList) => {
  newDailyProgram.map((obj, i) => {
    for (let j = 0; j < obj.Movements.length; j++) {
      let mov = obj.Movements[j].MovementName;
      let item = obj.Movements[j];
      let date = SumSecond(
        obj.Movements[j].DateTime,
        obj.Movements[j].Duration
      );
      if (mov === 'ARRIVAL' || mov === 'CHECKOUT') {
        if (mov === 'CHECKOUT') {
          if (j !== 0)
            if (obj.Movements[j - 1].MovementName !== 'CHECKIN') {
              if (obj.Movements[j - 1].MovementName !== 'DAYSTART')
                if (obj.Movements[j].MovementName !== 'DRIVING') {
                  obj.Movements.splice(
                    j,
                    0,
                    AddDrivingNull(MovementList, item, date)
                  );
                  j++;
                }
            }
        }
        // if (obj.Movements[j + 1].MovementName !== 'FREETIMELOCKED')
        //   if (obj.Movements[j + 3] !== undefined) {
        //     if (obj.Movements[j + 3].MovementName !== 'DRIVING') {
        //       obj.Movements.splice(
        //         j + 3,
        //         0,
        //         AddDrivingNull(MovementList, item, date)
        //       );
        //       j++;
        //     }
        //   } else {
        //     obj.Movements.push(AddDrivingNull(MovementList, item, date));
        //     j++;
        //   }
      }

      if (mov === 'ARRIVAL') {
        if (obj.Movements[j + 1] === undefined) {
          obj.Movements.splice(
            j + 1,
            0,
            AddDrivingNull(MovementList, item, date)
          );
          j++;
        } else if (obj.Movements[j + 1].MovementName !== 'DRIVING') {
          obj.Movements.splice(
            j + 1,
            0,
            AddDrivingNull(MovementList, item, date)
          );
          j++;
        }
      }

      if (i !== newDailyProgram.length - 1 && mov === 'DEPARTURE') {
        if (obj.Movements[j + 1] !== undefined) {
          if (obj.Movements[j + 1].MovementName !== 'FLIGHTTIME') {
            obj.Movements.splice(
              j + 1,
              0,
              AddMovmentFlight(MovementList, item, date)
            );
            j++;
          }
        } else {
          obj.Movements.push(AddMovmentFlight(MovementList, item, date));
        }
      }
    }
    return obj;
  });

  return newDailyProgram;
};

export const addStartEndDays2 = (
  SummaryProgram,
  DailyProgramTemp,
  Departure,
  Arrival,
  MovementList
) => {
  let dpTemp = copyObject(DailyProgramTemp);
  let idx = 0;
  let SP = SummaryProgram.filter(
    item => item.AccommodationSummary !== undefined
  );
  dpTemp.map((daily, i) => {
    let chIn = false;
    let chOut = false;
    daily.Movements.map(move => {
      if (move.MovementName === 'CHECKIN') chIn = true;
      else if (move.MovementName === 'CHECKOUT') chOut = true;
      return move;
    });
    let dp = daily.Movements;
    let dpLength = dp.length - 1;
    if (i === 0) {
      if (daily.Movements.length === 0) {
        daily.Movements = [];
      } else if (!chIn && !chOut && dp[dpLength].MovementName !== 'DAYEND')
        daily.Movements.push(
          addDayEndDriving(
            addCheckInAco(MovementList, SP[idx]),
            convertToStringDate(Arrival.Date) + 'T23:59:59',
            MovementList
          )
        );
      else if (chIn && !chOut && dp[dpLength].MovementName !== 'DAYEND')
        daily.Movements.push(
          addDayEnd(
            addCheckInAco(MovementList, SP[idx]),
            SP[idx].AccommodationSummary.CheckIn,
            MovementList
          )
        );
      else if (chIn && chOut && dp[dpLength].MovementName !== 'DAYEND') {
        idx++;
        daily.Movements.push(
          addDayEndDriving(
            addCheckInAco(MovementList, SP[idx]),
            convertToStringDate(SP[idx - 1].Date) + 'T23:59:59',
            MovementList
          )
        );
      }
    } else if (i === dpTemp.length - 1) {
      if (daily.Movements.length === 0) {
        daily.Movements = [];
      } else if (
        daily.Movements[0].MovementName !== 'CHECKIN' &&
        dp[0].MovementName !== 'DAYSTART'
      )
        if (daily.Movements[0].MovementName !== 'DEPARTURE')
          daily.Movements.splice(
            0,
            0,
            addDayStart(
              addCheckInAco(MovementList, SP[idx]),
              SP[idx].AccommodationSummary.CheckOut,
              MovementList
            )
          );
    } else {
      let chIn = false;
      let chOut = false;
      daily.Movements.map(move => {
        if (move.MovementName === 'CHECKIN') chIn = true;
        else if (move.MovementName === 'CHECKOUT') chOut = true;
        return move;
      });
      if (chIn && chOut && dp[0].MovementName !== 'DAYSTART') {
        daily.Movements.splice(
          0,
          0,
          addDayStart(
            addCheckInAco(MovementList, SP[idx]),
            daily.Movements[0].DateTime,
            MovementList
          )
        );
        idx++;
        if (SP[idx] !== undefined)
          daily.Movements.push(
            addDayEnd(
              addCheckInAco(MovementList, SP[idx]),
              daily.Movements[daily.Movements.length - 1].DateTime,
              MovementList
            )
          );
        else
          daily.Movements.push(
            addDayEndDriving(
              setAirportMovement(Departure, MovementList, 'DEPARTURE'),
              convertToStringDate(SP[idx - 1].LeavingDate) + 'T23:59:59',
              MovementList
            )
          );
      } else if (
        chIn &&
        chOut &&
        dp[0].MovementName === 'DAYSTART' &&
        dp[dpLength].MovementName === 'DAYEND'
      ) {
        idx++;
      } else if (chIn && !chOut && dp[dpLength].MovementName !== 'DAYEND') {
        daily.Movements.push(
          addDayEnd(
            addCheckInAco(MovementList, SP[idx]),
            convertToStringDate(SP[idx].AccommodationSummary.CheckOut) +
              'T20:00:00',
            MovementList
          )
        );
      } else if (!chIn && chOut && dp[0].MovementName !== 'DAYSTART') {
        daily.Movements.splice(
          0,
          0,
          addDayStart(
            addCheckInAco(MovementList, SP[idx]),
            daily.Movements[0].DateTime,
            MovementList
          )
        );
        idx++;
        if (SP[idx] !== undefined)
          daily.Movements.push(
            addDayEndDriving(
              addCheckInAco(MovementList, SP[idx]),
              convertToStringDate(SP[idx - 1].LeavingDate) + 'T23:59:59',
              MovementList
            )
          );
        //line add day end tiba tiba
        //  else
        //     daily.Movements.push(
        //       addDayEndDriving(
        //         setAirportMovement(Departure, MovementList, 'DEPARTURE'),
        //         convertToStringDate(SP[idx - 1].LeavingDate) + 'T23:59:59',
        //         MovementList
        //       )
        //     );
      } else {
        if (daily.Movements.length === 0 && SP[idx] !== undefined) {
          let a = getTime(SP[idx].Date);
          let b = getTime(SP[idx].LeavingDate);
          if (getTime(daily.Date) >= a && getTime(daily.Date) <= b) {
            daily.Movements.splice(
              0,
              0,
              addDayStartDriving(
                addCheckInAco(MovementList, SP[idx]),
                convertToStringDate(SP[idx].Date) + 'T08:00:00',
                MovementList
              )
            );
          } else daily.Movements = [];
          if (getTime(daily.Date) > a && getTime(daily.Date) < b) {
            daily.Movements.push(
              addDayEndDriving(
                addCheckInAco(MovementList, SP[idx]),
                convertToStringDate(SP[idx].Date) + 'T20:00:00',
                MovementList
              )
            );
          }
        } else if (SP[idx] !== undefined) {
          if (dp[0].MovementName !== 'DAYSTART')
            if (dp[0].MovementName !== 'CHECKIN')
              daily.Movements.splice(
                0,
                0,
                addDayStartDriving(
                  addCheckInAco(MovementList, SP[idx]),
                  daily.Movements[0].DateTime,
                  MovementList
                )
              );
          if (dp[dpLength].MovementName !== 'DAYEND')
            daily.Movements.push(
              addDayEndDriving(
                addCheckInAco(MovementList, SP[idx]),
                daily.Movements[daily.Movements.length - 1].DateTime,
                MovementList
              )
            );
        }
      }
    }
    return daily;
  });
  return dpTemp;
};

// ===================================== GENERATE DAILY PROGRAM ==================================
export const generateDailyProgramFixed = (
  Arrival,
  Departure,
  SummaryProgram,
  oldDailyProgram,
  MovementList
) => {
  let DailyProgramTemp = [];
  DailyProgramTemp = dailyProgramNull(Arrival, SummaryProgram, Departure); //create tempalate with destination
  // DailyProgramTemp = setMovementAirportDailyProgram(
  //   DailyProgramTemp,
  //   Arrival,
  //   MovementList,
  //   'ARRIVAL'
  // );
  //setarival
  DailyProgramTemp = setConnectionFlight(
    SummaryProgram,
    DailyProgramTemp,
    MovementList
  );
  DailyProgramTemp = setCheckoutinAccomodasi(
    SummaryProgram,
    DailyProgramTemp,
    MovementList
  );
  // ============== GENERATE OLD DAILY PROGRAM YANG BARU JANGAN DI HAPUS ================
  // if (
  //   createArrayMovements(oldDailyProgram).some(
  //     e => listActivity.indexOf(e.MovementName) !== -1
  //   )
  // ) {
  //   DailyProgramTemp = getOldDaily(
  //     DailyProgramTemp,
  //     oldDailyProgram,
  //     SummaryProgram
  //   );
  // }
  // ============== GENERATE OLD DAILY PROGRAM YANG BARU JANGAN DI HAPUS ================
  DailyProgramTemp = getOldDailyProgram(DailyProgramTemp, oldDailyProgram); //get old program to new program
  // DailyProgramTemp = addVirtualAccomodasi(DailyProgramTemp, MovementList);
  DailyProgramTemp = addedDriving(DailyProgramTemp, MovementList);
  DailyProgramTemp = addStartEndDays2(
    SummaryProgram,
    DailyProgramTemp,
    Departure,
    Arrival,
    MovementList
  );
  DailyProgramTemp = checkDayEnd(DailyProgramTemp);
  // DailyProgramTemp = generateFreeTimeNew(DailyProgramTemp, MovementList);
  // DailyProgramTemp = setDailyProgramTempIfNull(DailyProgramTemp);
  DailyProgramTemp = fixDateAndSequenceNum(DailyProgramTemp);
  // DailyProgramTemp = sequentialNumberinMovement(DailyProgramTemp);
  // getDurationFromApi1();
  return DailyProgramTemp;
};

export const listActivity = [
  ARRIVAL,
  DEPARTURE,
  RECREATION,
  EAT,
  FREETIME,
  QUEUETIME,
];

export const getOldDaily = (newDaily, oldDaily, summaryPrograms) => {
  // eslint-disable-next-line
  let newDailyTemp = copyObject(newDaily);
  let oldAccommodations = createAccommodationArray(oldDaily);
  // eslint-disable-next-line
  summaryPrograms.map((summaryProg, iSummProgram) => {
    let totaldays = getTotalDays(
      oldAccommodations[iSummProgram].checkinDate,
      oldAccommodations[iSummProgram].checkoutDate,
      true
    );
    if (
      oldAccommodations[iSummProgram].serviceItemId ===
        summaryProg.AccommodationSummary.ServiceItemId &&
      totaldays < summaryProg.TotalDays
    ) {
      let selectedOldDays = selectedDaysCheckin(
        oldDaily,
        oldAccommodations,
        iSummProgram
      );
      let selectedNewDays = selectedDaysCheckin(
        newDailyTemp,
        oldAccommodations,
        iSummProgram
      );
      let selectedOldDaily = oldDaily.slice(selectedOldDays, totaldays);
      // eslint-disable-next-line
      selectedOldDaily.map((daily, iOldDaily) => {
        let oldMovements = getOldMovements(
          daily,
          oldAccommodations,
          iSummProgram
        );
        // sisipkan movementnya
        let idxCheckInDayStart = newDailyTemp[
          selectedNewDays + iOldDaily
        ].Movements.reduce(
          (acc, e, iCheckInDayStart) =>
            [CHECKIN, DAYSTART].indexOf(e.MovementName) !== -1 &&
            e.Item.ServiceItemId ===
              oldAccommodations[iSummProgram].serviceItemId
              ? iCheckInDayStart
              : acc,
          -1
        );
        let day =
          iOldDaily + 1 === selectedOldDaily.length
            ? summaryProg.TotalDays - 1
            : selectedNewDays + iOldDaily;
        // eslint-disable-next-line
        oldMovements.map((e, i) => {
          newDailyTemp[day].Movements.splice(idxCheckInDayStart + i + 1, 0, e);
        });
      });
    }
  });
  return newDailyTemp;
};
// untuk mendapatkan movement dari daily program yang laama. dimana ia sudah
// memotong jika ada checkin dan checkout
const getOldMovements = (daily, oldAccommodations, iSummProgram) => {
  let oldMovements = daily.Movements.reduce(
    (acc, movement, iMovement) => {
      if (
        [CHECKIN, DAYSTART].indexOf(movement.MovementName) !== -1 &&
        movement.Item.ServiceItemId ===
          oldAccommodations[iSummProgram].serviceItemId
      ) {
        acc.movements = daily.Movements.slice(
          iMovement + 1,
          daily.Movements.length
        );
        acc.idxStart = iMovement + 1;
      } else if (
        [CHECKOUT, DAYEND].indexOf(movement.MovementName) !== -1 &&
        movement.Item.ServiceItemId ===
          oldAccommodations[iSummProgram].serviceItemId
      ) {
        acc.movements = acc.movements.slice(0, iMovement - acc.idxStart);
      }
      return acc;
    },
    { movements: [], idxStart: 0 }
  );
  return oldMovements.movements;
};
// mengambil hari yang ada checkin
const selectedDaysCheckin = (
  oldDailyProgram,
  oldAccommodations,
  iSummProgram
) =>
  oldDailyProgram.findIndex(
    e =>
      getTotalDays(e.Date, oldAccommodations[iSummProgram].checkinDate, 0) === 1
  );

export const createAccommodationArray = dailyProg => {
  let movements = createArrayMovements(dailyProg);
  let newMovements = movements.reduce((res, item, i) => {
    if (item.MovementName === 'CHECKIN') {
      let newArray = movements.slice(i);
      let checkoutObj = getFirstCheckout(newArray, item.Item.ServiceItemId);
      let accom = {
        checkinDate: item.DateTime,
        serviceItemId: item.Item.ServiceItemId,
        checkoutDate: checkoutObj.DateTime,
        checkinDayIndex: item.DayIndex,
        checkOutDayIndex: checkoutObj.DayIndex,
      };
      res.push(accom);
    }
    return res;
  }, []);
  return newMovements;
};

// const getMoveIndex = (movement, name) => {
//   return movement.findIndex(item => item.MovementName === name);
// };

// fungsi untuk mendapatkan checkout object
const getFirstCheckout = (movements, id) => {
  let obj = movements.find(
    item => item.MovementName === 'CHECKOUT' && item.Item.ServiceItemId === id
  );
  return obj;
};

// fungsi digunakan untuk mendapatkan semua kumpulan movements
export const createArrayMovements = dailyProg => {
  let newDaily = dailyProg.reduce((res, item, i) => {
    item.Movements.map(move => {
      move.DayIndex = i;
      return move;
    });
    res = res.concat(item.Movements);
    return res;
  }, []);
  return newDaily;
};
// ===================================== END GENERATE DAILY PROGRAM ==================================

//untuk memperbaiki date movement dengan sequence number
export const fixDateAfterChangeStartDate = (DailyProgramTemp, date) => {
  let dpTemp = copyObject(DailyProgramTemp);
  dpTemp.map((daily, i) => {
    let newDate = convertDateFormat(
      addDate(date, i, 'd'),
      'YYYY-MM-DDTHH:mm:ss'
    );
    daily.Date = convertToStringDate(newDate) + 'T00:00:00';
    daily.Movements.map((move, j) => {
      move.DateTime =
        convertToStringDate(newDate) + convertToStringTime(move.DateTime);
      move.SeqNumber = j + 1;
      return move;
    });
    return daily;
  });
  return dpTemp;
};

export const fixReturnHeadlineProgram = (headline, endDate) => {
  let copyHeadline = copyObject(headline);
  if (copyHeadline.Returns[0].Date > endDate) {
    copyHeadline.Returns[0].Date = endDate + 'T00:00:00';
  }
  return copyHeadline;
};
