import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Checkbox, Form, Grid } from 'semantic-ui-react';
import Loader from '../../../../components/modal/loaderModal';
import InputWithLabel from '../../../../components/input/inputWithLabel';
import ButtonClose from '../../../../components/button/buttonCloseModal';
import styles from '../../Styles';

const ModalCountry = props => {
  return (
    <Modal
      dimmer="blurring"
      open={props.open}
      onClose={props.handleClose}
      size="small"
      style={styles.modalCountry}
    >
      <Modal.Header>
        {props.header} <ButtonClose onClose={props.handleClose} />
      </Modal.Header>
      <Modal.Content>
        <Loader loading={props.loading} />
        <Grid>
          <Grid.Column>
            <Form>
              <InputWithLabel
                inline={false}
                placeholder="CountryId"
                title="CountryId"
                name="CountryId"
                label="Country ID"
                fluid={true}
                handleChange={props.handleChange}
                value={props.contentField.CountryId}
                validate={props.errorInformation}
              />
            </Form>
            <Form>
              <InputWithLabel
                inline={false}
                placeholder="Name"
                title="Name"
                name="Name"
                label="Country Name"
                handleChange={props.handleChange}
                value={props.contentField.Name}
                validate={props.errorInformation.NameError}
              />
            </Form>
            <Form>
              <Checkbox
                label={{ children: 'Tour Relevant' }}
                onChange={props.handleCheckClick}
                onClick={props.handleCheckClick}
                checked={props.contentField.IsTourRelevant}
                style={styles.checkBoxMargin}
              />
            </Form>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={props.saveCountry} primary content="Update" />
      </Modal.Actions>
    </Modal>
  );
};

ModalCountry.propTypes = {
  disabled: PropTypes.bool,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  listCountry: PropTypes.array,
  listRegion: PropTypes.array,
  handleChange: PropTypes.func,
  contentField: PropTypes.shape({
    Name: PropTypes.string,
    CountryId: PropTypes.string,
    IsTourRelevant: PropTypes.bool,
  }),
  saveCountry: PropTypes.func,
  handleCheckClick: PropTypes.func,
  errorInformation: PropTypes.shape({
    CountryIdError: PropTypes.string,
    NameError: PropTypes.string,
  }),
  loading: PropTypes.bool,
};
export default ModalCountry;
