import * as types from './constant/actionTypes';

const initialState = {
  getCountry: [],
  getCountryStatus: '',
  deleteCountryStatus: '',
  postCountryStatus: '',
  putCountryStatus: '',
  getCountryById: [],
  getCountryByIdStatus: '',
  errors: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_COUNTRY_PAGE:
      return {
        ...state,
        getCountryListStatus: '',
        getCountryStatus: '',
        deleteCountryStatus: '',
        getCountryByIdStatus: '',
        postCountryStatus: '',
        putCountryStatus: '',
      };
    case types.GET_COUNTRY_LIST:
      return { ...state };
    case types.GET_COUNTRY_LIST_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getCountryStatus: 'failed',
      };
    case types.GET_COUNTRY_LIST_PENDING:
      return { ...state, loading: true };
    case types.GET_COUNTRY_LIST_FULFILLED:
      return {
        ...state,
        getCountry: action.payload.data,
        loading: false,
        getCountryStatus: 'success',
      };
    case types.DELETE_COUNTRY:
      return { ...state };
    case types.DELETE_COUNTRY_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        deleteCountryStatus: 'failed',
      };
    case types.DELETE_COUNTRY_PENDING:
      return { ...state, loading: true };
    case types.DELETE_COUNTRY_FULFILLED:
      return {
        ...state,
        loading: false,
        deleteCountryStatus: 'success',
      };
    case types.GET_COUNTRY_BY_ID:
      return { ...state };
    case types.GET_COUNTRY_BY_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getCountryByIdStatus: 'failed',
      };
    case types.GET_COUNTRY_BY_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_COUNTRY_BY_ID_FULFILLED:
      return {
        ...state,
        getCountryById: action.payload.data,
        loading: false,
        getCountryByIdStatus: 'success',
      };
    case types.POST_COUNTRY:
      return { ...state };
    case types.POST_COUNTRY_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        postCountryStatus: 'failed',
      };
    case types.POST_COUNTRY_PENDING:
      return { ...state, loading: true };
    case types.POST_COUNTRY_FULFILLED:
      return {
        ...state,
        loading: false,
        postCountryStatus: 'success',
      };
    case types.PUT_COUNTRY:
      return { ...state };
    case types.PUT_COUNTRY_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        putCountryStatus: 'failed',
      };
    case types.PUT_COUNTRY_PENDING:
      return { ...state, loading: true };
    case types.PUT_COUNTRY_FULFILLED:
      return {
        ...state,
        loading: false,
        putCountryStatus: 'success',
      };

    default:
      return state;
  }
};

export default reducer;
