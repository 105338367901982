import React from 'react';
import { Step } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const step = props => {
  return (
    <Step.Group ordered fluid>
      {props.listStep.map((step, i) => {
        if (step.Active) {
          return (
            <Step key={i} active>
              <Step.Content>
                <Step.Title>{step.Tittle}</Step.Title>
              </Step.Content>
            </Step>
          );
        } else {
          return (
            <Step key={i}>
              <Step.Content>
                <Step.Title>{step.Tittle}</Step.Title>
              </Step.Content>
            </Step>
          );
        }
      })}
    </Step.Group>
  );
};
step.propTypes = {
  listStep: PropTypes.array,
};
export default step;
