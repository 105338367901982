import React from 'react';
import PropTypes from 'prop-types';
import { Header, Button, Table, Input, Popup } from 'semantic-ui-react';
import CurrencyFormat from 'react-currency-format';

const ModalCalculate = props => {
  return (
    <Table style={{ border: 'none' }}>
      <Table.Row style={{ border: 'none' }}>
        <Table.Cell width={2} />
        <Table.Cell width={5}>
          <Header as="h5">Balance Payment</Header>
        </Table.Cell>
        <Table.Cell width={1}>{props.contentDetail.CurrencyId}</Table.Cell>
        <Table.Cell width={5} textAlign="right">
          {new Intl.NumberFormat('de-DE').format(props.BalancePayment)}
        </Table.Cell>
        <Table.Cell width={3} />
      </Table.Row>

      <Table.Row>
        <Table.Cell width={2} style={{ border: 'none' }} />
        <Table.Cell style={{ border: 'none' }} width={5}>
          <Header as="h5">Total Payable</Header>
        </Table.Cell>
        <Table.Cell style={{ border: 'none' }} width={1}>
          {props.contentDetail.CurrencyId}
        </Table.Cell>
        <Table.Cell style={{ border: 'none' }} width={5}>
          <CurrencyFormat
            // thousandSeparator={true}
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={2}
            value={props.totalPayableValue ? props.totalPayableValue : 0}
            customInput={Input}
            onValueChange={e => props.handleChangePayable(e.value)}
            style={{ textAlign: 'right' }}
          />
        </Table.Cell>
        <Table.Cell width={3} />
      </Table.Row>

      <Table.Row>
        <Table.Cell width={2} style={{ border: 'none' }} />
        <Table.Cell style={{ border: 'none' }} width={5}>
          <Header as="h5">Customer Current Balance</Header>
        </Table.Cell>
        <Table.Cell style={{ border: 'none' }} width={1}>
          {props.contentDetail.CurrencyId}
        </Table.Cell>
        <Table.Cell textAlign="right" style={{ border: 'none' }} width={3}>
          {new Intl.NumberFormat('de-DE').format(props.customerBalance)}
        </Table.Cell>
        <Table.Cell textAlign="right" style={{ border: 'none' }} width={5}>
          <Popup
            content="Add balance to customer touress wallet"
            trigger={
              // <Button
              //   circular
              //   icon="add"
              //   primary
              //   onClick={props.openModalBalanceDetail}
              // />
              <Button
                primary
                content="Top Up"
                size="small"
                onClick={props.openModalBalanceDetail}
              />
            }
          />
        </Table.Cell>
      </Table.Row>
    </Table>
  );
};

ModalCalculate.propTypes = {
  contentDetail: PropTypes.func,
  totalPayable: PropTypes.number,
  customerBalance: PropTypes.number,
  openModalBalanceDetail: PropTypes.func,
  customValue: PropTypes.number,
  BalancePayment: PropTypes.number,
  totalPayableValue: PropTypes.number,
  handleChangePayable: PropTypes.func,
};
export default ModalCalculate;
