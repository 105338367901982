import React from 'react';
import { Table, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import LoaderModal from '../../../../components/modal/loaderModal';
import { rangeDate } from '../../../../scripts/moment';
import { formatCurrency } from '../../../../components/genericFunction/convertFormat';
import { Link } from 'react-router-dom';

const TableCompanyCommission = props => (
  <Table basic="very" selectable fixed>
    <LoaderModal loading={props.loading} />
    <Table.Header>
      <Table.Row>
        {props.headerTable.map((headTableContent, index) => {
          return (
            <Table.HeaderCell key={index}>{headTableContent}</Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {props.listTransaction ? (
        props.listTransaction.map((commision, idx) => {
          const totalPax =
            commision.AdultPax + commision.ChildPax + commision.InfantPax;
          return (
            <Table.Row key={idx}>
              <Table.Cell component="th" scope="row" width={1}>
                <Link
                  to={
                    props.isFinance
                      ? `/Simple-Transaction-Detail/${commision.BookingId}`
                      : `/tour-transaction/edit/${
                          commision.PackageType === 0 ? 'Custom' : 'Fixed'
                        }/${commision.BookingId}`
                  }
                >
                  {commision.BookingId}
                </Link>
              </Table.Cell>
              <Table.Cell>{commision.BookingName}</Table.Cell>
              <Table.Cell>{commision.CompanyName}</Table.Cell>
              <Table.Cell>
                {rangeDate(commision.StartDate, commision.EndDate)}
              </Table.Cell>
              <Table.Cell>
                {commision.TransactionStatus.replace('_', ' ')}
              </Table.Cell>
              <Table.Cell>
                {commision.Currency + ' ' + formatCurrency(commision.TotalPaid)}
              </Table.Cell>
              <Table.Cell>
                {' '}
                {commision.Currency +
                  ' ' +
                  formatCurrency(totalPax * commision.OutstandingStaffCashback)}
              </Table.Cell>
              <Table.Cell>
                <Button
                  primary
                  content={
                    commision.IsStaffCashbackPaid ? 'Already Paid' : 'Pay'
                  }
                  disabled={commision.IsStaffCashbackPaid}
                  onClick={props.handleModalPay}
                  value={commision}
                />
              </Table.Cell>
              {/* <Table.Cell>{commision.IsStaffCashbackPaid}</Table.Cell> */}
            </Table.Row>
          );
        })
      ) : (
        <Table.Row>
          <Table.Cell colSpan="8" textAlign="center">
            No Data
          </Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  </Table>
);

TableCompanyCommission.propTypes = {
  listTransaction: PropTypes.array,
  headerTable: PropTypes.array,
  loading: PropTypes.bool,
  handleModalPay: PropTypes.func,
  isFinance: PropTypes.bool,
};
export default TableCompanyCommission;
