import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider, Popup, Icon } from 'semantic-ui-react';
import InputAmount from '../../../../../components/input/inputAmount';
import { numberRoundUp2Decimal } from '../../../../../scripts/convertNumber';
import styles from './Styles';

const AdditionalServiceForm = props => {
  const {
    data,
    handleDelete,
    handleIncrease,
    handleDecrease,
    currentPackage,
    handleChangeAmount,
    isAcceptedQuotation,
  } = props;
  let isReadOnly =
    currentPackage &&
    currentPackage.status === 'edit' &&
    (currentPackage.type !== 'Quotation' ||
      (currentPackage.type === 'Quotation' && isAcceptedQuotation));
  return (
    <>
      <Grid style={styles.gridForm}>
        {data &&
          data.map((item, idx) => {
            return (
              <>
                <Grid.Row columns={4} key={idx}>
                  <Grid.Column width={7} verticalAlign="middle">
                    {item.AdditionalServiceName}
                    {item.IsInfantCount && (
                      <span style={styles.colorRed}>{` **`}</span>
                    )}
                  </Grid.Column>
                  <Grid.Column
                    width={3}
                    textAlign="center"
                    verticalAlign="middle"
                  >
                    {!isReadOnly ? (
                      <InputAmount
                        name={item.AdditionalServiceId}
                        value={item.Quantity}
                        handleIncrease={handleIncrease}
                        handleDecrease={handleDecrease}
                        disabled={item.IsMandatory}
                        handleOnChange={handleChangeAmount}
                      />
                    ) : (
                      `${item.Quantity}`
                    )}
                  </Grid.Column>
                  <Grid.Column width={5} verticalAlign="middle">
                    {`${item.Currency} ${numberRoundUp2Decimal(
                      item.Amount * item.Quantity
                    )}`}
                  </Grid.Column>
                  <Grid.Column width={1} verticalAlign="middle">
                    {!isReadOnly && !item.IsMandatory ? (
                      <Popup
                        trigger={
                          <Icon
                            link
                            name="delete"
                            color="red"
                            size="large"
                            onClick={handleDelete}
                            id={item.AdditionalServiceId}
                          />
                        }
                        content="Delete additional service"
                      />
                    ) : null}
                  </Grid.Column>
                </Grid.Row>
                <Divider style={styles.marginTopBottom0} />
              </>
            );
          })}
      </Grid>
      {data.find(item => item.IsInfantCount) && (
        <Grid textAlign="left" style={styles.gridForm}>
          <Grid.Row>
            <Grid.Column>
              <small style={styles.colorRed}>** Infant Is Included</small>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </>
  );
};

AdditionalServiceForm.propTypes = {
  data: PropTypes.array,
  handleDelete: PropTypes.func,
  handleChangeAmount: PropTypes.func,
  handleIncrease: PropTypes.func,
  handleDecrease: PropTypes.func,
  currentPackage: PropTypes.object,
  isAcceptedQuotation: PropTypes.bool,
};

export default React.memo(AdditionalServiceForm);
