const ucwords = text => {
  return text
    ? text
        .toLowerCase()
        .split(' ')
        .map(e => e.charAt(0).toUpperCase() + '' + e.substring(1))
        .join(' ')
    : '';
};

export { ucwords };
