import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Segment, Grid, Button, Header, Image } from 'semantic-ui-react';
import Loader from '../../components/loader/loader';
import HeaderTitle from '../../components/common/headerTitle';
import styles from './styles';
import AdditionalInfo from '../shared/components/tourTransaction/generalInformation/segment/AdditionalInformation';
import {
  getFixedPackage,
  putAdditionalInfoOperation,
} from '../../actions/packageAction';
import { convertDateFormat } from '../../scripts/itineraryBuilder/timeHelper';
import PropTypes from 'prop-types';
import { getActivityListAction } from '../../actions/packageAction';
import { TabDailyProgram } from './components/createFixedPackage';
import { Redirect } from 'react-router-dom';
import ModalConfirm from '../../components/modal/modalConfirmationTwoButton';
import ModalAlert from '../../components/modal/modalAlert';
import TourItinerary from '../shared/components/tourTransaction/dailyProgram/TourItinerary';
//import promise from 'redux-promise-middleware';
const PackageDetailForOperation = props => {
  //==========initial state=========
  const [generalInfo, setGeneralInfo] = useState({
    packageType: 'Fixed',
    id: '',
    image: '',
    tourName: '',
    firstDestination: '',
    desc: '',
    tourType: '',
    currency: '',
    startDate: '',
    endDate: '',
  });
  const [dailyProgram, setDailyProgram] = useState([]);
  const [headlineProgram, setHeadlineProgram] = useState({
    Departures: [],
    MainPrograms: [],
    Returns: [],
  });
  const [additionalInfo, setAdditionalInfo] = useState([]);
  const [
    deletedAdditionalInformation,
    setDeletedAdditionalInformation,
  ] = useState([]);
  const [changePage, setChangePage] = useState({ isChange: false, link: '' });
  const [modalConfirm, setModalConfirm] = useState({
    type: '',
    isOpen: false,
    header: '',
    message: '',
  });
  const [modalAlert, setModalAlert] = useState({
    isOpen: false,
    header: '',
    message: '',
  });
  //========== end initial state=========
  //========== reducer ==========
  const packageDetail = useSelector(state => state.PackageStore.fixedPackage);
  const loadingPackageStore = useSelector(state => state.PackageStore.loading);
  const activityList = useSelector(state => state.PackageStore.activityList);
  // console.log(packageDetail);
  //========== end reducer =======

  //===========action===========
  const dispatch = useDispatch();
  const getFixedPackageAction = (type, id) =>
    dispatch(getFixedPackage(type, id));
  const getMovementListAction = () => dispatch(getActivityListAction());
  const putAdditionalInfoOperationAction = params =>
    dispatch(putAdditionalInfoOperation(params));
  //=========== end action===========
  useEffect(() => {
    const { type, id } = props.match.params;
    getFixedPackageAction(type, id);
    getMovementListAction();
    setGeneralInfo({ ...generalInfo, id, packageType: type });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    initialPackage();
    // eslint-disable-next-line
  }, [packageDetail]);

  const initialPackage = () => {
    let {
      BookingDetailSum,
      Brochures,
      Prices,
      Descriptions,
      HeadlineProgram,
      DailyPrograms,
    } = packageDetail;
    if (BookingDetailSum && Brochures && Prices)
      setGeneralInfo({
        ...generalInfo,
        image: Brochures && Brochures.length > 0 ? Brochures[0].ImageUrl : '',
        tourName: BookingDetailSum.Title,
        firstDestination: `${BookingDetailSum.City.Name}, ${BookingDetailSum.Country.Name}`,
        desc: BookingDetailSum.Description,
        tourType: BookingDetailSum.TourCategory.Name,
        currency: BookingDetailSum.CurrencyId
          ? BookingDetailSum.CurrencyId
          : Prices.CurrencyId,
        startDate: convertDateFormat(BookingDetailSum.StartDate, 'DD MMM YYYY'),
        endDate: convertDateFormat(BookingDetailSum.EndDate, 'DD MMM YYYY'),
      });
    if (Descriptions)
      setAdditionalInfo(
        Descriptions.map(e =>
          e.SubTitle.includes('FOR_VOUCHER')
            ? {
                ...e,
                SubTitle: e.SubTitle.replace('FOR_VOUCHER ', ''),
                ForVoucher: true,
              }
            : { ...e }
        )
      );
    if (HeadlineProgram && DailyPrograms) {
      setHeadlineProgram(HeadlineProgram);
      setDailyProgram(DailyPrograms);
    }
  };

  const handleAdditionalInfo = (name, value) => {
    setAdditionalInfo(value);
  };

  const handleBack = () => {
    setModalConfirm({
      type: 'BACK',
      isOpen: true,
      header: 'Confirmation',
      message:
        'Are you sure you left this package? the filled in data will be lost',
    });
  };
  const handleSave = () => {
    setModalConfirm({
      type: 'SAVE',
      isOpen: true,
      header: 'Confirmation',
      message:
        'Are you sure the data that is filled in is correct? package will be saved',
    });
  };
  const doBackPage = () => {
    setChangePage({
      isChange: true,
      link:
        '/PackageList/' +
        (generalInfo.packageType === 'FixedDateVariable'
          ? 'Ready'
          : generalInfo.packageType),
    });
  };
  const doSave = () => {
    let dataPost = additionalInfo
      ? additionalInfo.reduce((acc, e) => {
          if (e.ForVoucher) {
            acc.push({ ...e, SubTitle: 'FOR_VOUCHER ' + e.SubTitle });
          }
          return acc;
        }, [])
      : [];
    putAdditionalInfoOperationAction({
      packageType: generalInfo.packageType,
      id: generalInfo.id,
      data: dataPost,
    })
      .then(() => {
        handleAlert('Success', 'The package was successfully update');
        getFixedPackageAction(generalInfo.packageType, generalInfo.id);
      })
      .catch(error => handleAlert('Failed', error.response.data.Message));
  };
  const handleDeletedAdditionalInformation = value => {
    let data = deletedAdditionalInformation;
    if (value === 'clear') data = [];
    else data.push(value);
    setDeletedAdditionalInformation(data);
  };
  const handleCloseConfirm = () =>
    setModalConfirm({ type: '', isOpen: false, header: '', message: '' });
  const handleConfirm = () => {
    setModalConfirm({ isOpen: false, type: '', header: '', message: '' });
    modalConfirm.type === 'BACK' ? doBackPage() : modalConfirm.type && doSave();
  };
  const handleAlert = (header, message) => {
    setModalAlert({
      isOpen: !modalAlert.isOpen,
      header: !modalAlert.isOpen ? header : '',
      message: !modalAlert.isOpen ? message : '',
    });
  };
  const handleDailyProgram = DP => {
    setDailyProgram(DP);
  };
  const handleHeadlineProgram = headlineProgram =>
    setHeadlineProgram(headlineProgram);
  const loading = loadingPackageStore;
  if (changePage.isChange) return <Redirect to={changePage.link} />;
  else
    return (
      <Segment style={{ marginTop: '30px' }}>
        <Loader active={loading} inverted={true} />
        <HeaderTitle
          title={`${
            generalInfo.packageType === 'FixedDateVariable'
              ? 'Fixed Price'
              : generalInfo.packageType === 'Fixed'
              ? 'Series'
              : generalInfo.packageType
          } package`}
        />
        <Grid columns="equal">
          <Grid.Row style={styles.noPadding}>
            <Grid.Column width={13} />
            <Grid.Column width={3}>
              <Button
                content="Back"
                floated="left"
                style={styles.buttonBorderBlue}
                onClick={handleBack}
              />
              <Button
                content="Save"
                floated="left"
                style={styles.buttonBlue}
                onClick={handleSave}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={styles.paddingBottom20px}>
            <Grid.Column width={16} style={styles.paddingBottom10px}>
              <Header as="h4" content="General Information" />
            </Grid.Column>
            <Grid.Column width={3}>
              <Image
                src={
                  generalInfo.image
                    ? generalInfo.image
                    : require('./../../assets/Icons/no_image.jpg')
                }
                size="small"
                style={styles.width100Percent}
              />
            </Grid.Column>
            <Grid.Column width={7}>
              <Header as="h5" content="Tour Information" />
              <Grid>
                <Grid.Row>
                  <Grid.Column width={6}>Tour name</Grid.Column>
                  <Grid.Column width={10}>
                    : {generalInfo.tourName || '-'}
                  </Grid.Column>
                  <Grid.Column width={6}>First Destination</Grid.Column>
                  <Grid.Column width={10}>
                    : {generalInfo.firstDestination || '-'}
                  </Grid.Column>
                  <Grid.Column width={6}>Description</Grid.Column>
                  <Grid.Column width={10}>
                    : {generalInfo.desc || '-'}
                  </Grid.Column>
                  <Grid.Column width={6}>Tour Type</Grid.Column>
                  <Grid.Column width={10}>
                    : {generalInfo.tourType || '-'}
                  </Grid.Column>
                  <Grid.Column width={6}>Currency</Grid.Column>
                  <Grid.Column width={10}>
                    : {generalInfo.currency || '-'}
                  </Grid.Column>
                  <Grid.Column width={6}>Start date</Grid.Column>
                  <Grid.Column width={10}>
                    : {generalInfo.startDate || '-'}
                  </Grid.Column>
                  <Grid.Column width={6}>End date</Grid.Column>
                  <Grid.Column width={10}>
                    : {generalInfo.endDate || '-'}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column width={6}>
              <AdditionalInfo
                additionalInfoList={additionalInfo}
                handleAdditionalInfo={handleAdditionalInfo}
                deletedAdditionalInformation={
                  handleDeletedAdditionalInformation
                }
                tableName="Additional Information"
                isEVoucher={true}
                // disabled={isOperation && isPublished}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16} style={styles.paddingBottom10px}>
              <Header as="h3" content="Daily Program" />
            </Grid.Column>
            <Grid.Column width={16}>
              {generalInfo.packageType === 'Fixed' ? (
                <TabDailyProgram
                  dailyProgram={dailyProgram}
                  // setDailyProgram={this.setDailyProgram}
                  headlineProgram={headlineProgram}
                  // setheadlineProgram={this.setheadlineProgram}
                  isPublished={true}
                  isDisabled={true}
                  isOperation={true}
                />
              ) : (
                <TourItinerary
                  dailyProgram={dailyProgram}
                  headlineProgram={headlineProgram}
                  setDailyProgram={handleDailyProgram}
                  typePackage={generalInfo.packageType}
                  movementList={activityList}
                  setheadlineProgram={handleHeadlineProgram}
                  // guests={this.state.guests}
                  isPublished={true}
                  // currentPackage={currentPackage}
                  // isPackageMangement={true}
                  isPackageManagemenEdit={true}
                />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ModalConfirm
          openModal={modalConfirm.isOpen}
          close={handleCloseConfirm}
          confirm={handleConfirm}
          message={modalConfirm.message}
          nameConfirm={modalConfirm.type}
          header={modalConfirm.header}
        />
        <ModalAlert
          openModal={modalAlert.isOpen}
          handleClose={handleAlert}
          header={modalAlert.header}
          content={modalAlert.message}
        />
      </Segment>
    );
};
PackageDetailForOperation.propTypes = {
  match: PropTypes.object,
};
export default PackageDetailForOperation;
