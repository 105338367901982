// import { GOLD_COLOR } from '../../../../../constant/colour';
const style = {
  buttonAddGuide: {
    boxShadow: '0 0 0 transparent',
    padding: 0,
  },
  paddingTopZero: { paddingTop: 0 },
  backGroundGold: {
    background: '#e6ca6b',
  },
};

export default style;
