import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tab, Grid, Segment } from 'semantic-ui-react';
import PaymentHistory from './table/TablePaymentTermTourInDetailBooking';
import Invoices from './table/TableInvoiceTourTransaction';
import Commission from './table/TableCommissionHistory';
import TableAdditionalItem from './table/TableAdditionalItem';
// import TableSpecialAdjusment from './table/TableSpecialAdjusment';
import TableItemBooking from './table/TableItemBooking';
import {
  resetStatusTransactionAction,
  getPrintPaymentReceiptTransactionAction,
} from '../../../../actions/tourTransactionAction';
import styles from '../../../../assets/styles/styleCreateTourTransaction';

class SegmentPaymentList extends React.Component {
  state = {
    TabMenu: [
      {
        title: 'paymentHistory',
        menuItem: 'Payment History',
        render: () => (
          <Tab.Pane
            active={true}
            content={
              <PaymentHistory
                listData={this.props.contentPaymentHistoryList}
                Currency={this.props.CurrencyId}
              />
            }
          />
        ),
      },
      {
        title: 'invoices',
        menuItem: 'Invoices',
        render: () => (
          <Tab.Pane
            attached={false}
            content={
              <Invoices
                listData={this.props.invoiceList}
                typePackage={this.props.typePackage}
                printInvoice={this.printInvoice}
                loading={this.state.loadingPrint}
              />
            }
          />
        ),
      },
      {
        title: 'staffCommission',
        menuItem: 'Staff Commission',
        render: () => (
          <Tab.Pane
            attached={false}
            content={
              <Commission
                listData={this.props.tourTransactionsCommission}
                typePackage={this.props.typePackage}
                getCommissions={this.props.getCommissions}
              />
            }
          />
        ),
      },
      {
        title: 'additionalItem',
        menuItem: 'Additional Item',
        render: () => (
          <Tab.Pane
            attached={false}
            content={
              <TableAdditionalItem
                listData={this.props.getPayableSupplemetItem}
                typePackage={this.props.typePackage}
                isHidePrice={this.props.isOperation}
              />
            }
          />
        ),
      },
      {
        title: 'roomRequest',
        menuItem: 'Room Request',
        render: () => (
          <Tab.Pane
            attached={false}
            content={
              <TableItemBooking
                listConfirmRoomAllotment={this.props.itemBookingList}
                loading={false}
              />
            }
          />
        ),
      },
    ],
  };
  filterTab = (isHidePrice, tabMenu) => {
    return tabMenu.reduce((acc, e) => {
      if (isHidePrice) {
        if (e.title === 'additionalItem' || e.title === 'roomRequest')
          acc.push(e);
      } else {
        acc.push(e);
      }
      return acc;
    }, []);
  };
  renderPaymentList = statusPackage => {
    let { TabMenu, TabMenuSpecialAdjustment } = { ...this.state };
    let { isOperation } = this.props;
    let isHidePrice = isOperation;
    let newTabMenu = this.filterTab(isHidePrice, [...TabMenu]);
    if (this.props.currentPackage.type === 'Quotation') {
      return (
        <Tab
          menu={{ secondary: true, pointing: true }}
          panes={TabMenuSpecialAdjustment}
        />
      );
    } else if (statusPackage !== 'create') {
      return (
        <Tab menu={{ secondary: true, pointing: true }} panes={newTabMenu} />
      );
      // } else if (statusPackage === 'create') {
      //   return (
      //     <Tab
      //       menu={{ secondary: true, pointing: true }}
      //       panes={TabMenuSpecialAdjustment}
      //     />
      //   );
    } else {
      return null;
    }
  };

  printInvoice = (e, { value, id }) => {
    this.setState({
      loadingPrint: true,
    });
    if (id === null) {
      this.props
        .getPrintPaymentReceiptTransactionAction('', value)
        .then(res => {
          window.open(res.value.data);
          this.setState({
            loadingPrint: false,
          });
        });
    } else {
      this.props
        .getPrintPaymentReceiptTransactionAction(id, value)
        .then(res => {
          window.open(res.value.data);
          this.setState({
            loadingPrint: false,
          });
        });
    }
    this.setState({
      loadingInvoice: true,
    });
  };

  render() {
    const { statusPackage } = this.props;
    return (
      statusPackage !== 'create' && (
        <Segment raised fluid>
          <div style={styles.marginContentInsideCard}>
            <Grid.Column width={16}>
              {this.renderPaymentList(statusPackage)}
            </Grid.Column>
          </div>
        </Segment>
      )
    );
  }
}
SegmentPaymentList.propTypes = {
  contentPaymentHistoryList: PropTypes.array,
  typePackage: PropTypes.string,
  getInvoiceByCompanyCodeStatus: PropTypes.string,
  getInvoiceByCompanyCode: PropTypes.array,
  transactionId: PropTypes.number,
  invoiceList: PropTypes.array,
  tourTransactionsCommission: PropTypes.array,
  getTourTransactionsCommissionPaymentStatus: PropTypes.string,
  getTourTransactionsCommissionPayment: PropTypes.array,
  statusPackage: PropTypes.string,
  getPrintPaymentReceiptTransactionAction: PropTypes.func,
  getPayableSupplemetItem: PropTypes.array,
  specialAdjusments: PropTypes.array,
  currencies: PropTypes.array,
  handleSpecialAdjustment: PropTypes.func,
  CurrencyId: PropTypes.string,
  currentPackage: PropTypes.object,
  getCommissions: PropTypes.func,
  itemBookingList: PropTypes.array,
  packageType: PropTypes.string,
  getAdditionalItemTypeList: PropTypes.array,
  changeSpecialAdjustment: PropTypes.func,
  isOperation: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    getInvoiceByCompanyCodeStatus:
      state.TourTransactionStore.getInvoiceByCompanyCodeStatus,
    getInvoiceByCompanyCode: state.TourTransactionStore.getInvoiceByCompanyCode,
    getTourTransactionsCommissionPayment:
      state.TourTransactionStore.getTourTransactionsCommissionPayment,
    getTourTransactionsCommissionPaymentStatus:
      state.TourTransactionStore.getTourTransactionsCommissionPaymentStatus,
    getPayableSupplemetItemStatus:
      state.TourTransactionStore.getPayableSupplemetItemStatus,
    getPayableSupplemetItem: state.TourTransactionStore.getPayableSupplemetItem,
    getAdditionalItemTypeList:
      state.AdditionalItemStore.getAdditionalItemTypeList,
    isOperation: state.UserAccessStore.isOperation,
  };
}

export default connect(mapStateToProps, {
  resetStatusTransactionAction,
  getPrintPaymentReceiptTransactionAction,
})(SegmentPaymentList);
