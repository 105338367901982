import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Checkbox, Form, Grid } from 'semantic-ui-react';
import ButtonClose from '../../../../components/button/buttonCloseModal';
import { StyleModal } from '../../../../assets/styles/size';

const ModalCancelBooking = props => {
  const { close, open, CancellationReasonType } = props;
  const [check, setCheck] = useState(false);

  const handleCheckBox = () => {
    setCheck(!check);
  };

  return (
    <>
      <Modal.Header>
        {CancellationReasonType === 'Partner'
          ? `Did supplier want to reject this item ?`
          : `Did customer want to cancel this booking ?`}
        <ButtonClose onClose={close} />
      </Modal.Header>
      <Modal.Content>
        <Grid.Column>
          {CancellationReasonType === 'Partner'
            ? `Each package has a different cancellation policy. Make sure the
          supplier read the information regarding cancellation on the invoice.`
            : `Each package has a different cancellation policy. Make sure your
          customer read the information regarding cancellation on the invoice.`}
        </Grid.Column>
        <Grid.Column>
          <Form>
            <Checkbox
              style={StyleModal.marginModalCancellationReason.checkBoxMargin}
              name="isCheckBoxChecked"
              value={check}
              label={{
                children:
                  CancellationReasonType === 'Partner'
                    ? 'Yes, the supplier already read cancellation policy'
                    : 'Yes, my customer already read cancellation policy',
              }}
              onChange={handleCheckBox}
              checked={check}
            />
          </Form>
        </Grid.Column>
      </Modal.Content>
      <Modal.Actions style={StyleModal.center}>
        <Button
          style={StyleModal.buttonConfirm}
          content="Yes, cancel this booking"
          disabled={!check}
          name="isCheckBoxChecked"
          onClick={open}
        />
        <Button style={StyleModal.buttonCancel} content="No" onClick={close} />
      </Modal.Actions>
    </>
  );
};
ModalCancelBooking.propTypes = {
  close: PropTypes.func,
  open: PropTypes.func,
  CancellationReasonType: PropTypes.string,
};
export default ModalCancelBooking;
