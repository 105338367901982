import { copyObject } from '../../../../scripts/itineraryBuilder/itineraryBuilder';
import { ERRORS_PRICE, ERRORS_COST } from '../constant/tierPrice';
import {
  setCost,
  setPackageCostFunc,
  setPrice,
} from '../../../../scripts/packageManagement/fixedPackageFunc';

const checkEmptyField = price => {
  let copyPrice = copyObject(price);
  let copyErrorPrice = copyObject(ERRORS_PRICE);
  let copyErrorCost = copyObject(ERRORS_COST);
  let isError = false;
  if (copyPrice.MinimumPax === 0) {
    isError = true;
    copyErrorPrice.MinimumPax.error = true;
    copyErrorPrice.MinimumPax.message = 'Pax cannot be smaller than 0';
  }
  if (copyPrice.CurrencyId === '') {
    isError = true;
    copyErrorPrice.CurrencyId.error = true;
    copyErrorPrice.CurrencyId.message = "Field can't be empty!";
  }
  if (copyPrice.SingleRoomPrice === 0) {
    isError = true;
    copyErrorPrice.SingleRoomPrice = "Field can't be empty!";
  }
  if (copyPrice.SharingRoomPrice === 0) {
    isError = true;
    copyErrorPrice.SharingRoomPrice = "Field can't be empty!";
  }

  if (copyPrice.Cost.SingleRoomPrice === 0) {
    isError = true;
    copyErrorCost.SingleRoomPrice = "Field can't be empty!";
  }
  if (copyPrice.Cost.SharingRoomPrice === 0) {
    isError = true;
    copyErrorCost.SharingRoomPrice = "Field can't be empty!";
  }
  return { isError, errorsPrice: copyErrorPrice, errorsCost: copyErrorCost };
};

const deleteConditionCRUD = price => {
  return {
    ...price,
    IsAdd: undefined,
    IsEdit: undefined,
    IsDelete: undefined,
  };
};

const checkDuplicatePrice = (price, prices) => {
  let copyPrice = copyObject(price);
  let copyPrices = copyObject(prices);
  copyPrice = deleteConditionCRUD(copyPrice);
  let isDuplicate =
    copyPrices.find(
      e =>
        JSON.stringify(deleteConditionCRUD(e)) ===
        JSON.stringify(copyObject(copyPrice))
    ) !== undefined;
  return isDuplicate;
};
const editPrice = (prices, price, selectedPrice) => {
  let copySelectedPrice = copyObject(selectedPrice);
  let copyPrices = copyObject(prices);
  copySelectedPrice = deleteConditionCRUD(copySelectedPrice);
  return copyPrices.map(e =>
    JSON.stringify(deleteConditionCRUD(e)) ===
    JSON.stringify(copyObject(copySelectedPrice))
      ? price
      : e
  );
};
const initialCustomPrices = value => {
  let {
    stateCustomPrices,
    Prices, //array pricenya
    IsTourOperatorFixedPackage,
    BaseMarginPercentage,
    TourOperatorMarginPercentage,
  } = value;
  IsTourOperatorFixedPackage = false;
  let copyPrices = copyObject(Prices);
  copyPrices = copyPrices.map(e => {
    let packagePrice = setPrice(
      e,
      IsTourOperatorFixedPackage,
      BaseMarginPercentage,
      TourOperatorMarginPercentage
    );
    let packageCost = setCost(
      e,
      BaseMarginPercentage,
      TourOperatorMarginPercentage,
      IsTourOperatorFixedPackage
    );
    packageCost = IsTourOperatorFixedPackage
      ? setPackageCostFunc(
          packageCost,
          e,
          BaseMarginPercentage,
          IsTourOperatorFixedPackage
        )
      : packageCost;
    return { ...e, ...packagePrice, Cost: packageCost };
  });

  return {
    ...stateCustomPrices,
    Prices: copyPrices,
    SystemMargin: BaseMarginPercentage,
    TourOperatorMargin: TourOperatorMarginPercentage,
    IsTourOperatorFixedPackage,
  };
};
export { checkEmptyField, checkDuplicatePrice, editPrice, initialCustomPrices };
