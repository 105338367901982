const WIDTH_SIDE_BAR = 260;

const WIDTH_PUSHER = window.innerWidth - WIDTH_SIDE_BAR;

const MIN_HEIGTH = `${window.innerHeight + 13.5}px`;

const StyleModal = {
  modalWidth: {
    width: '515px',
  },
  modalWidthManualPayment: {
    width: '630px',
  },
  modalXendit: {
    width: '46em',
  },
  marginModalManualPayment: {
    marginBooking: { margin: '1em' },
    marginCustomer: { margin: '1.8em' },
    marginTourName: { margin: '1.4em' },
    marginPrice: { margin: '1.8em' },
    marginPaid: { margin: '2.1em' },
    marginTotalPayable: { margin: '13.7em' },
    marginCustomerBalance: { margin: '6em' },
  },
  marginModalDetailBooking: {
    marginBooking: { margin: '3em' },
    marginTourName: { margin: '1.4em' },
    marginCustomerName: { margin: '1.3em' },
    marginStatus: { margin: '5em' },
    marginTourPrice: { margin: '1em' },
    marginTotalPaid: { margin: '3.6em' },
    marginSpecialAdjusment: { marginBottom: '1em', marginTop: '-2.5em' },
  },
  marginModalSpecialAdjustment: {
    marginBooking: { margin: '2.8em' },
    marginTourName: { margin: '1em' },
    marginStatus: { margin: '5.4em' },
    marginButtonAddNew: { marginBottom: '1em', marginTop: '-3em' },
    marginButtonSave: { marginBottom: '1em' },
    widthModal: { width: '94em' },
  },
  marginModalCancellationReason: {
    checkBoxMargin: { marginTop: '1em' },
    cancelButton: { color: '#F44336' },
    confirmButton: { color: '#00BCD4' },
    textAlign: 'center',
    marginTop0: { marginTop: '0px' },
    marginTop1: { marginTop: '1px' },
    marginBottom1: { marginBottom: '1px' },
    marginTopMin20: { marginTop: '-20px' },
  },
  modalWidthCancel: {
    width: '450px',
  },
  buttonConfirm: {
    backgroundColor: '#F44336',
    color: 'white',
    border: '2px solid #F44336',
    borderRadius: '25px', //38AF95
  },
  buttonCancel: {
    backgroundColor: '#00BCD4',
    color: 'white',
    border: '2px solid #00BCD4',
    borderRadius: '25px',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export { WIDTH_SIDE_BAR, WIDTH_PUSHER, MIN_HEIGTH, StyleModal };
