import React from 'react';
import PropTypes from 'prop-types';
import AccomodationProfileTable from './table/TableMasterDataAccomodationProfile';
import ExcursionObjectTable from './table/TableMasterDataExcursionObject';
import RestaurantProfileTable from './table/TableMasterDataRestaurantProfile';
import TransportationProfileTable from './table/TableMasterDataTransportationProfile';
import AccomodationItem from './table/TableMasterDataAccomodationItem';
import RestaurantItem from './table/TableMasterDataRestaurantItem';
import TransportationUnit from './table/TableMasterDataTransportationUnit';
import RestaurantPrice from './table/TableMasterDataRestaurantPrice';
import ExcursionPrice from './table/TableMasterDataExcursionPrice';

export default class UploadMasterDataTable extends React.Component {
  render() {
    return this.props.type === 'Accomodation' ? (
      <AccomodationProfileTable listData={this.props.listData} />
    ) : this.props.type === 'Excursion' ? (
      <ExcursionObjectTable listData={this.props.listData} />
    ) : this.props.type === 'Restaurant' ? (
      <RestaurantProfileTable listData={this.props.listData} />
    ) : this.props.type === 'Transportation' ? (
      <TransportationProfileTable listData={this.props.listData} />
    ) : this.props.type === 'AccomodationItem' ? (
      <AccomodationItem listData={this.props.listData} />
    ) : this.props.type === 'RestaurantItem' ? (
      <RestaurantItem listData={this.props.listData} />
    ) : this.props.type === 'TransportationUnit' ? (
      <TransportationUnit listData={this.props.listData} />
    ) : this.props.type === 'RestaurantPrice' ? (
      <RestaurantPrice listData={this.props.listData} />
    ) : this.props.type === 'ExcursionPrice' ? (
      <ExcursionPrice listData={this.props.listData} />
    ) : null;
  }
}
UploadMasterDataTable.propTypes = {
  type: PropTypes.string,
  listData: PropTypes.array,
};
