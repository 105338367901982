import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  Form,
  Header,
  Radio,
  Checkbox,
  Grid,
} from 'semantic-ui-react';
// import styles from './../styles';
import ButtonClose from '../../../../components/button/buttonCloseModal';
import InputWithLabel from '../../../../components/input/inputWithLabel';

const modalPaymentSchemeDetail = props => {
  const {
    isOpen,
    onClose,
    contentPaymentTerm,
    handleChange,
    savePaymentScheme,
    handleCheckClick,
    errorInformation,
    contentHeaderModal,
  } = props;
  const {
    IntervalDays,
    Percentage,
    isLockGuest,
    Description,
    IsAfterBookingBased,
  } = contentPaymentTerm;
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      size="mini"
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        {contentHeaderModal} <ButtonClose onClose={onClose} />
      </Modal.Header>
      <Modal.Content>
        <Form>
          <InputWithLabel
            inline={false}
            label="Payment Description"
            type="text"
            required
            name="Description"
            value={Description}
            placeholder="Payment Description"
            handleChange={handleChange}
            validate={errorInformation}
          />
        </Form>
        <Form>
          <InputWithLabel
            inline={false}
            label="Percentage (%)"
            type="number"
            required
            name="Percentage"
            value={Percentage}
            placeholder="Percentage (%)"
            handleChange={handleChange}
            validate={errorInformation}
          />
        </Form>
        <Form>
          <InputWithLabel
            inline={false}
            label="Interval Days"
            type="number"
            required
            name="IntervalDays"
            value={IntervalDays}
            placeholder="Interval Days"
            handleChange={handleChange}
            validate={errorInformation}
          />
        </Form>
        <Header as="h4" content="When does the interval day apply?" />
        <Grid columns={2} padded="vertically">
          <Grid.Column>
            <Form>
              <Form.Field>
                <Radio
                  label="After package created"
                  value={true}
                  checked={IsAfterBookingBased === true}
                  onChange={handleChange}
                  name="IsAfterBookingBased"
                  style={{ fontWeight: 'bold' }}
                />
              </Form.Field>
            </Form>
          </Grid.Column>
          <Grid.Column>
            <Form>
              <Form.Field>
                <Radio
                  label="Before started tour date"
                  value={false}
                  name="IsAfterBookingBased"
                  checked={IsAfterBookingBased === false}
                  onChange={handleChange}
                  style={{ fontWeight: 'bold' }}
                />
              </Form.Field>
            </Form>
          </Grid.Column>
        </Grid>

        <Checkbox
          label={{ children: 'Lock guest' }}
          onChange={handleCheckClick}
          onClick={handleCheckClick}
          checked={isLockGuest}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          onClick={savePaymentScheme}
          content="Save"
          value={contentPaymentTerm}
        />
      </Modal.Actions>
    </Modal>
  );
};

modalPaymentSchemeDetail.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  contentPaymentTerm: PropTypes.object,
  savePaymentScheme: PropTypes.func,
  handleChange: PropTypes.func,
  handleCheckClick: PropTypes.func,
  errorInformation: PropTypes.object,
  contentHeaderModal: PropTypes.string,
};

export default modalPaymentSchemeDetail;
