import React from 'react';
import { Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import InputAmount from '../../../../../../components/input/inputAmount';
import styles from '../../Styles';
const FormGuestQty = props => {
  let {
    guests,
    // currentPackage,
    handleIncrease,
    handleDecrease,
    handleChange,
  } = props;
  // let isFixedPackage = currentPackage.type === 'Fixed';
  // let isEditPackage = currentPackage.status === 'edit';
  // let disabledAdults = isFixedPackage
  //   ? isEditPackage
  //     ? guests.adults === 0
  //     : false
  //   : isEditPackage;
  // let disabledChilds = isFixedPackage
  //   ? isEditPackage
  //     ? guests.childs === 0
  //     : false
  //   : isEditPackage;
  // let disabledInfants = isFixedPackage
  //   ? isEditPackage
  //     ? guests.infants === 0
  //     : false
  //   : isEditPackage;
  return (
    <Form>
      <Form.Group widths="equal">
        <Form.Field style={styles.paddingRight20}>
          <InputAmount
            label="Adults(>12 Years)"
            labelStyle={styles.textAlignCenter}
            inputStyle={styles.borderBottom}
            name="adults"
            objectname="guests"
            value={guests.adults}
            handleIncrease={handleIncrease}
            handleDecrease={handleDecrease}
            handleOnChange={handleChange}
            // disabled={disabledAdults}
          />
        </Form.Field>
        <Form.Field>
          <InputAmount
            label="Children(<12 Years)"
            labelStyle={styles.textAlignCenter}
            inputStyle={styles.borderBottom}
            name="childs"
            objectname="guests"
            value={guests.childs}
            handleIncrease={handleIncrease}
            handleDecrease={handleDecrease}
            handleOnChange={handleChange}
            // disabled={disabledChilds}
          />
        </Form.Field>
        <Form.Field style={styles.paddingLeft20}>
          <InputAmount
            label="Infants"
            labelStyle={styles.textAlignCenter}
            inputStyle={styles.borderBottom}
            name="infants"
            objectname="guests"
            value={guests.infants}
            handleIncrease={handleIncrease}
            handleDecrease={handleDecrease}
            handleOnChange={handleChange}
            // disabled={disabledInfants}
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
};
FormGuestQty.propTypes = {
  guests: PropTypes.object,
  currentPackage: PropTypes.object,
  handleIncrease: PropTypes.func,
  handleDecrease: PropTypes.func,
  handleChange: PropTypes.func,
};
export default FormGuestQty;
