import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Form } from 'semantic-ui-react';
import InputWithLabel from '../../../../../../components/input/inputWithLabel';
import styles from '../../Styles';

const SegmentCancellationReason = props => {
  const { cancellationReason, cancellationReasonNote, currentPackage } = props;
  return (
    <div>
      <Grid columns="equal">
        <Grid.Row stretched>
          <Grid.Column width={16}>
            <p style={{ fontSize: '14px' }}>Cancellation Reason</p>
            <Form style={styles.marginTopMin10}>
              <InputWithLabel
                inline={false}
                type="textArea"
                fluid={true}
                value={cancellationReason || cancellationReasonNote}
                disabled={currentPackage.disabled}
                inputStyles={{ minHeight: '3em' }}
              />
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
SegmentCancellationReason.propTypes = {
  cancellationReason: PropTypes.object,
  cancellationReasonNote: PropTypes.object,
  currentPackage: PropTypes.bool,
};
export default SegmentCancellationReason;
