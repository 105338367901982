import React from 'react';
import { Dimmer } from 'semantic-ui-react';
import { connect } from 'react-redux';
import ModalAlert from '../shared/components/modal/ModalAlert';
import Loader from '../../components/loader';
import SegmentSystemMargin from './component/SystemMarginList';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  getSystemMarginListAction,
  resetSystemMarginAction,
  postSystemMarginAction,
  putSystemMarginAction,
  deleteSystmeMarginAction,
  getSystemMarginByIdAction,
  getTourOperatorMarginByIdAction,
  postTourOperatorSystemMarginAction,
  putTourOperatorSystemMarginAction,
  getTourOperatorMarginByProfileIdAction,
  deleteTourOperatorMarginAction,
} from './systemMarginAction';
import {
  getCustomerProfileByCodeAction,
  resetCustomerProfileAction,
} from '../shared/actions/customerProfileAction';
import {
  getTourOperatorProfileDetailByIdAction,
  resetGetTourOperatorByCodeAction,
} from '../../actions/packageAction';
import ModalSystemMargin from './component/ModalSystemMargin';
import ModalConfirm from '../../components/modal/modalConfirmationTwoButton';
import ModalDateRange from '../shared/components/modal/ModalDateRange';

class SystemMargin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      systemMarginList: [],
      systemMargin: {},
      loadingSystemMargin: true,
      failedSystemMargin: false,
      modalOpen: false,
      headerAlert: '',
      contentAlert: '',
      modalSystemMargin: false,
      modalConfirmation: false,
      modalDateRange: false,
      headerModal: '',
      errors: {},

      searchInput: '',
      activePage: 1,
      dateRange: {
        tourStartDate: null,
        tourEndDate: null,
      },
    };
  }
  static propTypes = {
    getSystemMarginListAction: PropTypes.func,
    statusSystemMargin: PropTypes.func,
    listSystemMargin: PropTypes.func,
    resetSystemMarginAction: PropTypes.func,
    getCustomerProfileByCodeAction: PropTypes.func,
    getCustomerProfileByCodeStatus: PropTypes.string,
    getCustomerProfileByCode: PropTypes.array,
    resetCustomerProfileAction: PropTypes.func,
    postSystemMarginAction: PropTypes.func,
    putSystemMarginAction: PropTypes.func,
    postSystemMarginStatus: PropTypes.string,
    putSystemMarginStatus: PropTypes.string,
    deleteSystmeMarginAction: PropTypes.func,
    getSystemMarginByIdAction: PropTypes.func,
    deleteSystemMarginStatus: PropTypes.string,
    getSystemMarginByIdStatus: PropTypes.string,
    getSystemMarginById: PropTypes.array,
    match: PropTypes.shape({
      params: PropTypes.shape({
        ProfileId: PropTypes.string,
        Type: PropTypes.string,
      }),
    }),
    errorsMessage: PropTypes.object,
    disableButton: false,
    getTourOperatorMarginByIdAction: PropTypes.func,
    loading: PropTypes.bool,
    getTourOperatorProfileDetailByIdAction: PropTypes.func,
    postTourOperatorSystemMarginAction: PropTypes.func,
    putTourOperatorSystemMarginAction: PropTypes.func,
    getTourOperatorMarginByProfileIdAction: PropTypes.func,
    deleteTourOperatorMarginAction: PropTypes.func,
  };

  handleOpen = () => this.setState({ modalOpen: true });
  handleClose = () =>
    this.setState({
      modalOpen: false,
      modalConfirmation: false,
      openConfirm: false,
      failedSystemMargin: false,
    });
  handleCloseModal = () => this.setState({ modalSystemMargin: false });
  componentDidMount = () => {
    this.getSystemMarginProfileId();
  };
  getSystemMarginProfileId = () => {
    let { ProfileId, Type } = this.props.match.params;
    if (Type === 'Customer') {
      if (!ProfileId) {
        this.props.getSystemMarginListAction('10000');
        this.setState({
          loadingSystemMargin: false,
          disableButton: true,
          systemMargin: {
            companyName: 'Touress.com',
            CompanyCode: 10000,
          },
        });
      } else {
        return this.getDataSystemMargin(ProfileId);
      }
    } else if (Type === 'TourOperator') {
      this.props.getTourOperatorMarginByProfileIdAction(ProfileId).then(res => {
        this.setState({
          systemMarginList: res.value.data,
          loadingSystemMargin: false,
        });
      });
      this.props.getTourOperatorProfileDetailByIdAction(ProfileId).then(res => {
        this.setState({
          systemMargin: {
            Id: '',
            CompanyCode: res.value.data.Company.Code,
            companyName: res.value.data.Name,
          },
        });
      });
    } else {
      this.props.getSystemMarginListAction('10000');
      this.setState({
        loadingSystemMargin: false,
        disableButton: true,
        systemMargin: {
          companyName: 'Touress.com',
          CompanyCode: 10000,
        },
      });
    }
  };
  getDataSystemMargin = code => {
    this.props.getSystemMarginListAction(code);
    this.props.getCustomerProfileByCodeAction(code);
    this.setState({
      disableButton: false,
      systemMargin: {
        Id: '',
        CompanyCode: code,
      },
    });
  };
  componentDidUpdate = () => {
    if (this.props.statusSystemMargin === 'success') {
      this.setState({
        systemMarginList: this.props.listSystemMargin,
        loadingSystemMargin: false,
      });
      this.props.resetSystemMarginAction();
    } else if (this.props.statusSystemMargin === 'failed') {
      this.setState({
        failedSystemMargin: true,
        loadingSystemMargin: false,
        headerAlert: 'Error',
        contentAlert: this.props.errorsMessage.global,
      });
      this.props.resetSystemMarginAction();
    }
    if (this.props.getCustomerProfileByCodeStatus === 'success') {
      this.setState({
        systemMargin: {
          ...this.state.systemMargin,
          companyName: this.props.getCustomerProfileByCode.Name,
        },
        loadingSystemMargin: false,
      });
      this.props.resetCustomerProfileAction();
    } else if (this.props.getCustomerProfileByCodeStatus === 'failed') {
      this.setState({ failedSystemMargin: true, loadingSystemMargin: false });
      this.props.resetCustomerProfileAction();
    }
    if (this.props.postSystemMarginStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Saving System Margin Succesfully',
        modalConfirmation: true,
        loadingSystemMargin: false,
      });
      this.props.resetSystemMarginAction();
      this.getSystemMarginProfileId();
    } else if (this.props.postSystemMarginStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorsMessage.global,
        modalConfirmation: true,
        loadingSystemMargin: false,
      });
      this.props.resetSystemMarginAction();
      this.getSystemMarginProfileId();
    }
    if (this.props.putSystemMarginStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Edit System Margin Succesfully',
        modalConfirmation: true,
      });
      this.props.resetSystemMarginAction();
      this.getSystemMarginProfileId();
    } else if (this.props.putSystemMarginStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorsMessage.global,
        modalConfirmation: true,
      });
      this.props.resetSystemMarginAction();
      this.getSystemMarginProfileId();
    }
    if (this.props.getSystemMarginByIdStatus === 'success') {
      this.setState({
        systemMargin: {
          ...this.props.getSystemMarginById,
          EndDate: moment(this.props.getSystemMarginById.EndDate).format(
            'YYYY-MM-DD'
          ),
          StartDate: moment(this.props.getSystemMarginById.StartDate).format(
            'YYYY-MM-DD'
          ),
        },
      });
      this.props.resetSystemMarginAction();
    } else if (this.props.getSystemMarginByIdStatus === 'failed') {
      this.props.resetSystemMarginAction();
    }
    if (this.props.deleteSystemMarginStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Delete System Margin Succesfully',
        modalConfirmation: true,
      });
      this.props.resetSystemMarginAction();
      this.getSystemMarginProfileId();
    } else if (this.props.deleteSystemMarginStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorsMessage.global,
        modalConfirmation: true,
      });
      this.props.resetSystemMarginAction();
      this.getSystemMarginProfileId();
    }
  };
  openModalMargin = () => {
    this.setState({
      modalSystemMargin: true,
      headerModal: 'Add New System Margin ',
      systemMargin: {
        ...this.state.systemMargin,
        Id: '',
        MinimalCost: 0,
        Margin: '',
        EndDate: moment(new Date()).format('YYYY-MM-DD'),
        StartDate: moment(new Date()).format('YYYY-MM-DD'),
      },
    });
  };
  saveMargin = () => {
    let { Type, ProfileId } = this.props.match.params;
    const err = this.validate();
    if (!err) {
      const Id = this.state.systemMargin.Id;
      const data = this.state.systemMargin;
      // if customer or admin (base margin)
      if (Type === 'Customer' || typeof Type === 'undefined') {
        if (Id === '') {
          this.props.postSystemMarginAction(data);
        } else {
          this.props.putSystemMarginAction(Id, data);
        }
      } else {
        const tourOperator = {
          Id: data.Id,
          EndDate: data.EndDate,
          TourOperatorProfileId: ProfileId,
          Margin: data.Margin,
          MinimalCost: data.MinimalCost,
          StartDate: data.StartDate,
          // CompanyCode: 20001,
          CompanyCode: data.CompanyCode,
        };
        if (Id === '') {
          // postTourOperatorSystemMarginAction
          // postSystemMarginAction
          this.props.postTourOperatorSystemMarginAction(tourOperator);
        } else {
          // putTourOperatorSystemMarginAction
          // putSystemMarginAction
          this.props.putTourOperatorSystemMarginAction(Id, tourOperator);
        }
      }
      this.setState({ modalSystemMargin: false, loadingSystemMargin: true });
    }
  };
  checkStartDateAndMinimumCost = () => {
    const { systemMargin, systemMarginList } = this.state;
    let statusCheck = false;
    systemMarginList.map(margin => {
      if (
        moment(margin.StartDate).format('l') ===
          moment(systemMargin.StartDate).format('l') &&
        Number(margin.MinimalCost) === Number(systemMargin.MinimalCost) &&
        !margin.Id
      ) {
        statusCheck = true;
      }
      return margin;
    });
    return statusCheck;
  };
  validate = () => {
    let isError = false;
    let { modalConfirmation, contentAlert, headerAlert } = this.state;
    const errors = {
      MinimalCostError: '',
      MarginError: '',
      StartDateError: '',
      EndDateError: '',
    };
    if (this.state.systemMargin.StartDate.length < 1) {
      isError = true;
      errors.StartDateError = "Start Date Can't Be Empty";
    }
    if (this.state.systemMargin.EndDate.length < 1) {
      isError = true;
      errors.EndDateError = "End Date Can't Be Empty";
    }
    if (this.state.systemMargin.MinimalCost.length < 1) {
      isError = true;
      errors.MinimalCostError = "Minimal Cost Can't Be Empty";
    }
    if (this.state.systemMargin.Margin.length < 1) {
      isError = true;
      errors.MarginError = "Margin Can't Be Empty";
    }
    if (this.checkStartDateAndMinimumCost()) {
      isError = true;
      modalConfirmation = true;
      contentAlert =
        'the system has recorded for the same date and minimum cost. please change the date or minimum cost.';
      headerAlert = 'Error';
    }
    this.setState({
      errors: {
        ...errors,
      },
      modalConfirmation: modalConfirmation,
      contentAlert: contentAlert,
      headerAlert: headerAlert,
    });
    return isError;
  };
  onChange = (e, { value, name }) => {
    const systemMargin = this.state.systemMargin;
    systemMargin[name] = value;
    this.setState({ systemMargin: systemMargin }, () => this.validate());
  };
  handleChangeDate = (value, name) => {
    const systemMargin = this.state.systemMargin;
    systemMargin[name] = moment(value).format('YYYY/MM/DD');
    this.setState({ systemMargin: systemMargin });
  };
  onChangeCost = e => {
    const systemMargin = { ...this.state.systemMargin };
    systemMargin.MinimalCost = e.value;
    this.setState({ systemMargin: systemMargin }, () => this.validate());
  };
  editMargin = (e, { value }) => {
    let { Type } = this.props.match.params;
    let { systemMarginList } = this.state;
    if (Type === 'Customer') {
      this.props.getSystemMarginByIdAction(value);
    } else {
      // this.props.getTourOperatorMarginByIdAction(value).then(res =>
      //   this.setState({
      //     systemMargin: {
      //       ...res.value.data,
      //       EndDate: moment(res.value.data.EndDate).format('YYYY-MM-DD'),
      //       StartDate: moment(res.value.data.StartDate).format('YYYY-MM-DD'),
      //     },
      //   })
      // );
      let selectSystemMargin = systemMarginList.find(item => item.Id === value);
      this.setState({
        systemMargin: selectSystemMargin,
      });
    }
    this.setState({
      modalSystemMargin: true,
      headerModal: 'Edit System Margin ',
    });
  };
  delete = (e, { value }) => {
    //this.props.deleteSystmeMarginAction(e.target.value);
    this.setState({
      openConfirm: true,
      messageConfirm: ' Are you sure to delete ?',
      marginId: value,
    });
  };
  confirmAction = () => {
    const { marginId } = this.state;
    let { Type } = this.props.match.params;
    this.setState({
      openConfirm: false,
    });
    if (Type === 'Customer' || typeof Type === 'undefined') {
      this.props.deleteSystmeMarginAction(marginId);
    } else {
      this.props.deleteTourOperatorMarginAction(marginId);
    }
  };

  handleBack = () => {
    let { ProfileId, Type } = this.props.match.params;
    const { CompanyCode } = this.state.systemMargin;
    if (Type === 'Customer') {
      // eslint-disable-next-line
      this.props.history.push(`/Customer-Profile-Detail/detail/${ProfileId}`);
    } else {
      // eslint-disable-next-line
      this.props.history.push(`/Partner-Profile-Detail/detail/${CompanyCode}`);
    }
  };

  openModalDateRange = () => {
    this.setState({ modalDateRange: true });
  };

  closeModalDateRange = () => {
    this.setState({ modalDateRange: false });
  };

  actionDateRange = id => {
    this.closeModalDateRange();
    this.setState({ searchInput: id });
    // console.log(id);
  };

  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  handleChangeDateRange = (value, name) => {
    this.setState({ [name]: value, activePage: 1 });
  };
  pageChange = (e, { activePage }) => {
    this.setState({ activePage: activePage });
  };

  render() {
    let {
      // loadingSystemMargin,
      failedSystemMargin,
      modalConfirmation,
    } = this.state;
    // let loading = loadingSystemMargin;
    let loading = this.props.loading;
    let openModal = failedSystemMargin || modalConfirmation;
    return (
      <div>
        <Dimmer.Dimmable blurring dimmed={loading}>
          <Loader loading={loading} />
          <SegmentSystemMargin
            data={this.state.systemMarginList}
            type="systemMargin"
            filterBy="FormatedDate"
            content={this.state.systemMargin}
            buttonNew={this.openModalMargin}
            butttonEdit={this.editMargin}
            buttonDelete={this.delete}
            test={this.state.EndDate}
            buttonBack={this.handleBack}
            disableButton={this.state.disableButton}
            searchInput={this.state.searchInput}
            activePage={this.state.activePage}
            openModalDateRange={this.openModalDateRange}
            handleChange={this.handleChange}
            handleChangeDate={this.handleChangeDateRange}
            pageChange={this.pageChange}
            dateRange={this.state.searchInput}
          />
          <ModalAlert
            openModal={openModal}
            handleClose={this.handleClose}
            header={this.state.headerAlert}
            content={this.state.contentAlert}
            nameButton="OK"
          />
          <ModalSystemMargin
            open={this.state.modalSystemMargin}
            handleClose={this.handleCloseModal}
            header={this.state.headerModal}
            handleChange={this.onChange}
            onchangeDate={this.handleChangeDate}
            handleChangeMinCost={this.onChangeCost}
            contentField={this.state.systemMargin}
            saveMargin={this.saveMargin}
            errorInformation={this.state.errors}
            loading={loading}
          />
          <ModalConfirm
            openModal={this.state.openConfirm}
            size="mini"
            close={this.handleClose}
            confirm={this.confirmAction}
            message={this.state.messageConfirm}
          />
          <ModalDateRange
            openModal={this.state.modalDateRange}
            dateRange={this.state.dateRange}
            isHiddenOptions={true}
            contentHeader="Filter System Margin"
            handleClose={this.closeModalDateRange}
            actionDateRange={this.actionDateRange}
          />
        </Dimmer.Dimmable>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    listSystemMargin: state.SystemMarginStore.getSystemMarginList,
    statusSystemMargin: state.SystemMarginStore.getSystemMarginListStatus,
    getCustomerProfileByCode:
      state.CustomerProfileStore.getCustomerProfileByCode,
    getCustomerProfileByCodeStatus:
      state.CustomerProfileStore.getCustomerProfileByCodeStatus,
    postSystemMarginStatus: state.SystemMarginStore.postSystemMarginStatus,
    putSystemMarginStatus: state.SystemMarginStore.putSystemMarginStatus,
    getSystemMarginById: state.SystemMarginStore.getSystemMarginById,
    getSystemMarginByIdStatus:
      state.SystemMarginStore.getSystemMarginByIdStatus,
    deleteSystemMarginStatus: state.SystemMarginStore.deleteSystemMarginStatus,
    errorsMessage: state.SystemMarginStore.errors,
    loading: state.SystemMarginStore.loading || state.PackageStore.loading,
    //loadingPackage: state.PackageStore.loading,
  };
}
export default connect(
  mapStateToProps,
  {
    getSystemMarginListAction,
    resetSystemMarginAction,
    getCustomerProfileByCodeAction,
    resetCustomerProfileAction,
    postSystemMarginAction,
    putSystemMarginAction,
    deleteSystmeMarginAction,
    getSystemMarginByIdAction,
    getTourOperatorMarginByIdAction,
    postTourOperatorSystemMarginAction,
    putTourOperatorSystemMarginAction,
    getTourOperatorProfileDetailByIdAction,
    getTourOperatorMarginByProfileIdAction,
    resetGetTourOperatorByCodeAction,
    deleteTourOperatorMarginAction,
  }
)(SystemMargin);
