import { copyObject } from './../../../../../scripts/itineraryBuilder/dailyProgram';
const inputValidation = (data, errorObject) => {
  let newError = copyObject(errorObject);
  let messageEmpty = 'Please check field, some field cannot be empty';
  newError.firstDestination.error = data.firstDestination === '';
  // newError.firstDestination.message = newError.firstDestination.error
  //   ? messageEmpty
  //   : '';
  newError.groupCapacity.error = data.groupCapacity === '';
  // newError.groupCapacity.message = newError.groupCapacity.error
  //   ? messageEmpty
  //   : '';
  newError.tourCategory.error = data.tourCategory === '';
  // newError.tourCategory.message = newError.tourCategory.error
  //   ? messageEmpty
  //   : '';
  newError.tourName.error = data.tourName === '';
  // newError.tourName.message = newError.tourName.error ? messageEmpty : '';
  newError.tourType.error = data.tourType === '';
  // newError.tourType.message = newError.tourType.error ? messageEmpty : '';

  let field =
    newError.firstDestination.error ||
    newError.groupCapacity.error ||
    newError.tourCategory.error ||
    newError.tourName.error ||
    newError.tourType.error;

  //   let date = newError.expiredDate.error;

  newError.message = field ? messageEmpty : null;
  newError.isError = field;
  return newError;
};

export { inputValidation };
