import React from 'react';
import { Dimmer } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {
  getRegionAction,
  resetRegionAction,
} from '../shared/actions/regionAction';
import {
  deleteRegionAction,
  getRegionByIdAction,
  putRegionAction,
  postRegionAction,
  resetRegionPageAction,
} from './regionAction';
import ModalAlert from './../../components/modal/modalAlert';
import Loader from '../../components/loader';
import RegionList from './components/RegionList';
import PropTypes from 'prop-types';
import ModalRegion from './components/ModalRegion';
import { getCountryListAction } from '../shared/actions/countryAction';
import ModalConfirm from '../../components/modal/modalConfirmationTwoButton';
class Region extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      regionList: [],
      regionById: {
        Id: '',
        ImageContent: '',
        ImageName: '',
        Name: '',
        CountryId: '',
        ImagePreview: '',
      },
      /* imagePreview:
                                            'https://exploregram.com/wp-content/uploads/2015/05/Pura-Lempuyang-door-Temple-in-Bali-Indonesia.jpg', */
      loadingRegion: true,
      failedRegion: false,
      modalOpen: false,
      modalRegion: false,
      modalConfirmation: false,
      headerAlert: '',
      contentAlert: '',
      errors: {},
      openConfirm: false,
      loadingModal: false,
    };
  }
  static propTypes = {
    getRegionAction: PropTypes.func,
    resetRegionAction: PropTypes.func,
    resetRegionPageAction: PropTypes.func,
    getRegionStatus: PropTypes.string,
    getRegion: PropTypes.array,
    listCountry: PropTypes.array,
    getCountryListAction: PropTypes.func,
    deleteRegionAction: PropTypes.func,
    getRegionByIdAction: PropTypes.func,
    putRegionAction: PropTypes.func,
    postRegionAction: PropTypes.func,
    getRegionByIdStatus: PropTypes.string,
    getRegionById: PropTypes.array,
    postRegionStatus: PropTypes.string,
    deleteRegionStatus: PropTypes.string,
    putRegionStatus: PropTypes.string,
    errorMessage: PropTypes.string,
    errorPageMessage: PropTypes.string,
    loading: PropTypes.bool,
    loadingPage: PropTypes.bool,
  };

  handleOpen = () => this.setState({ modalOpen: true });
  handleClose = () =>
    this.setState({ modalOpen: false, modalConfirmation: false });
  handleCloseModal = () => this.setState({ modalRegion: false });
  handleCloseConfirm = () =>
    this.setState({ openConfirm: false, loadingRegion: false });
  componentDidMount = () => {
    this.props.getRegionAction();
    this.props.getCountryListAction();
  };
  onChange = (e, { value, name }) => {
    const regionById = this.state.regionById;
    regionById[name] = value;
    this.setState({ regionById: regionById });
  };
  componentDidUpdate() {
    let errorMessage = this.props.errorMessage
      ? this.props.errorMessage
      : this.props.errorPageMessage;
    if (this.props.getRegionStatus === 'success') {
      this.setState({
        regionList: this.props.getRegion,
        loadingRegion: false,
      });
      this.props.resetRegionAction();
      this.props.resetRegionPageAction();
    } else if (this.props.getRegionStatus === 'failed') {
      this.setState({ failedRegion: true, loadingRegion: false });
      this.props.resetRegionAction();
      this.props.resetRegionPageAction();
    }
    if (this.props.getRegionByIdStatus === 'success') {
      this.setStateRegion(this.props.getRegionById);
      this.setState({
        loadingModal: false,
      });
      this.props.resetRegionAction();
      this.props.resetRegionPageAction();
    } else if (this.props.getRegionByIdStatus === 'failed') {
      this.props.resetRegionAction();
      this.props.resetRegionPageAction();
    }
    if (this.props.postRegionStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Saving Region Succesfully',
        modalConfirmation: true,
        loadingModal: false,
        modalRegion: false,
      });
      this.props.resetRegionAction();
      this.props.resetRegionPageAction();
      this.props.getRegionAction();
    } else if (this.props.postRegionStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: errorMessage,
        modalConfirmation: true,
        loadingModal: false,
      });
      this.props.resetRegionAction();
      this.props.resetRegionPageAction();
      this.props.getRegionAction();
    }
    if (this.props.putRegionStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Edit Region Succesfully',
        modalConfirmation: true,
        loadingModal: false,
        modalRegion: false,
      });
      this.props.resetRegionAction();
      this.props.resetRegionPageAction();
      this.props.getRegionAction();
    } else if (this.props.putRegionStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: errorMessage,
        modalConfirmation: true,
        loadingModal: false,
      });
      this.props.resetRegionAction();
      this.props.resetRegionPageAction();
      this.props.getRegionAction();
    }
    if (this.props.deleteRegionStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Delete Region Succesfully',
        modalConfirmation: true,
        openConfirm: false,
      });
      this.props.resetRegionAction();
      this.props.resetRegionPageAction();
      this.props.getRegionAction();
    } else if (this.props.deleteRegionStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: errorMessage,
        modalConfirmation: true,
        openConfirm: false,
      });
      this.props.resetRegionAction();
      this.props.resetRegionPageAction();
      this.props.getRegionAction();
    }
  }
  setStateRegion = data => {
    const { Id, Name, ImageUrl, CountryId } = data;
    let regionById = {
      Id: Id ? Id : '',
      ImageContent: ImageUrl ? ImageUrl : '',
      ImageName: '',
      Name: Name ? Name : '',
      CountryId: CountryId ? CountryId : '',
      ImagePreview: ImageUrl ? ImageUrl : '',
    };
    this.setState({ regionById: regionById });
  };
  newRegion = () => {
    this.setState({
      modalRegion: true,
      regionById: {
        Id: '',
        ImageContent: '',
        ImageName: '',
        Name: '',
        CountryId: '',
        ImagePreview: '',
      },
      headerModal: ' Add New Region',
      errors: {},
    });
  };
  edit = e => {
    this.props.getRegionByIdAction(e.target.value);
    this.setState({
      modalRegion: true,
      headerModal: ' Edit Region',
      errors: {},
      RegionById: {
        Id: '',
        ImageContent: '',
        ImageName: '',
        Name: '',
        CountryId: '',
        ImagePreview: '',
      },
      loadingModal: true,
    });
  };

  delete = (e, { value, name }) => {
    this.setState({
      openConfirm: true,
      regionId: value,
      contentConfirm: 'Are you sure want to delete ' + name + '?',
      loadingRegion: true,
    });
  };
  handleConfirm = () => {
    const { regionId } = this.state;
    this.props.deleteRegionAction(regionId);
    this.setState({
      openConfirm: false,
    });
  };

  saveRegion = () => {
    const err = this.validate();
    if (!err) {
      const Id = this.state.regionById.Id;
      const data = this.state.regionById;
      if (this.state.regionById.Id === '') {
        this.props.postRegionAction(data);
      } else {
        this.props.putRegionAction(Id, data);
      }
      this.setState({ loadingModal: true });
    }
  };
  validate = () => {
    let isError = false;
    const errors = {
      NameError: '',
      CountryIdError: '',
    };
    if (this.state.regionById.Name.length < 1) {
      isError = true;
      errors.Name.error = true;
      errors.NameError = "Region Name Can't Be Empty";
    }
    if (this.state.regionById.CountryId.length < 1) {
      isError = true;
      errors.CountryId.error = true;
      errors.CountryIdError = "Country Can't Be Empty";
    }
    this.setState({
      errors: {
        ...errors,
      },
    });
    return isError;
  };
  handleChangeImage = e => {
    let region = this.state.regionById;
    let reader = new FileReader();
    let files = e.target.files[0];
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      let image = {
        ...region,
        ImageName: files.name,
        ImageContent: reader.result.split(',')[1],
        ImagePreview: reader.result,
      };
      this.setState({
        regionById: image,
      });
    };
  };
  render() {
    let { loadingRegion, failedRegion, modalConfirmation } = this.state;
    let loading = loadingRegion || this.props.loading || this.props.loadingPage;
    let openModal = failedRegion || modalConfirmation;
    return (
      <div>
        <Dimmer.Dimmable blurring dimmed={loading}>
          <Loader loading={loading} />
          <RegionList
            data={this.state.regionList}
            type="regionList"
            filterBy="Name"
            buttonNew={this.newRegion}
            butttonEdit={this.edit}
            buttonDelete={this.delete}
          />
          <ModalAlert
            openModal={openModal}
            handleClose={this.handleClose}
            header={this.state.headerAlert}
            content={this.state.contentAlert}
            nameButton="OK"
          />
          <ModalRegion
            open={this.state.modalRegion}
            handleClose={this.handleCloseModal}
            header={this.state.headerModal}
            listCountry={this.props.listCountry}
            handleChange={this.onChange}
            contentField={this.state.regionById}
            saveRegion={this.saveRegion}
            imagePreview={this.state.imagePreview}
            errorInformation={this.state.errors}
            loading={this.state.loadingModal}
            handleChangeImage={this.handleChangeImage}
          />
          <ModalConfirm
            openModal={this.state.openConfirm}
            size="mini"
            close={this.handleCloseConfirm}
            confirm={this.handleConfirm}
            message={this.state.contentConfirm}
            header="Confirmation"
          />
        </Dimmer.Dimmable>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    getRegion: state.RegionStore.getRegion,
    getRegionStatus: state.RegionStore.getRegionStatus,
    listCountry: state.CountryStore.getCountryList.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    getRegionById: state.RegionPageStore.getRegionById,
    getRegionByIdStatus: state.RegionPageStore.getRegionByIdStatus,
    deleteRegionStatus: state.RegionPageStore.deleteRegionStatus,
    postRegionStatus: state.RegionPageStore.postRegionStatus,
    putRegionStatus: state.RegionPageStore.putRegionStatus,
    errorMessage: state.RegionStore.errors.global,
    errorPageMessage: state.RegionPageStore.errors.global,
    loading: state.RegionStore.loading,
    loadingPage: state.RegionPageStore.loading,
  };
}
export default connect(
  mapStateToProps,
  {
    getRegionAction,
    resetRegionAction,
    resetRegionPageAction,
    getCountryListAction,
    deleteRegionAction,
    getRegionByIdAction,
    putRegionAction,
    postRegionAction,
  }
)(Region);
