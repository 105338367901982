export const RESET_CITY_PAGE = 'RESET_CITY_PAGE';

export const DELETE_CITY = 'DELETE_CITY';
export const DELETE_CITY_FULFILLED = 'DELETE_CITY_FULFILLED';
export const DELETE_CITY_PENDING = 'DELETE_CITY_PENDING';
export const DELETE_CITY_REJECTED = 'DELETE_CITY_REJECTED';

export const POST_CITY = 'POST_CITY';
export const POST_CITY_FULFILLED = 'POST_CITY_FULFILLED';
export const POST_CITY_PENDING = 'POST_CITY_PENDING';
export const POST_CITY_REJECTED = 'POST_CITY_REJECTED';

export const PUT_CITY = 'PUT_CITY';
export const PUT_CITY_FULFILLED = 'PUT_CITY_FULFILLED';
export const PUT_CITY_PENDING = 'PUT_CITY_PENDING';
export const PUT_CITY_REJECTED = 'PUT_CITY_REJECTED';

export const GET_CITY_BY_ID = 'GET_CITY_BY_ID';
export const GET_CITY_BY_ID_FULFILLED = 'GET_CITY_BY_ID_FULFILLED';
export const GET_CITY_BY_ID_PENDING = 'GET_CITY_BY_ID_PENDING';
export const GET_CITY_BY_ID_REJECTED = 'GET_CITY_BY_ID_REJECTED';
