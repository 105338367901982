import React from 'react';
import PropTypes from 'prop-types';
import { dropdowns } from '../../../../../components/tourTransaction/funtionDailyProgram';
import { Modal, Grid, Button } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
class TimeLineAddActivity extends React.Component {
  state = { openModalActivity: false };
  openModal = (e, { name }) => {
    this.props.handleOpenModalActivities(
      name,
      this.props.movements,
      this.props.activity.Movements,
      this.props.index,
      this.props.day,
      'ADD'
    );
    this.openCloseModalActivity();
  };
  openCloseModalActivity = () => {
    const { openModalActivity } = this.state;
    this.setState({ openModalActivity: !openModalActivity });
  };
  render() {
    const { openModalActivity } = this.state;
    const { TypeTransaction } = this.props.match.params;
    const { packageType } = this.props;
    const showButtonComponent =
      (typeof this.props.isPublished !== 'undefined' &&
        this.props.isPublished === false) ||
      (TypeTransaction === 'edit' && packageType === 'QUOTATION') ||
      (TypeTransaction === 'create' && packageType !== 'QUOTATION');
    return showButtonComponent ? (
      <React.Fragment>
        <Modal
          style={{ width: '250px' }}
          open={openModalActivity}
          onClose={this.openCloseModalActivity}
          trigger={
            <Button
              content="Add New Activities"
              onClick={this.openCloseModalActivity}
              icon="plus"
              style={{
                background: '#16ab39',
                color: 'white',
                marginLeft: '7em',
                marginTop: '1em',
                marginBottom: '1em',
              }}
              disabled={this.props.isPublished}
            />
          }
        >
          <Modal.Header content="Select Activity" />
          <Modal.Content>
            {dropdowns.map((e, i) => (
              <Grid key={i}>
                <Grid.Row style={{ padding: '5px 0px', margin: '0px' }}>
                  <Grid.Column width={16}>
                    <Button
                      name={e.value}
                      onClick={this.openModal}
                      primary
                      style={{ width: '100%' }}
                    >
                      {e.title}
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            ))}
          </Modal.Content>
        </Modal>
      </React.Fragment>
    ) : (
      <div />
    );
  }
}
TimeLineAddActivity.propTypes = {
  activity: PropTypes.object,
  handleOpenModalActivities: PropTypes.func,
  openModalSetActivity: PropTypes.func,
  movements: PropTypes.array,
  index: PropTypes.number,
  day: PropTypes.number,
  packageType: PropTypes.string,
  isPublished: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      Id: PropTypes.number,
      type: PropTypes.string,
      TypeTransaction: PropTypes.string,
      PackageType: PropTypes.string,
    }),
  }),
};
export default withRouter(TimeLineAddActivity);
