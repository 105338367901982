import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Segment } from 'semantic-ui-react';
import SegmentRoomDetail from './SegmentRoomDetail';
import SegmentGuestDetail from './SegmentGuestDetail';

import SegmentFooterDetail from './SegmentFooterDetail';
import { withRouter } from 'react-router-dom';
import ModalAcceptRoom from '../../home/component/modal/ModalAcceptRoom';
import ModalRejectRoom from '../../home/component/modal/ModalRejectRoom';
import ModalInformation from '../../home/component/modal/ModalInformation';
import ModalConfirmation from '../../home/component/modal/ModalConfirmation';
import { postConfirmationNumberApi } from '../../../api/dashboardApi';
import SegmentAutoConfirm from './headerSegment/SegmentAutoConfirm';
import SegmentManualConfirm from './headerSegment/SegmentManualConfirm';
import SegmentConfirm from './headerSegment/SegmentConfirm';
import SegmentReject from './headerSegment/SegmentReject';
import ModalCancellationReason from '../../shared/components/modal/ModalCancellationReason';

const SegmentRequestDetail = props => {
  //============initial==============
  const { data, loading } = props;
  const { status } = props.match.params;
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openReject, setOpenReject] = useState(false);
  const [requestItemId, setRequestItemId] = useState('');
  const [roomName, setRoomName] = useState('');
  const [qty, setQty] = useState('');
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [openModalInformation, setOpenModalInformation] = useState(false);
  const [contentAlert, setContentAlert] = useState('');
  const [headerAlert, setHeaderAlert] = useState('');
  const [postData, setPostData] = useState({});
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [messageModal, setMessageModal] = useState('');
  const [openCancel, setOpenCancel] = useState(false);

  //===========helper=================
  const confirmAllotment = (data, isAccept) => {
    setLoadingSubmit(true);
    postConfirmationNumberApi(data)
      .then(() => {
        setLoadingSubmit(false);
        setOpenModalInformation(true);
        setHeaderAlert(
          isAccept ? 'Confirmation Success' : 'You Rejected The Request'
        );
        setContentAlert(
          isAccept
            ? data.ConfirmationNumber === null
              ? `Request ID ${data.RequestItemId} has been successfully Confirm`
              : `Request ID ${data.RequestItemId} has been successfully given a confirmation number ${data.ConfirmationNumber}`
            : `We understand your situation, thank you for responding to this request.`
          // Request ID {{RequestID}} has been
        );
      })
      .catch(err => {
        setLoadingSubmit(false);
        setOpenModalInformation(true);
        setHeaderAlert('Failed');
        setContentAlert(
          err.response.data.ModelState
            ? err.response.data.ModelState['model.ConfirmationNumber']
              ? err.response.data.ModelState['model.ConfirmationNumber']
              : err.response.data.Message
            : err.response.data.Message
        );
      });
  };

  const openModalConfirm = data => {
    if (data.ConfirmationNote !== '') {
      setMessageModal(
        `Are you sure want to reject Request Item ID ${data.RequestItemId}?`
      );
    }
    setOpenModalConfirmation(true);
  };
  //===========handler================
  const handleOpenConfirm = (e, { name, id, content }) => {
    setRequestItemId(id);
    setRoomName(name);
    setQty(content);
    setOpenConfirmation(true);
  };
  const handleClose = () => {
    setOpenConfirmation(false);
    setOpenReject(false);
    setOpenCancel(false);
    setOpenModalConfirmation(false);
  };

  const handleConfirm = data => {
    // setPostData(data);
    confirmAllotment(data, 1);
  };

  const handleReject = data => {
    setLoadingSubmit(true);
    confirmAllotment(data, 0);
  };

  // const handleOpenReject = (e, { name, id }) => {
  //   setRequestItemId(id);
  //   setRoomName(name);
  //   setOpenReject(true);
  // };

  const handleOpenCancellation = (e, { name, id }) => {
    setRequestItemId(id);
    setRoomName(name);
    setOpenCancel(true);
  };

  const handleCloseModalInformation = () => {
    setHeaderAlert('');
    setContentAlert('');
    setOpenModalInformation(false);
    props.loadData(data.RequestItemId);
    // props.history.push(`/request-room-history/detail/${id}`);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirmation(false);
  };

  const handleConfirmConfirmation = () => {
    if (postData.ActionType === 1) {
      confirmAllotment(postData, 0);
    } else {
      confirmAllotment(postData, 1);
    }
    handleClose();
  };

  const handleSaveCancellationReason = data => {
    if (data.CancellationReasonId || data.CancellationReasonNote) {
      data = {
        ...data,
        RequestItemId: requestItemId,
        ActionType: 1,
      };
    }
    setPostData(data);
    openModalConfirm(data);
  };

  return data ? (
    <Segment loading={loading || loadingSubmit}>
      <Grid columns={4}>
        {/* <SegmentHeaderDetail data={data} status={status} /> */}
        {data.Status === 'Pending' ? (
          <SegmentManualConfirm data={data} />
        ) : data.Status === 'Auto_confirmed' ? (
          <SegmentAutoConfirm data={data} />
        ) : data.Status === 'Accepted' ? (
          <SegmentConfirm data={data} />
        ) : (
          <SegmentReject data={data} />
        )}
      </Grid>
      <Grid columns={1}>
        <Grid.Row>
          <Grid.Column>
            <SegmentRoomDetail data={data} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <SegmentGuestDetail data={data} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <SegmentFooterDetail
        data={data}
        status={status}
        handleOpenConfirm={handleOpenConfirm}
        // handleOpenReject={handleOpenReject}
        handleOpenCancel={handleOpenCancellation}
      />
      <ModalAcceptRoom
        handleConfirm={handleConfirm}
        openModal={openConfirmation}
        close={handleClose}
        requestItemId={requestItemId}
        roomName={roomName}
        qty={qty}
      />
      <ModalRejectRoom
        handleReject={handleReject}
        openModal={openReject}
        close={handleClose}
        requestItemId={requestItemId}
        roomName={roomName}
        qty={qty}
      />
      <ModalInformation
        close={handleClose}
        open={openModalInformation}
        content={contentAlert}
        modalHeader={headerAlert}
        onClickButton={handleCloseModalInformation}
        size="mini"
        contentButton="OK"
      />
      <ModalConfirmation
        openModal={openModalConfirmation}
        confirm={handleConfirmConfirmation}
        close={handleCloseModalConfirm}
        message={messageModal}
      />
      <ModalCancellationReason
        openModal={openCancel}
        handleSave={handleSaveCancellationReason}
        handleClose={handleClose}
        requestItemId={requestItemId}
        roomName={roomName}
        CancellationReasonType="Partner"
      />
    </Segment>
  ) : (
    <Segment loading={true} />
  );
};

SegmentRequestDetail.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  loadData: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.string,
  }),
};

export default withRouter(React.memo(SegmentRequestDetail));
