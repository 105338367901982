export const headerTableResourceBookingFilter = [
  'Id',
  'Transaction Number',
  'Resource Booking No.',
  'Resource Name',
  'Type',
  'Due Date',
  'Total Qty',
  'Payment Status',
  'Resource Status',
  'Status',
  'Start Date',
  'End Date',
  'Owner',
];
