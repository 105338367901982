export const HeaderTransportationUnitList = [
  'Unit Name',
  'Description',
  'Rating',
  'Code',
  'Capacites',
  'Action',
];

export const HeaderTranportationPrice = [
  'Type',
  'Description',
  'Hour',
  'Price',
  'Start Date',
  'End Date',
  'Cutt Of Day',
  'Action',
];
