import React from 'react';
import PropTypes from 'prop-types';
import { Form, Grid, Header } from 'semantic-ui-react';
import InputWithLabel from '../../../components/input/inputWithLabel';
import { genderOptions } from '../constant/genderOptions';

const GeneralInformation = props => {
  return (
    <React.Fragment>
      <Header as="h4" content="General Information" />
      <Grid columns={2} padded="vertically">
        <Grid.Column>
          <Form>
            <InputWithLabel
              inline={false}
              label="Username"
              type="text"
              required
              disabled
              name="UserName"
              placeholder="Username"
              value={props.userProfile.UserName}
              handleChange={props.handleChange}
              validate={props.errorInformation}
            />
            <InputWithLabel
              inline={false}
              label="First Name"
              name="FirstName"
              placeholder="First Name"
              type="text"
              required
              value={props.userProfile.FirstName}
              handleChange={props.handleChange}
              validate={props.errorInformation}
              disabled={props.disablePage}
            />
            <InputWithLabel
              inline={false}
              label="Position/Job Title"
              type="text"
              required
              name="PositionId"
              // placeholder="Position/Job Title"
              value={
                props.userProfile.Position ? props.userProfile.Position : '-'
              }
              handleChange={props.handleChange}
              // options={props.listPositions}
              validate={props.errorInformation}
              disabled
            />
          </Form>
        </Grid.Column>
        <Grid.Column>
          <Form>
            <InputWithLabel
              inline={false}
              label="Supervisor "
              type="text"
              disabled
              name="Supervisor"
              value={props.userProfile.Supervisor}
              placeholder="Supervisor"
            />
            <InputWithLabel
              inline={false}
              placeholder="Last Name"
              name="LastName"
              label="Last Name"
              type="text"
              required
              value={props.userProfile.LastName}
              handleChange={props.handleChange}
              validate={props.errorInformation}
              disabled={props.disablePage}
            />
            <InputWithLabel
              inline={false}
              label="Gender"
              type="select"
              required
              name="Gender"
              value={props.userProfile.Gender}
              placeholder="Gender"
              options={genderOptions}
              handleChange={props.handleChange}
              validate={props.errorInformation}
              disabled={props.disablePage}
            />
            {/* <InputWithLabel
              inline={false}
              label="Account Manager "
              type="text"
              disabled
              name="AccountManager"
              value={
                props.userProfile.AccountManager
                  ? props.userProfile.AccountManager.FirstName !== null
                    ? `${props.userProfile.AccountManager.FirstName} ${
                        props.userProfile.AccountManager.LastName
                      }`
                    : '-'
                  : '-'
              }
              placeholder="Account Manager"
            /> */}
          </Form>
        </Grid.Column>
      </Grid>
    </React.Fragment>
  );
};

GeneralInformation.propTypes = {
  handleChange: PropTypes.func,
  errorInformation: PropTypes.object,
  userProfile: PropTypes.object,
  countryName: PropTypes.object,
  countryList: PropTypes.array,
  loading: PropTypes.bool,
  disablePage: PropTypes.bool,
};
export default GeneralInformation;
