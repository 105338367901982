export const headerTableResourceBooking = [
  'Resource Booking Id',
  'Resource Booking No',
  'Tour Name',
  'Package Type',
  'Start Date',
  'Action',
];

export const headerModalResourceBooking = [
  'Booking No',
  'Tour Name',
  'Package Type',
  'Action',
];
