import React, { Component } from 'react';
import { Table, Input, Header, Divider } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../../../scripts/pagination';
import FilterData from '../../../../../scripts/filterDataTable';
import { HeaderTransportationUnitList } from '../constant/headerTable';
import ButtonGroupSupplier from '../../../../../components/common/ButtonGroupSupplier';
import { Link } from 'react-router-dom';
import ActionButton from '../../../../../components/common/buttonEditAndDelete';
import Pagination from '../../../../../components/common/pagination';

class TransportationUnit extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
    };
  }
  static propTypes = {
    data: PropTypes.array,
  };
  handleChange = (e, { name, value }) => this.setState({ [name]: value });
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  render() {
    let filterData = FilterData(
      this.props.listData,
      'Name',
      this.state.searchInput
    );
    const props = this.props;
    let pagedData = datasetPagination(filterData, 5);
    let listData = pagedData[this.state.activePage - 1];

    let { numbersFrom, numbersTo } = getNumberFromTo(
      listData,
      this.state.activePage,
      5
    );
    return (
      <div>
        <Divider />
        <Header as="h4" content="Transportation Unit" />
        <Input
          icon="search"
          placeholder="search"
          name="searchInput"
          value={this.state.searchInput}
          onChange={this.handleChange}
        />
        <ButtonGroupSupplier
          buttonNew={this.props.newTransportationUnit}
          butttonExport={this.props.exportTransportationUnit}
          buttonUploadData={this.props.uploadTransportationUnit}
          buttonDownloadTemplate={this.props.templateTransportationUnit}
          disablePage={this.props.disablePage}
        />
        <Table basic="very" selectable fixed>
          <Table.Header>
            <Table.Row>
              {HeaderTransportationUnitList.map((headTableContent, index) => {
                return (
                  <Table.HeaderCell key={index}>
                    {headTableContent}
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {listData &&
              listData.map((transportationUnit, idx) => {
                return (
                  <Table.Row key={idx}>
                    <Table.Cell>
                      <Link
                        to={`/Transportation-Unit/Detail/by-ProfileId=${
                          this.props.transportationData.Id
                        }/by-Country=${
                          this.props.transportationData.AddressObject.CountryId
                        }/by-UnitId=${transportationUnit.Id}`}
                      >
                        {transportationUnit.Name}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>{transportationUnit.Description}</Table.Cell>
                    <Table.Cell>
                      {transportationUnit.TransportationRatingId}
                    </Table.Cell>
                    <Table.Cell>{transportationUnit.Code}</Table.Cell>
                    <Table.Cell>
                      {transportationUnit.TransportationSeatTypeId}
                    </Table.Cell>
                    <Table.Cell>
                      <ActionButton
                        id={transportationUnit.Id}
                        handleClickEdit={this.props.buttonEdit}
                        handleClickDelete={this.props.buttonDelete}
                        name1="Edit"
                        name2={transportationUnit.Name}
                        content1="Edit"
                        content2="Delete"
                        disablePage={this.props.disablePage}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        {/* {pagedData &&
            pagedData.length > 1 && (
              <Table.Footer> */}
        <Pagination
          // totalPages={pagedData.length}
          // onPageChange={this.pageChange}
          // activePage={this.state.activePage}
          pagedData={pagedData}
          numbersTo={numbersTo}
          numbersFrom={numbersFrom}
          data={props.listData}
          pageChange={this.pageChange}
          activePage={this.state.activePage}
        />
        {/* </Table.Footer>
            )} */}
      </div>
    );
  }
}

TransportationUnit.propTypes = {
  listData: PropTypes.array,
  addLocation: PropTypes.array,
  newTransportationUnit: PropTypes.func,
  transportationData: PropTypes.shape({
    AddressObject: PropTypes.object,
    Id: PropTypes.string,
  }),
  exportTransportationUnit: PropTypes.func,
  uploadTransportationUnit: PropTypes.func,
  templateTransportationUnit: PropTypes.func,
  buttonEdit: PropTypes.func,
  buttonDelete: PropTypes.func,
  disablePage: PropTypes.bool,
};
export default TransportationUnit;
