const copyObject = obj => {
  let newObj = JSON.stringify(obj);
  return JSON.parse(newObj);
};

const validationTourGuide = (tourGuideObject, errorFormTourGuide) => {
  let newErrorFormGuest = copyObject(errorFormTourGuide);
  newErrorFormGuest.FirstName.error = tourGuideObject.FirstName === '';
  newErrorFormGuest.LastName.error = tourGuideObject.LastName === '';
  newErrorFormGuest.IdentityNbr.error = tourGuideObject.IdentityNbr === '';
  newErrorFormGuest.NationalityId.error = tourGuideObject.NationalityId === '';
  newErrorFormGuest.RegionCoverages.error =
    tourGuideObject.RegionCoverages === [];
  newErrorFormGuest.Address1.error = tourGuideObject.Address1 === '';
  newErrorFormGuest.AreaId.error = tourGuideObject.AreaId === '';
  newErrorFormGuest.Landmark.error = tourGuideObject.Landmark === '';
  newErrorFormGuest.EmailAddress.error = tourGuideObject.EmailAddress === '';
  newErrorFormGuest.TourGuideLanguage.error =
    tourGuideObject.TourGuideLanguage.length === 0;
  newErrorFormGuest.RegionCoverages.error =
    tourGuideObject.RegionCoverages.length === 0;
  return newErrorFormGuest;
};

export { validationTourGuide };
