import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from './styles';

class TabMenu extends Component {
  render() {
    let { tabActive, handleTab, menus } = this.props;
    return (
      <Menu secondary>
        {menus.map((item, i) => (
          <Menu.Item
            key={i}
            name="tabActive"
            value={item.value}
            onClick={handleTab}
            style={
              tabActive === item.value ? styles.tabActive : styles.tabNonActive
            }
          >
            {item.title}
          </Menu.Item>
        ))}
      </Menu>
    );
  }
}
TabMenu.propTypes = {
  tabActive: PropTypes.string,
  handleTab: PropTypes.func,
  value: PropTypes.string,
  menus: PropTypes.array,
  title: PropTypes.string,
};
export default TabMenu;
