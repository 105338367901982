import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Segment, Button } from 'semantic-ui-react';
import HeaderTitle from '../../../shared/components/common/headerSegment/HeaderTitle';
import AdditionalCostSetting from './form/AdditionalCostSetting';
import AdditionalCostPrice from './form/AdditionalCostPrice';

const SegmentAdditionalCostDetail = props => {
  // --------------------- inital ---------------------
  const {
    data,
    transportationTypeList,
    countriesList,
    cityInOriginCountryList,
    cityInDestinationCountryList,
    currenciesList,
    handleBack,
    handleChangeInput,
    handleAddPrice,
    handleEditPrice,
    handleDeletePrice,
    handleSave,
    errors,
    // loading
    loading,
    additionalCostLoading,
    transportationTypeLoading,
    countriesLoading,
    cityInOriginCountryLoading,
    cityInDestinationCountryLoading,
    currenciesLoading,
    additionalCostTypeLoading,
    saveLoading,
  } = props;
  return (
    <>
      <Grid columns="equal" style={{ marginTop: '30px' }}>
        <Grid.Row>
          <Grid.Column>
            <Segment>
              <HeaderTitle title="Additional Cost Detail" />
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Button
                      floated="right"
                      primary
                      icon="save"
                      content="Save"
                      onClick={handleSave}
                      loading={saveLoading}
                    />
                    <Button
                      floated="right"
                      negative
                      icon="angle left"
                      content="Back"
                      onClick={handleBack}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <AdditionalCostSetting
                      data={data}
                      transportationTypeList={transportationTypeList}
                      countriesList={countriesList}
                      cityInOriginCountryList={cityInOriginCountryList}
                      cityInDestinationCountryList={
                        cityInDestinationCountryList
                      }
                      currenciesList={currenciesList}
                      handleChangeInput={handleChangeInput}
                      errors={errors}
                      // loading
                      loading={loading}
                      additionalCostLoading={additionalCostLoading}
                      transportationTypeLoading={transportationTypeLoading}
                      countriesLoading={countriesLoading}
                      cityInOriginCountryLoading={cityInOriginCountryLoading}
                      cityInDestinationCountryLoading={
                        cityInDestinationCountryLoading
                      }
                      currenciesLoading={currenciesLoading}
                      additionalCostTypeLoading={additionalCostTypeLoading}
                      saveLoading={saveLoading}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <AdditionalCostPrice
                      data={data}
                      currenciesList={currenciesList}
                      loading={loading}
                      handleAddPrice={handleAddPrice}
                      handleEditPrice={handleEditPrice}
                      handleDeletePrice={handleDeletePrice}
                      additionalCostLoading={additionalCostLoading}
                      saveLoading={saveLoading}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

SegmentAdditionalCostDetail.propTypes = {
  data: PropTypes.object,
  transportationTypeList: PropTypes.array,
  countriesList: PropTypes.array,
  cityInOriginCountryList: PropTypes.array,
  cityInDestinationCountryList: PropTypes.array,
  currenciesList: PropTypes.array,
  additionalCostTypeList: PropTypes.array,
  handleBack: PropTypes.func,
  loading: PropTypes.bool,
  handleChangeInput: PropTypes.func,
  handleAddPrice: PropTypes.func,
  handleEditPrice: PropTypes.func,
  handleDeletePrice: PropTypes.func,
  handleSave: PropTypes.func,
  errors: PropTypes.object,
  additionalCostLoading: PropTypes.bool,
  transportationTypeLoading: PropTypes.bool,
  countriesLoading: PropTypes.bool,
  cityInOriginCountryLoading: PropTypes.bool,
  cityInDestinationCountryLoading: PropTypes.bool,
  currenciesLoading: PropTypes.bool,
  additionalCostTypeLoading: PropTypes.bool,
  saveLoading: PropTypes.bool,
};

export default React.memo(SegmentAdditionalCostDetail);
