import { convertDateFormat } from '../../../../../scripts/moment';
const setDataTourGuide = state => {
  const data = {
    Id: state.Id,
    FirstName: state.FirstName,
    AddressId: state.AddressId,
    LastName: state.LastName,
    NationalityId: state.NationalityId,
    IdentityNbr: state.IdentityNbr,
    StartedJobAt: convertDateFormat(state.StartedJobAt, 'YYYY-MM-DD'),
    AddressObjectRequest: {
      Address1: state.Address1,
      Address2: state.Address2,
      Address3: state.Address3,
      Landmark: state.Landmark,
      PostalCode: state.PostalCode,
      AreaId: state.AreaId,
    },
    TourGuideLanguage: state.TourGuideLanguage.map(data => {
      return { LanguageId: data.TourGuideLanguage };
    }),
    RegionCoverages: state.RegionCoverages.map(data => {
      return { RegionId: data.RegionId };
    }),
    EmailAddress: state.EmailAddress,
  };

  return data;
};

export default setDataTourGuide;
