//Get Place
const GET_PLACE_LIST = 'GET_PLACE_LIST';
const GET_PLACE_LIST_FULFILLED = 'GET_PLACE_LIST_FULFILLED';
const GET_PLACE_LIST_PENDING = 'GET_PLACE_LIST_PENDING';
const GET_PLACE_LIST_REJECTED = 'GET_PLACE_LIST_REJECTED';
const RESET_PLACE = 'RESET_PLACE';
//Delete Place
const DELETE_PLACE = 'DELETE_PLACE';
const DELETE_PLACE_FULFILLED = 'DELETE_PLACE_FULFILLED';
const DELETE_PLACE_PENDING = 'DELETE_PLACE_PENDING';
const DELETE_PLACE_REJECTED = 'DELETE_PLACE_REJECTED';
//Get By ID => Place
const GET_PLACE_BY_ID = 'GET_PLACE_BY_ID';
const GET_PLACE_BY_ID_FULFILLED = 'GET_PLACE_BY_ID_FULFILLED';
const GET_PLACE_BY_ID_PENDING = 'GET_PLACE_BY_ID_PENDING';
const GET_PLACE_BY_ID_REJECTED = 'GET_PLACE_BY_ID_REJECTED';

//PUT PLACE
const PUT_PLACE = 'PUT_PLACE';
const PUT_PLACE_FULFILLED = 'PUT_PLACE_FULFILLED';
const PUT_PLACE_PENDING = 'PUT_PLACE_PENDING';
const PUT_PLACE_REJECTED = 'PUT_PLACE_REJECTED';

//PUT PLACE
const GET_PLACE_TYPE = 'GET_PLACE_TYPE';
const GET_PLACE_TYPE_FULFILLED = 'GET_PLACE_TYPE_FULFILLED';
const GET_PLACE_TYPE_PENDING = 'GET_PLACE_TYPE_PENDING';
const GET_PLACE_TYPE_REJECTED = 'GET_PLACE_TYPE_REJECTED';

//POST PLACE
const POST_PLACE = 'POST_PLACE';
const POST_PLACE_FULFILLED = 'POST_PLACE_FULFILLED';
const POST_PLACE_PENDING = 'POST_PLACE_PENDING';
const POST_PLACE_REJECTED = 'POST_PLACE_REJECTED';

//GET_PLACE_CITIES_BY_PLACE_ID
const GET_PLACE_CITIES_BY_PLACE_ID = 'GET_PLACE_CITIES_BY_PLACE_ID';
const GET_PLACE_CITIES_BY_PLACE_ID_FULFILLED =
  'GET_PLACE_CITIES_BY_PLACE_ID_FULFILLED';
const GET_PLACE_CITIES_BY_PLACE_ID_PENDING =
  'GET_PLACE_CITIES_BY_PLACE_ID_PENDING';
const GET_PLACE_CITIES_BY_PLACE_ID_REJECTED =
  'GET_PLACE_CITIES_BY_PLACE_ID_REJECTED';

//POST_PLACE_CITIES
const POST_PLACE_CITIES = 'POST_PLACE_CITIES';
const POST_PLACE_CITIES_FULFILLED = 'POST_PLACE_CITIES_FULFILLED';
const POST_PLACE_CITIES_PENDING = 'POST_PLACE_CITIES_PENDING';
const POST_PLACE_CITIES_REJECTED = 'POST_PLACE_CITIES_REJECTED';

//PUT_PLACE_CITIES
const PUT_PLACE_CITIES = 'PUT_PLACE_CITIES';
const PUT_PLACE_CITIES_FULFILLED = 'PUT_PLACE_CITIES_FULFILLED';
const PUT_PLACE_CITIES_PENDING = 'PUT_PLACE_CITIES_PENDING';
const PUT_PLACE_CITIES_REJECTED = 'PUT_PLACE_CITIES_REJECTED';

//DELETE_PLACE_CITIES
const DELETE_PLACE_CITIES = 'DELETE_PLACE_CITIES';
const DELETE_PLACE_CITIES_FULFILLED = 'DELETE_PLACE_CITIES_FULFILLED';
const DELETE_PLACE_CITIES_PENDING = 'DELETE_PLACE_CITIES_PENDING';
const DELETE_PLACE_CITIES_REJECTED = 'DELETE_PLACE_CITIES_REJECTED';

export {
  GET_PLACE_LIST,
  GET_PLACE_LIST_FULFILLED,
  GET_PLACE_LIST_PENDING,
  GET_PLACE_LIST_REJECTED,
  RESET_PLACE,
  DELETE_PLACE,
  DELETE_PLACE_FULFILLED,
  DELETE_PLACE_PENDING,
  DELETE_PLACE_REJECTED,
  GET_PLACE_BY_ID,
  GET_PLACE_BY_ID_FULFILLED,
  GET_PLACE_BY_ID_PENDING,
  GET_PLACE_BY_ID_REJECTED,
  PUT_PLACE,
  PUT_PLACE_FULFILLED,
  PUT_PLACE_PENDING,
  PUT_PLACE_REJECTED,
  GET_PLACE_TYPE,
  GET_PLACE_TYPE_FULFILLED,
  GET_PLACE_TYPE_PENDING,
  GET_PLACE_TYPE_REJECTED,
  POST_PLACE,
  POST_PLACE_FULFILLED,
  POST_PLACE_PENDING,
  POST_PLACE_REJECTED,
  GET_PLACE_CITIES_BY_PLACE_ID,
  GET_PLACE_CITIES_BY_PLACE_ID_FULFILLED,
  GET_PLACE_CITIES_BY_PLACE_ID_PENDING,
  GET_PLACE_CITIES_BY_PLACE_ID_REJECTED,
  POST_PLACE_CITIES,
  POST_PLACE_CITIES_FULFILLED,
  POST_PLACE_CITIES_PENDING,
  POST_PLACE_CITIES_REJECTED,
  PUT_PLACE_CITIES,
  PUT_PLACE_CITIES_FULFILLED,
  PUT_PLACE_CITIES_PENDING,
  PUT_PLACE_CITIES_REJECTED,
  DELETE_PLACE_CITIES,
  DELETE_PLACE_CITIES_FULFILLED,
  DELETE_PLACE_CITIES_PENDING,
  DELETE_PLACE_CITIES_REJECTED,
};
