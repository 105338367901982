const initialGuest = {
  adults: 2,
  childs: 0,
  infants: 0,
};
const initialRoom = {
  BabyCrib: 0,
  ChildExtraBedQty: 0,
  ChildSharingRoomQty: 0,
  ChildSingleRoomQty: 0,
  ExtraBedQty: 0,
  NoBed: 0,
  SharingBedQty: 0,
  SharingRoomQty: 2,
  SingleRoomQty: 0,
};

const setAllowedDayFalse = {
  Sunday: false,
  Monday: false,
  Tuesday: false,
  Wednesday: false,
  Thursday: false,
  Friday: false,
  Saturday: false,
};

const setAllowedDayTrue = {
  Sunday: true,
  Monday: true,
  Tuesday: true,
  Wednesday: true,
  Thursday: true,
  Friday: true,
  Saturday: true,
};

export { initialGuest, initialRoom, setAllowedDayFalse, setAllowedDayTrue };
