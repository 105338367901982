import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form, Grid } from 'semantic-ui-react';
import { StyleModal } from '../../../../../assets/styles/size';
import InputWithLabel from '../../common//input/InputWithLabel';
const ModalResetPassword = props => {
  return (
    <Modal
      dimmer="blurring"
      closeIcon
      inverted
      open={props.openModal}
      onClose={props.handleClose}
      size="small"
      style={StyleModal.modalWidth}
    >
      <Modal.Header>{props.header}</Modal.Header>
      <Modal.Content>
        <Grid>
          <Grid.Column>
            <Form>
              <InputWithLabel
                inline={false}
                label="Company Code"
                type="text"
                name="CompanyCode"
                placeholder="Company Code"
                fluid
                value={props.contentDetail.CompanyCode}
                disabled
              />
            </Form>
            <Form>
              <InputWithLabel
                inline={false}
                label="Username"
                type="text"
                name="UserName"
                placeholder="Username"
                fluid
                value={props.contentDetail.Username}
                disabled
              />
            </Form>
            <Form>
              <InputWithLabel
                inline={false}
                label="New Password"
                type={props.typePassword}
                required
                name="NewPassword"
                placeholder="example: 12345Ab~"
                fluid
                handleChange={props.handleChange}
                validate={props.errors}
                action={
                  <Button
                    tabIndex="2"
                    name="password"
                    icon={props.passwordIcon}
                    onClick={props.onClickPassIcon}
                    type="button"
                  />
                }
              />
            </Form>
            <Form>
              <InputWithLabel
                inline={false}
                label="Confirm Password"
                type={props.typeConfirmPassword}
                required
                name="ConfirmPassword"
                placeholder="example: 12345Ab~"
                fluid
                handleChange={props.handleChange}
                validate={props.errors}
                action={
                  <Button
                    tabIndex="2"
                    name="confirmPassword"
                    icon={props.confirmPasswordIcon}
                    onClick={props.onClickConfirmPassIcon}
                    type="button"
                  />
                }
              />
            </Form>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={props.handleClose} content="Close" />
        <Button
          icon="save"
          positive
          inverted
          onClick={props.saveResetPassword}
          content="Save"
          loading={props.loading}
        />
      </Modal.Actions>
    </Modal>
  );
};

ModalResetPassword.propTypes = {
  disabled: PropTypes.bool,
  openModal: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  handleChange: PropTypes.func,
  contentDetail: PropTypes.shape({
    CompanyCode: PropTypes.number,
    Username: PropTypes.string,
  }),
  errors: PropTypes.shape({
    NewPassword: PropTypes.string,
    ConfirmPassword: PropTypes.string,
  }),
  saveResetPassword: PropTypes.func,
  loading: PropTypes.bool,
  typePassword: PropTypes.string,
  passwordIcon: PropTypes.string,
  onClickPassIcon: PropTypes.func,
  typeConfirmPassword: PropTypes.string,
  confirmPasswordIcon: PropTypes.string,
  onClickConfirmPassIcon: PropTypes.func,
};
export default ModalResetPassword;
