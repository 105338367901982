import {
  RESET_AREA_PAGE,
  DELETE_AREA,
  PUT_AREA,
  GET_AREA_BY_ID,
  POST_AREA,
} from './constant/actionTypes';
import deleteAreaApi from '../../api/area/deleteAreaApi';
import getAreaByIdApi from '../../api/area/getAreaByIdApi';
import postAreaApi from '../../api/area/postAreaApi';
import putAreaApi from '../../api/area/putAreaApi';

export const ResetAreaPageAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_AREA_PAGE,
    });
  };
};

export const DeleteAreaAction = Id => {
  return dispatch => {
    return dispatch({
      type: DELETE_AREA,
      payload: deleteAreaApi(Id),
    });
  };
};

export const GetAreaByIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_AREA_BY_ID,
      payload: getAreaByIdApi(Id),
    });
  };
};

export const PostAreaAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_AREA,
      payload: postAreaApi(data),
    });
  };
};

export const PutAreaAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_AREA,
      payload: putAreaApi(id, data),
    });
  };
};
