const RESET_AREA_PAGE = 'RESET_AREA_PAGE';
//Get Area By City
const GET_AREA_LIST = 'GET_AREA_LIST';
const GET_AREA_LIST_FULFILLED = 'GET_AREA_LIST_FULFILLED';
const GET_AREA_LIST_PENDING = 'GET_AREA_LIST_PENDING';
const GET_AREA_LIST_REJECTED = 'GET_AREA_LIST_REJECTED';

//Delete Area By City
const DELETE_AREA = 'DELETE_AREA';
const DELETE_AREA_FULFILLED = 'DELETE_AREA_FULFILLED';
const DELETE_AREA_PENDING = 'DELETE_AREA_PENDING';
const DELETE_AREA_REJECTED = 'DELETE_AREA_REJECTED';

const GET_AREA_BY_ID = 'GET_AREA_BY_ID';
const GET_AREA_BY_ID_FULFILLED = 'GET_AREA_BY_ID_FULFILLED';
const GET_AREA_BY_ID_PENDING = 'GET_AREA_BY_ID_PENDING';
const GET_AREA_BY_ID_REJECTED = 'GET_AREA_BY_ID_REJECTED';

//POST AREA
const POST_AREA = 'POST_AREA';
const POST_AREA_FULFILLED = 'POST_AREA_FULFILLED';
const POST_AREA_PENDING = 'POST_AREA_PENDING';
const POST_AREA_REJECTED = 'POST_AREA_REJECTED';

//PUT AREA
const PUT_AREA = 'PUT_AREA';
const PUT_AREA_FULFILLED = 'PUT_AREA_FULFILLED';
const PUT_AREA_PENDING = 'PUT_AREA_PENDING';
const PUT_AREA_REJECTED = 'PUT_AREA_REJECTED';

export {
  RESET_AREA_PAGE,
  POST_AREA,
  POST_AREA_FULFILLED,
  POST_AREA_PENDING,
  POST_AREA_REJECTED,
  PUT_AREA,
  PUT_AREA_FULFILLED,
  PUT_AREA_PENDING,
  PUT_AREA_REJECTED,
  GET_AREA_LIST,
  GET_AREA_LIST_FULFILLED,
  GET_AREA_LIST_PENDING,
  GET_AREA_LIST_REJECTED,
  DELETE_AREA,
  DELETE_AREA_FULFILLED,
  DELETE_AREA_PENDING,
  DELETE_AREA_REJECTED,
  GET_AREA_BY_ID,
  GET_AREA_BY_ID_FULFILLED,
  GET_AREA_BY_ID_PENDING,
  GET_AREA_BY_ID_REJECTED,
};
