import React from 'react';
import { Table, Button } from 'semantic-ui-react';
import { ConvertSecondToHour } from '../../../../scripts/convertTime';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const TabelExcursionList = props => (
  <Table basic="very" selectable>
    <Table.Header>
      <Table.Row>
        {props.headerTable.map((headTableContent, index) => {
          return (
            <Table.HeaderCell key={index} className="center aligned">
              {headTableContent}
            </Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {props.listData && props.listData.length > 0 ? (
        props.listData.map((data, idx) => {
          return (
            <Table.Row key={idx}>
              <Table.Cell>
                <Link to={`/Excursion-Profile/Detail/${data.ServiceItemId}`}>
                  {data.Name}
                </Link>
              </Table.Cell>
              <Table.Cell>{data.Address}</Table.Cell>
              <Table.Cell>{data.AttractionType.Name}</Table.Cell>
              <Table.Cell>
                {moment(data.OperationStartTime).format('HH:mm')}
              </Table.Cell>
              <Table.Cell>
                {moment(data.OperationEndTime).format('HH:mm')}
              </Table.Cell>
              <Table.Cell>
                {ConvertSecondToHour(data.OptimumDuration)}
              </Table.Cell>
              <Table.Cell>{data.AttractionCategory}</Table.Cell>
              {props.useInResourceBookingList ? (
                <Table.Cell>
                  {data.EstimatedTotalPrice.CurrencyId}{' '}
                  {data.EstimatedTotalPrice.Price.toLocaleString('id')}
                </Table.Cell>
              ) : null}
              <Table.Cell>
                {props.useInResourceBookingList ? (
                  <Button
                    content="Choose"
                    primary
                    value={data}
                    onClick={props.handleChooseProfileForRBL}
                  />
                ) : (
                  <Button.Group>
                    <Button
                      name={data.Name + idx}
                      value={data.ServiceItemId}
                      positive
                      onClick={props.handleClickEdit}
                      content="Edit"
                    />
                    <Button.Or />
                    <Button
                      name={data.Name + idx}
                      value={data.ServiceItemId}
                      negative
                      onClick={props.handleClickDelete}
                      content="Delete"
                    />
                  </Button.Group>
                )}
              </Table.Cell>
            </Table.Row>
          );
        })
      ) : (
        <Table.Row>
          <Table.Cell colSpan={props.headerTable.length} textAlign="center">
            No Data
          </Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  </Table>
);

TabelExcursionList.propTypes = {
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  handleClickEdit: PropTypes.func,
  handleClickDelete: PropTypes.func,
  useInResourceBookingList: PropTypes.bool,
  handleChooseProfileForRBL: PropTypes.func,
};
export default TabelExcursionList;
