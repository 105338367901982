const BLACK_COLOR = '#252525';
const BLUE_COLOR = '#2962ff';
const WHITE_COLOR = '#FFF';
const RED_COLOR = '#f44336';
// const GREY_COLOR = '#f2f2f2';

const Style = {
  backgroundModal: {
    background: '#fbfbfb',
  },
  backgroundGold: {
    background: BLUE_COLOR,
    color: WHITE_COLOR,
  },
  backgroundGoldNoPadding: {
    background: BLUE_COLOR,
    padding: '0px',
  },
  backgroundCard: {
    background: WHITE_COLOR,
  },
  goldColor: {
    color: BLUE_COLOR,
    opacity: 1,
  },
  boldGoldColor: {
    color: BLUE_COLOR,
    fontWeight: 'bold',
  },
  textTransparent: {
    color: WHITE_COLOR,
    fontWeight: 'bold',
  },
  textBold: {
    fontWeight: 'bold',
  },
  justify: {
    textAlign: 'justify',
  },
  labelGold: {
    borderColor: '#b2993d',
    background: 'linear-gradient(to right, #fffd9b, #b2993d)',
    color: 'white',
    paddingBottom: '5px',
    paddingTop: '5px',
  },
  buttonGold: {
    background: BLUE_COLOR,
    color: 'black',
    borderStyle: 'solid',
    borderColor: BLUE_COLOR,
    // width: '100%',
  },
  buttonGoldNoPadding: {
    padding: '5px 0px',
    background: BLUE_COLOR,
    color: 'black',
    borderStyle: 'solid',
    borderColor: BLUE_COLOR,
  },
  buttonWhite: {
    background: WHITE_COLOR,
    color: BLUE_COLOR,
    // width: '100%',
    borderStyle: 'solid',
    borderColor: BLUE_COLOR,
  },
  buttonClose: {
    background: WHITE_COLOR,
    color: RED_COLOR,
    padding: '0px',
    margin: '0px',
  },
  buttonTransparentBlack: {
    backgroundColor: 'transparent',
    color: BLACK_COLOR,
    padding: '0px',
    margin: '0px',
  },
  imageAttachment: {
    backgroundColor: BLACK_COLOR,
    opacity: 0.5,
  },
  inputWithPaddingNine: {
    width: '100%',
    padding: '9px',
  },
  inputStyles: {
    width: '100%',
    // padding: '9px',
    // borderBottom: '2px solid lightgrey',
  },
  noMarginTop: {
    marginTop: '0px',
  },
  noMargin: {
    margin: '0px',
  },
  noPaddingTop: { paddingTop: '0px' },
  noPadding: { padding: '0px' },
  paddingLeft25px: {
    paddingLeft: '25px',
  },
  padding10px: {
    padding: '10px',
  },
  buttonTransparent: {
    background: '#fff',
    color: BLUE_COLOR,
    padding: '0px',
  },
  label: {
    width: '50%',
    marginRight: '0',
  },
  segment: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    padding: '10px 30px 10px 30px',
    margin: '5px 0px 20px 0px',
  },
  segmentNoPadding: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    margin: '5px 0px 20px 0px',
  },
  segmentSearch: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    padding: '1px 5px',
  },
  inputSelectionStyle: {
    border: 'none',
    // borderBottom: '1px solid grey',
    // borderRadius: '0px',
  },
  inputTextStyle: {
    padding: '.638500em 1em',
    // border: 'none',
    // borderBottom: '1px solid grey',
  },
  inputDateStyle: {
    width: '100% !important',
  },
  inputTimeStyle: {
    padding: '.67857143em 1em',
    // border: 'none',
    // borderBottom: '1px solid grey',
  },
  input: {
    width: '50%',
  },
  borderBottom: {
    borderBottom: '1px solid grey',
  },
  segmentInside: {
    border: 'none',
    boxShadow: '0 2px 6px 1px rgba(0,0,0,0.2)',
    padding: '30px 30px',
    margin: '5px 0px 20px 0px',
  },
  textCenter: {
    textAlign: 'center',
  },
  primaryColor: {
    color: '#000',
  },
  paddingLeft20: {
    paddingLeft: '20px',
  },
  paddingRight20: {
    paddingRight: '20px',
  },
  messageGold: {
    border: '1px solid rgba(230, 202, 107)',
    background: 'rgba(230, 202, 107,0.3)',
  },
  marginTopBottom10: { margin: '10px 0px' },
  marginTop3: {
    marginTop: '3px',
  },
  marginTopMin10: { marginTop: '-10px' },
  width100percent: {
    width: '100%',
  },
};
export default Style;
