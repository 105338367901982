import React, { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Segment,
  Grid,
  Button,
  // Form,
  Header,
  Divider,
} from 'semantic-ui-react';
// import Input from '../../shared/components/common/input/InputWithLabel';
import { Collapse } from 'react-collapse';
import CollapseButton from '../../../components/button/collapseButton';
import { copyObject } from '../../../scripts/itineraryBuilder/itineraryBuilder';
import { convertDateFormat } from '../../../scripts/itineraryBuilder/timeHelper';
import ModalConfirm from '../../../components/modal/modalConfirmationTwoButton';
import { setStringPaymentStatus } from '../scripts/resourceBookingDetail';
import { ucwords } from '../../../scripts/convertWord';
const BookingItem = props => {
  //==========initial state=========
  const [listOpenBookingItem, setListOpenBookingItem] = useState([]);
  const [listOpenPaymentReq, setListOpenPaymentReq] = useState([]);
  const [selectedBookingItem, setSelectedBookingItem] = useState({ idx: null });
  const [modalConfirm, setModalConfirm] = useState({
    isOpen: false,
    type: '',
    message: '',
    header: '',
  });
  //========== End initial state=========
  // ========== action ===============
  // ========== end action ===========
  // ========== reducer ========
  // ============ END REDUCER ==============
  const handleCollapse = (e, { name, value }) => {
    let newList = [...listOpenBookingItem];
    if (value) {
      // eslint-disable-next-line
      let idx = newList.findIndex(e => e == name);
      newList.splice(idx, 1);
    } else {
      newList.push(name);
    }
    setListOpenBookingItem(newList);
  };
  const handleCollapsePaymentReq = (e, { name, value }) => {
    let newList = [...listOpenPaymentReq];
    if (value) {
      // eslint-disable-next-line
      let idx = newList.findIndex(e => e == name);
      newList.splice(idx, 1);
    } else {
      newList.push(name);
    }
    setListOpenPaymentReq(newList);
  };
  // handle booking item
  const handleBookingItem = (e, { value, name, objectname }) => {
    let newListBookingItem = copyObject(props.listBookingItem);
    // if (name === 'IsDelete')
    //   newListBookingItem[objectname].IsDelete = value;
    // else
    if (name === 'IsDelete') {
      setSelectedBookingItem({ idx: objectname });
      openModalConfirm(
        'DELETE',
        'Confirmation',
        'Are you sure you want to delete the reservation?'
      );
    } else {
      newListBookingItem[objectname][name] = value;
      props.handleListBookingItem(newListBookingItem);
    }
  };
  const openModalConfirm = (type, header, message) => {
    setModalConfirm({
      isOpen: true,
      type,
      message,
      header,
    });
  };
  const closeModalConfirm = () => {
    setModalConfirm({
      isOpen: false,
      type: '',
      message: '',
      header: '',
    });
  };
  const handleModalConfirm = (e, { name }) => {
    closeModalConfirm();
    if (name === 'DELETE') {
      let newListBookingItem = copyObject(props.listBookingItem);
      newListBookingItem[selectedBookingItem.idx].IsDelete = true;
      props.handleListBookingItem(newListBookingItem);
    }
  };
  // end handle booking item
  // const convertStatusPayment = status => {
  //   return status === 0 || status === 'UNPAID'
  //     ? 'Unpaid'
  //     : status === 1
  //     ? 'Partial Payment'
  //     : status === 2
  //     ? 'Full Payment'
  //     : status;
  // };
  let {
    listBookingItem,
    handleOpenModalPaymentReq,
    disablePage,
    handleEditBookingItem,
  } = props;
  let list = listBookingItem ? listBookingItem.filter(e => !e.IsDelete) : [];
  return (
    <Grid.Column width={16}>
      {list && list.length !== 0 ? (
        list.map((e, idx) => (
          <Segment key={idx}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={14}>
                  <Header
                    as="h4"
                    content={
                      e.Id ? `Booking Item #${e.Id}` : 'New Booking Item'
                    }
                  />
                </Grid.Column>
                <Grid.Column width={2}>
                  <CollapseButton
                    handleCollapse={handleCollapse}
                    style={{ boxShadow: '0 0 0 transparent' }}
                    name={idx}
                    isCollapse={
                      listOpenBookingItem.find(e => e === idx) !== undefined
                    }
                  />
                </Grid.Column>
                <Grid.Column width={16}>
                  <Divider />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Collapse
              isOpened={listOpenBookingItem.find(e => e === idx) !== undefined}
              style={{ width: '100%', padding: '0px 15px' }}
            >
              <Grid>
                <Grid.Row>
                  <Grid.Column width={10} style={{ paddingBottom: '20px' }}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={7}>Qty</Grid.Column>
                        <Grid.Column width={1}>:</Grid.Column>
                        <Grid.Column width={8}>{e.Qty}</Grid.Column>
                        <Grid.Column width={7}>Status</Grid.Column>
                        <Grid.Column width={1}>:</Grid.Column>
                        <Grid.Column width={8}>
                          {e.ResourceStatus === 'CREATED'
                            ? 'Created'
                            : e.ResourceStatus === 'PARTIALBOOKING'
                            ? 'Partial booking'
                            : 'Full booked'}
                        </Grid.Column>
                        <Grid.Column width={7}>Supplier ref no.</Grid.Column>
                        <Grid.Column width={1}>:</Grid.Column>
                        <Grid.Column width={8}>{e.SupplierRefNo}</Grid.Column>
                        <Grid.Column width={7}>Note</Grid.Column>
                        <Grid.Column width={1}>:</Grid.Column>
                        <Grid.Column width={8}>{e.Note}</Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column width={6} textAlign="right">
                    <Button
                      content="Delete"
                      value={!e.IsDelete}
                      name="IsDelete"
                      negative
                      onClick={handleBookingItem}
                      objectname={idx}
                      disabled={
                        disablePage || e.ResourceStatus === 'FULLBOOKED'
                      }
                    />
                    <Button
                      content="Edit"
                      value={{ ...e, Idx: idx }}
                      name="Edit"
                      positive
                      onClick={handleEditBookingItem}
                      disabled={
                        disablePage || e.ResourceStatus === 'FULLBOOKED'
                      }
                    />
                    <Button
                      content={
                        e.PaymetRequestDetails &&
                        e.PaymetRequestDetails.length > 0
                          ? 'Edit Payment Request'
                          : 'Payment Request'
                      }
                      primary
                      value={{ ...e, Idx: idx }}
                      onClick={handleOpenModalPaymentReq}
                      disabled={
                        disablePage || e.ResourceStatus !== 'FULLBOOKED'
                      }
                      // title={
                      //   true
                      //     ? 'You can make a payment request if the booking item status is full booked'
                      //     : null
                      // }
                    />
                  </Grid.Column>
                  <Grid.Column width={14}>
                    <Header as="h4" content="Payment Request Detail" />
                  </Grid.Column>
                  <Grid.Column width={2}>
                    <CollapseButton
                      handleCollapse={handleCollapsePaymentReq}
                      style={{ boxShadow: '0 0 0 transparent' }}
                      name={idx}
                      isCollapse={
                        listOpenPaymentReq.find(e => e === idx) !== undefined
                      }
                    />
                  </Grid.Column>
                  <Grid.Column width={16}>
                    <Divider />
                  </Grid.Column>
                  <Grid.Column width={16}>
                    <Collapse
                      isOpened={
                        listOpenPaymentReq.find(e => e === idx) !== undefined
                      }
                      style={{ width: '100%', padding: '0px 15px' }}
                    >
                      {e.PaymetRequestDetails &&
                      e.PaymetRequestDetails.length !== 0 ? (
                        <Grid>
                          <Grid.Row>
                            <Grid.Column width={8}>
                              <Grid>
                                <Grid.Row>
                                  <Grid.Column width={5}>
                                    Payment request no
                                  </Grid.Column>
                                  <Grid.Column width={11}>
                                    : {e.PaymetRequestDetails[0].Id}
                                  </Grid.Column>
                                  <Grid.Column width={5}>
                                    Request date
                                  </Grid.Column>
                                  <Grid.Column width={11}>
                                    :{' '}
                                    {convertDateFormat(
                                      e.PaymetRequestDetails[0].Date,
                                      'DD MMM YYYY'
                                    )}
                                  </Grid.Column>
                                  <Grid.Column width={5}>Due date</Grid.Column>
                                  <Grid.Column width={11}>
                                    :{' '}
                                    {convertDateFormat(
                                      e.PaymetRequestDetails[0].DueDate,
                                      'DD MMM YYYY'
                                    )}
                                  </Grid.Column>
                                  <Grid.Column width={5}>
                                    Total cost
                                  </Grid.Column>
                                  <Grid.Column width={11}>
                                    : {e.PaymetRequestDetails[0].Currency}{' '}
                                    {e.PaymetRequestDetails[0].Value.toLocaleString(
                                      'id'
                                    )}
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Grid>
                                <Grid.Row>
                                  <Grid.Column width={5}>Status</Grid.Column>
                                  <Grid.Column width={1}>:</Grid.Column>
                                  <Grid.Column width={9}>
                                    {setStringPaymentStatus(
                                      e.PaymetRequestDetails[0].PaymentStatus
                                    )}
                                  </Grid.Column>
                                  <Grid.Column width={5}>Priority</Grid.Column>
                                  <Grid.Column width={1}>:</Grid.Column>
                                  <Grid.Column width={9}>
                                    {ucwords(
                                      e.PaymetRequestDetails[0].Priority
                                    )}
                                  </Grid.Column>
                                  <Grid.Column width={5}>Note</Grid.Column>
                                  <Grid.Column width={1}>:</Grid.Column>
                                  <Grid.Column width={9}>
                                    {e.PaymetRequestDetails[0].Note}
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      ) : (
                        'No Payment Request'
                      )}
                    </Collapse>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Collapse>
            <ModalConfirm
              openModal={modalConfirm.isOpen}
              size="mini"
              close={closeModalConfirm}
              confirm={handleModalConfirm}
              message={modalConfirm.message}
              nameConfirm={modalConfirm.type}
              header={modalConfirm.header}
            />
          </Segment>
        ))
      ) : (
        <Segment>There are no item orders</Segment>
      )}
    </Grid.Column>
  );
};

BookingItem.propTypes = {
  listBookingItem: PropTypes.array,
  handleOpenModalPaymentReq: PropTypes.func,
  handleListBookingItem: PropTypes.func,
  disablePage: PropTypes.func,
  handleEditBookingItem: PropTypes.func,
};

export default BookingItem;
