import React from 'react';
import { useState } from 'react';
// import { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './Styles';
import { Grid, Segment, Header, Icon, Button } from 'semantic-ui-react';
import CollapseButton from '../../../../../components/button/collapseButton';
import { Collapse } from 'react-collapse';
import AdditionalServiceForm from './AdditionalServiceForm';
import AdditionalServiceHeader from './AdditionalServicesHeader';
import ModalAddAdditionalServices from './modal/ModalAddAdditionalServices';
import { getAdditionalServicesListTransactionApi } from '../../../../../api/additionalServices/additionalServicesApi';
import {
  getIndexofInArrayObject,
  getCountryFromArray,
  filterAdditionalServicesBySelected,
  filterAdditionalServicesByArrayId,
} from '../../../../shared/services/additionalServices/processData';

const SegmentAdditionalServices = props => {
  // --------------- initial ---------------
  const {
    selectedAdditionalServices,
    headlineProgram,
    setAdditionalServices,
    handleIncrease,
    handleDecrease,
    currentPackage,
    companyProfile,
    roomPrices,
    packageById,
    loadingDemoPrice,
    handleChangeAmount,
  } = props;

  const [openModal, setOpenModal] = useState(false);
  const [additionalServicesList, setAdditionalServicesList] = useState([]);
  const [loadingModal, setLoadingModal] = useState(false);
  // const [selectedAdditionalServices, setSelectedAdditionalServices] = useState(
  //   []
  // );
  const [openCollapse, setOpenCollapse] = useState(true);

  // --------------- helper ---------------
  const getAdditionalServiceFromApi = async (data, idx = 0, res = []) => {
    if (idx >= data.length) return res;
    else if (data.length) {
      setLoadingModal(true);
      let resData = [...res];
      await getAdditionalServicesListTransactionApi(
        data[idx].from,
        data[idx].to,
        packageById.BookingDetailSum &&
          packageById.BookingDetailSum.PackageType === 'FixedDateVariable'
          ? roomPrices.CurrencyId
          : companyProfile.Currency
      )
        .then(result => {
          // let listDataRes = result.data;
          let listDataRes =
            data[idx].from === 'DEF'
              ? result.data.map(i => {
                  return {
                    ...i,
                    OriginalCountries: i.OriginalCountries.filter(
                      j => j.Value === 'DEF'
                    ),
                  };
                })
              : result.data;
          resData = resData.concat(listDataRes);
          setLoadingModal(false);
        })
        .catch(() => setLoadingModal(false));
      return getAdditionalServiceFromApi(data, idx + 1, resData);
    }
  };

  // --------------- handler ---------------
  const handleClose = () => setOpenModal(false);

  const handleOpenModal = () => {
    setOpenModal(true);
    if (headlineProgram && headlineProgram.MainPrograms.City !== null) {
      let resultGetApi = getAdditionalServiceFromApi(getCountry);
      resultGetApi &&
        resultGetApi.then(items => {
          let selected = [...selectedAdditionalServices];
          let addServ = [...items];
          let newAdditionalServicesList = filterAdditionalServicesBySelected(
            addServ,
            selected
          );
          setAdditionalServicesList(newAdditionalServicesList);
        });
    }
  };

  // arrayId is additional services id's selected in modal
  const handleSave = arrayId => {
    let selected = [...selectedAdditionalServices];
    let addServ = [...additionalServicesList];
    let filtered = filterAdditionalServicesByArrayId(addServ, arrayId);
    let newSelected = filtered.reduce((res, cur) => {
      let item = {
        AdditionalServiceId: cur.Id,
        Quantity: 0,
        AdditionalServiceName: cur.AdditionalServicesName,
        Amount: cur.OriginalCountries[0].Amount,
        Currency: cur.OriginalCountries[0].Currency,
        OriginalCountryId: cur.OriginalCountries[0].OriginalCountryId,
        IsMandatory: false,
        DestinationCountry: cur.DestinationCountry.Text,
        DestinationCountryId: cur.DestinationCountry.Value,
      };
      res.push(item);
      return res;
    }, []);
    selected = selected.concat(newSelected);
    // setSelectedAdditionalServices(selected);
    setAdditionalServices(selected);
    setOpenModal(false);
  };

  const handleDelete = e => {
    let newSelected = [...selectedAdditionalServices];
    const id = parseInt(e.target.id);
    let index = getIndexofInArrayObject(newSelected, 'AdditionalServiceId', id);
    newSelected.splice(index, 1);
    setAdditionalServices(newSelected);
    // setSelectedAdditionalServices(newSelected);
  };

  const handleCollapse = () => {
    setOpenCollapse(!openCollapse);
  };

  // --------------- variable ---------------
  let resultFilter =
    headlineProgram &&
    headlineProgram.MainPrograms.filter(
      item => typeof item.AccommodationSummary !== 'undefined'
    );
  let getCountry = getCountryFromArray(resultFilter);
  let isShowButton =
    (currentPackage.type === 'Quotation' ||
      currentPackage.status === 'create') &&
    currentPackage.type !== 'Fixed' &&
    !currentPackage.disabledEdit;

  return (
    <>
      <Segment raised fluid loading={loadingDemoPrice}>
        <div style={styles.marginContentInsideCard}>
          <Grid verticalAlign="middle">
            <Grid.Row>
              <Grid.Column width={14}>
                <Header as="h3" style={styles.marginHeader}>
                  <Icon name="clipboard list" style={{ color: '#38af95' }} />
                  Additional Services
                </Header>
              </Grid.Column>
              <Grid.Column width={2} textAlign="left">
                <CollapseButton
                  isCollapse={openCollapse}
                  name="openCollapse"
                  handleCollapse={handleCollapse}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Collapse isOpened={openCollapse} style={{ margin: '0px' }}>
            <Segment.Group style={styles.noBorder}>
              <Segment style={styles.noBorder}>
                <AdditionalServiceHeader
                  data={selectedAdditionalServices}
                  isShowButton={isShowButton}
                />
                <AdditionalServiceForm
                  data={selectedAdditionalServices}
                  handleDelete={handleDelete}
                  handleIncrease={handleIncrease}
                  handleDecrease={handleDecrease}
                  currentPackage={currentPackage}
                  handleChangeAmount={handleChangeAmount}
                  isAcceptedQuotation={props.isAcceptedQuotation}
                />
                {isShowButton ? (
                  <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                        primary
                        content={
                          selectedAdditionalServices.length
                            ? 'Add more additional service'
                            : 'Add additional service'
                        }
                        onClick={handleOpenModal}
                      />
                    </Grid.Column>
                  </Grid>
                ) : null}
              </Segment>
            </Segment.Group>
          </Collapse>
        </div>
      </Segment>

      {/* modal add additional service */}
      <ModalAddAdditionalServices
        open={openModal}
        onClose={handleClose}
        loading={loadingModal}
        data={additionalServicesList}
        handleSave={handleSave}
      />
    </>
  );
};

SegmentAdditionalServices.propTypes = {
  headlineProgram: PropTypes.object,
  setAdditionalServices: PropTypes.func,
  handleChangeAmount: PropTypes.func,
  handleIncrease: PropTypes.func,
  handleDecrease: PropTypes.func,
  selectedAdditionalServices: PropTypes.array,
  currentPackage: PropTypes.object,
  guests: PropTypes.array,
  companyProfile: PropTypes.object,
  roomPrices: PropTypes.array,
  packageById: PropTypes.object,
  loadingDemoPrice: PropTypes.bool,
  isAcceptedQuotation: PropTypes.bool,
};

export default React.memo(SegmentAdditionalServices);
