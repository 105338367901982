export const headerTableAdditionalServicesList = [
  'Additional Services Name',
  'Destination',
  'Type',
  'Description',
  'Action',
];

export const headerTableOriginCountry = [
  'Origin Country',
  'Currency',
  'Price',
  'Action',
];

export const headerTablePrice = ['Currency', 'Price', 'Action'];
