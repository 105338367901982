import React, { Component } from 'react';
import { Table, Button, Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import IndexPagination from '../../../../components/genericFunction/indexPagination';
import { Style } from '../../../../assets/styles/styleAccomodation';
import { StyleButton } from '../../../../components/common/styleCommon';
import moment from 'moment';
import Pagination from '../../../../components/common/pagination';
import { getNumberFromTo } from '../../../../scripts/pagination';
class TableContractingFile extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
      // pageLength: 3,
      // status: false,
    };
  }
  static propTypes = {
    data: PropTypes.array,
  };
  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  // setDefaultActivePage = () => {
  //   this.setState({
  //     status: false,
  //     activePage: 1,
  //   });
  // };
  render() {
    const props = this.props;

    let { numbersFrom, numbersTo } = getNumberFromTo(
      props.listData,
      props.activePage,
      3
    );
    return (
      <div style={Style.marginTable}>
        <Table basic="very" selectable fixed>
          <Table.Header>
            <Table.Row>
              {this.props.headerTable.map((headTableContent, index) => {
                return (
                  <Table.HeaderCell key={index}>
                    {headTableContent}
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.props.listData ? (
              this.props.listData.map((data, idx) => {
                const idxPagination = IndexPagination(
                  3,
                  this.props.activePage,
                  idx
                );
                return (
                  <Table.Row key={idxPagination}>
                    <Table.Cell>{data.FileName}</Table.Cell>
                    <Table.Cell>
                      {moment(data.StartDate).format('ll') +
                        ' - ' +
                        moment(data.EndDate).format('ll')}
                    </Table.Cell>
                    <Table.Cell>{data.Description}</Table.Cell>
                    <Table.Cell>
                      <Popup
                        content={' Preview'}
                        trigger={
                          <Button
                            compact
                            icon="eye"
                            style={StyleButton.buttonTransparent}
                            onClick={this.props.viewContracting}
                            id={data.FileUrl}
                            disabled={this.props.disabled}
                          />
                        }
                      />
                      <Popup
                        content={'Delete File'}
                        trigger={
                          <Button
                            compact
                            icon="delete"
                            style={StyleButton.buttonTransparentRed}
                            onClick={this.props.deleteContracting}
                            value={data.FileId}
                            name={data.FileName}
                            disabled={this.props.disabled}
                            id={idx}
                          />
                        }
                      />

                      {/* <Button
                        content="Delete"
                        negative
                        value={this.props.value} //id={idx}
                        //value={data.Id}
                        id={data.FacilityId || data.LocationId || data.Id}
                        name={idxPagination} //name={data.Id}
                        onClick={this.props.deleteState}
                        disabled={this.props.disabled}
                      /> */}
                    </Table.Cell>
                  </Table.Row>
                );
              })
            ) : (
              <Table.Row>
                <Table.Cell colSpan="4" textAlign="center">
                  No Data
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>

        <Pagination
          pagedData={props.pagedData}
          numbersTo={numbersTo}
          numbersFrom={numbersFrom}
          data={props.contractingFileList}
          pageChange={props.pageChange}
          activePage={props.activePage}
        />
      </div>
    );
  }
}

TableContractingFile.propTypes = {
  contractingFileList: PropTypes.array,
  listData: PropTypes.array,
  deleteState: PropTypes.func,
  headerTable: PropTypes.array,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  activePage: PropTypes.string,
  pageAddingData: PropTypes.array,
  pageChange: PropTypes.func,
  deleteContracting: PropTypes.func,
  viewContracting: PropTypes.func,
  downloadContracting: PropTypes.func,
};
export default TableContractingFile;
