import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Grid,
  Segment,
  Form,
  Button,
  Divider,
  Message,
} from 'semantic-ui-react';
// import PropTypes from 'prop-types';
import HeaderTitle from '../shared/components/common/headerSegment/HeaderTitle';
import styles from './style';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ButtonWithIcon from '../shared/components/common/button/ButtonWithIcon';
import AccountsummaryTable from './components/AccountsummaryTable';
import { datasetPagination, getNumberFromTo } from '../../scripts/pagination';
import Pagination from '../../components/common/pagination';
import { getDateFromTo } from './service/helper/dateHelper';
import {
  getGenerateARSummaryAction,
  getARSummaryDownloadAction,
  getARSummaryListAction,
} from './accountStatementAction';
import { getAccountStatementAction } from '../home/homeAction';
import InputWithLabel from '../shared/components/common/input/InputWithLabel';
import TotalBalanceByCurrencyTable from './components/TotalBalanceByCurrencyTable';
import { viewDate, viewTime } from '../../scripts/itineraryBuilder/timeHelper';
const AccountStatementSummaryPage = () => {
  //===============initial====================
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [lastModifiedDate, setLastModifiedDate] = useState(startDate);
  const [activePage, setActivePage] = useState(1);
  const [summaryData, setSummaryData] = useState([]);
  const [balanceTotal, setBalanceTotal] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [loadingDownloadSummary, setLoadingDownloadSummary] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  // const accountRecievableStore = useSelector(state => {
  //   return state.ARstatementStore;
  // });
  //==============actions=====================
  const dispatch = useDispatch();
  const getGenerateARSummary = () => dispatch(getGenerateARSummaryAction());
  const downloadARsummary = (year, month) =>
    dispatch(getARSummaryDownloadAction(year, month));
  const getARSummaryList = (year, month) =>
    dispatch(getARSummaryListAction(year, month));
  const getAccountStatementCompany = (companyCode, from, to) =>
    dispatch(getAccountStatementAction(companyCode, from, to));
  //==============lifeCycle===================
  useEffect(() => {
    const hostname = window && window.location && window.location.hostname;
    if (hostname === 'admin.touress.com') {
      helperGetSummaryData();
    } else {
      setLoadingList(true);
      getGenerateARSummary()
        .then(() => {
          helperGetSummaryData();
        })
        .catch(() => helperGetSummaryData());
    }

    // eslint-disable-next-line
  }, []);
  //==============helper====================
  const helperGetSummaryData = (year, month) => {
    let newYearParam = year ? year : new Date().getFullYear();
    let newMonthParam = month ? month : new Date().getMonth() + 1;
    setLoadingList(true);
    getARSummaryList(newYearParam, newMonthParam)
      .then(res => {
        setSummaryData(res.value.data.ARStatementSummaryListData);
        setBalanceTotal(res.value.data.BalanceTotals);
        setLastModifiedDate(res.value.data.LastModifiedDate);
        setLoadingList(false);
      })
      .catch(() => {
        setLoadingList(false);
      });
  };
  const helperGetYearAndMonth = date => {
    let newDate = date ? new Date(date) : new Date();
    let year = newDate.getFullYear();
    let month = newDate.getMonth() + 1;
    return {
      year: year,
      month: month,
    };
  };
  const helperFilterData = () => {
    return summaryData.filter(data => {
      return (
        data.Company.Text.toLowerCase().indexOf(searchInput.toLowerCase()) !==
        -1
      );
    });
  };
  //===============handler====================
  const pageChange = (e, { activePage }) => {
    setActivePage(activePage);
  };
  const handleChangeDate = date => {
    setStartDate(date);
    const dateFromTo = getDateFromTo(date);
    setLastModifiedDate(dateFromTo.To);
  };
  const handleDownloadSummaryReport = () => {
    let { year, month } = helperGetYearAndMonth(startDate);
    setLoadingDownloadSummary(true);
    downloadARsummary(year, month)
      .then(res => {
        setLoadingDownloadSummary(false);
        window.open(res.value.data.UrlLink);
      })
      .catch(() => {
        setLoadingDownloadSummary(false);
      });
  };
  const handleDownloadDetailReport = companyCode => {
    let { from, to } = getDateFromTo(startDate);
    getAccountStatementCompany(companyCode, from, to).then(res => {
      window.open(res.value.data);
    });
  };
  const handleSubmitGetARSummary = () => {
    let { year, month } = helperGetYearAndMonth(startDate);
    helperGetSummaryData(year, month);
  };
  const handleChangeSearchInput = (e, { value }) => {
    setSearchInput(value);
    setActivePage(1);
  };
  //===========================================
  let filteredData = helperFilterData();
  let pagedData = datasetPagination(filteredData, 7);
  let listData = pagedData[activePage - 1];
  let { numbersFrom, numbersTo } = getNumberFromTo(listData, activePage, 7);
  //===========================================

  return (
    <Grid style={styles.marginTop30}>
      <Grid.Row stretched>
        <Grid.Column width={15}>
          <Segment>
            <HeaderTitle title="Account Statement Summary" />
            <Grid>
              <Grid.Row>
                <Grid.Column width="8">
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width="6">
                        <Form>
                          <Form.Field>
                            <label>Period (Month / Year)</label>
                            <DatePicker
                              selected={startDate}
                              onChange={date => handleChangeDate(date)}
                              dateFormat="MM/yyyy"
                              showMonthYearPicker
                            />
                          </Form.Field>
                        </Form>
                      </Grid.Column>
                      <Grid.Column width="10" style={styles.noPaddingLeft}>
                        <Button
                          style={{
                            ...styles.marginTopForbutton,
                            ...styles.marginRight14,
                          }}
                          onClick={handleSubmitGetARSummary}
                          positive
                        >
                          Submit
                        </Button>
                        <ButtonWithIcon
                          text="Download Report"
                          iconName="download"
                          primary={true}
                          style={styles.marginTopForbutton}
                          handleOnClick={handleDownloadSummaryReport}
                          loading={loadingDownloadSummary}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width="16">
                        <Message
                          content={
                            lastModifiedDate
                              ? `Data is taken from ${viewDate(
                                  startDate
                                )} ${viewTime(startDate)} to ${viewDate(
                                  lastModifiedDate
                                )} ${viewTime(lastModifiedDate)}`
                              : 'Data is not available for the selected period'
                          }
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>

                <Grid.Column width="8">
                  <label>Total Balance By Currency</label>
                  <TotalBalanceByCurrencyTable
                    listData={balanceTotal}
                    loading={loadingList}
                  />
                </Grid.Column>
              </Grid.Row>
              <Divider />
              <Grid.Row>
                <Grid.Column width="4">
                  <Form>
                    <Form.Field>
                      <InputWithLabel
                        label="Search"
                        placeholder="Search by Company"
                        name="searchInput"
                        value={searchInput}
                        handleChange={handleChangeSearchInput}
                      />
                    </Form.Field>
                  </Form>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width="16">
                  <AccountsummaryTable
                    listData={listData}
                    handleAction={handleDownloadDetailReport}
                    loading={loadingList}
                  />
                  <Pagination
                    pagedData={pagedData}
                    numbersTo={numbersTo}
                    numbersFrom={numbersFrom}
                    data={summaryData}
                    pageChange={pageChange}
                    activePage={activePage}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default AccountStatementSummaryPage;
