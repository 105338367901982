const setDataSaveModalPrice = state => {
  const data = {
    Id: state.Id,
    CutOffDay: state.CutOffDay,
    Hours: state.Hours,
    Cost: state.Cost,
    StartDate: state.StartDate,
    EndDate: state.EndDate,
    UomId: 'PAX',
    TaxTypeId: 7,
    Currency: state.Currency,
    Rank: 1,
    ServiceItemId: state.ServiceItemId > 0 ? state.ServiceItemId : 0,
    ServicePriceType: 501,
    MinimumPax: 0,
    Description: '',
    TourGuideLanguage: state.TourGuideLanguage,
    LanguageId: state.TourGuideLanguage,
    Language: {
      EnglishName: state.Language.text,
      LocalName: state.Language.key,
    },
    postStatus: state.postStatus,
    Action: state.postStatus ? 2 : 0,
  };
  return data;
};

export default setDataSaveModalPrice;
