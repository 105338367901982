import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Grid } from 'semantic-ui-react';
import ButtonClose from '../../button/buttonCloseModal';
import styles from './../styles';
import {
  getExcursionTypeAction,
  resetExcursionAction,
} from '../../../pages/shared/actions/excursionAction';
import Filter from '../modalSelectionMasterData/formFilter';
import ListCard from './listCard';
const listExcursionCategory = [
  { key: 'package', text: 'Fixed Timing', checked: false },
  { key: 'single', text: 'Flexible Timming', checked: false },
];
class modalListExcursion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formFilter: [],
      activePage: 1,
      searchName: '',
    };
  }
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    name: PropTypes.string,
    data: PropTypes.array,
    location: PropTypes.string,
    resetExcursionAction: PropTypes.func,
    listExcursionType: PropTypes.array,
    excursionTypeStatus: PropTypes.string,
    loading: PropTypes.bool,
    loadingExcursionByFilter: PropTypes.bool,
    setExcursion: PropTypes.func,
    excursionTypeList: PropTypes.array,
    getExcursionTypesStatus: PropTypes.string,
  };

  componentDidUpdate = prevProps => {
    if (this.props.getExcursionTypesStatus === 'success') {
      let data = [
        { name: 'Excursion Category', data: listExcursionCategory },
        {
          name: 'Excursion Type',
          data: this.showOptions(this.props.excursionTypeList),
        },
      ];
      this.setState({
        ...this.state,
        formFilter: data,
      });
      this.props.resetExcursionAction();
    }
    if (this.props.open !== prevProps.open) {
      this.setState({
        ...this.state,
        activePage: 1,
        searchName: '',
      });
    }
  };

  showOptions = data => {
    return data.map(e => {
      return { key: e.Id, text: e.Name, checked: false };
    });
  };
  filterExcursion = (data, formFilter, searchName) => {
    let dataFilter, indexForm;
    return data.filter(data => {
      let { AttractionCategory, AttractionType } = data;
      dataFilter = true;
      if (searchName) {
        dataFilter =
          dataFilter &&
          data.Name.toLowerCase().indexOf(searchName.toLowerCase()) !== -1;
      }
      indexForm = formFilter.findIndex(e => e.name === 'Excursion Category');
      indexForm !== -1 &&
        formFilter[indexForm].data.some(e => e.checked) &&
        (dataFilter =
          dataFilter &&
          formFilter[indexForm].data.findIndex(
            e => e.key === AttractionCategory.toLowerCase() && e.checked
          ) !== -1);

      indexForm = formFilter.findIndex(e => e.name === 'Excursion Type');
      indexForm !== -1 &&
        formFilter[indexForm].data.some(e => e.checked) &&
        (dataFilter =
          dataFilter &&
          formFilter[indexForm].data.findIndex(
            e => e.key === AttractionType.Id && e.checked
          ) !== -1);
      return dataFilter;
    });
  };
  filteredData = data => {
    let { formFilter, searchName } = this.state;
    return this.filterExcursion(data, formFilter, searchName);
  };
  pageChange = (e, { activePage }) => {
    this.setState({ ...this.state, activePage });
  };
  handleChange = (e, { name, value }) => {
    this.setState({
      ...this.state,
      [name]: value,
      activePage: 1,
    });
  };
  handleChangeCheckbox = (e, { name, value }) => {
    let dataList = this.state.formFilter;
    let indexForm = dataList.findIndex(e => e.name === name);
    let indexData = dataList[indexForm].data.findIndex(e => e.key === value);
    dataList[indexForm].data[indexData].checked = !dataList[indexForm].data[
      indexData
    ].checked;
    this.setState({ ...this.state, formFilter: dataList, activePage: 1 });
  };
  handleResetFilter = () => {
    let { formFilter } = this.state;
    formFilter = formFilter.map(filter =>
      filter.data.some(e => e.checked)
        ? { name: filter.name, data: this.resetFilter(filter.data) }
        : filter
    );
    return this.setState({ ...this.state, formFilter, activePage: 1 });
  };
  resetFilter = listData => {
    return listData.map(data => {
      return {
        key: data.key,
        text: data.text,
        checked: false,
        desc: data.desc,
      };
    });
  };
  render() {
    let {
      open,
      onClose,
      name,
      loading,
      data,
      setExcursion,
      location,
    } = this.props;
    let { formFilter, activePage, searchName } = this.state;
    data = data.length !== 0 ? this.filteredData(data) : data;
    //data = !loadingExcursionByFilter ? data : [];
    return (
      <Modal open={open} onClose={onClose} size="small" closeOnDimmerClick>
        <Modal.Header style={styles.backgroundModal}>
          {name === 'EAT' ? 'Restaurant' : 'Excursion'}
          <ButtonClose onClose={onClose} />
        </Modal.Header>
        <Modal.Content style={styles.backgroundModal}>
          <Modal.Description>
            <Grid>
              <Grid.Column width={5}>
                <Filter
                  loading={loading}
                  formFilter={formFilter}
                  handleChange={this.handleChangeCheckbox}
                  resetFilter={this.handleResetFilter}
                />
              </Grid.Column>
              <Grid.Column width={11}>
                <ListCard
                  location={location}
                  data={data}
                  //loading={loadingExcursionByFilter}
                  loading={loading}
                  activePage={activePage}
                  pageChange={this.pageChange}
                  searchName={searchName}
                  handleChange={this.handleChange}
                  setExcursion={setExcursion}
                />
              </Grid.Column>
            </Grid>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}
const mapStateToProps = state => {
  return {
    excursionTypeList: state.ExcursionStore.excursionTypeList,
    getExcursionTypesStatus: state.ExcursionStore.getExcursionTypesStatus,
    loading: state.ExcursionStore.loading,
  };
};
export default connect(mapStateToProps, {
  resetExcursionAction,
  getExcursionTypeAction,
})(modalListExcursion);
