import React from 'react';
import { Grid, Container } from 'semantic-ui-react';

const HeaderTable = () => {
  return (
    <Grid columns={6}>
      <Grid.Row>
        <Grid.Column>
          <Container>
            <strong>Title</strong>
          </Container>
        </Grid.Column>
        <Grid.Column>
          <Container>
            <strong>First Name</strong>
          </Container>
        </Grid.Column>
        <Grid.Column>
          <Container>
            <strong>Last Name</strong>
          </Container>
        </Grid.Column>
        <Grid.Column>
          <Container>
            <strong>Identity Type</strong>
          </Container>
        </Grid.Column>
        <Grid.Column>
          <Container>
            <strong>Identity Number</strong>
          </Container>
        </Grid.Column>
        <Grid.Column>
          <Container>
            <strong>Home Country</strong>
          </Container>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default HeaderTable;
