import React, { Component } from 'react';
import { Table, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../../../scripts/pagination';
import moment from 'moment';
import headerTableLocationAccomodationItemPrice from '../constant/tableHeader';
import Pagination from '../../../../../components/common/pagination';
class tableAddingData extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
      pageLength: 3,
    };
  }
  static propTypes = {
    data: PropTypes.array,
  };
  handleChange = (e, { name }) => {
    this.setState({ ...this.state, activePage: 1 });
    this.props.editPrice(name);
  };
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };

  render() {
    const props = this.props;
    let pagedData = datasetPagination(props.listData, 5);
    let listData = pagedData[this.state.activePage - 1];
    let { numbersFrom, numbersTo } = getNumberFromTo(
      listData,
      this.state.activePage,
      5
    );
    return (
      <div>
        <Table basic="very" selectable fixed>
          <Table.Header>
            <Table.Row>
              {headerTableLocationAccomodationItemPrice.map(
                (headTableContent, index) => {
                  return (
                    <Table.HeaderCell key={index}>
                      {headTableContent}
                    </Table.HeaderCell>
                  );
                }
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {listData ? (
              listData.map((price, i) => {
                return (
                  <Table.Row key={i} textAlign="center">
                    <Table.Cell>
                      {price.ServicePriceType.replace('_', ' ')}
                    </Table.Cell>
                    <Table.Cell>
                      {price.RankType === 1
                        ? 'Standard'
                        : price.RankType === 2
                        ? 'High Priority'
                        : price.RankType === 3
                        ? 'Promotion'
                        : 'Other'}
                    </Table.Cell>
                    <Table.Cell>{price.RankValue}</Table.Cell>
                    <Table.Cell>{price.MinimumNight}</Table.Cell>
                    <Table.Cell>
                      {moment(price.StartDate).format('DD MMM YYYY')}
                    </Table.Cell>
                    <Table.Cell>
                      {moment(price.EndDate).format('DD MMM YYYY')}
                    </Table.Cell>
                    <Table.Cell>
                      {moment(price.CutOfDate).format('DD MMM YYYY')}
                    </Table.Cell>
                    <Table.Cell>{price.CutOfDay}</Table.Cell>
                    <Table.Cell textAlign="center">
                      <Button
                        // name={idxPagination}
                        name={price.Id}
                        positive
                        content="Edit"
                        onClick={this.handleChange}
                        disabled={props.disabled}
                      />
                      {/* <ButtonEditAndDelete
                  name1={i}
                  name2={i}
                  content1="Edit"
                  content2="Delete"
                  handleClickEdit={props.editPrice}
                  handleClickDelete={props.deletePrice}
                  id={price.Id}
                /> */}
                    </Table.Cell>
                  </Table.Row>
                );
              })
            ) : (
              <Table.Row>
                <Table.Cell colSpan="9" textAlign="center">
                  No Data
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        <Pagination
          pagedData={pagedData}
          numbersTo={numbersTo}
          numbersFrom={numbersFrom}
          data={props.listData}
          pageChange={this.pageChange}
          activePage={this.state.activePage}
        />
      </div>
    );
  }
}

tableAddingData.propTypes = {
  listData: PropTypes.array,
  deleteState: PropTypes.func,
  headerTable: PropTypes.array,
  value: PropTypes.string,
  activePage: PropTypes.number,
  pageChange: PropTypes.func,
  editPrice: PropTypes.func,
};
export default tableAddingData;
