import React, { Component } from 'react';
import {
  Segment,
  Grid,
  Form,
  Header,
  Button,
  Image,
  Tab,
} from 'semantic-ui-react';
import HeaderTitle from '../../components/common/headerTitle';
import { connect } from 'react-redux';
import {
  getTourOperatorProfileDetailByIdAction,
  putTourOperatorAction,
  postImageTourOperatorAction,
  postTourOperatorAction,
} from '../../actions/packageAction';
import { getCityAction } from '../shared/actions/cityAction';
import { getCustomerProfileByCodeAction } from '../shared/actions/customerProfileAction';
import {
  getTourOperatorEmailSettingByOperator,
  putTourOperatorEmailSetting,
  postTourOperatorEmailSetting,
  deleteTourOperatorEmailSetting,
} from '../shared/actions/partnerProfileAction';
import PropTypes from 'prop-types';
import styles from './StylesTourOperator';
import ModalAlert from '../shared/components/modal/ModalAlert';
import setOperatorData from './service/setTourOperatorData';
import NoImage from '../../assets/Icons/no_image.jpg';
// import ModalImage from '../../components/modal/modalAccomodation/modalImage';
import ModalImage from '../shared/components/modal/accommodation/ModalImage';
import Loader from '../../components/loader';
import {
  tourOperatorField,
  emailSettingField,
} from './constant/fieldToValidate';
import handleValidation from './service/validation/emptyFieldValidation';
import _ from 'lodash';
import TourOperatorForm from './form/TourOperatorForm';
import TabEmailSettings from './components/TableEmailSettings';
import ModalEmailSettings from './components/ModalEmailSettings';
class TourOperatorDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tourOperatorData: {
        // Id: '',
        Name: '',
        Address: '',
        Telephone: '',
        Email: '',
        ImageUrl: '',
        CityId: '',
        //RegionId: '',
        Company: '',
      },
      errors: {},
      modalAlert: false,
      contentAlert: '',
      headerAlert: '',
      isFailed: false,
      emailSettingObj: {
        Credential: '',
        EmailAddress: '',
        Id: '',
        Port: '',
        SmtpClient: '',
        IsRecipientOnly: true,
      },
      contentModal: '',
      openModalImage: false,
      dataItem: [],
      imageUrl: null,
      imageTemp: {
        ImageContent: null,
        ImageName: null,
        ImagePreview: null,
      },
      openModalEmailSetting: false,
      disable: true,
      contentButton: '',
      isDetail: true,
      emailSettingList: [],
      TabPanes: [
        {
          menuItem: 'Tour Operator Email Settings',
          // eslint-disable-next-line react/display-name
          render: () => (
            <Tab.Pane
              attached={true}
              content={
                <TabEmailSettings
                  listData={this.state.emailSettingList}
                  openModal={this.openModalEmail}
                  deleteData={this.handleDeleteEmail}
                  disabled={this.state.disable}
                />
              }
            />
          ),
        },
      ],
      headerModalEmail: '',
      modalEmailMode: '',
      emailIndex: 0,
      deletedEmail: [],
      emailErrors: {},
    };
  }
  componentDidMount = () => {
    const { profileId, id } = this.props.match.params;

    this.getTourOperatorId();

    this.props.getCityAction(); // eslint-disable-next-line
    if (id != 0) {
      this.initialData(id);
    } else {
      this.getCompanyProfile(profileId);
    }
  };
  initialData = async id => {
    await this.props
      .getTourOperatorProfileDetailByIdAction(id)
      .then(res => this.setStateTourOperator(res.value.data));
    await this.props.getTourOperatorEmailSettingByOperator(id).then(res =>
      this.setState({
        emailSettingList: res.value.data,
      })
    );
  };
  getCompanyProfile = (
    profileId
    // type
  ) => {
    this.props.getCustomerProfileByCodeAction(profileId).then(res => {
      let { tourOperatorData, emailSettingObj } = { ...this.state };
      tourOperatorData.Company = {
        Code: res.value.data.Code,
        Name: res.value.data.Name,
      };
      tourOperatorData.ProfileId = profileId;
      // emailSettingObj.TourOperatorProfileId = parseInt(profileId);
      this.setState({
        // isNewForm: type === 'new',
        tourOperatorData: tourOperatorData,
        emailSettingObj: emailSettingObj,
        // contentButton: type === 'detail' ? 'Edit' : 'Save',
        // disable: type === 'detail',
      });
    });
  };

  getTourOperatorId = () => {
    const { profileId, Type } = this.props.match.params;
    this.props.getCityAction(); // eslint-disable-next-line
    this.getDataTourOperator(profileId, Type);
  };
  getDataTourOperator = (code, type) => {
    // this.load(code);
    this.setState({
      customerProfile: {
        CompanyId: code,
      },
      contentButton: type === 'detail' ? 'Edit' : 'Save',
      disable: type === 'detail',
    });
  };

  updateTourOperator = () => {
    let currentState = this.state.tourOperatorData;
    const { id } = this.props.match.params;
    const { tourOperatorImages } = this.state;
    const {
      putTourOperatorAction,
      postTourOperatorAction,
      postImageTourOperatorAction,
    } = this.props;
    let objectTourOperator = {};
    let regionId = this.props.listCity.filter(
      item => item.value === this.state.tourOperatorData.CityId
    )[0];
    const err = this.validateTourOperator();
    if (!err) {
      // eslint-disable-next-line
      if (id != 0) {
        objectTourOperator = {
          Id: currentState.Id,
          Name: currentState.Name,
          Address: currentState.Address,
          Telephone: currentState.Telephone,
          Email: currentState.Email,
          ImageUrl: currentState.ImageUrl,
          CompanyCode: currentState.Company.Code,
          CityId: currentState.CityName
            ? currentState.CityName
            : currentState.CityId,
          RegionId: regionId ? regionId.region : currentState.RegionName,
        };
        putTourOperatorAction(currentState.Id, objectTourOperator)
          .then(() => {
            this.handleSaveEmail();
            this.postImageTourOperator(
              currentState.Id,
              tourOperatorImages,
              postImageTourOperatorAction
            );
            // this.handleSuccessMessage();
            // this.load(ProfileId);
            this.setState({
              openModalDetailTourOperator: false,
            });
          })
          .catch(err => this.handleFailedMessage(err.response.data.Message));
      } else {
        objectTourOperator = {
          Name: currentState.Name,
          Address: currentState.Address,
          Telephone: currentState.Telephone,
          Email: currentState.Email,
          CompanyCode: currentState.ProfileId,
          CityId: currentState.CityName
            ? currentState.CityName
            : currentState.CityId,
          RegionId: regionId ? regionId.region : currentState.RegionName,
          ImageName: currentState.ImageName,
          ImageContent: currentState.ImageContent,
        };
        postTourOperatorAction(objectTourOperator)
          .then(res => {
            this.handleSaveEmail(res.value.data.Id);
            this.postImageTourOperator(
              res.value.data.Id,
              tourOperatorImages,
              postImageTourOperatorAction
            );
            // this.load(ProfileId);
            let { tourOperatorData } = { ...this.state };
            tourOperatorData.Id = res.value.data.Id;
            this.setState({
              openModalDetailTourOperator: false,
              tourOperatorData: tourOperatorData,
              updatePage: true,
            });

            this.handleSuccessMessage();
          })
          .catch(err => this.handleFailedMessage(err.response.data.Message));
      }
    }
  };

  handleFailedMessage = content => {
    this.setState({
      modalAlert: true,
      contentAlert: content,
      headerAlert: 'Failed',
      isFailed: true,
    });
  };
  handleSuccessMessage = () => {
    this.setState({
      modalAlert: true,
      contentAlert: 'Tour operator successfully saved',
      headerAlert: 'Success',
      isFailed: true,
    });
  };
  setStateTourOperator = data => {
    let operatorData = setOperatorData(data);
    let tourOperatorImages = {
      tourOperatorProfileId: data.Id,
      IsPrimaryImage: true,
      ImageName: data.TourOperatorProfileImages.ImageName,
      ImageContent: data.TourOperatorProfileImages.ImageUrl,
    };
    this.setState({
      tourOperatorData: operatorData,
      tourOperatorImages: tourOperatorImages,
    });
  };
  validateTourOperator = () => {
    let isError = false;
    const { tourOperatorData } = this.state;
    let operatorDataValidation = handleValidation(
      tourOperatorData,
      tourOperatorField
    );

    let error = { ...operatorDataValidation };
    this.setState({ errors: { ...error } });
    isError = !_.isEmpty(error);
    return isError;
  };
  validateEmailSetting = () => {
    let isError = false;
    const { emailSettingObj } = this.state;
    let emailSettingValidation = handleValidation(
      emailSettingObj,
      emailSettingField
    );

    let error = { ...emailSettingValidation };
    this.setState({ emailErrors: { ...error } });
    isError = !_.isEmpty(error);
    return { isError, error };
  };

  handleChangeProfile = (e, { value, name }) => {
    const data = this.state.tourOperatorData;
    data[name] = value;
    this.setState({ tourOperatorData: data });
    this.validateTourOperator();
  };
  //handle image
  modalImage = (e, { id }) => {
    if (id === 'LicensePicture') {
      this.setState({
        contentModal: 'License Picture',
        openModalImage: true,
        dataItem: [],
        imageUrl: null,
        imageTemp: {
          ImageContent: null,
          ImageName: null,
          ImagePreview: null,
        },
      });
    } else if (id === 'CompanyLogo') {
      this.setState({
        contentModal: 'Company Logo',
        openModalImage: true,
        dataItem: [],
        imageUrl: null,
        imageTemp: {
          ImageContent: null,
          ImageName: null,
          ImagePreview: null,
        },
      });
    } else {
      this.setState({
        contentModal: 'Tour Operator Images',
        openModalImage: true,
        dataItem: [],
        imageUrl: null,
        imageTemp: {
          ImageContent: null,
          ImageName: null,
          ImagePreview: null,
        },
      });
    }
  };
  addImage = () => {
    let { contentModal, imageTemp } = this.state;
    let { ImageContent, ImageName, ImagePreview } = imageTemp;
    const { Id } = this.state.tourOperatorData;
    if (contentModal === 'License Picture') {
      return this.setState({
        customerProfile: {
          ...this.state.customerProfile,
          ImageContent,
          ImageName,
          ImagePreview,
        },
        openModalImage: false,
        isImageUpdate: true,
      });
    } else if (contentModal === 'Company Logo') {
      return this.setState({
        customerProfile: {
          ...this.state.customerProfile,
          CompanyLogo: {
            ImageContent,
            ImageName,
            ImagePreview,
          },
        },
        openModalImage: false,
        isLogoUpdate: true,
      });
    } else {
      return this.setState({
        tourOperatorImages: {
          tourOperatorProfileId: Id,
          IsPrimaryImage: true,
          ImageName: ImageName,
          ImageContent: ImageContent,
        },
        tourOperatorData: {
          ...this.state.tourOperatorData,
          ImageUrl: ImagePreview,
          ImageName: ImageName,
          ImageContent: ImageContent,
        },
        onChangeImages: true,
        openModalImage: false,
      });
    }
  };
  onChangeImage = e => {
    e.map(base => {
      return this.setState({
        imageTemp: {
          ...this.state.imageTemp,
          ImageContent: base.base64.split(',')[1],
          ImageName: base.file.name,
          ImagePreview: URL.createObjectURL(base.file),
        },
      });
    });
  };
  handleCloseModalImage = () => this.setState({ openModalImage: false });
  postImageTourOperator = (
    id,
    tourOperatorImages,
    postImageTourOperatorAction
  ) => {
    if (this.state.onChangeImages) {
      postImageTourOperatorAction(id, {
        tourOperatorProfileId: id,
        IsPrimaryImage: true,
        ImageName: tourOperatorImages.ImageName,
        ImageContent: tourOperatorImages.ImageContent,
      })
        .then(() => {})
        .catch(err => {
          this.handleFailedMessage(err.response.data.Message);
        });
    }
  };
  //====
  //=== handle email
  handleSaveEmail = newId => {
    const listData = [...this.state.emailSettingList];
    const deletedEmail = [...this.state.deletedEmail];

    listData.forEach(data => {
      this.saveEmailSetting(data, newId);
    });

    deletedEmail.forEach(data => {
      this.props.deleteTourOperatorEmailSetting(data);
    });
  };
  handleAlertMail = (header, content) => {
    this.setState({
      headerAlert: header,
      contentAlert: content,
      modalAlert: true,
      // openModalEmailSetting: modalEmail,
    });
  };
  handleMailonChange = (e, { name, value }) => {
    let mail = { ...this.state.emailSettingObj };
    mail[name] = name === 'IsRecipientOnly' ? !value : value;
    // eslint-disable-next-line
    let promise = Promise.resolve(
      this.setState({
        ...this.state,
        emailSettingObj: mail,
      })
    );
    promise.then(() => this.validateEmailSetting());
  };
  openModalEmail = (name, value) => {
    let object = this.initialEmailObject(value);
    this.setState({
      openModalEmailSetting: true,
      headerModalEmail: name === 'add' ? 'Add Email' : 'Edit Email',
      emailSettingObj: object,
      modalEmailMode: name,
      emailIndex: value,
      emailErrors: {},
    });
  };
  closeModalEmail = () => {
    this.setState({
      ...this.props,
      openModalEmailSetting: false,
      emailErrors: {},
    });
  };
  initialEmailObject = value => {
    let object = {
      Credential: '',
      EmailAddress: '',
      Id: 0,
      Port: '',
      SmtpClient: '',
      IsRecipientOnly: true,
    };
    let listData = [...this.state.emailSettingList];
    let data = listData[value];

    return data ? data : object;
  };
  //===
  handleSaveData = () => {
    const { contentButton } = this.state;
    if (contentButton === 'Edit') {
      this.setState({
        contentButton: 'Save',
        disable: false,
      });
    } else {
      this.updateTourOperator();
      // this.handleSaveEmail();
    }
  };
  handleClose = () => {
    let { updatePage, tourOperatorData } = this.state;
    if (updatePage) {
      this.setState({ ...this.state, modalAlert: false });
      this.props.history.push(
        `/tour-operator-detail/edit/${tourOperatorData.Company.Code}/${tourOperatorData.Id}`
      );
      this.initialData(tourOperatorData.Id);
    } else {
      this.setState({ ...this.state, modalAlert: false });
    }
  };
  buttonBack = () => {
    let { tourOperatorData } = this.state;
    this.props.history.push(
      `/Partner-Profile-Detail/detail/${tourOperatorData.Company.Code}`
    );
  };
  handleDeleteEmail = value => {
    let listData = [...this.state.emailSettingList];
    let deletedEmail = [...this.state.deletedEmail];
    // eslint-disable-next-line
    let data = listData.find((item, i) => i == value);
    if (data.Id) {
      deletedEmail.push(data.Id);
    }
    // eslint-disable-next-line
    let newList = listData.filter((item, i) => i != value);
    this.setState({
      ...this.state,
      emailSettingList: newList,
      deletedEmail: deletedEmail,
    });
  };
  handleSaveEmailObject = () => {
    let {
      emailSettingObj,
      emailSettingList,
      modalEmailMode,
      emailIndex,
    } = this.state;
    let listData = [...emailSettingList];
    let { isError, error } = this.validateEmailSetting();
    if (!isError) {
      if (modalEmailMode === 'add') {
        listData.push(emailSettingObj);
      } else if (modalEmailMode === 'edit') {
        listData[emailIndex] = emailSettingObj;
      }
    }

    this.setState({
      ...this.state,
      emailSettingList: listData,
      openModalEmailSetting: isError ? true : false,
      emailErrors: error,
    });
  };
  //=====action=====
  saveEmailSetting = data => {
    if (data.Id) {
      this.props
        .putTourOperatorEmailSetting(data.Id, data)
        // eslint-disable-next-line
        .then(res => this.handleSuccessMessage())
        .catch(err =>
          this.handleAlertMail('Failed', err.response.data.Message, true)
        );
    } else {
      let { Id } = this.state.tourOperatorData;
      this.props
        .postTourOperatorEmailSetting({
          Credential: data.Credential,
          EmailAddress: data.EmailAddress,
          Port: data.Port,
          SmtpClient: data.SmtpClient,
          TourOperatorProfileId: Id, //id ? id : data.TourOperatorProfileId,
          IsRecipientOnly: data.IsRecipientOnly,
        })
        // eslint-disable-next-line
        .then(res => {
          this.handleSuccessMessage();
        })
        .catch(err =>
          this.handleAlertMail('Failed', err.response.data.Message, true)
        );
    }
  };
  render() {
    // const props = this.props;
    const {
      modalAlert,
      tourOperatorData,
      errors,
      emailSettingObj,
      contentButton,
      TabPanes,
      openModalEmailSetting,
      headerModalEmail,
      emailErrors,
      disable,
    } = this.state;
    const {
      listCity,
      loadingEmailSetting,
      loadingTourOperator,
      loadingCustomer,
    } = this.props;
    let contentLogo = '';
    let operatorLogo = tourOperatorData && tourOperatorData.ImageUrl;
    if (operatorLogo) {
      contentLogo = 'Change Image';
    } else {
      contentLogo = 'Add Image';
    }
    let loading = loadingEmailSetting || loadingTourOperator || loadingCustomer;

    return (
      <Segment style={styles.marginTop30}>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <HeaderTitle iconName="user" title={`Tour Operator Detail`} />
            </Grid.Column>
            <div style={{ margin: '1em 0em 0em 63em' }}>
              <Button content="Back" primary onClick={this.buttonBack} />
              {this.props.isFinance || (
                <Button
                  content={contentButton}
                  positive
                  onClick={this.handleSaveData}
                />
              )}
              {/* <Button primary onClick={this.handleSaveData}>
                Save
              </Button> */}
            </div>
          </Grid.Row>
        </Grid>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header as="h3">General Information</Header>
              <TourOperatorForm
                handleChange={this.handleChangeProfile}
                errors={errors}
                tourOperatorData={tourOperatorData}
                listCity={listCity}
                buttonBack={this.buttonBack}
                disable={disable}
                contentButton={this.contentButton}
                buttonEdit={this.handleSaveData}
              />
            </Grid.Column>
            <Grid.Column>
              <Form>
                <Form.Field>
                  <Image
                    src={
                      tourOperatorData && tourOperatorData.ImageUrl
                        ? tourOperatorData.ImageUrl
                        : '' || NoImage
                    }
                    size="medium"
                    style={styles.imageStyle}
                    centered
                  />
                </Form.Field>
                <Grid>
                  <Grid.Row columns="equal">
                    <Grid.Column textAlign="center">
                      <Button
                        positive
                        content={contentLogo}
                        onClick={this.modalImage}
                        disabled={disable}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Tab
                menu={{ secondary: true, pointing: true }}
                panes={TabPanes}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ModalAlert
          openModal={modalAlert}
          handleClose={this.handleClose}
          header={this.state.headerAlert}
          content={this.state.contentAlert}
          nameButton="OK"
        />
        <ModalImage
          openModal={this.state.openModalImage}
          handleClose={this.handleCloseModalImage}
          content={this.state.contentModal}
          imagePreview={this.state.imageUrl}
          handleChange={this.onChangeImage}
          addImage={this.addImage}
        />
        <ModalEmailSettings
          open={openModalEmailSetting}
          handleClose={this.closeModalEmail}
          emailSettingObj={emailSettingObj}
          errors={emailErrors}
          handleChange={this.handleMailonChange}
          header={headerModalEmail}
          handleSave={this.handleSaveEmailObject}
        />
        <Loader loading={loading} />
      </Segment>
    );
  }
}
const mapStateToProps = state => {
  return {
    detailTourOperatorById: state.PackageStore.detailTourOperatorById,
    listCity: state.CityStore.getCity.map(type => {
      return {
        text: type.Name,
        value: type.Id,
        key: type.Id,
        region: type.Region.Id,
      };
    }),
    loadingEmailSetting: state.PartnerProfile.loading,
    loadingTourOperator: state.PackageStore.loading,
    loadingCustomer: state.CustomerProfileStore.loading,
    isFinance: state.UserAccessStore.isFinance,
  };
};
TourOperatorDetail.propTypes = {
  getTourOperatorProfileDetailByIdAction: PropTypes.func,
  putTourOperatorAction: PropTypes.func,
  postImageTourOperatorAction: PropTypes.func,
  postTourOperatorAction: PropTypes.func,
  match: PropTypes.object,
  listCity: PropTypes.array,
  getCityAction: PropTypes.func,
  getTourOperatorEmailSettingByOperator: PropTypes.func,
  putTourOperatorEmailSetting: PropTypes.func,
  postTourOperatorEmailSetting: PropTypes.func,
  loadingEmailSetting: PropTypes.bool,
  getCustomerProfileByCodeAction: PropTypes.func,
  history: PropTypes.object,
  loadingTourOperator: PropTypes.bool,
  loadingCustomer: PropTypes.bool,
  contentButton: PropTypes.string,
  buttonEdit: PropTypes.bool,
  disable: PropTypes.bool,
  listData: PropTypes.array,
  deleteTourOperatorEmailSetting: PropTypes.func,
  isFinance: PropTypes.bool,
};
export default connect(mapStateToProps, {
  getTourOperatorProfileDetailByIdAction,
  putTourOperatorAction,
  postImageTourOperatorAction,
  postTourOperatorAction,
  getCityAction,
  getTourOperatorEmailSettingByOperator,
  putTourOperatorEmailSetting,
  postTourOperatorEmailSetting,
  getCustomerProfileByCodeAction,
  deleteTourOperatorEmailSetting,
})(TourOperatorDetail);
