import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Grid, Button } from 'semantic-ui-react';
import ButtonClose from '../../../../components/button/buttonCloseModal';
import TableSpecialAdjustment from '../tourTransaction/specialAdjustment/components/TableSpecialAdjustment';
import Loader from '../../../../components/loader/loaderInModal';

const ModalSpecialAdjusments = props => {
  let { openModal, handleClose, loading } = props;
  return (
    <Modal
      dimmer="blurring"
      inverted
      open={openModal}
      onClose={handleClose}
      size="large"
    >
      <Modal.Header>
        Special Adjusments
        <ButtonClose onClose={handleClose} />
      </Modal.Header>
      <Modal.Content>
        <Loader loading={loading} />
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <TableSpecialAdjustment
                listData={props.listData}
                currencies={props.currencies}
                handleSpecialAdjustment={props.handleSpecialAdjustment}
                CurrencyId={props.CurrencyId}
                currentPackage={props.currentPackage}
                statusPackage={props.statusPackage}
                additionalItemTypeList={props.getAdditionalItemTypeList}
                typePackage={props.typePackage}
                idTransaction={props.idTransaction}
                loading={false}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Save" primary onClick={props.saveSpecialAdjusment} />
      </Modal.Actions>
    </Modal>
  );
};

ModalSpecialAdjusments.propTypes = {
  openModal: PropTypes.bool,
  listData: PropTypes.array,
  handleClose: PropTypes.func,
  currencies: PropTypes.array,
  handleSpecialAdjustment: PropTypes.func,
  CurrencyId: PropTypes.string,
  statusPackage: PropTypes.string,
  currentPackage: PropTypes.string,
  getAdditionalItemTypeList: PropTypes.array,
  typePackage: PropTypes.string,
  saveSpecialAdjusment: PropTypes.func,
  loading: PropTypes.bool,
  idTransaction: PropTypes.string,
};
export default ModalSpecialAdjusments;
