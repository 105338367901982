import * as types from '../constants/actionTypes';
const initialState = {
  loading: false,
  getAccomodationList: [],
  loadingGetAccommodationList: false,
  getAccomodationListStatus: '',
  getAccomodationByProfilId: [],
  getAccomodationItemByProfileId: [],
  getAccomodationItemStatus: '',
  getAccomodationItemByServiceTypeId: [],
  getAccomodationItemByServiceTypeStatus: '',
  deleteAccomodationStatus: '',
  getAccomodationTemplateStatus: '',
  getAccomodationTemplate: '',
  getAccomodationExportStatus: '',
  getAccomodationExport: '',
  getAccomodationItemTemplate: '',
  getAccomodationItemTemplateStatus: '',
  postUploadImageAccomodationProfileStatus: '',
  postUploadImageAccomodationProfile: [],
  postUploadImageAccomodationItemStatus: '',
  postUploadImageAccomodationItem: [],
  getAccomodationItemAll: [],
  getAccomodationItemAllStatus: '',
  getAccomodationByCity: [],
  getAccomodationByCityStatus: '',
  getAccomodationByProfilIdAndDate: [],
  getAccomodationByProfilIdAndDateStatus: '',
  postPrimaryImageStatus: '',
  errors: {},
  listAccommodationsWithFilter: [],
  getAccomodationwithFilterStatus: '',
  loadingGetRoomAllotment: false,
  getRoomAllotmentStatus: '',
  roomAllotments: [],
  roomAllotmentDay: {},
  postRoomAllotmentDayStatus: '',
  loadingPostRoomAllotmentDay: false,
  isNeedUpdateRoomAllotment: false,
  loadingGetRequestRoomAllotment: false,
  requestRoomAllotment: null,
  loadingPostConfirmRoomAllotment: false,
  loadingGetListConfirmRoomAllotment: false,
  listConfirmRoomAllotment: [],
  loadingGetResendRequestConfirm: false,
  getResendRequestConfirm: null,
  loadingGetListRoomAllotmentByProfileId: false,
  listRoomAllotment: [],
  loadingGetStockRoomAllotment: false,
  listStockRoomAllotment: [],
  loadingGetExportStockRoomAllotment: false,
  exportRoomAllotment: '',
  getSupplierList: [],
  getSupplierListStatus: '',
  getExportDataItemById: '',
  getExportDataItemByIdStatus: null,
  accommodationEstimatePrice: null,
  loadingGetAccommodationEstimatePrice: false,
  loadingGetAccommodationItemEstimatePrice: false,
  listAccommodationItemEstimatePrice: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ACCOMODATION_LIST:
      return { ...state };
    case types.GET_ACCOMODATION_LIST_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getAccomodationListStatus: 'failed',
        loadingGetAccommodationList: false,
      };
    case types.GET_ACCOMODATION_LIST_PENDING:
      return { ...state, loadingGetAccommodationList: true };
    case types.GET_ACCOMODATION_LIST_FULFILLED:
      return {
        ...state,
        getAccomodationList: action.payload.data,
        loading: false,
        getAccomodationListStatus: 'success',
        loadingGetAccommodationList: false,
      };

    case types.RESET_STATUS_ACCOMODATION:
      return {
        ...state,
        getAccomodationListStatus: '',
        getAccomodationItemStatus: '',
        getAccomodationItemByServiceTypeStatus: '',
        postAccomodationStatus: '',
        deleteAccomodationStatus: '',
        getAccomodationTemplateStatus: '',
        getAccomodationExportStatus: '',
        putAccomodationStatus: '',
        getAccomodationItemTypesStatus: '',
        getAccomodationItemExportStatus: '',
        getAccomodationItemTemplateStatus: '',
        postUploadImageAccomodationProfileStatus: '',
        postUploadImageAccomodationItemStatus: '',
        getAccomodationItemAllStatus: '',
        getAccomodationByProfilIdAndDateStatus: '',
        postAccomodationItemStatus: '',
        putAccomodationItemStatus: '',
        postPrimaryImageStatus: '',
        deleteAccomodationItemStatus: '',
        deleteImageAccomodationStatus: '',
        getAccomodationByCityStatus: '',
        deleteContractingFileStatus: '',
        postContractingFileStatus: '',
        getAccomodationwithFilterStatus: '',
        isNeedUpdateRoomAllotment: false,
        loadingGetExportStockRoomAllotment: false,
        getSupplierListStatus: '',
        getExportDataItemByIdStatus: null,
      };

    case types.GET_ACCOMODATION_BY_PROFILE_ID:
      return { ...state };
    case types.GET_ACCOMODATION_BY_PROFILE_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getAccomodationListStatus: 'failed',
        isNeedUpdateRoomAllotment: false,
      };
    case types.GET_ACCOMODATION_BY_PROFILE_ID_PENDING:
      return { ...state, loading: true, isNeedUpdateRoomAllotment: false };
    case types.GET_ACCOMODATION_BY_PROFILE_ID_FULFILLED:
      return {
        ...state,
        getAccomodationListStatus: 'success',
        getAccomodationByProfilId: action.payload.data,
        loading: false,
        isNeedUpdateRoomAllotment: true,
      };

    case types.GET_ACCOMODATION_ITEM_BY_PROFILE_ID:
      return { ...state };
    case types.GET_ACCOMODATION_ITEM_BY_PROFILE_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getAccomodationItemStatus: 'failed',
      };
    case types.GET_ACCOMODATION_ITEM_BY_PROFILE_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_ACCOMODATION_ITEM_BY_PROFILE_ID_FULFILLED:
      return {
        ...state,
        getAccomodationItemByProfileId: action.payload.data,
        loading: false,
        getAccomodationItemStatus: 'success',
      };

    case types.GET_ACCOMODATION_ITEM_BY_SERVICE_TYPE_ID:
      return { ...state };
    case types.GET_ACCOMODATION_ITEM_BY_SERVICE_TYPE_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getAccomodationItemByServiceTypeStatus: 'failed',
      };
    case types.GET_ACCOMODATION_ITEM_BY_SERVICE_TYPE_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_ACCOMODATION_ITEM_BY_SERVICE_TYPE_ID_FULFILLED:
      return {
        ...state,
        getAccomodationItemByServiceTypeId: action.payload.data,
        loading: false,
        getAccomodationItemByServiceTypeStatus: 'success',
      };

    case types.DELETE_ACCOMODATION_BY_PROFILE_ID:
      return { ...state };
    case types.DELETE_ACCOMODATION_BY_PROFILE_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
      };
    case types.DELETE_ACCOMODATION_BY_PROFILE_ID_PENDING:
      return { ...state, loading: true };
    case types.DELETE_ACCOMODATION_BY_PROFILE_ID_FULFILLED:
      return { ...state, deleteAccomodationStatus: 'success', loading: false };

    case types.GET_TEMPLATE_ACCOMODATION:
      return { ...state };
    case types.GET_TEMPLATE_ACCOMODATION_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getAccomodationTemplateStatus: 'failed',
      };
    case types.GET_TEMPLATE_ACCOMODATION_PENDING:
      return { ...state, loading: true };
    case types.GET_TEMPLATE_ACCOMODATION_FULFILLED:
      return {
        ...state,
        getAccomodationTemplate: action.payload.data,
        loading: false,
        getAccomodationTemplateStatus: 'success',
      };

    case types.GET_EXPORT_ACCOMODATION:
      return { ...state };
    case types.GET_EXPORT_ACCOMODATION_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getAccomodationExportStatus: 'failed',
      };
    case types.GET_EXPORT_ACCOMODATION_PENDING:
      return { ...state, loading: true };
    case types.GET_EXPORT_ACCOMODATION_FULFILLED:
      return {
        ...state,
        getAccomodationExport: action.payload.data,
        loading: false,
        getAccomodationExportStatus: 'success',
      };

    case types.GET_TEMPLATE_ACCOMODATION_ITEM_DATA:
      return { ...state };
    case types.GET_TEMPLATE_ACCOMODATION_ITEM_DATA_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getAccomodationItemTemplateStatus: 'failed',
      };
    case types.GET_TEMPLATE_ACCOMODATION_ITEM_DATA_PENDING:
      return { ...state, loading: true };
    case types.GET_TEMPLATE_ACCOMODATION_ITEM_DATA_FULFILLED:
      return {
        ...state,
        getAccomodationItemTemplate: action.payload.data,
        loading: false,
        getAccomodationItemTemplateStatus: 'success',
      };

    case types.POST_UPLOAD_IMAGE_ACCOMODATION_PROFILE:
      return { ...state };
    case types.POST_UPLOAD_IMAGE_ACCOMODATION_PROFILE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        postUploadImageAccomodationProfileStatus: 'failed',
      };
    case types.POST_UPLOAD_IMAGE_ACCOMODATION_PROFILE_PENDING:
      return { ...state, loading: true };
    case types.POST_UPLOAD_IMAGE_ACCOMODATION_PROFILE_FULFILLED:
      return {
        ...state,
        postUploadImageAccomodationProfileStatus: 'success',
        postUploadImageAccomodationProfile: action.payload.data,
        loading: false,
      };

    case types.POST_UPLOAD_IMAGE_ACCOMODATION_ITEM:
      return { ...state };
    case types.POST_UPLOAD_IMAGE_ACCOMODATION_ITEM_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        postUploadImageAccomodationItemStatus: 'failed',
      };
    case types.POST_UPLOAD_IMAGE_ACCOMODATION_ITEM_PENDING:
      return { ...state, loading: true };
    case types.POST_UPLOAD_IMAGE_ACCOMODATION_ITEM_FULFILLED:
      return {
        ...state,
        postUploadImageAccomodationItemStatus: 'success',
        postUploadImageAccomodationItem: action.payload.data,
        loading: false,
      };

    case types.GET_ACCOMODATION_ITEM_ALL:
      return { ...state };
    case types.GET_ACCOMODATION_ITEM_ALL_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getAccomodationItemAllStatus: 'failed',
      };
    case types.GET_ACCOMODATION_ITEM_ALL_PENDING:
      return { ...state, loading: true };
    case types.GET_ACCOMODATION_ITEM_ALL_FULFILLED:
      return {
        ...state,
        getAccomodationItemAll: action.payload.data,
        loading: false,
        getAccomodationItemAllStatus: 'success',
      };

    case types.GET_ACCOMMODATION_BY_CITY: {
      return { ...state };
    }
    case types.GET_ACCOMMODATION_BY_CITY_PENDING: {
      return { ...state, loading: true };
    }
    case types.GET_ACCOMMODATION_BY_CITY_FULFILLED: {
      return {
        ...state,
        loading: false,
        getAccomodationByCity: action.payload.data,
        getAccomodationByCityStatus: 'success',
      };
    }
    case types.GET_ACCOMMODATION_BY_CITY_REJECTED: {
      return {
        ...state,
        loading: false,
        getAccomodationByCityStatus: 'failed',
      };
    }

    case types.GET_ACCOMODATION_ITEM_BY_PROFILE_ID_AND_REQUEST_DATE: {
      return { ...state };
    }
    case types.GET_ACCOMODATION_ITEM_BY_PROFILE_ID_AND_REQUEST_DATE_PENDING: {
      return { ...state, loading: true };
    }
    case types.GET_ACCOMODATION_ITEM_BY_PROFILE_ID_AND_REQUEST_DATE_FULFILLED: {
      return {
        ...state,
        loading: false,
        getAccomodationByProfilIdAndDate: action.payload.data,
        getAccomodationByProfilIdAndDateStatus: 'success',
      };
    }
    case types.GET_ACCOMODATION_ITEM_BY_PROFILE_ID_AND_REQUEST_DATE_REJECTED: {
      return {
        ...state,
        loading: false,
        getAccomodationByProfilIdAndDateStatus: 'failed',
      };
    }

    case types.POST_SET_PRIMARY_IMAGE:
      return { ...state };
    case types.POST_SET_PRIMARY_IMAGE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        postPrimaryImageStatus: 'failed',
      };
    case types.POST_SET_PRIMARY_IMAGE_PENDING:
      return { ...state, loading: true };
    case types.POST_SET_PRIMARY_IMAGE_FULFILLED:
      return { ...state, loading: false, postPrimaryImageStatus: 'success' };

    case types.GET_ACCOMMODATION_WITH_FILTER:
      return { ...state };
    case types.GET_ACCOMMODATION_WITH_FILTER_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getAccomodationwithFilterStatus: 'failed',
      };
    case types.GET_ACCOMMODATION_WITH_FILTER_PENDING:
      return { ...state, loading: true };
    case types.GET_ACCOMMODATION_WITH_FILTER_FULFILLED:
      return {
        ...state,
        listAccommodationsWithFilter: action.payload.data,
        getAccomodationwithFilterStatus: 'success',
        loading: false,
      };

    case types.GET_ROOM_ALLOTMENT_BY_PROFILE_ID:
      return { ...state };
    case types.GET_ROOM_ALLOTMENT_BY_PROFILE_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loadingGetRoomAllotment: false,
        getRoomAllotmentStatus: 'failed',
      };
    case types.GET_ROOM_ALLOTMENT_BY_PROFILE_ID_PENDING:
      return { ...state, loadingGetRoomAllotment: true };
    case types.GET_ROOM_ALLOTMENT_BY_PROFILE_ID_FULFILLED:
      return {
        ...state,
        roomAllotments: action.payload.data,
        getRoomAllotmentStatus: 'success',
        loadingGetRoomAllotment: false,
      };

    case types.POST_ROOM_ALLOTMENT_DAY:
      return { ...state };
    case types.POST_ROOM_ALLOTMENT_DAY_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loadingPostRoomAllotmentDay: false,
        postRoomAllotmentDayStatus: 'failed',
      };
    case types.POST_ROOM_ALLOTMENT_DAY_PENDING:
      return { ...state, loadingPostRoomAllotmentDay: true };
    case types.POST_ROOM_ALLOTMENT_DAY_FULFILLED:
      return {
        ...state,
        roomAllotmentDay: action.payload.data,
        postRoomAllotmentDayStatus: 'success',
        loadingPostRoomAllotmentDay: false,
      };

    case types.POST_ROOM_ALLOTMENT_PERIOD:
      return { ...state };
    case types.POST_ROOM_ALLOTMENT_PERIOD_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loadingPostRoomAllotmentPeriod: false,
        postRoomAllotmentPeriodStatus: 'failed',
      };
    case types.POST_ROOM_ALLOTMENT_PERIOD_PENDING:
      return { ...state, loadingPostRoomAllotmentPeriod: true };
    case types.POST_ROOM_ALLOTMENT_PERIOD_FULFILLED:
      return {
        ...state,
        roomAllotmentPeriod: action.payload.data,
        postRoomAllotmentPeriodStatus: 'success',
        loadingPostRoomAllotmentPeriod: false,
      };

    case types.GET_REQUEST_ROOM_ALLOTMENT:
      return { ...state };
    case types.GET_REQUEST_ROOM_ALLOTMENT_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loadingGetRequestRoomAllotment: false,
        requestRoomAllotment: null,
      };
    case types.GET_REQUEST_ROOM_ALLOTMENT_PENDING:
      return { ...state, loadingGetRequestRoomAllotment: true };
    case types.GET_REQUEST_ROOM_ALLOTMENT_FULFILLED:
      return {
        ...state,
        requestRoomAllotment: action.payload.data,
        loadingGetRequestRoomAllotment: false,
      };

    case types.POST_CONFIRM_ROOM_ALLOTMENT:
      return { ...state };
    case types.POST_CONFIRM_ROOM_ALLOTMENT_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loadingPostConfirmRoomAllotment: false,
      };
    case types.POST_CONFIRM_ROOM_ALLOTMENT_PENDING:
      return { ...state, loadingPostConfirmRoomAllotment: true };
    case types.POST_CONFIRM_ROOM_ALLOTMENT_FULFILLED:
      return {
        ...state,
        loadingPostConfirmRoomAllotment: false,
      };

    case types.GET_LIST_CONFIRM_ROOM_ALLOTMENTS:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loadingGetRequestRoomAllotment: false,
        requestRoomAllotment: null,
      };
    case types.GET_LIST_CONFIRM_ROOM_ALLOTMENTS_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loadingGetListConfirmRoomAllotment: false,
        listConfirmRoomAllotment: [],
      };
    case types.GET_LIST_CONFIRM_ROOM_ALLOTMENTS_PENDING:
      return { ...state, loadingGetListConfirmRoomAllotment: true };
    case types.GET_LIST_CONFIRM_ROOM_ALLOTMENTS_FULFILLED:
      return {
        ...state,
        loadingGetListConfirmRoomAllotment: false,
        listConfirmRoomAllotment: action.payload.data,
      };

    case types.GET_RESEND_REQUEST_CONFIRMATION:
      return { ...state };
    case types.GET_RESEND_REQUEST_CONFIRMATION_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loadingGetResendRequestConfirm: false,
        getResendRequestConfirm: null,
      };
    case types.GET_RESEND_REQUEST_CONFIRMATION_PENDING:
      return { ...state, loadingGetResendRequestConfirm: true };
    case types.GET_RESEND_REQUEST_CONFIRMATION_FULFILLED:
      return {
        ...state,
        loadingGetResendRequestConfirm: false,
        getResendRequestConfirm: action.payload.data,
      };

    case types.GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID:
      return { ...state };
    case types.GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loadingGetListRoomAllotmentByProfileId: false,
        listRoomAllotment: [],
      };
    case types.GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID_PENDING:
      return { ...state, loadingGetListRoomAllotmentByProfileId: true };
    case types.GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID_FULFILLED:
      return {
        ...state,
        errors: {},
        loadingGetListRoomAllotmentByProfileId: false,
        listRoomAllotment: action.payload.data,
      };

    case types.GET_STOCK_ROOM_ALLOTMENT:
      return { ...state };
    case types.GET_STOCK_ROOM_ALLOTMENT_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loadingGetStockRoomAllotment: false,
        listStockRoomAllotment: [],
      };
    case types.GET_STOCK_ROOM_ALLOTMENT_PENDING:
      return { ...state, loadingGetStockRoomAllotment: true };
    case types.GET_STOCK_ROOM_ALLOTMENT_FULFILLED:
      return {
        ...state,
        errors: {},
        loadingGetStockRoomAllotment: false,
        listStockRoomAllotment: action.payload.data,
      };

    case types.GET_EXPORT_STOCK_ROOM_ALLOTMENT:
      return { ...state };
    case types.GET_EXPORT_STOCK_ROOM_ALLOTMENT_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loadingGetExportStockRoomAllotment: false,
        exportRoomAllotment: '',
      };
    case types.GET_EXPORT_STOCK_ROOM_ALLOTMENT_PENDING:
      return { ...state, loadingGetExportStockRoomAllotment: true };
    case types.GET_EXPORT_STOCK_ROOM_ALLOTMENT_FULFILLED:
      return {
        ...state,
        errors: {},
        loadingGetExportStockRoomAllotment: false,
        exportRoomAllotment: action.payload.data,
      };

    case types.GET_SUPPLIER_LIST:
      return { ...state };
    case types.GET_SUPPLIER_LIST_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getSupplierListStatus: 'failed',
      };
    case types.GET_SUPPLIER_LIST_PENDING:
      return { ...state, loading: true };
    case types.GET_SUPPLIER_LIST_FULFILLED:
      return {
        ...state,
        getSupplierList: action.payload.data,
        loading: false,
        getSupplierListStatus: 'success',
      };

    case types.GET_EXPORT_DATA_ITEM_BY_ID:
      return { ...state };
    case types.GET_EXPORT_DATA_ITEM_BY_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getExportDataItemByIdStatus: false,
      };
    case types.GET_EXPORT_DATA_ITEM_BY_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_EXPORT_DATA_ITEM_BY_ID_FULFILLED:
      return {
        ...state,
        getExportDataItemById: action.payload.data,
        loading: false,
        getExportDataItemByIdStatus: true,
      };
    case types.GET_ACCOMMODATION_ESTIMATE_PRICE:
      return { ...state };
    case types.GET_ACCOMMODATION_ESTIMATE_PRICE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loadingGetAccommodationEstimatePrice: false,
        accommodationEstimatePrice: null,
      };
    case types.GET_ACCOMMODATION_ESTIMATE_PRICE_PENDING:
      return { ...state, loadingGetAccommodationEstimatePrice: true };
    case types.GET_ACCOMMODATION_ESTIMATE_PRICE_FULFILLED:
      return {
        ...state,
        accommodationEstimatePrice: action.payload.data,
        loadingGetAccommodationEstimatePrice: false,
      };
    case types.GET_ACCOMMODATION_ITEM_ESTIMATE_PRICE:
      return { ...state };
    case types.GET_ACCOMMODATION_ITEM_ESTIMATE_PRICE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loadingGetAccommodationItemEstimatePrice: false,
        listAccommodationItemEstimatePrice: [],
      };
    case types.GET_ACCOMMODATION_ITEM_ESTIMATE_PRICE_PENDING:
      return { ...state, loadingGetAccommodationItemEstimatePrice: true };
    case types.GET_ACCOMMODATION_ITEM_ESTIMATE_PRICE_FULFILLED:
      return {
        ...state,
        listAccommodationItemEstimatePrice: action.payload.data,
        loadingGetAccommodationItemEstimatePrice: false,
      };

    default:
      return state;
  }
};

export default reducer;
