import React, { useState, useEffect } from 'react';
import { Modal, ModalActions, Button, Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ButtonClose from '../../../../../components/button/buttonCloseModal';
import TablePrice from './TablePriceReadyPackageFixedprice';
import { copyObject } from '../../../../../scripts/itineraryBuilder/itineraryBuilder';
import InputWithLabel from '../../../../shared/components/common/input/InputWithLabel';
import { PRICE, ERRORS_PRICE, ERRORS_COST } from '../../constant/tierPrice';
import {
  checkEmptyField,
  checkDuplicatePrice,
  editPrice,
} from '../../scripts/scriptTierPrice';
import ModalAlert from '../../../../../components/modal/modalAlert';
import {
  calculateCostMargin,
  calculatePrice,
} from '../../../../../scripts/packageManagement/fixedPackageFunc';

const ModalTierPrice = props => {
  let {
    isOpen,
    handleClose,
    selectedPrice,
    currencies,
    customPrices,
    handleSetCustomPrices,
  } = props;
  let {
    Prices,
    IsTourOperatorFixedPackage,
    SystemMargin,
    TourOperatorMargin,
  } = customPrices;
  const [price, setPrice] = useState(copyObject(PRICE));
  const [errorsPrice, setErrorsPrice] = useState(copyObject(ERRORS_PRICE));
  const [errorsCost, setErrorsCost] = useState(copyObject(ERRORS_COST));
  const [modalAlert, setModalAlert] = useState({
    isOpen,
    header: '',
    message: '',
  });
  const [isChange, setIsChange] = useState(false);
  // ===== use effect =====
  useEffect(() => {
    isOpen && selectedPrice && setPrice(copyObject(selectedPrice));
    // eslint-disable-next-line
  }, [isOpen]);
  // ===== end use effect =====
  // ===== handle change ======
  const handleSave = () => {
    let validateEmpty = checkEmptyField(price, Prices);
    let isDuplicate =
      !validateEmpty.isError && isChange
        ? checkDuplicatePrice(price, Prices)
        : false;
    if (validateEmpty.isError) {
      setErrorsPrice(validateEmpty.errorsPrice);
      setErrorsCost(validateEmpty.errorsCost);
    } else if (isDuplicate) openCloseAlert(true, 'Failed', 'Duplicate price');
    else {
      // proses menyimpan
      if (isChange) {
        if (price.IsAdd && !price.IsEdit) {
          Prices.push(price);
        } else {
          Prices = editPrice(Prices, price, selectedPrice);
        }
      }
      handleSetCustomPrices({ ...customPrices, Prices: Prices });
      closeModal();
    }
  };
  const handleChange = (e, { name, value }) => {
    handleChangePrice(value, name);
  };
  const handleChangeCost = (value, name) => {
    let newValuePrice = calculatePrice(value, SystemMargin, TourOperatorMargin);
    setPrice({
      ...price,
      [name]: newValuePrice,
      Cost: { ...price.Cost, [name]: value },
      IsEdit: selectedPrice ? true : false,
    });
    setIsChange(true);
  };
  const handleChangePrice = (value, name) => {
    let newValueCost = calculateCostMargin(value, SystemMargin);
    setPrice({
      ...price,
      [name]: value,
      Cost: { ...price.Cost, [name]: newValueCost },
      IsEdit: selectedPrice ? true : false,
    });
    setIsChange(true);
  };
  const closeModal = () => {
    setPrice(copyObject(PRICE));
    setIsChange(false);
    setErrorsPrice(copyObject(ERRORS_PRICE));
    setErrorsCost(copyObject(ERRORS_COST));
    handleClose();
  };
  // ===== end handle change =====
  const openCloseAlert = (isOpen, header, message) => {
    isOpen = isOpen === true;
    setModalAlert({
      isOpen,
      header: isOpen ? header : '',
      message: isOpen ? message : '',
    });
  };
  return (
    <Modal
      dimmer="blurring"
      inverted
      open={isOpen}
      onClose={closeModal}
      size="medium"
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        {`${selectedPrice ? 'Detail' : 'Add'} Price`}
        <ButtonClose onClose={closeModal} />
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group widths="equal">
            <InputWithLabel
              type="number"
              label="Minimum Pax"
              placeholder="1"
              name="MinimumPax"
              value={price.MinimumPax}
              validate={errorsPrice}
              handleChange={handleChange}
            />
            <InputWithLabel
              type="select"
              label="Currency"
              name="CurrencyId"
              value={price.CurrencyId}
              options={currencies}
              validate={errorsPrice}
              handleChange={handleChange}
            />
          </Form.Group>
        </Form>
        <TablePrice
          price={price}
          handelChangePrice={handleChangePrice}
          errors={errorsPrice}
          cost={price.Cost}
          disabledCost={IsTourOperatorFixedPackage === true}
          disabled={!IsTourOperatorFixedPackage}
          errorsCost={errorsCost}
          handleChangeCost={handleChangeCost}
        />
        <ModalAlert
          openModal={modalAlert.isOpen}
          handleClose={openCloseAlert}
          header={modalAlert.header}
          content={modalAlert.message}
        />
      </Modal.Content>
      <ModalActions>
        <Button content="Save" primary onClick={handleSave} />
      </ModalActions>
    </Modal>
  );
};
ModalTierPrice.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedPrice: PropTypes.object,
  currencies: PropTypes.array,
  customPrices: PropTypes.object,
  handleSetCustomPrices: PropTypes.func,
};
export default React.memo(ModalTierPrice);
