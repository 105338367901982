import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
// import { getGuests } from '../genericFunction/convertPackage';
import {
  postJoinTourOnBehalf,
  postCreateTourTransactionAction,
  postConfirmTransaction,
  postEditQuotationAction,
  getDownloadScheduleByTransactionIdAction,
  postEmailSendConfirmation,
} from '../../../../../actions/tourTransactionAction';
import { getGenerateResouceBookingAction } from './../../../../shared/actions/resourceBookingAction';
import { Redirect } from 'react-router-dom';
import {
  validationCustom,
  validationFixed,
} from '../../../../../scripts/validation';
import { convertDataPostSpecialAdjustment } from '../../../../../scripts/specialAdjustment';
import {
  getGuests,
  convertDataBookNowFixed,
  getAdditionalItems,
} from '../../../../../scripts/convertPackage';
import ModalAlert from '../../../../../components/modal/modalAlert';
import ModalConfirm from '../../../../../components/modal/modalConfirmationTwoButton';
import {
  validateDailyProgramExcursionTime,
  transportValidation,
  validateDailyProgramOverloadTime,
  destructureAdditionalServices,
} from '../../../../../scripts/itineraryBuilder/dailyProgram';
// import ModalPayment from '../../modal/ModalPaymentTransaction';
import { postAdditionalItems } from '../../../../../actions/tourTransactionAction';
// import ButtonWithPopup from '../../../../../components/button/buttonWithPopUp';
import { getTime } from '../../../../../components/genericFunction/timeHelper';
import ModalCancellationReason from '../../modal/ModalCancellationReason';
import { isCheckInDifferentDate } from '../../../../../scripts/itineraryBuilder/dailyProgramValidation';
import ButtonDropdown from '../../../../../components/common/buttonDropdown';
import {
  CREATE_FIXED,
  CREATE_FIXED_PRICE,
  CREATE_CUSTOM,
  CREATE_READY,
  CREATE_QUOTATION,
  CREATE_SIMILAR,
  EDIT_FIXED,
  EDIT_CUSTOM_READY_FIXEDPRICE,
  CURRENT_PACKAGE_DISABLE,
  NOT_BOOKING_CANCEL,
  CURRENT_PACKAGE_ENABLE,
  DISABLE_EDIT,
  PAY,
  SEND_MANUAL_EMAIL,
  BOOKING_ON_HOLD,
  GENERATE_RBL,
} from './../constants/allButton';
import { isExpiredDate } from './../../../../../scripts/moment';
import ModalManualSendEmail from '../../modal/ModalManualSendEmail';
// const styleButtonRed = {
//   background: '#ff3d3d',
//   color: 'white',
// };

// const styleButtonBlue = {
//   background: '#2962ff',
//   color: 'white',
// };

// const styleButtonYellow = {
//   background: '#ff9800',
//   color: 'white',
// };
class AllButtonTransaction extends Component {
  state = {
    goTo: false,
    linkGoTo: '',
    openModalAlert: false,
    openModalAlertValidation: false,
    messageModalAlert: [],
    openConfirmation: false,
    messageConfirmation: '',
    isFailed: false,
    isCreateQuotation: false,
    IsSaveTransaction: false,
    openModalCancelBooking: false,
    loadingPostCancel: false,
    CancellationReasonId: null,
    CancellationReasonNote: '',
    isChooseSendEmail: false,
    isSendMail: false,
    openSendManualEmail: false,
    contentOk: '',
    contentNo: '',
    idRBL: null,
    isSendMailManual: false,
  };

  bookFixedPackage = () => {
    let requestDemoPrice = { ...this.props.requestDemoPrice };
    const {
      guests,
      roomAllocation,
      guestList,
      commission,
      supplements,
      onBehalf,
      tourNote,
      specialAdjusments,
      tourInformation,
    } = this.props;
    let dataBookNow = {};
    if (this.props.currentPackage.type === 'Fixed') {
      dataBookNow = convertDataBookNowFixed(
        commission.splitCommision,
        commission.printCommision,
        guests,
        roomAllocation,
        supplements,
        guestList
        // tourInformation.tourNote
      );
      dataBookNow.TourNote = tourNote;
      dataBookNow.CompanyCode = onBehalf.customerCode;
      dataBookNow.AdditionalItems = specialAdjusments.filter(e => !e.IsDelete);
      dataBookNow.EmailSendConfirmed = this.state.isSendMail;
      onBehalf.userId && (dataBookNow.UserProfileId = onBehalf.userId);
      // dataBookNow.Guests = getGuests(this.props.guestList);
    } else {
      requestDemoPrice.Guests = getGuests(this.props.guestList);
      requestDemoPrice.TourNote = tourNote;
      requestDemoPrice.EmailSendConfirmed = this.state.isSendMail;
      requestDemoPrice.GroupType = tourInformation.groupCapacity;
      requestDemoPrice.AdditionalItems = specialAdjusments.filter(
        e => !e.IsDelete
      );
    }

    this.props
      .postJoinTourOnBehalf(
        this.props.currentPackage.id,
        this.props.currentPackage.type === 'Fixed'
          ? dataBookNow
          : requestDemoPrice,
        this.props.currentPackage.type === 'FixedDateVariable'
          ? 'VariableDate'
          : ''
      )
      .then(res => this.handleSaveMessage(res.value.data))
      .catch(error => {
        this.setState({
          openModalAlert: true,
          contentAlert: error.response.data.Message,
          headerAlert: 'Failed',
          isFailed: true,
        });
      });
  };

  handleSaveMessage = (data, dataMessage) => {
    const { currentPackage } = this.props;
    let message = '';
    if (data ? data.BookingDetailSum : false) {
      if (data.BookingDetailSum.Status === 'Booking_created') {
        message =
          'Booking number ' +
          data.BookingDetailSum.Id +
          ' successfully created';
      } else if (data.BookingDetailSum.Status === 'Booking_draft') {
        message =
          'Quotation number ' +
          data.BookingDetailSum.Id +
          ' successfully created';
      } else {
        message = 'Your booking is on hold number ' + data.BookingDetailSum.Id;
      }
    } else if (dataMessage ? true : false) {
      message = dataMessage;
    } else {
      message = 'Quotation saved';
    }
    this.setState(
      {
        openModalAlert: true,
        contentAlert: message,
        headerAlert: 'Successfully',
      },
      () => {
        if (this.state.isSendMail)
          this.props.postEmailSendConfirmation({
            tourTransactionId:
              data.BookingDetailSum && data.BookingDetailSum.Id
                ? data.BookingDetailSum.Id
                : currentPackage.id,
            emailSendConfirmed: true,
          });
      }
    );
  };

  handleFailedMessage = () => {
    this.setState({
      openModalAlert: true,
      contentAlert: this.props.errors.global,
      headerAlert: 'Failed',
      isFailed: true,
    });
  };
  // handleSaveTransaction = () => {
  handleSaveTransaction = async () => {
    const {
      requestDemoPrice,
      currentPackage,
      foc,
      additionalServices,
      specialAdjusments,
      tourInformation,
      tourNote,
    } = this.props;
    let _foc = foc ? (foc.Qty ? foc : undefined) : undefined;
    let _additionalServices = destructureAdditionalServices(additionalServices);
    let data = {
      ...requestDemoPrice,
      Title: tourInformation.tourName,
      TourNote: tourNote,
      GroupType: tourInformation.groupCapacity,
      TourCategoryId: tourInformation.tourType,
      TourPaxTypeId: tourInformation.tourCategory,
      FoC: _foc,
      AdditionalServices: _additionalServices,
      EmailSendConfirmed: this.state.isSendMail,
      IsReadyPackage: currentPackage.type === 'Ready',
      ReadyPackageId:
        currentPackage.type === 'Ready' ? currentPackage.id : null,
      AdditionalItems: specialAdjusments.filter(e => !e.IsDelete),
    };
    let listGuest = await getGuests(this.props.guestList);
    let quotation = {
      ...data,
      IsQuotation: true,
      Guests: listGuest,
      FoC: _foc,
      AdditionalServices: _additionalServices,
      AdditionalItems: getAdditionalItems(data.AdditionalItems || []),
    };
    let editQuotation = {
      ...data,
      TourTransactionId: currentPackage.id,
      Guests: listGuest,
      FoC: _foc,
      AdditionalServices: _additionalServices,
      AdditionalItems: getAdditionalItems(data.AdditionalItems || []),
    };
    if (
      currentPackage.type === 'Fixed' ||
      currentPackage.type === 'FixedDateVariable'
    ) {
      this.bookFixedPackage();
    } else {
      if (currentPackage.status === 'edit') {
        if (currentPackage.type === 'Custom') {
          this.props
            .postCreateTourTransactionAction(data)
            .then(res => this.handleSaveMessage(res.value.data))
            .catch(() => this.handleFailedMessage());
        } else {
          if (this.state.isBookingQuotation) {
            editQuotation.AcceptQuotationAtOnce = true;
          }
          this.props
            .postEditQuotationAction(currentPackage.id, editQuotation)
            .then(res => this.handleSaveMessage(res.value.data))
            .catch(() => this.handleFailedMessage());
        }
      } else {
        if (this.state.isCreateQuotation) {
          this.props
            .postCreateTourTransactionAction(quotation)
            .then(res => this.handleSaveMessage(res.value.data))
            .catch(() => this.handleFailedMessage());
        } else {
          this.props
            .postCreateTourTransactionAction(
              // currentPackage.type === 'Custom' ? data : quotation
              currentPackage.type === 'Quotation' ? quotation : data
            )
            .then(res => this.handleSaveMessage(res.value.data))
            .catch(() => this.handleFailedMessage());
        }
      }
    }
  };
  //=============================== action button in top and bottom page =========
  // validasi custom package =  customer on be half, tour operator sama guest
  // validasi fixed package = semua field di validasi
  // ketika save data setelah berhasil, pindah ke page tour transaction
  //
  handleClickAction = async () => {
    const { currentPackage } = this.props;
    const { typeButton } = this.state;
    let cancelTransaction = {
      Id: currentPackage.id,
      Confirmation: 'Cancel',
      PaymentChoice: 0,
      CancellationReasonId: this.state.CancellationReasonId,
      CancellationReasonNote: this.state.CancellationReasonNote,
    };
    let validation = null;
    await this.setState({
      openConfirmation: false,
    });
    switch (typeButton) {
      case 'back':
        this.setState({
          ...this.state,
          goTo: true,
          linkGoTo: '/tour-transaction',
        });
        break;
      case 'save':
        // if (currentPackage.Type === 'Fixed') {
        //   this.handleBookNow();
        // } else {
        //   this.props
        //     .postCreateTourTransactionAction(data)
        //     .then(res => alert(res.value.data.BookingDetailSum.Id))
        //     .catch(() => alert('gagal'));
        // }
        // ------------------ handle save ----------------------------
        if (currentPackage.type !== 'Fixed') {
          validation = validationCustom(
            this.props.tourOperator,
            this.props.guestList,
            this.props.onBehalf,
            this.props.additionalServices
          );
          if (validateDailyProgramExcursionTime(this.props.dailyProgram)) {
            validation.isError = true;
            validation.messages.push(
              'Please check your Itinerary, something wrong with excursion time'
            );
          }
          if (transportValidation(this.props.dailyProgram)) {
            validation.isError = true;
            validation.messages.push(
              'Please check your Itinerary, something wrong with transportation'
            );
          }
          if (validateDailyProgramOverloadTime(this.props.dailyProgram)) {
            validation.isError = true;
            validation.messages.push(
              'Please check your Itinerary, something wrong with activity time'
            );
          }
          if (isCheckInDifferentDate(this.props.dailyProgram)) {
            validation.isError = true;
            validation.messages.push(
              'Please check your Itinerary, something wrong with check in time'
            );
          }
        } else if (currentPackage.type === 'Fixed') {
          validation = validationFixed(
            this.props.guests,
            this.props.commission.splitCommision,
            this.props.availablePax,
            this.props.guestList,
            this.props.onBehalf
          );
        }
        if (validation.isError) {
          this.setState({
            ...this.state,
            openModalAlertValidation: validation.isError,
            messageModalAlert: validation.messages,
          });
        } else {
          this.handleSaveTransaction();
        }
        // --------------- end handle save ----------------------------
        break;
      case 'cancel':
        this.setState({
          loadingPostCancel: true,
        });
        this.props
          .postConfirmTransaction(cancelTransaction)
          .then(() =>
            this.setState(
              {
                loadingPostCancel: false,
                openModalAlert: true,
                contentAlert: `Package with booking code ${currentPackage.id} has been cancelled`,
                headerAlert: 'Cancellation Success',
              },
              () => {
                if (this.state.isSendMail) {
                  this.props.postEmailSendConfirmation({
                    tourTransactionId: currentPackage.id,
                    emailSendConfirmed: true,
                  });
                }
              }
            )
          )
          .catch(() =>
            this.setState({
              loadingPostCancel: false,
              openModalAlert: true,
              contentAlert: 'Cancelled transaction failed',
              headerAlert: 'Failed',
            })
          );

        break;
      case 'quotation':
        validation = validationCustom(
          this.props.tourOperator,
          this.props.guestList,
          this.props.onBehalf,
          this.props.additionalServices
        );
        if (transportValidation(this.props.dailyProgram)) {
          validation.isError = true;
          validation.messages.push(
            'Please check your Itinerary, something wrong with transportation'
          );
        }
        if (isCheckInDifferentDate(this.props.dailyProgram)) {
          validation.isError = true;
          validation.messages.push(
            'Please check your Itinerary, something wrong with check in time'
          );
        }
        // quotation.listGuest = await getGuests(this.props.guestList);
        validation.isError
          ? this.setState({
              ...this.state,
              openModalAlertValidation: validation.isError,
              messageModalAlert: validation.messages,
            })
          : this.handleSaveTransaction();
        // this.props
        //     .postCreateTourTransactionAction(quotation)
        //     .then(res => this.handleSaveMessage(res.value.data))
        //     .catch(() => this.handleFailedMessage());
        // .then(res => alert(res.value.data.BookingDetailSum.Id));
        break;
      case 'similar':
        this.setState({
          ...this.state,
          goTo: true,
          linkGoTo: `/tour-transaction/create/${
            this.props.isReadyFixedPrice ? 'FixedDateVariable/' : 'Similar/'
          }${
            this.props.isReadyFixedPrice
              ? this.props.transactionDetail.BookingDetailSum.BookingTemplateRef
              : currentPackage.id
          }`,
          // '/tour-transaction/create/Similar/' + currentPackage.id,
        });
        window.location.reload();
        break;
      case 'downloadSchedule':
        this.props
          .getDownloadScheduleByTransactionIdAction(currentPackage.id)
          .then(res => window.open(res.value.data));
        // this.setState({
        //   ...this.state,
        //   goTo: true,
        //   linkGoTo: '/tour-transaction/create/Similar/' + currentPackage.id,
        // });
        // window.location.reload();
        break;
      case 'onHold':
        this.props
          .postConfirmTransaction({
            Id: currentPackage.id,
            Confirmation: 'Approve',
            PaymentChoice: 0,
          })
          .then(() =>
            this.setState({
              openModalAlert: true,
              contentAlert: 'Transaction successfully approved',
              headerAlert: 'Successfully',
              IsSaveTransaction: true,
            })
          )
          .catch(error =>
            this.setState({
              openModalAlert: true,
              contentAlert: error
                ? error.response.data.Message
                : 'Transaction failed to approve',
              headerAlert: 'Failed',
            })
          );
        break;
      case 'edit':
        break;
      case 'sendMail':
        this.handleSendMail();
        break;
      case 'generateRBL':
        this.handleGenerateResource();
        break;
      default:
        alert('Are you sure create transaction?');
    }
  };

  handleClickConfirmation = (name = '') => {
    let message = '';
    let isExpired = false;
    const { tourInformation, isReadyFixedPrice, currentPackage } = this.props;
    switch (name) {
      case 'back':
        message =
          'Are you sure you left this transaction? the filled in data will be lost';
        break;
      case 'save':
        message =
          // 'Are you sure the data that is filled in is correct? transaction will be saved';
          'Make sure the data that is filled in is correct, transaction will be create';
        break;
      case 'cancel':
        message = 'Are you sure to cancel this transaction?';
        break;
      case 'quotation':
        message =
          'Make sure the data that is filled in is correct, quotation will be create';
        //'Do you want to make a quotation from this transaction?';
        break;
      case 'similar':
        if (isReadyFixedPrice) {
          isExpired = getTime(new Date()) > getTime(tourInformation.expiredOn);
        }
        isExpired
          ? (message = 'Package is already expired')
          : (message = 'Do you want to make a similar from this transaction?');
        break;
      case 'edit':
        // message = 'Do you want to save this Quotation?';
        message =
          'Make sure the data that is filled in is correct, Quotation will be save';
        break;
      case 'downloadSchedule':
        message = 'Do you want to download schedule this package?';
        break;
      case 'onHold':
        message = 'Are you sure you approve this transaction ?';
        break;
      case 'sendMail':
        message = `Are you sure want to send email notification to travel agent? Booking code : ${currentPackage.id} `;
        break;
      case 'generateRBL':
        message = `Are you sure want to generate resource?`;
        break;
      default:
        message =
          // 'Are you sure the data that is filled in is correct? transaction will be saved';
          'Make sure the data that is filled in is correct, transaction will be create';
    }
    this.setState({
      openConfirmation: !isExpired,
      typeButton: name === 'bookQuotation' || name === 'edit' ? 'save' : name,
      messageConfirmation: message,
      isBookingQuotation: name === 'bookQuotation',
      isCreateQuotation: name === 'quotation',
      openModalAlert: isExpired,
      contentAlert: message,
      headerAlert: 'Error',
      isChooseSendEmail:
        name === 'save' ||
        name === 'quotation' ||
        name === 'edit' ||
        name === 'bookQuotation',
      contentOk:
        name === 'save' || name === 'bookQuotation'
          ? 'Book'
          : name === 'edit'
          ? 'Save'
          : name === 'quotation'
          ? 'Create Quotation'
          : '',
      contentNo:
        name === 'save' ||
        name === 'edit' ||
        name === 'quotation' ||
        name === 'bookQuotation'
          ? 'Cancel'
          : '',
    });
  };

  handleClose = () => {
    this.setState({
      openConfirmation: false,
      openModalAlert: false,
      openModalCancelBooking: false,
      openSendManualEmail: false,
    });
  };
  handleCloseAlert = () => {
    const { IsSaveTransaction, isSendMailManual, idRBL } = this.state;
    if (IsSaveTransaction) {
      this.setState({
        openModalAlert: false,
      });
      window.location.reload();
    } else if (isSendMailManual) {
      this.setState({
        openModalAlert: false,
        isSendMail: false,
        goTo: !this.state.isFailed,
        linkGoTo: !this.state.isFailed ? '/tour-transaction' : null,
        isSendMailManual: false,
      });
    } else if (idRBL) {
      this.setState({
        ...this.state,
        goTo: !this.state.isFailed,
        linkGoTo: !this.state.isFailed
          ? `/Resource-Booking-Detail/${idRBL}`
          : null,
        openModalAlert: false,
      });
    } else {
      this.setState({
        ...this.state,
        goTo: !this.state.isFailed,
        linkGoTo: !this.state.isFailed ? '/tour-transaction' : null,
        openModalAlert: false,
      });
    }
  };
  //==============================================================================
  handleCloseAlertValidation = () =>
    this.setState({
      ...this.state,
      openModalAlertValidation: !this.state.openModalAlertValidation,
    });

  handleClickPayment = () => {
    this.props.handleOpenModalPayement();
  };

  handleClickCancel = () => {
    this.setState({
      openModalCancelBooking: true,
      typeButton: 'cancel',
    });
  };

  handleEditTransaction = () => {
    let { currentPackage } = this.props;
    this.setState({ IsSaveTransaction: true });
    currentPackage.disabled
      ? this.props.setCurrentPackage({
          ...currentPackage,
          disabled: !currentPackage.disabled,
        })
      : this.props
          .postAdditionalItems(
            convertDataPostSpecialAdjustment(this.props.specialAdjusments)
          )
          .then(() => {
            this.handleSaveMessage(null, 'Success Edit Transaction');
          })
          .catch(error =>
            this.setState({
              openModalAlert: true,
              contentAlert: error
                ? error.response.data.Message
                : 'Transaction failed to approve',
              headerAlert: 'Failed',
            })
          );
  };

  handleSaveCancellationReason = async data => {
    await this.setState({
      CancellationReasonId: data.CancellationReasonId,
      CancellationReasonNote: data.CancellationReasonNote,
      openSendManualEmail: true,
    });
    // this.handleClickAction();
  };

  handleSendMail = () => {
    const { currentPackage } = this.props;
    this.props
      .postEmailSendConfirmation({
        tourTransactionId: currentPackage.id,
        emailSendConfirmed: true,
      })
      .then(() => {
        this.setState({
          openModalAlert: true,
          contentAlert: 'Email Has Been Sent',
          headerAlert: 'Success',
          isSendMailManual: true,
        });
      })
      .catch(() => {
        this.setState({
          openModalAlert: true,
          contentAlert: 'Send Email',
          headerAlert: 'Failed',
          isSendMailManual: true,
        });
      });
  };
  handleGenerateResource = () => {
    const { currentPackage } = this.props;
    this.props
      .getGenerateResouceBookingAction({
        tourTransactionCode:
          currentPackage.type !== 'Fixed' ? currentPackage.id : 0,
        bookingTemplateIdRef: 0,
        resourceBookingType: '0',
      })
      .then(() => {
        this.setState({
          openModalAlert: true,
          contentAlert: 'Generated resource successfully',
          headerAlert: 'Success',
          idRBL: this.props.resourceBooking.ResourceBookingId,
          isFailed: false,
        });
      })
      .catch(() => {
        this.setState({
          openModalAlert: true,
          contentAlert: this.props.errorResourceBooking
            ? this.props.errorResourceBooking.global
            : 'Failed generate resource',
          headerAlert: 'Failed',
          idRBL: null,
          isFailed: true,
        });
      });
  };
  handleDropdown = (e, { value, name }) => {
    value === 'handleClickConfirmation'
      ? this.handleClickConfirmation(name)
      : value === 'handleEditTransaction'
      ? this.handleEditTransaction()
      : value === 'handleClickCancel'
      ? this.handleClickCancel()
      : value === 'handleClickPayment' && this.handleClickPayment();
  };
  selectListDropdown = () => {
    let {
      currentPackage,
      bookingStatus,
      tourInformation,
      isOperation,
      isSales,
      isAdministrator,
      isFinance,
      resourceBookingByTransaction,
      transactionDetail,
    } = this.props;
    let { status, type } = currentPackage;
    let isCreate = status === 'create';
    let isEdit = status === 'edit';
    let isFixed = type === 'Fixed';
    let isReady = type === 'Ready';
    let isCustom = type === 'Custom';
    let isSimilar = type === 'Similar';
    let isReadyFixedPrice = type === 'FixedDateVariable';
    // let isQuotation = this.props.currentPackage.type === 'Quotation';
    let isQuotation = bookingStatus === 'Booking_draft';
    let isConfirmedByStatus = bookingStatus === 'Booking_Confirmed';
    let isBookingCancelled = bookingStatus === 'Booking_Cancelled';
    let isBookingComplete = bookingStatus === 'Booking_Completed';
    let isBookingOnHold = bookingStatus === 'Booking_hold';
    let isBookingDpConfirm = bookingStatus === 'Booking_DP_Confirmed';
    let isBookingInPayment = bookingStatus === 'Booking_In_Payment';
    const getCode = localStorage.getItem('code');
    let sales = getCode !== '';
    let isConfirmed = sales || isConfirmedByStatus;
    let isDisableEdit = !isQuotation && isEdit ? true : false;
    let isExpired = isExpiredDate({
      expiredDate: tourInformation ? tourInformation.expiredOn : '',
    });
    let option = [];
    if (isCreate || isQuotation) {
      switch (true) {
        case isFixed:
          option = CREATE_FIXED;
          break;
        case isReadyFixedPrice:
          option = CREATE_FIXED_PRICE;
          break;
        case isReady:
          option = CREATE_READY;
          break;
        case isCustom:
          option = CREATE_CUSTOM;
          break;
        case isSimilar:
          option = CREATE_SIMILAR;
          break;
        case transactionDetail.BookingDetailSum.Quotation.IsAccepted:
          option = [];
          break;
        default:
          option = CREATE_QUOTATION;
      }
    } else {
      if (isFixed) option = [...EDIT_FIXED];
      else if (!isOperation && !isFinance && !isFixed)
        option = [...EDIT_CUSTOM_READY_FIXEDPRICE];
      if (
        currentPackage.disabled &&
        !isBookingComplete &&
        !isBookingCancelled &&
        !isOperation &&
        !isFinance
      )
        option.push(CURRENT_PACKAGE_DISABLE);
      else if (!currentPackage.disabled && !isOperation && !isFinance)
        option.push(CURRENT_PACKAGE_ENABLE);
      if (
        !isBookingCancelled &&
        !isBookingComplete &&
        !isOperation &&
        !isFinance
      )
        option.push(NOT_BOOKING_CANCEL);
      if (!isDisableEdit && !isOperation && !isFinance)
        option.push(DISABLE_EDIT);
      if (
        !isConfirmed &&
        !isBookingComplete &&
        !isBookingCancelled &&
        !isBookingOnHold &&
        !isExpired &&
        isAdministrator
      )
        option.push(PAY);
      if (isBookingOnHold && isOperation) option.push(BOOKING_ON_HOLD);
      if (isSales) option.push(SEND_MANUAL_EMAIL);
      if (
        !isFixed &&
        isOperation &&
        (isConfirmedByStatus ||
          isBookingDpConfirm ||
          isBookingInPayment ||
          isBookingComplete) &&
        (!resourceBookingByTransaction || !resourceBookingByTransaction.Id)
      )
        option.push(GENERATE_RBL);
    }
    return option;
  };

  handleSendManualEmail = value => {
    // this.setState({ isSendMail: !this.state.isSendMail });
    this.setState({ isSendMail: value === 1 ? true : false });
  };

  handleSendManualEmailOnCancel = () => {
    this.setState({ openSendManualEmail: false }, () => {
      this.handleClickAction();
    });
  };

  render() {
    let isCreate = this.props.currentPackage.status === 'create';
    // let isEdit = this.props.currentPackage.status === 'edit';
    // let isFixed = this.props.currentPackage.type === 'Fixed';
    // let isReadyFixedPrice =
    //   this.props.currentPackage.type === 'FixedDateVariable';
    // let isQuotation = this.props.currentPackage.type === 'Quotation';
    let isQuotation = this.props.bookingStatus === 'Booking_draft';
    // let isConfirmedByStatus = this.props.bookingStatus === 'Booking_Confirmed';
    // let isBookingCancelled = this.props.bookingStatus === 'Booking_Cancelled';
    // let isBookingComplete = this.props.bookingStatus === 'Booking_Completed';
    // let isBookingOnHold = this.props.bookingStatus === 'Booking_hold';
    // const getCode = localStorage.getItem('code');
    // let sales = getCode !== '';
    // let isConfirmed = sales || isConfirmedByStatus;
    // let isDisableEdit = !isQuotation && isEdit ? true : false;
    let disabledDropdown = this.props.disabledDropdown;

    let listDropdown = this.selectListDropdown();
    if (this.state.goTo) {
      return <Redirect to={this.state.linkGoTo} />;
    }
    const { loadingJoinTourOnBeHalf, loading, loadingGetDuration } = this.props;
    const {
      openConfirmation,
      messageConfirmation,
      openModalAlert,
      headerAlert,
      contentAlert,
      messageModalAlert,
      openModalCancelBooking,
      loadingPostCancel,
      openSendManualEmail,
    } = this.state;
    // const { reloadPage } = this.props;
    let loadingButton =
      loadingJoinTourOnBeHalf || loading || loadingGetDuration;
    return (
      // <Button.Group primary floated="right">
      //   <Button>Book Now</Button>
      //   <Dropdown
      //     className="button icon"
      //     floating
      //     options={options}
      //     trigger={<React.Fragment />}
      //     onClick={this.handleFloated}
      //   />
      // </Button.Group>
      <Grid style={{ float: 'right' }}>
        <Button.Group floated="right">
          <Button
            // primary
            negative
            name="back"
            value={'handleClickConfirmation'}
            content="Back"
            onClick={this.handleDropdown}
            position="top center"
            style={{ marginRight: '10px' }}
            // style={{ padding: '10px 40px', borderRadius: '8px 0px 0px 8px' }}
          />

          {isCreate ||
          (isQuotation &&
            this.props.transactionDetail &&
            !this.props.transactionDetail.BookingDetailSum.Quotation
              .IsAccepted) ? (
            <Button
              primary
              name={isCreate ? 'save' : isQuotation ? 'bookQuotation' : ''}
              value={'handleClickConfirmation'}
              content={'Book Now'}
              onClick={this.handleDropdown}
              position="top center"
              style={{ marginRight: '10px' }}
              loading={loadingButton}
              // style={{ padding: '10px 40px', borderRadius: '8px 0px 0px 8px' }}
            />
          ) : null}
          {listDropdown.length > 0 ? (
            listDropdown.length === 1 ? (
              <Button
                // primary
                positive
                name={listDropdown[0].value}
                value={listDropdown[0].type}
                content={listDropdown[0].title}
                onClick={this.handleDropdown}
                position="top center"
                style={{ marginRight: '10px' }}
                loading={loadingButton}
                // style={{ padding: '10px 40px', borderRadius: '8px 0px 0px 8px' }}
              />
            ) : (
              <ButtonDropdown
                dropdowns={listDropdown}
                title={`Action `}
                handleClick={this.handleDropdown}
                className="item"
                dropdownStyles={{
                  background: '#54B6FF',
                  color: 'white',
                  zIndex: '99999',
                  padding: '10px 25px',
                  // borderRadius: '0px 8px 8px 0px',
                }}
                disabled={disabledDropdown}
                loading={loadingButton}
              />
            )
          ) : null}
        </Button.Group>

        {/* {isCreate || isQuotation ? (
          <div>
            <ButtonWithPopup
              icon="angle left"
              style={styleButtonRed}
              name="back"
              contentPopUp="Back"
              onClick={this.handleClickConfirmation}
              position="top center"
            />
            {!isQuotation && !isFixed && !isReadyFixedPrice ? (
              <ButtonWithPopup
                icon="sticky note outline"
                positive
                name="quotation"
                contentPopUp="Create Quotation"
                onClick={this.handleClickConfirmation}
                position="top center"
              />
            ) : (
              isQuotation && (
                <ButtonWithPopup
                  icon="save outline"
                  positive
                  name="bookQuotation"
                  contentPopUp="Book Now"
                  onClick={this.handleClickConfirmation}
                  position="top center"
                />
              )
            )}
            <ButtonWithPopup
              icon="save outline"
              primary
              name="save"
              contentPopUp={isQuotation ? 'Save Quotation' : 'Book Now'}
              onClick={this.handleClickConfirmation}
              position="top center"
            />
          </div>
        ) : (
          <div>
            <ButtonWithPopup
              icon="angle left"
              primary
              name="back"
              contentPopUp="Back"
              onClick={this.handleClickConfirmation}
              position="top center"
            />
            <ButtonWithPopup
              icon={currentPackage.disabled ? 'edit outline' : 'save outline'}
              primary
              // name="Save"
              name="save"
              contentPopUp={
                currentPackage.disabled
                  ? 'Edit Special Adjustment'
                  : 'Save Special Adjustment'
              }
              onClick={this.handleEditTransaction}
              position="top center"
            />
            {!isBookingCancelled && (
              <ButtonWithPopup
                icon={'cancel'}
                style={styleButtonRed}
                name="cancel"
                contentPopUp="Cancel Booking"
                // onClick={this.handleClickConfirmation}
                onClick={this.handleClickCancel}
                position="top center"
              />
            )}
            {!isFixed && (
              <ButtonWithPopup
                icon="copy outline"
                positive
                name="similar"
                contentPopUp="Create Similar Package"
                onClick={this.handleClickConfirmation}
                position="top center"
              />
            )}
            {!isConfirmed &&
            !isBookingComplete &&
            !isBookingCancelled &&
            !isBookingOnHold ? (
              <ButtonWithPopup
                icon="money bill alternate outline"
                style={styleButtonYellow}
                name="pay"
                contentPopUp="Pay"
                onClick={this.handleClickPayment}
                disabled={isConfirmed}
                position="top center"
              />
            ) : null}
            {!isDisableEdit && (
              <ButtonWithPopup
                icon="edit outline"
                style={styleButtonBlue}
                name="edit"
                contentPopUp="Edit"
                onClick={this.handleClickConfirmation}
                disabled={isDisableEdit}
                position="top center"
              />
            )}
            {isBookingOnHold && (
              <ButtonWithPopup
                icon="check"
                style={styleButtonBlue}
                name="onHold"
                contentPopUp="Approve"
                onClick={this.handleClickConfirmation}
                // disabled={isDisableEdit}
                position="top center"
              />
            )}
          </div>
        )} */}
        <ModalAlert
          openModal={this.state.openModalAlertValidation}
          handleClose={this.handleCloseAlertValidation}
          header="Failed"
          content={messageModalAlert}
          nameButton="OK"
          isArrayContentAlert={true}
        />
        <ModalConfirm
          openModal={openConfirmation}
          size="tiny"
          close={this.handleClose}
          confirm={this.handleClickAction}
          message={messageConfirmation}
          isChooseSendEmail={this.state.isChooseSendEmail}
          handleSendManualEmail={this.handleSendManualEmail}
          contentOk={this.state.isChooseSendEmail && this.state.contentOk}
          contentNo={this.state.isChooseSendEmail && this.state.contentNo}
          isSendMail={this.state.isSendMail}
        />
        <ModalAlert
          openModal={openModalAlert}
          handleClose={this.handleCloseAlert}
          header={headerAlert}
          content={contentAlert}
          nameButton="OK"
        />
        <ModalCancellationReason
          openModal={openModalCancelBooking}
          handleSave={this.handleSaveCancellationReason}
          handleClose={this.handleClose}
          loadingPostCancel={loadingPostCancel}
        />
        <ModalManualSendEmail
          openModal={openSendManualEmail}
          size="tiny"
          close={this.handleClose}
          confirm={this.handleSendManualEmailOnCancel}
          isChooseSendEmail={this.state.isChooseSendEmail}
          handleSendManualEmail={this.handleSendManualEmail}
          isSendMail={this.state.isSendMail}
        />
      </Grid>
    );
  }
}
AllButtonTransaction.propTypes = {
  currentPackage: PropTypes.object,
  guests: PropTypes.object,
  roomAllocation: PropTypes.object,
  guestList: PropTypes.array,
  commission: PropTypes.object,
  supplements: PropTypes.array,
  postJoinTourOnBehalf: PropTypes.func,
  requestDemoPrice: PropTypes.object,
  postCreateTourTransactionAction: PropTypes.func,
  tourOperator: PropTypes.object,
  onBehalf: PropTypes.object,
  availablePax: PropTypes.number,
  postConfirmTransaction: PropTypes.func,
  postEditQuotationAction: PropTypes.func,
  dailyProgram: PropTypes.object,
  reloadPage: PropTypes.func,
  bookingStatus: PropTypes.string,
  errors: PropTypes.object,
  getDownloadScheduleByTransactionIdAction: PropTypes.func,
  specialAdjusments: PropTypes.array,
  postAdditionalItems: PropTypes.func,
  setCurrentPackage: PropTypes.func,
  isReadyFixedPrice: PropTypes.bool,
  foc: PropTypes.object,
  tourInformation: PropTypes.object,
  transactionDetail: PropTypes.object,
  tourNote: PropTypes.string,
  // foc: PropTypes.object,
  additionalServices: PropTypes.array,
  handleOpenModalPayement: PropTypes.func,
  postEmailSendConfirmation: PropTypes.func,
  isSales: PropTypes.bool,
  isAdministrator: PropTypes.bool,
  isOperation: PropTypes.bool,
  isFinance: PropTypes.bool,
  disabledDropdown: PropTypes.bool,
  getGenerateResouceBookingAction: PropTypes.func,
  errorResourceBooking: PropTypes.object,
  resourceBooking: PropTypes.object,
  resourceBookingByTransaction: PropTypes.object,
  loadingJoinTourOnBeHalf: PropTypes.bool,
  loading: PropTypes.bool,
  loadingGetDuration: PropTypes.bool,
};
const mapStateToProps = state => {
  return {
    joinTourOnBeHalf: state.TourTransactionStore.joinTourOnBeHalf,
    errors: state.TourTransactionStore.errors,
    loadingJoinTourOnBeHalf: state.TourTransactionStore.loadingJoinTourOnBeHalf,
    postCreateTourTransaction:
      state.TourTransactionStore.postCreateTourTransaction,
    postCreateTourTransactionStatus:
      state.TourTransactionStore.postCreateTourTransactionStatus,
    loading: state.TourTransactionStore.loading,
    isSales: state.UserAccessStore.isSales,
    isAdministrator: state.UserAccessStore.isAdministrator,
    isOperation: state.UserAccessStore.isOperation,
    isFinance: state.UserAccessStore.isFinance,
    errorResourceBooking: state.ResourceBooking.errors,
    resourceBooking: state.ResourceBooking.generateResourceBooking,
    resourceBookingByTransaction:
      state.ResourceBooking.resourceBookingByTransaction,
    loadingGetDuration: state.PackageStore.loadingGetDuration,
  };
};

export default connect(mapStateToProps, {
  postJoinTourOnBehalf,
  postCreateTourTransactionAction,
  postConfirmTransaction,
  postEditQuotationAction,
  getDownloadScheduleByTransactionIdAction,
  postAdditionalItems,
  postEmailSendConfirmation,
  getGenerateResouceBookingAction,
})(AllButtonTransaction);
