const LOGIN = 'LOGIN';
const LOGOUT = 'LOGOUT';
const RESET_LOGIN = 'RESET_STATUS_LOGIN';
const LOGIN_FULFILLED = 'LOGIN_FULFILLED';
const LOGIN_PENDING = 'LOGIN_PENDING';
const LOGIN_REJECTED = 'LOGIN_REJECTED';

export {
  LOGIN,
  LOGOUT,
  RESET_LOGIN,
  LOGIN_FULFILLED,
  LOGIN_PENDING,
  LOGIN_REJECTED,
};
