import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Form, Message, Segment, Button } from 'semantic-ui-react';
import InputWithLabel from '../../input/inputWithLabel';
import InputAmount from '../../input/inputAmount';
import IconButton from '../../button/iconButton';
import moment from 'moment';
// import HeaderWithIcon from '../common/headerWithIcon';
// import CollapseButton from '../button/collapseButton';
import InputDate from '../../input/inputDate';
import InputTime from '../../input/inputTime';
import styles from './styles';
import {
  getHourAndMinute,
  convertDateFormat,
} from '../../genericFunction/moment';
// import options from '../../genericFunction/selectOptionsConverter';
import {
  changeDuration,
  copyObject,
  setDateDeparture,
  changeDestinationSummaryProgram,
  // isAccommodationEmpty,
  changeChecInCheckOut,
  setPlaceInArrivalDepartureByHeadLine,
  addConnectionFlight,
  delSummaryProgram,
  setDateSummaryProgramByIndex,
  isEarlyCheck,
  isOverlapping,
  isThereActivity,
  getPlaces,
  delConectionFlight,
} from '../../../scripts/itineraryBuilder/dailyProgram';
// import {
//   getAccommodations,
//   getAccommodationRatings,
//   getAccommodationTypes,
//   getAccommodationLocations,
//   getAccommodationFacilities,
// } from '../../../actions/accommodationAction';
// import { setFirstDestination } from '../../../actions/customPackageAction';
import { getAirportAction } from '../../../actions/airportAction';
import { connect } from 'react-redux';
// import Loader from '../../common/loader';
import ModalConfirm from '../../modal/modalConfirmationTwoButton';
import ModalAlert from '../../modal/modalAlert';
// import ModalConfirm from '../../common/confirmModalTwoButton';
import { getDurationAddresstoAddress } from '../../../actions/packageAction';
import { setReturnsDate } from '../../../scripts/itineraryBuilder/itineraryBuilder';
class segmentAccommodation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkinDate: props.data.AccommodationSummary.CheckIn,
      checkoutDate: props.data.AccommodationSummary.CheckOut,
      checkinTime: getHourAndMinute(props.data.AccommodationSummary.CheckIn),
      checkoutTime: getHourAndMinute(props.data.AccommodationSummary.CheckOut),
      destination: props.data.City ? props.data.City.Id : null,
      timeChange: '',
      openModalConfirm: false,
      openModalAlert: false,
      messageModalAlert: '',
    };
  }
  componentDidUpdate() {
    const { checkinDate, checkoutDate, checkinTime, checkoutTime } = this.state;
    const { CheckIn, CheckOut } = this.props.data.AccommodationSummary;
    if (
      checkinDate !== CheckIn ||
      checkoutDate !== CheckOut ||
      checkinTime !== getHourAndMinute(CheckIn) ||
      checkoutTime !== getHourAndMinute(CheckOut)
    ) {
      this.setState({
        ...this.state,
        checkinDate: CheckIn,
        checkoutDate: CheckOut,
        checkinTime: getHourAndMinute(CheckIn),
        checkoutTime: getHourAndMinute(CheckOut),
      });
    }
  }
  openModalConfirm = (context, value, name) => {
    let { dailyProgram } = this.props;
    let { checkinDate, checkoutDate } = this.state;
    if (
      isThereActivity(
        dailyProgram,
        name === 'checkin' ? checkinDate : checkoutDate
      )
    ) {
      this.setState({
        ...this.state,
        value: value,
        timeChange: name,
        openModalConfirm: true,
      });
    } else {
      if (name === 'checkout') {
        this.setCheckoutTime(this, value);
      } else if (name === 'checkin') {
        this.setCheckinTime(this, value);
      }
    }
  };
  closeModal = () => this.setState({ openModalConfirm: false });
  confirmTime = () => {
    let { timeChange, value } = this.state;
    if (timeChange === 'checkout') {
      this.setCheckoutTime(this, value);
    } else if (timeChange === 'checkin') {
      this.setCheckinTime(this, value);
    }
  };
  setCheckinTime(context, value) {
    const { index, headlineProgram } = context.props;
    let headLineProg = { ...headlineProgram };
    let date = `${convertDateFormat(
      context.state.checkinDate,
      'YYYY-MM-DD'
    )}T${value}:00`;
    headLineProg.MainPrograms = changeChecInCheckOut(
      headlineProgram.MainPrograms,
      index,
      date,
      'in'
    );
    context.setState({
      ...context.state,
      checkinTime: value,
      openModalConfirm: false,
    });
    context.props.setHeadLines(headLineProg);
  }
  setCheckoutTime(context, value) {
    let time = value.split(':');
    if (+time[0] < 12 || (+time[0] === 12 && +time[1] === 0)) {
      const { index, headlineProgram } = context.props;
      let headLineProg = { ...headlineProgram };
      let date = `${convertDateFormat(
        context.state.checkoutDate,
        'YYYY-MM-DD'
      )}T${value}:00`;
      headLineProg.MainPrograms = changeChecInCheckOut(
        headlineProgram.MainPrograms,
        index,
        date,
        'out'
      );
      context.setState({ ...context.state, checkoutTime: value });
      context.props.setHeadLines(headLineProg);
    } else {
      // alert('Your check-out time should not be more than 12:00');
      context.setState({
        ...context.state,
        checkoutTime: context.state.checkoutTime,
        openModalConfirm: false,
      });
    }
  }
  handleChangeTotalDay = (e, { value }) => {
    value = value === '' ? 0 : value;
    this.setTotalDay(value);
  };
  handleIncrease = (e, { value }) => {
    value = value + 1;
    this.setTotalDay(value);
  };
  handleDecrease = (e, { value }) => {
    const newValue = value - 1 < 0 ? 0 : value - 1;
    this.setTotalDay(newValue);
  };

  setTotalDay = value => {
    let { headlineProgram, index } = this.props;
    let headLineProg = copyObject(headlineProgram);
    let newMainProg = changeDuration(headLineProg.MainPrograms, index, value);
    let newDeparture = setReturnsDate(headLineProg.Returns, newMainProg);
    headLineProg.MainPrograms = newMainProg;
    headLineProg.Returns = newDeparture;
    this.props.setHeadLines(headLineProg);
  };
  openModal = () => {
    let context = this;
    context.props.openModal(context.props.data, context.props.index);
  };
  checkDurationTwoAddress = async (city1, city2) => {
    let city = { city1: city1, city2: city2 };
    let obj = {
      iserror: false,
    };
    let result = await this.props.getDurationAddresstoAddress(
      city1,
      city2,
      2,
      2
    );
    let durasi = await result.payload.Duration.value;
    let hour = await (durasi / 3600);
    if (hour >= 14) {
      obj = {
        iserror: true,
        city: city,
      };
    } else {
      obj = {
        iserror: false,
        city: city,
      };
    }
    return obj;
  };
  changeDestination = (e, { value }) => {
    this.setDestination(value);
  };
  setDestination = async value => {
    let { index, cityInCountry, headlineProgram } = this.props;
    let city = cityInCountry.find(item => item.Id === value);
    let headLineProg = { ...headlineProgram };
    headLineProg.MainPrograms = changeDestinationSummaryProgram(
      headLineProg.MainPrograms,
      index,
      city
    );
    headLineProg.MainPrograms = this.deleteDeleteConnectionFlightWhenSameRegion(
      headLineProg.MainPrograms,
      index,
      city,
      headLineProg.Departures[headLineProg.Departures.length - 1]
    );
    // index === 0 && this.props.setFirstDestination(city);
    // this.props.setHeadLines(headLineProg);
    let context = this;
    await this.props.getAirportAction(city.Region.Id).then(async () => {
      await context.setPlace(headLineProg);
    });
    this.props.setAccommodationOnChangeIndex(index);
    // ------------------ jangan dihapus ------------------
    // if (
    //   index > 0 &&
    //   headLineProg.MainPrograms[index].Region !==
    //     headLineProg.MainPrograms[index - 1].Region
    // ) {
    //   let city1 = headLineProg.MainPrograms[index - 1].City.Name;
    //   let result = await this.checkDurationTwoAddress(city1, city.Name);
    //   if (result.iserror) {
    //     this.props.openModalAlert(
    //       `Please Add Connection Flight from ${result.city.city1} to ${
    //         result.city.city2
    //       }`,
    //       'Attention'
    //     );
    //   }
    // }
  };
  //fungction to delete connection flight when the accommodation have same region
  deleteDeleteConnectionFlightWhenSameRegion = (
    mainProg,
    index,
    city,
    departures
  ) => {
    if (index === 0) {
      // jika yg diganti adalah akomodasi pertama
      let nextObj = mainProg[index + 1] ? mainProg[index + 1] : null;
      // eslint-disable-next-line
      if (nextObj && nextObj.hasOwnProperty('TransferType')) {
        if (mainProg[index + 3].City.Region.Id === city.Region.Id) {
          mainProg = delConectionFlight(departures, mainProg, index + 1);
        }
      }
    } else {
      // selain akomodasi pertama
      let prevObj = mainProg[index - 1] ? mainProg[index - 1] : null;
      // eslint-disable-next-line
      if (prevObj && prevObj.hasOwnProperty('TransferType')) {
        if (mainProg[index - 3].City.Region.Id === city.Region.Id) {
          mainProg = delConectionFlight(departures, mainProg, index - 2);
        }
      }
    }
    return mainProg;
  };
  setPlace = async headline => {
    const { places, cityInCountry } = this.props;
    let newHeadline = await setPlaceInArrivalDepartureByHeadLine(
      headline,
      places,
      cityInCountry
    );
    this.props.setHeadLines(newHeadline);
  };
  renderButtonConnectionFlight = () => {
    const { index, headlineProgram, data } = this.props;
    const mainProg = headlineProgram.MainPrograms;

    if (
      index !== 0 &&
      // eslint-disable-next-line
      mainProg[index - 1].hasOwnProperty('AccommodationSummary')
    ) {
      if (data.City && mainProg[index - 1].City) {
        if (
          data.City.Region &&
          mainProg[index - 1].City.Region &&
          data.City.Region.Id !== mainProg[index - 1].City.Region.Id
        ) {
          return (
            !this.props.isFixedPackageMangement && (
              <div style={{ margin: '10px', textAlign: 'center' }}>
                <Button primary onClick={this.handleAddConnectionFlight}>
                  Add Connection Flight
                </Button>
              </div>
            )
          );
        }
      }
    }
  };
  handleAddConnectionFlight = () => {
    let { index, headlineProgram, places } = this.props;
    let headLineProg = copyObject(headlineProgram);
    let originPlace = getPlaces(
      headLineProg.MainPrograms[index - 1].Region,
      places
    );
    let destPlace = getPlaces(headLineProg.MainPrograms[index].Region, places);
    if (originPlace.length > 0 && destPlace.length > 0) {
      headLineProg.MainPrograms = addConnectionFlight(
        headLineProg.MainPrograms,
        index
      );
      this.setPlace(headLineProg);
    } else {
      this.setState({
        ...this.state,
        openModalAlert: true,
        messageModalAlert:
          'We cannot find the nearest airport / transit place in your destination',
      });
    }

    // this.props.setHeadLines(hLProg);
  };
  handleDelete = () => {
    // this.props.deleteAccommodation(this.props.index);
    // let { headlineProgram } = this.props;
    let {
      cityInCountry,
      places,
      index,
      headlineProgram,
      dailyProgram,
    } = this.props;
    let result = delSummaryProgram(
      headlineProgram.Departures[0],
      headlineProgram.Returns[0],
      headlineProgram.MainPrograms,
      dailyProgram,
      index
    );

    let headLineProg = { ...headlineProgram };
    headLineProg.MainPrograms = result.summaryProgram;
    headLineProg.Returns[0] = result.departure;
    // this.setDailyProgram(result.dailyProgram);
    headLineProg = setPlaceInArrivalDepartureByHeadLine(
      headLineProg,
      places,
      cityInCountry
    );
    this.props.setHeadLines(headLineProg);
  };
  delete = async () => {
    let {
      cityInCountry,
      places,
      index,
      headlineProgram,
      dailyProgram,
    } = this.props;
    let result = await delSummaryProgram(
      this.props.isFixedPackageMangement
        ? headlineProgram.MainPrograms[0]
        : headlineProgram.Departures[0],
      headlineProgram.Returns[0],
      headlineProgram.MainPrograms,
      dailyProgram,
      index
    );
    let headLineProg = { ...headlineProgram };
    headLineProg.MainPrograms = result.summaryProgram;
    headLineProg.Returns[0] = result.departure;
    // this.setDailyProgram(result.dailyProgram);
    headLineProg = await setPlaceInArrivalDepartureByHeadLine(
      headLineProg,
      places,
      cityInCountry
    );

    this.props.setHeadLineAndDaily(headLineProg, result.dailyProgram);
    // alert('tert');
  };
  setRangeStartDate = () => {
    let { headlineProgram, index } = this.props;
    let mainProg = headlineProgram.MainPrograms;
    let date;
    if (index === 0) {
      date = headlineProgram.Departures[0].Date;
    } else {
      if (
        mainProg[index - 1] &&
        // eslint-disable-next-line
        mainProg[index - 1].hasOwnProperty('AccommodationSummary')
      ) {
        date = mainProg[index - 1].LeavingDate;
      } else {
        date = mainProg[index - 1].Date;
      }
    }

    return date;
  };
  setDate = date => {
    let { headlineProgram, index, isFixedPackageMangement } = this.props;
    let newHeadline = { ...headlineProgram };
    newHeadline.MainPrograms = setDateSummaryProgramByIndex(
      newHeadline.MainPrograms,
      newHeadline.Departures[0],
      index,
      date
    );
    newHeadline.Returns[0] = setDateDeparture(
      newHeadline.Returns[0],
      newHeadline.MainPrograms
    );
    if (isFixedPackageMangement) {
      newHeadline.MainPrograms = newHeadline.MainPrograms.map(e => {
        e.NextDay = false;
        return e;
      });
    }
    this.props.setHeadLines(newHeadline);
  };
  isPlaceEmpty = () => {
    let { data, places } = this.props;
    let place = getPlaces(data.Region, places);
    return place.length < 1 ? true : false;
  };
  setDateVariableDate = (e, { value }) => {
    let { headlineProgram, index } = this.props;
    let newHeadline = { ...headlineProgram };
    newHeadline.MainPrograms = setDateSummaryProgramByIndex(
      newHeadline.MainPrograms,
      newHeadline.Departures[0],
      index,
      moment(value)
    );
    newHeadline.Returns[0] = setDateDeparture(
      newHeadline.Returns[0],
      newHeadline.MainPrograms
    );
    this.props.setHeadLines(newHeadline);
  };
  handleCloseAlert = () => {
    this.setState({ openModalAlert: false });
  };
  render() {
    const { index, data, headlineProgram, loading } = this.props;
    const {
      checkinTime,
      checkoutTime,
      checkinDate,
      checkoutDate,
      timeChange,
      openModalConfirm,
      openModalAlert,
      messageModalAlert,
    } = this.state; // destination,
    const accommodation = data.AccommodationSummary.Name
      ? `${data.AccommodationSummary.Name}, ${
          data.AccommodationSummary.RoomName
            ? data.AccommodationSummary.RoomName
            : ''
        }, ${
          data.AccommodationSummary.RoomService === 'Bed'
            ? 'Room Only'
            : data.AccommodationSummary.RoomService.replace('_', ' ')
        }`
      : '';
    const destination = data.City ? data.City.Id : null;
    const startDate = this.setRangeStartDate();
    // const earlyCheck = this.props.isFixedPackageMangement
    //   ? false
    //   : isEarlyCheck(data.AccommodationSummary.CheckIn);
    // const isOverlapp = this.props.isFixedPackageMangement
    //   ? false
    //   : isOverlapping(headlineProgram, 'accommodation', index);
    const earlyCheck = isEarlyCheck(data.AccommodationSummary.CheckIn);
    const isOverlapp = isOverlapping(headlineProgram, 'accommodation', index);
    const isPlaceEmpty = this.isPlaceEmpty();
    return (
      <div>
        {this.renderButtonConnectionFlight()}
        <Segment style={styles.segmentInside} loading={loading}>
          <Grid>
            <Grid.Row>
              <Grid.Column textAlign="right">
                {index !== 0 && (
                  <IconButton
                    name="times"
                    iconStyle={{ color: 'red', cursor: 'pointer' }}
                    handleOnClick={this.delete}
                    size="large"
                  />
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Form>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <InputDate
                        label="Checkin Date"
                        value={moment(checkinDate)}
                        labelStyle={styles.dateTimeLabelStyle}
                        minDate={
                          !this.props.isFixedPackageMangement
                            ? moment(startDate)
                            : null
                        }
                        maxDate={
                          !this.props.isFixedPackageMangement
                            ? moment(startDate).add(1, 'days')
                            : null
                        }
                        setDate={this.setDate}
                      />
                    </Form.Field>

                    <Form.Field width={16}>
                      <InputTime
                        labelText="Checkin Time"
                        labelStyles={styles.dateTimeLabelStyle}
                        context={this}
                        value={checkinTime}
                        name="checkin"
                        setTime={
                          this.openModalConfirm //this.setCheckinTime // inputStyles={styles.inputTimeStyle}
                        }
                      />
                    </Form.Field>
                    <Form.Field width={16}>
                      <InputAmount
                        label="Total Days"
                        labelStyles={styles.dateTimeLabelStyle}
                        inputStyle={{
                          borderBottom: '1px solid grey',
                          marginTop: '12px',
                        }}
                        labelStyle={styles.textAlignCenter}
                        value={
                          data.TotalDays // inputStyle={styles.borderBottom}
                        }
                        handleIncrease={this.handleIncrease}
                        handleDecrease={this.handleDecrease}
                        handleOnChange={this.handleChangeTotalDay}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field width={16}>
                      <InputDate
                        label="Checkout Date"
                        value={checkoutDate}
                        labelStyle={styles.dateTimeLabelStyle}
                        readonly={true}
                      />
                    </Form.Field>
                    <Form.Field width={16}>
                      <InputTime
                        labelText="Checkout Time"
                        labelStyles={styles.dateTimeLabelStyle}
                        context={this}
                        value={checkoutTime}
                        name="checkout"
                        setTime={
                          this.openModalConfirm //this.setCheckoutTime
                        }
                      />
                    </Form.Field>
                    <Form.Field width={16}>
                      <InputWithLabel
                        label="City Destination"
                        inline={false}
                        type="select"
                        placeholder="Select Place"
                        labelStyles={styles.dateTimeLabelStyle}
                        options={
                          this.props.cityList // inputStyles={styles.inputSelectionStyle}
                        }
                        value={destination}
                        handleChange={this.changeDestination}
                        fluid={
                          true //   options={this.props.listCountry}
                        }
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Field>
                    <InputWithLabel
                      handleClick={this.openModal}
                      label="Accommodation"
                      inline={
                        false // transparent
                      }
                      type="text"
                      value={accommodation}
                      placeholder="Select Place"
                      labelStyles={styles.dateTimeLabelStyle}
                      fluid={
                        true // inputStyles={styles.inputTextStyle} //   options={this.props.listCountry}
                      }
                    />
                  </Form.Field>
                </Form>
                <Message warning>
                  <Message.Header>Note:</Message.Header>
                  <p>
                    Check in date can be changed to Day + 1 from Arrival Date or
                    Check out date on previous Accommodation
                  </p>
                  {earlyCheck && (
                    <p style={{ color: '#f44336' }}>
                      Your check in time is less than 2 PM, there will be extra
                      charge for early checkin.
                    </p>
                  )}
                  {//!this.props.isFixedPackageMangement &&
                  isOverlapp && (
                    <p style={{ color: '#f44336' }}>
                      <strong>
                        Warning!! your accommodation time is overlapping.
                      </strong>{' '}
                    </p>
                  )}
                  {!this.props.isFixedPackageMangement && isPlaceEmpty && (
                    <p style={{ color: '#f44336' }}>
                      <strong>
                        {`Warning!! we cannot find the nearest airport / transit
                        place in '${
                          data.City ? data.City.Name : ''
                        }', please change your destination.`}
                      </strong>{' '}
                    </p>
                  )}
                </Message>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <ModalConfirm
            openModal={openModalConfirm}
            size="small"
            close={this.closeModal}
            confirm={this.confirmTime}
            message="Please be careful, changing the time of accommodation will reset the activity in the current day"
            nameConfirm={timeChange}
            contentOk="Ok, Continue"
            contentNo="No"
          />
          <ModalAlert
            handleClose={this.handleCloseAlert}
            openModal={openModalAlert}
            content={messageModalAlert}
            size="mini"
          />
          {/* <Loader active={loading} inverted={true} />
          <ModalConfirm
            openModal={openModalConfirm}
            size="small"
            close={this.closeModal}
            confirm={this.confirmTime}
            message="Please be careful, changing the time of accommodation will reset the activity in the current day"
            nameConfirm={timeChange}
            contentOk="Ok, Continue"
            contentNo="Back"
          /> */}
        </Segment>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    places: state.AirportStore.places,
    loading: state.AirportStore.loading,
  };
};
segmentAccommodation.propTypes = {
  data: PropTypes.object,
  cityList: PropTypes.array,
  index: PropTypes.number,
  headlineProgram: PropTypes.object,
  setHeadLines: PropTypes.func,
  getAccommodationRatings: PropTypes.func,
  getAccommodationTypes: PropTypes.func,
  getAccommodationLocations: PropTypes.func,
  getAccommodationFacilities: PropTypes.func,
  getAccommodations: PropTypes.func,
  cityInCountry: PropTypes.array,
  getAirportAction: PropTypes.func,
  getDurationAddresstoAddress: PropTypes.func,
  setAccommodationOnChangeIndex: PropTypes.func,
  // setFirstDestination: PropTypes.func,
  openModal: PropTypes.func,
  places: PropTypes.array,
  deleteAccommodation: PropTypes.func,
  dailyProgram: PropTypes.array,
  loading: PropTypes.bool,
  setHeadLineAndDaily: PropTypes.func,
  isFixedPackageMangement: PropTypes.bool,
  openModalAlert: PropTypes.func,
  currentPackage: PropTypes.object,
  tourInformation: PropTypes.object,
};
export default connect(mapStateToProps, {
  // getAccommodations,
  // getAccommodationRatings,
  // getAccommodationTypes,
  // getAccommodationLocations,
  // getAccommodationFacilities,
  getAirportAction,
  getDurationAddresstoAddress,
  // setFirstDestination,
})(segmentAccommodation);
