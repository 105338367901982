import {
  GET_RESTAURANT_BY_PROFILE_ID,
  GET_RESTAURANT_ITEM_BY_PROFILE_ID,
  RESET_RESTAURANT,
  DELETE_RESTAURANT_BY_PROFILE_ID,
  GET_RESTAURANT_LIST,
  GET_RESTAURANT_CLASS,
  GET_RESTAURANT_TYPE,
  POST_RESTAURANT_PROFILE,
  PUT_RESTAURANT_PROFILE,
  GET_RESTAURANT_ITEM_EXPORT_DATA,
  GET_RESTAURANT_ITEM_PRICE_EXPORT_DATA,
  GET_TEMPLATE_RESTAURANT_ITEM_DATA,
  GET_TEMPLATE_RESTAURANT_ITEM_PRICE_DATA,
  GET_TEMPLATE_RESTAURANT_PROFILE_DATA,
  GET_RESTAURANT_CATEGORY,
  PUT_RESTAURANT_ITEM,
  POST_RESTAURANT_ITEM,
  GET_RESTAURANT_ITEM,
  GET_RESTAURANT_ITEM_BY_ID,
  GET_EXPORT_RESTAURANT_PROFILE,
  POST_UPLOAD_IMAGE_RESTAURANT_ITEM,
  GET_RESTAURANT_ITEM_ALL,
  POST_UPLOAD_IMAGE_RESTAURANT_PROFILE,
  GET_RESTAURANT_BY_CITY_DATE_PAX,
  GET_MASTER_REFERENCE_MENU_TYPE_LIST,
  GET_MENU_CLASS,
  POST_SET_PRIMARY_IMAGE,
  GET_MASTER_REFERENCE_RESTAURANT_PROFILE_SPECIALIZATIONS_LIST,
  GET_RESTAURANT_RATING,
  DELETE_RESTAURANT_ITEM_BY_ID,
  GET_EXPORT_DATA_ITEM_BY_ID,
  GET_RESTAURANT_ESTIMATE_PRICE,
} from './actionTypes';
import deleteRestaurantByProfileIdApi from '../api/restaurantApi/deleteRestaurantByProfileIdApi';
import getRestaurantByProfileIdApi from '../api/restaurantApi/getRestaurantByProfileIdApi';
import getRestaurantItemByProfileIdApi from '../api/restaurantApi/getRestaurantItemByProfileIdApi';
import getRestaurantListApi from '../api/restaurantApi/getRestaurantListApi';
import getRestaurantClassApi from '../api/restaurantApi/getRestaurantClassApi';
import getRestaurantTypeApi from '../api/restaurantApi/getRestaurantTypeApi';
import getRestaurantCategoryApi from '../api/restaurantApi/getRestaurantCategoryApi';
import postRestaurantProfileApi from '../api/restaurantApi/postRestaurantProfileApi';
import putRestaurantProfileApi from '../api/restaurantApi/putRestaurantProfileApi';
import getExportRestaurantItemApi from '../api/restaurantApi/getExportRestaurantItemApi';
import getExportRestaurantPriceApi from '../api/restaurantApi/getExportRestaurantPriceApi';
import getTemplateRestaurantPriceApi from '../api/restaurantApi/getTemplateRestaurantPriceApi';
import getTemplateRestaurantProfileApi from '../api/restaurantApi/getTemplateRestaurantProfileApi';
import getTemplateRestaurantItemApi from '../api/restaurantApi/getTemplateRestaurantItemApi';
import putRestaurantItemApi from '../api/restaurantApi/putRestaurantItemApi';
import postRestaurantItemApi from '../api/restaurantApi/postRestaurantItemApi';
import getRestaurantItemApi from '../api/restaurantApi/getRestaurantItemApi';
import getRestaurantItemByIdApi from '../api/restaurantApi/getRestaurantItemByIdApi';
import getExportRestaurantProfileApi from '../api/restaurantApi/getExportRestaurantProfileApi';
import postUploadImageRestaurantItemApi from '../api/restaurantApi/postUploadImageRestaurantItemApi';
import getRestaurantItemAllApi from '../api/restaurantApi/getRestaurantItemAllApi';
import postUploadImageRestaurantProfileApi from '../api/restaurantApi/postUploadImageRestaurantProfileApi';
import getRestaurantByCityDateAndPaxApi from '../api/restaurantApi/getRestaurantByCityDateAndPaxApi';
import getMenuTypeApi from '../api/restaurantApi/getMenuType';
import getMenuClassesApi from '../api/restaurantApi/getMenuClasses';
import postSetPrimaryImageRestaurantProfileApi from '../api/restaurantApi/postSetPrimaryImageRestaurantProfileApi';
import postSetPrimaryImageRestaurantItemApi from '../api/restaurantApi/postSetPrimaryImageRestaurantItemApi';
import getRestaurantProfileSpecializationsApi from '../api/restaurantApi/getRestaurantProfileSpecializationsApi';
import getRestaurantRatingApi from '../api/restaurantApi/getRestaurantRatingApi';
import deleteRestaurantItemByIdApi from '../api/restaurantApi/deleteRestaurantItemByIdApi';
import getRestaurantByFilterApi from '../api/restaurantApi/getRestaurantByFilterApi';
import getExportDataItemByIdApi from '../api/supplier/getExportDataItemByIdApi';
import { getRestaurantEstimatePriceApi } from '../api/restaurantApi';
const getRestaurantListAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_RESTAURANT_LIST,
      payload: getRestaurantListApi(),
    });
  };
};

const getRestaurantByProfileIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_RESTAURANT_BY_PROFILE_ID,
      payload: getRestaurantByProfileIdApi(Id),
    });
  };
};

const getRestaurantItemByProfileIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_RESTAURANT_ITEM_BY_PROFILE_ID,
      payload: getRestaurantItemByProfileIdApi(Id),
    });
  };
};

const deleteRestaurantByProfileIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: DELETE_RESTAURANT_BY_PROFILE_ID,
      payload: deleteRestaurantByProfileIdApi(Id),
    });
  };
};

const resetRestaurantAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_RESTAURANT,
    });
  };
};

const getRestaurantClassAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_RESTAURANT_CLASS,
      payload: getRestaurantClassApi(),
    });
  };
};
const getRestaurantCategoryAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_RESTAURANT_CATEGORY,
      payload: getRestaurantCategoryApi(),
    });
  };
};
const getRestaurantTypeAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_RESTAURANT_TYPE,
      payload: getRestaurantTypeApi(),
    });
  };
};

const postRestaurantProfileAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_RESTAURANT_PROFILE,
      payload: postRestaurantProfileApi(data),
    });
  };
};

const putRestaurantProfileAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_RESTAURANT_PROFILE,
      payload: putRestaurantProfileApi(id, data),
    });
  };
};

const getExportRestaurantItemAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_RESTAURANT_ITEM_EXPORT_DATA,
      payload: getExportRestaurantItemApi,
    });
  };
};
const getExportRestaurantPriceAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_RESTAURANT_ITEM_PRICE_EXPORT_DATA,
      payload: getExportRestaurantPriceApi,
    });
  };
};

const getTemplateRestaurantItemAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_TEMPLATE_RESTAURANT_ITEM_DATA,
      payload: getTemplateRestaurantItemApi,
    });
  };
};
const getTemplateRestaurantPriceAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_TEMPLATE_RESTAURANT_ITEM_PRICE_DATA,
      payload: getTemplateRestaurantPriceApi,
    });
  };
};

const getTemplateRestaurantProfileAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_TEMPLATE_RESTAURANT_PROFILE_DATA,
      payload: getTemplateRestaurantProfileApi,
    });
  };
};

const getRestaurantItemAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_RESTAURANT_ITEM,
      payload: getRestaurantItemApi(),
    });
  };
};
const getRestaurantItemByIdAction = id => {
  return dispatch => {
    return dispatch({
      type: GET_RESTAURANT_ITEM_BY_ID,
      payload: getRestaurantItemByIdApi(id),
    });
  };
};
const putRestaurantItemAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_RESTAURANT_ITEM,
      payload: putRestaurantItemApi(id, data),
    });
  };
};
// const postRestaurantItemAction = data => {
//   return dispatch => {
//     return dispatch({
//       type: POST_RESTAURANT_ITEM,
//       payload: postRestaurantItemApi(data),
//     });
//   };
// };
const postRestaurantItemAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_RESTAURANT_ITEM,
      payload: postRestaurantItemApi(data),
    });
  };
};
const getExportRestaurantProfileAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_EXPORT_RESTAURANT_PROFILE,
      payload: getExportRestaurantProfileApi,
    });
  };
};

const postUploadImageRestaurantItemAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: POST_UPLOAD_IMAGE_RESTAURANT_ITEM,
      payload: postUploadImageRestaurantItemApi(id, data),
    });
  };
};

const getRestaurantItemAllAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_RESTAURANT_ITEM_ALL,
      payload: getRestaurantItemAllApi(),
    });
  };
};

const postUploadImageRestaurantProfileAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: POST_UPLOAD_IMAGE_RESTAURANT_PROFILE,
      payload: postUploadImageRestaurantProfileApi(id, data),
    });
  };
};
const getRestaurantByCityDateAndPaxAction = (city, date, pax) => {
  return dispatch => {
    return dispatch({
      type: GET_RESTAURANT_BY_CITY_DATE_PAX,
      payload: getRestaurantByCityDateAndPaxApi(city, date, pax),
    });
  };
};
const getMenuTypeAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_MASTER_REFERENCE_MENU_TYPE_LIST,
      payload: getMenuTypeApi(),
    });
  };
};
const getMenuClassesAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_MENU_CLASS,
      payload: getMenuClassesApi(),
    });
  };
};

const postSetPrimaryImageRestaurantProfileAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: POST_SET_PRIMARY_IMAGE,
      payload: postSetPrimaryImageRestaurantProfileApi(id, data),
    });
  };
};

const postSetPrimaryImageRestaurantItemAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: POST_SET_PRIMARY_IMAGE,
      payload: postSetPrimaryImageRestaurantItemApi(id, data),
    });
  };
};

const getRestaurantProfileSpecializationsAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_MASTER_REFERENCE_RESTAURANT_PROFILE_SPECIALIZATIONS_LIST,
      payload: getRestaurantProfileSpecializationsApi(),
    });
  };
};

const getRestaurantRatingAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_RESTAURANT_RATING,
      payload: getRestaurantRatingApi(),
    });
  };
};
const deleteRestaurantItemByIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: DELETE_RESTAURANT_ITEM_BY_ID,
      payload: deleteRestaurantItemByIdApi(Id),
    });
  };
};

const getRestaurantByFilterAction = data => {
  return dispatch => {
    return dispatch({
      type: GET_RESTAURANT_BY_CITY_DATE_PAX,
      payload: getRestaurantByFilterApi(data),
    });
  };
};

const getExportDataItemByIdAction = (supplierType, profileId) => {
  return dispatch => {
    return dispatch({
      type: GET_EXPORT_DATA_ITEM_BY_ID,
      payload: getExportDataItemByIdApi(supplierType, profileId),
    });
  };
};
const getRestaurantEstimatePrice = param => {
  return dispatch => {
    return dispatch({
      type: GET_RESTAURANT_ESTIMATE_PRICE,
      payload: getRestaurantEstimatePriceApi(param),
    });
  };
};
export {
  deleteRestaurantByProfileIdAction,
  getRestaurantByProfileIdAction,
  getRestaurantItemByProfileIdAction,
  getRestaurantListAction,
  resetRestaurantAction,
  getRestaurantTypeAction,
  getRestaurantClassAction,
  postRestaurantProfileAction,
  putRestaurantProfileAction,
  getExportRestaurantPriceAction,
  getExportRestaurantItemAction,
  getTemplateRestaurantProfileAction,
  getTemplateRestaurantPriceAction,
  getTemplateRestaurantItemAction,
  getRestaurantCategoryAction,
  putRestaurantItemAction,
  postRestaurantItemAction,
  getRestaurantItemAction,
  getRestaurantItemByIdAction,
  getExportRestaurantProfileAction,
  postUploadImageRestaurantItemAction,
  getRestaurantItemAllAction,
  postUploadImageRestaurantProfileAction,
  getRestaurantByCityDateAndPaxAction,
  getMenuTypeAction,
  getMenuClassesAction,
  postSetPrimaryImageRestaurantItemAction,
  postSetPrimaryImageRestaurantProfileAction,
  getRestaurantProfileSpecializationsAction,
  getRestaurantRatingAction,
  deleteRestaurantItemByIdAction,
  getRestaurantByFilterAction,
  getExportDataItemByIdAction,
  getRestaurantEstimatePrice,
};
