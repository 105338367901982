import { PACKAGE_DETAIL } from '../../constant/constSimplePackageInformation';
import { copyObject } from '../../../../scripts/itineraryBuilder/itineraryBuilder';
import { rangeDate } from './../../../../scripts/moment';
import { setRoomPrices } from '../../../../components/genericFunction/convertPackage';
let initialDetailPackage = (type, packageDetail) => {
  let packageDetailCopy = copyObject(PACKAGE_DETAIL);
  let {
    Brochures,
    BookingDetailSum,
    Prices,
    Commissions,
    Images,
    DailyPrograms,
    HeadlineProgram,
    Descriptions,
  } = packageDetail;
  packageDetailCopy = {
    ...packageDetailCopy,
    brochure: Brochures && Brochures.length > 0 ? Brochures[0] : null,
    tourName: BookingDetailSum.Title,
    firstDestination:
      BookingDetailSum.City.Name + ', ' + BookingDetailSum.Country.Name,
    description: BookingDetailSum.Description,
    tourType: BookingDetailSum.TourCategory.Name,
    tourCategory: BookingDetailSum.TourPaxType.Name,
    tourDate: rangeDate(BookingDetailSum.StartDate, BookingDetailSum.EndDate),
    paxAvailable: BookingDetailSum.FixedPackage
      ? BookingDetailSum.FixedPackage.PaxLeft
      : 0,
    currency: Prices.CurrencyId,
    totalCommission: Commissions.reduce((acc, e) => acc + e.Value, 0),
    image:
      Images && Images.length > 0
        ? Images[0].ImageUrl
        : BookingDetailSum.City.ImageUrl,
    packageImages: Images,
    roomAllocationPrice: setRoomPrices(Prices),
    paymentTerms: BookingDetailSum.FixedPackage
      ? BookingDetailSum.FixedPackage.PaymentTerms
      : [],
    dailyProgram: DailyPrograms,
    headlineProgram: HeadlineProgram,
    additionalInfo: Descriptions,
  };
  return packageDetailCopy;
};

export { initialDetailPackage };
