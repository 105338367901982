import React, { Component } from 'react';
import { Table, Input, Button, Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { HeaderTableCustomerProfileUserData } from '../../../../../components/table/headerTabel';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../../../scripts/pagination';
import LoadingPage from '../../../../../components/modal/loaderModal';
import Pagination from '../../../../../components/common/pagination';
class EmployeeCompanyProfileTable extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
    };
  }
  static propTypes = {
    data: PropTypes.array,
  };
  handleChange = (e, { name, value }) => this.setState({ [name]: value });
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };

  filteredData = data =>
    data.filter(data => {
      const name = `${data.FirstName} ${data.LastName}`;
      return (
        name.toLowerCase().indexOf(this.state.searchInput.toLowerCase()) !==
          -1 ||
        (data.Username &&
          data.Username.toLowerCase().indexOf(
            this.state.searchInput.toLowerCase()
          ) !== -1) ||
        (data.Email &&
          data.Email.toLowerCase().indexOf(
            this.state.searchInput.toLowerCase()
          ) !== -1)
      );
    });
  render() {
    let filterData = this.filteredData(this.props.listData);
    let pagedData = datasetPagination(filterData, 3);
    let listData = pagedData[this.state.activePage - 1];
    const props = this.props;

    let { numbersFrom, numbersTo } = getNumberFromTo(
      listData,
      this.state.activePage,
      3
    );
    return (
      <div>
        <LoadingPage loading={this.props.loading} />
        <Input
          icon="search"
          placeholder="search"
          name="searchInput"
          value={this.state.searchInput}
          onChange={this.handleChange}
        />
        <Button
          icon="add"
          content="Add Employee"
          positive
          floated="right"
          onClick={this.props.buttonModalEmployee}
          disabled={this.props.isNewForm || this.props.disabled}
        />
        <Table basic="very" selectable fixed>
          <Table.Header>
            <Table.Row>
              {HeaderTableCustomerProfileUserData.map(
                (headTableContent, index) => {
                  return (
                    <Table.HeaderCell key={index}>
                      {headTableContent}
                    </Table.HeaderCell>
                  );
                }
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {listData &&
              listData.map((userData, idx) => {
                return (
                  <Table.Row key={idx}>
                    <Table.Cell component="th" scope="row" width={1}>
                      {userData.Username ? userData.Username : '-'}
                    </Table.Cell>
                    <Table.Cell>
                      {userData.FirstName}
                      {userData.LastName}
                    </Table.Cell>
                    <Table.Cell>
                      {userData.Telephone ? userData.Telephone : '-'}
                    </Table.Cell>
                    <Table.Cell>
                      {userData.Email ? userData.Email : '-'}
                    </Table.Cell>
                    <Table.Cell> {userData.Address}</Table.Cell>
                    <Table.Cell>
                      {userData.IsInactive ? 'Inactive' : 'Active'}
                    </Table.Cell>
                    <Table.Cell>
                      <Popup
                        trigger={
                          <Button
                            icon="unlock alternate"
                            onClick={this.props.unlockUser}
                            id={userData.Id}
                            color="blue"
                            disabled={
                              !userData.IsLocked
                                ? true
                                : false || this.props.disabled
                            }
                            circular
                          />
                        }
                        content={
                          userData.IsLocked === false
                            ? 'Unlock employee'
                            : 'Lock employee'
                        }
                      />
                      <Popup
                        trigger={
                          <Button
                            icon="key"
                            onClick={this.props.resetPassword}
                            id={userData.Id}
                            color="blue"
                            disabled={this.props.disabled}
                            circular
                          />
                        }
                        content="Reset password employee"
                      />
                      <Popup
                        trigger={
                          <Button
                            icon="edit"
                            onClick={this.props.editUser}
                            id={userData.Id}
                            color="blue"
                            disabled={this.props.disabled}
                            circular
                          />
                        }
                        content="Edit data employee"
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>

        <Pagination
          pagedData={pagedData}
          numbersTo={numbersTo}
          numbersFrom={numbersFrom}
          data={props.listData}
          pageChange={this.pageChange}
          activePage={this.state.activePage}
        />
      </div>
    );
  }
}

EmployeeCompanyProfileTable.propTypes = {
  listData: PropTypes.array,
  unlockUser: PropTypes.func,
  buttonModalEmployee: PropTypes.func,
  resetPassword: PropTypes.func,
  editUser: PropTypes.func,
  buttonAddUser: PropTypes.func,
  loading: PropTypes.bool,
  isNewForm: PropTypes.bool,
  disabled: PropTypes.bool,
};
export default EmployeeCompanyProfileTable;
