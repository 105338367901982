import React from 'react';
import { Table, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import LoaderModal from '../../../../components/modal/loaderModal';
import { getTotalDays, convertDateFormat } from '../../../../scripts/moment';
const TablePickSharingPax = props => (
  <Table basic="very" selectable>
    <LoaderModal loading={props.loading} />
    <Table.Header>
      <Table.Row>
        {props.headerTable.map((headTableContent, index) => {
          return (
            <Table.HeaderCell key={index}>{headTableContent}</Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {props.listData ? (
        props.listData.map((data, idx) => {
          return (
            <Table.Row key={idx}>
              <Table.Cell>{data.Title ? data.Title : ''}</Table.Cell>
              <Table.Cell> {data.City.Name}</Table.Cell>
              <Table.Cell>
                {convertDateFormat(data.StartDate, 'DD MMMM YYYY')}
              </Table.Cell>
              <Table.Cell>
                {data.TourCategory.Name ? data.TourCategory.Name : ''}
              </Table.Cell>
              <Table.Cell> {data.FixedPackage.MinimumGuest}</Table.Cell>
              <Table.Cell>{data.FixedPackage.MaximumGuest}</Table.Cell>
              <Table.Cell>{data.FixedPackage.PaxLeft}</Table.Cell>
              <Table.Cell>
                {getTotalDays(data.StartDate, data.EndDate, true)} Days
              </Table.Cell>
              <Table.Cell>
                <Button
                  primary
                  content="Pick"
                  onClick={props.handlePickSharingPax}
                  name={data.Title ? data.Title : ''}
                  // value={data.Id ? data.Id : 0}
                  value={data}
                  objectname="tourInformation"
                />
              </Table.Cell>
            </Table.Row>
          );
        })
      ) : (
        <Table.Row>
          <Table.Cell colSpan="8" textAlign="center">
            No Data
          </Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  </Table>
);
TablePickSharingPax.propTypes = {
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  loading: PropTypes.bool,
  handlePickSharingPax: PropTypes.func,
};
export default TablePickSharingPax;
