import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Label } from 'semantic-ui-react';
// import styles from '../../../../../assets/styles/styleCreateTourTransaction';
import separatorStyle from './styles';
import { convertDateFormat } from '../../../../../scripts/moment';
const TimeLineDateSeparator = props => {
  const { date } = props;
  return (
    <Grid verticalAlign="middle">
      <Grid.Row style={{ marginTop: '20px' }}>
        <Grid.Column width={2}>
          <Label
            circular
            content={convertDateFormat(date, 'DD MMM YYYY')}
            size="large"
            style={separatorStyle.separatorDateStyle}
          />
        </Grid.Column>
        <Grid.Column width={14}>
          <div style={separatorStyle.separatorLineStyle} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
TimeLineDateSeparator.propTypes = {
  date: PropTypes.string,
};
export default TimeLineDateSeparator;
