import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Container, Divider } from 'semantic-ui-react';
import { StyleModal } from '../../../../assets/styles/size';
import LoaderModal from '../../../../components/modal/loaderModal';
import DescriptionManualPayment from '../../../shared/components/modal/ModalDescriptionManualPayment';
import TableManualPayment from '../../../shared/components/modal/ModalTableManualPayment';
import ContentCalculate from '../../../shared/components/modal/ModalCalculate';
const ModalManualPayment = props => {
  let contentMessage = '';
  const Balance = props.contentDetailDemoManualPayment.CompanyBalance;
  //let Value = props.tourTransactionConfirmationObject.CustomValue;
  const CustomerBalance = props.contentDetailDemoManualPayment.CompanyBalance
    ? props.contentDetailDemoManualPayment.CompanyBalance
    : 0;
  const tourTransactionBills = props.tourTransactionConfirmationObject
    .tourTransactionBills
    ? props.tourTransactionConfirmationObject.tourTransactionBills
    : 0;
  const tourTransactionBilled =
    props.tourTransactionConfirmationObject.tourTransactionBilled;
  const tourTransactionBilledStatic = props.tourTransactionBilled;
  const TotalPayable =
    tourTransactionBilledStatic.RestOfTheBillWithoutSupplement +
    props.totalPayableAdditionalItem
      ? tourTransactionBilledStatic.RestOfTheBillWithoutSupplement +
        props.totalPayableAdditionalItem
      : 0;
  const CustomValue =
    tourTransactionBilled + props.totalPayableAdditionalItem
      ? tourTransactionBilled + props.totalPayableAdditionalItem
      : 0;
  // tourTransactionBilled ? tourTransactionBilled : 0;

  const BalancePayment = tourTransactionBills ? tourTransactionBills : 0;

  // + props.totalMandatory
  //   ? props.totalMandatory
  //   : 0 + props.totalNotMandatory
  //     ? props.totalNotMandatory
  //     : 0; // sisa yang belum dibayarkan
  // const CustomerBalance = 0; // balance customer

  // const TourPrice = tourTransactionBilled + props.totalMandatory;
  const TourPrice = tourTransactionBilled;
  // const Payable = TourPrice + props.totalNotMandatory; // yang dibayarkan oleh user // total payable + ismandatory price
  if (TotalPayable > Balance) {
    contentMessage = 'Your balance is insufficient';
  } else {
    contentMessage = '';
  }
  return (
    <Modal
      closeIcon
      inverted
      open={props.open}
      onClose={props.handleClose}
      style={StyleModal.modalWidthManualPayment}
    >
      <Modal.Header>Details Confirmation Transaction</Modal.Header>
      <LoaderModal loading={props.loadingModal} />
      <Modal.Content>
        <DescriptionManualPayment
          contentDetail={props.contentDetail}
          tourTransactionBilled={tourTransactionBilled}
          tourTransactionBills={tourTransactionBills}
        />
        <Divider />
        <TableManualPayment
          contentDetail={props.contentDetail}
          additionalItem={props.additionalItem}
          tourTransactionBills={tourTransactionBills}
          tourTransactionBilled={tourTransactionBilled}
          tourTransactionBilledStatic={tourTransactionBilledStatic}
          handleChangeManualPayment={props.handleChangeManualPayment}
          handleChangeAdditionalItem={props.handleChangeAdditionalItem}
          getPayableSupplemetItemStatic={props.getPayableSupplemetItemStatic}
          additionalItemValue={props.additionalItemValue}
          handleChangeManualPaymentAmount={
            props.handleChangeManualPaymentAmount
          }
          // handleChangeAdditionalItemAmount={
          //   props.handleChangeAdditionalItemAmount
          // }
          TourPrice={TourPrice}
          payableMandatory={props.totalMandatory}
        />
        <ContentCalculate
          contentDetail={props.contentDetail}
          customerBalance={CustomerBalance}
          // totalPayable={TotalPayable}
          openModalBalanceDetail={props.openModalBalanceDetail}
          customValue={CustomValue}
          //tambahan
          BalancePayment={BalancePayment}
          // totalPayableValue={props.totalPayableValue}
          handleChangePayable={props.handleChangePayable}
          totalPayableValue={props.totalPayableValue}
          payableMandatory={props.totalMandatory}
        />
      </Modal.Content>
      <Modal.Actions>
        <Container
          content={contentMessage}
          style={{ color: 'red' }}
          textAlign="left"
        />
        <Button
          positive
          inverted
          onClick={props.handleSubmitTourTransactionConfirmation}
          content="Pay"
          disabled={props.disabled}
          loading={props.loadingButton}
        />
      </Modal.Actions>
    </Modal>
  );
};

ModalManualPayment.propTypes = {
  contentDetail: PropTypes.shape({
    Id: PropTypes.number,
    Customer: PropTypes.string,
    TourName: PropTypes.string,
    TourPrice: PropTypes.string,
    TourPaid: PropTypes.string,
    CurrencyId: PropTypes.string,
  }),
  NoBooking: PropTypes.number,
  paymentMethod: PropTypes.func,
  disabled: PropTypes.bool,
  open: PropTypes.bool,
  handleChangeManualPayment: PropTypes.func,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  contentMessageManualPayment: PropTypes.string,
  contentDetailDemoManualPayment: PropTypes.object,
  AdditionalItem: PropTypes.array,
  // paymentPercentage: PropTypes.number,
  TotalPayableAdditionalItem: PropTypes.number,
  handleSubmitTourTransactionConfirmation: PropTypes.func,
  openModalBalanceDetail: PropTypes.func,
  tourTransactionBilled: PropTypes.object,
  tourTransactionBills: PropTypes.object,
  tourTransactionConfirmationObject: PropTypes.object,
  getPayableSupplemetItemStatic: PropTypes.array,
  additionalItemValue: PropTypes.array,
  loadingModal: PropTypes.bool,
  totalPayableAdditionalItem: PropTypes.number,
  additionalItemPay: PropTypes.number,
  handleChangeAdditionalItem: PropTypes.func,
  loadingButton: PropTypes.bool,
  additionalItem: PropTypes.array,
  handleChangeManualPaymentAmount: PropTypes.func,
  handleChangeAdditionalItemAmount: PropTypes.func,
  handleChangePayable: PropTypes.func,
  totalPayableValue: PropTypes.string,
  totalMandatory: PropTypes.number,
};
export default ModalManualPayment;
