import {
  PUT_NOT_ALLOWED_COUNTRY_FOR_COMPANY,
  POST_NOT_ALLOWED_COUNTRY_FOR_COMPANY,
  GET_NOT_ALLOWED_COUNTRY_FOR_COMPANY_BY_COMPANY_CODE,
  GET_ALL_NOT_ALLOWED_COUNTRY_FOR_COMPANY,
  RESET_NOT_ALLOWED_COUNTRY_FOR_COMPANY,
} from './constant/actionTypes';
import putAllowedForCompanyApi from '../../api/allowedForCompany/putAllowedForCompanyApi';
import postAllowedForCompanyApi from '../../api/allowedForCompany/postAllowedForCompanyApi';
import getAllowedForCompanyByCompanyCodeApi from '../../api/allowedForCompany/getAllowedForCompanyByCompanyCodeApi';
import getAllAllowedForCompanyApi from '../../api/allowedForCompany/getAllAllowedForCompanyApi';

export const resetNotAllowedCountryAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_NOT_ALLOWED_COUNTRY_FOR_COMPANY,
    });
  };
};

export const putAllowedForCompanyAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_NOT_ALLOWED_COUNTRY_FOR_COMPANY,
      payload: putAllowedForCompanyApi(id, data),
    });
  };
};

export const postAllowedForCompanyAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_NOT_ALLOWED_COUNTRY_FOR_COMPANY,
      payload: postAllowedForCompanyApi(data),
    });
  };
};

export const getAllowedForCompanyByCompanyCodeAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_NOT_ALLOWED_COUNTRY_FOR_COMPANY_BY_COMPANY_CODE,
      payload: getAllowedForCompanyByCompanyCodeApi(Id),
    });
  };
};

export const getAllAllowedForCompanyAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_ALL_NOT_ALLOWED_COUNTRY_FOR_COMPANY,
      payload: getAllAllowedForCompanyApi(Id),
    });
  };
};
