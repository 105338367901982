const StylesTextfieldWithError = {
  Red: {
    color: 'red',
    fontWeight: 'bold',
    marginLeft: '0px',
    justifyContent: 'flex-start',
  },
  bold: {
    fontWeight: 'bold',
    marginRight: '0px',
    justifyContent: 'flex-end',
  },
  size: {
    width: '250px',
  },
  sizeDoubleComponent: {
    //width: '218px',
    //marginTop: '1.5em',
    width: '15.5em  ',
  },
  newMargin: {
    margin: '0px 0px 10px 0px',
  },
  margin: {
    margin: '10px 10px 13px 0px',
  },
  marginLeft: {
    marginLeft: '15px',
  },
  minHeight: {
    minHeight: '100',
    width: '476px',
  },
  doubleTextArea: {
    minHeight: '100',
    width: '220px',
    marginTop: '20px ',
  },
  doubleTextAreaRight: {
    minHeight: '100',
    width: '220px',
    margin: '20px 0px 0px 20px ',
  },
  labelTop: {
    position: 'absolute',
    marginTop: '-3px',
    fontWeight: 'bold',
  },
  labelTopRight: {
    position: 'absolute',
    marginTop: '-3px',
    marginLeft: '20px',
    fontWeight: 'bold',
  },
  doubleDropdown: {
    width: '220px',
    marginTop: '20px ',
    marginLeft: '20px',
  },
  newInputDate: {
    width: '240px',
  },
  doubleTextField: {
    minHeight: '100',
    width: '220px',
    marginTop: '18px ',
  },
  doubleTextFieldRight: {
    minHeight: '100',
    width: '218px',
    marginLeft: '20px ',
  },
  labelError: {
    position: 'absolute',
    margin: '60px -220px',
    fontWeight: 'bold',
    color: 'red',
  },
  spanError: {
    fontWeight: 'bold',
    color: 'red',
  },
  labelRequired: {
    color: 'red',
    // position: 'absolute',
    // top: '1.7em',
    // left: '5.5em',
  },
  textAreaOnFluid: {
    minHeight: '3em',
    width: '33em',
    height: '3em',
  },
  textAreaOnFluidDisabled: {
    minHeight: '3em',
    width: '33em',
    height: '3em',
    color: 'rgba(0, 0, 0, 0.4)',
  },
  widthTextFieldonFluid: {
    width: '32.5em',
  },
  buttonTransportationPricePosition: {
    marginLeft: '63.5em',
    marginBottom: '1em',
  },
  styleDatePickerRestaurant: {
    width: '16.8em',
  },
  styleDropdownWithoutWidth: {
    minHeight: '100',
    marginLeft: '13em ',
    width: '5em',
    marginTop: '-2.9em',
  },
  textfieldDateBalanceDetail: {
    minHeight: '100',
    width: '12em',
    marginTop: '18px ',
  },
  widthNoteBalanceDetail: {
    width: '31.5em',
    height: '3em',
  },
  sizeInModalAccomodation: {
    width: '17em',
  },
  widthRestaurantItem: {
    width: '36.5em',
  },
};

const StyleButton = {
  buttonGroup: {
    position: 'absolute',
  },
  buttonTransparent: {
    background: 'transparent',
    color: '#417df4',
    padding: '0px',
  },
  buttonLogoSupplier: {
    margin: '10px 10px 20px 70px',
  },
  buttonTransparentGreen: {
    background: 'transparent',
    color: '#4cd137',
  },
  buttonTransparentBlack: {
    background: 'transparent',
    color: '#000',
  },
  buttonTransparentRed: {
    background: 'transparent',
    color: '#ff3d3d',
  },
  iconTransparentBlue: {
    background: 'transparent',
    color: '#417df4',
    /*  marginLeft: '29em',
      top: '5.5em',
      position: 'absolute', */
  },
  buttonWidthPaymentGateway: {
    width: '34em',
  },
  buttonBlueActivity: {
    background: 'transparent',
    color: '#417df4',
    padding: '0px',
    marginLeft: '20em',
  },
};
const StyleDate = {
  inputDateStyle: {
    border: 'none',
    borderBottom: '1px solid grey',
    borderRadius: '0px',
    paddingLeft: '20px !important',
  },
};
const StyleButtonLikeLink = {
  background: 'transparent',
  color: '#4183c4',
};
export {
  StylesTextfieldWithError,
  StyleButton,
  StyleDate,
  StyleButtonLikeLink,
};
