import React, { Component } from 'react';
import { Table, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../../../scripts/pagination';
import Loader from '../../../../../components/loader/loaderInModal';
import Pagination from '../../../../../components/common/pagination';
import { convertDateFormat } from '../../../../../scripts/moment';
import ModalAlert from '../../../../shared/components/modal/ModalAlert';
import ModalConfirm from '../../../../shared/components/modal/ModalConfirmationAlert';
import styles from '../../../../../components/table/styles';
import {
  getResendRequestConfirmation,
  getListByCodeConfirmRoomAllotmentAction,
} from '../../../../shared/actions/accommodationAction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const PENDING = 'Pending';
const ACCEPTED = 'Accepted';
const DECLINED = 'Declined';
const AUTO_CONFIRMED = 'Auto_confirmed';
const convertStatus = item => {
  const { Status, ConfirmationNote, ConfirmationNumber } = item;
  let data = {
    id: Status,
    text: Status ? Status.split('_').join(' ') : '-',
    contentButton: 'null',
    styleText: {},
  };
  switch (Status) {
    case PENDING:
      data.contentButton = 'Re-Request';
      data.styleText = styles.colorYellow;
      data.isConfirm = true;
      data.dataAction = {
        header: 'Confirmation',
        message: 'Are you sure want to re-request this confirmation?',
        post: item,
        type: Status,
      };
      break;
    case DECLINED:
      data.contentButton = 'See Note';
      data.styleText = styles.colorOrangeRed;
      data.isAlert = true;
      data.dataAction = {
        header: 'Note',
        message: ConfirmationNote ? ConfirmationNote : 'No note',
        post: item,
        type: Status,
      };
      break;
    case ACCEPTED:
      data.contentButton = 'Conf No.';
      data.styleText = {};
      data.isAlert = true;
      data.dataAction = {
        header: 'Confirmation Number',
        message: ConfirmationNumber
          ? ConfirmationNumber
          : 'No confirmation number',
        post: item,
        type: Status,
      };
      break;
    case AUTO_CONFIRMED:
      // data.hiddenButton = true;
      data.styleText = {};
      data.contentButton = 'Re-Request';
      data.isConfirm = true;
      data.dataAction = {
        header: 'Confirmation',
        message: 'Are you sure want to re-request this confirmation?',
        post: item,
        type: Status,
      };
      break;
    default:
      data.contentButton = data.text;
      data.styleText = {};
  }
  return data;
};

class TableBookingStatusList extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
      pageLength: 3,
      modalAlertOpen: false,
      modalAlertHeader: '',
      modalAlertMessage: '',
      modalConfirmOpen: false,
      modalConfirmHeader: '',
      modalConfirmMessage: '',
      itemBookingList: [],
      loadingGetConfirmRoomAllotment: false,
    };
  }
  // static propTypes = {
  //   data: PropTypes.array,
  //   listConfirmRoomAllotment: PropTypes.array,
  //   loading: PropTypes.bool,
  //   handleOpenModalAlert: PropTypes.func,
  //   handleOpenModalConfirm: PropTypes.func,
  // };

  getConfirmAllotment = () => {
    const { Id } = this.props.match.params;
    this.setState(
      {
        loadingGetConfirmRoomAllotment: true,
      },
      () => {
        this.props
          .getListByCodeConfirmRoomAllotmentAction(Id)
          .then(res =>
            this.setState({
              itemBookingList: res.value.data,
              loadingGetConfirmRoomAllotment: false,
            })
          )
          .catch(() => {
            this.setState({
              loadingGetConfirmRoomAllotment: false,
            });
          });
      }
    );
  };

  componentDidMount() {
    this.getConfirmAllotment();
  }

  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };

  handleOpenModalAlert = (e, { value }) =>
    this.setState({
      ...this.state,
      modalAlertOpen: true,
      modalAlertHeader: value && value.header,
      modalAlertMessage: value && value.message,
      dataPost: value && value.post,
    });

  handleOpenModalConfirm = (e, { value }) =>
    this.setState({
      ...this.state,
      modalConfirmOpen: true,
      modalConfirmHeader: value && value.header,
      modalConfirmMessage: value && value.message,
      dataPost: value && value.post,
      actionType: value && value.type,
    });

  handleCloseAlert = () =>
    this.setState({ ...this.state, modalAlertOpen: false });

  handleDeclineConfirm = () =>
    this.setState({ ...this.state, modalConfirmOpen: false });

  handleAcceptConfirm = () => {
    const { dataPost } = this.state;
    const { TransactionId, Token } = dataPost;
    this.props
      .getResendRequestConfirmation(TransactionId, Token)
      .then(async () => {
        await this.openModalAlert('Success', 'Please check email');
        this.handleDeclineConfirm();
      })
      .catch(async err => {
        await this.openModalAlert(
          'Failed',
          err.response.data && err.response.data.Message
            ? err.response.data.Message
            : 'Failed resend room confirmation'
        );
        this.handleDeclineConfirm();
      });
  };

  openModalAlert = (modalAlertHeader, modalAlertMessage) =>
    this.setState({
      ...this.state,
      modalAlertOpen: true,
      modalAlertHeader,
      modalAlertMessage,
    });

  render() {
    // let pagedData = datasetPagination(this.props.listConfirmRoomAllotment, 3);
    let pagedData = datasetPagination(this.state.itemBookingList, 3);
    let listData = pagedData[this.state.activePage - 1];
    // const props = this.props;

    let { numbersFrom, numbersTo } = getNumberFromTo(
      listData,
      this.state.activePage,
      3
    );

    // const {
    //   // listConfirmRoomAllotment,
    //   // loading,
    //   //   handleOpenModalAlert,
    //   //   handleOpenModalConfirm,
    // } = this.props;
    const {
      modalAlertOpen,
      modalAlertHeader,
      modalAlertMessage,
      modalConfirmOpen,
      modalConfirmMessage,
      modalConfirmHeader,
      loadingGetConfirmRoomAllotment,
    } = this.state;
    let loading = loadingGetConfirmRoomAllotment;
    return (
      <div>
        <Loader loading={loading} />
        <Table basic="very" selectable style={{ marginTop: '35px' }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Booking Code</Table.HeaderCell>
              <Table.HeaderCell>Supplier Name</Table.HeaderCell>
              <Table.HeaderCell>Item Name</Table.HeaderCell>
              <Table.HeaderCell>Expired Date Order</Table.HeaderCell>
              <Table.HeaderCell>Order Date</Table.HeaderCell>
              <Table.HeaderCell>Quantity</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {/* listConfirmRoomAllotment.map((item, idx) => { */}
            {!loading && listData && listData.length > 0 ? (
              listData.map((item, idx) => {
                let status = convertStatus(item);
                return (
                  <Table.Row key={idx}>
                    <Table.Cell>{item.TransactionId}</Table.Cell>
                    <Table.Cell>{item.AccommodationProfileName}</Table.Cell>
                    <Table.Cell>{item.AccommodationItemName}</Table.Cell>
                    <Table.Cell>
                      {convertDateFormat(
                        item.ExpiredDate,
                        'DD MMM YYYY | HH:mm:ss'
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {convertDateFormat(item.CheckInDateTime, 'DD MMM YYYY') +
                        ' - ' +
                        convertDateFormat(item.CheckOutDateTime, 'DD MMM YYYY')}
                    </Table.Cell>
                    <Table.Cell>{item.Qty} Rooms/Night</Table.Cell>
                    <Table.Cell style={status && status.styleText}>
                      {status && status.text}
                    </Table.Cell>
                    <Table.Cell>
                      {!status.hiddenButton && (
                        <Button
                          content={status && status.contentButton}
                          value={status.dataAction}
                          onClick={
                            status.isConfirm
                              ? this.handleOpenModalConfirm
                              : status.isAlert && this.handleOpenModalAlert
                          }
                          primary
                        />
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              })
            ) : (
              <Table.Row>
                <Table.Cell colSpan="8" textAlign="center">
                  <Loader active={loading} inline={true} />
                </Table.Cell>
              </Table.Row>
            )}
            {!loading && this.state.itemBookingList.length === 0 && (
              <Table.Row>
                <Table.Cell colSpan="8" textAlign="center">
                  No Data
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
          <ModalAlert
            openModal={modalAlertOpen}
            handleClose={this.handleCloseAlert}
            header={modalAlertHeader}
            content={modalAlertMessage}
          />
          <ModalConfirm
            openModal={modalConfirmOpen}
            size="mini"
            close={this.handleDeclineConfirm}
            // confirm={this.handleAcceptConfirm}
            confirm={this.handleAcceptConfirm}
            message={modalConfirmMessage}
            header={modalConfirmHeader}
            loading={this.props.loadingGetResendRequestConfirm}
          />
        </Table>

        <Pagination
          pagedData={pagedData}
          numbersTo={numbersTo}
          numbersFrom={numbersFrom}
          // data={this.props.listConfirmRoomAllotment}
          data={this.state.itemBookingList}
          pageChange={this.pageChange}
          activePage={this.state.activePage}
        />
      </div>
    );
  }
}

TableBookingStatusList.propTypes = {
  listData: PropTypes.array,
  handleChoose: PropTypes.func,
  loadingGetUserByCode: PropTypes.bool,
  getResendRequestConfirmation: PropTypes.func,
  loadingGetResendRequestConfirm: PropTypes.bool,
  loading: PropTypes.bool,
  listConfirmRoomAllotment: PropTypes.array,
  getListByCodeConfirmRoomAllotmentAction: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      Id: PropTypes.number,
      type: PropTypes.string,
      TypeTransaction: PropTypes.string,
      PackageType: PropTypes.string,
    }),
  }),
};

function mapStateToProps(state) {
  return {
    loadingGetListConfirmRoomAllotment:
      state.AccomodationStore.loadingGetListConfirmRoomAllotment,
    listConfirmRoomAllotment: state.AccomodationStore.listConfirmRoomAllotment,
    loadingGetResendRequestConfirm:
      state.AccomodationStore.loadingGetResendRequestConfirm,
  };
}

export default connect(mapStateToProps, {
  // getListConfirmRoomAllotments,
  getListByCodeConfirmRoomAllotmentAction,
  getResendRequestConfirmation,
})(withRouter(TableBookingStatusList));
