const COLOR_ADMIN = '#38af95';
const styles = {
  noBorder: {
    border: 'none',
  },
  backgroundWhiteBorderGold: {
    padding: '0px',
    border: '1px solid #38af95',
    background: 'white',
  },
  backgroundGoldTransparentPadding7px20px: {
    padding: '7px 20px',
    background: 'rgba(56, 175, 149, 0.1)',
  },
  colorBorderGold: {
    color: COLOR_ADMIN,
    border: '1px solid #38af95',
  },
  paddingTenAndTwenty: {
    padding: '10px 20px',
  },
  noMargin: {
    margin: '0px',
  },
  noPadding: {
    padding: '0px',
  },
  buttonBorderBlack: {
    padding: '5px',
    border: '2px solid #000000',
    background: 'white',
  },
  buttonAdmin: {
    padding: '5px',
    border: '2px solid #38af95',
    background: COLOR_ADMIN,
    color: 'white',
  },
  calender50: {
    padding: '5px 0px 5px 0px',
    background: 'rgba(0,0,0, 0.5)',
    color: 'white',
  },
  calender20: {
    padding: '2px 0px 2px 0px',
    background: 'rgba(0,0,0, 0.2)',
    color: 'white',
  },
  calender50withHeight: {
    padding: '5px 0px 5px 0px',
    background: 'rgba(0,0,0, 0.5)',
    color: 'white',
    height: '29px',
  },
  calender20withHeight: {
    padding: '2px 0px 2px 0px',
    background: 'rgba(0,0,0, 0.2)',
    color: 'white',
    height: '23px',
  },
  inputAllotmentSpecial: {
    padding: '0px 5px 3px 5px',
    background: 'rgba(56,175,149,0.1)',
  },
  inputAllotmentDefault: {
    padding: '1.6px 5px',
    background: 'rgba(56,175,149,0.1)',
  },
  disableInputAllotment: {
    padding: '5px 5px',
    height: '74px',
    background: 'rgba(56,175,149,0.1)',
  },
};
export default styles;
