import React from 'react';
import { Dimmer } from 'semantic-ui-react';
import { connect } from 'react-redux';
import SegmentRestaurantList from './components/RestaurantList';
import ModalAlert from '../../shared/components/modal/ModalAlert';
import ModalConfirm from '../../../components/modal/modalConfirmationTwoButton';
import Loader from '../../../components/loader';
import PropTypes from 'prop-types';
import {
  // getRestaurantListAction,
  resetRestaurantAction,
  deleteRestaurantByProfileIdAction,
  getTemplateRestaurantProfileAction,
  getExportRestaurantProfileAction,
  getExportRestaurantItemAction,
} from '../../../actions/restaurantAction';
import {
  getSupplierListAction,
  resetAccomodationAction,
} from '../../shared/actions/accommodationAction';
class RestaurantList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      restaurantList: [],
      loadingRestaurant: true,
      failedRestaurant: false,
      modalOpen: false,
      modalDeleteConfirmation: false,
      modalInformation: '',
      modalHeader: '',
      confirmationDelete: false,
      idProfile: 0,
      openDelete: false,
      deleteStatus: '',
      deleteMessage: '',
    };
  }

  static propTypes = {
    getSupplierListAction: PropTypes.func,
    resetRestaurantAction: PropTypes.func,
    deleteRestaurantByProfileIdAction: PropTypes.func,
    getRestaurantListStatus: PropTypes.string,
    getRestaurantList: PropTypes.array,
    getTemplateRestaurantProfileAction: PropTypes.func,
    getRestaurantProfileTemplateStatus: PropTypes.string,
    getRestaurantProfileTemplate: PropTypes.array,
    getExportRestaurantProfileAction: PropTypes.func,
    getRestaurantProfileExportStatus: PropTypes.string,
    getRestaurantProfileExport: PropTypes.string,
    deleteRestaurantByProfileIdStatus: PropTypes.string,
    errors: PropTypes.object,
    resetAccomodationAction: PropTypes.func,
    getRestaurantItemExportStatus: PropTypes.string,
    getRestaurantItemExport: PropTypes.array,
    getExportRestaurantItemAction: PropTypes.func,
  };
  handleClose = () =>
    this.setState({
      modalOpen: false,
      modalDeleteConfirmation: false,
      failedRestaurant: false,
    });
  componentDidMount = () => {
    // this.props.getRestaurantListAction();
    this.props.getSupplierListAction('RestaurantProfiles');
  };
  componentDidUpdate() {
    if (this.props.getRestaurantListStatus === 'success') {
      this.setState({
        restaurantList: this.props.getRestaurantList,
      });
      this.setState({ loadingRestaurant: false });
      // this.props.resetRestaurantAction();
      this.props.resetAccomodationAction();
    } else if (this.props.getRestaurantListStatus === 'failed') {
      this.setState({ failedRestaurant: true });
      this.setState({ loadingRestaurant: false });
      this.props.resetRestaurantAction();
    }
    if (this.props.deleteRestaurantByProfileIdStatus === 'success') {
      this.setState({
        modalDeleteConfirmation: false,
        openDelete: true,
        deleteStatus: 'Success',
        deleteMessage: 'Restaurant successfully deleted',
      });
      // this.props.getRestaurantListAction();
      this.props.getSupplierListAction('RestaurantProfiles');
      this.props.resetRestaurantAction();
    }
    if (this.props.deleteRestaurantByProfileIdStatus === 'failed') {
      this.setState({
        modalDeleteConfirmation: false,
        deleteStatus: 'Failed',
        openDelete: true,
        deleteMessage: 'Restaurant Profile failed,please check your data first',
      });
      this.props.resetRestaurantAction();
    }
    if (this.props.getRestaurantProfileTemplateStatus === 'success') {
      var templateRestaurant = this.props.getRestaurantProfileTemplate;
      var elemenTemplateRestaurant = document.createElement('a');
      var TemplateRestaurant = new Blob([templateRestaurant], {
        type: 'text/plain',
      });
      elemenTemplateRestaurant.href = URL.createObjectURL(TemplateRestaurant);
      elemenTemplateRestaurant.download = 'Restaurant Template.txt';
      elemenTemplateRestaurant.click();
      this.props.resetRestaurantAction();
    } else if (this.props.getRestaurantProfileTemplateStatus === 'failed') {
      this.props.resetRestaurantAction();
    }
    if (this.props.getRestaurantProfileExportStatus === 'success') {
      var exportRestaurant = this.props.getRestaurantProfileExport;
      var elemenExportRestaurant = document.createElement('a');
      var ExportRestaurant = new Blob([exportRestaurant], {
        type: 'text/plain',
      });
      elemenExportRestaurant.href = URL.createObjectURL(ExportRestaurant);
      elemenExportRestaurant.download = 'Restaurant Export.txt';
      elemenExportRestaurant.click();
      this.props.resetRestaurantAction();
      this.setState({ loadingRestaurant: false });
    } else if (this.props.getRestaurantProfileExportStatus === 'failed') {
      this.setState({ loadingRestaurant: false });
      this.props.resetRestaurantAction();
    }
    if (this.props.getRestaurantItemExportStatus === 'success') {
      this.handleDownload(
        this.props.getRestaurantItemExport,
        'Restaurant Item Export.txt'
      );
      this.setState({ loadingRestaurant: false });
      this.props.resetRestaurantAction();
    } else if (this.props.getRestaurantItemExportStatus === 'failed') {
      this.setState({ loadingRestaurant: false });
      this.props.resetRestaurantAction();
    }
  }
  closeDelete = () => {
    this.setState({ openDelete: false });
  };
  buttonEditRestaurant = (e, { value }) => {
    // eslint-disable-next-line
    this.props.history.push('/Restaurant-Profile/Edit/' + value);
  };
  buttonDeleteRestaurant = (e, { value }) => {
    this.confirmationDeleteRestaurant();
    this.setState({
      idProfile: value,
    });
  };
  confirmationDeleteRestaurant = () => {
    this.setState({
      modalDeleteConfirmation: true,
      modalInformation: 'Are you sure want to delete this restaurant profile?',
    });
  };
  confirmDelete = () => {
    let id = this.state.idProfile;
    this.props.deleteRestaurantByProfileIdAction(id);
  };
  newRestaurant = () => {
    // eslint-disable-next-line
    this.props.history.push('/Restaurant-Profile');
  };
  ButtonUploadData = () => {
    // eslint-disable-next-line
    this.props.history.push(`/Upload-Master-Data/${'Restaurant'}`);
  };
  ButtonDownloadTemplate = () => {
    this.props.getTemplateRestaurantProfileAction();
  };
  ButtonExport = () => {
    this.setState({ loadingRestaurant: true });
    this.props.getExportRestaurantProfileAction();
  };
  handleCloseDelete = () => {
    this.setState({ modalDeleteConfirmation: false });
  };
  ButtonExportItem = () => {
    this.setState({ loadingRestaurant: true });
    this.props.getExportRestaurantItemAction();
  };
  handleDownload = (exportFile, name) => {
    var elementDownload = document.createElement('a');
    var fileData = new Blob([exportFile], {
      type: 'text/plain',
    });
    elementDownload.href = URL.createObjectURL(fileData);
    elementDownload.download = name;
    elementDownload.click();
  };
  render() {
    let { loadingRestaurant, failedRestaurant } = this.state;
    let loading = loadingRestaurant;
    let openModal = failedRestaurant;
    return (
      <div>
        <Dimmer.Dimmable blurring dimmed={loading}>
          <Loader loading={loading} />
          <SegmentRestaurantList
            data={this.state.restaurantList}
            type="restaurant"
            filterBy="Name"
            buttonNew={this.newRestaurant}
            butttonExport={this.ButtonExport}
            buttonDownloadTemplate={this.ButtonDownloadTemplate}
            buttonUploadData={this.ButtonUploadData}
            butttonEdit={this.buttonEditRestaurant}
            buttonDelete={this.buttonDeleteRestaurant}
            buttonExportItem={this.ButtonExportItem}
          />
          <ModalAlert
            openModal={openModal}
            handleClose={this.handleClose}
            header="Failed"
            content={this.props.errors.global}
            nameButton="OK"
          />
          <ModalAlert
            openModal={this.state.openDelete}
            handleClose={this.closeDelete}
            header={this.state.deleteStatus}
            content={this.state.deleteMessage}
            nameButton="OK"
          />
          <ModalConfirm
            openModal={this.state.modalDeleteConfirmation}
            size="tiny"
            message={this.state.modalInformation}
            close={this.handleCloseDelete}
            confirm={this.confirmDelete}
          />
        </Dimmer.Dimmable>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    // getRestaurantList: state.RestaurantStore.getRestaurantList,
    // getRestaurantListStatus: state.RestaurantStore.getRestaurantListStatus,
    getRestaurantList: state.AccomodationStore.getSupplierList,
    getRestaurantListStatus: state.AccomodationStore.getSupplierListStatus,
    deleteRestaurantByProfileIdStatus:
      state.RestaurantStore.deleteRestaurantByProfileIdStatus,
    getRestaurantProfileTemplate:
      state.RestaurantStore.getRestaurantProfileTemplate,
    getRestaurantProfileTemplateStatus:
      state.RestaurantStore.getRestaurantProfileTemplateStatus,
    getRestaurantProfileExport:
      state.RestaurantStore.getRestaurantProfileExport,
    getRestaurantProfileExportStatus:
      state.RestaurantStore.getRestaurantProfileExportStatus,
    errors: state.RestaurantStore.errors,
    getRestaurantItemExport: state.RestaurantStore.getRestaurantItemExport,
    getRestaurantItemExportStatus:
      state.RestaurantStore.getRestaurantItemExportStatus,
  };
}
export default connect(
  mapStateToProps,
  {
    // getRestaurantListAction,
    resetRestaurantAction,
    deleteRestaurantByProfileIdAction,
    getTemplateRestaurantProfileAction,
    getExportRestaurantProfileAction,
    getSupplierListAction,
    resetAccomodationAction,
    getExportRestaurantItemAction,
  }
)(RestaurantList);
