import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form } from 'semantic-ui-react';
import Input from '../../../components/input/inputWithLabel';
import ButtonClose from '../../../components/button/buttonCloseModal';
import {
  validationAddBookingItem,
  ERRORS_BOOKING_ITEM,
} from './../scripts/resourceDetail';
import { dateNow, convertDateFormat } from '../../../scripts/moment';
import { copyObject } from '../../../scripts/itineraryBuilder/itineraryBuilder';
import ModalAlert from '../../shared/components/modal/ModalAlert';

const listStatus = [
  {
    key: 'CREATED',
    value: 'CREATED',
    text: 'Created',
  },
  {
    key: 'PARTIALBOOKING',
    value: 'PARTIALBOOKING',
    text: 'Partial Booking',
  },
  {
    key: 'FULLBOOKED',
    value: 'FULLBOOKED',
    text: 'Full Booked',
  },
];
const ModalAddBookingItem = props => {
  const [bookingItem, setBookingItem] = useState({
    Id: null,
    Qty: 0,
    Note: '',
    Date: convertDateFormat(dateNow, 'YYYY-MM-DDThh:mm:ss'),
    ResourceStatus: 'CREATED',
    ResourceBookingDetailColletionId: props.resourceId,
    SupplierRefNo: '',
    IsNew: true,
    IsEdit: false,
    IsDelete: false,
  });
  const [errors, setErrors] = useState(copyObject(ERRORS_BOOKING_ITEM));
  const [modalAlert, setModalAlert] = useState({
    isOpen: false,
    header: '',
    message: '',
  });
  // end state
  // action
  useEffect(() => {
    if (props.openModal && props.selectedBookingItem !== null) {
      setBookingItem({
        ...props.selectedBookingItem,
      });
    }
    // eslint-disable-next-line
  }, [props.openModal]);
  // end action
  const resetBookingItem = () => {
    setBookingItem({
      Qty: 0,
      Note: '',
      Date: convertDateFormat(dateNow, 'YYYY-MM-DDThh:mm:ss'),
      ResourceStatus: 'CREATED',
      ResourceBookingDetailColletionId: props.resourceId,
      SupplierRefNo: '',
      IsNew: true,
      IsEdit: false,
      IsDelete: false,
    });
  };
  const handleClose = () => {
    resetBookingItem();
    props.handleClose();
  };
  const handleBookingItem = (e, { name, value }) => {
    value = name === 'Qty' ? parseInt(value.replace(/\D/g, ''), 10) : value;
    setBookingItem({ ...bookingItem, [name]: value, IsEdit: true });
    if (name === 'ResourceStatus' && value === 'FULLBOOKED') {
      openModalalert(
        'Alert',
        'if you change the status to full booked, you cannot change the reservation again'
      );
    }
  };

  const handleAddBookingItem = () => {
    let validation = validationAddBookingItem(
      bookingItem,
      props.listBookingItem,
      props.totalQty
    );
    if (validation.isError) setErrors(validation.errors);
    else {
      props.handleBookingItem({
        ...bookingItem,
        ResourceBookingDetailColletionId: props.resourceId,
      });
      resetBookingItem();
      setErrors(copyObject(ERRORS_BOOKING_ITEM));
    }
  };
  const openModalalert = (header, message) => {
    setModalAlert({
      isOpen: true,
      header,
      message,
    });
  };
  const closeModalAlert = () =>
    setModalAlert({ isOpen: false, header: '', message: '' });
  let { openModal } = props;
  return (
    <Modal
      dimmer="blurring"
      inverted
      open={openModal}
      onClose={handleClose}
      size="tiny"
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        Add Booking Item <ButtonClose onClose={handleClose} />
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group widths="equal">
            <Input
              type="number"
              label="Qty"
              placeholder="Qty"
              value={bookingItem.Qty}
              name="Qty"
              handleChange={handleBookingItem}
              validate={errors}
            />
            <Input
              type="select"
              label="Status"
              placeholder="Status"
              value={bookingItem.ResourceStatus}
              name="ResourceStatus"
              handleChange={handleBookingItem}
              validate={errors}
              options={listStatus}
            />
          </Form.Group>
          <Input
            type="text"
            label="Note"
            placeholder="Note"
            value={bookingItem.Note}
            name="Note"
            handleChange={handleBookingItem}
            validate={errors}
          />
          <Input
            type="text"
            label="Supplier ref no."
            placeholder="PNR / Confirmation number"
            value={bookingItem.SupplierRefNo}
            name="SupplierRefNo"
            handleChange={handleBookingItem}
            validate={errors}
          />
        </Form>
        <ModalAlert
          openModal={modalAlert.isOpen}
          handleClose={closeModalAlert}
          header={modalAlert.header}
          content={modalAlert.message}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button positive onClick={handleAddBookingItem} content="save" />
      </Modal.Actions>
    </Modal>
  );
};

ModalAddBookingItem.propTypes = {
  openModal: PropTypes.bool,
  handleClose: PropTypes.func,
  handleBookingItem: PropTypes.func,
  listBookingItem: PropTypes.array,
  totalQty: PropTypes.number,
  resourceId: PropTypes.number,
  selectedBookingItem: PropTypes.object,
};

export default ModalAddBookingItem;
