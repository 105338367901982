const BLACK_COLOR = '#252525';
const GOLD_COLOR = '#e6ca6b';
const WHITE_COLOR = '#FFF';
const GREY_COLOR = '#f2f2f2';

const style = {
  backgroundModal: {
    backgroundColor: GREY_COLOR,
  },
  backgroundCard: {
    backgroundColor: WHITE_COLOR,
  },
  goldColor: {
    color: GOLD_COLOR,
    opacity: 1,
  },
  blackColor: {
    color: BLACK_COLOR,
  },
  greyColor: {
    color: '#d3d3d3',
  },
  message: {
    border: '0.3px solid #e6ca6b',
    backgroundColor: 'white',
    color: 'black',
  },
  messageDisable: {
    border: '0.5px solid #d3d3d3',
    backgroundColor: 'white',
    color: 'black',
  },
  //   boldGoldColor: {
  //     color: GOLD_COLOR,
  //     fontWeight: 'bold',
  //   },
  //   justify: {
  //     textAlign: 'justify',
  //   },
  //   labelGold: {
  //     borderColor: '#b2993d',
  //     background: 'linear-gradient(to right, #fffd9b, #b2993d)',
  //     color: 'white',
  //     paddingBottom: '5px',
  //     paddingTop: '5px',
  //   },
  buttonGold: {
    background: GOLD_COLOR,
    color: WHITE_COLOR,
  },
  transparentButton: {
    backgroundColor: WHITE_COLOR,
    color: GOLD_COLOR,
    padding: '0px',
  },
  buttonChooseRoom: {
    backgroundColor: GOLD_COLOR,
    color: BLACK_COLOR,
    padding: '10px',
  },
  buttonChooseRoomDisable: {
    backgroundColor: '#d3d3d3',
    color: '#252525',
    padding: '10px',
  },
  imageAttachment: {
    backgroundColor: BLACK_COLOR,
    opacity: 0.5,
  },
  //   inputStyles: {
  //     width: '100%',
  //     padding: '9px',
  //     borderBottom: '2px solid lightgrey',
  //   },
  // noMarginTop: {
  //   marginTop: '0px',
  // },
  noMarginBottom: {
    marginBottom: '0px',
  },
  marginBottomfifteen: { marginBottom: '15px' },
  noPaddingTop: { paddingTop: '0px' },
  paddingthree: {
    padding: '3px',
  },
};
export default style;
