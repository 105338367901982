const contactAddressField = [
  'Address1',
  'CountryId',
  'RegionId',
  'AreaId',
  'CityId',
  'Email',
  'PhoneNbr',
];
const excursionGeneralInfoField = [
  'Name',
  'Description',
  'AttractionType',
  'AttractionCategory',
  'OptimumDuration',
];
const priceFieldToValidate = [
  'ServicePriceType',
  'MinimumPax',
  'Description',
  'CutOffDay',
  'Currency',
];
export { contactAddressField, excursionGeneralInfoField, priceFieldToValidate };
