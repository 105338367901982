import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Input,
  Pagination,
  Grid,
  Segment,
  Button,
  Container,
} from 'semantic-ui-react';
import TabelOnHoldTransaction from './table/TableOnHoldTransaction';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../scripts/pagination';
import moment from 'moment';
import HeaderTitle from '../../../components/common/headerTitle';
import { getTransactionByStatusOnHoldAction } from '../homeAction';
import { resetStatusTransactionAction } from '../../../actions/tourTransactionAction';
import { convertDateFormat, addDate } from '../../../scripts/moment';
import ModalDateRange from '../../shared/components/modal/ModalDateRange';
class OnHoldTransaction extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
      headerTable: [
        'checkHold',
        'Booking Number',
        'Tour Name',
        'Company Name',
        'Total Guest',
        'Action',
      ],
      transactionOnHold: [],
      dateRangeonHold: {
        value: '',
        createStartDate: '',
        createEndDate: '',
        tourStartDate: convertDateFormat(new Date(), 'YYYY-MM-DD'),
        tourEndDate: convertDateFormat(
          addDate(new Date(), 30, 'd'),
          'YYYY-MM-DD'
        ),
      },
      disabled: true,
      openModalFilterBooking: false,
      radioValueDateRange: '1',
    };
  }
  componentDidMount() {
    const {
      createStartDate,
      createEndDate,
      tourStartDate,
      tourEndDate,
    } = this.state.dateRangeonHold;
    this.loadDataWithDate(
      createStartDate,
      createEndDate,
      tourStartDate,
      tourEndDate,
      'Booking_hold'
    );
  }

  handleCloseFilterBooking = () => {
    const { openModalFilterBooking } = this.state;
    this.setState({
      openModalFilterBooking: !openModalFilterBooking,
    });
  };
  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  filteredData = data =>
    data.filter(data => {
      let { searchInput } = this.state;
      return (
        data.Title.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1 ||
        data.Id.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1
      );
    });

  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };

  loadDataWithDate = (
    createStartDate,
    createEndDate,
    tourStartDate,
    tourEndDate,
    status
  ) => {
    // this.setState({ loading: true });
    this.props.getTransactionByStatusOnHoldAction(
      tourStartDate,
      tourEndDate,
      createStartDate,
      createEndDate,
      status
    );
  };

  componentDidUpdate() {
    if (this.props.statusTransactionOnHold === 'success') {
      this.setState({
        transactionOnHold: this.props.listTransactionOnHold.map(data => {
          return {
            Id: data.BookingId,
            Title: data.BookingName,
            CompanyCode: data.CompanyCode,
            CompanyName: data.CompanyName,
            TotalGuest: data.AdultPax + data.ChildPax,
            Status: data.TransactionStatus,
            PackageType: data.PackageType,
          };
        }),
        // loading: false,
      });
      this.props.resetStatusTransactionAction();
    }
  }
  setDisabledButton = () => {
    let check = true;
    this.state.transactionOnHold.map(data => {
      return data.checkHold ? (check = false) : null;
    });
    this.setState({ disabled: check });
  };
  onChangeHold = (e, { name, checked, id }) => {
    let checkHold = 'checkHold';
    let listOnHold = this.state.transactionOnHold;
    if (name === 'checkAll') {
      listOnHold.map((holdCheck, i) => {
        return (listOnHold[i] = { ...holdCheck, [checkHold]: checked });
      });
      this.setState({
        transactionOnHold: this.state.transactionOnHold,
        listOnHold,
      });
      this.setDisabledButton();
      this.handleCheckAll();
    } else {
      listOnHold.map(data => {
        if (data.Id === id) {
          data[checkHold] = checked;
          return listOnHold;
        }
        return listOnHold;
      });
      this.setState({ transactionOnHold: listOnHold });
      this.setDisabledButton();
      this.handleCheckAll();
    }
  };
  handleRadioDateRange = value => {
    this.setState({ radioValueDateRange: value });
  };

  actionDateRange = id => {
    const { radioValueDateRange } = this.state;
    const value = radioValueDateRange;
    const { from, to } = id;
    // const { statusFilter } = dateRangeonHold;
    this.setState({
      openModalFilterBooking: false,
    });
    let setDate = {};
    if (value === '2') {
      this.loadDataWithDate(
        moment(from).format('YYYY-MM-DD'),
        moment(to).format('YYYY-MM-DD'),
        '',
        '',
        'Booking_hold'
      );
      setDate = {
        createStartDate: from,
        createEndDate: to,
        tourStartDate: '',
        tourEndDate: '',
      };
      this.setState({ dateRangeonHold: setDate });
    } else {
      this.loadDataWithDate(
        '',
        '',
        moment(from).format('YYYY-MM-DD'),
        moment(to).format('YYYY-MM-DD'),
        'Booking_hold'
      );
      setDate = {
        createStartDate: '',
        createEndDate: '',
        tourStartDate: from,
        tourEndDate: to,
        value: 'Booking_hold',
      };
      this.setState({ dateRangeonHold: setDate });
    }
    this.props.handleSetDateRange(setDate);
  };

  openModalDateRange = () =>
    this.setState({
      openModalFilterBooking: true,
      dateRangeonHold: {
        createStartDate: '',
        createEndDate: '',
        tourStartDate: '',
        tourEndDate: '',
      },
    });

  handleCheckAll = () => {
    const { transactionOnHold } = this.state;
    let dataToSend = transactionOnHold.filter(item => item.checkHold);
    this.props.setTransactionOnHoldList(dataToSend);
  };

  render() {
    const {
      transactionOnHold,
      activePage,
      searchInput,
      headerTable,
      dateRangeonHold,
      // loading,
      disabled,
      radioValueDateRange,
    } = this.state;
    const { loading } = this.props;
    let filterData = this.filteredData(transactionOnHold);
    let pagedData = datasetPagination(filterData, 5);
    let listData = pagedData[activePage - 1];
    const props = this.props;

    let { numbersFrom, numbersTo } = getNumberFromTo(listData, activePage, 5);
    let createEndDate =
      dateRangeonHold.createEndDate === ''
        ? ''
        : moment(dateRangeonHold.createEndDate).format('DD MMM YYYY');
    let createStartDate =
      dateRangeonHold.createStartDate === ''
        ? ''
        : moment(dateRangeonHold.createStartDate).format('DD MMM');
    let tourStartDate =
      dateRangeonHold.tourStartDate === ''
        ? ''
        : moment(dateRangeonHold.tourStartDate).format('DD MMM');
    let tourEndDate =
      dateRangeonHold.tourEndDate === ''
        ? ''
        : moment(dateRangeonHold.tourEndDate).format('DD MMM YYYY');
    return (
      <div>
        <Grid columns="equal" style={{ marginTop: '30px' }}>
          <Grid.Row stretched>
            <Grid.Column width={15}>
              <Segment>
                <HeaderTitle title="Transaction On Hold" />
                <Input
                  icon="search"
                  placeholder="Search"
                  name="searchInput"
                  value={searchInput}
                  onChange={this.handleChange}
                />
                <Button
                  name="ApproveAll"
                  style={{ marginLeft: '10px' }}
                  value="ApproveAll"
                  positive
                  disabled={disabled}
                  onClick={this.props.actionTransaction}
                  content="Approve All"
                />
                <Button
                  name="RejectedAll"
                  style={{ marginLeft: '10px' }}
                  value="RejectedAll"
                  negative
                  disabled={disabled}
                  onClick={this.props.actionTransaction}
                  content="Reject All"
                />
                <Button
                  icon="filter"
                  basic
                  size="small"
                  floated="right"
                  positive
                  id="Transaction On Hold"
                  name="Booking_hold"
                  onClick={this.openModalDateRange}
                />

                <div
                  style={{
                    marginTop: '-2.9em',
                    marginRight: '5em',
                    fontWeight: 'bold',
                    marginBottom: '3em',
                  }}
                >
                  <Container
                    content={
                      'Start tour range date : ' +
                      tourStartDate +
                      ' - ' +
                      tourEndDate
                    }
                    textAlign="right"
                  />

                  <Container
                    content={
                      'Created tour range date : ' +
                      createStartDate +
                      ' - ' +
                      createEndDate
                    }
                    textAlign="right"
                  />
                </div>

                <TabelOnHoldTransaction
                  data={transactionOnHold}
                  headerTable={headerTable}
                  pageChange={this.pageChange}
                  listData={listData}
                  pagedData={pagedData}
                  loading={loading}
                  actionTransaction={props.actionTransaction}
                  openDetailTransaction={props.openDetailTransaction}
                  onChangeHold={this.onChangeHold}
                  activePage={activePage}
                />

                {pagedData && pagedData.length > 1 && (
                  <Pagination
                    totalPages={pagedData.length}
                    onPageChange={this.pageChange}
                    activePage={activePage}
                    style={{ float: 'right' }}
                  />
                )}
                {pagedData && pagedData.length > 1 && (
                  <p>
                    Showing {numbersTo} to {numbersFrom} of
                    {transactionOnHold.length}
                  </p>
                )}
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <ModalDateRange
            openModal={this.state.openModalFilterBooking}
            handleClose={this.handleCloseFilterBooking}
            dateRange={dateRangeonHold}
            actionDateRange={this.actionDateRange}
            contentHeader="Transaction On Hold"
            value={radioValueDateRange}
            handleRadioDateRange={this.handleRadioDateRange}
          />
        </Grid>
      </div>
    );
  }
}

OnHoldTransaction.propTypes = {
  data: PropTypes.array,
  actionTransaction: PropTypes.func,
  loading: PropTypes.bool,
  openDetailTransaction: PropTypes.func,
  disabled: PropTypes.bool,
  getTransactionByStatusOnHoldAction: PropTypes.func,
  statusTransactionOnHold: PropTypes.string,
  listTransactionOnHold: PropTypes.array,
  resetStatusTransactionAction: PropTypes.func,
  setTransactionOnHoldList: PropTypes.func,
  handleSetDateRange: PropTypes.func,
};
function mapStateToProps(state) {
  return {
    statusTransactionOnHold:
      state.TourTransactionStore.transactionByStatusOnHold,
    listTransactionOnHold:
      state.TourTransactionStore.listTransactionByStatusOnhold,
    loading: state.TourTransactionStore.loading,
  };
}

export default connect(mapStateToProps, {
  getTransactionByStatusOnHoldAction,
  resetStatusTransactionAction,
})(OnHoldTransaction);
