import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Button, Checkbox } from 'semantic-ui-react';
import InputWithLabel from '../../../../shared/components/common/input/InputWithLabel';
import LoaderModal from '../../../../../components/modal/loaderModal';
import ModalAlert from '../../../../../components/modal/modalAlert';

const ModalAdditionalServices = props => {
  // ------------------- initial -------------------
  const { data, openModal, handleClose, handleSave, loading, typeList } = props;
  const [id, setId] = useState(null);
  const [selectedType, setSelectedType] = useState(0);
  const [isMandatory, setIsMandatory] = useState(null);
  const [isInfantCount, setIsInfantCount] = useState(null);
  const [modalAlert, setModalAlert] = useState({
    isOpen: false,
    header: '',
    content: '',
  });
  // ------------------- helper -------------------

  // ------------------- handler -------------------
  const handleChange = (e, { value }) => {
    setId(value);
  };

  const handleChangeType = (e, { value }) => {
    if (value !== selectedType) setId(null);
    setSelectedType(value);
  };

  const validateForm = () => {
    let message = [];
    if (id === null) message.push('Additional service name cant be empty');
    if (isMandatory === null || (isMandatory && isInfantCount === null)) {
      message.push('you have to answer the question');
    }
    return message;
  };
  const handleSaveButton = () => {
    let messageValidation = validateForm();
    if (messageValidation.length === 0) {
      let selectedId = id;
      setId(null);
      handleSave(
        selectedId,
        isMandatory,
        isInfantCount === null ? false : isInfantCount
      );
      setIsMandatory(false);
      setIsInfantCount(false);
    } else {
      setModalAlert({
        isOpen: true,
        header: 'Validation',
        content: messageValidation,
      });
    }
  };

  const handleCloseModal = () => {
    setId(null);
    setIsMandatory(false);
    setIsInfantCount(false);
    handleClose();
  };

  const handleClickMandatory = (e, { value }) => {
    setIsMandatory(value);
  };

  const handleClickInfantInclude = (e, { value }) => {
    setIsInfantCount(value);
  };
  const handleCloseModalAlert = () => {
    setModalAlert({
      isOpen: false,
      header: '',
      content: '',
    });
  };
  // ------------------- lifecycle -------------------
  // useEffect(() => {
  //   setId(null);
  // }, [id]);

  // ------------------- variable -------------------
  let additionalServicesList = data.reduce((res, cur) => {
    let obj = {
      key: cur.Id,
      text: cur.AdditionalServicesName,
      value: cur.Id,
      type: parseInt(cur.AdditionalServiceType.Id),
    };
    res = res.concat(obj);
    return res;
  }, []);

  additionalServicesList =
    selectedType === 0
      ? additionalServicesList
      : additionalServicesList.filter(item => item.type === selectedType);

  return (
    <Modal
      dimmer="blurring"
      closeIcon
      inverted
      open={openModal}
      onClose={handleCloseModal}
      size="mini"
    >
      <Modal.Header>
        <h3>Add Additional Service</h3>
      </Modal.Header>
      <Modal.Content>
        <LoaderModal loading={loading} />
        <Form>
          <Form.Field>
            <InputWithLabel
              label="Type"
              type="select"
              required
              name="additionalServiceType"
              placeholder="Additional Services Type"
              options={typeList}
              handleChange={handleChangeType}
              value={selectedType}
            />
            <InputWithLabel
              label="Name"
              type="select"
              required
              name="additionalServiceName"
              placeholder="Additional Services Name"
              options={additionalServicesList}
              handleChange={handleChange}
              value={id}
            />
          </Form.Field>

          <Form.Field style={{ marginBottom: '5px' }}>
            Do you want the quantity to be adjusted the number of guests?
            <span style={{ color: 'red' }}> *</span>
          </Form.Field>
          <Form.Group>
            <Form.Field>
              <Checkbox
                radio
                label="Yes"
                name="IsMandatory"
                value={true}
                checked={isMandatory === true}
                onChange={handleClickMandatory}
                // objectname="commission"
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                radio
                label="No"
                name="IsMandatory"
                value={false}
                checked={isMandatory === false}
                onChange={handleClickMandatory}
                // objectname="commission"
              />
            </Form.Field>
          </Form.Group>
          {/* <Form.Field>
            <Checkbox
              label="Mandatory"
              name="IsMandatory"
              value={isMandatory}
              onClick={handleClickMandatory}
            />
          </Form.Field> */}
          {isMandatory && (
            <>
              <Form.Field style={{ marginBottom: '5px' }}>
                Do you want to include the number of infant in the quantity?
                <span style={{ color: 'red' }}> *</span>
              </Form.Field>
              <Form.Group>
                <Form.Field>
                  <Checkbox
                    radio
                    label="Yes"
                    name="IsInfantCount"
                    value={true}
                    checked={isInfantCount === true}
                    onChange={handleClickInfantInclude}
                    // objectname="commission"
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    radio
                    label="No"
                    name="IsInfantCount"
                    value={false}
                    checked={isInfantCount === false}
                    onChange={handleClickInfantInclude}
                    // objectname="commission"
                  />
                </Form.Field>
              </Form.Group>
            </>
            // <Form.Field>
            //   <Checkbox
            //     label="Infant Count"
            //     name="IsInfantCount"
            //     value={isMandatory ? isInfantCount : false}
            //     onClick={handleClickInfantInclude}
            //   />
            // </Form.Field>
          )}
        </Form>
        <ModalAlert
          openModal={modalAlert.isOpen}
          handleClose={handleCloseModalAlert}
          header={modalAlert.header}
          content={modalAlert.content}
          isArrayContentAlert={typeof modalAlert.content !== 'string'}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button content="Save" positive onClick={handleSaveButton} />
      </Modal.Actions>
    </Modal>
  );
};

ModalAdditionalServices.propTypes = {
  data: PropTypes.array,
  openModal: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  loading: PropTypes.bool,
  typeList: PropTypes.array,
};

export default React.memo(ModalAdditionalServices);
