const fieldToValidateTransportItem = [
  'Name',
  'TransportationRatingId',
  'Description',
  'Code',
  'TransportationSeatTypeId',
  'TransportationTypeId',
];
const fieldToValidatePriceDetail = [
  'Type',
  'MinPax',
  'Description',
  'CutOffDay',
  'Currency',
];
export { fieldToValidateTransportItem, fieldToValidatePriceDetail };
