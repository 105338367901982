const styles = {
  modalWidth: {
    width: '515px',
  },
  marginImage: {
    marginTop: '1em',
  },
  marginButton: {
    marginTop: '1em',
    marginLeft: '3.6em',
  },
  gridStyle: {
    marginTop: '30px',
  },
};
export default styles;
