import apiClient from '../apiClient';
import { POST_UPLOAD_IMAGE_EXCURSION_BY_SERVICE_ITEM_ID_URL } from '../apiUrl';

const postUploadImageExcursionObjectApi = (Id, data) => {
  return apiClient.post(
    POST_UPLOAD_IMAGE_EXCURSION_BY_SERVICE_ITEM_ID_URL(Id),
    data
  );
};

export default postUploadImageExcursionObjectApi;
