import React from 'react';
import { Form } from 'semantic-ui-react';
import Input from '../../../components/input/inputWithLabel';
import PropTypes from 'prop-types';

const TourOperatorForm = props => {
  const { tourOperatorData, errors, listCity, handleChange } = props;
  return (
    <Form>
      <Form.Group widths="equal">
        <Input
          inline={false}
          name="Port"
          type="number"
          label="Company Id"
          placeholder="Company Id"
          value={tourOperatorData.Company ? tourOperatorData.Company.Code : 0}
          disabled
        />
        <Input
          inline={false}
          name="Port"
          type="text"
          label="Company Name"
          placeholder="Company Name"
          value={tourOperatorData.Company ? tourOperatorData.Company.Name : ''}
          disabled
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Input
          inline={false}
          name="Name"
          type="text"
          label="Tour Operator Name"
          placeholder="Tour Operator Name"
          value={tourOperatorData.Name}
          required
          handleChange={handleChange}
          validate={errors}
          disabled={props.disable}
        />
        <Input
          inline={false}
          name="Email"
          type="text"
          label="Email"
          placeholder="Email"
          value={tourOperatorData.Email}
          required
          handleChange={handleChange}
          validate={errors}
          disabled={props.disable}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Input
          inline={false}
          name="Telephone"
          type="number"
          label="Telephone"
          placeholder="Telephone"
          value={tourOperatorData.Telephone}
          required
          handleChange={handleChange}
          validate={errors}
          disabled={props.disable}
        />
        <Input
          inline={false}
          name="CityId"
          type="select"
          label="City"
          placeholder="City"
          options={listCity}
          value={tourOperatorData.CityId}
          required
          handleChange={handleChange}
          validate={errors}
          disabled={props.disable}
        />
      </Form.Group>
      <Form.Field>
        <Input
          inline={false}
          name="Address"
          type="textArea"
          label="Address"
          placeholder="Address"
          value={tourOperatorData.Address}
          required
          handleChange={handleChange}
          validate={errors}
          disabled={props.disable}
        />
      </Form.Field>
    </Form>
  );
};
TourOperatorForm.propTypes = {
  handleChange: PropTypes.func,
  errors: PropTypes.object,
  tourOperatorData: PropTypes.object,
  listCity: PropTypes.array,
  disable: PropTypes.bool,
};
export default TourOperatorForm;
