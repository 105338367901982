import React from 'react';
import { Table, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const TableListCustomerProfile = props => (
  <Table basic="very" selectable fixed>
    <Table.Header>
      <Table.Row>
        {props.headerTable.map((headTableContent, index) => {
          return headTableContent === 'Action' ? (
            <Table.HeaderCell key={index} colSpan="2" textAlign="center">
              {headTableContent}
            </Table.HeaderCell>
          ) : (
            <Table.HeaderCell textAlign="left" key={index}>
              {headTableContent}
            </Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {props.listData &&
        props.listData.map((customerProfile, idx) => {
          return (
            <Table.Row key={idx}>
              <Table.Cell>
                {props.title && props.title === 'Partner Profile' ? (
                  <Link
                    to={`/Partner-Profile-Detail/detail/${customerProfile.Code}`}
                  >
                    {customerProfile.Code}
                  </Link>
                ) : (
                  <Link
                    to={`/Customer-Profile-Detail/detail/${customerProfile.Code}`}
                  >
                    {customerProfile.Code}
                  </Link>
                )}
              </Table.Cell>
              <Table.Cell>{customerProfile.Name}</Table.Cell>
              <Table.Cell>
                {customerProfile.TradelicenseNbr
                  ? customerProfile.TradelicenseNbr
                  : '-'}
              </Table.Cell>
              <Table.Cell>
                {' '}
                {customerProfile.Telephone ? customerProfile.Telephone : '-'}
              </Table.Cell>
              <Table.Cell>
                {' '}
                {customerProfile.Email ? customerProfile.Email : '-'}
              </Table.Cell>

              <Table.Cell colSpan="2" textAlign="center">
                <Button
                  primary
                  content={
                    props.isSales || props.isFinance ? 'See Detail' : 'Edit'
                  }
                  onClick={props.buttonEdit}
                  name={props.isSales || props.isFinance ? 'detail' : 'edit'}
                  value={customerProfile.Code}
                />
                {!props.isHideButtonDelete && (
                  <Button
                    negative
                    content="Delete"
                    onClick={props.buttonDelete}
                    name="delete"
                    value={customerProfile}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          );
        })}
    </Table.Body>
  </Table>
);

TableListCustomerProfile.propTypes = {
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  title: PropTypes.string,
  buttonEdit: PropTypes.func,
  buttonDelete: PropTypes.func,
  buttonSeeDetail: PropTypes.func,
  isHideButtonDelete: PropTypes.bool,
  isSales: PropTypes.bool,
  isFinance: PropTypes.bool,
};
export default TableListCustomerProfile;
