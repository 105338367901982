import React from 'react';
import { TablePackageList } from './';
import { Grid, Input, Button, Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../../scripts/pagination';
// import FilterData from '../../../../scripts/filterDataTable';
import filterListByTitleOrId from '../../services/helper/filterHelper';
class TabListPackagePublish extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let { isOperation, isSales } = this.props;
    let filterData = filterListByTitleOrId(
      this.props.packageListPublish,
      this.props.searchInput
    );
    let pagedData = datasetPagination(filterData, 7);
    let listData = pagedData[this.props.activePage - 1];
    // let numbers =
    // listData && listData.length
    //   ? this.props.activePage * (listData.length - 1)
    //   : 0;

    let { numbersFrom, numbersTo } = getNumberFromTo(
      listData,
      this.props.activePage,
      7
    );
    return (
      <div>
        <Grid>
          <Grid.Row columns="5">
            <Grid.Column>
              <Input
                icon="search"
                placeholder="search"
                name="searchInput"
                value={this.props.searchInput}
                onChange={this.props.handleChange}
              />
            </Grid.Column>
            <Grid.Column width={1}>
              <Popup
                content="Filter package by date"
                trigger={
                  <Button
                    icon="filter"
                    onClick={this.props.handleOnClickFilterDate}
                    positive
                    basic
                  />
                }
              />
            </Grid.Column>

            <Grid.Column>
              {isOperation || isSales || (
                <Button
                  content="Unpublish All"
                  primary
                  disabled={this.props.statusCheckPublish}
                  onClick={this.props.postActionUnPublishAll}
                />
              )}
            </Grid.Column>
            <Grid.Column />
            <Grid.Column />
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <TablePackageList
                listData={listData}
                openDetail={this.props.openDetailPackage}
                pagedData={pagedData}
                pageChange={this.props.pageChange}
                handleChangeCheck={this.props.handleChangeCheck}
                postActionPublish={this.props.postActionUnPublish}
                deletePackage={this.props.deletePackage}
                copyPackage={this.props.copyPackage}
                numbersFrom={numbersFrom}
                numbersTo={numbersTo}
                data={this.props.packageListPublish}
                popupContent={{
                  publish: 'Unpublish',
                  copy: 'Copy',
                  edit: 'Edit',
                  delete: 'Delete',
                }}
                typePackage={this.props.typePackage}
                activePage={this.props.activePage}
                loading={this.props.loading}
                isOperation={isOperation}
                isSales={isSales}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

TabListPackagePublish.propTypes = {
  packageListPublish: PropTypes.array,
  openDetailPackage: PropTypes.func,
  pagedData: PropTypes.array,
  pageChange: PropTypes.func,
  handleChange: PropTypes.func,
  searchInput: PropTypes.string,
  handleChangeCheck: PropTypes.func,
  statusCheckPublish: PropTypes.bool,
  postActionUnPublishAll: PropTypes.func,
  postActionUnPublish: PropTypes.func,
  deletePackage: PropTypes.func,
  deletePackageAllPublish: PropTypes.func,
  copyPackage: PropTypes.func,
  data: PropTypes.array,
  typePackage: PropTypes.string,
  activePage: PropTypes.number,
  loading: PropTypes.bool,
  handleOnClickFilterDate: PropTypes.func,
  isOperation: PropTypes.bool,
  isSales: PropTypes.bool,
};
export default TabListPackagePublish;
