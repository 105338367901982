import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';
import EmailSettingForm from '../form/EmailSettingForm';

const ModalAlert = props => (
  <Modal
    dimmer="blurring"
    inverted
    open={props.open}
    onClose={props.handleClose}
    size="mini"
    // closeOnDimmerClick={false}
  >
    <Modal.Header>{props.header}</Modal.Header>
    <Modal.Content>
      <EmailSettingForm
        emailSettingObj={props.emailSettingObj}
        handleChange={props.handleChange}
        errors={props.errors}
      />
    </Modal.Content>
    <Modal.Actions>
      <Button positive onClick={props.handleSave}>
        Save
      </Button>
    </Modal.Actions>
  </Modal>
);

ModalAlert.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  emailSettingObj: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  handleSave: PropTypes.func,
};

export default ModalAlert;
