import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Segment, Grid, Form, Button, Divider } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Input from '../../../components/input/inputWithLabel';
import HeaderTitle from '../../shared/components/common/headerSegment/HeaderTitle';
// import { getAccomodationListAction } from '../../../actions/accomodationAction';
import options from '../../../scripts/convertOption';
import { addDate, rangeDate, convertDateFormat } from '../../../scripts/moment';
import {
  setDayDateWithRange,
  setRoomAllotment,
} from '../../../scripts/roomAllotment';
import HeaderDate from '../../shared/components/calendar/Header';
import Availability from '../../shared/components/calendar/AccommodationAvaibility';
import { datasetPagination } from '../../../scripts/pagination';
import {
  getAccomodationListAction,
  getListRoomAllotmentByProfileId,
  getStockRoomAllotment,
  getExportStockRoomAllotment,
  resetAccomodationAction,
} from '../../../pages/shared/actions/accommodationAction';
// } from '../../../actions/accomodationAction';
import Loader from '../../../components/loader/loader';
import ModalAlert from '../../shared/components/modal/ModalAlert';
import ModalCalendar from '../../../components/modal/modalCalendar';
import { Redirect } from 'react-router-dom';
let TODAY = new Date();
let ENDDATE = addDate(TODAY, 1, 'M');
class SegmentAccommodationAvailbility extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedHotel: null,
      startDate: TODAY,
      endDate: ENDDATE,
      selectedRoom: [],
      activePage: 1,
      dates: [],
      roomAllotments: [],
      modalCalendarOpen: false,
      modalAlertOpen: false,
      modalAlertMessage: '',
      modalAlertHeader: '',
      changePage: '',
    };
  }
  componentDidMount() {
    this.props.getAccomodationListAction();
    if (this.props.id) {
      this.props.getListRoomAllotmentByProfileId(this.props.id);
    }
    this.initial();
  }
  // ======================= INITIAL =======================================
  initial = async () => {
    const { startDate, endDate } = this.state;
    let dates = await setDayDateWithRange(startDate, endDate);
    this.setState({
      ...this.state,
      dates,
      selectedHotel: parseInt(this.props.id, 10),
    });
  };

  // ===================== END INITIAL =====================================
  // ======================= GET ROOM AVAILABLE ============================
  getRoomAvailable = () => {
    const { selectedHotel, selectedRoom, startDate, endDate } = this.state;
    let rooms = selectedRoom.join(',');
    this.props
      .getStockRoomAllotment(
        selectedHotel,
        convertDateFormat(startDate, 'MM-DD-YYYY'),
        convertDateFormat(endDate, 'MM-DD-YYYY'),
        rooms
      )
      .then(async () => {
        let dates = await setDayDateWithRange(startDate, endDate);
        let newRoomAllotments = await setRoomAllotment(
          this.props.listStockRoomAllotment,
          dates,
          0
        );
        this.setState({
          ...this.state,
          roomAllotments: newRoomAllotments,
          dates,
        });
      })
      .catch(() => {
        this.handleOpenAlert('Failed', 'Failed Get Room Allotment');
      });
  };
  // ===================== END GET ROOM AVAILABLE ============================
  handleChangeRoom = (e, { name, value }) =>
    this.setState({ ...this.state, [name]: value }, () =>
      this.props.getListRoomAllotmentByProfileId(value)
    );

  handleChangeMultiSelect = async (e, { value }) => {
    this.setState({ ...this.state, selectedRoom: value });
  };

  changePage = (e, { name, value }) =>
    this.setState({ ...this.state, [name]: value });

  handleModalCalendar = () =>
    this.setState({
      ...this.state,
      modalCalendarOpen: !this.state.modalCalendarOpen,
    });

  handleOpenAlert = (header, message) =>
    this.setState({
      ...this.state,
      modalAlertOpen: true,
      modalAlertMessage: message,
      modalAlertHeader: header,
    });

  handleCloseAlert = () => {
    this.setState({ ...this.state, modalAlertOpen: false });
    this.props.resetAccomodationAction();
  };

  handleDateRange = (id, name) => {
    const { from, to } = id;
    // eslint-disable-next-line
    name;
    this.setState({ ...this.state, startDate: from, endDate: to }, () =>
      this.handleModalCalendar()
    );
  };
  handleBack = () => {
    this.setState({
      ...this.state,
      changePage: '/Accomodation/Detail/' + this.state.selectedHotel,
    });
  };
  exportExcel = () => {
    const { selectedHotel, selectedRoom, startDate, endDate } = this.state;
    let rooms = selectedRoom.join(',');
    this.props
      .getExportStockRoomAllotment(
        selectedHotel,
        convertDateFormat(startDate, 'MM-DD-YYYY'),
        convertDateFormat(endDate, 'MM-DD-YYYY'),
        rooms
      )
      .then(async () => {
        var exportAccomodation = this.props.exportRoomAllotment;
        var elementAccomodation = document.createElement('a');
        var fileAccomdation = new Blob([exportAccomodation], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
        });
        elementAccomodation.href = URL.createObjectURL(fileAccomdation);
        elementAccomodation.download = 'Room Allotment.xls';
        elementAccomodation.click();
      })
      .catch(() => {
        this.handleOpenAlert('Failed', 'Please Select Hotel Name & Room');
      });
  };
  render() {
    const {
      selectedHotel,
      startDate,
      endDate,
      selectedRoom,
      dates,
      activePage,
      roomAllotments,
      modalAlertOpen,
      modalAlertMessage,
      modalAlertHeader,
      modalCalendarOpen,
      changePage,
    } = this.state;
    const {
      id,
      listAccommodation,
      listRoom,
      loadingRoom,
      loadingHotel,
      loadingGetStockRoomAllotment,
      loadingExport,
    } = this.props;
    let pagedData = datasetPagination(dates, 7);
    let showDates = pagedData.length !== 0 ? pagedData[activePage - 1] : [];
    if (changePage !== '') {
      return <Redirect to={changePage} />;
    }
    return (
      <div style={{ margin: '30px 0px' }}>
        <Grid columns="equal">
          <Grid.Row stretched>
            <Grid.Column width={15}>
              <Segment>
                <HeaderTitle title="Room Availability" />
                <Grid>
                  <Grid.Row style={{ top: '-8px', padding: '0px' }}>
                    <Grid.Column width={4} floated="right">
                      <Button
                        positive
                        content="Export Excel"
                        onClick={this.exportExcel}
                        loading={loadingExport}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={4}>
                      <Form>
                        <Input
                          label="Hotel Name"
                          placeholder="Hotel Name"
                          type="select"
                          inline={false}
                          fluid={false}
                          value={selectedHotel}
                          name="selectedHotel"
                          handleChange={this.handleChangeRoom}
                          options={listAccommodation}
                          disabled={id ? true : false}
                          loading={loadingHotel}
                        />
                      </Form>
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <Form>
                        <Input
                          label="Date"
                          type="text"
                          inline={false}
                          fluid={false}
                          value={rangeDate(startDate, endDate)}
                          name="date"
                          handleClick={this.handleModalCalendar}
                          options={listAccommodation}
                        />
                      </Form>
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <Form>
                        <Input
                          label="Room"
                          placeholder="All Rooms"
                          type="select"
                          inline={false}
                          fluid={false}
                          multiple={true}
                          value={selectedRoom}
                          name="selectedRoom"
                          handleChange={this.handleChangeMultiSelect}
                          options={!loadingRoom ? listRoom : []}
                          loading={loadingRoom}
                        />
                      </Form>
                    </Grid.Column>
                    <Grid.Column width={4} verticalAlign="bottom">
                      <Button
                        content="Submit"
                        primary
                        onClick={this.getRoomAvailable}
                      />
                      <Button
                        icon="angle left"
                        primary
                        value={activePage - 1}
                        disabled={activePage === 1}
                        name="activePage"
                        onClick={this.changePage}
                      />
                      <Button
                        icon="angle right"
                        primary
                        value={activePage + 1}
                        disabled={pagedData.length === activePage}
                        name="activePage"
                        onClick={this.changePage}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={1}>
                    <Grid.Column>
                      <Divider style={{ background: '#dcdcdc' }} />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column
                      width={2}
                      textAlign="right"
                      style={{ top: '-9px' }}
                    >
                      <div style={{ margin: '5px 0px 5px 0px' }}>
                        <strong>Month</strong>
                      </div>
                      <div style={{ margin: '5px 0px 5px 0px' }}>
                        <strong>Day</strong>
                      </div>
                      <div style={{ margin: '2px 0px 2px 0px' }}>
                        <strong>Date</strong>
                      </div>
                    </Grid.Column>
                    <Grid.Column width={14}>
                      {/* <Grid style={{ width: '930px' }} columns={7}>
                  <Grid.Row style={{ textAlign: 'center' }}> */}
                      <HeaderDate dates={showDates} />
                      {/* </Grid.Row>
                </Grid> */}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    {/* , overflow: 'auto', maxHeight: 700 */}
                    <Grid
                      style={{
                        margin: '0px',
                        width: '100%',
                        overflow: 'auto',
                        maxHeight: '250px',
                      }}
                    >
                      <Loader
                        active={loadingGetStockRoomAllotment}
                        inverted={true}
                      />
                      {roomAllotments.length === 0 ? (
                        <div style={{ textAlign: 'center', width: '100%' }}>
                          No Data
                        </div>
                      ) : (
                        <Availability
                          roomAllotments={roomAllotments}
                          activePage={activePage}
                        />
                      )}
                    </Grid>
                  </Grid.Row>
                </Grid>
                <ModalAlert
                  openModal={modalAlertOpen}
                  handleClose={this.handleCloseAlert}
                  header={modalAlertHeader}
                  content={modalAlertMessage}
                  nameButton="OK"
                />
                <ModalCalendar
                  actionDateRange={this.handleDateRange}
                  openModal={modalCalendarOpen}
                  handleClose={this.handleModalCalendar}
                  contentHeader="Select Dates"
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
SegmentAccommodationAvailbility.propTypes = {
  id: PropTypes.number,
  getAccomodationListAction: PropTypes.func,
  listAccommodation: PropTypes.array,
  getListRoomAllotmentByProfileId: PropTypes.func,
  listRoom: PropTypes.array,
  loadingRoom: PropTypes.bool,
  loadingHotel: PropTypes.bool,
  loadingGetStockRoomAllotment: PropTypes.bool,
  listStockRoomAllotment: PropTypes.array,
  getStockRoomAllotment: PropTypes.func,
  getExportStockRoomAllotment: PropTypes.func,
  loadingExport: PropTypes.bool,
  exportRoomAllotment: PropTypes.string,
  isHome: PropTypes.bool,
  resetAccomodationAction: PropTypes.func,
};
function mapStateToProps(state) {
  const listAccommodation = state.AccomodationStore.getAccomodationList.map(e =>
    options(e.Id, e.Id, e.Name)
  );
  const listRoom = state.AccomodationStore.listRoomAllotment.map(e => {
    let promo = e.IsPromo ? ' (Promo Item)' : '';
    return options(e.Id, e.Id, e.Text + promo);
  });
  return {
    listAccommodation,
    loadingHotel: state.AccomodationStore.loadingGetAccommodationList,
    loadingRoom: state.AccomodationStore.loadingGetListRoomAllotmentByProfileId,
    listRoom,
    loadingGetStockRoomAllotment:
      state.AccomodationStore.loadingGetStockRoomAllotment,
    listStockRoomAllotment: state.AccomodationStore.listStockRoomAllotment,
    loadingExport: state.AccomodationStore.loadingGetExportStockRoomAllotment,
    exportRoomAllotment: state.AccomodationStore.exportRoomAllotment,
  };
}
export default connect(
  mapStateToProps,
  {
    getAccomodationListAction,
    getListRoomAllotmentByProfileId,
    getStockRoomAllotment,
    getExportStockRoomAllotment,
    resetAccomodationAction,
  }
)(SegmentAccommodationAvailbility);
