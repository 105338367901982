export const RESET_REGION_PAGE = 'RESET_REGION_PAGE';

// Delete Region By Country
export const DELETE_REGION = 'DELETE_REGION';
export const DELETE_REGION_FULFILLED = 'DELETE_REGION_FULFILLED';
export const DELETE_REGION_PENDING = 'DELETE_REGION_PENDING';
export const DELETE_REGION_REJECTED = 'DELETE_REGION_REJECTED';

//POST REGION
export const POST_REGION = 'POST_REGION';
export const POST_REGION_FULFILLED = 'POST_REGION_FULFILLED';
export const POST_REGION_PENDING = 'POST_REGION_PENDING';
export const POST_REGION_REJECTED = 'POST_REGION_REJECTED';

//PUT REGION
export const PUT_REGION = 'PUT_REGION';
export const PUT_REGION_FULFILLED = 'PUT_REGION_FULFILLED';
export const PUT_REGION_PENDING = 'PUT_REGION_PENDING';
export const PUT_REGION_REJECTED = 'PUT_REGION_REJECTED';

export const GET_REGION_BY_ID = 'GET_REGION_BY_ID';
export const GET_REGION_BY_ID_FULFILLED = 'GET_REGION_BY_ID_FULFILLED';
export const GET_REGION_BY_ID_PENDING = 'GET_REGION_BY_ID_PENDING';
export const GET_REGION_BY_ID_REJECTED = 'GET_REGION_BY_ID_REJECTED';
