import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import { StyleModal } from '../../../../assets/styles/size';
import { getCancellationReasonByTypeReferenceApi } from '../../../../api/uploadMasterDataReferenceApi/getMasterDataReferenceApi';
import ModalCancelBooking from './ModalCancelBooking';
import ModalListCancellationReason from './ModalListCancellationReason';

const ModalCancellationReason = props => {
  //==============initial=============
  const {
    openModal,
    handleClose,
    handleSave,
    loadingPostCancel,
    CancellationReasonType,
  } = props;
  const [
    openModalCancellationReasonList,
    setOpenModalCancellationReasonList,
  ] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [dataCancellationReason, setDataCancellationReason] = useState('');

  const openModalCancellationReason = () => {
    setOpenModalCancellationReasonList(true);
    setLoadingModal(true);
    getCancellationReasonByTypeReferenceApi(
      CancellationReasonType ? CancellationReasonType : 'Travel_agent'
    )
      .then(result => {
        setDataCancellationReason(result.data);
        setLoadingModal(false);
      })
      .catch(() => {
        //eslint-disable-next-line
        setLoadingModal(false);
      });
  };

  const handleCloseModal = () => {
    setOpenModalCancellationReasonList(false);
  };

  const handleConfirm = data => {
    handleSave(data);
  };

  useEffect(() => {
    if (!openModal) {
      setOpenModalCancellationReasonList(false);
    }
  }, [openModal]);

  return (
    <Modal
      dimmer="blurring"
      open={openModal}
      onClose={handleClose}
      size="small"
      style={StyleModal.modalWidthCancel}
    >
      <ModalCancelBooking
        open={openModalCancellationReason}
        close={handleClose}
        CancellationReasonType={CancellationReasonType}
      />
      <ModalListCancellationReason
        open={openModalCancellationReasonList}
        close={handleCloseModal}
        data={dataCancellationReason}
        handleConfirm={handleConfirm}
        loadingModal={loadingModal}
        loadingPostCancel={loadingPostCancel}
      />
    </Modal>
  );
};
ModalCancellationReason.propTypes = {
  openModal: PropTypes.bool,
  loadingPostCancel: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  CancellationReasonType: PropTypes.string,
};
export default ModalCancellationReason;
