import React from 'react';
import { Table, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ButtonActionDetailList from '../../../shared/components/common/button/ActionButtonList';
import { Link } from 'react-router-dom';

const TableItem = props => {
  let {
    header,
    listData,
    IdProfile,
    handleClickDelete,
    handleClickEdit,
    disablePage,
    handleChooseItemForRBL,
    useInResourceBookingList,
  } = props;
  return (
    <Table basic="very" selectable fixed>
      <Table.Header>
        <Table.Row>
          {header.map((headTableContent, index) => {
            return (
              <Table.HeaderCell key={index}>
                {headTableContent}
              </Table.HeaderCell>
            );
          })}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {listData &&
          listData.map((restaurantItem, idx) => {
            return (
              <Table.Row key={idx}>
                <Table.Cell component="th" scope="row" width={1}>
                  <Link
                    to={`/Restaurant-Item/Detail/${IdProfile}/${restaurantItem.ServiceItemId}`}
                  >
                    {restaurantItem.Name}
                  </Link>
                </Table.Cell>
                <Table.Cell>{restaurantItem.Description}</Table.Cell>
                <Table.Cell> {restaurantItem.TypeOfMenu}</Table.Cell>
                <Table.Cell>{restaurantItem.MenuClass}</Table.Cell>
                <Table.Cell> {restaurantItem.Category}</Table.Cell>
                {useInResourceBookingList ? (
                  <Table.Cell>
                    {restaurantItem.EstimatedTotalPrice.CurrencyId}
                    {restaurantItem.EstimatedTotalPrice.Price.toLocaleString(
                      'id'
                    )}
                  </Table.Cell>
                ) : null}
                <Table.Cell>
                  {useInResourceBookingList ? (
                    <Button
                      content="Choose"
                      primary
                      value={restaurantItem}
                      onClick={handleChooseItemForRBL}
                    />
                  ) : (
                    <ButtonActionDetailList
                      id={restaurantItem.ServiceItemId}
                      handleClickDelete={handleClickDelete}
                      handleClickEdit={handleClickEdit}
                      content1="Edit"
                      content2="Delete"
                      name1={restaurantItem.ServiceItemId}
                      name2={restaurantItem.Name}
                      disablePage={disablePage}
                    />
                  )}
                </Table.Cell>
              </Table.Row>
            );
          })}
      </Table.Body>
    </Table>
  );
};
TableItem.propTypes = {
  header: PropTypes.array,
  listData: PropTypes.array,
  handleClickEdit: PropTypes.func,
  handleClickDelete: PropTypes.func,
  IdProfile: PropTypes.number,
  disablePage: PropTypes.bool,
  useInResourceBookingList: PropTypes.bool,
  handleChooseItemForRBL: PropTypes.func,
};
export default TableItem;
