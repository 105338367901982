import * as types from './constant/actionTypes';

const initialState = {
  loading: false,
  getPlaceList: [],
  getPlaceListStatus: '',
  deletePlaceStatus: '',
  putPlaceStatus: '',
  postPlaceStatus: '',
  getPlaceById: [],
  getPlaceByIdStatus: '',
  getPlaceType: [],
  errors: {},
  placeCities: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PLACE_LIST:
      return { ...state };
    case types.GET_PLACE_LIST_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getPlaceListStatus: 'failed',
      };
    case types.GET_PLACE_LIST_PENDING:
      return { ...state, loading: true };
    case types.GET_PLACE_LIST_FULFILLED:
      return {
        ...state,
        getPlaceList: action.payload.data,
        loading: false,
        getPlaceListStatus: 'success',
      };
    case types.RESET_PLACE:
      return {
        ...state,
        getPlaceListStatus: '',
        deletePlaceStatus: '',
        getPlaceByIdStatus: '',
        putPlaceStatus: '',
        postPlaceStatus: '',
      };
    case types.DELETE_PLACE:
      return { ...state };
    case types.DELETE_PLACE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        deletePlaceStatus: 'failed',
      };
    case types.DELETE_PLACE_PENDING:
      return { ...state, loading: true };
    case types.DELETE_PLACE_FULFILLED:
      return {
        ...state,
        loading: false,
        deletePlaceStatus: 'success',
      };
    case types.GET_PLACE_BY_ID:
      return { ...state };
    case types.GET_PLACE_BY_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getPlaceByIdStatus: 'failed',
      };
    case types.GET_PLACE_BY_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_PLACE_BY_ID_FULFILLED:
      return {
        ...state,
        getPlaceById: action.payload.data,
        loading: false,
        getPlaceByIdStatus: 'success',
      };
    case types.POST_PLACE:
      return { ...state };
    case types.POST_PLACE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        postPlaceStatus: 'failed',
      };
    case types.POST_PLACE_PENDING:
      return { ...state, loading: true };
    case types.POST_PLACE_FULFILLED:
      return {
        ...state,
        loading: false,
        postPlaceStatus: 'success',
      };
    case types.PUT_PLACE:
      return { ...state };
    case types.PUT_PLACE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        putPlaceStatus: 'failed',
      };
    case types.PUT_PLACE_PENDING:
      return { ...state, loading: true };
    case types.PUT_PLACE_FULFILLED:
      return {
        ...state,
        loading: false,
        putPlaceStatus: 'success',
      };
    case types.GET_PLACE_TYPE:
      return { ...state };
    case types.GET_PLACE_TYPE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
      };
    case types.GET_PLACE_TYPE_PENDING:
      return { ...state, loading: true };
    case types.GET_PLACE_TYPE_FULFILLED:
      return {
        ...state,
        getPlaceType: action.payload.data,
        loading: false,
      };
    case types.GET_PLACE_CITIES_BY_PLACE_ID:
      return { ...state };
    case types.GET_PLACE_CITIES_BY_PLACE_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        placeCities: [],
      };
    case types.GET_PLACE_CITIES_BY_PLACE_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_PLACE_CITIES_BY_PLACE_ID_FULFILLED:
      return {
        ...state,
        placeCities: action.payload.data,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
