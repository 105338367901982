import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  postMasterDataPreviewAccomodationProfileAction,
  postMasterDataCommitAccomodationProfileAction,
  postMasterDataPreviewExcursionObjectAction,
  postMasterDataCommitExcursionObjectAction,
  postMasterDataPreviewRestaurantProfileAction,
  postMasterDataCommitRestaurantProfileAction,
  postMasterDataPreviewTransportationProfileAction,
  postMasterDataCommitTransportationProfileAction,
  postMasterDataPreviewAccomodationItemAction,
  postMasterDataCommitAccomodationItemAction,
  postMasterDataPreviewRestaurantItemAction,
  postMasterDataCommitRestaurantItemAction,
  postMasterDataPreviewTransportationUnitAction,
  postMasterDataCommitTransportationUnitAction,
  postMasterDataPreviewRestaurantPriceAction,
  postMasterDataCommitRestaurantPriceAction,
  postMasterDataPreviewExcursionPriceAction,
  postMasterDataCommitExcursionPriceAction,
  resetUploadDataMasterAction,
} from './uploadMasterDataAction';
import ModalAlert from '../../components/modal/modalAlert';
import MasterData from './components/UploadMasterDataForm';
import {
  resetAccomodationAction,
  getTemplateAccomodationProfileAction,
  getTemplateAccomodationItemAction,
} from '../../pages/shared/actions/accommodationAction';
// } from '../../actions/accomodationAction';
import {
  resetExcursionAction,
  getTemplateExcursionObjectAction,
  getTemplateExcursionPriceAction,
} from '../shared/actions/excursionAction';
import {
  resetTransportationAction,
  getTemplateTransportationProfileAction,
  getTemplateTransportationUnitAction,
} from '../shared/actions/transportationAction';
import {
  resetRestaurantAction,
  getTemplateRestaurantProfileAction,
  getTemplateRestaurantItemAction,
} from '../../actions/restaurantAction';
import Loader from '../../components/loader';

class UploadDataMasterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      listUpload: [],
      typeUpload: '',
      disabledSave: true,
      disabledUpload: true,
      modalAlert: false,
      headerAlert: '',
      contentAlert: '',
      loadingButtonUpload: false,
      isArrayContentAlert: false,
    };
  }
  onChange = e => {
    this.setState({ file: e.target.files[0], disabledUpload: false });
  };
  handleClose = () => {
    window.location.reload();
    this.setState({ modalAlert: false });
  };
  fileUpload = file => {
    const data = new FormData();
    data.set('file', file, file.name);
    // data.append('file', file);
    // data.append('name', file.name);
    if (this.state.typeUpload === 'Accomodation') {
      this.props.postMasterDataPreviewAccomodationProfileAction(data);
    } else if (this.state.typeUpload === 'Transportation') {
      this.props.postMasterDataPreviewTransportationProfileAction(data);
    } else if (this.state.typeUpload === 'Restaurant') {
      this.props.postMasterDataPreviewRestaurantProfileAction(data);
    } else if (this.state.typeUpload === 'Excursion') {
      this.props.postMasterDataPreviewExcursionObjectAction(data);
    } else if (this.state.typeUpload === 'AccomodationItem') {
      this.props.postMasterDataPreviewAccomodationItemAction(data);
    } else if (this.state.typeUpload === 'RestaurantItem') {
      this.props.postMasterDataPreviewRestaurantItemAction(data);
    } else if (this.state.typeUpload === 'TransportationUnit') {
      this.props.postMasterDataPreviewTransportationUnitAction(data);
    } else if (this.state.typeUpload === 'RestaurantPrice') {
      this.props.postMasterDataPreviewRestaurantPriceAction(data);
    } else if (this.state.typeUpload === 'ExcursionPrice') {
      this.props.postMasterDataPreviewExcursionPriceAction(data);
    }
  };
  static propTypes = {
    resetUploadDataMasterAction: PropTypes.func,
    listUploadMasterDataPreviewAccomodationProfile: PropTypes.array,
    listUploadMasterDataPreviewAccomodationProfileStatus: PropTypes.string,
    postMasterDataCommitAccomodationProfileAction: PropTypes.func,
    postMasterDataPreviewAccomodationProfileAction: PropTypes.func,
    postMasterDataPreviewExcursionObjectAction: PropTypes.func,
    postMasterDataCommitExcursionObjectAction: PropTypes.func,
    postMasterDataPreviewRestaurantProfileAction: PropTypes.func,
    postMasterDataCommitRestaurantProfileAction: PropTypes.func,
    postMasterDataPreviewTransportationProfileAction: PropTypes.func,
    postMasterDataCommitTransportationProfileAction: PropTypes.func,
    postMasterDataPreviewAccomodationItemAction: PropTypes.func,
    postMasterDataCommitAccomodationItemAction: PropTypes.func,
    postMasterDataPreviewRestaurantItemAction: PropTypes.func,
    postMasterDataCommitRestaurantItemAction: PropTypes.func,
    postMasterDataPreviewTransportationUnitAction: PropTypes.func,
    postMasterDataCommitTransportationUnitAction: PropTypes.func,
    listUploadMasterDataPreviewExcursionObject: PropTypes.array,
    listUploadMasterDataPreviewExcursionObjectStatus: PropTypes.string,
    listUploadMasterDataPreviewTransportationProfile: PropTypes.array,
    listUploadMasterDataPreviewTransportationProfileStatus: PropTypes.string,
    listUploadMasterDataPreviewRestaurantProfile: PropTypes.array,
    listUploadMasterDataPreviewRestaurantProfileStatus: PropTypes.string,
    listUploadMasterDataPreviewAccomodationItem: PropTypes.array,
    listUploadMasterDataPreviewAccomodationItemStatus: PropTypes.string,
    listUploadMasterDataPreviewTransportationUnit: PropTypes.array,
    listUploadMasterDataPreviewTransportationUnitStatus: PropTypes.string,
    listUploadMasterDataPreviewRestaurantItem: PropTypes.array,
    listUploadMasterDataPreviewRestaurantItemStatus: PropTypes.string,
    postMasterDataPreviewRestaurantPriceAction: PropTypes.func,
    postMasterDataCommitRestaurantPriceAction: PropTypes.func,
    postMasterDataPreviewExcursionPriceAction: PropTypes.func,
    postMasterDataCommitExcursionPriceAction: PropTypes.func,
    listUploadMasterDataPreviewExcursionPrice: PropTypes.array,
    listUploadMasterDataPreviewExcursionPriceStatus: PropTypes.string,
    listUploadMasterDataPreviewRestaurantPrice: PropTypes.array,
    listUploadMasterDataPreviewRestaurantPriceStatus: PropTypes.string,
    errorsMessage: PropTypes.string,
    postUploadCommitStatus: PropTypes.string,
    postUploadCommit: PropTypes.object,
    loadingUploadMasterDataCommit: PropTypes.bool,
    getTemplateAccomodationProfileAction: PropTypes.func,
    getTemplateTransportationProfileAction: PropTypes.func,
    getTemplateRestaurantProfileAction: PropTypes.func,
    getTemplateExcursionObjectAction: PropTypes.func,
    getTemplateAccomodationItemAction: PropTypes.func,
    getTemplateRestaurantItemAction: PropTypes.func,
    getTemplateTransportationUnitAction: PropTypes.func,
    getAccomodationTemplateStatus: PropTypes.string,
    resultDownloadAccomodation: PropTypes.string,
    resetAccomodationAction: PropTypes.func,
    getRestaurantProfileTemplateStatus: PropTypes.string,
    getRestaurantProfileTemplate: PropTypes.string,
    resetRestaurantAction: PropTypes.func,
    getExursionObjectTemplateStatus: PropTypes.string,
    getExursionObjectTemplate: PropTypes.string,
    getTransportationProfileTemplateStatus: PropTypes.string,
    getTransportationProfileTemplate: PropTypes.string,
    resetTransportationAction: PropTypes.func,
    resetExcursionAction: PropTypes.func,
    getAccomodationItemTemplateStatus: PropTypes.string,
    getAccomodationItemTemplate: PropTypes.string,
    getRestaurantItemTemplateStatus: PropTypes.string,
    getRestaurantItemTemplate: PropTypes.string,
    getTransportationUnitTemplateStatus: PropTypes.string,
    getTransportationUnitTemplate: PropTypes.string,
    getTemplateExcursionPriceAction: PropTypes.func,
    getExursionPriceTemplate: PropTypes.string,
    getExursionPriceTemplateStatus: PropTypes.string,
    numbersFrom: PropTypes.number,
    numbersTo: PropTypes.number,
  };
  componentDidMount = () => {
    this.getTypeUpload();
  };
  getTypeUpload = () => {
    // eslint-disable-next-line
    let type = this.props.match.params.Type;
    this.setState({
      typeUpload: type,
    });
  };
  componentDidUpdate() {
    if (
      this.props.listUploadMasterDataPreviewAccomodationProfileStatus ===
      'success'
    ) {
      this.setState({
        listUpload: this.props.listUploadMasterDataPreviewAccomodationProfile,
        loadingButtonUpload: false,
      });

      this.props.resetUploadDataMasterAction();
    } else if (
      this.props.listUploadMasterDataPreviewAccomodationProfileStatus ===
      'failed'
    ) {
      this.setState({
        modalAlert: true,
        headerAlert: 'Failed Upload Master Data',
        contentAlert: this.props.errorsMessage,
        disabledSave: true,
        disabledUpload: true,
        loadingButtonUpload: false,
      });
      this.props.resetUploadDataMasterAction();
    }

    if (
      this.props.listUploadMasterDataPreviewExcursionObjectStatus === 'success'
    ) {
      this.setState({
        listUpload: this.props.listUploadMasterDataPreviewExcursionObject,
        loadingButtonUpload: false,
      });
      this.props.resetUploadDataMasterAction();
    } else if (
      this.props.listUploadMasterDataPreviewExcursionObjectStatus === 'failed'
    ) {
      this.setState({
        modalAlert: true,
        headerAlert: 'Failed Upload Master Data',
        contentAlert:
          this.props.errorsMessage || 'Failed Upload Data Excursion',
        disabledSave: true,
        disabledUpload: true,
        loadingButtonUpload: false,
      });
      this.props.resetUploadDataMasterAction();
    }

    if (
      this.props.listUploadMasterDataPreviewTransportationProfileStatus ===
      'success'
    ) {
      this.setState({
        listUpload: this.props.listUploadMasterDataPreviewTransportationProfile,
        loadingButtonUpload: false,
      });

      this.props.resetUploadDataMasterAction();
    } else if (
      this.props.listUploadMasterDataPreviewTransportationProfileStatus ===
      'failed'
    ) {
      this.setState({
        modalAlert: true,
        headerAlert: 'Failed Upload Master Data',
        contentAlert: this.props.errorsMessage,
        disabledSave: true,
        disabledUpload: true,
        loadingButtonUpload: false,
      });
      this.props.resetUploadDataMasterAction();
    }

    if (
      this.props.listUploadMasterDataPreviewRestaurantProfileStatus ===
      'success'
    ) {
      this.setState({
        listUpload: this.props.listUploadMasterDataPreviewRestaurantProfile,
        loadingButtonUpload: false,
      });
      this.props.resetUploadDataMasterAction();
    } else if (
      this.props.listUploadMasterDataPreviewRestaurantProfileStatus === 'failed'
    ) {
      this.setState({
        modalAlert: true,
        headerAlert: 'Failed Upload Master Data',
        contentAlert: this.props.errorsMessage,
        disabledSave: true,
        disabledUpload: true,
        loadingButtonUpload: false,
      });
      this.props.resetUploadDataMasterAction();
    }

    if (
      this.props.listUploadMasterDataPreviewAccomodationItemStatus === 'success'
    ) {
      this.setState({
        listUpload: this.props.listUploadMasterDataPreviewAccomodationItem,
        loadingButtonUpload: false,
      });
      this.props.resetUploadDataMasterAction();
    } else if (
      this.props.listUploadMasterDataPreviewAccomodationItemStatus === 'failed'
    ) {
      this.setState({
        modalAlert: true,
        headerAlert: 'Failed Upload Master Data',
        contentAlert: this.props.errorsMessage,
        disabledSave: true,
        disabledUpload: true,
        loadingButtonUpload: false,
      });
      this.props.resetUploadDataMasterAction();
    }
    if (
      this.props.listUploadMasterDataPreviewRestaurantItemStatus === 'success'
    ) {
      this.setState({
        listUpload: this.props.listUploadMasterDataPreviewRestaurantItem,
        loadingButtonUpload: false,
      });
      this.props.resetUploadDataMasterAction();
    } else if (
      this.props.listUploadMasterDataPreviewRestaurantItemStatus === 'failed'
    ) {
      this.setState({
        modalAlert: true,
        headerAlert: 'Failed Upload Master Data',
        contentAlert: this.props.errorsMessage,
        disabledSave: true,
        disabledUpload: true,
        loadingButtonUpload: false,
      });
      this.props.resetUploadDataMasterAction();
    }

    if (
      this.props.listUploadMasterDataPreviewTransportationUnitStatus ===
      'success'
    ) {
      this.setState({
        listUpload: this.props.listUploadMasterDataPreviewTransportationUnit,
        loadingButtonUpload: false,
      });
      this.props.resetUploadDataMasterAction();
    } else if (
      this.props.listUploadMasterDataPreviewTransportationUnitStatus ===
      'failed'
    ) {
      this.setState({
        modalAlert: true,
        headerAlert: 'Failed Upload Master Data',
        contentAlert: this.props.errorsMessage,
        disabledSave: true,
        disabledUpload: true,
        loadingButtonUpload: false,
      });
      this.props.resetUploadDataMasterAction();
    }

    if (
      this.props.listUploadMasterDataPreviewRestaurantPriceStatus === 'success'
    ) {
      this.setState({
        listUpload: this.props.listUploadMasterDataPreviewRestaurantPrice,
        loadingButtonUpload: false,
      });
      this.props.resetUploadDataMasterAction();
    } else if (
      this.props.listUploadMasterDataPreviewRestaurantPriceStatus === 'failed'
    ) {
      this.setState({
        modalAlert: true,
        headerAlert: 'Failed Upload Master Data',
        contentAlert: this.props.errorsMessage,
        disabledSave: true,
        disabledUpload: true,
        loadingButtonUpload: false,
      });
      this.props.resetUploadDataMasterAction();
    }

    if (
      this.props.listUploadMasterDataPreviewExcursionPriceStatus === 'success'
    ) {
      this.setState({
        listUpload: this.props.listUploadMasterDataPreviewExcursionPrice,
        loadingButtonUpload: false,
      });
      this.props.resetUploadDataMasterAction();
    } else if (
      this.props.listUploadMasterDataPreviewExcursionPriceStatus === 'failed'
    ) {
      this.setState({
        modalAlert: true,
        headerAlert: 'Failed Upload Master Data',
        contentAlert: this.props.errorsMessage,
        disabledSave: true,
        disabledUpload: true,
        loadingButtonUpload: false,
      });
      this.props.resetUploadDataMasterAction();
    }
    if (this.props.postUploadCommitStatus === 'success') {
      let { postUploadCommit } = this.props;
      let contentAlert = [];
      postUploadCommit.map(e => {
        return (
          e.IsSuccess === false &&
          contentAlert.push(`Line ${e.Line} ${e.ErrorMessage}`)
        );
      });
      let headerAlert = contentAlert.length > 0 ? 'Failed' : 'Success';
      contentAlert =
        contentAlert.length > 0 ? contentAlert : 'Success Save Master Data';
      this.setState({
        modalAlert: true,
        headerAlert,
        contentAlert,
        isArrayContentAlert: true,
      });
      this.props.resetUploadDataMasterAction();
    } else if (this.props.postUploadCommitStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorsMessage,
        modalAlert: true,
      });
      this.props.resetUploadDataMasterAction();
    }
  }
  submit = () => {
    this.fileUpload(this.state.file);
    this.setState({
      disabledSave: false,
      loadingButtonUpload: true,
    });
  };
  saveUpload = () => {
    if (this.state.typeUpload === 'Accomodation') {
      this.props.postMasterDataCommitAccomodationProfileAction(
        this.state.listUpload
      );
    } else if (this.state.typeUpload === 'Transportation') {
      this.props.postMasterDataCommitTransportationProfileAction(
        this.state.listUpload
      );
    } else if (this.state.typeUpload === 'Restaurant') {
      this.props.postMasterDataCommitRestaurantProfileAction(
        this.state.listUpload
      );
    } else if (this.state.typeUpload === 'Excursion') {
      this.props.postMasterDataCommitExcursionObjectAction(
        this.state.listUpload
      );
    } else if (this.state.typeUpload === 'AccomodationItem') {
      this.props.postMasterDataCommitAccomodationItemAction(
        this.state.listUpload
      );
    } else if (this.state.typeUpload === 'RestaurantItem') {
      this.props.postMasterDataCommitRestaurantItemAction(
        this.state.listUpload
      );
    } else if (this.state.typeUpload === 'TransportationUnit') {
      this.props.postMasterDataCommitTransportationUnitAction(
        this.state.listUpload
      );
    } else if (this.state.typeUpload === 'RestaurantPrice') {
      this.props.postMasterDataCommitRestaurantPriceAction(
        this.state.listUpload
      );
    } else if (this.state.typeUpload === 'ExcursionPrice') {
      this.props.postMasterDataCommitExcursionPriceAction(
        this.state.listUpload
      );
    }
  };
  handleDownload = (data, name) => {
    var link = document.createElement('a');
    var template = new Blob([data], {
      type: 'text/plain',
    });
    link.href = URL.createObjectURL(template);
    link.download = name;
    link.click();
  };
  downloadTemplate = () => {
    const { typeUpload } = this.state;
    // const context = this;
    this.setState({ loadingUploadImage: true });
    switch (typeUpload) {
      case 'Accomodation':
        this.downloadTemplateAction(
          this.props.getTemplateAccomodationProfileAction,
          'Accomodation Template.txt'
        );
        break;
      case 'Transportation':
        this.downloadTemplateAction(
          this.props.getTemplateTransportationProfileAction,
          'Transportation Template.txt'
        );
        break;
      case 'Restaurant':
        this.downloadTemplateAction(
          this.props.getTemplateRestaurantProfileAction,
          'Restaurant Template.txt'
        );
        break;
      case 'Excursion':
        this.downloadTemplateAction(
          this.props.getTemplateExcursionObjectAction,
          'Excursion Template.txt'
        );
        break;
      case 'AccomodationItem':
        this.downloadTemplateAction(
          this.props.getTemplateAccomodationItemAction,
          'Accomodation Item Template.txt'
        );
        break;
      case 'RestaurantItem':
        this.downloadTemplateAction(
          this.props.getTemplateRestaurantItemAction,
          'Restaurant Item Template.txt'
        );
        break;
      case 'TransportationUnit':
        this.downloadTemplateAction(
          this.props.getTemplateTransportationUnitAction,
          'Transportation Unit Template.txt'
        );
        break;
      case 'ExcursionPrice':
        this.downloadTemplateAction(
          this.props.getTemplateExcursionPriceAction,
          'Excursion Price Template.txt'
        );
        break;
      default:
        this.setState({ loadingUploadImage: true });
    }
  };
  //region download template
  downloadTemplateAction = (func, name) => {
    const context = this;
    func()
      .then(res => {
        context.handleDownload(res.value.data, name);
        context.setState({ ...context.state, loadingUploadImage: false });
      })
      .catch(() => {
        context.setState({ loadingUploadImage: false });
      });
  };

  render() {
    const { loadingUploadImage } = this.state;
    return (
      <div>
        <Loader loading={loadingUploadImage} />
        <MasterData
          handleChange={this.onChange}
          disabledSave={this.state.disabledSave}
          disabledUpload={this.state.disabledUpload}
          saveUpload={this.saveUpload}
          submit={this.submit}
          listUpload={this.state.listUpload}
          type={this.state.typeUpload}
          loadingButtonUpload={this.state.loadingButtonUpload}
          loadingButtonSave={this.props.loadingUploadMasterDataCommit}
          downloadTemplate={this.downloadTemplate}
          numbersTo={this.props.numbersTo}
          numbersFrom={this.props.numbersFrom}
        />
        <ModalAlert
          openModal={this.state.modalAlert}
          handleClose={this.handleClose}
          header={this.state.headerAlert}
          content={this.state.contentAlert}
          nameButton="OK"
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    listUploadMasterDataPreviewAccomodationProfile:
      state.UploadMasterDataStore
        .listUploadMasterDataPreviewAccomodationProfile,
    listUploadMasterDataPreviewAccomodationProfileStatus:
      state.UploadMasterDataStore
        .listUploadMasterDataPreviewAccomodationProfileStatus,
    listUploadMasterDataPreviewExcursionObject:
      state.UploadMasterDataStore.listUploadMasterDataPreviewExcursionObject,
    listUploadMasterDataPreviewExcursionObjectStatus:
      state.UploadMasterDataStore
        .listUploadMasterDataPreviewExcursionObjectStatus,
    listUploadMasterDataPreviewTransportationProfile:
      state.UploadMasterDataStore
        .listUploadMasterDataPreviewTransportationProfile,
    listUploadMasterDataPreviewTransportationProfileStatus:
      state.UploadMasterDataStore
        .listUploadMasterDataPreviewTransportationProfileStatus,
    listUploadMasterDataPreviewRestaurantProfile:
      state.UploadMasterDataStore.listUploadMasterDataPreviewRestaurantProfile,
    listUploadMasterDataPreviewRestaurantProfileStatus:
      state.UploadMasterDataStore
        .listUploadMasterDataPreviewRestaurantProfileStatus,
    listUploadMasterDataPreviewAccomodationItem:
      state.UploadMasterDataStore.listUploadMasterDataPreviewAccomodationItem,
    listUploadMasterDataPreviewAccomodationItemStatus:
      state.UploadMasterDataStore
        .listUploadMasterDataPreviewAccomodationItemStatus,
    listUploadMasterDataPreviewTransportationUnit:
      state.UploadMasterDataStore.listUploadMasterDataPreviewTransportationUnit,
    listUploadMasterDataPreviewTransportationUnitStatus:
      state.UploadMasterDataStore
        .listUploadMasterDataPreviewTransportationUnitStatus,
    listUploadMasterDataPreviewRestaurantItem:
      state.UploadMasterDataStore.listUploadMasterDataPreviewRestaurantItem,
    listUploadMasterDataPreviewRestaurantItemStatus:
      state.UploadMasterDataStore
        .listUploadMasterDataPreviewRestaurantItemStatus,
    listUploadMasterDataPreviewExcursionPrice:
      state.UploadMasterDataStore.listUploadMasterDataPreviewExcursionPrice,
    listUploadMasterDataPreviewExcursionPriceStatus:
      state.UploadMasterDataStore
        .listUploadMasterDataPreviewExcursionPriceStatus,
    listUploadMasterDataPreviewRestaurantPrice:
      state.UploadMasterDataStore.listUploadMasterDataPreviewRestaurantPrice,
    listUploadMasterDataPreviewRestaurantPriceStatus:
      state.UploadMasterDataStore
        .listUploadMasterDataPreviewRestaurantPriceStatus,
    errorsMessage: state.UploadMasterDataStore.errors.global,
    postUploadCommitStatus: state.UploadMasterDataStore.postUploadCommitStatus,
    postUploadCommit: state.UploadMasterDataStore.postUploadCommit,
    loadingUploadMasterDataCommit:
      state.UploadMasterDataStore.loadingUploadMasterDataCommit,
    getAccomodationTemplateStatus:
      state.AccomodationStore.getAccomodationTemplateStatus,
    resultDownloadAccomodation: state.AccomodationStore.getAccomodationTemplate,
    getRestaurantProfileTemplate:
      state.RestaurantStore.getRestaurantProfileTemplate,
    getRestaurantProfileTemplateStatus:
      state.RestaurantStore.getRestaurantProfileTemplateStatus,
    getExursionObjectTemplate: state.ExcursionStore.getExursionObjectTemplate,
    getExursionObjectTemplateStatus:
      state.ExcursionStore.getExursionObjectTemplateStatus,
    getTransportationProfileTemplate:
      state.TransportationStore.getTransportationProfileTemplate,
    getTransportationProfileTemplateStatus:
      state.TransportationStore.getTransportationProfileTemplateStatus,
    getAccomodationItemTemplate:
      state.AccomodationStore.getAccomodationItemTemplate,
    getAccomodationItemTemplateStatus:
      state.AccomodationStore.getAccomodationItemTemplateStatus,
    getRestaurantItemTemplate: state.RestaurantStore.getRestaurantItemTemplate,
    getRestaurantItemTemplateStatus:
      state.RestaurantStore.getRestaurantItemTemplateStatus,
    getTransportationUnitTemplate:
      state.TransportationStore.getTransportationUnitTemplate,
    getTransportationUnitTemplateStatus:
      state.TransportationStore.getTransportationUnitTemplateStatus,
    getExursionPriceTemplate: state.ExcursionStore.getExursionPriceTemplate,
    getExursionPriceTemplateStatus:
      state.ExcursionStore.getExursionPriceTemplateStatus,
  };
}
export default connect(mapStateToProps, {
  postMasterDataPreviewAccomodationProfileAction,
  postMasterDataCommitAccomodationProfileAction,
  postMasterDataPreviewExcursionObjectAction,
  postMasterDataCommitExcursionObjectAction,
  postMasterDataPreviewRestaurantProfileAction,
  postMasterDataCommitRestaurantProfileAction,
  postMasterDataPreviewTransportationProfileAction,
  postMasterDataCommitTransportationProfileAction,
  postMasterDataPreviewAccomodationItemAction,
  postMasterDataCommitAccomodationItemAction,
  postMasterDataPreviewRestaurantItemAction,
  postMasterDataCommitRestaurantItemAction,
  postMasterDataPreviewTransportationUnitAction,
  postMasterDataCommitTransportationUnitAction,
  postMasterDataPreviewRestaurantPriceAction,
  postMasterDataCommitRestaurantPriceAction,
  postMasterDataPreviewExcursionPriceAction,
  postMasterDataCommitExcursionPriceAction,
  resetUploadDataMasterAction,
  resetAccomodationAction,
  getTemplateAccomodationProfileAction,
  getTemplateAccomodationItemAction,
  resetExcursionAction,
  getTemplateExcursionObjectAction,
  resetTransportationAction,
  getTemplateTransportationProfileAction,
  getTemplateTransportationUnitAction,
  resetRestaurantAction,
  getTemplateRestaurantProfileAction,
  getTemplateRestaurantItemAction,
  getTemplateExcursionPriceAction,
})(UploadDataMasterPage);
