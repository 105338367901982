import apiClient from './apiClient';
import {
  GET_GROUP_TYPE_LIST_URL,
  POST_EMAIL_SEND_CONFIRMATION_URL,
  GET_TRANSACTION_HISTORY_BY_OPERATION_URL,
  GET_TRANSACTION_HISTORY_BY_TRANSACTION_URL,
} from './apiUrl';

const getGroupTypeListApi = () => {
  return apiClient.get(GET_GROUP_TYPE_LIST_URL);
};

const postEmailSendConfirmationApi = data => {
  return apiClient.post(
    `${POST_EMAIL_SEND_CONFIRMATION_URL}?tourTransactionCode=${data.tourTransactionId}&emailSendConfirmed=${data.emailSendConfirmed}`
  );
};
const getTransactionHistoryByOperationApi = (
  start,
  end,
  createStart,
  createEnd,
  status
) => {
  return apiClient.get(
    `${GET_TRANSACTION_HISTORY_BY_OPERATION_URL}?tourStartDate=${start}&tourUntilDate=${end}&createdStartDate=${createStart}&createdUntilDate=${createEnd}&status=${status}`
  );
};
const getTransactionHistoryByTransactionApi = id => {
  return apiClient.get(
    `${GET_TRANSACTION_HISTORY_BY_TRANSACTION_URL}?transactionId=${id}`
  );
};
export {
  getGroupTypeListApi,
  postEmailSendConfirmationApi,
  getTransactionHistoryByOperationApi,
  getTransactionHistoryByTransactionApi,
};
