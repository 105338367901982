//System Margin
const GET_SYSTEM_MARGIN_LIST = 'GET_SYSTEM_MARGIN_LIST';
const GET_SYSTEM_MARGIN_LIST_FULFILLED = 'GET_SYSTEM_MARGIN_LIST_FULFILLED';
const GET_SYSTEM_MARGIN_LIST_PENDING = 'GET_SYSTEM_MARGIN_LIST_PENDING';
const GET_SYSTEM_MARGIN_LIST_REJECTED = 'GET_SYSTEM_MARGIN_LIST_REJECTED';
const RESET_STATUS_SYSTEM_MARGIN = 'RESET_STATUS_SYSTEM_MARGIN';

//Get System Margin By Id
const GET_SYSTEM_MARGIN_BY_ID = 'GET_SYSTEM_MARGIN_BY_ID';
const GET_SYSTEM_MARGIN_BY_ID_FULFILLED = 'GET_SYSTEM_MARGIN_BY_ID_FULFILLED';
const GET_SYSTEM_MARGIN_BY_ID_PENDING = 'GET_SYSTEM_MARGIN_BY_ID_PENDING';
const GET_SYSTEM_MARGIN_BY_ID_REJECTED = 'GET_SYSTEM_MARGIN_BY_ID_REJECTED';

//Get System Margin By Id
const GET_SYSTEM_MARGIN_BY_PROFILE_ID = 'GET_SYSTEM_MARGIN_BY_PROFILE_ID';
const GET_SYSTEM_MARGIN_BY_PROFILE_ID_FULFILLED =
  'GET_SYSTEM_MARGIN_BY_PROFILE_ID_FULFILLED';
const GET_SYSTEM_MARGIN_BY_PROFILE_ID_PENDING =
  'GET_SYSTEM_MARGIN_BY_PROFILE_ID_PENDING';
const GET_SYSTEM_MARGIN_BY_PROFILE_ID_REJECTED =
  'GET_SYSTEM_MARGIN_BY_PROFILE_ID_REJECTED';

const DELETE_TOUR_OPERATOR_MARGIN = 'DELETE_TOUR_OPERATOR_MARGIN';
const DELETE_TOUR_OPERATOR_MARGIN_FULFILLED =
  'DELETE_TOUR_OPERATOR_MARGIN_FULFILLED';
const DELETE_TOUR_OPERATOR_MARGIN_PENDING =
  'DELETE_TOUR_OPERATOR_MARGIN_PENDING';
const DELETE_TOUR_OPERATOR_MARGIN_REJECTED =
  'DELETE_TOUR_OPERATOR_MARGIN_REJECTED';

//POST SYSTEM_MARGIN
const POST_SYSTEM_MARGIN = 'POST_SYSTEM_MARGIN';
const POST_SYSTEM_MARGIN_FULFILLED = 'POST_SYSTEM_MARGIN_FULFILLED';
const POST_SYSTEM_MARGIN_PENDING = 'POST_SYSTEM_MARGIN_PENDING';
const POST_SYSTEM_MARGIN_REJECTED = 'POST_SYSTEM_MARGIN_REJECTED';

//PUT SYSTEM_MARGIN
const PUT_SYSTEM_MARGIN = 'PUT_SYSTEM_MARGIN';
const PUT_SYSTEM_MARGIN_FULFILLED = 'PUT_SYSTEM_MARGIN_FULFILLED';
const PUT_SYSTEM_MARGIN_PENDING = 'PUT_SYSTEM_MARGIN_PENDING';
const PUT_SYSTEM_MARGIN_REJECTED = 'PUT_SYSTEM_MARGIN_REJECTED';

//Delete SYSTME MARGIN
const DELETE_SYSTEM_MARGIN = 'DELETE_SYSTEM_MARGIN';
const DELETE_SYSTEM_MARGIN_FULFILLED = 'DELETE_SYSTEM_MARGIN_FULFILLED';
const DELETE_SYSTEM_MARGIN_PENDING = 'DELETE_SYSTEM_MARGIN_PENDING';
const DELETE_SYSTEM_MARGIN_REJECTED = 'DELETE_SYSTEM_MARGIN_REJECTED';

export {
  //System Margin
  GET_SYSTEM_MARGIN_LIST,
  GET_SYSTEM_MARGIN_LIST_FULFILLED,
  GET_SYSTEM_MARGIN_LIST_PENDING,
  GET_SYSTEM_MARGIN_LIST_REJECTED,
  RESET_STATUS_SYSTEM_MARGIN,
  GET_SYSTEM_MARGIN_BY_ID,
  GET_SYSTEM_MARGIN_BY_ID_FULFILLED,
  GET_SYSTEM_MARGIN_BY_ID_PENDING,
  GET_SYSTEM_MARGIN_BY_ID_REJECTED,
  GET_SYSTEM_MARGIN_BY_PROFILE_ID,
  GET_SYSTEM_MARGIN_BY_PROFILE_ID_FULFILLED,
  GET_SYSTEM_MARGIN_BY_PROFILE_ID_PENDING,
  GET_SYSTEM_MARGIN_BY_PROFILE_ID_REJECTED,
  DELETE_TOUR_OPERATOR_MARGIN,
  DELETE_TOUR_OPERATOR_MARGIN_FULFILLED,
  DELETE_TOUR_OPERATOR_MARGIN_PENDING,
  DELETE_TOUR_OPERATOR_MARGIN_REJECTED,
  POST_SYSTEM_MARGIN,
  POST_SYSTEM_MARGIN_FULFILLED,
  POST_SYSTEM_MARGIN_PENDING,
  POST_SYSTEM_MARGIN_REJECTED,
  PUT_SYSTEM_MARGIN,
  PUT_SYSTEM_MARGIN_FULFILLED,
  PUT_SYSTEM_MARGIN_PENDING,
  PUT_SYSTEM_MARGIN_REJECTED,
  DELETE_SYSTEM_MARGIN,
  DELETE_SYSTEM_MARGIN_FULFILLED,
  DELETE_SYSTEM_MARGIN_PENDING,
  DELETE_SYSTEM_MARGIN_REJECTED,
};
