import React from 'react';
import PropTypes from 'prop-types';
import { Card, Grid, Checkbox, Header } from 'semantic-ui-react';

const filterContent = props => {
  const {
    header,
    data,
    columns,
    gridStyles,
    columnStyles,
    name,
    handleChange,
  } = props;
  return (
    <Card.Content>
      <Header as="h3">{header}</Header>
      <Grid columns={columns} style={gridStyles}>
        {data.map((item, index) => (
          <Grid.Column key={index} style={columnStyles}>
            <Checkbox
              name={name}
              value={item.key}
              label={item.text}
              checked={item.checked ? item.checked : false}
              onChange={handleChange}
            />
          </Grid.Column>
        ))}
      </Grid>
    </Card.Content>
  );
};

filterContent.propTypes = {
  header: PropTypes.string,
  data: PropTypes.array,
  columns: PropTypes.number,
  gridStyles: PropTypes.object,
  columnStyles: PropTypes.object,
  name: PropTypes.string,
  handleChange: PropTypes.func,
};

export default filterContent;
