const handleValidation = (state, fields) => {
  const validate = {};
  fields.map(field => {
    if (state[field] === '') {
      validate[field] = {
        error: true,
        message: `Field can't be empty!!`,
      };
    } else if (
      (field === 'DestinationCountry' || field === 'AdditionalServiceType') &&
      state[field] &&
      state[field].Value === ''
    ) {
      validate[field] = {
        error: true,
        message: `Field can't be empty!!`,
      };
    } else {
      delete validate[field];
    }
    return validate;
  });

  return validate;
};

export default handleValidation;
