import * as types from './constant/actionTypes';

const initialState = {
  deleteCityStatus: '',
  postCityStatus: '',
  putCityStatus: '',
  getCityById: [],
  getCityByIdStatus: '',
  errors: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_CITY_PAGE: {
      return {
        ...state,
        deleteCityStatus: '',
        getCityByIdStatus: '',
        postCityStatus: '',
        putCityStatus: '',
      };
    }

    case types.DELETE_CITY: {
      return { ...state };
    }
    case types.DELETE_CITY_REJECTED: {
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        deleteCityStatus: 'failed',
      };
    }
    case types.DELETE_CITY_PENDING: {
      return { ...state, loading: true };
    }
    case types.DELETE_CITY_FULFILLED: {
      return {
        ...state,
        loading: false,
        deleteCityStatus: 'success',
      };
    }
    case types.GET_CITY_BY_ID: {
      return { ...state };
    }
    case types.GET_CITY_BY_ID_REJECTED: {
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getCityByIdStatus: 'failed',
        loading: false,
      };
    }
    case types.GET_CITY_BY_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_CITY_BY_ID_FULFILLED: {
      return {
        ...state,
        getCityById: action.payload.data,
        loading: false,
        getCityByIdStatus: 'success',
      };
    }
    case types.POST_CITY: {
      return { ...state };
    }
    case types.POST_CITY_REJECTED: {
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        postCityStatus: 'failed',
      };
    }
    case types.POST_CITY_PENDING: {
      return { ...state, loading: true };
    }
    case types.POST_CITY_FULFILLED: {
      return {
        ...state,
        loading: false,
        postCityStatus: 'success',
      };
    }
    case types.PUT_CITY: {
      return { ...state };
    }
    case types.PUT_CITY_REJECTED: {
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        putCityStatus: 'failed',
      };
    }
    case types.PUT_CITY_PENDING:
      return { ...state, loading: true };
    case types.PUT_CITY_FULFILLED: {
      return {
        ...state,
        loading: false,
        putCityStatus: 'success',
      };
    }

    default:
      return state;
  }
};

export default reducer;
