import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
const tableAdditionalItemInDetailBooking = props => (
  <div style={{ marginTop: '2em' }}>
    <Table basic="very" selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Tour Item</Table.HeaderCell>
          <Table.HeaderCell>Total Pax</Table.HeaderCell>
          <Table.HeaderCell>Price/pax</Table.HeaderCell>
          <Table.HeaderCell>Sub Total</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {props.listTourPrice.length > 0
          ? props.listTourPrice.map((room, idx) => {
              return (
                room.data.Pax > 0 && (
                  <Table.Row key={idx}>
                    <Table.Cell>{room.name}</Table.Cell>
                    <Table.Cell>{room.data.Pax}</Table.Cell>
                    <Table.Cell>
                      {room.data.PricePerPax
                        ? `  ${room.data.Currency} ${new Intl.NumberFormat(
                            'de-DE'
                          ).format(room.data.PricePerPax)} `
                        : 0}
                    </Table.Cell>
                    <Table.Cell>
                      {room.data.TotalPrice
                        ? `  ${room.data.Currency} ${new Intl.NumberFormat(
                            'de-DE'
                          ).format(room.data.TotalPrice)} `
                        : 0}
                    </Table.Cell>
                  </Table.Row>
                )
              );
            })
          : null}

        {props.listData && props.listData.length > 0
          ? props.listData.map((data, idx) => {
              return (
                <Table.Row key={idx}>
                  <Table.Cell>
                    {data.Description}
                    {' (Special Adjusment) '}
                  </Table.Cell>
                  <Table.Cell>{data.Qty}</Table.Cell>
                  <Table.Cell
                    style={
                      Number(data.UnitCost) < 0
                        ? { color: 'red', fontWeight: 'bold' }
                        : {}
                    }
                  >
                    {data.UnitCost < 0
                      ? ` ( ${data.CurrencyId} ${new Intl.NumberFormat(
                          'de-DE'
                        ).format(Math.abs(data.UnitCost))} ) `
                      : `${data.CurrencyId} ${new Intl.NumberFormat(
                          'de-DE'
                        ).format(Math.abs(data.UnitCost))}`}
                  </Table.Cell>
                  <Table.Cell
                    style={
                      Number(data.TotalCost) < 0
                        ? { color: 'red', fontWeight: 'bold' }
                        : {}
                    }
                  >
                    {data.TotalCost < 0
                      ? ` ( ${data.CurrencyId} ${new Intl.NumberFormat(
                          'de-DE'
                        ).format(Math.abs(data.TotalCost))} ) `
                      : `${data.CurrencyId} ${new Intl.NumberFormat(
                          'de-DE'
                        ).format(Math.abs(data.TotalCost))}`}
                  </Table.Cell>
                </Table.Row>
              );
            })
          : null}
      </Table.Body>
    </Table>
  </div>
);

tableAdditionalItemInDetailBooking.propTypes = {
  listData: PropTypes.array,
  listTourPrice: PropTypes.array,
};
export default tableAdditionalItemInDetailBooking;
