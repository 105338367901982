import React from 'react';
import { Grid, Divider, Segment, Button, Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ListUploadData from './UploadMasterDataTable';

const UploadMasterDataForm = props => (
  <Segment>
    <Grid>
      <Grid.Row>
        <Grid.Column width={14}>
          <Input
            type="file"
            onChange={props.handleChange}
            style={{ paddingRight: '5px' }}
          />
          <Button
            onClick={props.downloadTemplate}
            primary
            content="Download Template"
          />
          <Button
            onClick={props.submit}
            positive
            content="Upload File"
            disabled={props.disabledUpload}
            loading={props.loadingButtonUpload}
          />

          <Button
            onClick={props.saveUpload}
            positive
            content="Save"
            disabled={props.disabledSave}
            loading={props.loadingButtonSave}
          />
        </Grid.Column>
      </Grid.Row>
      <Divider />
      <Grid.Row>
        <Grid.Column width={16}>
          <ListUploadData listData={props.listUpload} type={props.type} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
);

UploadMasterDataForm.propTypes = {
  handleChange: PropTypes.func,
  disabledSave: PropTypes.bool,
  disabledUpload: PropTypes.bool,
  saveUpload: PropTypes.func,
  submit: PropTypes.func,
  listUpload: PropTypes.array,
  type: PropTypes.string,
  loadingButtonUpload: PropTypes.bool,
  loadingButtonSave: PropTypes.bool,
  downloadTemplate: PropTypes.func,
};

export default UploadMasterDataForm;
