import React from 'react';
import { Dimmer } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {
  getCustomerProfileListAction,
  resetCustomerProfileAction,
} from '../shared/actions/customerProfileAction';
import ModalAlert from '../shared/components/modal/ModalAlert';
import Loader from '../../components/loader';
// import PartnerProfileList from '../../components/globalListVew/globalListComponent';
import PartnerProfileList from './components/PartnerProfileList';
import PropTypes from 'prop-types';
import { deleteCompaniesByIdAction } from '../../actions/companyAction';
import ModalConfirm from '../../components/modal/modalConfirmationTwoButton';
import { partnerProfileDetail } from './constant/pathName';
import { Redirect } from 'react-router-dom';
import { getUserRoleByBearerAction } from '../../actions/userAccessAction';
import { checkUser } from './../../scripts/authorization';
class PartnerProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      partnerProfileList: [],
      loadingPartnerProfile: true,
      failedPartnerProfile: false,
      modalOpen: false,
      modalConfirm: {
        type: '',
        isOpen: false,
        message: '',
        data: {},
        isConfirm: false,
      },
      modalAlert: {
        isOpen: false,
        header: '',
        message: '',
      },
      changePage: false,
      gotoLink: '',
      isFinance: null,
    };
  }
  static propTypes = {
    getCustomerProfileListAction: PropTypes.func,
    getUserRoleByBearerAction: PropTypes.func,
    statusPartnerProfile: PropTypes.func,
    listPartnerProfile: PropTypes.func,
    resetCustomerProfileAction: PropTypes.func,
    errors: PropTypes.object,
    deleteCompaniesByIdAction: PropTypes.func,
    deleteCompany: PropTypes.object,
    errorCompany: PropTypes.string,
    userRoles: PropTypes.object,
  };

  handleOpen = () => this.setState({ modalOpen: true });
  handleClose = () => this.setState({ failedPartnerProfile: false });
  componentDidMount = () => {
    this.props.getCustomerProfileListAction('Partner');
    this.props.getUserRoleByBearerAction().then(() => {
      let authorization = checkUser(this.props.userRoles.Roles);
      this.setState({ ...this.state, isFinance: authorization.isFinance });
    });
  };
  componentDidUpdate() {
    if (this.props.statusPartnerProfile === 'success') {
      this.setState({
        partnerProfileList: this.props.listPartnerProfile,
      });
      this.setState({ loadingPartnerProfile: false });
      this.props.resetCustomerProfileAction();
    } else if (this.props.statusPartnerProfile === 'failed') {
      this.setState({ failedPartnerProfile: true });
      this.setState({ loadingPartnerProfile: false });
      this.props.resetCustomerProfileAction();
    }
  }

  addNew = () => {
    // eslint-disable-next-line
    this.props.history.push(`${partnerProfileDetail}new`);
  };
  buttonEdit = (e, { name, value }) => {
    let link = `/Partner-Profile-Detail/${name}/${value}`;
    this.setState({ ...this.state, changePage: true, gotoLink: link });
  };
  handleDelete = (e, { name, value }) => {
    let modalConfirm = { ...this.state.modalConfirm };
    modalConfirm.type = name;
    modalConfirm.isOpen = true;
    modalConfirm.message = `Are you sure want to delete ${value.Name}?`;
    modalConfirm.data = value;
    this.setState({
      ...this.state,
      modalConfirm,
    });
  };

  handleModalConfirm = (e, { name }) => {
    let modalConfirm = { ...this.state.modalConfirm };
    modalConfirm.isOpen = false;
    modalConfirm.isConfirm = name === 'isConfirm';
    this.setState({ ...this.state, modalConfirm }, () => this.confirmAction());
  };

  confirmAction = () => {
    let { isConfirm, data } = this.state.modalConfirm;
    if (isConfirm) {
      this.props
        .deleteCompaniesByIdAction(data.Code)
        .then(() => {
          this.setAlert(
            'Success',
            `Delete ${this.props.deleteCompany.Name} succesfully`
          );
          this.props.getCustomerProfileListAction('Partner');
        })
        .catch(() => this.setAlert('Failed', this.props.errorCompany.Message));
    }
  };

  setAlert = (status, message) => {
    let modalAlert = { ...this.state.modalAlert };
    modalAlert.isOpen = true;
    modalAlert.header = status;
    modalAlert.message = message;
    this.setState({ ...this.state, modalAlert });
  };

  handleCloseAlert = () => {
    let modalAlert = { ...this.state.modalAlert };
    modalAlert.isOpen = false;
    this.setState({ ...this.state, modalAlert });
  };

  render() {
    let {
      loadingPartnerProfile,
      failedPartnerProfile,
      modalConfirm,
      modalAlert,
      changePage,
      gotoLink,
      isFinance,
    } = this.state;
    let loading = loadingPartnerProfile;
    let openModal = failedPartnerProfile;
    if (changePage) {
      return <Redirect to={gotoLink} />;
    }
    return (
      <div>
        <Dimmer.Dimmable blurring dimmed={loading}>
          <Loader loading={loading} />
          <PartnerProfileList
            data={this.state.partnerProfileList}
            type="partnerProfile"
            filterBy="Name"
            buttonNew={this.addNew}
            buttonEdit={this.buttonEdit}
            buttonDelete={this.handleDelete}
            isHideButtonDelete={isFinance}
            isFinance={isFinance}
          />
          <ModalAlert
            openModal={openModal}
            handleClose={this.handleClose}
            header="Failed"
            content={this.props.errors.global}
            nameButton="OK"
          />
          <ModalAlert
            openModal={modalAlert.isOpen}
            handleClose={this.handleCloseAlert}
            header={modalAlert.header}
            content={modalAlert.message}
            nameButton="OK"
          />
          <ModalConfirm
            openModal={modalConfirm.isOpen}
            size="mini"
            close={this.handleModalConfirm}
            confirm={this.handleModalConfirm}
            nameConfirm="isConfirm"
            message={modalConfirm.message}
          />
        </Dimmer.Dimmable>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    listPartnerProfile: state.CustomerProfileStore.getCustomerProfileList,
    statusPartnerProfile:
      state.CustomerProfileStore.getCustomerProfileListStatus,
    errors: state.CustomerProfileStore.errors,
    deleteCompany: state.CompanyStore.deleteCompany,
    errorCompany: state.CompanyStore.errors,
    loadingCompany: state.CompanyStore.loading,
    userRoles: state.UserAccessStore.getUserRoleByBearer,
  };
}
export default connect(mapStateToProps, {
  getCustomerProfileListAction,
  resetCustomerProfileAction,
  deleteCompaniesByIdAction,
  getUserRoleByBearerAction,
})(PartnerProfile);
