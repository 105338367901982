const tourOperatorField = [
  'Id',
  'Name',
  'Address',
  'Telephone',
  'Email',
  // 'ImageUrl',
  'CityId',
  'RegionId',
  'Company',
];

const emailSettingField = [
  'Credential',
  'EmailAddress',
  'Id',
  'Port',
  'SmtpClient',
  'TourOperatorProfileId',
];
export { tourOperatorField, emailSettingField };
