import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import {
  FREETIME,
  DRIVING,
  QUEUETIME,
  DEPARTURE,
  ARRIVAL,
  FLIGHTTIME,
  RECREATION,
  EAT,
  TRANSIT,
} from '../../../../shared/components/tourTransaction/dailyProgram/constant/activityType';
class buttonEditActivity extends React.Component {
  handleDelete = () => {
    this.props.deleteActivity(this.props.index, this.props.day);
  };
  handleEdit = () => {
    let { movement, activity, index, day } = this.props;
    this.props.handleOpenModalActivities(
      movement.MovementName,
      movement,
      activity.Movements,
      index,
      day,
      'EDIT',
      null
    );
  };
  render() {
    let { movement, isPublished } = this.props;
    if (
      [
        RECREATION,
        DEPARTURE,
        ARRIVAL,
        TRANSIT,
        EAT,
        FREETIME,
        QUEUETIME,
        DRIVING,
        FLIGHTTIME,
      ].indexOf(movement.MovementName) !== -1
    ) {
      return (
        <div>
          <Button
            icon="edit"
            primary
            onClick={this.handleEdit}
            disabled={isPublished}
          />
          {[DRIVING, FLIGHTTIME].indexOf(movement.MovementName) === -1 && (
            <Button
              icon="close"
              negative
              onClick={this.handleDelete}
              disabled={isPublished}
            />
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}

buttonEditActivity.propTypes = {
  movement: PropTypes.object,
  index: PropTypes.number,
  day: PropTypes.number,
  deleteActivity: PropTypes.number,
  activity: PropTypes.object,
  handleOpenModalActivities: PropTypes.func,
  isPublished: PropTypes.bool,
};
export default buttonEditActivity;
