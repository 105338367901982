import { emailValidation } from '../../../shared/services/validation/emailValidation';
const copyObject = obj => {
  let newObj = JSON.stringify(obj);
  return JSON.parse(newObj);
};

const userProfileValidation = (state, error) => {
  let checkErrors = copyObject(error);
  checkErrors.UserName.error = state.UserName === '' || state.UserName === null;
  checkErrors.FirstName.error =
    state.FirstName === '' || state.FirstName === null;
  checkErrors.LastName.error = state.LastName === '' || state.LastName === null;
  checkErrors.PhoneNbr.error = state.PhoneNbr === '' || state.PhoneNbr === null;
  checkErrors.Email.error =
    state.Email === '' || !emailValidation(state.Email) || state.Email === null;
  checkErrors.Gender.error = state.Gender === '' || state.Gender === null;
  checkErrors.Country.error =
    state.Country.Name === '' || state.Country.Name === null;
  checkErrors.isError =
    checkErrors.UserName.error ||
    checkErrors.FirstName.error ||
    checkErrors.LastName.error ||
    checkErrors.PhoneNbr.error ||
    checkErrors.Email.error ||
    checkErrors.Gender.error ||
    checkErrors.Country.error;
  return checkErrors;
};

export { userProfileValidation };
