import React from 'react';
import { Message, Form, Checkbox, Grid, Icon } from 'semantic-ui-react';
import { Collapse } from 'react-collapse';
import PropTypes from 'prop-types';
import styles from '../../../../../../components/segment/styles';

const getCommission = (commissions, splitCommision, printCommision) => {
  const newCommissions = [];
  // eslint-disable-next-line
  commissions &&
  commissions.length > 0 &&
  (splitCommision === 0 || splitCommision === 1) // eslint-disable-next-line
    ? commissions.map(item => {
        return splitCommision === 1
          ? item.Category === 'Agent_Commission' &&
              newCommissions.push({
                name: item.Category.replace('_', ' '),
                value: item.Value,
              })
          : splitCommision === 0 && printCommision === 0
          ? newCommissions.length === 0
            ? newCommissions.push({
                name: 'Total Commission',
                value: item.Value,
              })
            : (newCommissions[0].value = newCommissions[0].value + item.Value)
          : newCommissions.push({
              name: item.Category.replace('_', ' '),
              value: item.Value,
            });
      })
    : null;
  return newCommissions;
};

const SegmentCommision = props => {
  let {
    commissions,
    splitCommision,
    printCommision,
    handleChangeRadio,
    currency,
  } = props;

  let selectCommission = getCommission(
    commissions,
    splitCommision,
    printCommision
  );
  return (
    // <Segment style={styles.noBorder}>
    <div style={{ marginTop: '10px' }}>
      <h4>Commision Confirmation</h4>
      <Form>
        <Form.Group>
          <Form.Field>Do you want to split staff commision?</Form.Field>
          <Form.Field>
            <Checkbox
              radio
              label="Yes"
              name="splitCommision"
              value={1}
              checked={splitCommision === 1}
              onChange={handleChangeRadio}
              objectname="commission"
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              radio
              label="No"
              name="splitCommision"
              value={0}
              checked={splitCommision === 0}
              onChange={handleChangeRadio}
              objectname="commission"
            />
          </Form.Field>
        </Form.Group>
        <Collapse isOpened={splitCommision === 0}>
          <Form.Group>
            <Form.Field>Print Commission as Agent and Staff?</Form.Field>
            <Form.Field>
              <Checkbox
                radio
                label="Yes"
                name="printCommision"
                value={1}
                checked={printCommision === 1}
                onChange={handleChangeRadio}
                objectname="commission"
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                radio
                label="No"
                name="printCommision"
                value={0}
                checked={printCommision === 0}
                onChange={handleChangeRadio}
                objectname="commission"
              />
            </Form.Field>
          </Form.Group>
        </Collapse>
      </Form>
      <Collapse isOpened={selectCommission.length !== 0}>
        <Message style={styles.backgroundWhiteBorderGold}>
          <Message.Header
            style={styles.backgroundGoldTransparentPadding7px20px}
          >
            <Icon
              name="info"
              size="small"
              circular
              style={styles.colorBorderGold}
            />
            Commission will print in invoice as
          </Message.Header>
          <Message.Content style={styles.paddingTenAndTwenty}>
            {selectCommission.map((commission, index) => (
              <Grid key={index}>
                <Grid.Column width={8}>{commission.name}</Grid.Column>
                <Grid.Column width={8}>
                  {currency} {commission.value}
                  /pax
                </Grid.Column>
              </Grid>
            ))}
          </Message.Content>
        </Message>
      </Collapse>
      {/* // </Segment> */}
    </div>
  );
};

SegmentCommision.propTypes = {
  splitCommision: PropTypes.number,
  printCommision: PropTypes.number,
  commissions: PropTypes.array,
  handleChangeRadio: PropTypes.func,
  currency: PropTypes.string,
};
export default SegmentCommision;
