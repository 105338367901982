import React from 'react';
//import Helmet from 'react-helmet';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { Button, Header, Modal, Grid, Checkbox } from 'semantic-ui-react';
import PropTypes from 'prop-types';
// import moment from 'moment';

class modalCalendar extends React.Component {
  static defaultProps = {
    numberOfMonths: 2,
  };
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.state = this.getInitialState();
  }
  getInitialState() {
    return {
      from: undefined,
      to: undefined,
      allowedDay: {
        Sunday: true,
        Monday: true,
        Tuesday: true,
        Wednesday: true,
        Thursday: true,
        Friday: true,
        Saturday: true,
      },
    };
  }
  handleDayClick(day) {
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);
  }
  handleResetClick() {
    this.setState(this.getInitialState());
  }
  filterByDate = (e, { id, name }) => {
    this.handleResetClick();
    !this.props.isReadyFixPrice
      ? this.props.actionDateRange(id, name)
      : this.props.actionDateRange(id, this.state.allowedDay);
  };

  handlePickDay = (e, { name }) => {
    const { allowedDay } = this.state;
    // let day = this.handleDayConvert(name);
    allowedDay[name] = !allowedDay[name];
    this.setState({ allowedDay });
  };

  handleMountModal = () => {
    const { startDate, endDate } = this.props;
    if (startDate && endDate) {
      this.setState({ from: startDate, to: endDate });
    }
  };

  render() {
    const { from, to, allowedDay } = this.state;
    const modifiers = { start: from, end: to };
    return (
      <Modal
        dimmer="blurring"
        closeIcon
        inverted
        open={this.props.openModal}
        onClose={this.props.handleClose}
        style={{ width: '43em' }}
        onMount={this.handleMountModal}
      >
        <Header icon="filter" content={this.props.contentHeader} />
        <Modal.Content>
          <div className="RangeExample">
            <DayPicker
              className="Selectable"
              numberOfMonths={2}
              selectedDays={[from, { from, to }]}
              modifiers={modifiers}
              onDayClick={this.handleDayClick}
            />
            {this.props.isReadyFixPrice && (
              <Grid columns={4} style={{ marginTop: '1em' }}>
                <Header as={'h4'} content="Available Day" />
                <Grid.Row>
                  <Grid.Column>
                    <Checkbox
                      label="Monday"
                      // name={1}
                      name="Monday"
                      checked={allowedDay.Monday}
                      onChange={this.handlePickDay}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Checkbox
                      label="Wednesday"
                      // name={3}
                      name="Wednesday"
                      checked={allowedDay.Wednesday}
                      onChange={this.handlePickDay}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Checkbox
                      label="Friday"
                      // name={5}
                      name="Friday"
                      checked={allowedDay.Friday}
                      onChange={this.handlePickDay}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Checkbox
                      label="Sunday"
                      // name={0}
                      name="Sunday"
                      checked={allowedDay.Sunday}
                      onChange={this.handlePickDay}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Checkbox
                      label="Tuesday"
                      // name={2}
                      name="Tuesday"
                      checked={allowedDay.Tuesday}
                      onChange={this.handlePickDay}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Checkbox
                      label="Thursday"
                      // name={4}
                      name="Thursday"
                      checked={allowedDay.Thursday}
                      onChange={this.handlePickDay}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Checkbox
                      label="Saturday"
                      // name={6}
                      name="Saturday"
                      checked={allowedDay.Saturday}
                      onChange={this.handlePickDay}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
            <style>{`
  .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .Selectable .DayPicker-Day {
    border-radius: 0 !important;
  }
  .Selectable .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .Selectable .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
`}</style>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            // icon="search"
            content="Select Date"
            onClick={this.filterByDate}
            id={this.state}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

modalCalendar.propTypes = {
  actionDateRange: PropTypes.func,
  openModal: PropTypes.func,
  handleClose: PropTypes.func,
  contentHeader: PropTypes.string,
  isReadyFixPrice: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};
export default modalCalendar;
