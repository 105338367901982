import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Button, Form, Message } from 'semantic-ui-react';
import InputWithLabel from '../../../../../components/input/inputWithLabel';
import InputTime from '../../../../../components/input/inputTime';
import {
  getHourAndMinute,
  timeDuration,
  convertDateFormat,
  changeTime,
} from './../../../../../scripts/moment';
// '../../../../../components/genericFunction/moment';

import InputAmount from '../../../../../components/input/inputAmount';
import { SumSecond } from '../../../../../components/genericFunction/timeHelper';
import Options from '../../../../../scripts/convertOption';
import { getExcursionAction } from '../../../../shared/actions/excursionAction';
import {
  getRestaurantItemByIdAction,
  getRestaurantByFilterAction,
} from '../../../../../actions/restaurantAction';
import ModalListExcursion from '../../../../../components/modal/modalListExcursion';
import ModalListRestaurant from '../../../../../components/modal/modalListRestaurant';
import {
  EAT,
  QUEUETIME,
  FREETIME,
  ARRIVAL,
  RECREATION,
  DEPARTURE,
  TRANSIT,
  DRIVING,
  FLIGHTTIME,
} from '../../../../shared/components/tourTransaction/dailyProgram/constant/activityType';
import { getExcursionByCityAction } from '../../../../shared/actions/excursionAction';
import { getAirportAction } from '../../../../../actions/airportAction';
// import { getAirportAction } from './../../../actions/airportAction';
import {
  addDailyProgram,
  isFirstDayNoAccom,
  editDailyProgram,
} from '../../../../../scripts/itineraryBuilderCreateFixedPackage/dailyProgramFixedPackage';
import { TOP } from './../../../../../scripts/itineraryBuilderCreateFixedPackage/constData';
import ModalAlert from '../../../../../components/modal/modalAlert';
const dataRestaurant = {
  cityId: '',
  ratingIds: '',
  locationIds: '',
  specializationId: '',
  menuClassId: '',
  facilityIds: '',
  requestedDate: '2018-17-28',
  pax: 1,
};

class modalActivityFixedPackage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openModalExcursion: false,
      openModalRestaurant: false,
      openModalAlert: false,
      messageModalAlert: '',
      activityData: {
        Name: '',
        Startime: '',
        Duration: 0,
        optimumDuration: 0,
        Note: null,
      },
      activityObj: null,
      isOpen: false,
      error: { error: false, message: '' },
      destination: '',
      objDestination: {},
      selectedAirport: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.openModal !== prevProps.openModal) {
      this.setInitialData();
    }
  }

  setInitialData = async () => {
    let { activityData, activityObj, selectedAirport } = this.state;
    let {
      activityType,
      action,
      activity,
      day,
      index,
      dailyProgram,
      positionButtonAdd,
      dayFirstCheckIn,
    } = this.props;
    let newActivityData = { ...activityData };
    let newActivityObj = { ...activityObj };
    // select destination
    let isFirstDayNoAccomResult = isFirstDayNoAccom(
      day,
      index,
      dailyProgram[day],
      positionButtonAdd,
      dayFirstCheckIn
    );
    let destination =
      action === 'EDIT'
        ? activity.Destination
        : !isFirstDayNoAccomResult
        ? activity
          ? activity.Destination
          : null
        : null;
    let selectedNextMovement =
      dailyProgram[day].Movements[
        positionButtonAdd === TOP ? index : index + 1
      ];
    destination =
      activityType === ARRIVAL
        ? selectedNextMovement
          ? selectedNextMovement.Destination
          : ''
        : destination;
    let objDestination = await this.getRegionByCityId(destination);
    // end select destination
    if ([FREETIME, QUEUETIME, FLIGHTTIME].indexOf(activityType) !== -1) {
      newActivityData.optimumDuration = 3600;
      newActivityData.Duration = action === 'EDIT' ? activity.Duration : 3600;
      newActivityData.Note = activity ? activity.Note : null;
      newActivityData.Startime =
        action === 'EDIT'
          ? activity.DateTime
          : activity
          ? SumSecond(activity.DateTime, activity.Duration)
          : changeTime(dailyProgram[day].Date, 8, 0);
    } else if (['RECREATION', 'EAT'].indexOf(activityType) !== -1) {
      if (action === 'EDIT') {
        let response =
          activityType === 'RECREATION'
            ? await this.props.getExcursionAction(activity.Item.ServiceItemId)
            : await this.props.getRestaurantItemByIdAction(
                activity.Item.ServiceItemId
              );
        newActivityObj = response ? response.value.data : newActivityObj;
        newActivityData.ServiceItemId = activity.Item.ServiceItemId;
        newActivityData.Name = activity.Item.Name;
        newActivityData.Startime = activity.DateTime;
        newActivityData.Duration = activity.Duration;
        newActivityData.optimumDuration = response
          ? response.value.data.OptimumDuration
            ? response.value.data.OptimumDuration
            : 3600
          : 3600;
        newActivityData.OperationEndTime = response
          ? response.value.data.OperationEndTime
          : null;
        newActivityData.OperationStartTime = response
          ? response.value.data.OperationStartTime
          : null;
        newActivityData.IsSolidStartTime = response
          ? response.value.data.IsSolidStartTime
            ? response.value.data.IsSolidStartTime
            : false
          : false;
      } else {
        newActivityData = {
          Name: '',
          Startime: SumSecond(activity.DateTime, activity.Duration),
          Duration: 3600,
          optimumDuration: 3600,
          Note: null,
        };
        newActivityObj = null;
      }
    } else if ([ARRIVAL, DEPARTURE, TRANSIT].indexOf(activityType) !== -1) {
      objDestination &&
        objDestination.Region &&
        this.props.getAirportAction(objDestination.Region.Id);
      newActivityData.optimumDuration = 3600;
      newActivityData.Note = activity ? activity.Note : null;
      newActivityData.Startime =
        action === 'EDIT'
          ? activity.DateTime
          : activity
          ? SumSecond(activity.DateTime, activity.Duration)
          : changeTime(dailyProgram[day].Date, 8, 0);
      newActivityObj =
        action === 'EDIT'
          ? await this.getObjAirport(activity.Item.PlaceId)
          : newActivityObj;
      selectedAirport = action === 'EDIT' ? activity.Item.PlaceId : null;
    } else if ([DRIVING].indexOf(activityType) !== -1) {
      newActivityData.Duration = activity.Duration;
      newActivityData.Startime = activity.DateTime;
    }
    this.setState({
      ...this.state,
      activityData: newActivityData,
      isOpen: this.props.openModal,
      activityObj: newActivityObj,
      destination,
      objDestination,
      isFirstDayNoAccomResult,
      selectedAirport,
      error: {
        error: false,
        message: '',
      },
    });
  };

  handleSetActivity = () => {
    let { activityType } = this.props;
    let { activityData, activityObj, destination } = this.state;
    if (
      [EAT, RECREATION].indexOf(activityType) !== -1 &&
      activityData.Name === ''
    ) {
      this.setError(
        true,
        `Please select your ${
          activityType === 'EAT' ? 'Restaurant' : 'Excursion'
        }`
      );
    } else if (
      [FREETIME, QUEUETIME].indexOf(activityType) !== -1 &&
      activityData.Duration < 900
    ) {
      this.setError(true, `The Minimum duration is 15 minutes`);
    } else if (
      [ARRIVAL, DEPARTURE].indexOf(activityType) !== -1 &&
      (activityObj === {} || activityObj === undefined || destination === null)
    ) {
      this.setError(true, `Please select your destination and airport`);
    } else if (
      [FREETIME, QUEUETIME].indexOf(activityType) !== -1 &&
      destination === null
    ) {
      this.setError(true, `Please select your destination`);
    } else if (activityType === 'RECREATION' && this.checkExcursion()) {
      this.setError(true, `The Excursion is Already Selected`);
    } else if (activityType === 'RECREATION' && this.checkAbsoluteTime()) {
      this.setError(true, `The Excursion have Fixed Time`);
    } else {
      this.setDaily();
      this.setError(false, '');
    }
  };
  renderHeaderTitle = () => {
    switch (this.props.activityType) {
      case 'RECREATION':
        return 'Set Excursion Activity';
      case 'EAT':
        return 'Set Meal Activity';
      case 'FREETIME':
        return 'Set Free Time Activity';
      case QUEUETIME:
        return 'Set Queue Time Activity';
      case DEPARTURE:
        return 'Set Departure';
      case ARRIVAL:
        return 'Set Arrival';
      case TRANSIT:
        return 'Set Transit';
      case DRIVING:
        return 'Set Trip By Car';
      case FLIGHTTIME:
        return 'Set Flight Time';
      default:
        return '';
    }
  };

  setStartTime = (context, value) => {
    let state = { ...this.state };
    state.activityData.Startime = `${convertDateFormat(
      state.activityData.Startime,
      'YYYY-MM-DD'
    )}T${value}:00`;
    context.setState({ ...context.state, activityData: state.activityData });
  };

  handleDecreaseDuration = () => {
    let { activityData } = this.state;
    let { activityType, isFixedPackageManagement } = this.props;
    if (
      activityType === DRIVING ||
      (isFixedPackageManagement
        ? activityData.Duration > 900
        : activityData.Duration > 3600)
    ) {
      activityData.Duration = activityData.Duration - 900;
      this.setState({
        activityData: activityData,
      });
    }
  };
  handleIncreaseDuration = () => {
    let { activityData } = this.state;
    activityData.Duration = activityData.Duration + 900;
    this.setState({
      activityData: activityData,
    });
  };
  setError = (value, message) => {
    this.setState({
      ...this.state,
      error: {
        error: value,
        message: message,
      },
    });
  };
  setDaily = () => {
    let {
      dailyProgram,
      movementList,
      index,
      day,
      activityType,
      action,
      activity,
      positionButtonAdd,
    } = this.props;
    let { activityObj, activityData, objDestination } = this.state;
    let typeMovement = movementList.find(item => item.Name === activityType);
    let itemMovement =
      [FREETIME, QUEUETIME].indexOf(activityType) !== -1 ? null : activityObj;
    activityData.Startime = activityData.Startime
      ? activityData.Startime
      : activity.DateTime;
    // const move = dailyProgram[day].Movements[index].MovementName;
    let newDaily =
      action === 'ADD'
        ? addDailyProgram(
            dailyProgram,
            day,
            index,
            typeMovement,
            movementList,
            itemMovement,
            activityData,
            positionButtonAdd,
            objDestination
          )
        : editDailyProgram(
            dailyProgram,
            day,
            index,
            typeMovement,
            itemMovement,
            activityData,
            objDestination
          );
    this.props.setDailyProgramAfterAdd(
      newDaily,
      day,
      index,
      typeMovement,
      movementList,
      action,
      itemMovement
    );
    this.props.closeModal();
  };
  onChange = (e, { value, name }) => {
    let activityData = this.state.activityData;
    activityData[name] = value;
    this.setState({ ...this.state, activityData: activityData });
    name !== 'Note' && this.setActivity(value);
  };
  checkExcursion = () => {
    let { ServiceItemId } = this.state.activityObj;
    let { movements, action } = this.props;
    return (
      action === 'ADD' &&
      movements &&
      movements.some(
        move =>
          move.MovementName === 'RECREATION' &&
          move.Item.ServiceItemId === ServiceItemId
      )
    );
  };
  checkAbsoluteTime = () => {
    let { movements } = this.props;
    let { activityData } = this.state;
    if (activityData.IsSolidStartTime) {
      let arrival = movements.find(item => item.MovementName === 'ARRIVAL');
      let departure = movements.find(item => item.MovementName === 'DEPARTURE');
      let startTime = activityData.Startime;
      let endTime = SumSecond(activityData.Startime, activityData.Duration);
      let isoverlapArrival = arrival
        ? new Date(startTime).getTime() <
            new Date(arrival.DateTime).getTime() &&
          new Date(arrival.DateTime).getTime() < new Date(endTime).getTime()
          ? true
          : false
        : false;
      let isoverlapDeparture = departure
        ? new Date(startTime).getTime() <
            new Date(departure.DateTime).getTime() &&
          new Date(departure.DateTime).getTime() < new Date(endTime).getTime()
          ? true
          : false
        : false;
      return isoverlapArrival || isoverlapDeparture;
    } else return false;
  };

  setActivity = activity => {
    const { activityType } = this.props;
    let state = { ...this.state };
    let { DateTime } = this.props.activity;
    state.activityData.Name = activity.Name;
    state.activityData.Startime =
      activityType !== 'EAT'
        ? activity.IsSolidStartTime
          ? `${convertDateFormat(DateTime, 'YYYY-MM-DD')}T${getHourAndMinute(
              activity.OperationStartTime
                ? activity.OperationStartTime
                : state.activityData.Startime
            )}:00`
          : state.activityData.Startime
        : `${convertDateFormat(DateTime, 'YYYY-MM-DD')}T${getHourAndMinute(
            activity.OperationStartTime
              ? activity.OperationStartTime
              : state.activityData.Startime
          )}:00`;
    state.activityData.Duration = activity.OptimumDuration
      ? activity.OptimumDuration
      : 3600;
    state.activityData.optimumDuration = activity.OptimumDuration
      ? activity.OptimumDuration
      : 3600;
    state.activityData.OperationEndTime = activity.OperationEndTime
      ? activity.OperationEndTime
      : null;
    state.activityData.OperationStartTime = activity.OperationStartTime
      ? activity.OperationStartTime
      : null;
    state.activityData.IsSolidStartTime = activity.IsSolidStartTime
      ? activity.IsSolidStartTime
      : false;
    state.activityObj = activity;
    state.tempStartTime = getHourAndMinute(
      activity.OperationStartTime ? activity.OperationStartTime : DateTime
    );
    this.setState({
      ...this.state,
      activityData: state.activityData,
      activityObj: state.activityObj,
    });
    this.closeModal();
  };
  closeModal = () => {
    this.setState({
      openModalExcursion: false,
      openModalRestaurant: false,
    });
  };
  openModalExcursion = () => {
    this.setState({ openModalExcursion: true });
  };
  openModalRestaurant = () => {
    this.setState({ openModalRestaurant: true });
  };
  openCloseAlert = message => {
    this.setState({
      openModalAlert: !this.state.openModalAlert,
      messageModalAlert: message ? message : '',
    });
  };

  openModalList = () => {
    let isNullDestination = this.state.destination ? false : true;
    if (isNullDestination) {
      this.openCloseAlert('Please select destination');
    } else {
      const { activityType } = this.props;
      activityType === 'EAT'
        ? this.openModalRestaurant()
        : this.openModalExcursion();
    }
  };
  closeModalList = () => {
    const { activityType } = this.props;
    activityType === 'EAT'
      ? this.setState({ openModalRestaurant: false })
      : this.setState({ openModalExcursion: false });
  };
  clearError = () => {
    this.setState({ error: { error: false, message: '' } });
  };
  // =================================== FIXED PACKAGE MANAGEMENT =============================
  handleChangeDestination = async (e, { value }) => {
    const { activityType, activity } = this.props;
    let objDestination = await this.getRegionByCityId(value);
    if (activityType === EAT) {
      const dataRestaurant = {
        cityId: value,
        ratingIds: '',
        locationIds: '',
        specializationId: '',
        menuClassId: '',
        facilityIds: '',
        requestedDate: convertDateFormat(activity.DateTime, 'YYYY-MM-DD'),
        pax: 1,
      };
      this.props.getRestaurantByFilterAction(dataRestaurant);
    } else if (activityType === RECREATION) {
      this.props.getExcursionByCityAction(value);
    } else if ([ARRIVAL, DEPARTURE].indexOf(activityType) !== -1) {
      this.props.getAirportAction(objDestination.Region.Id);
    }
    this.setState({ ...this.state, destination: value, objDestination });
  };

  getRegionByCityId = city => {
    let cityInCountry = this.props.listCityInCountry.find(e => e.Id === city);
    return cityInCountry;
  };

  handleChangeAirport = async (e, { value }) => {
    let activityObj = await this.getObjAirport(value);
    this.setState({ ...this.state, activityObj, selectedAirport: value });
  };
  getObjAirport = airport => {
    let objAirport = this.props.listAirport.find(e => e.Id === airport);
    return objAirport;
  };
  // =================================== FIXED PACKAGE MANAGEMENT =============================
  render() {
    const {
      activityData,
      error,
      openModalExcursion,
      openModalRestaurant,
      destination,
      selectedAirport,
      openModalAlert,
      messageModalAlert,
    } = this.state;
    const {
      openModal,
      handleClose,
      activityType,
      activity,
      listItem,
      loadingItem,
      isFixedPackageManagement,
      optionCityInCountry,
      optionAirport,
      loadingGetAirport,
    } = this.props;
    let startTime = activityData.Startime
      ? activityData.Startime
      : activity
      ? activity.DateTime
      : '00:00';
    return (
      <div>
        <Modal closeIcon open={openModal} onClose={handleClose} size="tiny">
          <Modal.Header>
            <h3>{this.renderHeaderTitle()}</h3>
          </Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Field>
                <InputWithLabel
                  label="Destination"
                  placeholder="Destination"
                  name="destination"
                  type={isFixedPackageManagement ? 'select' : 'text'}
                  value={destination}
                  labelStyles={{
                    display: 'block',
                    fontWeight: 'bold',
                    marginBottom: '4px',
                  }}
                  disabled={
                    !isFixedPackageManagement || activityType === FLIGHTTIME
                  }
                  fluid={true}
                  options={optionCityInCountry}
                  handleChange={this.handleChangeDestination}
                />
              </Form.Field>
              {[ARRIVAL, DEPARTURE, TRANSIT].indexOf(activityType) !== -1 && (
                <InputWithLabel
                  label="Airport"
                  placeholder="Select Airport"
                  name="airport"
                  type="select"
                  labelStyles={{
                    display: 'block',
                    fontWeight: 'bold',
                    marginBottom: '4px',
                  }}
                  fluid={true}
                  options={optionAirport}
                  handleChange={this.handleChangeAirport}
                  loading={loadingGetAirport}
                  value={selectedAirport}
                />
              )}
              {[EAT, RECREATION].indexOf(activityType) !== -1 && (
                <Form.Field>
                  <InputWithLabel
                    label={activityType === 'EAT' ? 'Restaurant' : 'Excursion'}
                    placeholder={
                      activityType === 'EAT' ? 'Restaurant' : 'Excursion'
                    }
                    type="text"
                    value={activityData.Name}
                    labelStyles={{
                      display: 'block',
                      fontWeight: 'bold',
                      marginBottom: '4px',
                    }}
                    //options={listItem}
                    fluid={true}
                    //loading={loadingItem}
                    //handleChange={this.setActivity}
                    name={activityType === 'EAT' ? 'Restaurant' : 'Excursion'}
                    //selectOnBlur={true}
                    handleClick={this.openModalList}
                  />
                </Form.Field>
              )}
              {[DRIVING, FLIGHTTIME].indexOf(activityType) === -1 && (
                <Form.Field>
                  <InputTime
                    labelText="Start Time"
                    labelStyles={{
                      display: 'block',
                      fontWeight: 'bold',
                      marginBottom: '4px',
                    }}
                    context={this}
                    value={getHourAndMinute(startTime)}
                    setTime={this.setStartTime}
                  />
                </Form.Field>
              )}
              {[ARRIVAL, DEPARTURE, TRANSIT].indexOf(activityType) === -1 && (
                <Form.Field>
                  <InputAmount
                    label="Duration"
                    value={timeDuration(activityData.Duration)}
                    name="Duration"
                    min={activityData.optimumDuration}
                    handleDecrease={this.handleDecreaseDuration}
                    handleIncrease={this.handleIncreaseDuration}
                    typeInput="string"
                    handleOnChange={this.changeDuration}
                    disabled={FLIGHTTIME === activityType}
                  />
                </Form.Field>
              )}
              {activityType !== 'RECREATION' && (
                <Form.Field>
                  <InputWithLabel
                    label="Note"
                    placeholder="Note"
                    type="textArea"
                    labelStyles={{ display: 'block', fontWeight: 'bold' }}
                    name="Note"
                    fluid={true}
                    value={activityData.Note}
                    handleChange={this.onChange}
                  />
                </Form.Field>
              )}
            </Form>
            {error.error && (
              <Message error>
                <p>{error.message}</p>
              </Message>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button content="Save" primary onClick={this.handleSetActivity} />
          </Modal.Actions>
        </Modal>
        <ModalListExcursion
          open={openModalExcursion}
          onClose={this.closeModalList}
          name={activityType}
          location={destination}
          setExcursion={this.setActivity}
          data={listItem}
          loadingItem={loadingItem}
        />
        <ModalListRestaurant
          open={openModalRestaurant}
          onClose={this.closeModal}
          name="Restaurant"
          location={destination}
          date={
            activity ? convertDateFormat(activity.DateTime, 'YYYY-MM-DD') : ''
          }
          pax={dataRestaurant.pax}
          setRestaurant={this.setActivity}
          loadingItem={loadingItem}
        />
        <ModalAlert
          openModal={openModalAlert}
          content={messageModalAlert}
          handleClose={this.openCloseAlert}
          header="Warning"
          isArrayContentAlert={false}
        />
      </div>
    );
  }
}

modalActivityFixedPackage.propTypes = {
  openModal: PropTypes.bool,
  content: PropTypes.string,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  addMovement: PropTypes.func,
  activityType: PropTypes.string,
  activity: PropTypes.object,
  dailyProgram: PropTypes.object,
  movementList: PropTypes.array,
  index: PropTypes.number,
  day: PropTypes.number,
  action: PropTypes.string,
  setDailyProgramAfterAdd: PropTypes.func,
  closeModal: PropTypes.func,
  movements: PropTypes.array,
  listItem: PropTypes.array,
  loadingItem: PropTypes.bool,
  getExcursionAction: PropTypes.func,
  getRestaurantItemByIdAction: PropTypes.func,
  isFixedPackageManagement: PropTypes.bool,
  optionCityInCountry: PropTypes.array,
  getRestaurantByFilterAction: PropTypes.func,
  getExcursionByCityAction: PropTypes.func,
  getAirportAction: PropTypes.func,
  listAirport: PropTypes.array,
  optionAirport: PropTypes.array,
  listCityInCountry: PropTypes.array,
  positionButtonAdd: PropTypes.string,
  dayFirstCheckIn: PropTypes.number,
  loadingGetAirport: PropTypes.bool,
};

const mapStateToProps = state => {
  const listCityInCountry = state.CityStore.getCityInCountryList;
  let optionCityInCountry = listCityInCountry.map((city, i) =>
    Options(
      i,
      city.Id,
      city.Name + ', ' + city.Region.Name + ', ' + city.Country.Name
    )
  );
  const listAirport = state.AirportStore.getAirportList;
  const optionAirport = listAirport.map(e => Options(e.Id, e.Id, e.Name));
  return {
    loadingGetAirport: state.AirportStore.loading,
    movementList: state.PackageStore.activityList,
    optionCityInCountry,
    listCityInCountry,
    listAirport,
    optionAirport,
  };
};

export default connect(
  mapStateToProps,
  {
    getExcursionAction,
    getRestaurantItemByIdAction,
    getRestaurantByFilterAction,
    getExcursionByCityAction,
    getAirportAction,
  }
)(modalActivityFixedPackage);
