import React from 'react';
import { Grid, Tab } from 'semantic-ui-react';
import PropTypes from 'prop-types';
const TabItem = props => {
  return (
    <div>
      <Grid columns="equal">
        <Grid.Row stretched>
          <Grid.Column>
            <Tab
              menu={{ secondary: true, pointing: true }}
              panes={props.tabMenu}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

TabItem.propTypes = {
  historyTransaction: PropTypes.array,
  userData: PropTypes.array,
  unlockUser: PropTypes.func,
  tabMenu: PropTypes.array,
};
export default TabItem;
