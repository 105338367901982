import apiClient from '../apiClient';
import { GET_ACCOUNT_STATEMENT_URL } from '../apiUrl';

const getAccountStatementApi = (companyCode, startDate, endDate) => {
  return apiClient.get(
    GET_ACCOUNT_STATEMENT_URL(companyCode, startDate, endDate)
  );
};

export default getAccountStatementApi;
