import React, { Component } from 'react';
import { Message, Grid, Segment, Header, Icon } from 'semantic-ui-react';
// import { Collapse } from 'react-collapse';
import PropTypes from 'prop-types';
import styles from '../../../../../../components/segment/styles';
import InputAmount from '../../../../../../components/input/inputAmount';
import {
  setAdditionalItemAmount,
  setAdditionalPrice,
} from '../../../../../../scripts/additionalItem';
import CollapseButton from '../../../../../../components/button/collapseButton';
import { Collapse } from 'react-collapse';

class SegmentAdditionalItem extends Component {
  state = {
    openCollapse: false,
  };
  handleIncrease = (e, { name, value }) => {
    let supplements = [...this.props.listData];
    supplements[name].qty = value + 1;
    let newSupplements = setAdditionalItemAmount(
      supplements,
      this.props.guests
    );

    newSupplements = setAdditionalPrice(
      supplements,
      this.props.paymentTerms,
      this.props.nowPayment.PaymentPercentage
    );
    this.props.handleSupplements(newSupplements);
  };

  handleDecrease = (e, { name, value }) => {
    let supplements = [...this.props.listData];
    supplements[name].qty = value - 1 < 0 ? 0 : value - 1;
    let newSupplements = setAdditionalItemAmount(
      supplements,
      this.props.guests
    );
    this.props.handleSupplements(newSupplements);
  };

  handleChange = (e, { name, value }) => {
    let supplements = { ...this.props.listData };
    // let supplements = [...this.props.listData];
    supplements[name].qty = value === '' ? 0 : parseInt(value, 10);
    let newSupplements = setAdditionalItemAmount(
      supplements,
      this.props.guests
    );
    this.props.handleSupplements(newSupplements);
  };

  handleCollapse = () =>
    this.setState({ ...this.state, openCollapse: !this.state.openCollapse });
  render() {
    let { listData } = this.props;
    return (
      <Segment raised fluid>
        <div style={{ margin: '1em' }}>
          {/* // <Segment style={styles.segmentGoldTransparent}> */}
          <Grid verticalAlign="middle">
            <Grid.Row style={styles.noPaddingBottom}>
              <Grid.Column width={14}>
                <Header as="h3">
                  <Icon name="money" style={{ color: '#38af95' }} />
                  Additional Item
                </Header>
              </Grid.Column>
              <Grid.Column width={2} textAlign="left">
                <CollapseButton
                  isCollapse={this.state.openCollapse}
                  handleCollapse={this.handleCollapse}
                  name="openCollapse"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Collapse
            isOpened={this.state.openCollapse}
            style={{ margin: '0px' }}
          >
            <React.Fragment>
              <Grid>
                <Grid.Row columns={1}>
                  <Grid.Column textAlign="right">
                    (<span style={{ color: 'red', fontWeight: '700' }}>*</span>)
                    is mandatory item
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <hr />
              {listData && listData.length > 0 ? (
                <div>
                  <Grid>
                    <Grid.Column width={5}>
                      <h4>Input</h4>
                    </Grid.Column>
                    <Grid.Column width={5}>
                      <h4>Description</h4>
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <h4>Price / Pax</h4>
                    </Grid.Column>
                  </Grid>
                  <hr />
                  <Grid>
                    {listData.map((item, i) => (
                      <Grid.Row key={i}>
                        <Grid.Column width={5}>
                          <InputAmount
                            name={i}
                            inputStyle={styles.borderBottom}
                            value={item.qty}
                            handleIncrease={this.handleIncrease}
                            handleDecrease={this.handleDecrease}
                            handleOnChange={this.handleChange}
                            disabled={item.isMandatory}
                          />
                        </Grid.Column>
                        <Grid.Column width={5}>
                          {item.isMandatory && (
                            <span style={{ color: 'red', fontWeight: '700' }}>
                              *
                            </span>
                          )}
                          {item.name}
                        </Grid.Column>
                        <Grid.Column width={6}>
                          {item.currencyId +
                            ' ' +
                            (item.unitCost
                              ? item.unitCost.toLocaleString('id')
                              : '0')}
                        </Grid.Column>
                      </Grid.Row>
                    ))}
                  </Grid>
                </div>
              ) : (
                <Message info>
                  <p>No Additional Item Available</p>
                </Message>
              )}
              {/* </Segment> */}
            </React.Fragment>
          </Collapse>
        </div>
      </Segment>
    );
  }
}
SegmentAdditionalItem.propTypes = {
  listData: PropTypes.array,
  guests: PropTypes.object,
  paymentTerms: PropTypes.array,
  nowPayment: PropTypes.object,
  handleSupplements: PropTypes.func,
};
export default SegmentAdditionalItem;
