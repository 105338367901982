import { useState, useEffect } from 'react';
// import { convertDateFormat } from '../../../scripts/moment';
// import setDataCalendar from '../services/setDataCalendar';
import setDataCalendarOperator from '../services/helper/setDataCalendar';
import moment from 'moment';

const useApiCall = (api, startDate = null, endDate = null) => {
  // const role = localStorage.getItem('roles');
  //   const userRoles = localStorage.getItem('userRoles');
  //   const dataUserRole = userRoles ? userRoles.split(',') : [];
  //   const role = dataUserRole.find(item => item === 'Supplier')
  //     ? 'Supplier'
  //     : 'Partner';
  const theDay = moment().format('DD MMMM YYYY');
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState(null);
  const [todayBookingData, setTodayBookingData] = useState(null);

  const fetchApi = (from, to) => {
    setIsLoading(true);
    api(from, to)
      .then(result => {
        let optionsData =
          //   role === 'Supplier'
          //     ? setDataCalendar(result.data)
          // :
          setDataCalendarOperator(result.data);
        setIsLoading(false);
        setFetchedData(optionsData);
        // set today booking data
        setTodayBookingData(
          optionsData.find(item => item.DateBooking === theDay)
        );
      })
      .catch(() => {
        //eslint-disable-next-line
        // console.log(err);
        setIsLoading(false);
      });
  };

  const handleDateClick = date => {
    setTodayBookingData(fetchedData.find(item => item.DateBooking === date));
  };
  const loadData = () => {
    fetchApi(startDate, endDate);
  };

  useEffect(() => {
    fetchApi(startDate, endDate);
    //eslint-disable-next-line
  }, []);

  return [isLoading, fetchedData, todayBookingData, handleDateClick, loadData];
};

export default useApiCall;
