import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Form } from 'semantic-ui-react';
import InputWithLabel from '../../../../components/input/inputWithLabel';
const ExcurtionGeneralInfo = props => (
  <Grid columns="equal">
    <Grid.Row stretched>
      <Grid.Column width={15}>
        <Header as="h3" content="General Information" />
        <Form>
          <Form.Group>
            <Form.Field width="8">
              <InputWithLabel
                label="Excursion Name"
                placeholder="Excursion Name"
                type="text"
                name="Name"
                value={props.generalInfo.Name}
                handleChange={props.handleChange}
                required
                fluid={true}
                validate={props.errors}
                disabled={props.disablePage}
              />
            </Form.Field>
            <Form.Field width="8">
              <InputWithLabel
                label="Type"
                placeholder="Type"
                type="select"
                name="AttractionType"
                value={
                  props.generalInfo.AttractionType
                    ? props.generalInfo.AttractionType
                    : ''
                }
                handleChange={props.handleChange}
                options={props.excursionTypeList}
                required
                fluid={true}
                validate={props.errors}
                disabled={props.disablePage}
              />
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <InputWithLabel
              label="Description"
              placeholder="Description"
              type="textArea"
              name="Description"
              value={props.generalInfo.Description}
              handleChange={props.handleChange}
              required
              fluid={true}
              validate={props.errors}
              disabled={props.disablePage}
            />
          </Form.Field>
          <Form.Field>
            <InputWithLabel
              label="Long Description"
              placeholder="Long Description"
              type="textArea"
              name="LongDescription"
              value={props.generalInfo.LongDescription}
              handleChange={props.handleChange}
              fluid={true}
              disabled={props.disablePage}
            />
          </Form.Field>
          <Form.Field>
            <InputWithLabel
              label="Excursion Category"
              placeholder="Excursion Category"
              type="select"
              name="AttractionCategory"
              value={
                props.generalInfo.AttractionCategory
                  ? props.generalInfo.AttractionCategory
                  : ''
              }
              handleChange={props.handleChange}
              required
              fluid={true}
              options={props.excurtionCategory}
              validate={props.errors}
              disabled={props.disablePage}
            />
          </Form.Field>
        </Form>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);
ExcurtionGeneralInfo.propTypes = {
  generalInfo: PropTypes.shape({
    Name: PropTypes.number,
    Description: PropTypes.string,
    LongDescription: PropTypes.string,
    AttractionType: PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    }),
    AttractionCategory: PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
  handleChange: PropTypes.func,
  excursionTypeList: PropTypes.array,
  excurtionCategory: PropTypes.array,
  onChangeDropdown: PropTypes.func,
  errors: PropTypes.shape({
    Name: PropTypes.string,
    Description: PropTypes.string,
    LongDescription: PropTypes.string,
    AttractionType: PropTypes.string,
    AttractionCategory: PropTypes.string,
  }),
  disablePage: PropTypes.bool,
};
export default ExcurtionGeneralInfo;
