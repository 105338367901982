export const headerTableAdditionalCostList = [
  // 'Name',
  'Transportation Type',
  'Origin City',
  'Destination City',
  // 'Cost Type',
  'Action',
];

export const headerTablePrice = ['Name', 'Type', 'Price', 'Action'];
