import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Container, Form } from 'semantic-ui-react';
import InputWithLabel from '../../../../components/input/inputWithLabel';

const GeneralInformationGuide = props => {
  return (
    <React.Fragment>
      <Container as="h3" content="Contact Information" />
      <Form>
        <InputWithLabel
          inline={false}
          label="Address"
          type="text"
          required
          name="Address1"
          value={props.tourGuideObject.Address1}
          placeholder="Address"
          handleChange={props.handleChange}
          validate={props.errorFormTourGuide}
          disabled={props.disabled}
        />
      </Form>
      <Grid columns={2} padded="vertically">
        <Grid.Column>
          <Form>
            <InputWithLabel
              inline={false}
              label="Address 2"
              type="text"
              name="Address2"
              value={props.tourGuideObject.Address2}
              placeholder="Address 2"
              handleChange={props.handleChange}
              // validate={props.errorInformation}
              disabled={props.disabled}
            />
          </Form>
        </Grid.Column>
        <Grid.Column>
          <Form>
            <InputWithLabel
              inline={false}
              label="Address 3"
              type="text"
              name="Address3"
              value={props.tourGuideObject.Address3}
              placeholder="Address 3"
              handleChange={props.handleChange}
              // validate={props.errorInformation}
              disabled={props.disabled}
            />
          </Form>
        </Grid.Column>
      </Grid>
      <Form>
        <InputWithLabel
          inline={false}
          label="GPS Address"
          type="text"
          required
          name="Landmark"
          value={props.tourGuideObject.Landmark}
          placeholder="GPS Address"
          handleChange={props.handleChange}
          validate={props.errorFormTourGuide}
          disabled={props.disabled}
        />
      </Form>
      <Grid columns={2} padded="vertically">
        <Grid.Column>
          <Form>
            <InputWithLabel
              inline={false}
              label="Area"
              type="select"
              required
              name="AreaId"
              value={props.tourGuideObject.AreaId}
              placeholder="Nationality"
              handleChange={props.handleChange}
              options={props.listArea}
              validate={props.errorFormTourGuide}
              disabled={props.disabled}
            />
          </Form>
        </Grid.Column>
        <Grid.Column />
      </Grid>
    </React.Fragment>
  );
};

GeneralInformationGuide.propTypes = {
  tourGuideObject: PropTypes.object,
  listCountry: PropTypes.array,
  listArea: PropTypes.array,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
  errorFormTourGuide: PropTypes.object,
};
export default GeneralInformationGuide;
