import apiClient from '../apiClient';
import {
  GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID_URL,
  POST_JOIN_TOUR_ON_BE_HALF_URL,
  POST_DEMO_PRICE_URL,
  POST_DEMO_JOIN_TOUR_URL,
  GET_TRANSACTION_INVOICE_WITH_STATUS_URL,
  POST_COMMISSION_PAYMENT_URL,
  POST_JOIN_TOUR_URL,
} from '../apiUrl';

const getSupplementItemByFixedPackageIdApi = id => {
  return apiClient.get(GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID_URL(id));
};
const postJoinTourOnBehalfApi = (id, data, type) => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('tokenadmin');
  return apiClient.post(
    `${
      type === 'VariableDate'
        ? POST_JOIN_TOUR_URL
        : POST_JOIN_TOUR_ON_BE_HALF_URL
    }${type === 'VariableDate' ? 'VariableDate/' : '/'}${id}`,
    data
  );
};
const postDemoPriceApi = data => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('tokenadmin');
  return apiClient.post(POST_DEMO_PRICE_URL, data);
};
const postDemoJoinTourApi = (id, data, type) => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('tokenadmin');
  return apiClient.post(
    `${POST_DEMO_JOIN_TOUR_URL}${
      type === 'VariableDate' ? '/VariableDate/' : '/'
    }${id}`,
    data
  );
};

const getTransactionInvoiceWithStatusApi = (
  start,
  end,
  createStart,
  createEnd,
  status
) => {
  return apiClient.get(
    `${GET_TRANSACTION_INVOICE_WITH_STATUS_URL}?tourStartDate=${start}&tourUntilDate=${end}&createdStartDate=${createStart}&createdUntilDate=${createEnd}&status=${status}`
  );
};

const postCommissionPaymentApi = data => {
  return apiClient.post(POST_COMMISSION_PAYMENT_URL, data);
};

// const getTourTransactionsCommissionPaymentApi = Id => {
//   return apiClient.get(
//     `${GET_TOUR_TRANSACTIONS_COMMISSION_PAYMENT_URL}?tourTransactionId=${Id}`
//   );
// };
const getTourTransactionsCommissionPaymentApi = tourId => {
  return apiClient.get(`/TourTransactions/CompanyCommission?tourId=${tourId}`);
};
export {
  getSupplementItemByFixedPackageIdApi,
  postJoinTourOnBehalfApi,
  postDemoPriceApi,
  postDemoJoinTourApi,
  getTransactionInvoiceWithStatusApi,
  postCommissionPaymentApi,
  getTourTransactionsCommissionPaymentApi,
};
