import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Icon, Button, Message } from 'semantic-ui-react';
import Styles from './styles';

const roomService = props => {
  let { name, disable, handleChange, service } = props;
  let styleMessage, styleHeader, styleIcon, styleButton;
  if (disable) {
    styleHeader = Styles.greyColor;
    styleIcon = Styles.greyColor;
    styleButton = Styles.buttonChooseRoomDisable;
    styleMessage = Styles.messageDisable;
  } else {
    styleHeader = Styles.blackColor;
    styleIcon = Styles.goldColor;
    styleButton = Styles.buttonChooseRoom;
    styleMessage = Styles.message;
  }

  return (
    <Grid.Column disable width="4">
      <Message warning style={styleMessage}>
        <Grid>
          <Grid.Column width="16" style={Styles.paddingthree}>
            <Header as="h5" textAlign="center" style={styleHeader}>
              {name}
            </Header>
          </Grid.Column>
          <Grid.Column
            width="16"
            style={{ textAlign: 'center', padding: '10px 3px 3px 3px' }}
          >
            {name === 'Room Only' ? (
              <Icon name="bed" size="big" style={styleIcon} />
            ) : name === 'With Breakfast' ? (
              <div>
                <Icon name="bed" size="big" style={styleIcon} />
                <Icon name="food" size="big" style={styleIcon} />
              </div>
            ) : name === 'Half Board' ? (
              <div style={styleIcon}>
                <Icon name="bed" size="big" style={styleIcon} />
                <Icon name="food" size="big" style={styleIcon} />
                2x
              </div>
            ) : name === 'Full Board' ? (
              <div style={styleIcon}>
                <Icon name="bed" size="big" style={styleIcon} />
                <Icon name="food" size="big" style={styleIcon} />
                3x
              </div>
            ) : null}
          </Grid.Column>
          <Grid.Column
            width="16"
            style={{ textAlign: 'center', padding: '3px' }}
          >
            <Button
              name="room"
              value={name}
              service={service}
              size="small"
              disabled={disable}
              style={styleButton}
              onClick={handleChange}
            >
              CHOOSE ROOM
            </Button>
          </Grid.Column>
          {/* <Grid.Column width="16" style={Styles.paddingthree}>
            <p style={styleDesc}>
              Description:<br />
              {disable ? 'Not available' : desc}
            </p>
          </Grid.Column> */}
        </Grid>
      </Message>
    </Grid.Column>
  );
};

roomService.propTypes = {
  name: PropTypes.string,
  disable: PropTypes.bool,
  desc: PropTypes.string,
  handleChange: PropTypes.func,
  service: PropTypes.string,
};
export default roomService;
