import apiClient from '../apiClient';
import {
  POST_CREATE_CUSTOMER_ON_BE_HALF_URL,
  GET_LIST_TOTAL_EMPLOYEE_URL,
} from '../apiUrl';

const postCreateCustomerOnBehalfApi = data => {
  return apiClient.post(POST_CREATE_CUSTOMER_ON_BE_HALF_URL, data);
};

const getListTotalEmployeeApi = () => {
  return apiClient.get(GET_LIST_TOTAL_EMPLOYEE_URL);
};

export { postCreateCustomerOnBehalfApi, getListTotalEmployeeApi };
