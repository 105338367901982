import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Grid } from 'semantic-ui-react';
import Filter from '../modalSelectionMasterData/formFilter';
import ListCard from './listCard';
import styles from './../styles';
import ButtonClose from '../../button/buttonCloseModal';
import {
  resetRestaurantAction,
  getRestaurantByFilterAction,
  //   getRestaurantByFilter,
  //   resetStatusRestaurant,
  //   resetStatusRestaurantsByFilter,
} from './../../../actions/restaurantAction';
class modalListRestaurant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formFilter: [],
      data: [],
      searchName: '',
      activePage: 1,
    };
  }
  componentDidUpdate = () => {
    if (
      this.props.getRestaurantProfileSpecializationsStatus === 'success' &&
      this.props.menuClassesStatus === 'success'
    ) {
      let data = [
        {
          name: 'Restaurant Classes',
          data: this.props.menuClasses,
        },
        {
          name: 'Restaurant Speciality',
          data: this.showOptions(this.props.restaurantSpecializations),
        },
      ];
      this.setState({
        ...this.state,
        formFilter: data,
      });
      this.props.resetRestaurantAction();
    }
    // if (this.props.restaurantByFilterStatus === 'success') {
    //   this.setState({
    //     ...this.state,
    //     data: this.props.listRestaurant,
    //   });
    //   this.props.resetStatusRestaurantsByFilter();
    // }
  };
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    name: PropTypes.string,
    location: PropTypes.string,
    date: PropTypes.string,
    pax: PropTypes.number,
    resetStatusRestaurant: PropTypes.func,
    listRestaurantSpecialization: PropTypes.array,
    restaurantSpecializationsStatus: PropTypes.string,
    listRestaurantMenuClass: PropTypes.array,
    restaurantMenuClassesStatus: PropTypes.string,
    resetStatusRestaurantsByFilter: PropTypes.func,
    restaurantByFilterStatus: PropTypes.string,
    listRestaurant: PropTypes.array,
    getRestaurantByFilterAction: PropTypes.func,
    loading: PropTypes.bool,
    loadingRestaurantByFilter: PropTypes.bool,
    setRestaurant: PropTypes.func,
    //=====================
    getRestaurantProfileSpecializationsStatus: PropTypes.string,
    menuClassesStatus: PropTypes.string,
    menuClasses: PropTypes.array,
    restaurantSpecializations: PropTypes.array,
    resetRestaurantAction: PropTypes.func,
    loadingFilter: PropTypes.bool,
    cityId: PropTypes.string,
  };
  showOptions = data => {
    return data.map(e => {
      return { key: e.Id, text: e.Name, checked: false };
    });
  };
  filteredData = formFilter => {
    let { date, pax, cityId } = this.props;
    let specializationId, menuClassId, data;
    formFilter.map(filter =>
      filter.name === 'Restaurant Classes'
        ? (menuClassId = filter.data
            .filter(e => e.checked)
            .map(e => e.key)
            .join(','))
        : (specializationId = filter.data
            .filter(e => e.checked)
            .map(e => e.key)
            .join(','))
    );
    data = {
      cityId,
      ratingIds: '',
      locationIds: '',
      specializationId,
      menuClassId,
      facilityIds: '',
      requestedDate: date,
      pax,
    };
    return this.props.getRestaurantByFilterAction(data);
  };
  handleChangeCheckbox = (e, { name, value }) => {
    let dataList = this.state.formFilter;
    let indexForm = dataList.findIndex(e => e.name === name);
    let indexData = dataList[indexForm].data.findIndex(e => e.key === value);
    dataList[indexForm].data[indexData].checked = !dataList[indexForm].data[
      indexData
    ].checked;
    this.setState({ ...this.state, formFilter: dataList });
    this.filteredData(this.state.formFilter);
  };
  handleResetFilter = () => {
    let { formFilter } = this.state;
    formFilter = formFilter.map(filter =>
      filter.data.some(e => e.checked)
        ? { name: filter.name, data: this.resetFilter(filter.data) }
        : filter
    );
    this.setState({ ...this.state, formFilter });
    this.filteredData(formFilter);
  };
  resetFilter = listData => {
    return listData.map(data => {
      return {
        key: data.key,
        text: data.text,
        checked: false,
        desc: data.desc,
      };
    });
  };
  filterByName = (listRestaurant, searchName) => {
    return listRestaurant.filter(
      data => data.Name.toLowerCase().indexOf(searchName.toLowerCase()) !== -1
    );
  };
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };

  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });

  handleSetRestaurant = data => {
    this.setState({ activePage: 1 });
    this.props.setRestaurant(data);
  };
  render() {
    let {
      open,
      onClose,
      name,
      location,
      loading,
      //   loadingRestaurantByFilter,
      listRestaurant,
      // setRestaurant,
      //loadingItem,
      loadingFilter,
    } = this.props;
    let { formFilter, searchName, activePage } = this.state;
    listRestaurant = this.filterByName(listRestaurant, searchName);
    return (
      <Modal open={open} onClose={onClose} size="small" closeOnDimmerClick>
        <Modal.Header style={styles.backgroundModal}>
          {name} <ButtonClose onClose={onClose} />
        </Modal.Header>
        <Modal.Content style={styles.backgroundModal}>
          <Modal.Description>
            <Grid>
              <Grid.Column width={5}>
                <Filter
                  loading={loading}
                  formFilter={formFilter}
                  handleChange={this.handleChangeCheckbox}
                  resetFilter={this.handleResetFilter}
                />
              </Grid.Column>
              <Grid.Column width={11}>
                <ListCard
                  location={location}
                  data={listRestaurant}
                  //loading={loadingRestaurantByFilter}
                  loading={loadingFilter}
                  searchName={searchName}
                  activePage={activePage}
                  handleChange={this.handleChange}
                  pageChange={this.pageChange}
                  setRestaurant={this.handleSetRestaurant}
                />
              </Grid.Column>
            </Grid>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}
const mapStateToProps = state => {
  return {
    // listRestaurantSpecialization: state.restaurant.restaurantSpecializations,
    // restaurantSpecializationsStatus:
    //   state.restaurant.getRestaurantSpecializationsStatus,
    // listRestaurantMenuClass: state.restaurant.restaurantMenuClasses.map(e => {
    //   return { key: e, text: e + ' Dinning', checked: false };
    // }),
    // restaurantMenuClassesStatus:
    //   state.restaurant.getRestaurantMenuClassesStatus,
    // listRestaurant: state.restaurant.restaurants,
    // restaurantByFilterStatus: state.restaurant.getRestaurantByFilterStatus,
    // loading: state.restaurant.loading,
    // loadingRestaurantByFilter: state.restaurant.loadingRestaurantByFilter,
    listRestaurant: state.RestaurantStore.restaurantByCityDateAndPax,
    restaurantSpecializations: state.RestaurantStore.restaurantSpecializations,
    getRestaurantProfileSpecializationsStatus:
      state.RestaurantStore.getRestaurantProfileSpecializationsStatus,
    menuClassesStatus: state.RestaurantStore.menuClassesStatus,
    menuClasses: state.RestaurantStore.menuClasses.map(e => {
      return { key: e, text: e + ' Dinning', checked: false };
    }),
    loading: state.RestaurantStore.loading,
    loadingFilter: state.RestaurantStore.loadingFilter,
  };
};
export default connect(
  mapStateToProps,
  {
    resetRestaurantAction,
    getRestaurantByFilterAction,
    // resetStatusRestaurant,
    // resetStatusRestaurantsByFilter,
    // getRestaurantByFilter,
  }
)(modalListRestaurant);
