import * as types from '../actions/actionTypes';

const initialState = {
  loading: false,
  listGuestTitleType: [],
  listIdentityType: [],
  errors: {},
  guests: { adults: 0, childs: 0, infants: 0 },
  guestList: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_GUEST_TITLE_TYPE:
      return { ...state };
    case types.GET_GUEST_TITLE_TYPE_PENDING:
      return { ...state, loading: true };
    case types.GET_GUEST_TITLE_TYPE_FULFILLED:
      return {
        ...state,
        listGuestTitleType: action.payload.data,
        loading: false,
      };
    case types.GET_GUEST_TITLE_TYPE_REJECTED:
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.response.data.error_description },
      };

    case types.GET_IDENTITY_TYPE:
      return { ...state };
    case types.GET_IDENTITY_TYPE_PENDING:
      return { ...state, loading: true };
    case types.GET_IDENTITY_TYPE_FULFILLED:
      return {
        ...state,
        listIdentityType: action.payload.data,
        loading: false,
      };
    case types.GET_IDENTITY_TYPE_REJECTED:
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.response.data.error_description },
      };
    default:
      return state;
  }
};

export default reducer;
