import * as types from '../actions/actionTypes';
import { checkUser } from '../scripts/authorization';
const initialState = {
  loading: false,
  getUserAccessList: [],
  getLockedUserList: [],
  getUserProfileByUserId: [],
  postResetPassword: '',
  getUserAccessListStatus: '',
  getLockedUserListStatus: '',
  postUnlockedUserStatus: '',
  getUserProfileByUserIdStatus: '',
  postResetPasswordStatus: '',
  getUserRoleByBearerStatus: '',
  getUserRoleByBearer: {},
  getUserProfileOnlyById: [],
  getUserProfileOnlyByIdStatus: '',
  errors: {},
  isSales: false,
  isAdministrator: false,
  isOperation: false,
  isFinance: false,
  isFinanceAP: false,
  isFinanceAR: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USER_ACCESS_LIST:
      return { ...state };
    case types.GET_USER_ACCESS_LIST_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getUserAccessListStatus: 'failed',
      };
    case types.GET_USER_ACCESS_LIST_PENDING:
      return { ...state, loading: true };
    case types.GET_USER_ACCESS_LIST_FULFILLED:
      return {
        ...state,
        getUserAccessList: action.payload.data,
        loading: false,
        getUserAccessListStatus: 'success',
      };

    case types.GET_LOCKED_USER_LIST:
      return { ...state };
    case types.GET_LOCKED_USER_LIST_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getLockedUserListStatus: 'failed',
      };
    case types.GET_LOCKED_USER_LIST_PENDING:
      return { ...state, loading: true };
    case types.GET_LOCKED_USER_LIST_FULFILLED:
      return {
        ...state,
        getLockedUserList: action.payload.data,
        loading: false,
        getLockedUserListStatus: 'success',
      };

    case types.POST_UNLOCK_USER:
      return { ...state };
    case types.POST_UNLOCK_USER_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        postUnlockedUserStatus: 'failed',
      };
    case types.POST_UNLOCK_USER_PENDING:
      return { ...state, loading: true };
    case types.POST_UNLOCK_USER_FULFILLED:
      return {
        ...state,
        loading: false,
        postUnlockedUserStatus: 'success',
      };

    case types.GET_USER_PROFILE_BY_USER_ID:
      return { ...state };
    case types.GET_USER_PROFILE_BY_USER_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getUserProfileByUserIdStatus: 'failed',
      };
    case types.GET_USER_PROFILE_BY_USER_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_USER_PROFILE_BY_USER_ID_FULFILLED:
      return {
        ...state,
        getUserProfileByUserId: action.payload.data,
        loading: false,
        getUserProfileByUserIdStatus: 'success',
      };

    case types.POST_RESET_PASSWORD:
      return { ...state };
    case types.POST_RESET_PASSWORD_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        postResetPasswordStatus: 'failed',
      };
    case types.POST_RESET_PASSWORD_PENDING:
      return { ...state, loading: true };
    case types.POST_RESET_PASSWORD_FULFILLED:
      return {
        ...state,
        postResetPassword: action.payload.data,
        loading: false,
        postResetPasswordStatus: 'success',
      };
    case types.RESET_STATUS_USER_ACCESS:
      return {
        ...state,
        getLockedUserListStatus: '',
        getUserAccessListStatus: '',
        postUnlockedUserStatus: '',
        getUserProfileByUserIdStatus: '',
        postResetPasswordStatus: '',
        getUserRoleByBearerStatus: '',
        getUserProfileOnlyByIdStatus: '',
      };

    case types.GET_USER_ROLE_BY_BEARER:
      return { ...state };
    case types.GET_USER_ROLE_BY_BEARER_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getUserRoleByBearerStatus: 'failed',
        isSales: false,
        isAdministrator: false,
        isOperation: false,
        isFinance: false,
        isFinanceAP: false,
        isFinanceAR: false,
      };
    case types.GET_USER_ROLE_BY_BEARER_PENDING:
      return {
        ...state,
        loading: true,
        isSales: false,
        isAdministrator: false,
        isOperation: false,
        isFinance: false,
        isFinanceAP: false,
        isFinanceAR: false,
      };
    case types.GET_USER_ROLE_BY_BEARER_FULFILLED: {
      localStorage.setItem('companyRoles', action.payload.data.Roles);
      let autorization = checkUser(action.payload.data.Roles);
      return {
        ...state,
        loading: false,
        getUserRoleByBearerStatus: 'success',
        getUserRoleByBearer: action.payload.data,
        isSales: autorization.isSales,
        isAdministrator: autorization.isAdministrator,
        isOperation: autorization.isOperation,
        isFinance: autorization.isFinance,
        isFinanceAP: autorization.isFinanceAP,
        isFinanceAR: autorization.isFinanceAR,
      };
    }
    case types.GET_USER_PROFILE_ONLY_BY_USER_ID:
      return { ...state };
    case types.GET_USER_PROFILE_ONLY_BY_USER_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getUserProfileOnlyByIdStatus: 'failed',
      };
    case types.GET_USER_PROFILE_ONLY_BY_USER_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_USER_PROFILE_ONLY_BY_USER_ID_FULFILLED:
      return {
        ...state,
        getUserProfileOnlyById: action.payload.data,
        loading: false,
        getUserProfileOnlyByIdStatus: 'success',
      };

    default:
      return state;
  }
};

export default reducer;
