import React, { Component } from 'react';
import { Segment, Grid, Input, Button, Container } from 'semantic-ui-react';
import { getTransactionInvoiceWithStatus } from '../../../actions/tourTransactionAction';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import HeaderTitle from '../../shared/components/common/headerSegment/HeaderTitle';
import ModalDateRange from '../../shared/components/modal/ModalDateRange';
import { convertDateFormat, addDate } from '../../../scripts/moment';
import TableCompanyCommission from './table/TableCompanyCommission';
import ModalPay from '../../../components/modal/modalPayCommission';
import Pagination from '../../../components/common/pagination';
import FilterData from '../../../scripts/filterDataTable';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../scripts/pagination';
import { getUserRoleByBearerAction } from '../../../actions/userAccessAction';
import { checkUser } from '../../../scripts/authorization';
import moment from 'moment';
let TODAY = new Date();
class SegmentCompanyCommission extends Component {
  state = {
    filterByDate: {
      from: TODAY,
      to: addDate(TODAY, 30, 'd'),
      createStartDate: '',
      createEndDate: '',
      tourStartDate: moment(new Date()).format('YYYY-MM-DD'),
      tourEndDate: moment()
        .add(30, 'd')
        .format('YYYY-MM-DD'),
    },
    modalFilterDateOpen: false,
    searchInput: '',
    headerTable: [
      'Booking Number',
      'Tour Name',
      'Company Name',
      'Tour Date',
      'Status',
      'Total Paid',
      'Must Be Paid',
      'Action',
    ],
    listTransaction: [],
    activePage: 1,
    radioValueDateRange: '1',
    isFinance: null,
  };

  componentDidMount() {
    let {
      tourStartDate,
      tourEndDate,
      createEndDate,
      createStartDate,
    } = this.state.filterByDate;
    this.props
      .getTransactionInvoiceWithStatus(
        tourStartDate,
        tourEndDate,
        createStartDate,
        createEndDate,
        'Done'
      )
      .then(() => this.setListTransaction(this.props.listTransaction));
    this.props.getUserRoleByBearerAction().then(() => {
      let authorization = checkUser(this.props.userRoles.Roles);
      this.setState({ ...this.state, isFinance: authorization.isFinance });
    });
  }

  setListTransaction = listTransaction => {
    this.setState({
      ...this.state,
      listTransaction,
    });
  };

  handleModalFilter = () =>
    this.setState(state => ({
      ...state,
      modalFilterDateOpen: !state.modalFilterDateOpen,
    }));

  // handleChange = (e, { name, value }) => {
  //   this.setState({ ...this.state, [name]: value });
  // };

  applyFilter = id => {
    const value = this.state.radioValueDateRange;
    const { from, to } = id;
    let filterByDate = { ...this.state.filterByDate };
    if (value === '1') {
      this.filterDateRangeFunc(
        convertDateFormat(from, 'YYYY-MM-DD'),
        convertDateFormat(to, 'YYYY-MM-DD'),
        '',
        ''
      );
      filterByDate.tourStartDate = convertDateFormat(from, 'YYYY-MM-DD');
      filterByDate.tourEndDate = convertDateFormat(to, 'YYYY-MM-DD');
      filterByDate.createStartDate = '';
      filterByDate.createEndDate = '';
    } else if (value === '2') {
      this.filterDateRangeFunc(
        '',
        '',
        convertDateFormat(from, 'YYYY-MM-DD'),
        convertDateFormat(to, 'YYYY-MM-DD')
      );
      filterByDate.tourStartDate = '';
      filterByDate.tourEndDate = '';
      filterByDate.createEndDate = convertDateFormat(to, 'YYYY-MM-DD');
      filterByDate.createStartDate = convertDateFormat(from, 'YYYY-MM-DD');
    }
    this.setState({ ...this.state, filterByDate, activePage: 1 }, async () => {
      await this.handleModalFilter();
    });
  };
  handleModalPay = (e, { value }) => {
    const totalPax = value.AdultPax + value.ChildPax + value.InfantPax;
    this.setState({
      openModalPay: !this.state.openModalPay,
      cashBack: value ? value.OutstandingStaffCashback * totalPax : 0,
      tourTransactionId: value.BookingId,
    });
  };

  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  handleCloseModal = () => {
    this.setState({
      openModalPay: false,
    });
  };
  handleRadioDateRange = value => {
    this.setState({ radioValueDateRange: value });
  };

  filterDateRangeFunc = (
    tourStartDate,
    tourEndDate,
    createStartDate,
    createEndDate
  ) => {
    this.props
      .getTransactionInvoiceWithStatus(
        tourStartDate,
        tourEndDate,
        createStartDate,
        createEndDate,
        'Done'
      )
      .then(() => this.setListTransaction(this.props.listTransaction));
  };
  render() {
    const {
      modalFilterDateOpen,
      searchInput,
      filterByDate,
      openModalPay,
      listTransaction,
      headerTable,
      activePage,
      isFinance,
    } = this.state;

    let filterData = FilterData(
      listTransaction,
      'bookingConfirm',
      this.state.searchInput
    );
    //let filterData = this.filteredData(this.props.data);
    let pagedData = datasetPagination(filterData, 5);
    let listData = pagedData[this.state.activePage - 1];
    const props = this.props;

    let { numbersFrom, numbersTo } = getNumberFromTo(
      listData,
      this.state.activePage,
      5
    );
    const { loadingGetTransaction } = this.props;
    let createEndDate =
      filterByDate.createEndDate === ''
        ? ''
        : moment(filterByDate.createEndDate).format('DD MMM YYYY');
    let createStartDate =
      filterByDate.createStartDate === ''
        ? ''
        : moment(filterByDate.createStartDate).format('DD MMM');
    let tourStartDate =
      filterByDate.tourStartDate === ''
        ? ''
        : moment(filterByDate.tourStartDate).format('DD MMM');
    let tourEndDate =
      filterByDate.tourEndDate === ''
        ? ''
        : moment(filterByDate.tourEndDate).format('DD MMM YYYY');
    return (
      <div style={{ margin: '25px 0px' }}>
        <Grid columns="equal">
          <Grid.Row stretched>
            <Grid.Column width={15}>
              <Segment>
                <HeaderTitle title="Company Commission" />
                <Input
                  icon="search"
                  placeholder="search"
                  name="searchInput"
                  value={searchInput}
                  onChange={this.handleChange}
                />
                <Button
                  icon="filter"
                  basic
                  size="small"
                  floated="right"
                  positive
                  onClick={this.handleModalFilter}
                />
                <div
                  style={{
                    marginTop: '-2.9em',
                    marginRight: '5em',
                    fontWeight: 'bold',
                    marginBottom: '3em',
                  }}
                >
                  {/* {(tourStartDate !== '' || tourEndDate !== '') && ( */}
                  <Container
                    content={
                      'Start tour range date : ' +
                      tourStartDate +
                      ' - ' +
                      tourEndDate
                    }
                    textAlign="right"
                  />
                  {/* )}
                  {(createStartDate !== '' || createEndDate !== '') && ( */}
                  <Container
                    content={
                      'Created tour range date : ' +
                      createStartDate +
                      ' - ' +
                      createEndDate
                    }
                    textAlign="right"
                  />
                  {/* )} */}
                </div>
                <TableCompanyCommission
                  data={props.data}
                  loading={loadingGetTransaction}
                  listTransaction={listData}
                  headerTable={headerTable}
                  handleModalPay={this.handleModalPay}
                  isFinance={isFinance}
                />
                <Pagination
                  pagedData={pagedData}
                  numbersTo={numbersTo}
                  numbersFrom={numbersFrom}
                  data={listTransaction}
                  pageChange={this.pageChange}
                  activePage={activePage}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <ModalDateRange
          actionDateRange={this.applyFilter}
          openModal={modalFilterDateOpen}
          handleClose={this.handleModalFilter}
          dateRange={this.state.filterByDate}
          contentHeader="Filter Company Commission"
          value={this.state.radioValueDateRange}
          handleRadioDateRange={this.handleRadioDateRange}
        />
        <ModalPay
          openModal={openModalPay}
          handleClose={this.handleCloseModal}
          cashBack={this.state.cashBack}
          tourTransactionId={this.state.tourTransactionId}
          filterDateRangeFunc={this.filterDateRangeFunc}
          filterByDate={filterByDate}
        />
      </div>
    );
  }
}
SegmentCompanyCommission.propTypes = {
  data: PropTypes.object,
  getTransactionInvoiceWithStatus: PropTypes.func,
  listTransaction: PropTypes.array,
  loading: PropTypes.bool,
  loadingGetTransaction: PropTypes.bool,
  getUserRoleByBearerAction: PropTypes.func,
};
function mapStateToProps(state) {
  return {
    loadingGetTransaction:
      state.TourTransactionStore.loadingGetTransactionInvoiceWithStatus,
    listTransaction:
      state.TourTransactionStore.listTransactionInvoiceWithStatus,
    userRoles: state.UserAccessStore.getUserRoleByBearer,
  };
}
export default connect(mapStateToProps, {
  getTransactionInvoiceWithStatus,
  getUserRoleByBearerAction,
})(SegmentCompanyCommission);
