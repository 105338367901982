import { getTime } from '../../../../../scripts/moment';
const copyObject = obj => {
  let newObj = JSON.stringify(obj);
  return JSON.parse(newObj);
};

const validationPrice = (tourGuidePrice, errorPrice) => {
  let NewErrorPrice = copyObject(errorPrice);
  NewErrorPrice.Currency.error = tourGuidePrice.Currency === '';
  NewErrorPrice.Hours.error = tourGuidePrice.Hours === '';
  NewErrorPrice.TourGuideLanguage.error =
    tourGuidePrice.TourGuideLanguage === '';
  NewErrorPrice.StartDate.error =
    getTime(tourGuidePrice.StartDate) >= getTime(tourGuidePrice.EndDate);
  NewErrorPrice.EndDate.error =
    getTime(tourGuidePrice.EndDate) <= getTime(tourGuidePrice.StartDate);
  return NewErrorPrice;
};

export { validationPrice };
