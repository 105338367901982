const setCountryState = data => {
  const { Id, Name, IsTourRelevant } = data;
  let countryById = {
    savingStatus: 'Edit',
    CountryId: Id ? Id : '',
    Name: Name ? Name : '',
    IsTourRelevant: IsTourRelevant ? IsTourRelevant : false,
  };
  return countryById;
};
const setPostCountryState = (id, data) => {
  let postCountry = {
    CountryId: id,
    Name: data.Name,
    IsTourRelevant: data.IsTourRelevant,
    PaymentGateawayChoice: data.PaymentGateawayChoice,
  };
  return postCountry;
};
export { setCountryState, setPostCountryState };
