import React from 'react';
import { Dimmer } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {
  resetCityGroupAction,
  getCityGroupAction,
  deleteCityGroupAction,
  postCityGroupAction,
  putCityGroupAction,
  getCityGroupByIdAction,
  getCityInCountryAction,
  postCityGroupDetailAction,
  postCityGroupDetailFullAddAction,
} from './cityGroupAction';
import { getCountryListAction } from '../shared/actions/countryAction';
import ModalAlert from '../../components/modal/modalAlert';
import Loader from '../../components/loader';
import CityGroupList from './components/CityGroupListComponent';
import PropTypes from 'prop-types';
import ModalCityGroup from './components/modal/ModalCityGroup';
import ModalConfirm from '../../components/modal/modalConfirmationTwoButton';
class CityGroupPages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cityGroupList: [],
      cityGroupById: {},
      cityGroupDetailById: {},
      loadingCityGroup: true,
      failedCityGroup: false,
      modalOpen: false,
      modalCityGroup: false,
      modalAlert: '',
      error: {},
      openModalConfirm: false,
      confirmMessage: '',
      deletedId: null,
    };
  }
  static propTypes = {
    getCityGroupAction: PropTypes.func,
    resetCityGroupAction: PropTypes.func,
    listCountry: PropTypes.array,
    listCityInCountry: PropTypes.array,
    deleteCityGroupAction: PropTypes.func,
    getCityGroupByIdAction: PropTypes.func,
    postCityGroupAction: PropTypes.func,
    putCityGroupAction: PropTypes.func,
    getCityGroupByIdStatus: PropTypes.string,
    postCityGroupStatus: PropTypes.string,
    putCityGroupStatus: PropTypes.string,
    deleteCityGroupStatus: PropTypes.string,
    getCityGroupById: PropTypes.array,
    getCityInCountryAction: PropTypes.func,
    getCountryListAction: PropTypes.func,
    getCityGroupListStatus: PropTypes.string,
    getCityGroupList: PropTypes.array,
    postCityGroupDetailAction: PropTypes.func,
    getCityGroupId: PropTypes.array,
    postCityGroupDetailFullAddAction: PropTypes.func,
    loading: PropTypes.bool,
    postCityGroupDetailFullAddStatus: PropTypes.string,
  };

  handleOpen = () => this.setState({ modalOpen: true });
  handleClose = () => this.setState({ modalOpen: false, modalAlert: false });
  handleCloseModal = () => this.setState({ modalCityGroup: false });
  componentDidMount = () => {
    this.props.getCityGroupAction();
    this.props.getCountryListAction();
  };
  onChange = (e, { value, name }) => {
    const cityGroupById = this.state.cityGroupById;
    cityGroupById[name] = value;
    this.setState({ cityGroupById: cityGroupById });
    const getRegion = name === 'CountryId';
    if (getRegion) {
      this.props.getCityInCountryAction(value);
    }
    this.validate();
  };
  componentDidUpdate() {
    if (this.props.getCityGroupListStatus === 'success') {
      this.setState({
        cityGroupList: this.props.getCityGroupList,
        loadingCityGroup: false,
      });
      this.props.resetCityGroupAction();
    } else if (this.props.getCityGroupListStatus === 'failed') {
      this.setState({
        loadingCityGroup: false,
        failedCityGroup: true,
        headerAlert: 'Failed',
        contentAlert:
          'Please check your internet connection and Refresh Page [Ctrl+R]',
      });
      this.props.resetCityGroupAction();
    }
    if (this.props.getCityGroupByIdStatus === 'success') {
      this.setStateCity(this.props.getCityGroupById);
      this.props.resetCityGroupAction();
    } else if (this.props.getCityGroupByIdStatus === 'failed') {
      this.props.resetCityGroupAction();
    }
    if (this.props.postCityGroupStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Save city group successfully',
        modalAlert: true,
      });
      this.setGroupDetailHeader(this.props.getCityGroupId);
      this.props.resetCityGroupAction();
      this.props.getCityGroupAction();
    } else if (this.props.postCityGroupStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: 'Saving City Group Failed',
        modalAlert: true,
      });
      this.props.resetCityGroupAction();
      this.props.getCityGroupAction();
    }
    if (this.props.putCityGroupStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Edit City Group Succesfully',
        modalAlert: true,
      });
      this.props.resetCityGroupAction();
      this.props.getCityGroupAction();
    } else if (this.props.putCityGroupStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: 'Edit City Group Failed',
        modalAlert: true,
      });
      this.props.resetCityGroupAction();
      this.props.getCityGroupAction();
    }
    if (this.props.deleteCityGroupStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Delete City Group Succesfully',
        modalAlert: true,
      });
      this.props.resetCityGroupAction();
      window.location.reload();
      //this.props.getCityGroupAction();
    } else if (this.props.deleteCityGroupStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: 'Delete City Group Failed',
        modalAlert: true,
      });
      this.props.resetCityGroupAction();
      this.props.getCityGroupAction();
    }
    if (this.props.postCityGroupDetailFullAddStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Saving City Group Succesfully',
      });
      this.props.resetCityGroupAction();
      this.props.getCityGroupAction();
    } else if (this.props.postCityGroupDetailFullAddStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: 'Saving City Group Failed',
        modalAlert: true,
      });
      this.props.resetCityGroupAction();
      this.props.getCityGroupAction();
    }
  }

  setGroupDetailHeader = data => {
    const { headerModalCityGroup, cityGroupById } = this.state;
    let cityGroupDetailById = {};
    if (headerModalCityGroup === ' Edit City Group') {
      cityGroupDetailById = {
        Id: data.Id,
        Name: data.Name,
        CountryId: data.CountryId,
        Cities: data.Cities.map(city => {
          return {
            CityId: city,
          };
        }),
      };
      this.props.postCityGroupDetailFullAddAction(
        cityGroupById.Id,
        cityGroupDetailById
      );
    } else {
      let IdDetail = data.Id;
      cityGroupDetailById = {
        Id: data.Id,
        Name: data.Name,
        CountryId: data.Country.Value,
        Cities: this.state.cityGroupById.Cities.map(city => {
          return {
            CityId: city,
          };
        }),
      };
      this.props.postCityGroupDetailFullAddAction(
        IdDetail,
        cityGroupDetailById
      );
    }
  };
  setStateCity = data => {
    const { Id, Name, Country, Cities } = data;
    let cityGroupById = {
      Id: Id ? Id : '',
      Name: Name ? Name : '',
      CountryId: Country.Value ? Country.Value : '',
      Cities: Cities.map(type => {
        return type.Value;
      }),
    };
    this.props.getCityInCountryAction(Country.Value);
    this.setState({ cityGroupById: cityGroupById });
  };
  newCity = () => {
    this.setState({
      modalCityGroup: true,
      headerModalCityGroup: ' Add City Group',
      cityGroupById: {
        Id: '',
        Name: '',
        CountryId: '',
        Cities: '',
      },
      error: {},
    });
    this.props.getCityInCountryAction();
  };
  editCity = e => {
    this.props.getCityGroupByIdAction(e.target.value);
    this.setState({
      modalCityGroup: true,
      headerModalCityGroup: ' Edit City Group',
      error: {},
      cityGroupById: {
        Id: '',
        Name: '',
        CountryId: '',
        Cities: '',
      },
    });
  };
  deleteCity = e => {
    // eslint-disable-next-line
    let name = this.state.cityGroupList.find(item => item.Id == e.target.value)
      .Name;
    this.setState({
      ...this.state,
      openModalConfirm: true,
      confirmMessage: `Are you sure want to delete City Group ${name}?`,
      deletedId: e.target.value,
    });
  };
  handleDelete = () => {
    this.props.deleteCityGroupAction(this.state.deletedId);
    this.closeModalConfirm();
  };
  closeModalConfirm = () => {
    this.setState({ ...this.state, openModalConfirm: false });
  };
  saveCity = () => {
    const err = this.validate();
    if (!err) {
      const Id = this.state.cityGroupById.Id;
      const data = this.state.cityGroupById;
      if (Id === '') {
        this.props.postCityGroupAction(data);
      } else {
        //this.props.putCityGroupAction(Id, data);
        //this.props.postCityGroupAction(data);
        this.setGroupDetailHeader(data);
      }
      this.setState({ modalCityGroup: false });
    }
  };
  /*   saveCityHeader = () => {
      const IdDetail = this.state.cityGroupDetailById.Id;
      const dataDetail = this.state.cityGroupDetailById;
      this.props.postCityGroupDetailFullAddAction(IdDetail, dataDetail);
    };
  
    saveCityGroupAndCityGroupDetail = () => {
      this.saveCity();
      this.saveCityHeader();
    }; */
  validate = () => {
    let isError = false;
    const errors = {
      Name: { error: false, message: '' },
      Cities: { error: false, message: '' },
      CountryId: { error: false, message: '' },
    };
    if (this.state.cityGroupById.Name.length < 1) {
      isError = true;
      errors.Name.error = true;
      errors.Name.message = "City Group Name Can't Be Empty";
    }
    if (this.state.cityGroupById.Cities.length < 1) {
      isError = true;
      errors.Cities.error = true;
      errors.Cities.message = "City Can't Be Empty";
    }
    if (this.state.cityGroupById.CountryId.length < 1) {
      isError = true;
      errors.CountryId.error = true;
      errors.CountryId.message = "Country Can't Be Empty";
    }
    this.setState({
      error: {
        ...errors,
      },
    });
    return isError;
  };
  render() {
    let {
      // loadingCityGroup,
      failedCityGroup,
      modalAlert,
      openModalConfirm,
      confirmMessage,
    } = this.state;
    let { loading } = this.props;
    //let loadings = loadingCityGroup || loading;
    let openModal = failedCityGroup || modalAlert;
    let handleConfirm = this.handleDelete;
    return (
      <div>
        <Dimmer.Dimmable blurring dimmed={loading}>
          <Loader loading={loading} />
          <CityGroupList
            data={this.state.cityGroupList}
            type="cityGroupList"
            filterBy="Name"
            buttonNew={this.newCity}
            butttonEdit={this.editCity}
            buttonDelete={this.deleteCity}
          />
          <ModalAlert
            openModal={openModal}
            handleClose={this.handleClose}
            header={this.state.headerAlert}
            content={this.state.contentAlert}
            nameButton="OK"
          />
          <ModalCityGroup
            open={this.state.modalCityGroup}
            handleClose={this.handleCloseModal}
            header={this.state.headerModalCityGroup}
            listCountry={this.props.listCountry}
            listCityInCountry={this.props.listCityInCountry}
            handleChange={this.onChange}
            contentField={this.state.cityGroupById}
            saveCity={this.saveCity}
            errorInformation={this.state.error}
            test={this.saveCityHeader}
            loading={loading}
          />
          <ModalConfirm
            openModal={openModalConfirm}
            size="mini"
            close={this.closeModalConfirm}
            confirm={handleConfirm}
            message={confirmMessage}
            header="Confirmation"
          />
        </Dimmer.Dimmable>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    getCityGroupList: state.CityGroupPageStore.getCityGroupList,
    getCityGroupListStatus: state.CityGroupPageStore.getCityGroupListStatus,
    getCityGroupById: state.CityGroupPageStore.getCityGroupById,
    getCityGroupByIdStatus: state.CityGroupPageStore.getCityGroupByIdStatus,
    postCityGroupStatus: state.CityGroupPageStore.postCityGroupStatus,
    putCityGroupStatus: state.CityGroupPageStore.putCityGroupStatus,
    deleteCityGroupStatus: state.CityGroupPageStore.deleteCityGroupStatus,
    listCountry: state.CountryStore.getCountryList.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    listCityInCountry: state.CityGroupPageStore.getCityInCountry.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    getCityGroupId: state.CityGroupPageStore.getCityGroupId,
    loading: state.CityGroupPageStore.loading,
    postCityGroupDetailFullAddStatus:
      state.CityGroupPageStore.postCityGroupDetailFullAddStatus,
  };
}
export default connect(mapStateToProps, {
  resetCityGroupAction,
  getCityGroupAction,
  deleteCityGroupAction,
  postCityGroupAction,
  putCityGroupAction,
  getCityGroupByIdAction,
  getCityInCountryAction,
  getCountryListAction,
  postCityGroupDetailAction,
  postCityGroupDetailFullAddAction,
})(CityGroupPages);
