const GET_TRANSPORTATION_UNIT_HEADER_BY_ID =
  'GET_TRANSPORTATION_UNIT_HEADER_BY_ID';
const GET_TRANSPORTATION_UNIT_HEADER_BY_ID_PENDING =
  'GET_TRANSPORTATION_UNIT_HEADER_BY_ID_PENDING';
const GET_TRANSPORTATION_UNIT_HEADER_BY_ID_FULFILLED =
  'GET_TRANSPORTATION_UNIT_HEADER_BY_ID_FULFILLED';
const GET_TRANSPORTATION_UNIT_HEADER_BY_ID_REJECTED =
  'GET_TRANSPORTATION_UNIT_HEADER_BY_ID_REJECTED';

const GET_TRANSPORTATION_UNIT_BY_HEADER_ID =
  'GET_TRANSPORTATION_UNIT_BY_HEADER_ID';
const GET_TRANSPORTATION_UNIT_BY_HEADER_ID_PENDING =
  'GET_TRANSPORTATION_UNIT_BY_HEADER_ID_PENDING';
const GET_TRANSPORTATION_UNIT_BY_HEADER_ID_FULFILLED =
  'GET_TRANSPORTATION_UNIT_BY_HEADER_ID_FULFILLED';
const GET_TRANSPORTATION_UNIT_BY_HEADER_ID_REJECTED =
  'GET_TRANSPORTATION_UNIT_BY_HEADER_ID_REJECTED';

const GET_EXPORT_TRANSPORTATION_UNIT_DATA =
  'GET_EXPORT_TRANSPORTATION_UNIT_DATA';
const GET_EXPORT_TRANSPORTATION_UNIT_DATA_FULFILLED =
  'GET_EXPORT_TRANSPORTATION_UNIT_DATA_FULFILLED';
const GET_EXPORT_TRANSPORTATION_UNIT_DATA_PENDING =
  'GET_EXPORT_TRANSPORTATION_UNIT_DATA_PENDING';
const GET_EXPORT_TRANSPORTATION_UNIT_DATA_REJECTED =
  'GET_EXPORT_TRANSPORTATION_UNIT_DATA_REJECTED';

const GET_TEMPLATE_TRANSPORTATION_UNIT_DATA =
  'GET_TEMPLATE_TRANSPORTATION_UNIT_DATA';
const GET_TEMPLATE_TRANSPORTATION_UNIT_DATA_FULFILLED =
  'GET_TEMPLATE_TRANSPORTATION_UNIT_DATA_FULFILLED';
const GET_TEMPLATE_TRANSPORTATION_UNIT_DATA_PENDING =
  'GET_TEMPLATE_TRANSPORTATION_UNIT_DATA_PENDING';
const GET_TEMPLATE_TRANSPORTATION_UNIT_DATA_REJECTED =
  'GET_TEMPLATE_TRANSPORTATION_UNIT_DATA_REJECTED';

const GET_TRANSPORTATION_UNIT_ALL = 'GET_TRANSPORTATION_UNIT_ALL';
const GET_TRANSPORTATION_UNIT_ALL_PENDING =
  'GET_TRANSPORTATION_UNIT_ALL_PENDING';
const GET_TRANSPORTATION_UNIT_ALL_FULFILLED =
  'GET_TRANSPORTATION_UNIT_ALL_FULFILLED';
const GET_TRANSPORTATION_UNIT_ALL_REJECTED =
  'GET_TRANSPORTATION_UNIT_ALL_REJECTED';

//GET TRANSPORTATION_UNIT_BY_SERVICE_ITEM_ID
const GET_TRANSPORTATION_UNIT_BY_SERVICE_ITEM_ID =
  'GET_TRANSPORTATION_UNIT_BY_SERVICE_ITEM_ID';
const GET_TRANSPORTATION_UNIT_BY_SERVICE_ITEM_ID_FULFILLED =
  'GET_TRANSPORTATION_UNIT_BY_SERVICE_ITEM_ID_FULFILLED';
const GET_TRANSPORTATION_UNIT_BY_SERVICE_ITEM_ID_PENDING =
  'GET_TRANSPORTATION_UNIT_BY_SERVICE_ITEM_ID_PENDING';
const GET_TRANSPORTATION_UNIT_BY_SERVICE_ITEM_ID_REJECTED =
  'GET_TRANSPORTATION_UNIT_BY_SERVICE_ITEM_ID_REJECTED';

//POST _IMAGE_TRANSPORTATION_UNIT
const POST_UPLOAD_IMAGE_TRANSPORTATION_UNIT =
  'POST_UPLOAD_IMAGE_TRANSPORTATION_UNIT';
const POST_UPLOAD_IMAGE_TRANSPORTATION_UNIT_FULFILLED =
  'POST_UPLOAD_IMAGE_TRANSPORTATION_UNIT_FULFILLED';
const POST_UPLOAD_IMAGE_TRANSPORTATION_UNIT_PENDING =
  'POST_UPLOAD_IMAGE_TRANSPORTATION_UNIT_PENDING';
const POST_UPLOAD_IMAGE_TRANSPORTATION_UNIT_REJECTED =
  'POST_UPLOAD_IMAGE_TRANSPORTATION_UNIT_REJECTED';

//POST PUT_TRANSPORTATION_UNIT_HEADER
const GET_TRANSPORTATION_BY_FROM_TO_DATE = 'GET_TRANSPORTATION_BY_FROM_TO_DATE';
const GET_TRANSPORTATION_BY_FROM_TO_DATE_PENDING =
  'GET_TRANSPORTATION_BY_FROM_TO_DATE_PENDING';
const GET_TRANSPORTATION_BY_FROM_TO_DATE_REJECTED =
  'GET_TRANSPORTATION_BY_FROM_TO_DATE_REJECTED';
const GET_TRANSPORTATION_BY_FROM_TO_DATE_FULFILLED =
  'GET_TRANSPORTATION_BY_FROM_TO_DATE_FULFILLED';

//POST PUT_TRANSPORTATION_UNIT_HEADER
const PUT_TRANSPORTATION_UNIT_HEADER = 'PUT_TRANSPORTATION_UNIT_HEADER';
const PUT_TRANSPORTATION_UNIT_HEADER_FULFILLED =
  'PUT_TRANSPORTATION_UNIT_HEADER_FULFILLED';
const PUT_TRANSPORTATION_UNIT_HEADER_PENDING =
  'PUT_TRANSPORTATION_UNIT_HEADER_PENDING';
const PUT_TRANSPORTATION_UNIT_HEADER_REJECTED =
  'PUT_TRANSPORTATION_UNIT_HEADER_REJECTED';

const POST_TRANSPORTATION_UNIT = 'POST_TRANSPORTATION_UNIT';
const POST_TRANSPORTATION_UNIT_FULFILLED = 'POST_TRANSPORTATION_UNIT_FULFILLED';
const POST_TRANSPORTATION_UNIT_PENDING = 'POST_TRANSPORTATION_UNIT_PENDING';
const POST_TRANSPORTATION_UNIT_REJECTED = 'POST_TRANSPORTATION_UNIT_REJECTED';

//POST POST_TRANSPORTATION_UNIT_HEADER
const POST_TRANSPORTATION_UNIT_HEADER = 'POST_TRANSPORTATION_UNIT_HEADER';
const POST_TRANSPORTATION_UNIT_HEADER_FULFILLED =
  'POST_TRANSPORTATION_UNIT_HEADER_FULFILLED';
const POST_TRANSPORTATION_UNIT_HEADER_PENDING =
  'POST_TRANSPORTATION_UNIT_HEADER_PENDING';
const POST_TRANSPORTATION_UNIT_HEADER_REJECTED =
  'POST_TRANSPORTATION_UNIT_HEADER_REJECTED';

const PUT_TRANSPORTATION_UNIT = 'PUT_TRANSPORTATION_UNIT';
const PUT_TRANSPORTATION_UNIT_FULFILLED = 'PUT_TRANSPORTATION_UNIT_FULFILLED';
const PUT_TRANSPORTATION_UNIT_PENDING = 'PUT_TRANSPORTATION_UNIT_PENDING';
const PUT_TRANSPORTATION_UNIT_REJECTED = 'PUT_TRANSPORTATION_UNIT_REJECTED';

const DELETE_TRANSPORTATION_UNIT_HEADER = 'DELETE_TRANSPORTATION_UNIT_HEADER';
const DELETE_TRANSPORTATION_UNIT_HEADER_FULFILLED =
  'DELETE_TRANSPORTATION_UNIT_HEADER_FULFILLED';
const DELETE_TRANSPORTATION_UNIT_HEADER_PENDING =
  'DELETE_TRANSPORTATION_UNIT_HEADER_PENDING';
const DELETE_TRANSPORTATION_UNIT_HEADER_REJECTED =
  'DELETE_TRANSPORTATION_UNIT_HEADER_REJECTED';

const DELETE_TRANSPORTATION_UNIT = 'DELETE_TRANSPORTATION_UNIT';
const DELETE_TRANSPORTATION_UNIT_FULFILLED =
  'DELETE_TRANSPORTATION_UNIT_FULFILLED';
const DELETE_TRANSPORTATION_UNIT_PENDING = 'DELETE_TRANSPORTATION_UNIT_PENDING';
const DELETE_TRANSPORTATION_UNIT_REJECTED =
  'DELETE_TRANSPORTATION_UNIT_REJECTED';

const DELETE_IMAGE_TRANSPORTATION_UNIT = 'DELETE_IMAGE_TRANSPORTATION_UNIT';
const DELETE_IMAGE_TRANSPORTATION_UNIT_FULFILLED =
  'DELETE_IMAGE_TRANSPORTATION_UNIT_FULFILLED';
const DELETE_IMAGE_TRANSPORTATION_UNIT_PENDING =
  'DELETE_IMAGE_TRANSPORTATION_UNIT_PENDING';
const DELETE_IMAGE_TRANSPORTATION_UNIT_REJECTED =
  'DELETE_IMAGE_TRANSPORTATION_UNIT_REJECTED';

const GET_EXPORT_DATA_ITEM_BY_ID = 'GET_EXPORT_DATA_ITEM_BY_ID';
const GET_EXPORT_DATA_ITEM_BY_ID_FULFILLED =
  'GET_EXPORT_DATA_ITEM_BY_ID_FULFILLED';
const GET_EXPORT_DATA_ITEM_BY_ID_PENDING = 'GET_EXPORT_DATA_ITEM_BY_ID_PENDING';
const GET_EXPORT_DATA_ITEM_BY_ID_REJECTED =
  'GET_EXPORT_DATA_ITEM_BY_ID_REJECTED';

const GET_TRANSPORTATION_ESTIMATE_PRICE = 'GET_TRANSPORTATION_ESTIMATE_PRICE';
const GET_TRANSPORTATION_ESTIMATE_PRICE_FULFILLED =
  'GET_TRANSPORTATION_ESTIMATE_PRICE_FULFILLED';
const GET_TRANSPORTATION_ESTIMATE_PRICE_PENDING =
  'GET_TRANSPORTATION_ESTIMATE_PRICE_PENDING';
const GET_TRANSPORTATION_ESTIMATE_PRICE_REJECTED =
  'GET_TRANSPORTATION_ESTIMATE_PRICE_REJECTED';
export {
  GET_TRANSPORTATION_UNIT_HEADER_BY_ID,
  GET_TRANSPORTATION_UNIT_HEADER_BY_ID_PENDING,
  GET_TRANSPORTATION_UNIT_HEADER_BY_ID_FULFILLED,
  GET_TRANSPORTATION_UNIT_HEADER_BY_ID_REJECTED,
  GET_TRANSPORTATION_UNIT_BY_HEADER_ID,
  GET_TRANSPORTATION_UNIT_BY_HEADER_ID_PENDING,
  GET_TRANSPORTATION_UNIT_BY_HEADER_ID_FULFILLED,
  GET_TRANSPORTATION_UNIT_BY_HEADER_ID_REJECTED,
  GET_EXPORT_TRANSPORTATION_UNIT_DATA,
  GET_EXPORT_TRANSPORTATION_UNIT_DATA_FULFILLED,
  GET_EXPORT_TRANSPORTATION_UNIT_DATA_PENDING,
  GET_EXPORT_TRANSPORTATION_UNIT_DATA_REJECTED,
  GET_TEMPLATE_TRANSPORTATION_UNIT_DATA,
  GET_TEMPLATE_TRANSPORTATION_UNIT_DATA_FULFILLED,
  GET_TEMPLATE_TRANSPORTATION_UNIT_DATA_PENDING,
  GET_TEMPLATE_TRANSPORTATION_UNIT_DATA_REJECTED,
  GET_TRANSPORTATION_UNIT_ALL,
  GET_TRANSPORTATION_UNIT_ALL_PENDING,
  GET_TRANSPORTATION_UNIT_ALL_FULFILLED,
  GET_TRANSPORTATION_UNIT_ALL_REJECTED,
  GET_TRANSPORTATION_UNIT_BY_SERVICE_ITEM_ID,
  GET_TRANSPORTATION_UNIT_BY_SERVICE_ITEM_ID_FULFILLED,
  GET_TRANSPORTATION_UNIT_BY_SERVICE_ITEM_ID_PENDING,
  GET_TRANSPORTATION_UNIT_BY_SERVICE_ITEM_ID_REJECTED,
  POST_UPLOAD_IMAGE_TRANSPORTATION_UNIT,
  POST_UPLOAD_IMAGE_TRANSPORTATION_UNIT_FULFILLED,
  POST_UPLOAD_IMAGE_TRANSPORTATION_UNIT_PENDING,
  POST_UPLOAD_IMAGE_TRANSPORTATION_UNIT_REJECTED,
  GET_TRANSPORTATION_BY_FROM_TO_DATE,
  GET_TRANSPORTATION_BY_FROM_TO_DATE_PENDING,
  GET_TRANSPORTATION_BY_FROM_TO_DATE_FULFILLED,
  GET_TRANSPORTATION_BY_FROM_TO_DATE_REJECTED,
  PUT_TRANSPORTATION_UNIT_HEADER,
  PUT_TRANSPORTATION_UNIT_HEADER_FULFILLED,
  PUT_TRANSPORTATION_UNIT_HEADER_PENDING,
  PUT_TRANSPORTATION_UNIT_HEADER_REJECTED,
  POST_TRANSPORTATION_UNIT_HEADER,
  POST_TRANSPORTATION_UNIT_HEADER_FULFILLED,
  POST_TRANSPORTATION_UNIT_HEADER_PENDING,
  POST_TRANSPORTATION_UNIT_HEADER_REJECTED,
  POST_TRANSPORTATION_UNIT,
  POST_TRANSPORTATION_UNIT_FULFILLED,
  POST_TRANSPORTATION_UNIT_PENDING,
  POST_TRANSPORTATION_UNIT_REJECTED,
  PUT_TRANSPORTATION_UNIT,
  PUT_TRANSPORTATION_UNIT_FULFILLED,
  PUT_TRANSPORTATION_UNIT_PENDING,
  PUT_TRANSPORTATION_UNIT_REJECTED,
  DELETE_TRANSPORTATION_UNIT_HEADER,
  DELETE_TRANSPORTATION_UNIT_HEADER_FULFILLED,
  DELETE_TRANSPORTATION_UNIT_HEADER_PENDING,
  DELETE_TRANSPORTATION_UNIT_HEADER_REJECTED,
  DELETE_TRANSPORTATION_UNIT,
  DELETE_TRANSPORTATION_UNIT_FULFILLED,
  DELETE_TRANSPORTATION_UNIT_PENDING,
  DELETE_TRANSPORTATION_UNIT_REJECTED,
  DELETE_IMAGE_TRANSPORTATION_UNIT,
  DELETE_IMAGE_TRANSPORTATION_UNIT_FULFILLED,
  DELETE_IMAGE_TRANSPORTATION_UNIT_PENDING,
  DELETE_IMAGE_TRANSPORTATION_UNIT_REJECTED,
  GET_EXPORT_DATA_ITEM_BY_ID,
  GET_EXPORT_DATA_ITEM_BY_ID_FULFILLED,
  GET_EXPORT_DATA_ITEM_BY_ID_PENDING,
  GET_EXPORT_DATA_ITEM_BY_ID_REJECTED,
  GET_TRANSPORTATION_ESTIMATE_PRICE,
  GET_TRANSPORTATION_ESTIMATE_PRICE_FULFILLED,
  GET_TRANSPORTATION_ESTIMATE_PRICE_PENDING,
  GET_TRANSPORTATION_ESTIMATE_PRICE_REJECTED,
};
