import React from 'react';
import PropTypes from 'prop-types';
import TablePrice from './TablePrice';
import {
  Grid,
  Checkbox,
  Form,
  // Select,
  // Container,
  Input,
} from 'semantic-ui-react';
// import { StylesTextfieldWithError } from '../../../../components/common/styleCommon';
import { getTourOperatorProfileAction } from '../../../../actions/packageAction';
import { connect } from 'react-redux';
import {
  calculatePrice,
  calculateCostMargin,
  setPackageCostFunc,
  setPackagePriceFunc,
} from '../../../../scripts/packageManagement/fixedPackageFunc';
import InputWithLabel from '../../../../components/input/inputWithLabel';
import { tourOperatorValidation } from '../../services/validation/validationPrice';
class TabPricePackage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      errorPrice: {
        TourOperator: { error: false, message: 'Please choose tour operator' },
        isError: false,
      },
    };
  }
  componentDidMount() {
    this.props.getTourOperatorProfileAction();
  }
  handleChangeCost = async (value, name) => {
    let {
      packagePrice,
      packageCost,
      tourOperator,
      setPackagePriceAction,
      setPackageCostAction,
    } = this.props;
    packageCost = { ...packageCost, [name]: value };
    let newValue = calculatePrice(
      value,
      tourOperator.BaseMarginPercentage ? tourOperator.BaseMarginPercentage : 0,
      tourOperator.TourOperatorMarginPercentage
        ? tourOperator.TourOperatorMarginPercentage
        : 0
    );
    packagePrice = { ...packagePrice, [name]: newValue };
    await setPackageCostAction(packageCost);
    await setPackagePriceAction(packagePrice);
    this.validateRoomPrice();
  };

  handleChangePrice = async (value, name) => {
    let {
      packagePrice,
      packageCost,
      tourOperator,
      setPackagePriceAction,
      setPackageCostAction,
    } = this.props;
    packagePrice = { ...packagePrice, [name]: value };
    let newValue = calculateCostMargin(
      value,
      tourOperator.BaseMarginPercentage
    );
    packageCost = { ...packageCost, [name]: newValue };
    await setPackageCostAction(packageCost);
    await setPackagePriceAction(packagePrice);
    this.validateRoomPrice();
  };

  handleChangeTourOperator = async (e, { name, value }) => {
    let {
      packagePrice,
      packageCost,
      tourOperator,
      setPackagePriceAction,
      setPackageCostAction,
      setTourOperatorAction,
      handleErrorTourOperator,
    } = this.props;
    if (
      name === 'BaseMarginPercentage' ||
      name === 'TourOperatorMarginPercentage'
    ) {
      if (Number(value) < 0) {
        value = '0';
      }
      if (Number(value) > 100) {
        value = '100';
      }
      if (tourOperator.IsTourOperatorFixedPackage) {
        tourOperator = { ...tourOperator, [name]: value };
        packageCost = setPackageCostFunc(packageCost, packagePrice, value);
        setPackageCostAction(packageCost);
        setTourOperatorAction(tourOperator);
      } else {
        tourOperator = { ...tourOperator, [name]: value };
        packagePrice = setPackagePriceFunc(
          packageCost,
          packagePrice,
          tourOperator.BaseMarginPercentage,
          tourOperator.TourOperatorMarginPercentage
        );
        setPackagePriceAction(packagePrice);
        setTourOperatorAction(tourOperator);
      }
    }
    tourOperator = { ...tourOperator, [name]: value };
    await setPackagePriceAction(packagePrice);
    await setTourOperatorAction(tourOperator);
    let errorPrice = tourOperatorValidation(
      tourOperator,
      this.state.errorPrice
    );
    handleErrorTourOperator(errorPrice);
  };
  handleChangeChecboxTourOperator = (e, { name, checked }) => {
    let {
      packagePrice,
      packageCost,
      tourOperator,
      setPackagePriceAction,
      setPackageCostAction,
      setTourOperatorAction,
    } = this.props;
    tourOperator = { ...tourOperator, [name]: checked };
    let { basemargin } = this.state;
    if (checked) {
      tourOperator.TourOperatorMarginPercentage = 0;
      packageCost = setPackageCostFunc(
        packageCost,
        packagePrice,
        tourOperator.BaseMarginPercentage
      );
      setPackageCostAction(packageCost);
    } else {
      let checkValue = basemargin === tourOperator.BaseMarginPercentage;
      if (!checkValue) {
        packagePrice = setPackagePriceFunc(
          packageCost,
          packagePrice,
          tourOperator.BaseMarginPercentage,
          tourOperator.TourOperatorMarginPercentage
        );
      }
      setPackagePriceAction(packagePrice);
    }
    setTourOperatorAction(tourOperator);
    this.setState({ basemargin: tourOperator.BaseMarginPercentage });
  };

  validateRoomPrice = () => {
    let isError = false;
    const error = {
      SharingRoomPrice: '',
      SingleRoomPrice: '',
    };

    if (this.props.packagePrice.SharingRoomPrice === 0) {
      isError = true;
      error.SharingRoomPrice = 'price cannot be 0';
    }
    if (this.props.packagePrice.SingleRoomPrice === 0) {
      isError = true;
      error.SingleRoomPrice = 'price cannot be 0';
    }
    if (this.props.packageCost.SharingRoomPrice === 0) {
      isError = true;
      error.SharingRoomPrice = 'price cannot be 0';
    }
    if (this.props.packageCost.SingleRoomPrice === 0) {
      isError = true;
      error.SingleRoomPrice = 'price cannot be 0';
    }
    this.setState({
      errors: {
        ...error,
      },
    });
    return isError;
  };
  render() {
    // const { tourOperatorErrors } = this.state;
    const {
      currentPackage,
      tourOperatorProfileList,
      packagePrice,
      packageCost,
      tourOperator,
      errorTourOperator,
      isPublished,
    } = this.props;
    let status = currentPackage.disabledEdit || isPublished;
    return (
      <Grid columns="equal">
        {/* <Grid.Row>
          <Grid.Column width={4}>
            <div style={StylesTextfieldWithError.margin}>
              <Form>
             
                <label style={StylesTextfieldWithError.labelTop}>
                  Tour Operator Profile
                </label>
                <Select
                  options={tourOperatorProfileList}
                  style={StylesTextfieldWithError.doubleTextField}
                  value={tourOperator.TourOperator}
                  name="TourOperator"
                  onChange={this.handleChangeTourOperator}
                  disabled={status ? true : false}
                  placeholder="Select Tour Operator"
                /> 
                <InputWithLabel
                  label="Tour Operator Profile"
                  inline={false}
                  type="select"
                  name="TourOperator"
                  placeholder="Select Tour Operator"
                  onChange={this.handleChangeTourOperator}
                  required
                  selectOnBlur={false}
                  options={tourOperatorProfileList}
                />
                <Checkbox
                  label="Tour Operator Series Package"
                  style={StylesTextfieldWithError.doubleTextFieldRight}
                  checked={tourOperator.IsTourOperatorFixedPackage}
                  name="IsTourOperatorFixedPackage"
                  onChange={this.handleChangeChecboxTourOperator}
                  disabled={status ? true : false}
                />
                <Form.Group widths={2}>
                  <Container
                    content={tourOperatorErrors}
                    style={StylesTextfieldWithError.Red}
                  />
                  <Container />
                </Form.Group>
              </Form>
            </div>
          </Grid.Column>
          <Grid.Column width={8}>
            <Form>
              <Form.Group>
                <Form.Field
                  control={Input}
                  label="Touress Margin (%)"
                  name="BaseMarginPercentage"
                  placeholder={0}
                  type="number"
                  onChange={this.handleChangeTourOperator}
                  disabled={false || status ? true : false}
                  value={tourOperator.BaseMarginPercentage}
                />
                <Form.Field
                  control={Input}
                  type="number"
                  placeholder={0}
                  label="Tour Operator Margin (%)"
                  name="TourOperatorMarginPercentage"
                  disabled={
                    tourOperator.IsTourOperatorFixedPackage || status
                      ? true
                      : false
                  }
                  onChange={this.handleChangeTourOperator}
                  value={tourOperator.TourOperatorMarginPercentage}
                />
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row> */}
        <Grid.Row columns={4}>
          <Grid.Column>
            <Form>
              <InputWithLabel
                label="Tour Operator Profile"
                inline={false}
                type="select"
                name="TourOperator"
                placeholder="Select Tour Operator"
                handleChange={this.handleChangeTourOperator}
                required
                options={tourOperatorProfileList}
                value={tourOperator.TourOperator}
                validate={errorTourOperator}
                disabled={status ? true : false}
              />
            </Form>
          </Grid.Column>
          <Grid.Column>
            <Form>
              <Checkbox
                label="Tour Operator Series Package"
                // style={StylesTextfieldWithError.doubleTextFieldRight}
                checked={tourOperator.IsTourOperatorFixedPackage}
                name="IsTourOperatorFixedPackage"
                onChange={this.handleChangeChecboxTourOperator}
                disabled={status ? true : false}
                style={{ marginTop: '2.5em' }}
              />
            </Form>
          </Grid.Column>
          <Grid.Column>
            <Form>
              <Form.Group>
                <Form.Field
                  control={Input}
                  label="Touress Margin (%)"
                  name="BaseMarginPercentage"
                  placeholder={0}
                  type="number"
                  onChange={this.handleChangeTourOperator}
                  disabled={false || status ? true : false}
                  value={tourOperator.BaseMarginPercentage}
                />
              </Form.Group>
            </Form>
          </Grid.Column>
          <Grid.Column>
            <Form>
              <Form.Group>
                <Form.Field
                  control={Input}
                  type="number"
                  placeholder={0}
                  label="Tour Operator Margin (%)"
                  name="TourOperatorMarginPercentage"
                  disabled={
                    tourOperator.IsTourOperatorFixedPackage || status
                      ? true
                      : false
                  }
                  onChange={this.handleChangeTourOperator}
                  value={tourOperator.TourOperatorMarginPercentage}
                />
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row stretched>
          <Grid.Column width={15}>
            <TablePrice
              price={packagePrice}
              cost={packageCost}
              handelChangeCost={this.handleChangeCost}
              handelChangePrice={this.handleChangePrice}
              errors={this.state.errors}
              isTourOperator={tourOperator.IsTourOperatorFixedPackage}
              status={status}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

TabPricePackage.propTypes = {
  packagePrice: PropTypes.object,
  packageCost: PropTypes.object,
  setPackagePriceAction: PropTypes.func,
  setPackageCostAction: PropTypes.func,
  setTourOperatorAction: PropTypes.func,
  tourOperator: PropTypes.shape({
    BaseMarginPercentage: PropTypes.number,
    TourOperator: PropTypes.number,
    TourOperatorMarginPercentage: PropTypes.number,
    IsTourOperatorFixedPackage: PropTypes.bool,
  }),
  getTourOperatorProfileAction: PropTypes.func,
  currentPackage: PropTypes.object,
  packageDetail: PropTypes.object,
  tourOperatorProfileList: PropTypes.array,
  errorTourOperator: PropTypes.object,
  isPublished: PropTypes.bool,
};
function mapStateToProps(state) {
  return {
    tourOperatorProfileList: state.PackageStore.tourOperatorProfileList.map(
      (tour, i) => {
        return { key: i, text: tour.Name, value: tour.Id };
      }
    ),
    fixedPackage: state.PackageStore.fixedPackage,
    getFixedPackageStatus: state.PackageStore.getFixedPackageStatus,
  };
}
export default connect(mapStateToProps, {
  getTourOperatorProfileAction,
})(TabPricePackage);
