import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MainPageList from './mainPageList';
import { datasetPagination, getNumberFromTo } from '../../scripts/pagination';
import FilterData from '../../scripts/filterDataTable';

export default class globalListComponent extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
    };
  }
  static propTypes = {
    data: PropTypes.array,
  };
  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  handleChangeDate = (value, name) => {
    this.setState({ [name]: value, activePage: 1 });
  };
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  render() {
    let filterData = FilterData(
      this.props.data,
      this.props.filterBy,
      this.state.searchInput
    );
    let pagedData = datasetPagination(filterData, 7);
    let listData = pagedData[this.state.activePage - 1];
    let { numbersFrom, numbersTo } = getNumberFromTo(
      listData,
      this.state.activePage,
      7
    );
    let handleChange =
      this.props.filterBy === 'FormatedDate'
        ? this.handleChangeDate
        : this.props.filterBy === 'exchangeRate'
          ? this.handleChangeDate
          : this.handleChange;
    return (
      <MainPageList
        filterData={filterData}
        listData={listData}
        pagedData={pagedData}
        pageChange={this.pageChange}
        activePage={this.state.activePage}
        searchInput={this.state.searchInput}
        handleChange={handleChange}
        data={this.props.data}
        dataPublish={this.props.dataPublish}
        dataDraft={this.props.dataDraft}
        type={this.props.type}
        content={this.props.content}
        buttonNew={this.props.buttonNew}
        butttonExport={this.props.butttonExport}
        buttonUploadData={this.props.buttonUploadData}
        buttonDownloadTemplate={this.props.buttonDownloadTemplate}
        butttonEdit={this.props.butttonEdit}
        buttonDelete={this.props.buttonDelete}
        buttonPublished={this.props.buttonPublished}
        buttonCopy={this.props.buttonCopy}
        buttonEdit={this.props.buttonEdit}
        unlockUser={this.props.unlockUser}
        resetPassword={this.props.resetPassword}
        openNewRate={this.props.openNewRate}
        header={this.props.header}
        editExchangeRate={this.props.editExchangeRate}
        buttonSeeDetail={this.props.buttonSeeDetail}
        numbersFrom={numbersFrom}
        numbersTo={numbersTo}
        handleSorting={this.props.handleSorting}
        buttonBack={this.props.buttonBack}
        disableButton={this.props.disableButton}
      />
    );
  }
}

globalListComponent.propTypes = {
  data: PropTypes.array,
  type: PropTypes.string,
  filterBy: PropTypes.string,
  buttonNew: PropTypes.func,
  butttonExport: PropTypes.func,
  buttonUploadData: PropTypes.func,
  buttonDownloadTemplate: PropTypes.func,
  butttonEdit: PropTypes.func,
  buttonDelete: PropTypes.func,
  buttonPublished: PropTypes.func,
  buttonCopy: PropTypes.func,
  buttonEdit: PropTypes.func,
  content: PropTypes.object,
  dataPublish: PropTypes.array,
  dataDraft: PropTypes.array,
  unlockUser: PropTypes.func,
  resetPassword: PropTypes.func,
  openNewRate: PropTypes.func,
  editExchangeRate: PropTypes.func,
  buttonSeeDetail: PropTypes.func,
  header: PropTypes.string,
  handleChange: PropTypes.func,
  handleSorting: PropTypes.func,
  buttonBack: PropTypes.func,
  disableButton: PropTypes.bool,
};
