import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  Icon,
  List,
  Grid,
  Segment,
  Form,
} from 'semantic-ui-react';
import { StyleModal } from '../../../assets/styles/size';
import { Collapse } from 'react-collapse';
import { StyleButton } from '../../common/styleCommon';
import LoaderModal from '../../modal/loaderModal';
import {
  getPaymentMethodChoiceListAction,
  resetCustomEnumAction,
} from '../../../pages/shared/actions/customEnumAction';
import { connect } from 'react-redux';
import InputWithLabel from '../../../components/input/inputWithLabel';
class modalChoosePaymentGateway extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openCollapse: false,
      paymentMethodByCountryId: [],
    };
  }
  collapseButton = () => {
    this.setState({ openCollapse: !this.state.openCollapse });
  };
  componentDidUpdate() {
    const {
      getPaymentMethodByCountryIdStatus,
      getPaymentMethodByCountryId,
    } = this.props;
    if (getPaymentMethodByCountryIdStatus === 'success') {
      this.setPaymentGateway(
        getPaymentMethodByCountryId.map(p => p.PaymentGateawayChoice)
      );
      this.props.resetCustomEnumAction();
    } else if (getPaymentMethodByCountryIdStatus === 'failed') {
      this.props.resetCustomEnumAction();
    }
  }
  setPaymentGateway = data => {
    const manual = ['Deposit'];
    const paymentGateway = data;
    let filteredKeywords = paymentGateway.filter(
      word => !manual.includes(word)
    );
    this.setState({
      listPaymentgateway: filteredKeywords.map(type => {
        return {
          name: type,
          Id: type === 'Deposit' ? 0 : 1,
        };
      }),
    });
  };

  render() {
    const props = this.props;
    const { openCollapse, listPaymentgateway } = this.state;
    return (
      <Modal
        dimmer="blurring"
        closeIcon
        inverted
        open={props.open}
        onClose={props.handleClose}
        size="small"
        style={StyleModal.modalWidth}
      >
        <Modal.Header>{props.header}</Modal.Header>
        <Modal.Content>
          <LoaderModal loading={props.loadingModal} />
          <Button
            icon
            onClick={this.collapseButton}
            style={StyleButton.buttonWidthPaymentGateway}
            primary
            labelPosition="right"
          >
            Manual Payment
            <Icon name="chevron down" />
          </Button>
          <Collapse isOpened={openCollapse}>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Segment style={{ marginTop: '1em', height: '7em' }}>
                    <Form>
                      <InputWithLabel
                        label="No Reference"
                        type="text"
                        name="Name"
                        handleChange={props.handleChange}
                        placeholder="No Reference"
                        inputStyles={{ width: '20em' }}
                      />
                      <Button
                        positive
                        onClick={props.saveBalanceDetail}
                        name="ManualPayment"
                        content="Add Fund"
                        floated="right"
                        style={{ marginTop: '-3.7em' }}
                        loading={props.loadingAddBalance}
                      />
                    </Form>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Collapse>
          <List divided relaxed>
            {listPaymentgateway &&
              listPaymentgateway.map((data, idx) => {
                return (
                  <List.Item key={idx}>
                    <List.Content>
                      <List.Header as={props.LinkTo}>
                        <Button
                          icon
                          style={StyleButton.buttonWidthPaymentGateway}
                          primary
                          labelPosition="right"
                          onClick={props.saveBalanceDetail}
                          name="postPaymentGateway"
                          id={data.Id}
                          value={data.name}
                        >
                          {data.name}
                          <Icon name="chevron right" />
                        </Button>
                      </List.Header>
                      <List.Description as="a" />
                    </List.Content>
                  </List.Item>
                );
              })}
          </List>
        </Modal.Content>
        <Modal.Actions>
          {/* <Button positive inverted onClick={props.saveBalanceDetail}>
            <Icon name="save" /> Save
          </Button> */}
        </Modal.Actions>
      </Modal>
    );
  }
}

modalChoosePaymentGateway.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  //   handleChange: PropTypes.func,
  //   contentField: PropTypes.shape({
  //     Name: PropTypes.string,
  //     CompanyCode: PropTypes.number,
  //     DateTime: PropTypes.string,
  //     Value: PropTypes.number,
  //   }),
  //   errorInformation: PropTypes.shape({
  //     ValueError: PropTypes.string,
  //   }),
  //   saveBalanceDetail: PropTypes.func,
  //   handleClickPaymentGateway: PropTypes.func,
  //   listPaymentMethod: PropTypes.array,
  //   LinkTo: PropTypes.func,
  //   openCollapse: PropTypes.bool,
  //   collapseButton: PropTypes.func,
  //   loadingModal: PropTypes.bool,
  getPaymentMethodByCountryIdStatus: PropTypes.string,
  getPaymentMethodByCountryId: PropTypes.array,
  resetCustomEnumAction: PropTypes.func,
};
const mapStateToProps = state => {
  return {
    getPaymentMethodByCountryId:
      state.CustomEnumReducerStore.getPaymentMethodByCountryId,
    getPaymentMethodByCountryIdStatus:
      state.CustomEnumReducerStore.getPaymentMethodByCountryIdStatus,
  };
};

export default connect(
  mapStateToProps,
  {
    getPaymentMethodChoiceListAction,
    resetCustomEnumAction,
  }
)(modalChoosePaymentGateway);
