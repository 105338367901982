import React from 'react';
import { Table, Button, Grid } from 'semantic-ui-react';
//import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Pagination from '../../../../../components/common/pagination';
import { headerTableResourceBooking } from '../../constant/headerTable';

const TableListResourceBooking = props => {
  const {
    activePage,
    data,
    pagedData,
    listData,
    numbersFrom,
    numbersTo,
    pageChange,
    handleOpenDetail,
  } = props;

  return (
    <React.Fragment>
      <Table basic="very" selectable>
        <Table.Header>
          <Table.Row>
            {headerTableResourceBooking.map((headTableContent, index) => {
              return (
                <Table.HeaderCell
                  textAlign={headTableContent === 'Action' ? 'center' : ''}
                  key={index}
                >
                  {headTableContent}
                </Table.HeaderCell>
              );
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {listData ? (
            listData.map((item, idx) => {
              return (
                <Table.Row key={idx}>
                  <Table.Cell>{item.Id}</Table.Cell>
                  <Table.Cell>
                    {/* <Link
                      to={`Resource-Booking-Detail/${item.ResourceBookingCode}`}
                    >
                      {item.ResourceBookingCode}
                    </Link> */}
                    {item.ResourceBookingCode}
                  </Table.Cell>
                  <Table.Cell>{item.Name ? item.Name : '-'}</Table.Cell>
                  <Table.Cell>
                    {item.PackageType === 'Custom'
                      ? 'Custom'
                      : item.PackageType === 'FixedDateVariable'
                      ? 'Ready Fixed Price'
                      : item.PackageType === 'Ready'
                      ? 'Ready'
                      : 'Series'}
                  </Table.Cell>
                  <Table.Cell>{item.StartTour}</Table.Cell>
                  <Table.Cell textAlign="center">
                    <Grid columns="equal">
                      <Grid.Column>
                        <Button
                          name={item.Name}
                          id={item.Id}
                          onClick={handleOpenDetail}
                          positive
                        >
                          See Detail
                        </Button>
                      </Grid.Column>
                    </Grid>
                  </Table.Cell>
                </Table.Row>
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell colSpan="6" textAlign="center">
                No Data
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
        <Table.Row>
          <Table.HeaderCell colSpan="6">
            <Pagination
              pagedData={pagedData}
              numbersTo={numbersTo}
              numbersFrom={numbersFrom}
              data={data}
              pageChange={pageChange}
              activePage={activePage}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table>
    </React.Fragment>
  );
};
TableListResourceBooking.propTypes = {
  activePage: PropTypes.number,
  pagedData: PropTypes.array,
  listData: PropTypes.array,
  numbersFrom: PropTypes.number,
  numbersTo: PropTypes.number,
  pageChange: PropTypes.func,
  data: PropTypes.array,
  handleClose: PropTypes.func,
  handleOpenDetail: PropTypes.func,
};
export default React.memo(TableListResourceBooking);
