import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Segment, Button } from 'semantic-ui-react';
import GeneralInformation from '../form/GeneralInformationRestaurantItem';
import ImageListComponent from '../../../../components/image/image';
import TableRestaurantItemPriceList from './table/RestaurantPriceTable';
import {
  headerRestaurantPrice,
  headerTableImageAccomodationProfile,
} from '../constant/tableHeader';
import HeaderTitle from '../../../shared/components/common/headerSegment/HeaderTitle';
import { Styles } from '../../Styles';
const SegmentRestaurantItem = props => (
  <div>
    <Segment style={Styles.Segment}>
      <Grid columns={2} padded="vertically">
        <Grid.Column>
          {/* Kiri */}
          <HeaderTitle title={props.title} />
          <h4>Item For {props.restaurantItem.Name}</h4>
          <GeneralInformation
            getRestaurantType={props.getRestaurantType}
            getRestaurantClass={props.getRestaurantClass}
            getRestaurantCategory={props.getRestaurantCategory}
            restaurantItem={props.restaurantItem}
            handleChange={props.handleChange}
            handleChangeItem={props.handleChangeItem}
            menuType={props.menuType}
            menuClasses={props.menuClasses}
            isEdit={props.isEdit}
            disablePage={props.disablePage}
            errors={props.errors}
          />
        </Grid.Column>

        <Grid.Column>
          <div style={Styles.SaveBackButton}>
            <Button
              primary
              onClick={props.backPage}
              name={'Back'}
              icon="angle left"
              content="Back"
            />
            <Button
              primary
              onClick={props.saveRestaurant}
              name={props.contentButton}
              content={props.contentButton}
            />
          </div>
          <ImageListComponent
            addData={props.modalImageRestaurantItem}
            buttonContent="Add Image"
            headerContent="Image"
            deleteData={props.deleteStateItem}
            headerTable={headerTableImageAccomodationProfile}
            listData={props.restaurantItem.RestaurantImages}
            idButton="Image"
            openDetail={props.openDetailItem}
            handleChangePrimaryImage={props.handleChangePrimaryImage}
            disablePage={props.disablePage}
            pattern="Restaurants/"
          />
        </Grid.Column>
      </Grid>

      <Grid columns="equal">
        <Grid.Row stretched>
          <Grid.Column>
            <Segment>
              <h3>Menu Prices</h3>
              <TableRestaurantItemPriceList
                headerTable={headerRestaurantPrice}
                listData={props.restaurantPrice}
                editPrice={props.editPrice}
                disablePage={props.disablePage}
                addPrice={props.addPrice}
                deletePrice={props.deletePrice}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  </div>
);

SegmentRestaurantItem.propTypes = {
  restaurantPrice: PropTypes.array,
  restaurantItem: PropTypes.object,
  addPrice: PropTypes.func,
  deletePrice: PropTypes.func,
  handleChangePrice: PropTypes.func,
  currencies: PropTypes.array,
  servicePriceType: PropTypes.array,
  handleChangePrices: PropTypes.func,
  getRestaurantType: PropTypes.array,
  getRestaurantClass: PropTypes.array,
  getRestaurantCategory: PropTypes.array,
  saveRestaurant: PropTypes.func,
  title: PropTypes.string,
  handleChange: PropTypes.func,
  handleChangeItem: PropTypes.func,
  menuType: PropTypes.array,
  menuClasses: PropTypes.array,
  modalImageRestaurantItem: PropTypes.func,
  deleteStateItem: PropTypes.func,
  openDetailItem: PropTypes.func,
  handleChangePrimaryImage: PropTypes.func,
  handleClickEdit: PropTypes.func,
  isEdit: PropTypes.bool,
  openModalAddPrice: PropTypes.func,
  editPrice: PropTypes.func,
  disablePage: PropTypes.bool,
  backPage: PropTypes.func,
  contentButton: PropTypes.string,
  errors: PropTypes.object,
  buttonNew: PropTypes.func,
  exportData: PropTypes.func,
  uploadData: PropTypes.func,
  downloadTemplate: PropTypes.func,
  searchInput: PropTypes.string,
};
export default SegmentRestaurantItem;
