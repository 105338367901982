const setDataAdditionalInformationForAction = (
  state,
  post,
  bookingTemplateId
) => {
  let x = {};
  if (post) {
    x = {
      BookingTemplateId: bookingTemplateId,
      Subtitle: state.SubTitle,
      Content: state.Content,
      ForVoucher:
        state.SubTitle && state.SubTitle.indexOf('FOR_VOUCHER') !== -1
          ? true
          : false,
    };
  } else {
    x = {
      Id: state.Id,
      Subtitle: state.SubTitle,
      Content: state.Content,
      ForVoucher:
        state.SubTitle && state.SubTitle.indexOf('FOR_VOUCHER') !== -1
          ? true
          : false,
    };
  }
  return x;
};

export default setDataAdditionalInformationForAction;
