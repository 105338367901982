import React, { useState, useEffect } from 'react';
import { Grid, Segment, Header } from 'semantic-ui-react';
import styles from '../styles';
import CardCalendar from './card/CardBookingCalendar';
import useApiCall from '../hooks/useApiCallCalendar';
import { getBookingCalendarOperationApi } from '../../../api/dashboardApi'; // ganti
import moment from 'moment';
import CalendarBooking from './calendar/CalendarBooking';
import getListDate from '../services/helper/getListDate';
import PropTypes from 'prop-types';
import HeaderTitle from '../../../components/common/headerTitle';
import { convertDateFormat, addDate } from '../../../scripts/moment';
const SegmentBookingCalendar = props => {
  const { isReloadCalendar } = props;
  //=======================initial state=======================
  const [dateClick, setDateClick] = useState(moment().format('DD MMMM YYYY'));
  const [value, setValue] = useState('');
  const [
    calendarLoading,
    calendarList,
    todayBookingData,
    handleDateClick,
    loadData,
  ] = useApiCall(
    getBookingCalendarOperationApi,
    convertDateFormat(new Date(), 'YYYY-MM-DD'),
    convertDateFormat(addDate(new Date(), 6, 'M'), 'YYYY-MM-DD')
  );

  //=======================lifecycle=======================
  useEffect(() => {
    if (isReloadCalendar) {
      loadData(
        convertDateFormat(new Date(), 'YYYY-MM-DD'),
        convertDateFormat(addDate(new Date(), 1, 'y'), 'YYYY-MM-DD')
      );
      // handleReloadCalendar(false);
    }
    //eslint-disable-next-line
  }, [isReloadCalendar]);

  //=======================handler=======================
  const handleOnChangeDate = date => {
    let clickedDate = moment(date).format('DD MMMM YYYY');
    setDateClick(clickedDate);
    setValue(date);
    handleDateClick(clickedDate);
  };
  return (
    <Grid columns="equal" style={styles.styleGrid}>
      <Grid.Row>
        <Grid.Column width={15}>
          <Segment>
            <HeaderTitle
              title="Booking Calendar"
              iconName="list alternate outline"
            />
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column width={6}>
                  <CalendarBooking
                    className="calendar-booking"
                    tileClassName="calendar-day"
                    listDate={getListDate(calendarList)}
                    handleChangeDate={handleOnChangeDate}
                    value={value}
                  />
                </Grid.Column>
                <Grid.Column width={10}>
                  <Segment loading={calendarLoading}>
                    <Header
                      as="h3"
                      content={`
                        Booking list : ${dateClick}`}
                    />
                    <Grid style={styles.cardCalendar}>
                      <CardCalendar
                        loading={calendarLoading}
                        items={todayBookingData && todayBookingData.Items}
                        dateClick={dateClick}
                      />
                    </Grid>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

SegmentBookingCalendar.propTypes = {
  arrayRole: PropTypes.array,
  isReloadCalendar: PropTypes.bool,
};

export default React.memo(SegmentBookingCalendar);
