import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Form } from 'semantic-ui-react';
import InputWithLabel from '../../../../components/input/inputWithLabel';
const GeneralInfoProfile = props => {
  return (
    <div>
      <Header as="h4" content="General Information" />
      <Grid columns="equal">
        <Grid.Row stretched>
          <Grid.Column width={15}>
            <Form>
              <Form.Group>
                <Form.Field width={8}>
                  <InputWithLabel
                    label="Company ID"
                    placeholder="Company ID"
                    name="CompanyId"
                    type="text"
                    value={props.contentDetail.CompanyId}
                    handleChange={props.handleChange}
                    required={false}
                    fluid={true}
                    validate={props.errorInformation}
                    disabled={true}
                  />
                </Form.Field>
                <Form.Field width={8}>
                  <InputWithLabel
                    label="Based Currency"
                    placeholder="Based Currency"
                    name="CurrencyId"
                    type="select"
                    value={props.contentDetail.CurrencyId}
                    handleChange={props.handleChange}
                    options={props.currenciesListOptions}
                    required
                    fluid={true}
                    validate={props.errorInformation}
                    disabled={props.disabled}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={16}>
                  <InputWithLabel
                    label="Company Name"
                    placeholder="Company Name"
                    name="Name"
                    type="text"
                    value={props.contentDetail.Name}
                    handleChange={props.handleChange}
                    required
                    fluid={true}
                    validate={props.errorInformation}
                    disabled={props.disabled}
                  />
                </Form.Field>
                <Form.Field width={16}>
                  <InputWithLabel
                    label="Supplier Property"
                    placeholder="Supplier Property"
                    name="AccommodationProfileId"
                    type="select"
                    value={props.contentDetail.AccommodationProfileId}
                    handleChange={props.handleChange}
                    options={props.accommodationOptions}
                    required
                    fluid={true}
                    validate={props.errorInformation}
                    disabled={props.disabled || props.loading}
                    loading={props.loading}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

GeneralInfoProfile.propTypes = {
  handleChange: PropTypes.func,
  contentDetail: PropTypes.shape({
    Name: PropTypes.string,
    CompanyId: PropTypes.string,
    CurrencyId: PropTypes.string,
    AccommodationProfileId: PropTypes.string,
  }),
  errorInformation: PropTypes.object,
  currenciesListOptions: PropTypes.array,
  disabled: PropTypes.bool,
  accommodationOptions: PropTypes.array,
  loading: PropTypes.bool,
};
export default GeneralInfoProfile;
