import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { DRIVING } from './../constant/activityType';
const DescriptionActivity = props => {
  let { movement, desc } = props;
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <label style={{ fontWeight: 'bold' }}>
            {movement.MovementName === DRIVING && !movement.Item.ServiceItemId
              ? 'Self Transportation'
              : movement.MovementName === 'FREETIMELOCKED'
              ? 'Free Time'
              : movement.MovementDescription}
          </label>
          {desc}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
DescriptionActivity.propTypes = {
  movement: PropTypes.string,
  desc: PropTypes.string,
};

export default DescriptionActivity;
