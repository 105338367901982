import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Segment, Form } from 'semantic-ui-react';
import { connect } from 'react-redux';
import HeaderTitle from '../shared/components/common/headerSegment/HeaderTitle';
import ButtonWithIcon from '../shared/components/common/button/ButtonWithIcon';
import InputWithLabel from '../shared/components/common/input/InputWithLabel';
import InputDate from '../../components/input/inputDate';
import ModalAlert from '../../components/modal/modalAlert';
import { getAccountStatementAction } from '../home/homeAction';
import LoaderSegment from '../../components/modal/loaderModal';
import { getCustomerProfileListAction } from '../shared/actions/customerProfileAction';
import handleValidation from '../../scripts/emptyFieldValidation';
import _ from 'lodash';
import { convertDateFormat } from '../../components/genericFunction/moment';
class AccountReceivable extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      accountReceivable: {
        from: convertDateFormat(new Date(), 'YYYY-MM-DD'),
        to: convertDateFormat(new Date(), 'YYYY-MM-DD'),
        companyCode: '',
      },
      errors: {
        companyCode: { error: false, message: '' },
      },
      loadingDownload: false,
      openAlert: false,
      message: '',
      header: '',
    };
  }
  componentDidMount() {
    this.props.getCustomerProfileListAction('Customer');
  }

  static propTypes = {
    getCustomerProfileListAction: PropTypes.func,
    loadingCustomer: PropTypes.bool,
    listCustomerProfile: PropTypes.array,
    getAccountStatementAction: PropTypes.func,
  };
  componentDidUpdate() {}
  downloadReport = () => {
    const { from, to, companyCode } = this.state.accountReceivable;
    this.setState({ loadingDownload: true });
    if (!this.validate()) {
      this.props
        .getAccountStatementAction(companyCode, from, to)
        .then(res => {
          this.setState({ loadingDownload: false, url: res.value.data });
          window.open(res.value.data);
        })
        .catch(error => {
          this.setState({
            loadingDownload: false,
            message: error.response.data.Message,
            header: 'Failed',
          });
        });
    }
    this.setState({ loadingDownload: false });
  };

  setDate = (date, name) => {
    let accountReceivable = { ...this.state.accountReceivable };
    accountReceivable[name] = convertDateFormat(date, 'YYYY-MM-DD');
    this.setState({
      ...this.state,
      accountReceivable,
    });
  };

  handleChange = (e, { name, value }) => {
    let accountReceivable = { ...this.state.accountReceivable };
    accountReceivable[name] = value;
    this.setState(
      {
        ...this.state,
        accountReceivable,
      },
      () => this.validate()
    );
  };

  validate = () => {
    let isError = false;
    const { accountReceivable, errors } = this.state;
    let error1 = handleValidation(accountReceivable, ['companyCode'], errors);
    let error = { ...error1 };
    isError = !_.isEmpty(error);
    this.setState({
      errors: {
        ...error,
      },
    });
    return isError;
  };

  render() {
    const {
      accountReceivable,
      errors,
      loadingDownload,
      openAlert,
      message,
      header,
    } = this.state;
    const { loadingCustomer, listCustomerProfile } = this.props;
    return (
      <Grid style={{ marginTop: '30px' }}>
        <Grid.Row stretched>
          <Grid.Column width={15}>
            <Segment>
              <HeaderTitle title="Account Receivable" />
              <LoaderSegment loading={loadingCustomer || loadingDownload} />
              <Grid>
                <Grid.Row columns={4}>
                  <Grid.Column width={5}>
                    <Form>
                      <InputWithLabel
                        placeholder="Select Company"
                        options={listCustomerProfile}
                        type="select"
                        handleChange={this.handleChange}
                        value={accountReceivable.companyCode}
                        label="Company"
                        inline={false}
                        loading={loadingCustomer}
                        name="companyCode"
                        validate={errors}
                      />
                    </Form>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <Form>
                      <InputDate
                        label="From"
                        placeholder="From"
                        value={accountReceivable.from}
                        setDate={this.setDate}
                        labelStyle={{
                          display: 'block',
                          fontWeight: 'bold',
                          marginBottom: '4px',
                        }}
                        name="from"
                      />
                    </Form>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <Form>
                      <InputDate
                        label="To"
                        placeholder="To"
                        value={accountReceivable.to}
                        setDate={this.setDate}
                        labelStyle={{
                          display: 'block',
                          fontWeight: 'bold',
                          marginBottom: '4px',
                        }}
                        name="to"
                      />
                    </Form>
                  </Grid.Column>
                  <Grid.Column>
                    <Form>
                      <ButtonWithIcon
                        text="Download Report"
                        iconName="download"
                        handleOnClick={this.downloadReport}
                        primary={true}
                        style={{
                          width: '15em',
                          marginTop: '1.65em',
                          marginLeft: '-1em',
                        }}
                      />
                    </Form>
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <Grid columns={4} />
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <ModalAlert
          openModal={openAlert}
          handleClose={this.handleCloseAlert}
          header={header}
          content={message}
        />
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    listCustomerProfile: state.CustomerProfileStore.getCustomerProfileList.map(
      (data, i) => {
        return { key: i, text: data.Name, value: data.Code };
      }
    ),
    loadingCustomer: state.CustomerProfileStore.loadingGetCustomerProfile,
  };
}
export default connect(mapStateToProps, {
  getCustomerProfileListAction,
  getAccountStatementAction,
})(AccountReceivable);
