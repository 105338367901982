import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Grid } from 'semantic-ui-react';
import TablePickSharingPax from './TablePickSharingPax';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../../scripts/pagination';
import Pagination from '../../../../components/common/pagination';
class SegmentPickSharingPax extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      // searchInput: '',
      // activePage: 1,
      headerTable: [
        'Package name',
        'Destination',
        'Start Date',
        'Type',
        'Min.Guest',
        'Max.Guest',
        'Leftover Pax',
        'Durations',
        'Action',
      ],
    };
  }
  static propTypes = {
    data: PropTypes.array,
  };

  filtered = listData => {
    let { searchInput } = this.props;
    let skil = listData.filter(data => {
      return data.Title.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1;
    });
    return skil;
  };
  render() {
    let filterData = this.filtered(this.props.data);
    let pagedData = datasetPagination(filterData, 5);
    let listData = pagedData[this.props.activePage - 1];

    let { numbersFrom, numbersTo } = getNumberFromTo(
      listData,
      this.props.activePage,
      5
    );
    return (
      <Grid style={{ marginTop: '1em' }}>
        <Input
          //icon="search"
          placeholder="search"
          name="searchInput"
          value={this.props.searchInput}
          onChange={this.props.handleChange}
        />
        <TablePickSharingPax
          headerTable={this.state.headerTable}
          listData={listData}
          handlePickSharingPax={this.props.handlePickSharingPax}
          loading={this.props.loading}
        />
        <Pagination
          pagedData={pagedData}
          numbersTo={numbersTo}
          numbersFrom={numbersFrom}
          data={this.props.data}
          pageChange={this.props.pageChange}
          activePage={this.props.activePage}
        />
      </Grid>
    );
  }
}

SegmentPickSharingPax.propTypes = {
  handlePickSharingPax: PropTypes.func,
  loading: PropTypes.bool,
  activePage: PropTypes.number,
  handleChange: PropTypes.func,
  pageChange: PropTypes.func,
  searchInput: PropTypes.string,
};
export default SegmentPickSharingPax;
