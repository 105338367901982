import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { convertDateFormat, dateNow } from '../../../../../scripts/moment';
import ActionButton from '../button/ButtonActionTourTransaction';
import { Link } from 'react-router-dom';
const tableListCreatedTransaction = props => {
  let {
    listData,
    handleConvertPackage,
    isUserAdmin,
    isOperation,
    isFinance,
    isSales,
    tabActive,
  } = props;

  return (
    <Table basic="very" striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            {tabActive === 'quotation' ? 'Quotation No.' : 'Booking No.'}
          </Table.HeaderCell>
          <Table.HeaderCell>Tour Name</Table.HeaderCell>
          <Table.HeaderCell>Package Type</Table.HeaderCell>
          {isUserAdmin || isOperation || isFinance || (
            <Table.HeaderCell>Account Manager</Table.HeaderCell>
          )}
          {isUserAdmin || isOperation || (
            <Table.HeaderCell>Customer</Table.HeaderCell>
          )}
          <Table.HeaderCell>Total Guest</Table.HeaderCell>
          <Table.HeaderCell>Creation Date</Table.HeaderCell>
          <Table.HeaderCell>Start Tour</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          {isFinance || (
            <Table.HeaderCell textAlign="center">Action</Table.HeaderCell>
          )}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {listData.length > 0
          ? listData.map((data, index) => {
              let transactionStatus = data.TourTransactionStatus
                ? data.TourTransactionStatus
                : data.TransactionStatus;
              let packageType = handleConvertPackage(data.PackageType);
              return (
                <Table.Row key={'cell' + index}>
                  <Table.Cell>
                    {data.TransactionStatus === 'Booking_draft' ? (
                      <Link
                        to={`${
                          isFinance
                            ? '/Simple-Transaction-Detail/'
                            : '/tour-transaction/edit/Quotation/'
                        }${data.BookingId}`}
                      >
                        {data.BookingId}
                      </Link>
                    ) : (
                      <Link
                        to={
                          isFinance
                            ? `/Simple-Transaction-Detail/${data.BookingId}`
                            : '/tour-transaction/edit/' +
                              packageType +
                              '/' +
                              data.BookingId
                        }
                      >
                        {data.BookingId}
                      </Link>
                    )}
                  </Table.Cell>
                  <Table.Cell>{data.BookingName}</Table.Cell>
                  <Table.Cell>
                    {packageType === 'FixedDateVariable'
                      ? 'Ready Fixed Price'
                      : packageType === 'Fixed'
                      ? 'Series'
                      : packageType}
                  </Table.Cell>
                  {isUserAdmin || isOperation || isFinance || (
                    <Table.Cell>
                      {data.AccountManager ? data.AccountManager.Text : ''}
                    </Table.Cell>
                  )}
                  {isUserAdmin || isOperation || (
                    <Table.Cell>{data.CompanyName}</Table.Cell>
                  )}
                  <Table.Cell>
                    {data.TotalGuest
                      ? data.TotalGuest
                      : data.InfantPax + data.AdultPax + data.ChildPax}
                  </Table.Cell>
                  <Table.Cell>
                    {convertDateFormat(
                      data.CreateDate ? data.CreateDate : data.CreatedDate,
                      'D MMM YYYY'
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {convertDateFormat(data.StartDate, 'D MMM YYYY')}
                  </Table.Cell>
                  <Table.Cell>
                    {/* {data.TransactionStatus.split('_').join(' ')} */}
                    {transactionStatus === 'Booking_draft'
                      ? data.Quotation.IsAccepted
                        ? 'Quotation Accepted'
                        : 'Quotation Draft'
                      : transactionStatus
                      ? transactionStatus.split('_').join(' ')
                      : ''}
                  </Table.Cell>
                  <ActionButton
                    isShowCreateSimilar={
                      !isOperation && !isFinance && packageType !== 'Fixed'
                    }
                    isShowSendEmailTravelAgent={!isOperation && !isFinance}
                    isShowEditQuotation={!isOperation && !isFinance}
                    isGenerateResource={isOperation && !data.IsGenerated}
                    handleClick={props.handleClick}
                    id={
                      data.PackageType !== 3
                        ? data.TransactionStatus
                        : data.PackageType
                    }
                    idSendEmail={data.BookingId}
                    value={
                      data.PackageType !== 3
                        ? data.BookingId
                        : data.BookingTemplateRefId
                    }
                    packageType={data.PackageType}
                    // isAccepted={false}
                    //data={data}
                    isAccepted={
                      data.TransactionStatus === 'Booking_draft' &&
                      data.Quotation.IsAccepted === true
                        ? true
                        : false
                    }
                    transactionStatus={transactionStatus}
                    // isAccepted={false}
                    //data={data}
                    isOperation={isOperation}
                    isFinance={isFinance}
                    isGreaterThenEndDate={data.EndDate > dateNow}
                    isSales={isSales}
                  />
                </Table.Row>
              );
            })
          : null}
      </Table.Body>
    </Table>
  );
};

tableListCreatedTransaction.propTypes = {
  listData: PropTypes.array,
  handleClick: PropTypes.func,
  handleConvertPackage: PropTypes.func,
  isUserAdmin: PropTypes.bool,
  isOperation: PropTypes.bool,
  isFinance: PropTypes.bool,
  isSales: PropTypes.bool,
  tabActive: PropTypes.string,
};
export default tableListCreatedTransaction;
