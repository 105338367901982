import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Form } from 'semantic-ui-react';
import InputWithLabel from '../../../../components/input/inputWithLabel';
const ContactInfo = props => (
  <Grid>
    <Grid.Row>
      <Grid.Column width="15">
        <Header as="h3" content="Contact Information" />
        <Form>
          <Form.Field>
            <InputWithLabel
              label="Address"
              placeholder="Address"
              name="Address1"
              type="textArea"
              value={props.contactObject.Address1}
              handleChange={props.handleChange}
              required
              fluid={true}
              validate={props.errors}
              disabled={props.disablePage}
            />
          </Form.Field>
          <Form.Group>
            <Form.Field width="8">
              <InputWithLabel
                label="Address 2"
                placeholder="Address 2"
                name="Address2"
                type="text"
                value={props.contactObject.Address2}
                handleChange={props.handleChange}
                fluid={true}
                disabled={props.disablePage}
              />
            </Form.Field>
            <Form.Field width="8">
              <InputWithLabel
                label="Address 3"
                placeholder="Address 3"
                name="Address3"
                type="text"
                value={props.contactObject.Address3}
                handleChange={props.handleChange}
                fluid={true}
                disabled={props.disablePage}
              />
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <InputWithLabel
              label="GPS Address"
              placeholder="GPS Address"
              name="Landmark"
              type="text"
              value={props.contactObject.Landmark}
              handleChange={props.handleChange}
              fluid={true}
              disabled={props.disablePage}
            />
          </Form.Field>
          <Form.Group>
            <Form.Field width="8">
              <InputWithLabel
                label="Country"
                placeholder="Country"
                name="CountryId"
                type="select"
                value={props.contactObject.CountryId}
                handleChange={props.handleChange}
                options={props.listCountry}
                required
                fluid={true}
                validate={props.errors}
                disabled={props.disablePage}
              />
            </Form.Field>
            <Form.Field width="8">
              <InputWithLabel
                label="Region"
                placeholder="Region"
                name="RegionId"
                type="select"
                value={props.contactObject.RegionId}
                handleChange={props.handleChange}
                options={props.listRegion}
                required
                fluid={true}
                validate={props.errors}
                disabled={props.disablePage}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field width="8">
              <InputWithLabel
                label="City"
                placeholder="City"
                name="CityId"
                type="select"
                value={props.contactObject.CityId}
                handleChange={props.handleChange}
                options={props.listCity}
                required
                fluid={true}
                validate={props.errors}
                disabled={props.disablePage}
              />
            </Form.Field>
            <Form.Field width="8">
              <InputWithLabel
                label="Area"
                placeholder="Area"
                name="AreaId"
                type="select"
                value={props.contactObject.AreaId}
                handleChange={props.handleChange}
                options={props.listArea}
                required
                fluid={true}
                validate={props.errors}
                disabled={props.disablePage}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field width="8">
              <InputWithLabel
                label="Phone Number"
                placeholder="Phone Number"
                name="PhoneNbr"
                type="number"
                value={props.contactObject.PhoneNbr}
                handleChange={props.handleChange}
                required
                fluid={true}
                validate={props.errors}
                disabled={props.disablePage}
              />
            </Form.Field>
            <Form.Field width="8">
              <InputWithLabel
                label="Email"
                placeholder="Email"
                name="Email"
                type="text"
                value={props.contactObject.Email}
                handleChange={props.handleChange}
                required
                fluid={true}
                validate={props.errors}
                disabled={props.disablePage}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);
ContactInfo.propTypes = {
  contactObject: PropTypes.shape({
    PhoneNbr: PropTypes.string,
    Email: PropTypes.string,
    AreaId: PropTypes.string,
    CityId: PropTypes.string,
    RegionId: PropTypes.string,
    CountryId: PropTypes.string,
    Address1: PropTypes.string,
    Address2: PropTypes.string,
    Address3: PropTypes.string,
    Landmark: PropTypes.string,
  }),
  listRegion: PropTypes.array,
  listCountry: PropTypes.array,
  listCity: PropTypes.array,
  listArea: PropTypes.array,
  handleChange: PropTypes.func,
  onChangeDropdown: PropTypes.func,
  handleChangeAddress: PropTypes.func,
  errors: PropTypes.shape({
    Address1: PropTypes.string,
    PhoneNbr: PropTypes.string,
    Email: PropTypes.string,
    CityId: PropTypes.string,
    RegionId: PropTypes.string,
    CountryId: PropTypes.string,
    AreaId: PropTypes.string,
  }),
  loadingTransportation: PropTypes.bool,
  disablePage: PropTypes.bool,
};
export default ContactInfo;
