import apiClient from '../apiClient';
import { GET_FILTER_SHARED_PACKAGE_URL } from '../apiUrl';

const getFilterSharedpackageApi = (
  tourCategoryId,
  totalDays,
  startDate,
  destination
) => {
  return apiClient.get(
    GET_FILTER_SHARED_PACKAGE_URL(
      tourCategoryId,
      totalDays,
      startDate,
      destination
    )
  );
};

export default getFilterSharedpackageApi;
