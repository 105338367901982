const ColorContent = '#6b82e6';
const styles = {
  styleGrid: {
    marginTop: '3em',
  },
  styleRow: {
    marginTop: '1em',
  },
  styleRowTable: {
    marginTop: '3em',
  },
  colorContent: {
    color: ColorContent,
  },
  buttonStyle: {
    color: ColorContent,
    backgroundColor: 'white',
    marginBottom: '2em',
    marginTop: '-2.8em',
  },
  cardCalendar: {
    margin: '0px',
    overflow: 'auto',
    maxHeight: '320px',
  },
};
export default styles;
