import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Form } from 'semantic-ui-react';
import InputWithLabel from '../../../../components/input/inputWithLabel';
const GeneralInformation = props => {
  return (
    <div>
      <Grid>
        <Grid.Row>
          <Grid.Column width="15">
            <Header as="h3" content="General Information" />
            <Form>
              <Form.Field>
                <InputWithLabel
                  label="Restaurant Name"
                  type="text"
                  name="Name"
                  value={props.dataRestaurantProfile.Name}
                  handleChange={props.handleChange}
                  required
                  fluid={true}
                  validate={props.errors}
                  placeholder="Restaurant Name"
                  disabled={props.disablePage}
                />
              </Form.Field>
              <Form.Field>
                <InputWithLabel
                  label="Description"
                  type="textArea"
                  name="Description"
                  value={props.dataRestaurantProfile.Description}
                  handleChange={props.handleChange}
                  required
                  fluid={true}
                  validate={props.errors}
                  placeholder="Description"
                  disabled={props.disablePage}
                />
              </Form.Field>
              <Form.Field>
                <InputWithLabel
                  label="Long Description"
                  type="textArea"
                  name="LongDescription"
                  value={props.dataRestaurantProfile.LongDescription}
                  handleChange={props.handleChange}
                  //   required
                  fluid={true}
                  //   validate={props.errors}
                  placeholder="Long Description"
                  disabled={props.disablePage}
                />
              </Form.Field>
              <Form.Group>
                <Form.Field width="8">
                  <InputWithLabel
                    label="Restaurant Class"
                    type="select"
                    name="RatingId"
                    value={props.dataRestaurantProfile.RatingId}
                    handleChange={props.handleChange}
                    required
                    options={props.listRestaurantClass}
                    fluid={true}
                    validate={props.errors}
                    placeholder="Restaurant Class"
                    disabled={props.disablePage}
                    inline={false}
                  />
                </Form.Field>
                <Form.Field width="8">
                  <InputWithLabel
                    label="Speciality"
                    type="select"
                    name="RestaurantType"
                    value={props.dataRestaurantProfile.RestaurantType}
                    handleChange={props.handleChange}
                    required
                    options={props.listRestaurantSpecialty}
                    fluid={true}
                    validate={props.errors}
                    placeholder="Speciality"
                    disabled={props.disablePage}
                    inline={false}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

GeneralInformation.propTypes = {
  handleChange: PropTypes.func,
  dataRestaurantProfile: PropTypes.object,
  listRestaurantClass: PropTypes.array,
  listRestaurantSpecialty: PropTypes.array,
  errors: PropTypes.shape({
    NameError: PropTypes.string,
    DescriptionError: PropTypes.string,
    LongDescriptionError: PropTypes.string,
    RestaurantTypeError: PropTypes.string,
    RatingIdError: PropTypes.string,
  }),
  disablePage: PropTypes.bool,
};
export default GeneralInformation;
