import {
  GET_PENDING_COMPANY_FOR_APPROVAL,
  RESET_STATUS_COMPANY,
  POST_ACCEPT_COMPANY_REGISTRATION,
  POST_REJECT_COMPANY_REGISTRATION,
  GET_PUBLISHED_PACKAGE,
  POST_STATUS_TRANSACTION,
  GET_TRANSACTION_BY_STATUS_CREATED_LIST,
  GET_TRANSACTION_BY_STATUS_ON_HOLD_LIST,
  GET_PAYMENT_HISTORY,
  RESET_STATUS_PAYMENT_HISTORY,
  GET_SALES_REPORT,
} from './constant/actionTypes';
import getCompanyForApprovalApi from '../../api/getCompanyForApprovalApi';
import postStatusCompanyRegistrationApi from '../../api/postStatusCompanyRegistrationApi';
import { getPackagePublishedApi } from '../../api/packageApi';
import postStatusBookingConfirmationApi from '../../api/postStatusBookingConfirmationApi';
import getTransactionByStatusActionCreatedApi from '../../api/getTransactionHistoryByStatusApi';
import getTransactionByStatusActionOnHoldApi from '../../api/getTransactionHistoryByStatusApi';
import getPaymentHistoryApi from '../../api/getPaymentHistoryApi';
import getSalesReportApi from '../../api/userProfile/getSalesReportApi';
import getAccountStatementApi from '../../api/accountReceivable/getAccountStatementApi';
export const getPendingCompanyAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_PENDING_COMPANY_FOR_APPROVAL,
      payload: getCompanyForApprovalApi(),
    });
  };
};

export const resetCompanyAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_STATUS_COMPANY,
    });
  };
};

export const postConfirmCompanyRegistrationAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_ACCEPT_COMPANY_REGISTRATION,
      payload: postStatusCompanyRegistrationApi(data),
    });
  };
};
export const postRejectCompanyRegistrationAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_REJECT_COMPANY_REGISTRATION,
      payload: postStatusCompanyRegistrationApi(data),
    });
  };
};

export const getPublishedPackageAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_PUBLISHED_PACKAGE,
      payload: getPackagePublishedApi(),
    });
  };
};

export const updateStatusBookingConfirmationAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_STATUS_TRANSACTION,
      payload: postStatusBookingConfirmationApi(data),
    });
  };
};

export const getTransactionByStatusCreatedAction = (
  start,
  end,
  createStart,
  createEnd,
  status
) => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSACTION_BY_STATUS_CREATED_LIST,
      payload: getTransactionByStatusActionCreatedApi(
        start,
        end,
        createStart,
        createEnd,
        status
      ),
    });
  };
};

export const getTransactionByStatusOnHoldAction = (
  start,
  end,
  createStart,
  createEnd,
  status
) => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSACTION_BY_STATUS_ON_HOLD_LIST,
      payload: getTransactionByStatusActionOnHoldApi(
        start,
        end,
        createStart,
        createEnd,
        status
      ),
    });
  };
};

export const getPaymentHistoryAction = (start, end, createStart, createEnd) => {
  return dispatch => {
    return dispatch({
      type: GET_PAYMENT_HISTORY,
      payload: getPaymentHistoryApi(start, end, createStart, createEnd),
    });
  };
};

export const resetPaymentHistoryAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_STATUS_PAYMENT_HISTORY,
    });
  };
};

export const getSalesReportAction = (startMonth, endMonth, year) => {
  return dispatch => {
    return dispatch({
      type: GET_SALES_REPORT,
      payload: getSalesReportApi(startMonth, endMonth, year),
    });
  };
};

export const getAccountStatementAction = (companyCode, startDate, endDate) => {
  return dispatch => {
    return dispatch({
      // type: GET_PAYMENT_HISTORY,
      payload: getAccountStatementApi(companyCode, startDate, endDate),
    });
  };
};
