import {
  RESET_STATUS_EXCURSION_PAGE,
  POST_EXCURSION_PROFILE,
  GET_SERVICE_PRICE,
  PUT_EXCURSION_PROFILE,
  GET_EXCURSION_PRICE_TYPE,
  GET_EXCURSION_OBJECT_EXPORT_DATA,
  GET_EXCURSION_PRICE_EXPORT_DATA,
  DELETE_EXCURSION_OBJECT,
} from './constant/actionTypes';
import getExcursionPriceApi from '../../api/servicePrice/getServicePriceApi';
import postExcursionApi from '../../api/excurtion/postExcursionApi';
import putExcursionActionApi from '../../api/excurtion/putExcursionApi';
import getExcursionPriceTypeApi from '../../api/excurtion/getExcursionPriceTypeApi';
import getExportExcursionObjectApi from '../../api/excurtion/getExportExcursionObjectApi';
import getExportExcursionPriceApi from '../../api/excurtion/getExportExcursionPriceApi';
import { deleteExcursionObjectApi } from '../../api/excurtion/excursionApi';
const resetExcursionPageAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_STATUS_EXCURSION_PAGE,
    });
  };
};
const postExcursionAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_EXCURSION_PROFILE,
      payload: postExcursionApi(data),
    });
  };
};
const putExcursionAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_EXCURSION_PROFILE,
      payload: putExcursionActionApi(id, data),
    });
  };
};
const getExcursionPriceTypeAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_EXCURSION_PRICE_TYPE,
      payload: getExcursionPriceTypeApi(),
    });
  };
};
const getExcurtionPrice = id => {
  return dispatch => {
    return dispatch({
      type: GET_SERVICE_PRICE,
      payload: getExcursionPriceApi(id),
    });
  };
};
const getExportExcursionObjectAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_EXCURSION_OBJECT_EXPORT_DATA,
      payload: getExportExcursionObjectApi,
    });
  };
};
const getExportExcursionPriceAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_EXCURSION_PRICE_EXPORT_DATA,
      payload: getExportExcursionPriceApi,
    });
  };
};

const deleteExcursionObjectAction = id => {
  return dispatch => {
    return dispatch({
      type: DELETE_EXCURSION_OBJECT,
      payload: deleteExcursionObjectApi(id),
    });
  };
};

export {
  resetExcursionPageAction,
  postExcursionAction,
  getExcurtionPrice,
  putExcursionAction,
  getExcursionPriceTypeAction,
  getExportExcursionObjectAction,
  getExportExcursionPriceAction,
  deleteExcursionObjectAction,
};
