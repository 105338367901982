import { validatePhone } from '../../../../../components/genericFunction/validation';
const handleValidation = (state, fields) => {
  const validate = {};
  fields.map(field => {
    if (state[field] === '') {
      validate[field] = {
        error: true,
        message: `Field can't be empty!!`,
      };
    } else if (field === 'Email' && state.Email.indexOf('@' && '.') === -1) {
      validate[field] = {
        error: true,
        message: `Email format not valid!!`,
      };
    } else if (field === 'PhoneNbr') {
      let val = validatePhone(state.PhoneNbr);
      if (val && val.length > 0) {
        validate[field] = {
          error: true,
          message: `Phone number format not valid!!`,
        };
      } else {
        delete validate[field];
      }
    } else {
      delete validate[field];
    }
    return validate;
  });

  return validate;
};

export default handleValidation;
