import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Segment, Form, Button } from 'semantic-ui-react';
import styles from '../resourceBookingList/styles';
import HeaderTitle from '../../shared/components/common/headerSegment/HeaderTitle';
import InputSearch from '../../../components/input/inputSearch';
import useApiCall from './hooks/useApiCall';
import {
  postResourceBookingByTypeApi,
  getResourceTypeEunumApi,
} from '../../../api/resourceBookingApi';
import TableListResourceBookingFilter from './components/table/TableListResourceBookingFilter';
import InputWithLabel from '../../shared/components/common/input/InputWithLabel';
import { setTypeEnum } from './services/helper/setTypeEnum';
import ModalDateRangeCheckIn from '../../shared/components/modal/ModalDateRangeCheckIn';
import { convertDateFormat, addDate } from '../../../scripts/moment';
import { saveStartDateEndDate } from '../../shared/actions/resourceBookingAction';
import { Redirect } from 'react-router-dom';

const TODAY = new Date();

const ResourceBookingFilter = () => {
  // ============================= initial =============================
  const [dataFilter, setDataFilter] = useState({
    ResourceBookingType: [],
    StartDueDate: convertDateFormat(TODAY, 'YYYY-MM-DD'),
    EndDueDate: convertDateFormat(addDate(TODAY, 30, 'd'), 'YYYY-MM-DD'),
  });

  const [activePage, setActivePage] = useState(1);
  const [searchInput, setSearchInput] = useState('');
  const [loadingResourceType, setLoadingResourceType] = useState(false);
  const [resourceTypeEnum, setResourceTypeEnum] = useState([]);
  const [openDateRange, setOpenDateRange] = useState(false);
  const [selectedResourceType, setSelectedResourceType] = useState([]);

  const [isRedirect, setIsRedirect] = useState(false);
  const [objectRedirect, setObjectRedirect] = useState({});

  const [startDate, setStartDate] = useState(
    convertDateFormat(TODAY, 'YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(
    convertDateFormat(addDate(TODAY, 30, 'd'), 'YYYY-MM-DD')
  );

  // ============================= custom hooks =============================
  const [
    resourceBookingLoading,
    resourceBookingList,
    loadResourceBookingList,
  ] = useApiCall(postResourceBookingByTypeApi, dataFilter);

  // ============================= action =============================
  const dispatch = useDispatch();

  const saveStartDateEndDateAction = param =>
    dispatch(saveStartDateEndDate(param));

  const saveDate = (StartDate, EndDate, id, resourceBookingId) => {
    saveStartDateEndDateAction({
      startDate: convertDateFormat(StartDate, 'DD MMM YYYY'),
      endDate: convertDateFormat(EndDate, 'DD MMM YYYY'),
      // id,
      resourceBookingId,
    });
    setObjectRedirect({
      id: id,
      resourceBookingId: resourceBookingId,
    });
  };

  const handleClickId = (e, { value }) => {
    //   const handleClickId = e => {
    saveDate(value.startDate, value.endDate, value.id, value.resourceBookingId);
    setIsRedirect(true);
  };

  // ============================= helper =============================
  const pageChange = (e, { activePage }) => {
    setActivePage(activePage);
  };

  const setDateDataFilter = (from, to) => {
    let dataToFilter = { ...dataFilter };
    dataToFilter.StartDueDate = from;
    dataToFilter.EndDueDate = to;
    setDataFilter(dataToFilter);
    loadResourceBookingList(dataToFilter);
  };

  const actionDateRange = id => {
    let from = convertDateFormat(id.from, 'YYYY-MM-DD');
    let to = convertDateFormat(id.to, 'YYYY-MM-DD');
    handleCloseDateRange();
    setStartDate(from);
    setEndDate(to);
    setDateDataFilter(from, to);
  };

  const setTypeDataFilter = selectedType => {
    let dataToFilter = { ...dataFilter };
    dataToFilter.ResourceBookingType = selectedType.map(item => {
      let res = resourceTypeEnum.find(resource => resource.value === item);
      return {
        MainResouceBookingType: res.type,
        SubResouceBookingType: res.value,
      };
    });
    setDataFilter(dataToFilter);
  };

  const getResourceTypeEnum = () => {
    setLoadingResourceType(true);
    getResourceTypeEunumApi()
      .then(res => {
        setLoadingResourceType(false);
        setResourceTypeEnum(setTypeEnum(res.data));
      })
      .catch(() => {
        setLoadingResourceType(false);
      });
  };
  // ============================= handler =============================
  const handleChangeSearch = e => {
    setSearchInput(e.target.value);
    setActivePage(1);
  };

  const handleOpenDateRange = () => {
    setOpenDateRange(true);
  };

  const handleCloseDateRange = () => {
    setOpenDateRange(false);
  };

  const handleFilterByType = (e, { value }) => {
    setSelectedResourceType(value);
    setTypeDataFilter(value);
  };

  const handleSelectType = () => {
    loadResourceBookingList(dataFilter);
  };

  // ============================= lifecycle =============================
  useEffect(() => {
    getResourceTypeEnum();
  }, []);

  // ============================= const/var =============================
  let endDueDate = !endDate ? '' : convertDateFormat(endDate, 'DD MMM YYYY');
  let startDueDate = !startDate
    ? ''
    : convertDateFormat(startDate, 'DD MMM YYYY');
  let loading = resourceBookingLoading || loadingResourceType;

  return isRedirect ? (
    <Redirect
      to={`/Resource-Detail/Detail/${objectRedirect.resourceBookingId}/${objectRedirect.id}`}
    />
  ) : (
    <Grid columns="equal" style={styles.styleGrid}>
      <Grid.Row stretched>
        <Grid.Column width={16}>
          <Segment loading={loading}>
            <HeaderTitle
              title="Resource Booking Filter"
              iconName="clipboard list"
            />
            <Grid>
              <Grid.Row columns="3">
                <Grid.Column width={5}>
                  <InputSearch
                    inputStyle={styles.inputStyle}
                    inputInnerStyle={styles.inputStyleInner}
                    icon="search"
                    placeholder="Search"
                    name="searchInput"
                    value={searchInput}
                    handleChange={handleChangeSearch}
                  />
                </Grid.Column>
                <Grid.Column width={6}>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={12}>
                        <Form>
                          <Form.Field>
                            <InputWithLabel
                              placeholder="Choose Resource Type"
                              type="select"
                              name="resourceType"
                              value={selectedResourceType}
                              handleChange={handleFilterByType}
                              options={resourceTypeEnum}
                              //   onClick={this.handleClick}
                              multiple={true}
                              fluid={false}
                              inline={false}
                            />
                          </Form.Field>
                        </Form>
                      </Grid.Column>
                      <Grid.Column width={4} style={{ alignSelf: 'center' }}>
                        <Button positive onClick={handleSelectType}>
                          Select
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
                <Grid.Column width={5}>
                  <Grid>
                    <Grid.Column
                      width={13}
                      style={{ alignSelf: 'center' }}
                      textAlign="right"
                    >
                      <strong>
                        Due Date Range:
                        <br />
                        {startDueDate} - {endDueDate}
                      </strong>
                    </Grid.Column>
                    <Grid.Column width={3} style={{ alignSelf: 'center' }}>
                      <Button
                        icon="filter"
                        basic
                        size="small"
                        floated="right"
                        positive
                        onClick={handleOpenDateRange}
                      />
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row style={styles.styleRowTable}>
                <Grid.Column width={16}>
                  <TableListResourceBookingFilter
                    data={resourceBookingList}
                    activePage={activePage}
                    setActivePage={setActivePage}
                    pageChange={pageChange}
                    searchInput={searchInput}
                    resourceBookingTypeFilter={dataFilter.ResourceBookingType}
                    handleClickId={handleClickId}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <ModalDateRangeCheckIn
              contentHeader="Resource Booking Due Date"
              openModal={openDateRange}
              close={handleCloseDateRange}
              actionDateRange={actionDateRange}
              startDate={startDate}
              endDate={endDate}
            />
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ResourceBookingFilter;
