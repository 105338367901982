import { getTime } from './moment';
const FilterData = (data, filterBy, searchInput) => {
  return filterBy === 'Name'
    ? data.filter(data => {
        return (
          data.Name.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1
        );
      })
    : filterBy === 'FirstName'
    ? data.filter(data => {
        return (
          data.FirstName.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1
        );
      })
    : filterBy === 'CashFlow'
    ? data.filter(data => {
        return (
          data.CashFlowType.toLowerCase().indexOf(searchInput.toLowerCase()) !==
          -1
        );
      })
    : filterBy === 'EXCURTION'
    ? data.filter(data => {
        return (
          data.Name.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1 ||
          (data.AttractionType.Name &&
            data.AttractionType.Name.toLowerCase().indexOf(
              searchInput.toLowerCase()
            ) !== -1)
        );
      })
    : filterBy === 'Date'
    ? data.filter(data => {
        return data.StartDate
          ? data.StartDate.toLowerCase().indexOf(searchInput.toLowerCase()) !==
              -1
          : data.EffectiveDate.toLowerCase().indexOf(
              searchInput.toLowerCase()
            ) !== -1;
      })
    : filterBy === 'CompanyName'
    ? data.filter(data => {
        return (
          data.CreatedBy.CompanyName.toLowerCase().indexOf(
            searchInput.toLowerCase()
          ) !== -1
        );
      })
    : filterBy === 'Title'
    ? data.filter(data => {
        return (
          data.Title.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1
        );
      })
    : filterBy === 'Username'
    ? data.filter(data => {
        return (
          data.Username.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1
        );
      })
    : filterBy === 'MasterRefrence'
    ? data.filter(data => {
        let filter =
          data.Name ||
          data.Description ||
          data.CountryId ||
          data.Category ||
          data.EnglishName ||
          data.LocalName ||
          data.JobCode ||
          data.JobTitle ||
          data.JobPositionLevel ||
          data.Id;
        if (filter === null) {
          return '';
        } else if (typeof filter === 'string') {
          // filter =
          //   typeof filter === 'string'
          return filter.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1;
        } else if (typeof filter !== 'string') {
          return (
            filter
              .toString()
              .toLowerCase()
              .indexOf(searchInput.toLowerCase()) !== -1
          );
        } else {
          return filter;
        }
      })
    : filterBy === 'Image'
    ? data.filter(data => {
        const filter = data.ImageContent
          ? data.ImageContent
          : null || data.ImageUrl
          ? data.ImageUrl
          : null;
        if (filter === null) {
          return '';
        } else {
          return filter.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1;
        }
      })
    : filterBy === 'UserName'
    ? data.filter(data => {
        return (
          data.Username.toLowerCase().indexOf(searchInput.toLowerCase()) !==
            -1 ||
          (data.CompanyCode &&
            data.CompanyCode.toLowerCase().indexOf(
              searchInput.toLowerCase()
            ) !== -1)
        );
      })
    : filterBy === 'PaymentMethod'
    ? data.filter(data => {
        return (
          data.Country.Text.toLowerCase().indexOf(searchInput.toLowerCase()) !==
          -1
        );
      })
    : filterBy === 'PendingCompany'
    ? data.filter(data => {
        return (
          data.CompanyDetail.Name.toLowerCase().indexOf(
            searchInput.toLowerCase()
          ) !== -1
        );
      })
    : filterBy === 'bookingConfirm'
    ? data.filter(data => {
        return (
          // data.Title ||
          // data.TransactionStatus ||
          // data.CreatedBy.CompanyName ||
          // data.Status ||
          // data.Id;
          data.BookingName.toLowerCase().indexOf(searchInput.toLowerCase()) !==
            -1 ||
          data.BookingId.toLowerCase().indexOf(searchInput.toLowerCase()) !==
            -1 ||
          data.CompanyName.toLowerCase().indexOf(searchInput.toLowerCase()) !==
            -1
        );
        // return (
        //   filter
        //     .toLowerCase()
        //     .indexOf(searchInput.toLowerCase()) !== -1
        // );
      })
    : filterBy === 'AccomodationRoom'
    ? data.filter(data => {
        return (
          data.AccommodationProfileName.toLowerCase().indexOf(
            searchInput.toLowerCase()
          ) !== -1
        );
      })
    : filterBy === 'Subtitle'
    ? data.filter(data => {
        return (
          data.Subtitle.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1
        );
      })
    : filterBy === 'RestaurantMenu'
    ? data.filter(data => {
        return (
          data.RestaurantProfileName.toLowerCase().indexOf(
            searchInput.toLowerCase()
          ) !== -1
        );
      })
    : filterBy === 'PriceType'
    ? data.filter(data => {
        return (
          data.ServicePriceType.toLowerCase().indexOf(
            searchInput.toLowerCase()
          ) !== -1
        );
      })
    : filterBy === 'TransportationUnit'
    ? data.filter(data => {
        return (
          data.TransportationTypeName.toLowerCase().indexOf(
            searchInput.toLowerCase()
          ) !== -1
        );
      })
    : filterBy === 'FormatedDate'
    ? // ? data.filter(data => {
      //     return searchInput
      //       ? data.EffectiveDate
      //         ? moment(
      //             data.EffectiveDate
      //           ).format('DD/MM/YYYY') ===
      // moment(searchInput).format(
      //   'DD/MM/YYYY'
      // )
      //         : moment(
      //             data.StartDate
      //           ).format('DD/MM/YYYY') ===
      //           moment(searchInput).format(
      //             'DD/MM/YYYY'
      //           )
      //       : [];
      //   })
      data.filter(d => {
        var time = searchInput === '' ? '' : getTime(searchInput);
        return time === ''
          ? data
          : time >= getTime(d.StartDate) && time <= getTime(d.EndDate);
      })
    : filterBy === 'TransportationPrice'
    ? data.filter(data => {
        return (
          data.OriginCity.Name.toLowerCase().indexOf(
            searchInput.toLowerCase()
          ) !== -1
        );
      })
    : filterBy === 'schemeName'
    ? data.filter(data => {
        return (
          data.Type.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1 ||
          (data.Id &&
            data.Id.toString().indexOf(searchInput.toLowerCase()) !== -1)
        );
      })
    : filterBy === 'notAllowedCompany'
    ? data.filter(data => {
        return (
          data.CompanyName.toLowerCase().indexOf(searchInput.toLowerCase()) !==
            -1 ||
          (data.CompanyCode &&
            data.CompanyCode.toLowerCase().indexOf(
              searchInput.toLowerCase()
            ) !== -1)
        );
      })
    : filterBy === 'customerProfile'
    ? data.filter(data => {
        return (
          data.Name.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1 ||
          (data.Code &&
            data.Code.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1)
        );
      })
    : filterBy === 'exchangeRate'
    ? data.filter(data => {
        return searchInput === ''
          ? data
          : getTime(data.EffectiveDate) >= getTime(searchInput);
      })
    : null;
};

export default FilterData;
