import * as types from '../../../actions/actionTypes';
// import { getFormInitialValues } from 'redux-form';

const initialState = {
  getCustomEnumByCatagoriesRevenueRange: [],
  loadingGetRevenue: false,
  getCustomEnumByCatagoriesTotalBranch: [],
  loadingGetTotalBranch: false,
  getCustomEnumByCatagoriesTotalEmploye: [],
  getPaymentMethodList: [],
  getPaymentMethodListStatus: '',
  getPaymentMethodByCountryId: [],
  getPaymentMethodByCountryIdStatus: '',
  getPaymentChoiceStatus: '',
  postPaymentMethodStatus: '',
  postPaymentMethod: '',
  postTopUpUsingPaymentGateway: [],
  postTopUpUsingPaymentGatewayStatus: '',
  deletePaymentMethod: [],
  deletePaymentMethodStatus: '',
  getPaymentChoice: [],
  loading: false,
  errors: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CUSTOM_ENUM_BY_CATAGORIES_REVENUE_RANGE:
      return { ...state };
    case types.GET_CUSTOM_ENUM_BY_CATAGORIES_REVENUE_RANGE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        loadingGetRevenue: false,
        getCustomEnumByCatagoriesRevenueRange: [],
      };
    case types.GET_CUSTOM_ENUM_BY_CATAGORIES_REVENUE_RANGE_PENDING:
      return { ...state, loading: true, loadingGetRevenue: true };
    case types.GET_CUSTOM_ENUM_BY_CATAGORIES_REVENUE_RANGE_FULFILLED:
      return {
        ...state,
        getCustomEnumByCatagoriesRevenueRange: action.payload.data,
        loading: false,
        loadingGetRevenue: false,
      };
    case types.GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_BRANCH:
      return { ...state };
    case types.GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_BRANCH_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getCustomEnumByCatagoriesTotalBranch: [],
        loadingGetTotalBranch: false,
      };
    case types.GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_BRANCH_PENDING:
      return { ...state, loading: true, loadingGetTotalBranch: true };
    case types.GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_BRANCH_FULFILLED:
      return {
        ...state,
        getCustomEnumByCatagoriesTotalBranch: action.payload.data,
        loading: false,
        loadingGetTotalBranch: false,
      };
    case types.GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_EMPLOYE:
      return { ...state };
    case types.GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_EMPLOYE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
      };
    case types.GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_EMPLOYE_PENDING:
      return { ...state, loading: true };
    case types.GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_EMPLOYE_FULFILLED:
      return {
        ...state,
        getCustomEnumByCatagoriesTotalEmploye: action.payload.data,
        loading: false,
      };
    case types.GET_PAYMENT_METHOD:
      return { ...state };
    case types.GET_PAYMENT_METHOD_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getPaymentMethodListStatus: 'failed',
        loading: false,
      };
    case types.GET_PAYMENT_METHOD_PENDING:
      return { ...state, loading: true };
    case types.GET_PAYMENT_METHOD_FULFILLED:
      return {
        ...state,
        getPaymentMethodListStatus: 'success',
        getPaymentMethodList: action.payload.data,
        loading: false,
      };
    case types.GET_PAYMENT_METHOD_BY_COUNTRY_ID:
      return { ...state };
    case types.GET_PAYMENT_METHOD_BY_COUNTRY_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getPaymentMethodByCountryIdStatus: 'failed',
      };
    case types.GET_PAYMENT_METHOD_BY_COUNTRY_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_PAYMENT_METHOD_BY_COUNTRY_ID_FULFILLED:
      return {
        ...state,
        getPaymentMethodByCountryIdStatus: 'success',
        getPaymentMethodByCountryId: action.payload.data,
        loading: false,
      };
    case types.GET_PAYMENT_METHOD_CHOICE_LIST:
      return { ...state };
    case types.GET_PAYMENT_METHOD_CHOICE_LIST_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loading: false,
        getPaymentChoiceStatus: 'failed',
      };
    case types.GET_PAYMENT_METHOD_CHOICE_LIST_PENDING:
      return { ...state, loading: true };
    case types.GET_PAYMENT_METHOD_CHOICE_LIST_FULFILLED:
      return {
        ...state,
        getPaymentChoice: action.payload.data,
        loading: false,
        getPaymentChoiceStatus: 'success',
      };
    case types.POST_PAYMENT_METHOD_IN_COUNTRY:
      return { ...state };
    case types.POST_PAYMENT_METHOD_IN_COUNTRY_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loading: false,
        postPaymentMethodStatus: 'failed',
      };
    case types.POST_PAYMENT_METHOD_IN_COUNTRY_PENDING:
      return { ...state, loading: true };
    case types.POST_PAYMENT_METHOD_IN_COUNTRY_FULFILLED:
      return {
        ...state,
        postPaymentMethod: action.payload.data,
        loading: false,
        postPaymentMethodStatus: 'success',
      };
    case types.POST_TOP_UP_PAYMENT_GATEWAY:
      return { ...state };
    case types.POST_TOP_UP_PAYMENT_GATEWAY_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response.data.Message,
          message: action.payload.response.data.error_description,
        },
        postTopUpUsingPaymentGatewayStatus: 'failed',
      };
    case types.POST_TOP_UP_PAYMENT_GATEWAY_PENDING:
      return { ...state, loading: true };
    case types.POST_TOP_UP_PAYMENT_GATEWAY_FULFILLED:
      return {
        ...state,
        postTopUpUsingPaymentGateway: action.payload.data,
        postTopUpUsingPaymentGatewayStatus: 'success',
        loading: false,
      };

    case types.DELETE_PAYMENT_METHOD_IN_COUNTRY:
      return { ...state };
    case types.DELETE_PAYMENT_METHOD_IN_COUNTRY_REJECTED:
      return {
        ...state,
        errors: {
          global: action.payload.response.data.Message,
          message: action.payload.response.data.error_description,
        },
        deletePaymentMethodStatus: 'failed',
      };
    case types.DELETE_PAYMENT_METHOD_IN_COUNTRY_PENDING:
      return { ...state, loading: true };
    case types.DELETE_PAYMENT_METHOD_IN_COUNTRY_FULFILLED:
      return {
        ...state,
        deletePaymentMethod: action.payload.data,
        deletePaymentMethodStatus: 'success',
        loading: false,
      };
    case types.RESET_CUSTOM_ENUM:
      return {
        ...state,
        getCustomEnumByCatagoriesStatus: '',
        getPaymentMethodByCountryIdStatus: '',
        getPaymentMethodListStatus: '',
        getPaymentChoiceStatus: '',
        postPaymentMethodStatus: '',
        postTopUpUsingPaymentGatewayStatus: '',
        deletePaymentMethodStatus: '',
      };
    default:
      return state;
  }
};

export default reducer;
