import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';
// import StepImage from '../common/step';
import PanelThumbnailImage from '../panel/panelThumbnailImage';

const ModalDownloadImage = props => (
  <Modal
    //dimmer="blurring"
    closeIcon
    inverted
    open={props.openModal}
    onClose={props.handleClose}
    // size="medium"
    size="small"
    style={{ width: '60em' }}
  >
    <Modal.Header>{props.header}</Modal.Header>
    <Modal.Content>
      <h5>{props.content}</h5>
      <PanelThumbnailImage
        image={props.image}
        handleChange={props.handleChangePrimaryImage}
      />
    </Modal.Content>
    <Modal.Actions>
      {/* {props.indexActive === 0 || (
        <Button
          content="Back"
          basic
          color="blue"
          onClick={props.handleBackStep}
        />
      )} */}
      <Button
        content="Download Image"
        primary
        onClick={props.handleDownloadImage}
      />
    </Modal.Actions>
  </Modal>
);

ModalDownloadImage.propTypes = {
  openModal: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  content: PropTypes.string,
  handleChange: PropTypes.func,
  imagePreview: PropTypes.func,
  handleNext: PropTypes.func,
  handleBackStep: PropTypes.func,
  image: PropTypes.array,
  listStepImage: PropTypes.array,
  indexActive: PropTypes.number,
  handleChangePrimaryImage: PropTypes.func,
  // imageRatio: PropTypes.object,
  imageRatio: PropTypes.array,
  handleDownloadImage: PropTypes.func,
};

export default ModalDownloadImage;
