import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../../scripts/pagination';
import FilterData from '../../../../scripts/filterDataTable';
import { Input, Grid, Segment } from 'semantic-ui-react';
import headerTabelAccomodation from '../../constant/tableHeader';
import TableAccomodationList from './TableAccommodationList';
import ButtonAction from '../../../../components/common/ButtonGroupSupplier';
import HeaderTitle from '../../../../components/common/headerTitle';
import Pagination from '../../../../components/common/pagination';

class AccommodationList extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
    };
  }
  static propTypes = {
    data: PropTypes.array,
  };
  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  handleChangeDate = (value, name) => {
    this.setState({ [name]: value, activePage: 1 });
  };
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  render() {
    let {
      data,
      filterBy,
      buttonNew,
      butttonEdit,
      buttonDelete,
      butttonExport,
      buttonUploadData,
      buttonDownloadTemplate,
      buttonExportItem,
    } = this.props;
    let { searchInput, activePage } = this.state;
    let filterData = FilterData(data, filterBy, searchInput);
    let pagedData = datasetPagination(filterData, 7);
    let listData = pagedData[activePage - 1];

    let { numbersFrom, numbersTo } = getNumberFromTo(listData, activePage, 7);
    let handleChange =
      filterBy === 'FormatedDate'
        ? this.handleChangeDate
        : filterBy === 'exchangeRate'
        ? this.handleChangeDate
        : this.handleChange;
    return (
      <div>
        <Grid columns="equal" style={{ marginTop: '30px' }}>
          <Grid.Row stretched>
            <Grid.Column>
              <Segment>
                <HeaderTitle title="Accommodation List" />
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={4}>
                      <Input
                        icon="search"
                        placeholder="search"
                        name="searchInput"
                        value={searchInput}
                        onChange={handleChange}
                      />
                    </Grid.Column>
                    <Grid.Column width={12}>
                      <ButtonAction
                        buttonNew={buttonNew}
                        butttonExport={butttonExport}
                        buttonUploadData={buttonUploadData}
                        buttonDownloadTemplate={buttonDownloadTemplate}
                        buttonExportItem={buttonExportItem}
                        isSupplierList={true}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <TableAccomodationList
                  data={data}
                  headerTable={headerTabelAccomodation}
                  pageChange={this.pageChange}
                  listData={listData}
                  pagedData={pagedData}
                  butttonEdit={butttonEdit}
                  buttonDelete={buttonDelete}
                />
                <Pagination
                  pagedData={pagedData}
                  numbersTo={numbersTo}
                  numbersFrom={numbersFrom}
                  data={data}
                  pageChange={this.pageChange}
                  activePage={activePage}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

AccommodationList.propTypes = {
  listData: PropTypes.array,
  pagedData: PropTypes.array,
  title: PropTypes.string,
  data: PropTypes.array,
  buttonNew: PropTypes.func,
  butttonEdit: PropTypes.func,
  buttonDelete: PropTypes.func,
  butttonExport: PropTypes.func,
  buttonUploadData: PropTypes.func,
  buttonDownloadTemplate: PropTypes.func,
  filterBy: PropTypes.string,
  buttonExportItem: PropTypes.func,
};

export default AccommodationList;
