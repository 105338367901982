import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Image } from 'semantic-ui-react';
import ButtonClose from '../../../components/button/buttonCloseModal';
// import NoImage from '../../assets/Icons/no_image.jpg';
import NoImage from './../../../assets/Icons/no_image.jpg';
const ModalPaymentProof = props => {
  let { handleCloseModalPaymentProof, isOpen, imageUrl } = props;
  return (
    <Modal
      dimmer="blurring"
      inverted
      open={isOpen}
      onClose={handleCloseModalPaymentProof}
      size="mini"
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        Payment Proof <ButtonClose onClose={handleCloseModalPaymentProof} />
      </Modal.Header>
      <Modal.Content>
        <Image src={imageUrl || NoImage} />
      </Modal.Content>
    </Modal>
  );
};

ModalPaymentProof.propTypes = {
  isOpen: PropTypes.bool,
  imageUrl: PropTypes.string,
  handleCloseModalPaymentProof: PropTypes.func,
};

export default ModalPaymentProof;
