import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';
import {
  CurrencyAndTextFieldWithError,
  DoubleDatePickerWithError,
} from '../../../components/common/fieldWithError';
import { StyleModal } from '../../../assets/styles/size';
import ModalLoader from '../../../components//modal/loaderModal';
const ModalSystemMargin = props => {
  return (
    <Modal
      dimmer="blurring"
      closeIcon
      inverted
      open={props.open}
      onClose={props.handleClose}
      size="small"
      style={StyleModal.modalWidth}
      closeOnDimmerClick={false}
    >
      <Modal.Header>{props.header}</Modal.Header>
      <Modal.Content>
        <ModalLoader loading={props.loading} />
        <div style={{ marginBottom: '10px' }}>
          Add Margin for
          <strong>
            {'     '}
            {props.contentField.companyName}
            {'     '}
            {props.contentField.companyCode}
          </strong>
        </div>
        <DoubleDatePickerWithError
          name1="StartDate"
          name2="EndDate"
          title1="Start Date"
          title2="End Date"
          handleChange={props.onchangeDate}
          data1={props.contentField.StartDate}
          data2={props.contentField.EndDate}
          error1={props.errorInformation.StartDateError}
          error2={props.errorInformation.EndDateError}
        />
        <CurrencyAndTextFieldWithError
          placeholder1="Minumum Cost"
          placeholder2="Percentage (%)"
          name1="MinimalCost"
          name2="Margin"
          title1="Minumum Cost"
          title2="Percentage (%)"
          handleChange1={props.handleChangeMinCost}
          handleChange2={props.handleChange}
          data1={props.contentField.MinimalCost}
          data2={props.contentField.Margin}
          type1="number"
          type2="number"
          error1={props.errorInformation.MinimalCostError}
          error2={props.errorInformation.MarginError}
          required1={true}
          required2={true}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={props.saveMargin}>
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

ModalSystemMargin.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  handleChange: PropTypes.func,
  handleChangeMinCost: PropTypes.func,
  contentField: PropTypes.shape({
    MinimalCost: PropTypes.string,
    Margin: PropTypes.string,
    StartDate: PropTypes.string,
    EndDate: PropTypes.string,
    companyName: PropTypes.string,
    companyCode: PropTypes.string,
  }),
  saveMargin: PropTypes.func,
  handleChangeDate: PropTypes.func,
  errorInformation: PropTypes.shape({
    MinimalCostError: PropTypes.string,
    MarginError: PropTypes.string,
    StartDateError: PropTypes.string,
    EndDateError: PropTypes.string,
  }),
  onchangeDate: PropTypes.func,
  loading: PropTypes.bool,
};
export default ModalSystemMargin;
