import {
  DELETE_COMPANIES_BY_ID,
  GET_COMPANY_COMMISSIONS,
  GET_COMPANY_COMMISSION_BY_ID,
  POST_COMPANY_COMMISSION,
  PUT_COMPANY_COMMISSION,
  DELETE_COMPANY_COMMISSIONS,
  GET_COMPANY_PROFILE,
  GET_COMPANIES_BY_TYPE,
} from './actionTypes';
import {
  deleteCompaniesByIdApi,
  getCompanyCommissionsApi,
  getCompanyCommissionByIdApi,
  postCompanyCommissionApi,
  putCompanyCommissionApi,
  getCompanyCommissionsByCompanyCodeApi,
  deleteCompanyCommissionApi,
  getCompanyProfileApi,
  getCompaniesByTypeApi,
} from '../api/companyApi';

const deleteCompaniesByIdAction = id => {
  return dispatch => {
    return dispatch({
      type: DELETE_COMPANIES_BY_ID,
      payload: deleteCompaniesByIdApi(id),
    });
  };
};

const getCompanyCommissions = () => {
  return dispatch => {
    return dispatch({
      type: GET_COMPANY_COMMISSIONS,
      payload: getCompanyCommissionsApi(),
    });
  };
};

const getCompanyCommissionsByCompanyCode = customerCode => {
  return dispatch => {
    return dispatch({
      type: GET_COMPANY_COMMISSIONS,
      payload: getCompanyCommissionsByCompanyCodeApi(customerCode),
    });
  };
};

const getCompanyCommissionById = id => {
  return dispatch => {
    return dispatch({
      type: GET_COMPANY_COMMISSION_BY_ID,
      payload: getCompanyCommissionByIdApi(id),
    });
  };
};

const postCompanyCommission = data => {
  return dispatch => {
    return dispatch({
      type: POST_COMPANY_COMMISSION,
      payload: postCompanyCommissionApi(data),
    });
  };
};

const putCompanyCommission = (data, id) => {
  return dispatch => {
    return dispatch({
      type: PUT_COMPANY_COMMISSION,
      payload: putCompanyCommissionApi(data, id),
    });
  };
};

const deleteCompanyCommission = id => {
  return dispatch => {
    return dispatch({
      type: DELETE_COMPANY_COMMISSIONS,
      payload: deleteCompanyCommissionApi(id),
    });
  };
};
const getCompaniesByType = type => {
  return dispatch => {
    return dispatch({
      type: GET_COMPANIES_BY_TYPE,
      payload: getCompaniesByTypeApi(type),
    });
  };
};

const getCompanyProfileAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_COMPANY_PROFILE,
      payload: getCompanyProfileApi(),
    });
  };
};

export {
  deleteCompaniesByIdAction,
  getCompanyCommissions,
  getCompanyCommissionById,
  postCompanyCommission,
  putCompanyCommission,
  getCompanyCommissionsByCompanyCode,
  deleteCompanyCommission,
  getCompanyProfileAction,
  getCompaniesByType,
};
