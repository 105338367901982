import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Pagination, Form } from 'semantic-ui-react';
import TableModalAccessibleCity from '../table/excurtion/tableModalAccessibleCity';
import InputWithLabel from './../input/inputWithLabel';

const modalAccessibleCity = props => (
  <Modal
    dimmer="blurring"
    closeIcon
    inverted
    open={props.openModal}
    onClose={props.handleClose}
    size="tiny"
  >
    <Modal.Header>Add {props.header}</Modal.Header>
    <Modal.Content>
      <Form>
        <InputWithLabel
          placeholder="Select Region"
          options={props.listAllRegions}
          type="select"
          handleChange={props.handleChangeRegionAccessibleCity}
          value={props.regionForAccessibleCity}
          label="Region"
          inline={false}
        />
        <InputWithLabel
          icon="search"
          placeholder="search"
          name="searchInput"
          value={props.searchInput}
          handleChange={props.handleChange}
          label="Search by city"
        />
      </Form>
      {/* <h5>{props.content}</h5> */}
      {/* <Input
        icon="search"
        placeholder="search"
        name="searchInput"
        value={props.searchInput}
        onChange={props.handleChange}
      /> */}
      <TableModalAccessibleCity
        listData={props.listData}
        handleChangeAccessCity={props.handleChangeAccessCity}
        loading={props.loadingGetCityByRegion}
        activePage={props.activePage}
      />
      {props.pageData &&
        props.pageData.length > 1 && (
          <Pagination
            totalPages={props.pageData.length}
            onPageChange={props.pageChange}
            activePage={props.activePage}
          />
        )}
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={props.addAccessCity} content="Add City" primary />
    </Modal.Actions>
  </Modal>
);

modalAccessibleCity.propTypes = {
  openModal: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  content: PropTypes.string,
  nameButton: PropTypes.string,
  listData: PropTypes.array,
  handleChangeAccessCity: PropTypes.func,
  pageData: PropTypes.array,
  activePage: PropTypes.number,
  pageChange: PropTypes.func,
  addAccessCity: PropTypes.func,
  searchInput: PropTypes.string,
  handleChange: PropTypes.func,
  listAllRegions: PropTypes.array,
  handleChangeRegionAccessibleCity: PropTypes.func,
  regionForAccessibleCity: PropTypes.string,
  loadingGetCityByRegion: PropTypes.bool,
};

export default modalAccessibleCity;
