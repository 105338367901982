import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Button } from 'semantic-ui-react';
import TableContractingFile from './TableContractingFile';

const accomodationContractingFile = props => {
  return (
    <div>
      <Grid columns="equal" style={{ marginTop: '0.5em' }}>
        <Grid.Row>
          <Grid.Column>
            <Header as="h3" content={props.headerContent} />
          </Grid.Column>
          <Grid.Column />
          <Grid.Column floated="right">
            <Button
              positive
              content={props.buttonContent}
              onClick={props.openModalContractingFile}
              floated="right"
              id={props.idButton}
              disabled={props.disablePage}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <TableContractingFile
              listData={props.listAddingData}
              headerTable={props.headerTable}
              value={props.idButton}
              disabled={props.disablePage}
              //listDataAccessCity={props.listDataAccessCity}
              pagedData={props.pageAddingData}
              activePage={props.activePage}
              pageChange={props.pageChange}
              deleteContracting={props.deleteContracting}
              viewContracting={props.viewContracting}
              // downloadContracting={props.downloadContracting}
              numbersTo={props.numbersTo}
              numbersFrom={props.numbersFrom}
              contractingFileList={props.contractingFileList}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

accomodationContractingFile.propTypes = {
  contractingFileList: PropTypes.array,
  openModalContractingFile: PropTypes.func,
  buttonContent: PropTypes.string,
  headerContent: PropTypes.string,
  headerTable: PropTypes.array,
  disablePage: PropTypes.bool,
  pageAddingData: PropTypes.array,
  activePage: PropTypes.string,
  listAddingData: PropTypes.array,
  pageChange: PropTypes.string,
  deleteContracting: PropTypes.func,
  viewContracting: PropTypes.func,
  downloadContracting: PropTypes.func,
  idButton: PropTypes.string,
  numbersFrom: PropTypes.number,
  numbersTo: PropTypes.number,
};
export default accomodationContractingFile;
