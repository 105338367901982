import React from 'react';
import { Table } from 'semantic-ui-react';
import { convertDateFormat } from '../../../../scripts/moment';
import PropTypes from 'prop-types';
import LoaderModal from '../../../../components/modal/loaderModal';
// import { StyleButton } from '../../../../components/common/styleCommon';
import { Link } from 'react-router-dom';

const TablePaymentHistory = props => (
  <Table basic="very" selectable>
    <LoaderModal loading={props.loading} />
    <Table.Header>
      <Table.Row>
        {props.headerTable.map((headTableContent, index) => {
          return (
            <Table.HeaderCell key={index}>{headTableContent}</Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {props.listData ? (
        props.listData.map((payment, idx) => {
          return (
            <Table.Row key={idx}>
              <Table.Cell component="th" scope="row" width={1}>
                <Link
                  to={
                    props.isFinance
                      ? `/Simple-Transaction-Detail/${payment.BookingId}`
                      : `/tour-transaction/edit/${
                          payment.PackageType === 0 ? 'Custom' : 'Fixed'
                        }/${payment.BookingId}`
                  }
                >
                  {payment.BookingId}
                </Link>
              </Table.Cell>
              <Table.Cell>
                {/* {' '}
                <Link to={`/Transaction-Details/${payment.BookingId}`}>
                  {payment.BookingName}
                </Link>{' '} */}
                {/* <Link
                  to={
                    payment.PackageType === 0
                      ? `/tour-transaction/edit/Custom/${payment.BookingId}`
                      : `/Package-Details/Fixed/${payment.BookingTemplateRefId}`
                  }
                >
                  {payment.BookingName}
                </Link> */}
                {payment.BookingName}
              </Table.Cell>
              <Table.Cell>
                <Link
                  to={`/Customer-Profile-Detail/detail/${payment.CompanyCode}`}
                >
                  {payment.CompanyCode + ' - ' + payment.CompanyName}
                </Link>
              </Table.Cell>
              <Table.Cell>{payment.Description}</Table.Cell>
              <Table.Cell>
                {payment.CurrencyId +
                  '           ' +
                  new Intl.NumberFormat('de-DE').format(payment.PaymentValue)}
              </Table.Cell>
              <Table.Cell>
                {payment.PaymentDate
                  ? convertDateFormat(payment.PaymentDate, 'DD MMMM YYYY')
                  : null}
              </Table.Cell>
            </Table.Row>
          );
        })
      ) : (
        <Table.Row>
          <Table.Cell colSpan="8" textAlign="center">
            No Data
          </Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  </Table>
);

TablePaymentHistory.propTypes = {
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  loading: PropTypes.bool,
  isFinance: PropTypes.bool,
};
export default TablePaymentHistory;
