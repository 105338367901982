import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Grid, Segment } from 'semantic-ui-react';
import TableNotAllowedCountry from './TableNotAllowedCountry';
import { HeaderTableNotAllowedCountryForCompany } from '../constant/HeaderTable';
import HeaderTitle from '../../../components/common/headerTitle';
import Pagination from '../../../components/common/pagination';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../scripts/pagination';
import FilterData from '../../../scripts/filterDataTable';

class NotAllowedCountryList extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
    };
  }
  static propTypes = {
    data: PropTypes.array,
  };
  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  handleChangeDate = (value, name) => {
    this.setState({ [name]: value, activePage: 1 });
  };
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };

  render() {
    let { data, filterBy, buttonEdit } = this.props;
    let { activePage, searchInput } = this.state;
    let filterData = FilterData(data, filterBy, searchInput);
    let pagedData = datasetPagination(filterData, 7);
    let listData = pagedData[activePage - 1];

    let { numbersFrom, numbersTo } = getNumberFromTo(listData, activePage, 7);
    let handleChange =
      filterBy === 'FormatedDate'
        ? this.handleChangeDate
        : filterBy === 'exchangeRate'
          ? this.handleChangeDate
          : this.handleChange;

    return (
      <div>
        <Grid columns="equal" style={{ marginTop: '30px' }}>
          <Grid.Row stretched>
            <Grid.Column width={12}>
              <Segment>
                <HeaderTitle title="Not Allowed Country For Company" />
                <Input
                  icon="search"
                  placeholder="search"
                  name="searchInput"
                  value={searchInput}
                  onChange={handleChange}
                />
                <TableNotAllowedCountry
                  data={data}
                  headerTable={HeaderTableNotAllowedCountryForCompany}
                  pageChange={this.pageChange}
                  listData={listData}
                  pagedData={pagedData}
                  buttonEdit={buttonEdit}
                  notAllowedCountryList={filterData}
                />
                <Pagination
                  pagedData={pagedData}
                  numbersTo={numbersTo}
                  numbersFrom={numbersFrom}
                  data={data}
                  pageChange={this.pageChange}
                  activePage={activePage}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

NotAllowedCountryList.propTypes = {
  listData: PropTypes.array,
  pagedData: PropTypes.array,
  title: PropTypes.string,
  data: PropTypes.array,
  buttonEdit: PropTypes.func,
  filterBy: PropTypes.string,
};

export default NotAllowedCountryList;
