import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SegmentSystemMargin from './SegmentSystemMargin';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../scripts/pagination';
// import FilterData from '../../../scripts/filterDataTable';
import { filterByDateRange } from '../services/filterDate';

export default class SystemMarginList extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      // searchInput: '',
      // activePage: 1,
    };
  }
  static propTypes = {
    data: PropTypes.array,
  };
  // handleChange = (e, { name, value }) =>
  //   this.setState({ [name]: value, activePage: 1 });
  // handleChangeDate = (value, name) => {
  //   this.setState({ [name]: value, activePage: 1 });
  // };
  // pageChange = (e, { activePage }) => {
  //   this.setState({ activePage });
  // };
  render() {
    // let filterData = FilterData(
    //   this.props.data,
    //   this.props.filterBy,
    //   this.props.searchInput
    // );
    let filterData = filterByDateRange(this.props.data, this.props.searchInput);
    let pagedData = datasetPagination(filterData, 7);
    let listData = pagedData[this.props.activePage - 1];

    let { numbersFrom, numbersTo } = getNumberFromTo(
      listData,
      this.props.activePage,
      7
    );
    let handleChange =
      this.props.filterBy === 'FormatedDate'
        ? this.props.handleChangeDate
        : this.props.filterBy === 'exchangeRate'
        ? this.props.handleChangeDate
        : this.props.handleChange;
    return (
      <SegmentSystemMargin
        listData={listData}
        pagedData={pagedData}
        title={'System Margin'}
        pageChange={this.props.pageChange}
        activePage={this.props.activePage}
        searchInput={this.props.searchInput}
        handleChange={handleChange}
        data={this.props.data}
        unlockUser={this.props.unlockUser}
        resetPassword={this.props.resetPassword}
        buttonEdit={this.props.buttonEdit}
        buttonNew={this.props.buttonNew}
        numbersFrom={numbersFrom}
        numbersTo={numbersTo}
        content={this.props.content}
        systemMarginList={filterData}
        butttonEdit={this.props.butttonEdit}
        buttonDelete={this.props.buttonDelete}
        buttonBack={this.props.buttonBack}
        disableButton={this.props.disableButton}
        openModalDateRange={this.props.openModalDateRange}
        dateRange={this.props.dateRange}
      />
    );
  }
}

SystemMarginList.propTypes = {
  data: PropTypes.array,
  type: PropTypes.string,
  filterBy: PropTypes.string,
  buttonNew: PropTypes.func,
  butttonExport: PropTypes.func,
  buttonUploadData: PropTypes.func,
  buttonDownloadTemplate: PropTypes.func,
  butttonEdit: PropTypes.func,
  buttonDelete: PropTypes.func,
  buttonPublished: PropTypes.func,
  buttonCopy: PropTypes.func,
  buttonEdit: PropTypes.func,
  content: PropTypes.object,
  dataPublish: PropTypes.array,
  dataDraft: PropTypes.array,
  unlockUser: PropTypes.func,
  resetPassword: PropTypes.func,
  openNewRate: PropTypes.func,
  editExchangeRate: PropTypes.func,
  buttonSeeDetail: PropTypes.func,
  header: PropTypes.string,
  handleChange: PropTypes.func,
  handleSorting: PropTypes.func,
  buttonBack: PropTypes.func,
  disableButton: PropTypes.bool,
  openModalDateRange: PropTypes.func,
  handleChangeDate: PropTypes.func,
  pageChange: PropTypes.func,
  activePage: PropTypes.number,
  searchInput: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dateRange: PropTypes.object,
};
