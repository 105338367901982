import React from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Loader } from 'semantic-ui-react';

const loader = props => (
  <Dimmer
    active={props.loading}
    page={props.page ? false : true}
    inverted={props.inverted}
  >
    <Loader size="large">Please Wait...</Loader>
  </Dimmer>
);

loader.propTypes = {
  loading: PropTypes.bool,
  page: PropTypes.bool,
  inverted: PropTypes.bool,
};

export default loader;
