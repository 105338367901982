import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Modal, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import CollapseButton from '../button/collapseButton';
import styles from './styles';
import { Collapse } from 'react-collapse';
import ArrivalDeparture from '../segment/segmentDestinationsSummary/segmentArrivalDepartureMini';
// import ArrivalDeparture from '../segment/segmentDestinationsSummary/segmentArrivalDeparture';
import Accommodation from '../segment/segmentDestinationsSummary/segmentAccommodation';
import {
  addSummaryProgram,
  setDateSummaryProgram,
  // setDateDeparture,
  isAccommodationEmpty,
  changeAccomodation,
  isHeadLineConflict,
  isValidAirport,
  delConectionFlight,
  // isAccommodationTimeNeedChange,
} from '../../scripts/itineraryBuilder/dailyProgram';
import { fixDateHeadline } from './../../scripts/itineraryBuilderCreateFixedPackage/dailyProgramFixedPackage';
import { convertDateFormat, FORMAT_DATE_API } from '../../scripts/moment';
import ModalAlert from './modalAlert';
import options from '../genericFunction/selectOptionConverter';
import { getAccommodationsWithFilter } from '../../pages/shared/actions/accommodationAction';
import ModalAccommodationList from './modalSelectionMasterData/modalListAccommodation';
import { checkDateAccommodationEqualStartDateAndEndDate } from '../../pages/packageMangement/services/validation/validationSeriesPackage';
import { getDurationIdtoId } from '../../actions/packageAction';
import ModalConfirm from './modalConfirmationTwoButton';
import { setReturnsDate } from '../../scripts/itineraryBuilder/itineraryBuilder';
import { fixReturnHeadlineProgram } from '../../scripts/packageManagement/dailyProgramFixed';
// import _ from 'lodash';

class ModalDestinationsAndDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapse: true,
      openModalAccommodation: false,
      accomDestination: '',
      accomDestinationName: '',
      accomIndex: null,
      headlineProgram: {
        Departures: [],
        MainPrograms: [],
        Returns: [],
      },
      dailyProgram: [],
      errorMessage: '',
      openModalAlert: false,
      dataAccommodation: {},
      headerAlert: '',
      openModalConfirm: false,
      headerModalConfirm: '',
      messageModalConfirm: '',
    };
  }

  setAccommodationOnChangeIndex = id => {
    this.setState({
      ...this.state,
      accomIndex: id,
    });
  };
  onOpen = () => {
    this.setInitialData();
    this.openModalDestination();
  };
  openModalDestination = () => {
    this.props.openModal();
  };
  setInitialData = () => {
    let { dailyProgram, headlineProgram, isFixedPackageMangement } = this.props;
    this.setState({
      ...this.state,
      dailyProgram: [...dailyProgram],
      headlineProgram: isFixedPackageMangement
        ? fixDateHeadline(headlineProgram)
        : headlineProgram,
    });
  };
  setDailyProgram = dailyProgram => {
    let newDP = dailyProgram ? dailyProgram : this.props.dailyProgram;
    let dailyProg = { ...this.state.dailyProgram };
    dailyProg = newDP;
    this.setState({ ...this.state, dailyProgram: dailyProg });
  };
  setHeadLines = async headLineProg => {
    let headLine = headLineProg ? headLineProg : this.props.headlineProgram;
    if (this.props.isFixedPackageMangement) {
      headLine = await fixDateHeadline(headLine);
    }
    this.setState({
      ...this.state,
      headlineProgram: headLine,
    });
  };
  setHeadLineAndDaily = (headline, daily) => {
    this.setState({
      ...this.state,
      headlineProgram: headline,
      dailyProgram: daily,
      // ini
    });
  };
  closeModal = () => {
    this.setState({
      openModalAccommodation: false,
      headerAlert: null,
    });
  };
  openModalAccommodation = (data, index) => {
    if (data.City && data.City.Id) {
      let { roomAllocation } = this.props;
      let dataAccommodation = {
        cityID: data.City.Id,
        ratingID: '',
        areaID: '',
        locationID: '',
        typeID: '',
        facilityID: '',
        promoonly: false,
        requestedDate: convertDateFormat(data.Date, FORMAT_DATE_API),
        useExtraBed:
          data.AccommodationSummary.Allocations.ExtraBedPax > 0 ? true : false,
        useChildExtraBed:
          data.AccommodationSummary.Allocations.ChildExtraBedPax > 0
            ? true
            : false,
        useSharingBed:
          data.AccommodationSummary.Allocations.SharingBedPax > 0
            ? true
            : false,
        sharingRoomPax: roomAllocation.SharingRoomQty,
        singleRoomPax: roomAllocation.SingleRoomQty,
        checkOutDate: convertDateFormat(data.LeavingDate, FORMAT_DATE_API),
      };

      let state = { ...this.state };
      state.openModalAccommodation = true;
      state.accomDestination = data.City.Id;
      state.accomDestinationName = data.City.Name;
      state.accomIndex = index;
      this.props.getAccommodationsWithFilter(dataAccommodation);
      this.setState({
        ...this.state,
        openModalAccommodation: state.openModalAccommodation,
        accomDestination: state.accomDestination,
        accomDestinationName: state.accomDestinationName,
        accomIndex: state.accomIndex,
        dataAccommodation,
      });
    } else {
      this.openModalAlert('Please select your destination');
    }
  };

  handleCollapse = () => {
    let isCollapse = this.state.isCollapse;
    isCollapse = !isCollapse;
    this.setState({ ...this.state, isCollapse: isCollapse });
  };

  addNewAccommodation = () => {
    let headLineProg = { ...this.state.headlineProgram };
    if (isAccommodationEmpty(headLineProg.MainPrograms)) {
      this.openModalAlert('Please input your accommodation!');
    } else {
      headLineProg.MainPrograms = addSummaryProgram(headLineProg.MainPrograms);
      headLineProg.MainPrograms = setDateSummaryProgram(
        this.props.isFixedPackageMangement
          ? headLineProg.MainPrograms[0]
          : headLineProg.Departures[headLineProg.Departures.length - 1],
        headLineProg.MainPrograms
      );
      headLineProg.Returns = setReturnsDate(
        headLineProg.Returns,
        headLineProg.MainPrograms
      );
      this.setHeadLines(headLineProg);
    }
  };

  isNeedChangePlace = (data, main, index, type) => {
    let result = false;
    if (type === 'Arrival') {
      if (index === 0) {
        result = false;
      } else {
        //eslint-disable-next-line
        if (main[index - 1] && main[index - 1].hasOwnProperty('TransferType')) {
          result = data.Region !== main[index - 1].Region ? true : false;
        } else result = false;
      }
    } else if (type === 'Departure') {
      if (index === 0) {
        result = false;
      } else {
        //eslint-disable-next-line
        if (main[index + 1] && main[index + 1].hasOwnProperty('TransferType')) {
          result = data.Region !== main[index + 1].Region ? true : false;
        } else result = false;
      }
    }
    return result;
  };

  async isErrorConnectionFlight(arr, idx = 0, error = false, obj = null) {
    if (idx >= arr.length - 1 || error) {
      if (obj === null) return (obj = { iserror: false });
      else return obj;
    } else {
      if (
        typeof arr[idx + 1].TransferType !== 'undefined' &&
        arr[idx + 1].TransferType === 'Movement_departure'
      ) {
        return this.isErrorConnectionFlight(arr, idx + 3, error, obj);
      } else {
        let city = {
          city1: arr[idx].City.Name,
          city2: arr[idx + 1].City.Name,
        };
        let result = await this.props.getDurationIdtoId(
          arr[idx].AccommodationSummary.ServiceItemId,
          arr[idx + 1].AccommodationSummary.ServiceItemId,
          2,
          2
        );
        let durasi = await result.payload.Duration.value;
        let hour = await (durasi / 3600);
        if (hour >= 14) {
          obj = {
            iserror: true,
            city: city,
          };
          error = true;
        } else {
          obj = {
            iserror: false,
            city: city,
          };
        }
      }
      return this.isErrorConnectionFlight(arr, idx + 1, error, obj);
    }
  }

  setDestinationAndDate = async () => {
    let {
      isFixedPackageMangement,
      headlineProgram,
      isPackageMangement,
    } = this.props;
    let isPakcageFixedPrice = isFixedPackageMangement || isPackageMangement;
    if (
      isPakcageFixedPrice &&
      (headlineProgram.MainPrograms[0]
        ? headlineProgram.MainPrograms[0].AccommodationSummary
          ? headlineProgram.MainPrograms[0].AccommodationSummary
              .AccommodationProfileId !== null
          : false
        : false)
    ) {
      this.openCloseModalConfirm(
        'Warning',
        'All activity outside in itinerary accommodation will be reset'
      );
    } else {
      this.closeModalDestAndDate();
    }
  };
  openCloseModalConfirm = (header, message) => {
    this.setState({
      ...this.state,
      openModalConfirm: header ? true : false,
      headerModalConfirm: header ? header : '',
      messageModalConfirm: message ? message : '',
    });
  };
  openModalAlert = (message, headerAlert = null) => {
    this.setState({
      errorMessage: message,
      openModalAlert: true,
      headerAlert: headerAlert,
    });
  };
  closeModalDestAndDate = async () => {
    let { headlineProgram, dailyProgram } = this.state;
    let isConflict = this.props.isFixedPackageMangement
      ? false
      : isHeadLineConflict(headlineProgram);
    // let isConflict = isHeadLineConflict(headlineProgram);
    // let mainProgram = headlineProgram.MainPrograms;
    let accommodationEmpty = isAccommodationEmpty(headlineProgram.MainPrograms);
    if (isConflict) {
      this.openModalAlert('Please Fix the conflict');
    } else if (
      !isValidAirport(headlineProgram) &&
      !this.props.isFixedPackageMangement
    ) {
      this.openModalAlert('Please choose your place of arrival or departure!');
    } else if (accommodationEmpty) {
      this.openModalAlert('Please input your accommodation!');
      // ------------------ jangan dihapus ------------------
      // } else if (mainProgram.length > 1 && !accommodationEmpty) {
      //   let result = await this.isErrorConnectionFlight(mainProgram);
      //   if (result.iserror) {
      //     this.openModalAlert(
      //       `Please Add Connection Flight from ${result.city.city1} to ${
      //         result.city.city2
      //       }`
      //     );
      //   } else this.closeModalDestinationSummary(headlineProgram, dailyProgram);
    } else {
      // if (this.props.currentPackage.isReadyFixPrice) {
      //   if (
      //     getTime(
      //       new Date(
      //         headlineProgram.Departures[0] &&
      //           headlineProgram.Departures[0].Date
      //       )
      //     ) < getTime(new Date(this.props.tourInformation.activeDate)) ||
      //     getTime(
      //       new Date(
      //         headlineProgram.Departures[0] &&
      //           headlineProgram.Departures[0].Date
      //       )
      //     ) > getTime(new Date(this.props.tourInformation.expiredDate)) ||
      //     getTime(
      //       new Date(
      //         headlineProgram.Returns[0] && headlineProgram.Returns[0].Date
      //       )
      //     ) > getTime(new Date(this.props.tourInformation.expiredDate))
      //   ) {
      //     this.openModalAlert(
      //       `Please change date, because the date is invalid! active date ${convertDateFormat(
      //         this.props.tourInformation.activeDate,
      //         'DD MMM YYYY'
      //       )} and expired date ${convertDateFormat(
      //         this.props.tourInformation.expiredDate,
      //         'DD MMM YYYY'
      //       )} !`
      //     );
      //   } else {
      //     await this.openCloseModalConfirm(null, null);
      //     this.closeModalDestinationSummary(headlineProgram, dailyProgram);
      //   }
      // } else {
      await this.openCloseModalConfirm(null, null);
      this.closeModalDestinationSummary(headlineProgram, dailyProgram);
      // }
    }
  };

  closeModalDestinationSummary = async (headlineProgram, dailyProgram) => {
    let checkAccommodation = {};
    await this.props.handleGeneralInfo(
      'firstDestination',
      headlineProgram.MainPrograms[0].City.Id,
      'tourInformation'
    );
    headlineProgram.Returns[0].Date > this.props.tourInformation.endDate &&
      (await this.props.handleGeneralInfo(
        'endDate',
        convertDateFormat(
          headlineProgram.Returns.slice(-1).pop().Date,
          'YYYY-MM-DD'
        ),
        'tourInformation'
      ));
    await this.props.handleGeneralInfo(
      'startDate',
      convertDateFormat(headlineProgram.Departures[0].Date, 'YYYY-MM-DD'),
      'tourInformation'
    );

    if (this.props.isFixedPackageMangement) {
      checkAccommodation = await checkDateAccommodationEqualStartDateAndEndDate(
        this.props.tourInformation,
        headlineProgram
      );
      if (checkAccommodation.isError) {
        this.openModalAlert(checkAccommodation.message);
      } else {
        headlineProgram = await fixReturnHeadlineProgram(
          headlineProgram,
          this.props.tourInformation.endDate
        );
        await this.props.setDailyProgram(dailyProgram);
        await this.props.setheadlineProgram(headlineProgram);
        await this.props.addAccommodation();
      }
    } else {
      await this.props.setDailyProgram(dailyProgram);
      await this.props.setheadlineProgram(headlineProgram);
    }
    if (this.props.isFixedPackageMangement) {
      !checkAccommodation.isError && this.props.closeModal();
    } else {
      this.props.closeModal();
    }
    //this.props.closeModal();
  };

  handleChooseAccom = (accommodations, room, service) =>
    this.setState(
      {
        ...this.state,
        openModalAccommodation: false,
      },
      () => this.setAccommodationRoom(accommodations, room, service)
    );

  setAccommodationRoom = (accommodations, room, service) => {
    let { headlineProgram, accomIndex } = this.state;
    let headLineProg = { ...headlineProgram };
    let accommodation = accommodations.find(
      item => item.Id === room.AccommodationProfileId
    );
    headLineProg.MainPrograms = changeAccomodation(
      headLineProg.MainPrograms,
      accomIndex,
      service,
      room,
      accommodation
    );
    this.setHeadLines(headLineProg);
  };
  closeModalAlert = () => {
    this.setState({ ...this.state, openModalAlert: false });
  };
  //============fungsi untuk delete connection flight==============
  handleDeleteConnectionFlight = (e, { index }) => {
    let { headlineProgram } = this.state;
    let hlProgram = { ...headlineProgram };
    hlProgram.MainPrograms = delConectionFlight(
      hlProgram.Departures[hlProgram.Departures.length - 1],
      hlProgram.MainPrograms,
      index
    );
    this.setHeadLines(hlProgram);
  };
  //=============================================
  render() {
    const {
      openModalAccommodation,
      headlineProgram,
      accomIndex,
      dailyProgram,
      openModalAlert,
      errorMessage,
      accomDestinationName,
      dataAccommodation,
      headerAlert,
      openModalConfirm,
      headerModalConfirm,
      messageModalConfirm,
    } = this.state;
    const { Departures, MainPrograms, Returns } = headlineProgram;
    const {
      open,
      placeByRegion,
      cityList,
      cityInCountry,
      currentPackage,
      tourInformation,
      placeByType,
    } = this.props;
    return (
      <div>
        <Modal
          // size="small"
          open={open}
          onClose={this.props.closeModal}
          onOpen={this.onOpen}
          trigger={
            <div style={styles.textCenter}>
              <Button primary disabled={this.props.isPublished}>
                Set Destinations
              </Button>
            </div>
          }
        >
          <Modal.Header>Destinations Summary</Modal.Header>
          <Modal.Content>
            <Grid>
              <Grid.Row>
                <Grid.Column width={14} />
                <Grid.Column width={2} textAlign="right">
                  <CollapseButton
                    handleCollapse={this.handleCollapse}
                    style={{ boxShadow: '0 0 0 transparent' }}
                    isCollapse={this.state.isCollapse}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Collapse isOpened={this.state.isCollapse}>
              <div style={styles.paddingSideTwenty}>
                {/* {!this.props.isFixedPackageMangement && Departures.length > 0 && (
                  <ArrivalDeparture
                    subType="arrival"
                    type="firstArrival"
                    data={Departures[0]}
                    setHeadLines={this.setHeadLines}
                    headlineProgram={headlineProgram}
                    places={placeByRegion}
                    labelDestination="Arrival Destination"
                    labelNextDay="Next Day Arrival"
                    labelDate="Arrival Date"
                    labelTime="Arrival Time"
                    cityList={cityInCountry}
                    //ready fix price
                    currentPackage={currentPackage}
                    tourInformation={tourInformation}
                  />
                )} */}
                {!this.props.isFixedPackageMangement && (
                  <Segment style={styles.segmentInside}>
                    <Grid>
                      <Grid.Row columns="2">
                        {Departures.length > 0 &&
                          Departures.map((airport, i) =>
                            airport.TransferType === 'Movement_arrival' ? (
                              <Grid.Column key={i}>
                                <ArrivalDeparture
                                  subType="arrival"
                                  type="firstArrival"
                                  data={airport}
                                  setHeadLines={this.setHeadLines}
                                  headlineProgram={headlineProgram}
                                  places={placeByRegion}
                                  labelDestination="Arrival To"
                                  labelNextDay="Next Day Arrival"
                                  labelDate="Arrival Date"
                                  labelTime="Arrival Time"
                                  cityList={cityInCountry}
                                  index={i}
                                />
                              </Grid.Column>
                            ) : (
                              <Grid.Column key={i}>
                                <ArrivalDeparture
                                  subType="departure"
                                  type="firstDeparture"
                                  data={airport}
                                  setHeadLines={this.setHeadLines}
                                  headlineProgram={headlineProgram}
                                  places={placeByRegion}
                                  labelDestination="Departure From"
                                  labelNextDay="Next Day Departure"
                                  labelDate="Departure Date"
                                  labelTime="Departure Time"
                                  cityList={cityInCountry}
                                  allPlaces={placeByType}
                                  index={i}
                                />
                              </Grid.Column>
                            )
                          )}
                      </Grid.Row>
                    </Grid>
                  </Segment>
                )}
                {MainPrograms.length > 0 &&
                  MainPrograms.map((item, i) =>
                    //eslint-disable-next-line
                    item.hasOwnProperty('AccommodationSummary') ? (
                      <Accommodation
                        data={item}
                        key={i}
                        index={i}
                        openModal={this.openModalAccommodation}
                        setAccommodationOnChangeIndex={
                          this.setAccommodationOnChangeIndex
                        }
                        headlineProgram={headlineProgram}
                        setHeadLines={this.setHeadLines}
                        deleteAccommodation={this.deleteAccommodation}
                        dailyProgram={dailyProgram}
                        setHeadLineAndDaily={this.setHeadLineAndDaily}
                        cityList={cityList}
                        cityInCountry={cityInCountry}
                        isFixedPackageMangement={
                          this.props.isFixedPackageMangement
                        }
                        openModalAlert={this.openModalAlert}
                      />
                    ) : (
                      !this.props.isFixedPackageMangement &&
                      //eslint-disable-next-line
                      item.hasOwnProperty('TransferType') &&
                      item.TransferType === 'Movement_departure' && (
                        <div>
                          <Segment style={styles.segmentInside}>
                            <Grid>
                              <Grid.Row columns="2">
                                <Grid.Column>
                                  <ArrivalDeparture
                                    subType="departure"
                                    type="connectionFlight"
                                    data={item}
                                    key={i}
                                    index={i}
                                    setHeadLines={this.setHeadLines}
                                    headlineProgram={headlineProgram}
                                    places={placeByRegion}
                                    labelDestination="Departure Destination"
                                    labelNextDay="Next Day Departure"
                                    labelDate="Departure Date"
                                    labelTime="Departure Time"
                                    cityList={cityInCountry}
                                  />
                                </Grid.Column>
                                <Grid.Column>
                                  <ArrivalDeparture
                                    subType="arrival"
                                    type="connectionFlight"
                                    data={MainPrograms[i + 1]}
                                    key={i + 1}
                                    index={i + 1}
                                    setHeadLines={this.setHeadLines}
                                    headlineProgram={headlineProgram}
                                    places={placeByRegion}
                                    labelDestination="Arrival Destination"
                                    labelNextDay="Next Day Arrival"
                                    labelDate="Arrival Date"
                                    labelTime="Arrival Time"
                                    cityList={cityInCountry}
                                  />
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </Segment>
                          <Grid.Row style={{ padding: 0, textAlign: 'center' }}>
                            <Grid.Column style={{ marginBottom: '15px' }}>
                              <Button
                                basic
                                color="red"
                                onClick={this.handleDeleteConnectionFlight}
                                index={i}
                              >
                                Delete Connection Flight
                              </Button>
                            </Grid.Column>
                          </Grid.Row>
                        </div>
                      )
                    )
                  )}
                {/* {MainPrograms.length > 0 &&
                  MainPrograms.map((item, i) =>
                    //eslint-disable-next-line
                    item.hasOwnProperty('AccommodationSummary') ? (
                      <Accommodation
                        data={item}
                        key={i}
                        index={i}
                        openModal={this.openModalAccommodation}
                        setAccommodationOnChangeIndex={
                          this.setAccommodationOnChangeIndex
                        }
                        headlineProgram={headlineProgram}
                        setHeadLines={this.setHeadLines}
                        deleteAccommodation={this.deleteAccommodation}
                        dailyProgram={dailyProgram}
                        setHeadLineAndDaily={this.setHeadLineAndDaily}
                        cityList={cityList}
                        cityInCountry={cityInCountry}
                        isFixedPackageMangement={
                          this.props.isFixedPackageMangement
                        }
                        openModalAlert={this.openModalAlert}
                      />
                    ) : !this.props.isFixedPackageMangement &&
                      //eslint-disable-next-line
                      item.hasOwnProperty('TransferType') &&
                      item.TransferType === 'Movement_arrival' ? (
                      <ArrivalDeparture
                        subType="arrival"
                        type="connectionFlight"
                        data={item}
                        key={i}
                        index={i}
                        setHeadLines={this.setHeadLines}
                        headlineProgram={headlineProgram}
                        places={placeByRegion}
                        labelDestination="Arrival Destination"
                        labelNextDay="Next Day Arrival"
                        labelDate="Arrival Date"
                        labelTime="Arrival Time"
                        cityList={cityInCountry}
                        //ready fix price
                        // currentPackage={currentPackage}
                        // tourInformation={tourInformation}
                      />
                    ) : (
                      !this.props.isFixedPackageMangement && (
                        <ArrivalDeparture
                          subType="departure"
                          type="connectionFlight"
                          data={item}
                          key={i}
                          index={i}
                          setHeadLines={this.setHeadLines}
                          headlineProgram={headlineProgram}
                          places={placeByRegion}
                          labelDestination="Departure Destination"
                          labelNextDay="Next Day Departure"
                          labelDate="Departure Date"
                          labelTime="Departure Time"
                          cityList={cityInCountry}
                          //ready fix price
                          // currentPackage={currentPackage}
                          // tourInformation={tourInformation}
                        />
                      )
                    )
                  )} */}
                <div style={{ margin: '10px', textAlign: 'center' }}>
                  <Button primary onClick={this.addNewAccommodation}>
                    ADD DESTINATION
                  </Button>
                </div>
                {/* {!this.props.isFixedPackageMangement && Returns.length > 0 && (
                  <ArrivalDeparture
                    subType="departure"
                    type="lastDeparture"
                    data={Returns[0]}
                    setHeadLines={this.setHeadLines}
                    headlineProgram={headlineProgram}
                    places={placeByRegion}
                    labelDestination="Departure Destination"
                    labelNextDay="Next Day Departure"
                    labelDate="Departure Date"
                    labelTime="Departure Time"
                    cityList={cityInCountry}
                    //ready fix price
                    // currentPackage={currentPackage}
                    // tourInformation={tourInformation}
                  />
                )} */}
                {!this.props.isFixedPackageMangement && (
                  <Segment style={styles.segmentInside}>
                    <Grid>
                      <Grid.Row columns="2">
                        {Returns.length > 0 &&
                          Returns.map((airport, i) =>
                            airport.TransferType === 'Movement_arrival' ? (
                              <Grid.Column key={i}>
                                <ArrivalDeparture
                                  subType="arrival"
                                  type="lastArrival"
                                  data={airport}
                                  setHeadLines={this.setHeadLines}
                                  headlineProgram={headlineProgram}
                                  places={placeByRegion}
                                  labelDestination="Arrival To"
                                  labelNextDay="Next Day Arrival"
                                  labelDate="Arrival Date"
                                  labelTime="Arrival Time"
                                  cityList={cityInCountry}
                                  allPlaces={placeByType}
                                  index={i}
                                />
                              </Grid.Column>
                            ) : (
                              <Grid.Column key={i}>
                                <ArrivalDeparture
                                  subType="departure"
                                  type="lastDeparture"
                                  data={airport}
                                  setHeadLines={this.setHeadLines}
                                  headlineProgram={headlineProgram}
                                  places={placeByRegion}
                                  labelDestination="Departure From"
                                  labelNextDay="Next Day Departure"
                                  labelDate="Departure Date"
                                  labelTime="Departure Time"
                                  cityList={cityInCountry}
                                  index={i}
                                />
                              </Grid.Column>
                            )
                          )}
                      </Grid.Row>
                    </Grid>
                  </Segment>
                )}
              </div>
            </Collapse>
            <ModalAlert
              openModal={openModalAlert}
              size="tiny"
              handleClose={this.closeModalAlert}
              content={errorMessage}
              header={headerAlert ? headerAlert : 'Failed'}
              nameButton="OK"
            />
            <ModalConfirm
              openModal={openModalConfirm}
              close={this.openCloseModalConfirm}
              confirm={this.closeModalDestAndDate}
              message={messageModalConfirm}
              header={headerModalConfirm}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={this.setDestinationAndDate} content="Ok" />
          </Modal.Actions>
        </Modal>
        <ModalAccommodationList
          open={openModalAccommodation}
          onClose={this.closeModal}
          name="Accommodation"
          data={this.props.listAccommodation}
          locationName={accomDestinationName}
          accomIndex={accomIndex}
          headlineProgram={headlineProgram}
          setAccommodationRoom={this.setAccommodationRoom}
          dataGetAccommodation={dataAccommodation}
          currentPackage={currentPackage}
          tourInformation={tourInformation}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    cityList: state.CityStore.getCityInCountryList.map(item => {
      return options(item.Id, item.Id, item.Name);
    }),
    cityInCountry: state.CityStore.getCityInCountryList,
    placeByRegion: state.AirportStore.places,
    listAccommodation: state.AccomodationStore.listAccommodationsWithFilter,
    placeByType: state.AirportStore.placeByType,
  };
};
ModalDestinationsAndDate.propTypes = {
  headlineProgram: PropTypes.object,
  placeByRegion: PropTypes.array,
  accommodationChangeIndex: PropTypes.number,
  cityList: PropTypes.array,
  cityInCountry: PropTypes.array,
  setheadlineProgram: PropTypes.func,
  accommodations: PropTypes.array,
  closeModal: PropTypes.func,
  openModal: PropTypes.func,
  open: PropTypes.bool,
  dailyProgram: PropTypes.array,
  setDailyProgram: PropTypes.func,
  places: PropTypes.array,
  setFirstDestination: PropTypes.func,
  getAccommodationsWithFilter: PropTypes.func,
  handleGeneralInfo: PropTypes.func,
  listAccommodation: PropTypes.array,
  isFixedPackageMangement: PropTypes.bool,
  addAccommodation: PropTypes.func,
  tourInformation: PropTypes.func,
  roomAllocation: PropTypes.object,
  getDurationIdtoId: PropTypes.func,
  isPublished: PropTypes.bool,
  currentPackage: PropTypes.object,
  isPackageMangement: PropTypes.bool,
  placeByType: PropTypes.array,
};
export default connect(mapStateToProps, {
  getAccommodationsWithFilter,
  getDurationIdtoId,
})(ModalDestinationsAndDate);
