import React from 'react';
import { Table, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ActionButton from '../button/ActionButtonBooking';
import LoaderModal from '../../../../components/modal/loaderModal';
import { Link } from 'react-router-dom';
// import { StyleButton } from '../../../../components/common/styleCommon';

const status = status => {
  return status === 'Booking_DP_Confirmed' ? (
    <Table.Cell> DP Confirmed</Table.Cell>
  ) : status === 'Booking_Confirmed' ? (
    <Table.Cell> Confirmed</Table.Cell>
  ) : status === 'Booking_In_Payment' ? (
    <Table.Cell> In Payment</Table.Cell>
  ) : (
    <Table.Cell />
  );
};
const TableBookingConfirmation = props => (
  <div style={{ overflow: 'auto' }}>
    <Table basic="very" selectable>
      <LoaderModal loading={props.loading} />
      <Table.Header>
        <Table.Row>
          {props.headerTable.map((headTableContent, index) => {
            return (
              <Table.HeaderCell key={index}>
                {headTableContent}
              </Table.HeaderCell>
            );
          })}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {props.listData ? (
          props.listData.map((booking, idx) => {
            return (
              <Table.Row key={idx}>
                <Table.Cell component="th" scope="row" width={1}>
                  {/* <Button
                    onClick={props.actionModalDetailBooking}
                    id={booking.Id}
                    content={booking.Id}
                    style={StyleButton.buttonTransparent}
                  /> */}
                  <Link
                    to={`/tour-transaction/edit/${
                      booking.PackageType === 0 ? 'Custom' : 'Fixed'
                    }/${booking.Id}`}
                  >
                    {booking.Id}
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  {/* <Link to={`/Transaction-Details/${booking.Id}`}>
                    {booking.Title}
                  </Link> */}
                  <Link
                    to={
                      booking.PackageType === 0
                        ? `/tour-transaction/edit/Custom/${booking.Id}`
                        : `/Package-Details/Fixed/edit/${booking.BookingTemplateRefId}`
                    }
                  >
                    {booking.Title}
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  <Link
                    to={`/Customer-Profile-Detail/detail/${booking.CompanyCode}`}
                  >
                    {booking.CompanyName}
                  </Link>
                </Table.Cell>
                {status(booking.Status)}
                <Table.Cell>
                  {booking.CurrencyId +
                    '           ' +
                    new Intl.NumberFormat('de-DE').format(
                      booking.TourTotalPrice
                    )}
                </Table.Cell>
                <Table.Cell>
                  {' '}
                  {booking.CurrencyId +
                    '           ' +
                    new Intl.NumberFormat('de-DE').format(booking.TotalPayed)}
                </Table.Cell>
                <Table.Cell>
                  <Button
                    onClick={props.actionModalDetailBooking}
                    id={booking.Id}
                    // content={downpayment.Id}
                    content=" Payment Detail"
                    // style={StyleButton.buttonTransparent}
                    positive
                  />
                </Table.Cell>
                <ActionButton
                  id={booking.Id}
                  handleClick={props.actionButton}
                  value={booking.Status}
                />
              </Table.Row>
            );
          })
        ) : (
          <Table.Row>
            <Table.Cell colSpan="8" textAlign="center">
              No Data
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  </div>
);

TableBookingConfirmation.propTypes = {
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  actionButton: PropTypes.func,
  loading: PropTypes.bool,
  actionModalDetailBooking: PropTypes.func,
};
export default TableBookingConfirmation;
