import React from 'react';
import { Table, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const ActionButtonCompanyProfileList = props => {
  return (
    <Table.Cell>
      <Button.Group>
        <Button
          name={props.name1}
          value={props.id}
          positive
          onClick={props.handleClick}
          content={props.content1}
          disabled={props.disabled}
        />
        <Button.Or />
        <Button
          name={props.name2}
          value={props.id}
          negative
          onClick={props.handleClick}
          content={props.content2}
          disabled={props.disabled}
        />
      </Button.Group>
    </Table.Cell>
  );
};
ActionButtonCompanyProfileList.propTypes = {
  value: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  handleClick: PropTypes.func,
  name1: PropTypes.string,
  name2: PropTypes.string,
  content1: PropTypes.string,
  content2: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ActionButtonCompanyProfileList;
