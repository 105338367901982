import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from 'semantic-ui-react';
import TableOriginCountry from '../table/TableOriginCountry';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../../scripts/pagination';
import Pagination from '../../../../components/common/pagination';
import { withRouter } from 'react-router-dom';

const OriginCountry = props => {
  // --------------------- initial ---------------------
  const {
    data,
    countryList,
    countryOptionsList,
    currencyList,
    loading,
    handleChangeInputTable,
    handleChangeInputAmount,
    handleDelete,
    addOriginCountry,
    disable,
  } = props;
  const [activePage, setActivePage] = useState(1);

  // --------------------- helper ---------------------
  const pageChange = (e, { activePage }) => {
    setActivePage(activePage);
  };

  // --------------------- pagination ---------------------
  const pagedData = datasetPagination(
    data && data.OriginalCountries ? data.OriginalCountries : [],
    3
  );
  const listData = pagedData[activePage - 1];

  const { numbersFrom, numbersTo } = getNumberFromTo(listData, activePage, 3);

  return (
    <>
      <Grid columns="equal">
        <Grid.Row stretched>
          <Grid.Column width={16}>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Button
                    floated="right"
                    content="Add New"
                    positive
                    onClick={addOriginCountry}
                    disabled={disable}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <TableOriginCountry
                    listData={listData}
                    countryList={countryList}
                    countryOptionsList={countryOptionsList}
                    currencyList={currencyList}
                    loading={loading}
                    handleChangeInputTable={handleChangeInputTable}
                    handleChangeInputAmount={handleChangeInputAmount}
                    handleDelete={handleDelete}
                    disable={disable}
                  />
                  {data && (
                    <Pagination
                      pagedData={pagedData}
                      numbersTo={numbersTo}
                      numbersFrom={numbersFrom}
                      data={data.OriginalCountries}
                      pageChange={pageChange}
                      activePage={activePage}
                    />
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

OriginCountry.propTypes = {
  data: PropTypes.object,
  countryList: PropTypes.array,
  countryOptionsList: PropTypes.array,
  currencyList: PropTypes.array,
  loading: PropTypes.bool,
  handleChangeInputTable: PropTypes.func,
  handleChangeInputAmount: PropTypes.func,
  handleDelete: PropTypes.func,
  addOriginCountry: PropTypes.func,
  disable: PropTypes.bool,
};

export default withRouter(OriginCountry);
