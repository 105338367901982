import apiClient from '../apiClient';
import {
  GET_INVOICE_TRAVEL_AGENT_URL,
  GET_INVOICE_CUSTOMER_URL,
} from '../apiUrl';

const getInvoiceCustomerApi = id => {
  return apiClient.get(GET_INVOICE_CUSTOMER_URL(id));
};

const getInvoiceAgentApi = id => {
  return apiClient.get(GET_INVOICE_TRAVEL_AGENT_URL(id));
};

export { getInvoiceCustomerApi, getInvoiceAgentApi };
