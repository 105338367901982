import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Grid } from 'semantic-ui-react';
import {
  TextFieldFluidOnPage,
  DropdownFluidOnPage,
} from '../../../../../components/common/fieldWithError';
import LoaderModal from '../../../../../components/modal/loaderModal';
const ModalUserRole = props => (
  <Modal
    dimmer="blurring"
    closeIcon
    inverted
    open={props.openModal}
    onClose={props.handleClose}
    size="tiny"
  >
    <Modal.Header>{props.header}</Modal.Header>
    <Modal.Content>
      <LoaderModal loading={props.loading} />
      <Grid>
        <Grid.Column>
          <Grid columns="equal">
            <Grid.Row stretched>
              <Grid.Column>
                {!props.isUserList && (
                  <div>
                    <TextFieldFluidOnPage
                      placeholder="First Name"
                      name="FirstName"
                      title="First Name"
                      fluid={true}
                      type="text"
                      data={props.contentDetail.FirstName}
                      disable
                    />
                    <TextFieldFluidOnPage
                      placeholder="Last Name"
                      name="LastName"
                      title="Last Name"
                      fluid={true}
                      type="text"
                      data={props.contentDetail.LastName}
                      disable
                    />
                  </div>
                )}
                <TextFieldFluidOnPage
                  placeholder="Username"
                  name="UserName"
                  title="Username"
                  fluid={true}
                  type="text"
                  data={props.contentDetail.UserName}
                  disable
                />
                <TextFieldFluidOnPage
                  placeholder="Email"
                  name="Email"
                  title="Email"
                  fluid={true}
                  type="text"
                  data={props.contentDetail.Email}
                  disable
                />
                <DropdownFluidOnPage
                  placeholder="Roles"
                  name="Roles"
                  title="Roles"
                  handleChange={props.handleChange}
                  fluid={true}
                  option={props.listUserRole}
                  multiple={true}
                  valueDropDown={props.contentDetail.Roles}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid>
    </Modal.Content>
    <Modal.Actions>
      <Button primary onClick={props.handleClose} content={props.nameButton1} />
      <Button
        positive
        onClick={props.handleSubmit}
        content={props.nameButton2}
      />
    </Modal.Actions>
  </Modal>
);

ModalUserRole.propTypes = {
  openModal: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  content: PropTypes.string,
  nameButton1: PropTypes.string,
  nameButton2: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  listCountry: PropTypes.array,
  listUserRole: PropTypes.array,
  contentDetail: PropTypes.object,
  loading: PropTypes.bool,
  isUserList: PropTypes.bool,
};

export default ModalUserRole;
