import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Segment,
  Button,
  // Icon,
  Input,
  Container,
} from 'semantic-ui-react';
import HeaderTitle from '../../shared/components/common/headerSegment/HeaderTitle';
import TableManualConfirmationNumber from './table/TableManualConfirmationNumber';
import styles from '../styles';
import ModalAcceptRoom from './modal/ModalAcceptRoom';
import ModalRejectRoom from './modal/ModalRejectRoom';
import ModalDateRangeCheckIn from '../../shared/components/modal/ModalDateRangeCheckIn';
import ModalConfirmation from './modal/ModalConfirmation';
import {
  getManualConfirmationNumberApi,
  postConfirmationNumberApi,
} from '../../../api/dashboardApi';
import useApiCall from '../hooks/useApiCallPartner';
import PaginationScript from '../../../components/genericFunction/pagination';
import moment from 'moment';
import { searchFilterManualConfirmationNumber } from '../../../scripts/filterData';
import ModalInformation from './modal/ModalInformation';
import { getNumberFromTo } from '../../../components/genericFunction/getPaginationNumberFrom';
import ModalCancellationReason from '../../shared/components/modal/ModalCancellationReason';
import { addDate, convertDateFormat } from '../../../scripts/moment';
// import ModalManualSendEmail from '../../shared/components/modal/ModalManualSendEmail';
// import { postEmailSendConfirmationApi } from '../../../api/transactionApi';

const TODAY = new Date();

const ManualConfirmationNumber = () => {
  //=======================initial state=======================
  // const {
  //   setIsReloadCalendar,
  //   setIsReloadUpcoming,
  //   setIsReloadStayToday,
  // } = props;
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openReject, setOpenReject] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [activePage, setActivePage] = useState(1);
  const [requestItemId, setRequestItemId] = useState('');
  const [roomName, setRoomName] = useState('');
  const [qty, setQty] = useState('');
  const [loading, setLoading] = useState(false);
  const [openModalInformation, setOpenModalInformation] = useState(false);
  const [contentAlert, setContentAlert] = useState('');
  const [headerAlert, setHeaderAlert] = useState('');
  const [postData, setPostData] = useState({});

  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [messageModal, setMessageModal] = useState('');
  const [openCancel, setOpenCancel] = useState(false);
  const [openDateRange, setOpenDateRange] = useState(false);
  // const [openSendManualEmail, setOpenSendManualEmail] = useState(false);
  // const [isChooseSendEmail, setIsChooseSendEmail] = useState(false);
  // const [isSendMail, setIsSendMail] = useState(false);
  // const [cancellationReasonId, setCancellationReasonId] = useState(null);
  // const [cancellationReasonNote, setCancellationReasonNote] = useState(false);
  // const [bookingNumber, setBookingNumber] = useState('');

  // const [radioValueDateRange, setRadioValueDateRange] = useState('1');
  // const [openModalFilterBooking, setOpenModalFilterBooking] = useState(false);

  const [
    manualConfirmLoading,
    manualConfirmList,
    handleDateRange,
    loadData,
    startDate,
    endDate,
  ] = useApiCall(
    getManualConfirmationNumberApi,
    convertDateFormat(TODAY, 'YYYY-MM-DD'),
    convertDateFormat(addDate(TODAY, 30, 'd'), 'YYYY-MM-DD')
  );

  //=======================helper=======================
  const confirmManualConfirmationNumber = (data, isAccept) => {
    setLoading(true);
    postConfirmationNumberApi({
      ...data,
      ConfirmationNote: data.CancellationReasonNote,
      CancellationReasonNote: undefined,
    })
      .then(() => {
        setLoading(false);
        setOpenModalInformation(true);
        // setIsReloadCalendar(true);
        // setIsReloadUpcoming(true);
        // setIsReloadStayToday(true);
        setHeaderAlert(
          isAccept ? 'Confirmation Success' : 'You Rejected The Request'
        );
        setContentAlert(
          isAccept
            ? data.ConfirmationNumber === null
              ? `Request ID ${data.RequestItemId} has been successfully Confirm`
              : `Request ID ${data.RequestItemId} has been successfully given a confirmation number ${data.ConfirmationNumber}`
            : `We understand your situation, thank you for responding to this request.`
        );
        // if (isSendMail) {
        //   postEmailSendConfirmationApi({
        //     tourTransactionId: bookingNumber,
        //     emailSendConfirmed: true,
        //   });
        // }
      })
      .catch(err => {
        setLoading(false);
        setOpenModalInformation(true);
        setHeaderAlert('Failed');
        setContentAlert(
          err.response.data.ModelState
            ? err.response.data.ModelState['model.ConfirmationNumber']
              ? err.response.data.ModelState['model.ConfirmationNumber']
              : err.response.data.Message
            : err.response.data.Message
        );
      });
    setOpenCancel(false);
  };

  const actionDateRange = id => {
    let from = moment(id.from).format('YYYY-MM-DD');
    let to = moment(id.to).format('YYYY-MM-DD');
    handleClose();
    handleDateRange(from, to);
  };

  const filteredData = (data, searchInput) => {
    return searchFilterManualConfirmationNumber(data, searchInput);
  };

  const pageChange = (e, { activePage }) => {
    setActivePage(activePage);
  };

  const openModalConfirm = data => {
    let _requestItemId = data.RequestItemId
      ? data.RequestItemId
      : requestItemId;
    if (data.ConfirmationNumber === null && data.ConfirmationNote === '') {
      setMessageModal(
        `Are you sure want to confirm Request Item ID: ${_requestItemId} with no confirmation number?`
      );
    } else if (
      data.ConfirmationNumber !== null &&
      data.ConfirmationNote === ''
    ) {
      setMessageModal(
        `Are you sure want to confirm Request Item ID: ${_requestItemId} with confirmation number: ${data.ConfirmationNumber}?`
      );
    } else if (data.ConfirmationNote !== '') {
      setMessageModal(
        `Are you sure want to reject Request Item ID ${_requestItemId}?`
      );
    }
    setOpenModalConfirmation(true);
  };

  //=======================handler=======================
  const handleChangeSearch = e => {
    setSearchInput(e.target.value);
    setActivePage(1);
  };

  const handleOpenConfirm = (e, { name, id, content }) => {
    setRequestItemId(id);
    setRoomName(name);
    setQty(content);
    setOpenConfirmation(true);
  };
  const handleClose = () => {
    setOpenConfirmation(false);
    setOpenReject(false);
    setOpenDateRange(false);
    setOpenModalConfirmation(false);
    setOpenCancel(false);
  };
  const handleCloseModalInformation = () => {
    setHeaderAlert('');
    setContentAlert('');
    setOpenModalInformation(false);
    // if (postData.ActionType === 0 && postData.ConfirmationNumber === null) {
    //   setIsReloadCalendar(true);
    //   setIsReloadUpcoming(true);
    //   setIsReloadStayToday(true);
    // } else if (
    //   postData.ActionType === 0 &&
    //   postData.ConfirmationNumber !== null
    // ) {
    //   setIsReloadUpcoming(true);
    // }

    setActivePage(1);
    loadData();
  };
  //--------------- Used Again Soon ----------------
  // const handleOpenReject = (e, { name, id }) => {
  //   setRequestItemId(id);
  //   setRoomName(name);
  //   setOpenReject(true);
  // };
  const handleOpenDateRange = () => {
    setOpenDateRange(true);
  };

  const handleOpenCancellation = (e, { id }) => {
    setRequestItemId(id);
    // setBookingNumber(name);
    setOpenCancel(true);
    // setIsChooseSendEmail(true);
  };

  const handleConfirm = data => {
    setPostData(data);
    openModalConfirm(data);
  };

  const handleReject = data => {
    setPostData(data);
    openModalConfirm(data);
  };

  const handleConfirmConfirmation = () => {
    if (postData.ActionType === 1) {
      confirmManualConfirmationNumber(postData, 0);
    } else {
      confirmManualConfirmationNumber(postData, 1);
    }
    handleClose();
  };

  const handleSaveCancellationReason = data => {
    if (data.CancellationReasonId || data.CancellationReasonNote) {
      data = {
        ...data,
        RequestItemId: requestItemId,
        ActionType: 1,
      };
    }
    setPostData(data);

    openModalConfirm(data);
    // setOpenSendManualEmail(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirmation(false);
  };

  // const handleCloseManualSendEmail = () => {
  //   setOpenSendManualEmail(false);
  // };

  // const handleSendManualEmail = value => {
  //   setIsSendMail(value === 1 ? true : false);
  // };

  // const handleRadioDateRange = value => {
  //   setRadioValueDateRange(value);
  // };

  // const handleCloseFilterBooking = () => {
  //   setOpenModalFilterBooking(true);
  // };

  //=======================pagination data=======================
  let filterData = filteredData(
    manualConfirmList !== null ? manualConfirmList : [],
    searchInput
  );
  let pagedData = PaginationScript(filterData, 5);
  let listData = pagedData[activePage - 1];
  let { numbersFrom, numbersTo } = getNumberFromTo(listData, activePage, 5);

  let checkInUntilDate = !endDate ? '' : moment(endDate).format('DD MMM YYYY');
  let checkInStartDate = !startDate ? '' : moment(startDate).format('DD MMM');
  return (
    <Grid columns="equal" style={styles.styleGrid}>
      <Grid.Row stretched>
        <Grid.Column width={15}>
          <Segment loading={manualConfirmLoading || loading}>
            <HeaderTitle title="Manual Confirmation Number" />
            <Input
              icon="search"
              placeholder="Search"
              name="searchInput"
              value={searchInput}
              onChange={handleChangeSearch}
            />
            <Button
              icon="filter"
              basic
              size="small"
              floated="right"
              positive
              onClick={handleOpenDateRange}
            />
            <div
              style={{
                marginTop: '-2.9em',
                marginRight: '5em',
                fontWeight: 'bold',
              }}
            >
              {/* {(filterByDate.tourStartDate !== '' ||
                    filterByDate.tourUntilDate !== '') && ( */}
              <Container
                content={
                  'Check-in range date : ' +
                  checkInStartDate +
                  ' - ' +
                  checkInUntilDate
                }
                textAlign="right"
              />
            </div>
            <Grid.Row style={styles.styleRowTable}>
              <TableManualConfirmationNumber
                activePage={activePage}
                data={manualConfirmList}
                pagedData={pagedData}
                listData={listData}
                numbersFrom={numbersFrom}
                numbersTo={numbersTo}
                pageChange={pageChange}
                handleClose={handleClose}
                handleOpenConfirm={handleOpenConfirm}
                // handleOpenReject={handleOpenReject}
                handleOpenCancel={handleOpenCancellation}
                // handleChangeSearch={handleChangeSearch}
              />
            </Grid.Row>
          </Segment>
        </Grid.Column>
      </Grid.Row>
      <ModalAcceptRoom
        // message={messageConfirm}
        handleConfirm={handleConfirm}
        openModal={openConfirmation}
        close={handleClose}
        requestItemId={requestItemId}
        roomName={roomName}
        qty={qty}
      />
      <ModalRejectRoom
        handleReject={handleReject}
        openModal={openReject}
        close={handleClose}
        requestItemId={requestItemId}
        roomName={roomName}
        qty={qty}
      />
      <ModalDateRangeCheckIn
        contentHeader="Manual Confirmation Number"
        openModal={openDateRange}
        close={handleClose}
        actionDateRange={actionDateRange}
        startDate={startDate}
        endDate={endDate}
      />
      <ModalInformation
        close={handleClose}
        open={openModalInformation}
        content={contentAlert}
        modalHeader={headerAlert}
        onClickButton={handleCloseModalInformation}
        size="mini"
        contentButton="OK"
      />
      <ModalConfirmation
        openModal={openModalConfirmation}
        confirm={handleConfirmConfirmation}
        close={handleCloseModalConfirm}
        message={messageModal}
      />
      <ModalCancellationReason
        openModal={openCancel}
        handleSave={handleSaveCancellationReason}
        handleClose={handleClose}
        requestItemId={requestItemId}
        roomName={roomName}
        CancellationReasonType="Partner"
      />
      {/* <ModalManualSendEmail
        openModal={openSendManualEmail}
        size="tiny"
        close={handleCloseManualSendEmail}
        confirm={handleReject} // confirmBooking
        isChooseSendEmail={isChooseSendEmail}
        handleSendManualEmail={handleSendManualEmail}
        isSendMail={isSendMail}
      /> */}
    </Grid>
  );
};

ManualConfirmationNumber.propTypes = {
  setIsReloadCalendar: PropTypes.bool,
  setIsReloadUpcoming: PropTypes.bool,
  setIsReloadStayToday: PropTypes.bool,
};

export default React.memo(ManualConfirmationNumber);
