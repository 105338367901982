import React from 'react';
import Calendar from 'react-calendar';
import PropTypes from 'prop-types';
import styles from './styles';
import { shortFormatDate } from '../../../../scripts/moment';
import 'react-calendar/dist/Calendar.css';

const CalendarBooking = props => {
  const { className, tileClassName, listDate, handleChangeDate, value } = props;
  const findDate = date => {
    return listDate && listDate.length > 0
      ? listDate.find(item => item === shortFormatDate(date))
        ? true
        : false
      : false;
  };
  const renderTileClassName = ({ date, view }) => {
    let style =
      view === 'month' && shortFormatDate(date) === shortFormatDate(new Date())
        ? tileClassName
        : view !== 'month'
        ? null
        : 'circle-day';
    return style;
  };
  return (
    <Calendar
      className={className}
      tileClassName={renderTileClassName}
      onChange={handleChangeDate}
      value={value ? new Date(value) : new Date()}
      tileContent={({ date, view }) =>
        view === 'month' && findDate(date) ? (
          <div style={styles.dateMarker} />
        ) : null
      }
    />
  );
};

CalendarBooking.propTypes = {
  className: PropTypes.string,
  tileClassName: PropTypes.string,
  listDate: PropTypes.array,
  handleChangeDate: PropTypes.func,
  value: PropTypes.string,
};

export default React.memo(CalendarBooking);
