import React from 'react';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const buttonGroupThriple = props => {
  return (
    <div style={{ margin: '10px 0px 0px 800px' }}>
      <Button
        name={props.name1}
        value={props.id1}
        primary
        onClick={props.handleClick1}
        content={props.content1}
      />
      <Button
        name={props.name2}
        value={props.id2}
        positive
        onClick={props.handleClick2}
        content={props.content2}
      />
      <Button
        name={props.name3}
        value={props.id3}
        negative
        onClick={props.handleClick3}
        content={props.content3}
      />
    </div>
  );
};
buttonGroupThriple.propTypes = {
  id1: PropTypes.number,
  id2: PropTypes.number,
  id3: PropTypes.number,
  handleClick1: PropTypes.func,
  handleClick2: PropTypes.func,
  handleClick3: PropTypes.func,
  name1: PropTypes.string,
  name2: PropTypes.string,
  name3: PropTypes.string,
  content1: PropTypes.string,
  content2: PropTypes.string,
  content3: PropTypes.string,
};
export default buttonGroupThriple;
