import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Pagination,
  Grid,
  Segment,
  Button,
  Container,
} from 'semantic-ui-react';
import TableListOfTransactionDownPayment from './table/TableListOfTransactionDownPayment';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../scripts/pagination';
import moment from 'moment';
import HeaderTitle from '../../shared/components/common/headerSegment/HeaderTitle';
import { connect } from 'react-redux';
import { getTransactionByStatusCreatedAction } from '../homeAction';
import { resetStatusTransactionAction } from '../../../actions/tourTransactionAction';
import ModalDateRange from '../../shared/components/modal/ModalDateRange';
import ModalAlert from '../../shared/components/modal/ModalAlert';
class ListOfTransactionDownPayment extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
      listOfTransactionDownPayment: [],
      dateRangeDp: {
        value: '',
        createStartDate: '',
        createEndDate: '',
        tourStartDate: moment(new Date()).format('YYYY-MM-DD'),
        tourEndDate: moment()
          .add(30, 'd')
          .format('YYYY-MM-DD'),
      },
      openModalFilterBooking: false,
      radioValueDateRange: '1',
    };
  }

  handleRadioDateRange = value => {
    this.setState({ radioValueDateRange: value });
  };
  actionDateRange = id => {
    const { radioValueDateRange } = this.state;
    const value = radioValueDateRange;
    const { from, to } = id;
    // const { statusFilter } = dateRangeDp;
    this.setState({
      openModalFilterBooking: false,
      activePage: 1,
    });
    let setDate = {};
    if (value === '2') {
      this.loadDataWithDate(
        moment(from).format('YYYY-MM-DD'),
        moment(to).format('YYYY-MM-DD'),
        '',
        '',
        'Booking_created_only'
      );
      setDate = {
        createStartDate: from,
        createEndDate: to,
        tourStartDate: '',
        tourEndDate: '',
        value: 'PayDownPayment',
      };
      this.setState({ dateRangeDp: setDate });
    } else {
      this.loadDataWithDate(
        '',
        '',
        moment(from).format('YYYY-MM-DD'),
        moment(to).format('YYYY-MM-DD'),
        'Booking_created_only'
      );
      setDate = {
        createStartDate: '',
        createEndDate: '',
        tourStartDate: from,
        tourEndDate: to,
        value: 'PayDownPayment',
      };
      this.setState({ dateRangeDp: setDate });
    }
    this.props.handleSetDateRange(setDate);
  };

  componentDidMount() {
    const {
      createStartDate,
      createEndDate,
      tourStartDate,
      tourEndDate,
    } = this.state.dateRangeDp;
    this.loadDataWithDate(
      createStartDate,
      createEndDate,
      tourStartDate,
      tourEndDate,
      'Booking_created_only'
    );
  }

  loadDataWithDate = (
    createStartDate,
    createEndDate,
    tourStartDate,
    tourEndDate,
    status
  ) => {
    // this.setState({ loading: true });
    this.props.getTransactionByStatusCreatedAction(
      tourStartDate,
      tourEndDate,
      createStartDate,
      createEndDate,
      status
    );
  };
  componentDidUpdate() {
    if (this.props.statusTransactionDownpayment === 'success') {
      this.setState({
        listOfTransactionDownPayment: this.props.listTransactionDownpayment,
        loadingDownPayment: false,
      });
      this.props.resetStatusTransactionAction();
    }
    // if (this.props.postTransactionConfirmationStatus === 'success') {
    //   const { dateRangeDp } = this.state;
    //   this.loadDataWithDate(
    //     dateRangeDp.createStartDate !== ''
    //       ? moment(dateRangeDp.createEndDate).format('YYYY-MM-DD')
    //       : '',
    //     dateRangeDp.createEndDate !== ''
    //       ? moment(dateRangeDp.createEndDate).format('YYYY-MM-DD')
    //       : '',
    //     dateRangeDp.tourStartDate !== ''
    //       ? moment(dateRangeDp.tourStartDate).format('YYYY-MM-DD')
    //       : '',
    //     dateRangeDp.tourEndDate !== ''
    //       ? moment(dateRangeDp.tourEndDate).format('YYYY-MM-DD')
    //       : '',
    //     'Booking_created_only'
    //   );
    //   this.setState({
    //     headerAlert: 'Success',
    //     contentAlert: 'Transaction Successfully',
    //     modalConfirmation: true,
    //     // loadingConfirm: false,
    //     // modalManualPayment: false,
    //     // openModalDetailBooking: false,
    //   });
    //   this.props.resetStatusTransactionAction();
    // } else if (this.props.postTransactionConfirmationStatus === 'failed') {
    //   this.setState({
    //     headerAlert: 'Failed',
    //     contentAlert: this.props.errorMessageTourTransaction,
    //     modalConfirmation: true,
    //     // openModalIframePaymentGateway: false,
    //     // loadingConfirm: false,
    //     // loadingBooking: false,
    //     // loadingTransactionOnHold: false,
    //   });
    //   this.props.resetStatusTransactionAction();
    // }
  }

  openModalDateRange = () =>
    this.setState({
      openModalFilterBooking: true,
      dateRangeDp: {
        createStartDate: '',
        createEndDate: '',
        tourStartDate: '',
        tourEndDate: '',
      },
    });

  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  filteredData = data =>
    data.filter(data => {
      return (
        data.BookingName.toLowerCase().indexOf(
          this.state.searchInput.toLowerCase()
        ) !== -1 ||
        data.BookingId.toLowerCase().indexOf(
          this.state.searchInput.toLowerCase()
        ) !== -1
      );
    });

  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };

  handleCloseFilterBooking = () => {
    const { openModalFilterBooking } = this.state;
    this.setState({
      openModalFilterBooking: !openModalFilterBooking,
    });
  };

  handleClose = () => {
    this.setState({
      modalConfirmation: false,
    });
    this.props.handleClose();
  };

  render() {
    const {
      dateRangeDp,
      listOfTransactionDownPayment,
      activePage,
      searchInput,
      headerTable,
      radioValueDateRange,
    } = this.state;
    let filterData = this.filteredData(listOfTransactionDownPayment);
    let pagedData = datasetPagination(filterData, 5);
    let listData = pagedData[activePage - 1];
    const props = this.props;

    let { numbersFrom, numbersTo } = getNumberFromTo(listData, activePage, 5);
    let createEndDate =
      dateRangeDp.createEndDate === ''
        ? ''
        : moment(dateRangeDp.createEndDate).format('DD MMM YYYY');
    let createStartDate =
      dateRangeDp.createStartDate === ''
        ? ''
        : moment(dateRangeDp.createStartDate).format('DD MMM');
    let tourStartDate =
      dateRangeDp.tourStartDate === ''
        ? ''
        : moment(dateRangeDp.tourStartDate).format('DD MMM');
    let tourEndDate =
      dateRangeDp.tourEndDate === ''
        ? ''
        : moment(dateRangeDp.tourEndDate).format('DD MMM YYYY');
    return (
      <div>
        <Grid columns="equal" style={{ marginTop: '30px' }}>
          <Grid.Row stretched>
            <Grid.Column width={15}>
              <Segment>
                <HeaderTitle title="Transaction Of Downpayment" />
                <Input
                  icon="search"
                  placeholder="search"
                  name="searchInput"
                  value={searchInput}
                  onChange={this.handleChange}
                />
                <Button
                  icon="filter"
                  basic
                  size="small"
                  floated="right"
                  positive
                  id="Transaction Of Down Payment"
                  name="Booking_created_only" //Booking_created
                  onClick={this.openModalDateRange}
                />
                <div
                  style={{
                    marginTop: '-2.9em',
                    marginRight: '5em',
                    fontWeight: 'bold',
                    marginBottom: '3em',
                  }}
                >
                  {/* {(tourStartDate !== '' || tourEndDate !== '') && ( */}
                  <Container
                    content={
                      'Start tour range date : ' +
                      tourStartDate +
                      ' - ' +
                      tourEndDate
                    }
                    textAlign="right"
                  />
                  {/* )}
                  {(createStartDate !== '' || createEndDate !== '') && ( */}
                  <Container
                    content={
                      'Created tour range date : ' +
                      createStartDate +
                      ' - ' +
                      createEndDate
                    }
                    textAlign="right"
                  />
                  {/* )} */}
                </div>

                <TableListOfTransactionDownPayment
                  data={props.data}
                  headerTable={headerTable}
                  pageChange={this.pageChange}
                  listData={listData}
                  pagedData={pagedData}
                  loading={props.loading}
                  actionTransaction={props.actionTransaction}
                  openModalSpecialAdjusment={props.openModalSpecialAdjusment}
                  openDetailTransaction={props.openDetailTransaction}
                />
                {pagedData && pagedData.length > 1 && (
                  <Pagination
                    totalPages={pagedData.length}
                    onPageChange={this.pageChange}
                    activePage={activePage}
                    style={{ float: 'right' }}
                  />
                )}
                {pagedData && pagedData.length > 1 && (
                  <p>
                    Showing {numbersTo} to {numbersFrom} of
                    {listOfTransactionDownPayment.length}
                  </p>
                )}
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <ModalDateRange
            openModal={this.state.openModalFilterBooking}
            handleClose={this.handleCloseFilterBooking}
            dateRange={dateRangeDp}
            actionDateRange={this.actionDateRange}
            contentHeader="Transaction On Down Payment"
            value={radioValueDateRange}
            handleRadioDateRange={this.handleRadioDateRange}
          />
          <ModalAlert
            openModal={this.state.modalConfirmation}
            handleClose={this.handleClose}
            header={this.state.headerAlert}
            content={this.state.contentAlert}
            nameButton="OK"
          />
        </Grid>
      </div>
    );
  }
}

ListOfTransactionDownPayment.propTypes = {
  data: PropTypes.array,
  actionTransaction: PropTypes.func,
  loading: PropTypes.bool,
  openModalSpecialAdjusment: PropTypes.func,
  openDetailTransaction: PropTypes.func,
  dateRange: PropTypes.object,
  actionDateRange: PropTypes.func,
  getTransactionByStatusCreatedAction: PropTypes.func,
  statusTransactionDownpayment: PropTypes.string,
  listTransactionDownpayment: PropTypes.array,
  resetStatusTransactionAction: PropTypes.func,
  postTransactionConfirmationStatus: PropTypes.string,
  errorMessageTourTransaction: PropTypes.string,
  handleClose: PropTypes.func,
  handleSetDateRange: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    listTransactionDownpayment:
      state.TourTransactionStore.listTransactionByStatusDownPayment,
    statusTransactionDownpayment:
      state.TourTransactionStore.transactionByStatusDownpayment,
    loading: state.TourTransactionStore.loading,
    postTransactionConfirmationStatus:
      state.TourTransactionStore.postTransactionConfirmationStatus,
    errorMessageTourTransaction: state.TourTransactionStore.errors.message,
  };
}
export default connect(
  mapStateToProps,
  {
    getTransactionByStatusCreatedAction,
    resetStatusTransactionAction,
  }
)(ListOfTransactionDownPayment);
