import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Grid, Button } from 'semantic-ui-react';
import RegisterCompany from '../form/FormRegisterCompany';
import RegisterUser from '../form/FormRegisterUser';
import { connect } from 'react-redux';
import options from '../../../../../../components/genericFunction/selectOptionConverter';
import ButtonClose from '../../../../../../components/button/buttonCloseModal';
import { getCountryListAction } from '../../../../actions/countryAction';
import { getCurrenciesAction } from '../../../../../../actions/currenciesAction';
import {
  validationShortRegisCompany,
  validationPhoneNumber,
} from '../../../../../../components/genericFunction/validation';

class ModalRegisOnBehalf extends Component {
  state = {
    formCompany: {
      companyName: { data: '', error: false, message: '' },
      companyCountry: { data: '', error: false, message: '' },
      companyTelephone: { data: '', error: false, message: '' },
      companyAddress: { data: '', error: false, message: '' },
      companyCurrency: { data: '', error: false, message: '' },
      userFirstName: { data: '', error: false, message: '' },
      userLastName: { data: '', error: false, message: '' },
      userPhoneNumber: { data: '', error: false, message: '' },
      userEmail: { data: '', error: false, message: '' },
    },
  };

  componentDidMount() {
    this.props.getCountryListAction();
    this.props.getCurrenciesAction();
  }

  handleChange = (e, { name, value }) => {
    const state = { ...this.state.formCompany };
    const objectInState = state[name];
    if (name === 'userPhoneNumber') {
      if (value === '+' || value === '') {
        objectInState.data = value;
      }
      if (validationPhoneNumber(value)) {
        objectInState.data = value;
      }
    } else {
      objectInState.data = value;
    }
    this.setState({ ...this.state, [name]: objectInState });
  };

  handleRegister = () => {
    let { formCompany } = this.state;
    let validationForm = validationShortRegisCompany(formCompany);
    if (!validationForm.isValid) {
      this.setState({ ...this.state, formCompany: validationForm.data });
    } else {
      let dataSimpleCompany = {
        CompanyName: formCompany.companyName.data,
        CompanyAddress: formCompany.companyAddress.data,
        CompanyPhone: formCompany.companyTelephone.data,
        CountryId: formCompany.companyCountry.data,
        CurrencyId: formCompany.companyCurrency.data,
        Firstname: formCompany.userFirstName.data,
        Lastname: formCompany.userLastName.data,
        UserPhone: formCompany.userPhoneNumber.data,
        Email: formCompany.userEmail.data,
      };
      this.props.simpleRegisterCompany(dataSimpleCompany);
    }
  };

  render() {
    let {
      isOpen,
      onClose,
      countryDataLoading,
      currenciesDataLoading,
      loadingCreateOnBehalf,
    } = this.props;
    let { formCompany } = this.state;
    let loadingFormCompany = countryDataLoading || currenciesDataLoading;
    return (
      <Modal open={isOpen} onClose={onClose} size="small" closeOnDimmerClick>
        <Modal.Header>
          Registration Company <ButtonClose onClose={onClose} />
        </Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16} style={{ paddingBottom: '5px' }}>
                <RegisterCompany
                  handleChange={this.handleChange}
                  companyName={formCompany.companyName}
                  companyCountry={formCompany.companyCountry}
                  companyTelephone={formCompany.companyTelephone}
                  companyAddress={formCompany.companyAddress}
                  companyCurrency={formCompany.companyCurrency}
                  countryOptions={this.props.countryList}
                  currencyOptions={this.props.currenciesList}
                  loading={loadingFormCompany}
                  validate={formCompany}
                />
              </Grid.Column>
              <Grid.Column width={16} style={{ paddingBottom: '5px' }}>
                <RegisterUser
                  handleChange={this.handleChange}
                  userFirstName={formCompany.userFirstName}
                  userLastName={formCompany.userLastName}
                  userPhoneNumber={formCompany.userPhoneNumber}
                  userEmail={formCompany.userEmail}
                  validate={formCompany}
                />
              </Grid.Column>
              <Grid.Column
                width={16}
                textAlign="right"
                style={{ paddingBottom: '5px' }}
              >
                <Button
                  onClick={this.handleRegister}
                  primary
                  loading={loadingCreateOnBehalf}
                >
                  Register
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

ModalRegisOnBehalf.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  getCountryListAction: PropTypes.func,
  getCurrenciesAction: PropTypes.func,
  countryDataLoading: PropTypes.bool,
  currenciesDataLoading: PropTypes.bool,
  countryList: PropTypes.array,
  currenciesList: PropTypes.array,
  simpleRegisterCompany: PropTypes.func,
  loadingCreateOnBehalf: PropTypes.bool,
};

const mapStateToProps = state => {
  const currenciesList = state.currenciesStore.currencies.map(data =>
    options(data.Id, data.Id, data.Description)
  );

  const countryList = state.CountryStore.getCountryList.map(data =>
    options(data.Id, data.Id, data.Name)
  );

  return {
    currenciesList,
    countryList,
    countryDataLoading: state.CountryStore.loading,
    currenciesDataLoading: state.currenciesStore.loading,
  };
};

export default connect(mapStateToProps, {
  getCountryListAction,
  getCurrenciesAction,
})(ModalRegisOnBehalf);
//   mapStateToProps,
//   {
//     getCurrencies,
//     getCountries,
//   }
// export default modalRegisCompany;
