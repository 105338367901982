import { getTime } from '../../../../../scripts/moment';

const setStayPeriod = data => {
  let availableDay = [];
  data.map(data => {
    return data.AllowedDates.map(
      obj =>
        getTime(obj.Date) >= getTime(new Date()) &&
        availableDay.push(new Date(obj.Date))
    );
  });
  return Object.values(availableDay);
};

export default setStayPeriod;
