import apiClient from '../apiClient';
import { GET_TRANSPORTATION_UNIT_BY_FILTER_URL } from '../apiUrl';

const getTransportationUnitByFilterApi = data => {
  return apiClient.get(
    GET_TRANSPORTATION_UNIT_BY_FILTER_URL(
      data.fromCity,
      data.toCities,
      data.RatingId,
      data.seatTypeId,
      data.typeId,
      data.requestedDate
    )
  );
};

export default getTransportationUnitByFilterApi;
