import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Input, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { HeaderBookingConfirmation } from '../../../../../components/table/headerTabel';
import { Link } from 'react-router-dom';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../../../scripts/pagination';
import FilterData from '../../../../../scripts/filterDataTable';
import ActionButton from '../../../../home/component/button/ActionButtonBooking';
import LoadingPage from '../../../../../components/modal/loaderModal';
import { getInvoiceByTransactionIdAction } from '../../../../../actions/tourTransactionAction';
import ModalAlert from '../../../../../components/modal/modalAlert';
import Pagination from '../../../../../components/common/pagination';
const emptyModalAlert = {
  isOpen: false,
  header: '',
  message: '',
};
class TableHistoryTransaction extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
      modalAlert: emptyModalAlert,
      loadingPrint: false,
    };
  }

  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });

  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };

  handlePrint = (e, { value }) =>
    this.setState({ ...this.state, loadingPrint: true }, () =>
      this.getInvoice(value)
    );

  getInvoice = id => {
    this.props
      .getInvoiceByTransactionIdAction(id)
      .then(() =>
        this.setState({ ...this.state, loadingPrint: false }, () =>
          this.handleDownloadFile(this.props.getInvoiceByTransactionId)
        )
      )
      .catch(() =>
        this.setState({ ...this.state, loadingPrint: false }, () =>
          this.openAlert('Failed', 'Failed get invoice')
        )
      );
  };

  handleDownloadFile = dataUrl => {
    window.open(dataUrl);
  };

  openAlert = (header, message) => {
    let modalAlert = {
      isOpen: true,
      header,
      message,
    };
    this.setState({
      ...this.state,
      modalAlert,
    });
  };

  closeAlert = () =>
    this.setState({ ...this.state, modalAlert: emptyModalAlert });

  status = status => {
    switch (status) {
      case 'Booking_DP_Confirmed':
        return <Table.Cell> DP Confirmed</Table.Cell>;
      // break;
      case 'Booking_Confirmed':
        return <Table.Cell> Confirmed</Table.Cell>;
      // break;
      case 'Booking_In_Payment':
        return <Table.Cell> In Payment</Table.Cell>;
      // break;
      case 'Booking_created':
        return <Table.Cell> Created</Table.Cell>;
      // break;
      case 'Booking_hold':
        return <Table.Cell> On Hold</Table.Cell>;
      // break;
      case 'Booking_Cancelled':
        return <Table.Cell> Cancel</Table.Cell>;
      // break;
      case 'Booking_draft':
        return <Table.Cell> Quotation</Table.Cell>;
      // break;
      case 'Booking_Completed':
        return <Table.Cell> Complete</Table.Cell>;
      // break;
      default:
        return <Table.Cell />;
    }
  };
  render() {
    const props = this.props;
    const { loadingPrint, modalAlert } = this.state;
    const { isHidePayment, isHideApprove } = this.props;
    const loading = loadingPrint || props.loading;
    const { isOpen, header, message } = modalAlert;
    let filterData = FilterData(
      this.props.listData,
      'Title',
      this.state.searchInput
    );
    let pagedData = datasetPagination(filterData, 3);
    let listData = pagedData[this.state.activePage - 1];
    let { numbersFrom, numbersTo } = getNumberFromTo(
      listData,
      this.state.activePage,
      3
    );
    return (
      <div>
        <LoadingPage loading={loading} />
        <Input
          icon="search"
          placeholder="Search Tour Name"
          name="searchInput"
          value={this.state.searchInput}
          onChange={this.handleChange}
        />
        <Table basic="very" selectable fixed>
          <Table.Header>
            <Table.Row>
              {HeaderBookingConfirmation.map((headTableContent, index) => {
                return headTableContent === 'Action' ? (
                  <Table.HeaderCell key={index} colSpan={2}>
                    {headTableContent}
                  </Table.HeaderCell>
                ) : (
                  <Table.HeaderCell key={index}>
                    {headTableContent}
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {listData &&
              listData.map((booking, idx) => {
                return (
                  <Table.Row key={idx}>
                    <Table.Cell component="th" scope="row" width={1}>
                      {booking.Id}
                    </Table.Cell>
                    <Table.Cell>
                      <Link
                        to={
                          props.isFinance
                            ? `/Simple-Transaction-Detail/${booking.Id}`
                            : `/tour-transaction/edit/${
                                booking.Status === 'Booking_draft'
                                  ? 'Quotation'
                                  : booking.PackageType
                              }/${booking.Id}`
                        }
                      >
                        {booking.Title}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      {/* <Link
                        to={`/Customer-Profile-Detail/detail/${booking.CreatedBy.CompanyCode}`}
                      ></Link> */}
                      {booking.CreatedBy.CompanyName}
                    </Table.Cell>
                    {this.status(booking.Status)}
                    <Table.Cell>{booking.TourTotalPrice}</Table.Cell>
                    <Table.Cell>{booking.TotalPayed}</Table.Cell>
                    <Table.Cell>
                      <Button
                        primary
                        content="Print"
                        onClick={this.handlePrint}
                        value={booking.Id}
                        //loading={loadingPrint}
                        disabled={props.disable}
                      />
                    </Table.Cell>
                    <Table.Cell colSpan={2}>
                      <ActionButton
                        id={booking.Id}
                        handleClick={props.actionButton}
                        value={booking.Status}
                        isHidePayment={isHidePayment}
                        isHideApprove={isHideApprove}
                        disabled={props.disable}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        {/* {pagedData &&
            pagedData.length > 1 && ( */}
        {/* <Table.Footer> */}
        <Pagination
          // totalPages={pagedData.length}
          // onPageChange={this.pageChange}
          // activePage={this.state.activePage}
          pagedData={pagedData}
          numbersTo={numbersTo}
          numbersFrom={numbersFrom}
          data={props.listData}
          pageChange={this.pageChange}
          activePage={this.state.activePage}
        />
        {/* </Table.Footer> */}
        {/* )} */}

        <ModalAlert
          openModal={isOpen}
          handleClose={this.closeAlert}
          header={header}
          content={message}
          nameButton="OK"
        />
      </div>
    );
  }
}

TableHistoryTransaction.propTypes = {
  listData: PropTypes.array,
  actionButton: PropTypes.func,
  loading: PropTypes.bool,
  getInvoiceByTransactionIdAction: PropTypes.func,
  getInvoiceByTransactionId: PropTypes.string,
  isHidePayment: PropTypes.bool,
  isHideApprove: PropTypes.bool,
  isFinance: PropTypes.bool,
  disable: PropTypes.bool,
};
// export default tableHistoryTransaction;
// connect
function mapStateToProps(state) {
  return {
    getInvoiceByTransactionId:
      state.TourTransactionStore.getInvoiceByTransactionId,
  };
}

export default connect(mapStateToProps, { getInvoiceByTransactionIdAction })(
  TableHistoryTransaction
);
