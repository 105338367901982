import { convertDateFormat } from '../../../../../scripts/moment';
import moment from 'moment';
import { getTime } from '../../../../../scripts/itineraryBuilder/timeHelper';

const copyObject = obj => {
  let newObj = JSON.stringify(obj);
  return JSON.parse(newObj);
};

export const inputValidation = (data, errorObject, isReadyFixPrice) => {
  let newError = copyObject(errorObject);
  let messageEmpty = 'Please check field, some field cannot be empty';
  let messageDate = 'Please check field date , the date invalid';
  newError.tourName.error = data.tourName === '';
  newError.description.error = data.description === '';
  newError.tourType.error = data.tourType === '';
  newError.tourCategory.error = data.tourCategory === '';
  newError.firstDestination.error = data.firstDestination === '';
  newError.expiredDate.error =
    data.expiredDate === '' ||
    dateValidation(data.activeDate, data.expiredDate);
  if (data.minPax === '' && isReadyFixPrice) {
    newError.minPax.error = true;
    newError.minPax.message = ['Please fill minimum pax'];
  } else if (data.minPax === '0' && isReadyFixPrice) {
    newError.minPax.error = true;
    newError.minPax.message = ['Please input at least 1 pax'];
  } else {
    newError.minPax.error = false;
    newError.minPax.message = [];
  }
  let field =
    newError.tourName.error ||
    newError.description.error ||
    newError.tourType.error ||
    newError.tourCategory.error ||
    newError.firstDestination.error ||
    newError.minPax.error;
  let date = newError.expiredDate.error;
  newError.message = field ? messageEmpty : date ? messageDate : null;
  newError.isError = field || date;
  return newError;
};

export const dateValidation = (activeDate, expiredDate) => {
  return moment(convertDateFormat(expiredDate, 'YYYY-MM-DD')).isSameOrBefore(
    convertDateFormat(activeDate, 'YYYY-MM-DD')
  );
};

export const readyFixedPriceValidation = (
  validation,
  packagePrice,
  listPeriod,
  isPublished,
  activeDate,
  statusAction
) => {
  if (
    (packagePrice.SharingRoomPrice === 0 ||
      packagePrice.SingleRoomPrice === 0) &&
    !isPublished
  ) {
    validation.isError = true;
    validation.messages.push(
      `This package cannot be ${
        statusAction === 'publish' ? 'published' : 'saved'
      } yet because it doesn't have a price in a single room or sharing room`
    );
  } else if (listPeriod.length === 0) {
    validation.isError = true;
    validation.messages.push(
      `This package cannot be ${
        statusAction === 'publish' ? 'published' : 'saved'
      } yet because it doesn't have a stay period`
    );
  } else if (listPeriod.length > 0) {
    var isValid = false;
    var isDayValid = false;
    listPeriod.map(data => {
      if (!isValid || !isDayValid) {
        isValid = getTime(data.StartDate) < getTime(activeDate);
        isDayValid = data.AllowedDays.length === 0;
      }
      return isValid;
    });
    if (isValid || isDayValid || listPeriod.length === 0) {
      const messageValidDate = `This package cannot be ${
        statusAction === 'publish' ? 'published' : 'saved'
      } yet because period is invalid. period cannot less than active date`;
      const messageValidDay = `This package cannot be ${
        statusAction === 'publish' ? 'published' : 'saved'
      } yet because period is invalid. please choose at least one day in every period`;
      validation.isError = true;
      validation.messages.push(isValid ? messageValidDate : messageValidDay);
    }
  }
  return validation;
};
