import { getTime, getTotalDays } from '../../../../scripts/moment';
const copyObject = obj => {
  let newObj = JSON.stringify(obj);
  return JSON.parse(newObj);
};
export const sharedPaxValidation = (data, pickedPackage) => {
  const error = {
    Destination: {
      error: false,
    },
    StartDate: {
      error: false,
    },
    Duration: {
      error: false,
    },
    message: '',
    isError: false,
  };
  const newError = copyObject(error);
  let message = '';

  if (data.startDate === '' || data.destination === '') {
    if (data.startDate === '' && data.destination === '') {
      newError.StartDate.error = data.startDate === '';
      newError.Destination.error = data.destination === '';
      message =
        'Please fill start date and destination to sharing pax this package';
    } else if (data.startDate === '') {
      newError.StartDate.error = data.startDate === '';
      message = 'Please fill start date to sharing pax this package';
    } else if (data.destination === '') {
      newError.Destination.error = data.destination === '';
      message = 'Please fill destination to sharing pax this package';
    }
  } else {
    let totalDaysCurrent = getTotalDays(data.startDate, data.endDate, true);
    newError.StartDate.error =
      getTime(data.startDate) !== getTime(pickedPackage.StartDate);
    newError.Destination.error = data.destination !== pickedPackage.City.Id;
    newError.Duration.error =
      totalDaysCurrent !==
      getTotalDays(pickedPackage.StartDate, pickedPackage.EndDate, true);
    let initial =
      newError.Destination.error &&
      newError.StartDate.error &&
      newError.Duration.error
        ? 'The current destination, start date & duration series package is different from the package you pick, Please change destination, start date & duration to series package that you pick '
        : newError.Destination.error
          ? 'The current destination series package is different from the package you pick, Please change destination to series package that you pick '
          : newError.StartDate.error
            ? 'The current start date series package is different from the package you pick, Please change start date to series package that you pick'
            : newError.Duration.error
              ? `The current duration series package is different from the package you pick, the duration in current series package is ${totalDaysCurrent} days. Please change duration to series package that you pick`
              : '';
    message = initial;
  }
  newError.message = message;
  newError.isError =
    newError.StartDate.error ||
    newError.Destination.error ||
    newError.Duration.error;
  return newError;
};

export const checkConfirmGuestSharingPax = async (
  sharingPaxId,
  sharingAction
) => {
  let checkConfirmGuest = false;
  await sharingAction('Fixed', sharingPaxId).then(res => {
    checkConfirmGuest =
      res.value.data.BookingDetailSum.FixedPackage.ConfirmedGuest !== 0
        ? true
        : false;
  });

  return checkConfirmGuest;
};
