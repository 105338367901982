import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Form } from 'semantic-ui-react';
import { getHourAndMinute, convertDateFormat } from '../../../scripts/moment';
import styles from './styles';
// import { connect } from 'react-redux';
// import Loader from '../common/loader';
import ItemWithIcon from '../../common/ItemWithIcon';

const arrivalDepartureCard = props => {
  const { data, subType } = props;
  const arrivalDepartureDate = data.Date;
  const arrivalDepartureTime = getHourAndMinute(data.Date);
  const label = subType === 'arrival' ? 'Arrival' : 'Departure';
  return (
    <div>
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Form>
              <Form.Group widths="equal">
                <Form.Field width={16}>
                  <ItemWithIcon
                    labelText={`${label} Date`}
                    labelStyle={{
                      display: 'block',
                      fontWeight: 'bold',
                      marginBottom: '4px',
                    }}
                    withIcon={true}
                    iconStyle={styles.goldColor}
                    descriptionText={convertDateFormat(
                      arrivalDepartureDate,
                      'DD MMM YYYY'
                    )}
                    iconName="calendar"
                  />
                </Form.Field>
                <Form.Field width={16}>
                  <ItemWithIcon
                    labelText={`${label} Time`}
                    labelStyle={{
                      display: 'block',
                      fontWeight: 'bold',
                      marginBottom: '4px',
                    }}
                    withIcon={true}
                    iconStyle={styles.goldColor}
                    descriptionText={arrivalDepartureTime}
                    iconName="clock"
                  />
                </Form.Field>
                <Form.Field width={16}>
                  {/* {subType === 'arrival' || ( */}
                  <ItemWithIcon
                    labelText="Flight Number"
                    labelStyle={{
                      display: 'block',
                      fontWeight: 'bold',
                      marginBottom: '4px',
                    }}
                    withIcon={false}
                    descriptionText={data.Note ? data.Note : '-'}
                  />
                  {/* )} */}
                </Form.Field>
              </Form.Group>
              <Form.Field width={16}>
                <ItemWithIcon
                  labelText={`${label} Destination`}
                  labelStyle={{
                    display: 'block',
                    fontWeight: 'bold',
                    marginBottom: '4px',
                  }}
                  withIcon={false}
                  descriptionText={data.Place ? data.Place : '-'}
                />
              </Form.Field>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {/* <Loader active={loading} inverted={true} /> */}
    </div>
  );
};

arrivalDepartureCard.propTypes = {
  index: PropTypes.number,
  subType: PropTypes.string,
  data: PropTypes.object,
  headlineProgram: PropTypes.object,
  loading: PropTypes.bool,
};
export default arrivalDepartureCard;
