import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Form,
  Checkbox,
  Message,
  // Button,
} from 'semantic-ui-react';
import InputWithLabel from '../../input/inputWithLabel';
import InputDate from '../../input/inputDate';
import InputTime from '../../input/inputTime';
// import IconButton from '../../button/iconButton';
import {
  getHourAndMinute,
  convertDateFormat,
} from '../../genericFunction/moment';
import {
  // setDateArrival,
  // setDateSummaryProgram,
  copyObject,
  // setDateDeparture,
  changeAirport,
  // isOverlapping,
  // nextDayFlight,
  // setArrivalTime,
  delConectionFlight,
  changeTimeArrivalDeparture,
  helperNextDay,
} from '../../../scripts/itineraryBuilder/dailyProgram';
import styles from '../styles';
// import { setPlaceDataByRegion } from '../../genericFunction/convertOption';
import {
  isOverlapping,
  changeFirstDepartureDate,
  changeLastArrivalDate,
} from '../../../scripts/itineraryBuilder/itineraryBuilder';
import segmentStyle from './styles';
import CustomSelect from '../../../components/input/CustomSelect';
import { initialAirport } from '../../../scripts/initialAirport';
// import Loader from '../../common/loader';
class segmentArrivalDeparture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placeDate: props.data.Date,
      placeTime: getHourAndMinute(props.data.Date),
    };
  }
  componentDidMount() {
    //set place when first time render the component
    if (
      !this.props.data.PlaceId &&
      this.props.places.length > 0 &&
      this.props.data.Region
    ) {
      let place = this.getPlaceByRegion(this.props.data.Region);
      if (place.length > 0) this.setFlight(place[0].Id);
    }
  }

  handleChangeDate = date => {
    let { type, headlineProgram } = this.props;
    let newHeadline = copyObject(headlineProgram);
    if (type === 'firstDeparture') {
      newHeadline = changeFirstDepartureDate(
        date,
        newHeadline,
        getHourAndMinute(newHeadline.Departures[0].Date)
      );
      let newDate = newHeadline.Departures[0].Date;
      this.setState({ ...this.state, placeDate: newDate });
    } else if (type === 'lastArrival') {
      newHeadline = changeLastArrivalDate(date, newHeadline);
      let newDate = newHeadline.Returns[newHeadline.Returns.length - 1].Date;
      this.setState({ ...this.state, placeDate: newDate });
    }
    this.props.setHeadLines(newHeadline);
  };
  handleChangeTime = (context, value) => {
    let { type, index, headlineProgram } = this.props;
    let newHeadline = copyObject(headlineProgram);
    if (['lastDeparture', 'lastArrival'].indexOf(type) !== -1) {
      let flight = newHeadline.Returns[index];
      let date = flight.Date;
      newHeadline.Returns[index] = changeTimeArrivalDeparture(
        flight,
        `${convertDateFormat(date, 'YYYY-MM-DD')}T${value}:00`
      );
    } else if (['firstDeparture', 'firstArrival'].indexOf(type) !== -1) {
      let flight = newHeadline.Departures[index];
      let date = flight.Date;
      newHeadline.Departures[index] = changeTimeArrivalDeparture(
        flight,
        `${convertDateFormat(date, 'YYYY-MM-DD')}T${value}:00`
      );
    } else {
      newHeadline.MainPrograms[index] = changeTimeArrivalDeparture(
        newHeadline.MainPrograms[index],
        `${convertDateFormat(
          newHeadline.MainPrograms[index].Date,
          'YYYY-MM-DD'
        )}T${value}:00`
      );
    }
    this.setState({ placeTime: value });
    this.props.setHeadLines(newHeadline);
  };
  setPlaceDataByRegion = (region, places) => {
    let selectedPlaces =
      places && places.length > 0
        ? places.find(item => item.region === region)
        : [];
    let place = selectedPlaces ? selectedPlaces.data : null;
    if (!place) {
      let allPlaces =
        places && places.length > 0
          ? places.reduce((result, current) => {
              result = result.concat(current.data);
              return result;
            }, [])
          : [];
      place = allPlaces.filter(item => item.Region.Id === region);
    }
    return place;
  };
  //convert place data for dropdown
  setPlaceData = () => {
    let { allPlaces } = this.props;
    let region = this.props.data.RequestFrom;
    if (region) {
      return this.setPlaceDataByRegion(region, this.props.places);
    } else return allPlaces ? allPlaces : [];
  };
  //set the place
  handleOnchange = value => {
    //(e, { value }) => {
    this.setFlight(value);
  };
  // set place in summary program
  // setFlight = value => {
  //   let { headlineProgram, type, index, data, cityList } = this.props;
  //   let headLineProg = { ...headlineProgram };
  //   let place = this.getPlaceByRegion(data.Region);
  //   let airport = place.find(item => item.Id === value);
  //   let city = cityList.find(item => item.Id === airport.CityId);
  //   if (type === 'firstArrival') {
  //     headLineProg.Departures[0] = changeAirport(data, airport);
  //     headLineProg.Departures[0].City = city;
  //   } else if (type === 'lastDeparture') {
  //     headLineProg.Returns[0] = changeAirport(data, airport);
  //     headLineProg.Returns[0].City = city;
  //   } else {
  //     headLineProg.MainPrograms[index] = changeAirport(data, airport);
  //     headLineProg.MainPrograms[index].City = city;
  //   }
  //   this.props.setHeadLines(headLineProg);
  // };

  setFlight = value => {
    let {
      headlineProgram,
      type,
      index,
      data,
      cityList,
      allPlaces,
    } = this.props;
    let headLineProg = copyObject(headlineProgram);
    let place =
      data.RequestFrom && ['firstDeparture', 'lastArrival'].indexOf(type) === -1
        ? this.getPlaceByRegion(data.RequestFrom)
        : allPlaces;
    let airport = place.find(item => item.Id === value);
    let city = cityList.find(item => item.Id === airport.CityId);
    if (type === 'firstArrival' || type === 'firstDeparture') {
      headLineProg.Departures[index] = changeAirport(data, airport);
      headLineProg.Departures[index].City = city;
    } else if (type === 'lastArrival' || type === 'lastDeparture') {
      headLineProg.Returns[index] = changeAirport(data, airport);
      headLineProg.Returns[index].City = city;
    } else {
      headLineProg.MainPrograms[index] = changeAirport(data, airport);
      headLineProg.MainPrograms[index].City = city;
    }
    this.props.setHeadLines(headLineProg);
  };

  //filter place array based on region
  getPlaceByRegion = region => {
    let { places } = this.props;
    let data = places.filter(item => item.region === region);
    return data.length > 0 ? data[0].data : [];
  };
  //function to edit note
  handleEditFlightNumber = (e, { value }) => {
    this.editFlightNumber(value);
  };
  editFlightNumber = value => {
    let { headlineProgram, type, index } = this.props;
    let headLineProg = copyObject(headlineProgram);
    if (type === 'firstDeparture') {
      headLineProg.Departures[0].Note = value;
    } else if (type === 'lastDeparture') {
      headLineProg.Returns[0].Note = value;
    } else {
      headLineProg.MainPrograms[index].Note = value;
    }
    this.props.setHeadLines(headLineProg);
  };

  handleNextDay = () => {
    let { headlineProgram, index, data, type } = this.props;
    let newHeadline = copyObject(headlineProgram);
    newHeadline = helperNextDay(newHeadline, index, data, type);
    this.props.setHeadLines(newHeadline);
  };
  // isNextDayDisable = () => {
  //   const { type, subType, headlineProgram, index } = this.props;
  //   if (type === 'connectionFlight' && subType === 'arrival') {
  //     return headlineProgram.MainPrograms[index - 1].NextDay;
  //   } else if (type === 'connectionFlight' && subType === 'departure') {
  //     return headlineProgram.MainPrograms[index + 1].NextDay;
  //   } else return false;
  // };
  isNextDayDisable = () => {
    const { type, headlineProgram, index } = this.props;
    if (type === 'connectionFlight') {
      return headlineProgram.MainPrograms[index + 1].NextDay;
    } else if (type === 'lastDeparture') {
      return headlineProgram.Returns[index + 1]
        ? headlineProgram.Returns[index + 1].NextDay
        : true;
    } else if (type === 'lastArrival') {
      return headlineProgram.Returns[index - 1]
        ? headlineProgram.Returns[index - 1].NextDay
        : true;
    } else return false;
  };

  handleDeleteConnectionFlight = () => {
    let { headlineProgram, index } = this.props;
    let hlProgram = copyObject(headlineProgram);
    hlProgram.MainPrograms = delConectionFlight(
      hlProgram.Departures[0],
      hlProgram.MainPrograms,
      index
    );
    this.props.setHeadLines(hlProgram);
  };
  render() {
    const { placeTime } = this.state;
    const {
      type,
      data,
      headlineProgram,
      index,
      labelDestination,
      labelNextDay,
      labelDate,
      labelTime,
      subType,
      allPlaces,
    } = this.props;
    const currentDate = data.Date;
    const options =
      ['firstDeparture', 'lastArrival'].indexOf(type) !== -1
        ? initialAirport(allPlaces)
        : initialAirport(this.setPlaceData());
    // this.setPlaceData();
    const isOverlapp = isOverlapping(headlineProgram, type, index);
    const isNextDayDisable = this.isNextDayDisable();
    return (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Form>
                <Form.Field width={16}>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width="7">
                        <label style={segmentStyle.dateTimeLabelStyle}>
                          {labelDestination}
                        </label>
                      </Grid.Column>
                      <Grid.Column width="9" textAlign="right">
                        {type !== 'firstDeparture' && (
                          <Checkbox
                            label={labelNextDay}
                            defaultChecked={data.NextDay}
                            onChange={this.handleNextDay}
                            disabled={isNextDayDisable}
                          />
                        )}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <CustomSelect
                    airport={options}
                    value={data.PlaceId ? data.PlaceId : ''}
                    handleSelect={this.handleOnchange}
                  />
                </Form.Field>
                <Form.Group widths="equal">
                  <Form.Field width={16}>
                    <InputDate
                      label={labelDate}
                      value={currentDate}
                      inputStyles={styles.inputDateStyle}
                      setDate={this.handleChangeDate}
                      labelStyle={segmentStyle.dateTimeLabelStyle}
                      iconStyle={styles.primaryColor}
                      readonly={
                        type === 'lastArrival' || type === 'firstDeparture'
                          ? false
                          : true
                      }
                    />
                  </Form.Field>
                  <Form.Field width={16}>
                    <InputTime
                      labelText={labelTime}
                      labelStyles={segmentStyle.dateTimeLabelStyle}
                      context={this}
                      value={placeTime}
                      setTime={this.handleChangeTime}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Field width={16}>
                  {subType === 'arrival' ? (
                    <div>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column width="7">
                            <label style={segmentStyle.dateTimeLabelStyle}>
                              Notes
                            </label>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                      <Message
                        style={{
                          color: '#573a08',
                          background: '#fffaf3',
                          margin: 0,
                          paddingTop: '9px',
                          paddingBottom: '9px',
                        }}
                        content="All date and time are local for each city"
                      />{' '}
                    </div>
                  ) : (
                    <InputWithLabel
                      label="Flight Number"
                      type="text"
                      value={data.Note}
                      labelStyles={segmentStyle.dateTimeLabelStyle}
                      handleChange={this.handleEditFlightNumber}
                      fluid={true}
                    />
                  )}
                </Form.Field>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {isOverlapp && (
          <Message error>
            <p>
              <strong>Warning!! your arrival time is overlapping.</strong>{' '}
            </p>
          </Message>
        )}
      </div>
    );
  }
}

segmentArrivalDeparture.propTypes = {
  subType: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.object,
  headlineProgram: PropTypes.object,
  setHeadLines: PropTypes.func,
  places: PropTypes.array,
  index: PropTypes.number,
  loading: PropTypes.bool,
  labelDestination: PropTypes.string,
  labelNextDay: PropTypes.string,
  labelDate: PropTypes.string,
  labelTime: PropTypes.string,
  cityList: PropTypes.array,
  allPlaces: PropTypes.array,
};
export default segmentArrivalDeparture;
