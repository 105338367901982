import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { HeaderPaymentTermDetail } from '../../constant/HeaderTable';
import moment from 'moment';
import Pagination from '../../../../../components/common/pagination';
// import { connect } from 'http2';
import { getNumberFromTo } from '../../../../../scripts/pagination';
class TablePaymentTermTourInDetailBooking extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
      // modalAlert: emptyModalAlert,
      loadingPrint: false,
    };
  }
  // const tablePaymentTermTourInDetailBooking = props => (

  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });

  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  render() {
    const props = this.props;

    let { numbersFrom, numbersTo } = getNumberFromTo(
      props.listData,
      this.state.activePage,
      2
    );
    return (
      <div style={{ marginTop: '2em' }}>
        <Table basic="very" selectable>
          <Table.Header>
            <Table.Row>
              {HeaderPaymentTermDetail.map((headTableContent, index) => {
                return (
                  <Table.HeaderCell key={index}>
                    {headTableContent}
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {props.listData ? (
              props.listData.map((data, idx) => {
                return (
                  <Table.Row key={idx}>
                    <Table.Cell>{data.Description}</Table.Cell>
                    <Table.Cell>{data.Percentage}</Table.Cell>
                    <Table.Cell
                      style={{
                        color: data.PaidOff ? 'blue' : 'red',
                        fontWeight: 'bold',
                      }}
                    >
                      {`${
                        props.Currency ? props.Currency : ''
                      } ${new Intl.NumberFormat('de-DE').format(
                        data.PaymentTermBasedValue
                          ? data.PaymentTermBasedValue
                          : 0
                      )}`}
                    </Table.Cell>
                    <Table.Cell
                      style={{
                        color: data.PaidOff ? 'blue' : 'red',
                        fontWeight: 'bold',
                      }}
                    >
                      {`${
                        props.Currency ? props.Currency : ''
                      } ${new Intl.NumberFormat('de-DE').format(
                        data.PaidValue ? data.PaidValue : 0
                      )}`}
                    </Table.Cell>
                    <Table.Cell
                      style={{
                        color: data.PaidOff ? 'blue' : 'red',
                        fontWeight: 'bold',
                      }}
                    >
                      {moment(data.DueDate).format('DD MMM YYYY')}
                    </Table.Cell>
                    <Table.Cell
                      style={{
                        color: data.PaidOff ? 'blue' : 'red',
                        fontWeight: 'bold',
                        // textAlign: data.PaidOff ? 'left' : 'center',
                      }}
                    >
                      {data.PaidDate
                        ? moment(data.PaidDate).format('DD MMM YYYY')
                        : `   -`}
                    </Table.Cell>
                    <Table.Cell
                      style={{
                        color: data.PaidOff ? 'blue' : 'red',
                        fontWeight: 'bold',
                      }}
                    >
                      {data.PaidOff === true ? 'Paid' : 'Unpaid'}
                    </Table.Cell>
                  </Table.Row>
                );
              })
            ) : (
              <Table.Row>
                <Table.Cell colSpan="7" textAlign="center">
                  No Data
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        <Pagination
          pagedData={props.pagedData}
          numbersTo={numbersTo}
          numbersFrom={numbersFrom}
          data={props.listData}
          pageChange={this.pageChange}
          activePage={this.state.activePage}
        />
      </div>
    );
  }
}

TablePaymentTermTourInDetailBooking.propTypes = {
  listData: PropTypes.array,
  pagedData: PropTypes.array,
  Currency: PropTypes.string,
};
function mapStateToProps(state) {
  return {
    getInvoiceByTransactionId:
      state.TourTransactionStore.getInvoiceByTransactionId,
  };
}
export default connect(
  mapStateToProps,
  {}
)(TablePaymentTermTourInDetailBooking);
