import React from 'react';
import { Table, Button, Checkbox } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ActionButton from '../button/ActionButtonCompanyProfileList';
import { StyleButton } from '../../../shared/components/common/StylesCommon';
import moment from 'moment';
import LoaderModal from '../../../../components/modal/loaderModal';
const TableCompanyRegistration = props => (
  <Table basic="very" selectable>
    <LoaderModal loading={props.loading} />
    <Table.Header>
      <Table.Row>
        {props.headerTable.map((headTableContent, index) => {
          if (headTableContent === 'checkCompany') {
            return (
              <Table.HeaderCell key={index}>
                <Checkbox name="checkAll" onChange={props.onChangeCompany} />
              </Table.HeaderCell>
            );
          }
          return (
            <Table.HeaderCell key={index}>{headTableContent}</Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {props.listData ? (
        props.listData.map((data, idx) => {
          return (
            <Table.Row key={idx}>
              <Table.Cell>
                <Checkbox
                  name={idx}
                  onChange={props.onChangeCompany}
                  //id={'check ' + idx}
                  id={data.Id}
                  key={idx}
                  checked={data.CheckCompany}
                />
              </Table.Cell>
              <Table.Cell>
                <Button
                  content={
                    data.CompanyDetail.Name ? data.CompanyDetail.Name : null
                  }
                  onClick={() => props.handleModalDetail(data)}
                  style={StyleButton.buttonTransparent}
                />
              </Table.Cell>
              <Table.Cell>{data.Email ? data.Email : null}</Table.Cell>
              <Table.Cell>
                {data.CompanyDetail.Address ? data.CompanyDetail.Address : null}
              </Table.Cell>
              <Table.Cell textAlign="center">
                {data.CompanyDetail.CreatedDate
                  ? moment(data.CompanyDetail.CreatedDate).format('DD MMM YYYY')
                  : '-'}
              </Table.Cell>
              <Table.Cell>
                {data.CompanyDetail.CompanyBusiness
                  ? data.CompanyDetail.CompanyBusiness
                  : null}
              </Table.Cell>
              <ActionButton
                value={data.Id ? data.Id : null}
                //id={data.CompanyDetail.Code + ',' + data.CompanyDetail.Name}
                id={data.Id ? data.Id : null}
                handleClick={props.actionCompanyRegistration}
                name1={1}
                name2={2}
                content1="Approve"
                content2="Reject"
                disabled={data.CheckCompany}
              />
            </Table.Row>
          );
        })
      ) : (
        <Table.Row>
          <Table.Cell colSpan="8" textAlign="center">
            No Data
          </Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  </Table>
);
TableCompanyRegistration.propTypes = {
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  onChangeCompany: PropTypes.func,
  actionCompanyRegistration: PropTypes.func,
  handleModalDetail: PropTypes.func,
  loading: PropTypes.bool,
};
export default TableCompanyRegistration;
