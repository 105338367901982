const Styles = {
  Segment: { marginTop: '30px' },
  BorderBlueBackgrounWhite: {
    background: 'white',
    border: 'solid 2px #2680EB',
    color: '#2680EB',
    padding: '9px 15px',
  },
};
export default Styles;
