import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'semantic-ui-react';

const uploadButton = props => {
  let myImageInput = React.createRef();
  return (
    <Form.Field inline>
      {/* <label style={props.labelStyles}>{props.labelTitle}</label> */}
      <Button
        //   icon
        //   labelPosition="left"
        onClick={() => myImageInput.click()}
        positive
        content={props.label}
        style={props.buttonStyle}
        id={props.id}
        disabled={props.disabled}
      />
      <div>
        <input
          hidden
          type="file"
          onChange={props.onImageSelect}
          ref={imageInput => (myImageInput = imageInput)}
          accept="image/gif,image/jpeg,image/jpg,image/png"
        />
      </div>
    </Form.Field>
  );
};

uploadButton.propTypes = {
  onImageSelect: PropTypes.func,
  label: PropTypes.string,
  buttonStyle: PropTypes.object,
  id: PropTypes.string,
  disabled: PropTypes.bool,
};

export default uploadButton;
