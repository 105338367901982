import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form } from 'semantic-ui-react'; //Checkbox
import InputRichTextEditor from '../../input/inputRichTextEditor';

const modalAddittionalInfo = props => (
  <Modal
    dimmer="blurring"
    closeIcon
    inverted
    open={props.openModal}
    onClose={props.handleClose}
    size="medium"
  >
    <Modal.Header>
      <h3>Add {props.tableName}</h3>
    </Modal.Header>
    <Modal.Content>
      <Form>
        <Form.Field>
          <Form.Input
            label="Title"
            value={
              props.additionalInfo.ForVoucher
                ? props.additionalInfo.SubTitle.replace('FOR_VOUCHER', '')
                : props.additionalInfo.SubTitle
            }
            onChange={props.onchangeTittleInfo}
            name="SubTitle"
          />
        </Form.Field>
        <Form.Field>
          <InputRichTextEditor
            onChange={props.onChangeAdditionalInfo}
            value={props.additionalInfo.Content}
          />
        </Form.Field>
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button content="Save" primary onClick={props.saveAddittionalInfo} />
    </Modal.Actions>
  </Modal>
);
modalAddittionalInfo.propTypes = {
  openModal: PropTypes.bool,
  handleClose: PropTypes.func,
  additionalInfo: PropTypes.shape({
    Content: PropTypes.string,
    SubTitle: PropTypes.string,
    ForVoucher: PropTypes.bool,
  }),
  onChangeAdditionalInfo: PropTypes.func,
  saveAddittionalInfo: PropTypes.func,
  onchangeTittleInfo: PropTypes.func,
  handleInfoForVoucher: PropTypes.func,
  tableName: PropTypes.string,
};
export default modalAddittionalInfo;
