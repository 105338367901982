const Style = {
  companyLogo: {
    margin: '-250px 0px 0px 200px',
  },
  buttonAddImageLeft: {
    // marginLeft: '25px',
    marginLeft: '10px',
    marginTop: '1em',
  },
  buttonAddImageRight: {
    marginLeft: '130px',
  },
  styleImagePreview: {
    margin: '30px 100px',
  },
  buttonImagePreview: {
    margin: '10px 10px 20px 145px',
  },
  buttonImagePreviewUserRole: {
    margin: '1em 1em 1em 7em',
  },
  checkBoxModalEmployeePosition: {
    marginLeft: '19em',
    top: '-28px',
  },
  widthDropdownUsername: {
    width: '20em',
  },
};

export { Style };
