const setOperatorData = data => {
  const {
    Address,
    //CityName,
    Company,
    Email,
    Id,
    ImageUrl,
    Name,
    RegionName,
    Telephone,
    City,
  } = data;
  let tourOperatorData = {
    Id: Id ? Id : '',
    Name: Name ? Name : '',
    Address: Address ? Address : '',
    Telephone: Telephone ? Telephone : '',
    Email: Email ? Email : '',
    ImageUrl: ImageUrl ? ImageUrl : '',
    CityId: City.Id ? City.Id : '',
    RegionId: RegionName === null ? null : RegionName.toUpperCase(),
    Company: Company ? Company : '',
  };
  return tourOperatorData;
};

export default setOperatorData;
