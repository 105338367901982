import * as types from '../actions/actionTypes';

const initialState = {
  getAirportList: [],
  getAirportStatus: '',
  loading: false,
  places: [],
  placeByType: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_AIRPORT_BY_CITY: {
      return { ...state };
    }
    case types.GET_AIRPORT_BY_CITY_PENDING: {
      return { ...state, loading: true };
    }
    case types.GET_AIRPORT_BY_CITY_FULFILLED: {
      let newPlaces = [...state.places];
      if (action.payload.data.length > 0) {
        if (
          newPlaces.filter(
            item =>
              action.payload.data.length !== 0 &&
              item.region === action.payload.data[0].RequestedFromId
          ).length === 0
        ) {
          newPlaces = newPlaces.concat({
            region: action.payload.data[0].RequestedFromId,
            data: action.payload.data,
          });
        }
      }
      return {
        ...state,
        loading: false,
        places: newPlaces,
        getAirportList: action.payload.data,
        getAirportStatus: 'success',
      };
    }
    case types.GET_AIRPORT_BY_CITY_REJECTED: {
      return {
        ...state,
        loading: false,
        getAirportStatus: 'failed',
      };
    }
    case types.RESET_AIRPORT: {
      return {
        ...state,
        getAirportStatus: '',
      };
    }
    //---------------------------------
    // GET_PLACE_BY_PLACE_TYPE_ID
    //---------------------------------
    case types.GET_PLACE_BY_PLACE_TYPE_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_PLACE_BY_PLACE_TYPE_ID_FULFILLED: {
      return {
        ...state,
        placeByType: action.payload.data,
        errors: {},
        loading: false,
      };
    }
    case types.GET_PLACE_BY_PLACE_TYPE_ID_REJECTED:
      return {
        ...state,
        placeByType: [],
        errors: action.payload.response.data,
        loading: false,
      };
    default: {
      return state;
    }
  }
};
export default reducer;
