import React from 'react';
import { Card, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import FilterContent from './filterContent';
import FilterHeader from './filterHeader';
import LoaderModal from '../../loaderModal';
import styles from '../../styles';

const formFilter = props => {
  let { formFilter, handleChange, resetFilter, loading } = props;
  return (
    <Card style={styles.segmentNoPadding}>
      <FilterHeader header="Filter" resetFilter={resetFilter} />
      {loading && (
        <Segment
          style={{
            border: 'none',
            background: 'transparent',
            minHeight: '50px',
          }}
        >
          <LoaderModal loading={loading} />
        </Segment>
      )}
      {!loading &&
        formFilter.map((item, index) => (
          <FilterContent
            key={index}
            columns={1}
            header={item.name}
            data={item.data}
            gridStyles={{ marginBottom: '15px', marginTop: '15px' }}
            columnStyles={{ paddingTop: '5px', paddingBottom: '5px' }}
            handleChange={handleChange}
            name={item.name}
          />
        ))}
    </Card>
  );
};
formFilter.propTypes = {
  loading: PropTypes.bool,
  formFilter: PropTypes.array,
  handleChange: PropTypes.func,
  resetFilter: PropTypes.func,
};

export default formFilter;
