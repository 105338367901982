import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Table, Button, Grid, Pagination } from 'semantic-ui-react';
// import styles from './../styles';
import ButtonClose from '../../../../../../components/button/buttonCloseModal';
import Input from '../../../../../../components/input/inputWithLabel';

class ModalCompanies extends Component {
  state = {
    searchCompany: '',
    activePage: 1,
  };
  pagination = (data, perPage) => {
    let sets = [],
      chunks = data.length / perPage;
    for (let i = 0, j = 0; i < chunks; i++, j += perPage)
      sets[i] = data.slice(j, j + perPage);
    return sets;
  };
  handleChange = (e, { name, value }) =>
    this.setState({ ...this.state, [name]: value, activePage: 1 });
  pageChange = (e, { activePage }) => {
    this.setState({ ...this.state, activePage });
  };
  render() {
    let { isOpen, onClose, companies, handleButtonPick } = this.props;
    let { searchCompany, activePage } = this.state;
    let filterCompanies = companies.filter(
      e =>
        searchCompany
          ? e.Name.toLowerCase().indexOf(searchCompany.toLowerCase()) !== -1
          : true
    );
    let pagedData = this.pagination(filterCompanies, 7);
    let listCompanies = pagedData[activePage - 1];
    return (
      <Modal open={isOpen} onClose={onClose} size="larfe" closeOnDimmerClick>
        <Modal.Header>
          Showing List Company <ButtonClose onClose={onClose} />
        </Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column width={6}>
                <Input
                  type="text"
                  placeholder="Search company by name"
                  icon="search"
                  iconPosition="left"
                  inputStyles={{ width: '100%' }}
                  handleChange={this.handleChange}
                  value={searchCompany}
                  name="searchCompany"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Table basic="very" selectable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Code</Table.HeaderCell>
                      <Table.HeaderCell>Name</Table.HeaderCell>
                      <Table.HeaderCell>Address</Table.HeaderCell>
                      <Table.HeaderCell>Telephone</Table.HeaderCell>
                      <Table.HeaderCell>Action</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {listCompanies &&
                      listCompanies.map((company, index) => (
                        <Table.Row key={index}>
                          <Table.Cell>{company.Code}</Table.Cell>
                          <Table.Cell>{company.Name}</Table.Cell>
                          <Table.Cell>{company.Address}</Table.Cell>
                          <Table.Cell>{company.Telephone}</Table.Cell>
                          <Table.Cell>
                            <Button
                              primary
                              value={company}
                              onClick={handleButtonPick}
                              name="selectedCompany"
                            >
                              Pick
                            </Button>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Column
                textAlign="center"
                width={16}
                style={{ paddingTop: '10px' }}
              >
                {pagedData &&
                  pagedData.length > 1 && (
                    <Pagination
                      totalPages={pagedData.length}
                      onPageChange={this.pageChange}
                      activePage={activePage}
                    />
                  )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

ModalCompanies.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  companies: PropTypes.array,
  handleButtonPick: PropTypes.func,
};

export default ModalCompanies;
