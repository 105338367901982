import {
  addDate,
  startOf,
  convertDateFormat,
  endOf,
  getTotalDays,
} from './moment';
// const ROOM_ALLOTMENT_BY_ID = {
//   ServiceItemId: null,
//   ServiceItemName: null,
//   RoomAllotments: [],
// };
const setMonth = (today, totalMonth) => {
  let firstDay = startOf(today, 'month');
  let listMonth = [];
  for (let i = 0; i < totalMonth; i++) {
    let newDate = addDate(firstDay, i, 'M');
    listMonth.push({
      year: convertDateFormat(newDate, 'YYYY'),
      month: convertDateFormat(newDate, 'MMM'),
      startDate: i === 0 ? today : newDate,
    });
  }
  return listMonth;
};
const setDayDate = startDate => {
  const numEndDate = parseInt(
    convertDateFormat(endOf(startDate, 'month'), 'DD'),
    10
  );
  const numStartDate = parseInt(convertDateFormat(startDate, 'DD'), 10);
  const differenceDate = numEndDate - numStartDate;
  let dates = [];
  for (let i = 0; i <= differenceDate; i++) {
    let date = addDate(startDate, i, 'd');
    dates.push(date);
  }
  let sisa = Math.abs((differenceDate % 7) - 7);
  for (let i = 1; i < sisa; i++) {
    dates.push(null);
  }
  return dates;
};

const setDayDateWithRange = (startDate, endDate) => {
  let differenceDate = getTotalDays(startDate, endDate, true) - 1;
  let dates = [];
  for (let i = 0; i <= differenceDate; i++) {
    let date = addDate(startDate, i, 'd');
    dates.push(date);
  }
  let sisa = Math.abs((differenceDate % 7) - 7);
  for (let i = 1; i < sisa; i++) {
    dates.push(null);
  }
  return dates;
};

const selectEndDayDate = dates => {
  let findIndex = dates.findIndex(date => date === null);
  let indexEndDates = findIndex !== -1 ? findIndex - 1 : dates.length - 1;
  return dates[indexEndDates];
};
const setRoomAllotment = (roomAllotment, dates, defaultReleaseDays) => {
  return roomAllotment.reduce((accRoom, room) => {
    let newDatesRoomAllotment = dates.reduce((accDatesRoom, date) => {
      date = date ? convertDateFormat(date, 'YYYY-MM-DD') : null;
      let indexDates = room.RoomAllotments.findIndex(
        e => e && convertDateFormat(e.DateTime, 'YYYY-MM-DD') === date
      );
      let ReleaseDays =
        indexDates !== -1
          ? room.RoomAllotments[indexDates].ReleaseDays
          : defaultReleaseDays;
      let Qty = indexDates !== -1 ? room.RoomAllotments[indexDates].Qty : 0;
      let IsSpecialPeriod =
        indexDates !== -1
          ? room.RoomAllotments[indexDates].IsSpecialPeriod
          : false;
      let StockCode =
        indexDates !== -1 ? room.RoomAllotments[indexDates].StockCode : '';
      let StockItemId =
        indexDates !== -1 ? room.RoomAllotments[indexDates].StockItemId : '';
      let IsStillValid =
        indexDates !== -1 ? room.RoomAllotments[indexDates].IsStillValid : null;
      let StockLeft =
        indexDates !== -1 ? room.RoomAllotments[indexDates].StockLeft : 0;
      accDatesRoom.push(
        date
          ? {
              DateTime: date,
              IsSpecialPeriod,
              IsStillValid,
              Qty,
              ReleaseDays,
              StockCode,
              StockItemId,
              StockLeft,
            }
          : null
      );
      return accDatesRoom;
    }, []);
    accRoom.push({
      ...room,
      RoomAllotments: newDatesRoomAllotment,
    });
    return accRoom;
  }, []);
};

const changeAllotment = (roomAllotments, idRoom, date, qty) => {
  // eslint-disable-next-line
  let indexChange = roomAllotments.findIndex(e => e.ServiceItemId == idRoom);
  let roomAllotment = roomAllotments[indexChange];
  roomAllotment.RoomAllotments.map(e => {
    if (e && e.DateTime === date) {
      e.Qty = qty;
    }
    return e;
  });
  roomAllotments.splice(indexChange, 1, roomAllotment);
  return roomAllotments;
};
export {
  setMonth,
  setDayDate,
  selectEndDayDate,
  setRoomAllotment,
  changeAllotment,
  setDayDateWithRange,
};
