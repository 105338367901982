import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Grid } from 'semantic-ui-react';
import TableSupplierPrice from '../../../../../../components/table/tableSupplierPrice';
import { HeaderTranportationPrice } from '../../constant/headerTable';
import { DoubleDropdownWithErrorOnFluid } from '../../../../../../components/common/fieldWithError';
import { StylesTextfieldWithError } from '../../../../../../components/common/styleCommon';

const modalTransportationPrice = props => (
  <Modal
    //dimmer="blurring"
    closeIcon
    inverted
    open={props.openModalPrice}
    onClose={props.handleClose}
    size="large"
  >
    <Modal.Header as={'h2'}>{props.headerModal} Unit Price</Modal.Header>
    <Modal.Content>
      <h4>
        <strong>
          {props.transportationHeader.TransportationProfileName} in{' '}
          {props.countryName}{' '}
          {props.transportationPrice.OriginRegion
            ? props.transportationPrice.OriginRegion.Name + ', '
            : ''}{' '}
          {props.transportationPrice.OriginCity
            ? props.transportationPrice.OriginCity.Name
            : ''}
        </strong>
      </h4>
      {/* <h4>
        <strong>From - To</strong>
      </h4> */}
      <Grid columns={2} padded="vertically">
        <Grid.Column>
          {/* Kiri */}
          <DoubleDropdownWithErrorOnFluid
            name1="OriginRegion"
            valueDropDown1={
              props.transportationPrice.OriginRegion
                ? props.transportationPrice.OriginRegion.Id
                : ''
            }
            title1="Origin Region"
            handleChange={props.handleChangePrice}
            placeholder1="Origin Region"
            option1={props.listRegion}
            requiredDropdownLeft={true}
            name2="OriginCity"
            valueDropDown2={
              props.transportationPrice.OriginCity
                ? props.transportationPrice.OriginCity.Id
                : ''
            }
            title2="Origin City"
            placeholder2="Origin City"
            option2={props.listOriginCity}
            requiredDropdownRight={true}
            styleDropdownLeft={StylesTextfieldWithError.sizeDoubleComponent}
            styleDropdownRight={StylesTextfieldWithError.doubleDropdown}
            disable={props.headerModal === 'Edit'}
          />
        </Grid.Column>
        <Grid.Column>
          {/* Kanan */}
          <DoubleDropdownWithErrorOnFluid
            name1="DestinationRegion"
            valueDropDown1={
              props.transportationPrice.DestinationRegion
                ? props.transportationPrice.DestinationRegion.Id
                : ''
            }
            title1="Destination Region"
            handleChange={props.handleChangePrice}
            placeholder1="Destination Region"
            option1={props.listRegion}
            requiredDropdownLeft={true}
            name2="DestinationCity"
            valueDropDown2={
              props.transportationPrice.DestinationCity
                ? props.transportationPrice.DestinationCity.Id
                : ''
            }
            title2="Destination City"
            placeholder2="Destination City"
            option2={props.listDestCity}
            requiredDropdownRight={true}
            styleDropdownLeft={StylesTextfieldWithError.sizeDoubleComponent}
            styleDropdownRight={StylesTextfieldWithError.doubleDropdown}
            disable={props.headerModal === 'Edit'}
          />
        </Grid.Column>
      </Grid>
      <Button
        content="Add Price"
        positive
        //onClick={props.addPriceDetails}
        onClick={props.openModalPriceDetail}
        style={StylesTextfieldWithError.buttonTransportationPricePosition}
      />

      <div style={{ overflow: 'auto' }}>
        <TableSupplierPrice
          currenciesList={props.currencies}
          servicePriceType={props.servicePriceType}
          headerTable={HeaderTranportationPrice}
          listData={props.transportationPrice.PriceDetails}
          handleChangePrices={props.handleChangeCostPriceDetails}
          handleChangePrice={props.handleChangePriceDetails}
          deletePrice={props.handleClickDelete}
          handleEditPriceDetail={props.handleEditPriceDetail}
          numbersTo={props.numbersTo}
          numbersFrom={props.numbersFrom}
        />
      </div>
      {/* </Form> */}
    </Modal.Content>
    <Modal.Actions>
      <Button content="Save" primary onClick={props.addPriceToList} />
    </Modal.Actions>
  </Modal>
);
modalTransportationPrice.propTypes = {
  openModalPrice: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  content: PropTypes.string,
  addPrice: PropTypes.func,
  listRegion: PropTypes.array,
  listDestCity: PropTypes.array,
  listOriginCity: PropTypes.array,
  handleChangePrice: PropTypes.func,
  transportationPrice: PropTypes.shape({
    OriginRegion: PropTypes.shape({
      Id: PropTypes.string,
      Name: PropTypes.string,
    }),
    DestinationRegion: PropTypes.shape({
      Id: PropTypes.string,
      Name: PropTypes.string,
    }),
    OriginCity: PropTypes.shape({
      Id: PropTypes.string,
      Name: PropTypes.string,
    }),
    DestinationCity: PropTypes.shape({
      Id: PropTypes.string,
      Name: PropTypes.string,
    }),
    PriceDetails: PropTypes.array,
  }),
  addPriceDetails: PropTypes.func,
  servicePriceType: PropTypes.array,
  currencies: PropTypes.array,
  handleChangePriceDetails: PropTypes.func,
  handleChangeCostPriceDetails: PropTypes.func,
  addPriceToList: PropTypes.func,
  handleClickDelete: PropTypes.func,
  transportationHeader: PropTypes.object,
  headerModal: PropTypes.string,
  countryName: PropTypes.string,
  handleEditPriceDetail: PropTypes.func,
  openModalPriceDetail: PropTypes.bool,
  numbersFrom: PropTypes.number,
  numbersTo: PropTypes.number,
};
export default modalTransportationPrice;
