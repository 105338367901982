import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Button } from 'semantic-ui-react';
import TableAddingData from '../table/tableAddingData';
// import Pagination from '../common/pagination';
const addingDataComponent = props => (
  <div>
    <Grid columns="equal" style={{ marginTop: '0.5em' }} verticalAlign="middle">
      <Grid.Row style={{ marginBottom: 0 }}>
        <Grid.Column>
          <Header as="h3" content={props.headerContent} />
        </Grid.Column>
        <Grid.Column />
        <Grid.Column floated="right">
          <Button
            positive
            content={props.buttonContent}
            onClick={props.addData}
            floated="right"
            id={props.idButton}
            disabled={props.disablePage}
            loading={props.loadingShowListCity}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <TableAddingData
            addingDataList={props.addingDataList}
            pagedData={props.pageAddingData}
            listData={props.listAddingData}
            deleteState={props.deleteData}
            headerTable={props.headerTable}
            value={props.idButton}
            disabled={props.disablePage}
            // listDataAccessCity={props.listDataAccessCity}
            //pageAddingData={props.pageAddingData}
            activePage={props.activePage}
            pageChange={props.pageChange}
            handleChange={props.handleChange}
            numbersTo={props.numbersTo}
            numbersFrom={props.numbersFrom}
            headerContent={props.headerContent}
          />
          {/* <Pagination
            pagedData={props.pagedData}
            numbersTo={props.numbersTo}
            numbersFrom={props.numbersFrom}
            data={props.data}
            pageChange={props.pageChange}
            activePage={props.activePage}
          /> */}
        </Grid.Column>
      </Grid.Row>
    </Grid>

    {/* <Grid columns="equal">
        <Grid.Row stretched>
          <Header
            as="h4"
            content={props.headerContent}
            style={Style.titleTable}
          />
          <Button
            positive
            content={props.buttonContent}
            onClick={props.addData}
            id={props.idButton}
            //style={Style.buttonAddingTable}
            floated="right"
            disabled={props.disablePage}
          />
          <Grid.Column>
            <TableAddingData
              listData={props.listData}
              deleteState={props.deleteData}
              headerTable={props.headerTable}
              value={props.idButton}
              disabled={props.disablePage}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid> */}
  </div>
);

addingDataComponent.propTypes = {
  addingDataList: PropTypes.array,
  handleChange: PropTypes.func,
  data: PropTypes.array,
  // listData: PropTypes.array,
  pagedData: PropTypes.array,
  addData: PropTypes.func,
  buttonContent: PropTypes.string,
  headerContent: PropTypes.string,
  deleteData: PropTypes.func,
  headerTable: PropTypes.array,
  listAddingData: PropTypes.array,
  idButton: PropTypes.string,
  viewDataBy: PropTypes.string,
  disablePage: PropTypes.bool,
  pageAddingData: PropTypes.array,
  activePage: PropTypes.string,
  listDataAccessCity: PropTypes.array,
  pageChange: PropTypes.string,
  numbersFrom: PropTypes.number,
  numbersTo: PropTypes.number,
  loadingShowListCity: PropTypes.bool,
};
export default addingDataComponent;
