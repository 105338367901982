import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Label, Segment, Header } from 'semantic-ui-react';
// import noImage from './../../../assets/image/NoImagetrans.png';
// import GoldRibbon from './../../home/tourPackage/goldRibbon';
import LightBox from './lightBox';
// import options from './../../../genericFunction/imageListConverter';
// import HeaderRoomCard from './headerRoomCard';
import styles from './../../styles';

// const bgImage = (imgUrl, width) => {
//   return {
//     width: width,
//     minHeight: '150px',
//     flex: 'none',
//     backgroundImage: `url("${imgUrl}")`,
//     // backgroundSize: 'cover',
//     borderBottom: '0px',
//     backgroundRepeat: 'no-repeate',
//     backgroundPosition: 'center bottom',
//     backgroundColor: '#f7f7f7',
//     backgroundSize: '100% 100%',
//     border: 'none',
//   };
// };

// const imageCard = props => {
class imageCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openImage: false,
    };
  }
  openImage = () => {
    this.setState({ openImage: !this.state.openImage });
  };
  // getImageArray(images, image) {
  //   let showImage;
  //   if (images.length !== 0) {
  //     showImage = images.map(image => {
  //       return options('images', image.ImageUrl);
  //     });
  //   } else if (image) {
  //     showImage = [options('images', image)];
  //   } else {
  //     showImage = [options('images', noImage)];
  //   }
  //   return showImage;
  // }
  render() {
    // let { images, image, isPromo } = this.props;
    let { images, image } = this.props;
    let { openImage } = this.state;
    return (
      <Segment
      // style={bgImage(
      //   images.length !== 0 ? images[0].ImageUrl : image ? image : noImage,
      //   size
      // )}
      >
        {/* {isPromo ? <GoldRibbon size="big" text="Promo" /> : null} */}
        {images.length > 1 ? (
          <Label
            size="big"
            onClick={this.openImage}
            attached="bottom"
            style={styles.imageAttachment}
          >
            <Header as="h3" textAlign="center" style={styles.goldColor}>
              SEE MORE PHOTO
            </Header>
          </Label>
        ) : null}
        {openImage && (
          <LightBox
            images={this.getImageArray(images, image)}
            handleClose={this.openImage}
          />
        )}
      </Segment>
    );
  }
}
imageCard.propTypes = {
  images: PropTypes.array,
  image: PropTypes.string,
  isPromo: PropTypes.bool,
  size: PropTypes.string,
};
export default imageCard;
