import React from 'react';
import { Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const totalDataTableLabel = props => {
  return (
    <div style={{ margin: 'auto' }}>
      {props.pagedData && props.pagedData.length > 1 && (
        <Header as="h5">
          {`Showing ${props.numbersFrom} to ${props.numbersTo} of ${props.data.length}`}
        </Header>
      )}
    </div>
  );
};
totalDataTableLabel.propTypes = {
  pagedData: PropTypes.array,
  numbersTo: PropTypes.number,
  numbersFrom: PropTypes.number,
  data: PropTypes.array,
};

export default totalDataTableLabel;
