import {
  DELETE_COUNTRY,
  POST_COUNTRY,
  PUT_COUNTRY,
  RESET_COUNTRY_PAGE,
} from './constant/actionTypes';
import deleteCountryApi from '../../api/country/deleteCountryApi';
import postCountryApi from '../../api/country/postCountryApi';
import putCountryApi from '../../api/country/putCountryApi';

const resetCountryPageAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_COUNTRY_PAGE,
    });
  };
};

const deleteCountryAction = Id => {
  return dispatch => {
    return dispatch({
      type: DELETE_COUNTRY,
      payload: deleteCountryApi(Id),
    });
  };
};

const postCountryAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_COUNTRY,
      payload: postCountryApi(data),
    });
  };
};

const putCountryAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_COUNTRY,
      payload: putCountryApi(id, data),
    });
  };
};

export {
  deleteCountryAction,
  putCountryAction,
  postCountryAction,
  resetCountryPageAction,
};
