import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Segment } from 'semantic-ui-react';
import HeaderTitle from '../../shared/components/common/headerSegment/HeaderTitle';
import TableListResourceBooking from './components/table/TableListResourceBooking';
import styles from './styles';
import InputSearch from '../../../components/input/inputSearch';
import { getResourceBookingListApi } from '../../../api/getResourceBookingListApi';
import { getResourceBookingByPackageNumberApi } from '../../../api/getResourceBookingByPackageNumberApi';
import { getResourceBookingByTransactionNumberApi } from '../../../api/getResourceBookingByTransactionNumberApi';
import useApiCall from './hooks/useApiCall';
import PaginationScript from '../../../components/genericFunction/pagination';
import { searchFilterResourceBooking } from '../../../scripts/filterData';
import { getNumberFromTo } from '../../../components/genericFunction/getPaginationNumberFrom';
import ModalPackageList from './components/modal/ModalPackageList';
import ButtonDropdown from '../../../components/common/buttonDropdown';
import { Redirect } from 'react-router-dom';
// import moment from 'moment';
// import ModalDateRange from '../../shared/components/modal/ModalDateRangeCheckIn';
import { addDate, convertDateFormat } from '../../../scripts/moment';
const TODAY = new Date();

const DROPDOWNS = [
  { value: 'byPackageNo', title: 'By Package Number' },
  { value: 'byTransactionNo', title: 'By Transaction Number' },
];
const ResourceBooking = () => {
  //=======================initial state=======================
  // const { isRefreshPending, setIsReloadUpcoming } = props;
  const [openModalResource, setOpenModalResource] = useState(false);
  // const [openDateRange, setOpenDateRange] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [activePage, setActivePage] = useState(1);
  const [loadingPackage, setLoadingPackage] = useState(false);
  const [createBy, setCreateBy] = useState('');
  const [resourceBookingLoading, resourceBookingList] = useApiCall(
    getResourceBookingListApi
  );
  const [changePage, setChangePage] = useState({
    isChangePage: false,
    link: '',
  });
  const [resourceBookingByType, setResourceBookingByType] = useState([]);

  //=======================lifecycle=======================
  // useEffect(() => {
  //   isRefreshPending && loadData();
  //   // eslint-disable-next-line
  // }, [isRefreshPending]); // Only re-run the effect if isRefreshPending is true

  //=======================helper=======================
  // const actionDateRange = id => {
  //   let from = moment(id.from).format('YYYY-MM-DD');
  //   let to = moment(id.to).format('YYYY-MM-DD');
  //   handleClose();
  //   handleDateRange(from, to);
  // };

  const filteredData = (data, searchInput) => {
    return searchFilterResourceBooking(data, searchInput);
  };

  const pageChange = (e, { activePage }) => {
    setActivePage(activePage);
  };

  //=======================handler=======================
  const handleChangeSearch = e => {
    setSearchInput(e.target.value);
    setActivePage(1);
  };

  const handleAddResource = async (e, { name }) => {
    setOpenModalResource(true);
    setCreateBy(name);
    setLoadingPackage(true);
    if (name === 'byPackageNo') {
      getResourceBookingByPackageNumberApi()
        .then(result => {
          setResourceBookingByType(result.data);
          setLoadingPackage(false);
        })
        .catch(() => {
          setLoadingPackage(false);
        });
    } else if (name === 'byTransactionNo') {
      await getResourceBookingByTransactionNumberApi(
        convertDateFormat(TODAY, 'YYYY-MM-DD'),
        convertDateFormat(addDate(TODAY, 30, 'd'), 'YYYY-MM-DD'),
        '',
        '',
        'After_DP'
      )
        .then(result => {
          setResourceBookingByType(resourceBookingByType.concat(result.data));
          setLoadingPackage(false);
        })
        .catch(() => {
          setLoadingPackage(false);
        });
    }
  };

  const handleClose = () => {
    setOpenModalResource(false);
    setResourceBookingByType([]);
    // setOpenDateRange(false);
  };
  //   const handleOpenDateRange = () => {
  //     setOpenDateRange(true);
  //   };
  const handleOpenDetail = (e, { id }) => {
    setChangePage({
      isChangePage: true,
      link: '/Resource-Booking-Detail/' + id,
    });
  };

  //=======================pagination data=======================
  let filterData = filteredData(
    resourceBookingList !== null ? resourceBookingList : [],
    searchInput
  );
  let pagedData = PaginationScript(filterData, 5);
  let listData = pagedData[activePage - 1];
  let { numbersFrom, numbersTo } = getNumberFromTo(listData, activePage, 5);

  //   let checkInUntilDate = !endDate ? '' : moment(endDate).format('DD MMM YYYY');
  //   let checkInStartDate = !startDate ? '' : moment(startDate).format('DD MMM');
  if (changePage.isChangePage) {
    return <Redirect to={changePage.link} />;
  } else
    return (
      <Grid columns="equal" style={styles.styleGrid}>
        <Grid.Row stretched>
          <Grid.Column width={16}>
            {/* <Segment loading={false}> */}
            <Segment loading={resourceBookingLoading}>
              <HeaderTitle
                title="Resource Booking List"
                iconName="clipboard list"
              />
              <Grid>
                <Grid.Row>
                  <Grid.Column width={12}>
                    <InputSearch
                      inputStyle={styles.inputStyle}
                      inputInnerStyle={styles.inputStyleInner}
                      icon="search"
                      placeholder="Search"
                      name="searchInput"
                      value={searchInput}
                      handleChange={handleChangeSearch}
                    />
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <ButtonDropdown
                      dropdowns={DROPDOWNS}
                      title="Add Resource Booking"
                      handleClick={handleAddResource}
                      pointing={true}
                      labeled={true}
                      dropdownStyles={{
                        color: 'white',
                        background: '#16ab39',
                        zIndex: '99999',
                      }}
                      icon="plus"
                    />
                  </Grid.Column>
                </Grid.Row>
                {/* <Button
              icon="filter"
              basic
              size="small"
              floated="right"
              positive
              onClick={handleOpenDateRange}
            />
            <div
              style={{
                marginTop: '-2.9em',
                marginRight: '5em',
                fontWeight: 'bold',
              }}
            >
              <Container
                content={
                  'Check-in range date : ' +
                  checkInStartDate +
                  ' - ' +
                  checkInUntilDate
                }
                textAlign="right"
              />
            </div> */}
                <Grid.Row style={styles.styleRowTable}>
                  <Grid.Column width={16}>
                    <TableListResourceBooking
                      activePage={activePage}
                      data={resourceBookingList}
                      pagedData={pagedData}
                      listData={listData}
                      numbersFrom={numbersFrom}
                      numbersTo={numbersTo}
                      pageChange={pageChange}
                      handleClose={handleClose}
                      handleOpenDetail={handleOpenDetail}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <ModalPackageList
          header={
            createBy === 'byPackageNo'
              ? 'Package Number List'
              : 'Transaction Number List'
          }
          openModal={openModalResource}
          closeModal={handleClose}
          isCreateByPackage={createBy === 'byPackageNo'}
          packages={resourceBookingByType}
          loading={loadingPackage}
          //packageType={packageTypeSelected === ''}
        />
        {/* <ModalDateRange
          contentHeader="Resource Booking List"
          openModal={openDateRange}
          close={handleClose}
          actionDateRange={actionDateRange}
          startDate={startDate}
          endDate={endDate}
        /> */}
      </Grid>
    );
};

ResourceBooking.propTypes = {
  isRefreshPending: PropTypes.bool,
  setIsReloadUpcoming: PropTypes.func,
};

export default React.memo(ResourceBooking);
