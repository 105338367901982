import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import ButtonClose from '../../../components/button/buttonCloseModal';
import { ACCOMMODATION, RESTAURANT } from '../scripts/supplier';
import TableItemAccommodation from '../../shared/components/table/accommodation/TableItem';
import { HeaderTabelAccomodationItemList } from '../../../components/table/headerTabel';
import { copyObject } from '../../../scripts/itineraryBuilder/itineraryBuilder';
import LoaderModal from '../../../components/modal/loaderModal';
import TableItem from '../../restaurant/restaurantProfile/components/TableItem';
import { headerRestaurantItemList } from '../../restaurant/restaurantProfile/constant/tableHeader';

const ModalSupplierItem = props => {
  let {
    openModal,
    handleClose,
    resourceType,
    accommodationItem,
    handleChooseItem,
    restaurantItem,
  } = props;
  const copyHeaderTabelAccomodationItemList = copyObject(
    HeaderTabelAccomodationItemList
  );
  copyHeaderTabelAccomodationItemList.splice(-1, 0, 'Estimated Price');
  const copyHeaderRestaurantItemList = copyObject(headerRestaurantItemList);
  copyHeaderRestaurantItemList.splice(-1, 0, 'Estimated Price');
  return (
    <Modal
      dimmer="blurring"
      inverted
      open={openModal}
      onClose={handleClose}
      size="large"
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        Change Resource <ButtonClose onClose={handleClose} />
      </Modal.Header>
      <Modal.Content>
        <LoaderModal loading={accommodationItem.loading} />
        {resourceType === ACCOMMODATION ? (
          <TableItemAccommodation
            header={copyHeaderTabelAccomodationItemList}
            listData={accommodationItem.listItem}
            useInResourceBookingList={true}
            handleChooseItemForRBL={handleChooseItem}
          />
        ) : resourceType === RESTAURANT ? (
          <TableItem
            header={copyHeaderRestaurantItemList}
            listData={restaurantItem.listItem}
            IdProfile={restaurantItem.profileId}
            useInResourceBookingList={true}
            handleChooseItemForRBL={handleChooseItem}
          />
        ) : null}
      </Modal.Content>
    </Modal>
  );
};

ModalSupplierItem.propTypes = {
  openModal: PropTypes.bool,
  handleClose: PropTypes.func,
  resourceType: PropTypes.string,
  accommodationItem: PropTypes.object,
  restaurantItem: PropTypes.object,
  handleChooseItem: PropTypes.func,
};
export default ModalSupplierItem;
