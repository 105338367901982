const Style = {
  buttonAddingTable: {
    marginLeft: '28em',
  },
  titleTable: {
    marginBottom: '-2em',
    marginLeft: '1em',
  },
  marginTable: {
    marginTop: '2em',
    marginBottom: '2em',
  },
  buttonBackAndSave: {
    marginLeft: '21.9em',
    //position: 'absolute',
    marginTop: '2em',
  },
  buttonGroupSupplierStyle: {
    marginBottom: '-2.5em',
  },
};

export { Style };
