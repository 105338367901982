const sortExchangeRate = (value, list) => {
  if (value === 1) {
    return list.sort(function(a, b) {
      return (
        new Date(a.EffectiveDate).getTime() -
        new Date(b.EffectiveDate).getTime()
      );
    });
  } else if (value === 2) {
    return list.sort(function(a, b) {
      return (
        new Date(b.EffectiveDate).getTime() -
        new Date(a.EffectiveDate).getTime()
      );
    });
  }
};

export default sortExchangeRate;
