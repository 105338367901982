import { useState, useEffect } from 'react';

const useApiCall = (api, id = null) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState(undefined);

  const fetchApi = id => {
    setIsLoading(true);
    api(id && id)
      .then(result => {
        let optionsData = result.data;
        setIsLoading(false);
        setFetchedData(optionsData);
      })
      .catch(err => {
        //eslint-disable-next-line
                console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // setFetchedData(id ? {} : null);
    if (id > 0) fetchApi(id);
    // eslint-disable-next-line
    }, []);

  const loadData = id => {
    fetchApi(id);
  };

  return [isLoading, fetchedData, setFetchedData, loadData];
};

export default useApiCall;
