import { convertDateFormat } from '../../../../../scripts/moment';
const setDataTourGuide = state => {
  const data = {
    Id: state.Id ? state.Id : '',
    FirstName: state.FirstName ? state.FirstName : '',
    LastName: state.LastName ? state.LastName : '',
    NationalityId: state.NationalityId ? state.NationalityId : '',
    IdentityNbr: state.IdentityNbr ? state.IdentityNbr : '',
    StartedJobAt: convertDateFormat(state.StartedJobAt, 'YYYY-MM-DD'),
    AddressId: state.AddressObject.Id,
    Address1: state.AddressObject.Address1,
    Address2: state.AddressObject.Address2,
    Address3: state.AddressObject.Address3,
    Landmark: state.AddressObject.Landmark,
    PostalCode: state.AddressObject.PostalCode,
    AreaId: state.AddressObject.AreaId,
    // TourGuideLanguage: state.TourGuideLanguage.map(data => data.LanguageId),
    TourGuideLanguage: state.TourGuideLanguage.map(data => {
      return {
        ServiceItemId: data.ServiceItemId,
        TourGuideId: state.Id,
        Action: 0,
        LanguageId: data.LanguageId,
        Language: data.Language,
      };
    }),
    // RegionCoverages: state.RegionCoverage.map(data => data.Region.Id),
    RegionCoverages: state.RegionCoverage.map(data => {
      return {
        Id: data.Id,
        TourGuideId: state.Id,
        Action: 0,
        RegionId: data.Region.Id,
        RegionName: data.Region.Name,
      };
    }),
    EmailAddress: state.EmailAddress,
  };

  return data;
};

export default setDataTourGuide;
