import React, { Component } from 'react';
import { Table, Button, Form, Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { headerRestaurantPrice } from '../../constant/tableHeader';
import FilterData from '../../../../../scripts/filterDataTable';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../../../scripts/pagination';
import InputDate from '../../../../../components/input/inputDate';
import Pagination from '../../../../shared/components/common/pagination/Pagination';

class RestaurantPriceTable extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: new Date(),
      activePage: 1,
    };
  }
  static propTypes = {
    data: PropTypes.array,
  };
  handleChangeDate = (value, name) => {
    this.setState({ [name]: value, activePage: 1 });
  };
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };

  handleEditPrice = (e, { data }) => {
    let index = this.props.listData.findIndex(item => item === data);
    this.props.editPrice(index);
  };
  handleDeletePrice = (e, { data }) => {
    let index = this.props.listData.findIndex(item => item === data);
    this.props.deletePrice(index);
  };
  render() {
    let filterData = FilterData(
      this.props.listData,
      'FormatedDate',
      this.state.searchInput
    );
    let pagedData = filterData ? datasetPagination(filterData, 3) : filterData;
    let listData = pagedData ? pagedData[this.state.activePage - 1] : [];
    const props = this.props;

    let { numbersFrom, numbersTo } = getNumberFromTo(
      listData,
      this.state.activePage,
      3
    );
    return (
      <div>
        <Grid columns={2} padded="vertically">
          <Grid.Column>
            <Form>
              <InputDate
                label="Search by valid date"
                value={this.state.searchInput}
                setDate={this.handleChangeDate}
                labelStyle={{
                  fontWeight: 'bold',
                }}
                name="searchInput"
              />
            </Form>
          </Grid.Column>
          <Grid.Column>
            <Button
              positive
              icon="add"
              content="Add New"
              id="price"
              onClick={props.addPrice}
              floated="right"
              style={{ marginTop: '1em' }}
              disabled={props.disablePage}
            />
          </Grid.Column>
        </Grid>

        <Table basic="very" selectable fixed>
          <Table.Header>
            <Table.Row>
              {headerRestaurantPrice.map((headTableContent, index) => {
                return (
                  <Table.HeaderCell key={index} textAlign="center">
                    {headTableContent}
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {listData &&
              listData.map((price, i) => {
                return (
                  <Table.Row key={i} textAlign="center">
                    <Table.Cell>
                      {price.ServicePriceType.replace(/_/g, ' ')}
                    </Table.Cell>
                    <Table.Cell>
                      {moment(price.StartDate).format('DD MMMM YYYY')}
                    </Table.Cell>
                    <Table.Cell>
                      {moment(price.EndDate).format('DD MMMM YYYY')}
                    </Table.Cell>
                    <Table.Cell>
                      {' '}
                      {price.Currency +
                        '           ' +
                        new Intl.NumberFormat('de-DE').format(price.Cost)}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Button
                        name={i}
                        data={price}
                        positive
                        content="Edit"
                        onClick={this.handleEditPrice} //{props.editPrice}
                        disabled={props.disablePage}
                      />
                      <Button
                        name={i}
                        negative
                        data={price}
                        content="Delete"
                        onClick={this.handleDeletePrice}
                        disabled={props.disablePage}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        {/* {pagedData &&
            pagedData.length > 1 && (
              <Table.Footer> */}
        <Pagination
          // totalPages={pagedData.length}
          // onPageChange={this.pageChange}
          // activePage={this.state.activePage}
          pagedData={pagedData}
          numbersTo={numbersTo}
          numbersFrom={numbersFrom}
          data={props.listData}
          pageChange={this.pageChange}
          activePage={this.state.activePage}
        />
        {/* </Table.Footer>
            )} */}
      </div>
    );
  }
}

RestaurantPriceTable.propTypes = {
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  EditPrice: PropTypes.func,
  editPrice: PropTypes.func,
  deletePrice: PropTypes.func,
  disablePage: PropTypes.bool,
};
export default RestaurantPriceTable;
