import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Form } from 'semantic-ui-react';
import InputWithLabel from '../../../../components/input/inputWithLabel';
const ContactInfoProfile = props => {
  return (
    <div>
      <Grid columns="equal">
        <Grid.Row stretched>
          <Grid.Column width={15}>
            <Header as="h4" content="Contact Information" />
            <Form>
              <Form.Field>
                <InputWithLabel
                  label="Address"
                  placeholder="Address"
                  name="Address"
                  type="textArea"
                  value={props.contentDetail.Address}
                  handleChange={props.handleChange}
                  required
                  fluid={true}
                  validate={props.errorInformation}
                  disabled={props.disabled}
                />
              </Form.Field>
              <Form.Group>
                <Form.Field width={8}>
                  <InputWithLabel
                    label="Country"
                    placeholder="Country"
                    name="CountryId"
                    type="select"
                    value={props.contentDetail.CountryId}
                    handleChange={props.handleChange}
                    options={props.countryListOptions}
                    required
                    fluid={true}
                    validate={props.errorInformation}
                    disabled={props.disabled}
                  />
                </Form.Field>
                <Form.Field width={8}>
                  <InputWithLabel
                    label="Phone Number"
                    placeholder="Phone Number"
                    name="Telephone"
                    type="number"
                    value={props.contentDetail.Telephone}
                    handleChange={props.handleChange}
                    required
                    fluid={true}
                    validate={props.errorInformation}
                    disabled={props.disabled}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={8}>
                  <InputWithLabel
                    label="Email"
                    placeholder="Email"
                    name="Email"
                    type="text"
                    value={props.contentDetail.Email}
                    handleChange={props.handleChange}
                    required
                    fluid={true}
                    validate={props.errorInformation}
                    disabled={props.disabled}
                  />
                </Form.Field>
                <Form.Field width={8}>
                  <InputWithLabel
                    label="Fax Number"
                    placeholder="Fax Number"
                    name="FaxNbr"
                    type="number"
                    value={props.contentDetail.FaxNbr}
                    handleChange={props.handleChange}
                    required
                    fluid={true}
                    validate={props.errorInformation}
                    disabled={props.disabled}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

ContactInfoProfile.propTypes = {
  handleChange: PropTypes.func,
  contentDetail: PropTypes.shape({
    Address: PropTypes.string,
    Telephone: PropTypes.string,
    Email: PropTypes.string,
    FaxNbr: PropTypes.string,
    CountryId: PropTypes.string,
  }),
  errorInformation: PropTypes.object,
  disabled: PropTypes.bool,
  countryListOptions: PropTypes.array,
};
export default ContactInfoProfile;
