import { GET_LANGUAGE_ALL } from '../constant/actionTypes';
import { getLanguageAllApi } from '../../../api/languageApi';

const getLanguageAll = () => {
  return dispatch => {
    return dispatch({
      type: GET_LANGUAGE_ALL,
      payload: getLanguageAllApi(),
    });
  };
};

export { getLanguageAll };
