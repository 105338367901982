import {
  RESET_REGION,
  GET_REGION_BY_COUNTRY,
  GET_REGION_LIST,
} from '../constants/actionTypes';
import getRegionByRegionApi from '../../../api/region/getRegionByCountryApi';
import getRegionListApi from '../../../api/region/getRegionListApi';
const getRegionListAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_REGION_BY_COUNTRY,
      payload: getRegionByRegionApi(Id),
    });
  };
};

const resetRegionAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_REGION,
    });
  };
};

const getRegionAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_REGION_LIST,
      payload: getRegionListApi(),
    });
  };
};

export { getRegionListAction, resetRegionAction, getRegionAction };
