import * as types from './constant/actionTypes';
const initialState = {
  loading: false,
  getAccomodationTypes: [],
  getAccomodationLocation: [],
  getAccomodationFacilities: [],
  getAccomodationItemTypes: [],
  getAccomodationItemTypesStatus: '',
  getAccomodationItemServiceType: [],
  getAccomodationItemServiceTypeStatus: '',
  postAccomodationStatus: '',
  putAccomodationStatus: '',
  getAccomodationItemExport: '',
  getAccomodationItemExportStatus: '',
  postUploadImageAccomodationItem: [],
  postAccomodation: [],
  postAccomodationItem: [],
  postAccomodationItemStatus: '',
  putAccomodationItem: [],
  putAccomodationItemStatus: '',
  deleteAccomodationItemStatus: '',
  deleteImageAccomodationStatus: '',
  deleteContractingFile: {},
  deleteContractingFileStatus: '',
  postContractingFile: {},
  postContractingFileStatus: '',
  errors: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ACCOMODATION_TYPES:
      return { ...state };
    case types.GET_ACCOMODATION_TYPES_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
      };
    case types.GET_ACCOMODATION_TYPES_PENDING:
      return { ...state, loading: true };
    case types.GET_ACCOMODATION_TYPES_FULFILLED:
      return {
        ...state,
        getAccomodationTypes: action.payload.data,
        loading: false,
      };

    case types.GET_ACCOMODATION_LOCATION:
      return { ...state };
    case types.GET_ACCOMODATION_LOCATION_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
      };
    case types.GET_ACCOMODATION_LOCATION_PENDING:
      return { ...state, loading: true };
    case types.GET_ACCOMODATION_LOCATION_FULFILLED:
      return {
        ...state,
        getAccomodationLocation: action.payload.data,
        loading: false,
      };

    case types.GET_ACCOMODATION_FACILITIES:
      return { ...state };
    case types.GET_ACCOMODATION_FACILITIES_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
      };
    case types.GET_ACCOMODATION_FACILITIES_PENDING:
      return { ...state, loading: true };
    case types.GET_ACCOMODATION_FACILITIES_FULFILLED:
      return {
        ...state,
        getAccomodationFacilities: action.payload.data,
        loading: false,
      };

    case types.GET_ACCOMODATION_ITEM_SERVICE_TYPE_LIST:
      return { ...state };
    case types.GET_ACCOMODATION_ITEM_SERVICE_TYPE_LIST_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getAccomodationItemServiceTypeStatus: 'failed',
      };
    case types.GET_ACCOMODATION_ITEM_SERVICE_TYPE_LIST_PENDING:
      return { ...state, loading: true };
    case types.GET_ACCOMODATION_ITEM_SERVICE_TYPE_LIST_FULFILLED:
      return {
        ...state,
        getAccomodationItemServiceType: action.payload.data,
        loading: false,
        getAccomodationItemServiceTypeStatus: 'success',
      };

    case types.GET_ACCOMODATION_ITEM_TYPES:
      return { ...state };
    case types.GET_ACCOMODATION_ITEM_TYPES_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getAccomodationItemTypesStatus: 'failed',
      };
    case types.GET_ACCOMODATION_ITEM_TYPES_PENDING:
      return { ...state, loading: true };
    case types.GET_ACCOMODATION_ITEM_TYPES_FULFILLED:
      return {
        ...state,
        getAccomodationItemTypes: action.payload.data,
        loading: false,
        getAccomodationItemTypesStatus: 'success',
      };

    case types.POST_ACCOMODATION:
      return { ...state };
    case types.POST_ACCOMODATION_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        postAccomodationStatus: 'failed',
      };
    case types.POST_ACCOMODATION_PENDING:
      return { ...state, loading: true };
    case types.POST_ACCOMODATION_FULFILLED:
      return {
        ...state,
        postAccomodation: action.payload.data,
        postAccomodationStatus: 'success',
        loading: false,
      };

    case types.PUT_ACCOMODATION:
      return { ...state };
    case types.PUT_ACCOMODATION_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        putAccomodationStatus: 'failed',
      };
    case types.PUT_ACCOMODATION_PENDING:
      return { ...state, loading: true };
    case types.PUT_ACCOMODATION_FULFILLED:
      return { ...state, putAccomodationStatus: 'success', loading: false };

    case types.GET_ACCOMODATION_ITEM_EXPORT_DATA:
      return { ...state };
    case types.GET_ACCOMODATION_ITEM_EXPORT_DATA_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getAccomodationItemExportStatus: 'failed',
      };
    case types.GET_ACCOMODATION_ITEM_EXPORT_DATA_PENDING:
      return { ...state, loading: true };
    case types.GET_ACCOMODATION_ITEM_EXPORT_DATA_FULFILLED:
      return {
        ...state,
        getAccomodationItemExport: action.payload.data,
        loading: false,
        getAccomodationItemExportStatus: 'success',
      };

    case types.POST_ACCOMODATION_ITEM:
      return { ...state };
    case types.POST_ACCOMODATION_ITEM_REJECTED:
      return {
        ...state,
        errors: { message: action.payload.response.data.Message },
        loading: false,
        postAccomodationItemStatus: 'failed',
      };
    case types.POST_ACCOMODATION_ITEM_PENDING:
      return { ...state, loading: true };
    case types.POST_ACCOMODATION_ITEM_FULFILLED:
      return {
        ...state,
        postAccomodationItem: action.payload.data,
        postAccomodationItemStatus: 'success',
        loading: false,
      };

    case types.PUT_ACCOMODATION_ITEM:
      return { ...state };
    case types.PUT_ACCOMODATION_ITEM_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        putAccomodationItemStatus: 'failed',
      };
    case types.PUT_ACCOMODATION_ITEM_PENDING:
      return { ...state, loading: true };
    case types.PUT_ACCOMODATION_ITEM_FULFILLED:
      return {
        ...state,
        putAccomodationItem: action.payload.data,
        putAccomodationItemStatus: 'success',
        loading: false,
      };

    case types.DELETE_ACCOMODATION_ITEM:
      return { ...state };
    case types.DELETE_ACCOMODATION_ITEM_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
      };
    case types.DELETE_ACCOMODATION_ITEM_PENDING:
      return { ...state, loading: true };
    case types.DELETE_ACCOMODATION_ITEM_FULFILLED:
      return {
        ...state,
        deleteAccomodationItemStatus: 'success',
        loading: false,
      };

    case types.DELETE_IMAGE:
      return { ...state };
    case types.DELETE_IMAGE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
      };
    case types.DELETE_IMAGE_PENDING:
      return { ...state, loading: true };
    case types.DELETE_IMAGE_FULFILLED:
      return {
        ...state,
        deleteImageAccomodationStatus: 'success',
        loading: false,
      };

    case types.DELETE_CONTRACTING_FILE:
      return { ...state };
    case types.DELETE_CONTRACTING_FILE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        deleteContractingFileStatus: 'failed',
      };
    case types.DELETE_CONTRACTING_FILE_PENDING:
      return { ...state, loading: true };
    case types.DELETE_CONTRACTING_FILE_FULFILLED:
      return {
        ...state,
        deleteContractingFile: action.payload.data,
        deleteContractingFileStatus: 'success',
        loading: false,
      };

    case types.POST_CONTRACTING_FILE:
      return { ...state };
    case types.POST_CONTRACTING_FILE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        postContractingFileStatus: 'failed',
      };
    case types.POST_CONTRACTING_FILE_PENDING:
      return { ...state, loading: true };
    case types.POST_CONTRACTING_FILE_FULFILLED:
      return {
        ...state,
        postContractingFile: action.payload.data,
        postContractingFileStatus: 'success',
        loading: false,
      };

    case types.RESET_STATUS_ACCOMODATION_PAGE:
      return {
        ...state,
        getAccomodationListStatus: '',
        getAccomodationItemStatus: '',
        getAccomodationItemByServiceTypeStatus: '',
        postAccomodationStatus: '',
        deleteAccomodationStatus: '',
        getAccomodationTemplateStatus: '',
        getAccomodationExportStatus: '',
        //
        putAccomodationStatus: '',
        getAccomodationItemTypesStatus: '',
        getAccomodationItemExportStatus: '',
        //
        getAccomodationItemTemplateStatus: '',
        postUploadImageAccomodationProfileStatus: '',
        postUploadImageAccomodationItemStatus: '',
        getAccomodationItemAllStatus: '',
        getAccomodationByProfilIdAndDateStatus: '',
        //
        postAccomodationItemStatus: '',
        putAccomodationItemStatus: '',
        //
        postPrimaryImageStatus: '',
        //
        deleteAccomodationItemStatus: '',
        deleteImageAccomodationStatus: '',
        //
        getAccomodationByCityStatus: '',
        //
        deleteContractingFileStatus: '',
        postContractingFileStatus: '',
        //
        getAccomodationwithFilterStatus: '',
        isNeedUpdateRoomAllotment: false,
      };

    default:
      return state;
  }
};

export default reducer;
