import {
  GET_GENERATE_AR_SUMMARY,
  GET_AR_SUMMARY_LIST,
  DOWNLOAD_AR_SUMMARY,
} from '../shared/constants/actionTypes';

import {
  getGenerateARSummaryApi,
  getARSummaryListApi,
  getARSummaryDownloadApi,
} from '../../api/accountReceivable/accountRecievableSummaryApi';

const getGenerateARSummaryAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_GENERATE_AR_SUMMARY,
      payload: getGenerateARSummaryApi(),
    });
  };
};

const getARSummaryListAction = (year, month) => {
  return dispatch => {
    return dispatch({
      type: GET_AR_SUMMARY_LIST,
      payload: getARSummaryListApi(year, month),
    });
  };
};

const getARSummaryDownloadAction = (year, month) => {
  return dispatch => {
    return dispatch({
      type: DOWNLOAD_AR_SUMMARY,
      payload: getARSummaryDownloadApi(year, month),
    });
  };
};

export {
  getGenerateARSummaryAction,
  getARSummaryListAction,
  getARSummaryDownloadAction,
};
