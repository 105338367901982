import React from 'react';
import { Dimmer } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {
  resetExcursionPageAction,
  postExcursionAction,
  putExcursionAction,
  getExcursionPriceTypeAction,
  // getExportExcursionPriceAction,
} from '../excursionAction';
import {
  getExcursionListAction,
  resetExcursionAction,
  getExcursionTypeAction,
  getExcursionAction,
  getTemplateExcursionObjectAction,
  getTemplateExcursionPriceAction,
  postSetPrimaryExcursionObjectAction,
  getExportDataItemByIdAction,
} from '../../shared/actions/excursionAction';
import { getRegionAction } from './../../shared/actions/regionAction';
import {
  getServicePriceAction,
  postServicePriceAction,
  putServicePriceAction,
  deleteServicePriceAction,
  resetServicePriceAction,
  getServicePriceTypeAction,
} from '../../../actions/servicePriceAction';
import { getCurrenciesAction } from '../../../actions/currenciesAction';
import Loader from '../../../components/loader';
import ExcrustionProfile from './components/ExcursionProfileDetails';
import PropTypes from 'prop-types';
import { getCityListAction } from '../../shared/actions/cityAction';
import { getRegionListAction } from '../../shared/actions/regionAction';
import { getCountryListAction } from '../../shared/actions/countryAction';
import { getAreaListAction } from '../../shared/actions/areaAction';
import { datasetPagination } from '../../../scripts/pagination';
import FilterData from '../../../scripts/filterDataTable';
import {
  getHourAndMinute,
  convertSecondToHour,
  // convertHourToSecond,
} from '../../../components/genericFunction/moment';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import ModalAlert from '../../shared/components/modal/ModalAlert';
import ModalConfirm from '../../../components/modal/modalConfirmationTwoButton';
import ModalExcursionPrice from '../../transportation/transportationProfile/transportationUnit/components/modal/modalTransportationPriceDetail';
import handleValidation from './services/validation/emptyFieldValidation';
import {
  contactAddressField,
  excursionGeneralInfoField,
  priceFieldToValidate,
} from './constant/fieldToValidate';
import _ from 'lodash';
import {
  setAddressObject,
  setExcursionObject,
  setPriceListData,
  setDataToPost,
  setPriceToPost,
} from './services/data/setDataState';
class ExcursionProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      excursion: {
        IsSolidDuration: false,
        IsSolidStartTime: false,
        OperationStartTime: getHourAndMinute(moment()),
        OperationEndTime: getHourAndMinute(moment()),
        OptimumDuration: convertSecondToHour(0),
        ServiceItemId: 0,
        Name: '',
        Description: '',
        LongDescription: '',
        AttractionCategory: '',
        AttractionType: '',
        ObjectImages: [],
      },
      errors: {},
      listAccessCity: [],
      //errorsPrice: [],
      loadingExcursion: true,
      failedExcursion: false,
      modalOpen: false,
      redirect: false,
      ServiceItemId: 0,
      addressObject: {
        Address1: '',
        CountryId: '',
        PhoneNbr: '',
        Email: '',
        CityId: '',
        RegionId: '',
        AreaId: '',
      },
      excurtionPrice: [],
      openModalImage: false,
      image: {},
      imageUrl: '',
      excurtionCategory: [
        { key: '1', text: 'Single', value: 'Single' },
        { key: '2', text: 'Package', value: 'Package' },
      ],
      listStepImage: [
        { Tittle: 'Choose Image', Active: true },
        { Tittle: 'Select Thumbnail Image', Active: false },
        { Tittle: 'Edit Thumbnail Image', Active: false },
      ],
      indexActive: 0,
      openModalAccCity: false,
      accessCity: [],
      searchInput: '',
      activePage: 1,
      disablePage: true,
      contentButton: '',
      openModalConfirm: false,
      message: '',
      deletedCityId: null,
      confirmType: '',
      deletedImage: null,
      deletedPrice: null,
      deletedPriceId: null,
      activePageImage: 1,
      activePageModalCity: 1,
      //====================================
      excursionPriceTemporary: {
        ServicePriceType: '',
        MinimumPax: '',
        Cost: '',
        Description: '',
        CutOffDay: '',
        Currency: '',
      },
      errorsPrice: {},
      contentAlert: '',
      listCityByRegionForContactInfo: [],
      regionForAccessibleCity: '',
      loadingShowListCity: false,
    };
  }
  handleChangePrices = (value, i, name) => {
    const { excurtionPrice } = this.state;
    let valueConvert = moment(value).format('MM/DD/YYYY');
    if (name === 'StartDate') {
      if (new Date(valueConvert) < moment(new Date()).format('MM/DD/YYYY')) {
        excurtionPrice[i] = {
          ...excurtionPrice[i],
          [name]: value,
          StartDateError: true,
          onChange: true,
        };
      } else {
        value = moment(value).format('MM/DD/YYYY');
        excurtionPrice[i] = {
          ...excurtionPrice[i],
          [name]: value,
          StartDateError: false,
          onChange: true,
        };
      }
    } else if (name === 'EndDate') {
      let checkStartDate = excurtionPrice[i];
      if (
        new Date(valueConvert) <=
        moment(checkStartDate.StartDate).format('MM/DD/YYYY')
      ) {
        excurtionPrice[i] = {
          ...excurtionPrice[i],
          [name]: value,
          //EndDateError: 'Date must be in the future Start Date',
          EndDateError: true,
          onChange: true,
        };
      } else {
        value = moment(value).format('MM/DD/YYYY');
        excurtionPrice[i] = {
          ...excurtionPrice[i],
          [name]: value,
          EndDateError: false,
          onChange: true,
        };
      }
    } else {
      excurtionPrice[i] = {
        ...excurtionPrice[i],
        [name]: value,
        onChange: true,
      };
    }
    this.setState({
      excurtionPrice: excurtionPrice,
    });
  };
  handleChangePrice = (e, { value, name }) => {
    let index = name.split(',')[1];
    let newName = name.split(',')[0];
    let price = this.state.excurtionPrice;
    // if (newName === 'Type' || newName === 'Currency') {
    //   let newValue = {
    //     text: e.target.textContent,
    //     value: value,
    //   };
    //   price[index] = { ...price[index], [newName]: newValue };
    // } else {
    //   price[index] = { ...price[index], [newName]: value };
    // }
    price[index] = { ...price[index], [newName]: value, onChange: true };

    this.setState({
      excurtionPrice: price,
    });
  };
  onChangeDropdown = (e, { value, name }) => {
    const addressObject = this.state.addressObject;
    addressObject[name] = value;
    this.setState({ addressObject: addressObject });
    const getRegion = name === 'CountryId';
    const getCity = name === 'RegionId';
    const getArea = name === 'CityId';
    if (getRegion) {
      this.props.getRegionListAction(value);
    } else if (getCity) {
      this.props.getCityListAction(value).then(() =>
        this.setState({
          ...this.state,
          listCityByRegionForContactInfo: this.props.listCity,
        })
      );
    } else if (getArea) {
      this.props.getAreaListAction(value);
    }
    this.validate();
  };
  onChange = (e, { value, name }) => {
    let excursion = this.state.excursion;
    excursion = { ...excursion, [name]: value, onChange: true };
    this.setState({ excursion: excursion });
    this.validate();
  };
  handleClose = () => {
    this.setState({
      failedExcursion: false,
      modalConfirmation: false,
      openModalImage: false,
    });
  };
  setEndTime = (contex, value, name) => {
    let excurtion = this.state.excursion;
    excurtion[name] = value;
    this.setState({ excursion: excurtion });
  };
  setStartTime = (contex, value, name) => {
    let excurtion = this.state.excursion;
    excurtion[name] = value;
    this.setState({ excursion: excurtion });
  };
  setMinimumHour = (contex, value, name) => {
    let excursion = this.state.excursion;
    excursion[name] = value === '00:00' ? '00:05' : value;
    this.setState({ excursion: excursion });
  };
  componentDidMount = () => {
    const { Id, Type } = this.props.match.params;
    this.props.getCountryListAction();
    this.props.getExcursionTypeAction();
    this.props.getExcursionPriceTypeAction();
    this.props.getCurrenciesAction();
    this.props.getServicePriceTypeAction('Attraction');
    this.props.getRegionAction();
    if (Type === 'Detail') {
      this.handleSetHeader(Id, 'Detail Excursion', 'Edit', false);
    } else if (Type === 'Edit') {
      this.handleSetHeader(Id, 'Edit Excursion', 'Save', false);
    } else {
      this.handleSetHeader('', 'Add Excursion', 'Save', false);
    }
    if (Id) {
      this.props.getExcursionAction(Id);
      this.props.getServicePriceAction(Id);
    }
  };
  handleSetHeader = (id, title, content, disable) => {
    this.setState({
      idUrl: id,
      titleHeader: title,
      contentButton: content,
      disablePage: disable,
    });
  };
  componentDidUpdate = () => {
    if (this.props.getExcursionStatus === 'success') {
      let {
        AddressObject,
        PhoneNumber,
        EmailAddress,
        Cities,
      } = this.props.excurtion;
      const { City, Region, Country } = AddressObject;
      let addressObject = setAddressObject(
        AddressObject,
        EmailAddress,
        PhoneNumber
      );
      Country && this.props.getRegionListAction(Country.Id);
      Region && this.props.getCityListAction(Region.Id);
      City &&
        this.props.getAreaListAction(City.Id).then(() =>
          this.setState({
            ...this.state,
            listCityByRegionForContactInfo: this.props.listCity,
          })
        );
      let excursion = setExcursionObject(this.props.excurtion);
      this.setState({
        addressObject: addressObject,
        loadingExcursion: false,
        accessCity: Cities,
        excursion: excursion,
      });
      this.props.resetExcursionAction();
      this.props.resetExcursionPageAction();
    }
    if (this.props.putExcurtionStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Saving Excursion Successfully',
        modalConfirmation: true,
        disablePage: true,
        contentButton: 'Edit',
      });
      const { Id } = this.props.match.params;
      this.saveExcurtionPrice(Id);
      this.resetData(Id);
    }
    if (this.props.putExcurtionStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorMessage,
        modalConfirmation: true,
        disablePage: true,
        contentButton: 'Edit',
      });
      this.props.resetExcursionAction();
      this.props.resetExcursionPageAction();
      // const { Id } = this.props.match.params;
      // this.resetData(Id);
    }
    if (this.props.postExcurtionStatus === 'success') {
      // const { Id } = this.props.match.params;
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Saving Excursion Successfully',
        modalConfirmation: true,
        disablePage: true,
        contentButton: 'Edit',
      });
      this.props.resetExcursionAction();
      this.props.resetExcursionPageAction();
      //eslint-disable-next-line
      this.props.history.push(
        `/Excursion-Profile/Edit/${this.props.postExcurtion.ServiceItemId}`
      );
      this.props.getExcursionAction(this.props.postExcurtion.ServiceItemId);
      this.props.getServicePriceAction(this.props.postExcurtion.ServiceItemId);
      // this.saveExcurtionPrice(Id);
    }
    if (this.props.deleteSupplierPrice === 'success') {
      this.props.resetServicePriceAction();
    }
    if (this.props.getSupplierPriceStatus === 'success') {
      const priceList = this.props.supplierPriceList;
      let dataPrice = [];
      priceList.map(price => {
        let data = setPriceListData(price);
        return dataPrice.push(data);
      });
      this.setState({ excurtionPrice: dataPrice });
      this.props.resetServicePriceAction();
    }
    if (this.props.getExursionPriceTemplateStatus === 'success') {
      this.handleDownload(
        this.props.getExursionPriceTemplate,
        'Excursion Item Template.txt'
      );
      this.props.resetExcursionAction();
      // this.props.resetExcursionPageAction();
    } else if (this.props.getExursionPriceTemplateStatus === 'failed') {
      this.props.resetExcursionAction();
      this.props.resetExcursionPageAction();
    }
    if (this.props.getExursionPriceExportStatus) {
      this.handleDownload(
        this.props.getExursionPriceExport,
        'Excursion Item Export.txt'
      );
      this.props.resetExcursionAction();
      this.props.resetExcursionPageAction();
    } else if (this.props.getExursionPriceExportStatus) {
      this.props.resetExcursionAction();
      this.props.resetExcursionPageAction();
    }
  };
  resetData = id => {
    this.props.getCountryListAction();
    this.props.getExcursionTypeAction();
    this.props.getExcursionPriceTypeAction();
    this.props.getCurrenciesAction();
    this.props.getServicePriceTypeAction('Attraction');
    this.props.getExcursionAction(id);
    this.props.getServicePriceAction(id);
    this.props.resetExcursionAction();
    this.props.resetExcursionPageAction();
  };
  handleDownload = (exportFile, name) => {
    var elementDownload = document.createElement('a');
    var fileData = new Blob([exportFile], {
      type: 'text/plain',
    });
    elementDownload.href = URL.createObjectURL(fileData);
    elementDownload.download = name;
    elementDownload.click();
  };
  renderRedirect = () => {
    if (this.state.redirect) {
      return (
        <Redirect to={'/Excursion-Profile/Edit/' + this.state.ServiceItemId} />
      );
    }
  };
  setSuccessPost = id => {
    this.setState({ ServiceItemId: id });
    this.setState({ redirect: true });
  };
  handleChangeRadio = (e, { name, value }) => {
    let excursions = this.state.excursion;
    excursions = { ...excursions, [name]: value, onChange: true };
    this.setState({
      excursion: excursions,
    });
  };
  deletePrice = (e, { name, value }) => {
    this.setState({
      disablePage: false,
      openModalConfirm: true,
      message: `Are you sure want to delete price ?`,
      confirmType: 'DELETEPRICE',
      deletedPrice: name,
      deletedPriceId: value,
    });
  };
  handleDeletePrice = () => {
    let { excurtionPrice, deletedPrice, deletedPriceId } = this.state;
    excurtionPrice.splice(deletedPrice, 1);
    this.setState({ excurtionPrice: excurtionPrice, openModalConfirm: false });
    if (deletedPriceId > 0) {
      this.props.deleteServicePriceAction(deletedPriceId);
    }
  };
  addPrice = () => {
    const { ServiceItemId } = this.state.excursion;
    let data = {
      Id: 0,
      Type: '',
      Description: '',
      MinimumPax: '0',
      ServiceItemId: ServiceItemId,
      Currency: '',
      Cost: '0',
      StartDate: moment(),
      EndDate: moment(),
      CutOffDay: '0',
      onChange: true,
      StartDateError: '',
      EndDateError: '',
    };
    let excurtionPrice = this.state.excurtionPrice;
    excurtionPrice.push(data);
    this.setState({ excurtionPrice: excurtionPrice });
  };
  saveExcurtionPrice = ServiceItemId => {
    let excursionPrice = this.state.excurtionPrice;
    if (excursionPrice) {
      let price = {};
      excursionPrice.map(data => {
        price = setPriceToPost(data, ServiceItemId);
        const { Id, onChange } = data;
        if (onChange) {
          if (Id > 0) {
            data = this.props.putServicePriceAction('Attraction', Id, price);
          } else {
            data = this.props
              .postServicePriceAction('Attraction', price)
              .then(() => {
                this.props.getServicePriceAction(ServiceItemId);
              });
          }
        }
        return data;
      });
    }
  };

  saveExcurtion = (e, { name }) => {
    const { excursion } = this.state;
    if (!this.validate()) {
      if (name === 'Edit') {
        this.setState({
          titleHeader: 'Edit Excursion',
          contentButton: 'Save',
          disablePage: false,
        });
      } else if (
        excursion.OptimumDuration === 0 ||
        excursion.OptimumDuration === null ||
        excursion.OptimumDuration === '00:00'
      ) {
        this.setState({
          contentAlert: 'Minimum Hour cannot be set to 00:00',
          modalConfirmation: true,
          headerAlert: 'Failed',
        });
      } else {
        this.setState({
          titleHeader: 'Detail Excursion',
          contentButton: 'Save',
          disablePage: false,
          openModalConfirm: true,
          message: 'Are you sure want to save the excursion data?',
          confirmType: 'SAVE',
        });
      }
    }
  };
  handleSave = () => {
    this.setState({ openModalConfirm: false });
    const { excursion } = this.state;
    let data = setDataToPost(this.state);
    if (!this.validate()) {
      return excursion.ServiceItemId
        ? this.handlePutAndSetPrimaryAction(excursion.ServiceItemId, data)
        : this.props.postExcursionAction(data).then(res => {
            this.saveExcurtionPrice(res.value.data.ServiceItemId);
          });
    }
  };

  handlePutAndSetPrimaryAction = (serviceItemId, data) => {
    this.props.putExcursionAction(serviceItemId, data);
    data.AttractionObjectImages.map(object => {
      return (
        object.IsPrimaryImage &&
        this.props.postSetPrimaryExcursionObjectAction(object.ImageId)
      );
    });
  };
  validate = () => {
    let isError = false;
    const { addressObject, excursion } = this.state;
    let error1 = handleValidation(addressObject, contactAddressField);
    let error2 = handleValidation(excursion, excursionGeneralInfoField);
    let errors = { ...error1, ...error2 };
    isError = !_.isEmpty(errors);
    this.setState({
      errors: {
        ...errors,
      },
    });
    return isError;
  };
  openModal = (e, { id }) => {
    if (id === 'image') {
      this.setState({
        contentModal: 'Image',
        openModalImage: true,
        indexActive: 0,
      });
    }
  };
  addImage = () => {
    let objectImage = this.state.excursion;
    let image = this.state.image;
    objectImage.ObjectImages.push(image);
    let listStepImage = this.state.listStepImage;
    listStepImage.map((step, i) => {
      step['Active'] = i === 0 ? true : false;
      return listStepImage;
    });
    this.setState({
      excursion: objectImage,
      openModalImage: false,
      listStepImage: listStepImage,
      indexActive: 0,
    });
  };
  handleDeleteImage = value => {
    let objectImage = this.state.excursion;

    // let imageName = this.state.deletedImage;
    objectImage.ObjectImages = objectImage.ObjectImages.filter(
      (item, i) => i !== value
    );
    this.setState({
      excursion: objectImage,
      openModalConfirm: false,
      activePageImage: 1,
    });
  };
  deleteImage = (e, { name }) => {
    let objectImage = this.state.excursion;
    let image = objectImage.ObjectImages.find((item, i) => name === i);
    this.setState({
      disablePage: false,
      openModalConfirm: true,
      message: `Are you sure want to delete ${image.ImageName} ?`,
      confirmType: 'DELETEIMAGE',
      deletedImage: name,
    });
  };
  changeImage = e => {
    let imageObject = this.state.excursion;
    //eslint-disable-next-line
    e.map(base => {
      let image = {
        Id: 0,
        ImageContent: base.base64.split(',')[1],
        ImagePreview: base.base64,
        ImageName: base.file.name,
        IsPrimaryImage: false,
      };
      return imageObject.ObjectImages.push(image);
    });
    this.setState({
      excursion: imageObject,
    });
  };
  openDetail = () => {
    let listStepImage = this.state.listStepImage;
    listStepImage.map((step, i) => {
      step['Active'] = i === 0 ? true : false;
      return listStepImage;
    });
    this.setState({
      contentModal: 'Image',
      openModalImage: true,
      indexActive: 0,
      listStepImage: listStepImage,
    });
  };
  handleChangePrimaryImage = (e, { value }) => {
    let listImage = this.state.excursion;
    let newList = [];
    listImage.ObjectImages.map((img, i) => {
      img['IsPrimaryImage'] = i === value ? true : false;
      return newList.push(img);
    });
    this.setState({
      excursion: { ...this.state.excursion, ObjectImages: newList },
    });
  };
  handleNext = () => {
    let indexActive = this.state.indexActive;
    indexActive = indexActive + 1;
    let listStepImage = this.state.listStepImage;
    listStepImage.map((step, i) => {
      step['Active'] = i === indexActive ? true : false;
      return listStepImage;
    });
    if (indexActive === 2) {
      let listImage = this.state.excursion;
      listImage.ObjectImages.map(img => {
        return img.IsPrimaryImage && this.setState({ image: img });
      });
    }
    if (indexActive === 3) {
      return this.setState({ indexActive: 0, openModalImage: false });
    }
    this.setState({ listStepImage: listStepImage, indexActive: indexActive });
  };
  handleBackStep = () => {
    let indexActive = this.state.indexActive;
    indexActive = indexActive - 1;
    let listStepImage = this.state.listStepImage;
    listStepImage.map((step, i) => {
      step['Active'] = i === indexActive ? true : false;
      return listStepImage;
    });
    this.setState({ listStepImage: listStepImage, indexActive: indexActive });
  };
  handleCloseAccCity = () => {
    this.setState({ openModalAccCity: false });
  };
  onClickModalAccCity = () => {
    const { RegionId } = this.state.addressObject;
    let errors = {
      RegionId: '',
    };
    if (!RegionId) {
      errors.RegionId = 'Please Select Region';
      this.setState({
        errors: {
          ...errors,
        },
      });
    } else {
      this.setState({ loadingShowListCity: true }, () => {
        this.props
          .getCityListAction(RegionId)
          .then(() => this.showListAccessibleCityOnModal(RegionId));
      });
    }
  };
  handleChangeAccessCity = (e, { name, checked }) => {
    let list = this.state.listAccessCity;
    list.map(city => {
      if (city.Id === name) {
        city['Status'] = checked;
        city['onChange'] = true;
        return list;
      }
      return list;
    });
    this.setState({ listAccessCity: list });
  };
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  pageChangeImage = (e, { activePage }) => {
    this.setState({ activePageImage: activePage });
  };
  pageChangeModalCity = (e, { activePage }) => {
    this.setState({ activePageModalCity: activePage });
  };
  addAccessCity = () => {
    let { listAccessCity, accessCity, regionForAccessibleCity } = this.state;
    //accessibleCityDiffRegion ==> list city yang beda region dengan region yang dipilih
    let accessibleCityDiffRegion = accessCity.filter(
      e => e.Region.Id !== regionForAccessibleCity
    );
    //updateAccessibleCity ===> list city yang sama dengan region yang dipilih. lakukan update di city ini.
    listAccessCity.map(city => {
      if (city.Status === true) {
        accessibleCityDiffRegion.push({
          ...city,
          Region: city.CompleteData.Region,
        });
      }
      return city;
    });
    this.setState({
      accessCity: accessibleCityDiffRegion,
      openModalAccCity: false,
    });
    // let listCity = this.state.listAccessCity;
    // let newListCity = [...this.state.accessCity];
    // let
    // listCity.map(city => {
    //   if (
    //     city.Status === true &&
    //     newListCity.filter(e => e.Id === city.Id).length === 0
    //   ) {
    //     newListCity.push({ ...city });
    //   }
    //   return newListCity;
    // });
    // this.setState({ accessCity: newListCity, openModalAccCity: false });
  };
  deleteAccessCity = (e, { id }) => {
    let city = this.state.accessCity.find(item => item.Id === id);
    this.setState({
      disablePage: false,
      openModalConfirm: true,
      message: `Are you sure want to delete ${city.Name} city?`,
      confirmType: 'DELETECITY',
      deletedCityId: city.Id,
      activePage: 1,
    });
  };
  deleteCity = () => {
    let value = this.state.deletedCityId;
    let listCity = this.state.accessCity.filter(item => item.Id !== value);
    // listCity.splice(name, 1);
    let listCity1 = this.state.listAccessCity;
    listCity1.map(city => {
      if (city.Id === value) {
        city['Status'] = false;
      }
      return listCity1;
    });
    this.setState({
      openModalConfirm: false,
      accessCity: listCity,
      listCity: listCity1,
    });
  };
  buttonBack = () => {
    // eslint-disable-next-line
    this.props.history.push(`/Excursion`);
  };

  handleSearchAccesibleCity = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  ButtonUploadDataExcursionItem = () => {
    // eslint-disable-next-line
    this.props.history.push(`/Upload-Master-Data/${'ExcursionPrice'}`);
  };
  ButtonDownloadTemplate = () => {
    this.props.getTemplateExcursionPriceAction();
  };
  ButtonExportExcursion = () => {
    const { Id } = this.props.match.params;
    this.props.getExportDataItemByIdAction('AttractionObjects', Id);
  };
  openModalConfirm = message => {
    this.setState({ openModalConfirm: true, message: message });
  };
  closeModalConfirm = () => {
    this.setState({ openModalConfirm: false });
  };
  handleConfirm = () => {
    let type = this.state.confirmType;
    if (type === 'SAVE') {
      this.handleSave();
    } else if (type === 'DELETECITY') {
      this.deleteCity();
    } else if (type === 'DELETEIMAGE') {
      this.handleDeleteImage();
    } else if (type === 'DELETEPRICE') {
      this.handleDeletePrice();
    }
  };

  //===========================================================
  openModalPriceDetailFunc = () => {
    const { ServiceItemId } = this.state.excursion;
    let data = {
      Id: '0',
      Type: '',
      ServicePriceType: '',
      Description: '',
      MinimumPax: '0',
      ServiceItemId: ServiceItemId,
      Currency: '',
      Cost: '0',
      StartDate: moment(new Date()).format('MM/DD/YYYY'),
      EndDate: moment(new Date()).format('MM/DD/YYYY'),
      CutOffDay: '0',
      onChange: true,
      StartDateError: '',
      EndDateError: '',
    };
    this.setState({
      openModalPriceDetail: true,
      excursionPriceTemporary: data,
      editPrice: false,
    });
  };

  handlCloseModalPriceDetail = () => {
    this.setState({ openModalPriceDetail: false });
  };
  onChangeModalPriceDetails = async (e, { value, name }) => {
    const { excursionPriceTemporary } = this.state;
    let excursionPrice = { ...excursionPriceTemporary };
    excursionPrice[name] = value;
    excursionPrice['onChange'] = true;
    await this.setState({
      excursionPriceTemporary: excursionPrice,
    });
    this.validatePrice();
  };

  handleChangePriceDate = (value, name) => {
    const { excursionPriceTemporary, errors } = this.state;
    let excursionPrice = excursionPriceTemporary;
    let valueConvert = moment(value).format('MM/DD/YYYY');
    // let today = moment(new Date()).format('MM/DD/YYYY');
    let startDate = moment(excursionPrice.StartDate).format('MM/DD/YYYY');
    if (name === 'StartDate') {
      excursionPrice[name] = moment(value).format('MM/DD/YYYY');
      excursionPrice['onChange'] = true;
    } else if (name === 'EndDate') {
      if (moment(valueConvert).isSameOrBefore(startDate)) {
        this.setState({
          errors: {
            ...errors,
            EndDate: 'Date must be in the future Start Date',
          },
        });
      } else {
        excursionPrice[name] = moment(value).format('MM/DD/YYYY');
        excursionPrice['onChange'] = true;
        this.setState({
          errors: {
            ...errors,
            EndDate: '',
          },
        });
      }

      excursionPrice[name] = moment(value).format('MM/DD/YYYY');
      excursionPrice['onChange'] = true;
    } else {
      excursionPrice[name] = value;
      excursionPrice['onChange'] = true;
    }
    this.setState({
      excursionPriceTemporary: excursionPrice,
    });
  };

  messageErrorPriceDetails = () => {
    this.setState({
      contentAlert: 'Please fill in the price list',
      modalConfirmation: true,
      headerAlert: 'Information',
    });
  };

  validatePrice = () => {
    let isError = false;
    const errors = handleValidation(
      this.state.excursionPriceTemporary,
      priceFieldToValidate
    );
    isError = !_.isEmpty(errors);
    this.setState({
      errorsPrice: {
        ...errors,
      },
    });
    return isError;
  };

  savePriceDetail = () => {
    const {
      indexEditPrice,
      editPrice,
      excursionPriceTemporary,
      excurtionPrice,
    } = this.state;
    let temporaryPriceDetail = excursionPriceTemporary;
    let priceDetails = excurtionPrice;
    const err = this.validatePrice();
    let valid = false;
    let validPrice = false;
    if (!err) {
      excurtionPrice.map(data => {
        let StartDate = moment(data.StartDate).format('MM/DD/YYYY');
        let EndDate = moment(data.EndDate).format('MM/DD/YYYY');
        let Cost = data.Cost.toString();
        let MinimumPax = data.MinimumPax.toString();
        if (
          Cost === temporaryPriceDetail.Cost &&
          StartDate === temporaryPriceDetail.StartDate &&
          EndDate === temporaryPriceDetail.EndDate &&
          data.ServicePriceType === temporaryPriceDetail.ServicePriceType &&
          MinimumPax === temporaryPriceDetail.MinimumPax
        ) {
          valid = true;
        }
        return data;
      });
      if (temporaryPriceDetail.Cost === '0') {
        validPrice = true;
      } else if (temporaryPriceDetail.MinimumPax === '0') {
        validPrice = true;
      }

      if (!valid || editPrice) {
        if (validPrice) {
          this.setState({
            contentAlert: 'the price you enter cannot be 0',
            modalConfirmation: true,
            headerAlert: 'Information',
          });
        } else {
          if (editPrice) {
            priceDetails[indexEditPrice] = temporaryPriceDetail;
          } else {
            priceDetails.push(temporaryPriceDetail);
          }
          this.setState({
            excursionPrice: priceDetails,
            openModalPriceDetail: false,
          });
        }
      } else {
        this.setState({
          contentAlert:
            'The price with the service type and date you fill in already exists',
          modalConfirmation: true,
          headerAlert: 'Information',
        });
      }
    }
  };

  handleEditPriceDetail = (e, { name, value }) => {
    let unitDetails = {};
    const { excurtionPrice } = this.state;
    if (value !== 0) {
      unitDetails = excurtionPrice.find(data => data.Id === value);
    } else {
      unitDetails = excurtionPrice[name];
    }
    this.setState({
      excursionPriceTemporary: unitDetails,
      openModalPriceDetail: true,
      editPrice: true,
      indexEditPrice: name,
    });
  };
  // Handle accessible city in region
  handleChangeRegionAccessibleCity = (e, { value }) => {
    this.setState({
      ...this.state,
      regionForAccessibleCity: value,
    });
    this.props
      .getCityListAction(value)
      .then(() => this.showListAccessibleCityOnModal(value));
  };
  showListAccessibleCityOnModal = regionId => {
    let listCity = this.props.listAccessCity;
    let cityAccess = this.state.accessCity;
    listCity.map(city => {
      cityAccess.map(acce => {
        if (city.Id === acce.Id) {
          city['Status'] = true;
          return listCity;
        }
        return listCity;
      });
      return listCity;
    });
    this.setState({
      openModalAccCity: true,
      listAccessCity: listCity,
      regionForAccessibleCity: regionId,
      loadingShowListCity: false,
    });
  };
  // Handle accessible city in region
  render() {
    let loading = this.props.loading;
    let { listAllRegions } = this.props;
    const {
      addressObject,
      excursion,
      excurtionPrice,
      openModalImage,
      errors,
      excurtionCategory,
      imageUrl,
      indexActive,
      image,
      listStepImage,
      openModalAccCity,
      listAccessCity,
      accessCity,
      searchInput,
      activePage,
      modalConfirmation,
      headerAlert,
      contentAlert,
      disablePage,
      contentButton,
      openModalConfirm,
      message,
      activePageImage,
      activePageModalCity,
      openModalPriceDetail,
    } = this.state;
    let { ObjectImages } = excursion;
    let filterData = FilterData(listAccessCity, 'Name', searchInput);
    let pageDataCity = datasetPagination(filterData, 7);
    let listDataCity = pageDataCity[activePageModalCity - 1];
    let filterDataAccessCity = FilterData(accessCity, 'Name', searchInput);
    let pagedDataAccessCity = datasetPagination(filterDataAccessCity, 3);
    let listDataAccessCity = pagedDataAccessCity[activePage - 1];
    let filterDataImage =
      ObjectImages && ObjectImages.length > 0
        ? FilterData(ObjectImages, 'Image', '')
        : [];
    // let pagedData = datasetPagination(filterDataImage, 3);
    // let listDataImage = pagedData[activePageImage - 1];
    return (
      <div>
        <Dimmer.Dimmable blurring dimmed={loading}>
          <Loader loading={loading} />
          {this.renderRedirect()}
          <ExcrustionProfile
            filterDataAccessCity={filterDataAccessCity}
            filterDataImage={filterDataImage}
            contactObject={addressObject}
            listCountry={this.props.listCountry}
            listRegion={this.props.listRegion}
            listCityByRegionForContactInfo={
              this.state.listCityByRegionForContactInfo
            }
            listArea={this.props.listArea}
            onChangeDropdown={this.onChangeDropdown}
            handleChange={this.onChange}
            excursionTypeList={this.props.excursionTypeList}
            excurtionCategory={excurtionCategory}
            handleChangeRadio={this.handleChangeRadio}
            excursion={excursion}
            saveExcurtion={this.saveExcurtion}
            excurtionPrice={excurtionPrice}
            // addPrice={this.addPrice}
            addPrice={this.openModalPriceDetailFunc}
            // servicePriceType={this.props.servicePriceType}
            // currencies={this.props.currencies}
            handleChangePrice={this.handleChangePrice}
            handleChangePrices={this.handleChangePrices}
            deletePrice={this.deletePrice}
            errors={errors}
            setEndTime={this.setEndTime}
            setStartTime={this.setStartTime}
            setMinimumHour={this.setMinimumHour}
            openModal={this.openModal}
            openModalImage={openModalImage}
            handleClose={this.handleClose}
            imagePreview={imageUrl}
            changeImage={this.changeImage}
            addImage={this.addImage}
            openDetail={this.openDetail}
            listStepImage={listStepImage}
            handleNext={this.handleNext}
            handleBackStep={this.handleBackStep}
            indexActive={indexActive}
            image={image}
            handleChangePrimaryImage={this.handleChangePrimaryImage}
            openModalAccCity={openModalAccCity}
            handleCloseAccCity={this.handleCloseAccCity}
            onClickModalAccCity={this.onClickModalAccCity}
            listAccessCity={listDataCity}
            handleChangeAccessCity={this.handleChangeAccessCity}
            // accessCity={accessCity}
            pageChange={this.pageChange}
            activePage={activePage}
            pageDataCity={pageDataCity}
            addAccessCity={this.addAccessCity}
            deleteAccessCity={this.deleteAccessCity}
            title={this.state.titleHeader}
            deleteImage={this.handleDeleteImage}
            backPage={this.buttonBack}
            disablePage={disablePage}
            contentButton={contentButton}
            handleSearchAccesibleCity={this.handleSearchAccesibleCity}
            searchInput={searchInput}
            buttonUploadData={this.ButtonUploadDataExcursionItem}
            butttonExport={this.ButtonExportExcursion}
            buttonDownloadTemplate={this.ButtonDownloadTemplate}
            listDataAccessCity={listDataAccessCity}
            pagedDataAccessCity={pagedDataAccessCity}
            listDataImage={filterDataImage}
            // pagedData={pagedData}
            activePageImage={activePageImage}
            activePageModalCity={activePageModalCity}
            pageChangeImage={this.pageChangeImage}
            pageChangeModalCity={this.pageChangeModalCity}
            handleEdit={this.handleEditPriceDetail}
            listAllRegions={listAllRegions}
            handleChangeRegionAccessibleCity={
              this.handleChangeRegionAccessibleCity
            }
            listCityByRegionForAccessibleCity={this.props.listCity}
            regionForAccessibleCity={this.state.regionForAccessibleCity}
            loadingGetCityByRegion={this.props.loadingGetCityByRegion}
            loadingShowListCity={this.state.loadingShowListCity}
          />
          <ModalAlert
            openModal={modalConfirmation}
            handleClose={this.handleClose}
            header={headerAlert}
            content={contentAlert}
            nameButton="OK"
            isArrayContentAlert={typeof contentAlert !== 'string'}
          />
          <ModalConfirm
            close={this.closeModalConfirm}
            openModal={openModalConfirm}
            size="mini"
            confirm={this.handleConfirm}
            message={message}
            header="Confirmation"
          />
          <ModalExcursionPrice
            openModalPrice={openModalPriceDetail}
            handleClose={this.handlCloseModalPriceDetail}
            header="Add New Price"
            servicePriceType={this.props.servicePriceType}
            currenciesList={this.props.currencies}
            data={this.state.excursionPriceTemporary}
            type={'excursion'}
            headerTransportationUnit={excursion.Name}
            handleChangePrice={this.onChangeModalPriceDetails}
            handleChangePriceDate={this.handleChangePriceDate}
            savePriceDetail={this.savePriceDetail}
            errors={this.state.errorsPrice}
            errorsDate={this.state.errors}
            // countryName={countryName}
            // transportationOriginCity={this.state.transportationPrice.OriginCity}
            // transportationOriginRegion={
            //   this.state.transportationPrice.OriginRegion
            //}
          />
        </Dimmer.Dimmable>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    listCountry: state.CountryStore.getCountryList.map((type, i) => {
      return { key: i, text: type.Name, value: type.Id };
    }),
    listRegion: state.RegionStore.getRegionList.map((type, i) => {
      return { key: i, text: type.Name, value: type.Id };
    }),
    listAllRegions: state.RegionStore.getRegion.map((region, i) => {
      return {
        key: i,
        text: region.Name,
        value: region.Id,
        completeData: region,
      };
    }),
    listCity: state.CityStore.getCityList.map((type, i) => {
      return { key: i, text: type.Name, value: type.Id, completeData: type };
    }),
    listAccessCity: state.CityStore.getCityList.map(city => {
      return {
        Id: city.Id,
        Name: city.Name,
        Status: false,
        CompleteData: city,
      };
    }),
    listArea: state.AreaStore.getAreaList.map((type, i) => {
      return { key: i, text: type.Name, value: type.Id };
    }),
    excursionTypeList: state.ExcursionStore.excursionTypeList.map((type, i) => {
      return { key: i, text: type.Name, value: type.Id };
    }),
    getExcursionStatus: state.ExcursionStore.getExcursionStatus,
    excurtion: state.ExcursionStore.excurtion,
    servicePriceType: state.SupplierPriceStore.servicePriceType.map(type => {
      return { text: type.replace('_', ' '), value: type };
    }),
    postExcurtionStatus: state.ExcursionPageStore.postExcurtionStatus,
    postExcurtion: state.ExcursionPageStore.postExcurtion,
    loading: state.ExcursionStore.loading
      ? state.ExcursionStore.loading
      : state.ExcursionPageStore.loading,
    currencies: state.currenciesStore.currencies.map((cur, i) => {
      return { key: i, text: cur.Id, value: cur.Id };
    }),
    putExcurtionStatus: state.ExcursionStore.putExcurtionStatus,
    supplierPriceList: state.SupplierPriceStore.supplierPriceList,
    getSupplierPriceStatus: state.SupplierPriceStore.getSupplierPriceStatus,
    getExursionPriceTemplateStatus:
      state.ExcursionStore.getExursionPriceTemplateStatus,
    getExursionPriceTemplate: state.ExcursionStore.getExursionPriceTemplate,
    getExursionPriceExportStatus:
      state.ExcursionStore.getExportDataItemByIdStatus,
    getExursionPriceExport: state.ExcursionStore.getExportDataItemById,
    errorMessage: state.ExcursionStore.messageErrors
      ? state.ExcursionStore.messageErrors
      : state.ExcursionPageStore.errors.global,
    loadingGetCityByRegion: state.CityStore.loading,
  };
}
ExcursionProfile.propTypes = {
  getExcursionTypeAction: PropTypes.func,
  getCountryListAction: PropTypes.func,
  getRegionListAction: PropTypes.func,
  getCityListAction: PropTypes.func,
  getAreaListAction: PropTypes.func,
  listCountry: PropTypes.array,
  listRegion: PropTypes.array,
  listCity: PropTypes.array,
  listArea: PropTypes.array,
  excursionTypeList: PropTypes.array,
  match: PropTypes.object,
  getExcursionStatus: PropTypes.string,
  resetExcursionAction: PropTypes.func,
  resetExcursionPageAction: PropTypes.func,
  excurtion: PropTypes.object,
  postExcursionAction: PropTypes.func,
  putExcursionAction: PropTypes.func,
  addPrice: PropTypes.func,
  excurtionPrice: PropTypes.array,
  getExcursionPriceTypeAction: PropTypes.func,
  servicePriceType: PropTypes.array,
  getCurrenciesAction: PropTypes.func,
  currencies: PropTypes.array,
  getExcursionAction: PropTypes.func,
  postServicePriceAction: PropTypes.func,
  putServicePriceAction: PropTypes.func,
  putExcurtionStatus: PropTypes.string,
  getServicePriceAction: PropTypes.func,
  getSupplierPriceStatus: PropTypes.string,
  supplierPriceList: PropTypes.array,
  resetServicePriceAction: PropTypes.func,
  deleteServicePriceAction: PropTypes.func,
  postExcurtionStatus: PropTypes.string,
  deleteSupplierPrice: PropTypes.string,
  getServicePriceTypeAction: PropTypes.func,
  getRegionAction: PropTypes.func,
  loading: PropTypes.bool,
  postExcurtion: PropTypes.object,
  listAccessCity: PropTypes.array,
  getTemplateExcursionObjectAction: PropTypes.func,
  getExportDataItemByIdAction: PropTypes.func,
  getTemplateExcursionPriceAction: PropTypes.func,
  getExursionPriceTemplateStatus: PropTypes.string,
  getExursionPriceTemplate: PropTypes.string,
  getExursionPriceExportStatus: PropTypes.string,
  getExursionPriceExport: PropTypes.string,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  postSetPrimaryExcursionObjectAction: PropTypes.func,
  listAllRegions: PropTypes.array,
  loadingGetCityByRegion: PropTypes.bool,
};
export default connect(mapStateToProps, {
  getExcursionListAction,
  resetExcursionAction,
  resetExcursionPageAction,
  getAreaListAction,
  getCityListAction,
  getCountryListAction,
  getRegionListAction,
  getExcursionTypeAction,
  getExcursionAction,
  getServicePriceAction,
  postServicePriceAction,
  putServicePriceAction,
  postExcursionAction,
  putExcursionAction,
  getExcursionPriceTypeAction,
  getCurrenciesAction,
  resetServicePriceAction,
  deleteServicePriceAction,
  getServicePriceTypeAction,
  getRegionAction,
  getTemplateExcursionObjectAction,
  getExportDataItemByIdAction,
  getTemplateExcursionPriceAction,
  postSetPrimaryExcursionObjectAction,
})(ExcursionProfile);
