import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dimmer, Grid, Segment, Button, Menu } from 'semantic-ui-react';
import HeaderTitle from '../shared/components/common/headerSegment/HeaderTitle';
import GeneralInformation from './component/GeneralInformation';
import ChangePassword from './component/ChangePassword';
import SegmentImageUser from './component/SegmentImageUser';
import {
  getUserProfileAction,
  putUpdateProfileAction,
  postChangePassword,
  resetUserInfoAction,
} from '../../actions/userAction';
import { getCountryListAction } from '../../pages/shared/actions/countryAction';
import { userProfileValidation } from './services/validation/userProfileValidation';
import { changePasswordValidation } from './services/validation/changePasswordValidation';
import ModalAlert from '../../components/modal/modalAlert';
import style from './styles';
import LoaderModal from '../../components/modal/loaderModal';
import ContactInformation from './component/ContactInformation';

const FILL = 'Please fill ';
const CHOOSE = 'Please choose ';
const CHECK = 'Please check ';
class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userProfile: {},
      password: {
        OldPassword: '',
        NewPassword: '',
        ConfirmPassword: '',
      },
      errorUser: {
        UserName: { error: false, message: FILL + 'Username' },
        FirstName: { error: false, message: FILL + 'first name' },
        LastName: { error: false, message: FILL + 'last name' },
        PhoneNbr: { error: false, message: FILL + 'phone number' },
        Email: { error: false, message: CHECK + 'email' },
        Gender: { error: false, message: CHOOSE + 'gender' },
        Country: { error: false, message: CHOOSE + 'country' },
        isError: false,
      },
      errorPassword: {
        NewPassword: { error: false, message: CHECK + 'new password' },
        ConfirmPassword: { error: false, message: CHECK + 'confirm password' },
      },
      openModalAlert: false,
      contentAlert: '',
      activeItem: 'Account Detail',
      // typePassword: 'password',
      // passwordIcon: 'eye slash',
      typePassword: {
        OldPassword: 'password',
        NewPassword: 'password',
        ConfirmPassword: 'password',
      },
      passwordIcon: {
        OldPassword: 'eye slash',
        NewPassword: 'eye slash',
        ConfirmPassword: 'eye slash',
      },
      passwordShow: {
        OldPassword: false,
        NewPassword: false,
        ConfirmPassword: false,
      },
      disablePage: true,
      contentButton: 'Edit',
    };
  }

  loadData = () => {
    this.props.getUserProfileAction().then(() => {
      this.setState({ ...this.state, userProfile: this.props.getUserProfile });
    });
  };

  componentDidMount() {
    this.loadData();
    this.props.getCountryListAction();
  }

  handleChange = (e, { name, value }) => {
    let { userProfile, errorUser } = this.state;
    if (name === 'Country') {
      let Country = userProfile.Country;
      Country.Id = value;
      Country.Name = Country.Name ? Country.Name : e.target.textContent;
      userProfile[name] = Country;
    } else {
      userProfile[name] = value;
    }
    this.setState({ userProfile: userProfile });
    const errorMessage = userProfileValidation(userProfile, errorUser);
    this.setState({ errorUser: errorMessage });
  };

  handleChangePassword = (e, { name, value }) => {
    let { password, errorPassword } = this.state;
    password[name] = value;
    this.setState({ password: password });
    const err = changePasswordValidation(password, errorPassword);
    this.setState({ errorPassword: err });
  };

  handleUpdatePassword = () => {
    let { password } = this.state;
    if (password.NewPassword !== password.ConfirmPassword) {
      this.setState({
        openModalAlert: true,
        contentAlert: 'The new password and confirmation password do not match',
        headerAlert: 'Failed',
      });
    } else {
      this.props
        .postChangePassword(password)
        .then(() => {
          this.resetFormPassword();
          this.props.resetUserInfoAction();
        })
        .catch(err => {
          this.setState({
            openModalAlert: true,
            contentAlert: err.response.data.ModelState
              ? err.response.data.ModelState['model.NewPassword']
                ? err.response.data.ModelState['model.NewPassword']
                : err.response.data.ModelState['model.ConfirmPassword']
                  ? err.response.data.ModelState['model.ConfirmPassword']
                  : err.response.data.ModelState.message0[0]
              : err.response.data.Message
                ? err.response.data.Message
                : 'Failed update password',
            headerAlert: 'Failed',
          });
          this.props.resetUserInfoAction();
        });
    }
  };

  resetFormPassword = () => {
    this.setState({
      ...this.state,
      password: {
        OldPassword: '',
        NewPassword: '',
        ConfirmPassword: '',
      },
    });
    this.setState({
      openModalAlert: true,
      contentAlert: 'Success update password',
      headerAlert: 'Success',
    });
  };

  handleSaveAndEdit = e => {
    if (e.target.textContent === 'Edit') {
      this.setState({ disablePage: false, contentButton: 'Save' });
    } else {
      this.saveUser();
    }
  };

  saveUser = () => {
    let { userProfile, errorUser } = this.state;
    let userProfileObject = userProfile;
    userProfileObject.CountryId = userProfile.Country.Id;
    const errorMessage = userProfileValidation(userProfile, errorUser);
    const err = errorMessage.isError;
    if (!err) {
      this.props
        .putUpdateProfileAction(userProfile.Id, userProfileObject)
        .then(() => {
          this.setState({
            openModalAlert: true,
            contentAlert: 'Success update profile',
            headerAlert: 'Success',
            disablePage: true,
            contentButton: 'Edit',
          });
          this.props.resetUserInfoAction();
          this.loadData();
        })
        .catch(err => {
          this.setState({
            openModalAlert: true,
            // contentAlert: 'Failed update profile',
            contentAlert: err.response.data.ModelState
              ? err.response.data.ModelState['model.Address']
                ? err.response.data.ModelState['model.Address']
                : err.response.data.Message
              : err.response.data.Message
                ? err.response.data.Message
                : 'Failed update profile',
            headerAlert: 'Failed',
          });
          this.props.resetUserInfoAction();
        });
    } else {
      this.setState({ errorUser: errorMessage });
    }
  };

  closeModalAlert = () =>
    this.setState({
      openModalAlert: false,
      contentAlert: '',
    });

  handleChangeImage = e => {
    let user = this.state.userProfile;
    let reader = new FileReader();
    let files = e.target.files[0];
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      let image = {
        ...user,
        ImageName: files.name,
        ImageContent: reader.result.split(',')[1],
        ImagePreview: reader.result,
        ImageUrl: '',
      };
      this.setState({
        userProfile: image,
      });
    };
  };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  onClickPassIcon = (e, { name }) => {
    let typePass = this.state.typePassword;
    let passIcon = this.state.passwordIcon;
    let passShow = this.state.passwordShow;

    if (passShow[name] === false) {
      passShow[name] = true;
      typePass[name] = 'text';
      passIcon[name] = 'eye';
    } else {
      passShow[name] = false;
      typePass[name] = 'password';
      passIcon[name] = 'eye slash';
    }

    this.setState({
      typePassword: typePass,
      passwordIcon: passIcon,
      passwordShow: passShow,
    });
  };

  render() {
    let { getCountryList, loading } = this.props;
    let {
      userProfile,
      openModalAlert,
      contentAlert,
      headerAlert,
      password,
      errorUser,
      errorPassword,
      activeItem,
      passwordIcon,
      typePassword,
      disablePage,
      contentButton,
    } = this.state;
    return (
      <div>
        <Dimmer.Dimmable blurring dimmed={loading}>
          <LoaderModal loading={loading} />
          <Grid>
            <Grid.Column width={16}>
              <Segment style={style.marginTop3}>
                <HeaderTitle iconName="user" title={'My Account'} />
                <Grid>
                  <Grid.Row style={style.paddingLeft14}>
                    <Menu secondary>
                      <Menu.Item
                        name="Account Detail"
                        active={activeItem === 'Account Detail'}
                        onClick={this.handleItemClick}
                        style={
                          activeItem === 'Account Detail'
                            ? style.tabActive
                            : style.tabNonActive
                        }
                      >
                        Account Detail
                      </Menu.Item>
                      <Menu.Item
                        name="Change Password"
                        active={activeItem === 'Change Password'}
                        onClick={this.handleItemClick}
                        style={
                          activeItem === 'Change Password'
                            ? style.tabActive
                            : style.tabNonActive
                        }
                      >
                        Change Password
                      </Menu.Item>
                    </Menu>
                  </Grid.Row>
                </Grid>
                <Grid
                  style={
                    activeItem !== 'Account Detail' ? { display: 'none' } : {}
                  }
                >
                  <Grid.Row style={style.noPaddingTopBottom}>
                    <Grid.Column>
                      <Button
                        floated="right"
                        content={contentButton}
                        primary
                        onClick={this.handleSaveAndEdit}
                        style={style.buttonSaveEdit}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row centered>
                    <Grid centered columns={2} padded="vertically">
                      <Grid.Row centered>
                        <Grid.Column>
                          <GeneralInformation
                            userProfile={userProfile}
                            countryList={getCountryList}
                            handleChange={this.handleChange}
                            errorInformation={errorUser}
                            loading={loading}
                            disablePage={disablePage}
                          />
                          <ContactInformation
                            countryList={getCountryList}
                            userProfile={userProfile}
                            handleChange={this.handleChange}
                            errorInformation={errorUser}
                            disablePage={disablePage}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <SegmentImageUser
                            userProfile={userProfile}
                            handleChangeImage={this.handleChangeImage}
                            imagePreview={userProfile.ImagePreview}
                            disablePage={disablePage}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Row>
                </Grid>
                <Grid
                  centered
                  style={
                    activeItem !== 'Change Password' ? { display: 'none' } : {}
                  }
                >
                  <Grid.Row centered>
                    <Grid.Column>
                      <ChangePassword
                        password={password}
                        handleChangePassword={this.handleChangePassword}
                        handleUpdatePassword={this.handleUpdatePassword}
                        errorInformation={errorPassword}
                        typePassword={typePassword}
                        onClickPassIcon={this.onClickPassIcon}
                        passwordIcon={passwordIcon}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Column>
          </Grid>
          <ModalAlert
            openModal={openModalAlert}
            handleClose={this.closeModalAlert}
            header={headerAlert}
            content={contentAlert}
            //   nameButton: PropTypes.string,
          />
        </Dimmer.Dimmable>
      </div>
    );
  }
}

UserProfile.propTypes = {
  getUserProfile: PropTypes.object,
  getCountryList: PropTypes.array,
  getUserProfileAction: PropTypes.func,
  putUpdateProfileAction: PropTypes.func,
  postChangePassword: PropTypes.func,
  getCountryListAction: PropTypes.func,
  loading: PropTypes.bool,
  resetUserInfoAction: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    getUserProfile: state.UserStore.getUserProfile,
    getCountryList: state.CountryStore.getCountryList.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    loading: state.UserStore.loading,
  };
}

export default connect(
  mapStateToProps,
  {
    getUserProfileAction,
    putUpdateProfileAction,
    postChangePassword,
    getCountryListAction,
    resetUserInfoAction,
  }
)(UserProfile);
