import React from 'react';
// import TableCommissionPackage from '../../table/package/tableCommissionPackage';
import { Grid, Table, Form, Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';
// import InputCurrencyFormat from '../../input/inputCurrencyFormat';
// import CurrencyFormat from 'react-currency-format';

const TabCommissionPackage = props => {
  return (
    <div>
      <Grid>
        <Grid.Column width={12}>
          <Table basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="center">Type</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Value</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  Description
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {props.commision
                ? props.commision.map((data, idx) => (
                    <Table.Row key={idx}>
                      <Table.Cell textAlign="center" width={2}>
                        {data.Category.split('_').join(' ')}
                      </Table.Cell>
                      <Table.Cell width={5}>
                        <Form>
                          <Input
                            type="text"
                            name={'Value,' + idx}
                            id={'Value' + idx}
                            onChange={e => {
                              props.handleChangeCommissions(
                                parseInt(e.target.value.replace(/\D/g, ''), 10),
                                idx,
                                'Value'
                              );
                            }}
                            disabled={props.isPublished}
                            value={data.Value.toLocaleString('ID')}
                          />
                          {/* <CurrencyFormat
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            decimalScale={2}
                            value={data.Value}
                            name={'Value,' + idx}
                            id={'Value' + idx}
                            onValueChange={e =>
                              props.handleChangeCommissions(
                                e.value,
                                idx,
                                'Value'
                              )
                            }
                            disabled={true}
                          /> */}
                        </Form>
                      </Table.Cell>
                      <Table.Cell width={5}>
                        <Input
                          value={data.Description}
                          name={'Description,' + idx}
                          type="text"
                          fluid
                          onChange={e =>
                            props.handleChangeCommissions(
                              e.target.value,
                              idx,
                              'Description'
                            )
                          }
                          disabled={props.isPublished}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))
                : null}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
    </div>
  );
};

TabCommissionPackage.propTypes = {
  agentCommission: PropTypes.object,
  staffCommission: PropTypes.object,
  handleChangeCommissionDescription: PropTypes.func,
  handleChangeCommission: PropTypes.func,
  status: PropTypes.bool,
  commision: PropTypes.array,
  handleChangeCommissions: PropTypes.func,
  isPublished: PropTypes.bool,
};
export default TabCommissionPackage;
