export const HeaderTabelTourOperatorProfile = [
  'Tour Operator Name',
  'Telephone',
  'Email',
  'City',
  'Address',
  'Action',
];

export const HeaderTabelEmployeeList = [
  'Username',
  'Name',
  'Phone Number',
  'Email',
  'Address',
  'Status',
  'Action',
];
