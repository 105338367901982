import React from 'react';
import PropTypes from 'prop-types';
import { Form, Grid, Header } from 'semantic-ui-react';
import InputWithLabel from '../../../../components/input/inputWithLabel';

const ContactInformation = props => {
  return (
    <React.Fragment>
      <Header as="h4" content="Contact Information" />
      <Form>
        <InputWithLabel
          inline={false}
          label="Address"
          type="text"
          required
          name="Address"
          value={props.contentDetail.Address}
          placeholder="Address"
          handleChange={props.handleChange}
          validate={props.errorInformation}
          disabled={props.disable}
        />
      </Form>
      <Grid columns={2} padded="vertically">
        <Grid.Column>
          <Form>
            <InputWithLabel
              inline={false}
              label="Email"
              type="text"
              required
              name="Email"
              value={props.contentDetail.Email}
              placeholder="Email"
              handleChange={props.handleChange}
              validate={props.errorInformation}
              disabled={props.disable}
            />
          </Form>
        </Grid.Column>
        <Grid.Column>
          <Form>
            <InputWithLabel
              inline={false}
              label="Phone Number"
              type="text"
              required
              name="PhoneNbr"
              value={props.contentDetail.PhoneNbr}
              placeholder="Phone Number"
              handleChange={props.handleChange}
              validate={props.errorInformation}
              disabled={props.disable}
            />
          </Form>
        </Grid.Column>
      </Grid>
    </React.Fragment>
  );
};

ContactInformation.propTypes = {
  contentDetail: PropTypes.object,
  handleChange: PropTypes.func,
  errorInformation: PropTypes.object,
  disable: PropTypes.bool,
};
export default ContactInformation;
