import React from 'react';
import { Table, Input, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import CurrencyFormat from 'react-currency-format';
const tableSupplierPriceDetails = props => (
  <Table size="small" striped>
    <Table.Header>
      <Table.Row>
        {props.headerTable.map((headTableContent, index) => {
          return (
            <Table.HeaderCell key={index}>{headTableContent}</Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {props.listData &&
        props.listData.map((margin, idx) => {
          return (
            <Table.Row key={idx}>
              <Table.Cell>
                <CurrencyFormat
                  // thousandSeparator={true}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  decimalScale={2}
                  value={margin.Price}
                  textAlign="right"
                  name={'Price,' + idx}
                  id={'Price' + idx}
                  onValueChange={e =>
                    props.handleChangeCostPriceDetails(e.value, idx, 'Price')
                  }
                />
              </Table.Cell>
              <Table.Cell>
                <Input
                  name={'MinPax,' + idx}
                  type="number"
                  onChange={props.handleChangePriceDetails}
                  value={margin.MinPax}
                />
              </Table.Cell>
              <Table.Cell>
                <Button
                  content="Delete"
                  negative
                  id={idx}
                  value={margin.Id}
                  onClick={props.deletePrice}
                />
              </Table.Cell>
            </Table.Row>
          );
        })}
    </Table.Body>
  </Table>
);

tableSupplierPriceDetails.propTypes = {
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  excurtionTypePrice: PropTypes.array,
  currenciesList: PropTypes.array,
  handleChangePrice: PropTypes.func,
  handleChangeCostPriceDetails: PropTypes.func,
  handleChangePriceDetails: PropTypes.func,
  deletePrice: PropTypes.func,
};
export default tableSupplierPriceDetails;
