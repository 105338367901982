import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Header,
  Form,
  // Image,
  Button,
  Container,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Input from '../../../../components/input/inputWithLabel';
import { getTourTypeAction } from '../../../../actions/tourTransactionAction';
import {
  resetPackageAction,
  getFilterSharedpackageAction,
  getFixedPackage,
} from '../../../../actions/packageAction';
import { getCurrenciesAction } from '../../../../actions/currenciesAction';
import options from '../../../../scripts/convertOption';
import AdditionalInfo from '../../../shared/components/tourTransaction/generalInformation/segment/AdditionalInformation';
import TableImage from '../../../../components/image/image';
import ModalImage from '../../../../components/modal/modalImage';
import { datasetPagination } from '../../../../scripts/pagination';
import ModalSharingPax from './ModalSharingPax';
import { getAirportAction } from '../../../../actions/airportAction';
import {
  changeDestinationSummaryProgram,
  setPlaceInArrivalDepartureByHeadLine,
} from '../../../../scripts/itineraryBuilder/dailyProgram';
// import UploadButton from '../../../../components/common/uploadImageButton';
import ModalConfirm from '../../../../components/modal/modalConfirmationTwoButton';
import { sharedPaxValidation } from '../../services/validation/validationSharingPax';
import moment from 'moment';
import ModalAlert from '../../../../components/modal/modalAlert';
import InputDate from '../../../../components/input/inputDate';
import { convertDateFormat, addDate } from './../../../../scripts/moment';
import ModalDownloadImage from '../../../../components/modal/ModalDownloadImage';
import { handleDownloadFile } from '../../../../scripts/download';
import { getUrlImageBrochureOverlay } from '../../services/helper/getUrlImageBrochureOverlay';
import { GET_DOWNLOAD_BROCHURE_BY_BOOKING_TEMPLATE_ID_AND_IMAGE_ID_URL } from '../../../../api/apiUrl';

const HEADLINE_PROGRAM = {
  Departures: [],
  MainPrograms: [],
  Returns: [],
};
class generalInfoPackage extends Component {
  state = {
    dataForImage: {
      contentModal: '',
      openModalImage: false,
      indexActive: 0,
      listStepImage: [
        {
          Tittle: 'Choose Image',
          Active: true,
        },
        {
          Tittle: 'Select Thumbnail Image',
          Active: false,
        },
        {
          Tittle: 'Edit Thumbnail Image',
          Active: false,
        },
      ],
      imagesModal: [],
      image: {},
      activePageImage: 1,
      openModalDownloadImage: false,
      imagesDownloadModal: [],
    },
    openModalSharingPax: false,
    checkBoxSharingPax: false,
    nameSharingPax: '',
    idSharingPax: '',
    tourImages: {},
    isHaveConfirmGuest: false,
    openAlert: false,
    isConfirmGuestCurrentPackage: false,
    isConfirmGuestSharedPackage: false,
    valueConfirm: null,
    headerAlert: '',
  };
  componentDidMount() {
    this.props.getTourTypeAction();
    this.props.getCurrenciesAction();
    this.firstRender();
  }

  firstRender = async () => {
    let waitIdFulfilled = (await this.props.currentPackage.id) > 0;
    !waitIdFulfilled &&
      this.props
        .getFixedPackage('Fixed', this.props.currentPackage.id)
        .then(res => {
          const {
            SharedPaxAllotmentPackages,
            BookingDetailSum,
          } = res.value.data;
          this.setStateSharingPax(SharedPaxAllotmentPackages, BookingDetailSum);
        });
  };
  // componentDidUpdate() {
  //   if (this.props.getFixedPackageStatus === 'success') {
  //     const {
  //       SharedPaxAllotmentPackages,
  //       BookingDetailSum,
  //     } = this.props.transactionDetail;
  //     this.setStateSharingPax(SharedPaxAllotmentPackages, BookingDetailSum);
  //     this.props.resetPackageAction();
  //   }
  // }

  setStateSharingPax = (SharedPaxAllotmentPackages, BookingDetailSum) => {
    const { currentPackage } = this.props;
    if (BookingDetailSum.Id === currentPackage.id) {
      let checkBoxSharingPax =
        SharedPaxAllotmentPackages.length > 0
          ? SharedPaxAllotmentPackages[0].Id
          : 0;
      let nameSharingPax =
        SharedPaxAllotmentPackages.length > 0
          ? SharedPaxAllotmentPackages[0].Text
          : '';
      let checkConfirmGuestCurrent =
        BookingDetailSum.FixedPackage.ConfirmedGuest > 0 ? true : false;
      checkBoxSharingPax > 0 &&
        this.props.getFixedPackage('Fixed', checkBoxSharingPax).then(res => {
          const {
            SharedPaxAllotmentPackages,
            BookingDetailSum,
          } = res.value.data;
          this.setStateSharingPax(SharedPaxAllotmentPackages, BookingDetailSum);
        });
      this.setState({
        checkBoxSharingPax,
        nameSharingPax,
        isConfirmGuestCurrentPackage: checkConfirmGuestCurrent,
      });
    } else {
      let checkConfirmGuest =
        BookingDetailSum.FixedPackage.ConfirmedGuest > 0 ? true : false;
      this.setState({
        isConfirmGuestSharedPackage: checkConfirmGuest,
      });
    }
  };

  handleOnchangeDate = async (date, name) => {
    const { tourInformation, errors } = this.props;
    const { valueConfirm } = this.state;
    if (
      tourInformation.endDate !== '' &&
      name === 'endDate' &&
      (valueConfirm === null || valueConfirm === undefined)
    ) {
      if (new Date(tourInformation.endDate) > new Date(date)) {
        this.openConfirmDate(
          date,
          name,
          'RESET_ACCOM_DAILY',
          'Reducing the total days will reset the accommodation and itinerary'
        );
      } else {
        this.openConfirmDate(
          date,
          name,
          'ADD_DAYS',
          'All activity outside accommodation in itinerary will be reset'
        );
      }
    } else {
      date = convertDateFormat(date, 'YYYY-MM-DD');
      if (name === 'startDate' || name === 'endDate') {
        await this.props.handleDateHeadlineProgram(
          date,
          0,
          name,
          tourInformation
        );
      }
      await this.props.handleGeneralInfo(name, date, 'tourInformation');
      await this.props.checkGeneralInformation(tourInformation, errors);
    }
  };

  handleBlurTotalDays = async (e, { name, value, objectname }) => {
    const { tourInformation, errors } = this.props;
    // let { name, value } = e.target;
    let x = await addDate(tourInformation.startDate, value - 1, 'd');
    await this.handleOnchangeDate(x, 'endDate');
    await this.props.handleGeneralInfo(name, value, objectname);
    this.props.checkGeneralInformation(tourInformation, errors);
  };
  handleOnchange = async (e, { name, value, objectname }) => {
    const { tourInformation, errors } = this.props;
    name === 'destination' &&
      (await this.setFirstDestination(name, value, objectname));
    await this.props.handleGeneralInfo(name, value, objectname);
    ['STARTDATE', 'ENDDATE'].indexOf(name.toUpperCase()) !== -1 &&
      this.props.handleDateHeadlineProgram(value, 0, name, tourInformation);
    await this.props.checkGeneralInformation(tourInformation, errors);
    // let errors = { ...this.props.errors };
    // let isError = value ? false : true;
    // errors[name].error = isError;
    // this.props.setErrorsGeneralInform(errors);
    // if (name === 'destination') {
    //   this.props.setFirstDestination(value);
    // }
  };
  // ========================= HANDLE UPLOAD IMAGE ===================================
  deleteImage = async value => {
    // (e, { value })
    let images = [...this.props.images];
    // images.splice(id, 1);
    images = images.filter((item, i) => i !== value);
    let dataForImage = {
      ...this.state.dataForImage,
      activePageImage: 1,
    };
    await this.setState({ ...this.state, dataForImage });
    this.props.handleImages('images', images);
  };

  openDetail = () => {
    let { listStepImage, contentModal } = { ...this.state.dataForImage };
    let { images } = this.props;
    listStepImage.map((step, i) => {
      step['Active'] = i === 0 ? true : false;
      return listStepImage;
    });
    let imagesModal = [...images];
    contentModal = 'Image';
    let dataForImage = {
      contentModal: contentModal,
      openModalImage: true,
      indexActive: 0,
      listStepImage: listStepImage,
      imagesModal: imagesModal,
    };
    this.setState({
      ...this.state,
      dataForImage,
    });
  };
  changeImage = e => {
    let imageObject = [...this.state.dataForImage.imagesModal];
    e.map(base => {
      let image = {
        Id: 0,
        ImageContent: base.base64.split(',')[1],
        ImagePreview: base.base64,
        ImageName: base.file.name,
        IsPrimaryImage: false,
      };
      return imageObject.push(image);
    });
    let dataForImage = { ...this.state.dataForImage, imagesModal: imageObject };
    this.setState({
      ...this.state,
      dataForImage,
    });
  };
  addImage = async () => {
    let images = [...this.props.images];
    let listStepImage = [...this.state.listStepImage];
    listStepImage.map((step, i) => {
      step['Active'] = i === 0 ? true : false;
      return listStepImage;
    });
    let dataForImage = {
      ...this.state.dataForImage,
      openModalImage: false,
      listStepImage: listStepImage,
      indexActive: 0,
    };
    await this.setState({
      ...this.state,
      dataForImage,
    });
    this.props.handleImages('images', images);
  };
  handleClose = () => {
    let dataForImage = {
      ...this.state.dataForImage,
      openModalImage: false,
      openModalDownloadImage: false,
    };
    this.setState({
      ...this.state,
      dataForImage,
    });
  };
  handleNext = async () => {
    let { indexActive, images, imagesModal, listStepImage } = {
      ...this.state.dataForImage,
    };
    indexActive = indexActive + 1;
    listStepImage.map((step, i) => {
      step['Active'] = i === indexActive ? true : false;
      return step;
    });
    if (indexActive === 2) {
      let listImage = await imagesModal.filter(img => img.IsPrimaryImage);
      let image = listImage ? (listImage.length > 0 ? listImage[0] : {}) : {};
      let dataForImage = await {
        ...this.state.dataForImage,
        image,
      };
      this.setState({ ...this.state, dataForImage });
    }
    if (indexActive === 3) {
      images = [...imagesModal];
      let dataForImage = {
        ...this.state.dataForImage,
        indexActive: 0,
        openModalImage: false,
      };
      await this.setState({ ...this.state, dataForImage });
      await this.props.handleImages('images', images);
    }
    let dataForImage = {
      ...this.state.dataForImage,
      listStepImage: listStepImage,
      indexActive: indexActive,
    };
    this.setState({ ...this.state, dataForImage });
  };
  handleBackStep = () => {
    let indexActive = this.state.dataForImage.indexActive;
    indexActive = indexActive - 1;
    let listStepImage = [...this.state.dataForImage.listStepImage];
    listStepImage.map((step, i) => {
      step['Active'] = i === indexActive ? true : false;
      return listStepImage;
    });
    let dataForImage = {
      ...this.state.dataForImage,
      listStepImage: listStepImage,
      indexActive: indexActive,
    };
    this.setState({ ...this.state, dataForImage });
  };
  handleChangePrimaryImageModal = (e, { value }) => {
    let listImage = [...this.state.dataForImage.imagesModal];
    let newList = [];
    listImage.map((img, i) => {
      img['IsPrimaryImage'] = i === value ? true : false;
      return newList.push(img);
    });
    let dataForImage = {
      ...this.state.dataForImage,
      imagesModal: newList,
    };
    this.setState({ ...this.state, dataForImage });
  };
  handleChangePrimaryImage = (e, { value }) => {
    let listImage = [...this.props.images];
    let newList = [];
    listImage.map((img, i) => {
      img['IsPrimaryImage'] = i === value ? true : false;
      return newList.push(img);
    });
    this.props.handleImages('images', newList);
  };
  handleChangeSelectedImageDownload = (e, { value }) => {
    const { images } = this.props;
    let listImage = [...images];
    let newList = [];
    listImage.map((img, i) => {
      img['IsPrimaryImage'] = i === value ? true : false;
      return newList.push(img);
    });
    let dataForImage = {
      ...this.state.dataForImage,
      imagesDownloadModal: newList,
    };
    this.setState({ ...this.state, dataForImage });
  };
  pageChangeImage = (e, { activePage }) => {
    let dataForImage = {
      ...this.state.dataForImage,
      activePageImage: activePage,
    };
    this.setState({ ...this.state, dataForImage });
  };

  // ========================= END HANDLE UPLOAD IMAGE ================================
  handleModalSharingPax = async () => {
    let { openModalSharingPax } = this.state;
    this.setState({
      openModalSharingPax: !openModalSharingPax,
    });
    !openModalSharingPax &&
      (await this.props.getFilterSharedpackageAction('', '', '', ''));
  };
  handleCheckBoxSharingPax = async () => {
    const { checkBoxSharingPax } = this.state;
    await this.props.handleGeneralInfo(
      'BookingTemplateSharedPaxId',
      0,
      'tourInformation'
    );
    this.setState({
      checkBoxSharingPax: !checkBoxSharingPax,
      nameSharingPax: '',
    });
  };
  handlePickSharingPax = async (e, { value }) => {
    const { tourInformation } = this.props;
    let err = sharedPaxValidation(tourInformation, value);
    if (!err.isError) {
      this.setState({ sharingPaxData: value }, () =>
        this.setSharingPaxToField()
      );
      // this.handleSetSharingPaxPicked();
    } else {
      this.setState({
        messageConfirmation: err.message,
        openAlert: err.isError,
        sharingPaxData: value,
      });
      // if (currentPackage.status === 'create') {
      //   this.setState({
      //     messageConfirmation: err.message,
      //     openConfirmation: err.isError,

      //     sharingPaxData: value,
      //   });
      // } else {
      //   this.setState({
      //     messageConfirmation: err.message,

      //     openAlert: err.isError,
      //     sharingPaxData: value,
      //   });
      // }
    }
  };

  setFirstDestination = async (name, value, objectname) => {
    const { tourInformation } = this.props;
    let city = this.props.cityInCountry.find(item => item.Id === value);
    let headLineProg = { ...this.props.headlineProgram };
    if (
      tourInformation.destination !== value ||
      (headLineProg.MainPrograms[0].City ? false : true)
    ) {
      // eslint-disable-next-line
      headLineProg.MainPrograms = await changeDestinationSummaryProgram(
        headLineProg.MainPrograms,
        0,
        city
      );
    }
    await this.props.handleGeneralInfo(name, value, objectname);
    let context = this;
    await this.props.getAirportAction(city.Region.Id).then(res => {
      if (res.value.data.length > 0) {
        context.setPlace(headLineProg);
      } else {
        context.props.setheadlineProgram(headLineProg);
      }
    });
    //await this.props.setAccommodationOnChangeIndex(0);
  };
  setPlace = async headline => {
    const { placeByRegion, cityInCountry } = this.props;
    let newHeadline = await setPlaceInArrivalDepartureByHeadLine(
      headline,
      placeByRegion,
      cityInCountry
    );
    if (!this.props.isFixedPackageMangement) {
      newHeadline.Departures = headline.Departures;
      newHeadline.Returns = headline.Returns;
    }
    this.props.setheadlineProgram(newHeadline);
  };

  handleChangeTourBrocure = e => {
    const { setBrochures, currentPackage } = this.props;
    let object = this.state.tourImages;
    let reader = new FileReader();
    let files = e.target.files[0];
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      let image = {
        ...object,
        ImageType: 83,
        BookingTemplateId: currentPackage.id,
        ImageName: files.name,
        ImageContent: reader.result.split(',')[1],
        ImagePreview: reader.result,
        change: true,
      };
      setBrochures(image);
    };
  };
  handleSetSharingPaxPicked = () => {
    this.setSharingPaxToField();
    this.props.setSharingPaxData(this.state.sharingPaxData);
  };

  handleCloseConfirm = () => {
    this.setState({
      openConfirmation: false,
      openAlert: false,
      valueConfirm: null,
      headerAlert: '',
    });
  };
  setSharingPaxToField = async () => {
    const { sharingPaxData } = this.state;
    const { tourInformation, errors } = this.props;
    const objectname = 'tourInformation';
    this.setState({
      nameSharingPax: sharingPaxData.Title,
    });
    await this.handleSetSharingPax(
      'BookingTemplateSharedPaxId',
      sharingPaxData.Id,
      objectname
    );
    await this.handleSetSharingPax(
      'maxGuest',
      sharingPaxData.FixedPackage.MaximumGuest,
      objectname
    );
    await this.handleSetSharingPax(
      'minGuest',
      sharingPaxData.FixedPackage.MinimumGuest,
      objectname
    );
    await this.handleSetSharingPax(
      'startDate',
      moment(sharingPaxData.StartDate).format('YYYY-MM-DD'),
      objectname
    );
    await this.handleSetSharingPax(
      'destination',
      sharingPaxData.City.Id,
      objectname
    );
    await this.props.checkGeneralInformation(tourInformation, errors);
    this.handleModalSharingPax();
  };
  handleSetSharingPax = async (name, value, objectname) => {
    await this.props.handleGeneralInfo(name, value, objectname);
    name === 'destination' &&
      (await this.setFirstDestination(name, value, objectname));
    ['STARTDATE', 'ENDDATE'].indexOf(name.toUpperCase()) !== -1 &&
      this.props.handleDateHeadlineProgram(
        value,
        0,
        name,
        this.props.tourInformation
      );
  };

  openConfirmDate = async (date, name, nameConfirm, messageConfirmation) => {
    date = convertDateFormat(date, 'YYYY-MM-DD');
    this.setState({
      ...this.state,
      openConfirmation: true,
      nameConfirm,
      messageConfirmation,
      valueConfirm: { date, name },
    });
  };

  handleEndDate = async () => {
    const { date, name } = this.state.valueConfirm;
    await this.handleOnchangeDate(date, name);
    // this.handleCloseConfirm();
  };

  handleConfirm = async (e, { name }) => {
    if (name === 'RESET_ACCOM_DAILY' || name === 'ADD_DAYS') {
      this.handleEndDate();
      name === 'RESET_ACCOM_DAILY' && this.resetAccomDaily();
      name === 'ADD_DAYS' &&
        (await this.setState({
          ...this.state,
          openAlert: true,
          headerAlert: 'Warning',
          messageConfirmation:
            'Please check again your accommodation and itinerary',
        }));
    } else this.handleSetSharingPaxPicked();
    this.setState({ openConfirmation: false, valueConfirm: null });
  };

  resetAccomDaily = async () => {
    let { tourInformation, roomAllocation } = this.props;
    let { startDate, endDate, destination } = { ...tourInformation };
    await this.props.resetAccomDaily(HEADLINE_PROGRAM, roomAllocation);
    await this.props.handleDateHeadlineProgram(startDate, 0, 'startDate', {
      ...tourInformation,
      startDate: '',
    });
    await this.props.handleDateHeadlineProgram(endDate, 0, 'endDate', {
      ...tourInformation,
      endDate: '',
    });
    await this.setFirstDestination(
      'destination',
      destination,
      'tourInformation'
    );
  };

  handleClickDownloadBrochure = () => {
    let dataForImage = { ...this.state.dataForImage };
    dataForImage.openModalDownloadImage = true;
    this.setState({ dataForImage });
  };

  handleDownloadBrochure = () => {
    let dataImage = { ...this.state.dataForImage };
    let imagesDownload = dataImage.imagesDownloadModal;
    let imageToDownload = imagesDownload.find(item => item.IsPrimaryImage);
    // let imageUrl = imageToDownload.ImageUrl.replace(/(\r\n|\n|\r)/gm, '');
    let imageUrl = getUrlImageBrochureOverlay(
      GET_DOWNLOAD_BROCHURE_BY_BOOKING_TEMPLATE_ID_AND_IMAGE_ID_URL(
        this.props.Id,
        30000,
        imageToDownload.ImageId
      )
    );
    handleDownloadFile(imageUrl, '');
  };

  render() {
    const {
      currentPackage,
      tourInformation,
      errors,
      listTourType,
      loadingGetTourType,
      listCurrencies,
      loadingGetCurrencies,
      optionCityInCountry,
      loadingCityGroup,
      additionalInfo,
      images,
      isPublished,
      isOperation,
      isDisabled,
    } = this.props;
    const {
      dataForImage,
      openModalSharingPax,
      checkBoxSharingPax,
      openConfirmation,
      messageConfirmation,
      openAlert,
      isConfirmGuestCurrentPackage,
      isConfirmGuestSharedPackage,
      nameConfirm,
      headerAlert,
    } = this.state;
    let isHaveConfirmGuest =
      isConfirmGuestCurrentPackage || isConfirmGuestSharedPackage;
    // ======================= images =================================
    let pagedDataImages = images ? datasetPagination(images, 3) : [];
    // ===================== end images ===============================

    return (
      <div>
        <Grid columns={2}>
          <Grid.Column>
            <Header as="h4" content="General Information" />
            <Form>
              <Form.Group widths="equal">
                <Form.Field>
                  <Input
                    inline={false}
                    label="Tour Name"
                    type="text"
                    fluid={true}
                    required
                    // disabled={currentPackage.disabledEdit || isPublished}
                    disabled={isDisabled || isOperation}
                    name="tourName"
                    value={tourInformation.tourName}
                    placeholder="Tour Name"
                    handleChange={this.handleOnchange}
                    objectname="tourInformation"
                    validate={errors}
                    // loading={loadingTourTransaction}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <Input
                    inline={false}
                    label="Description"
                    type="textArea"
                    fluid={true}
                    required
                    disabled={currentPackage.disabledEdit || isOperation}
                    name="description"
                    value={tourInformation.description}
                    placeholder="Description"
                    handleChange={this.handleOnchange}
                    objectname="tourInformation"
                    validate={errors}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <Input
                    label="Tour Type"
                    type="select"
                    fluid={true}
                    required
                    name="tourType"
                    value={tourInformation.tourType}
                    placeholder="Tour Type"
                    options={listTourType}
                    loading={loadingGetTourType}
                    disabled={currentPackage.disabledEdit || isOperation}
                    handleChange={this.handleOnchange}
                    objectname="tourInformation"
                    validate={errors}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    label="Currency"
                    type="select"
                    required
                    name="currency"
                    value={tourInformation.currency}
                    placeholder="Currency"
                    options={listCurrencies}
                    loading={loadingGetCurrencies}
                    // disabled={currentPackage.disabledEdit || isPublished}
                    disabled={isDisabled || isOperation}
                    handleChange={this.handleOnchange}
                    objectname="tourInformation"
                    validate={errors}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
            <Form>
              <Form.Group widths="equal">
                <Form.Field>
                  <Input
                    inline={false}
                    label="Reference id"
                    type="text"
                    fluid={true}
                    name="referenceId"
                    value={tourInformation.referenceId}
                    placeholder="Reference Id"
                    handleChange={this.handleOnchange}
                    validate={errors}
                    objectname="tourInformation"
                    disabled={isOperation}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    inline={false}
                    label="Minimum Pax"
                    type="number"
                    fluid={true}
                    required
                    name="minPax"
                    value={tourInformation.minPax}
                    placeholder="Minimum Pax"
                    handleChange={this.handleOnchange}
                    validate={errors}
                    objectname="tourInformation"
                    disabled={isOperation}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <Form.Checkbox
                    label={{ children: 'is featured?' }}
                    objectname="tourInformation"
                    name="isFeatured"
                    value={!tourInformation.isFeatured}
                    checked={tourInformation.isFeatured}
                    onChange={this.handleOnchange}
                    disabled={isOperation}
                  />
                </Form.Field>
              </Form.Group>
            </Form>

            <React.Fragment>
              <Header as="h4" content="Guest" />
              <Form>
                <Form.Field>
                  <Form.Checkbox
                    label={{ children: 'Sharing pax with another package?' }}
                    checked={checkBoxSharingPax}
                    onChange={this.handleCheckBoxSharingPax}
                    disabled={isHaveConfirmGuest || isOperation}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    label="Sharing Pax With"
                    //type="number"
                    fluid={true}
                    required
                    //name="minGuest"
                    value={this.state.nameSharingPax}
                    placeholder="Sharing Pax With"
                    disabled={
                      currentPackage.disabledEdit ||
                      !checkBoxSharingPax ||
                      isHaveConfirmGuest ||
                      isOperation
                    }
                    //handleChange={this.handleOnchange}
                    //objectname="tourInformation"
                    validate={errors}
                    handleClick={this.handleModalSharingPax}
                  />
                </Form.Field>
              </Form>
              <Form>
                <Form.Group widths="equal">
                  <Form.Field>
                    <Input
                      label="Minimum Guest"
                      type="number"
                      fluid={true}
                      required
                      name="minGuest"
                      value={tourInformation.minGuest}
                      placeholder="Minimum Guest"
                      disabled={
                        (currentPackage.status === 'create' &&
                          checkBoxSharingPax) ||
                        isHaveConfirmGuest ||
                        isOperation
                      }
                      handleChange={this.handleOnchange}
                      objectname="tourInformation"
                      validate={errors}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      label="Maximum Guest"
                      type="number"
                      required
                      name="maxGuest"
                      value={tourInformation.maxGuest}
                      placeholder="Maximum Guest"
                      disabled={
                        (currentPackage.status === 'create' &&
                          checkBoxSharingPax) ||
                        isHaveConfirmGuest ||
                        isOperation
                      }
                      handleChange={this.handleOnchange}
                      objectname="tourInformation"
                      validate={errors}
                      fluid={true}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </React.Fragment>

            <Header as="h4" content="Destination and Date" />
            <Form>
              <Form.Group widths="equal">
                <Form.Field>
                  <Input
                    inline={false}
                    label="First Destination"
                    type="select"
                    fluid={true}
                    required
                    disabled={isDisabled || isOperation}
                    name="destination"
                    value={tourInformation.destination}
                    options={optionCityInCountry}
                    placeholder="Select First Destination"
                    loading={loadingCityGroup}
                    handleChange={this.handleOnchange}
                    objectname="tourInformation"
                    validate={errors}
                  />
                </Form.Field>
              </Form.Group>

              <Form>
                {' '}
                <Form.Group widths="equal">
                  <Form.Field>
                    <InputDate
                      label="Start Date"
                      value={tourInformation.startDate}
                      labelStyle={{
                        display: 'block',
                        fontWeight: 'bold',
                        marginBottom: '4px',
                      }}
                      // setDate={this.handleOnchangeDate}
                      setDate={this.handleOnchangeDate}
                      name="startDate"
                      placeholder="Start Date"
                      validate={errors}
                      disabled={
                        currentPackage.disabledEdit ||
                        isPublished ||
                        isOperation
                      }
                    />
                  </Form.Field>
                  <Form.Field>
                    <InputDate
                      label="End Date"
                      value={tourInformation.endDate}
                      labelStyle={{
                        display: 'block',
                        fontWeight: 'bold',
                        marginBottom: '4px',
                      }}
                      setDate={this.handleOnchangeDate}
                      name="endDate"
                      placeholder="End Date"
                      validate={errors}
                      disabled={
                        currentPackage.disabledEdit ||
                        isPublished ||
                        isOperation
                      }
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <InputDate
                      label="Expired Date"
                      value={tourInformation.expiredDate}
                      labelStyle={{
                        display: 'block',
                        fontWeight: 'bold',
                        marginBottom: '4px',
                      }}
                      setDate={this.handleOnchangeDate}
                      name="expiredDate"
                      placeholder="Expired Date"
                      validate={errors}
                      disabled={currentPackage.disabledEdit || isOperation}
                    />
                  </Form.Field>
                  <Form.Field>
                    <InputDate
                      label="Grace Date"
                      value={tourInformation.graceDate}
                      labelStyle={{
                        display: 'block',
                        fontWeight: 'bold',
                        marginBottom: '4px',
                      }}
                      setDate={this.handleOnchangeDate}
                      name="graceDate"
                      placeholder="Grace Date"
                      validate={errors}
                      disabled={currentPackage.disabledEdit || isOperation}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Form>
          </Grid.Column>
          <Grid.Column>
            <AdditionalInfo
              additionalInfoList={additionalInfo}
              handleAdditionalInfo={this.props.handleAdditionalInfo}
              deletedAdditionalInformation={
                this.props.deletedAdditionalInformation
              }
              tableName="Additional Info"
              isEVoucher={false}
              disabled={isOperation}
            />
            <AdditionalInfo
              additionalInfoList={additionalInfo}
              handleAdditionalInfo={this.props.handleAdditionalInfo}
              deletedAdditionalInformation={
                this.props.deletedAdditionalInformation
              }
              tableName="Additional Info For E-Voucher"
              isEVoucher={true}
              disabled={isOperation && isPublished}
            />
            <TableImage
              headerContent="Image"
              buttonContent="Add Image"
              deleteData={this.deleteImage}
              listData={images}
              openDetail={this.openDetail}
              handleChangePrimaryImage={this.handleChangePrimaryImage}
              addData={this.openDetail}
              pattern="BookingTemplateImages/"
              pagedData={pagedDataImages}
              pageChange={this.pageChangeImage}
              activePage={dataForImage.activePageImage}
              handleDeleteImages={this.props.handleDeleteImages}
              disablePage={isOperation}
            />

            <Grid
              columns="equal"
              style={{ marginTop: '2em' }}
              verticalAlign="middle"
            >
              <Grid.Row>
                <Grid.Column>
                  <Header as="h4" content="Tour Brochure" />
                </Grid.Column>
                <Grid.Column />
                <Grid.Column floated="right" />
              </Grid.Row>
              {/* <Grid.Row>
                <Grid.Column>
                  <Image
                    src={
                      this.props.brochures.ImagePreview ||
                      require('../../../../assets/Icons/no_image.jpg')
                    }
                    size="medium"
                    centered
                  />
                </Grid.Column>
              </Grid.Row> */}
              <Grid.Row columns="2">
                <Grid.Column floated="right" textAlign="center">
                  <Container textAlign="center">
                    <Button.Group>
                      {/* <UploadButton
                        onImageSelect={this.handleChangeTourBrocure}
                        label="Select Image"
                        // buttonStyle={{
                        //   marginTop: '1em',
                        //   marginLeft: '14em',
                        // }}
                        disabled={isOperation}
                      /> */}
                      {currentPackage.type === 'Fixed' && isPublished && (
                        <Button
                          primary
                          style={{ marginLeft: '10px' }}
                          onClick={this.handleClickDownloadBrochure}
                        >
                          Download Brochure
                        </Button>
                      )}
                    </Button.Group>
                  </Container>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <ModalImage
              openModal={dataForImage.openModalImage}
              handleChange={this.changeImage}
              addImage={this.addImage}
              handleClose={this.handleClose}
              header="Upload Image"
              image={dataForImage.imagesModal}
              listStepImage={dataForImage.listStepImage}
              handleNext={this.handleNext}
              handleBackStep={this.handleBackStep}
              indexActive={dataForImage.indexActive}
              handleChangePrimaryImage={this.handleChangePrimaryImageModal}
              imageRatio={dataForImage.image}
            />
            <ModalDownloadImage
              openModal={dataForImage.openModalDownloadImage}
              handleClose={this.handleClose}
              header="Download Brochure Image"
              image={images}
              handleDownloadImage={this.handleDownloadBrochure}
              handleChangePrimaryImage={this.handleChangeSelectedImageDownload}
            />
          </Grid.Column>
        </Grid>
        <ModalSharingPax
          open={openModalSharingPax}
          handleClose={this.handleModalSharingPax}
          header="Choose tour packge for sharing pax"
          loadingModal={false}
          listTourType={this.props.listTourType}
          handlePickSharingPax={this.handlePickSharingPax}
          currentPackage={this.props.currentPackage}
        />
        <ModalConfirm
          openModal={openConfirmation}
          size="mini"
          close={this.handleCloseConfirm}
          confirm={this.handleConfirm}
          message={messageConfirmation}
          nameConfirm={nameConfirm}
        />
        <ModalAlert
          openModal={openAlert}
          handleClose={this.handleCloseConfirm}
          header={headerAlert ? headerAlert : 'Information'}
          content={messageConfirmation}
        />
      </div>
    );
  }
}
generalInfoPackage.propTypes = {
  currentPackage: PropTypes.object,
  tourInformation: PropTypes.object,
  errors: PropTypes.object,
  getTourTypeAction: PropTypes.func,
  listTourType: PropTypes.array,
  loadingGetTourType: PropTypes.bool,
  getCurrenciesAction: PropTypes.func,
  listCurrencies: PropTypes.array,
  loadingGetCurrencies: PropTypes.bool,
  optionCityInCountry: PropTypes.array,
  loadingCityGroup: PropTypes.bool,
  handleGeneralInfo: PropTypes.func,
  cityInCountry: PropTypes.array,
  setErrorsGeneralInform: PropTypes.func,
  additionalInfo: PropTypes.array,
  handleAdditionalInfo: PropTypes.func,
  images: PropTypes.array,
  handleImages: PropTypes.func,
  handleDateHeadlineProgram: PropTypes.func,
  getFixedPackageStatus: PropTypes.string,
  transactionDetail: PropTypes.object,
  resetPackageAction: PropTypes.func,
  headlineProgram: PropTypes.object,
  getAirportAction: PropTypes.func,
  placeByRegion: PropTypes.object,
  setheadlineProgram: PropTypes.func,
  brochures: PropTypes.object,
  setBrochures: PropTypes.func,
  checkGeneralInformation: PropTypes.func,
  isFixedPackageMangement: PropTypes.bool,
  getFilterSharedpackageAction: PropTypes.func,
  setSharingPaxData: PropTypes.func,
  getFixedPackage: PropTypes.func,
  resetAccomDaily: PropTypes.func,
  roomAllocation: PropTypes.object,
  isPublished: PropTypes.bool,
  isDisabled: PropTypes.bool,
  deletedAdditionalInformation: PropTypes.func,
  handleDeleteImages: PropTypes.func,
  isOperation: PropTypes.bool,
  imageOverlayBrochure: PropTypes.object,
  arrayImageForDownload: PropTypes.array,
  Id: PropTypes.string,
};

const mapStateToProps = state => {
  let listTourType = state.TourTransactionStore.listTourType.map(e =>
    options(e.Id, e.Id, e.Name)
  );
  let listCurrencies = state.currenciesStore.currencies.map(e =>
    options(e.Id, e.Id, e.Id)
  );
  return {
    listTourType,
    loadingGetTourType: state.TourTransactionStore.loadingGetTourType,
    listCurrencies,
    loadingGetCurrencies: state.currenciesStore.loading,
    loadingCityGroup: state.CityStore.loading,
    transactionDetail: state.PackageStore.fixedPackage,
    getFixedPackageStatus: state.PackageStore.getFixedPackageStatus,
    placeByRegion: state.AirportStore.places,
    // packageHistoryList: state.PackageHistoryStore.packageTransactionHistoryList,
  };
};

export default connect(mapStateToProps, {
  getTourTypeAction,
  getCurrenciesAction,
  resetPackageAction,
  getAirportAction,
  getFilterSharedpackageAction,
  getFixedPackage,
})(generalInfoPackage);

// export default generalInfoPackage;
