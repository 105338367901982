const StyleButton = {
  buttonLogoSupplier: {
    margin: '10px 10px 20px 170px',
  },
  styleImagePreview: {
    marginLeft: '6.6em',
  },
};

export { StyleButton };
