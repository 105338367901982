import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-collapse';
import { Segment, Header, Grid, Button, Icon } from 'semantic-ui-react';
//import ImageCard from './../card/imageCard';
import Input from './../../input/inputWithLabel';
import styles from '../styles';
class card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openCollapse: false,
      optionDuration: [],
      typeService: '',
      duration: '',
      errors: {
        typeService: { error: false, message: '' },
        duration: { error: false, message: '' },
      },
    };
  }
  static propTypes = {
    image: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    rating: PropTypes.string,
    seat: PropTypes.string,
    options: PropTypes.array,
    id: PropTypes.number,
    setTransportItem: PropTypes.func,
    data: PropTypes.object,
    onClose: PropTypes.func,
  };
  handleClick = (e, { name }) => {
    let state = { ...this.state };
    if (name === 'openCollapse') {
      state.typeService = '';
      state.duration = '';
    }
    this.setState({
      [name]: !this.state[name],
      typeService: state.typeService,
      duration: state.duration,
    });
  };
  handleChange = (e, { name, value }) => {
    let index = this.props.options.findIndex(e => e.key === value);
    let optionDuration =
      index !== -1
        ? this.props.options[index].options
        : this.state.optionDuration;
    let { errors } = { ...this.state };
    errors[name].error = false;
    errors[name].message = '';
    this.setState({
      [name]: value,
      optionDuration,
    });
  };
  handleTransport = () => {
    this.setTransport();
  };
  setTransport = () => {
    const { typeService, duration, errors } = this.state;
    let { data, setTransportItem, onClose } = this.props;
    if (typeService && duration) {
      let transportServicetype = data.TransportationItemServiceTypes.find(
        item => item.ServiceType === typeService
      );
      let objHour = transportServicetype.Hours.find(
        item => item.Id === duration
      );
      setTransportItem(data, typeService, objHour);
      onClose();
    } else {
      let newErrors = { ...errors };
      newErrors.typeService.error = !typeService;
      newErrors.typeService.message = typeService ? '' : 'Please choose type';
      newErrors.duration.error = !duration;
      newErrors.duration.message = duration ? '' : 'Please choose duration';
      this.setState({ ...this.state, errors: newErrors });
    }
  };
  render() {
    let {
      openCollapse,
      optionDuration,
      typeService,
      duration,
      errors,
    } = this.state;
    let { name, id, type, rating, seat, options } = this.props;
    return (
      <Segment.Group style={styles.segmentNoPadding}>
        <Segment.Group horizontal>
          {/* <ImageCard images={[]} image={image} isPromo={false} size="340px  " /> */}
          {/* <Segment style={bgImage(image ? image : noImage)} /> */}
          <Segment style={{ border: 'none' }}>
            <Header as="h3" style={styles.goldColor}>
              {name.replace('_', ' ')}
            </Header>
            <Grid>
              <Grid.Row>
                <Grid.Column width="16" style={styles.justify}>
                  <p>{type}</p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{ paddingTop: '3px' }}>
                <Grid.Column width="1">
                  <Icon className="star" style={styles.goldColor} />
                </Grid.Column>
                <Grid.Column width="6">{rating}</Grid.Column>
                <Grid.Column width="1">
                  <Icon className="Seater-Icon" />
                </Grid.Column>
                <Grid.Column width="6">{seat} Passengers</Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width="10" floated="right">
                  <Button
                    size="small"
                    onClick={this.handleClick}
                    name="openCollapse"
                    //style={styles.buttonGold}
                    primary
                    floated="right"
                  >
                    SET SERVICE
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Segment.Group>
        <Collapse isOpened={openCollapse} style={{ margin: '0px' }}>
          <Segment style={{ border: 'none' }}>
            <Grid>
              <Grid.Row>
                <Grid.Column width="7" floated="left">
                  <Input
                    name="typeService"
                    type="select"
                    inputStyles={{
                      width: '100%',
                      border: '0px',
                      borderBottom: '2px solid #e6ca6b',
                    }}
                    transparent={true}
                    label="Service"
                    labelStyles={{ fontWeight: 'bold' }}
                    options={options}
                    handleChange={this.handleChange}
                    value={typeService}
                    placeholder="Choose Type"
                    validate={errors}
                  />
                </Grid.Column>
                <Grid.Column width="7" floated="right">
                  <Input
                    name="duration"
                    type="select"
                    inputStyles={{
                      width: '100%',
                      border: '0px',
                      borderBottom: '2px solid #e6ca6b',
                    }}
                    transparent={true}
                    label="Duration"
                    labelStyles={{ fontWeight: 'bold' }}
                    options={optionDuration}
                    handleChange={this.handleChange}
                    value={duration}
                    placeholder="Choose Duration"
                    validate={errors}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width="5" floated="right">
                  <Button
                    size="small"
                    floated="right"
                    //style={styles.buttonGold}
                    value={id}
                    onClick={this.handleTransport}
                    primary
                  >
                    CHOOSE
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Collapse>
      </Segment.Group>
    );
  }
}

export default card;
