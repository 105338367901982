import React from 'react';
import {
  Segment,
  Label,
  Icon,
  Form,
  Divider,
  Header,
  Grid,
  Button,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InputWithLabel from '../../../components/input/inputWithLabel';
import {
  getPaymentSchemeByIdAction,
  getPaymentSchemeAction,
  resetCustomerProfileAction,
  putPaymentSchemeAction,
  getPaymentSchemeDetailByIdAction,
  postPaymentSchemeDetailAction,
  postPaymentSchemeAction,
  putPaymentSchemeDetailAction,
  deletePaymentSchemeDetailAction,
} from '../../shared/actions/customerProfileAction';
import Loader from '../../../components/loader';
import TablePaymentScheme from './components/TablePaymentSchemeDetail';
import { HeaderTablePaymentSchemeDetail } from '../paymentSchemeDetail/constants/HeaderTable';
import ButtonWithIcon from '../../../components/button/buttonWithIcon';
import ModalConfirm from '../../../components/modal/modalConfirmationTwoButton';
import ModalPaymentScheme from './components/ModalPaymentSchemeDetail';
import ModalAlert from '../../../components/modal/modalAlert';
import { paymentTermScheme } from '../constants/pathName';
import { setContentPaymentTerm } from './services/data/setDataState';
import getTotalPercentage from './services/helper/getTotalPercentage';
class PaymentSchemeDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contentHeader: {
        Id: 0,
        Type: '',
        Description: '',
        IsLock: false,
      },
      contentTable: [],
      openModalPayment: false,
      contentPaymentTerm: {
        Id: 0,
        IntervalDays: '',
        Percentage: '',
        Sequence: '',
        isLockGuest: false,
        Description: '',
        IsAfterBookingBased: false,
        action: '',
      },
      errors: {},
      errorsHeader: {},
      deletedPaymentTerm: [],
    };
  }
  static propTypes = {
    getPaymentSchemeByIdStatus: PropTypes.string,
    getPaymentSchemeById: PropTypes.array,
    resetCustomerProfileAction: PropTypes.func,
    getPaymentSchemeByIdAction: PropTypes.func,
    getPaymentSchemeDetailByIdAction: PropTypes.func,
    errorMessage: PropTypes.object,
    loading: PropTypes.bool,
    postPaymentSchemeAction: PropTypes.func,
    putPaymentSchemeAction: PropTypes.func,
    putPaymentSchemeDetailAction: PropTypes.func,
    postPaymentSchemeDetailAction: PropTypes.func,
    deletePaymentSchemeDetailAction: PropTypes.func,
    numbersFrom: PropTypes.number,
    numbersTo: PropTypes.number,
    pagedData: PropTypes.array,
    activePage: PropTypes.string,
  };

  componentDidMount() {
    // eslint-disable-next-line
    let { SchemeId } = this.props.match.params;
    if (SchemeId === '0') {
      this.setState({ HeaderPage: 'Add Payment Term Scheme' });
    } else {
      this.setState({ HeaderPage: 'Edit Payment Term Scheme' });
      this.loadPage(SchemeId);
    }
  }

  loadPage = SchemeId => {
    const {
      getPaymentSchemeByIdAction,
      getPaymentSchemeDetailByIdAction,
    } = this.props;
    getPaymentSchemeByIdAction(SchemeId).then(res =>
      this.setState({ contentHeader: res.value.data })
    );
    getPaymentSchemeDetailByIdAction(SchemeId).then(res => {
      this.setState({
        contentTable: res.value.data,
      });
    });
  };
  componentDidUpdate() {
    const {
      getPaymentSchemeByIdStatus,
      getPaymentSchemeById,
      resetCustomerProfileAction,
      errorMessage,
    } = this.props;
    if (getPaymentSchemeByIdStatus === 'success') {
      this.setState({
        paymentTermList: getPaymentSchemeById,
      });
      resetCustomerProfileAction();
    } else if (getPaymentSchemeByIdStatus === 'failed') {
      this.setState({
        failedCity: true,
        headerAlert: 'Failed',
        contentAlert: errorMessage,
      });
      resetCustomerProfileAction();
    }
  }

  onChangeHeader = (e, { value, name }) => {
    const { contentHeader } = this.state;
    contentHeader[name] = value;
    this.setState({ contentHeader: contentHeader, onChangeHeader: true });
    this.validateScheme();
  };
  buttonBack = () => {
    // eslint-disable-next-line
    this.props.history.push(paymentTermScheme);
  };

  checkPercentage = data => {
    let { indexEdit, editModal } = this.state;
    let list = [...data];
    let total = getTotalPercentage(list);
    if (editModal && list[indexEdit]) {
      let percentageObject = list[indexEdit].Percentage;
      total = total - percentageObject;
    }
    return total;
  };
  openModalPaymentScheme = () => {
    const { contentTable } = this.state;
    let total = this.checkPercentage(contentTable);
    let { SchemeId } = this.props.match.params;
    if (Number(total) >= 100) {
      this.openAlert('Information', 'the percentage of your scheme is 100%');
    } else {
      this.setState({
        openModalPayment: true,
        indexEdit: -1,
        contentPaymentTerm: {
          Id: 0,
          IntervalDays: '',
          // Percentage: 100 - Number(total),
          Percentage: 0,
          Sequence: '1',
          isLockGuest: false,
          Description: '',
          IsAfterBookingBased: false,
          PaymentSchemeId: SchemeId,
        },
        contentHeaderModal: 'Add Payment Term Scheme',
        editModal: false,
      });
    }
  };
  handleCloseModalPayment = () => {
    this.setState({ openModalPayment: false });
  };
  savePaymentSchemeDetail = (e, { value }) => {
    const { contentPaymentTerm, indexEdit, contentTable } = this.state;
    const err = this.validateSchemeDetail();
    if (!err) {
      if (indexEdit > -1) {
        contentTable[indexEdit] = contentPaymentTerm;
        contentTable[indexEdit].action = 'edit';
        this.setState({
          openModalPayment: false,
          contentTable: contentTable,
          onChangePaymentTerm: true,
        });
      } else {
        value.action = 'add';
        contentTable.push(value);
        this.setState({
          openModalPayment: false,
          onChangePaymentTerm: true,
        });
      }
    }
  };

  savePaymentScheme = () => {
    const {
      contentHeader,
      contentTable,
      onChangeHeader,
      onChangePaymentTerm,
      deletedPaymentTerm,
    } = this.state;
    const {
      postPaymentSchemeAction,
      putPaymentSchemeAction,
      putPaymentSchemeDetailAction,
      postPaymentSchemeDetailAction,
      deletePaymentSchemeDetailAction,
    } = this.props;
    let { SchemeId } = this.props.match.params;
    let totalPercentage = getTotalPercentage(contentTable);
    const err = this.validateScheme();
    let data = {
      Type: contentHeader.Type,
      Description: contentHeader.Description,
      IsLock: contentHeader.IsLock,
      PaymentSchemeDetail: contentTable,
    };
    if (!err) {
      if (Number(totalPercentage) < 100) {
        this.openAlert(
          'Information',
          'Payment term is less than 100%, please check your payment term'
        );
      } else if (Number(totalPercentage) > 100) {
        this.openAlert(
          'Information',
          'Please check your payment term, total payment term more than 100%'
        );
      } else if (SchemeId === '0') {
        postPaymentSchemeAction(data)
          .then(res => this.handleSuccessMessageScheme(res.value.data.Id))
          .catch(() => this.handleFailedMessage());
      } else if (data.Type.length > 10) {
        postPaymentSchemeAction(data)
          .then(res => this.handleSuccessMessageScheme(res.value.data.Id))
          .catch(error =>
            this.openAlert('Information', error.response.data.Message)
          );
      } else {
        // change the header
        onChangeHeader &&
          putPaymentSchemeAction(SchemeId, contentHeader)
            .then(() => this.handleSuccessMessageScheme(SchemeId))
            .catch(() => this.handleFailedMessage());
        // post new payment scheme and put
        onChangePaymentTerm &&
          contentTable.map(data => {
            // eslint-disable-next-line
            if (data.Id == 0) {
              postPaymentSchemeDetailAction(data)
                .then(res => this.handleSuccessMessage(res.value.data.Id))
                .catch(() => this.handleFailedMessage());
            } else if (data.action && data.action === 'edit') {
              putPaymentSchemeDetailAction(data.Id, data)
                .then(() => this.handleSuccessMessage(data.Id))
                .catch(() => this.handleFailedMessage());
            }
            return data;
          });
        // delete payment scheme
        if (deletedPaymentTerm.length > 0) {
          // eslint-disable-next-line
          deletedPaymentTerm.map(values => {
            if (values !== 0) {
              deletePaymentSchemeDetailAction(values)
                .then(() =>
                  this.openAlert(
                    'Success',
                    'payment scheme successfully saved' //'payment term was successfully deleted'
                  )
                )
                .catch(
                  () => this.openAlert('Error', 'payment scheme failed to save') //'payment term failed to delete')
                );
            }
          });
        }
      }
    }
  };
  // deletePaymentScheme = () => {
  //   const { contentTable, indexPayment } = this.state;
  //   contentTable.splice(indexPayment, 1);
  //   this.setState({
  //     contentTable: contentTable,
  //     openConfirm: false,
  //     deletePaymentTerm:
  //   });
  // };
  deletePaymentScheme = () => {
    const {
      paymentTermId,
      contentTable,
      indexPayment,
      deletedPaymentTerm,
    } = this.state;
    let newDeletedPaymentTerm = [...deletedPaymentTerm];
    if (paymentTermId === 0) {
      contentTable.splice(indexPayment, 1);
    } else {
      // accomodationProfileId.Contracts[indexContracting].deleted = true;
      contentTable.splice(indexPayment, 1);
      newDeletedPaymentTerm.push(paymentTermId);
    }
    this.setState({
      openConfirm: false,
      deletedPaymentTerm: newDeletedPaymentTerm,
    });
  };
  confirmDeletePayment = (e, { name, value, id }) => {
    this.setState({
      openConfirm: true,
      messageConfirm: 'Are you sure to delete ' + name + '?',
      paymentTermDelete: name,
      indexPayment: value,
      paymentTermId: id,
    });
  };
  handleCloseConfirm = () => {
    this.setState({
      openConfirm: false,
    });
  };

  handleChangePaymentScheme = (e, { value, name }) => {
    const { contentPaymentTerm } = this.state;
    contentPaymentTerm[name] = value;
    this.setState({
      contentPaymentTerm: contentPaymentTerm,
      onChangePaymentTerm: true,
    });
    this.validateSchemeDetail();
  };
  handleCheckClick = () => {
    this.setState({
      contentPaymentTerm: {
        ...this.state.contentPaymentTerm,
        isLockGuest: !this.state.contentPaymentTerm.isLockGuest,
      },
    });
  };

  handleRadioClick = (e, { value }) => {
    this.setState({
      IsAfterBookingBased: {
        ...this.state.contentPaymentTerm,
        IsAfterBookingBased: value,
      },
    });
  };

  validateSchemeDetail = () => {
    let isError = false;
    const errors = {
      IntervalDays: { error: false, message: '' },
      Percentage: { error: false, message: '' },
      Description: { error: false, message: '' },
    };
    const {
      IntervalDays,
      Percentage,
      Description,
    } = this.state.contentPaymentTerm;
    const { contentTable } = this.state;
    let totalPercentage = this.checkPercentage(contentTable);
    let currentValue = Number(Percentage) + Number(totalPercentage);
    if (Description.length < 1) {
      isError = true;
      errors.Description.error = true;
      errors.Description.message = "Scheme Name Can't Be Empty";
    }
    if (currentValue > 100) {
      isError = true;
      errors.Percentage.error = true;
      errors.Percentage.message = 'Percentage Invalid';
    }
    if (Percentage === '0' || Percentage === '') {
      isError = true;
      errors.Percentage.error = true;
      errors.Percentage.message = "Percentage Scheme Can't Be Empty";
    }
    if (Percentage > 100) {
      isError = true;
      errors.Percentage.error = true;
      errors.Percentage.message = 'Maximum of Percentage is 100%    ';
    }
    if (IntervalDays === '0' || IntervalDays === '') {
      isError = true;
      errors.IntervalDays.error = true;
      errors.IntervalDays.message = "Interval Days Can't Be Empty";
    }
    this.setState({
      errors: {
        ...errors,
      },
    });
    return isError;
  };

  validateScheme = () => {
    let isError = false;
    const errors = {
      Description: { error: false, message: '' },
      Type: { error: false, message: '' },
    };
    const { Description, Type } = this.state.contentHeader;
    if (Description.length < 1) {
      isError = true;
      errors.Description.error = true;
      errors.Description.message = 'Description cant be empty';
    }
    if (Description.length > 100) {
      isError = true;
      errors.Description.error = true;
      errors.Description.message =
        'Description cant be longer than 100 characters';
    }
    if (Type.length < 1) {
      isError = true;
      errors.Type.error = true;
      errors.Type.message = 'Name cant be empty';
    }
    if (Type.length > 10) {
      isError = true;
      errors.Type.error = true;
      errors.Type.message = 'Name cant be longer than 10 characters';
    }
    this.setState({
      errorsHeader: {
        ...errors,
      },
    });
    return isError;
  };

  handleCloseAlert = () => {
    this.setState({
      openModalAlert: false,
    });
  };

  handleEdit = (e, { value }) => {
    const { contentTable } = this.state;
    // let obj = contentTable[value];
    let contentPayment = setContentPaymentTerm(contentTable[value]);
    this.setState({
      openModalPayment: true,
      contentPaymentTerm: contentPayment,
      indexEdit: value,
      contentHeaderModal: 'Edit Payment Term Scheme',
      editModal: true,
    });
  };

  handleSuccessMessage = data => {
    const { SchemeId } = this.props.match.params;
    if (data === 'edit') {
      this.loadPage(SchemeId);
    }
    this.openAlert('Success', 'payment scheme successfully saved');
  };
  handleFailedMessage = () => {
    this.openAlert('Error', 'payment scheme failed to save');
  };

  handleSuccessMessageScheme = SchemeId => {
    this.props.history.push(`/Payment-Terms-Scheme/${SchemeId}`);
    this.loadPage(SchemeId);
    this.openAlert('Success', 'payment scheme successfully saved');
  };

  openAlert = (header, content) => {
    this.setState({
      openModalAlert: true,
      contentAlert: content,
      headerAlert: header,
    });
  };
  render() {
    const {
      contentHeader,
      contentTable,
      openModalPayment,
      openConfirm,
      messageConfirm,
      contentPaymentTerm,
      errors,
      errorsHeader,
      openModalAlert,
      headerAlert,
      contentAlert,
      HeaderPage,
      contentHeaderModal,
    } = this.state;
    const { loading } = this.props;
    return (
      <Segment style={{ marginTop: '30px', marginRight: '10px' }}>
        <Loader loading={loading} />
        <Label
          as="h3"
          size="huge"
          style={{
            position: 'absolute',
            top: '-25px',
            background: '#38af95',
            color: 'white',
          }}
        >
          <Icon name="money" /> {HeaderPage}
        </Label>
        <div style={{ marginTop: '2em' }}>
          <Button
            positive
            content="Save"
            onClick={this.savePaymentScheme}
            floated="right"
          />
          <Button
            primary
            content="Back"
            onClick={this.buttonBack}
            floated="right"
          />
          <Grid columns={2} padded="vertically">
            <Grid.Column>
              <Form>
                <InputWithLabel
                  label="Scheme Name"
                  inline={false}
                  type="text"
                  name="Type"
                  placeholder="Payment Term Schame Name"
                  value={contentHeader.Type}
                  handleChange={this.onChangeHeader}
                  required
                  fluid={true}
                  validate={errorsHeader}
                />
              </Form>
              <Form>
                <InputWithLabel
                  label="Scheme Description"
                  inline={false}
                  type="text"
                  name="Description"
                  value={contentHeader.Description}
                  handleChange={this.onChangeHeader}
                  placeholder="Schame Description"
                  required
                  fluid={true}
                  validate={errorsHeader}
                />
              </Form>
            </Grid.Column>
            <Grid.Column />
          </Grid>
        </div>
        <Divider style={{ marginTop: '3em' }} />
        <Header as="h3">
          <Header.Content>Payment Term</Header.Content>
        </Header>
        <ButtonWithIcon
          text="Add New Payment Term"
          iconName="add"
          handleOnClick={this.openModalPaymentScheme}
          positive={true}
          floated="right"
          style={{ marginTop: '-3em', marginBottom: '2em' }}
        />
        <TablePaymentScheme
          headerTable={HeaderTablePaymentSchemeDetail}
          listData={contentTable}
          handleDelete={this.confirmDeletePayment}
          handleEdit={this.handleEdit}
          numbersFrom={this.props.numbersFrom}
          numbersTo={this.props.numbersTo}
          activePage={this.props.activePage}
          pagedData={this.props.pagedData}
        />
        <ModalPaymentScheme
          isOpen={openModalPayment}
          onClose={this.handleCloseModalPayment}
          savePaymentScheme={this.savePaymentSchemeDetail}
          contentPaymentTerm={contentPaymentTerm}
          handleChange={this.handleChangePaymentScheme}
          handleCheckClick={this.handleCheckClick}
          errorInformation={errors}
          contentHeaderModal={contentHeaderModal}
        />
        <ModalConfirm
          openModal={openConfirm}
          size="mini"
          close={this.handleCloseConfirm}
          confirm={this.deletePaymentScheme}
          message={messageConfirm}
        />
        <ModalAlert
          openModal={openModalAlert}
          handleClose={this.handleCloseAlert}
          header={headerAlert}
          content={contentAlert}
          nameButton="OK"
        />
      </Segment>
    );
  }
}

function mapStateToProps(state) {
  return {
    getPaymentSchemeByIdStatus:
      state.CustomerProfileStore.getPaymentSchemeByIdStatus,
    getPaymentSchemeById: state.CustomerProfileStore.getPaymentSchemeById,
    loading: state.CustomerProfileStore.loading,
    errors: state.CustomerProfileStore.errors,
  };
}
export default connect(mapStateToProps, {
  getPaymentSchemeByIdAction,
  getPaymentSchemeAction,
  resetCustomerProfileAction,
  putPaymentSchemeAction,
  getPaymentSchemeDetailByIdAction,
  postPaymentSchemeDetailAction,
  postPaymentSchemeAction,
  putPaymentSchemeDetailAction,
  deletePaymentSchemeDetailAction,
})(PaymentSchemeDetail);
