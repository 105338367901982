import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Grid, Header } from 'semantic-ui-react';
import Filter from '../modalSelectionMasterData/formFilter';
import ListCard from './listCard';
import styles from './../styles';
import {
  resetTransportationAction,
  getTransportationUnitByFilterAction,
} from '../../../pages/shared/actions/transportationAction';
import { resetMasterDataReferenceAction } from '../../../pages/shared/actions/uploadMasterDataReferenceAction';

import ButtonClose from '../../button/buttonCloseModal';
const options = [
  { key: 'HS', value: 'highest seat', text: 'Highest Seat' },
  { key: 'LS', value: 'lowest seat', text: 'Lowest Seat' },
];
class modalListTransportationUnits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formFilter: [],
      activePage: 1,
      activeSort: '',
      searchName: '',
    };
  }
  componentDidUpdate = () => {
    const {
      transportationTypesList,
      transportationSeatTypeList,
      transportationProfileCatagories,
      getMasterReferenceStatus,
    } = this.props;
    if (getMasterReferenceStatus === 'success') {
      let data = [
        {
          name: 'Transportation Unit Rate',
          data: this.showOptions(
            transportationProfileCatagories
              ? transportationProfileCatagories
              : []
          ),
        },
        {
          name: 'Transportation Type',
          data: this.showOptions(
            transportationTypesList ? transportationTypesList : []
          ),
        },
        {
          name: 'Capacities',
          data: this.showOptionsCapacities(
            transportationSeatTypeList ? transportationSeatTypeList : []
          ),
        },
      ];
      this.setState({
        ...this.state,
        formFilter: data,
      });
      this.props.resetMasterDataReferenceAction();
    }
    // if (
    //   this.props.transportationProfileCategoryStatus === 'success' &&
    //   this.props.transportationTypeStatus === 'success' &&
    //   this.props.transportationSeatTypeStatus === 'success'
    // ) {
    //   let data = [
    //     {
    //       name: 'Transportation Unit Rate',
    //       data: this.showOptions(this.props.listTransportationProfileCategory),
    //     },
    //     {
    //       name: 'Transportation Type',
    //       data: this.showOptions(this.props.listTransportationType),
    //     },
    //     {
    //       name: 'Capacities',
    //       data: this.showOptionsCapacities(
    //         this.props.listTransportationSeatType
    //       ),
    //     },
    //   ];
    //   this.setState({
    //     ...this.state,
    //     formFilter: data,
    //   });
    //   this.props.resetTransportationAction();
    // }
  };
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    name: PropTypes.string,
    from: PropTypes.string,
    to: PropTypes.string,
    date: PropTypes.string,
    resetStatusTransportation: PropTypes.func,
    transportationProfileCategoryStatus: PropTypes.string,
    listTransportationProfileCategory: PropTypes.array,
    listTransportationType: PropTypes.array,
    transportationTypeStatus: PropTypes.string,
    listTransportationSeatType: PropTypes.array,
    transportationSeatTypeStatus: PropTypes.string,
    listTransportationUnitsFilter: PropTypes.array,
    getTransportationUnitByFilterAction: PropTypes.func,
    loading: PropTypes.bool,
    loadingTransportationUnitsFilter: PropTypes.bool,
    setTransportItem: PropTypes.func,
    resetTransportationAction: PropTypes.func,
    transportationTypesList: PropTypes.array,
    transportationSeatTypeList: PropTypes.array,
    transportationProfileCatagories: PropTypes.array,
    getMasterReferenceStatus: PropTypes.string,
    resetMasterDataReferenceAction: PropTypes.func,
    isThereActivity: PropTypes.bool,
  };
  showOptions = data => {
    return data.map(e => {
      return { key: e.Id, text: e.Name, checked: false };
    });
  };
  showOptionsCapacities = data => {
    let accumulator = 1;
    return data.map(data => {
      let options = {
        key: data.Id,
        text: `${accumulator} to ${data.Name} Passenggers`,
        value: data.Name,
        checked: false,
      };
      accumulator = data.Name;
      return options;
    });
  };
  // filterRestaurant = (data, formFilter) => {
  //   let dataFilter, indexForm;
  //   return data.filter(data => {
  //     let { SpecialityId } = data;
  //     dataFilter = true;
  //     indexForm = formFilter.findIndex(e => e.name === 'Restaurant Speciality');
  //     if (formFilter[indexForm].data.some(e => e.checked) && SpecialityId) {
  //       dataFilter =
  //         dataFilter &&
  //         formFilter[indexForm].data.findIndex(
  //           e => e.key === SpecialityId.toLowerCase() && e.checked
  //         ) !== -1;
  //     }
  //     return dataFilter;
  //   });
  // };
  filteredData = formFilter => {
    let RatingId, typeId, seatTypeId, data;
    let { from, to, date } = this.props;
    formFilter.map(filter =>
      filter.name === 'Transportation Unit Rate'
        ? (RatingId = filter.data
            .filter(e => e.checked)
            .map(e => e.key)
            .join(','))
        : filter.name === 'Transportation Type'
        ? (typeId = filter.data
            .filter(e => e.checked)
            .map(e => e.key)
            .join(','))
        : (seatTypeId = filter.data
            .filter(e => e.checked)
            .map(e => e.value)
            .join(','))
    );
    data = {
      fromCity: from,
      toCities: to,
      RatingId,
      typeId,
      seatTypeId,
      requestedDate: date,
    };
    return this.props.getTransportationUnitByFilterAction(data);
  };
  handleChangeCheckbox = (e, { name, value }) => {
    let dataList = this.state.formFilter;
    let indexForm = dataList.findIndex(e => e.name === name);
    let indexData = dataList[indexForm].data.findIndex(e => e.key === value);
    dataList[indexForm].data[indexData].checked = !dataList[indexForm].data[
      indexData
    ].checked;
    this.setState({ ...this.state, formFilter: dataList });
    this.filteredData(this.state.formFilter);
  };
  handleResetFilter = () => {
    let { formFilter } = this.state;
    formFilter = formFilter.map(filter =>
      filter.data.some(e => e.checked)
        ? { name: filter.name, data: this.resetFilter(filter.data) }
        : filter
    );
    this.setState({ ...this.state, formFilter });
    this.filteredData(formFilter);
  };
  resetFilter = listData => {
    return listData.map(data => {
      return {
        key: data.key,
        text: data.text,
        checked: false,
        desc: data.desc,
      };
    });
  };
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });

  filteredByName = (listTransportation, searchName) => {
    return listTransportation.filter(
      transport =>
        transport.Name.toLowerCase().indexOf(searchName.toLowerCase()) !== -1
    );
  };
  compare = (a, b) => {
    let comparison = 0;

    if (a.TransportationSeatTypeId > b.TransportationSeatTypeId) {
      comparison = 1;
    } else if (b.TransportationSeatTypeId > a.TransportationSeatTypeId) {
      comparison = -1;
    }
    return comparison;
  };
  sortData = (listTransport, type) => {
    return type === 'lowest seat'
      ? listTransport.sort(this.compare)
      : type === 'highest seat' && listTransport.sort(this.compare).reverse();
  };
  filterTransfer = data => {
    let { isThereActivity } = this.props;
    return isThereActivity
      ? data.filter(
          item =>
            item.TransportationItemServiceTypes.filter(
              item => item.ServiceType.search('Transfer') === -1
            ).length > 0
        )
      : data;
  };
  render() {
    let {
      open,
      onClose,
      name,
      from,
      to,
      loading,
      loadingTransportationUnitsFilter,
      listTransportationUnitsFilter,
      setTransportItem,
      isThereActivity,
    } = this.props;
    let { formFilter, activePage, activeSort, searchName } = this.state;
    listTransportationUnitsFilter = this.filterTransfer(
      listTransportationUnitsFilter
    );
    listTransportationUnitsFilter = this.filteredByName(
      listTransportationUnitsFilter,
      searchName
    );
    listTransportationUnitsFilter = activeSort
      ? this.sortData(listTransportationUnitsFilter, activeSort)
      : listTransportationUnitsFilter;
    return (
      <Modal open={open} onClose={onClose} size="large" closeOnDimmerClick>
        <Modal.Header style={styles.backgroundModal}>
          {name} <ButtonClose onClose={onClose} />
        </Modal.Header>
        <Modal.Content style={styles.backgroundModal}>
          <Modal.Description>
            <Grid>
              <Grid.Column width={5}>
                <Header as="h5">
                  Showing {listTransportationUnitsFilter.length} Transportation
                </Header>
                <Filter
                  loading={loading}
                  formFilter={formFilter}
                  handleChange={this.handleChangeCheckbox}
                  resetFilter={this.handleResetFilter}
                />
              </Grid.Column>
              <Grid.Column width={11}>
                <ListCard
                  from={from}
                  to={to}
                  data={listTransportationUnitsFilter}
                  loading={loadingTransportationUnitsFilter}
                  //loading={loading}
                  activePage={activePage}
                  pageChange={this.pageChange}
                  activeSort={activeSort}
                  options={options}
                  searchName={searchName}
                  handleChange={this.handleChange}
                  setTransportItem={setTransportItem}
                  onClose={onClose}
                  isThereActivity={isThereActivity}
                />
              </Grid.Column>
            </Grid>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}
const mapStateToProps = state => {
  return {
    listTransportationUnitsFilter:
      state.TransportationStore.transportationByFromToDate,
    loading: state.TransportationStore.loading,
    listTransportationUnitsFilterStatus:
      state.TransportationStore.getTrasnportationByFromToDateStatus,
    loadingTransportationUnitsFilter: state.TransportationStore.loadingFilter,
    getMasterReferenceStatus:
      state.MasterDataReferenceStore.getMasterReferenceStatus,
    // transportationProfileCategoryStatus:
    //   state.transportation.getTransportationProfileCategoryStatus,
    // listTransportationProfileCategory:
    //   state.transportation.transportationProfileCategories,
    // listTransportationType: state.transportation.transportationTypes,
    // transportationTypeStatus: state.transportation.getTransportationTypeStatus,
    // listTransportationSeatType: state.transportation.transportationSeatTypes,
    // transportationSeatTypeStatus:
    //   state.transportation.getTransportationSeatTypeStatus,
    // listTransportationUnitsFilter:
    //   state.transportation.transportationUnitsFilter,
    // loading: state.transportation.loading,
    // loadingTransportationUnitsFilter:
    //   state.transportation.loadingTransportationUnitsFilter,
  };
};
export default connect(mapStateToProps, {
  getTransportationUnitByFilterAction,
  resetTransportationAction,
  resetMasterDataReferenceAction,
})(modalListTransportationUnits);
