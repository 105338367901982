import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Checkbox } from 'semantic-ui-react';
import {
  TexfieldWithDropdownFluidOnPage,
  TextAreaFluidOnPage,
  TextFieldFluidOnPage,
} from '../../../../../components/common/fieldWithError';
import { StylesTextfieldWithError } from '../../../../../components/common/styleCommon';

const GeneralInformationAccommodationItem = props => {
  return (
    <div>
      <Grid columns="equal">
        <Grid.Row stretched>
          <Grid.Column>
            <Header as="h4" content="General Information" />
            <TexfieldWithDropdownFluidOnPage
              placeholder1="Item Name"
              placeholder2="Item Type"
              name1="Desc"
              name2="AccommodationItemTypeId"
              title1="Item Name"
              title2="Item Type"
              data={props.accomodationItem.Desc}
              valueDropDown={props.accomodationItem.AccommodationItemTypeId}
              option={props.itemTypeData}
              handleChange={props.handleChange}
              handleChangeDropdown={props.handleChange}
              requiredInput={true}
              requiredDropdown={true}
              styleDropdown={StylesTextfieldWithError.doubleTextFieldRight}
              styleInput={StylesTextfieldWithError.doubleTextField}
              type="Text"
              error2={props.errors.Desc}
              error1={props.errors.AccommodationItemTypeId}
              disable={props.disablePage}
            />
            <TextAreaFluidOnPage
              placeholder="Description"
              name="Explanation"
              handleChange={props.handleChange}
              data={props.accomodationItem.Explanation}
              title="Description"
              type="text "
              required={true}
              style={StylesTextfieldWithError.textAreaOnFluid}
              errors={props.errors.Description}
              disable={props.disablePage}
            />
            <TextFieldFluidOnPage
              name="ExtraBedCapacity"
              data={props.accomodationItem.ExtraBedCapacity}
              handleChange={props.handleChange}
              title="Extra Bed Capacity"
              type="text"
              required={true}
              style={{ width: '16em' }}
              disable={props.disablePage}
              errors={props.errors.ExtraBedCapacity}
            />
            <Checkbox
              label="Promotion"
              name="IsPromo"
              onChange={props.onChangeCheckbox}
              checked={props.accomodationItem.IsPromo}
              style={{
                marginTop: '-3em',
                marginLeft: '17em',
              }}
              readOnly={props.disablePage}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
GeneralInformationAccommodationItem.propTypes = {
  itemTypeData: PropTypes.array,
  accomodationItem: PropTypes.shape({
    Desc: PropTypes.string,
    AccommodationItemTypeId: PropTypes.string,
    ExtraBedCapacity: PropTypes.number,
    MinDays: PropTypes.number,
    IsPromo: PropTypes.bool,
    Explanation: PropTypes.string,
  }),
  handleChange: PropTypes.func,
  onChangeCheckbox: PropTypes.func,
  errors: PropTypes.shape({
    Desc: PropTypes.string,
    AccommodationItemTypeId: PropTypes.string,
    ExtraBedCapacity: PropTypes.string,
    MinDays: PropTypes.string,
    Description: PropTypes.string,
  }),
  disablePage: PropTypes.bool,
};
export default GeneralInformationAccommodationItem;
