const PRICE = {
  MinimumPax: 0,
  SingleRoomPrice: 0,
  SharingRoomPrice: 0,
  AdultExtraBedPrice: 0,
  SharingBedPrice: 0,
  NoBedPrice: 0,
  ChildExtraBedPrice: 0,
  CurrencyId: '',
  IsAdd: true,
  Cost: {
    SingleRoomPrice: 0,
    SharingRoomPrice: 0,
    AdultExtraBedPrice: 0,
    SharingBedPrice: 0,
    NoBedPrice: 0,
    ChildExtraBedPrice: 0,
  },
};
const ERRORS_PRICE = {
  MinimumPax: { error: false, message: '' },
  CurrencyId: { error: false, message: '' },
  SingleRoomPrice: '',
  SharingRoomPrice: '',
  NoBedPrice: '',
};
const ERRORS_COST = {
  SingleRoomPrice: '',
  SharingRoomPrice: '',
  NoBedPrice: '',
};

export { PRICE, ERRORS_PRICE, ERRORS_COST };
