import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ModalAlert from '../../components/modal/modalAlert';
import Loader from '../../components/loader';
import { Dimmer } from 'semantic-ui-react';
import MasterDataReference from './components/UploadMasterDataReferenceComponent';
import {
  getMasterReferenceAction,
  resetMasterDataReferenceAction,
  getCancellationReasonByTypeReferenceAction,
  resetCancellationReasonByTypeReferenceAction,
} from '../shared/actions/uploadMasterDataReferenceAction';
import { getCountryAction } from './../shared/actions/countryAction';
import {
  deleteMasterReferenceAction,
  postMasterReferenceAction,
  putMasterReferenceAction,
  resetMasterDataReferencePageAction,
} from './masterDataReferenceAction';
import {
  HeaderUploadMasterData,
  HeaderUploadMasterDataWithDescription,
  HeaderUploadMasterDataCustomEnumeration,
  HeaderUploadMasterDataIdentityTypeConfiguration,
  HeaderUploadMasterDataLanguage,
  HeaderUploadMasterDataWithDuplicate,
} from '../../components/table/headerTabel';
import ModalConfirm from '../../components/modal/modalConfirmationTwoButton';
import uploadMasterDataField from './constant/fieldToChange';
import FilterData from '../../scripts/filterDataTable';
import options from '../../scripts/convertOption';
class UploadMasterDataReferencePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      masterDataList: [],
      headerTable: [],
      ViewListBy: 0,
      modalAlert: false,
      headerAlert: '',
      contentAlert: '',
      loadingMasterDataReference: true,
      failedMasterDataReference: false,
      openModalConfirmation: false,
      searchInput: '',
      activePage: 1,
      openModalConfirm: false,
      deletedId: '',
      showCancellationReason: false,
      cancellationReasonType: 'Travel_agent',
    };
  }
  static propTypes = {
    errorsMessage: PropTypes.string,
    deleteMasterReferenceStatus: PropTypes.string,
    putMasterReferenceStatus: PropTypes.string,
    postMasterReferenceStatus: PropTypes.string,
    getMasterReferenceStatus: PropTypes.string,
    getMasterReferenceList: PropTypes.array,
    getMasterReferenceAction: PropTypes.func,
    deleteMasterReferenceAction: PropTypes.func,
    postMasterReferenceAction: PropTypes.func,
    putMasterReferenceAction: PropTypes.func,
    resetMasterDataReferenceAction: PropTypes.func,
    resetMasterDataReferencePageAction: PropTypes.func,
    loadingMasterData: PropTypes.bool,
    errorsMasterData: PropTypes.string,
    loadingMasterDataPage: PropTypes.bool,
    getCancellationReasonByTypeReferenceAction: PropTypes.func,
    getCountryAction: PropTypes.func,
    listCountry: PropTypes.array,
    loadingCountry: PropTypes.bool,
  };
  componentDidMount() {
    this.setState({
      loadingMasterDataReference: false,
    });
  }
  componentDidUpdate = () => {
    if (this.props.getMasterReferenceStatus === 'success') {
      this.setState({
        masterDataList: this.props.getMasterReferenceList.map(type => {
          return { ...type, disabled: true };
        }),
        loadingMasterDataReference: false,
      });
      this.props.resetMasterDataReferenceAction();
      this.props.resetMasterDataReferencePageAction();
    } else if (this.props.getMasterReferenceStatus === 'failed') {
      this.setState({
        failedMasterDataReference: true,
        loadingMasterDataReference: false,
        headerAlert: 'Failed',
        contentAlert:
          'Please check your internet connection and Refresh Page [Ctrl+R]',
      });
      this.props.resetMasterDataReferenceAction();
      this.props.resetMasterDataReferencePageAction();
    }

    if (this.props.postMasterReferenceStatus === 'success') {
      this.setState({
        headerAlert: 'Success Saving Reference',
        contentAlert: 'Saving Reference',
        openModalConfirmation: true,
      });
      this.props.resetMasterDataReferenceAction();
      this.props.resetMasterDataReferencePageAction();
      this.props.getMasterReferenceAction(this.state.ViewListBy);
      if (this.state.ViewListBy === 'CancellationReasons') {
        this.props.getCancellationReasonByTypeReferenceAction(
          this.state.cancellationReasonType
        );
      }
    } else if (this.props.postMasterReferenceStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert:
          this.props.errorsMasterData.global === undefined
            ? 'Failed Saving Reference'
            : this.props.errorsMasterData.global,
        openModalConfirmation: true,
      });
      this.props.resetMasterDataReferenceAction();
      this.props.resetMasterDataReferencePageAction();
    }
    //================================================
    if (this.props.putMasterReferenceStatus === 'success') {
      this.setState({
        headerAlert: 'Success Edit Reference',
        contentAlert: 'Edit Reference',
        openModalConfirmation: true,
      });
      this.props.resetMasterDataReferenceAction();
      this.props.resetMasterDataReferencePageAction();
      this.props.getMasterReferenceAction(this.state.ViewListBy);
      if (this.state.ViewListBy === 'CancellationReasons') {
        this.props.getCancellationReasonByTypeReferenceAction(
          this.state.cancellationReasonType
        );
      }
    } else if (this.props.putMasterReferenceStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: 'Failed Edit Reference',
        openModalConfirmation: true,
      });
      this.props.resetMasterDataReferenceAction();
      this.props.resetMasterDataReferencePageAction();
    }
    if (this.props.deleteMasterReferenceStatus === 'success') {
      this.setState({
        headerAlert: 'Success Delete Reference',
        contentAlert: 'Delete Reference',
        openModalConfirmation: true,
      });
      this.props.resetMasterDataReferenceAction();
      this.props.resetMasterDataReferencePageAction();
      this.props.getMasterReferenceAction(this.state.ViewListBy);
      if (this.state.ViewListBy === 'CancellationReasons') {
        this.props.getCancellationReasonByTypeReferenceAction(
          this.state.cancellationReasonType
        );
      }
    } else if (this.props.deleteMasterReferenceStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: 'Failed Delete Reference',
        openModalConfirmation: true,
      });
      this.props.resetMasterDataReferenceAction();
      this.props.resetMasterDataReferencePageAction();
    }
  };

  handleClose = () =>
    this.setState({
      modalAlert: false,
      failedMasterDataReference: false,
      openModalConfirmation: false,
    });
  onChange = (e, { value }) => {
    let matching = uploadMasterDataField.find(values => values === value);
    if (value === matching) {
      this.setState({
        ViewListBy: value,
        triggerTabelCell: 1,
        masterDataList: [],
        loadingMasterDataReference: true,
        headerTable: HeaderUploadMasterDataWithDescription,
        searchInput: '',
        activePage: 1,
        maxLength: 5,
        showCancellationReason: false,
      });
    } else if (value === 'IdentityTypeConfigurations') {
      this.props.getCountryAction();
      this.setState({
        ViewListBy: value,
        triggerTabelCell: 2,
        masterDataList: [],
        loadingMasterDataReference: true,
        headerTable: HeaderUploadMasterDataIdentityTypeConfiguration,
        searchInput: '',
        activePage: 1,
        maxLength: '',
        showCancellationReason: false,
      });
    } else if (value === 'CustomEnums') {
      this.setState({
        ViewListBy: value,
        triggerTabelCell: 3,
        masterDataList: [],
        loadingMasterDataReference: true,
        headerTable: HeaderUploadMasterDataCustomEnumeration,
        searchInput: '',
        activePage: 1,
        maxLength: '',
        showCancellationReason: false,
      });
    } else if (value === 'Languages') {
      this.setState({
        ViewListBy: value,
        triggerTabelCell: 4,
        masterDataList: [],
        loadingMasterDataReference: true,
        headerTable: HeaderUploadMasterDataLanguage,
        searchInput: '',
        activePage: 1,
        maxLength: 5,
        showCancellationReason: false,
      });
    } else if (value === 0) {
      this.setState({
        ViewListBy: value,
        masterDataList: [],
        headerTable: [],
        showCancellationReason: false,
      });
    } else if (value === 'AccommodationItemTypes') {
      this.setState({
        ViewListBy: value,
        triggerTabelCell: 1,
        masterDataList: [],
        loadingMasterDataReference: true,
        headerTable: ['ID', 'Name', 'Capacities', 'Action'],
        searchInput: '',
        activePage: 1,
        showCancellationReason: false,
      });
    } else if (value === 'Positions') {
      this.setState({
        ViewListBy: value,
        triggerTabelCell: 6,
        masterDataList: [],
        loadingMasterDataReference: true,
        headerTable: ['Job Code', 'Job Title', 'Job Position Level', 'Action'],
        searchInput: '',
        activePage: 1,
        showCancellationReason: false,
      });
    } else if (value === 'CancellationReasons') {
      this.setState({
        ViewListBy: value,
        triggerTabelCell: 7,
        masterDataList: [],
        loadingMasterDataReference: true,
        headerTable: HeaderUploadMasterData,
        searchInput: '',
        activePage: 1,
        showCancellationReason: true,
        cancellationReasonType: 'Travel_agent',
      });
      value = 'CancellationReasons/ByType/Travel_agent';
    } else if (value === 'AdditionalServiceType') {
      this.setState({
        ViewListBy: value,
        triggerTabelCell: 8,
        masterDataList: [],
        loadingMasterDataReference: true,
        headerTable: HeaderUploadMasterDataWithDuplicate,
        searchInput: '',
        activePage: 1,
        showCancellationReason: false,
      });
    }
    // if (value !== 'CancellationReasons') {
    //   this.setState({
    //     ...this.state,
    //     showCancellationReason: false,
    //   });
    //}
    else {
      this.setState({
        ViewListBy: value,
        triggerTabelCell: 5,
        masterDataList: [],
        loadingMasterDataReference: true,
        headerTable: HeaderUploadMasterData,
        searchInput: '',
        activePage: 1,
        maxLength: '',
        showCancellationReason: false,
      });
    }
    if (value !== 0) {
      this.props.getMasterReferenceAction(value);
    }
  };

  handleCancellationReasonType = (e, { value }) => {
    this.setState({ cancellationReasonType: value, activePage: 1 });
    this.props.getCancellationReasonByTypeReferenceAction(value);
  };

  addMasterReference = () => {
    let masterDataList = this.state.masterDataList;
    // let seq = Math.max.apply(
    //   Math,
    //   masterDataList.map(o => {
    //     return o.Sequance ? o.Sequance : 0;
    //   })
    // );
    let seq = Math.max.apply(
      Math,
      masterDataList.map(o => {
        return o.Id ? o.Id : 0;
      })
    );
    seq = isNaN(seq) ? 0 : seq + 1;
    // let data = {
    //   Id: `NEWROW${seq + 1}`,
    //   Name: 'New Row',
    //   EnglishName: 'New Row',
    //   LocalName: 'New Row',
    //   Description: 'New Row',
    //   Sequance: seq + 1,
    //   Status: 'POST',
    //   disabled: false,
    // };
    let data = {
      // Id: `NEWROW${seq + 1}`,
      Id: `${seq}`,
      Name: '',
      EnglishName: '',
      LocalName: '',
      Description: '',
      IdentityType: '',
      Capacity: '',
      Category: '',
      Value: '',
      CountryId: '',
      // Code: `NEWROW${seq + 1}`,
      Code: `${seq}`,
      Level: '',
      // Sequance: seq + 1,
      Sequance: seq,
      Status: 'POST',
      disabled: false,
      CancellationReasonType: this.state.cancellationReasonType,
      TypeName: '',
      IsRestrictedCountry: false,
    };
    masterDataList.unshift(data);
    this.setState({ masterDataList: masterDataList });
  };
  handleChangeMasterData = (e, { value, name }) => {
    let index = name.split(',')[1];
    let newName = name.split(',')[0];
    let { searchInput } = this.state;
    let masterDataList = [...this.state.masterDataList];
    if (searchInput) {
      let filteredMasterData = FilterData(
        masterDataList,
        'MasterRefrence',
        searchInput
      );
      index = masterDataList.findIndex(
        e =>
          JSON.stringify({ ...e }) ===
          JSON.stringify({ ...filteredMasterData[index] })
      );
    }
    masterDataList[index] = {
      ...masterDataList[index],
      [newName]: value,
      PUT: true,
      IsRestrictedCountry:
        newName === 'IsRestrictedCountry'
          ? !masterDataList[index][newName]
          : false,
    };
    this.setState({
      masterDataList: masterDataList,
    });
  };
  deleteDataReference = (e, { id }) => {
    let masterDataList = this.state.masterDataList;
    let obj = masterDataList.find(item => item.Id === id);
    if (obj.Sequance) {
      masterDataList = masterDataList.filter(item => item.Id !== id);
      this.setState({ masterDataList: masterDataList });
    } else {
      this.setState({
        openModalConfirm: true,
        contentAlert: `Are you sure want to delete ${obj.Name ||
          obj.EnglishName ||
          obj.Description}?`,
        deletedId: id,
      });
      // this.props.deleteMasterReferenceAction(this.state.ViewListBy, id);
    }
  };
  closeModalConfirm = () =>
    this.setState({
      openModalConfirm: false,
    });
  deleteData = () => {
    let { ViewListBy, deletedId } = this.state;
    this.props.deleteMasterReferenceAction(ViewListBy, deletedId);
    this.closeModalConfirm();
  };
  saveMasterReference = () => {
    let dataPost = this.state.masterDataList.filter(
      item => item.Status === 'POST'
    );
    let dataPut = this.state.masterDataList.filter(
      item => item.PUT === true && item.Status !== 'POST'
    );
    dataPost.map(post => {
      return this.props.postMasterReferenceAction(this.state.ViewListBy, {
        Id: post.Id,
        Name: post.Name,
        Description: post.Description,
        Category: post.Category,
        Value: post.Value,
        IdentityType: post.IdentityType,
        CountryId: post.CountryId,
        Capacity: post.Description,
        Code: post.Code,
        Level: post.Level,
        EnglishName: post.EnglishName,
        LocalName: post.LocalName,
        CancellationReasonType: post.CancellationReasonType,
        TypeName: post.TypeName,
        IsRestrictedCountry: post.IsRestrictedCountry,
      });
    });
    dataPut.map(post => {
      return this.props.putMasterReferenceAction(
        this.state.ViewListBy,
        post.Id,
        {
          Id: post.Id,
          Name: post.Name,
          Description: post.Description,
          Category: post.Category,
          Value: post.Value,
          IdentityType: post.IdentityType,
          CountryId: post.CountryId,
          Capacity: post.Description,
          Code: post.Code,
          Level: post.Level,
          EnglishName: post.EnglishName,
          LocalName: post.LocalName,
          CancellationReasonType: post.CancellationReasonType,
          TypeName: post.TypeName,
          IsRestrictedCountry: post.IsRestrictedCountry,
        }
      );
    });
  };
  handleChangeSearch = value =>
    this.setState({ searchInput: value, activePage: 1 });
  pageChange = activePage => this.setState({ activePage });
  render() {
    let {
      loadingMasterDataReference,
      failedMasterDataReference,
      openModalConfirmation,
    } = this.state;
    let loading =
      loadingMasterDataReference ||
      this.props.loadingMasterData ||
      this.props.loadingMasterDataPage;
    let openModal = failedMasterDataReference || openModalConfirmation;
    return (
      <div>
        <Dimmer.Dimmable blurring dimmed={loading}>
          <Loader loading={loading} />
          <MasterDataReference
            handleChange={this.onChange}
            listData={this.state.masterDataList}
            data={this.state.ViewListBy}
            addMasterReference={this.addMasterReference}
            handleChangeMasterData={this.handleChangeMasterData}
            deleteDataReference={this.deleteDataReference}
            saveMasterReference={this.saveMasterReference}
            headerTableMasterData={this.state.headerTable}
            triggerTabelCell={this.state.triggerTabelCell}
            getMasterReferenceStatus={this.props.getMasterReferenceStatus}
            handleChangeSearch={this.handleChangeSearch}
            searchInput={this.state.searchInput}
            activePage={this.state.activePage}
            pageChange={this.pageChange}
            maxLength={this.state.maxLength}
            showCancellationReason={this.state.showCancellationReason}
            handleCancellationReasonType={this.handleCancellationReasonType}
            cancellationReasonType={this.state.cancellationReasonType}
            listCountry={this.props.listCountry}
            loadingCountry={this.props.loadingCountry}
          />
          <ModalConfirm
            message={this.state.contentAlert}
            confirm={this.deleteData}
            openModal={this.state.openModalConfirm}
            close={this.closeModalConfirm}
            size="mini"
          />
          <ModalAlert
            openModal={openModal}
            handleClose={this.handleClose}
            header={this.state.headerAlert}
            content={this.state.contentAlert}
            nameButton="OK"
          />
        </Dimmer.Dimmable>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    getAccomodationFacilitiesList:
      state.MasterDataReferenceStore.getAccomodationFacilitiesList,
    getAccomodationTypeList:
      state.MasterDataReferenceStore.getAccomodationTypeList,
    getAccomodationLocationList:
      state.MasterDataReferenceStore.getAccomodationLocationList,
    getAccomodationItemTypeList:
      state.MasterDataReferenceStore.getAccomodationItemTypeList,
    getAccomodationRatingList:
      state.MasterDataReferenceStore.getAccomodationRatingList,
    getExcursionCategoriesList:
      state.MasterDataReferenceStore.getExcursionCategoriesList,
    getCurrenciesList: state.MasterDataReferenceStore.getCurrenciesList,
    getAccomodationTypeListStatus:
      state.MasterDataReferenceStore.getAccomodationTypeListStatus,
    getAccomodationFacilitiesListStatus:
      state.MasterDataReferenceStore.getAccomodationFacilitiesListStatus,
    getAccomodationLocationListStatus:
      state.MasterDataReferenceStore.getAccomodationLocationListStatus,
    getAccomodationRatingListStatus:
      state.MasterDataReferenceStore.getAccomodationRatingListStatus,
    getAccomodationItemTypeListStatus:
      state.MasterDataReferenceStore.getAccomodationItemTypeListStatus,
    getExcursionCategoriesListStatus:
      state.MasterDataReferenceStore.getExcursionCategoriesListStatus,
    getCurrenciesListStatus:
      state.MasterDataReferenceStore.getCurrenciesListStatus,
    errorsMessage: state.MasterDataReferenceStore.errors.global,
    getMasterReferenceList:
      state.MasterDataReferenceStore.getMasterReferenceList,
    getMasterReferenceStatus:
      state.MasterDataReferenceStore.getMasterReferenceStatus,
    deleteMasterReferenceStatus:
      state.MasterDataReferencePageStore.deleteMasterReferenceStatus,
    postMasterReferenceStatus:
      state.MasterDataReferencePageStore.postMasterReferenceStatus,
    putMasterReferenceStatus:
      state.MasterDataReferencePageStore.putMasterReferenceStatus,
    loadingMasterData: state.MasterDataReferenceStore.loading,
    errorsMasterData: state.MasterDataReferenceStore.errors,
    loadingMasterDataPage: state.MasterDataReferencePageStore.loading,
    listCountry: state.CountryStore.getCountry.map(e =>
      options(e.Id, e.Id, e.Id)
    ),
    loadingCountry: state.CountryStore.loading,
  };
}
export default connect(mapStateToProps, {
  getMasterReferenceAction,
  getCancellationReasonByTypeReferenceAction,
  deleteMasterReferenceAction,
  postMasterReferenceAction,
  putMasterReferenceAction,
  resetMasterDataReferenceAction,
  resetMasterDataReferencePageAction,
  resetCancellationReasonByTypeReferenceAction,
  getCountryAction,
})(UploadMasterDataReferencePage);
