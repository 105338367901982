//GET HISTORY_TRANSACTION
const GET_HISTORY_TRANSACTION_BY_CODE = 'GET_HISTORY_TRANSACTION_BY_CODE';
const GET_HISTORY_TRANSACTION_BY_CODE_FULFILLED =
  'GET_HISTORY_TRANSACTION_BY_CODE_FULFILLED';
const GET_HISTORY_TRANSACTION_BY_CODE_PENDING =
  'GET_HISTORY_TRANSACTION_BY_CODE_PENDING';
const GET_HISTORY_TRANSACTION_BY_CODE_REJECTED =
  'GET_HISTORY_TRANSACTION_BY_CODE_REJECTED';

//GET SUPERVISOR
const GET_SUPERVISOR_BY_COMPANY_CODE_POSITION_ID =
  'GET_SUPERVISOR_BY_COMPANY_CODE';
const GET_SUPERVISOR_BY_COMPANY_CODE_POSITION_ID_FULFILLED =
  'GET_SUPERVISOR_BY_COMPANY_CODE_FULFILLED';
const GET_SUPERVISOR_BY_COMPANY_CODE_POSITION_ID_PENDING =
  'GET_SUPERVISOR_BY_COMPANY_CODE_PENDING';
const GET_SUPERVISOR_BY_COMPANY_CODE_POSITION_ID_REJECTED =
  'GET_SUPERVISOR_BY_COMPANY_CODE_REJECTED';

const RESET_STATUS_CUSTOMER_PROFILE_PAGE = 'RESET_STATUS_CUSTOMER_PROFILE_PAGE';

export {
  //GET HISTORY TRANSACTION
  GET_HISTORY_TRANSACTION_BY_CODE,
  GET_HISTORY_TRANSACTION_BY_CODE_FULFILLED,
  GET_HISTORY_TRANSACTION_BY_CODE_PENDING,
  GET_HISTORY_TRANSACTION_BY_CODE_REJECTED,
  GET_SUPERVISOR_BY_COMPANY_CODE_POSITION_ID,
  GET_SUPERVISOR_BY_COMPANY_CODE_POSITION_ID_FULFILLED,
  GET_SUPERVISOR_BY_COMPANY_CODE_POSITION_ID_PENDING,
  GET_SUPERVISOR_BY_COMPANY_CODE_POSITION_ID_REJECTED,
  RESET_STATUS_CUSTOMER_PROFILE_PAGE,
};
