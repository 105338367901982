import apiClient from '../apiClient';
import { GET_PLACE_LIST_URL, GET_PLACE_BY_PLACE_TYPE_ID_URL } from '../apiUrl';

const getPlaceListApi = () => {
  return apiClient.get(GET_PLACE_LIST_URL);
};
const getPlaceByPlaceTypeIdApi = Id => {
  return apiClient.get(`${GET_PLACE_BY_PLACE_TYPE_ID_URL}${Id}`);
};
export { getPlaceListApi, getPlaceByPlaceTypeIdApi };
