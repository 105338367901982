export const fieldToValidateAdditionalCost = [
  'Currency',
  'OriginalCity',
  'DestinationCity',
  'TransportationType',
  'OriginalCountry',
  'DestinationCountry',
];

export const fieldToValidateCostPrice = [
  'TransportationAdditionalCostName',
  'TransportationAdditionalCostType',
  'Amount',
  'Description',
];
