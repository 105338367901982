import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Segment, Header, Grid } from 'semantic-ui-react';
import GuestTable from './tableGuestDetail/GuestTable';
import HeaderTable from './tableGuestDetail/HeaderTable';
import useApiCall from '../hooks/useApiCall';
import getCountryApi from '../../../api/country/getCountryApi';
import {
  getGuestTitleTypeApi,
  getIdentityTypeApi,
} from '../../../api/guestApi';
import { Collapse } from 'react-collapse';
import ButtonCollapse from '../../../components/button/collapseButton';
import style from '../styles';
const SegmentGuestDetail = props => {
  //=======================initial=======================
  const { data } = props;
  const [countryLoading, countryList] = useApiCall(getCountryApi);
  const [identityTypeLoading, identityTypeList] = useApiCall(
    getIdentityTypeApi
  );
  const [guestTitleLoading, guestTitleList] = useApiCall(getGuestTitleTypeApi);
  const [openCollapse, setOpenCollapse] = useState(false);

  const handleCollapse = () => {
    setOpenCollapse(!openCollapse);
  };
  return (
    data && (
      <Segment
        loading={countryLoading || identityTypeLoading || guestTitleLoading}
        style={style.paddingSegmentGuest}
      >
        <Grid>
          <Grid.Row>
            <Grid.Column width={15}>
              <Header
                as="h3"
                content={`Guest List (Total Pax : ${data.TotalGuests} Pax)`}
              />
            </Grid.Column>
            <Grid.Column>
              {data.TotalGuests !== 1 ? (
                <ButtonCollapse
                  isCollapse={openCollapse}
                  handleCollapse={handleCollapse}
                />
              ) : (
                ''
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Header as="h4" content={`Adult (${data.GuestAdults.length} Pax)`} />
        <HeaderTable />
        <GuestTable
          guests={data.GuestAdults.slice(0, 1)}
          guestType="Adults"
          countryList={countryList}
          identityTypeList={identityTypeList}
          guestTitleList={guestTitleList}
        />
        <Collapse isOpened={openCollapse} style={style.marginTop1em}>
          <GuestTable
            guests={data.GuestAdults.slice(1)}
            guestType="Adults"
            countryList={countryList}
            identityTypeList={identityTypeList}
            guestTitleList={guestTitleList}
          />
          {data.GuestChildrens.length ? (
            <React.Fragment>
              <Header
                as="h4"
                content={`Child (${data.GuestChildrens.length} Pax)`}
              />
              <HeaderTable />
              <GuestTable
                guests={data.GuestChildrens}
                guestType="Childrens"
                countryList={countryList}
                identityTypeList={identityTypeList}
                guestTitleList={guestTitleList}
              />
            </React.Fragment>
          ) : (
            ''
          )}
          {data.GuestInfants.length ? (
            <React.Fragment>
              <Header
                as="h4"
                content={`Infant (${data.GuestInfants.length} Pax)`}
              />
              <HeaderTable />
              <GuestTable
                guests={data.GuestInfants}
                guestType="Infants"
                countryList={countryList}
                identityTypeList={identityTypeList}
                guestTitleList={guestTitleList}
              />
            </React.Fragment>
          ) : (
            ''
          )}
        </Collapse>
      </Segment>
    )
  );
};

SegmentGuestDetail.propTypes = {
  data: PropTypes.object,
};

export default React.memo(SegmentGuestDetail);
