import apiClient from './apiClient';
import { GET_LIST_PAYMENT_HISTORY_URL } from './apiUrl';

const getPaymentHistoryApi = (start, end, createStart, createEnd) => {
  return apiClient.get(
    GET_LIST_PAYMENT_HISTORY_URL(start, end, createStart, createEnd)
  );
};

export default getPaymentHistoryApi;
