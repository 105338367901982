import {
  POST_SUPPLIER,
  RESET_SUPPLIER,
  PUT_ACCOMMODATION_SUPPLIER,
} from './constant/actionTypes';
import postSupplierApi from '../../../api/supplier/postSupplierApi';
import putAccommodationSupplierApi from '../../../api/supplier/putAccommodationSupplierApi';

const postSupplierAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_SUPPLIER,
      payload: postSupplierApi(data),
    });
  };
};

const putAccommodationSupplierAction = (companyCode, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_ACCOMMODATION_SUPPLIER,
      payload: putAccommodationSupplierApi(companyCode, data),
    });
  };
};

const resetSupplierAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_SUPPLIER,
    });
  };
};

export {
  putAccommodationSupplierAction,
  postSupplierAction,
  resetSupplierAction,
};
