import apiClient from '../apiClient';
import {
  GET_CUSTOMER_PROFILE_BY_CODE_URL,
  GET_CUSTOMER_PROFILE_BY_CODE_MANAGE_BY_SALES_URL,
} from '../apiUrl';

const getCustomerProfileByCodeApi = Id => {
  return apiClient.get(GET_CUSTOMER_PROFILE_BY_CODE_URL(Id));
};

const getCustomerProfileByCodeManageBySalesApi = Id => {
  return apiClient.get(GET_CUSTOMER_PROFILE_BY_CODE_MANAGE_BY_SALES_URL(Id));
};
export {
  getCustomerProfileByCodeApi,
  getCustomerProfileByCodeManageBySalesApi,
};
