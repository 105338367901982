import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Segment, Grid, Header, Button, Label, Popup } from 'semantic-ui-react';
// import ImageCard from './../card/imageCard';
import styles from './styles';
import stylesModal from './../styles';
// import ImageLightBox from './../../common/imageLightBox';

class headerRoomCard extends Component {
  setRestaurant = () => {
    const { data, setRestaurant } = this.props;
    setRestaurant(data);
  };
  render() {
    let { header, menuClass, category, typeMenu, description, id } = this.props;
    let shortDesc = description ? description.substring(0, 50) : '-';
    let nexDesc = description ? description.substring(51, 52) : '';
    return (
      <Segment.Group horizontal style={stylesModal.segmentNoPadding}>
        {/* <ImageCard images={images} image={image} isPromo={false} size="300px" /> */}
        <Segment style={{ border: 'none' }}>
          <Grid style={{ margin: '3px 3px 0px 3px' }}>
            <Header as="h3">{header}</Header>
            <Grid.Row style={styles.noPaddingTop}>
              <Grid.Column width={16}>
                <Label basic style={stylesModal.label}>
                  {menuClass}
                </Label>
                <Label basic style={stylesModal.label}>
                  {typeMenu}
                </Label>

                {/* <Header as="h5" style={styles.goldColor}>
                {menuClass}
              </Header> */}
                {/* <Header as="h5" style={{ color: '#e6ca6b', marginTop: '0px' }}>
                {category}
              </Header> */}
              </Grid.Column>
              <Grid.Column width={16} style={{ marginTop: '5px' }}>
                <Label basic style={stylesModal.label}>
                  {category}
                </Label>
                {/* <Header as="h5" style={styles.goldColor}>
                {typeMenu}
              </Header> */}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={styles.noPaddingTop}>
              <Grid.Column width="16">
                <Header as="h5" style={{ marginBottom: '3px' }}>
                  Description
                </Header>
                {description ? (
                  <span>
                    {shortDesc}
                    {nexDesc && (
                      <Popup
                        trigger={<span>...</span>}
                        content={description}
                        size="small"
                      />
                    )}
                  </span>
                ) : (
                  <p>-</p>
                )}
              </Grid.Column>
              <Grid.Column
                width="6"
                floated="right"
                style={{ marginTop: '10px' }}
              >
                <Button
                  size="large"
                  //style={stylesModal.buttonGold}
                  value={id}
                  onClick={this.setRestaurant}
                  primary
                >
                  CHOOSE
                </Button>
                {/* <Button style={styles.transparentButton} size="massive">
                <strong>CHOOSE</strong>
              </Button> */}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Segment.Group>
    );
  }
}
headerRoomCard.propTypes = {
  images: PropTypes.array,
  image: PropTypes.string,
  header: PropTypes.string,
  menuClass: PropTypes.string,
  category: PropTypes.string,
  typeMenu: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.number,
  setRestaurant: PropTypes.func,
  data: PropTypes.object,
};
export default headerRoomCard;
