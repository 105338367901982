import { convertDateFormat } from '../../../../../scripts/moment';
const setNewDataTourGuideDetail = () => {
  const data = {
    Id: 0,
    FirstName: '',
    AddressId: '',
    LastName: '',
    NationalityId: '',
    IdentityNbr: '',
    StartedJobAt: convertDateFormat(new Date(), 'YYYY-MM-DD'),
    Address1: '',
    Address2: '',
    Address3: '',
    Landmark: '',
    PostalCode: '',
    AreaId: '',
    RegionCoverages: [],
    TourGuideLanguage: [],
    EmailAddress: '',
  };

  return data;
};

export default setNewDataTourGuideDetail;
