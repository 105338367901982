import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Button, Form } from 'semantic-ui-react';
import LoaderModal from './loaderModal';
import InputWithLabel from '../input/inputWithLabel';
import InputDate from '../input/inputDate';
import options from '../../scripts/convertOption';
import ButtonClose from '../button/buttonCloseModal';
import { convertDateFormat } from './../../scripts/moment';
import { postRoomAllotmentPeriod } from './../../pages/shared/actions/accommodationAction';
// import { postRoomAllotmentPeriod } from './../../actions/accomodationAction';
import ModalAlert from './modalAlert';
let TODAY = new Date();

//import Name from '../components/accomodation/generalInformationAccomodationItem';
class modalSetRoomAllotment extends Component {
  state = {
    beginDate: null,
    finalDate: null,
    accomodationItem: [],
    quantity: null,
    releaseDays: null,
  };
  handleChange = (e, { value, name }) => {
    this.setState({ ...this.state, [name]: value });
  };
  setDate = (date, name) => {
    this.setState({
      ...this.state,
      [name]: convertDateFormat(date, 'YYYY-MM-DD'),
    });
  };
  componentDidUpdate(prevProps) {
    if (prevProps.openModal === false && this.props.openModal === true) {
      this.clearForm();
    }
  }
  clearForm = () => {
    this.setState({
      beginDate: convertDateFormat(TODAY, 'YYYY-MM-DD'),
      finalDate: convertDateFormat(TODAY, 'YYYY-MM-DD'),
      accomodationItem: '',
      quantity: null,
      releaseDays: null,
      header:
        this.props.actionType === 'SETALLOTMENT'
          ? 'Set Room Allotment'
          : 'Set Release Day Special Periode',
    });
  };
  handleSave = () => {
    let {
      accomodationItem,
      beginDate,
      finalDate,
      quantity,
      releaseDays,
      header,
    } = this.state;
    let data = {
      ServiceItemIds: accomodationItem,
      StartDate: beginDate,
      EndDate: finalDate,
    };
    // if (releaseDays) {
    data.ReleaseDays = releaseDays;
    // } else if (quantity) {
    data.Qty = quantity ? parseInt(quantity, 10) : null;
    // }
    this.props
      .postRoomAllotmentPeriod(data)
      .then(async () => {
        await this.props.handleClose();
        this.props.getRoomAllotmentByProfileId();
      })
      .catch(() =>
        this.setState({
          ...this.state,
          modalAlertOpen: true,
          modalAlertMessage: `Failed ${header}`,
        })
      );
  };
  handleModalAlert = () =>
    this.setState({ ...this.state, modalAlertOpen: false });

  render() {
    const { modalAlertOpen, modalAlertMessage, header } = this.state;
    const {
      openModal,
      handleClose,
      actionType,
      accomodationProfile,
      accomodationItem,
      loadingPostRoomAllotmentPeriod,
    } = this.props;
    const optionsItem = accomodationItem.map(item => {
      let promo = item.IsPromo ? ' (Promo Item)' : '';
      return options(
        item.ServiceItemId,
        item.ServiceItemId,
        item.ServiceItemName + promo
      );
    });
    const divStyle = {
      flexDirection: 'row',
      flexWrap: 'wrap',
    };
    return (
      <Modal open={openModal} size="mini">
        <Modal.Header>
          {header}
          <ButtonClose onClose={handleClose} />
        </Modal.Header>
        <Modal.Content>
          <LoaderModal loading={loadingPostRoomAllotmentPeriod} />
          <text style={divStyle}>
            {this.props.actionType === 'SETALLOTMENT'
              ? `Add Room Allotment for `
              : `Add Release Day Special Periode `}
            <text style={{ fontWeight: 'bold' }}>{accomodationProfile}</text>
          </text>
          <Form style={{ marginTop: '10px' }}>
            <Form.Group>
              <Form.Field>
                <InputDate
                  label="From"
                  placeholder="From"
                  value={this.state.beginDate}
                  setDate={this.setDate}
                  labelStyle={{
                    display: 'block',
                    fontWeight: 'bold',
                    marginBottom: '4px',
                  }}
                  name="beginDate"
                />
              </Form.Field>
              <Form.Field>
                <InputDate
                  label="To"
                  placeholder="To"
                  value={this.state.finalDate}
                  setDate={this.setDate}
                  labelStyle={{
                    display: 'block',
                    fontWeight: 'bold',
                    marginBottom: '4px',
                  }}
                  name="finalDate"
                  minDate={this.state.beginDate}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              {actionType === 'SETALLOTMENT' ? (
                <Form.Field width={16}>
                  <InputWithLabel
                    type="number"
                    label="Quantity"
                    placeholder="Quantity Room"
                    fluid={true}
                    value={this.state.quantity}
                    handleChange={this.handleChange}
                    name="quantity"
                  />
                </Form.Field>
              ) : (
                <Form.Field width={16}>
                  <InputWithLabel
                    type="number"
                    label="Release Days"
                    placeholder="Total Day per date"
                    fluid={true}
                    value={this.state.releaseDays}
                    handleChange={this.handleChange}
                    name="releaseDays"
                  />
                </Form.Field>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Field width={16}>
                <InputWithLabel
                  label="Accommodation Item"
                  placeholder="Choose Accommodation Item"
                  type="select"
                  fluid={true}
                  multiple={true}
                  options={optionsItem}
                  value={this.state.accomodationItem}
                  handleChange={this.handleChange}
                  name="accomodationItem"
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button primary content="Save" onClick={this.handleSave}>
            Save
          </Button>
        </Modal.Actions>
        <ModalAlert
          openModal={modalAlertOpen}
          handleClose={this.handleModalAlert}
          header="Failed"
          content={modalAlertMessage}
        />
      </Modal>
    );
  }
}

modalSetRoomAllotment.propTypes = {
  openModal: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  actionType: PropTypes.string,
  accomodationProfile: PropTypes.object,
  accomodationItem: PropTypes.array,
  postRoomAllotmentPeriod: PropTypes.func,
  loadingPostRoomAllotmentPeriod: PropTypes.bool,
  getRoomAllotmentByProfileId: PropTypes.func,
};
function mapStateToProps(state) {
  return {
    loadingPostRoomAllotmentPeriod:
      state.AccomodationStore.loadingPostRoomAllotmentPeriod,
  };
}
export default connect(mapStateToProps, {
  postRoomAllotmentPeriod,
})(modalSetRoomAllotment);
