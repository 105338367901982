import { GET_ADDITIONAL_ITEM_TYPE } from '../../../actions/actionTypes';
import { getAdditionalItemTypeApi } from '../../../api/additionalItemTypeApi';

export const getAdditionalItemTypeAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_ADDITIONAL_ITEM_TYPE,
      payload: getAdditionalItemTypeApi(),
    });
  };
};
