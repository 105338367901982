import * as types from '../actions/actionTypes';

const initialState = {
  loading: false,
  getPublishedPackageList: [],
  publishedPackageStatus: '',
  fixedPackage: {},
  packages: [],
  getFixedPackageStatus: '',
  getPackagesStatus: '',
  activityList: [],
  getActivityListStatus: '',
  tourOperatorProfileList: [],
  getTourOperatorProfileStatus: '',
  durationStore: [],
  durationMap: [],
  drivingData: null,
  getDurationStatus: '',
  getDurationCount: 0,
  errors: {},
  postPackageStatus: '',
  postPackage: {},
  putPackageStatus: '',
  postPublishPackageStatus: '',
  deletePackageStatus: '',
  copyPackageStatus: '',
  copyPackage: {},
  tourOperatorListByCode: [],
  detailTourOperatorById: {},
  getTourOperatorProfileByIdStatus: '',
  deleteTourOperatorStatus: '',
  putTourOperatorStatus: '',
  getFilterSharedPackageStatus: null,
  getFilterSharedPackage: [],
  loadingGetDuration: false,
  downloadBookingTemplate: null,
  loadingGetDownloadBookingTemplate: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PUBLISHED_PACKAGE: {
      return { ...state };
    }
    case types.GET_PUBLISHED_PACKAGE_REJECTED: {
      return {
        ...state,
        errors: { global: action.payload.message },
        loading: false,
        publishedPackageStatus: 'failed',
      };
    }
    case types.GET_PUBLISHED_PACKAGE_PENDING: {
      return { ...state, loading: true };
    }
    case types.GET_PUBLISHED_PACKAGE_FULFILLED: {
      return {
        ...state,
        getPublishedPackageList: action.payload.data,
        loading: false,
        publishedPackageStatus: 'success',
      };
    }
    case types.RESET_ACTIVITY_PACKAGE: {
      return { ...state, getActivityListStatus: '' };
    }
    case types.RESET_STATUS_PACKAGE: {
      return {
        ...state,
        publishedPackageStatus: '',
        getFixedPackageStatus: '',
        getPackagesStatus: '',
        getActivityListStatus: '',
        postPackageStatus: '',
        putPackageStatus: '',
        postPublishPackageStatus: '',
        deletePackageStatus: '',
        copyPackageStatus: '',
        getTourOperatorByCodeStatus: '',
        getFilterSharedPackageStatus: null,
      };
    }
    case types.GET_FIXED_PACKAGE: {
      return { ...state };
    }
    case types.GET_FIXED_PACKAGE_PENDING: {
      return { ...state, loading: true };
    }
    case types.GET_FIXED_PACKAGE_REJECTED: {
      return { ...state, loading: false, getFixedPackageStatus: 'failed' };
    }
    case types.GET_FIXED_PACKAGE_FULFILLED: {
      return {
        ...state,
        loading: false,
        getFixedPackageStatus: 'success',
        fixedPackage: action.payload.data,
      };
    }
    case types.GET_PACKAGES: {
      return { ...state };
    }
    case types.GET_PACKAGES_PENDING: {
      return { ...state, loading: true };
    }
    case types.GET_PACKAGES_REJECTED: {
      return { ...state, loading: false, getPackagesStatus: 'failed' };
    }
    case types.GET_PACKAGES_FULFILLED: {
      return {
        ...state,
        loading: false,
        getPackagesStatus: 'success',
        packages: action.payload.data,
      };
    }
    case types.GET_ACTIVITY_LIST: {
      return { ...state };
    }
    case types.GET_ACTIVITY_LIST_PENDING: {
      return { ...state, loading: true };
    }
    case types.GET_ACTIVITY_LIST_FULFILLED: {
      return {
        ...state,
        loading: false,
        activityList: action.payload.data,
        getActivityListStatus: 'success',
      };
    }
    case types.GET_ACTIVITY_LIST_REJECTED: {
      return {
        ...state,
        loading: false,
        getActivityListStatus: 'failed',
      };
    }
    case types.GET_TOUR_OPERATOR_PROFILE: {
      return { ...state };
    }
    case types.GET_TOUR_OPERATOR_PROFILE_PENDING: {
      return { ...state, loading: true };
    }
    case types.GET_TOUR_OPERATOR_PROFILE_REJECTED: {
      return {
        ...state,
        loading: false,
        getTourOperatorProfileStatus: 'failed',
      };
    }
    case types.GET_TOUR_OPERATOR_PROFILE_FULFILLED: {
      return {
        ...state,
        loading: false,
        getTourOperatorProfileStatus: 'success',
        tourOperatorProfileList: action.payload.data,
      };
    }
    //========get duration address to id========//
    case types.GET_DURATION_ADDRESS_TO_ID:
      return { ...state };
    case types.GET_DURATION_ADDRESS_TO_ID_PENDING:
      return { ...state, loadingGetDuration: true };
    case types.GET_DURATION_ADDRESS_TO_ID_FULFILLED: {
      // eslint-disable-next-line
      if (action.hasOwnProperty('data')) {
        let mapping = {
          day: action.data.day,
          index: action.data.index,
          duration: action.payload.Duration.value,
        };
        let store = {
          from: action.data.from,
          to: action.data.to,
          duration: action.payload.Duration.value,
        };
        return {
          ...state,
          durationStore: state.durationStore.concat(store),
          durationMap: state.durationMap.concat(mapping),
          getDurationStatus: 'success',
          getDurationCount: state.getDurationCount + 1,
          errors: {},
          loadingGetDuration: false,
        };
      } else return { ...state };
    }
    case types.GET_DURATION_ADDRESS_TO_ID_REJECTED: {
      // eslint-disable-next-line
      if (action.hasOwnProperty('data')) {
        let mapping = {
          day: action.data.day,
          index: action.data.index,
          duration: 0,
        };
        let store = { from: action.data.from, to: action.data.to, duration: 0 };
        return {
          ...state,
          durationStore: state.durationStore.concat(store),
          durationMap: state.durationMap.concat(mapping),
          getDurationCount: state.getDurationCount + 1,
          getDurationStatus: 'failed',
          errors: {},
          loadingGetDuration: false,
        };
      } else return { ...state };
    }
    //========get duration address to address========//
    case types.GET_DURATION_ADDRESS_TO_ADDRESS:
      return { ...state };
    case types.GET_DURATION_ADDRESS_TO_ADDRESS_PENDING:
      return { ...state, loadingGetDuration: true };
    case types.GET_DURATION_ADDRESS_TO_ADDRESS_FULFILLED: {
      // eslint-disable-next-line
      if (action.hasOwnProperty('data')) {
        let mapping = {
          day: action.data.day,
          index: action.data.index,
          duration: action.payload.Duration.value,
        };
        let store = {
          from: action.data.from,
          to: action.data.to,
          duration: action.payload.Duration.value,
        };
        return {
          ...state,
          durationStore: state.durationStore.concat(store),
          durationMap: state.durationMap.concat(mapping),
          getDurationStatus: 'success',
          getDurationCount: state.getDurationCount + 1,
          errors: {},
          loadingGetDuration: false,
        };
      } else return { ...state };
    }
    case types.GET_DURATION_ADDRESS_TO_ADDRESS_REJECTED: {
      // eslint-disable-next-line
      if (action.hasOwnProperty('data')) {
        let mapping = {
          day: action.data.day,
          index: action.data.index,
          duration: 0,
        };
        let store = { from: action.data.from, to: action.data.to, duration: 0 };
        return {
          ...state,
          durationStore: state.durationStore.concat(store),
          durationMap: state.durationMap.concat(mapping),
          getDurationCount: state.getDurationCount + 1,
          getDurationStatus: 'failed',
          errors: {},
          loadingGetDuration: false,
        };
      } else return { ...state };
    }
    //========get duration id to address========//
    case types.GET_DURATION_ID_TO_ADDRESS:
      return { ...state };
    case types.GET_DURATION_ID_TO_ADDRESS_PENDING:
      return { ...state, loadingGetDuration: true };
    case types.GET_DURATION_ID_TO_ADDRESS_FULFILLED: {
      // eslint-disable-next-line
      if (action.hasOwnProperty('data')) {
        let mapping = {
          day: action.data.day,
          index: action.data.index,
          duration: action.payload.Duration.value,
        };
        let store = {
          from: action.data.from,
          to: action.data.to,
          duration: action.payload.Duration.value,
        };
        return {
          ...state,
          durationStore: state.durationStore.concat(store),
          durationMap: state.durationMap.concat(mapping),
          getDurationCount: state.getDurationCount + 1,
          getDurationStatus: 'success',
          errors: {},
          loadingGetDuration: false,
        };
      } else return { ...state };
    }
    case types.GET_DURATION_ID_TO_ADDRESS_REJECTED: {
      // eslint-disable-next-line
      if (action.hasOwnProperty('data')) {
        let mapping = {
          day: action.data.day,
          index: action.data.index,
          duration: 0,
        };
        let store = { from: action.data.from, to: action.data.to, duration: 0 };
        return {
          ...state,
          durationStore: state.durationStore.concat(store),
          durationMap: state.durationMap.concat(mapping),
          getDurationCount: state.getDurationCount + 1,
          getDurationStatus: 'failed',
          errors: {},
          loadingGetDuration: false,
        };
      } else return { ...state };
    }
    //========get duration id to id========//
    case types.GET_DURATION_ID_TO_ID:
      return { ...state };
    case types.GET_DURATION_ID_TO_ID_PENDING:
      return { ...state, loadingGetDuration: true };
    case types.GET_DURATION_ID_TO_ID_FULFILLED: {
      // eslint-disable-next-line
      if (action.hasOwnProperty('data')) {
        let mapping = {
          day: action.data.day,
          index: action.data.index,
          duration: action.payload.Duration.value,
        };
        let store = {
          from: action.data.from,
          to: action.data.to,
          duration: action.payload.Duration.value,
        };
        return {
          ...state,
          durationStore: state.durationStore.concat(store),
          durationMap: state.durationMap.concat(mapping),
          getDurationCount: state.getDurationCount + 1,
          getDurationStatus: 'success',
          errors: {},
          loadingGetDuration: false,
        };
      } else return { ...state };
    }
    case types.GET_DURATION_ID_TO_ID_REJECTED: {
      // eslint-disable-next-line
      if (action.hasOwnProperty('data')) {
        let mapping = {
          day: action.data.day,
          index: action.data.index,
          duration: 0,
        };
        let store = { from: action.data.from, to: action.data.to, duration: 0 };
        return {
          ...state,
          durationStore: state.durationStore.concat(store),
          durationMap: state.durationMap.concat(mapping),
          getDurationCount: state.getDurationCount + 1,
          getDurationStatus: 'failed',
          errors: {},
          loadingGetDuration: false,
        };
      } else return { ...state };
    }
    case types.POST_PACKAGE: {
      return { ...state };
    }
    case types.POST_PACKAGE_PENDING: {
      return { ...state, loading: true };
    }
    case types.POST_PACKAGE_FULFILLED: {
      return {
        ...state,
        loading: false,
        postPackageStatus: 'success',
        postPackage: action.payload.data,
      };
    }
    case types.POST_PACKAGE_REJECTED: {
      return {
        ...state,
        loading: false,
        postPackageStatus: 'failed',
        errors: { global: action.payload.response.data.Message },
      };
    }
    case types.PUT_PACKAGE: {
      return { ...state };
    }
    case types.PUT_PACKAGE_PENDING: {
      return { ...state, loading: true };
    }
    case types.PUT_PACKAGE_FULFILLED: {
      return { ...state, loading: false, putPackageStatus: 'success' };
    }
    case types.PUT_PACKAGE_REJECTED: {
      return {
        ...state,
        loading: false,
        putPackageStatus: 'failed',
        errors: { global: action.payload.response.data.ExceptionMessage },
      };
    }
    case types.POST_PUBLISH_PACKAGE: {
      return { ...state };
    }
    case types.POST_PUBLISH_PACKAGE_PENDING: {
      return { ...state, loading: true };
    }
    case types.POST_PUBLISH_PACKAGE_FULFILLED: {
      return { ...state, loading: false, postPublishPackageStatus: 'success' };
    }
    case types.POST_PUBLISH_PACKAGE_REJECTED: {
      return { ...state, loading: false, postPublishPackageStatus: 'failed' };
    }
    case types.DELETE_PACKAGE_PENDING: {
      return { ...state, loading: true };
    }
    case types.DELETE_PACKAGE_FULFILLED: {
      return { ...state, loading: false, deletePackageStatus: 'success' };
    }
    case types.DELETE_PACKAGE_REJECTED: {
      return { ...state, loading: false, deletePackageStatus: 'failed' };
    }
    case types.COPY_PACKAGE_PENDING: {
      return { ...state, loading: true };
    }
    case types.COPY_PACKAGE_FULFILLED: {
      return {
        ...state,
        loading: false,
        copyPackage: action.payload.data,
        copyPackageStatus: 'success',
      };
    }
    case types.COPY_PACKAGE_REJECTED: {
      return { ...state, loading: false, copyPackageStatus: 'failed' };
    }
    case types.GET_TOUR_OPERATOR_PROFILE_BY_CODE: {
      return { ...state };
    }
    case types.GET_TOUR_OPERATOR_PROFILE_BY_CODE_PENDING: {
      return { ...state, loading: true };
    }
    case types.GET_TOUR_OPERATOR_PROFILE_BY_CODE_FULFILLED: {
      return {
        ...state,
        loading: false,
        getTourOperatorByCodeStatus: 'success',
        tourOperatorListByCode: action.payload.data,
      };
    }
    case types.GET_TOUR_OPERATOR_PROFILE_BY_CODE_REJECTED: {
      return {
        ...state,
        loading: false,
        getTourOperatorByCodeStatus: 'failed',
      };
    }
    case types.RESET_STATUS_GET_TOUR_OPERATOR_BY_CODE: {
      return {
        ...state,
        getTourOperatorByCodeStatus: '',
        getTourOperatorProfileByIdStatus: '',
      };
    }
    case types.GET_TOUR_OPERATOR_PROFILE_DETAIL_BY_ID: {
      return { ...state };
    }
    case types.GET_TOUR_OPERATOR_PROFILE_DETAIL_BY_ID_PENDING: {
      return { ...state, loading: true };
    }
    case types.GET_TOUR_OPERATOR_PROFILE_DETAIL_BY_ID_FULFILLED: {
      return {
        ...state,
        loading: false,
        getTourOperatorProfileByIdStatus: 'success',
        detailTourOperatorById: action.payload.data,
      };
    }
    case types.GET_TOUR_OPERATOR_PROFILE_DETAIL_BY_ID_REJECTED: {
      return {
        ...state,
        loading: false,
        getTourOperatorProfileByIdStatus: 'failed',
      };
    }
    case types.DELETE_TOUR_OPERATOR: {
      return { ...state };
    }
    case types.DELETE_TOUR_OPERATOR_PENDING: {
      return { ...state, loading: true };
    }
    case types.DELETE_TOUR_OPERATOR_FULFILLED: {
      return {
        ...state,
        loading: false,
        deleteTourOperatorStatus: 'success',
      };
    }
    case types.DELETE_TOUR_OPERATOR_REJECTED: {
      return {
        ...state,
        loading: false,
        deleteTourOperatorStatus: 'failed',
      };
    }
    case types.RESET_STATUS_DELETE_TOUR_OPERATOR: {
      return {
        ...state,
        loading: false,
        deleteTourOperatorStatus: '',
        putTourOperatorStatus: '',
      };
    }
    case types.PUT_TOUR_OPERATOR: {
      return {
        ...state,
      };
    }
    case types.PUT_TOUR_OPERATOR_FULFILLED: {
      return {
        ...state,
        loading: false,
        putTourOperatorStatus: 'success',
      };
    }
    case types.PUT_TOUR_OPERATOR_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.PUT_TOUR_OPERATOR_REJECTED: {
      return {
        ...state,
        loading: false,
        putTourOperatorStatus: 'failed',
      };
    }

    case types.POST_IMAGE_TOUR_OPERATOR: {
      return {
        ...state,
      };
    }
    case types.POST_IMAGE_TOUR_OPERATOR_FULFILLED: {
      return {
        ...state,
        loading: false,
        postImageTourOperatorStatus: 'success',
      };
    }
    case types.POST_IMAGE_TOUR_OPERATOR_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.POST_IMAGE_TOUR_OPERATOR_REJECTED: {
      return {
        ...state,
        loading: false,
        postImageTourOperatorStatus: 'failed',
      };
    }

    case types.POST_TOUR_OPERATOR: {
      return {
        ...state,
      };
    }
    case types.POST_TOUR_OPERATOR_FULFILLED: {
      return {
        ...state,
        loading: false,
        postTourOperatorStatus: 'success',
      };
    }
    case types.POST_TOUR_OPERATOR_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.POST_TOUR_OPERATOR_REJECTED: {
      return {
        ...state,
        loading: false,
        postTourOperatorStatus: 'failed',
      };
    }

    case types.DELETE_IMAGE_TOUR_OPERATOR: {
      return {
        ...state,
      };
    }
    case types.DELETE_IMAGE_TOUR_OPERATOR_FULFILLED: {
      return {
        ...state,
        loading: false,
        deleteImageTourOperatorStatus: 'success',
      };
    }
    case types.DELETE_IMAGE_TOUR_OPERATOR_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.DELETE_IMAGE_TOUR_OPERATOR_REJECTED: {
      return {
        ...state,
        loading: false,
        deleteImageTourOperatorStatus: 'failed',
      };
    }
    case types.GET_FILTER_SHARED_PACKAGE: {
      return { ...state };
    }
    case types.GET_FILTER_SHARED_PACKAGE_REJECTED: {
      return {
        ...state,
        errors: { global: action.payload.message },
        loading: false,
        getFilterSharedPackageStatus: false,
      };
    }
    case types.GET_FILTER_SHARED_PACKAGE_PENDING: {
      return { ...state, loading: true };
    }
    case types.GET_FILTER_SHARED_PACKAGE_FULFILLED: {
      return {
        ...state,
        getFilterSharedPackageStatus: true,
        getFilterSharedPackage: action.payload.data,
        loading: false,
      };
    }
    case types.PUT_ADDITIONAL_INFO_PACKAGE_OPERATION: {
      return { ...state };
    }
    case types.PUT_ADDITIONAL_INFO_PACKAGE_OPERATION_REJECTED: {
      return {
        ...state,
        errors: { global: action.payload.message },
        loading: false,
      };
    }
    case types.PUT_ADDITIONAL_INFO_PACKAGE_OPERATION_PENDING: {
      return { ...state, loading: true };
    }
    case types.PUT_ADDITIONAL_INFO_PACKAGE_OPERATION_FULFILLED: {
      return {
        ...state,
        loading: false,
      };
    }
    case types.GET_DOWNLOAD_SCHEDULE_BY_BOOKING_TEMPLATE: {
      return { ...state };
    }
    case types.GET_DOWNLOAD_SCHEDULE_BY_BOOKING_TEMPLATE_REJECTED: {
      return {
        ...state,
        errors: { global: action.payload.message },
        loadingGetDownloadBookingTemplate: false,
        downloadBookingTemplate: null,
      };
    }
    case types.GET_DOWNLOAD_SCHEDULE_BY_BOOKING_TEMPLATE_PENDING: {
      return { ...state, loadingGetDownloadBookingTemplate: true };
    }
    case types.GET_DOWNLOAD_SCHEDULE_BY_BOOKING_TEMPLATE_FULFILLED: {
      return {
        ...state,
        downloadBookingTemplate: action.payload.data,
        loadingGetDownloadBookingTemplate: false,
      };
    }

    default:
      return state;
  }
};

export default reducer;
