import React from 'react';
import { Dimmer } from 'semantic-ui-react';
import { connect } from 'react-redux';
import SegementTourGuideDetail from './components/TourGuideDetail';
import {
  getTourGuideByIdAction,
  resetTourGuideAction,
  putTourGuideAction,
  postTourGuideAction,
} from '../tourGuideAction';
import {
  postServicePriceAction,
  putServicePriceAction,
  resetServicePriceAction,
} from '../../../actions/servicePriceAction';
import { getMasterReferenceAction } from '../../shared/actions/uploadMasterDataReferenceAction';
import { getRegionAction } from '../../shared/actions/regionAction';
import { getCountryListAction } from '../../shared/actions/countryAction';
import { getAreaAction } from '../../shared/actions/areaAction';
import ModalAlert from '../../shared/components/modal/ModalAlert';
import Loader from '../../../components/loader';
import PropTypes from 'prop-types';
import SetDataTourGuide from './service/data/setDataTourGuide';
import SetNewDataTourGuide from './service/data/setNewDataToutGuideDetail';
class TourGuideList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tourGuideObject: { TourGuideLanguage: [], RegionCoverages: [] },
      disablePage: false,
      contentButton: '',
      tourGuideProfilePicture: {
        ImageName: '',
        ImageContent: '',
        ImagePreview: '',
        ImageUrl: '',
      },
      listOfPriceGuide: [],
    };
  }

  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.string,
    }),
    loading: PropTypes.bool,
    listCountry: PropTypes.array,
    listArea: PropTypes.array,
    tourGuideById: PropTypes.object,
    tourGuideByIdStatus: PropTypes.string,
    resetTourGuideAction: PropTypes.func,
    getCountryListAction: PropTypes.func,
    getAreaAction: PropTypes.func,
    getTourGuideByIdAction: PropTypes.func,
    postTourGuideAction: PropTypes.func,
    putTourGuideAction: PropTypes.func,
    listRegion: PropTypes.array,
    listLanguage: PropTypes.array,
    getRegionAction: PropTypes.func,
    getMasterReferenceAction: PropTypes.func,
    postServicePriceAction: PropTypes.func,
    putServicePriceAction: PropTypes.func,
    postTourGuideStatus: PropTypes.string,
    putTourGuideStatus: PropTypes.string,
    postTourGuide: PropTypes.object,
    putTourGuide: PropTypes.object,
    errors: PropTypes.object,
    putSupplierPriceStatus: PropTypes.string,
    postSupplierPriceStatus: PropTypes.string,
    errorsServicePrice: PropTypes.string,
    resetServicePriceAction: PropTypes.func,
  };
  componentDidUpdate() {
    let {
      tourGuideById,
      tourGuideByIdStatus,
      resetTourGuideAction,
      postTourGuideStatus,
      putTourGuideStatus,
      errors,
      putSupplierPriceStatus,
      postSupplierPriceStatus,
      errorsServicePrice,
      resetServicePriceAction,
    } = this.props;
    if (tourGuideByIdStatus === 'success') {
      this.setState({
        tourGuideObject: SetDataTourGuide(tourGuideById),
      });
      resetTourGuideAction();
    } else if (tourGuideByIdStatus === 'failed') {
      resetTourGuideAction();
    }
    if (
      postTourGuideStatus === 'success' ||
      putTourGuideStatus === 'success' ||
      putSupplierPriceStatus === 'success' ||
      postSupplierPriceStatus === 'success'
    ) {
      this.setState({
        openModalAlert: true,
        headerAlert: 'Success',
        contentAlert: 'Tour guide successfully saved',
        reload: true,
      });
      putTourGuideStatus === 'success' &&
        // eslint-disable-next-line
        this.props.history.push(
          `/Tour-Guide-Detail/Edit/${this.props.putTourGuide.Id}`
        );
      postTourGuideStatus === 'success' &&
        // eslint-disable-next-line
        this.props.history.push(
          `/Tour-Guide-Detail/Edit/${this.props.postTourGuide.Id}`
        );
      resetTourGuideAction();
      resetServicePriceAction();
    } else if (
      postTourGuideStatus === 'failed' ||
      putTourGuideStatus === 'failed' ||
      putSupplierPriceStatus === 'failed' ||
      postSupplierPriceStatus === 'failed'
    ) {
      let message = errors.global || errorsServicePrice.global;
      this.setState({
        openModalAlert: true,
        headerAlert: 'Failed',
        contentAlert: message === '' ? 'Failed to save tour guide' : null,
        reload: false,
      });
      resetTourGuideAction();
      resetServicePriceAction();
    }
  }

  componentDidMount() {
    this.rendering();
  }

  rendering = () => {
    const { Type, TourGuideId } = this.props.match.params;
    this.setTourGuide(Type, TourGuideId);
    this.props.getCountryListAction();
    this.props.getAreaAction();
    this.props.getRegionAction();
    this.props.getMasterReferenceAction('Languages');
  };
  setTourGuide = (Type, Id) => {
    if (Type === 'Create' || Type === 'Edit') {
      let data = SetNewDataTourGuide();
      this.setState({ contentButton: 'Save', tourGuideObject: data });
    } else {
      this.setState({ contentButton: 'Edit', disablePage: true });
    }
    Type !== 'Create' && this.props.getTourGuideByIdAction(Id);
  };

  setDisablePage = () => {
    let { disablePage } = this.state;
    this.setState({ disablePage: !disablePage, contentButton: 'Save' });
  };

  handleChange = (name, value) => {
    let { tourGuideObject } = this.state;
    tourGuideObject[name] = value;
    this.setState({ tourGuideObject });
  };

  handleChangeImage = images => {
    let { tourGuideProfilePicture } = this.state;
    tourGuideProfilePicture = images;
    this.setState({ tourGuideProfilePicture });
  };

  handlePushLanguages = (index, value) => {
    let { tourGuideObject, listOfPriceGuide } = this.state;
    if (index !== -1) {
      tourGuideObject.TourGuideLanguage[index] = value;
      listOfPriceGuide.push(value);
    } else {
      tourGuideObject.TourGuideLanguage.push(value);
      listOfPriceGuide.push(value);
    }
  };

  handleClose = () => {
    if (this.state.reload) {
      //this.rendering();
      window.location.reload();
      this.setState({ openModalAlert: false });
    } else {
      this.setState({ openModalAlert: false });
    }
  };

  render() {
    let {
      loading,
      listCountry,
      listArea,
      listRegion,
      listLanguage,
      postTourGuideAction,
      putTourGuideAction,
      postServicePriceAction,
      putServicePriceAction,
      tourGuideById,
    } = this.props;
    let {
      tourGuideObject,
      openModalAlert,
      headerAlert,
      contentAlert,
      contentButton,
      disablePage,
      tourGuideProfilePicture,
      listOfPriceGuide,
    } = this.state;
    return (
      <div>
        <Dimmer.Dimmable blurring dimmed={loading}>
          <Loader loading={loading} />
          <SegementTourGuideDetail
            tourGuideObject={tourGuideObject}
            contentButton={contentButton}
            disablePage={disablePage}
            setDisablePage={this.setDisablePage}
            listCountry={listCountry}
            listArea={listArea}
            listRegion={listRegion}
            handleChange={this.handleChange}
            tourGuideProfilePicture={tourGuideProfilePicture}
            handleChangeImage={this.handleChangeImage}
            listLanguage={listLanguage}
            handlePushLanguages={this.handlePushLanguages}
            postTourGuideAction={postTourGuideAction}
            putTourGuideAction={putTourGuideAction}
            listOfPriceGuide={listOfPriceGuide}
            postServicePriceAction={postServicePriceAction}
            putServicePriceAction={putServicePriceAction}
            tourGuideById={tourGuideById}
          />
          <ModalAlert
            openModal={openModalAlert}
            handleClose={this.handleClose}
            header={headerAlert}
            content={contentAlert}
            nameButton="OK"
          />

          {/*
          <ModalAlert
            openModal={this.state.modalDeleteConfirmation}
            handleClose={this.handleClose}
            header={this.state.modalHeader}
            content={this.state.modalInformation}
            nameButton="OK"
          />
          <ModalConfirm
            openModal={this.state.openModalConfirm}
            size="mini"
            close={this.closeModalConfirm}
            confirm={this.ButtonDeleteAccomodation}
            message={this.state.message}
            header="Confirmation"
          /> */}
        </Dimmer.Dimmable>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    tourGuideById: state.TourGuideStore.tourGuideById,
    tourGuideByIdStatus: state.TourGuideStore.tourGuideByIdStatus,
    loading: state.TourGuideStore.loading,
    errors: state.TourGuideStore.errors,
    listCountry: state.CountryStore.getCountryList.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    listArea: state.AreaStore.getArea.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    listRegion: state.RegionStore.getRegion.map(data => {
      return { Id: data.Id, Name: data.Name, Status: false };
    }),
    listLanguage: state.MasterDataReferenceStore.getMasterReferenceList.map(
      type => {
        return { text: type.EnglishName, value: type.Id, key: type.LocalName };
      }
    ),
    postTourGuideStatus: state.TourGuideStore.postTourGuideStatus,
    putTourGuideStatus: state.TourGuideStore.putTourGuideStatus,
    postTourGuide: state.TourGuideStore.postTourGuide,
    putTourGuide: state.TourGuideStore.putTourGuide,
    putSupplierPriceStatus: state.SupplierPriceStore.putSupplierPriceStatus,
    postSupplierPriceStatus: state.SupplierPriceStore.postSupplierPriceStatus,
    errorsServicePrice: state.SupplierPriceStore.errors,
  };
}
export default connect(
  mapStateToProps,
  {
    getTourGuideByIdAction,
    resetTourGuideAction,
    getCountryListAction,
    getAreaAction,
    putTourGuideAction,
    postTourGuideAction,
    getRegionAction,
    getMasterReferenceAction,
    postServicePriceAction,
    putServicePriceAction,
    resetServicePriceAction,
  }
)(TourGuideList);
