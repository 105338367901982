import React from 'react';
import {
  Modal,
  Message,
  Segment,
  Grid,
  Icon,
  Header,
  // Label,
  Button,
} from 'semantic-ui-react';
// import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './styles';
import ModalGuideInput from './ModalTourGuideInput';
import ButtonCloseModal from '../../../../../components/button/buttonCloseModal';
import {
  getCities,
  getTransportation,
  createTourGuideArray,
} from './../../../../../components/genericFunction/tourGuide';
import TourGuideCard from './TourGuideCard';
// import { getLanguageAll } from '../../../actions/languageAction';
// import options from '../../../../../components/genericFunction/selectOptionConverter';

class ModalTourGuide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalGuideInputOpen: false,
      currentCity: '',
      guide: [],
      selectedGuide: null,
    };
  }
  // componentDidMount = () => {
  //   // this.props.getMasterReferenceAction('Languages');
  //   this.props.getLanguageAll();
  // };
  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open && this.props.open === true) {
      this.generateGuide();
    }
  }

  generateGuide = async () => {
    const { dailyData, language } = this.props;
    let cities = getCities(dailyData);
    // let transportation = getTransportation(dailyData);
    let guide = await cities.map(e => {
      e.transportation = getTransportation(dailyData, e.CityId);
      e.tourGuide = dailyData.TourGuides
        ? this.setInitialGuide(dailyData, e.CityId, language)
        : [];
      return e;
    });
    this.setState({ ...this.state, guide });
  };
  setInitialGuide = (daily, city, language) => {
    return daily.TourGuides.reduce((res, cur) => {
      if (cur.CityId === city) {
        let obj = {};
        obj.guideAmount = cur.Qty;
        obj.language = language.find(item => item.value === cur.Language.Id);
        res.push(obj);
      }
      return res;
    }, []);
  };
  openModalGuideInput = (e, { value }) =>
    this.setState({
      ...this.state,
      modalGuideInputOpen: true,
      selectedGuide: value,
    });
  closeModalGuideInput = () =>
    this.setState({ ...this.state, modalGuideInputOpen: false });

  addTourGuide = value => {
    let { selectedGuide, guide } = { ...this.state };
    let newGuide = guide.map(item => {
      if (item.CityId === selectedGuide.CityId) {
        item.tourGuide.push(value);
      }
      return item;
    });
    // Promise.resolve(this.setState({ ...this.setState, guide: newGuide })).then(
    //   () => this.closeModalGuideInput()
    // );
    this.setState({ ...this.setState, guide: newGuide }, () =>
      this.closeModalGuideInput()
    );
  };

  handleIncrease = (e, { name, objectname, value }) => {
    let { guide } = this.state;
    value++;
    let newGuide = this.changeAmount(guide, objectname, name, value);
    this.setState({ ...this.state, guide: newGuide });
  };
  handleDecrease = (e, { name, objectname, value }) => {
    let { guide } = this.state;
    value--;
    let newGuide =
      value > 0 ? this.changeAmount(guide, objectname, name, value) : guide;
    this.setState({ ...this.state, guide: newGuide });
  };
  handleChangeGuideAmount = (e, { name, objectname, value }) => {
    let { guide } = this.state;
    value--;
    let newGuide =
      value > 0 ? this.changeAmount(guide, objectname, name, value) : guide;
    this.setState({ ...this.state, guide: newGuide });
  };

  handleDeleteGuide = (e, { objectname, name }) => {
    let { guide } = this.state;
    guide.map(item => {
      // eslint-disable-next-line
      if (item.CityId == objectname) {
        item.tourGuide = item.tourGuide.filter(g => g.language.value !== name);
      }
      return item;
    });
    this.setState({ ...this.state, guide: guide });
  };

  changeAmount = (guide, objectname, name, value) => {
    return guide.map(item => {
      //eslint-disable-next-line
      if (item.CityId == objectname) {
        item.tourGuide.map(g => {
          //eslint-disable-next-line
          if (g.language.value == name) {
            g.guideAmount = value;
          }
          return g;
        });
      }
      return item;
    });
  };
  handleSumGuide = guide => {
    return guide.tourGuide.length > 0
      ? guide.tourGuide.reduce((res, cur) => {
          return res + cur.guideAmount;
        }, 0)
      : 0;
  };
  handleSaveTourGuide = () => {
    let { guide } = this.state;
    let { day, dailyProgram } = this.props;
    let tourGuideData = createTourGuideArray(guide);
    dailyProgram[day].TourGuides = tourGuideData;
    this.props.setDailyProgram(dailyProgram);
    // this.props.setTourOperator(null);
    this.props.onClose();
  };
  render() {
    const { open, onClose, dailyData, language } = this.props;
    const { modalGuideInputOpen, selectedGuide, guide } = this.state;
    // const totalGuests = guests.adults + guests.childs;
    // console.log(language);
    return (
      <Modal open={open} onClose={onClose} size="tiny">
        <Modal.Header>
          Tour Guide Day in Day {dailyData ? dailyData.Day : null}
          <ButtonCloseModal onClose={onClose} />
        </Modal.Header>
        <Modal.Content>
          <Message color="yellow">
            You can select tour guides for each transportation unit or city
          </Message>
          {guide && guide.length > 0
            ? guide.map((guide, i) => (
                <Segment key={`city${i}`}>
                  <Grid>
                    <Grid.Row
                      style={{
                        borderBottom: '2px dashed #eaeaea',
                        marginBottom: '14px',
                      }}
                    >
                      <Grid.Column width="10">
                        <Header as="h4">{guide.CityName}</Header>
                      </Grid.Column>
                      <Grid.Column width="6" textAlign="right">
                        <Button
                          basic
                          style={styles.buttonAddGuide}
                          icon
                          value={guide}
                          onClick={this.openModalGuideInput}
                          name="buttonOpenGuideInput"
                        >
                          <span style={{ color: 'blue' }}>Add Tour Guide</span>
                          <Icon
                            name="plus"
                            style={{ paddingLeft: '5px', color: 'blue' }}
                          />
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                    {guide.tourGuide.length > 0 ? (
                      guide.tourGuide.map((item, idx) => (
                        <TourGuideCard
                          key={`guide${idx}`}
                          item={item}
                          cityId={guide.CityId}
                          handleIncrease={this.handleIncrease}
                          handleDecrease={this.handleDecrease}
                          handleChangeGuideAmount
                          handleDeleteGuide={this.handleDeleteGuide}
                        />
                        // <span>harusnya muncul TourGuideCard</span>
                      ))
                    ) : (
                      <Grid.Row style={{ paddingTop: 0 }}>
                        <Grid.Column width="16">
                          <Header
                            as="h4"
                            textAlign="center"
                            style={{ color: '#eaeaea' }}
                          >
                            No Tour Guide Selected
                          </Header>
                        </Grid.Column>
                      </Grid.Row>
                    )}
                  </Grid>
                </Segment>
              ))
            : null}
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={this.handleSaveTourGuide}
            style={styles.backGroundGold}
          >
            Save
          </Button>
        </Modal.Actions>
        <ModalGuideInput
          listLanguage={language}
          open={modalGuideInputOpen}
          onClose={this.closeModalGuideInput}
          data={dailyData}
          selectedGuide={selectedGuide}
          addTourGuide={this.addTourGuide}
        />
      </Modal>
    );
  }
}
ModalTourGuide.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  dailyData: PropTypes.object,
  language: PropTypes.array,
  guests: PropTypes.object,
  setDailyProgram: PropTypes.func,
  dailyProgram: PropTypes.array,
  day: PropTypes.number,
  // setTourOperator: PropTypes.func,
  // getMasterReferenceAction: PropTypes.func,
  getLanguageAll: PropTypes.func,
};
// const mapStateToProps = state => {
//   return {
//     language: state.LanguageStore.languageList.map(item => {
//       return options(item.Id, item.Id, item.EnglishName);
//     }),
//   };
// };
// export default connect(
//   mapStateToProps,
//   { getLanguageAll }
// )(ModalTourGuide);

export default ModalTourGuide;
