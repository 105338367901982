import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Segment,
  Grid,
  Button,
  // Form,
  Header,
  Divider,
} from 'semantic-ui-react';
import Loader from '../../components/loader/loader';
import HeaderTitle from '../../components/common/headerTitle';
import Styles from './Styles';
import { Redirect } from 'react-router-dom';
// import Input from '../shared/components/common/input/InputWithLabel';
// import { Collapse } from 'react-collapse';
// import CollapseButton from '../../components/button/collapseButton';
import { copyObject } from '../../scripts/itineraryBuilder/itineraryBuilder';
import { convertDateFormat } from '../../scripts/moment';
import ModalAddBookingItem from './components/ModalAddBookingItem';
import ModalPaymentRequest from './components/ModalPaymentRequest';
import GeneralInformationResourceDetail from './components/GeneralInformationResourceDetail';
import BookingItem from './components/BookingItem';
import {
  ERRORS_RESOURCE,
  validationAddResource,
  RESOURCE,
  generatForCallApi,
  generateResource,
  generateResourceReservation,
} from './scripts/resourceBookingDetail';
import ModalConfirm from '../../components/modal/modalConfirmationTwoButton';
import {
  postResource,
  putResource,
  putReadyToUseQty,
  getResourceDetailById,
  getResourceBookingReservationByResourceId,
  postResourceBookingReservation,
  putResourceBookingReservation,
  deleteResourceBookingReservation,
  postResourceBookingReservationPayment,
  putResourceBookingReservationPayment,
  getResourceTypeEunum,
  // postCancelResource,
  resetStatusResource,
} from '../shared/actions/resourceBookingAction';
import ModalAlert from '../shared/components/modal/ModalAlert';
import { getTourOperatorProfileByCodeAction } from '../../actions/packageAction';
import { totalQtyBookingItem } from './scripts/resourceDetail';
// const listStatus = [
//   {
//     key: 'booked',
//     value: 'booked',
//     text: 'Booked',
//   },
//   {
//     key: 'created',
//     value: 'created',
//     text: 'Created',
//   },
// ];
const ResourceDetail = props => {
  //==========initial state=========
  const [changePage, setChangePage] = useState({
    isChangePage: false,
    link: '',
  });
  // const [general, setGeneral] = useState({
  //   qtyReadyToUse: 0,
  //   ownership: '',
  //   tourOperator: '',
  // });
  const [openModalAddBookingItem, setOpenModalAddBookingItem] = useState(false);
  const [selectedBookingItem, setSelectedBookingItem] = useState(null);
  const [listBookingItem, setListBookingItem] = useState([
    // {
    //   allowedEdit: true,
    //   isModeEdit: false,
    //   qty: 0,
    //   status: 'created', //booked
    //   supplierRefNo: '',
    //   note: '',
    // },
    // {
    //   allowedEdit: true,
    //   isModeEdit: false,
    //   qty: 0,
    //   status: 'created', //booked
    //   supplierRefNo: '',
    //   note: '',
    // },
  ]);
  const [openModalPaymentReq, setOpenModalPaymentReq] = useState(false);
  const [mode, setMode] = useState('');
  const [resource, setResource] = useState(copyObject(RESOURCE));
  const [errorsResource, setErrorsResource] = useState(
    copyObject(ERRORS_RESOURCE)
  );
  const [modalConfirm, setModalConfirm] = useState({
    isOpen: false,
    type: '',
    message: '',
    header: '',
  });
  const [modalAlert, setModalAlert] = useState({
    isOpen: false,
    header: '',
    message: '',
    url: '',
  });
  const [id, setId] = useState(null);
  const [resourceId, setResourceId] = useState(null);
  //========== End initial state=========
  // ========== action ===============
  const dispatch = useDispatch();
  const postResourceAction = param => dispatch(postResource(param));
  const putResourceAction = param => dispatch(putResource(param));
  const putReadyToUseQtyAction = param => dispatch(putReadyToUseQty(param));
  const getResourceDetailByIdAction = param =>
    dispatch(getResourceDetailById(param));
  const getTourOperatorProfileByCode = param =>
    dispatch(getTourOperatorProfileByCodeAction(param));
  const getResourceBookingReservationByResourceIdAction = param => {
    dispatch(getResourceBookingReservationByResourceId(param));
  };
  const postResourceBookingReservationAction = param => {
    dispatch(postResourceBookingReservation(param));
  };
  const putResourceBookingReservationAction = param => {
    dispatch(putResourceBookingReservation(param));
  };
  const deleteResourceBookingReservationAction = param => {
    dispatch(deleteResourceBookingReservation(param));
  };
  const postResourceBookingReservationPaymentAction = param => {
    dispatch(postResourceBookingReservationPayment(param));
  };
  const putResourceBookingReservationPaymentAction = param => {
    dispatch(putResourceBookingReservationPayment(param));
  };
  const getResourceTypeEunumAction = () => {
    dispatch(getResourceTypeEunum());
  };
  // const postCancelResourceAction = param => {
  //   dispatch(postCancelResource(param));
  // };
  const resetStatusResourceAction = param => {
    dispatch(resetStatusResource(param));
  };
  // ========== end action ===========
  // ========== reducer ========
  const loadingPostResource = useSelector(
    state => state.ResourceBooking.loadingPostResource
  );
  const loadingPutResource = useSelector(
    state => state.ResourceBooking.loadingPutResource
  );
  const resourceReducer = useSelector(state => state.ResourceBooking.resource);
  const listResourceReservation = useSelector(
    state => state.ResourceBooking.listResourceReservation
  );
  const loadingGetResourceBookingReservationByResourceId = useSelector(
    state =>
      state.ResourceBooking.loadingGetResourceBookingReservationByResourceId
  );
  const loadingPostResourceBookingReservationPayment = useSelector(
    state => state.ResourceBooking.loadingPostResourceBookingReservationPayment
  );
  const isSuccessPostResourceBookingReservation = useSelector(
    state => state.ResourceBooking.isSuccessPostResourceBookingReservation
  );
  const errorReducer = useSelector(state => state.ResourceBooking.error);
  const loadingPutReadyToUseQty = useSelector(
    state => state.ResourceBooking.loadingPutReadyToUseQty
  );
  const dataStartDateEndDate = useSelector(
    state => state.ResourceBooking.dataStartDateEndDate
  );
  const loadingPutResourceBookingReservationPayment = useSelector(
    state => state.ResourceBooking.loadingPutResourceBookingReservationPayment
  );
  const isSuccessPutResourceBookingReservation = useSelector(
    state => state.ResourceBooking.isSuccessPutResourceBookingReservation
  );
  // const dataCancelResource = useSelector(
  //   state => state.ResourceBooking.dataCancelResource
  // );
  // const isSuccessPostCancelResource = useSelector(
  //   state => state.ResourceBooking.isSuccessPostCancelResource
  // );
  // ============ END REDUCER ==============
  useEffect(() => {
    // let { type, id, resourceId } = props.match.params;
    load(props.match.params);
    // eslint-disable-next-line
  }, []);
  const doReIssue = () => {
    let data = generatForCallApi(resource, id);
    postResourceAction([
      {
        ...data,
        ResourceBookingDetailColletionIdRef:
          resource.ResourceBookingDetailColletionIdRef || resource.Id,
        ServiceItemId: resource.ServiceItemId,
      },
    ])
      .then(e => {
        resetStatusResourceAction();
        let resourceNewId = e.value.data[0].ResourceDetailCollectionId;
        setMode(resourceNewId ? 'Detail' : 'Add');
        setId(id);
        setResourceId(resourceNewId || null);
        openModalAlert(
          'Success',
          'ReIssue resource successfully',
          `/Resource-Detail/Detail/${id}/${resourceNewId}`
        );
      })
      .catch(err => {
        openModalAlert('Failed', err.response.data.Message, '');
      });
  };
  const load = dataUrl => {
    let { type, id, resourceId } = dataUrl;
    setMode(type);
    setId(id);
    setResourceId(resourceId || null);
    getResourceTypeEunumAction();
    if (dataStartDateEndDate === null) handleBack();
    else if (type !== 'Add') {
      getResourceDetailByIdAction(resourceId);
      getResourceBookingReservationByResourceIdAction(
        type === 'ReIssue' ? null : resourceId
      );
    } else {
      setResource({
        ...copyObject(RESOURCE),
        Date: dataStartDateEndDate.startDate,
        EndDate: dataStartDateEndDate.startDate,
      });
      getResourceBookingReservationByResourceIdAction(null);
    }
  };
  useEffect(() => {
    if (resourceReducer !== null && props.match.params.type !== 'Add') {
      setResource(generateResource(resourceReducer, mode));
      getTourOperatorProfileByCode(
        resourceReducer.ResourceOwnerShip
          ? resourceReducer.ResourceOwnerShip.Value
          : 0
      );
    }
    // eslint-disable-next-line
  }, [resourceReducer]);
  useEffect(() => {
    if (listResourceReservation.length !== 0) {
      setListBookingItem(generateResourceReservation(listResourceReservation));
    }
  }, [listResourceReservation]);
  useEffect(() => {
    if (
      isSuccessPostResourceBookingReservation ||
      isSuccessPutResourceBookingReservation
    ) {
      openModalAlert(
        'Success',
        'Update resource successfully',
        `/Resource-Detail/Detail/${id}/${resourceId}`
      );
    } else if (
      isSuccessPostResourceBookingReservation === false ||
      isSuccessPutResourceBookingReservation === false
    ) {
      openModalAlert(
        'Failed',
        errorReducer ? errorReducer.global : 'Failed update resource',
        ''
      );
    }
    // eslint-disable-next-line
  }, [
    isSuccessPostResourceBookingReservation,
    isSuccessPutResourceBookingReservation,
  ]);
  // useEffect(() => {
  //   if (isSuccessPostResource && postResourceReducer !== null) {

  //   }
  //   // eslint-disable-next-line
  // }, [isSuccessPostResource]);
  const handleBack = () => {
    setChangePage({
      isChangePage: true,
      link: `/Resource-Booking-Detail/${props.match.params.id}`,
    });
  };
  const handleSave = (e, { value }) => {
    if (value === 'Save' && props.match.params.type === 'Add') {
      let validation = validationAddResource(resource);
      if (validation.isError) setErrorsResource(validation.errors);
      else {
        setErrorsResource(copyObject(ERRORS_RESOURCE));
        openModalConfirm(
          'Add',
          'Confirmation',
          `Are You sure want to add resource?`
        );
      }
    } else if (value === 'Save' && props.match.params.type === 'Detail') {
      let validation = validationAddResource(resource);
      if (validation.isError) setErrorsResource(validation.errors);
      else {
        setErrorsResource(copyObject(ERRORS_RESOURCE));
        openModalConfirm(
          'Edit',
          'Confirmation',
          `Are You sure want to update resource?`
        );
      }
    } else if (value === 'Save' && props.match.params.type === 'ReIssue') {
      let validation = validationAddResource(resource);
      if (validation.isError) setErrorsResource(validation.errors);
      else {
        setErrorsResource(copyObject(ERRORS_RESOURCE));
        openModalConfirm(
          'ReIssue',
          'Confirmation',
          `Are You sure want to reissue resource?`
        );
      }
    } else setMode(value);
  };
  // const handleGeneralData = (e, { name, value }) => {
  //   if (name === 'qtyReadyToUse') value = value ? (value > 0 ? value : 0) : 0;
  //   setGeneral({ ...general, [name]: value });
  // };
  const handleAddBookingItem = () => {
    setOpenModalAddBookingItem(!openModalAddBookingItem);
    if (!openModalAddBookingItem === false) {
      setSelectedBookingItem(null);
    }
  };
  const handleListBookingItem = newListBookingItem => {
    setListBookingItem(newListBookingItem);
  };
  const handleModalPaymentReq = () => {
    setOpenModalPaymentReq(!openModalPaymentReq);
    if (!openModalPaymentReq === false) setSelectedBookingItem(null);
  };
  const updateResource = newResource => {
    setResource(newResource);
  };
  const openModalAlert = (header, message, url) => {
    setModalAlert({
      isOpen: true,
      header,
      message,
      url,
    });
  };
  const handleCloseModalAlert = () => {
    if (modalAlert.url) {
      // getResourceDetailByIdAction(resourceId);
      // getResourceBookingReservationByResourceIdAction(resourceId);
      props.history.push(modalAlert.url);
      load({ type: mode, id, resourceId });

      // location.reload();
      // setChangePage({
      //   isChangePage: true,
      //   link: modalAlert.url,
      // });
    }
    closeModalAlert();
  };
  const closeModalAlert = () => {
    setModalAlert({
      isOpen: false,
      header: '',
      message: '',
      url: '',
    });
  };
  const openModalConfirm = (type, header, message) => {
    setModalConfirm({
      isOpen: true,
      type,
      message,
      header,
    });
  };
  const closeModalConfirm = () => {
    setModalConfirm({
      isOpen: false,
      type: '',
      message: '',
      header: '',
    });
  };

  const handleModalConfirm = () => {
    closeModalConfirm();
    let data = generatForCallApi(resource, id);
    if (modalConfirm.type === 'ReIssue') {
      doReIssue();
    } else if (mode === 'Edit') {
      data.Id = resource.Id;
      // putResourceAction(data)
      //   .then(() => {
      //     getResourceDetailByIdAction(resourceId);
      //     openModalAlert('Success', 'Update resource successfully', '');
      //   })
      //   .catch(err => {
      //     openModalAlert('Failed', err.response.data.Message, '');
      //   });
      handleUpdateAction(data);
    } else {
      postResourceAction([data])
        .then(e => {
          let resourceNewId = e.value.data[0].ResourceDetailCollectionId;
          setMode(resourceNewId ? 'Detail' : 'Add');
          setId(id);
          setResourceId(resourceNewId || null);
          openModalAlert(
            'Success',
            'Add resource successfully',
            `/Resource-Detail/Detail/${id}/${resourceNewId}`
          );
        })
        .catch(err => {
          openModalAlert(
            'Failed',
            err.response.data ? err.response.data.Message : '',
            ''
          );
        });
    }
  };
  // ============================= handle booking item =============================
  const handleBookingItem = newBookingItem => {
    if (selectedBookingItem === null) {
      listBookingItem.push(newBookingItem);
      setListBookingItem(listBookingItem);
    } else if (selectedBookingItem !== null) {
      let listDelete = listBookingItem.filter(e => e.IsDelete);
      let show = listBookingItem.filter(e => !e.IsDelete);
      show.splice(newBookingItem.Idx, 1, newBookingItem);
      let newListBookingItem = show.concat(listDelete);
      setListBookingItem(newListBookingItem);
    }
    setSelectedBookingItem(null);
    handleAddBookingItem();
  };
  const handleEditBookingItem = (e, { value }) => {
    setSelectedBookingItem(value);
    handleAddBookingItem();
  };

  const handleUpdateAction = dataUpdateResource => {
    let listAction = listBookingItem.reduce(
      (acc, e) => {
        if (e.IsDelete && !e.IsNew) {
          // DELETE
          acc.push(deleteResourceBookingReservationAction(e.Id));
        } else if (e.IsNew && !e.IsDelete) {
          // POST
          acc.push(postResourceBookingReservationAction(e));
        } else if (e.IsEdit) {
          // PUT
          acc.push(putResourceBookingReservationAction(e));
        }
        return acc;
      },
      listResourceReservation.length === 0
        ? [putResourceAction(dataUpdateResource)]
        : [
            putReadyToUseQtyAction({
              Id: parseInt(id, 10),
              ResourceBookingDetailColletionId: parseInt(resourceId, 10),
              Ready: resource.QtyReadyToUse,
            }),
          ]
    );
    // eslint-disable-next-line
    Promise.all(listAction)
      .then(async () => {
        await getResourceDetailByIdAction(resourceId);
        await getResourceBookingReservationByResourceIdAction(resourceId);
        await openModalAlert(
          'Success',
          'Update resource successfully',
          `/Resource-Detail/Detail/${id}/${resourceId}`
        );
      })
      .catch(err => {
        openModalAlert('Failed', err.response.data.Message, '');
      });
  };
  // ============================= end handle booking item =========================
  // =================== handle payment req ============================
  const handleOpenModalPaymentReq = (e, { value }) => {
    if (listBookingItem.some(e => e.IsEdit || e.IsNew || e.IsDelete)) {
      openModalConfirm(
        'Save',
        'Confirmation',
        `There are some unsaved booking items. Please save before making a payment request. Do you want to save it?`
      );
    } else {
      setSelectedBookingItem(value);
      handleModalPaymentReq();
    }
  };
  const handleAddPaymentRequest = paymentRequest => {
    // let newListBookingItem = copyObject(listBookingItem);
    // let listDelete = newListBookingItem.filter(e => e.IsDelete);
    // let show = newListBookingItem.filter(e => !e.IsDelete);
    // show[selectedBookingItem.Idx].PaymetRequestDetails.push(paymentRequest);
    // newListBookingItem = show.concat(listDelete);
    // setListBookingItem(newListBookingItem);
    let data = {
      ImageContent: null,
      PaymentStatus: paymentRequest.PaymentStatus,
      TotalCost: paymentRequest.Value,
      DueDate: convertDateFormat(paymentRequest.DueDate, 'YYYY-MM-DDThh:mm:ss'),
      Note: paymentRequest.Note,
      Currency: paymentRequest.Currency,
      resourceBookingReservationId: selectedBookingItem.Id,
      Priority: paymentRequest.Priority,
    };
    paymentRequest.isEdit
      ? putResourceBookingReservationPaymentAction({
          ...data,
          Id: paymentRequest.Id,
        })
      : postResourceBookingReservationPaymentAction(data);
    handleModalPaymentReq();
  };
  const handleChooseSupplier = resource => {
    setResource(resource);
  };
  let qtyResourceList =
    listBookingItem &&
    listBookingItem.length > 0 &&
    totalQtyBookingItem(listBookingItem, null);
  let isDisabledButtonAdd = qtyResourceList === resource.Qty;
  if (changePage.isChangePage) {
    return <Redirect to={changePage.link} />;
  } else
    return (
      <Segment style={Styles.Segment}>
        <Loader
          active={
            loadingPostResource ||
            loadingPutResource ||
            loadingGetResourceBookingReservationByResourceId ||
            loadingPostResourceBookingReservationPayment ||
            loadingPutReadyToUseQty ||
            loadingPutResourceBookingReservationPayment
          }
          description="Loading"
          page={true}
        />
        <HeaderTitle
          title={mode === 'Add' ? 'Add Resource' : 'Resource Item Detail'}
        />
        <Grid>
          <Grid.Row>
            <Grid.Column width={16} textAlign="right">
              <Button
                content="Back"
                style={Styles.BorderBlueBackgrounWhite}
                onClick={handleBack}
              />
              {mode === 'Canceled' ? null : (
                <Button
                  content={mode === 'Detail' ? 'Edit' : 'Save'}
                  value={mode === 'Detail' ? 'Edit' : 'Save'}
                  primary
                  onClick={handleSave}
                />
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Header as="h4" content="General Information" />
            </Grid.Column>
          </Grid.Row>
          <GeneralInformationResourceDetail
            updateResource={updateResource}
            resource={resource}
            errors={errorsResource}
            mode={mode}
            isDisabled={listResourceReservation.length !== 0}
            dataStartDateEndDate={dataStartDateEndDate}
            handleChooseSupplier={handleChooseSupplier}
          />
          {mode === 'Add' || mode === 'ReIssue' ? (
            <Grid.Row>
              <Grid.Column>
                <Divider />
                <Segment>
                  Save the resource first before ordering an item
                </Segment>
              </Grid.Column>
            </Grid.Row>
          ) : (
            <Grid.Row>
              <Grid.Column width={16} style={{ padding: '10px 15px' }}>
                <Divider />
                <Button
                  primary
                  content="Add Booking Item"
                  floated="right"
                  onClick={handleAddBookingItem}
                  disabled={
                    mode === 'Detail' ||
                    mode === 'Canceled' ||
                    isDisabledButtonAdd
                  }
                />
              </Grid.Column>
              <BookingItem
                listBookingItem={listBookingItem}
                handleOpenModalPaymentReq={handleOpenModalPaymentReq}
                handleListBookingItem={handleListBookingItem}
                disablePage={mode === 'Detail' || mode === 'Canceled'}
                handleEditBookingItem={handleEditBookingItem}
              />
            </Grid.Row>
          )}
        </Grid>
        <ModalAddBookingItem
          openModal={openModalAddBookingItem}
          handleClose={handleAddBookingItem}
          handleBookingItem={handleBookingItem}
          listBookingItem={listBookingItem}
          totalQty={resource.Qty}
          resourceId={resourceId}
          selectedBookingItem={selectedBookingItem}
        />
        <ModalPaymentRequest
          openModal={openModalPaymentReq}
          handleClose={handleModalPaymentReq}
          handleAddPaymentRequest={handleAddPaymentRequest}
          DueDate={resource.DueDate}
          Currency={resource.Currency}
          itemName={resource.ResourceName}
          useDate={resource.Date}
          qtyBookingItem={selectedBookingItem ? selectedBookingItem.Qty : 0}
          supplierName={
            resourceReducer
              ? resourceReducer.Supplier
                ? resourceReducer.Supplier.Name
                : ''
              : ''
          }
          supplierContact={
            resourceReducer && resourceReducer.Supplier
              ? resourceReducer.Supplier.Telephone
              : ''
          }
          supplierEmail={
            resourceReducer && resourceReducer.Supplier
              ? resourceReducer.Supplier.Email
              : ''
          }
          unitCost={resource.UnitCost}
          selectedPaymentReq={
            selectedBookingItem && selectedBookingItem.PaymetRequestDetails
              ? selectedBookingItem.PaymetRequestDetails[0]
              : null
          }
        />
        <ModalConfirm
          openModal={modalConfirm.isOpen}
          size="mini"
          close={closeModalConfirm}
          confirm={handleModalConfirm}
          message={modalConfirm.message}
          nameConfirm={modalConfirm.type}
          header={modalConfirm.header}
        />
        <ModalAlert
          openModal={modalAlert.isOpen}
          handleClose={handleCloseModalAlert}
          header={modalAlert.header}
          content={modalAlert.message}
        />
      </Segment>
    );
};

ResourceDetail.propTypes = {
  match: PropTypes.bool,
};

export default ResourceDetail;
