// search untuk list
export const searchFilterAdditionalCostList = (data, searchInput) => {
  // console.log(data);
  return data.filter(item => {
    return (
      (item.TransportationType !== null &&
        item.TransportationType.toLowerCase().indexOf(
          searchInput.toLowerCase()
        ) !== -1) ||
      (item.Destination !== null &&
        item.Destination.toLowerCase().indexOf(searchInput.toLowerCase()) !==
          -1) ||
      (item.Origin !== null &&
        item.Origin.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1)
    );
  });
};

// search untuk tabel detail
export const searchFilterAdditionalCostPrice = (data, searchInput) => {
  // console.log(data);
  return data.filter(item => {
    return (
      (item.TransportationAdditionalCostName !== null &&
        item.TransportationAdditionalCostName.toLowerCase().indexOf(
          searchInput.toLowerCase()
        ) !== -1) ||
      (item.TransportationAdditionalCostType !== null &&
        item.TransportationAdditionalCostType.Text.toLowerCase().indexOf(
          searchInput.toLowerCase()
        ) !== -1) ||
      (item.Amount !== null &&
        item.Amount.toString().indexOf(searchInput.toLowerCase()) !== -1)
    );
  });
};
