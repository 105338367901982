const filterListByTitleOrId = (list, searchInput) => {
  return searchInput
    ? list.filter(data => {
        return (
          data.Title.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1 ||
          data.Id.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1
        );
      })
    : list;
};

export default filterListByTitleOrId;
