import React from 'react';
import { Table, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const ActionButtonBooking = props => {
  return props.value === 'Booking_created' ? (
    // <Table.Cell>
    //   <Button.Group>
    //     <Button
    //       name="Confirm"
    //       value={props.value}
    //       primary
    //       onClick={props.handleClick}
    //       content="Confirm"
    //       id={props.id}
    //     />
    //     <Button.Or />
    //     <Button
    //       name="Cancel"
    //       negative
    //       onClick={props.handleClick}
    //       content="Cancel"
    //       id={props.id}
    //     />
    //   </Button.Group>
    // </Table.Cell>
    <Table.Cell>
      {props.isHidePayment ? (
        <Button
          name="Cancel"
          value={props.value}
          negative
          onClick={props.handleClick}
          content="Cancel"
          id={props.id}
        />
      ) : (
        <Button.Group>
          <Button
            name="Confirm"
            value={props.value}
            onClick={props.handleClick}
            content="Pay"
            id={props.id}
            style={{ backgroundColor: 'orange', color: 'white' }}
            disabled={props.disabled}
          />
          <Button.Or />
          <Button
            name="Cancel"
            value={props.value}
            negative
            onClick={props.handleClick}
            content="Cancel"
            id={props.id}
            disabled={props.disabled}
          />
        </Button.Group>
      )}
    </Table.Cell>
  ) : props.value === 'Booking_hold' ? (
    <Table.Cell>
      {props.isHideApprove ? (
        <Button
          name="Cancel"
          value={props.value}
          negative
          onClick={props.handleClick}
          content="Cancel"
          id={props.id}
          disabled={props.disabled}
        />
      ) : (
        <Button.Group>
          <Button
            name="Approve"
            value={props.value}
            positive
            onClick={props.handleClick}
            content="Approve"
            id={props.id}
            disabled={props.disabled}
          />
          <Button.Or />
          <Button
            name="Cancel"
            value={props.value}
            negative
            onClick={props.handleClick}
            content="Cancel"
            id={props.id}
            disabled={props.disabled}
          />
        </Button.Group>
      )}
    </Table.Cell>
  ) : props.value === 'Booking_Confirmed' || props.value === 'Booking_draft' ? (
    <Table.Cell>
      <Button
        name="Cancel"
        negative
        onClick={props.handleClick}
        content="Cancel"
        id={props.id}
        value={props.value}
      />
    </Table.Cell>
  ) : props.value === 'Booking_Cancelled' ? (
    <Table.Cell>
      <Button negative disabled>
        Cancelled
      </Button>
    </Table.Cell>
  ) : props.value === 'Booking_DP_Confirmed' ? (
    <Table.Cell>
      <Button.Group>
        <Button
          name="Confirm"
          primary
          value={props.value}
          onClick={props.handleClick}
          content="Confirm"
          id={props.id}
          disabled={props.disabled}
        />
        <Button.Or />
        <Button
          name="Cancel"
          value={props.value}
          negative
          onClick={props.handleClick}
          content="Cancel"
          id={props.id}
          disabled={props.disabled}
        />
      </Button.Group>
    </Table.Cell>
  ) : props.value === 'Booking_In_Payment' ? (
    /*   <Table.Cell>
      <Button.Group>
        <Button
          name="Confirm"
          value={props.value}
          primary
          onClick={props.handleClick}
          content="Confirm"
          id={props.id}
        />
        <Button.Or />
        <Button
          name="Cancel"
          negative
          onClick={props.handleClick}
          content="Cancel"
          id={props.id}
        />
      </Button.Group>
    </Table.Cell> */
    <Table.Cell>
      <Button.Group>
        <Button
          name="Confirm"
          primary
          value={props.value}
          onClick={props.handleClick}
          content="Confirm"
          id={props.id}
          disabled={props.disabled}
        />
        <Button.Or />
        <Button
          name="Cancel"
          value={props.value}
          negative
          onClick={props.handleClick}
          content="Cancel"
          id={props.id}
          disabled={props.disabled}
        />
      </Button.Group>
    </Table.Cell>
  ) : (
    <Table.Cell>
      <Button secondary disabled>
        Complete
      </Button>
    </Table.Cell>
    // <Table.Cell>
    //   <Button.Group>
    //     <Button
    //       name="Confirm"
    //       value={props.value}
    //       primary
    //       onClick={props.handleClick}
    //       content="Confirm"
    //       id={props.id}
    //     />
    //     <Button.Or />
    //     <Button
    //       name="Cancel"
    //       negative
    //       onClick={props.handleClick}
    //       content="Cancel"
    //       id={props.id}
    //     />
    //   </Button.Group>
    // </Table.Cell>
  );
};

ActionButtonBooking.propTypes = {
  id: PropTypes.number,
  handleClick: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  isHidePayment: PropTypes.bool,
  isHideApprove: PropTypes.bool,
};

export default ActionButtonBooking;
