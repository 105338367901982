import React from 'react';
import { Grid, Header, Form, Popup, Icon, Divider } from 'semantic-ui-react';
import InputWithLabel from '../../../../../../../src/components/input/inputWithLabel';
import styles from '../../Styles';
import PropTypes from 'prop-types';

const SegmentTourNote = props => {
  let {
    tourNote,
    currentPackage,
    // isQuotation,
    loadingTourTransaction,
    handleOnchange,
  } = props;

  return (
    <Form>
      <Form.Group widths="equal">
        <Form.Field>
          <Grid columns={2} style={styles.marginTop3}>
            <Grid.Row>
              <Grid.Column width={3}>
                <p style={{ fontSize: '14px' }}>Tour Note</p>
              </Grid.Column>
              <Grid.Column width={4}>
                <Popup
                  trigger={
                    <Icon
                      name="info"
                      size="small"
                      color="blue"
                      circular
                      style={{ marginLeft: '-2em' }}
                    />
                  }
                  position="bottom left"
                >
                  <Header as="h5">Note</Header>
                  <Divider style={{ padding: '0px' }} />
                  <p style={{ textAlign: 'justify' }}>
                    this note belongs to travel agent if ever need deliver
                    something to tour operator
                  </p>
                </Popup>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <InputWithLabel
            inline={false}
            type="textArea"
            fluid={true}
            disabled={currentPackage.disabledEdit}
            name="tourNote"
            value={tourNote}
            objectname="tourInformation"
            loading={loadingTourTransaction}
            handleChange={handleOnchange}
            inputStyles={{ minHeight: '5em' }}
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
};
SegmentTourNote.propTypes = {
  tourNote: PropTypes.object,
  handleOnchange: PropTypes.func,
  currentPackage: PropTypes.object,
  isQuotation: PropTypes.bool,
  loadingTourTransaction: PropTypes.bool,
};
export default SegmentTourNote;
