import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import ActionButton from '../../../../components/common/buttonEditAndDelete';
import { headerTableAdditionalServicesList } from '../../constants/HeaderTable';

const TableAdditionalServicesList = props => {
  const { listData, handleEdit, handleDelete } = props;
  return (
    <Table basic="very" selectable>
      <Table.Header>
        <Table.Row>
          {headerTableAdditionalServicesList.map((header, index) => {
            return (
              <Table.HeaderCell
                key={index}
                textAlign={header === 'Action' ? 'center' : ''}
              >
                {header}
              </Table.HeaderCell>
            );
          })}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {listData ? (
          listData.map((item, idx) => {
            return (
              <Table.Row key={idx}>
                <Table.Cell width={3}>{item.AdditionalServicesName}</Table.Cell>
                <Table.Cell width={3}>
                  {item.DestinationCountry.Text}
                </Table.Cell>
                <Table.Cell width={3}>
                  {item.AdditionalServiceType.TypeName}
                </Table.Cell>
                <Table.Cell width={4}>{item.Description}</Table.Cell>
                <Table.Cell width={3} textAlign="center">
                  <ActionButton
                    id={item.Id}
                    handleClickEdit={handleEdit}
                    handleClickDelete={handleDelete}
                    name1="Edit"
                    name2="Delte"
                    content1="Edit"
                    content2="Delete"
                  />
                </Table.Cell>
              </Table.Row>
            );
          })
        ) : (
          <Table.Row>
            <Table.Cell colSpan={4} textAlign="center">
              No Data
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};

TableAdditionalServicesList.propTypes = {
  listData: PropTypes.array,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default TableAdditionalServicesList;
