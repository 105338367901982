import React from 'react';
import { Segment, Header, Icon } from 'semantic-ui-react';
import Parser from 'html-react-parser';
import PropTypes from 'prop-types';
import styles from './styles';
const AdditionalInformation = props => {
  return (
    <div>
      <Segment style={styles.segment}>
        <Header as="h3" textAlign="left">
          <Icon name="info circle" style={styles.primaryColor} />
          Additional Information
        </Header>
        {props.descriptions && props.descriptions.length > 0 ? (
          props.descriptions.map((item, idx) => (
            <div key={idx} style={styles.marginBottom20}>
              <Header as="h4">
                {Parser(item.SubTitle || item.Subtitle || '')}
              </Header>
              <p>{Parser(item.Content || '')}</p>
            </div>
          ))
        ) : (
          <Header as="h4" textAlign="center">
            {' '}
            No Additional Information{' '}
          </Header>
        )}
      </Segment>
    </div>
  );
};

AdditionalInformation.propTypes = {
  descriptions: PropTypes.array,
};
export default AdditionalInformation;
