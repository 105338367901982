import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Icon, List, Grid, Segment } from 'semantic-ui-react';
import { StyleModal } from '../../../../assets/styles/size';
import { Collapse } from 'react-collapse';
import { StyleButton } from '../../../../components/common/styleCommon';
import { TextFieldFluidOnPage } from '../../../../components/common/fieldWithError';
import LoaderModal from '../../../../components/modal/loaderModal';
const ModalPaymentGateway = props => {
  return (
    <Modal
      dimmer="blurring"
      closeIcon
      inverted
      open={props.open}
      onClose={props.handleClose}
      size="small"
      style={StyleModal.modalWidth}
    >
      <Modal.Header>{props.header}</Modal.Header>
      <Modal.Content>
        <LoaderModal loading={props.loadingModal} />
        <Button
          icon
          onClick={props.collapseButton}
          style={StyleButton.buttonWidthPaymentGateway}
          //content="Manual Payment"
          primary
          labelPosition="right"
        >
          Manual Payment
          <Icon name="chevron down" />
        </Button>
        <Collapse isOpened={props.openCollapse}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Segment style={{ marginTop: '1em', height: '7em' }}>
                  <TextFieldFluidOnPage
                    placeholder="No Reference"
                    name="Reference"
                    title="No Reference"
                    handleChange={props.handleChange}
                    type="text"
                  />
                  <Button
                    positive
                    onClick={props.saveBalanceDetail}
                    name="ManualPayment"
                    content="Add Fund"
                    floated="right"
                    style={{ marginTop: '-3.7em' }}
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Collapse>
        <List divided relaxed>
          {props.listPaymentMethod &&
            props.listPaymentMethod.map((data, idx) => {
              return (
                <List.Item key={idx}>
                  <List.Content>
                    <List.Header as={props.LinkTo}>
                      <Button
                        icon
                        style={StyleButton.buttonWidthPaymentGateway}
                        primary
                        labelPosition="right"
                        onClick={props.saveBalanceDetail}
                        name="postPaymentGateway"
                        id={data.Id}
                        value={data.name}
                      >
                        {data.name}
                        <Icon name="chevron right" />
                      </Button>
                    </List.Header>
                    <List.Description as="a" />
                  </List.Content>
                </List.Item>
              );
            })}
        </List>
      </Modal.Content>
      <Modal.Actions>
        <Button positive inverted onClick={props.saveBalanceDetail}>
          <Icon name="save" /> Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

ModalPaymentGateway.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  handleChange: PropTypes.func,
  contentField: PropTypes.shape({
    Name: PropTypes.string,
    CompanyCode: PropTypes.number,
    DateTime: PropTypes.string,
    Value: PropTypes.number,
  }),
  errorInformation: PropTypes.shape({
    ValueError: PropTypes.string,
  }),
  saveBalanceDetail: PropTypes.func,
  handleClickPaymentGateway: PropTypes.func,
  listPaymentMethod: PropTypes.array,
  LinkTo: PropTypes.func,
  openCollapse: PropTypes.bool,
  collapseButton: PropTypes.func,
  loadingModal: PropTypes.bool,
};
export default ModalPaymentGateway;
