import React from 'react';
import { Grid, Segment, Divider } from 'semantic-ui-react';
import PropTypes from 'prop-types';
// import GeneralInformation from '../../../components/accomodation/accomodationItem/generalInformationAccomodationItem';
import GeneralInformation from './GeneralInformationAccommodationItem';
// import AccomodationPriceList from '../../../components/accomodation/accomodationItem/accomodationPrice';
import AccomodationPriceList from './AccommodationPrice';
//import ImageAccomodationItem from '../../globalListVew/imageListComponent';
// import ImageListComponent from '../../image/image';
import ImageListComponent from '../../../../../components/image/image';
import ButtonBackAndSave from '../../../../../components/common/buttonBackAndSave';
import {
  HeaderTableImageAccomodationProfile,
  HeaderTableFacilitiesAccomodationProfile,
} from '../../../../../components/table/headerTabel';
import AddingDataWithTable from '../../../../../components/globalListVew/addingDataComponent';
import HeaderTitle from '../../../../../components/common/headerTitle';
const accomodationItemForm = props => (
  <div>
    <Segment style={{ width: '76em', marginTop: '30px' }}>
      <Grid columns={2} padded="vertically">
        <Grid.Column>
          {/* Kiri */}
          <HeaderTitle title={props.title} />
          <div style={{ marginBottom: '1em' }}>
            <label style={{ fontSize: 'large' }}>
              Item for <strong> {props.accomodationProfileName}</strong>
            </label>
          </div>
          <GeneralInformation
            accomodationItem={props.accomodationItem}
            itemTypeData={props.itemTypeList}
            handleChange={props.handleChange}
            onChangeCheckbox={props.onChangeCheckbox}
            errors={props.errors}
            disablePage={props.disablePage}
          />
        </Grid.Column>
        <Grid.Column>
          {/* Kanan */}
          <ButtonBackAndSave
            save={props.saveAccommodationItem}
            backPage={props.backPage}
            nameBack="Back"
            nameSave={props.contentButton}
          />
          <div style={{ marginTop: '1.5em' }}>
            <AddingDataWithTable
              addData={props.modalItem}
              buttonContent="Add Facilities"
              headerContent="Facilities"
              deleteData={props.deleteData}
              headerTable={HeaderTableFacilitiesAccomodationProfile}
              idButton="Facilities"
              listData={
                props.accomodationItem.SimpleAccommodationItemFacilities
              }
              disablePage={props.disablePage}
              listAddingData={props.listDataFacilities}
              pageAddingData={props.pageFacilities}
              activePage={props.activePage}
              pageChange={props.pageChange}
              addingDataList={props.SimpleAccommodationItemFacilities}
            />
          </div>
          <ImageListComponent
            addData={props.modalItem}
            buttonContent="Add Image"
            headerContent="Image"
            deleteData={props.deleteImage}
            headerTable={HeaderTableImageAccomodationProfile}
            listData={props.listImage}
            //openDetail={}
            idButton="Image"
            openDetail={props.openDetail}
            handleChangePrimaryImage={props.handleChangePrimaryImage}
            disablePage={props.disablePage}
            pattern="Accommodations/"
            pagedData={props.pageImage}
            pageChange={props.pageChange}
            activePage={props.activePage}
            profileImageList={props.AccommodationItemImages}
          />
        </Grid.Column>
      </Grid>
      <Divider />
      <AccomodationPriceList
        itemServiceTypeData={props.itemServiceTypeData}
        openModalPrice={props.openModalPrice}
        addPrice={props.addPrice}
        handleClose={props.handleClose}
        addPriceDetails={props.addPriceDetails}
        accomodationPrice={props.accomodationPrice}
        currenciesList={props.currenciesList}
        handleChangeCostPriceDetails={props.handleChangeCostPriceDetails}
        handleChangePriceDetails={props.handleChangePriceDetails}
        handleChangePrice={props.handleChangePrice}
        handleChangePriceDate={props.handleChangePriceDate}
        rankType={props.rankType}
        handleChangeServiceType={props.handleChangeServiceType}
        serviceType={props.serviceType}
        accomodationPriceList={props.accomodationPriceList}
        addPriceToList={props.addPriceToList}
        headerTable={props.headerTable}
        editPrice={props.editPrice}
        deletePrice={props.deletePrice}
        errors={props.errors}
        disablePage={props.disablePage}
        itemName={props.accomodationItem.Desc}
        servicePriceName={props.serviceTypeModify}
      />
    </Segment>
  </div>
);

accomodationItemForm.propTypes = {
  SimpleAccommodationItemFacilities: PropTypes.array,
  AccommodationItemImages: PropTypes.array,
  Facilities: PropTypes.array,
  ProfileImages: PropTypes.array,
  itemTypeData: PropTypes.array,
  itemServiceTypeData: PropTypes.object,
  accomodationItem: PropTypes.object,
  handleChange: PropTypes.func,
  buttonBack: PropTypes.func,
  openModalPrice: PropTypes.bool,
  addPrice: PropTypes.func,
  handleClose: PropTypes.func,
  addPriceDetails: PropTypes.func,
  accomodationPrice: PropTypes.object,
  currenciesList: PropTypes.array,
  handleChangeCostPriceDetails: PropTypes.func,
  handleChangePriceDetails: PropTypes.func,
  handleChangePrice: PropTypes.func,
  handleChangePriceDate: PropTypes.func,
  rankType: PropTypes.func,
  handleChangeServiceType: PropTypes.func,
  serviceType: PropTypes.string,
  accomodationPriceList: PropTypes.array,
  addPriceToList: PropTypes.func,
  headerTable: PropTypes.array,
  editPrice: PropTypes.func,
  onChangeCheckbox: PropTypes.func,
  deletePrice: PropTypes.func,
  saveAccommodationItem: PropTypes.func,
  title: PropTypes.string,
  modalItem: PropTypes.func,
  listData: PropTypes.array,
  itemTypeList: PropTypes.array,
  deleteData: PropTypes.func,
  openDetail: PropTypes.func,
  handleChangePrimaryImage: PropTypes.func,
  errors: PropTypes.object,
  backPage: PropTypes.func,
  contentButton: PropTypes.string,
  disablePage: PropTypes.bool,
  activePage: PropTypes.number,
  pageChange: PropTypes.func,
  listDataFacilities: PropTypes.array,
  pageFacilities: PropTypes.array,
  listImage: PropTypes.array,
  pageImage: PropTypes.array,
  accomodationProfileName: PropTypes.string,
  serviceTypeModify: PropTypes.string,
  deleteImage: PropTypes.func,
};
export default accomodationItemForm;
