import React from 'react';
const initialAirport = listAirport => {
  listAirport = [...listAirport].map(e => ({
    key: e.Id,
    value: e.Id,
    content: (
      <div style={{ color: 'gray' }}>
        <b>
          {e.City ? `${e.City.Name},` : ''}
          {e.Country ? `${e.Country.Name}` : ''}
        </b>
        <br />
        {e.Code}, {e.Name}
      </div>
    ),
    completeObj: e,
  }));
  let popular = listAirport.filter(airport => airport.completeObj.IsPopular);
  return { popular, all: listAirport };
};

export { initialAirport };
