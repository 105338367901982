import React from 'react';
import { Dimmer } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {
  // getTransportationListAction,
  resetTransportationAction,
  getTemplateTransportationProfileAction,
} from '../../shared/actions/transportationAction';
import {
  getSupplierListAction,
  resetAccomodationAction,
} from '../../shared/actions/accommodationAction';
import {
  getExportTransportationProfileAction,
  deleteTransportationProfileIdAction,
} from '../transportationAction';
import Loader from '../../../components/loader';
import TransportationList from '../../../components/globalListVew/globalListComponent';
import ModalAlert from '../../../components/modal/modalAlert';
import PropTypes from 'prop-types';
import ModalConfirm from '../../../components/modal/modalConfirmationTwoButton';
class TransportationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transportationList: [],
      loadingTransportation: true,
      failedTransportation: false,
      modalOpen: false,
      openModalConfirm: false,
      message: '',
      transportId: null,
      loadingDeleteTransportation: false,
    };
  }

  static propTypes = {
    // getTransportationListAction: PropTypes.func,
    statusTransportation: PropTypes.string,
    listTransportation: PropTypes.array,
    resetTransportationAction: PropTypes.func,
    getTemplateTransportationProfileAction: PropTypes.func,
    getTransportationProfileTemplateStatus: PropTypes.string,
    getTransportationProfileTemplate: PropTypes.string,
    getTransportationProfileExportStatus: PropTypes.string,
    getTransportationProfileExport: PropTypes.string,
    getExportTransportationProfileAction: PropTypes.func,
    deleteTransportationProfileIdAction: PropTypes.func,
    deleteTransportationStatus: PropTypes.string,
    getSupplierListAction: PropTypes.func,
    resetAccomodationAction: PropTypes.func,
  };

  handleOpen = () => this.setState({ modalOpen: true });
  handleClose = () => this.setState({ failedTransportation: false });
  componentDidMount = () => {
    // this.props.getTransportationListAction();
    this.props.getSupplierListAction('TransportationProfiles');
  };
  componentDidUpdate = () => {
    if (this.props.statusTransportation === 'success') {
      this.setState({
        transportationList: this.props.listTransportation,
      });
      this.setState({ loadingTransportation: false });
      // this.props.resetTransportationAction();
      this.props.resetAccomodationAction();
    } else if (this.props.statusTransportation === 'failed') {
      this.setState({
        failedTransportation: true,
        contentAlert:
          'Please check your internet connection and Refresh Page [Ctrl+R]',
        headerAlert: 'Failed',
      });
      this.setState({ loadingTransportation: false });
      this.props.resetTransportationAction();
    }

    if (this.props.getTransportationProfileTemplateStatus === 'success') {
      var templateTransportation = this.props.getTransportationProfileTemplate;
      var elemenTemplateTransportation = document.createElement('a');
      var TemplateTransportation = new Blob([templateTransportation], {
        type: 'text/plain',
      });
      elemenTemplateTransportation.href = URL.createObjectURL(
        TemplateTransportation
      );
      elemenTemplateTransportation.download =
        'Transportation Profile Template.txt';
      elemenTemplateTransportation.click();
      this.setState({ loadingTransportation: false });
      this.props.resetTransportationAction();
    } else if (this.props.getTransportationProfileTemplateStatus === 'failed') {
      this.setState({ loadingTransportation: false });
      this.props.resetTransportationAction();
    }

    if (this.props.getTransportationProfileExportStatus === 'success') {
      var exportTransportation = this.props.getTransportationProfileExport;
      var elementTransportation = document.createElement('a');
      var fileAccomdation = new Blob([exportTransportation], {
        type: 'text/plain',
      });
      elementTransportation.href = URL.createObjectURL(fileAccomdation);
      elementTransportation.download = 'Transportation Export.txt';
      elementTransportation.click();
      this.setState({ loadingTransportation: false });
      this.props.resetTransportationAction();
    } else if (this.props.getTransportationProfileExportStatus === 'failed') {
      this.setState({ loadingTransportation: false });
      this.props.resetTransportationAction();
    }
    if (this.props.deleteTransportationStatus === 'success') {
      this.setState({
        failedTransportation: true,
        contentAlert: 'transportation was successfully deleted',
        headerAlert: 'Successfully',
        loadingDeleteTransportation: false,
      });
      this.props.resetTransportationAction();
      // this.props.getTransportationListAction();
      this.props.getSupplierListAction('TransportationProfiles');
    } else if (this.props.deleteTransportationStatus === 'failed') {
      this.setState({
        failedTransportation: true,
        contentAlert:
          'Please check your internet connection and Refresh Page [Ctrl+R]',
        headerAlert: 'Failed',
        loadingDeleteTransportation: false,
      });
      this.props.resetTransportationAction();
    }
  };

  buttonNew = () => {
    // eslint-disable-next-line
    this.props.history.push('/Transportation-Profile');
  };
  buttonEdit = e => {
    let Id = e.target.value;
    // eslint-disable-next-line
    this.props.history.push(`/Transportation-Profile/Edit/${Id}`);
  };
  buttonUploadData = () => {
    // eslint-disable-next-line
    this.props.history.push(`/Upload-Master-Data/${'Transportation'}`);
  };
  buttonDelete = () => {
    this.setState({ loadingDeleteTransportation: true });
    this.props.deleteTransportationProfileIdAction(this.state.transportId);
    this.closeModalConfirm();
  };
  buttonDownloadTemplate = () => {
    this.props.getTemplateTransportationProfileAction();
    this.setState({ loadingTransportation: true });
  };
  buttonExport = () => {
    this.props.getExportTransportationProfileAction();
    this.setState({ loadingTransportation: true });
  };
  handleDeleteProfile = e => {
    let transportationName = this.state.transportationList.find(
      // eslint-disable-next-line
      item => item.Id == e.target.value
    ).Name;
    this.setState({
      transportId: e.target.value,
      openModalConfirm: true,
      message: `Are you sure want to delete '${transportationName}'?`,
    });
  };
  closeModalConfirm = () => {
    this.setState({ openModalConfirm: false });
  };
  render() {
    let {
      loadingTransportation,
      failedTransportation,
      openModalConfirm,
      message,
      headerAlert,
      contentAlert,
      loadingDeleteTransportation,
    } = this.state;
    let loading = loadingTransportation || loadingDeleteTransportation;
    let openModal = failedTransportation;
    return (
      <div>
        <Dimmer.Dimmable blurring dimmed={loading}>
          <Loader loading={loading} />
          <TransportationList
            data={this.state.transportationList}
            type="transportation"
            filterBy="Name"
            buttonNew={this.buttonNew}
            butttonExport={this.buttonExport}
            buttonUploadData={this.buttonUploadData}
            buttonDownloadTemplate={this.buttonDownloadTemplate}
            buttonDelete={this.handleDeleteProfile}
            buttonEdit={this.buttonEdit}
          />
          <ModalAlert
            openModal={openModal}
            handleClose={this.handleClose}
            header={headerAlert} //"Failed"
            content={contentAlert} //"Please check your internet connection and Refresh Page [Ctrl+R]"
            nameButton="OK"
          />
          <ModalConfirm
            openModal={openModalConfirm}
            size="mini"
            close={this.closeModalConfirm}
            confirm={this.buttonDelete}
            message={message}
            header="Confirmation"
          />
        </Dimmer.Dimmable>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    // listTransportation: state.TransportationStore.getTransportationList,
    // statusTransportation: state.TransportationStore.getTransportationListStatus,
    listTransportation: state.AccomodationStore.getSupplierList,
    statusTransportation: state.AccomodationStore.getSupplierListStatus,
    getTransportationUnitExport:
      state.TransportationPageStore.getTransportationUnitExport, //Page
    getTransportationUnitTemplate:
      state.TransportationStore.getTransportationUnitTemplate,
    getTransportationProfileTemplate:
      state.TransportationStore.getTransportationProfileTemplate,
    getTransportationProfileTemplateStatus:
      state.TransportationStore.getTransportationProfileTemplateStatus,
    getTransportationProfileExportStatus:
      state.TransportationPageStore.getTransportationProfileExportStatus, //Page
    getTransportationProfileExport:
      state.TransportationPageStore.getTransportationProfileExport, //Page
    deleteTransportationStatus:
      state.TransportationPageStore.deleteTransportationStatus, //Page
  };
}
export default connect(
  mapStateToProps,
  {
    // getTransportationListAction,
    resetTransportationAction,
    getTemplateTransportationProfileAction,
    getExportTransportationProfileAction,
    deleteTransportationProfileIdAction,
    getSupplierListAction,
    resetAccomodationAction,
  }
)(TransportationPage);
