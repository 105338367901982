import { emailValidation } from '../../../../shared/services/validation/emailValidation';
const copyObject = obj => {
  let newObj = JSON.stringify(obj);
  return JSON.parse(newObj);
};

const employeeValidation = (state, error, companyId) => {
  let checkErrors = copyObject(error);
  checkErrors.FirstName.error = state.FirstName === '';
  checkErrors.LastName.error = state.LastName === '';
  checkErrors.PhoneNbr.error = state.PhoneNbr === '';
  checkErrors.Email.error = state.Email === '' || !emailValidation(state.Email);
  checkErrors.Address.error = state.Address === '';
  if (companyId.charAt(0) !== '1') {
    checkErrors.PositionId.error = state.PositionId === '';
    checkErrors.Gender.error = state.Gender === '';
    if (companyId !== '30000') {
      checkErrors.AccountManagerProfileId.error =
        state.AccountManagerProfileId === '';
    }
  }
  checkErrors.isError =
    checkErrors.FirstName.error ||
    checkErrors.LastName.error ||
    checkErrors.PhoneNbr.error ||
    checkErrors.Email.error ||
    checkErrors.Gender.error ||
    checkErrors.Address.error ||
    checkErrors.AccountManagerProfileId.error ||
    checkErrors.PositionId.error;
  return checkErrors;
};

export { employeeValidation };
