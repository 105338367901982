const styles = {
  paddingTop0: {
    paddingTop: '0px',
  },
  paddingLeftSegmentCircle: {
    paddingLeft: '35px',
  },
  marginBottom0: {
    marginBottom: '0px',
  },
  marginTop5: {
    marginTop: '5px',
  },
  paddingBottom5: {
    paddingBottom: '5px',
  },
};

export default styles;
