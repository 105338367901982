import React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Segment, Grid, Header, Icon } from 'semantic-ui-react';
import styles from '../../../../../assets/styles/styleCreateTourTransaction';
import CollapseButton from '../../../../../components/button/collapseButton';
import { Collapse } from 'react-collapse';
import DailyOrderedItems from './components/DailyOrderedItems';
/* import {
  getResourceBookingByTourTransactionCodeApi,
  getResourceBookingDetailByResourceBookingIdApi,
} from '../../../../../api/resourceBooking/resourceBookingApi';
import { restructureResourceBooking } from './services/restructureData'; */
// import { dataDummyResource } from './constants/dataDummy';

const OrderedItems = props => {
  // ==================== initial ====================
  const { dailyProgram } = props;
  const [orderedItem, setOrderedItem] = useState([]);
  const [openCollapse, setOpenCollapse] = useState(false);

  // ==================== handler ====================
  const handleCollapse = () => setOpenCollapse(!openCollapse);

  const convertOrderedItem = dailyPrograms => {
    if ('OrderedItems' in dailyPrograms[0]) {
      let orderedItem = dailyPrograms
        .filter(e => e.OrderedItems.length !== 0)
        .map(daily => {
          return {
            day: daily.Day,
            date: daily.Date,
            items: daily.OrderedItems,
          };
        });
      setOrderedItem(orderedItem);
    }
  };

  // ==================== lifecycle ====================
  /*   useEffect(() => {
    if (dailyProgram.length > 0) {
      getResourceBookingByTourTransactionCodeApi(id).then(result1 =>
        getResourceBookingDetailByResourceBookingIdApi(result1.data.Id).then(
          result2 => {
            setResourceBooking(
              restructureResourceBooking(result2.data, dailyProgram)
            );
          }
        )
      );
    }
    // eslint-disable-next-line
  }, [dailyProgram]); */

  useEffect(() => {
    if (dailyProgram.length > 0) {
      convertOrderedItem(dailyProgram);
    }
  }, [dailyProgram]);

  return (
    <Segment>
      <div style={styles.marginContentInsideCard}>
        <Grid verticalAlign="middle">
          <Grid.Row style={styles.noPaddingBottom}>
            <Grid.Column width={14}>
              <Header as="h3" style={styles.marginHeader}>
                <Icon name="clipboard list" style={{ color: '#38af95' }} />
                Ordered Items
              </Header>
            </Grid.Column>
            <Grid.Column width={2} textAlign="left">
              <CollapseButton
                isCollapse={openCollapse}
                handleCollapse={handleCollapse}
                name="openCollapse"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Collapse isOpened={openCollapse} style={{ marginTop: '0px' }}>
          <DailyOrderedItems orderedItem={orderedItem} />
        </Collapse>
      </div>
    </Segment>
  );
};

OrderedItems.propTypes = {
  id: PropTypes.string,
  dailyProgram: PropTypes.array,
};

export default OrderedItems;
