import {
  POST_UPLOAD_PREVIEW_ACCOMODATION_PROFILE,
  RESET_UPLOAD_DATA_MASTER,
  POST_UPLOAD_PREVIEW_EXCURSION_OBJECT,
  POST_UPLOAD_PREVIEW_RESTAURANT_PROFILE,
  POST_UPLOAD_PREVIEW_ACCOMODATION_ITEM,
  POST_UPLOAD_PREVIEW_RESTAURANT_ITEM,
  POST_UPLOAD_PREVIEW_TRANSPORTATION_PROFILE,
  POST_UPLOAD_PREVIEW_RESTAURANT_PRICE,
  POST_UPLOAD_PREVIEW_EXCURSION_PRICE,
  POST_UPLOAD_COMMIT,
} from './constant/actionTypes';
import uploadMasterDataPreviewAccomodationProfileApi from '../../api/uploadMasterDataPreviewApi/uploadMasterDataPreviewAccomodationProfileApi';
import uploadMasterDataCommitAccomodationProfileApi from '../../api/uploadMasterDataCommitApi/uploadMasterAccomodationProfileApi';
import uploadMasterExcursionObjectApi from '../../api/uploadMasterDataCommitApi/uploadMasterExcursionObjectApi';
import uploadMasterDataPreviewExcursionObjectApi from '../../api/uploadMasterDataPreviewApi/uploadMasterDataPreviewExcursionObjectApi';
import uploadMasterRestaurantProfileApi from '../../api/uploadMasterDataCommitApi/uploadMasterRestaurantProfileApi';
import uploadMasterDataPreviewRestaurantProfileApi from '../../api/uploadMasterDataPreviewApi/uploadMasterDataPreviewRestaurantProfileApi';
import uploadMasterDataPreviewTransportationProfileApi from '../../api/uploadMasterDataPreviewApi/uploadMasterDataPreviewTransportationProfileApi';
import uploadMasterTransportationProfileApi from '../../api/uploadMasterDataCommitApi/uploadMasterTransportationProfileApi';
import uploadMasterAccomodationItemApi from '../../api/uploadMasterDataCommitApi/uploadMasterAccomodationItemApi';
import uploadMasterDataPreviewAccomodationItemApi from '../../api/uploadMasterDataPreviewApi/uploadMasterDataPreviewAccomodationItemApi';
import uploadMasterRestaurantItemApi from '../../api/uploadMasterDataCommitApi/uploadMasterRestaurantItemApi';
import uploadMasterDataPreviewRestaurantItemApi from '../../api/uploadMasterDataPreviewApi/uploadMasterDataPreviewRestaurantItemApi';
import uploadMasterDataPreviewTransportationUnitApi from '../../api/uploadMasterDataPreviewApi/uploadMasterDataPreviewTransportationUnitApi';
import uploadMasterTransportationUnitApi from '../../api/uploadMasterDataCommitApi/uploadMasterTransportationUnitApi';
import uploadMasterDataPreviewRestaurantPriceApi from '../../api/uploadMasterDataPreviewApi/uploadMasterDataPreviewRestaurantPriceApi';
import uploadMasterRestaurantPriceApi from '../../api/uploadMasterDataCommitApi/uploadMasterRestaurantPriceApi';
import uploadMasterDataPreviewExcursionPriceApi from '../../api/uploadMasterDataPreviewApi/uploadMasterDataPreviewExcursionPriceApi';
import uploadMasterExcursionPriceApi from '../../api/uploadMasterDataCommitApi/uploadMasterExcursionPriceApi';
const postMasterDataPreviewAccomodationProfileAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_UPLOAD_PREVIEW_ACCOMODATION_PROFILE,
      payload: uploadMasterDataPreviewAccomodationProfileApi(data),
    });
  };
};

const postMasterDataCommitAccomodationProfileAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_UPLOAD_COMMIT,
      payload: uploadMasterDataCommitAccomodationProfileApi(data),
    });
  };
};

const postMasterDataPreviewExcursionObjectAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_UPLOAD_PREVIEW_EXCURSION_OBJECT,
      payload: uploadMasterDataPreviewExcursionObjectApi(data),
    });
  };
};

const postMasterDataCommitExcursionObjectAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_UPLOAD_COMMIT,
      payload: uploadMasterExcursionObjectApi(data),
    });
  };
};

const postMasterDataPreviewRestaurantProfileAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_UPLOAD_PREVIEW_RESTAURANT_PROFILE,
      payload: uploadMasterDataPreviewRestaurantProfileApi(data),
    });
  };
};

const postMasterDataCommitRestaurantProfileAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_UPLOAD_COMMIT,
      payload: uploadMasterRestaurantProfileApi(data),
    });
  };
};

const postMasterDataPreviewTransportationProfileAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_UPLOAD_PREVIEW_TRANSPORTATION_PROFILE,
      payload: uploadMasterDataPreviewTransportationProfileApi(data),
    });
  };
};

const postMasterDataCommitTransportationProfileAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_UPLOAD_COMMIT,
      payload: uploadMasterTransportationProfileApi(data),
    });
  };
};

//--------------------------------------------------------------------
const postMasterDataPreviewAccomodationItemAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_UPLOAD_PREVIEW_ACCOMODATION_ITEM,
      payload: uploadMasterDataPreviewAccomodationItemApi(data),
    });
  };
};

const postMasterDataCommitAccomodationItemAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_UPLOAD_COMMIT,
      payload: uploadMasterAccomodationItemApi(data),
    });
  };
};

const postMasterDataPreviewRestaurantItemAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_UPLOAD_PREVIEW_RESTAURANT_ITEM,
      payload: uploadMasterDataPreviewRestaurantItemApi(data),
    });
  };
};

const postMasterDataCommitRestaurantItemAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_UPLOAD_COMMIT,
      payload: uploadMasterRestaurantItemApi(data),
    });
  };
};

const postMasterDataPreviewTransportationUnitAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_UPLOAD_PREVIEW_TRANSPORTATION_PROFILE,
      payload: uploadMasterDataPreviewTransportationUnitApi(data),
    });
  };
};

const postMasterDataCommitTransportationUnitAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_UPLOAD_COMMIT,
      payload: uploadMasterTransportationUnitApi(data),
    });
  };
};
//--------------------------------------------------------------------

const postMasterDataPreviewRestaurantPriceAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_UPLOAD_PREVIEW_RESTAURANT_PRICE,
      payload: uploadMasterDataPreviewRestaurantPriceApi(data),
    });
  };
};

const postMasterDataCommitRestaurantPriceAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_UPLOAD_COMMIT,
      payload: uploadMasterRestaurantPriceApi(data),
    });
  };
};

const postMasterDataPreviewExcursionPriceAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_UPLOAD_PREVIEW_EXCURSION_PRICE,
      payload: uploadMasterDataPreviewExcursionPriceApi(data),
    });
  };
};

const postMasterDataCommitExcursionPriceAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_UPLOAD_COMMIT,
      payload: uploadMasterExcursionPriceApi(data),
    });
  };
};

const resetUploadDataMasterAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_UPLOAD_DATA_MASTER,
    });
  };
};

export {
  postMasterDataPreviewAccomodationProfileAction,
  postMasterDataCommitAccomodationProfileAction,
  postMasterDataPreviewExcursionObjectAction,
  postMasterDataCommitExcursionObjectAction,
  postMasterDataPreviewRestaurantProfileAction,
  postMasterDataCommitRestaurantProfileAction,
  postMasterDataPreviewTransportationProfileAction,
  postMasterDataCommitTransportationProfileAction,
  postMasterDataPreviewAccomodationItemAction,
  postMasterDataCommitAccomodationItemAction,
  postMasterDataPreviewRestaurantItemAction,
  postMasterDataCommitRestaurantItemAction,
  postMasterDataPreviewTransportationUnitAction,
  postMasterDataCommitTransportationUnitAction,
  postMasterDataPreviewRestaurantPriceAction,
  postMasterDataCommitRestaurantPriceAction,
  postMasterDataPreviewExcursionPriceAction,
  postMasterDataCommitExcursionPriceAction,
  resetUploadDataMasterAction,
};
