import {
  GET_TOUR_OPERATOR_EMAIL_SETTING_ALL,
  GET_TOUR_OPERATOR_EMAIL_SETTING_BY_ID,
  POST_TOUR_OPERATOR_EMAIL_SETTING,
  PUT_TOUR_OPERATOR_EMAIL_SETTING,
  DELETE_TOUR_OPERATOR_EMAIL_SETTING,
  GET_TOUR_OPERATOR_EMAIL_SETTING_BY_OPERATOR,
  GET_PARTNER_EMPLOYEE,
} from '../../../actions/actionTypes';
import {
  getTourOperatorEmailSettingAllApi,
  getTourOperatorEmailSettingByIdApi,
  postTourOperatorEmailSettingApi,
  putTourOperatorEmailSettingApi,
  deleteTourOperatorEmailSettingApi,
  getTourOperatorEmailSettingByIdTourOperatorApi,
} from '../../../api/partnerProfile/emailSettingApi';
import { getPartnerEmployeeListApi } from '../../../api/partnerProfile/getPartnerEmployeeApi';

const getTourOperatorEmailSettingAll = () => {
  return dispatch => {
    return dispatch({
      type: GET_TOUR_OPERATOR_EMAIL_SETTING_ALL,
      payload: getTourOperatorEmailSettingAllApi(),
    });
  };
};

const getTourOperatorEmailSettingById = id => {
  return dispatch => {
    return dispatch({
      type: GET_TOUR_OPERATOR_EMAIL_SETTING_BY_ID,
      payload: getTourOperatorEmailSettingByIdApi(id),
    });
  };
};
//
const postTourOperatorEmailSetting = data => {
  return dispatch => {
    return dispatch({
      type: POST_TOUR_OPERATOR_EMAIL_SETTING,
      payload: postTourOperatorEmailSettingApi(data),
    });
  };
};
//
const putTourOperatorEmailSetting = (id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_TOUR_OPERATOR_EMAIL_SETTING,
      payload: putTourOperatorEmailSettingApi(id, data),
    });
  };
};
const deleteTourOperatorEmailSetting = id => {
  return dispatch => {
    return dispatch({
      type: DELETE_TOUR_OPERATOR_EMAIL_SETTING,
      payload: deleteTourOperatorEmailSettingApi(id),
    });
  };
};
//
const getTourOperatorEmailSettingByOperator = id => {
  return dispatch => {
    return dispatch({
      type: GET_TOUR_OPERATOR_EMAIL_SETTING_BY_OPERATOR,
      payload: getTourOperatorEmailSettingByIdTourOperatorApi(id),
    });
  };
};

const getPartnerEmployeeAction = id => {
  return dispatch => {
    return dispatch({
      type: GET_PARTNER_EMPLOYEE,
      payload: getPartnerEmployeeListApi(id),
    });
  };
};

export {
  getTourOperatorEmailSettingAll,
  getTourOperatorEmailSettingById,
  postTourOperatorEmailSetting,
  putTourOperatorEmailSetting,
  deleteTourOperatorEmailSetting,
  getTourOperatorEmailSettingByOperator,
  getPartnerEmployeeAction,
};
