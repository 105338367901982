import React from 'react';
import { Grid } from 'semantic-ui-react';
import styles from './Styles';
import PropTypes from 'prop-types';
import { convertDateFormat } from '../../../../scripts/moment';

const Header = props => {
  const { dates } = props;
  let stringMonth = '';
  let month1 = 0;
  let month2 = 0;
  return (
    <Grid style={{ width: '870px' }}>
      {dates.map((item, idx) => {
        stringMonth = idx === 0 ? convertDateFormat(item, 'MMMM') : stringMonth;
        idx === 0
          ? month1++
          : convertDateFormat(item, 'MMMM') === stringMonth
            ? month1++
            : month2++;
        return (
          idx === 6 && (
            <Grid.Row style={styles.noPadding}>
              <Grid.Column
                textAlign="center"
                key={idx}
                // width={month1 * 2}
                style={{
                  padding: '7px 0px',
                  background: 'rgba(56,175,149, 0.5)',
                  width: `${month1 * 124}px`,
                }}
              >
                <div>
                  <strong style={{ color: 'white' }}>{stringMonth}</strong>
                </div>
              </Grid.Column>
              {month2 ? (
                <Grid.Column
                  textAlign="center"
                  key={idx}
                  // width={month2 * 2}
                  style={{
                    padding: '7px 0px',
                    background: 'rgba(0,127,103, 0.5)',
                    width: `${month2 * 124}px`,
                  }}
                >
                  <div>
                    <strong style={{ color: 'white' }}>
                      {item && convertDateFormat(item, 'MMMM')}
                    </strong>
                  </div>
                </Grid.Column>
              ) : null}
            </Grid.Row>
          )
        );
      })}
      <Grid.Row style={{ textAlign: 'center', padding: '0px' }}>
        {dates.map((item, idx) => {
          return (
            <Grid.Column
              textAlign="center"
              key={idx}
              style={{ padding: '0px', width: '124px' }}
            >
              <div
                style={item ? styles.calender50 : styles.calender50withHeight}
              >
                {item && convertDateFormat(item, 'ddd')}
              </div>
              <div
                style={item ? styles.calender20 : styles.calender20withHeight}
              >
                {item && convertDateFormat(item, 'DD')}
              </div>
            </Grid.Column>
          );
        })}
      </Grid.Row>
    </Grid>
  );
};

Header.propTypes = {
  dates: PropTypes.array,
  withMonth: PropTypes.bool,
};

export default Header;
