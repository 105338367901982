import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import InputWithLabel from '../../../components/input/inputWithLabel';
const PlaceForm = props => {
  return (
    // <Grid>
    //   <Grid.Row>
    //     <Grid.Column width="16">
    <Form>
      <Form.Group>
        <Form.Field width="8">
          <InputWithLabel
            label="Code"
            type="text"
            name="Code"
            value={props.contentField.Code}
            handleChange={props.handleChange}
            required
            fluid={true}
            validate={props.errorInformation}
            placeholder="Code"
          />
        </Form.Field>
        <Form.Field width="8">
          <InputWithLabel
            label="Type"
            type="select"
            name="PlaceType"
            value={props.contentField.PlaceType}
            handleChange={props.handleChange}
            required
            options={props.listPlaceType}
            fluid={true}
            validate={props.errorInformation}
            placeholder="Type"
            inline={false}
          />
        </Form.Field>
      </Form.Group>
      <Form.Field>
        <InputWithLabel
          label="Name"
          type="textArea"
          name="Name"
          value={props.contentField.Name}
          handleChange={props.handleChange}
          required
          fluid={true}
          validate={props.errorInformation}
          placeholder="Name"
          inline={false}
        />
      </Form.Field>
      <Form.Group>
        <Form.Field width="8">
          <InputWithLabel
            label="Country"
            type="select"
            name="Country"
            value={props.contentField.AddressObject.Country}
            handleChange={props.handleChangeAddress}
            required
            options={props.listCountry}
            fluid={true}
            validate={props.errorInformation}
            placeholder="Country"
            inline={false}
          />
        </Form.Field>
        <Form.Field width="8">
          <InputWithLabel
            label="Region"
            type="select"
            name="Region"
            value={props.contentField.AddressObject.Region}
            handleChange={props.handleChangeAddress}
            required
            options={props.listRegion}
            fluid={true}
            validate={props.errorInformation}
            placeholder="Region"
            inline={false}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field width="8">
          <InputWithLabel
            label="City"
            type="select"
            name="City"
            value={props.contentField.AddressObject.City}
            handleChange={props.handleChangeAddress}
            required
            options={props.listCity}
            fluid={true}
            validate={props.errorInformation}
            placeholder="City"
            inline={false}
          />
        </Form.Field>
        <Form.Field width="8">
          <InputWithLabel
            label="Area"
            type="select"
            name="AreaId"
            value={props.contentField.AddressObject.AreaId}
            handleChange={props.handleChangeAddress}
            required
            options={props.listArea}
            fluid={true}
            validate={props.errorInformation}
            placeholder="Area"
            inline={false}
          />
        </Form.Field>
      </Form.Group>
      <Form.Field>
        <InputWithLabel
          label="Address"
          type="textArea"
          name="Address1"
          value={props.contentField.AddressObject.Address1}
          handleChange={props.handleChangeAddress}
          required
          fluid={true}
          validate={props.errorInformation}
          placeholder="Address"
          inline={false}
        />
      </Form.Field>
      <Form.Group>
        <Form.Field width="8">
          <InputWithLabel
            label="Address 2"
            type="text"
            name="Address2"
            value={props.contentField.AddressObject.Address2}
            handleChange={props.handleChangeAddress}
            fluid={true}
            placeholder="Address 2"
            inline={false}
          />
        </Form.Field>
        <Form.Field width="8">
          <InputWithLabel
            label="Address 3"
            type="text"
            name="Address3"
            value={props.contentField.AddressObject.Address3}
            handleChange={props.handleChangeAddress}
            fluid={true}
            placeholder="Address 3"
            inline={false}
          />
        </Form.Field>
      </Form.Group>
      <Form.Field>
        <InputWithLabel
          label="GPS Address"
          type="text"
          name="LandMark"
          value={props.contentField.AddressObject.LandMark}
          handleChange={props.handleChangeAddress}
          fluid={true}
          placeholder="GPS Address"
          inline={false}
        />
      </Form.Field>
    </Form>
    //     {/* </Grid.Column>
    //   </Grid.Row>
    // </Grid> */}
  );
};

PlaceForm.propTypes = {
  listCountry: PropTypes.array,
  listRegion: PropTypes.array,
  handleChange: PropTypes.func,
  handleChangeAddress: PropTypes.func,
  contentField: PropTypes.shape({
    Id: PropTypes.string,
    Name: PropTypes.string,
    Address: PropTypes.string,
    Code: PropTypes.string,
    CityId: PropTypes.string,
    PlaceType: PropTypes.string,
    AddressObject: PropTypes.shape({
      Address1: PropTypes.string,
      Address2: PropTypes.string,
      Address3: PropTypes.string,
      AreaId: PropTypes.string,
      PostalCode: PropTypes.string,
      LandMark: PropTypes.string,
      Country: PropTypes.string,
      Region: PropTypes.string,
      City: PropTypes.string,
    }),
  }),
  errorInformation: PropTypes.object,
  listPlaceType: PropTypes.array,
  listCity: PropTypes.array,
  listArea: PropTypes.array,
};
export default PlaceForm;
