import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';
// import TableItem from '../../table/accomodation/tableModalAccomodationProfile';
import TableItem from '../../table/accommodation/TableModalAccommodationProfile';

const modalFacilitiesAccomodationProfile = props => (
  <Modal
    dimmer="blurring"
    closeIcon
    inverted
    open={props.openModal}
    onClose={props.handleClose}
    size="tiny"
  >
    <Modal.Header>{props.header}</Modal.Header>
    <Modal.Content>
      <h5>{props.content}</h5>
      <TableItem listData={props.data} onChangeItem={props.onChangeItem} />
    </Modal.Content>
    <Modal.Actions>
      <Button positive content="Done" onClick={props.addItem} />
    </Modal.Actions>
  </Modal>
);

modalFacilitiesAccomodationProfile.propTypes = {
  openModal: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  content: PropTypes.string,
  data: PropTypes.array,
  onChangeItem: PropTypes.func,
  addItem: PropTypes.func,
};

export default modalFacilitiesAccomodationProfile;
