import apiClient from '../apiClient';
import {
  GET_GENERATE_AR_SUMMARY_URL,
  GET_AR_SUMMARY_LIST_URL,
  DOWNLOAD_AR_SUMMARY_URL,
} from '../apiUrl';
const getGenerateARSummaryApi = () => {
  return apiClient.get(GET_GENERATE_AR_SUMMARY_URL);
};
const getARSummaryListApi = (year, month) => {
  return apiClient.get(GET_AR_SUMMARY_LIST_URL(year, month));
};
const getARSummaryDownloadApi = (year, month) => {
  return apiClient.get(DOWNLOAD_AR_SUMMARY_URL(year, month));
};

export {
  getGenerateARSummaryApi,
  getARSummaryListApi,
  getARSummaryDownloadApi,
};
