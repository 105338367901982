const GET_CURRENCIES_URL = '/currencies/';
//LoginAuthencation
const LOGIN_AUTHENTICATION_URL = '/Account/Login/BackOffice';
//CompanyPendingApproval
const GET_LIST_PENDING_APPROVAL_COMPANY_URL =
  '/UserProfiles/RegistrationPendingApproval';
//PackageList
const GET_PUBLISHED_PACKAGE_URL =
  '/BookingTemplates/GetTemplates/FixedPackage/OnGoing';
//UserInfo
const GET_USER_INFO_URL = '/Account/UserInfo';
const GET_USER_ROLES_URL = '/Account/UserOnly/All';
//APPROVE, CANCEL & CONFIRM BOOKING CONFIRMATION
const UPDATE_TRANSACTION_URL = '/TourTransactions/Confirmation';
//APPROVE AND REJECT COMPANY REGISTRATION
const UPDATE_COMPANY_REGISTRATION_URL =
  '/UserProfiles/CompanyRegistrationApproval';
const GET_DRIVING_DURATION_URL = '/TourTransactions/Movement';
//PAYMENT HISTORY
const GET_LIST_PAYMENT_HISTORY_URL = (start, end, createStart, createEnd) =>
  '/TourTransactions/TransactionPaymentHistory?tourStartDate=' +
  start +
  '&tourUntilDate=' +
  end +
  '&createdStartDate=' +
  createStart +
  '&createdUntilDate=' +
  createEnd +
  '';

//BookingConfirmation
const GET_TRANSACTION_URL = (start, end, createStart, createEnd) =>
  '/TourTransactions/TransactionHistory?tourStartDate=' +
  start +
  '&tourUntilDate=' +
  end +
  '&createdStartDate=' +
  createStart +
  '&createdUntilDate=' +
  createEnd +
  '';

const GET_TRANSACTION_BY_PERIODE_URL = (start, end, createStart, createEnd) =>
  '/TourTransactions/TransactionInvoiceHistory?tourStartDate=' +
  start +
  '&tourUntilDate=' +
  end +
  '&createdStartDate=' +
  createStart +
  '&createdUntilDate=' +
  createEnd +
  '';

// Manual Confirmation Number
const GET_MANUAL_CONFIRMATION_NUMBER = (
  startCheckInDateRange,
  endCheckInDateRange
) =>
  '/TourTransactions/Admin/Accommodation/PendingConfirmationNumber?startCheckInDateRange=' +
  startCheckInDateRange +
  '&endCheckInDateRange=' +
  endCheckInDateRange;

// Confirmation Number
const POST_CONFIRMATION_NUMBER =
  '/TourTransactions/Supplier/Accommodation/Confirmation';

// Pending Confirmation Number
const GET_PENDING_CONFIRMATION_LIST_URL = (
  startCheckInDateRange,
  endCheckInDateRange
) =>
  '/TourTransactions/Admin/Accommodation/AutoConfirmConfirmationNumber?startCheckInDateRange=' +
  startCheckInDateRange +
  '&endCheckInDateRange=' +
  endCheckInDateRange;

// Request Room History Detail
const GET_REQUEST_ROOM_HISTORY_DETAIL_URL = id =>
  '/TourTransactions/Admin/Accommodation/RoomHistory/' + id;

// Calendar
const GET_BOOKING_CALENDAR_OPERATION_URL = (startDate, endDate) =>
  `/TourOperatorProfiles/Calendar/Operation?startDate=${startDate}&EndDate=${endDate}`;

// Payment Request
const GET_PAYMENT_REQUEST_LIST_API = (startDueDate, endDueDate) =>
  `/ResourceBookingReservationPayment?StartDueDate=${startDueDate}&EndDueDate=${endDueDate}`;

// Payment Request Detail
const GET_PAYMENT_REQUEST_BY_ID_API = id =>
  `/ResourceBookingReservationPayment/${id}`;

// Pay Resource Booking
const PUT_PAY_RESOURCE_BOOKING_API =
  '/ResourceBookingReservationPayment/PayResourceBooking';

//Accomodation List
const GET_ACCOMODATION_LIST_URL = '/AccommodationProfiles/';
//Transportation List
const GET_TRANSPORTATION_LIST_URL = '/TransportationProfiles/';
//Excursion List
const GET_EXCURSION_LIST_URL = '/AttractionObjects/';
const GET_EXCURSION_URL = id => '/AttractionObjects/ById/' + id;
const POST_EXCURSION_URL = '/AttractionObjects';
const PUT_EXCURSION_URL = id => '/AttractionObjects/' + id;
const GET_EXCURSION_PRICE_TYPE_URL =
  'ServicePrices/SercvicePriceTypes/Attraction';

//Customer Profile
// const GET_CUSTOMER_PROFILE_URL = type =>
//   '/Companies/CompanyProfile/Admin/ByType?type=' + type;
const GET_CUSTOMER_PROFILE_URL = type =>
  '/Companies/CompanyProfile/AdminSimplified?type=' + type;
const DELETE_COMPANIES_BY_ID_URL = '/Companies/Remove';
//User Access
const GET_USER_ACCESS_LIST_URL = '/Account/UserOnly/All';
const GET_LOCKED_USER_LIST_URL = '/Account/LockedUser/List';

//Exchange Rate
const GET_EXCHANGE_RATE_LIST_URL = '/ExchangeRates/';
//System Configuration
const GET_SYSTEM_MARGIN_LIST_URL = code =>
  '/BaseMargins/Get/ByCompanyCode?CompanyCode=' + code;
const GET_TOUR_OPERATOR_MARGIN_BY_PROFILE_ID_URL = id =>
  '/TourOperatorMargins/Get/ByProfileId?profileId=' + id;
const GET_TRANSACTION_HISTORY_BY_STATUS_URL = (
  start,
  end,
  createStart,
  createEnd,
  status
) =>
  '/TourTransactions/TransactionInvoiceHistory?tourStartDate=' +
  start +
  '&tourUntilDate=' +
  end +
  '&createdStartDate=' +
  createStart +
  '&createdUntilDate=' +
  createEnd +
  '&status=' +
  status;
const GET_TRANSACTION_BY_ID_URL = id =>
  '/TourTransactions/TransactionSummary?id=' + id;
const GET_TRANSACTION_PAYABLE_URL = id =>
  '/TourTransactions/SupplementItem/ByTransactionId/Payable?tourTransactionId=' +
  id;

const GET_ACCOMODATION_BYPROFILE_ID_URL = Id =>
  '/AccommodationProfiles?id=' + Id;

const GET_ACCOMODATION_TYPES_URL = '/AccommodationTypes';
const GET_ACCOMODATION_FACILITIES_URL = '/AccommodationFacilities';
const GET_ACCOMODATION_LOCATION_URL = '/AccommodationLocations';
const GET_TOUR_TYPE_URL = '/TourTransactions/TourTypeList';
const GET_TOUR_PAX_TYPE_URL = '/TourTransactions/TourPaxTypeList';

const GET_ACCOMODATION_ITEM_BYPROFILE_ID_URL = Id =>
  '/AccommodationItems/ByProfile?profileId=' + Id;
// const GET_ACCOMODATION_ITEM_BYPROFILE_ID_REQUEST_DATE_URL = (
//   id,
//   reqDate,
//   endDate
// ) =>
//   `/AccommodationItems/ByProfile?profileId=${id}&requestedDate=${reqDate}&enddate=${
//     endDate ? endDate : reqDate
//   }`;
const GET_ACCOMODATION_ITEM_BYPROFILE_ID_REQUEST_DATE_URL =
  '/AccommodationItems/byprofile';
const GET_ACCOMODATION_ITEM_BY_SERVICE_TYPE_ID_URL = Id =>
  '/AccommodationItems/' + Id;
const GET_ACCOMODATION_ITEM_TYPES_URL = '/AccommodationItemTypes';
const GET_ACCOMODATION_ITEM_SERVICE_TYPE_LIST_URL =
  '/AccommodationItems/ServiceTypeList';
const GET_COUNTRY_URL = '/countries';
const GET_CITY_URL = '/cities/CityInCountry/';
const GET_REGION_BY_COUNTRY_URL = Id => '/Regions/Get/ByCountry/' + Id;
const GET_CITY_BY_REGION_URL = Id => '/Cities/CityInRegion?regionId=' + Id;
const GET_AREA_BY_CITY_URL = Id => '/Areas/ByCity?cityId=' + Id;

const POST_ACCOMODATION_URL = '/AccommodationProfiles/RegisterAccommodation';
const PUT_ACCOMODATION_URL = id =>
  '/AccommodationProfiles/UpdateAccommodation?id=' + id;
const PUT_ACCOMODATION_ITEM_URL = id => '/AccommodationItems/' + id;
const POST_ACCOMODATION_ITEM_URL = '/AccommodationItems/Add';
const GET_TRANSPORTATION_BY_PROFILE_ID_URL = Id =>
  '/TransportationProfiles/' + Id;
const GET_TRANSPORTATION_BY_FROM_TO_DATE_URL = (from, to, date) =>
  '/TransportationUnits/TransportationService?fromCity=' +
  from +
  '&toCities=' +
  to +
  '&requestedDate=' +
  date;
const DELETE_TRANSPORTATION_BY_PROFILE_ID_URL = Id =>
  '/TransportationProfiles/' + Id;
const POST_TRANSPORTATION_URL = '/TransportationProfiles';
const GET_TRANSPORTATION_UNIT_HEADER_BY_ID_URL = id =>
  '/TransportationUnitHeaders/' + id;
const GET_TRANSPORTATION_UNIT_BY_HEADER_ID_URL = id =>
  '/TransportationUnits/ByUnitHeader?transportationUnitHeaderId=' + id;
const GET_LIST_TRANSPORTATION_UNIT_BY_PROFILE_ID_URL = Id =>
  '/TransportationUnitHeaders/ByProfile?transportationProfileId=' + Id;
const POST_TRANSPORTATION_UNIT_HEADER_URL = '/TransportationUnitHeaders/Add';
const PUT_TRANSPORTATION_UNIT_HEADER_URL = Id =>
  '/TransportationUnitHeaders/Update?id=' + Id;
const GET_ACCOMODATION_TEMPLATE = '/AccommodationProfiles/GetTemplate';
const GET_ACCOMODATION_EXPORT_DATA = '/AccommodationProfiles/GetDataFile';
const DELETE_ACCOMODATION_BY_PROFILE_ID_URL = Id =>
  '/AccommodationProfiles/Remove?id=' + Id;
const GET_EXCURSION_TYPE_URL = '/AttractionTypes';
const GET_TRANSPORTATION_CATAGORIES_URL = '/TransportationProfileCategories';
const PUT_TRANSPORTATION_URL = id => '/TransportationProfiles?id=' + id;
const GET_RESTAURANT_LIST_URL = '/RestaurantProfiles/';
const GET_RESTAURANT_BY_PROFILE_ID_URL = Id => '/RestaurantProfiles/ById/' + Id;
const GET_RESTAURANT_ITEM_BY_PROFILE_ID_URL = Id =>
  '/RestaurantItems/ByProfileId?profileId=' + Id;
const DELETE_RESTAURANT_ITEM_BY_ID_URL = Id =>
  '/RestaurantItems/Remove?id=' + Id;

const DELETE_RESTAURANT_BY_PROFILE_ID_URL = Id => '/RestaurantProfiles/' + Id;
const GET_MENU_CLASS_URL = '/RestaurantItems/MenuClasses';
const GET_RESTAURANT_CLASS_URL = '/RestaurantProfileSpecializations';
const GET_RESTAURANT_CATEGORY_URL = '/RestaurantItems/MenuCategories';
//const GET_RESTAURANT_TYPE_URL = '/RestaurantItems/MenuTypes';
const GET_RESTAURANT_TYPE_URL = '/RestaurantTypes';
const GET_SERVICE_PRICE_URL = id => '/ServicePrices/ByServiceItemId/' + id;
const GET_SERVICE_PRICE_TYPE_URL = type =>
  '/ServicePrices/ServicePriceTypes/' + type;
const POST_SERVICE_PRICE_URL = type => '/ServicePrices/Add/' + type;
const PUT_SERVICE_PRICE_URL = (type, id) =>
  '/ServicePrices/Edit/' + type + '/' + id;
const DELETE_SERVICE_PRICE_URL = id => 'ServicePrices/Remove?id=' + id;
//SYSTEM CONFIGURATION AREA
const GET_AREA_LIST_URL = '/Areas';
const POST_AREA_URL = '/Areas/Add';
const PUT_AREA_URL = Id => '/Areas/Update?id=' + Id;
const DELETE_AREA_URL = Id => '/Areas/Remove?id=' + Id;
const GET_AREA_BY_ID_URL = Id => '/Areas/' + Id;

//SYSTEM CONFIGURATION CITY
const GET_CITY_LIST_URL = '/Cities';
const POST_CITY_URL = '/Cities/Add';
const PUT_CITY_URL = Id => '/Cities/Update?Id=' + Id;
const DELETE_CITY_URL = Id => '/Cities/Remove?Id=' + Id;
const GET_CITY_BY_ID_URL = Id => '/Cities?id=' + Id;

//SYSTEM CONFIGURATION REGION
const GET_REGION_LIST_URL = '/Regions';
const POST_REGION_URL = '/Regions/Add';
const PUT_REGION_URL = Id => '/Regions/Update?Id=' + Id;
const DELETE_REGION_URL = Id => '/Regions/Remove?Id=' + Id;
const GET_REGION_BY_ID_URL = Id => '/Regions/' + Id;

//SYSTEM CONFIGURATION PLACE
const GET_PLACE_LIST_URL = '/Places';
const POST_PLACE_URL = '/Places/Add';
const PUT_PLACE_URL = Id => '/Places/Edit?id=' + Id;
const DELETE_PLACE_URL = Id => '/Places/' + Id;
const GET_PLACE_BY_ID_URL = Id => '/Places/Get/ById/' + Id;
const GET_PLACE_BY_PLACE_TYPE_ID_URL = '/Places/Get/ByPlaceType/';

//SYSTEM CONFIGURATION COUNTRY
const GET_COUNTRY_LIST_URL = '/Countries';
const POST_COUNTRY_URL = '/Countries/Add';
const PUT_COUNTRY_URL = Id => '/Countries/Edit?id=' + Id;
const DELETE_COUNTRY_URL = Id => '/Countries/Remove?id=' + Id;
const GET_COUNTRY_BY_ID_URL = Id => '/Countries/' + Id;

const GET_PLACE_TYPE_URL = '/Places/PlaceTypes';
const POST_RESTAURANT_PROFILE_URL = '/RestaurantProfiles';
const PUT_RESTAURANT_PROFILE_URL = Id => '/RestaurantProfiles/' + Id;
const PUT_RESTAURANT_ITEM_URL = Id => '/RestaurantItems/Update?id=' + Id;
const POST_RESTAURANT_ITEM_URL = '/RestaurantItems/Add';
const GET_RESTAURANT_ITEM_URL = '/RestaurantItems';
const GET_RESTAURANT_ITEM_BY_ID_URL = id => '/RestaurantItems/' + id;
const GET_CUSTOMER_PROFILE_BY_CODE_URL = Code =>
  '/Companies/CompanyProfile/Admin/ByCode?code=' + Code;
const GET_CUSTOMER_PROFILE_BY_CODE_MANAGE_BY_SALES_URL = Code =>
  '/Companies/CompanyProfile/ByCode/ManageBySales?code=' + Code;
const GET_SUPERVISOR_BY_COMPANY_CODE_POSITION_ID_URL = (Code, PositionId) =>
  '/UserProfiles/Supervisor?companyCode=' + Code + '&positionId=' + PositionId;
const PUT_CUSTOMER_PROFILE_URL = Id =>
  '/Companies/UpdateCompany?companyCode=' + Id;
const GET_HISTORY_TRANSACTION_BY_CODE_URL = Code =>
  '/TourTransactions/TransactionHistoryForAdmin?companyCode=' + Code;
const GET_HISTORY_TRANSACTION_FOR_SALES_BY_CODE_URL = Code =>
  '/TourTransactions/TransactionHistoryForSales?companyCode=' + Code;
const GET_USER_DATA_BY_CODE_URL = Code =>
  '/UserProfiles/CompanyUsers/ByCompanyCode?companyCode=' + Code;
const GET_USER_DATA_BY_CODE_FOR_SALES_URL =
  '/UserProfiles/CompanyUsers/Sales/ByCompanyCode?companyCode=';
const GET_BALANCE_HISTORY_BY_COMPANY_CODE = Code =>
  '/BalanceHistories/BalanceHistory?companyCode=' + Code;
const POST_BALANCE_DETAIL_URL = '/BalanceHistories/NewTransaction';
const GET_CUSTOM_ENUM_BY_CATAGORIES = categories =>
  '/CustomEnums/Get/ByCategories/' + categories;

//SYSTEM CONFIGURATION CITY GROUP
const GET_CITY_GROUP_LIST_URL = '/CityGroups';
const POST_CITY_GROUP_URL = '/CityGroups/Add';
const PUT_CITY_GROUP_URL = Id => '/CityGroups/Update?id=' + Id;
const DELETE_CITY_GROUP_URL = Id => '/CityGroups/Remove?id=' + Id;
const GET_CITY_GROUP_BY_ID_URL = Id => '/CityGroups/' + Id;
const GET_CITY_IN_COUNTRY_URL = Id => '/Cities/CityInCountry?countryId=' + Id;
const POST_CITY_GROUP_DETAIL_URL = '/CityGroupDetails/Add';

const POST_SYSTEM_MARGIN_URL = '/BaseMargins/';
const PUT_SYSTEM_MARGIN_URL = Id => '/BaseMargins/' + Id;
const GET_SYSTEM_MARGIN_BY_ID_URL = Id => '/BaseMargins/Get/ById?Id=' + Id;
const DELETE_SYSTEM_MARGIN_BY_ID_URL = Id => '/BaseMargins/' + Id;
const GET_PAYMENT_SCHEME_URL = '/PaymentSchemes';
const POST_CUSTOMER_PROFILE_URL = '/Companies/CompanyRegister';
const PUT_CUSTOMER_PROFILE = Id => '/Companies/UpdateCompany?companyCode=' + Id;
// const GET_PACKAGES_URL = type =>
//   '/BookingTemplates/GetTemplates/' + type + 'Package/All';
const GET_PACKAGES_URL = type =>
  '/BookingTemplates/GetTemplates/' + type + '/All';
const GET_FIXED_PACKAGE = (type, id) =>
  '/BookingTemplates/GetTemplates/Admin/' + type + '/' + id;
const POST_PACKAGE_URL = type => '/BookingTemplates/' + type;
const PUT_PACKAGE_URL = id => '/BookingTemplates/SaveTemplate/Edit?id=' + id;
const DELETE_PACKAGE_URL = id => '/BookingTemplates/Remove?id=' + id;
const COPY_PACKAGE_URL = (type, id, startDate) =>
  '/BookingTemplates/' +
  type +
  'Package/Copy?id=' +
  id +
  '&startDate=' +
  startDate;
const UPLOAD_BROCHURE_PACKAGE_URL = id =>
  '/BookingTemplates/UploadBookingTemplateImage?id=' + id;
const PUT_GENERAL_INFO_PACKAGE_URL = id =>
  '/BookingTemplates/Edit/GeneralInfo?id=' + id;
const PUT_ALLOWED_PAX_PACKAGE_URL = id =>
  '/BookingTemplates/Edit/AllowedPax?id=' + id;
//IMAGE
const POST_UPLOAD_IMAGE_ACCOMODATION_PROFILE_URL =
  '/AccommodationProfiles/UploadAccommodationImage';
const POST_SET_PRIMARY_IMAGE_ACCOMODATION_PROFILE_URL = Id =>
  '/AccommodationProfiles/Images/SetPrimaryImage?imageId=' + Id;
const DELETE_IMAGE_ACCOMODATION_PROFILE_URL = Id =>
  '/AccommodationProfiles/Images/Remove?imageId=' + Id;

const UPLOAD_IMAGE_ACCOMODATION_ITEM_BY_ID_URL = Id =>
  '/AccommodationItems/UploadAccommodationItemImage?id=' + Id;
const SET_PRIMARY_IMAGE_ACCOMODATION_ITEM_URL = Id =>
  '/AccommodationItems/Images/SetPrimaryImage?imageId=' + Id;
//UPLOAD  PREVIEW ACCOMODATION
const UPLOAD_PREVIEW_ACCOMODATION_PROFILE_URL =
  '/AccommodationProfiles/PreviewUpload';
const UPLOAD_PREVIEW_ACCOMODATION_ITEM_URL =
  '/AccommodationItems/PreviewAccommodationItem';
// UPLOAD COMMIT ACCOMODATION
const UPLOAD_COMMIT_ACCOMODATION_PROFILE_URL =
  '/AccommodationProfiles/CommitUpload';
const UPLOAD_COMMIT_ACCOMODATION_ITEM_URL =
  '/AccommodationItems/CommitAccommodationItem';

//UPLOAD  PREVIEW EXCURSION
const UPLOAD_PREVIEW_EXCURSION_OBJECT_URL =
  '/AttractionObjects/PreviewAttractionObject';
const UPLOAD_PREVIEW_EXCURSION_PRICE_URL =
  '/AttractionObjects/PreviewAttractionObjectPrice';
// UPLOAD COMMIT EXCURSION
const UPLOAD_COMMIT_EXCURSION_OBJECT_URL =
  '/AttractionObjects/CommitAttractionObject';
const UPLOAD_COMMIT_EXCURSION_PRICE_URL =
  '/AttractionObjects/CommitAttractionPrice';
//
const UPLOAD_IMAGE_EXCURSION_URL = '/AttractionObjects/UploadAttractionPrice';
const POST_SET_PRIMARY_IMAGE_EXCURSION_URL = Id =>
  '/AttractionObjects/Images/SetPrimaryImage?imageId=' + Id;
const POST_UPLOAD_IMAGE_EXCURSION_BY_SERVICE_ITEM_ID_URL = Id =>
  '/AttractionObjects/UploadAttractionImage?serviceItemId=' + Id;

//UPLOAD  PREVIEW RESTAURANT
const UPLOAD_PREVIEW_RESTAURANT_PROFILE_URL =
  '/RestaurantProfiles/PreviewRestaurantProfile';
const UPLOAD_PREVIEW_RESTAURANT_ITEM_URL =
  '/RestaurantItems/PreviewRestaurantItems';
const UPLOAD_PREVIEW_RESTAURANT_PRICE_URL =
  '/RestaurantItems/PreviewRestaurantPrice';
// UPLOAD COMMIT RESTAURANT
const UPLOAD_COMMIT_RESTAURANT_ITEM_URL =
  '/RestaurantItems/CommitRestaurantItems';
const UPLOAD_COMMIT_RESTAURANT_PROFILE_URL =
  '/RestaurantProfiles/CommitRestaurantProfile';
const UPLOAD_COMMIT_RESTAURANT_PRICE_URL =
  '/RestaurantItems/CommitRestaurantPrice';
//UPLOAD IMAGE RESTAURANT
const SET_PRIMARY_IMAGE_RESTAURANT_ITEM_URL = '';
const UPLOAD_IMAGE_RESTAURANT_BY_SERIVICE_TYPE_ID = Id =>
  '/RestaurantItems/UploadRestaurantImage?serviceItemId=' + Id;

//UPLOAD  PREVIEW TRANSPORTATION
const UPLOAD_PREVIEW_TRANSPORTATION_PROFILE_URL =
  'TransportationProfiles/PreviewUpload';
const UPLOAD_PREVIEW_TRANSPORTATION_UNIT_URL =
  '/TransportationUnits/PreviewTransportationUnit';
// UPLOAD COMMIT TRANSPORTATION
const UPLOAD_COMMIT_TRANSPORTATION_PROFILE_URL =
  'TransportationProfiles/CommitUpload';
const UPLOAD_COMMIT_TRANSPORTATION_UNIT_URL =
  'TransportationUnits/CommitTransportationUnit';

const GET_PAYMENT_METHOD_LIST = '/PaymentMethodRelatedToCountries';
const GET_PAYMENT_METHOD_CHOICE_LIST = '/Invoices/Get/PaymentChoiceList';
const POST_CITY_GROUP_DETAIL_FULLADD_URL = Id => '/CityGroups/FullAdd?id=' + Id;
const GET_EXCURSION_OBJECT_EXPORT_DATA_URL = '/AttractionObjects/GetDataFile';
const GET_EXCURSION_PRICE_EXPORT_DATA_URL =
  '/AttractionObjects/GetPriceDataFile';
const GET_RESTAURANT_ITEM_EXPORT_DATA_URL = '/RestaurantItems/GetDataFile';
const GET_RESTAURANT_ITEM_PRICE_EXPORT_DATA_URL =
  '/RestaurantItems/GetPriceDataFile';
const GET_ACCOMODATION_ITEM_EXPORT_DATA_URL = '/AccommodationItems/GetDataFile';
const GET_TRANSPORTATION_UNIT_EXPORT_DATA_URL =
  '/TransportationUnits/GetDataFile';
const GET_TRANSPORTATION_PROFILE_EXPORT_DATA_URL =
  '/TransportationProfiles/GetDataFile';
const GET_RESTAURANT_PROFILE_EXPORT_DATA_URL =
  '/RestaurantProfiles/GetDataFile';

//const GET_PAYMENT_METHOD_ALL = '/PaymentMethodRelatedToCountries';
const PUT_PAYMENT_METHOD = Id =>
  '/PaymentMethodRelatedToCountries/Update?id=' + Id;
const POST_PAYMENT_METHOD_IN_COUNTRY_URL =
  '/PaymentMethodRelatedToCountries/Add';
const GET_PAYMENT_METHOD_IN_COUNTRY_URL = (countryId, paymentMethod) =>
  '/PaymentMethodRelatedToCountries?countryId=' +
  countryId +
  '&paymentMethod=' +
  paymentMethod;
const DELETE_PAYMENT_METHOD_IN_COUNTRY_URL = (countryId, paymentMethod) =>
  '/PaymentMethodRelatedToCountries/Remove?id=' +
  countryId +
  '&paymentMethod=' +
  paymentMethod;

// const GET_TEMPLATE_EXCURSION_OBJECT_DATA_URL =
//   '/AttractionObjects/Price/GetTemplate';
// const GET_TEMPLATE_EXCURSION_PRICE_DATA_URL =
//   '  /AttractionObjects/GetTemplate';
const GET_TEMPLATE_EXCURSION_OBJECT_DATA_URL = '/AttractionObjects/GetTemplate';
const GET_TEMPLATE_EXCURSION_PRICE_DATA_URL =
  '/AttractionObjects/Price/GetTemplate';
const GET_TEMPLATE_RESTAURANT_ITEM_PRICE_DATA_URL =
  '/RestaurantItems/GetTemplate/Price';
const GET_TEMPLATE_RESTAURANT_ITEM_DATA_URL = '/RestaurantItems/GetTemplate';
const GET_TEMPLATE_RESTAURANT_PROFILE_DATA_URL =
  '/RestaurantProfiles/GetTemplate';
const GET_TEMPLATE_ACCOMODATION_ITEM_DATA_URL =
  '/AccommodationItems/GetTemplate';
const GET_TEMPLATE_TRANSPORTATION_UNIT_DATA_URL =
  '/TransportationUnits/GetTemplate';
const GET_TEMPLATE_TRANSPORTATION_PROFILE_URL =
  '/TransportationProfiles/GetTemplate';

//IMAGE UPLOAD ACCOMODATION
const POST_IMAGE_EXCURSION_OBJECT_URL = Id =>
  '/AttractionObjects/UploadAttractionImage?serviceItemId=' + Id;
const POST_SET_PRIMARY_EXCRUSION_OBJECT_URL = Id =>
  '/AttractionObjects/Images/SetPrimaryImage?imageId=' + Id;
const POST_IMAGE_TRANSPORTATION_PROFILE_URL = Id =>
  '/TransportationProfiles/UploadTransportationImage?id=' + Id;
const POST_SET_PRIMARY_TRANSPORTATION_PROFILE_URL = Id =>
  '/TransportationProfiles/Images/SetPrimaryImage?imageId=' + Id;
const POST_IMAGE_RESTAURANT_ITEM_IMAGE_URL = Id =>
  '/RestaurantItems/UploadRestaurantImage?serviceItemId=' + Id;
const POST_SET_PRIMARY_IMAGE_RESTAURANT_ITEM_URL = Id =>
  '/RestaurantItems/Images/SetPrimaryImage?imageId=' + Id;
const POST_IMAGE_ACCOMODATION_ITEM_URL = Id =>
  '/AccommodationItems/UploadAccommodationItemImage?id=' + Id;
const POST_SET_PRIMARY_IMAGE_ACCOMODATION_ITEM_URL = Id =>
  '/AccommodationItems/Images/SetPrimaryImage?imageId=' + Id;
// const POST_IMAGE_TRANSPORTATION_UNIT_URL = Id =>
//   '/TransportationUnits/UploadTransportatioUnitImage?id=' + Id;
const POST_IMAGE_TRANSPORTATION_UNIT_URL = Id =>
  '/TransportationUnitHeaders/UploadTransportationUnitHeaderImage?id=' + Id;
const POST_IMAGE_NEW_TRANSPORTATION_UNIT_URL = Id =>
  '/TransportationUnits/UploadNewTransportatioUnitImage?code=' + Id;
const POST_SET_PRIMARY_IMAGE_TRANSPORTATION_UNIT_URL = Id =>
  '/TransportationUnits/Images/SetPrimaryImage?imageId=' + Id;
const POST_IMAGE_RESTAURANT_PROFILE_URL = Id =>
  '/RestaurantProfiles/UploadRestaurantProfileImage?id=' + Id;
const POST_SET_PRIMARY_ACCOMODATION_PROFILE_URL = Id =>
  '/AccommodationProfiles/Images/SetPrimaryImage?imageId=' + Id;
const POST_SET_PRIMARY_RESTAURANT_PROFILE_URL = Id =>
  '/RestaurantProfiles/Images/SetPrimaryImage?imageId=' + Id;
const POST_SET_PRIMARY_TRANSPORTATION_UNIT_HEADER_URL = Id =>
  '/TransportationUnitHeaders/Images/SetPrimaryImage?imageId=' + Id;

const POST_TRANSPORTATION_UNIT_BY_SERVICE_TYPE_ID_URL = Id =>
  '/TransportationUnits/Images?serviceItemId=' + Id;
const POST_IMAGE_TRANSPORTATION_TYPE_URL =
  '/TransportationTypes/UploadTransportationImages';
const GET_IMAGE_TRANSPORTATION_TYPE_BY_TRANSPORTATION_TYPE_AND_TRANSPORTATION_PROFILE_URL = (
  TypeId,
  ProfileId
) =>
  '/TransportationTypes/TransportationImages?transportationTypeId=' +
  TypeId +
  '&transportationProfileId=' +
  ProfileId;
const DELETE_IMAGE_TRANSPORTATION_TYPE_URL = Id =>
  '/TransportationTypes/RemoveTransportationImage?imageId=' + Id;

const GET_IMAGE_ALL_URL = '/Images';
const GET_IMAGE_BY_ID_URL = Id => '/Images/' + Id;
const PUT_IMAGE_BY_ID_URL = Id => '/Images.' + Id;
const POST_IMAGE_URL = '/Images';
const DELETE_IMAGE_BY_ID_URL = Id => '/Images' + Id;
const POST_IMAGE_BY_ENTITIES_AND_FILENAME_AND_HOST_URL = (
  entitiesType,
  fileName,
  hostUrl
) =>
  '/Images?entitiesType=' +
  entitiesType +
  '&fileName=' +
  fileName +
  '&hostUrl=' +
  hostUrl;
const GET_TRANSPORTATION_PROFILE_IMAGE_URL = '/TransportationProfileImages';
const GET_TRANSPORTATION_PROFILE_IMAGE_BY_ID_URL = Id =>
  '/TransportationProfileImages/' + Id;
const PUT_TRANSPORTATION_PROFILE_IMAGE_BY_ID_URL = Id =>
  '/TransportationProfileImages/' + Id;
const POST_TRANSPORTATION_PROFILE_IMAGE_URL = '/TransportationProfileImages';
const DELETE_TRANSPORTATION_PROFILE_IMAGE_URL = Id =>
  '/TransportationProfileImages/' + Id;
const DELETE_TRANSPORTATION_PROFILE_IMAGE_BY_ID_URL = imageId =>
  `/TransportationProfiles/Images/Remove?imageId=${imageId}`;
const GET_ACCOMODATION_PROFILE_IMAGE_URL = '/AccommodationProfileImages';
const GET_ACCOMODATION_PROFILE_IMAGE_BY_ID_URL = Id =>
  '/AccommodationProfileImages' + Id;
const PUT_ACCOMODATION_PROFILE_IMAGE_URL = Id =>
  '/AccommodationProfileImages' + Id;
const POST_ACCOMODATION_PROFILE_IMAGE_URL = '/AccommodationProfileImages';
const DELETE_ACCOMODATION_PROFILE_IMAGE_URL = Id =>
  '/AccommodationProfileImages' + Id;

const GET_READY_PACKAGE_ALL_URL =
  '/BookingTemplates/GetTemplates/ReadyPackage/All';
const POST_READY_PACKAGE_TEMPLATE_URL =
  '/BookingTemplates/SaveReadyPackageTemplate';
const GET_READY_PACKAGE_WITH_FILTER_URL = (
  cityId,
  desiredDays,
  tourCategoryId,
  tourPaxTypeId,
  accommodationType,
  accommodationRating,
  accommodationLocation,
  startingDate
) =>
  '/BookingTemplates/GetTemplates/ReadyPackage?cityId=' +
  cityId +
  '&desiredDays=' +
  desiredDays +
  '&tourCategoryId=' +
  tourCategoryId +
  '&tourPaxTypeId=' +
  tourPaxTypeId +
  '&accommodationType=' +
  accommodationType +
  '&accommodationRating=' +
  accommodationRating +
  '&accommodationLocation=' +
  accommodationLocation +
  '&startingDate=' +
  startingDate +
  '';
const GET_PACKAGE_BY_TYPE_AND_ID_URL = (Type, Id) =>
  '/BookingTemplates/GetTemplates/' + Type + '/' + Id + '';
const GET_PAYMENT_METHOD_BY_COUNTRY_ID_URL = Id =>
  '/PaymentMethodRelatedToCountries?countryId=' + Id;

//-------------------------------------------------------
const GET_MASTER_REFERENCE_ACCOMODATION_FACILITIES_LIST_URL =
  '/AccommodationFacilities';
const POST_MASTER_REFERENCE_ACCOMODATION_FACILITIES_URL =
  '/AccommodationFacilities/Add';
const PUT_MASTER_REFERENCE_ACCOMODATION_FACILITIES_URL = Id =>
  '/AccommodationFacilities/Update?id=' + Id;
const DELETE_MASTER_REFERENCE_ACCOMODATION_FACILITIES_URL = Id =>
  '/AccommodationFacilities/Remove?id=' + Id;

const GET_MASTER_REFERENCE_URL = name => '/' + name;
const POST_MASTER_REFERENCE_URL = name => '/' + name + '/Add';
const PUT_MASTER_REFERENCE_URL = (name, Id) => '/' + name + '/Update?id=' + Id;
const DELETE_MASTER_REFERENCE_URL = (name, Id) =>
  '/' + name + '/Remove?id=' + Id;
const GET_CANCELLATION_REASON_BY_TYPES_REFERENCE_URL = type =>
  '/CancellationReasons/ByType/' + type;

const POST_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_URL =
  '/AccommodationItemTypes/Add';
const PUT_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_URL = Id =>
  '/AccommodationItemTypes/Update?id=' + Id;
const DELETE_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_URL = Id =>
  '/AccommodationItemTypes/Remove?id=' + Id;

const GET_MASTER_REFERENCE_ACCOMODATION_LOCATION_LIST_URL =
  '/AccommodationLocations';
const POST_MASTER_REFERENCE_ACCOMODATION_LOCATION_URL =
  '/AccommodationLocations/Add';
const PUT_MASTER_REFERENCE_ACCOMODATION_LOCATION_URL = Id =>
  '/AccommodationLocations/Update?id=' + Id;
const DELETE_MASTER_REFERENCE_ACCOMODATION_LOCATION_URL = Id =>
  '/AccommodationLocations/Remove?id=' + Id;

const GET_MASTER_REFERENCE_ACCOMODATION_RATING_LIST_URL =
  '/AccommodationRatings';
const POST_MASTER_REFERENCE_ACCOMODATION_RATING_URL =
  '/AccommodationRatings/Add';
const PUT_MASTER_REFERENCE_ACCOMODATION_RATING_URL = Id =>
  '/AccommodationRatings/Update?id=' + Id;
const DELETE_MASTER_REFERENCE_ACCOMODATION_RATING_URL = Id =>
  '/AccommodationRatings/Remove?id=' + Id;

const GET_MASTER_REFERENCE_ACCOMODATION_TYPE_LIST_URL = '/AccommodationTypes';
const POST_MASTER_REFERENCE_ACCOMODATION_TYPE_URL = '/AccommodationTypes/Add';
const PUT_MASTER_REFERENCE_ACCOMODATION_TYPE_URL = Id =>
  '/AccommodationTypes/Update?id=' + Id;
const DELETE_MASTER_REFERENCE_ACCOMODATION_TYPE_URL = Id =>
  '/AccommodationTypes/Remove?id=' + Id;

const GET_MASTER_REFERENCE_ATTRACTION_TYPE_LIST_URL = '/AttractionTypes';
const POST_MASTER_REFERENCE_ATTRACTION_TYPE_URL = '/AttractionTypes/Add';
const PUT_MASTER_REFERENCE_ATTRACTION_TYPE_URL = Id =>
  '/AttractionTypes/Update?id=' + Id;
const DELETE_MASTER_REFERENCE_ATTRACTION_TYPE_URL = Id =>
  '/AttractionTypes/Remove?id=' + Id;

const GET_MASTER_REFERENCE_ATTRACTION_CATEGORIES_LIST_URL =
  '/AttracionCategories';
const POST_MASTER_REFERENCE_ATTRACTION_CATEGORIES_URL =
  '/AttracionCategories/Add';
const PUT_MASTER_REFERENCE_ATTRACTION_CATEGORIES_URL = Id =>
  '/AttracionCategories/Update?id=' + Id;
const DELETE_MASTER_REFERENCE_ATTRACTION_CATEGORIES_URL = Id =>
  '/AttracionCategories/Remove?id=' + Id;

const GET_MASTER_REFERENCE_CURRENCIES_LIST_URL = '/Currencies';
const POST_MASTER_REFERENCE_CURRENCIES_URL = '/Currencies/Add';
const PUT_MASTER_REFERENCE_CURRENCIES_URL = Id => '/Currencies/Update?id=' + Id;
const DELETE_MASTER_REFERENCE_CURRENCIES_URL = Id =>
  '/Currencies/Remove?id=' + Id;

const GET_MASTER_REFERENCE_CUSTOM_ENUM_LIST_URL = '/CustomEnums';
const GET_MASTER_REFERENCE_CUSTOM_ENUM_BY_CATEGORIES_URL = Id =>
  '/CustomEnums/Get/ByCategories/' + Id;
const POST_MASTER_REFERENCE_CUSTOM_ENUM_URL = '/CustomEnums/Add';
const PUT_MASTER_REFERENCE_CUSTOM_ENUM_URL = Id =>
  '/CustomEnums/Update?id=' + Id;
const DELETE_MASTER_REFERENCE_CUSTOM_ENUM_URL = Id =>
  '/CustomEnums/Remove?id=' + Id;

const GET_MASTER_REFERENCE_GUEST_CATEGORIES_LIST_URL = '/GuestCategories';
const POST_MASTER_REFERENCE_GUEST_CATEGORIES_URL = '/GuestCategories/Add';
const PUT_MASTER_REFERENCE_GUEST_CATEGORIES_URL = Id =>
  '/GuestCategories/Update?id=' + Id;
const DELETE_MASTER_REFERENCE_GUEST_CATEGORIES_URL = Id =>
  '/GuestCategories/Remove?id=' + Id;

const GET_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_LIST_URL = '/GuestIdentityTypes';
const POST_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_URL = '/GuestIdentityTypes/Add';
const PUT_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_URL = Id =>
  '/GuestIdentityTypes/Update?id=' + Id;
const DELETE_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_URL = Id =>
  '/GuestIdentityTypes/Remove?id=' + Id;

const GET_MASTER_REFERENCE_GUEST_TITLES_LIST_URL = '/GuestTitles';
const POST_MASTER_REFERENCE_GUEST_TITLES_URL = '/GuestTitles/Add';
const PUT_MASTER_REFERENCE_GUEST_TITLES_URL = Id =>
  '/GuestTitles/Update?id=' + Id;
const DELETE_MASTER_REFERENCE_GUEST_TITLES_URL = Id =>
  '/GuestTitles/Remove?id=' + Id;

const GET_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATIONS_LIST_URL =
  '/IdentityTypeConfigurations';
const POST_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATIONS_URL =
  '/IdentityTypeConfigurations/Add';
const PUT_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATIONS_URL = Id =>
  '/IdentityTypeConfigurations/Update?id=' + Id;
const DELETE_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATIONS_URL = Id =>
  '/IdentityTypeConfigurations/Remove?id=' + Id;

const GET_MASTER_REFERENCE_MENU_CATEGORIES_LIST_URL = '/MenuCategories';
const POST_MASTER_REFERENCE_MENU_CATEGORIES_URL = '/MenuCategories/Add';
const PUT_MASTER_REFERENCE_MENU_CATEGORIES_URL = Id =>
  '/MenuCategories/Update?id=' + Id;
const DELETE_MASTER_REFERENCE_MENU_CATEGORIES_URL = Id =>
  '/MenuCategories/Remove?id=' + Id;

const GET_MASTER_REFERENCE_MENU_CLASS_LIST_URL = '/MenuClasses';
const POST_MASTER_REFERENCE_MENU_CLASS_URL = '/MenuClasses/Add';
const PUT_MASTER_REFERENCE_MENU_CLASS_URL = Id =>
  '/MenuClasses/Update?id=' + Id;
const DELETE_MASTER_REFERENCE_MENU_CLASS_URL = Id =>
  '/MenuClasses/Remove?id=' + Id;

const GET_MASTER_REFERENCE_MENU_TYPE_LIST_URL = '/MenuTypes';
const POST_MASTER_REFERENCE_MENU_TYPE_URL = '/MenuTypes/Add';
const PUT_MASTER_REFERENCE_MENU_TYPE_URL = Id => '/MenuTypes/Update?id=' + Id;
const DELETE_MASTER_REFERENCE_MENU_TYPE_URL = Id =>
  '/MenuTypes/Remove?id=' + Id;

const GET_MASTER_REFERENCE_NUMBERING_SETUP_LIST_URL = '/NumberingSetups';
const POST_MASTER_REFERENCE_NUMBERING_SETUP_URL = '/NumberingSetups/Add';
const PUT_MASTER_REFERENCE_NUMBERING_SETUP_URL = Id =>
  '/NumberingSetups/Update?id=' + Id;
const DELETE_MASTER_REFERENCE_NUMBERING_SETUP_URL = Id =>
  '/NumberingSetups/Remove?id=' + Id;

const GET_MASTER_REFERENCE_PAX_TYPE_LIST_URL = '/PaxTypes';
const POST_MASTER_REFERENCE_PAX_TYPE_URL = '/PaxTypes/Add';
const PUT_MASTER_REFERENCE_PAX_TYPE_URL = Id => '/PaxTypes/Update?id=' + Id;
const DELETE_MASTER_REFERENCE_PAX_TYPE_URL = Id => '/PaxTypes/Remove?id=' + Id;

const GET_MASTER_REFERENCE_PLACE_TYPES_LIST_URL = '/PlaceTypes';
const POST_MASTER_REFERENCE_PLACE_TYPES_URL = '/PlaceTypes/Add';
const PUT_MASTER_REFERENCE_PLACE_TYPES_URL = Id =>
  '/PlaceTypes/Update?id=' + Id;
const DELETE_MASTER_REFERENCE_PLACE_TYPES_URL = Id =>
  '/PlaceTypes/Remove?id=' + Id;

const GET_MASTER_REFERENCE_RESTAURANT_PROFILE_SPECIALIZATIONS_LIST_URL =
  '/RestaurantProfileSpecializations';
const POST_MASTER_REFERENCE_RESTAURANT_PROFILE_SPECIALIZATIONS_URL =
  '/RestaurantProfileSpecializations/Add';
const PUT_MASTER_REFERENCE_RESTAURANT_PROFILE_SPECIALIZATIONS_URL = Id =>
  '/RestaurantProfileSpecializations/Update?id=' + Id;
const DELETE_MASTER_REFERENCE_RESTAURANT_PROFILE_SPECIALIZATIONS_URL = Id =>
  '/RestaurantProfileSpecializations/Remove?id=' + Id;

const GET_MASTER_REFERENCE_JOB_POSITION_LIST_URL = '/Positions';
const GET_MASTER_REFERENCE_JOB_POSITION_BY_ID_URL = Id => '/Positions/' + Id;
const POST_MASTER_REFERENCE_JOB_POSITION_URL = '/Positions/Add';
const PUT_MASTER_REFERENCE_JOB_POSITION_BY_ID_URL = Id =>
  `/Positions/Update?id=` + Id;
const DELETE_MASTER_REFERENCE_JOB_POSITION_BY_ID_URL = Id =>
  `/Positions/Remove?id=` + Id;

// const GET_MASTER_REFERENCE_CUSTOMER_LEVEL_LIST_URL = '/CustomerLevels';
// const GET_MASTER_REFERENCE_CUSTOMER_LEVEL_BY_ID_URL = Id =>
//   '/CustomerLevels/' + Id;
// const POST_MASTER_REFERENCE_CUSTOMER_LEVEL_URL = '/CustomerLevels/';
// const PUT_MASTER_REFERENCE_CUSTOMER_LEVEL_URL = Id => '/CustomerLevels/' + Id;
// const DELETE_MASTER_REFERENCE_CUSTOMER_LEVEL_URL = Id =>
//   '/CustomerLevels/' + Id;
const GET_ACCOMMODATION_BY_CITY_URL = id =>
  '/AccommodationProfiles/ByCity?city=' + id;
const GET_EXCURTION_BY_CITY_URL = id =>
  '/AttractionObjects/ByCity?cityid=' + id;
const GET_ACTIVITY_LIST_URL = '/MovementModes';
const GET_AIRPORT_BY_CITY_URL = id => '/Places/Get/ByRegion/' + id;
const GET_TOUR_OPERATOR_PROFILE_URL = '/TourOperatorProfiles/';
const GET_TOUR_OPERATOR_PROFILE_BY_CODE_URL = id =>
  '/TourOperatorProfiles/ByCompanyCode/' + id;
const GET_TOUR_OPERATOR_PROFILE_DETAIL_BY_ID_URL = id =>
  '/TourOperatorProfiles/ById/' + id;
const DELETE_TOUR_OPERATOR_URL = id => '/TourOperatorProfiles/' + id;
const UPDATE_TOUR_OPERATOR_PROFILE_URL = id =>
  '/TourOperatorProfiles/Admin/Edit?id=' + id;
const POST_IMAGES_TOUR_OPERATOR_URL = Id =>
  '/TourOperatorProfiles/UploadTourOperatorProfileImage?id=' + Id;
const POST_TOUR_OPERATOR_URL = '/TourOperatorProfiles/Admin/Add';
const DELETE_IMAGES_TOUR_OPERATOR_URL = Id =>
  '/TourOperatorProfiles/Images/Remove?imageId=' + Id;
//-------------------------------------------------------
const POST_DEMO_MANUAL_PAYMENT_URL = '/TourTransactions/v2/Confirmation/Demo'; //'/TourTransactions/Confirmation/Demo';
const POST_MANUAL_PAYMENT_URL = '/TourTransactions/Confirmation';
const GET_PAYABLE_SUPPLEMENT_ITEM_URL = Id =>
  '/TourTransactions/SupplementItem/ByTransactionId/Payable?tourTransactionId=' +
  Id;
const POST_TOP_UP_PAYMENT_GATEWAY_URL = '/TourTransactions/TopUp';
const POST_UNLOCK_USER_URL = Id => '/Account/UnlockUser?userId=' + Id;
const GET_TRANSACTION_PAYMENT_TERM_BY_TRANSACTION_ID_URL = Id =>
  '/TourTransactions/ToBePayed/' + Id;
const GET_USER_PROFILE = '/UserProfiles/UserProfile';
const GET_USER_PROFILE_BY_USER_ID = Id =>
  '/UserProfiles/UserProfile?userId=' + Id;
const DELETE_USER_PROFILE_URL = Id => '/UserProfiles/Delete?id=' + Id;
const POST_USER_PROFILE_ACTIVATE_URL = Id => '/UserProfiles/Activate?id=' + Id;
const POST_RESET_PASSWORD_URL = '/Account/ResetUserPassword';
const GET_ACCOMMODATION_ITEM_ALL_URL = '/AccommodationItems';
//const GET_TRANSPORTATION_UNIT_ALL_URL = '/TransportationUnits';
const GET_TRANSPORTATION_UNIT_ALL_URL = '/TransportationUnitHeaders';
const GET_RESTAURANT_ITEM_ALL_URL = '/RestaurantItems';
const GET_TRANSPORTATION_UNIT_BY_SERVICE_ITEM_ID_URL = Id =>
  '/TransportationUnits/' + Id;
const POST_USER_PROFILE_URL = '/UserProfiles/Add';
const POST_USER_URL = '/Account/User/CreateNew';
// const POST_TOUR_TRANSACTION_CONFIRMATION_URL = '/TourTransactions/Confirmation';
const POST_TOUR_TRANSACTION_CONFIRMATION_URL =
  '/TourTransactions/v2/Confirmation';

const GET_USER_ROLE_LIST_URL = '/Account/RoleList';
const GET_USER_NOT_EMPL0YEE_URL = '/Account/UserOnly/NotEmployee';
const PUT_USER_PROFILE_URL = Id => '/UserProfiles/Admin/UpdateProfile/' + Id;
const GET_USER_ROLE_BY_USER_ID_URL = Id =>
  '/Account/User/GetRoles?userId=' + Id;
const POST_ADD_ROLE_BY_USER_ID_URL = userId =>
  '/Account/User/UpdateRole?userId=' + userId;
const DELETE_ROLE_BY_USER_ID_URL = (userId, role) =>
  '/Account/User/RemoveRole?userId=' + userId + '&role=' + role;
const GET_INVOICE_STATUS_PAYMENT_GATEWAY_URL = Id =>
  '/TourTransactions/Invoice/Status/Check?code=' + Id;

const GET_SPECIAL_ADJUSMENT_BY_TRANSACTION_ID_URL = Id =>
  '/TourTransactions/AdditionalItem/ByTransactionId/' + Id;

const GET_TOUR_TRANSACTION_BILLED_BY_TRANSACTION_ID_URL = Id =>
  '/TourTransactions/Billed/' + Id;
const GET_TOUR_TRANSACTION_BILLS_BY_TRANSACTION_ID_URL = Id =>
  '/TourTransactions/Bills/' + Id;
const GET_USER_ROLE_BY_BEARER_URL = '/Account/UserRoles';
const GET_RESTAURANT_BY_CITY_DATE_PAX_URL = (city, date, pax) =>
  'RestaurantItems/ByCity?cityId=' +
  city +
  '&requestedDate=' +
  date +
  '&pax=' +
  pax;
const POST_TRANSPORTATION_UNIT_URL = '/TransportationUnits/Add';
const PUT_TRANSPORTATION_UNIT_URL = Id => '/TransportationUnits/' + Id;
const POST_PUBLISH_PACKAGE_URL = (typePackage, typeAction, id) =>
  '/BookingTemplates/' + typePackage + '/' + typeAction + '/' + id;

const GET_TRANSACTION_ALL_BY_STATUS_URL = Id =>
  '/TourTransactions/TransactionHistory/All?status=' + Id;

const POST_SPECIAL_ADJUSMENT_URL = '/TourTransactions/AdditionalItem/Add';
const DELETE_SPECIAL_ADJUSMENT_URL = Id =>
  '/TourTransactions/AdditionalItem/Delete/' + Id;
const EDIT_SPECIAL_ADJUSMENT_URL = 'TourTransactions/AdditionalItem/Edit/';
const POST_ADDITIONAL_ITEMS_URL = '/TourTransactions/AdditionalItems';
const GET_SCHEDULE_TOUR_TRANSACTION_URL = Id =>
  '/TourTransactions/GetPrintOutForScheduleOfTourTransaction?id=' + Id;

const GET_INVOICE_BY_TRANSACTION_ID_URL = Id =>
  '/TourTransactions/GeneratePDFTransaction?id=' + Id;
const GET_GROUP_TYPE_LIST_URL = '/TourTransactions/GroupTypeList';
const GET_IDENTITY_TYPE_URL = '/TourTransactions/IdentityTypeList';
const GET_GUEST_TITLE_TYPE_URL = '/TourTransactions/GuestTitleList';
const POST_EXCHANGE_RATE_URL = '/ExchangeRates/Add';
const PUT_EXCHANGE_RATE_URL = Id => '/ExchangeRates/' + Id;

const DELETE_TRANSPORTATION_UNIT_HEADER_URL = Id =>
  '/TransportationUnitHeaders/Remove?id=' + Id;

const GET_INVOICES_BY_COMPANY_CODE_URL = Id => '/Invoices/ByCompanyCode/' + Id;

const GET_PRINT_PAYMENT_RECEIPT_URL = (tourtransactionId, invoiceCode) =>
  '/TourTransactions/PrintPaymentReceipt?tourtransactionId=' +
  tourtransactionId +
  '&invoiceCode=' +
  invoiceCode;

const DELETE_ACCOMODATION_ITEM_URL = id => '/AccommodationItems/' + id;
const POST_ACCEPT_QUOTATION_URL = id =>
  '/TourTransactions/AcceptQuotation?tourTransactionId=' + id;
const POST_EDIT_QUOTATION_URL = id =>
  '/TourTransactions/EditQuotation?tourTransactionId=' + id;
const GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID_URL = id =>
  '/TourTransactions/SupplementItem/ByFixedPackageId/' + id;
const GET_TOUR_OPERATOR_PROFILE_BY_ID_URL = Id =>
  '/TourOperatorProfiles/ById/' + Id;
const GET_LIST_TOUR_OPERATOR_URL = '/TourTransactions/TourOperatorList';
const GET_USER_PROFILE_ACCOUNT_MANAGER_URL = '/UserProfiles/Sales';
const GET_USER_PROFILE_ACCOUNT_MANAGER_BY_COMPANY_URL =
  '/UserProfiles/CompanyUsers/Sales';
const GET_RESTAURANT_RATING_URL = '/RestaurantRatings';
const DELETE_EXCURSION_OBJECT_URL = '/AttractionObjects/Remove?id=';
const DELETE_TRANSPORTATION_UNIT_URL = Id => '/TransportationUnits/' + Id;
const DELETE_EXCHANGE_RATE_URL = '/ExchangeRates/';
const POST_CONTRACTING_FILE_URL =
  '/AccommodationProfiles/UploadAccommodationContract';
const DELETE_CONTRACTING_FILE_URL = (fileId, ProfileId) =>
  '/AccommodationProfiles/ProfileContract/Remove/' +
  fileId +
  '/' +
  ProfileId +
  '';
const POST_JOIN_TOUR_ON_BE_HALF_URL = '/TourTransactions/JoinTour/OnBehalf';
const POST_CREATE_CUSTOMER_ON_BE_HALF_URL =
  '/Account/RegisterCompany/SimpleCustomer';
const GET_ACCOMMODATIONS_WITH_FILTER_URL = '/AccommodationProfiles/ByCity';
const POST_DEMO_PRICE_URL = '/TourTransactions/DemoPrice';
const POST_CREATE_TOUR_TRANSACTION_URL = '/TourTransactions/CreateTour';
const POST_DEMO_JOIN_TOUR_URL = '/TourTransactions/Demo/JoinTour';
const GET_LIST_TOTAL_EMPLOYEE_URL =
  '/CustomEnums/Get/ByCategories/TOTAL_EMPLOYEE';

const GET_RESTAURANT_BY_FILTER_URL = (
  cityId,
  ratingIds,
  locationIds,
  specializationId,
  menuClassId,
  facilityIds,
  requestedDate,
  pax
) =>
  '/RestaurantProfiles/ByCity?cityId=' +
  cityId +
  '&ratingIds=' +
  ratingIds +
  '&locationIds=' +
  locationIds +
  '&specializationId=' +
  specializationId +
  '&menuClassId=' +
  menuClassId +
  '&facilityIds=' +
  facilityIds +
  '&requestedDate=' +
  requestedDate +
  '&pax=' +
  pax +
  '';

const GET_TRANSPORTATION_UNIT_BY_FILTER_URL = (
  fromCity,
  toCities,
  ratingId,
  seatTypeId,
  typeId,
  requestedDate
) =>
  '/TransportationUnits/TransportationService?fromCity=' +
  fromCity +
  '&toCities=' +
  toCities +
  '&ratingId=' +
  ratingId +
  '&seatTypeId=' +
  seatTypeId +
  '&typeId=' +
  typeId +
  '&requestedDate=' +
  requestedDate +
  '';
const GET_EXCURSION_BY_CITY_WITH_FILTER_URL =
  '/AttractionObjects/ByCityWithFilter';

const GET_PAYMENT_RECEIPT_BY_TRANSACTION_ID_URL = Id =>
  '/Invoices/ByTransactionId/' + Id;

const GET_ALL_NOT_ALLOWED_FOR_COMPANY_URL =
  '/NotAllowedCountryForCompanies/Get/All';
const GET_NOT_ALLOWED_COUNTRY_FOR_COMPANY_BY_COMPANY_CODE_URL = Id =>
  '/NotAllowedCountryForCompanies/Get?companyCode=' + Id;
const PUT_NOT_ALLOWED_COUNTRY_FOR_COMPANY_URL = Id =>
  '/NotAllowedCountryForCompanies/Edit?companyCode=' + Id;
const POST_NOT_ALLOWED_COUNTRY_FOR_COMPANY_URL =
  '/NotAllowedCountryForCompanies/Add';

const DELETE_IMAGE_TRANSPORTATION_UNIT_URL = Id =>
  '/TransportationUnitHeaders/Images/Remove?imageId=' + Id;
// =================== ROOM ALLOTMENT ============================
const GET_ROOM_ALLOTMENT_BY_PROFILE_ID_URL =
  '/DailyStocks/RoomAllotment/ByProfileId';
const POST_ROOM_ALLOTMENT_URL = '/DailyStocks/RoomAllotment/day';
const POST_ROOM_ALLOTMENT_PERIOD_URL = '/DailyStocks/RoomAllotment/period';

const GET_PAYMENT_SCHEME_BY_ID_URL = Id => '/PaymentSchemes/' + Id;
const PUT_PAYMENT_SCHEME_URL = Id => '/PaymentSchemes/' + Id;
const POST_PAYMENT_SCHEME_URL = '/PaymentSchemes/PaymentSchemesWithDetails';

//const GET_PAYMENT_SCHEME_DETAIL_BY_ID_URL = Id => '/PaymentSchemeDetails/';
const DELETE_PAYMENT_SCHEME_DETAIL_URL = Id => '/PaymentSchemeDetails/' + Id;
const GET_PAYMENT_SCHEME_DETAIL_BY_ID_URL = Id =>
  '/PaymentSchemeDetails/ByPaymentSchemaId?id=' + Id;
const PUT_PAYMENT_SCHEME_DETAIL_URL = Id => '/PaymentSchemeDetails/' + Id;
const POST_PAYMENT_SCHEME_DETAIL_URL = '/PaymentSchemeDetails';
const PUT_TOUR_OPERATOR_MARGIN_URL = Id => '/TourOperatorMargins?id=' + Id;
const POST_TOUR_OPERATOR_MARGIN_URL = '/TourOperatorMargins';
const DELETE_TOUR_OPERATOR_MARGIN_URL = Id => '/TourOperatorMargins?id=' + Id;

const GET_REQUEST_ROOM_ALLOTMENT_URL =
  '/TourTransactions/Supplier/RequestConfirmation';
const POST_CONFIRM_ROOM_ALLOTMENT_URL =
  '/TourTransactions/Supplier/AllotmentConfirmation';
const GET_LIST_CONFIRM_ROOM_ALLOTMENTS_URL =
  '/TourTransactions/Supplier/ConfirmationList';
const GET_RESEND_REQUEST_CONFIRMATION_URL =
  '/TourTransactions/Supplier/Resend/RequestConfirmation';

const GET_TOUR_OPERATOR_MARGIN_BY_ID_URL = id =>
  '/TourOperatorMargins/Get/ById?id=' + id;
const GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID_URL =
  '/DailyStocks/RoomAllotment/ByProfileId';
const GET_STOCK_ROOM_ALLOTMENT_URL = '/DailyStocks/RoomAllotment/ByProfileId';
const GET_EXPORT_STOCK_ROOM_ALLOTMENT_URL = '/DailyStocks/GetDataFile';
const GET_SALES_REPORT_URL = (startMonth, endMonth, year) =>
  '/TourTransactions/Export/Report/DetailSalesReport?startMonth=' +
  startMonth +
  '&endMonth=' +
  endMonth +
  '&year=' +
  year +
  '';
const GET_COMPANY_COMMISSIONS_URL = '/CompanyCommissions';
const GET_COMPANIES_BY_TYPE_URL = '/Companies/CompanyProfile/Admin/ByType';
const GET_TRANSACTION_INVOICE_WITH_STATUS_URL =
  '/TourTransactions/TransactionInvoiceHistory';
const POST_COMMISSION_PAYMENT_URL = '/TourTransactions/CommissionPayment';

const TOUR_OPERATOR_EMAIL_SETTING_URL = '/TourOperatorEmailSettings';

const GET_TOUR_TRANSACTIONS_COMMISSION_PAYMENT_URL =
  '/TourTransactions/CommissionPayment';

const GET_TOUR_GUIDE_URL = '/TourGuides';
const GET_TOUR_GUIDE_BY_ID_URL = id => `/TourGuides/${id}`;
const POST_TOUR_GUIDE_URL = '/TourGuides';
const PUT_TOUR_GUIDE_URL = id => `/TourGuides/${id}`;
const DELETE_TOUR_GUIDE_URL = id => `/TourGuides/${id}`;
const PACKAGE_TRANSACTION_HISTORY_URL = '/TourTransactions/TransactionsMade/';

const GET_ALL_SHARED_PACKAGE_URL = '/BookingTemplates/SharedPax/Get/All';
const GET_DATA_TRANSFER_LOGS_URL = '/DataTransferLogs';
const GET_DATA_TRANSFER_LOGS_BY_ID_URL = id => `/DataTransferLogs?id=${id}`;
const POST_DATA_TRANSFER_LOGS_URL = '/DataTransferLogs';
const PUT_DATA_TRANSFER_LOGS_URL = id => `/DataTransferLogs/edit/${id}`;
const DELETE_DATA_TRANSFER_LOGS_URL = id => `/DataTransferLogs/${id}`;
const GET_DATA_TRANSFER_LOGS_BY_RANGE_URL = (fromLine, toLine) =>
  `/DataTransferLogs?fromLine=${fromLine}&toLine=${toLine}`;
const GET_DATA_TRANSFER_LOGS_BY_REFERENCE_ID_URL = referenceId =>
  `/DataTransferLogs/GetByReferenceId?referenceId=${referenceId}`;

const POST_RESEND_DATA_TOUR_TRANSACTION_URL = dataTransferLogId =>
  `/TourTransactions/ResendData?dataTransferLogId=${dataTransferLogId}`;

const GET_FILTER_SHARED_PACKAGE_URL = (
  tourCategoryId,
  totalDays,
  startDate,
  destination
) =>
  `/BookingTemplates/SeriesPackage/Filter?tourCategoryId=${tourCategoryId}&totalDays=${totalDays}&startDate=${startDate}&destination=${destination}`;
const GET_SHARED_PACKAGE_BY_ID_URL = Id =>
  `/BookingTemplates/SharedPax/Get/ByBookingTemplateId?id=${Id}`;

const LANGUAGE_URL = '/Languages';

const GET_CUSTOMER_PROFILE_FOR_SALES_URL =
  '/Companies/CompanyProfile/Sales/AdminSimplified?type=';
const PUT_UPDATE_PROFILE_URL = '/UserProfiles/UpdateProfile';
const POST_CHANGE_PASSWORD = '/Account/ChangePassword';
const GET_USER_PROFILE_ONLY_BY_USER_ID_URL = Id => `/Account/UserOnly/${Id}`;
const GET_DOWNLOAD_SCHEDULE_BY_TRANSACTION_ID_URL = tourTransactionId =>
  `/TourTransactions/DownloadSchedule?tourTransactionId=${tourTransactionId}`;
// const GET_DOWNLOAD_BROCHURE_BY_TRANSACTION_ID = (
//   tourTransactionId,
//   companyCode
// ) => `/BookingTemplates/GetOverlayBrochure/${tourTransactionId}/${companyCode}`;
const GET_DOWNLOAD_BROCHURE_BY_BOOKING_TEMPLATE_ID_URL = (
  bookingTemplateId,
  companyCode
) => `
/BookingTemplates/GetOverlayBrochure/${bookingTemplateId}/${companyCode}`;
const GET_DOWNLOAD_BROCHURE_BY_BOOKING_TEMPLATE_ID_AND_IMAGE_ID_URL = (
  bookingTemplateId,
  companyCode,
  imageId
) => `
/BookingTemplates/GetOverlayBrochureImage/${bookingTemplateId}/${companyCode}/${imageId}`;
const GET_INVOICES_BY_TRANSACTION_ID_URL = Id =>
  '/Invoices/ByTransactionId/' + Id;

const GET_DOWNLOAD_VOUCHER_CONFIRMATION_BY_TRANSACTION_ID_URL = Id =>
  '/TourTransactions/DownloadVoucherConfirmation?tourTransactionId=' + Id;
const POST_SUPPLIER_URL = '/Companies/AddSupplierCompany';
const PUT_ACCOMMODATION_SUPPLIER_URL = companyCode =>
  `/Companies/Admin/UpdateCompanySupplier?companyCode=${companyCode}`;
const PLACE_CITIES_URL = '/PlaceCities';
const GET_SERIES_VARIABLE_DATE_URL =
  '/BookingTemplates/GetTemplates/VariableDate/All';

const POST_ADDITIONAL_INFO_PACKAGE_URL = '/BookingTemplates/Add/Subtitle';
const PUT_ADDITIONAL_INFO_PACKAGE_URL = id =>
  `/BookingTemplates/Edit/Subtitle?id=${id}`;
const DELETE_ADDITIONAL_INFO_PACKAGE_URL = id =>
  `/BookingTemplates/Remove/Subtitle?id=${id}`;

const BOOKINGTEMPLATEIMAGES_URL = id => `/BookingTemplateImages/${id}`;
const PUT_ADDITIONAL_INFO_PACKAGE_OPERATION_URL =
  '/BookingTemplates/UpdateAdditionalInfo/Operation/';
const GET_ACCOUNT_STATEMENT_URL = (companyCode, startDate, endDate) =>
  `/AccountStatements/Download/AR?companyCode=${companyCode}&startDate=${startDate}&endDate=${endDate}`;
const GET_SUPPLIER_LIST_URL = supplierType => `/${supplierType}/Supplier`;

const POST_JOIN_TOUR_URL = '/TourTransactions/JoinTour/';
const GET_EXPORT_DATA_ITEM_BY_PROFILE_ID = (supplierType, profileId) =>
  `/${supplierType}/GetDataFile/${profileId}`;
// ==================== PARTNER EMPLOYEE (TOUR OPERATOR) ====================
const GET_PARTNER_EMPLOYEE_LIST = `/TourOperatorProfiles/GetAllEmployee`;

// ==================== ADDITIONAL SERVICES ====================
const GET_ADDITIONAL_SERVICES_LIST_URL = '/AdditionalServices';
const GET_ADDITIONAL_SERVICE_BY_ID_URL = id => `/AdditionalServices/${id}`;
const PUT_ADDITIONAL_SERVICE = id => `/AdditionalServices/Update?id=${id}`;
const POST_ADDITIONAL_SERVICE = `/AdditionalServices/Add`;
const DELETE_ADDITIONAL_SERVICE = id => `/AdditionalServices/Remove?id=${id}`;
// transaction
const GET_ADDITIONAL_SERVICES_LIST_IN_TRANSACTION_URL = (
  originalCountry,
  destinationCountry,
  currency
) =>
  `/AdditionalServices/GetList/${originalCountry}/${destinationCountry}/${currency}`;

const GET_ADDITIONAL_SERVICE_TYPE_URL = '/AdditionalServiceType';
const GET_RESTRICTED_COUNTRY_BY_ADDITIONAL_SERVICE_TYPE_URL = additionalServiceTypeId =>
  `AdditionalServices/AvailableCountryForRestrictedService?additionalServiceTypeId=${additionalServiceTypeId}`;

// transportation additional cost
const GET_TRANSPORTATION_ADDITIONAL_COST_LIST_URL = `/TransportationAdditionalCost`;
const GET_TRANSPORTATION_ADDITIONAL_COST_TYPE_URL = `/TransportationAdditionalCost/Type`;
const GET_ADDITIONAL_COST_BY_CITY_AND_TYPE_URL = (
  originalCityId,
  destinationCityId,
  transportationTypeId
) =>
  `/TransportationAdditionalCost/FilterByCity/${originalCityId}/${destinationCityId}/${transportationTypeId}`;
const DELETE_ADDITIONAL_COST_BY_CITY_AND_TYPE_URL = (
  originalCityId,
  destinationCityId,
  transportationTypeId
) =>
  `/TransportationAdditionalCost/RemoveRange/${originalCityId}/${destinationCityId}/${transportationTypeId}`;

const POST_TRANSPORTATION_ADDITIONAL_COST_DETAIL_URL = `/TransportationAdditionalCost/AddRange`;
const PUT_TRANSPORTATION_ADDITIONAL_COST_DETAIL_URL = `/TransportationAdditionalCost/UpdateRange`;

const GET_COMPANY_PROFILE = '/Companies/CompanyProfile';

// download customer & agent invoice
const GET_INVOICE_TRAVEL_AGENT_URL = id =>
  '/TourTransactions/DownloadInvoice?tourTransactionId=' + id;
const GET_INVOICE_CUSTOMER_URL = id =>
  '/TourTransactions/DownloadInvoiceForGuest?tourTransactionId=' + id;

const GET_ADDITIONAL_ITEM_TYPE_URL = '/AdditionalItemType';

const POST_EMAIL_SEND_CONFIRMATION_URL =
  '/TourTransactions/EmailSendConfirmation';

const REPORT_SALES_URL = (startDate, endDate) =>
  `/TourTransactions/AgentReport?startDate=${startDate}&endDate=${endDate}`;

// Resource Booking
const GET_RESOURCE_BOOKING_BY_TOUR_TRANSACTION_CODE_URL = tourTransactionId =>
  `/ResourceBookings/ByTourTransactionCode/${tourTransactionId}`;

const GET_RESOURCE_BOOKING_DETAIL_BY_RESOURCE_BOOKING_ID_URL = Id =>
  `/ResourceBookingDetail/ByResourceBookingId/${Id}`;

const GET_RESOURCE_BOOKING_LIST_URL = '/ResourceBookings';

const GET_RESOURCE_BOOKING_BY_ID_URL = id => '/ResourceBookings/' + id;

const GET_RESOURCE_BOOKING_BY_PACKAGE_NUMBER_URL = `/ResourceBookingHeader/ByPackage`;

const GET_RESORCE_BOOKING_BY_TRANSACTION_NUMBER_URL = (
  tourStartDate,
  tourUntilDate,
  createdStartDate,
  createdUntilDate,
  status
) =>
  `/ResourceBookingHeader/ByTransaction?tourStartDate=${tourStartDate}&tourUntilDate=${tourUntilDate}&createdStartDate=${createdStartDate}&createdUntilDate=${createdUntilDate}&status=${status}`;
// const GET_RESOURCE_BOOKING_BY_ID_URL = id =>
//   '/TourTransactions/ResourceBooking?id=' + id;
const GET_GENERATE_RESOURCE_BOOKING_URL = '/ResourceBookings/Generate';
const GET_DOWNLOAD_RESOURCE_BOOKING_DETAIL_URL =
  '/ResourceBookingDetail/DownloadResourceBookingDocument';
const GET_TRANSACTION_HISTORY_BY_OPERATION_URL =
  '/ResourceBookingHeader/ByTransaction';
const PUT_RESOURCE_BOOKING_DETAIL_UPDATE_URL = '/ResourceBookingDetail/Update';
const GET_RESOURCE_BOOKING_WITH_SUMMARY_URL =
  '/ResourceBookings/WithSummaryDetail';
const GET_DOWNLAOD_RESOURCE_BOOKING_DETAIL_DOCUMENT_URL =
  '/ResourceBookingDetail/DownloadResourceBookingDetailDocument';
const DELETE_RESOURCE_BOOKING_COLLECTION_URL = '/ResourceBookingDetail/Remove';
const GET_RESOURCE_LIST_URL = '/ResourceBookingDetail/ByResourceBookingId/';
const POST_RESOURCE = '/ResourceBookingDetail/Add';
const PUT_RESOURCE = '/ResourceBookingDetail/Update';
const GET_RESOURCE_DETAIL_BY_ID_URL = '/ResourceBookingDetail';
const GET_RESOURCE_BOOKING_RESERVATION_BY_ID_URL =
  '/ResourceBookingReservation/ByResouceBookingDetailCollection';
const POST_RESOURCE_BOOKING_RESERVATION_URL = '/ResourceBookingReservation/Add';
const PUT_RESOURCE_BOOKING_RESERVATION_URL =
  '/ResourceBookingReservation/Update';
const DELETE_RESOURCE_BOOKING_RESERVATION_URL =
  '/ResourceBookingReservation/Remove';
const POST_RESOURCE_BOOKING_RESERVATION_PAYMENT_URL =
  '/ResourceBookingReservationPayment/Add';
const PUT_READY_TO_USE_QTY_URL = '/ResourceBookingDetail/UpdateReadyQty';
const GET_LIST_CUSTOMER_PAYMENT_URL = '/Finance/Invoices/Open';
const GET_GENERATE_AR_SUMMARY_URL = '/AccountStatements/AR/GenerateARSummary';
const GET_AR_SUMMARY_LIST_URL = (year, month) =>
  `/AccountStatements/AR/GetARSummary?year=${year}&month=${month}`;
const DOWNLOAD_AR_SUMMARY_URL = (year, month) =>
  `/AccountStatements/Download/AR/Summary?year=${year}&month=${month}`;
const PUT_RESOURCE_BOOKING_RESERVATION_PAYMENT_URL =
  '/ResourceBookingReservationPayment/Update';
const POST_CANCEL_RESOURCE_URL =
  '/ResourceBookingDetail/CancelResourceBookingDetail';
const GET_RESOURCE_TYPE_ENUM_URL =
  '/ResourceBookingDetail/GetResourceTypeEunum';
const GET_ACCOMMODATION_ESTIMATE_PRICE_URL =
  '/AccommodationProfiles/v2/Filter/ShowPrice';
const GET_ACCOMMODATION_ITEM_ESTIMATE_PRICE_URL =
  '/AccommodationItems/ByProfile/ShowPrice';
const GET_ATTRACTION_ESTIMATE_PRICE_URL =
  '/AttractionObjects/ByCityWithFilter/ShowPrice';
const GET_RESTAURANT_ESTIMATE_PRICE = '/RestaurantProfiles/ByCity/ShowPrice';
const GET_TRANSPORTATION_ESTIMATE_PRICE_URL =
  '/TransportationUnits/TransportationService/ShowPrice';

const POST_REMOVE_GUEST_LIST = id => `/TourTransactions/RemoveGuest?id=${id}`;

const POST_RESOURCE_BOOKING_BY_TYPE_URL = `/ResourceBookingDetail/ByResourceBookingType`;

const GET_TRANSACTION_HISTORY_BY_TRANSACTION_URL =
  '/TourTransactions/TransactionPaymentHistory';
const GET_DOWNLOAD_SCHEDULE_BY_BOOKING_TEMPLATE_URL =
  '/BookingTemplates/DownloadSchedule';
export {
  PUT_RESOURCE,
  PUT_RESOURCE_BOOKING_DETAIL_UPDATE_URL,
  POST_CREATE_CUSTOMER_ON_BE_HALF_URL,
  GET_CURRENCIES_URL,
  LOGIN_AUTHENTICATION_URL,
  GET_LIST_PENDING_APPROVAL_COMPANY_URL,
  UPDATE_COMPANY_REGISTRATION_URL,
  GET_PUBLISHED_PACKAGE_URL,
  GET_TRANSACTION_URL,
  GET_USER_INFO_URL,
  GET_USER_ROLES_URL,
  UPDATE_TRANSACTION_URL,
  GET_LIST_PAYMENT_HISTORY_URL,
  GET_ACCOMODATION_LIST_URL,
  GET_TRANSPORTATION_LIST_URL,
  GET_EXCURSION_LIST_URL,
  GET_CUSTOMER_PROFILE_URL,
  GET_USER_ACCESS_LIST_URL,
  GET_LOCKED_USER_LIST_URL,
  GET_SYSTEM_MARGIN_LIST_URL,
  GET_EXCHANGE_RATE_LIST_URL,
  GET_TRANSACTION_HISTORY_BY_STATUS_URL,
  GET_TRANSACTION_BY_PERIODE_URL,
  GET_TRANSACTION_BY_ID_URL,
  GET_TRANSACTION_PAYABLE_URL,
  GET_ACCOMODATION_BYPROFILE_ID_URL,
  GET_ACCOMODATION_TYPES_URL,
  GET_TOUR_PAX_TYPE_URL,
  GET_TOUR_TYPE_URL,
  GET_ACCOMODATION_FACILITIES_URL,
  GET_ACCOMODATION_LOCATION_URL,
  GET_ACCOMODATION_ITEM_BYPROFILE_ID_URL,
  GET_ACCOMODATION_ITEM_BYPROFILE_ID_REQUEST_DATE_URL,
  GET_ACCOMODATION_ITEM_BY_SERVICE_TYPE_ID_URL,
  GET_ACCOMODATION_ITEM_TYPES_URL,
  GET_ACCOMODATION_ITEM_SERVICE_TYPE_LIST_URL,
  GET_COUNTRY_URL,
  GET_CITY_URL,
  GET_REGION_BY_COUNTRY_URL,
  GET_CITY_BY_REGION_URL,
  GET_AREA_BY_CITY_URL,
  POST_ACCOMODATION_URL,
  GET_TRANSPORTATION_BY_PROFILE_ID_URL,
  GET_LIST_TRANSPORTATION_UNIT_BY_PROFILE_ID_URL,
  GET_ACCOMODATION_TEMPLATE,
  GET_ACCOMODATION_EXPORT_DATA,
  GET_EXCURSION_TYPE_URL,
  GET_EXCURSION_URL,
  POST_EXCURSION_URL,
  PUT_EXCURSION_URL,
  GET_EXCURSION_PRICE_TYPE_URL,
  DELETE_ACCOMODATION_BY_PROFILE_ID_URL,
  DELETE_TRANSPORTATION_BY_PROFILE_ID_URL,
  POST_TRANSPORTATION_URL,
  PUT_ACCOMODATION_URL,
  GET_TRANSPORTATION_CATAGORIES_URL,
  PUT_TRANSPORTATION_URL,
  DELETE_RESTAURANT_BY_PROFILE_ID_URL,
  GET_RESTAURANT_BY_PROFILE_ID_URL,
  GET_RESTAURANT_ITEM_BY_PROFILE_ID_URL,
  GET_RESTAURANT_LIST_URL,
  GET_RESTAURANT_TYPE_URL,
  GET_RESTAURANT_CLASS_URL,
  GET_RESTAURANT_CATEGORY_URL,
  GET_SERVICE_PRICE_URL,
  POST_SERVICE_PRICE_URL,
  PUT_SERVICE_PRICE_URL,
  GET_SERVICE_PRICE_TYPE_URL,
  DELETE_SERVICE_PRICE_URL,
  PUT_ACCOMODATION_ITEM_URL,
  POST_ACCOMODATION_ITEM_URL,
  GET_AREA_LIST_URL,
  POST_AREA_URL,
  PUT_AREA_URL,
  DELETE_AREA_URL,
  GET_AREA_BY_ID_URL,
  GET_CITY_LIST_URL,
  POST_CITY_URL,
  PUT_CITY_URL,
  DELETE_CITY_URL,
  GET_CITY_BY_ID_URL,
  GET_REGION_LIST_URL,
  POST_REGION_URL,
  PUT_REGION_URL,
  DELETE_REGION_URL,
  GET_REGION_BY_ID_URL,
  GET_PLACE_LIST_URL,
  POST_PLACE_URL,
  PUT_PLACE_URL,
  DELETE_PLACE_URL,
  GET_PLACE_BY_ID_URL,
  GET_COUNTRY_LIST_URL,
  POST_COUNTRY_URL,
  PUT_COUNTRY_URL,
  DELETE_COUNTRY_URL,
  GET_COUNTRY_BY_ID_URL,
  GET_PLACE_TYPE_URL,
  POST_RESTAURANT_PROFILE_URL,
  PUT_RESTAURANT_PROFILE_URL,
  GET_RESTAURANT_ITEM_URL,
  GET_RESTAURANT_ITEM_BY_ID_URL,
  POST_RESTAURANT_ITEM_URL,
  PUT_RESTAURANT_ITEM_URL,
  GET_CUSTOMER_PROFILE_BY_CODE_URL,
  GET_CUSTOMER_PROFILE_BY_CODE_MANAGE_BY_SALES_URL,
  PUT_CUSTOMER_PROFILE_URL,
  GET_HISTORY_TRANSACTION_BY_CODE_URL,
  GET_HISTORY_TRANSACTION_FOR_SALES_BY_CODE_URL,
  GET_SUPERVISOR_BY_COMPANY_CODE_POSITION_ID_URL,
  GET_USER_DATA_BY_CODE_URL,
  GET_CITY_GROUP_LIST_URL,
  POST_CITY_GROUP_URL,
  PUT_CITY_GROUP_URL,
  DELETE_CITY_GROUP_URL,
  GET_CITY_GROUP_BY_ID_URL,
  GET_CITY_IN_COUNTRY_URL,
  GET_BALANCE_HISTORY_BY_COMPANY_CODE,
  POST_BALANCE_DETAIL_URL,
  GET_CUSTOM_ENUM_BY_CATAGORIES,
  POST_SYSTEM_MARGIN_URL,
  PUT_SYSTEM_MARGIN_URL,
  GET_SYSTEM_MARGIN_BY_ID_URL,
  DELETE_SYSTEM_MARGIN_BY_ID_URL,
  GET_PAYMENT_SCHEME_URL,
  POST_CUSTOMER_PROFILE_URL,
  PUT_CUSTOMER_PROFILE,
  POST_UPLOAD_IMAGE_ACCOMODATION_PROFILE_URL,
  POST_SET_PRIMARY_IMAGE_ACCOMODATION_PROFILE_URL,
  DELETE_IMAGE_ACCOMODATION_PROFILE_URL,
  UPLOAD_IMAGE_ACCOMODATION_ITEM_BY_ID_URL,
  SET_PRIMARY_IMAGE_ACCOMODATION_ITEM_URL,
  UPLOAD_PREVIEW_ACCOMODATION_PROFILE_URL,
  UPLOAD_PREVIEW_ACCOMODATION_ITEM_URL,
  UPLOAD_COMMIT_ACCOMODATION_PROFILE_URL,
  UPLOAD_COMMIT_ACCOMODATION_ITEM_URL,
  UPLOAD_PREVIEW_EXCURSION_OBJECT_URL,
  UPLOAD_PREVIEW_EXCURSION_PRICE_URL,
  UPLOAD_COMMIT_EXCURSION_OBJECT_URL,
  UPLOAD_COMMIT_EXCURSION_PRICE_URL,
  UPLOAD_IMAGE_EXCURSION_URL,
  POST_SET_PRIMARY_IMAGE_EXCURSION_URL,
  POST_UPLOAD_IMAGE_EXCURSION_BY_SERVICE_ITEM_ID_URL,
  UPLOAD_PREVIEW_RESTAURANT_PROFILE_URL,
  UPLOAD_PREVIEW_RESTAURANT_ITEM_URL,
  UPLOAD_PREVIEW_RESTAURANT_PRICE_URL,
  UPLOAD_COMMIT_RESTAURANT_ITEM_URL,
  UPLOAD_COMMIT_RESTAURANT_PROFILE_URL,
  UPLOAD_COMMIT_RESTAURANT_PRICE_URL,
  SET_PRIMARY_IMAGE_RESTAURANT_ITEM_URL,
  UPLOAD_IMAGE_RESTAURANT_BY_SERIVICE_TYPE_ID,
  UPLOAD_PREVIEW_TRANSPORTATION_PROFILE_URL,
  UPLOAD_PREVIEW_TRANSPORTATION_UNIT_URL,
  UPLOAD_COMMIT_TRANSPORTATION_PROFILE_URL,
  UPLOAD_COMMIT_TRANSPORTATION_UNIT_URL,
  POST_CITY_GROUP_DETAIL_URL,
  GET_PAYMENT_METHOD_LIST,
  POST_CITY_GROUP_DETAIL_FULLADD_URL,
  GET_EXCURSION_OBJECT_EXPORT_DATA_URL,
  GET_RESTAURANT_ITEM_EXPORT_DATA_URL,
  GET_EXCURSION_PRICE_EXPORT_DATA_URL,
  GET_RESTAURANT_ITEM_PRICE_EXPORT_DATA_URL,
  GET_ACCOMODATION_ITEM_EXPORT_DATA_URL,
  GET_TRANSPORTATION_UNIT_EXPORT_DATA_URL,
  POST_PAYMENT_METHOD_IN_COUNTRY_URL,
  GET_TEMPLATE_EXCURSION_OBJECT_DATA_URL,
  GET_TEMPLATE_EXCURSION_PRICE_DATA_URL,
  GET_TEMPLATE_RESTAURANT_ITEM_PRICE_DATA_URL,
  GET_TEMPLATE_RESTAURANT_ITEM_DATA_URL,
  GET_TEMPLATE_RESTAURANT_PROFILE_DATA_URL,
  GET_TEMPLATE_ACCOMODATION_ITEM_DATA_URL,
  GET_TEMPLATE_TRANSPORTATION_UNIT_DATA_URL,
  GET_PAYMENT_METHOD_IN_COUNTRY_URL,
  DELETE_PAYMENT_METHOD_IN_COUNTRY_URL,
  POST_IMAGE_EXCURSION_OBJECT_URL,
  POST_SET_PRIMARY_EXCRUSION_OBJECT_URL,
  POST_IMAGE_TRANSPORTATION_PROFILE_URL,
  POST_IMAGE_RESTAURANT_ITEM_IMAGE_URL,
  POST_SET_PRIMARY_IMAGE_RESTAURANT_ITEM_URL,
  POST_IMAGE_ACCOMODATION_ITEM_URL,
  POST_SET_PRIMARY_IMAGE_ACCOMODATION_ITEM_URL,
  POST_IMAGE_TRANSPORTATION_UNIT_URL,
  POST_IMAGE_NEW_TRANSPORTATION_UNIT_URL,
  POST_SET_PRIMARY_IMAGE_TRANSPORTATION_UNIT_URL,
  POST_TRANSPORTATION_UNIT_BY_SERVICE_TYPE_ID_URL,
  POST_IMAGE_TRANSPORTATION_TYPE_URL,
  GET_IMAGE_TRANSPORTATION_TYPE_BY_TRANSPORTATION_TYPE_AND_TRANSPORTATION_PROFILE_URL,
  DELETE_IMAGE_TRANSPORTATION_TYPE_URL,
  GET_IMAGE_ALL_URL,
  GET_IMAGE_BY_ID_URL,
  PUT_IMAGE_BY_ID_URL,
  POST_IMAGE_URL,
  DELETE_IMAGE_BY_ID_URL,
  POST_IMAGE_BY_ENTITIES_AND_FILENAME_AND_HOST_URL,
  GET_TRANSPORTATION_PROFILE_IMAGE_URL,
  GET_TRANSPORTATION_PROFILE_IMAGE_BY_ID_URL,
  PUT_TRANSPORTATION_PROFILE_IMAGE_BY_ID_URL,
  DELETE_TRANSPORTATION_PROFILE_IMAGE_URL,
  DELETE_TRANSPORTATION_PROFILE_IMAGE_BY_ID_URL,
  GET_ACCOMODATION_PROFILE_IMAGE_URL,
  GET_ACCOMODATION_PROFILE_IMAGE_BY_ID_URL,
  PUT_ACCOMODATION_PROFILE_IMAGE_URL,
  POST_ACCOMODATION_PROFILE_IMAGE_URL,
  DELETE_ACCOMODATION_PROFILE_IMAGE_URL,
  POST_TRANSPORTATION_PROFILE_IMAGE_URL,
  GET_READY_PACKAGE_ALL_URL,
  POST_READY_PACKAGE_TEMPLATE_URL,
  GET_READY_PACKAGE_WITH_FILTER_URL,
  GET_PACKAGE_BY_TYPE_AND_ID_URL,
  GET_PAYMENT_METHOD_BY_COUNTRY_ID_URL,
  GET_TRANSPORTATION_UNIT_HEADER_BY_ID_URL,
  GET_TRANSPORTATION_UNIT_BY_HEADER_ID_URL,
  GET_PACKAGES_URL,
  POST_PACKAGE_URL,
  PUT_PACKAGE_URL,
  GET_FIXED_PACKAGE,
  DELETE_PACKAGE_URL,
  GET_TEMPLATE_TRANSPORTATION_PROFILE_URL,
  GET_MASTER_REFERENCE_ACCOMODATION_FACILITIES_LIST_URL,
  POST_MASTER_REFERENCE_ACCOMODATION_FACILITIES_URL,
  PUT_MASTER_REFERENCE_ACCOMODATION_FACILITIES_URL,
  DELETE_MASTER_REFERENCE_ACCOMODATION_FACILITIES_URL,
  GET_MASTER_REFERENCE_URL,
  POST_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_URL,
  PUT_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_URL,
  DELETE_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_URL,
  GET_MASTER_REFERENCE_ACCOMODATION_LOCATION_LIST_URL,
  POST_MASTER_REFERENCE_ACCOMODATION_LOCATION_URL,
  PUT_MASTER_REFERENCE_ACCOMODATION_LOCATION_URL,
  DELETE_MASTER_REFERENCE_ACCOMODATION_LOCATION_URL,
  GET_MASTER_REFERENCE_ACCOMODATION_RATING_LIST_URL,
  POST_MASTER_REFERENCE_ACCOMODATION_RATING_URL,
  PUT_MASTER_REFERENCE_ACCOMODATION_RATING_URL,
  DELETE_MASTER_REFERENCE_ACCOMODATION_RATING_URL,
  GET_MASTER_REFERENCE_ACCOMODATION_TYPE_LIST_URL,
  POST_MASTER_REFERENCE_ACCOMODATION_TYPE_URL,
  PUT_MASTER_REFERENCE_ACCOMODATION_TYPE_URL,
  DELETE_MASTER_REFERENCE_ACCOMODATION_TYPE_URL,
  POST_DEMO_MANUAL_PAYMENT_URL,
  POST_MANUAL_PAYMENT_URL,
  GET_PAYABLE_SUPPLEMENT_ITEM_URL,
  GET_MASTER_REFERENCE_ATTRACTION_TYPE_LIST_URL,
  POST_MASTER_REFERENCE_ATTRACTION_TYPE_URL,
  PUT_MASTER_REFERENCE_ATTRACTION_TYPE_URL,
  DELETE_MASTER_REFERENCE_ATTRACTION_TYPE_URL,
  GET_MASTER_REFERENCE_ATTRACTION_CATEGORIES_LIST_URL,
  POST_MASTER_REFERENCE_ATTRACTION_CATEGORIES_URL,
  PUT_MASTER_REFERENCE_ATTRACTION_CATEGORIES_URL,
  DELETE_MASTER_REFERENCE_ATTRACTION_CATEGORIES_URL,
  GET_MASTER_REFERENCE_CURRENCIES_LIST_URL,
  POST_MASTER_REFERENCE_CURRENCIES_URL,
  PUT_MASTER_REFERENCE_CURRENCIES_URL,
  DELETE_MASTER_REFERENCE_CURRENCIES_URL,
  GET_MASTER_REFERENCE_CUSTOM_ENUM_LIST_URL,
  GET_MASTER_REFERENCE_CUSTOM_ENUM_BY_CATEGORIES_URL,
  POST_MASTER_REFERENCE_CUSTOM_ENUM_URL,
  PUT_MASTER_REFERENCE_CUSTOM_ENUM_URL,
  DELETE_MASTER_REFERENCE_CUSTOM_ENUM_URL,
  GET_MASTER_REFERENCE_GUEST_CATEGORIES_LIST_URL,
  POST_MASTER_REFERENCE_GUEST_CATEGORIES_URL,
  PUT_MASTER_REFERENCE_GUEST_CATEGORIES_URL,
  DELETE_MASTER_REFERENCE_GUEST_CATEGORIES_URL,
  GET_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_LIST_URL,
  POST_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_URL,
  PUT_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_URL,
  DELETE_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_URL,
  GET_MASTER_REFERENCE_GUEST_TITLES_LIST_URL,
  POST_MASTER_REFERENCE_GUEST_TITLES_URL,
  PUT_MASTER_REFERENCE_GUEST_TITLES_URL,
  DELETE_MASTER_REFERENCE_GUEST_TITLES_URL,
  GET_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATIONS_LIST_URL,
  POST_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATIONS_URL,
  PUT_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATIONS_URL,
  DELETE_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATIONS_URL,
  GET_MASTER_REFERENCE_MENU_CATEGORIES_LIST_URL,
  POST_MASTER_REFERENCE_MENU_CATEGORIES_URL,
  PUT_MASTER_REFERENCE_MENU_CATEGORIES_URL,
  DELETE_MASTER_REFERENCE_MENU_CATEGORIES_URL,
  GET_MASTER_REFERENCE_MENU_CLASS_LIST_URL,
  POST_MASTER_REFERENCE_MENU_CLASS_URL,
  PUT_MASTER_REFERENCE_MENU_CLASS_URL,
  DELETE_MASTER_REFERENCE_MENU_CLASS_URL,
  GET_MASTER_REFERENCE_MENU_TYPE_LIST_URL,
  POST_MASTER_REFERENCE_MENU_TYPE_URL,
  PUT_MASTER_REFERENCE_MENU_TYPE_URL,
  DELETE_MASTER_REFERENCE_MENU_TYPE_URL,
  GET_MASTER_REFERENCE_NUMBERING_SETUP_LIST_URL,
  POST_MASTER_REFERENCE_NUMBERING_SETUP_URL,
  PUT_MASTER_REFERENCE_NUMBERING_SETUP_URL,
  DELETE_MASTER_REFERENCE_NUMBERING_SETUP_URL,
  POST_TOP_UP_PAYMENT_GATEWAY_URL,
  GET_MASTER_REFERENCE_PAX_TYPE_LIST_URL,
  POST_MASTER_REFERENCE_PAX_TYPE_URL,
  PUT_MASTER_REFERENCE_PAX_TYPE_URL,
  DELETE_MASTER_REFERENCE_PAX_TYPE_URL,
  GET_MASTER_REFERENCE_PLACE_TYPES_LIST_URL,
  POST_MASTER_REFERENCE_PLACE_TYPES_URL,
  PUT_MASTER_REFERENCE_PLACE_TYPES_URL,
  DELETE_MASTER_REFERENCE_PLACE_TYPES_URL,
  GET_MASTER_REFERENCE_RESTAURANT_PROFILE_SPECIALIZATIONS_LIST_URL,
  POST_MASTER_REFERENCE_RESTAURANT_PROFILE_SPECIALIZATIONS_URL,
  PUT_MASTER_REFERENCE_RESTAURANT_PROFILE_SPECIALIZATIONS_URL,
  DELETE_MASTER_REFERENCE_RESTAURANT_PROFILE_SPECIALIZATIONS_URL,
  POST_UNLOCK_USER_URL,
  POST_MASTER_REFERENCE_URL,
  PUT_MASTER_REFERENCE_URL,
  DELETE_MASTER_REFERENCE_URL,
  GET_CANCELLATION_REASON_BY_TYPES_REFERENCE_URL,
  GET_MASTER_REFERENCE_JOB_POSITION_LIST_URL,
  GET_MASTER_REFERENCE_JOB_POSITION_BY_ID_URL,
  POST_MASTER_REFERENCE_JOB_POSITION_URL,
  PUT_MASTER_REFERENCE_JOB_POSITION_BY_ID_URL,
  DELETE_MASTER_REFERENCE_JOB_POSITION_BY_ID_URL,
  // GET_MASTER_REFERENCE_CUSTOMER_LEVEL_LIST_URL,
  // GET_MASTER_REFERENCE_CUSTOMER_LEVEL_BY_ID_URL,
  // POST_MASTER_REFERENCE_CUSTOMER_LEVEL_URL,
  // PUT_MASTER_REFERENCE_CUSTOMER_LEVEL_URL,
  // DELETE_MASTER_REFERENCE_CUSTOMER_LEVEL_URL,
  GET_TRANSACTION_PAYMENT_TERM_BY_TRANSACTION_ID_URL,
  GET_USER_PROFILE,
  GET_USER_PROFILE_BY_USER_ID,
  DELETE_USER_PROFILE_URL,
  POST_USER_PROFILE_ACTIVATE_URL,
  POST_RESET_PASSWORD_URL,
  // GET_PAYMENT_METHOD_ALL,
  PUT_PAYMENT_METHOD,
  GET_PAYMENT_METHOD_CHOICE_LIST,
  GET_TRANSPORTATION_PROFILE_EXPORT_DATA_URL,
  GET_RESTAURANT_PROFILE_EXPORT_DATA_URL,
  GET_ACCOMMODATION_ITEM_ALL_URL,
  GET_TRANSPORTATION_UNIT_ALL_URL,
  GET_RESTAURANT_ITEM_ALL_URL,
  GET_TRANSPORTATION_UNIT_BY_SERVICE_ITEM_ID_URL,
  POST_IMAGE_RESTAURANT_PROFILE_URL,
  POST_USER_PROFILE_URL,
  POST_USER_URL,
  POST_TOUR_TRANSACTION_CONFIRMATION_URL,
  GET_USER_ROLE_LIST_URL,
  GET_USER_NOT_EMPL0YEE_URL,
  PUT_USER_PROFILE_URL,
  GET_USER_ROLE_BY_USER_ID_URL,
  DELETE_ROLE_BY_USER_ID_URL,
  POST_ADD_ROLE_BY_USER_ID_URL,
  GET_INVOICE_STATUS_PAYMENT_GATEWAY_URL,
  GET_SPECIAL_ADJUSMENT_BY_TRANSACTION_ID_URL,
  GET_TOUR_TRANSACTION_BILLED_BY_TRANSACTION_ID_URL,
  GET_TOUR_TRANSACTION_BILLS_BY_TRANSACTION_ID_URL,
  GET_ACCOMMODATION_BY_CITY_URL,
  GET_EXCURTION_BY_CITY_URL,
  GET_ACTIVITY_LIST_URL,
  GET_AIRPORT_BY_CITY_URL,
  GET_TOUR_OPERATOR_PROFILE_URL,
  GET_DRIVING_DURATION_URL,
  POST_TRANSPORTATION_UNIT_HEADER_URL,
  PUT_TRANSPORTATION_UNIT_HEADER_URL,
  GET_USER_ROLE_BY_BEARER_URL,
  GET_TRANSPORTATION_BY_FROM_TO_DATE_URL,
  GET_RESTAURANT_BY_CITY_DATE_PAX_URL,
  POST_TRANSPORTATION_UNIT_URL,
  PUT_TRANSPORTATION_UNIT_URL,
  GET_MENU_CLASS_URL,
  POST_PUBLISH_PACKAGE_URL,
  GET_TRANSACTION_ALL_BY_STATUS_URL,
  POST_SPECIAL_ADJUSMENT_URL,
  DELETE_SPECIAL_ADJUSMENT_URL,
  GET_SCHEDULE_TOUR_TRANSACTION_URL,
  POST_SET_PRIMARY_TRANSPORTATION_PROFILE_URL,
  POST_SET_PRIMARY_ACCOMODATION_PROFILE_URL,
  POST_SET_PRIMARY_RESTAURANT_PROFILE_URL,
  POST_SET_PRIMARY_TRANSPORTATION_UNIT_HEADER_URL,
  GET_INVOICE_BY_TRANSACTION_ID_URL,
  GET_GROUP_TYPE_LIST_URL,
  GET_IDENTITY_TYPE_URL,
  GET_GUEST_TITLE_TYPE_URL,
  COPY_PACKAGE_URL,
  POST_EXCHANGE_RATE_URL,
  PUT_EXCHANGE_RATE_URL,
  UPLOAD_BROCHURE_PACKAGE_URL,
  DELETE_TRANSPORTATION_UNIT_HEADER_URL,
  GET_INVOICES_BY_COMPANY_CODE_URL,
  GET_PRINT_PAYMENT_RECEIPT_URL,
  PUT_GENERAL_INFO_PACKAGE_URL,
  PUT_ALLOWED_PAX_PACKAGE_URL,
  DELETE_ACCOMODATION_ITEM_URL,
  POST_ACCEPT_QUOTATION_URL,
  POST_EDIT_QUOTATION_URL,
  GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID_URL,
  GET_TOUR_OPERATOR_PROFILE_BY_CODE_URL,
  GET_TOUR_OPERATOR_PROFILE_DETAIL_BY_ID_URL,
  GET_TOUR_OPERATOR_MARGIN_BY_ID_URL,
  DELETE_TOUR_OPERATOR_URL,
  UPDATE_TOUR_OPERATOR_PROFILE_URL,
  GET_TOUR_OPERATOR_PROFILE_BY_ID_URL,
  GET_LIST_TOUR_OPERATOR_URL,
  GET_USER_PROFILE_ACCOUNT_MANAGER_URL,
  GET_USER_PROFILE_ACCOUNT_MANAGER_BY_COMPANY_URL,
  DELETE_COMPANIES_BY_ID_URL,
  GET_RESTAURANT_RATING_URL,
  DELETE_EXCURSION_OBJECT_URL,
  DELETE_TRANSPORTATION_UNIT_URL,
  DELETE_RESTAURANT_ITEM_BY_ID_URL,
  DELETE_EXCHANGE_RATE_URL,
  POST_CONTRACTING_FILE_URL,
  DELETE_CONTRACTING_FILE_URL,
  POST_JOIN_TOUR_ON_BE_HALF_URL,
  GET_ACCOMMODATIONS_WITH_FILTER_URL,
  POST_DEMO_PRICE_URL,
  POST_CREATE_TOUR_TRANSACTION_URL,
  POST_DEMO_JOIN_TOUR_URL,
  GET_RESTAURANT_BY_FILTER_URL,
  GET_TRANSPORTATION_UNIT_BY_FILTER_URL,
  GET_EXCURSION_BY_CITY_WITH_FILTER_URL,
  GET_PAYMENT_RECEIPT_BY_TRANSACTION_ID_URL,
  GET_ALL_NOT_ALLOWED_FOR_COMPANY_URL,
  GET_NOT_ALLOWED_COUNTRY_FOR_COMPANY_BY_COMPANY_CODE_URL,
  PUT_NOT_ALLOWED_COUNTRY_FOR_COMPANY_URL,
  POST_NOT_ALLOWED_COUNTRY_FOR_COMPANY_URL,
  DELETE_IMAGE_TRANSPORTATION_UNIT_URL,
  GET_ROOM_ALLOTMENT_BY_PROFILE_ID_URL,
  POST_ROOM_ALLOTMENT_URL,
  POST_IMAGES_TOUR_OPERATOR_URL,
  POST_TOUR_OPERATOR_URL,
  DELETE_IMAGES_TOUR_OPERATOR_URL,
  POST_ROOM_ALLOTMENT_PERIOD_URL,
  GET_PAYMENT_SCHEME_BY_ID_URL,
  PUT_PAYMENT_SCHEME_URL,
  POST_PAYMENT_SCHEME_URL,
  GET_PAYMENT_SCHEME_DETAIL_BY_ID_URL,
  PUT_PAYMENT_SCHEME_DETAIL_URL,
  POST_PAYMENT_SCHEME_DETAIL_URL,
  GET_REQUEST_ROOM_ALLOTMENT_URL,
  POST_CONFIRM_ROOM_ALLOTMENT_URL,
  GET_LIST_CONFIRM_ROOM_ALLOTMENTS_URL,
  GET_RESEND_REQUEST_CONFIRMATION_URL,
  PUT_TOUR_OPERATOR_MARGIN_URL,
  POST_TOUR_OPERATOR_MARGIN_URL,
  GET_TOUR_OPERATOR_MARGIN_BY_PROFILE_ID_URL,
  DELETE_PAYMENT_SCHEME_DETAIL_URL,
  DELETE_TOUR_OPERATOR_MARGIN_URL,
  GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID_URL,
  GET_STOCK_ROOM_ALLOTMENT_URL,
  GET_EXPORT_STOCK_ROOM_ALLOTMENT_URL,
  GET_SALES_REPORT_URL,
  GET_COMPANY_COMMISSIONS_URL,
  GET_COMPANIES_BY_TYPE_URL,
  GET_TRANSACTION_INVOICE_WITH_STATUS_URL,
  POST_COMMISSION_PAYMENT_URL,
  TOUR_OPERATOR_EMAIL_SETTING_URL,
  GET_TOUR_TRANSACTIONS_COMMISSION_PAYMENT_URL,
  GET_TOUR_GUIDE_URL,
  GET_TOUR_GUIDE_BY_ID_URL,
  POST_TOUR_GUIDE_URL,
  PUT_TOUR_GUIDE_URL,
  DELETE_TOUR_GUIDE_URL,
  PACKAGE_TRANSACTION_HISTORY_URL,
  GET_ALL_SHARED_PACKAGE_URL,
  GET_DATA_TRANSFER_LOGS_URL,
  GET_DATA_TRANSFER_LOGS_BY_ID_URL,
  POST_DATA_TRANSFER_LOGS_URL,
  PUT_DATA_TRANSFER_LOGS_URL,
  DELETE_DATA_TRANSFER_LOGS_URL,
  GET_DATA_TRANSFER_LOGS_BY_RANGE_URL,
  GET_DATA_TRANSFER_LOGS_BY_REFERENCE_ID_URL,
  POST_RESEND_DATA_TOUR_TRANSACTION_URL,
  GET_FILTER_SHARED_PACKAGE_URL,
  GET_SHARED_PACKAGE_BY_ID_URL,
  LANGUAGE_URL,
  GET_CUSTOMER_PROFILE_FOR_SALES_URL,
  GET_USER_DATA_BY_CODE_FOR_SALES_URL,
  PUT_UPDATE_PROFILE_URL,
  POST_CHANGE_PASSWORD,
  GET_USER_PROFILE_ONLY_BY_USER_ID_URL,
  GET_DOWNLOAD_SCHEDULE_BY_TRANSACTION_ID_URL,
  // GET_DOWNLOAD_BROCHURE_BY_TRANSACTION_ID,
  GET_DOWNLOAD_BROCHURE_BY_BOOKING_TEMPLATE_ID_URL,
  GET_DOWNLOAD_BROCHURE_BY_BOOKING_TEMPLATE_ID_AND_IMAGE_ID_URL,
  GET_INVOICES_BY_TRANSACTION_ID_URL,
  GET_DOWNLOAD_VOUCHER_CONFIRMATION_BY_TRANSACTION_ID_URL,
  EDIT_SPECIAL_ADJUSMENT_URL,
  POST_ADDITIONAL_ITEMS_URL,
  POST_SUPPLIER_URL,
  PUT_ACCOMMODATION_SUPPLIER_URL,
  PLACE_CITIES_URL,
  GET_SERIES_VARIABLE_DATE_URL,
  POST_ADDITIONAL_INFO_PACKAGE_URL,
  PUT_ADDITIONAL_INFO_PACKAGE_URL,
  DELETE_ADDITIONAL_INFO_PACKAGE_URL,
  BOOKINGTEMPLATEIMAGES_URL,
  GET_ACCOUNT_STATEMENT_URL,
  GET_SUPPLIER_LIST_URL,
  POST_JOIN_TOUR_URL,
  GET_EXPORT_DATA_ITEM_BY_PROFILE_ID,
  GET_PARTNER_EMPLOYEE_LIST,
  GET_ADDITIONAL_SERVICES_LIST_URL,
  GET_ADDITIONAL_SERVICE_BY_ID_URL,
  PUT_ADDITIONAL_SERVICE,
  POST_ADDITIONAL_SERVICE,
  DELETE_ADDITIONAL_SERVICE,
  GET_PLACE_BY_PLACE_TYPE_ID_URL,
  GET_ADDITIONAL_SERVICES_LIST_IN_TRANSACTION_URL,
  GET_ADDITIONAL_SERVICE_TYPE_URL,
  GET_RESTRICTED_COUNTRY_BY_ADDITIONAL_SERVICE_TYPE_URL,
  GET_TRANSPORTATION_ADDITIONAL_COST_LIST_URL,
  GET_TRANSPORTATION_ADDITIONAL_COST_TYPE_URL,
  GET_ADDITIONAL_COST_BY_CITY_AND_TYPE_URL,
  DELETE_ADDITIONAL_COST_BY_CITY_AND_TYPE_URL,
  POST_TRANSPORTATION_ADDITIONAL_COST_DETAIL_URL,
  PUT_TRANSPORTATION_ADDITIONAL_COST_DETAIL_URL,
  GET_COMPANY_PROFILE,
  GET_INVOICE_TRAVEL_AGENT_URL,
  GET_INVOICE_CUSTOMER_URL,
  GET_ADDITIONAL_ITEM_TYPE_URL,
  POST_EMAIL_SEND_CONFIRMATION_URL,
  GET_LIST_TOTAL_EMPLOYEE_URL,
  PUT_ADDITIONAL_INFO_PACKAGE_OPERATION_URL,
  GET_MANUAL_CONFIRMATION_NUMBER,
  POST_CONFIRMATION_NUMBER,
  GET_PENDING_CONFIRMATION_LIST_URL,
  GET_REQUEST_ROOM_HISTORY_DETAIL_URL,
  REPORT_SALES_URL,
  GET_RESOURCE_BOOKING_BY_TOUR_TRANSACTION_CODE_URL,
  GET_RESOURCE_BOOKING_DETAIL_BY_RESOURCE_BOOKING_ID_URL,
  GET_BOOKING_CALENDAR_OPERATION_URL,
  GET_RESOURCE_BOOKING_LIST_URL,
  GET_RESOURCE_BOOKING_BY_ID_URL,
  GET_RESOURCE_BOOKING_BY_PACKAGE_NUMBER_URL,
  GET_RESORCE_BOOKING_BY_TRANSACTION_NUMBER_URL,
  GET_GENERATE_RESOURCE_BOOKING_URL,
  GET_DOWNLOAD_RESOURCE_BOOKING_DETAIL_URL,
  GET_TRANSACTION_HISTORY_BY_OPERATION_URL,
  GET_RESOURCE_BOOKING_WITH_SUMMARY_URL,
  GET_DOWNLAOD_RESOURCE_BOOKING_DETAIL_DOCUMENT_URL,
  DELETE_RESOURCE_BOOKING_COLLECTION_URL,
  GET_RESOURCE_LIST_URL,
  POST_RESOURCE,
  GET_PAYMENT_REQUEST_LIST_API,
  GET_RESOURCE_DETAIL_BY_ID_URL,
  GET_RESOURCE_BOOKING_RESERVATION_BY_ID_URL,
  POST_RESOURCE_BOOKING_RESERVATION_URL,
  PUT_RESOURCE_BOOKING_RESERVATION_URL,
  DELETE_RESOURCE_BOOKING_RESERVATION_URL,
  POST_RESOURCE_BOOKING_RESERVATION_PAYMENT_URL,
  GET_PAYMENT_REQUEST_BY_ID_API,
  PUT_PAY_RESOURCE_BOOKING_API,
  PUT_READY_TO_USE_QTY_URL,
  GET_LIST_CUSTOMER_PAYMENT_URL,
  //================
  GET_GENERATE_AR_SUMMARY_URL,
  GET_AR_SUMMARY_LIST_URL,
  DOWNLOAD_AR_SUMMARY_URL,
  PUT_RESOURCE_BOOKING_RESERVATION_PAYMENT_URL,
  POST_CANCEL_RESOURCE_URL,
  GET_RESOURCE_TYPE_ENUM_URL,
  GET_ACCOMMODATION_ESTIMATE_PRICE_URL,
  GET_ACCOMMODATION_ITEM_ESTIMATE_PRICE_URL,
  GET_ATTRACTION_ESTIMATE_PRICE_URL,
  GET_RESTAURANT_ESTIMATE_PRICE,
  GET_TRANSPORTATION_ESTIMATE_PRICE_URL,
  POST_REMOVE_GUEST_LIST,
  POST_RESOURCE_BOOKING_BY_TYPE_URL,
  GET_TRANSACTION_HISTORY_BY_TRANSACTION_URL,
  GET_DOWNLOAD_SCHEDULE_BY_BOOKING_TEMPLATE_URL,
};
