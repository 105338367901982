import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Grid, Header, Container, Form } from 'semantic-ui-react';
import InputWithLabel from '../../../../components/input/inputWithLabel';

const StatusEmployee = props => {
  return (
    <React.Fragment>
      <Header as="h4" content="Status" />
      <Grid columns={2} padded="vertically">
        <Grid.Column>
          <Form>
            <Container content="User account activation status" />
          </Form>
        </Grid.Column>
        <Grid.Column>
          <Checkbox
            toggle
            checked={!props.contentDetail.IsInactive}
            onChange={props.handleIsActive}
            disabled={props.disable}
          />
        </Grid.Column>
        {props.companyId.charAt(0) !== '1' && (
          <Form>
            <InputWithLabel
              inline={false}
              name="Supervisor"
              value={props.contentDetail.Supervisor}
              placeholder="Supervisor"
              title="Supervisor"
              label="Supervisor"
              type="select"
              //required
              handleChange={props.handleChange}
              validate={props.errorInformation}
              options={props.listSupervisors}
              disabled={props.disable}
            />
          </Form>
        )}
      </Grid>
    </React.Fragment>
  );
};

StatusEmployee.propTypes = {
  contentDetail: PropTypes.object,
  handleChange: PropTypes.func,
  errorInformation: PropTypes.object,
  handleIsActive: PropTypes.func,
  listSupervisors: PropTypes.array,
  companyId: PropTypes.string,
  disable: PropTypes.bool,
};
export default StatusEmployee;
