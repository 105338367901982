import React from 'react';
import { Table, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
const TablePriceExcursion = props => (
  <Table basic="very" selectable>
    <Table.Header>
      <Table.Row>
        {props.headerTable.map((headTableContent, index) => {
          return (
            <Table.HeaderCell key={index}>{headTableContent}</Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {props.listData ? (
        props.listData.map((margin, idx) => {
          return (
            <Table.Row key={idx}>
              <Table.Cell>
                {margin.ServicePriceType.replace('_', ' ')}
              </Table.Cell>
              <Table.Cell>{margin.Description}</Table.Cell>
              <Table.Cell>{margin.MinimumPax}</Table.Cell>
              {/* <Table.Cell>{margin.Currency}</Table.Cell> */}
              <Table.Cell textAlign="right">
                {margin.Currency +
                  '           ' +
                  new Intl.NumberFormat('de-DE').format(margin.Cost)}
              </Table.Cell>
              <Table.Cell>
                {moment(margin.StartDate).format('DD MMMM YYYY')}
              </Table.Cell>
              <Table.Cell>
                {moment(margin.EndDate).format('DD MMMM YYYY')}
              </Table.Cell>
              <Table.Cell>{margin.CutOffDay}</Table.Cell>
              <Table.Cell>
                <Button
                  name={idx}
                  positive
                  content="Edit"
                  onClick={props.handleEdit}
                  value={margin.Id}
                  disabled={props.disablePage}
                />
                <Button
                  name={idx}
                  negative
                  content="Delete"
                  onClick={props.deletePrice}
                  value={margin.Id}
                  disabled={props.disablePage}
                />
              </Table.Cell>
            </Table.Row>
          );
        })
      ) : (
        <Table.Row>
          <Table.Cell singleLine textAlign="center">
            No Price Recorded
          </Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  </Table>
);

TablePriceExcursion.propTypes = {
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  servicePriceType: PropTypes.array,
  currenciesList: PropTypes.array,
  handleChangePrice: PropTypes.func,
  handleChangePrices: PropTypes.func,
  deletePrice: PropTypes.func,
  placeholderType: PropTypes.string,
  handleEdit: PropTypes.func,
  disablePage: PropTypes.bool,
};
export default TablePriceExcursion;
