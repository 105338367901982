import React from 'react';
import { Table, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
const tablePaymentMethod = props => (
  <Table basic="very" selectable fixed>
    <Table.Header>
      <Table.Row>
        {props.headerTable.map((headTableContent, index) => {
          return (
            <Table.HeaderCell key={index}>{headTableContent}</Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {props.listData &&
        props.listData.map((data, idx) => {
          return (
            <Table.Row key={idx}>
              <Table.Cell>{data.Country.Text}</Table.Cell>
              <Table.Cell>{data.PaymentGateawayChoice}</Table.Cell>
              <Table.Cell>
                <Button
                  content="Edit"
                  primary
                  id={data.Country.Value}
                  onClick={props.butttonEdit}
                />
              </Table.Cell>
            </Table.Row>
          );
        })}
    </Table.Body>
  </Table>
);

tablePaymentMethod.propTypes = {
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  butttonEdit: PropTypes.func,
};
export default tablePaymentMethod;
