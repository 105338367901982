import React, { Component } from 'react';
import { Table, Input, Button, Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { HeaderTableCustomerProfileUserData } from '../../../../../components/table/headerTabel';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../../../scripts/pagination';
// import FilterData from '../../../../../scripts/filterDataTable';
import LoadingPage from '../../../../../components/modal/loaderModal';
import Pagination from '../../../../../components/common/pagination';
import { filterDataEmployee } from '../../services/data/filterDataEmployee';
class TableUserData extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
    };
  }
  static propTypes = {
    data: PropTypes.array,
  };
  handleChange = (e, { name, value }) => this.setState({ [name]: value });
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  render() {
    let { isHideAddEmployee, isHideResetPassword, isHideUnlock } = this.props;
    // let filterData = FilterData(
    //   this.props.listData,
    //   'FirstName',
    //   this.state.searchInput
    // );
    let filterData = filterDataEmployee(
      this.props.listData || [],
      this.state.searchInput
    );
    let pagedData = datasetPagination(filterData, 3);
    let listData = pagedData[this.state.activePage - 1];
    const props = this.props;

    let { numbersFrom, numbersTo } = getNumberFromTo(
      listData,
      this.state.activePage,
      3
    );
    return (
      <div>
        <LoadingPage loading={this.props.loading} />
        <Input
          icon="search"
          placeholder="search"
          name="searchInput"
          value={this.state.searchInput}
          onChange={this.handleChange}
        />
        {!isHideAddEmployee && (
          <Button
            icon="add"
            content="Add Employee"
            positive
            floated="right"
            onClick={this.props.buttonModalEmployee}
            disabled={this.props.isNewForm}
          />
        )}
        <Table basic="very" selectable fixed>
          <Table.Header>
            <Table.Row>
              {HeaderTableCustomerProfileUserData.map(
                (headTableContent, index) => {
                  return (
                    <Table.HeaderCell key={index}>
                      {headTableContent}
                    </Table.HeaderCell>
                  );
                }
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {listData &&
              listData.map((userData, idx) => {
                return (
                  <Table.Row key={idx}>
                    <Table.Cell component="th" scope="row" width={1}>
                      {userData.Username ? userData.Username : '-'}
                    </Table.Cell>
                    <Table.Cell>
                      {userData.FirstName} {''}
                      {userData.LastName}
                    </Table.Cell>
                    <Table.Cell>
                      {' '}
                      {userData.Telephone ? userData.Telephone : '-'}
                    </Table.Cell>
                    <Table.Cell>
                      {' '}
                      {userData.Email ? userData.Email : '-'}
                    </Table.Cell>
                    <Table.Cell> {userData.Address}</Table.Cell>
                    <Table.Cell>
                      {' '}
                      {!userData.IsInactive ? 'Active' : 'InActive'}
                    </Table.Cell>
                    <Table.Cell>
                      {!isHideUnlock && (
                        <Popup
                          trigger={
                            <Button
                              icon="unlock alternate"
                              onClick={this.props.unlockUser}
                              id={userData.UserId}
                              color="blue"
                              disabled={
                                !userData.IsLocked || this.props.isFinance
                                  ? true
                                  : false
                              }
                            />
                          }
                          content={
                            !userData.IsLocked
                              ? 'Unlock employee'
                              : 'Lock employee'
                          }
                        />
                      )}
                      {!isHideResetPassword && (
                        <Popup
                          trigger={
                            <Button
                              icon="key"
                              onClick={this.props.resetPassword}
                              id={userData.Id}
                              color="blue"
                              disabled={this.props.isNewForm}
                            />
                          }
                          content="Reset password employee"
                        />
                      )}
                      <Popup
                        trigger={
                          <Button
                            icon="edit"
                            onClick={this.props.editUser}
                            id={userData.Id}
                            color="blue"
                            disabled={this.props.isNewForm}
                          />
                        }
                        content="Edit data employee"
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        {/* {pagedData &&
            pagedData.length > 1 && (
              <Table.Footer> */}
        <Pagination
          // totalPages={pagedData.length}
          // onPageChange={this.pageChange}
          // activePage={this.state.activePage}
          pagedData={pagedData}
          numbersTo={numbersTo}
          numbersFrom={numbersFrom}
          data={props.listData}
          pageChange={this.pageChange}
          activePage={this.state.activePage}
        />
        {/* </Table.Footer>
            )} */}
      </div>
    );
  }
}

TableUserData.propTypes = {
  listData: PropTypes.array,
  unlockUser: PropTypes.func,
  buttonModalEmployee: PropTypes.func,
  resetPassword: PropTypes.func,
  editUser: PropTypes.func,
  buttonAddUser: PropTypes.func,
  loading: PropTypes.bool,
  isNewForm: PropTypes.bool,
  isHideAddEmployee: PropTypes.bool,
  isHideUnlock: PropTypes.bool,
  isHideResetPassword: PropTypes.bool,
  isFinance: PropTypes.bool,
};
export default TableUserData;
