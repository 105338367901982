import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { Segment, Grid, Button, Image, Header, Table } from 'semantic-ui-react';
import Styles from './styles';
import Loader from '../../components/loader/loader';
import HeaderTitle from '../shared/components/common/headerSegment/HeaderTitle';
import { Redirect } from 'react-router-dom';
import ModalAlert from '../shared/components/modal/ModalAlert';
import {
  getDownloadScheduleByBookingTemplate,
  getActivityListAction,
} from '../../actions/packageAction';
import { PACKAGE_DETAIL } from './constant/constSimplePackageInformation';
import { getReadyPackageByTypeAndIdAction } from '../../actions/readyPackageAction';
import { initialDetailPackage } from './services/data/setDataSimplePackageInformation';
import { handleDownloadFile } from '../../scripts/download';
import { convertDateFormat } from '../../scripts/moment';
import TourItinerary from '../shared/components/tourTransaction/dailyProgram/TourItinerary';
import { copyObject } from '../../scripts/itineraryBuilder/itineraryBuilder';
import AdditionalInformation from '../tourTransaction/transactionDetail/components/SegmentAdditionalInformation/AdditionalInformation';
import ModalDownloadImage from '../../components/modal/ModalDownloadImage';
import { getUrlImageBrochureOverlay } from './services/helper/getUrlImageBrochureOverlay';
import { GET_DOWNLOAD_BROCHURE_BY_BOOKING_TEMPLATE_ID_AND_IMAGE_ID_URL } from '../../api/apiUrl';
const FIXED_PACKAGE = 'Fixed';
const READY_PACKAGE = 'Ready';
// const FIXED_PRICE_PACKAGE = 'FixedDateVariable';
class SimplePackageInformation extends React.Component {
  //==========initial state=========
  constructor(props) {
    super(props);
    this.state = {
      changePage: {
        isChangePage: false,
        link: '',
      },
      packageType: null,
      id: null,
      modalAlert: {
        isOpen: false,
        header: '',
        message: '',
        url: '',
      },
      packageDetail: copyObject(PACKAGE_DETAIL),
      dataForImage: {
        openModalDownloadImage: false,
        imagesDownloadModal: [],
      },
    };
    //========== end initial state=========
  }
  //========== initial useEffect ===========
  componentDidMount = () => {
    this.load();
  };
  //========== end initial useEffect ===========
  // ==== function ============
  load = async () => {
    let { Id, PackageType } = this.props.match.params;
    await this.setState({ ...this.state, id: Id, packageType: PackageType });
    this.props.getActivityListAction();
    this.props
      .getReadyPackageByTypeAndIdAction(PackageType, Id)
      .then(res => {
        this.setState({
          ...this.state,
          packageDetail: initialDetailPackage(PackageType, res.value.data),
        });
      })
      .catch(err => {
        this.openCloseModalAlert({
          isOpen: true,
          header: 'Failed',
          message: err.response
            ? err.response.data.Message || 'Failed get template'
            : err,
        });
      });
  };
  openCloseModalAlert = dataAlert => {
    let isOpen = dataAlert.isOpen === true;
    this.setState({
      ...this.state,
      modalAlert: {
        ...this.state.modalAlert,
        isOpen,
        header: isOpen ? dataAlert.header : '',
        message: isOpen ? dataAlert.message : '',
      },
    });
  };
  setDailyProgram = dailyProgram => {
    this.setState({
      ...this.state,
      packageDetail: {
        ...this.state.packageDetail,
        dailyProgram,
      },
    });
  };
  handleheadlineProgram = headlineProgram => {
    this.setState({
      ...this.state,
      packageDetail: {
        ...this.state.packageDetail,
        headlineProgram,
      },
    });
  };
  handleTourOperator = tourOperator => {
    this.setState({
      ...this.state,
      packageDetail: {
        ...this.state.packageDetail,
        tourOperator,
      },
    });
  };
  // ==== end function ==========
  // ========= handle button ===================
  handleBack = () => {
    let { packageType } = this.state;
    this.setState({
      ...this.state,
      changePage: {
        ...this.state.changePage,
        isChangePage: true,
        link:
          '/PackageList/' + (packageType === FIXED_PACKAGE ? 'Fixed' : 'Ready'),
      },
    });
  };
  handleCreateTransaction = () => {
    let { packageType, id } = this.state;
    this.setState({
      ...this.state,
      changePage: {
        ...this.state.changePage,
        isChangePage: true,
        link: '/tour-transaction/create/' + packageType + '/' + id,
      },
    });
  };
  handleDownloadSchedule = () => {
    this.props
      .getDownloadScheduleByBookingTemplate(this.state.id)
      .then(res => window.open(res.value.data))
      .catch(err => {
        this.openCloseModalAlert({
          isOpen: true,
          header: 'Failed',
          message: err.response.data.Message || 'Failed download schedule',
        });
      });
  };
  // handleDownloadBrochure = (e, { name, value }) => {
  //   handleDownloadFile(value, name);
  // };

  handleClickDownloadBrochure = () => {
    let dataForImage = { ...this.state.dataForImage };
    dataForImage.openModalDownloadImage = true;
    this.setState({ dataForImage });
  };

  handleDownloadBrochure = () => {
    let dataImage = { ...this.state.dataForImage };
    let imagesDownload = dataImage.imagesDownloadModal;
    let imageToDownload = imagesDownload.find(item => item.IsPrimaryImage);
    // let imageUrl = imageToDownload.ImageUrl.replace(/(\r\n|\n|\r)/gm, '');
    let imageUrl = getUrlImageBrochureOverlay(
      GET_DOWNLOAD_BROCHURE_BY_BOOKING_TEMPLATE_ID_AND_IMAGE_ID_URL(
        this.props.match.params.Id,
        30000,
        imageToDownload.ImageId
      )
    );
    handleDownloadFile(imageUrl, '');
  };

  handleChangeSelectedImageDownload = (e, { value }) => {
    const { packageDetail } = this.state;
    let listImage = [...packageDetail.packageImages];
    let newList = [];
    listImage.map((img, i) => {
      img['IsPrimaryImage'] = i === value ? true : false;
      return newList.push(img);
    });
    let dataForImage = {
      ...this.state.dataForImage,
      imagesDownloadModal: newList,
    };
    this.setState({ ...this.state, dataForImage });
  };

  handleCloseModalDownload = () => {
    let dataForImage = {
      ...this.state.dataForImage,
      openModalDownloadImage: false,
    };
    this.setState({
      ...this.state,
      dataForImage,
    });
  };

  // ========= end handle button ===================
  render() {
    let {
      changePage,
      packageType,
      packageDetail,
      modalAlert,
      dataForImage,
    } = this.state;
    let {
      loadingGetTemplate,
      loadingGetDownloadBookingTemplate,
      activityList,
    } = this.props;

    if (changePage.isChangePage) {
      return <Redirect to={changePage.link} />;
    } else
      return (
        <Segment style={Styles.marginTop30px}>
          <Loader
            active={loadingGetTemplate}
            description="Loading"
            page={true}
          />
          <HeaderTitle title="Package Information" />
          <Grid>
            <Grid.Row>
              <Grid.Column width={16} textAlign="right">
                <Button
                  content="Back"
                  style={Styles.buttonBorderBlue}
                  onClick={this.handleBack}
                />
                <Button
                  content="Create Transaction"
                  positive
                  style={Styles.padding12point5}
                  onClick={this.handleCreateTransaction}
                />
                <Button
                  content="Download Schedule"
                  primary
                  style={Styles.buttonBlue}
                  onClick={this.handleDownloadSchedule}
                  loading={loadingGetDownloadBookingTemplate}
                />
                {packageType === FIXED_PACKAGE && packageDetail.brochure && (
                  <Button
                    content="Download Brochure"
                    primary
                    style={Styles.buttonBlue}
                    // onClick={this.handleDownloadBrochure}
                    onClick={this.handleClickDownloadBrochure}
                    value={packageDetail.brochure.ImageUrl}
                    name={packageDetail.brochure.ImageName}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              {/* ====== gambar atau city image========== */}
              <Grid.Column width={5}>
                <Image
                  src={
                    packageDetail.brochure
                      ? packageDetail.image
                      : require('./../../assets/Icons/no_image.jpg')
                    //   generalInfo.image
                    //     ? generalInfo.image
                    //     : require('./../../assets/Icons/no_image.jpg')
                  }
                  size="medium"
                />
              </Grid.Column>
              <Grid.Column width={11}>
                {/* ========= general information */}
                <Header as="h4" content="General Information" />
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={6}>Tour name</Grid.Column>
                    <Grid.Column width={10}>
                      : {packageDetail.tourName || '-'}
                    </Grid.Column>
                    <Grid.Column width={6}>First Destination</Grid.Column>
                    <Grid.Column width={10}>
                      : {packageDetail.firstDestination || '-'}
                    </Grid.Column>
                    <Grid.Column width={6}>Description</Grid.Column>
                    <Grid.Column width={10}>
                      : {packageDetail.description || '-'}
                    </Grid.Column>
                    <Grid.Column width={6}>Tour Type</Grid.Column>
                    <Grid.Column width={10}>
                      : {packageDetail.tourType || '-'}
                    </Grid.Column>
                    <Grid.Column width={6}>Tour Category</Grid.Column>
                    <Grid.Column width={10}>
                      : {packageDetail.tourCategory || '-'}
                    </Grid.Column>
                    <Grid.Column width={6}>Tour Date</Grid.Column>
                    <Grid.Column width={10}>
                      : {packageDetail.tourDate || '-'}
                    </Grid.Column>
                    {packageType === FIXED_PACKAGE && (
                      <>
                        <Grid.Column width={6}>Pax Available</Grid.Column>
                        <Grid.Column width={10}>
                          : {packageDetail.paxAvailable || '-'}
                        </Grid.Column>
                        <Grid.Column width={6}>Total Commission</Grid.Column>
                        <Grid.Column width={10}>
                          :{' '}
                          {packageDetail.currency +
                            ' ' +
                            (packageDetail.totalCommission
                              ? packageDetail.totalCommission.toLocaleString(
                                  'id'
                                )
                              : 0)}
                        </Grid.Column>
                      </>
                    )}
                  </Grid.Row>
                </Grid>
                {/* =========  end general information */}
                {/* ========= price */}
                {packageType === READY_PACKAGE ? null : (
                  <>
                    <Header as="h4" content="Room Allocation Price" />
                    <Table
                      basic="very"
                      selectable
                      style={{ paddingRight: '20px' }}
                    >
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Room type</Table.HeaderCell>
                          <Table.HeaderCell>Price</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {packageDetail.roomAllocationPrice.map(e => (
                          <Table.Row key={e.Id}>
                            <Table.Cell>{e.Room}</Table.Cell>
                            <Table.Cell>
                              {e.Currency +
                                ' ' +
                                (e.Price ? e.Price.toLocaleString('id') : 0)}
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </>
                )}
                {/* ======== end price */}
              </Grid.Column>
            </Grid.Row>
            {packageType === FIXED_PACKAGE &&
              packageDetail.paymentTerms.length > 0 && (
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Header as="h4" content="Payment Terms" />
                    <Table
                      basic="very"
                      selectable
                      style={{ paddingRight: '20px' }}
                    >
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Description</Table.HeaderCell>
                          <Table.HeaderCell>
                            Payment Percentage
                          </Table.HeaderCell>
                          <Table.HeaderCell>Paid On</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {packageDetail.paymentTerms.map(e => (
                          <Table.Row key={e.Id}>
                            <Table.Cell>{e.Description}</Table.Cell>
                            <Table.Cell>{e.PaymentPercentage + '%'}</Table.Cell>
                            <Table.Cell>
                              {convertDateFormat(e.DueDate, 'DD MMM YYYY')}
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </Grid.Column>
                </Grid.Row>
              )}
            <Grid.Row>
              <Grid.Column width={16}>
                <TourItinerary
                  dailyProgram={packageDetail.dailyProgram}
                  headlineProgram={packageDetail.headlineProgram}
                  setDailyProgram={this.setDailyProgram}
                  typePackage={'Fixed'}
                  movementList={activityList}
                  setheadlineProgram={this.setheadlineProgram}
                  guests={{
                    adults: 2,
                    childs: 0,
                    infants: 0,
                  }}
                  setTourOperator={this.setTourOperator}
                  currentPackage={{
                    disabled: false,
                    disabledEdit: false,
                    id: null,
                    status: 'edit',
                    type: 'Fixed',
                  }}
                  isReadyFixedPrice={false}
                />
              </Grid.Column>
            </Grid.Row>
            {packageDetail.additionalInfo &&
              packageDetail.additionalInfo.length > 0 && (
                <Grid.Row>
                  <Grid.Column width={16}>
                    <AdditionalInformation
                      descriptions={packageDetail.additionalInfo}
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
          </Grid>
          <ModalAlert
            openModal={modalAlert.isOpen}
            handleClose={this.openCloseModalAlert}
            header={modalAlert.header}
            content={modalAlert.message}
          />
          <ModalDownloadImage
            openModal={dataForImage.openModalDownloadImage}
            handleClose={this.handleCloseModalDownload}
            header="Download Brochure Image"
            image={packageDetail.packageImages}
            handleDownloadImage={this.handleDownloadBrochure}
            handleChangePrimaryImage={this.handleChangeSelectedImageDownload}
          />
        </Segment>
      );
  }
}
SimplePackageInformation.propTypes = {
  match: PropTypes.object,
  getReadyPackageByTypeAndIdAction: PropTypes.func,
  getDownloadScheduleByBookingTemplate: PropTypes.func,
  loadingGetTemplate: PropTypes.bool,
  loadingGetDownloadBookingTemplate: PropTypes.bool,
  getActivityListAction: PropTypes.func,
  activityList: PropTypes.array,
};
const mapStateToProps = state => {
  return {
    loadingGetDownloadBookingTemplate:
      state.PackageStore.loadingGetDownloadBookingTemplate,
    activityList: state.PackageStore.activityList,
    loadingGetTemplate: state.ReadyPackageStore.loading,
  };
};
export default connect(mapStateToProps, {
  getDownloadScheduleByBookingTemplate,
  getReadyPackageByTypeAndIdAction,
  getActivityListAction,
})(SimplePackageInformation);
