export const headerTablePaymentRequest = [
  'Payment Request No.',
  'Resource Booking No.',
  'Package No. / Booking Code',
  'Package Type',
  'Status',
  'Ownership',
  'Total Amount',
  'Request Date',
  'Due Date',
  'Priority',
  'Action',
];
