import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Image } from 'semantic-ui-react';
import UploadButton from '../../../../components/common/uploadImageButton';
const GeneralInformation = props => {
  return (
    <React.Fragment>
      <Header as="h4" content="Profile Picture" />
      <Grid columns={2} padded="horizontally">
        <Grid.Column>
          <Image
            size="medium"
            circular
            src={
              props.contentDetail.ImageUrl ||
              props.imagePreview ||
              require('../../../../assets/Icons/no_image.jpg')
            }
          />
        </Grid.Column>
      </Grid>
      <UploadButton
        onImageSelect={props.handleChangeImage}
        label={
          props.contentDetail.ImageUrl === '' || props.imagePreview === ''
            ? 'Select Image'
            : 'Change Image'
        }
        buttonStyle={{ marginTop: '3em', marginLeft: '4em' }}
        disabled={props.disable}
      />
    </React.Fragment>
  );
};

GeneralInformation.propTypes = {
  contentDetail: PropTypes.object,
  imagePreview: PropTypes.string,
  handleChangeImage: PropTypes.func,
  disable: PropTypes.bool,
};
export default GeneralInformation;
