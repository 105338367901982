const headerTableImageAccomodationProfile = [
  'File Name',
  'Thumbnail',
  'Action',
];

const headerTableLocationAccomodationProfile = [
  'Location Description',
  'Action',
];

const headerTableContractingFileAccomodationProfile = [
  'File Name',
  'Valid Period',
  'Description',
  'Action',
];

const headerTableFacilitiesAccomodationProfile = ['Facilities Name', 'Action'];

export {
  headerTableImageAccomodationProfile,
  headerTableLocationAccomodationProfile,
  headerTableContractingFileAccomodationProfile,
  headerTableFacilitiesAccomodationProfile,
};
