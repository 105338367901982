import { copyObject } from '../../../scripts/itineraryBuilder/dailyProgram';
import { convertDateFormat, dateNow } from '../../../scripts/moment';
export const ADDITIONAL_SERVICE = 'Additional Service';
export const MENU_RESOURCE_BOOKING_DETAIL = [
  { value: 'RESOURCE_ITEM_LIST', title: 'Resource Item List' },
  { value: 'TOUR_NOTE_LIST', title: 'Tour Note List' },
];
export let ERRORS_RESOURCE = {
  ResourceName: {
    error: false,
    message: '',
  },
  Qty: {
    error: false,
    message: '',
  },
  UnitCost: {
    error: false,
    message: '',
  },
  Date: {
    error: false,
    message: '',
  },
  DueDate: {
    error: false,
    message: '',
  },
  TourOperatorProfileId: {
    error: false,
    message: '',
  },
  CompanyId: {
    error: false,
    message: '',
  },
  Currency: {
    error: false,
    message: '',
  },
  Description: {
    error: false,
    message: '',
  },
  QtyReadyToUse: {
    error: false,
    message: '',
  },
  ResourceType: {
    error: false,
    message: '',
  },
};

export let RESOURCE = {
  AllowedDelete: true,
  Type: 'other',
  ResourceName: '',
  Qty: 0,
  UnitCost: 0,
  Date: convertDateFormat(dateNow, 'YYYY-MM-DD'),
  EndDate: convertDateFormat(dateNow, 'YYYY-MM-DD'),
  DueDate: convertDateFormat(dateNow, 'YYYY-MM-DD'),
  CompanyId: 0,
  TourOperatorProfileId: '',
  Currency: '',
  Description: '',
  // after add
  QtyReadyToUse: 0,
  ActualCost: 0,
  PaymentStatus: '',
  ResourceStatus: '',
  IsAdjutment: true,
  ResourceType: '',
  CompleteDate: convertDateFormat(dateNow, 'YYYY-MM-DDThh:mm:ss'),
  CompleteEndDate: convertDateFormat(dateNow, 'YYYY-MM-DDThh:mm:ss'),
  ServiceItemId: null,
};
export const validationAddResource = resource => {
  let newErrors = copyObject(ERRORS_RESOURCE);
  let isError = false;
  if (resource.ResourceName === '') {
    isError = true;
    newErrors.ResourceName.error = true;
    newErrors.ResourceName.message = "Field can't be empty!";
  }
  if (resource.Qty === 0 || resource.Qty < 0) {
    isError = true;
    newErrors.Qty.error = true;
    newErrors.Qty.message =
      resource.Qty < 0
        ? 'Qty cannot be smaller than 0'
        : "Field can't be empty!";
  }
  if (resource.UnitCost === 0) {
    isError = true;
    newErrors.UnitCost.error = true;
    newErrors.UnitCost.message = "Field can't be empty!";
  }
  if (resource.CompanyId === '') {
    isError = true;
    newErrors.CompanyId.error = true;
    newErrors.CompanyId.message = "Field can't be empty!";
  }
  if (resource.CompanyId !== 0 && resource.TourOperatorProfileId === '') {
    isError = true;
    newErrors.TourOperatorProfileId.error = true;
    newErrors.TourOperatorProfileId.message = "Field can't be empty!";
  }
  if (resource.Currency === '') {
    isError = true;
    newErrors.Currency.error = true;
    newErrors.Currency.message = "Field can't be empty!";
  }
  if (resource.Description === '') {
    isError = true;
    newErrors.Description.error = true;
    newErrors.Description.message = "Field can't be empty!";
  }
  if (
    parseInt(resource.QtyReadyToUse, 10) > parseInt(resource.Qty, 10) ||
    parseInt(resource.QtyReadyToUse, 10) < 0
  ) {
    isError = true;
    newErrors.QtyReadyToUse.error = true;
    newErrors.QtyReadyToUse.message =
      parseInt(resource.QtyReadyToUse, 10) < 0
        ? 'Qty ready to use cannot be smaller than 0'
        : "qty ready to use can't be more than qty";
  }
  if (resource.ResourceType === '') {
    isError = true;
    newErrors.ResourceType.error = true;
    newErrors.ResourceType.message = "Field can't be empty!";
  }

  return { isError, errors: newErrors };
};

export const generateResourceBookingDetail = resourceBookingDetailWithSummary => {
  let {
    CreatedDate,
    CreatedBy,
    LastModifiedDate,
    LastModifiedBy,
    TourTransactionSummaries,
    ImageUrl,
    ResourceBooking,
    BookingTemplateSummary,
  } = resourceBookingDetailWithSummary;
  return {
    CreatedDate: CreatedDate,
    CreatedBy: CreatedBy,
    LastModifiedDate: LastModifiedDate,
    LastModifiedBy: LastModifiedBy,
    Title: TourTransactionSummaries[0]
      ? TourTransactionSummaries[0].BookingDetailSum.Title
      : '',
    FirstDestination: TourTransactionSummaries[0]
      ? TourTransactionSummaries[0].BookingDetailSum.City.Name +
        ', ' +
        resourceBookingDetailWithSummary.TourTransactionSummaries[0]
          .BookingDetailSum.Country.Name
      : '',
    TourType: TourTransactionSummaries[0]
      ? TourTransactionSummaries[0].BookingDetailSum.TourCategory.Name
      : '',
    StartDate: TourTransactionSummaries[0]
      ? TourTransactionSummaries[0].BookingDetailSum.StartDate
      : '',
    EndDate: TourTransactionSummaries[0]
      ? TourTransactionSummaries[0].BookingDetailSum.EndDate
      : '',
    Description: TourTransactionSummaries[0]
      ? TourTransactionSummaries[0].BookingDetailSum.Description
      : '',
    TourNote: TourTransactionSummaries[0]
      ? TourTransactionSummaries[0].TourNote
      : '',
    IsAllowedUpdate: TourTransactionSummaries[0]
      ? TourTransactionSummaries[0].BookingDetailSum.PackageType === 'Fixed'
      : false,
    ImageUrl,
    CityImage: TourTransactionSummaries[0]
      ? TourTransactionSummaries[0].BookingDetailSum.City.ImageUrl
      : '',
    PackageType: TourTransactionSummaries[0]
      ? TourTransactionSummaries[0].BookingDetailSum.PackageType
      : '',
    ResourceBooking: ResourceBooking,
    Id:
      TourTransactionSummaries[0].BookingDetailSum.PackageType === 'Fixed'
        ? BookingTemplateSummary
          ? BookingTemplateSummary.BookingDetailSum.Id
          : '-'
        : TourTransactionSummaries[0]
        ? TourTransactionSummaries[0].BookingDetailSum.Id
        : '',
    Allotment: generateAllotment(TourTransactionSummaries),
  };
};
const generateAllotment = TourSummaries => {
  let allotment = TourSummaries.reduce(
    (acc, e) => {
      acc = {
        ...acc,
        SharingRoomQty: acc.SharingRoomQty + e.TourPriceSum.SharingRoomSum.Pax,
        ChildSharingRoomQty:
          acc.ChildSharingRoomQty + e.TourPriceSum.ChildSharingRoomSum.Pax,
        SingleRoomQty: acc.SingleRoomQty + e.TourPriceSum.SingleRoomSum.Pax,
        ChildSingleRoomQty:
          acc.ChildSingleRoomQty + e.TourPriceSum.ChildSingleRoomSum.Pax,
        ExtraBedQty: acc.ExtraBedQty + e.TourPriceSum.ExtraBedSum.Pax,
        ChildExtraBedQty:
          acc.ChildExtraBedQty + e.TourPriceSum.ChildExtraBedSum.Pax,
        SharingBedQty: acc.SharingBedQty + e.TourPriceSum.SharingBedSum.Pax,
        NoBed: acc.NoBed + e.TourPriceSum.NoBedSum.Pax,
      };
      return acc;
    },
    {
      SharingRoomQty: 0,
      ChildSharingRoomQty: 0,
      SingleRoomQty: 0,
      ChildSingleRoomQty: 0,
      ExtraBedQty: 0,
      ChildExtraBedQty: 0,
      SharingBedQty: 0,
      BabyCrib: 0,
      NoBed: 0,
    }
  );
  return allotment;
};
export const setStringPaymentStatus = paymentStatus => {
  return paymentStatus === 'PARTIALPAYMENT'
    ? 'Partial Payment'
    : paymentStatus === 'UNPAID'
    ? 'Unpaid'
    : paymentStatus === 'CANCELED'
    ? 'Canceled'
    : paymentStatus === 'FULLPAYMENT'
    ? 'Full Payment'
    : paymentStatus;
};
export const setStringResourceStatus = resourceStatus => {
  return resourceStatus === 'CREATED'
    ? 'Created'
    : resourceStatus === 'PARTIALBOOKING'
    ? 'Partial Booking'
    : resourceStatus === 'FULLBOOKED'
    ? 'Full Booked'
    : resourceStatus === 'CANCELED'
    ? 'Canceled'
    : resourceStatus;
};
export const generateResource = (resource, mode) => {
  let isReIssue = mode === 'ReIssue';
  let resourceType =
    resource.ResourceType === 'Additional Service'
      ? resource.ResourceName.split(' Type - ').join('-')
      : `${resource.ResourceType}-${resource.ResourceType}`;
  return {
    AllowedDelete: true,
    Type: resource.generateResource ? resource.generateResource : 'other',
    ResourceName: resource.ResourceName,
    Qty: isReIssue ? resource.CancelQty : resource.Qty,
    UnitCost: resource.UnitCost,
    Date: convertDateFormat(resource.Date, 'YYYY-MM-DD'),
    EndDate: convertDateFormat(resource.EndDate, 'YYYY-MM-DD'),
    CompleteDate: resource.Date,
    CompleteEndDate: resource.EndDate,
    DueDate: convertDateFormat(resource.DueDate, 'YYYY-MM-DD'),
    CompanyId: resource.ResourceOwnerShip
      ? resource.ResourceOwnerShip.Value !== ''
        ? resource.ResourceOwnerShip.Value
        : 0
      : 0,
    // resource.ResourceOwnerShip === 'Touress' ? 0 : resource.ResourceOwnerShip,
    TourOperatorProfileId: resource.TourOperatorProfile
      ? resource.TourOperatorProfile.Id
      : '',
    Currency: resource.Currency,
    Description: resource.Description,
    Id: resource.Id,
    IsAdjutment: resource.IsAdjutment,
    PaymentStatus: isReIssue
      ? ''
      : setStringPaymentStatus(resource.PaymentStatus),
    ResourceStatus: isReIssue
      ? ''
      : setStringResourceStatus(resource.ResourceStatus),
    ActualCost: isReIssue ? 0 : resource.ActualCost,
    QtyReadyToUse: isReIssue ? 0 : resource.Ready,
    ResourceType: resourceType,
    ResourceBookingDetailColletionIdRef: isReIssue
      ? resource.ResourceBookingDetailColletionRefId
      : null,
  };
};

// KETENTUAN UNTUK MENYIMPAN NAMA RESOURCE DARI BE
// kalo tipenya others namenya "[freetext]"
// kalo tipenya accom, excursion, meal namenya "[profile]-[item]"
// else wkwkw namenya "[tipe]-[freetext]"
// KETENTUAN UNTUK MENYIMPAN DESKRIPSI RESOURCE DARI BE
export const generatForCallApi = (resource, idResourceBookingDetail) => {
  let resourceTypeValue = resource.ResourceType.split('-')[0];
  // let resourceTypeText = resource.ResourceType.split('-')[1];
  return {
    ResourceName: resource.ResourceName,
    Description: resource.Description,
    Qty: resource.Qty,
    UnitCost: resource.UnitCost,
    Currency: resource.Currency,
    Date: convertDateFormat(resource.Date, 'YYYY-MM-DDThh:mm:ss'),
    EndDate: convertDateFormat(resource.EndDate, 'YYYY-MM-DDThh:mm:ss'),
    DueDate: convertDateFormat(resource.DueDate, 'YYYY-MM-DDThh:mm:ss'),
    ResourceBookingId: idResourceBookingDetail,
    CompanyId: resource.CompanyId === 0 ? '' : resource.CompanyId,
    TourOperatorProfileId: resource.TourOperatorProfileId,
    ResourceType: resourceTypeValue,
  };
};

export const generateResourceReservation = listResourceReservation => {
  return listResourceReservation.map(e => ({
    ...e,
    ResourceStatus: getStringResourceReservationStatus(e.ResourceStatus),
    Date: convertDateFormat(e.Date, 'YYYY-MM-DDThh:mm:ss'),
  }));
};

const getStringResourceReservationStatus = status => {
  return status === 0
    ? 'CREATED'
    : status === 1
    ? 'PARTIALBOOKING'
    : 'FULLBOOKED';
};

export const generateTourNoteList = transactionList => {
  return transactionList.map(e => ({
    id: e.BookingDetailSum.Id,
    note: e.TourNote,
  }));
};
