import React from 'react';
import { Dimmer, Grid, Segment, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {
  resetUserAccessAction,
  getUserProfileByUserIdAction,
} from '../../../actions/userAccessAction';
import { getUserRoleByBearerAction } from './../../../actions/userAccessAction';
import {
  resetCustomerProfileAction,
  getAccountManagerAction,
  deleteUserProfileByIdAction,
  postUserProfileActivateAction,
} from '../../shared/actions/customerProfileAction';
import {
  resetUserInfoAction,
  getUserNotEmployeeAction,
  getUserRoleListAction,
  postUserAction,
  postUserProfileAction,
  putUserProfileAction,
} from '../../../actions/userAction';
import {
  getMasterReferenceAction,
  resetMasterDataReferenceAction,
} from '../../shared/actions/uploadMasterDataReferenceAction';
import { getSupervisorByCompanyCodePositionIdAction } from '../customerProfileAction';
import ModalAlert from '../../../components/modal/modalAlert';
import Loader from '../../../components/loader';
import PropTypes from 'prop-types';
// import ModalConfirm from '../../../components/modal/modalConfirmationTwoButton';
import GeneralInformation from './form/GeneralInformation';
import ContactInformation from './form/ContactInformation';
import StatusEmployee from './form/StatusEmployee';
import ProfilePicture from './components/SegmentImageEmployee';
import HeaderTitle from '../../shared/components/common/headerSegment/HeaderTitle';
import ModalUser from '../../shared/components/modal/user/ModalUser';
import { userValidation } from './services/validation/userValidation';
import { employeeValidation } from './services/validation/employeeValidation';
import setDataEmployee from './services/data/setDataEmpolyee';
import setDataNewEmployee from './services/data/setDataNewEmployee';
import convertOptions from './services/helper/convertOptions';
import { checkUser } from './../../../scripts/authorization';

const FILL = 'Please fill ';
const CHOOSE = 'Please choose ';
const CHECK = 'Please check ';
const numberOnly = /^[+0-9-\b]+$/;

class Employee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      EmployeeObject: {},
      errors: {},
      UserObject: {},
      errorEmpolyee: {
        FirstName: { error: false, message: FILL + 'first name' },
        LastName: { error: false, message: FILL + 'last name' },
        PhoneNbr: { error: false, message: FILL + 'phone number' },
        Email: { error: false, message: CHECK + 'email' },
        Gender: { error: false, message: CHOOSE + 'gender' },
        Address: { error: false, message: FILL + 'address' },
        AccountManagerProfileId: {
          error: false,
          message: CHOOSE + 'account manager',
        },
        PositionId: { error: false, message: CHOOSE + 'position' },
        isError: false,
      },
      errorUser: {
        ConfirmPassword: {
          error: false,
          message: CHECK + ' confirm password',
        },
        Password: { error: false, message: CHECK + 'password' },
        Username: { error: false, message: CHECK + 'Username' },
        Email: { error: false, message: CHECK + 'email' },
        Roles: { error: false, message: CHECK + 'roles' },
        isError: false,
      },
      users: [],
      IsInactive: false,
      openModalAlert: false,
      contentAlert: '',
      disable: false,
      typePassword: 'password',
      passwordIcon: 'eye slash',
      typeConfirmPassword: 'password',
      confirmPasswordIcon: 'eye slash',
      isSales: null,
    };
  }
  static propTypes = {
    getUserNotEmployee: PropTypes.array,
    getUserProfileByUserIdAction: PropTypes.func,
    getAccountManagerAction: PropTypes.func,
    getUserNotEmployeeAction: PropTypes.func,
    match: PropTypes.object,
    getUserProfileByUserIdStatus: PropTypes.string,
    getUserProfileByUserId: PropTypes.array,
    resetUserAccessAction: PropTypes.func,
    loading: PropTypes.bool,
    getMasterReferenceAction: PropTypes.func,
    listPositions: PropTypes.array,
    listSupervisors: PropTypes.array,
    listAccountManager: PropTypes.array,
    postUserProfileStatus: PropTypes.string,
    resetUserInfoAction: PropTypes.func,
    postUserProfile: PropTypes.object,
    errorMessage: PropTypes.object,
    getSupervisorByCompanyCodePositionIdAction: PropTypes.func,
    postUserProfileAction: PropTypes.func,
    putUserProfileAction: PropTypes.func,
    getUserRoleListAction: PropTypes.func,
    getUserRoleList: PropTypes.array,
    putUserProfileStatus: PropTypes.string,
    postUserStatus: PropTypes.string,
    postUserAction: PropTypes.func,
    deleteUserProfileByIdAction: PropTypes.func,
    postUserProfileActivateAction: PropTypes.func,
    getUserRoleByBearerAction: PropTypes.func,
    userRoles: PropTypes.object,
  };

  componentDidMount = () => {
    this.props.getUserRoleByBearerAction().then(() => {
      let authorization = checkUser(this.props.userRoles.Roles);
      this.setState({
        ...this.state,
        isSales: authorization.isSales,
        disable: authorization.isSales,
      });
    });
    this.loadData();
  };

  loadData = () => {
    let { EmployeeId, Type, CompanyId } = this.props.match.params;
    if (Type === 'create') {
      this.handlegetData();
      let EmployeeObject = setDataNewEmployee(CompanyId);
      this.setState({ EmployeeObject });
    } else {
      this.props.getUserProfileByUserIdAction(EmployeeId).then(res => {
        let data = res.value.data;
        let newUser = convertOptions(data.UserName, data.UserId, data.UserId);
        this.setState({
          users: this.state.users.concat(newUser),
        });
      });
      this.handlegetData();
    }
  };
  handlegetData = () => {
    this.props.getAccountManagerAction();
    this.props.getUserNotEmployeeAction().then(res => {
      this.handleSetUser(res.value.data);
    });
    this.props.getMasterReferenceAction('Positions');
  };
  handleSetUser = data => {
    let newUsers = data.map(data => {
      return convertOptions(data.Username, data.UserId, data.UserId);
    });
    this.setState({
      users: [...this.state.users, ...newUsers],
    });
  };
  handleSetUserWhileCreateNew = data => {
    let newUsers = data.map(data => {
      return convertOptions(data.Username, data.UserId, data.UserId);
    });
    this.setState({
      ...this.state,
      users: newUsers,
    });
    let { EmployeeId } = this.props.match.params;
    if (EmployeeId > 0) {
      this.props.getUserProfileByUserIdAction(EmployeeId).then(res => {
        let data = res.value.data;
        let newUser = convertOptions(data.UserName, data.UserId, data.UserId);
        this.setState({
          users: this.state.users.concat(newUser),
        });
      });
    }
  };
  getListSupervisor = async (CompanyId, PositionId) =>
    await this.props.getSupervisorByCompanyCodePositionIdAction(
      CompanyId,
      PositionId
    );

  componentDidUpdate() {
    if (this.props.getUserProfileByUserIdStatus === 'success') {
      let { CompanyId } = this.props.match.params;
      const data = setDataEmployee(
        this.props.getUserProfileByUserId,
        CompanyId
      );
      this.getListSupervisor(CompanyId, data.PositionId);
      this.setState({
        EmployeeObject: data,
      });
      this.props.resetUserAccessAction();
      this.props.resetUserInfoAction();
    } else if (this.props.getUserProfileByUserIdStatus === 'failed') {
      this.setState({
        headerAlert: ' Failed',
        contentAlert: ' The user is Failed Loaded',
        openModalAlert: true,
      });
      this.props.resetUserAccessAction();
      this.props.resetUserInfoAction();
    }

    // if (this.props.postUserProfileStatus === 'success') {
    //   this.setState({
    //     headerAlert: ' Success',
    //     contentAlert: 'Create Employee successfully ',
    //     openModalAlert: true,
    //     openModalEmployee: false,
    //   });
    //   this.props.resetUserInfoAction();
    //   this.setLinkAfterPostEmployee(this.props.postUserProfile.Id);
    // } else if (this.props.postUserProfileStatus === 'failed') {
    //   this.setState({
    //     headerAlert: ' Failed',
    //     contentAlert: this.props.errorMessage,
    //     openModalAlert: true,
    //   });
    //   this.props.resetUserInfoAction();
    // }
    if (this.props.putUserProfileStatus === 'success') {
      this.setState({
        headerAlert: ' Success',
        contentAlert: 'Edit Employee successfully ',
        openModalAlert: true,
        openModalEmployee: false,
      });
      this.props.resetUserInfoAction();
      this.loadData();
    } else if (this.props.putUserProfileStatus === 'failed') {
      this.setState({
        headerAlert: ' Failed',
        contentAlert: this.props.errorMessage,
        openModalAlert: true,
      });
      this.props.resetUserInfoAction();
    }
    if (this.props.postUserStatus === 'success') {
      this.setState({
        headerAlert: ' Success',
        contentAlert: 'Create New User successfully ',
        openModalAlert: true,
        openModalUser: false,
      });
      this.props.getUserNotEmployeeAction().then(res => {
        this.handleSetUserWhileCreateNew(res.value.data);
      });
      this.props.resetUserInfoAction();
    } else if (this.props.postUserStatus === 'failed') {
      this.setState({
        headerAlert: ' Failed',
        contentAlert: this.props.errorMessage,
        openModalAlert: true,
      });
      this.props.resetUserInfoAction(); //
    }
  }

  setLinkAfterPostEmployee = async data => {
    let { CompanyId } = this.props.match.params;
    // eslint-disable-next-line
    await this.props.history.push(`/Employee/Edit/${CompanyId}/${data}`);
    this.loadData();
  };

  handleBack = () => {
    let { CompanyId } = this.props.match.params;
    if (this.state.isSales) {
      // eslint-disable-next-line
      this.props.history.push(`/company-profile-detail/detail/${CompanyId}`);
    } else {
      CompanyId.charAt(0) === '1'
        ? // eslint-disable-next-line
          this.props.history.push(`/company-profile-detail/edit/${CompanyId}`)
        : CompanyId.charAt(0) === '2'
        ? // eslint-disable-next-line
          this.props.history.push(`/Partner-Profile-Detail/detail/${CompanyId}`)
        : // eslint-disable-next-line
          this.props.history.push(`/Customer-Profile-Detail/Edit/${CompanyId}`);
    }
  };

  handleChangeImage = e => {
    let city = this.state.EmployeeObject;
    let reader = new FileReader();
    let files = e.target.files[0];
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      let image = {
        ...city,
        ImageName: files.name,
        ImageContent: reader.result.split(',')[1],
        ImagePreview: reader.result,
        ImageUrl: '',
      };
      this.setState({
        EmployeeObject: image,
      });
    };
  };

  saveEmployee = async () => {
    let { EmployeeObject, errorEmpolyee } = this.state;
    let { CompanyId } = this.props.match.params;
    //const err = this.validateEmployee();
    const errorMessage = employeeValidation(
      EmployeeObject,
      errorEmpolyee,
      CompanyId
    );
    const err = errorMessage.isError;
    if (!err) {
      if (EmployeeObject.Id === '') {
        await this.setState({
          openModalEmployee: false,
        });
        this.props
          .postUserProfileAction(EmployeeObject)
          .then(() => {
            this.setState({
              headerAlert: ' Success',
              contentAlert: 'Create Employee successfully ',
              openModalAlert: true,
              openModalEmployee: false,
            });
            this.props.resetUserInfoAction();
            this.setLinkAfterPostEmployee(this.props.postUserProfile.Id);
          })
          .catch(() => {
            this.setState({
              headerAlert: ' Failed',
              contentAlert: this.props.errorMessage,
              openModalAlert: true,
            });
            this.props.resetUserInfoAction();
          });
      } else {
        await this.setState({
          openModalEmployee: false,
        });
        if (EmployeeObject.IsInactive === true) {
          this.props.deleteUserProfileByIdAction(EmployeeObject.Id);
          this.props.putUserProfileAction(EmployeeObject.Id, EmployeeObject);
        } else if (EmployeeObject.IsInactive === false) {
          this.props.postUserProfileActivateAction(EmployeeObject.Id);
          this.props.putUserProfileAction(EmployeeObject.Id, EmployeeObject);
        }
      }
    } else {
      this.setState({ errorEmpolyee: errorMessage });
    }
  };

  handleChange = (e, { value, name }) => {
    let { EmployeeObject, errorEmpolyee } = this.state;
    let { CompanyId } = this.props.match.params;
    if (name === 'PhoneNbr') {
      if (value === '' || numberOnly.test(value)) {
        EmployeeObject[name] = value;
      }
    } else {
      EmployeeObject[name] = value;
    }

    if (name === 'PositionId') {
      this.props.getSupervisorByCompanyCodePositionIdAction(
        Number(CompanyId),
        EmployeeObject.PositionId
      );
    }

    this.setState({ EmployeeObject: EmployeeObject });
    const errorMessage = employeeValidation(
      EmployeeObject,
      errorEmpolyee,
      CompanyId
    );
    this.setState({ errorEmpolyee: errorMessage });
  };

  createNewUser = () => {
    this.setState({
      openModalUser: true,
      UserObject: {
        Password: '',
        ConfirmPassword: '',
        Username: '',
        Email: '',
        Roles: '',
      },
      EmployeeObject: {
        ...this.state.EmployeeObject,
      },
      errors: {},
    });
    this.props.getUserRoleListAction();
  };

  handleSubmitUser = () => {
    let { CompanyId } = this.props.match.params;
    const errorMessage = userValidation(
      this.state.UserObject,
      this.state.errorUser
    );
    if (!errorMessage.isError) {
      let data = {
        ...this.state.UserObject,
        Roles:
          CompanyId.charAt(0) === '1'
            ? ['Supplier Admin', 'Supplier']
            : CompanyId.charAt(0) === '2'
            ? ['Partner', 'Partner Admin']
            : this.state.UserObject.Roles,
      };
      this.props.postUserAction(data);
    }
    this.setState({ errorUser: errorMessage });
  };
  handleChangeUser = (e, { value, name }) => {
    const UserObject = this.state.UserObject;
    UserObject[name] = value;
    this.setState({ UserObject: UserObject });
    const err = userValidation(this.state.UserObject, this.state.errorUser);
    this.setState({ errorUser: err });
  };

  handleCloseModalUser = () =>
    this.setState({
      openModalUser: false,
    });

  closeModalAlert = () =>
    this.setState({
      openModalAlert: false,
      contentAlert: '',
    });

  handleIsActive = async () => {
    let { EmployeeObject } = this.state;
    await this.setState({
      EmployeeObject: {
        ...EmployeeObject,
        IsInactive: !EmployeeObject.IsInactive,
      },
    });
  };
  onClickPassIcon = (e, { name }) => {
    let icon = `${name}Icon`;
    let show = `${name}Show`;
    if (this.state[show]) {
      this.setState({
        [icon]: 'eye slash',
        [show]: false,
        typePassword: 'password',
      });
    } else {
      this.setState({
        [icon]: 'eye',
        [show]: true,
        typePassword: 'text',
      });
    }
  };
  onClickConfirmPassIcon = (e, { name }) => {
    let icon = `${name}Icon`;
    let show = `${name}Show`;
    if (this.state[show]) {
      this.setState({
        [icon]: 'eye slash',
        [show]: false,
        typeConfirmPassword: 'password',
      });
    } else {
      this.setState({
        [icon]: 'eye',
        [show]: true,
        typeConfirmPassword: 'text',
      });
    }
  };
  render() {
    const {
      EmployeeObject,
      openModalAlert,
      headerAlert,
      contentAlert,
      users,
      passwordIcon,
      typePassword,
      confirmPasswordIcon,
      typeConfirmPassword,
      disable,
      isSales,
    } = this.state;
    const {
      // getUserNotEmployee,
      loading,
      listPositions,
      listSupervisors,
      listAccountManager,
    } = this.props;
    let { CompanyId } = this.props.match.params;
    return (
      <div>
        <Dimmer.Dimmable blurring dimmed={loading}>
          <Loader loading={loading} />
          <Grid>
            <Grid.Column width={15}>
              <Segment style={{ marginTop: '3em' }}>
                <HeaderTitle
                  iconName="user"
                  title={
                    CompanyId.charAt(0) === '1'
                      ? 'Supplier Employee Detail'
                      : 'Customer Employee Detail'
                  }
                />
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      {!isSales && (
                        <Button
                          floated="right"
                          content="Save"
                          primary
                          onClick={this.saveEmployee}
                        />
                      )}
                      <Button
                        negative
                        floated="right"
                        content="Back"
                        primary
                        onClick={this.handleBack}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid columns={2} padded="vertically">
                      <Grid.Column>
                        <GeneralInformation
                          contentDetail={EmployeeObject}
                          listUsername={users}
                          listPositions={listPositions}
                          listAccountManager={listAccountManager}
                          handleNewUsername={this.createNewUser}
                          errorInformation={this.state.errorEmpolyee}
                          handleChange={this.handleChange}
                          disable={disable}
                          companyId={CompanyId}
                        />
                        <ContactInformation
                          contentDetail={EmployeeObject}
                          errorInformation={this.state.errorEmpolyee}
                          handleChange={this.handleChange}
                          disable={disable}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <StatusEmployee
                          contentDetail={EmployeeObject}
                          handleChange={this.handleChange}
                          handleIsActive={this.handleIsActive}
                          listSupervisors={listSupervisors}
                          companyId={CompanyId}
                          disable={disable}
                        />
                        <ProfilePicture
                          contentDetail={EmployeeObject}
                          handleChangeImage={this.handleChangeImage}
                          imagePreview={EmployeeObject.ImagePreview}
                          disable={disable}
                        />
                      </Grid.Column>
                    </Grid>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Column>
            <ModalUser
              openModal={this.state.openModalUser}
              header="Add User"
              handleClose={this.handleCloseModalUser}
              nameButton="Save"
              handleChange={this.handleChangeUser}
              errorInformation={this.state.errorUser}
              handleSubmit={this.handleSubmitUser}
              listUserRole={this.props.getUserRoleList}
              loading={loading}
              onClickPassIcon={this.onClickPassIcon}
              onClickConfirmPassIcon={this.onClickConfirmPassIcon}
              passwordIcon={passwordIcon}
              typePassword={typePassword}
              confirmPasswordIcon={confirmPasswordIcon}
              typeConfirmPassword={typeConfirmPassword}
              isShow={
                CompanyId.charAt(0) === '1' || CompanyId.charAt(0) === '2'
              }
            />
          </Grid>
          <ModalAlert
            openModal={openModalAlert}
            handleClose={this.closeModalAlert}
            header={headerAlert}
            content={contentAlert}
            //   nameButton: PropTypes.string,
          />
        </Dimmer.Dimmable>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    getUserProfileByUserIdStatus:
      state.UserAccessStore.getUserProfileByUserIdStatus,
    getUserProfileByUserId: state.UserAccessStore.getUserProfileByUserId,
    postUserProfileStatus: state.UserStore.postUserProfileStatus,
    postUserProfile: state.UserStore.postUserProfile,
    putUserProfile: state.UserStore.putUserProfile,
    putUserProfileStatus: state.UserStore.putUserProfileStatus,
    postUserStatus: state.UserStore.postUserStatus,
    postUser: state.UserStore.postUser,
    loading: state.UserStore.loading || state.UserAccessStore.loading,
    errorMessage:
      state.UserStore.errors.global === undefined
        ? state.UserAccessStore.errors.global
        : state.UserStore.errors.global,
    getUserNotEmployee: state.UserStore.getUserNotEmployee.map(data => {
      return convertOptions(data.Username, data.UserId, data.UserId);
    }),
    listSupervisors: state.CustomerProfilePageStore.getSupervisorByCompanyCodePositionId.map(
      data => {
        return convertOptions(data.Name, data.Id, data.Id);
      }
    ),
    listPositions: state.MasterDataReferenceStore.getMasterReferenceList.map(
      data => {
        return convertOptions(data.Name, data.Id, data.Level);
      }
    ),
    listAccountManager: state.CustomerProfileStore.getAccountManager.map(
      type => {
        return convertOptions(
          type.FirstName + ' ' + type.LastName,
          type.Id,
          type.Id
        );
      }
    ),
    getUserRoleList: state.UserStore.getUserRoleList.map(type => {
      return convertOptions(type, type, type);
    }),
    userRoles: state.UserAccessStore.getUserRoleByBearer,
  };
}
export default connect(mapStateToProps, {
  resetUserAccessAction,
  getUserProfileByUserIdAction,
  resetCustomerProfileAction,
  getAccountManagerAction,
  resetUserInfoAction,
  getUserNotEmployeeAction,
  getMasterReferenceAction,
  getSupervisorByCompanyCodePositionIdAction,
  resetMasterDataReferenceAction,
  getUserRoleListAction,
  postUserAction,
  postUserProfileAction,
  putUserProfileAction,
  deleteUserProfileByIdAction,
  postUserProfileActivateAction,
  getUserRoleByBearerAction,
})(Employee);
