import React, { useState, useEffect, useRef } from 'react';
import { Input, Grid, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const useOutsideAlerter = (ref, setFunc, selectedData, setInput) => {
  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setFunc(false);
      if (selectedData) {
        setInput(`${selectedData.City.Name} (${selectedData.Code})`);
      }
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};
const CustomSelect = props => {
  const [airport, setAirport] = useState(null);
  const [focus, setFocus] = useState(false);
  const [data, setData] = useState(airport);
  // eslint-disable-next-line
  const [selectedData, setSelectedData] = useState(null);
  const [inputSearch, setInputSearch] = useState('');
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setFocus, selectedData, setInputSearch);

  //=======lifecycle======
  //component did mount
  useEffect(() => {
    setData(props.airport);
    setAirport(props.airport);
    // eslint-disable-next-line
  }, []);
  //component did update
  // eslint-disable-next-line
  useEffect(() => {
    setAirport(props.airport);
    if (props.value) {
      if (!selectedData || (selectedData && selectedData.Id !== props.value)) {
        let newSelected = props.airport.all.find(
          item => item.completeObj.Id === props.value
        );
        if (newSelected) {
          setSelectedData(newSelected.completeObj);
          setInputSearch(
            `${newSelected.completeObj.City.Name} (${newSelected.completeObj.Code})`
          );
        }
      }
    }
  });
  //=====================
  const enablefield = () => {
    focusField();
    let brackets = '(';
    if (!inputSearch || inputSearch.indexOf(brackets)) {
      let newData = { ...airport };
      newData.all = newData.popular.length > 0 ? [] : newData.all;
      setData(newData);
    }
  };
  const newSearch = (e, { value }) => {
    let newData = {
      popular: searchFilter(airport.popular, value),
      all: searchFilter(airport.all, value),
    };
    setData(newData);
    setInputSearch(value);
  };
  const searchFilter = (data, value) => {
    let newData = data.filter(item => {
      let val = value.toLowerCase();
      return (
        item.completeObj.Code.toLowerCase().indexOf(val) !== -1 ||
        item.completeObj.City.Name.toLowerCase().indexOf(val) !== -1 ||
        item.completeObj.Country.Name.toLowerCase().indexOf(val) !== -1 ||
        item.completeObj.Name.toLowerCase().indexOf(val) !== -1
      );
    });
    return newData;
  };
  const handleSelectAirport = (e, { value }) => {
    setSelectedData(value);
    setInputSearch(`${value.City.Name} (${value.Code})`);
    setFocus(false);
    props.handleSelect(value.Id, props.name);
  };

  const focusField = () => {
    if (!focus) {
      setFocus(true);
    }
  };
  return (
    <div>
      <Input
        onFocus={enablefield}
        onChange={newSearch}
        value={inputSearch}
        onSelect={focusField}
        onClick={focusField}
        style={props.styles ? props.styles : {}}
        placeholder="Search by City, Airport Code or Country"
      />
      {focus && (
        <div ref={wrapperRef}>
          <Grid
            style={{
              top: 'auto',
              borderRadius: '2px',
              border: '1px solid rgba(67,67,67,.17)',
              boxShadow: '0 2px 4px 1px rgba(0,0,0,.14)',
              marginTop: '2px',
              marginLeft: '0px',
              position: 'absolute',
              zIndex: '15',
              backgroundColor: 'white',
              width: props.widthDropdown ? props.widthDropdown : '350px',
              maxHeight: '300px',
              overflow: 'scroll',
              overflowX: 'hidden',
            }}
          >
            {/* ==================== DATA POPULER ======================= */}
            {data.popular && data.popular.length > 0 && (
              <Grid.Row
                columns="1"
                style={{
                  margin: '0px',
                  padding: '10px 0px 5px 0px',
                  background: 'rgb(220, 220, 220)',
                }}
              >
                <Grid.Column>
                  <b>Popular</b>
                </Grid.Column>
              </Grid.Row>
            )}
            {data.popular &&
              data.popular.length > 0 &&
              data.popular.map((item, i) => (
                <Button
                  key={i}
                  style={{
                    backgroundColor: 'white',
                    borderTop:
                      i !== 0 ? '1px solid rgba(67,67,67,.17)' : 'none',
                    width: '100%',
                    textAlign: 'left',
                    paddingLeft: '10px',
                    margin: '0px 5px 0px 5px',
                  }}
                  value={item.completeObj}
                  onClick={handleSelectAirport}
                >
                  <Grid.Row columns="1">
                    <Grid.Column>{item.content}</Grid.Column>
                  </Grid.Row>
                </Button>
              ))}
            {/* ==================== END DATA POPULER ======================= */}
            {/* ==================== ALL DATA ======================= */}
            {data.all && data.all.length > 0 && (
              <Grid.Row
                columns="1"
                style={{
                  margin: '0px',
                  padding: '10px 0px 5px 0px',
                  background: 'rgb(220, 220, 220)',
                }}
              >
                <Grid.Column>
                  <b>Recommended</b>
                </Grid.Column>
              </Grid.Row>
            )}
            {data.all &&
              data.all.length > 0 &&
              data.all.map((item, i) => (
                <Button
                  key={i}
                  style={{
                    backgroundColor: 'white',
                    borderTop:
                      i !== 0 ? '1px solid rgba(67,67,67,.17)' : 'none',
                    width: '100%',
                    textAlign: 'left',
                    paddingLeft: '10px',
                    margin: '0px 5px 0px 5px',
                  }}
                  value={item.completeObj}
                  onClick={handleSelectAirport}
                >
                  <Grid.Row columns="1">
                    <Grid.Column>{item.content}</Grid.Column>
                  </Grid.Row>
                </Button>
              ))}
            {data.all &&
              data.all.length < 1 &&
              data.popular &&
              data.popular.length < 1 && (
                <Grid.Row
                  columns="1"
                  style={{
                    borderTop: '1px solid rgba(67,67,67,.17)',
                  }}
                >
                  {' '}
                  <Grid.Column>
                    <p>No result found</p>
                  </Grid.Column>
                </Grid.Row>
              )}
            {/* ==================== END ALL DATA ======================= */}
          </Grid>
        </div>
      )}
    </div>
  );
};

CustomSelect.propTypes = {
  airport: PropTypes.object,
  handleSelect: PropTypes.func,
  value: PropTypes.number,
  styles: PropTypes.object,
  widthDropdown: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
export default CustomSelect;
