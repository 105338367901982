import React from 'react';
import { Dimmer } from 'semantic-ui-react';
import { connect } from 'react-redux';
import Loader from '../../components/loader';
import UserAccess from './component/UserList';
import ModalResetPassword from '../shared/components/modal/user/ModalResetPassword';
import ModalAlert from '../shared/components/modal/ModalAlert';
import PropTypes from 'prop-types';
import {
  getUserAccessListAction,
  resetUserAccessAction,
  postUnlockUserAction,
  postResetPasswordAction,
  // getUserProfileByUserIdAction,
  getUserProfileOnlyByIdAction,
} from '../../actions/userAccessAction';
import {
  resetUserInfoAction,
  postUserAction,
  getUserRoleListAction,
  getUserRoleByUserIdAction,
  putRoleByUserIdAction,
} from '../../actions/userAction';
import ModalConfirm from '../../components/modal/modalConfirmationTwoButton';
import ModalUserRole from '../shared/components/modal/user/ModalUserRole';
import ModalUser from '../shared/components/modal/user/ModalUser';
import { getCountryListAction } from '../shared/actions/countryAction';
import handleValidation from './services/validation/validationResetPassword';
import { resetPasswordField } from './constant/fieldToValidate';
import _ from 'lodash';
class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userAccessList: [],
      lockedUserAccess: [],
      loadingUserAccess: true,
      failedUserAccess: false,
      loadingLockedAccess: true,
      failedLockedAccess: false,
      modalOpen: false,
      openModalResetPassword: false,
      openModalUserRole: false,
      modalConfirmation: false,
      userRole: {
        Roles: [],
        RolesClone: [],
      },
      resetPasswordObject: {},
      errors: {},
      loadingModalUserRole: true,
      loadingPostUser: false,
      UserObject: {
        Password: '',
        ConfirmPassword: '',
        Username: '',
        Email: '',
        Roles: '',
      },
      typePassword: 'password',
      passwordIcon: 'eye slash',
      typeConfirmPassword: 'password',
      confirmPasswordIcon: 'eye slash',
    };
  }

  static propTypes = {
    getUserAccessListAction: PropTypes.func,
    resetUserAccessAction: PropTypes.func,
    postUnlockUserAction: PropTypes.func,
    postResetPasswordAction: PropTypes.func,
    getUserProfileOnlyByIdAction: PropTypes.func,
    listUserAccess: PropTypes.array,
    listLockedUser: PropTypes.array,
    statusLockedUser: PropTypes.string,
    statusUserAccess: PropTypes.string,
    getUserProfileOnlyByIdStatus: PropTypes.string,
    getUserProfileOnlyById: PropTypes.array,
    postUnlockedUserStatus: PropTypes.string,
    postResetPasswordStatus: PropTypes.string,
    getUserRoleListAction: PropTypes.func,
    getCountryListAction: PropTypes.func,
    postUserStatus: PropTypes.string,
    resetUserInfoAction: PropTypes.func,
    getUserRoleByUserIdStatus: PropTypes.string,
    getUserRoleByUserId: PropTypes.array,
    putRoleByUserIdStatus: PropTypes.string,
    putRoleByUserIdAction: PropTypes.func,
    getUserRoleByUserIdAction: PropTypes.func,
    listCountry: PropTypes.array,
    getUserRoleList: PropTypes.array,
    postUserAction: PropTypes.func,
    errors: PropTypes.object,
  };
  handleClose = () =>
    this.setState({
      modalOpen: false,
      openModalResetPassword: false,
      modalConfirmation: false,
      openConfirm: false,
      openModalUserRole: false,
      loadingModalUserRole: true,
      openModalUser: false,
      failedUserAccess: false,
    });
  componentDidMount = () => {
    this.props.getUserAccessListAction();
    this.props.getUserRoleListAction();
    this.props.getCountryListAction();
  };
  componentDidUpdate = () => {
    if (this.props.statusUserAccess === 'success') {
      this.setState({
        userAccessList: this.props.listUserAccess,
        loadingUserAccess: false,
      });
      this.props.resetUserAccessAction();
    } else if (this.props.statusUserAccess === 'failed') {
      this.setState({
        failedUserAccess: true,
        headerAlert: ' Failed',
        contentAlert: this.props.errors.global,
        modalConfirmation: true,
        loadingUserAccess: false,
      });
      this.props.resetUserAccessAction();
    }
    if (this.props.getUserProfileOnlyByIdStatus === 'success') {
      this.setState({
        resetPasswordObject: {
          ...this.state.resetPasswordObject,
          NewPassword: '',
          ConfirmPassword: '',
        },
      });
      this.props.resetUserAccessAction();
    } else if (this.props.getUserProfileOnlyByIdStatus === 'failed') {
      this.setState({
        headerAlert: ' Failed',
        contentAlert: ' user failed to load',
        modalConfirmation: true,
      });
      this.props.resetUserAccessAction();
    }
    if (this.props.postUnlockedUserStatus === 'success') {
      this.setState({
        headerAlert: ' Success',
        contentAlert: 'The user is successfully unlocked',
        modalConfirmation: true,
      });
      this.props.getUserAccessListAction();
      this.props.resetUserAccessAction();
    } else if (this.props.postUnlockedUserStatus === 'failed') {
      this.setState({
        headerAlert: ' Failed',
        contentAlert: ' The user is failed unlocked',
        modalConfirmation: true,
      });
      this.props.resetUserAccessAction();
    }
    if (this.props.postResetPasswordStatus === 'success') {
      this.setState({
        headerAlert: ' Success',
        contentAlert: 'The user is successfully reset password',
        modalConfirmation: true,
      });
      this.props.getUserAccessListAction();
      this.props.resetUserAccessAction();
    } else if (this.props.postResetPasswordStatus === 'failed') {
      this.setState({
        headerAlert: ' Failed',
        contentAlert: ' The user is failed reset password',
        modalConfirmation: true,
      });
      this.props.resetUserAccessAction();
    }
    //======================================================
    if (this.props.postUserStatus === 'success') {
      this.setState({
        headerAlert: ' Success',
        contentAlert: 'New user successfully created',
        modalConfirmation: true,
        loadingUserRole: true,
        loadingPostUser: false,
      });
      this.props.getUserAccessListAction();
      this.props.resetUserInfoAction();
    } else if (this.props.postUserStatus === 'failed') {
      this.setState({
        headerAlert: ' Failed',
        contentAlert: ' New user failed to create',
        modalConfirmation: true,
      });
      this.props.resetUserInfoAction();
    }
    if (this.props.getUserProfileOnlyByIdStatus === 'success') {
      this.setState({
        loadingModalUserRole: false,
        userRole: {
          ...this.state.userRole,
          // FirstName: this.props.getUserProfileOnlyById.FirstName,
          UserName: this.props.getUserProfileOnlyById[0].Username,
          Address: this.props.getUserProfileOnlyById[0].Address,
          Email: this.props.getUserProfileOnlyById[0].Email,
          // LastName: this.props.getUserProfileOnlyById.LastName,
        },
      });
      this.props.resetUserAccessAction();
    } else if (this.props.getUserProfileOnlyByIdStatus === 'failed') {
      this.setState({
        headerAlert: ' Failed',
        contentAlert: ' user failed to load',
        modalConfirmation: true,
        loadingModalUserRole: false,
      });
      this.props.resetUserAccessAction();
    }
    if (this.props.getUserRoleByUserIdStatus === 'success') {
      this.setState({
        loadingModalUserRole: false,
        userRole: {
          ...this.state.userRole,
          UserId: this.props.getUserRoleByUserId.UserId,
          Roles: this.props.getUserRoleByUserId.Roles,
          RolesClone: this.props.getUserRoleByUserId.Roles,
        },
      });
      this.props.resetUserInfoAction();
    } else if (this.props.getUserRoleByUserIdStatus === 'failed') {
      this.setState({
        headerAlert: ' Failed',
        contentAlert: ' user failed to load',
        modalConfirmation: true,
        loadingModalUserRole: false,
      });
      this.props.resetUserInfoAction();
    }
    if (this.props.putRoleByUserIdStatus === 'success') {
      this.setState({
        headerAlert: ' Success',
        contentAlert: 'Edit Role successfully',
        modalConfirmation: true,
        loadingUserRole: true,
      });
      this.props.getUserAccessListAction();
      this.props.resetUserInfoAction();
    } else if (this.props.putRoleByUserIdStatus === 'failed') {
      this.setState({
        headerAlert: ' Failed',
        contentAlert: ' Edit Role failed',
        modalConfirmation: true,
      });
      this.props.resetUserInfoAction();
    }
  };
  unlockUser = (e, { id }) => {
    this.setState({
      openConfirm: true,
      messageConfirm: ' Are you sure to unlock this user ?',
      userId: id,
    });
  };
  confirmAction = () => {
    const { userId } = this.state;
    this.props.postUnlockUserAction(userId);
  };
  resetPassword = (e, { id, value, name }) => {
    this.props.getUserProfileOnlyByIdAction(id);
    this.setState({
      openModalResetPassword: true,
      resetPasswordObject: {
        Username: name,
        CompanyCode: value,
        UserId: id,
        NewPassword: '',
        ConfirmPassword: '',
      },
    });
  };
  handleChangeResetPassword = (e, { value, name }) => {
    const resetPasswordObject = this.state.resetPasswordObject;
    resetPasswordObject[name] = value;
    this.setState({ resetPasswordObject: resetPasswordObject });
    this.validate();
  };
  saveResetPassword = () => {
    const err = this.validate();
    let data = this.state.resetPasswordObject;
    if (!err) {
      this.props.postResetPasswordAction(data);
      this.setState({ openModalResetPassword: false, loadingUserAccess: true });
    }
  };
  validate = () => {
    let isError = false;
    const { resetPasswordObject } = this.state;
    let error1 = handleValidation(resetPasswordObject, resetPasswordField);
    let errors = { ...error1 };
    isError = !_.isEmpty(errors);
    this.setState({
      errors: {
        ...errors,
      },
    });
    return isError;
  };

  handleChangeEmployee = (e, { value, name }) => {
    const userRole = this.state.userRole;
    userRole[name] = value;
    this.setState({ userRole: userRole });
  };
  handleSubmitEmployee = () => {
    let { userRole } = this.state;
    let UserId = userRole.UserId;
    this.props.putRoleByUserIdAction(UserId, userRole.Roles);
    this.setState({ loadingModalUserRole: true, loadingUserAccess: true });
  };
  editUserRole = (e, { id }) => {
    this.props.getUserProfileOnlyByIdAction(id);
    this.props.getUserRoleByUserIdAction(id);
    this.setState({
      openModalUserRole: true,
      headerModalUser: 'Edit User Role',
      userRole: {},
    });
  };

  //===============================================================================
  buttonAddUser = () => {
    this.setState({
      openModalUser: true,
      EmployeeObject: {
        ...this.state.EmployeeObject,
      },
      errors: {},
    });
  };
  handleSubmitUser = () => {
    const err = this.validateUser();
    if (!err) {
      this.setState(
        {
          loadingPostUser: true,
        },
        () => {
          let data = {
            ...this.state.UserObject,
            Roles: [this.state.UserObject.Roles],
          };
          this.props.postUserAction(data);
        }
      );
    }
  };
  handleChangeUser = (e, { value, name }) => {
    const UserObject = this.state.UserObject;
    UserObject[name] = value;
    this.setState({ UserObject: UserObject }, () => this.validateUser());
  };
  validateUser = () => {
    let isError = false;
    const { Password, Email } = this.state.UserObject;
    const errors = {
      ConfirmPasswordUser: '',
      Password: '',
      Username: '',
      EmailUser: '',
      EmailEmployee: '',
      Roles: '',
    };
    //Modal User
    if (this.state.UserObject.Username.length < 1) {
      isError = true;
      errors.Username = "Username Can't Be Empty";
    }
    if (Password.length < 1) {
      isError = true;
      errors.Password = "Password Can't Be Empty";
    } else if (Password.length >= 1 && !this.validationPassword(Password)) {
      isError = true;
      errors.Password = 'Password Invalid';
    }
    if (
      this.state.UserObject.ConfirmPassword !== this.state.UserObject.Password
    ) {
      isError = true;
      errors.ConfirmPasswordUser = 'Password does not match';
    }
    if (Email.length < 1) {
      isError = true;
      errors.EmailUser = "Email Can't Be Empty";
    } else if (Email.length >= 1 && !this.validationEmail(Email)) {
      isError = true;
      errors.EmailUser = 'Email Invalid';
    }
    if (this.state.UserObject.Roles.length < 1) {
      isError = true;
      errors.Roles = "Roles Can't Be Empty";
    }

    this.setState({
      errors: {
        ...errors,
      },
    });
    return isError;
  };
  validationEmail = email => {
    return email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
  };
  validationPassword = password => {
    return password.match(
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9~`)({}+!#$%^&*@_]{8,})$/
    );
  };

  onClickPassIcon = (e, { name }) => {
    let icon = `${name}Icon`;
    let show = `${name}Show`;
    if (this.state[show]) {
      this.setState({
        [icon]: 'eye slash',
        [show]: false,
        typePassword: 'password',
      });
    } else {
      this.setState({
        [icon]: 'eye',
        [show]: true,
        typePassword: 'text',
      });
    }
  };
  onClickConfirmPassIcon = (e, { name }) => {
    let icon = `${name}Icon`;
    let show = `${name}Show`;
    if (this.state[show]) {
      this.setState({
        [icon]: 'eye slash',
        [show]: false,
        typeConfirmPassword: 'password',
      });
    } else {
      this.setState({
        [icon]: 'eye',
        [show]: true,
        typeConfirmPassword: 'text',
      });
    }
  };

  render() {
    let {
      loadingUserAccess,
      failedLockedAccess,
      failedUserAccess,
      modalConfirmation,
      loadingModalUserRole,
      loadingPostUser,
      passwordIcon,
      typePassword,
      confirmPasswordIcon,
      typeConfirmPassword,
    } = this.state;
    let loading = loadingUserAccess;
    let openModal = failedLockedAccess || failedUserAccess || modalConfirmation;
    return (
      <div>
        <Dimmer.Dimmable blurring dimmed={loading}>
          <Loader loading={loading} />
          <UserAccess
            data={this.state.userAccessList}
            type="userrAccess"
            filterBy="UserName"
            unlockUser={this.unlockUser}
            resetPassword={this.resetPassword}
            buttonEdit={this.editUserRole}
            buttonNew={this.buttonAddUser}
          />
          <ModalResetPassword
            openModal={this.state.openModalResetPassword}
            header="Reset Password"
            handleClose={this.handleClose}
            nameButton="Oke"
            contentDetail={this.state.resetPasswordObject}
            handleChange={this.handleChangeResetPassword}
            saveResetPassword={this.saveResetPassword}
            errors={this.state.errors}
            onClickPassIcon={this.onClickPassIcon}
            onClickConfirmPassIcon={this.onClickConfirmPassIcon}
            passwordIcon={passwordIcon}
            typePassword={typePassword}
            confirmPasswordIcon={confirmPasswordIcon}
            typeConfirmPassword={typeConfirmPassword}
          />
          <ModalUserRole
            openModal={this.state.openModalUserRole}
            header={this.state.headerModalUser}
            handleClose={this.handleClose}
            nameButton1="Cancel"
            nameButton2="Save"
            handleChange={this.handleChangeEmployee}
            listCountry={this.props.listCountry}
            listUserRole={this.props.getUserRoleList}
            handleSubmit={this.handleSubmitEmployee}
            contentDetail={this.state.userRole}
            loading={loadingModalUserRole}
            isUserList={true}
          />
          <ModalUser
            openModal={this.state.openModalUser}
            header="Add User"
            handleClose={this.handleClose}
            nameButton="Save"
            handleChange={this.handleChangeUser}
            errors={this.state.errors}
            handleSubmit={this.handleSubmitUser}
            listUserRole={this.props.getUserRoleList}
            loading={loadingPostUser}
            onClickPassIcon={this.onClickPassIcon}
            onClickConfirmPassIcon={this.onClickConfirmPassIcon}
            passwordIcon={passwordIcon}
            typePassword={typePassword}
            confirmPasswordIcon={confirmPasswordIcon}
            typeConfirmPassword={typeConfirmPassword}
          />
          <ModalAlert
            openModal={openModal}
            handleClose={this.handleClose}
            header={this.state.headerAlert}
            content={this.state.contentAlert}
            nameButton="OK"
          />
          <ModalConfirm
            openModal={this.state.openConfirm}
            size="mini"
            close={this.handleClose}
            confirm={this.confirmAction}
            message={this.state.messageConfirm}
          />
        </Dimmer.Dimmable>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    listUserAccess: state.UserAccessStore.getUserAccessList,
    listLockedUser: state.UserAccessStore.getLockedUserList,
    getUserProfileByUserId: state.UserAccessStore.getUserProfileByUserId,
    statusUserAccess: state.UserAccessStore.getUserAccessListStatus,
    statusLockedUser: state.UserAccessStore.getLockedUserListStatus,
    getUserProfileByUserIdStatus:
      state.UserAccessStore.getUserProfileByUserIdStatus,
    postUnlockedUserStatus: state.UserAccessStore.postUnlockedUserStatus,
    postResetPassword: state.UserAccessStore.postResetPassword,
    postResetPasswordStatus: state.UserAccessStore.postResetPasswordStatus,
    getUserListInTableStatus: state.UserStore.getUserListInTableStatus,
    getUserListInTable: state.UserStore.getUserListInTable,
    postUserStatus: state.UserStore.postUserStatus,
    postUser: state.UserStore.postUser,
    getUserRoleListStatus: state.UserStore.getUserRoleListStatus,
    getUserRoleList: state.UserStore.getUserRoleList.map(type => {
      return {
        text: type,
        value: type,
        key: type,
      };
    }),
    listCountry: state.CountryStore.getCountryList.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    getUserRoleByUserId: state.UserStore.getUserRoleByUserId,
    getUserRoleByUserIdStatus: state.UserStore.getUserRoleByUserIdStatus,
    putRoleByUserIdStatus: state.UserStore.putRoleByUserIdStatus,
    putRoleByUserId: state.UserStore.putRoleByUserId,
    errors: state.UserStore.errors,
    getUserProfileOnlyByIdStatus:
      state.UserAccessStore.getUserProfileOnlyByIdStatus,
    getUserProfileOnlyById: state.UserAccessStore.getUserProfileOnlyById,
  };
}
export default connect(
  mapStateToProps,
  {
    getUserAccessListAction,
    resetUserAccessAction,
    postUnlockUserAction,
    postResetPasswordAction,
    getUserProfileOnlyByIdAction,
    resetUserInfoAction,
    postUserAction,
    getUserRoleListAction,
    getUserRoleByUserIdAction,
    putRoleByUserIdAction,
    getCountryListAction,
  }
)(UserList);
