import React from 'react';
import { Table, Button, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Pagination from '../pagination/Pagination';
import { headerTablePendingConfirmation } from '../../constant/headerTable';

const PendingConfirmationTable = props => {
  const {
    activePage,
    data,
    pagedData,
    listData,
    numbersFrom,
    numbersTo,
    pageChange,
    handleOpenConfirm,
  } = props;

  return (
    <React.Fragment>
      <Table basic="very" selectable>
        <Table.Header>
          <Table.Row>
            {headerTablePendingConfirmation.map((headTableContent, index) => {
              return (
                <Table.HeaderCell
                  textAlign={headTableContent === 'Action' ? 'center' : ''}
                  key={index}
                >
                  {headTableContent}
                </Table.HeaderCell>
              );
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {listData ? (
            listData.map((item, idx) => {
              return (
                <Table.Row key={idx}>
                  <Table.Cell>
                    <Link
                      to={`/request-room-history/detail/${item.RequestItemId}`}
                    >
                      {item.RequestItemId}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    {item.AccommodationName ? item.AccommodationName : '-'}
                  </Table.Cell>
                  <Table.Cell>{item.Text ? item.Text : '-'}</Table.Cell>
                  <Table.Cell>{item.CheckInDate}</Table.Cell>
                  <Table.Cell>{item.CheckOutDate}</Table.Cell>
                  <Table.Cell textAlign="center">
                    <Grid columns="equal">
                      <Grid.Column>
                        <Button
                          name={item.Text}
                          id={item.RequestItemId}
                          content={item.Qty}
                          onClick={handleOpenConfirm}
                          primary
                        >
                          Input Confirmation No.
                        </Button>
                      </Grid.Column>
                    </Grid>
                  </Table.Cell>
                </Table.Row>
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell colSpan="6" textAlign="center">
                No Data
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
        <Table.Row>
          <Table.HeaderCell colSpan="6">
            <Pagination
              pagedData={pagedData}
              numbersTo={numbersTo}
              numbersFrom={numbersFrom}
              data={data}
              pageChange={pageChange}
              activePage={activePage}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table>
    </React.Fragment>
  );
};
PendingConfirmationTable.propTypes = {
  activePage: PropTypes.number,
  pagedData: PropTypes.array,
  listData: PropTypes.array,
  numbersFrom: PropTypes.number,
  numbersTo: PropTypes.number,
  pageChange: PropTypes.func,
  data: PropTypes.array,
  handleClose: PropTypes.func,
  handleOpenConfirm: PropTypes.func,
};
export default React.memo(PendingConfirmationTable);
