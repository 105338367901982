import React from 'react';
import ResourceBookingList from './resourceBookingList/ResourceBookingList';
import ResourceBookingFilter from './resourceBookingSummary/ResourceBookingFilter';

const ResourceBooking = () => {
  return (
    <>
      <ResourceBookingList />
      <ResourceBookingFilter />
    </>
  );
};

export default ResourceBooking;
