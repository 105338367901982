import CurrencyFormat from 'react-currency-format';
import React from 'react';
import PropTypes from 'prop-types';
import { Input, Container } from 'semantic-ui-react';
import { StylesTextfieldWithError } from '../common/styleCommon';

class inputCurrencyFormat extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
    };
  }
  handleChange = e => {
    this.props.handelChangePrice(
      Number(e.value),
      this.props.name,
      this.props.index
    );
  };
  render() {
    return (
      <div>
        <label style={this.props.labelStyle}>{this.props.label}</label>
        <CurrencyFormat
          // thousandSeparator={true}
          thousandSeparator={'.'}
          decimalSeparator={','}
          decimalScale={2}
          placeholder={0}
          customInput={Input}
          value={this.props.value}
          disabled={this.props.disabled}
          name={this.props.name}
          onValueChange={this.handleChange}
          allowNegative={false}
          index={this.props.index}
        />
        <Container
          fluid
          content={this.props.errors}
          style={StylesTextfieldWithError.Red}
        />
      </div>
    );
  }
}
inputCurrencyFormat.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  labelStyle: PropTypes.string,
  name: PropTypes.string,
  handelChangePrice: PropTypes.func,
  disabled: PropTypes.bool,
  errors: PropTypes.string,
  index: PropTypes.number,
};
export default inputCurrencyFormat;
