import { GET_PACKAGE_TRANSACTION_HISTORY } from './actionTypes';
import { getPackageTransactionHistoryApi } from '../api/packageTransactionHistoryApi';

const getPackageTransactionHistory = id => {
  return dispatch => {
    return dispatch({
      type: GET_PACKAGE_TRANSACTION_HISTORY,
      payload: getPackageTransactionHistoryApi(id),
    });
  };
};

export default getPackageTransactionHistory;
