import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dimmer } from 'semantic-ui-react';
import Loader from '../../components/loader';
import ModalAlert from '../../components/modal/modalAlert';
import MasterImageList from './components/MasterImageList';
import {
  getAccomodationListAction,
  getAccomodationByProfiledIdAction,
  resetAccomodationAction,
  postUploadImageAccomodationProfileAction,
  getAccomodationItemByServiceTypeIdAction,
  getAccomodationItemAllAction,
  postUploadImageAccomodationItemAction,
  postSetPrimaryImageAccomodationItemAction,
  postSetPrimaryImageAccomodationProfileAction,
  getAccomodationItemByProfiledIdAction,
} from '../../pages/shared/actions/accommodationAction';
// } from '../../actions/accomodationAction';
import {
  getExcursionListAction,
  getExcursionAction,
  resetExcursionAction,
  postUploadImageExcursionObjectAction,
  postSetPrimaryExcursionObjectAction,
} from '../shared/actions/excursionAction';
import {
  getRestaurantListAction,
  getRestaurantByProfileIdAction,
  resetRestaurantAction,
  getRestaurantItemAllAction,
  getRestaurantItemByProfileIdAction,
  getRestaurantItemByIdAction,
  postUploadImageRestaurantItemAction,
  postUploadImageRestaurantProfileAction,
  postSetPrimaryImageRestaurantItemAction,
  postSetPrimaryImageRestaurantProfileAction,
} from '../../actions/restaurantAction';
import {
  getTransportationListAction,
  getTransportationProfileIdAction,
  resetTransportationAction,
  postUploadImageTransportationProfileAction,
  postUploadImageTransportationUnitAction,
  getTransportationUnitAllAction,
  getTransportationUnitByServiceItemIdAction,
  postSetPrimaryImageTransportaionUnitHeaderAction,
  postSetPrimaryImageTransportationProfileAction,
  getTransportationUnitHeaderByIdAction,
} from '../shared/actions/transportationAction';
import ModalImage from '../../components/modal/modalImage';

class uploadMasterImagePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageDataList: [],
      imageById: [],
      uploadImage: [],
      image: [],
      viewListBy: 0,
      modalAlert: false,
      headerAlert: '',
      contentAlert: '',
      loadingMasterImage: true,
      failedMasterImage: false,
      openModalImage: false,
      listStepImage: [
        {
          Tittle: 'Choose Image',
          Active: true,
        },
        {
          Tittle: 'Select Thumbnail Image',
          Active: false,
        },
        {
          Tittle: 'Edit Thumbnail Image',
          Active: false,
        },
      ],
      indexActive: 0,
      disableSearch: {
        search: true,
        searchObject: true,
      },
      searchInput: '',
      searchInputObject: '',
      activePage: 1,
    };
  }
  handleClose = () =>
    this.setState({
      modalAlert: false,
      failedMasterImage: false,
      openModalImage: false,
    });
  uploadImage = () => {
    this.setState({ indexActive: 0, openModalImage: false });
    let imageUpload = this.state.uploadImage;
    let initial = this.state.viewListBy;
    let Id = this.state.Id;
    this.setState({ loadingMasterImage: true });
    switch (initial) {
      case 'Excursion': {
        imageUpload.map(data => {
          // if (data.onChange) {
          //   return this.props.postUploadImageExcursionObjectAction(Id, {
          //     ServiceItemId: Id,
          //     ImageContent: data.ImageContent,
          //     ImageName: data.ImageName,
          //   });
          // }
          return data.onChange
            ? this.props.postUploadImageExcursionObjectAction(Id, {
                ServiceItemId: Id,
                ImageContent: data.ImageContent,
                ImageName: data.ImageName,
              })
            : null;
        });
        break;
      }
      case 'AccomodationProfile': {
        imageUpload.map(data => {
          return data.onChange
            ? this.props.postUploadImageAccomodationProfileAction({
                accommodationProfileId: Id,
                imageUrl: data.ImageContent,
                TinyImageUrl: data.ImageContent,
                ImageName: data.ImageName,
                ImageContent: data.ImageContent,
              })
            : null;
        });
        break;
      }
      case 'TransportationProfile': {
        imageUpload.map(data => {
          return data.onChange
            ? this.props.postUploadImageTransportationProfileAction(Id, {
                ImageContent: data.ImageContent,
                ImageName: data.ImageName,
                base64Text: data.ImageContent,
                imageUrl: data.ImageContent,
                TransportationProfileId: this.state.Id,
                IsPrimaryImage: true,
              })
            : null;
        });
        break;
      }
      case 'AccomodationRoom': {
        imageUpload.map(data => {
          return data.onChange
            ? this.props.postUploadImageAccomodationItemAction(Id, {
                ServiceItemId: Id,
                ImageContent: data.ImageContent,
                ImageName: data.ImageName,
              })
            : null;
        });
        break;
      }
      case 'RestaurantMenu': {
        imageUpload.map(data => {
          return data.onChange
            ? this.props.postUploadImageRestaurantItemAction(Id, {
                ServiceItemId: Id,
                ImageContent: data.ImageContent,
                ImageName: data.ImageName,
              })
            : null;
        });
        break;
      }
      case 'TransportationUnit': {
        imageUpload.map(data => {
          return data.onChange
            ? this.props.postUploadImageTransportationUnitAction(Id, {
                // ImageContent: data.ImageContent,
                // ImageName: data.ImageName,
                // TransportationUnitHeaderId: Id,
                ImageContent: data.ImageContent,
                ImageName: data.ImageName,
                TransportationUnitHeaderId: Id,
                IsPrimaryImage: true,
              })
            : null;
        });
        break;
      }
      case 'RestaurantProfile': {
        imageUpload.map(data => {
          return data.onChange
            ? this.props.postUploadImageRestaurantProfileAction(Id, {
                restaurantProfileId: Id,
                imageUrl: data.ImageContent,
                tinyImageUrl: data.ImageContent,
                ImageContent: data.ImageContent,
                ImageName: data.ImageName,
              })
            : null;
        });
        break;
      }

      default: {
        alert('Invalid choice');
        break;
      }
    }
  };

  setPrimaryImage = () => {
    this.setState({ indexActive: 1 });
    let imageUpload = this.state.uploadImage;
    let initial = this.state.viewListBy;
    switch (initial) {
      case 'Excursion': {
        imageUpload.map(data => {
          return data.IsPrimaryImage
            ? this.props.postSetPrimaryExcursionObjectAction(data.Id)
            : null;
        });
        break;
      }
      case 'AccomodationProfile': {
        imageUpload.map(data => {
          // if (data.IsPrimaryImage) {
          //   return this.props.postSetPrimaryImageAccomodationProfileAction(
          //     data.ImageId
          //   );
          // }
          return data.IsPrimaryImage
            ? this.props.postSetPrimaryImageAccomodationProfileAction(
                data.ImageId
              )
            : null;
        });
        break;
      }
      case 'TransportationProfile': {
        imageUpload.map(data => {
          return data.IsPrimaryImage
            ? this.props.postSetPrimaryImageTransportationProfileAction(
                data.ImageId
              )
            : null;
        });
        break;
      }
      case 'AccomodationRoom': {
        imageUpload.map(data => {
          return data.IsPrimaryImage
            ? this.props.postSetPrimaryImageAccomodationItemAction(data.ImageId)
            : null;
        });
        break;
      }
      case 'RestaurantMenu': {
        imageUpload.map(data => {
          return data.IsPrimaryImage
            ? this.props.postSetPrimaryImageRestaurantItemAction(data.ImageId)
            : null;
        });
        break;
      }
      case 'TransportationUnit': {
        imageUpload.map(data => {
          return data.IsPrimaryImage
            ? this.props.postSetPrimaryImageTransportaionUnitHeaderAction(
                data.ImageId
              )
            : null;
        });
        break;
      }
      case 'RestaurantProfile': {
        imageUpload.map(data => {
          return data.IsPrimaryImage
            ? this.props.postSetPrimaryImageRestaurantProfileAction(
                data.ImageId
              )
            : null;
        });
        break;
      }

      default: {
        alert('Invalid choice');
        break;
      }
    }
  };
  componentDidMount = () => {
    this.setState({ loadingMasterImage: false });
  };
  dataList = data => {
    switch (data) {
      case 'AccomodationProfile': {
        this.setState({
          loadingMasterImage: true,
          disableSearch: {
            search: false,
            searchObject: true,
          },
        });
        this.props.getAccomodationListAction();
        break;
      }
      case 'RestaurantProfile': {
        this.setState({
          loadingMasterImage: true,
          disableSearch: {
            search: false,
            searchObject: true,
          },
        });
        this.props.getRestaurantListAction();
        break;
      }
      case 'Excursion': {
        this.setState({
          loadingMasterImage: true,
          disableSearch: {
            search: false,
            searchObject: true,
          },
        });
        this.props.getExcursionListAction();
        break;
      }
      case 'TransportationProfile': {
        this.setState({
          loadingMasterImage: true,
          disableSearch: {
            search: false,
            searchObject: true,
          },
        });
        this.props.getTransportationListAction();
        break;
      }
      case 'AccomodationRoom': {
        this.setState({
          loadingMasterImage: true,
          disableSearch: {
            search: false,
            searchObject: false,
          },
        });

        this.props.getAccomodationItemAllAction();
        break;
      }
      case 'TransportationUnit': {
        this.setState({
          loadingMasterImage: true,
          disableSearch: {
            search: false,
            searchObject: false,
          },
        });
        this.props.getTransportationUnitAllAction();
        break;
      }
      case 'RestaurantMenu': {
        this.setState({
          loadingMasterImage: true,
          disableSearch: {
            search: false,
            searchObject: false,
          },
        });
        this.props.getRestaurantItemAllAction();
        break;
      }
      default: {
        alert('Invalid choice');
        break;
      }
    }
  };
  static propTypes = {
    getAccomodationListAction: PropTypes.func,
    getRestaurantListAction: PropTypes.func,
    resetAccomodationAction: PropTypes.func,
    postMasterDataPreviewAccomodationProfileAction: PropTypes.func,
    getExcursionListAction: PropTypes.func,
    getTransportationListAction: PropTypes.func,
    getAccomodationListStatus: PropTypes.string,
    getAccomodationList: PropTypes.array,
    getExcursionListStatus: PropTypes.string,
    getExcursionList: PropTypes.array,
    getRestaurantListStatus: PropTypes.string,
    getRestaurantList: PropTypes.array,
    getTransportationListStatus: PropTypes.string,
    getTransportationList: PropTypes.array,
    resetExcursionAction: PropTypes.func,
    resetRestaurantAction: PropTypes.func,
    resetTransportationAction: PropTypes.func,
    getAccomodationByProfiledIdAction: PropTypes.func,
    getExcursionAction: PropTypes.func,
    getExcursionByIdStatus: PropTypes.string,
    getExcursionById: PropTypes.array,
    postUploadImageExcursionObjectAction: PropTypes.func,
    postUploadImageTransportationProfileAction: PropTypes.func,
    postUploadImageAccomodationItemAction: PropTypes.func,
    postUploadImageRestaurantItemAction: PropTypes.func,
    postUploadImageTransportationUnitAction: PropTypes.func,
    postUploadImageRestaurantProfileAction: PropTypes.func,
    getAccomodationItemAllAction: PropTypes.func,
    getTransportationUnitAllAction: PropTypes.func,
    getRestaurantItemAllAction: PropTypes.func,
    getAccomodationItemByServiceTypeStatus: PropTypes.string,
    getAccomodationItemByServiceType: PropTypes.func,
    getAccomodationItemAllStatus: PropTypes.string,
    getAccomodationItemAll: PropTypes.func,
    getRestaurantItemAllStatus: PropTypes.string,
    getRestaurantItemAll: PropTypes.func,
    getRestaurantItemStatus: PropTypes.string,
    restaurantItem: PropTypes.array,
    getRestaurantItemByProfileIdStatus: PropTypes.string,
    getRestaurantItemByProfileId: PropTypes.array,
    getTransportationUnitAllStatus: PropTypes.string,
    getTransportationUnitAll: PropTypes.array,
    getTransportationUnitByServiceItemIdStatus: PropTypes.string,
    getTransportationUnitByServiceItemId: PropTypes.array,
    getRestaurantByProfileIdStatus: PropTypes.string,
    getRestaurantByProfileId: PropTypes.array,
    postUploadImageAccomodationProfileStatus: PropTypes.string,
    postUploadImageExcursionProfileStatus: PropTypes.string,
    getRestaurantByProfileIdAction: PropTypes.func,
    getAccomodationItemByServiceTypeIdAction: PropTypes.func,
    getTransportationUnitByServiceItemIdAction: PropTypes.func,
    getRestaurantItemByIdAction: PropTypes.func,
    postUploadImageAccomodationProfileAction: PropTypes.func,
    postSetPrimaryImageAccomodationItemAction: PropTypes.func,
    postSetPrimaryImageAccomodationProfileAction: PropTypes.func,
    postSetPrimaryExcursionObjectAction: PropTypes.func,
    postSetPrimaryImageRestaurantItemAction: PropTypes.func,
    postSetPrimaryImageRestaurantProfileAction: PropTypes.func,
    postSetPrimaryImageTransportaionUnitHeaderAction: PropTypes.func,
    postSetPrimaryImageTransportationProfileAction: PropTypes.func,
    getAccomodationByProfilId: PropTypes.array,
    getTransportationUnitHeaderByIdAction: PropTypes.func,
    getTransportationProfileIdAction: PropTypes.func,
    errorUploadAccomodation: PropTypes.string,
    postUploadImageRestaurantProfileStatus: PropTypes.string,
    errorUploadRestaurant: PropTypes.string,
    postUploadImageRestaurantItemStatus: PropTypes.string,
    getTransportationByProfileIdStatus: PropTypes.string,
    getTransportationByProfileId: PropTypes.array,
    errorUploadExcursion: PropTypes.string,
    postUploadImageAccomodationItemStatus: PropTypes.string,
    postUploadImageTransportationProfileStatus: PropTypes.string,
    errorsTransaportation: PropTypes.object,
    getTransportationUnitByHeaderIdStatus: PropTypes.string,
    getTransportationHeaderById: PropTypes.object,
    postUploadImageTransportationUnitStatus: PropTypes.string,
  };

  onChange = (e, { value }) => {
    if (value !== 0) {
      this.dataList(value);
      this.setState({
        viewListBy: value,
        imageDataList: [],
        searchInput: '',
        searchInputObject: '',
        activePage: 1,
      });
    } else {
      this.setState({
        viewListBy: value,
        imageDataList: [],
        disableSearch: {
          search: true,
          searchObject: true,
        },
      });
    }
  };
  componentDidUpdate = () => {
    if (this.props.getAccomodationListStatus === 'success') {
      this.setState({
        imageDataList: this.props.getAccomodationList,
        loadingMasterImage: false,
      });

      this.props.resetAccomodationAction();
    } else if (this.props.getAccomodationListStatus === 'failed') {
      this.setState({
        modalAlert: true,
        contentAlert: 'Accomodation failed to load ',
        headerAlert: 'Failed',
        failedMasterImage: true,
        loadingMasterImage: false,
      });
      this.props.resetAccomodationAction();
    }
    if (this.props.getAccomodationItemByServiceTypeStatus === 'success') {
      this.setState({
        imageById: this.props.getAccomodationItemByServiceType,
        //loadingAccomodationItem: false,
      });
      this.props.resetAccomodationAction();
    } else if (this.props.getAccomodationItemByServiceTypeStatus === 'failed') {
      this.setState({
        failedAccomodationItem: true,
        //loadingAccomodationItem: false,
      });
      this.props.resetAccomodationAction();
    }

    //------------------- Accomodation Room
    if (this.props.getAccomodationItemAllStatus === 'success') {
      this.setState({
        imageDataList: this.props.getAccomodationItemAll,
        loadingMasterImage: false,
      });
      this.props.resetAccomodationAction();
    } else if (this.props.getAccomodationItemAllStatus === 'failed') {
      this.setState({ failedMasterImage: true, loadingMasterImage: false });
      this.props.resetAccomodationAction();
    }

    //------------------- Excursion
    if (this.props.getExcursionListStatus === 'success') {
      this.setState({
        imageDataList: this.props.getExcursionList,
      });
      this.setState({ loadingMasterImage: false });
      this.props.resetExcursionAction();
    } else if (this.props.getExcursionListStatus === 'failed') {
      this.setState({ failedMasterImage: true });
      this.setState({ loadingMasterImage: false });
      this.props.resetExcursionAction();
    }
    //-----------------Resto
    if (this.props.getRestaurantListStatus === 'success') {
      this.setState({
        imageDataList: this.props.getRestaurantList,
      });
      this.setState({ loadingMasterImage: false });
      this.props.resetRestaurantAction();
    } else if (this.props.getRestaurantListStatus === 'failed') {
      this.setState({ failedMasterImage: true });
      this.setState({ loadingMasterImage: false });
      this.props.resetRestaurantAction();
    }
    if (this.props.getRestaurantItemAllStatus === 'success') {
      this.setState({
        imageDataList: this.props.getRestaurantItemAll,
        loadingMasterImage: false,
      });
      this.props.resetRestaurantAction();
    } else if (this.props.getRestaurantItemAllStatus === 'failed') {
      this.setState({ loadingMasterImage: false, failedMasterImage: true });
      this.props.resetRestaurantAction();
    }
    if (this.props.getRestaurantItemByProfileIdStatus === 'success') {
      this.setState({
        imageById: this.props.getRestaurantItemByProfileId,
      });
      //this.setState({ loadingRestaurantItem: false });
      this.props.resetRestaurantAction();
    } else if (this.props.getRestaurantItemByProfileIdStatus === 'failed') {
      this.setState({
        failedRestaurantItem: true,
        //loadingRestaurantItem: false,
      });
      this.props.resetRestaurantAction();
    }

    //-----------------------Transportation
    if (this.props.getTransportationListStatus === 'success') {
      this.setState({
        imageDataList: this.props.getTransportationList,
      });
      this.setState({ loadingMasterImage: false });
      this.props.resetTransportationAction();
    } else if (this.props.getTransportationListStatus === 'failed') {
      this.setState({ failedMasterImage: true });
      this.setState({ loadingMasterImage: false });
      this.props.resetTransportationAction();
    }
    if (this.props.getTransportationUnitAllStatus === 'success') {
      this.setState({
        imageDataList: this.props.getTransportationUnitAll,
        loadingMasterImage: false,
      });
      this.props.resetTransportationAction();
    } else if (this.props.getTransportationUnitAllStatus === 'failed') {
      this.setState({ loadingMasterImage: false, failedMasterImage: true });
      this.props.resetTransportationAction();
    }
    if (this.props.getTransportationUnitByHeaderIdStatus === 'success') {
      this.setState({
        imageById: this.props.getTransportationHeaderById,
        uploadImage: this.props.getTransportationHeaderById.UnitImages,
        loadingMasterImage: false,
      });
      this.props.resetTransportationAction();
    } else if (this.props.getTransportationUnitByHeaderIdStatus === 'failed') {
      this.setState({ loadingMasterImage: false, failedMasterImage: true });
      this.props.resetTransportationAction();
    }
    //------------------- GET ACCOMODATION BY ID------
    if (this.props.getAccomodationListStatus === 'success') {
      this.setState({
        imageById: this.props.getAccomodationByProfilId,
        uploadImage: this.props.getAccomodationByProfilId.ProfileImages,
      });
      this.setState({ loadingMasterImage: false });
      this.props.resetExcursionAction();
    } else if (this.props.getAccomodationListStatus === 'failed') {
      this.setState({ failedMasterImage: true });
      this.setState({ loadingMasterImage: false });
      this.props.resetExcursionAction();
    }
    //------------------- GET EXCURSION BY ID------
    if (this.props.getExcursionByIdStatus === 'success') {
      this.setState({
        imageById: this.props.getExcursionById,
        uploadImage: this.props.getExcursionById.ObjectImages,
      });
      this.setState({ loadingMasterImage: false });
      this.props.resetExcursionAction();
    } else if (this.props.getExcursionByIdStatus === 'failed') {
      this.setState({ failedMasterImage: true });
      this.setState({ loadingMasterImage: false });
      this.props.resetExcursionAction();
    }
    //========================= GET RESTAURANT PROFILE ID
    if (this.props.getRestaurantByProfileIdStatus === 'success') {
      this.setState({
        imageById: this.props.getRestaurantByProfileId,
        uploadImage: this.props.getRestaurantByProfileId
          .RestaurantProfileImages,
      });
      this.setState({ loadingMasterImage: false });
      this.props.resetRestaurantAction();
    } else if (this.props.getRestaurantByProfileId === 'failed') {
      this.setState({ failedMasterImage: true });
      this.setState({ loadingMasterImage: false });
      this.props.resetRestaurantAction();
    }

    //========================= GET RESTAURANT Item By ID
    if (this.props.getRestaurantItemStatus === 'success') {
      this.setState({
        imageById: this.props.restaurantItem,
        uploadImage: this.props.restaurantItem.RestaurantImages,
        loadingMasterImage: false,
      });
      this.props.resetRestaurantAction();
    } else if (this.props.getRestaurantItemStatus === 'failed') {
      this.setState({ loadingMasterImage: false, failedMasterImage: true });
      this.props.resetRestaurantAction();
    }
    //------------------- GET TRANSPORTATION PROFILE BY ID------
    if (this.props.getTransportationByProfileIdStatus === 'success') {
      this.setState({
        imageById: this.props.getTransportationByProfileId,
        loadingMasterImage: false,
      });
      this.props.resetTransportationAction();
    } else if (this.props.getTransportationByProfileIdStatus === 'failed') {
      this.setState({ failedMasterImage: true, loadingMasterImage: false });
      this.props.resetTransportationAction();
    }

    //=============================POST UPLOAD====================
    if (this.props.postUploadImageAccomodationProfileStatus === 'success') {
      this.setState({
        contentAlert: 'Accommodation profile image successfully uploaded ',
        headerAlert: 'Success',
        modalAlert: true,
      });
      this.props.getAccomodationListAction();
      this.props.resetAccomodationAction();
    } else if (
      this.props.postUploadImageAccomodationProfileStatus === 'failed'
    ) {
      this.setState({
        modalAlert: true,
        contentAlert: this.props.errorUploadAccomodation.global,
        headerAlert: 'Failed',
        failedMasterImage: true,
        loadingMasterImage: false,
      });
      this.props.resetAccomodationAction();
    }
    if (this.props.postUploadImageExcursionProfileStatus === 'success') {
      this.setState({
        contentAlert: 'Excursion image successfully uploaded ',
        headerAlert: 'Success',
        loadingMasterImage: false,
        modalAlert: true,
      });
      this.props.getExcursionListAction();
      this.props.resetExcursionAction();
    } else if (this.props.postUploadImageExcursionProfileStatus === 'failed') {
      this.setState({
        modalAlert: true,
        contentAlert: this.props.errorUploadExcursion.global,
        headerAlert: 'Failed',
        failedMasterImage: true,
        loadingMasterImage: false,
      });
      this.props.resetExcursionAction();
    }
    if (this.props.postUploadImageRestaurantProfileStatus === 'success') {
      this.setState({
        contentAlert: 'Restaurant profile image successfully uploaded ',
        headerAlert: 'Success',
        modalAlert: true,
      });
      this.props.getRestaurantListAction();
      this.props.resetRestaurantAction();
    } else if (this.props.postUploadImageRestaurantProfileStatus === 'failed') {
      this.setState({
        modalAlert: true,
        contentAlert: this.props.errorUploadRestaurant.global,
        headerAlert: 'Failed',
        failedMasterImage: true,
        loadingMasterImage: false,
      });
      this.props.resetRestaurantAction();
    }
    if (this.props.postUploadImageRestaurantItemStatus === 'success') {
      this.setState({
        contentAlert: 'Restaurant item image successfully uploaded ',
        headerAlert: 'Success',
        modalAlert: true,
      });
      this.props.getRestaurantItemAllAction();
      this.props.resetRestaurantAction();
    } else if (this.props.postUploadImageRestaurantItemStatus === 'failed') {
      this.setState({
        modalAlert: true,
        contentAlert: this.props.errorUploadRestaurant.global,
        headerAlert: 'Failed',
        failedMasterImage: true,
        loadingMasterImage: false,
      });
      this.props.resetRestaurantAction();
    }
    if (this.props.postUploadImageAccomodationItemStatus === 'success') {
      this.setState({
        contentAlert: 'Accommodation item image successfully uploaded',
        headerAlert: 'Success',
        modalAlert: true,
      });
      this.props.getAccomodationItemAllAction();
      this.props.resetAccomodationAction();
    } else if (this.props.postUploadImageAccomodationItemStatus === 'failed') {
      this.setState({
        modalAlert: true,
        contentAlert: this.props.errorUploadAccomodation.global,
        headerAlert: 'Failed',
        failedMasterImage: true,
        loadingMasterImage: false,
      });
      this.props.resetAccomodationAction();
    }
    if (this.props.postUploadImageTransportationProfileStatus === 'success') {
      this.setState({
        contentAlert: 'Transportation profile image successfully uploaded',
        headerAlert: 'Success',
        modalAlert: true,
      });
      this.props.getTransportationListAction();
      this.props.resetTransportationAction();
    } else if (
      this.props.postUploadImageTransportationProfileStatus === 'failed'
    ) {
      this.setState({
        modalAlert: true,
        contentAlert: this.props.errorsTransaportation.global,
        headerAlert: 'Failed',
        failedMasterImage: true,
        loadingMasterImage: false,
      });
      this.props.resetTransportationAction();
    }
    if (this.props.postUploadImageTransportationUnitStatus === 'success') {
      this.setState({
        contentAlert: 'Transportation Unit image successfully uploaded',
        headerAlert: 'Success',
        modalAlert: true,
      });
      this.props.getTransportationUnitAllAction();
      this.props.resetTransportationAction();
    } else if (
      this.props.postUploadImageTransportationUnitStatus === 'failed'
    ) {
      this.setState({
        modalAlert: true,
        contentAlert: this.props.errorsTransaportation.global,
        headerAlert: 'Failed',
        failedMasterImage: true,
        loadingMasterImage: false,
      });
      this.props.resetTransportationAction();
    }
  };
  //--------------------------------------------------upload Image
  openModalImage = e => {
    const data = this.state.viewListBy;
    let Id = e.target.id;
    this.setState({
      openModalImage: true,
      Id: Id,
      uploadImage: [],
      indexActive: 0,
    });
    switch (data) {
      case 'AccomodationProfile': {
        this.props.getAccomodationByProfiledIdAction(Id);
        break;
      }
      case 'RestaurantProfile': {
        this.props.getRestaurantByProfileIdAction(Id);
        break;
      }
      case 'Excursion': {
        this.props.getExcursionAction(Id);
        break;
      }
      case 'AccomodationRoom': {
        this.props.getAccomodationItemByServiceTypeIdAction(Id);
        break;
      }
      case 'TransportationUnit': {
        //this.props.getTransportationUnitByServiceItemIdAction(Id);
        this.props.getTransportationUnitHeaderByIdAction(Id);
        break;
      }
      case 'RestaurantMenu': {
        this.props.getRestaurantItemByIdAction(Id);
        break;
      }
      case 'TransportationProfile': {
        this.props.getTransportationProfileIdAction(Id);
        break;
      }
      default: {
        alert('Under Development');
        break;
      }
    }
  };
  addImage = () => {
    let objectImage = this.state.uploadImage;
    let image = this.state.image;
    objectImage.push(image);
    let listStepImage = this.state.listStepImage;
    listStepImage.map((step, i) => {
      step['Active'] = i === 0 ? true : false;
      return listStepImage;
    });
    this.setState({
      uploadImage: objectImage,
      openModalImage: false,
      listStepImage: listStepImage,
      indexActive: 0,
    });
  };
  changeImage = e => {
    let imageObject = this.state.uploadImage;
    e.map(base => {
      let image = {
        ImageId: 0,
        ImageContent: base.base64.split(',')[1],
        ImagePreview: base.base64,
        ImageName: base.file.name,
        IsPrimaryImage: false,
        onChange: true,
      };
      return imageObject.push(image);
    });
    this.setState({
      uploadImage: imageObject,
    });
  };
  openDetail = (e, { name }) => {
    const ObjectImages = this.state.uploadImage;
    let listStepImage = this.state.listStepImage;
    listStepImage.map((step, i) => {
      step['Active'] = i === 0 ? true : false;
      return listStepImage;
    });
    let image = ObjectImages[name];
    this.setState({
      image: image,
      indexActive: 0,
      listStepImage: listStepImage,
      openModalImage: true,
      imageUrl: image.ImageUrl //ImageUrl
        ? image.ImageUrl //ImageUrl
        : URL.createObjectURL(e.target.files[0]),
    });
  };

  handleChangePrimaryImage = (e, { name }) => {
    let listImage = this.state.uploadImage;
    let newList = [];
    listImage.map((img, i) => {
      img['IsPrimaryImage'] = i === name ? true : false;
      return newList.push(img);
    });
    this.setState({
      uploadImage: newList,
    });
  };
  handleNext = () => {
    let indexActive = this.state.indexActive;
    indexActive = indexActive + 1;
    let listStepImage = this.state.listStepImage;
    listStepImage.map((step, i) => {
      step['Active'] = i === indexActive ? true : false;
      return listStepImage;
    });
    if (indexActive === 2) {
      let listImage = this.state.uploadImage;
      listImage.map(img => {
        return img.IsPrimaryImage && this.setState({ image: img });
      });
      this.setPrimaryImage();
    }
    if (indexActive === 3) {
      //return this.setState({ indexActive: 0, openModalImage: false });
      return this.uploadImage();
    }
    this.setState({ listStepImage: listStepImage, indexActive: indexActive });
  };
  handleBackStep = () => {
    let indexActive = this.state.indexActive;
    indexActive = indexActive - 1;
    let listStepImage = this.state.listStepImage;
    listStepImage.map((step, i) => {
      step['Active'] = i === indexActive ? true : false;
      return listStepImage;
    });
    this.setState({ listStepImage: listStepImage, indexActive: indexActive });
  };
  handleChangeSearch = (name, value) => {
    this.setState({ [name]: value, activePage: 1 });
  };
  pageChange = activePage => this.setState({ activePage });
  render() {
    let { loadingMasterImage, failedMasterImage, modalAlert } = this.state;
    let loading = loadingMasterImage;
    let openModal = failedMasterImage || modalAlert;
    return (
      <div>
        <Dimmer.Dimmable blurring dimmed={loading}>
          <Loader loading={loading} />
          <MasterImageList
            handleChange={this.onChange}
            disabledSave={this.state.disabledSave}
            disabledUpload={this.state.disabledUpload}
            saveUpload={this.saveUpload}
            submit={this.submit}
            imageDataList={this.state.imageDataList}
            valueDropDown={this.state.viewListBy}
            openModalImage={this.openModalImage}
            disableSearch={this.state.disableSearch}
            handleChangeSearch={this.handleChangeSearch}
            pageChange={this.pageChange}
            searchInputObject={this.state.searchInputObject}
            searchInput={this.state.searchInput}
            activePage={this.state.activePage}
          />
          <ModalAlert
            openModal={openModal}
            handleClose={this.handleClose}
            header={this.state.headerAlert}
            content={this.state.contentAlert}
            nameButton="OK"
          />
          <ModalImage
            openModal={this.state.openModalImage}
            imagePreview={this.state.imageUrl}
            handleChange={this.changeImage}
            addImage={this.addImage}
            handleClose={this.handleClose}
            header="Upload Image"
            image={this.state.uploadImage}
            listStepImage={this.state.listStepImage}
            handleNext={this.handleNext}
            handleBackStep={this.handleBackStep}
            indexActive={this.state.indexActive}
            handleChangePrimaryImage={this.handleChangePrimaryImage}
            imageRatio={this.state.image}
          />
        </Dimmer.Dimmable>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    //---------------------- actionList----------------------
    getAccomodationList: state.AccomodationStore.getAccomodationList,
    getAccomodationListStatus:
      state.AccomodationStore.getAccomodationListStatus,
    getExcursionList: state.ExcursionStore.getExcursionList,
    getExcursionListStatus: state.ExcursionStore.getExcursionListStatus,
    getRestaurantList: state.RestaurantStore.getRestaurantList,
    getRestaurantListStatus: state.RestaurantStore.getRestaurantListStatus,
    getTransportationList: state.TransportationStore.getTransportationList,
    getTransportationListStatus:
      state.TransportationStore.getTransportationListStatus,
    postUploadImageAccomodationProfile:
      state.AccomodationStore.postUploadImageAccomodationProfile,
    postUploadImageAccomodationProfileStatus:
      state.AccomodationStore.postUploadImageAccomodationProfileStatus,
    postUploadImageExcursionProfileStatus:
      state.ExcursionStore.postUploadImageExcursionStatus,
    postUploadImageExcursionProfile:
      state.ExcursionStore.postUploadImageExcursion,
    getTransportationUnitAll:
      state.TransportationStore.getTransportationUnitAll,
    getTransportationUnitAllStatus:
      state.TransportationStore.getTransportationUnitAllStatus,
    getRestaurantItemAll: state.RestaurantStore.getRestaurantItemAll,
    getRestaurantItemAllStatus:
      state.RestaurantStore.getRestaurantItemAllStatus,
    getAccomodationItemAll: state.AccomodationStore.getAccomodationItemAll,
    getAccomodationItemAllStatus:
      state.AccomodationStore.getAccomodationItemAllStatus,
    restaurantItem: state.RestaurantStore.restaurantItem,
    getRestaurantItemStatus: state.RestaurantStore.getRestaurantItemStatus,
    postUploadImageRestaurantProfileStatus:
      state.RestaurantStore.postUploadImageRestaurantProfileStatus,
    postUploadImageRestaurantProfile:
      state.RestaurantStore.postUploadImageRestaurantProfile,
    postPrimaryImageAccomodationStatus:
      state.AccomodationStore.postPrimaryImageStatus,
    postPrimaryImageRestaurantStatus:
      state.RestaurantStore.postPrimaryImageStatus,
    postPrimaryImageExcursionStatus:
      state.ExcursionStore.postPrimaryImageStatus,
    postPrimaryImageTransportationStatus:
      state.TransportationStore.postPrimaryImageStatus,
    //===========================================
    getAccomodationByProfilId:
      state.AccomodationStore.getAccomodationByProfilId,
    getExcursionByIdStatus: state.ExcursionStore.getExcursionStatus,
    getExcursionById: state.ExcursionStore.excurtion,
    getRestaurantByProfileId: state.RestaurantStore.getRestaurantByProfileId,
    getRestaurantByProfileIdStatus:
      state.RestaurantStore.getRestaurantByProfileIdStatus,
    getAccomodationItemByServiceTypeStatus:
      state.AccomodationStore.getAccomodationItemByServiceTypeStatus,
    getAccomodationItemByServiceType:
      state.AccomodationStore.getAccomodationItemByServiceTypeId,
    getTransportationUnitByServiceItemId:
      state.TransportationStore.getTransportationUnitByServiceItemId,
    getTransportationUnitByServiceItemIdStatus:
      state.TransportationStore.getTransportationUnitByServiceItemIdStatus,
    errorUploadAccomodation: state.AccomodationStore.errors,
    errorUploadRestaurant: state.RestaurantStore.errors,
    errorUploadExcursion: state.ExcursionStore.errors,
    errorsTransaportation: state.TransportationStore.errors,
    postUploadImageRestaurantItemStatus:
      state.RestaurantStore.postUploadImageRestaurantItemStatus,
    getTransportationByProfileId:
      state.TransportationStore.getTransportationByProfileId,
    getTransportationByProfileIdStatus:
      state.TransportationStore.getTransportationByProfileIdStatus,
    postUploadImageAccomodationItemStatus:
      state.AccomodationStore.postUploadImageAccomodationItemStatus,
    postUploadImageTransportationProfileStatus:
      state.TransportationStore.postUploadImageTransportationProfileStatus,
    postUploadImageTransportationUnitStatus:
      state.TransportationStore.postUploadImageTransportationUnitStatus,
    getTransportationHeaderById: state.TransportationStore.transportationHeader,
    getTransportationUnitByHeaderIdStatus:
      state.TransportationStore.getTransportationUnitByHeaderIdStatus,
    /*   
         getRestaurantItemByProfileId:
          state.RestaurantStore.getRestaurantItemByProfileId,
        getRestaurantItemByProfileIdStatus:
          state.RestaurantStore.getRestaurantItemByProfileIdStatus,
        getTransportationUnitByProfileId:
          state.ExcursionStore.getTransportationUnitByProfileId,
        getTransportationUnitByProfileIdStatus:
          state.ExcursionStore.getTransportationUnitByProfileIdStatus, */
  };
}
export default connect(
  mapStateToProps,
  {
    getAccomodationListAction,
    resetAccomodationAction,
    getExcursionListAction,
    resetExcursionAction,
    getRestaurantListAction,
    resetRestaurantAction,
    getTransportationListAction,
    resetTransportationAction,
    getAccomodationByProfiledIdAction,
    getExcursionAction,
    getRestaurantByProfileIdAction,
    getTransportationProfileIdAction,
    postUploadImageAccomodationProfileAction,
    postUploadImageExcursionObjectAction,
    postUploadImageTransportationProfileAction,
    postUploadImageTransportationUnitAction,
    getAccomodationItemByServiceTypeIdAction,
    getAccomodationItemAllAction,
    getRestaurantItemByProfileIdAction,
    getTransportationUnitByServiceItemIdAction,
    getRestaurantItemAllAction,
    getTransportationUnitAllAction,
    postUploadImageAccomodationItemAction,
    getRestaurantItemByIdAction,
    postUploadImageRestaurantItemAction,
    postUploadImageRestaurantProfileAction,
    postSetPrimaryImageAccomodationItemAction,
    postSetPrimaryImageAccomodationProfileAction,
    postSetPrimaryExcursionObjectAction,
    postSetPrimaryImageRestaurantItemAction,
    postSetPrimaryImageRestaurantProfileAction,
    postSetPrimaryImageTransportaionUnitHeaderAction,
    postSetPrimaryImageTransportationProfileAction,
    getAccomodationItemByProfiledIdAction,
    getTransportationUnitHeaderByIdAction,
  }
)(uploadMasterImagePage);
