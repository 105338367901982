import * as types from '../../../actions/actionTypes';

const initialState = {
  loading: false,
  getExcursionList: [],
  getExcursionListStatus: '',
  errors: {},
  putExcurtionStatus: '',
  excursionTypeList: [],
  getExcursionTypesStatus: '',
  getExcursionStatus: '',
  excurtion: {},
  getExursionObjectTemplate: '',
  getExursionObjectTemplateStatus: '',
  getExursionPriceTemplate: '',
  getExursionPriceTemplateStatus: '',
  getExcurtionProfileByCity: [],
  getExcurtionProfileByCityStatus: '',
  postPrimaryImageStatus: '',
  messageErrors: [],
  getExportDataItemById: '',
  getExportDataItemByIdStatus: null,
  loadingGetAttractionEstimatePrice: false,
  listAttractionEstimatePrice: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_EXCURSION_PROFILES:
      return { ...state };
    case types.GET_EXCURSION_PROFILES_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getExcursionListStatus: 'failed',
      };
    case types.GET_EXCURSION_PROFILES_PENDING:
      return { ...state, loading: true };
    case types.GET_EXCURSION_PROFILES_FULFILLED:
      return {
        ...state,
        getExcursionList: action.payload.data,
        loading: false,
        getExcursionListStatus: 'success',
      };

    case types.GET_EXCURSION_PROFILE: {
      return { ...state };
    }
    case types.GET_EXCURSION_PROFILE_FULFILLED: {
      return {
        ...state,
        loading: false,
        getExcursionStatus: 'success',
        excurtion: action.payload.data,
      };
    }
    case types.GET_EXCURSION_PROFILE_PENDING: {
      return { ...state, loading: true };
    }
    case types.GET_EXCURSION_PROFILE_REJECTED: {
      return {
        ...state,
        loading: false,
        getExcursionStatus: 'failed',
        errors: { global: action.payload.response.data.Message },
      };
    }

    case types.GET_EXCURSION_TYPE: {
      return { ...state };
    }
    case types.GET_EXCURSION_TYPE_PENDING: {
      return { ...state, loading: true };
    }
    case types.GET_EXCURSION_TYPE_FULFILLED: {
      return {
        ...state,
        excursionTypeList: action.payload.data,
        getExcursionTypesStatus: 'success',
        loading: false,
      };
    }
    case types.GET_EXCURSION_TYPE_REJECTED: {
      return {
        ...state,
        getExcursionTypesStatus: 'failed',
        loading: false,
        errors: { global: action.payload.response.data.Message },
      };
    }
    case types.PUT_EXCURSION_PROFILE: {
      return { ...state };
    }
    case types.PUT_EXCURSION_PROFILE_PENDING: {
      return { ...state, loading: true };
    }
    case types.PUT_EXCURSION_PROFILE_FULFILLED: {
      return {
        ...state,
        loading: false,
        putExcurtionStatus: 'success',
        excurtion: action.payload.data,
      };
    }
    case types.PUT_EXCURSION_PROFILE_REJECTED: {
      let messageErrors = action.payload.response.data
        ? action.payload.response.data.ModelState[
            'attractionObject.Description'
          ]
        : [];
      return {
        ...state,
        loading: false,
        putExcurtionStatus: 'failed',
        errors: { global: action.payload.response.data.Message },
        messageErrors,
      };
    }

    case types.RESET_STATUS_EXCURSION: {
      return {
        ...state,
        getExcursionListStatus: '',
        getExcursionStatus: '',
        putExcurtionStatus: '',
        getExursionObjectExportStatus: '',
        getExursionObjectTemplateStatus: '',
        getExursionPriceExportStatus: '',
        getExursionPriceTemplateStatus: '',
        postUploadImageExcursionStatus: '',
        getExcurtionProfileByCityStatus: '',
        postExcurtionStatus: '',
        postPrimaryImageStatus: '',
        getExcursionTypesStatus: '',
        getExportDataItemByIdStatus: null,
      };
    }

    case types.GET_TEMPLATE_EXCURSION_OBJECT_DATA:
      return { ...state };
    case types.GET_TEMPLATE_EXCURSION_OBJECT_DATA_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getExursionObjectTemplateStatus: 'failed',
      };
    case types.GET_TEMPLATE_EXCURSION_OBJECT_DATA_PENDING:
      return { ...state, loading: true };
    case types.GET_TEMPLATE_EXCURSION_OBJECT_DATA_FULFILLED:
      return {
        ...state,
        getExursionObjectTemplate: action.payload.data,
        loading: false,
        getExursionObjectTemplateStatus: 'success',
      };

    case types.GET_TEMPLATE_EXCURSION_PRICE_DATA:
      return { ...state };
    case types.GET_TEMPLATE_EXCURSION_PRICE_DATA_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getExursionPriceTemplateStatus: 'failed',
      };
    case types.GET_TEMPLATE_EXCURSION_PRICE_DATA_PENDING:
      return { ...state, loading: true };
    case types.GET_TEMPLATE_EXCURSION_PRICE_DATA_FULFILLED:
      return {
        ...state,
        getExursionPriceTemplate: action.payload.data,
        loading: false,
        getExursionPriceTemplateStatus: 'success',
      };

    case types.GET_EXCURSION_PROFILE_BY_CITY: {
      return { ...state };
    }
    case types.GET_EXCURSION_PROFILE_BY_CITY_FULFILLED: {
      return {
        ...state,
        getExcurtionProfileByCity: action.payload.data,
        loading: false,
        getExcurtionProfileByCityStatus: 'success',
      };
    }
    case types.GET_EXCURSION_PROFILE_BY_CITY_PENDING: {
      return { ...state, loading: true };
    }
    case types.GET_EXCURSION_PROFILE_BY_CITY_REJECTED: {
      return {
        ...state,
        loading: false,
        getExcurtionProfileByCityStatus: 'failed',
        errors: { global: action.payload.response.data.Message },
      };
    }

    case types.POST_SET_PRIMARY_IMAGE:
      return { ...state };
    case types.POST_SET_PRIMARY_IMAGE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        postPrimaryImageStatus: 'failed',
        loading: false,
      };
    case types.POST_SET_PRIMARY_IMAGE_PENDING:
      return { ...state, loading: true };
    case types.POST_SET_PRIMARY_IMAGE_FULFILLED:
      return { ...state, loading: false, postPrimaryImageStatus: 'success' };

    case types.DELETE_EXCURSION_OBJECT:
      return { ...state };
    case types.DELETE_EXCURSION_OBJECT_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        deleteExcursionObjectStatus: 'failed',
        loading: false,
      };
    case types.DELETE_EXCURSION_OBJECT_PENDING:
      return { ...state, loading: true };
    case types.DELETE_EXCURSION_OBJECT_FULFILLED:
      return {
        ...state,
        loading: false,
        deleteExcursionObjectStatus: 'success',
      };

    case types.POST_UPLOAD_IMAGE_EXCURSION_OBJECT:
      return { ...state };
    case types.POST_UPLOAD_IMAGE_EXCURSION_OBJECT_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        postUploadImageExcursionStatus: 'failed',
      };
    case types.POST_UPLOAD_IMAGE_EXCURSION_OBJECT_PENDING:
      return { ...state, loading: true };
    case types.POST_UPLOAD_IMAGE_EXCURSION_OBJECT_FULFILLED:
      return {
        ...state,
        postUploadImageExcursion: action.payload.data,
        loading: false,
        postUploadImageExcursionStatus: 'success',
      };

    case types.GET_EXPORT_DATA_ITEM_BY_ID:
      return { ...state };
    case types.GET_EXPORT_DATA_ITEM_BY_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getExportDataItemByIdStatus: false,
      };
    case types.GET_EXPORT_DATA_ITEM_BY_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_EXPORT_DATA_ITEM_BY_ID_FULFILLED:
      return {
        ...state,
        getExportDataItemById: action.payload.data,
        loading: false,
        getExportDataItemByIdStatus: true,
      };
    case types.GET_ATTRACTION_ESTIMATE_PRICE:
      return { ...state };
    case types.GET_ATTRACTION_ESTIMATE_PRICE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loadingGetAttractionEstimatePrice: false,
        listAttractionEstimatePrice: null,
      };
    case types.GET_ATTRACTION_ESTIMATE_PRICE_PENDING:
      return { ...state, loadingGetAttractionEstimatePrice: true };
    case types.GET_ATTRACTION_ESTIMATE_PRICE_FULFILLED:
      return {
        ...state,
        listAttractionEstimatePrice: action.payload.data,
        loadingGetAttractionEstimatePrice: false,
      };
    default:
      return state;
  }
};

export default reducer;
