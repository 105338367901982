import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Label } from 'semantic-ui-react';

const headerTitle = props => {
  const { title, iconName } = props;
  return (
    <div style={{ marginBottom: '20px' }}>
      <Label
        as="h3"
        size="huge"
        style={{
          position: 'absolute',
          top: '-25px',
          background: '#38af95',
          color: 'white',
        }}
      >
        {iconName && <Icon name={iconName} />} {title}
      </Label>
    </div>
  );
};

headerTitle.propTypes = {
  title: PropTypes.string,
  iconName: PropTypes.string,
};
export default headerTitle;
