const fieldToValidate = [
  'Name',
  'Address',
  'Telephone',
  'CountryId',
  'CurrencyId',
  'Email',
  'FaxNbr',
  'AccommodationProfileId',
];

export const resetPasswordField = ['NewPassword', 'ConfirmPassword'];
export default fieldToValidate;
