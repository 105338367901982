export const ListDropdownReference = [
  {
    key: 0,
    text: 'Choose Type ',
    value: 0,
  },
  {
    key: 'AccommodationFacilities',
    text: 'Accommodation Facility',
    value: 'AccommodationFacilities',
  },
  {
    key: 'AccommodationItemType',
    text: 'Accommodation Item Type',
    value: 'AccommodationItemTypes',
  },
  {
    key: 'AccommodationLocation',
    text: 'Accommodation Location',
    value: 'AccommodationLocations',
  },
  {
    key: 'AccommodationRating',
    text: 'Accommodation Rating',
    value: 'AccommodationRatings',
  },
  {
    key: 'AccommodationType',
    text: 'Accommodation Type',
    value: 'AccommodationTypes',
  },
  {
    key: 'AdditionalServiceType',
    text: 'Additional Service Type',
    value: 'AdditionalServiceType',
  },
  {
    key: 'AdditionalItemType',
    text: 'Special Adjustment type',
    value: 'AdditionalItemType',
  },
  {
    key: 'AttractionCategories',
    text: 'Attraction Category',
    value: 'AttractionCategories',
  },
  {
    key: 'AttractionType',
    text: 'Attraction Type',
    value: 'AttractionTypes',
  },
  {
    key: 'CancellationReasons',
    text: 'Cancellation Reason',
    value: 'CancellationReasons',
  },
  {
    key: 'Currency',
    text: 'Currency',
    value: 'Currencies',
  },
  {
    key: 'CustomEnums',
    text: 'Custom Enumeration',
    value: 'CustomEnums',
  },
  {
    key: 'CustomerLevelTypes',
    text: 'Customer Level',
    value: 'CustomerLevelTypes',
  },
  {
    key: 'GuestCategory',
    text: 'Guest Category',
    value: 'GuestCategories',
  },
  {
    key: 'GuestIdentityType',
    text: 'Guest Identity Type',
    value: 'GuestIdentityTypes',
  },
  {
    key: 'GuestTitle',
    text: 'Guest Title',
    value: 'GuestTitles',
  },
  {
    key: 'IdentityTypeConfiguration',
    text: 'Identity Type Configuration',
    value: 'IdentityTypeConfigurations',
  },
  {
    key: 'Languages',
    text: 'Language',
    value: 'Languages',
  },
  {
    key: 'Positions',
    text: 'Job Position',
    value: 'Positions',
  },
  {
    key: 'MenuCategory',
    text: 'Menu Category',
    value: 'MenuCategories',
  },
  {
    key: 'MenuClasses',
    text: 'Menu Class',
    value: 'MenuClasses',
  },
  {
    key: 'MenuTypes',
    text: 'Menu Type',
    value: 'MenuTypes',
  },
  {
    key: 'PaxTypes',
    text: 'Pax Type',
    value: 'PaxTypes',
  },
  {
    key: 'RestaurantProfileSpecializations',
    text: 'Restaurant Profile Specialization',
    value: 'RestaurantProfileSpecializations',
  },
  {
    key: 'RestaurantRatings',
    text: 'Restaurant Rating',
    value: 'RestaurantRatings',
  },
  {
    key: 'RestaurantTypes',
    text: 'Restaurant Type',
    value: 'RestaurantTypes',
  },
  {
    key: 'TourCategories',
    text: 'Tour Category',
    value: 'TourCategories',
  },
  {
    key: 'TransportationProfileCategories',
    text: 'Transportation Profile Category',
    value: 'TransportationProfileCategories',
  },
  {
    key: 'TransportationProfileRatings',
    text: 'Transportation Profile Rating',
    value: 'TransportationProfileRatings',
  },
  {
    key: 'TransportationRatings',
    text: 'Transportation Rating',
    value: 'TransportationRatings',
  },
  {
    key: 'TransportationSeatTypes',
    text: 'Transportation Seat Type',
    value: 'TransportationSeatTypes',
  },
  {
    key: 'TransportationTypes',
    text: 'Transportation Type',
    value: 'TransportationTypes',
  },
  {
    key: 'RestaurantLocations',
    text: 'Restaurant Location',
    value: 'RestaurantLocations',
  },
];
