import { getAdditionalServicesListTransactionApi } from '../../../../api/additionalServices/additionalServicesApi';

const initialAdditionalServices = additionalServices => {
  let data = additionalServices.reduce((res, cur) => {
    let item = {
      AdditionalServiceId: cur.Id,
      Quantity: cur.Quantity ? cur.Quantity : 0,
      AdditionalServiceName: cur.AdditionalServicesName.Text
        ? cur.AdditionalServicesName.Text
        : cur.AdditionalServicesName,
      Amount: cur.OriginalCountries && cur.OriginalCountries[0].Amount,
      Currency: cur.OriginalCountries[0].Currency,
      OriginalCountry: cur.OriginalCountries && cur.OriginalCountries[0].Value,
      OriginalCountryId:
        cur.OriginalCountries && cur.OriginalCountries[0].OriginalCountryId,
      IsMandatory: cur.IsMandatory ? cur.IsMandatory : false,
      IsInfantCount: cur.IsInfantCount ? cur.IsInfantCount : false,
      DestinationCountry: cur.DestinationCountry.Text,
      DestinationCountryId: cur.DestinationCountry.Value,
    };
    res.push(item);
    return res;
  }, []);
  return data;
};

const getIndexofInArrayObject = (arr, prop, val) => {
  let arrId = arr.map(item => {
    return item[prop];
  });
  let index = arrId.indexOf(val);
  return index;
};

const getCountryFromArray = data => {
  if (
    data.length === 0 ||
    typeof data[0].City === 'undefined' ||
    data[0].City === null
  ) {
    return [];
  } else {
    return data.reduce((res, cur, idx, arr) => {
      let move = {
        from: idx === 0 ? 'DEF' : arr[idx - 1].City.Country.Id,
        to: cur.City.Country.Id,
      };
      res.push(move);
      return res;
    }, []);
  }
};

const filterAdditionalServicesByArrayId = (addServ, arrayId) => {
  let result = addServ.filter(item => arrayId.includes(item.Id));
  return result;
};

const deleteSameItemInArray = (item, arr, idx = 0) => {
  if (idx >= arr.length) return arr;
  else {
    let res = [...arr];
    let index = getIndexofInArrayObject(res, 'Id', item);
    if (index > -1) res.splice(index, 1);
    return deleteSameItemInArray(item, res, idx + 1);
  }
};

const filterAdditionalServicesBySelected = (addServ, selected) => {
  let newAddServ = addServ;
  selected.forEach(i => {
    let filtered = deleteSameItemInArray(i.AdditionalServiceId, newAddServ);
    newAddServ = filtered;
  });
  return newAddServ;
};

const removeDuplicates = (myArr, prop) => {
  return myArr.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
};

const removeDuplicateByString = myArr => {
  return myArr.filter((item, index) => {
    const _item = JSON.stringify(item);
    return (
      index ===
      myArr.findIndex(obj => {
        return JSON.stringify(obj) === _item;
      })
    );
  });
};

const getArrayCountryDestination = data => {
  let dataCountry = data.map(item => {
    return {
      id: item.City.Country.Id,
    };
  });
  let uniqueCountry = removeDuplicates(dataCountry, 'id');
  return uniqueCountry;
};

const getAdditionalServiceAfterDestinationChange = (
  addService,
  destination
) => {
  let addServ = [...addService];
  addServ = addServ.map(item => {
    return {
      ...item,
      isNotDeletedDestinationCountry: false,
    };
  });
  destination.forEach(dest => {
    addService.forEach((service, serviceIdx) => {
      if (dest.id === service.DestinationCountryId) {
        addServ[serviceIdx].isNotDeletedDestinationCountry = true;
      }
    });
  });
  let newSelectedAdditionalService = addServ.filter(
    item => item.isNotDeletedDestinationCountry
  );
  return newSelectedAdditionalService;
};

const updateAdditionalServiceAfterChangeDestination = (
  headlineProgram,
  additionalServices
) => {
  //   const { additionalServices } = this.state;
  let headlineCity =
    headlineProgram &&
    headlineProgram.MainPrograms.filter(item => item.City !== null);

  let arrayDestination = getArrayCountryDestination(
    headlineCity && headlineCity
  );
  let data = getAdditionalServiceAfterDestinationChange(
    additionalServices,
    arrayDestination
  );
  // this.setState({ ...this.state, additionalServices: data });
  return data;
};

const getAdditionalServiceFromApi = async (
  data = [],
  currency,
  idx = 0,
  res = []
) => {
  if (idx >= data.length) return res;
  else if (data.length) {
    let resData = [...res];
    await getAdditionalServicesListTransactionApi(
      data[idx].origin,
      data[idx].destination,
      currency
    )
      .then(result => {
        let listDataRes =
          data[idx].from === 'DEF'
            ? result.data.map(i => {
                return {
                  ...i,
                  OriginalCountries: i.OriginalCountries.filter(
                    j => j.Value === 'DEF'
                  ),
                };
              })
            : result.data;
        resData = resData.concat(listDataRes);
      })
      // eslint-disable-next-line
      .catch(err => console.log(err));
    return getAdditionalServiceFromApi(data, currency, idx + 1, resData);
  }
};

const getMatchAdditionalServices = (dataFromApi, dataFromPackage) => {
  dataFromApi = dataFromApi.map(item => {
    return {
      ...item,
      isNotInPackage: false,
    };
  });

  dataFromPackage.forEach(fromPackage => {
    dataFromApi.forEach((fromApi, fromApiIdx) => {
      // eslint-disable-next-line
      if (fromPackage.AdditionalServicesName.Value == fromApi.Id) { // fromPackage.Id === fromApi.Id
        dataFromApi[fromApiIdx].isNotInPackage = true;
        dataFromApi[fromApiIdx].Quantity = 0;
        dataFromApi[fromApiIdx].IsMandatory = fromPackage.IsMandatory;
        dataFromApi[fromApiIdx].IsInfantCount = fromPackage.IsInfantCount;
        dataFromApi[fromApiIdx].BookingTemplateAdditionalServiceId =
          fromPackage.BookingTemplateAdditionalServiceId;
      }
      fromApi.OriginalCountries.forEach(originCountry => {
        if (
          originCountry.OriginalCountryId ===
          fromPackage.OriginalCountries[0].OriginalCountryId
        ) {
          originCountry.IsSelected = true;
        }
      });
    });
  });

  let newSelectedAdditionalService = dataFromApi.filter(
    item => item.isNotInPackage
  );

  // ambil originalCountries yang sesuai dengan paket
  newSelectedAdditionalService = newSelectedAdditionalService.reduce(
    (res, cur) => {
      let data = {
        ...cur,
        OriginalCountries: cur.OriginalCountries.filter(
          item => item.IsSelected
        ),
      };
      res.push(data);
      return res;
    },
    []
  );
  return newSelectedAdditionalService;
};

const getNewAdditonalServicesFromAdditionalServiceBookingTemplate = async (
  additionalServices,
  currency
) => {
  let data = additionalServices.map(item => {
    return {
      origin: item.OriginalCountries[0].Value,
      destination: item.DestinationCountry.Value,
    };
  });
  let uniqueData = removeDuplicateByString(data);
  let newAdditionalServices =
    uniqueData && (await getAdditionalServiceFromApi(uniqueData, currency));
  let resultData = await getMatchAdditionalServices(
    newAdditionalServices,
    additionalServices
  );
  return resultData;
};

export {
  initialAdditionalServices,
  getIndexofInArrayObject,
  getCountryFromArray,
  filterAdditionalServicesByArrayId,
  filterAdditionalServicesBySelected,
  getAdditionalServiceAfterDestinationChange,
  updateAdditionalServiceAfterChangeDestination,
  getNewAdditonalServicesFromAdditionalServiceBookingTemplate,
};
