export const genderOptions = [
  {
    key: 'Male',
    text: 'Male',
    value: 'Male',
  },
  {
    key: 'Female',
    text: 'Female',
    value: 'Female',
  },
];
