const YELLOW = '#ff9800';
const ORANGE_RED = '#ff3d3d';

const styles = {
  tableHeader: {
    padding: '3px',
    borderBottom: '1px solid #252525',
    borderTop: 'none',
  },
  tableBody: {
    padding: '3px',
    border: 'none',
  },
  tableNoBorder: {
    border: 'none',
    padding: '3px',
  },
  colorYellow: {
    color: YELLOW,
  },
  colorOrangeRed: {
    color: ORANGE_RED,
  },
};

export default styles;
