import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form } from 'semantic-ui-react';
import InputWithLabel from '../../../shared/components/common/input/InputWithLabel';
const ModalChooseAccountManager = props => (
  <Modal
    dimmer="blurring"
    inverted
    open={props.openModal}
    onClose={props.handleClose}
    size="mini"
    closeIcon
  >
    <Modal.Header>{props.header}</Modal.Header>
    <Modal.Content>
      <Form>
        <InputWithLabel
          label="Account Manager"
          inline={false}
          type="select"
          name="AccountManagerProfileId"
          placeholder="Account Manager"
          handleChange={props.handleChange}
          required
          fluid={true}
          selectOnBlur={false}
          options={props.listDropdown}
        />
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button
        positive
        onClick={props.handleClickAgree}
        content={props.contentAgree}
        loading={props.loading}
      />
      <Button
        negative
        onClick={props.handleClose}
        content={props.contentCancel}
      />
    </Modal.Actions>
  </Modal>
);

ModalChooseAccountManager.propTypes = {
  openModal: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  content: PropTypes.string,
  contentCancel: PropTypes.string,
  handleClickAgree: PropTypes.func,
  contentAgree: PropTypes.string,
  handleChange: PropTypes.func,
  listDropdown: PropTypes.array,
  loading: PropTypes.bool,
};

export default ModalChooseAccountManager;
