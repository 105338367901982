import React from 'react';
import { connect } from 'react-redux';
import { Dimmer, Confirm } from 'semantic-ui-react';
//component
import {
  getTransactionByStatusCreatedAction,
  getTransactionByStatusOnHoldAction,
  getPaymentHistoryAction,
} from './homeAction';
import {
  getTransactionByStatusActionAfterDPAction,
  resetStatusTransactionAction,
  getTransactionByIdAction,
  postDemoManualPaymentAction,
  getPayableSupplemetItemAction,
  getPaymentTermByTransactionIdAction,
  postConfirmTransaction,
  getSpecialAdjusmentByTransactionIdAction,
  getTourTransactioBillsAction,
  getTourTransactionBilledAction,
  editSpecialAdjsumentAction,
  deleteSpecialAdjusmentAction,
  getScheduleTourTransactionAction,
  getInvoiceByTransactionIdAction,
  resetErrorMessageAction,
  postEmailSendConfirmation,
  postAdditionalItems,
} from '../../actions/tourTransactionAction';
import {
  getPaymentMethodChoiceListAction,
  postTopUpPaymentGatewayAction,
  resetCustomEnumAction,
  getPaymentMethodByCountryIdAction,
} from '../shared/actions/customEnumAction';
import {
  getCustomerProfileByCodeAction,
  resetCustomerProfileAction,
  postBalanceDetailAction,
  getAccountManagerAction,
} from '../shared/actions/customerProfileAction';
import { getCurrenciesAction } from '../../actions/currenciesAction';
import { getAdditionalItemTypeAction } from '../shared/actions/additionalItemAction';

import PropTypes from 'prop-types';
import moment from 'moment';
import PublishPackage from './component/PublishPackage';
import BookingConfirmation from './component/BookingConfirmation';
import CompanyRegistration from './component/CompanyRegistration';
import PaymentHistory from './component/PaymentHistory';
import SegmentCompanyCommissions from './component/SegmentCompanyCommission';
import ModalAlert from '../shared/components/modal/ModalAlert';
import OnHoldTransaction from './component/OnHoldTransaction';
import SalesReport from './component/SalesReport';
import ListOfTransactionDownPayment from './component/ListOfTransactionDownPayment';
import SegmentAccommodationAvailbility from './component/SegmentAccommodationAvailbility';
import BookingStatusList from './component/SegmentBookingStatusList';
import ManualConfirmationNumber from './component/ManualConfirmationNumber';
import ModalConfirmTransaction from '../shared/components/modal/ModalConfirmationAlert';
import PendingConfirmationNumber from './component/PendingConfirmationNumber';
// import ModalSpecialAdjusment from '../shared/components/modal/ModalSpecialAdjusment';
import ModalDetailBooking from '../shared/components/modal/ModalDetailBooking';
import ModalManualPayment from './component/modal/ModalManualPayment';
import ModalBalanceDetail from '../shared/components/modal/ModalBalanceDetail';
import ModalPaymentGateway from '../shared/components/modal/ModalPaymentGateway';
import ModalIframePaymentGateway from '../shared/components/modal/ModalIframePaymentGateway';
import CustomerPaymentCombine from './component/CustomerPaymentCombine';
import {
  setManualPaymentObject,
  setConfirmTransactionData,
} from './services/data/setDataState';
import SetTourPrice from './services/data/setTourPrice';
import { convertDateFormat } from '../../components/genericFunction/moment';
import ModalSpecialAdjusments from '../shared/components/modal/ModalSpecialAdjusments';
import {
  convertDataPostSpecialAdjustment,
  convertAfterGetSpecialAdjustment,
} from './../../scripts/specialAdjustment';
import ReportSales from './component/ReportSales';
import ModalCancellationReason from '../shared/components/modal/ModalCancellationReason';
import ModalManualSendEmail from '../shared/components/modal/ModalManualSendEmail';
import SegmentBookingCalendar from './component/SegmentBookingCalendar';
import PaymentRequestList from '../paymentRequestList/PaymentRequestList';
// import AccountReceivable from './component/AccountReceivable';
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingBooking: true,
      loadingTransactionOnHold: true,
      loadingCompleteTransaction: true,
      postTemporaryAction: {},
      disabledOnHold: true,
      modalManualPayment: false,
      listTransactionDetail: {},
      manualPaymentDemo: [],
      tourTransactionConfirmationObject: {
        CustomValue: 0,
      },
      additonalItemPay: [],
      modalPaymentGateway: false,
      openCollapse: false,
      openModalIframePaymentGateway: false,
      iframePaymentGatewayObject: {},
      balanceDetail: {
        Name: '',
        CompanyCode: '',
      },
      modalBalanceDetail: false,
      errors: {},
      loadingAdditionalItem: true,
      loadingPercentage: true,
      loadingTransactionDetail: true,
      modalConfirmation: false,
      totalAdditionalItemPay: 0,
      loadingConfirm: false,
      openConfirmAlertPaymentGateway: false,
      openModalDetailBooking: false,
      openModalSpecialAdjusment: false,
      openModalSpecialAdjusments: false,
      specialAdjusmentObject: [],
      openConfirmBooking: false,
      listTourPrice: [],
      disabledManualPayment: false,
      dateRange: {
        value: '',
        createStartDate: '',
        createEndDate: '',
        tourStartDate: moment(new Date()).format('YYYY-MM-DD'),
        tourEndDate: moment()
          .add(30, 'd')
          .format('YYYY-MM-DD'),
      },
      isChooseSendEmail: false,
      isSendMail: false,
      openModalCancelBooking: false,
      openSendManualEmail: false,
      loadingPostCancel: false,
      CancellationReasonId: null,
      CancellationReasonNote: '',
      isReloadSegmentBookingCalendar: false,
    };
  }
  static propTypes = {
    getTransactionByStatusActionAfterDPAction: PropTypes.func,
    getTransactionByStatusCreatedAction: PropTypes.func,
    getTransactionByStatusOnHoldAction: PropTypes.func,
    resetStatusTransactionAction: PropTypes.func,

    getTransactionByIdAction: PropTypes.func,
    TransactionDetailsStatus: PropTypes.string,
    TransactionDetails: PropTypes.array,
    getPayableSupplemetItemAction: PropTypes.func,
    postDemoManualPaymentAction: PropTypes.func,
    postDemoManualPaymentStatus: PropTypes.string,
    postDemoManualPayment: PropTypes.array,
    errorsTourTransaction: PropTypes.string,
    getPayableSupplemetItemStatus: PropTypes.string,
    getPayableSupplemetItem: PropTypes.array,
    getPaymentTermByTransactionIdAction: PropTypes.func,
    getTransactionPaymentTermByTransactionIdStatus: PropTypes.string,
    postConfirmTransaction: PropTypes.func,
    getSpecialAdjusmentByTransactionIdAction: PropTypes.func,
    getPaymentMethodChoiceListAction: PropTypes.func,
    getCustomerProfileByCodeStatus: PropTypes.string,
    getCustomerProfileByCode: PropTypes.array,
    resetCustomerProfileAction: PropTypes.func,
    postTopUpUsingPaymentGatewayStatus: PropTypes.string,
    postTopUpUsingPaymentGateway: PropTypes.array,
    resetCustomEnumAction: PropTypes.func,
    errorMessageTopUp: PropTypes.string,
    postTransactionConfirmationStatus: PropTypes.string,
    errorMessageTourTransaction: PropTypes.array,
    getSpecialAdjusmentByTransactionIdStatus: PropTypes.string,
    getSpecialAdjusmentByTransactionId: PropTypes.array,
    getPaymentMethodByCountryIdStatus: PropTypes.string,
    getPaymentMethodByCountryId: PropTypes.array,
    getTourTransactionBillsStatus: PropTypes.string,
    getTourTransactionBills: PropTypes.array,
    getTourTransactionBilledStatus: PropTypes.string,
    getTourTransactionBilled: PropTypes.array,
    postBalanceDetailStatus: PropTypes.string,
    errorMessage: PropTypes.string,
    getCustomerProfileByCodeAction: PropTypes.func,
    getPaymentMethodByCountryIdAction: PropTypes.func,
    getTourTransactioBillsAction: PropTypes.func,
    getTourTransactionBilledAction: PropTypes.func,
    postBalanceDetailAction: PropTypes.func,
    postTopUpPaymentGatewayAction: PropTypes.func,

    postSpecialAdjusmentStatus: PropTypes.string,
    editSpecialAdjusmentStatus: PropTypes.string,
    getCurrenciesAction: PropTypes.func,
    deleteSpecialAdjusmentAction: PropTypes.func,
    postAdditionalItems: PropTypes.func,
    editSpecialAdjsumentAction: PropTypes.func,
    currencies: PropTypes.array,
    getScheduleTourTransactionStatus: PropTypes.string,
    getScheduleTourTransaction: PropTypes.string,
    getScheduleTourTransactionAction: PropTypes.func,
    getInvoiceByTransactionIdStatus: PropTypes.string,
    getInvoiceByTransactionId: PropTypes.string,
    getInvoiceByTransactionIdAction: PropTypes.func,

    getAccountManagerAction: PropTypes.func,
    resetErrorMessageAction: PropTypes.func,
    postEmailSendConfirmation: PropTypes.func,
    getAdditionalItemTypeAction: PropTypes.func,
    getAdditionalItemTypeList: PropTypes.array,
  };
  handleClose = () =>
    this.setState({
      openModalIframePaymentGateway: false,
      modalPaymentGateway: false,
      modalBalanceDetail: false,
      modalConfirmation: false,
      modalManualPayment: false,
      openModalDetailBooking: false,
    });
  handleCloseIframe = () => {
    this.setState({
      openModalIframePaymentGateway: false,
      modalPaymentGateway: false,
      modalBalanceDetail: false,
      loadingAdditionalItem: true,
      loadingPercentage: true,
      loadingTransactionDetail: true,
      modalManualPayment: false,
    });
  };
  handleCloseConfirmAlertPaymentGateway = () => {
    this.setState({
      openConfirmAlertPaymentGateway: false,
    });
  };
  handleCloseDetailBooking = () => {
    this.setState({
      openModalDetailBooking: false,
      loadingAdditionalItem: true,
      loadingPercentage: true,
      loadingTransactionDetail: true,
      loadingBooking: false,
    });
  };
  handleCloseSpecialAdjusment = () => {
    this.setState({
      openModalSpecialAdjusment: false,
    });
  };
  handleCloseSpecialAdjusments = () => {
    this.setState({ openModalSpecialAdjusments: false });
  };

  handleCloseConfirmBooking = () =>
    this.setState({
      openConfirmBooking: false,
      isChooseSendEmail: false,
      isSendMail: false,
    });

  componentDidMount = () => {
    const getToken = localStorage.getItem('tokenadmin');
    const getCode = localStorage.getItem('code');
    let sales = getCode !== '';
    let operation = getCode !== '';
    let finance = getCode !== '';
    let financeAP = getCode !== '';
    let financeAR = getCode !== '';
    this.props.resetStatusTransactionAction();
    this.props.resetErrorMessageAction();
    if (getToken) {
      if (!sales && !operation && !finance && !financeAP && !financeAR) {
        this.props.getPaymentMethodChoiceListAction();
        this.props.getCurrenciesAction();
        this.props.getAdditionalItemTypeAction();
      }
    }
  };

  async loadDataWithDate(
    createStartDate,
    createEndDate,
    tourStartDate,
    tourEndDate,
    status
  ) {
    const context = this;
    if (status === 'After_DP') {
      this.setState({ loadingBooking: true });
      await context.props.getTransactionByStatusActionAfterDPAction(
        tourStartDate,
        tourEndDate,
        createStartDate,
        createEndDate,
        status
      );
    } else if (status === 'Booking_hold') {
      this.setState({ loadingTransactionOnHold: true });
      await context.props.getTransactionByStatusOnHoldAction(
        tourStartDate,
        tourEndDate,
        createStartDate,
        createEndDate,
        status
      );
    } else if (status === 'Booking_created_only') {
      this.setState({ loadingDownPayment: true });
      await context.props.getTransactionByStatusCreatedAction(
        tourStartDate,
        tourEndDate,
        createStartDate,
        createEndDate,
        status
      );
    } else if (status === 'PaymentHistory') {
      await context.props.getPaymentHistoryAction(
        tourStartDate,
        tourEndDate,
        createStartDate,
        createEndDate
      );
    }
  }

  handleCloseModalPayment = () => {
    this.setState({
      modalManualPayment: false,
      contentMessage: '',
      loadingAdditionalItem: true,
      loadingPercentage: true,
      loadingTransactionDetail: true,
      loadingBooking: false,
    });
  };
  componentDidUpdate() {
    if (this.props.TransactionDetailsStatus === 'success') {
      this.setStateTransactionDetail(this.props.TransactionDetails);
      this.setState({
        loadingTransactionDetail: false,
        loadingModalManualPayment: false,
      });
      this.props.resetStatusTransactionAction();
    } else if (this.props.TransactionDetailsStatus === 'failed') {
      this.setState({
        headerAlert: ' Failed',
        contentAlert: ' Detailed transaction failed to load',
        modalConfirmation: true,
      });
      this.props.resetStatusTransactionAction();
    }
    if (this.props.postDemoManualPaymentStatus === 'success') {
      this.setState({
        manualPaymentDemo: this.props.postDemoManualPayment,
        disabledManualPayment: false,
      });
      this.props.resetStatusTransactionAction();
    } else if (this.props.postDemoManualPaymentStatus === 'failed') {
      this.setState({
        contentMessageManualPayment: this.props.errorsTourTransaction.global,
        disabledManualPayment: true,
        modalConfirmation: true,
        headerAlert: 'Failed',
        contentAlert: this.props.errorsTourTransaction.global,
        modalManualPayment: false,
      });
      this.props.resetStatusTransactionAction();
    }
    if (this.props.getPayableSupplemetItemStatus === 'success') {
      this.setAdditionalInfoPrice(this.props.getPayableSupplemetItem);
      this.setState({
        disabledManualPayment: false,
        additonalItemPay: this.props.getPayableSupplemetItem,
        loadingAdditionalItem: false,
      });
      this.props.resetStatusTransactionAction();
    } else if (this.props.getPayableSupplemetItemStatus === 'failed') {
      this.setState({
        contentMessageManualPayment: this.props.errorsTourTransaction.global,
        disabledManualPayment: true,
      });
      this.props.resetStatusTransactionAction();
    }
    if (
      this.props.getTransactionPaymentTermByTransactionIdStatus === 'success'
    ) {
      this.setState({
        loadingPercentage: false,
        disabledManualPayment: false,
      });
      this.props.resetStatusTransactionAction();
    } else if (
      this.props.getTransactionPaymentTermByTransactionIdStatus === 'failed'
    ) {
      this.setState({
        contentMessageManualPayment: this.props.errorsTourTransaction.global,
        disabledManualPayment: true,
      });
      this.props.resetStatusTransactionAction();
    }

    if (this.props.getCustomerProfileByCodeStatus === 'success') {
      this.setStateBalanceDetail(this.props.getCustomerProfileByCode);
      this.setState({ loadingBalanceDetail: false });
      this.props.resetCustomerProfileAction();
    } else if (this.props.getCustomerProfileByCodeStatus === 'failed') {
      this.setState({
        failedBalanceDetail: true,
        loadingBalanceDetail: false,
      });
      this.props.resetCustomerProfileAction();
    }
    if (this.props.postTopUpUsingPaymentGatewayStatus === 'success') {
      this.setState({
        openModalIframePaymentGateway: true,
        iframePaymentGatewayObject: this.props.postTopUpUsingPaymentGateway,
      });
      this.props.resetCustomEnumAction();
    } else if (this.props.postTopUpUsingPaymentGatewayStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorMessageTopUp,
        modalConfirmation: true,
        openModalIframePaymentGateway: false,
      });
      this.props.resetCustomEnumAction();
    }
    if (this.props.postTransactionConfirmationStatus === 'success') {
      const { dateRange } = this.state;
      if (
        dateRange.value === 'PayDownPayment' ||
        dateRange.value === 'Booking_hold' ||
        dateRange.value === 'After_DP'
      ) {
        this.loadDataWithDate(
          dateRange.createStartDate !== ''
            ? convertDateFormat(dateRange.createStartDate, 'YYYY-MM-DD')
            : '',
          dateRange.createEndDate !== ''
            ? convertDateFormat(dateRange.createEndDate, 'YYYY-MM-DD')
            : '',
          dateRange.tourStartDate !== ''
            ? convertDateFormat(dateRange.tourStartDate, 'YYYY-MM-DD')
            : '',
          dateRange.tourEndDate !== ''
            ? convertDateFormat(dateRange.tourEndDate, 'YYYY-MM-DD')
            : '',
          dateRange.value === 'PayDownPayment'
            ? 'Booking_created_only'
            : dateRange.value === 'Booking_hold'
            ? 'Booking_hold'
            : 'After_DP'
        );
      } else {
        this.loadDataWithDate(
          dateRange.createStartDate !== ''
            ? convertDateFormat(dateRange.createStartDate, 'YYYY-MM-DD')
            : '',
          dateRange.createEndDate !== ''
            ? convertDateFormat(dateRange.createEndDate, 'YYYY-MM-DD')
            : '',
          dateRange.tourStartDate !== ''
            ? convertDateFormat(dateRange.tourStartDate, 'YYYY-MM-DD')
            : '',
          dateRange.tourEndDate !== ''
            ? convertDateFormat(dateRange.tourEndDate, 'YYYY-MM-DD')
            : '',
          'Booking_created_only'
        );
        this.loadDataWithDate(
          dateRange.createStartDate !== ''
            ? convertDateFormat(dateRange.createStartDate, 'YYYY-MM-DD')
            : '',
          dateRange.createEndDate !== ''
            ? convertDateFormat(dateRange.createEndDate, 'YYYY-MM-DD')
            : '',
          dateRange.tourStartDate !== ''
            ? convertDateFormat(dateRange.tourStartDate, 'YYYY-MM-DD')
            : '',
          dateRange.tourEndDate !== ''
            ? convertDateFormat(dateRange.tourEndDate, 'YYYY-MM-DD')
            : '',

          'Booking_hold'
        );
        this.loadDataWithDate(
          dateRange.createStartDate !== ''
            ? convertDateFormat(dateRange.createStartDate, 'YYYY-MM-DD')
            : '',
          dateRange.createEndDate !== ''
            ? convertDateFormat(dateRange.createEndDate, 'YYYY-MM-DD')
            : '',
          dateRange.tourStartDate !== ''
            ? convertDateFormat(dateRange.tourStartDate, 'YYYY-MM-DD')
            : '',
          dateRange.tourEndDate !== ''
            ? convertDateFormat(dateRange.tourEndDate, 'YYYY-MM-DD')
            : '',

          'After_DP'
        );
      }
      this.setState({
        headerAlert: 'Success',
        contentAlert:
          'Successfully ' +
          (this.state.confirmBooking
            ? this.state.confirmBooking.Confirmation
            : '') +
          ' Transaction',
        modalConfirmation: true,
        loadingConfirm: false,
        modalManualPayment: false,
        openModalDetailBooking: false,
      });
      this.props.resetStatusTransactionAction();
    } else if (this.props.postTransactionConfirmationStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorMessageTourTransaction,
        modalConfirmation: true,
        openModalIframePaymentGateway: false,
        loadingConfirm: false,
        loadingBooking: false,
        loadingTransactionOnHold: false,
      });
      this.props.resetStatusTransactionAction();
    }
    if (this.props.getSpecialAdjusmentByTransactionIdStatus === 'success') {
      this.setState({
        specialAdjusmentObject: convertAfterGetSpecialAdjustment(
          this.props.getSpecialAdjusmentByTransactionId,
          this.state.listTransactionDetail.Id
        ),
      });

      this.props.resetStatusTransactionAction();
    } else if (
      this.props.getSpecialAdjusmentByTransactionIdStatus === 'failed'
    ) {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: 'Failed Load Special Adjusment',
        modalConfirmation: true,
      });
      this.props.resetStatusTransactionAction();
    }
    if (this.props.getPaymentMethodByCountryIdStatus === 'success') {
      this.setState({
        paymentMethodByCountryId: this.props.getPaymentMethodByCountryId.map(
          p => p.PaymentGateawayChoice
        ),
      });
      this.props.resetCustomEnumAction();
    } else if (this.props.getPaymentMethodByCountryIdStatus === 'failed') {
      this.props.resetCustomEnumAction();
    }
    if (this.props.getTourTransactionBillsStatus === 'success') {
      const transactionBills = this.props.getTourTransactionBills[
        this.props.getTourTransactionBills.length - 1
      ];
      this.setState({
        tourTransactionConfirmationObject: {
          ...this.state.tourTransactionConfirmationObject,

          tourTransactionBills: transactionBills.RestOfTheBill,
        },
      });
      this.props.resetStatusTransactionAction();
    } else if (this.props.getTourTransactionBillsStatus === 'failed') {
      this.props.resetStatusTransactionAction();
    }
    if (this.props.getTourTransactionBilledStatus === 'success') {
      this.setState({
        totalPayableValue: this.props.getTourTransactionBilled.RestOfTheBill
          ? this.props.getTourTransactionBilled.RestOfTheBill
          : 0,
        tourTransactionConfirmationObject: {
          ...this.state.tourTransactionConfirmationObject,

          tourTransactionBilled: this.props.getTourTransactionBilled
            .RestOfTheBill
            ? this.props.getTourTransactionBilled.RestOfTheBill
            : 0,
          tourTransactionBilledStatic: this.props.getTourTransactionBilled
            .RestOfTheBill
            ? this.props.getTourTransactionBilled.RestOfTheBill
            : 0,
        },
      });
      this.props.resetStatusTransactionAction();
    } else if (this.props.getTourTransactionBilledStatus === 'failed') {
      this.props.resetStatusTransactionAction();
    }
    if (this.props.postBalanceDetailStatus === 'success') {
      this.setState({
        modalBalanceDetail: false,
        headerAlert: 'Success',
        contentAlert: 'Save Balance Succesfully',
        modalConfirmation: true,
        loadingBooking: false,
      });
      const { listTransactionDetail } = this.state;
      this.props.postDemoManualPaymentAction(listTransactionDetail);
      this.props.resetCustomerProfileAction();
    } else if (this.props.postBalanceDetailStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorMessage,
        modalConfirmation: true,
        modalBalanceDetail: false,
        loadingBooking: false,
      });
      this.props.resetCustomerProfileAction();
    }

    if (
      this.props.postSpecialAdjusmentStatus === 'success' ||
      this.props.editSpecialAdjusmentStatus === 'success'
    ) {
      this.setState({
        openModalSpecialAdjusment: false,
        openModalSpecialAdjusments: false,
        headerAlert: 'Success',
        contentAlert: 'Save Special Adjusment Succesfully',
        loadingSpecialAdjusment: false,
      });
      this.props.resetStatusTransactionAction();
    } else if (
      this.props.postSpecialAdjusmentStatus === 'failed' ||
      this.props.editSpecialAdjusmentStatus === 'failed'
    ) {
      this.setState({
        // openModalSpecialAdjusment: false,
        headerAlert: 'Failed',
        contentAlert: this.props.errorMessageTourTransaction,
        loadingSpecialAdjusment: false,
      });
      this.props.resetStatusTransactionAction();
    }
    if (this.props.getScheduleTourTransactionStatus === 'success') {
      this.handleDownloadFile(this.props.getScheduleTourTransaction);
      this.setState({ loadingSpecialAdjusment: false });
      this.props.resetStatusTransactionAction();
    } else if (this.props.getScheduleTourTransactionStatus === 'failed') {
      this.setState({
        modalConfirmation: true,
        headerAlert: 'Failed',
        contentAlert: this.props.errorMessageTourTransaction,
      });
      this.props.resetStatusTransactionAction();
    }
    if (this.props.getInvoiceByTransactionIdStatus === 'success') {
      this.handleDownloadFile(this.props.getInvoiceByTransactionId);
      this.setState({ loadingInvoice: false });
      this.props.resetStatusTransactionAction();
    } else if (this.props.getInvoiceByTransactionIdStatus === 'failed') {
      this.setState({
        modalConfirmation: true,
        headerAlert: 'Failed',
        contentAlert: this.props.errorMessageTourTransaction,
      });
      this.props.resetStatusTransactionAction();
    }
  }

  setAdditionalInfoPrice = data => {
    let total = data.reduce((total, num) => {
      return total + num.MinimumPayable;
    }, 0);
    return this.setState({
      TotalPayableAdditionalItem: total,
    });
  };

  setStateTransactionDetail = data => {
    const { BookingDetailSum, TourPriceSum } = data;
    let manualPaymentObject = setManualPaymentObject(BookingDetailSum);
    this.setState({
      listTransactionDetail: manualPaymentObject,
      listTourPrice: SetTourPrice(TourPriceSum),
    });
    this.props.postDemoManualPaymentAction(manualPaymentObject);
    this.props.getPaymentTermByTransactionIdAction(BookingDetailSum.Id);
    this.props.getCustomerProfileByCodeAction(
      BookingDetailSum.CreatedBy.CompanyCode
    );
    this.props.getSpecialAdjusmentByTransactionIdAction(BookingDetailSum.Id);
    this.props.getPaymentMethodChoiceListAction();
    this.props.getPaymentMethodByCountryIdAction(BookingDetailSum.Country.Id);
    this.props.getTourTransactioBillsAction(BookingDetailSum.Id);
    this.props.getTourTransactionBilledAction(BookingDetailSum.Id);
    this.getPayable();
  };

  bookingAction = (e, { name, value, id }) => {
    let keys = [
      'Booking_DP_Confirmed',
      'Booking_In_Payment',
      'Booking_created_only',
      'Booking_created',
      'Booking_Confirmed',
    ];
    if (name === 'Cancel') {
      let data = {
        Id: id,
        Confirmation: name,
        PaymentChoice: 0,
      };
      if (value === 'Booking_hold') {
        this.setState({
          confirmBooking: data,
          // openConfirmBooking: true,
          contentAlertBooking:
            'Are you sure to cancel this booking id : ' + id + '?',
          statusTransaction: 'Hold',
          isChooseSendEmail: true,
          openModalCancelBooking: true,
        });
      } else if (value === 'Booking_created_only') {
        this.setState({
          confirmBooking: data,
          // openConfirmBooking: true,
          contentAlertBooking:
            'Are you sure to cancel this booking id : ' + id + '?',
          statusTransaction: 'PayDownPayment',
          openModalCancelBooking: true,
        });
      } else {
        this.setState({
          confirmBooking: data,
          // openConfirmBooking: true,
          contentAlertBooking:
            'Are you sure to cancel this booking id : ' + id + '?',
          statusTransaction: '',
          openModalCancelBooking: true,
        });
      }
    } else {
      let matching = keys.find(values => values === value);
      if (value === matching) {
        this.props.getTransactionByIdAction(id);
        this.props.getPayableSupplemetItemAction(id);
        this.setState({
          postTemporaryAction: {
            Id: id,
            Confirmation: name,
            SupplementPayment: [],
            PaymentChoice: 'Deposit',
            UpdateGuest: null,
          },
          modalManualPayment: true,
          openModalDetailBooking: false,
          contentMessageManualPayment: '',

          manualPaymentDemo: [],

          tourTransactionConfirmationObject: {
            CustomValue: 0,
          },
          additionalItemPay: [],
          payTransactionBy: value,
        });
      } else {
        if (name === 'ApproveAll') {
          this.setState({
            statusTransaction: 'Hold',
            // openConfirmBooking: true,
            contentAlertBooking:
              'Are you sure to approve all booking on hold ?',
            onHoldStatus: value,
            isChooseSendEmail: true,
            openSendManualEmail: true,
          });
        } else if (name === 'RejectedAll') {
          this.setState({
            statusTransaction: 'Hold',
            // openConfirmBooking: true,
            contentAlertBooking: 'Are you sure to reject all booking on hold ?',
            onHoldStatus: value,
            isChooseSendEmail: true,
            openModalCancelBooking: true,
          });
        } else {
          let data = {
            Id: id,
            Confirmation: name,
            PaymentChoice: 0,
          };
          this.setState({
            statusTransaction: 'Hold',
            confirmBooking: data,
            // openConfirmBooking: true,
            contentAlertBooking:
              'Are you sure to approve this booking id :  ' + id + '?',
            isChooseSendEmail: true,
            openSendManualEmail: true,
          });
        }
      }
    }
    this.setState({ additonalItemPay: [] });
  };

  setTransactionOnHoldList = data => {
    this.setState({ transactionOnHold: data });
  };

  confirmBooking = () => {
    let {
      confirmBooking,
      statusTransaction,
      onHoldStatus,
      transactionOnHold,
    } = this.state;
    if (confirmBooking) {
      confirmBooking.CancellationReasonId = this.state.CancellationReasonId;
      confirmBooking.CancellationReasonNote = this.state.CancellationReasonNote;
    }
    this.setState({ loadingPostCancel: true });
    if (statusTransaction === 'Hold') {
      if (onHoldStatus === 'ApproveAll') {
        this.setState({
          openConfirmBooking: false,
          loadingTransactionOnHold: true,
          openSendManualEmail: false,
        });
        this.confirmOnHold(transactionOnHold, 'Approve');
      } else if (onHoldStatus === 'RejectedAll') {
        this.setState({
          openConfirmBooking: false,
          loadingTransactionOnHold: true,
          openSendManualEmail: false,
        });
        this.confirmOnHold(transactionOnHold, 'Cancel'); //Reject
      } else {
        this.setState({
          openConfirmBooking: false,
          loadingTransactionOnHold: true,
          loadingConfirm: true,
          openSendManualEmail: false,
        });
        this.props.postConfirmTransaction(confirmBooking).then(() => {
          this.setState({
            loadingPostCancel: false,
            openModalCancelBooking: false,
          });
          if (this.state.isSendMail) {
            this.props.postEmailSendConfirmation({
              tourTransactionId: confirmBooking.Id,
              emailSendConfirmed: true,
            });
          }
        });
      }
    } else if (statusTransaction === 'PayDownPayment') {
      this.setState({
        openConfirmBooking: false,
        openSendManualEmail: false,
      });
      this.props.postConfirmTransaction(confirmBooking).then(() => {
        this.setState({
          loadingPostCancel: false,
          openModalCancelBooking: false,
        });
        if (this.state.isSendMail) {
          this.props.postEmailSendConfirmation({
            tourTransactionId: confirmBooking.Id,
            emailSendConfirmed: true,
          });
        }
      });
    } else {
      this.setState({
        openConfirmBooking: false,
        loadingBooking: true,
        openSendManualEmail: false,
      });
      this.props.postConfirmTransaction(confirmBooking).then(() => {
        this.setState({
          loadingPostCancel: false,
          openModalCancelBooking: false,
        });
        if (this.state.isSendMail) {
          this.props.postEmailSendConfirmation({
            tourTransactionId: confirmBooking.Id,
            emailSendConfirmed: true,
          });
        }
      });
    }
  };

  handleSubmitTourTransactionConfirmation = () => {
    const {
      additonalItemPay,
      manualPaymentDemo,
      payTransactionBy,
      totalPayableValue,
    } = this.state;

    const balance = manualPaymentDemo.CompanyBalance;
    let data = setConfirmTransactionData(
      additonalItemPay,
      totalPayableValue,
      this.state.listTransactionDetail.Id
    );
    if (
      totalPayableValue === '0' ||
      totalPayableValue <= 0 ||
      totalPayableValue === ''
    ) {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: 'Amount invalid',
        modalConfirmation: true,
      });
    } else if (totalPayableValue <= balance) {
      if (payTransactionBy === 'Booking_created_only') {
        this.setState({
          statusTransaction: 'PayDownPayment',
          loadingConfirm: true,
        });
        this.props.postConfirmTransaction(data);
      } else {
        this.setState({ loadingConfirm: true });
        this.props.postConfirmTransaction(data);
      }
    } else if (balance < totalPayableValue) {
      this.openModalBalanceDetail();
    } else {
      if (payTransactionBy === 'Booking_created_only') {
        this.setState({
          statusTransaction: 'PayDownPayment',
        });
        this.props.postConfirmTransaction(data);
      } else {
        this.setState({ loadingConfirm: true });
        this.props.postConfirmTransaction(data);
      }
    }
  };
  collapseButton = () => {
    this.setState({ openCollapse: !this.state.openCollapse });
  };
  setStateBalanceDetail = data => {
    const { Code, Currency, Name, Balance } = data;
    let balanceDetail = {
      CompanyCode: Code ? Code : '',
      CashFlowType: 'Credit',
      CurrencyId: Currency ? Currency : '',
      Value: 0,
      Reference: '',
      Description: '',
      PaymentMethod: 0,
      Name: Name ? Name : '',
      Balance: Balance ? Balance : 0,
      DateTime: moment(new Date()).format('YYYY-MM-DD'),
    };
    this.setState({ balanceDetail: balanceDetail });
  };
  openModalBalanceDetail = () => {
    const {
      tourTransactionConfirmationObject,
      manualPaymentDemo,
      totalMandatory,
    } = this.state;
    const manual = ['Deposit'];
    const paymentGateway = this.state.paymentMethodByCountryId;
    let filteredKeywords = paymentGateway.filter(
      word => !manual.includes(word)
    );

    const Payable =
      tourTransactionConfirmationObject.tourTransactionBilled + totalMandatory;
    const CompanyBalance = manualPaymentDemo.CompanyBalance;
    let Amount = 0;
    if (Payable > CompanyBalance) {
      Amount = Payable - CompanyBalance;
    } else {
      Amount = 0;
    }
    this.props.getCurrenciesAction();
    this.setState({
      modalBalanceDetail: true,
      listPaymentgateway: filteredKeywords.map(type => {
        return {
          name: type,
          Id: type === 'Deposit' ? 0 : 1,
        };
      }),
      balanceDetail: {
        ...this.state.balanceDetail,
        Value: Amount,
        DateTime: moment(new Date()).format('YYYY-MM-DD'),
      },
    });
  };
  handleCloseModal = () => this.setState({ modalBalanceDetail: false });
  handleChangeBalaceDetail = (e, { value, name }) => {
    const balanceDetail = this.state.balanceDetail;
    balanceDetail[name] = value;
    this.setState({ balanceDetail: balanceDetail });
  };
  saveBalanceDetail = (e, { name, id, value }) => {
    const err = this.validate();
    const data = this.state.balanceDetail;
    if (!err) {
      if (name === 'ManualPayment') {
        this.props.postBalanceDetailAction(data);
      } else if (name === 'postPaymentGateway') {
        this.setState({
          openConfirmAlertPaymentGateway: true,
          contentConfirmAlert:
            'Are you sure to make payments using ' + value + ' ?',
          IdForPayment: id,
        });
      } else {
        this.setState({ modalPaymentGateway: true });
      }
    }
  };
  payUsingPaymentGateway = () => {
    const dataPaymentgateway = {
      PaymentGateway: this.state.IdForPayment,
      Amount: this.state.balanceDetail.Value,
      CompanyCode: this.state.balanceDetail.CompanyCode,
      CurrencyId: this.state.balanceDetail.CurrencyId,
    };
    this.setState({
      openConfirmAlertPaymentGateway: false,
    });
    this.props.postTopUpPaymentGatewayAction(dataPaymentgateway);
  };
  validate = () => {
    let isError = false;
    const errors = {
      ValueError: '',
    };
    if (this.state.balanceDetail.Value <= 0) {
      isError = true;
      errors.ValueError = 'Amount invalid';
    }
    this.setState({
      errors: {
        ...errors,
      },
    });
    return isError;
  };

  buttonOpenModalDetailBooking = (e, { id }) => {
    this.props.getTransactionByIdAction(id);
    this.props.getPayableSupplemetItemAction(id);
    this.setState({ openModalDetailBooking: true });
  };
  buttonOpenModalSpecialAdjusments = (e, { id, name, value }) => {
    this.setState({
      openModalSpecialAdjusments: true,
      listTransactionDetail: {
        ...this.state.listTransactionDetail,
        Id: id,
        Customer: name,
        Status: value.split('_').join(' '),
      },
    });
    this.props.getSpecialAdjusmentByTransactionIdAction(id);
  };
  // addNewSpecialAjusment = (e, { id }) => {
  //   this.props.getCurrenciesAction();
  //   let specialAdjusmentObject = this.state.specialAdjusmentObject;
  //   let data = {
  //     CurrencyId: '',
  //     Description: '',
  //     Qty: 0,
  //     TourTransactionId: id,
  //     UnitCost: 0,
  //     TotalCost: '',
  //     IsHiddenData: false,
  //   };
  //   specialAdjusmentObject.push(data);
  //   this.setState({ specialAdjusmentObject: specialAdjusmentObject });
  // };
  handleSpecialAdjustment = specialAdjusmentObject => {
    this.setState({
      ...this.state,
      specialAdjusmentObject,
    });
  };
  handleChangeSpecialAdjusment = (e, { value, name }) => {
    let index = name.split(',')[1];
    let newName = name.split(',')[0];
    let specialAdjusmentObject = this.state.specialAdjusmentObject;
    specialAdjusmentObject[index] = {
      ...specialAdjusmentObject[index],
      [newName]: value,
    };
    if (newName === 'Qty') {
      specialAdjusmentObject[index] = {
        ...specialAdjusmentObject[index],
        TotalCost: specialAdjusmentObject[index].UnitCost * value,
      };
    }
    this.setState({
      specialAdjusmentObject: specialAdjusmentObject,
    });
  };
  handleChangeCostSpecialAdjusment = (value, i, name) => {
    let specialAdjusmentObject = this.state.specialAdjusmentObject;
    specialAdjusmentObject[i] = { ...specialAdjusmentObject[i], [name]: value };
    this.setState({
      specialAdjusmentObject: specialAdjusmentObject,
      TotalCost: value * specialAdjusmentObject[i].Qty,
      UnitCost: value,
    });
  };

  deleteSpecialAdjusment = (e, { name, value }) => {
    let specialAdjusmentObject = this.state.specialAdjusmentObject;
    specialAdjusmentObject.splice(name, 1);
    this.setState({
      specialAdjusmentObject: specialAdjusmentObject,
      loadingSpecialAdjusment: true,
    });
    if (value > 0) {
      this.props
        .deleteSpecialAdjusmentAction(value)
        .then(() => this.setState({ loadingSpecialAdjusment: false }))
        .catch(() => this.setState({ loadingSpecialAdjusment: false }));
    }
  };
  saveSpecialAdjusment = async () => {
    const { specialAdjusmentObject } = this.state;
    await this.setState({ loadingSpecialAdjusment: true });
    this.props
      .postAdditionalItems(
        convertDataPostSpecialAdjustment(specialAdjusmentObject)
      )
      .then(() =>
        this.setState({
          ...this.state,
          openModal: true,
          headerAlert: 'Success',
          contentAlert: 'Success save special adjustment',
          loadingSpecialAdjusment: false,
          openModalSpecialAdjusments: false,
        })
      )
      .catch(() =>
        this.setState({
          ...this.state,
          openModal: true,
          headerAlert: 'Failed',
          contentAlert: 'Failed save special adjustment',
          loadingSpecialAdjusment: false,
          openModalSpecialAdjusments: false,
        })
      );
    // specialAdjusmentObject.map(data => {
    //   if (listTransactionDetail.Id !== undefined && data.Id === undefined) {
    //     this.props.postAdditionalItems({
    //       ...data,
    //       TourTransactionId: listTransactionDetail.Id,
    //       UnitCost: data.UnitCost,
    //       TotalCost: data.UnitCost * data.Qty,
    //       IsHiddenData: data.IsHiddenData,
    //     });
    //   } else {
    //     this.props.editSpecialAdjsumentAction({
    //       ...data,
    //       TourTransactionId: listTransactionDetail.Id,
    //       UnitCost: data.UnitCost,
    //       TotalCost: data.UnitCost * data.Qty,
    //       IsHiddenData: data.IsHiddenData,
    //     });
    //   }
    //   return data;
    // });
  };

  handlePrintSchedule = (e, { id }) => {
    this.setState({ loadingSpecialAdjusment: true });
    this.props.getScheduleTourTransactionAction(id);
  };

  handleDownloadInvoice = (e, { id }) => {
    this.setState({ loadingInvoice: true });
    this.props.getInvoiceByTransactionIdAction(id);
  };

  handleDownloadFile = dataUrl => {
    window.open(dataUrl);
  };
  onChangeAmount = (value, name) => {
    const balanceDetail = this.state.balanceDetail;
    balanceDetail[name] = value;
    this.setState({ balanceDetail: balanceDetail });
  };

  confirmOnHold = async (transactionOnHold, value) => {
    const { isSendMail } = this.state;
    let data = {};
    const context = this.props;
    const those = this;
    transactionOnHold.forEach(async function(onHold) {
      if (onHold.checkHold) {
        data = {
          Id: onHold.Id,
          Confirmation: value,
          PaymentChoice: 0,
          CancellationReasonId: those.state.CancellationReasonId,
          CancellationReasonNote: those.state.CancellationReasonNote,
        };
        await context.postConfirmTransaction(data).then(() => {
          those.setState({
            loadingPostCancel: false,
            openModalCancelBooking: false,
          });
          if (isSendMail) {
            context.postEmailSendConfirmation({
              tourTransactionId: onHold.Id,
              emailSendConfirmed: true,
            });
          }
        });
      }
    });
  };

  handleSetDateRange = data => {
    this.setState({ dateRange: data });
  };

  handleSendManualEmail = value => {
    this.setState({ isSendMail: value === 1 ? true : false });
  };

  // ------ cancellation reason
  handleSaveCancellationReason = data => {
    this.setState({
      CancellationReasonId: data.CancellationReasonId,
      CancellationReasonNote: data.CancellationReasonNote,
      openSendManualEmail: true,
      // openConfirmBooking: true,
    });
    // this.handleClickAction();
  };

  handleCloseCancellationReason = () => {
    this.setState({ openModalCancelBooking: false });
  };

  handleCloseManualSendEmail = () => {
    this.setState({ openSendManualEmail: false });
  };
  reloadUpcoming = value => {
    this.setState({ ...this.state, isReloadSegmentBookingCalendar: value });
  };
  renderHome = (sales, operation, finance, financeAP, financeAR) => {
    if (!sales && !operation && !finance && !financeAP && !financeAR) {
      return (
        <div>
          <SalesReport />
          {/* <AccountReceivable /> */}
          <CompanyRegistration />
          <CustomerPaymentCombine isfinance={finance} />
          <PublishPackage />
          <OnHoldTransaction
            actionTransaction={this.bookingAction}
            openDetailTransaction={this.buttonOpenModalDetailBooking}
            isChooseSendEmail={this.state.isChooseSendEmail}
            setTransactionOnHoldList={this.setTransactionOnHoldList}
            handleSetDateRange={this.handleSetDateRange}
          />
          <BookingStatusList />
          <SegmentAccommodationAvailbility isHome={true} />
          <ListOfTransactionDownPayment
            actionTransaction={this.bookingAction}
            openModalSpecialAdjusment={this.buttonOpenModalSpecialAdjusments}
            openDetailTransaction={this.buttonOpenModalDetailBooking}
            // handleClose={this.handleClose}
            handleSetDateRange={this.handleSetDateRange}
          />
          <BookingConfirmation
            actionBookingConfirmation={this.bookingAction}
            actionModalDetailBooking={this.buttonOpenModalDetailBooking}
            handleSetDateRange={this.handleSetDateRange}
          />
          <PaymentHistory />
          <SegmentCompanyCommissions />
          {/* <SegmentPaymentRequest /> */}
        </div>
      );
    } else if (operation) {
      return (
        <React.Fragment>
          <SegmentBookingCalendar
            isReloadCalendar={this.state.isReloadSegmentBookingCalendar}
            handleReloadCalendar={this.reloadUpcoming}
          />
          <OnHoldTransaction
            actionTransaction={this.bookingAction}
            openDetailTransaction={this.buttonOpenModalDetailBooking}
            isChooseSendEmail={this.state.isChooseSendEmail}
            setTransactionOnHoldList={this.setTransactionOnHoldList}
            handleSetDateRange={this.handleSetDateRange}
          />
          <ManualConfirmationNumber />
          <PendingConfirmationNumber
            reloadUpcomingForHome={this.reloadUpcoming}
          />
          <SegmentAccommodationAvailbility />
        </React.Fragment>
      );
    } else if (finance && financeAP && financeAR) {
      return (
        <React.Fragment>
          <PaymentRequestList />
          <CustomerPaymentCombine />
          <PaymentHistory />
          <SegmentCompanyCommissions />
        </React.Fragment>
      );
    } else if (finance && financeAP) {
      return (
        <React.Fragment>
          <PaymentRequestList />
        </React.Fragment>
      );
    } else if (finance && financeAR) {
      return (
        <React.Fragment>
          <CustomerPaymentCombine />
          <PaymentHistory />
          <SegmentCompanyCommissions />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <ReportSales />
          <SegmentAccommodationAvailbility />
        </React.Fragment>
      );
    }
  };

  getPayable = () => {
    const { tourTransactionConfirmationObject, totalMandatory } = this.state;
    this.setState({
      totalPayableValue:
        tourTransactionConfirmationObject.tourTransactionBilled +
        totalMandatory,
    });
  };
  handleChangePayable = value => {
    this.setState({
      totalPayableValue: value,
    });
  };

  render() {
    let {
      loadingAdditionalItem,
      loadingPercentage,
      loadingTransactionDetail,
      modalConfirmation,
      loadingConfirm,
      loadingSpecialAdjusment,
      openModalCancelBooking,
      loadingPostCancel,
    } = this.state;
    let openModal = modalConfirmation;
    let loadingModal =
      loadingAdditionalItem ||
      loadingPercentage ||
      loadingTransactionDetail ||
      loadingConfirm;
    const { getTourTransactionBilled, getPayableSupplemetItem } = this.props;
    let sales = localStorage.getItem('companyRoles')
      ? localStorage.getItem('companyRoles').indexOf('Sales') !== -1
      : false;
    let operation = localStorage.getItem('companyRoles')
      ? localStorage.getItem('companyRoles').indexOf('Operation') !== -1
      : false;
    let finance = localStorage.getItem('companyRoles')
      ? localStorage.getItem('companyRoles').indexOf('Finance Admin') !== -1
      : false;
    let financeAP = localStorage.getItem('companyRoles')
      ? localStorage.getItem('companyRoles').indexOf('FINANCE AP') !== -1
      : false;
    let financeAR = localStorage.getItem('companyRoles')
      ? localStorage.getItem('companyRoles').indexOf('FINANCE AR') !== -1
      : false;
    return (
      <div>
        <Dimmer.Dimmable blurring dimmed={false}>
          {this.renderHome(sales, operation, finance, financeAP, financeAR)}
          <ModalAlert
            openModal={openModal}
            handleClose={this.handleClose}
            header={this.state.headerAlert}
            content={this.state.contentAlert}
            nameButton="OK"
          />

          <ModalManualPayment
            open={this.state.modalManualPayment}
            handleClose={this.handleCloseModalPayment}
            contentDetail={this.state.listTransactionDetail}
            contentDetailDemoManualPayment={this.state.manualPaymentDemo}
            contentMessageManualPayment={this.state.contentMessageManualPayment}
            additionalItem={this.state.additonalItemPay}
            totalPayableAdditionalItem={this.state.TotalPayableAdditionalItem}
            disabled={this.state.disabledManualPayment}
            handleSubmitTourTransactionConfirmation={
              this.handleSubmitTourTransactionConfirmation
            }
            openModalBalanceDetail={this.openModalBalanceDetail}
            contentMessage={this.state.contentMessage}
            loadingModal={loadingModal}
            tourTransactionConfirmationObject={
              this.state.tourTransactionConfirmationObject
            }
            tourTransactionBilled={getTourTransactionBilled}
            getPayableSupplemetItemStatic={getPayableSupplemetItem}
            additionalItemPay={this.state.totalAdditionalItemPay}
            loadingButton={loadingConfirm}
            totalMandatory={this.state.totalMandatory}
            handleChangePayable={this.handleChangePayable}
            totalPayableValue={this.state.totalPayableValue}
          />

          <ModalBalanceDetail
            open={this.state.modalBalanceDetail}
            handleClose={this.handleCloseModal}
            header="Credit"
            handleChange={this.handleChangeBalaceDetail}
            contentField={this.state.balanceDetail}
            errorInformation={this.state.errors}
            saveBalanceDetail={this.saveBalanceDetail}
            contentDetailDemoManualPayment={this.state.manualPaymentDemo}
            TotalPayableAdditionalItem={this.state.TotalPayableAdditionalItem}
            contentButton="Top Up"
            currencies={this.props.currencies}
            handleChangeAmount={this.onChangeAmount}
          />
          <ModalPaymentGateway
            open={this.state.modalPaymentGateway}
            handleClose={this.handleClose}
            header="Choose Payment Gateway"
            handleChange={this.handleChangeBalaceDetail}
            saveBalanceDetail={this.saveBalanceDetail}
            listPaymentMethod={this.state.listPaymentgateway}
            openCollapse={this.state.openCollapse}
            collapseButton={this.collapseButton}
          />
          <ModalIframePaymentGateway
            open={this.state.openModalIframePaymentGateway}
            handleClose={this.handleCloseIframe}
            url={this.state.iframePaymentGatewayObject.Url}
          />
          <Confirm
            dimmer="blurring"
            open={this.state.openConfirmAlertPaymentGateway}
            onCancel={this.handleCloseConfirmAlertPaymentGateway}
            onConfirm={this.payUsingPaymentGateway}
            content={this.state.contentConfirmAlert}
            size="mini"
          />
          <ModalDetailBooking
            openModal={this.state.openModalDetailBooking}
            handleClose={this.handleCloseDetailBooking}
            header="Payment Detail"
            contentDetail={this.state.listTransactionDetail}
            getPayableSupplemetItem={getPayableSupplemetItem}
            actionBookingConfirmation={this.bookingAction}
            loadingModal={loadingModal}
            openModalSpecialAdjusment={this.buttonOpenModalSpecialAdjusments}
            handlePrintSchedule={this.handlePrintSchedule}
            handleDownloadInvoice={this.handleDownloadInvoice}
            loadingSpecialAdjusment={this.state.loadingSpecialAdjusment}
            loadingInvoice={this.state.loadingInvoice}
            getTourTransactionBills={this.props.getTourTransactionBills}
            getSpecialAdjusmentByTransactionId={
              this.props.getSpecialAdjusmentByTransactionId
            }
            listTourPrice={this.state.listTourPrice}
            Currency={this.state.listTransactionDetail.CurrencyId}
          />
          <ModalSpecialAdjusments
            openModal={this.state.openModalSpecialAdjusments}
            handleClose={this.handleCloseSpecialAdjusments}
            listData={this.state.specialAdjusmentObject}
            currencies={this.props.currencies}
            saveSpecialAdjusment={this.saveSpecialAdjusment}
            loading={loadingSpecialAdjusment}
            handleSpecialAdjustment={this.handleSpecialAdjustment}
            getAdditionalItemTypeList={this.props.getAdditionalItemTypeList}
            idTransaction={this.state.listTransactionDetail.Id}
            CurrencyId=""
            statusPackage=""
            currentPackage=""
            typePackage=""
          />
          {/* <ModalSpecialAdjusment
            openModal={this.state.openModalSpecialAdjusment}
            handleClose={this.handleCloseSpecialAdjusment}
            header="Detail Special Adjustment"
            contentDetail={this.state.listTransactionDetail}
            listData={this.state.specialAdjusmentObject}
            addNewSpecialAjusment={this.addNewSpecialAjusment}
            handleChangeSpecialAdjusment={this.handleChangeSpecialAdjusment}
            handleChangeCostSpecialAdjusment={
              this.handleChangeCostSpecialAdjusment
            }
            deleteSpecialAdjusment={this.deleteSpecialAdjusment}
            currenciesList={this.props.currencies}
            saveSpecialAdjusment={this.saveSpecialAdjusment}
            loadingModal={loadingSpecialAdjusment}
          /> */}
          <ModalConfirmTransaction
            openModal={this.state.openConfirmBooking}
            size="mini"
            close={this.handleCloseConfirmBooking}
            confirm={this.confirmBooking}
            message={this.state.contentAlertBooking}
            // isChooseSendEmail={this.state.isChooseSendEmail}
            // handleSendManualEmail={this.handleSendManualEmail}
          />
          <ModalCancellationReason
            openModal={openModalCancelBooking}
            handleSave={this.handleSaveCancellationReason}
            handleClose={this.handleCloseCancellationReason}
            loadingPostCancel={loadingPostCancel}
          />
          <ModalManualSendEmail
            openModal={this.state.openSendManualEmail}
            size="tiny"
            close={this.handleCloseManualSendEmail}
            confirm={this.confirmBooking}
            isChooseSendEmail={this.state.isChooseSendEmail}
            handleSendManualEmail={this.handleSendManualEmail}
            isSendMail={this.state.isSendMail}
          />
        </Dimmer.Dimmable>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    TransactionDetails: state.TourTransactionStore.listTransactionDetails,
    TransactionDetailsStatus:
      state.TourTransactionStore.listTransactionDetailsStatus,
    postDemoManualPaymentStatus:
      state.TourTransactionStore.postDemoManualPaymentStatus,
    postDemoManualPayment: state.TourTransactionStore.postDemoManualPayment,
    errorsTourTransaction: state.TourTransactionStore.errors,
    getPayableSupplemetItemStatus:
      state.TourTransactionStore.getPayableSupplemetItemStatus,
    getPayableSupplemetItem: state.TourTransactionStore.getPayableSupplemetItem,
    getTransactionPaymentTermByTransactionIdStatus:
      state.TourTransactionStore.getTransactionPaymentTermByTransactionIdStatus,
    listPaymentMethod: state.CustomEnumReducerStore.getPaymentChoice,
    getCustomerProfileByCode:
      state.CustomerProfileStore.getCustomerProfileByCode,
    getCustomerProfileByCodeStatus:
      state.CustomerProfileStore.getCustomerProfileByCodeStatus,
    postTopUpUsingPaymentGateway:
      state.CustomEnumReducerStore.postTopUpUsingPaymentGateway,
    postTopUpUsingPaymentGatewayStatus:
      state.CustomEnumReducerStore.postTopUpUsingPaymentGatewayStatus,
    postTransactionConfirmationStatus:
      state.TourTransactionStore.postTransactionConfirmationStatus,
    errorMessageTopUp: state.CustomEnumReducerStore.errors.message,
    getSpecialAdjusmentByTransactionId:
      state.TourTransactionStore.getSpecialAdjusmentByTransactionId,
    getSpecialAdjusmentByTransactionIdStatus:
      state.TourTransactionStore.getSpecialAdjusmentByTransactionIdStatus,
    getPaymentMethodByCountryId:
      state.CustomEnumReducerStore.getPaymentMethodByCountryId,
    getPaymentMethodByCountryIdStatus:
      state.CustomEnumReducerStore.getPaymentMethodByCountryIdStatus,
    getTourTransactionBilled:
      state.TourTransactionStore.getTourTransactionBilled,
    getTourTransactionBilledStatus:
      state.TourTransactionStore.getTourTransactionBilledStatus,
    getTourTransactionBills: state.TourTransactionStore.getTourTransactionBills,
    getTourTransactionBillsStatus:
      state.TourTransactionStore.getTourTransactionBillsStatus,
    postBalanceDetailStatus: state.CustomerProfileStore.postBalanceDetailStatus,
    errorMessage: state.CustomerProfileStore.errors.global,
    errorMessageTourTransaction: state.TourTransactionStore.errors.message,

    currencies: state.currenciesStore.currencies.map((cur, i) => {
      return { key: i, text: cur.Id, value: cur.Id };
    }),

    postSpecialAdjusmentStatus:
      state.TourTransactionStore.postSpecialAdjusmentStatus,
    editSpecialAdjusmentStatus:
      state.TourTransactionStore.editSpecialAdjusmentStatus,
    getScheduleTourTransaction:
      state.TourTransactionStore.getScheduleTourTransaction,
    getScheduleTourTransactionStatus:
      state.TourTransactionStore.getScheduleTourTransactionStatus,
    getInvoiceByTransactionIdStatus:
      state.TourTransactionStore.getInvoiceByTransactionIdStatus,
    getInvoiceByTransactionId:
      state.TourTransactionStore.getInvoiceByTransactionId,
    getAdditionalItemTypeList:
      state.AdditionalItemStore.getAdditionalItemTypeList,
  };
}

export default connect(mapStateToProps, {
  getTransactionByStatusActionAfterDPAction,
  getPaymentHistoryAction,
  resetStatusTransactionAction,
  getTransactionByStatusCreatedAction,
  getTransactionByStatusOnHoldAction,
  getTransactionByIdAction,
  postDemoManualPaymentAction,
  getPayableSupplemetItemAction,
  getPaymentTermByTransactionIdAction,
  postConfirmTransaction,
  getPaymentMethodChoiceListAction,
  getCustomerProfileByCodeAction,
  resetCustomerProfileAction,
  postTopUpPaymentGatewayAction,
  resetCustomEnumAction,
  postBalanceDetailAction,
  getSpecialAdjusmentByTransactionIdAction,
  getPaymentMethodByCountryIdAction,
  getTourTransactioBillsAction,
  getTourTransactionBilledAction,
  getCurrenciesAction,
  postAdditionalItems,
  editSpecialAdjsumentAction,
  deleteSpecialAdjusmentAction,
  getScheduleTourTransactionAction,
  getInvoiceByTransactionIdAction,
  getAccountManagerAction,
  resetErrorMessageAction,
  postEmailSendConfirmation,
  getAdditionalItemTypeAction,
})(Home);
