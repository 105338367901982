import React from 'react';
import { Dimmer, Confirm } from 'semantic-ui-react';
import { connect } from 'react-redux';
import Loader from '../../components/loader';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  resetCustomerProfileAction,
  getBalanceHistoryByCompanyCodeAction,
  getCustomerProfileByCodeAction,
  postBalanceDetailAction,
} from '../shared/actions/customerProfileAction';
import {
  postTopUpPaymentGatewayAction,
  resetCustomEnumAction,
  getPaymentMethodByCountryIdAction,
} from '../shared/actions/customEnumAction';
import { getCurrenciesAction } from '../../actions/currenciesAction';
import BalanceDetail from '../shared/components/balanceDetail/BalanceDetailForm';
import ModalBalanceDetail from '../shared/components/modal/ModalBalanceDetail';
import ModalPaymentGateway from '../shared/components/modal/ModalPaymentGateway';
import ModalIframePaymentGateway from '../shared/components/modal/ModalIframePaymentGateway';
import ModalAlert from '../shared/components/modal/ModalAlert';
class BalanceDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      codeBalance: 0,
      balanceDetail: {},
      historyTransaction: [],
      headerModalBalanceDetail: '',
      loadingBalanceDetail: true,
      failedBalanceDetail: false,
      loadingHistoryTransaction: true,
      failedHistoryTransaction: false,
      modalBalanceDetail: false,
      modalAlert: false,
      contentAlert: '',
      modalConfirmation: false,
      postTemporaryAction: {},
      errors: {},
      modalPaymentGateway: false,
      paymentGatewayObject: {},
      openCollapse: false,
      openModalIframePaymentGateway: false,
      iframePaymentGatewayObject: {},
      loadingPayment: true,
      openConfirmAlert: false,
    };
  }
  handleClose = () =>
    this.setState({
      modalConfirmation: false,
      modalPaymentGateway: false,
    });
  handleCloseIframe = () => {
    this.setState({
      openModalIframePaymentGateway: false,
      modalPaymentGateway: false,
      modalBalanceDetail: false,
      loadingBalanceDetail: true,
      loadingHistoryTransaction: true,
    });
    this.componentDidMount();
  };
  handleCloseConfirmAlert = () => {
    this.setState({
      openConfirmAlert: false,
      loadingPayment: false,
    });
  };
  handleCloseModal = () => this.setState({ modalBalanceDetail: false });
  static propTypes = {
    resetCustomerProfileAction: PropTypes.func,
    getBalanceHistoryByCompanyCodeAction: PropTypes.func,
    getCustomerProfileByCodeAction: PropTypes.func,
    getBalanceHistory: PropTypes.array,
    getBalanceHistoryStatus: PropTypes.string,
    getCustomerProfileByCodeStatus: PropTypes.string,
    getCustomerProfileByCode: PropTypes.array,
    postBalanceDetailAction: PropTypes.func,
    postBalanceDetailStatus: PropTypes.string,
    errorMessage: PropTypes.string,
    postTopUpUsingPaymentGatewayStatus: PropTypes.string,
    postTopUpUsingPaymentGateway: PropTypes.array,
    resetCustomEnumAction: PropTypes.func,
    errorMessageTopUp: PropTypes.string,
    getPaymentMethodByCountryIdStatus: PropTypes.string,
    getPaymentMethodByCountryId: PropTypes.array,
    getPaymentMethodByCountryIdAction: PropTypes.func,
    postTopUpPaymentGatewayAction: PropTypes.func,
    getCurrenciesAction: PropTypes.func,
    currencies: PropTypes.array,
  };
  componentDidMount = () => {
    this.checkBalanceHistoryCode();
  };
  checkBalanceHistoryCode = () => {
    // eslint-disable-next-line
    let code = this.props.match.params.ProfileId;
    if (code === undefined) {
      this.setState({
        loadingBalanceDetail: false,
        loadingHistoryTransaction: false,
        loadingPayment: false,
      });
    } else {
      return this.getBalanceHistory(code);
    }
  };
  getBalanceHistory = code => {
    this.props.getCustomerProfileByCodeAction(code);
    this.props.getBalanceHistoryByCompanyCodeAction(code);
    this.setState({
      codeBalance: code,
    });
  };
  componentDidUpdate() {
    if (this.props.getCustomerProfileByCodeStatus === 'success') {
      this.setStateBalanceDetail(this.props.getCustomerProfileByCode);
      this.setState({ loadingBalanceDetail: false, loadingPayment: false });
      this.props.resetCustomerProfileAction();
    } else if (this.props.getCustomerProfileByCodeStatus === 'failed') {
      this.setState({
        failedBalanceDetail: true,
        loadingBalanceDetail: false,
        loadingPayment: false,
      });
      this.props.resetCustomerProfileAction();
    }
    if (this.props.getBalanceHistoryStatus === 'success') {
      this.setState({
        historyTransaction: this.props.getBalanceHistory,
      });
      this.setState({ loadingHistoryTransaction: false });
      this.props.resetCustomerProfileAction();
    } else if (this.props.getBalanceHistoryStatus === 'failed') {
      this.setState({
        loadingHistoryTransaction: true,
        failedHistoryTransaction: false,
      });
      this.props.resetCustomerProfileAction();
    }
    if (this.props.postBalanceDetailStatus === 'success') {
      this.setState({
        modalBalanceDetail: false,
        headerAlert: 'Success',
        contentAlert: 'Save Balance Succesfully',
        modalConfirmation: true,
        loadingPayment: false,
        loadingButtonBalance: false,
      });
      this.props.resetCustomerProfileAction();
      this.props.getBalanceHistoryByCompanyCodeAction(this.state.codeBalance);
      this.props.getCustomerProfileByCodeAction(this.state.codeBalance);
    } else if (this.props.postBalanceDetailStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorMessage,
        modalConfirmation: true,
        modalBalanceDetail: false,
        loadingPayment: false,
      });
      this.props.resetCustomerProfileAction();
    }
    if (this.props.postTopUpUsingPaymentGatewayStatus === 'success') {
      this.setState({
        openModalIframePaymentGateway: true,
        iframePaymentGatewayObject: this.props.postTopUpUsingPaymentGateway,
        loadingPayment: false,
      });
      this.props.resetCustomEnumAction();
    } else if (this.props.postTopUpUsingPaymentGatewayStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorMessageTopUp,
        modalConfirmation: true,
        openModalIframePaymentGateway: false,
        loadingPayment: false,
      });
      this.props.resetCustomEnumAction();
    }
    if (this.props.getPaymentMethodByCountryIdStatus === 'success') {
      this.setState({
        paymentMethodByCountryId: this.props.getPaymentMethodByCountryId.map(
          p => p.PaymentGateawayChoice
        ),
      });
      this.props.resetCustomEnumAction();
    } else if (this.props.getPaymentMethodByCountryIdStatus === 'failed') {
      //this.setState({ failedPaymentMethod: true, loadingCountry: false });
      this.props.resetCustomEnumAction();
    }
  }
  onChange = (e, { value, name }) => {
    const balanceDetail = this.state.balanceDetail;
    balanceDetail[name] = value;
    this.setState({ balanceDetail: balanceDetail });
  };
  onChangeAmount = (value, name) => {
    const balanceDetail = this.state.balanceDetail;
    balanceDetail[name] = value;
    this.setState({ balanceDetail: balanceDetail });
  };
  setStateBalanceDetail = data => {
    const { Code, Currency, Name, Balance, CountryId } = data;
    let balanceDetail = {
      CompanyCode: Code ? Code : '',
      DateTime: '',
      CashFlowType: 0,
      Currency: Currency ? Currency : '',
      Value: 0,
      Reference: '',
      Description: '',
      PaymentMethod: 0,
      Name: Name ? Name : '',
      Balance: Balance ? Balance : 0,
      CountryId: CountryId,
    };
    this.setState({ balanceDetail: balanceDetail });
  };
  buttonBack = () => {
    const { codeBalance } = this.state;
    let getFirstString =
      codeBalance.length > 1 ? codeBalance.substring(0, 1) : '0';
    if (getFirstString === '0') {
      // eslint-disable-next-line
      this.props.history.push(`/Customer-Profile-Detail/new`);
    } else if (getFirstString === '2') {
      // eslint-disable-next-line
      this.props.history.push(
        `/Partner-Profile-Detail/detail/${this.state.codeBalance}`
      );
    } else {
      // eslint-disable-next-line
      this.props.history.push(
        `/Customer-Profile-Detail/detail/${this.state.codeBalance}`
      );
    }
  };
  openModalBalanceDetail = (e, { value }) => {
    this.props.getCurrenciesAction();
    if (value === 'Credit') {
      this.props.getPaymentMethodByCountryIdAction(
        this.state.balanceDetail.CountryId
      );
      this.setState({
        headerModalBalanceDetail: value,
        modalBalanceDetail: true,
        nameButton: 'PaymentGateway',
        contentButton: 'Top Up',
        balanceDetail: {
          ...this.state.balanceDetail,
          CashFlowType: value,
          DateTime: moment(new Date()).format('YYYY-MM-DD'),
        },
        errors: {},
      });
    } else {
      this.setState({
        headerModalBalanceDetail: value,
        modalBalanceDetail: true,
        nameButton: value,
        contentButton: 'Save',
        balanceDetail: {
          ...this.state.balanceDetail,
          CashFlowType: 68,
          DateTime: moment(new Date()).format('YYYY-MM-DD'),
        },
        errors: {},
      });
    }
  };
  validate = () => {
    let isError = false;
    const errors = {
      ValueError: '',
    };
    if (
      this.state.balanceDetail.Value <= 0 ||
      this.state.balanceDetail.Value === '' ||
      this.state.balanceDetail.Value === '0'
    ) {
      isError = true;
      errors.ValueError = `Amount Invalid!`;
    }
    this.setState({
      errors: {
        ...errors,
      },
    });
    return isError;
  };

  saveBalanceDetail = (e, { name, id, value }) => {
    if (name === 'Debit') {
      const err = this.validate();
      if (!err) {
        const { balanceDetail } = this.state;
        this.props.postBalanceDetailAction(balanceDetail);
        this.setState({ loadingButtonBalance: true });
      }
    } else {
      const err = this.validate();
      const data = this.state.balanceDetail;
      if (!err) {
        if (name === 'ManualPayment') {
          this.setState({ loadingPayment: true });
          this.props.postBalanceDetailAction(data);
        } else if (name === 'postPaymentGateway') {
          this.setState({
            loadingPayment: true,
            openConfirmAlert: true,
            contentConfirAlert:
              'Are you sure to make payments using ' + value + ' ?',
            IdForPayment: id,
          });
        } else {
          this.paymentGateway();
        }
      }
    }
  };
  payUsingPaymentGateway = () => {
    const dataPaymentgateway = {
      PaymentGateway: this.state.IdForPayment,
      Amount: this.state.balanceDetail.Value,
      CompanyCode: this.state.balanceDetail.CompanyCode,
      CurrencyId: this.state.balanceDetail.CurrencyId,
    };
    this.setState({
      openConfirmAlert: false,
    });
    //console.log(dataPaymentgateway);
    this.props.postTopUpPaymentGatewayAction(dataPaymentgateway);
  };
  paymentGateway = () => {
    const manual = ['Deposit'];
    const paymentGateway = this.state.paymentMethodByCountryId;
    let filteredKeywords = paymentGateway.filter(
      word => !manual.includes(word)
    );
    this.setState({
      modalPaymentGateway: true,
      listPaymentgateway: filteredKeywords.map(type => {
        return {
          name: type,
          Id: type === 'Deposit' ? 0 : 1,
        };
      }),
    });
  };
  /*  LinkToPaymentGateway = (e, { id }) => {
    alert(id);
  }; */

  collapseButton = () => {
    this.setState({ openCollapse: !this.state.openCollapse });
  };
  render() {
    let {
      loadingBalanceDetail,
      loadingHistoryTransaction,
      failedBalanceDetail,
      failedHistoryTransaction,
      modalConfirmation,
      loadingPayment,
    } = this.state;
    let loading = loadingBalanceDetail || loadingHistoryTransaction;
    let openModal =
      (failedBalanceDetail && failedHistoryTransaction) || modalConfirmation;
    let loadingModalPayment = loadingPayment;
    return (
      <div>
        <Dimmer.Dimmable blurring dimmed={loading}>
          <Loader loading={loading} />
          <BalanceDetail
            historyTransaction={this.state.historyTransaction}
            contentDetail={this.state.balanceDetail}
            buttonBack={this.buttonBack}
            openModalBalanceDetail={this.openModalBalanceDetail}
          />
          <ModalAlert
            openModal={openModal}
            handleClose={this.handleClose}
            header={this.state.headerAlert}
            content={this.state.contentAlert}
            nameButton="OK"
          />
          <ModalBalanceDetail
            open={this.state.modalBalanceDetail}
            handleClose={this.handleCloseModal}
            header={this.state.headerModalBalanceDetail}
            handleChange={this.onChange}
            contentField={this.state.balanceDetail}
            errorInformation={this.state.errors}
            saveBalanceDetail={this.saveBalanceDetail}
            nameButton={this.state.nameButton}
            contentButton={this.state.contentButton}
            loading={this.state.loadingButtonBalance}
            currencies={this.props.currencies}
            handleChangeAmount={this.onChangeAmount}
          />
          <ModalPaymentGateway
            open={this.state.modalPaymentGateway}
            handleClose={this.handleClose}
            header="Choose Payment Gateway"
            handleChange={this.onChange}
            contentField={this.state.balanceDetail}
            errorInformation={this.state.errors}
            saveBalanceDetail={this.saveBalanceDetail}
            listPaymentMethod={this.state.listPaymentgateway}
            LinkTo={this.LinkToPaymentGateway}
            openCollapse={this.state.openCollapse}
            collapseButton={this.collapseButton}
            loadingModal={loadingModalPayment}
          />
          <ModalIframePaymentGateway
            open={this.state.openModalIframePaymentGateway}
            handleClose={this.handleCloseIframe}
            url={this.state.iframePaymentGatewayObject.Url}
          />
          <Confirm
            dimmer="blurring"
            open={this.state.openConfirmAlert}
            onCancel={this.handleCloseConfirmAlert}
            onConfirm={this.payUsingPaymentGateway}
            content={this.state.contentConfirAlert}
            size="mini"
          />
        </Dimmer.Dimmable>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    getBalanceHistory: state.CustomerProfileStore.getBalanceHistory,
    getBalanceHistoryStatus: state.CustomerProfileStore.getBalanceHistoryStatus,
    getCustomerProfileByCode:
      state.CustomerProfileStore.getCustomerProfileByCode,
    getCustomerProfileByCodeStatus:
      state.CustomerProfileStore.getCustomerProfileByCodeStatus,
    postBalanceDetailStatus: state.CustomerProfileStore.postBalanceDetailStatus,
    errorMessage: state.CustomerProfileStore.errors.global,
    postTopUpUsingPaymentGateway:
      state.CustomEnumReducerStore.postTopUpUsingPaymentGateway,
    postTopUpUsingPaymentGatewayStatus:
      state.CustomEnumReducerStore.postTopUpUsingPaymentGatewayStatus,
    errorMessageTopUp: state.CustomEnumReducerStore.errors.message,
    getPaymentMethodByCountryId:
      state.CustomEnumReducerStore.getPaymentMethodByCountryId,
    getPaymentMethodByCountryIdStatus:
      state.CustomEnumReducerStore.getPaymentMethodByCountryIdStatus,
    currencies: state.currenciesStore.currencies.map((cur, i) => {
      return { key: i, text: cur.Id, value: cur.Id };
    }),
  };
}
export default connect(mapStateToProps, {
  resetCustomerProfileAction,
  getBalanceHistoryByCompanyCodeAction,
  getCustomerProfileByCodeAction,
  postBalanceDetailAction,
  postTopUpPaymentGatewayAction,
  resetCustomEnumAction,
  getPaymentMethodByCountryIdAction,
  getCurrenciesAction,
})(BalanceDetailPage);
