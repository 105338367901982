let PACKAGE_DETAIL = {
  brochure: null,
  tourName: '',
  firstDestination: '',
  description: '',
  tourType: '',
  tourCategory: '',
  tourDate: '',
  paxAvailable: 0,
  totalCommission: 0,
  currency: '',
  images: null,
  roomAllocationPrice: [],
  paymentTerms: [],
  dailyProgram: [],
  headlineProgram: {
    Departures: [],
    MainPrograms: [],
    Returns: [],
  },
  additionalInfo: [],
};

export { PACKAGE_DETAIL };
