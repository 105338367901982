// constanta untuk button (pembuatan transaksi)
const CREATE_FIXED = [
  // {
  //   value: 'back',
  //   title: 'Back',
  //   type: 'handleClickConfirmation',
  //   icon: 'angle left',
  // },
  // {
  //   value: 'save',
  //   title: 'Book Now',
  //   type: 'handleClickConfirmation',
  //   icon: 'save outline',
  // },
];
const CREATE_READY = [
  // {
  //   value: 'back',
  //   title: 'Back',
  //   type: 'handleClickConfirmation',
  //   icon: 'angle left',
  // },
  {
    value: 'quotation',
    title: 'Create Quotation',
    type: 'handleClickConfirmation',
    icon: 'sticky note outline',
  },
  // {
  //   value: 'save',
  //   title: 'Book Now',
  //   type: 'handleClickConfirmation',
  //   icon: 'save outline',
  // },
];

const CREATE_CUSTOM = [
  // {
  //   value: 'back',
  //   title: 'Back',
  //   type: 'handleClickConfirmation',
  //   icon: 'angle left',
  // },
  {
    value: 'quotation',
    title: 'Create Quotation',
    type: 'handleClickConfirmation',
    icon: 'sticky note outline',
  },
  // {
  //   value: 'save',
  //   title: 'Book Now',
  //   type: 'handleClickConfirmation',
  //   icon: 'save outline',
  // },
];

const CREATE_FIXED_PRICE = [
  // {
  //   value: 'back',
  //   title: 'Back',
  //   type: 'handleClickConfirmation',
  //   icon: 'angle left',
  // },
  // {
  //   value: 'save',
  //   title: 'Book Now',
  //   type: 'handleClickConfirmation',
  //   icon: 'save outline',
  // },
];

const CREATE_QUOTATION = [
  // {
  //   value: 'back',
  //   title: 'Back',
  //   type: 'handleClickConfirmation',
  //   icon: 'angle left',
  // },
  // {
  //   value: 'bookQuotation',
  //   title: 'Book Now',
  //   type: 'handleClickConfirmation',
  //   icon: 'save outline',
  // },
  {
    value: 'edit', // save
    title: 'Save Quotation',
    type: 'handleClickConfirmation',
    icon: 'save outline',
  },
];

const CREATE_SIMILAR = [
  // {
  //   value: 'back',
  //   title: 'Back',
  //   type: 'handleClickConfirmation',
  //   icon: 'angle left',
  // },
  {
    value: 'quotation',
    title: 'Create Quotation',
    type: 'handleClickConfirmation',
    icon: 'sticky note outline',
  },
  // {
  //   value: 'save',
  //   title: 'Book Now',
  //   type: 'handleClickConfirmation',
  //   icon: 'save outline',
  // },
];

let EDIT_CUSTOM_READY_FIXEDPRICE = [
  // {
  //   value: 'back',
  //   title: 'Back',
  //   type: 'handleClickConfirmation',
  //   icon: 'angle left',
  // },
  {
    value: 'similar',
    title: 'Create Similar Package',
    type: 'handleClickConfirmation',
    icon: 'copy outline',
  },
];

let EDIT_FIXED = [
  // {
  //   value: 'back',
  //   title: 'Back',
  //   type: 'handleClickConfirmation',
  //   icon: 'angle left',
  // },
];

let CURRENT_PACKAGE_DISABLE = {
  value: 'Save',
  title: 'Edit Special Adjustment',
  type: 'handleEditTransaction',
  icon: 'save outline',
};

let CURRENT_PACKAGE_ENABLE = {
  value: 'Save',
  title: 'Save Special Adjustment',
  type: 'handleEditTransaction',
  icon: 'edit outline',
};

let NOT_BOOKING_CANCEL = {
  value: 'cancel',
  title: 'Cancel Booking',
  type: 'handleClickCancel',
  icon: 'cancel',
};

let DISABLE_EDIT = {
  value: 'edit',
  title: 'Edit',
  type: 'handleClickConfirmation',
  icon: 'edit outline',
};

let BOOKING_ON_HOLD = {
  value: 'onHold',
  title: 'Approve',
  type: 'handleClickConfirmation',
  icon: 'check',
};

let PAY = {
  value: 'pay',
  title: 'Pay',
  type: 'handleClickPayment',
  icon: 'money bill alternate outline',
};

let SEND_MANUAL_EMAIL = {
  value: 'sendMail',
  title: 'Send Email',
  // type: 'handleClickSendMail',
  type: 'handleClickConfirmation',
  icon: 'send',
};

let GENERATE_RBL = {
  value: 'generateRBL',
  title: 'Generate Resource',
  // type: 'handleClickSendMail',
  type: 'handleClickConfirmation',
  icon: null,
};

export {
  CREATE_FIXED,
  CREATE_READY,
  CREATE_CUSTOM,
  CREATE_FIXED_PRICE,
  CREATE_QUOTATION,
  CREATE_SIMILAR,
  EDIT_FIXED,
  EDIT_CUSTOM_READY_FIXEDPRICE,
  CURRENT_PACKAGE_DISABLE,
  CURRENT_PACKAGE_ENABLE,
  NOT_BOOKING_CANCEL,
  DISABLE_EDIT,
  BOOKING_ON_HOLD,
  PAY,
  SEND_MANUAL_EMAIL,
  GENERATE_RBL,
};
