import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  Grid,
  Header,
  Segment,
  Container,
} from 'semantic-ui-react';
import styles from '../style';

const Login = props => (
  <Grid
    textAlign="center"
    style={{ height: '100%', paddingTop: '150px' }}
    verticalAlign="middle"
  >
    <Grid.Column style={{ maxWidth: 300 }}>
      <Header as="h2" color="teal" textAlign="center">
        Login
      </Header>
      <Form size="large">
        <Segment stacked>
          {/* No password in admin */}
          {/*           <Form.Input
            fluid
            icon="suitcase"
            iconPosition="left"
            placeholder="Company"
            name="idCompany"
            onChange={props.handleChange}
            error={props.errors.idCompanyError}
          />
          {props.errors.idCompanyError && (
            <Container textAlign="left" style={styles.messageError} as="h5">
              {props.errors.idCompanyErrorText}
            </Container>
          )} */}
          <Form.Input
            fluid
            icon="user"
            iconPosition="left"
            placeholder="Username"
            name="username"
            onChange={props.handleChange}
            error={props.errors.usernameError}
          />
          {props.errors.usernameError && (
            <Container textAlign="left" style={styles.messageError} as="h5">
              {props.errors.usernameErrorText}
            </Container>
          )}
          <Form.Input
            fluid
            icon="lock"
            iconPosition="left"
            placeholder="Password"
            type={props.typePassword}
            name="password"
            onChange={props.handleChange}
            error={props.errors.passwordError}
            action={
              <Button
                tabIndex="2"
                name="password"
                icon={props.passwordIcon}
                onClick={props.onClickPassIcon}
                type="button"
              />
            }
          />
          {props.errors.passwordError && (
            <Container textAlign="left" style={styles.messageError} as="h5">
              {props.errors.passwordErrorText}
            </Container>
          )}
          <Button
            color="teal"
            fluid
            size="large"
            onClick={props.handleClick}
            loading={props.loading}
          >
            Login
          </Button>
        </Segment>
      </Form>
    </Grid.Column>
  </Grid>
);

Login.propTypes = {
  handleChange: PropTypes.func,
  errors: PropTypes.object,
  handleClick: PropTypes.func,
  loading: PropTypes.bool,
  typePassword: PropTypes.string,
  passwordIcon: PropTypes.string,
  onClickPassIcon: PropTypes.func,
};

export default Login;
