import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import { headerTableAdditionalCostList } from '../../constants/headerTable';
import ActionButton from '../../../../../components/common/buttonEditAndDelete';

const TableAdditionalCostList = props => {
  const { listData, handleEdit, handleDelete } = props;
  return (
    <Table basic="very" selectable>
      <Table.Header>
        <Table.Row>
          {headerTableAdditionalCostList.map((header, index) => {
            return (
              <Table.HeaderCell
                key={index}
                textAlign={header === 'Action' ? 'center' : ''}
              >
                {header}
              </Table.HeaderCell>
            );
          })}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {listData &&
          listData.map((item, idx) => {
            return (
              <Table.Row key={idx}>
                {/* <Table.Cell>{item.TransportationAdditionalCostName}</Table.Cell> */}
                <Table.Cell>{item.TransportationType}</Table.Cell>
                <Table.Cell>{item.Origin}</Table.Cell>
                <Table.Cell>{item.Destination}</Table.Cell>
                {/* <Table.Cell>
                  {item.TransportationAdditionalCostType.Text}
                </Table.Cell> */}
                <Table.Cell width={3} textAlign="center">
                  <ActionButton
                    id={item.Id}
                    handleClickEdit={handleEdit}
                    handleClickDelete={handleDelete}
                    name1="Edit"
                    name2="Delte"
                    content1="Edit"
                    content2="Delete"
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
      </Table.Body>
    </Table>
  );
};

TableAdditionalCostList.propTypes = {
  listData: PropTypes.array,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default React.memo(TableAdditionalCostList);
