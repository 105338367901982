import { convertDateFormat } from '../../../../../scripts/moment';
import moment from 'moment';

export const periodValidation = (from, to, startDate, endDate) => {
  return (
    moment(convertDateFormat(from, 'YYYY-MM-DD')).isSame(
      convertDateFormat(startDate, 'YYYY-MM-DD')
    ) ||
    moment(convertDateFormat(to, 'YYYY-MM-DD')).isSame(
      convertDateFormat(endDate, 'YYYY-MM-DD')
    ) ||
    moment(convertDateFormat(from, 'YYYY-MM-DD')).isSame(
      convertDateFormat(endDate, 'YYYY-MM-DD')
    ) ||
    moment(convertDateFormat(to, 'YYYY-MM-DD')).isSame(
      convertDateFormat(startDate, 'YYYY-MM-DD')
    ) ||
    moment(convertDateFormat(from, 'YYYY-MM-DD')).isBetween(
      convertDateFormat(startDate, 'YYYY-MM-DD'),
      convertDateFormat(endDate, 'MM-DD-YYYY')
    ) ||
    moment(convertDateFormat(to, 'YYYY-MM-DD')).isBetween(
      convertDateFormat(startDate, 'YYYY-MM-DD'),
      convertDateFormat(endDate, 'MM-DD-YYYY')
    )
  );
};
