import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Button } from 'semantic-ui-react';
import Pagination from '../../../../../components/common/pagination';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../../../scripts/pagination';
import IndexPagination from '../../../../../components/genericFunction/indexPagination';

const TableAdditionalServices = props => {
  const dataPerPage = 3;
  const { data, handleDelete } = props;
  const [activePage, setActivePage] = useState(1);

  let pagedData = datasetPagination(data ? data : [], dataPerPage);
  let listData = pagedData[activePage - 1];
  let { numbersFrom, numbersTo } = getNumberFromTo(
    listData,
    activePage,
    dataPerPage
  );

  const pageChange = (e, { activePage }) => {
    setActivePage(activePage);
  };

  const handleDeleteService = e => {
    setActivePage((data.length - 1) % dataPerPage === 0 ? 1 : activePage);
    handleDelete(e);
  };

  return (
    <>
      <Table basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Additional Service Name</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {listData ? (
            listData.map((item, idx) => {
              return (
                <Table.Row key={IndexPagination(dataPerPage, activePage, idx)}>
                  <Table.Cell>{item.AdditionalServiceName}</Table.Cell>
                  <Table.Cell textAlign="center">
                    <Button
                      content="Delete"
                      negative
                      id={item.AdditionalServiceId}
                      onClick={handleDeleteService}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell colSpan={2} textAlign="center">
                No Data
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <Pagination
        pagedData={pagedData}
        numbersTo={numbersTo}
        numbersFrom={numbersFrom}
        data={data}
        pageChange={pageChange}
        activePage={activePage}
      />
    </>
  );
};

TableAdditionalServices.propTypes = {
  data: PropTypes.array,
  handleDelete: PropTypes.func,
};

export default TableAdditionalServices;
