import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Grid, Form } from 'semantic-ui-react';
import InputWithLabel from '../../common//input/InputWithLabel';
const ModalUser = props => (
  <Modal
    dimmer="blurring"
    closeIcon
    inverted
    open={props.openModal}
    onClose={props.handleClose}
    size="tiny"
  >
    <Modal.Header>{props.header}</Modal.Header>
    <Modal.Content>
      <Grid>
        <Grid.Column>
          <Form>
            <InputWithLabel
              inline={false}
              label="Username"
              type="text"
              required
              name="Username"
              placeholder="Username"
              fluid
              handleChange={props.handleChange}
              validate={props.errorInformation}
            />
          </Form>
          <Form>
            <InputWithLabel
              inline={false}
              label="Email"
              type="text"
              required
              name="Email"
              placeholder="Email"
              fluid
              handleChange={props.handleChange}
              validate={props.errorInformation}
            />
          </Form>
          <Form>
            <InputWithLabel
              inline={false}
              label="Password"
              // type="password"
              type={props.typePassword}
              required
              name="Password"
              placeholder="example: 12345Ab~"
              fluid
              handleChange={props.handleChange}
              validate={props.errorInformation}
              action={
                <Button
                  tabIndex="2"
                  name="password"
                  icon={props.passwordIcon}
                  onClick={props.onClickPassIcon}
                  type="button"
                />
              }
            />
          </Form>
          <Form>
            <InputWithLabel
              inline={false}
              label="Confirm Password"
              type={props.typeConfirmPassword}
              required
              name="ConfirmPassword"
              placeholder="example: 12345Ab~"
              fluid
              handleChange={props.handleChange}
              validate={props.errorInformation}
              action={
                <Button
                  tabIndex="2"
                  name="confirmPassword"
                  icon={props.confirmPasswordIcon}
                  onClick={props.onClickConfirmPassIcon}
                  type="button"
                />
              }
            />
          </Form>
          {!props.isShow && (
            <Form>
              <InputWithLabel
                inline={false}
                label="Roles"
                type="select"
                required
                name="Roles"
                placeholder="Roles"
                options={props.listUserRole}
                handleChange={props.handleChange}
                validate={props.errorInformation}
                multiple={true}
              />
            </Form>
          )}
        </Grid.Column>
      </Grid>
    </Modal.Content>
    <Modal.Actions>
      <Button
        positive
        onClick={props.handleSubmit}
        content={props.nameButton}
        loading={props.loading || false}
      />
    </Modal.Actions>
  </Modal>
);

ModalUser.propTypes = {
  openModal: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  content: PropTypes.string,
  nameButton: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  listCountry: PropTypes.array,
  listGender: PropTypes.array,
  openModalImage: PropTypes.func,
  imagePreview: PropTypes.string,
  listUserRole: PropTypes.array,
  errors: PropTypes.object,
  loading: PropTypes.bool,
  errorInformation: PropTypes.object,
  typePassword: PropTypes.string,
  passwordIcon: PropTypes.string,
  onClickPassIcon: PropTypes.func,
  typeConfirmPassword: PropTypes.string,
  confirmPasswordIcon: PropTypes.string,
  onClickConfirmPassIcon: PropTypes.func,
  isShow: PropTypes.bool,
};

export default ModalUser;
