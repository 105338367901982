//POST PREVIEW ACCOMODATION PROFILE
export const POST_UPLOAD_PREVIEW_ACCOMODATION_PROFILE =
  'POST_UPLOAD_PREVIEW_ACCOMODATION_PROFILE';
export const POST_UPLOAD_PREVIEW_ACCOMODATION_PROFILE_FULFILLED =
  'POST_UPLOAD_PREVIEW_ACCOMODATION_PROFILE_FULFILLED';
export const POST_UPLOAD_PREVIEW_ACCOMODATION_PROFILE_PENDING =
  'POST_UPLOAD_PREVIEW_ACCOMODATION_PROFILE_PENDING';
export const POST_UPLOAD_PREVIEW_ACCOMODATION_PROFILE_REJECTED =
  'POST_UPLOAD_PREVIEW_ACCOMODATION_PROFILE_REJECTED';
export const RESET_UPLOAD_DATA_MASTER = 'RESET_UPLOAD_DATA_MASTER';

//POST COMMIT ACCOMODATION PROFILE
export const POST_UPLOAD_COMMIT = 'POST_UPLOAD_COMMIT';
export const POST_UPLOAD_COMMIT_FULFILLED = 'POST_UPLOAD_COMMIT_FULFILLED';
export const POST_UPLOAD_COMMIT_PENDING = 'POST_UPLOAD_COMMIT_PENDING';
export const POST_UPLOAD_COMMIT_REJECTED = 'POST_UPLOAD_COMMIT_REJECTED';

//POST PREVIEW EXCURSION_OBJECT
export const POST_UPLOAD_PREVIEW_EXCURSION_OBJECT =
  'POST_UPLOAD_PREVIEW_EXCURSION_OBJECT';
export const POST_UPLOAD_PREVIEW_EXCURSION_OBJECT_FULFILLED =
  'POST_UPLOAD_PREVIEW_EXCURSION_OBJECT_FULFILLED';
export const POST_UPLOAD_PREVIEW_EXCURSION_OBJECT_PENDING =
  'POST_UPLOAD_PREVIEW_EXCURSION_OBJECT_PENDING';
export const POST_UPLOAD_PREVIEW_EXCURSION_OBJECT_REJECTED =
  'POST_UPLOAD_PREVIEW_EXCURSION_OBJECT_REJECTED';

//POST COMMIT EXCURSION_OBJECT
export const POST_UPLOAD_COMMIT_EXCURSION_OBJECT =
  'POST_UPLOAD_COMMIT_EXCURSION_OBJECT';
export const POST_UPLOAD_COMMIT_EXCURSION_OBJECT_FULFILLED =
  'POST_UPLOAD_COMMIT_EXCURSION_OBJECT_FULFILLED';
export const POST_UPLOAD_COMMIT_EXCURSION_OBJECT_PENDING =
  'POST_UPLOAD_COMMIT_EXCURSION_OBJECT_PENDING';
export const POST_UPLOAD_COMMIT_EXCURSION_OBJECT_REJECTED =
  'POST_UPLOAD_COMMIT_EXCURSION_OBJECT_REJECTED';

//POST PREVIEW TRANSPORTATION_PROFILE
export const POST_UPLOAD_PREVIEW_TRANSPORTATION_PROFILE =
  'POST_UPLOAD_PREVIEW_TRANSPORTATION_PROFILE';
export const POST_UPLOAD_PREVIEW_TRANSPORTATION_PROFILE_FULFILLED =
  'POST_UPLOAD_PREVIEW_TRANSPORTATION_PROFILE_FULFILLED';
export const POST_UPLOAD_PREVIEW_TRANSPORTATION_PROFILE_PENDING =
  'POST_UPLOAD_PREVIEW_TRANSPORTATION_PROFILE_PENDING';
export const POST_UPLOAD_PREVIEW_TRANSPORTATION_PROFILE_REJECTED =
  'POST_UPLOAD_PREVIEW_TRANSPORTATION_PROFILE_REJECTED';

//POST COMMIT TRANSPORTATION_PROFILE
export const POST_UPLOAD_COMMIT_TRANSPORTATION_PROFILE =
  'POST_UPLOAD_COMMIT_TRANSPORTATION_PROFILE';
export const POST_UPLOAD_COMMIT_TRANSPORTATION_PROFILE_FULFILLED =
  'POST_UPLOAD_COMMIT_TRANSPORTATION_PROFILE_FULFILLED';
export const POST_UPLOAD_COMMIT_TRANSPORTATION_PROFILE_PENDING =
  'POST_UPLOAD_COMMIT_TRANSPORTATION_PROFILE_PENDING';
export const POST_UPLOAD_COMMIT_TRANSPORTATION_PROFILE_REJECTED =
  'POST_UPLOAD_COMMIT_TRANSPORTATION_PROFILE_REJECTED';

//POST PREVIEW RESTAURANT_PROFILE
export const POST_UPLOAD_PREVIEW_RESTAURANT_PROFILE =
  'POST_UPLOAD_PREVIEW_RESTAURANT_PROFILE';
export const POST_UPLOAD_PREVIEW_RESTAURANT_PROFILE_FULFILLED =
  'POST_UPLOAD_PREVIEW_RESTAURANT_PROFILE_FULFILLED';
export const POST_UPLOAD_PREVIEW_RESTAURANT_PROFILE_PENDING =
  'POST_UPLOAD_PREVIEW_RESTAURANT_PROFILE_PENDING';
export const POST_UPLOAD_PREVIEW_RESTAURANT_PROFILE_REJECTED =
  'POST_UPLOAD_PREVIEW_RESTAURANT_PROFILE_REJECTED';

//POST COMMIT RESTAURANT_PROFILE
export const POST_UPLOAD_COMMIT_RESTAURANT_PROFILE =
  'POST_UPLOAD_COMMIT_RESTAURANT_PROFILE';
export const POST_UPLOAD_COMMIT_RESTAURANT_PROFILE_FULFILLED =
  'POST_UPLOAD_COMMIT_RESTAURANT_PROFILE_FULFILLED';
export const POST_UPLOAD_COMMIT_RESTAURANT_PROFILE_PENDING =
  'POST_UPLOAD_COMMIT_RESTAURANT_PROFILE_PENDING';
export const POST_UPLOAD_COMMIT_RESTAURANT_PROFILE_REJECTED =
  'POST_UPLOAD_COMMIT_RESTAURANT_PROFILE_REJECTED';

//POST PREVIEW ACCOMODATION ITEM
export const POST_UPLOAD_PREVIEW_ACCOMODATION_ITEM =
  'POST_UPLOAD_PREVIEW_ACCOMODATION_ITEM';
export const POST_UPLOAD_PREVIEW_ACCOMODATION_ITEM_FULFILLED =
  'POST_UPLOAD_PREVIEW_ACCOMODATION_ITEM_FULFILLED';
export const POST_UPLOAD_PREVIEW_ACCOMODATION_ITEM_PENDING =
  'POST_UPLOAD_PREVIEW_ACCOMODATION_ITEM_PENDING';
export const POST_UPLOAD_PREVIEW_ACCOMODATION_ITEM_REJECTED =
  'POST_UPLOAD_PREVIEW_ACCOMODATION_ITEM_REJECTED';

//POST COMMIT ACCOMODATION ITEM
export const POST_UPLOAD_COMMIT_ACCOMODATION_ITEM =
  'POST_UPLOAD_COMMIT_ACCOMODATION_ITEM';
export const POST_UPLOAD_COMMIT_ACCOMODATION_ITEM_FULFILLED =
  'POST_UPLOAD_COMMIT_ACCOMODATION_ITEM_FULFILLED';
export const POST_UPLOAD_COMMIT_ACCOMODATION_ITEM_PENDING =
  'POST_UPLOAD_COMMIT_ACCOMODATION_ITEM_PENDING';
export const POST_UPLOAD_COMMIT_ACCOMODATION_ITEM_REJECTED =
  'POST_UPLOAD_COMMIT_ACCOMODATION_ITEM_REJECTED';

//POST PREVIEW TRANSPORTATION_UNIT
export const POST_UPLOAD_PREVIEW_TRANSPORTATION_UNIT =
  'POST_UPLOAD_PREVIEW_TRANSPORTATION_UNIT';
export const POST_UPLOAD_PREVIEW_TRANSPORTATION_UNIT_FULFILLED =
  'POST_UPLOAD_PREVIEW_TRANSPORTATION_UNIT_FULFILLED';
export const POST_UPLOAD_PREVIEW_TRANSPORTATION_UNIT_PENDING =
  'POST_UPLOAD_PREVIEW_TRANSPORTATION_UNIT_PENDING';
export const POST_UPLOAD_PREVIEW_TRANSPORTATION_UNIT_REJECTED =
  'POST_UPLOAD_PREVIEW_TRANSPORTATION_UNIT_REJECTED';

//POST COMMIT TRANSPORTATION_UNIT
export const POST_UPLOAD_COMMIT_TRANSPORTATION_UNIT =
  'POST_UPLOAD_COMMIT_TRANSPORTATION_UNIT';
export const POST_UPLOAD_COMMIT_TRANSPORTATION_UNIT_FULFILLED =
  'POST_UPLOAD_COMMIT_TRANSPORTATION_UNIT_FULFILLED';
export const POST_UPLOAD_COMMIT_TRANSPORTATION_UNIT_PENDING =
  'POST_UPLOAD_COMMIT_TRANSPORTATION_UNIT_PENDING';
export const POST_UPLOAD_COMMIT_TRANSPORTATION_UNIT_REJECTED =
  'POST_UPLOAD_COMMIT_TRANSPORTATION_UNIT_REJECTED';

//POST PREVIEW RESTAURANT_ITEM
export const POST_UPLOAD_PREVIEW_RESTAURANT_ITEM =
  'POST_UPLOAD_PREVIEW_RESTAURANT_ITEM';
export const POST_UPLOAD_PREVIEW_RESTAURANT_ITEM_FULFILLED =
  'POST_UPLOAD_PREVIEW_RESTAURANT_ITEM_FULFILLED';
export const POST_UPLOAD_PREVIEW_RESTAURANT_ITEM_PENDING =
  'POST_UPLOAD_PREVIEW_RESTAURANT_ITEM_PENDING';
export const POST_UPLOAD_PREVIEW_RESTAURANT_ITEM_REJECTED =
  'POST_UPLOAD_PREVIEW_RESTAURANT_ITEM_REJECTED';

//POST COMMIT RESTAURANT_ITEM
export const POST_UPLOAD_COMMIT_RESTAURANT_ITEM =
  'POST_UPLOAD_COMMIT_RESTAURANT_ITEM';
export const POST_UPLOAD_COMMIT_RESTAURANT_ITEM_FULFILLED =
  'POST_UPLOAD_COMMIT_RESTAURANT_ITEM_FULFILLED';
export const POST_UPLOAD_COMMIT_RESTAURANT_ITEM_PENDING =
  'POST_UPLOAD_COMMIT_RESTAURANT_ITEM_PENDING';
export const POST_UPLOAD_COMMIT_RESTAURANT_ITEM_REJECTED =
  'POST_UPLOAD_COMMIT_RESTAURANT_ITEM_REJECTED';

//POST PREVIEW RESTAURANT_PRICE
export const POST_UPLOAD_PREVIEW_RESTAURANT_PRICE =
  'POST_UPLOAD_PREVIEW_RESTAURANT_PRICE';
export const POST_UPLOAD_PREVIEW_RESTAURANT_PRICE_FULFILLED =
  'POST_UPLOAD_PREVIEW_RESTAURANT_PRICE_FULFILLED';
export const POST_UPLOAD_PREVIEW_RESTAURANT_PRICE_PENDING =
  'POST_UPLOAD_PREVIEW_RESTAURANT_PRICE_PENDING';
export const POST_UPLOAD_PREVIEW_RESTAURANT_PRICE_REJECTED =
  'POST_UPLOAD_PREVIEW_RESTAURANT_PRICE_REJECTED';

//POST COMMIT RESTAURANT_PRICE
export const POST_UPLOAD_COMMIT_RESTAURANT_PRICE =
  'POST_UPLOAD_COMMIT_RESTAURANT_PRICE';
export const POST_UPLOAD_COMMIT_RESTAURANT_PRICE_FULFILLED =
  'POST_UPLOAD_COMMIT_RESTAURANT_PRICE_FULFILLED';
export const POST_UPLOAD_COMMIT_RESTAURANT_PRICE_PENDING =
  'POST_UPLOAD_COMMIT_RESTAURANT_PRICE_PENDING';
export const POST_UPLOAD_COMMIT_RESTAURANT_PRICE_REJECTED =
  'POST_UPLOAD_COMMIT_RESTAURANT_PRICE_REJECTED';

//POST PREVIEW EXCURSION_PRICE
export const POST_UPLOAD_PREVIEW_EXCURSION_PRICE =
  'POST_UPLOAD_PREVIEW_EXCURSION_PRICE';
export const POST_UPLOAD_PREVIEW_EXCURSION_PRICE_FULFILLED =
  'POST_UPLOAD_PREVIEW_EXCURSION_PRICE_FULFILLED';
export const POST_UPLOAD_PREVIEW_EXCURSION_PRICE_PENDING =
  'POST_UPLOAD_PREVIEW_EXCURSION_PRICE_PENDING';
export const POST_UPLOAD_PREVIEW_EXCURSION_PRICE_REJECTED =
  'POST_UPLOAD_PREVIEW_EXCURSION_PRICE_REJECTED';

//POST COMMIT EXCURSION_PRICE
export const POST_UPLOAD_COMMIT_EXCURSION_PRICE =
  'POST_UPLOAD_COMMIT_EXCURSION_PRICE';
export const POST_UPLOAD_COMMIT_EXCURSION_PRICE_FULFILLED =
  'POST_UPLOAD_COMMIT_EXCURSION_PRICE_FULFILLED';
export const POST_UPLOAD_COMMIT_EXCURSION_PRICE_PENDING =
  'POST_UPLOAD_COMMIT_EXCURSION_PRICE_PENDING';
export const POST_UPLOAD_COMMIT_EXCURSION_PRICE_REJECTED =
  'POST_UPLOAD_COMMIT_EXCURSION_PRICE_REJECTED';
