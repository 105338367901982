import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DailyProgram from './DailyProgram';
import {
  getDurationIdtoAddress,
  getDurationAddresstoId,
  getDurationIdtoId,
} from '../../../../../actions/packageAction';
// import { getMasterReferenceAction } from '../../../actions/uploadMasterDataReferenceAction';
// import {
// } from '../genericFunction/dailyProgram';
import {
  getDrivingArray,
  getFunctionDP,
  getFunctionDPBot,
  editFunctionDP,
  editFunctionDPBot,
  copyObject,
  deleteActivity,
  fixafterDelete,
  deleteTransportation,
  addItemTransportation,
  dailyGenerate4,
  isAccommodationEmpty,
  getFunctionAfterDriving,
  isAccommodationTimeNeedChange,
  changeTimeSummaryProgramAfterAddActivity,
  isBeforeDeparture,
} from '../../../../../scripts/itineraryBuilder/dailyProgram';
import { setTransport } from '../../../../../scripts/itineraryBuilder/demoPrice';
import { Header, Icon, Grid, Segment } from 'semantic-ui-react';
import CollapseButton from '../../../../../components/button/collapseButton';
import { Collapse } from 'react-collapse';
import styles from '../../../../../assets/styles/styleCreateTourTransaction';
class TourItinerary extends Component {
  state = {
    openCollapse: false,
    transportStore: [],
    generateDaily: true,
  };
  // componentDidMount = () => {
  //   this.props.getMasterReferenceAction('Languages');
  // };
  componentDidUpdate = prevProps => {
    if (
      (JSON.stringify(copyObject(prevProps.headlineProgram)) !==
        JSON.stringify(copyObject(this.props.headlineProgram)) &&
        this.props.typePackage !== 'Fixed' &&
        this.props.typePackage !== 'FixedDateVariable') ||
      (JSON.stringify(copyObject(prevProps.headlineProgram)) !==
        JSON.stringify(copyObject(this.props.headlineProgram)) &&
        this.props.isPackageManagemenEdit)
    ) {
      if (this.state.generateDaily) {
        this.generateDailyProgram(this.props);
      } else this.setState({ generateDaily: true });
    }
    if (
      (JSON.stringify(copyObject(prevProps.dailyProgram)) !==
        JSON.stringify(copyObject(this.props.dailyProgram)) &&
        this.props.typePackage !== 'Fixed' &&
        this.props.typePackage !== 'FixedDateVariable') ||
      (JSON.stringify(copyObject(prevProps.dailyProgram)) !==
        JSON.stringify(copyObject(this.props.dailyProgram)) &&
        this.props.isPackageManagemenEdit)
    ) {
      let generateDaily = true;
      if (
        // cek perbedaan cekin cek out
        isAccommodationTimeNeedChange(
          this.props.dailyProgram,
          this.props.headlineProgram.MainPrograms
        )
      ) {
        let newMainProg = changeTimeSummaryProgramAfterAddActivity(
          this.props.dailyProgram,
          this.props.headlineProgram.MainPrograms
        );
        let headline = { ...this.props.headlineProgram };
        headline.MainPrograms = newMainProg;
        this.props.setheadlineProgram(headline);
        generateDaily = false;
      }
      let transport = setTransport(this.props.dailyProgram);
      this.setState({
        ...this.state,
        transportStore: transport,
        generateDaily,
      });
    }
  };
  generateDailyProgram = async props => {
    let {
      headlineProgram,
      dailyProgram,
      movementList,
      cityInCountry,
      isReadyFixedPrice,
    } = props;
    let hlProg = { ...headlineProgram };
    let dP = dailyProgram;
    if (
      movementList.length > 0 &&
      isAccommodationEmpty(hlProg.MainPrograms) === false
    ) {
      dP = await dailyGenerate4(
        hlProg.Departures,
        hlProg.Returns,
        hlProg.MainPrograms,
        dailyProgram,
        movementList,
        cityInCountry,
        isReadyFixedPrice
      );
      this.createDrivingArray(dP);
    }
    this.props.setDailyProgram(dP);
  };

  async createDrivingArray(dailyProgram) {
    let drivings = getDrivingArray(dailyProgram);
    this.setState({ totalGetDurations: drivings.length });
    // this.getDrivingDurations(drivings);
    let newDP = await this.getDrivingDurations3(drivings, dailyProgram);
    newDP = getFunctionAfterDriving(newDP);
    this.props.setDailyProgram(newDP);
    // this.getDrivingArray2(dailyProgram);
  }

  handleCollapse = (e, { name, value }) =>
    this.setState({ ...this.state, [name]: !value });

  setDailyProgramAfterAdd = (
    dailyProgram,
    dayIdx,
    moveIdx,
    move,
    movementList,
    action
  ) => {
    this.generateDailyPrograms(
      dailyProgram,
      dayIdx,
      moveIdx,
      move,
      movementList,
      action
    );
  };
  async generateDailyPrograms(
    dailyProgram,
    dayIdx,
    moveIdx,
    move,
    movementList,
    action
  ) {
    let drivings = getDrivingArray(dailyProgram);
    // this.setState({ totalGetDurations: drivings.length });
    let newDP = await this.getDrivingDurations3(drivings, dailyProgram);
    newDP = this.fixDrivingDestination(newDP);
    let isBeforeDepartures = isBeforeDeparture(newDP, dayIdx, moveIdx);
    if (action === 'ADD') {
      if (dayIdx !== newDP.length - 1 && !isBeforeDepartures) {
        newDP = getFunctionDP(newDP, dayIdx, moveIdx, move, movementList);
      } else
        newDP = getFunctionDPBot(newDP, dayIdx, moveIdx, move, movementList);
    } else {
      if (dayIdx !== newDP.length - 1 && !isBeforeDepartures) {
        newDP = editFunctionDP(newDP, dayIdx, moveIdx, move, movementList);
      } else
        newDP = editFunctionDPBot(newDP, dayIdx, moveIdx, move, movementList);
    }
    this.props.setDailyProgram(newDP);
  }
  async getDrivingDurations3(drivings, daillyProgram) {
    let { durationStore } = this.props;
    let dp = copyObject(daillyProgram);
    for (var i = 0; i < drivings.length; i++) {
      let obj = drivings[i];
      let existingDuration = durationStore.find(
        item => item.from === obj.from && item.to === obj.to
      );
      if (existingDuration) {
        dp[obj.day].Movements[obj.index].Duration = existingDuration.duration;
      } else if (obj.fromType === 'ID' && obj.toType === 'ADDRESS') {
        let response = await this.props.getDurationIdtoAddress(
          obj.from,
          obj.to,
          obj.day,
          obj.index
        );

        dp[obj.day].Movements[obj.index].Duration = response
          ? response.payload.Duration.value
          : 0;
      } else if (obj.fromType === 'ADDRESS' && obj.toType === 'ID') {
        let response = await this.props.getDurationAddresstoId(
          obj.from,
          obj.to,
          obj.day,
          obj.index
        );
        dp[obj.day].Movements[obj.index].Duration = response
          ? response.payload.Duration.value
          : 0;
      } else {
        let response = await this.props.getDurationIdtoId(
          obj.from,
          obj.to,
          obj.day,
          obj.index
        );
        dp[obj.day].Movements[obj.index].Duration = response
          ? response.payload.Duration.value
          : 0;
      }
    }
    return dp;
  }

  deleteAct = async (moveIdx, dayIdx) => {
    let { dailyProgram } = this.props;
    let newDP = deleteActivity(dayIdx, moveIdx, dailyProgram);
    newDP = this.fixDrivingDestination(newDP);
    let drivings = getDrivingArray(newDP);
    newDP = await this.getDrivingDurations3(drivings, newDP);
    newDP = fixafterDelete(dailyProgram, newDP, dayIdx, moveIdx);
    this.props.setDailyProgram(newDP);
  };
  fixDrivingDestination = dailyProgram => {
    return dailyProgram.map(daily => {
      daily.Movements.map((move, i) => {
        if (move.MovementName === 'DRIVING') {
          let prevMove = daily.Movements[i - 1];
          move.Destination = prevMove ? prevMove.Destination : move.Destination;
          move.DestinationName = prevMove
            ? prevMove.DestinationName
            : move.DestinationName;
        }
        return move;
      });
      return daily;
    });
  };
  deleteTransportItem = (dayIdx, moveIdx) => {
    this.deleteTransport(dayIdx, moveIdx);
  };
  deleteTransport = (dayIdx, moveIdx) => {
    const { dailyProgram } = this.props;
    let newDP = deleteTransportation(dailyProgram, dayIdx, moveIdx);
    this.props.setDailyProgram(newDP);
  };
  setTransportItem = (dayIdx, moveIdx, transport, service, hour) => {
    this.setTransport(dayIdx, moveIdx, transport, service, hour);
  };
  setTransport = (dayIdx, moveIdx, transport, service, hour) => {
    const { dailyProgram } = this.props;
    let newDP = addItemTransportation(
      dailyProgram,
      dayIdx,
      moveIdx,
      transport,
      service,
      hour
    );
    this.props.setDailyProgram(newDP);
  };

  render() {
    const props = this.props;
    const { openCollapse, transportStore } = this.state;
    const {
      currentPackage,
      isPublished,
      isPackageMangement,
      isReadyFixedPrice,
    } = this.props;
    return (
      <Segment>
        <div style={styles.marginContentInsideCard}>
          <Grid verticalAlign="middle">
            <Grid.Row style={styles.noPaddingBottom}>
              <Grid.Column width={14}>
                <Header as="h3" style={styles.marginHeader}>
                  <Icon name="clipboard list" style={{ color: '#38af95' }} />
                  Itinerary
                </Header>
              </Grid.Column>
              <Grid.Column width={2} textAlign="left">
                <CollapseButton
                  isCollapse={openCollapse}
                  handleCollapse={this.handleCollapse}
                  name="openCollapse"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Collapse isOpened={openCollapse} style={{ marginTop: '0px' }}>
            {isAccommodationEmpty(props.headlineProgram.MainPrograms) ===
            false ? (
              <DailyProgram
                dailyProgram={props.dailyProgram}
                setDailyProgramAfterAdd={this.setDailyProgramAfterAdd}
                typePackage={props.typePackage}
                deleteActivity={this.deleteAct}
                deleteTransportItem={this.deleteTransportItem}
                setTransportItem={this.setTransportItem}
                transportStore={transportStore}
                guests={props.guests}
                setDailyProgram={this.props.setDailyProgram}
                setTourOperator={this.props.setTourOperator}
                currentPackage={currentPackage}
                isPublished={isPublished}
                isStatic={!this.props.isStatic}
                isPackageMangement={isPackageMangement}
                isReadyFixedPrice={isReadyFixedPrice}
              />
            ) : (
              <h4 style={{ textAlign: 'center' }}>
                Please complete general information and destinations summary
              </h4>
            )}
          </Collapse>
        </div>
      </Segment>
    );
  }
}
const mapStateToProps = state => {
  return {
    durationStore: state.PackageStore.durationStore,
    cityInCountry: state.CityStore.getCityInCountryList,
  };
};

TourItinerary.propTypes = {
  setDailyProgram: PropTypes.func,
  getDurationIdtoAddress: PropTypes.func,
  getDurationAddresstoId: PropTypes.func,
  getDurationIdtoId: PropTypes.func,
  typePackage: PropTypes.string,
  dailyProgram: PropTypes.array,
  headlineProgram: PropTypes.object,
  movementList: PropTypes.array,
  setheadlineProgram: PropTypes.func,
  loading: PropTypes.bool,
  guests: PropTypes.object,
  setTourOperator: PropTypes.func,
  currentPackage: PropTypes.object,
  isStatic: PropTypes.bool,
  isPackageMangement: PropTypes.bool,
  // getMasterReferenceAction: PropTypes.func,
  isPublished: PropTypes.bool,
  durationStore: PropTypes.object,
  isReadyFixedPrice: PropTypes.bool,
  isPackageManagemenEdit: PropTypes.bool,
  cityInCountry: PropTypes.array,
};

export default connect(mapStateToProps, {
  getDurationIdtoAddress,
  getDurationAddresstoId,
  getDurationIdtoId,
  // getMasterReferenceAction,
})(TourItinerary);
