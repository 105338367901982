import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Input,
  Pagination,
  Grid,
  Segment,
  Container,
  Button,
} from 'semantic-ui-react';
import TablePaymentHistory from './table/TablePaymentHistory';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../scripts/pagination';
import moment from 'moment';
import HeaderTitle from '../../../components/common/headerTitle';
import { getPaymentHistoryAction } from '../homeAction';
import { convertDateFormat, addDate } from '../../../scripts/moment';
import ModalDateRange from '../../shared/components/modal/ModalDateRange';
import { getUserRoleByBearerAction } from '../../../actions/userAccessAction';
import { checkUser } from '../../../scripts/authorization';
class PaymentHistory extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
      headerTable: [
        'Booking Number',
        'Tour Name',
        'Company Name',
        'Description',
        'Amount',
        'Payment Date',
        // 'Payment Detail',
      ],
      paymentList: [],
      loading: false,
      dateRangePaymentHistory: {
        value: '',
        createStartDate: '',
        createEndDate: '',
        tourStartDate: convertDateFormat(new Date(), 'YYYY-MM-DD'),
        tourEndDate: convertDateFormat(
          addDate(new Date(), 30, 'd'),
          'YYYY-MM-DD'
        ),
      },
      radioValueDateRange: '1',
      openModalFilterBooking: false,
      isFinance: null,
    };
  }

  loadDataWithDate = (
    tourStartDate,
    tourEndDate,
    createStartDate,
    createEndDate
  ) => {
    this.setState({ loading: true });
    this.props
      .getPaymentHistoryAction(
        tourStartDate,
        tourEndDate,
        createStartDate,
        createEndDate
      )
      .then(res =>
        this.setState({ paymentList: res.value.data, loading: false })
      )
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  componentDidMount() {
    const {
      createStartDate,
      createEndDate,
      tourStartDate,
      tourEndDate,
    } = this.state.dateRangePaymentHistory;
    this.loadDataWithDate(
      tourStartDate,
      tourEndDate,
      createStartDate,
      createEndDate
    );
    this.props.getUserRoleByBearerAction().then(() => {
      let authorization = checkUser(this.props.userRoles.Roles);
      this.setState({ ...this.state, isFinance: authorization.isFinance });
    });
  }

  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  filteredData = data =>
    data.filter(data => {
      return (
        data.BookingName.toLowerCase().indexOf(
          this.state.searchInput.toLowerCase()
        ) !== -1 ||
        data.BookingId.toLowerCase().indexOf(
          this.state.searchInput.toLowerCase()
        ) !== -1 ||
        data.CompanyCode.toLowerCase().indexOf(
          this.state.searchInput.toLowerCase()
        ) !== -1 ||
        data.CompanyName.toLowerCase().indexOf(
          this.state.searchInput.toLowerCase()
        ) !== -1 ||
        data.Description.toLowerCase().indexOf(
          this.state.searchInput.toLowerCase()
        ) !== -1 ||
        (data &&
          data.PaymentDate &&
          data.PaymentDate.toLowerCase().indexOf(
            this.state.searchInput.toLowerCase()
          ) !== -1)
      );
    });

  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };

  handleRadioDateRange = value => {
    this.setState({ radioValueDateRange: value });
  };

  actionDateRange = id => {
    const { radioValueDateRange } = this.state;
    const value = radioValueDateRange;
    const { from, to } = id;
    // const { statusFilter } = dateRangePaymentHistory;
    this.setState({
      openModalFilterBooking: false,
    });
    let setDate = {};
    if (value === '2') {
      this.loadDataWithDate(
        moment(from).format('YYYY-MM-DD'),
        moment(to).format('YYYY-MM-DD'),
        '',
        ''
      );
      setDate = {
        createStartDate: from,
        createEndDate: to,
        tourStartDate: '',
        tourEndDate: '',
      };
      this.setState({ dateRangePaymentHistory: setDate });
    } else {
      this.loadDataWithDate(
        '',
        '',
        moment(from).format('YYYY-MM-DD'),
        moment(to).format('YYYY-MM-DD')
      );
      setDate = {
        createStartDate: '',
        createEndDate: '',
        tourStartDate: from,
        tourEndDate: to,
      };
      this.setState({ dateRangePaymentHistory: setDate });
    }
  };

  openModalDateRange = () =>
    this.setState({
      openModalFilterBooking: true,
      dateRangePaymentHistory: {
        createStartDate: '',
        createEndDate: '',
        tourStartDate: '',
        tourEndDate: '',
      },
    });

  handleCloseFilterBooking = () => {
    const { openModalFilterBooking } = this.state;
    this.setState({
      openModalFilterBooking: !openModalFilterBooking,
    });
  };
  render() {
    const {
      paymentList,
      activePage,
      dateRangePaymentHistory,
      searchInput,
      loading,
      headerTable,
      radioValueDateRange,
      isFinance,
    } = this.state;
    let filterData = this.filteredData(paymentList);
    let pagedData = datasetPagination(filterData, 5);
    let listData = pagedData[activePage - 1];
    let { numbersFrom, numbersTo } = getNumberFromTo(listData, activePage, 5);
    let createEndDate =
      dateRangePaymentHistory.createEndDate === ''
        ? ''
        : moment(dateRangePaymentHistory.createEndDate).format('DD MMM YYYY');
    let createStartDate =
      dateRangePaymentHistory.createStartDate === ''
        ? ''
        : moment(dateRangePaymentHistory.createStartDate).format('DD MMM');
    let tourStartDate =
      dateRangePaymentHistory.tourStartDate === ''
        ? ''
        : moment(dateRangePaymentHistory.tourStartDate).format('DD MMM');
    let tourEndDate =
      dateRangePaymentHistory.tourEndDate === ''
        ? ''
        : moment(dateRangePaymentHistory.tourEndDate).format('DD MMM YYYY');
    return (
      <div>
        <Grid columns="equal" style={{ marginTop: '30px' }}>
          <Grid.Row stretched>
            <Grid.Column width={15}>
              <Segment>
                <HeaderTitle title="Customer Payment History" />
                <Input
                  icon="search"
                  placeholder="search"
                  name="searchInput"
                  value={searchInput}
                  onChange={this.handleChange}
                />
                <Button
                  icon="filter"
                  basic
                  size="small"
                  floated="right"
                  positive
                  id="List Of Payment History"
                  name="PaymentHistory"
                  onClick={this.openModalDateRange}
                />
                <div
                  style={{
                    marginTop: '-2.9em',
                    marginRight: '5em',
                    fontWeight: 'bold',
                    marginBottom: '3em',
                  }}
                >
                  {/* {(tourStartDate !== '' || tourEndDate !== '') && ( */}
                  <Container
                    content={
                      'Start tour range date : ' +
                      tourStartDate +
                      ' - ' +
                      tourEndDate
                    }
                    textAlign="right"
                  />
                  {/* )}
                  {(createStartDate !== '' || createEndDate !== '') && ( */}
                  <Container
                    content={
                      'Created tour range date : ' +
                      createStartDate +
                      ' - ' +
                      createEndDate
                    }
                    textAlign="right"
                  />
                  {/* )} */}
                </div>
                <TablePaymentHistory
                  data={paymentList}
                  headerTable={headerTable}
                  pageChange={this.pageChange}
                  listData={listData}
                  pagedData={pagedData}
                  loading={loading}
                  isFinance={isFinance}
                />
                {pagedData && pagedData.length > 1 && (
                  <Pagination
                    totalPages={pagedData.length}
                    onPageChange={this.pageChange}
                    activePage={activePage}
                    style={{ float: 'right' }}
                  />
                )}
                {pagedData && pagedData.length > 1 && (
                  <p>
                    Showing {numbersTo} to {numbersFrom} of
                    {paymentList.length}
                  </p>
                )}
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <ModalDateRange
            openModal={this.state.openModalFilterBooking}
            handleClose={this.handleCloseFilterBooking}
            dateRange={dateRangePaymentHistory}
            actionDateRange={this.actionDateRange}
            contentHeader="Booking Confirmation"
            value={radioValueDateRange}
            handleRadioDateRange={this.handleRadioDateRange}
          />
        </Grid>
      </div>
    );
  }
}

PaymentHistory.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  getPaymentHistoryAction: PropTypes.func,
  actionDateRange: PropTypes.func,
  getUserRoleByBearerAction: PropTypes.func,
  userRoles: PropTypes.object,
};
function mapStateToProps(state) {
  return {
    userRoles: state.UserAccessStore.getUserRoleByBearer,
  };
}

export default connect(mapStateToProps, {
  getPaymentHistoryAction,
  getUserRoleByBearerAction,
})(PaymentHistory);
