import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';
import { StyleModal } from '../../../assets/styles/size';
import ButtonClose from '../../../components/button/buttonCloseModal';
import ModalLoader from '../../../components/modal/loaderModal';
import PlaceForm from '../form/PlaceForm';
const ModalPlace = props => {
  return (
    <Modal
      dimmer="blurring"
      // closeIcon
      // inverted
      open={props.open}
      onClose={props.handleClose}
      size="small"
      style={StyleModal.modalWidth}
    >
      <Modal.Header>
        {props.header} <ButtonClose onClose={props.handleClose} />
      </Modal.Header>
      <Modal.Content>
        <ModalLoader loading={props.loading} />
        <PlaceForm
          handleChange={props.handleChange}
          handleChangeAddress={props.handleChangeAddress}
          contentField={props.contentField}
          getPlaceType={props.getPlaceType}
          listCountry={props.listCountry}
          listRegion={props.listRegion}
          listCity={props.listCity}
          listArea={props.listArea}
          errorInformation={props.errorInformation}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={props.savePlace}>
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

ModalPlace.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  listCountry: PropTypes.array,
  listRegion: PropTypes.array,
  handleChange: PropTypes.func,
  handleChangeAddress: PropTypes.func,
  contentField: PropTypes.shape({
    Id: PropTypes.string,
    Name: PropTypes.string,
    Address: PropTypes.string,
    Code: PropTypes.string,
    CityId: PropTypes.string,
    PlaceType: PropTypes.string,
    AddressObject: PropTypes.shape({
      Address1: PropTypes.string,
      Address2: PropTypes.string,
      Address3: PropTypes.string,
      AreaId: PropTypes.string,
      PostalCode: PropTypes.string,
      LandMark: PropTypes.string,
      Country: PropTypes.string,
      Region: PropTypes.string,
    }),
  }),
  errorInformation: PropTypes.object,
  getPlaceType: PropTypes.array,
  listCity: PropTypes.array,
  listArea: PropTypes.array,
  savePlace: PropTypes.func,
  loading: PropTypes.bool,
};
export default ModalPlace;
