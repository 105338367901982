const setPlaceIdObject = state => {
  const {
    Id,
    Name,
    Address,
    Code,
    CityId,
    PlaceType,
    AddressObject,
    Country,
    City,
    Region,
  } = state;
  let placeById = {
    Id: Id ? Id : '',
    Name: Name ? Name : '',
    Address: Address ? Address : '',
    Code: Code ? Code : '',
    CityId: CityId ? CityId : '',
    PlaceType: PlaceType ? PlaceType : '',
    AddressObject: {
      Id: AddressObject.Id ? AddressObject.Id : '',
      Address1: AddressObject.Address1 ? AddressObject.Address1 : '',
      Address2: AddressObject.Address2 ? AddressObject.Address2 : '',
      Address3: AddressObject.Address3 ? AddressObject.Address3 : '',
      AreaId: AddressObject.AreaId ? AddressObject.AreaId : '',
      PostalCode: AddressObject.PostalCode ? AddressObject.PostalCode : '',
      LandMark: AddressObject.Landmark ? AddressObject.Landmark : '',
      Country: Country.Id ? Country.Id : '',
      City: City.Id ? City.Id : '',
      Region: Region.Id ? Region.Id : '',
    },
  };
  return placeById;
};

export { setPlaceIdObject };
