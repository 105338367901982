import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Form, Message } from 'semantic-ui-react';
import styles from './styles';
import {
  getHourAndMinute,
  convertDateFormat,
} from '../../genericFunction/moment';
import ItemWithIcon from '../../common/ItemWithIcon';
import { isEarlyCheck } from '../../../scripts/itineraryBuilder/dailyProgram';
class accommodationCard extends Component {
  constructor(props) {
    super(props);
    let checkIn = props.data.AccommodationSummary.CheckIn
      ? props.data.AccommodationSummary.CheckIn
      : props.data.AccommodationSummary.Date;
    let checkOut = props.data.AccommodationSummary.CheckOut
      ? props.data.AccommodationSummary.CheckOut
      : props.data.AccommodationSummary.LeavingDate;
    this.state = {
      checkinDate: checkIn,
      checkoutDate: checkOut,
      checkinTime: getHourAndMinute(checkIn),
      checkoutTime: getHourAndMinute(checkOut),
      destination: props.data.City ? props.data.City.Id : null,
    };
  }
  componentDidUpdate() {
    const { checkinDate, checkoutDate, checkinTime, checkoutTime } = this.state;
    const CheckIn = this.props.data.AccommodationSummary.CheckIn
      ? this.props.data.AccommodationSummary.CheckIn
      : this.props.data.AccommodationSummary.Date;
    const CheckOut = this.props.data.AccommodationSummary.CheckOut
      ? this.props.data.AccommodationSummary.CheckOut
      : this.props.data.AccommodationSummary.LeavingDate;
    // const { CheckIn, CheckOut } = this.props.data.AccommodationSummary;
    if (
      checkinDate !== CheckIn ||
      checkoutDate !== CheckOut ||
      checkinTime !== getHourAndMinute(CheckIn) ||
      checkoutTime !== getHourAndMinute(CheckOut)
    ) {
      this.setState({
        ...this.state,
        checkinDate: CheckIn,
        checkoutDate: CheckOut,
        checkinTime: getHourAndMinute(CheckIn),
        checkoutTime: getHourAndMinute(CheckOut),
      });
    }
  }

  getAccommodationName = data => {
    return data.AccommodationSummary.Name
      ? `${data.AccommodationSummary.Name}, ${
          data.AccommodationSummary.RoomName
        }, ${data.AccommodationSummary.RoomService.replace('_', ' ')}`
      : '-';
  };
  isEven = num => {
    return num % 2 === 0 ? true : false;
  };
  render() {
    const { data, index } = this.props;
    const {
      checkinTime,
      checkoutTime, // destination,
      checkinDate,
      checkoutDate,
    } = this.state;
    const accommodation = this.getAccommodationName(data);
    const destination = data.City ? data.City.Name : '-';
    const styleBg =
      index === 0 || this.isEven(index) ? styles.backgroundGoldTransparent : {};
    const earlyCheck =
      this.props.isFixedPackageMangement || this.props.isReadyFixedPrice
        ? false
        : isEarlyCheck(data.AccommodationSummary.CheckIn);
    return (
      <div>
        <Grid style={styleBg}>
          <Grid.Row>
            <Grid.Column width={16}>
              <Form>
                <Form.Group widths="equal">
                  <Form.Field width={16}>
                    <ItemWithIcon
                      labelText="Checkin Date"
                      labelStyle={{
                        display: 'block',
                        fontWeight: 'bold',
                        marginBottom: '4px',
                      }}
                      withIcon={true}
                      iconStyle={styles.goldColor}
                      descriptionText={convertDateFormat(
                        checkinDate,
                        'DD MMM YYYY'
                      )}
                      iconName="calendar"
                    />
                  </Form.Field>
                  <Form.Field width={16}>
                    <ItemWithIcon
                      labelText="Checkin Time"
                      labelStyle={{
                        display: 'block',
                        fontWeight: 'bold',
                        marginBottom: '4px',
                      }}
                      withIcon={true}
                      iconStyle={styles.goldColor}
                      descriptionText={checkinTime}
                      iconName="clock"
                    />
                  </Form.Field>
                  <Form.Field width={16}>
                    <ItemWithIcon
                      labelText="Total Days"
                      labelStyle={{
                        display: 'block',
                        fontWeight: 'bold',
                        marginBottom: '4px',
                      }}
                      withIcon={false}
                      descriptionText={data.TotalDays}
                      descriptionStyle={styles.textCenter}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field width={16}>
                    <ItemWithIcon
                      labelText="Checkout Date"
                      labelStyle={{
                        display: 'block',
                        fontWeight: 'bold',
                        marginBottom: '4px',
                      }}
                      withIcon={true}
                      iconStyle={styles.goldColor}
                      descriptionText={convertDateFormat(
                        checkoutDate,
                        'DD MMM YYYY'
                      )}
                      // descriptionText={convertDateFormat(
                      //   checkoutDate,
                      //   'DD/MM/YYYY'
                      // )}
                      iconName="calendar"
                    />
                  </Form.Field>
                  <Form.Field width={16}>
                    <ItemWithIcon
                      labelText="Checkout Time"
                      labelStyle={{
                        display: 'block',
                        fontWeight: 'bold',
                        marginBottom: '4px',
                      }}
                      withIcon={true}
                      iconStyle={styles.goldColor}
                      descriptionText={checkoutTime}
                      iconName="clock"
                    />
                  </Form.Field>
                  <Form.Field width={16}>
                    <ItemWithIcon
                      labelText="City Destination"
                      labelStyle={{
                        display: 'block',
                        fontWeight: 'bold',
                        marginBottom: '4px',
                      }}
                      withIcon={false}
                      iconStyle={styles.goldColor}
                      descriptionText={destination}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Field>
                  <ItemWithIcon
                    labelText="Accommodation"
                    labelStyle={{
                      display: 'block',
                      fontWeight: 'bold',
                      marginBottom: '4px',
                    }}
                    withIcon={false}
                    iconStyle={styles.goldColor}
                    descriptionText={accommodation}
                  />
                </Form.Field>
              </Form>
              {!this.props.isFixedPackageMangement && earlyCheck && (
                <Message warning>
                  <Message.Header>Note:</Message.Header>
                  <p style={{ color: '#f44336' }}>
                    Your check in time is less than 2 PM, there will be extra
                    charge for early checkin.
                  </p>
                </Message>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

accommodationCard.propTypes = {
  index: PropTypes.number,
  data: PropTypes.object,
  isFixedPackageMangement: PropTypes.bool,
  currentPackage: PropTypes.object,
  tourInformation: PropTypes.object,
  isReadyFixedPrice: PropTypes.bool,
};
export default accommodationCard;
