import React from 'react';
import { Image, Container, Header } from 'semantic-ui-react';
import imageContent from '../../assets/logo/ComingSoonWeb.png';
const ComingSoon = () => (
  <div>
    <Image src={imageContent} size="large" centered />
    <Container fluid>
      <Header as="h2" textAlign="center">
        Coming Soon
      </Header>
      <Header as="h3" textAlign="center">
        Features started forming and it will take shape soon​.
      </Header>
    </Container>
  </div>
);

export default ComingSoon;
