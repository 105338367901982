import React from 'react';
import { Grid, Segment, Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import TableCompanyList from './table/TableCompanyList';
import Header from '../../../../components/common/headerTitle';
import ButtonWithIcon from '../../../../components/button/buttonWithIcon';
import Pagination from '../../../../components/common/pagination';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../../scripts/pagination';
import { Redirect } from 'react-router-dom';
class SegmentCompanyProfileList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerTableCompanyProfile: [
        'Company Code',
        'Company Name',
        'Phone Number',
        'Email',
        'Action',
      ],
      searchInput: '',
      gotoLink: '',
      changePage: false,
    };
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
    this.props.handleSetPage(1);
  };

  filteredData = data =>
    data.filter(data => {
      return (
        data.Name.toLowerCase().indexOf(
          this.state.searchInput.toLowerCase()
        ) !== -1 ||
        (data.Code &&
          data.Code.toLowerCase().indexOf(
            this.state.searchInput.toLowerCase()
          ) !== -1) ||
        (data.Email &&
          data.Email.toLowerCase().indexOf(
            this.state.searchInput.toLowerCase()
          ) !== -1)
      );
    });

  addNew = () => {
    let link = `/company-profile-detail/create/0`;
    this.setState({ changePage: true, gotoLink: link });
  };
  editCompanyProfile = (e, { value }) => {
    let link = `/company-profile-detail/edit/${value}`;
    this.setState({ changePage: true, gotoLink: link });
  };
  deleteCompanyProfile = (e, { name, value }) => {
    let { confirmDeleteSupplier } = this.props;
    confirmDeleteSupplier(name, value);
  };
  render() {
    const { data, loading, activePage, pageChange } = this.props;
    const { headerTableCompanyProfile, gotoLink, changePage } = this.state;
    let filterData = this.filteredData(data);
    let pagedData = datasetPagination(filterData, 7);
    let listData = pagedData[activePage - 1];

    let { numbersFrom, numbersTo } = getNumberFromTo(listData, activePage, 7);
    if (changePage) {
      return <Redirect to={gotoLink} />;
    }
    return (
      <Grid columns="equal" style={{ marginTop: '30px' }}>
        <Grid.Row stretched>
          <Grid.Column>
            <Segment loading={loading}>
              <Header title="Supplier" />
              <Input
                icon="search"
                placeholder="search"
                name="searchInput"
                value={this.state.searchInput}
                onChange={this.handleChange}
              />
              <ButtonWithIcon
                text="Add New"
                iconName="add"
                handleOnClick={this.addNew}
                positive={true}
                floated="right"
              />
              <TableCompanyList
                listData={listData}
                editCompanyProfile={this.editCompanyProfile}
                deleteCompanyProfile={this.deleteCompanyProfile}
                headerTable={headerTableCompanyProfile}
                pageChange={pageChange}
              />
              <Pagination
                pagedData={pagedData}
                numbersTo={numbersTo}
                numbersFrom={numbersFrom}
                data={data}
                pageChange={pageChange}
                activePage={activePage}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

SegmentCompanyProfileList.propTypes = {
  data: PropTypes.array,
  confirmDeleteSupplier: PropTypes.func,
  loading: PropTypes.bool,
  activePage: PropTypes.number,
  pageChange: PropTypes.func,
  handleSetPage: PropTypes.func,
};
export default SegmentCompanyProfileList;
