import apiClient from '../apiClient';
import { PLACE_CITIES_URL } from '../apiUrl';

const getPlaceCitiesListByPlaceIdApi = id => {
  return apiClient.get(`${PLACE_CITIES_URL}?placeId=${id}`);
};

const putPlaceCitiesByIdApi = (id, data) => {
  return apiClient.put(`${PLACE_CITIES_URL}/${id}`, data);
};

const postPlaceCitiesApi = data => {
  return apiClient.post(PLACE_CITIES_URL, data);
};
const deletePlaceCitiesByIdApi = id => {
  return apiClient.delete(`${PLACE_CITIES_URL}/${id}`);
};
export {
  getPlaceCitiesListByPlaceIdApi,
  putPlaceCitiesByIdApi,
  postPlaceCitiesApi,
  deletePlaceCitiesByIdApi,
};
