import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Header, Icon } from 'semantic-ui-react';
import styles from './styles';

const menuItem = props => (
  <Link to={props.link} onClick={props.handleClickConfirm} title={props.name}>
    {/* onClick={props.handleClickConfirm} */}
    <Header as="h5" style={styles.submenu}>
      {props.icon ? (
        <Icon name={props.icon} size="mini" style={styles.submenuIcon} />
      ) : null}
      {props.title}
    </Header>
  </Link>
);

menuItem.propTypes = {
  link: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
  handleClickConfirm: PropTypes.func,
};

export default menuItem;
