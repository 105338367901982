import React from 'react';
import { Grid, Segment, Header, Form, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ContactInformation from '../../../shared/components/contactInfo/ContactInformation';
import ExcrutionGeneralInfo from '../form/GeneralInformation'; // './ExcurtionGeneralInfo';
import TableSupplierPrice from './TablePriceExcursion';
//import TableAccessibleCity from '../table/tableAccessibleCity';
import ImageListComponent from '../../../../components/image/image';
import { HeaderExcurtionPrice } from '../../constant/headerTable';
import InputTime from '../../../../components/input/inputTime';
import ModalImage from '../../../../components/modal/modalImage';
import { HeaderTableImageAccomodationProfile } from '../../../../components/table/headerTabel';
import ModalAccessibleCity from '../../../../components/modal/modalAccessibleCity';
import AddingDataWithTable from '../../../../components/globalListVew/addingDataComponent';
import { HeaderTableAccessibleCity } from '../../../../components/table/headerTabel';
import ButtonGroupSupplier from '../../../../components/common/ButtonGroupSupplier';
import HeaderTitle from '../../../../components/common/headerTitle';
const ExcursionProfileDetails = props => (
  <div>
    <Segment style={{ marginTop: '30px' }}>
      <Grid columns={2} padded="vertically">
        <Grid.Column>
          {/* Kiri */}
          <HeaderTitle title={props.title} />
          <ExcrutionGeneralInfo
            handleChange={props.handleChange}
            generalInfo={props.excursion}
            errors={props.errors}
            excursionTypeList={props.excursionTypeList}
            excurtionCategory={props.excurtionCategory}
            onChangeDropdown={props.onChangeDropdown}
            disablePage={props.disablePage}
          />
          <ContactInformation
            errors={props.errors}
            contactObject={props.contactObject}
            listCountry={props.listCountry}
            onChangeDropdown={props.onChangeDropdown}
            listRegion={props.listRegion}
            listCity={props.listCityByRegionForContactInfo}
            listArea={props.listArea}
            handleChange={props.onChangeDropdown}
            disablePage={props.disablePage}
          />
        </Grid.Column>
        <Grid.Column>
          <div
            style={{
              marginLeft: '24.2em',
              marginTop: '2em',
            }}
          >
            <Button
              primary
              onClick={props.backPage}
              name={'Back'}
              icon="angle left"
              content={'Back'}
            />
            <Button
              primary
              onClick={props.saveExcurtion}
              name={props.contentButton}
              content={props.contentButton}
            />
          </div>
          <ImageListComponent
            profileImageList={props.filterDataImage}
            addData={props.openModal}
            buttonContent="Add Image"
            headerContent="Image"
            deleteData={props.deleteImage}
            headerTable={HeaderTableImageAccomodationProfile}
            listData={props.listDataImage}
            idButton="image"
            openDetail={props.openDetail}
            handleChangePrimaryImage={props.handleChangePrimaryImage}
            disablePage={props.disablePage}
            pattern="Attractions/"
            activePage={props.activePageImage}
            pagedData={props.pagedData}
            pageChange={props.pageChangeImage}
          />
          <ModalImage
            openModal={props.openModalImage}
            imagePreview={props.imagePreview}
            handleChange={props.changeImage}
            addImage={props.addImage}
            handleClose={props.handleClose}
            header="Upload Image"
            image={props.excursion.ObjectImages}
            listStepImage={props.listStepImage}
            handleNext={props.handleNext}
            handleBackStep={props.handleBackStep}
            indexActive={props.indexActive}
            handleChangePrimaryImage={props.handleChangePrimaryImage}
            imageRatio={props.image}
          />
          <Grid columns="equal">
            <Grid.Row stretched>
              <Grid.Column>
                <Header as="h3" content="Opening Hour" />
                <Form>
                  <Form.Group>
                    <InputTime
                      labelText="Open Hour"
                      labelStyles={{
                        display: 'block',
                        fontWeight: 'bold',
                      }}
                      inputStyles={{
                        minHeight: '100',
                        width: '220px',
                      }}
                      value={props.excursion.OperationStartTime}
                      setTime={props.setStartTime}
                      disable={props.disablePage}
                      name="OperationStartTime"
                    />
                    <InputTime
                      labelText="Close Hour"
                      labelStyles={{
                        display: 'block',
                        fontWeight: 'bold',
                        marginLeft: '20px ',
                      }}
                      inputStyles={{
                        minHeight: '100',
                        width: '220px',
                        marginLeft: '20px ',
                      }}
                      value={props.excursion.OperationEndTime}
                      name="OperationEndTime"
                      setTime={props.setEndTime}
                      disable={props.disablePage}
                    />
                  </Form.Group>
                  <Form.Group>
                    <InputTime
                      labelText="Minimum Hour"
                      labelStyles={{
                        display: 'block',
                        fontWeight: 'bold',
                      }}
                      inputStyles={{
                        minHeight: '100',
                        width: '220px',
                      }}
                      value={props.excursion.OptimumDuration}
                      setTime={props.setMinimumHour}
                      disable={props.disablePage}
                      name="OptimumDuration"
                      validate={props.errors}
                    />
                  </Form.Group>
                </Form>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Form>
                  <Form.Group inline>
                    <label>Is Excursion Start Time Fixed ?</label>
                    <Form.Radio
                      name="IsSolidStartTime"
                      label="Yes"
                      value={true}
                      checked={props.excursion.IsSolidStartTime === true}
                      onChange={props.handleChangeRadio}
                      disabled={props.disablePage}
                    />
                    <Form.Radio
                      name="IsSolidStartTime"
                      label="No"
                      value={false}
                      checked={props.excursion.IsSolidStartTime === false}
                      onChange={props.handleChangeRadio}
                      disabled={props.disablePage}
                    />
                  </Form.Group>
                </Form>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Form>
                  <Form.Group inline>
                    <label>Is Excursion Duration Time Fixed ? </label>
                    <Form.Radio
                      name="IsSolidDuration"
                      label="Yes"
                      value={true}
                      checked={props.excursion.IsSolidDuration === true}
                      onChange={props.handleChangeRadio}
                      disabled={props.disablePage}
                    />
                    <Form.Radio
                      name="IsSolidDuration"
                      label="No"
                      value={false}
                      checked={props.excursion.IsSolidDuration === false}
                      onChange={props.handleChangeRadio}
                      disabled={props.disablePage}
                    />
                  </Form.Group>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid columns="3">
            <Grid.Row stretched>
              <Grid.Column width={16}>
                <AddingDataWithTable
                  addingDataList={props.filterDataAccessCity}
                  addData={props.onClickModalAccCity}
                  buttonContent="Add City"
                  headerContent="Accessible City"
                  headerTable={HeaderTableAccessibleCity}
                  // listData={props.accessCity}
                  deleteData={props.deleteAccessCity}
                  idButton="city"
                  disablePage={props.disablePage}
                  listAddingData={props.listDataAccessCity}
                  pageAddingData={props.pagedDataAccessCity}
                  activePage={props.activePage}
                  pageChange={props.pageChange}
                  loadingShowListCity={props.loadingShowListCity}
                />
                <ModalAccessibleCity
                  header="Accessible City"
                  openModal={props.openModalAccCity}
                  handleClose={props.handleCloseAccCity}
                  listData={props.listAccessCity}
                  handleChangeAccessCity={props.handleChangeAccessCity}
                  pageChange={props.pageChangeModalCity}
                  activePage={props.activePageModalCity}
                  pageData={props.pageDataCity}
                  addAccessCity={props.addAccessCity}
                  searchInput={props.searchInput}
                  handleChange={props.handleSearchAccesibleCity}
                  listAllRegions={props.listAllRegions}
                  handleChangeRegionAccessibleCity={
                    props.handleChangeRegionAccessibleCity
                  }
                  regionForAccessibleCity={props.regionForAccessibleCity}
                  loadingGetCityByRegion={props.loadingGetCityByRegion}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid>
      <Grid>
        <Grid.Row>
          <Grid.Column width={5}>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <h3>Excursion Price</h3>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column floated="right" width={11}>
            <Grid>
              <Grid.Row>
                {/* <Grid.Column /> */}
                {/* <Grid.Column /> */}
                <Grid.Column floated="right">
                  <ButtonGroupSupplier
                    buttonNew={props.addPrice}
                    butttonExport={props.butttonExport}
                    buttonUploadData={props.buttonUploadData}
                    buttonDownloadTemplate={props.buttonDownloadTemplate}
                    disablePage={props.disablePage}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <TableSupplierPrice
              headerTable={HeaderExcurtionPrice}
              placeholderType="Excurtion Type"
              listData={props.excurtionPrice}
              servicePriceType={props.servicePriceType}
              currenciesList={props.currencies}
              handleChangePrice={props.handleChangePrice}
              handleChangePrices={props.handleChangePrices}
              deletePrice={props.deletePrice}
              errors={props.errors}
              handleEdit={props.handleEdit}
              disablePage={props.disablePage}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  </div>
);
ExcursionProfileDetails.propTypes = {
  filterDataImage: PropTypes.array,
  filterDataAccessCity: PropTypes.array,
  contactObject: PropTypes.object,
  handleChangeRadio: PropTypes.func,
  excursion: PropTypes.shape({
    IsSolidDuration: PropTypes.bool,
    IsSolidStartTime: PropTypes.bool,
    OperationEndTime: PropTypes.string,
    OperationStartTime: PropTypes.string,
    OptimumDuration: PropTypes.string,
    ObjectImages: PropTypes.array,
  }),
  excurtionCategory: PropTypes.array,
  listCountry: PropTypes.array,
  listRegion: PropTypes.array,
  listCityByRegionForContactInfo: PropTypes.array,
  listArea: PropTypes.array,
  handleChange: PropTypes.func,
  onChangeDropdown: PropTypes.func,
  excursionTypeList: PropTypes.array,
  saveExcurtion: PropTypes.func,
  addPrice: PropTypes.func,
  excurtionPrice: PropTypes.array,
  servicePriceType: PropTypes.array,
  currencies: PropTypes.array,
  handleChangePrice: PropTypes.func,
  handleChangePrices: PropTypes.func,
  deletePrice: PropTypes.func,
  errors: PropTypes.object,
  setEndTime: PropTypes.func,
  setStartTime: PropTypes.func,
  openModal: PropTypes.func,
  setMinimumHour: PropTypes.func,
  deleteImage: PropTypes.func,
  openModalImage: PropTypes.bool,
  handleClose: PropTypes.func,
  addImage: PropTypes.func,
  imagePreview: PropTypes.string,
  openDetail: PropTypes.func,
  changeImage: PropTypes.func,
  listStepImage: PropTypes.array,
  handleNext: PropTypes.func,
  handleBackStep: PropTypes.func,
  indexActive: PropTypes.number,
  handleChangePrimaryImage: PropTypes.func,
  image: PropTypes.object,
  openModalAccCity: PropTypes.bool,
  handleCloseAccCity: PropTypes.func,
  onClickModalAccCity: PropTypes.func,
  listAccessCity: PropTypes.array,
  handleChangeAccessCity: PropTypes.func,
  // accessCity: PropTypes.array,
  pageChange: PropTypes.func,
  activePage: PropTypes.number,
  pageDataCity: PropTypes.array,
  addAccessCity: PropTypes.func,
  deleteAccessCity: PropTypes.func,
  title: PropTypes.string,
  deleteState: PropTypes.func,
  backPage: PropTypes.func,
  disablePage: PropTypes.bool,
  contentButton: PropTypes.string,
  searchInput: PropTypes.string,
  handleSearchAccesibleCity: PropTypes.func,
  buttonUploadData: PropTypes.func,
  butttonExport: PropTypes.func,
  buttonDownloadTemplate: PropTypes.func,
  listDataAccessCity: PropTypes.array,
  pagedDataAccessCity: PropTypes.array,
  pagedData: PropTypes.array,
  listDataImage: PropTypes.array,
  activePageImage: PropTypes.string,
  pageChangeImage: PropTypes.func,
  pageChangeModalCity: PropTypes.func,
  activePageModalCity: PropTypes.string,
  handleEdit: PropTypes.func,
  listAllRegions: PropTypes.array,
  handleChangeRegionAccessibleCity: PropTypes.func,
  regionForAccessibleCity: PropTypes.string,
  loadingGetCityByRegion: PropTypes.bool,
  loadingShowListCity: PropTypes.bool,
};
export default ExcursionProfileDetails;
