import React from 'react';
import { Input, Grid, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { headerRestaurantList } from '../constant/tableHeader';
import TableRestaurantList from './TableRestaurantList';
import ButtonAction from '../../../shared/components/common/button/ButtonGroupSupplierList';
import HeaderTitle from '../../../shared/components/common/headerSegment/HeaderTitle';
import Pagination from '../../../shared/components/common/pagination/Pagination';
import { Styles } from '../../Styles';
const SegmentRestaurantList = props => (
  <div>
    <Grid columns="equal" style={Styles.Segment}>
      <Grid.Row stretched>
        <Grid.Column>
          <Segment>
            <HeaderTitle title={props.title} />
            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Input
                    icon="search"
                    placeholder="search"
                    name="searchInput"
                    value={props.searchInput}
                    onChange={props.handleChange}
                  />
                </Grid.Column>{' '}
                <Grid.Column width={12}>
                  <ButtonAction
                    buttonNew={props.buttonNew}
                    butttonExport={props.butttonExport}
                    buttonUploadData={props.buttonUploadData}
                    buttonDownloadTemplate={props.buttonDownloadTemplate}
                    buttonExportItem={props.buttonExportItem}
                    isSupplierList={true}
                  />{' '}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <TableRestaurantList
              data={props.data}
              headerTable={headerRestaurantList}
              pageChange={props.pageChange}
              listData={props.listData}
              pagedData={props.pagedData}
              butttonEdit={props.butttonEdit}
              buttonDelete={props.buttonDelete}
            />
            <Pagination
              pagedData={props.pagedData}
              numbersTo={props.numbersTo}
              numbersFrom={props.numbersFrom}
              data={props.data}
              pageChange={props.pageChange}
              activePage={props.activePage}
            />
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
);

SegmentRestaurantList.propTypes = {
  listData: PropTypes.array,
  pagedData: PropTypes.array,
  title: PropTypes.string,
  pageChange: PropTypes.func,
  activePage: PropTypes.number,
  searchInput: PropTypes.string,
  handleChange: PropTypes.func,
  data: PropTypes.array,
  buttonNew: PropTypes.func,
  butttonExport: PropTypes.func,
  buttonUploadData: PropTypes.func,
  buttonDownloadTemplate: PropTypes.func,
  butttonEdit: PropTypes.func,
  buttonDelete: PropTypes.func,
  numbersFrom: PropTypes.number,
  numbersTo: PropTypes.number,
  buttonExportItem: PropTypes.func,
};
export default SegmentRestaurantList;
