export const HeaderExcurtionPrice = [
  'Type',
  'Description',
  'Min. Pax',
  'Price',
  'Start Date',
  'End Date',
  'Cutt Of Day',
  'Action',
];
