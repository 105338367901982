import React from 'react';
import { Table, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
const TableSupplierPriceRestaurant = props => (
  <Table size="small" striped>
    <Table.Header>
      <Table.Row>
        {props.headerTable.map((headTableContent, index) => {
          return (
            <Table.HeaderCell key={index} textAlign="center">
              {headTableContent}
            </Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {props.listData &&
        props.listData.map((margin, idx) => {
          return (
            <Table.Row key={idx}>
              <Table.Cell textAlign="right">
                {margin.Currency +
                  '           ' +
                  new Intl.NumberFormat('de-DE').format(margin.Cost)}
              </Table.Cell>
              <Table.Cell textAlign="center">{margin.MinPax}</Table.Cell>
              <Table.Cell textAlign="center">
                <Button
                  content="Edit"
                  positive
                  value={margin.Id}
                  id={idx}
                  onClick={props.handleEdit}
                />
                <Button
                  content="Delete"
                  negative
                  value={margin.Id}
                  id={idx}
                  name={margin.Cost}
                  onClick={props.deletePrice}
                />
              </Table.Cell>
            </Table.Row>
          );
        })}
    </Table.Body>
  </Table>
);

TableSupplierPriceRestaurant.propTypes = {
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  excurtionTypePrice: PropTypes.array,
  currenciesList: PropTypes.array,
  handleChangePrice: PropTypes.func,
  handleChangeCostPriceDetails: PropTypes.func,
  handleChangePriceDetails: PropTypes.func,
  deletePrice: PropTypes.func,
  currencies: PropTypes.array,
  handleEdit: PropTypes.func,
};
export default TableSupplierPriceRestaurant;
