const RESET_STATUS_ACCOMODATION_PAGE = 'RESET_STATUS_ACCOMODATION';

//Accomodation By Profile Id
const GET_ACCOMODATION_TYPES = 'GET_ACCOMODATION_TYPES';
const GET_ACCOMODATION_TYPES_FULFILLED = 'GET_ACCOMODATION_TYPES_FULFILLED';
const GET_ACCOMODATION_TYPES_PENDING = 'GET_ACCOMODATION_TYPES_PENDING';
const GET_ACCOMODATION_TYPES_REJECTED = 'GET_ACCOMODATION_TYPES_REJECTED';

//Accomodation Facilities
const GET_ACCOMODATION_FACILITIES = 'GET_ACCOMODATION_FACILITIES';
const GET_ACCOMODATION_FACILITIES_FULFILLED =
  'GET_ACCOMODATION_FACILITIES_FULFILLED';
const GET_ACCOMODATION_FACILITIES_PENDING =
  'GET_ACCOMODATION_FACILITIES_PENDING';
const GET_ACCOMODATION_FACILITIES_REJECTED =
  'GET_ACCOMODATION_FACILITIES_REJECTED';

//Accomodation Location
const GET_ACCOMODATION_LOCATION = 'GET_ACCOMODATION_LOCATION';
const GET_ACCOMODATION_LOCATION_FULFILLED =
  'GET_ACCOMODATION_LOCATION_FULFILLED';
const GET_ACCOMODATION_LOCATION_PENDING = 'GET_ACCOMODATION_LOCATION_PENDING';
const GET_ACCOMODATION_LOCATION_REJECTED = 'GET_ACCOMODATION_LOCATION_REJECTED';

//Accomodation By Profile Id
const GET_ACCOMODATION_ITEM_TYPES = 'GET_ACCOMODATION_ITEM_TYPES';
const GET_ACCOMODATION_ITEM_TYPES_FULFILLED =
  'GET_ACCOMODATION_ITEM_TYPES_FULFILLED';
const GET_ACCOMODATION_ITEM_TYPES_PENDING =
  'GET_ACCOMODATION_ITEM_TYPES_PENDING';
const GET_ACCOMODATION_ITEM_TYPES_REJECTED =
  'GET_ACCOMODATION_ITEM_TYPES_REJECTED';

//Accomodation Item Service Type List
const GET_ACCOMODATION_ITEM_SERVICE_TYPE_LIST =
  'GET_ACCOMODATION_ITEM_SERVICE_TYPE_LIST';
const GET_ACCOMODATION_ITEM_SERVICE_TYPE_LIST_FULFILLED =
  'GET_ACCOMODATION_ITEM_SERVICE_TYPE_LIST_FULFILLED';
const GET_ACCOMODATION_ITEM_SERVICE_TYPE_LIST_PENDING =
  'GET_ACCOMODATION_ITEM_SERVICE_TYPE_LIST_PENDING';
const GET_ACCOMODATION_ITEM_SERVICE_TYPE_LIST_REJECTED =
  'GET_ACCOMODATION_ITEM_SERVICE_TYPE_LIST_REJECTED';

//Post Accomodation
const POST_ACCOMODATION = 'POST_ACCOMODATION';
const POST_ACCOMODATION_FULFILLED = 'POST_ACCOMODATION_FULFILLED';
const POST_ACCOMODATION_PENDING = 'POST_ACCOMODATION_PENDING';
const POST_ACCOMODATION_REJECTED = 'POST_ACCOMODATION_REJECTED';

//Delete Accomodation By Profile Id
const DELETE_ACCOMODATION_BY_PROFILE_ID = 'DELETE_ACCOMODATION_BY_PROFILE_ID';
const DELETE_ACCOMODATION_BY_PROFILE_ID_FULFILLED =
  'DELETE_ACCOMODATION_BY_PROFILE_ID_FULFILLED';
const DELETE_ACCOMODATION_BY_PROFILE_ID_PENDING =
  'DELETE_ACCOMODATION_BY_PROFILE_ID_PENDING';
const DELETE_ACCOMODATION_BY_PROFILE_ID_REJECTED =
  'DELETE_ACCOMODATION_BY_PROFILE_ID_REJECTED';

//Get export Accomodation
const GET_EXPORT_ACCOMODATION = 'GET_EXPORT_ACCOMODATION';
const GET_EXPORT_ACCOMODATION_FULFILLED = 'GET_EXPORT_ACCOMODATION_FULFILLED';
const GET_EXPORT_ACCOMODATION_PENDING = 'GET_EXPORT_ACCOMODATION_PENDING';
const GET_EXPORT_ACCOMODATION_REJECTED = 'GET_EXPORT_ACCOMODATION_REJECTED';

//Put Accomodation
const PUT_ACCOMODATION = 'PUT_ACCOMODATION';
const PUT_ACCOMODATION_FULFILLED = 'PUT_ACCOMODATION_FULFILLED';
const PUT_ACCOMODATION_PENDING = 'PUT_ACCOMODATION_PENDING';
const PUT_ACCOMODATION_REJECTED = 'PUT_ACCOMODATION_REJECTED';

// post accommodation item
const POST_ACCOMODATION_ITEM = 'POST_ACCOMODATION_ITEM';
const POST_ACCOMODATION_ITEM_PENDING = 'POST_ACCOMODATION_ITEM_PENDING';
const POST_ACCOMODATION_ITEM_FULFILLED = 'POST_ACCOMODATION_ITEM_FULFILLED';
const POST_ACCOMODATION_ITEM_REJECTED = 'POST_ACCOMODATION_ITEM_REJECTED';

// put accommodation item
const PUT_ACCOMODATION_ITEM = 'PUT_ACCOMODATION_ITEM';
const PUT_ACCOMODATION_ITEM_PENDING = 'PUT_ACCOMODATION_ITEM_PENDING';
const PUT_ACCOMODATION_ITEM_FULFILLED = 'PUT_ACCOMODATION_ITEM_FULFILLED';
const PUT_ACCOMODATION_ITEM_REJECTED = 'PUT_ACCOMODATION_ITEM_REJECTED';

const GET_ACCOMODATION_ITEM_EXPORT_DATA = 'GET_ACCOMODATION_ITEM_EXPORT_DATA';
const GET_ACCOMODATION_ITEM_EXPORT_DATA_FULFILLED =
  'GET_ACCOMODATION_ITEM_EXPORT_DATA_FULFILLED';
const GET_ACCOMODATION_ITEM_EXPORT_DATA_PENDING =
  'GET_ACCOMODATION_ITEM_EXPORT_DATA_PENDING';
const GET_ACCOMODATION_ITEM_EXPORT_DATA_REJECTED =
  'GET_ACCOMODATION_ITEM_EXPORT_DATA_REJECTED';

const DELETE_ACCOMODATION_ITEM = 'DELETE_ACCOMODATION_ITEM';
const DELETE_ACCOMODATION_ITEM_PENDING = 'DELETE_ACCOMODATION_ITEM_PENDING';
const DELETE_ACCOMODATION_ITEM_FULFILLED = 'DELETE_ACCOMODATION_ITEM_FULFILLED';
const DELETE_ACCOMODATION_ITEM_REJECTED = 'DELETE_ACCOMODATION_ITEM_REJECTED';

const DELETE_IMAGE = 'DELETE_IMAGE';
const DELETE_IMAGE_FULFILLED = 'DELETE_IMAGE_FULFILLED';
const DELETE_IMAGE_PENDING = 'DELETE_IMAGE_PENDING';
const DELETE_IMAGE_REJECTED = 'DELETE_IMAGE_REJECTED';

const DELETE_CONTRACTING_FILE = 'DELETE_CONTRACTING_FILE';
const DELETE_CONTRACTING_FILE_FULFILLED = 'DELETE_CONTRACTING_FILE_FULFILLED';
const DELETE_CONTRACTING_FILE_PENDING = 'DELETE_CONTRACTING_FILE_PENDING';
const DELETE_CONTRACTING_FILE_REJECTED = 'DELETE_CONTRACTING_FILE_REJECTED';

const POST_CONTRACTING_FILE = 'POST_CONTRACTING_FILE';
const POST_CONTRACTING_FILE_FULFILLED = 'POST_CONTRACTING_FILE_FULFILLED';
const POST_CONTRACTING_FILE_PENDING = 'POST_CONTRACTING_FILE_PENDING';
const POST_CONTRACTING_FILE_REJECTED = 'POST_CONTRACTING_FILE_REJECTED';

export {
  RESET_STATUS_ACCOMODATION_PAGE,
  //accomodation Types
  GET_ACCOMODATION_TYPES,
  GET_ACCOMODATION_TYPES_FULFILLED,
  GET_ACCOMODATION_TYPES_PENDING,
  GET_ACCOMODATION_TYPES_REJECTED,
  // accomodation Location
  GET_ACCOMODATION_LOCATION,
  GET_ACCOMODATION_LOCATION_PENDING,
  GET_ACCOMODATION_LOCATION_FULFILLED,
  GET_ACCOMODATION_LOCATION_REJECTED,
  //accomodation Facilities
  GET_ACCOMODATION_FACILITIES,
  GET_ACCOMODATION_FACILITIES_FULFILLED,
  GET_ACCOMODATION_FACILITIES_PENDING,
  GET_ACCOMODATION_FACILITIES_REJECTED,
  // accomodation Item Types
  GET_ACCOMODATION_ITEM_TYPES,
  GET_ACCOMODATION_ITEM_TYPES_FULFILLED,
  GET_ACCOMODATION_ITEM_TYPES_PENDING,
  GET_ACCOMODATION_ITEM_TYPES_REJECTED,
  //accomodation Item Service Type List
  GET_ACCOMODATION_ITEM_SERVICE_TYPE_LIST,
  GET_ACCOMODATION_ITEM_SERVICE_TYPE_LIST_FULFILLED,
  GET_ACCOMODATION_ITEM_SERVICE_TYPE_LIST_PENDING,
  GET_ACCOMODATION_ITEM_SERVICE_TYPE_LIST_REJECTED,
  //Post Accomodation
  POST_ACCOMODATION,
  POST_ACCOMODATION_FULFILLED,
  POST_ACCOMODATION_PENDING,
  POST_ACCOMODATION_REJECTED,
  //DELETE ACCOMODATION
  DELETE_ACCOMODATION_BY_PROFILE_ID,
  DELETE_ACCOMODATION_BY_PROFILE_ID_FULFILLED,
  DELETE_ACCOMODATION_BY_PROFILE_ID_PENDING,
  DELETE_ACCOMODATION_BY_PROFILE_ID_REJECTED,
  //EXPORT ACCOMODATION
  GET_EXPORT_ACCOMODATION,
  GET_EXPORT_ACCOMODATION_FULFILLED,
  GET_EXPORT_ACCOMODATION_PENDING,
  GET_EXPORT_ACCOMODATION_REJECTED,
  //Put Accomodation Profile
  PUT_ACCOMODATION,
  PUT_ACCOMODATION_FULFILLED,
  PUT_ACCOMODATION_PENDING,
  PUT_ACCOMODATION_REJECTED,
  // post accommodation item
  POST_ACCOMODATION_ITEM,
  POST_ACCOMODATION_ITEM_FULFILLED,
  POST_ACCOMODATION_ITEM_PENDING,
  POST_ACCOMODATION_ITEM_REJECTED,
  // put accommodation item
  PUT_ACCOMODATION_ITEM,
  PUT_ACCOMODATION_ITEM_FULFILLED,
  PUT_ACCOMODATION_ITEM_PENDING,
  PUT_ACCOMODATION_ITEM_REJECTED,
  //
  GET_ACCOMODATION_ITEM_EXPORT_DATA,
  GET_ACCOMODATION_ITEM_EXPORT_DATA_FULFILLED,
  GET_ACCOMODATION_ITEM_EXPORT_DATA_PENDING,
  GET_ACCOMODATION_ITEM_EXPORT_DATA_REJECTED,
  // delete accommodation item
  DELETE_ACCOMODATION_ITEM,
  DELETE_ACCOMODATION_ITEM_PENDING,
  DELETE_ACCOMODATION_ITEM_FULFILLED,
  DELETE_ACCOMODATION_ITEM_REJECTED,
  // delete image
  DELETE_IMAGE,
  DELETE_IMAGE_FULFILLED,
  DELETE_IMAGE_PENDING,
  DELETE_IMAGE_REJECTED,
  // delete contracting
  DELETE_CONTRACTING_FILE,
  DELETE_CONTRACTING_FILE_FULFILLED,
  DELETE_CONTRACTING_FILE_PENDING,
  DELETE_CONTRACTING_FILE_REJECTED,
  // post contracting
  POST_CONTRACTING_FILE,
  POST_CONTRACTING_FILE_FULFILLED,
  POST_CONTRACTING_FILE_PENDING,
  POST_CONTRACTING_FILE_REJECTED,
};
