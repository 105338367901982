import apiClient from '../apiClient';
import {
  GET_CUSTOMER_PROFILE_URL,
  GET_CUSTOMER_PROFILE_FOR_SALES_URL,
} from '../apiUrl';

const getCustomerProfileListApi = type => {
  return apiClient.get(GET_CUSTOMER_PROFILE_URL(type));
};
const getCustomerProfileListForSalesApi = type => {
  return apiClient.get(`${GET_CUSTOMER_PROFILE_FOR_SALES_URL}${type}`);
};
export { getCustomerProfileListApi, getCustomerProfileListForSalesApi };
