import { useState, useEffect } from 'react';

const useApiCall = (api, id = null) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState(null);

  const fetchApi = id => {
    setIsLoading(true);
    api(id)
      .then(result => {
        setIsLoading(false);
        setFetchedData(result.data);
      })
      .catch(() => {
        //eslint-disable-next-line
        // console.log(err);
        setIsLoading(false);
      });
  };

  const handleSubmit = id => {
    fetchApi(id);
  };

  const loadData = () => {
    fetchApi(id);
  };

  useEffect(() => {
    fetchApi(id);
    //eslint-disable-next-line
  }, []);

  return [isLoading, fetchedData, handleSubmit, loadData];
};

export default useApiCall;
