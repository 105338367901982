import postAdditionalInfoApi from '../../../api/additionalInfo/postAdditionalInfoApi';
import deleteAdditionalInfoApi from '../../../api/additionalInfo/deleteAdditionalInfoApi';
import putAdditionalInfoApi from '../../../api/additionalInfo/putAdditionalInfoApi';
const postAdditionalInfoAction = data => {
  return dispatch => {
    return dispatch({
      payload: postAdditionalInfoApi(data),
    });
  };
};

const deleteAdditionalInfoAction = id => {
  return dispatch => {
    return dispatch({
      payload: deleteAdditionalInfoApi(id),
    });
  };
};

const putAdditionalInfoAction = (id, data) => {
  return dispatch => {
    return dispatch({
      payload: putAdditionalInfoApi(id, data),
    });
  };
};

export {
  postAdditionalInfoAction,
  deleteAdditionalInfoAction,
  putAdditionalInfoAction,
};
