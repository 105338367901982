import React, { Component } from 'react';
// import { connect } from 'react-redux';
import { Grid, Label, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from '../../../../../assets/styles/styleCreateTourTransaction';
import moment from 'moment';
import {
  VIRTUALCHECKIN,
  VIRTUALCHECKOUT,
  DRIVING,
  FREETIMELOCKED,
  HIDE_ACTIVITY,
  EDIT_ACTIVITY,
} from './constant/activityType';
import TimeLineAddActivity from './TimeLineAddActivity';
import TimeLineEditActivity from './TimeLineEditAndDeleteActivity';
import { convertDateFormat, isSameDate } from '../../../../../scripts/moment';
// import { getOvertimeTransport } from '../../../../../scripts/itineraryBuilder/demoPrice';
// import FixedPackageTimeline from './FixedTimeline';
// import {
//   isExcursionTimeOver,
//   isTransportValid,
// } from '../../../../../scripts/itineraryBuilder/dailyProgram';
import { SumSecond } from '../../../../../scripts/itineraryBuilder/timeHelper';
// collapse tambahan
import { Collapse } from 'react-collapse';
import CollapseButton from '../../../../../components/button/collapseButton';
import TimeLineGuideInfo from './TimeLineGuideInfo';
import TimeLineDateSeparator from './TimeLineDateSeparator';
// import MessageOverlap from './MessageOverlap';
import DescriptionActivity from './activity/DescriptionActivity';
import MessageErrorActivity from './activity/MessageErrorActivity';
import { IsShowButtonAddActivity } from './scripts/configurationButtonAddActivity';

class TimeLineDailyProgram extends Component {
  state = {
    isCheckExcursion: false,
    openCollapseDay: false,
  };
  // MEMANG TIDAK DI PAKAI
  // transportationValidation = (movement, transport) => {
  //   let type =
  //     ['Transfer_with_guide', 'Transfer'].indexOf(movement.Item.ServiceType) !==
  //     -1;
  //   if (type) {
  //     this.isValidTransportation(transport, movement);
  //   } else {
  //     this.getOverTime(movement);
  //   }
  // };
  // SUDAH DI PINDAHKAN KE MESSAGE ERROR ACTIVITY
  // getOverTime = movement => {
  //   let { transportStore } = this.props;
  //   let overTime = 0;
  //   if (movement.Item.ServiceItemId && transportStore) {
  //     let transport = transportStore.find(
  //       item =>
  //         item.ServiceItemId === movement.Item.ServiceItemId &&
  //         convertDateFormat(item.DateTime, 'YYYY-MM-DD') ===
  //           convertDateFormat(movement.DateTime, 'YYYY-MM-DD')
  //     );
  //     overTime = transport ? getOvertimeTransport(transport) : 0;
  //   }
  //   if (overTime > 0) {
  //     return (
  //       <Message warning style={{ width: '43em', marginLeft: '5em' }}>
  //         Overtime {overTime} h, there will be extra charge for transportation
  //         overtime.
  //       </Message>
  //     );
  //   } else return null;
  // };
  // MEMANG TIDAK DI PAKAI
  // checkStartTimeExcursion = movement => {
  //   let isCheckExcursion = false;
  //   isCheckExcursion =
  //     movement.MovementName === RECREATION
  //       ? movement.Item.OperationStartTime
  //         ? isExcursionTimeOver(movement)
  //         : false
  //       : false;
  //   this.setState({ isCheckExcursion: isCheckExcursion });
  // };
  // SUDAH DI PINDAHKAN KE MESSAGE ERROR ACTIVITY
  // isValidTransportation = (movement, transport) => {
  //   const validTransportation = isTransportValid(movement.Movements, transport);
  //   if (validTransportation) {
  //     return (
  //       <Message warning style={{ width: '43em', marginLeft: '5em' }}>
  //         Transport type cannot be used because it exceeds the duration
  //         limit.Please change the type of transportation.
  //       </Message>
  //     );
  //   } else return null;
  // };
  handleCollapseDay = (e, { name, value }) =>
    this.setState({ ...this.state, [name]: !value });

  renderSeparatorDay = (
    isActivityInCurrentDate,
    isEndTimeInCurrentDate,
    date,
    duration
  ) => {
    let nextDate = SumSecond(date, duration);
    return (
      isActivityInCurrentDate &&
      !isEndTimeInCurrentDate && <TimeLineDateSeparator date={nextDate} />
    );
  };
  render() {
    const props = this.props;
    const {
      data,
      handleOpenModalActivities,
      day,
      deleteActivity,
      deleteTransportItem,
      openTransportationModal,
      dayDate,
      isPublished,
      setDailyProgram,
      dailyProgram,
      isStatic,
      guests,
      // currentPackage,
      // isPackageMangement,
      // isReadyFixedPrice,
      type,
      checkItemName,
      isAllowedEdit,
    } = this.props;
    const { openCollapseDay } = this.state;
    // JANGAN DI HAPUS INI UNTUK TOUR GUIDE
    const showTourGuide = false;
    // const showTourGuide =
    //   currentPackage.type !== 'Fixed' &&
    //   !isPackageMangement &&
    //   !isReadyFixedPrice;
    // END JANGAN DI HAPUS INI UNTUK TOUR GUIDE
    return (
      <Grid verticalAlign="middle" key={day}>
        <Grid.Row style={styles.marginDailyProgramDays}>
          <Grid.Column width={14}>
            <Header as="h3" style={{ marginTop: '10px', paddingTop: '10px' }}>
              Day {data.Day}, {moment(data.Date).format('DD MMM YYYY')}
            </Header>
          </Grid.Column>
          <Grid.Column width={2} textAlign="left">
            <CollapseButton
              isCollapse={openCollapseDay}
              handleCollapse={this.handleCollapseDay}
              name="openCollapseDay"
            />
          </Grid.Column>
        </Grid.Row>
        <Collapse isOpened={openCollapseDay} style={{ width: '70em' }}>
          <Grid style={{ marginLeft: '3em', marginBottom: '10px' }}>
            <Grid.Row>
              {showTourGuide && (
                <TimeLineGuideInfo
                  dailyData={data}
                  setDailyProgram={setDailyProgram}
                  dailyProgram={dailyProgram}
                  day={day}
                  isStatic={isStatic}
                  guests={guests}
                  isPublished={isPublished}
                  // setTourOperator={setTourOperator}
                />
              )}
            </Grid.Row>
            {data.Movements.map((movement, i) => {
              let isActivityInCurrentDate = isSameDate(
                movement.DateTime,
                data.Date
              );
              let isEndTimeInCurrentDate = isSameDate(
                SumSecond(movement.DateTime, movement.Duration),
                data.Date
              );
              const isNoNextMovement = data.Movements[i + 1] ? false : true;
              return isAllowedEdit &&
                type !== 'Fixed' &&
                // type !== 'FixedDateVariable' &&
                movement.MovementName === VIRTUALCHECKIN &&
                data.Movements[i + 1].MovementName === VIRTUALCHECKOUT ? (
                <Grid.Row style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                  <Grid.Column width={16}>
                    <TimeLineAddActivity
                      activity={data}
                      movements={movement}
                      handleOpenModalActivities={handleOpenModalActivities}
                      day={day}
                      index={i}
                      packageType={props.type.toUpperCase()}
                      isPublished={isPublished}
                    />
                  </Grid.Column>
                </Grid.Row>
              ) : (
                HIDE_ACTIVITY.indexOf(movement.MovementName) !== -1 ||
                  (movement.MovementName === DRIVING &&
                    movement.Duration === 0) ||
                  (movement.MovementName === FREETIMELOCKED &&
                    movement.Duration === 0) || (
                    <React.Fragment key={i}>
                      <Grid.Row
                        style={{ paddingTop: '5px', paddingBottom: '5px' }}
                      >
                        <Grid.Column width={1}>
                          {/* tampilan jam nya */}
                          <Label
                            color="blue"
                            circular
                            content={convertDateFormat(
                              movement.DateTime,
                              'HH.mm'
                            )}
                            size="large"
                          />
                          {/* end tampilan jam nya */}
                        </Grid.Column>
                        <Grid.Column width={13} verticalAlign="middle">
                          {/* tampilan desc nya */}
                          <DescriptionActivity
                            movement={movement}
                            desc={checkItemName(movement, type)}
                            isAllowedEdit={isAllowedEdit}
                          />
                          {/* end tampilan desc nya */}
                        </Grid.Column>
                        <Grid.Column width={2} verticalAlign="middle">
                          {isAllowedEdit &&
                          props.type.toUpperCase() !== 'FIXED' &&
                          EDIT_ACTIVITY.find(
                            values => values === movement.MovementName
                          ) ? (
                            <TimeLineEditActivity
                              activity={data}
                              movements={movement}
                              handleOpenModalActivities={
                                handleOpenModalActivities
                              }
                              day={day}
                              index={i}
                              deleteActivity={deleteActivity}
                              deleteTransportItem={deleteTransportItem}
                              activityType={movement.MovementName}
                              openTransportationModal={openTransportationModal}
                              isPublished={isPublished}
                            />
                          ) : null}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row
                        style={{ paddingTop: '0px', paddingBottom: '0px' }}
                      >
                        <Grid.Column width={16}>
                          {/* tampilan alert nya */}
                          <MessageErrorActivity
                            type={type}
                            movement={movement}
                            data={data}
                            dayDate={dayDate}
                            transportStore={this.props.transportStore}
                          />
                          {/* end tampilan alertnya nya */}
                        </Grid.Column>
                        {/* </Grid.Row>
                      <Grid.Row
                        style={{ paddingTop: '0px', paddingBottom: '0px' }}
                      > */}
                        <Grid.Column width={16}>
                          {/* tampilan button add nya */}
                          {(type
                            ? type !== 'Fixed'
                            : // ? type !== 'FixedDateVariable'
                              // : false
                              false) &&
                            isAllowedEdit &&
                            isActivityInCurrentDate &&
                            isEndTimeInCurrentDate &&
                            IsShowButtonAddActivity(
                              movement,
                              data.Movements,
                              i
                            ) && (
                              <TimeLineAddActivity
                                activity={data}
                                movements={movement}
                                handleOpenModalActivities={
                                  handleOpenModalActivities
                                }
                                day={day}
                                index={i}
                                packageType={props.type.toUpperCase()}
                                isPublished={isPublished}
                              />
                            )}
                          {/* end tampilan button add nya */}
                        </Grid.Column>
                        {/* </Grid.Row>
                      <Grid.Row
                        style={{ paddingTop: '0px', paddingBottom: '0px' }}
                      > */}
                        <Grid.Column width={16}>
                          {isActivityInCurrentDate &&
                            !isEndTimeInCurrentDate &&
                            !isNoNextMovement &&
                            this.renderSeparatorDay(
                              isActivityInCurrentDate,
                              isEndTimeInCurrentDate,
                              movement.DateTime
                            )}
                        </Grid.Column>
                      </Grid.Row>
                    </React.Fragment>
                  )
              );
            })}
          </Grid>
        </Collapse>
      </Grid>
    );
  }
}
TimeLineDailyProgram.propTypes = {
  data: PropTypes.array,
  checkItemName: PropTypes.func,
  handleAddActivities: PropTypes.func,
  buttonAddActivity: PropTypes.func,
  type: PropTypes.string,
  handleOpenModalActivities: PropTypes.func,
  day: PropTypes.number,
  deleteActivity: PropTypes.func,
  transportStore: PropTypes.array,
  deleteTransportItem: PropTypes.func,
  openTransportationModal: PropTypes.func,
  dayDate: PropTypes.string,
  isPublished: PropTypes.bool,
  setDailyProgram: PropTypes.func,
  dailyProgram: PropTypes.array,
  isStatic: PropTypes.bool,
  isPackageMangement: PropTypes.bool,
  guests: PropTypes.object,
  currentPackage: PropTypes.object,
  isReadyFixedPrice: PropTypes.bool,
  isAllowedEdit: PropTypes.bool,
};

export default TimeLineDailyProgram;
