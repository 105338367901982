import React, { useState, useEffect } from 'react';
//import Helmet from 'react-helmet';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { Button, Modal, Form, Grid, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import InputWithLabel from '../../../../components/input/inputWithLabel';
import ButtonOnClose from '../../../../components/button/buttonCloseModal';
import styles from './styles';

const ModalDateRange = props => {
  // const role = localStorage.getItem('roles');
  const userRoles = localStorage.getItem('userRoles');
  const role = userRoles ? userRoles.split(',') : [];
  const filterBy = role.find(item => item === 'Supplier')
    ? 'Check-in date'
    : 'Start date';

  const getInitialState = () => {
    return {
      from: props.startDate ? moment(props.startDate, 'YYYY-MM-DD')._d : null,
      to: props.endDate ? moment(props.endDate, 'YYYY-MM-DD')._d : null,
      value: '1',
      selectedDate: filterBy,
      // selectedDate: 'Check-In Date',
    };
  };

  const [state, setState] = useState(getInitialState());

  const handleDayClick = day => {
    const range = DateUtils.addDayToRange(day, state);
    setState(range);
  };

  const handleResetClick = () => {
    setState(getInitialState());
  };

  const handleClose = () => {
    handleResetClick();
    props.close();
  };

  const filterByDate = (e, { id }) => {
    if (state.from && state.to) {
      handleResetClick();
      props.actionDateRange(id);
    }
  };

  useEffect(() => {
    setState({
      from: props.startDate ? moment(props.startDate, 'YYYY-MM-DD')._d : null,
      to: props.endDate ? moment(props.endDate, 'YYYY-MM-DD')._d : null,
      value: '1',
      selectedDate: filterBy,
    });
    // eslint-disable-next-line
  }, [props.startDate, props.endDate]);

  const modifiers = { start: state.from, end: state.to };
  const { from, to } = state;

  return (
    <Modal
      dimmer="blurring"
      inverted
      open={props.openModal}
      onClose={handleClose}
      style={styles.modalWidth}
    >
      <Modal.Header>
        <Icon name="filter" />
        {`Filter ${props.contentHeader}`}
        <ButtonOnClose onClose={props.close} />
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <Grid>
              {/* <Grid.Row style={styles.zeroPaddingBottom}>
                <Grid.Column>
                  <strong>{filterBy}</strong>
                </Grid.Column>
              </Grid.Row> */}
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Grid columns={2}>
                    <Grid.Column>
                      <InputWithLabel
                        label="From"
                        // placeholder={`${filterBy} From`}
                        placeholder={`From`}
                        type="text"
                        value={state.from ? moment(state.from).format('L') : ''}
                        readonly
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <InputWithLabel
                        label="To"
                        // placeholder={`${filterBy} To`}
                        placeholder={`To`}
                        type="text"
                        value={state.to ? moment(state.to).format('L') : ''}
                        readonly
                      />
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
                <Grid.Column />
              </Grid.Row>
            </Grid>
          </Form.Field>
        </Form>

        <div>
          <p>
            {!state.from && !state.to && 'Please select the first day.'}
            {state.from && !state.to && 'Please select the last day.'}
          </p>
          <DayPicker
            className="Selectable"
            numberOfMonths={2}
            selectedDays={[state.from, { from, to }]}
            modifiers={modifiers}
            onDayClick={handleDayClick}
          />
          <style>{`
  .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .Selectable .DayPicker-Day {
    border-radius: 0 !important;
  }
  .Selectable .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .Selectable .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
`}</style>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          positive
          icon="search"
          content="Search"
          onClick={filterByDate}
          id={state}
          name={props.id}
        />
      </Modal.Actions>
    </Modal>
  );
};

ModalDateRange.propTypes = {
  actionDateRange: PropTypes.func,
  id: PropTypes.string,
  openModal: PropTypes.func,
  close: PropTypes.func,
  contentHeader: PropTypes.string,
  dateRange: PropTypes.object,
  searchButton: PropTypes.func,
  isHiddenOptions: PropTypes.bool,
  defaultFilterName: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  filterBy: PropTypes.string,
};

export default ModalDateRange;
