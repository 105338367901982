import React, { Component } from 'react';
import { Table, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { formatCurrency } from '../../../../components/genericFunction/convertFormat';
import Pagination from '../../../../components/common/pagination';
import IndexPagination from '../../../../components/genericFunction/indexPagination';
import { getNumberFromTo } from '../../../../scripts/pagination';
// const tableAdditionalPackage = props => {
class TableAdditionalPackage extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
    };
  }
  static propTypes = {
    data: PropTypes.array,
  };
  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  render() {
    const props = this.props;

    let { numbersFrom, numbersTo } = getNumberFromTo(
      props.listData,
      props.activePage,
      3
    );
    let headerTable =
      props.isOperation && props.headerTable
        ? props.headerTable.filter(e => e !== 'Price')
        : props.headerTable;
    return (
      <Table basic="very">
        <Table.Header>
          <Table.Row>
            {headerTable &&
              headerTable.map((headTableContent, index) => {
                return (
                  <Table.HeaderCell key={index} textAlign="center">
                    {headTableContent}
                  </Table.HeaderCell>
                );
              })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {props.listData &&
            props.listData.map((item, idx) => {
              const idxPagination = IndexPagination(
                3,
                this.state.activePage,
                idx
              );
              return (
                <Table.Row key={idxPagination}>
                  <Table.Cell textAlign="center">{item.Name}</Table.Cell>
                  <Table.Cell textAlign="center">{item.Description}</Table.Cell>
                  {props.isOperation || (
                    <Table.Cell textAlign="center">
                      {formatCurrency(item.UnitCost)}
                    </Table.Cell>
                  )}
                  <Table.Cell textAlign="center">
                    {item.IsMandatory ? 'Yes' : 'No'}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {item.IsDeduction ? 'Yes' : 'No'}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <Button
                      content="Edit"
                      positive
                      name={{ Index: idxPagination, Type: 'Edit' }}
                      onClick={props.editAdditionalItem}
                      disabled={props.isPublished || props.isOperation}
                    />
                    <Button
                      content="Delete"
                      negative
                      name={{ Index: idxPagination, Type: 'Delete' }}
                      onClick={props.deleteAdditionalItem}
                      id={item.Name}
                      disabled={props.isPublished || props.isOperation}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
        <Pagination
          pagedData={props.pagedData}
          numbersTo={numbersTo}
          numbersFrom={numbersFrom}
          // data={props.listData}
          data={props.listData}
          pageChange={props.pageChange}
          activePage={props.activePage}
        />
      </Table>
    );
  }
}

TableAdditionalPackage.propTypes = {
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  actionAddittionalItem: PropTypes.func,
  handleClickDelete: PropTypes.func,
  activePage: PropTypes.number,
  isOperation: PropTypes.bool,
  editAdditionalItem: PropTypes.func,
  isPublished: PropTypes.func,
  deleteAdditionalItem: PropTypes.func,
  pageChange: PropTypes.func,
  pagedData: PropTypes.array,
};
export default TableAdditionalPackage;
