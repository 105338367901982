import {
  GET_ACCOMMODATIONS_WITH_FILTER_URL,
  GET_ROOM_ALLOTMENT_BY_PROFILE_ID_URL,
  POST_ROOM_ALLOTMENT_URL,
  POST_ROOM_ALLOTMENT_PERIOD_URL,
  GET_REQUEST_ROOM_ALLOTMENT_URL,
  POST_CONFIRM_ROOM_ALLOTMENT_URL,
  GET_LIST_CONFIRM_ROOM_ALLOTMENTS_URL,
  GET_RESEND_REQUEST_CONFIRMATION_URL,
  GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID_URL,
  GET_STOCK_ROOM_ALLOTMENT_URL,
  GET_EXPORT_STOCK_ROOM_ALLOTMENT_URL,
  GET_ACCOMMODATION_ESTIMATE_PRICE_URL,
  GET_ACCOMMODATION_ITEM_ESTIMATE_PRICE_URL,
} from '../apiUrl';
import apiClient from '../apiClient';
const getAccommodationsWithFilterApi = data => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('tokenadmin');
  return apiClient.get(
    `${GET_ACCOMMODATIONS_WITH_FILTER_URL}?city=${data.cityID}&requestedDate=${data.requestedDate}&useExtraBed=${data.useExtraBed}&useChildExtraBed=${data.useChildExtraBed}&useSharingBed=${data.useSharingBed}&sharingRoomPax=${data.sharingRoomPax}&singleRoomPax=${data.singleRoomPax}&checkOutDate=${data.checkOutDate}`
  );
};

const getRoomAllotmentByProfileIdApi = (profileId, startDate, endDate) => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('tokenadmin');
  return apiClient.get(
    `${GET_ROOM_ALLOTMENT_BY_PROFILE_ID_URL}/${profileId}/${startDate}/${endDate}`
  );
};

const postRoomAllotmentDayApi = data => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('tokenadmin');
  return apiClient.post(POST_ROOM_ALLOTMENT_URL, data);
};
const postRoomAllotmentPeriodApi = data => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('tokenadmin');
  return apiClient.post(POST_ROOM_ALLOTMENT_PERIOD_URL, data);
};
const getRequestRoomAllotmentApi = (transactionId, token) => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('tokenadmin');
  return apiClient.get(
    `${GET_REQUEST_ROOM_ALLOTMENT_URL}/${transactionId}/${token}`
  );
};
const postConfirmRoomAllotmentApi = data => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('tokenadmin');
  return apiClient.post(POST_CONFIRM_ROOM_ALLOTMENT_URL, data);
};
const getListConfirmRoomAllotmentsApi = (
  tourStartDate,
  tourUntilDate,
  createdStartDate,
  createdUntilDate
) => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('tokenadmin');
  return apiClient.get(
    `${GET_LIST_CONFIRM_ROOM_ALLOTMENTS_URL}?tourStartDate=${tourStartDate}&tourUntilDate=${tourUntilDate}&createdStartDate=${createdStartDate}&createdUntilDate=${createdUntilDate}`
  );
};
const getResendRequestConfirmationApi = (transactionId, token) => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('tokenadmin');
  return apiClient.get(
    `${GET_RESEND_REQUEST_CONFIRMATION_URL}/${transactionId}/${token}`
  );
};

const getListRoomAllotmentByProfileIdApi = profileId => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('tokenadmin');
  return apiClient.get(
    `${GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID_URL}/${profileId}`
  );
};

const getStockRoomAllotmentApi = (
  profileId,
  startDate,
  endDate,
  serviceItemId
) => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('tokenadmin');
  return apiClient.get(
    `${GET_STOCK_ROOM_ALLOTMENT_URL}/${profileId}/${startDate}/${endDate}/${serviceItemId}`
  );
};

const getExportStockRoomAllotmentApi = (
  profileId,
  startDate,
  endDate,
  serviceItemId
) => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('tokenadmin');
  return apiClient.get(
    `${GET_EXPORT_STOCK_ROOM_ALLOTMENT_URL}/${profileId}/${startDate}/${endDate}/${serviceItemId}`
  );
};

const getListByCodeConfirmRoomAllotmentApi = Code => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('tokenadmin');
  return apiClient.get(`${GET_LIST_CONFIRM_ROOM_ALLOTMENTS_URL}/${Code}`);
};

const getAccomodationEstimatePriceApi = param => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('tokenadmin');
  return apiClient.post(
    `${GET_ACCOMMODATION_ESTIMATE_PRICE_URL}?cityId=${param.cityId}&ratingId=${param.ratingId}&areaId=${param.areaId}&locationId=${param.locationId}&typeId=${param.typeId}&facilityId=${param.facilityId}&promoOnly=${param.promoOnly}&requestedDate=${param.requestedDate}&useExtraBed=${param.useExtraBed}&useChildExtraBed=${param.useChildExtraBed}&useSharingBed=${param.useSharingBed}&sharingRoomPax=${param.sharingRoomPax}&singleRoomPax=${param.singleRoomPax}&checkOutDate=${param.checkOutDate}`,
    param.bodyParam
  );
};

const getAccomodationItemEstimatePriceApi = param => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('tokenadmin');
  return apiClient.post(
    `${GET_ACCOMMODATION_ITEM_ESTIMATE_PRICE_URL}?profileId=${param.profileId}&requestedDate=${param.requestedDate}&endDate=${param.endDate}&useExtraBed=${param.useExtraBed}&useChildExtraBed=${param.useChildExtraBed}&useSharingBed=${param.useSharingBed}&sharingRoomPax=${param.sharingRoomPax}&singleRoomPax=${param.singleRoomPax}`,
    param.bodyParam
  );
};
export {
  getAccommodationsWithFilterApi,
  getRoomAllotmentByProfileIdApi,
  postRoomAllotmentDayApi,
  postRoomAllotmentPeriodApi,
  getRequestRoomAllotmentApi,
  postConfirmRoomAllotmentApi,
  getListConfirmRoomAllotmentsApi,
  getResendRequestConfirmationApi,
  getListRoomAllotmentByProfileIdApi,
  getStockRoomAllotmentApi,
  getExportStockRoomAllotmentApi,
  getListByCodeConfirmRoomAllotmentApi,
  getAccomodationEstimatePriceApi,
  getAccomodationItemEstimatePriceApi,
};
