import React from 'react';
import { Grid, Segment, Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import TableTourGuide from './table/TableTourGuide';
// import ButtonAction from '../common/ButtonGroupSupplier';
import Header from '../../../../components/common/headerTitle';
import Pagination from '../../../../components/common/pagination';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../../scripts/pagination';
import ButtonWithIcon from '../../../../components/button/buttonWithIcon';
import { Redirect } from 'react-router-dom';
class TourGuideList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerTableGTourGuide: [
        'Name',
        'Nationality',
        'Identity Number',
        'Join',
        'Address',
        'Language',
        'Action',
      ],
      searchInput: '',
      activePage: 1,
      gotoLink: '',
      changePage: false,
    };
  }

  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  filteredData = data =>
    data.filter(data => {
      let Name = data.FirstName + data.LastName;
      return (
        Name.toLowerCase().indexOf(this.state.searchInput.toLowerCase()) !== -1
      );
    });

  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };

  addNew = () => {
    let link = `/Tour-Guide-Detail/Create/0`;
    this.setState({ changePage: true, gotoLink: link });
  };
  editTourGuide = (e, { value }) => {
    let link = `/Tour-Guide-Detail/Edit/${value}`;
    this.setState({ changePage: true, gotoLink: link });
  };

  deleteTourGuide = (e, { name, value }) => {
    let { deleteTourGuideAction } = this.props;
    deleteTourGuideAction(name, value);
  };
  render() {
    const { data } = this.props;
    const {
      headerTableGTourGuide,
      activePage,
      gotoLink,
      changePage,
    } = this.state;
    let filterData = this.filteredData(data);
    let pagedData = datasetPagination(filterData, 7);
    let listData = pagedData[activePage - 1];

    let { numbersFrom, numbersTo } = getNumberFromTo(
      listData,
      this.state.activePage,
      7
    );
    if (changePage) {
      return <Redirect to={gotoLink} />;
    }
    return (
      <Grid columns="equal" style={{ marginTop: '30px' }}>
        <Grid.Row stretched>
          <Grid.Column>
            <Segment>
              <Header title="Tour Guide" />
              <Input
                icon="search"
                placeholder="search"
                name="searchInput"
                value={this.state.searchInput}
                onChange={this.handleChange}
              />
              <ButtonWithIcon
                text="Add New"
                iconName="add"
                handleOnClick={this.addNew}
                positive={true}
                floated="right"
              />
              <TableTourGuide
                listData={listData}
                headerTable={headerTableGTourGuide}
                pageChange={this.pageChange}
                pagedData={pagedData}
                editTourGuide={this.editTourGuide}
                deleteTourGuide={this.deleteTourGuide}
              />

              <Pagination
                pagedData={pagedData}
                numbersTo={numbersTo}
                numbersFrom={numbersFrom}
                data={data}
                pageChange={this.pageChange}
                activePage={activePage}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

TourGuideList.propTypes = {
  listData: PropTypes.array,
  pagedData: PropTypes.array,
  title: PropTypes.string,
  pageChange: PropTypes.func,
  activePage: PropTypes.number,
  searchInput: PropTypes.string,
  handleChange: PropTypes.func,
  data: PropTypes.array,
  buttonNew: PropTypes.func,
  butttonExport: PropTypes.func,
  buttonUploadData: PropTypes.func,
  buttonDownloadTemplate: PropTypes.func,
  butttonEdit: PropTypes.func,
  buttonDelete: PropTypes.func,
  numbersFrom: PropTypes.number,
  numbersTo: PropTypes.number,
  headerTableGTourGuide: PropTypes.array,
  deleteTourGuideAction: PropTypes.func,
};
export default TourGuideList;
