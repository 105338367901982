const getRoomPrices = prices => {
  const roomPrices = [
    {
      Id: 'SHR',
      Currency: prices ? prices.CurrencyId : '-',
      Room: 'Sharing Room',
      Price: prices ? prices.SharingRoomPrice : '-',
    },
    {
      Id: 'SIR',
      Currency: prices ? prices.CurrencyId : '-',
      Room: 'Single Room',
      Price: prices ? prices.SingleRoomPrice : '-',
    },
    {
      Id: 'AEB',
      Currency: prices ? prices.CurrencyId : '-',
      Room: 'Adult Extra Bed',
      Price: prices ? prices.AdultExtraBedPrice : '-',
    },
    {
      Id: 'CEB',
      Currency: prices ? prices.CurrencyId : '-',
      Room: 'Child Extra Bed',
      Price: prices ? prices.ChildExtraBedPrice : '-',
    },
    {
      Id: 'SBW',
      Currency: prices ? prices.CurrencyId : '-',
      Room: 'Sharing Bed With Parents',
      Price: prices ? prices.SharingBedPrice : '-',
    },
    {
      Id: 'BCN',
      Currency: prices ? prices.CurrencyId : '-',
      Room: 'Baby Crib / No Bed',
      Price: prices ? prices.NoBedPrice : '-',
    },
  ];
  return roomPrices;
};

export default getRoomPrices;
