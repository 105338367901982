import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Modal, Table, Pagination, Button } from 'semantic-ui-react';
import Loader from '../../../../../components/loader';
import InputSearch from '../../../../../components/input/inputSearch';
import { convertDateFormat, getTotalDays } from '../../../../../scripts/moment';
import { datasetPagination } from '../../../../../scripts/pagination';
class ModalPackageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTitle: '',
      activePage: 1,
      // packages: [],
      gotoNext: false,
      gotoNextId: '',
      gotoNextType: '',
      packageFixed: [],
      packagesReady: [],
    };
  }
  componentDidUpdate = prevProps => {
    if (prevProps.packages !== this.props.packages) {
      this.setListByStatus(this.props.packages);
    }
  };
  setListByStatus = list => {
    const { packagesReady } = this.state;
    let filterPackage = list.filter(
      data =>
        getTotalDays(
          data.ExpiredOn,
          convertDateFormat(new Date(), 'YYYY-MM-DDTHH:mm:ss'),
          false
        ) > 0
    );
    this.setState({
      ...this.state,
      packagesReady: !this.props.packageType
        ? packagesReady.concat(filterPackage)
        : [],
      packageFixed: filterPackage,
    });
  };

  handlePick = (e, { name, value }) =>
    this.setState({
      ...this.state,
      gotoNext: true,
      gotoNextId: value,
      gotoNextType: name,
    });

  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  filtered = listData => {
    let { searchTitle } = this.state;
    return listData.filter(
      data => data.Title.toLowerCase().indexOf(searchTitle.toLowerCase()) !== -1
    );
  };
  //   pagination = (data, perPage) => {
  //     let sets = [],
  //       chunks = data.length / perPage;

  //     for (var i = 0, j = 0; i < chunks; i++, j += perPage)
  //       sets[i] = data.slice(j, j + perPage);

  //     return sets;
  //   };
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  render() {
    let { openModal, closeModal, header, loading, packageType } = this.props;
    let {
      searchTitle,
      // packages,
      gotoNext,
      gotoNextId,
      gotoNextType,
      packagesReady,
      packageFixed,
    } = this.state;
    if (gotoNext) {
      return (
        <Redirect
          to={'/tour-transaction/create/' + gotoNextType + '/' + gotoNextId}
        />
      );
    }
    // let filterData = this.filtered(packages);
    let filterData = this.filtered(packageType ? packageFixed : packagesReady);
    let pagedData = datasetPagination(filterData, 5);
    let listData = pagedData[this.state.activePage - 1];
    // let showCard = packages && !loading;
    let showCard = packageType ? packageFixed : packagesReady && !loading;
    return (
      <Modal
        closeIcon
        inverted
        open={openModal}
        onClose={closeModal}
        // size="medium"
        size="large"
      >
        <Modal.Header>{header}</Modal.Header>
        <Modal.Content>
          <InputSearch
            value={searchTitle}
            handleChange={this.handleChange}
            placeholder="Search Tour Title"
            inputStyle={{ borderBottom: '1px solid black' }}
            inputInnerStyle={{ border: '0px', borderRadius: 'none' }}
          />
          <Table basic="very" striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Booking No.</Table.HeaderCell>
                <Table.HeaderCell>Tour Name</Table.HeaderCell>
                {this.props.packageType || (
                  <Table.HeaderCell>Fixed Price</Table.HeaderCell>
                )}
                <Table.HeaderCell>Total Booking</Table.HeaderCell>
                <Table.HeaderCell>Total Confirm</Table.HeaderCell>
                <Table.HeaderCell>Available Seat</Table.HeaderCell>
                <Table.HeaderCell>Country</Table.HeaderCell>
                <Table.HeaderCell>Start Date</Table.HeaderCell>
                <Table.HeaderCell>Duration</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {showCard && listData
                ? listData.map((item, index) => (
                    <Table.Row key={'list' + index}>
                      <Table.Cell>{item.Id}</Table.Cell>
                      <Table.Cell>{item.Title}</Table.Cell>
                      {this.props.packageType || (
                        <Table.Cell>
                          {item.PackageType === 'Ready' ? 'No' : 'Yes'}
                        </Table.Cell>
                      )}
                      <Table.Cell>
                        {item.FixedPackage
                          ? item.FixedPackage.RegisteringGuest
                          : 0}
                      </Table.Cell>
                      <Table.Cell>
                        {item.FixedPackage
                          ? item.FixedPackage.ConfirmedGuest
                          : 0}
                      </Table.Cell>
                      <Table.Cell>
                        {item.FixedPackage ? item.FixedPackage.PaxLeft : 0}
                      </Table.Cell>
                      <Table.Cell>{item.Country.Name}</Table.Cell>
                      <Table.Cell>
                        {convertDateFormat(item.StartDate, 'D MMM YYYY')}
                      </Table.Cell>
                      <Table.Cell>
                        {getTotalDays(item.StartDate, item.EndDate, true) +
                          ' Days'}
                      </Table.Cell>
                      <Table.Cell>
                        <Button
                          color="blue"
                          name={item.PackageType}
                          value={item.Id}
                          onClick={this.handlePick}
                        >
                          Pick Package
                        </Button>{' '}
                      </Table.Cell>
                    </Table.Row>
                  ))
                : null}
            </Table.Body>
          </Table>
          <div style={{ textAlign: 'center' }}>
            {pagedData && pagedData.length > 1 && (
              <Pagination
                totalPages={pagedData.length}
                onPageChange={this.pageChange}
                activePage={this.state.activePage}
              />
            )}
          </div>
        </Modal.Content>
        <Loader loading={loading} inverted={true} page={true} />
      </Modal>
    );
  }
}

ModalPackageList.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  header: PropTypes.string,
  loading: PropTypes.bool,
  packages: PropTypes.array,
  packageType: PropTypes.bool,
};

export default ModalPackageList;
