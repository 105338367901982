import * as types from '../constant/actionTypes';

const initialState = {
  languageList: [],
  loading: false,
  errors: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //---------------------------------
    // GET_LANGUAGE_ALL
    //---------------------------------
    case types.GET_LANGUAGE_ALL:
      return { ...state };
    case types.GET_LANGUAGE_ALL_PENDING:
      return { ...state, loading: true };
    case types.GET_LANGUAGE_ALL_FULFILLED:
      return {
        ...state,
        languageList: action.payload.data,
        errors: {},
        loading: false,
      };
    case types.GET_LANGUAGE_ALL_REJECTED:
      return {
        ...state,
        errors: action.payload.response.data,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
