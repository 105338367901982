import { convertDateFormat } from '../../../../../../scripts/moment';
// const filterSupplierPrice = () => {
//   const supplierPrice = this.props.supplierPriceList;
//   let newSupplier = [];
//   supplierPrice.filter(function(item) {
//     var i = newSupplier.findIndex(
//       x =>
//         x.ServicePriceType === item.ServiceType &&
//         x.StartDate === item.StartDate &&
//         x.EndDate === item.EndDate &&
//         x.RankType === item.Rank
//     );
//     if (i <= -1) {
//       newSupplier.push({
//         Id: item.Id,
//         RankType: item.Rank,
//         RankValue: item.Rank,
//         ServicePriceType: item.ServiceType,
//         ServiceItemId: item.ServiceItemId,
//         MinimumNight: item.MinDays,
//         StartDate: moment(item.StartDate).format('MM/DD/YYYY'),
//         EndDate: moment(item.EndDate).format('MM/DD/YYYY'),
//         CutOfDate: item.CutOffDate,
//         CutOfDay: item.CutOffDay,
//         Currency: item.Currency,
//         PriceDetails: [],
//         onChange: false,
//         Status: 'Edit',
//       });
//     }
//     return newSupplier;
//   });
//   return newSupplier;
// };

const findPriceIndex = (datalist, currentData) => {
  return datalist.findIndex(item => {
    return (
      item.ServiceType === currentData.ServiceType &&
      item.StartDate ===
        convertDateFormat(currentData.StartDate, 'MM/DD/YYYY') &&
      item.EndDate === convertDateFormat(currentData.EndDate, 'MM/DD/YYYY') &&
      item.RankType === currentData.Rank
    );
  });
};

const groupingSupplierPriceDetails = priceList => {
  let groupingPrice = priceList.reduce((res, current, index) => {
    let existingIndex = findPriceIndex(res, current);
    if (existingIndex >= 0) {
      res[existingIndex].PriceDetails.push({
        Id: current.Id,
        MinPax: current.CalculationBase.Code,
        Price: current.Cost,
      });
      res[existingIndex].CustomId.push(current.Id);
    } else {
      res.push({
        Currency: current.Currency,
        CutOfDate: convertDateFormat(current.CutOffDate, 'MM/DD/YYYY'),
        CutOfDay: current.CutOffDay,
        EndDate: convertDateFormat(current.EndDate, 'MM/DD/YYYY'),
        CustomId: [current.Id],
        Id: current.Id,
        MinimumNight: current.MinDays,
        RankType: current.Rank,
        RankValue: current.Rank,
        ServiceItemId: current.ServiceItemId,
        ServiceType: current.ServiceType,
        ServicePriceType: current.ServiceType,
        StartDate: convertDateFormat(current.StartDate, 'MM/DD/YYYY'),
        Status: 'Edit',
        onChange: false,
        PriceDetails: [
          {
            Id: current.Id,
            MinPax: current.CalculationBase.Code,
            Price: current.Cost,
          },
        ],
        index: index,
      });
    }
    return res;
  }, []);

  return groupingPrice;
};

const filterByService = (dataList, service) =>
  dataList.filter(obj => {
    return obj.ServicePriceType === service;
  });

export { groupingSupplierPriceDetails, filterByService };
