const style = {
  dateMarker: {
    width: '60%',
    height: '3px',
    background: '#1266f1',
    margin: 'auto',
    border: '1px solid white',
  },
};

export default style;
