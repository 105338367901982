import apiClient from '../apiClient';
import { GET_EXPORT_DATA_ITEM_BY_PROFILE_ID } from '../apiUrl';

const getExportDataItemByIdApi = (supplierType, profileId) => {
  return apiClient.get(
    GET_EXPORT_DATA_ITEM_BY_PROFILE_ID(supplierType, profileId)
  );
};

export default getExportDataItemByIdApi;
