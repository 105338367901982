import React, { Component } from 'react';
import { Table, Button, Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import IndexPagination from '../../../../../components/genericFunction/indexPagination';
import { Style } from '../../../../../assets/styles/styleAccomodation';
import Pagination from '../../../../../components/common/pagination';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../../../scripts/pagination';
class TableTourGuidePrice extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
      headerTable: ['English Name', 'Local Name', 'Action'],
    };
  }
  static propTypes = {
    data: PropTypes.array,
  };
  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  filteredData = data =>
    data.filter(data => {
      return (
        data.Language.EnglishName.toLowerCase().indexOf(
          this.state.searchInput.toLowerCase()
        ) !== -1
      );
    });
  render() {
    const { data, disabled } = this.props;
    const { activePage, headerTable } = this.state;
    let filterData = this.filteredData(data);
    let pagedData = datasetPagination(filterData, 7);
    let listData = pagedData[activePage - 1];

    let { numbersFrom, numbersTo } = getNumberFromTo(listData, activePage, 7);
    return (
      <div>
        <Input
          icon="search"
          placeholder="search"
          name="searchInput"
          value={this.state.searchInput}
          onChange={this.handleChange}
        />
        <div style={Style.marginTable}>
          <Table basic="very" selectable fixed>
            <Table.Header>
              <Table.Row>
                {headerTable.map((headTableContent, index) => {
                  return (
                    <Table.HeaderCell key={index}>
                      {headTableContent}
                    </Table.HeaderCell>
                  );
                })}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {listData ? (
                listData.map((data, idx) => {
                  const idxPagination = IndexPagination(
                    7,
                    this.state.activePage,
                    idx
                  );
                  return (
                    data.Action !== 1 && (
                      <Table.Row key={idxPagination}>
                        <Table.Cell>{data.Language.EnglishName}</Table.Cell>
                        <Table.Cell>{data.Language.LocalName}</Table.Cell>
                        <Table.Cell>
                          <Button
                            content="Edit"
                            positive
                            onClick={this.props.handleEdit}
                            value={data.ServiceItemId}
                            disabled={disabled}
                            id={idxPagination}
                            name={data.LanguageId}
                          />
                          <Button
                            content="Delete"
                            negative
                            onClick={this.props.handleDelete}
                            value={data.ServiceItemId}
                            disabled={disabled}
                            id={idxPagination}
                            name={data.Language.EnglishName}
                          />
                        </Table.Cell>
                      </Table.Row>
                    )
                  );
                })
              ) : (
                <Table.Row>
                  <Table.Cell colSpan="4" textAlign="center">
                    No Data
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
          <Pagination
            pagedData={pagedData}
            numbersTo={numbersTo}
            numbersFrom={numbersFrom}
            data={data}
            pageChange={this.pageChange}
            activePage={activePage}
          />
        </div>
      </div>
    );
  }
}

TableTourGuidePrice.propTypes = {
  contractingFileList: PropTypes.array,
  listData: PropTypes.array,
  deleteState: PropTypes.func,
  headerTable: PropTypes.array,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  activePage: PropTypes.string,
  pageAddingData: PropTypes.array,
  pageChange: PropTypes.func,
  deleteContracting: PropTypes.func,
  viewContracting: PropTypes.func,
  downloadContracting: PropTypes.func,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};
export default TableTourGuidePrice;
