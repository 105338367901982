import React, { Component } from 'react';
import { Table, Pagination, Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { HeaderTableMasterDataRestaurantItem } from '../../../../components/table/headerTabel';
import { datasetPagination } from '../../../../scripts/pagination';
import FilterData from '../../../../scripts/filterDataTable';
class TableMasterDataRestaurantPrice extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
    };
  }
  static propTypes = {
    data: PropTypes.array,
  };
  handleChange = (e, { name, value }) => this.setState({ [name]: value });
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  render() {
    let filterData = FilterData(
      this.props.listData,
      'Name',
      this.state.searchInput
    );
    let pagedData = datasetPagination(filterData, 3);
    let listData = pagedData[this.state.activePage - 1];
    return (
      <div>
        <Input
          icon="search"
          placeholder="search"
          name="searchInput"
          value={this.state.searchInput}
          onChange={this.handleChange}
        />
        <Table basic="very" selectable fixed>
          <div style={{ overflow: 'auto' }}>
            <Table.Header>
              <Table.Row>
                {HeaderTableMasterDataRestaurantItem.map(
                  (headTableContent, index) => {
                    return (
                      <Table.HeaderCell key={index}>
                        {headTableContent}
                      </Table.HeaderCell>
                    );
                  }
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {listData &&
                listData.map((restaurantMenu, idx) => {
                  return (
                    <Table.Row key={idx}>
                      <Table.Cell component="th" scope="row" width={1}>
                        {restaurantMenu.CompanyCode}
                      </Table.Cell>
                      <Table.Cell> {restaurantMenu.Name}</Table.Cell>
                      <Table.Cell> {restaurantMenu.PriceUnit}</Table.Cell>
                      {/* Convert Uang */}
                      <Table.Cell> {restaurantMenu.Currency}</Table.Cell>
                      <Table.Cell>{restaurantMenu.PaxFrom}</Table.Cell>
                      <Table.Cell> {restaurantMenu.Price}</Table.Cell>
                      <Table.Cell> {restaurantMenu.StartDate}</Table.Cell>
                      {/* Convert Date */}
                      <Table.Cell> {restaurantMenu.EndDate}</Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
            {pagedData &&
              pagedData.length > 1 && (
                <Table.Footer>
                  <Pagination
                    totalPages={pagedData.length}
                    onPageChange={this.pageChange}
                    activePage={this.state.activePage}
                  />
                </Table.Footer>
              )}
          </div>
        </Table>
      </div>
    );
  }
}

TableMasterDataRestaurantPrice.propTypes = {
  listData: PropTypes.array,
};
export default TableMasterDataRestaurantPrice;
