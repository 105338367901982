import React from 'react';
import { Button } from 'semantic-ui-react';
import Styles from './Styles';
import PropTypes from 'prop-types';

const ButtonCloseModal = props => (
  <Button
    floated="right"
    icon="close"
    onClick={props.onClose}
    style={Styles.buttonClose}
    size="massive"
  />
);

ButtonCloseModal.propTypes = {
  onClose: PropTypes.func,
};

export default ButtonCloseModal;
