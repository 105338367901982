const styles = {
  marginTop30px: { marginTop: '30px' },
  displayTab: {
    display: 'none',
  },
  buttonBorderBlue: {
    border: 'solid 2px #2680EB',
    background: 'white',
    color: '#2680EB',
  },
  buttonBlue: {
    border: 'solid 2px #2680EB',
    background: '#2680EB',
    color: 'white',
  },
  padding12point5: {
    padding: '12.5px',
  },
  noPadding: {
    padding: '0px',
  },
  paddingBottom20px: {
    padding: '0px 0px 20px 0px',
  },
  paddingBottom10px: {
    paddingBottom: '10px',
  },
  width100Percent: {
    width: '100%',
  },
};
export default styles;
