import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Accordion } from 'semantic-ui-react';
import AccordionTitle from './accordionTitle';
import AccordionContent from './accordionContent';

const accordionMenu = props => {
  return (
    <Accordion as={Menu} inverted>
      <Menu.Item style={{ paddingLeft: '27px' }}>
        <AccordionTitle
          activeIndex={props.activeIndex}
          index={props.index}
          handleClick={props.handleClick}
          title={props.title}
          icon={props.icon}
          dropdownStyle={props.dropdownStyle}
          level={2}
        />
        <AccordionContent
          activeIndex={props.activeIndex}
          index={props.index}
          menu={props.menu}
          handleClickConfirm={props.handleClickConfirm}
          // handleClick={props.handleClick}
          // title={props.title}
          // icon={props.icon}
          // dropdownStyle={props.dropdownStyle}
        />
      </Menu.Item>
    </Accordion>
  );
};

// const accordionMenu = props => (
//   <Accordion as={Menu} inverted>
//     <Menu.Item>
//       <AccordionTitle
//         activeIndex={props.activeIndex}
//         index={props.index}
//         handleClick={props.handleClick}
//         title={props.title}
//         icon={props.icon}
//         dropdownStyle={props.dropdownStyle}
//       />
//       <AccordionContent
//         activeIndex={props.activeIndex}
//         index={props.index}
//         menu={props.menu}
//         handleClickConfirm={props.handleClickConfirm}
//       />
//     </Menu.Item>
//   </Accordion>
// );

accordionMenu.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  icon: PropTypes.string,
  menu: PropTypes.array,
  dropdownStyle: PropTypes.object,
  handleClickConfirm: PropTypes.func,
};
export default accordionMenu;
