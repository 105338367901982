import {
  RESET_MASTER_REFERENCE_PAGE,
  POST_MASTER_REFERENCE,
  DELETE_MASTER_REFERENCE,
  PUT_MASTER_REFERENCE,
} from './constant/actionTypes';
import deleteMasterDataReferenceApi from '../../api/uploadMasterDataReferenceApi/deleteMasterDataReferenceApi';
import postMasterDataReferenceApi from '../../api/uploadMasterDataReferenceApi/postMasterDataReferenceApi';
import putMasterDataReferenceApi from '../../api/uploadMasterDataReferenceApi/putMasterDataReferenceApi';

export const resetMasterDataReferencePageAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_MASTER_REFERENCE_PAGE,
    });
  };
};

export const deleteMasterReferenceAction = (param, Id) => {
  return dispatch => {
    return dispatch({
      type: DELETE_MASTER_REFERENCE,
      payload: deleteMasterDataReferenceApi(param, Id),
    });
  };
};

export const postMasterReferenceAction = (param, data) => {
  return dispatch => {
    return dispatch({
      type: POST_MASTER_REFERENCE,
      payload: postMasterDataReferenceApi(param, data),
    });
  };
};

export const putMasterReferenceAction = (param, id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_MASTER_REFERENCE,
      payload: putMasterDataReferenceApi(param, id, data),
    });
  };
};
