import apiClient from '../apiClient';
import {
  GET_RESOURCE_BOOKING_BY_TOUR_TRANSACTION_CODE_URL,
  GET_RESOURCE_BOOKING_DETAIL_BY_RESOURCE_BOOKING_ID_URL,
} from '../apiUrl';

export const getResourceBookingByTourTransactionCodeApi = tourTransactionId => {
  return apiClient.get(
    GET_RESOURCE_BOOKING_BY_TOUR_TRANSACTION_CODE_URL(tourTransactionId)
  );
};

export const getResourceBookingDetailByResourceBookingIdApi = tourTransactionId => {
  return apiClient.get(
    GET_RESOURCE_BOOKING_DETAIL_BY_RESOURCE_BOOKING_ID_URL(tourTransactionId)
  );
};

// GET_RESOURCE_BOOKING_DETAIL_BY_RESOURCE_BOOKING_ID_URL
