import React from 'react';
import { Dimmer, Tab } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ModalAlert from '../../../components/modal/modalAlert';
import Loader from '../../../components/loader';
import AccomodationProfileList from './components/AccommodationProfileList';
import TableAccomodationItemList from '../../shared/components/table/accommodation/TableAccommodationItemList';
import SegmentRoomAllotment from '../../../components/segment/segmentRoomAllotment';
import ModalItem from '../../shared/components/modal/accommodation/ModalItemAccomodationProfile';
//import ModalItem from '../components/modal/modalAccessibleCity';
import { datasetPagination } from '../../../scripts/pagination';
import moment from 'moment';
import ModalImage from '../../../components/modal/modalImage';
import { rating } from '../../../scripts/rating';
// import {
//   getAccomodationByProfiledIdAction, //
//   resetAccomodationAction, //
//   getAccomodationTypesAction,
//   getAccomodationFacilitiesAction,
//   getAccomodationLocationAction,
//   getAccomodationItemByProfiledIdAction, //
//   postAccomodationProfileAction,
//   putAccomodationProfileAction,
//   getTemplateAccomodationItemAction, //
//   getExportDataItemByIdAction,
//   deleteAccomodationItemAction,
//   postUploadImageAccomodationProfileAction, //
//   deleteImageAccomodationProfileAction,
//   deleteContactingFileAction,
//   postContractingFileAction,
// } from '../../../actions/accomodationAction';
import {
  getAccomodationTypesAction,
  getAccomodationFacilitiesAction,
  getAccomodationLocationAction,
  postAccomodationProfileAction,
  putAccomodationProfileAction,
  deleteAccomodationItemAction,
  deleteImageAccomodationProfileAction,
  deleteContactingFileAction,
  postContractingFileAction,
} from '../accommodationAction';
import {
  getAccomodationByProfiledIdAction,
  resetAccomodationAction,
  getAccomodationItemByProfiledIdAction,
  getTemplateAccomodationItemAction,
  postUploadImageAccomodationProfileAction,
  getExportDataItemByIdAction,
} from '../../shared/actions/accommodationAction';
import {
  getTourPaxTypeAction,
  getTourTypeAction,
} from '../../../actions/tourTransactionAction';
import { getCityListAction } from '../../shared/actions/cityAction';
import { getRegionListAction } from '../../shared/actions/regionAction';
import { getCountryListAction } from '../../shared/actions/countryAction';
import { getAreaListAction } from '../../shared/actions/areaAction';
import { encodeImageFileAsURL } from '../../../scripts/getBase64';
import ModalConfirm from '../../../components/modal/modalConfirmationTwoButton';
import ModalContractingFile from '../../../components/modal/modalContractingFile';
// import accommodationProfileField from './constant/fieldToValidate';
// import handleValidation from '../../shared/services/validation/emptyFieldValidation';
// import _ from 'lodash';

class AccomodationProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accomodationProfileId: {
        Segments: [],
        Name: '',
        Description: '',
        TypeId: '',
        LocationId: [],
        NeedApprovalThreshold: '',
        RatingId: '',
        AccommodationProfileImages: [],
        ProfileImages: [],
        Facilities: [],
        Contracts: [],
        ProfileContracts: [],
        AddressObjectRequest: {
          Address1: '',
          Address2: '',
          Address3: '',
          PostalCode: '',
          LandMark: '',
          PhoneNbr: '',
          Email: '',
          CityId: '',
          RegionId: '',
          AreaId: '',
          CountryId: '',
        },
        DefaultReleaseDays: 0,
        Priority: false,
        Company: {
          Code: '',
          Name: '',
        },
      },
      contractingFile: {
        FileName: '',
        FileContent: '',
        StartDate: moment(new Date()).format('YYYY-MM-DD'),
        EndDate: moment(new Date())
          .add(7, 'days')
          .format('YYYY-MM-DD'),
        Description: '',
      },
      listAccomodationItemByProfilId: [],
      loadingAccomodation: true,
      failedAccomodation: false,
      loadingAccomodationItem: true,
      failedAccomodationItem: false,
      listTourType: [],
      modalOpen: false,
      openModalItem: false,
      openModalImage: false,
      contentModal: '',
      dataItem: [],
      imageTest: null,
      idURL: '',
      headerAlert: '',
      contentAlert: '',
      openModalConfirmation: false,
      locationAccomodation: [],
      featuresAccomodation: [],
      image: [],
      listStepImage: [
        {
          Tittle: 'Choose Image',
          Active: true,
        },
        {
          Tittle: 'Select Thumbnail Image',
          Active: false,
        },
        {
          Tittle: 'Edit Thumbnail Image',
          Active: false,
        },
      ],
      indexActive: 0,
      imageUrl: null,
      errors: {},
      disablePage: true,
      activePage: 1,
      activePageImage: 1,
      activePageFeatures: 1,
      activePageLocation: 1,
      activePageContract: 1,
      isDeleteImage: false,
      deletedContracting: [],
      deletedImage: [],
      isSaveAccom: false,
    };
  }

  static propTypes = {
    getAccomodationByProfiledIdAction: PropTypes.func,
    resetAccomodationAction: PropTypes.func,
    statusAccomodation: PropTypes.string,
    listAccomodationProfile: PropTypes.array,
    statusPaymentHistory: PropTypes.string,
    getAccomodationTypesAction: PropTypes.func,
    getAccomodationLocationAction: PropTypes.func,
    getAccomodationFacilitiesAction: PropTypes.func,
    getAccomodationItemByProfiledIdAction: PropTypes.func,
    listType: PropTypes.array,
    listTourType: PropTypes.array,
    listTourPaxType: PropTypes.array,
    listAccomodationFacilities: PropTypes.array,
    listAccomodationLocation: PropTypes.array,
    statusAccomodationItem: PropTypes.string,
    listAccomodationItem: PropTypes.array,
    getTourPaxTypeAction: PropTypes.func,
    getTourTypeAction: PropTypes.func,
    getRegionListAction: PropTypes.func,
    getCityListAction: PropTypes.func,
    getAreaListAction: PropTypes.func,
    getCountryListAction: PropTypes.func,
    listCountry: PropTypes.array,
    postAccomodationProfileAction: PropTypes.func,
    listRegion: PropTypes.array,
    listCity: PropTypes.array,
    listArea: PropTypes.array,
    deleteAccomodationByProfileIdAction: PropTypes.func,
    putAccomodationProfileAction: PropTypes.func,
    postStatusAccommodation: PropTypes.string,
    getTemplateAccomodationItemAction: PropTypes.func,
    getExportDataItemByIdAction: PropTypes.func,
    getAccomodationItemTemplate: PropTypes.string,
    getAccomodationItemTemplateStatus: PropTypes.string,
    getAccomodationItemExportStatus: PropTypes.string,
    getAccomodationItemExport: PropTypes.string,
    postAccommodation: PropTypes.array,
    putStatusAccommodation: PropTypes.string,
    deleteAccomodationItemAction: PropTypes.func,
    deleteAccomodationItemStatus: PropTypes.string,
    errorMessage: PropTypes.string,
    deleteImageAccomodationStatus: PropTypes.string,
    postUploadImageAccomodationProfileAction: PropTypes.func,
    deleteImageAccomodationProfileAction: PropTypes.func,
    deleteContractingFileStatus: PropTypes.string,
    deleteContactingFileAction: PropTypes.func,
    postContractingFileAction: PropTypes.func,
    deleteContractingFile: PropTypes.string,
    postContractingFileStatus: PropTypes.string,
    match: PropTypes.object,
    postSetPrimaryImageAccomodationProfileAction: PropTypes.func,
  };
  renderTabMenu = () => [
    {
      menuItem: 'Accommodation Item',
      render: () => (
        <Tab.Pane
          active={true}
          content={
            <TableAccomodationItemList
              listData={this.state.listAccomodationItemByProfilId}
              loading={this.state.loadingAccomodationItem}
              handleClickEdit={this.handleClickEdit}
              handleClickDelete={this.handleClickDelete}
              handleNewAccomodation={this.ButtonNewAccomodationItem}
              butttonExport={this.ButtonExportAccomodation}
              buttonUploadData={this.ButtonUploadDataAccomodationItem}
              buttonDownloadTemplate={this.ButtonDownloadTemplate}
              disablePage={this.state.disablePage}
            />
          }
        />
      ),
    },
    {
      menuItem: 'Room Allotment',
      render: () => (
        <Tab.Pane
          active={true}
          content={
            <SegmentRoomAllotment
              accomodationProfile={this.state.accomodationProfileId}
              idProfile={this.props.match.params.ProfileId}
              handleChangeAccommodation={this.onChange}
              disablePage={this.state.disablePage}
              defaultReleaseDays={
                this.props.listAccomodationProfile
                  ? this.props.listAccomodationProfile.ReleaseDays
                  : 0
              }
              handleBlurDefaultReleaseDay={this.saveAccomodation}
            />
          }
        />
      ),
    },
  ];
  onChange = async (e, { value, name }) => {
    const accomodationProfileId = { ...this.state.accomodationProfileId };
    accomodationProfileId[name] =
      name === 'DefaultReleaseDays' ? parseInt(value, 10) : value;
    await this.setState({
      ...this.state,
      accomodationProfileId: accomodationProfileId,
    });
    const getRegion = name === 'CountryId';
    const getCity = name === 'RegionId';
    const getArea = name === 'CityId';
    if (getRegion) {
      this.props.getRegionListAction(value);
    } else if (getCity) {
      this.props.getCityListAction(value);
    } else if (getArea) {
      this.props.getAreaListAction(value);
    }
  };
  handleClose = () =>
    this.setState({
      failedAccomodation: false,
      failedAccomodationItem: false,
      openModalConfirmation: false,
      openModalImage: false,
      openConfirm: false,
      modalContracting: false,
      openConfirmContracting: false,
    });
  handleCloseModalItem = () =>
    this.setState({
      openModalItem: false,
      locationAccomodation: [],
      featuresAccomodation: [],
    });
  handleCloseModalImage = () =>
    this.setState({
      openModalImage: false,
      imageAccomodation: [],
    });
  componentDidMount = () => {
    this.props.getAccomodationTypesAction();
    this.props.getAccomodationLocationAction();
    this.props.getAccomodationFacilitiesAction();
    this.getAccomodationProfileId();
    this.props.getTourPaxTypeAction();
    this.props.getTourTypeAction();
    this.props.getCountryListAction();
  };
  getAccomodationProfileId = () => {
    // eslint-disable-next-line
    // let id = this.props.match.params.ProfileId;
    const { ProfileId } = this.props.match.params;
    if (ProfileId === undefined) {
      this.setState({
        loadingAccomodation: false,
        loadingAccomodationItem: false,
        idURL: '',
        titleHeader: 'Add Accommodation',
        disablePage: false,
        contentButton: 'Save',
      });
    } else {
      return this.getAllAccomodation(ProfileId);
    }
  };
  getAllAccomodation = id => {
    this.props.getAccomodationByProfiledIdAction(id);
    this.props.getAccomodationItemByProfiledIdAction(id);
    const { Type } = this.props.match.params;
    if (Type === 'Detail') {
      this.setState({
        idURL: id,
        titleHeader: 'Detail Accommodation',
        contentButton: 'Edit',
        disablePage: true,
      });
    } else {
      this.setState({
        idURL: id,
        titleHeader: 'Edit Accommodation',
        contentButton: 'Save',
        disablePage: false,
      });
    }
  };
  componentDidUpdate() {
    if (this.props.statusAccomodation === 'success') {
      this.setState({
        accomodationProfileId: this.props.listAccomodationProfile,
      });
      this.setStateAccomodationProfile(this.props.listAccomodationProfile);
      this.setState({ loadingAccomodation: false });
      this.props.resetAccomodationAction();
    } else if (this.props.statusAccomodation === 'failed') {
      this.setState({
        failedAccomodation: true,
        headerAlert: 'Failed',
        contentAlert:
          'Please check your internet connection and Refresh Page [Ctrl+R]',
      });
      this.setState({ loadingAccomodation: false });
      this.props.resetAccomodationAction();
    }
    if (this.props.statusAccomodationItem === 'success') {
      this.setState({
        listAccomodationItemByProfilId: this.props.listAccomodationItem,
      });
      this.setState({ loadingAccomodationItem: false });
      this.props.resetAccomodationAction();
    } else if (this.props.statusAccomodationItem === 'failed') {
      this.setState({
        failedAccomodationItem: true,
        headerAlert: 'Failed',
        contentAlert:
          'Please check your internet connection and Refresh Page [Ctrl+R]',
      });
      this.setState({ loadingAccomodationItem: false });
      this.props.resetAccomodationAction();
    }
    if (this.props.postStatusAccommodation === 'success') {
      this.setState({
        headerAlert: 'Success Saving Accommodation',
        contentAlert: 'Saving Accommodation',
        openModalConfirmation: true,
        //postDataAccomodation: postAccommodation,
        loadingAccomodationItem: false,
        contentButton: 'Edit',
        disablePage: true,
        loadingAccomodation: false,
      });
      this.getAllAccomodation(this.props.postAccommodation.Id);
      this.postContracting(this.props.postAccommodation.Id);
      // eslint-disable-next-line
      this.props.history.push(
        `/Accomodation/Edit/${this.props.postAccommodation.Id}`
      );
      this.props.resetAccomodationAction();
    } else if (this.props.postStatusAccommodation === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: 'Failed Saving Accommodation',
        openModalConfirmation: true,
        loadingAccomodation: false,
      });
      this.setState({ failedAccomodationItem: true });
      this.setState({ loadingAccomodationItem: false });
      this.props.resetAccomodationAction();
    }

    if (this.props.getAccomodationItemTemplateStatus === 'success') {
      var templateAccomodation = this.props.getAccomodationItemTemplate;
      var elemenTemplateAccomodation = document.createElement('a');
      var TemplateAccomodation = new Blob([templateAccomodation], {
        type: 'text/plain',
      });
      elemenTemplateAccomodation.href = URL.createObjectURL(
        TemplateAccomodation
      );
      elemenTemplateAccomodation.download = 'Accomodation Item Template.txt';
      elemenTemplateAccomodation.click();
      this.setState({ loadingAccomodation: false });
      this.props.resetAccomodationAction();
    } else if (this.props.getAccomodationItemTemplateStatus === 'failed') {
      this.props.resetAccomodationAction();
    }

    if (this.props.getAccomodationItemExportStatus) {
      var exportAccomodation = this.props.getAccomodationItemExport;
      var elementAccomodation = document.createElement('a');
      var fileAccomdation = new Blob([exportAccomodation], {
        type: 'text/plain',
      });
      elementAccomodation.href = URL.createObjectURL(fileAccomdation);
      elementAccomodation.download = 'Accomodation Item Export.txt';
      elementAccomodation.click();
      this.setState({ loadingAccomodation: false });
      this.props.resetAccomodationAction();
    } else if (this.props.getAccomodationItemExportStatus) {
      this.props.resetAccomodationAction();
    }
    if (this.props.putStatusAccommodation === 'success') {
      this.setState({
        headerAlert: 'Success Edit Accommodation',
        contentAlert: 'Saving Accommodation',
        openModalConfirmation: true,
        loadingAccomodationItem: false,
        contentButton: 'Edit',
        disablePage: true,
        loadingAccomodation: false,
      });
      let id = this.props.match.params.ProfileId;
      this.getAllAccomodation(id);
      this.props.resetAccomodationAction();
    } else if (this.props.putStatusAccommodation === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: 'Failed Saving Accommodation',
        openModalConfirmation: true,
        loadingAccomodation: false,
      });
      this.setState({ failedAccomodationItem: true });
      this.setState({ loadingAccomodationItem: false });
      this.props.resetAccomodationAction();
    }

    if (this.props.deleteAccomodationItemStatus === 'success') {
      this.setState({
        headerAlert: 'Success ',
        contentAlert: 'Delete Accommodation',
        openModalConfirmation: true,
        loadingAccomodationItem: false,
      });
      const { ProfileId } = this.props.match.params;
      this.getAllAccomodation(ProfileId);
      this.props.resetAccomodationAction();
    } else if (this.props.deleteAccomodationItemStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorMessage,
        openModalConfirmation: true,
        loadingAccomodationItem: false,
      });
      this.props.resetAccomodationAction();
    }
    if (this.props.deleteImageAccomodationStatus === 'success') {
      this.setState({
        headerAlert: 'Success ',
        contentAlert: 'the picture was successfully deleted',
        openModalConfirmation: true,
        loadingAccomodation: false,
      });
      const { ProfileId } = this.props.match.params;
      this.getAllAccomodation(ProfileId);
      this.props.resetAccomodationAction();
    } else if (this.props.deleteImageAccomodationStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorMessage,
        openModalConfirmation: true,
        loadingAccomodation: false,
      });
      this.props.resetAccomodationAction();
    }
    if (this.props.deleteContractingFileStatus === 'success') {
      // this.setState({
      //   headerAlert: 'Success ',
      //   contentAlert: this.props.deleteContractingFile,
      //   openModalConfirmation: true,
      // });
      const { ProfileId } = this.props.match.params;
      this.props.getAccomodationByProfiledIdAction(ProfileId);
      this.props.getAccomodationItemByProfiledIdAction(ProfileId);
      this.props.resetAccomodationAction();
    } else if (this.props.deleteContractingFileStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorMessage,
        openModalConfirmation: true,
      });
      this.props.resetAccomodationAction();
    }
    if (this.props.postContractingFileStatus === 'success') {
      // this.setState({
      //   headerAlert: 'Success ',
      //   contentAlert: 'file has been added successfully',
      //   openModalConfirmation: true,
      // });
      const { ProfileId } = this.props.match.params;
      this.props.getAccomodationByProfiledIdAction(ProfileId);
      this.props.getAccomodationItemByProfiledIdAction(ProfileId);
      this.props.resetAccomodationAction();
    } else if (this.props.postContractingFileStatus === 'failed') {
      //remove/fileId/accomodatiopProfileId
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorMessage,
        openModalConfirmation: true,
      });
      this.props.resetAccomodationAction();
    }
  }

  // validateAccommodationProfile = () => {
  //   let isError = false;
  //   const errors = handleValidation(
  //     this.state.accommodationProfileId,
  //     accommodationProfileField
  //   );

  //   isError = !_.isEmpty(errors);

  //   this.setState({
  //     errors: {
  //       ...errors,
  //     },
  //   });
  //   return isError;
  // };

  validate = () => {
    let isError = false;
    const errors = {
      Name: '',
      Description: '',
      TypeId: '',
      Address1: '',
      PhoneNbr: '',
      Email: '',
      CityId: '',
      RegionId: '',
      CountryId: '',
      AreaId: '',
      NeedApprovalThreshold: '',
      RatingId: '',
      Segments: '',
    };
    if (this.state.accomodationProfileId.Name.length < 1) {
      isError = true;
      errors.Name = "Name Can't Be Empty";
    }
    if (this.state.accomodationProfileId.Description.length < 1) {
      isError = true;
      errors.Description = "Description Can't Be Empty";
    }
    if (
      this.state.accomodationProfileId.AddressObjectRequest.Address1.length < 1
    ) {
      isError = true;
      errors.Address1 = "Address Can't Be Empty";
    }
    if (
      this.state.accomodationProfileId.AddressObjectRequest.CityId.length < 1
    ) {
      isError = true;
      errors.CityId = "City Can't Be Empty";
    }
    if (
      this.state.accomodationProfileId.AddressObjectRequest.CountryId.length < 1
    ) {
      isError = true;
      errors.CountryId = "Country Can't Be Empty";
    }
    if (
      this.state.accomodationProfileId.AddressObjectRequest.RegionId.length < 1
    ) {
      isError = true;
      errors.RegionId = "Region Can't Be Empty";
    }
    if (
      this.state.accomodationProfileId.AddressObjectRequest.AreaId.length < 1
    ) {
      isError = true;
      errors.AreaId = "Area Can't Be Empty";
    }
    if (
      this.state.accomodationProfileId.AddressObjectRequest.PhoneNbr.length < 1
    ) {
      isError = true;
      errors.PhoneNbr = "Phone Number Can't Be Empty";
    }
    if (
      this.state.accomodationProfileId.AddressObjectRequest.Email.indexOf(
        '@' && '.'
      ) === -1
    ) {
      isError = true;
      errors.Email = 'Email Invalid';
    }
    if (this.state.accomodationProfileId.NeedApprovalThreshold.length < 1) {
      isError = true;
      errors.NeedApprovalThreshold = "Room Limit Can't Be Empty";
    }
    if (this.state.accomodationProfileId.TypeId.length < 1) {
      isError = true;
      errors.TypeId = "Type Can't Be Empty";
    }
    if (this.state.accomodationProfileId.RatingId.length < 1) {
      isError = true;
      errors.RatingId = "Rating Can't Be Empty";
    }
    if (this.state.accomodationProfileId.Segments.length < 1) {
      isError = true;
      errors.Segments = "Tour Type Can't Be Empty";
    }
    this.setState({
      errors: {
        ...errors,
      },
    });
    return isError;
  };

  saveAccomodation = async () => {
    const err = await this.validate();
    if (!err) {
      await this.setState({
        ...this.state,
        openConfirm: true,
        messageConfirm: 'Are you sure want to save this accommodation?',
        isSaveAccom: true,
      });
    }
  };
  doSave = () => {
    const {
      Segments,
      AddressObjectRequest,
      CompanyCode,
      ProfileContracts,
      // Contracts,
      ProfileImages,
    } = this.state.accomodationProfileId;
    const segmentDestructure = Segments.map(segment => {
      return { SegmentId: segment };
    });
    const { deletedContracting } = this.state;
    let data = {
      ...this.state.accomodationProfileId,
      CompanyCode: CompanyCode ? CompanyCode : 10000,
      Segments: segmentDestructure,
      AreaId: AddressObjectRequest.AreaId,
      CityId: AddressObjectRequest.CityId,
      RegionId: AddressObjectRequest.RegionId,
      CountryId: AddressObjectRequest.CountryId,
      Telephone: AddressObjectRequest.PhoneNbr,
      Email: AddressObjectRequest.Email,
      ProfileImages: ProfileImages.map(data => {
        return {
          ImageContent: data.ImageId > 0 ? '' : data.ImageContent,
          ImageId: data.ImageId,
          ImageName: data.ImageName,
          ImageUrl: data.ImageId > 0 ? '' : data.ImageContent,
          IsPrimaryImage: data.IsPrimaryImage,
        };
      }),
    };
    this.setState({ loadingAccomodation: true });
    const Id = this.state.idURL;
    if (Id === '') {
      this.props.postAccomodationProfileAction(data);
      // .then(res => {
      //   data.ProfileImages.map(data => {
      //     return data.ImageId === 0
      //       ? this.props
      //           .postUploadImageAccomodationProfileAction({
      //             accommodationProfileId: res.value.data.Id,
      //             imageUrl: data.ImageContent,
      //             TinyImageUrl: data.ImageContent,
      //             ImageName: data.ImageName,
      //             ImageContent: data.ImageContent,
      //           })
      //           .then(() => {
      //             this.getAccomodationProfileId();
      //           })
      //       : null;
      //   });
      // });
    } else {
      this.props.putAccomodationProfileAction(Id, data);
      // data.ProfileImages.map(data => {
      //   return data.ImageId === 0
      //     ? this.props.postUploadImageAccomodationProfileAction({
      //         accommodationProfileId: Id,
      //         imageUrl: data.ImageContent,
      //         TinyImageUrl: data.ImageContent,
      //         ImageName: data.ImageName,
      //         ImageContent: data.ImageContent,
      //       })
      //     : null;
      // });
      ProfileContracts.map(
        data =>
          // eslint-disable-next-line
          data.FileId == 0 && this.props.postContractingFileAction(data)
      );
      if (deletedContracting.length > 0) {
        deletedContracting.map(values =>
          this.props.deleteContactingFileAction(values, Id)
        );
      }
      if (this.state.deletedImage.length > 0) {
        this.state.deletedImage.map(values =>
          this.props.deleteImageAccomodationProfileAction(values)
        );
      }
      // if (this.state.isDeleteImage) {
      //   this.props.deleteImageAccomodationProfileAction(this.state.ImageId);
      // }
    }
    this.setState({ deletedImage: [], deletedContracting: [] });
  };
  handleSave = (e, { name }) => {
    if (name === 'Edit') {
      this.setState({
        titleHeader: 'Edit Accommodation ',
        contentButton: 'Save',
        disablePage: false,
      });
    } else {
      this.saveAccomodation();
    }
  };
  setStateAccomodationProfile = data => {
    const {
      Name,
      Desc,
      Telephone,
      Email,
      ImageUrl,
      OverView,
      NeedApprovalThreshold,
      Company,
      AccommodationType,
      AccommodationRating,
      ProfileImages,
      AccommodationLocations,
      ProfileFacilities,
      AccommodationSegment,
      Area,
      AddressObject,
      Contracts,
      ProfileContracts,
      DefaultReleaseDays,
      Priority,
    } = data;

    const locationDestructure =
      AccommodationLocations &&
      AccommodationLocations.map(location => {
        return { LocationId: location.Id, Name: location.Name, Status: true };
      });
    const featuresDestructure = ProfileFacilities.map(features => {
      return { FacilityId: features.Id, Name: features.Name };
    });
    const imageDestructure = ProfileImages.map(location => {
      return {
        ImageId: location.ImageId,
        ImageContent: location.ImageUrl,
        ImageName: location.ImageName,
        IsPrimaryImage: location.IsPrimaryImage,
        ImageUrl: location.ImageUrl,
      };
    });
    let accomodationProfileId = {
      CompanyCode: Company.Code ? Company.Code : '',
      Name: Name ? Name : '',
      Description: Desc ? Desc : '',
      Address: '',
      Telephone: Telephone ? Telephone : '',
      Email: Email ? Email : '',
      AreaId: Area.Id ? Area.Id : '',
      CityId: AddressObject.City.Id ? AddressObject.City.Id : '',
      RegionId: AddressObject.Region.Id ? AddressObject.Region.Id : '',
      RatingId: AccommodationRating.Id ? AccommodationRating.Id : '',
      TypeId:
        AccommodationType && AccommodationType.Id ? AccommodationType.Id : '',
      NeedApprovalThreshold: NeedApprovalThreshold ? NeedApprovalThreshold : '',
      LocationId: locationDestructure,
      Facilities: featuresDestructure,
      Segments: AccommodationSegment.map(p => p.SegmentId),
      AddressObjectRequest: {
        Address1: AddressObject.Address1 ? AddressObject.Address1 : '',
        Address2: AddressObject.Address2 ? AddressObject.Address2 : '',
        Address3: AddressObject.Address3 ? AddressObject.Address3 : '',
        AreaId: AddressObject.AreaId ? Area.Id : '',
        PostalCode: AddressObject.PostalCode ? AddressObject.PostalCode : '',
        Landmark: AddressObject.Landmark ? AddressObject.Landmark : '',
        CityId: AddressObject.City.Id ? AddressObject.City.Id : '',
        RegionId: AddressObject.Region.Id ? AddressObject.Region.Id : '',
        CountryId: AddressObject.Country.Id ? AddressObject.Country.Id : '',
        PhoneNbr: Telephone ? Telephone : '',
        Phone: Telephone ? Telephone : '',
        Email: Email ? Email : '',
      },
      ProfileImages: imageDestructure,
      Overview: OverView ? OverView : '',
      ImageName: '',
      ImageContent: ImageUrl ? ImageUrl : '',
      Contracts: Contracts ? Contracts : [],
      ProfileContracts: ProfileContracts ? ProfileContracts : [],
      DefaultReleaseDays: DefaultReleaseDays,
      Priority: Priority ? Priority : false,
      Company,
    };
    this.props.getRegionListAction(
      AddressObject.Country.Id ? AddressObject.Country.Id : ''
    );
    this.props.getCityListAction(AddressObject.Region.Id);
    this.props.getAreaListAction(AddressObject.City.Id);
    this.setState({
      accomodationProfileId: accomodationProfileId,
      locationAccomodation: locationDestructure,
      featuresAccomodation: featuresDestructure,
    });
  };
  ButtonNewAccomodationItem = () => {
    const { idURL } = this.state;
    if (idURL === '') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: 'Please Save Accommodation First',
        openModalConfirmation: true,
      });
    } else {
      // eslint-disable-next-line
      this.props.history.push(`/Accomodation-Item/${idURL}`);
    }
  };
  onClickBack = () => {
    // eslint-disable-next-line
    this.props.history.push('/Accomodation');
  };

  addState = () => {
    if (this.state.contentModal === 'Features') {
      this.setState({
        openModalItem: false,
        accomodationProfileId: {
          ...this.state.accomodationProfileId,
          Facilities: this.state.featuresAccomodation,
        },
      });
    } else if (this.state.contentModal === 'Location') {
      // const { LocationId } = this.state.accomodationProfileId;
      // const list = this.props.listAccomodationLocation.map(location => {
      //   return { Id: location.Id, Name: location.Name, Status: false };
      // });
      this.setState({
        openModalItem: false,
        accomodationProfileId: {
          ...this.state.accomodationProfileId,
          LocationId: this.state.locationAccomodation,
        },
      });
    } else {
      this.setState({
        openModalImage: false,
        accomodationProfileId: {
          ...this.state.accomodationProfileId,
          AccommodationProfileImages: this.state.imageAccomodation,
        },
      });
    }
  };
  onChangeState = e => {
    let value = e.target.name;
    let name = e.target.id;
    if (this.state.contentModal === 'Features') {
      this.setState(prevState => ({
        featuresAccomodation: [
          ...prevState.featuresAccomodation,
          {
            FacilityId: value,
            Name: name,
          },
        ],
      }));
    } else if (this.state.contentModal === 'Location') {
      this.setState(prevState => ({
        locationAccomodation: [
          ...prevState.locationAccomodation,
          {
            LocationId: value,
            Name: name,
          },
        ],
      }));
    } else {
      let objectImage = URL.createObjectURL(e.target.files[0]);
      let ImageName = e.target.files[0].name;
      encodeImageFileAsURL(objectImage).then(dataUrl => {
        this.setState(prevState => ({
          imageAccomodation: [
            ...prevState.imageAccomodation,
            {
              ImageId: 0,
              ImageContent: dataUrl,
              ImageName: ImageName,
              IsPrimaryImage: false,
            },
          ],
        }));
      });
    }
  };

  deleteState = (e, { value, id }) => {
    if (value === 'Location') {
      const { LocationId } = this.state.accomodationProfileId;
      let listLocation = LocationId.filter(item => item.LocationId !== id);
      let list = this.state.dataItem;
      list.map(location => {
        if (location.Id === id) {
          location['Status'] = false;
          return list;
        }
        return list;
      });
      // this.setState({
      //   accomodationProfileId: {
      //     ...this.state.accomodationProfileId,
      //     LocationId: this.state.accomodationProfileId.LocationId.filter(
      //       item => item.LocationId !== id
      //     ),
      //   },
      //   //activePage: 1,
      //   activePageLocation: 1,
      // });
      this.setState({
        accomodationProfileId: {
          ...this.state.accomodationProfileId,
          LocationId: listLocation,
        },
        activePageLocation: 1,
        dataItem: list,
      });
    } else if (value === 'Features') {
      this.setState({
        accomodationProfileId: {
          ...this.state.accomodationProfileId,
          Facilities: this.state.accomodationProfileId.Facilities.filter(
            item => item.FacilityId !== id
          ),
        },
        activePageFeatures: 1,
        // activePage: 1,
      });
    }
  };
  addImage = e => {
    let objectImage = URL.createObjectURL(e.target.files[0]);
    let ImageName = e.target.files[0].name;
    encodeImageFileAsURL(objectImage).then(dataUrl => {
      this.setState({
        imageUrl: objectImage,
        customerProfile: {
          ...this.state.customerProfile,
          CompanyLogo: {
            ImageName: ImageName,
            ImageContent: dataUrl,
          },
        },
      });
    });

    this.state.accomodationProfileId.image.push({
      ImageId: 0,
      ImageContent: e.target.files[0],
      ImageName: e.target.files[0].name,
      IsPrimaryImage: true,
    });
    this.setState({
      imageUrl: URL.createObjectURL(e.target.files[0]),
    });
  };

  modalItemAction = (e, { id }) => {
    if (id === 'Location') {
      const { LocationId } = this.state.accomodationProfileId;
      const list = this.props.listAccomodationLocation.map(location => {
        return { Id: location.Id, Name: location.Name, Status: false };
      });
      list.map(location => {
        LocationId.map(data => {
          if (location.Id === data.LocationId) {
            location['Status'] = true;
            return list;
          }
          return list;
        });
        return list;
      });
      this.setState({
        contentModal: 'Location',
        openModalItem: true,
        dataItem: list,
      });
    } else if (id === 'Features') {
      const { Facilities } = this.state.accomodationProfileId;
      const list = this.props.listAccomodationFacilities.map(location => {
        return { Id: location.Id, Name: location.Name, Status: false };
      });
      list.map(features => {
        Facilities.map(data => {
          if (features.Id === data.FacilityId) {
            features['Status'] = true;
            return list;
          }
          return list;
        });
        return list;
      });
      this.setState({
        contentModal: 'Features',
        openModalItem: true,
        dataItem: list,
      });
    } else {
      this.setState({
        contentModal: 'Image',
        openModalImage: true,
        dataItem: [],
      });
    }
    // return e.target.id === 'Location'
    //   ? this.setState({
    //       contentModal: 'Location',
    //       openModalItem: true,
    //       dataItem: this.props.listAccomodationLocation,
    //     })
    //   : //? this.handleItemLocation()
    //     e.target.id === 'Features'
    //     ? this.setState({
    //         contentModal: 'Features',
    //         openModalItem: true,
    //         dataItem: this.props.listAccomodationFacilities,
    //       })
    //     : this.setState({
    //         contentModal: 'Image',
    //         openModalImage: true,
    //         dataItem: [],
    //       });
  };
  ButtonUploadDataAccomodationItem = () => {
    // eslint-disable-next-line
    this.props.history.push(`/Upload-Master-Data/${'AccomodationItem'}`);
  };
  ButtonDownloadTemplate = () => {
    this.props.getTemplateAccomodationItemAction();
    this.setState({ loadingAccomodation: true });
  };
  ButtonExportAccomodation = () => {
    const { ProfileId } = this.props.match.params;
    this.props.getExportDataItemByIdAction('AccommodationItems', ProfileId);
    this.setState({ loadingAccomodation: true });
  };
  onChangeDropdown = (e, { value, name }) => {
    const addressObject = this.state.accomodationProfileId.AddressObjectRequest;
    addressObject[name] = value;
    this.setState({ addressObject: addressObject });
    const getRegion = name === 'CountryId';
    const getCity = name === 'RegionId';
    const getArea = name === 'CityId';
    if (getRegion) {
      this.props.getRegionListAction(value);
    } else if (getCity) {
      this.props.getCityListAction(value);
    } else if (getArea) {
      this.props.getAreaListAction(value);
    }
  };

  // modal Image
  addImage = () => {
    let objectImage = this.state.accomodationProfileId;
    let image = this.state.image;
    objectImage.ProfileImages.push(image);
    let listStepImage = this.state.listStepImage;
    listStepImage.map((step, i) => {
      step['Active'] = i === 0 ? true : false;
      return listStepImage;
    });
    this.setState({
      accomodationProfileId: objectImage,
      openModalImage: false,
      listStepImage: listStepImage,
      indexActive: 0,
    });
  };
  changeImage = e => {
    let imageObject = this.state.accomodationProfileId;
    e.map(base => {
      let image = {
        ImageId: 0,
        ImageContent: base.base64.split(',')[1],
        ImagePreview: base.base64,
        ImageName: base.file.name,
        IsPrimaryImage: false,
      };
      return imageObject.ProfileImages.push(image);
    });
    this.setState({
      accomodationProfileId: imageObject,
    });
  };
  openDetail = (e, { value }) => {
    const { ProfileImages } = this.state.accomodationProfileId;
    let listStepImage = this.state.listStepImage;
    listStepImage.map((step, i) => {
      step['Active'] = i === 0 ? true : false;
      return listStepImage;
    });
    let image = ProfileImages[value];
    this.setState({
      image: image,
      indexActive: 0,
      listStepImage: listStepImage,
      openModalImage: true,
      imageUrl: image.ImageContent,
      // imageUrl: image.ImageContent //ImageUrl
      //   ? image.ImageUrl //ImageUrl
      //   : URL.createObjectURL(e.target.files[0]),
    });
  };
  handleChangePrimaryImage = (e, { value }) => {
    let listImage = this.state.accomodationProfileId;
    let newList = [];
    listImage.ProfileImages.map((img, i) => {
      img['IsPrimaryImage'] = i === value ? true : false;
      return newList.push(img);
    });
    this.setState({
      accomodationProfileId: {
        ...this.state.accomodationProfileId,
        ProfileImages: newList,
      },
    });
  };
  handleNext = () => {
    let indexActive = this.state.indexActive;
    indexActive = indexActive + 1;
    let listStepImage = this.state.listStepImage;
    listStepImage.map((step, i) => {
      step['Active'] = i === indexActive ? true : false;
      return listStepImage;
    });
    if (indexActive === 2) {
      let listImage = this.state.accomodationProfileId;
      listImage.ProfileImages.map(img => {
        return img.IsPrimaryImage && this.setState({ image: img });
      });
    }
    if (indexActive === 3) {
      return this.setState({ indexActive: 0, openModalImage: false });
    }
    this.setState({ listStepImage: listStepImage, indexActive: indexActive });
  };
  handleBackStep = () => {
    let indexActive = this.state.indexActive;
    indexActive = indexActive - 1;
    let listStepImage = this.state.listStepImage;
    listStepImage.map((step, i) => {
      step['Active'] = i === indexActive ? true : false;
      return listStepImage;
    });
    this.setState({ listStepImage: listStepImage, indexActive: indexActive });
  };
  //---------------====---
  //Modal Adding Data
  handleItemLocation = () => {
    let listLocation = this.props.listAccomodationLocation;
    let location = this.state.locationAccomodation;
    listLocation.map(datalocationList => {
      location.map(dataLocation => {
        if (datalocationList.Id === dataLocation.Id) {
          location['Status'] = true;
          return listLocation;
        }
        return listLocation;
      });
      return listLocation;
    });
    this.setState({
      contentModal: 'Location',
      openModalItem: true,
      dataItem: listLocation,
    });
  };
  handleCheckLocation = (e, { name, checked }) => {
    let list = this.state.dataItem;
    // let value = e.target.name;
    // let Name = e.target.id;
    list.map(location => {
      if (location.Id === name) {
        location['Status'] = checked;
      }
      return location;
    });
    this.setState({ dataItem: list });
    if (this.state.contentModal === 'Features') {
      let newFeatures = list.reduce((total, state) => {
        if (state.Status === true) {
          total.push({
            FacilityId: state.Id,
            Name: state.Name,
          });
        }
        return total;
      }, []);
      this.setState({ featuresAccomodation: newFeatures });
    } else {
      let newLocation = list.reduce((total, state) => {
        if (state.Status === true) {
          total.push({ LocationId: state.Id, Name: state.Name });
        }
        return total;
      }, []);
      this.setState({ locationAccomodation: newLocation });
    }
  };

  handleClickEdit = (e, { value }) => {
    const { ProfileId } = this.props.match.params;
    // eslint-disable-next-line
    this.props.history.push(`/Accomodation-Item/Edit/${ProfileId}/${value}`);
  };

  handleClickDelete = (e, { value }) => {
    this.setState({ loadingAccomodationItem: true });
    this.props.deleteAccomodationItemAction(value);
  };

  deleteConfirmation = (e, { name }) => {
    let { accomodationProfileId } = this.state;
    let { ProfileImages } = accomodationProfileId;
    let id = ProfileImages[name].ImageId ? ProfileImages[name].ImageId : 0;
    let imageName = ProfileImages[name].ImageName
      ? ProfileImages[name].ImageName
      : '';
    this.setState({
      ImageId: id,
      indexImageDelete: name,
      openConfirm: true,
      messageConfirm: 'Are you sure to delete image  ' + imageName + ' ?',
      isDeleteImage: true,
    });
  };
  hanldeConfirmModalConfirm = async idx => {
    await this.setState({
      ...this.state,
      openConfirm: false,
      messageConfirm: '',
    });
    if (this.state.isSaveAccom) this.doSave();
    else if (this.state.isDeleteImage) this.deleteImageAccomodationProfile(idx);
  };
  deleteImageAccomodationProfile = idx => {
    const { deletedImage, accomodationProfileId } = this.state;
    let accommodationProfile = { ...accomodationProfileId };
    let profileImage = [...accomodationProfileId.ProfileImages];
    // profileImage.splice(indexImageDelete, 1);
    const imageid = profileImage[idx].ImageId ? profileImage[idx].ImageId : 0;
    deletedImage.push(imageid);
    profileImage = profileImage.filter((item, i) => i !== idx);
    accommodationProfile.ProfileImages = profileImage;

    this.setState({
      accomodationProfileId: accommodationProfile,
      activePageImage: 1,
      openConfirm: false,
    });
  };
  pageChangeImage = (e, { activePage }) => {
    this.setState({ activePageImage: activePage });
  };
  pageChangeFeatures = (e, { activePage }) => {
    this.setState({ activePageFeatures: activePage });
  };
  pageChangeLocation = (e, { activePage }) => {
    this.setState({ activePageLocation: activePage });
  };
  pageChangeContract = (e, { activePage }) => {
    this.setState({ activePageContract: activePage });
  };

  viewDetailContractingFile = (e, { id }) => {
    window.open(id);
  };
  // downloadContractingFile = (e, { id, name }) => {
  //   var urlDownloadContractingFile = id;
  //   var elemenDownloadContractingFile = document.createElement('a');
  //   var fileDownloadContractingFile = new Blob([urlDownloadContractingFile], {
  //     type: 'text/plain',
  //   });
  //   elemenDownloadContractingFile.href = URL.createObjectURL(
  //     fileDownloadContractingFile
  //   );
  //   elemenDownloadContractingFile.download = name;
  //   elemenDownloadContractingFile.click();
  //   this.setState({ loadingAccomodation: false });
  // };
  deleteContractingFile = () => {
    const {
      ContractingId,
      accomodationProfileId,
      indexContracting,
    } = this.state;
    let deletedContracting = [];
    if (ContractingId === 0) {
      accomodationProfileId.ProfileContracts.splice(indexContracting, 1);
    } else {
      accomodationProfileId.Contracts[indexContracting].deleted = true;
      accomodationProfileId.ProfileContracts.splice(indexContracting, 1);
      deletedContracting.push(ContractingId);
    }
    this.setState({
      //loadingAccomodation: true,
      openConfirmContracting: false,
      deletedContracting: deletedContracting,
      activePageContract: 1,
    });
  };
  saveContracting = () => {
    const { contractingFile } = this.state;
    const { ProfileId } = this.props.match.params;
    let data = {
      ...contractingFile,
      AccommodationProfileId: ProfileId,
      FileId: 0,
    };
    if (
      contractingFile.FileContent !== '' &&
      contractingFile.FileContent !== ''
    ) {
      this.state.accomodationProfileId.ProfileContracts.push(data);
      this.state.accomodationProfileId.Contracts.push(data);
      // this.props.postContractingFileAction(data);
      this.setState({
        modalContracting: false,
      });
    }
  };

  openModalContractingFile = () => {
    //const { idURL } = this.state;
    // if (idURL === '') {
    //   this.setState({
    //     headerAlert: 'Failed',
    //     contentAlert: 'Please Save Accommodation First',
    //     openModalConfirmation: true,
    //   });
    // } else {
    this.setState({
      modalContracting: true,
      contractingFile: {
        FileName: '',
        FileContent: '',
        StartDate: moment(new Date()).format('YYYY-MM-DD'),
        EndDate: moment(new Date())
          .add(7, 'days')
          .format('YYYY-MM-DD'),
        Description: '',
        FileId: 0,
      },
    });
    // }
  };
  handleChangeContracting = (e, { value, name }) => {
    const contractingFile = this.state.contractingFile;
    contractingFile[name] = value;
    this.setState({ contractingFile: contractingFile });
  };

  handleChangeFile = e => {
    let contractingFile = this.state.contractingFile;
    let file = {};
    e.map(base => {
      file = {
        ...contractingFile,
        FileName: base.file.name,
        FileContent: base.base64.split(',')[1],
      };
      return file;
    });
    this.setState({
      contractingFile: file,
    });
  };
  confirmDeleteContracting = (e, { name, value, id }) => {
    this.setState({
      ContractingId: value,
      openConfirmContracting: true,
      messageConfirm: 'Are you sure to delete ' + name + '?',
      ContractingName: name,
      indexContracting: id,
    });
  };
  postContracting = Id => {
    const { ProfileContracts } = this.state.accomodationProfileId;
    let contractingFile = {};
    ProfileContracts.map(data => {
      contractingFile = {
        ...data,
        AccommodationProfileId: Id,
      };
      return (
        // eslint-disable-next-line
        data.FileId == 0 &&
        this.props.postContractingFileAction(contractingFile)
      );
    });
  };
  onChangeCheckboxPriority = (e, { checked }) => {
    const accomodationProfileId = { ...this.state.accomodationProfileId };
    accomodationProfileId['Priority'] = checked;
    this.setState({
      ...this.state,
      accomodationProfileId: accomodationProfileId,
    });
  };

  render() {
    let {
      loadingAccomodation,
      failedAccomodation,
      loadingAccomodationItem,
      failedAccomodationItem,
      openModalConfirmation,
      accomodationProfileId,
      // searchInput,
      // activePage,
      activePageContract,
      activePageFeatures,
      activePageImage,
      activePageLocation,
    } = this.state;
    let {
      LocationId,
      Facilities,
      ProfileImages,
      //Contracts,
      ProfileContracts,
    } = accomodationProfileId;
    // console.log(LocationId);
    // let filterDataLocation = FilterData(LocationId, '', searchInput);
    let pageLocation = datasetPagination(LocationId, 3);
    let listDataLocation = pageLocation[activePageLocation - 1];
    let pageFacilities = datasetPagination(Facilities, 3);
    let listDataFacilities = pageFacilities[activePageFeatures - 1];
    let loading = loadingAccomodation || loadingAccomodationItem;
    // let pageImage = datasetPagination(ProfileImages, 3);
    // let listImage = pageImage[activePageImage - 1];
    let pageContracting = datasetPagination(ProfileContracts, 3);
    let listDataContracting = pageContracting[activePageContract - 1];
    let openModal =
      failedAccomodation || failedAccomodationItem || openModalConfirmation;
    return (
      <div>
        <Dimmer.Dimmable blurring dimmed={loading}>
          <Loader loading={loading} />
          <AccomodationProfileList
            Facilities={Facilities}
            LocationId={LocationId}
            ProfileContracts={ProfileContracts}
            ProfileImages={ProfileImages}
            saveAccomodation={this.handleSave}
            // saveAccomodation={this.saveAccomodation}
            backPage={this.onClickBack}
            handleChange={this.onChange}
            handleChangeDropdown={this.onChangeDropdown}
            accomodationProfileData={this.state}
            listAccomodationFacilities={this.props.listAccomodationFacilities}
            listAccomodationLocation={this.props.listAccomodationLocation}
            listType={this.props.listType}
            listTourType={this.props.listTourType}
            listTourPaxType={this.props.listTourPaxType}
            handleNewAccomodation={this.ButtonNewAccomodationItem}
            listAccomodationItemByProfilId={
              this.state.listAccomodationItemByProfilId
            }
            modalItem={this.modalItemAction}
            listImage={ProfileImages}
            listCountry={this.props.listCountry}
            listRegion={this.props.listRegion}
            listCity={this.props.listCity}
            listArea={this.props.listArea}
            addLocation={this.addLocation}
            rating={rating}
            deleteData={this.deleteState}
            buttonUploadData={this.ButtonUploadDataAccomodationItem}
            butttonExport={this.ButtonExportAccomodation}
            buttonDownloadTemplate={this.ButtonDownloadTemplate}
            title={this.state.titleHeader}
            openDetail={this.openDetail}
            handleChangePrimaryImage={this.handleChangePrimaryImage}
            errors={this.state.errors}
            contentButton={this.state.contentButton}
            disablePage={this.state.disablePage}
            handleClickDelete={this.handleClickDelete}
            handleClickEdit={this.handleClickEdit}
            deleteImage={this.deleteImageAccomodationProfile}
            //activePage={activePage}
            //pageChange={this.pageChange}
            listDataLocation={listDataLocation}
            pageLocation={pageLocation}
            listDataFacilities={listDataFacilities}
            pageFacilities={pageFacilities}
            // pageImage={pageImage}
            viewContracting={this.viewDetailContractingFile}
            // downloadContracting={this.downloadContractingFile}
            deleteContracting={this.confirmDeleteContracting}
            openModalContractingFile={this.openModalContractingFile}
            listDataContracting={listDataContracting}
            pageContracting={pageContracting}
            pageChangeImage={this.pageChangeImage}
            pageChangeLocation={this.pageChangeLocation}
            pageChangeFeatures={this.pageChangeFeatures}
            pageChangeContract={this.pageChangeContract}
            activePageImage={activePageImage}
            activePageContract={activePageContract}
            activePageFeatures={activePageFeatures}
            activePageLocation={activePageLocation}
            tabMenu={this.renderTabMenu()}
            onChangeCheckboxPriority={this.onChangeCheckboxPriority}
          />
          <ModalAlert
            openModal={openModal}
            handleClose={this.handleClose}
            header={this.state.headerAlert}
            content={this.state.contentAlert}
            nameButton="OK"
          />
          <ModalItem
            openModal={this.state.openModalItem}
            handleClose={this.handleCloseModalItem}
            content={this.state.contentModal}
            data={this.state.dataItem}
            //onChangeItem={this.onChangeState}
            onChangeItem={this.handleCheckLocation}
            addItem={this.addState}
            listData={this.state.dataItem}
            addAccessCity={this.addAccessCity}
            /*header="Accessible City"
          openModal={props.openModalAccCity}
          handleClose={props.handleCloseAccCity}
          listData={props.listAccessCity}
          handleChangeAccessCity={props.handleChangeAccessCity}
          pageChange={props.pageChangeCity}
          activePage={props.activePage}
          pageData={props.pageDataCity}
          addAccessCity={props.addAccessCity}*/
          />
          {/* <ModalImage
            openModal={this.state.openModalImage}
            handleClose={this.handleCloseModalImage}
            content={this.state.contentModal}
            imagePreview={this.state.imageUrl}
            handleChange={this.onChangeState}
            addImage={this.addState}
          /> */}
          <ModalImage
            openModal={this.state.openModalImage}
            imagePreview={this.state.imageUrl}
            handleChange={this.changeImage}
            addImage={this.addImage}
            handleClose={this.handleClose}
            header="Upload Image"
            image={this.state.accomodationProfileId.ProfileImages}
            listStepImage={this.state.listStepImage}
            handleNext={this.handleNext}
            handleBackStep={this.handleBackStep}
            indexActive={this.state.indexActive}
            handleChangePrimaryImage={this.handleChangePrimaryImage}
            imageRatio={this.state.image}
          />
          <ModalConfirm
            openModal={this.state.openConfirm}
            size="mini"
            close={this.handleClose}
            confirm={this.hanldeConfirmModalConfirm}
            message={this.state.messageConfirm}
          />
          <ModalContractingFile
            open={this.state.modalContracting}
            handleClose={this.handleClose}
            header="Contracting File"
            handleChange={this.handleChangeContracting}
            contentField={this.state.contractingFile}
            saveContracting={this.saveContracting}
            // errorInformation={this.state.error}
            loading={this.state.loadingModalContracting}
            handleChangeFile={this.handleChangeFile}
          />
          <ModalConfirm
            openModal={this.state.openConfirmContracting}
            size="mini"
            close={this.handleClose}
            confirm={this.deleteContractingFile}
            message={this.state.messageConfirm}
          />
        </Dimmer.Dimmable>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    listAccomodationProfile: state.AccomodationStore.getAccomodationByProfilId,
    statusAccomodation: state.AccomodationStore.getAccomodationListStatus,
    listAccomodationFacilities:
      state.AccomodationPageStore.getAccomodationFacilities,
    listAccomodationLocation:
      state.AccomodationPageStore.getAccomodationLocation,
    statusAccomodationItem: state.AccomodationStore.getAccomodationItemStatus,
    listAccomodationItem:
      state.AccomodationStore.getAccomodationItemByProfileId,
    listType: state.AccomodationPageStore.getAccomodationTypes.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    listTourType: state.TourTransactionStore.listTourType.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    listTourPaxType: state.TourTransactionStore.listTourPaxType.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    listCountry: state.CountryStore.getCountryList.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    listRegion: state.RegionStore.getRegionList.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    listCity: state.CityStore.getCityList.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    listArea: state.AreaStore.getAreaList.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    postStatusAccommodation: state.AccomodationPageStore.postAccomodationStatus,
    postAccommodation: state.AccomodationPageStore.postAccomodation,
    putStatusAccommodation: state.AccomodationPageStore.putAccomodationStatus,
    getAccomodationItemTemplate:
      state.AccomodationStore.getAccomodationItemTemplate,
    getAccomodationItemTemplateStatus:
      state.AccomodationStore.getAccomodationItemTemplateStatus,
    getAccomodationItemExport: state.AccomodationStore.getExportDataItemById,
    getAccomodationItemExportStatus:
      state.AccomodationStore.getExportDataItemByIdStatus,
    errorMessage: state.AccomodationStore.errors.global,
    deleteAccomodationItemStatus:
      state.AccomodationPageStore.deleteAccomodationItemStatus,
    deleteImageAccomodationStatus:
      state.AccomodationPageStore.deleteImageAccomodationStatus,
    deleteContractingFile: state.AccomodationPageStore.deleteContractingFile,
    deleteContractingFileStatus:
      state.AccomodationPageStore.deleteContractingFileStatus,
    postContractingFile: state.AccomodationPageStore.postContractingFile,
    postContractingFileStatus:
      state.AccomodationPageStore.postContractingFileStatus,
  };
}
export default connect(mapStateToProps, {
  getAccomodationByProfiledIdAction,
  resetAccomodationAction,
  getAccomodationTypesAction,
  getAccomodationFacilitiesAction,
  getAccomodationLocationAction,
  getAccomodationItemByProfiledIdAction,
  getTourPaxTypeAction,
  getTourTypeAction,
  getCityListAction,
  getRegionListAction,
  getCountryListAction,
  getAreaListAction,
  postAccomodationProfileAction,
  putAccomodationProfileAction,
  getTemplateAccomodationItemAction,
  getExportDataItemByIdAction,
  deleteAccomodationItemAction,
  postUploadImageAccomodationProfileAction,
  deleteImageAccomodationProfileAction,
  deleteContactingFileAction,
  postContractingFileAction,
  // postSetPrimaryImageAccomodationProfileAction,
})(AccomodationProfile);
