const fieldToValidate = [
  'Name',
  'Address1',
  'Code',
  'City',
  'PlaceType',
  'AreaId',
  'Country',
  'Region',
];

export default fieldToValidate;
