import { passwordValidation } from '../../../shared/services/validation/passwordValidation';

const copyObject = obj => {
  let newObj = JSON.stringify(obj);
  return JSON.parse(newObj);
};

const changePasswordValidation = (UserObject, error) => {
  let checkErrors = copyObject(error);
  checkErrors.NewPassword.error =
    UserObject.NewPassword === '' ||
    !passwordValidation(UserObject.NewPassword);
  checkErrors.ConfirmPassword.error = UserObject.ConfirmPassword === '';
  checkErrors.ConfirmPassword.error =
    UserObject.ConfirmPassword !== UserObject.NewPassword;
  checkErrors.isError =
    checkErrors.NewPassword.error ||
    checkErrors.ConfirmPassword.error ||
    checkErrors.ConfirmPassword.error;
  return checkErrors;
};

export { changePasswordValidation };
