import React from 'react';
import { Dimmer } from 'semantic-ui-react';
import { connect } from 'react-redux';
import SegmentTourGuide from './components/TourGuideList';
import {
  getTourGuideAction,
  resetTourGuideAction,
  deleteTourGuideAction,
} from '../tourGuideAction';
import ModalAlert from '../../shared/components/modal/ModalAlert';
import Loader from '../../../components/loader';
import PropTypes from 'prop-types';
import ModalConfirm from '../../../components/modal/modalConfirmationTwoButton';
class TourGuideList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tourGuideList: [],
    };
  }

  static propTypes = {
    getTourGuideAction: PropTypes.func,
    tourGuideStatus: PropTypes.string,
    tourGuideList: PropTypes.array,
    resetTourGuideAction: PropTypes.func,
    loading: PropTypes.bool,
    deleteTourGuideAction: PropTypes.func,
  };
  componentDidMount = () => {
    this.props.getTourGuideAction();
  };
  componentDidUpdate() {
    let { tourGuideStatus, tourGuideList, resetTourGuideAction } = this.props;
    if (tourGuideStatus === 'success') {
      this.setState({
        tourGuideList: tourGuideList,
      });
      resetTourGuideAction();
    } else if (tourGuideStatus === 'failed') {
      resetTourGuideAction();
    }
  }

  confirmDeleteTourGuide = (name, value) => {
    this.setState({
      openModalConfirm: true,
      message: ` Are sure to delete ${name} ?`,
      idWantToDelete: value,
      nameWantToDelete: name,
    });
  };
  closeModalConfirm = () => {
    this.setState({
      openModalConfirm: false,
    });
  };
  deleteTourGuide = () => {
    let { idWantToDelete, nameWantToDelete } = this.state;
    this.props
      .deleteTourGuideAction(idWantToDelete)
      .then(() => {
        this.closeModalConfirm();
        this.handleAlert('Success', `${nameWantToDelete} successfully deleted`);
      })
      .catch(err => {
        this.handleAlert('Failed', err.response.data.Message);
        // eslint-disable-next-line
        console.log(err);
      });
  };

  handleClose = () => {
    if (this.state.headerAlert === 'Success') {
      this.props.getTourGuideAction();
    }
    this.setState({ openModalAlert: false });
  };

  handleAlert = (header, content) => {
    this.setState({
      openModalAlert: true,
      headerAlert: header,
      contentAlert: content,
    });
  };
  render() {
    let { loading } = this.props;
    let {
      tourGuideList,
      openModalAlert,
      headerAlert,
      contentAlert,
      openModalConfirm,
      message,
    } = this.state;

    return (
      <div>
        <Dimmer.Dimmable blurring dimmed={loading}>
          <Loader loading={loading} />
          <SegmentTourGuide
            data={tourGuideList}
            deleteTourGuideAction={this.confirmDeleteTourGuide}
          />
          <ModalAlert
            openModal={openModalAlert}
            handleClose={this.handleClose}
            header={headerAlert}
            content={contentAlert}
            nameButton="OK"
          />
          <ModalConfirm
            openModal={openModalConfirm}
            size="mini"
            close={this.closeModalConfirm}
            confirm={this.deleteTourGuide}
            message={message}
            header="Confirmation"
          />
        </Dimmer.Dimmable>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    tourGuideList: state.TourGuideStore.tourGuideList,
    tourGuideStatus: state.TourGuideStore.tourGuideStatus,
    loading: state.TourGuideStore.loading,
    errors: state.TourGuideStore.errors,
  };
}
export default connect(
  mapStateToProps,
  {
    getTourGuideAction,
    resetTourGuideAction,
    deleteTourGuideAction,
  }
)(TourGuideList);
