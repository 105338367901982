import React from 'react';
import { Table, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
const TableNotAllowedCountryForCompany = props => (
  <Table basic="very" selectable fixed>
    <Table.Header>
      <Table.Row>
        {props.headerTable.map((headTableContent, index) => {
          return (
            <Table.HeaderCell key={index}>{headTableContent}</Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {props.listData &&
        props.listData.map((notAllowed, idx) => {
          return (
            <Table.Row key={idx}>
              <Table.Cell component="th" scope="row" width={1}>
                {notAllowed.CompanyCode}
              </Table.Cell>
              <Table.Cell>{notAllowed.CompanyName}</Table.Cell>
              <Table.Cell>
                {notAllowed.NotAllowedCountries &&
                  Array.prototype.map
                    .call(notAllowed.NotAllowedCountries, function(item) {
                      return item.Country.Name;
                    })
                    .join(', ')}
              </Table.Cell>
              <Table.Cell>
                <Button
                  content="Edit"
                  primary
                  onClick={props.buttonEdit}
                  id={notAllowed.CompanyCode}
                />
              </Table.Cell>
            </Table.Row>
          );
        })}
    </Table.Body>
  </Table>
);

TableNotAllowedCountryForCompany.propTypes = {
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  buttonEdit: PropTypes.func,
};
export default TableNotAllowedCountryForCompany;
