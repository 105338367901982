import React from 'react';
import { Dimmer } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ModalAlert from '../../shared/components/modal/ModalAlert';
import ModalImage from '../../../components/modal/modalImage';
import Loader from '../../../components/loader';
import RestaurantItemPrice from './components/SegmentRestaurantItem';
import moment from 'moment';
import { getCurrenciesAction } from '../../../actions/currenciesAction';
import {
  getRestaurantClassAction,
  getRestaurantTypeAction,
  getRestaurantCategoryAction,
  getRestaurantItemByIdAction,
  resetRestaurantAction,
  postRestaurantItemAction,
  putRestaurantItemAction,
  getMenuTypeAction,
  getMenuClassesAction,
} from '../../../actions/restaurantAction';
import {
  getServicePriceAction,
  postServicePriceAction,
  putServicePriceAction,
  deleteServicePriceAction,
  resetServicePriceAction,
  getServicePriceTypeAction,
} from '../../../actions/servicePriceAction';
import ModalRestaurantPrice from './components/modal/ModalRestaurantPrice';
import ModalConfirm from '../../../components/modal/modalConfirmationTwoButton';
import ModalRestaurantPriceDetail from './components/modal/ModalRestaurantPriceDetail';
import {
  fieldToValidate,
  fieldToValidatePrice,
} from './constant/fieldToValidate';
import handleValidation from './services/validation/emptyFieldValidation';
import _ from 'lodash';
import {
  setRestaurantPrice,
  setSupplierPriceForFilter,
} from './services/data/setRestaurantPriceData';
class RestaurantItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      restaurantPrice: {
        PriceDetails: [],
      },
      restaurantPriceList: [],
      restaurantItem: {
        Description: '',
        ImageUrl: null,
        MenuCategory: '',
        MenuClass: '',
        MenuType: '',
        MinimumPax: 0,
        Name: '',
        RestaurantImages: [],
        RestaurantProfileId: 0,
        ServiceItemId: null,
        TinyImageUrl: null,
      },
      openModalAlert: false,
      headerModal: '',
      content: '',
      openModalImage: false,
      listStepImage: [
        {
          Tittle: 'Choose Image',
          Active: true,
        },
        {
          Tittle: 'Select Thumbnail Image',
          Active: false,
        },
        {
          Tittle: 'Edit Thumbnail Image',
          Active: false,
        },
      ],
      indexActive: 0,
      imageUrl: null,
      openModalPrice: false,
      editIndex: -1,
      disablePage: true,
      loadingRestaurantItem: true,
      loadingRestaurantItemPrice: true,
      errors: {},
      searchInput: '',
      // isEdit: true,
      restaurantPriceTemporary: {
        Currency: '',
        Cost: 0,
        MinPax: 0,
        Id: 0,
      },
      errorsPrice: {
        Cost: {
          message: '',
        },
      },
      deletedPrice: [],
      deletedPrices: [],
      deletePriceList: false,
    };
  }

  // setRestaurant = data => {
  //   const { RestaurantImages } = data;
  //   let destcruture = {
  //     data,
  //     RestaurantImages: RestaurantImages.map(base => {
  //       return {
  //         ImageId: 0,
  //         ImageContent: base.ImageContent,
  //         ImageName: base.Name,
  //         IsPrimaryImage: base.IsPrimaryImage,
  //       };
  //     }),
  //   };
  //   this.setState({
  //     restaurantItem: destcruture,
  //     loadingRestaurantItem: false,
  //   });
  // };
  componentDidUpdate = () => {
    if (this.props.getRestaurantItemStatus === 'success') {
      const restaurantItem = this.props.restaurantItem;
      //this.setRestaurant(restaurantItem);
      this.setState({
        restaurantItem: restaurantItem,
        loadingRestaurantItem: false,
      });
      this.props.resetRestaurantAction();
    }
    if (this.props.getSupplierPriceStatus === 'success') {
      this.filterSupplierPriceDetails(this.filterSupplierPrice());
      this.props.resetServicePriceAction();

      this.setState({ loadingRestaurantItemPrice: false });
    }
    if (this.props.putRestaurantItemStatus === 'success') {
      //this.saveRestaurantPrice();
      this.setState({
        openModalAlert: true,
        contentModal: 'Update item restaurant is success',
        headerModal: 'Success',
        contentButton: 'Edit',
        disablePage: true,
        loadingRestaurantItem: false,
      });
      this.props.resetRestaurantAction();
    }
    if (this.props.putRestaurantItemStatus === 'failed') {
      this.setState({
        openModalAlert: true,
        contentModal: this.props.errorMessage,
        headerModal: 'Failed',
        loadingRestaurantItem: false,
      });
      this.props.resetRestaurantAction();
    }

    if (this.props.postRestaurantItemStatus === 'success') {
      const restaurantItem = this.props.dataRestaurantItem;
      this.setState({
        restaurantItem: restaurantItem,
        openModalAlert: true,
        contentModal: 'Save restaurant item is success',
        headerModal: 'Success',
        contentButton: 'Edit',
        titleHeader: 'Detail Restaurant Item',
        disablePage: true,
        loadingRestaurantItem: false,
      });
      const { ProfileId } = this.props.match.params;
      this.props.getRestaurantItemByIdAction(restaurantItem.ServiceItemId);
      // eslint-disable-next-line
      this.props.history.push(
        '/Restaurant-Item/Edit/' +
          ProfileId +
          '/' +
          restaurantItem.ServiceItemId
      );
      this.props.resetRestaurantAction();
    }
    if (this.props.postRestaurantItemStatus === 'failed') {
      this.setState({
        openModalAlert: true,
        contentModal: this.props.errorMessage,
        headerModal: 'Failed',
        loadingRestaurantItem: false,
      });
      this.props.resetRestaurantAction();
    }
    if (this.props.postSupplierPriceStatus === 'success') {
      this.props.getServicePriceAction(this.state.restaurantItem.ServiceItemId);
      this.setState({
        loadingRestaurantItem: false,
        openModalAlert: true,
        contentModal: 'Success add price for ' + this.state.restaurantItem.Name,
        headerModal: 'Success',
      });
      this.props.resetServicePriceAction();
    }
    if (this.props.postSupplierPriceStatus === 'failed') {
      this.props.getServicePriceAction(this.state.restaurantItem.ServiceItemId);
      this.setState({
        openModalAlert: true,
        contentModal: this.props.errorMessage,
        headerModal: 'Failed',
        loadingRestaurantItem: false,
      });
      this.props.resetServicePriceAction();
    }
    if (this.props.deleteSupplierPriceStatus === 'success') {
      //this.saveRestaurantPrice();
      this.setState({
        loadingRestaurantItem: false,
        openModalAlert: true,
        contentModal: 'the price was successfully deleted',
        headerModal: 'Success',
      });
      this.props.resetServicePriceAction();
    }
    if (this.props.deleteSupplierPriceStatus === 'failed') {
      this.setState({
        loadingRestaurantItem: false,
        openModalAlert: true,
        contentModal: 'the price failed to be deleted',
        headerModal: 'Failed',
      });
      this.props.resetServicePriceAction();
    }
    if (this.props.putSupplierPriceStatus === 'success') {
      this.setState({
        headerModal: 'Success',
        contentModal: 'Edit Price Successfully',
        modalConfirmation: true,
        loadingRestaurantItem: false,
      });
      const { ItemId } = this.props.match.params;
      this.props.getRestaurantItemByIdAction(ItemId);
      this.props.getServicePriceAction(ItemId);
      this.props.resetServicePriceAction();
    } else if (this.props.putSupplierPriceStatus === 'failed') {
      this.setState({
        headerModal: 'Failed',
        contentModal: this.props.errorMessage,
        modalConfirmation: true,
        loadingRestaurantItem: false,
      });
      this.props.resetServicePriceAction();
    }
  };

  componentDidMount = () => {
    const { ProfileId, ItemId, Type } = this.props.match.params;
    this.props.getRestaurantItemByIdAction(ItemId);
    this.props.getServicePriceAction(ItemId);
    this.props.getServicePriceTypeAction('Restaurant');
    this.props.getCurrenciesAction();
    this.props.getRestaurantClassAction();
    this.props.getRestaurantTypeAction();
    this.props.getRestaurantCategoryAction();
    this.props.getMenuTypeAction();
    this.props.getMenuClassesAction();
    if (ItemId) {
      this.setState({
        idRestaurantItem: ItemId,
        titleHeader:
          Type === 'Detail' ? 'Detail Restaurant Item' : 'Edit Restaurant Item',
        disablePage: Type === 'Detail',
        contentButton: Type === 'Detail' ? 'Edit' : 'Save',
      });
    } else {
      this.setState({
        idRestaurantProfile: ProfileId,
        idRestaurantItem: '',
        titleHeader: 'Add Restaurant Item',
        disablePage: false,
        contentButton: 'Save',
        loadingRestaurantItem: false,
        loadingRestaurantItemPrice: false,
        restaurantItem: {
          ...this.state.restaurantItem,
          RestaurantProfileId: ProfileId,
        },
      });
    }
  };
  deletePrice = () => {
    if (this.state.deletePriceList) {
      this.handleDeletePrices();
    } else {
      this.deletePriceInModal();
    }
  };
  deletePriceInModal = () => {
    const { priceId, restaurantPrice, indexDeletePrice } = this.state;
    let deletedPrice = [];
    if (priceId === 0) {
      restaurantPrice.PriceDetails.splice(indexDeletePrice, 1);
    } else {
      // accomodationProfileId.Contracts[indexContracting].deleted = true;
      restaurantPrice.PriceDetails.splice(indexDeletePrice, 1);
      deletedPrice.push(priceId);
    }
    this.setState({
      openConfirmPrice: false,
      deletedPrice: deletedPrice,
    });
  };
  confirmationDeletePrice = (e, { id, value }) => {
    this.setState({
      priceId: value,
      openConfirmPrice: true,
      messageConfirm: 'Are you sure to delete this price ?',
      indexDeletePrice: id,
    });
  };

  handleChangePrices = (value, name) => {
    const { restaurantPrice, errors } = this.state;
    let valueConvert = moment(value).format('MM/DD/YYYY');

    let today = moment(new Date()).format('MM/DD/YYYY');
    if (name === 'StartDate') {
      if (restaurantPrice.Status === 'New') {
        if (new Date(valueConvert) < new Date(today)) {
          this.setState({
            errors: {
              ...errors,
              StartDate: 'Date must be in the future',
            },
          });
        } else {
          restaurantPrice[name] = moment(value).format('MM/DD/YYYY');
          restaurantPrice['onChange'] = true;
          this.setState({
            errors: {
              ...errors,
              StartDate: '',
            },
          });
        }
      } else {
        restaurantPrice[name] = moment(value).format('MM/DD/YYYY');
        restaurantPrice['onChange'] = true;
        this.setState({
          errors: {
            ...errors,
            StartDate: '',
          },
        });
      }
      //Date in the past
    } else if (name === 'EndDate') {
      if (restaurantPrice.Status === 'New') {
        if (new Date(valueConvert) <= new Date(restaurantPrice.StartDate)) {
          this.setState({
            errors: {
              ...errors,
              EndDate: 'Date must be in the future Start Date',
            },
          });
        } else {
          restaurantPrice[name] = moment(value).format('MM/DD/YYYY');
          restaurantPrice['onChange'] = true;
          this.setState({
            errors: {
              ...errors,
              EndDate: '',
            },
          });
        }
      } else {
        restaurantPrice[name] = moment(value).format('MM/DD/YYYY');
        restaurantPrice['onChange'] = true;
        this.setState({
          errors: {
            ...errors,
            EndDate: '',
          },
        });
      }
    }
    this.setState({
      restaurantPrice: restaurantPrice,
    });
  };
  handleChangePrice = (e, { value, name }) => {
    let restaurantPrice = this.state.restaurantPrice;
    restaurantPrice[name] = value;
    restaurantPrice['onChange'] = true;
    this.setState({ restaurantPrice: restaurantPrice });
  };
  handleChangeItem = (e, { value, name }) => {
    let restaurantItem = this.state.restaurantItem;
    restaurantItem[name] = value;
    restaurantItem['onChange'] = true;
    this.setState({ restaurantItem: restaurantItem });
    this.validate();
  };

  handleSearchAccesibleCity = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  addPrice = () => {
    // const { ItemId } = this.props.match.params;
    // if (ItemId === undefined) {
    //   this.setState({
    //     headerModal: 'Failed',
    //     contentModal: 'Please Save Restaurant Item First',
    //     openModalAlert: true,
    //   });
    // } else {
    let price = {
      CutOffDay: 0,
      MinPax: 0,
      Cost: 0,
      StartDate: moment(new Date()).format('MM/DD/YYYY'),
      EndDate: moment(new Date()).format('MM/DD/YYYY'),
      UomId: 'PCS',
      TaxTypeId: 0,
      Currency: '',
      Rank: 1,
      ServiceItemId: 0, //ItemId,
      ServicePriceType: 0,
      Description: '',
      PriceDetails: [],
      onChange: true,
      Status: 'New',
    };
    this.setState({
      restaurantPrice: price,
      editIndex: -1,
      openModalPrice: true,
    });
    // }
  };
  // addPriceDetails = () => {
  //   let restaurantPrice = this.state.restaurantPrice;
  //   let priceDetails = restaurantPrice.PriceDetails;
  //   let newPrice = {
  //     Currency: '',
  //     Cost: 0,
  //     MinPax: 0,
  //     Id: 0,
  //   };
  //   priceDetails.push(newPrice);
  //   this.setState({
  //     restaurantPrice: {
  //       ...this.state.restaurantPrice,
  //       PriceDetails: priceDetails,
  //     },
  //   });
  // };
  addPriceToList = () => {
    const { restaurantPriceList, restaurantPrice } = this.state;
    let isValid = false;
    let validationPrice = false;
    let validationPriceDetail = false;
    restaurantPriceList.map(data => {
      let StartDate = moment(data.StartDate).format('MM/DD/YYYY');
      let EndDate = moment(data.EndDate).format('MM/DD/YYYY');
      let Status = data.Status === 'New' ? true : false;
      if (
        StartDate === restaurantPrice.StartDate &&
        EndDate === restaurantPrice.EndDate &&
        data.ServicePriceType === restaurantPrice.ServicePriceType
      ) {
        Status ? (isValid = false) : (isValid = true);
      }
      return data;
    });
    if (restaurantPrice.ServicePriceType === 0) {
      validationPrice = true;
    }
    if (restaurantPrice.PriceDetails.length === 0) {
      validationPrice = true;
      validationPriceDetail = true;
    }
    if (!validationPrice) {
      if (!isValid) {
        if (this.state.editIndex >= 0) {
          let restaurantPriceindex = this.state.restaurantPrice;
          restaurantPriceList[this.state.editIndex] = restaurantPriceindex;
        } else {
          restaurantPriceList.push(this.state.restaurantPrice);
        }
        this.setState({
          restaurantPriceList: restaurantPriceList,
          openModalPrice: false,
        });
      } else {
        this.openAlert('Information', 'the data you entered already exists ');
      }
    } else {
      validationPriceDetail
        ? this.openAlert('Information', "Price list can't be empty")
        : this.openAlert('Information', 'Please Fill Service Price Type ');
    }
  };

  closeModalRestaurantPrice = () => {
    this.setState({ openModalPrice: false });
  };

  // saveRestaurantPrice = () => {
  //   let list = this.state.restaurantPriceList;
  //   list.map(price => {
  //     if (price) {
  //       price.PriceDetails.map(details => {
  //         let data = {
  //           Id: details.Id,
  //           CutOffDay: price.CuttOfDay,
  //           Cost: details.Cost,
  //           StartDate: price.StartDate,
  //           EndDate: price.EndDate,
  //           UomId: 'PCS',
  //           TaxTypeId: 0,
  //           Currency: price.Currency,
  //           Rank: price.RankType,
  //           ServiceItemId: price.ServiceItemId,
  //           ServicePriceType: price.ServicePriceType,
  //           MinimumPax: details.MinimumPax,
  //           Description: price.Description,
  //         };
  //         return details.Id
  //           ? console.log('Restaurant', data.Id, data) //this.props.putServicePriceAction('Restaurant', data.Id, data)
  //           : this.props.postServicePriceAction('Restaurant', data);
  //       });
  //     }
  //     return price;
  //   });
  // };
  filterSupplierPriceDetails = params => {
    const details = this.props.supplierPriceList;
    let data1 = params;
    params.map((data, i) => {
      details.map(price => {
        if (
          data.Type === price.Type &&
          data.Rank === price.Rank &&
          data.StartDate === price.StartDate &&
          data.EndDate === price.EndDate
        ) {
          let restaurantPrice = params[i];
          let priceDetails = restaurantPrice.PriceDetails;
          let newpriceDetails = {
            Currency: price.Currency,
            Cost: price.Cost,
            MinPax: price.CalculationBase.Code,
            Id: price.Id,
          };
          priceDetails.push(newpriceDetails);
        }
        return data1;
      });
      return data1;
    });
    this.setState({ restaurantPriceList: data1 });
  };
  filterSupplierPrice = () => {
    const supplierPrice = this.props.supplierPriceList;
    let newSupplier = [];
    supplierPrice.filter(item => {
      var i = newSupplier.findIndex(
        x =>
          x.ServicePriceType.split('_').join(' ') ===
            item.ServiceType.split('_').join(' ') &&
          x.StartDate === item.StartDate &&
          x.EndDate === item.EndDate &&
          x.Rank === item.Rank
      );
      if (i <= -1) {
        newSupplier.push(setSupplierPriceForFilter(item));
      }
      return newSupplier;
    });
    return newSupplier;
  };
  editPrice = index => {
    let restPrice = this.state.restaurantPriceList[index];
    this.setState({
      restaurantPrice: restPrice,
      editIndex: index,
      openModalPrice: true,
    });
  };
  handleClose = () => {
    this.setState({
      openModalAlert: false,
    });
  };
  handleCloseImage = () => {
    this.setState({
      openModalImage: false,
    });
  };
  saveRestaurant = (e, { name }) => {
    if (name === 'Edit') {
      this.setState({
        titleHeader: 'Edit Restaurant Item ',
        contentButton: 'Save',
        disablePage: false,
      });
    } else {
      const err = this.validate();
      const data = this.state.restaurantItem;
      const { ServiceItemId } = data;
      // if (data.onChange) {
      if (!err) {
        this.setState({ loadingRestaurantItem: true });

        if (ServiceItemId) {
          this.props.putRestaurantItemAction(ServiceItemId, data).then(() => {
            this.saveRestaurantItemPrice(ServiceItemId);
          });
        } else {
          this.props.postRestaurantItemAction(data).then(res => {
            this.saveRestaurantItemPrice(res.value.data.ServiceItemId);
          });
        }
      } else {
        ServiceItemId && this.saveRestaurantItemPrice(ServiceItemId);
      }

      // this.saveRestaurantItemPrice();
    }
    if (this.state.deletedPrices.length > 0) {
      this.state.deletedPrices.map(values =>
        this.props.deleteServicePriceAction(values)
      );
    }
  };

  saveRestaurantItemPrice = serviceItemId => {
    let { restaurantPriceList } = this.state;
    restaurantPriceList.map(price => {
      if (price.onChange) {
        if (price) {
          this.setState({ loadingRestaurantItem: true });
          price.PriceDetails.map(details => {
            let data = setRestaurantPrice(details, price, serviceItemId);
            return details.Id
              ? this.props.putServicePriceAction('Restaurant', data.Id, data)
              : this.props.postServicePriceAction('Restaurant', data);
          });
        }
        return null;
      }
      return price;
    });
  };

  validate = () => {
    let isError = false;
    const errors = handleValidation(this.state.restaurantItem, fieldToValidate);
    isError = !_.isEmpty(errors);
    this.setState({
      errors: {
        ...errors,
      },
    });
    return isError;
  };

  modalImageRestaurantItem = () => {
    this.setState({
      openModalImage: true,
    });
  };
  changeImage = e => {
    let imageObject = this.state.restaurantItem;
    e.map(base => {
      let image = {
        ImageId: 0,
        ImageContent: base.base64.split(',')[1],
        ImagePreview: base.base64,
        ImageName: base.file.name,
        IsPrimaryImage: false,
        onChange: true,
      };
      return (
        imageObject.RestaurantImages.push(image),
        (imageObject['onChange'] = true)
      );
    });
    this.setState({
      restaurantItem: imageObject,
    });
  };
  addImage = () => {
    let objectImage = this.state.restaurantItem;
    let image = this.state.image;
    objectImage.RestaurantImages.push(image);
    let listStepImage = this.state.listStepImage;
    listStepImage.map((step, i) => {
      step['Active'] = i === 0 ? true : false;
      return listStepImage;
    });
    // let onChangeItem = this.state.restaurantItem.onChange;
    this.setState({
      restaurantItem: objectImage,
      openModalImage: false,
      listStepImage: listStepImage,
      indexActive: 0,
    });
  };
  handleChangePrimaryImage = (e, { value }) => {
    let listImage = this.state.restaurantItem;
    let newList = [];
    listImage.RestaurantImages.map((img, i) => {
      img['IsPrimaryImage'] = i === value ? true : false;
      return newList.push(img);
    });
    this.setState({
      restaurantItem: {
        ...this.state.restaurantItem,
        RestaurantImages: newList,
      },
    });
  };
  handleNext = () => {
    let indexActive = this.state.indexActive;
    indexActive = indexActive + 1;
    let listStepImage = this.state.listStepImage;
    listStepImage.map((step, i) => {
      step['Active'] = i === indexActive ? true : false;
      return listStepImage;
    });
    if (indexActive === 2) {
      let listImage = this.state.restaurantItem;
      listImage.RestaurantImages.map(img => {
        return img.IsPrimaryImage && this.setState({ image: img });
      });
    }
    if (indexActive === 3) {
      return this.setState({ indexActive: 0, openModalImage: false });
    }
    this.setState({ listStepImage: listStepImage, indexActive: indexActive });
  };
  handleBackStep = () => {
    let indexActive = this.state.indexActive;
    indexActive = indexActive - 1;
    let listStepImage = this.state.listStepImage;
    listStepImage.map((step, i) => {
      step['Active'] = i === indexActive ? true : false;
      return listStepImage;
    });
    this.setState({ listStepImage: listStepImage, indexActive: indexActive });
  };
  handleChangePriceDetails = (e, { value, name }) => {
    let restaurantPrice = this.state.restaurantPrice;
    let priceDetails = restaurantPrice.PriceDetails;
    let MinPax = 0;
    let i = name.split(',')[1];
    let newName = name.split(',')[0];
    if (newName === 'MinPax') {
      MinPax = value;
    }
    priceDetails[i] = { ...priceDetails[i], [newName]: value, onChange: true };

    this.setState({
      restaurantPrice: {
        ...this.state.restaurantPrice,
        MinPax: MinPax,
        PriceDetails: priceDetails,
        onChange: true,
      },
    });
  };

  handleChangeCostPriceDetails = (value, i, name) => {
    let restaurantPrice = this.state.restaurantPrice;
    let priceDetails = restaurantPrice.PriceDetails;
    let Cost = 0;
    if (name === 'Cost') {
      Cost = value;
    }
    priceDetails[i] = { ...priceDetails[i], [name]: value };
    this.setState({
      restaurantPrice: {
        ...this.state.restaurantPrice,
        Cost: Cost,
        PriceDetails: priceDetails,
        onChange: true,
      },
    });
  };

  // deleteImage = e => {
  //   this.setState({
  //     restaurantItem: {
  //       ...this.state.restaurantItem,
  //       RestaurantImages: this.state.restaurantItem.RestaurantImages.filter(
  //         item => item.ImageName || item.Name !== e.target.id
  //       ),
  //     },
  //   });
  // };

  deleteConfirmation = (e, { name, id }) => {
    this.setState({
      imageId: name,
      openConfirm: true,
      messageConfirm: 'Are you sure to delete ' + id + '?',
      ImageName: id,
      indexImage: name,
    });
  };

  buttonBack = () => {
    let idProfile = this.props.match.params.ProfileId;
    // eslint-disable-next-line
    this.props.history.push(`/Restaurant-Profile/Detail/${idProfile}`);
  };
  openDetailItem = (e, { name }) => {
    const { RestaurantImages } = this.state.restaurantItem;
    let listStepImage = this.state.listStepImage;
    listStepImage.map((step, i) => {
      step['Active'] = i === 0 ? true : false;
      return listStepImage;
    });
    let image = RestaurantImages[name];
    this.setState({
      image: image,
      indexActive: 0,
      listStepImage: listStepImage,
      openModalImage: true,
      // imageUrl: image.ImageUrl //ImageUrl
      //   ? image.ImageUrl //ImageUrl
      //   : URL.createObjectURL(e.target.files[0]),
    });
  };

  openModalPriceDetailFunc = () => {
    let data = {
      Currency: '',
      Cost: 0,
      MinPax: 0,
      Id: 0,
    };
    this.setState({
      openModalPriceDetail: true,
      restaurantPriceTemporary: data,
      editPrice: false,
    });
  };

  handlCloseModalPriceDetail = () => {
    this.setState({ openModalPriceDetail: false });
  };
  onChangeModalPriceDetails = async (e, { value, name }) => {
    const { restaurantPriceTemporary } = this.state;
    let restaurantPrice = { ...restaurantPriceTemporary };
    restaurantPrice[name] = value;
    restaurantPrice['onChange'] = true;
    await this.setState({
      restaurantPriceTemporary: restaurantPrice,
    });
    this.validatePrice();
  };

  handleChangePriceDate = (value, name) => {
    const { restaurantPriceTemporary } = this.state;
    let restaurantPrice = restaurantPriceTemporary;
    restaurantPrice[name] = value;
    restaurantPrice['onChange'] = true;
    this.setState({
      restaurantPriceTemporary: restaurantPrice,
    });
  };

  validatePrice = () => {
    let isError = false;
    const errors = handleValidation(
      this.state.restaurantPriceTemporary,
      fieldToValidatePrice
    );
    isError = !_.isEmpty(errors);
    this.setState({
      errorsPrice: {
        ...errors,
      },
    });
    return isError;
  };

  savePriceDetail = () => {
    const {
      indexEditPrice,
      editPrice,
      restaurantPriceTemporary,
      restaurantPrice,
    } = this.state;
    let temporaryPriceDetail = restaurantPriceTemporary;
    let priceDetails = restaurantPrice.PriceDetails;
    const err = this.validatePrice();
    let valid = false;
    let validPrice = false;
    let validCostEmpty = false;
    if (!err) {
      priceDetails.map(data => {
        let MinPax = data.MinPax.toString();
        if (MinPax === temporaryPriceDetail.MinPax) {
          valid = true;
        }
        return data;
      });
      if (temporaryPriceDetail.Cost === 0) {
        validPrice = true;
      } else if (temporaryPriceDetail.MinPax === 0) {
        validPrice = true;
      } else if (temporaryPriceDetail.Cost === '') {
        validPrice = true;
        validCostEmpty = true;
      }

      if (!valid || editPrice) {
        if (validPrice) {
          this.openAlert(
            'Information',
            validCostEmpty
              ? 'the price you enter cannot be empty '
              : 'the price you enter cannot be 0 '
          );
        } else {
          if (editPrice) {
            priceDetails[indexEditPrice] = temporaryPriceDetail;
          } else {
            priceDetails.push(temporaryPriceDetail);
          }
          this.setState({
            restaurantPrice: {
              ...restaurantPrice,
              PriceDetails: priceDetails,
              Cost: restaurantPriceTemporary.Cost,
              Currency: restaurantPriceTemporary.Currency,
              onChange: true,
            },
            openModalPriceDetail: false,
          });
        }
      } else {
        this.openAlert(
          'Information',
          'The price with the service type and date you fill in already exists'
        );
      }
    }
  };

  handleEditPriceDetail = (e, { id, value }) => {
    let unitDetails = {};
    const { restaurantPrice } = this.state;
    // restaurantPrice.PriceDetails.map(data => data.Id === value)
    if (value !== 0) {
      unitDetails = restaurantPrice.PriceDetails.find(
        data => data.Id === value
      );
    } else {
      unitDetails = restaurantPrice.PriceDetails[id];
    }
    this.setState({
      restaurantPriceTemporary: unitDetails,
      openModalPriceDetail: true,
      editPrice: true,
      indexEditPrice: id,
    });
  };

  handleCloseConfirm = () => {
    this.setState({
      openConfirm: false,
      openConfirmPrice: false,
    });
  };

  deleteImage = value => {
    const { restaurantItem } = this.state;
    restaurantItem.RestaurantImages = restaurantItem.RestaurantImages.filter(
      (item, i) => i !== value
    );
    restaurantItem['onChange'] = true;
    // this.setState({
    //   openConfirm: false,
    // });
    this.setState({
      restaurantItem: restaurantItem,
    });
  };
  confirmDeletePriceInList = index => {
    this.setState({
      openConfirmPrice: true,
      messageConfirm: 'Are you sure to delete this price ?',
      indexDeletePrice: index,
      deletePriceList: true,
    });
  };
  handleDeletePrices = () => {
    let { deletedPrices, restaurantPriceList, indexDeletePrice } = this.state;
    let list = restaurantPriceList;
    let id = list[indexDeletePrice].Id ? list[indexDeletePrice].Id : 0;
    if (id) {
      deletedPrices.push(id);
    }
    list = list.filter((item, i) => i !== indexDeletePrice);
    this.setState({
      openConfirmPrice: false,
      restaurantPriceList: list,
      deletedPrices: deletedPrices,
      deletePriceList: false,
    });
  };
  openAlert = (header, content) => {
    this.setState({
      contentModal: content,
      headerModal: header,
      openModalAlert: true,
    });
  };
  render() {
    let {
      // loadingRestaurantItemPrice,
      // loadingRestaurantItem,
      failedRestaurantItemPrice,
      failedRestaurantItem,
      openModalAlert,
      // searchInput,
      openModalPriceDetail,
    } = this.state;
    //let loading = loadingRestaurantItemPrice || loadingRestaurantItem;
    let loading =
      this.props.loadingRestaurant || this.props.loadingSupplierPrice;
    // let filterData = FilterData('Name', searchInput);
    let openModal =
      failedRestaurantItemPrice || failedRestaurantItem || openModalAlert;
    return (
      <div>
        <Dimmer.Dimmable blurring dimmed={loading}>
          <Loader loading={loading} />
          <RestaurantItemPrice
            restaurantPrice={this.state.restaurantPriceList}
            addPrice={this.addPrice}
            deletePrice={this.confirmDeletePriceInList}
            handleChangePrice={this.handleChangePrice}
            handleChangePrices={this.handleChangePrices}
            currencies={this.props.currencies}
            servicePriceType={this.props.servicePriceType}
            getRestaurantType={this.props.getRestaurantType}
            getRestaurantClass={this.props.getRestaurantClass}
            getRestaurantCategory={this.props.getRestaurantCategory}
            restaurantItem={this.state.restaurantItem}
            saveRestaurant={this.saveRestaurant}
            title={this.state.titleHeader}
            handleChange={this.handleChangeItem}
            handleChangeItem={this.handleChangeItem}
            menuType={this.props.menuType}
            menuClasses={this.props.menuClasses}
            modalImageRestaurantItem={this.modalImageRestaurantItem}
            stateRestaurantItem={this.state.restaurantItem}
            editPrice={this.editPrice}
            disablePage={this.state.disablePage}
            contentButton={this.state.contentButton}
            deleteStateItem={this.deleteImage}
            // deleteStateItem={this.deleteConfirmation}
            errors={this.state.errors}
            backPage={this.buttonBack}
            openDetailItem={this.openDetailItem}
            // downloadTemplate={this.downloadTemplate}
            // uploadData={this.uploadData}
            // exportData={this.exportData}
            handleChangePrimaryImage={this.handleChangePrimaryImage}
            searchInput={this.searchInput}
          />
          <ModalAlert
            openModal={openModal}
            handleClose={this.handleClose}
            header={this.state.headerModal}
            content={this.state.contentModal}
            nameButton="OK"
          />
          <ModalImage
            openModal={this.state.openModalImage}
            imagePreview={this.state.imageUrl}
            handleChange={this.changeImage}
            addImage={this.addImage}
            handleClose={this.handleCloseImage}
            header="Upload Image"
            image={
              this.state.restaurantItem.RestaurantImages
                ? this.state.restaurantItem.RestaurantImages
                : []
            }
            listStepImage={this.state.listStepImage}
            handleNext={this.handleNext}
            handleBackStep={this.handleBackStep}
            indexActive={this.state.indexActive}
            handleChangePrimaryImage={this.handleChangePrimaryImage}
            imageRatio={this.state.image}
          />
          <ModalRestaurantPrice
            openModalPrice={this.state.openModalPrice}
            handleClose={this.closeModalRestaurantPrice}
            restaurantPrice={this.state.restaurantPrice}
            restaurantItem={this.state.restaurantItem}
            servicePriceType={this.props.servicePriceType}
            handleChangePrice={this.handleChangePrice}
            placeholderType="Restaurant Type"
            handleChangePrices={this.handleChangePrices}
            addPrice={this.addPrice}
            // addPriceDetails={this.addPriceDetails}
            addPriceDetails={this.openModalPriceDetailFunc}
            handleChangePriceDetails={this.handleChangePriceDetails}
            deletePrice={this.confirmationDeletePrice}
            handleChangeCostPriceDetails={this.handleChangeCostPriceDetails}
            addPriceToList={this.addPriceToList}
            currencies={this.props.currencies}
            errors={this.state.errors}
            handleEdit={this.handleEditPriceDetail}
          />
          <ModalRestaurantPriceDetail
            openModalPrice={openModalPriceDetail}
            handleClose={this.handlCloseModalPriceDetail}
            headerItem={this.state.restaurantItem.Name}
            currenciesList={this.props.currencies}
            data={this.state.restaurantPriceTemporary}
            handleChangePrice={this.onChangeModalPriceDetails}
            handleChangePriceDate={this.handleChangePriceDate}
            savePriceDetail={this.savePriceDetail}
            errors={this.state.errorsPrice}
          />
          <ModalConfirm
            openModal={this.state.openConfirm}
            size="mini"
            close={this.handleCloseConfirm}
            confirm={this.deleteImage}
            message={this.state.messageConfirm}
          />
          <ModalConfirm
            openModal={this.state.openConfirmPrice}
            size="mini"
            close={this.handleCloseConfirm}
            confirm={this.deletePrice}
            message={this.state.messageConfirm}
          />
        </Dimmer.Dimmable>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    currencies: state.currenciesStore.currencies.map((cur, i) => {
      return { key: i, text: cur.Id, value: cur.Id };
    }),
    servicePriceType: state.SupplierPriceStore.servicePriceType.map(type => {
      return { text: type.replace('_', ' '), value: type, key: type.Id };
    }),
    getRestaurantClass: state.RestaurantStore.getRestaurantClass.map(classe => {
      return { text: classe.Name, value: classe.Id, key: classe.Id };
    }),
    getRestaurantType: state.RestaurantStore.getRestaurantType.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    // getRestaurantCategory: state.RestaurantStore.getRestaurantCategory.map(
    //   (category, i) => {
    //     return {
    //       text: category.replace('_', ' '),
    //       value: category,
    //       key: i,
    //     }),
    getRestaurantCategory: state.RestaurantStore.getRestaurantCategory.map(
      (category, i) => {
        return {
          text: category.replace('_', ' '),
          value: category,
          key: i,
        };
      }
    ),
    menuType: state.RestaurantStore.menuType.map((menu, i) => {
      return {
        text: menu.Name,
        value: menu.Id,
        key: i,
      };
    }),
    menuClasses: state.RestaurantStore.menuClasses.map((menu, i) => {
      return {
        text: menu,
        value: menu,
        key: i,
      };
    }),
    restaurantItem: state.RestaurantStore.restaurantItem,
    getRestaurantItemStatus: state.RestaurantStore.getRestaurantItemStatus,
    getSupplierPriceStatus: state.SupplierPriceStore.getSupplierPriceStatus,
    supplierPriceList: state.SupplierPriceStore.supplierPriceList,
    postRestaurantStatus: state.RestaurantStore.postRestaurantStatus,
    putRestaurantItemStatus: state.RestaurantStore.putRestaurantItemStatus,
    postRestaurantItemStatus: state.RestaurantStore.postRestaurantItemStatus,
    dataRestaurantItem: state.RestaurantStore.dataRestaurantItem,
    postSupplierPriceStatus: state.SupplierPriceStore.postSupplierPriceStatus,
    putSupplierPriceStatus: state.SupplierPriceStore.putSupplierPriceStatus,
    deleteSupplierPriceStatus:
      state.SupplierPriceStore.deleteSupplierPriceStatus,
    errorMessage: state.SupplierPriceStore.errors.global,
    // getRestaurantPriceExport: state.RestaurantStore.getRestaurantPriceExport,
    // getRestaurantPriceExportStatus:
    //   state.RestaurantStore.getRestaurantPriceExportStatus,
    // getRestaurantPriceTemplateStatus:
    //   state.RestaurantStore.getRestaurantPriceTemplateStatus,
    // getRestaurantPriceTemplate:
    //   state.RestaurantStore.getRestaurantPriceTemplate,
    loadingRestaurant: state.RestaurantStore.loading,
    loadingSupplierPrice: state.SupplierPriceStore.loading,
  };
}
RestaurantItem.propTypes = {
  getCurrenciesAction: PropTypes.func,
  getServicePriceAction: PropTypes.func,
  postServicePriceAction: PropTypes.func,
  putServicePriceAction: PropTypes.func,
  deleteServicePriceAction: PropTypes.func,
  getServicePriceTypeAction: PropTypes.func,
  resetServicePriceAction: PropTypes.func,
  currencies: PropTypes.array,
  servicePriceType: PropTypes.array,
  getRestaurantClassAction: PropTypes.func,
  getRestaurantTypeAction: PropTypes.func,
  getRestaurantClass: PropTypes.array,
  getRestaurantType: PropTypes.array,
  getRestaurantCategory: PropTypes.array,
  getRestaurantCategoryAction: PropTypes.func,
  getRestaurantItemByIdAction: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
  restaurantItem: PropTypes.object,
  getRestaurantItemStatus: PropTypes.string,
  resetRestaurantAction: PropTypes.func,
  getSupplierPriceStatus: PropTypes.string,
  supplierPriceList: PropTypes.array,
  postRestaurantItemAction: PropTypes.func,
  putRestaurantItemAction: PropTypes.func,
  postRestaurantStatus: PropTypes.string,
  postRestaurantItemStatus: PropTypes.string,
  putRestaurantItemStatus: PropTypes.string,
  dataRestaurantItem: PropTypes.object,
  getMenuTypeAction: PropTypes.func,
  menuType: PropTypes.array,
  getMenuClassesAction: PropTypes.func,
  menuClasses: PropTypes.array,
  handleClickEdit: PropTypes.func,
  postSupplierPriceStatus: PropTypes.string,
  putSupplierPriceStatus: PropTypes.string,
  errorMessage: PropTypes.string,
  deleteSupplierPriceStatus: PropTypes.string,
  loadingRestaurant: PropTypes.bool,
  loadingSupplierPrice: PropTypes.bool,
  // getTemplateRestaurantPriceAction: PropTypes.func,
  // getExportRestaurantPriceAction: PropTypes.func,
  // getRestaurantPriceExportStatus: PropTypes.string,
  // getRestaurantPriceExport: PropTypes.array,
  // getRestaurantPriceTemplate: PropTypes.array,
  // getRestaurantPriceTemplateStatus: PropTypes.string,
};
export default connect(
  mapStateToProps,
  {
    getCurrenciesAction,
    getServicePriceAction,
    postServicePriceAction,
    putServicePriceAction,
    deleteServicePriceAction,
    getServicePriceTypeAction,
    resetServicePriceAction,
    getRestaurantClassAction,
    getRestaurantTypeAction,
    getRestaurantCategoryAction,
    getRestaurantItemByIdAction,
    resetRestaurantAction,
    postRestaurantItemAction,
    putRestaurantItemAction,
    getMenuTypeAction,
    getMenuClassesAction,
    // getTemplateRestaurantPriceAction,
    // getExportRestaurantPriceAction,
  }
)(RestaurantItem);
