import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon, Button } from 'semantic-ui-react';
import InputAmount from '../../../../../components/input/inputAmount';
import styles from './styles';
const TourGuideCard = props => {
  const {
    item,
    handleIncrease,
    handleDecrease,
    handleChangeGuideAmount,
    cityId,
    handleDeleteGuide,
  } = props;
  return (
    <Grid.Row style={styles.paddingTopZero}>
      <Grid.Column width="1" verticalAlign="middle">
        <Icon className="speak" />
      </Grid.Column>
      <Grid.Column width="8" verticalAlign="middle">
        {item.language ? item.language.text : ''}
      </Grid.Column>
      <Grid.Column width="5" verticalAlign="middle">
        <InputAmount
          size="mini"
          name={item.language.value}
          objectname={cityId}
          value={item.guideAmount}
          handleIncrease={handleIncrease}
          handleDecrease={handleDecrease}
          handleOnChange={handleChangeGuideAmount}
        />
      </Grid.Column>
      <Grid.Column width="2" verticalAlign="middle" textAlign="right">
        <Button
          icon
          basic
          style={{ boxShadow: 'none' }}
          onClick={handleDeleteGuide}
          name={item.language.value}
          objectname={cityId}
        >
          {' '}
          <Icon name="times" color="red" />
        </Button>
      </Grid.Column>
    </Grid.Row>
  );
};
TourGuideCard.propTypes = {
  item: PropTypes.object,
  handleIncrease: PropTypes.func,
  handleDecrease: PropTypes.func,
  handleChangeGuideAmount: PropTypes.func,
  cityId: PropTypes.string,
  handleDeleteGuide: PropTypes.func,
};
export default TourGuideCard;
