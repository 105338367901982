import axios from 'axios';
import { API_ROOT } from '../config/envUrl';

const handleDownloadFile = (dataurl, filename) => {
  var link = document.createElement('a');
  link.download = filename;
  link.href = dataurl;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const downloadImageBlob = async (apiUrl, id) => {
  let imageBlob = (
    await axios.get(`${API_ROOT}${apiUrl}`, {
      responseType: 'blob',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('tokenadmin'),
        'Content-Type': 'application/json',
      },
    })
  ).data;
  let image = {
    data: URL.createObjectURL(imageBlob),
    url:
      API_ROOT.replace(/(\r\n|\n|\r)/gm, '') +
      apiUrl.replace(/(\r\n|\n|\r)/gm, ''),
    id: id,
  };
  return image;
};

export { handleDownloadFile, downloadImageBlob };
