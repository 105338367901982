import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Segment, Form } from 'semantic-ui-react';
import { connect } from 'react-redux';
import moment from 'moment';
import HeaderTitle from '../../shared/components/common/headerSegment/HeaderTitle';
import ButtonWithIcon from '../../shared/components/common/button/ButtonWithIcon';
import { resetUserInfoAction } from '../../../actions/userAction';
import { getSalesReportAction } from '../homeAction';
import LoaderModal from '../../../components/modal/loaderModal';
const styleButtonRed = {
  color: '#ff3d3d',
};
class SalesReport extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      listOfMonths: moment.months(),
      currentYear: Number(moment(new Date()).format('YYYY')),
      listOfYears: [],
      salesReportObject: {
        from: '',
        to: '',
        year: '',
        type: '',
      },
      error: {
        from: { error: false, message: '' },
        to: { error: false, message: '' },
        year: { error: false, message: '' },
        type: { error: false, message: '' },
      },
    };
  }
  handleChange = e => {
    const { salesReportObject, listOfMonths } = this.state;
    if (e.target.name === 'from') {
      let nameMonth = listOfMonths[e.target.value - 1];
      this.setState({
        fromName: nameMonth,
      });
    }
    if (e.target.name === 'to') {
      let nameMonth = listOfMonths[e.target.value - 1];
      this.setState({
        toName: nameMonth,
      });
    }
    salesReportObject[e.target.name] = e.target.value;
    this.setState({
      salesReportObject: salesReportObject,
    });
    this.validate();
  };

  validate = () => {
    let isError = false;
    const errors = {
      from: { error: false, message: '' },
      to: { error: false, message: '' },
      year: { error: false, message: '' },
      type: { error: false, message: '' },
    };
    const { from, to, year, type } = this.state.salesReportObject;
    if (from.length < 1) {
      isError = true;
      errors.from.error = true;
      errors.from.message = 'Please fill month';
    }
    if (to.length < 1) {
      isError = true;
      errors.to.error = true;
      errors.to.message = 'Please fill month';
    }
    if (year.length < 1) {
      isError = true;
      errors.year.error = true;
      errors.year.message = 'Please fill year';
    }
    if (type.length < 1) {
      isError = true;
      errors.type.error = true;
      errors.type.message = 'Please fill report type';
    }
    this.setState({
      error: {
        ...errors,
      },
    });
    return isError;
  };

  static propTypes = {
    getSalesReport: PropTypes.string,
    getSalesReportStatus: PropTypes.string,
    resetUserInfoAction: PropTypes.func,
    getSalesReportAction: PropTypes.func,
    loading: PropTypes.bool,
  };
  componentDidUpdate() {
    const {
      getSalesReport,
      getSalesReportStatus,
      resetUserInfoAction,
    } = this.props;

    const { year, type } = this.state.salesReportObject;
    const { toName, fromName } = this.state;
    if (getSalesReportStatus === 'success') {
      var exportAccomodation = getSalesReport;
      var elementAccomodation = document.createElement('a');
      var fileAccomdation = new Blob([exportAccomodation], {
        type: 'text/plain',
      });
      elementAccomodation.href = URL.createObjectURL(fileAccomdation);
      elementAccomodation.download = `Sales Report from ${fromName} to ${toName} ${year}.${type}`;
      elementAccomodation.click();
      resetUserInfoAction();
    } else if (getSalesReportStatus === 'failed') {
      resetUserInfoAction();
    }
  }
  downloadReport = () => {
    const { from, to, year } = this.state.salesReportObject;
    const err = this.validate();
    if (!err) {
      this.props.getSalesReportAction(from, to, year);
    }
  };

  render() {
    const { listOfMonths, currentYear, error } = this.state;
    let min = currentYear - 5;
    let listOfYears = Array.apply(null, { length: currentYear + 1 - min }).map(
      function(_, i) {
        return i + min;
      }
    );

    return (
      <div>
        <Grid style={{ marginTop: '30px' }}>
          <Grid.Row stretched>
            <Grid.Column width={15}>
              <Segment>
                <HeaderTitle title="Sales Report" />
                <LoaderModal loading={this.props.loading} />
                <h5>Report Period</h5>
                <h5 style={{ margin: '-2.3em 0em 1px 36.2em' }}>
                  Select report type you want download
                </h5>
                <Grid relaxed columns={5}>
                  <Grid.Column style={{ width: '12em' }}>
                    <Form>
                      <Form.Field
                        control="select"
                        onChange={this.handleChange}
                        name="from"
                        placeholder="From"
                        error={error.from.error}
                      >
                        <option
                          disabled
                          selected
                          value
                          style={{ display: 'none' }}
                        >
                          From
                        </option>
                        {listOfMonths.map((value, index) => {
                          return (
                            <option value={index + 1} key={index}>
                              {value}
                            </option>
                          );
                        })}
                      </Form.Field>
                    </Form>
                    <label style={styleButtonRed}>{error.from.message}</label>
                  </Grid.Column>
                  <Grid.Column style={{ width: '12em' }}>
                    <Form>
                      <Form.Field
                        control="select"
                        onChange={this.handleChange}
                        name="to"
                        placeholder="To"
                        error={error.to.error}
                      >
                        <option
                          disabled
                          selected
                          value
                          style={{ display: 'none' }}
                        >
                          To
                        </option>
                        {listOfMonths.map((value, index) => {
                          return (
                            <option value={index + 1} key={index}>
                              {value}
                            </option>
                          );
                        })}
                      </Form.Field>
                    </Form>
                    <label style={styleButtonRed}>{error.to.message}</label>
                  </Grid.Column>
                  <Grid.Column style={{ width: '12em' }}>
                    <Form>
                      <Form.Field
                        control="select"
                        onChange={this.handleChange}
                        name="year"
                        placeholder="Year"
                        error={error.year.error}
                      >
                        <option
                          disabled
                          selected
                          value
                          style={{ display: 'none' }}
                        >
                          Year
                        </option>
                        {listOfYears.map((value, index) => {
                          return (
                            <option value={value} key={index}>
                              {value}
                            </option>
                          );
                        })}
                      </Form.Field>
                    </Form>
                    <label style={styleButtonRed}>{error.year.message}</label>
                  </Grid.Column>
                  <Grid.Column style={{ width: '20em' }}>
                    <Form>
                      <Form.Field
                        control="select"
                        onChange={this.handleChange}
                        name="type"
                        placeholder="Select report type"
                        error={error.type.error}
                      >
                        <option
                          disabled
                          selected
                          value
                          style={{ display: 'none' }}
                        >
                          Report Type
                        </option>
                        <option value="xls">Excel</option>
                        <option value="txt">Plain text</option>
                      </Form.Field>
                    </Form>
                    <label style={styleButtonRed}>{error.type.message}</label>
                  </Grid.Column>
                  <Grid.Column>
                    <ButtonWithIcon
                      text="Download Sales Report"
                      iconName="download"
                      handleOnClick={this.downloadReport}
                      primary={true}
                      style={{
                        width: '15em',
                        // marginTop: '5px',
                        marginLeft: '-1em',
                      }}
                    />
                  </Grid.Column>
                </Grid>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    getSalesReport: state.UserStore.getSalesReport,
    getSalesReportStatus: state.UserStore.getSalesReportStatus,
    loading: state.UserStore.loading,
  };
}
export default connect(
  mapStateToProps,
  {
    getSalesReportAction,
    resetUserInfoAction,
  }
)(SalesReport);
