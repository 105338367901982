import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Image, Form, Grid } from 'semantic-ui-react';
import styles from '../../Styles';
import Loader from '../../../../components/modal/loaderModal';
import InputWithLabel from '../../../../components/input/inputWithLabel';
// import InputBase64 from '../input/inputBase64';
import ButtonClose from '../../../../components/button/buttonCloseModal';
import UploadButton from '../../../../components/common/uploadImageButton';

const ModalCity = props => {
  return (
    <Modal
      dimmer="blurring"
      // closeIcon
      // inverted
      open={props.open}
      onClose={props.handleClose}
      size="small"
      style={styles.modalWidth}
    >
      <Modal.Header>
        {props.header} <ButtonClose onClose={props.handleClose} />
      </Modal.Header>
      <Modal.Content>
        <Loader loading={props.loading} />
        <Grid columns={2} padded="vertically">
          <Grid.Column>
            <Form>
              <InputWithLabel
                inline={false}
                label="City Name"
                type="text"
                required
                name="Name"
                value={props.contentField.Name}
                placeholder="Name"
                handleChange={props.handleChange}
                validate={props.errorInformation}
              />
            </Form>
            <Form>
              <InputWithLabel
                inline={false}
                label="UTC"
                type="number"
                required
                name="UTC"
                value={props.contentField.UTC}
                placeholder="UTC"
                handleChange={props.handleChange}
                validate={props.errorInformation}
              />
            </Form>
            <Form>
              <InputWithLabel
                inline={false}
                label="Country"
                type="select"
                required
                name="CountryId"
                value={props.contentField.CountryId}
                placeholder="Country"
                handleChange={props.handleChange}
                options={props.listCountry}
                validate={props.errorInformation}
              />
            </Form>
            <Form>
              <InputWithLabel
                inline={false}
                label="Region"
                type="select"
                required
                name="RegionId"
                value={props.contentField.RegionId}
                placeholder="Region"
                handleChange={props.handleChange}
                options={props.listRegion}
                validate={props.errorInformation}
              />
            </Form>
          </Grid.Column>
          <Grid.Column>
            <Image
              src={
                props.contentField.ImagePreview ||
                require('../../../../assets/Icons/no_image.jpg')
              }
              size="small"
              centered
              rounded
              style={styles.marginImage}
            />

            <UploadButton
              onImageSelect={props.handleChangeImage}
              label="Select Image"
              buttonStyle={styles.marginButton}
            />
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={props.saveCity}>
          Update
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

ModalCity.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  listCountry: PropTypes.array,
  listRegion: PropTypes.array,
  handleChange: PropTypes.func,
  contentField: PropTypes.shape({
    ImageContent: PropTypes.string,
    ImageName: PropTypes.string,
    Name: PropTypes.string,
    RegionId: PropTypes.string,
    UTC: PropTypes.number,
    CountryId: PropTypes.string,
    ImagePreview: PropTypes.string,
  }),
  imagePreview: PropTypes.string,
  saveCity: PropTypes.func,
  errorInformation: PropTypes.shape({
    ImageContentError: PropTypes.string,
    ImageNameError: PropTypes.string,
    Name: PropTypes.object,
    RegionId: PropTypes.object,
    UTC: PropTypes.object,
    CountryId: PropTypes.object,
  }),
  loading: PropTypes.bool,
  handleChangeImage: PropTypes.func,
  onImageSelect: PropTypes.func,
  labelStyles: PropTypes.object,
};
export default ModalCity;
