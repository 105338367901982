import React from 'react';
import { Dimmer } from 'semantic-ui-react';
import { connect } from 'react-redux';
import ModalAlert from '../shared/components/modal/ModalAlert';
import Loader from '../../components/loader';
import SegmentExchangeRate from './component/ExchangeRateList';
import PropTypes from 'prop-types';
import {
  getExchangeRateListAction,
  resetExchangeRateAction,
} from '../../actions/exchangeRateAction';
import ModalExchangeRate from './component/ModalExchangeRate';
import { getCurrenciesAction } from '../../actions/currenciesAction';
import {
  postExchangeRateAction,
  putExchangeRateAction,
  deleteExchangeRateAction,
} from '../../actions/exchangeRateAction';
import moment from 'moment';
import handleValidation from '../../scripts/emptyFieldValidation';
import _ from 'lodash';
import ModalConfirm from '../../components/modal/modalConfirmationTwoButton';
import sortExchangeRate from './services/helper/sortExchangeRate';
import { setExchangeRateData, setPostData } from './services/data/setDataState';
import ModalDateRange from '../shared/components/modal/ModalDateRange';

class ExchangeRate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exchangeRateList: [],
      loadingExchangeRate: true,
      failedExchangeRate: false,
      modalOpen: false,
      openModal: false,
      exchangeRateData: {
        id: 0,
        currencyFrom: '',
        currencyTo: '',
        valueRate: 0,
        startDate: moment(),
      },
      message: '',
      editIndex: 0,
      headerModal: '',
      validate: {},
      sortValue: 0,
      openModalConfirm: false,

      modalDateRange: false,
      searchInput: '',
      activePage: 1,
      dateRange: {
        tourStartDate: null,
        tourEndDate: null,
      },
    };
  }
  static propTypes = {
    getExchangeRateListAction: PropTypes.func,
    statusExchangeRate: PropTypes.string,
    listExchangeRate: PropTypes.array,
    resetExchangeRateAction: PropTypes.func,
    getCurrenciesAction: PropTypes.func,
    currencies: PropTypes.array,
    errors: PropTypes.string,
    postExchangeRateAction: PropTypes.func,
    postExchangeRateStatus: PropTypes.string,
    putExchangeRateAction: PropTypes.func,
    putExchangeRateStatus: PropTypes.string,
    loading: PropTypes.bool,
    deleteExchangeRateAction: PropTypes.func,
  };

  handleOpen = () => this.setState({ modalOpen: true });
  handleCloseAlert = () => this.setState({ modalOpen: false });
  componentDidMount = () => {
    this.props.getExchangeRateListAction();
    this.props.getCurrenciesAction();
  };
  componentDidUpdate = () => {
    if (this.props.statusExchangeRate === 'success') {
      this.setState({
        exchangeRateList: this.props.listExchangeRate,
      });
      this.setState({ loadingExchangeRate: false });
      this.props.resetExchangeRateAction();
    } else if (this.props.statusExchangeRate === 'failed') {
      this.setState({ failedExchangeRate: true });
      this.setState({
        loadingExchangeRate: false,
        message:
          'Please check your internet connection and Refresh Page [Ctrl+R]',
        header: 'Failed',
        modalOpen: true,
      });
      this.props.resetExchangeRateAction();
    } else if (this.props.postExchangeRateStatus === 'success') {
      this.props.getExchangeRateListAction();
      this.props.resetExchangeRateAction();
      this.handleClose();
      this.openModalAlert('Success', 'Exchange Rate is successfully added');
      this.resetExchangeRateState();
    } else if (this.props.postExchangeRateStatus === 'failed') {
      this.props.resetExchangeRateAction();
      this.handleClose();
      this.openModalAlert(
        'Failed',
        'Exchange Rate is failed to be added, please recheck your data and click save button again'
      );
    } else if (this.props.putExchangeRateStatus === 'success') {
      this.props.getExchangeRateListAction();
      this.props.resetExchangeRateAction();
      this.handleClose();
      this.openModalAlert('Success', 'Exchange rate is successfully updated');
      this.resetExchangeRateState();
    } else if (this.props.putExchangeRateStatus === 'failed') {
      this.props.resetExchangeRateAction();
      this.handleClose();
      this.openModalAlert('Failed', 'Exchange rate is failed to be updated');
    }
  };
  resetExchangeRateState = () => {
    let data = {
      id: 0,
      startDate: moment(),
      valueRate: 0,
      currencyFrom: '',
      currencyTo: '',
    };
    this.setState({ exchangeRateData: data });
  };
  openNewRate = () => {
    this.setState({
      editIndex: 0,
      openModal: true,
      headerModal: 'Add New Exchange Rate ',
    });
    this.resetExchangeRateState();
  };
  handleClose = () => {
    this.setState({ openModal: false });
  };
  addRate = () => {
    if (!this.validation()) {
      let { editIndex, exchangeRateData } = this.state;
      let objectRequest = setPostData(editIndex, exchangeRateData);
      if (objectRequest.Id === 0) {
        this.props.postExchangeRateAction(objectRequest);
      } else {
        this.props.putExchangeRateAction(editIndex, objectRequest);
      }
    }
  };
  validation = () => {
    const fields = ['currencyFrom', 'currencyTo', 'valueRate'];
    let errors = handleValidation(
      this.state.exchangeRateData,
      fields,
      this.state.validate
    );
    this.setState({ validate: errors });
    let result = !_.isEmpty(errors);
    return result;
  };
  handleChangeRate = (e, { value, name }) => {
    let data = this.state.exchangeRateData;
    data[name] = value;
    this.setState({ exchangeRateData: data });
  };
  handleChangeDate = value => {
    let data = this.state.exchangeRateData;
    data.startDate = moment(value).format('MM/DD/YYYY');
    this.setState({ exchangeRateData: data });
  };
  editExchangeRate = (e, { name, value }) => {
    let listData = this.props.listExchangeRate;
    let objectData = setExchangeRateData(value, listData[name]);
    this.setState({
      openModal: true,
      exchangeRateData: objectData,
      editIndex: value,
      headerModal: 'Edit Exchange Rate',
    });
  };
  handleChange = (e, { value }) => {
    let list = sortExchangeRate(value, this.state.exchangeRateList);
    this.setState({ exchangeRateList: list });
  };
  deleteExchangeRate = (e, { value }) => {
    this.setState({
      openModalConfirm: true,
      editIndex: value,
      message: 'Are you sure want to delete exchange rate?',
    });
  };
  handleDelete = () => {
    let context = this;
    this.closeModalConfirm();
    this.props
      .deleteExchangeRateAction(this.state.editIndex)
      .then(() => {
        context.openModalAlert(
          'Success',
          'Successfully delete the exchange rate'
        );
        this.props.getExchangeRateListAction();
      })
      .catch(() => {
        context.openModalAlert('Failed', 'Failed delete the exchange rate');
      });
  };
  closeModalConfirm = () => {
    this.setState({
      openModalConfirm: false,
      message: '',
    });
  };

  openModalAlert = (header, message) => {
    this.setState({
      modalOpen: true,
      header: header,
      message: message,
    });
  };

  openModalDateRange = () => {
    this.setState({ modalDateRange: true });
  };

  closeModalDateRange = () => {
    this.setState({ modalDateRange: false });
  };

  actionDateRange = id => {
    this.closeModalDateRange();
    this.setState({ searchInput: id });
    // console.log(id);
  };

  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  handleChangeDateRange = (value, name) => {
    this.setState({ [name]: value, activePage: 1 });
  };
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };

  render() {
    let { loadingExchangeRate, openModalConfirm, message } = this.state;
    let loading = loadingExchangeRate || this.props.loading;
    return (
      <div>
        <Dimmer.Dimmable blurring dimmed={loading}>
          <Loader loading={loading} />
          <SegmentExchangeRate
            data={this.state.exchangeRateList}
            type="exchangeRate"
            filterBy="exchangeRate"
            header={this.state.headerModal}
            openNewRate={this.openNewRate}
            editExchangeRate={this.editExchangeRate}
            sortValue={this.state.sortValue}
            handleSorting={this.handleChange}
            buttonDelete={this.deleteExchangeRate}
            //
            searchInput={this.state.searchInput}
            activePage={this.state.activePage}
            openModalDateRange={this.openModalDateRange}
            handleChange={this.handleChange}
            handleChangeDate={this.handleChangeDateRange}
            pageChange={this.pageChange}
            dateRange={this.state.searchInput}
          />
          <ModalAlert
            openModal={this.state.modalOpen}
            handleClose={this.handleCloseAlert}
            header={this.state.header}
            content={this.state.message}
            nameButton="OK"
          />
          <ModalExchangeRate
            openModal={this.state.openModal}
            handleClose={this.handleClose}
            handleChangeRate={this.handleChangeRate}
            currencies={this.props.currencies}
            objectState={this.state}
            addRate={this.addRate}
            handleChangeDate={this.handleChangeDate}
            header={this.state.headerModal}
            errors={this.state.validate}
          />
          <ModalConfirm
            openModal={openModalConfirm}
            size="mini"
            close={this.closeModalConfirm}
            confirm={this.handleDelete}
            message={message}
            header="Confirmation"
          />
          <ModalDateRange
            openModal={this.state.modalDateRange}
            dateRange={this.state.dateRange}
            isHiddenOptions={true}
            contentHeader="Filter System Margin"
            handleClose={this.closeModalDateRange}
            actionDateRange={this.actionDateRange}
          />
        </Dimmer.Dimmable>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    listExchangeRate: state.ExchangeRateStore.getExchangeRateList,
    statusExchangeRate: state.ExchangeRateStore.getExchangeRateListStatus,
    currencies: state.currenciesStore.currencies.map(data => {
      return { text: data.Id, value: data.Id, key: data.Id };
    }),
    postExchangeRateStatus: state.ExchangeRateStore.postExchangeRateStatus,
    putExchangeRateStatus: state.ExchangeRateStore.putExchangeRateStatus,
    loading: state.ExchangeRateStore.loading,
  };
}
export default connect(
  mapStateToProps,
  {
    getExchangeRateListAction,
    resetExchangeRateAction,
    getCurrenciesAction,
    postExchangeRateAction,
    putExchangeRateAction,
    deleteExchangeRateAction,
  }
)(ExchangeRate);
