import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'semantic-ui-react';
import { StyleModal } from '../../../../assets/styles/size';

const ModalDescriptionManualPayment = props => {
  return (
    <div>
      <label style={{ color: 'red' }}>
        {props.contentMessageManualPayment}
      </label>
      <Container>
        No Booking{' '}
        <strong style={StyleModal.marginModalManualPayment.marginBooking}>
          : {props.contentDetail.Id}
        </strong>
      </Container>
      <Container>
        Customer{' '}
        <strong style={StyleModal.marginModalManualPayment.marginCustomer}>
          {' '}
          : {props.contentDetail.Customer}
        </strong>
      </Container>
      <Container>
        Tour Name
        <strong style={StyleModal.marginModalManualPayment.marginTourName}>
          {' '}
          : {props.contentDetail.TourName}
        </strong>
      </Container>
      <Container>
        Total Price
        <strong style={StyleModal.marginModalManualPayment.marginPrice}>
          : {props.contentDetail.CurrencyId}{' '}
          {new Intl.NumberFormat('de-DE').format(
            props.contentDetail.TourPrice ? props.contentDetail.TourPrice : 0
          )}{' '}
          With Special Adjusment
        </strong>
      </Container>
      <Container>
        Total Paid
        <strong style={StyleModal.marginModalManualPayment.marginPaid}>
          : {props.contentDetail.CurrencyId}{' '}
          {new Intl.NumberFormat('de-DE').format(
            props.contentDetail.TourPaid ? props.contentDetail.TourPaid : 0
          )}
        </strong>
      </Container>
    </div>
  );
};

ModalDescriptionManualPayment.propTypes = {
  contentDetail: PropTypes.func,
  contentMessageManualPayment: PropTypes.string,
};
export default ModalDescriptionManualPayment;
