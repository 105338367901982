import React from 'react';
import { Tab } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getHistoryTransactionByCodeAction,
  getHistoryTransactionForSalesByCodeAction,
  getSupervisorByCompanyCodePositionIdAction,
  resetCustomerProfilePageAction,
} from '../customerProfileAction';
import {
  resetCustomerProfileAction,
  getCustomerProfileByCodeAction,
  putCustomerProfileAction,
  getUserDataByCodeAction,
  getPaymentSchemeAction,
  postCustomerProfileAction,
  getAccountManagerAction,
  postBalanceDetailAction,
  getCustomerProfileByCodeManageBySalesAction,
} from '../../shared/actions/customerProfileAction';
import {
  resetCustomEnumAction,
  getCustomEnumByCatagoriesRevenueRangeAction,
  getCustomEnumByCatagoriesTotalBranchAction,
  getCustomEnumByCatagoriesTotalEmployeAction,
  getPaymentMethodByCountryIdAction,
  postTopUpPaymentGatewayAction,
} from '../../shared/actions/customEnumAction';
import { getCurrenciesAction } from '../../../actions/currenciesAction';
import { getCountryListAction } from '../../shared/actions/countryAction';
import {
  postUnlockUserAction,
  resetUserAccessAction,
  getUserProfileByUserIdAction,
  postResetPasswordAction,
} from '../../../actions/userAccessAction';
import {
  postUserAction,
  postUserProfileAction,
  resetUserInfoAction,
  getUserRoleListAction,
  putUserProfileAction,
} from '../../../actions/userAction';
import CustomerProfileDetail from './components/CustomerProfileDetail';
import ModalAlert from '../../../components/modal/modalAlert';
import ModalImage from '../../shared/components/modal/accommodation/ModalImage';
import TableHistoryTransacton from './components/table/TableHistoryTransaction';
import TableInvoices from './components/table/TableInvoices';
import TableUserData from './components/table/TableUserData';
import ModalResetPassword from '../../shared/components/modal/user/ModalResetPassword';
import {
  getInvoiceStatusUsingPaymentGatewayAction,
  resetStatusTransactionAction,
  getInvoiceByCompanyCodeAction,
  getPrintPaymentReceiptTransactionAction,
  getTransactionByIdAction,
  postDemoManualPaymentAction,
  getPayableSupplemetItemAction,
  getPaymentTermByTransactionIdAction,
  postConfirmTransaction,
  getSpecialAdjusmentByTransactionIdAction,
  getTourTransactioBillsAction,
  getTourTransactionBilledAction,
  postEmailSendConfirmation,
} from '../../../actions/tourTransactionAction';
import ModalManualPayment from '../../home/component/modal/ModalManualPayment';
// import ModalPayment from '../../shared/components/modal/ModalPaymentTransaction';
import ModalBalanceDetail from '../../shared/components/modal/ModalBalanceDetail';
import ModalPaymentGateway from '../../shared/components/modal/ModalPaymentGateway';
import ModalIframePaymentGateway from '../../shared/components/modal/ModalIframePaymentGateway';
import ModalDetailBooking from '../../shared/components/modal/ModalDetailBooking';
import ModalSpecialAdjusment from '../../shared/components/modal/ModalSpecialAdjusment';
import moment from 'moment';
import ModalConfirm from '../../../components/modal/modalConfirmationTwoButton';
import handleValidation from '../../shared/services/validation/emptyFieldValidation';
import {
  customerProfileField,
  resetPasswordField,
} from './constant/fieldToValidate';
import _ from 'lodash';
import SetDataUserCustomer from './services/data/setDataUserDataCustomer';
import setHistoryTransaction from './services/data/setHistoryTransaction';
import setDataInvoice from './services/data/setDataInvoice';
import { getUserRoleByBearerAction } from '../../../actions/userAccessAction';
import { checkUser } from '../../../scripts/authorization';
import ModalCancellationReason from '../../shared/components/modal/ModalCancellationReason';
import ModalManualSendEmail from '../../shared/components/modal/ModalManualSendEmail';

class CustomerProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNewForm: false,
      nextPage: false,
      nextPageLink: '',
      codeCustomer: 0,
      customerProfile: {},
      historyTransactionCustomer: [],
      userDataCustomer: [],
      invoicesByCompany: [],
      disable: true,
      contentButton: '',
      contentModal: '',
      modalConfirmation: false,
      loadingCustomerProfile: true,
      failedCustomerProfile: false,
      loadingUserDataCustomer: true,
      failedUserDatanCustomer: false,
      loadingHistoryTransactionCustomer: true,
      failedHistoryTransactionCustomer: false,
      loadingInvoice: true,
      openModalUser: false,
      openModalImage: false,
      openModalEmployee: false,
      openModalResetPassword: false,
      loadingUserProfile: true,
      loadingPostEmployee: false,
      loadingPostUser: false,
      EmployeeObject: {
        Id: '',
        FirstName: '',
        LastName: '',
        PhoneNbr: '',
        Gender: '',
        Address: '',
        Email: '',
        ImageUrl: '',
      },
      UserObject: {
        Password: '',
        ConfirmPassword: '',
        Username: '',
        Email: '',
        Roles: '',
      },
      resetPasswordObject: {
        NewPassword: '',
        ConfirmPassword: '',
      },
      dataItem: [],
      listGender: [
        {
          key: 'Male',
          text: 'Male',
          value: 'Male',
        },
        {
          key: 'Female',
          text: 'Female',
          value: 'Female',
        },
      ],
      errors: {},
      errorResetPassword: {},
      typePassword: 'password',
      passwordIcon: 'eye slash',
      typeConfirmPassword: 'password',
      confirmPasswordIcon: 'eye slash',
      TabMenu: [
        {
          menuItem: 'History Transaction',
          render: () => (
            <Tab.Pane
              active={true}
              content={
                <TableHistoryTransacton
                  listData={this.state.historyTransactionCustomer}
                  actionButton={this.bookingAction}
                  loading={this.state.loadingHistoryTransactionCustomer}
                  isHidePayment={this.state.isSales}
                  isHideApprove={this.state.isSales || this.state.isFinance}
                  isFinance={this.state.isFinance}
                  disable={false}
                />
              }
            />
          ),
        },
        {
          menuItem: 'Employee',
          render: () => (
            <Tab.Pane
              attached={false}
              content={
                <TableUserData
                  listData={this.state.userDataCustomer}
                  unlockUser={this.unlockUser}
                  buttonModalEmployee={this.buttonModalEmployee}
                  resetPassword={this.resetPassword}
                  editUser={this.editUser}
                  loading={this.state.loadingUserDataCustomer}
                  isNewForm={this.state.isFinance ? true : this.state.isNewForm}
                  isHideAddEmployee={this.state.isSales}
                  isHideResetPassword={this.state.isSales}
                  isHideUnlock={this.state.isSales}
                  isFinance={this.state.isFinance}
                />
              }
            />
          ),
        },
        {
          menuItem: 'Invoices',
          render: () => (
            <Tab.Pane
              active={true}
              content={
                <TableInvoices
                  listData={this.state.invoicesByCompany}
                  loading={this.state.loadingInvoice}
                  printInvoice={this.printInvoice}
                  isNewForm={this.state.isNewForm}
                />
              }
            />
          ),
        },
      ],
      iframePaymentGatewayObject: {},
      listTransactionDetail: {},
      manualPaymentDemo: [],
      tourTransactionConfirmationObject: {
        CustomValue: 0,
      },
      balanceDetail: {
        Name: '',
        CompanyCode: '',
      },
      totalAdditionalItemPay: 0,
      additionalItemPay: [],
      totalPayableValue: 0,
      imageTemp: {
        ImageContent: '',
        ImageName: '',
        ImagePreview: '',
      },
      openCollapse: false,
      isImageUpdate: false,
      isLogoUpdate: false,
      openModalPayment: false,
      transactionId: 0,
      modalManualPayment: false,
      disabledManualPayment: false,
      isSales: null,
      isFinance: null,
      isSendMail: false,
      isChooseSendEmail: false,
      openModalCancelBooking: false,
      loadingPostCancel: false,
      CancellationReasonId: null,
      CancellationReasonNote: '',
      openSendManualEmail: false,
    };
  }
  static propTypes = {
    resetCustomerProfileAction: PropTypes.func,
    resetCustomerProfilePageAction: PropTypes.func,
    getCustomerProfileByCodeAction: PropTypes.func,
    putCustomerProfileAction: PropTypes.func,
    getHistoryTransactionByCodeAction: PropTypes.func,
    getHistoryTransactionForSalesByCodeAction: PropTypes.func,
    getSupervisorByCompanyCodePositionIdAction: PropTypes.func,
    getUserDataByCodeAction: PropTypes.func,
    getCustomerProfileByCodeStatus: PropTypes.string,
    getCustomerProfileByCode: PropTypes.array,
    getHistoryTransactionByCodeStatus: PropTypes.string,
    getHistoryTransactionByCode: PropTypes.array,
    getUserDataByCodeStatus: PropTypes.string,
    getUserDataByCode: PropTypes.array,
    resetCustomEnumAction: PropTypes.func,
    getCustomEnumByCatagoriesRevenueRangeAction: PropTypes.func,
    getCatagoriesCustomEnumStatus: PropTypes.string,
    getCatagoriesCustomEnum: PropTypes.array,
    getCustomEnumByCatagoriesTotalBranchAction: PropTypes.func,
    getCustomEnumByCatagoriesTotalEmployeAction: PropTypes.func,
    getCustomEnumByCatagoriesRevenueRange: PropTypes.array,
    getCustomEnumByCatagoriesTotalEmploye: PropTypes.array,
    getCustomEnumByCatagoriesTotalBranch: PropTypes.array,
    getCurrenciesAction: PropTypes.func,
    getCurrencies: PropTypes.array,
    listCountry: PropTypes.array,
    getCountryListAction: PropTypes.func,
    getPaymentSchemeAction: PropTypes.func,
    getPaymentSchemeList: PropTypes.array,
    postCustomerProfileAction: PropTypes.func,
    putCustomerProfileStatus: PropTypes.string,
    postUnlockUserAction: PropTypes.func,
    postUnlockedUserStatus: PropTypes.string,
    resetUserAccessAction: PropTypes.func,
    getUserProfileByUserIdAction: PropTypes.func,
    getUserProfileByUserIdStatus: PropTypes.string,
    getUserProfileByUserId: PropTypes.array,
    postResetPasswordAction: PropTypes.func,
    postResetPasswordStatus: PropTypes.string,
    getUserRoleListAction: PropTypes.func,
    getUserRoleListStatus: PropTypes.string,
    getUserRoleList: PropTypes.array,
    putUserProfileAction: PropTypes.func,
    postCustomerProfileStatus: PropTypes.string,
    postUserStatus: PropTypes.string,
    resetUserInfoAction: PropTypes.func,
    postUserProfileStatus: PropTypes.string,
    errorsUser: PropTypes.string,
    putUserProfileStatus: PropTypes.string,
    postUserAction: PropTypes.func,
    postUserProfileAction: PropTypes.func,
    getInvoiceByCompanyCodeAction: PropTypes.func,
    getPrintPaymentReceiptTransactionAction: PropTypes.func,
    getInvoiceByCompanyCodeStatus: PropTypes.string,
    getInvoiceByCompanyCode: PropTypes.array,
    resetStatusTransactionAction: PropTypes.func,
    getPrintPaymentReceiptStatus: PropTypes.string,
    getPrintPaymentReceipt: PropTypes.array,
    errorMessageTourTransaction: PropTypes.string,
    TransactionDetailsStatus: PropTypes.string,
    TransactionDetails: PropTypes.array,
    getPayableSupplemetItemStatus: PropTypes.string,
    getPayableSupplemetItem: PropTypes.array,
    errorsTourTransaction: PropTypes.string,
    postDemoManualPaymentStatus: PropTypes.string,
    postDemoManualPayment: PropTypes.array,
    getTransactionByIdAction: PropTypes.func,
    getPayableSupplemetItemAction: PropTypes.func,
    postConfirmTransaction: PropTypes.func,
    postDemoManualPaymentAction: PropTypes.func,
    getPaymentTermByTransactionIdAction: PropTypes.func,
    getSpecialAdjusmentByTransactionIdAction: PropTypes.func,
    currencies: PropTypes.array,
    getTourTransactioBillsAction: PropTypes.func,
    getTourTransactionBilledAction: PropTypes.func,
    getTourTransactionBilled: PropTypes.array,
    postCustomerProfile: PropTypes.object,
    getTourTransactionBillsStatus: PropTypes.string,
    getTourTransactionBills: PropTypes.array,
    getTourTransactionBilledStatus: PropTypes.string,
    postTransactionConfirmationStatus: PropTypes.string,
    getPaymentMethodByCountryIdStatus: PropTypes.string,
    getPaymentMethodByCountryIdAction: PropTypes.func,
    getPaymentMethodByCountryId: PropTypes.array,
    postBalanceDetailAction: PropTypes.func,
    postTopUpPaymentGatewayAction: PropTypes.func,
    postBalanceDetailStatus: PropTypes.string,
    postTopUpUsingPaymentGatewayStatus: PropTypes.string,
    postTopUpUsingPaymentGateway: PropTypes.object,
    errorMessage: PropTypes.string,
    getAccountManagerAction: PropTypes.func,
    getAccountManager: PropTypes.array,
    errorMessageTopUp: PropTypes.string,
    match: PropTypes.object,
    history: PropTypes.object,
    loadingUserStore: PropTypes.bool,
    userRoles: PropTypes.object,
    getUserRoleByBearerAction: PropTypes.func,
    getCustomerProfileByCodeManageBySalesAction: PropTypes.func,
    postEmailSendConfirmation: PropTypes.func,
  };
  componentDidMount = () => {
    this.getCustomerProfileId();
    this.props.getAccountManagerAction();
    this.load();
  };
  async load(code) {
    await this.props.getUserRoleByBearerAction().then(() => {
      let authorization = checkUser(this.props.userRoles.Roles);
      this.setState({
        ...this.state,
        isSales: authorization.isSales,
        isFinance: authorization.isFinance,
      });
    });
    if (code) {
      if (this.state.isSales) {
        await this.props.getCustomerProfileByCodeManageBySalesAction(code);
        await this.props.getHistoryTransactionForSalesByCodeAction(code);
      } else {
        await this.props.getCustomerProfileByCodeAction(code);
        await this.props.getHistoryTransactionByCodeAction(code);
      }
      await this.props.getUserDataByCodeAction(code);
      await this.props.getInvoiceByCompanyCodeAction(code);
      await this.props.getCurrenciesAction();
    } else {
      await this.props.getCustomEnumByCatagoriesRevenueRangeAction();
      await this.props.getCustomEnumByCatagoriesTotalBranchAction();
      await this.props.getCustomEnumByCatagoriesTotalEmployeAction();
      await this.props.getCurrenciesAction();
      await this.props.getCountryListAction();
      await this.props.getPaymentSchemeAction();
    }
  }
  handleCloseIframe = () => {
    this.setState({
      openModalIframePaymentGateway: false,
      modalPaymentGateway: false,
      modalBalanceDetail: false,
      loadingAdditionalItem: true,
      loadingManualDemo: true,
      loadingPercentage: true,
      loadingTransactionDetail: true,
      modalManualPayment: false,
    });
  };
  handleCloseConfirmAlertPaymentGateway = () => {
    this.setState({
      openConfirmAlertPaymentGateway: false,
      loadingPayment: false,
    });
  };
  handleCloseDetailBooking = () => {
    this.setState({
      openModalDetailBooking: false,
      loadingManualDemo: true,
      loadingAdditionalItem: true,
      loadingPercentage: true,
      loadingTransactionDetail: true,
      loadingBooking: false,
    });
  };
  handleCloseSpecialAdjusment = () => {
    this.setState({
      openModalSpecialAdjusment: false,
    });
  };
  handleCloseModalPayment = () => {
    this.setState({
      modalManualPayment: false,
      contentMessage: '',
      loadingManualDemo: true,
      loadingAdditionalItem: true,
      loadingPercentage: true,
      loadingTransactionDetail: true,
      loadingBooking: false,
    });
  };
  handleCloseAlert = () => {
    let { nextPageLink } = this.state;
    // eslint-disable-next-line
    nextPageLink && this.props.history.push(nextPageLink);
    this.setState({
      nextPageLink: '',
      // loadingHistoryTransactionCustomer: false,
      modalConfirmation: false,
      modalManualPayment: false,
    });
  };
  handleClose = () =>
    this.setState({
      modalOpen: false,
      failedBooking: false,
      failedCompany: false,
      failedPublish: false,
      openModalIframePaymentGateway: false,
      modalPaymentGateway: false,
      modalBalanceDetail: false,
      modalConfirmation: false,
      openModalChooseManager: false,
      openAllConfirmAlert: false,
    });
  handleCloseEmployee = () =>
    this.setState({
      openModalEmployee: false,
      loadingPostUser: false,
    });
  handleCloseResetPassword = () =>
    this.setState({
      openModalResetPassword: false,
    });
  handleCloseModalUser = () =>
    this.setState({
      openModalUser: false,
    });
  handleCloseModalImage = () => this.setState({ openModalImage: false });
  getCustomerProfileId = () => {
    // eslint-disable-next-line
    let { ProfileId, Type } = this.props.match.params;
    if (Type === 'new') {
      this.setState({
        isNewForm: Type === 'new',
        loadingCustomerProfile: false,
        loadingHistoryTransactionCustomer: false,
        loadingUserDataCustomer: false,
        contentButton: 'Save',
        disable: false,
        customerProfile: {
          CompanyId: '',
          Name: '',
          Address: '',
          Telephone: '',
          CountryId: '',
          EmployeeRangeId: '',
          RevenueRangeId: '',
          BranchRangeId: '',
          CurrencyId: '',
          CompanyBusiness: 3,
          ImageContent: '',
          ImagePreview: '',
          CompanyLogo: {
            ImageName: '',
            ImageContent: '',
            ImagePreview: '',
          },
          Email: '',
          //AccountManagerId: '',
          //LevelId: '',
          FaxNbr: '',
          PaymentSchemeId: '',
        },
        typeCustomer: Type,
      });
    } else {
      return this.getDataCustomer(ProfileId, Type);
    }
  };
  getDataCustomer = (code, type) => {
    this.load(code);
    this.setState({
      isNewForm: type === 'new',
      customerProfile: {
        CompanyId: code,
      },
      resetPasswordObject: {
        ...this.state.resetPasswordObject,
        CompanyCode: code,
      },
      contentButton: type === 'detail' ? 'Edit' : 'Save',
      disable: type === 'detail',
      typeCustomer: type,
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.match.params.Type !== prevProps.match.params.Type) {
      this.getCustomerProfileId();
    }
    if (this.props.getCustomerProfileByCodeStatus === 'success') {
      this.setStateCustomerProfile(this.props.getCustomerProfileByCode);
      this.setStateBalanceDetail(this.props.getCustomerProfileByCode);
      this.setState({
        loadingCustomerProfile: false,
        loadingBalanceDetail: false,
      });
      this.props.resetCustomerProfileAction();
      this.props.resetCustomerProfilePageAction();
    } else if (this.props.getCustomerProfileByCodeStatus === 'failed') {
      this.setState({
        failedCustomerProfile: true,
        loadingCustomerProfile: false,
        loadingBalanceDetail: false,
      });
      this.props.resetCustomerProfileAction();
      this.props.resetCustomerProfilePageAction();
    }
    if (this.props.getHistoryTransactionByCodeStatus === 'success') {
      this.setState({
        historyTransactionCustomer: setHistoryTransaction(
          this.props.getHistoryTransactionByCode
        ),
        loadingHistoryTransactionCustomer: false,
      });
      this.props.resetCustomerProfileAction();
      this.props.resetCustomerProfilePageAction();
    } else if (this.props.getHistoryTransactionByCodeStatus === 'failed') {
      this.setState({
        loadingHistoryTransactionCustomer: true,
        failedHistoryTransactionCustomer: false,
      });
      this.props.resetCustomerProfileAction();
      this.props.resetCustomerProfilePageAction();
    }
    if (this.props.getUserDataByCodeStatus === 'success') {
      this.setState({
        userDataCustomer: SetDataUserCustomer(this.props.getUserDataByCode),
      });
      this.setState({ loadingUserDataCustomer: false });
      this.props.resetCustomerProfileAction();
      this.props.resetCustomerProfilePageAction();
    } else if (this.props.getUserDataByCodeStatus === 'failed') {
      this.setState({
        failedUserDatanCustomer: true,
        loadingUserDataCustomer: false,
      });
      this.props.resetCustomerProfileAction();
      this.props.resetCustomerProfilePageAction();
    }
    if (this.props.getCatagoriesCustomEnumStatus === 'success') {
      this.setState({ companyRevenue: this.props.getCatagoriesCustomEnum });
      this.props.resetCustomEnumAction();
      this.props.getCustomEnumByCatagoriesRevenueRangeAction('TOTAL_BRANCH');
    } else if (this.props.getCatagoriesCustomEnumStatus === 'failed') {
      this.setState({
        failedCustomerProfile: true,
        loadingCustomerProfile: false,
      });
      this.props.resetCustomEnumAction();
    }
    if (this.props.putCustomerProfileStatus === 'success') {
      this.setState({
        headerAlert: ' Success',
        contentAlert: ' Success Edit Customer Profile',
        modalConfirmation: true,
        loadingCustomerProfile: false,
        nextPageLink:
          '/Customer-Profile-Detail/detail/' +
          this.state.customerProfile.CompanyId,
      });
      this.getCustomerProfileId();
      this.props.resetCustomerProfileAction();
      this.props.resetCustomerProfilePageAction();
    } else if (this.props.putCustomerProfileStatus === 'failed') {
      this.setState({
        headerAlert: ' Failed',
        contentAlert: ' Failed Edit Customer Profile',
        modalConfirmation: true,
        loadingCustomerProfile: false,
      });
      this.props.resetCustomerProfileAction();
      this.props.resetCustomerProfilePageAction();
    }
    if (this.props.postCustomerProfileStatus === 'success') {
      let { Code } = this.props.postCustomerProfile;
      this.setState({
        headerAlert: ' Success',
        contentAlert: ' Success Save Customer Profile',
        modalConfirmation: true,
        loadingCustomerProfile: false,
        nextPageLink: '/Customer-Profile-Detail/detail/' + Code,
      });
      this.props.resetCustomerProfileAction();
      this.props.resetCustomerProfilePageAction();
    } else if (this.props.postCustomerProfileStatus === 'failed') {
      this.setState({
        headerAlert: ' Failed',
        contentAlert: ' Failed Save Customer Profile',
        modalConfirmation: true,
        loadingCustomerProfile: false,
      });
      this.props.resetCustomerProfileAction();
      this.props.resetCustomerProfilePageAction();
    }
    if (this.props.postUnlockedUserStatus === 'success') {
      this.setState({
        headerAlert: ' Success',
        contentAlert: 'The user is successfully unlocked',
        modalConfirmation: true,
        loadingUserDataCustomer: true,
      });
      this.props.getUserDataByCodeAction(this.state.customerProfile.CompanyId);
      this.props.resetUserAccessAction();
    } else if (this.props.postUnlockedUserStatus === 'failed') {
      this.setState({
        headerAlert: ' Failed',
        contentAlert: ' The user is failed unlocked',
        modalConfirmation: true,
      });
      this.props.resetUserAccessAction();
    }
    if (this.props.getUserProfileByUserIdStatus === 'success') {
      this.setState({
        resetPasswordObject: {
          ...this.state.resetPasswordObject,
          UserId: this.props.getUserProfileByUserId.UserId,
          Username: this.props.getUserProfileByUserId.UserName,
        },
        loadingUserProfile: false,
      });
      this.props.resetUserAccessAction();
    } else if (this.props.getUserProfileByUserIdStatus === 'failed') {
      this.setState({
        headerAlert: ' Failed',
        contentAlert: ' The user is Failed Loaded',
        modalConfirmation: true,
      });
      this.props.resetUserAccessAction();
    }
    if (this.props.postResetPasswordStatus === 'success') {
      this.setState({
        headerAlert: ' Success',
        contentAlert: 'New Password successfully ',
        modalConfirmation: true,
        openModalResetPassword: false,
      });
      this.props.resetUserAccessAction();
    } else if (this.props.postResetPasswordStatus === 'failed') {
      this.setState({
        headerAlert: ' Failed',
        contentAlert: ' Failed Change Password',
        modalConfirmation: true,
      });
      this.props.resetUserAccessAction();
    }

    if (this.props.getInvoiceByCompanyCodeStatus === 'success') {
      this.setState({
        invoicesByCompany: setDataInvoice(this.props.getInvoiceByCompanyCode),
        loadingInvoice: false,
      });
      this.props.resetStatusTransactionAction();
    } else if (this.props.getInvoiceByCompanyCodeStatus === 'failed') {
      this.setState({
        loadingInvoice: false,
      });
      this.props.resetStatusTransactionAction();
    }

    if (this.props.getPrintPaymentReceiptStatus === 'success') {
      window.open(this.props.getPrintPaymentReceipt);
      this.setState({
        loadingInvoice: false,
      });
      this.props.resetStatusTransactionAction();
    } else if (this.props.getPrintPaymentReceiptStatus === 'failed') {
      this.setState({
        modalConfirmation: true,
        headerAlert: 'Failed',
        contentAlert: this.props.errorMessageTourTransaction,
        loadingInvoice: false,
      });
      this.props.resetStatusTransactionAction();
    }
    if (this.props.TransactionDetailsStatus === 'success') {
      this.setStateTransactionDetail(this.props.TransactionDetails);
      this.setState({ loadingTransactionDetail: false });
      this.props.resetStatusTransactionAction();
    } else if (this.props.TransactionDetailsStatus === 'failed') {
      this.setState({
        headerAlert: ' Failed',
        contentAlert: ' Failed Edit Customer Profile',
        modalConfirmation: true,
      });
      this.props.resetStatusTransactionAction();
    }
    if (this.props.getPayableSupplemetItemStatus === 'success') {
      this.setAdditionalInfoPrice(this.props.getPayableSupplemetItem);
      this.setState({
        AdditonalItem: this.props.getPayableSupplemetItem,
        additionalItemPay: this.props.getPayableSupplemetItem,

        loadingAdditionalItem: false,
      });
      this.props.resetStatusTransactionAction();
    } else if (this.props.getPayableSupplemetItemStatus === 'failed') {
      this.setState({
        contentMessageManualPayment: this.props.errorsTourTransaction.global,
        disabledManualPayment: true,
      });
      this.props.resetStatusTransactionAction();
    }
    if (this.props.postDemoManualPaymentStatus === 'success') {
      this.setState({
        manualPaymentDemo: this.props.postDemoManualPayment,
        loadingManualDemo: false,
        loadingPayment: false,
      });
      this.props.resetStatusTransactionAction();
    } else if (this.props.postDemoManualPaymentStatus === 'failed') {
      this.setState({
        contentMessageManualPayment: this.props.errorsTourTransaction.global,
        disabledManualPayment: true,
        loadingPayment: false,
        modalConfirmation: true,
        contentAlert: this.props.errorsTourTransaction.global,
        headerAlert: 'Failed',
        modalManualPayment: false,
        //loadingManualDemo: false,
      });
      this.props.resetStatusTransactionAction();
    }
    if (this.props.TransactionDetailsStatus === 'success') {
      this.setStateTransactionDetail(this.props.TransactionDetails);
      this.setState({ loadingTransactionDetail: false });
      this.props.resetStatusTransactionAction();
    } else if (this.props.TransactionDetailsStatus === 'failed') {
      this.setState({
        headerAlert: ' Failed',
        contentAlert: ' Failed Edit Customer Profile',
        modalConfirmation: true,
      });
      this.props.resetStatusTransactionAction();
    }
    if (this.props.getTourTransactionBillsStatus === 'success') {
      const transactionBills = this.props.getTourTransactionBills[
        this.props.getTourTransactionBills.length - 1
      ];
      this.setState({
        tourTransactionConfirmationObject: {
          ...this.state.tourTransactionConfirmationObject,
          // tourTransactionBills: transactionBills.RestOfTheBillWithoutSupplement,
          tourTransactionBills: transactionBills.RestOfTheBill,
        },
      });
      this.props.resetStatusTransactionAction();
    } else if (this.props.getTourTransactionBillsStatus === 'failed') {
      this.props.resetStatusTransactionAction();
    }
    if (this.props.getTourTransactionBilledStatus === 'success') {
      this.setState({
        totalPayableValue: this.props.getTourTransactionBilled.RestOfTheBill
          ? this.props.getTourTransactionBilled.RestOfTheBill
          : 0,
        tourTransactionConfirmationObject: {
          ...this.state.tourTransactionConfirmationObject,
          tourTransactionBilled: this.props.getTourTransactionBilled
            .RestOfTheBillWithoutSupplement
            ? this.props.getTourTransactionBilled.RestOfTheBillWithoutSupplement
            : 0,
          tourTransactionBilledStatic: this.props.getTourTransactionBilled
            .RestOfTheBillWithoutSupplement
            ? this.props.getTourTransactionBilled.RestOfTheBillWithoutSupplement
            : 0,
          // tourTransactionBilled: this.props.getTourTransactionBilled
          //   .RestOfTheBill
          //   ? this.props.getTourTransactionBilled.RestOfTheBill
          //   : 0,
          // tourTransactionBilledStatic: this.props.getTourTransactionBilled
          //   .RestOfTheBill
          //   ? this.props.getTourTransactionBilled.RestOfTheBill
          //   : 0,
        },
      });
      this.props.resetStatusTransactionAction();
    } else if (this.props.getTourTransactionBilledStatus === 'failed') {
      this.props.resetStatusTransactionAction();
    }
    if (this.props.postTransactionConfirmationStatus === 'success') {
      const { customerProfile } = this.state;
      this.setState({
        ...this.state,
        headerAlert: 'Success',
        contentAlert: 'Payment successfully',
        modalConfirmation: true,
        loadingConfirm: false,
        loadingBooking: false,
        modalManualPayment: false,
      });
      // this.demoManualPayment(this.props.TransactionDetails);
      if (this.state.isSales) {
        this.props.getHistoryTransactionForSalesByCodeAction(
          customerProfile.CompanyId
        );
      } else {
        this.props.getHistoryTransactionByCodeAction(customerProfile.CompanyId);
      }
      this.props.resetStatusTransactionAction();
    } else if (this.props.postTransactionConfirmationStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorMessageTourTransaction,
        modalConfirmation: true,
        openModalIframePaymentGateway: false,
        loadingConfirm: false,
        loadingBooking: false,
      });
      this.props.resetStatusTransactionAction();
    }
    if (this.props.getPaymentMethodByCountryIdStatus === 'success') {
      this.setState({
        paymentMethodByCountryId: this.props.getPaymentMethodByCountryId.map(
          p => p.PaymentGateawayChoice
        ),
      });
      this.props.resetCustomEnumAction();
    } else if (this.props.getPaymentMethodByCountryIdStatus === 'failed') {
      this.props.resetCustomEnumAction();
    }
    if (this.props.postBalanceDetailStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Save Balance Succesfully',
        modalConfirmation: true,
        modalBalanceDetail: false,
        modalPaymentGateway: false,
        loadingBooking: false,
      });
      this.demoManualPayment(this.props.TransactionDetails);
      this.props.resetCustomerProfileAction();
      this.props.resetCustomerProfilePageAction();
    } else if (this.props.postBalanceDetailStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorMessage,
        modalConfirmation: true,
        loadingPayment: false,
        loadingBooking: false,
      });
      this.props.resetCustomerProfileAction();
    }
    if (this.props.postTopUpUsingPaymentGatewayStatus === 'success') {
      this.setState({
        openModalIframePaymentGateway: true,
        iframePaymentGatewayObject: this.props.postTopUpUsingPaymentGateway,
        loadingPayment: false,
      });
      this.props.resetCustomEnumAction();
    } else if (this.props.postTopUpUsingPaymentGatewayStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorMessageTopUp,
        modalConfirmation: true,
        openModalIframePaymentGateway: false,
        loadingPayment: false,
      });
      this.props.resetCustomEnumAction();
    }
  }
  setAdditionalInfoPrice = data => {
    let total = data.reduce((total, num) => {
      return total + num.MinimumPayable;
    }, 0);
    return this.setState({
      TotalPayableAdditionalItem: total,
    });
  };
  setStateCustomerProfile = data => {
    const {
      Name,
      ImageName,
      ImageUrl,
      PaymentSchemeType,
      IsAllowedOwnCountryTour,
      CountryId,
      Address,
      Email,
      Telephone,
      FaxNbr,
      TradelicenseNbr,
      AssociationNumber,
      IATANbr,
      EmployeeRange,
      BranchRange,
      RevenueRange,
      Code,
      Currency,
      CompanyLogo,
      ApprovedDate,
      CreatedDate,
      AccountManagerId,
    } = data;
    let customerProfileData = {
      CompanyId: Code ? Code : '',
      Name: Name ? Name : '',
      Address: Address ? Address : '',
      Telephone: Telephone ? Telephone : '',
      Email: Email ? Email : '',
      FaxNbr: FaxNbr ? FaxNbr : '',
      IATANbr: IATANbr ? IATANbr : '',
      TradeLicenseNbr: TradelicenseNbr ? TradelicenseNbr : '',
      AssociationNumber: AssociationNumber ? AssociationNumber : '',
      EmployeeRangeId:
        EmployeeRange && EmployeeRange.Id ? EmployeeRange.Id : '',
      BranchRangeId: BranchRange && BranchRange.Id ? BranchRange.Id : '',
      RevenueRangeId: RevenueRange && RevenueRange.Id ? RevenueRange.Id : '',
      CountryId: CountryId ? CountryId : '',
      CurrencyId: Currency ? Currency : '',
      CompanyLogo: {
        ImageContent: CompanyLogo ? CompanyLogo.ImageUrl : '',
        ImageName: CompanyLogo ? CompanyLogo.ImageName : '',
        ImagePreview: CompanyLogo ? CompanyLogo.ImageUrl : '',
      },
      IsAllowedOwnCountryTour: IsAllowedOwnCountryTour
        ? IsAllowedOwnCountryTour
        : false,
      ImageName: ImageName ? ImageName : '',
      ImageContent: ImageUrl ? ImageUrl : '',
      ImagePreview: ImageUrl ? ImageUrl : '',
      PaymentSchemeId:
        PaymentSchemeType && PaymentSchemeType.Id ? PaymentSchemeType.Id : '',
      CompanyBusiness: 3,
      ApprovedDate,
      CreatedDate,
      AccountManagerId,
    };
    this.setState({
      customerProfile: customerProfileData,
    });
  };
  // editCustomer = () => {
  //   this.setState({
  //     disable: false,
  //     contentButton: 'Save',
  //   });
  // };
  balanceDetail = () => {
    // eslint-disable-next-line
    this.props.history.push(
      `/Balance-Detail/${this.state.customerProfile.CompanyId}`
    );
  };
  setMargin = () => {
    // eslint-disable-next-line
    this.props.history.push(
      `/System-Margin/Customer/${this.state.customerProfile.CompanyId}`
    );
  };
  setCommission = () => {
    this.props.history.push(
      `/Company-Commissions/${this.state.customerProfile.CompanyId}`
    );
  };

  buttonBack = () => {
    // eslint-disable-next-line
    this.props.history.push('/Customer-Profile');
  };
  onChange = (e, { value, name }) => {
    let customerProfile = { ...this.state.customerProfile };
    customerProfile[name] = value;
    this.setState({ customerProfile: customerProfile }, () => this.validate());
  };

  saveCustomer = () => {
    const { contentButton } = this.state;
    if (contentButton === 'Edit') {
      this.setState({
        contentButton: 'Save',
        disable: false,
      });
    } else {
      const err = this.validate();
      if (!err) {
        this.setState({
          loadingCustomerProfile: true,
        });
        let { customerProfile, isImageUpdate, isLogoUpdate } = this.state;
        const Id = customerProfile.CompanyId;
        let data = { ...customerProfile };
        if (!isImageUpdate) {
          delete data.ImageName;
          delete data.ImageContent;
          delete data.ImagePreview;
        }
        if (!isLogoUpdate) {
          delete data.CompanyLogo;
        }

        if (Id === '') {
          this.props.postCustomerProfileAction(data);
        } else {
          this.props.putCustomerProfileAction(Id, data);
        }
      }
    }
  };
  // validateCustomerProfile = () => {
  //   let isError = false;
  //   let { Email } = this.state.customerProfile;
  //   const errors = {
  //     NameError: '',
  //     AddressError: '',
  //     TelephoneError: '',
  //     CountryIdError: '',
  //     EmployeeRangeIdError: '',
  //     RevenueRangeIdError: '',
  //     BranchRangeIdError: '',
  //     CurrencyIdError: '',
  //     EmailError: '',
  //     FaxError: '',
  //   };
  //   if (this.state.customerProfile.Name.length < 1) {
  //     isError = true;
  //     errors.NameError = "Company Name Can't Be Empty";
  //   }
  //   if (this.state.customerProfile.Address.length < 1) {
  //     isError = true;
  //     errors.AddressError = "Address Can't Be Empty";
  //   }
  //   if (this.state.customerProfile.Telephone.length < 1) {
  //     isError = true;
  //     errors.TelephoneError = "Phone Number Can't Be Empty";
  //   }
  //   if (this.state.customerProfile.CountryId.length < 1) {
  //     isError = true;
  //     errors.CountryIdError = "Country Can't Be Empty";
  //   }
  //   if (this.state.customerProfile.EmployeeRangeId.length < 1) {
  //     isError = true;
  //     errors.EmployeeRangeIdError = "Number Of Employe Can't Be Empty";
  //   }
  //   if (this.state.customerProfile.BranchRangeId.length < 1) {
  //     isError = true;
  //     errors.BranchRangeIdError = "Branch Can't Be Empty";
  //   }
  //   if (this.state.customerProfile.RevenueRangeId.length < 1) {
  //     isError = true;
  //     errors.RevenueRangeIdError = "Company Revenue Can't Be Empty";
  //   }
  //   if (this.state.customerProfile.CurrencyId.length < 1) {
  //     isError = true;
  //     errors.CurrencyIdError = "Currency Can't Be Empty";
  //   }
  //   if (Email.length < 1) {
  //     isError = true;
  //     errors.EmailError = "Email Can't Be Empty";
  //   } else if (Email.length >= 1 && !this.validationEmail(Email)) {
  //     isError = true;
  //     errors.EmailError = 'Email invalid';
  //   }
  //   if (this.state.customerProfile.FaxNbr.length < 1) {
  //     isError = true;
  //     errors.FaxError = "Fax Number Can't Be Empty";
  //   }
  //   this.setState({
  //     errors: {
  //       ...errors,
  //     },
  //   });
  //   return isError;
  // };

  // validationEmail = email => {
  //   return email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
  // };

  validate = () => {
    let isError = false;
    const errors = handleValidation(
      this.state.customerProfile,
      customerProfileField
    );
    isError = !_.isEmpty(errors);
    this.setState({
      errors: {
        ...errors,
      },
    });
    return isError;
  };

  validationResetPassword = () => {
    let isError = false;
    const errors = handleValidation(
      this.state.resetPasswordObject,
      resetPasswordField
    );
    isError = !_.isEmpty(errors);
    this.setState({
      errorResetPassword: {
        ...errors,
      },
    });
    return isError;
  };

  // validationPassword = password => {
  //   return password.match(
  //     /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9~`)({}+!#$%^&*@_]{8,})$/
  //   );
  // };

  handleCheckClick = () => {
    this.setState({
      customerProfile: {
        ...this.state.customerProfile,
        IsAllowedOwnCountryTour: !this.state.customerProfile
          .IsAllowedOwnCountryTour,
      },
    });
  };
  modalImage = e => {
    if (e.target.id === 'LicensePicture') {
      this.setState({
        contentModal: 'Add License Picture',
        openModalImage: true,
        dataItem: [],
        imageUrl: null,
        imageTemp: {
          ImageContent: null,
          ImageName: null,
          ImagePreview: null,
        },
      });
    } else {
      this.setState({
        contentModal: 'Add Company Logo',
        openModalImage: true,
        dataItem: [],
        imageUrl: null,
        imageTemp: {
          ImageContent: null,
          ImageName: null,
          ImagePreview: null,
        },
      });
    }
  };

  onChangeImage = e => {
    e.map(base => {
      return this.setState({
        imageTemp: {
          ...this.state.imageTemp,
          ImageContent: base.base64.split(',')[1],
          ImageName: base.file.name,
          ImagePreview: URL.createObjectURL(base.file),
        },
      });
    });
  };

  addImage = () => {
    let { contentModal, imageTemp } = this.state;
    let { ImageContent, ImageName, ImagePreview } = imageTemp;
    if (contentModal === 'Add License Picture') {
      return this.setState({
        customerProfile: {
          ...this.state.customerProfile,
          ImageContent,
          ImageName,
          ImagePreview,
        },
        openModalImage: false,
        isImageUpdate: true,
      });
    } else if (contentModal === 'Add Company Logo') {
      return this.setState({
        customerProfile: {
          ...this.state.customerProfile,
          CompanyLogo: {
            ImageContent,
            ImageName,
            ImagePreview,
          },
        },
        openModalImage: false,
        isLogoUpdate: true,
      });
    } else {
      return this.setState({
        EmployeeObject: {
          ...this.state.EmployeeObject,
          ImageContent,
          ImageName,
          ImagePreview,
          ImageUrl: null,
        },
        openModalImage: false,
      });
    }
  };
  unlockUser = (e, { id }) => {
    this.props.postUnlockUserAction(id);
  };
  resetPassword = (e, { id }) => {
    this.props.getUserProfileByUserIdAction(id);
    this.setState({
      resetPasswordObject: {
        ...this.state.resetPasswordObject,
      },
      openModalResetPassword: true,
    });
  };

  buttonModalEmployee = () => {
    this.props.history.push(
      `/Employee/create/${this.state.customerProfile.CompanyId}/0`
    );
  };
  handleChangeResetPassword = (e, { value, name }) => {
    const resetPasswordObject = this.state.resetPasswordObject;
    resetPasswordObject[name] = value;
    this.setState({ resetPasswordObject: resetPasswordObject });
    // this.validate();
    this.validationResetPassword();
  };
  saveResetPassword = () => {
    const err = this.validationResetPassword();
    let data = this.state.resetPasswordObject;
    if (!err) {
      this.props.postResetPasswordAction(data);
    }
  };
  handleCheckClickEmployee = () => {
    this.setState({
      EmployeeObject: {
        ...this.state.EmployeeObject,
        IsInactive: !this.state.EmployeeObject.IsInactive,
      },
    });
  };

  openModalImage = () => {
    this.setState({
      openModalImage: true,
    });
  };
  editUser = (e, { id }) => {
    this.props.history.push(
      `/Employee/Edit/${this.state.customerProfile.CompanyId}/${id}`
    );
  };

  printInvoice = (e, { value, id }) => {
    if (id === null) {
      this.props.getPrintPaymentReceiptTransactionAction('', value);
    } else {
      this.props.getPrintPaymentReceiptTransactionAction(id, value);
    }
    this.setState({
      loadingInvoice: true,
    });
  };
  bookingAction = (e, { name, value, id }) => {
    let keys = [
      'Booking_DP_Confirmed',
      'Booking_In_Payment',
      'Booking_created',
    ];
    let isCancel = name === 'Cancel';
    let matching = keys.find(values => values === value);
    if (value === matching && !isCancel) {
      this.setState({ loadingBooking: true });
      this.props.getTransactionByIdAction(id);
      this.props.getPayableSupplemetItemAction(id);
      this.setState({
        openModalPayment: true,
        transactionId: id,
        postTemporaryAction: {
          Id: id,
          Confirmation: name,
          SupplementPayment: [],
          PaymentChoice: 'Deposit',
          UpdateGuest: null,
        },
        modalManualPayment: true,
        openModalDetailBooking: false,
        contentMessageManualPayment: '',
        listTransaction: {},
        manualPaymentDemo: {},
        AdditonalItem: [],
        tourTransactionConfirmationObject: {
          CustomValue: 0,
        },
        additionalItemPay: [],
        loadingTransactionDetail: true,
      });
    } else {
      let data = {
        Id: id,
        Confirmation: name,
        PaymentChoice: 0,
      };
      this.setState({
        // openModalPayment: true,
        transactionId: id,
        loadingTransactionOnHold: true,
        confirmBooking: data,
        // openConfirmBooking: true,
        contentAlertBooking:
          'Are you sure to ' + name.toLowerCase() + ' booking id ' + id + '?',
        isChooseSendEmail: true,
        openModalCancelBooking: true,
      });
    }
  };

  confirmBooking = () => {
    this.setState(
      {
        openConfirmBooking: false,
        loadingHistoryTransactionCustomer: true,
        openSendManualEmail: false,
      },
      () => this.confirmTransaction()
    );
  };

  handleCloseConfirmBooking = () => {
    this.setState({
      openConfirmBooking: false,
      loadingHistoryTransactionCustomer: false,
    });
  };

  confirmTransaction = () => {
    let { confirmBooking, customerProfile } = this.state;
    if (confirmBooking.Confirmation === 'Cancel') {
      confirmBooking.CancellationReasonId = this.state.CancellationReasonId;
      confirmBooking.CancellationReasonNote = this.state.CancellationReasonNote;
    }
    this.setState({ loadingPostCancel: true });
    this.props
      .postConfirmTransaction(confirmBooking)
      .then(() => {
        this.setState(
          {
            headerAlert: ' Success',
            contentAlert: `Success ${confirmBooking.Confirmation} Transaction`,
            modalConfirmation: true,
            loadingPostCancel: false,
            openModalCancelBooking: false,
          },
          () => {
            if (this.state.isSales) {
              this.props.getHistoryTransactionForSalesByCodeAction(
                customerProfile.CompanyId
              );
            } else {
              this.props.getHistoryTransactionByCodeAction(
                customerProfile.CompanyId
              );
            }
            if (this.state.isSendMail) {
              this.props.postEmailSendConfirmation({
                tourTransactionId: confirmBooking.Id,
                emailSendConfirmed: true,
              });
            }
          }
        );
      })
      .catch(() =>
        this.setState({
          headerAlert: ' Failed',
          contentAlert: `Failed ${confirmBooking.Confirmation} Transaction`,
          modalConfirmation: true,
          loadingHistoryTransactionCustomer: false,
          loadingPostCancel: false,
          openModalCancelBooking: false,
        })
      );
  };
  generateManualPayment = BookingDetailSum => {
    let ManualPaymentObject = {};
    if (BookingDetailSum !== undefined && BookingDetailSum !== null) {
      if (BookingDetailSum.PackageType === 'Custom') {
        ManualPaymentObject = {
          PaymentChoice: 0,
          CustomValue: 0,
          Id: BookingDetailSum.Id,
          Confirmation: 0,
          SupplementPayment: [],
          Customer: BookingDetailSum.CreatedBy.CompanyName,
          TourName: BookingDetailSum.Title,
          TourPrice: BookingDetailSum.TourTotalPrice,
          TourPaid: BookingDetailSum.TotalPayed,
          CurrencyId: BookingDetailSum.CurrencyId,
          PaymentTermCompany: BookingDetailSum.PaymentTerms
            ? BookingDetailSum.PaymentTerms
            : [],
          Payment: BookingDetailSum.PaymentTerms.map(data => {
            return {
              PaymentPercentage: data.PaymentPercentage,
              IsPayed: data.IsPayed,
            };
          }),
          Status: BookingDetailSum.Status,
          CompanyCode: BookingDetailSum.CreatedBy.CompanyCode,
        };
      } else {
        ManualPaymentObject = {
          PaymentChoice: 0,
          CustomValue: 0,
          Id: BookingDetailSum.Id,
          Confirmation: 0,
          SupplementPayment: [],
          Customer: BookingDetailSum.CreatedBy.CompanyName,
          TourName: BookingDetailSum.Title,
          TourPrice: BookingDetailSum.TourTotalPrice, //BookingDetailSum.FixedPackage.BookingCommission.TotalPriceForCustomer,
          TourPaid: BookingDetailSum.TotalPayed,
          CurrencyId: BookingDetailSum.CurrencyId,
          PaymentTermCompany: BookingDetailSum.PaymentTerms
            ? BookingDetailSum.PaymentTerms
            : [],
          Payment: BookingDetailSum.PaymentTerms.map(data => {
            return {
              PaymentPercentage: data.PaymentPercentage,
              IsPayed: data.IsPayed,
            };
          }),
          Status: BookingDetailSum.Status,
          CompanyCode: BookingDetailSum.CreatedBy.CompanyCode,
        };
      }
    }
    return ManualPaymentObject;
  };

  demoManualPayment = data => {
    const { BookingDetailSum } = data;
    let ManualPaymentObject = this.generateManualPayment(BookingDetailSum);
    if (!_.isEmpty(ManualPaymentObject)) {
      this.props.postDemoManualPaymentAction(ManualPaymentObject);
    }
  };

  setStateTransactionDetail = data => {
    const { BookingDetailSum } = data;
    let ManualPaymentObject = this.generateManualPayment(BookingDetailSum);

    this.setState({
      listTransactionDetail: ManualPaymentObject,
    });
    this.getTotalMandatory();
    if (!_.isEmpty(ManualPaymentObject)) {
      this.props.postDemoManualPaymentAction(ManualPaymentObject);
    }
    this.props.getPaymentTermByTransactionIdAction(BookingDetailSum.Id);
    this.props.getCustomerProfileByCodeAction(
      BookingDetailSum.CreatedBy.CompanyCode
    );
    this.props.getSpecialAdjusmentByTransactionIdAction(BookingDetailSum.Id);
    this.props.getPaymentMethodByCountryIdAction(BookingDetailSum.Country.Id);
    this.props.getTourTransactioBillsAction(BookingDetailSum.Id);
    this.props.getTourTransactionBilledAction(BookingDetailSum.Id);
    this.getTotalPayable();
  };
  getTotalPayable = () => {
    const data = this.state.AdditonalItem;
    let total = data.reduce((total, num) => {
      return total + num.MinimumPayable;
    }, 0);
    return this.setState({ totalPayable: total });
  };

  handleChangeAdditionalItem = (e, { value, name }) => {
    this.handleChangeAdditionalItemAmount(value, name);
  };

  setAdditionalItemPay = data => {
    let total = data.reduce((total, num) => {
      return total + num.MinimumPayable;
    }, 0);
    return this.setState({
      totalAdditionalItemPay: total,
    });
  };

  openModalBalanceDetail = () => {
    const {
      tourTransactionConfirmationObject,
      manualPaymentDemo,
      TotalPayableAdditionalItem,
    } = this.state;

    const manual = ['Deposit'];
    const paymentGateway = this.state.paymentMethodByCountryId;
    let filteredKeywords = paymentGateway.filter(
      word => !manual.includes(word)
    );
    const Payable =
      tourTransactionConfirmationObject.tourTransactionBilled +
      TotalPayableAdditionalItem;
    const CompanyBalance = manualPaymentDemo.CompanyBalance;
    let Amount = 0;
    if (Payable > CompanyBalance) {
      Amount = Payable - CompanyBalance;
    } else {
      Amount = 0;
    }
    this.setState({
      modalBalanceDetail: true,
      listPaymentgateway: filteredKeywords.map(type => {
        return {
          name: type,
          Id: type === 'Deposit' ? 0 : 1,
        };
      }),
      balanceDetail: {
        ...this.state.balanceDetail,
        Value: Amount,
        DateTime: moment(new Date()).format('YYYY-MM-DD'),
      },
    });
  };

  handleChangeManualPayment = (value, name) => {
    const tourTransactionConfirmationObject = this.state
      .tourTransactionConfirmationObject;
    tourTransactionConfirmationObject[name] = Number(value);
    this.setState({
      tourTransactionConfirmationObject: tourTransactionConfirmationObject,
    });
  };

  handleSubmitTourTransactionConfirmation = () => {
    const {
      additionalItemPay,
      manualPaymentDemo,
      totalPayableValue,
    } = this.state;

    const balance = manualPaymentDemo.CompanyBalance;
    if (
      totalPayableValue === '0' ||
      totalPayableValue <= 0 ||
      totalPayableValue === ''
    ) {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: 'Amount invalid',
        modalConfirmation: true,
      });
    } else if (totalPayableValue <= balance) {
      this.setState({
        loadingConfirm: true,
        loadingHistoryTransactionCustomer: true,
      });
      let paymentSupplement = [];
      additionalItemPay.map(pay => {
        let newData = {
          PaymentValue: 0,
          Id: pay.Id,
          Qty: pay.Qty,
        };
        return paymentSupplement.push(newData);
      });
      let data = {
        PaymentChoice: 'Deposit',
        Id: this.state.listTransactionDetail.Id,
        Confirmation: 'Confirm',
        SupplementPayment: paymentSupplement,
        CustomValue: totalPayableValue,
      };
      this.props.postConfirmTransaction(data);
    } else if (balance < totalPayableValue) {
      this.openModalBalanceDetail();
    } else {
      this.setState({
        loadingConfirm: true,
        loadingHistoryTransactionCustomer: true,
      });
      let paymentSupplement = [];
      additionalItemPay.map(pay => {
        let newData = {
          PaymentValue: pay.MinimumPayable,
          Id: pay.Id,
          Qty: pay.Qty,
        };
        return paymentSupplement.push(newData);
      });
      let data = {
        ...this.state.tourTransactionConfirmationObject,
        PaymentChoice: 'Deposit',
        Id: this.state.listTransactionDetail.Id,
        Confirmation: 'Confirm',
        SupplementPayment: paymentSupplement,
        CustomValue: totalPayableValue,
      };
      this.props.postConfirmTransaction(data);
    }
  };

  handleChangeBalaceDetail = (e, { value, name }) => {
    const balanceDetail = this.state.balanceDetail;
    balanceDetail[name] = value;
    this.setState({ balanceDetail: balanceDetail });
  };

  validateBalance = () => {
    let isError = false;
    const errors = {
      ValueError: '',
    };
    if (this.state.balanceDetail.Value <= 0) {
      isError = true;
      errors.ValueError = 'Amount invalid';
    }
    this.setState({
      errors: {
        ...errors,
      },
    });
    return isError;
  };
  saveBalanceDetail = (e, { name, id, value }) => {
    const err = this.validateBalance();
    const data = this.state.balanceDetail;
    if (!err) {
      if (name === 'ManualPayment') {
        this.setState({ loadingPayment: true });
        this.props.postBalanceDetailAction(data);
      } else if (name === 'postPaymentGateway') {
        this.setState({
          loadingPayment: false,
          openConfirmPayment: true,
          contentConfirmPaymet:
            'Are you sure to make payments using ' + value + ' ?',
          IdForPayment: id,
        });
      } else {
        this.setState({ modalPaymentGateway: true });
      }
    }
  };
  confirmPayment = () => {
    const { IdForPayment, balanceDetail } = this.state;
    const dataPaymentgateway = {
      PaymentGateway: IdForPayment,
      Amount: balanceDetail.Value,
      CompanyCode: balanceDetail.CompanyCode,
      CurrencyId: balanceDetail.CurrencyId,
    };
    this.setState({
      openConfirmPayment: false,
      loadingPayment: true,
    });
    this.props.postTopUpPaymentGatewayAction(dataPaymentgateway);
  };

  collapseButton = () => {
    this.setState({ openCollapse: !this.state.openCollapse });
  };

  handleCloseModalBalance = () => this.setState({ modalBalanceDetail: false });

  setStateBalanceDetail = data => {
    const { Code, Currency, Name, Balance } = data;
    let balanceDetail = {
      CompanyCode: Code ? Code : '',
      CashFlowType: 'Credit',
      CurrencyId: Currency ? Currency : '',
      Value: 0,
      Reference: '',
      Description: '',
      PaymentMethod: 0,
      Name: Name ? Name : '',
      Balance: Balance ? Balance : 0,
      DateTime: moment(new Date()).format('YYYY-MM-DD'),
    };
    this.setState({ balanceDetail: balanceDetail });
  };

  onChangeAmount = (value, name) => {
    const balanceDetail = this.state.balanceDetail;
    balanceDetail[name] = value;
    this.setState({ balanceDetail: balanceDetail });
  };

  handleCloseConfirmPayment = () => {
    this.setState({
      openConfirmPayment: false,
      loadingPayment: false,
    });
  };

  handleChangeAdditionalItemAmount = (value, name) => {
    let index = name.split(',')[1];
    let newName = name.split(',')[0];
    let AdditonalItem = this.state.additionalItemPay;
    let AdditionalItemCopy = [...AdditonalItem];
    AdditionalItemCopy[index] = {
      ...AdditionalItemCopy[index],
      [newName]: Number(value),
    };
    this.setAdditionalItemPay(AdditionalItemCopy);
    this.setAdditionalInfoPrice(AdditionalItemCopy);
    this.setState({
      additionalItemPay: AdditionalItemCopy,
    });
  };

  onClickPassIcon = (e, { name }) => {
    let icon = `${name}Icon`;
    let show = `${name}Show`;
    if (this.state[show]) {
      this.setState({
        [icon]: 'eye slash',
        [show]: false,
        typePassword: 'password',
      });
    } else {
      this.setState({
        [icon]: 'eye',
        [show]: true,
        typePassword: 'text',
      });
    }
  };
  onClickConfirmPassIcon = (e, { name }) => {
    let icon = `${name}Icon`;
    let show = `${name}Show`;
    if (this.state[show]) {
      this.setState({
        [icon]: 'eye slash',
        [show]: false,
        typeConfirmPassword: 'password',
      });
    } else {
      this.setState({
        [icon]: 'eye',
        [show]: true,
        typeConfirmPassword: 'text',
      });
    }
  };

  handleReloadPage = () => {
    window.location.reload();
  };

  handleClosePayment = () => {
    this.setState({
      openModalPayment: false,
    });
  };

  getPayable = () => {
    const { tourTransactionConfirmationObject } = this.state;
    this.setState({
      totalPayableValue:
        tourTransactionConfirmationObject.tourTransactionBilled,
    });
  };
  handleChangePayable = value => {
    this.setState({
      totalPayableValue: value,
    });
  };

  getTotalMandatory = () => {
    const data = this.state.AdditonalItem;
    let total = data.reduce((total, data) => {
      return data.IsMandatory ? total + data.MinimumPayable : total;
    }, 0);
    return this.setState({ totalMandatory: total });
  };

  // ------ cancellation reason
  handleSaveCancellationReason = data => {
    this.setState({
      CancellationReasonId: data.CancellationReasonId,
      CancellationReasonNote: data.CancellationReasonNote,
      openSendManualEmail: true,
    });
  };

  handleCloseCancellationReason = () => {
    this.setState({ openModalCancelBooking: false });
  };

  handleCloseManualSendEmail = () => {
    this.setState({ openSendManualEmail: false });
  };

  handleSendManualEmail = value => {
    this.setState({ isSendMail: value === 1 ? true : false });
  };

  render() {
    let {
      modalConfirmation,
      loadingConfirm,
      loadingAdditionalItem,
      loadingManualDemo,
      loadingTransactionDetail,
      loadingPayment,
      typeCustomer,
      passwordIcon,
      typePassword,
      confirmPasswordIcon,
      typeConfirmPassword,
      isSales,
      isFinance,
    } = this.state;

    const {
      getTourTransactionBilled,
      getPayableSupplemetItem,
      loadingUserStore,
    } = this.props;
    let loadingModal =
      loadingAdditionalItem ||
      loadingManualDemo ||
      loadingTransactionDetail ||
      loadingPayment;
    return (
      <div>
        <CustomerProfileDetail
          contentDetail={this.state.customerProfile}
          buttonBack={this.buttonBack}
          balanceDetail={this.balanceDetail}
          setMargin={this.setMargin}
          numberEmploye={this.props.getCustomEnumByCatagoriesTotalEmploye}
          revenueRange={this.props.getCustomEnumByCatagoriesRevenueRange}
          branch={this.props.getCustomEnumByCatagoriesTotalBranch}
          currencies={this.props.getCurrencies}
          handleChange={this.onChange}
          disable={isFinance ? true : this.state.disable}
          buttonEdit={this.saveCustomer}
          contentButton={this.state.contentButton}
          listCountry={this.props.listCountry}
          getPaymentSchemeList={this.props.getPaymentSchemeList}
          errorInformation={this.state.errors}
          saveCustomer={this.saveCustomer}
          handleCheckClick={this.handleCheckClick}
          modalImage={this.modalImage}
          listCompanyBussiness={this.state.CompanyBussiness}
          tabMenu={this.state.TabMenu}
          loadingCustomerProfile={this.state.loadingCustomerProfile}
          isNewForm={this.state.isNewForm}
          setCommission={this.setCommission}
          typeCustomer={typeCustomer}
          accountManagerList={this.props.getAccountManager}
          isHideMargin={isSales || isFinance}
          isHideSave={isSales}
          isHideBalance={isSales}
          isHideCommission={isFinance}
          isFinance={isFinance}
        />
        <ModalAlert
          openModal={modalConfirmation}
          handleClose={this.handleCloseAlert}
          header={this.state.headerAlert}
          content={this.state.contentAlert}
          nameButton="OK"
        />
        <ModalImage
          openModal={this.state.openModalImage}
          handleClose={this.handleCloseModalImage}
          content={this.state.contentModal}
          imagePreview={this.state.imageUrl}
          handleChange={this.onChangeImage}
          addImage={this.addImage}
        />

        <ModalResetPassword
          openModal={this.state.openModalResetPassword}
          header="Reset Password"
          handleClose={this.handleCloseResetPassword}
          nameButton="Oke"
          contentDetail={this.state.resetPasswordObject}
          CompanyCode={this.state.customerProfile.CompanyId}
          handleChange={this.handleChangeResetPassword}
          saveResetPassword={this.saveResetPassword}
          errors={this.state.errorResetPassword}
          loading={loadingUserStore}
          onClickPassIcon={this.onClickPassIcon}
          onClickConfirmPassIcon={this.onClickConfirmPassIcon}
          passwordIcon={passwordIcon}
          typePassword={typePassword}
          confirmPasswordIcon={confirmPasswordIcon}
          typeConfirmPassword={typeConfirmPassword}
        />

        <ModalDetailBooking
          openModal={this.state.openModalDetailBooking}
          handleClose={this.handleCloseDetailBooking}
          header="Detail Booking"
          contentDetail={this.state.listTransactionDetail}
          getPayableSupplemetItem={getPayableSupplemetItem}
          actionBookingConfirmation={this.bookingAction}
          loadingModal={loadingModal}
          openModalSpecialAdjusment={this.buttonOpenModalSpecialAdjusment}
          handlePrintSchedule={this.handlePrintSchedule}
          handleDownloadInvoice={this.handleDownloadInvoice}
          loadingSpecialAdjusment={this.state.loadingSpecialAdjusment}
          loadingInvoice={this.state.loadingInvoice}
        />
        <ModalSpecialAdjusment
          openModal={this.state.openModalSpecialAdjusment}
          handleClose={this.handleCloseSpecialAdjusment}
          header="Detail Special Adjustment"
          contentDetail={this.state.listTransactionDetail}
          listData={this.state.specialAdjusmentObject}
          addNewSpecialAjusment={this.addNewSpecialAjusment}
          handleChangeSpecialAdjusment={this.handleChangeSpecialAdjusment}
          handleChangeCostSpecialAdjusment={
            this.handleChangeCostSpecialAdjusment
          }
          deleteSpecialAdjusment={this.deleteSpecialAdjusment}
          currenciesList={this.props.currencies}
          saveSpecialAdjusment={this.saveSpecialAdjusment}
        />
        <ModalConfirm
          openModal={this.state.openConfirmBooking}
          size="mini"
          close={this.handleCloseConfirmBooking}
          confirm={this.confirmBooking}
          message={this.state.contentAlertBooking}
        />
        <ModalManualPayment
          open={this.state.modalManualPayment}
          handleClose={this.handleCloseModalPayment}
          contentDetail={this.state.listTransactionDetail}
          contentDetailDemoManualPayment={this.state.manualPaymentDemo}
          contentMessageManualPayment={this.state.contentMessageManualPayment}
          additionalItem={this.state.additionalItemPay}
          paymentPercentage={this.state.PaymentPercentage}
          totalPayableAdditionalItem={this.state.TotalPayableAdditionalItem}
          disabled={this.state.disabledManualPayment}
          nameButton="OK"
          handleChangeManualPaymentAmount={this.handleChangeManualPayment}
          handleSubmitTourTransactionConfirmation={
            this.handleSubmitTourTransactionConfirmation
          }
          openModalBalanceDetail={this.openModalBalanceDetail}
          contentMessage={this.state.contentMessage}
          loadingModal={loadingModal}
          tourTransactionConfirmationObject={
            this.state.tourTransactionConfirmationObject
          }
          tourTransactionBilled={getTourTransactionBilled}
          specialAdjusment={this.state.specialAdjusmentObject}
          totalPayable={this.state.totalPayable}
          handleChangeAdditionalItem={this.handleChangeAdditionalItem}
          getPayableSupplemetItemStatic={getPayableSupplemetItem}
          additionalItemPay={this.state.totalAdditionalItemPay}
          loadingButton={loadingConfirm}
          handleChangeAdditionalItemAmount={
            this.handleChangeAdditionalItemAmount
          }
          //tambahan
          totalMandatory={this.state.totalMandatory}
          totalNotMandatory={this.state.totalNotMandatory}
          handleChangePayable={this.handleChangePayable}
          totalPayableValue={this.state.totalPayableValue}
        />
        {/* <ModalPayment
          open={openModalPayment}
          handleClose={this.handleClosePayment}
          reloadPage={this.handleReloadPage}
          transactionId={transactionId}
        /> */}
        <ModalBalanceDetail
          open={this.state.modalBalanceDetail}
          handleClose={this.handleCloseModalBalance}
          header="Credit"
          handleChange={this.handleChangeBalaceDetail}
          contentField={this.state.balanceDetail}
          errorInformation={this.state.errors}
          saveBalanceDetail={this.saveBalanceDetail}
          contentDetailDemoManualPayment={this.state.manualPaymentDemo}
          paymentPercentage={this.state.PaymentPercentage}
          TotalPayableAdditionalItem={this.state.TotalPayableAdditionalItem}
          contentButton="Top Up"
          currencies={this.props.getCurrencies}
          handleChangeAmount={this.onChangeAmount}
        />
        <ModalPaymentGateway
          open={this.state.modalPaymentGateway}
          handleClose={this.handleClose}
          header="Choose Payment Gateway"
          handleChange={this.handleChangeBalaceDetail}
          saveBalanceDetail={this.saveBalanceDetail}
          listPaymentMethod={this.state.listPaymentgateway}
          openCollapse={this.state.openCollapse}
          collapseButton={this.collapseButton}
          loadingModal={loadingPayment}
        />
        <ModalConfirm
          openModal={this.state.openConfirmPayment}
          size="mini"
          close={this.handleCloseConfirmPayment}
          confirm={this.confirmPayment}
          message={this.state.contentConfirmPaymet}
        />
        <ModalIframePaymentGateway
          open={this.state.openModalIframePaymentGateway}
          handleClose={this.handleCloseIframe}
          url={this.state.iframePaymentGatewayObject.Url}
        />
        <ModalCancellationReason
          openModal={this.state.openModalCancelBooking}
          handleSave={this.handleSaveCancellationReason}
          handleClose={this.handleCloseCancellationReason}
          loadingPostCancel={this.state.loadingPostCancel}
        />
        <ModalManualSendEmail
          openModal={this.state.openSendManualEmail}
          size="tiny"
          close={this.handleCloseManualSendEmail}
          confirm={this.confirmBooking}
          isChooseSendEmail={this.state.isChooseSendEmail}
          handleSendManualEmail={this.handleSendManualEmail}
          isSendMail={this.state.isSendMail}
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    getCustomerProfileByCode:
      state.CustomerProfileStore.getCustomerProfileByCode,
    getCustomerProfileByCodeStatus:
      state.CustomerProfileStore.getCustomerProfileByCodeStatus,
    getUserDataByCode: state.CustomerProfileStore.getUserDataByCode,
    getUserDataByCodeStatus: state.CustomerProfileStore.getUserDataByCodeStatus,
    getHistoryTransactionByCode:
      state.CustomerProfilePageStore.getHistoryTransactionByCode,
    getHistoryTransactionByCodeStatus:
      state.CustomerProfilePageStore.getHistoryTransactionByCodeStatus,
    getCustomEnumByCatagoriesTotalEmploye: state.CustomEnumReducerStore.getCustomEnumByCatagoriesTotalEmploye.map(
      type => {
        return { text: type.Value, value: type.Id, key: type.Id };
      }
    ),
    getCustomEnumByCatagoriesTotalBranch: state.CustomEnumReducerStore.getCustomEnumByCatagoriesTotalBranch.map(
      type => {
        return { text: type.Value, value: type.Id, key: type.Id };
      }
    ),
    getCustomEnumByCatagoriesRevenueRange: state.CustomEnumReducerStore.getCustomEnumByCatagoriesRevenueRange.map(
      type => {
        return { text: type.Value, value: type.Id, key: type.Id };
      }
    ),
    getCurrencies: state.currenciesStore.currencies.map(type => {
      return { text: type.Id, value: type.Id, key: type.Id };
    }),
    listCountry: state.CountryStore.getCountryList.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    getPaymentSchemeList: state.CustomerProfileStore.getPaymentSchemeList.map(
      type => {
        return { text: type.Description, value: type.Id, key: type.Id };
      }
    ),
    putCustomerProfileStatus:
      state.CustomerProfileStore.putCustomerProfileStatus,
    postCustomerProfileStatus:
      state.CustomerProfileStore.postCustomerProfileStatus,
    postCustomerProfile: state.CustomerProfileStore.postCustomerProfile,
    postUnlockedUserStatus: state.UserAccessStore.postUnlockedUserStatus,
    getUserProfileByUserIdStatus:
      state.UserAccessStore.getUserProfileByUserIdStatus,
    getUserProfileByUserId: state.UserAccessStore.getUserProfileByUserId,
    postResetPasswordStatus: state.UserAccessStore.postResetPasswordStatus,
    postResetPassword: state.UserAccessStore.postResetPassword,
    getUserRoleListStatus: state.UserStore.getUserRoleListStatus,
    getUserRoleList: state.UserStore.getUserRoleList.map(type => {
      return {
        text: type,
        value: type,
        key: type,
      };
    }),
    postUserStatus: state.UserStore.postUserStatus,
    postUser: state.UserStore.postUser,
    getUserNotEmployee: state.UserStore.getUserNotEmployee.map(data => {
      return {
        text: data.Username,
        value: data.UserId,
        key: data.UserId,
      };
    }),
    postUserProfileStatus: state.UserStore.postUserProfileStatus,
    postUserProfile: state.UserStore.postUserProfile,
    putUserProfile: state.UserStore.putUserProfile,
    putUserProfileStatus: state.UserStore.putUserProfileStatus,
    errorsUser: state.UserStore.errors.global,
    getPrintPaymentReceiptStatus:
      state.TourTransactionStore.getPrintPaymentReceiptStatus,
    getPrintPaymentReceipt: state.TourTransactionStore.getPrintPaymentReceipt,
    getInvoiceByCompanyCodeStatus:
      state.TourTransactionStore.getInvoiceByCompanyCodeStatus,
    getInvoiceByCompanyCode: state.TourTransactionStore.getInvoiceByCompanyCode,
    errorMessageTourTransaction: state.TourTransactionStore.errors.message,
    TransactionDetails: state.TourTransactionStore.listTransactionDetails,
    TransactionDetailsStatus:
      state.TourTransactionStore.listTransactionDetailsStatus,
    getPayableSupplemetItem: state.TourTransactionStore.getPayableSupplemetItem,
    getTourTransactionBilled:
      state.TourTransactionStore.getTourTransactionBilled,
    getTourTransactionBilledStatus:
      state.TourTransactionStore.getTourTransactionBilledStatus,
    getAccountManager: state.CustomerProfileStore.getAccountManager.map(
      type => {
        return {
          text: type.FirstName + ' ' + type.LastName,
          value: type.Id,
          key: type.Id,
        };
      }
    ),
    getTourTransactionBills: state.TourTransactionStore.getTourTransactionBills,
    getTourTransactionBillsStatus:
      state.TourTransactionStore.getTourTransactionBillsStatus,
    postDemoManualPayment: state.TourTransactionStore.postDemoManualPayment,
    postDemoManualPaymentStatus:
      state.TourTransactionStore.postDemoManualPaymentStatus,
    getPayableSupplemetItemStatus:
      state.TourTransactionStore.getPayableSupplemetItemStatus,
    postTransactionConfirmationStatus:
      state.TourTransactionStore.postTransactionConfirmationStatus,
    getPaymentMethodByCountryIdStatus:
      state.CustomEnumReducerStore.getPaymentMethodByCountryIdStatus,
    getPaymentMethodByCountryId:
      state.CustomEnumReducerStore.getPaymentMethodByCountryId,
    postBalanceDetailStatus: state.CustomerProfileStore.postBalanceDetailStatus,
    postTopUpUsingPaymentGatewayStatus:
      state.CustomEnumReducerStore.postTopUpUsingPaymentGatewayStatus,
    postTopUpUsingPaymentGateway:
      state.CustomEnumReducerStore.postTopUpUsingPaymentGateway,
    errorMessageTopUp: state.CustomEnumReducerStore.errors.message,
    loadingUserStore: state.UserAccessStore.loading,
    errorsTourTransaction: state.TourTransactionStore.errors,
    userRoles: state.UserAccessStore.getUserRoleByBearer,
  };
}

export default connect(mapStateToProps, {
  resetCustomerProfileAction,
  resetCustomerProfilePageAction,
  getCustomerProfileByCodeAction,
  putCustomerProfileAction,
  getHistoryTransactionByCodeAction,
  getSupervisorByCompanyCodePositionIdAction,
  getUserDataByCodeAction,
  resetCustomEnumAction,
  getCustomEnumByCatagoriesRevenueRangeAction,
  getCustomEnumByCatagoriesTotalBranchAction,
  getCustomEnumByCatagoriesTotalEmployeAction,
  getCurrenciesAction,
  getCountryListAction,
  getPaymentSchemeAction,
  postCustomerProfileAction,
  postUnlockUserAction,
  resetUserAccessAction,
  getUserProfileByUserIdAction,
  postResetPasswordAction,
  postUserAction,
  postUserProfileAction,
  resetUserInfoAction,
  getUserRoleListAction,
  getInvoiceStatusUsingPaymentGatewayAction,
  resetStatusTransactionAction,
  putUserProfileAction,
  getInvoiceByCompanyCodeAction,
  getPrintPaymentReceiptTransactionAction,
  getTransactionByIdAction,
  postDemoManualPaymentAction,
  getPaymentTermByTransactionIdAction,
  postConfirmTransaction,
  getSpecialAdjusmentByTransactionIdAction,
  getTourTransactioBillsAction,
  getTourTransactionBilledAction,
  getPayableSupplemetItemAction,
  getAccountManagerAction,
  getPaymentMethodByCountryIdAction,
  postBalanceDetailAction,
  postTopUpPaymentGatewayAction,
  getHistoryTransactionForSalesByCodeAction,
  getUserRoleByBearerAction,
  getCustomerProfileByCodeManageBySalesAction,
  postEmailSendConfirmation,
})(CustomerProfile);
