import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Form, Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ButtonClose from '../../../components/button/buttonCloseModal';
import Input from '../../shared/components/common/input/InputWithLabel';
import { getCityInCountry } from '../../shared/actions/cityAction';
import options from '../../../scripts/convertOption';
import { copyObject } from '../../../scripts/itineraryBuilder/itineraryBuilder';
import {
  SUPPLIER,
  ERRORS_SUPPLIER,
  TRANSPORTATION,
  ACCOMMODATION,
  validationSupplier,
  dataApiForGetAccommodation,
  ATTRACTION,
  dataApiForGetAttraction,
  RESTAURANT,
  dataApiForGetRestaurant,
  dataApiForGetTransportation,
  dataApiForGetAccommodationItem,
} from '../scripts/supplier';
import {
  getAccomodationEstimatePrice,
  getAccommodationItemEstimatePrice,
} from '../../shared/actions/accommodationAction';
import { getAttractionEstimatePrice } from '../../shared/actions/excursionAction';
import { getRestaurantEstimatePrice } from '../../../actions/restaurantAction';
import { getTransportationEstimatePrice } from '../../shared/actions/transportationAction';
import TableAccomodationList from '../../accommodation/accommodationList/components/TableAccommodationList';
import headerTabelAccomodation from '../../accommodation/constant/tableHeader';
import LoaderModal from '../../../components/loader/loaderInModal';
import ModalSupplierItem from './ModalSupplierItem';
import TabelExcursionList from '../../excurtion/excursionList/components/TableExcursionList';
import {
  HeaderTabelExcursion,
  HeaderRestaurantList,
} from '../../../components/table/headerTabel';
import TableRestaurantList from '../../restaurant/restaurantList/components/TableRestaurantList';
import TableTransportationList from '../../transportation/transportationList/components/table/tableTransportationList';
import ModalAlert from '../../shared/components/modal/ModalAlert';
import ModalSupplierService from './ModalSupplierService';

const ModalSupplier = props => {
  const [supplier, setSupplier] = useState(copyObject(SUPPLIER));
  const [errors, setErrors] = useState(copyObject(ERRORS_SUPPLIER));
  const [modalSupplierItem, setModalSupplierItem] = useState({ isOpen: false });
  const [modalAlert, setModalAlert] = useState({
    isOpen: false,
    header: '',
    message: '',
  });
  const [modalSupplierService, setModalSupplierService] = useState({
    isOpen: false,
  });
  // ============ Action =================
  const dispatch = useDispatch();
  const getCityInCountryAction = () => dispatch(getCityInCountry());
  const getAccomodationEstimatePriceAction = param =>
    dispatch(getAccomodationEstimatePrice(param));
  const getAccommodationItemEstimatePriceAction = param =>
    dispatch(getAccommodationItemEstimatePrice(param));
  const getAttractionEstimatePriceAction = param =>
    dispatch(getAttractionEstimatePrice(param));
  const getRestaurantEstimatePriceAction = param =>
    dispatch(getRestaurantEstimatePrice(param));
  const getTransportationEstimatePriceAction = param =>
    dispatch(getTransportationEstimatePrice(param));
  // ============ End Action =================
  //   ============ reducer ====================
  const listCitiesInCountry = useSelector(state =>
    state.CityStore.getCityInCountryList.map(e =>
      options(e.Id, e.Id, `${e.Name}, ${e.Region.Name}, ${e.Country.Name}`)
    )
  );
  const loadingGetCities = useSelector(state => state.CityStore.loading);
  const dataAllotment = useSelector(
    state => state.ResourceBooking.dataAllotment
  );
  const accommodationEstimatePrice = useSelector(state => {
    let accommAndFilter = state.AccomodationStore.accommodationEstimatePrice;
    return accommAndFilter ? accommAndFilter.AccommodationResults : [];
  });
  const loadingGetAccommodationEstimatePrice = useSelector(
    state => state.AccomodationStore.loadingGetAccommodationEstimatePrice
  );
  const listAccommodationItemEstimatePrice = useSelector(
    state => state.AccomodationStore.listAccommodationItemEstimatePrice
  );
  const loadingGetAccommodationItemEstimatePrice = useSelector(
    state => state.AccomodationStore.loadingGetAccommodationItemEstimatePrice
  );
  const listAttractionEstimatePrice = useSelector(
    state => state.ExcursionStore.listAttractionEstimatePrice
  );
  const loadingGetAttractionEstimatePrice = useSelector(
    state => state.ExcursionStore.loadingGetAttractionEstimatePrice
  );
  const listRestaurantEstimatePrice = useSelector(
    state => state.RestaurantStore.restaurantEstimatePrice
  );
  const loadingGetRestaurantEstimatePrice = useSelector(
    state => state.RestaurantStore.loadingGetRestaurantEstimatePrice
  );
  const loadingGetTransportEstimatePrice = useSelector(
    state => state.TransportationStore.loadingGetTransportEstimatePrice
  );
  const listTransportationEstimatePrice = useSelector(
    state => state.TransportationStore.listTransportationEstimatePrice
  );
  // ============== end reducer ================
  useEffect(() => {
    getCityInCountryAction();
    // eslint-disable-next-line
  }, []);
  const handleChange = (e, { name, value }) => {
    setSupplier({ ...supplier, [name]: value });
  };
  const handleSearchSupplier = () => {
    let validation = validationSupplier({
      supplier,
      supplierType: props.resource.ResourceType.split('-')[0],
    });
    if (validation.isError) setErrors(validation.errors);
    else {
      setSupplier({ ...supplier, showList: true });
      setErrors(copyObject(ERRORS_SUPPLIER));
      getSupplier();
    }
  };
  const getSupplier = () => {
    let { resource } = props;
    let resourceType = resource.ResourceType.split('-')[0];
    if (resourceType === ACCOMMODATION) {
      let data = dataApiForGetAccommodation(supplier, resource, dataAllotment);
      getAccomodationEstimatePriceAction(data).catch(err => {
        openCloseModalAlert(true, 'Failed', err.response.data.Message);
      });
    } else if (resourceType === ATTRACTION) {
      let data = dataApiForGetAttraction(supplier, resource, dataAllotment);
      getAttractionEstimatePriceAction(data).catch(err => {
        openCloseModalAlert(true, 'Failed', err.response.data.Message);
      });
    } else if (resourceType === RESTAURANT) {
      let data = dataApiForGetRestaurant(supplier, resource, dataAllotment);
      getRestaurantEstimatePriceAction(data).catch(err => {
        openCloseModalAlert(true, 'Failed', err.response.data.Message);
      });
    } else if (resourceType === TRANSPORTATION) {
      let data = dataApiForGetTransportation(supplier, resource, dataAllotment);
      getTransportationEstimatePriceAction(data).catch(err => {
        openCloseModalAlert(
          true,
          'Failed',
          err.response
            ? err.response.data
              ? err.response.data.Message
              : 'Failed get transportation'
            : 'Failed get transportation'
        );
      });
    }
  };
  const openCloseModalAlert = (isOpen, header, message) => {
    setModalAlert({
      isOpen: isOpen === true,
      header: isOpen === true ? header : '',
      message: isOpen === true ? message : '',
    });
  };
  const handleChooseProfile = async (e, { value }) => {
    let resourceType = resource.ResourceType.split('-')[0];
    let newDataSupplier = {
      ...supplier,
      Profile: value,
    };
    setSupplier(newDataSupplier);
    if (resourceType === ATTRACTION) {
      saveResourceName(newDataSupplier);
    } else if (resourceType === ACCOMMODATION) {
      setModalSupplierItem({ isOpen: true });
      getAccommodationItemEstimatePriceAction(
        dataApiForGetAccommodationItem(newDataSupplier, resource, dataAllotment)
      );
    } else if (resourceType === TRANSPORTATION) {
      setModalSupplierService({ isOpen: true });
    } else if (resourceType === RESTAURANT) {
      setModalSupplierItem({ isOpen: true });
    }
  };
  const saveResourceName = dataSupplier => {
    let resourceType = resource.ResourceType.split('-')[0];
    if (resourceType === ATTRACTION) {
      props.handleChooseSupplier({
        ...resource,
        ResourceName: dataSupplier.Profile.Name,
        UnitCost: dataSupplier.Profile.EstimatedTotalPrice.Price,
        Currency: dataSupplier.Profile.EstimatedTotalPrice.CurrencyId,
        ServiceItemId: dataSupplier.Profile.ServiceItemId,
      });
    } else if (resourceType === ACCOMMODATION) {
      props.handleChooseSupplier({
        ...resource,
        ResourceName: `${dataSupplier.Profile.Name} - ${dataSupplier.Item.Name}`,
        UnitCost: dataSupplier.Item.EstimatedTotalPrice.Price,
        Currency: dataSupplier.Item.EstimatedTotalPrice.CurrencyId,
        ServiceItemId: dataSupplier.Item.ServiceItemId,
      });
    } else if (resourceType === RESTAURANT) {
      props.handleChooseSupplier({
        ...resource,
        ResourceName: `${dataSupplier.Profile.Name} - ${dataSupplier.Item.Name}`,
        UnitCost: dataSupplier.Profile.EstimatedTotalPrice.Price,
        Currency: dataSupplier.Profile.EstimatedTotalPrice.CurrencyId,
        ServiceItemId: dataSupplier.Item.ServiceItemId,
      });
    } else if (resourceType === TRANSPORTATION) {
      props.handleChooseSupplier({
        ...resource,
        ResourceName: `${dataSupplier.Profile.Name} - ${dataSupplier.Duration.Name}`,
        UnitCost: dataSupplier.Profile.EstimatedTotalPrice.Price,
        Currency: dataSupplier.Profile.EstimatedTotalPrice.CurrencyId,
        ServiceItemId: dataSupplier.Profile.ServiceItemId,
      });
    }
    handleCloseSupplierService();
    props.handleClose();
  };
  // ================================ handle supplier item =================================

  const handleCloseSupplierItem = () => {
    setModalSupplierItem({ isOpen: false });
  };
  const handleChooseItem = (e, { value }) => {
    let resourceType = resource.ResourceType.split('-')[0];
    let newDataSupplier = {
      ...supplier,
      Item: value,
    };
    setSupplier(newDataSupplier);
    if (resourceType === ACCOMMODATION) {
      setModalSupplierService({ isOpen: true });
    }
    if (resourceType === RESTAURANT) {
      saveResourceName(newDataSupplier);
    }
    handleCloseSupplierItem();
  };
  // ======================== end handle supplier item =======================================
  // ======================= handle supplier service =====================================
  const handleCloseSupplierService = () => {
    setModalSupplierService({ isOpen: false });
  };
  const handleChooseService = serviceAndDuration => {
    let newDataSupplier = {
      ...supplier,
      Service: serviceAndDuration.service,
      Duration: serviceAndDuration.duration,
    };
    setSupplier(newDataSupplier);
    saveResourceName(newDataSupplier);
  };
  // ======================= end handle supplier service =====================================

  let { openModal, handleClose, resource } = props;
  let resourceType = resource.ResourceType.split('-')[0];
  const copyHeaderTableAccomodation = copyObject(headerTabelAccomodation);
  copyHeaderTableAccomodation.splice(-1, 0, 'Estimated Price');
  const copyHeaderTabelExcursion = copyObject(HeaderTabelExcursion);
  copyHeaderTabelExcursion.splice(-1, 0, 'Estimated Price');
  const copyHeaderRestaurantList = copyObject(HeaderRestaurantList);
  copyHeaderRestaurantList.splice(-1, 0, 'Estimated Price');
  const copyHeaderTabelTransportation = [
    'Name',
    'Description',
    'Type',
    'Rating',
    'Estimated Price',
    'Action',
  ];
  return (
    <Modal
      dimmer="blurring"
      inverted
      open={openModal}
      onClose={handleClose}
      size="large"
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        Change Resource <ButtonClose onClose={handleClose} />
      </Modal.Header>
      <Modal.Content>
        <LoaderModal
          loading={
            loadingGetAccommodationEstimatePrice ||
            loadingGetAttractionEstimatePrice ||
            loadingGetRestaurantEstimatePrice ||
            loadingGetTransportEstimatePrice
          }
        />
        <Form>
          <Form.Group widths="equal">
            {resourceType === TRANSPORTATION ? (
              <>
                <Input
                  type="select"
                  label="From"
                  placeholder="From"
                  value={supplier.From}
                  options={listCitiesInCountry}
                  name="From"
                  handleChange={handleChange}
                  validate={errors}
                  loading={loadingGetCities}
                />
                <Input
                  type="select"
                  label="To"
                  placeholder="To"
                  value={supplier.To}
                  options={listCitiesInCountry}
                  name="To"
                  handleChange={handleChange}
                  validate={errors}
                  loading={loadingGetCities}
                />
              </>
            ) : (
              <Input
                type="select"
                label="City"
                placeholder="City"
                value={supplier.CityId}
                options={listCitiesInCountry}
                name="CityId"
                handleChange={handleChange}
                validate={errors}
                loading={loadingGetCities}
              />
            )}
            <Button
              style={{
                height: '40px',
                marginTop: '20px',
              }}
              content="search"
              primary
              onClick={handleSearchSupplier}
            />
          </Form.Group>
        </Form>
        <Grid style={{ marginTop: '10px' }}>
          <Grid.Row>
            <Grid.Column width={16} textAlign="center">
              {!supplier.showList ? (
                'Please enter resource location'
              ) : resourceType === ACCOMMODATION ? (
                <TableAccomodationList
                  listData={accommodationEstimatePrice}
                  headerTable={copyHeaderTableAccomodation}
                  useInResourceBookingList={true}
                  handleSeeRoomRBL={handleChooseProfile}
                />
              ) : resourceType === ATTRACTION ? (
                <TabelExcursionList
                  listData={listAttractionEstimatePrice}
                  headerTable={copyHeaderTabelExcursion}
                  useInResourceBookingList={true}
                  handleChooseProfileForRBL={handleChooseProfile}
                />
              ) : resourceType === RESTAURANT ? (
                <TableRestaurantList
                  listData={listRestaurantEstimatePrice}
                  headerTable={copyHeaderRestaurantList}
                  useInResourceBookingList={true}
                  handleChooseProfileForRBL={handleChooseProfile}
                />
              ) : resourceType === TRANSPORTATION ? (
                <TableTransportationList
                  listData={listTransportationEstimatePrice}
                  headerTable={copyHeaderTabelTransportation}
                  useInResourceBookingList={true}
                  handleChooseProfileForRBL={handleChooseProfile}
                />
              ) : null}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ModalSupplierItem
          openModal={modalSupplierItem.isOpen}
          handleClose={handleCloseSupplierItem}
          resourceType={resourceType}
          accommodationItem={{
            listItem: listAccommodationItemEstimatePrice,
            loading: loadingGetAccommodationItemEstimatePrice,
          }}
          restaurantItem={{
            listItem: supplier.Profile ? supplier.Profile.Menus || [] : [],
            profileId: supplier.Profile ? supplier.Profile.Id : null,
          }}
          handleChooseItem={handleChooseItem}
        />
        <ModalSupplierService
          openModal={modalSupplierService.isOpen}
          handleClose={handleCloseSupplierService}
          resourceType={resourceType}
          accommodationService={
            supplier.Item ? supplier.Item.AccommodationItemServiceTypes : []
          }
          transportService={
            supplier.Profile
              ? supplier.Profile.TransportationItemServiceTypes || []
              : []
          }
          selectedTransportService={resource.Description}
          handleChooseService={handleChooseService}
        />
        <ModalAlert
          openModal={modalAlert.isOpen}
          handleClose={openCloseModalAlert}
          header={modalAlert.header}
          content={modalAlert.message}
        />
      </Modal.Content>
    </Modal>
  );
};
ModalSupplier.propTypes = {
  openModal: PropTypes.bool,
  handleClose: PropTypes.func,
  resource: PropTypes.object,
  handleChooseSupplier: PropTypes.func,
};
export default ModalSupplier;
