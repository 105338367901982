import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Image,
  Container,
  Header,
  Icon,
  Button,
  Segment,
} from 'semantic-ui-react';
import ModalListTourOperator from '../modal/ModalListTourOperator';
import { Style } from '../../../../../../assets/styles/styleTourTransaction';
import {
  getListTourOperatorAction,
  getTourOperatorByIdAction,
  postDemoPrice,
  postDemoJoinTourAction,
} from '../../../../../../actions/tourTransactionAction';
import { connect } from 'react-redux';
import { sumValuesObject } from '../../../../../../scripts/itineraryBuilder/guest';
import {
  validateDailyProgramExcursionTime,
  isAccommodationEmpty,
  transportValidation,
  additionalServicesValidation,
} from '../../../../../../scripts/itineraryBuilder/dailyProgram';
import { generateDemoPrice } from '../../../../../../scripts/itineraryBuilder/demoPrice';
import ModalAlert from '../../../../../../components/modal/modalAlert';
import { convertDataBookNowFixed } from '../../../../../../components/genericFunction/convertPackage';
import { validateEmptyAirport } from '../services/validation/headlineProgramValidation';
class TourOperatorSegment extends Component {
  state = {
    openModalTourOperator: false,
    modalAlert: false,
    messageAlert: '',
  };
  componentDidUpdate(prevProps) {
    if (
      this.props.currentPackage.type !== 'Fixed' &&
      (this.props.currentPackage.status === 'create' ||
        this.props.currentPackage.type === 'Quotation') &&
      (prevProps.dailyProgram !== this.props.dailyProgram ||
        prevProps.onBehalf !== this.props.onBehalf ||
        prevProps.headlineProgram !== this.props.headlineProgram ||
        prevProps.additionalServices !== this.props.additionalServices)
    ) {
      this.getDemoPriceAfterUpdate();
    } else if (
      this.props.currentPackage.type === 'Fixed' &&
      this.isNeedUpdatePriceFixed(prevProps)
    ) {
      this.demoPriceFixedPackage();
    } else if (
      (this.props.currentPackage.status === 'create' ||
        (this.props.currentPackage.status === 'edit' &&
          this.props.currentPackage.type === 'Quotation')) &&
      this.props.isChangeSpecialAdjustment
      // this.isNeedUpdatePriceSpecialAdjusments(prevProps)
    ) {
      this.getDemoPriceAfterUpdate();
    }
    // if (
    //   JSON.stringify(prevProps.requestDemoPrice) !==
    //   JSON.stringify(this.props.requestDemoPrice)
    // ) {
    //   this.getDemoPriceAfterUpdate();
    // }
  }

  isNeedUpdatePriceFixed = prevProps => {
    return (
      this.props.guests &&
      this.props.guests.adults !== 0 &&
      this.props.commission.splitCommision !== null &&
      // eslint-disable-next-line
      this.props.onBehalf.hasOwnProperty('customerCode') &&
      (JSON.stringify(prevProps.guests) !== JSON.stringify(this.props.guests) ||
        JSON.stringify(prevProps.roomAllocation) !==
          JSON.stringify(this.props.roomAllocation) ||
        JSON.stringify(prevProps.commission) !==
          JSON.stringify(this.props.commission) ||
        prevProps.supplements !== this.props.supplements ||
        JSON.stringify(prevProps.onBehalf) !==
          JSON.stringify(this.props.onBehalf))
    );
  };

  // isNeedUpdatePriceSpecialAdjusments = prevProps => {
  //   return (
  //     JSON.stringify(prevProps.specialAdjusments) !==
  //     JSON.stringify(this.props.specialAdjusments)
  //   );
  // };

  demoPriceFixedPackage = () => {
    const { onBehalf } = this.props;
    let {
      currentPackage,
      guests,
      roomAllocation,
      guestList,
      commission,
      supplements,
      isReadyFixedPrice,
      generalInformation,
      specialAdjusments,
    } = this.props;
    let dataBookNow = convertDataBookNowFixed(
      commission.splitCommision,
      commission.printCommision,
      guests,
      roomAllocation,
      supplements,
      guestList
      // generalInformation.tourNote
    );
    dataBookNow.CompanyCode = onBehalf.customerCode;
    dataBookNow.TourNote = generalInformation.tourNote;
    dataBookNow.AdditionalItems = specialAdjusments;
    onBehalf.userId && (dataBookNow.UserProfileId = onBehalf.userId);
    this.props
      .postDemoJoinTourAction(
        currentPackage.id,
        dataBookNow,
        isReadyFixedPrice ? 'VariableDate' : ''
      )
      .then(async () => {
        await this.props.setReqDemoPrice(dataBookNow);
        this.props.setDemoPrice(this.props.demoJoinTour);
      })
      .catch(() => this.props.setDemoPrice(null));
  };

  getDemoPriceObject = () => {
    let {
      headlineProgram,
      dailyProgram,
      roomAllocation,
      guests,
      guestList,
      generalInformation,
      foc,
      additionalServices,
      specialAdjusments,
    } = this.props;
    let {
      tourName,
      groupCapacity,
      firstDestination,
      tourType,
      tourCategory,
      tourNote,
    } = generalInformation;
    let demoPrice = generateDemoPrice(
      guests,
      roomAllocation,
      tourName,
      groupCapacity,
      firstDestination,
      tourType,
      tourCategory,
      headlineProgram,
      dailyProgram,
      guestList,
      foc,
      tourNote,
      additionalServices,
      specialAdjusments
    );
    return demoPrice;
  };

  demoJoinTourFixPrice = operatorId => {
    const { onBehalf, generalInformation } = this.props;
    let {
      currentPackage,
      guests,
      roomAllocation,
      guestList,
      commission,
      supplements,
      isReadyFixedPrice,
      headlineProgram,
      additionalServices,
      specialAdjusments,
    } = this.props;
    let dataBookNow = convertDataBookNowFixed(
      commission.splitCommision,
      commission.printCommision,
      guests,
      roomAllocation,
      supplements,
      guestList,
      additionalServices
      // generalInformation.tourNote
    );
    dataBookNow.StartTour = headlineProgram.Departures[0].Date;
    dataBookNow.EndTour =
      headlineProgram.Returns[headlineProgram.Returns.length - 1].Date;
    dataBookNow.TourOperatorProfileId = operatorId;
    dataBookNow.CompanyCode = onBehalf.customerCode;
    dataBookNow.Title = generalInformation.tourName;
    dataBookNow.AdditionalItems = specialAdjusments;
    // dataBookNow.TourNote = generalInformation.tourNote;
    onBehalf.userId && (dataBookNow.UserProfileId = onBehalf.userId);
    this.props
      .postDemoJoinTourAction(
        currentPackage.id,
        dataBookNow,
        isReadyFixedPrice ? 'VariableDate' : ''
      )
      .then(async () => {
        await this.props.setReqDemoPrice(dataBookNow);
        this.props.setDemoPrice(this.props.demoJoinTour);
      })
      .catch(() => this.props.setDemoPrice(null));
  };
  generateTourOperator = async () => {
    const context = this;
    let totalGuest = this.props.guests ? sumValuesObject(this.props.guests) : 0;
    let validateDailyProgram = validateDailyProgramExcursionTime(
      this.props.dailyProgram
    );
    let validateTransportation = transportValidation(this.props.dailyProgram);
    let validateAdditionalServices = additionalServicesValidation(
      this.props.additionalServices
    );
    let validatePlace = validateEmptyAirport(this.props.headlineProgram);
    if (!this.props.isPackageMangement && totalGuest === 0) {
      this.setState({
        ...this.state,
        modalAlert: true,
        messageAlert:
          'Please check your pax arrangement, at least one adult guest',
      });
    } else if (validatePlace) {
      this.setState({
        ...this.state,
        modalAlert: true,
        messageAlert: 'Please check your Flight, Flight place cannot be empty',
      });
    } else if (validateTransportation) {
      this.setState({
        ...this.state,
        modalAlert: true,
        messageAlert:
          'Please check your Itinerary, something wrong with transportation',
      });
    } else if (validateDailyProgram) {
      this.setState({
        ...this.state,
        modalAlert: true,
        messageAlert:
          'Please check your Itinerary, something wrong with excursion time',
      });
    } else if (validateAdditionalServices) {
      this.setState({
        ...this.state,
        modalAlert: true,
        messageAlert:
          'Please check your additional services, quantity can not be 0',
      });
    } else {
      let demoPrice = this.getDemoPriceObject();
      this.setState(
        {
          openModalTourOperator: true,
        },
        () =>
          this.props.getListTourOperatorAction(demoPrice).catch(err => {
            context.setState({
              ...context.state,
              modalAlert: true,
              messageAlert: err.response.data.Message,
            });
          })
      );
      // this.props.getListTourOperatorAction(demoPrice)
    }
  };
  // .then(() => this.props.setReqDemoPrice(demoPrice))

  setTourOperator = async tourOperator => {
    await this.props.setTourOperator(tourOperator);
    !this.props.isReadyFixedPrice
      ? this.getDemoPrice(tourOperator.Id)
      : this.demoJoinTourFixPrice(tourOperator.Id);
  };

  getDemoPrice = operatorId => {
    let { onBehalf } = this.props;
    let demoPrice = this.getDemoPriceObject();
    demoPrice.TourOperatorId = operatorId;
    demoPrice.CompanyCode = onBehalf ? onBehalf.customerCode : null;
    demoPrice.UserProfileId = onBehalf ? onBehalf.userId : null;
    this.props.setReqDemoPrice(demoPrice);
    this.props
      .postDemoPrice(demoPrice)
      .then(async () => {
        await this.props.setDemoPrice(this.props.demoPrice);
      })
      .catch(() => this.props.setDemoPrice(null));
  };

  getDemoPriceAfterUpdate = () => {
    let { tourOperator, headlineProgram, isReadyFixedPrice } = this.props;
    if (
      tourOperator &&
      isAccommodationEmpty(headlineProgram.MainPrograms) === false
    ) {
      !isReadyFixedPrice
        ? this.props.currentPackage.type === 'Fixed'
          ? this.demoPriceFixedPackage()
          : this.getDemoPrice(tourOperator.Id)
        : this.demoJoinTourFixPrice(tourOperator.Id);
    }
    this.props.changeSpecialAdjustment();
  };

  closeModalTourOperator = () =>
    this.setState({ ...this.state, openModalTourOperator: false });

  handleAlert = () => this.setState({ ...this.state, modalAlert: false });
  renderButtonTourOperator = tourOperator => (
    <Grid.Row>
      <Grid.Column width={16} textAlign="center">
        <h5>Have you finished your arrangement? </h5>
        <Button primary onClick={this.generateTourOperator}>
          {tourOperator ? 'Change Operator' : 'Yes, send me offers'}
        </Button>
      </Grid.Column>
    </Grid.Row>
  );

  render() {
    let {
      tourOperator,
      loadingListTourOperator,
      listTourOperator,
      currentPackage,
      isPackageMangement,
      isReadyFixedPrice,
    } = this.props;
    let { openModalTourOperator, modalAlert, messageAlert } = this.state;
    return (
      <Segment raised fluid>
        <div style={{ margin: '1em' }}>
          <Grid verticalAlign="middle">
            <Grid.Row>
              <Grid.Column width={14}>
                <Header as="h3" style={{ marginTop: '10px' }}>
                  <Icon name="user" style={{ color: '#38af95' }} />
                  Tour Operator
                </Header>
              </Grid.Column>
            </Grid.Row>
            {tourOperator ? (
              <Grid.Row>
                <Grid.Column width={5}>
                  <Image src={tourOperator.ImageUrl} size="small" />
                </Grid.Column>
                <Grid.Column width={11}>
                  <Container content="Tour Operator Name" />
                  <label style={Style.labelTourTransaction}>
                    : {tourOperator.Name}
                  </label>
                  <Container content="Email" />
                  <label style={Style.labelTourTransaction}>
                    : {tourOperator.Email}
                  </label>
                  <Container content="Telephone" />
                  <label style={Style.labelTourTransaction}>
                    : {tourOperator.Telephone}
                  </label>
                </Grid.Column>
              </Grid.Row>
            ) : null}
            {isPackageMangement
              ? this.renderButtonTourOperator(tourOperator)
              : (currentPackage.type === 'Quotation' ||
                  currentPackage.status === 'create') &&
                currentPackage.type !== 'Fixed' &&
                !currentPackage.disabledEdit
              ? this.renderButtonTourOperator(tourOperator)
              : null}
            <ModalListTourOperator
              openModal={openModalTourOperator}
              handleClose={this.closeModalTourOperator}
              loadingListTourOperator={loadingListTourOperator}
              listTourOperator={listTourOperator}
              setTourOperator={this.setTourOperator}
              isReadyFixedPrice={isReadyFixedPrice}
            />
            <ModalAlert
              openModal={modalAlert}
              header="Failed"
              handleClose={this.handleAlert}
              content={messageAlert}
              nameButton="OK"
            />
          </Grid>
        </div>
      </Segment>
    );
  }
}

TourOperatorSegment.propTypes = {
  tourOperator: PropTypes.shape({
    Address: PropTypes.string,
    CityName: PropTypes.string,
    Company: PropTypes.shape({
      Code: PropTypes.string,
      Name: PropTypes.string,
    }),
    Email: PropTypes.string,
    Id: PropTypes.number,
    ImageUrl: PropTypes.string,
    Name: PropTypes.string,
    RegionName: PropTypes.string,
    Telephone: PropTypes.string,
    TourOperatorProfileImages: PropTypes.array,
  }),
  getListTourOperatorAction: PropTypes.func,
  getTourOperatorByIdAction: PropTypes.func,
  loadingListTourOperator: PropTypes.bool,
  listTourOperator: PropTypes.array,
  setTourOperator: PropTypes.func,
  loadingTourOperator: PropTypes.bool,
  currentPackage: PropTypes.object,
  guests: PropTypes.object,
  dailyProgram: PropTypes.array,
  headlineProgram: PropTypes.object,
  roomAllocation: PropTypes.object,
  guestList: PropTypes.array,
  generalInformation: PropTypes.object,
  setReqDemoPrice: PropTypes.func,
  postDemoPrice: PropTypes.func,
  onBehalf: PropTypes.object,
  demoPrice: PropTypes.object,
  setDemoPrice: PropTypes.func,
  demoJoinTour: PropTypes.func,
  commission: PropTypes.object,
  supplements: PropTypes.array,
  postDemoJoinTourAction: PropTypes.func,
  requestDemoPrice: PropTypes.object,
  isPackageMangement: PropTypes.func,
  foc: PropTypes.object,
  isReadyFixedPrice: PropTypes.bool,
  additionalServices: PropTypes.array,
  specialAdjusments: PropTypes.array,
  isChangeSpecialAdjustment: PropTypes.bool,
  changeSpecialAdjustment: PropTypes.func,
};
const mapStateToProps = state => {
  return {
    listTourOperator: state.TourTransactionStore.listTourOperator,
    loadingListTourOperator: state.TourTransactionStore.loadingListTourOperator,
    demoPrice: state.TourTransactionStore.demoPrice,
    demoJoinTour: state.TourTransactionStore.demoJoinTour,
  };
};
export default connect(mapStateToProps, {
  getListTourOperatorAction,
  getTourOperatorByIdAction,
  postDemoPrice,
  postDemoJoinTourAction,
})(TourOperatorSegment);
