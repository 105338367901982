import * as types from './constant/actionTypes';

const initialState = {
  loading: false,
  loginAdminStatus: '',
  logoutAdminStatus: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN:
      return { ...state };
    case types.LOGIN_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.error_description },
        // errors: { global: action.payload.response.data.error },
        loading: false,
        loginAdminStatus: 'failed',
      };
    case types.LOGIN_PENDING:
      return { ...state, loading: true };
    case types.LOGIN_FULFILLED:
      localStorage.setItem('tokenadmin', action.payload.data.access_token);
      localStorage.setItem('expiredtokenadmin', action.payload.data.expires);
      return {
        ...state,
        loading: false,
        loginAdminStatus: 'success',
      };
    case types.RESET_LOGIN:
      return {
        ...state,
        loginAdminStatus: '',
        logoutAdminStatus: '',
      };
    case types.LOGOUT:
      localStorage.clear();
      return { logoutAdminStatus: 'success' };
    default:
      return state;
  }
};

export default reducer;
