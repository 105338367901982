import React from 'react';
import PropTypes from 'prop-types';
import { Table, Icon } from 'semantic-ui-react';

const ModalTableManualPayment = props => {
  let additional = props.getPayableSupplemetItemStatic;
  return (
    <div>
      <Table basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={2}>Description</Table.HeaderCell>
            <Table.HeaderCell width={3} />
            <Table.HeaderCell width={3} />
            <Table.HeaderCell width={4} textAlign="center">
              {`  ${
                props.tourTransactionBilledStatic.Description
                  ? `(${props.tourTransactionBilledStatic.Description})`
                  : ''
              } Payment`}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Tour Price</Table.Cell>
            <Table.Cell width={3} />
            <Table.Cell width={3} />
            <Table.Cell textAlign="center" width={3}>
              {`${
                props.contentDetail.CurrencyId
                  ? props.contentDetail.CurrencyId
                  : ''
              } ${new Intl.NumberFormat('de-DE').format(
                /* props.TourPrice ? props.TourPrice + props.payableMandatory : 0 */
                props.TourPrice ? props.TourPrice : 0
              )}  `}
            </Table.Cell>
          </Table.Row>
          {additional
            ? additional.map((data, idx) => {
                return (
                  data.IsMandatory && (
                    <Table.Row key={idx}>
                      <Table.HeaderCell
                        colSpan="2"
                        style={{ paddingLeft: '1em' }}
                      >
                        <Icon fitted name="plus" /> {` ${data.Name}`}
                      </Table.HeaderCell>
                    </Table.Row>
                  )
                );
              })
            : null}
        </Table.Body>
      </Table>
      {TableAdditionalItem(props)}
    </div>
  );
};

const TableAdditionalItem = props => {
  let additional = props.getPayableSupplemetItemStatic;
  return (
    <Table basic="very">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={4}>Additional Item</Table.HeaderCell>
          <Table.HeaderCell width={3} />
          <Table.HeaderCell width={3} />
          <Table.HeaderCell width={4} textAlign="center">
            Sub total additional item
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {additional
          ? additional.map((data, idx) => {
              return (
                !data.IsMandatory && (
                  <Table.Row key={idx}>
                    <Table.Cell component="th" scope="row" width={1}>
                      {`${data.Name} - ${data.Qty} Pax `}
                    </Table.Cell>
                    <Table.Cell width={3} />
                    <Table.Cell width={3} />
                    <Table.Cell
                      textAlign="center"
                      style={
                        Number(additional.length && additional[idx].TotalCost) <
                        0
                          ? { color: 'red', fontWeight: 'bold' }
                          : {}
                      }
                    >
                      {additional.length > 0 && additional[idx].TotalCost < 0
                        ? ` ( ${
                            props.contentDetail.CurrencyId
                              ? props.contentDetail.CurrencyId
                              : ''
                          } ${new Intl.NumberFormat('de-DE').format(
                            Math.abs(
                              additional.length > 0
                                ? additional[idx].TotalCost
                                : 0
                            )
                          )} ) `
                        : `${
                            props.contentDetail.CurrencyId
                              ? props.contentDetail.CurrencyId
                              : ''
                          } ${new Intl.NumberFormat('de-DE').format(
                            Math.abs(
                              additional.length > 0
                                ? additional[idx].TotalCost
                                : 0
                            )
                          )}`}

                      {/* {`${
                        props.contentDetail.CurrencyId
                          ? props.contentDetail.CurrencyId
                          : ''
                      } ${new Intl.NumberFormat('de-DE').format(
                        additional.length > 0 ? additional[idx].TotalCost : 0
                      )}  `} */}
                    </Table.Cell>
                  </Table.Row>
                )
              );
            })
          : null}
      </Table.Body>
    </Table>
  );
};

TableAdditionalItem.propTypes = {
  contentDetail: PropTypes.func,
  // tourTransactionBilled: PropTypes.object,
  tourTransactionBilledStatic: PropTypes.object,
  // additionalItem: PropTypes.array,
  getPayableSupplemetItemStatic: PropTypes.array,
  // handleChangeAdditionalItem: PropTypes.func,
  handleChangeManualPayment: PropTypes.func,
  // tourTransactionBills: PropTypes.number,
  // handleChangeManualPaymentAmount: PropTypes.func,
  // handleChangeAdditionalItemAmount: PropTypes.func,
};
ModalTableManualPayment.propTypes = {
  contentDetail: PropTypes.func,
  // tourTransactionBilled: PropTypes.object,
  tourTransactionBilledStatic: PropTypes.object,
  // additionalItem: PropTypes.array,
  getPayableSupplemetItemStatic: PropTypes.array,
  // handleChangeAdditionalItem: PropTypes.func,
  handleChangeManualPayment: PropTypes.func,
  // tourTransactionBills: PropTypes.number,
  // handleChangeManualPaymentAmount: PropTypes.func,
  // handleChangeAdditionalItemAmount: PropTypes.func,
  TourPrice: PropTypes.number,
  payableMandatory: PropTypes.number,
};
export default ModalTableManualPayment;
