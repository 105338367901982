const CHECKIN = 'CHECKIN';
const CHECKOUT = 'CHECKOUT';
const DRIVING = 'DRIVING';
const RECREATION = 'RECREATION';
const RETURNACCOMMODATION = 'RETURNACCOMMODATION';
const LEAVEACCOMMODATION = 'LEAVEACCOMMODATION';
const DEPARTURE = 'DEPARTURE';
const ARRIVAL = 'ARRIVAL';
const FREETIME = 'FREETIME';
const TRANSIT = 'TRANSIT';
const FLIGHTTIME = 'FLIGHTTIME';
const EAT = 'EAT';
const DAYSTART = 'DAYSTART';
const DAYEND = 'DAYEND';
const VIRTUALCHECKIN = 'VIRTUALCHECKIN';
const VIRTUALCHECKOUT = 'VIRTUALCHECKOUT';
const VIRTUALRETURNACCOMMODATION = 'VIRTUALRETURNACCOMMODATION';
const VIRTUALLEAVEACCOMMODATION = 'VIRTUALLEAVEACCOMMODATION';
const FREETIMELOCKED = 'FREETIMELOCKED';
const QUEUETIME = 'QUEUETIME';
//set the variable in here to export
const HIDE_ACTIVITY = [
  VIRTUALCHECKIN,
  VIRTUALRETURNACCOMMODATION,
  VIRTUALLEAVEACCOMMODATION,
  VIRTUALCHECKOUT,
  FLIGHTTIME,
];
const EDIT_ACTIVITY = [RECREATION, EAT, DRIVING, FREETIME];
export {
  CHECKIN,
  CHECKOUT,
  DRIVING,
  RECREATION,
  RETURNACCOMMODATION,
  LEAVEACCOMMODATION,
  DEPARTURE,
  ARRIVAL,
  FREETIME,
  TRANSIT,
  FLIGHTTIME,
  EAT,
  DAYSTART,
  DAYEND,
  VIRTUALCHECKIN,
  VIRTUALCHECKOUT,
  VIRTUALRETURNACCOMMODATION,
  VIRTUALLEAVEACCOMMODATION,
  FREETIMELOCKED,
  QUEUETIME,
  HIDE_ACTIVITY,
  EDIT_ACTIVITY,
};
