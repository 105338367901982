import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Grid, Header, Divider } from 'semantic-ui-react';
// import DescriptionDetailBooking from '../../../../components/modal/modalHome/descriptionInDetailBooking';
import DescriptionDetailBooking from './modalPayment/bookingDetailInformation';
import PaymentTermDetailBooking from '../../../tourTransaction/transactionDetail/components/table/TablePaymentTermTourInDetailBooking';
import AdditionalItemDetailBooking from '../../../../components/modal/modalHome/tableAdditionalItemInDetailBooking';
// import { StyleButton } from '../../../../components/common/styleCommon';
import LoaderModal from '../../../../components/modal/loaderModal';
import ButtonClose from '../common/button/ButtonCloseModal';
import CollapseButton from '../../../../components/button/collapseButton';
import { Collapse } from 'react-collapse';
import TourPricePaymentDetail from './modalPayment/tourPricePaymentDetail';
import ActionPaymentDetail from './modalPayment/actionFooterPaymentDetail';

class modalDetailBooking extends Component {
  state = {
    isCollapseTourPrice: false,
    isCollpaseAdditional: false,
  };

  handleCollapseTourPrice = () => {
    const { isCollapseTourPrice } = this.state;
    this.setState({ isCollapseTourPrice: !isCollapseTourPrice });
  };
  handleCollapseAdditional = () => {
    const { isCollpaseAdditional } = this.state;
    this.setState({ isCollpaseAdditional: !isCollpaseAdditional });
  };
  render() {
    const props = this.props;
    const { isCollapseTourPrice, isCollpaseAdditional } = this.state;
    return (
      <Modal
        dimmer="blurring"
        open={props.openModal}
        onClose={props.handleClose}
      >
        <Modal.Header as="h3">
          {props.header} <ButtonClose onClose={props.handleClose} />
        </Modal.Header>
        <Modal.Content>
          <LoaderModal loading={props.loadingModal} />
          <DescriptionDetailBooking contentDetail={props.contentDetail} />
          <Grid>
            <Grid.Row>
              <Grid.Column width={14}>
                <Header as="h5" content="Tour Price" />
              </Grid.Column>
              <Grid.Column width={2} textAlign="right">
                <CollapseButton
                  handleCollapse={this.handleCollapseTourPrice}
                  style={{ boxShadow: '0 0 0 transparent', marginTop: '-1em' }}
                  isCollapse={isCollapseTourPrice}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Divider style={{ borderColor: 'black' }} />
          <Collapse isOpened={isCollapseTourPrice}>
            <TourPricePaymentDetail
              listData={props.getSpecialAdjusmentByTransactionId}
              listTourPrice={props.listTourPrice}
            />
            <Divider style={{ borderColor: 'black' }} />
          </Collapse>

          <Grid>
            <Grid.Row>
              <Grid.Column width={14}>
                <Header as="h5" content="Additional Item Detail" />
              </Grid.Column>
              <Grid.Column width={2} textAlign="right">
                <CollapseButton
                  handleCollapse={this.handleCollapseAdditional}
                  style={{ boxShadow: '0 0 0 transparent', marginTop: '-1em' }}
                  isCollapse={isCollpaseAdditional}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Divider style={{ borderColor: 'black' }} />
          <Collapse isOpened={isCollpaseAdditional}>
            <AdditionalItemDetailBooking
              listData={props.getPayableSupplemetItem}
            />
            <Divider style={{ borderColor: 'black' }} />
          </Collapse>

          <PaymentTermDetailBooking
            // listData={props.contentDetail.PaymentTermCompany}
            listData={props.getTourTransactionBills}
            Currency={props.Currency}
          />
          <Divider style={{ borderColor: 'black' }} />
        </Modal.Content>
        <Modal.Actions>
          <ActionPaymentDetail
            loadingInvoice={props.loadingInvoice}
            handleDownloadInvoice={props.handleDownloadInvoice}
            id={props.contentDetail.Id}
            handlePrintSchedule={props.handlePrintSchedule}
            loadingSpecialAdjusment={props.loadingSpecialAdjusment}
            openModalSpecialAdjusment={props.openModalSpecialAdjusment}
            nameCustomer={props.contentDetail.Customer}
            status={props.contentDetail.Status}
            actionBookingConfirmation={props.actionBookingConfirmation}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

modalDetailBooking.propTypes = {
  openModal: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  contentDetail: PropTypes.object,
  getPayableSupplemetItem: PropTypes.array,
  actionBookingConfirmation: PropTypes.func,
  loadingModal: PropTypes.bool,
  openModalSpecialAdjusment: PropTypes.func,
  handlePrintSchedule: PropTypes.func,
  loadingSpecialAdjusment: PropTypes.bool,
  loadingInvoice: PropTypes.bool,
  handleDownloadInvoice: PropTypes.func,
};

export default modalDetailBooking;
