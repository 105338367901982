import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Segment, Input, Button } from 'semantic-ui-react';
import HeaderTitle from '../../shared/components/common/headerSegment/HeaderTitle';
import TableAdditionalServicesList from './table/TableAdditionalServicesList';
import { searchFilterAdditionalServices } from '../services/filterData';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../scripts/pagination';
import Pagination from '../../shared/components/common/pagination/Pagination';

const AdditionalServicesList = props => {
  // ------------------------- Initial -------------------------
  const {
    data,
    activePage,
    pageChange,
    searchInput,
    handleChangeSearch,
    handleEdit,
    handleDelete,
    handleAdd,
    loading,
  } = props;

  // ------------------------- Pagination -------------------------
  const filterData = searchFilterAdditionalServices(
    data ? data : [],
    searchInput
  );
  const pagedData = datasetPagination(filterData, 7);
  const listData = pagedData[activePage - 1];

  const { numbersFrom, numbersTo } = getNumberFromTo(listData, activePage, 7);
  return (
    <>
      <Grid columns="equal" style={{ marginTop: '30px' }}>
        <Grid.Row stretched>
          <Grid.Column>
            <Segment loading={loading}>
              <HeaderTitle title="Additional Services" />
              <Grid>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Input
                      icon="search"
                      placeholder="search"
                      name="searchInput"
                      value={searchInput}
                      onChange={handleChangeSearch}
                    />
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <Button
                      floated="right"
                      positive
                      icon="add"
                      content="Add"
                      onClick={handleAdd}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <TableAdditionalServicesList
                listData={listData}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                loading={loading}
              />

              <Pagination
                pagedData={pagedData}
                numbersTo={numbersTo}
                numbersFrom={numbersFrom}
                data={data}
                pageChange={pageChange}
                activePage={activePage}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

AdditionalServicesList.propTypes = {
  data: PropTypes.array,
  activePage: PropTypes.number,
  loading: PropTypes.bool,
  pageChange: PropTypes.func,
  searchInput: PropTypes.string,
  handleChangeSearch: PropTypes.func,
  handleEdit: PropTypes.func,
  handleAdd: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default AdditionalServicesList;
