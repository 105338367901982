import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import LoaderModal from '../../../../components/modal/loaderModal';
import { Link } from 'react-router-dom';
import { headerTableCustomerPayment } from '../../constant/headerTable';
import Pagination from '../pagination/Pagination';
import { convertDateFormat } from '../../../../scripts/moment';

const TableCustomerPayment = props => {
  const {
    pagedData,
    numbersTo,
    numbersFrom,
    pageChange,
    activePage,
    data,
    listData,
  } = props;
  return (
    <Table basic="very" selectable>
      <LoaderModal loading={props.loading} />
      <Table.Header>
        <Table.Row>
          {headerTableCustomerPayment.map((headTableContent, index) => {
            return (
              <Table.HeaderCell
                key={index}
                textAlign={headTableContent === 'Action' ? 'center' : ''}
              >
                {headTableContent}
              </Table.HeaderCell>
            );
          })}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {listData ? (
          listData.map((payment, idx) => {
            return (
              <Table.Row key={idx}>
                <Table.Cell component="th" scope="row" width={1}>
                  <Link
                    to={`/Simple-Transaction-Detail/${payment.TransactionId}`} //${payment.PackageType}
                  >
                    {payment.TransactionId}
                  </Link>
                </Table.Cell>
                <Table.Cell>{payment.TransactionName}</Table.Cell>
                <Table.Cell>
                  {/* <Link
                  to={`/Customer-Profile-Detail/detail/${payment.CompanyCode}`}
                >
                  {payment.CompanyCode + ' - ' + payment.CompanyName}
                </Link> */}
                  {payment.CompanyCode + ' - ' + payment.CompanyName}
                </Table.Cell>
                <Table.Cell>{payment.BillDescription}</Table.Cell>
                <Table.Cell>
                  {payment.Currency +
                    '           ' +
                    new Intl.NumberFormat('de-DE').format(payment.Bill)}
                </Table.Cell>
                <Table.Cell>
                  {payment.DueDate
                    ? convertDateFormat(payment.DueDate, 'DD MMMM YYYY')
                    : null}
                </Table.Cell>
              </Table.Row>
            );
          })
        ) : (
          <Table.Row>
            <Table.Cell colSpan="8" textAlign="center">
              No Data
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
      <Table.Row>
        <Table.HeaderCell colSpan="6">
          <Pagination
            pagedData={pagedData}
            numbersTo={numbersTo}
            numbersFrom={numbersFrom}
            data={data}
            pageChange={pageChange}
            activePage={activePage}
          />
        </Table.HeaderCell>
      </Table.Row>
    </Table>
  );
};
TableCustomerPayment.propTypes = {
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  loading: PropTypes.bool,
  activePage: PropTypes.number,
  pagedData: PropTypes.array,
  numbersFrom: PropTypes.number,
  numbersTo: PropTypes.number,
  pageChange: PropTypes.func,
  data: PropTypes.array,
};
export default TableCustomerPayment;
