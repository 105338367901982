import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Image } from 'semantic-ui-react';
import UploadButton from '../../../components/common/uploadImageButton';
const SegmentImageUser = props => {
  return (
    <React.Fragment>
      <Grid centered>
        <Grid.Row centered>
          <Header as="h4" content="Profile Picture" />
        </Grid.Row>
        <Grid centered>
          <Grid.Column>
            <Image
              size="medium"
              circular
              src={
                props.userProfile.ImageUrl ||
                props.imagePreview ||
                require('../../../assets/Icons/no_image.jpg')
              }
            />
          </Grid.Column>
        </Grid>
        <Grid.Row verticalAlign="middle" centered>
          <UploadButton
            onImageSelect={props.handleChangeImage}
            label={
              props.userProfile.ImageUrl === '' || props.imagePreview === ''
                ? 'Select Image'
                : 'Change Image'
            }
            // label={'Select Image'}
            buttonStyle={{ marginTop: '10px' }}
            disabled={props.disablePage}
          />
        </Grid.Row>
      </Grid>
    </React.Fragment>
  );
};

SegmentImageUser.propTypes = {
  userProfile: PropTypes.object,
  imagePreview: PropTypes.string,
  handleChangeImage: PropTypes.func,
  disablePage: PropTypes.bool,
};
export default SegmentImageUser;
