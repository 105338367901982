import apiClient from '../apiClient';
import {
  DELETE_EXCURSION_OBJECT_URL,
  GET_EXCURSION_BY_CITY_WITH_FILTER_URL,
  GET_ATTRACTION_ESTIMATE_PRICE_URL,
} from '../apiUrl';

const deleteExcursionObjectApi = id => {
  return apiClient.delete(`${DELETE_EXCURSION_OBJECT_URL}${id}`);
};

const getExcursionByCityWithFilterApi = (
  cityId,
  attractionTypeId,
  requestedDate,
  pax
) => {
  return apiClient.get(
    `${GET_EXCURSION_BY_CITY_WITH_FILTER_URL}?cityId=${cityId}&attractionTypeId=${attractionTypeId}&requestedDate=${requestedDate}&pax=${pax}`
  );
};
const getAttractionEstimatePriceApi = param => {
  return apiClient.post(
    `${GET_ATTRACTION_ESTIMATE_PRICE_URL}?CityId=${param.CityId}&AttractionTypeId=${param.AttractionTypeId}&RequestedDate=${param.RequestedDate}&Pax=${param.Pax}`,
    param.bodyParam
  );
};
export {
  deleteExcursionObjectApi,
  getExcursionByCityWithFilterApi,
  getAttractionEstimatePriceApi,
};
