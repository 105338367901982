import React from 'react';
import { Button, Header, Modal, Form, Grid, TextArea } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import PrettifyHTML from '../script/prettyHTML';

class ModalDataFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      request: '',
    };
  }

  handleChange = (e, { name, value }) => {
    let obj = value;
    this.setState({ ...this.state, [name]: obj });
  };

  filterByRange = () => {
    this.props.setFilter(this.state.from, this.state.to);
    this.setState(this.getInitialState());
  };

  parseJSON = string => {
    function getParsedJSON(string) {
      if (!JSON.parse(string)) {
        return string;
      } else {
        let prettyString = JSON.parse(string);
        return prettyString;
      }
    }
    try {
      let object = getParsedJSON(string);
      return object;
    } catch (e) {
      // console.log('asdfasdfasdfasdfasdf');
      return string;
    }
  };

  stringifyObj = value => {
    // console.log(typeof value);
    if (typeof value === 'object') {
      let obj = JSON.stringify(value, null, 2);
      return obj;
    } else {
      return value;
    }
  };

  getString = string => {
    let defaultJSON = string ? string : '{ "string": "" }';
    let objFromString = this.parseJSON(defaultJSON);
    let prettyString = this.stringifyObj(objFromString);
    return prettyString;
  };

  prettifyHTML = text => {
    let string = PrettifyHTML(text);
    return string;
  };

  closeModal = () => {
    this.setState({ ...this.state, request: '' });
    this.props.handleClose();
  };

  handleChangeRequest = (e, { name, value }) => {
    this.setState({ ...this.state, [name]: value });
  };

  saveReq = () => {
    this.props.saveRequest(this.props.id, this.state.request);
  };

  render() {
    let { openModal, response, request, id } = this.props;
    let stringJSON = this.getString(response);
    let stringHTML = this.prettifyHTML(request);
    // console.log(this.state.request.substr(0, 4));

    return (
      <Modal
        dimmer="blurring"
        closeIcon
        inverted
        open={openModal}
        // onOpen={() => console.warn('onOpen')}
        onClose={this.closeModal}
        style={{ width: '74em' }}
      >
        <Header content="Detail Preview and Response" />
        <Modal.Content>
          <Form>
            <Grid columns={2} relaxed="very">
              <Grid.Column>
                <Form.Group>
                  <Form.Field width={16}>
                    <label>Request:</label>
                    <TextArea
                      name="request"
                      onChange={this.handleChangeRequest}
                      width={16}
                      rows={20}
                    >
                      {stringHTML}
                    </TextArea>
                  </Form.Field>
                </Form.Group>
              </Grid.Column>
              <Grid.Column>
                <Form.Group>
                  <Form.Field width={16}>
                    <label>Response:</label>
                    <TextArea readonly="" width={16} rows={20}>
                      {stringJSON}
                    </TextArea>
                  </Form.Field>
                </Form.Group>
              </Grid.Column>
            </Grid>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            name="save"
            id={id}
            onClick={this.saveReq}
            request={this.state.request}
            color="blue"
          >
            Save
          </Button>
          <Button
            name="resend"
            id={id}
            onClick={this.props.resendData}
            request={this.state.request}
            color="green"
          >
            Resend
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

ModalDataFilter.propTypes = {
  id: PropTypes.number,
  openModal: PropTypes.bool,
  handleClose: PropTypes.func,
  setFilter: PropTypes.func,
  toLine: PropTypes.number,
  fromLine: PropTypes.number,
  request: PropTypes.string,
  response: PropTypes.string,
  saveRequest: PropTypes.func,
  resendData: PropTypes.func,
};

export default ModalDataFilter;
