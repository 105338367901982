import React from 'react';
import { connect } from 'react-redux';
import {
  getFixedPackages,
  resetPackageAction,
  postPublishPackageAction,
  deletePackageAction,
  // copyPackageAction,
  // postPackageAction,
  // getSeriesPackageVariableDateAction,
} from '../../actions/packageAction';
import ModalAlert from '../../components/modal/modalAlert';
import PropTypes from 'prop-types';
import { Tab, Segment } from 'semantic-ui-react';
import {
  TabListPackageDraft,
  TabListPackagePublish,
} from './components/packageManagementList';
import { Redirect } from 'react-router-dom';
import { datasetPagination } from '../../scripts/pagination';
import ModalConfirmation from '../../components/modal/modalConfirmationTwoButton';
import FilterData from '../../scripts/filterDataTable';
import HeaderTitle from '../../components/common/headerTitle';
import styles from './styles';
import ModalCopyPackage from './../../components/modal/ModalCopyPackage';
import { sortDateByDate } from '../../scripts/sortDateByDate';
import ModalCalendar from '../../components/modal/modalCalendar';
import { getTime } from '../../scripts/moment';
class PackageListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      packageListDraft: [],
      packageListPublish: [],
      tabMenuPackage: [
        {
          menuItem: 'Draft',
          render: () => (
            <Tab.Pane
              active={true}
              content={
                <TabListPackageDraft
                  packageListDraft={this.state.packageListDraft}
                  openDetailPackage={this.openDetailPackage}
                  handleChangeCheck={this.handleChangeCheck}
                  statusCheckDraft={this.state.statusCheckDraft}
                  postActionPublishAll={this.postActionPublishAll}
                  postActionPublish={this.postActionPublish}
                  deletePackage={this.showConfirmation}
                  deletePackageAllDraft={this.deletePackageAllDraft}
                  copyPackage={this.copyPackage}
                  typePackage={this.state.typePackage}
                  handleChange={this.handleChange}
                  pageChange={this.pageChange}
                  activePage={this.state.activePage}
                  searchInput={this.state.searchInput}
                  loading={this.props.loading}
                  handleOnClickFilterDate={this.handleModalCalendar}
                  isOperation={this.props.isOperation}
                  isSales={this.props.isSales}
                />
              }
            />
          ),
        },
        {
          menuItem: 'Publish',
          render: () => (
            <Tab.Pane
              content={
                <TabListPackagePublish
                  openDetailPackage={this.openDetailPackage}
                  packageListPublish={this.state.packageListPublish}
                  activePage={this.state.activePage}
                  handleChangeCheck={this.handleChangeCheck}
                  statusCheckPublish={this.state.statusCheckPublish}
                  postActionUnPublishAll={this.postActionUnPublishAll}
                  postActionUnPublish={this.postActionUnPublish}
                  deletePackage={this.showConfirmation}
                  copyPackage={this.copyPackage}
                  typePackage={this.state.typePackage}
                  handleChange={this.handleChange}
                  pageChange={this.pageChange}
                  searchInput={this.state.searchInput}
                  loading={this.props.loading}
                  handleOnClickFilterDate={this.handleModalCalendar}
                  isOperation={this.props.isOperation}
                  isSales={this.props.isSales}
                />
              }
            />
          ),
        },
      ],
      redirectDetails: false,
      typePackage: '',
      packageId: 0,
      listPackagePublish: [],
      listPackageDraft: [],
      activePage: 1,
      searchInput: '',
      pageDataDraft: [],
      pageDataPublish: [],
      packageType: '',
      statusCheckDraft: true,
      statusCheckPublish: true,
      loading: true,
      openConfirmation: false,
      openModal: false,
      activeIndex: 0,
      modalCopyPackage: {
        openModal: false,
        selectedId: null,
        typePackage: null,
        startDate: '',
      },
      modalCalendarOpen: false,
    };
  }
  handleCloseCopyPackage = () => {
    let modalCopyPackage = { ...this.state.modalCopyPackage, openModal: false };
    this.setState({ ...this.state, modalCopyPackage });
  };
  handleClose = () =>
    this.setState({
      openModal: false,
      openConfirmation: false,
    });
  handleCloseCopyPackage = () => {
    let modalCopyPackage = { ...this.state.modalCopyPackage, openModal: false };
    this.setState({ ...this.state, modalCopyPackage });
  };
  componentDidMount = () => {
    const { Type } = this.props.match.params;
    this.setState({ typePackage: Type });
    this.props.getFixedPackages(`${Type}Package`);
    Type === 'Ready' && this.getVariableDatePackage();
    // this.setState({
    //   packageListDraft: [],
    //   packageListPublish: [],
    //   staticDraft: [],
    //   staticPublish: [],
    // });
  };
  getVariableDatePackage = () => {
    this.props.getFixedPackages(`VariableDate`);
  };
  componentDidUpdate = prevProps => {
    const { Type } = this.props.match.params;
    if (Type !== this.state.typePackage) {
      this.props.getFixedPackages(`${Type}Package`);
      this.setState({ typePackage: Type, loading: true });
    }
    if (this.props.getPackagesStatus === 'success') {
      this.setListByStatus(this.props.packages);
      this.setState({ loading: false });
      this.props.resetPackageAction();
    }
    if (prevProps.match.params.Type !== Type) {
      if (Type === 'Ready') {
        this.getVariableDatePackage();
      }
      this.setState({
        packageListDraft: [],
        packageListPublish: [],
        staticDraft: [],
        staticPublish: [],
        activePage: 1,
      });
    }
  };

  setListByStatus = data => {
    const { packageListDraft, packageListPublish } = this.state;
    data.map(list => {
      if (list.IsPublished) {
        return packageListPublish.push(list);
      } else {
        return packageListDraft.push(list);
      }
    });
    this.setPagintation(packageListDraft, packageListPublish);
    let sortDraftByDate = sortDateByDate(packageListDraft, 'Id');
    let sortPublishByDate = sortDateByDate(packageListPublish, 'Id');
    this.setState({
      packageListDraft: sortDraftByDate,
      packageListPublish: sortPublishByDate,
      staticDraft: sortDraftByDate,
      staticPublish: sortPublishByDate,
    });
  };

  convertTypePackage = typePackage => {
    if (typePackage === 'Fixed') {
      typePackage = 'FixedPackage';
    } else if (typePackage === 'Ready') {
      typePackage = 'ReadyPackage';
    } else if (typePackage === 'FixedDateVariable') {
      typePackage = 'VariableDatePackage';
    }
    return typePackage;
  };

  actionPublishApiCall = (typePackage, actionTaken, name) => {
    const { Type } = this.props.match.params;
    this.props
      .postPublishPackageAction(typePackage, actionTaken, name)
      .then(() => {
        this.props.getFixedPackages(`${Type}Package`);
        Type === 'Ready' && this.props.getFixedPackages(`VariableDate`);
        this.props.resetPackageAction();
        this.setState({
          statusCheckDraft: true,
          statusCheckPublish: true,
          openModal: true,
          headerAlert: 'Successfully',
          contentAlert: `The package has been successfully ${actionTaken.toLowerCase()}ed `,
          packageListDraft: [],
          packageListPublish: [],
        });
      })
      .catch(error =>
        this.setState({
          statusCheckDraft: true,
          statusCheckPublish: true,
          openModal: true,
          headerAlert: 'Failed',
          contentAlert: error.response.data.Message,
        })
      );
  };

  actionDeleteApiCall = id => {
    const { Type } = this.props.match.params;
    this.props
      .deletePackageAction(id)
      .then(() => {
        this.props.getFixedPackages(`${Type}Package`);
        Type === 'Ready' && this.props.getFixedPackages(`VariableDate`);
        this.props.resetPackageAction();
        this.setState({
          statusCheckDraft: true,
          statusCheckPublish: true,
          openModal: true,
          headerAlert: 'Successfully',
          contentAlert: `The package has been successfully deleted `,
          packageListDraft: [],
          packageListPublish: [],
        });
      })
      .catch(error =>
        this.setState({
          statusCheckDraft: true,
          statusCheckPublish: true,
          openModal: true,
          headerAlert: 'Failed',
          contentAlert: error.response.data.Message,
        })
      );
  };

  postActionPublish = (e, { name, value, id }) => {
    let isSharingRoomNol = id;
    if (isSharingRoomNol) {
      this.setState({
        openModal: true,
        headerAlert: 'Information',
        contentAlert:
          'the package cannot be published because the room price of the package is 0 cannot publish because ',
      });
    } else {
      let typePackage = this.convertTypePackage(value);
      this.setState({ typePublish: 'Publish', activePage: 1, searchInput: '' });
      this.actionPublishApiCall(typePackage, 'Publish', name);
    }
  };
  // postActionPublish = (e, { name }) => {
  //   let typePackage = this.convertTypePackage(this.state.typePackage);
  //   this.setState({ typePublish: 'Publish', activePage: 1, searchInput: '' });
  //   this.props.postPublishPackageAction(typePackage, 'Publish', name);
  // };
  // postActionUnPublish = (e, { name, value }) => {
  //   let typePackage = this.convertTypePackage(value);
  //   this.setState({ typePublish: 'Unpublish', activePage: 1, searchInput: '' });

  //   this.actionPublishApiCall(typePackage, 'UnPublish', name);
  // };
  postActionUnPublish = (e, { name, value }) => {
    let typePackage = this.convertTypePackage(value);
    this.setState({ typePublish: 'Unpublish', activePage: 1, searchInput: '' });
    this.actionPublishApiCall(typePackage, 'UnPublish', name);
  };
  deletePackage = () => {
    this.actionDeleteApiCall(this.state.packageId);
    this.setState({ activePage: 1, searchInput: '' });
  };
  showConfirmation = (e, { name }) => {
    this.setState({
      openConfirmation: true,
      packageId: name,
      contentAlert: ' Are you sure to delete this package ?',
    });
  };
  copyPackage = (e, { name, value, date }) => {
    let modalCopyPackage = {
      openModal: true,
      selectedId: value.Id,
      // typePackage: name === 'FixedDateVariable' ? 'VariableDate' : name,
      typePackage: name,
      startDate: date,
    };
    this.setState({ ...this.state, modalCopyPackage });
  };

  deletePackageAllDraft = () => {
    let { packageListDraft } = this.state;
    packageListDraft.map(detail => {
      if (
        (detail.IsCheck ? true : false) &&
        (detail.PackageType === 'Fixed'
          ? detail.FixedPackage.ConfirmedGuest > 0
            ? false
            : true
          : true)
      ) {
        this.actionDeleteApiCall(detail.Id);
      }
      return detail;
    });
    this.setState({ activePage: 1, searchInput: '' });
  };
  postActionPublishAll = () => {
    let { packageListDraft } = this.state;
    packageListDraft.map(detail => {
      if (detail.IsCheck) {
        let typePackage = this.convertTypePackage(detail.PackageType);
        this.actionPublishApiCall(typePackage, 'Publish', detail.Id);
      }
      return detail;
    });
    this.setState({ activePage: 1, searchInput: '' });
  };
  postActionUnPublishAll = () => {
    let { packageListPublish } = this.state;
    packageListPublish.map(detail => {
      if (detail.IsCheck) {
        let typePackage = this.convertTypePackage(detail.PackageType);
        this.actionPublishApiCall(typePackage, 'UnPublish', detail.Id);
      }
      return detail;
    });
    this.setState({ activePage: 1, searchInput: '' });
  };

  redirectDetails = () => {
    if (this.state.redirectDetails) {
      if (this.state.packageId === 'New') {
        if (this.state.typePackage === 'Fixed') {
          return (
            <Redirect
              to={'/Package-Details/' + this.state.typePackage + '/create/0'} //create/0 new
            />
          );
        } else {
          return (
            <Redirect
              to={'/Package-Details/' + this.state.typePackage + '/create/0'}
            />
          );
        }
      } else {
        if (this.props.isOperation) {
          return (
            <Redirect
              to={
                '/Simple-Package-Detail/' +
                this.state.openDetailType +
                '/' +
                this.state.packageId
              }
            />
          );
        } else {
          if (this.state.typePackage === 'Fixed') {
            return (
              <Redirect
                to={
                  '/Package-Details/' +
                  this.state.openDetailType +
                  '/edit/' +
                  this.state.packageId
                }
              />
            );
          } else {
            return (
              <Redirect
                to={
                  '/Package-Details/' +
                  this.state.openDetailType +
                  '/edit/' +
                  this.state.packageId
                }
              />
            );
          }
        }
      }
    }
  };
  openDetailPackage = (e, { name, value }) => {
    this.setState({
      packageId: value,
      redirectDetails: true,
      openDetailType: name,
    });
  };
  handleChangeCheck = (e, { name, checked }) => {
    let {
      packageListPublish,
      packageListDraft,
      statusCheckDraft,
      statusCheckPublish,
    } = this.state;
    let { Status, Id } = name;
    let IsCheck = 'IsCheck';
    if (Status) {
      if (Id === 'All') {
        packageListPublish.map((detail, i) => {
          packageListPublish[i] = {
            ...packageListPublish[i],
            [IsCheck]: checked,
          };
          return detail;
        });
      } else {
        packageListPublish.map((detail, i) => {
          if (detail.Id === Id) {
            packageListPublish[i] = {
              ...packageListPublish[i],
              [IsCheck]: checked,
            };
          }
          return detail;
        });
      }
      statusCheckPublish = checked ? false : true;
      packageListDraft.map(detail => {
        if (detail.IsCheck) {
          statusCheckPublish = false;
        }
        return detail;
      });
      this.setState({
        packageListPublish: packageListPublish,
        statusCheckPublish: statusCheckPublish,
      });
    } else {
      if (Id === 'All') {
        packageListDraft.map((detail, i) => {
          packageListDraft[i] = {
            ...packageListDraft[i],
            [IsCheck]: checked,
          };
          return detail;
        });
      } else {
        packageListDraft.map((detail, i) => {
          if (detail.Id === Id) {
            packageListDraft[i] = {
              ...packageListDraft[i],
              [IsCheck]: checked,
            };
          }
          return detail;
        });
      }
      statusCheckDraft = checked ? false : true;
      packageListDraft.map(detail => {
        if (detail.IsCheck) {
          statusCheckDraft = false;
        }
        return detail;
      });
      this.setState({
        packageListDraft: packageListDraft,
        statusCheckDraft: statusCheckDraft,
      });
    }
  };
  setPagintation = (draft, publish) => {
    let { activePage, searchInput } = this.state;
    let filterDataDraft = FilterData(draft, 'Title', searchInput);
    let pageDataDraft = datasetPagination(filterDataDraft, 10);
    let listDraft = pageDataDraft[activePage - 1];
    this.setState({
      listPackageDraft: listDraft,
      pageDataDraft: pageDataDraft,
    });
    let filterDataPublish = FilterData(publish, 'Title', searchInput);
    let pageDataPublish = datasetPagination(filterDataPublish, 10);
    let listPublish = pageDataPublish[activePage - 1];
    this.setState({
      listPackagePublish: listPublish,
      pageDataPublish: pageDataPublish,
    });
  };
  onTabChanges = (e, data) => {
    this.setState({
      activeIndex: data.activeIndex,
      activePage: 1,
    });
  };

  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value, activePage: 1 });
  };
  handleDateRange = async id => {
    let { staticDraft, staticPublish, activeIndex } = this.state;
    const { from, to } = id;
    let list = [];
    this.props.isSales
      ? (list = await this.filterDate(staticPublish, from, to ? to : from))
      : activeIndex > 0
      ? (list = await this.filterDate(staticPublish, from, to ? to : from))
      : (list = await this.filterDate(staticDraft, from, to ? to : from));
    await list.sort((a, b) => getTime(a.StartDate) - getTime(b.StartDate));
    this.props.isSales
      ? this.setState({ packageListPublish: list })
      : activeIndex > 0
      ? this.setState({ packageListPublish: list })
      : this.setState({ packageListDraft: list });
    this.handleModalCalendar();
  };

  filterDate = (list, from, to) => {
    return list.filter(
      d =>
        getTime(d.StartDate) >= getTime(from) &&
        getTime(d.StartDate) <= getTime(to)
    );
  };
  handleModalCalendar = () =>
    this.setState({
      ...this.state,
      modalCalendarOpen: !this.state.modalCalendarOpen,
    });

  render() {
    let {
      openConfirmation,
      typePackage,
      openModal,
      modalCopyPackage,
      modalCalendarOpen,
    } = this.state;
    return (
      <Segment style={styles.marginTop30px}>
        <HeaderTitle
          iconName="clipboard list"
          title={`${typePackage === 'Fixed' ? 'Series' : 'Ready'} Package List`}
        />

        {this.redirectDetails()}
        <ModalAlert
          openModal={openModal}
          handleClose={this.handleClose}
          header={this.state.headerAlert}
          content={this.state.contentAlert}
          nameButton="OK"
        />
        <ModalConfirmation
          openModal={openConfirmation}
          confirm={this.deletePackage}
          size="mini"
          close={this.handleClose}
          header={this.state.headerAlert}
          message={this.state.contentAlert}
          loading={this.props.loading}
        />
        <Tab
          menu={{ secondary: true, pointing: true }}
          renderActiveOnly={true}
          panes={
            this.props.isSales
              ? this.state.tabMenuPackage.filter(
                  item => item.menuItem === 'Publish'
                )
              : this.state.tabMenuPackage
          }
          activeIndex={this.state.activeIndex}
          onTabChange={this.onTabChanges}
        />
        <ModalCopyPackage
          open={modalCopyPackage.openModal}
          id={modalCopyPackage.selectedId}
          type={modalCopyPackage.typePackage}
          startDate={modalCopyPackage.startDate}
          handleClose={this.handleCloseCopyPackage}
        />
        <ModalCalendar
          actionDateRange={this.handleDateRange}
          openModal={modalCalendarOpen}
          handleClose={this.handleModalCalendar}
          contentHeader="Select Dates"
        />
      </Segment>
    );
  }
}
PackageListPage.propTypes = {
  packages: PropTypes.array,
  loading: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.string,
  }),
  getFixedPackages: PropTypes.func,
  getPackagesStatus: PropTypes.string,
  resetPackageAction: PropTypes.func,
  postPublishPackageAction: PropTypes.func,
  deletePackageAction: PropTypes.func,
  getSeriesPackageVariableDateAction: PropTypes.func,
  isOperation: PropTypes.bool,
  isSales: PropTypes.bool,
};
function mapStateToProps(state) {
  return {
    packages: state.PackageStore.packages,
    loading: state.PackageStore.loading,
    getPackagesStatus: state.PackageStore.getPackagesStatus,
    postPublishPackageStatus: state.PackageStore.postPublishPackageStatus,
    deletePackageStatus: state.PackageStore.deletePackageStatus,
    isOperation: state.UserAccessStore.isOperation,
    isSales: state.UserAccessStore.isSales,
  };
}
export default connect(mapStateToProps, {
  getFixedPackages,
  resetPackageAction,
  postPublishPackageAction,
  deletePackageAction,
  // getSeriesPackageVariableDateAction,
})(PackageListPage);
