import React, { Component } from 'react';
import { Table, Input, Button, Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { HeaderTableInvoicesCompanyProfile } from '../../../../../components/table/headerTabel';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../../../scripts/pagination';
//import FilterData from '../../../scripts/filterDataTable';
import LoaderModal from '../../../../../components/modal/loaderModal';
import LoadingPage from '../../../../../components/modal/loaderModal';
import Pagination from '../../../../../components/common/pagination';
class TableBalanceDetailHistory extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
    };
  }
  static propTypes = {
    data: PropTypes.array,
  };

  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });

  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  // data.filter(data => {
  //   return (
  //     data.CashFlowType.toLowerCase().indexOf(
  //       searchInput.toLowerCase()
  //     ) !== -1
  //   );
  // })
  filterData = listData => {
    let { searchInput } = this.state;
    if (searchInput) {
      return listData.filter(e => {
        return (
          e.TourTransactionId &&
          e.TourTransactionId.toString().indexOf(searchInput) !== -1
        );
      });
    } else {
      return listData;
    }
  };
  render() {
    // let filterData = FilterData(
    //   this.props.listData,
    //   'CashFlow',
    //   this.state.searchInput
    // );
    let { isNewForm, loading, listData } = this.props;
    // listData = listData && this.filterData(listData);

    let pagedData = datasetPagination(listData, 3);
    let listDataFilter = pagedData[this.state.activePage - 1];
    let loadingInvoice = isNewForm ? false : loading;
    // const props = this.props;

    let { numbersFrom, numbersTo } = getNumberFromTo(
      listData,
      this.state.activePage,
      3
    );
    return (
      <div>
        <LoadingPage loading={loadingInvoice} />
        <Input
          icon="search"
          placeholder="search by Booking number"
          name="searchInput"
          value={this.state.searchInput}
          onChange={this.handleChange}
        />
        <LoaderModal loading={loadingInvoice} />
        <Table basic="very" selectable fixed>
          <Table.Header>
            <Table.Row>
              {HeaderTableInvoicesCompanyProfile.map(
                (headTableContent, index) => {
                  return (
                    <Table.HeaderCell key={index}>
                      {headTableContent}
                    </Table.HeaderCell>
                  );
                }
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {listDataFilter &&
              listDataFilter.map((booking, idx) => {
                return (
                  <Table.Row key={idx}>
                    <Popup
                      trigger={<Table.Cell>{booking.Code}</Table.Cell>}
                      content={booking.Code}
                    />
                    <Table.Cell>
                      {booking.TourTransactionId === null
                        ? ' - '
                        : booking.TourTransactionId}
                    </Table.Cell>
                    <Table.Cell>{booking.InvoiceStatus}</Table.Cell>
                    <Table.Cell>
                      {' '}
                      {moment(booking.ExpiredDate).format('ll')}
                    </Table.Cell>
                    <Table.Cell>
                      {' '}
                      {moment(booking.LastModifiedDate).format('ll')}
                    </Table.Cell>
                    <Table.Cell> {booking.Amount}</Table.Cell>
                    <Table.Cell> {booking.PaidAmount}</Table.Cell>
                    <Table.Cell>
                      <Button
                        content="Print"
                        primary
                        onClick={this.props.printInvoice}
                        id={booking.TourTransactionId}
                        value={booking.Code}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        {/* {pagedData &&
            pagedData.length > 1 && (
              <Table.Footer> */}
        <Pagination
          // totalPages={pagedData.length}
          // onPageChange={this.pageChange}
          // activePage={this.state.activePage}
          pagedData={pagedData}
          numbersTo={numbersTo}
          numbersFrom={numbersFrom}
          data={listData}
          pageChange={this.pageChange}
          activePage={this.state.activePage}
        />
        {/* </Table.Footer>
            )} */}
      </div>
    );
  }
}

TableBalanceDetailHistory.propTypes = {
  listData: PropTypes.array,
  loading: PropTypes.bool,
  printInvoice: PropTypes.func,
  isNewForm: PropTypes.bool,
};
export default TableBalanceDetailHistory;
