import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { withRouter } from 'react-router';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import store from './store';
import App from './App';
import Login from './pages/login/Login';
import './assets/Icons/Icons.css';
import 'semantic-ui-css/semantic.min.css';
import './style.css';

const APPWITHROUTER = withRouter(App);
const LOGINWITHROUTER = withRouter(Login);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <div>
        <Route exact path="/Login" component={LOGINWITHROUTER} />
        <APPWITHROUTER />
      </div>
    </Router>
  </Provider>,
  document.getElementById('root')
);
