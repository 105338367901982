import {
  DELETE_REGION,
  GET_REGION_BY_ID,
  POST_REGION,
  PUT_REGION,
  RESET_REGION_PAGE,
} from './constant/actionTypes';
import deleteRegionApi from '../../api/region/deleteRegionApi';
import getRegionByIdApi from '../../api/region/getRegionByIdApi';
import postRegionApi from '../../api/region/postRegionApi';
import putRegionApi from '../../api/region/putRegionApi';

const resetRegionPageAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_REGION_PAGE,
    });
  };
};

const deleteRegionAction = Id => {
  return dispatch => {
    return dispatch({
      type: DELETE_REGION,
      payload: deleteRegionApi(Id),
    });
  };
};

const getRegionByIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_REGION_BY_ID,
      payload: getRegionByIdApi(Id),
    });
  };
};

const postRegionAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_REGION,
      payload: postRegionApi(data),
    });
  };
};

const putRegionAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_REGION,
      payload: putRegionApi(id, data),
    });
  };
};

export {
  resetRegionPageAction,
  deleteRegionAction,
  getRegionByIdAction,
  postRegionAction,
  putRegionAction,
};
