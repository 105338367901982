// import { emailValidation } from '../../../../shared/services/validation/emailValidation';
// import { passwordValidation } from '../../../../shared/services/validation/passwordValidation';
// const copyObject = obj => {
//   let newObj = JSON.stringify(obj);
//   return JSON.parse(newObj);
// };

// const userValidation = (UserObject, error) => {
//   let checkErrors = copyObject(error);
//   checkErrors.Username.error = UserObject.Username === '';
//   checkErrors.Username.error = UserObject.Username.includes(' ');
//   checkErrors.Password.error =
//     UserObject.Password === '' || !passwordValidation(UserObject.Password);
//   checkErrors.ConfirmPassword.error = UserObject.ConfirmPassword === '';
//   checkErrors.Email.error =
//     UserObject.Email === '' || !emailValidation(UserObject.Email);
//   checkErrors.Roles.error = UserObject.Roles === '';
//   checkErrors.ConfirmPassword.error =
//     UserObject.ConfirmPassword !== UserObject.Password;
//   checkErrors.isError =
//     checkErrors.Username.error ||
//     checkErrors.Password.error ||
//     checkErrors.ConfirmPassword.error ||
//     checkErrors.Email.error ||
//     checkErrors.Roles.error ||
//     checkErrors.ConfirmPassword.error;
//   return checkErrors;
// };
import fields from '../../constant/fieldValidation';

export const userValidation = state => {
  const validate = {};
  fields.map(field => {
    if (state[field] === '') {
      validate[field] = {
        error: true,
        message: `Field can't be empty!!`,
      };
    } else if (state.Username.includes(' ')) {
      validate[field] = {
        error: true,
        message: `Username is not allowed to use spaces`,
      };
    } else if (
      ['Email', 'EmailAddress'].indexOf(field) !== -1 &&
      state[field].indexOf('@' && '.') === -1
    ) {
      validate[field] = {
        error: true,
        message: `Email format is invalid!!`,
      };
    } else if (state.Password !== state.ConfirmPassword) {
      validate['ConfirmPassword'] = {
        error: true,
        message: `Password does not match!!`,
      };
    } else {
      delete validate[field];
    }
    return validate;
  });

  return validate;
};
