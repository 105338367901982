import {
  GET_MASTER_REFERENCE,
  RESET_MASTER_REFERENCE,
  GET_CANCELLATION_REASON_BY_TYPE_REFERENCE,
  RESET_CANCELLATION_REASON_BY_TYPE_REFERENCE,
} from '../constants/actionTypes';
import {
  getMasterDataReferenceApi,
  getCancellationReasonByTypeReferenceApi,
} from '../../../api/uploadMasterDataReferenceApi/getMasterDataReferenceApi';

const getMasterReferenceAction = params => {
  return dispatch => {
    return dispatch({
      type: GET_MASTER_REFERENCE,
      payload: getMasterDataReferenceApi(params),
    });
  };
};

const resetMasterDataReferenceAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_MASTER_REFERENCE,
    });
  };
};

const getCancellationReasonByTypeReferenceAction = type => {
  return dispatch => {
    return dispatch({
      type: GET_CANCELLATION_REASON_BY_TYPE_REFERENCE,
      payload: getCancellationReasonByTypeReferenceApi(type),
    });
  };
};

const resetCancellationReasonByTypeReferenceAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_CANCELLATION_REASON_BY_TYPE_REFERENCE,
    });
  };
};

export {
  resetMasterDataReferenceAction,
  getMasterReferenceAction,
  getCancellationReasonByTypeReferenceAction,
  resetCancellationReasonByTypeReferenceAction,
};
