import React from 'react';
import PropTypes from 'prop-types';
import { Input, Icon } from 'semantic-ui-react';

const InputSearch = props => {
  const {
    value,
    handleChange,
    placeholder,
    inputStyle,
    inputInnerStyle,
  } = props;
  return (
    <Input
      icon
      iconPosition="left"
      placeholder={placeholder}
      name="searchTitle"
      value={value}
      onChange={handleChange}
      style={inputStyle}
    >
      <Icon name="search" />
      <input style={inputInnerStyle} />
    </Input>
  );
};
InputSearch.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  inputStyle: PropTypes.object,
  inputInnerStyle: PropTypes.object,
};
export default InputSearch;
