const MENU_SUMMARY = 'summary';
const MENU_DAILY = 'daily';
const MENU_PRICE = 'price';
const MENU_CUSTOM_PRICE = 'customPrices';
const MENU_HISTORY = 'transactionHistory';
const MENU_PERIOD = 'period';
const MENUS = [
  { value: 'summary', title: 'Summary Destination' },
  { value: 'daily', title: 'Daily Program' },
  { value: 'period', title: 'Stay Period' },
  { value: 'price', title: 'Price' },
];
const MENUS_WITH_TIER_PRICE = [
  { value: 'summary', title: 'Summary Destination' },
  { value: 'daily', title: 'Daily Program' },
  { value: 'period', title: 'Stay Period' },
  { value: 'customPrices', title: 'Prices' },
];
const MENUFULL = [
  { value: 'summary', title: 'Summary Destination' },
  { value: 'daily', title: 'Daily Program' },
  { value: 'period', title: 'Stay Period' },
  { value: 'price', title: 'Price' },
  { value: 'transactionHistory', title: 'Transaction History' },
];

export {
  MENU_SUMMARY,
  MENU_DAILY,
  MENU_PRICE,
  MENU_HISTORY,
  MENUS,
  MENUFULL,
  MENU_PERIOD,
  MENUS_WITH_TIER_PRICE,
  MENU_CUSTOM_PRICE,
};
