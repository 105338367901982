import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from 'semantic-ui-react';

const bookingDetailInformation = props => (
  <Grid divided="vertically">
    <Grid.Row columns={5}>
      <Grid.Column width={4}>
        <Button
          content="Download Invoice"
          icon="download"
          style={{
            background: 'transparent',
            color: '#417df4',
          }}
          loading={props.loadingInvoice}
          onClick={props.handleDownloadInvoice}
          id={props.id}
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <Button
          content="Download Schedule"
          icon="download"
          style={{
            background: 'transparent',
            color: '#417df4',
          }}
          id={props.id}
          onClick={props.handlePrintSchedule}
          loading={props.loadingSpecialAdjusment}
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <Button
          positive
          content="Add Special Adjustment"
          onClick={props.openModalSpecialAdjusment}
          id={props.id}
          name={props.nameCustomer}
          value={props.status}
        />
      </Grid.Column>
      <Grid.Column width={2}>
        <Button
          content={props.status === 'Booking_hold' ? 'Approve' : 'Pay'}
          positive={props.status === 'Booking_hold' ? true : false}
          style={
            props.status === 'Booking_hold'
              ? {}
              : { backgroundColor: 'orange', color: 'white' }
          }
          id={props.id}
          value={props.status}
          name={props.status === 'Booking_hold' ? 'Approve' : 'Confirm'}
          onClick={props.actionBookingConfirmation}
        />
      </Grid.Column>
      <Grid.Column width={2}>
        <Button
          negative
          content={
            props.status === 'Booking_hold' ? 'Reject' : 'Cancel Booking'
          }
        />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

bookingDetailInformation.propTypes = {
  loadingInvoice: PropTypes.bool,
  handleDownloadInvoice: PropTypes.func,
  id: PropTypes.string,
  handlePrintSchedule: PropTypes.func,
  loadingSpecialAdjusment: PropTypes.bool,
  openModalSpecialAdjusment: PropTypes.func,
  nameCustomer: PropTypes.string,
  status: PropTypes.string,
  actionBookingConfirmation: PropTypes.func,
};

export default bookingDetailInformation;
