import React, { Component } from 'react';
import { Table, Button, Checkbox } from 'semantic-ui-react';
import PropTypes from 'prop-types';
// import { HeaderTableImage } from './headerTabel';
// import { StyleButton } from '../common/styleCommon';
// import IndexPagination from '../genericFunction/indexPagination';
import Pagination from '../../../../../components/common/pagination'; // '../../common/pagination'; // '../common/pagination';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../../../scripts/pagination';
import styles from './styles';
// import FilterData from '../../scripts/filterDataTable';
class tableImage extends Component {
  // const tableAccommodation = props => {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
    };
  }
  static propTypes = {
    data: PropTypes.array,
  };
  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  handleDelete = (e, { value, id }) => {
    const { deleteData, listData, handleDeleteImages } = this.props;
    let { activePage } = this.state;
    deleteData(value);
    let pagedData = datasetPagination(listData ? listData : [], 3);
    let currentList = pagedData[activePage - 1];
    if (currentList.length === 1 && activePage !== 1) {
      activePage = activePage - 1;
      this.setState({ activePage });
    } else if (currentList.length === 1 && activePage === 1) {
      activePage = 1;
      this.setState({ activePage });
    }
    handleDeleteImages && handleDeleteImages(id);
  };
  render() {
    const props = this.props;
    const { activePage } = this.state;
    let pagedData = datasetPagination(props.listData ? props.listData : [], 3);
    let listData = pagedData[activePage - 1];
    let { numbersFrom, numbersTo } = getNumberFromTo(listData, activePage, 3);
    let initialnumber = (activePage - 1) * 3;
    return (
      <div>
        <Table basic="very" selectable>
          <Table.Header>
            <Table.Row>
              {['File Name', 'Thumbnail', 'Action'].map(
                (headTableContent, index) => {
                  return (
                    <Table.HeaderCell key={index}>
                      {headTableContent}
                    </Table.HeaderCell>
                  );
                }
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {listData && listData.length > 0 ? (
              listData.map((data, idx) => {
                return (
                  <Table.Row key={`img${idx}`}>
                    <Table.Cell>
                      <Button
                        name={`img${idx}`}
                        key={idx}
                        content={data.ImageName ? data.ImageName : ''}
                        style={styles.buttonTransparent}
                        onClick={props.openDetail}
                        disabled={props.disabled}
                        value={initialnumber + idx}
                      />
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Checkbox
                        name={initialnumber + idx}
                        value={initialnumber + idx}
                        checked={data.IsPrimaryImage}
                        onChange={props.handleChangePrimaryImage}
                        disabled={props.disabled}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        content="Delete"
                        negative
                        value={initialnumber + idx}
                        id={data.ImageId}
                        onClick={this.handleDelete}
                        disabled={props.disabled}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })
            ) : (
              <Table.Row>
                <Table.Cell colSpan="4" textAlign="center">
                  No Image
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        <Pagination
          pagedData={pagedData}
          numbersTo={numbersTo}
          numbersFrom={numbersFrom}
          // data={props.listData}
          data={props.listData}
          pageChange={this.pageChange}
          activePage={activePage}
        />
        {/* )} */}
      </div>
    );
  }
}

tableImage.propTypes = {
  //   profileImageList: PropTypes.array,
  listData: PropTypes.array,
  //   addMasterReference: PropTypes.func,
  //   handleChangeMasterData: PropTypes.func,
  //   deleteDataReference: PropTypes.func,
  //   pattern: PropTypes.string,
  openDetail: PropTypes.func,
  deleteData: PropTypes.func,
  disabled: PropTypes.bool,
  handleChangePrimaryImage: PropTypes.func,
  //   pagedData: PropTypes.array,
  //   idxPagination: PropTypes.number,
  //   pageChange: PropTypes.func,
  //   activePage: PropTypes.string,
  //   numbersFrom: PropTypes.number,
  //   numbersTo: PropTypes.number,
  //   data: PropTypes.array,
  handleDeleteImages: PropTypes.func,
};
export default tableImage;
