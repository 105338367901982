import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header } from 'semantic-ui-react';
import {
  TextAreaFluidOnPage,
  DoubleDropdownWithErrorOnFluid,
  DoubleTextFieldWithErrorOnFluid,
  DoubleTextFieldWithError,
} from '../../../../components/common/fieldWithError';
import { StylesTextfieldWithError } from '../../../../components/common/styleCommon';
const ContactInfo = props => (
  <Grid columns="equal">
    <Grid.Row stretched>
      <Grid.Column width={15}>
        <Header as="h3" content="Contact Information" />
        <TextAreaFluidOnPage
          placeholder="Address"
          name="Address1"
          title="Address"
          handleChange={props.handleChange}
          data={props.contactObject.Address1}
          errors={props.errors.Address1}
          required={true}
          style={
            props.disablePage
              ? StylesTextfieldWithError.textAreaOnFluidDisabled
              : StylesTextfieldWithError.textAreaOnFluid
          }
          disable={props.disablePage}
        />
        <DoubleTextFieldWithError
          placeholder1="Address 2"
          placeholder2="Address 3"
          name1="Address2"
          name2="Address3"
          title1="Address 2"
          title2="Address 3"
          handleChange={props.handleChange}
          data1={props.contactObject.Address2}
          data2={props.contactObject.Address3}
          disable1={props.disablePage}
          disable2={props.disablePage}
        />
        <TextAreaFluidOnPage
          placeholder="GPS Address"
          name="Landmark"
          title="GPS Address"
          handleChange={props.handleChange}
          data={props.contactObject.Landmark}
          //required={true}
          style={
            props.disablePage
              ? StylesTextfieldWithError.textAreaOnFluidDisabled
              : StylesTextfieldWithError.textAreaOnFluid
          }
          disable={props.disablePage}
        />
        <DoubleDropdownWithErrorOnFluid
          placeholder1="Country"
          placeholder2="Region"
          name1="CountryId"
          name2="RegionId"
          title1="Country"
          title2="Region"
          handleChange={props.onChangeDropdown}
          option1={props.listCountry}
          option2={props.listRegion}
          valueDropDown1={props.contactObject.CountryId}
          valueDropDown2={props.contactObject.RegionId}
          error1={props.errors.CountryId}
          error2={props.errors.RegionId}
          requiredDropdownLeft={true}
          requiredDropdownRight={true}
          styleDropdownLeft={StylesTextfieldWithError.sizeDoubleComponent}
          styleDropdownRight={StylesTextfieldWithError.doubleDropdown}
          loadingDropdown1={props.loadingTransportation}
          loadingDropdown2={props.loadingTransportation}
          disable={props.disablePage}
        />
        <DoubleDropdownWithErrorOnFluid
          placeholder1="City"
          placeholder2="Area"
          name1="CityId"
          name2="AreaId"
          title1="City"
          title2="Area"
          handleChange={props.onChangeDropdown}
          option1={props.listCity}
          option2={props.listArea}
          valueDropDown1={props.contactObject.CityId}
          valueDropDown2={props.contactObject.AreaId}
          error1={props.errors.CityId}
          error2={props.errors.AreaId}
          requiredDropdownLeft={true}
          requiredDropdownRight={true}
          styleDropdownLeft={StylesTextfieldWithError.sizeDoubleComponent}
          styleDropdownRight={StylesTextfieldWithError.doubleDropdown}
          loadingDropdown1={props.loadingTransportation}
          loadingDropdown2={props.loadingTransportation}
          disable={props.disablePage}
        />
        <DoubleTextFieldWithErrorOnFluid
          placeholder1="Phone Number"
          placeholder2="Email"
          name1="PhoneNbr"
          name2="Email"
          type1="number"
          type2="text"
          title1="Phone Number"
          title2="Email"
          handleChange={props.handleChange}
          data1={props.contactObject.PhoneNbr}
          data2={props.contactObject.Email}
          error2={props.errors.Email}
          error1={props.errors.PhoneNbr}
          requiredFieldLeft={true}
          requiredFieldRight={true}
          styleFieldRight={StylesTextfieldWithError.doubleTextFieldRight}
          styleFieldLeft={StylesTextfieldWithError.doubleTextField}
          disable1={props.disablePage}
          disable2={props.disablePage}
        />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);
ContactInfo.propTypes = {
  contactObject: PropTypes.shape({
    PhoneNbr: PropTypes.string,
    Email: PropTypes.string,
    AreaId: PropTypes.string,
    CityId: PropTypes.string,
    RegionId: PropTypes.string,
    CountryId: PropTypes.string,
    Address1: PropTypes.string,
    Address2: PropTypes.string,
    Address3: PropTypes.string,
    Landmark: PropTypes.string,
  }),
  listRegion: PropTypes.array,
  listCountry: PropTypes.array,
  listCity: PropTypes.array,
  listArea: PropTypes.array,
  handleChange: PropTypes.func,
  onChangeDropdown: PropTypes.func,
  handleChangeAddress: PropTypes.func,
  errors: PropTypes.shape({
    Address1: PropTypes.string,
    PhoneNbr: PropTypes.string,
    Email: PropTypes.string,
    CityId: PropTypes.string,
    RegionId: PropTypes.string,
    CountryId: PropTypes.string,
    AreaId: PropTypes.string,
  }),
  loadingTransportation: PropTypes.bool,
  disablePage: PropTypes.bool,
};
export default ContactInfo;
