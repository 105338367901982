import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input, Grid, Segment, Dimmer, Form, Button } from 'semantic-ui-react';
import {
  getDataTransferLogsAction,
  getDataTransferLogsByIdAction,
  getDataTransferLogsByRangeAction,
  resetDataTransferLogsAction,
  resetDataTransferLogsByIdAction,
  getDataTransferLogsByReferenceIdAction,
  putDataTransferLogsAction,
} from './dataTransferLogsAction';
import {
  postResendDataTourTransactionAction,
  resetStatusTransactionAction,
} from '../../actions/tourTransactionAction';
import PropTypes from 'prop-types';
import { HeaderTableDataTransferLogs } from './constants/headerTableDataTransferLogs';
import HeaderTitle from '../../components/common/headerTitle';
import Pagination from '../../components/common/pagination';
import TableDataTransferLog from './components/TableDataTransferLog';
import ModalDataDetail from './components/modalDataDetail';
import { datasetPagination, getNumberFromTo } from '../../scripts/pagination';
import FilterData from './script/filterDataTable';
import { decodeXML } from './script/decodeXML';
import { encodeXML } from './script/encodeXML';
import ModalAlert from '../../components/modal/modalAlert';

class DataTransferLogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: '',
      activePage: 1,
      fromLine: 1,
      toLine: 100,
      loadingData: true,
      failedArea: false,
      modalOpen: false,
      headerAlert: '',
      contentAlert: '',
      request: '',
      response: '',
      dataId: null,
    };
  }

  decode = async (string, objectname) => {
    let str = await decodeXML(string);
    this.setState({ ...this.state, [objectname]: str });
    // console.log(this.state[objectname]);
  };

  handleOpenModal = () => {
    this.setState({
      modalOpen: true,
    });
  };

  openModalDataDetail = async (e, { id, request, response }) => {
    await this.decode(request, 'request');
    await this.decode(response, 'response');
    await this.setState({ ...this.state, dataId: id });
    await this.handleOpenModal();
    // console.log(request);
  };

  handleCloseModal = () => {
    this.setState({
      modalOpen: false,
      failedArea: false,
    });
  };

  async loadDataWithRange(from, to) {
    this.setState({ loadingData: true });
    await this.props.getDataTransferLogsByRangeAction(from, to);
  }

  setFilter = () => {
    let from = this.state.fromLine;
    let to = this.state.toLine;
    this.setState({ ...this.state, modalOpen: false });
    this.loadDataWithRange(from, to);
  };

  handleChangeFilter = (e, { name, value }) => {
    this.setState({ ...this.state, [name]: value });
  };

  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });

  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };

  async loadDataUsingID(id) {
    this.setState({ loadingData: true });
    await this.props.getDataTransferLogsByIdAction(id);
  }

  searchById = (e, { id }) => {
    this.loadDataUsingID(id);
  };

  setOtherFailedAlert = message => {
    message = message ? message : 'Failed';
    this.setState({
      headerAlert: 'Failed',
      contentAlert: message,
      failedArea: true,
      loadingData: false,
    });
  };

  componentDidMount = () => {
    this.props.getDataTransferLogsByRangeAction(
      this.state.fromLine,
      this.state.toLine
    );
  };

  componentDidUpdate = () => {
    if (this.props.getDataTransferLogsByRangeStatus === 'success') {
      this.setState({ loadingData: false, searchInput: '' });
      this.props.resetDataTransferLogsAction();
      this.props.resetDataTransferLogsByIdAction();
    } else if (this.props.getDataTransferLogsByRangeStatus === 'failed') {
      if (this.props.errorMessage) {
        this.setState({
          headerAlert: 'Failed',
          contentAlert: this.props.errorMessage,
          failedArea: true,
          loadingData: false,
        });
      } else {
        this.setOtherFailedAlert('Out of range');
      }
      this.props.resetDataTransferLogsAction();
    }
    if (this.props.getDataTransferLogsByIdStatus === 'success') {
      this.setState({ loadingData: false });
      this.props.resetDataTransferLogsAction();
    } else if (this.props.getDataTransferLogsByIdStatus === 'failed') {
      if (this.props.errorMessage) {
        this.setState({
          headerAlert: 'Failed',
          contentAlert: this.props.errorMessage,
          failedArea: true,
          loadingData: false,
        });
      } else {
        this.setOtherFailedAlert('Out of range');
      }
      this.props.resetDataTransferLogsAction();
    } else if (this.props.putDataTransferLogsStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Save Data Succesfully',
        loadingData: false,
        failedArea: true,
      });
      this.props.resetDataTransferLogsAction();
      this.props.getDataTransferLogsByRangeAction(
        this.state.fromLine,
        this.state.toLine
      );
    } else if (this.props.putDataTransferLogsStatus === 'failed') {
      if (this.props.errorMessage) {
        this.setState({
          headerAlert: 'Failed',
          contentAlert: this.props.errorMessage,
          failedArea: true,
          loadingData: false,
        });
      } else {
        this.setOtherFailedAlert('Unknown Error');
      }
      this.props.resetDataTransferLogsAction();
    }
    if (this.props.postResendDataTourTransactionStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Data is resent successfully',
        loadingData: false,
        failedArea: true,
      });
      this.props.resetDataTransferLogsAction();
      this.props.resetStatusTransactionAction();
      this.props.getDataTransferLogsByRangeAction(
        this.state.fromLine,
        this.state.toLine
      );
    } else if (this.props.postResendDataTourTransactionStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorMessage,
        failedArea: true,
        loadingData: false,
      });
      this.props.resetDataTransferLogsAction();
      this.props.resetStatusTransactionAction();
      this.props.getDataTransferLogsByRangeAction(
        this.state.fromLine,
        this.state.toLine
      );
    }
  };

  parseObjToArray = obj => {
    let arr = new Array(obj);
    return arr;
  };

  saveRequest = async (id, request) => {
    this.handleCloseModal();
    let encodedXML = await encodeXML(request);
    let data = {
      Id: id,
      Request: encodedXML,
    };
    if (request !== '') {
      await this.setState({ ...this.state, loadingData: true });
      await this.props.putDataTransferLogsAction(id, data);
    } else {
      this.setState({
        headerAlert: 'Warning',
        contentAlert: 'Nothing change to save',
        failedArea: true,
        loadingData: false,
      });
    }
  };

  resendData = async (e, { id }) => {
    await this.handleCloseModal();
    await this.setState({ ...this.state, loadingData: true });
    await this.props.postResendDataTourTransactionAction(id);
  };

  render() {
    let {
      searchInput,
      loadingData,
      activePage,
      failedArea,
      fromLine,
      toLine,
      request,
      response,
      dataId,
    } = this.state;
    let { getDataTransferLogsById, getDataTransferLogsByRange } = this.props;
    let dataToShow = getDataTransferLogsById.Id
      ? this.parseObjToArray(getDataTransferLogsById)
      : getDataTransferLogsByRange;
    let filterData = FilterData(dataToShow, 'Id', searchInput);
    let pagedData = datasetPagination(filterData, 10);
    let listData = pagedData[activePage - 1];
    let { numbersFrom, numbersTo } = getNumberFromTo(listData, activePage, 10);
    let openModalAlert = failedArea;

    return (
      <div>
        <Dimmer.Dimmable blurring dimmed={false}>
          <Grid columnHeaderTitles="equal" style={{ marginTop: '30px' }}>
            <Grid.Row stretched>
              <Grid.Column width={15}>
                <Segment>
                  <HeaderTitle title="Data Transfer Logs" />
                  <Grid relaxed verticalAlign="bottom">
                    <Grid.Row style={{ paddingTop: '0px' }}>
                      <Grid.Column width={4}>
                        <Input
                          icon="search"
                          placeholder="search by ID"
                          name="searchInput"
                          value={searchInput}
                          onChange={this.handleChange}
                        />
                      </Grid.Column>
                      <Grid.Column width={12}>
                        <Form>
                          <label>Show Data Entries:</label>
                          <Form.Group style={{ marginBottom: '0px' }}>
                            <Form.Input
                              placeholder="From"
                              name="fromLine"
                              value={fromLine}
                              onChange={this.handleChangeFilter}
                            />
                            <Form.Input
                              placeholder="To"
                              name="toLine"
                              value={toLine}
                              onChange={this.handleChangeFilter}
                            />
                            <Button onClick={this.setFilter} color="blue">
                              Submit
                            </Button>
                          </Form.Group>
                        </Form>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <TableDataTransferLog
                    headerTable={HeaderTableDataTransferLogs}
                    dataRange={listData}
                    pageChange={this.pageChange}
                    listData={listData}
                    pagedData={pagedData}
                    loading={loadingData}
                    openModalDataDetail={this.openModalDataDetail}
                  />
                  {searchInput !== '' ? (
                    <Grid textAlign="center">
                      <Grid.Row columns={1} style={{ paddingBottom: '0px' }}>
                        <label>
                          Not find for your search ? Try Search For Older Data
                        </label>
                      </Grid.Row>
                      <Grid.Row columns={1}>
                        <Button
                          id={searchInput}
                          onClick={this.searchById}
                          color="yellow"
                        >
                          Search For Older Data
                        </Button>
                      </Grid.Row>
                    </Grid>
                  ) : (
                    ''
                  )}
                  <Pagination
                    pagedData={pagedData}
                    numbersTo={numbersTo}
                    numbersFrom={numbersFrom}
                    data={this.props.getDataTransferLogsByRange}
                    pageChange={this.pageChange}
                    activePage={this.state.activePage}
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <ModalDataDetail
              openModal={this.state.modalOpen}
              handleClose={this.handleCloseModal}
              request={request}
              response={response}
              saveRequest={this.saveRequest}
              resendData={this.resendData}
              id={dataId}
            />
            <ModalAlert
              openModal={openModalAlert}
              handleClose={this.handleCloseModal}
              header={this.state.headerAlert}
              content={this.state.contentAlert}
              nameButton="OK"
            />
          </Grid>
        </Dimmer.Dimmable>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    getDataTransferLogs: state.DataTransferLogsStore.getDataTransferLogs,
    getDataTransferLogsById:
      state.DataTransferLogsStore.getDataTransferLogsById,
    getDataTransferLogsByIdStatus:
      state.DataTransferLogsStore.getDataTransferLogsByIdStatus,
    getDataTransferLogsByRange:
      state.DataTransferLogsStore.getDataTransferLogsByRange,
    getDataTransferLogsByRangeStatus:
      state.DataTransferLogsStore.getDataTransferLogsByRangeStatus,
    getDataTransferLogsByReferenceId:
      state.DataTransferLogsStore.getDataTransferLogsByReferenceId,
    getDataTransferLogsByReferenceIdStatus:
      state.DataTransferLogsStore.getDataTransferLogsByReferenceIdStatus,
    postDataTransferLogsStatus:
      state.DataTransferLogsStore.postDataTransferLogsStatus,
    putDataTransferLogsStatus:
      state.DataTransferLogsStore.putDataTransferLogsStatus,
    deleteDataTransferLogsStatus:
      state.DataTransferLogsStore.deleteDataTransferLogsStatus,
    postResendDataTourTransactionStatus:
      state.TourTransactionStore.postResendDataTourTransactionStatus,
    errorMessage:
      state.DataTransferLogsStore.errors.global === undefined
        ? state.TourTransactionStore.errors.global
        : state.DataTransferLogsStore.errors.global,
    loading: state.DataTransferLogsStore.loading,
  };
}
DataTransferLogs.propTypes = {
  title: PropTypes.string,
  pagedData: PropTypes.array,
  numbersTo: PropTypes.number,
  numbersFrom: PropTypes.number,
  data: PropTypes.array,
  getDataTransferLogsAction: PropTypes.func,
  getDataTransferLogsByIdAction: PropTypes.func,
  getDataTransferLogsByRangeAction: PropTypes.func,
  getDataTransferLogsByReferenceIdAction: PropTypes.func,
  resetDataTransferLogsAction: PropTypes.func,
  resetDataTransferLogsByIdAction: PropTypes.func,
  putDataTransferLogsAction: PropTypes.func,
  putDataTransferLogsStatus: PropTypes.string,
  getDataTransferLogs: PropTypes.array,
  getDataTransferLogsStatus: PropTypes.string,
  getDataTransferLogsById: PropTypes.array,
  getDataTransferLogsByIdStatus: PropTypes.string,
  getDataTransferLogsByRange: PropTypes.array,
  getDataTransferLogsByRangeStatus: PropTypes.string,
  getDataTransferLogsByReferenceId: PropTypes.array,
  getDataTransferLogsByReferenceIdStatus: PropTypes.string,
  postResendDataTourTransactionAction: PropTypes.func,
  postResendDataTourTransactionStatus: PropTypes.string,
  resetStatusTransactionAction: PropTypes.func,
  errorMessage: PropTypes.string,
};

export default connect(
  mapStateToProps,
  {
    getDataTransferLogsAction,
    getDataTransferLogsByIdAction,
    getDataTransferLogsByRangeAction,
    getDataTransferLogsByReferenceIdAction,
    resetDataTransferLogsAction,
    resetDataTransferLogsByIdAction,
    putDataTransferLogsAction,
    postResendDataTourTransactionAction,
    resetStatusTransactionAction,
  }
)(DataTransferLogs);
