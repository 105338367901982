import React from 'react';
import { Table } from 'semantic-ui-react';
import { getDay } from '../../../../scripts/getDay';
import moment from 'moment';
import PropTypes from 'prop-types';
import LoaderModal from '../../../../components/modal/loaderModal';
import { Link } from 'react-router-dom';

const TablePublishPackage = props => (
  <Table basic="very" selectable fixed>
    <LoaderModal loading={props.loading} />
    <Table.Header>
      <Table.Row>
        {props.headerTable.map((headTableContent, index) => {
          return (
            <Table.HeaderCell key={index}>{headTableContent}</Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {props.listData ? (
        props.listData.map((publish, idx) => {
          return (
            <Table.Row key={idx}>
              <Table.Cell component="th" scope="row" width={1}>
                <Link to={`/Package-Details/Fixed/edit/${publish.Id}`}>
                  {/* <Link to={`/Package-Details/Fixed/${publish.Id}`}> */}
                  {publish.Title}
                </Link>
              </Table.Cell>
              <Table.Cell>{publish.FixedPackage.RegisteringGuest}</Table.Cell>
              <Table.Cell>{publish.FixedPackage.ConfirmedGuest}</Table.Cell>
              <Table.Cell>
                {publish.FixedPackage.MinimumGuest
                  ? publish.FixedPackage.MinimumGuest
                  : 0 - publish.FixedPackage.ConfirmedGuest
                    ? publish.FixedPackage.ConfirmedGuest
                    : 0}
              </Table.Cell>
              <Table.Cell>{publish.Country.Name}</Table.Cell>
              <Table.Cell>{moment(publish.StartDate).format('ll')}</Table.Cell>
              <Table.Cell>
                {getDay(publish.StartDate, publish.EndDate)}
              </Table.Cell>
            </Table.Row>
          );
        })
      ) : (
        <Table.Row>
          <Table.Cell colSpan="8" textAlign="center">
            No Data
          </Table.Cell>
        </Table.Row>
      )}

      {/* {props.listData &&
        props.listData.map((publish, idx) => {
          return (
            <Table.Row key={idx}>
              <Table.Cell component="th" scope="row" width={1}>
                <Link to={`/Package-Details/Fixed/${publish.Id}`}>
                  {publish.Title}
                </Link>
              </Table.Cell>
              <Table.Cell>{publish.FixedPackage.RegisteringGuest}</Table.Cell>
              <Table.Cell>{publish.FixedPackage.ConfirmedGuest}</Table.Cell>
              <Table.Cell>
                {publish.FixedPackage.MinimumGuest
                  ? publish.FixedPackage.MinimumGuest
                  : 0 - publish.FixedPackage.ConfirmedGuest
                    ? publish.FixedPackage.ConfirmedGuest
                    : 0}
              </Table.Cell>
              <Table.Cell>{publish.Country.Name}</Table.Cell>
              <Table.Cell>{moment(publish.StartDate).format('ll')}</Table.Cell>
              <Table.Cell>
                {getDay(publish.StartDate, publish.EndDate)}
              </Table.Cell>
            </Table.Row>
          );
        })} */}
    </Table.Body>
  </Table>
);

TablePublishPackage.propTypes = {
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  loading: PropTypes.bool,
};
export default TablePublishPackage;
