const validatePhone = phone => {
  // eslint-disable-next-line
  return phone.match(/^(\([0-9]{3}\)\s*|[0-9]{3}\-)[0-9]{3}-[0-9]{4}$/);
};
const validationPhoneNumber = value => {
  // eslint-disable-next-line
  return /^[+]{0,1}[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g.test(value);
};

const validationEmail = email => {
  return email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
};

const copyObject = obj => {
  let newObj = JSON.stringify(obj);
  return JSON.parse(newObj);
};

const validationShortRegisCompany = data => {
  let copyData = copyObject(data);
  let {
    companyName,
    companyCountry,
    companyTelephone,
    companyAddress,
    companyCurrency,
    userFirstName,
    userLastName,
    userEmail,
    userPhoneNumber,
  } = copyData;
  let messageRequired = 'This section must be filled';
  let isValid = true;

  if (companyName.data === '') {
    companyName.error = true;
    companyName.message = messageRequired;
    isValid = isValid && false;
  } else {
    companyName.error = false;
    companyName.message = '';
    isValid = isValid && true;
  }

  if (companyCountry.data === '') {
    companyCountry.error = true;
    companyCountry.message = messageRequired;
    isValid = isValid && false;
  } else {
    companyCountry.error = false;
    companyCountry.message = '';
    isValid = isValid && true;
  }

  if (companyTelephone.data === '') {
    companyTelephone.error = true;
    companyTelephone.message = messageRequired;
    isValid = isValid && false;
  } else {
    companyTelephone.error = false;
    companyTelephone.message = '';
    isValid = isValid && true;
  }

  if (companyAddress.data === '') {
    companyAddress.error = true;
    companyAddress.message = messageRequired;
    isValid = isValid && false;
  } else {
    companyAddress.error = false;
    companyAddress.message = '';
    isValid = isValid && true;
  }

  if (companyCurrency.data === '') {
    companyCurrency.error = true;
    companyCurrency.message = messageRequired;
    isValid = isValid && false;
  } else {
    companyCurrency.error = false;
    companyCurrency.message = '';
    isValid = isValid && true;
  }

  if (userFirstName.data === '') {
    userFirstName.error = true;
    userFirstName.message = messageRequired;
    isValid = isValid && false;
  } else {
    userFirstName.error = false;
    userFirstName.message = '';
    isValid = isValid && true;
  }

  if (userLastName.data === '') {
    userLastName.error = true;
    userLastName.message = messageRequired;
    isValid = isValid && false;
  } else {
    userLastName.error = false;
    userLastName.message = '';
    isValid = isValid && true;
  }

  if (userEmail.data === '') {
    userEmail.error = true;
    userEmail.message = messageRequired;
    isValid = isValid && false;
  } else {
    if (!validationEmail(userEmail.data)) {
      userEmail.error = true;
      userEmail.message = 'invalid email format';
      isValid = isValid && false;
    } else {
      userEmail.error = false;
      userEmail.message = '';
      isValid = isValid && true;
    }
  }

  if (userPhoneNumber.data === '') {
    userPhoneNumber.error = true;
    userPhoneNumber.message = messageRequired;
    isValid = isValid && false;
  } else {
    userPhoneNumber.error = false;
    userPhoneNumber.message = '';
    isValid = isValid && true;
  }

  return { data: copyData, isValid };
};
export {
  validatePhone,
  validationEmail,
  validationShortRegisCompany,
  validationPhoneNumber,
};
