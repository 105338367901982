import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';
import { DropdownFluidOnPage } from '../../../components/common/fieldWithError';
// import { StyleModal } from '../../assets/styles/size';
import ButtonClose from '../../../components/button/buttonCloseModal';
import Loader from '../../../components/modal/loaderModal';

const ModalPaymentMethod = props => {
  return (
    <Modal
      dimmer="blurring"
      // inverted
      open={props.open}
      onClose={props.handleClose}
      size="small"
      style={{ width: '400px' }}
    >
      <Modal.Header>
        {props.header} <ButtonClose onClose={props.handleClose} />
      </Modal.Header>
      <Modal.Content>
        <Loader loading={props.loading} />
        <DropdownFluidOnPage
          placeholder="Country"
          name="CountryId"
          title="Select Country"
          handleChange={props.handleChange}
          option={props.listCountry}
          fluid={true}
          valueDropDown={props.contentField.CountryId}
          //error={props.errorInformation.CountryError}
        />
        <DropdownFluidOnPage
          placeholder="Payment Method"
          name="PaymentGateawayChoice"
          title="Payment Method"
          handleChange={props.handleChangePaymentMethod}
          option={props.listPaymentMethod}
          multiple={true}
          fluid={true}
          valueDropDown={props.paymentMethodList}
          //error={props.errorInformation.CountryError}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          onClick={props.savePaymentMethod}
          content="Save"
          name="Save"
        />
      </Modal.Actions>
    </Modal>
  );
};

ModalPaymentMethod.propTypes = {
  handleChange: PropTypes.func,
  open: PropTypes.bool,
  handleChangePaymentMethod: PropTypes.func,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  contentField: PropTypes.shape({
    CountryId: PropTypes.string,
  }),
  savePaymentMethod: PropTypes.func,
  errorInformation: PropTypes.shape({}),
  listPaymentMethod: PropTypes.array,
  listCountry: PropTypes.array,
  paymentMethodList: PropTypes.array,
  loading: PropTypes.bool,
};
export default ModalPaymentMethod;
