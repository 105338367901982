import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Form } from 'semantic-ui-react';
import InputWithLabel from '../../../../shared/components/common/input/InputWithLabel';

const AdditionalCostSetting = props => {
  const {
    data,
    transportationTypeList,
    countriesList,
    cityInOriginCountryList,
    cityInDestinationCountryList,
    currenciesList,
    handleChangeInput,
    errors,
    // loading props
    // loading,
    additionalCostLoading,
    transportationTypeLoading,
    countriesLoading,
    cityInOriginCountryLoading,
    cityInDestinationCountryLoading,
    currenciesLoading,
    // additionalCostTypeLoading,
    saveLoading,
  } = props;
  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Header as="h3" content="Additional Cost Setting" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="equal">
          <Grid.Column>
            <Form>
              <Form.Field>
                <InputWithLabel
                  label="Origin Country"
                  type="select"
                  name="OriginalCountry"
                  required
                  fluid={true}
                  placeholder="Origin Country"
                  options={countriesList}
                  value={
                    data && data.OriginalCountry && data.OriginalCountry.Value
                  }
                  loading={
                    countriesLoading || additionalCostLoading || saveLoading
                  }
                  handleChange={handleChangeInput}
                  validate={errors}
                />
              </Form.Field>
            </Form>
          </Grid.Column>
          <Grid.Column>
            <Form>
              <Form.Field>
                <InputWithLabel
                  label="Destination Country"
                  type="select"
                  name="DestinationCountry"
                  required
                  fluid={true}
                  placeholder="Destination Country"
                  options={countriesList}
                  value={
                    data &&
                    data.DestinationCountry &&
                    data.DestinationCountry.Value
                  }
                  loading={
                    countriesLoading || additionalCostLoading || saveLoading
                  }
                  handleChange={handleChangeInput}
                  validate={errors}
                />
              </Form.Field>
            </Form>
          </Grid.Column>
          <Grid.Column>
            <Form>
              <Form.Field>
                <InputWithLabel
                  label="Origin City"
                  type="select"
                  name="OriginalCity"
                  required
                  fluid={true}
                  placeholder="Origin City"
                  options={cityInOriginCountryList}
                  value={data && data.OriginalCity && data.OriginalCity.Value}
                  loading={
                    cityInOriginCountryLoading ||
                    additionalCostLoading ||
                    saveLoading
                  }
                  handleChange={handleChangeInput}
                  validate={errors}
                />
              </Form.Field>
            </Form>
          </Grid.Column>
          <Grid.Column>
            <Form>
              <Form.Field>
                <InputWithLabel
                  label="Destination City"
                  type="select"
                  name="DestinationCity"
                  required
                  fluid={true}
                  placeholder="Destination City"
                  options={cityInDestinationCountryList}
                  value={
                    data && data.DestinationCity && data.DestinationCity.Value
                  }
                  loading={
                    cityInDestinationCountryLoading ||
                    additionalCostLoading ||
                    saveLoading
                  }
                  handleChange={handleChangeInput}
                  validate={errors}
                />
              </Form.Field>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="equal">
          <Grid.Column>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Form>
                    <Form.Field>
                      <InputWithLabel
                        label="Transportation Type"
                        type="select"
                        name="TransportationType"
                        required
                        fluid={true}
                        placeholder="Transportation Type"
                        options={transportationTypeList}
                        value={
                          data &&
                          data.TransportationType &&
                          parseInt(data.TransportationType.Value)
                        }
                        loading={
                          transportationTypeLoading ||
                          additionalCostLoading ||
                          saveLoading
                        }
                        handleChange={handleChangeInput}
                        validate={errors}
                      />
                    </Form.Field>
                  </Form>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>

          <Grid.Column>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Form>
                    <Form.Field>
                      <InputWithLabel
                        label="Currency"
                        type="select"
                        name="Currency"
                        required
                        fluid={true}
                        placeholder="Currency"
                        options={currenciesList}
                        value={data && data.Currency && data.Currency.Value}
                        loading={
                          currenciesLoading ||
                          additionalCostLoading ||
                          saveLoading
                        }
                        handleChange={handleChangeInput}
                        validate={errors}
                        clearable
                      />
                    </Form.Field>
                  </Form>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

AdditionalCostSetting.propTypes = {
  data: PropTypes.object,
  transportationTypeList: PropTypes.array,
  countriesList: PropTypes.array,
  cityInOriginCountryList: PropTypes.array,
  cityInDestinationCountryList: PropTypes.array,
  currenciesList: PropTypes.array,
  loading: PropTypes.bool,
  handleChangeInput: PropTypes.func,
  errors: PropTypes.object,

  additionalCostLoading: PropTypes.bool,
  transportationTypeLoading: PropTypes.bool,
  countriesLoading: PropTypes.bool,
  cityInOriginCountryLoading: PropTypes.bool,
  cityInDestinationCountryLoading: PropTypes.bool,
  currenciesLoading: PropTypes.bool,
  additionalCostTypeLoading: PropTypes.bool,
  saveLoading: PropTypes.bool,
};

export default React.memo(AdditionalCostSetting);
