export const filterDataEmployee = (data, searchInput) => {
  // console.log(data);
  return data.filter(item => {
    let fullname = `${item.FirstName} ${item.LastName}`;
    return (
      (item.Username !== null &&
        item.Username.toLowerCase().indexOf(searchInput.toLowerCase()) !==
          -1) ||
      (fullname !== null &&
        fullname.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1)
    );
  });
};
