import {
  GET_TOUR_GUIDE,
  RESET_TOUR_GUIDE,
  GET_TOUR_GUIDE_BY_ID,
  POST_TOUR_GUIDE,
  PUT_TOUR_GUIDE,
  DELETE_TOUR_GUIDE,
} from './tourGuideDetail/constant/actionTypes';
import getTourGuideApi from '../../api/tourGuide/getTourGuideApi';
import getTourGuideByIdApi from '../../api/tourGuide/getTourGuideByIdApi';
import postTourGuideApi from '../../api/tourGuide/postTourGuideApi';
import putTourGuideApi from '../../api/tourGuide/putTourGuideApi';
import deleteTourGuideApi from '../../api/tourGuide/deleteTourGuideApi';

const getTourGuideAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_TOUR_GUIDE,
      payload: getTourGuideApi(),
    });
  };
};

const resetTourGuideAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_TOUR_GUIDE,
    });
  };
};

const getTourGuideByIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_TOUR_GUIDE_BY_ID,
      payload: getTourGuideByIdApi(Id),
    });
  };
};

const postTourGuideAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_TOUR_GUIDE,
      payload: postTourGuideApi(data),
    });
  };
};

const putTourGuideAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_TOUR_GUIDE,
      payload: putTourGuideApi(id, data),
    });
  };
};

const deleteTourGuideAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: DELETE_TOUR_GUIDE,
      payload: deleteTourGuideApi(id, data),
    });
  };
};

export {
  getTourGuideAction,
  resetTourGuideAction,
  getTourGuideByIdAction,
  postTourGuideAction,
  putTourGuideAction,
  deleteTourGuideAction,
};
