import {
  CHECKIN,
  CHECKOUT,
  DAYEND,
  DRIVING,
  RECREATION,
  RETURNACCOMMODATION,
  LEAVEACCOMMODATION,
  DEPARTURE,
  ARRIVAL,
  FREETIME,
  TRANSIT,
  FLIGHTTIME,
  EAT,
  DAYSTART,
  VIRTUALCHECKIN,
  QUEUETIME,
  FREETIMELOCKED,
  VIRTUALLEAVEACCOMMODATION,
  VIRTUALRETURNACCOMMODATION,
  VIRTUALCHECKOUT,
} from '../constant/activityType';

export const IsShowButtonAddActivity = (movement, movements, index) => {
  //   let showButtonAdd = false;
  switch (movement.MovementName) {
    case CHECKIN:
      return [CHECKOUT, DAYEND].indexOf(movements[index + 1].MovementName) !==
        -1
        ? true
        : false;
    case CHECKOUT:
      return false;
    case DRIVING:
      return false;
    case RECREATION:
      return true;
    case RETURNACCOMMODATION:
      return false;
    case LEAVEACCOMMODATION:
      return false;
    case DEPARTURE:
      return false;
    case ARRIVAL:
      return false;
    case FREETIME:
      return true;
    case TRANSIT:
      return false;
    case FLIGHTTIME:
      return false;
    case EAT:
      return true;
    case DAYSTART:
      return [CHECKOUT, DAYEND].indexOf(movements[index + 1].MovementName) !==
        -1
        ? true
        : false;
    case DAYEND:
      return false;
    case VIRTUALCHECKIN:
      return movements[index + 1].MovementName === VIRTUALCHECKOUT
        ? true
        : false;
    case VIRTUALCHECKOUT:
      return false;
    case VIRTUALRETURNACCOMMODATION:
      return false;
    case VIRTUALLEAVEACCOMMODATION:
      return false;
    case FREETIMELOCKED:
      return false;
    case QUEUETIME:
      return false;
    default:
      return false;
  }
};
