import React from 'react';
import { Table, Form } from 'semantic-ui-react';
import InputCurrencyFormat from '../../../../../components/input/inputCurrencyFormat';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
const TablePrice = props => (
  <Table basic="very">
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Type</Table.HeaderCell>
        <Table.HeaderCell>Cost</Table.HeaderCell>
        <Table.HeaderCell>Price</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      <Table.Row>
        <Table.Cell>
          <Form.Field label="Sharing Room" required />
        </Table.Cell>{' '}
        <Table.Cell>
          <InputCurrencyFormat
            value={
              props.cost.SharingRoomPrice ? props.cost.SharingRoomPrice : 0
            }
            name={'SharingRoomPrice'}
            disabled={props.disabledCost}
            handelChangePrice={props.handleChangeCost}
            errors={props.errorsCost.SharingRoomPrice}
          />
        </Table.Cell>
        <Table.Cell>
          <InputCurrencyFormat
            value={
              props.price.SharingRoomPrice ? props.price.SharingRoomPrice : 0
            }
            name={'SharingRoomPrice'}
            disabled={props.disabled}
            handelChangePrice={props.handelChangePrice}
            errors={props.errors.SharingRoomPrice}
          />
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>
          <Form.Field label="Single Room" required />
        </Table.Cell>
        <Table.Cell>
          <InputCurrencyFormat
            value={props.cost.SingleRoomPrice ? props.cost.SingleRoomPrice : 0}
            name={'SingleRoomPrice'}
            disabled={props.disabledCost}
            handelChangePrice={props.handleChangeCost}
            errors={props.errorsCost.SingleRoomPrice}
          />
        </Table.Cell>
        <Table.Cell>
          <InputCurrencyFormat
            value={
              props.price.SingleRoomPrice ? props.price.SingleRoomPrice : 0
            }
            name={'SingleRoomPrice'}
            disabled={props.disabled}
            handelChangePrice={props.handelChangePrice}
            errors={props.errors.SingleRoomPrice}
          />
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>Adult Extra Bed</Table.Cell>
        <Table.Cell>
          <InputCurrencyFormat
            value={
              props.cost.AdultExtraBedPrice ? props.cost.AdultExtraBedPrice : 0
            }
            name={'AdultExtraBedPrice'}
            disabled={props.disabledCost}
            handelChangePrice={props.handleChangeCost}
          />
        </Table.Cell>
        <Table.Cell>
          <InputCurrencyFormat
            value={
              props.price.AdultExtraBedPrice
                ? props.price.AdultExtraBedPrice
                : 0
            }
            name={'AdultExtraBedPrice'}
            disabled={props.disabled}
            handelChangePrice={props.handelChangePrice}
          />
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>Child Extra Bed</Table.Cell>
        <Table.Cell>
          <InputCurrencyFormat
            value={
              props.cost.ChildExtraBedPrice ? props.cost.ChildExtraBedPrice : 0
            }
            name={'ChildExtraBedPrice'}
            disabled={props.disabledCost}
            handelChangePrice={props.handleChangeCost}
          />
        </Table.Cell>
        <Table.Cell>
          <InputCurrencyFormat
            value={
              props.price.ChildExtraBedPrice
                ? props.price.ChildExtraBedPrice
                : 0
            }
            name={'ChildExtraBedPrice'}
            disabled={props.disabled}
            handelChangePrice={props.handelChangePrice}
          />
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>Sharing Bed With Parent</Table.Cell>
        <Table.Cell>
          <InputCurrencyFormat
            value={props.cost.SharingBedPrice ? props.cost.SharingBedPrice : 0}
            name={'SharingBedPrice'}
            disabled={props.disabledCost}
            handelChangePrice={props.handleChangeCost}
          />
        </Table.Cell>
        <Table.Cell>
          <InputCurrencyFormat
            value={
              props.price.SharingBedPrice ? props.price.SharingBedPrice : 0
            }
            name={'SharingBedPrice'}
            disabled={props.disabled}
            handelChangePrice={props.handelChangePrice}
          />
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>No Bed</Table.Cell>
        <Table.Cell>
          <InputCurrencyFormat
            value={props.cost.NoBedPrice ? props.cost.NoBedPrice : 0}
            name={'NoBedPrice'}
            disabled={props.disabledCost}
            handelChangePrice={props.handleChangeCost}
            errors={props.errorsCost.NoBedPrice}
          />
        </Table.Cell>
        <Table.Cell>
          <InputCurrencyFormat
            value={props.price.NoBedPrice ? props.price.NoBedPrice : 0}
            name={'NoBedPrice'}
            disabled={props.disabled}
            handelChangePrice={props.handelChangePrice}
          />
        </Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
);

TablePrice.propTypes = {
  handelChangePrice: PropTypes.func,

  price: PropTypes.shape({
    SingleRoomPrice: PropTypes.number,
    SharingRoomPrice: PropTypes.number,
    AdultExtraBedPrice: PropTypes.number,
    SharingBedPrice: PropTypes.number,
    NoBedPrice: PropTypes.number,
    ChildExtraBedPrice: PropTypes.number,
  }),
  errors: PropTypes.shape({
    SingleRoomPrice: PropTypes.string,
    SharingRoomPrice: PropTypes.string,
    NoBedPrice: PropTypes.string,
  }),
  cost: PropTypes.shape({
    SingleRoomPrice: PropTypes.number,
    SharingRoomPrice: PropTypes.number,
    AdultExtraBedPrice: PropTypes.number,
    SharingBedPrice: PropTypes.number,
    NoBedPrice: PropTypes.number,
    ChildExtraBedPrice: PropTypes.number,
  }),
  disabled: PropTypes.bool,
  disabledCost: PropTypes.bool,
  handleChangeCost: PropTypes.func,
  errorsCost: PropTypes.shape({
    SingleRoomPrice: PropTypes.string,
    SharingRoomPrice: PropTypes.string,
    NoBedPrice: PropTypes.string,
  }),
};
export default TablePrice;
