import apiClient from './apiClient';
import {
  DELETE_COMPANIES_BY_ID_URL,
  GET_COMPANY_COMMISSIONS_URL,
  GET_COMPANY_PROFILE,
  GET_COMPANIES_BY_TYPE_URL,
} from './apiUrl';

const deleteCompaniesByIdApi = id => {
  return apiClient.delete(`${DELETE_COMPANIES_BY_ID_URL}/${id}`);
};

const getCompanyCommissionsApi = () => {
  return apiClient.get(GET_COMPANY_COMMISSIONS_URL);
};

const getCompanyCommissionsByCompanyCodeApi = companyCode => {
  return apiClient.get(
    `${GET_COMPANY_COMMISSIONS_URL}?companyCode=${companyCode}`
  );
};

const getCompanyCommissionByIdApi = id => {
  return apiClient.get(`${GET_COMPANY_COMMISSIONS_URL}?id=${id}`);
};

const postCompanyCommissionApi = data => {
  return apiClient.post(GET_COMPANY_COMMISSIONS_URL, data);
};

const putCompanyCommissionApi = (data, id) => {
  return apiClient.put(`${GET_COMPANY_COMMISSIONS_URL}?id=${id}`, data);
};

const deleteCompanyCommissionApi = id => {
  return apiClient.delete(`${GET_COMPANY_COMMISSIONS_URL}/${id}`);
};
const getCompaniesByTypeApi = type => {
  return apiClient.get(`${GET_COMPANIES_BY_TYPE_URL}?type=${type}`);
};

const getCompanyProfileApi = () => {
  return apiClient.get(GET_COMPANY_PROFILE);
};

export {
  deleteCompaniesByIdApi,
  getCompanyCommissionsApi,
  getCompanyCommissionByIdApi,
  postCompanyCommissionApi,
  putCompanyCommissionApi,
  getCompanyCommissionsByCompanyCodeApi,
  deleteCompanyCommissionApi,
  getCompanyProfileApi,
  getCompaniesByTypeApi,
};
