import React from 'react';
import { Button, Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { StyleButton } from './styleCommon';

const buttonPackageInTable = props => {
  return (
    <div>
      {props.isOperation || (
        <>
          <Popup
            content={props.popupContent.publish}
            trigger={
              <Button
                icon={
                  props.popupContent.publish === 'Publish'
                    ? 'cloud upload'
                    : 'cloud download'
                }
                style={StyleButton.buttonTransparent}
                onClick={props.buttonPublished}
                name={props.data.Id}
                value={props.data.PackageType}
                id={props.isSharingRoom}
              />
            }
          />
          <Popup
            content={props.popupContent.copy}
            trigger={
              <Button
                icon="copy outline"
                style={StyleButton.buttonTransparentGreen}
                onClick={props.buttonCopy}
                name={props.data.PackageType}
                value={props.data}
                date={props.data.StartDate}
              />
            }
          />
        </>
      )}
      <Popup
        content={props.popupContent.edit}
        trigger={
          <Button
            icon="pencil alternate"
            style={StyleButton.buttonTransparent}
            onClick={props.buttonEdit}
            name={props.data.PackageType}
            value={props.data.Id}
          />
        }
      />
      {props.isOperation || props.statusDelete || (
        <Popup
          content={props.popupContent.delete}
          trigger={
            <Button
              icon="delete"
              style={StyleButton.buttonTransparentRed}
              onClick={props.buttonDelete}
              name={props.data.Id}
              // value={props.data.Id}
            />
          }
        />
      )}
    </div>
  );
};
buttonPackageInTable.propTypes = {
  buttonPublished: PropTypes.func,
  buttonCopy: PropTypes.func,
  buttonEdit: PropTypes.func,
  buttonDelete: PropTypes.func,
  statusDelete: PropTypes.bool,
  data: PropTypes.shape({
    Id: PropTypes.number,
    PackageType: PropTypes.string,
    StartDate: PropTypes.string,
  }),
  popupContent: PropTypes.shape({
    publish: PropTypes.string,
    edit: PropTypes.string,
    copy: PropTypes.string,
    delete: PropTypes.string,
  }),
  isSharingRoom: PropTypes.bool,
  isOperation: PropTypes.bool,
};

export default buttonPackageInTable;
