import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Grid,
  Segment,
  Pagination,
  Header,
} from 'semantic-ui-react';
import Input from './../../input/inputWithLabel';
import LoaderModal from './../loaderModal';
import { ucwords } from '../../genericFunction/convertWord';
import Card from './card';
import styles from '../styles';

class listCard extends Component {
  static propTypes = {
    data: PropTypes.array,
    from: PropTypes.string,
    to: PropTypes.string,
    loading: PropTypes.bool,
    activePage: PropTypes.number,
    pageChange: PropTypes.func,
    activeSort: PropTypes.string,
    options: PropTypes.array,
    searchName: PropTypes.string,
    handleChange: PropTypes.func,
    setTransportItem: PropTypes.func,
    onClose: PropTypes.func,
    isThereActivity: PropTypes.bool,
  };

  parseOption = data => {
    let { isThereActivity } = this.props;
    data = isThereActivity
      ? data.filter(item => item.ServiceType.search('Transfer') === -1)
      : data;
    return data.map(data => {
      let options = data.Hours.map(hour => {
        return {
          key: hour.Id,
          value: hour.Id,
          text: hour.Name,
        };
      });
      return {
        key: data.ServiceType,
        value: data.ServiceType,
        text: data.ServiceType.split('_').join(' '),
        options,
      };
    });
  };

  pagination = (data, perPage) => {
    let sets = [],
      chunks = data.length / perPage;
    for (let i = 0, j = 0; i < chunks; i++, j += perPage)
      sets[i] = data.slice(j, j + perPage);
    return sets;
  };

  render() {
    let {
      data,
      from,
      to,
      loading,
      activePage,
      pageChange,
      activeSort,
      options,
      searchName,
      handleChange,
      setTransportItem,
      onClose,
    } = this.props;
    let pagedData = this.pagination(data, 10);
    let listData = pagedData[activePage - 1];
    from = ucwords(from);
    to = ucwords(to);
    return (
      <Container>
        <Grid>
          <Grid.Row>
            <Grid.Column width={16} style={{ marginBottom: '15px' }}>
              <Segment style={styles.segmentSearch}>
                <Input
                  name="searchName"
                  type="input"
                  icon="search"
                  iconPosition="left"
                  inputStyles={styles.inputWithPaddingNine}
                  transparent={true}
                  labelStyles={styles.textTransparent}
                  value={searchName}
                  handleChange={handleChange}
                  placeholder="Search by transportation name"
                />
              </Segment>
            </Grid.Column>
            <Grid.Column width={5}>
              <Segment style={styles.segmentNoPadding}>
                <Input
                  type="input"
                  icon="map marker alternate"
                  iconPosition="left"
                  inputStyles={styles.inputStyles}
                  label="From"
                  labelStyles={{ fontWeight: 'bold' }}
                  value={from}
                  disabled={true}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column width={5}>
              <Segment style={styles.segmentNoPadding}>
                <Input
                  type="input"
                  icon="map marker alternate"
                  iconPosition="left"
                  inputStyles={styles.inputStyles}
                  label="To"
                  labelStyles={{ fontWeight: 'bold' }}
                  value={to}
                  disabled={true}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column width={6}>
              <Segment style={styles.segmentNoPadding}>
                <Input
                  name="activeSort"
                  type="select"
                  inputStyles={styles.inputStyles}
                  label="Sort by:"
                  labelStyles={{ fontWeight: 'bold' }}
                  options={options}
                  value={activeSort}
                  placeholder="Sort transportation"
                  handleChange={handleChange}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              width={16}
              style={{
                marginTop: '10px',
                marginBottom: '10px',
                minHeight: '50px',
              }}
            >
              <LoaderModal loading={loading} />
              {listData && !loading
                ? listData.map((data, index) => {
                    let options = this.parseOption(
                      data.TransportationItemServiceTypes
                    );
                    return (
                      <Card
                        key={index}
                        image={data.ImageUrl}
                        name={data.Name}
                        type={data.TransportationTypeName}
                        rating={data.TransportationRatingName}
                        seat={data.TransportationSeatTypeName}
                        options={options}
                        id={data.ServiceItemId}
                        setTransportItem={setTransportItem}
                        data={data}
                        onClose={onClose}
                      />
                    );
                  })
                : !loading && (
                    <Header textAlign="center" as="h4">
                      There is no result of transportation for your destination
                    </Header>
                  )}
            </Grid.Column>
            <Grid.Column textAlign="center" width={16}>
              {pagedData &&
                !loading &&
                pagedData.length > 1 && (
                  <Pagination
                    totalPages={pagedData.length}
                    onPageChange={pageChange}
                    activePage={activePage}
                  />
                )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

export default listCard;
