import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';
import styles from './styles';
const ModalConfirmation = props => {
  const {
    openModal,
    size,
    confirm,
    close,
    message,
    nameConfirm,
    contentOk,
    contentNo,
    header,
  } = props;
  return (
    <Modal
      size={size ? size : 'mini'}
      open={openModal}
      onClose={close}
      closeOnDimmerClick={false}
      dimmer="blurring"
    >
      <Modal.Header>{header ? header : 'Confirmation'}</Modal.Header>
      <Modal.Content>{message}</Modal.Content>
      <Modal.Actions style={styles.alignRight}>
        <Button
          positive
          name={nameConfirm}
          content={contentOk ? contentOk : 'Yes'}
          onClick={confirm}
        />
        <Button
          style={styles.buttonNegative}
          content={contentNo ? contentNo : 'No'}
          onClick={close}
        />
      </Modal.Actions>
    </Modal>
  );
};

ModalConfirmation.propTypes = {
  openModal: PropTypes.bool,
  size: PropTypes.string,
  close: PropTypes.func,
  confirm: PropTypes.func,
  message: PropTypes.string,
  nameConfirm: PropTypes.string,
  contentOk: PropTypes.string,
  contentNo: PropTypes.string,
  header: PropTypes.string,
};
export default ModalConfirmation;
