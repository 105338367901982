import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Grid,
  Container,
  Divider,
  Form,
  Button,
} from 'semantic-ui-react';
import LoaderModal from '../../../../components/modal/loaderModal';
import { connect } from 'react-redux';
import InputWithLabel from '../../../shared/components/common/input/InputWithLabel';
import SegmentPickSharingPax from './SegmentPickSharingPax';
import {
  getAllSharedPackageAction,
  getFilterSharedpackageAction,
  resetPackageAction,
} from '../../../../actions/packageAction';
import { convertDateFormat } from '../../../../scripts/moment';
const style = {
  marginContent: {
    marginTop: '-1em',
    marginBottom: '-1em',
  },
};
class ModalSharingPax extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sharedPackageList: [],
      TourType: '',
      Duration: '',
      startDate: convertDateFormat(new Date(), 'YYYY-MM-DD'),
      destination: '',
      activePage: 1,
      searchInput: '',
    };
  }
  loadData = (tourType, Duration, starDate, destination) => {
    this.props.getFilterSharedpackageAction(
      tourType,
      Duration,
      starDate,
      destination
    );
  };
  setListSharedPackage = getFilterSharedPackage => {
    const { currentPackage } = this.props;
    let sharedPackageList = getFilterSharedPackage.filter((data, i) => {
      if (data.Id !== currentPackage.id) {
        return {
          PaxLeft: data.FixedPackage.PaxLeft,
          MinimumGuest: data.FixedPackage.MinimumGuest,
          MaximumGuest: data.FixedPackage.MaximumGuest,
          Title: data.Title ? data.Title : '',
          Id: data.Id,
          index: i,
          Type: data.TourCategory.Name,
          StartDate: data.StartDate,
          EndDate: data.EndDate,
        };
      }

      return sharedPackageList;
    });
    this.setState({ sharedPackageList });
  };
  componentDidUpdate() {
    const { getFilterSharedPackageStatus, getFilterSharedPackage } = this.props;
    if (getFilterSharedPackageStatus) {
      this.setListSharedPackage(getFilterSharedPackage);
      this.setState({
        TourType: '',
        Duration: '',
        destination: '',
        activePage: 1,
        searchInput: '',
      });
      this.props.resetPackageAction();
    }
  }
  handleChangeFilter = (e, { name, value }) => {
    const test = this.state;
    test[name] = value;
    this.setState({ test });
  };
  filterButton = () => {
    const { Duration, TourType, startDate, destination } = this.state;
    this.loadData(TourType, Duration, startDate, destination);
  };

  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  render() {
    const props = this.props;
    let duration = Array.from({ length: 11 }, (v, k) => k + 1).map(type => {
      return { text: type, value: type, key: type };
    });
    const {
      TourType,
      Duration,
      startDate,
      destination,
      activePage,
      searchInput,
    } = this.state;
    return (
      <Modal
        dimmer="blurring"
        closeIcon
        inverted
        open={props.open}
        onClose={props.handleClose}
        size="medium"
      >
        <Modal.Header>{props.header}</Modal.Header>
        <Modal.Content>
          <LoaderModal loading={props.loadingModal} />
          <Container content="Filter" as="h5" style={style.marginContent} />
          <Grid padded="vertically">
            <Grid.Row columns={4}>
              <Grid.Column>
                <Form>
                  <InputWithLabel
                    inline={false}
                    label="Tour Type"
                    type="select"
                    name="TourType"
                    placeholder="Tour Type"
                    handleChange={this.handleChangeFilter}
                    options={props.listTourType}
                    value={TourType}
                  />
                </Form>
              </Grid.Column>
              <Grid.Column>
                <Form>
                  <InputWithLabel
                    inline={false}
                    label="Destination"
                    type="select"
                    name="destination"
                    placeholder="Destination"
                    handleChange={this.handleChangeFilter}
                    options={this.props.optionCityInCountry}
                    value={destination}
                  />
                </Form>
              </Grid.Column>
              <Grid.Column>
                <Form>
                  <InputWithLabel
                    inline={false}
                    label="Duration (in a days)"
                    type="select"
                    name="Duration"
                    placeholder="Duration (in a days)"
                    handleChange={this.handleChangeFilter}
                    options={duration}
                    value={Duration}
                  />
                </Form>
              </Grid.Column>
              <Grid.Column>
                <Form>
                  <InputWithLabel
                    inline={false}
                    label="StartDate"
                    type="date"
                    name="startDate"
                    placeholder="Start Date"
                    handleChange={this.handleChangeFilter}
                    value={startDate}
                  />
                </Form>
                <Form>
                  <Button
                    content="search"
                    icon="search"
                    primary
                    floated="right"
                    onClick={this.filterButton}
                    style={{ marginTop: '1em' }}
                  />
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Divider />
          <SegmentPickSharingPax
            data={this.state.sharedPackageList}
            handlePickSharingPax={props.handlePickSharingPax}
            loading={this.props.loading}
            activePage={activePage}
            handleChange={this.handleChange}
            pageChange={this.pageChange}
            searchInput={searchInput}
          />
        </Modal.Content>
      </Modal>
    );
  }
}
ModalSharingPax.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  getFilterSharedpackageAction: PropTypes.func,
  loading: PropTypes.bool,
  currentPackage: PropTypes.object,
  getFilterSharedPackageStatus: PropTypes.string,
  getFilterSharedPackage: PropTypes.array,
  resetPackageAction: PropTypes.func,
  optionCityInCountry: PropTypes.array,
};
const mapStateToProps = state => {
  return {
    loading: state.PackageStore.loading,
    getFilterSharedPackageStatus:
      state.PackageStore.getFilterSharedPackageStatus,
    getFilterSharedPackage: state.PackageStore.getFilterSharedPackage,
    optionCityInCountry: state.CityStore.getCityInCountryList.map((city, i) => {
      return {
        key: i,
        text: city.Name + ', ' + city.Country.Name,
        value: city.Id,
      };
    }),
  };
};
export default connect(
  mapStateToProps,
  {
    getAllSharedPackageAction,
    getFilterSharedpackageAction,
    resetPackageAction,
  }
)(ModalSharingPax);
