const handleValidation = (state, fields) => {
  const validate = {};
  fields.map(field => {
    if (state[field] === '') {
      validate[field] = {
        error: true,
        message: `Field can't be empty!!`,
      };
    } else if (
      ['Email', 'EmailAddress'].indexOf(field) !== -1 &&
      state[field].indexOf('@' && '.') === -1
    ) {
      validate[field] = {
        error: true,
        message: `Email format is invalid!!`,
      };
    } else {
      delete validate[field];
    }
    return validate;
  });

  return validate;
};

export default handleValidation;
