import React from 'react';
import PropTypes from 'prop-types';
import { Form, Grid, Header, Button } from 'semantic-ui-react';
import InputWithLabel from '../../../components/input/inputWithLabel';

const ChangePassword = props => {
  return (
    <React.Fragment>
      <Grid centered>
        <Grid.Row centered>
          <Header as="h4" content="Change Password" />
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column width={6}>
            <Form>
              <InputWithLabel
                inline={false}
                label="Current Password"
                type={props.typePassword.OldPassword}
                name="OldPassword"
                placeholder="Current Password"
                value={props.password.OldPassword}
                handleChange={props.handleChangePassword}
                // validate={props.errorInformation}
                action={
                  <Button
                    tabIndex="2"
                    name="OldPassword"
                    icon={props.passwordIcon.OldPassword}
                    onClick={props.onClickPassIcon}
                    type="button"
                  />
                }
                size="small"
              />
              <InputWithLabel
                inline={false}
                label="New Password"
                type={props.typePassword.NewPassword}
                name="NewPassword"
                placeholder="New Password"
                value={props.password.NewPassword}
                handleChange={props.handleChangePassword}
                validate={props.errorInformation}
                action={
                  <Button
                    tabIndex="2"
                    name="NewPassword"
                    icon={props.passwordIcon.NewPassword}
                    onClick={props.onClickPassIcon}
                    type="button"
                  />
                }
                size="small"
              />
              <InputWithLabel
                inline={false}
                label="Confirm Password"
                type={props.typePassword.ConfirmPassword}
                name="ConfirmPassword"
                placeholder="Confirm Password"
                value={props.password.ConfirmPassword}
                handleChange={props.handleChangePassword}
                validate={props.errorInformation}
                action={
                  <Button
                    tabIndex="2"
                    name="ConfirmPassword"
                    icon={props.passwordIcon.ConfirmPassword}
                    onClick={props.onClickPassIcon}
                    type="button"
                  />
                }
                size="small"
              />
            </Form>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Button color="green" onClick={props.handleUpdatePassword}>
            Update Password
          </Button>
        </Grid.Row>
      </Grid>
    </React.Fragment>
  );
};

ChangePassword.propTypes = {
  password: PropTypes.object,
  handleUpdatePassword: PropTypes.func,
  handleChangePassword: PropTypes.func,
  errorInformation: PropTypes.object,
  onClickPassIcon: PropTypes.func,
  typePassword: PropTypes.object,
  passwordIcon: PropTypes.object,
};
export default ChangePassword;
