import React from 'react';
import PropTypes from 'prop-types';
import { Card, Grid, Button } from 'semantic-ui-react';
import styles from './../../styles.js';
const filterHeader = props => {
  const { header, resetFilter } = props;
  return (
    <Card.Content>
      <Grid columns="equal">
        <Grid.Column>
          <h3>{header}</h3>
        </Grid.Column>
        <Grid.Column style={{ color: '##e6ca6b' }}>
          <Button
            floated="right"
            style={styles.buttonTransparent}
            onClick={resetFilter}
          >
            <h3>Reset</h3>
          </Button>
        </Grid.Column>
      </Grid>
    </Card.Content>
  );
};

filterHeader.propTypes = {
  header: PropTypes.string,
  resetFilter: PropTypes.func,
};

export default filterHeader;
