import apiClient from './apiClient';
import {
  GET_MANUAL_CONFIRMATION_NUMBER,
  POST_CONFIRMATION_NUMBER,
  GET_PENDING_CONFIRMATION_LIST_URL,
  GET_BOOKING_CALENDAR_OPERATION_URL,
  GET_PAYMENT_REQUEST_LIST_API,
} from './apiUrl';

const getBookingCalendarOperationApi = (startDate, endDate) => {
  return apiClient.get(GET_BOOKING_CALENDAR_OPERATION_URL(startDate, endDate));
};

const getManualConfirmationNumberApi = (
  startCheckInDateRange,
  endCheckInDateRange
) => {
  return apiClient.get(
    GET_MANUAL_CONFIRMATION_NUMBER(startCheckInDateRange, endCheckInDateRange)
  );
};

const postConfirmationNumberApi = data => {
  return apiClient.post(POST_CONFIRMATION_NUMBER, data);
};

const getPendingConfirmationListApi = (
  startCheckInDateRange,
  endCheckInDateRange
) => {
  return apiClient.get(
    GET_PENDING_CONFIRMATION_LIST_URL(
      startCheckInDateRange,
      endCheckInDateRange
    )
  );
};

const getPaymentRequestListApi = (startDueDate, endDueDate) => {
  return apiClient.get(GET_PAYMENT_REQUEST_LIST_API(startDueDate, endDueDate));
};
export {
  getManualConfirmationNumberApi,
  postConfirmationNumberApi,
  getPendingConfirmationListApi,
  getBookingCalendarOperationApi,
  getPaymentRequestListApi,
};
