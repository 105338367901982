import React, { Component } from 'react';
import { Table, Button, Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../../../../scripts/pagination';
import { Style } from '../../../../../../assets/styles/styleAccomodation';
import FilterData from '../../../../../../scripts/filterDataTable';
import IndexPagination from '../../../../../../components/genericFunction/indexPagination';
import Pagination from '../../../../../../components/common/pagination';
class tableAddingData extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      // activePage: 1,
      // pageLength: 3,
    };
  }
  static propTypes = {
    data: PropTypes.array,
  };
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
    this.props.setActivePage(1);
  };
  pageChange = (e, { activePage }) => {
    this.props.setActivePage(activePage);
    // this.setState({ activePage });
  };
  render() {
    const props = this.props;
    const { searchInput } = this.state;
    const { activePage } = this.props;
    let filterData = FilterData(
      props.listData,
      'TransportationPrice',
      searchInput
    );
    let pagedData = datasetPagination(filterData, 3);
    let listData = pagedData[activePage - 1];

    let { numbersFrom, numbersTo } = getNumberFromTo(listData, activePage, 3);
    return (
      <div>
        <Input
          icon="search"
          placeholder="search by Origin City"
          name="searchInput"
          value={searchInput}
          onChange={this.handleChange}
        />
        <Button
          content="Add New "
          positive
          onClick={props.addNew}
          floated="right"
          disabled={props.disablePage}
          name="Add"
        />
        <div style={Style.marginTable}>
          <Table basic="very" selectable fixed>
            <Table.Header>
              <Table.Row>
                {props.headerTable.map((headTableContent, index) => {
                  return (
                    <Table.HeaderCell key={index}>
                      {headTableContent}
                    </Table.HeaderCell>
                  );
                })}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {listData ? (
                listData.map((unit, idx) => {
                  const idxPagination = IndexPagination(3, activePage, idx);
                  return (
                    <Table.Row key={idx}>
                      <Table.Cell>{unit.OriginRegion.Name}</Table.Cell>
                      <Table.Cell>{unit.OriginCity.Name}</Table.Cell>
                      <Table.Cell>{unit.DestinationRegion.Name}</Table.Cell>
                      <Table.Cell>{unit.DestinationCity.Name}</Table.Cell>
                      <Table.Cell textAlign="center">
                        <Button
                          content="Edit"
                          positive
                          name={idxPagination}
                          onClick={props.handleClickEdit}
                          id={unit.ServiceItemId}
                          disabled={props.disablePage}
                        />
                        <Button
                          content="Delete"
                          negative
                          name={idxPagination}
                          onClick={props.handleClickDelete}
                          id={unit.ServiceItemId}
                          disabled={props.disablePage}
                          value="unit"
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              ) : (
                <Table.Row>
                  <Table.Cell colSpan="5" textAlign="center">
                    No Data
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
          {/* {pagedData &&
              pagedData.length > 1 && (
                <Table.Footer> */}
          <Pagination
            // totalPages={pagedData.length}
            // onPageChange={this.pageChange}
            // activePage={activePage}
            pagedData={pagedData}
            numbersTo={numbersTo}
            numbersFrom={numbersFrom}
            data={props.listData}
            pageChange={this.pageChange}
            activePage={activePage}
          />
          {/* </Table.Footer>
              )} */}
        </div>
      </div>
    );
  }
}

tableAddingData.propTypes = {
  listData: PropTypes.array,
  deleteState: PropTypes.func,
  headerTable: PropTypes.array,
  value: PropTypes.string,
  activePage: PropTypes.number,
  pageChange: PropTypes.func,
  setActivePage: PropTypes.func,
};
export default tableAddingData;
