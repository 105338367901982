import React, { Component } from 'react';
import { Table, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import IndexPagination from '../../../../../components/genericFunction/indexPagination';
import { Style } from '../../../../../assets/styles/styleAccomodation';
import Pagination from '../../../../../components/common/pagination';
import {
  getNumberFromTo,
  datasetPagination,
} from '../../../../../scripts/pagination';
class TableRegionCoverages extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
      headerTable: ['Region', 'Action'],
    };
  }
  static propTypes = {
    data: PropTypes.array,
  };
  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  render() {
    const { listData, deleteData, disabled, contractingFileList } = this.props;
    const { activePage, headerTable } = this.state;
    let pagedData = datasetPagination(listData, 3);
    let newListData = pagedData ? pagedData[activePage - 1] : [];
    let { numbersFrom, numbersTo } = getNumberFromTo(
      newListData,
      activePage,
      3
    );
    return (
      <div style={Style.marginTable}>
        <Table basic="very" selectable fixed>
          <Table.Header>
            <Table.Row>
              {headerTable.map((headTableContent, index) => {
                return (
                  <Table.HeaderCell key={index}>
                    {headTableContent}
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {newListData ? (
              newListData.map((data, idx) => {
                const idxPagination = IndexPagination(3, activePage, idx);
                return (
                  data.Action !== 1 && (
                    <Table.Row key={idxPagination}>
                      <Table.Cell>{data.RegionName}</Table.Cell>
                      <Table.Cell>
                        <Button
                          content="Delete"
                          negative
                          value={data.Id}
                          name={data.RegionName}
                          id={idxPagination}
                          onClick={deleteData}
                          disabled={disabled}
                        />
                      </Table.Cell>
                    </Table.Row>
                  )
                );
              })
            ) : (
              <Table.Row>
                <Table.Cell colSpan="4" textAlign="center">
                  No Data
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        <Pagination
          pagedData={pagedData}
          numbersTo={numbersTo}
          numbersFrom={numbersFrom}
          // data={props.listData}
          data={contractingFileList}
          pageChange={this.pageChange}
          activePage={activePage}
        />
      </div>
    );
  }
}

TableRegionCoverages.propTypes = {
  contractingFileList: PropTypes.array,
  listData: PropTypes.array,
  deleteState: PropTypes.func,
  headerTable: PropTypes.array,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  activePage: PropTypes.string,
  pageAddingData: PropTypes.array,
  pageChange: PropTypes.func,
  deleteContracting: PropTypes.func,
  viewContracting: PropTypes.func,
  downloadContracting: PropTypes.func,
  deleteData: PropTypes.func,
};
export default TableRegionCoverages;
