const COLOR_WHITE = '#fefefe';
const COLOR_GOLD = '#e6ca6b';
const COLOR_RED = '#f44336';
const COLOR_BLUE = '#6b82e6';
const COLOR_BLACK = '#000000';

const styles = {
  borderNone: {
    border: 'none',
  },
  segment: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    padding: '10px 30px 10px 30px',
    margin: '5px 0px 20px 0px',
  },
  segmentNoPadding: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    margin: '5px 0px 20px 0px',
  },
  segmentWithShadowBox: {
    border: 'none',
    boxShadow: '0 2px 6px 1px  rgba(0,0,0,0.2)',
    padding: '30px 30px',
    margin: '5px 0px 20px 0px',
  },
  goldColor: { color: '#e6ca6b' },
  greyColor: { color: '#dbdbdb' },
  // whiteCardColor: { background: '#fefefe' },
  // transparentGold: {
  //   background: '#fefefe',
  //   color: '#e6ca6b',
  //   padding: '0px',
  // },
  // goldCardColor: {
  //   padding: '5px 5px',
  //   background: 'rgba(230, 202, 107,0.1)',
  // },
  // goldCardColor15px: {
  //   padding: '15px',
  //   background: 'rgba(230, 202, 107,0.1)',
  // },
  widthCard: { width: '490px' },
  // titleTable: {
  //   borderBottom: '1px solid #252525',
  //   borderTop: 'none',
  // },
  tableHeader: {
    padding: '3px',
    borderBottom: '1px solid #252525',
    borderTop: 'none',
  },
  tableBody: {
    padding: '3px',
    border: 'none',
  },
  tableNoBorder: {
    border: 'none',
    padding: '3px',
  },
  buttonGold: {
    margin: '0px 10px',
    color: COLOR_BLACK,
    background: COLOR_GOLD,
    border: '2px solid' + COLOR_GOLD,
  },
  buttonGoldNoPadding: {
    color: COLOR_BLACK,
    background: COLOR_GOLD,
  },
  // buttonRed: {
  //   margin: '0px 10px',
  //   color: COLOR_WHITE,
  //   background: COLOR_RED,
  //   border: '2px solid' + COLOR_RED,
  // },
  buttonBlue: {
    margin: '0px 10px',
    color: COLOR_BLUE,
    background: COLOR_WHITE,
    border: '2px solid #6b82e6',
  },
  buttonIconInfo: {
    color: '#252525',
    padding: '0px',
  },
  paddingBottom5px: {
    paddingBottom: '5px',
  },
  backgroundWhiteWithPadding15px: {
    padding: '15px',
    background: COLOR_WHITE,
  },
  noPadding: {
    padding: '0px',
  },
  padding2px: {
    padding: '2px',
  },
  paddingRight10Left20: {
    padding: '0px 10px 0px 20px',
  },
  paddingTop2pxLeftRight15px: {
    padding: '2px 15px 0px 15px',
  },
  paddingTop20px: {
    paddingTop: '10px',
  },
  paddingTop10px: {
    paddingTop: '10px',
  },
  paddingTop10pxBackgroundGold: {
    paddingTop: '10px',
    background: 'rgba(230, 202, 107,0.1)',
  },
  marginTopBottom5px: {
    margin: '5px 0px 5px 0px',
  },
  noMargin: {
    margin: '0px',
  },
  colorRed: {
    color: COLOR_RED,
  },
  width100percent: {
    width: '100%',
  },
  width100padding30: {
    width: '100%',
    height: '100px',
    textAlign: 'justify',
    borderColor: '#B9BBB6',
    textColor: COLOR_BLACK,
  },
  placeholder: {
    color: COLOR_BLACK,
    opacity: 1,
  },
  marginLeft5px: {
    marginLeft: '5px',
  },
  padding0pxMarginRight: {
    padding: '0px',
    marginRight: '10px',
  },
  marginBottom14px: {
    marginBottom: '14px',
  },
  marginBottomTop10px: {
    margin: '10px 0px 10px 0px',
  },
  backgroundGoldTransparent: {
    background: 'rgba(230, 202, 107,0.1)',
  },
  tableNoBorderTitle: {
    border: 'none',
    padding: '3px 0px',
  },
  redColor: {
    color: COLOR_RED,
  },
  paddingLeftRight20px: {
    padding: '0px 20px',
  },
  marginTop10Bottom5px: {
    margin: '10px 0px 5px 0px',
  },
  paddingTopBottom10pxLeftRight35px: {
    padding: '10px 35px',
  },
  paddingTopBottom5pxLeftRight35px: {
    padding: '5px 35px',
  },
  padding15px: {
    padding: '15px 15px',
  },
  paddingTop15px: {
    padding: '15px 0px 0px 0px',
  },
  margin10px: {
    margin: '10px',
  },
  noPaddingNoMargin: {
    padding: '0px',
    margin: '0px',
  },
  orderedItemEven: {
    // paddingTop: '10px',
    // paddingBottom: '10px',
    padding: '10px',
    margin: '0px 30px 0px 40px',
    background: 'rgba(230, 202, 107, 0.1)',
  },
  orderedItemOdd: {
    // paddingTop: '10px',
    // paddingBottom: '10px',
    padding: '10px',
    margin: '0px 30px 0px 40px',
  },
  padding5px35pxMarginTop15px: {
    padding: '5px 35px',
    margin: '15px 0px 0px 0px',
  },
  noMarginPadding10px0pxWidth100percent: {
    margin: '0px',
    padding: '10px 0px',
    width: '100%',
  },
};

export default styles;
