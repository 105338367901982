import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Header } from 'semantic-ui-react';
import { convertDateFormat } from '../../../../../scripts/moment';

const bookingDetailInformation = props => (
  <Grid divided="vertically">
    <Grid.Row columns={3}>
      <Grid.Column width={7}>
        <Grid divided="vertically">
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header content="Booking Code" as="h5" />
            </Grid.Column>
            <Grid.Column>
              <Header
                content={`: ${
                  props.contentDetail.Id ? props.contentDetail.Id : ''
                }`}
                as="h5"
              />
            </Grid.Column>
            <Grid.Column>
              <Header content="Tour Title Name" as="h5" />
            </Grid.Column>
            <Grid.Column>
              <Header
                content={`: ${
                  props.contentDetail.TourName
                    ? props.contentDetail.TourName
                    : ''
                }`}
                as="h5"
              />
            </Grid.Column>
            <Grid.Column>
              <Header content="Customer Name" as="h5" />
            </Grid.Column>
            <Grid.Column>
              <Header
                content={`: ${
                  props.contentDetail.Customer
                    ? props.contentDetail.Customer
                    : ''
                }`}
                as="h5"
              />
            </Grid.Column>
            {/* <Grid.Column>
              <Header content="Tour Date" as="h5" />
            </Grid.Column>
            <Grid.Column>
              <Header
                content={`: ${rangeDate(
                  props.contentDetail.StartTour,
                  props.contentDetail.EndTour
                )}`}
                as="h5"
              />
            </Grid.Column> */}
            {'StartTour' in props.contentDetail ? (
              <Fragment>
                <Grid.Column>
                  <Header content="Start Tour" as="h5" />
                </Grid.Column>
                <Grid.Column>
                  <Header
                    content={`: ${
                      props.contentDetail.StartTour
                        ? convertDateFormat(
                            props.contentDetail.StartTour,
                            'D MMM YYYY'
                          )
                        : ''
                    }`}
                    as="h5"
                  />
                </Grid.Column>
              </Fragment>
            ) : null}
            {'EndTour' in props.contentDetail ? (
              <Fragment>
                <Grid.Column>
                  <Header content="End Tour" as="h5" />
                </Grid.Column>
                <Grid.Column>
                  <Header
                    content={`: ${
                      props.contentDetail.EndTour
                        ? convertDateFormat(
                            props.contentDetail.EndTour,
                            'D MMM YYYY'
                          )
                        : ''
                    }`}
                    as="h5"
                  />
                </Grid.Column>
              </Fragment>
            ) : null}
          </Grid.Row>
        </Grid>
      </Grid.Column>
      <Grid.Column width={1} />
      <Grid.Column width={7}>
        <Grid divided="vertically">
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header content="Status" as="h5" />
            </Grid.Column>
            <Grid.Column>
              <Header
                content={`: ${
                  props.contentDetail.Status
                    ? props.contentDetail.Status.replace(/_/g, ' ')
                    : ''
                }`}
                as="h5"
              />
            </Grid.Column>
            <Grid.Column>
              <Header content="Total Tour Price" as="h5" />
            </Grid.Column>
            <Grid.Column>
              <Header
                content={`: ${
                  props.contentDetail.CurrencyId
                    ? props.contentDetail.CurrencyId
                    : ''
                } ${
                  props.contentDetail.TourPrice
                    ? new Intl.NumberFormat('de-DE').format(
                        props.contentDetail.TourPrice
                      )
                    : 0
                }`}
                as="h5"
              />
            </Grid.Column>
            <Grid.Column>
              <Header content="Total Paid" as="h5" />
            </Grid.Column>
            <Grid.Column>
              <Header
                content={`: ${
                  props.contentDetail.CurrencyId
                    ? props.contentDetail.CurrencyId
                    : ''
                } ${
                  props.contentDetail.TourPaid
                    ? new Intl.NumberFormat('de-DE').format(
                        props.contentDetail.TourPaid
                      )
                    : 0
                }`}
                as="h5"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

bookingDetailInformation.propTypes = {
  contentDetail: PropTypes.object,
};

export default bookingDetailInformation;
