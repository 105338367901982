import React from 'react';
import { Table, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
const TablePaymentScheme = props => (
  <Table basic="very" selectable>
    <Table.Header>
      <Table.Row>
        {props.headerTable.map((headTableContent, index) => {
          return (
            <Table.HeaderCell
              key={index}
              textAlign={headTableContent === 'Action' ? 'center' : ''}
            >
              {headTableContent}
            </Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {props.listData &&
        props.listData.map((scheme, idx) => {
          return (
            <Table.Row key={idx}>
              <Table.Cell component="th" scope="row" width={3}>
                {scheme.Id}
              </Table.Cell>
              <Table.Cell>{scheme.Type}</Table.Cell>
              <Table.Cell>{scheme.Description}</Table.Cell>
              <Table.Cell>
                <Button
                  content="Lock"
                  positive
                  onClick={props.buttonLock}
                  id={scheme}
                  disabled={scheme.IsLock}
                />
                {!scheme.IsLock && (
                  <Button
                    content="Edit"
                    primary
                    onClick={props.buttonEdit}
                    id={scheme.Id}
                    disabled={scheme.IsLock}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          );
        })}
    </Table.Body>
  </Table>
);

TablePaymentScheme.propTypes = {
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  buttonEdit: PropTypes.func,
  buttonDelete: PropTypes.func,
  buttonLock: PropTypes.func,
};
export default TablePaymentScheme;
