import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Grid, Form, Checkbox } from 'semantic-ui-react';
import { StyleModal } from '../../../../assets/styles/size';
import ButtonClose from '../../../../components/button/buttonCloseModal';
import Input from '../common/input/InputWithLabel';
const OPTION_TYPE_PRICE = [
  { key: 'deduction', value: 'deduction', text: 'Deduction' },
  { key: 'addition', value: 'addition', text: 'Addition' },
];
const listValidation = [
  'AdditionalItemType',
  'Description',
  'PriceEffect',
  'Qty',
  'CurrencyId',
  'UnitCost',
];
const ORIGIN_DATA = {
  idx: null,
  AdditionalItemType: {
    Value: '',
    Text: '',
  },
  Description: '',
  PriceEffect: '',
  IsHiddenData: false,
  Qty: 0,
  CurrencyId: '',
  UnitCost: 0,
  AdditionalItemTypeId: '',
  TourTransactionId: '',
  validation: null,
};
class ModalSpecialAdjusment extends Component {
  state = {
    data: {
      idx: null,
      AdditionalItemType: {
        Value: '',
        Text: '',
      },
      Description: '',
      PriceEffect: '',
      IsHiddenData: false,
      Qty: 0,
      CurrencyId: '',
      UnitCost: '',
      AdditionalItemTypeId: '',
      TourTransactionId: '',
      validation: {},
    },
  };
  componentDidUpdate(prevProps) {
    if (prevProps.openModal !== this.props.openModal) {
      let { selectedSpecialAdjusment, currencyId, idTransaction } = this.props;
      if (selectedSpecialAdjusment) {
        this.setState({
          ...this.state,
          data: {
            ...selectedSpecialAdjusment,
            CurrencyId: currencyId
              ? currencyId
              : selectedSpecialAdjusment
              ? selectedSpecialAdjusment.CurrencyId
              : '',
          },
        });
      } else {
        this.setState({
          ...this.state,
          data: {
            ...ORIGIN_DATA,
            TourTransactionId: idTransaction,
            CurrencyId: currencyId
              ? currencyId
              : selectedSpecialAdjusment
              ? selectedSpecialAdjusment.CurrencyId
              : '',
          },
        });
      }
    }
  }
  handleChangeSpecialAdjusment = (e, { value, name }) => {
    let { data } = this.state;
    let { additionalItemTypeList } = this.props;
    let newData = {
      ...data,
      [name]: value,
    };
    if (name === 'Qty') {
      newData = {
        ...newData,
        TotalCost: newData.UnitCost * value,
      };
    }
    if (name === 'IsHiddenData') {
      newData = {
        ...newData,
        IsHiddenData: !newData.IsHiddenData,
      };
    }
    if (name === 'AdditionalItemType') {
      let typeName = additionalItemTypeList.find(item => item.value === value);
      newData = {
        ...newData,
        AdditionalItemType: {
          Value: value,
          Text: typeName.text,
        },
        AdditionalItemTypeId: parseInt(value),
      };
    }
    if (name === 'PriceEffect') {
      if (value === 'deduction') {
        newData = {
          ...newData,
          TotalCost: newData.TotalCost
            ? newData.TotalCost * -1
            : newData.TotalCost,
          UnitCost: newData.UnitCost ? newData.UnitCost * -1 : newData.UnitCost,
        };
      } else {
        newData = {
          ...newData,
          TotalCost: newData.TotalCost
            ? newData.TotalCost * -1
            : newData.TotalCost,
          UnitCost: newData.UnitCost ? newData.UnitCost * -1 : newData.UnitCost,
        };
      }
    }

    this.setState({
      ...this.state,
      data: newData,
    });
  };

  getFlag = (isReduction, unitCost) => {
    return isReduction ? (unitCost < 0 ? 1 : -1) : unitCost < 0 ? -1 : 1;
  };
  handleChangeCostSpecialAdjusment = (e, { value }) => {
    let data = { ...this.state.data };
    value =
      value && value !== '' && value !== '-'
        ? parseInt(value.replace(/\D/g, ''), 10)
        : 0;
    let emptyValue = value === '' || value === '-' || value === 0;
    let flag = this.getFlag(data.PriceEffect === 'deduction', value); //data.UnitCost
    data = {
      ...data,
      TotalCost: emptyValue ? 0 : value * data.Qty * flag,
      UnitCost: emptyValue ? 0 : value * flag,
    };
    this.setState({
      ...this.state,
      data: data,
    });
  };
  saveSpecialAdjusment = () => {
    let { data } = this.state;
    let resultValidation = this.checkValidation(listValidation, data);
    this.setState(
      {
        ...this.state,
        validation: resultValidation.resultValidation,
      },
      () => {
        if (!resultValidation.isError) this.props.saveSpecialAdjusment(data);
      }
    );
  };
  checkValidation = (listValidation, specialAdjustment) => {
    let isError = false;
    let resultValidation = listValidation.reduce((acc, e) => {
      if (
        (e === 'AdditionalItemType' && specialAdjustment[e].Value === '') ||
        specialAdjustment[e] === ''
      ) {
        acc[e] = {
          error: true,
          message: "Field can't be empty",
        };
        isError = true;
      } else {
        acc[e] = {
          error: false,
          message: '',
        };
      }
      return acc;
    }, {});
    return { resultValidation, isError };
  };
  render() {
    let {
      AdditionalItemType,
      Description,
      IsHiddenData,
      Qty,
      CurrencyId,
      UnitCost,
      PriceEffect,
    } = this.state.data;
    let {
      openModal,
      handleClose,
      header,
      additionalItemTypeList,
      currenciesList,
      disableCurrency,
    } = this.props;
    return (
      <Modal
        dimmer="blurring"
        inverted
        open={openModal}
        onClose={handleClose}
        size="tiny"
      >
        <Modal.Header>
          {header}
          <ButtonClose onClose={handleClose} />
        </Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <Form>
                  <Input
                    required
                    type="select"
                    label="Type"
                    fluid
                    options={additionalItemTypeList}
                    name={'AdditionalItemType'}
                    handleChange={this.handleChangeSpecialAdjusment}
                    value={parseInt(AdditionalItemType.Value)}
                    validate={this.state.validation}
                  />
                  <Input
                    required
                    type="textArea"
                    label="Description"
                    fluid
                    name="Description"
                    // type="text"
                    handleChange={this.handleChangeSpecialAdjusment}
                    value={Description}
                    validate={this.state.validation}
                  />
                  <Form.Group widths="equal">
                    <Input
                      required
                      type="select"
                      label="Operator"
                      fluid
                      options={OPTION_TYPE_PRICE}
                      name="PriceEffect"
                      value={PriceEffect}
                      handleChange={this.handleChangeSpecialAdjusment}
                      validate={this.state.validation}
                    />
                    <Form.Field style={{ marginTop: '30px' }}>
                      <Checkbox
                        label="Hide adjusment"
                        onClick={this.handleChangeSpecialAdjusment}
                        checked={IsHiddenData}
                        value={IsHiddenData}
                        name={'IsHiddenData'}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Input
                      required
                      type="select"
                      label="Currency"
                      options={currenciesList}
                      fluid
                      name={'CurrencyId'}
                      handleChange={this.handleChangeSpecialAdjusment}
                      value={CurrencyId}
                      disabled={disableCurrency}
                      validate={this.state.validation}
                    />
                    <Input
                      required
                      type="number"
                      label="Quantity"
                      fluid
                      name={'Qty'}
                      handleChange={this.handleChangeSpecialAdjusment}
                      value={Qty}
                      validate={this.state.validation}
                    />
                  </Form.Group>

                  <Input
                    required
                    inputStyles={{ textAlign: 'right' }}
                    type="text"
                    label="Unit Cost"
                    fluid
                    value={
                      UnitCost < 0
                        ? (UnitCost * -1).toLocaleString('ID')
                        : UnitCost.toLocaleString('ID')
                    }
                    name="UnitCost"
                    handleChange={this.handleChangeCostSpecialAdjusment}
                    validate={this.state.validation}
                  />
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button
            positive
            content="Save"
            floated="right"
            style={StyleModal.marginModalSpecialAdjustment.marginButtonSave}
            onClick={this.saveSpecialAdjusment}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

ModalSpecialAdjusment.propTypes = {
  openModal: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  selectedSpecialAdjusment: PropTypes.object,
  currencyId: PropTypes.string,
  contentDetail: PropTypes.object,
  listData: PropTypes.array,
  saveSpecialAdjusment: PropTypes.func,
  currenciesList: PropTypes.array,
  handleChangeCostSpecialAdjusment: PropTypes.func,
  disableCurrency: PropTypes.bool,
  additionalItemTypeList: PropTypes.array,
  idTransaction: PropTypes.string,
};

export default ModalSpecialAdjusment;
