import moment from 'moment';
import { addDate } from '../../scripts/moment';

const shortFormatDate = date => {
  return moment(date).format('DD MMM');
};

const getYear = date => {
  return moment(date).format('YYYY');
};

const convertDateFormat = (date, string) => {
  return moment(date).format(string);
};

const getTotalDays = (day1, day2, absolute) => {
  var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  var firstDate = new Date(moment(day1).format('YYYY-MM-DD'));
  var secondDate = new Date(moment(day2).format('YYYY-MM-DD'));

  var diffDays = Math.round(
    absolute === false
      ? (firstDate.getTime() - secondDate.getTime()) / oneDay
      : Math.abs((firstDate.getTime() - secondDate.getTime()) / oneDay)
  );
  return diffDays + 1;
};

const getHourAndMinute = date => {
  return addZero(moment(date).hours()) + ':' + addZero(moment(date).minutes());
};
const convertHourToSecond = time => {
  let hour = time.split(':')[0];
  let minutes = time.split(':')[1];
  return Number(hour) * 3600 + Number(minutes) * 60;
};
const convertSecondToHour = time => {
  time = Number(time);
  var h = Math.floor(time / 3600);
  var m = Math.floor((time % 3600) / 60);

  return ('0' + h).slice(-2) + ':' + ('0' + m).slice(-2);
};
const convertNewDateTime = (duration, datetime) => {
  let getDurationDateTime = getHourAndMinute(moment(datetime));
  let time = convertHourToSecond(getDurationDateTime);
  let newTime = convertSecondToHour(Number(duration) + Number(time));
  return groupDateAndTime(datetime, newTime);
};
const convertNewDateTimeBefore = (duration, datetime) => {
  let getDurationDateTime = getHourAndMinute(moment(datetime));
  let time = convertHourToSecond(getDurationDateTime);
  let newTime = convertSecondToHour(Number(time) - Number(duration));
  return groupDateAndTime(datetime, newTime);
};
const addZero = i => {
  if (i < 10) {
    i = i.length === 2 ? i : '0' + i;
  }
  return i;
};

const indexPagination = (index, activePage, pageLenght) => {
  let test = activePage - 1;
  return test * pageLenght;
};
const groupDateAndTime = (date, time) => {
  let newDate = new Date(date);
  let month = newDate.getMonth() + 1;
  if (month < 10) {
    month = '0' + month;
  }
  let day = newDate.getDate();
  if (day < 10) {
    day = '0' + day;
  }
  let year = newDate.getFullYear();
  let date1 = year + '-' + month + '-' + day;
  let newDate1 = date1 + 'T' + time + ':00';
  return newDate1;
};
const getTotalDayFromStarAndEndDate = (start, end) => {
  const parseStartDate = Date.parse(start);
  const parseEndDate = Date.parse(end);
  const totalDays = (parseEndDate - parseStartDate + 86400000) / 86400000;
  return Math.floor(totalDays);
};
const timeDuration = currentValue => {
  let timeDecimal = currentValue / 3600;
  let hour = Math.floor(timeDecimal);
  let leaveMinute = currentValue % 3600;
  let minute = addZero(Math.ceil(leaveMinute / 60));
  let display = '';
  // eslint-disable-next-line
  if (hour == '00' && minute == '00') {
    display = '0 min';
    // eslint-disable-next-line
  } else if (hour == '00' && minute != '00') {
    display = minute + ' mins';
    // eslint-disable-next-line
  } else if (hour != '00' && minute == '00') {
    display = hour + ' h';
  } else {
    display = hour + ':' + minute + ' h';
  }
  return display;
};

const getDayCurrentFromListDate = (startDate, totalDays, currentDate) => {
  let listOfDate = [];
  for (let i = 0; i <= totalDays; i++) {
    let isi = convertDateFormat(addDate(startDate, i, 'd'), 'YYYY-MM-DD');
    listOfDate.push(isi);
  }
  return listOfDate.indexOf(convertDateFormat(currentDate, 'YYYY-MM-DD')) + 1;
};

const getListDateMinMax = (startDate, endDate) => {
  let listOfDate = [];
  let days = getTotalDays(startDate, endDate, true);
  for (let i = 0; i <= days; i++) {
    let isi = convertDateFormat(addDate(startDate, i, 'd'), 'YYYY-MM-DD');
    let data = { key: i, text: `Day ${i + 1}`, value: isi };
    listOfDate.push(data);
  }
  return listOfDate;
};

export {
  shortFormatDate,
  getYear,
  convertDateFormat,
  getTotalDays,
  getHourAndMinute,
  addZero,
  convertHourToSecond,
  convertSecondToHour,
  indexPagination,
  groupDateAndTime,
  convertNewDateTime,
  getTotalDayFromStarAndEndDate,
  timeDuration,
  convertNewDateTimeBefore,
  getDayCurrentFromListDate,
  getListDateMinMax,
};
