import React from 'react';
//import PropTypes from 'prop-types';
import { Grid, Header } from 'semantic-ui-react';
import RestaurantItemListTable from './RestaurantItemTable';
// import ButtonGroupSupplier from '../../../shared/components/common/button/ButtonGroupSupplierList';
import PropTypes from 'prop-types';
// import { Style } from '../../../../assets/styles/styleAccomodation';

const RestaurantItemList = props => {
  return (
    <div>
      <Grid columns="equal">
        <Grid.Row stretched>
          <Grid.Column width={16}>
            <Header as="h3" content="Restaurant Item" />
            {/* <ButtonGroupSupplier
              buttonNew={props.buttonNew}
              butttonExport={props.butttonExport}
              buttonUploadData={props.buttonUploadData}
              buttonDownloadTemplate={props.buttonDownloadTemplate}
              style={Style.buttonGroupSupplierStyle}
              disablePage={props.disablePage}
            /> */}
            <RestaurantItemListTable
              listData={props.restaurantItemList}
              handleClickEdit={props.handleClickEdit}
              idProfile={props.idProfile}
              handleClickDelete={props.handleClickDelete}
              disablePage={props.disablePage}
              numbersTo={props.numbersTo}
              numbersFrom={props.numbersFrom}
              //
              buttonNew={props.buttonNew}
              butttonExport={props.butttonExport}
              buttonUploadData={props.buttonUploadData}
              buttonDownloadTemplate={props.buttonDownloadTemplate}
              // style={Style.buttonGroupSupplierStyle}
              // disablePage={props.disablePage}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

RestaurantItemList.propTypes = {
  buttonNew: PropTypes.func,
  restaurantItemList: PropTypes.array,
  butttonExport: PropTypes.func,
  buttonUploadData: PropTypes.func,
  buttonDownloadTemplate: PropTypes.func,
  handleClickEdit: PropTypes.func,
  idProfile: PropTypes.object,
  handleClickDelete: PropTypes.func,
  disablePage: PropTypes.bool,
  numbersTo: PropTypes.number,
  numbersFrom: PropTypes.number,
};
export default RestaurantItemList;
