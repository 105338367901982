import React from 'react';
import { Table, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ActionButton from '../../../../components/common/buttonEditAndDelete';
import { Link } from 'react-router-dom';

const tableAccomodationList = props => (
  <Table basic="very" selectable>
    <Table.Header>
      <Table.Row>
        {props.headerTable.map((headTableContent, index) => {
          return (
            <Table.HeaderCell key={index}>{headTableContent}</Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {props.listData && props.listData.length > 0 ? (
        props.listData.map((data, idx) => {
          return (
            <Table.Row key={idx}>
              <Table.Cell>
                <Link to={`/Accomodation/Detail/${data.Id}`}>{data.Name}</Link>
              </Table.Cell>
              <Table.Cell>{data.AccommodationType.Name}</Table.Cell>
              <Table.Cell>{data.AccommodationRating.Name}</Table.Cell>
              <Table.Cell>{data.Region.Name}</Table.Cell>
              <Table.Cell>{data.City.Name}</Table.Cell>
              <Table.Cell>{data.Country.Name}</Table.Cell>
              <Table.Cell>{data.Telephone}</Table.Cell>
              {props.useInResourceBookingList && (
                <Table.Cell>
                  {data.EstimatedTotalPrice.CurrencyId}{' '}
                  {data.EstimatedTotalPrice.Price.toLocaleString('id')}
                </Table.Cell>
              )}
              {props.useInResourceBookingList ? (
                <Table.Cell>
                  <Button
                    content="See Room"
                    primary
                    value={data}
                    onClick={props.handleSeeRoomRBL}
                  />
                </Table.Cell>
              ) : (
                <ActionButton
                  id={data.Id}
                  handleClickEdit={props.butttonEdit}
                  handleClickDelete={props.buttonDelete}
                  name1="Edit"
                  name2="Delte"
                  content1="Edit"
                  content2="Delete"
                />
              )}
            </Table.Row>
          );
        })
      ) : (
        <Table.Row>
          <Table.Cell
            colSpan={props.headerTable ? props.headerTable.length : 0}
            textAlign="center"
          >
            No data
          </Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  </Table>
);

tableAccomodationList.propTypes = {
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  butttonEdit: PropTypes.func,
  buttonDelete: PropTypes.func,
  useInResourceBookingList: PropTypes.bool,
  handleSeeRoomRBL: PropTypes.func,
};
export default tableAccomodationList;
