import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Input, Button, Divider, Header } from 'semantic-ui-react';
import { Style } from '../../../../../../assets/styles/styleTourTransaction';

const SegmentCustomerPick = props => {
  const { currentPackage, isQuotation } = props;
  const customerName =
    props.onBehalf.customerName === undefined
      ? ''
      : props.onBehalf.customerName;
  // const employeeCustomer =
  //   props.onBehalf.userName === undefined ? '' : props.onBehalf.userName;
  const employeeCustomer =
    props.onBehalf.firstName === undefined
      ? ''
      : `${props.onBehalf.firstName} ${props.onBehalf.lastName}`;
  return (
    <Grid.Row>
      <Grid.Column width={10}>
        <Header as="h4" content="Customer" />
        <label style={Style.marginRequiredCustomer}>*</label>
        <Input
          style={{ width: '100%' }}
          icon="search"
          placeholder="Select customer on behalf"
          iconPosition="left"
          transparent
          value={
            customerName + (employeeCustomer ? ' - ' + employeeCustomer : '')
          }
          onClick={props.handleClickModalCustomer}
          disabled={currentPackage.disabledEdit || isQuotation}
        />
        <Divider />
        {/* <Container content="Customer" />
          <label style={Style.labelTourTransaction}>: </label>
          <Container content="User Contact" />
          <label style={Style.labelTourTransaction}>: </label>
          <Container content="Phone No." />
          <label style={Style.labelTourTransaction}>: </label>
          <Container content="Email" />
          <label style={Style.labelTourTransaction}>: </label> */}
      </Grid.Column>
      <Grid.Column width={6}>
        <Button
          content="Add New Customer"
          positive
          style={Style.styleButtonAddCustomer}
          onClick={props.handleClickModaNewCustomer}
          fluid={true}
          disabled={currentPackage.disabledEdit || isQuotation}
        />
      </Grid.Column>
    </Grid.Row>
  );
};

SegmentCustomerPick.propTypes = {
  handleClickModaNewCustomer: PropTypes.func,
  handleClickModalCustomer: PropTypes.func,
  onBehalf: PropTypes.object,
  currentPackage: PropTypes.object,
  isQuotation: PropTypes.bool,
};
export default SegmentCustomerPick;
