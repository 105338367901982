import {
  convertDateFormat,
  getTime,
  SubDays,
} from '../../../../scripts/itineraryBuilder/timeHelper';
const getDateFromTo = selectedDate => {
  selectedDate = convertDateFormat(selectedDate, 'YYYY-MM-DD');
  let firstDateCurrentMonth = convertDateFormat(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    'YYYY-MM-DD'
  );
  let fromTo = {
    from: '',
    to: '',
  };
  //===================
  if (getTime(selectedDate) === getTime(firstDateCurrentMonth)) {
    fromTo.from = selectedDate;
    fromTo.to = convertDateFormat(
      new Date(SubDays(new Date(), 1)),
      'YYYY-MM-DD'
    );
  } else if (getTime(selectedDate) < getTime(firstDateCurrentMonth)) {
    fromTo.from = selectedDate;
    fromTo.to = convertDateFormat(
      new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      'YYYY-MM-DD'
    );
  } else {
    fromTo.from = selectedDate;
    fromTo.to = null;
  }
  return fromTo;
};

export { getDateFromTo };
