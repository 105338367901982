const setDataEmpolyee = (state, companyId) => {
  const data = {
    Id: state.Id ? state.Id : '', //UserProfileId
    UserId: state.UserId ? state.UserId : '',
    FirstName: state.FirstName ? state.FirstName : '',
    UserName: state.UserName ? state.UserName : '',
    Address: state.Address ? state.Address : '',
    Email: state.Email ? state.Email : '',
    LastName: state.LastName ? state.LastName : '',
    Gender: state.Gender ? state.Gender : '',
    PhoneNbr: state.PhoneNbr ? state.PhoneNbr : '',
    ImageUrl: state.ImageUrl ? state.ImageUrl : '',
    AccountManagerProfileId: state.AccountManager.Id
      ? state.AccountManager.Id
      : '',
    PositionId: state.Position ? state.Position.Id : '',
    CompanyCode: companyId,
    CountryId: state.Country ? state.Country.Id : '',
    IsInactive: state.IsInactive ? state.IsInactive : false,
    // IsLocked: state.IsLocked ? state.IsLocked : false,
    Supervisor: state.Supervisor ? state.Supervisor.Id : null,
  };

  return data;
};

export default setDataEmpolyee;
