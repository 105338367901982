import * as types from '../../../actions/actionTypes';

import {
  GET_TRANSPORTATION_LIST,
  GET_TRANSPORTATION_LIST_FULFILLED,
  GET_TRANSPORTATION_LIST_PENDING,
  GET_TRANSPORTATION_LIST_REJECTED,
  RESET_STATUS_TRANSPORTATION,
} from '../../transportation/transportationList/constant/actionTypes';

import {
  GET_TRANSPORTATION_BY_PROFILE_ID,
  GET_TRANSPORTATION_BY_PROFILE_ID_REJECTED,
  GET_TRANSPORTATION_BY_PROFILE_ID_PENDING,
  GET_TRANSPORTATION_BY_PROFILE_ID_FULFILLED,
  GET_TEMPLATE_TRANSPORTATION_PROFILE_DATA,
  GET_TEMPLATE_TRANSPORTATION_PROFILE_DATA_FULFILLED,
  GET_TEMPLATE_TRANSPORTATION_PROFILE_DATA_PENDING,
  GET_TEMPLATE_TRANSPORTATION_PROFILE_DATA_REJECTED,
  POST_UPLOAD_IMAGE_TRANSPORTATION_PROFILE,
  POST_UPLOAD_IMAGE_TRANSPORTATION_PROFILE_REJECTED,
  POST_UPLOAD_IMAGE_TRANSPORTATION_PROFILE_PENDING,
  POST_UPLOAD_IMAGE_TRANSPORTATION_PROFILE_FULFILLED,
} from '../../transportation/transportationProfile/constant/actionTypes';

import {
  GET_TEMPLATE_TRANSPORTATION_UNIT_DATA,
  GET_TEMPLATE_TRANSPORTATION_UNIT_DATA_REJECTED,
  GET_TEMPLATE_TRANSPORTATION_UNIT_DATA_PENDING,
  GET_TEMPLATE_TRANSPORTATION_UNIT_DATA_FULFILLED,
  GET_TRANSPORTATION_UNIT_HEADER_BY_ID,
  GET_TRANSPORTATION_UNIT_HEADER_BY_ID_PENDING,
  GET_TRANSPORTATION_UNIT_HEADER_BY_ID_FULFILLED,
  GET_TRANSPORTATION_UNIT_HEADER_BY_ID_REJECTED,
  GET_TRANSPORTATION_BY_FROM_TO_DATE,
  GET_TRANSPORTATION_BY_FROM_TO_DATE_PENDING,
  GET_TRANSPORTATION_BY_FROM_TO_DATE_REJECTED,
  GET_TRANSPORTATION_BY_FROM_TO_DATE_FULFILLED,
  GET_TRANSPORTATION_UNIT_ALL,
  GET_TRANSPORTATION_UNIT_ALL_PENDING,
  GET_TRANSPORTATION_UNIT_ALL_FULFILLED,
  GET_TRANSPORTATION_UNIT_ALL_REJECTED,
  GET_TRANSPORTATION_UNIT_BY_SERVICE_ITEM_ID,
  GET_TRANSPORTATION_UNIT_BY_SERVICE_ITEM_ID_REJECTED,
  GET_TRANSPORTATION_UNIT_BY_SERVICE_ITEM_ID_PENDING,
  GET_TRANSPORTATION_UNIT_BY_SERVICE_ITEM_ID_FULFILLED,
  POST_UPLOAD_IMAGE_TRANSPORTATION_UNIT,
  POST_UPLOAD_IMAGE_TRANSPORTATION_UNIT_REJECTED,
  POST_UPLOAD_IMAGE_TRANSPORTATION_UNIT_PENDING,
  POST_UPLOAD_IMAGE_TRANSPORTATION_UNIT_FULFILLED,
  GET_TRANSPORTATION_ESTIMATE_PRICE,
  GET_TRANSPORTATION_ESTIMATE_PRICE_FULFILLED,
  GET_TRANSPORTATION_ESTIMATE_PRICE_PENDING,
  GET_TRANSPORTATION_ESTIMATE_PRICE_REJECTED,
} from '../../transportation/transportationProfile/transportationUnit/constant/actionTypes';

const initialState = {
  loading: false,
  loadingFilter: false,
  loadingPostImage: false,
  transportationHeader: {},
  transportationByFromToDate: [],
  getTransportationList: [],
  getTransportationListStatus: '',
  getTransportationByProfileId: [],
  getTransportationByProfileIdStatus: '',
  getTransportationUnitTemplate: '',
  getTransportationUnitTemplateStatus: '',
  getTrasnportationByFromToDateStatus: '',
  getTransportationUnitByHeaderIdStatus: '',
  getTransportationProfileTemplate: '',
  getTransportationProfileTemplateStatus: '',
  postUploadImageTransportationProfileStatus: '',
  postUploadImageTransportationProfile: [],
  postUploadImageTransportationUnitStatus: '',
  postUploadImageTransportationUnit: [],
  postPrimaryImageStatus: '',
  getTransportationUnitAll: [],
  getTransportationUnitAllStatus: '',
  getTransportationUnitByServiceItemId: [],
  getTransportationUnitByServiceItemIdStatus: '',
  loadingGetTransportEstimatePrice: false,
  listTransportationEstimatePrice: [],
  errors: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TRANSPORTATION_LIST:
      return { ...state };
    case GET_TRANSPORTATION_LIST_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getTransportationListStatus: 'failed',
      };
    case GET_TRANSPORTATION_LIST_PENDING:
      return { ...state, loading: true };
    case GET_TRANSPORTATION_LIST_FULFILLED:
      return {
        ...state,
        getTransportationList: action.payload.data,
        loading: false,
        getTransportationListStatus: 'success',
      };
    case RESET_STATUS_TRANSPORTATION:
      return {
        ...state,
        getTransportationListStatus: '',
        getTransportationByProfileIdStatus: '',
        getTransportationUnitTemplateStatus: '',
        getTransportationUnitByHeaderIdStatus: '',
        postUploadImageTransportationProfileStatus: '',
        postUploadImageTransportationUnitStatus: '',
        getTransportationUnitAllStatus: '',
        getTransportationUnitByServiceItemIdStatus: '',
        getTrasnportationByFromToDateStatus: '',
        getTransportationProfileTemplateStatus: '',
      };
    case GET_TRANSPORTATION_BY_PROFILE_ID:
      return { ...state };
    case GET_TRANSPORTATION_BY_PROFILE_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getTransportationByProfileIdStatus: 'failed',
      };
    case GET_TRANSPORTATION_BY_PROFILE_ID_PENDING:
      return { ...state, loading: true };
    case GET_TRANSPORTATION_BY_PROFILE_ID_FULFILLED:
      return {
        ...state,
        getTransportationByProfileId: action.payload.data,
        loading: false,
        getTransportationByProfileIdStatus: 'success',
      };

    case GET_TEMPLATE_TRANSPORTATION_UNIT_DATA:
      return { ...state };
    case GET_TEMPLATE_TRANSPORTATION_UNIT_DATA_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getTransportationUnitTemplateStatus: 'failed',
      };
    case GET_TEMPLATE_TRANSPORTATION_UNIT_DATA_PENDING:
      return { ...state, loading: true };
    case GET_TEMPLATE_TRANSPORTATION_UNIT_DATA_FULFILLED:
      return {
        ...state,
        getTransportationUnitTemplate: action.payload.data,
        loading: false,
        getTransportationUnitTemplateStatus: 'success',
      };
    case GET_TRANSPORTATION_UNIT_HEADER_BY_ID: {
      return { ...state };
    }
    case GET_TRANSPORTATION_UNIT_HEADER_BY_ID_FULFILLED: {
      return {
        ...state,
        loading: false,
        transportationHeader: action.payload.data,
        getTransportationUnitByHeaderIdStatus: 'success',
      };
    }
    case GET_TRANSPORTATION_UNIT_HEADER_BY_ID_PENDING: {
      return { ...state, loading: true };
    }
    case GET_TRANSPORTATION_UNIT_HEADER_BY_ID_REJECTED: {
      return {
        ...state,
        loading: false,
        getTransportationUnitByHeaderIdStatus: 'failed',
      };
    }

    case GET_TRANSPORTATION_BY_FROM_TO_DATE: {
      return { ...state };
    }
    case GET_TRANSPORTATION_BY_FROM_TO_DATE_PENDING: {
      return { ...state, loading: true, loadingFilter: true };
    }
    case GET_TRANSPORTATION_BY_FROM_TO_DATE_REJECTED: {
      return {
        ...state,
        loading: true,
        getTrasnportationByFromToDateStatus: 'failed',
        loadingFilter: true,
      };
    }
    case GET_TRANSPORTATION_BY_FROM_TO_DATE_FULFILLED: {
      return {
        ...state,
        loading: false,
        transportationByFromToDate: action.payload.data,
        getTrasnportationByFromToDateStatus: 'success',
        loadingFilter: false,
      };
    }
    case GET_TEMPLATE_TRANSPORTATION_PROFILE_DATA:
      return { ...state };
    case GET_TEMPLATE_TRANSPORTATION_PROFILE_DATA_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getTransportationProfileTemplateStatus: 'failed',
      };
    case GET_TEMPLATE_TRANSPORTATION_PROFILE_DATA_PENDING:
      return { ...state, loading: true };
    case GET_TEMPLATE_TRANSPORTATION_PROFILE_DATA_FULFILLED:
      return {
        ...state,
        getTransportationProfileTemplate: action.payload.data,
        loading: false,
        getTransportationProfileTemplateStatus: 'success',
      };
    case POST_UPLOAD_IMAGE_TRANSPORTATION_PROFILE:
      return { ...state };
    case POST_UPLOAD_IMAGE_TRANSPORTATION_PROFILE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loadingPostImage: false,
        postUploadImageTransportationProfileStatus: 'failed',
      };
    case POST_UPLOAD_IMAGE_TRANSPORTATION_PROFILE_PENDING:
      return { ...state, loadingPostImage: true };
    case POST_UPLOAD_IMAGE_TRANSPORTATION_PROFILE_FULFILLED:
      return {
        ...state,
        postUploadImageTransportationProfileStatus: 'success',
        postUploadImageTransportationProfile: action.payload.data,
        loadingPostImage: false,
      };
    case POST_UPLOAD_IMAGE_TRANSPORTATION_UNIT:
      return { ...state };
    case POST_UPLOAD_IMAGE_TRANSPORTATION_UNIT_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        postUploadImageTransportationUnitStatus: 'failed',
      };
    case POST_UPLOAD_IMAGE_TRANSPORTATION_UNIT_PENDING:
      return { ...state, loading: true };
    case POST_UPLOAD_IMAGE_TRANSPORTATION_UNIT_FULFILLED:
      return {
        ...state,
        postUploadImageTransportationUnitStatus: 'success',
        postUploadImageTransportationUnit: action.payload.data,
        loading: false,
      };
    case GET_TRANSPORTATION_UNIT_ALL:
      return { ...state };
    case GET_TRANSPORTATION_UNIT_ALL_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getTransportationUnitAllStatus: 'failed',
      };
    case GET_TRANSPORTATION_UNIT_ALL_PENDING:
      return { ...state, loading: true };
    case GET_TRANSPORTATION_UNIT_ALL_FULFILLED:
      return {
        ...state,
        getTransportationUnitAll: action.payload.data,
        loading: false,
        getTransportationUnitAllStatus: 'success',
      };
    case GET_TRANSPORTATION_UNIT_BY_SERVICE_ITEM_ID:
      return { ...state };
    case GET_TRANSPORTATION_UNIT_BY_SERVICE_ITEM_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getTransportationUnitByServiceItemIdStatus: 'failed',
      };
    case GET_TRANSPORTATION_UNIT_BY_SERVICE_ITEM_ID_PENDING:
      return { ...state, loading: true };
    case GET_TRANSPORTATION_UNIT_BY_SERVICE_ITEM_ID_FULFILLED:
      return {
        ...state,
        getTransportationUnitByServiceItemId: action.payload.data,
        loading: false,
        getTransportationUnitByServiceItemIdStatus: 'success',
      };

    case types.POST_SET_PRIMARY_IMAGE:
      return { ...state };
    case types.POST_SET_PRIMARY_IMAGE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        postPrimaryImageStatus: 'failed',
      };
    case types.POST_SET_PRIMARY_IMAGE_PENDING:
      return { ...state, loading: true };
    case types.POST_SET_PRIMARY_IMAGE_FULFILLED:
      return {
        ...state,
        loading: false,
        postPrimaryImageStatus: 'success',
      };
    case GET_TRANSPORTATION_ESTIMATE_PRICE:
      return { ...state };
    case GET_TRANSPORTATION_ESTIMATE_PRICE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loadingGetTransportEstimatePrice: false,
        listTransportationEstimatePrice: [],
      };
    case GET_TRANSPORTATION_ESTIMATE_PRICE_PENDING:
      return { ...state, loadingGetTransportEstimatePrice: true };
    case GET_TRANSPORTATION_ESTIMATE_PRICE_FULFILLED:
      return {
        ...state,
        listTransportationEstimatePrice: action.payload.data,
        loadingGetTransportEstimatePrice: false,
      };

    default:
      return state;
  }
};

export default reducer;
