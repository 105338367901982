import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Input, Button } from 'semantic-ui-react';
import TablePrice from '../table/TablePrice';
import Pagination from '../../../../../components/common/pagination';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../../../scripts/pagination';
import { searchFilterAdditionalCostPrice } from '../../services/filterData';

const AdditionalCostPrice = props => {
  const {
    data,
    loading,
    handleAddPrice,
    handleEditPrice,
    handleDeletePrice,
    additionalCostLoading,
    saveLoading,
  } = props;
  const [activePage, setActivePage] = useState(1);
  const [searchInput, setSearchInput] = useState('');
  const dataPerPage = 3;

  // --------------------- helper ---------------------
  const pageChange = (e, { activePage }) => {
    setActivePage(activePage);
  };

  // --------------------- handler ---------------------
  const handleChangeSearch = (e, { value }) => {
    setSearchInput(value);
  };

  const handleDelete = (e, { idx }) => {
    setActivePage(1);
    handleDeletePrice(idx);
  };

  // --------------------- pagination ---------------------
  const filteredData = searchFilterAdditionalCostPrice(
    data ? (data.DetailCost ? data.DetailCost : []) : [],
    searchInput
  );
  const pagedData = datasetPagination(
    filteredData && filteredData ? filteredData : [],
    dataPerPage
  );
  const listData = pagedData[activePage - 1];
  const { numbersFrom, numbersTo } = getNumberFromTo(
    listData,
    activePage,
    dataPerPage
  );

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Header as="h3" content="Additional Cost Price" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Input
                    icon="search"
                    placeholder="search"
                    name="searchInput"
                    value={searchInput}
                    onChange={handleChangeSearch}
                  />
                </Grid.Column>
                <Grid.Column width={12}>
                  <Button
                    floated="right"
                    positive
                    icon="add"
                    content="Add Price"
                    onClick={handleAddPrice}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {/* table */}
            <TablePrice
              data={data}
              listData={listData}
              loading={loading}
              handleEdit={handleEditPrice}
              handleDelete={handleDelete}
              additionalCostLoading={additionalCostLoading}
              saveLoading={saveLoading}
              activePage={activePage}
              dataPerPage={dataPerPage}
            />
            {/* pagination */}
            {data && (
              <Pagination
                pagedData={pagedData}
                numbersTo={numbersTo}
                numbersFrom={numbersFrom}
                data={data.DetailCost}
                pageChange={pageChange}
                activePage={activePage}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

AdditionalCostPrice.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  handleAddPrice: PropTypes.func,
  handleEditPrice: PropTypes.func,
  handleDeletePrice: PropTypes.func,
  additionalCostLoading: PropTypes.bool,
  saveLoading: PropTypes.bool,
};

export default React.memo(AdditionalCostPrice);
