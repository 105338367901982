import React, { Component } from 'react';
import { Table, Button, Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Pagination from '../../../components/common/pagination';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../scripts/pagination';

class TableEmailSettings extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
    };
  }
  static propTypes = {
    data: PropTypes.array,
  };
  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  handleDelete = (e, { value }) => {
    const { deleteData, listData } = this.props;
    let { activePage } = this.state;
    deleteData(value);
    let pagedData = datasetPagination(listData ? listData : [], 3);
    let currentList = pagedData[activePage - 1];
    if (currentList.length === 1 && activePage !== 1) {
      activePage = activePage - 1;
      this.setState({ activePage });
    } else if (currentList.length === 1 && activePage === 1) {
      activePage = 1;
      this.setState({ activePage });
    }
    // handleDeleteImages(id);
  };
  handleOpenModal = (e, { name, value }) => {
    this.props.openModal(name, value);
  };
  render() {
    const { listData, disabled } = this.props;
    const { activePage } = this.state;
    let pagedData = datasetPagination(listData ? listData : [], 3);
    let pagelistData = pagedData[activePage - 1];
    let { numbersFrom, numbersTo } = getNumberFromTo(
      pagelistData,
      activePage,
      3
    );
    let initialnumber = (activePage - 1) * 3;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width="8" />
          <Grid.Column width="8" textAlign="right">
            <Button
              content="Add New Email"
              positive
              onClick={this.handleOpenModal}
              disabled={disabled}
              name="add"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width="16">
            <Table basic="very" selectable>
              <Table.Header>
                <Table.Row>
                  {[
                    'Email Address',
                    'SMTP Client',
                    'Port',
                    'Recipient Only',
                  ].map((headTableContent, index) => {
                    return (
                      <Table.HeaderCell key={index}>
                        {headTableContent}
                      </Table.HeaderCell>
                    );
                  })}
                  <Table.HeaderCell textAlign="center">Action</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {pagelistData && pagelistData.length > 0 ? (
                  pagelistData.map((data, idx) => {
                    return (
                      <Table.Row key={`img${idx}`}>
                        <Table.Cell>{data.EmailAddress}</Table.Cell>
                        <Table.Cell>{data.SmtpClient}</Table.Cell>
                        <Table.Cell>{data.Port}</Table.Cell>
                        <Table.Cell>
                          {data.IsRecipientOnly ? 'Yes' : 'No'}
                        </Table.Cell>

                        <Table.Cell textAlign="center">
                          <Button
                            content="Edit"
                            positive
                            value={initialnumber + idx}
                            onClick={this.handleOpenModal}
                            disabled={disabled}
                            name="edit"
                          />
                          <Button
                            content="Delete"
                            negative
                            value={initialnumber + idx}
                            onClick={this.handleDelete}
                            disabled={disabled}
                            name="delete"
                          />
                        </Table.Cell>
                      </Table.Row>
                    );
                  })
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan="4" textAlign="center">
                      No Image
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
            <Pagination
              pagedData={pagedData}
              numbersTo={numbersTo}
              numbersFrom={numbersFrom}
              data={pagelistData}
              pageChange={this.pageChange}
              activePage={activePage}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

TableEmailSettings.propTypes = {
  deleteData: PropTypes.func,
  listData: PropTypes.array,
  openModal: PropTypes.func,
  disabled: PropTypes.bool,
};
export default TableEmailSettings;
