const Styles = {
  modalCountry: {
    width: '400px',
  },
  checkBoxMargin: {
    marginTop: '1em',
  },
  gridStyle: {
    marginTop: '30px',
  },
};
export default Styles;
