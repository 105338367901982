import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form, Grid } from 'semantic-ui-react';
import InputWithLabel from '../../../../shared/components/common/input/InputWithLabel';
import TableSupplierPriceDetails from '../table/TableSupplierPriceRestaurant';
import { headerRestaurantPriceDetails } from '../../constant/tableHeader';
import InputDate from '../../../../../components/input/inputDate';
const ModalRestaurantPrice = props => (
  <Modal
    closeIcon
    inverted
    open={props.openModalPrice}
    onClose={props.handleClose}
    closeOnDimmerClick={false}
    size="tiny"
  >
    <Modal.Header>
      {props.restaurantPrice.Status} price for {props.restaurantItem.Name}
    </Modal.Header>
    <Modal.Content>
      <Grid columns={2} padded="vertically">
        <Grid.Column>
          <Form>
            <InputWithLabel
              inline={false}
              label="Service Type"
              type="select"
              name="ServicePriceType"
              required
              options={props.servicePriceType}
              handleChange={props.handleChangePrice}
              value={props.restaurantPrice.ServicePriceType}
              placeholder="Service Type"
              validate={props.errorInformation}
            />
          </Form>
          <Form>
            <Form.Field>
              <InputDate
                label="Start Date"
                name="StartDate"
                value={props.restaurantPrice.StartDate}
                setDate={props.handleChangePrices}
                labelStyle={{
                  display: 'block',
                  fontWeight: 'bold',
                  marginBottom: '4px',
                }}
                validate={props.errors}
                readonly={false}
              />
            </Form.Field>
          </Form>
        </Grid.Column>
        <Grid.Column>
          <Form>
            <InputWithLabel
              inline={false}
              label="Cut Off Day"
              type="number"
              required
              handleChange={props.handleChangePrice}
              placeholder="Cut Off Day"
              validate={props.errorInformation}
              name="CutOffDay"
              value={props.restaurantPrice.CutOffDay}
            />
          </Form>
          <Form>
            <Form.Field>
              <InputDate
                label="End Date"
                name="EndDate"
                value={props.restaurantPrice.EndDate}
                setDate={props.handleChangePrices}
                labelStyle={{
                  display: 'block',
                  fontWeight: 'bold',
                  marginBottom: '4px',
                }}
                validate={props.errors}
                readonly={false}
              />
            </Form.Field>
          </Form>
        </Grid.Column>
      </Grid>
      <Button
        positive
        content="Add Price"
        onClick={props.addPriceDetails}
        floated="right"
        style={{ marginBottom: '1em' }}
      />
      <TableSupplierPriceDetails
        headerTable={headerRestaurantPriceDetails}
        listData={props.restaurantPrice.PriceDetails}
        handleChangePriceDetails={props.handleChangePriceDetails}
        handleChangeCostPriceDetails={props.handleChangeCostPriceDetails}
        deletePrice={props.deletePrice}
        currencies={props.currencies}
        handleEdit={props.handleEdit}
      />
    </Modal.Content>
    <Modal.Actions>
      <Button content="Save" positive onClick={props.addPriceToList} />
    </Modal.Actions>
  </Modal>
);

ModalRestaurantPrice.propTypes = {
  handleChangeCostPriceDetails: PropTypes.func,
  handleChangePriceDetails: PropTypes.func,
  openModalPrice: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  content: PropTypes.string,
  addPrice: PropTypes.func,
  addPriceDetails: PropTypes.func,
  handleChangePrice: PropTypes.func,
  handleChangePriceDate: PropTypes.func,
  addPriceToList: PropTypes.func,
  deletePrice: PropTypes.func,
  errors: PropTypes.object,
  restaurantPrice: PropTypes.object,
  restaurantItem: PropTypes.object,
  servicePriceType: PropTypes.array,
  handleChangePrices: PropTypes.func,
  currencies: PropTypes.array,
  errorInformation: PropTypes.object,
  handleEdit: PropTypes.func,
};

export default ModalRestaurantPrice;
