import React from 'react';
import { connect } from 'react-redux';
import { getCountryListAction } from '../shared/actions/countryAction';
import {
  getPaymentMethodAction,
  getPaymentMethodByCountryIdAction,
  getPaymentMethodChoiceListAction,
  resetCustomEnumAction,
} from '../shared/actions/customEnumAction';
import {
  postPaymentMethodInCountryAction,
  getPaymentMethodInCountryAction,
  deletePaymentMethodInCountryAction,
} from './paymentMethodAction';
import ModalAlert from './../../components/modal/modalAlert';
// import Loader from './../components/loader';
import PaymentMethodList from './components/PaymentMethodList';
import PropTypes from 'prop-types';
// import ModalPaymentMethod from '../../components/country/modalPaymentMethod';
import ModalPaymentMethod from './components/ModalPaymentMethod';
import { deposit, xendit } from './constant/generalConstant';
class PaymentMethod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentMethodList: [],
      paymentMethodObject: {},
      errors: {},
      loadingPaymentMethod: true,
      failedPaymentMethod: false,
      modalOpen: false,
      modalPaymentMethod: false,
      modalConfirmation: false,
      paymentMethod: [],
      loadingModal: false,
    };
  }
  static propTypes = {
    getPaymentMethodAction: PropTypes.func,
    getPaymentMethodListStatus: PropTypes.string,
    getPaymentMethodList: PropTypes.array,
    resetCustomEnumAction: PropTypes.func,
    listPaymentMethod: PropTypes.array,
    listCountry: PropTypes.array,
    getPaymentMethodChoiceListAction: PropTypes.func,
    getCountryListAction: PropTypes.func,
    postPaymentMethodInCountryAction: PropTypes.func,
    getPaymentMethodByCountryIdStatus: PropTypes.string,
    getPaymentMethodByCountryId: PropTypes.array,
    getPaymentMethodByCountryIdAction: PropTypes.func,
    postPaymentMethodStatus: PropTypes.string,
    deletePaymentMethodStatus: PropTypes.string,
    deletePaymentMethodInCountryAction: PropTypes.func,
    errors: PropTypes.object,
    loading: PropTypes.bool,
  };
  handleClose = () =>
    this.setState({ modalConfirmation: false, failedPaymentMethod: false });
  handleCloseModalPaymentMethod = () =>
    this.setState({ modalPaymentMethod: false });
  componentDidMount = () => {
    this.props.getPaymentMethodAction();
    this.props.getPaymentMethodChoiceListAction();
    this.props.getCountryListAction();
  };
  componentDidUpdate() {
    if (this.props.getPaymentMethodListStatus === 'success') {
      this.setState({
        loadingPaymentMethod: false,
        paymentMethodList: this.props.getPaymentMethodList.reduce((m, o) => {
          var found = m.find(p => p.Country.Value === o.Country.Value);
          if (found) {
            found.PaymentGateawayChoice += ' , ' + o.PaymentGateawayChoice;
          } else {
            m.push(o);
          }
          return m;
        }, []),
        countryInPaymentMethod: this.props.getPaymentMethodList.map(type => {
          return {
            text: type.Country.Text,
            value: type.Country.Value,
            key: type,
          };
        }),
      });
      this.props.resetCustomEnumAction();
    } else if (this.props.getPaymentMethodListStatus === 'failed') {
      this.setState({
        headerAlert: ' Failed',
        contentAlert: this.props.errors.global,
        modalConfirmation: true,
        failedPaymentMethod: true,
        loadingPaymentMethod: false,
      });
      this.props.resetCustomEnumAction();
    }
    if (this.props.getPaymentMethodByCountryIdStatus === 'success') {
      this.setState({
        loadingModal: false,
        paymentMethod: this.props.getPaymentMethodByCountryId.map(
          p => (p.PaymentGateawayChoice === deposit ? 0 : 1)
        ),
        paymentMethodClone: this.props.getPaymentMethodByCountryId.map(
          p => (p.PaymentGateawayChoice === deposit ? 0 : 1)
        ),
      });
      this.props.resetCustomEnumAction();
    } else if (this.props.getPaymentMethodByCountryIdStatus === 'failed') {
      this.setState({ failedPaymentMethod: true, loadingModal: false });
      this.props.resetCustomEnumAction();
    }
    if (this.props.postPaymentMethodStatus === 'success') {
      this.handleAlertSuccess(' Saving Payment Method in Country Successfully');
      this.props.getPaymentMethodAction();
      this.props.resetCustomEnumAction();
    } else if (this.props.postPaymentMethodStatus === 'failed') {
      this.handleAlertFailed(' Saving Payment Method in Country Failed');
      this.props.resetCustomEnumAction();
    }
    if (this.props.deletePaymentMethodStatus === 'success') {
      this.handleAlertSuccess(' Delete Payment Method in Country Successfully');
      this.props.getPaymentMethodAction();
      this.props.resetCustomEnumAction();
    } else if (this.props.deletePaymentMethodStatus === 'failed') {
      this.handleAlertFailed(' Delete Payment Method in Country Failed');
      this.props.resetCustomEnumAction();
    }
  }

  handleAlertSuccess = content => {
    this.setState({
      headerAlert: ' Success',
      contentAlert: content,
      modalConfirmation: true,
      failedPaymentMethod: true,
      modalPaymentMethod: false,
      loadingModal: false,
    });
  };
  handleAlertFailed = content => {
    this.setState({
      failedPaymentMethod: true,
      loadingPaymentMethod: false,
      headerAlert: ' Failed',
      contentAlert: content,
      loadingModal: false,
    });
  };
  newPaymentMethod = () => {
    const country = this.props.listCountry;
    const countryInPaymentGateway = this.state.paymentMethodList.map(type => {
      return {
        text: type.Country.Text,
        value: type.Country.Value,
        key: type.Country.Value,
      };
    });
    var result = country
      .filter(function(o1) {
        return !countryInPaymentGateway.some(function(o2) {
          return o1.value === o2.value;
        });
      })
      .map(function(o) {
        return o;
      });
    this.setState({
      modalPaymentMethod: true,
      headerModal: 'Add New Payment Method',
      errors: {},
      paymentMethodObject: {},
      paymentMethod: [],
      disabledButtonDelete: true,
      listCountry: result,
    });
  };
  edit = (e, { id }) => {
    this.props.getPaymentMethodByCountryIdAction(id);
    this.setState({
      modalPaymentMethod: true,
      headerModal: 'Edit Payment Method',
      listCountry: this.props.listCountry,
      errors: {},
      paymentMethodObject: {
        ...this.state.paymentMethodObject,
        CountryId: id,
      },
    });
  };
  savePaymentMethod = () => {
    const { headerModal, paymentMethod } = this.state;
    this.setState({ loadingModal: true });
    if (headerModal === 'Add New Payment Method') {
      paymentMethod.map(data => {
        return this.props.postPaymentMethodInCountryAction({
          CountryId: this.state.paymentMethodObject.CountryId,
          PaymentGateawayChoice: data,
        });
      });
    } else {
      let paymentMethodClone = this.state.paymentMethodClone;
      let paymentMethod = this.state.paymentMethod;
      if (paymentMethodClone.length > paymentMethod.length) {
        let filteredKeywords = paymentMethodClone.filter(
          word => !paymentMethod.includes(word)
        );
        filteredKeywords.map(type => {
          return this.props.deletePaymentMethodInCountryAction(
            this.state.paymentMethodObject.CountryId,
            type === 0 ? deposit : xendit
          );
        });
      } else if (paymentMethodClone.length < paymentMethod.length) {
        let filteredKeywords = paymentMethod.filter(
          word => !paymentMethodClone.includes(word)
        );
        filteredKeywords.map(data => {
          return this.props.postPaymentMethodInCountryAction({
            CountryId: this.state.paymentMethodObject.CountryId,
            PaymentGateawayChoice: data,
          });
        });
      } else {
        const matching =
          paymentMethod.toString() === paymentMethodClone.toString();
        if (matching === false) {
          paymentMethodClone.map(data => {
            return this.props.deletePaymentMethodInCountryAction(
              this.state.paymentMethodObject.CountryId,
              data === 0 ? deposit : xendit
            );
          });
          paymentMethod.map(data => {
            return this.props.postPaymentMethodInCountryAction({
              CountryId: this.state.paymentMethodObject.CountryId,
              PaymentGateawayChoice: data,
            });
          });
        }
        this.setState({ loadingModal: false });
      }
    }
  };
  onChange = (e, { value, name }) => {
    const paymentMethodObject = this.state.paymentMethodObject;
    paymentMethodObject[name] = value;
    this.setState({ paymentMethodObject: paymentMethodObject });
  };
  onChangePaymentMethod = (e, { value }) => {
    this.setState({
      paymentMethod: value,
    });
  };

  render() {
    let { failedPaymentMethod, modalConfirmation } = this.state;
    let openModal = failedPaymentMethod || modalConfirmation;
    return (
      <div>
        <PaymentMethodList
          data={this.state.paymentMethodList}
          type="paymentMethodList"
          filterBy="PaymentMethod"
          buttonNew={this.newPaymentMethod}
          butttonEdit={this.edit}
          loading={this.props.loading}
        />
        <ModalAlert
          openModal={openModal}
          handleClose={this.handleClose}
          header={this.state.headerAlert}
          content={this.state.contentAlert}
          nameButton="OK"
        />
        <ModalPaymentMethod
          open={this.state.modalPaymentMethod}
          handleClose={this.handleCloseModalPaymentMethod}
          header={this.state.headerModal}
          handleChange={this.onChange}
          contentField={this.state.paymentMethodObject}
          savePaymentMethod={this.savePaymentMethod}
          errorInformation={this.state.errors}
          listPaymentMethod={this.props.listPaymentMethod}
          handleChangePaymentMethod={this.onChangePaymentMethod}
          listCountry={this.state.listCountry}
          paymentMethodList={this.state.paymentMethod}
          loading={this.props.loading}
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    getPaymentMethodList: state.CustomEnumReducerStore.getPaymentMethodList,
    getPaymentMethodListStatus:
      state.CustomEnumReducerStore.getPaymentMethodListStatus,
    listCountry: state.CountryStore.getCountryList.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    listPaymentMethod: state.CustomEnumReducerStore.getPaymentChoice.map(
      type => {
        return {
          text: type,
          value: type === deposit ? 0 : 1,
          key: type,
        };
      }
    ),
    getPaymentMethodByCountryId:
      state.CustomEnumReducerStore.getPaymentMethodByCountryId,
    getPaymentMethodByCountryIdStatus:
      state.CustomEnumReducerStore.getPaymentMethodByCountryIdStatus,
    postPaymentMethodStatus:
      state.CustomEnumReducerStore.postPaymentMethodStatus,
    postPaymentMethod: state.CustomEnumReducerStore.postPaymentMethod,
    deletePaymentMethod: state.CustomEnumReducerStore.deletePaymentMethod,
    deletePaymentMethodStatus:
      state.CustomEnumReducerStore.deletePaymentMethodStatus,
    errors: state.CustomEnumReducerStore.errors,
    loading: state.CustomEnumReducerStore.loading,
  };
}
export default connect(
  mapStateToProps,
  {
    getPaymentMethodAction,
    postPaymentMethodInCountryAction,
    getPaymentMethodInCountryAction,
    deletePaymentMethodInCountryAction,
    getPaymentMethodByCountryIdAction,
    resetCustomEnumAction,
    getCountryListAction,
    getPaymentMethodChoiceListAction,
  }
)(PaymentMethod);
