import React from 'react';
import { Grid, Segment, Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import TableMasterImage from './TableMasterImage';
import { ListDropdownImage } from '../constants/MenuDropDownUploadImage';
import InputWithLabel from '../../../components/input/inputWithLabel';
import HeaderTitle from '../../../components/common/headerTitle';
const uploadMasterImage = props => (
  <Segment style={{ marginTop: '30px' }}>
    <Grid>
      <Grid.Row>
        <Grid.Column width={3}>
          <HeaderTitle title="Upload Image" />
          <Form>
            <InputWithLabel
              inline={false}
              type="select"
              label="Choose Menus"
              name="viewListBy"
              options={ListDropdownImage}
              handleChange={props.handleChange}
              selectOnBlur={false}
              value={props.valueDropDown}
            />
          </Form>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <TableMasterImage
            listData={props.imageDataList}
            openModalImage={props.openModalImage}
            valueDropDown={props.valueDropDown}
            disableSearch={props.disableSearch}
            handleChangeSearch={props.handleChangeSearch}
            pageChange={props.pageChange}
            searchInputObject={props.searchInputObject}
            searchInput={props.searchInput}
            activePage={props.activePage}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
);

uploadMasterImage.propTypes = {
  handleChange: PropTypes.func,
  disabledSave: PropTypes.bool,
  disabledUpload: PropTypes.bool,
  saveUpload: PropTypes.func,
  submit: PropTypes.func,
  imageDataList: PropTypes.array,
  valueDropDown: PropTypes.string,
  openModalImage: PropTypes.func,
  disableSearch: PropTypes.object,
  handleChangeSearch: PropTypes.func,
  pageChange: PropTypes.func,
  searchInputObject: PropTypes.string,
  searchInput: PropTypes.string,
  activePage: PropTypes.number,
};

export default uploadMasterImage;
