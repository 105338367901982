import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import { convertDateFormat } from '../../../../../../scripts/moment';
import { RECREATION } from './../constant/activityType';
import {
  isExcursionTimeOver,
  isTransportValid,
} from '../../../../../../scripts/itineraryBuilder/dailyProgram';
import MessageOverlap from '../MessageOverlap';
import { getOvertimeTransport } from '../../../../../../scripts/itineraryBuilder/demoPrice';

const typeTransaportationTransafer = ['Transfer_with_guide', 'Transfer'];
const MessageErrorActivity = props => {
  const isValidTransportation = (movement, transport) => {
    const validTransportation = isTransportValid(movement.Movements, transport);
    if (validTransportation) {
      return (
        <Message warning style={{ width: '43em', marginLeft: '5em' }}>
          Transport type cannot be used because it exceeds the duration
          limit.Please change the type of transportation.
        </Message>
      );
    } else return null;
  };
  const getOverTime = movement => {
    let { transportStore } = props;
    let overTime = 0;
    if (movement.Item.ServiceItemId && transportStore) {
      let transport = transportStore.find(
        item =>
          item.ServiceItemId === movement.Item.ServiceItemId &&
          convertDateFormat(item.DateTime, 'YYYY-MM-DD') ===
            convertDateFormat(movement.DateTime, 'YYYY-MM-DD')
      );
      overTime = transport ? getOvertimeTransport(transport) : 0;
    }
    if (overTime > 0) {
      return (
        <Message warning style={{ width: '43em', marginLeft: '5em' }}>
          Overtime {overTime} h, there will be extra charge for transportation
          overtime.
        </Message>
      );
    } else return null;
  };

  let { type, movement, data, dayDate } = props;
  return (
    <React.Fragment>
      {['READY', 'CUSTOM', 'SIMILAR', 'QUOTATION'].indexOf(
        type.toUpperCase()
      ) !== -1 &&
      typeTransaportationTransafer.indexOf(movement.Item.ServiceType) !== -1
        ? isValidTransportation(data, movement)
        : getOverTime(movement)}
      {(movement.MovementName === RECREATION ? (
        movement.Item.OperationStartTime ? (
          isExcursionTimeOver(movement)
        ) : (
          false
        )
      ) : (
        false
      )) ? (
        <Message
          error
          style={{
            width: '43em',
            marginLeft: '5em',
          }}
        >
          The excursion time is outside the operating time. Please change your
          excursion time.
        </Message>
      ) : null}

      <MessageOverlap
        dateDay={dayDate}
        dateActivity={movement.DateTime}
        movementName={movement.MovementName}
      />
    </React.Fragment>
  );
};

MessageErrorActivity.propTypes = {
  movement: PropTypes.object,
  type: PropTypes.string,
  data: PropTypes.object,
  dayDate: PropTypes.string,
  transportStore: PropTypes.object,
};

export default MessageErrorActivity;
