import React from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import {
  Button,
  Header,
  Modal,
  Grid,
  Checkbox,
  Message,
  Icon,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  setAllowedDayFalse,
  setAllowedDayTrue,
} from '../../constant/initialData';
import { convertDateFormat } from '../../../../../scripts/itineraryBuilder/timeHelper';

class ModalPeriodDate extends React.Component {
  static defaultProps = {
    numberOfMonths: 2,
  };
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.state = this.getInitialState();
  }
  getInitialState() {
    return {
      from: undefined,
      to: undefined,
      allowedDay: setAllowedDayTrue,
      checkAll: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      // JSON.stringify(prevProps.objectPeriod) !==
      // JSON.stringify(this.props.objectPeriod)
      prevProps.openModal !== this.props.openModal
    ) {
      // this.setState({
      //   from: this.props.objectPeriod
      //     ? new Date(this.props.objectPeriod.StartDate)
      //     : undefined,
      //   to: this.props.objectPeriod
      //     ? new Date(this.props.objectPeriod.EndDate)
      //     : undefined,
      //   checkAll: this.props.objectPeriod
      //     ? this.props.objectPeriod.AllowedDays.length === 7
      //       ? true
      //       : false
      //     : true,
      // });
      this.props.onEdit
        ? this.convertArrayAllowedToObject(this.props.objectPeriod.AllowedDays)
        : this.setState({
            allowedDay: {
              Sunday: true,
              Monday: true,
              Tuesday: true,
              Wednesday: true,
              Thursday: true,
              Friday: true,
              Saturday: true,
            },
          });
    }
  }
  convertArrayAllowedToObject = data => {
    let allowedDay = { ...setAllowedDayFalse };
    data.map(obj => {
      allowedDay[obj.Text] = true;
      return allowedDay;
    });
    this.setState({ allowedDay });
  };

  handleDayClick(day) {
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);
  }
  handleResetClick() {
    this.setState(this.getInitialState());
  }
  filterByDate = (e, { id, name }) => {
    this.handleResetClick();
    !this.props.isReadyFixPrice
      ? this.props.actionDateRange(id, name)
      : this.props.actionDateRange(id, this.state.allowedDay);
  };

  handlePickDay = async (e, { name }) => {
    let { allowedDay } = this.state;
    allowedDay[name] = !allowedDay[name];
    await this.checkingCheckAll(allowedDay);
    this.setState({ allowedDay });
  };

  checkingCheckAll = allowedDay => {
    let checkAll = Object.values(allowedDay);
    this.setState({ checkAll: checkAll.every(x => x === true) });
  };

  handleAllDay = () => {
    this.setState(({ checkAll }) => ({
      checkAll: !checkAll,
      allowedDay: {
        Sunday: !checkAll,
        Monday: !checkAll,
        Tuesday: !checkAll,
        Wednesday: !checkAll,
        Thursday: !checkAll,
        Friday: !checkAll,
        Saturday: !checkAll,
      },
    }));
  };
  handleMountModal = () => {
    if (this.props.onEdit) {
      this.setState({
        from: this.props.objectPeriod
          ? new Date(this.props.objectPeriod.StartDate)
          : undefined,
        to: this.props.objectPeriod
          ? new Date(this.props.objectPeriod.EndDate)
          : undefined,
        checkAll: this.props.objectPeriod
          ? this.props.objectPeriod.AllowedDays.length === 7
            ? true
            : false
          : true,
      });
    }
  };
  render() {
    const { from, to, allowedDay, checkAll } = this.state;
    const modifiers = { start: from, end: to };
    return (
      <Modal
        dimmer="blurring"
        closeIcon
        inverted
        open={this.props.openModal}
        onClose={this.props.handleClose}
        style={{ width: '43em' }}
        onMount={this.handleMountModal}
      >
        <Header icon="filter" content={this.props.contentHeader} />
        <Modal.Content>
          <Message warning compact>
            <Icon circular name="info" size="small" />
            The selected period must <strong>exceed the total days </strong>
          </Message>
          <div className="RangeExample">
            <DayPicker
              className="Selectable"
              numberOfMonths={2}
              selectedDays={[from, { from, to }]}
              modifiers={modifiers}
              onDayClick={this.handleDayClick}
              initialMonth={
                new Date(
                  convertDateFormat(from, 'YYYY'),
                  convertDateFormat(from, 'MM')
                )
              }
            />
            {this.props.isReadyFixPrice && (
              <Grid columns={4} style={{ marginTop: '1em' }}>
                <Header as={'h4'} content="Available Day" />
                <Grid.Row>
                  <Grid.Column>
                    <Checkbox
                      label="Monday"
                      name="Monday"
                      checked={allowedDay.Monday}
                      onChange={this.handlePickDay}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Checkbox
                      label="Tuesday"
                      name="Tuesday"
                      checked={allowedDay.Tuesday}
                      onChange={this.handlePickDay}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Checkbox
                      label="Wednesday"
                      name="Wednesday"
                      checked={allowedDay.Wednesday}
                      onChange={this.handlePickDay}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Checkbox
                      label="Thursday"
                      name="Thursday"
                      checked={allowedDay.Thursday}
                      onChange={this.handlePickDay}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column>
                    <Checkbox
                      label="Friday"
                      name="Friday"
                      checked={allowedDay.Friday}
                      onChange={this.handlePickDay}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Checkbox
                      label="Saturday"
                      name="Saturday"
                      checked={allowedDay.Saturday}
                      onChange={this.handlePickDay}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Checkbox
                      label="Sunday"
                      name="Sunday"
                      checked={allowedDay.Sunday}
                      onChange={this.handlePickDay}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Checkbox
                      label="All"
                      name="All"
                      checked={checkAll}
                      onChange={this.handleAllDay}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
            <style>{`
  .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .Selectable .DayPicker-Day {
    border-radius: 0 !important;
  }
  .Selectable .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .Selectable .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
`}</style>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            // icon="search"
            content="Select Date"
            onClick={this.filterByDate}
            id={this.state}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

ModalPeriodDate.propTypes = {
  actionDateRange: PropTypes.func,
  openModal: PropTypes.func,
  handleClose: PropTypes.func,
  contentHeader: PropTypes.string,
  isReadyFixPrice: PropTypes.bool,
  objectPeriod: PropTypes.object,
  onEdit: PropTypes.bool,
};
export default ModalPeriodDate;
