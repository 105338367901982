import {
  GET_SYSTEM_MARGIN_LIST,
  RESET_STATUS_SYSTEM_MARGIN,
  POST_SYSTEM_MARGIN,
  PUT_SYSTEM_MARGIN,
  DELETE_SYSTEM_MARGIN,
  GET_SYSTEM_MARGIN_BY_ID,
  GET_SYSTEM_MARGIN_BY_PROFILE_ID,
} from './constant/actionTypes';
import getSystemMarginListApi from '../../api/systemMarginApi/getSystemMarginListApi';
import postSystemMarginApi from '../../api/systemMarginApi/postSystemMarginApi';
import putSystemMarginApi from '../../api/systemMarginApi/putSystemMarginApi';
import deleteSystmeMarginApi from '../../api/systemMarginApi/deleteSystmeMarginApi';
import getSystemMarginByIdApi from '../../api/systemMarginApi/getSystemMarginByIdApi';
import getTourOperatorMarginByIdApi from '../../api/systemMarginApi/getTourOperatorMarginByIdApi';
import postTourOperatorSystemMarginApi from '../../api/systemMarginApi/postTourOperatorSystemMarginApi';
import getTourOperatorMarginByProfileIdApi from '../../api/systemMarginApi/getTourOperatorMarginByProfileIdApi';
import putTourOperatorSystemMarginApi from '../../api/systemMarginApi/putTourOperatorSystemMarginApi';
import deleteTourOperatorMarginApi from '../../api/systemMarginApi/deleteTourOperatorMarginApi';
const getSystemMarginListAction = code => {
  return dispatch => {
    return dispatch({
      type: GET_SYSTEM_MARGIN_LIST,
      payload: getSystemMarginListApi(code),
    });
  };
};

const resetSystemMarginAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_STATUS_SYSTEM_MARGIN,
    });
  };
};
const postSystemMarginAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_SYSTEM_MARGIN,
      payload: postSystemMarginApi(data),
    });
  };
};

const putSystemMarginAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_SYSTEM_MARGIN,
      payload: putSystemMarginApi(id, data),
    });
  };
};

const deleteSystmeMarginAction = Id => {
  return dispatch => {
    return dispatch({
      type: DELETE_SYSTEM_MARGIN,
      payload: deleteSystmeMarginApi(Id),
    });
  };
};

const getSystemMarginByIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_SYSTEM_MARGIN_BY_ID,
      payload: getSystemMarginByIdApi(Id),
    });
  };
};

const getTourOperatorMarginByIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_SYSTEM_MARGIN_BY_ID,
      payload: getTourOperatorMarginByIdApi(Id),
    });
  };
};

const getTourOperatorMarginByProfileIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_SYSTEM_MARGIN_BY_PROFILE_ID,
      payload: getTourOperatorMarginByProfileIdApi(Id),
    });
  };
};

const postTourOperatorSystemMarginAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_SYSTEM_MARGIN,
      payload: postTourOperatorSystemMarginApi(data),
    });
  };
};

const putTourOperatorSystemMarginAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_SYSTEM_MARGIN,
      payload: putTourOperatorSystemMarginApi(id, data),
    });
  };
};

const deleteTourOperatorMarginAction = Id => {
  return dispatch => {
    return dispatch({
      type: DELETE_SYSTEM_MARGIN,
      payload: deleteTourOperatorMarginApi(Id),
    });
  };
};

export {
  getSystemMarginListAction,
  resetSystemMarginAction,
  postSystemMarginAction,
  putSystemMarginAction,
  deleteSystmeMarginAction,
  getSystemMarginByIdAction,
  getTourOperatorMarginByIdAction,
  postTourOperatorSystemMarginAction,
  putTourOperatorSystemMarginAction,
  getTourOperatorMarginByProfileIdAction,
  deleteTourOperatorMarginAction,
};
