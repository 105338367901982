const copyObject = obj => {
  let newObj = JSON.stringify(obj);
  return JSON.parse(newObj);
};
export const tourOperatorValidation = (data, error) => {
  let newError = copyObject(error);
  newError.TourOperator.error = data.TourOperator === '';
  newError.isError = newError.TourOperator.error;
  return newError;
};
