import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import GeneralInformation from '../form/generalInfoTransportationProfile';
import LogoSupplierTransportationProfile from '../form/logoSupplierTransportationProfile';
import ContactInformation from '../../../shared/components/contactInfo/ContactInformation';
import TransportationUnit from '../transportationUnit/components/TransportationUnitList';
import ButtonBackAndSave from '../../../../components/common/buttonBackAndSave';
import HeaderTitle from '../../../../components/common/headerTitle';

const TransportationProfileForm = props => (
  <div>
    <Segment width={15} style={{ marginTop: '30px' }} loading={props.loading}>
      <Grid columns={2} padded="vertically">
        <Grid.Column>
          <HeaderTitle title={props.title} />
          <GeneralInformation
            handleChange={props.handleChange}
            rating={props.rating}
            transportationData={props.transportationData}
            listTransportationCatagories={props.listTransportationCatagories}
            errors={props.errors}
            disablePage={props.disablePage}
          />
          <ContactInformation
            handleChange={props.handleChangeContactInfo}
            onChangeDropdown={props.handleChangeContactInfo}
            contactObject={props.transportationData.AddressObject}
            errors={props.errors}
            listCountry={props.listCountry}
            listRegion={props.listRegion}
            listCity={props.listCity}
            listArea={props.listArea}
            loadingTransportation={props.loadingTransportation}
            disablePage={props.disablePage}
          />
        </Grid.Column>
        <Grid.Column>
          <ButtonBackAndSave
            save={props.saveTransportation}
            backPage={props.backPage}
            nameBack="Back"
            nameSave={props.contentButton}
          />
          <LogoSupplierTransportationProfile
            modalImage={props.modalImage}
            removeImage={props.removeImage}
            imagePreview={props.logoTransportation}
            headerContent="Supplier Logo"
            disablePage={props.disablePage}
            onImageSelect={props.handleChangeImage}
          />
        </Grid.Column>
      </Grid>
      <TransportationUnit
        newTransportationUnit={props.newTransportationUnit}
        listData={props.listTransportationUnit}
        transportationData={props.transportationData}
        exportTransportationUnit={props.exportTransportationUnit}
        uploadTransportationUnit={props.uploadTransportationUnit}
        templateTransportationUnit={props.templateTransportationUnit}
        disablePage={props.disablePage}
        buttonEdit={props.buttonEdit}
        buttonDelete={props.buttonDelete}
        numbersTo={props.numbersTo}
        numbersFrom={props.numbersFrom}
      />
    </Segment>
  </div>
);

TransportationProfileForm.propTypes = {
  newTransportationUnit: PropTypes.func,
  listArea: PropTypes.array,
  listRegion: PropTypes.array,
  listCity: PropTypes.array,
  listCountry: PropTypes.array,
  handleChange: PropTypes.func,
  rating: PropTypes.array,
  transportationData: PropTypes.object,
  modalImage: PropTypes.func,
  removeImage: PropTypes.func,
  imagePreview: PropTypes.string,
  listTransportationUnit: PropTypes.array,
  listTransportationCatagories: PropTypes.array,
  saveTransportation: PropTypes.func,
  backPage: PropTypes.func,
  title: PropTypes.string,
  handleChangeContactInfo: PropTypes.func,
  errors: PropTypes.object,
  exportTransportationUnit: PropTypes.func,
  uploadTransportationUnit: PropTypes.func,
  templateTransportationUnit: PropTypes.func,
  loadingTransportation: PropTypes.bool,
  disablePage: PropTypes.bool,
  contentButton: PropTypes.string,
  logoTransportation: PropTypes.object,
  buttonEdit: PropTypes.func,
  buttonDelete: PropTypes.func,
  numbersTo: PropTypes.number,
  numbersFrom: PropTypes.number,
  handleChangeImage: PropTypes.func,
  loading: PropTypes.bool,
};
export default TransportationProfileForm;
