import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Table, Header } from 'semantic-ui-react';
import { convertDateFormat } from '../../../../scripts/moment';
import Pagination from '../../../../components/common/pagination';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../../scripts/pagination';
import { Link } from 'react-router-dom';
// import InputWithLabel from '../../input/inputWithLabel';
class TabTransactionHistory extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
    };
  }
  //   filterData = () => {
  //     const { historyList } = this.props;
  //     const { searchInput } = this.state;
  //     return historyList.filter(data => {
  //       return data.Id.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1;
  //     });
  //   };
  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });

  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  render() {
    const { historyList, currentPackage } = this.props;
    const { activePage } = this.state;
    // let filterData = this.filterData();
    let pagedData = datasetPagination(historyList, 5);
    let listData = pagedData[activePage - 1];

    let { numbersFrom, numbersTo } = getNumberFromTo(listData, activePage, 5);
    return (
      <Grid>
        {/* <Grid.Row>
          <Grid.Column width="3">
            <Form>
              <InputWithLabel
                value={searchInput}
                handleChange={this.handleChange}
                name="searchInput"
                placeholder="Search by Id"
                inline={false}
                label="Search by Id"
                type="text"
              />
            </Form>
          </Grid.Column>
        </Grid.Row> */}
        <Grid.Row>
          <Grid.Column width="16">
            <Table basic="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Transaction Id</Table.HeaderCell>
                  <Table.HeaderCell>Created Date</Table.HeaderCell>
                  <Table.HeaderCell>Total Pax</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {listData && listData.length > 0
                  ? listData.map(
                      (item, i) =>
                        item.BookingTemplateRef ===
                          Number(currentPackage.id) && (
                          <Table.Row key={i}>
                            <Table.Cell>
                              <Link
                                to={`/tour-transaction/edit/${item.PackageType}/${item.Id}`}
                              >
                                {item.Id}
                              </Link>
                            </Table.Cell>
                            <Table.Cell>
                              {convertDateFormat(
                                item.CreatedDate,
                                'DD/MM/YYYY'
                              )}
                            </Table.Cell>
                            <Table.Cell>{item.TotalPaxQty}</Table.Cell>
                            <Table.Cell>
                              {item.Status.replace('_', ' ')}
                            </Table.Cell>
                          </Table.Row>
                        )
                    )
                  : null}
              </Table.Body>
            </Table>
            {!listData || listData.length === 0 ? (
              <Header textAlign="center">
                No transaction for this package{' '}
              </Header>
            ) : null}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Pagination
              pagedData={pagedData}
              numbersTo={numbersTo}
              numbersFrom={numbersFrom}
              data={historyList}
              pageChange={this.pageChange}
              activePage={activePage}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

TabTransactionHistory.propTypes = {
  historyList: PropTypes.array,
  currentPackage: PropTypes.object,
};

export default TabTransactionHistory;
