import { convertDateFormat } from '../../../scripts/moment';

const setDataPaymentRequest = listdata => {
  let optionsData = listdata.map(data => {
    return {
      Id: data.Id ? data.Id : null,
      ResouceBookingCode: data.ResouceBookingCode
        ? data.ResouceBookingCode
        : null,
      PaymentStatus: data.PaymentStatus ? data.PaymentStatus : null,
      OwnerShip: data.OwnerShip ? data.OwnerShip : null,
      Currency: data.Currency ? data.Currency : null,
      Value: data.Value ? data.Value : null,
      DueDate: data.DueDate
        ? convertDateFormat(data.DueDate, 'DD MMMM YYYY')
        : null,
      Date: data.Date ? convertDateFormat(data.Date, 'DD MMMM YYYY') : null,
      TourStartDate: data.TourStartDate
        ? convertDateFormat(data.TourStartDate, 'DD MMMM YYYY')
        : null,
      TourEndDate: data.TourEndDate
        ? convertDateFormat(data.TourEndDate, 'DD MMMM YYYY')
        : null,
      Note: data.Note ? data.Note : null,
      Priority: data.Priority ? data.Priority : null,
      CompleteData: data,
      PackageNo: data.PackageNo ? data.PackageNo : null,
      PackageType: data.PackageType ? data.PackageType : null,
    };
  });
  return optionsData;
};

export default setDataPaymentRequest;
