import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Image, Form, Grid } from 'semantic-ui-react';
import { StyleModal } from '../../../assets/styles/size';
import Loader from '../../../components/modal/loaderModal';
import InputWithLabel from '../../../components/input/inputWithLabel';
import ButtonClose from '../../../components/button/buttonCloseModal';
import UploadButton from '../../../components/common/uploadImageButton';

const ModalRegion = props => {
  return (
    <Modal
      dimmer="blurring"
      // closeIcon
      // inverted
      open={props.open}
      onClose={props.handleClose}
      size="small"
      style={StyleModal.modalWidth}
    >
      <Modal.Header>
        {props.header} <ButtonClose onClose={props.handleClose} />
      </Modal.Header>
      <Modal.Content>
        <Loader loading={props.loading} />
        <Grid columns={2} padded="vertically">
          <Grid.Column>
            <Form>
              <InputWithLabel
                placeholder="Name"
                name="Name"
                title="Name"
                label="Region Name"
                handleChange={props.handleChange}
                validate={props.errorInformation}
                value={props.contentField.Name}
              />
            </Form>
            <Form>
              <InputWithLabel
                label="Country"
                type="select"
                name="CountryId"
                value={props.contentField.CountryId}
                placeholder="Country"
                handleChange={props.handleChange}
                options={props.listCountry}
                validate={props.errorInformation}
              />
            </Form>
          </Grid.Column>

          <Grid.Column>
            <Image
              src={
                props.contentField.ImagePreview ||
                require('../../../assets/Icons/no_image.jpg')
              }
              size="small"
              centered
              rounded
              style={{ marginTop: '1em' }}
            />
            <UploadButton
              onImageSelect={props.handleChangeImage}
              label="Select Image"
              buttonStyle={{ marginTop: '1em', marginLeft: '3.6em' }}
            />
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={props.saveRegion}>
          Update
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

ModalRegion.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  listCountry: PropTypes.array,
  handleChange: PropTypes.func,
  contentField: PropTypes.shape({
    Name: PropTypes.string,
    Id: PropTypes.string,
    CountryId: PropTypes.string,
    ImagePreview: PropTypes.string,
  }),
  saveRegion: PropTypes.func,
  imagePreview: PropTypes.string,
  errorInformation: PropTypes.shape({
    CountryIdError: PropTypes.string,
    NameError: PropTypes.string,
  }),
  loading: PropTypes.bool,
  handleChangeImage: PropTypes.func,
  onImageSelect: PropTypes.func,
  labelStyles: PropTypes.object,
};
export default ModalRegion;
