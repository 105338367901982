export const CancellationReasonTypes = [
  {
    key: 'Travel_agent',
    text: 'Customer',
    value: `Travel_agent`,
  },
  {
    key: 'Partner',
    text: 'Partner',
    value: 'Partner',
  },
];
