import React from 'react';
import { connect } from 'react-redux';
import {
  Segment,
  Header,
  Grid,
  Dimmer,
  Loader,
  Container,
  Button,
  Form,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import TabTourTransaction from '../../../components/tab/TabMenuOnlyHeader';
import {
  getTransactionAllByStatus,
  postConfirmTransaction,
  resetStatusTransactionAction,
  getTransactionByStatusActionAfterDPAction,
  postEmailSendConfirmation,
  getTransactionHistoryByOperation,
} from '../../../actions/tourTransactionAction';
import { getAccountManagerByCompanyAction } from '../../shared/actions/customerProfileAction';
import { getGenerateResouceBookingAction } from '../../shared/actions/resourceBookingAction';
import { postAcceptQuotationAction } from './transactionAction';
import ButtonDropdown from '../../../components/common/buttonDropdown';
import ModalPackageList from './components/modal/ModalPackageList';
import {
  getFixedPackages,
  resetPackageAction,
} from '../../../actions/packageAction';
import InputSearch from '../../../components/input/inputSearch';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../scripts/pagination';
import TableListCreatedTransaction from './components/table/TableListCreatedTransaction';
import ModalAlert from '../../../components/modal/modalAlert';
import { Redirect } from 'react-router-dom';
import ModalConfirm from '../../../components/modal/modalConfirmationTwoButton';
import HeaderTitle from '../../../components/common/headerTitle';
import moment from 'moment';
import { convertDateFormat } from '../../../scripts/moment';
import ModalDateRange from '../../shared/components/modal/ModalDateRange';
import Pagination from '../../../components/common/pagination';
import InputWithLabel from '../../../components/input/inputWithLabel';
import styles from '../../../components/segment/styles';
import convertOptions from './services/helper/convertOptions';

const DELETE = 'Delete';
const SEND_EMAIL = 'Send Email';
const GENERATE_RESOURCE = 'Generate Resource';
const menus = [
  { value: 'created', title: 'Created' },
  { value: 'in payment', title: 'In Payment' },
  { value: 'confirmed', title: 'Confirmed' },
  { value: 'complete', title: 'Complete' },
  { value: 'cancelled', title: 'Cancelled' },
  { value: 'quotation', title: 'Quotation' },
];
const menusForOperation = [
  { value: 'in payment', title: 'In Payment' },
  { value: 'confirmed', title: 'Confirmed' },
  { value: 'complete', title: 'Complete' },
  { value: 'cancelled', title: 'Cancelled' },
];
const menusForFinance = [
  { value: 'created', title: 'Created' },
  { value: 'in payment', title: 'In Payment' },
  { value: 'confirmed', title: 'Confirmed' },
  { value: 'complete', title: 'Complete' },
  { value: 'cancelled', title: 'Cancelled' },
];
const dropdowns = [
  { value: 'fixed', title: 'Series Package' },
  { value: 'custom', title: 'Custom Package' },
  { value: 'ready', title: 'Ready Package' },
];
const operation = localStorage.getItem('companyRoles')
  ? localStorage.getItem('companyRoles').indexOf('Operation') !== -1
  : false;

class TourTransaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabActive: !operation ? 'created' : 'in payment',
      searchTitle: '',
      activePage: 1,
      openModalPackage: false,
      packageTypeSelected: '',
      openModalAlert: false,
      gotoNext: false,
      openModalConfirm: false,
      messageModalConfirm: '',
      nameModalConfirm: '',
      dateRange: {
        value: '',
        createStartDate: '',
        createEndDate: '',
        tourStartDate: moment(new Date()).format('YYYY-MM-DD'),
        tourEndDate: moment()
          .add(30, 'd')
          .format('YYYY-MM-DD'),
      },
      transactionAllByStatus: [],
      accountManager: [],
      radioValueDateRange: '1',
      packageListReadyAndFix: [],
      selectedIdSendEmail: 0,
      isOperation: null,
    };
  }

  componentDidMount() {
    const { tourStartDate, tourEndDate } = this.state.dateRange;
    this.props.getAccountManagerByCompanyAction();
    this.setState(
      {
        ...this.state,
        tabActive: this.props.isOperation ? 'in payment' : 'created',
      },
      () =>
        this.loadDataWithDate(
          '',
          '',
          tourStartDate,
          tourEndDate,
          !operation ? 'Booking_created' : 'settle'
        )
    );
  }
  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  handleTab = (e, { value }) => {
    this.setState({ tabActive: value });
    this.getListBooking(value);
  };
  getListBooking = tabActive => {
    this.setState({
      transactionAllByStatus: [],
      tabActive: tabActive,
    });
    const {
      createStartDate,
      createEndDate,
      tourStartDate,
      tourEndDate,
    } = this.state.dateRange;
    tabActive === 'created'
      ? this.loadDataWithDate(
          createStartDate,
          createEndDate,
          tourStartDate,
          tourEndDate,
          'Booking_created'
        )
      : tabActive === 'in payment'
      ? this.loadDataWithDate(
          createStartDate,
          createEndDate,
          tourStartDate,
          tourEndDate,
          'settle'
        )
      : tabActive === 'confirmed'
      ? this.loadDataWithDate(
          createStartDate,
          createEndDate,
          tourStartDate,
          tourEndDate,
          'Booking_confirmed'
        )
      : tabActive === 'cancelled'
      ? this.loadDataWithDate(
          createStartDate,
          createEndDate,
          tourStartDate,
          tourEndDate,
          'Booking_cancelled'
        )
      : tabActive === 'complete'
      ? this.loadDataWithDate(
          createStartDate,
          createEndDate,
          tourStartDate,
          tourEndDate,
          'Booking_completed'
        )
      : this.loadDataWithDate(
          createStartDate,
          createEndDate,
          tourStartDate,
          tourEndDate,
          'Booking_draft'
        );
    // : this.props.getTransactionAllByStatus('Booking_draft');
    this.setState({ activePage: 1 });
  };
  filtered = listData => {
    let { searchTitle } = this.state;
    return listData.filter(data => {
      let transactionStatus = data.TransactionStatus || data.PackageType;
      return (
        data.BookingName.toLowerCase().indexOf(searchTitle.toLowerCase()) !==
          -1 ||
        data.BookingId.toLowerCase().indexOf(searchTitle.toLowerCase()) !==
          -1 ||
        transactionStatus.toLowerCase().indexOf(searchTitle.toLowerCase()) !==
          -1 ||
        (data.CompanyName !== null &&
          data.CompanyName.toLowerCase().indexOf(searchTitle.toLowerCase()) !==
            -1)
      );
    });
  };
  filterByAccountManager = listData => {
    let { accountManager } = this.state;
    let newList = [];
    if (accountManager.length === 0) {
      newList = listData;
    } else if (accountManager.length > 0) {
      newList = listData.filter(
        data =>
          data.AccountManager.Value === accountManager[0] ||
          data.AccountManager.Value === accountManager[1] ||
          data.AccountManager.Value === accountManager[2]
      );
    } else {
      newList = listData;
    }
    return newList;
  };
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  openModalPackage = () => {
    this.setState({ openModalPackage: true });
  };
  closeModalPackage = () => {
    this.setState({ openModalPackage: false });
  };
  handleAddPackage = (e, { name }) => {
    this.setState({ openModalPackage: true, packageTypeSelected: name });
    if (name !== 'custom') {
      this.getListPackage(name);
    } else {
      this.setState({ ...this.state, gotoNext: true });
    }
  };

  getListPackage = name => {
    switch (name) {
      case 'fixed':
        this.props.getFixedPackages('FixedPackage');
        break;
      case 'ready':
        this.props.getFixedPackages('ReadyPackage');
        this.props.getFixedPackages('VariableDate');
        break;
      default:
        break;
    }
  };

  handleClickActionPackage = (e, { name, value, id }) => {
    const { tabActive } = this.state;
    let cancelTransaction = {
      Id: value,
      Confirmation: 'Cancel',
      PaymentChoice: 0,
    };
    if (name === 'createSimilar') {
      id !== 3
        ? // eslint-disable-next-line
          this.props.history.push(`/tour-transaction/create/Similar/${value}`)
        : // eslint-disable-next-line
          this.props.history.push(
            `/tour-transaction/create/FixedDateVariable/${value}`
          );
    } else if (name === 'delete') {
      this.setState({
        ...this.state,
        openModalConfirm: true,
        messageModalConfirm: 'Are You sure want cancel this transaction?',
        nameModalConfirm: DELETE,
        cancelTransaction,
      });
    } else if (name === 'edit') {
      // eslint-disable-next-line
      /Simple-Transaction-Detail/;
      this.props.history.push(
        `${
          this.props.isFinance
            ? '/Simple-Transaction-Detail/'
            : `/tour-transaction/edit/Quotation/`
        }${value}`
      );
    } else if (name === 'sendEmail') {
      this.setState({
        ...this.state,
        openModalConfirm: true,
        messageModalConfirm: 'Are you sure want send email to travel agent?',
        nameModalConfirm: SEND_EMAIL,
        selectedIdSendEmail: value,
      });
    } else if (name === 'generateResource') {
      this.setState({
        ...this.state,
        openModalConfirm: true,
        messageModalConfirm: 'Are you sure want generate resource?',
        nameModalConfirm: GENERATE_RESOURCE,
        selectedIdGenerate: value,
      });
      // eslint-disable-next-line
      // this.props.history.push(`/Resource-Booking-Detail/${value}`);
    } else {
      this.props
        .postAcceptQuotationAction(value)
        .then(res => {
          // eslint-disable-next-line
          // this.props.history.push(
          //   `/tour-transaction/create/Custom/${res.value.data.BookingDetailSum.Id}`
          // );
          this.setState({
            openModalAlert: true,
            contentAlert: `Success Accept Quotation with Booking Number ${res.value.data.BookingDetailSum.Id}`,
            headerAlert: 'Success',
          });
          this.getListBooking(tabActive);
        })
        .catch(res => {
          this.setState({
            openModalAlert: true,
            contentAlert: res.response.data.Message,
            headerAlert: 'Failed',
          });
          this.getListBooking(tabActive);
        });
    }
  };
  componentDidUpdate(prevProps) {
    if (this.props.postAcceptQuotationStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'the draft was successfully accepted',
      });
      this.getListBooking('quotation');
      this.props.resetStatusTransactionAction();
    } else if (this.props.postAcceptQuotationStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorMessage.message,
      });
      this.props.resetStatusTransactionAction();
    }
    if (this.props.statusBooking === 'success') {
      this.setState({
        transactionAllByStatus: this.props.listBooking,
      });
      this.props.resetStatusTransactionAction();
    }

    if (this.props.getTransactionAllByStatusStatus === 'success') {
      this.setState({
        transactionAllByStatus: this.props.transactionAllByStatus,
      });
      this.props.resetStatusTransactionAction();
    }
    if (prevProps.isOperation !== this.props.isOperation) {
      if (this.state.isOperation === null) {
        this.setState(
          {
            isOperation: this.props.isOperation,
            tabActive: this.props.isOperation ? 'in payment' : 'created',
          },
          () => {
            const { tourStartDate, tourEndDate } = this.state.dateRange;
            this.loadDataWithDate(
              '',
              '',
              tourStartDate,
              tourEndDate,
              !operation ? 'Booking_created' : 'settle'
            );
          }
        );
      }
    }
  }
  handleClose = () =>
    this.setState(
      {
        openModalAlert: false,
      },
      () => this.state.urlAlert && this.props.history.push(this.state.urlAlert)
    );

  handleModalConfirm = (e, { name }) => {
    // handleModalConfirm = () => {
    let { cancelTransaction, tabActive, selectedIdSendEmail } = this.state;
    if (name === DELETE) {
      this.deleteTransactions(cancelTransaction, tabActive);
    } else if (name === SEND_EMAIL) {
      this.props
        .postEmailSendConfirmation({
          tourTransactionId: selectedIdSendEmail,
          emailSendConfirmed: true,
        })
        .then(() => {
          this.setState({
            openModalAlert: true,
            contentAlert: 'Send Email',
            headerAlert: 'Successfully',
          });
        })
        .catch(() => {
          this.setState({
            openModalAlert: true,
            contentAlert: 'Send Email',
            headerAlert: 'Failed',
          });
        });
    } else if (name === GENERATE_RESOURCE) {
      this.props
        .getGenerateResouceBookingAction({
          tourTransactionCode: this.state.selectedIdGenerate,
          bookingTemplateIdRef: 0,
          resourceBookingType: '0',
        })
        .then(() => {
          this.setState({
            openModalAlert: true,
            contentAlert: 'Generated resource successfully',
            headerAlert: 'Successfully',
            urlAlert: `/Resource-Booking-Detail/${this.props.resourceBooking.ResourceBookingId}`,
          });
        })
        .catch(() => {
          this.setState({
            openModalAlert: true,
            contentAlert: this.props.errorResourceBooking
              ? this.props.errorResourceBooking.global
              : 'Failed generate resource',
            headerAlert: 'Failed',
          });
        });
    }
    this.setState({ ...this.state, openModalConfirm: false });
  };

  deleteTransactions = (cancelTransaction, tabActive) => {
    this.props
      .postConfirmTransaction(cancelTransaction)
      .then(() => {
        // eslint-disable-next-line
        this.setState({
          openModalAlert: true,
          contentAlert: 'Transaction canceled',
          headerAlert: 'Successfully',
        });
        this.getListBooking(tabActive);
      })
      .catch(() => {
        this.setState({
          openModalAlert: true,
          contentAlert: 'Canceled transaction failed',
          headerAlert: 'Failed',
        });
        this.getListBooking(tabActive);
      });
  };

  loadDataWithDate = (
    createStartDate,
    createEndDate,
    tourStartDate,
    tourEndDate,
    status
  ) => {
    !this.props.isOperation
      ? this.props.getTransactionByStatusActionAfterDPAction(
          tourStartDate,
          tourEndDate,
          createStartDate,
          createEndDate,
          status
        )
      : this.props.getTransactionHistoryByOperation(
          tourStartDate,
          tourEndDate,
          createStartDate,
          createEndDate,
          status
        );
  };

  handleCloseFilterBooking = () => {
    this.setState({
      openModalFilterBooking: false,
    });
  };

  actionDateRange = async id => {
    const value = this.state.radioValueDateRange;
    const { from, to } = id;
    const { statusFilter } = this.state.dateRange;
    this.setState({
      openModalFilterBooking: false,
    });
    if (value === '1') {
      await this.setState({
        dateRange: {
          ...this.state.dateRange,
          createStartDate: '',
          createEndDate: '',
          tourStartDate: moment(from).format('YYYY-MM-DD'),
          tourEndDate: moment(to).format('YYYY-MM-DD'),
        },
      });
      this.getListBooking(statusFilter);
    } else {
      await this.setState({
        dateRange: {
          ...this.state.dateRange,
          createStartDate: moment(from).format('YYYY-MM-DD'),
          createEndDate: moment(to).format('YYYY-MM-DD'),
          tourStartDate: '',
          tourEndDate: '',
        },
      });
      this.getListBooking(statusFilter);
    }
  };

  openModalDateRange = (e, { id, name }) =>
    this.setState({
      openModalFilterBooking: true,
      dateRange: {
        ...this.state.dateRange,
        idFilter: 'Filter ' + id + '',
        statusFilter: name,
      },
    });

  handleConvertPackage = packageType => {
    let a = typeof packageType;
    let type =
      a === 'string'
        ? packageType
        : packageType === 1
        ? 'Ready'
        : packageType === 2
        ? 'Fixed'
        : packageType === 3
        ? 'FixedDateVariable'
        : 'Custom';
    return type;
  };

  handleFilterByAccountManager = (e, { value }) => {
    this.setState({ ...this.state, accountManager: value });
  };

  handleClick = () => {
    this.setState({ accountManager: [] });
  };

  //====helper====
  // checkUserRole = () => {
  //   let { userRoles } = this.props;
  //   if (!_.isEmpty(userRoles)) {
  //     let userAdmin = userRoles.Roles.find(item => item === 'Administrator');
  //     return userAdmin ? true : false;
  //   } else return false;
  // };

  handleRadioDateRange = value => {
    this.setState({ radioValueDateRange: value });
  };

  render() {
    const {
      tabActive,
      searchTitle,
      openModalPackage,
      packageTypeSelected,
      gotoNext,
      openModalConfirm,
      messageModalConfirm,
      nameModalConfirm,
      transactionAllByStatus,
      openModalFilterBooking,
      accountManager,
      // packageListReadyAndFix,
    } = this.state;
    const {
      loadingHistory,
      packages,
      // getPackagesStatus,
      loadingPackage,
      loadingTourTransactions,
      listAccountManager,
      loadingSendEmail,
      isSales,
      isUserAdmin,
      isOperation,
      isFinance,
      loadingGenerate,
    } = this.props;
    let filterData = this.filtered(transactionAllByStatus);
    filterData = this.filterByAccountManager(filterData);
    let pagedData = datasetPagination(filterData, 5);
    let listData = pagedData[this.state.activePage - 1];
    let showCard =
      transactionAllByStatus &&
      loadingHistory === false &&
      loadingTourTransactions === false &&
      loadingSendEmail === false;

    let { numbersFrom, numbersTo } = getNumberFromTo(
      listData,
      this.state.activePage,
      5
    );
    if (gotoNext) {
      return <Redirect to="/tour-transaction/create/Custom/0" />;
    }
    let createEndDate =
      this.state.dateRange.createEndDate === ''
        ? ''
        : moment(this.state.dateRange.createEndDate).format('DD MMM YYYY');
    let createStartDate =
      this.state.dateRange.createStartDate === ''
        ? ''
        : moment(this.state.dateRange.createStartDate).format('DD MMM');
    let tourStartDate =
      this.state.dateRange.tourStartDate === ''
        ? ''
        : moment(this.state.dateRange.tourStartDate).format('DD MMM');
    let tourEndDate =
      this.state.dateRange.tourEndDate === ''
        ? ''
        : moment(this.state.dateRange.tourEndDate).format('DD MMM YYYY');
    // let isUserAdmin = this.checkUserRole();
    return (
      <Segment style={{ marginTop: '30px' }}>
        <HeaderTitle title="Tour Transaction" iconName="clipboard list" />
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <InputSearch
                value={searchTitle}
                handleChange={this.handleChange}
                placeholder="Search by Booking No or Tour Name"
                inputStyle={{
                  borderBottom: '1px solid black',
                  width: '275px',
                }}
                inputInnerStyle={{ border: '0px', borderRadius: 'none' }}
              />
            </Grid.Column>
            <Grid.Column width={8} textAlign="right">
              {isOperation || isFinance || (
                <ButtonDropdown
                  dropdowns={dropdowns}
                  title="Add New Transaction"
                  handleClick={this.handleAddPackage}
                  pointing={true}
                  labeled={true}
                  dropdownStyles={{
                    color: 'white',
                    background: '#16ab39',
                    zIndex: '99999',
                  }}
                  icon="plus"
                />
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={styles.noPadding}>
            <Grid.Column width={8}>
              <div
                style={{
                  fontWeight: 'bold',
                }}
              >
                {/* {(tourStartDate !== '' || tourEndDate !== '') && ( */}
                <Container
                  content={
                    'Start tour range date : ' +
                    tourStartDate +
                    ' - ' +
                    tourEndDate
                  }
                />
                {/* )} */}
                {/* {(createStartDate !== '' || createEndDate !== '') && ( */}
                <Container
                  content={
                    'Created tour range date : ' +
                    createStartDate +
                    ' - ' +
                    createEndDate
                  }
                />
                {/* )} */}
              </div>
            </Grid.Column>
            <Grid.Column width={8} textAlign="right">
              <Button
                icon="filter"
                basic
                size="small"
                floated="right"
                positive
                id="Tour Transaction"
                name={tabActive}
                onClick={this.openModalDateRange}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={styles.noPadding}>
            <Grid.Column width={4}>
              {isSales && (
                <Form>
                  <InputWithLabel
                    // placeholder="Choose Person responsible"
                    placeholder="Choose Account Manager"
                    type="select"
                    name="accountManager"
                    value={accountManager}
                    handleChange={this.handleFilterByAccountManager}
                    options={listAccountManager}
                    onClick={this.handleClick}
                    multiple={true}
                    fluid={false}
                    inline={false}
                  />
                </Form>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <TabTourTransaction
                menus={
                  isOperation
                    ? menusForOperation
                    : isFinance
                    ? menusForFinance
                    : menus
                }
                tabActive={tabActive}
                handleTab={this.handleTab}
              />
              {!(loadingSendEmail || loadingGenerate) &&
              showCard &&
              listData !== undefined &&
              listData.length > 0 ? (
                <TableListCreatedTransaction
                  listData={listData ? listData : []}
                  handleClick={this.handleClickActionPackage}
                  handleConvertPackage={this.handleConvertPackage}
                  isUserAdmin={isUserAdmin}
                  isOperation={isOperation}
                  isFinance={isFinance}
                  isSales={isSales}
                  tabActive={tabActive}
                />
              ) : loadingHistory ||
                loadingTourTransactions ||
                loadingSendEmail ||
                loadingGenerate ? (
                <Dimmer
                  active={
                    loadingHistory ||
                    loadingTourTransactions ||
                    loadingSendEmail ||
                    loadingGenerate
                  }
                  inverted={true}
                >
                  <Loader indeterminate />
                </Dimmer>
              ) : (
                <Header as="h4" style={{ textAlign: 'center' }}>
                  There are no {tabActive} transactions
                </Header>
              )}
              {/* {(!loadingHistory && !showCard && !!listData) ||
              (listData && listData.length < 1) ? (
                <Header as="h4" style={{ textAlign: 'center' }}>
                  There are no {tabActive} transactions
                </Header>
              ) : null} */}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row textAlign="center">
            <Grid.Column width={16} />
          </Grid.Row>
        </Grid>
        <Pagination
          pagedData={pagedData}
          numbersTo={numbersTo}
          numbersFrom={numbersFrom}
          data={filterData}
          pageChange={this.pageChange}
          activePage={this.state.activePage}
        />
        <ModalPackageList
          openModal={openModalPackage}
          closeModal={this.closeModalPackage}
          header={
            packageTypeSelected === 'fixed'
              ? 'Series Package List'
              : 'Ready Package List'
          }
          packages={packages}
          loading={loadingPackage}
          packageType={packageTypeSelected === 'fixed'}
        />
        <ModalAlert
          openModal={this.state.openModalAlert}
          handleClose={this.handleClose}
          header={this.state.headerAlert}
          content={this.state.contentAlert}
          nameButton="OK"
        />
        <ModalConfirm
          openModal={openModalConfirm}
          size="mini"
          close={this.handleModalConfirm}
          confirm={this.handleModalConfirm}
          message={messageModalConfirm}
          nameConfirm={nameModalConfirm}
          header={nameModalConfirm}
        />
        <ModalDateRange
          openModal={openModalFilterBooking}
          handleClose={this.handleCloseFilterBooking}
          dateRange={this.state.dateRange}
          actionDateRange={this.actionDateRange}
          contentHeader={this.state.dateRange.idFilter}
          value={this.state.radioValueDateRange}
          handleRadioDateRange={this.handleRadioDateRange}
        />
      </Segment>
    );
  }
}
TourTransaction.propTypes = {
  transactionAllByStatus: PropTypes.array,
  getTransactionAllByStatus: PropTypes.func,
  getTransactionAllByStatusStatus: PropTypes.string,
  loadingHistory: PropTypes.bool,
  getFixedPackages: PropTypes.func,
  packages: PropTypes.array,
  loadingPackage: PropTypes.bool,
  postAcceptQuotationAction: PropTypes.func,
  postConfirmTransaction: PropTypes.func,
  postAcceptQuotationStatus: PropTypes.string,
  resetStatusTransactionAction: PropTypes.func,
  errorMessage: PropTypes.string,
  loadingTourTransactions: PropTypes.bool,
  statusBooking: PropTypes.string,
  listBooking: PropTypes.array,
  getTransactionByStatusActionAfterDPAction: PropTypes.func,
  getAccountManagerByCompanyAction: PropTypes.func,
  listAccountManager: PropTypes.array,
  // userRoles: PropTypes.object,
  postEmailSendConfirmation: PropTypes.func,
  loadingSendEmail: PropTypes.bool,
  // getUserRoleByBearerAction: PropTypes.func,
  isSales: PropTypes.bool,
  isUserAdmin: PropTypes.bool,
  isOperation: PropTypes.bool,
  isFinance: PropTypes.bool,
  getGenerateResouceBookingAction: PropTypes.func,
  resourceBooking: PropTypes.object,
  errorResourceBooking: PropTypes.object,
  loadingGenerate: PropTypes.bool,
  getTransactionHistoryByOperation: PropTypes.func,
  history: PropTypes.object,
};
const mapStateToProps = state => {
  return {
    transactionAllByStatus: state.TourTransactionStore.listTransaction,
    getTransactionAllByStatusStatus:
      state.TourTransactionStore.listTransactionStatus ||
      state.TourTransactionStore.getTransactionAllByStatusStatus,
    loadingHistory: state.TourTransactionStore.loadingHistory,
    packages: state.PackageStore.packages,
    getPackagesStatus: state.PackageStore.getPackagesStatus,
    loadingPackage: state.PackageStore.loading,
    postAcceptQuotationStatus:
      state.TourTransactionStore.postAcceptQuotationStatus,
    postAcceptQuotation: state.TourTransactionStore.postAcceptQuotation,
    errorMessage: state.TourTransactionStore.errors,
    loadingTourTransactions: state.TourTransactionStore.loading,
    listBooking: state.TourTransactionStore.listTransaction.map(data => {
      return {
        Id: data.BookingId,
        Title: data.BookingName,
        CompanyCode: data.CompanyCode,
        CreatedBy: {
          CompanyName: data.CompanyName,
        },
        TotalGuest: data.AdultPax + data.ChildPax,
        Status: data.TransactionStatus,
        PackageType:
          data.PackageType === 1
            ? 'Ready'
            : data.PackageType === 2
            ? 'Fixed'
            : 'Custom',
        StartDate: data.StartDate
          ? convertDateFormat(data.StartDate, 'DD MMMM YYYY')
          : new Date(),
        EndDate: data.EndDate,
        CreateDate: data.CreateDate
          ? convertDateFormat(data.CreateDate, 'DD MMMM YYYY')
          : new Date(),
      };
    }),
    statusBooking: state.TourTransactionStore.listTransactionStatus,
    listAccountManager: state.CustomerProfileStore.getAccountManagerByCompany.map(
      type => {
        return convertOptions(type.Text, type.Value, type.Value);
      }
    ),
    loadingSendEmail: state.TourTransactionStore.loadingSendEmail,
    isSales: state.UserAccessStore.isSales,
    isUserAdmin: state.UserAccessStore.isAdministrator,
    isOperation: state.UserAccessStore.isOperation,
    isFinance: state.UserAccessStore.isFinance,
    resourceBooking: state.ResourceBooking.generateResourceBooking,
    errorResourceBooking: state.ResourceBooking.errors,
    loadingGenerate: state.ResourceBooking.loadingGenerate,
  };
};
export default connect(mapStateToProps, {
  getTransactionAllByStatus,
  getFixedPackages,
  postAcceptQuotationAction,
  postConfirmTransaction,
  resetStatusTransactionAction,
  getTransactionByStatusActionAfterDPAction,
  getAccountManagerByCompanyAction,
  resetPackageAction,
  postEmailSendConfirmation,
  getGenerateResouceBookingAction,
  getTransactionHistoryByOperation,
})(TourTransaction);
