import apiClient from '../apiClient';
import {
  GET_TRANSPORTATION_ADDITIONAL_COST_LIST_URL,
  GET_TRANSPORTATION_ADDITIONAL_COST_TYPE_URL,
  GET_ADDITIONAL_COST_BY_CITY_AND_TYPE_URL,
  DELETE_ADDITIONAL_COST_BY_CITY_AND_TYPE_URL,
  POST_TRANSPORTATION_ADDITIONAL_COST_DETAIL_URL,
  PUT_TRANSPORTATION_ADDITIONAL_COST_DETAIL_URL,
} from '../apiUrl';

const getAdditionalCostListApi = () => {
  return apiClient.get(GET_TRANSPORTATION_ADDITIONAL_COST_LIST_URL);
};

const getAdditionalCostTypeApi = () => {
  return apiClient.get(GET_TRANSPORTATION_ADDITIONAL_COST_TYPE_URL);
};

const getAdditionalCostByCityAndTypeApi = (origin, destination, type) => {
  return apiClient.get(
    GET_ADDITIONAL_COST_BY_CITY_AND_TYPE_URL(origin, destination, type)
  );
};

const deleteAdditionalCostByCityAndTypeApi = (origin, destination, type) => {
  return apiClient.delete(
    DELETE_ADDITIONAL_COST_BY_CITY_AND_TYPE_URL(origin, destination, type)
  );
};

const postAdditionalCostDetailApi = data => {
  return apiClient.post(POST_TRANSPORTATION_ADDITIONAL_COST_DETAIL_URL, data);
};

const putAdditionalCostDetailApi = data => {
  return apiClient.put(PUT_TRANSPORTATION_ADDITIONAL_COST_DETAIL_URL, data);
};

export {
  getAdditionalCostListApi,
  getAdditionalCostTypeApi,
  getAdditionalCostByCityAndTypeApi,
  deleteAdditionalCostByCityAndTypeApi,
  postAdditionalCostDetailApi,
  putAdditionalCostDetailApi,
};
