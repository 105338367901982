const Styles = {
  fivePaddingTop: {
    padding: '5px 0px',
  },
  noPadding: {
    padding: '0px',
  },
  calender50: {
    padding: '5px 0px 5px 0px',
    background: 'rgba(0,0,0, 0.5)',
    color: 'white',
  },
  calender20: {
    padding: '2px 0px 2px 0px',
    background: 'rgba(0,0,0, 0.2)',
    color: 'white',
  },
  calender50withHeight: {
    padding: '5px 0px 5px 0px',
    background: 'rgba(0,0,0, 0.5)',
    color: 'white',
    height: '29px',
  },
  calender20withHeight: {
    padding: '2px 0px 2px 0px',
    background: 'rgba(0,0,0, 0.2)',
    color: 'white',
    height: '23px',
  },
  sizeFontAllotment: {
    fontSize: '13px',
  },
};
export default Styles;
