import * as types from './constant/actionTypes';
const initialState = {
  deleteRegionStatus: '',
  postRegionStatus: '',
  putRegionStatus: '',
  getRegionById: {},
  getRegionByIdStatus: '',
  errors: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_REGION_PAGE:
      return {
        ...state,
        getRegionByIdStatus: '',
        deleteRegionStatus: '',
        postRegionStatus: '',
        putRegionStatus: '',
      };

    case types.DELETE_REGION:
      return { ...state };
    case types.DELETE_REGION_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        deleteRegionStatus: 'failed',
      };
    case types.DELETE_REGION_PENDING:
      return { ...state, loading: true };
    case types.DELETE_REGION_FULFILLED:
      return {
        ...state,
        loading: false,
        deleteRegionStatus: 'success',
      };
    case types.GET_REGION_BY_ID:
      return { ...state };
    case types.GET_REGION_BY_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getRegionByIdStatus: 'failed',
      };
    case types.GET_REGION_BY_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_REGION_BY_ID_FULFILLED:
      return {
        ...state,
        getRegionById: action.payload.data,
        loading: false,
        getRegionByIdStatus: 'success',
      };
    case types.POST_REGION:
      return { ...state };
    case types.POST_REGION_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        postRegionStatus: 'failed',
      };
    case types.POST_REGION_PENDING:
      return { ...state, loading: true };
    case types.POST_REGION_FULFILLED:
      return {
        ...state,
        loading: false,
        postRegionStatus: 'success',
      };
    case types.PUT_REGION:
      return { ...state };
    case types.PUT_REGION_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        putRegionStatus: 'failed',
      };
    case types.PUT_REGION_PENDING:
      return { ...state, loading: true };
    case types.PUT_REGION_FULFILLED:
      return {
        ...state,
        loading: false,
        putRegionStatus: 'success',
      };
    default:
      return state;
  }
};

export default reducer;
