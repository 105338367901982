import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ActionButton from '../../../../components/common/buttonEditAndDelete';
const TableCityGroup = props => (
  <Table basic="very" selectable fixed>
    <Table.Header>
      <Table.Row>
        {props.headerTable.map((headTableContent, index) => {
          return (
            <Table.HeaderCell key={index}>{headTableContent}</Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {props.listData ? (
        props.listData.map((cityGroup, idx) => {
          return (
            <Table.Row key={idx}>
              <Table.Cell>{cityGroup.Name}</Table.Cell>
              <Table.Cell>
                {cityGroup.Cities &&
                  Array.prototype.map
                    .call(cityGroup.Cities, function(item) {
                      return item.Text;
                    })
                    .join(', ')}
              </Table.Cell>
              <ActionButton
                id={cityGroup.Id}
                handleClickEdit={props.butttonEdit}
                handleClickDelete={props.buttonDelete}
                name1="Edit"
                name2="Delte"
                content1="Edit"
                content2="Delete"
              />
            </Table.Row>
          );
        })
      ) : (
        <Table.Row>
          <Table.Cell colSpan="3" textAlign="center">
            No Data
          </Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  </Table>
);

TableCityGroup.propTypes = {
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  actionButton: PropTypes.func,
  butttonEdit: PropTypes.func,
  buttonDelete: PropTypes.func,
};
export default TableCityGroup;
