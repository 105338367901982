import apiClient from '../apiClient';
import { GET_DOWNLOAD_VOUCHER_CONFIRMATION_BY_TRANSACTION_ID_URL } from '../apiUrl';

const getDownloadVoucherConfirmationByTransactionIdApi = Id => {
  return apiClient.get(
    GET_DOWNLOAD_VOUCHER_CONFIRMATION_BY_TRANSACTION_ID_URL(Id)
  );
};

export default getDownloadVoucherConfirmationByTransactionIdApi;
