const encodeString = (string, char) => {
  return char === '<'
    ? string.replace(/</g, '%3C')
    : char === '!'
      ? string.replace(/!/g, '%21')
      : char === '/'
        ? string.replace(/\//g, '%2F')
        : char === '>'
          ? string.replace(/>/g, '%3E')
          : char === '%0A'
            ? string.replace(/%0A/g, '') // The ASCII control characters %00-%1F were originally designed to control hardware devices.
            : char === '%0D'
              ? string.replace(/%0D/g, '')
              : char === '"'
                ? string.replace(/"/g, '%22')
                : char === '#'
                  ? string.replace(/#/g, '%23')
                  : char === '%'
                    ? string.replace(/%/g, '%25')
                    : char === '&'
                      ? string.replace(/&/g, '%26')
                      : char === "'"
                        ? string.replace(/'/g, '%27')
                        : char === '('
                          ? string.replace(/\(/g, '%28')
                          : char === ')'
                            ? string.replace(/\)/g, '%29')
                            : char === '*'
                              ? string.replace(/\*/g, '%2A')
                              : char === ','
                                ? string.replace(/,/g, '%2C')
                                : char === ':'
                                  ? string.replace(/:/g, '%3A')
                                  : char === ';'
                                    ? string.replace(/;/g, '%3B')
                                    : char === '='
                                      ? string.replace(/=/g, '%3D')
                                      : char === '@'
                                        ? string.replace(/@/g, '%40')
                                        : char === '{'
                                          ? string.replace(/{/g, '%7B')
                                          : char === '}'
                                            ? string.replace(/}/g, '%7D')
                                            : string;
};

const encodeOtherChar = (string, char) => {
  return char === ' '
    ? string.replace(/\s/g, '+')
    : char === '\n'
      ? string.replace(/\n/g, '&')
      : char === ''
        ? // not use
          string.replace(/\r/g, '')
        : char === '\n'
          ? string.replace(/\n/g, '')
          : char === '\\'
            ? string.replace(/\\/g, '')
            : string;
};

const encodeXML = async string => {
  let encodedString = string;
  await (encodedString = encodeString(encodedString, '%'));
  await (encodedString = encodeString(encodedString, '<'));
  await (encodedString = encodeString(encodedString, '!'));
  await (encodedString = encodeString(encodedString, '/'));
  await (encodedString = encodeString(encodedString, '>'));
  await (encodedString = encodeString(encodedString, '%0A'));
  await (encodedString = encodeString(encodedString, '%0D'));
  await (encodedString = encodeString(encodedString, '"'));
  await (encodedString = encodeString(encodedString, '#'));
  await (encodedString = encodeString(encodedString, '&'));
  await (encodedString = encodeString(encodedString, "'"));
  await (encodedString = encodeString(encodedString, '('));
  await (encodedString = encodeString(encodedString, ')'));
  await (encodedString = encodeString(encodedString, '*'));
  await (encodedString = encodeString(encodedString, ','));
  await (encodedString = encodeString(encodedString, ':'));
  await (encodedString = encodeString(encodedString, ';'));
  await (encodedString = encodeString(encodedString, '='));
  await (encodedString = encodeString(encodedString, '@'));
  await (encodedString = encodeString(encodedString, '{'));
  await (encodedString = encodeString(encodedString, '}'));

  // non url encode
  await (encodedString = encodeOtherChar(encodedString, ' '));
  // await (encodedString = encodeOtherChar(encodedString, '&'));
  //   await (encodedString = encodeOtherChar(encodedString, '\\'));
  //   await (encodedString = encodeOtherChar(encodedString, '\r'));
  //   await (encodedString = encodeOtherChar(encodedString, '\n'));
  return encodedString;
};

export { encodeXML };
