let isShortCompanyFormValid = false;

const selectTourLeader = guests => {
  let leader = guests.filter(e => e.GuestType === 'TOURLEADER');
  if (leader.length === 0) {
    let idMin = Math.min.apply(
      Math,
      guests.map(e => {
        return e.Id;
      })
    );
    leader = guests.filter(e => e.Id === idMin && e.GuestCategory === 'ADULT');
  }
  return leader[0];
};
const validationFillGuest = (guestList, errorFormGuest) => {
  let tourLeader = selectTourLeader(guestList);
  let newErrorFormGuest = copyObject(errorFormGuest);
  if (tourLeader) {
    newErrorFormGuest.firstName.error = tourLeader.FirstName === '';
    newErrorFormGuest.lastName.error = tourLeader.LastName === '';
    newErrorFormGuest.identityNbr.error = tourLeader.IdentityNbr === '';
    newErrorFormGuest.identityType.error = tourLeader.IdentityType === '';
    newErrorFormGuest.guestTitle.error = tourLeader.GuestTitle === '';
    newErrorFormGuest.countryId.error = tourLeader.CountryId === '';
  }
  return newErrorFormGuest;
};
const checkFillGuest = (guest, errors) => {
  if (!guest.IsDelete) {
    errors.firstName.error = guest.FirstName ? false : true;
    errors.lastName.error = guest.LastName ? false : true;
    errors.identityNbr.error = guest.IdentityNbr ? false : true;
    errors.identityType.error = guest.IdentityType ? false : true;
    errors.guestTitle.error = guest.GuestTitle ? false : true;
    errors.countryId.error = guest.CountryId ? false : true;
  }
  return errors;
};
const validationFillAllGuests = guestList => {
  let copyGuestList = copyObject(guestList);
  return copyGuestList.map(guest => {
    return { ...guest, errors: checkFillGuest(guest, guest.errors) };
  });
};
const isGuestError = errorGuest => {
  return (
    errorGuest.firstName.error ||
    errorGuest.lastName.error ||
    errorGuest.identityNbr.error ||
    errorGuest.identityType.error ||
    errorGuest.guestTitle.error ||
    errorGuest.countryId.error
  );
};
const isAllGuestsError = guestList => {
  let copyGuestList = copyObject(guestList);
  return copyGuestList.some(guest => isGuestError(guest.errors));
};
const copyObject = obj => {
  let newObj = JSON.stringify(obj);
  return JSON.parse(newObj);
};

const validationTourPax = (guests, errorFormPackage) => {
  let newErrorFormPackage = copyObject(errorFormPackage);
  if (guests.adults === 0) {
    newErrorFormPackage.tourPax.error = true;
    newErrorFormPackage.tourPax.message = ['Please input at least 1 adult'];
  } else {
    newErrorFormPackage.tourPax.error = false;
    newErrorFormPackage.tourPax.message = [];
  }
  return newErrorFormPackage;
};
const validationAllocationPax = (guests, roomAllocation, errorFormPackage) => {
  let newErrorFormPackage = copyObject(errorFormPackage);
  newErrorFormPackage.roomAllocation.message = [];
  let { adults, childs, infants } = guests;
  let allocatedAdults =
    roomAllocation.ExtraBedQty +
    roomAllocation.SharingRoomQty +
    roomAllocation.SingleRoomQty;
  let allocatedChilds =
    roomAllocation.ChildExtraBedQty +
    roomAllocation.ChildSharingRoomQty +
    roomAllocation.ChildSingleRoomQty +
    roomAllocation.SharingBedQty;
  let allocatedInfants = roomAllocation.BabyCrib + roomAllocation.NoBed;
  let currentAdult = adults - allocatedAdults;
  let currentChild = childs - allocatedChilds;
  let currentInfant = infants - allocatedInfants;
  if (currentAdult !== 0) {
    let diff = Math.abs(currentAdult);
    let message1 = `Please allocate ${diff} more ${
      diff > 1 ? 'adults' : 'adult'
    }`;
    let message2 = `Over allocated ${diff} ${diff > 1 ? 'adults' : 'adult'}`;
    newErrorFormPackage.roomAllocation.error = true;
    newErrorFormPackage.roomAllocation.message.push(
      adults > allocatedAdults ? message1 : message2
    );
  }
  if (currentChild !== 0) {
    let diff = Math.abs(currentChild);
    let message1 = `Please allocate ${diff} more ${
      diff > 1 ? 'childs' : 'child'
    }`;
    let message2 = `Over allocated ${diff} ${diff > 1 ? 'childs' : 'child'}`;
    newErrorFormPackage.roomAllocation.error = true;
    newErrorFormPackage.roomAllocation.message.push(
      childs > allocatedChilds ? message1 : message2
    );
  }
  if (currentInfant !== 0) {
    let diff = Math.abs(currentInfant);
    let message1 = `Please allocate ${diff} more ${
      diff > 1 ? 'infants' : 'infant'
    }`;
    let message2 = `Over allocated ${diff} ${diff > 1 ? 'infants' : 'infant'}`;
    newErrorFormPackage.roomAllocation.error = true;
    newErrorFormPackage.roomAllocation.message.push(
      infants > allocatedInfants ? message1 : message2
    );
  }
  if (currentAdult === 0 && currentChild === 0 && currentInfant === 0) {
    newErrorFormPackage.roomAllocation.error = false;
    newErrorFormPackage.roomAllocation.message = [];
  }
  return newErrorFormPackage;
};
const validationMinPax = (guests, errorFormPackage, minPax) => {
  let newErrorFormPackage = copyObject(errorFormPackage);
  let total = guests.adults + guests.childs + guests.infants;
  if (total < minPax) {
    newErrorFormPackage.minimumPax.error = true;
    newErrorFormPackage.minimumPax.message = [
      `Guest invalid, the minimum pax for this package is ${minPax} pax`,
    ];
  } else {
    newErrorFormPackage.minimumPax.error = false;
    newErrorFormPackage.minimumPax.message = [];
  }
  return newErrorFormPackage;
};
const validationTotalGuests = (guestList, guests, errorFormPackage) => {
  let copyGuestList = copyObject(guestList);
  let copyErrorFormPackage = copyObject(errorFormPackage);
  let adults = copyGuestList.filter(
    e => e.GuestCategory === 'ADULT' && !e.IsDelete
  );
  let children = copyGuestList.filter(
    e => e.GuestCategory === 'CHILDREN' && !e.IsDelete
  );
  let infants = copyGuestList.filter(
    e => e.GuestCategory === 'INFANT' && !e.IsDelete
  );
  copyErrorFormPackage.guests = { error: false, message: [] };
  if (adults.length < guests.adults) {
    copyErrorFormPackage.guests.error = true;
    copyErrorFormPackage.guests.message.push(
      'Please add ' + (guests.adults - adults.length) + ' adults'
    );
  } else if (adults.length > guests.adults) {
    copyErrorFormPackage.guests.error = true;
    copyErrorFormPackage.guests.message.push(
      'Please remove ' + (adults.length - guests.adults) + ' adults'
    );
  }
  if (children.length < guests.childs) {
    copyErrorFormPackage.guests.error = true;
    copyErrorFormPackage.guests.message.push(
      'Please add ' + (guests.childs - children.length) + ' children'
    );
  } else if (children.length > guests.childs) {
    copyErrorFormPackage.guests.error = true;
    copyErrorFormPackage.guests.message.push(
      'Please remove ' + (children.length - guests.childs) + ' children'
    );
  }
  if (infants.length < guests.infants) {
    copyErrorFormPackage.guests.error = true;
    copyErrorFormPackage.guests.message.push(
      'Please add ' + (guests.infants - infants.length) + ' infants'
    );
  } else if (infants.length > guests.infants) {
    copyErrorFormPackage.guests.error = true;
    copyErrorFormPackage.guests.message.push(
      'Please remove ' + (infants.length - guests.infants) + ' infants'
    );
  }
  return copyErrorFormPackage;
};
const isErrorFormPackage = errorFormPackage => {
  let errorTourPax = errorFormPackage.tourPax && errorFormPackage.tourPax.error;
  let errorRoomAllocation =
    errorFormPackage.roomAllocation && errorFormPackage.roomAllocation.error;
  let errorQuota = errorFormPackage.quota && errorFormPackage.quota.error;
  let errorCommission =
    errorFormPackage.commission && errorFormPackage.commission.error;
  let errorMinPax =
    errorFormPackage.minimumPax && errorFormPackage.minimumPax.error;
  return (
    errorTourPax ||
    errorRoomAllocation ||
    errorQuota ||
    errorCommission ||
    errorMinPax
  );
};
const isAdultChildAllocationValid = (guests, roomAllocation) => {
  const totalGuest = guests.adults + guests.childs;
  let totalAllocated =
    (roomAllocation.SharingRoomQty
      ? roomAllocation.SharingRoomQty
      : roomAllocation.SharingRoomSum
      ? roomAllocation.SharingRoomSum.Pax
      : 0) +
    (roomAllocation.SingleRoomQty
      ? roomAllocation.SingleRoomQty
      : roomAllocation.SingleRoomSum
      ? roomAllocation.SingleRoomSum.Pax
      : 0) +
    (roomAllocation.ExtraBedQty
      ? roomAllocation.ExtraBedQty
      : roomAllocation.ExtraBedSum
      ? roomAllocation.ExtraBedSum.Pax
      : 0) +
    (roomAllocation.ChildExtraBedQty
      ? roomAllocation.ChildExtraBedQty
      : roomAllocation.ChildExtraBedSum
      ? roomAllocation.ChildExtraBedSum.Pax
      : 0) +
    (roomAllocation.SharingBedQty
      ? roomAllocation.SharingBedQty
      : roomAllocation.SharingBedSum
      ? roomAllocation.SharingBedSum.Pax
      : 0);
  // totalAllocated =
  //   totalAllocated ||
  //   (roomAllocation.SharingRoomSum.Pax || 0) +
  //     (roomAllocation.SingleRoomSum.Pax || 0) +
  //     roomAllocation.ExtraBedSum.Pax +
  //     roomAllocation.ChildExtraBedSum.Pax +
  //     roomAllocation.SharingBedSum.Pax;
  // totalAllocated = totalAllocated || 0;
  return totalAllocated < totalGuest
    ? 'You need to allocate ' +
        (totalGuest - totalAllocated) +
        ' more adult or children'
    : totalAllocated > totalGuest
    ? 'You over allocated ' +
      (totalAllocated - totalGuest) +
      ' adult or children'
    : '';
};
const isInfantAllocationValid = (guests, roomAllocation) => {
  let totalAllocated = roomAllocation.NoBed
    ? roomAllocation.NoBed
    : roomAllocation.NoBedSum
    ? roomAllocation.NoBedSum.Pax
    : 0;
  return totalAllocated < guests.infants
    ? 'You need to allocate ' +
        (guests.infants - totalAllocated) +
        ' more infant'
    : totalAllocated > guests.infants
    ? 'You over allocated ' + (totalAllocated - guests.infants) + ' infant'
    : '';
};
const isRoomAllocationValid = (errorFormPackage, guests, roomAllocation) => {
  let newErrorFormPackage = copyObject(errorFormPackage);
  let isAdultChildValid = isAdultChildAllocationValid(guests, roomAllocation);
  let isInfantValid = isInfantAllocationValid(guests, roomAllocation);
  if (isAdultChildValid !== '' && isInfantValid !== '') {
    newErrorFormPackage.roomAllocation.error = true;
    newErrorFormPackage.roomAllocation.message = [
      isAdultChildValid,
      isInfantValid,
    ];
  } else if (isAdultChildValid !== '' && isInfantValid === '') {
    newErrorFormPackage.roomAllocation.error = true;
    newErrorFormPackage.roomAllocation.message = [isAdultChildValid];
  } else if (isAdultChildValid === '' && isInfantValid !== '') {
    newErrorFormPackage.roomAllocation.error = true;
    newErrorFormPackage.roomAllocation.message = [
      isAdultChildValid,
      isInfantValid,
    ];
  } else {
    newErrorFormPackage.roomAllocation.error = false;
    newErrorFormPackage.roomAllocation.message = [''];
  }
  return newErrorFormPackage;
};

const isQuotaValid = (
  errorFormPackage,
  minimumGuest,
  confirmedGuest,
  guests
) => {
  let newErrorFormPackage = copyObject(errorFormPackage);
  let availablePax = minimumGuest - confirmedGuest;
  let totalGuest = guests.adults + guests.childs + guests.infants;
  if (totalGuest > availablePax) {
    newErrorFormPackage.quota.error = true;
    newErrorFormPackage.quota.message = [
      'Series package out off quota, the remaining quota is ' +
        (totalGuest - availablePax) +
        ' person',
    ];
  } else {
    newErrorFormPackage.quota.error = false;
    newErrorFormPackage.quota.message = [''];
  }
  return newErrorFormPackage;
};
const isCommissionValid = (errorFormPackage, splitCommision) => {
  let newErrorFormPackage = copyObject(errorFormPackage);
  if (splitCommision !== 0 && splitCommision !== 1) {
    newErrorFormPackage.commission.error = true;
    newErrorFormPackage.commission.message = [
      'Please select commission confirmation first',
    ];
  } else {
    newErrorFormPackage.commission.error = false;
    newErrorFormPackage.commission.message = [''];
  }
  return newErrorFormPackage;
};
const isRoomValid = (name, value, guests, packageType) => {
  let isValid = true;
  if (packageType === 'Fixed') {
    if (name === 'SharingRoomSum' || name === 'SingleRoomSum') {
      isValid = guests.adults + guests.childs > value;
    } else if (name === 'ChildExtraBedSum' || name === 'ChildSharingRoomSum') {
      isValid = guests.childs > value;
    } else if (name === 'ExtraBedSum') {
      isValid = guests.infants > value;
    }
  } else {
    if (
      name === 'SharingRoomSum' ||
      name === 'SingleRoomSum' ||
      name === 'ExtraBedSum'
    ) {
      isValid = guests.adults > value;
    } else if (
      name === 'ChildSharingRoomSum' ||
      name === 'ChildSingleRoomSum' ||
      name === 'ChildExtraBedSum' ||
      name === 'SharingBedSum'
    ) {
      isValid = guests.childs > value;
    } else {
      isValid = guests.infants > value;
    }
  }
  return isValid;
};
const isGuestsValid = (name, value, bookingDetailSum) => {
  let copyBookingDetailSum = copyObject(bookingDetailSum);
  let isValid = false;
  if (name === 'adults') {
    isValid = copyBookingDetailSum.AdultQty > value;
  } else if (name === 'childs') {
    isValid = copyBookingDetailSum.ChildQty > value;
  } else {
    isValid = copyBookingDetailSum.InfantQty > value;
  }
  return isValid;
};

const isGroupCapacityValid = (errors, guestsAmount, groupCapacity) => {
  let copyErrors = copyObject(errors);
  let errorCapacity = copyErrors.groupCapacity;
  let { adults, childs, infants } = guestsAmount;
  let totalGuest = adults + childs + infants;
  if (totalGuest > 10 && groupCapacity === 'Small') {
    errorCapacity.error = true;
    errorCapacity.message = 'Maximum pax for small group type is 10 pax';
  } else if (totalGuest < 10 && groupCapacity === 'Large') {
    errorCapacity.error = true;
    errorCapacity.message = 'Minimum pax for large group type is 11 pax';
  } else if (groupCapacity === '') {
    errorCapacity.error = true;
    errorCapacity.message = 'Please choose tour type';
  } else {
    errorCapacity.error = false;
    errorCapacity.message = '';
  }
  return copyErrors;
};

const validationShortRegisCompany = data => {
  let copyData = copyObject(data);
  let {
    companyName,
    companyCountry,
    companyTelephone,
    companyAddress,
    companyCurrency,
    userFirstName,
    userLastName,
    userEmail,
    userPhoneNumber,
  } = copyData;
  let messageRequired = 'This section must be filled';
  let isValid = true;

  if (companyName.data === '') {
    companyName.error = true;
    companyName.message = messageRequired;
    isValid = isValid && false;
  } else {
    companyName.error = false;
    companyName.message = '';
    isValid = isValid && true;
  }

  if (companyCountry.data === '') {
    companyCountry.error = true;
    companyCountry.message = messageRequired;
    isValid = isValid && false;
  } else {
    companyCountry.error = false;
    companyCountry.message = '';
    isValid = isValid && true;
  }

  if (companyTelephone.data === '') {
    companyTelephone.error = true;
    companyTelephone.message = messageRequired;
    isValid = isValid && false;
  } else {
    companyTelephone.error = false;
    companyTelephone.message = '';
    isValid = isValid && true;
  }

  if (companyAddress.data === '') {
    companyAddress.error = true;
    companyAddress.message = messageRequired;
    isValid = isValid && false;
  } else {
    companyAddress.error = false;
    companyAddress.message = '';
    isValid = isValid && true;
  }

  if (companyCurrency.data === '') {
    companyCurrency.error = true;
    companyCurrency.message = messageRequired;
    isValid = isValid && false;
  } else {
    companyCurrency.error = false;
    companyCurrency.message = '';
    isValid = isValid && true;
  }

  if (userFirstName.data === '') {
    userFirstName.error = true;
    userFirstName.message = messageRequired;
    isValid = isValid && false;
  } else {
    userFirstName.error = false;
    userFirstName.message = '';
    isValid = isValid && true;
  }

  if (userLastName.data === '') {
    userLastName.error = true;
    userLastName.message = messageRequired;
    isValid = isValid && false;
  } else {
    userLastName.error = false;
    userLastName.message = '';
    isValid = isValid && true;
  }

  if (userEmail.data === '') {
    userEmail.error = true;
    userEmail.message = messageRequired;
    isValid = isValid && false;
  } else {
    if (!validationEmail(userEmail.data)) {
      userEmail.error = true;
      userEmail.message = 'invalid email format';
      isValid = isValid && false;
    } else {
      userEmail.error = false;
      userEmail.message = '';
      isValid = isValid && true;
    }
  }

  if (userPhoneNumber.data === '') {
    userPhoneNumber.error = true;
    userPhoneNumber.message = messageRequired;
    isValid = isValid && false;
  } else {
    userPhoneNumber.error = false;
    userPhoneNumber.message = '';
    isValid = isValid && true;
  }

  return { data: copyData, isValid };
};

const validationEmail = email => {
  return email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
};

const validationGeneralInform = (generalInfo, firstDestination, errors) => {
  let copyErrors = copyObject(errors);
  let fill = 'Please fill ';
  let choose = 'Please choose ';
  let isError = false;
  if (generalInfo) {
    if (generalInfo.tourName.length === 0) {
      isError = true;
      copyErrors.tourName.error = true;
      copyErrors.tourName.message = fill + 'tour name';
    } else {
      copyErrors.tourName.error = false;
      copyErrors.tourName.message = fill + 'tour name';
    }

    if (generalInfo.groupCapacity.length === 0) {
      isError = true;
      copyErrors.groupCapacity.error = true;
      copyErrors.groupCapacity.message = choose + 'group capacity';
    } else {
      copyErrors.groupCapacity.error = false;
      copyErrors.groupCapacity.message = choose + 'group capacity';
    }

    if (generalInfo.tourType.length === 0) {
      isError = true;
      copyErrors.tourType.error = true;
      copyErrors.tourType.message = choose + 'tour type';
    } else {
      copyErrors.tourType.error = false;
      copyErrors.tourType.message = choose + 'tour type';
    }

    if (generalInfo.tourCategory.length === 0) {
      isError = true;
      copyErrors.tourCategory.error = true;
      copyErrors.tourCategory.message = choose + 'tour category';
    } else {
      copyErrors.tourCategory.error = false;
      copyErrors.tourCategory.message = choose + 'tour category';
    }
  }
  // if (firstDestination) {
  if (firstDestination.length === 0) {
    isError = true;
    copyErrors.firstDestination.error = true;
    copyErrors.firstDestination.message = fill + 'first destination';
  } else {
    copyErrors.firstDestination.error = false;
    copyErrors.firstDestination.message = fill + 'first destination';
  }
  // } else {
  //   isError = true;
  //   copyErrors.firstDestination.error = true;
  //   copyErrors.firstDestination.message = fill + 'first destination';
  // }
  return { isError, data: copyErrors };
};

const isNull = data => {
  return data ? data.length === 0 : true;
};
const validationLeaderGuests = guestList => {
  let tourLeader = selectTourLeader(guestList);
  let errorFormGuest = false;
  if (tourLeader) {
    errorFormGuest =
      isNull(tourLeader.FirstName) ||
      isNull(tourLeader.LastName) ||
      isNull(tourLeader.IdentityNbr) ||
      isNull(tourLeader.IdentityType) ||
      isNull(tourLeader.GuestTitle) ||
      isNull(tourLeader.CountryId);
  }
  return errorFormGuest;
};
const validationCustom = (
  tourOperator,
  guestList,
  onBehalf,
  additionalServices
) => {
  let messages = [];
  if (!tourOperator) {
    messages.push('Please choose tour operator');
  }
  if (validationLeaderGuests(guestList)) {
    messages.push('Please fill guest');
  }
  if (!onBehalf.customerCode) {
    messages.push('Please choose customer');
  }
  if (additionalServices && additionalServices.find(item => !item.Quantity)) {
    messages.push('Please fill additional service quantity');
  }
  return { isError: messages.length !== 0, messages };
};

const validationFixed = (
  guests,
  splitCommision,
  availablePax,
  guestList,
  onBehalf
) => {
  let messages = [];
  let totalGuest = guests.adults + guests.childs + guests.infants;
  if (guests.adults === 0) {
    messages.push('Please input at least 1 adult');
  }
  if (splitCommision === null) {
    messages.push('Please select commission confirmation first');
  }
  if (totalGuest > availablePax) {
    messages.push(
      'Series package out off quota, the remaining quota is ' +
        (totalGuest - availablePax) +
        ' person'
    );
  }
  if (validationLeaderGuests(guestList)) {
    messages.push('Please fill guest');
  }
  if (!onBehalf.customerCode) {
    messages.push('Please choose customer');
  }
  return { isError: messages.length !== 0, messages };
};
const isValidGeneralInformation = errors => {
  if (errors) {
    const {
      firstDestination,
      groupCapacity,
      tourCategory,
      tourName,
      tourType,
    } = errors;
    return (
      firstDestination.error ||
      groupCapacity.error ||
      tourCategory.error ||
      tourName.error ||
      tourType.error
    );
  } else {
    return true;
  }
};

const validationGeneralInformPackageManagement = (
  generalInfo,
  firstDestination,
  errors
) => {
  let copyErrors = copyObject(errors);
  let fill = 'Please fill ';
  let choose = 'Please choose ';
  let isError = false;
  if (generalInfo) {
    if (generalInfo.tourName.length === 0) {
      isError = true;
      copyErrors.tourName.error = true;
      copyErrors.tourName.message = fill + 'tour name';
    } else {
      copyErrors.tourName.error = false;
      copyErrors.tourName.message = fill + 'tour name';
    }

    if (generalInfo.groupCapacity.length === 0) {
      isError = true;
      copyErrors.groupCapacity.error = true;
      copyErrors.groupCapacity.message = choose + 'group capacity';
    } else {
      copyErrors.groupCapacity.error = false;
      copyErrors.groupCapacity.message = choose + 'group capacity';
    }

    if (generalInfo.tourType.length === 0) {
      isError = true;
      copyErrors.tourType.error = true;
      copyErrors.tourType.message = choose + 'tour type';
    } else {
      copyErrors.tourType.error = false;
      copyErrors.tourType.message = choose + 'tour type';
    }

    if (generalInfo.tourCategory.length === 0) {
      isError = true;
      copyErrors.tourCategory.error = true;
      copyErrors.tourCategory.message = choose + 'tour category';
    } else {
      copyErrors.tourCategory.error = false;
      copyErrors.tourCategory.message = choose + 'tour category';
    }

    if (generalInfo.description.length === 0) {
      isError = true;
      copyErrors.description.error = true;
      copyErrors.description.message = fill + 'description';
    } else {
      copyErrors.description.error = false;
      copyErrors.description.message = fill + 'description';
    }

    if (generalInfo.expiredDate.length === 0) {
      isError = true;
      copyErrors.expiredDate.error = true;
      copyErrors.expiredDate.message = fill + 'expired date';
    } else {
      copyErrors.expiredDate.error = false;
      copyErrors.expiredDate.message = fill + 'expired date';
    }
  }
  // if (firstDestination) {
  if (firstDestination.length === 0) {
    isError = true;
    copyErrors.firstDestination.error = true;
    copyErrors.firstDestination.message = fill + 'first destination';
  } else {
    copyErrors.firstDestination.error = false;
    copyErrors.firstDestination.message = fill + 'first destination';
  }
  // } else {
  //   isError = true;
  //   copyErrors.firstDestination.error = true;
  //   copyErrors.firstDestination.message = fill + 'first destination';
  // }
  return { isError, data: copyErrors };
};

const validationGeneralInformFixedPackageManagement = (
  generalInfo,
  firstDestination,
  errors
) => {
  let copyErrors = copyObject(errors);
  let fill = 'Please fill ';
  let choose = 'Please choose ';
  let isError = false;
  if (generalInfo) {
    if (generalInfo.tourName.length === 0) {
      isError = true;
      copyErrors.tourName.error = true;
      copyErrors.tourName.message = fill + 'tour name';
    } else {
      copyErrors.tourName.error = false;
      copyErrors.tourName.message = fill + 'tour name';
    }

    if (generalInfo.tourType.length === 0) {
      isError = true;
      copyErrors.tourType.error = true;
      copyErrors.tourType.message = choose + 'tour type';
    } else {
      copyErrors.tourType.error = false;
      copyErrors.tourType.message = choose + 'tour type';
    }

    if (generalInfo.tourType.length === 0) {
      isError = true;
      copyErrors.tourType.error = true;
      copyErrors.tourType.message = choose + 'tour type';
    } else {
      copyErrors.tourType.error = false;
      copyErrors.tourType.message = choose + 'tour type';
    }

    if (generalInfo.currency.length === 0) {
      isError = true;
      copyErrors.currency.error = true;
      copyErrors.currency.message = fill + 'currency';
    } else {
      copyErrors.currency.error = false;
      copyErrors.currency.message = fill + 'currency';
    }

    if (generalInfo.description.length === 0) {
      isError = true;
      copyErrors.description.error = true;
      copyErrors.description.message = fill + 'description';
    } else {
      copyErrors.description.error = false;
      copyErrors.description.message = fill + 'description';
    }

    if (generalInfo.expiredDate.length === 0) {
      isError = true;
      copyErrors.expiredDate.error = true;
      copyErrors.expiredDate.message = fill + 'expired date';
    } else {
      copyErrors.expiredDate.error = false;
      copyErrors.expiredDate.message = fill + 'expired date';
    }
    if (generalInfo.startDate.length === 0) {
      isError = true;
      copyErrors.startDate.error = true;
      copyErrors.startDate.message = fill + 'start date';
    } else {
      copyErrors.startDate.error = false;
      copyErrors.startDate.message = fill + 'start date';
    }

    if (generalInfo.endDate.length === 0) {
      isError = true;
      copyErrors.endDate.error = true;
      copyErrors.endDate.message = fill + 'end date';
    } else {
      copyErrors.endDate.error = false;
      copyErrors.endDate.message = fill + 'end date';
    }

    if (generalInfo.maxGuest.length === 0) {
      isError = true;
      copyErrors.maxGuest.error = true;
      copyErrors.maxGuest.message = fill + 'maximum guest';
    } else {
      copyErrors.maxGuest.error = false;
      copyErrors.maxGuest.message = fill + 'maximum guest';
    }

    if (generalInfo.minGuest.length === 0) {
      isError = true;
      copyErrors.minGuest.error = true;
      copyErrors.minGuest.message = fill + 'minimum guest';
    } else {
      copyErrors.minGuest.error = false;
      copyErrors.minGuest.message = fill + 'minimum guest';
    }
    if (generalInfo.graceDate.length === 0) {
      isError = true;
      copyErrors.graceDate.error = true;
      copyErrors.graceDate.message = fill + 'grace date';
    } else {
      copyErrors.graceDate.error = false;
      copyErrors.graceDate.message = fill + 'grace date';
    }
    if (firstDestination.length === 0) {
      isError = true;
      copyErrors.destination.error = true;
      copyErrors.destination.message = choose + 'first destination';
    } else {
      copyErrors.destination.error = false;
      copyErrors.destination.message = choose + 'first destination';
    }
  }

  return { isError, data: copyErrors };
};
// validasi pax guest
// validasi commission
// validasi sisa package
// validasi guest list
export {
  validationFillGuest,
  selectTourLeader,
  isGuestError,
  validationTourPax,
  isErrorFormPackage,
  isRoomAllocationValid,
  isQuotaValid,
  isCommissionValid,
  isRoomValid,
  isGuestsValid,
  validationFillAllGuests,
  isAllGuestsError,
  validationTotalGuests,
  isGroupCapacityValid,
  validationShortRegisCompany,
  isShortCompanyFormValid,
  validationAllocationPax,
  validationGeneralInform,
  validationCustom,
  validationFixed,
  isValidGeneralInformation,
  validationGeneralInformPackageManagement,
  validationGeneralInformFixedPackageManagement,
  validationMinPax,
};
