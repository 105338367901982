import React from 'react';
import { Segment, Grid, Image } from 'semantic-ui-react';
import PropTypes from 'prop-types';
const panelRatioImage = props => {
  return (
    <Segment>
      <Grid column={3}>
        <Grid.Row width={16} textAlign="center">
          <Image
            src={
              props.image
                ? props.image.ImageUrl
                  ? props.image.ImageUrl
                  : props.image.ImagePreview
                : ''
            }
          />
        </Grid.Row>
      </Grid>
    </Segment>
  );
};
panelRatioImage.propTypes = {
  image: PropTypes.object,
};
export default panelRatioImage;
