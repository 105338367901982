import {
  GET_HISTORY_TRANSACTION_BY_CODE,
  GET_SUPERVISOR_BY_COMPANY_CODE_POSITION_ID,
  // GET_CUSTOMER_LEVEL_BY_COMPANY_CODE,
  RESET_STATUS_CUSTOMER_PROFILE_PAGE,
} from './constant/actionTypes';
import {
  getHistoryTransactionByCodeApi,
  getHistoryTransactionForSalesByCodeApi,
} from '../../api/customerProfile/getHistoryTransactionByCodeApi';
import getSupervisorByCompanyCodePositionIdApi from '../../api/customerProfile/getSupervisorByCompanyCodePositionIdApi';
//import getCustomerLevelListApi from '../../api/customerProfile/getCustomerLevelListApi';

const getHistoryTransactionByCodeAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_HISTORY_TRANSACTION_BY_CODE,
      payload: getHistoryTransactionByCodeApi(Id),
    });
  };
};

const getHistoryTransactionForSalesByCodeAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_HISTORY_TRANSACTION_BY_CODE,
      payload: getHistoryTransactionForSalesByCodeApi(Id),
    });
  };
};

const getSupervisorByCompanyCodePositionIdAction = (Code, PositionId) => {
  return dispatch => {
    return dispatch({
      type: GET_SUPERVISOR_BY_COMPANY_CODE_POSITION_ID,
      payload: getSupervisorByCompanyCodePositionIdApi(Code, PositionId),
    });
  };
};

// const getCustomerLevelListAction = () => {
//   return dispatch => {
//     return dispatch({
//       type: GET_CUSTOMER_LEVEL_BY_COMPANY_CODE,
//       payload: getCustomerLevelListApi(),
//     });
//   };
// };

const resetCustomerProfilePageAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_STATUS_CUSTOMER_PROFILE_PAGE,
    });
  };
};

export {
  getHistoryTransactionByCodeAction,
  resetCustomerProfilePageAction,
  getSupervisorByCompanyCodePositionIdAction,
  //getCustomerLevelListAction,
  getHistoryTransactionForSalesByCodeAction,
};
