export const Styles = {
  Segment: { marginTop: '30px' },
  SaveBackButton: {
    marginLeft: '66%',
    marginTop: '2em',
  },
  LabelStyle: {
    display: 'block',
    fontWeight: 'bold',
    //marginLeft: '20px ',
  },
  InputStyle: {
    minHeight: '100',
    width: '220px',
    //marginLeft: '20px ',
  },
};
