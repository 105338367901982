import React from 'react';
import PropTypes from 'prop-types';
import { Header, Button, Popup, Grid } from 'semantic-ui-react';
import styles from './styles';

const PopupTourGuide = props => (
  <Popup trigger={<Button color="blue">See Detail</Button>} hoverable>
    <Header>Tour Guide</Header>
    <Grid columns={2} style={styles.widthPopup}>
      {typeof props.dailyData.TourGuides !== 'undefined' ? (
        props.dailyData.TourGuides.length > 0 ? (
          props.dailyData.TourGuides.map((guide, i) => (
            <Grid.Row key={i} style={styles.paddingPopup}>
              <Grid.Column>{`${guide.Qty} Person for ${
                guide.CityName
              }`}</Grid.Column>
              <Grid.Column>{`${guide.Language.Text} Speaker`}</Grid.Column>
            </Grid.Row>
          ))
        ) : (
          <Grid.Row style={styles.paddingPopup}>
            <Grid.Column>No Tour Guide Selected</Grid.Column>
          </Grid.Row>
        )
      ) : (
        <Grid.Row style={styles.paddingPopup}>
          <Grid.Column>No Tour Guide Selected</Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  </Popup>
);

PopupTourGuide.propTypes = {
  dailyData: PropTypes.array,
};

export default PopupTourGuide;
