import React from 'react';
import { Grid, Segment, Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ListUploadMasterDataReference from './SegmentMasterDataReference';
import { ListDropdownReference } from './ReferenceOptionInDropdown';
import { CancellationReasonTypes } from './CancellationReasonType';
// import { Style } from '../../assets/styles/styleMasterReference';
import ButtonWithIcon from '../../../components/button/buttonWithIcon';
import InputWithLabel from '../../../components/input/inputWithLabel';
import HeaderTitle from '../../../components/common/headerTitle';
const UploadMasterDataReference = props => (
  <Grid style={{ marginTop: '30px' }}>
    <Grid.Row>
      <Grid.Column width={16}>
        <Segment>
          <HeaderTitle title="Master Data Reference" />
          <Grid>
            <Grid.Row columns="equal" style={{ paddingBottom: 0 }}>
              <Grid.Column>
                <Form>
                  <InputWithLabel
                    inline={false}
                    type="select"
                    label="Choose Menus"
                    name="ChooseMenus"
                    options={ListDropdownReference}
                    handleChange={props.handleChange}
                    value={props.data}
                    selectOnBlur={false}
                  />
                </Form>
              </Grid.Column>
              <Grid.Column>
                {props.showCancellationReason ? (
                  <Form>
                    <InputWithLabel
                      inline={false}
                      type="select"
                      label="Cancellation reason for"
                      name="CancellationReasonType"
                      options={CancellationReasonTypes}
                      handleChange={props.handleCancellationReasonType}
                      value={props.cancellationReasonType}
                      selectOnBlur={false}
                    />
                  </Form>
                ) : null}
              </Grid.Column>
              <Grid.Column>
                <ButtonWithIcon
                  text="Save"
                  floated="right"
                  iconName="save"
                  disabled={props.disabledUpload || !props.triggerTabelCell}
                  primary
                  handleOnClick={props.saveMasterReference}
                />
                <ButtonWithIcon
                  text="Add"
                  floated="right"
                  iconName="add"
                  positive
                  disabled={!props.triggerTabelCell}
                  handleOnClick={props.addMasterReference}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="equal">
              <Grid.Column>
                <ListUploadMasterDataReference
                  listData={props.listData}
                  addMasterReference={props.addMasterReference}
                  handleChangeMasterData={props.handleChangeMasterData}
                  deleteDataReference={props.deleteDataReference}
                  headerTableMasterData={props.headerTableMasterData}
                  triggerTabelCell={props.triggerTabelCell}
                  getMasterReferenceStatus={props.getMasterReferenceStatus}
                  handleChangeSearch={props.handleChangeSearch}
                  searchInput={props.searchInput}
                  activePage={props.activePage}
                  pageChange={props.pageChange}
                  maxLength={props.maxLength}
                  listCountry={props.listCountry}
                  loadingCountry={props.loadingCountry}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Grid.Column>
    </Grid.Row>
    {/* <Grid.Row>
        <Grid.Column width={9} />
      </Grid.Row> */}
  </Grid>
);

UploadMasterDataReference.propTypes = {
  handleChange: PropTypes.func,
  handleCancellationReasonType: PropTypes.func,
  disabledSave: PropTypes.bool,
  disabledUpload: PropTypes.bool,
  saveUpload: PropTypes.func,
  submit: PropTypes.func,
  listData: PropTypes.array,
  data: PropTypes.string,
  addMasterReference: PropTypes.func,
  handleChangeMasterData: PropTypes.func,
  deleteDataReference: PropTypes.func,
  saveMasterReference: PropTypes.func,
  headerTableMasterData: PropTypes.array,
  triggerTabelCell: PropTypes.number,
  getMasterReferenceStatus: PropTypes.string,
  handleChangeSearch: PropTypes.func,
  searchInput: PropTypes.string,
  activePage: PropTypes.number,
  pageChange: PropTypes.func,
  maxLength: PropTypes.string,
  showCancellationReason: PropTypes.bool,
  cancellationReasonType: PropTypes.string,
  listCountry: PropTypes.array,
  loadingCountry: PropTypes.bool,
};

export default UploadMasterDataReference;
