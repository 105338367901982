import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Segment,
  Input,
  // Button,
  // Container,
} from 'semantic-ui-react';
import HeaderTitle from '../../shared/components/common/headerSegment/HeaderTitle';
import TableCustomerPayment from './table/TableCustomerPayment';
import styles from '../styles';
//import { getCustomerPaymentApi } from '../../../api/getCustomerPaymentApi';
//import useApiCall from '../hooks/useApiCallCustomerPayment';
import PaginationScript from '../../../components/genericFunction/pagination';
import { searchFilterCustomerPayment } from '../../../scripts/filterData';
import { getNumberFromTo } from '../../../components/genericFunction/getPaginationNumberFrom';
//import ModalDateRangeCheckIn from '../../shared/components/modal/ModalDateRangeCheckIn';
// import moment from 'moment';

const CustomerPayment = props => {
  //=======================initial state=======================
  const [searchInput, setSearchInput] = useState('');
  const [activePage, setActivePage] = useState(1);
  // const [loading, setLoading] = useState(false);
  //   const [openDateRange, setOpenDateRange] = useState(false);

  const {
    loading,
    data,
    title,
    // handleDateRange,
    // loadData,
    // startDate,
    // endDate,
  } = props;

  //=======================helper=======================
  //   const actionDateRange = id => {
  //     let from = moment(id.from).format('YYYY-MM-DD');
  //     let to = moment(id.to).format('YYYY-MM-DD');
  //     handleClose();
  //     handleDateRange(from, to);
  //   };

  const filteredData = (data, searchInput) => {
    return searchFilterCustomerPayment(data, searchInput);
  };

  const pageChange = (e, { activePage }) => {
    setActivePage(activePage);
  };

  //=======================handler=======================
  const handleChangeSearch = e => {
    setSearchInput(e.target.value);
    setActivePage(1);
  };

  //   const handleClose = () => {
  //     setOpenDateRange(false);
  //   };

  //   const handleOpenDateRange = () => {
  //     setOpenDateRange(true);
  //   };

  //=======================pagination data=======================
  let newListData =
    data && title === 'Upcoming Customer Payment Due'
      ? data
        ? data.UpcomingDue
        : []
      : data
      ? data.OverDue
      : [];
  let filterData = filteredData(
    newListData !== null ? newListData : [],
    searchInput
  );
  let pagedData = PaginationScript(filterData, 5);
  let listData = pagedData[activePage - 1];
  let { numbersFrom, numbersTo } = getNumberFromTo(listData, activePage, 5);
  //   let checkInUntilDate = !endDate ? '' : moment(endDate).format('DD MMM YYYY');
  //   let checkInStartDate = !startDate ? '' : moment(startDate).format('DD MMM');
  return (
    <Grid columns="equal" style={styles.styleGrid}>
      <Grid.Row stretched>
        <Grid.Column width={15}>
          <Segment loading={loading}>
            {/* || loading */}
            <HeaderTitle title={title} />
            <Input
              icon="search"
              placeholder="Search"
              name="searchInput"
              value={searchInput}
              onChange={handleChangeSearch}
            />
            {/* <Button
              icon="filter"
              basic
              size="small"
              floated="right"
              positive
              onClick={handleOpenDateRange}
            /> */}
            {/* <div
              style={{
                marginTop: '-2.9em',
                marginRight: '5em',
                fontWeight: 'bold',
              }}
            >
              //{(filterByDate.tourStartDate !== '' ||
              //      filterByDate.tourUntilDate !== '') && (
              <Container
                content={
                  'Check-in range date : ' +
                  checkInStartDate +
                  ' - ' +
                  checkInUntilDate
                }
                textAlign="right"
              />
            </div> */}
            <Grid.Row style={styles.styleRowTable}>
              <TableCustomerPayment
                activePage={activePage}
                data={newListData}
                pagedData={pagedData}
                listData={listData}
                numbersFrom={numbersFrom}
                numbersTo={numbersTo}
                pageChange={pageChange}
                //handleClose={handleClose}
              />
            </Grid.Row>
          </Segment>
        </Grid.Column>
      </Grid.Row>
      {/* <ModalDateRangeCheckIn
        contentHeader="Customer Payment"
        openModal={openDateRange}
        close={handleClose}
        actionDateRange={actionDateRange}
        startDate={startDate}
        endDate={endDate}
      /> */}
    </Grid>
  );
};

CustomerPayment.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  loading: PropTypes.bool,
};

export default React.memo(CustomerPayment);
