import React, { Component } from 'react';
import { Table, Button, Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import { StyleButtonLikeLink } from '../../../../components/common/styleCommon';
import ButtonGroup from '../../../../components/common/buttonActionTourOperator';
import Pagination from '../../../../components/common/pagination';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../../scripts/pagination';
import FilterData from '../../../../scripts/filterDataTable';
// const tableListPartnerProfile = props => (
class TableListPartnerProfile extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
    };
  }
  static propTypes = {
    data: PropTypes.array,
  };

  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });

  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  render() {
    const props = this.props;
    let filterData = FilterData(
      this.props.listData,
      'Name',
      this.state.searchInput
    );
    let pagedData = datasetPagination(filterData, 3);
    let listData = pagedData[this.state.activePage - 1];

    let { numbersFrom, numbersTo } = getNumberFromTo(
      listData,
      this.state.activePage,
      3
    );
    return (
      <div>
        <Input
          icon="search"
          placeholder="search"
          name="searchInput"
          value={this.state.searchInput}
          onChange={this.handleChange}
        />
        <Button
          content="Add Tour Operator"
          positive
          floated="right"
          onClick={props.addNewTourOperator}
          value={0}
          disabled={props.disable}
        />
        <Table basic="very" selectable>
          <Table.Header>
            <Table.Row>
              {props.headerTable.map((headTableContent, index) => {
                return (
                  <Table.HeaderCell key={index}>
                    {headTableContent}
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {listData &&
              listData.map((customerProfile, idx) => {
                return (
                  <Table.Row key={idx}>
                    <Table.Cell>
                      <Button
                        onClick={props.handleClickDetail}
                        value={customerProfile.Id}
                        style={StyleButtonLikeLink}
                      >
                        {customerProfile.Name}
                      </Button>
                    </Table.Cell>
                    <Table.Cell>
                      {customerProfile.Telephone
                        ? customerProfile.Telephone
                        : '-'}
                    </Table.Cell>
                    <Table.Cell>
                      {customerProfile.Email ? customerProfile.Email : '-'}
                    </Table.Cell>
                    <Table.Cell width={1}>
                      {customerProfile.CityName
                        ? customerProfile.CityName
                        : '-'}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {customerProfile.Address ? customerProfile.Address : '-'}
                    </Table.Cell>
                    <Table.Cell>
                      <ButtonGroup
                        buttonMargin={props.buttonSetMargin}
                        butttonEdit={props.buttonEdit}
                        buttonDelete={props.buttonDelete}
                        contentRightEnd="Set Margin"
                        contentRight="Edit"
                        contentLeft="Delete"
                        value={customerProfile.Id}
                        name={customerProfile.Name}
                        disable={props.disable}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        <Pagination
          // totalPages={pagedData.length}
          // onPageChange={this.pageChange}
          // activePage={this.state.activePage}
          pagedData={pagedData}
          numbersTo={numbersTo}
          numbersFrom={numbersFrom}
          data={props.listData}
          pageChange={this.pageChange}
          activePage={this.state.activePage}
        />
      </div>
    );
  }
}

TableListPartnerProfile.propTypes = {
  data: PropTypes.array,
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  actionButton: PropTypes.func,
  handleClickDetail: PropTypes.func,
  buttonSetMargin: PropTypes.func,
  buttonEdit: PropTypes.func,
  buttonDelete: PropTypes.func,
  searchInput: PropTypes.string,
  addNewTourOperator: PropTypes.func,
  disable: PropTypes.bool,
  isFinance: PropTypes.bool,
};
export default TableListPartnerProfile;
