import React from 'react';
import { Dimmer, Tab } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import EmployeeCompanyProfile from './table/EmployeeCompanyProfileTable';
import ModalResetPassword from '../../../shared/components/modal/user/ModalResetPassword';
import { connect } from 'react-redux';
import {
  postUnlockUserAction,
  resetUserAccessAction,
  getUserProfileByUserIdAction,
  postResetPasswordAction,
} from '../../../../actions/userAccessAction';
import { Redirect } from 'react-router-dom';
import ModalAlert from '../../../shared/components/modal/ModalAlert';
import ModalConfirm from '../../../../components/modal/modalConfirmationTwoButton';
import handleValidation from '../services/validation/validationResetPassword';
import _ from 'lodash';
import { resetPasswordField } from '../constant/fieldToValidate';
class TabCompanyProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabMenu: [
        {
          menuItem: 'Employee',
          render: () => (
            <Tab.Pane
              attached={false}
              loading={this.props.loading}
              content={
                <EmployeeCompanyProfile
                  listData={this.props.data}
                  unlockUser={this.unlockConfrimation}
                  buttonModalEmployee={this.buttonModalEmployee}
                  resetPassword={this.resetPassword}
                  editUser={this.editUser}
                  loading={this.props.loading}
                  isNewForm={this.state.isNewForm}
                  disabled={this.props.disabled}
                />
              }
            />
          ),
        },
      ],
      openModalResetPassword: false,
      typePassword: 'password',
      passwordIcon: 'eye slash',
      typeConfirmPassword: 'password',
      confirmPasswordIcon: 'eye slash',
      resetPasswordObject: {
        NewPassword: '',
        ConfirmPassword: '',
      },
      errors: {},
      isNewForm: '',
      gotoLink: '',
      changePage: false,
    };
  }

  static propTypes = {
    postResetPasswordStatus: PropTypes.string,
    resetUserAccessAction: PropTypes.func,
    getUserProfileByUserIdAction: PropTypes.func,
    postUnlockUserAction: PropTypes.func,
    postResetPasswordAction: PropTypes.func,
    loading: PropTypes.bool,
    data: PropTypes.array,
    companyId: PropTypes.string,
    getUserProfileByUserIdStatus: PropTypes.string,
    getUserProfileByUserId: PropTypes.object,
    disabled: PropTypes.bool,
  };

  handleCloseResetPassword = () =>
    this.setState({
      openModalResetPassword: false,
    });

  componentDidUpdate() {
    if (this.props.postResetPasswordStatus === 'success') {
      this.setState({
        headerAlert: ' Success',
        contentAlert: 'New Password successfully ',
        modalConfirmation: true,
        openModalResetPassword: false,
      });
      this.props.resetUserAccessAction();
    } else if (this.props.postResetPasswordStatus === 'failed') {
      this.setState({
        headerAlert: ' Failed',
        contentAlert: ' Failed Change Password',
        modalConfirmation: true,
      });
      this.props.resetUserAccessAction();
    }
    if (this.props.getUserProfileByUserIdStatus === 'success') {
      this.setState({
        resetPasswordObject: {
          ...this.state.resetPasswordObject,
          UserId: this.props.getUserProfileByUserId.UserId,
          Username: this.props.getUserProfileByUserId.UserName,
          CompanyCode: this.props.companyId,
        },
        loadingUserProfile: false,
      });
      this.props.resetUserAccessAction();
    } else if (this.props.getUserProfileByUserIdStatus === 'failed') {
      this.setState({
        headerAlert: ' Failed',
        contentAlert: ' The user is Failed Loaded',
        modalConfirmation: true,
      });
      this.props.resetUserAccessAction();
    }
  }

  resetPassword = (e, { id }) => {
    this.props.getUserProfileByUserIdAction(id);
    this.setState({
      resetPasswordObject: {
        ...this.state.resetPasswordObject,
      },
      openModalResetPassword: true,
    });
  };
  unlockConfrimation = (e, { id }) => {
    this.handleConfirm('Are you sure to unlock this user ?  ');
    this.setState({ idToUnlockUser: id });
  };
  unlockUser = () => {
    const { idToUnlockUser } = this.state;
    this.props
      .postUnlockUserAction(idToUnlockUser)
      .then(res => {
        this.handleCloseModalConfirm();
        this.handleAlert('Success', res.value.data);
      })
      .catch(err => {
        this.handleAlert('Failed', err.response.data.Message);
      });
  };
  handleChangeResetPassword = (e, { value, name }) => {
    const resetPasswordObject = this.state.resetPasswordObject;
    resetPasswordObject[name] = value;
    this.setState({ resetPasswordObject: resetPasswordObject });
    this.validate();
  };
  saveResetPassword = () => {
    const err = this.validate();
    let data = this.state.resetPasswordObject;
    if (!err) {
      this.props.postResetPasswordAction(data);
    }
  };

  onClickPassIcon = (e, { name }) => {
    let icon = `${name}Icon`;
    let show = `${name}Show`;
    if (this.state[show]) {
      this.setState({
        [icon]: 'eye slash',
        [show]: false,
        typePassword: 'password',
      });
    } else {
      this.setState({
        [icon]: 'eye',
        [show]: true,
        typePassword: 'text',
      });
    }
  };
  onClickConfirmPassIcon = (e, { name }) => {
    let icon = `${name}Icon`;
    let show = `${name}Show`;
    if (this.state[show]) {
      this.setState({
        [icon]: 'eye slash',
        [show]: false,
        typeConfirmPassword: 'password',
      });
    } else {
      this.setState({
        [icon]: 'eye',
        [show]: true,
        typeConfirmPassword: 'text',
      });
    }
  };

  buttonModalEmployee = () => {
    let link = `/Employee/create/${this.props.companyId}/0`;
    this.setState({ changePage: true, gotoLink: link });
  };
  editUser = (e, { id }) => {
    let link = `/Employee/Edit/${this.props.companyId}/${id}`;
    this.setState({ changePage: true, gotoLink: link });
  };

  handleCloseModalConfirm = () => {
    this.setState({ openModalConfirm: false });
  };

  handleAlert = (headerAlert, contentAlert) => {
    this.setState({
      openModalAlert: true,
      headerAlert: headerAlert,
      contentAlert: contentAlert,
    });
  };
  handleConfirm = message => {
    this.setState({
      openModalConfirm: true,
      message: message,
    });
  };

  handleCloseAlert = () => {
    this.setState({ openModalAlert: false });
  };

  validate = () => {
    let isError = false;
    const { resetPasswordObject } = this.state;
    let error1 = handleValidation(resetPasswordObject, resetPasswordField);
    let errors = { ...error1 };
    isError = !_.isEmpty(errors);
    this.setState({
      errors: {
        ...errors,
      },
    });
    return isError;
  };
  render() {
    const {
      tabMenu,
      passwordIcon,
      typePassword,
      confirmPasswordIcon,
      typeConfirmPassword,
      resetPasswordObject,
      errors,
      openModalResetPassword,
      changePage,
      gotoLink,
      openModalAlert,
      headerAlert,
      contentAlert,
      message,
      openModalConfirm,
    } = this.state;
    const { loading } = this.props;
    if (changePage) {
      return <Redirect to={gotoLink} />;
    }
    return (
      <React.Fragment>
        <Dimmer.Dimmable blurring dimmed={false} />
        <Tab menu={{ secondary: true, pointing: true }} panes={tabMenu} />
        <ModalResetPassword
          openModal={openModalResetPassword}
          header="Reset Password"
          handleClose={this.handleCloseResetPassword}
          nameButton="Oke"
          contentDetail={resetPasswordObject}
          CompanyCode={this.props.companyId}
          handleChange={this.handleChangeResetPassword}
          saveResetPassword={this.saveResetPassword}
          errors={errors}
          loading={loading}
          onClickPassIcon={this.onClickPassIcon}
          onClickConfirmPassIcon={this.onClickConfirmPassIcon}
          passwordIcon={passwordIcon}
          typePassword={typePassword}
          confirmPasswordIcon={confirmPasswordIcon}
          typeConfirmPassword={typeConfirmPassword}
        />
        <ModalAlert
          openModal={openModalAlert}
          handleClose={this.handleCloseAlert}
          header={headerAlert}
          content={contentAlert}
          nameButton="OK"
        />
        <ModalConfirm
          openModal={openModalConfirm}
          size="mini"
          close={this.handleCloseModalConfirm}
          confirm={this.unlockUser}
          message={message}
          header="Confirmation"
        />
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    postResetPasswordStatus: state.UserAccessStore.postResetPasswordStatus,
    postResetPassword: state.UserAccessStore.postResetPassword,
    loading: state.UserAccessStore.loading,
    getUserProfileByUserIdStatus:
      state.UserAccessStore.getUserProfileByUserIdStatus,
    getUserProfileByUserId: state.UserAccessStore.getUserProfileByUserId,
  };
}
export default connect(
  mapStateToProps,
  {
    postUnlockUserAction,
    resetUserAccessAction,
    getUserProfileByUserIdAction,
    postResetPasswordAction,
  }
)(TabCompanyProfile);
