import { POST_ACCEPT_QUOTATION } from './constant/actionTypes';
import postAcceptQuotationApi from '../../../api/tourTransaction/postAcceptQuotationApi';
export const postAcceptQuotationAction = id => {
  return dispatch => {
    return dispatch({
      type: POST_ACCEPT_QUOTATION,
      payload: postAcceptQuotationApi(id),
    });
  };
};
