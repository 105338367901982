import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Segment, Header } from 'semantic-ui-react';
import styles from './styles';

const square = { width: 120, height: 120 };

const RightPanel = props => {
  const { report } = props;
  return (
    <>
      <Grid.Column>
        <Grid>
          {/* first line */}
          <Grid.Row columns="4">
            <Grid.Column width="2" />
            <Grid.Column width="6">
              <Grid textAlign="center">
                <Grid.Row style={styles.paddingBottom5}>
                  <Grid.Column style={styles.paddingLeftSegmentCircle}>
                    <Segment circular style={square} inverted color="grey">
                      <Header as="h2" inverted>
                        {report ? report.TotalOnHold : '0'}
                        <Header.Subheader>Booking</Header.Subheader>
                      </Header>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={styles.paddingTop0}>
                  <Grid.Column>
                    <h3>On Hold</h3>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>

            <Grid.Column width="6">
              <Grid textAlign="center">
                <Grid.Row style={styles.paddingBottom5}>
                  <Grid.Column style={styles.paddingLeftSegmentCircle}>
                    <Segment circular style={square} inverted color="teal">
                      <Header as="h2" inverted>
                        {report ? report.TotalCreated : '0'}
                        <Header.Subheader>Booking</Header.Subheader>
                      </Header>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={styles.paddingTop0}>
                  <Grid.Column>
                    <h3>Created</h3>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column width="2" />
          </Grid.Row>
          {/* second line */}
          <Grid.Row columns="4">
            <Grid.Column width="2" />
            <Grid.Column width="6">
              <Grid textAlign="center">
                <Grid.Row style={styles.paddingBottom5}>
                  <Grid.Column style={styles.paddingLeftSegmentCircle}>
                    <Segment circular style={square} inverted color="yellow">
                      <Header as="h2" inverted>
                        {report ? report.TotalInPayment : '0'}
                        <Header.Subheader>Booking</Header.Subheader>
                      </Header>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={styles.paddingTop0}>
                  <Grid.Column>
                    <h3>In-Payment</h3>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column width="6">
              <Grid textAlign="center">
                <Grid.Row style={styles.paddingBottom5}>
                  <Grid.Column style={styles.paddingLeftSegmentCircle}>
                    <Segment circular style={square} inverted color="orange">
                      <Header as="h2" inverted>
                        {report ? report.TotalConfirmed : '0'}
                        <Header.Subheader>Booking</Header.Subheader>
                      </Header>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={styles.paddingTop0}>
                  <Grid.Column>
                    <h3>Confirmed</h3>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>

            <Grid.Column width="2" />
          </Grid.Row>
          {/* third line */}
          <Grid.Row columns="4">
            <Grid.Column width="2" />

            <Grid.Column width="6">
              <Grid textAlign="center">
                <Grid.Row style={styles.paddingBottom5}>
                  <Grid.Column style={styles.paddingLeftSegmentCircle}>
                    <Segment circular style={square} inverted color="blue">
                      <Header as="h2" inverted>
                        {report ? report.TotalCompleted : '0'}
                        <Header.Subheader>Booking</Header.Subheader>
                      </Header>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={styles.paddingTop0}>
                  <Grid.Column>
                    <h3>Completed</h3>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>

            <Grid.Column width="6">
              <Grid textAlign="center">
                <Grid.Row style={styles.paddingBottom5}>
                  <Grid.Column style={styles.paddingLeftSegmentCircle}>
                    <Segment circular style={square} inverted color="pink">
                      <Header as="h2" inverted>
                        {report ? report.TotalCancelled : '0'}
                        <Header.Subheader>Booking</Header.Subheader>
                      </Header>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={styles.paddingTop0}>
                  <Grid.Column>
                    <h3>Cancelled</h3>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>

            <Grid.Column width="2" />
          </Grid.Row>{' '}
          <Grid.Row columns="4">
            <Grid.Column width="2" />

            <Grid.Column width="6">
              <Grid textAlign="center">
                <Grid.Row style={styles.paddingBottom5}>
                  <Grid.Column style={styles.paddingLeftSegmentCircle}>
                    <Segment circular style={square} inverted color="purple">
                      <Header as="h2" inverted>
                        {report ? report.TotalQuotation : '0'}
                        <Header.Subheader>Booking</Header.Subheader>
                      </Header>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={styles.paddingTop0}>
                  <Grid.Column>
                    <h3>Quotation</h3>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
    </>
  );
};

RightPanel.propTypes = {
  report: PropTypes.object,
};

export default RightPanel;
