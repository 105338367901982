import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
// import InputWithLabel from '../../../shared/components/common/input/InputWithLabel';
import LeftSummary from './LeftSummary';
import TableBestCustomer from './TableBestCustomer';

const LeftPanel = props => {
  const { report } = props;
  return (
    <>
      <Grid.Column>
        <Grid>
          {/* <Grid.Row columns="2">
            <Grid.Column>
              <Form>
                <Form.Field>
                  <InputWithLabel
                    label="Periode Date"
                    type="text"
                    inline={false}
                    fluid={false}
                    //   value={rangeDate(startDate, endDate)}
                    name="date"
                    // handleClick={this.handleModalCalendar}
                    //   options={listAccommodation}
                  />
                </Form.Field>
              </Form>
            </Grid.Column>
          </Grid.Row> */}

          {/* leftSummary */}
          <LeftSummary report={report} />

          {/* Table Summary */}
          <TableBestCustomer report={report} />
        </Grid>
      </Grid.Column>
    </>
  );
};

LeftPanel.propTypes = {
  report: PropTypes.object,
};

export default LeftPanel;
