import * as types from '../actions/actionTypes';

const initialState = {
  loading: false,
  getExchangeRateList: [],
  getExchangeRateListStatus: '',
  errors: {},
  postExchangeRateStatus: '',
  putExchangeRateStatus: '',
  deleteExchangeRateStatus: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_EXCHANGE_RATE_LIST:
      return { ...state };
    case types.GET_EXCHANGE_RATE_LIST_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loading: false,
        getExchangeRateListStatus: 'failed',
      };
    case types.GET_EXCHANGE_RATE_LIST_PENDING:
      return { ...state, loading: true };
    case types.GET_EXCHANGE_RATE_LIST_FULFILLED:
      return {
        ...state,
        getExchangeRateList: action.payload.data,
        loading: false,
        getExchangeRateListStatus: 'success',
      };
    case types.RESET_STATUS_EXCHANGE_RATE:
      return {
        ...state,
        getExchangeRateListStatus: '',
        postExchangeRateStatus: '',
        putExchangeRateStatus: '',
      };
    case types.POST_EXCHANGE_RATE:
      return { ...state };
    case types.POST_EXCHANGE_RATE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loading: false,
        postExchangeRateStatus: 'failed',
      };
    case types.POST_EXCHANGE_RATE_PENDING:
      return { ...state, loading: true };
    case types.POST_EXCHANGE_RATE_FULFILLED:
      return {
        ...state,
        getExchangeRateList: action.payload.data,
        loading: false,
        postExchangeRateStatus: 'success',
      };
    case types.PUT_EXCHANGE_RATE:
      return { ...state };
    case types.PUT_EXCHANGE_RATE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loading: false,
        putExchangeRateStatus: 'failed',
      };
    case types.PUT_EXCHANGE_RATE_PENDING:
      return { ...state, loading: true };
    case types.PUT_EXCHANGE_RATE_FULFILLED:
      return {
        ...state,
        getExchangeRateList: action.payload.data,
        loading: false,
        putExchangeRateStatus: 'success',
      };

    case types.DELETE_EXCHANGE_RATE:
      return { ...state };
    case types.DELETE_EXCHANGE_RATE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loading: false,
        deleteExchangeRateStatus: 'failed',
      };
    case types.DELETE_EXCHANGE_RATE_PENDING:
      return { ...state, loading: true };
    case types.DELETE_EXCHANGE_RATE_FULFILLED:
      return {
        ...state,
        loading: false,
        deleteExchangeRateStatus: 'success',
      };
    default:
      return state;
  }
};

export default reducer;
