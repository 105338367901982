import React from 'react';
import PropTypes from 'prop-types';
import ExcursionList from '../excursion/excursionList';
import CustomerProfileList from '../../pages/customer/customerList/components/ListCustomerProfile';
import TransportationList from '../../pages/transportation/transportationList/components/TransportationList';
// import LockedUserList from '../userAccess/lockedUserList';
// import UserAccessList from '../userAccess/userAccessList';
// import ExchangeRateList from '../exchangeRate/exchangeRateList';
// import SystemMarginList from '../systemMargin/systemMarginList';
// import AccomodationItemList from '../accomodation/accomodationProfile/accomodationItemList';
// import AccomodationItemList from '../../pages/accommodationProfile/components';
//import RestaurantList from '../restaurant/restaurantList';
// import AreaList from '../area/areaList';
import AreaList from '../../pages/area/components/AreaListComponent';
import CityList from '../../pages/city/components/CityListComponent';
import RegionList from '../../pages/region/components/RegionList';
import PlaceList from '../../pages/place/components/PlaceList';
import CountryList from '../../pages/country/components/CountryListComponent';
import CityGroupList from '../../pages/cityGroup/components/CityGroupListComponent';
import ReadyPackage from '../../pages/readyPackage/readyPackageList/components/ReadyPackageList';
import PaymentMethod from '../../pages/paymentMethod/components/PaymentMethodList';
// import UserRoleList from '../userRole/userRoleForm';
import NotAllowedCountry from '../../pages/notAllowedForCompany/components/NotAllowedCountryList';
import PaymentScheme from '../../pages/paymentScheme/components/PaymentSchemeList';

export default class mainPageList extends React.Component {
  render() {
    return this.props.type === 'excursion' ? (
      <ExcursionList
        listData={this.props.listData}
        pagedData={this.props.pagedData}
        title={'Excursion List'}
        pageChange={this.props.pageChange}
        activePage={this.props.activePage}
        searchInput={this.props.searchInput}
        handleChange={this.props.handleChange}
        butttonEdit={this.props.butttonEdit}
        data={this.props.data}
        butttonExport={this.props.butttonExport}
        buttonUploadData={this.props.buttonUploadData}
        buttonDownloadTemplate={this.props.buttonDownloadTemplate}
        buttonNew={this.props.buttonNew}
        buttonDelete={this.props.buttonDelete}
        numbersFrom={this.props.numbersFrom}
        numbersTo={this.props.numbersTo}
      />
    ) : // this.props.type === 'accomodation' ? (
    // <AccomodationList
    //   listData={this.props.listData}
    //   pagedData={this.props.pagedData}
    //   title={'Accommodation List'}
    //   pageChange={this.props.pageChange}
    //   activePage={this.props.activePage}
    //   searchInput={this.props.searchInput}
    //   handleChange={this.props.handleChange}
    //   data={this.props.data}
    //   buttonNew={this.props.buttonNew}
    //   butttonExport={this.props.butttonExport}
    //   buttonUploadData={this.props.buttonUploadData}
    //   buttonDownloadTemplate={this.props.buttonDownloadTemplate}
    //   butttonEdit={this.props.butttonEdit}
    //   buttonDelete={this.props.buttonDelete}
    //   numbersFrom={this.props.numbersFrom}
    //   numbersTo={this.props.numbersTo}
    // />
    this.props.type === 'customerProfile' ? (
      <CustomerProfileList
        listData={this.props.listData}
        pagedData={this.props.pagedData}
        title={'Customer Profile'}
        pageChange={this.props.pageChange}
        activePage={this.props.activePage}
        searchInput={this.props.searchInput}
        handleChange={this.props.handleChange}
        data={this.props.data}
        buttonNew={this.props.buttonNew}
        buttonDelete={this.props.buttonDelete}
        butttonEdit={this.props.butttonEdit}
        buttonSeeDetail={this.props.buttonSeeDetail}
        type={this.props.type}
        numbersFrom={this.props.numbersFrom}
        numbersTo={this.props.numbersTo}
      />
    ) : this.props.type === 'transportation' ? (
      <TransportationList
        listData={this.props.listData}
        pagedData={this.props.pagedData}
        title={'Transportation List'}
        pageChange={this.props.pageChange}
        activePage={this.props.activePage}
        searchInput={this.props.searchInput}
        handleChange={this.props.handleChange}
        data={this.props.data}
        buttonNew={this.props.buttonNew}
        butttonExport={this.props.butttonExport}
        buttonUploadData={this.props.buttonUploadData}
        buttonDownloadTemplate={this.props.buttonDownloadTemplate}
        buttonDelete={this.props.buttonDelete}
        buttonEdit={this.props.buttonEdit}
        numbersFrom={this.props.numbersFrom}
        numbersTo={this.props.numbersTo}
      />
    ) : // : this.props.type === 'userrAccess' ? (
    //   <UserAccessList
    //     listData={this.props.listData}
    //     pagedData={this.props.pagedData}
    //     title={'User List'}
    //     pageChange={this.props.pageChange}
    //     activePage={this.props.activePage}
    //     searchInput={this.props.searchInput}
    //     handleChange={this.props.handleChange}
    //     data={this.props.data}
    //     unlockUser={this.props.unlockUser}
    //     resetPassword={this.props.resetPassword}
    //     buttonEdit={this.props.buttonEdit}
    //     buttonNew={this.props.buttonNew}
    //     numbersFrom={this.props.numbersFrom}
    //     numbersTo={this.props.numbersTo}
    //   />
    // )
    // this.props.type === 'lockedUser' ? (
    //   <LockedUserList
    //     listData={this.props.listData}
    //     pagedData={this.props.pagedData}
    //     title={'Locked User List'}
    //     pageChange={this.props.pageChange}
    //     activePage={this.props.activePage}
    //     searchInput={this.props.searchInput}
    //     handleChange={this.props.handleChange}
    //     data={this.props.data}
    //     numbersFrom={this.props.numbersFrom}
    //     numbersTo={this.props.numbersTo}
    //   />
    // ) :

    // this.props.type === 'exchangeRate' ? (
    //   <ExchangeRateList
    //     // listData={this.props.listData ? this.props.listData : this.props.data}
    //     listData={this.props.listData}
    //     pagedData={this.props.pagedData}
    //     title={'Exchange Rate'}
    //     pageChange={this.props.pageChange}
    //     activePage={this.props.activePage}
    //     searchInput={this.props.searchInput}
    //     handleChange={this.props.handleChange}
    //     data={this.props.data}
    //     openNewRate={this.props.openNewRate}
    //     header={this.props.header}
    //     editExchangeRate={this.props.editExchangeRate}
    //     handleSorting={this.props.handleSorting}
    //     buttonDelete={this.props.buttonDelete}
    //     numbersFrom={this.props.numbersFrom}
    //     numbersTo={this.props.numbersTo}
    //   />
    // )
    //:  this.props.type === 'systemMargin' ? (
    //   <SystemMarginList
    //     systemMarginList={this.props.filterData}
    //     listData={this.props.listData}
    //     pagedData={this.props.pagedData}
    //     title={'System Margin'}
    //     pageChange={this.props.pageChange}
    //     activePage={this.props.activePage}
    //     searchInput={this.props.searchInput}
    //     handleChange={this.props.handleChange}
    //     data={this.props.data}
    //     content={this.props.content}
    //     buttonNew={this.props.buttonNew}
    //     butttonEdit={this.props.butttonEdit}
    //     buttonDelete={this.props.buttonDelete}
    //     buttonBack={this.props.buttonBack}
    //     disableButton={this.props.disableButton}
    //     numbersFrom={this.props.numbersFrom}
    //     numbersTo={this.props.numbersTo}
    //   />
    // ) :
    this.props.type === 'partnerProfile' ? (
      <CustomerProfileList
        listData={this.props.listData}
        pagedData={this.props.pagedData}
        title={'Partner Profile'}
        pageChange={this.props.pageChange}
        activePage={this.props.activePage}
        searchInput={this.props.searchInput}
        handleChange={this.props.handleChange}
        data={this.props.data}
        buttonNew={this.props.buttonNew}
        butttonEdit={this.props.buttonEdit}
        type={this.props.type}
        numbersFrom={this.props.numbersFrom}
        numbersTo={this.props.numbersTo}
      />
    ) : // : this.props.type === 'accomodationItem' ? (
    // <AccomodationItemList
    //   listData={this.props.listData}
    //   pagedData={this.props.pagedData}
    //   title={'Accomodation Item List'}
    //   pageChange={this.props.pageChange}
    //   activePage={this.props.activePage}
    //   searchInput={this.props.searchInput}
    //   handleChange={this.props.handleChange}
    //   data={this.props.data}
    //   numbersFrom={this.props.numbersFrom}
    //   numbersTo={this.props.numbersTo}
    // />
    // this.props.type === 'restaurant' ? (
    //   <RestaurantList
    //     listData={this.props.listData}
    //     pagedData={this.props.pagedData}
    //     title={'Restaurant List'}
    //     pageChange={this.props.pageChange}
    //     activePage={this.props.activePage}
    //     searchInput={this.props.searchInput}
    //     handleChange={this.props.handleChange}
    //     data={this.props.data}
    //     buttonNew={this.props.buttonNew}
    //     butttonExport={this.props.butttonExport}
    //     buttonUploadData={this.props.buttonUploadData}
    //     buttonDownloadTemplate={this.props.buttonDownloadTemplate}
    //     butttonEdit={this.props.butttonEdit}
    //     buttonDelete={this.props.buttonDelete}
    //     numbersFrom={this.props.numbersFrom}
    //     numbersTo={this.props.numbersTo}
    //   />
    // ) :
    this.props.type === 'areaList' ? (
      <AreaList
        listData={this.props.listData}
        pagedData={this.props.pagedData}
        title={'Area List'}
        pageChange={this.props.pageChange}
        activePage={this.props.activePage}
        searchInput={this.props.searchInput}
        handleChange={this.props.handleChange}
        data={this.props.data}
        buttonNew={this.props.buttonNew}
        butttonEdit={this.props.butttonEdit}
        buttonDelete={this.props.buttonDelete}
        numbersFrom={this.props.numbersFrom}
        numbersTo={this.props.numbersTo}
      />
    ) : this.props.type === 'cityList' ? (
      <CityList
        listData={this.props.listData}
        pagedData={this.props.pagedData}
        title={'City List'}
        pageChange={this.props.pageChange}
        activePage={this.props.activePage}
        searchInput={this.props.searchInput}
        handleChange={this.props.handleChange}
        data={this.props.data}
        buttonNew={this.props.buttonNew}
        butttonEdit={this.props.butttonEdit}
        buttonDelete={this.props.buttonDelete}
        numbersFrom={this.props.numbersFrom}
        numbersTo={this.props.numbersTo}
      />
    ) : this.props.type === 'regionList' ? (
      <RegionList
        listData={this.props.listData}
        pagedData={this.props.pagedData}
        title={'Region List'}
        pageChange={this.props.pageChange}
        activePage={this.props.activePage}
        searchInput={this.props.searchInput}
        handleChange={this.props.handleChange}
        data={this.props.data}
        buttonNew={this.props.buttonNew}
        butttonEdit={this.props.butttonEdit}
        buttonDelete={this.props.buttonDelete}
        numbersFrom={this.props.numbersFrom}
        numbersTo={this.props.numbersTo}
      />
    ) : this.props.type === 'placeList' ? (
      <PlaceList
        listData={this.props.listData}
        pagedData={this.props.pagedData}
        title={'Place List'}
        pageChange={this.props.pageChange}
        activePage={this.props.activePage}
        searchInput={this.props.searchInput}
        handleChange={this.props.handleChange}
        data={this.props.data}
        buttonNew={this.props.buttonNew}
        butttonEdit={this.props.butttonEdit}
        buttonDelete={this.props.buttonDelete}
        numbersFrom={this.props.numbersFrom}
        numbersTo={this.props.numbersTo}
      />
    ) : this.props.type === 'countryList' ? (
      <CountryList
        listData={this.props.listData}
        pagedData={this.props.pagedData}
        title={'Country List'}
        pageChange={this.props.pageChange}
        activePage={this.props.activePage}
        searchInput={this.props.searchInput}
        handleChange={this.props.handleChange}
        data={this.props.data}
        buttonNew={this.props.buttonNew}
        butttonEdit={this.props.butttonEdit}
        buttonDelete={this.props.buttonDelete}
        numbersFrom={this.props.numbersFrom}
        numbersTo={this.props.numbersTo}
      />
    ) : this.props.type === 'cityGroupList' ? (
      <CityGroupList
        listData={this.props.listData}
        pagedData={this.props.pagedData}
        title={'City Group List'}
        pageChange={this.props.pageChange}
        activePage={this.props.activePage}
        searchInput={this.props.searchInput}
        handleChange={this.props.handleChange}
        data={this.props.data}
        buttonNew={this.props.buttonNew}
        butttonEdit={this.props.butttonEdit}
        buttonDelete={this.props.buttonDelete}
        numbersFrom={this.props.numbersFrom}
        numbersTo={this.props.numbersTo}
      />
    ) : this.props.type === 'readyPackage' ? (
      <ReadyPackage
        listData={this.props.listData}
        pagedData={this.props.pagedData}
        title={'Ready Package'}
        pageChange={this.props.pageChange}
        activePage={this.props.activePage}
        searchInput={this.props.searchInput}
        handleChange={this.props.handleChange}
        data={this.props.data}
        buttonNew={this.props.buttonNew}
        buttonDelete={this.props.buttonDelete}
        buttonPublished={this.props.buttonPublished}
        buttonCopy={this.props.buttonCopy}
        buttonEdit={this.props.buttonEdit}
        dataPublish={this.props.dataPublish}
        dataDraft={this.props.dataDraft}
      />
    ) : this.props.type === 'paymentMethodList' ? (
      <PaymentMethod
        listData={this.props.listData}
        pagedData={this.props.pagedData}
        title={'Payment Method List'}
        pageChange={this.props.pageChange}
        activePage={this.props.activePage}
        searchInput={this.props.searchInput}
        handleChange={this.props.handleChange}
        data={this.props.data}
        buttonNew={this.props.buttonNew}
        butttonEdit={this.props.butttonEdit}
        buttonDelete={this.props.buttonDelete}
        numbersFrom={this.props.numbersFrom}
        numbersTo={this.props.numbersTo}
      />
    ) : // this.props.type === 'UserRole' ? (
    //   <UserRoleList
    //     listData={this.props.listData}
    //     pagedData={this.props.pagedData}
    //     title={'Back Office User Role List'}
    //     pageChange={this.props.pageChange}
    //     activePage={this.props.activePage}
    //     searchInput={this.props.searchInput}
    //     handleChange={this.props.handleChange}
    //     data={this.props.data}
    //     buttonNew={this.props.buttonNew}
    //     butttonEdit={this.props.butttonEdit}
    //     numbersFrom={this.props.numbersFrom}
    //     numbersTo={this.props.numbersTo}
    //   />
    // ) :
    this.props.type === 'notAllowedCountry' ? (
      <NotAllowedCountry
        notAllowedCountryList={this.props.filterData}
        listData={this.props.listData}
        pagedData={this.props.pagedData}
        title={'Not Allowed Country For Company'}
        pageChange={this.props.pageChange}
        activePage={this.props.activePage}
        searchInput={this.props.searchInput}
        handleChange={this.props.handleChange}
        data={this.props.data}
        buttonEdit={this.props.buttonEdit}
        numbersFrom={this.props.numbersFrom}
        numbersTo={this.props.numbersTo}
      />
    ) : this.props.type === 'paymentScheme' ? (
      <PaymentScheme
        listData={this.props.listData}
        pagedData={this.props.pagedData}
        title={'Payment term scheme for company'}
        pageChange={this.props.pageChange}
        activePage={this.props.activePage}
        searchInput={this.props.searchInput}
        handleChange={this.props.handleChange}
        data={this.props.data}
        buttonEdit={this.props.buttonEdit}
        buttonDelete={this.props.buttonDelete}
        buttonNew={this.props.buttonNew}
        buttonLock={this.props.buttonPublished}
        numbersFrom={this.props.numbersFrom}
        numbersTo={this.props.numbersTo}
      />
    ) : null;
  }
}

mainPageList.propTypes = {
  filterData: PropTypes.array,
  type: PropTypes.string,
  listData: PropTypes.array,
  pagedData: PropTypes.array,
  title: PropTypes.string,
  pageChange: PropTypes.func,
  activePage: PropTypes.number,
  searchInput: PropTypes.string,
  handleChange: PropTypes.func,
  data: PropTypes.array,
  content: PropTypes.object,
  buttonNew: PropTypes.func,
  butttonExport: PropTypes.func,
  buttonUploadData: PropTypes.func,
  buttonDownloadTemplate: PropTypes.func,
  butttonEdit: PropTypes.func,
  buttonDelete: PropTypes.func,
  buttonPublished: PropTypes.func,
  buttonCopy: PropTypes.func,
  buttonEdit: PropTypes.func,
  dataPublish: PropTypes.array,
  dataDraft: PropTypes.array,
  unlockUser: PropTypes.func,
  resetPassword: PropTypes.func,
  openNewRate: PropTypes.func,
  editExchangeRate: PropTypes.func,
  buttonSeeDetail: PropTypes.func,
  numbersFrom: PropTypes.number,
  numbersTo: PropTypes.number,
  header: PropTypes.string,
  handleSorting: PropTypes.func,
  buttonBack: PropTypes.func,
  disableButton: PropTypes.bool,
};
