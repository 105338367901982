//Accomodation List
const GET_ACCOMODATION_LIST = 'GET_ACCOMODATION_LIST';
const GET_ACCOMODATION_LIST_FULFILLED = 'GET_ACCOMODATION_LIST_FULFILLED';
const GET_ACCOMODATION_LIST_PENDING = 'GET_ACCOMODATION_LIST_PENDING';
const GET_ACCOMODATION_LIST_REJECTED = 'GET_ACCOMODATION_LIST_REJECTED';

const RESET_STATUS_ACCOMODATION = 'RESET_STATUS_ACCOMODATION';

//Accomodation By Profile Id
const GET_ACCOMODATION_BY_PROFILE_ID = 'GET_ACCOMODATION_BY_PROFILE_ID';
const GET_ACCOMODATION_BY_PROFILE_ID_FULFILLED =
  'GET_ACCOMODATION_BY_PROFILE_ID_FULFILLED';
const GET_ACCOMODATION_BY_PROFILE_ID_PENDING =
  'GET_ACCOMODATION_BY_PROFILE_ID_PENDING';
const GET_ACCOMODATION_BY_PROFILE_ID_REJECTED =
  'GET_ACCOMODATION_BY_PROFILE_ID_REJECTED';

//Accomodation Item By Profile Id
const GET_ACCOMODATION_ITEM_BY_PROFILE_ID =
  'GET_ACCOMODATION_ITEM_BY_PROFILE_ID';
const GET_ACCOMODATION_ITEM_BY_PROFILE_ID_FULFILLED =
  'GET_ACCOMODATION_ITEM_BY_PROFILE_ID_FULFILLED';
const GET_ACCOMODATION_ITEM_BY_PROFILE_ID_PENDING =
  'GET_ACCOMODATION_ITEM_BY_PROFILE_ID_PENDING';
const GET_ACCOMODATION_ITEM_BY_PROFILE_ID_REJECTED =
  'GET_ACCOMODATION_ITEM_BY_PROFILE_ID_REJECTED';

//Accomodation Item By Service Type ID
const GET_ACCOMODATION_ITEM_BY_SERVICE_TYPE_ID =
  'GET_ACCOMODATION_ITEM_BY_SERVICE_TYPE_ID';
const GET_ACCOMODATION_ITEM_BY_SERVICE_TYPE_ID_FULFILLED =
  'GET_ACCOMODATION_ITEM_BY_SERVICE_TYPE_ID_FULFILLED';
const GET_ACCOMODATION_ITEM_BY_SERVICE_TYPE_ID_PENDING =
  'GET_ACCOMODATION_ITEM_BY_SERVICE_TYPE_ID_PENDING';
const GET_ACCOMODATION_ITEM_BY_SERVICE_TYPE_ID_REJECTED =
  'GET_ACCOMODATION_ITEM_BY_SERVICE_TYPE_ID_REJECTED';

//Get Template Accomodation
const GET_TEMPLATE_ACCOMODATION = 'GET_TEMPLATE_ACCOMODATION';
const GET_TEMPLATE_ACCOMODATION_FULFILLED =
  'GET_TEMPLATE_ACCOMODATION_FULFILLED';
const GET_TEMPLATE_ACCOMODATION_PENDING = 'GET_TEMPLATE_ACCOMODATION_PENDING';
const GET_TEMPLATE_ACCOMODATION_REJECTED = 'GET_TEMPLATE_ACCOMODATION_REJECTED';

const GET_TEMPLATE_ACCOMODATION_ITEM_DATA =
  'GET_TEMPLATE_ACCOMODATION_ITEM_DATA';
const GET_TEMPLATE_ACCOMODATION_ITEM_DATA_FULFILLED =
  'GET_TEMPLATE_ACCOMODATION_ITEM_DATA_FULFILLED';
const GET_TEMPLATE_ACCOMODATION_ITEM_DATA_PENDING =
  'GET_TEMPLATE_ACCOMODATION_ITEM_DATA_PENDING';
const GET_TEMPLATE_ACCOMODATION_ITEM_DATA_REJECTED =
  'GET_TEMPLATE_ACCOMODATION_ITEM_DATA_REJECTED';

//POST _IMAGE_ACCOMODATION_PROFILE
const POST_UPLOAD_IMAGE_ACCOMODATION_PROFILE =
  'POST_UPLOAD_IMAGE_ACCOMODATION_PROFILE';
const POST_UPLOAD_IMAGE_ACCOMODATION_PROFILE_FULFILLED =
  'POST_UPLOAD_IMAGE_ACCOMODATION_PROFILE_FULFILLED';
const POST_UPLOAD_IMAGE_ACCOMODATION_PROFILE_PENDING =
  'POST_UPLOAD_IMAGE_ACCOMODATION_PROFILE_PENDING';
const POST_UPLOAD_IMAGE_ACCOMODATION_PROFILE_REJECTED =
  'POST_UPLOAD_IMAGE_ACCOMODATION_PROFILE_REJECTED';

//POST _IMAGE_ACCOMODATION_ITEM
const POST_UPLOAD_IMAGE_ACCOMODATION_ITEM =
  'POST_UPLOAD_IMAGE_ACCOMODATION_ITEM';
const POST_UPLOAD_IMAGE_ACCOMODATION_ITEM_FULFILLED =
  'POST_UPLOAD_IMAGE_ACCOMODATION_ITEM_FULFILLED';
const POST_UPLOAD_IMAGE_ACCOMODATION_ITEM_PENDING =
  'POST_UPLOAD_IMAGE_ACCOMODATION_ITEM_PENDING';
const POST_UPLOAD_IMAGE_ACCOMODATION_ITEM_REJECTED =
  'POST_UPLOAD_IMAGE_ACCOMODATION_ITEM_REJECTED';

//Accomodation Item
const GET_ACCOMODATION_ITEM_ALL = 'GET_ACCOMODATION_ITEM_ALL';
const GET_ACCOMODATION_ITEM_ALL_FULFILLED =
  'GET_ACCOMODATION_ITEM_ALL_FULFILLED';
const GET_ACCOMODATION_ITEM_ALL_PENDING = 'GET_ACCOMODATION_ITEM_ALL_PENDING';
const GET_ACCOMODATION_ITEM_ALL_REJECTED = 'GET_ACCOMODATION_ITEM_ALL_REJECTED';

const GET_ACCOMMODATION_BY_CITY = 'GET_ACCOMMODATION_BY_CITY';
const GET_ACCOMMODATION_BY_CITY_PENDING = 'GET_ACCOMMODATION_BY_CITY_PENDING';
const GET_ACCOMMODATION_BY_CITY_FULFILLED =
  'GET_ACCOMMODATION_BY_CITY_FULFILLED';
const GET_ACCOMMODATION_BY_CITY_REJECTED = 'GET_ACCOMMODATION_BY_CITY_REJECTED';

const GET_ACCOMODATION_ITEM_BY_PROFILE_ID_AND_REQUEST_DATE =
  'GET_ACCOMODATION_ITEM_BY_PROFILE_ID_AND_REQUEST_DATE';
const GET_ACCOMODATION_ITEM_BY_PROFILE_ID_AND_REQUEST_DATE_PENDING =
  'GET_ACCOMODATION_ITEM_BY_PROFILE_ID_AND_REQUEST_DATE_PENDING';
const GET_ACCOMODATION_ITEM_BY_PROFILE_ID_AND_REQUEST_DATE_FULFILLED =
  'GET_ACCOMODATION_ITEM_BY_PROFILE_ID_AND_REQUEST_DATE_FULFILLED';
const GET_ACCOMODATION_ITEM_BY_PROFILE_ID_AND_REQUEST_DATE_REJECTED =
  'GET_ACCOMODATION_ITEM_BY_PROFILE_ID_AND_REQUEST_DATE_REJECTED';

const POST_SET_PRIMARY_IMAGE = 'POST_SET_PRIMARY_IMAGE';
const POST_SET_PRIMARY_IMAGE_FULFILLED = 'POST_SET_PRIMARY_IMAGE_FULFILLED';
const POST_SET_PRIMARY_IMAGE_PENDING = 'POST_SET_PRIMARY_IMAGE_PENDING';
const POST_SET_PRIMARY_IMAGE_REJECTED = 'POST_SET_PRIMARY_IMAGE_REJECTED';

const GET_ACCOMMODATION_WITH_FILTER = 'GET_ACCOMMODATION_WITH_FILTER';
const GET_ACCOMMODATION_WITH_FILTER_FULFILLED =
  'GET_ACCOMMODATION_WITH_FILTER_FULFILLED';
const GET_ACCOMMODATION_WITH_FILTER_PENDING =
  'GET_ACCOMMODATION_WITH_FILTER_PENDING';
const GET_ACCOMMODATION_WITH_FILTER_REJECTED =
  'GET_ACCOMMODATION_WITH_FILTER_REJECTED';

const GET_ROOM_ALLOTMENT_BY_PROFILE_ID = 'GET_ROOM_ALLOTMENT_BY_PROFILE_ID';
const GET_ROOM_ALLOTMENT_BY_PROFILE_ID_FULFILLED =
  'GET_ROOM_ALLOTMENT_BY_PROFILE_ID_FULFILLED';
const GET_ROOM_ALLOTMENT_BY_PROFILE_ID_PENDING =
  'GET_ROOM_ALLOTMENT_BY_PROFILE_ID_PENDING';
const GET_ROOM_ALLOTMENT_BY_PROFILE_ID_REJECTED =
  'GET_ROOM_ALLOTMENT_BY_PROFILE_ID_REJECTED';

const POST_ROOM_ALLOTMENT_DAY = 'POST_ROOM_ALLOTMENT_DAY';
const POST_ROOM_ALLOTMENT_DAY_FULFILLED = 'POST_ROOM_ALLOTMENT_DAY_FULFILLED';
const POST_ROOM_ALLOTMENT_DAY_PENDING = 'POST_ROOM_ALLOTMENT_DAY_PENDING';
const POST_ROOM_ALLOTMENT_DAY_REJECTED = 'POST_ROOM_ALLOTMENT_DAY_REJECTED';

const POST_ROOM_ALLOTMENT_PERIOD = 'POST_ROOM_ALLOTMENT_PERIOD';
const POST_ROOM_ALLOTMENT_PERIOD_FULFILLED =
  'POST_ROOM_ALLOTMENT_PERIOD_FULFILLED';
const POST_ROOM_ALLOTMENT_PERIOD_PENDING = 'POST_ROOM_ALLOTMENT_PERIOD_PENDING';
const POST_ROOM_ALLOTMENT_PERIOD_REJECTED =
  'POST_ROOM_ALLOTMENT_PERIOD_REJECTED';

const GET_REQUEST_ROOM_ALLOTMENT = 'GET_REQUEST_ROOM_ALLOTMENT';
const GET_REQUEST_ROOM_ALLOTMENT_FULFILLED =
  'GET_REQUEST_ROOM_ALLOTMENT_FULFILLED';
const GET_REQUEST_ROOM_ALLOTMENT_PENDING = 'GET_REQUEST_ROOM_ALLOTMENT_PENDING';
const GET_REQUEST_ROOM_ALLOTMENT_REJECTED =
  'GET_REQUEST_ROOM_ALLOTMENT_REJECTED';

const POST_CONFIRM_ROOM_ALLOTMENT = 'POST_CONFIRM_ROOM_ALLOTMENT';
const POST_CONFIRM_ROOM_ALLOTMENT_FULFILLED =
  'POST_CONFIRM_ROOM_ALLOTMENT_FULFILLED';
const POST_CONFIRM_ROOM_ALLOTMENT_PENDING =
  'POST_CONFIRM_ROOM_ALLOTMENT_PENDING';
const POST_CONFIRM_ROOM_ALLOTMENT_REJECTED =
  'POST_CONFIRM_ROOM_ALLOTMENT_REJECTED';

const GET_LIST_CONFIRM_ROOM_ALLOTMENTS = 'GET_LIST_CONFIRM_ROOM_ALLOTMENTS';
const GET_LIST_CONFIRM_ROOM_ALLOTMENTS_FULFILLED =
  'GET_LIST_CONFIRM_ROOM_ALLOTMENTS_FULFILLED';
const GET_LIST_CONFIRM_ROOM_ALLOTMENTS_PENDING =
  'GET_LIST_CONFIRM_ROOM_ALLOTMENTS_PENDING';
const GET_LIST_CONFIRM_ROOM_ALLOTMENTS_REJECTED =
  'GET_LIST_CONFIRM_ROOM_ALLOTMENTS_REJECTED';

const GET_RESEND_REQUEST_CONFIRMATION = 'GET_RESEND_REQUEST_CONFIRMATION';
const GET_RESEND_REQUEST_CONFIRMATION_FULFILLED =
  'GET_RESEND_REQUEST_CONFIRMATION_FULFILLED';
const GET_RESEND_REQUEST_CONFIRMATION_PENDING =
  'GET_RESEND_REQUEST_CONFIRMATION_PENDING';
const GET_RESEND_REQUEST_CONFIRMATION_REJECTED =
  'GET_RESEND_REQUEST_CONFIRMATION_REJECTED';

const GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID =
  'GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID';
const GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID_FULFILLED =
  'GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID_FULFILLED';
const GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID_PENDING =
  'GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID_PENDING';
const GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID_REJECTED =
  'GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID_REJECTED';

const GET_STOCK_ROOM_ALLOTMENT = 'GET_STOCK_ROOM_ALLOTMENT';
const GET_STOCK_ROOM_ALLOTMENT_FULFILLED = 'GET_STOCK_ROOM_ALLOTMENT_FULFILLED';
const GET_STOCK_ROOM_ALLOTMENT_PENDING = 'GET_STOCK_ROOM_ALLOTMENT_PENDING';
const GET_STOCK_ROOM_ALLOTMENT_REJECTED = 'GET_STOCK_ROOM_ALLOTMENT_REJECTED';

const GET_EXPORT_STOCK_ROOM_ALLOTMENT = 'GET_EXPORT_STOCK_ROOM_ALLOTMENT';
const GET_EXPORT_STOCK_ROOM_ALLOTMENT_FULFILLED =
  'GET_EXPORT_STOCK_ROOM_ALLOTMENT_FULFILLED';
const GET_EXPORT_STOCK_ROOM_ALLOTMENT_PENDING =
  'GET_EXPORT_STOCK_ROOM_ALLOTMENT_PENDING';
const GET_EXPORT_STOCK_ROOM_ALLOTMENT_REJECTED =
  'GET_EXPORT_STOCK_ROOM_ALLOTMENT_REJECTED';

//Delete Accomodation By Profile Id
const DELETE_ACCOMODATION_BY_PROFILE_ID = 'DELETE_ACCOMODATION_BY_PROFILE_ID';
const DELETE_ACCOMODATION_BY_PROFILE_ID_FULFILLED =
  'DELETE_ACCOMODATION_BY_PROFILE_ID_FULFILLED';
const DELETE_ACCOMODATION_BY_PROFILE_ID_PENDING =
  'DELETE_ACCOMODATION_BY_PROFILE_ID_PENDING';
const DELETE_ACCOMODATION_BY_PROFILE_ID_REJECTED =
  'DELETE_ACCOMODATION_BY_PROFILE_ID_REJECTED';

//Get export Accomodation
const GET_EXPORT_ACCOMODATION = 'GET_EXPORT_ACCOMODATION';
const GET_EXPORT_ACCOMODATION_FULFILLED = 'GET_EXPORT_ACCOMODATION_FULFILLED';
const GET_EXPORT_ACCOMODATION_PENDING = 'GET_EXPORT_ACCOMODATION_PENDING';
const GET_EXPORT_ACCOMODATION_REJECTED = 'GET_EXPORT_ACCOMODATION_REJECTED';

// ----------- Customer Profile ------------

//Customer Profile
const GET_CUSTOMER_PROFILE_LIST = 'GET_CUSTOMER_PROFILE_LIST';
const RESET_STATUS_CUSTOMER_PROFILE = 'RESET_STATUS_CUSTOMER_PROFILE';
const GET_CUSTOMER_PROFILE_LIST_FULFILLED =
  'GET_CUSTOMER_PROFILE_LIST_FULFILLED';
const GET_CUSTOMER_PROFILE_LIST_PENDING = 'GET_CUSTOMER_PROFILE_LIST_PENDING';
const GET_CUSTOMER_PROFILE_LIST_REJECTED = 'GET_CUSTOMER_PROFILE_LIST_REJECTED';

//GET CUSTOMER_PROFILE
const GET_CUSTOMER_PROFILE_BY_CODE = 'GET_CUSTOMER_PROFILE_BY_CODE';
const GET_CUSTOMER_PROFILE_BY_CODE_FULFILLED =
  'GET_CUSTOMER_PROFILE_BY_CODE_FULFILLED';
const GET_CUSTOMER_PROFILE_BY_CODE_PENDING =
  'GET_CUSTOMER_PROFILE_BY_CODE_PENDING';
const GET_CUSTOMER_PROFILE_BY_CODE_REJECTED =
  'GET_CUSTOMER_PROFILE_BY_CODE_REJECTED';

//GET USER_DATA
const GET_USER_DATA_BY_CODE = 'GET_USER_DATA_BY_CODE';
const GET_USER_DATA_BY_CODE_FULFILLED = 'GET_USER_DATA_BY_CODE_FULFILLED';
const GET_USER_DATA_BY_CODE_PENDING = 'GET_USER_DATA_BY_CODE_PENDING';
const GET_USER_DATA_BY_CODE_REJECTED = 'GET_USER_DATA_BY_CODE_REJECTED';

//PUT CUSTOMER_PROFILE
const PUT_CUSTOMER_PROFILE = 'PUT_CUSTOMER_PROFILE';
const PUT_CUSTOMER_PROFILE_FULFILLED = 'PUT_CUSTOMER_PROFILE_FULFILLED';
const PUT_CUSTOMER_PROFILE_PENDING = 'PUT_CUSTOMER_PROFILE_PENDING';
const PUT_CUSTOMER_PROFILE_REJECTED = 'PUT_CUSTOMER_PROFILE_REJECTED';

//GET BALANCE_HISTORY
const GET_BALANCE_HISTORY_BY_CODE = 'GET_BALANCE_HISTORY_BY_CODE';
const GET_BALANCE_HISTORY_BY_CODE_FULFILLED =
  'GET_BALANCE_HISTORY_BY_CODE_FULFILLED';
const GET_BALANCE_HISTORY_BY_CODE_PENDING =
  'GET_BALANCE_HISTORY_BY_CODE_PENDING';
const GET_BALANCE_HISTORY_BY_CODE_REJECTED =
  'GET_BALANCE_HISTORY_BY_CODE_REJECTED';

//POST BALANCE_DETAIL
const POST_BALANCE_DETAIL = 'POST_BALANCE_DETAIL';
const POST_BALANCE_DETAIL_FULFILLED = 'POST_BALANCE_DETAIL_FULFILLED';
const POST_BALANCE_DETAIL_PENDING = 'POST_BALANCE_DETAIL_PENDING';
const POST_BALANCE_DETAIL_REJECTED = 'POST_BALANCE_DETAIL_REJECTED';

//Get Payment Scheme
const GET_PAYMENT_SCHEME = 'GET_PAYMENT_SCHEME';
const GET_PAYMENT_SCHEME_FULFILLED = 'GET_PAYMENT_SCHEME_FULFILLED';
const GET_PAYMENT_SCHEME_PENDING = 'GET_PAYMENT_SCHEME_PENDING';
const GET_PAYMENT_SCHEME_REJECTED = 'GET_PAYMENT_SCHEME_REJECTED';

//POST CUSTOMER_PROFILE
const POST_CUSTOMER_PROFILE = 'POST_CUSTOMER_PROFILE';
const POST_CUSTOMER_PROFILE_FULFILLED = 'POST_CUSTOMER_PROFILE_FULFILLED';
const POST_CUSTOMER_PROFILE_PENDING = 'POST_CUSTOMER_PROFILE_PENDING';
const POST_CUSTOMER_PROFILE_REJECTED = 'POST_CUSTOMER_PROFILE_REJECTED';

const GET_USER_PROFILE_ACCOUNT_MANAGER = 'GET_USER_PROFILE_ACCOUNT_MANAGER';
const GET_USER_PROFILE_ACCOUNT_MANAGER_PENDING =
  'GET_USER_PROFILE_ACCOUNT_MANAGER_PENDING';
const GET_USER_PROFILE_ACCOUNT_MANAGER_FULFILLED =
  'GET_USER_PROFILE_ACCOUNT_MANAGER_FULFILLED';
const GET_USER_PROFILE_ACCOUNT_MANAGER_REJECTED =
  'GET_USER_PROFILE_ACCOUNT_MANAGER_REJECTED';

const GET_USER_PROFILE_ACCOUNT_MANAGER_BY_COMPANY =
  'GET_USER_PROFILE_ACCOUNT_MANAGER_BY_COMPANY';
const GET_USER_PROFILE_ACCOUNT_MANAGER_BY_COMPANY_PENDING =
  'GET_USER_PROFILE_ACCOUNT_MANAGER_BY_COMPANY_PENDING';
const GET_USER_PROFILE_ACCOUNT_MANAGER_BY_COMPANY_FULFILLED =
  'GET_USER_PROFILE_ACCOUNT_MANAGER_BY_COMPANY_FULFILLED';
const GET_USER_PROFILE_ACCOUNT_MANAGER_BY_COMPANY_REJECTED =
  'GET_USER_PROFILE_ACCOUNT_MANAGER_BY_COMPANY_REJECTED';

//DELETE USER_PROFILE
const DELETE_USER_PROFILE = 'DELETE_USER_PROFILE';
const DELETE_USER_PROFILE_FULFILLED = 'DELETE_USER_PROFILE_FULFILLED';
const DELETE_USER_PROFILE_PENDING = 'DELETE_USER_PROFILE_PENDING';
const DELETE_USER_PROFILE_REJECTED = 'DELETE_USER_PROFILE_REJECTED';

//POST USER_PROFILE_ACTIVATE
const POST_USER_PROFILE_ACTIVATE = 'POST_USER_PROFILE';
const POST_USER_PROFILE_ACTIVATE_FULFILLED = 'POST_USER_PROFILE_FULFILLED';
const POST_USER_PROFILE_ACTIVATE_PENDING = 'POST_USER_PROFILE_PENDING';
const POST_USER_PROFILE_ACTIVATE_REJECTED = 'POST_USER_PROFILE_REJECTED';

const POST_CREATE_CUSTOMER_ON_BE_HALF = 'POST_CREATE_CUSTOMER_ON_BE_HALF';
const POST_CREATE_CUSTOMER_ON_BE_HALF_FULFILLED =
  'POST_CREATE_CUSTOMER_ON_BE_HALF_FULFILLED';
const POST_CREATE_CUSTOMER_ON_BE_HALF_PENDING =
  'POST_CREATE_CUSTOMER_ON_BE_HALF_PENDING';
const POST_CREATE_CUSTOMER_ON_BE_HALF_REJECTED =
  'POST_CREATE_CUSTOMER_ON_BE_HALF_REJECTED';

const GET_PAYMENT_SCHEME_BY_ID = 'GET_PAYMENT_SCHEME_BY_ID';
const GET_PAYMENT_SCHEME_BY_ID_FULFILLED = 'GET_PAYMENT_SCHEME_BY_ID_FULFILLED';
const GET_PAYMENT_SCHEME_BY_ID_PENDING = 'GET_PAYMENT_SCHEME_BY_ID_PENDING';
const GET_PAYMENT_SCHEME_BY_ID_REJECTED = 'GET_PAYMENT_SCHEME_BY_ID_REJECTED';

const POST_PAYMENT_SCHEME = 'POST_PAYMENT_SCHEME';
const POST_PAYMENT_SCHEME_FULFILLED = 'POST_PAYMENT_SCHEME_FULFILLED';
const POST_PAYMENT_SCHEME_PENDING = 'POST_PAYMENT_SCHEME_PENDING';
const POST_PAYMENT_SCHEME_REJECTED = 'POST_PAYMENT_SCHEME_REJECTED';

const PUT_PAYMENT_SCHEME = 'PUT_PAYMENT_SCHEME';
const PUT_PAYMENT_SCHEME_FULFILLED = 'PUT_PAYMENT_SCHEME_FULFILLED';
const PUT_PAYMENT_SCHEME_PENDING = 'PUT_PAYMENT_SCHEME_PENDING';
const PUT_PAYMENT_SCHEME_REJECTED = 'PUT_PAYMENT_SCHEME_REJECTED';

const POST_PAYMENT_SCHEME_DETAIL = 'POST_PAYMENT_SCHEME_DETAIL';
const POST_PAYMENT_SCHEME_DETAIL_FULFILLED =
  'POST_PAYMENT_SCHEME_DETAIL_FULFILLED';
const POST_PAYMENT_SCHEME_DETAIL_PENDING = 'POST_PAYMENT_SCHEME_DETAIL_PENDING';
const POST_PAYMENT_SCHEME_DETAIL_REJECTED =
  'POST_PAYMENT_SCHEME_DETAIL_REJECTED';

const PUT_PAYMENT_SCHEME_DETAIL = 'PUT_PAYMENT_SCHEME_DETAIL';
const PUT_PAYMENT_SCHEME_DETAIL_FULFILLED =
  'PUT_PAYMENT_SCHEME_DETAIL_FULFILLED';
const PUT_PAYMENT_SCHEME_DETAIL_PENDING = 'PUT_PAYMENT_SCHEME_DETAIL_PENDING';
const PUT_PAYMENT_SCHEME_DETAIL_REJECTED = 'PUT_PAYMENT_SCHEME_DETAIL_REJECTED';

const DELETE_PAYMENT_SCHEME_DETAIL = 'DELETE_PAYMENT_SCHEME_DETAIL';
const DELETE_PAYMENT_SCHEME_DETAIL_FULFILLED =
  'DELETE_PAYMENT_SCHEME_DETAIL_FULFILLED';
const DELETE_PAYMENT_SCHEME_DETAIL_PENDING =
  'DELETE_PAYMENT_SCHEME_DETAIL_PENDING';
const DELETE_PAYMENT_SCHEME_DETAIL_REJECTED =
  'DELETE_PAYMENT_SCHEME_DETAIL_REJECTED';

const GET_PAYMENT_SCHEME_DETAIL_BY_ID = 'GET_PAYMENT_SCHEME_DETAIL_BY_ID';
const GET_PAYMENT_SCHEME_DETAIL_BY_ID_FULFILLED =
  'GET_PAYMENT_SCHEME_DETAIL_BY_ID_FULFILLED';
const GET_PAYMENT_SCHEME_DETAIL_BY_ID_PENDING =
  'GET_PAYMENT_SCHEME_DETAIL_BY_ID_PENDING';
const GET_PAYMENT_SCHEME_DETAIL_BY_ID_REJECTED =
  'GET_PAYMENT_SCHEME_DETAIL_BY_ID_REJECTED';

// ----------- End Customer Profile ------------

//Get Country
const GET_COUNTRY = 'GET_COUNTRY';
const GET_COUNTRY_FULFILLED = 'GET_COUNTRY_FULFILLED';
const GET_COUNTRY_PENDING = 'GET_COUNTRY_PENDING';
const GET_COUNTRY_REJECTED = 'GET_COUNTRY_REJECTED';
const RESET_COUNTRY = 'RESET_COUNTRY';

//Get Country
const GET_COUNTRY_LIST = 'GET_COUNTRY_LIST';
const GET_COUNTRY_LIST_FULFILLED = 'GET_COUNTRY_LIST_FULFILLED';
const GET_COUNTRY_LIST_PENDING = 'GET_COUNTRY_LIST_PENDING';
const GET_COUNTRY_LIST_REJECTED = 'GET_COUNTRY_LIST_REJECTED';

//Get By ID => Country
const GET_COUNTRY_BY_ID = 'GET_COUNTRY_BY_ID';
const GET_COUNTRY_BY_ID_FULFILLED = 'GET_COUNTRY_BY_ID_FULFILLED';
const GET_COUNTRY_BY_ID_PENDING = 'GET_COUNTRY_BY_ID_PENDING';
const GET_COUNTRY_BY_ID_REJECTED = 'GET_COUNTRY_BY_ID_REJECTED';

const GET_MASTER_REFERENCE = 'GET_MASTER_REFERENCE';
const GET_MASTER_REFERENCE_FULFILLED = 'GET_MASTER_REFERENCE_FULFILLED';
const GET_MASTER_REFERENCE_PENDING = 'GET_MASTER_REFERENCE_PENDING';
const GET_MASTER_REFERENCE_REJECTED = 'GET_MASTER_REFERENCE_REJECTED';

const RESET_MASTER_REFERENCE = 'RESET_MASTER_REFERENCE';

const GET_CANCELLATION_REASON_BY_TYPE_REFERENCE =
  'GET_CANCELLATION_REASON_BY_TYPE_REFERENCE';
const GET_CANCELLATION_REASON_BY_TYPE_REFERENCE_FULFILLED =
  'GET_CANCELLATION_REASON_BY_TYPE_REFERENCE_FULFILLED';
const GET_CANCELLATION_REASON_BY_TYPE_REFERENCE_PENDING =
  'GET_CANCELLATION_REASON_BY_TYPE_REFERENCE_PENDING';
const GET_CANCELLATION_REASON_BY_TYPE_REFERENCE_REJECTED =
  'GET_CANCELLATION_REASON_BY_TYPE_REFERENCE_REJECTED';

const RESET_CANCELLATION_REASON_BY_TYPE_REFERENCE =
  'RESET_CANCELLATION_REASON_BY_TYPE_REFERENCE';
//Get Area By City
const GET_AREA_BY_CITY = 'GET_AREA_BY_CITY';
const GET_AREA_BY_CITY_FULFILLED = 'GET_AREA_BY_CITY_FULFILLED';
const GET_AREA_BY_CITY_PENDING = 'GET_AREA_BY_CITY_PENDING';
const GET_AREA_BY_CITY_REJECTED = 'GET_AREA_BY_CITY_REJECTED';

const RESET_AREA = 'RESET_AREA';

//Get Area By City
const GET_AREA_LIST = 'GET_AREA_LIST';
const GET_AREA_LIST_FULFILLED = 'GET_AREA_LIST_FULFILLED';
const GET_AREA_LIST_PENDING = 'GET_AREA_LIST_PENDING';
const GET_AREA_LIST_REJECTED = 'GET_AREA_LIST_REJECTED';

const GET_DEST_CITY_BY_REGION = 'GET_DEST_CITY_BY_REGION';
const GET_DEST_CITY_BY_REGION_PENDING = 'GET_DEST_CITY_BY_REGION_PENDING';
const GET_DEST_CITY_BY_REGION_FULFILLED = 'GET_DEST_CITY_BY_REGION_FULFILLED';
const GET_DEST_CITY_BY_REGION_REJECTED = 'GET_DEST_CITY_BY_REGION_REJECTED';

const GET_ORIGIN_CITY_BY_REGION = 'GET_ORIGIN_CITY_BY_REGION';
const GET_ORIGIN_CITY_BY_REGION_PENDING = 'GET_ORIGIN_CITY_BY_REGION_PENDING';
const GET_ORIGIN_CITY_BY_REGION_FULFILLED =
  'GET_ORIGIN_CITY_BY_REGION_FULFILLED';
const GET_ORIGIN_CITY_BY_REGION_REJECTED = 'GET_ORIGIN_CITY_BY_REGION_REJECTED';

const RESET_CITY = 'RESET_CITY';

//Get City By Region
const GET_CITY_LIST = 'GET_CITY_LIST';
const GET_CITY_LIST_FULFILLED = 'GET_CITY_LIST_FULFILLED';
const GET_CITY_LIST_PENDING = 'GET_CITY_LIST_PENDING';
const GET_CITY_LIST_REJECTED = 'GET_CITY_LIST_REJECTED';

//Get City In Country
const GET_CITY_IN_COUNTRY = 'GET_CITY_IN_COUNTRY';
const GET_CITY_IN_COUNTRY_FULFILLED = 'GET_CITY_IN_COUNTRY_FULFILLED';
const GET_CITY_IN_COUNTRY_PENDING = 'GET_CITY_IN_COUNTRY_PENDING';
const GET_CITY_IN_COUNTRY_REJECTED = 'GET_CITY_IN_COUNTRY_REJECTED';

//Get City By Region
const GET_CITY_BY_REGION = 'GET_CITY_BY_REGION';
const GET_CITY_BY_REGION_FULFILLED = 'GET_CITY_BY_REGION_FULFILLED';
const GET_CITY_BY_REGION_PENDING = 'GET_CITY_BY_REGION_PENDING';
const GET_CITY_BY_REGION_REJECTED = 'GET_CITY_BY_REGION_REJECTED';

const RESET_REGION = 'RESET_REGION';

// Get Region By Country
const GET_REGION_LIST = 'GET_REGION_LIST';
const GET_REGION_LIST_FULFILLED = 'GET_REGION_LIST_FULFILLED';
const GET_REGION_LIST_PENDING = 'GET_REGION_LIST_PENDING';
const GET_REGION_LIST_REJECTED = 'GET_REGION_LIST_REJECTED';

// Get Region By Country
const GET_REGION_BY_COUNTRY = 'GET_REGION_BY_COUNTRY';
const GET_REGION_BY_COUNTRY_FULFILLED = 'GET_REGION_BY_COUNTRY_FULFILLED';
const GET_REGION_BY_COUNTRY_PENDING = 'GET_REGION_BY_COUNTRY_PENDING';
const GET_REGION_BY_COUNTRY_REJECTED = 'GET_REGION_BY_COUNTRY_REJECTED';

//Excursion
const GET_EXCURSION_PROFILES = 'GET_EXCURSION_PROFILES';
const GET_EXCURSION_PROFILES_FULFILLED = 'GET_EXCURSION_PROFILES_FULFILLED';
const GET_EXCURSION_PROFILES_PENDING = 'GET_EXCURSION_PROFILES_PENDING';
const GET_EXCURSION_PROFILES_REJECTED = 'GET_EXCURSION_PROFILES_REJECTED';

const GET_EXCURSION_PROFILE = 'GET_EXCURSION_PROFILE';
const GET_EXCURSION_PROFILE_REJECTED = 'GET_EXCURSION_PROFILE_REJECTED';
const GET_EXCURSION_PROFILE_PENDING = 'GET_EXCURSION_PROFILE_PENDING';
const GET_EXCURSION_PROFILE_FULFILLED = 'GET_EXCURSION_PROFILE_FULFILLED';

const RESET_STATUS_EXCURSION = 'RESET_STATUS_EXCURSION';

const GET_EXCURSION_TYPE = 'GET_EXCURSION_TYPE';
const GET_EXCURSION_TYPE_PENDING = 'GET_EXCURSION_TYPE_PENDING';
const GET_EXCURSION_TYPE_FULFILLED = 'GET_EXCURSION_TYPE_FULFILLED';
const GET_EXCURSION_TYPE_REJECTED = 'GET_EXCURSION_TYPE_REJECTED';

// SERVICE PRICE
const GET_SERVICE_PRICE = 'GET_SERVICE_PRICE';
const GET_SERVICE_PRICE_PENDING = 'GET_SERVICE_PRICE_PENDING';
const GET_SERVICE_PRICE_FULFILLED = 'GET_SERVICE_PRICE_FULFILLED';
const GET_SERVICE_PRICE_REJECTED = 'GET_SERVICE_PRICE_REJECTED';

const GET_TEMPLATE_EXCURSION_OBJECT_DATA = 'GET_TEMPLATE_EXCURSION_OBJECT_DATA';
const GET_TEMPLATE_EXCURSION_OBJECT_DATA_FULFILLED =
  'GET_TEMPLATE_EXCURSION_OBJECT_DATA_FULFILLED';
const GET_TEMPLATE_EXCURSION_OBJECT_DATA_PENDING =
  'GET_TEMPLATE_EXCURSION_OBJECT_DATA_PENDING';
const GET_TEMPLATE_EXCURSION_OBJECT_DATA_REJECTED =
  'GET_TEMPLATE_EXCURSION_OBJECT_DATA_REJECTED';

const GET_TEMPLATE_EXCURSION_PRICE_DATA = 'GET_TEMPLATE_EXCURSION_PRICE_DATA';
const GET_TEMPLATE_EXCURSION_PRICE_DATA_FULFILLED =
  'GET_TEMPLATE_EXCURSION_PRICE_DATA_FULFILLED';
const GET_TEMPLATE_EXCURSION_PRICE_DATA_PENDING =
  'GET_TEMPLATE_EXCURSION_PRICE_DATA_PENDING';
const GET_TEMPLATE_EXCURSION_PRICE_DATA_REJECTED =
  'GET_TEMPLATE_EXCURSION_PRICE_DATA_REJECTED';

const GET_EXCURSION_PROFILE_BY_CITY = 'GET_EXCURSION_PROFILE_BY_CITY';
const GET_EXCURSION_PROFILE_BY_CITY_FULFILLED =
  'GET_EXCURSION_PROFILE_BY_CITY_FULFILLED';
const GET_EXCURSION_PROFILE_BY_CITY_PENDING =
  'GET_EXCURSION_PROFILE_BY_CITY_PENDING';
const GET_EXCURSION_PROFILE_BY_CITY_REJECTED =
  'GET_EXCURSION_PROFILE_BY_CITY_REJECTED';

//POST _IMAGE_EXCURSION_OBJECT
export const POST_UPLOAD_IMAGE_EXCURSION_OBJECT =
  'POST_UPLOAD_IMAGE_EXCURSION_OBJECT';
export const POST_UPLOAD_IMAGE_EXCURSION_OBJECT_FULFILLED =
  'POST_UPLOAD_IMAGE_EXCURSION_OBJECT_FULFILLED';
export const POST_UPLOAD_IMAGE_EXCURSION_OBJECT_PENDING =
  'POST_UPLOAD_IMAGE_EXCURSION_OBJECT_PENDING';
export const POST_UPLOAD_IMAGE_EXCURSION_OBJECT_REJECTED =
  'POST_UPLOAD_IMAGE_EXCURSION_OBJECT_REJECTED';

const GET_CUSTOMER_PROFILE_FOR_SALES = 'GET_CUSTOMER_PROFILE_FOR_SALES';
const GET_CUSTOMER_PROFILE_FOR_SALES_FULFILLED =
  'GET_CUSTOMER_PROFILE_FOR_SALES_FULFILLED';
const GET_CUSTOMER_PROFILE_FOR_SALES_PENDING =
  'GET_CUSTOMER_PROFILE_FOR_SALES_PENDING';
const GET_CUSTOMER_PROFILE_FOR_SALES_REJECTED =
  'GET_CUSTOMER_PROFILE_FOR_SALES_REJECTED';

const GET_USER_DATA_BY_CODE_FOR_SALES = 'GET_USER_DATA_BY_CODE_FOR_SALES';
const GET_USER_DATA_BY_CODE_FOR_SALES_FULFILLED =
  'GET_USER_DATA_BY_CODE_FOR_SALES_FULFILLED';
const GET_USER_DATA_BY_CODE_FOR_SALES_PENDING =
  'GET_USER_DATA_BY_CODE_FOR_SALES_PENDING';
const GET_USER_DATA_BY_CODE_FOR_SALES_REJECTED =
  'GET_USER_DATA_BY_CODE_FOR_SALES_REJECTED';

const GET_SUPPLIER_LIST = 'GET_SUPPLIER_LIST';
const GET_SUPPLIER_LIST_FULFILLED = 'GET_SUPPLIER_LIST_FULFILLED';
const GET_SUPPLIER_LIST_PENDING = 'GET_SUPPLIER_LIST_PENDING';
const GET_SUPPLIER_LIST_REJECTED = 'GET_SUPPLIER_LIST_REJECTED';

const GET_EXPORT_DATA_ITEM_BY_ID = 'GET_EXPORT_DATA_ITEM_BY_ID';
const GET_EXPORT_DATA_ITEM_BY_ID_FULFILLED =
  'GET_EXPORT_DATA_ITEM_BY_ID_FULFILLED';
const GET_EXPORT_DATA_ITEM_BY_ID_PENDING = 'GET_EXPORT_DATA_ITEM_BY_ID_PENDING';
const GET_EXPORT_DATA_ITEM_BY_ID_REJECTED =
  'GET_EXPORT_DATA_ITEM_BY_ID_REJECTED';

const GET_LIST_TOTAL_EMPLOYEE = 'GET_LIST_TOTAL_EMPLOYEE';
const GET_LIST_TOTAL_EMPLOYEE_FULFILLED = 'GET_LIST_TOTAL_EMPLOYEE_FULFILLED';
const GET_LIST_TOTAL_EMPLOYEE_PENDING = 'GET_LIST_TOTAL_EMPLOYEE_PENDING';
const GET_LIST_TOTAL_EMPLOYEE_REJECTED = 'GET_LIST_TOTAL_EMPLOYEE_REJECTED';

const GET_GENERATE_RESOURCE_BOOKING = 'GET_GENERATE_RESOURCE_BOOKING';
const GET_GENERATE_RESOURCE_BOOKING_FULFILLED =
  'GET_GENERATE_RESOURCE_BOOKING_FULFILLED';
const GET_GENERATE_RESOURCE_BOOKING_PENDING =
  'GET_GENERATE_RESOURCE_BOOKING_PENDING';
const GET_GENERATE_RESOURCE_BOOKING_REJECTED =
  'GET_GENERATE_RESOURCE_BOOKING_REJECTED';

const GET_DOWNLOAD_RESOURCE_BOOKING_DETAIL =
  'GET_DOWNLOAD_RESOURCE_BOOKING_DETAIL';
const GET_DOWNLOAD_RESOURCE_BOOKING_DETAIL_FULFILLED =
  'GET_DOWNLOAD_RESOURCE_BOOKING_DETAIL_FULFILLED';
const GET_DOWNLOAD_RESOURCE_BOOKING_DETAIL_PENDING =
  'GET_DOWNLOAD_RESOURCE_BOOKING_DETAIL_PENDING';
const GET_DOWNLOAD_RESOURCE_BOOKING_DETAIL_REJECTED =
  'GET_DOWNLOAD_RESOURCE_BOOKING_DETAIL_REJECTED';

const PUT_RESOURCE_BOOKING_DETAIL_UPDATE = 'PUT_RESOURCE_BOOKING_DETAIL_UPDATE';
const PUT_RESOURCE_BOOKING_DETAIL_UPDATE_FULFILLED =
  'PUT_RESOURCE_BOOKING_DETAIL_UPDATE_FULFILLED';
const PUT_RESOURCE_BOOKING_DETAIL_UPDATE_PENDING =
  'PUT_RESOURCE_BOOKING_DETAIL_UPDATE_PENDING';
const PUT_RESOURCE_BOOKING_DETAIL_UPDATE_REJECTED =
  'PUT_RESOURCE_BOOKING_DETAIL_UPDATE_REJECTED';

const GET_RESOURCE_BOOKING_WITH_SUMMARY = 'GET_RESOURCE_BOOKING_WITH_SUMMARY';
const GET_RESOURCE_BOOKING_WITH_SUMMARY_FULFILLED =
  'GET_RESOURCE_BOOKING_WITH_SUMMARY_FULFILLED';
const GET_RESOURCE_BOOKING_WITH_SUMMARY_PENDING =
  'GET_RESOURCE_BOOKING_WITH_SUMMARY_PENDING';
const GET_RESOURCE_BOOKING_WITH_SUMMARY_REJECTED =
  'GET_RESOURCE_BOOKING_WITH_SUMMARY_REJECTED';

const GET_DOWNLAOD_RESOURCE_BOOKING_DETAIL_DOCUMENT =
  'GET_DOWNLAOD_RESOURCE_BOOKING_DETAIL_DOCUMENT';
const GET_DOWNLAOD_RESOURCE_BOOKING_DETAIL_DOCUMENT_FULFILLED =
  'GET_DOWNLAOD_RESOURCE_BOOKING_DETAIL_DOCUMENT_FULFILLED';
const GET_DOWNLAOD_RESOURCE_BOOKING_DETAIL_DOCUMENT_PENDING =
  'GET_DOWNLAOD_RESOURCE_BOOKING_DETAIL_DOCUMENT_PENDING';
const GET_DOWNLAOD_RESOURCE_BOOKING_DETAIL_DOCUMENT_REJECTED =
  'GET_DOWNLAOD_RESOURCE_BOOKING_DETAIL_DOCUMENT_REJECTED';

const DELETE_RESOURCE_BOOKING_COLLECTION = 'DELETE_RESOURCE_BOOKING_COLLECTION';
const DELETE_RESOURCE_BOOKING_COLLECTION_FULFILLED =
  'DELETE_RESOURCE_BOOKING_COLLECTION_FULFILLED';
const DELETE_RESOURCE_BOOKING_COLLECTION_PENDING =
  'DELETE_RESOURCE_BOOKING_COLLECTION_PENDING';
const DELETE_RESOURCE_BOOKING_COLLECTION_REJECTED =
  'DELETE_RESOURCE_BOOKING_COLLECTION_REJECTED';
const GET_RESOURCE_LIST = 'GET_RESOURCE_LIST';
const GET_RESOURCE_LIST_FULFILLED = 'GET_RESOURCE_LIST_FULFILLED';
const GET_RESOURCE_LIST_PENDING = 'GET_RESOURCE_LIST_PENDING';
const GET_RESOURCE_LIST_REJECTED = 'GET_RESOURCE_LIST_REJECTED';

const POST_RESOURCE = 'POST_RESOURCE';
const POST_RESOURCE_FULFILLED = 'POST_RESOURCE_FULFILLED';
const POST_RESOURCE_PENDING = 'POST_RESOURCE_PENDING';
const POST_RESOURCE_REJECTED = 'POST_RESOURCE_REJECTED';

const PUT_RESOURCE = 'PUT_RESOURCE';
const PUT_RESOURCE_FULFILLED = 'PUT_RESOURCE_FULFILLED';
const PUT_RESOURCE_PENDING = 'PUT_RESOURCE_PENDING';
const PUT_RESOURCE_REJECTED = 'PUT_RESOURCE_REJECTED';

const GET_RESOURCE_DETAIL_BY_ID = 'GET_RESOURCE_DETAIL_BY_ID';
const GET_RESOURCE_DETAIL_BY_ID_FULFILLED =
  'GET_RESOURCE_DETAIL_BY_ID_FULFILLED';
const GET_RESOURCE_DETAIL_BY_ID_PENDING = 'GET_RESOURCE_DETAIL_BY_ID_PENDING';
const GET_RESOURCE_DETAIL_BY_ID_REJECTED = 'GET_RESOURCE_DETAIL_BY_ID_REJECTED';

const GET_RESOURCE_BOOKING_RESERVATION_BY_RESOURCE_ID =
  'GET_RESOURCE_BOOKING_RESERVATION_BY_RESOURCE_ID';
const GET_RESOURCE_BOOKING_RESERVATION_BY_RESOURCE_ID_FULFILLED =
  'GET_RESOURCE_BOOKING_RESERVATION_BY_RESOURCE_ID_FULFILLED';
const GET_RESOURCE_BOOKING_RESERVATION_BY_RESOURCE_ID_PENDING =
  'GET_RESOURCE_BOOKING_RESERVATION_BY_RESOURCE_ID_PENDING';
const GET_RESOURCE_BOOKING_RESERVATION_BY_RESOURCE_ID_REJECTED =
  'GET_RESOURCE_BOOKING_RESERVATION_BY_RESOURCE_ID_REJECTED';

const POST_RESOURCE_BOOKING_RESERVATION = 'POST_RESOURCE_BOOKING_RESERVATION';
const POST_RESOURCE_BOOKING_RESERVATION_FULFILLED =
  'POST_RESOURCE_BOOKING_RESERVATION_FULFILLED';
const POST_RESOURCE_BOOKING_RESERVATION_PENDING =
  'POST_RESOURCE_BOOKING_RESERVATION_PENDING';
const POST_RESOURCE_BOOKING_RESERVATION_REJECTED =
  'POST_RESOURCE_BOOKING_RESERVATION_REJECTED';

const PUT_RESOURCE_BOOKING_RESERVATION = 'PUT_RESOURCE_BOOKING_RESERVATION';
const PUT_RESOURCE_BOOKING_RESERVATION_FULFILLED =
  'PUT_RESOURCE_BOOKING_RESERVATION_FULFILLED';
const PUT_RESOURCE_BOOKING_RESERVATION_PENDING =
  'PUT_RESOURCE_BOOKING_RESERVATION_PENDING';
const PUT_RESOURCE_BOOKING_RESERVATION_REJECTED =
  'PUT_RESOURCE_BOOKING_RESERVATION_REJECTED';

const DELETE_RESOURCE_BOOKING_RESERVATION =
  'DELETE_RESOURCE_BOOKING_RESERVATION';
const DELETE_RESOURCE_BOOKING_RESERVATION_FULFILLED =
  'DELETE_RESOURCE_BOOKING_RESERVATION_FULFILLED';
const DELETE_RESOURCE_BOOKING_RESERVATION_PENDING =
  'DELETE_RESOURCE_BOOKING_RESERVATION_PENDING';
const DELETE_RESOURCE_BOOKING_RESERVATION_REJECTED =
  'DELETE_RESOURCE_BOOKING_RESERVATION_REJECTED';

const POST_RESOURCE_BOOKING_RESERVATION_PAYMENT =
  'POST_RESOURCE_BOOKING_RESERVATION_PAYMENT';
const POST_RESOURCE_BOOKING_RESERVATION_PAYMENT_FULFILLED =
  'POST_RESOURCE_BOOKING_RESERVATION_PAYMENT_FULFILLED';
const POST_RESOURCE_BOOKING_RESERVATION_PAYMENT_PENDING =
  'POST_RESOURCE_BOOKING_RESERVATION_PAYMENT_PENDING';
const POST_RESOURCE_BOOKING_RESERVATION_PAYMENT_REJECTED =
  'POST_RESOURCE_BOOKING_RESERVATION_PAYMENT_REJECTED';

const PUT_RESOURCE_BOOKING_RESERVATION_PAYMENT =
  'PUT_RESOURCE_BOOKING_RESERVATION_PAYMENT';
const PUT_RESOURCE_BOOKING_RESERVATION_PAYMENT_FULFILLED =
  'PUT_RESOURCE_BOOKING_RESERVATION_PAYMENT_FULFILLED';
const PUT_RESOURCE_BOOKING_RESERVATION_PAYMENT_PENDING =
  'PUT_RESOURCE_BOOKING_RESERVATION_PAYMENT_PENDING';
const PUT_RESOURCE_BOOKING_RESERVATION_PAYMENT_REJECTED =
  'PUT_RESOURCE_BOOKING_RESERVATION_PAYMENT_REJECTED';

const PUT_READY_TO_USE_QTY = 'PUT_READY_TO_USE_QTY';
const PUT_READY_TO_USE_QTY_FULFILLED = 'PUT_READY_TO_USE_QTY_FULFILLED';
const PUT_READY_TO_USE_QTY_PENDING = 'PUT_READY_TO_USE_QTY_PENDING';
const PUT_READY_TO_USE_QTY_REJECTED = 'PUT_READY_TO_USE_QTY';
const SAVE_START_DATE_END_DATE = 'SAVE_START_DATE_END_DATE';
const SAVE_DATA_CANCEL_RESOURCE = 'SAVE_DATA_CANCEL_RESOURCE';
const SAVE_ALLOTMENT = 'SAVE_ALLOTMENT';
const PUT_PAY_RESOURCE_BOOKING = 'PUT_PAY_RESOURCE_BOOKING';
const PUT_PAY_RESOURCE_BOOKING_FULFILLED = 'PUT_PAY_RESOURCE_BOOKING_FULFILLED';
const PUT_PAY_RESOURCE_BOOKING_PENDING = 'PUT_PAY_RESOURCE_BOOKING_PENDING';
const PUT_PAY_RESOURCE_BOOKING_REJECTED = 'PUT_PAY_RESOURCE_BOOKING_REJECTED';
const GET_GENERATE_AR_SUMMARY = 'GET_GENERATE_AR_SUMMARY';
const GET_GENERATE_AR_SUMMARY_FULFILLED = 'GET_GENERATE_AR_SUMMARY_FULFILLED';
const GET_GENERATE_AR_SUMMARY_PENDING = 'GET_GENERATE_AR_SUMMARY_PENDING';
const GET_GENERATE_AR_SUMMARY_REJECTED = 'GET_GENERATE_AR_SUMMARY_REJECTED';

const GET_AR_SUMMARY_LIST = 'GET_AR_SUMMARY_LIST';
const GET_AR_SUMMARY_LIST_FULFILLED = 'GET_AR_SUMMARY_LIST_FULFILLED';
const GET_AR_SUMMARY_LIST_PENDING = 'GET_AR_SUMMARY_LIST_PENDING';
const GET_AR_SUMMARY_LIST_REJECTED = 'GET_AR_SUMMARY_LIST_REJECTED';

const DOWNLOAD_AR_SUMMARY = 'DOWNLOAD_AR_SUMMARY';
const DOWNLOAD_AR_SUMMARY_FULFILLED = 'DOWNLOAD_AR_SUMMARY_FULFILLED';
const DOWNLOAD_AR_SUMMARY_PENDING = 'DOWNLOAD_AR_SUMMARY_PENDING';
const DOWNLOAD_AR_SUMMARY_REJECTED = 'DOWNLOAD_AR_SUMMARY_REJECTED';

const POST_CANCEL_RESOURCE = 'POST_CANCEL_RESOURCE';
const POST_CANCEL_RESOURCE_FULFILLED = 'POST_CANCEL_RESOURCE_FULFILLED';
const POST_CANCEL_RESOURCE_PENDING = 'POST_CANCEL_RESOURCE_PENDING';
const POST_CANCEL_RESOURCE_REJECTED = 'POST_CANCEL_RESOURCE_REJECTED';

const GET_RESOURCE_TYPE_ENUM = 'GET_RESOURCE_TYPE_ENUM';
const GET_RESOURCE_TYPE_ENUM_FULFILLED = 'GET_RESOURCE_TYPE_ENUM_FULFILLED';
const GET_RESOURCE_TYPE_ENUM_PENDING = 'GET_RESOURCE_TYPE_ENUM_PENDING';
const GET_RESOURCE_TYPE_ENUM_REJECTED = 'GET_RESOURCE_TYPE_ENUM_REJECTED';

const GET_ACCOMMODATION_ESTIMATE_PRICE = 'GET_ACCOMMODATION_ESTIMATE_PRICE';
const GET_ACCOMMODATION_ESTIMATE_PRICE_FULFILLED =
  'GET_ACCOMMODATION_ESTIMATE_PRICE_FULFILLED';
const GET_ACCOMMODATION_ESTIMATE_PRICE_PENDING =
  'GET_ACCOMMODATION_ESTIMATE_PRICE_PENDING';
const GET_ACCOMMODATION_ESTIMATE_PRICE_REJECTED =
  'GET_ACCOMMODATION_ESTIMATE_PRICE_REJECTED';

const GET_ACCOMMODATION_ITEM_ESTIMATE_PRICE =
  'GET_ACCOMMODATION_ITEM_ESTIMATE_PRICE';
const GET_ACCOMMODATION_ITEM_ESTIMATE_PRICE_FULFILLED =
  'GET_ACCOMMODATION_ITEM_ESTIMATE_PRICE_FULFILLED';
const GET_ACCOMMODATION_ITEM_ESTIMATE_PRICE_PENDING =
  'GET_ACCOMMODATION_ITEM_ESTIMATE_PRICE_PENDING';
const GET_ACCOMMODATION_ITEM_ESTIMATE_PRICE_REJECTED =
  'GET_ACCOMMODATION_ITEM_ESTIMATE_PRICE_REJECTED';
const RESET_STATUS_RESOURCE = 'RESET_STATUS_RESOURCE';
const RESET_DOWNLOAD_RESOURCE_BOOKING_DETAIL =
  'RESET_DOWNLOAD_RESOURCE_BOOKING_DETAIL';
const RESET_DOWNLOAD_RESOURCE_DETAIL = 'RESET_DOWNLOAD_RESOURCE_DETAIL';

const GET_RESOURCE_BOOKING_BY_TRANSACTION_CODE =
  'GET_RESOURCE_BOOKING_BY_TRANSACTION_CODE';
const GET_RESOURCE_BOOKING_BY_TRANSACTION_CODE_FULFILLED =
  'GET_RESOURCE_BOOKING_BY_TRANSACTION_CODE_FULFILLED';
const GET_RESOURCE_BOOKING_BY_TRANSACTION_CODE_PENDING =
  'GET_RESOURCE_BOOKING_BY_TRANSACTION_CODE_PENDING';
const GET_RESOURCE_BOOKING_BY_TRANSACTION_CODE_REJECTED =
  'GET_RESOURCE_BOOKING_BY_TRANSACTION_CODE_REJECTED';
export {
  //Accomodation List
  GET_ACCOMODATION_LIST,
  GET_ACCOMODATION_LIST_FULFILLED,
  GET_ACCOMODATION_LIST_PENDING,
  GET_ACCOMODATION_LIST_REJECTED,
  RESET_STATUS_ACCOMODATION,
  //Accomodation By Profile Id
  GET_ACCOMODATION_BY_PROFILE_ID,
  GET_ACCOMODATION_BY_PROFILE_ID_FULFILLED,
  GET_ACCOMODATION_BY_PROFILE_ID_PENDING,
  GET_ACCOMODATION_BY_PROFILE_ID_REJECTED,
  //accomodation Item By Profile Id
  GET_ACCOMODATION_ITEM_BY_PROFILE_ID,
  GET_ACCOMODATION_ITEM_BY_PROFILE_ID_FULFILLED,
  GET_ACCOMODATION_ITEM_BY_PROFILE_ID_PENDING,
  GET_ACCOMODATION_ITEM_BY_PROFILE_ID_REJECTED,
  //accomodation Item By Service Item Id
  GET_ACCOMODATION_ITEM_BY_SERVICE_TYPE_ID,
  GET_ACCOMODATION_ITEM_BY_SERVICE_TYPE_ID_FULFILLED,
  GET_ACCOMODATION_ITEM_BY_SERVICE_TYPE_ID_PENDING,
  GET_ACCOMODATION_ITEM_BY_SERVICE_TYPE_ID_REJECTED,
  //TEMPLATE ACCOMODATION
  GET_TEMPLATE_ACCOMODATION,
  GET_TEMPLATE_ACCOMODATION_FULFILLED,
  GET_TEMPLATE_ACCOMODATION_PENDING,
  GET_TEMPLATE_ACCOMODATION_REJECTED,
  //
  GET_TEMPLATE_ACCOMODATION_ITEM_DATA,
  GET_TEMPLATE_ACCOMODATION_ITEM_DATA_FULFILLED,
  GET_TEMPLATE_ACCOMODATION_ITEM_DATA_PENDING,
  GET_TEMPLATE_ACCOMODATION_ITEM_DATA_REJECTED,
  //
  POST_UPLOAD_IMAGE_ACCOMODATION_PROFILE,
  POST_UPLOAD_IMAGE_ACCOMODATION_PROFILE_FULFILLED,
  POST_UPLOAD_IMAGE_ACCOMODATION_PROFILE_PENDING,
  POST_UPLOAD_IMAGE_ACCOMODATION_PROFILE_REJECTED,
  //
  POST_UPLOAD_IMAGE_ACCOMODATION_ITEM,
  POST_UPLOAD_IMAGE_ACCOMODATION_ITEM_FULFILLED,
  POST_UPLOAD_IMAGE_ACCOMODATION_ITEM_PENDING,
  POST_UPLOAD_IMAGE_ACCOMODATION_ITEM_REJECTED,
  //
  GET_ACCOMODATION_ITEM_ALL,
  GET_ACCOMODATION_ITEM_ALL_FULFILLED,
  GET_ACCOMODATION_ITEM_ALL_PENDING,
  GET_ACCOMODATION_ITEM_ALL_REJECTED,
  //
  GET_ACCOMMODATION_BY_CITY,
  GET_ACCOMMODATION_BY_CITY_PENDING,
  GET_ACCOMMODATION_BY_CITY_FULFILLED,
  GET_ACCOMMODATION_BY_CITY_REJECTED,
  //
  GET_ACCOMODATION_ITEM_BY_PROFILE_ID_AND_REQUEST_DATE,
  GET_ACCOMODATION_ITEM_BY_PROFILE_ID_AND_REQUEST_DATE_PENDING,
  GET_ACCOMODATION_ITEM_BY_PROFILE_ID_AND_REQUEST_DATE_FULFILLED,
  GET_ACCOMODATION_ITEM_BY_PROFILE_ID_AND_REQUEST_DATE_REJECTED,
  //
  POST_SET_PRIMARY_IMAGE,
  POST_SET_PRIMARY_IMAGE_FULFILLED,
  POST_SET_PRIMARY_IMAGE_PENDING,
  POST_SET_PRIMARY_IMAGE_REJECTED,
  //
  GET_ACCOMMODATION_WITH_FILTER,
  GET_ACCOMMODATION_WITH_FILTER_FULFILLED,
  GET_ACCOMMODATION_WITH_FILTER_PENDING,
  GET_ACCOMMODATION_WITH_FILTER_REJECTED,
  //
  GET_ROOM_ALLOTMENT_BY_PROFILE_ID,
  GET_ROOM_ALLOTMENT_BY_PROFILE_ID_FULFILLED,
  GET_ROOM_ALLOTMENT_BY_PROFILE_ID_PENDING,
  GET_ROOM_ALLOTMENT_BY_PROFILE_ID_REJECTED,
  //
  POST_ROOM_ALLOTMENT_DAY,
  POST_ROOM_ALLOTMENT_DAY_FULFILLED,
  POST_ROOM_ALLOTMENT_DAY_PENDING,
  POST_ROOM_ALLOTMENT_DAY_REJECTED,
  //
  POST_ROOM_ALLOTMENT_PERIOD,
  POST_ROOM_ALLOTMENT_PERIOD_FULFILLED,
  POST_ROOM_ALLOTMENT_PERIOD_PENDING,
  POST_ROOM_ALLOTMENT_PERIOD_REJECTED,
  //
  GET_REQUEST_ROOM_ALLOTMENT,
  GET_REQUEST_ROOM_ALLOTMENT_FULFILLED,
  GET_REQUEST_ROOM_ALLOTMENT_PENDING,
  GET_REQUEST_ROOM_ALLOTMENT_REJECTED,
  //
  POST_CONFIRM_ROOM_ALLOTMENT,
  POST_CONFIRM_ROOM_ALLOTMENT_FULFILLED,
  POST_CONFIRM_ROOM_ALLOTMENT_PENDING,
  POST_CONFIRM_ROOM_ALLOTMENT_REJECTED,
  //
  GET_LIST_CONFIRM_ROOM_ALLOTMENTS,
  GET_LIST_CONFIRM_ROOM_ALLOTMENTS_FULFILLED,
  GET_LIST_CONFIRM_ROOM_ALLOTMENTS_PENDING,
  GET_LIST_CONFIRM_ROOM_ALLOTMENTS_REJECTED,
  //
  GET_RESEND_REQUEST_CONFIRMATION,
  GET_RESEND_REQUEST_CONFIRMATION_FULFILLED,
  GET_RESEND_REQUEST_CONFIRMATION_PENDING,
  GET_RESEND_REQUEST_CONFIRMATION_REJECTED,
  //
  GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID,
  GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID_FULFILLED,
  GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID_PENDING,
  GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID_REJECTED,
  //
  GET_STOCK_ROOM_ALLOTMENT,
  GET_STOCK_ROOM_ALLOTMENT_FULFILLED,
  GET_STOCK_ROOM_ALLOTMENT_PENDING,
  GET_STOCK_ROOM_ALLOTMENT_REJECTED,
  //
  GET_EXPORT_STOCK_ROOM_ALLOTMENT,
  GET_EXPORT_STOCK_ROOM_ALLOTMENT_FULFILLED,
  GET_EXPORT_STOCK_ROOM_ALLOTMENT_PENDING,
  GET_EXPORT_STOCK_ROOM_ALLOTMENT_REJECTED,
  //DELETE ACCOMODATION
  DELETE_ACCOMODATION_BY_PROFILE_ID,
  DELETE_ACCOMODATION_BY_PROFILE_ID_FULFILLED,
  DELETE_ACCOMODATION_BY_PROFILE_ID_PENDING,
  DELETE_ACCOMODATION_BY_PROFILE_ID_REJECTED,
  //EXPORT ACCOMODATION
  GET_EXPORT_ACCOMODATION,
  GET_EXPORT_ACCOMODATION_FULFILLED,
  GET_EXPORT_ACCOMODATION_PENDING,
  GET_EXPORT_ACCOMODATION_REJECTED,
  // ----------------
  //Customer Profile
  GET_CUSTOMER_PROFILE_LIST,
  GET_CUSTOMER_PROFILE_LIST_FULFILLED,
  GET_CUSTOMER_PROFILE_LIST_PENDING,
  GET_CUSTOMER_PROFILE_LIST_REJECTED,
  RESET_STATUS_CUSTOMER_PROFILE,
  //GET CUSTOMER BY CODE
  GET_CUSTOMER_PROFILE_BY_CODE,
  GET_CUSTOMER_PROFILE_BY_CODE_FULFILLED,
  GET_CUSTOMER_PROFILE_BY_CODE_PENDING,
  GET_CUSTOMER_PROFILE_BY_CODE_REJECTED,
  // GET USER DATA
  GET_USER_DATA_BY_CODE,
  GET_USER_DATA_BY_CODE_FULFILLED,
  GET_USER_DATA_BY_CODE_PENDING,
  GET_USER_DATA_BY_CODE_REJECTED,
  //PUT CUSTOMER PROFILE
  PUT_CUSTOMER_PROFILE,
  PUT_CUSTOMER_PROFILE_FULFILLED,
  PUT_CUSTOMER_PROFILE_PENDING,
  PUT_CUSTOMER_PROFILE_REJECTED,
  //
  GET_BALANCE_HISTORY_BY_CODE,
  GET_BALANCE_HISTORY_BY_CODE_FULFILLED,
  GET_BALANCE_HISTORY_BY_CODE_PENDING,
  GET_BALANCE_HISTORY_BY_CODE_REJECTED,
  //
  POST_BALANCE_DETAIL,
  POST_BALANCE_DETAIL_FULFILLED,
  POST_BALANCE_DETAIL_PENDING,
  POST_BALANCE_DETAIL_REJECTED,
  //
  GET_PAYMENT_SCHEME,
  GET_PAYMENT_SCHEME_FULFILLED,
  GET_PAYMENT_SCHEME_PENDING,
  GET_PAYMENT_SCHEME_REJECTED,
  //
  POST_CUSTOMER_PROFILE,
  POST_CUSTOMER_PROFILE_FULFILLED,
  POST_CUSTOMER_PROFILE_PENDING,
  POST_CUSTOMER_PROFILE_REJECTED,
  //
  GET_USER_PROFILE_ACCOUNT_MANAGER,
  GET_USER_PROFILE_ACCOUNT_MANAGER_PENDING,
  GET_USER_PROFILE_ACCOUNT_MANAGER_FULFILLED,
  GET_USER_PROFILE_ACCOUNT_MANAGER_REJECTED,
  //
  GET_USER_PROFILE_ACCOUNT_MANAGER_BY_COMPANY,
  GET_USER_PROFILE_ACCOUNT_MANAGER_BY_COMPANY_PENDING,
  GET_USER_PROFILE_ACCOUNT_MANAGER_BY_COMPANY_FULFILLED,
  GET_USER_PROFILE_ACCOUNT_MANAGER_BY_COMPANY_REJECTED,
  //
  DELETE_USER_PROFILE,
  DELETE_USER_PROFILE_FULFILLED,
  DELETE_USER_PROFILE_PENDING,
  DELETE_USER_PROFILE_REJECTED,
  //
  POST_USER_PROFILE_ACTIVATE,
  POST_USER_PROFILE_ACTIVATE_FULFILLED,
  POST_USER_PROFILE_ACTIVATE_PENDING,
  POST_USER_PROFILE_ACTIVATE_REJECTED,
  //
  POST_CREATE_CUSTOMER_ON_BE_HALF,
  POST_CREATE_CUSTOMER_ON_BE_HALF_FULFILLED,
  POST_CREATE_CUSTOMER_ON_BE_HALF_PENDING,
  POST_CREATE_CUSTOMER_ON_BE_HALF_REJECTED,
  //
  GET_PAYMENT_SCHEME_BY_ID,
  GET_PAYMENT_SCHEME_BY_ID_FULFILLED,
  GET_PAYMENT_SCHEME_BY_ID_PENDING,
  GET_PAYMENT_SCHEME_BY_ID_REJECTED,
  //
  POST_PAYMENT_SCHEME,
  POST_PAYMENT_SCHEME_FULFILLED,
  POST_PAYMENT_SCHEME_PENDING,
  POST_PAYMENT_SCHEME_REJECTED,
  //
  PUT_PAYMENT_SCHEME,
  PUT_PAYMENT_SCHEME_FULFILLED,
  PUT_PAYMENT_SCHEME_PENDING,
  PUT_PAYMENT_SCHEME_REJECTED,
  //
  POST_PAYMENT_SCHEME_DETAIL,
  POST_PAYMENT_SCHEME_DETAIL_FULFILLED,
  POST_PAYMENT_SCHEME_DETAIL_PENDING,
  POST_PAYMENT_SCHEME_DETAIL_REJECTED,
  //
  PUT_PAYMENT_SCHEME_DETAIL,
  PUT_PAYMENT_SCHEME_DETAIL_FULFILLED,
  PUT_PAYMENT_SCHEME_DETAIL_PENDING,
  PUT_PAYMENT_SCHEME_DETAIL_REJECTED,
  //
  DELETE_PAYMENT_SCHEME_DETAIL,
  DELETE_PAYMENT_SCHEME_DETAIL_FULFILLED,
  DELETE_PAYMENT_SCHEME_DETAIL_PENDING,
  DELETE_PAYMENT_SCHEME_DETAIL_REJECTED,
  //
  GET_PAYMENT_SCHEME_DETAIL_BY_ID,
  GET_PAYMENT_SCHEME_DETAIL_BY_ID_FULFILLED,
  GET_PAYMENT_SCHEME_DETAIL_BY_ID_PENDING,
  GET_PAYMENT_SCHEME_DETAIL_BY_ID_REJECTED,
  //Country
  GET_COUNTRY,
  GET_COUNTRY_FULFILLED,
  GET_COUNTRY_PENDING,
  GET_COUNTRY_REJECTED,
  RESET_COUNTRY,
  //GET COUNTRY
  GET_COUNTRY_LIST,
  GET_COUNTRY_LIST_FULFILLED,
  GET_COUNTRY_LIST_PENDING,
  GET_COUNTRY_LIST_REJECTED,
  //GET BY ID COUNTRY
  GET_COUNTRY_BY_ID,
  GET_COUNTRY_BY_ID_FULFILLED,
  GET_COUNTRY_BY_ID_PENDING,
  GET_COUNTRY_BY_ID_REJECTED,
  //
  GET_MASTER_REFERENCE,
  GET_MASTER_REFERENCE_FULFILLED,
  GET_MASTER_REFERENCE_PENDING,
  GET_MASTER_REFERENCE_REJECTED,
  //
  RESET_MASTER_REFERENCE,
  //
  GET_CANCELLATION_REASON_BY_TYPE_REFERENCE,
  GET_CANCELLATION_REASON_BY_TYPE_REFERENCE_FULFILLED,
  GET_CANCELLATION_REASON_BY_TYPE_REFERENCE_PENDING,
  GET_CANCELLATION_REASON_BY_TYPE_REFERENCE_REJECTED,
  //
  RESET_CANCELLATION_REASON_BY_TYPE_REFERENCE,
  //Area By City
  GET_AREA_BY_CITY,
  GET_AREA_BY_CITY_FULFILLED,
  GET_AREA_BY_CITY_PENDING,
  GET_AREA_BY_CITY_REJECTED,
  //
  RESET_AREA,
  //GET AREA LIST
  GET_AREA_LIST,
  GET_AREA_LIST_FULFILLED,
  GET_AREA_LIST_PENDING,
  GET_AREA_LIST_REJECTED,
  // city
  GET_DEST_CITY_BY_REGION,
  GET_DEST_CITY_BY_REGION_PENDING,
  GET_DEST_CITY_BY_REGION_FULFILLED,
  GET_DEST_CITY_BY_REGION_REJECTED,
  //
  GET_ORIGIN_CITY_BY_REGION,
  GET_ORIGIN_CITY_BY_REGION_PENDING,
  GET_ORIGIN_CITY_BY_REGION_REJECTED,
  GET_ORIGIN_CITY_BY_REGION_FULFILLED,
  //
  RESET_CITY,
  // GET CITY LIST
  GET_CITY_LIST,
  GET_CITY_LIST_FULFILLED,
  GET_CITY_LIST_PENDING,
  GET_CITY_LIST_REJECTED,
  //
  GET_CITY_IN_COUNTRY,
  GET_CITY_IN_COUNTRY_FULFILLED,
  GET_CITY_IN_COUNTRY_PENDING,
  GET_CITY_IN_COUNTRY_REJECTED,
  //City By Region
  GET_CITY_BY_REGION,
  GET_CITY_BY_REGION_FULFILLED,
  GET_CITY_BY_REGION_PENDING,
  GET_CITY_BY_REGION_REJECTED,
  //
  RESET_REGION,
  // GET REGION LIST
  GET_REGION_LIST,
  GET_REGION_LIST_FULFILLED,
  GET_REGION_LIST_PENDING,
  GET_REGION_LIST_REJECTED,
  //Region By Country
  GET_REGION_BY_COUNTRY,
  GET_REGION_BY_COUNTRY_FULFILLED,
  GET_REGION_BY_COUNTRY_PENDING,
  GET_REGION_BY_COUNTRY_REJECTED,
  //Excursion
  GET_EXCURSION_PROFILES,
  GET_EXCURSION_PROFILES_FULFILLED,
  GET_EXCURSION_PROFILES_PENDING,
  GET_EXCURSION_PROFILES_REJECTED,
  //
  GET_EXCURSION_PROFILE,
  GET_EXCURSION_PROFILE_FULFILLED,
  GET_EXCURSION_PROFILE_PENDING,
  GET_EXCURSION_PROFILE_REJECTED,
  //
  RESET_STATUS_EXCURSION,
  //
  GET_EXCURSION_TYPE,
  GET_EXCURSION_TYPE_PENDING,
  GET_EXCURSION_TYPE_FULFILLED,
  GET_EXCURSION_TYPE_REJECTED,
  //
  GET_SERVICE_PRICE,
  GET_SERVICE_PRICE_PENDING,
  GET_SERVICE_PRICE_FULFILLED,
  GET_SERVICE_PRICE_REJECTED,
  //
  GET_TEMPLATE_EXCURSION_OBJECT_DATA,
  GET_TEMPLATE_EXCURSION_OBJECT_DATA_FULFILLED,
  GET_TEMPLATE_EXCURSION_OBJECT_DATA_PENDING,
  GET_TEMPLATE_EXCURSION_OBJECT_DATA_REJECTED,
  //
  GET_TEMPLATE_EXCURSION_PRICE_DATA,
  GET_TEMPLATE_EXCURSION_PRICE_DATA_FULFILLED,
  GET_TEMPLATE_EXCURSION_PRICE_DATA_PENDING,
  GET_TEMPLATE_EXCURSION_PRICE_DATA_REJECTED,
  //
  GET_EXCURSION_PROFILE_BY_CITY,
  GET_EXCURSION_PROFILE_BY_CITY_PENDING,
  GET_EXCURSION_PROFILE_BY_CITY_FULFILLED,
  GET_EXCURSION_PROFILE_BY_CITY_REJECTED,
  //
  GET_CUSTOMER_PROFILE_FOR_SALES,
  GET_CUSTOMER_PROFILE_FOR_SALES_FULFILLED,
  GET_CUSTOMER_PROFILE_FOR_SALES_PENDING,
  GET_CUSTOMER_PROFILE_FOR_SALES_REJECTED,
  //
  GET_USER_DATA_BY_CODE_FOR_SALES,
  GET_USER_DATA_BY_CODE_FOR_SALES_FULFILLED,
  GET_USER_DATA_BY_CODE_FOR_SALES_PENDING,
  GET_USER_DATA_BY_CODE_FOR_SALES_REJECTED,
  //
  GET_SUPPLIER_LIST,
  GET_SUPPLIER_LIST_FULFILLED,
  GET_SUPPLIER_LIST_PENDING,
  GET_SUPPLIER_LIST_REJECTED,
  //
  GET_EXPORT_DATA_ITEM_BY_ID,
  GET_EXPORT_DATA_ITEM_BY_ID_FULFILLED,
  GET_EXPORT_DATA_ITEM_BY_ID_PENDING,
  GET_EXPORT_DATA_ITEM_BY_ID_REJECTED,
  //
  GET_LIST_TOTAL_EMPLOYEE,
  GET_LIST_TOTAL_EMPLOYEE_FULFILLED,
  GET_LIST_TOTAL_EMPLOYEE_PENDING,
  GET_LIST_TOTAL_EMPLOYEE_REJECTED,
  GET_GENERATE_RESOURCE_BOOKING,
  GET_GENERATE_RESOURCE_BOOKING_FULFILLED,
  GET_GENERATE_RESOURCE_BOOKING_PENDING,
  GET_GENERATE_RESOURCE_BOOKING_REJECTED,
  GET_DOWNLOAD_RESOURCE_BOOKING_DETAIL,
  GET_DOWNLOAD_RESOURCE_BOOKING_DETAIL_FULFILLED,
  GET_DOWNLOAD_RESOURCE_BOOKING_DETAIL_PENDING,
  GET_DOWNLOAD_RESOURCE_BOOKING_DETAIL_REJECTED,
  PUT_RESOURCE_BOOKING_DETAIL_UPDATE,
  PUT_RESOURCE_BOOKING_DETAIL_UPDATE_FULFILLED,
  PUT_RESOURCE_BOOKING_DETAIL_UPDATE_PENDING,
  PUT_RESOURCE_BOOKING_DETAIL_UPDATE_REJECTED,
  GET_RESOURCE_BOOKING_WITH_SUMMARY,
  GET_RESOURCE_BOOKING_WITH_SUMMARY_FULFILLED,
  GET_RESOURCE_BOOKING_WITH_SUMMARY_PENDING,
  GET_RESOURCE_BOOKING_WITH_SUMMARY_REJECTED,
  GET_DOWNLAOD_RESOURCE_BOOKING_DETAIL_DOCUMENT,
  GET_DOWNLAOD_RESOURCE_BOOKING_DETAIL_DOCUMENT_FULFILLED,
  GET_DOWNLAOD_RESOURCE_BOOKING_DETAIL_DOCUMENT_PENDING,
  GET_DOWNLAOD_RESOURCE_BOOKING_DETAIL_DOCUMENT_REJECTED,
  DELETE_RESOURCE_BOOKING_COLLECTION,
  DELETE_RESOURCE_BOOKING_COLLECTION_FULFILLED,
  DELETE_RESOURCE_BOOKING_COLLECTION_PENDING,
  DELETE_RESOURCE_BOOKING_COLLECTION_REJECTED,
  GET_RESOURCE_LIST,
  GET_RESOURCE_LIST_FULFILLED,
  GET_RESOURCE_LIST_PENDING,
  GET_RESOURCE_LIST_REJECTED,
  POST_RESOURCE,
  POST_RESOURCE_FULFILLED,
  POST_RESOURCE_PENDING,
  POST_RESOURCE_REJECTED,
  PUT_RESOURCE,
  PUT_RESOURCE_FULFILLED,
  PUT_RESOURCE_PENDING,
  PUT_RESOURCE_REJECTED,
  GET_RESOURCE_DETAIL_BY_ID,
  GET_RESOURCE_DETAIL_BY_ID_FULFILLED,
  GET_RESOURCE_DETAIL_BY_ID_PENDING,
  GET_RESOURCE_DETAIL_BY_ID_REJECTED,
  GET_RESOURCE_BOOKING_RESERVATION_BY_RESOURCE_ID,
  GET_RESOURCE_BOOKING_RESERVATION_BY_RESOURCE_ID_FULFILLED,
  GET_RESOURCE_BOOKING_RESERVATION_BY_RESOURCE_ID_PENDING,
  GET_RESOURCE_BOOKING_RESERVATION_BY_RESOURCE_ID_REJECTED,
  POST_RESOURCE_BOOKING_RESERVATION,
  POST_RESOURCE_BOOKING_RESERVATION_FULFILLED,
  POST_RESOURCE_BOOKING_RESERVATION_PENDING,
  POST_RESOURCE_BOOKING_RESERVATION_REJECTED,
  PUT_RESOURCE_BOOKING_RESERVATION,
  PUT_RESOURCE_BOOKING_RESERVATION_FULFILLED,
  PUT_RESOURCE_BOOKING_RESERVATION_PENDING,
  PUT_RESOURCE_BOOKING_RESERVATION_REJECTED,
  DELETE_RESOURCE_BOOKING_RESERVATION,
  DELETE_RESOURCE_BOOKING_RESERVATION_FULFILLED,
  DELETE_RESOURCE_BOOKING_RESERVATION_PENDING,
  DELETE_RESOURCE_BOOKING_RESERVATION_REJECTED,
  POST_RESOURCE_BOOKING_RESERVATION_PAYMENT,
  POST_RESOURCE_BOOKING_RESERVATION_PAYMENT_FULFILLED,
  POST_RESOURCE_BOOKING_RESERVATION_PAYMENT_PENDING,
  POST_RESOURCE_BOOKING_RESERVATION_PAYMENT_REJECTED,
  PUT_RESOURCE_BOOKING_RESERVATION_PAYMENT,
  PUT_RESOURCE_BOOKING_RESERVATION_PAYMENT_FULFILLED,
  PUT_RESOURCE_BOOKING_RESERVATION_PAYMENT_PENDING,
  PUT_RESOURCE_BOOKING_RESERVATION_PAYMENT_REJECTED,
  PUT_READY_TO_USE_QTY,
  PUT_READY_TO_USE_QTY_FULFILLED,
  PUT_READY_TO_USE_QTY_PENDING,
  PUT_READY_TO_USE_QTY_REJECTED,
  SAVE_START_DATE_END_DATE,
  SAVE_DATA_CANCEL_RESOURCE,
  SAVE_ALLOTMENT,
  PUT_PAY_RESOURCE_BOOKING,
  PUT_PAY_RESOURCE_BOOKING_FULFILLED,
  PUT_PAY_RESOURCE_BOOKING_PENDING,
  PUT_PAY_RESOURCE_BOOKING_REJECTED,
  GET_GENERATE_AR_SUMMARY,
  GET_GENERATE_AR_SUMMARY_FULFILLED,
  GET_GENERATE_AR_SUMMARY_PENDING,
  GET_GENERATE_AR_SUMMARY_REJECTED,
  //
  GET_AR_SUMMARY_LIST,
  GET_AR_SUMMARY_LIST_FULFILLED,
  GET_AR_SUMMARY_LIST_PENDING,
  GET_AR_SUMMARY_LIST_REJECTED,
  //
  DOWNLOAD_AR_SUMMARY,
  DOWNLOAD_AR_SUMMARY_FULFILLED,
  DOWNLOAD_AR_SUMMARY_PENDING,
  DOWNLOAD_AR_SUMMARY_REJECTED,
  POST_CANCEL_RESOURCE,
  POST_CANCEL_RESOURCE_FULFILLED,
  POST_CANCEL_RESOURCE_PENDING,
  POST_CANCEL_RESOURCE_REJECTED,
  GET_RESOURCE_TYPE_ENUM,
  GET_RESOURCE_TYPE_ENUM_FULFILLED,
  GET_RESOURCE_TYPE_ENUM_PENDING,
  GET_RESOURCE_TYPE_ENUM_REJECTED,
  GET_ACCOMMODATION_ESTIMATE_PRICE,
  GET_ACCOMMODATION_ESTIMATE_PRICE_FULFILLED,
  GET_ACCOMMODATION_ESTIMATE_PRICE_PENDING,
  GET_ACCOMMODATION_ESTIMATE_PRICE_REJECTED,
  GET_ACCOMMODATION_ITEM_ESTIMATE_PRICE,
  GET_ACCOMMODATION_ITEM_ESTIMATE_PRICE_FULFILLED,
  GET_ACCOMMODATION_ITEM_ESTIMATE_PRICE_PENDING,
  GET_ACCOMMODATION_ITEM_ESTIMATE_PRICE_REJECTED,
  RESET_STATUS_RESOURCE,
  RESET_DOWNLOAD_RESOURCE_BOOKING_DETAIL,
  RESET_DOWNLOAD_RESOURCE_DETAIL,
  GET_RESOURCE_BOOKING_BY_TRANSACTION_CODE,
  GET_RESOURCE_BOOKING_BY_TRANSACTION_CODE_FULFILLED,
  GET_RESOURCE_BOOKING_BY_TRANSACTION_CODE_PENDING,
  GET_RESOURCE_BOOKING_BY_TRANSACTION_CODE_REJECTED,
};
