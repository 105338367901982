import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SegmentPaymentRequestDetail from './components/SegmentPaymentRequestDetail';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { getPaymentHistoryByIdApi } from '../../api/getPaymentHistoryByIdApi';
import useApiCallForDetail from './hooks/useApiCallDetail';
import ModalPaymentRequest from '../../components/modal/modalPaymentRequest';
import ModalPaymentProof from './components/ModalPaymentProof';

const PaymentRequestDetail = props => {
  //=======================initial=======================
  const [changePage, setChangePage] = useState({
    isChangePage: false,
    link: '',
  });
  const [totalPayableValue, setTotalPayable] = useState(0);
  const [openPay, setOpenPay] = useState(false);
  const [cashBack, setCashBack] = useState(0);
  const [reservationPaymentId, setReservationPaymentId] = useState(0);
  const [selectedPaymentRequest, setSelectedPaymentRequest] = useState(null);
  const [modalPaymentProof, setModalPaymentProof] = useState({
    isOpen: false,
    imageUrl: '',
  });
  const [
    historyDetailLoading,
    historyDetailData,
    loadData,
  ] = useApiCallForDetail(getPaymentHistoryByIdApi, props.match.params.id);

  const handleChangePayable = value => {
    setTotalPayable(value);
  };

  const handleOpenModalPay = (e, { value }) => {
    setCashBack(value ? value.BalanceDue : 0);
    setReservationPaymentId(value ? value.Id : 0);
    setSelectedPaymentRequest(historyDetailData);
    setOpenPay(true);
  };
  const handleSeePaymentProof = (e, { value }) => {
    setModalPaymentProof({ isOpen: true, imageUrl: value.ImageUrl });
  };
  const handleCloseModalPaymentProof = () => {
    setModalPaymentProof({ isOpen: false, imageUrl: '' });
  };
  const handleClose = () => {
    setOpenPay(false);
    // setOpenDateRange(false);
  };

  const handleBack = () => {
    setChangePage({
      isChangePage: true,
      link: `/request-payment-list`,
    });
  };

  if (changePage.isChangePage) {
    return <Redirect to={changePage.link} />;
  } else
    return (
      <div>
        <SegmentPaymentRequestDetail
          data={historyDetailData}
          loading={historyDetailLoading}
          loadData={loadData}
          handleChangePayable={handleChangePayable}
          totalPayableValue={totalPayableValue}
          handleOpenModalPay={handleOpenModalPay}
          handleBack={handleBack}
          handleSeePaymentProof={handleSeePaymentProof}
        />
        <ModalPaymentRequest
          openModal={openPay}
          handleClose={handleClose}
          cashBack={cashBack}
          paymentId={reservationPaymentId}
          selectedPaymentRequest={selectedPaymentRequest}
        />
        <ModalPaymentProof
          isOpen={modalPaymentProof.isOpen}
          imageUrl={modalPaymentProof.imageUrl}
          handleCloseModalPaymentProof={handleCloseModalPaymentProof}
        />
      </div>
    );
};

PaymentRequestDetail.propTypes = {
  data: PropTypes.array,
  match: PropTypes.shape({
    params: PropTypes.string,
  }),
};

export default withRouter(React.memo(PaymentRequestDetail));
