const setRestaurantPrice = (details, price, serviceItemId) => {
  let data = {
    Id: details.Id,
    CutOffDay: price.CutOffDay,
    Cost: details.Cost,
    StartDate: price.StartDate,
    EndDate: price.EndDate,
    UomId: 'PCS',
    TaxTypeId: 0,
    Currency: details.Currency,
    Rank: price.Rank,
    ServiceItemId: serviceItemId,
    ServicePriceType: price.ServicePriceType,
    MinimumPax: details.MinPax,
    Description: '',
  };
  return data;
};

const setSupplierPriceForFilter = item => {
  let data = {
    Id: item.Id,
    Rank: item.Rank,
    ServicePriceType: item.ServiceType, //.split('_').join(' '),
    ServiceItemId: item.ServiceItemId,
    StartDate: item.StartDate,
    EndDate: item.EndDate,
    CutOffDay: item.CutOffDay,
    Currency: item.Currency,
    Cost: item.Cost,
    PriceDetails: [],
    Description: item.Description,
    MinPax: item.CalculationBase.Code,
    Status: 'Edit',
  };
  return data;
};
export { setRestaurantPrice, setSupplierPriceForFilter };
