//GET HISTORY_TRANSACTION
const POST_SUPPLIER = 'POST_SUPPLIER';
const POST_SUPPLIER_FULFILLED = 'POST_SUPPLIER_FULFILLED';
const POST_SUPPLIER_PENDING = 'POST_SUPPLIER_PENDING';
const POST_SUPPLIER_REJECTED = 'POST_SUPPLIER_REJECTED';
const RESET_SUPPLIER = 'RESET_SUPPLIER';

const PUT_ACCOMMODATION_SUPPLIER = 'PUT_ACCOMMODATION_SUPPLIER';
const PUT_ACCOMMODATION_SUPPLIER_FULFILLED =
  'PUT_ACCOMMODATION_SUPPLIER_FULFILLED';
const PUT_ACCOMMODATION_SUPPLIER_PENDING = 'PUT_ACCOMMODATION_SUPPLIER_PENDING';
const PUT_ACCOMMODATION_SUPPLIER_REJECTED =
  'PUT_ACCOMMODATION_SUPPLIER_REJECTED';

export {
  POST_SUPPLIER,
  POST_SUPPLIER_FULFILLED,
  POST_SUPPLIER_PENDING,
  POST_SUPPLIER_REJECTED,
  RESET_SUPPLIER,
  PUT_ACCOMMODATION_SUPPLIER,
  PUT_ACCOMMODATION_SUPPLIER_FULFILLED,
  PUT_ACCOMMODATION_SUPPLIER_PENDING,
  PUT_ACCOMMODATION_SUPPLIER_REJECTED,
};
