import React from 'react';
import { Table, Checkbox } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import LoaderModal from '../../../../components/modal/loaderModal';
import ActionButton from '../button/ActionButtonBooking';
import { Link } from 'react-router-dom';
import IndexPagination from '../../../../components/genericFunction/indexPagination';
const TableOnHoldTransaction = props => (
  <Table basic="very" selectable>
    <LoaderModal loading={props.loading} />
    <Table.Header>
      <Table.Row>
        {props.headerTable.map((headTableContent, index) => {
          if (headTableContent === 'checkHold') {
            return (
              <Table.HeaderCell key={index}>
                <Checkbox name="checkAll" onChange={props.onChangeHold} />
              </Table.HeaderCell>
            );
          }
          return (
            <Table.HeaderCell key={index}>{headTableContent}</Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {props.listData ? (
        props.listData.map((onHold, idx) => {
          return (
            <Table.Row key={IndexPagination(5, props.activePage, idx)}>
              <Table.Cell>
                <Checkbox
                  name={idx}
                  onChange={props.onChangeHold}
                  id={onHold.Id}
                  key={idx}
                  checked={onHold.checkHold}
                />
              </Table.Cell>
              <Table.Cell component="th" scope="row" width={1}>
                <Link
                  // to={`/tour-transaction/edit/${
                  //   onHold.PackageType === 0 ? 'Custom' : 'Fixed'
                  // }/${onHold.Id}`}
                  to={`/tour-transaction/edit/${
                    onHold.PackageType === 0
                      ? 'Custom'
                      : onHold.PackageType === 1
                      ? 'Ready'
                      : onHold.PackageType === 2
                      ? 'Fixed'
                      : 'FixedDateVariable'
                  }/${onHold.Id}`}
                >
                  {onHold.Id}
                </Link>
              </Table.Cell>
              <Table.Cell>
                <Link
                  to={`/tour-transaction/edit/${
                    onHold.PackageType === 0
                      ? 'Custom'
                      : onHold.PackageType === 1
                      ? 'Ready'
                      : onHold.PackageType === 2
                      ? 'Fixed'
                      : 'FixedDateVariable'
                  }/${onHold.Id}`}
                >
                  {onHold.Title}
                </Link>
              </Table.Cell>
              <Table.Cell>
                {/* <Link
                  to={`/Customer-Profile-Detail/detail/${onHold.CompanyCode}`}
                > */}
                {onHold.CompanyName}
                {/* </Link> */}

                {}
              </Table.Cell>
              <Table.Cell>
                {/* {onHold.AdultPax + onHold.ChildPax || onHold.TotalGuest} */}
                {onHold.TotalGuest}
              </Table.Cell>
              {/* <Table.Cell /> */}
              <ActionButton
                id={onHold.Id}
                handleClick={props.actionTransaction}
                value={onHold.Status}
                disabled={onHold.checkHold}
              />
            </Table.Row>
          );
        })
      ) : (
        <Table.Row>
          <Table.Cell colSpan="5" textAlign="center">
            No Data
          </Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  </Table>
);
TableOnHoldTransaction.propTypes = {
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  actionTransaction: PropTypes.func,
  loading: PropTypes.bool,
  openDetailTransaction: PropTypes.func,
  onChangeHold: PropTypes.func,
  activePage: PropTypes.number,
};
export default TableOnHoldTransaction;
