import React, { Component } from 'react';
import { Table, Button, Checkbox } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Pagination from '../../../../components/common/pagination';
import IndexPagination from '../../../../components/genericFunction/indexPagination';
import { getNumberFromTo } from '../../../../scripts/pagination';
class TablePaymentSchemeDetail extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
    };
  }
  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });

  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  render() {
    // const tablePaymentSchemeDetail = props => {

    const props = this.props;

    let { numbersFrom, numbersTo } = getNumberFromTo(
      props.listData,
      props.activePage,
      3
    );
    return (
      <Table basic="very" selectable>
        <Table.Header>
          <Table.Row>
            {props.headerTable.map((headTableContent, index) => {
              return (
                <Table.HeaderCell key={index}>
                  {headTableContent}
                </Table.HeaderCell>
              );
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {props.listData &&
            props.listData.map((scheme, idx) => {
              const idxPagination = IndexPagination(3, props.activePage, idx);
              return (
                <Table.Row key={idxPagination}>
                  <Table.Cell component="th" scope="row" width={3}>
                    {scheme.Description}
                  </Table.Cell>
                  <Table.Cell>{scheme.Percentage}</Table.Cell>
                  <Table.Cell>{scheme.IntervalDays}</Table.Cell>
                  <Table.Cell>
                    <Checkbox checked={scheme.isLockGuest} />
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      content="Edit"
                      primary
                      onClick={props.handleEdit}
                      id={scheme.Id}
                      value={idx}
                      name={scheme.Description}
                    />
                    <Button
                      content="Delete"
                      negative
                      onClick={props.handleDelete}
                      id={scheme.Id}
                      value={idx}
                      name={scheme.Description}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
        <Pagination
          pagedData={props.pagedData}
          numbersTo={numbersTo}
          numbersFrom={numbersFrom}
          data={props.listData}
          pageChange={props.pageChange}
          activePage={props.activePage}
        />
      </Table>
    );
  }
}

TablePaymentSchemeDetail.propTypes = {
  numbersFrom: PropTypes.number,
  numbersTo: PropTypes.number,
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  activePage: PropTypes.string,
  pagedData: PropTypes.array,
  pageChange: PropTypes.func,
  idxPagination: PropTypes.number,
};
export default TablePaymentSchemeDetail;
