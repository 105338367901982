import React from 'react';
import PropTypes from 'prop-types';
import { Input, Icon, Label, Form } from 'semantic-ui-react';
// const GOLD_COLOR = '#e6ca6b';
// const buttonCircle = {
//   color: GOLD_COLOR,
//   border: '1px solid ' + GOLD_COLOR,
//   backgroundColor: 'white',
// };
// const buttonCircle = {
//   // padding: '10px 12px',
//   margin: 0,
//   // color: GOLD_COLOR,
//   // border: `1px solid ${GOLD_COLOR}`,
//   background: 'white',
// };

class InputAmount extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { value: 0 };
  }

  render() {
    const {
      value,
      labelStyle,
      label,
      name,
      objectname,
      handleOnChange,
      disabled,
      handleDecrease,
      handleIncrease,
    } = this.props;

    return (
      <Form.Field>
        <label style={labelStyle}>{label}</label>
        <Input
          labelPosition="right"
          // placeholder="Amount"
          fluid
          style={{
            margin: 0,
            cursor: 'pointer',
            borderLeft: 'none',
            borderRight: 'none',
          }}
          type={this.props.typeInput}
          min={1}
          value={value}
          name={name}
          objectname={objectname}
          onChange={disabled ? null : handleOnChange}
          disabled={disabled}
        >
          <Label
            basic
            style={{ margin: 0, cursor: 'pointer', borderRight: 'none' }}
            value={value}
            name={name}
            objectname={objectname}
            onClick={disabled ? null : handleDecrease}
            disabled={disabled}
          >
            <Icon style={{ margin: 0 }} name="minus" color="red" />
          </Label>
          <input style={{ textAlign: 'center' }} />
          <Label
            basic
            style={{ margin: 0, cursor: 'pointer', borderLeft: 'none' }}
            value={value}
            name={name}
            objectname={objectname}
            onClick={disabled ? null : handleIncrease}
            disabled={disabled}
          >
            <Icon style={{ margin: 0 }} name="plus" color="green" />
          </Label>
        </Input>
      </Form.Field>
    );
  }
}
InputAmount.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
  label: PropTypes.string,
  handleIncrease: PropTypes.func,
  handleOnChange: PropTypes.func,
  handleDecrease: PropTypes.func,
  name: PropTypes.string,
  objectname: PropTypes.string,
  disabled: PropTypes.bool,
  labelStyle: PropTypes.object,
  inputStyle: PropTypes.object,
  typeInput: PropTypes.string,
};
export default InputAmount;
