// import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { Popup, Input, Grid, Button, Icon, Message } from 'semantic-ui-react';
// import { addZero } from '../genericFunction/moment';
// import styles from './styles';
// class InputText extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       hours: props.hours,
//       minutes: props.minutes,
//       time: '',
//       isEmpty: false,
//     };
//   }
//   handleIncrease = (e, { name, value }) => {
//     value = value === '' ? '00' : value;
//     const newValue =
//       name === 'hours'
//         ? +value + 1 > 23
//           ? +value
//           : +value + 1
//         : +value + 1 > 59
//           ? +value
//           : +value + 1;
//     this.setState({ ...this.state, [name]: addZero(newValue), isEmpty: false });
//   };
//   handleDecrease = (e, { name, value }) => {
//     value = value === '' ? '00' : value;
//     const newValue = +value - 1 < 0 ? 0 : +value - 1;
//     this.setState({ ...this.state, [name]: addZero(newValue), isEmpty: false });
//   };
//   handleOnChange = (e, { name, value }) => {
//     const oldVal = this.state[name];
//     const numbers = /^[0-9]+$/;
//     let newValue = !+value.match(numbers) && +value !== 0 ? oldVal : value;
//     newValue =
//       newValue.length < 2
//         ? newValue
//         : newValue.length > 2
//           ? oldVal
//           : addZero(newValue);
//     newValue =
//       name === 'hours'
//         ? +newValue > 23
//           ? oldVal
//           : newValue
//         : +newValue > 59
//           ? oldVal
//           : newValue;
//     this.setState({
//       ...this.state,
//       [name]: newValue,
//       isEmpty: newValue.length < 1 ? true : false,
//     });
//   };
//   handleSetTime = () => {
//     const { hours, minutes } = this.state;
//     if (!hours || !minutes) {
//       this.setState({ ...this.state, isEmpty: true });
//     } else {
//       this.props.handleSetTime(
//         `${addZero(hours)}:${addZero(minutes)}`,
//         hours,
//         minutes
//       );
//       this.props.onClose();
//     }
//   };
//   render() {
//     const { hours, minutes, isEmpty } = this.state;
//     return (
//       <Grid centered>
//         <Grid.Row style={{ padding: 0 }}>
//           <Grid.Column width={8} textAlign="center">
//             <Button
//               basic
//               icon
//               name="hours"
//               value={hours}
//               onClick={this.handleIncrease}
//               style={{ boxShadow: '0 0 0 transparent' }}
//             >
//               <Icon name="angle up" size="large" />
//             </Button>
//             <Input
//               type="number"
//               name="hours"
//               fluid
//               min={0}
//               max={23}
//               value={hours}
//               onChange={this.handleOnChange}
//             >
//               <input
//                 style={{ textAlign: 'center' }}
//                 maxLength="2"
//                 name="hours"
//                 max="12"
//               />
//             </Input>
//             <Button
//               basic
//               icon
//               name="hours"
//               value={hours}
//               onClick={this.handleDecrease}
//               style={{ boxShadow: '0 0 0 transparent' }}
//             >
//               <Icon name="angle down" size="large" />
//             </Button>
//           </Grid.Column>
//           <span
//             style={{
//               position: 'absolute',
//               marginTop: '47px',
//             }}
//           >
//             :
//           </span>
//           <Grid.Column width={8} textAlign="center">
//             <Button
//               basic
//               icon
//               name="minutes"
//               value={minutes}
//               onClick={this.handleIncrease}
//               style={{ boxShadow: '0 0 0 transparent' }}
//             >
//               <Icon name="angle up" size="large" />
//             </Button>
//             <Input
//               type="number"
//               value={minutes}
//               name="minutes"
//               onChange={this.handleOnChange}
//               fluid
//               min={0}
//               max={59}
//             >
//               <input
//                 style={{ textAlign: 'center' }}
//                 maxLength="2"
//                 value={minutes}
//                 name="minutes"
//               />
//             </Input>
//             <Button
//               basic
//               icon
//               name="minutes"
//               value={minutes}
//               onClick={this.handleDecrease}
//               style={{ boxShadow: '0 0 0 transparent' }}
//             >
//               <Icon name="angle down" size="large" />
//             </Button>
//           </Grid.Column>
//         </Grid.Row>
//         {isEmpty && (
//           <Grid.Row style={{ padding: 0 }}>
//             <Grid.Column>
//               <Message size="mini" negative>
//                 <p>Time cannot be empty and number only</p>
//               </Message>
//             </Grid.Column>
//           </Grid.Row>
//         )}
//         <Grid.Row>
//           <Grid.Column textAlign="right">
//             <Button
//               style={styles.inputTimeButtonStyle}
//               onClick={this.handleSetTime}
//             >
//               Ok
//             </Button>
//           </Grid.Column>
//         </Grid.Row>
//       </Grid>
//     );
//   }
// }
// class inputTime extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       hours: '00',
//       minutes: '00',
//       time: this.props.value,
//       isOpen: false,
//     };
//   }
//   componentDidMount() {
//     let times = this.props.value.split(':');
//     this.setState({
//       ...this.state,
//       hours: times[0],
//       minutes: times[1],
//     });
//   }
//   handleOpen = () => {
//     this.setState({ ...this.state, isOpen: true });
//   };

//   handleClose = () => {
//     this.setState({ isOpen: false });
//   };

//   handleSetTime = (time, hours, minutes) => {
//     this.setState({
//       ...this.state,
//       time: time,
//       hours: hours,
//       minutes: minutes,
//     });
//     //this.props.setTime(time);
//     this.props.setTime(this.props.context, time, this.props.name);
//   };
//   render() {
//     const { hours, minutes } = this.state;
//     const { labelText, labelStyles } = this.props;
//     return (
//       <div>
//         <label style={labelStyles}>{labelText}</label>
//         <Popup
//           style={{ zIndex: '1000' }}
//           trigger={
//             <Input
//               fluid
//               basic="true"
//               style={this.props.inputStyles}
//               value={this.props.value}
//               iconPosition="left"
//               disabled={this.props.disable}
//             >
//               <Icon name="clock" style={{ color: '#4cd137' }} />
//               <input />
//             </Input>
//           }
//           content={
//             <InputText
//               handleSetTime={this.handleSetTime}
//               hours={hours}
//               minutes={minutes}
//               onClose={this.handleClose}
//             />
//           }
//           open={this.state.isOpen}
//           onClose={this.handleClose}
//           onOpen={this.handleOpen}
//           on="focus"
//         />
//       </div>
//     );
//   }
// }
// InputText.propTypes = {
//   handleIncrease: PropTypes.func,
//   handleDecrease: PropTypes.func,
//   handleOnChange: PropTypes.func,
//   handleSetTime: PropTypes.func,
//   onClose: PropTypes.func,
//   hours: PropTypes.string,
//   minutes: PropTypes.string,
// };
// inputTime.propTypes = {
//   labelStyles: PropTypes.object,
//   labelText: PropTypes.string,
//   inputStyles: PropTypes.object,
//   setTime: PropTypes.func,
//   value: PropTypes.string,
//   context: PropTypes.object,
//   disable: PropTypes.bool,
//   name: PropTypes.string,
// };
// export default inputTime;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Popup,
  Input,
  Grid,
  Button,
  Icon,
  Message,
  Form,
} from 'semantic-ui-react';
import { addZero } from '../genericFunction/moment';
// import styles from './styles';

class InputText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hours: props.hours,
      minutes: props.minutes,
      time: '',
      isEmpty: false,
    };
  }
  handleIncrease = (e, { name, value }) => {
    value = value === '' ? '00' : value;
    const newValue =
      name === 'hours'
        ? +value + 1 > 23
          ? +value
          : +value + 1
        : +value + 5 > 59
        ? +value
        : +value + 5;
    this.setState({ ...this.state, [name]: addZero(newValue), isEmpty: false });
  };
  handleDecrease = (e, { name, value }) => {
    value = value === '' ? '00' : value;
    const newValue =
      +value - 1 < 0 ? 0 : name === 'hours' ? +value - 1 : +value - 5;
    this.setState({ ...this.state, [name]: addZero(newValue), isEmpty: false });
  };
  handleOnChange = (e, { name, value }) => {
    const oldVal = this.state[name];
    const numbers = /^[0-9]+$/;
    let newValue = !+value.match(numbers) && +value !== 0 ? oldVal : value;
    newValue =
      newValue.length < 2
        ? newValue
        : newValue.length > 2
        ? oldVal
        : addZero(newValue);
    newValue =
      name === 'hours'
        ? +newValue > 23
          ? oldVal
          : newValue
        : +newValue > 59
        ? oldVal
        : newValue;
    this.setState({
      ...this.state,
      [name]: newValue,
      isEmpty: newValue.length < 1 ? true : false,
    });
  };
  //change the value to multiple 5
  handleOnBlur = () => {
    let { minutes } = this.state;
    if (minutes < 10) {
      minutes = minutes <= 5 ? (minutes > 0 ? 5 : 0) : 10;
      let newValue = minutes.length > 2 ? minutes : addZero(minutes);
      this.setState({
        ...this.state,
        minutes: newValue,
      });
    } else {
      if (minutes[1] <= 5) {
        minutes = minutes[1] > 0 ? `${minutes[0]}5` : minutes;
      } else {
        let number = +minutes[0] + 1;
        minutes = `${number.toString()}0`;
        minutes = minutes > 55 ? 55 : minutes;
      }
      this.setState({
        ...this.state,
        minutes: minutes,
      });
    }
  };
  handleSetTime = () => {
    const { hours, minutes } = this.state;
    if (!hours || !minutes) {
      this.setState({ ...this.state, isEmpty: true });
    } else {
      this.props.handleSetTime(
        `${addZero(hours)}:${addZero(minutes)}`,
        hours,
        minutes
      );
      this.props.onClose();
    }
  };
  render() {
    const { hours, minutes, isEmpty } = this.state;
    return (
      <Grid centered>
        <Grid.Row style={{ padding: 0 }}>
          <Grid.Column width={8} textAlign="center">
            <Button
              basic
              icon
              name="hours"
              value={hours}
              onClick={this.handleIncrease}
              style={{ boxShadow: '0 0 0 transparent' }}
            >
              <Icon name="angle up" size="large" />
            </Button>
            <Input
              type="number"
              name="hours"
              fluid
              min={0}
              max={23}
              value={hours}
              onChange={this.handleOnChange}
            >
              <input
                style={{ textAlign: 'center' }}
                maxLength="2"
                name="hours"
                max="12"
              />
            </Input>
            <Button
              basic
              icon
              name="hours"
              value={hours}
              onClick={this.handleDecrease}
              style={{ boxShadow: '0 0 0 transparent' }}
            >
              <Icon name="angle down" size="large" />
            </Button>
          </Grid.Column>
          <span
            style={{
              position: 'absolute',
              marginTop: '47px',
            }}
          >
            :
          </span>
          <Grid.Column width={8} textAlign="center">
            <Button
              basic="true"
              icon
              name="minutes"
              value={minutes}
              onClick={this.handleIncrease}
              style={{ boxShadow: '0 0 0 transparent' }}
            >
              <Icon name="angle up" size="large" />
            </Button>
            <Input
              type="number"
              value={minutes}
              name="minutes"
              onBlur={this.handleOnBlur}
              onChange={this.handleOnChange}
              fluid
              min={0}
              max={59}
            >
              <input
                style={{ textAlign: 'center' }}
                maxLength="2"
                value={minutes}
                name="minutes"
              />
            </Input>
            <Button
              basic="true"
              icon
              name="minutes"
              value={minutes}
              onClick={this.handleDecrease}
              style={{ boxShadow: '0 0 0 transparent' }}
            >
              <Icon name="angle down" size="large" />
            </Button>
          </Grid.Column>
        </Grid.Row>
        {isEmpty && (
          <Grid.Row style={{ padding: 0 }}>
            <Grid.Column>
              <Message size="mini" negative>
                <p>Time cannot be empty and number only</p>
              </Message>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column textAlign="right">
            <Button
              //style={styles.inputTimeButtonStyle}
              onClick={this.handleSetTime}
              primary
            >
              Ok
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
class inputTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hours: '00',
      minutes: '00',
      time: this.props.value,
      isOpen: false,
    };
  }
  componentDidMount() {
    let times = this.props.value.split(':');
    this.setState({
      ...this.state,
      hours: times[0],
      minutes: times[1],
    });
  }
  componentDidUpdate() {
    if (this.props.value !== this.state.time) {
      let timeSplit = this.props.value.split(':');
      this.setState({
        ...this.state,
        time: this.props.value,
        hours: timeSplit[0],
        minutes: timeSplit[1],
      });
    }
  }
  handleOpen = () => {
    this.setState({ ...this.state, isOpen: true });
  };
  handleClose = () => {
    this.setState({ isOpen: false });
  };

  handleSetTime = (time, hours, minutes) => {
    this.setState({
      ...this.state,
      time: time,
      hours: hours,
      minutes: minutes,
    });
    this.props.setTime(this.props.context, time, this.props.name);
  };

  render() {
    const { time, hours, minutes } = this.state;
    const { labelText, labelStyles, inputStyles, value, validate } = this.props;
    const validation = { error: false, message: '' };
    const textStyles = { color: 'black' };
    const newLabelStyles = { ...labelStyles };
    const newInputStyles = { ...inputStyles };
    if (value && validate) {
      if (validate[value] !== undefined) {
        validation.error = validate[value].error;
        validation.message = validate[value].message;
        if (validation.error) {
          textStyles['color'] = '#9f3a38';
          newLabelStyles['color'] = '#9f3a38';
          newInputStyles['borderColor'] = '#e0b4b4';
          newInputStyles['background'] = '#fff6f6';
          newInputStyles['color'] = '#9f3a38';
        }
      }
    }
    return (
      <Form.Field error={validation.error}>
        <label style={labelStyles}>{labelText}</label>
        <Popup
          trigger={
            <Input
              basic="true"
              style={inputStyles}
              value={time}
              iconPosition="left"
              disabled={this.props.disable}
              error={this.props.validate}
            >
              <Icon name="clock" style={{ color: '#4cd137' }} />
              <input />
              {/* style={{ border: 'none', padding: 0 }} */}
            </Input>
          }
          content={
            <InputText
              handleSetTime={this.handleSetTime}
              hours={hours}
              minutes={minutes}
              onClose={this.handleClose}
            />
          }
          open={this.state.isOpen}
          onClose={this.handleClose}
          onOpen={this.handleOpen}
          on="focus"
        />
        {validation.error ? (
          <span style={textStyles}>{validation.message}</span>
        ) : null}
      </Form.Field>
    );
  }
}
InputText.propTypes = {
  handleIncrease: PropTypes.func,
  handleDecrease: PropTypes.func,
  handleOnChange: PropTypes.func,
  handleSetTime: PropTypes.func,
  onClose: PropTypes.func,
  hours: PropTypes.string,
  minutes: PropTypes.string,
};
inputTime.propTypes = {
  labelStyles: PropTypes.object,
  labelText: PropTypes.string,
  inputStyles: PropTypes.object,
  setTime: PropTypes.func,
  value: PropTypes.string,
  context: PropTypes.object,
  name: PropTypes.string,
  disable: PropTypes.bool,
  validate: PropTypes.object,
};
export default inputTime;
