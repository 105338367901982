const WHITE_COLOR = '#FFF';
const RED_COLOR = '#f44336';

const style = {
  buttonClose: {
    background: WHITE_COLOR,
    color: RED_COLOR,
    padding: '0px',
    margin: '0px',
  },
};

export default style;
