import React from 'react';
import { Table, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
// import ActionButton from '../../../components/common/buttonEditAndDelete';
import LoaderModal from '../../../components/modal/loaderModal';
import { convertDateFormat } from '../../../scripts/moment';

const TableDataTransferLogs = props => (
  <Table basic="very" selectable fixed>
    <LoaderModal loading={props.loading} />
    <Table.Header>
      <Table.Row>
        {props.headerTable.map((headTableContent, index) => {
          return (
            <Table.HeaderCell key={index}>{headTableContent}</Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {props.dataRange &&
        props.dataRange.map((data, idx) => {
          return (
            <Table.Row key={idx}>
              <Table.Cell component="th" scope="row" width={1}>
                {data.Id}
              </Table.Cell>
              <Table.Cell>
                {data.ReferenceId !== null ? data.ReferenceId : '-'}
              </Table.Cell>
              <Table.Cell>
                {convertDateFormat(data.CreatedDate, 'DD/MM/YY | HH:mm:ss')}
              </Table.Cell>
              <Table.Cell>{data.Status}</Table.Cell>
              <Table.Cell>
                <Button
                  content="See Detail"
                  primary
                  onClick={props.openModalDataDetail}
                  id={data.Id}
                  request={data.Request}
                  response={data.Response}
                />
              </Table.Cell>
              {/* <ActionButton
                id={data.id}
                // handleClickEdit={props.butttonEdit}
                // handleClickDelete={props.buttonDelete}
                name1="Edit"
                name2="Delte"
                content1="Edit"
                content2="Delete"
              /> */}
            </Table.Row>
          );
        })}
    </Table.Body>
    {/* <Grid>
      <Grid.Row columns={1}>
        <span style={{ marginTop: '15px' }}>
          Not looking for your searcch? Try Search Online
        </span>
        <Button color="yellow">Search Online By Id</Button>
      </Grid.Row>
    </Grid> */}
  </Table>
);

TableDataTransferLogs.propTypes = {
  //   listData: PropTypes.array,
  headerTable: PropTypes.array,
  //   actionButton: PropTypes.func,
  butttonEdit: PropTypes.func,
  buttonDelete: PropTypes.func,
  data: PropTypes.array,
  Id: PropTypes.number,
  dataRange: PropTypes.array,
  loading: PropTypes.bool,
  openModalDataDetail: PropTypes.func,
};
export default TableDataTransferLogs;
