import * as types from '../constants/actionTypes';

const initialState = {
  loadingGenerate: false,
  generateResourceBooking: null,
  loadingDownloadResourceDetail: false,
  downloadResourceBookingDetail: null,
  loadingResourceBookingDetailWithSummary: false,
  resourceBookingDetailWithSummary: null,
  resourceBookingDetailDocument: null,
  loadingResourceBookingDetailDocument: false,
  loadingDeleteResourceBookingCollection: false,
  deleteResourceBookingCollection: null,
  loadingGetResourceList: false,
  resourceList: [],
  loadingPostResource: false,
  loadingUpdateResourceBookingDetail: false,
  loadingPutResource: false,
  errors: {},
  loadingGetResourceDetailById: false,
  resource: null,
  loadingGetResourceBookingReservationByResourceId: false,
  listResourceReservation: [],
  loadingPostResourceBookingReservation: false,
  postResourceBookingReservation: null,
  loadingPutResourceBookingReservation: false,
  putResourceBookingReservation: null,
  loadingDeleteResourceBookingReservation: false,
  deleteResourceBookingReservation: null,
  loadingPostResourceBookingReservationPayment: false,
  isSuccessPostResourceBookingReservation: null,
  isSuccessPostResource: null,
  postResource: null,
  loadingPutReadyToUseQty: false,
  dataStartDateEndDate: null,
  loadingPutResourceBookingReservationPayment: false,
  isSuccessPutResourceBookingReservation: null,
  loadingPostCancelResource: false,
  isSuccessPostCancelResource: null,
  loadingGetResourceTypeEnum: false,
  listResourceTypeEnum: [],
  dataCancelResource: null,
  dataAllotment: null,
  loadingGetResourceBookingByTransaction: false,
  resourceBookingByTransaction: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_GENERATE_RESOURCE_BOOKING:
      return { ...state };
    case types.GET_GENERATE_RESOURCE_BOOKING_PENDING:
      return { ...state, loadingGenerate: true };
    case types.GET_GENERATE_RESOURCE_BOOKING_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loadingGenerate: false,
        generateResourceBooking: null,
      };
    case types.GET_GENERATE_RESOURCE_BOOKING_FULFILLED:
      return {
        ...state,
        generateResourceBooking: action.payload.data,
        loadingGenerate: false,
      };
    case types.GET_DOWNLOAD_RESOURCE_BOOKING_DETAIL:
      return { ...state };
    case types.GET_DOWNLOAD_RESOURCE_BOOKING_DETAIL_PENDING:
      return { ...state, loadingDownloadResourceDetail: true };
    case types.GET_DOWNLOAD_RESOURCE_BOOKING_DETAIL_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loadingDownloadResourceDetail: false,
        downloadResourceBookingDetail: null,
      };
    case types.GET_DOWNLOAD_RESOURCE_BOOKING_DETAIL_FULFILLED:
      return {
        ...state,
        downloadResourceBookingDetail: action.payload.data,
        loadingDownloadResourceDetail: false,
      };
    case types.GET_RESOURCE_BOOKING_WITH_SUMMARY:
      return { ...state };
    case types.GET_RESOURCE_BOOKING_WITH_SUMMARY_PENDING:
      return { ...state, loadingResourceBookingDetailWithSummary: true };
    case types.GET_RESOURCE_BOOKING_WITH_SUMMARY_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loadingResourceBookingDetailWithSummary: false,
        resourceBookingDetailWithSummary: null,
      };
    case types.GET_RESOURCE_BOOKING_WITH_SUMMARY_FULFILLED:
      return {
        ...state,
        resourceBookingDetailWithSummary: action.payload.data,
        loadingResourceBookingDetailWithSummary: false,
      };
    case types.GET_DOWNLAOD_RESOURCE_BOOKING_DETAIL_DOCUMENT:
      return { ...state };
    case types.GET_DOWNLAOD_RESOURCE_BOOKING_DETAIL_DOCUMENT_PENDING:
      return { ...state, loadingResourceBookingDetailDocument: true };
    case types.GET_DOWNLAOD_RESOURCE_BOOKING_DETAIL_DOCUMENT_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loadingResourceBookingDetailDocument: false,
        resourceBookingDetailDocument: null,
      };
    case types.GET_DOWNLAOD_RESOURCE_BOOKING_DETAIL_DOCUMENT_FULFILLED:
      return {
        ...state,
        resourceBookingDetailDocument: action.payload.data,
        loadingResourceBookingDetailDocument: false,
      };
    case types.RESET_DOWNLOAD_RESOURCE_DETAIL:
      return {
        ...state,
        resourceBookingDetailDocument: null,
      };
    case types.DELETE_RESOURCE_BOOKING_COLLECTION:
      return { ...state };
    case types.DELETE_RESOURCE_BOOKING_COLLECTION_PENDING:
      return { ...state, loadingDeleteResourceBookingCollection: true };
    case types.DELETE_RESOURCE_BOOKING_COLLECTION_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loadingDeleteResourceBookingCollection: false,
        deleteResourceBookingCollection: null,
      };
    case types.DELETE_RESOURCE_BOOKING_COLLECTION_FULFILLED:
      return {
        ...state,
        deleteResourceBookingCollection: action.payload.data,
        loadingDeleteResourceBookingCollection: false,
      };
    case types.GET_RESOURCE_LIST:
      return { ...state };
    case types.GET_RESOURCE_LIST_PENDING:
      return { ...state, loadingGetResourceList: true };
    case types.GET_RESOURCE_LIST_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loadingGetResourceList: false,
        resourceList: [],
      };
    case types.GET_RESOURCE_LIST_FULFILLED:
      return {
        ...state,
        resourceList: action.payload.data,
        loadingGetResourceList: false,
      };
    case types.POST_RESOURCE:
      return { ...state };
    case types.POST_RESOURCE_PENDING:
      return {
        ...state,
        loadingPostResource: true,
        isSuccessPostResource: null,
      };
    case types.POST_RESOURCE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loadingPostResource: false,
        isSuccessPostResource: false,
        postResource: null,
      };
    case types.POST_RESOURCE_FULFILLED:
      return {
        ...state,
        loadingPostResource: false,
        isSuccessPostResource: true,
        postResource: action.payload.data,
      };
    case types.PUT_RESOURCE_BOOKING_DETAIL_UPDATE:
      return { ...state };
    case types.PUT_RESOURCE_BOOKING_DETAIL_UPDATE_PENDING:
      return { ...state, loadingUpdateResourceBookingDetail: true };
    case types.PUT_RESOURCE_BOOKING_DETAIL_UPDATE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loadingUpdateResourceBookingDetail: false,
      };
    case types.PUT_RESOURCE_BOOKING_DETAIL_UPDATE_FULFILLED:
      return {
        ...state,
        loadingUpdateResourceBookingDetail: false,
      };
    case types.PUT_RESOURCE:
      return { ...state };
    case types.PUT_RESOURCE_PENDING:
      return { ...state, loadingPutResource: true };
    case types.PUT_RESOURCE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loadingPutResource: false,
      };
    case types.PUT_RESOURCE_FULFILLED:
      return {
        ...state,
        loadingPutResource: false,
      };
    case types.GET_RESOURCE_DETAIL_BY_ID:
      return { ...state };
    case types.GET_RESOURCE_DETAIL_BY_ID_PENDING:
      return { ...state, loadingGetResourceDetailById: true };
    case types.GET_RESOURCE_DETAIL_BY_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loadingGetResourceDetailById: false,
        resource: null,
      };
    case types.GET_RESOURCE_DETAIL_BY_ID_FULFILLED:
      return {
        ...state,
        loadingGetResourceDetailById: false,
        resource: action.payload.data,
      };
    case types.GET_RESOURCE_BOOKING_RESERVATION_BY_RESOURCE_ID:
      return { ...state };
    case types.GET_RESOURCE_BOOKING_RESERVATION_BY_RESOURCE_ID_PENDING:
      return {
        ...state,
        loadingGetResourceBookingReservationByResourceId: true,
      };
    case types.GET_RESOURCE_BOOKING_RESERVATION_BY_RESOURCE_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loadingGetResourceBookingReservationByResourceId: false,
        listResourceReservation: [],
      };
    case types.GET_RESOURCE_BOOKING_RESERVATION_BY_RESOURCE_ID_FULFILLED:
      return {
        ...state,
        loadingGetResourceBookingReservationByResourceId: false,
        listResourceReservation: action.payload.data,
      };
    case types.POST_RESOURCE_BOOKING_RESERVATION:
      return { ...state };
    case types.POST_RESOURCE_BOOKING_RESERVATION_PENDING:
      return {
        ...state,
        loadingPostResourceBookingReservation: true,
      };
    case types.POST_RESOURCE_BOOKING_RESERVATION_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loadingPostResourceBookingReservation: false,
        postResourceBookingReservation: null,
      };
    case types.POST_RESOURCE_BOOKING_RESERVATION_FULFILLED:
      return {
        ...state,
        loadingPostResourceBookingReservation: false,
        postResourceBookingReservation: action.payload.data,
      };
    case types.PUT_RESOURCE_BOOKING_RESERVATION:
      return { ...state };
    case types.PUT_RESOURCE_BOOKING_RESERVATION_PENDING:
      return {
        ...state,
        loadingPutResourceBookingReservation: true,
      };
    case types.PUT_RESOURCE_BOOKING_RESERVATION_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loadingPutResourceBookingReservation: false,
        putResourceBookingReservation: null,
      };
    case types.PUT_RESOURCE_BOOKING_RESERVATION_FULFILLED:
      return {
        ...state,
        loadingPutResourceBookingReservation: false,
        putResourceBookingReservation: action.payload.data,
      };
    case types.DELETE_RESOURCE_BOOKING_RESERVATION:
      return { ...state };
    case types.DELETE_RESOURCE_BOOKING_RESERVATION_PENDING:
      return {
        ...state,
        loadingDeleteResourceBookingReservation: true,
      };
    case types.DELETE_RESOURCE_BOOKING_RESERVATION_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loadingDeleteResourceBookingReservation: false,
        deleteResourceBookingReservation: null,
      };
    case types.DELETE_RESOURCE_BOOKING_RESERVATION_FULFILLED:
      return {
        ...state,
        loadingDeleteResourceBookingReservation: false,
        deleteResourceBookingReservation: action.payload.data,
      };
    case types.POST_RESOURCE_BOOKING_RESERVATION_PAYMENT:
      return { ...state };
    case types.POST_RESOURCE_BOOKING_RESERVATION_PAYMENT_PENDING:
      return {
        ...state,
        loadingPostResourceBookingReservationPayment: true,
        isSuccessPostResourceBookingReservation: null,
      };
    case types.POST_RESOURCE_BOOKING_RESERVATION_PAYMENT_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loadingPostResourceBookingReservationPayment: false,
        isSuccessPostResourceBookingReservation: false,
      };
    case types.POST_RESOURCE_BOOKING_RESERVATION_PAYMENT_FULFILLED:
      return {
        ...state,
        loadingPostResourceBookingReservationPayment: false,
        isSuccessPostResourceBookingReservation: true,
      };
    case types.PUT_RESOURCE_BOOKING_RESERVATION_PAYMENT:
      return { ...state };
    case types.PUT_RESOURCE_BOOKING_RESERVATION_PAYMENT_PENDING:
      return {
        ...state,
        loadingPutResourceBookingReservationPayment: true,
        isSuccessPutResourceBookingReservation: null,
      };
    case types.PUT_RESOURCE_BOOKING_RESERVATION_PAYMENT_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loadingPutResourceBookingReservationPayment: false,
        isSuccessPutResourceBookingReservation: false,
      };
    case types.PUT_RESOURCE_BOOKING_RESERVATION_PAYMENT_FULFILLED:
      return {
        ...state,
        loadingPutResourceBookingReservationPayment: false,
        isSuccessPutResourceBookingReservation: true,
      };
    case types.PUT_READY_TO_USE_QTY:
      return { ...state };
    case types.PUT_READY_TO_USE_QTY_PENDING:
      return {
        ...state,
        loadingPutReadyToUseQty: true,
      };
    case types.PUT_READY_TO_USE_QTY_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loadingPutReadyToUseQty: false,
      };
    case types.PUT_READY_TO_USE_QTY_FULFILLED:
      return {
        ...state,
        loadingPutReadyToUseQty: false,
      };
    case types.SAVE_START_DATE_END_DATE:
      return {
        ...state,
        dataStartDateEndDate: action.data,
      };
    case types.SAVE_DATA_CANCEL_RESOURCE:
      return {
        ...state,
        dataCancelResource: action.data,
      };
    case types.SAVE_ALLOTMENT:
      return {
        ...state,
        dataAllotment: action.data,
      };
    case types.PUT_PAY_RESOURCE_BOOKING:
      return { ...state };
    case types.PUT_PAY_RESOURCE_BOOKING_PENDING:
      return {
        ...state,
        loadingPutPayResource: true,
      };
    case types.PUT_PAY_RESOURCE_BOOKING_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loadingPutPayResource: false,
      };
    case types.PUT_PAY_RESOURCE_BOOKING_FULFILLED:
      return {
        ...state,
        loadingPutPayResource: false,
      };
    case types.POST_CANCEL_RESOURCE:
      return { ...state };
    case types.POST_CANCEL_RESOURCE_PENDING:
      return {
        ...state,
        loadingPostCancelResource: true,
        isSuccessPostCancelResource: null,
      };
    case types.POST_CANCEL_RESOURCE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loadingPostCancelResource: false,
        isSuccessPostCancelResource: false,
      };
    case types.POST_CANCEL_RESOURCE_FULFILLED:
      return {
        ...state,
        loadingPostCancelResource: false,
        isSuccessPostCancelResource: true,
      };
    case types.GET_RESOURCE_TYPE_ENUM:
      return { ...state };
    case types.GET_RESOURCE_TYPE_ENUM_PENDING:
      return {
        ...state,
        loadingGetResourceTypeEnum: true,
        listResourceTypeEnum: [],
      };
    case types.GET_RESOURCE_TYPE_ENUM_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loadingGetResourceTypeEnum: false,
        listResourceTypeEnum: [],
      };
    case types.GET_RESOURCE_TYPE_ENUM_FULFILLED:
      return {
        ...state,
        loadingGetResourceTypeEnum: false,
        listResourceTypeEnum: action.payload.data,
      };
    case types.RESET_STATUS_RESOURCE:
      return {
        ...state,
        isSuccessPostCancelResource: null,
      };
    case types.RESET_DOWNLOAD_RESOURCE_BOOKING_DETAIL:
      return {
        ...state,
        downloadResourceBookingDetail: null,
      };
    case types.GET_RESOURCE_BOOKING_BY_TRANSACTION_CODE:
      return { ...state };
    case types.GET_RESOURCE_BOOKING_BY_TRANSACTION_CODE_PENDING:
      return {
        ...state,
        loadingGetResourceBookingByTransaction: true,
        resourceBookingByTransaction: null,
      };
    case types.GET_RESOURCE_BOOKING_BY_TRANSACTION_CODE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loadingGetResourceBookingByTransaction: false,
        resourceBookingByTransaction: null,
      };
    case types.GET_RESOURCE_BOOKING_BY_TRANSACTION_CODE_FULFILLED:
      return {
        ...state,
        loadingGetResourceBookingByTransaction: false,
        resourceBookingByTransaction: action.payload.data,
      };

    default:
      return state;
  }
};

export default reducer;
