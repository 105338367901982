import apiClient from './apiClient';
import { GET_TRANSPORTATION_ESTIMATE_PRICE_URL } from './apiUrl';

const getTransportationEstimatePriceApi = param => {
  return apiClient.post(
    `${GET_TRANSPORTATION_ESTIMATE_PRICE_URL}?fromCity=${param.fromCity}&toCities=${param.toCities}&ratingId=${param.ratingId}&seatTypeId=${param.seatTypeId}&typeId=${param.typeId}&requestedDate=${param.requestedDate}`,
    param.bodyParam
  );
};

export default getTransportationEstimatePriceApi;
