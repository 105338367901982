import React from 'react';
import { Table, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { HeaderPaymentHistory } from '../constant/headerTable';
import Pagination from '../../../components/common/pagination';
const PaymentHistory = props => {
  const {
    activePage,
    data,
    pagedData,
    numbersFrom,
    numbersTo,
    pageChange,
    listData,
    currency,
    handleSeePaymentProof,
    // handleUpload,
  } = props;
  return (
    <div style={{ marginTop: '2em' }}>
      <Table basic="very" selectable>
        <Table.Header>
          <Table.Row>
            {HeaderPaymentHistory.map((headTableContent, index) => {
              return (
                <Table.HeaderCell
                  key={index}
                  textAlign={headTableContent === 'Action' ? 'center' : ''}
                >
                  {headTableContent}
                </Table.HeaderCell>
              );
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {listData.length > 0 ? (
            listData.map((data, idx) => {
              return (
                data && (
                  <Table.Row key={idx}>
                    <Table.Cell>{data.Date}</Table.Cell>
                    <Table.Cell>
                      {currency +
                        '           ' +
                        new Intl.NumberFormat('de-DE').format(data.Value)}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Button
                        value={data}
                        onClick={handleSeePaymentProof}
                        primary
                      >
                        See Payment Proof
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                )
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell colSpan="2" textAlign="center">
                No Data
              </Table.Cell>
            </Table.Row>
          )}
          <Table.Row>
            <Table.HeaderCell colSpan="6">
              <Pagination
                pagedData={pagedData}
                numbersTo={numbersTo}
                numbersFrom={numbersFrom}
                data={data}
                pageChange={pageChange}
                activePage={activePage}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
};

PaymentHistory.propTypes = {
  listData: PropTypes.array,
  handlePay: PropTypes.func,
  activePage: PropTypes.number,
  pagedData: PropTypes.array,
  numbersFrom: PropTypes.number,
  numbersTo: PropTypes.number,
  pageChange: PropTypes.func,
  data: PropTypes.array,
  currency: PropTypes.string,
  handleSeePaymentProof: PropTypes.func,
};
export default PaymentHistory;
