export const Styles = {
  Size: {
    width: '250px',
  },
  LabelRequired: {
    color: 'red',
  },
  Segment: { marginTop: '30px' },
  Grid: { marginBottom: '8px' },
  InputDateStyle: {
    display: 'block',
    fontWeight: 'bold',
    marginBottom: '4px',
  },
};
