import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';
import StepImage from '../common/step';
import PanelChooseImage from '../panel/panelChooseImage';
import PanelThumbnailImage from '../panel/panelThumbnailImage';
import PanelRatioImage from '../panel/panelRatioImage';

const modalImage = props => (
  <Modal
    //dimmer="blurring"
    closeIcon
    inverted
    open={props.openModal}
    onClose={props.handleClose}
    // size="medium"
    size="small"
    style={{ width: '60em' }}
  >
    <Modal.Header>{props.header}</Modal.Header>
    <Modal.Content>
      <StepImage listStep={props.listStepImage} />
      <h5>{props.content}</h5>
      {props.indexActive === 0 ? (
        <PanelChooseImage
          image={props.image}
          handleChange={props.handleChange}
        />
      ) : props.indexActive === 1 ? (
        <PanelThumbnailImage
          image={props.image}
          handleChange={props.handleChangePrimaryImage}
        />
      ) : (
        <PanelRatioImage image={props.imageRatio} />
      )}
    </Modal.Content>
    <Modal.Actions>
      {props.indexActive === 0 || (
        <Button
          content="Back"
          basic
          color="blue"
          onClick={props.handleBackStep}
        />
      )}
      <Button
        content={props.indexActive === 2 ? 'Add Image' : 'Next'}
        primary
        onClick={props.handleNext}
      />
    </Modal.Actions>
  </Modal>
);

modalImage.propTypes = {
  openModal: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  content: PropTypes.string,
  handleChange: PropTypes.func,
  imagePreview: PropTypes.func,
  handleNext: PropTypes.func,
  handleBackStep: PropTypes.func,
  image: PropTypes.array,
  listStepImage: PropTypes.array,
  indexActive: PropTypes.number,
  handleChangePrimaryImage: PropTypes.func,
  // imageRatio: PropTypes.object,
  imageRatio: PropTypes.array,
};

export default modalImage;
