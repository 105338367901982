import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Form } from 'semantic-ui-react';
import InputWithLabel from '../../../../components/input/inputWithLabel';

const LegalityCustomer = props => {
  return (
    <React.Fragment>
      <Header as="h4" content="Legality" />
      <Grid columns={2} padded="vertically">
        <Grid.Column>
          <Form>
            {!props.isPartner && (
              <InputWithLabel
                inline={false}
                label="IATA Number"
                name="IATANbr"
                placeholder="IATA Number"
                type="text"
                value={props.contentDetail.IATANbr}
                handleChange={props.handleChange}
                validate={props.errorInformation}
                disabled={props.disable}
              />
            )}
            <InputWithLabel
              inline={false}
              label="Association Number"
              name="AssociationNumber"
              placeholder="Association Number"
              type="text"
              value={props.contentDetail.AssociationNumber}
              handleChange={props.handleChange}
              validate={props.errorInformation}
              disabled={props.disable}
            />
          </Form>
        </Grid.Column>
        <Grid.Column>
          <Form>
            <InputWithLabel
              inline={false}
              label="Trade License Number"
              name="TradeLicenseNbr"
              placeholder="Trade License Number"
              type="text"
              value={props.contentDetail.TradeLicenseNbr}
              handleChange={props.handleChange}
              validate={props.errorInformation}
              disabled={props.disable}
            />
          </Form>
        </Grid.Column>
      </Grid>
    </React.Fragment>
  );
};

LegalityCustomer.propTypes = {
  handleChange: PropTypes.func,
  contentDetail: PropTypes.shape({
    IATANbr: PropTypes.string,
    TradeLicenseNbr: PropTypes.string,
    AssociationNumber: PropTypes.string,
    IsAllowedOwnCountryTour: PropTypes.string,
    CompanyBusiness: PropTypes.string,
    //LevelId: PropTypes.string,
  }),
  disable: PropTypes.bool,
  handleCheckClick: PropTypes.func,
  listCompanyBussiness: PropTypes.array,
  // getLevelList: PropTypes.array,
  // listLevel: PropTypes.array,
  // listCustomerLevel: PropTypes.array,
  errorInformation: PropTypes.object,
  isPartner: PropTypes.bool,
};
export default LegalityCustomer;
