import React from 'react';
import { Grid, Segment, Button, Icon, Tab } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import HeaderTitle from '../../../../components/common/headerTitle';
const ReadyPackageList = props => {
  return (
    <div>
      <Grid columns="equal" style={{ marginTop: '30px' }}>
        <Grid.Row stretched>
          <Grid.Column>
            <Segment>
              <HeaderTitle title={props.title} />
              <Button onClick={props.buttonNew} positive floated="right">
                <Icon name="add" />
                Add New
              </Button>
              <Tab
                menu={{ secondary: true, pointing: true }}
                panes={props.tabMenu}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

ReadyPackageList.propTypes = {
  listData: PropTypes.array,
  pagedData: PropTypes.array,
  title: PropTypes.string,
  pageChange: PropTypes.func,
  activePage: PropTypes.number,
  searchInput: PropTypes.string,
  handleChange: PropTypes.func,
  data: PropTypes.array,
  buttonDelete: PropTypes.func,
  buttonNew: PropTypes.func,
  buttonPublished: PropTypes.func,
  buttonCopy: PropTypes.func,
  buttonEdit: PropTypes.func,
  dataPublish: PropTypes.array,
  dataDraft: PropTypes.array,
  tabMenu: PropTypes.array,
};
export default ReadyPackageList;
