import { convertDateFormat } from '../../../../scripts/moment';

const setDataCalendar = data => {
  let destructure = [];

  const destructureItem = items => {
    let destructureItem = [];
    let itemData = [];
    items.map(item => {
      itemData = {
        BookingCode: item.BookingDetailSum ? item.BookingDetailSum.Id : '',
        PackageType: item.BookingDetailSum
          ? item.BookingDetailSum.PackageType
          : '',
        Title: item.BookingDetailSum ? item.BookingDetailSum.Title : '',
        StartDate: item.BookingDetailSum
          ? convertDateFormat(item.BookingDetailSum.StartDate, 'DD MMM YYYY')
          : '',
        EndDate: item.BookingDetailSum
          ? convertDateFormat(item.BookingDetailSum.EndDate, 'DD MMM YYYY')
          : '',
        TotalPax: item.BookingDetailSum
          ? item.BookingDetailSum.AdultQty +
            item.BookingDetailSum.ChildQty +
            item.BookingDetailSum.InfantQty
          : 0,
      };
      return destructureItem.push(itemData);
    });
    return destructureItem;
  };

  data.map(data => {
    let bookingData = {
      AllotmentDate: data.CalendarDate ? data.CalendarDate : null,
      DateBooking: data.CalendarDate
        ? convertDateFormat(data.CalendarDate, 'DD MMMM YYYY')
        : null,
      Items: data.Items ? destructureItem(data.Items) : null,
    };
    return destructure.push(bookingData);
  });
  return destructure;
};

export default setDataCalendar;
