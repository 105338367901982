import { combineReducers } from 'redux';
// import { reducer as formReducer } from 'redux-form';
import currenciesStore from '../../../reducers/currenciesReducer';
import LoginStore from '../../login/loginReducer';
import CompanyStore from '../../../reducers/companyReducer';
import PackageStore from '../../../reducers/packageReducer';
import TourTransactionStore from '../../../reducers/tourTransactionReducer';
import PaymentStore from './paymentReducer';
import TransportationStore from '../reducers/transportationReducer';
import TransportationPageStore from '../../transportation/transportationPageReducer';
import ExcursionStore from './excursionReducer';
import ExcursionPageStore from '../../excurtion/excursionPageReducer';
import AccomodationStore from './accomodationReducer';
import AccomodationPageStore from '../../accommodation/accommodationPageReducer';
import CustomerProfileStore from './customerProfileReducer';
import CustomerProfilePageStore from '../../customer/customerProfilePageReducer';
import UserAccessStore from '../../../reducers/userAccessReducer';
import SystemMarginStore from '../../systemMargin/systemMarginReducer';
import ExchangeRateStore from '../../../reducers/exchangeRateReducer';
import AreaStore from './areaReducer';
import AreaPageStore from '../../area/areaPageReducer';
import CountryStore from './countryReducer';
import CountryPageStore from '../../country/countryPageReducer';
import CityStore from './cityReducer';
import CityPageStore from '../../city/cityPageReducer';
import RegionStore from './regionReducer';
import RegionPageStore from '../../region/regionPageReducer';
import RestaurantStore from '../../../reducers/restaurantReducer';
import SupplierPriceStore from '../../../reducers/supplierPriceReducer';
import PlaceStore from '../../place/placeReducer';
import CityGroupPageStore from '../../cityGroup/cityGroupReducer';
import CustomEnumReducerStore from './customEnumReducer';
import UploadMasterDataStore from '../../uploadMasterData/uploadMasterDataReducer';
import ReadyPackageStore from '../../../reducers/readyPackageReducer';
import UserStore from '../../../reducers/userReducer';
import MasterDataReferenceStore from './uploadMasterDataReferenceReducer';
import MasterDataReferencePageStore from '../../uploadMasterDataReference/uploadMasterDataReferencePageReducer';
import AirportStore from '../../../reducers/airportReducer';
import GuestStore from '../../../reducers/guetsReducer';
import PartnerProfile from './partnerProfileReducer';
import TourGuideStore from '../../tourGuide/tourGuideReducer';
import PackageHistoryStore from '../../../reducers/packageTransactionHistoryReducer';
import NotAllowedCountryStore from '../../notAllowedForCompany/notAllowedCompanyPageReducer';
import DataTransferLogsStore from '../../dataTransferLog/dataTransferLogsReducer';
import LanguageStore from '../../shared/reducers/languageReducer';
import CompanyProfileStore from '../../companyProfile/companyProfileDetail/companyProfileReducer';
import AdditionalItemStore from './additionalItemReducer';
import ResourceBooking from './resourceBookingReducer';
import ARstatementStore from '../../accountStatement/accountStatementReducer';
const reducers = {
  // form: formReducer,
  currenciesStore,
  LoginStore,
  CompanyStore,
  PackageStore,
  TourTransactionStore,
  PaymentStore,
  TransportationStore,
  TransportationPageStore,
  AccomodationPageStore,
  AccomodationStore,
  ExcursionStore,
  CustomerProfileStore,
  CustomerProfilePageStore,
  UserAccessStore,
  SystemMarginStore,
  ExchangeRateStore,
  AreaStore,
  AreaPageStore,
  CityStore,
  CityPageStore,
  RegionStore,
  RegionPageStore,
  CountryStore,
  CountryPageStore,
  RestaurantStore,
  SupplierPriceStore,
  PlaceStore,
  CityGroupPageStore,
  CustomEnumReducerStore,
  UploadMasterDataStore,
  ReadyPackageStore,
  UserStore,
  MasterDataReferenceStore,
  MasterDataReferencePageStore,
  AirportStore,
  GuestStore,
  PartnerProfile,
  TourGuideStore,
  PackageHistoryStore,
  NotAllowedCountryStore,
  DataTransferLogsStore,
  ExcursionPageStore,
  LanguageStore,
  CompanyProfileStore,
  AdditionalItemStore,
  ResourceBooking,
  ARstatementStore,
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
