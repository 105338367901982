import apiClient from '../apiClient';
import { DELETE_PAYMENT_METHOD_IN_COUNTRY_URL } from '../apiUrl';

const deletePaymentMethodInCountryApi = (countryId, paymentMethod) => {
  return apiClient.delete(
    DELETE_PAYMENT_METHOD_IN_COUNTRY_URL(countryId, paymentMethod)
  );
};

export default deletePaymentMethodInCountryApi;
