import React from 'react';
import PropTypes from 'prop-types';
import { Form, Grid, Header } from 'semantic-ui-react';
import InputWithLabel from '../../../components/input/inputWithLabel';

const ContactInformation = props => {
  return (
    <React.Fragment>
      <Header as="h4" content="Contact Information" />
      <Form>
        <InputWithLabel
          inline={false}
          label="Address"
          type="text"
          required
          name="Address"
          value={props.userProfile.Address}
          placeholder="Address"
          handleChange={props.handleChange}
          validate={props.errorInformation}
          disabled={props.disablePage}
        />
      </Form>
      <Grid columns={2} padded="vertically">
        <Grid.Column>
          <Form>
            <InputWithLabel
              inline={false}
              label="Country"
              type="select"
              required
              name="Country"
              placeholder="Country"
              value={
                props.userProfile.Country ? props.userProfile.Country.Id : null
              }
              handleChange={props.handleChange}
              options={props.countryList}
              validate={props.errorInformation}
              disabled={props.disablePage}
            />
            <InputWithLabel
              inline={false}
              placeholder="Email"
              name="Email"
              label="Email"
              type="email"
              required
              value={props.userProfile.Email}
              handleChange={props.handleChange}
              validate={props.errorInformation}
              disabled={props.disablePage}
            />
          </Form>
        </Grid.Column>
        <Grid.Column>
          <Form>
            <InputWithLabel
              inline={false}
              placeholder="Telephone"
              name="PhoneNbr"
              label="Telephone"
              type="number"
              required
              value={props.userProfile.PhoneNbr}
              handleChange={props.handleChange}
              validate={props.errorInformation}
              disabled={props.disablePage}
            />
          </Form>
        </Grid.Column>
      </Grid>
    </React.Fragment>
  );
};

ContactInformation.propTypes = {
  userProfile: PropTypes.object,
  handleChange: PropTypes.func,
  errorInformation: PropTypes.object,
  disablePage: PropTypes.bool,
  countryName: PropTypes.object,
  countryList: PropTypes.array,
};
export default ContactInformation;
