import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Icon, Segment } from 'semantic-ui-react';
import styles from '../../../../../components/segment/segmentDestinationsSummary/styles';
import ArrivalDepartureCard from '../../../../../components/segment/segmentDestinationsSummary/arrivalDepartureCard';
import Accommodation from '../../../../../components/segment/segmentDestinationsSummary/accommodationCard';
import { isAccommodationEmpty } from '../../../../../scripts/itineraryBuilder/dailyProgram';
import ModalDestinationSummary from '../../../../../components/modal/modalDestinationSummary';
import CollapseButton from '../../../../../components/button/collapseButton';
import { Collapse } from 'react-collapse';
import {
  validationGeneralInform,
  isGroupCapacityValid,
  validationGeneralInformPackageManagement,
  validationGeneralInformFixedPackageManagement,
  // isValidGeneralInformation,
} from '../../../../../scripts/validation';
class SegmentDestinationsSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapse: true,
      openModalAccommodation: false,
      accomDestination: '',
      openModalDestinations: false,
    };
  }
  handleCollapse = (e, { name, value }) =>
    this.setState({ ...this.state, [name]: !value });
  closeModal = () => {
    this.setState({
      openModalAccommodation: false,
    });
  };

  validationTourInformation = async () => {
    // ----------------- validation general inforation ------------------------
    let { tourInformation, currentPackage, guests, errors } = this.props;
    let newErrors = { isError: false, data: errors };
    if (currentPackage.type !== 'Fixed' && !this.props.isPackageMangement) {
      newErrors = await validationGeneralInform(
        tourInformation,
        tourInformation.firstDestination,
        errors
      );
      newErrors = {
        ...newErrors,
        data: await isGroupCapacityValid(
          newErrors.data,
          guests,
          tourInformation.groupCapacity
        ),
      };
    } else if (
      currentPackage.type !== 'Fixed' &&
      this.props.isPackageMangement
    ) {
      newErrors = await validationGeneralInformPackageManagement(
        tourInformation,
        tourInformation.firstDestination,
        errors
      );
      newErrors = {
        ...newErrors,
        data: newErrors.data,
      };
    } else if (
      currentPackage.type === 'Fixed' &&
      this.props.isFixedPackageMangement
    ) {
      newErrors = await validationGeneralInformFixedPackageManagement(
        tourInformation,
        tourInformation.firstDestination,
        errors
      );
      newErrors = {
        ...newErrors,
        data: newErrors.data,
      };
    }
    await this.props.setErrorsGeneralInform(newErrors.data);
    // await this.setState({ ...this.state, errors: newErrors.data });
    return newErrors.isError;
    // ----------------- end validation general inforation ------------------------
  };
  openModalDestination = async () => {
    // const isError = this.props.isFixedPackageMangement
    //   ? await this.props.checkGeneralInformation()
    //   : false;
    const isError = await this.props.checkGeneralInformation();
    // const isError = false;
    this.setState({ openModalDestinations: !isError });
  };
  closeModalDestination = () => {
    this.setState({ openModalDestinations: false });
  };

  render() {
    const {
      dailyProgram,
      headlineProgram,
      setDailyProgram,
      setheadlineProgram,
      handleGeneralInfo,
      currentPackage,
      isReadyFixedPrice,
      TypeTransaction,
      PackageType,
      isSales,
      isAdministrator,
      isAcceptedQuotation,
    } = this.props;
    const { Departures, MainPrograms, Returns } = headlineProgram;
    const { openModalDestinations, isCollapse } = this.state;
    const isAccomEmpty = isAccommodationEmpty(MainPrograms);
    const showSetDestination =
      (typeof this.props.isPublished !== 'undefined' &&
        this.props.isPublished === false) ||
      (TypeTransaction === 'edit' &&
        PackageType === 'Quotation' &&
        !isAcceptedQuotation) ||
      (TypeTransaction === 'create' && PackageType !== 'Quotation');
    let isAllowedEdit = isSales || isAdministrator;
    return (
      <Segment raised fluid>
        <div style={styles.marginContentInsideCard}>
          <Grid verticalAlign="middle">
            <Grid.Row style={{ paddingBottom: '0px' }}>
              <Grid.Column width={14}>
                <Header as="h3" style={styles.marginHeader}>
                  <Icon name="clipboard list" style={{ color: '#38af95' }} />
                  Accommodation and Date
                </Header>
              </Grid.Column>
              <Grid.Column width={2} textAlign="left">
                <CollapseButton
                  isCollapse={isCollapse}
                  handleCollapse={this.handleCollapse}
                  name="isCollapse"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Collapse isOpened={isCollapse} style={{ margin: '0px' }}>
            {isAccomEmpty || (
              <div style={styles.paddingSideTwenty}>
                {!this.props.isFixedPackageMangement &&
                  Departures.length > 0 &&
                  Departures.map((item, i) =>
                    item.TransferType &&
                    item.TransferType === 'Movement_arrival' ? (
                      <ArrivalDepartureCard
                        subType="arrival"
                        data={item}
                        key={i}
                        index={i}
                      />
                    ) : (
                      <ArrivalDepartureCard
                        subType="departure"
                        data={item}
                        key={i}
                        index={i}
                      />
                    )
                  )}
                {MainPrograms.length > 0 &&
                  MainPrograms.map((item, i) =>
                    //eslint-disable-next-line
                    item.hasOwnProperty('AccommodationSummary') ? (
                      <Accommodation
                        data={item}
                        key={i}
                        index={i}
                        isFixedPackageMangement={
                          this.props.isFixedPackageMangement
                        }
                        isReadyFixedPrice={isReadyFixedPrice}
                      />
                    ) : !this.props.isFixedPackageMangement &&
                      //eslint-disable-next-line
                      item.hasOwnProperty('TransferType') &&
                      item.TransferType === 'Movement_arrival' ? (
                      <ArrivalDepartureCard
                        subType="arrival"
                        data={item}
                        key={i}
                        index={i}
                      />
                    ) : (
                      !this.props.isFixedPackageMangement && (
                        <ArrivalDepartureCard
                          subType="departure"
                          data={item}
                          key={i}
                          index={i}
                        />
                      )
                    )
                  )}
                {!this.props.isFixedPackageMangement &&
                  Returns.length > 0 &&
                  Returns.map((item, i) =>
                    item.TransferType &&
                    item.TransferType === 'Movement_arrival' ? (
                      <ArrivalDepartureCard
                        subType="arrival"
                        data={item}
                        key={i}
                        index={i}
                      />
                    ) : (
                      <ArrivalDepartureCard
                        subType="departure"
                        data={item}
                        key={i}
                        index={i}
                      />
                    )
                  )}
              </div>
            )}
            {!isReadyFixedPrice
              ? showSetDestination &&
                !this.props.isPublished &&
                isAllowedEdit && (
                  <ModalDestinationSummary
                    open={openModalDestinations}
                    closeModal={this.closeModalDestination}
                    openModal={this.openModalDestination}
                    dailyProgram={dailyProgram}
                    headlineProgram={headlineProgram}
                    setDailyProgram={setDailyProgram}
                    setheadlineProgram={setheadlineProgram}
                    handleGeneralInfo={handleGeneralInfo}
                    isFixedPackageMangement={this.props.isFixedPackageMangement}
                    addAccommodation={this.props.addAccommodation}
                    tourInformation={this.props.tourInformation}
                    roomAllocation={this.props.roomAllocation}
                    isPublished={this.props.isPublished}
                    currentPackage={currentPackage}
                    isPackageMangement={this.props.isPackageMangement}
                  />
                )
              : null}
          </Collapse>
        </div>
      </Segment>
    );
  }
}

SegmentDestinationsSummary.propTypes = {
  headlineProgram: PropTypes.object,
  dailyProgram: PropTypes.array,
  setDailyProgram: PropTypes.func,
  setheadlineProgram: PropTypes.func,
  handleGeneralInfo: PropTypes.func,
  currentPackage: PropTypes.object,
  tourInformation: PropTypes.object,
  guests: PropTypes.object,
  errors: PropTypes.object,
  setErrorsGeneralInform: PropTypes.func,
  isPackageMangement: PropTypes.bool,
  isFixedPackageMangement: PropTypes.bool,
  addAccommodation: PropTypes.func,
  checkGeneralInformation: PropTypes.func,
  roomAllocation: PropTypes.object,
  isPublished: PropTypes.bool,
  isReadyFixedPrice: PropTypes.bool,
  TypeTransaction: PropTypes.string,
  PackageType: PropTypes.string,
  isSales: PropTypes.bool,
  isAdministrator: PropTypes.bool,
  isAcceptedQuotation: PropTypes.bool,
};
export default SegmentDestinationsSummary;
