const setCityState = data => {
  const { Id, Name, ImageUrl, Region, UTC, Country } = data;
  let cityById = {
    Id: Id ? Id : '',
    ImageContent: ImageUrl ? ImageUrl : '',
    ImageName: '',
    Name: Name ? Name : '',
    RegionId: Region.Id ? Region.Id : '',
    UTC: UTC ? UTC : '',
    CountryId: Country.Id ? Country.Id : '',
    ImagePreview: ImageUrl ? ImageUrl : '',
  };
  return cityById;
};

export { setCityState };
