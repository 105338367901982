import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Checkbox, Form } from 'semantic-ui-react';
import {
  TexfieldWithDropdownFluidOnPage,
  TextAreaFluidOnPage,
  DropdownFluidOnPage,
} from '../../../../components/common/fieldWithError';
import { StylesTextfieldWithError } from '../../../../components/common/styleCommon';
import InputWithLabel from '../../../../components/input/inputWithLabel';

const GeneralInfoAccomodationProfile = props => {
  return (
    <div>
      <Grid columns="equal">
        <Grid.Row stretched>
          <Grid.Column width={15}>
            <Header as="h3" content="General Information" />
            <TexfieldWithDropdownFluidOnPage
              placeholder1="Accommodation Name"
              placeholder2="Type"
              name1="Name"
              name2="TypeId"
              title2="Type"
              title1="Accommodation Name"
              handleChange={props.handleChange}
              handleChangeDropdown={props.handleChange}
              data={props.accomodationProfileData.accomodationProfileId.Name}
              option={props.listType}
              valueDropDown={
                props.accomodationProfileData.accomodationProfileId.TypeId
              }
              error2={props.errors.Name}
              error1={props.errors.TypeId}
              requiredInput={true}
              requiredDropdown={true}
              styleDropdown={StylesTextfieldWithError.doubleTextFieldRight}
              styleInput={StylesTextfieldWithError.doubleTextField}
              type="Text"
              disable={props.disablePage}
            />
            <Form style={{ paddingTop: '10px' }}>
              <InputWithLabel
                inline={false}
                label="Manage By"
                type="text"
                name="CompanyProfile"
                value={`${props.accomodationProfileData.accomodationProfileId.Company.Code} - ${props.accomodationProfileData.accomodationProfileId.Company.Name} `}
                placeholder="Company Profile"
                // handleChange={props.handleChange}
                disabled={true}
                // validate={props.errorInformation}
              />
            </Form>
            <TextAreaFluidOnPage
              placeholder="Hotel Description"
              name="Description"
              title="Description"
              handleChange={props.handleChange}
              data={
                props.accomodationProfileData.accomodationProfileId.Description
              }
              errors={props.errors.Description}
              required={true}
              style={
                props.disablePage
                  ? StylesTextfieldWithError.textAreaOnFluidDisabled
                  : StylesTextfieldWithError.textAreaOnFluid
              }
              disable={props.disablePage}
            />
            <TextAreaFluidOnPage
              placeholder="Long Description Hotel"
              name="Overview"
              title="Long Description"
              handleChange={props.handleChange}
              data={
                props.accomodationProfileData.accomodationProfileId.Overview
              }
              style={
                props.disablePage
                  ? StylesTextfieldWithError.textAreaOnFluidDisabled
                  : StylesTextfieldWithError.textAreaOnFluid
              }
              disable={props.disablePage}
            />
            <DropdownFluidOnPage
              placeholder="Tour Type"
              name="Segments"
              title="Tour Type"
              handleChange={props.handleChange}
              option={props.listTourType}
              valueDropDown={
                props.accomodationProfileData.accomodationProfileId.Segments
              }
              style={StylesTextfieldWithError.textAreaOnFluid}
              multiple={true}
              errors={props.errors.Segments}
              required={true}
              fluid={true}
              disable={props.disablePage}
            />
            <Checkbox
              label="Accommodation Priority"
              checked={
                props.accomodationProfileData.accomodationProfileId.Priority
              }
              onChange={props.onChangeCheckboxPriority}
              disable={props.disablePage}
              style={{ fontWeight: 'bold' }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

GeneralInfoAccomodationProfile.propTypes = {
  handleChange: PropTypes.func,
  accomodationProfileData: PropTypes.object,
  listType: PropTypes.array,
  listTourType: PropTypes.array,
  listTourPaxType: PropTypes.array,
  listAccomodationFacilities: PropTypes.object,
  listAccomodationLocation: PropTypes.object,
  handleChangeDropdown: PropTypes.func,
  errors: PropTypes.object,
  disablePage: PropTypes.bool,
  onChangeCheckboxPriority: PropTypes.func,
};
export default GeneralInfoAccomodationProfile;
