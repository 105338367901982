import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider } from 'semantic-ui-react';
import styles from './Styles';

const AdditionalServiceHeader = props => {
  const { data, isShowButton } = props;
  return data.length ? (
    <>
      <Grid style={styles.gridForm}>
        <Grid.Row columns={4}>
          <Grid.Column width={7}>
            <strong>Service</strong>
          </Grid.Column>
          <Grid.Column width={3} textAlign="center">
            <strong>Quantity</strong>
          </Grid.Column>
          <Grid.Column width={5}>
            <strong>Price</strong>
          </Grid.Column>
          <Grid.Column width={1}></Grid.Column>
        </Grid.Row>
        <Divider style={styles.marginTop0} />
      </Grid>
    </>
  ) : isShowButton ? (
    <>
      <Grid textAlign="center">
        <Grid.Row textAlign="center">
          <Grid.Column width={7}>
            <strong>Want to add additional services ?</strong>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  ) : (
    <>
      <Grid textAlign="center">
        <Grid.Row textAlign="center">
          <Grid.Column width={7}>
            <strong>No additional services</strong>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

AdditionalServiceHeader.propTypes = {
  data: PropTypes.array,
  isShowButton: PropTypes.bool,
};

export default React.memo(AdditionalServiceHeader);
