import * as types from './tourGuideDetail/constant/actionTypes';

const initialState = {
  loading: false,
  tourGuideList: [],
  tourGuideStatus: '',
  tourGuideById: {},
  tourGuideByIdStatus: '',
  postTourGuideStatus: '',
  putTourGuideStatus: '',
  postTourGuide: '',
  putTourGuide: '',
  errors: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_TOUR_GUIDE:
      return {
        ...state,
        tourGuideStatus: '',
        tourGuideByIdStatus: '',
        postTourGuideStatus: '',
        putTourGuideStatus: '',
      };
    case types.GET_TOUR_GUIDE:
      return { ...state };
    case types.GET_TOUR_GUIDE_PENDING:
      return { ...state, loading: true };
    case types.GET_TOUR_GUIDE_FULFILLED:
      return {
        ...state,
        tourGuideList: action.payload.data,
        loading: false,
        tourGuideStatus: 'success',
      };
    case types.GET_TOUR_GUIDE_REJECTED:
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.response.data.Message },
        tourGuideStatus: 'failed',
      };

    case types.GET_TOUR_GUIDE_BY_ID:
      return { ...state };
    case types.GET_TOUR_GUIDE_BY_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_TOUR_GUIDE_BY_ID_FULFILLED:
      return {
        ...state,
        tourGuideById: action.payload.data,
        loading: false,
        tourGuideByIdStatus: 'success',
      };
    case types.GET_TOUR_GUIDE_BY_ID_REJECTED:
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.response.data.Message },
        tourGuideByIdStatus: 'failed',
      };

    case types.POST_TOUR_GUIDE:
      return { ...state };
    case types.POST_TOUR_GUIDE_PENDING:
      return { ...state, loading: true };
    case types.POST_TOUR_GUIDE_FULFILLED:
      return {
        ...state,
        postTourGuide: action.payload.data,
        loading: false,
        postTourGuideStatus: 'success',
      };
    case types.POST_TOUR_GUIDE_REJECTED:
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.response.data.Message },
        postTourGuideStatus: 'failed',
      };

    case types.PUT_TOUR_GUIDE:
      return { ...state };
    case types.PUT_TOUR_GUIDE_PENDING:
      return { ...state, loading: true };
    case types.PUT_TOUR_GUIDE_FULFILLED:
      return {
        ...state,
        putTourGuide: action.payload.data,
        loading: false,
        putTourGuideStatus: 'success',
      };
    case types.PUT_TOUR_GUIDE_REJECTED:
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.response.data.Message },
        putTourGuideStatus: 'failed',
      };
    default:
      return state;
  }
};

export default reducer;
