import React from 'react';
import { Button } from 'semantic-ui-react';
import styles from './styles';
import PropTypes from 'prop-types';

const ButtonClose = props => (
  <Button
    floated="right"
    icon="close"
    onClick={props.onClose}
    style={styles.buttonClose}
    size="massive"
  />
);

ButtonClose.propTypes = {
  onClose: PropTypes.func,
};

export default ButtonClose;
