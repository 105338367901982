import apiClient from '../apiClient';
import { GET_SUPERVISOR_BY_COMPANY_CODE_POSITION_ID_URL } from '../apiUrl';

const getSupervisorByCompanyCodePositionIdApi = (Code, PositionId) => {
  return apiClient.get(
    GET_SUPERVISOR_BY_COMPANY_CODE_POSITION_ID_URL(Code, PositionId)
  );
};

export default getSupervisorByCompanyCodePositionIdApi;
