import {
  GET_READY_PACKAGE,
  GET_READY_PACKAGE_DETAIL,
  POST_READY_PACKAGE,
  RESET_READY_PACKAGE,
  GET_PACKAGE_BY_TYPE_AND_ID,
} from './actionTypes';
import getReadyPackageAllApi from '../api/readyPackage/getReadyPackageAllApi';
import getReadyPackageDetailApi from '../api/readyPackage/getReadyPackageDetailApi';
import postReadyPackageApi from '../api/readyPackage/postReadyPackageApi';
import getReadyPackageByTypeAndIdApi from '../api/readyPackage/getReadyPackageByIdApi';

const getReadyPackageAllAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_READY_PACKAGE,
      payload: getReadyPackageAllApi(),
    });
  };
};
const getReadyPackageDetailAction = (
  cityId,
  desiredDays,
  tourCategoryId,
  tourPaxTypeId,
  accommodationType,
  accommodationRating,
  accommodationLocation,
  startingDate
) => {
  return dispatch => {
    return dispatch({
      type: GET_READY_PACKAGE_DETAIL,
      payload: getReadyPackageDetailApi(
        cityId,
        desiredDays,
        tourCategoryId,
        tourPaxTypeId,
        accommodationType,
        accommodationRating,
        accommodationLocation,
        startingDate
      ),
    });
  };
};
const postReadyPackageAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_READY_PACKAGE,
      payload: postReadyPackageApi(data),
    });
  };
};

const getReadyPackageByTypeAndIdAction = (Type, Id) => {
  return dispatch => {
    return dispatch({
      type: GET_PACKAGE_BY_TYPE_AND_ID,
      payload: getReadyPackageByTypeAndIdApi(Type, Id),
    });
  };
};

const resetReadyPackageAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_READY_PACKAGE,
    });
  };
};

export {
  getReadyPackageAllAction,
  getReadyPackageDetailAction,
  postReadyPackageAction,
  resetReadyPackageAction,
  getReadyPackageByTypeAndIdAction,
};
