import React from 'react';
import { Table, Button, Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';
const SegmentTableUserList = props => (
  <Table basic="very" selectable fixed>
    <div style={{ overflow: 'auto' }}>
      <Table.Header>
        <Table.Row>
          {props.headerTable.map((headTableContent, index) => {
            return (
              <Table.HeaderCell key={index}>
                {headTableContent}
              </Table.HeaderCell>
            );
          })}
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {props.listData &&
          props.listData.map((userAccess, idx) => {
            return (
              <Table.Row key={idx}>
                <Table.Cell component="th" scope="row" width={1}>
                  {userAccess.CompanyCode ? userAccess.CompanyCode : '-'}
                </Table.Cell>
                <Table.Cell> {userAccess.Username}</Table.Cell>
                <Table.Cell>{userAccess.Roles.join(', ')}</Table.Cell>
                <Table.Cell>{userAccess.Email}</Table.Cell>
                <Table.Cell>
                  <Popup
                    trigger={
                      <Button
                        icon="unlock alternate"
                        onClick={props.unlockUser}
                        id={userAccess.UserId}
                        color="blue"
                        disabled={userAccess.IsLocked === true ? false : true}
                      />
                    }
                    content="Unlock User"
                  />
                  <Popup
                    trigger={
                      <Button
                        icon="key"
                        onClick={props.resetPassword}
                        id={userAccess.UserId}
                        value={userAccess.CompanyCode}
                        name={userAccess.Username}
                        color="blue"
                      />
                    }
                    content="Reset password employee"
                  />
                  <Popup
                    trigger={
                      <Button
                        icon="edit"
                        onClick={props.buttonEdit}
                        id={userAccess.UserId}
                        color="blue"
                        name="edit"
                      />
                    }
                    content="Edit user role"
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
      </Table.Body>
    </div>
  </Table>
);

SegmentTableUserList.propTypes = {
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  actionButton: PropTypes.func,
  unlockUser: PropTypes.func,
  resetPassword: PropTypes.func,
  buttonEdit: PropTypes.func,
};
export default SegmentTableUserList;
