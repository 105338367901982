import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CityGroupList from './CityGroupList';
import FilterData from '../../../scripts/filterDataTable';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../scripts/pagination';

class CityGroupListComponent extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
    };
  }
  static propTypes = {
    data: PropTypes.array,
  };
  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });

  handleChangeDate = (value, name) => {
    this.setState({ [name]: value, activePage: 1 });
  };
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };

  render() {
    let filterData = FilterData(
      this.props.data,
      this.props.filterBy,
      this.state.searchInput
    );
    let pagedData = datasetPagination(filterData, 7);
    let listData = pagedData[this.state.activePage - 1];

    let { numbersFrom, numbersTo } = getNumberFromTo(
      listData,
      this.state.activePage,
      7
    );
    let handleChange =
      this.props.filterBy === 'FormatedDate'
        ? this.handleChangeDate
        : this.props.filterBy === 'exchangeRate'
          ? this.handleChangeDate
          : this.handleChange;
    return (
      <CityGroupList
        listData={listData}
        pagedData={pagedData}
        title={'City Group List'}
        pageChange={this.pageChange}
        activePage={this.state.activePage}
        searchInput={this.state.searchInput}
        handleChange={handleChange}
        data={this.props.data}
        buttonNew={this.props.buttonNew}
        butttonEdit={this.props.butttonEdit}
        buttonDelete={this.props.buttonDelete}
        numbersFrom={numbersFrom}
        numbersTo={numbersTo}
      />
    );
  }
}

CityGroupListComponent.propTypes = {
  filterBy: PropTypes.string,
  data: PropTypes.array,
  actionTransaction: PropTypes.func,
  loading: PropTypes.bool,
  openDetailTransaction: PropTypes.func,
  disabled: PropTypes.bool,
  buttonNew: PropTypes.func,
  butttonEdit: PropTypes.func,
  buttonDelete: PropTypes.func,
  handleChange: PropTypes.func,
};
export default CityGroupListComponent;
