import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Input, Grid } from 'semantic-ui-react';
import {
  TexfieldWithDropdownFluidOnPage,
  TextAreaFluidOnPage,
} from '../../common/fieldWithError';
import CurrencyFormat from 'react-currency-format';
import { StyleModal } from '../../../assets/styles/size';
import { StylesTextfieldWithError } from '../../common/styleCommon';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  resetCustomerProfileAction,
  postBalanceDetailAction,
} from '../../../pages/shared/actions/customerProfileAction';
import {
  getPaymentMethodByCountryIdAction,
  postTopUpPaymentGatewayAction,
} from '../../../pages/shared/actions/customEnumAction';
import { getTransactionByIdAction } from '../../../actions/tourTransactionAction';
import LoaderModal from '../loaderModal';
import ModalPaymentGateway from './modalChoosePaymentGateway';
import ModalIframePaymentGateway from '../../../pages/shared/components/modal/ModalIframePaymentGateway';
import ModalConfirmation from '../modalConfirmationTwoButton';
import ModalAlert from '../modalAlert';
class modalTouressWallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      balanceDetail: {
        CompanyCode: '',
        CashFlowType: 'Credit',
        Name: '',
      },
      errors: {},
    };
  }
  componentDidUpdate() {
    const {
      getCustomerProfileByCodeStatus,
      getCustomerProfileByCode,
      resetCustomerProfileAction,
    } = this.props;
    if (getCustomerProfileByCodeStatus === 'success') {
      this.setStateBalanceDetail(getCustomerProfileByCode);
      resetCustomerProfileAction();
    } else if (getCustomerProfileByCodeStatus === 'failed') {
      resetCustomerProfileAction();
    }
  }
  setStateBalanceDetail = data => {
    const { Code, Currency, Name, Balance, CountryId } = data;
    let balanceDetail = {
      CompanyCode: Code ? Code : '',
      CashFlowType: 'Credit',
      CurrencyId: Currency ? Currency : '',
      Value: 0,
      Reference: '',
      Description: '',
      PaymentMethod: 0,
      Name: Name ? Name : '',
      Balance: Balance ? Balance : 0,
      DateTime: moment(new Date()).format('YYYY-MM-DD'),
      CountryId: CountryId ? CountryId : 0,
    };
    this.setState({ balanceDetail: balanceDetail });
  };
  handleChangeBalaceDetail = (e, { value, name }) => {
    const balanceDetail = this.state.balanceDetail;
    balanceDetail[name] = value;
    this.setState({ balanceDetail: balanceDetail });
  };
  handleChangeAmount = (value, name) => {
    const balanceDetail = this.state.balanceDetail;
    balanceDetail[name] = value;
    this.setState({ balanceDetail: balanceDetail });
  };

  validate = () => {
    let isError = false;
    const errors = {
      ValueError: '',
    };
    if (this.state.balanceDetail.Value <= 0) {
      isError = true;
      errors.ValueError = 'Amount invalid';
    }
    this.setState({
      errors: {
        ...errors,
      },
    });
    return isError;
  };

  saveBalanceDetail = (e, { name, id, value }) => {
    const err = this.validate();
    const data = this.state.balanceDetail;
    const {
      postBalanceDetailAction,
      getPaymentMethodByCountryIdAction,
      handleClose,
      getTransactionByIdAction,
      transactionId,
    } = this.props;
    if (!err) {
      if (name === 'ManualPayment') {
        this.setState({ loadingPayment: true });
        postBalanceDetailAction(data)
          .then(() => {
            this.setState({
              openModalAlert: true,
              headerAlert: 'Success',
              contentAlert: 'the transaction has been successfully',
            });
            this.handleClosePaymentGateway();
            handleClose();
            getTransactionByIdAction(transactionId);
          })
          .catch(err =>
            this.setState({
              openModalAlert: true,
              headerAlert: 'Failed',
              contentAlert: err.response.data.Message,
            })
          );
      } else if (name === 'postPaymentGateway') {
        this.setState({
          loadingPayment: true,
          openConfirmAlertPaymentGateway: true,
          contentConfirmAlert:
            'Are you sure to make payments using ' + value + ' ?',
          IdForPayment: id,
        });
      } else {
        const { CountryId } = this.state.balanceDetail;
        getPaymentMethodByCountryIdAction(CountryId);
        this.setState({ modalPaymentGateway: true });
      }
    }
  };

  handleClosePaymentGateway = () => {
    this.setState({ modalPaymentGateway: false });
  };
  handleCloseConfirmAlertPaymentGateway = () => {
    this.setState({
      openConfirmAlertPaymentGateway: false,
      loadingPayment: false,
    });
  };
  payUsingPaymentGateway = () => {
    const dataPaymentgateway = {
      PaymentGateway: this.state.IdForPayment,
      Amount: this.state.balanceDetail.Value,
      CompanyCode: this.state.balanceDetail.CompanyCode,
      CurrencyId: this.state.balanceDetail.CurrencyId,
    };
    this.setState({
      openConfirmAlertPaymentGateway: false,
    });
    this.props
      .postTopUpPaymentGatewayAction(dataPaymentgateway)
      .then(res =>
        this.setState({
          openModalIframePaymentGateway: true,
          iframePaymentGatewayObject: res.value.data.Url,
          loadingPayment: false,
        })
      )
      .catch(err =>
        this.setState({
          headerAlert: 'Failed',
          contentAlert: err.response.data.Message,
          modalConfirmation: true,
          openModalIframePaymentGateway: false,
          loadingPayment: false,
        })
      );
  };
  handleCloseIframe = () => {
    this.setState({
      openModalIframePaymentGateway: false,
      //   modalPaymentGateway: false,
      //   modalBalanceDetail: false,
      //   loadingAdditionalItem: true,
      //   loadingManualDemo: true,
      //   loadingPercentage: true,
      //   loadingTransactionDetail: true,
      //   modalManualPayment: false,
    });
  };

  handleCloseModalAlert = () => {
    this.setState({
      openModalAlert: false,
    });
  };

  handleDoneIFramePayment = () => {
    const { handleClose, getTransactionByIdAction, transactionId } = this.props;
    handleClose();
    getTransactionByIdAction(transactionId);
    this.setState({
      openModalIframePaymentGateway: false,
      modalPaymentGateway: false,
    });
  };
  render() {
    const props = this.props;
    const {
      Name,
      CompanyCode,
      DateTime,
      CurrencyId,
      Value,
    } = this.state.balanceDetail;
    const {
      modalPaymentGateway,
      openConfirmAlertPaymentGateway,
      contentConfirmAlert,
      openModalIframePaymentGateway,
      iframePaymentGatewayObject,
      openModalAlert,
      headerAlert,
      contentAlert,
    } = this.state;
    return (
      <div>
        <Modal
          dimmer="blurring"
          closeIcon
          inverted
          open={props.open}
          onClose={props.handleClose}
          size="small"
          style={StyleModal.modalWidth}
        >
          <LoaderModal loading={props.loadingCustomerProfile} />
          <Modal.Header>Add New {props.header}</Modal.Header>
          <Modal.Content>
            <label>
              Add <strong>{props.header}</strong> For
              <strong>
                {'     '}
                {Name}
                {'     '}
                {CompanyCode}
              </strong>
            </label>

            <TexfieldWithDropdownFluidOnPage
              placeholder1="Date Time"
              placeholder2="Amount"
              name1="DateTime"
              name2="CurrencyId"
              title1="Date Time"
              title2="Amount"
              handleChange={this.handleChangeBalaceDetail}
              handleChangeDropdown={this.handleChangeBalaceDetail}
              data={DateTime}
              option={props.currencies}
              valueDropDown={CurrencyId}
              requiredInput={true}
              requiredDropdown={true}
              styleDropdown={StylesTextfieldWithError.styleDropdownWithoutWidth}
              styleInput={StylesTextfieldWithError.textfieldDateBalanceDetail}
              type="date"
              fluidDropdown={true}
            />

            <Grid.Row style={{ marginLeft: '18.5em', marginTop: '-3.7em' }}>
              <Grid.Column>
                <CurrencyFormat
                  // thousandSeparator={true}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  decimalScale={2}
                  value={Value}
                  textAlign="right"
                  name="Value"
                  customInput={Input}
                  onValueChange={e => this.handleChangeAmount(e.value, 'Value')}
                />
                <label style={{ color: 'red' }}>
                  {this.state.errors.ValueError}
                </label>
              </Grid.Column>
            </Grid.Row>
            <TextAreaFluidOnPage
              placeholder="Note"
              name="Description"
              title="Note"
              handleChange={this.handleChangeBalaceDetail}
              style={StylesTextfieldWithError.widthNoteBalanceDetail}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              positive
              onClick={this.saveBalanceDetail}
              name={props.nameButton}
              icon="save"
              content={props.contentButton}
              loading={props.loading}
            />
          </Modal.Actions>
        </Modal>
        <ModalPaymentGateway
          open={modalPaymentGateway}
          handleClose={this.handleClosePaymentGateway}
          header="Choose Payment Gateway"
          handleChange={this.handleChangeBalaceDetail}
          saveBalanceDetail={this.saveBalanceDetail}
          listPaymentMethod={this.state.listPaymentgateway}
          loadingModal={props.loadingCustomEnumeration}
          loadingAddBalance={props.loadingCustomerProfile}
        />
        <ModalConfirmation
          openModal={openConfirmAlertPaymentGateway}
          close={this.handleCloseConfirmAlertPaymentGateway}
          confirm={this.payUsingPaymentGateway}
          message={contentConfirmAlert}
          size="mini"
        />
        <ModalIframePaymentGateway
          open={openModalIframePaymentGateway}
          handleClose={this.handleCloseIframe}
          url={iframePaymentGatewayObject}
          handleDone={this.handleDoneIFramePayment}
        />
        <ModalAlert
          openModal={openModalAlert}
          handleClose={this.handleCloseModalAlert}
          header={headerAlert}
          content={contentAlert}
          nameButton={'Ok'}
        />
      </div>
    );
  }
}

modalTouressWallet.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  getCustomerProfileByCodeStatus: PropTypes.string,
  getCustomerProfileByCode: PropTypes.object,
  resetCustomerProfileAction: PropTypes.func,
  postBalanceDetailAction: PropTypes.func,
  getPaymentMethodByCountryIdAction: PropTypes.func,
  postTopUpPaymentGatewayAction: PropTypes.func,
  getTransactionByIdAction: PropTypes.func,
  transactionId: PropTypes.number,
};
const mapStateToProps = state => {
  return {
    currencies: state.currenciesStore.currencies.map((cur, i) => {
      return { key: i, text: cur.Id, value: cur.Id };
    }),
    getCustomerProfileByCode:
      state.CustomerProfileStore.getCustomerProfileByCode,
    getCustomerProfileByCodeStatus:
      state.CustomerProfileStore.getCustomerProfileByCodeStatus,
    loadingCustomerProfile: state.CustomerProfileStore.loading,
    loadingCustomEnumeration: state.CustomEnumReducerStore.loading,
  };
};

export default connect(
  mapStateToProps,
  {
    resetCustomerProfileAction,
    postBalanceDetailAction,
    getPaymentMethodByCountryIdAction,
    postTopUpPaymentGatewayAction,
    getTransactionByIdAction,
  }
)(modalTouressWallet);
