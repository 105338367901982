import React from 'react';
import { connect } from 'react-redux';
import SegmentCompanyProfileDetail from './components/SegmentCompanyProfileDetail';
import ModalAlert from '../../shared/components/modal/ModalAlert';
import PropTypes from 'prop-types';
import ModalConfirm from '../../../components/modal/modalConfirmationTwoButton';
import handleValidation from '../../shared/services/validation/emptyFieldValidation';
import fieldToValidate from './constant/fieldToValidate';
import _ from 'lodash';
import SetDataCompanyProfile from './services/data/setDataCompanyProfile';
import setDataUserSupplier from './services/data/setDataUserSupplier';
import { getCurrenciesAction } from '../../../actions/currenciesAction';
import { getCountryListAction } from '../../shared/actions/countryAction';
import {
  resetCustomerProfileAction,
  getCustomerProfileByCodeAction,
  getUserDataByCodeAction,
} from '../../shared/actions/customerProfileAction';
import {
  postSupplierAction,
  putAccommodationSupplierAction,
} from './companyProfileAction';
import { getAccomodationListAction } from '../../shared/actions/accommodationAction';
class CompanyProfileDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contentButton: '',
      disabled: null,
      companyProfileObject: {},
      employeeSupplierList: [],
      errors: {},
      openModalAlert: false,
      headerAlert: '',
      contentAlert: '',
      openModalConfirm: false,
      message: '',
      isNew: false,
      isLogoUpdate: false,
      contentHeader: '',
      isReload: false,
      loadingSave: false,
    };
  }

  static propTypes = {
    loading: PropTypes.bool,
    getCurrenciesAction: PropTypes.func,
    getCountryListAction: PropTypes.func,
    countryListOptions: PropTypes.array,
    currenciesListOptions: PropTypes.array,
    getCustomerProfileByCodeStatus: PropTypes.string,
    getCustomerProfileByCode: PropTypes.array,
    getUserDataByCodeStatus: PropTypes.string,
    getUserDataByCode: PropTypes.array,
    getCustomerProfileByCodeAction: PropTypes.func,
    getUserDataByCodeAction: PropTypes.func,
    resetCustomerProfileAction: PropTypes.func,
    postSupplierAction: PropTypes.func,
    putAccommodationSupplierAction: PropTypes.func,
    accommodationOptions: PropTypes.array,
    getAccomodationListAction: PropTypes.func,
    loadingAccommodation: PropTypes.bool,
  };
  setInitialPage = (profileId, type) => {
    this.defaultApiCall();
    if (profileId !== '0') {
      this.defaultApiCallHaveCode(profileId);
      if (type === 'detail') {
        this.setState({
          contentButton: 'Edit',
          disabled: true,
          contentHeader: 'Supplier Detail',
        });
      } else if (type === 'edit') {
        this.setState({
          contentButton: 'Save',
          disabled: false,
          contentHeader: 'Edit Supplier',
        });
      }
    } else {
      this.setState({
        contentButton: 'Save',
        disabled: false,
        companyProfileObject: SetDataCompanyProfile(Number(profileId)),
        isNew: true,
        contentHeader: 'Add Supplier',
      });
    }
  };
  defaultApiCall = async () => {
    await this.props.getCurrenciesAction();
    await this.props.getCountryListAction();
    await this.props.getAccomodationListAction();
  };
  defaultApiCallHaveCode = async code => {
    await this.props.getCustomerProfileByCodeAction(code);
    await this.props.getUserDataByCodeAction(code);
  };
  componentDidMount = () => {
    // eslint-disable-next-line
    let { ProfileId, Type } = this.props.match.params;
    this.setInitialPage(ProfileId, Type);
  };
  componentDidUpdate() {
    if (this.props.getCustomerProfileByCodeStatus === 'success') {
      let { ProfileId } = this.props.match.params;
      const data = SetDataCompanyProfile(
        ProfileId,
        this.props.getCustomerProfileByCode
      );
      this.setState({ companyProfileObject: data });
      this.props.resetCustomerProfileAction();
    } else if (this.props.getCustomerProfileByCodeStatus === 'failed') {
      this.props.resetCustomerProfileAction();
    }
    if (this.props.getUserDataByCodeStatus === 'success') {
      this.setState({
        employeeSupplierList: setDataUserSupplier(this.props.getUserDataByCode),
      });
      this.props.resetCustomerProfileAction();
    } else if (this.props.getUserDataByCodeStatus === 'failed') {
      this.props.resetCustomerProfileAction();
    }
  }

  buttonBack = () => {
    // eslint-disable-next-line
    this.props.history.push('/company-profile-list');
  };

  saveCompanyProfile = () => {
    const { contentButton } = this.state;
    if (contentButton === 'Edit') {
      this.setState({
        contentButton: 'Save',
        disabled: false,
        contentHeader: 'Edit Supplier',
      });
    } else {
      const err = this.validate();
      if (!err) {
        let { companyProfileObject, isLogoUpdate } = this.state;
        const Id = companyProfileObject.CompanyId;
        let data = { ...companyProfileObject };
        if (!isLogoUpdate) {
          delete data.CompanyLogo;
        }
        if (Id === '') {
          this.handleApiSave(this.props.postSupplierAction(data), 'post');
        } else {
          this.handleApiSave(
            this.props.putAccommodationSupplierAction(Id, data),
            'put'
          );
        }
      }
    }
  };

  handleApiSave = (func, type) => {
    this.setState({ loadingSave: true });
    func
      .then(res => {
        this.handleAlert('Success', 'The supplier has been successfully saved');
        if (type === 'post') {
          // eslint-disable-next-line
          this.props.history.push(
            `/company-profile-detail/edit/${res.value.data.Code}`
          );
          this.setState({ isReload: true });
        }
        this.setState({ loadingSave: false });
      })
      .catch(err => {
        this.handleAlert('Failed', err.response.data.Message);
        this.setState({ loadingSave: false });
      });
  };

  handleChange = (e, { name, value }) => {
    const { companyProfileObject } = this.state;
    companyProfileObject[name] = value;
    this.setState({ companyProfileObject: companyProfileObject }, () =>
      this.validate()
    );
  };

  validate = () => {
    let isError = false;
    let { companyProfileObject } = this.state;
    let error = handleValidation(companyProfileObject, fieldToValidate);
    let errors = { ...error };
    isError = !_.isEmpty(errors);
    this.setState({
      errors: {
        ...errors,
      },
    });
    return isError;
  };

  handleChangeImage = e => {
    let { companyProfileObject } = this.state;
    let reader = new FileReader();
    let files = e.target.files[0];
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      let image = {
        ...companyProfileObject,
        CompanyLogo: {
          ImageName: files.name,
          ImageContent: reader.result.split(',')[1],
          ImagePreview: reader.result,
        },
      };
      this.setState({
        companyProfileObject: image,
        isLogoUpdate: true,
      });
    };
  };
  handleCloseAlert = () => {
    if (this.state.isReload) {
      window.location.reload();
    }
    this.setState({ openModalAlert: false, isReload: false });
  };
  handleCloseModalConfirm = () => {
    this.setState({ openModalConfirm: false });
  };
  handleAlert = (headerAlert, contentAlert) => {
    this.setState({
      openModalAlert: true,
      headerAlert: headerAlert,
      contentAlert: contentAlert,
    });
  };
  handleConfirm = message => {
    this.setState({
      openModalConfirm: true,
      message: message,
    });
  };
  render() {
    let {
      loading,
      countryListOptions,
      currenciesListOptions,
      accommodationOptions,
      loadingAccommodation,
    } = this.props;
    let {
      contentButton,
      disabled,
      errors,
      companyProfileObject,
      openModalAlert,
      headerAlert,
      contentAlert,
      message,
      openModalConfirm,
      employeeSupplierList,
      isNew,
      contentHeader,
    } = this.state;
    return (
      <React.Fragment>
        <SegmentCompanyProfileDetail
          buttonBack={this.buttonBack}
          buttonEdit={this.saveCompanyProfile}
          contentButton={contentButton}
          disabled={disabled}
          handleChange={this.handleChange}
          errorInformation={errors}
          contentDetail={companyProfileObject}
          handleChangeImage={this.handleChangeImage}
          countryListOptions={countryListOptions}
          currenciesListOptions={currenciesListOptions}
          accommodationOptions={accommodationOptions}
          employeeSupplierList={employeeSupplierList}
          isNew={isNew}
          loading={loading}
          contentHeader={contentHeader}
          loadingAccommodation={loadingAccommodation}
        />
        <ModalAlert
          openModal={openModalAlert}
          handleClose={this.handleCloseAlert}
          header={headerAlert}
          content={contentAlert}
          nameButton="OK"
        />
        <ModalConfirm
          openModal={openModalConfirm}
          size="mini"
          close={this.handleCloseModalConfirm}
          confirm={this.deleteTourGuide}
          message={message}
          header="Confirmation"
        />
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    loading:
      state.CustomerProfileStore.loadingGetUserByCode ||
      state.CustomerProfileStore.loading ||
      state.CompanyProfileStore.loading,
    currenciesListOptions: state.currenciesStore.currencies.map(type => {
      return { text: type.Id, value: type.Id, key: type.Id };
    }),
    countryListOptions: state.CountryStore.getCountryList.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    getCustomerProfileByCode:
      state.CustomerProfileStore.getCustomerProfileByCode,
    getCustomerProfileByCodeStatus:
      state.CustomerProfileStore.getCustomerProfileByCodeStatus,
    getUserDataByCode: state.CustomerProfileStore.getUserDataByCode,
    getUserDataByCodeStatus: state.CustomerProfileStore.getUserDataByCodeStatus,
    accommodationOptions: state.AccomodationStore.getAccomodationList.map(
      data => {
        return { text: data.Name, value: data.Id, key: data.Id };
      }
    ),
    loadingAccommodation: state.AccomodationStore.loadingGetAccommodationList,
  };
}
export default connect(
  mapStateToProps,
  {
    getCurrenciesAction,
    getCountryListAction,
    resetCustomerProfileAction,
    getCustomerProfileByCodeAction,
    putAccommodationSupplierAction,
    getUserDataByCodeAction,
    getAccomodationListAction,
    postSupplierAction,
  }
)(CompanyProfileDetail);
