import {
  GET_LOCKED_USER_LIST,
  GET_USER_ACCESS_LIST,
  RESET_STATUS_USER_ACCESS,
  POST_UNLOCK_USER,
  GET_USER_PROFILE_BY_USER_ID,
  POST_RESET_PASSWORD,
  GET_USER_ROLE_BY_BEARER,
  GET_USER_PROFILE_ONLY_BY_USER_ID,
} from './actionTypes';
import getUserAccessListApi from '../api/getUserAccessListApi';
import getLockedUserListApi from '../api/getLockedUserListApi';
import getUserProfileByUserIdApi from '../api/userProfile/getUserProfileByUserIdApi';
import postResetPasswordApi from '../api/userProfile/postResetPasswordApi';
import postUnlockUserApi from '../api/userProfile/postUnlockUserApi';
import getUserRoleByBearerApi from '../api/userProfile/getUserRoleByBearerApi';
import getUserProfileOnlyByIdApi from '../api/userProfile/getUserProfileOnlyByIdApi';

const getUserAccessListAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_USER_ACCESS_LIST,
      payload: getUserAccessListApi(),
    });
  };
};

const getLockedUserListAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_LOCKED_USER_LIST,
      payload: getLockedUserListApi(),
    });
  };
};

const resetUserAccessAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_STATUS_USER_ACCESS,
    });
  };
};
const postUnlockUserAction = Id => {
  return dispatch => {
    return dispatch({
      type: POST_UNLOCK_USER,
      payload: postUnlockUserApi(Id),
    });
  };
};

const getUserProfileByUserIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_USER_PROFILE_BY_USER_ID,
      payload: getUserProfileByUserIdApi(Id),
    });
  };
};

const postResetPasswordAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_RESET_PASSWORD,
      payload: postResetPasswordApi(data),
    });
  };
};
const getUserRoleByBearerAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_USER_ROLE_BY_BEARER,
      payload: getUserRoleByBearerApi(),
    });
  };
};

const getUserProfileOnlyByIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_USER_PROFILE_ONLY_BY_USER_ID,
      payload: getUserProfileOnlyByIdApi(Id),
    });
  };
};

export {
  getUserAccessListAction,
  getLockedUserListAction,
  resetUserAccessAction,
  postUnlockUserAction,
  getUserProfileByUserIdAction,
  postResetPasswordAction,
  getUserRoleByBearerAction,
  getUserProfileOnlyByIdAction,
};
