import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Grid, Icon, Header } from 'semantic-ui-react';
import Filter from './../formFilter';
import ListCard from './listCard';
// import ButtonClose from './../buttonClose';
import ButtonClose from './../../../button/buttonCloseModal';
import styles from './../../styles';
// import { resetStatusAccommodation } from './../../../actions/accommodationAction';
// import { resetStatusTransaction } from './../../../actions/tourTransactionAction';
// import {} from './../../../../actions'
import { getMasterReferenceAction } from '../../../../pages/shared/actions/uploadMasterDataReferenceAction';

const options = [
  { key: 'HS', value: 'highest rating', text: 'Highest Star' },
  { key: 'LS', value: 'lowest rating', text: 'Lowest Star' },
];
class modalListAccommodation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formFilter: [],
      activePage: 1,
      searchName: '',
      activeSort: '',
      openModalRoom: false,
      selectedAccommodation: 0,
    };
  }
  componentDidUpdate = prevProps => {
    let formFilter = [...this.state.formFilter];
    if (prevProps.open !== this.props.open) {
      this.setState({
        ...this.state,
        activePage: 1,
        searchName: '',
        activeSort: '',
      });
    }
    if (prevProps.open !== this.props.open) {
      this.props.getMasterReferenceAction('AccommodationRatings').then(() => {
        if (
          formFilter.findIndex(e => e.name === 'Accommodation Star Rating') ===
          -1
        ) {
          formFilter.push({
            name: 'Accommodation Star Rating',
            data: this.showStar(this.props.getMasterReferenceList),
          });
          this.setState({ ...this.state, formFilter });
        }
      });
      this.props.getMasterReferenceAction('AccommodationTypes').then(() => {
        if (formFilter.findIndex(e => e.name === 'Accommodation Type') === -1) {
          // let formFilter = [...this.state.formFilter];
          formFilter.push({
            name: 'Accommodation Type',
            data: this.showOptions(this.props.getMasterReferenceList),
          });
          this.setState({ ...this.state, formFilter });
        }
      });
      this.props.getMasterReferenceAction('AccommodationLocations').then(() => {
        if (
          formFilter.findIndex(e => e.name === 'Accommodation Location') === -1
        ) {
          // let formFilter = [...this.state.formFilter];
          formFilter.push({
            name: 'Accommodation Location',
            data: this.showOptions(this.props.getMasterReferenceList),
          });
          this.setState({ ...this.state, formFilter });
        }
      });
      this.props
        .getMasterReferenceAction('AccommodationFacilities')
        .then(() => {
          if (
            formFilter.findIndex(e => e.name === 'Accommodation Facilities') ===
            -1
          ) {
            // let formFilter = [...this.state.formFilter];
            formFilter.push({
              name: 'Accommodation Facilities',
              data: this.showOptions(this.props.getMasterReferenceList),
            });
            this.setState({ ...this.state, formFilter });
          }
        });
    }
  };

  // componentDidUpdate = () => {

  //   if (
  //     this.props.accommodationRatingStatus === 'success' &&
  //     this.props.accommodationTypeStatus === 'success' &&
  //     this.props.accommodationLocationStatus === 'success' &&
  //     this.props.accommodationFacilitiesStatus === 'success' &&
  //     this.props.tourTypeStatus === 'success'
  //   ) {
  //     let data = [
  //       {
  //         name: 'Accommodation Star Rating',
  //         data: this.showStar(this.props.listAccommodationRatings),
  //       },
  //       {
  //         name: 'Accommodation Type',
  //         data: this.showOptions(this.props.listAccommodationType),
  //       },
  //       {
  //         name: 'Accommodation Facilities',
  //         data: this.showOptions(this.props.listAccommodationFacilities),
  //       },
  //       {
  //         name: 'Accommodation Location',
  //         data: this.showOptions(this.props.listAccommodationLocation),
  //       },
  //       {
  //         name: 'Accommodation Segment',
  //         data: this.showOptions(this.props.listTourTypes),
  //       },
  //     ];
  //     this.setState({
  //       ...this.state,
  //       formFilter: data,
  //     });
  //     // this.props.resetStatusAccommodation();
  //     // this.props.resetStatusTransaction();
  //   }
  // };
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    name: PropTypes.string,
    data: PropTypes.array,
    location: PropTypes.string,
    // listAccommodationRatings: PropTypes.array,
    // accommodationRatingStatus: PropTypes.string,
    // resetStatusAccommodation: PropTypes.func,
    // listAccommodationType: PropTypes.array,
    // accommodationTypeStatus: PropTypes.string,
    // listAccommodationLocation: PropTypes.array,
    // accommodationLocationStatus: PropTypes.string,
    // listAccommodationFacilities: PropTypes.array,
    // accommodationFacilitiesStatus: PropTypes.string,
    // listTourTypes: PropTypes.array,
    // tourTypeStatus: PropTypes.string,
    // resetStatusTransaction: PropTypes.func,
    // loadingAccommodation: PropTypes.bool,
    // loadingTourTransaction: PropTypes.bool,
    // loadingGetAccommodation: PropTypes.bool,
    headlineProgram: PropTypes.object,
    setAccommodationRoom: PropTypes.func,
    accomIndex: PropTypes.number,
    locationName: PropTypes.string,
    getMasterReferenceAction: PropTypes.func,
    getMasterReferenceList: PropTypes.array,
    loadingGetMasterData: PropTypes.bool,
    loadingAccommodation: PropTypes.bool,
    dataGetAccommodation: PropTypes.object,
    currentPackage: PropTypes.object,
    tourInformation: PropTypes.object,
  };
  showOptions = data => {
    return data.map(e => {
      return { key: e.Id, text: e.Name, checked: false };
    });
  };
  showStar = listRating => {
    let firstData = parseInt(listRating[0].Id, 10);
    let secondData = parseInt(listRating[1].Id, 10);
    listRating = firstData < secondData ? listRating.reverse() : listRating;
    return listRating
      .sort((a, b) => parseInt(a.Id, 10) - parseInt(b.Id, 10))
      .reverse()
      .map(data => {
        let stars = new Array(parseInt(data.Id, 10)).fill(0);
        let text = (
          <label key={data.Id}>
            {stars.length !== 0
              ? stars.map((star, index) => (
                  <Icon key={star + index} name="star" color="yellow" />
                ))
              : 'Unrated'}
          </label>
        );
        return {
          key: data.Id,
          text,
          checked: false,
        };
      });
  };
  filterAccommodation = (data, formFilter, searchName) => {
    let dataFilter, indexForm, filters;
    return data.filter(data => {
      let {
        AccommodationRating,
        AccommodationType,
        AccommodationLocations,
        // AccommodationSegment,
        ProfileFacilities,
      } = data;
      dataFilter = true;
      if (searchName) {
        dataFilter =
          dataFilter &&
          data.Name.toLowerCase().indexOf(searchName.toLowerCase()) !== -1;
      }
      indexForm = formFilter.findIndex(
        e => e.name === 'Accommodation Star Rating'
      );
      if (
        formFilter[indexForm] &&
        formFilter[indexForm].data.some(e => e.checked)
      ) {
        dataFilter =
          dataFilter &&
          formFilter[indexForm].data.findIndex(
            e => e.key === AccommodationRating.Id && e.checked
          ) !== -1;
      }
      indexForm = formFilter.findIndex(e => e.name === 'Accommodation Type');
      if (
        formFilter[indexForm] &&
        formFilter[indexForm].data.some(e => e.checked)
      ) {
        dataFilter =
          dataFilter &&
          formFilter[indexForm].data.findIndex(
            e => e.key === AccommodationType.Id && e.checked
          ) !== -1;
      }
      indexForm = formFilter.findIndex(
        e => e.name === 'Accommodation Location'
      );
      filters = formFilter[indexForm]
        ? formFilter[indexForm].data.filter(e => {
            return e.checked === true;
          })
        : [];
      dataFilter =
        dataFilter &&
        filters.every(filter => {
          return (
            AccommodationLocations.findIndex(e => e.Name === filter.text) !== -1
          );
        });
      // indexForm = formFilter.findIndex(e => e.name === 'Accommodation Segment');
      // filters = formFilter[indexForm].data.filter(e => {
      //   return e.checked === true;
      // });
      // dataFilter =
      //   dataFilter &&
      //   filters.every(filter => {
      //     return (
      //       AccommodationSegment.findIndex(e => e.Name === filter.text) !== -1
      //     );
      //   });
      indexForm = formFilter.findIndex(
        e => e.name === 'Accommodation Facilities'
      );
      filters = formFilter[indexForm]
        ? formFilter[indexForm].data.filter(e => {
            return e.checked === true;
          })
        : [];
      dataFilter =
        dataFilter &&
        filters.every(filter => {
          return (
            ProfileFacilities.findIndex(e => e.Name === filter.text) !== -1
          );
        });
      return dataFilter;
    });
  };
  filteredData = data => {
    let { formFilter, searchName } = this.state;
    return this.filterAccommodation(data, formFilter, searchName);
  };
  handleChangeCheckbox = (e, { name, value }) => {
    let dataList = this.state.formFilter;
    let indexForm = dataList.findIndex(e => e.name === name);
    let indexData = dataList[indexForm].data.findIndex(e => e.key === value);
    dataList[indexForm].data[indexData].checked = !dataList[indexForm].data[
      indexData
    ].checked;
    this.setState({ ...this.state, formFilter: dataList });
  };
  handleResetFilter = () => {
    let { formFilter } = this.state;
    formFilter = formFilter.map(filter =>
      filter.data.some(e => e.checked)
        ? { name: filter.name, data: this.resetFilter(filter.data) }
        : filter
    );
    return this.setState({ ...this.state, formFilter });
  };
  resetFilter = listData => {
    return listData.map(data => {
      return {
        key: data.key,
        text: data.text,
        checked: false,
        desc: data.desc,
      };
    });
  };
  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  compare = (a, b) => {
    let comparison = 0;

    if (a.AccommodationRating.Id > b.AccommodationRating.Id) {
      comparison = 1;
    } else if (b.AccommodationRating.Id > a.AccommodationRating.Id) {
      comparison = -1;
    }
    return comparison;
  };
  sortData = (listAccommodation, type) => {
    return type === 'lowest rating'
      ? listAccommodation.sort(this.compare)
      : type === 'highest rating' &&
          listAccommodation.sort(this.compare).reverse();
  };

  openRoomModal = idAccom => {
    this.setState({
      ...this.state,
      openModalRoom: true,
      selectedAccommodation: idAccom,
    });
  };

  render() {
    let {
      open,
      onClose,
      name,
      data,
      location,
      loadingGetMasterData,
      loadingAccommodation,
      accomIndex,
      headlineProgram,
      setAccommodationRoom,
      locationName,
      dataGetAccommodation,
      currentPackage,
      tourInformation,
    } = this.props;
    let { formFilter, searchName, activePage, activeSort } = this.state;
    data = formFilter.length > 0 ? this.filteredData(data ? data : []) : data;
    data = activeSort ? this.sortData(data, activeSort) : data;
    let loading = loadingGetMasterData && data.length === 0;
    data = !loadingAccommodation ? data : [];
    return (
      <Modal open={open} onClose={onClose} size="small" closeOnDimmerClick>
        <Modal.Header style={styles.backgroundModal}>
          {name}
          <ButtonClose onClose={onClose} />
        </Modal.Header>
        <Modal.Content style={styles.backgroundModal}>
          <Modal.Description>
            <Grid>
              <Grid.Column width={5}>
                <Header as="h5">Showing {data.length} Accommodation</Header>
                <Filter
                  formFilter={formFilter}
                  handleChange={this.handleChangeCheckbox}
                  resetFilter={this.handleResetFilter}
                  loading={loading}
                />
              </Grid.Column>
              <Grid.Column width={11}>
                {/* loading get accommodation */}
                <ListCard
                  location={location}
                  locationName={locationName}
                  data={data}
                  loading={loadingAccommodation}
                  handleChange={this.handleChange}
                  searchName={searchName}
                  activePage={activePage}
                  pageChange={this.pageChange}
                  options={options}
                  activeSort={activeSort}
                  onClose={onClose}
                  accomIndex={accomIndex}
                  headlineProgram={headlineProgram}
                  setAccommodationRoom={setAccommodationRoom}
                  openRoomModal={this.openRoomModal}
                  dataGetAccommodation={dataGetAccommodation}
                  currentPackage={currentPackage}
                  tourInformation={tourInformation}
                />
              </Grid.Column>
              {/* <Loader description="Loading" active={loading} /> */}
            </Grid>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}
const mapStateToProps = state => {
  return {
    getMasterReferenceList:
      state.MasterDataReferenceStore.getMasterReferenceList,
    loadingGetMasterData: state.MasterDataReferenceStore.loading,
    loadingAccommodation: state.AccomodationStore.loading,
    // listAccommodationRatings: state.accommodation.accommodationRatings,
    // accommodationRatingStatus: state.accommodation.getAccommodationRatingStatus,
    // listAccommodationType: state.accommodation.accommodationTypes,
    // accommodationTypeStatus: state.accommodation.getAccommodationTypeStatus,
    // listAccommodationLocation: state.accommodation.accommodationLocations,
    // accommodationLocationStatus:
    //   state.accommodation.getAccommodationLocationStatus,
    // listAccommodationFacilities: state.accommodation.accommodationFacilities,
    // accommodationFacilitiesStatus:
    //   state.accommodation.getAccommodationFacilitiesStatus,
    // listTourTypes: state.tourTransaction.tourTypes,
    // tourTypeStatus: state.tourTransaction.getTourTypeStatus,
    // loadingAccommodation: state.accommodation.loading,
    // loadingTourTransaction: state.tourTransaction.loading,
    // loadingGetAccommodation: state.accommodation.loadingGetAccommodation,
  };
};
export default connect(mapStateToProps, {
  // resetStatusAccommodation,
  // resetStatusTransaction,
  getMasterReferenceAction,
})(modalListAccommodation);
