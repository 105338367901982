import React, { Component } from 'react';
import styles from './Styles';
import { Segment, Grid, Header, Icon, Button } from 'semantic-ui-react';
import GuestForm from './GuestForm';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectTourLeader } from '../../../../scripts/validation';
import { getCountryListAction } from '../../actions/countryAction';
import {
  getIdentityType,
  getGuestTitleType,
} from '../../../../actions/guestAction';
import CollapseButton from '../../../../components/button/collapseButton';
import { Collapse } from 'react-collapse';
class SegmentFormGuest extends Component {
  state = {
    openCollapse: false,
    buttonContent: 'Edit',
  };
  handleCollapse = (e, { name, value }) =>
    this.setState({ ...this.state, [name]: !value });
  componentDidMount = () => {
    this.props.getCountryListAction();
    this.props.getIdentityType();
    this.props.getGuestTitleType();
  };
  handleSaveEdit = (e, { name }) => {
    if (name === 'Edit') {
      this.setState({ buttonContent: 'Save' });
    } else {
      this.props.handleSaveGuest();
    }
  };

  render() {
    const { buttonContent } = this.state;
    let {
      guests,
      countries,
      listIdentityType,
      listGuestTitleType,
      loadingCountry,
      loadingGuest,
      handleChange,
      errors,
      isCustomOrReadyPackage,
      currentPackage,
      statusPackage,
      loading,
      isAcceptedQuotation,
    } = this.props;
    let tourLeader = selectTourLeader(guests);
    let idTourLeader = tourLeader ? tourLeader.Id : 0;
    let adults = guests.filter(
      e => e.GuestCategory === 'ADULT' && e.Id !== idTourLeader
    );
    let children = guests.filter(e => e.GuestCategory === 'CHILDREN');
    let infants = guests.filter(e => e.GuestCategory === 'INFANT');
    const { openCollapse } = this.state;

    let disableGuestForm =
      this.state.buttonContent === 'Edit' &&
      (currentPackage.type === 'Quotation' && isAcceptedQuotation
        ? true
        : currentPackage.type !== 'Quotation') &&
      currentPackage.status === 'edit';
    let editByStatus =
      statusPackage === 'Booking_created' ||
      statusPackage === 'Booking_DP_Confirmed' ||
      statusPackage === 'Booking_In_Payment';

    return (
      <Segment raised fluid>
        <div style={styles.marginContentInsideCard}>
          {/* <div style={{ margin: '5px 0px' }}> */}
          <Grid verticalAlign="middle">
            <Grid.Row style={styles.noPaddingBottom}>
              <Grid.Column width={14}>
                <Header as="h3" style={styles.marginHeader}>
                  <Icon name="users" style={{ color: '#38af95' }} />
                  Guest List
                </Header>
              </Grid.Column>
              <Grid.Column width={2} textAlign="left">
                <CollapseButton
                  isCollapse={openCollapse}
                  handleCollapse={this.handleCollapse}
                  name="openCollapse"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Collapse isOpened={openCollapse} style={{ margin: '0px' }}>
            <Segment.Group style={styles.noBorder}>
              {editByStatus &&
              currentPackage &&
              currentPackage.status === 'edit' &&
              currentPackage.type !== 'Quotation' ? (
                <Grid>
                  <Grid.Row textAlign="right">
                    <Grid.Column>
                      <Button
                        name={this.state.buttonContent}
                        primary
                        onClick={this.handleSaveEdit}
                        loading={loading}
                      >
                        {buttonContent} Guest
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              ) : (
                ''
              )}

              <Segment style={styles.noBorder}>
                {guests.length === 0 && <h5>No Guest</h5>}
                {isCustomOrReadyPackage
                  ? tourLeader && <h5>Tour Leader</h5>
                  : tourLeader && <h5>Adult &#40; &#62; 12 Years &#41;</h5>}
                {tourLeader && (
                  <div>
                    <GuestForm
                      key={tourLeader.Id}
                      listCountry={countries}
                      listTitleType={listGuestTitleType}
                      listIdentityType={listIdentityType}
                      loadingCountry={loadingCountry}
                      loadingIdentityType={loadingGuest}
                      loadingTitleType={loadingGuest}
                      identityType={tourLeader.IdentityType}
                      identityNbr={tourLeader.IdentityNbr}
                      countryId={tourLeader.CountryId}
                      guestTitle={tourLeader.GuestTitle}
                      firstName={tourLeader.FirstName}
                      lastName={tourLeader.LastName}
                      handleChange={handleChange}
                      id={tourLeader.Id}
                      validate={errors}
                      // disabled={currentPackage.disabledEdit}
                      disabled={disableGuestForm}
                    />
                  </div>
                )}
                {isCustomOrReadyPackage && (
                  <h5>
                    Tour Member <br />
                    <br /> Adult &#40; &#62; 12 Years &#41;
                  </h5>
                )}
                {adults.length !== 0 &&
                  adults.map(adult => (
                    <GuestForm
                      key={adult.Id}
                      listCountry={countries}
                      listTitleType={listGuestTitleType}
                      listIdentityType={listIdentityType}
                      loadingCountry={loadingCountry}
                      loadingIdentityType={loadingGuest}
                      loadingTitleType={loadingGuest}
                      identityType={adult.IdentityType}
                      identityNbr={adult.IdentityNbr}
                      countryId={adult.CountryId}
                      guestTitle={adult.GuestTitle}
                      firstName={adult.FirstName}
                      lastName={adult.LastName}
                      handleChange={handleChange}
                      id={adult.Id}
                      // disabled={currentPackage.disabledEdit}
                      disabled={disableGuestForm}
                    />
                  ))}
              </Segment>
              {children.length !== 0 && (
                <Segment style={styles.noBorder}>
                  <h5>Children &#40; 2-12 Years &#41;</h5>
                  {children.map(child => (
                    <GuestForm
                      key={child.Id}
                      listCountry={countries}
                      listTitleType={listGuestTitleType}
                      listIdentityType={listIdentityType}
                      loadingCountry={loadingCountry}
                      loadingIdentityType={loadingGuest}
                      loadingTitleType={loadingGuest}
                      identityType={child.IdentityType}
                      identityNbr={child.IdentityNbr}
                      countryId={child.CountryId}
                      guestTitle={child.GuestTitle}
                      firstName={child.FirstName}
                      lastName={child.LastName}
                      handleChange={handleChange}
                      id={child.Id}
                      // disabled={currentPackage.disabledEdit}
                      disabled={disableGuestForm}
                    />
                  ))}
                </Segment>
              )}
              {infants.length !== 0 && (
                <Segment style={styles.noBorder}>
                  <h5>Infants &#40; &#60; 2 Years &#41;</h5>{' '}
                  {infants.map(infant => (
                    <GuestForm
                      key={infant.Id}
                      listCountry={countries}
                      listTitleType={listGuestTitleType}
                      listIdentityType={listIdentityType}
                      loadingCountry={loadingCountry}
                      loadingIdentityType={loadingGuest}
                      loadingTitleType={loadingGuest}
                      identityType={infant.IdentityType}
                      identityNbr={infant.IdentityNbr}
                      countryId={infant.CountryId}
                      guestTitle={infant.GuestTitle}
                      firstName={infant.FirstName}
                      lastName={infant.LastName}
                      handleChange={handleChange}
                      id={infant.Id}
                      // disabled={currentPackage.disabledEdit}
                      disabled={disableGuestForm}
                    />
                  ))}
                </Segment>
              )}
            </Segment.Group>
          </Collapse>
        </div>
      </Segment>
    );
  }
}
SegmentFormGuest.propTypes = {
  guests: PropTypes.object,
  countries: PropTypes.array,
  listIdentityType: PropTypes.array,
  listGuestTitleType: PropTypes.array,
  loadingCountry: PropTypes.bool,
  loadingGuest: PropTypes.bool,
  handleChange: PropTypes.func,
  errors: PropTypes.object,
  isCustomOrReadyPackage: PropTypes.bool,
  getCountryListAction: PropTypes.func,
  getIdentityType: PropTypes.func,
  getGuestTitleType: PropTypes.func,
  currentPackage: PropTypes.object,
  handleSaveGuest: PropTypes.func,
  statusPackage: PropTypes.string,
  loading: PropTypes.bool,
  isAcceptedQuotation: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    countries: state.CountryStore.getCountryList.map(type => {
      return { text: type.Name, value: type.Id };
    }),
    listIdentityType: state.GuestStore.listIdentityType.map(type => {
      return { text: type.Description, value: type.Name };
    }),
    listGuestTitleType: state.GuestStore.listGuestTitleType.map(type => {
      return { text: type.Description, value: type.Name };
    }),
    loadingCountry: state.CountryStore.loading,
    loadingGuest: state.GuestStore.loading,
  };
};
export default connect(mapStateToProps, {
  getCountryListAction,
  getIdentityType,
  getGuestTitleType,
})(SegmentFormGuest);
