const convertTourPrice = tourPriceSum => {
  tourPriceSum = [
    {
      name: 'Sharing Room',
      data: tourPriceSum ? tourPriceSum.SharingRoomSum : {},
    },
    {
      name: 'Child Sharing Room',
      data: tourPriceSum ? tourPriceSum.ChildSharingRoomSum : {},
    },
    {
      name: 'Single Room',
      data: tourPriceSum ? tourPriceSum.SingleRoomSum : {},
    },
    {
      name: 'Child Single Room',
      data: tourPriceSum ? tourPriceSum.ChildSingleRoomSum : {},
    },
    {
      name: 'Extra Bed',
      data: tourPriceSum ? tourPriceSum.ExtraBedSum : {},
    },
    {
      name: 'Child Extra Bed',
      data: tourPriceSum ? tourPriceSum.ChildExtraBedSum : {},
    },
    {
      name: 'Sharing Bed with Parents',
      data: tourPriceSum ? tourPriceSum.SharingBedSum : {},
    },
    {
      name: 'Baby Crib / No Bed',
      data: tourPriceSum ? tourPriceSum.NoBedSum : {},
    },
  ];
  return tourPriceSum;
};

const copyObject = obj => {
  let newObj = JSON.stringify(obj);
  return JSON.parse(newObj);
};

const getSupplement = supplements => {
  let copySupplement = copyObject(supplements);
  let newSupplements = [];
  copySupplement.map(item => {
    return newSupplements.push({
      BookingTemplateId: item.bookingTemplateId,
      BookingTemplateSupplementId: item.bookingTemplateSupplementId,
      CurrencyId: item.currencyId,
      Qty: item.qty,
    });
  });
  return newSupplements;
};
const checkGuest = guest => {
  return (
    guest.CountryId !== '' &&
    guest.FirstName !== '' &&
    guest.GuestCategory !== '' &&
    guest.GuestTitle !== '' &&
    guest.GuestType !== '' &&
    guest.IdentityNbr !== '' &&
    guest.IdentityType !== '' &&
    guest.LastName !== ''
  );
};
const getGuests = listGuests => {
  let copyListGuests = copyObject(listGuests);
  let newListGuests = [];
  copyListGuests.map(item => {
    return (
      checkGuest(item) &&
      newListGuests.push({
        CountryId: item.CountryId,
        FirstName: item.FirstName,
        GuestCategory: item.GuestCategory,
        GuestTitle: item.GuestTitle,
        GuestType: item.GuestType,
        IdentityNbr: item.IdentityNbr,
        IdentityType: item.IdentityType,
        LastName: item.LastName,
      })
    );
  });
  return newListGuests;
};
const convertDataBookNowFixed = (
  splitCommision,
  printCommision,
  guests,
  roomAllocation,
  supplements,
  listGuests
  // tourNote
) => {
  let selectCommission =
    splitCommision === 1 ? true : printCommision === 0 ? null : false;
  let data = {
    // TourNote: tourNote,
    Supplements: getSupplement(supplements),
    IsQuotation: false,
    AdultPax: guests.adults,
    ChildPax: guests.childs,
    InfantPax: guests.infants,
    RoomAllocation: {
      ChildExtraBedQty: roomAllocation.ChildExtraBedQty,
      ExtraBedQty: roomAllocation.ExtraBedQty,
      NoBed: roomAllocation.NoBed,
      SharingBedQty: roomAllocation.SharingBedQty,
      SharingRoomQty: roomAllocation.SharingRoomQty,
      SingleRoomQty: roomAllocation.SingleRoomQty,
    },
    Guests: getGuests(listGuests),
  };
  selectCommission !== null && (data.IsSplitStaffCommission = selectCommission);
  return data;
};
const getTotalPayable = bookingDetailSum => {
  return bookingDetailSum
    ? bookingDetailSum.FixedPackage
      ? bookingDetailSum.FixedPackage.BookingCommission.TotalPriceForCustomer
      : 0
    : 0;
};

const getRoomPax = (rooms, name) => {
  return rooms ? rooms[name].Pax : 0;
};
const setCommissionAmount = (commissions, guests) => {
  let pax = guests.adults + guests.childs;
  const copyCommissions = copyObject(commissions);
  copyCommissions.map(commission => {
    commission.TotalPax = pax;
    commission.TotalPrice = commission.UnitPrice * pax;
    return commission;
  });
  // let AgentCommission = copyCommissions.AgentCommission;
  // AgentCommission.TotalPax = pax;
  // AgentCommission.TotalPrice = pax * AgentCommission.UnitPrice;
  // let ApplicableCommission = copyCommissions.ApplicableCommission;
  // ApplicableCommission.TotalPax = pax;
  // ApplicableCommission.TotalPrice = pax * ApplicableCommission.UnitPrice;
  // let StaffCommission = copyCommissions.StaffCommission;
  // StaffCommission.TotalPax = pax;
  // StaffCommission.TotalPrice = pax * StaffCommission.UnitPrice;
  // console.log(copyCommissions);
  return copyCommissions;
};
const getTotalPrice = (tourPriceSum, supplements) => {
  const totalPriceRoom = getTotalPriceRoom(tourPriceSum);
  const totalSupplements = getTotalSupplements(supplements);
  return totalPriceRoom + totalSupplements;
};
const getTotalSupplements = supplements => {
  return supplements.reduce((prev, cur) => {
    return prev + cur.totalCost;
  }, 0);
};
const getTotalPriceRoom = tourPriceSum => {
  return tourPriceSum.ChildExtraBedSum
    ? tourPriceSum.ChildExtraBedSum.TotalPrice +
        tourPriceSum.ChildSharingRoomSum.TotalPrice +
        tourPriceSum.ChildSingleRoomSum.TotalPrice +
        tourPriceSum.ExtraBedSum.TotalPrice +
        tourPriceSum.NoBedSum.TotalPrice +
        tourPriceSum.SharingBedSum.TotalPrice +
        tourPriceSum.SharingRoomSum.TotalPrice +
        tourPriceSum.SingleRoomSum.TotalPrice
    : 0;
};
const getTotalPayablePartialPayment = (commissions, totalPrice) => {
  const totalCommission = commissions
    ? commissions.reduce((prev, next) => {
        return prev + next.TotalPrice;
      }, 0)
    : 0;
  return totalPrice - totalCommission;
};
const getUpdateGuestPayment = (guests, roomAllocation, guestsList) => {
  let data = {
    AdultPax: guests.adults,
    ChildPax: guests.childs,
    InfantPax: guests.infants,
    RoomAllocation: {
      SharingRoomQty: roomAllocation.SharingRoomSum.Pax,
      SingleRoomQty: roomAllocation.SingleRoomSum.Pax,
      ChildSharingRoomQty: roomAllocation.ChildSharingRoomSum.Pax,
      ChildSingleRoomQty: roomAllocation.ChildSingleRoomSum.Pax,
      ExtraBedQty: roomAllocation.ExtraBedSum.Pax,
      ChildExtraBedQty: roomAllocation.ChildExtraBedSum.Pax,
      SharingBedQty: roomAllocation.SharingBedSum.Pax,
      NoBed: roomAllocation.NoBedSum.Pax,
    },
    Guests: getGuestsPayment(guestsList),
  };
  return data;
};
const getGuestsPayment = guestsList => {
  let newGuests = [];
  guestsList.map(guest => {
    return newGuests.push({
      CountryId: guest.CountryId,
      FirstName: guest.FirstName,
      GuestCategory: guest.GuestCategory,
      GuestTitle: guest.GuestTitle,
      GuestType: guest.GuestType,
      Id: guest.Id,
      IdentityNbr: guest.IdentityNbr,
      IdentityType: guest.IdentityType,
      LastName: guest.LastName,
      IsActive: !guest.IsDelete,
    });
  });
  return newGuests;
};
const getSupplementPayment = supplements => {
  let newSupplements = [];
  supplements.map(supplement => {
    return newSupplements.push({
      Id: supplement.bookingTemplateSupplementId,
      PaymentValue: supplement.paymentValue,
      Qty: supplement.qty,
    });
  });
  return newSupplements;
};
const convertStatus = (status, isAcceptedQuotation) => {
  let textStatus = [
    { key: 'Booking_created', value: 'Created' },
    {
      key: 'Booking_hold',
      value: 'Created (On Hold)',
    },
    {
      key: 'Booking_DP_Confirmed',
      value: 'DP confirm',
    },
    {
      key: 'Booking_SP_Confirmed',
      value: 'SP confirm',
    },
    {
      key: 'Booking_In_Payment',
      value: 'In Payment',
    },
    { key: 'Booking_Confirmed', value: 'Confirmed' },
    {
      key: 'Booking_Completed',
      value: 'Completed',
    },
    {
      key: 'Booking_Cancelled',
      value: 'Cancelled',
    },
    {
      key: 'Booking_draft',
      value: 'Quotation',
    },
  ];
  let newStatus = textStatus.filter(e => e.key === status)[0];
  isAcceptedQuotation && (newStatus.value = 'Quotation Accepted');
  return newStatus;
};

const getAdditionalItems = data => {
  return data.map(item => {
    return {
      ...item,
      AdditionalItemTypeId: parseInt(item.AdditionalItemType.Value),
    };
  });
};

export {
  convertTourPrice,
  convertDataBookNowFixed,
  getRoomPax,
  setCommissionAmount,
  getTotalPrice,
  getTotalPayable,
  getTotalPayablePartialPayment,
  getUpdateGuestPayment,
  getGuests,
  getGuestsPayment,
  getSupplementPayment,
  convertStatus,
  getAdditionalItems,
};
