import * as types from './constant/actionTypes';

const initialState = {
  deleteAreaStatus: '',
  postAreaStatus: '',
  putAreaStatus: '',
  getAreaById: [],
  getAreaByIdStatus: '',
  errors: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //
    case types.RESET_AREA_PAGE:
      return {
        ...state,
        deleteAreaStatus: '',
        getAreaByIdStatus: '',
        postAreaStatus: '',
        putAreaStatus: '',
      };

    //
    case types.GET_AREA_LIST:
      return { ...state };
    case types.GET_AREA_LIST_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getAreaStatus: 'failed',
        loading: false,
      };
    case types.GET_AREA_LIST_PENDING:
      return { ...state, loading: true };
    case types.GET_AREA_LIST_FULFILLED:
      return {
        ...state,
        getArea: action.payload.data,
        loading: false,
        getAreaStatus: 'success',
      };

    case types.DELETE_AREA:
      return { ...state };
    case types.DELETE_AREA_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        deleteAreaStatus: 'failed',
      };
    case types.DELETE_AREA_PENDING:
      return { ...state, loading: true };
    case types.DELETE_AREA_FULFILLED:
      return {
        ...state,
        loading: false,
        deleteAreaStatus: 'success',
      };
    case types.GET_AREA_BY_ID:
      return { ...state };
    case types.GET_AREA_BY_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getAreaByIdStatus: 'failed',
      };
    case types.GET_AREA_BY_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_AREA_BY_ID_FULFILLED:
      return {
        ...state,
        getAreaById: action.payload.data,
        loading: false,
        getAreaByIdStatus: 'success',
      };
    case types.POST_AREA:
      return { ...state };
    case types.POST_AREA_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        postAreaStatus: 'failed',
      };
    case types.POST_AREA_PENDING:
      return { ...state, loading: true };
    case types.POST_AREA_FULFILLED:
      return {
        ...state,
        loading: false,
        postAreaStatus: 'success',
      };
    case types.PUT_AREA:
      return { ...state };
    case types.PUT_AREA_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        putAreaStatus: 'failed',
      };
    case types.PUT_AREA_PENDING:
      return { ...state, loading: true };
    case types.PUT_AREA_FULFILLED:
      return {
        ...state,
        loading: false,
        putAreaStatus: 'success',
      };
    default:
      return state;
  }
};

export default reducer;
