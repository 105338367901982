import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Button, Form, Message } from 'semantic-ui-react';
import InputWithLabel from '../../../../../../components/input/inputWithLabel';
import InputTime from '../../../../../../components/input/inputTime';
import {
  getHourAndMinute,
  timeDuration,
  convertDateFormat,
} from '../../../../../../components/genericFunction/moment';
import InputAmount from '../../../../../../components/input/inputAmount';
import { SumSecond } from '../../../../../../components/genericFunction/timeHelper';
import Options from '../../../../../../scripts/convertOption';
// import {
//   addDailyDetails,
//   editActivityData,
// } from '../../genericFunction/dailyProgram';
import {
  addDailyDetails,
  editActivityData,
} from '../../../../../../scripts/itineraryBuilder/dailyProgram';
import {
  getExcursionAction,
  // getExcursionTypeAction,
} from '../../../../actions/excursionAction';
import {
  getRestaurantItemByIdAction,
  getRestaurantByFilterAction,
  // getRestaurantProfileSpecializationsAction,
  // getMenuClassesAction,
} from '../../../../../../actions/restaurantAction';
import ModalListExcursion from '../../../../../../components/modal/modalListExcursion';
import ModalListRestaurant from '../../../../../../components/modal/modalListRestaurant';
// import { ARRIVAL } from '../../../tourTransaction/activityType';
import { EAT } from '../constant/activityType';
const dataRestaurant = {
  cityId: '',
  ratingIds: '',
  locationIds: '',
  specializationId: '',
  menuClassId: '',
  facilityIds: '',
  requestedDate: '2018-17-28',
  pax: 1,
};

// const dataExcursion = {
//   cityid: '',
//   attractionTypeId: '',
//   requestedDate: '2018-09-08',
//   pax: 1,
// };
class ModalActivityTourTransaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openModalExcursion: false,
      openModalRestaurant: false,
      activityData: {
        Name: '',
        Startime: '',
        Duration: 0,
        optimumDuration: 0,
        Note: null,
      },
      activityObj: null,
      isOpen: false,
      error: { error: false, message: '' },
    };
  }

  componentDidUpdate() {
    if (this.props.openModal !== this.state.isOpen) {
      this.setInitialData();
    }
  }
  setInitialData = async () => {
    let { activityData, activityObj } = this.state;
    let { activityType, action, activity } = this.props;
    let newActivityData = { ...activityData };
    let newActivityObj = { ...activityObj };
    await this.setState({
      ...this.state,
      isOpen: this.props.openModal,
    });
    if (activityType === 'FREETIME') {
      newActivityData.optimumDuration = 3600;
      newActivityData.Duration = action === 'EDIT' ? activity.Duration : 3600;
      newActivityData.Note = action === 'EDIT' ? activity.Note : '';
      newActivityData.Startime =
        action === 'EDIT'
          ? activity.DateTime
          : SumSecond(activity.DateTime, activity.Duration);
    } else if (['RECREATION', 'EAT'].indexOf(activityType) !== -1) {
      if (action === 'EDIT') {
        let response =
          activityType === 'RECREATION'
            ? await this.props.getExcursionAction(activity.Item.ServiceItemId)
            : await this.props.getRestaurantItemByIdAction(
                activity.Item.ServiceItemId
              );
        newActivityObj = response ? response.value.data : newActivityObj;
        newActivityData.ServiceItemId = activity.Item.ServiceItemId;
        newActivityData.Name = activity.Item.Name;
        newActivityData.Startime = activity.DateTime;
        newActivityData.Duration = activity.Duration;
        newActivityData.optimumDuration = response
          ? response.value.data.OptimumDuration
            ? response.value.data.OptimumDuration
            : 3600
          : 3600;
        newActivityData.OperationEndTime = response
          ? response.value.data.OperationEndTime
          : null;
        newActivityData.OperationStartTime = response
          ? response.value.data.OperationStartTime
          : null;
        newActivityData.IsSolidStartTime = response
          ? response.value.data.IsSolidStartTime
            ? response.value.data.IsSolidStartTime
            : false
          : false;
        newActivityData.Note = activity.Note;
      } else {
        newActivityData = {
          Name: '',
          Startime: SumSecond(activity.DateTime, activity.Duration),
          Duration: 3600,
          optimumDuration: 3600,
          Note: null,
        };
        newActivityObj = null;
      }
    }
    this.setState({
      ...this.state,
      activityData: newActivityData,
      isOpen: this.props.openModal,
      activityObj: newActivityObj,
      destination: activity ? activity.Destination : null,
    });
  };

  handleSetActivity = () => {
    let { activityType } = this.props;
    let { activityData } = this.state;
    if (activityType !== 'FREETIME' && activityData.Name === '') {
      this.setError(
        true,
        `Please select your ${
          activityType === 'EAT' ? 'Restaurant' : 'Excursion'
        }`
      );
    } else if (activityType === 'FREETIME' && activityData.Duration < 900) {
      this.setError(true, `The Minimum duration is 15 minutes`);
    } else if (activityType === 'RECREATION' && this.checkExcursion()) {
      this.setError(true, `The Excursion is Already Selected`);
    } else if (activityType === 'RECREATION' && this.checkAbsoluteTime()) {
      this.setError(true, `The Excursion have Fixed Time`);
    } else {
      this.setDaily();
      this.setError(false, '');
    }
  };
  renderHeaderTitle = () => {
    switch (this.props.activityType) {
      case 'RECREATION':
        return 'Set Excursion Activity';
      case 'EAT':
        return 'Set Meal Activity';
      case 'FREETIME':
        return 'Set Free Time Activity';
      default:
        return '';
    }
  };

  setStartTime = (context, value) => {
    let state = { ...this.state };
    state.activityData.Startime = `${convertDateFormat(
      state.activityData.Startime,
      'YYYY-MM-DD'
    )}T${value}:00`;
    context.setState({ ...context.state, activityData: state.activityData });
  };

  handleDecreaseDuration = () => {
    let { activityData } = this.state;
    if (activityData.Duration > 900) {
      activityData.Duration = activityData.Duration - 900;
      this.setState({
        activityData: activityData,
      });
    }
  };
  handleIncreaseDuration = () => {
    let { activityData } = this.state;
    activityData.Duration = activityData.Duration + 900;
    this.setState({
      activityData: activityData,
    });
  };
  setError = (value, message) => {
    this.setState({
      ...this.state,
      error: {
        error: value,
        message: message,
      },
    });
  };
  setDaily = () => {
    let {
      dailyProgram,
      movementList,
      index,
      day,
      activityType,
      action,
      activity,
    } = this.props;
    let { activityObj, activityData } = this.state;
    let typeMovement = movementList.find(item => item.Name === activityType);
    let itemMovement = activityType === 'FREETIME' ? null : activityObj;
    activityData.Startime = activityData.Startime
      ? activityData.Startime
      : activity.DateTime;
    const move = dailyProgram[day].Movements[index].MovementName;
    let newDaily =
      action === 'ADD'
        ? addDailyDetails(
            dailyProgram,
            day,
            index,
            typeMovement,
            movementList,
            itemMovement,
            activityData
          )
        : editActivityData(
            dailyProgram,
            day,
            index,
            typeMovement,
            itemMovement,
            activityData
          );
    this.props.setDailyProgramAfterAdd(
      newDaily,
      day,
      index,
      move,
      movementList,
      action
    );
    this.props.closeModal();
  };
  onChange = (e, { value, name }) => {
    let activityData = this.state.activityData;
    activityData[name] = value;
    this.setState({ ...this.state, activityData: activityData });
    name !== 'Note' && this.setActivity(value);
  };
  checkExcursion = () => {
    let { ServiceItemId } = this.state.activityObj;
    let { movements, action } = this.props;
    return (
      action === 'ADD' &&
      movements &&
      movements.some(
        move =>
          move.MovementName === 'RECREATION' &&
          move.Item.ServiceItemId === ServiceItemId
      )
    );
  };
  checkAbsoluteTime = () => {
    let { movements } = this.props;
    let { activityData } = this.state;
    if (activityData.IsSolidStartTime) {
      let arrival = movements.find(item => item.MovementName === 'ARRIVAL');
      let departure = movements.find(item => item.MovementName === 'DEPARTURE');
      let startTime = activityData.Startime;
      let endTime = SumSecond(activityData.Startime, activityData.Duration);
      let isoverlapArrival = arrival
        ? new Date(startTime).getTime() <
            new Date(arrival.DateTime).getTime() &&
          new Date(arrival.DateTime).getTime() < new Date(endTime).getTime()
          ? true
          : false
        : false;
      let isoverlapDeparture = departure
        ? new Date(startTime).getTime() <
            new Date(departure.DateTime).getTime() &&
          new Date(departure.DateTime).getTime() < new Date(endTime).getTime()
          ? true
          : false
        : false;
      return isoverlapArrival || isoverlapDeparture;
    } else return false;
  };

  setActivity = activity => {
    const { activityType } = this.props;
    let state = { ...this.state };
    let { DateTime } = this.props.activity;
    state.activityData.Name = activity.Name;
    state.activityData.Startime =
      activityType !== 'EAT'
        ? activity.IsSolidStartTime
          ? `${convertDateFormat(DateTime, 'YYYY-MM-DD')}T${getHourAndMinute(
              activity.OperationStartTime
                ? activity.OperationStartTime
                : state.activityData.Startime
            )}:00`
          : state.activityData.Startime
        : `${convertDateFormat(DateTime, 'YYYY-MM-DD')}T${getHourAndMinute(
            activity.OperationStartTime
              ? activity.OperationStartTime
              : state.activityData.Startime
          )}:00`;
    state.activityData.Duration = activity.OptimumDuration
      ? activity.OptimumDuration
      : 3600;
    state.activityData.optimumDuration = activity.OptimumDuration
      ? activity.OptimumDuration
      : 3600;
    state.activityData.OperationEndTime = activity.OperationEndTime
      ? activity.OperationEndTime
      : null;
    state.activityData.OperationStartTime = activity.OperationStartTime
      ? activity.OperationStartTime
      : null;
    state.activityData.IsSolidStartTime = activity.IsSolidStartTime
      ? activity.IsSolidStartTime
      : false;
    state.activityObj = activity;
    state.tempStartTime = getHourAndMinute(
      activity.OperationStartTime ? activity.OperationStartTime : DateTime
    );
    this.setState({
      ...this.state,
      activityData: state.activityData,
      activityObj: state.activityObj,
    });
    this.closeModal();
  };
  closeModal = () => {
    this.setState({
      openModalExcursion: false,
      openModalRestaurant: false,
    });
  };
  openModalExcursion = () => {
    this.setState({ openModalExcursion: true });
    // this.props.getExcursionTypeAction();
    // let { guests, activity } = this.props;
    // dataExcursion.pax = guests.adults + guests.childs;
    // dataExcursion.requestedDate = convertDateFormat(
    //   activity.DateTime,
    //   'YYYY-MM-DD'
    // );
    // dataExcursion.cityid = activity.Destination;
    // this.props.getExcursionTypes();
    // this.props.getExcursionByFilter(dataExcursion);
  };
  //general button open modal
  openModalRestaurant = () => {
    this.setState({ openModalRestaurant: true });
    // let { guests, activity } = this.props;
    // dataRestaurant.pax = guests.adults + guests.childs;
    // dataRestaurant.requestedDate = convertDateFormat(
    //   activity.DateTime,
    //   'YYYY-MM-DD'
    // );
    // dataRestaurant.cityId = activity.Destination;
    // this.props.getRestaurantProfileSpecializationsAction();
    // this.props.getMenuClassesAction();
    // this.props.getRestaurantByFilter(dataRestaurant);
  };

  openModalList = () => {
    const { activityType } = this.props;
    activityType === 'EAT'
      ? this.openModalRestaurant()
      : this.openModalExcursion();
  };
  closeModalList = () => {
    const { activityType } = this.props;
    activityType === 'EAT'
      ? this.setState({ openModalRestaurant: false })
      : this.setState({ openModalExcursion: false });
  };
  clearError = () => {
    this.setState({ error: { error: false, message: '' } });
  };
  // =================================== FIXED PACKAGE MANAGEMENT =============================
  handleChangeDestination = (e, { value }) => {
    const { activityType, activity } = this.props;
    if (activityType === EAT) {
      const dataRestaurant = {
        cityId: value,
        ratingIds: '',
        locationIds: '',
        specializationId: '',
        menuClassId: '',
        facilityIds: '',
        requestedDate: convertDateFormat(activity.DateTime, 'YYYY-MM-DD'),
        pax: 1,
      };
      this.props.getRestaurantByFilterAction(dataRestaurant);
    }
    this.setState({ ...this.state, destination: value });
  };
  // =================================== FIXED PACKAGE MANAGEMENT =============================
  helperGetDestinationCity = () => {
    const { destination } = this.state;
    const { activity, isFixedPackageManagement, cities } = this.props;
    return isFixedPackageManagement
      ? destination
      : activity
      ? cities.find(item => item.Id === activity.Destination).Name
      : destination;
  };

  handleCloseModal = () => {
    this.props.handleClose();
    this.clearError();
  };
  render() {
    const {
      activityData,
      error,
      openModalExcursion,
      openModalRestaurant,
    } = this.state;
    const {
      openModal,
      activityType,
      activity,
      listItem,
      loadingItem,
      isFixedPackageManagement,
      optionCityInCountry,
    } = this.props;
    let startTime = activityData.Startime
      ? activityData.Startime
      : activity
      ? activity.DateTime
      : '00:00';
    let destLabel = this.helperGetDestinationCity();
    return (
      <div>
        <Modal
          closeIcon
          open={openModal}
          onClose={this.handleCloseModal}
          size="tiny"
        >
          <Modal.Header>
            <h3>{this.renderHeaderTitle()}</h3>
          </Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Field>
                <InputWithLabel
                  label="Destination"
                  placeholder="Destination"
                  name="destination"
                  type={isFixedPackageManagement ? 'select' : 'text'}
                  value={destLabel}
                  labelStyles={{
                    display: 'block',
                    fontWeight: 'bold',
                    marginBottom: '4px',
                  }}
                  disabled={!isFixedPackageManagement}
                  fluid={true}
                  options={optionCityInCountry}
                  handleChange={this.handleChangeDestination}
                />
              </Form.Field>
              {activityType !== 'FREETIME' && (
                <Form.Field>
                  <InputWithLabel
                    label={activityType === 'EAT' ? 'Restaurant' : 'Excursion'}
                    placeholder={
                      activityType === 'EAT' ? 'Restaurant' : 'Excursion'
                    }
                    type="text"
                    value={activityData.Name}
                    labelStyles={{
                      display: 'block',
                      fontWeight: 'bold',
                      marginBottom: '4px',
                    }}
                    //options={listItem}
                    fluid={true}
                    //loading={loadingItem}
                    //handleChange={this.setActivity}
                    name={activityType === 'EAT' ? 'Restaurant' : 'Excursion'}
                    //selectOnBlur={true}
                    handleClick={this.openModalList}
                  />
                </Form.Field>
              )}
              {/* {activityType === 'EAT' && (
              <Form.Field>
                <InputWithLabel
                  label="Restaurant Menus"
                  placeholder="Restaurant Menus"
                  type="text"
                  labelStyles={{ display: 'block', fontWeight: 'bold' }}
                  name="Note"
                  fluid={true}
                  value={activityData.Note}
                  handleChange={this.onChange}
                />
              </Form.Field>
            )} */}
              <Form.Field>
                <InputTime
                  labelText="Start Time"
                  labelStyles={{
                    display: 'block',
                    fontWeight: 'bold',
                    marginBottom: '4px',
                  }}
                  context={this}
                  value={getHourAndMinute(startTime)}
                  setTime={this.setStartTime}
                />
              </Form.Field>
              <Form.Field>
                <InputAmount
                  label="Duration"
                  value={timeDuration(activityData.Duration)}
                  name="Duration"
                  min={activityData.optimumDuration}
                  handleDecrease={this.handleDecreaseDuration}
                  handleIncrease={this.handleIncreaseDuration}
                  typeInput="string"
                  handleOnChange={this.changeDuration}
                />
              </Form.Field>
              {activityType !== 'RECREATION' && (
                <Form.Field>
                  <InputWithLabel
                    label="Note"
                    placeholder="Note"
                    type="textArea"
                    labelStyles={{ display: 'block', fontWeight: 'bold' }}
                    name="Note"
                    fluid={true}
                    value={activityData.Note}
                    handleChange={this.onChange}
                  />
                </Form.Field>
              )}
            </Form>
            {error.error && (
              <Message error>
                <p>{error.message}</p>
              </Message>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button content="Save" primary onClick={this.handleSetActivity} />
          </Modal.Actions>
        </Modal>
        <ModalListExcursion
          open={openModalExcursion}
          onClose={this.closeModalList}
          name={activityType}
          location={destLabel}
          setExcursion={this.setActivity}
          data={listItem}
        />
        <ModalListRestaurant
          open={openModalRestaurant}
          onClose={this.closeModal}
          name="Restaurant"
          location={destLabel}
          cityId={activity ? activity.Destination : ''}
          date={
            activity ? convertDateFormat(activity.DateTime, 'YYYY-MM-DD') : ''
          }
          pax={dataRestaurant.pax}
          setRestaurant={this.setActivity}
          loadingItem={loadingItem}
        />
      </div>
    );
  }
}

ModalActivityTourTransaction.propTypes = {
  openModal: PropTypes.bool,
  content: PropTypes.string,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  addMovement: PropTypes.func,
  activityType: PropTypes.string,
  activity: PropTypes.object,
  dailyProgram: PropTypes.object,
  movementList: PropTypes.array,
  index: PropTypes.number,
  day: PropTypes.number,
  action: PropTypes.string,
  setDailyProgramAfterAdd: PropTypes.func,
  closeModal: PropTypes.func,
  movements: PropTypes.array,
  listItem: PropTypes.array,
  loadingItem: PropTypes.bool,
  getExcursionAction: PropTypes.func,
  getRestaurantItemByIdAction: PropTypes.func,
  isFixedPackageManagement: PropTypes.bool,
  optionCityInCountry: PropTypes.array,
  getRestaurantByFilterAction: PropTypes.func,
  cities: PropTypes.array,
  // getExcursionTypeAction: PropTypes.func,
  // getRestaurantProfileSpecializationsAction: PropTypes.func,
  // getMenuClassesAction: PropTypes.func,
};

const mapStateToProps = state => {
  let optionCityInCountry = state.CityStore.getCityInCountryList.map(
    (city, i) =>
      Options(
        i,
        city.Id,
        city.Name + ', ' + city.Region.Name + ', ' + city.Country.Name
      )
  );
  return {
    // dailyProgram: state.customPackage.dailyProgram,
    movementList: state.PackageStore.activityList,
    optionCityInCountry,
    cities: state.CityStore.getCityInCountryList,
    // guests: state.customPackage.guests,
  };
};

export default connect(mapStateToProps, {
  getExcursionAction,
  getRestaurantItemByIdAction,
  getRestaurantByFilterAction,
  // getExcursionTypeAction,
  // getRestaurantProfileSpecializationsAction,
  // getMenuClassesAction,
})(ModalActivityTourTransaction);
