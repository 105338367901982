import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ActionButton from '../../../components/common/buttonEditAndDelete';
import { formatCurrency } from '../../../components/genericFunction/convertFormat';
import { rangeDate } from '../../../scripts/moment';
const TableSystemMargin = props => (
  <div>
    <Table basic="very" selectable fixed>
      <Table.Header>
        <Table.Row>
          {props.headerTable.map((headTableContent, index) => {
            return (
              <Table.HeaderCell key={index} textAlign="center">
                {headTableContent}
              </Table.HeaderCell>
            );
          })}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {props.listData &&
          props.listData.map((margin, idx) => {
            return (
              <Table.Row key={idx}>
                <Table.Cell
                  textAlign="center"
                  component="th"
                  scope="row"
                  width={1}
                >
                  {rangeDate(margin.StartDate, margin.EndDate)}
                  {/* {moment(margin.StartDate).format('DD/MMMM/YYYY') +
                    ' - ' +
                    moment(margin.EndDate).format('DD/MMMM/YYYY')} */}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {formatCurrency(margin.MinimalCost)}
                </Table.Cell>
                <Table.Cell textAlign="center">{margin.Margin}</Table.Cell>
                <Table.Cell textAlign="center">
                  <ActionButton
                    id={margin.Id}
                    handleClickEdit={props.butttonEdit}
                    handleClickDelete={props.buttonDelete}
                    name1="Edit"
                    name2="Delte"
                    content1="Edit"
                    content2="Delete"
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
      </Table.Body>
    </Table>
  </div>
);

TableSystemMargin.propTypes = {
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  butttonEdit: PropTypes.func,
  buttonDelete: PropTypes.func,
};
export default TableSystemMargin;
