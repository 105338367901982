export const RESET_MASTER_REFERENCE_PAGE = 'RESET_MASTER_REFERENCE_PAGE';

export const DELETE_MASTER_REFERENCE = 'DELETE_MASTER_REFERENCE';
export const DELETE_MASTER_REFERENCE_FULFILLED =
  'DELETE_MASTER_REFERENCE_FULFILLED';
export const DELETE_MASTER_REFERENCE_PENDING =
  'DELETE_MASTER_REFERENCE_PENDING';
export const DELETE_MASTER_REFERENCE_REJECTED =
  'DELETE_MASTER_REFERENCE_REJECTED';

export const POST_MASTER_REFERENCE = 'POST_MASTER_REFERENCE';
export const POST_MASTER_REFERENCE_FULFILLED =
  'POST_MASTER_REFERENCE_FULFILLED';
export const POST_MASTER_REFERENCE_PENDING = 'POST_MASTER_REFERENCE_PENDING';
export const POST_MASTER_REFERENCE_REJECTED = 'POST_MASTER_REFERENCE_REJECTED';

export const PUT_MASTER_REFERENCE = 'PUT_MASTER_REFERENCE';
export const PUT_MASTER_REFERENCE_FULFILLED = 'PUT_MASTER_REFERENCE_FULFILLED';
export const PUT_MASTER_REFERENCE_PENDING = 'PUT_MASTER_REFERENCE_PENDING';
export const PUT_MASTER_REFERENCE_REJECTED = 'PUT_MASTER_REFERENCE_REJECTED';
