import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Segment, Header, Icon, Grid, Button, Label } from 'semantic-ui-react';
//import ImageCard from './../card/imageCard';
//import ModalDetail from './../modalDetailExcursion';
import { convertDateFormat } from '../../genericFunction/moment';
import styles from '../styles';

class card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
    };
  }

  static propTypes = {
    images: PropTypes.array,
    image: PropTypes.string,
    isPromo: PropTypes.bool,
    name: PropTypes.string,
    address: PropTypes.string,
    category: PropTypes.string,
    type: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    id: PropTypes.number,
    description: PropTypes.string,
    setExcursion: PropTypes.func,
    data: PropTypes.object,
    onClose: PropTypes.func,
  };

  openCloseModal = () => {
    let { openModal } = this.state;
    this.setState({ openModal: !openModal });
  };
  chooseExcursion = () => {
    const { data, setExcursion } = this.props;
    setExcursion(data);
  };
  render() {
    let { data } = this.props;
    // let { openModal } = this.state;
    return (
      <div>
        <Segment.Group horizontal style={styles.segmentNoPadding}>
          {/* <ImageCard
            images={data.ObjectImages}
            image={data.ImageUrl}
            isPromo={data.IsPromo}
            size="300px"
          /> */}
          <Segment style={{ border: 'none' }}>
            <Header as="h3" style={styles.goldColor}>
              {data.Name}
            </Header>
            <Grid>
              <Grid.Row style={{ padding: '5px 0px' }}>
                <Grid.Column width="1">
                  <Icon name="map marker alternate" style={styles.goldColor} />
                </Grid.Column>
                <Grid.Column width="14" style={styles.justify}>
                  {data.Address}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width="1" style={{ marginTop: '5px' }}>
                  <Icon name="clock" style={styles.goldColor} />
                </Grid.Column>
                <Grid.Column
                  width="14"
                  style={{ marginTop: '5px' }}
                  verticalAlign="middle"
                >
                  <Header as="h5">
                    {convertDateFormat(data.OperationStartTime, 'HH:mm')} -
                    {convertDateFormat(data.OperationEndTime, 'HH:mm')}{' '}
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{ paddingTop: '3px' }}>
                <Grid.Column width="16">
                  <Label basic style={styles.label}>
                    {data.AttractionCategory.toLowerCase().indexOf('single') !==
                    -1
                      ? 'Flexible'
                      : 'Fixed'}{' '}
                    Timming
                  </Label>
                  <Label basic style={styles.label}>
                    {data.AttractionType.Name}
                  </Label>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={styles.noPaddingTop}>
                <Grid.Column floated="right" width="9">
                  {/* <Button
                    size="small"
                    style={styles.buttonWhite}
                    onClick={this.openCloseModal}
                    value={data.Id}
                  >
                    <strong>DETAIL</strong>
                  </Button> */}
                  <Button
                    size="small"
                    floated="right"
                    primary
                    //style={styles.buttonGold}
                    onClick={this.chooseExcursion}
                  >
                    <strong>CHOOSE</strong>
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Segment.Group>
        {/* <ModalDetail
          open={openModal}
          onClose={this.openCloseModal}
          profile={data}
          showButtonChoose={true}
          loading={false}
        /> */}
      </div>
    );
  }
}

export default card;
