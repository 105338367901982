import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Container, Image } from 'semantic-ui-react';
import imageWait from '../../../../assets/Icons/spinner_orange.png';
import moment from 'moment';

const SegmentAutoConfirm = props => {
  const { data } = props;
  return (
    <React.Fragment>
      <Grid.Row columns={3}>
        <Grid.Column width={4}>
          <Header
            as="h3"
            //            icon="check circle outline"
            content={`Request ID: ${data.RequestItemId}`}
            color="black"
          />
          {/* <Container
            style={{ fontSize: '16px', fontWeight: 'bold' }}
            content={`Request Item ID: ${data.RequestItemId}`}
          /> */}
          <Container
            content={`Request On: ${moment(data.OrderDate).format(
              'D MMM YYYY'
            )} `}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <Header as="h3" color="yellow">
            <Image size="mini" circular src={imageWait} /> Confirmed, Waiting
            for confirmation number
          </Header>
        </Grid.Column>
        <Grid.Column width={4}>
          <Container content={`Touress Booking No: ${data.BookingNo} `} />
        </Grid.Column>
      </Grid.Row>
    </React.Fragment>
  );
};

SegmentAutoConfirm.propTypes = {
  data: PropTypes.object,
  status: PropTypes.string,
};

export default React.memo(SegmentAutoConfirm);
