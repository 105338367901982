import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form, Grid } from 'semantic-ui-react';
// import { StyleModal } from '../../assets/styles/size';
import InputWithLabel from '../../../../components/input/inputWithLabel';
import Loader from '../../../../components/modal/loaderModal';
import ButtonClose from '../../../../components/button/buttonCloseModal';
import styles from '../../Styles';

const ModalCityGroup = props => {
  return (
    <Modal
      dimmer="blurring"
      // closeIcon
      // inverted
      open={props.open}
      onClose={props.handleClose}
      size="small"
      style={styles.modalCityGroup}
    >
      <Modal.Header>
        {props.header} <ButtonClose onClose={props.handleClose} />
      </Modal.Header>
      <Modal.Content>
        <Loader loading={props.loading} />
        <Grid>
          <Grid.Column>
            <Form>
              <InputWithLabel
                inline={false}
                label="Group Name"
                type="text"
                fluid={true}
                required
                name="Name"
                value={props.contentField.Name}
                placeholder="City Group Name"
                handleChange={props.handleChange}
                validate={props.errorInformation}
              />
            </Form>
            <Form>
              <InputWithLabel
                inline={false}
                label="Country Name"
                type="select"
                fluid={true}
                required
                name="CountryId"
                value={props.contentField.CountryId}
                placeholder="Country"
                handleChange={props.handleChange}
                options={props.listCountry}
                validate={props.errorInformation}
              />
            </Form>
            <Form>
              <InputWithLabel
                inline={false}
                label="City Names"
                type="select"
                fluid={true}
                required
                name="Cities"
                value={props.contentField.Cities}
                placeholder="City"
                handleChange={props.handleChange}
                options={props.listCityInCountry}
                validate={props.errorInformation}
                multiple={true}
              />
            </Form>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={props.saveCity}>
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

ModalCityGroup.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  listCountry: PropTypes.array,
  listCityInCountry: PropTypes.array,
  handleChange: PropTypes.func,
  contentField: PropTypes.shape({
    Name: PropTypes.string,
    CountryId: PropTypes.string,
    Cities: PropTypes.array,
  }),
  imagePreview: PropTypes.string,
  saveCity: PropTypes.func,
  errorInformation: PropTypes.shape({
    Name: PropTypes.object,
    City: PropTypes.object,
    CountryId: PropTypes.object,
  }),
  loading: PropTypes.bool,
};
export default ModalCityGroup;
