import React from 'react';
import PropTypes from 'prop-types';
import { Form, Grid, Header } from 'semantic-ui-react';
import InputWithLabel from '../../../../components/input/inputWithLabel';
import ButtonWithIcon from '../../../../components/button/buttonWithIcon';
import { genderOptions } from '../constant/genderOptions';

const GeneralInformation = props => {
  return (
    <React.Fragment>
      <Header as="h4" content="General Information" />
      <Grid columns={2} padded="vertically">
        <Grid.Column>
          <Form>
            <InputWithLabel
              inline={false}
              label="Username"
              type="select"
              required
              name="UserId"
              placeholder="Username"
              value={props.contentDetail.UserId}
              options={props.listUsername}
              handleChange={props.handleChange}
              validate={props.errorInformation}
              disabled={props.disable}
            />
            <InputWithLabel
              inline={false}
              label="First Name"
              name="FirstName"
              placeholder="First Name"
              type="text"
              required
              value={props.contentDetail.FirstName}
              handleChange={props.handleChange}
              validate={props.errorInformation}
              disabled={props.disable}
            />
            {props.companyId.charAt(0) !== '1' && (
              <Form>
                {' '}
                <InputWithLabel
                  inline={false}
                  label="Position/Job Title"
                  type="select"
                  required
                  name="PositionId"
                  placeholder="Position/Job Title"
                  value={props.contentDetail.PositionId}
                  handleChange={props.handleChange}
                  options={props.listPositions}
                  validate={props.errorInformation}
                  disabled={props.disable}
                />
                <InputWithLabel
                  inline={false}
                  label="Gender"
                  type="select"
                  required
                  name="Gender"
                  value={props.contentDetail.Gender}
                  placeholder="Gender"
                  options={genderOptions}
                  handleChange={props.handleChange}
                  validate={props.errorInformation}
                  disabled={props.disable}
                />
              </Form>
            )}
          </Form>
        </Grid.Column>
        <Grid.Column>
          <Form>
            <ButtonWithIcon
              text="Create New User"
              iconName="add"
              handleOnClick={props.handleNewUsername}
              positive={true}
              floated="left"
              style={{ marginTop: '1.7em', marginBottom: '1.1em' }}
              disabled={props.disable}
            />
            <InputWithLabel
              inline={false}
              placeholder="Last Name"
              name="LastName"
              label="Last Name"
              type="text"
              required
              value={props.contentDetail.LastName}
              handleChange={props.handleChange}
              validate={props.errorInformation}
              disabled={props.disable}
            />
            {props.companyId.charAt(0) !== '1' && (
              <InputWithLabel
                inline={false}
                label="Account Manager"
                type="select"
                required={props.companyId === '30000' ? false : true}
                name="AccountManagerProfileId"
                value={props.contentDetail.AccountManagerProfileId}
                placeholder="Account Manager"
                handleChange={props.handleChange}
                options={props.listAccountManager}
                validate={props.errorInformation}
                disabled={props.companyId === '30000' ? true : props.disable}
              />
            )}
          </Form>
        </Grid.Column>
      </Grid>
    </React.Fragment>
  );
};

GeneralInformation.propTypes = {
  contentDetail: PropTypes.object,
  listUsername: PropTypes.array,
  handleNewUsername: PropTypes.func,
  listPositions: PropTypes.array,
  listAccountManager: PropTypes.array,
  handleChange: PropTypes.func,
  errorInformation: PropTypes.object,
  companyId: PropTypes.string,
  disable: PropTypes.bool,
};
export default GeneralInformation;
