const headerTabelAccomodation = [
  'Accommodation Name',
  'Type',
  'Rating',
  'Region',
  'City',
  'Country',
  'Phone Number',
  'Action',
];

export default headerTabelAccomodation;
