import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Segment, Button } from 'semantic-ui-react';
import HeaderTitle from '../../shared/components/common/headerSegment/HeaderTitle';
import GeneralInformation from './form/GeneralInformation';
import OriginCountry from './form/OriginCountry';
// import Price from './form/Price';

const SegmentAdditionalServiceDetail = props => {
  // --------------------- initial ---------------------
  const {
    data,
    countryList,
    countryOptionsList,
    currencyList,
    handleBack,
    loading,
    addOriginCountry,
    handleChange,
    handleChangeInputTable,
    handleChangeInputAmount,
    handleDelete,
    handleSave,
    errors,
    additionalserviceTypeOptionList,
    id,
    disable,
    restrictedCountryList,
  } = props;

  return (
    <>
      <Grid columns="equal" style={{ marginTop: '30px' }}>
        <Grid.Row stretched>
          <Grid.Column>
            <Segment loading={loading}>
              <HeaderTitle
                title={`${id > 0 ? 'Edit' : 'Add'} Additional Services`}
              />
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Button
                      floated="right"
                      primary
                      icon="save"
                      content="Save"
                      onClick={handleSave}
                    />
                    <Button
                      floated="right"
                      negative
                      icon="angle left"
                      content="Back"
                      onClick={handleBack}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <GeneralInformation
                      data={data}
                      countryList={countryList}
                      loading={loading}
                      handleChange={handleChange}
                      errors={errors}
                      additionalserviceTypeOptionList={
                        additionalserviceTypeOptionList
                      }
                      disable={disable}
                      restrictedCountryList={restrictedCountryList}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <OriginCountry
                      data={data}
                      countryList={countryList}
                      countryOptionsList={countryOptionsList}
                      currencyList={currencyList}
                      loading={loading}
                      addOriginCountry={addOriginCountry}
                      handleChangeInputTable={handleChangeInputTable}
                      handleChangeInputAmount={handleChangeInputAmount}
                      handleDelete={handleDelete}
                      disable={disable}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

SegmentAdditionalServiceDetail.propTypes = {
  data: PropTypes.object,
  countryList: PropTypes.array,
  countryOptionsList: PropTypes.array,
  currencyList: PropTypes.array,
  handleBack: PropTypes.func,
  loading: PropTypes.bool,
  handleChange: PropTypes.func,
  handleChangeInputTable: PropTypes.func,
  handleChangeInputAmount: PropTypes.func,
  handleDelete: PropTypes.func,
  handleSave: PropTypes.func,
  addOriginCountry: PropTypes.func,
  errors: PropTypes.object,
  additionalserviceTypeOptionList: PropTypes.array,
  id: PropTypes.string,
  disable: PropTypes.bool,
  restrictedCountryList: PropTypes.array,
};

export default SegmentAdditionalServiceDetail;
