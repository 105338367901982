import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Header, Modal, Grid, Segment } from 'semantic-ui-react';
import RoomCard from './roomCard';
import ButtonClose from './../../../button/buttonCloseModal';
// import Input from './../../input/inputWithLabel';
import Input from './../../../input/inputWithLabel';
import LoaderModal from './../../../loader/loaderInModal';
import Styles from './styles';
import styles from './../../styles';
// import { convertDateFormat } from '../../../genericFunction/moment';
import { getAccomodationItemByProfiledIdAndRequestDateAction } from '../../../../pages/shared/actions/accommodationAction';
// import { getAccomodationItemByProfiledIdAndRequestDateAction } from '../../../../actions/accomodationAction';

// const ModalAccommodationRoom = props =>
class ModalAccommodationRoom extends Component {
  state = {
    searchName: '',
    modalOpen: false,
  };
  componentDidUpdate = () => {
    if (
      this.props.open !== this.state.modalOpen &&
      this.props.dataGetAccommodation !== {}
    ) {
      let dataGetAccommodation = { ...this.props.dataGetAccommodation };
      dataGetAccommodation.profileId = this.props.selectedAccommodation;
      this.props.getAccomodationItemByProfiledIdAndRequestDateAction(
        dataGetAccommodation
      );
      this.setState({ ...this.state, modalOpen: this.props.open });
      // this.props.selectedAccommodation,
      // convertDateFormat(
      //   this.props.mainProg[this.props.accomIndex].Date,
      //   'MM/DD/YYYY'
      // ),
      // convertDateFormat(
      //   this.props.mainProg[this.props.accomIndex].LeavingDate,
      //   'MM/DD/YYYY'
      // )
    }
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  filterData = (listRoom, searchName) => {
    return listRoom.filter(
      menu => menu.Name.toLowerCase().indexOf(searchName.toLowerCase()) !== -1
    );
  };
  onClose = () => {
    this.setState({ ...this.state, modalOpen: false }, () =>
      this.props.onClose()
    );
  };
  render() {
    let {
      open,
      listAccommodationRoomById,
      header,
      desc,
      amenities,
      loading,
      closeModalAccommodation,
      setAccommodationRoom,
    } = this.props;
    let { searchName } = this.state;
    let data = this.filterData(listAccommodationRoomById, searchName);
    data = !loading ? data : [];
    return (
      <Modal open={open} onClose={this.onClose} size="small" closeOnDimmerClick>
        <Modal.Header style={Styles.backgroundModal}>
          Room Accommodation <ButtonClose onClose={this.onClose} />
        </Modal.Header>
        <Modal.Content style={Styles.backgroundModal}>
          <Modal.Description style={{ margin: '10px' }}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Header as="h5">
                    Showing {data.length} {header}
                    &#39;s Room
                  </Header>
                </Grid.Column>
                <Grid.Column width={16}>
                  <Segment style={styles.segmentSearch}>
                    <Input
                      name="searchName"
                      type="input"
                      icon="search"
                      iconPosition="left"
                      labelStyles={styles.textTransparent}
                      transparent={true}
                      inputStyles={{ width: '100%', padding: '9px' }}
                      value={searchName}
                      handleChange={this.handleChange}
                      placeholder="Search by room name"
                    />
                  </Segment>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  {loading && (
                    <Segment
                      style={{
                        border: 'none',
                        background: 'transparent',
                        minHeight: '50px',
                      }}
                    >
                      <LoaderModal loading={loading} />
                    </Segment>
                  )}{' '}
                  {data.length !== 0 && !loading
                    ? data.map((data, index) => (
                        <RoomCard
                          key={index}
                          desc={desc}
                          amenities={amenities}
                          name={data.Name}
                          type={data.AccommodationType.Name}
                          capacity={data.AccommodationType.Capacity}
                          image={data.imageUrl}
                          images={data.ItemImages}
                          serviceType={data.AccommodationItemServiceTypes}
                          isPromo={data.IsPromo}
                          room={data}
                          onClose={this.onClose}
                          closeModalAccommodation={closeModalAccommodation}
                          setAccommodationRoom={setAccommodationRoom}
                          isInstantConfirmation={data.IsInstantConfirmation}
                        />
                      ))
                    : !loading && (
                        <Header textAlign="center" as="h4">
                          There is no result of room for your accommodation
                        </Header>
                      )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {/* <Header as="h5">
              Showing {data.length} {header}&#39;s Room
            </Header> */}
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}
ModalAccommodationRoom.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  // data: PropTypes.array,
  header: PropTypes.string,
  desc: PropTypes.string,
  amenities: PropTypes.array,
  closeModalAccommodation: PropTypes.func,
  loading: PropTypes.bool,
  setAccommodationRoom: PropTypes.func,
  getAccomodationItemByProfiledIdAndRequestDateAction: PropTypes.func,
  selectedAccommodation: PropTypes.number,
  mainProg: PropTypes.object,
  accomIndex: PropTypes.number,
  listAccommodationRoomById: PropTypes.array,
  dataGetAccommodation: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    listAccommodationRoomById:
      state.AccomodationStore.getAccomodationByProfilIdAndDate,
    loading: state.AccomodationStore.loading,
  };
};

export default connect(
  mapStateToProps,
  {
    getAccomodationItemByProfiledIdAndRequestDateAction,
  }
)(ModalAccommodationRoom);
