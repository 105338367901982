import React from 'react';
import { Icon, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const ButtonWithIcon = props => {
  let {
    handleOnClick,
    style,
    iconName,
    size,
    positive,
    negative,
    floated,
    text,
    disabled,
    primary,
    loading,
  } = props;
  return (
    <Button
      onClick={handleOnClick}
      positive={positive}
      negative={negative}
      floated={floated}
      size={size}
      style={style}
      disabled={disabled}
      primary={primary}
      loading={loading}
    >
      <Icon name={iconName} />
      {text}
    </Button>
  );
};

ButtonWithIcon.propTypes = {
  handleOnClick: PropTypes.func,
  style: PropTypes.object,
  iconName: PropTypes.string,
  size: PropTypes.string,
  positive: PropTypes.bool,
  negative: PropTypes.bool,
  floated: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  primary: PropTypes.bool,
  loading: PropTypes.bool,
};
export default ButtonWithIcon;
