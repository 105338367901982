import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Segment, Header, Icon, Grid, Button } from 'semantic-ui-react';
// import ImageCard from './../card/imageCard';
import ModalDetailListMenu from '../modalDetailListMenu';
// import { getRestaurantItemByProfileIdAction } from './../../../actions/restaurantAction';
import { getRestaurantItemByProfileIdAction } from '../../../actions/restaurantAction';
import styles from '../styles';
class card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
    };
  }

  static propTypes = {
    images: PropTypes.array,
    image: PropTypes.string,
    isPromo: PropTypes.bool,
    name: PropTypes.string,
    address: PropTypes.string,
    id: PropTypes.number,
    classes: PropTypes.string,
    typeMenu: PropTypes.string,
    getRestaurantItemByProfileIdAction: PropTypes.func,
    menus: PropTypes.array,
    loadingRestaurantMenuById: PropTypes.bool,
    setRestaurant: PropTypes.func,
  };

  openCloseModal = (e, { value }) => {
    let { openModal } = this.state;
    this.setState({ openModal: !openModal });
    this.props.getRestaurantItemByProfileIdAction(value);
  };

  render() {
    let {
      name,
      address,
      id,
      classes,
      typeMenu,
      menus,
      loadingRestaurantMenuById,
      setRestaurant,
    } = this.props;
    let { openModal } = this.state;
    return (
      <div>
        <Segment.Group horizontal style={styles.segmentNoPadding}>
          {/* <ImageCard
            images={images}
            image={image}
            isPromo={isPromo}
            size="300px"
          /> */}
          <Segment style={{ border: 'none' }}>
            <Header as="h3" style={styles.goldColor}>
              {name}
            </Header>
            <Grid>
              <Grid.Row>
                <Grid.Column width="1">
                  <Icon name="map marker alternate" style={styles.goldColor} />
                </Grid.Column>
                <Grid.Column width="14" style={styles.justify}>
                  {address}
                </Grid.Column>
              </Grid.Row>
              {classes && (
                <Grid.Row style={{ paddingTop: '0px' }}>
                  <Grid.Column width="1">
                    <Icon className="restorant-speciality" />
                  </Grid.Column>
                  <Grid.Column width="14">
                    <Header
                      as="h5"
                      style={{ marginTop: '10px', marginLeft: '10px' }}
                    >
                      {classes} Dinning - {typeMenu}
                    </Header>
                  </Grid.Column>
                </Grid.Row>
              )}
              <Grid.Row>
                <Grid.Column width="7" floated="right">
                  <Button
                    size="large"
                    // style={styles.buttonGold}
                    onClick={this.openCloseModal}
                    value={id}
                    primary
                  >
                    SEE MENUS
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Segment.Group>
        <ModalDetailListMenu
          open={openModal}
          onClose={this.openCloseModal}
          listDetailData={menus}
          loading={loadingRestaurantMenuById}
          setRestaurant={setRestaurant}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    menus: state.RestaurantStore.getRestaurantItemByProfileId,
    loadingRestaurantMenuById: state.RestaurantStore.loading,
  };
};

export default connect(mapStateToProps, {
  getRestaurantItemByProfileIdAction,
})(card);
