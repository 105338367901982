//Transportation List
const GET_TRANSPORTATION_LIST = 'GET_TRANSPORTATION_LIST';
const GET_TRANSPORTATION_LIST_FULFILLED = 'GET_TRANSPORTATION_LIST_FULFILLED';
const GET_TRANSPORTATION_LIST_PENDING = 'GET_TRANSPORTATION_LIST_PENDING';
const GET_TRANSPORTATION_LIST_REJECTED = 'GET_TRANSPORTATION_REJECTED';
const RESET_STATUS_TRANSPORTATION = 'RESET_STATUS_TRANSPORTATION';

export {
  //Transportation List
  GET_TRANSPORTATION_LIST,
  GET_TRANSPORTATION_LIST_FULFILLED,
  GET_TRANSPORTATION_LIST_PENDING,
  GET_TRANSPORTATION_LIST_REJECTED,
  RESET_STATUS_TRANSPORTATION,
};
