import { LOGIN, LOGOUT, RESET_LOGIN } from './constant/actionTypes';
import getLoginAuthenticationApi from '../../api/getLoginAuthenticationApi';

export const postLoginAction = data => {
  return dispatch => {
    return dispatch({
      type: LOGIN,
      payload: getLoginAuthenticationApi(data),
    });
  };
};
export const logoutAction = () => {
  return dispatch => {
    return dispatch({
      type: LOGOUT,
    });
  };
};

export const resetStatusLoginAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_LOGIN,
    });
  };
};
