import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Segment, Grid, Header, Icon } from 'semantic-ui-react';
import styles from '../../../../../assets/styles/styleCreateTourTransaction';
import CollapseButton from '../../../../../components/button/collapseButton';
import { Collapse } from 'react-collapse';
import {
  // getResourceBookingByTourTransactionCodeApi,
  getResourceBookingDetailByResourceBookingIdApi,
} from '../../../../../api/resourceBooking/resourceBookingApi';
import { restructureResourceBookingByResourceType } from './services/restructureData';
import ResourceItem from './components/ResourceItem';
import { getResourceBookingByTourTransactionCode } from '../../../actions/resourceBookingAction';

const ResourceStatus = props => {
  // ==================== initial ====================
  const { id, dailyProgram } = props;
  const [resourceBooking, setResourceBooking] = useState([]);
  const [openCollapse, setOpenCollapse] = useState(false);
  // ========= Initial action ===========
  const dispatch = useDispatch();
  const getResourceBookingByTourTransactionCodeAction = param =>
    dispatch(getResourceBookingByTourTransactionCode(param));

  // loadingGetResourceBookingByTransaction: false,
  // resourceBookingByTransaction: null,

  // ==================== handler ====================
  const handleCollapse = () => setOpenCollapse(!openCollapse);

  // ==================== lifecycle ====================
  useEffect(() => {
    if (dailyProgram.length > 0) {
      getResourceBookingByTourTransactionCodeAction(id).then(result1 => {
        getResourceBookingDetailByResourceBookingIdApi(
          result1.value.data.Id
        ).then(result2 => {
          setResourceBooking(
            restructureResourceBookingByResourceType(result2.data)
          );
        });
      });
    }
    // eslint-disable-next-line
  }, [dailyProgram]);

  return (
    <Segment>
      <div style={styles.marginContentInsideCard}>
        <Grid verticalAlign="middle">
          <Grid.Row style={styles.noPaddingBottom}>
            <Grid.Column width={14}>
              <Header as="h3" style={styles.marginHeader}>
                <Icon name="clipboard list" style={{ color: '#38af95' }} />
                Resource Status
              </Header>
            </Grid.Column>
            <Grid.Column width={2} textAlign="left">
              <CollapseButton
                isCollapse={openCollapse}
                handleCollapse={handleCollapse}
                name="openCollapse"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Collapse isOpened={openCollapse} style={{ marginTop: '0px' }}>
          <ResourceItem resourceBooking={resourceBooking} />
        </Collapse>
      </div>
    </Segment>
  );
};

ResourceStatus.propTypes = {
  id: PropTypes.string,
  dailyProgram: PropTypes.array,
};

export default ResourceStatus;
