import {
  GET_SERVICE_PRICE,
  POST_SERVICE_PRICE,
  PUT_SERVICE_PRICE,
  RESET_STATUS_SERVICE_PRICE,
  GET_SERVICE_PRICE_TYPE,
  DELETE_SERVICE_PRICE,
} from './actionTypes';
import getServicePriceApi from '../api/servicePrice/getServicePriceApi';
import getServicePriceTypeApi from '../api/servicePrice/getServicePriceTypeApi';
import postServicePriceApi from '../api/servicePrice/postServicePriceApi';
import putServicePriceApi from '../api/servicePrice/putServicePriceApi';
import deleteServicePriceApi from '../api/servicePrice/deleteServicePriceApi';

const getServicePriceAction = id => {
  return dispatch => {
    return dispatch({
      type: GET_SERVICE_PRICE,
      payload: getServicePriceApi(id),
    });
  };
};
const getServicePriceTypeAction = type => {
  return dispatch => {
    return dispatch({
      type: GET_SERVICE_PRICE_TYPE,
      payload: getServicePriceTypeApi(type),
    });
  };
};
const postServicePriceAction = (type, data) => {
  return dispatch => {
    return dispatch({
      type: POST_SERVICE_PRICE,
      payload: postServicePriceApi(type, data),
    });
  };
};
const putServicePriceAction = (type, id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_SERVICE_PRICE,
      payload: putServicePriceApi(type, id, data),
    });
  };
};
const deleteServicePriceAction = id => {
  return dispatch => {
    return dispatch({
      type: DELETE_SERVICE_PRICE,
      payload: deleteServicePriceApi(id),
    });
  };
};
const resetServicePriceAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_STATUS_SERVICE_PRICE,
    });
  };
};

export {
  getServicePriceAction,
  resetServicePriceAction,
  postServicePriceAction,
  putServicePriceAction,
  getServicePriceTypeAction,
  deleteServicePriceAction,
};
