import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ActionButton from '../button/ButtonActionTable';
import { Link } from 'react-router-dom';
import { convertDateFormat } from '../../../../../scripts/moment';
const TableTourGuide = props => (
  <Table basic="very" selectable>
    <Table.Header>
      <Table.Row>
        {props.headerTable.map((headTableContent, index) => {
          return (
            <Table.HeaderCell key={index}>{headTableContent}</Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {props.listData &&
        props.listData.map((data, idx) => {
          return (
            <Table.Row key={idx}>
              <Table.Cell>
                <Link to={`/Tour-Guide-Detail/Detail/${data.Id}`}>
                  {data.FirstName ? data.FirstName : ''} {' ' + data.LastName}
                </Link>
              </Table.Cell>
              <Table.Cell>{data.AddressObject.Country.Name}</Table.Cell>
              <Table.Cell>{data.IdentityNbr}</Table.Cell>
              <Table.Cell>
                {convertDateFormat(data.StartedJobAt, 'DD MMMM YYYY')}
              </Table.Cell>
              <Table.Cell>{data.AddressObject.AddressString}</Table.Cell>
              <Table.Cell>
                {data.TourGuideLanguage.map(e => e.Language.EnglishName).join(
                  ', '
                )}
              </Table.Cell>
              <Table.Cell textAlign="center">
                <ActionButton
                  id={data.Id}
                  handleClickEdit={props.editTourGuide}
                  handleClickDelete={props.deleteTourGuide}
                  name1="Edit"
                  name2={data.FirstName + ' ' + data.LastName}
                  content1="Edit"
                  content2="Delete"
                />
              </Table.Cell>
            </Table.Row>
          );
        })}
    </Table.Body>
  </Table>
);

TableTourGuide.propTypes = {
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  editTourGuide: PropTypes.func,
  deleteTourGuide: PropTypes.func,
};
export default TableTourGuide;
