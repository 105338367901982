import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Header, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import styles from './styles';
import { convertDateFormat } from '../../../../../scripts/moment';
import TimeLineDaily from './TimelineFixedPackage';
import {
  getRestaurantByFilterAction,
  getRestaurantProfileSpecializationsAction,
  getMenuClassesAction,
} from '../../../../../actions/restaurantAction';
import ModalActivity from './ModalAddActivityFixedPackage';
// import  from './../../../modal/package/modalActivityTourTransaction';
import {
  getExcursionByCityAction,
  getExcursionTypeAction,
} from '../../../../shared/actions/excursionAction';
import Loader from '../../../../../components/loader/loader';
// } from '../../../../pages/shared/actions/excursionAction.js';
// import TimeLineDailyProgram from './timeLineDailyProgram';
class DailyProgramFixedPackage extends Component {
  state = {
    openModalActivity: false,
    activityType: '',
    activity: null,
    index: 0,
    movements: null,
    day: null,
    openModalTransportationUnits: false,
    dataTransportation: null,
    listItem: [],
    isThereActivity: false,
  };
  handleCollapseDay = (e, { name, value }) =>
    this.setState({ ...this.state, [name]: !value });

  openModalSetActivity = (
    activityType,
    activity,
    movements,
    index,
    day,
    action,
    positionButton
  ) => {
    const totalPax = 1;
    const destination = activity ? activity.Destination : '';
    if (activityType === 'EAT') {
      const dataRestaurant = {
        cityId: destination,
        ratingIds: '',
        locationIds: '',
        specializationId: '',
        menuClassId: '',
        facilityIds: '',
        requestedDate: convertDateFormat(activity.DateTime, 'YYYY-MM-DD'),
        pax: totalPax,
      };
      this.props.getRestaurantByFilterAction(dataRestaurant);
      this.props.getRestaurantProfileSpecializationsAction();
      this.props.getMenuClassesAction();
    } else if (activityType === 'RECREATION') {
      this.props.getExcursionByCityAction(destination);
      this.props.getExcursionTypeAction();
    }
    this.setState({
      ...this.state,
      openModalActivity: true,
      activityType: activityType,
      activity: activity,
      index: index,
      movements: movements,
      day: day,
      action: action,
      positionButton,
    });
  };
  closeModalSetActivity = () => {
    this.setState({
      ...this.state,
      openModalActivity: false,
    });
  };
  render() {
    const {
      openModalActivity,
      activityType,
      activity,
      movements,
      action,
      index,
      day,
      positionButton,
    } = this.state;
    const {
      dailyProgram,
      loadingRestaurant,
      setDailyProgramAfterAdd,
      getExcurtionProfileByCity,
      dayFirstCheckIn,
      deleteActivity,
      isPublished,
      loadingPackage,
    } = this.props;
    const loadingItem = loadingRestaurant;
    return (
      <div>
        {dailyProgram ? (
          dailyProgram.map((data, idDays) => {
            return (
              <Grid verticalAlign="middle" key={idDays}>
                <Segment style={{ border: 'none' }}>
                  <Loader active={loadingPackage} inverted={true} />
                  <TimeLineDaily
                    data={data}
                    handleOpenModalActivities={this.openModalSetActivity}
                    day={idDays}
                    dailyProgram={dailyProgram}
                    // dayFirstCheckIn={dayFirstCheckIn}
                    deleteActivity={deleteActivity}
                    date={data.Date}
                    isPublished={isPublished}
                  />
                </Segment>
              </Grid>
            );
          })
        ) : (
          <Grid verticalAlign="middle">
            <Grid.Row style={styles.marginDailyProgramDays}>
              <Grid.Column width={12}>
                <Header as="h4" style={{ marginTop: '10px' }}>
                  Daily Program Empty
                </Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
        <ModalActivity
          openModal={openModalActivity}
          handleClose={this.closeModalSetActivity}
          activityType={activityType}
          activity={activity}
          closeModal={this.closeModalSetActivity}
          dailyProgram={dailyProgram}
          movements={movements}
          action={action}
          index={index}
          day={day}
          listItem={getExcurtionProfileByCity}
          loadingItem={loadingItem}
          setDailyProgramAfterAdd={setDailyProgramAfterAdd}
          isFixedPackageManagement={true}
          positionButtonAdd={positionButton}
          dayFirstCheckIn={dayFirstCheckIn}
        />
        {/*
        <ModalTransportation
          open={openModalTransportationUnits}
          onClose={this.closeModalSetActivity}
          name="Transportation"
          from={dataTransportation ? dataTransportation.fromCity : null}
          to={dataTransportation ? dataTransportation.toCities : null}
          date={dataTransportation ? dataTransportation.requestedDate : null}
          setTransportItem={this.setTransportation}
          //=======================field filter===============
          transportationTypesList={transportationTypesList}
          transportationSeatTypeList={transportationSeatTypeList}
          transportationProfileCatagories={transportationProfileCatagories}
          isThereActivity={isThereActivity}
        /> */}
      </div>
    );
  }
}

DailyProgramFixedPackage.propTypes = {
  dailyProgram: PropTypes.array,
  getRestaurantByFilterAction: PropTypes.func,
  loadingRestaurant: PropTypes.bool,
  getRestaurantProfileSpecializationsAction: PropTypes.func,
  getMenuClassesAction: PropTypes.func,
  setDailyProgramAfterAdd: PropTypes.func,
  getExcursionByCityAction: PropTypes.func,
  getExcursionTypeAction: PropTypes.func,
  getExcurtionProfileByCity: PropTypes.array,
  dayFirstCheckIn: PropTypes.number,
  deleteActivity: PropTypes.func,
  isPublished: PropTypes.bool,
  loadingPackage: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    loadingRestaurant: state.RestaurantStore.loading,
    getExcurtionProfileByCity: state.ExcursionStore.getExcurtionProfileByCity,
    loadingPackage: state.PackageStore.loadingGetDuration,
  };
};
// export default dailyProgramFixedPackage;
export default connect(
  mapStateToProps,
  {
    getRestaurantByFilterAction,
    getRestaurantProfileSpecializationsAction,
    getMenuClassesAction,
    getExcursionByCityAction,
    getExcursionTypeAction,
  }
)(DailyProgramFixedPackage);
