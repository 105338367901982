import React from 'react';
import { Dimmer } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {
  getCustomerProfileListAction,
  resetCustomerProfileAction,
} from '../../shared/actions/customerProfileAction';
import ModalAlert from '../../../components/modal/modalAlert';
import Loader from '../../../components/loader';
import CustomerProfileList from './components/ListCustomerProfile';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import ModalConfirm from '../../../components/modal/modalConfirmationTwoButton';
import { deleteCompaniesByIdAction } from '../../../actions/companyAction';
import { getUserRoleByBearerAction } from '../../../actions/userAccessAction';
import ListHelper from '../../shared/components/listHelper/ListHelper';
import { checkUser } from './../../../scripts/authorization';
// import ModalCompanyRegistration from './../../home/component/modal/ModalCompanyRegistration';
import ModalRegisOnBehalf from '../../shared/components/tourTransaction/generalInformation/modal/ModalRegisOnBehalf';
import { postCreateCustomerOnBehalfAction } from './../../shared/actions/customerProfileAction';
class CustomerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customerProfileList: [],
      loadingCustomerProfile: true,
      failedCustomerProfile: false,
      modalOpen: false,
      changePage: false,
      gotoLink: '',
      modalConfirm: {
        type: '',
        isOpen: false,
        message: '',
        data: {},
        isConfirm: false,
      },
      modalAlert: {
        isOpen: false,
        header: '',
        message: '',
      },
      isSales: null,
      isFinance: null,
      openModalNewCustomer: false,
    };
  }
  static propTypes = {
    getCustomerProfileListAction: PropTypes.func,
    statusCustomerProfile: PropTypes.func,
    listCustomerProfile: PropTypes.func,
    resetCustomerProfileAction: PropTypes.func,
    deleteCompaniesByIdAction: PropTypes.func,
    deleteCompany: PropTypes.object,
    errorCompany: PropTypes.object,
    loadingCompany: PropTypes.bool,
    getUserRoleByBearerAction: PropTypes.func,
    userRoles: PropTypes.object,
    postCreateCustomerOnBehalfAction: PropTypes.func,
    errors: PropTypes.object,
    loadingCreateOnBehalf: PropTypes.bool,
  };

  handleOpen = () => this.setState({ modalOpen: true });
  handleClose = () => this.setState({ failedCustomerProfile: false });
  componentDidMount = () => {
    this.props.getCustomerProfileListAction('Customer');
    this.props.getUserRoleByBearerAction().then(() => {
      let authorization = checkUser(this.props.userRoles.Roles);
      this.setState({
        ...this.state,
        isSales: authorization.isSales,
        isFinance: authorization.isFinance,
      });
    });
  };

  componentDidUpdate() {
    if (this.props.statusCustomerProfile === 'success') {
      this.setState({
        customerProfileList: this.props.listCustomerProfile,
      });
      this.setState({ loadingCustomerProfile: false });
      this.props.resetCustomerProfileAction();
    } else if (this.props.statusCustomerProfile === 'failed') {
      this.setState({ failedCustomerProfile: true });
      this.setState({ loadingCustomerProfile: false });
      this.props.resetCustomerProfileAction();
    }
  }
  newCustomerProfile = () => {
    if (this.state.isSales) {
      this.setState({ ...this.state, openModalNewCustomer: true });
    } else {
      // eslint-disable-next-line
      this.props.history.push('/Customer-Profile-Detail/new');
    }
  };

  changePage = (e, { name, value }) => {
    let link = `/Customer-Profile-Detail/${name}/${value}`;
    this.setState({ ...this.state, changePage: true, gotoLink: link });
  };

  handleDelete = (e, { name, value }) => {
    let modalConfirm = { ...this.state.modalConfirm };
    modalConfirm.type = name;
    modalConfirm.isOpen = true;
    modalConfirm.message = `Are you sure want to delete ${value.Name}?`;
    modalConfirm.data = value;
    this.setState({
      ...this.state,
      modalConfirm,
    });
  };

  handleModalConfirm = (e, { name }) => {
    let modalConfirm = { ...this.state.modalConfirm };
    modalConfirm.isOpen = false;
    modalConfirm.isConfirm = name === 'isConfirm';
    this.setState({ ...this.state, modalConfirm }, () => this.confirmAction());
  };

  confirmAction = () => {
    let { isConfirm, data } = this.state.modalConfirm;
    if (isConfirm) {
      this.props
        .deleteCompaniesByIdAction(data.Code)
        .then(() => {
          this.setAlert(
            'Success',
            `Delete ${this.props.deleteCompany.Name} succesfully`
          );
          this.props.getCustomerProfileListAction('Customer');
        })
        .catch(() => this.setAlert('Failed', this.props.errorCompany.Message));
    }
  };

  setAlert = (status, message) => {
    let modalAlert = { ...this.state.modalAlert };
    modalAlert.isOpen = true;
    modalAlert.header = status;
    modalAlert.message = message;
    this.setState({ ...this.state, modalAlert });
  };

  handleCloseAlert = () => {
    let modalAlert = { ...this.state.modalAlert };
    modalAlert.isOpen = false;
    this.setState({ ...this.state, modalAlert });
  };

  handleCloseModalCustomer = () =>
    this.setState({ ...this.state, openModalNewCustomer: false });

  simpleRegisterCompany = dataSimpleCompany => {
    this.props
      .postCreateCustomerOnBehalfAction(dataSimpleCompany)
      .then(() => {
        this.setState(
          {
            ...this.state,
            modalAlert: {
              isOpen: true,
              header: 'Success',
              message: 'Success Save Customer Profile',
            },
            openModalNewCustomer: false,
          },
          () => this.props.getCustomerProfileListAction('Customer')
        );
      })
      .catch(() => {
        let modalAlert = {
          isOpen: true,
          message: this.props.errors.ModelState
            ? this.props.errors.ModelState.message0[0]
            : this.props.errors.Message,
          header: 'Failed',
        };
        this.setState({ ...this.state, modalAlert });
      });
  };

  render() {
    let {
      loadingCustomerProfile,
      failedCustomerProfile,
      changePage,
      gotoLink,
      modalConfirm,
      modalAlert,
      isSales,
      isFinance,
      openModalNewCustomer,
    } = this.state;
    let { loadingCompany } = this.props;
    let loading = loadingCustomerProfile || loadingCompany;
    let openModal = failedCustomerProfile;
    if (changePage) {
      return <Redirect to={gotoLink} />;
    }
    return (
      <div>
        <Dimmer.Dimmable blurring dimmed={loading}>
          <Loader loading={loading} />
          <ListHelper
            title="Customer Profile"
            data={this.state.customerProfileList}
            type="customerProfile"
            filterBy="customerProfile"
            buttonNew={this.newCustomerProfile}
            buttonSeeDetail={this.changePage}
            buttonEdit={this.changePage}
            buttonDelete={this.handleDelete}
            isHideButtonDelete={isSales || isFinance}
            isSales={isSales}
            isFinance={isFinance}
          >
            <CustomerProfileList />
          </ListHelper>
          <ModalConfirm
            openModal={modalConfirm.isOpen}
            size="mini"
            close={this.handleModalConfirm}
            confirm={this.handleModalConfirm}
            nameConfirm="isConfirm"
            message={modalConfirm.message}
          />
          <ModalAlert
            openModal={openModal}
            handleClose={this.handleClose}
            header="Failed"
            content="Please check your internet connection and Refresh Page [Ctrl+R]"
            nameButton="OK"
          />
          <ModalAlert
            openModal={modalAlert.isOpen}
            handleClose={this.handleCloseAlert}
            header={modalAlert.header}
            content={modalAlert.message}
            nameButton="OK"
          />
          <ModalRegisOnBehalf
            // open={openModalNewCustomer}
            // handleModalDetail={this.handleCloseModalCustomer}
            // data={this.state.dataDetailCompany}
            // actionCompanyRegistration={this.companyAction}
            // handleCloseModalDetail={this.handleCloseModalCustomer}

            isOpen={openModalNewCustomer}
            onClose={this.handleCloseModalCustomer}
            simpleRegisterCompany={this.simpleRegisterCompany}
            loadingCreateOnBehalf={this.props.loadingCreateOnBehalf}
          />
        </Dimmer.Dimmable>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    listCustomerProfile: state.CustomerProfileStore.getCustomerProfileList,
    statusCustomerProfile:
      state.CustomerProfileStore.getCustomerProfileListStatus,
    deleteCompany: state.CompanyStore.deleteCompany,
    errorCompany: state.CompanyStore.errors,
    loadingCompany: state.CompanyStore.loading,
    userRoles: state.UserAccessStore.getUserRoleByBearer,
    errors: state.CustomerProfileStore.errors,
    loadingCreateOnBehalf: state.CustomerProfileStore.loadingCreateOnBehalf,
  };
}
export default connect(mapStateToProps, {
  getCustomerProfileListAction,
  resetCustomerProfileAction,
  deleteCompaniesByIdAction,
  getUserRoleByBearerAction,
  postCreateCustomerOnBehalfAction,
})(CustomerList);
