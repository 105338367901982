import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header } from 'semantic-ui-react';
import ImageAndLogoCustomer from './SegmentImagesAndLogoCustomer';
const PaymentTermCustomer = props => {
  let contentImage = '';
  let contentLogo = '';
  let companyLogo =
    props.contentCompanyLogo && props.contentCompanyLogo.ImagePreview;
  if (props.contentDetail.ImagePreview) {
    contentImage = 'Change Image';
  } else {
    contentImage = 'Add Image';
  }
  if (companyLogo) {
    contentLogo = 'Change Image';
  } else {
    contentLogo = 'Add Image';
  }
  return (
    <div>
      <Grid columns="equal">
        <Grid.Row stretched>
          <Grid.Column width={15}>
            {/* <Header as="h4" content="Images" /> */}
            <Header as="h4" content="Company Logo" />
            <ImageAndLogoCustomer
              companyImage={props.contentDetail.ImagePreview}
              companyLogo={companyLogo}
              modalImage={props.modalImage}
              contentImage={contentImage}
              contentLogo={contentLogo}
              disabled={props.disabled}
              handleChangeImage={props.handleChangeImage}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

PaymentTermCustomer.propTypes = {
  handleChange: PropTypes.func,
  errors: PropTypes.shape({}),
  contentDetail: PropTypes.shape({
    CompanyLogo: PropTypes.shape({
      ImageContent: PropTypes.string,
      ImageName: PropTypes.string,
      ImagePreview: PropTypes.string,
    }),
    ImageName: PropTypes.string,
    ImageContent: PropTypes.string,
    PaymentSchemeId: PropTypes.string,
    ImagePreview: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  getPaymentSchemeList: PropTypes.array,
  modalImage: PropTypes.func,
  contentCompanyLogo: PropTypes.string,
  handleChangeImage: PropTypes.func,
};
export default PaymentTermCustomer;
