import React from 'react';
import PropTypes from 'prop-types';
import { getCustomerPaymentApi } from '../../../api/getCustomerPaymentApi';
import CustomerPayment from './CustomerPayment';
import useApiCall from '../hooks/useApiCallCustomerPayment';

const CustomerPaymentCombine = () => {
  //=======================initial state=======================
  const [manualConfirmLoading, customerPayment] = useApiCall(
    getCustomerPaymentApi
  );

  return (
    <div>
      <CustomerPayment
        title="Upcoming Customer Payment Due"
        loading={manualConfirmLoading}
        data={customerPayment}
      />
      <CustomerPayment
        title="Overdue Customer Payment"
        loading={manualConfirmLoading}
        data={customerPayment}
      />
    </div>
  );
};

CustomerPaymentCombine.propTypes = {
  title: PropTypes.string,
  customerPayment: PropTypes.array,
  manualConfirmLoading: PropTypes.bool,
};

export default React.memo(CustomerPaymentCombine);
