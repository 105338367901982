const styles = {
  styleCard: {
    margin: '1em',
    backgroundColor: '#F8F9F9',
    borderRadius: '0px',
  },
  styleButton: {
    color: '#6b82e6',
    backgroundColor: '#F8F9F9',
    marginTop: '-2em',
  },
  styleSegment: { height: '280px', width: '700px', border: '0px' },
  minHeight: { height: '200px' },
};
export default styles;
