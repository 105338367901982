import { convertDateFormat } from '../../../../../scripts/moment'; //src\components\genericFunction\moment.js
import { ucwords } from '../../../../../scripts/convertWord';
import {
  setStringPaymentStatus,
  setStringResourceStatus,
} from '../../../scripts/resourceBookingDetail';

const setDataResourceBooking = data => {
  let optionsData = data.map(data => {
    return {
      Id: data.Id ? data.Id : null,
      TourTransactionCode: data.TourTransactionCode
        ? data.TourTransactionCode
        : null,
      ResourceBooking: data.ResourceBooking.Text
        ? data.ResourceBooking.Text
        : null,
      ResourceBookingId: data.ResourceBooking.Value
        ? data.ResourceBooking.Value
        : null,
      ResourceName: data.ResourceName ? data.ResourceName : null,
      ResourceType: data.ResourceType ? data.ResourceType : null,
      DueDate: data.DueDate
        ? convertDateFormat(data.DueDate, 'DD MMM YYYY')
        : null,
      TotalQty: data.TotalQty ? data.TotalQty : null,
      PaymentStatus: data.PaymentStatus
        ? setStringPaymentStatus(data.PaymentStatus)
        : null,
      ResourceStatus: data.ResourceStatus
        ? setStringResourceStatus(data.ResourceStatus)
        : null,
      Status: data.Status ? ucwords(data.Status) : null,
      Date: data.Date ? convertDateFormat(data.Date, 'DD MMM YYYY') : null,
      EndDate: data.EndDate
        ? convertDateFormat(data.EndDate, 'DD MMM YYYY')
        : null,
      ResourceOwnerShip: data.ResourceOwnerShip.Text
        ? data.ResourceOwnerShip.Text
        : null,
      PackageType: data.PackageType ? data.PackageType : '',
    };
  });
  return optionsData;
};

export default setDataResourceBooking;
