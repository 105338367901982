import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Pagination, Grid, Segment, Button } from 'semantic-ui-react';
import TableCompanyApproval from '../component/table/TableCompanyRegistration';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../scripts/pagination';
import FilterData from '../../../scripts/filterDataTable';
import HeaderTitle from '../../shared/components/common/headerSegment/HeaderTitle';
import {
  getPendingCompanyAction,
  postConfirmCompanyRegistrationAction,
  resetCompanyAction,
} from '../homeAction';
import { getAccountManagerAction } from '../../shared/actions/customerProfileAction';
import { connect } from 'react-redux';
import ModalCompanyRegistration from './modal/ModalCompanyRegistration';
import ModalChooseAccountManager from './modal/ModalChooseAccountManager';
import ModalConfirm from '../../shared/components/modal/ModalConfirmationAlert';
import ModalAlert from '../../shared/components/modal/ModalAlert';
class CompanyRegistration extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
      headerTable: [
        'checkCompany',
        'Company Name',
        'Email',
        'Address',
        'Registration Date',
        'Company Business',
        'Action',
      ],
      companyList: [],
      modalDetail: false,
      dataDetailCompany: null,
      companySelect: {},
      disabled: true,
    };
  }
  static propTypes = {
    disabled: PropTypes.bool,
    id: PropTypes.string,
    actionCompanyRegistration: PropTypes.func,
    handleChange: PropTypes.func,
    onChangeCompany: PropTypes.func,
    handleModalDetail: PropTypes.func,
    loading: PropTypes.bool,
    getPendingCompanyAction: PropTypes.func,
    getAccountManager: PropTypes.array,
    getAccountManagerAction: PropTypes.func,
    postCompanyStatus: PropTypes.string,
    resetCompanyAction: PropTypes.func,
    errorCompanyRegistration: PropTypes.object,
  };
  componentDidMount() {
    this.actionGetingData();
  }

  componentDidUpdate() {
    if (this.props.postCompanyStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        modalConfirmation: true,
        loading: false,
        modalDetail: false,
        ModalChooseAccountManager: false,
      });
      this.actionGetingData();
      // this.props.getPendingCompanyAction();
      this.props.resetCompanyAction();
    } else if (this.props.postCompanyStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorCompanyRegistration.global,
        modalConfirmation: true,
        loading: false,
      });
      this.props.resetCompanyAction();
    }
  }

  actionGetingData = () => {
    this.setState({ loading: true });
    this.props
      .getPendingCompanyAction()
      .then(res =>
        this.setState({ companyList: res.value.data, loading: false })
      )
      .catch(() => {
        this.setState({ loading: false });
      });
  };
  handleCloseAllConfirmAlert = () =>
    this.setState({ openAllConfirmAlert: false });
  handleCloseRejectAlert = () => this.setState({ openRejectAlert: false });
  handleCloseAllRejectAlert = () =>
    this.setState({ openAllRejectAlert: false });
  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  onChangeCompany = (e, { name, checked, id }) => {
    let CheckCompany = 'CheckCompany';
    let company = this.state.companyList;
    if (name === 'checkAll') {
      company.map((companyCheck, i) => {
        return (company[i] = { ...companyCheck, [CheckCompany]: checked });
      });
      this.setState({
        companyList: this.state.companyList,
        company,
      });
      this.setDisabledButton('company');
    } else {
      company.map(city => {
        if (city.Id === id) {
          city[CheckCompany] = checked;
          return company;
        }
        return company;
      });
      this.setState({ companyList: company });
      this.setDisabledButton('company');
    }
  };

  companyAction = (e, { name, value }) => {
    this.props.getAccountManagerAction();
    if (name === 'ApproveAll') {
      this.setState({
        openAllConfirmAlert: true,
        contentAlert: 'Message sent to your email',
      });
    } else if (name === 'RejectedAll') {
      this.setState({
        openAllRejectAlert: true,
        contentAlert: 'Message sent to your email',
      });
    } else if (name === 1) {
      let data = {
        UserProfileId: value,
        Action: 1,
        AccountManagerProfileId: null,
      };
      this.setState({
        companyRegistrationData: data,
        openModalChooseManager: true,
        contentAlert:
          'Registration company succesfully, message sent to your email',
      });
    } else if (name === 2) {
      let data = {
        UserProfileId: value,
        Action: 2,
      };
      this.setState({
        companyRegistrationData: data,
        openRejectAlert: true,
        contentAlert:
          'The company was successfully rejected, message sent to your email',
      });
    }
  };

  handleModalDetail = data => {
    this.setState({ modalDetail: !this.state.modalDetail });
    this.setState({ dataDetailCompany: data });
  };

  handleCloseModalDetail = () =>
    this.setState({ modalDetail: !this.state.modalDetail });

  handleChangeAccountManager = (e, { value, name }) => {
    const { ApproveAll } = this.state;
    if (ApproveAll) {
      this.setState({ AccountManagerProfileId: value });
    } else {
      const companyRegistrationData = this.state.companyRegistrationData;
      companyRegistrationData[name] = value;
      this.setState({ companyRegistrationData: companyRegistrationData });
    }
  };

  confirmAction = () => {
    const {
      openConfirmAlert,
      openAllConfirmAlert,
      openAllRejectAlert,
      companyList,
      companyRegistrationData,
      AccountManagerProfileId,
      ApproveAll,
    } = this.state;
    const context = this;
    this.setState({ loading: true });
    if (openAllConfirmAlert || ApproveAll) {
      companyList.map(company => {
        if (company.CheckCompany) {
          let data = {
            UserProfileId: company.Id,
            Action: 1,
            AccountManagerProfileId: AccountManagerProfileId
              ? AccountManagerProfileId
              : null,
          };
          return context.props.postConfirmCompanyRegistrationAction(data);
        } else {
          return null;
        }
      });
    } else if (openAllRejectAlert) {
      companyList.map(company => {
        if (company.CheckCompany) {
          let data = {
            UserProfileId: company.Id,
            Action: 2,
            AccountManagerProfileId: null,
          };
          return context.props.postConfirmCompanyRegistrationAction(data);
        } else {
          return null;
        }
      });
    } else if (openConfirmAlert) {
      context.props.postConfirmCompanyRegistrationAction(
        companyRegistrationData
      );
    } else {
      context.props.postConfirmCompanyRegistrationAction(
        companyRegistrationData
      );
    }
    this.setState({
      openRejectAlert: false,
      openAllRejectAlert: false,
      openAllConfirmAlert: false,
      openConfirmAlert: false,
    });
  };

  setDisabledButton = status => {
    let check = true;
    if (status === 'company') {
      this.state.companyList.map(company => {
        return company.CheckCompany ? (check = false) : null;
      });
      this.setState({ disabled: check });
    } else {
      this.state.transactionOnHold.map(data => {
        return data.checkHold ? (check = false) : null;
      });
      this.setState({ disabledOnHold: check });
    }
  };

  handleClose = () => {
    this.setState({ modalConfirmation: false, openModalChooseManager: false });
  };
  handleClickSelectedCompany = () => {
    this.setState({
      openModalChooseManager: true,
      ApproveAll: true,
      AccountManagerProfileId: null,
    });
  };
  render() {
    const {
      companyList,
      searchInput,
      activePage,
      modalDetail,
      headerTable,
      loading,
      companySelect,
      modalConfirmation,
      disabled,
    } = this.state;
    let filterData = FilterData(companyList, 'PendingCompany', searchInput);
    let pagedData = datasetPagination(filterData, 5);
    let listData = pagedData[activePage - 1];
    let { numbersFrom, numbersTo } = getNumberFromTo(listData, activePage, 5);
    const { Name, Code } = companySelect;
    return (
      <Grid columns="equal" style={{ marginTop: '30px' }}>
        <Grid.Row stretched>
          <Grid.Column width={15}>
            <Segment>
              <HeaderTitle title="Company Registration" />
              <Input
                icon="search"
                placeholder="search"
                name="searchInput"
                value={searchInput}
                onChange={this.handleChange}
              />
              <Button
                name="ApproveAll"
                style={{ marginLeft: '10px' }}
                // value={this.props.id}
                positive
                disabled={disabled}
                onClick={this.companyAction}
                content="Approve All"
              />
              <Button
                name="RejectedAll"
                style={{ marginLeft: '10px' }}
                value={this.props.id}
                negative
                disabled={disabled}
                onClick={this.companyAction}
                content="Reject All"
              />
              <TableCompanyApproval
                listData={listData}
                headerTable={headerTable}
                pageChange={this.pageChange}
                //listData={listData}
                onChangeCompany={this.onChangeCompany}
                pagedData={pagedData}
                actionCompanyRegistration={this.companyAction}
                modalDetail={modalDetail}
                handleModalDetail={this.handleModalDetail}
                loading={loading}
              />
              {pagedData && pagedData.length > 1 && (
                <Pagination
                  totalPages={pagedData.length}
                  onPageChange={this.pageChange}
                  activePage={activePage}
                  style={{ float: 'right' }}
                />
              )}
              {pagedData && pagedData.length > 1 && (
                <p>
                  Showing {numbersTo} to {numbersFrom} of
                  {companyList.length}
                </p>
              )}
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <ModalCompanyRegistration
          open={modalDetail}
          handleModalDetail={this.handleModalDetail}
          data={this.state.dataDetailCompany}
          actionCompanyRegistration={this.companyAction}
          handleCloseModalDetail={this.handleCloseModalDetail}
          readOnly={true}
        />
        <ModalChooseAccountManager
          openModal={this.state.openModalChooseManager}
          handleClose={this.handleClose}
          header="Choose Account Manager "
          content={this.state.contentAlert}
          contentCancel="Cancel"
          handleClickAgree={this.confirmAction}
          contentAgree="Choose"
          handleChange={this.handleChangeAccountManager}
          listDropdown={this.props.getAccountManager}
          loading={loading}
        />
        <ModalConfirm
          openModal={this.state.openConfirmAlert}
          size="mini"
          close={this.handleCloseConfirmAlert}
          confirm={this.confirmAction}
          message={
            'Are you sure approve this company ' + Code + ' - ' + Name + ' ?'
          }
        />
        <ModalConfirm
          openModal={this.state.openAllConfirmAlert}
          size="mini"
          close={this.handleCloseAllConfirmAlert}
          confirm={this.handleClickSelectedCompany}
          message="Are you sure approve all selected company ?"
        />
        <ModalConfirm
          openModal={this.state.openRejectAlert}
          size="mini"
          close={this.handleCloseRejectAlert}
          confirm={this.confirmAction}
          message="Are you sure to reject this company ?"
        />
        <ModalConfirm
          openModal={this.state.openAllRejectAlert}
          size="mini"
          close={this.handleCloseAllRejectAlert}
          confirm={this.confirmAction}
          message="Are you sure reject all selected company ?"
        />
        <ModalAlert
          openModal={modalConfirmation}
          handleClose={this.handleClose}
          header={this.state.headerAlert}
          content={this.state.contentAlert}
          nameButton="OK"
        />
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    errorCompanyRegistration: state.CompanyStore.errors,
    postCompanyStatus: state.CompanyStore.postCompanyStatus,
    getAccountManager: state.CustomerProfileStore.getAccountManager.map(
      type => {
        return {
          text: type.FirstName + ' ' + type.LastName,
          value: type.Id,
          key: type.Id,
        };
      }
    ),
  };
}

export default connect(
  mapStateToProps,
  {
    getPendingCompanyAction,
    getAccountManagerAction,
    postConfirmCompanyRegistrationAction,
    resetCompanyAction,
  }
)(CompanyRegistration);
