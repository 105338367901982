// import * as types from '../../../actions/actionTypes';
import * as types from '../../home/constant/actionTypes';

const initialState = {
  loading: false,
  getPaymentHistoryList: [],
  paymentHistoryStatus: '',
  errors: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PAYMENT_HISTORY:
      return { ...state };
    case types.GET_PAYMENT_HISTORY_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loading: false,
        paymentHistoryStatus: 'failed',
      };
    case types.GET_PAYMENT_HISTORY_PENDING:
      return { ...state, loading: true };
    case types.GET_PAYMENT_HISTORY_FULFILLED:
      return {
        ...state,
        getPaymentHistoryList: action.payload.data,
        loading: false,
        paymentHistoryStatus: 'success',
      };
    case types.RESET_STATUS_PAYMENT_HISTORY:
      return { ...state, paymentHistoryStatus: '' };
    default:
      return state;
  }
};

export default reducer;
