import {
  copyObject,
  Movement,
  ItemMov,
  Airport,
} from '../itineraryBuilder/dailyProgram';
import {
  FREETIMELOCKED,
  FREETIME,
  DRIVING,
  QUEUETIME,
  DEPARTURE,
  ARRIVAL,
  CHECKIN,
  FLIGHTTIME,
  DAYSTART,
  RECREATION,
  EAT,
  DAYEND,
  CHECKOUT,
  RETURNACCOMMODATION,
} from '../../pages/shared/components/tourTransaction/dailyProgram/constant/activityType';
import { TOP } from './constData';
import {
  subtractDate,
  addDate,
  convertDateFormat,
  diffDate,
  changeSecondToHourAndMinutes,
  changeTime,
  sortDate,
} from './../moment';
import { createArrayMovements } from './../packageManagement/dailyProgramFixed';
// import DayPicker from 'react-day-picker';
// import DailyProgram from '../../pages/shared/components/tourTransaction/dailyProgram/DailyProgram';
// ============================= GENERAL FUNC ====================================
const multiIndexOf = (array, objName, findValue) => {
  return array.reduce((acc, e, i) => {
    e[objName] === findValue && acc.push(i);
    return acc;
  }, []);
};
// =========================== END GENERAL FUNC ====================================

// ============================= RESET HEADLINE ====================================

// =========================== END RESET HEADLINE ==================================

// ============================= GENERATE HEADLINE =================================
export const generateHeadline = (headline, cityList, room) => {
  let tempHeadline = copyObject(headline);
  tempHeadline = fixObjMainPrograms(tempHeadline, cityList, room);
  tempHeadline = fixHeadline(tempHeadline);
  return tempHeadline;
};

const fixObjMainPrograms = (headline, cityList, room) => {
  let tempHeadline = copyObject(headline);
  tempHeadline.MainPrograms.map(mainProgram => {
    if (mainProgram.hasOwnProperty('AccommodationSummary')) {
      mainProgram.AccommodationSummary.CheckIn = mainProgram.Date;
      mainProgram.AccommodationSummary.CheckOut = mainProgram.LeavingDate;
      mainProgram.City = cityList.find(item => item.Id === mainProgram.City.Id);
      mainProgram.Region = mainProgram.City.Region.Id;
      mainProgram.AccommodationSummary.DateTime = mainProgram.Date;
      mainProgram.AccommodationSummary.Allocations.ChildExtraBedPax =
        room.ChildExtraBedQty;
      mainProgram.AccommodationSummary.Allocations.ChildSharingRoomPax =
        room.ChildSharingRoomQty;
      mainProgram.AccommodationSummary.Allocations.ChildSingleRoomPax =
        room.ChildSingleRoomQty;
      mainProgram.AccommodationSummary.Allocations.ExtraBedPax =
        room.ExtraBedQty;
      mainProgram.AccommodationSummary.Allocations.NoBedPax =
        room.BabyCrib + room.NoBed;
      mainProgram.AccommodationSummary.Allocations.SharingBedPax =
        room.SharingBedQty;
      mainProgram.AccommodationSummary.Allocations.SharingRoomPax =
        room.SharingRoomQty;
      mainProgram.AccommodationSummary.Allocations.SingleRoomPax =
        room.SingleRoomQty;
    } else {
      mainProgram.City = mainProgram.City
        ? cityList.find(item => item.Id === mainProgram.City.Id)
        : null;
      mainProgram.Region = mainProgram.City ? mainProgram.City.Region.Id : null;
    }
    return mainProgram;
  });
  return tempHeadline;
};

const fixHeadline = headline => {
  let tempHeadline = copyObject(headline);
  // mengumpulkan departures, mainprogram dan returns dalam 1 array dan di urutkan berdasarkan waktu
  let arrayHeadline = [];
  tempHeadline.Departures.map(e => arrayHeadline.push(e));
  tempHeadline.MainPrograms.map(e => arrayHeadline.push(e));
  tempHeadline.Returns.map(e => arrayHeadline.push(e));
  arrayHeadline = sortDate(arrayHeadline, 'Date');
  arrayHeadline = sortHeadline(arrayHeadline);
  // [END] mengumpulkan departures, mainprogram dan returns dalam 1 array dan di urutkan berdasarkan waktu
  // membuat Headline baru
  let newHeadline = { Departures: [], MainPrograms: [], Returns: [] };
  let idxFirstAccom = arrayHeadline.findIndex(e => e.AccommodationSummary);
  let idxLastAccom = [...arrayHeadline]
    .reverse()
    .findIndex(e => e.AccommodationSummary);
  idxLastAccom = arrayHeadline.length - (idxLastAccom + 1);
  newHeadline.Departures = arrayHeadline.slice(0, idxFirstAccom);
  newHeadline.MainPrograms = arrayHeadline.slice(
    idxFirstAccom,
    idxLastAccom + 1
  );
  newHeadline.Returns = arrayHeadline.slice(
    idxLastAccom + 1,
    arrayHeadline.length
  );
  return newHeadline;
  // [END] membuat headline baru
};

const sortHeadline = arrayHeadline => {
  let tempArrayHeadline = [...arrayHeadline];
  tempArrayHeadline = tempArrayHeadline.reduce((acc, hl, i) => {
    let nextHl = tempArrayHeadline[i + 1];
    let prevHl = tempArrayHeadline[i - 1];
    if (
      hl.AccommodationSummary !== undefined &&
      (nextHl ? new Date(hl.Date) - new Date(nextHl.Date) === 0 : false) &&
      (nextHl ? nextHl.TransferType === 'Movement_arrival' : false)
    ) {
      acc.push(nextHl);
      acc.push(hl);
    } else if (
      hl.TransferType === 'Movement_arrival' &&
      (prevHl ? new Date(hl.Date) - new Date(prevHl.Date) === 0 : false) &&
      (prevHl ? prevHl.AccommodationSummary !== undefined : false)
    ) {
      // eslint-disable-next-line
      acc;
    } else {
      acc.push(hl);
    }
    return acc;
  }, []);
  return tempArrayHeadline;
};
// ========================== END GENERATE HEADLINE =================================
// ======================== PERBAIKI WAKTU DI HEADLINE ==============================
// untuk memperbaiki tangga di accom karena tanggal c/i dan c/o tidak sama dengan date dan leaving date
// untuk memperbaiki date di return
export const fixDateHeadline = headlineProgram => {
  let tempHeadline = copyObject(headlineProgram);
  let lastAccom = null;
  tempHeadline.MainPrograms.map(e => {
    if (e.AccommodationSummary !== undefined) {
      e.Date = e.AccommodationSummary.CheckIn;
      e.LeavingDate = e.AccommodationSummary.CheckOut;
      lastAccom = e;
    }
    return e;
  });
  if (lastAccom !== null && tempHeadline.Returns.length !== 0) {
    // cek jika waktu return lebih kecil dari pada c/o maka perbaiki waktu return
    if (
      new Date(tempHeadline.Returns[0].Date).getTime() <
      new Date(lastAccom.AccommodationSummary.CheckOut).getTime()
    ) {
      let diffLastAccomodationDeparture = diffDate(
        tempHeadline.Returns[0].Date,
        lastAccom.AccommodationSummary.CheckOut,
        'm'
      );
      tempHeadline.Returns.map(e => {
        e.Date = addDate(e.Date, diffLastAccomodationDeparture, 'm');
        return e;
      });
    }
  }
  return tempHeadline;
};
// ==================== [END] PERBAIKI WAKTU DI HEADLINE =============================
// =========================== GENERATE DAILY PROGRAM ==============================
const generateDailyProgram = (dailyProgram, movementList) => {
  let dailyTemp = copyObject(dailyProgram);
  dailyTemp = addDayStartDayEnd(dailyTemp, movementList);
  // dailyTemp = deleteStartDayEnd(dailyTemp, movementList); //untuk menghapus day start dan day end yang berada di luar keiinginan
  dailyTemp = addDriving(dailyTemp, movementList);
  dailyTemp = addFlightTime(dailyTemp, movementList);
  // eslint-disable-next-line
  dailyTemp.map((daily, iDaily) => {
    dailyTemp = flightTimeNoDuration(dailyTemp, iDaily);
  });
  dailyTemp.map((daily, iDay) => {
    dailyTemp = fixItemQueueFreetime(dailyTemp, iDay);
    return daily;
  });
  return dailyTemp;
};

const addFlightTime = (dailyProgram, movementList) => {
  let dailyTemp = copyObject(dailyProgram);
  let objFlight = movementList.find(e => e.Name === FLIGHTTIME);
  // eslint-disable-next-line
  dailyTemp.map((daily, iDaily) => {
    let newMoves = [];
    // eslint-disable-next-line
    daily.Movements.map((move, iMove) => {
      let nowMove = move.MovementName;
      let nextMove = daily.Movements[iMove + 1]
        ? daily.Movements[iMove + 1].MovementName
        : '';
      if (
        (nowMove === DEPARTURE && nextMove === ARRIVAL) ||
        (nowMove === DEPARTURE && nextMove === '')
      ) {
        let itemMove = addItemFlightTimeDriving();
        let newMove = addMovementFlightTime(
          objFlight,
          itemMove,
          dailyTemp,
          iDaily,
          iMove,
          dailyTemp[iDaily].Movements.slice(0, iMove + 1)
        );
        newMoves.push({ idx: iMove + 1, obj: newMove });
      }
    });
    // eslint-disable-next-line
    newMoves.map((ePush, iPush) => {
      let idx = ePush.idx + iPush;
      dailyTemp[iDaily].Movements.splice(idx, 0, ePush.obj);
    });
  });
  return dailyTemp;
};
// untuk menambahkan driving pada hari yang tidak ada driving
const addDriving = (dailyProgram, movementList) => {
  let dailyTemp = copyObject(dailyProgram);
  let objDriving = movementList.find(e => e.Name === DRIVING);
  // eslint-disable-next-line
  dailyTemp.map((daily, iDaily) => {
    let newMoves = [];
    // eslint-disable-next-line
    daily.Movements.map((move, iMove) => {
      let prevMove = daily.Movements[iMove - 1]
        ? daily.Movements[iMove - 1].MovementName
        : '';
      let nowMove = move.MovementName;
      let nextMove = daily.Movements[iMove + 1]
        ? daily.Movements[iMove + 1].MovementName
        : '';
      if (
        // =============== DAY START =========================
        (nowMove === DAYSTART && nextMove === RECREATION) ||
        (nowMove === DAYSTART && nextMove === EAT) ||
        (nowMove === DAYSTART && nextMove === DEPARTURE) ||
        // =============== RECREATION =========================
        (nowMove === RECREATION && nextMove === RECREATION) ||
        (nowMove === RECREATION && nextMove === EAT) ||
        (nowMove === RECREATION && nextMove === DEPARTURE) ||
        (nowMove === RECREATION && nextMove === CHECKIN) ||
        (nowMove === RECREATION && nextMove === CHECKOUT) ||
        (nowMove === RECREATION && nextMove === DAYEND) ||
        (nowMove === RECREATION && nextMove === RETURNACCOMMODATION) ||
        // =============== EAT =========================
        (nowMove === EAT && nextMove === RECREATION) ||
        (nowMove === EAT && nextMove === EAT) ||
        (nowMove === EAT && nextMove === DEPARTURE) ||
        (nowMove === EAT && nextMove === CHECKIN) ||
        (nowMove === EAT && nextMove === CHECKOUT) ||
        (nowMove === EAT && nextMove === DAYEND) ||
        (nowMove === EAT && nextMove === RETURNACCOMMODATION) ||
        // =============== ARRIVAL =========================
        (nowMove === ARRIVAL && nextMove === CHECKIN) ||
        (nowMove === ARRIVAL && nextMove === EAT) ||
        (nowMove === ARRIVAL && nextMove === RECREATION) ||
        (nowMove === ARRIVAL && nextMove === '') ||
        // =============== FREETIME =========================
        (nowMove === FREETIME && nextMove === RECREATION) ||
        (nowMove === FREETIME && nextMove === EAT) ||
        (nowMove === FREETIME && nextMove === DEPARTURE && prevMove !== '') ||
        (nowMove === FREETIME && nextMove === DAYEND && prevMove !== CHECKIN) ||
        // =============== QUEUETIME =========================
        (nowMove === QUEUETIME && nextMove === RECREATION) ||
        (nowMove === QUEUETIME && nextMove === EAT) ||
        (nowMove === QUEUETIME && nextMove === DEPARTURE && prevMove !== '') ||
        (nowMove === QUEUETIME && nextMove === DAYEND && prevMove !== CHECKIN)
      ) {
        let newItemMove = addItemFlightTimeDriving();
        let newMove = addMovementDriving(objDriving, newItemMove, move);
        newMoves.push({ idx: iMove + 1, obj: newMove });
      }
    });
    // eslint-disable-next-line
    newMoves.map((ePush, iPush) => {
      let idx = ePush.idx + iPush;
      dailyTemp[iDaily].Movements.splice(idx, 0, ePush.obj);
    });
  });
  return dailyTemp;
};
const addDayStartDayEnd = (dailyProgram, movementList) => {
  let dailyTemp = copyObject(dailyProgram);
  let objDayStart = movementList.find(e => e.Name === DAYSTART);
  let objDayEnd = movementList.find(e => e.Name === DAYEND);
  let accommodations = getCheckinCheckout(dailyTemp); //array obj {checkIn: objMovement, checkOut: objMovement}
  // eslint-disable-next-line
  accommodations.map(accommodation => {
    // eslint-disable-next-line
    dailyTemp.map((daily, i) => {
      let diffDatesCheckIn = diffDate(
        daily.Date,
        changeTime(accommodation.checkIn.DateTime, 0, 0),
        'days'
      );
      let diffDatesCheckOut = diffDate(
        changeTime(accommodation.checkOut.DateTime, 0, 0),
        daily.Date,
        'days'
      );
      let isDayStart = daily
        ? daily.Movements[0]
          ? daily.Movements[0].MovementName === DAYSTART
          : false
        : false;
      let isDayEnd = daily
        ? daily.Movements[daily.Movements.length - 1]
          ? daily.Movements[daily.Movements.length - 1].MovementName === DAYEND
          : false
        : false;
      if (diffDatesCheckIn < 0 && diffDatesCheckOut < 0) {
        if (!isDayStart)
          dailyTemp = addMoveDayStart(
            dailyTemp,
            accommodation.checkIn,
            objDayStart,
            i
          );
        if (!isDayEnd)
          dailyTemp = addMoveDayEnd(
            dailyTemp,
            accommodation.checkOut,
            objDayEnd,
            i
          );
      } else if (diffDatesCheckIn === 0) {
        if (!isDayEnd)
          dailyTemp = addMoveDayEnd(
            dailyTemp,
            accommodation.checkOut,
            objDayEnd,
            i
          );
      } else if (diffDatesCheckOut === 0) {
        if (!isDayStart)
          dailyTemp = addMoveDayStart(
            dailyTemp,
            accommodation.checkIn,
            objDayStart,
            i
          );
      }
      // else if (diffDatesCheckIn > 0) {
      //   //untuk menghapus day start dan day end yang berada di luar keiinginan
      //   if (isDayStart) {
      //     dailyTemp[i].Movements.splice(0, 1);
      //   }
      // } else if (diffDatesCheckOut > 0) {
      //   if (isDayEnd) {
      //     dailyTemp[i].Movements.splice(dailyTemp[i].Movements.length - 1, 1);
      //   }
      // }
      dailyTemp = fixTimeDayStart(dailyTemp, i);
      dailyTemp = fixTimeDayEnd(dailyTemp, i);
    });
  });
  return dailyTemp;
};
// untuk menambahkan obj movement start day.
// accommodation adalah movement checkin.
const addMoveDayStart = (dailyProgram, accommodation, objDayStart, idxDay) => {
  let dailyTemp = copyObject(dailyProgram);
  dailyTemp[idxDay].Movements.splice(0, 0, {
    ...accommodation,
    MovementDescription: objDayStart ? objDayStart.Description : '',
    MovementName: objDayStart ? objDayStart.Name : DAYSTART,
    Note: '',
    ServiceItemId: objDayStart ? objDayStart.ServiceItemId : '',
    TypeMovement: true,
  });
  return dailyTemp;
};
const addMoveDayEnd = (dailyProgram, accommodation, objDayEnd, idxDay) => {
  let dailyTemp = copyObject(dailyProgram);
  dailyTemp[idxDay].Movements.push({
    ...accommodation,
    MovementDescription: objDayEnd ? objDayEnd.Description : '',
    MovementName: objDayEnd ? objDayEnd.Name : DAYSTART,
    Note: '',
    ServiceItemId: objDayEnd ? objDayEnd.ServiceItemId : '',
    TypeMovement: true,
  });
  return dailyTemp;
};

// diffDate(dateTimePrevMove, movement.DateTime, 'seconds') < 0
const getCheckinCheckout = dailyProgram => {
  let dailyTemp = copyObject(dailyProgram);
  let movements = createArrayMovements(dailyTemp);
  let idxCheckIn = multiIndexOf(movements, 'MovementName', CHECKIN);
  let idxCheckOut = multiIndexOf(movements, 'MovementName', CHECKOUT);
  let accommodation = idxCheckIn
    .map((e, i) => {
      let checkinCheckout = null;
      if (
        movements[e].Item.ServiceItemId ===
        movements[idxCheckOut[i]].Item.ServiceItemId
      ) {
        checkinCheckout = {
          checkIn: movements[e],
          checkOut: movements[idxCheckOut[i]],
        };
      }
      return checkinCheckout;
    })
    .filter(e => (e ? true : false));
  return accommodation;
};

// =========================== GENERATE DAILY PROGRAM ==============================
export const addDailyProgram = (
  dailyProgram,
  id,
  moveIdx,
  typeMovement, //moveyang ditambahin
  movementList,
  itemMovement,
  activityData,
  positionButtonAdd,
  objDestination
) => {
  let dailyTemp = copyObject(dailyProgram);
  let addMoveIdx = moveIdx;
  let newMovements = [];
  let newItemMovement = {};
  let typeMoveDriving = movementList.find(e => e.Name === DRIVING);
  let isUseNextMove =
    positionButtonAdd !== TOP && typeMovement.Name !== ARRIVAL;
  let nextMove = isUseNextMove
    ? dailyTemp[id].Movements[moveIdx + 1]
    : dailyTemp[id].Movements[moveIdx];
  if (moveIdx !== 0 || positionButtonAdd !== TOP) addMoveIdx = moveIdx + 1;
  // tambahkan driving dari startdate
  // NOTE: jika yang ditambahkan departure di hari pertama sebelum checkin, maka tidak perlu ada driving
  //       karena aktifitas sebelumnya selalu queue
  if ([ARRIVAL, FREETIME, QUEUETIME].indexOf(typeMovement.Name) === -1) {
    if (
      dailyTemp[id].Movements[0] &&
      (dailyTemp[id].Movements[0].MovementName === DAYSTART ||
        [CHECKIN, ARRIVAL, EAT, RECREATION, FREETIME, QUEUETIME].indexOf(
          dailyTemp[id].Movements[moveIdx].MovementName
        ) !== -1) &&
      (positionButtonAdd !== TOP || typeMovement.Name !== DEPARTURE)
    ) {
      newItemMovement = addItem(
        itemMovement,
        typeMoveDriving,
        moveIdx,
        positionButtonAdd,
        dailyTemp,
        id,
        objDestination
      );
      newMovements.push(
        addMovementDriving(
          typeMoveDriving,
          newItemMovement,
          dailyTemp[id].Movements[moveIdx]
        )
      );
    }
  }
  // end tambahkan driving dari startdate
  // tambahkan freetime lock sebelum departure
  if (typeMovement.Name === DEPARTURE) {
    let typeMoveFreetimeLocked = movementList.find(
      e => e.Name === FREETIMELOCKED
    );
    newItemMovement = addItem(
      itemMovement,
      typeMoveFreetimeLocked,
      moveIdx,
      positionButtonAdd,
      dailyTemp,
      id,
      objDestination
    );
    newMovements.push(
      addMovement(
        typeMoveFreetimeLocked,
        newItemMovement,
        activityData,
        objDestination
      )
    );
  }
  // end tambahkan freetime lock departure
  // tambahkan aktifitas baru ke daily program
  newItemMovement = addItem(
    itemMovement,
    typeMovement,
    moveIdx,
    positionButtonAdd,
    dailyTemp,
    id,
    objDestination
  );
  newMovements.push(
    addMovement(typeMovement, newItemMovement, activityData, objDestination)
  );
  // end tambahkan aktifitas baru ke daily program
  if (typeMovement.Name === DEPARTURE) {
    // tambahkan aktifitas flightime
    let typeMoveFlightTime = movementList.find(e => e.Name === FLIGHTTIME);
    newItemMovement = addItem(
      itemMovement,
      typeMoveFlightTime,
      moveIdx,
      positionButtonAdd,
      dailyTemp,
      id,
      objDestination
    );
    newMovements.push(
      addMovementFlightTime(
        typeMoveFlightTime,
        newItemMovement,
        dailyTemp,
        id,
        moveIdx,
        newMovements
      )
    );
    // end tambahkan aktifitas flightime
  } else if (
    [DEPARTURE, FREETIME, QUEUETIME].indexOf(typeMovement.Name) === -1 &&
    // dailyProgram.length !== id &&
    [DRIVING, FREETIME, QUEUETIME].indexOf(
      nextMove ? nextMove.MovementName : ''
    ) === -1
  ) {
    // tambahkan aktifitas driving
    newItemMovement = addItem(
      itemMovement,
      typeMoveDriving,
      moveIdx,
      positionButtonAdd,
      dailyTemp,
      id,
      objDestination
    );
    newMovements.push(
      addMovementDriving(
        typeMoveDriving,
        newItemMovement,
        newMovements[newMovements.length - 1]
      )
    );
    // end tambahkan aktifitas driving
  }

  // INSERT DATA DAILY PROGRAM
  newMovements.map((e, i) =>
    dailyTemp[id].Movements.splice(addMoveIdx + i, 0, e)
  );
  // END INSERT DATA DAILY PROGRAM
  return dailyTemp;
};

// =============================================================================================
// ============================= UNTUK MENAMBAHKAN MOVEMENT ITEM ===============================
// =============================================================================================
const addItem = (
  itemMovement,
  typeMovement,
  moveIdx,
  positionButtonAdd,
  dailyProgram,
  dayIdx,
  objDestination
) => {
  let newItemMovement = {};
  if ([DEPARTURE, ARRIVAL].indexOf(typeMovement.Name) !== -1) {
    newItemMovement = addItemAirport(itemMovement);
  } else if ([FLIGHTTIME].indexOf(typeMovement.Name) !== -1) {
    newItemMovement = addItemFlightTimeDriving();
  } else if ([QUEUETIME].indexOf(typeMovement.Name) !== -1) {
    if (moveIdx === 0 || positionButtonAdd === TOP) {
      newItemMovement = addItemQueueFreetimeWithNextMove(
        dailyProgram,
        dayIdx,
        moveIdx
      );
    } else {
      newItemMovement = addItemFreetime(dailyProgram, dayIdx, moveIdx);
    }
  } else if ([DRIVING].indexOf(typeMovement.Name) !== -1) {
    newItemMovement = addItemFlightTimeDriving();
  } else if ([RECREATION].indexOf(typeMovement.Name) !== -1) {
    newItemMovement = addItemRecreation(itemMovement, objDestination);
  } else if ([EAT].indexOf(typeMovement.Name) !== -1) {
    newItemMovement = addItemEat(itemMovement, objDestination);
  } else if ([FREETIME].indexOf(typeMovement.Name) !== -1) {
    newItemMovement = addItemFreetime(dailyProgram, dayIdx, moveIdx);
  } else if ([FREETIMELOCKED].indexOf(typeMovement.Name) !== -1) {
    newItemMovement = addItemQueueFreetimeWithNextMove(
      dailyProgram,
      dayIdx,
      moveIdx
    );
  }
  return newItemMovement;
};
// ********************************* QUEUE TIME *********************************************
// * Fungsi: untuk menambahkan quetime di aktifitas pertama yang tidak memiliki day start
//           sehingga itemnya diambil dari aktifitas setelah quetime.
//           contoh: day 1 : [queuetime, departure, arrival]
// * dayIdx => index hari, tipenya number
// * moveIdx => index movement, tipenya number
// * daily program => list seluruh aktifitas, tipenya array
// ******************************************************************************************
const addItemQueueFreetimeWithNextMove = (dailyProgram, dayIdx, moveIdx) => {
  let item = copyObject(ItemMov);
  let itemNextMove = dailyProgram[dayIdx].Movements[moveIdx]
    ? dailyProgram[dayIdx].Movements[moveIdx].Item
    : null;
  if (itemNextMove !== null) {
    item.Name = itemNextMove.Name;
    item.Desc = itemNextMove.Desc;
    item.ServiceType = itemNextMove.ServiceType;
    item.ImageUrl = itemNextMove.ImageUrl;
    item.ImageName = itemNextMove.ImageName;
    item.ServiceItemId = itemNextMove.ServiceItemId;
    item.Address = itemNextMove.Address;
    item.PlaceId = itemNextMove.PlaceId;
    item.CityId = itemNextMove.CityId;
    item.ProfileDesc = itemNextMove.ProfileDesc;
    if (itemNextMove.Hours) {
      item.Hours = itemNextMove.Hours;
    }
  }
  return item;
};
// *********************************** END QUEUETIME ****************************************
const addItemFreetime = (dailyProgram, dayIdx, moveIdx) => {
  let item = copyObject(ItemMov);
  let itemPrevMove = dailyProgram[dayIdx].Movements[moveIdx].Item;
  if (itemPrevMove !== null) {
    item.Name = itemPrevMove.Name;
    item.Desc = itemPrevMove.Desc;
    item.ServiceType = itemPrevMove.ServiceType;
    item.ImageUrl = itemPrevMove.ImageUrl;
    item.ImageName = itemPrevMove.ImageName;
    item.ServiceItemId = itemPrevMove.ServiceItemId;
    item.Address = itemPrevMove.Address;
    item.PlaceId = itemPrevMove.PlaceId;
    item.CityId = itemPrevMove.CityId;
    item.ProfileDesc = itemPrevMove.ProfileDesc;
    if (itemPrevMove.Hours) {
      item.Hours = itemPrevMove.Hours;
    }
  }
  return item;
};
// ************************************* AIRPORT ********************************************
const addItemAirport = itemMovement => {
  let item = copyObject(ItemMov);
  if (itemMovement !== null) {
    item.Name = itemMovement.PlaceType; //V
    item.Desc = itemMovement.Name; // V
    item.ServiceType = itemMovement.ServiceType;
    item.ImageUrl = itemMovement.ImageUrl;
    item.ImageName = itemMovement.ImageName;
    item.ServiceItemId = itemMovement.ServiceItemId;
    item.Address = itemMovement.AddressObject; //v
    item.PlaceId = itemMovement.Id;
    item.CityId = itemMovement.CityId; //v
    item.ProfileDesc = itemMovement.ProfileDesc;
    if (itemMovement.Hours) {
      item.Hours = itemMovement.Hours;
    }
  }
  return item;
};
// *********************************** END AIRPORT ******************************************
// ********************************* FLIGHT TIME ITEM ***************************************
const addItemFlightTimeDriving = () => {
  let item = copyObject(ItemMov);
  return item;
};
// ******************************* END FLIGHT TIME ITEM **************************************
// ********************************* ADD ITEM EXCURSION **************************************
const addItemRecreation = (itemMovement, objDestination) => {
  let item = copyObject(ItemMov);
  if (itemMovement !== null) {
    item.Name = itemMovement.Name;
    item.Desc = itemMovement.Description;
    item.ServiceType = itemMovement.AttractionCategory;
    item.ImageUrl = itemMovement.ImageUrl;
    item.ImageName = itemMovement.ImageName;
    item.ServiceItemId = itemMovement.ServiceItemId;
    item.Address = itemMovement.AddressObject;
    item.OperationEndTime = itemMovement.OperationEndTime;
    item.OperationStartTime = itemMovement.OperationStartTime;
    item.IsSolidStartTime = itemMovement.IsSolidStartTime;
    item.CityId = objDestination.Id;
  }
  return item;
};

// ******************************* END ADD ITEM EXCURSION ************************************
// ************************************* ADD ITEM MEAL ***************************************
const addItemEat = (itemMovement, objDestination) => {
  let item = copyObject(ItemMov);
  if (itemMovement !== null) {
    item.Name = itemMovement.Name;
    item.Desc = itemMovement.MenuClass;
    item.ServiceType = itemMovement.TypeOfMenu;
    item.ImageUrl = itemMovement.ImageUrl;
    item.ServiceItemId = itemMovement.ServiceItemId;
    item.Address = itemMovement.Address;
    // item.OperationEndTime = itemMovement.OperationEndTime;
    // item.OperationStartTime = itemMovement.OperationStartTime;
    // item.IsSolidStartTime = itemMovement.IsSolidStartTime;
    item.CityId = objDestination.Id;
  }
  return item;
};
// ***********************************  ENDADD ITEM MEAL **************************************
// =============================================================================================
// ============================= END UNTUK MENAMBAHKAN MOVEMENT ITEM ===========================
// =============================================================================================

// =============================================================================================
// ================================ UNTUK MENAMBAHKAN MOVEMENT =================================
// =============================================================================================

// ******************************* FLIGHT TIME MOVEMENT ****************************************
const addMovementFlightTime = (
  typeMovement,
  item,
  dailyProgram,
  dayIdx,
  moveIdx,
  newMovements
) => {
  let obj = copyObject(Movement);
  let dailyTemp = copyObject(dailyProgram);
  let newPrevMove = newMovements[newMovements.length - 1];
  let prevMove = dailyTemp[dayIdx].Movements[moveIdx];
  obj.DateTime = prevMove ? prevMove.DateTime : newPrevMove.DateTime;
  obj.MovementName = typeMovement.Name;
  obj.MovementDescription = typeMovement.Description;
  // obj.Duration = prevMove ? prevMove.Duration : 0;
  obj.Duration = 0;
  obj.DurationText = '0 mins';
  obj.ServiceItemId = typeMovement.ServiceItemId;
  obj.Destination = prevMove ? prevMove.Destination : newPrevMove.Destination;
  obj.DestinationName = prevMove
    ? prevMove.DestinationName
    : newPrevMove.Destination;
  obj.Item = item;
  return obj;
};

// **************************** END FLIGHT TIME MOVEMENT **************************************
// ********************************* DRIVING MOVEMENT *****************************************
const addMovementDriving = (typeMovement, item, prevMove) => {
  let obj = copyObject(Movement);
  obj.DateTime = convertDateFormat(
    addDate(prevMove.DateTime, prevMove.Duration, 's'),
    'YYYY-MM-DDTHH:mm:ss'
  );
  obj.MovementName = typeMovement.Name;
  obj.MovementDescription = typeMovement.Description;
  obj.Duration = 0;
  obj.DurationText = '0 mins';
  obj.ServiceItemId = typeMovement.ServiceItemId;
  obj.Destination = prevMove.Destination;
  obj.DestinationName = prevMove.DestinationName;
  obj.Item = item;
  return obj;
};

// ******************************* END DRIVING MOVEMENT **************************************

// ********************************* GENERAL MOVEMENT ****************************************
export const addMovement = (par, item, activity, destination) => {
  let obj = copyObject(Movement);
  obj.DateTime = activity.Startime;
  obj.MovementName = par.Name;
  obj.MovementDescription =
    par.Name !== FREETIMELOCKED ? par.Description : 'Free Time';
  obj.Duration =
    [ARRIVAL, DEPARTURE].indexOf(par.Name) !== -1
      ? 0
      : par.Name !== FREETIMELOCKED
      ? activity.Duration
      : 0;
  // par.Name !== FREETIMELOCKED
  //   ? activity.Duration
  //   : [ARRIVAL, DEPARTURE].indexOf(par.Name) !== -1
  //     ? 0
  //     : 0;
  obj.DurationText = changeSecondToHourAndMinutes(obj.Duration);
  obj.ServiceItemId = par.ServiceItemId;
  obj.Destination = destination.Id;
  obj.DestinationName = destination.Name;
  obj.Note = activity.Note;
  obj.Item = item;
  return obj;
};
// ******************************* END GENERAL MOVEMENT **************************************
// =============================================================================================
// ============================== END UNTUK MENAMBAHKAN MOVEMENT ===============================
// =============================================================================================

// =============================================================================================
// ============================== GENERATE DAILY PROGRAM =======================================
// =============================================================================================
// ************************ GENERATE DAILY PROGRAM AFTER DELETE ACTIVITY ***********************
const generateDailyProgramAfterDelete = (dailyProgram, dayIdx, moveIdx) => {
  let dailyTemp = copyObject(dailyProgram);
  dailyTemp = fixItemQueueFreetime(dailyTemp, dayIdx);
  dailyTemp = fixBottomDaily(dailyTemp, dayIdx, moveIdx).daily;
  dailyTemp = flightTimeNoDuration(dailyTemp, dayIdx);
  // dailyTemp = fixFreetimeLock(dailyTemp, dayIdx);
  dailyTemp = resetDayStartDayEnd(dailyTemp, dayIdx);
  dailyTemp = fixTimeDayStart(dailyTemp, dayIdx);
  dailyTemp = fixTimeDayEnd(dailyTemp, dayIdx);
  dailyTemp = sequanceDailyProgram(dailyTemp, dayIdx);
  return dailyTemp;
};
// ********************** END GENERATE DAILY PROGRAM AFTER DELETE ACTIVITY ***********************
// ************************ GENERATE DAILY PROGRAM AFTER ADD ACTIVITY **************************
const generateDailyProgramAfterCRUD = (
  dailyProgram,
  dayIdx,
  moveIdx,
  move
  // movementList
  // action
) => {
  let dailyTemp = copyObject(dailyProgram);
  dailyTemp = fixItemQueueFreetime(dailyTemp, dayIdx);
  dailyTemp = flightTimeNoDuration(dailyTemp, dayIdx);
  dailyTemp = fixTimeDaily(dailyTemp, dayIdx, moveIdx, move);
  // dailyTemp = fixFreetimeLock(dailyTemp, dayIdx);
  dailyTemp = fixTimeDayStart(dailyTemp, dayIdx);
  dailyTemp = fixTimeDayEnd(dailyTemp, dayIdx);
  dailyTemp = sequanceDailyProgram(dailyTemp, dayIdx);
  return dailyTemp;
};
// untuk mengubah item dari queue time
// terdapat 2 jenis item queue yaitu mengikuti item sebelumnya dan mengikuti item setelahnya
// dengan kondisi queue tsb adalah queue time pertama di hari tsb.
// kondisi : [queue, [button add], checkin] => [queue, departure, [button add], checkin]
//           item quetime mengikuti checkin => item queuetime mengikuti departure
const fixItemQueueFreetime = (dailyProgram, dayIdx) => {
  let dailyTemp = copyObject(dailyProgram);
  let movements = dailyTemp[dayIdx].Movements;
  // memperbaiki item quetime dan freetime lock yang mengikuti next activity
  for (let idx = movements.length - 1; idx >= 0; idx--) {
    let move = movements[idx];
    if (
      (move.MovementName === QUEUETIME && idx === 0) ||
      move.MovementName === FREETIMELOCKED
    ) {
      move.Item = addItemQueueFreetimeWithNextMove(dailyTemp, dayIdx, idx + 1);
    }
  }
  // end memperbaiki item quetime dan freetime lock yang mengikuti next activity
  // memperbaiki item queuetime dan freetime yang mengikuti prev activity
  movements.map((move, idx) => {
    if (
      FREETIME === move.MovementName ||
      (move.MovementName === QUEUETIME && idx !== 0)
    ) {
      move.Item = addItemFreetime(dailyTemp, dayIdx, idx - 1);
      return move;
    } else {
      return move;
    }
  });
  // end memperbaiki item queuetime dan freetime yang mengikuti prev activity
  return dailyTemp;
};
const fixTimeDaily = (dailyProgram, dayIdx, moveIdx, move) => {
  let tempDaily = copyObject(dailyProgram);
  let movements = tempDaily[dayIdx].Movements;
  // eslint-disable-next-line
  let newMoveIdx = movements.findIndex(
    (e, i) => e.MovementName === move.Name && i >= moveIdx
  );
  if (
    [DEPARTURE, RECREATION, EAT, FREETIME, QUEUETIME, DRIVING].indexOf(
      move.Name
    ) !== -1
  ) {
    let resultFixTopDaily = fixTopDaily(tempDaily, dayIdx, newMoveIdx - 1);
    tempDaily = resultFixTopDaily.daily;
    newMoveIdx =
      resultFixTopDaily.moveIdx < 0
        ? 0
        : resultFixTopDaily.moveIdx > movements.length
        ? movements.length - 1
        : resultFixTopDaily.moveIdx;
  }
  if (
    [ARRIVAL, RECREATION, EAT, FREETIME, QUEUETIME, DRIVING].indexOf(
      move.Name
    ) !== -1
  ) {
    let resultFixBottomDaily = fixBottomDaily(
      tempDaily,
      dayIdx,
      newMoveIdx + 1
    );
    tempDaily = resultFixBottomDaily.daily;
    newMoveIdx =
      resultFixBottomDaily.moveIdx > movements.length
        ? movements.length - 1
        : resultFixBottomDaily.moveIdx;
  }
  return tempDaily;
};
// untuk memperbaiki waktu di daily program
// pivotnya adalah movement baru, arrival, departure
// const fixTimeDailyOld = (dailyProgram, dayIdx, moveIdx, move) => {
//   let tempDaily = copyObject(dailyProgram);
//   let movements = tempDaily[dayIdx].Movements;
//   // eslint-disable-next-line
//   let newMoveIdx = movements.findIndex(
//     (e, i) => e.MovementName === move.Name && i >= moveIdx
//   );
//   if (
//     [DEPARTURE, RECREATION, EAT, FREETIME, QUEUETIME, DRIVING].indexOf(
//       move.Name
//     ) !== -1
//   ) {
//     let resultFixTopDaily = fixTopDaily(tempDaily, dayIdx, newMoveIdx - 1);
//     tempDaily = resultFixTopDaily.daily;
//     newMoveIdx =
//       resultFixTopDaily.moveIdx < 0
//         ? 0
//         : resultFixTopDaily.moveIdx > movements.length
//           ? movements.length - 1
//           : resultFixTopDaily.moveIdx;
//   }
//   if (
//     [ARRIVAL, RECREATION, EAT, FREETIME, QUEUETIME, DRIVING].indexOf(
//       move.Name
//     ) !== -1
//   ) {
//     let resultFixBottomDaily = fixBottomDaily(
//       tempDaily,
//       dayIdx,
//       newMoveIdx + 1
//     );
//     tempDaily = resultFixBottomDaily.daily;
//     newMoveIdx =
//       resultFixBottomDaily.moveIdx > movements.length
//         ? movements.length - 1
//         : resultFixBottomDaily.moveIdx;
//   }
//   if (newMoveIdx > 0 && newMoveIdx < movements.length - 1) {
//     let diffDates = diffDate(
//       movements[newMoveIdx + 1].DateTime,
//       movements[newMoveIdx].DateTime,
//       'seconds'
//     );
//     if (movements[newMoveIdx - 1].MovementName === DEPARTURE && diffDates < 0) {
//       let resultFixTopDaily = fixTopDaily(tempDaily, dayIdx, newMoveIdx - 2);
//       tempDaily = resultFixTopDaily.daily;
//     }
//   }
//   return tempDaily;
// };

// untuk memperbaiki aktifitas sebelum aktifitas baru di tambahkan
const fixTopDaily = (dailyProgram, dayIdx, moveIdx) => {
  let tempDaily = copyObject(dailyProgram);
  let movements = tempDaily[dayIdx].Movements;
  let loopMove = '';
  let idx = moveIdx;
  do {
    let movement = movements[idx];
    loopMove = movement ? movement.MovementName : movement;
    if ([ARRIVAL, DEPARTURE, undefined].indexOf(loopMove) === -1) {
      let nextMove = movements[idx + 1];
      let dateTimeNextMove = nextMove
        ? convertDateFormat(
            subtractDate(nextMove.DateTime, movement.Duration, 's'),
            'YYYY-MM-DDTHH:mm:ss'
          )
        : false;
      let diffDates = dateTimeNextMove
        ? diffDate(movement.DateTime, dateTimeNextMove, 'seconds') < 0
        : false;
      if (diffDates) {
        movement.DateTime = dateTimeNextMove
          ? dateTimeNextMove
          : movement.DateTime;
      }
    }
    idx--;
  } while (
    [DAYSTART, DAYEND, ARRIVAL, DEPARTURE, undefined].indexOf(loopMove) === -1
  );
  return { moveIdx: idx, daily: tempDaily };
};

// const fixTopDailyOld = (dailyProgram, dayIdx, moveIdx) => {
//   let tempDaily = copyObject(dailyProgram);
//   let movements = tempDaily[dayIdx].Movements;
//   let loopMove = '';
//   let idx = moveIdx;
//   do {
//     let movement = movements[idx];
//     loopMove = movement ? movement.MovementName : movement;
//     if ([ARRIVAL, DEPARTURE, undefined].indexOf(loopMove) === -1) {
//       let nextMove = movements[idx + 1];
//       movement.DateTime = convertDateFormat(
//         subtractDate(nextMove.DateTime, movement.Duration, 's'),
//         'YYYY-MM-DDTHH:mm:ss'
//       );
//     }
//     idx--;
//   } while (
//     [DAYSTART, DAYEND, ARRIVAL, DEPARTURE, undefined].indexOf(loopMove) === -1
//   );
//   return { moveIdx: idx, daily: tempDaily };
// };
// ========================== NEW FIX BOTTOM ===========================
const fixBottomDaily = (dailyProgram, dayIdx, moveIdx) => {
  let tempDaily = copyObject(dailyProgram);
  let movements = tempDaily[dayIdx].Movements;
  let loopMove = '';
  let idx = moveIdx;
  // eslint-disable-next-line
  do {
    let movement = movements[idx];
    loopMove = movement ? movement.MovementName : movement;
    if ([ARRIVAL, DEPARTURE, undefined].indexOf(loopMove) === -1) {
      let prevMove = movements[idx - 1];
      let dateTimePrevMove = prevMove
        ? convertDateFormat(
            addDate(prevMove.DateTime, prevMove.Duration, 's'),
            'YYYY-MM-DDTHH:mm:ss'
          )
        : false;
      let diffDates = dateTimePrevMove
        ? diffDate(dateTimePrevMove, movement.DateTime, 'seconds') < 0
        : false;
      if (diffDates || loopMove === DRIVING) {
        movement.DateTime = dateTimePrevMove
          ? dateTimePrevMove
          : movement.DateTime;
      }
    }
    idx++;
    // barunih
    movement = movements[idx];
    loopMove = movement ? movement.MovementName : movement;
    // barunih
  } while (
    [DAYSTART, DAYEND, ARRIVAL, DEPARTURE, undefined].indexOf(loopMove) === -1
  );
  return { moveIdx: idx, daily: tempDaily };
};
// ========================== NEW FIX BOTTOM ===========================
// const fixBottomDailyOld = (dailyProgram, dayIdx, moveIdx) => {
//   let tempDaily = copyObject(dailyProgram);
//   let movements = tempDaily[dayIdx].Movements;
//   let loopMove = '';
//   let idx = moveIdx;
//   // eslint-disable-next-line
//   do {
//     let movement = movements[idx];
//     loopMove = movement ? movement.MovementName : movement;
//     if ([ARRIVAL, DEPARTURE, undefined].indexOf(loopMove) === -1) {
//       let prevMove = movements[idx - 1];
//       movement.DateTime = prevMove
//         ? convertDateFormat(
//             addDate(prevMove.DateTime, prevMove.Duration, 's'),
//             'YYYY-MM-DDTHH:mm:ss'
//           )
//         : movement.DateTime;
//     }
//     idx++;
//   } while (
//     [DAYSTART, DAYEND, ARRIVAL, DEPARTURE, undefined].indexOf(loopMove) === -1
//   );
//   return { moveIdx: idx, daily: tempDaily };
// };
// untuk memperbaiki durasi penerbangannya
export const fixFlightTime = (dailyProgram, dayIdx) => {
  let tempDaily = copyObject(dailyProgram);
  let movements = tempDaily[dayIdx].Movements;
  let idxFlightTimes = multiIndexOf(movements, 'MovementName', FLIGHTTIME);
  // eslint-disable-next-line
  idxFlightTimes.map(idxFlightTime => {
    let nextMove = movements[idxFlightTime + 1];
    let prevMove = movements[idxFlightTime - 1];
    movements[idxFlightTime].DateTime = prevMove
      ? prevMove.DateTime
      : movements[idxFlightTime].DateTime;
    if (
      nextMove !== undefined &&
      prevMove !== undefined &&
      nextMove.MovementName === ARRIVAL
    ) {
      movements[idxFlightTime].Duration = diffDate(
        prevMove.DateTime,
        nextMove.DateTime,
        's'
      );
      movements[idxFlightTime].DurationText = changeSecondToHourAndMinutes(
        movements[idxFlightTime].Duration
      );
    } else {
      movements[idxFlightTime].Duration = 0;
      movements[idxFlightTime].DurationText = '0 mins';
    }
  });
  return tempDaily;
};
// memperbaiki durasi freetimelock
const flightTimeNoDuration = (dailyProgram, dayIdx) => {
  let tempDaily = copyObject(dailyProgram);
  let movements = tempDaily[dayIdx].Movements;
  let idxFlightTimes = multiIndexOf(movements, 'MovementName', FLIGHTTIME);
  // eslint-disable-next-line
  idxFlightTimes.map(idxFlightTime => {
    let prevMove = movements[idxFlightTime - 1];
    movements[idxFlightTime].DateTime = prevMove
      ? prevMove.DateTime
      : movements[idxFlightTime].DateTime;
    movements[idxFlightTime].Duration = 0;
    movements[idxFlightTime].DurationText = '0 mins';
  });
  return tempDaily;
};
// const fixFreetimeLock = (dailyProgram, dayIdx) => {
//   let tempDaily = copyObject(dailyProgram);
//   let movements = tempDaily[dayIdx].Movements;
//   let idxFreetimeLocks = multiIndexOf(
//     movements,
//     'MovementName',
//     FREETIMELOCKED
//   );
//   // eslint-disable-next-line
//   idxFreetimeLocks.map(idxFreetimeLock => {
//     let nextMove = movements[idxFreetimeLock + 1];
//     let prevMove = movements[idxFreetimeLock - 1];
//     let duration =
//       prevMove !== undefined
//         ? diffDate(
//             convertDateFormat(
//               addDate(prevMove.DateTime, prevMove.Duration, 's'),
//               'YYYY-MM-DDTHH:mm:ss'
//             ),
//             nextMove.DateTime,
//             's'
//           )
//         : 0;
//     movements[idxFreetimeLock].DateTime = prevMove
//       ? convertDateFormat(
//           addDate(prevMove.DateTime, prevMove.Duration, 's'),
//           'YYYY-MM-DDTHH:mm:ss'
//         )
//       : nextMove.DateTime;
//     movements[idxFreetimeLock].Duration = duration >= 0 ? duration : 0;
//     movements[idxFreetimeLock].DurationText =
//       prevMove !== undefined
//         ? changeSecondToHourAndMinutes(movements[idxFreetimeLock].Duration)
//         : '0 mins';
//   });
//   return tempDaily;
// };
// reset day star dan day end
const resetDayStartDayEnd = (dailyProgram, dayIdx) => {
  let tempDaily = copyObject(dailyProgram);
  let movements = tempDaily[dayIdx].Movements;
  if (
    movements.length === 4 &&
    movements[0].MovementName === DAYSTART &&
    movements[1].MovementName === CHECKOUT &&
    movements[2].MovementName === CHECKIN &&
    movements[3].MovementName === DAYEND
  ) {
    let dayStart = changeTime(movements[0].DateTime, 12, 0);
    let dayEnd = changeTime(movements[0].DateTime, 14, 0);
    movements[0].DateTime = dayStart;
    movements[1].DateTime = dayStart;
    movements[2].DateTime = dayEnd;
    movements[3].DateTime = dayEnd;
  } else if (movements.length === 2) {
    let start = movements[0];
    let end = movements[1];
    if (start.MovementName === CHECKIN && end.MovementName === DAYEND) {
      let newDate = changeTime(start.DateTime, 14, 0);
      movements[0].DateTime = newDate;
      movements[1].DateTime = newDate;
    } else if (
      start.MovementName === DAYSTART &&
      end.MovementName === CHECKOUT
    ) {
      let newDate = changeTime(start.DateTime, 10, 0);
      movements[0].DateTime = newDate;
      movements[1].DateTime = newDate;
    } else if (start.MovementName === DAYSTART && end.MovementName === DAYEND) {
      movements[0].DateTime = changeTime(start.DateTime, 8, 0);
      movements[1].DateTime = changeTime(start.DateTime, 20, 0);
    }
  }
  return tempDaily;
};
// memperbaiki jam day end pada hari yang di hapus atau di tambah aktifitasnya
const fixTimeDayEnd = (dailyProgram, dayIdx) => {
  let tempDaily = copyObject(dailyProgram);
  let movements = tempDaily[dayIdx].Movements;
  if (movements.length > 2) {
    if (movements[movements.length - 1].MovementName === DAYEND) {
      // -1 karena ingin mengambil aktifitas terakhir yaitu day end.
      // -2 karena ingin mengambil aktifitas sebelum day end.
      movements[movements.length - 1].DateTime = convertDateFormat(
        addDate(
          movements[movements.length - 2].DateTime,
          movements[movements.length - 2].Duration,
          's'
        ),
        'YYYY-MM-DDTHH:mm:ss'
      );
    }
  }
  return tempDaily;
};
// untuk memperbaiki jam day start pada hari yang di hapus atau ditambah aktifitasnya
const fixTimeDayStart = (dailyProgram, dayIdx) => {
  let tempDaily = copyObject(dailyProgram);
  let movements = tempDaily[dayIdx].Movements;
  if (movements.length > 2) {
    if (movements[0].MovementName === DAYSTART) {
      if (movements[1].MovementName === DRIVING) {
        movements[1].DateTime = movements[2].DateTime;
      }
      movements[0].DateTime = movements[1].DateTime;
    }
  }
  return tempDaily;
};
// memperbaiki sequance number
const sequanceDailyProgram = (dailyProgram, dayIdx) => {
  let tempDaily = copyObject(dailyProgram);
  tempDaily[dayIdx].Movements.map((e, i) => (e.SeqNumber = i + 1));
  return tempDaily;
};
// ********************** END GENERATE DAILY PROGRAM AFTER ADD ACTIVITY ************************
// =============================================================================================
// ============================ END GENERATE DAILY PROGRAM =====================================
// =============================================================================================

// =============================================================================================
// ============================== DELETE DAILY PROGRAM =========================================
// =============================================================================================
const deleteActivity = (dayIdx, moveIdx, dailyProgram) => {
  let dailyTemp = copyObject(dailyProgram);
  let deleteCount = 0;
  let deleteIdx = moveIdx;
  let movement = dailyTemp[dayIdx].Movements[moveIdx];
  let prevMove = dailyTemp[dayIdx].Movements[moveIdx - 1];
  let nextMove = dailyTemp[dayIdx].Movements[moveIdx + 1];
  let movements = dailyTemp[dayIdx].Movements;
  let dontDeleteMove = [
    DEPARTURE,
    ARRIVAL,
    CHECKIN,
    CHECKOUT,
    QUEUETIME,
    FREETIME,
    RECREATION,
    EAT,
    DAYSTART,
    DAYEND,
  ].filter(e => e !== movement.MovementName);
  if (
    movement.MovementName === DEPARTURE &&
    dailyTemp[dayIdx].Movements[0].MovementName !== DAYSTART
  ) {
    deleteIdx = !deleteIdx ? 0 : deleteIdx - 1;
    deleteCount = 3;
  } else if (movement.MovementName === DEPARTURE) {
    deleteIdx = deleteIdx - 2;
    deleteCount = 4;
  } else if ([QUEUETIME, FREETIME].indexOf(movement.MovementName) !== -1) {
    if (
      (prevMove
        ? [
            DEPARTURE,
            ARRIVAL,
            CHECKIN,
            CHECKOUT,
            QUEUETIME,
            FREETIME,
            RECREATION,
            EAT,
          ].indexOf(prevMove.MovementName) === -1
        : true) &&
      (nextMove ? nextMove.MovementName === DRIVING : false)
    )
      deleteCount = 2;
    else deleteCount = 1;
  } else if ([ARRIVAL].indexOf(movement.MovementName) !== -1) {
    deleteCount = 2;
  } else if ([RECREATION, EAT].indexOf(movement.MovementName) !== -1) {
    deleteIdx = deleteIdx - 1;
    if (
      movements.length > 3 &&
      [DAYSTART, CHECKIN, CHECKOUT].indexOf(
        movements[moveIdx - 2].MovementName
      ) !== -1 &&
      [DAYEND, CHECKIN, CHECKOUT].indexOf(
        movements[moveIdx + 2] ? movements[moveIdx + 2].MovementName : DAYEND
      ) !== -1
    ) {
      deleteCount = 3;
    } else {
      deleteCount = 2;
    }
  }
  // cek movements yang akan di buang.
  let deleteMovements = dailyTemp[dayIdx].Movements.slice(
    deleteIdx,
    deleteIdx + deleteCount
  );
  let newIdxForDelete = deleteMovements.reduce(
    (acc, move, i) => {
      if (
        dontDeleteMove.indexOf(move.MovementName) !== -1 &&
        acc.idxWantDelete === null
      ) {
        acc.addDeleteIndex = i + 1;
      } else if (move.MovementName === movement.MovementName) {
        acc.idxWantDelete = i;
      } else if (
        dontDeleteMove.indexOf(move.MovementName) !== -1 &&
        acc.idxWantDelete !== null
      ) {
        acc.minusDeleteCount = deleteMovements.length - i;
      }
      if (deleteMovements.length === i + 1) {
        if (acc.addDeleteIndex !== null) {
          acc.minusDeleteCount = acc.minusDeleteCount
            ? acc.minusDeleteCount + acc.addDeleteIndex
            : acc.addDeleteIndex;
        }
      }
      return acc;
    },
    { idxWantDelete: null, addDeleteIndex: null, minusDeleteCount: null }
  );

  // deleteMovements.map((move, i) => {
  //   if (dontDeleteMove.indexOf(move.MovementName) !== -1) {
  //     valueAddedIndex = i + 1;
  //   }
  //   return move;
  // });
  deleteCount = newIdxForDelete.minusDeleteCount
    ? deleteCount - newIdxForDelete.minusDeleteCount
    : deleteCount;
  deleteIdx = newIdxForDelete.addDeleteIndex
    ? deleteIdx + newIdxForDelete.addDeleteIndex
    : deleteIdx;

  dailyTemp[dayIdx].Movements.splice(deleteIdx, deleteCount);
  dailyTemp = generateDailyProgramAfterDelete(dailyTemp, dayIdx, deleteIdx--);
  return dailyTemp;
};

// =============================================================================================
// ============================== DELETE DAILY PROGRAM =========================================
// =============================================================================================

// =============================================================================================
// ============================== EDIT DAILY PROGRAM =========================================
// =============================================================================================
const editDailyProgram = (
  dailyProgram,
  day,
  moveIdx,
  typeMovement,
  itemMovement,
  activityData,
  objDestination
) => {
  let tempDaily = copyObject(dailyProgram);
  let newItemMovement = addItem(
    itemMovement,
    typeMovement,
    moveIdx,
    null,
    tempDaily,
    day,
    objDestination
  );
  tempDaily[day].Movements.splice(
    moveIdx,
    1,
    addMovement(typeMovement, newItemMovement, activityData, objDestination)
  );
  return tempDaily;
};
// =============================================================================================
// ============================ END EDIT DAILY PROGRAM =========================================
// =============================================================================================

const changeDepartureReturn = (
  dailyProgram,
  headlineProgram,
  objAirport,
  movementName
) => {
  let tempDaily = copyObject(dailyProgram);
  let tempHeadline = copyObject(headlineProgram);
  if (movementName === ARRIVAL) {
    // let searchDaily = tempDaily.length > 2 ? tempDaily.slice(0, 2) : tempDaily;
    let firstArrival = findArrivalDeparture(
      // searchDaily,
      tempDaily,
      ARRIVAL,
      objAirport.Id
    );
    tempHeadline = firstArrival
      ? fillDepartureAndReturn(
          'Departures',
          firstArrival,
          tempHeadline,
          objAirport
        )
      : tempHeadline;
  } else if (movementName === DEPARTURE) {
    // let searchDaily =
    //   tempDaily.length > 2
    //     ? tempDaily.slice(Math.max(tempDaily.length - 2, 1)).reverse()
    //     : tempDaily.reverse();
    // let searchDaily = tempDaily.length > 2 ? tempDaily.slice(0, 2) : tempDaily;
    let lastDeparture = findArrivalDeparture(
      // searchDaily,
      tempDaily,
      DEPARTURE,
      objAirport.Id
    );
    tempHeadline = lastDeparture
      ? fillDepartureAndReturn(
          'Returns',
          lastDeparture,
          tempHeadline,
          objAirport
        )
      : tempHeadline;
  }
  return tempHeadline;
};

const fillDepartureAndReturn = (objName, moveAirport, headline, objAirport) => {
  let tempHeadline = copyObject(headline);
  let selectedObj = tempHeadline[objName][0];
  selectedObj = selectedObj ? selectedObj : copyObject(Airport);
  selectedObj.Address = objAirport.Address;
  selectedObj.City = objAirport.City;
  selectedObj.Date = moveAirport.DateTime;
  selectedObj.Note = moveAirport.Note;
  selectedObj.Place = objAirport.Name;
  selectedObj.PlaceId = objAirport.Id;
  selectedObj.PlaceType = objAirport.PlaceType;
  selectedObj.Region = objAirport.Region ? objAirport.Region.Id : '';
  tempHeadline[objName][0] = selectedObj;
  return tempHeadline;
};
const findArrivalDeparture = (dailyProgram, keyMovement, idAirport) => {
  let tempDaily = copyObject(dailyProgram);
  let searchDaily = [];
  if (keyMovement === ARRIVAL) {
    searchDaily = tempDaily.length > 2 ? tempDaily.slice(0, 2) : tempDaily;
  } else {
    searchDaily =
      tempDaily.length > 2
        ? tempDaily.slice(Math.max(tempDaily.length - 2, 1)).reverse()
        : tempDaily.reverse();
  }
  let a = searchDaily.reduce((acc, daily) => {
    if (acc === null || acc === undefined) {
      acc = daily.Movements.find(e =>
        idAirport
          ? e.MovementName === keyMovement &&
            (e.Item && e.Item.PlaceId === idAirport)
          : e.MovementName === keyMovement
      );
    }
    return acc;
  }, null);
  return a;
};

// untuk cek apakah sudah cekin pertama
export const isFirstDayNoAccom = (
  day,
  index,
  activity,
  position,
  dayFirstCheckIn
) => {
  if (day === dayFirstCheckIn) {
    let idxDeparture = activity.Movements.findIndex(
      e => e.MovementName === DEPARTURE
    );
    let idxArrival = activity.Movements.findIndex(
      e => e.MovementName === ARRIVAL
    );
    let dataResult = false;
    if (idxDeparture !== -1) {
      dataResult =
        idxDeparture > index
          ? true
          : idxDeparture === index && position === 'top'
          ? true
          : false;
    } else if (idxArrival !== -1) {
      dataResult =
        idxArrival > index
          ? true
          : idxArrival === index && position === 'top'
          ? true
          : false;
    } else {
      let idxCheckIn = activity.Movements.findIndex(
        e => e.MovementName === CHECKIN
      );
      dataResult =
        idxCheckIn !== -1
          ? idxCheckIn > index
            ? true
            : idxCheckIn === index && position === 'top'
            ? true
            : false
          : false;
    }
    return dataResult;
  } else if (dayFirstCheckIn > day) {
    return true;
  } else {
    return false;
  }
};
// untuk mengambil hari pertama menginap / memiliki akomodasi
export const checkFirstaccom = dailyProgram => {
  let dayFirstAccom = 0;
  let isCheck = true;
  // eslint-disable-next-line
  dailyProgram.map((daily, i) => {
    let idxCheckIn = daily.Movements.findIndex(e => e.MovementName === CHECKIN);
    if (idxCheckIn !== -1 && isCheck) {
      isCheck = false;
      return (dayFirstAccom = i);
    }
  });
  return dayFirstAccom;
};

export const selectActivity = (
  dailyProgram,
  day,
  moveIdx,
  position,
  LIST_ADD_3_ACTIVITY,
  LIST_ADD_ACTIVITY
) => {
  let dailyTemp = copyObject(dailyProgram);
  if (day !== 0) {
    let selectedDaily = dailyTemp.slice(0, day);
    // eslint-disable-next-line
    selectedDaily.map(e => (moveIdx = moveIdx + e.Movements.length));
  }
  if (checkArrivalAndCheckIn(dailyTemp, moveIdx, position)) {
    return LIST_ADD_ACTIVITY;
  } else {
    return LIST_ADD_3_ACTIVITY;
  }
};

const checkArrivalAndCheckIn = (dailyProgram, moveIdx, position) => {
  let arrayMovement = createArrayMovements(dailyProgram);
  let selectedMovements = arrayMovement.slice(0, moveIdx + 1);
  let isThereArrival = false;
  let isThereCheckin = false;
  // eslint-disable-next-line
  selectedMovements.map(move => {
    isThereArrival = move.MovementName === ARRIVAL ? true : isThereArrival;
    isThereCheckin = move.MovementName === CHECKIN ? true : isThereCheckin;
  });
  return position !== TOP ? isThereArrival || isThereCheckin : false;
};

export {
  addItemQueueFreetimeWithNextMove,
  addItemAirport,
  generateDailyProgramAfterCRUD,
  deleteActivity,
  editDailyProgram,
  changeDepartureReturn,
  findArrivalDeparture,
  generateDailyProgram,
};
