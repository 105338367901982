const customerProfileField = [
  'Name',
  'Address',
  'Telephone',
  'CountryId',
  'EmployeeRangeId',
  'RevenueRangeId',
  'BranchRangeId',
  'CurrencyId',
  'Email',
  'FaxNbr',
  'PaymentSchemeId',
];
const resetPasswordField = ['NewPassword', 'ConfirmPassword'];

export { customerProfileField, resetPasswordField };
