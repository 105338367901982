// import { ARRIVAL } from '../../../tourTransaction/activityType';
import {
  DAYSTART,
  DAYEND,
  CHECKIN,
  CHECKOUT,
  RECREATION,
  DEPARTURE,
  ARRIVAL,
  TRANSIT,
  EAT,
  FREETIME,
  QUEUETIME,
  DRIVING,
} from '../../pages/shared/components/tourTransaction/dailyProgram/constant/activityType';

const DROPDOWN_LIST_ACTIVITY = [
  {
    value: 'RECREATION',
    title: 'Excursion',
  },
  {
    value: 'DEPARTURE',
    title: 'Departure',
  },
  {
    value: 'ARRIVAL',
    title: 'Arrival',
  },
  {
    value: 'EAT',
    title: 'Meal',
  },
  {
    value: 'FREETIME',
    title: 'Free Time',
  },
  {
    value: 'QUEUETIME',
    title: 'Queue Time',
  },
];

const DROPDOWN_LIST_ACTIVITY_BEFORE_CHECKIN_DAY1 = [
  {
    value: 'ARRIVAL',
    title: 'Arrival',
  },
  {
    value: 'DEPARTURE',
    title: 'Departure',
  },
  {
    value: 'QUEUETIME',
    title: 'Queue Time',
  },
];

const LIST_ACTIVITY_FIXED_PACKAGE = [
  DAYSTART,
  DAYEND,
  CHECKIN,
  CHECKOUT,
  RECREATION,
  DEPARTURE,
  ARRIVAL,
  TRANSIT,
  EAT,
  FREETIME,
  QUEUETIME,
  DRIVING,
];

const TOP = 'top';
const BOTTOM = 'bottom';
export {
  DROPDOWN_LIST_ACTIVITY,
  LIST_ACTIVITY_FIXED_PACKAGE,
  DROPDOWN_LIST_ACTIVITY_BEFORE_CHECKIN_DAY1,
  TOP,
  BOTTOM,
};
