export const RESET_COUNTRY_PAGE = 'RESET_COUNTRY_PAGE';

export const DELETE_COUNTRY = 'DELETE_COUNTRY';
export const DELETE_COUNTRY_FULFILLED = 'DELETE_COUNTRY_FULFILLED';
export const DELETE_COUNTRY_PENDING = 'DELETE_COUNTRY_PENDING';
export const DELETE_COUNTRY_REJECTED = 'DELETE_COUNTRY_REJECTED';

export const POST_COUNTRY = 'POST_COUNTRY';
export const POST_COUNTRY_FULFILLED = 'POST_COUNTRY_FULFILLED';
export const POST_COUNTRY_PENDING = 'POST_COUNTRY_PENDING';
export const POST_COUNTRY_REJECTED = 'POST_COUNTRY_REJECTED';

export const PUT_COUNTRY = 'PUT_COUNTRY';
export const PUT_COUNTRY_FULFILLED = 'PUT_COUNTRY_FULFILLED';
export const PUT_COUNTRY_PENDING = 'PUT_COUNTRY_PENDING';
export const PUT_COUNTRY_REJECTED = 'PUT_COUNTRY_REJECTED';

//Get Country
export const GET_COUNTRY_LIST = 'GET_COUNTRY_LIST';
export const GET_COUNTRY_LIST_FULFILLED = 'GET_COUNTRY_LIST_FULFILLED';
export const GET_COUNTRY_LIST_PENDING = 'GET_COUNTRY_LIST_PENDING';
export const GET_COUNTRY_LIST_REJECTED = 'GET_COUNTRY_LIST_REJECTED';

//Get By ID => Country
export const GET_COUNTRY_BY_ID = 'GET_COUNTRY_BY_ID';
export const GET_COUNTRY_BY_ID_FULFILLED = 'GET_COUNTRY_BY_ID_FULFILLED';
export const GET_COUNTRY_BY_ID_PENDING = 'GET_COUNTRY_BY_ID_PENDING';
export const GET_COUNTRY_BY_ID_REJECTED = 'GET_COUNTRY_BY_ID_REJECTED';
