import apiClient from '../apiClient';
import {
  EDIT_SPECIAL_ADJUSMENT_URL,
  POST_ADDITIONAL_ITEMS_URL,
} from '../apiUrl';

const editSpecialAdjsumentApi = data => {
  return apiClient.put(`${EDIT_SPECIAL_ADJUSMENT_URL}${data.Id}`, data);
};

const postAdditionalItemsApi = data => {
  return apiClient.post(POST_ADDITIONAL_ITEMS_URL, data);
};
export { editSpecialAdjsumentApi, postAdditionalItemsApi };
