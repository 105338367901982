import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Button } from 'semantic-ui-react';
import TableAddittionalInfo from '../../../../../../components/table/package/tableAdditionalInfoRefactory';
import ModalAddittionalInfo from '../../../../../../components/modal/package/modalAddittionalInfo';
import ModalConfirm from '../../../../../../components/modal/modalConfirmationTwoButton';
const DELETE = 'delete';
class additionalInfo extends Component {
  state = {
    openModalAdditionalInfo: false,
    dataFormAdditionalInfo: {
      Id: 0,
      SubTitle: '',
      Content: '',
      ForVoucher: this.props.isEVoucher,
    },
    selectedDeleteAdditionalInfo: '',
    modalConfirm: {
      isOpen: false,
      header: '',
      message: '',
      nameConfirm: '',
    },
    isModalEdit: false,
    valueIndex: null,
  };
  // ======================= handle modal additional infor ========================
  handleOpenModal = (e, { value }) => {
    const { additionalInfoList } = this.props;
    let valueIndex = value
      ? additionalInfoList.findIndex(item => item === value)
      : null;
    let dataFormAdditionalInfo = {
      ...this.state.dataFormAdditionalInfo,
      Id: value ? value.Id : 0,
      SubTitle: value ? value.SubTitle : '',
      Content: value ? value.Content : '',
      ForVoucher:
        value && value.SubTitle.indexOf('FOR_VOUCHER') !== -1 ? true : false,
    };
    this.setState({
      ...this.state,
      openModalAdditionalInfo: true,
      dataFormAdditionalInfo,
      isModalEdit: value ? true : false,
      valueIndex,
    });
  };
  handleCloseModal = () => {
    let dataFormAdditionalInfo = {
      ...this.state.dataFormAdditionalInfo,
      Id: 0,
      SubTitle: '',
      Content: '',
    };
    this.setState({
      ...this.state,
      openModalAdditionalInfo: false,
      dataFormAdditionalInfo,
      isModalEdit: false,
      valueIndex: null,
    });
  };
  onChangeAdditionalInfo = value => {
    let dataFormAdditionalInfo = {
      ...this.state.dataFormAdditionalInfo,
      Content: value,
    };
    this.setState({
      ...this.state,
      dataFormAdditionalInfo,
    });
  };
  onchangeTittleInfo = (e, { name, value }) => {
    let dataFormAdditionalInfo = {
      ...this.state.dataFormAdditionalInfo,
      [name]: value,
    };
    this.setState({
      ...this.state,
      dataFormAdditionalInfo,
    });
  };
  saveAddittionalInfo = async () => {
    let additionalInfoList = [...this.props.additionalInfoList];
    const { isModalEdit, dataFormAdditionalInfo, valueIndex } = this.state;
    dataFormAdditionalInfo.ForVoucher = this.props.isEVoucher;
    dataFormAdditionalInfo.SubTitle = dataFormAdditionalInfo.ForVoucher
      ? `FOR_VOUCHER${dataFormAdditionalInfo.SubTitle}`
      : dataFormAdditionalInfo.SubTitle.replace('FOR_VOUCHER', '');
    isModalEdit
      ? (additionalInfoList[valueIndex] = dataFormAdditionalInfo)
      : additionalInfoList.push(dataFormAdditionalInfo);
    await this.props.handleAdditionalInfo('additionalInfo', additionalInfoList);
    this.handleCloseModal();
  };
  handleDeleteAdditionalItem = async (id, value) => {
    await this.handleOpenAlert(
      'Confirmation',
      'Are you sure want to delete this additional info?',
      DELETE
    );
    this.setState({
      ...this.state,
      selectedDeleteAdditionalInfo: id,
      deletedId: value,
    });
  };
  doDeleteAdditionalItem = async (id, deletedId) => {
    let additionalInfoList = [...this.props.additionalInfoList];
    // additionalInfoList.splice(id, 1);
    // this.props.handleAdditionalInfo('additionalInfo', additionalInfoList);
    let dataNotEVoucher = additionalInfoList.filter(data => !data.ForVoucher);
    let dataEVoucher = additionalInfoList.filter(data => data.ForVoucher);
    if (this.props.isEVoucher) {
      dataEVoucher.splice(id, 1);
    } else {
      dataNotEVoucher.splice(id, 1);
    }
    let allData = dataNotEVoucher.concat(dataEVoucher);
    await this.props.handleAdditionalInfo('additionalInfo', allData);
    this.props.deletedAdditionalInformation(deletedId);
  };
  // ======================= end handle modal additional infor ========================
  // ========================= handle modal confirm =================================
  handleOpenAlert = (header, message, nameConfirm) => {
    let modalConfirm = {
      ...this.state.modalConfirm,
      isOpen: true,
      header,
      message,
      nameConfirm,
    };
    this.setState({ ...this.state, modalConfirm });
  };
  handleCloseModalConfirm = () => {
    let modalConfirm = {
      ...this.state.modalConfirm,
      isOpen: false,
    };
    this.setState({ ...this.state, modalConfirm });
  };
  handleConfirmModalConfirm = async () => {
    // if (e.target.name === DELETE) {
    await this.doDeleteAdditionalItem(
      this.state.selectedDeleteAdditionalInfo,
      this.state.deletedId
    );
    // }
    this.handleCloseModalConfirm();
  };
  // handleInfoForVoucher = () => {
  //   const { dataFormAdditionalInfo } = this.state;
  //   dataFormAdditionalInfo.ForVoucher = !dataFormAdditionalInfo.ForVoucher;
  //   dataFormAdditionalInfo.SubTitle = dataFormAdditionalInfo.ForVoucher
  //     ? `FOR_VOUCHER${dataFormAdditionalInfo.SubTitle}`
  //     : dataFormAdditionalInfo.SubTitle.replace('FOR_VOUCHER', '');
  //   this.setState({
  //     ...this.state,
  //     dataFormAdditionalInfo,
  //   });
  // };
  // ========================= end handle modal confirm ============================
  render() {
    const {
      openModalAdditionalInfo,
      dataFormAdditionalInfo,
      modalConfirm,
    } = this.state;
    const { additionalInfoList, loading, disabled } = this.props;
    let listInfo = additionalInfoList.filter(data => !data.ForVoucher);
    let listVoucher = additionalInfoList.filter(data => data.ForVoucher);
    return (
      <div>
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column width={8}>
              <Header as="h4" content={this.props.tableName} />
            </Grid.Column>
            {/* <Grid.Column /> */}
            <Grid.Column floated="right" width={7}>
              <Button
                positive
                content="Add New"
                onClick={this.handleOpenModal}
                floated="right"
                value={null}
                disabled={disabled}
              />
              <ModalAddittionalInfo
                tableName={this.props.tableName}
                openModal={openModalAdditionalInfo}
                handleClose={this.handleCloseModal}
                additionalInfo={dataFormAdditionalInfo}
                onChangeAdditionalInfo={this.onChangeAdditionalInfo}
                saveAddittionalInfo={this.saveAddittionalInfo}
                onchangeTittleInfo={this.onchangeTittleInfo}
                //handleInfoForVoucher={this.handleInfoForVoucher}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row stretched>
            <Grid.Column width={15}>
              <TableAddittionalInfo
                additionalInfoList={
                  this.props.isEVoucher ? listVoucher : listInfo
                }
                openModalAdditonalItem={this.handleOpenModal}
                handleDeleteAdditionalItem={this.handleDeleteAdditionalItem}
                loading={loading}
                tableName={this.props.tableName}
                disabled={disabled}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ModalConfirm
          openModal={modalConfirm.isOpen}
          size="mini"
          close={this.handleCloseModalConfirm}
          confirm={this.handleConfirmModalConfirm}
          message={modalConfirm.message}
          nameConfirm={modalConfirm.nameConfirm}
          header={modalConfirm.header}
        />
      </div>
    );
  }
}

additionalInfo.propTypes = {
  additionalInfoList: PropTypes.array,
  handleAdditionalInfo: PropTypes.func,
  loading: PropTypes.bool,
  deletedAdditionalInformation: PropTypes.func,
  tableName: PropTypes.string,
  isEVoucher: PropTypes.bool,
  disabled: PropTypes.bool,
};
export default additionalInfo;
