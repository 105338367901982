import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import InputWithLabel from '../../../../../../components/input/inputWithLabel';
import styles from '../../Styles';

const FormRegisterUser = props => {
  const {
    handleChange,
    userFirstName,
    userLastName,
    userEmail,
    userPhoneNumber,
    validate,
  } = props;

  return (
    <div>
      <h4 style={styles.h4}>
        <strong>User Data</strong>
      </h4>
      <div>
        <hr />
      </div>
      <Form>
        <InputWithLabel
          label="First Name"
          placeholder="First Name"
          inline
          required
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={handleChange}
          value={userFirstName.data}
          name="userFirstName"
          validate={validate}
        />
        <InputWithLabel
          label="Last Name"
          placeholder="Last Name"
          inline
          required
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={handleChange}
          value={userLastName.data}
          name="userLastName"
          validate={validate}
        />
        <InputWithLabel
          label="Email"
          placeholder="Email"
          inline
          required
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={handleChange}
          value={userEmail.data}
          name="userEmail"
          validate={validate}
        />
        <InputWithLabel
          label="Phone Number"
          placeholder="Phone Number"
          inline
          required
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={handleChange}
          value={userPhoneNumber.data ? userPhoneNumber.data : ''}
          name="userPhoneNumber"
          validate={validate}
        />
      </Form>
    </div>
  );
};

FormRegisterUser.propTypes = {
  handleChange: PropTypes.func,
  userName: PropTypes.string,
  userFirstName: PropTypes.string,
  userLastName: PropTypes.string,
  userPhoneNumber: PropTypes.string,
  userEmail: PropTypes.string,
  validate: PropTypes.object,
};
export default FormRegisterUser;
