export const generateForComponentBookingDetailInformation = bookingSummary => {
  let isFixedPackage =
    bookingSummary && bookingSummary.BookingDetailSum.PackageType === 'Fixed';
  return {
    Id: bookingSummary ? bookingSummary.BookingDetailSum.Id : '',
    TourName: bookingSummary ? bookingSummary.BookingDetailSum.Title : '',
    Customer: bookingSummary
      ? bookingSummary.BookingDetailSum.CreatedBy.CompanyName
      : '',
    Status: bookingSummary ? bookingSummary.BookingDetailSum.Status : '',
    CurrencyId: bookingSummary
      ? bookingSummary.BookingDetailSum.CurrencyId
      : '',
    TourPrice: bookingSummary
      ? isFixedPackage
        ? bookingSummary.BookingDetailSum.FixedPackage.BookingCommission
            .TotalPriceForCustomer
        : bookingSummary.BookingDetailSum.TourTotalPrice
      : 0,
    TourPaid: bookingSummary ? bookingSummary.BookingDetailSum.TotalPayed : '',
    StartTour: bookingSummary ? bookingSummary.BookingDetailSum.StartDate : '',
    EndTour: bookingSummary ? bookingSummary.BookingDetailSum.EndDate : '',
  };
};
