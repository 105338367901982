import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Segment,
  Grid,
  Button,
  Image,
  Header,
  Table,
  Popup,
} from 'semantic-ui-react';
import Loader from '../../components/loader/loader';
import HeaderTitle from '../../components/common/headerTitle';
import Styles from './Styles';
import { Redirect } from 'react-router-dom';
import ModalAddResource from './components/ModalAddResource';
import {
  getDownloadResourceBookingDetail,
  getResourceBookingWithSummary,
  getDownloadResouceBookingDetailDocument,
  deleteResourceBookingCollection,
  getResourceList,
  saveStartDateEndDate,
  getGenerateResouceBookingAction,
  // saveDataCancelResource,
  saveAllotment,
  resetDownloadResourceBookingDetail,
  resetDownloadResourceDetail,
  postCancelResource,
} from './../shared/actions/resourceBookingAction';
import { handleDownloadFile } from '../../scripts/download';
import ModalAlert from '../shared/components/modal/ModalAlert';
import { convertDateFormat } from '../../scripts/moment';
import {
  generateResourceBookingDetail,
  MENU_RESOURCE_BOOKING_DETAIL,
  generateTourNoteList,
} from './scripts/resourceBookingDetail';
import { ucwords } from '../../scripts/convertWord';
import options from '../../scripts/convertOption';
import { getCompaniesByType } from '../../actions/companyAction';
import Pagination from '../../components/common/pagination';
import PaginationScript from '../../components/genericFunction/pagination';
import { getNumberFromTo } from '../../components/genericFunction/getPaginationNumberFrom';
import { getMasterReferenceAction } from '../shared/actions/uploadMasterDataReferenceAction';
import ModalConfirm from '../../components/modal/modalConfirmationTwoButton';
import InputSearch from '../../components/input/inputSearch';
import {
  setStringPaymentStatus,
  setStringResourceStatus,
} from './scripts/resourceBookingDetail';
// import ModalCancelRBL from './components/ModalCancelRBL';
import ModalCancellationReason from '../shared/components/modal/ModalCancellationReason';
import TabMenu from '../../components/tab/TabMenuOnlyHeader';
// import { searchFilterResourceItemList } from '../../scripts/filterData';
const GENERATED = 'GENERATED';
const ResourceBookingDetail = props => {
  //==========initial state=========
  const [changePage, setChangePage] = useState({
    isChangePage: false,
    link: '',
  });
  const [modalAddResource, setModalAddResource] = useState({
    isOpen: false,
    mode: 'ADD',
    data: null,
  });
  const [resourceList, setResourceList] = useState([]);
  const [id, setId] = useState(0);
  const [modalAlert, setModalAlert] = useState({
    isOpen: false,
    header: '',
    message: '',
  });
  const [tourNoteList, setTourNoteList] = useState([]);
  const [resourceBookingDetail, setResourceBookingDetail] = useState({
    CreatedDate: '',
    CreatedBy: '',
    LastModifiedDate: '',
    LastModifiedBy: '',
    IsAllowedUpdate: false,
    Title: '',
    FirstDestination: '',
    TourType: '',
    StartDate: '',
    EndDate: '',
    Description: '',
    TourNote: '',
    ImageUrl: '',
    CityImage: '',
    PackageType: '',
    ResourceBooking: '',
    Id: '',
    Allotment: null,
  });
  const [activePage, setActivePage] = useState(1);
  const [modalConfirm, setModalConfirm] = useState({
    isOpen: false,
    type: '',
    message: '',
    header: '',
    value: '',
  });
  const [searchInput, setSearchInput] = useState('');
  const [modalCancel, setModalCancel] = useState({
    isOpen: false,
    value: '',
    header: '',
  });
  const [tabActive, setTabActive] = useState('RESOURCE_ITEM_LIST');
  //========== End initial state=========
  // ========= Initial action ===========
  const dispatch = useDispatch();
  const getDownloadResourceBookingDetailAction = param =>
    dispatch(getDownloadResourceBookingDetail(param));
  const getResourceBookingWithSummaryAction = param =>
    dispatch(getResourceBookingWithSummary(param));
  const getDownloadResouceDetailDocumentAction = param =>
    dispatch(getDownloadResouceBookingDetailDocument(param));
  const deleteResourceBookingCollectionAction = param =>
    dispatch(deleteResourceBookingCollection(param));
  const getResourceListAction = param => dispatch(getResourceList(param));
  const getCompaniesByTypeAction = param => dispatch(getCompaniesByType(param));
  const getMasterReference = param => dispatch(getMasterReferenceAction(param));
  const saveStartDateEndDateAction = param =>
    dispatch(saveStartDateEndDate(param));
  const getGenerateResouceBooking = param =>
    dispatch(getGenerateResouceBookingAction(param));
  // const saveDataCancelResourceAction = param =>
  //   dispatch(saveDataCancelResource(param));
  const saveAllotmentAction = param => dispatch(saveAllotment(param));
  const resetDownloadResourceBookingDetailAction = () =>
    dispatch(resetDownloadResourceBookingDetail());
  const resetDownloadResourceDetailAction = () =>
    dispatch(resetDownloadResourceDetail());
  const postCancelResourceAction = param => dispatch(postCancelResource(param));
  // ========= End Initial action ===========
  // ========= Initial reducer ==============
  const loadingDownloadResourceDetail = useSelector(
    state => state.ResourceBooking.loadingDownloadResourceDetail
  );
  const downloadResourceBookingDetail = useSelector(
    state => state.ResourceBooking.downloadResourceBookingDetail
  );
  // const errorsAction = useSelector(state => state.ResourceBooking.errors);
  const loadingResourceBookingDetailWithSummary = useSelector(
    state => state.ResourceBooking.loadingResourceBookingDetailWithSummary
  );
  const resourceBookingDetailWithSummary = useSelector(
    state => state.ResourceBooking.resourceBookingDetailWithSummary
  );
  const resourceBookingDetailDocument = useSelector(
    state => state.ResourceBooking.resourceBookingDetailDocument
  );
  const loadingResourceBookingDetailDocument = useSelector(
    state => state.ResourceBooking.loadingResourceBookingDetailDocument
  );
  const loadingDeleteResourceBookingCollection = useSelector(
    state => state.ResourceBooking.loadingDeleteResourceBookingCollection
  );
  const loadingGetResourceList = useSelector(
    state => state.ResourceBooking.loadingGetResourceList
  );
  const resourceListReducer = useSelector(
    state => state.ResourceBooking.resourceList
  );
  const loadingGetCompanies = useSelector(
    state => state.CompanyStore.loadingGetCompanies
  );
  const companies = useSelector(state =>
    state.CompanyStore.companies.map(e => options(e.Code, e.Code, e.Name))
  );
  const loadingGetCurrencies = useSelector(
    state => state.MasterDataReferenceStore.loading
  );
  const currencies = useSelector(state => {
    let data = state.MasterDataReferenceStore.getMasterReferenceList;
    return data ? data.map(e => options(e.Id, e.Id, e.Description)) : [];
  });
  const generateResourceBooking = useSelector(
    state => state.ResourceBooking.generateResourceBooking
  );
  const loadingPostCancelResource = useSelector(
    state => state.ResourceBooking.loadingPostCancelResource
  );
  const loadingGenerate = useSelector(
    state => state.ResourceBooking.loadingGenerate
  );
  // ====== end initial reducer
  useEffect(() => {
    setId(props.match.params.id);
    getResourceBookingWithSummaryAction(props.match.params.id);
    getCompaniesByTypeAction('Partner');
    getMasterReference('Currencies');
    // this.props
    //     .getGenerateResouceBookingAction({
    //       tourTransactionCode: this.state.selectedIdGenerate,
    //       bookingTemplateIdRef: 0,
    //       resourceBookingType: '0',
    //     })
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (resourceBookingDetailWithSummary !== null) {
      let resourceBookingDetail = generateResourceBookingDetail(
        resourceBookingDetailWithSummary
      );
      resourceBookingDetail.PackageType !== 'Fixed' &&
        getGenerateResouceBooking({
          tourTransactionCode: resourceBookingDetail.Id,
          bookingTemplateIdRef: 0,
          resourceBookingType: 'BYTRANSACTION',
        });
      setResourceList(resourceBookingDetailWithSummary.ResouceList);
      setTourNoteList(
        generateTourNoteList(
          resourceBookingDetailWithSummary.TourTransactionSummaries
        )
      );
      setResourceBookingDetail(resourceBookingDetail);
    }
    // eslint-disable-next-line
  }, [resourceBookingDetailWithSummary]);
  useEffect(() => {
    downloadResourceBookingDetail !== null &&
      handleDownloadFile(
        downloadResourceBookingDetail,
        'Resource Booking Detail'
      );
    resetDownloadResourceBookingDetailAction();
    // eslint-disable-next-line
  }, [downloadResourceBookingDetail]);
  useEffect(() => {
    if (resourceBookingDetailDocument !== null) {
      handleDownloadFile(resourceBookingDetailDocument, 'Resource Document');
      resetDownloadResourceDetailAction();
    }
    // eslint-disable-next-line
  }, [resourceBookingDetailDocument]);
  useEffect(() => {
    resourceListReducer !== [] && setResourceList(resourceListReducer);
  }, [resourceListReducer]);
  useEffect(() => {
    companies !== [] &&
      companies.unshift({ key: 0, value: 0, text: 'Touress' });
  }, [companies]);
  useEffect(() => {
    getResourceListAction(id);
    // eslint-disable-next-line
  }, [generateResourceBooking]);
  const handleBack = () => {
    setChangePage({
      isChangePage: true,
      link: '/resource-booking',
    });
  };

  const handleUpdate = () => {
    getGenerateResouceBooking({
      tourTransactionCode:
        resourceBookingDetail.PackageType === 'Fixed'
          ? 0
          : resourceBookingDetail.Id,
      bookingTemplateIdRef:
        resourceBookingDetail.PackageType === 'Fixed'
          ? resourceBookingDetail.Id
          : 0,
      resourceBookingType:
        resourceBookingDetail.PackageType === 'Fixed'
          ? 'BYPACKAGE'
          : 'BYTRANSACTION',
    })
      .then(() => {
        openModalAlert('Success', 'Updated resource successfully', '');
        setActivePage(1);
      })
      .catch(err => {
        openModalAlert(
          'Failed',
          err.response.data.Message || 'Failed update resource',
          ''
        );
      });
  };
  const handleDownloadResourceBookingDetail = (e, { value }) => {
    getDownloadResourceBookingDetailAction(value).catch(err => {
      openModalAlert('Failed', err.response.data.Message, '');
    });
  };

  const handleDelete = (e, { value, name }) => {
    // /ResourceBookingDetail/Remove?resourceBookingColletionId={resourceBookingColletionId}
    name === 'DELETE'
      ? openModalConfirm(
          name,
          value,
          'Confirmation',
          'Are you sure want to delete'
        )
      : openCloseModalCancel(true, value, '');
  };
  const handleDownloadResource = (e, { value }) => {
    getDownloadResouceDetailDocumentAction(value).catch(err => {
      openModalAlert('Failed', err.response.data.Message, '');
    });
  };
  const hanldeSeeDetailResource = (e, { value, id }) => {
    saveDate();
    setChangePage({
      isChangePage: true,
      link: `/Resource-Detail/${value === 'CANCELED' ? 'Canceled' : 'Detail'}/${
        props.match.params.id
      }/${id}`,
    });
  };
  const handleModalAddResource = (mode, data) => {
    setModalAddResource({
      isOpen: !modalAddResource.isOpen,
      mode: mode ? mode : 'ADD',
      data,
    });
  };
  const handleAddResource = () => {
    getResourceListAction(id).catch(err => {
      openModalAlert('Failed', err.response.data.Message, '');
    });
    setActivePage(1);
    handleModalAddResource('ADD', null);
  };
  // const handleEdit = (e, { value }) => {
  //   handleModalAddResource('EDIT', value);
  // };
  //  handle modal
  const openModalAlert = (header, message, link) => {
    setModalAlert({
      isOpen: true,
      header: header,
      message: message,
      linkChangePage: link,
    });
  };
  const closeModalAlert = () => {
    if (modalAlert.linkChangePage.search('/Resource-Detail/ReIssue') !== -1) {
      saveDate();
      setChangePage({ isChangePage: true, link: modalAlert.linkChangePage });
    }
    setModalAlert({
      isOpen: false,
      header: '',
      message: '',
    });
  };

  const openModalConfirm = (type, value, header, message) => {
    setModalConfirm({
      isOpen: true,
      type,
      message,
      header,
      value,
    });
  };
  const closeModalConfirm = () => {
    setModalConfirm({
      isOpen: false,
      type: '',
      message: '',
      header: '',
      value: '',
    });
  };
  const handleModalConfirm = () => {
    closeModalConfirm();
    if (modalConfirm.type === 'DELETE') {
      deleteResourceBookingCollectionAction(modalConfirm.value)
        .then(() => {
          getResourceListAction(id);
          openModalAlert('Success', 'Delete resources successfully', '');
        })
        .catch(err => {
          openModalAlert('Failed', err.response.data.Message, '');
        });
    }
  };

  const pageChange = (e, { activePage }) => {
    setActivePage(activePage);
  };
  const add = () => {
    saveDate();
    saveAllotmentAction(resourceBookingDetail.Allotment);
    setChangePage({
      isChangePage: true,
      link: `/Resource-Detail/Add/${id}`,
    });
  };
  const saveDate = () => {
    saveStartDateEndDateAction({
      startDate: convertDateFormat(StartDate, 'DD MMM YYYY'),
      endDate: convertDateFormat(EndDate, 'DD MMM YYYY'),
      id,
    });
  };

  const handleChangeSearch = e => {
    setSearchInput(e.target.value);
    setActivePage(1);
  };
  const filterData = (resourceList, searchInput) => {
    return resourceList.filter(e => {
      return (
        (e.Id && e.Id.toString().indexOf(searchInput) !== -1) ||
        (e.ResourceName &&
          e.ResourceName.toLowerCase().indexOf(searchInput.toLowerCase()) !==
            -1) ||
        (e.ResourceType &&
          e.ResourceType.toLowerCase().indexOf(searchInput.toLowerCase()) !==
            -1) ||
        (e.Status &&
          e.Status.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1)
      );
    });
  };
  // ============================= handle cancel resource ============================
  const openCloseModalCancel = (isOpen, value, header) => {
    setModalCancel({
      isOpen: isOpen === true,
      value: isOpen === true ? value : '',
      header: isOpen === true ? header : '',
    });
  };
  const handleSaveCancellationReason = async data => {
    // saveDate();
    let dataCancelResource = {
      CalCancellationReasonId: data.CancellationReasonId,
      CancellationReasonNote: data.CancellationReasonNote,
      ResourceBookingDetailCollectionId: modalCancel.value,
    };
    postCancelResourceAction(dataCancelResource)
      .then(() => {
        openCloseModalCancel(false);
        getResourceListAction(id);
        openModalAlert('Success', 'Cancel resources successfully', '');
      })
      .catch(err => {
        openModalAlert('Failed', err ? err.response.data.Message : err, '');
      });
    // setChangePage({
    //   isChangePage: true,
    //   link: `/Resource-Detail/ReIssue/${props.match.params.id}/${modalCancel.value}`,
    // });
    // this.handleClickAction();
  };
  // ============================= end handle cancel =========================
  // ============================= handle tab menu =================================
  const handleTab = (e, { value }) => {
    setTabActive(value);
    setSearchInput('');
    setActivePage(1);
  };

  // ============================= end handle tab menu =================================
  // handle change page reiisue
  const hanldeReissue = (e, { value }) => {
    saveDate();
    saveAllotmentAction(resourceBookingDetail.Allotment);
    setChangePage({
      isChangePage: true,
      link: `/Resource-Detail/ReIssue/${props.match.params.id}/${value}`,
    });
  };

  let {
    CreatedDate,
    CreatedBy,
    LastModifiedDate,
    LastModifiedBy,
    Title,
    FirstDestination,
    TourType,
    StartDate,
    EndDate,
    Description,
    IsAllowedUpdate,
    ImageUrl,
    CityImage,
    PackageType,
    ResourceBooking,
    Id,
  } = resourceBookingDetail;
  let isTabResourceItemListActive = tabActive === 'RESOURCE_ITEM_LIST';
  let resourceListAfterFilter =
    searchInput && isTabResourceItemListActive
      ? filterData(resourceList, searchInput)
      : resourceList;
  let tourNoteListAfterFilter =
    searchInput && tabActive === 'TOUR_NOTE_LIST'
      ? tourNoteList.filter(e => e.id.indexOf(searchInput.toUpperCase()) !== -1)
      : tourNoteList;
  let pagedData = PaginationScript(
    isTabResourceItemListActive
      ? resourceListAfterFilter
      : tourNoteListAfterFilter,
    5
  );
  let listData = pagedData[activePage - 1];
  let { numbersFrom, numbersTo } = getNumberFromTo(listData, activePage, 5);
  // paggination for tour note

  if (changePage.isChangePage) {
    return <Redirect to={changePage.link} />;
  } else
    return (
      <Segment style={Styles.Segment}>
        <Loader
          active={
            loadingResourceBookingDetailWithSummary ||
            loadingResourceBookingDetailDocument ||
            loadingDeleteResourceBookingCollection ||
            loadingGetResourceList
          }
          description="Loading"
          page={true}
        />
        <HeaderTitle title="Resource Booking Detail" />
        <Grid>
          <Grid.Row>
            <Grid.Column width={16} textAlign="right">
              <Button
                content="Back"
                style={Styles.BorderBlueBackgrounWhite}
                onClick={handleBack}
              />
              {IsAllowedUpdate ? (
                <Button
                  content="Update"
                  primary
                  onClick={handleUpdate}
                  loading={loadingGenerate}
                />
              ) : null}
              <Button
                content="Download resource booking"
                positive
                onClick={handleDownloadResourceBookingDetail}
                value={id}
                loading={loadingDownloadResourceDetail}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <Image
                style={{ width: '100%' }}
                src={
                  ImageUrl ? ImageUrl : CityImage
                  // require('./../../assets/Icons/no_image.jpg')
                }
              />
            </Grid.Column>
            <Grid.Column width={12}>
              <Header as="h4" content="General Information" />
              <Grid>
                <Grid.Row>
                  <Grid.Column width={3}>Generate date</Grid.Column>
                  <Grid.Column width={13}>
                    : {convertDateFormat(CreatedDate, 'DD MMM YYYY')} -{' '}
                    {CreatedBy}
                  </Grid.Column>
                  <Grid.Column width={3}>Last change</Grid.Column>
                  <Grid.Column width={13}>
                    : {convertDateFormat(LastModifiedDate, 'DD MMM YYYY')} -{' '}
                    {LastModifiedBy}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Header as="h4" content="Tour Information" />
              <Grid>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={6}>Resource Id</Grid.Column>
                        <Grid.Column width={10}>
                          : {ResourceBooking.Value}
                        </Grid.Column>
                        <Grid.Column width={6}>Resource Number</Grid.Column>
                        <Grid.Column width={10}>
                          : {ResourceBooking.Text}
                        </Grid.Column>
                        <Grid.Column width={6}>
                          {PackageType === 'Fixed'
                            ? 'Package No'
                            : 'Booking Code'}
                        </Grid.Column>
                        <Grid.Column width={10}>: {Id}</Grid.Column>
                        <Grid.Column width={6}>Tour Name</Grid.Column>
                        <Grid.Column width={10}>: {Title}</Grid.Column>
                        <Grid.Column width={6}>Package Type</Grid.Column>
                        <Grid.Column width={10}>
                          :{' '}
                          {PackageType === 'Custom'
                            ? 'Custom Package'
                            : PackageType === 'FixedDateVariable'
                            ? 'Ready Fixed Price Package'
                            : PackageType === 'Ready'
                            ? 'Ready Package'
                            : 'Series Package'}
                        </Grid.Column>
                        <Grid.Column width={6}>First Destination</Grid.Column>
                        <Grid.Column width={10}>
                          : {FirstDestination}
                        </Grid.Column>
                        <Grid.Column width={6}>Tour Type</Grid.Column>
                        <Grid.Column width={10}>: {TourType}</Grid.Column>
                        <Grid.Column width={6}>Start Date</Grid.Column>
                        <Grid.Column width={10}>
                          : {convertDateFormat(StartDate, 'DD MMM YYYY')}
                        </Grid.Column>
                        <Grid.Column width={6}>End Date</Grid.Column>
                        <Grid.Column width={10}>
                          : {convertDateFormat(EndDate, 'DD MMM YYYY')}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={5}>Description</Grid.Column>
                        <Grid.Column width={1}>:</Grid.Column>
                        <Grid.Column width={9}>
                          {Description || '-'}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <TabMenu
                tabActive={tabActive}
                handleTab={handleTab}
                menus={MENU_RESOURCE_BOOKING_DETAIL}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            style={
              tabActive !== 'RESOURCE_ITEM_LIST' ? { display: 'none' } : {}
            }
          >
            <Grid.Column width={13} style={{ paddingBottom: '20px' }}>
              <InputSearch
                inputStyle={Styles.inputStyle}
                inputInnerStyle={Styles.inputStyleInner}
                icon="search"
                placeholder="Search"
                name="searchInput"
                value={searchInput}
                handleChange={handleChangeSearch}
              />
            </Grid.Column>
            <Grid.Column width={3} textAlign="right">
              <Button content="Add Resource" positive onClick={add} />
            </Grid.Column>
            <Grid.Column width={16}>
              <Table basic="very" selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Id</Table.HeaderCell>
                    <Table.HeaderCell style={{ maxWidth: '200px' }}>
                      Resource Name
                    </Table.HeaderCell>
                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.HeaderCell>Due Date</Table.HeaderCell>
                    <Table.HeaderCell>Total Qty</Table.HeaderCell>
                    <Table.HeaderCell>Payment Status</Table.HeaderCell>
                    <Table.HeaderCell>Resource Status</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                      Action
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {listData ? ( //&& listData.length > 0
                    listData.map((e, idx) => (
                      <Table.Row key={idx}>
                        <Table.Cell>{e.Id}</Table.Cell>
                        <Table.Cell style={{ maxWidth: '200px' }}>
                          {e.ResourceName}
                        </Table.Cell>
                        <Table.Cell>{e.ResourceType}</Table.Cell>
                        <Table.Cell>
                          {convertDateFormat(e.DueDate, 'DD MMM YYYY')}
                        </Table.Cell>
                        <Table.Cell>{e.Qty}</Table.Cell>
                        <Table.Cell>
                          {setStringPaymentStatus(e.PaymentStatus)}
                        </Table.Cell>
                        <Table.Cell>
                          {setStringResourceStatus(e.ResourceStatus)}
                        </Table.Cell>
                        <Table.Cell>
                          {/* aktif atau cancel */}
                          {ucwords(e.Status)}
                        </Table.Cell>
                        <Table.Cell>
                          <Popup
                            content={
                              e.Status === 'CANCELED' ? 'See Detail' : 'Edit'
                            }
                            trigger={
                              <Button
                                icon="edit"
                                primary
                                value={e.Status}
                                id={e.Id}
                                onClick={hanldeSeeDetailResource}
                              />
                            }
                          />
                          <Popup
                            content="Download"
                            trigger={
                              <Button
                                icon="download"
                                positive
                                onClick={handleDownloadResource}
                                value={e.Id}
                              />
                            }
                          />
                          {e.ResourceStatus === 'CANCELED' ||
                          (!e.IsAdjutment &&
                            e.ResourceType === 'Additional Service') ? null : (
                            <Popup
                              content={
                                e.IsAdjutment && e.Status === GENERATED
                                  ? 'Delete'
                                  : 'Cancel'
                              }
                              trigger={
                                <Button
                                  icon="cancel"
                                  negative={true}
                                  value={e.Id}
                                  name={
                                    e.IsAdjutment && e.Status === GENERATED
                                      ? 'DELETE'
                                      : 'CANCEL'
                                  }
                                  onClick={handleDelete}
                                />
                              }
                            />
                          )}
                          {e.ResourceStatus === 'CANCELED' &&
                          !e.IsReissue &&
                          e.ResourceType !== 'Additional Service' ? (
                            <Popup
                              content="ReIssue"
                              trigger={
                                <Button
                                  icon="sync"
                                  primary
                                  value={e.Id}
                                  onClick={hanldeReissue}
                                />
                              }
                            />
                          ) : null}
                        </Table.Cell>
                      </Table.Row>
                    ))
                  ) : (
                    <Table.Row>
                      <Table.Cell colSpan="8" textAlign="center">
                        No data
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
                <Table.Row>
                  <Table.HeaderCell colSpan="10">
                    <Pagination
                      pagedData={pagedData}
                      numbersTo={numbersTo}
                      numbersFrom={numbersFrom}
                      data={resourceListAfterFilter}
                      pageChange={pageChange}
                      activePage={activePage}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            style={tabActive !== 'TOUR_NOTE_LIST' ? { display: 'none' } : {}}
          >
            <Grid.Column width={16} style={{ paddingBottom: '20px' }}>
              <InputSearch
                inputStyle={Styles.inputStyle}
                inputInnerStyle={Styles.inputStyleInner}
                icon="search"
                placeholder="Search by transaction id"
                name="searchInput"
                value={searchInput}
                handleChange={handleChangeSearch}
              />
            </Grid.Column>
            <Grid.Column width={16}>
              <Table basic="very" selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell style={{ width: '100px' }}>
                      Transaction Id
                    </Table.HeaderCell>
                    <Table.HeaderCell>Tour Note</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {listData && listData.length > 0 ? (
                    listData.map(e => (
                      <Table.Row key={e.id}>
                        <Table.Cell style={{ width: '150px' }}>
                          {e.id}
                        </Table.Cell>
                        <Table.Cell>{e.note || '-'}</Table.Cell>
                      </Table.Row>
                    ))
                  ) : (
                    <Table.Row>
                      <Table.Cell
                        style={{ width: '150px' }}
                        colSpan="2"
                        textAlign="center"
                      >
                        No Data
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
                <Table.Row>
                  <Table.HeaderCell colSpan="10">
                    <Pagination
                      pagedData={pagedData}
                      numbersTo={numbersTo}
                      numbersFrom={numbersFrom}
                      data={tourNoteListAfterFilter}
                      pageChange={pageChange}
                      activePage={activePage}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ModalAddResource
          openModal={modalAddResource.isOpen}
          handleClose={handleModalAddResource}
          handleAddResource={handleAddResource}
          loadingGetCompanies={loadingGetCompanies}
          companies={companies}
          loadingGetCurrencies={loadingGetCurrencies}
          currencies={currencies}
          idResourceBookingDetail={id}
          mode={modalAddResource.mode}
          data={modalAddResource.data}
        />
        <ModalAlert
          openModal={modalAlert.isOpen}
          handleClose={closeModalAlert}
          header={modalAlert.header}
          content={modalAlert.message}
        />
        <ModalConfirm
          openModal={modalConfirm.isOpen}
          size="mini"
          close={closeModalConfirm}
          confirm={handleModalConfirm}
          message={modalConfirm.message}
          nameConfirm={modalConfirm.type}
          header={modalConfirm.header}
        />
        <ModalCancellationReason
          openModal={modalCancel.isOpen}
          handleSave={handleSaveCancellationReason}
          handleClose={openCloseModalCancel}
          loadingPostCancel={loadingPostCancelResource}
          CancellationReasonType="Partner"
        />
      </Segment>
    );
};

ResourceBookingDetail.propTypes = {
  match: PropTypes.object,
};

export default ResourceBookingDetail;
