import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Grid,
  Input,
  Table,
  Checkbox,
  Button,
  Pagination,
} from 'semantic-ui-react';
import LoaderModal from '../../../../../../components/modal/loaderModal';
import { numberRoundUp2Decimal } from '../../../../../../scripts/convertNumber';
import { searchFilterAdditionalService } from '../services/filterData';

const ModalAddAdditionalServices = props => {
  // --------------- initial ---------------
  const { open, onClose, loading, data, handleSave } = props;
  const [activePage, setActivePage] = useState(1);
  const [arrayId, setArrayId] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  // --------------- helper ---------------
  const pagination = (data, perPage) => {
    let sets = [],
      chunks = data.length / perPage;
    for (let i = 0, j = 0; i < chunks; i++, j += perPage)
      sets[i] = data.slice(j, j + perPage);
    return sets;
  };

  const pageChange = (e, { activePage }) => {
    setActivePage(activePage);
  };

  // --------------- handler ---------------
  const handleClickCheckBox = e => {
    const id = parseInt(e.target.id);
    let newArrayId = [...arrayId];
    const indexOf = newArrayId.indexOf(id);
    if (newArrayId.length === 0 || indexOf === -1) {
      newArrayId.push(id);
    } else if (indexOf >= 0) {
      newArrayId.splice(indexOf, 1);
    }
    setArrayId(newArrayId);
  };

  const handleSaveButton = () => {
    handleSave(arrayId);
    setArrayId([]);
  };

  const handleChange = e => {
    setSearchInput(e.target.value);
  };

  // --------------- variable pagination ---------------
  let filterSearch = searchFilterAdditionalService(data, searchInput);
  let pagedData = pagination(filterSearch, 7);
  let listData = pagedData[activePage - 1];

  return (
    <Modal open={open} size="tiny" onClose={onClose} closeOnDimmerClick>
      <Modal.Header>Additional Service List</Modal.Header>
      <Modal.Content>
        <LoaderModal loading={loading} />
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Input
                type="text"
                placeholder="Search additional service"
                icon="search"
                iconPosition="left"
                inputStyles={{ width: '100%' }}
                name="searchInput"
                fluid
                onChange={handleChange}
                value={searchInput}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Table basic="very">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.HeaderCell>Price</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {listData && listData.length ? (
                    listData.map((item, idx) => {
                      return (
                        <Table.Row key={idx}>
                          <Table.Cell>
                            <Checkbox
                              id={item.Id}
                              onClick={handleClickCheckBox}
                            />
                          </Table.Cell>
                          <Table.Cell>{item.AdditionalServicesName}</Table.Cell>
                          <Table.Cell>
                            {item.AdditionalServiceType.TypeName}
                          </Table.Cell>
                          <Table.Cell>{`${
                            item.OriginalCountries[0].Currency
                          } ${numberRoundUp2Decimal(
                            item.OriginalCountries[0].Amount
                          )}`}</Table.Cell>
                        </Table.Row>
                      );
                    })
                  ) : (
                    <Table.Row>
                      <Table.Cell colSpan={4} textAlign="center">
                        No Additional Service Found
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.Cell colSpan={3} textAlign="center">
                      {pagedData.length > 1 ? (
                        <Pagination
                          totalPages={pagedData.length}
                          onPageChange={pageChange}
                          activePage={activePage}
                        />
                      ) : (
                        ''
                      )}
                    </Table.Cell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions style={{ textAlign: 'center' }}>
        <Button basic content="Cancel" onClick={onClose} color="red" />
        <Button primary content="Save" onClick={handleSaveButton} />
      </Modal.Actions>
    </Modal>
  );
};

ModalAddAdditionalServices.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  data: PropTypes.array,
  handleSave: PropTypes.func,
  firstDestination: PropTypes.object,
};

export default ModalAddAdditionalServices;
