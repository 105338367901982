import * as types from '../constants/actionTypes';

const initialState = {
  loading: false,
  getMasterReferenceList: [],
  getMasterReferenceStatus: '',
  errors: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MASTER_REFERENCE:
      return { ...state };
    case types.GET_MASTER_REFERENCE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getMasterReferenceStatus: 'failed',
        getMasterReferenceList: [],
      };
    case types.GET_MASTER_REFERENCE_PENDING:
      return { ...state, loading: true };
    case types.GET_MASTER_REFERENCE_FULFILLED:
      return {
        ...state,
        getMasterReferenceList: action.payload.data,
        loading: false,
        getMasterReferenceStatus: 'success',
      };

    case types.RESET_MASTER_REFERENCE:
      return {
        ...state,
        getMasterReferenceStatus: '',
        deleteMasterReferenceStatus: '',
        postMasterReferenceStatus: '',
        putMasterReferenceStatus: '',
      };

    case types.GET_CANCELLATION_REASON_BY_TYPE_REFERENCE:
      return { ...state };
    case types.GET_CANCELLATION_REASON_BY_TYPE_REFERENCE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getMasterReferenceStatus: 'failed',
        getMasterReferenceList: [],
      };
    case types.GET_CANCELLATION_REASON_BY_TYPE_REFERENCE_PENDING:
      return { ...state, loading: true };
    case types.GET_CANCELLATION_REASON_BY_TYPE_REFERENCE_FULFILLED:
      return {
        ...state,
        getMasterReferenceList: action.payload.data,
        loading: false,
        getMasterReferenceStatus: 'success',
      };

    case types.RESET_CANCELLATION_REASON_BY_TYPE_REFERENCE:
      return {
        ...state,
        getMasterReferenceStatus: '',
        deleteMasterReferenceStatus: '',
        postMasterReferenceStatus: '',
        putMasterReferenceStatus: '',
      };

    default:
      return state;
  }
};

export default reducer;
