import React, { Component } from 'react';
import { Table, Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { HeaderTableMasterDataExcursion } from '../../../../components/table/headerTabel';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../../scripts/pagination';
import FilterData from '../../../../scripts/filterDataTable';
import { getHourAndMinute, timeDuration } from '../../../../scripts/moment';
import Pagination from '../../../../components/common/pagination';
class TableMasterDataExcursionObject extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
    };
  }
  static propTypes = {
    data: PropTypes.array,
  };
  handleChange = (e, { name, value }) => this.setState({ [name]: value });
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  render() {
    const props = this.props;
    let filterData = FilterData(
      this.props.listData,
      'Name',
      this.state.searchInput
    );
    let pagedData = datasetPagination(filterData, 3);
    let listData = pagedData[this.state.activePage - 1];

    let { numbersFrom, numbersTo } = getNumberFromTo(
      listData,
      this.state.activePage,
      3
    );
    return (
      <div>
        <Input
          icon="search"
          placeholder="search"
          name="searchInput"
          value={this.state.searchInput}
          onChange={this.handleChange}
        />
        <Table basic="very" selectable fixed>
          <div style={{ overflow: 'auto' }}>
            <Table.Header>
              <Table.Row>
                {HeaderTableMasterDataExcursion.map(
                  (headTableContent, index) => {
                    return (
                      <Table.HeaderCell key={index}>
                        {headTableContent}
                      </Table.HeaderCell>
                    );
                  }
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {listData &&
                listData.map((attraction, idx) => {
                  return (
                    <Table.Row key={idx}>
                      <Table.Cell component="th" scope="row" width={1}>
                        {attraction.Name}
                      </Table.Cell>
                      <Table.Cell>
                        {' '}
                        {attraction.AddressObject.Address1}
                      </Table.Cell>
                      <Table.Cell> {attraction.AttractionTypeName}</Table.Cell>
                      <Table.Cell>
                        {' '}
                        {getHourAndMinute(attraction.OperationStartTime)}
                      </Table.Cell>
                      <Table.Cell>
                        {' '}
                        {getHourAndMinute(attraction.OperationEndTime)}
                      </Table.Cell>
                      <Table.Cell>
                        {' '}
                        {timeDuration(attraction.OptimumDuration)}
                      </Table.Cell>
                      <Table.Cell>{attraction.AttractionCategory}</Table.Cell>
                      {/* <Table.Cell> {attraction.RegionId}</Table.Cell> */}
                      {/* <Table.Cell> {attraction.CityId}</Table.Cell> */}
                    </Table.Row>
                  );
                })}
            </Table.Body>
            {/* {pagedData &&
              pagedData.length > 1 && (
                <Table.Footer> */}
            <Pagination
              // totalPages={pagedData.length}
              // onPageChange={this.pageChange}
              // activePage={this.state.activePage}
              pagedData={pagedData}
              numbersTo={numbersTo}
              numbersFrom={numbersFrom}
              data={props.listData}
              pageChange={this.pageChange}
              activePage={this.state.activePage}
            />
            {/* </Table.Footer>
              )} */}
          </div>
        </Table>
      </div>
    );
  }
}

TableMasterDataExcursionObject.propTypes = {
  listData: PropTypes.array,
};
export default TableMasterDataExcursionObject;
