import apiClient from '../apiClient';
import {
  GET_HISTORY_TRANSACTION_BY_CODE_URL,
  GET_HISTORY_TRANSACTION_FOR_SALES_BY_CODE_URL,
} from '../apiUrl';

const getHistoryTransactionByCodeApi = Id => {
  return apiClient.get(GET_HISTORY_TRANSACTION_BY_CODE_URL(Id));
};

const getHistoryTransactionForSalesByCodeApi = Id => {
  return apiClient.get(GET_HISTORY_TRANSACTION_FOR_SALES_BY_CODE_URL(Id));
};

export {
  getHistoryTransactionByCodeApi,
  getHistoryTransactionForSalesByCodeApi,
};
