import React from 'react';
import { Dimmer } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { getAreaAction, resetAreaAction } from '../shared/actions/areaAction';
import {
  DeleteAreaAction,
  GetAreaByIdAction,
  PostAreaAction,
  PutAreaAction,
  ResetAreaPageAction,
} from './areaAction';
import { getCityAction } from '../shared/actions/cityAction';
import ModalAlert from '../../components/modal/modalAlert';
// import Loader from '../../components/loader';
import AreaList from './components/AreaListComponent';
import ModalArea from './components/modal/ModalArea';
import PropTypes from 'prop-types';
import ModalConfirm from '../../components/modal/modalConfirmationTwoButton';

class AreaPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      areaList: [],
      areaById: {
        Id: '',
        ImageContent: '',
        ImageName: '',
        Name: '',
        CityId: '',
      },
      /*   imagePreview:
              'https://exploregram.com/wp-content/uploads/2015/05/Pura-Lempuyang-door-Temple-in-Bali-Indonesia.jpg', */
      loadingArea: true,
      failedArea: false,
      modalOpen: false,
      modalArea: false,
      modalConfirmation: false,
      headerAlert: '',
      contentAlert: '',
      errors: {
        Name: { error: false, message: '' },
        CityId: { error: false, message: '' },
      },
      openConfirm: false,
      loadingModal: false,
    };
  }
  static propTypes = {
    getAreaAction: PropTypes.func,
    ResetAreaPageAction: PropTypes.func,
    resetAreaAction: PropTypes.func,
    getAreaStatus: PropTypes.string,
    getArea: PropTypes.array,
    getCityAction: PropTypes.func,
    listCity: PropTypes.array,
    DeleteAreaAction: PropTypes.func,
    GetAreaByIdAction: PropTypes.func,
    getAreaByIdStatus: PropTypes.func,
    getAreaById: PropTypes.func,
    PostAreaAction: PropTypes.func,
    PutAreaAction: PropTypes.func,
    postAreaStatus: PropTypes.string,
    putAreaStatus: PropTypes.string,
    deleteAreaStatus: PropTypes.string,
    errorMessage: PropTypes.string,
    errorPageMessage: PropTypes.string,
    loading: PropTypes.bool,
    loadingPage: PropTypes.bool,
  };

  handleOpen = () => this.setState({ modalOpen: true });
  handleClose = () =>
    this.setState({
      modalOpen: false,
      modalConfirmation: false,
      failedArea: false,
    });
  handleCloseModal = () => this.setState({ modalArea: false });
  handleCloseConfirm = () =>
    this.setState({ openConfirm: false, loadingArea: false });
  componentDidMount = () => {
    this.props.getAreaAction();
    this.props.getCityAction();
  };
  componentDidUpdate = () => {
    let errorMessage = this.props.errorMessage
      ? this.props.errorMessage
      : this.props.errorPageMessage;
    if (this.props.getAreaStatus === 'success') {
      this.setState({
        areaList: this.props.getArea,
        areaById: this.state.areaById,
        loadingArea: false,
      });
      this.props.ResetAreaPageAction();
      this.props.resetAreaAction();
    } else if (this.props.getAreaStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: errorMessage,
        failedArea: true,
        loadingArea: false,
      });
      this.props.ResetAreaPageAction();
      this.props.resetAreaAction();
    }
    if (this.props.getAreaByIdStatus === 'success') {
      this.setStateArea(this.props.getAreaById);
      this.setState({
        loadingModal: false,
      });
      this.props.ResetAreaPageAction();
      this.props.resetAreaAction();
    } else if (this.props.getAreaByIdStatus === 'failed') {
      this.props.ResetAreaPageAction();
      this.props.resetAreaAction();
    }
    if (this.props.postAreaStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Saving Area Succesfully',
        modalConfirmation: true,
        loadingModal: false,
        modalArea: false,
      });
      this.props.ResetAreaPageAction();
      this.props.resetAreaAction();
      this.props.getAreaAction();
    } else if (this.props.postAreaStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: errorMessage,
        modalConfirmation: true,
        loadingModal: false,
      });
      this.props.ResetAreaPageAction();
      this.props.resetAreaAction();
      this.props.getAreaAction();
    }
    if (this.props.putAreaStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Edit Area Succesfully',
        modalConfirmation: true,
        loadingModal: false,
        modalArea: false,
      });
      this.props.ResetAreaPageAction();
      this.props.resetAreaAction();
      this.props.getAreaAction();
    } else if (this.props.putAreaStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: errorMessage,
        modalConfirmation: true,
        loadingModal: false,
      });
      this.props.ResetAreaPageAction();
      this.props.resetAreaAction();
      this.props.getAreaAction();
    }
    if (this.props.deleteAreaStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Delete Area Succesfully',
        modalConfirmation: true,
        openConfirm: false,
      });
      this.props.ResetAreaPageAction();
      this.props.resetAreaAction();
      this.props.getAreaAction();
    } else if (this.props.deleteAreaStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: errorMessage,
        modalConfirmation: true,
        openConfirm: false,
      });
      this.props.ResetAreaPageAction();
      this.props.resetAreaAction();
      this.props.getAreaAction();
    }
  };
  onChange = (e, { value, name }) => {
    const areaById = this.state.areaById;
    areaById[name] = value;
    this.setState({ areaById: areaById });
  };
  setStateArea = data => {
    const { Id, Name, imageUrl, City } = data;
    let areaById = {
      Id: Id ? Id : '',
      ImageContent: imageUrl ? imageUrl : '',
      ImageName: '',
      Name: Name ? Name : '',
      CityId: City.Id ? City.Id : '',
    };
    this.setState({ areaById: areaById });
  };
  newArea = () => {
    this.setState({
      modalArea: true,
      areaById: {
        Id: '',
        ImageContent: '',
        ImageName: '',
        Name: '',
        CityId: '',
      },
      headerModalArea: ' Add New Area',
      errors: {
        Name: { error: false, message: '' },
        CityId: { error: false, message: '' },
      },
    });
  };
  editArea = e => {
    this.props.GetAreaByIdAction(e.target.value);
    this.setState({
      modalArea: true,
      headerModalArea: ' Edit Area',
      errors: {
        Name: { error: false, message: '' },
        CityId: { error: false, message: '' },
      },
      loadingModal: false,
      areaById: {
        Id: '',
        ImageContent: '',
        ImageName: '',
        Name: '',
        CityId: '',
      },
    });
  };

  deleteArea = (e, { value, name }) => {
    this.setState({
      openConfirm: true,
      areaId: value,
      contentConfirm: 'Are you sure want to delete ' + name + '?',
    });
  };
  handleConfirm = () => {
    const { areaId } = this.state;
    this.props.DeleteAreaAction(areaId);
    this.setState({
      openConfirm: false,
    });
  };

  saveArea = () => {
    const err = this.validate();
    if (!err) {
      const Id = this.state.areaById.Id;
      const data = this.state.areaById;
      if (this.state.areaById.Id === '') {
        this.props.PostAreaAction(data);
      } else {
        this.props.PutAreaAction(Id, data);
      }
      this.setState({ loadingModal: true });
    }
  };
  validate = () => {
    let isError = false;
    const errors = {
      Name: { error: false, message: '' },
      CityId: { error: false, message: '' },
    };
    if (this.state.areaById.Name.length < 1) {
      isError = true;
      errors.Name.error = true;
      errors.Name.message = "Area Name Can't Be Empty";
    }
    if (this.state.areaById.CityId.length < 1) {
      isError = true;
      errors.CityId.error = true;
      errors.CityId.message = "City Can't Be Empty";
    }
    this.setState({
      errors: {
        ...errors,
      },
    });
    return isError;
  };
  render() {
    let { loadingArea, failedArea, modalConfirmation } = this.state;
    let loading = loadingArea || this.props.loading || this.props.loadingPage;
    let openModal = failedArea || modalConfirmation;
    return (
      <div>
        <Dimmer.Dimmable blurring dimmed={loading}>
          {/* <Loader loading={loading} /> */}
          <AreaList
            data={this.state.areaList}
            type="areaList"
            filterBy="Name"
            buttonNew={this.newArea}
            butttonEdit={this.editArea}
            buttonDelete={this.deleteArea}
          />
          <ModalAlert
            openModal={openModal}
            handleClose={this.handleClose}
            header={this.state.headerAlert}
            content={this.state.contentAlert}
            nameButton="OK"
          />
          <ModalArea
            open={this.state.modalArea}
            handleClose={this.handleCloseModal}
            header={this.state.headerModalArea}
            listCity={this.props.listCity}
            handleChange={this.onChange}
            contentField={this.state.areaById}
            imagePreview={this.state.imagePreview}
            saveArea={this.saveArea}
            errorInformation={this.state.errors}
            loading={loading}
          />
          <ModalConfirm
            openModal={this.state.openConfirm}
            size="mini"
            close={this.handleCloseConfirm}
            confirm={this.handleConfirm}
            message={this.state.contentConfirm}
            header="Confirmation"
          />
        </Dimmer.Dimmable>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    getArea: state.AreaStore.getArea,
    getAreaStatus: state.AreaStore.getAreaStatus,
    listCity: state.CityStore.getCity.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    getAreaById: state.AreaPageStore.getAreaById,
    getAreaByIdStatus: state.AreaPageStore.getAreaByIdStatus,
    deleteAreaStatus: state.AreaPageStore.deleteAreaStatus,
    postAreaStatus: state.AreaPageStore.postAreaStatus,
    putAreaStatus: state.AreaPageStore.putAreaStatus,
    errorMessage: state.AreaStore.errors.global,
    errorPageMessage: state.AreaPageStore.errors.global,
    loading: state.AreaStore.loading,
    loadingPage: state.AreaPageStore.loading,
  };
}
export default connect(
  mapStateToProps,
  {
    getAreaAction,
    ResetAreaPageAction,
    resetAreaAction,
    getCityAction,
    DeleteAreaAction,
    GetAreaByIdAction,
    PostAreaAction,
    PutAreaAction,
  }
)(AreaPage);
