import React from 'react';
import { Table, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
// import ActionButton from '../button/ButtonActionTable';
import { Link } from 'react-router-dom';
// import { convertDateFormat } from '../../../../../scripts/moment';
const TableCompanyList = props => (
  <Table basic="very" selectable>
    <Table.Header>
      <Table.Row>
        {props.headerTable.map((headTableContent, index) => {
          return (
            <Table.HeaderCell key={index} textAlign="center">
              {headTableContent}
            </Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {props.listData &&
        props.listData.map((data, idx) => {
          return (
            <Table.Row key={idx}>
              <Table.Cell>
                <Link to={`/company-profile-detail/detail/${data.Code}`}>
                  {data.Code}
                </Link>
              </Table.Cell>
              <Table.Cell>{data.Name}</Table.Cell>

              <Table.Cell>{data.Telephone}</Table.Cell>
              <Table.Cell>{data.Email}</Table.Cell>
              <Table.Cell colSpan="2" textAlign="center">
                <Button
                  primary
                  content="Edit"
                  onClick={props.editCompanyProfile}
                  name="edit"
                  value={data.Code}
                />
                <Button
                  negative
                  content="Delete"
                  onClick={props.deleteCompanyProfile}
                  name={data.Name}
                  value={data.Code}
                />
              </Table.Cell>
            </Table.Row>
          );
        })}
    </Table.Body>
  </Table>
);

TableCompanyList.propTypes = {
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  editCompanyProfile: PropTypes.func,
  deleteCompanyProfile: PropTypes.func,
};
export default TableCompanyList;
