import * as types from '../shared/constants/actionTypes';

const initialState = {
  generateARStatus: false,
  downloadARSummaryStatus: false,
  downloadArSummary: '',
  aRsummaryList: {},
  errors: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_GENERATE_AR_SUMMARY:
      return { ...state };
    case types.GET_GENERATE_AR_SUMMARY_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        generateARStatus: false,
      };
    case types.GET_GENERATE_AR_SUMMARY_PENDING:
      return { ...state, loading: true };
    case types.GET_GENERATE_AR_SUMMARY_FULFILLED:
      return {
        ...state,
        loading: false,
        generateARStatus: true,
      };
    case types.GET_AR_SUMMARY_LIST:
      return { ...state };
    case types.GET_AR_SUMMARY_LIST_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
      };
    case types.GET_AR_SUMMARY_LIST_PENDING:
      return { ...state, loading: true };
    case types.GET_AR_SUMMARY_LIST_FULFILLED:
      return {
        ...state,
        aRsummaryList: action.payload.data,
        loading: false,
      };
    case types.DOWNLOAD_AR_SUMMARY:
      return { ...state };
    case types.DOWNLOAD_AR_SUMMARY_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        downloadARSummaryStatus: false,
      };
    case types.DOWNLOAD_AR_SUMMARY_PENDING:
      return { ...state, loading: true };
    case types.DOWNLOAD_AR_SUMMARY_FULFILLED:
      return {
        ...state,
        downloadARSummaryStatus: true,
        downloadArSummary: action.payload.data,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
