import * as types from '../actions/actionTypes';

const initialState = {
  loading: false,
  getReadyPackageList: [],
  getReadyPackageListStatus: '',
  getReadyPackageDetail: [],
  getReadyPackageDetailStatus: '',
  postReadyPackageStatus: '',
  getReadyPackageById: [],
  getReadyPackageByIdStatus: '',
  errors: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_READY_PACKAGE:
      return { ...state };
    case types.GET_READY_PACKAGE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getReadyPackageListStatus: 'failed',
      };
    case types.GET_READY_PACKAGE_PENDING:
      return { ...state, loading: true };
    case types.GET_READY_PACKAGE_FULFILLED:
      return {
        ...state,
        getReadyPackageList: action.payload.data,
        loading: false,
        getReadyPackageListStatus: 'success',
      };
    case types.GET_READY_PACKAGE_DETAIL:
      return { ...state };
    case types.GET_READY_PACKAGE_DETAIL_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getReadyPackageDetailStatus: 'failed',
      };
    case types.GET_READY_PACKAGE_DETAIL_PENDING:
      return { ...state, loading: true };
    case types.GET_READY_PACKAGE_DETAIL_FULFILLED:
      return {
        ...state,
        getReadyPackageDetail: action.payload.data,
        loading: false,
        getReadyPackageDetailStatus: 'success',
      };
    case types.POST_READY_PACKAGE:
      return { ...state };
    case types.POST_READY_PACKAGE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        postReadyPackageStatus: 'failed',
      };
    case types.POST_READY_PACKAGE_PENDING:
      return { ...state, loading: true };
    case types.POST_READY_PACKAGE_FULFILLED:
      return {
        ...state,
        loading: false,
        postReadyPackageStatus: 'success',
      };
    case types.GET_PACKAGE_BY_TYPE_AND_ID:
      return { ...state };
    case types.GET_PACKAGE_BY_TYPE_AND_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getReadyPackageByIdStatus: 'failed',
      };
    case types.GET_PACKAGE_BY_TYPE_AND_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_PACKAGE_BY_TYPE_AND_ID_FULFILLED:
      return {
        ...state,
        getReadyPackageById: action.payload.data,
        loading: false,
        getReadyPackageByIdStatus: 'success',
      };
    case types.RESET_READY_PACKAGE:
      return {
        ...state,
        getReadyPackageListStatus: '',
        getReadyPackageDetailStatus: '',
        postReadyPackageStatus: '',
        getReadyPackageByIdStatus: '',
      };
    default:
      return state;
  }
};

export default reducer;
