import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleButton } from '../../../../../components/common/styleCommon';
import { Button, Grid } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

class TimeLineEditAndDeleteActivity extends Component {
  handleEdit = () => {
    const {
      activityType,
      openTransportationModal,
      handleOpenModalActivities,
      movements,
      activity,
      index,
      day,
    } = this.props;
    if (activityType === 'DRIVING') {
      let prevMove = activity.Movements[index - 1];
      let nextMove = activity.Movements[index + 1];
      openTransportationModal(
        prevMove.OriginDestination,
        nextMove.OriginDestination,
        movements.DateTime,
        index,
        day
      );
    } else {
      handleOpenModalActivities(
        movements.MovementName,
        movements,
        activity.Movements,
        index,
        day,
        'EDIT'
      );
    }
  };

  handleDelete = () => {
    const { activityType, deleteActivity, deleteTransportItem } = this.props;
    if (activityType === 'DRIVING') {
      deleteTransportItem(this.props.day, this.props.index);
    } else {
      deleteActivity(this.props.index, this.props.day);
    }
  };
  render() {
    const { activityType, movements } = this.props;
    const { TypeTransaction, PackageType } = this.props.match.params;
    let showButtonComponent =
      (typeof this.props.isPublished !== 'undefined' &&
        this.props.isPublished === false) ||
      (TypeTransaction === 'edit' && PackageType === 'Quotation') ||
      (TypeTransaction === 'create' &&
        (PackageType
          ? PackageType !== 'Quotation'
            ? PackageType !== 'Fixed'
              ? PackageType !== 'FixedDateVariable'
              : false
            : false
          : false));

    return showButtonComponent ? (
      <Button.Group>
        <Button
          icon="edit"
          style={StyleButton.buttonBlueActivity}
          onClick={this.handleEdit}
          size="huge"
          disabled={this.props.isPublished}
        />
        {(activityType === 'DRIVING' && !movements.Item.ServiceItemId) || (
          <Button
            icon="delete"
            style={StyleButton.buttonTransparentRed}
            onClick={this.handleDelete}
            size="huge"
            disabled={this.props.isPublished}
          />
        )}
      </Button.Group>
    ) : (
      <Grid style={{ marginBottom: '10px' }}>
        <Grid.Row>
          <Grid.Column>
            <span></span>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
TimeLineEditAndDeleteActivity.propTypes = {
  activity: PropTypes.object,
  handleOpenModalActivities: PropTypes.func,
  openModalSetActivity: PropTypes.func,
  movements: PropTypes.array,
  index: PropTypes.number,
  day: PropTypes.number,
  deleteActivity: PropTypes.func,
  activityType: PropTypes.func,
  deleteTransportItem: PropTypes.func,
  openTransportationModal: PropTypes.func,
  isPublished: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      Id: PropTypes.number,
      type: PropTypes.string,
      TypeTransaction: PropTypes.string,
      PackageType: PropTypes.string,
    }),
  }),
};
export default withRouter(TimeLineEditAndDeleteActivity);
