import React from 'react';
import { useState } from 'react';
// import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Button } from 'semantic-ui-react';
import ModalAdditionalServices from './ModalAdditionalServices';
import TableAdditionalServices from './TableAdditionalServices';
import {
  getAdditionalServicesListTransactionApi,
  getAdditionalServiceTypeApi,
} from '../../../../../api/additionalServices/additionalServicesApi';
import {
  getIndexofInArrayObject,
  getCountryFromArray,
  filterAdditionalServicesBySelected,
} from '../../../../shared/services/additionalServices/processData';

const SegmentAdditionalServices = props => {
  // ---------------------- initial ----------------------
  const {
    headlineProgram,
    selectedAdditionalServices,
    setAdditionalServices,
  } = props;
  const [additionalServicesList, setAdditionalServicesList] = useState([]);
  const [additionalServiceTypeList, setAdditionalServicesTypeList] = useState(
    []
  );
  const [openModal, setOpenModal] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  // --------------- helper ---------------

  const getAdditionalServiceFromApi = async (data, idx = 0, res = []) => {
    if (idx >= data.length) return res;
    else if (data.length) {
      setLoadingModal(true);
      let resData = [...res];
      await getAdditionalServicesListTransactionApi(
        data[idx].from,
        data[idx].to,
        'IDR'
      )
        .then(result => {
          // let listDataRes = result.data;
          let listDataRes =
            data[idx].from === 'DEF'
              ? result.data.map(i => {
                  return {
                    ...i,
                    OriginalCountries: i.OriginalCountries.filter(
                      j => j.Value === 'DEF'
                    ),
                  };
                })
              : result.data;
          resData = resData.concat(listDataRes);
          setLoadingModal(false);
        })
        .catch(() => setLoadingModal(false));
      return getAdditionalServiceFromApi(data, idx + 1, resData);
    }
  };

  const getAdditionalServiceTypeFromApi = () => {
    let newLoadingModal = loadingModal || true;
    setLoadingModal(newLoadingModal);
    getAdditionalServiceTypeApi()
      .then(result => {
        let data =
          result.data &&
          result.data.map(item => {
            return {
              key: item.Id,
              text: item.TypeName,
              value: item.Id,
            };
          });
        const allType = {
          key: 0,
          text: 'All Type',
          value: 0,
        };
        data.unshift(allType);
        setAdditionalServicesTypeList(data);
        setLoadingModal(loadingModal && false);
      })
      .catch(() => {
        setLoadingModal(loadingModal && false);
      });
  };

  // ---------------------- handler ----------------------
  const handleOpen = async () => {
    setOpenModal(true);
    if (headlineProgram && headlineProgram.MainPrograms.City !== null) {
      await getAdditionalServiceTypeFromApi();

      let resultGetApi = getAdditionalServiceFromApi(getCountry);
      resultGetApi &&
        resultGetApi.then(items => {
          let selected = [...selectedAdditionalServices];
          let addServ = [...items];
          let newAdditionalServicesList = filterAdditionalServicesBySelected(
            addServ,
            selected
          );
          setAdditionalServicesList(newAdditionalServicesList);
        });
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleSave = (id, IsMandatory = false, isInfantCount = false) => {
    if (id) {
      let selected = [...selectedAdditionalServices];
      let addServ = [...additionalServicesList];
      let obj = addServ.find(item => item.Id === id);
      let newSelected = {
        AdditionalServiceId: obj.Id,
        Quantity: 0,
        AdditionalServiceName: obj.AdditionalServicesName,
        Amount: obj.OriginalCountries[0].Amount,
        Currency: obj.OriginalCountries[0].Currency,
        OriginalCountryId: obj.OriginalCountries[0].OriginalCountryId,
        IsMandatory: IsMandatory,
        DestinationCountry: obj.DestinationCountry.Text,
        DestinationCountryId: obj.DestinationCountry.Value,
        IsInfantCount: isInfantCount,
      };
      selected.push(newSelected);
      setAdditionalServices(selected);
    }
    setOpenModal(false);
  };

  const handleDelete = e => {
    let newSelected = [...selectedAdditionalServices];
    const id = parseInt(e.target.id);
    let index = getIndexofInArrayObject(newSelected, 'AdditionalServiceId', id);
    newSelected.splice(index, 1);
    setAdditionalServices(newSelected);
  };

  // ---------------------- variable ----------------------

  let resultFilter =
    headlineProgram &&
    headlineProgram.MainPrograms.filter(
      item => typeof item.AccommodationSummary !== 'undefined'
    );
  let getCountry = getCountryFromArray(resultFilter);

  return (
    <Grid columns="equal">
      <Grid.Row>
        <Grid.Column>
          <Header as="h4" content="Additional Services" />
        </Grid.Column>
        <Grid.Column />
        <Grid.Column floated="right">
          <Button
            positive
            content="Add New"
            floated="right"
            onClick={handleOpen}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row stretched>
        <Grid.Column width={15}>
          {/* tabel additional services */}
          <TableAdditionalServices
            data={selectedAdditionalServices}
            handleDelete={handleDelete}
          />
        </Grid.Column>
      </Grid.Row>
      <ModalAdditionalServices
        openModal={openModal}
        handleClose={handleClose}
        handleSave={handleSave}
        loading={loadingModal}
        data={additionalServicesList}
        typeList={additionalServiceTypeList}
      />
    </Grid>
  );
};

SegmentAdditionalServices.propTypes = {
  headlineProgram: PropTypes.object,
  selectedAdditionalServices: PropTypes.array,
  setAdditionalServices: PropTypes.func,
};

export default React.memo(SegmentAdditionalServices);
