import * as types from '../constants/actionTypes';

const initialState = {
  getAreaList: [],
  getAreaListStatus: '',
  getArea: [],
  getAreaStatus: '',
  errors: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_AREA_BY_CITY:
      return { ...state };
    case types.GET_AREA_BY_CITY_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getAreaListStatus: 'failed',
      };
    case types.GET_AREA_BY_CITY_PENDING:
      return { ...state, loading: true };
    case types.GET_AREA_BY_CITY_FULFILLED:
      return {
        ...state,
        getAreaList: action.payload.data,
        loading: false,
        getAreaListStatus: 'success',
      };

    case types.RESET_AREA:
      return {
        ...state,
        getAreaListStatus: '',
        getAreaStatus: '',
      };

    case types.GET_AREA_LIST:
      return { ...state };
    case types.GET_AREA_LIST_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getAreaStatus: 'failed',
        loading: false,
      };
    case types.GET_AREA_LIST_PENDING:
      return { ...state, loading: true };
    case types.GET_AREA_LIST_FULFILLED:
      return {
        ...state,
        getArea: action.payload.data,
        loading: false,
        getAreaStatus: 'success',
      };

    default:
      return state;
  }
};

export default reducer;
