import {
  GET_PLACE_LIST,
  RESET_PLACE,
  DELETE_PLACE,
  GET_PLACE_BY_ID,
  POST_PLACE,
  PUT_PLACE,
  GET_PLACE_TYPE,
  GET_PLACE_CITIES_BY_PLACE_ID,
  POST_PLACE_CITIES,
  DELETE_PLACE_CITIES,
} from './constant/actionTypes';
import { getPlaceListApi } from '../../api/place/getPlaceListApi';
import deletePlaceApi from '../../api/place/deletePlaceApi';
import getPlaceByIdApi from '../../api/place/getPlaceByIdApi';
import postPlaceApi from '../../api/place/postPlaceApi';
import putPlaceApi from '../../api/place/putPlaceApi';
import getPlaceTypeApi from '../../api/place/getPlaceTypeApi';
import {
  getPlaceCitiesListByPlaceIdApi,
  postPlaceCitiesApi,
  deletePlaceCitiesByIdApi,
} from './../../api/place/placeCities';

const getPlaceListAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_PLACE_LIST,
      payload: getPlaceListApi(),
    });
  };
};

const resetPlaceAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_PLACE,
    });
  };
};

const deletePlaceAction = Id => {
  return dispatch => {
    return dispatch({
      type: DELETE_PLACE,
      payload: deletePlaceApi(Id),
    });
  };
};

const getPlaceByIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_PLACE_BY_ID,
      payload: getPlaceByIdApi(Id),
    });
  };
};

const postPlaceAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_PLACE,
      payload: postPlaceApi(data),
    });
  };
};

const putPlaceAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_PLACE,
      payload: putPlaceApi(id, data),
    });
  };
};

const getPlaceTypeAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_PLACE_TYPE,
      payload: getPlaceTypeApi(),
    });
  };
};

const getPlaceCitiesListByPlaceIdAction = id => {
  return dispatch => {
    return dispatch({
      type: GET_PLACE_CITIES_BY_PLACE_ID,
      payload: getPlaceCitiesListByPlaceIdApi(id),
    });
  };
};

const postPlaceCitiesAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_PLACE_CITIES,
      payload: postPlaceCitiesApi(data),
    });
  };
};

const deletePlaceCitiesByIdAction = id => {
  return dispatch => {
    return dispatch({
      type: DELETE_PLACE_CITIES,
      payload: deletePlaceCitiesByIdApi(id),
    });
  };
};

export {
  getPlaceListAction,
  resetPlaceAction,
  deletePlaceAction,
  getPlaceByIdAction,
  putPlaceAction,
  postPlaceAction,
  getPlaceTypeAction,
  getPlaceCitiesListByPlaceIdAction,
  postPlaceCitiesAction,
  deletePlaceCitiesByIdAction,
};
