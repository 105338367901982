import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Button, Form, Image, Grid } from 'semantic-ui-react';
import InputDate from './../input/inputDate';
import moment from 'moment';
import ButtonClose from './../button/buttonCloseModal';
import { postCommissionPayment } from './../../actions/tourTransactionAction';
import Loader from './../loader/loaderInModal';
import UploadButton from '../common/uploadImageButton';
import InputCurrencyFormat from '../input/inputCurrencyFormat';
import ModalAlert from '../../pages/shared/components/modal/ModalAlert';

class modalPayCommission extends Component {
  state = {
    commissionPayment: {
      PaymentValue: '',
      PaymentDate: moment(new Date()).format('YYYY-MM-DD'),
      ImageUrl: '',
      ImageName: '',
      ImageContent: '',
      ImagePreview: '',
    },
  };
  // handleChange = (e, { name, value, objectname }) => {
  //   let commissionPayment = { ...this.state[objectname] };
  //   let errors = { ...this.state.errors };
  //   commissionPayment[name] = value;
  //   errors[name].error = value === '';
  //   this.setState({ ...this.state, commissionPayment, errors });
  // };
  pay = () => {
    let { commissionPayment } = { ...this.state };
    let { cashBack, tourTransactionId } = this.props;
    let data = {
      PaymentValue: cashBack,
      PaymentDate: commissionPayment.PaymentDate,
      ImageContent: commissionPayment.ImageContent,
      ImageName: commissionPayment.ImageName,
      TourTransactionId: tourTransactionId,
    };
    this.props
      .postCommissionPayment(data)
      .then(() => {
        this.handleMessageAlert('Successfully', 'Commission successfully paid');
      })
      .catch(error =>
        this.handleMessageAlert('Failed', error.response.data.Message)
      );
  };

  handleMessageAlert = (header, message) => {
    this.setState({
      modalAlertOpen: true,
      modalAlertHeader: header,
      modalAlertMessage: message,
    });
  };

  handleChangeImage = e => {
    let { commissionPayment } = this.state;
    let reader = new FileReader();
    let files = e.target.files[0];
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      let images = {
        ...commissionPayment,
        ImageName: files.name,
        ImageContent: reader.result.split(',')[1],
        ImagePreview: reader.result,
        ImageUrl: reader.result.split(',')[1],
      };
      this.setState({
        commissionPayment: images,
      });
    };
  };
  handleChange = (value, name) => {
    let { commissionPayment } = this.state;
    commissionPayment = {
      ...commissionPayment,
      [name]: moment(value).format('YYYY-MM-DD'),
    };
    this.setState({ commissionPayment });
  };
  handleCloseAlert = () => {
    this.setState({
      modalAlertOpen: false,
    });
    if (this.state.modalAlertHeader === 'Successfully') {
      let { filterDateRangeFunc, filterByDate } = this.props;
      if (filterByDate !== undefined) {
        filterDateRangeFunc(
          filterByDate.tourStartDate,
          filterByDate.tourEndDate,
          filterByDate.createStartDate,
          filterByDate.createEndDate
        );
      }
      this.props.handleClose();
    }
  };
  render() {
    const {
      openModal,
      handleClose,
      loadingPostCommission,
      cashBack,
    } = this.props;
    const {
      commissionPayment,
      modalAlertOpen,
      modalAlertHeader,
      modalAlertMessage,
    } = this.state;
    return (
      <Modal
        dimmer="blurring"
        inverted
        open={openModal}
        onClose={handleClose}
        style={{ width: '30.5em' }}
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          Commission Payment
          <ButtonClose onClose={handleClose} />
        </Modal.Header>
        <Modal.Content>
          <Loader loading={loadingPostCommission} />
          <Grid columns={2} padded="vertically">
            <Grid.Column>
              <Form>
                <Form.Field>
                  <InputCurrencyFormat
                    value={cashBack ? cashBack : 0}
                    name="Payment"
                    label="Payment Value"
                    labelStyle={{ fontWeight: 'bold' }}
                    handelChangePrice={this.handleChange}
                  />
                </Form.Field>
                <Form.Field>
                  <InputDate
                    label="Date"
                    name="PaymentDate"
                    value={commissionPayment.PaymentDate}
                    inputStyles={{ width: '100%' }}
                    // setDate={this.handleChange}
                    labelStyle={{
                      display: 'block',
                      fontWeight: 'bold',
                      marginBottom: '4px',
                    }}
                    readonly={true}
                  />
                </Form.Field>
              </Form>
            </Grid.Column>
            <Grid.Column>
              <Image
                src={
                  commissionPayment.ImagePreview ||
                  'https://react.semantic-ui.com/images/wireframe/image.png'
                }
                size="small"
                centered
                rounded
                style={{ marginTop: '1em' }}
              />
              <UploadButton
                onImageSelect={this.handleChangeImage}
                label="Select Image"
                buttonStyle={{ marginTop: '1em', marginLeft: '2em' }}
              />
            </Grid.Column>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button positive content="Pay" onClick={this.pay} />
        </Modal.Actions>
        <ModalAlert
          openModal={modalAlertOpen}
          handleClose={this.handleCloseAlert}
          header={modalAlertHeader}
          content={modalAlertMessage}
        />
      </Modal>
    );
  }
}

modalPayCommission.propTypes = {
  openModal: PropTypes.bool,
  handleClose: PropTypes.func,
  postCommissionPayment: PropTypes.func,
  loadingPostCommission: PropTypes.bool,
  cashBack: PropTypes.number,
  tourTransactionId: PropTypes.number,
  filterDateRangeFunc: PropTypes.func,
  filterByDate: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    loadingPostCommission: state.TourTransactionStore.loadingPostCommission,
  };
}
export default connect(mapStateToProps, { postCommissionPayment })(
  modalPayCommission
);
