import React from 'react';
import { Grid, Divider, Segment, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import GeneralInformation from '../../../../pages/shared/components/profile/GeneralInfoProfile';
import ContactInformation from '../../../../pages/shared/components/profile/ContactInfoProfile';
import LegalityCustomer from '../../../../pages/shared/components/profile/LegalityCustomer';
import PaymentTermCustomer from '../../../../pages/shared/components/profile/PaymentTermCustomer';
import CustomerListHistory from '../../../../pages/shared/components/tab/TabItem';
import LoadingPage from '../../../../components/modal/loaderModal';
import HeaderTitle from '../../../../components/common/headerTitle';
const partnerProfileDetail = props => (
  <Segment style={{ marginTop: '30px' }}>
    <HeaderTitle iconName="users" title="Partner Profile Detail" />
    <Grid>
      <LoadingPage loading={props.loadingCustomerProfile} />
      <div style={{ margin: '1em 0em 0em 63em' }}>
        <Button content="Back" primary onClick={props.buttonBack} />
        {props.isFinance || (
          <Button
            content={props.contentButton}
            positive
            onClick={props.buttonEdit}
          />
        )}
      </div>
      <Grid.Row>
        <Grid.Column width={8}>
          <GeneralInformation
            contentDetail={props.contentDetail}
            numberEmploye={props.numberEmploye}
            branch={props.branch}
            revenueRange={props.revenueRange}
            currencies={props.currencies}
            handleChange={props.handleChange}
            disable={props.disable}
            errorInformation={props.errorInformation}
            typeCustomer="new"
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <LegalityCustomer
            contentDetail={props.contentDetail}
            handleChange={props.handleChange}
            disable={props.disable}
            errorInformation={props.errorInformation}
            handleCheckClick={props.handleCheckClick}
            listCompanyBussiness={props.listCompanyBussiness}
            isPartner={props.isPartner}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <ContactInformation
            contentDetail={props.contentDetail}
            handleChange={props.handleChange}
            disable={props.disable}
            listCountry={props.listCountry}
            errorInformation={props.errorInformation}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <PaymentTermCustomer
            contentDetail={props.contentDetail}
            contentCompanyLogo={props.contentDetail.CompanyLogo}
            handleChange={props.handleChange}
            disable={props.disable}
            getPaymentSchemeList={props.getPaymentSchemeList}
            errorInformation={props.errorInformation}
            modalImage={props.modalImage}
            isPartner={props.isPartner}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <Divider />
    <CustomerListHistory
      historyTransaction={props.historyTransaction}
      userData={props.userData}
      unlockUser={props.unlockUser}
      tabMenu={props.tabMenu}
    />
  </Segment>
);

partnerProfileDetail.propTypes = {
  listCompanyBussiness: PropTypes.array,
  historyTransaction: PropTypes.array,
  userData: PropTypes.array,
  balanceDetail: PropTypes.func,
  buttonBack: PropTypes.func,
  setMargin: PropTypes.func,
  saveCustomer: PropTypes.func,
  contentDetail: PropTypes.shape({
    Name: PropTypes.string,
    Address: PropTypes.string,
    Telephone: PropTypes.string,
    Email: PropTypes.string,
    FaxNbr: PropTypes.string,
    IATANbr: PropTypes.string,
    TradeLicenseNbr: PropTypes.string,
    AssociationNumber: PropTypes.string,
    EmployeeRangeId: PropTypes.string,
    BranchRangeId: PropTypes.string,
    RevenueRangeId: PropTypes.string,
    CountryId: PropTypes.string,
    /*  CompanyLogo: PropTypes.shape({
      ImageContent: PropTypes.string,
      ImageName: PropTypes.string,
    }), */
    CompanyLogo: PropTypes.object,
    IsAllowedOwnCountryTour: PropTypes.string,
    ImageName: PropTypes.string,
    ImageContent: PropTypes.string,
    PaymentSchemeId: PropTypes.string,
    CompanyId: PropTypes.string,
  }),
  errorInformation: PropTypes.object,
  numberEmploye: PropTypes.array,
  revenueRange: PropTypes.array,
  branch: PropTypes.array,
  currencies: PropTypes.array,
  handleChange: PropTypes.func,
  disable: PropTypes.bool,
  buttonEdit: PropTypes.bool,
  contentButton: PropTypes.string,
  listCountry: PropTypes.array,
  getPaymentSchemeList: PropTypes.array,
  handleCheckClick: PropTypes.func,
  modalImage: PropTypes.func,
  unlockUser: PropTypes.func,
  tabMenu: PropTypes.array,
  loadingCustomerProfile: PropTypes.bool,
  isNewForm: PropTypes.bool,
  isPartner: PropTypes.bool,
  isFinance: PropTypes.bool,
};

export default partnerProfileDetail;
