import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Form } from 'semantic-ui-react';
import { convertDateFormat } from '../../../../scripts/moment';
import InputWithLabel from '../../../../components/input/inputWithLabel';
const GeneralInfoProfile = props => {
  return (
    <div>
      <Grid columns="equal">
        <Grid.Row stretched>
          <Grid.Column width={15}>
            <Header as="h4" content="General Information" />
            <Form>
              <Form.Group>
                <Form.Field width={8}>
                  <InputWithLabel
                    label="Company ID"
                    placeholder="Company ID"
                    name="CompanyId"
                    type="text"
                    value={props.contentDetail.CompanyId}
                    handleChange={props.handleChange}
                    required={false}
                    fluid={true}
                    validate={props.errorInformation}
                    disabled={true}
                  />
                </Form.Field>
                <Form.Field width={8}>
                  <InputWithLabel
                    label="Company Name"
                    placeholder="Company Name"
                    name="Name"
                    type="text"
                    value={props.contentDetail.Name}
                    handleChange={props.handleChange}
                    required
                    fluid={true}
                    validate={props.errorInformation}
                    disabled={props.disable}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={8}>
                  <InputWithLabel
                    label="Number of Employee"
                    placeholder="Number of Employee"
                    name="EmployeeRangeId"
                    type="select"
                    value={props.contentDetail.EmployeeRangeId}
                    handleChange={props.handleChange}
                    options={props.numberEmploye}
                    required
                    fluid={true}
                    validate={props.errorInformation}
                    disabled={props.disable}
                  />
                </Form.Field>
                <Form.Field width={8}>
                  <InputWithLabel
                    label="Based Currency"
                    placeholder="Based Currency"
                    name="CurrencyId"
                    type="select"
                    value={props.contentDetail.CurrencyId}
                    handleChange={props.handleChange}
                    options={props.currencies}
                    required
                    fluid={true}
                    validate={props.errorInformation}
                    disabled={props.disable}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={8}>
                  <InputWithLabel
                    label="Total Sub Agents/Branch"
                    placeholder="Total Sub Agents/Branch"
                    name="BranchRangeId"
                    type="select"
                    value={props.contentDetail.BranchRangeId}
                    handleChange={props.handleChange}
                    options={props.branch}
                    required
                    fluid={true}
                    validate={props.errorInformation}
                    disabled={props.disable}
                  />
                </Form.Field>
                <Form.Field width={8}>
                  <InputWithLabel
                    label="Company Revenue"
                    placeholder="Company Revenue"
                    name="RevenueRangeId"
                    type="select"
                    value={props.contentDetail.RevenueRangeId}
                    handleChange={props.handleChange}
                    options={props.revenueRange}
                    required
                    fluid={true}
                    validate={props.errorInformation}
                    disabled={props.disable}
                  />
                </Form.Field>
              </Form.Group>
              {props.typeCustomer !== 'new' && (
                <Form.Group>
                  <Form.Field width={8}>
                    <InputWithLabel
                      label="Registration Date"
                      placeholder="Registration Date"
                      name="CreatedDate"
                      type="text"
                      value={
                        props.contentDetail.CreatedDate
                          ? convertDateFormat(
                              props.contentDetail.CreatedDate,
                              'DD MMM YYYY'
                            )
                          : '-'
                      }
                      fluid={true}
                      disabled={props.disable}
                    />
                  </Form.Field>
                  <Form.Field width={8}>
                    <InputWithLabel
                      label="Join Date"
                      placeholder="Join Date"
                      name="ApprovedDate"
                      type="text"
                      value={
                        props.contentDetail.ApprovedDate
                          ? convertDateFormat(
                              props.contentDetail.ApprovedDate,
                              'DD MMM YYYY'
                            )
                          : '-'
                      }
                      fluid={true}
                      disabled={props.disable}
                    />
                  </Form.Field>
                </Form.Group>
              )}
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

GeneralInfoProfile.propTypes = {
  handleChange: PropTypes.func,
  contentDetail: PropTypes.shape({
    Name: PropTypes.string,
    EmployeeRangeId: PropTypes.string,
    BranchRangeId: PropTypes.string,
    RevenueRangeId: PropTypes.string,
    CompanyId: PropTypes.string,
    CurrencyId: PropTypes.string,
    CreatedDate: PropTypes.string,
    ApprovedDate: PropTypes.string,
  }),
  errorInformation: PropTypes.shape({
    NameError: PropTypes.string,
    EmployeeRangeIdError: PropTypes.string,
    RevenueRangeIdError: PropTypes.string,
    BranchRangeIdError: PropTypes.string,
    CurrencyIdError: PropTypes.string,
  }),
  numberEmploye: PropTypes.array,
  revenueRange: PropTypes.array,
  branch: PropTypes.array,
  currencies: PropTypes.array,
  disable: PropTypes.bool,
  typeCustomer: PropTypes.string,
};
export default GeneralInfoProfile;
