export const headerTableAllotmentConfirmation = [
  'Request Item ID',
  'Accommodation Name',
  'Room Name',
  'Check-in Date',
  'Check-out Date',
  'Must Confirm Before',
  'Action',
];

export const headerTablePendingConfirmation = [
  'Request Item ID',
  'Accommodation Name',
  'Room Name',
  'Check-in Date',
  'Check-out Date',
  'Action',
];

export const headerTablePaymentRequest = [
  'Payment Request No.',
  'Resource Booking No.',
  'Status',
  'Description',
  'Total Amount',
  'Due Date',
  'Action',
];

export const headerTableCustomerPayment = [
  'Booking Code',
  'Tour Name',
  'Company Name',
  'Description',
  'Total Amount',
  'Due Date',
];
