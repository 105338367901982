import * as types from './constant/actionTypes';

const initialState = {
  putNotAllowedCountryForCompanyStatus: '',
  postNotAllowedCountryForCompanyStatus: '',
  getNotAllowedCountryForCountryByCompanyCodeStatus: '',
  getAllNotAllowedCountryForCountryStatus: '',
  putNotAllowedCountryForCompany: [],
  postNotAllowedCountryForCompany: [],
  getNotAllowedCountryForCountryByCompanyCode: [],
  getAllNotAllowedCountryForCountry: [],
  errors: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_NOT_ALLOWED_COUNTRY_FOR_COMPANY:
      return {
        ...state,
        putNotAllowedCountryForCompanyStatus: '',
        postNotAllowedCountryForCompanyStatus: '',
        getNotAllowedCountryForCountryByCompanyCodeStatus: '',
        getAllNotAllowedCountryForCountryStatus: '',
      };

    case types.PUT_NOT_ALLOWED_COUNTRY_FOR_COMPANY:
      return { ...state };
    case types.PUT_NOT_ALLOWED_COUNTRY_FOR_COMPANY_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        putNotAllowedCountryForCompanyStatus: 'failed',
        loading: false,
      };
    case types.PUT_NOT_ALLOWED_COUNTRY_FOR_COMPANY_PENDING:
      return { ...state, loading: true };
    case types.PUT_NOT_ALLOWED_COUNTRY_FOR_COMPANY_FULFILLED:
      return {
        ...state,
        loading: false,
        putNotAllowedCountryForCompanyStatus: 'success',
        putNotAllowedCountryForCompany: action.payload.data,
      };

    case types.POST_NOT_ALLOWED_COUNTRY_FOR_COMPANY:
      return { ...state };
    case types.POST_NOT_ALLOWED_COUNTRY_FOR_COMPANY_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        postNotAllowedCountryForCompanyStatus: 'failed',
      };
    case types.POST_NOT_ALLOWED_COUNTRY_FOR_COMPANY_PENDING:
      return { ...state, loading: true };
    case types.POST_NOT_ALLOWED_COUNTRY_FOR_COMPANY_FULFILLED:
      return {
        ...state,
        loading: false,
        postNotAllowedCountryForCompanyStatus: 'success',
        postNotAllowedCountryForCompany: action.payload.data,
      };

    case types.GET_NOT_ALLOWED_COUNTRY_FOR_COMPANY_BY_COMPANY_CODE:
      return { ...state };
    case types.GET_NOT_ALLOWED_COUNTRY_FOR_COMPANY_BY_COMPANY_CODE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getNotAllowedCountryForCountryByCompanyCodeStatus: 'failed',
      };
    case types.GET_NOT_ALLOWED_COUNTRY_FOR_COMPANY_BY_COMPANY_CODE_PENDING:
      return { ...state, loading: true };
    case types.GET_NOT_ALLOWED_COUNTRY_FOR_COMPANY_BY_COMPANY_CODE_FULFILLED:
      return {
        ...state,
        loading: false,
        getNotAllowedCountryForCountryByCompanyCodeStatus: 'success',
        getNotAllowedCountryForCountryByCompanyCode: action.payload.data,
      };

    case types.GET_ALL_NOT_ALLOWED_COUNTRY_FOR_COMPANY:
      return { ...state };
    case types.GET_ALL_NOT_ALLOWED_COUNTRY_FOR_COMPANY_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getAllNotAllowedCountryForCountryStatus: 'failed',
        loading: false,
      };
    case types.GET_ALL_NOT_ALLOWED_COUNTRY_FOR_COMPANY_PENDING:
      return { ...state, loading: true };
    case types.GET_ALL_NOT_ALLOWED_COUNTRY_FOR_COMPANY_FULFILLED:
      return {
        ...state,
        loading: false,
        getAllNotAllowedCountryForCountryStatus: 'success',
        getAllNotAllowedCountryForCountry: action.payload.data,
      };

    default:
      return state;
  }
};

export default reducer;
