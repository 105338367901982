import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';
import InputBase64 from '../../../../../components/input/inputBase64';

const ModalImage = props => (
  <Modal
    dimmer="blurring"
    closeIcon
    inverted
    open={props.openModal}
    onClose={props.handleClose}
    size="mini"
  >
    <Modal.Header>{props.header}</Modal.Header>
    <Modal.Content>
      <h5>{props.content}</h5>
      {/* <Input type="file" onChange={props.handleChange} /> */}
      <InputBase64
        multiple={true}
        accept="image/jpg, image/jpeg, image/png"
        onDone={props.handleChange}
      />
    </Modal.Content>
    <Modal.Actions>
      <Button positive content="Done" onClick={props.addImage} />
    </Modal.Actions>
  </Modal>
);

ModalImage.propTypes = {
  openModal: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  content: PropTypes.string,
  handleChange: PropTypes.func,
  addImage: PropTypes.func,
};

export default ModalImage;
