import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Segment, Grid, Header, Message, Icon } from 'semantic-ui-react';
import RoomService from './roomService';
import Styles from './styles';
import { connect } from 'react-redux';
class descriptionRoomCard extends Component {
  handleChange = (e, { service }) => {
    let {
      accommodations,
      setAccommodationRoom,
      onClose,
      room,
      closeModalAccommodation,
    } = this.props;
    setAccommodationRoom(accommodations, room, service);
    onClose();
    closeModalAccommodation();
  };

  render() {
    let { desc, service } = this.props;
    let bedOnly = service.find(type => type === 'Bed');
    let withBreakfast = service.find(type => type === 'With_breakfast');
    let halfBoard = service.find(type => type === 'Half_board');
    let fullBoard = service.find(type => type === 'Full_board');
    return (
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column width="16">
              <Header as="h4">Room Description</Header>
              <p style={Styles.noMarginBottom}>{desc}</p>
              {/* <Button style={Styles.transparentButton}>More Description</Button> */}
            </Grid.Column>
            {/* <Grid.Column width="8">
              <Header as="h4">Room Amenities</Header>
              {amenities
                ? amenities.map((data, index) => (
                    <p key={index} style={Styles.noMarginBottom}>
                      {data.Name}
                    </p>
                  ))
                : null}
              
            </Grid.Column> */}
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="16" style={Styles.marginBottomfifteen}>
              <Header as="h4">Room Service</Header>
            </Grid.Column>
            <Grid.Column width="16">
              <Message warning style={{ border: '0.3px solid #e6ca6b' }}>
                <p>
                  <Icon
                    circular
                    name="info"
                    style={{ backgroundColor: '#e6ca6b', color: 'white' }}
                  />
                  Choose room service what do you want.
                </p>
              </Message>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <RoomService
              name="Room Only"
              disable={!bedOnly}
              service={bedOnly}
              handleChange={this.handleChange}
            />
            <RoomService
              name="With Breakfast"
              disable={!withBreakfast}
              service={withBreakfast}
              handleChange={this.handleChange}
            />
            <RoomService
              name="Half Board"
              disable={!halfBoard}
              service={halfBoard}
              handleChange={this.handleChange}
            />
            <RoomService
              name="Full Board"
              disable={!fullBoard}
              service={fullBoard}
              handleChange={this.handleChange}
            />
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
const mapStateToProps = state => {
  return {
    accommodations: state.AccomodationStore.listAccommodationsWithFilter,
  };
};
descriptionRoomCard.propTypes = {
  desc: PropTypes.string,
  amenities: PropTypes.string,
  service: PropTypes.array,
  room: PropTypes.object,
  HeadlineProgram: PropTypes.object,
  accommodations: PropTypes.array,
  onClose: PropTypes.func,
  closeModalAccommodation: PropTypes.func,
  setAccommodationRoom: PropTypes.func,
};

export default connect(mapStateToProps)(descriptionRoomCard);
