import { convertDateFormat } from '../../../../components/genericFunction/moment'; //src\components\genericFunction\moment.js
import moment from 'moment';
const today = moment().format('DD MMMM YYYY');
const setDataDashboard = data => {
  let optionsData = data.map(data => {
    return {
      RequestItemId: data.RequestItemId ? data.RequestItemId : null,
      ConfirmationNumber: data.ConfirmationNumber
        ? data.ConfirmationNumber
        : null,
      Text: data.Text ? data.Text : null,
      CheckInDate: data.CheckInDate
        ? convertDateFormat(data.CheckInDate, 'DD MMMM YYYY')
        : null,
      CheckOutDate: data.CheckOutDate
        ? convertDateFormat(data.CheckOutDate, 'DD MMMM YYYY')
        : null,
      ExpiredDate: data.ExpiredDate
        ? convertDateFormat(data.ExpiredDate, 'DD MMMM YYYY')
        : null,
      Qty: data.Qty ? data.Qty.toString() : null,
      ConfirmationNote: data.ConfirmationNote ? data.ConfirmationNote : null,
      AccommodationItemType: data.AccommodationItemType
        ? data.AccommodationItemType
        : null,
      FirstName: data.FirstName ? data.FirstName : null,
      LastName: data.LastName ? data.LastName : null,
      TotalNights: data.TotalNights
        ? data.TotalNights.toString() + ` Night`
        : null,
      NightLeft: data.CheckOutDate
        ? moment(convertDateFormat(data.CheckOutDate, 'DD MMMM YYYY'))
            .diff(today, 'days')
            .toString() + ` Night`
        : null,
      BookingNo: data.BookingNo ? data.BookingNo : null,
      AccommodationName: data.AccommodationName ? data.AccommodationName : null,
    };
  });
  return optionsData;
};

export default setDataDashboard;
