// import { convertDateFormat } from '../../../../../scripts/moment';
import moment from 'moment';
const setDataPriceDetail = state => {
  let data = {};
  // state.map(price => {
  //   data = {
  //     Id: price.Id,
  //     Type: price.ServiceType,
  //     ServiceDescription: price.CalculationBase.Name,
  //     Description: price.CalculationBase.Name,
  //     MinPax: price.CalculationBase.Code,
  //     ServiceItemId: price.ServiceItemId,
  //     Currency: price.Currency,
  //     Cost: price.Cost,
  //     StartDate: moment(price.StartDate).format('YYYY-MM-DD'),
  //     EndDate: moment(price.EndDate).format('YYYY-MM-DD'),
  //     CutOffDay: price.CutOffDay,
  //     UomId: 'unt',
  //     Rank: 1,
  //     MinimumPax: 0,
  //     TaxTypeId: 0,
  //   };
  // });
  data = {
    Id: state.Id,
    Type: state.ServiceType,
    ServiceDescription: state.CalculationBase.Name,
    Description: state.CalculationBase.Name,
    MinPax: state.CalculationBase.Code,
    ServiceItemId: state.ServiceItemId,
    Currency: state.Currency,
    Cost: state.Cost,
    StartDate: moment(state.StartDate).format('YYYY-MM-DD'),
    EndDate: moment(state.EndDate).format('YYYY-MM-DD'),
    CutOffDay: state.CutOffDay,
    UomId: 'unt',
    Rank: 1,
    MinimumPax: 0,
    TaxTypeId: 0,
  };
  return data;
};

export default setDataPriceDetail;
