import React from 'react';
import PropTypes from 'prop-types';
// import AccommodationAvailability from './../components/segment/segmentAccommodationAvailbility';
import SegmentAccommodationAvailability from '../../../home/component/SegmentAccommodationAvailbility';
class AccommodationAvailability extends React.Component {
  render() {
    let { accommodationId } = this.props.match.params;
    return (
      <div>
        <SegmentAccommodationAvailability id={accommodationId} />
      </div>
    );
  }
}
AccommodationAvailability.propTypes = {
  match: PropTypes.object,
};
export default AccommodationAvailability;
