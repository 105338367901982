import React from 'react';
import { Form, Checkbox, Grid } from 'semantic-ui-react';
import Input from '../../../components/input/inputWithLabel';
import PropTypes from 'prop-types';

const EmailSettingForm = props => {
  const { emailSettingObj, errors, handleChange } = props;
  return (
    <Form>
      <Input
        inline={false}
        name="EmailAddress"
        type="text"
        label="Email Address"
        placeholder="Email Address"
        value={emailSettingObj.EmailAddress}
        handleChange={handleChange}
        required
        validate={errors}
        disabled={props.disable}
      />
      <Form.Field>
        <Grid>
          <Grid.Row>
            <Grid.Column width="13">
              This email type is recipient only?
            </Grid.Column>
            <Grid.Column width="3" textAlign="center">
              <Checkbox
                defaultChecked={emailSettingObj.IsRecipientOnly}
                value={emailSettingObj.IsRecipientOnly}
                onChange={handleChange}
                name="IsRecipientOnly"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form.Field>
      <Input
        inline={false}
        name="SmtpClient"
        type="text"
        label="SMTP Client"
        placeholder="SMTP Client"
        value={emailSettingObj.SmtpClient}
        handleChange={handleChange}
        required
        validate={errors}
        disabled={props.disable}
      />
      <Input
        inline={false}
        name="Port"
        type="text"
        label="Port"
        placeholder="Port"
        value={emailSettingObj.Port}
        handleChange={handleChange}
        required
        validate={errors}
        disabled={props.disable}
      />
      <Input
        inline={false}
        name="Credential"
        type="password"
        label="Credential"
        placeholder="Credential"
        value={emailSettingObj.Credential}
        handleChange={handleChange}
        required
        validate={errors}
        disabled={props.disable}
      />
    </Form>
  );
};
EmailSettingForm.propTypes = {
  emailSettingObj: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  disable: PropTypes.bool,
};
export default EmailSettingForm;
