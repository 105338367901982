import {
  GET_TRANSACTION,
  RESET_STATUS_TRANSACTION,
  GET_TRANSACTION_BY_STATUS_COMPLETE_LIST,
  GET_TRANSACTION_BY_PERIODE,
  GET_TRANSACTION_BY_ID,
  GET_TRANSACTION_PAYABLE,
  GET_TOUR_PAX_TYPE,
  GET_TOUR_TYPE,
  POST_DEMO_MANUAL_PAYMENT,
  GET_PAYABLE_SUPPLEMENT_ITEM,
  GET_TRANSACTION_PAYMENT_TERM_BY_TRANSACTION_ID,
  POST_TOUR_TRANSACTION_CONFIRMATION,
  GET_INVOICE_STATUS_USING_PAYMENT_GATEWAY,
  GET_SPECIAL_ADJUSMENT_BY_TRANSACTION_ID,
  GET_TOUR_TRANSACTION_BILLED_BY_TRANSACTION_ID,
  GET_TOUR_TRANSACTION_BILLS_BY_TRANSACTION_ID,
  GET_TRANSACTION_ALL_BY_STATUS_AFTER_DP_LIST,
  GET_TRANSACTION_ALL_BY_STATUS_ON_HOLD_LIST,
  GET_TRANSACTION_ALL_BY_STATUS_CANCEL_LIST,
  GET_TRANSACTION_ALL_BY_STATUS_COMPLETE_LIST,
  GET_TRANSACTION_ALL_BY_STATUS_CREATED_LIST,
  GET_TRANSACTION_BY_STATUS_CANCEL_LIST,
  POST_SPECIAL_ADJUSMENT,
  EDIT_SPECIAL_ADJUSMENT,
  DELETE_SPECIAL_ADJUSMENT,
  GET_SCHEDULE_TOUR_TRANSACTION,
  GET_INVOICE_BY_TRANSACTION_ID,
  GET_TRANSACTION_ALL_BY_STATUS,
  GET_GROUP_TYPE_LIST,
  GET_INVOICE_BY_COMPANY_CODE,
  GET_PRINT_PAYMENT_RECEIPT,
  POST_EDIT_QUOTATION,
  GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID,
  GET_TOUR_OPERATOR_PROFILE_BY_ID,
  POST_JOIN_TOUR_ON_BE_HALF,
  GET_LIST_TOUR_OPERATOR,
  POST_DEMO_PRICE,
  POST_CREATE_TOUR_TRANSACTION,
  POST_DEMO_JOIN_TOUR,
  GET_PAYMENT_RECEIPT_BY_TRANSACTION_ID,
  GET_TRANSACTION_INVOICE_WITH_STATUS,
  POST_COMMISSION_PAYMENT,
  GET_TOUR_TRANSACTIONS_COMMISSION_PAYMENT,
  POST_RESEND_DATA_TOUR_TRANSACTION,
  GET_DOWNLOAD_SCHEDULE_BY_TRANSACTION_ID,
  // GET_DOWNLOAD_BROCHURE_BY_TRANSACTION_ID,
  GET_INVOICE_HISTORY_BY_TRANSACTION_ID,
  POST_ADDITIONAL_ITEMS,
  RESET_ERROR_MESSAGE,
  POST_EMAIL_SEND_CONFIRMATION,
  // GET_RESOURCE_BOOKING_BY_PERIODE,
  GET_RESOURCE_BOOKING_LIST_URL,
  PUT_PAY_RESOURCE_BOOKING_URL,
  GET_TRANSACTION_HISTORY_BY_TRANSACTION,
} from './actionTypes';
//import getBookingConfirmationApi from '../api/getBookingConfirmationApi';

import getTransactionByStatusActionCompleteApi from '../api/getTransactionHistoryByStatusApi';
import getTransactionByStatusActionCancelApi from '../api/getTransactionHistoryByStatusApi';
import getTransactionByStatusActionAfterDPApi from '../api/getTransactionHistoryByStatusApi';
//import getTransactionByStatusInPaymentApi from '../api/getTransactionHistoryByStatusApi';
import getTransactionByPeriodeApi from '../api/getTransactionByPeriodeApi';
import getTransactionByIdApi from '../api/getTransactionByIdApi';
import getTransactionPayableApi from '../api/getTransactionPayableApi';
import getTourTypeApi from '../api/getTourTypeApi';
import getTourPaxTypeApi from '../api/getTourPaxTypeApi';
import postManualPaymentApi from '../api/manualPayment/postManualPaymentApi';
import postDemoManualPaymentApi from '../api/manualPayment/postDemoManualPaymentApi';
import getPayableSupplemetItemApi from '../api/manualPayment/getPayableSupplemetItemApi';
import getPaymentTermByTransactionIdApi from '../api/manualPayment/getPaymentTermByTransactionIdApi';
import postTourTransactionConfirmationApi from '../api/manualPayment/postTourTransactionConfirmationApi';
import getInvoiceStatusUsingPaymentGatewayApi from '../api/manualPayment/getInvoiceStatusUsingPaymentGatewayApi';
import getSpecialAdjusmentByTransactionIdApi from '../api/manualPayment/getSpecialAdjusmentByTransactionIdApi';
import getTourTransactionBilledApi from '../api/manualPayment/getTourTransactionBilledApi';
import getTourTransactioBillsApi from '../api/manualPayment/getTourTransactioBillsApi';

//=================================
import getTransactionAllByStatusOnHoldApi from '../api/getTransactionAllByStatusApi';
//import getTransactionAllByStatusDownPaymentApi from '../api/getTransactionAllByStatusApi';
import getTransactionAllByStatusCompleteApi from '../api/getTransactionAllByStatusApi';
import getTransactionAllByStatusCancelApi from '../api/getTransactionAllByStatusApi';
import getTransactionAllByStatusAfterDPApi from '../api/getTransactionAllByStatusApi';
import getTransactionAllByStatusCreatedApi from '../api/getTransactionAllByStatusApi';
import getTransactionAllByStatusApi from '../api/getTransactionAllByStatusApi';
import postSpecialAdjsumentApi from '../api/manualPayment/postSpecialAdjsumentApi';
import {
  editSpecialAdjsumentApi,
  postAdditionalItemsApi,
} from '../api/manualPayment/specialAdjusmentApi';
import deleteSpecialAdjusmentApi from '../api/manualPayment/deleteSpecialAdjusmentApi';
import getScheduleTourTransactionApi from '../api/tourTransaction/getScheduleTourTransactionApi';
import getInvoiceByTransactionIdApi from '../api/tourTransaction/getInvoiceByTransactionIdApi';
import postCreateTourTransactionApi from '../api/tourTransaction/postCreateTourTransactionApi';
import {
  getGroupTypeListApi,
  postEmailSendConfirmationApi,
  getTransactionHistoryByOperationApi,
  getTransactionHistoryByTransactionApi,
} from '../api/transactionApi';
import getInvoiceByCompanyCodeApi from '../api/tourTransaction/getInvoiceByCompanyCodeApi';
import getPrintPaymentReceiptTransactionApi from '../api/tourTransaction/getPrintPaymentReceiptTransactionApi';

import postEditQuotationApi from '../api/tourTransaction/postEditQuotationApi';
// import getTourTransactionsCommissionPaymentApi from '../api/tourTransaction/getTourTransactionsCommissionPaymentApi';

import {
  getSupplementItemByFixedPackageIdApi,
  postDemoJoinTourApi,
  getTourTransactionsCommissionPaymentApi,
} from './../api/tourTransaction/tourTransactionApi';
import {
  getTourOperatorByIdApi,
  getListTourOperatorApi,
} from '../api/tourTransaction/getTourOperatorByIdApi';
import {
  // getSupplementItemByFixedPackageIdApi,
  postJoinTourOnBehalfApi,
  postDemoPriceApi,
  getTransactionInvoiceWithStatusApi,
  postCommissionPaymentApi,
} from './../api/tourTransaction/tourTransactionApi';
import getPaymentReceiptByTransactionIdApi from '../api/tourTransaction/getPaymentReceiptByTransactionIdApi';

//resend tour transaction from data transfer logs
import postResendDataTourTransactionApi from '../api/tourTransaction/postResendDataTourTransactionApi';
import getDownloadScheduleByTransactionIdApi from '../api/tourTransaction/getDownloadScheduleByTransactionId';
//import getDownloadBrochureByTransactionIdApi from '../api/tourTransaction/getDownloadBrochureByTransactionId';
import getInvoiceHistoryByTransactionIdApi from '../api/tourTransaction/getInvoiceHistoryByTransactionIdApi';
import getDownloadVoucherConfirmationByTransactionIdApi from '../api/tourTransaction/getDownloadVoucherConfirmationByTransactionId';
import { getResourceBookingListApi } from '../api/getResourceBookingListApi';

const getTransactionByStatusActionAfterDPAction = (
  start,
  end,
  createStart,
  createEnd,
  status
) => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSACTION,
      payload: getTransactionByStatusActionAfterDPApi(
        start,
        end,
        createStart,
        createEnd,
        status
      ),
    });
  };
};
const getTransactionHistoryByOperation = (
  start,
  end,
  createStart,
  createEnd,
  status
) => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSACTION,
      payload: getTransactionHistoryByOperationApi(
        start,
        end,
        createStart,
        createEnd,
        status
      ),
    });
  };
};
const getTransactionByPeriodeAction = (start, end, createStart, createEnd) => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSACTION_BY_PERIODE,
      payload: getTransactionByPeriodeApi(start, end, createStart, createEnd),
    });
  };
};

const getTransactionInvoiceWithStatus = (
  start,
  end,
  createStart,
  createEnd,
  status
) => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSACTION_INVOICE_WITH_STATUS,
      payload: getTransactionInvoiceWithStatusApi(
        start,
        end,
        createStart,
        createEnd,
        status
      ),
    });
  };
};

const getTransactionByIdAction = id => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSACTION_BY_ID,
      payload: getTransactionByIdApi(id),
    });
  };
};
const resetStatusTransactionAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_STATUS_TRANSACTION,
    });
  };
};

const postDemoManualPaymentAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_DEMO_MANUAL_PAYMENT,
      payload: postDemoManualPaymentApi(data),
    });
  };
};

const postManualPaymentAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_DEMO_MANUAL_PAYMENT,
      payload: postManualPaymentApi(data),
    });
  };
};

const getTransactionByStatusCompleteAction = (
  start,
  end,
  createStart,
  createEnd,
  status
) => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSACTION_BY_STATUS_COMPLETE_LIST,
      payload: getTransactionByStatusActionCompleteApi(
        start,
        end,
        createStart,
        createEnd,
        status
      ),
    });
  };
};

const getTransactionByStatusActionCancelAction = (
  start,
  end,
  createStart,
  createEnd,
  status
) => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSACTION_BY_STATUS_CANCEL_LIST,
      payload: getTransactionByStatusActionCancelApi(
        start,
        end,
        createStart,
        createEnd,
        status
      ),
    });
  };
};

const getInvoiceStatusUsingPaymentGatewayAction = id => {
  return dispatch => {
    return dispatch({
      type: GET_INVOICE_STATUS_USING_PAYMENT_GATEWAY,
      payload: getInvoiceStatusUsingPaymentGatewayApi(id),
    });
  };
};

const getTrasnsactionPayable = id => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSACTION_PAYABLE,
      payload: getTransactionPayableApi(id),
    });
  };
};
const getTourTypeAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_TOUR_TYPE,
      payload: getTourTypeApi,
    });
  };
};

const getTourPaxTypeAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_TOUR_PAX_TYPE,
      payload: getTourPaxTypeApi,
    });
  };
};

const getPayableSupplemetItemAction = id => {
  return dispatch => {
    return dispatch({
      type: GET_PAYABLE_SUPPLEMENT_ITEM,
      payload: getPayableSupplemetItemApi(id),
    });
  };
};

const getPaymentTermByTransactionIdAction = id => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSACTION_PAYMENT_TERM_BY_TRANSACTION_ID,
      payload: getPaymentTermByTransactionIdApi(id),
    });
  };
};

const postConfirmTransaction = data => {
  return dispatch => {
    return dispatch({
      type: POST_TOUR_TRANSACTION_CONFIRMATION,
      payload: postTourTransactionConfirmationApi(data),
    });
  };
};

const getSpecialAdjusmentByTransactionIdAction = id => {
  return dispatch => {
    return dispatch({
      type: GET_SPECIAL_ADJUSMENT_BY_TRANSACTION_ID,
      payload: getSpecialAdjusmentByTransactionIdApi(id),
    });
  };
};

const getTourTransactionBilledAction = id => {
  return dispatch => {
    return dispatch({
      type: GET_TOUR_TRANSACTION_BILLED_BY_TRANSACTION_ID,
      payload: getTourTransactionBilledApi(id),
    });
  };
};

const getTourTransactioBillsAction = id => {
  return dispatch => {
    return dispatch({
      type: GET_TOUR_TRANSACTION_BILLS_BY_TRANSACTION_ID,
      payload: getTourTransactioBillsApi(id),
    });
  };
};

const getTransactionAllByStatusAfterDPAction = status => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSACTION_ALL_BY_STATUS_AFTER_DP_LIST,
      payload: getTransactionAllByStatusAfterDPApi(status),
    });
  };
};

const getTransactionAllByStatusOnHoldAction = status => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSACTION_ALL_BY_STATUS_ON_HOLD_LIST,
      payload: getTransactionAllByStatusOnHoldApi(status),
    });
  };
};
const getTransactionAllByStatusCancelAction = status => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSACTION_ALL_BY_STATUS_CANCEL_LIST,
      payload: getTransactionAllByStatusCancelApi(status),
    });
  };
};
const getTransactionAllByStatusCompleteAction = status => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSACTION_ALL_BY_STATUS_COMPLETE_LIST,
      payload: getTransactionAllByStatusCompleteApi(status),
    });
  };
};
const getTransactionAllByStatusCreatedAction = status => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSACTION_ALL_BY_STATUS_CREATED_LIST,
      payload: getTransactionAllByStatusCreatedApi(status),
    });
  };
};
const getTransactionAllByStatus = status => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSACTION_ALL_BY_STATUS,
      payload: getTransactionAllByStatusApi(status),
    });
  };
};
const postSpecialAdjusmentAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_SPECIAL_ADJUSMENT,
      payload: postSpecialAdjsumentApi(data),
    });
  };
};

const editSpecialAdjsumentAction = data => {
  return dispatch => {
    return dispatch({
      type: EDIT_SPECIAL_ADJUSMENT,
      payload: editSpecialAdjsumentApi(data),
    });
  };
};
const deleteSpecialAdjusmentAction = data => {
  return dispatch => {
    return dispatch({
      type: DELETE_SPECIAL_ADJUSMENT,
      payload: deleteSpecialAdjusmentApi(data),
    });
  };
};

const getScheduleTourTransactionAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_SCHEDULE_TOUR_TRANSACTION,
      payload: getScheduleTourTransactionApi(Id),
    });
  };
};

const getInvoiceByTransactionIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_INVOICE_BY_TRANSACTION_ID,
      payload: getInvoiceByTransactionIdApi(Id),
    });
  };
};
const getGroupTypeList = () => {
  return dispatch => {
    return dispatch({
      type: GET_GROUP_TYPE_LIST,
      payload: getGroupTypeListApi(),
    });
  };
};

const getInvoiceByCompanyCodeAction = id => {
  return dispatch => {
    return dispatch({
      type: GET_INVOICE_BY_COMPANY_CODE,
      payload: getInvoiceByCompanyCodeApi(id),
    });
  };
};

const getTourTransactionsCommissionPaymentAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_TOUR_TRANSACTIONS_COMMISSION_PAYMENT,
      payload: getTourTransactionsCommissionPaymentApi(Id),
    });
  };
};

const getPrintPaymentReceiptTransactionAction = (transactionId, code) => {
  return dispatch => {
    return dispatch({
      type: GET_PRINT_PAYMENT_RECEIPT,
      payload: getPrintPaymentReceiptTransactionApi(transactionId, code),
    });
  };
};

const postEditQuotationAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: POST_EDIT_QUOTATION,
      payload: postEditQuotationApi(id, data),
    });
  };
};

const getSupplementItemByFixedPackageIdAction = id => {
  return dispatch => {
    return dispatch({
      type: GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID,
      payload: getSupplementItemByFixedPackageIdApi(id),
    });
  };
};

const getTourOperatorByIdAction = id => {
  return dispatch => {
    return dispatch({
      type: GET_TOUR_OPERATOR_PROFILE_BY_ID,
      payload: getTourOperatorByIdApi(id),
    });
  };
};

const getListTourOperatorAction = data => {
  return dispatch => {
    return dispatch({
      type: GET_LIST_TOUR_OPERATOR,
      payload: getListTourOperatorApi(data),
    });
  };
};

const postJoinTourOnBehalf = (id, data, type) => {
  return dispatch => {
    return dispatch({
      type: POST_JOIN_TOUR_ON_BE_HALF,
      payload: postJoinTourOnBehalfApi(id, data, type),
    });
  };
};

const postDemoPrice = data => {
  return dispatch => {
    return dispatch({
      type: POST_DEMO_PRICE,
      payload: postDemoPriceApi(data),
    });
  };
};

const postCreateTourTransactionAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_CREATE_TOUR_TRANSACTION,
      payload: postCreateTourTransactionApi(data),
    });
  };
};

const postDemoJoinTourAction = (id, data, type) => {
  return dispatch => {
    return dispatch({
      type: POST_DEMO_JOIN_TOUR,
      payload: postDemoJoinTourApi(id, data, type),
    });
  };
};

const getPaymentReceiptByTransactionIdAction = id => {
  return dispatch => {
    return dispatch({
      type: GET_PAYMENT_RECEIPT_BY_TRANSACTION_ID,
      payload: getPaymentReceiptByTransactionIdApi(id),
    });
  };
};
const postCommissionPayment = data => {
  return dispatch => {
    return dispatch({
      type: POST_COMMISSION_PAYMENT,
      payload: postCommissionPaymentApi(data),
    });
  };
};
const postResendDataTourTransactionAction = Id => {
  return dispatch => {
    return dispatch({
      type: POST_RESEND_DATA_TOUR_TRANSACTION,
      payload: postResendDataTourTransactionApi(Id),
    });
  };
};

const getDownloadScheduleByTransactionIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_DOWNLOAD_SCHEDULE_BY_TRANSACTION_ID,
      payload: getDownloadScheduleByTransactionIdApi(Id),
    });
  };
};

// const getDownloadBrochureTransactionIdAction = (
//   tourTransactionId,
//   companyCode
// ) => {
//   return dispatch => {
//     return dispatch({
//       type: GET_DOWNLOAD_BROCHURE_BY_TRANSACTION_ID,
//       payload: getDownloadBrochureByTransactionIdApi(
//         tourTransactionId,
//         companyCode
//       ),
//     });
//   };
// };

const getInvoiceHistoryByTransactionIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_INVOICE_HISTORY_BY_TRANSACTION_ID,
      payload: getInvoiceHistoryByTransactionIdApi(Id),
    });
  };
};

const getDownloadVoucherConfirmationByTransactionIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_DOWNLOAD_SCHEDULE_BY_TRANSACTION_ID,
      payload: getDownloadVoucherConfirmationByTransactionIdApi(Id),
    });
  };
};

const postAdditionalItems = data => {
  return dispatch => {
    return dispatch({
      type: POST_ADDITIONAL_ITEMS,
      payload: postAdditionalItemsApi(data),
    });
  };
};

const resetErrorMessageAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_ERROR_MESSAGE,
    });
  };
};

const postEmailSendConfirmation = data => {
  return dispatch => {
    return dispatch({
      type: POST_EMAIL_SEND_CONFIRMATION,
      payload: postEmailSendConfirmationApi(data),
    });
  };
};

// const getResourceBookingByPeriodeAction = (
//   start,
//   end,
//   createStart,
//   createEnd
// ) => {
//   return dispatch => {
//     return dispatch({
//       type: GET_RESOURCE_BOOKING_BY_PERIODE,
//       payload: getResourceBookingByPeriodeApi(
//         start,
//         end,
//         createStart,
//         createEnd
//       ),
//     });
//   };
// };

const getResourceBookingListAction = id => {
  return dispatch => {
    return dispatch({
      type: GET_RESOURCE_BOOKING_LIST_URL,
      payload: getResourceBookingListApi(id),
    });
  };
};

const putPayResourceBooking = (id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_PAY_RESOURCE_BOOKING_URL,
      payload: putPayResourceBooking(id, data),
    });
  };
};

const getTransactionHistoryByTransaction = id => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSACTION_HISTORY_BY_TRANSACTION,
      payload: getTransactionHistoryByTransactionApi(id),
    });
  };
};
export {
  getTransactionByStatusActionAfterDPAction,
  resetStatusTransactionAction,
  getTransactionByStatusCompleteAction,
  getTransactionByPeriodeAction,
  getTrasnsactionPayable,
  getTourTypeAction,
  getTourPaxTypeAction,
  getTransactionByIdAction,
  postDemoManualPaymentAction,
  postManualPaymentAction,
  getPayableSupplemetItemAction,
  getPaymentTermByTransactionIdAction,
  postConfirmTransaction,
  getInvoiceStatusUsingPaymentGatewayAction,
  getSpecialAdjusmentByTransactionIdAction,
  getTourTransactioBillsAction,
  getTourTransactionBilledAction,
  getTransactionAllByStatusAfterDPAction,
  getTransactionAllByStatusOnHoldAction,
  getTransactionAllByStatusCancelAction,
  getTransactionAllByStatusCompleteAction,
  getTransactionAllByStatusCreatedAction,
  getTransactionByStatusActionCancelAction,
  postSpecialAdjusmentAction,
  deleteSpecialAdjusmentAction,
  getScheduleTourTransactionAction,
  getInvoiceByTransactionIdAction,
  getTransactionAllByStatus,
  getGroupTypeList,
  getInvoiceByCompanyCodeAction,
  getPrintPaymentReceiptTransactionAction,
  postEditQuotationAction,
  getSupplementItemByFixedPackageIdAction,
  getTourOperatorByIdAction,
  postJoinTourOnBehalf,
  getListTourOperatorAction,
  postDemoPrice,
  postCreateTourTransactionAction,
  postDemoJoinTourAction,
  getPaymentReceiptByTransactionIdAction,
  getTransactionInvoiceWithStatus,
  postCommissionPayment,
  getTourTransactionsCommissionPaymentAction,
  postResendDataTourTransactionAction,
  getDownloadScheduleByTransactionIdAction,
  // getDownloadBrochureTransactionIdAction,
  getInvoiceHistoryByTransactionIdAction,
  getDownloadVoucherConfirmationByTransactionIdAction,
  editSpecialAdjsumentAction,
  postAdditionalItems,
  resetErrorMessageAction,
  postEmailSendConfirmation,
  // getResourceBookingByPeriodeAction,
  getResourceBookingListAction,
  getTransactionHistoryByOperation,
  putPayResourceBooking,
  getTransactionHistoryByTransaction,
};
