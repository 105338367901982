import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Segment, Input, Button } from 'semantic-ui-react';
import HeaderTitle from '../../../shared/components/common/headerSegment/HeaderTitle';
import TableAdditionalCostList from './table/TableAdditionalCostList';
import Pagination from '../../../shared/components/common/pagination/Pagination';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../../scripts/pagination';
import { searchFilterAdditionalCostList } from '../services/filterData';

const AdditionalCostList = props => {
  // ------------------------- Initial -------------------------
  const {
    data,
    handleAdd,
    activePage,
    pageChange,
    searchInput,
    handleChangeSearch,
    handleEdit,
    handleDelete,
    loading,
  } = props;

  // ------------------------- variable / const -------------------------
  const filterData = searchFilterAdditionalCostList(
    data ? data : [],
    searchInput
  );
  const pagedData = datasetPagination(filterData, 7);
  const listData = pagedData[activePage - 1];
  const { numbersFrom, numbersTo } = getNumberFromTo(listData, activePage, 7);

  return (
    <>
      <Grid columns="equal" style={{ marginTop: '30px' }}>
        <Grid.Row stretched>
          <Grid.Column>
            <Segment loading={loading}>
              <HeaderTitle title="Additional Cost" />
              <Grid>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Input
                      icon="search"
                      placeholder="search"
                      name="searchInput"
                      value={searchInput}
                      onChange={handleChangeSearch}
                    />
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <Button
                      floated="right"
                      positive
                      icon="add"
                      content="Add"
                      onClick={handleAdd}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <TableAdditionalCostList
                listData={listData}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
              />
              {data ? (
                <Pagination
                  pagedData={pagedData}
                  numbersTo={numbersTo}
                  numbersFrom={numbersFrom}
                  data={data}
                  pageChange={pageChange}
                  activePage={activePage}
                />
              ) : (
                ''
              )}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

AdditionalCostList.propTypes = {
  data: PropTypes.array,
  handleAdd: PropTypes.func,
  activePage: PropTypes.number,
  pageChange: PropTypes.func,
  searchInput: PropTypes.string,
  handleChangeSearch: PropTypes.func,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  loading: PropTypes.bool,
};

export default React.memo(AdditionalCostList);
