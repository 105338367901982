// import moment from 'moment';
import {
  generateInitialDailyProgram,
  setMovementAirportToDailyProgram,
  setMovementConnectionFlight,
  setMovementCheckoutinAccomodasi,
  setMovementVirtualAccommodation,
  setMovementDriving,
  setMovementDayStartDayEnd,
  setMovementFreetimeLocked,
  getActivityOldDailyProgram,
  setReturnsDate,
  fixInitialActivityDestinationName,
  fixFlightPlaceDailyProgram,
  copyDailyProgram,
  generateFirstLastFlights,
} from './itineraryBuilder';
import { setTourGuideInDailyProgram } from '../../components/genericFunction/tourGuide';

import {
  SumDays,
  changeTime,
  getHour,
  SumSecond,
  convertToStringDate,
  convertToStringTime,
  getNumberOfDays,
  SubstractSecond,
  getNumberOfSecond,
  getTime,
  SubDays,
  convertDateFormat,
} from './timeHelper';
import { diffDate } from './../moment';
import {
  EAT,
  RECREATION,
  FREETIME,
} from '../../pages/shared/constants/activityTypes';
export class Guest {
  constructor(GuestCategory) {
    this.FirstName = null;
    this.LastName = null;
    this.CountryId = null;
    this.IdentityNbr = null;
    this.IdentityType = null;
    this.GuestType = 'TOURMEMBER';
    this.GuestCategory = GuestCategory;
    this.GuestTitle = null;
  }
}
export const DailyProgram = {
  Date: null,
  Day: null,
  Movements: [],
  TourDestinations: [],
};

export const ItemMov = {
  Address: null,
  CityId: null,
  Desc: null,
  ImageName: null,
  ImageUrl: null,
  Name: null,
  PlaceId: null,
  ProfileDesc: null,
  ServiceItemId: null,
  ServiceType: null,
  OperationStartTime: null,
  OperationEndTime: null,
  IsSolidStartTime: false,
  MapsString: null,
};
export const Movement = {
  DateTime: null,
  Destination: null,
  DestinationName: null,
  Duration: 0,
  DurationText: '',
  Id: null,
  Item: Object.assign({}, ItemMov),
  MovementName: null,
  MovementDescription: null,
  Note: null,
  SeqNumber: null,
  ServiceItemId: null,
  TypeMovement: true,
  TypeVirtual: false,
};
export const Airport = {
  Address: null,
  City: { Name: null },
  Date: null,
  Day: null,
  Note: null,
  Place: null,
  PlaceId: null,
  PlaceType: null,
  Region: null,
  TransferType: '',
  NextDay: false,
};
export const SummaryProgram = {
  AccommodationSummary: {
    CheckIn: null,
    CheckOut: null,
    Address: null,
    AccommodationProfileId: null,
    Allocations: {
      ChildExtraBedPax: 0,
      ChildSharingRoomPax: 0,
      ChildSingleRoomPax: 0,
      ExtraBedPax: 0,
      NoBedPax: 0,
      SharingBedPax: 0,
      SharingRoomPax: 0,
      SingleRoomPax: 0,
    },
    DateTime: null,
    Name: null,
    RoomName: null,
    RoomService: null,
    RoomType: null,
    SeqNumber: null,
    ServiceItemId: null,
    ImageUrl: null,
  },
  City: { Name: null },
  Date: null,
  Day: null,
  LeavingDate: null,
  Note: null,
  Region: null,
  TotalDays: 2,
  NextDay: false,
};

export const copyObject = par => {
  let temp = JSON.stringify(par);
  return JSON.parse(temp);
};

//untuk custom and ready
//item : untuk custom masukan array airport, untuk custom masukan object airport arrival
//city : masukan object kota yg berisi region (bisa filter dari balikan API)
//day : masukan int hari ke-
//lastDateSP : masukan Leaving Date object terakhir Summarry program  untuk departure pada ready program
export const setInitialAirport = (item, city, day, type, date, status) => {
  item = copyObject(item);
  let typeMov = type === 'Arrival' ? 'Movement_arrival' : 'Movement_departure';
  let air = copyObject(Airport);
  air.City = city && city;
  air.Region = city && city.Region.Id;
  air.Day = day;
  air.PlaceType = 'Airport';
  air.TransferType = typeMov;
  air.NextDay = false;
  if (status === 'Custom') {
    air.Date =
      type === 'Arrival'
        ? convertToStringDate(SumDays(date, 1)) + 'T10:00:00'
        : convertToStringDate(changeTime(date)) + 'T14:00:00';
    // } else if (status === 'Ready' || status === 'Similar') {
  } else if (
    status === 'Ready' ||
    status === 'Similar' ||
    status === 'FixedDateVariable'
  ) {
    air.Date =
      type === 'Arrival'
        ? convertToStringDate(SumDays(date, 1)) + convertToStringTime(item.Date)
        : convertToStringDate(changeTime(date)) +
          convertToStringTime(item.Date);
  } else if (status === 'Quotation') {
    air.Date = item.Date;
  } else {
    air.Date =
      type === 'Arrival' ? SumSecond(date, 7200) : SumSecond(date, 7200);
  }

  if (item != null)
    if (item.length > 0) {
      air.Address = item[0].Address;
      air.Place = item[0].Name;
      air.PlaceId = item[0].Id;
    } else if (item.length === undefined) {
      air.Address = item.Address;
      air.Place = item.Place;
      air.PlaceId = item.PlaceId;
    }
  return air;
};

//untuk setup summary program custom
//city : masukan object kota yg berisi region (bisa filter dari balikan API)
//room : berisi object room allocation
export const setSummaryProgram = (city, room) => {
  let date = new Date();
  let SP = copyObject(SummaryProgram);
  SP.City = city && city;
  SP.Date = convertToStringDate(SumDays(date, 1)) + 'T00:00:00';
  SP.Day = 1;
  SP.LeavingDate = convertToStringDate(SumDays(date, 2)) + 'T00:00:00';
  SP.Region = city ? city.Region.Id : null;
  SP.AccommodationSummary.DateTime = SP.Date;
  SP.AccommodationSummary.CheckIn =
    convertToStringDate(SumDays(date, 1)) + 'T14:00:00';
  SP.AccommodationSummary.CheckOut =
    convertToStringDate(SumDays(date, 2)) + 'T10:00:00';
  //Allocation Room
  SP.AccommodationSummary.Allocations.ChildExtraBedPax = room.ChildExtraBedQty;
  SP.AccommodationSummary.Allocations.ChildSharingRoomPax =
    room.ChildSharingRoomQty;
  SP.AccommodationSummary.Allocations.ChildSingleRoomPax =
    room.ChildSingleRoomQty;
  SP.AccommodationSummary.Allocations.ExtraBedPax = room.ExtraBedQty;
  SP.AccommodationSummary.Allocations.NoBedPax = room.BabyCrib + room.NoBed;
  SP.AccommodationSummary.Allocations.SharingBedPax = room.SharingBedQty;
  SP.AccommodationSummary.Allocations.SharingRoomPax = room.SharingRoomQty;
  SP.AccommodationSummary.Allocations.SingleRoomPax = room.SingleRoomQty;
  return SP;
};
//untuk setup summary program ready
//oldArrival : Object arrival yang lama
//oldSummaryProgram: Object Summaryprogram lama
//cityList :  Array of city yang ada region and country
//room : berisi object room allocation
export const setSummaryProgramReady = (
  Arrival,
  oldSummaryProgram,
  cityList,
  room,
  type
) => {
  let oldSP = copyObject(oldSummaryProgram);
  let oldDate = Arrival.Date;
  let newDate =
    ['Ready', 'Similar'].indexOf(type) !== -1
      ? convertToStringDate(SumDays(new Date(), 1)) + 'T00:00:00'
      : copyObject(Arrival.Date);

  let newSP = [];
  oldSP.map((obj, i) => {
    if (obj.AccommodationSummary !== undefined) {
      let SP = copyObject(SummaryProgram);
      if (convertToStringDate(oldDate) !== convertToStringDate(obj.Date)) {
        SP.NextDay = true;
        newDate = SumDays(newDate, 1);
      }
      SP.Day = obj.Day;
      SP.City = cityList.find(item => item.Id === obj.City.Id);
      SP.Region = SP.City.Region.Id;
      SP.TotalDays = obj.TotalDays;
      SP.Date = convertToStringDate(newDate) + 'T00:00:00';
      SP.LeavingDate =
        convertToStringDate(SumDays(SP.Date, SP.TotalDays - 1)) + 'T00:00:00';
      SP.AccommodationSummary.DateTime = SP.Date;
      SP.AccommodationSummary.CheckIn =
        convertToStringDate(SP.Date) + convertToStringTime(obj.Date);
      SP.AccommodationSummary.CheckOut =
        convertToStringDate(SP.LeavingDate) +
        convertToStringTime(obj.LeavingDate);
      SP.AccommodationSummary.Address = obj.AccommodationSummary.Address;
      SP.AccommodationSummary.AccommodationProfileId =
        obj.AccommodationSummary.AccommodationProfileId;
      SP.AccommodationSummary.Name = obj.AccommodationSummary.Name;
      SP.AccommodationSummary.RoomService =
        obj.AccommodationSummary.RoomService;
      SP.AccommodationSummary.RoomName = obj.AccommodationSummary.RoomName;
      SP.AccommodationSummary.RoomType = obj.AccommodationSummary.RoomType;
      SP.AccommodationSummary.SeqNumber = obj.AccommodationSummary.SeqNumber;
      SP.AccommodationSummary.ServiceItemId =
        obj.AccommodationSummary.ServiceItemId;
      //Allocation Room
      SP.AccommodationSummary.Allocations.ChildExtraBedPax =
        room.ChildExtraBedQty;
      SP.AccommodationSummary.Allocations.ChildSharingRoomPax =
        room.ChildSharingRoomQty;
      SP.AccommodationSummary.Allocations.ChildSingleRoomPax =
        room.ChildSingleRoomQty;
      SP.AccommodationSummary.Allocations.ExtraBedPax = room.ExtraBedQty;
      SP.AccommodationSummary.Allocations.NoBedPax = room.BabyCrib + room.NoBed;
      SP.AccommodationSummary.Allocations.SharingBedPax = room.SharingBedQty;
      SP.AccommodationSummary.Allocations.SharingRoomPax = room.SharingRoomQty;
      SP.AccommodationSummary.Allocations.SingleRoomPax = room.SingleRoomQty;
      oldDate = obj.LeavingDate;
      newDate = SP.LeavingDate;
      newSP.push(SP);
    } else {
      oldDate = obj.Date;
      let arr = copyObject(Airport);
      arr.Address = obj.Address;
      arr.City = cityList.find(item => item.Id === obj.City.Id);
      arr.Region = arr.City.Region.Id;
      arr.Day = obj.Day;
      arr.Note = obj.Note;
      arr.Place = obj.Place;
      arr.PlaceId = obj.PlaceId;
      arr.PlaceType = obj.PlaceType;
      arr.Date = convertToStringDate(newDate) + convertToStringTime(obj.Date);
      arr.TransferType = obj.TransferType;
      if (oldSP[i - 1] && oldSP[i - 1].AccommodationSummary !== undefined) {
        if (
          convertToStringDate(oldSP[i - 1].LeavingDate) !==
          convertToStringDate(obj.Date)
        ) {
          arr.NextDay = true;
          arr.Date =
            convertToStringDate(SumDays(newDate, 1)) +
            convertToStringTime(obj.Date);
        }
      } else if (oldSP[i - 1] !== undefined) {
        if (
          convertToStringDate(oldSP[i - 1].Date) !==
          convertToStringDate(obj.Date)
        ) {
          arr.NextDay = true;
          arr.Date =
            convertToStringDate(SumDays(newDate, 1)) +
            convertToStringTime(obj.Date);
        }
      }

      newDate = arr.Date;
      newSP.push(arr);
    }
    return obj;
  });

  return newSP;
};

//add accomodation baru
export const addSummaryProgram = oldSP => {
  oldSP = copyObject(oldSP);
  let SP = copyObject(SummaryProgram);
  SP.Day = oldSP[oldSP.length - 1].Day + 1;
  SP.Date = oldSP[oldSP.length - 1].LeavingDate;
  SP.LeavingDate = SumDays(oldSP[oldSP.length - 1].LeavingDate, 1);
  SP.AccommodationSummary.CheckIn = convertToStringDate(SP.Date) + 'T14:00:00';
  SP.AccommodationSummary.CheckOut =
    convertToStringDate(SP.LeavingDate) + 'T10:00:00';
  SP.Region = oldSP[oldSP.length - 1].Region;
  SP.AccommodationSummary.Allocations =
    oldSP[oldSP.length - 1].AccommodationSummary.Allocations;

  oldSP.push(SP);
  return oldSP;
};
//delete acomodasi in daily program
//Arrival : object arrival
//oldSP : array of Object Summary Program
//oldDailyProgram : array of object dailyprogram
//index : objct summary program yg di delete
export const delSummaryProgram = (
  Arrival,
  Departure,
  oldSP,
  oldDailyProgram,
  index
) => {
  let newDP = rmDailyProgramByAccomodation(oldSP, oldDailyProgram, index);
  let newSP = rmSummaryProgram(Arrival, oldSP, index);
  let newDeparture = setDateDeparture(Departure, newSP);
  return {
    dailyProgram: newDP,
    summaryProgram: newSP,
    departure: newDeparture,
  };
};
export const rmDailyProgramByAccomodation = (oldSP, oldDailyProgram, index) => {
  let newDP = copyObject(oldDailyProgram);
  oldSP = copyObject(oldSP);
  let startDate = new Date(oldSP[index].Date);
  let leaveDate = new Date(oldSP[index].LeavingDate);
  newDP = newDP.map(SP => {
    let tgl = new Date(SP.Date);
    let checkin = false;
    if (tgl.getDate() === startDate.getDate()) {
      for (let j = 0; j < SP.Movements.length; j++) {
        let movMen = SP.Movements[j].MovementName;
        if (checkin === true) {
          SP.Movements.splice(j, 1);
          j--;
        }
        if (movMen === 'CHECKOUT') checkin = true;
      }
    } else if (tgl.getDate() === leaveDate.getDate()) {
      for (let j = 0; j < SP.Movements.length; j++) {
        let movMen = SP.Movements.MovementName;
        SP.Movements.splice(j, 1);
        j--;
        if (movMen === 'CHECKOUT') {
          break;
        }
      }
    } else if (
      tgl.getDate() > startDate.getDate() &&
      tgl.getDate() < leaveDate.getDate()
    ) {
      SP.Movements = [];
    }
    return SP;
  });
  //delete dailyprogram if dailydetails is null
  for (let i = 0; i < newDP.length; i++) {
    if (newDP[i].Movements.length === 0) {
      newDP.splice(i, 1);
      i--;
    }
  }

  let mergeIndex = [];
  if (index !== 0 || index !== oldSP.length - 1) {
    let tgl1 = new Date(oldSP[index].Date);
    let tgl2 = new Date(oldSP[index].LeavingDate);
    for (let i = 0; i < newDP.length; i++) {
      let tgl = new Date(newDP[i].Date);
      if (
        tgl.getDate() === tgl1.getDate() ||
        tgl.getDate() === tgl2.getDate()
      ) {
        mergeIndex.push(i);
      }
    }
  }

  if (mergeIndex.length > 1) {
    //if delete in not array
    newDP[mergeIndex[0]].Movements = newDP[mergeIndex[0]].Movements.concat(
      newDP[mergeIndex[1]].Movements
    );
    newDP.splice(mergeIndex[1], 1);
  }
  return newDP;
};

export const rmSummaryProgram = (arrival, oldSP, index) => {
  //delete summaryProgram
  let newSP = copyObject(oldSP);
  if (index > -1) {
    if (newSP[index + 1] !== undefined) {
      // check what is mext summary program not null
      let prev = newSP[index - 1].AccommodationSummary;
      let next = newSP[index + 1].AccommodationSummary;
      if (prev === undefined && next === undefined) {
        // prev = arival , next = dep
        for (let j = 0; j < 5; j++) newSP.splice(index - 2, 1); // harus di delete arifalnya
      } else if (prev !== undefined && next === undefined) {
        //prev = accom, next = dep
        if (newSP[index - 1].Region === newSP[index].Region)
          newSP.splice(index, 1);
        else {
          for (let j = 0; j < 3; j++) newSP.splice(index, 1); // didelete dari index sampai arrival
        }
      } else if (prev === undefined && next !== undefined) {
        // prev = arival , next = accom
        if (newSP[index + 1].Region === newSP[index].Region)
          newSP.splice(index, 1);
        else {
          for (let j = 0; j < 3; j++) newSP.splice(index - 2, 1); // didelete dari index sampai arrival
        }
      } else if (newSP[index - 1].AccommodationSummary !== undefined) {
        newSP.splice(index, 1);
      } else {
        for (let j = 0; j < 3; j++) newSP.splice(index - 2, 1); // harus di delete arifalnya
      }
    } else {
      if (newSP[index - 1].AccommodationSummary !== undefined) {
        newSP.splice(index, 1);
      } else {
        for (let j = 0; j < 3; j++) newSP.splice(index - 2, 1); // harus di delete arifalnya
      }
    }
  }
  //update datetime
  return setDateSummaryProgram(arrival, newSP);
};

//change or slect accomodation
//ListSummaryProgram : array summary program
//index : index dari SP
//services: hotel services
//room : object item kamar
//object : hotel
export const changeAccomodation = (
  ListSummaryProgram,
  index,
  services,
  room,
  hotel
) => {
  const newData = copyObject(ListSummaryProgram);
  newData[index].AccommodationSummary.Name = hotel.Name;
  newData[index].AccommodationSummary.Address = hotel.Address;
  newData[index].AccommodationSummary.ImageUrl = hotel.ImageUrl;
  newData[index].AccommodationSummary.RoomType = room.AccommodationType.Name;
  newData[index].AccommodationSummary.RoomName = room.Name;
  newData[index].AccommodationSummary.RoomService = services;
  newData[index].AccommodationSummary.ServiceItemId = room.ServiceItemId;
  newData[index].AccommodationSummary.AccommodationProfileId =
    room.AccommodationProfileId;
  return newData;
};

//change or slect Destination ==> fungsi akan mendelete accomodation
//ListSummaryProgram : array summary program
//index : index dari SP
//city : object city yang berisi region
export const changeDestinationSummaryProgram = (
  ListSummaryProgram,
  index,
  city
) => {
  const newSP = copyObject(ListSummaryProgram);
  newSP[index].City = city;
  newSP[index].Region = city.Region.Id;
  newSP[index].AccommodationSummary.Name = null;
  newSP[index].AccommodationSummary.Address = null;
  newSP[index].AccommodationSummary.RoomType = null;
  newSP[index].AccommodationSummary.RoomName = null;
  newSP[index].AccommodationSummary.RoomService = null;
  newSP[index].AccommodationSummary.ServiceItemId = null;
  newSP[index].AccommodationSummary.AccommodationProfileId = null;
  return newSP;
};

//fungsi untuk check jika perlu perubahan arival atau departure
//ArrivalDeparture : object airport
//ListSummaryProgram : list dari summary program
//index : index dari summary program yg di change destinationnya
//type : Arrival or Departure
export const isNeedChangeArrivalDeparture = (
  ArrivalDeparture,
  ListSummaryProgram,
  index,
  type
) => {
  let air = copyObject(ArrivalDeparture);
  let SP = copyObject(ListSummaryProgram);
  if (type === 'Arrival') {
    // if (index === 0) {
    if (SP[index].Region !== air.RequestFrom) return true;
    else return false;
    // } else return false;
  } else {
    // if (index === SP.length - 1) {
    if (SP[index].Region !== air.RequestFrom) return true;
    else return false;
    // } else return false;
  }
};

//Untuk change airport jika ada perubahan destination
//ArrivalDeparture : object airport
//city : object city yang berisi region dipilih saat change accomodaion
//listAirport : array airport dari beckend
export const changeDestinationArivalDeparture = (
  ArrivalDeparture,
  cities,
  ListAirport,
  requestFrom
) => {
  let air = copyObject(ArrivalDeparture);
  let city =
    ListAirport.length > 0 ? findCityById(cities, ListAirport[0].CityId) : null;
  air.City = city;
  air.Region = city ? city.Region.Id : null;
  if (ListAirport.length > 0) {
    air.Address = ListAirport[0].AddressObject.AddressString
      ? ListAirport[0].AddressObject.AddressString
      : ListAirport[0].Address;
    air.Place = ListAirport[0].Name;
    air.PlaceId = ListAirport[0].Id;
  } else {
    air.Address = null;
    air.Place = null;
    air.PlaceId = null;
  }
  air.RequestFrom = requestFrom;
  return air;
};

//Untuk change airport jika ada perubahan destination
//ArrivalDeparture : object airport movement SP
//airport : object airport yg diselect
export const changeAirport = (ArrivalDeparture, airport) => {
  let air = copyObject(ArrivalDeparture);
  air.Address = airport.AddressObject.AddressString
    ? airport.AddressObject.AddressString
    : airport.Address;
  air.Place = airport.Name;
  air.PlaceId = airport.Id;
  return air;
};
//untuk change date arrival;
//arrival : object arrival
export const setDateArrival = (arrival, date) => {
  let air = copyObject(arrival);
  air.Date =
    convertToStringDate(changeTime(date)) + convertToStringTime(arrival.Date);
  return air;
};
//untuk change date departure;
//departure : objeck dari departure
//SummaryProgram : list of object summary program
export const setDateDeparture = (departure, SummaryProgram) => {
  let air = copyObject(departure);
  if (air.NextDay) {
    air.Date =
      convertToStringDate(
        SumDays(SummaryProgram[SummaryProgram.length - 1].LeavingDate, 1)
      ) + convertToStringTime(air.Date);
  } else
    air.Date =
      convertToStringDate(
        SummaryProgram[SummaryProgram.length - 1].LeavingDate
      ) + convertToStringTime(air.Date);
  return air;
};
//change time Arrival or Departure
//arrivalDeparture : object arrival or departure
//time : object date yang berisi time
export const changeTimeArrivalDeparture = (arrivalDeparture, time) => {
  let air = copyObject(arrivalDeparture);
  air.Date =
    convertToStringDate(air.Date) + convertToStringTime(changeTime(time));
  return air;
};
//set date Summary program jika arrival date diganti
export const setDateSummaryProgram = (arrival, SummaryProgram) => {
  let date = arrival.Date;
  let SP = copyObject(SummaryProgram);
  SP.map(obj => {
    if (obj.NextDay === true) {
      obj.Date =
        convertToStringDate(SumDays(date, 1)) + convertToStringTime(obj.Date);
    } else {
      obj.Date = convertToStringDate(date) + convertToStringTime(obj.Date);
    }

    if (obj.AccommodationSummary !== undefined) {
      obj.LeavingDate = SumDays(obj.Date, obj.TotalDays - 1);
      obj.AccommodationSummary.DateTime = obj.Date;
      obj.AccommodationSummary.CheckIn =
        convertToStringDate(obj.Date) +
        convertToStringTime(obj.AccommodationSummary.CheckIn);
      obj.AccommodationSummary.CheckOut =
        convertToStringDate(obj.LeavingDate) +
        convertToStringTime(obj.AccommodationSummary.CheckOut);
      date = obj.LeavingDate;
    } else {
      date = obj.Date;
    }
    return obj;
  });
  return SP;
};
//change duration Accomodation
//SummaryProgram : list of object summary program
//index : index number yang diganti durationnya
//duration : int dari duration day
export const changeDuration = (SummaryProgram, index, duration) => {
  let SP = copyObject(SummaryProgram);
  SP[index].TotalDays = Number(duration);
  SP[index].LeavingDate = SumDays(SP[index].Date, SP[index].TotalDays - 1);
  SP[index].AccommodationSummary.DateTime = SP[index].Date;
  SP[index].AccommodationSummary.CheckIn =
    convertToStringDate(SP[index].Date) +
    convertToStringTime(SP[index].AccommodationSummary.CheckIn);
  SP[index].AccommodationSummary.CheckOut =
    convertToStringDate(SP[index].LeavingDate) +
    convertToStringTime(SP[index].AccommodationSummary.CheckOut);
  let date = SP[index].LeavingDate;
  index++;
  return SP[index] ? setDateSummaryProgramSequence(SP, null, index, date) : SP;
};
//set date Summary program
//Summaryprogram : Summ ary Program
//Arrival : Arrival object
//index : index
//date :  date
export const setDateSummaryProgramByIndex = (
  SummaryProgram,
  Arrival,
  index,
  date
) => {
  let SP = copyObject(SummaryProgram);
  let air = copyObject(Arrival);
  date = changeTime(date);
  SP[index].Date =
    convertToStringDate(date) + convertToStringTime(SP[index].Date);
  if (index === 0) {
    SP[index].NextDay =
      convertToStringDate(SP[index].Date) === convertToStringDate(air.Date)
        ? false
        : true;
  } else {
    if (SP[index - 1].AccommodationSummary !== undefined) {
      SP[index].NextDay =
        convertToStringDate(SP[index - 1].LeavingDate) ===
        convertToStringDate(SP[index].Date)
          ? false
          : true;
    } else {
      SP[index].NextDay =
        convertToStringDate(SP[index - 1].Date) ===
        convertToStringDate(SP[index].Date)
          ? false
          : true;
    }
  }

  if (SP[index].AccommodationSummary !== undefined) {
    SP[index].AccommodationSummary.DateTime = SP[index].Date;
    SP[index].AccommodationSummary.CheckIn =
      convertToStringDate(SP[index].Date) +
      convertToStringTime(SP[index].AccommodationSummary.CheckIn);
    SP[index].TotalDays = getNumberOfDays(
      SP[index].Date,
      SP[index].LeavingDate
    );
    date = SP[index].LeavingDate;
  } else {
    date = SP[index].Date;
  }
  index++;
  return SP[index] ? setDateSummaryProgramSequence(SP, null, index, date) : SP;
};

//set date Summary program
//Summaryprogram : Summ ary Program
//Arrival : Arrival object
//index : index
//date :  date
export const setDateSummaryProgramSequence = (
  SummaryProgram,
  Arrival,
  index,
  date
) => {
  let SP = copyObject(SummaryProgram);
  let air = copyObject(Arrival);
  date = changeTime(date);
  SP[index].Date =
    convertToStringDate(date) + convertToStringTime(SP[index].Date);
  if (index === 0) {
    SP[index].NextDay =
      convertToStringDate(SP[index].Date) === convertToStringDate(air.Date)
        ? false
        : true;
  } else {
    if (SP[index - 1].AccommodationSummary !== undefined) {
      SP[index].NextDay =
        convertToStringDate(SP[index - 1].LeavingDate) ===
        convertToStringDate(SP[index].Date)
          ? false
          : true;
    } else {
      SP[index].NextDay =
        convertToStringDate(SP[index - 1].Date) ===
        convertToStringDate(SP[index].Date)
          ? false
          : true;
    }
  }

  if (SP[index].AccommodationSummary !== undefined) {
    SP[index].AccommodationSummary.DateTime = SP[index].Date;
    SP[index].AccommodationSummary.CheckIn =
      convertToStringDate(SP[index].Date) +
      convertToStringTime(SP[index].AccommodationSummary.CheckIn);
    SP[index].LeavingDate = SumDays(SP[index].Date, SP[index].TotalDays - 1);
    SP[index].AccommodationSummary.CheckOut =
      convertToStringDate(SP[index].LeavingDate) +
      convertToStringTime(SP[index].AccommodationSummary.CheckOut);
    date = SP[index].LeavingDate;
  } else {
    date = SP[index].Date;
  }
  index++;
  for (let i = index; i < SP.length; i++) {
    if (SP[i].NextDay === true) {
      SP[i].Date =
        convertToStringDate(SumDays(date, 1)) + convertToStringTime(SP[i].Date);
    } else {
      SP[i].Date = convertToStringDate(date) + convertToStringTime(SP[i].Date);
    }
    if (SP[i].AccommodationSummary !== undefined) {
      SP[i].LeavingDate = SumDays(SP[i].Date, SP[i].TotalDays - 1);
      SP[i].AccommodationSummary.DateTime = SP[i].Date;
      SP[i].AccommodationSummary.CheckIn =
        convertToStringDate(SP[i].Date) +
        convertToStringTime(SP[i].AccommodationSummary.CheckIn);
      SP[i].AccommodationSummary.CheckOut =
        convertToStringDate(SP[i].LeavingDate) +
        convertToStringTime(SP[i].AccommodationSummary.CheckOut);
      date = SP[i].LeavingDate;
    } else {
      date = SP[i].Date;
    }
  }
  return SP;
};

//addConnection Flight
export const addConnectionFlight = (SummaryProgram, index) => {
  let SP = copyObject(SummaryProgram);
  if (index !== 0 && SP[index].AccommodationSummary !== undefined) {
    if (SP[index].Region !== SP[index - 1].Region) {
      SP.splice(
        index,
        0,
        setInitialAirport(
          null,
          SP[index - 1].City,
          SP[index - 1].Day + 1,
          'Departure',
          SP[index - 1].AccommodationSummary.CheckOut,
          null
        )
      );
      SP.splice(
        index + 1,
        0,
        setInitialAirport(
          null,
          SP[index + 1].City,
          SP[index].Day + 1,
          'Arrival',
          SP[index].Date,
          null
        )
      );
      SP[index + 2].AccommodationSummary.CheckIn = SumSecond(
        SP[index + 1].Date,
        7200
      );
    }
  }
  return SP;
};

//change time Accomodation
//SummaryProgram : list of object summary program
//index : number of index yang diedit
//time : string datetime
//type : 'in' untuk checkin dan 'out' untuk checkout
export const changeChecInCheckOut = (SummaryProgram, index, time, type) => {
  let SP = copyObject(SummaryProgram);
  if (type === 'in') {
    SP[index].AccommodationSummary.CheckIn = changeTime(time);
    // SP[index].Date = time;
    // let hour = getHour(SP[index].AccommodationSummary.CheckIn);
    // if (hour < 14) SP[index].NextDay = true;
    // else SP[index].NextDay = false;
  } else {
    SP[index].AccommodationSummary.CheckOut = changeTime(time);
    // SP[index].LeavingDate = time;
  }

  return SP;
};

//////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////// DAILY Program ////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////

export const dailyGenerateCopy = (
  Arrival,
  Departure,
  SummaryProgram,
  oldDailyProgram,
  MovementList,
  cityInCountry
) => {
  let DailyProgramTemp = [];
  let firstArrival = Arrival[Arrival.length - 1];
  let lastDeparture = Departure[0];
  DailyProgramTemp = dailyProgramNull(
    firstArrival,
    SummaryProgram,
    lastDeparture
  );
  //dailyProgramNull(Arrival, SummaryProgram, Departure); //create tempalate with destination
  DailyProgramTemp = setMovementAirportDailyProgram(
    DailyProgramTemp,
    firstArrival,
    MovementList,
    'ARRIVAL'
  ); //setarival
  DailyProgramTemp = setConnectionFlight(
    SummaryProgram,
    DailyProgramTemp,
    MovementList
  ); //set connection flight
  DailyProgramTemp = setCheckoutinAccomodasi(
    SummaryProgram,
    DailyProgramTemp,
    MovementList
  ); // setcheckout
  DailyProgramTemp = setMovementAirportDailyProgram(
    DailyProgramTemp,
    lastDeparture,
    MovementList,
    'DEPARTURE'
  ); //setdeparture
  // DailyProgramTemp = getOldDailyProgram(DailyProgramTemp, oldDailyProgram); //get old program to new program
  DailyProgramTemp = getActivityOldDailyProgram(
    DailyProgramTemp,
    oldDailyProgram,
    cityInCountry
  );
  DailyProgramTemp = deleteDayendNextDeparture(DailyProgramTemp);
  DailyProgramTemp = addVirtualAccomodasi(DailyProgramTemp, MovementList);
  DailyProgramTemp = addedDriving(DailyProgramTemp, MovementList);
  DailyProgramTemp = addStartEndDays2(
    SummaryProgram,
    DailyProgramTemp,
    lastDeparture,
    firstArrival,
    MovementList
  );
  DailyProgramTemp = fixLeaveReturnAccomodation(DailyProgramTemp);
  DailyProgramTemp = checkDayEnd(DailyProgramTemp);
  DailyProgramTemp = generateFreeTimeNew(DailyProgramTemp, MovementList);
  DailyProgramTemp = setDailyProgramTempIfNull(DailyProgramTemp);
  DailyProgramTemp = fixDateAndSequenceNum(DailyProgramTemp);
  DailyProgramTemp = fixFlightTime(DailyProgramTemp);
  DailyProgramTemp = fixDayStartDayEndAddress(DailyProgramTemp);
  let completeDailyProgram = generateInitialDailyProgram({
    Departures: Arrival,
    MainPrograms: SummaryProgram,
    Returns: Departure,
  });
  DailyProgramTemp = copyDailyProgram(completeDailyProgram, DailyProgramTemp);
  DailyProgramTemp = generateFirstLastFlights(
    Arrival,
    Departure,
    DailyProgramTemp,
    MovementList
  );
  DailyProgramTemp = fixDateAndSequenceNum(DailyProgramTemp);
  DailyProgramTemp = fixFlightTime(DailyProgramTemp);
  return DailyProgramTemp;
};

export const dailyGenerate4 = (
  Arrival,
  Departure,
  SummaryProgram,
  oldDailyProgram,
  MovementList,
  cityInCountry,
  isVariableDate,
) => {
  let DailyProgramTemp = [];
  if (!isVariableDate) {
    DailyProgramTemp = generateInitialDailyProgram({
      Departures: Arrival,
      MainPrograms: SummaryProgram,
      Returns: Departure,
    });
    DailyProgramTemp = setMovementAirportToDailyProgram(
      DailyProgramTemp,
      Arrival,
      MovementList
    );
    DailyProgramTemp = setMovementConnectionFlight(
      SummaryProgram,
      DailyProgramTemp,
      MovementList
    ); //set connection flight
    DailyProgramTemp = setMovementCheckoutinAccomodasi(
      SummaryProgram,
      DailyProgramTemp,
      MovementList
    ); // setcheckout
    DailyProgramTemp = setMovementAirportToDailyProgram(
      DailyProgramTemp,
      Departure,
      MovementList
    ); //setdeparture
    DailyProgramTemp = getActivityOldDailyProgram(
      //getOldDailyProgram(
      DailyProgramTemp,
      oldDailyProgram,
      cityInCountry
    ); //get old program to new program
    DailyProgramTemp = fixFlightPlaceDailyProgram(
      {
        Departures: Arrival,
        MainPrograms: SummaryProgram,
        Returns: Departure,
      },
      DailyProgramTemp,
      MovementList
    );
    DailyProgramTemp = deleteDayendNextDeparture(DailyProgramTemp);
    DailyProgramTemp = setMovementVirtualAccommodation(
      DailyProgramTemp,
      MovementList
    );
    DailyProgramTemp = setMovementDriving(DailyProgramTemp, MovementList);
    DailyProgramTemp = setMovementDayStartDayEnd(
      SummaryProgram,
      DailyProgramTemp,
      MovementList
    );
    DailyProgramTemp = fixLeaveReturnAccomodation(DailyProgramTemp);
    DailyProgramTemp = checkDayEnd(DailyProgramTemp);
    DailyProgramTemp = setMovementFreetimeLocked(
      DailyProgramTemp,
      MovementList
    );
    DailyProgramTemp = setDailyProgramTempIfNull(DailyProgramTemp);
    DailyProgramTemp = fixDateAndSequenceNum(DailyProgramTemp);
    DailyProgramTemp = fixFlightTime(DailyProgramTemp);
    DailyProgramTemp = fixDayStartDayEndAddress(DailyProgramTemp);
    DailyProgramTemp = fixInitialActivityDestinationName(DailyProgramTemp);
    DailyProgramTemp = fixDrivingDestination(DailyProgramTemp);
  } else {
    DailyProgramTemp = oldDailyProgram;
  }

  return DailyProgramTemp;
};
//initial Daily Program with date and Set Destination
//Arrival :  object Arrival
//SummaryProgram: Array of object Summary program
//Departure: object Departure
export const dailyProgramNull = (Arrival, SummaryProgram, Departure) => {
  //set date
  let DP = [];
  let tgl1 = convertToStringDate(Arrival.Date) + 'T00:00:00';
  let days = getNumberOfDays(Arrival.Date, Departure.Date);
  for (let i = 0; i < days; i++) {
    let DailyPrograms = copyObject(DailyProgram);
    DP.push(DailyPrograms);
  }

  DP.map((obj, index) => {
    let tgl = SumDays(tgl1, index);
    obj.Day = index + 1;
    obj.Date = tgl;
    return obj;
  });
  //Initial Destination Arrival
  let DestinationArrival = {
    Destination: Arrival.City ? Arrival.City.Id : null,
  };
  //Initial Destination Departure
  let DestinationDeparture = {
    Destination: Departure.City ? Departure.City.Id : null,
  };
  //Set Destination
  DestinationArrival.Destination &&
    DP[0].TourDestinations.push(DestinationArrival);

  SummaryProgram.map(obj => {
    let DateFrom = convertToStringDate(obj.Date);
    let DateTo = null;
    let newDP = [];
    if (obj.AccommodationSummary !== undefined) {
      DateTo = convertToStringDate(obj.LeavingDate);
      newDP = DP.filter(
        item =>
          convertToStringDate(item.Date) >= DateFrom &&
          convertToStringDate(item.Date) <= DateTo
      );
    } else {
      newDP = DP.filter(item => convertToStringDate(item.Date) === DateFrom);
    }
    let item = {
      Destination: obj.City ? (obj.City.Name ? obj.City.Id : null) : null,
    };

    newDP.map(x => {
      if (
        x.TourDestinations.findIndex(
          td => td.Destination === item.Destination
        ) < 0
      ) {
        x.TourDestinations.push(item);
      }
      return x;
    });
    return obj;
  });

  if (
    DP[DP.length - 1].TourDestinations.findIndex(
      item => item.Destination === DestinationDeparture.Destination
    ) < 0
  ) {
    DP[DP.length - 1].TourDestinations.push(DestinationDeparture);
  }
  return DP;
};

//initial Daily Program with date and Set Destination
//Arrival :  object Arrival
//DailyProgram: Array of object Daily program
//Departure: object Departure
//movementList : array of object type movement
//type :  stirng with value "ARRIVAL" or "DEPARTURE"
export const setMovementAirportDailyProgram = (
  DailyProgram,
  ArrivalDeparture,
  MovementList,
  type
) => {
  let DP = copyObject(DailyProgram);
  type === 'ARRIVAL'
    ? DP[0].Movements.push(
        setAirportMovement(ArrivalDeparture, MovementList, type)
      )
    : DP[DP.length - 1].Movements.push(
        setAirportMovement(ArrivalDeparture, MovementList, type)
      );
  return DP;
};

//Untuk setup airport movement di daily program
//item : object arrival / departure
//movementList : array of object type movement
//type :  stirng with value "ARRIVAL" or "DEPARTURE"
export const setAirportMovement = (item, movementList, type) => {
  let mov = copyObject(Movement);
  let typeMov = movementList.find(mov => mov.Name === type);
  mov.DateTime = changeTime(item.Date);
  mov.Destination = item.City.Id;
  mov.DestinationName = item.City.Name;
  mov.OriginDestination = item.City.Id;
  mov.Item = addItemArrivalDeparture(item, type);
  mov.MovementName = typeMov ? typeMov.Name : '';
  mov.MovementDescription = typeMov ? typeMov.Description : '';
  mov.Note = item.Note;
  mov.SeqNumber = 1;
  mov.ServiceItemId = typeMov ? typeMov.ServiceItemId : '';
  return mov;
};

//Untuk setup item movement airport di daily program
//item : object arrival / departure
//type :  stirng with value "ARRIVAL" or "DEPARTURE"
export const addItemArrivalDeparture = (item, type) => {
  let it = copyObject(ItemMov);
  it.Address = item.Address;
  it.CityId = item.City ? item.City.Id : null;
  it.Desc = item.PlaceType;
  it.Name = item.Place;
  it.PlaceId = item.PlaceId;
  if (type === 'ARRIVAL') it.ServiceType = 'Movement_arrival';
  else it.ServiceType = 'Movement_departure';
  return it;
};

//initial connection flight Daily Program
//SummaryProgram: Array of object Summary program
//DailyProgram: Array of object Daily program
//movementList : array of object type movement
export const setConnectionFlight = (
  SummaryProgram,
  DailyProgram,
  MovementList
) => {
  let DP = copyObject(DailyProgram);
  let CF = copyObject(SummaryProgram).filter(
    item => item.AccommodationSummary === undefined
  );
  CF.map(obj => {
    let j = DP.findIndex(
      item => convertToStringDate(item.Date) === convertToStringDate(obj.Date)
    );
    if (j >= 0) {
      obj.TransferType === 'Movement_arrival'
        ? DP[j].Movements.push(setAirportMovement(obj, MovementList, 'ARRIVAL'))
        : DP[j].Movements.push(
            setAirportMovement(obj, MovementList, 'DEPARTURE')
          );
    }
    return obj;
  });
  return DP;
};

//untuk set movement accomdasi
//SummaryProgram: Array of object Summary program
//DailyProgram: Array of object Daily program
//movementList : array of object type movement
export const setCheckoutinAccomodasi = (
  SummaryProgram,
  DailyProgram,
  MovementList
) => {
  let SP = copyObject(SummaryProgram);
  let DP = copyObject(DailyProgram);
  let i = 0;
  SP = SP.filter(item => item.AccommodationSummary !== undefined);
  let no = SP.length;
  for (let j = 0; j < DP.length; j++) {
    //checkout
    if (
      convertToStringDate(SP[i].LeavingDate) === convertToStringDate(DP[j].Date)
    ) {
      if (DP[j].Movements.length === 0) {
        DP[j].Movements.push(
          setAccomodationtMovement(SP[i], MovementList, 'CHECKOUT')
        );
      } else {
        for (let k = 0; k < DP[j].Movements.length; k++) {
          // eslint-disable-next-line
          if (k == DP[j].Movements.length) break;
          else if (DP[j].Movements[k + 1] == null) {
            DP[j].Movements.push(
              setAccomodationtMovement(SP[i], MovementList, 'CHECKOUT')
            );
            k = DP[j].Movements.length;
          } else if (
            convertToStringTime(DP[j].Movements[k].DateTime) <
              convertToStringTime(SP[i].AccommodationSummary.CheckOut) &&
            convertToStringTime(SP[i].AccommodationSummary.CheckOut) <
              convertToStringTime(DP[j].Movements[k + 1].DateTime)
          ) {
            DP[j].Movements.splice(
              k + 1,
              0,
              setAccomodationtMovement(SP[i], MovementList, 'CHECKOUT')
            );
            k = DP[j].Movements.length;
          } else if (
            convertToStringTime(DP[j].Movements[k].DateTime) >
            convertToStringTime(SP[i].AccommodationSummary.CheckOut)
          ) {
            DP[j].Movements.splice(
              k,
              0,
              setAccomodationtMovement(SP[i], MovementList, 'CHECKOUT')
            );
            k = DP[j].Movements.length;
          }
        }
      }
      if (convertToStringDate(SP[i].Date) === convertToStringDate(DP[j].Date)) {
        for (let k = 0; k < DP[j].Movements.length; k++) {
          // eslint-disable-next-line
          if (k == DP[j].Movements.length) break;
          else if (DP[j].Movements[k + 1] == null) {
            DP[j].Movements.push(
              setAccomodationtMovement(SP[i], MovementList, 'CHECKIN')
            );
            k = DP[j].Movements.length;
          } else if (
            convertToStringTime(DP[j].Movements[k].DateTime) <
              convertToStringTime(SP[i].AccommodationSummary.CheckIn) &&
            convertToStringTime(SP[i].AccommodationSummary.CheckIn) <
              convertToStringTime(DP[j].Movements[k + 1].DateTime)
          ) {
            DP[j].Movements.splice(
              k + 1,
              0,
              setAccomodationtMovement(SP[i], MovementList, 'CHECKIN')
            );
            k = DP[j].Movements.length;
          } else if (
            convertToStringTime(DP[j].Movements[k].DateTime) >
            convertToStringTime(SP[i].AccommodationSummary.CheckIn)
          ) {
            DP[j].Movements.splice(
              k,
              0,
              setAccomodationtMovement(SP[i], MovementList, 'CHECKIN')
            );
            k = DP[j].Movements.length;
          }
        }
      }
      i++;
    }
    if (i === no) break;
    //checkin
    if (convertToStringDate(SP[i].Date) === convertToStringDate(DP[j].Date)) {
      DP[j].Movements.push(
        setAccomodationtMovement(SP[i], MovementList, 'CHECKIN')
      );
      if (
        convertToStringDate(SP[i].LeavingDate) ===
        convertToStringDate(DP[j].Date)
      ) {
        DP[j].Movements.push(
          setAccomodationtMovement(SP[i], MovementList, 'CHECKOUT')
        );
        i++;
      }
    }
    if (i === no) break;
  }
  return DP;
};

//untuk set accomodation movement
//item : Object Summary program accomodation
//movementList : array of object type movement
//type : string "CHECKIN" or "CHECKOUT"
export const setAccomodationtMovement = (item, MovementList, type) => {
  let aco = copyObject(Movement);
  let typeMov = MovementList.filter(item => item.Name === type);
  if (type === 'CHECKIN') {
    aco.DateTime = item.AccommodationSummary.CheckIn;
    aco.TypeMovement = false;
  } else {
    aco.DateTime = item.AccommodationSummary.CheckOut;
  }
  aco.Destination = item.City ? item.City.Id : null;
  aco.DestinationName = item.City ? item.City.Name : null;
  aco.OriginDestination = item.City ? item.City.Id : null;
  aco.Item = addItemHotel(aco.Destination, item.AccommodationSummary);
  aco.MovementName = typeMov[0].Name;
  aco.MovementDescription = typeMov[0].Description;
  aco.ServiceItemId = typeMov[0].ServiceItemId;
  return aco;
};

//untuk set item accomodation movement
//Destination : id of City
//AccommodationSummary
export const addItemHotel = (Destination, AccommodationSummary) => {
  let it = copyObject(ItemMov);
  if (AccommodationSummary !== null) {
    it.Address = AccommodationSummary.Address;
    it.CityId = Destination;
    it.ProfileDesc = AccommodationSummary.RoomName;
    it.ImageName = AccommodationSummary.ImageName;
    it.ImageUrl = AccommodationSummary.ImageUrl;
    it.Name = AccommodationSummary.Name;
    it.ServiceItemId = AccommodationSummary.ServiceItemId;
    it.ServiceType = AccommodationSummary.RoomService;
  }
  return it;
};

//untuk set item accomodation movement
//newDailyProgram : DailyProgram baru
//oldDailyProgram : Daily program lama dari props
//return = newDailyProgram
export const getOldDailyProgram = (newDailyProgram, oldDailyProgram) => {
  let IndexCheckInOutOld = [];
  let IndexCheckInOutNew = [];
  let IndexVirtual = [];
  let data = null;
  // eslint-disable-next-line
  newDailyProgram.map((obj, i) => {
    // eslint-disable-next-line
    obj.Movements.map(nextObj => {
      if (nextObj.MovementName === 'CHECKIN') {
        data = {
          In: null,
          Out: null,
        };
        data.In = i;
      } else if (nextObj.MovementName === 'CHECKOUT') {
        data.Out = i;
        IndexCheckInOutNew.push(data);
      }
      // return nextObj;
    });
    // return obj;
  });
  // eslint-disable-next-line
  oldDailyProgram.map((obj, i) => {
    // eslint-disable-next-line
    obj.Movements.map((nextObj, j) => {
      if (nextObj.MovementName === 'CHECKIN') {
        data = {
          In: null,
          Out: null,
        };
        data.In = i;
      } else if (nextObj.MovementName === 'CHECKOUT') {
        data.Out = i;
        IndexCheckInOutOld.push(data);
      } else if (nextObj.MovementName === 'VIRTUALCHECKOUT') {
        let data = {
          IndexI: i,
          IndexJ: j,
        };
        IndexVirtual.push(data);
      }
      // return nextObj;
    });
    // return obj;
  });
  // eslint-disable-next-line
  IndexCheckInOutNew.some((obj, i) => {
    let idxnewIn = obj.In;
    let idxnewOut = obj.Out;
    if (IndexCheckInOutOld[i] !== undefined) {
      var idxoldIn = IndexCheckInOutOld[i].In;
      var idxoldOut = IndexCheckInOutOld[i].Out;
      for (let j = idxnewIn; j <= idxnewOut; j++) {
        if (idxoldIn === idxoldOut) j = idxnewOut;
        if (newDailyProgram[j].Movements.length === 0) {
          // eslint-disable-next-line
          oldDailyProgram[idxoldIn].Movements.map((oDP, index) => {
            let num = newDailyProgram[j].Movements.length;
            if (
              [
                'RECREATION',
                'EAT',
                'LEAVEACCOMMODATION',
                'RETURNACCOMMODATION',
                'DRIVING',
                'FREETIMELOCKED',
                'FREETIME',
              ].indexOf(oDP.MovementName) !== -1
            ) {
              if (num > 0) {
                if (
                  getHour(oDP.DateTime) >=
                  getHour(newDailyProgram[j].Movements[num - 1].DateTime)
                ) {
                  newDailyProgram[j].Movements.push(
                    oldDailyProgram[idxoldIn].Movements[index]
                  );
                } else if (
                  convertToStringDate(oDP.DateTime) >=
                  convertToStringDate(
                    newDailyProgram[j].Movements[num - 1].DateTime
                  )
                ) {
                  if (oldDailyProgram[idxoldIn].Movements[index + 1]) {
                    if (
                      // eslint-disable-next-line
                      convertToStringDate(oDP.DateTime) ==
                      convertToStringDate(
                        oldDailyProgram[idxoldIn].Movements[index + 1].DateTime
                      )
                    ) {
                      newDailyProgram[j].Movements.push(
                        oldDailyProgram[idxoldIn].Movements[index]
                      );
                    }
                  } else {
                    newDailyProgram[j].Movements.push(
                      oldDailyProgram[idxoldIn].Movements[index]
                    );
                  }
                  // oldDailyProgram[idxoldIn].Movements[index + 1]
                  //   ? convertToStringDate(oDP.DateTime) ==
                  //     convertToStringDate(
                  //       oldDailyProgram[idxoldIn].Movements[index + 1].DateTime
                  //     )
                  //     ? newDailyProgram[j].Movements.push(
                  //         oldDailyProgram[idxoldIn].Movements[index]
                  //       )
                  //     : null
                  //   : newDailyProgram[j].Movements.push(
                  //       oldDailyProgram[idxoldIn].Movements[index]
                  //     );
                }
              } else {
                if (
                  oDP.Destination ===
                  newDailyProgram[j].TourDestinations[0].Destination
                ) {
                  newDailyProgram[j].Movements.push(
                    oldDailyProgram[idxoldIn].Movements[index]
                  );
                }
              }
            }
          });
          newDailyProgram[j].TourGuides = oldDailyProgram[idxoldIn].TourGuides
            ? oldDailyProgram[idxoldIn].TourGuides
            : [];
        } else {
          let dp = newDailyProgram[j].Movements;
          let dpOld = oldDailyProgram[idxoldIn].Movements;
          let arival = dp.filter(item => item.MovementName === 'ARRIVAL');
          let checkin = dp.filter(item => item.MovementName === 'CHECKIN');
          let checkout = dp.filter(item => item.MovementName === 'CHECKOUT');
          let departure = dp.filter(item => item.MovementName === 'DEPARTURE');
          dp = arival.concat(checkin, checkout, departure);

          arival = dpOld.filter(item => item.MovementName === 'ARRIVAL');
          checkin = dpOld.filter(item => item.MovementName === 'CHECKIN');
          checkout = dpOld.filter(item => item.MovementName === 'CHECKOUT');
          departure = dpOld.filter(item => item.MovementName === 'DEPARTURE');
          dpOld = arival.concat(checkin, checkout, departure);

          if (dp.length === dpOld.length) {
            let ok = false;
            let checkInCheck = false;
            let checkOutCheck = false;
            let isthereCheckin = false;
            let isthereCheckout = false;
            // eslint-disable-next-line
            let indexFlight = dp.findIndex(
              obj =>
                obj.MovementName === 'ARRIVAL' ||
                obj.MovementName === 'DEPARTURE'
            );
            // eslint-disable-next-line
            dp.some((obj, i) => {
              if (
                obj.MovementName === dpOld[i].MovementName &&
                convertToStringTime(obj.DateTime) ===
                  convertToStringTime(dpOld[i].DateTime) &&
                obj.Item.ServiceItemId === dpOld[i].Item.ServiceItemId
                // &&
                // obj.Item.CityId === dpOld[i].Item.CityId
              ) {
                dpOld[i].Item = obj.Item;
                dpOld[i].Destination = obj.Destination;
                dpOld[i].Note = obj.Note;

                if (obj.MovementName === 'CHECKIN') {
                  checkInCheck = true;
                  isthereCheckin = true;
                } else if (obj.MovementName === 'CHECKOUT') {
                  checkOutCheck = true;
                  isthereCheckout = true;
                } else if (
                  obj.MovementName === 'ARRIVAL' ||
                  obj.MovementName === 'DEPARTURE'
                ) {
                  ok = true;
                }
              } else {
                if (obj.MovementName === 'CHECKIN') {
                  checkInCheck = false;
                  isthereCheckin = true;
                } else if (obj.MovementName === 'CHECKOUT') {
                  checkOutCheck = false;
                  isthereCheckout = true;
                } else if (
                  obj.MovementName === 'ARRIVAL' ||
                  obj.MovementName === 'DEPARTURE'
                ) {
                  ok = false;
                }
                // if (i == dp.length - 1) return true;
              }
            });
            // eslint-disable-next-line
            let replaceDataFlightAccomo = indexFlight
              ? ok && (checkOutCheck !== false || checkInCheck !== false)
                ? true
                : false
              : true;
            // eslint-disable-next-line
            if (
              (ok && checkOutCheck && !isthereCheckin) ||
              (checkInCheck && !isthereCheckout) ||
              (checkInCheck && checkOutCheck)
            ) {
              // (checkOutCheck !== false || checkInCheck !== false)){
              newDailyProgram[j].Movements =
                oldDailyProgram[idxoldIn].Movements;
              newDailyProgram[j].TourGuides = oldDailyProgram[idxoldIn]
                .TourGuides
                ? oldDailyProgram[idxoldIn].TourGuides
                : [];
              // eslint-disable-next-line
            } else if (
              ok &&
              ((checkInCheck && !checkOutCheck) ||
                (!checkInCheck && checkOutCheck))
            ) {
              // eslint-disable-next-line no-self-assign
              newDailyProgram[j].Movements = newDailyProgram[j].Movements;
            } else if (replaceDataFlightAccomo) {
              if (checkInCheck !== false || checkOutCheck !== false) {
                let indexNewDp = newDailyProgram[j].Movements.findIndex(
                  obj =>
                    obj.MovementName ===
                    (!checkInCheck ? 'CHECKIN' : 'CHECKOUT')
                );
                let indexNewDpTrue = newDailyProgram[j].Movements.findIndex(
                  obj =>
                    obj.MovementName === (checkInCheck ? 'CHECKIN' : 'CHECKOUT')
                );
                let indexOldDp = oldDailyProgram[idxoldIn].Movements.findIndex(
                  obj =>
                    obj.MovementName === (checkInCheck ? 'CHECKIN' : 'CHECKOUT')
                );
                let slicdeDpOld =
                  indexOldDp >= oldDailyProgram[j].Movements.length / 2
                    ? oldDailyProgram[idxoldIn].Movements.slice(
                        indexOldDp,
                        oldDailyProgram[idxoldIn].Movements.length
                      )
                    : oldDailyProgram[idxoldIn].Movements.slice(
                        0,
                        indexOldDp + 1
                      );
                let slicdeDpNew =
                  indexNewDp >= newDailyProgram[j].Movements.length / 2
                    ? newDailyProgram[j].Movements.slice(
                        indexNewDp,
                        newDailyProgram[j].Movements.length
                      )
                    : newDailyProgram[j].Movements.slice(0, indexNewDp + 1);
                newDailyProgram[j].Movements =
                  indexNewDpTrue > indexNewDp
                    ? slicdeDpNew.concat(slicdeDpOld)
                    : slicdeDpOld.concat(slicdeDpNew);
              }
            }
          }
        }
        idxoldIn++;
        if (idxoldIn > idxoldOut) break;
      }
    } else return true;
    // return obj;
  });

  let k = 0;
  //untuk get activity di virtual checkout
  // eslint-disable-next-line
  newDailyProgram.some((obj, i) => {
    if (k < IndexVirtual.length) {
      let indexArr = obj.Movements.findIndex(
        item => item.MovementName === 'ARRIVAL'
      );
      let indexCO = obj.Movements.findIndex(
        item => item.MovementName === 'CHECKOUT'
      );
      let num = IndexVirtual[k].IndexJ;
      let id = 0;
      if (indexArr >= 0) id = indexArr;
      else if (indexCO >= 0) id = indexCO;

      if (obj.Movements.length > 1) {
        if (obj.Movements[id + 1] !== undefined) {
          if (
            obj.Movements[id + 1].MovementName === 'VIRTUALCHECKIN' ||
            obj.Movements[id + 1].MovementName === 'FREETIMELOCKED'
          ) {
            obj.Movements[id + 1].Destination = obj.Movements[id].Destination;
            if (obj.Movements[id + 2].MovementName === 'VIRTUALCHECKIN') {
              obj.Movements[id + 2].Destination =
                obj.Movements[id + 1].Destination;
              if (obj.Movements[id + 3].MovementName === 'VIRTUALCHECKOUT')
                obj.Movements[id + 3].Destination =
                  obj.Movements[id + 2].Destination;
            }

            if (obj.Movements[id + 2].MovementName === 'VIRTUALCHECKOUT')
              obj.Movements[id + 2].Destination =
                obj.Movements[id + 1].Destination;

            k++;
          }
        } else {
          if (
            obj.TourDestinations[0].Destination ===
              oldDailyProgram[IndexVirtual[k].IndexI].TourDestinations[0]
                .Destination &&
            obj.Movements.findIndex(item => item.MovementName === 'CHECKOUT') >=
              0
          ) {
            while (num) {
              if (
                oldDailyProgram[IndexVirtual[k].IndexI].Movements[num]
                  .MovementName === 'ARRIVAL'
              )
                break;
              if (
                oldDailyProgram[IndexVirtual[k].IndexI].Movements[num]
                  .MovementName === 'CHECKOUT'
              )
                break;

              oldDailyProgram[IndexVirtual[k].IndexI].Movements[
                num
              ].Destination =
                oldDailyProgram[IndexVirtual[k].IndexI].Movements[
                  num - 1
                ].Destination;
              obj[i].Movements.splice(
                id + 1,
                0,
                oldDailyProgram[IndexVirtual[k].IndexI].Movements[num]
              );
              num--;
            }
            k++;
          }
        }
      } else if (obj.Movements.length === 1) {
        if (
          obj.Movements[id].MovementName === 'ARRIVAL' ||
          obj.Movements[id].MovementName === 'CHECKOUT'
        ) {
          let Arr = oldDailyProgram[IndexVirtual[k].IndexI].Movements.findIndex(
            item => item.MovementName === 'ARRIVAL'
          );
          let CO = oldDailyProgram[IndexVirtual[k].IndexI].Movements.findIndex(
            item => item.MovementName === 'CHECKOUT'
          );
          let idx = 0;
          if (Arr >= 0) idx = Arr;
          if (CO >= 0) idx = CO;

          if (
            obj.TourDestinations[0].Destination ===
            oldDailyProgram[i].TourDestinations[0].Destination
          )
            for (
              let y = idx + 1;
              y < oldDailyProgram[IndexVirtual[k].IndexI].Movements.length;
              y++
            ) {
              obj.Movements.push(
                oldDailyProgram[IndexVirtual[k].IndexI].Movements[y]
              );
              if (
                oldDailyProgram[IndexVirtual[k].IndexI].Movements[y]
                  .MovementName === 'VIRTUALCHECKOUT'
              )
                break;
            }
        }
        k++;
      }
    }
    // return obj;
  });

  return newDailyProgram;
};
//menambahkan virtual Accomodasi
//newDailyProgram : Daily Program baru
//MovementList : Array of object movementList
export const addVirtualAccomodasi = (newDailyProgram, MovementList) => {
  newDailyProgram.map(obj => {
    for (let j = 0; j < obj.Movements.length; j++) {
      if (obj.Movements[j].MovementName === 'VIRTUALCHECKIN') {
        obj.Movements[j].Item =
          obj.Movements[j - 1].MovementName === 'FREETIMELOCKED'
            ? copyObject(obj.Movements[j - 2].Item)
            : copyObject(obj.Movements[j - 1].Item);
      } else if (
        obj.Movements[j].MovementName === 'VIRTUALCHECKOUT' ||
        obj.Movements[j].MovementName === 'VIRTUALLEAVEACCOMMODATION'
      ) {
        obj.Movements[j].Item = copyObject(obj.Movements[j - 1].Item);
      } else if (
        obj.Movements[j].MovementName === 'ARRIVAL' ||
        obj.Movements[j].MovementName === 'CHECKOUT'
      ) {
        if (obj.Movements[j + 1] === undefined) {
          obj.Movements.splice(
            j + 1,
            0,
            virtualCheckInOut(obj.Movements[j], MovementList, 'VIRTUALCHECKIN')
          );
          obj.Movements.splice(
            j + 2,
            0,
            virtualCheckInOut(
              obj.Movements[j + 1],
              MovementList,
              'VIRTUALCHECKOUT'
            )
          );
        } else {
          if (
            obj.Movements[j + 1].MovementName === 'VIRTUALCHECKIN' ||
            obj.Movements[j + 1].MovementName === 'FREETIMELOCKED'
          ) {
            // //console.log('Not Function');
          } else {
            obj.Movements.splice(
              j + 1,
              0,
              virtualCheckInOut(
                obj.Movements[j],
                MovementList,
                'VIRTUALCHECKIN'
              )
            );
            obj.Movements.splice(
              j + 2,
              0,
              virtualCheckInOut(
                obj.Movements[j + 1],
                MovementList,
                'VIRTUALCHECKOUT'
              )
            );
          }
        }
      }
    }
    return obj;
  });
  return newDailyProgram;
};

export const virtualCheckInOut = (item, MovementList, type) => {
  let d = copyObject(Movement);
  let mov1 = MovementList.filter(item => item.Name === type);
  d.DateTime = item.DateTime;
  d.SeqNumber = null;
  d.MovementName = mov1[0].Name;
  d.MovementDescription = mov1[0].Description;
  d.Duration = 0;
  d.ServiceItemId = mov1[0].ServiceItemId;
  d.Destination = item.Destination;
  d.DestinationName = item.DestinationName;
  d.OriginDestination = item.OriginDestination;
  d.Item = item.Item;
  d.TypeVirtual = true;
  return d;
};

//menambahkan driving null di antara activity
export const addedDriving = (newDailyProgram, MovementList) => {
  newDailyProgram.map((obj, i) => {
    for (let j = 0; j < obj.Movements.length; j++) {
      let mov = obj.Movements[j].MovementName;
      let item = obj.Movements[j + 1] ? obj.Movements[j + 1] : obj.Movements[j];
      let date = SumSecond(
        obj.Movements[j].DateTime,
        obj.Movements[j].Duration
      );
      if (mov === 'ARRIVAL' || mov === 'CHECKOUT') {
        if (mov === 'CHECKOUT') {
          if (j !== 0)
            if (obj.Movements[j - 1].MovementName !== 'CHECKIN') {
              if (obj.Movements[j - 1].MovementName !== 'DAYSTART')
                if (obj.Movements[j].MovementName !== 'DRIVING') {
                  obj.Movements.splice(
                    j,
                    0,
                    AddDrivingNull(MovementList, item, date)
                  );
                  j++;
                }
            }
        }
        if (obj.Movements[j + 1].MovementName !== 'FREETIMELOCKED')
          if (obj.Movements[j + 3] !== undefined) {
            if (obj.Movements[j + 3].MovementName !== 'DRIVING') {
              item = obj.Movements[j + 3];
              obj.Movements.splice(
                j + 3,
                0,
                AddDrivingNull(MovementList, item, date)
              );
              j++;
              item = obj.Movements[j + 1];
            }
          } else {
            obj.Movements.push(AddDrivingNull(MovementList, item, date));
            j++;
          }
      }

      if (mov === 'VIRTUALCHECKOUT') {
        if (obj.Movements[j + 1] === undefined) {
          obj.Movements.splice(
            j + 1,
            0,
            AddDrivingNull(MovementList, item, date)
          );
          j++;
        } else if (obj.Movements[j + 1].MovementName !== 'DRIVING') {
          obj.Movements.splice(
            j + 1,
            0,
            AddDrivingNull(MovementList, item, date)
          );
          j++;
        }
      }

      if (i !== newDailyProgram.length - 1 && mov === 'DEPARTURE') {
        if (obj.Movements[j + 1] !== undefined) {
          if (obj.Movements[j + 1].MovementName !== 'FLIGHTTIME') {
            obj.Movements.splice(
              j + 1,
              0,
              AddMovmentFlight(MovementList, item, date)
            );
            j++;
          }
        } else {
          obj.Movements.push(AddMovmentFlight(MovementList, item, date));
        }
      }
    }
    return obj;
  });

  return newDailyProgram;
};

//untuk mengecek item pada dayend
export const checkDayEnd = DailyProgramTemp => {
  let dpTemp = copyObject(DailyProgramTemp);
  dpTemp.map((daily, i) => {
    let dayEndIndex = daily.Movements.findIndex(
      item => item.MovementName === 'DAYEND'
    );
    if (dayEndIndex > 0) {
      if (daily.Movements[dayEndIndex - 1].MovementName === 'DRIVING') {
        daily.Movements[dayEndIndex].Destination =
          dpTemp[i + 1].Movements[0].Destination;
        daily.Movements[dayEndIndex].Item = dpTemp[i + 1].Movements[0].Item;
      }
    }
    return daily;
  });
  return dpTemp;
};

export const generateFreeTimeNew = (DailyProgramTemp, MovementList) => {
  let dpTemp = copyObject(DailyProgramTemp);
  dpTemp.map((daily, iDaily) => {
    for (let j = 0; j < daily.Movements.length; j++) {
      if (daily.Movements[j].MovementName === 'DRIVING') {
        // tambah freetime jika next day departure
        if (
          daily.Movements.length - 1 === j
            ? dpTemp[iDaily + 1]
              ? dpTemp[iDaily + 1].Movements[0]
                ? dpTemp[iDaily + 1].Movements[0]
                  ? dpTemp[iDaily + 1].Movements[0].MovementName === 'DEPARTURE'
                  : false
                : false
              : false
            : false
        ) {
          let tgl = SumSecond(
            daily.Movements[j].DateTime,
            daily.Movements[j].Duration
          );
          daily.Movements.splice(
            j + 1,
            0,
            addFreeTime(
              MovementList,
              tgl,
              dpTemp[iDaily + 1].Movements[0],
              0,
              false
            )
          );
          j++;
        }
        // tambah freetime jika next day departure
        else if (
          ['CHECKIN', 'DEPARTURE', 'DAYEND'].indexOf(
            daily.Movements[j + 1].MovementName
          ) !== -1
        ) {
          let tgl = SumSecond(
            daily.Movements[j].DateTime,
            daily.Movements[j].Duration
          );
          daily.Movements.splice(
            j + 1,
            0,
            addFreeTime(MovementList, tgl, daily.Movements[j + 1], 0, false)
          );
          j++;
        }
      }
      if (daily.Movements[j].MovementName === 'FREETIMELOCKED') {
        // daily.Movements[j].Item =
        //   daily.Movements[j - 1].MovementName !== 'DRIVING'
        //     ? daily.Movements[j - 1].Item
        //     : daily.Movements[j + 1].Item;
        if (daily.Movements[j - 1].MovementName === 'DRIVING') {
          // daily.Movements[j].Item = daily.Movements[j + 1].Item;
          // daily.Movements[j].Item = daily.Movements[j + 1].Item;
          let nextMove = daily.Movements[j + 1];
          let nextDay = dpTemp[iDaily + 1];
          daily.Movements[j].Item = nextMove
            ? nextMove.Item
            : nextDay
            ? nextDay.Movements[0].Item
            : null;
          let firstDatetime = daily.Movements[j].DateTime;
          // let secondDatetime = daily.Movements[j + 1].DateTime;
          let secondDatetime = nextMove
            ? nextMove.DateTime
            : nextDay
            ? nextDay.Movements[0].DateTime
            : null;
          let duration = Math.abs(
            getNumberOfSecond(firstDatetime, secondDatetime)
          );
          daily.Movements[j].Duration = duration;
        } else {
          daily.Movements[j].Item = daily.Movements[j - 1].Item;
        }
      }
    }
    return daily;
  });
  return dpTemp;
};

// set movement jika null;
export const setDailyProgramTempIfNull = DailyProgramTemp => {
  let dpTemp = copyObject(DailyProgramTemp);
  dpTemp.map(daily => {
    daily.Movements =
      daily.Movements.length === 0
        ? generateProgramDetailsIfNull(daily.Date)
        : daily.Movements;
    return daily;
  });
  return dpTemp;
};

//untuk memperbaiki date movement dengan sequence number
export const fixDateAndSequenceNum = DailyProgramTemp => {
  let dpTemp = copyObject(DailyProgramTemp);
  dpTemp.map(daily => {
    daily.Movements.map((move, j) => {
      move.DateTime =
        convertToStringDate(daily.Date) + convertToStringTime(move.DateTime);
      move.SeqNumber = j + 1;
      return move;
    });
    return daily;
  });
  return dpTemp;
};

//khusus untuk memperbaiki squntial number
export const sequentialNumberinMovement = DailyProgramTemp => {
  let dpTemp = copyObject(DailyProgramTemp);
  dpTemp.map(daily => {
    daily.Movements.map((move, j) => {
      move.SeqNumber = j + 1;
      return move;
    });
    return daily;
  });
  return dpTemp;
};

//fungsi untuk menambahkan 2 objek yaitu day start dan day end
//date : tanggal
//movementlist :array of objek type movement
export const generateProgramDetailsIfNull = (date, MovementList) => {
  let mov = [];
  let mov1 = MovementList.filter(item => item.Name === 'DAYSTART');
  let m = copyObject(Movement);
  m.Id = null;
  m.DateTime = convertToStringDate(date) + 'T08:00:00';
  m.Duration = 0;
  m.MovementDescription = mov1[0].Description;
  m.MovementName = mov1[0].Name;
  m.SeqNumber = 1;
  m.ServiceItemId = mov1[0].ServiceItemId;
  mov.push(m);
  let k = copyObject(Movement);
  mov1 = MovementList.filter(item => item.Name === 'DAYEND');
  k.Id = null;
  k.DateTime = convertToStringDate(date) + 'T20:00:00';
  k.Duration = 0;
  k.MovementDescription = mov1[0].Description;
  k.MovementName = mov1[0].Name;
  k.Note = '';
  k.SeqNumber = 2;
  k.ServiceItemId = mov1[0].ServiceItemId;
  mov.push(k);
  return mov;
};

export const addFreeTime = (MovementList, date, item, duration, type) => {
  let newMovement = copyObject(Movement);
  let move = MovementList.filter(e => e.Name === 'FREETIMELOCKED');
  newMovement.DateTime = date === null ? item.DateTime : date;
  newMovement.SeqNumber = null;
  newMovement.MovementName = move[0].Name;
  newMovement.MovementDescription = 'Free Time';
  newMovement.Duration = duration;
  newMovement.ServiceItemId = move[0].ServiceItemId;
  newMovement.Destination = item.Destination;
  newMovement.DestinationName = item.DestinationName;
  newMovement.OriginDestination = item.OriginDestination;
  newMovement.Note = '';
  newMovement.Item = addItemList(item.Item);
  newMovement.TypeMovement = type;
  return newMovement;
};

export const AddMovmentFlight = (movements, item, date) => {
  let newMovement = copyObject(Movement);
  let move = movements.filter(item => item.Name === 'FLIGHTTIME');
  newMovement.DateTime = date;
  newMovement.SeqNumber = null;
  newMovement.MovementName = move[0].Name;
  newMovement.MovementDescription = move[0].Description;
  newMovement.ServiceItemId = move[0].ServiceItemId;
  newMovement.Destination = item.Destination;
  newMovement.DestinationName = item.DestinationName;
  newMovement.OriginDestination = item.OriginDestination;
  newMovement.Item = addItemList(null);
  newMovement.Duration = 0;
  return newMovement;
};

export const AddDrivingNull = (MovementList, item, date) => {
  let newMovement = copyObject(Movement);
  let move = MovementList.filter(item => item.Name === 'DRIVING');
  newMovement.DateTime = date;
  newMovement.SeqNumber = null;
  newMovement.MovementName = move[0].Name;
  newMovement.MovementDescription = move[0].Description;
  newMovement.ServiceItemId = move[0].ServiceItemId;
  newMovement.Destination = item.Destination;
  newMovement.DestinationName = item.DestinationName;
  newMovement.OriginDestination = item.OriginDestination;
  newMovement.Item = addItemList(null);
  newMovement.Duration = 0;
  return newMovement;
};

export const checkinCheck = (daily, dayIdx, moveIdx, move, MovementList) => {
  let dailyTemp = copyObject(daily);
  let chIdx = dailyTemp[dayIdx].Movements.findIndex(
    item => item.MovementName === 'CHECKIN'
  );
  if (chIdx >= 0)
    if (chIdx <= moveIdx) {
      let n = 0;
      if (move === 'CHECKIN') n = chIdx + 3;
      else n = chIdx + 2;
      if (
        new Date(dailyTemp[dayIdx].Movements[chIdx].DateTime) <
        new Date(dailyTemp[dayIdx].Movements[n].DateTime)
      ) {
        let date = dailyTemp[dayIdx].Movements[n].DateTime;
        let durasi = 0;
        for (let i = 0; i < n; i++) {
          durasi = dailyTemp[dayIdx].Movements[n - 1 - i].Duration;
          let date1 = SubstractSecond(date, durasi);
          dailyTemp[dayIdx].Movements[n - 1 - i].DateTime = date1;
          date = dailyTemp[dayIdx].Movements[n - 1 - i].DateTime;
          if (
            ['CHECKIN', 'CHECKOUT', 'ARRIVAL'].indexOf(
              dailyTemp[dayIdx].Movements[n - 1 - i].MovementName
            ) !== -1
          ) {
            break;
          }
        }

        chIdx = dailyTemp[dayIdx].Movements.findIndex(
          item => item.MovementName === 'CHECKIN'
        );
        if (chIdx > 0)
          if (
            dailyTemp[dayIdx].Movements[chIdx - 1].MovementName ===
            'FREETIMELOCKED'
          ) {
            let firstDatetime = dailyTemp[dayIdx].Movements[chIdx - 1].DateTime;
            let secondDatetime = dailyTemp[dayIdx].Movements[chIdx].DateTime;
            // let duration = getNumberOfSecond(firstDatetime, secondDatetime);
            let duration = Math.abs(
              getNumberOfSecond(firstDatetime, secondDatetime)
            );
            dailyTemp[dayIdx].Movements[chIdx - 1].Duration = duration;
          } else {
            let time = SumSecond(
              dailyTemp[dayIdx].Movements[chIdx - 1].DateTime,
              dailyTemp[dayIdx].Movements[chIdx - 1].Duration
            );
            let firstDatetime = time;
            let secondDatetime = dailyTemp[dayIdx].Movements[chIdx].DateTime;
            // let duration = getNumberOfSecond(firstDatetime, secondDatetime);
            let duration = Math.abs(
              getNumberOfSecond(firstDatetime, secondDatetime)
            );
            dailyTemp[dayIdx].Movements.splice(
              chIdx,
              0,
              addFreeTime(
                MovementList,
                time,
                dailyTemp[dayIdx].Movements[chIdx],
                duration,
                false
              )
            );
          }
      }
    }
  return dailyTemp;
};
// this function is callled when add activity after arrival
// fix the daily program after add activity
export const getFunctionDP = (daily, dayIdx, moveIdx, move, MovementList) => {
  let dailyTemp = copyObject(daily);
  dailyTemp = checkinCheck(dailyTemp, dayIdx, moveIdx, move, MovementList);
  dailyTemp = sequanceDailytoTopNew(
    dailyTemp,
    dayIdx,
    moveIdx,
    move,
    MovementList
  );
  dailyTemp = sequanceDailyProgram(dailyTemp, dayIdx);
  dailyTemp = checkOutTime(dailyTemp, dayIdx);
  dailyTemp = generateFreeTimeDP(dailyTemp, dayIdx, MovementList); //untuk menenntuin freetime dan freetime lock
  dailyTemp = sequentialNumberinMovementByDays(dailyTemp, dayIdx);
  return dailyTemp;
};
//fix the dailu program after edit activity
export const editFunctionDP = (daily, dayIdx, moveIdx, move, MovementList) => {
  let dailyTemp = copyObject(daily);
  dailyTemp = checkinCheck(dailyTemp, dayIdx, moveIdx, move, MovementList);
  dailyTemp = sequanceDailytoTopNewEdit(dailyTemp, dayIdx, moveIdx);
  dailyTemp = sequanceDailyProgram(dailyTemp, dayIdx);
  dailyTemp = checkOutTime(dailyTemp, dayIdx);
  return dailyTemp;
};
//fix daily program after edit in last day
export const editFunctionDPBot = (daily, dayIdx, moveIdx, MovementList) => {
  let dailyTemp = copyObject(daily);
  dailyTemp = sequanceDailytoTopNewEdit(dailyTemp, dayIdx, moveIdx);
  dailyTemp = sequanceDailyProgram(dailyTemp, dayIdx);
  dailyTemp = sequanceDailyProgramFromLast(dailyTemp, dayIdx); //Old function
  dailyTemp = checkOutTime(dailyTemp, dayIdx);
  dailyTemp = generateFreeTimeDP(dailyTemp, dayIdx, MovementList);
  dailyTemp = sequentialNumberinMovementByDays(dailyTemp, dayIdx);
  return dailyTemp;
};
//fix daily program after add activity in lasy day
export const getFunctionDPBot = (
  daily,
  dayIdx,
  moveIdx,
  move,
  MovementList
) => {
  let dailyTemp = copyObject(daily);
  dailyTemp = sequanceDailytoTopNew(
    dailyTemp,
    dayIdx,
    moveIdx,
    move,
    MovementList
  );
  //experiment
  dailyTemp = fixDailyAfterMovement(
    dailyTemp,
    dayIdx,
    ['DAYSTART', 'CHECKIN', 'VIRTUALCHECKIN'].indexOf(move) !== -1
      ? moveIdx + 3
      : moveIdx + 2
  );
  dailyTemp = sequanceDailyProgram(dailyTemp, dayIdx);
  dailyTemp = sequanceDailyProgramFromLast(dailyTemp, dayIdx); //Old function
  dailyTemp = checkOutTime(dailyTemp, dayIdx);
  dailyTemp = generateFreeTimeDP(dailyTemp, dayIdx, MovementList);
  dailyTemp = sequentialNumberinMovementByDays(dailyTemp, dayIdx);
  return dailyTemp;
};

export const getFunctionAfterDriving = DailyProgramTemp => {
  let newDpTemp = copyObject(DailyProgramTemp);
  newDpTemp = sequentialNumberinMovement(newDpTemp);
  newDpTemp = addDurationFlightTime(newDpTemp);
  newDpTemp = sequenceDP(newDpTemp);
  newDpTemp = checkDurationFreetime(newDpTemp); //fungsi untuk freetime yang bawahnya dayend
  return newDpTemp;
};
const addDurationFlightTime = dailyProgramTemp => {
  let newDpTemp = copyObject(dailyProgramTemp);
  newDpTemp.map(daily => {
    daily.Movements.map((move, i) => {
      if (move.MovementName === 'FLIGHTTIME') {
        move.Duration = getNumberOfSecond(
          move.DateTime,
          daily.Movements[i + 1].DateTime
        );
      }
      return move;
    });
    return daily;
  });
  return newDpTemp;
};

export const sequenceDP = DailyProgramTemp => {
  for (let i = 0; i < DailyProgramTemp.length - 1; i++) {
    let duration = DailyProgramTemp[i].Movements[0].Duration;
    let date = DailyProgramTemp[i].Movements[0].DateTime;

    for (let j = 1; j < DailyProgramTemp[i].Movements.length; j++) {
      let date1 = SumSecond(date, duration);
      if (
        DailyProgramTemp[i].Movements[j - 1].MovementName === 'CHECKIN' &&
        DailyProgramTemp[i].Movements[j].MovementName === 'CHECKOUT'
      )
        break;
      if (
        DailyProgramTemp[i].Movements[j - 1].MovementName === 'CHECKIN' &&
        DailyProgramTemp[i].Movements[j].MovementName === 'DAYEND'
      )
        break;
      if (
        DailyProgramTemp[i].Movements[j - 1].MovementName === 'DAYSTART' &&
        DailyProgramTemp[i].Movements[j].MovementName === 'DAYEND'
      )
        break;

      DailyProgramTemp[i].Movements[j].DateTime = date1;
      if (DailyProgramTemp[i].Movements[j].MovementName === 'FREETIMELOCKED') {
        let nextMove = DailyProgramTemp[i].Movements[j + 1];
        let nextDay = DailyProgramTemp[i + 1]
          ? DailyProgramTemp[i + 1].Movements[0]
          : false;
        // let b = DailyProgramTemp[i].Movements[j + 1].DateTime;
        let b = nextMove
          ? nextMove.DateTime
          : nextDay
          ? nextDay.DateTime
          : null;
        let a = DailyProgramTemp[i].Movements[j].DateTime;
        // let newDuration = getNumberOfSecond(a, b);
        let newDuration = Math.abs(getNumberOfSecond(a, b));
        if (newDuration < 0) newDuration = 0;
        DailyProgramTemp[i].Movements[j].Duration = newDuration;
      }
      date = DailyProgramTemp[i].Movements[j].DateTime;
      duration = DailyProgramTemp[i].Movements[j].Duration;
    }
  }

  let idx = DailyProgramTemp.length - 1;
  let idp = DailyProgramTemp[idx].Movements.length;
  let date = DailyProgramTemp[idx].Movements[idp - 1].DateTime;
  for (let j = 1; j < DailyProgramTemp[idx].Movements.length; j++) {
    if (
      DailyProgramTemp[idx].Movements[idp - 1 - j].MovementName ===
        'CHECKOUT' &&
      DailyProgramTemp[idx].Movements[idp - 2 - j].MovementName === 'CHECKIN'
    ) {
      let date1 = SubstractSecond(
        date,
        DailyProgramTemp[idx].Movements[idp - 1 - j].Duration
      );
      DailyProgramTemp[idx].Movements[idp - 1 - j].DateTime = date1;
      break;
    }
    if (
      DailyProgramTemp[idx].Movements[idp - 1 - j].MovementName ===
      'FREETIMELOCKED'
    ) {
      let time = SumSecond(
        DailyProgramTemp[idx].Movements[idp - 2 - j].DateTime,
        DailyProgramTemp[idx].Movements[idp - 2 - j].Duration
      );
      let b = date;
      let a = time;
      // let newDuration = getNumberOfSecond(a, b);
      let newDuration = Math.abs(getNumberOfSecond(a, b));
      if (newDuration < 0) newDuration = 0;
      DailyProgramTemp[idx].Movements[idp - 1 - j].Duration = newDuration;
      let dateNew = SubstractSecond(date, newDuration);
      DailyProgramTemp[idx].Movements[idp - 1 - j].DateTime = dateNew;
    } else {
      let date1 = SubstractSecond(
        date,
        DailyProgramTemp[idx].Movements[idp - 1 - j].Duration
      );
      DailyProgramTemp[idx].Movements[idp - 1 - j].DateTime = date1;
    }
    date = DailyProgramTemp[idx].Movements[idp - 1 - j].DateTime;
  }
  return DailyProgramTemp;
};

export const checkDurationFreetime = oldDp => {
  let dpTemp = copyObject(oldDp);
  for (let i = 0; i < dpTemp.length; i++) {
    for (let j = 0; j < dpTemp[i].Movements.length; j++) {
      let move = dpTemp[i].Movements[j].MovementName;
      // let duration = dpTemp[i].Movements[j].Duration;
      if (move === 'FREETIMELOCKED') {
        if (
          dpTemp[i].Movements.length - 1 === j
            ? dpTemp[i + 1]
              ? dpTemp[i + 1].Movements[0]
                ? dpTemp[i + 1].Movements[0].MovementName === 'DEPARTURE'
                : false
              : false
            : false
        ) {
          let a = oldDp[i + 1].Movements[0].DateTime;
          let b = oldDp[i].Movements[j].DateTime;
          // let duration = getNumberOfSecond(b, a);
          let duration = Math.abs(getNumberOfSecond(b, a));
          dpTemp[i].Movements[j].Duration = duration;
        } else if (
          dpTemp[i].Movements[j + 1]
            ? dpTemp[i].Movements[j + 1].MovementName === 'DAYEND'
            : false
        ) {
          let a = oldDp[i + 1].Movements[0].DateTime;
          let b = oldDp[i].Movements[j].DateTime;
          // let duration = getNumberOfSecond(b, a);
          let duration = Math.abs(getNumberOfSecond(b, a));
          dpTemp[i].Movements[j].Duration = duration;
        }
      }

      if (move === 'FLIGHTTIME') {
        if (dpTemp[i].Movements[j + 1].MovementName === 'DAYEND') {
          let b = dpTemp[i + 1].Movements[0].DateTime;
          let a = dpTemp[i].Movements[j - 1].DateTime;
          // let duration = getNumberOfSecond(a, b);
          let duration = Math.abs(getNumberOfSecond(a, b));
          if (duration < 0) duration = 0;
          dpTemp[i].Movements[j].Duration = duration;
          dpTemp[i].Movements[j + 1].Item = dpTemp[i + 1].Movements[0].Item;
        }
      }
    }
  }
  return dpTemp;
};
//list attraction
//menghapus isi list atraksi
//listAttraction: list attraksi
export const destroyListAtt = listAttraction => {
  let list = copyObject(listAttraction);
  list.length = 0;
  return list;
};
//menambahkan object atraksi ke list atraksi
//listAttraction: list attraksi
//param: object atraksi
export const setListAttraction = (listAttraction, param) => {
  let list = copyObject(listAttraction);
  list.push(param);
  return list;
  // listAttraction.push(param);
};
// menambahkan aktivitas freetime dan meal pada daily program
//daily : Array daily program yang lama
//id: index dari daily prgram yang sedang dirubah saat ini
//moveIdx: index dari movement saat ini
//par : parameter boolean
//attraction: attraction data
//activityData:
export const addActivityEatFreeTime = (
  DailyProgram,
  id,
  moveIdx,
  move,
  activityData
) => {
  let dailyTemp = copyObject(DailyProgram);
  var item = dailyTemp[id].Movements[moveIdx].Item;
  if (move === 'CHECKIN' || move === 'DAYSTART') {
    dailyTemp[id].Movements.splice(moveIdx + 1, 0, addLeaveAco(item, 1));
    dailyTemp[id].Movements.splice(moveIdx + 2, 0, AddDriving(2));
    dailyTemp[id].Movements.splice(moveIdx + 3, 0, addEatFreeTime(item, 3));
    dailyTemp[id].Movements.splice(moveIdx + 4, 0, AddDriving(4));
    dailyTemp[id].Movements.splice(moveIdx + 5, 0, addReaturnAco(item, 5));
  } else if (move === 'VIRTUALCHECKIN') {
    dailyTemp[id].Movements.splice(moveIdx + 1, 0, virtualLeave(item, 1));
    dailyTemp[id].Movements.splice(moveIdx + 2, 0, AddDriving(2));
    dailyTemp[id].Movements.splice(moveIdx + 3, 0, addEatFreeTime(item, 3));
    dailyTemp[id].Movements.splice(moveIdx + 4, 0, AddDriving(4));
    dailyTemp[id].Movements.splice(
      moveIdx + 5,
      0,
      virtualReturn(dailyTemp[id].Movements[moveIdx + 3].Item, 5)
    );
    dailyTemp[id].Movements[moveIdx + 6].Item =
      dailyTemp[id].Movements[moveIdx + 5].Item;
  } else {
    dailyTemp[id].Movements.splice(
      moveIdx + 1,
      0,
      AddDriving2(item, activityData, moveIdx)
    );
    dailyTemp[id].Movements.splice(
      moveIdx + 2,
      0,
      addEatFreeTime(item, moveIdx)
    );
    if (
      dailyTemp[id].Movements[moveIdx + 4].MovementName ===
      'VIRTUALRETURNACCOMMODATION'
    ) {
      dailyTemp[id].Movements[moveIdx + 4].Item =
        dailyTemp[id].Movements[moveIdx + 2].Item;
      dailyTemp[id].Movements[moveIdx + 5].Item =
        dailyTemp[id].Movements[moveIdx + 2].Item;
    }
  }
  return dailyTemp;
};

// menambahkan aktivitas pada daily program
//DailyProgram : Array daily program yang lama
//id: index dari daily prgram yang sedang dirubah saat ini
//moveIdx: index dari movement saat ini
//TypeMovement : object dari movment yang dipih
//itemMovement: object dari excursion and resto, klo freetime kirim aja null
//activityData: object activty data
export const addDailyDetails = (
  DailyProgram,
  id,
  moveIdx,
  TypeMovement,
  MovementList,
  itemMovement,
  activityData
) => {
  let dailyTemp = copyObject(DailyProgram);
  let move = dailyTemp[id].Movements[moveIdx].MovementName;
  let item =
    ['CHECKIN', 'DAYSTART', 'LEAVEACCOMMODATION', 'RECREATION'].indexOf(
      move
    ) !== -1
      ? dailyTemp[id].Movements[moveIdx].Item
      : null;
  if (['CHECKIN', 'DAYSTART'].indexOf(move) !== -1) {
    dailyTemp[id].Movements.splice(
      moveIdx + 1,
      0,
      addLeaveAco(dailyTemp, MovementList, item, id, moveIdx)
    );
    dailyTemp[id].Movements.splice(
      moveIdx + 2,
      0,
      AddDriving(dailyTemp, id, moveIdx, MovementList)
    );

    if (TypeMovement.Name === 'RECREATION')
      dailyTemp[id].Movements.splice(
        moveIdx + 3,
        0,
        addMovement(
          dailyTemp,
          TypeMovement,
          itemMovement,
          activityData,
          id,
          moveIdx
        )
      );
    else
      dailyTemp[id].Movements.splice(
        moveIdx + 3,
        0,
        addEatFreeTime(
          dailyTemp,
          id,
          moveIdx,
          item,
          itemMovement,
          TypeMovement,
          activityData
        )
      );

    dailyTemp[id].Movements.splice(
      moveIdx + 4,
      0,
      AddDriving(dailyTemp, id, moveIdx + 3, MovementList)
    );
    dailyTemp[id].Movements.splice(
      moveIdx + 5,
      0,
      addReaturnAco(dailyTemp, MovementList, item, id, moveIdx)
    );
  } else if (move === 'VIRTUALCHECKIN') {
    dailyTemp[id].Movements.splice(
      moveIdx + 1,
      0,
      virtualLeave(
        dailyTemp,
        MovementList,
        dailyTemp[id].Movements[moveIdx].Item,
        id,
        moveIdx
      )
    );
    dailyTemp[id].Movements.splice(
      moveIdx + 2,
      0,
      AddDriving(dailyTemp, id, moveIdx + 1, MovementList)
    );
    if (TypeMovement.Name === 'RECREATION')
      dailyTemp[id].Movements.splice(
        moveIdx + 3,
        0,
        addMovement(
          dailyTemp,
          TypeMovement,
          itemMovement,
          activityData,
          id,
          moveIdx + 3
        )
      );
    else
      dailyTemp[id].Movements.splice(
        moveIdx + 3,
        0,
        addEatFreeTime(
          dailyTemp,
          id,
          moveIdx + 3,
          item,
          itemMovement,
          TypeMovement,
          activityData
        )
      );

    dailyTemp[id].Movements.splice(
      moveIdx + 4,
      0,
      AddDriving(dailyTemp, id, moveIdx + 3, MovementList)
    );
    dailyTemp[id].Movements.splice(
      moveIdx + 5,
      0,
      virtualReturn(
        dailyTemp,
        MovementList,
        dailyTemp[id].Movements[moveIdx + 3].Item,
        id,
        moveIdx + 5
      )
    );

    dailyTemp[id].Movements[moveIdx + 6].Item =
      dailyTemp[id].Movements[moveIdx + 5].Item;
  } else {
    dailyTemp[id].Movements.splice(
      moveIdx + 1,
      0,
      AddDriving(dailyTemp, id, moveIdx, MovementList)
    );
    if (TypeMovement.Name === 'RECREATION')
      dailyTemp[id].Movements.splice(
        moveIdx + 2,
        0,
        addMovement(
          dailyTemp,
          TypeMovement,
          itemMovement,
          activityData,
          id,
          moveIdx + 1
        )
      );
    else
      dailyTemp[id].Movements.splice(
        moveIdx + 2,
        0,
        addEatFreeTime(
          dailyTemp,
          id,
          moveIdx,
          item,
          itemMovement,
          TypeMovement,
          activityData
        )
      );

    if (
      dailyTemp[id].Movements[moveIdx + 4].MovementName ===
      'VIRTUALRETURNACCOMMODATION'
    ) {
      dailyTemp[id].Movements[moveIdx + 4].Item =
        dailyTemp[id].Movements[moveIdx + 2].Item;
      dailyTemp[id].Movements[moveIdx + 5].Item =
        dailyTemp[id].Movements[moveIdx + 2].Item;
    }
  }
  dailyTemp = fixDrivingDestination(dailyTemp);
  return dailyTemp;
};

//untuk set duration
//daily : Array daily program yang lama
//id: index dari daily prgram yang sedang dirubah saat ini
//moveIdx: index dari movement saat ini
//par : parameter boolean
export const sequanceDailyDPDays = (daily, id, moveIdx, par) => {
  let idx = 0;
  let dailyTemp = copyObject(daily);
  idx = id ? (par ? moveIdx - 3 : moveIdx - 2) : 0;
  let duration = dailyTemp[id].Movements[idx].Duration;
  let dataLength = dailyTemp[id].Movements.length;
  let date = dailyTemp[id].Movements[idx].DateTime;
  for (let i = idx + 1; i < dataLength; i++) {
    let activity = dailyTemp[id].Movements[i];
    let date1 = SumSecond(date, duration);
    activity.DateTime = date1; // date1.slice(0, -6);
    date = activity.DateTime;
    duration = activity.Duration;
    activity.SeqNumber = i + 1;
    if (
      ['VIRTUALCHECKIN', 'VIRTUALCHECKOUT'].indexOf(activity.MovementName) !==
      -1
    )
      dailyTemp[id].Movements[i].Item = dailyTemp[id].Movements[i - 1].Item;
    if (dailyTemp[id].Movements[i + 1] !== undefined) {
      if (dailyTemp[id].Movements[i + 1].MovementName === 'FREETIMELOCKED') {
        let newDate = SumSecond(activity.DateTime, activity.Duration);
        let firstDateTime = newDate;
        // let secondDateTime = dailyTemp[id].Movements[i + 2].DateTime;
        let nextDay = dailyTemp[id + 1];
        let secondDateTime = dailyTemp[id].Movements[i + 2]
          ? dailyTemp[id].Movements[i + 2].DateTime
          : nextDay
          ? nextDay.Movements[0].MovementName === 'DEPARTURE'
            ? nextDay.Movements[0].DateTime
            : null
          : null;
        // let duration = getNumberOfSecond(firstDateTime, secondDateTime);
        let duration = Math.abs(
          getNumberOfSecond(firstDateTime, secondDateTime)
        );
        dailyTemp[id].Movements[i + 1].Duration = duration < 0 ? 0 : duration;
      }
    }
  }
  return dailyTemp;
};

//untuk untuk memperbaiki time
//daily : Array daily program yang lama
//id: index dari daily prgram yang sedang dirubah saat ini
//moveIdx: index dari movement saat ini
export const sequanceDailytoTopNewEdit = (daily, dayIdx, moveIdx) => {
  let dailyTemp = copyObject(daily);
  // let n = IdP;
  let duration = 0;
  //if n = 5;
  let date = dailyTemp[dayIdx].Movements[moveIdx].DateTime;
  for (let i = 0; i < moveIdx; i++) {
    if (dailyTemp[dayIdx].Movements[moveIdx - 1 - i].MovementName === 'ARRIVAL')
      break;
    duration = dailyTemp[dayIdx].Movements[moveIdx - 1 - i].Duration;
    dailyTemp[dayIdx].Movements[moveIdx - 1 - i].DateTime = SubstractSecond(
      date,
      duration
    );
    date = dailyTemp[dayIdx].Movements[moveIdx - 1 - i].DateTime;
  }
  return dailyTemp;
};

//untuk untuk memperbaiki time
//daily : Array daily program yang lama
//id: index dari daily prgram yang sedang dirubah saat ini
//moveIdx: index dari movement saat ini
//move: movement name saat ini
export const sequanceDailytoTopNew = (
  daily,
  dayIdx,
  moveIdx,
  move,
  MovementList
) => {
  let dailyTemp = copyObject(daily);
  let addVariable =
    ['DAYSTART', 'CHECKIN', 'VIRTUALCHECKIN'].indexOf(move) !== -1 ? 3 : 2;
  // if (mov === "DAYSTART" || mov === "CHECKIN" || mov === "VIRTUALCHECKIN") x = 3;
  // else x = 2;
  let n = moveIdx + addVariable;
  let duration = 0;

  let date = dailyTemp[dayIdx].Movements[n].DateTime;
  for (let i = 0; i < n; i++) {
    if (dailyTemp[dayIdx].Movements[n - 1 - i].MovementName === 'ARRIVAL') {
      if (
        dailyTemp[dayIdx].Movements[n - i].MovementName === 'FREETIMELOCKED'
      ) {
        dailyTemp[dayIdx].Movements[n - i].DateTime =
          dailyTemp[dayIdx].Movements[n - 1 - i].DateTime;
        let firstDateTime = dailyTemp[dayIdx].Movements[n - 1 - i].DateTime;
        let secondDateTime = dailyTemp[dayIdx].Movements[n + 1 - i].DateTime;
        // let duration2 = getNumberOfSecond(firstDateTime, secondDateTime);
        let duration2 = Math.abs(
          getNumberOfSecond(firstDateTime, secondDateTime)
        );
        dailyTemp[dayIdx].Movements[n - i].Duration =
          duration2 <= 0 ? 0 : duration2;
      } else {
        let firstDateTime = dailyTemp[dayIdx].Movements[n - 1 - i].DateTime;
        let secondDateTime = dailyTemp[dayIdx].Movements[n - i].DateTime;
        // let duration2 = getNumberOfSecond(firstDateTime, secondDateTime);
        let duration2 = Math.abs(
          getNumberOfSecond(firstDateTime, secondDateTime)
        );
        if (duration2 <= 0) duration2 = 0;
        dailyTemp[dayIdx].Movements.splice(
          n - i,
          0,
          addFreeTime(
            MovementList,
            dailyTemp[dayIdx].Movements[n - 1 - i].DateTime,
            dailyTemp[dayIdx].Movements[n - 1 - i],
            duration2,
            false
          )
        );
      }
      break;
    }

    if (
      dailyTemp[dayIdx].Movements[n - 1 - i].MovementName === 'RECREATION' &&
      dailyTemp[dayIdx].Movements[n - 1 - i].Item.ServiceType === 'Package'
    )
      break;
    duration = dailyTemp[dayIdx].Movements[n - 1 - i].Duration;
    let date1 = SubstractSecond(date, duration);
    dailyTemp[dayIdx].Movements[n - 1 - i].DateTime = date1;
    date = dailyTemp[dayIdx].Movements[n - 1 - i].DateTime;
  }
  return dailyTemp;
};

//untuk untuk memperbaiki time
//daily : Array daily program yang lama
//id: index dari daily prgram yang sedang dirubah saat ini
export const sequanceDailyProgram = (daily, dayIdx) => {
  let dailyTemp = copyObject(daily);
  let arrivalIdx = dailyTemp[dayIdx].Movements.findIndex(
    e => e.MovementName === 'ARRIVAL'
  );
  if (arrivalIdx <= 0) arrivalIdx = 0;
  let duration = dailyTemp[dayIdx].Movements[arrivalIdx].Duration;
  let date = new Date(dailyTemp[dayIdx].Movements[arrivalIdx].DateTime);
  dailyTemp[dayIdx].Movements[0].SeqNumber = 1;
  for (let i = arrivalIdx + 1; i < dailyTemp[dayIdx].Movements.length; i++) {
    if (dailyTemp[dayIdx].Movements[i].MovementName === 'DEPARTURE') break;

    ////7-13-2018 tambahan ketika ada checkin checkout
    // if(dailyTemp[id].Movements[i].MovementName === "CHECKIN") {
    //   if (dailyTemp[id].Movements[i + 1].MovementName === "CHECKOUT") break;
    // }
    let date1 = SumSecond(date, duration);
    dailyTemp[dayIdx].Movements[i].DateTime = date1;

    if (
      dailyTemp[dayIdx].Movements[i].MovementName === 'CHECKIN' &&
      dailyTemp[dayIdx].Movements[i - 1].MovementName === 'FREETIMELOCKED'
    ) {
      dailyTemp[dayIdx].Movements[i].DateTime = date1;
      let firstDateTime = dailyTemp[dayIdx].Movements[i].DateTime;
      let secondDateTime = dailyTemp[dayIdx].Movements[i - 1].DateTime;
      // let newDuration = getNumberOfSecond(secondDateTime, firstDateTime);
      let newDuration = Math.abs(
        getNumberOfSecond(secondDateTime, firstDateTime)
      );
      dailyTemp[dayIdx].Movements[i - 1].Duration = newDuration;
    } else if (
      dailyTemp[dayIdx].Movements[i].MovementName === 'DAYEND' &&
      dailyTemp[dayIdx].Movements[i - 1].MovementName === 'FREETIMELOCKED'
    ) {
      if (new Date(date1) <= new Date(date1.slice(0, -9) + 'T23:59:00')) {
        dailyTemp[dayIdx].Movements[i].DateTime =
          convertToStringDate(date1) + 'T23:59:00';
        let firstDateTime = dailyTemp[dayIdx].Movements[i].DateTime;
        let secondDateTime = dailyTemp[dayIdx].Movements[i - 1].DateTime;
        // let newDuration = getNumberOfSecond(firstDateTime, secondDateTime);
        let newDuration = Math.abs(
          getNumberOfSecond(firstDateTime, secondDateTime)
        );
        dailyTemp[dayIdx].Movements[i - 1].Duration = newDuration;
      } else {
        dailyTemp[dayIdx].Movements[i].DateTime = date1;
        let firstDateTime = dailyTemp[dayIdx].Movements[i].DateTime;
        let secondDateTime = dailyTemp[dayIdx].Movements[i - 1].DateTime;
        // let newDuration = getNumberOfSecond(firstDateTime, secondDateTime);
        let newDuration = Math.abs(
          getNumberOfSecond(firstDateTime, secondDateTime)
        );
        dailyTemp[dayIdx].Movements[i - 1].Duration = newDuration;
      }
    } else if (
      dailyTemp[dayIdx].Movements[i].MovementName === 'FREETIMELOCKED'
    ) {
      if (
        dailyTemp[dayIdx].Movements.length - 1 === i
          ? dailyTemp[dayIdx + 1]
            ? dailyTemp[dayIdx + 1].Movements[0]
              ? dailyTemp[dayIdx + 1].Movements[0].MovementName === 'DEPARTURE'
              : false
            : false
          : false
      ) {
        let secondDateTime = dailyTemp[dayIdx + 1].Movements[0].DateTime;
        let firstDateTime = dailyTemp[dayIdx].Movements[i].DateTime;
        // let newDuration = getNumberOfSecond(firstDateTime, secondDateTime);
        let newDuration = Math.abs(
          getNumberOfSecond(firstDateTime, secondDateTime)
        );
        if (newDuration < 0) dailyTemp[dayIdx].Movements[i].Duration = 0;
        else dailyTemp[dayIdx].Movements[i].Duration = newDuration;
      } else if (
        dailyTemp[dayIdx].Movements[i + 1].MovementName === 'DEPARTURE'
      ) {
        let date = SumSecond(
          dailyTemp[dayIdx].Movements[i - 1].DateTime,
          dailyTemp[dayIdx].Movements[i - 1].Duration
        );
        dailyTemp[dayIdx].Movements[i].DateTime = date;
        let firstDateTime = dailyTemp[dayIdx].Movements[i + 1].DateTime;
        let secondDateTime = date;
        // let newDuration = getNumberOfSecond(firstDateTime, secondDateTime);
        let newDuration = Math.abs(
          getNumberOfSecond(firstDateTime, secondDateTime)
        );
        dailyTemp[dayIdx].Movements[i].Duration = newDuration;
      } else {
        let secondDateTime = dailyTemp[dayIdx].Movements[i + 1].DateTime;
        let firstDateTime = dailyTemp[dayIdx].Movements[i].DateTime;
        // let newDuration = getNumberOfSecond(firstDateTime, secondDateTime);
        let newDuration = getNumberOfSecond(firstDateTime, secondDateTime);
        if (newDuration < 0) dailyTemp[dayIdx].Movements[i].Duration = 0;
        else dailyTemp[dayIdx].Movements[i].Duration = newDuration;
      }
    }
    duration = dailyTemp[dayIdx].Movements[i].Duration;
    date = dailyTemp[dayIdx].Movements[i].DateTime;
    dailyTemp[dayIdx].Movements[i].SeqNumber = i + 1;
  }
  return dailyTemp;
};
export const sequanceDailyProgramFromLast = (daily, dayIdx) => {
  let dailyTemp = copyObject(daily);
  let duration = 0;
  let idx = dailyTemp[dayIdx].Movements.findIndex(
    e => e.MovementName === 'DEPARTURE'
  );
  let n = idx >= 0 ? idx : dailyTemp[dayIdx].Movements.length - 1;
  let date = dailyTemp[dayIdx].Movements[n].DateTime;
  dailyTemp[dayIdx].Movements[n].SeqNumber = n;
  for (let i = 0; i < n; i++) {
    if (
      dailyTemp[dayIdx].Movements[n - 1 - i].MovementName === 'FREETIMELOCKED'
    ) {
      let firstDateTime = dailyTemp[dayIdx].Movements[n - 1 - i].DateTime;
      let secondDateTime = dailyTemp[dayIdx].Movements[n - i].DateTime;
      // let newDuration = getNumberOfSecond(firstDateTime, secondDateTime);
      let newDuration = Math.abs(
        getNumberOfSecond(firstDateTime, secondDateTime)
      );
      if (newDuration < 0) dailyTemp[dayIdx].Movements[n - 1 - i].Duration = 0;
      else dailyTemp[dayIdx].Movements[n - 1 - i].Duration = newDuration;
    }

    duration = dailyTemp[dayIdx].Movements[n - 1 - i].Duration;
    let date1 = SubstractSecond(date, duration);
    dailyTemp[dayIdx].Movements[n - 1 - i].DateTime = date1;
    dailyTemp[dayIdx].Movements[n - 1 - i].SeqNumber = n - i - 1;
    date = dailyTemp[dayIdx].Movements[n - 1 - i].DateTime;
  }
  return dailyTemp;
};
//untuk untuk memperbaiki time dari check out jika melebihi jam yang ditentukan
//daily : Array daily program yang lama
//id: index dari daily prgram yang sedang dirubah saat ini
export const checkOutTime = (daily, dayIdx) => {
  let dailyTemp = copyObject(daily);
  let idx = dailyTemp[dayIdx].Movements.findIndex(
    e => e.MovementName === 'CHECKOUT'
  );
  if (idx >= 0) {
    let dp = dailyTemp[dayIdx].Movements[idx];
    if (getHour(dp.DateTime) >= 12) {
      dailyTemp[dayIdx].Movements[idx].DateTime = `${convertToStringDate(
        dp.DateTime
      )}T12:00:00`;
      dailyTemp = changeTimeDailyDetailProgramTop(dailyTemp, idx, dayIdx);
      dailyTemp = sequanceDailyProgram(dailyTemp, dayIdx);
    }
  }
  return dailyTemp;
};

//untuk untuk memperbaiki date time dari move paling atas ke movement tertentu
//daily : Array daily program yang lama
//id: index dari daily prgram yang sedang dirubah saat ini
//moveIdx :  index dari Movements yang sedang dirubah saat ini
export const changeTimeDailyDetailProgramTop = (daily, moveIdx, id) => {
  let dailyTemp = copyObject(daily);
  let duration = 0;
  let date = dailyTemp[id].Movements[moveIdx].DateTime;
  let n = moveIdx;
  for (let j = 0; j < n; j++) {
    duration = dailyTemp[id].Movements[n - 1 - j].Duration;
    let date1 = SubstractSecond(date, duration);
    dailyTemp[id].Movements[n - 1 - j].DateTime = date1;
    date = date1;
  }
  return dailyTemp;
};

//untuk merubah date dan durasi dari setelah menghapus suatu aktivitas
//oldDailyProgram : Array daily program yang lama
//newDP: Daily program yang baru hasil balikan fungsi lain
//id: index dari daily prgram yang sedang dirubah saat ini
//moveIdx : index dari movements saat ini
export const fixafterDelete = (oldDailyProgram, newDp, id, movIdx) => {
  let isCheck = checkMovIdxDel(id, movIdx, oldDailyProgram) ? true : false;
  // let idx = id != 0 ? (isCheck ? movIdx - 3 : movIdx - 2) : 0;
  let dailyTemp = copyObject(newDp);
  dailyTemp = sequanceDailyDPDays(newDp, id, movIdx, isCheck); // ini parameternya kurang belum jelas dari mana
  let moveLength = dailyTemp[id].Movements.length;
  dailyTemp =
    isCheck && moveLength === 2
      ? setDefaultTimeDayStartDayEnd(dailyTemp, id)
      : dailyTemp;
  return dailyTemp;
};

//untuk merubah date dan durasi dari freetime
//daily : Array daily program yang lama
//id: index dari daily prgram yang sedang dirubah saat ini
export const checkFreetime = (daily, id, MovementList) => {
  let dailyTemp = copyObject(daily);
  let moveLength = Object.keys(dailyTemp[id].Movements).length;
  let date = dailyTemp[id].Movements[0].DateTime;
  let duration = dailyTemp[id].Movements[0].Duration;
  for (let i = 1; i < moveLength - 1; i++) {
    let freeTimeDate = SumSecond(date, duration);
    if (dailyTemp[id].Movements[i].DateTime !== freeTimeDate) {
      if (dailyTemp[id].Movements[i].MovementName === 'CHECKIN') {
        if (dailyTemp[id].Movements[i - 1].MovementName === 'FREETIMELOCKED') {
          let firstDateTime = dailyTemp[id].Movements[i - 1].DateTime;
          let secondDateTime = dailyTemp[id].Movements[i].DateTime;
          // let newDuration = getNumberOfSecond(firstDateTime, secondDateTime);
          let newDuration = Math.abs(
            getNumberOfSecond(firstDateTime, secondDateTime)
          );
          dailyTemp[id].Movements[i - 1].Duration = newDuration;
        } else {
          let firstDateTime = dailyTemp[id].Movements[i].DateTime;
          let secondDateTime = freeTimeDate;
          // let newDuration = getNumberOfSecond(firstDateTime, secondDateTime);
          let newDuration = Math.abs(
            getNumberOfSecond(firstDateTime, secondDateTime)
          );
          dailyTemp[i].Movements.splice(
            i,
            0,
            addFreeTime(
              MovementList,
              freeTimeDate,
              dailyTemp[id].Movements[i],
              newDuration,
              false
            )
          );
        }
      }
    }
    date = dailyTemp[id].Movements[i].DateTime;
    duration = dailyTemp[id].Movements[i].Duration;
  }
  return dailyTemp;
};

///
//untuk merubah Time dari day start dan day end menjadi waktu default ketika pada hari tersebut cuma ada 2 aktivitas day start dan day end
//daily : Array daily program yang lama
//id: index dari daily prgram yang sedang dirubah saat ini
export const setDefaultTimeDayStartDayEnd = (daily, id) => {
  let dailyTemp = copyObject(daily);
  let length = dailyTemp[id].Movements.length;
  dailyTemp[id].Movements[0].DateTime =
    dailyTemp[id].Movements[0].MovementName === 'DAYSTART' &&
    `${convertToStringDate(dailyTemp[id].Date)}T08:00:00`;
  dailyTemp[id].Movements[length - 1].DateTime =
    dailyTemp[id].Movements[length - 1].MovementName === 'DAYEND' &&
    `${convertToStringDate(dailyTemp[id].Date)}T20:00:00`;

  return dailyTemp;
};

//untuk mengisi item transportasi pada daily program
//daily : Array daily program yang lama
//id: index dari daily prgram yang sedang dirubah saat ini
//moveIdx :  index dari Movements yang sedang dirubah saat ini
//transport : Movement transportasi
//service: service type transportation
//hour: object hour
export const addItemTransportation = (
  daily,
  id,
  moveIdx,
  transport,
  service,
  hour
) => {
  let dailyTemp = copyObject(daily);
  let arrivalIdx = dailyTemp[id].Movements.findIndex(
    e => e.MovementName === 'ARRIVAL'
  );
  let departureIdx = dailyTemp[id].Movements.findIndex(
    e => e.MovementName === 'DEPARTURE'
  );
  let start = moveIdx;
  let end = dailyTemp[id].Movements.length;
  let existTransIdx = 0;
  if (departureIdx >= 0 && arrivalIdx >= 0) {
    //console.log('ada semua');
    if (moveIdx < departureIdx && moveIdx > arrivalIdx) {
      existTransIdx = findExistDriving(
        arrivalIdx,
        moveIdx,
        dailyTemp[id].Movements
      );
      start = existTransIdx !== -1 ? existTransIdx : moveIdx; //arrivalIdx;
      end = departureIdx;
    } else if (moveIdx < departureIdx && departureIdx < arrivalIdx) {
      existTransIdx = findExistDriving(start, moveIdx, dailyTemp[id].Movements);
      start = existTransIdx !== -1 ? existTransIdx : moveIdx; //start;
      end = departureIdx;
    } else if (moveIdx > departureIdx && departureIdx < arrivalIdx) {
      existTransIdx = findExistDriving(
        arrivalIdx,
        moveIdx,
        dailyTemp[id].Movements
      );
      start = existTransIdx !== -1 ? existTransIdx : moveIdx; //arrivalIdx;
    }
  } else {
    existTransIdx = findExistDriving(0, moveIdx, dailyTemp[id].Movements);
  }
  start = existTransIdx !== -1 ? existTransIdx : moveIdx;
  for (let i = start; i < end; i++) {
    if (dailyTemp[id].Movements[i].MovementName === 'DRIVING') {
      let destination = dailyTemp[id].Movements[i + 1].Destination;
      dailyTemp[id].Movements[i].Item = setItemTransport(
        transport,
        service,
        hour,
        destination
      );
    }
  }
  return dailyTemp;
};
const findExistDriving = (iStart, iEnd, movements) => {
  let index = movements.findIndex(
    (item, i) =>
      i >= iStart &&
      i <= iEnd &&
      item.MovementName === 'DRIVING' &&
      item.Item.ServiceItemId
  );
  return index;
};
//untuk mengisi item transportasi
//transport : Movement transportasi
//service: service type transportation
//hour: object hour
//destination : destination transport
export const setItemTransport = (transport, service, hour, destination) => {
  let item = copyObject(ItemMov);
  item.Address = destination;
  item.Hours = hour.Id;
  item.Desc = transport.Description;
  item.ImageName = transport.ImageName;
  item.ImageUrl = transport.ImageUrl;
  item.ServiceItemId = hour.ServiceItemId;
  item.ServiceType = service;
  item.Name = `${transport.TransportationTypeName}, ${transport.TransportationSeatTypeName} Seater`;
  item.CityId = destination;
  item.ProfileDesc = transport.Description;
  item.Capacity = transport.TransportationSeatTypeId;
  return item;
};

//untuk delete transportasi
//daily : Array daily program yang lama
//id: index dari daily prgram yang sedang dirubah saat ini
//moveIdx :  index dari Movements yang sedang dirubah saat ini
export const deleteTransportation = (daily, id, moveIdx) => {
  let dailyTemp = copyObject(daily);
  let arrivalIdx = dailyTemp[id].Movements.findIndex(
    item => item.MovementName === 'ARRIVAL'
  );
  let departureIdx = dailyTemp[id].Movements.findIndex(
    item => item.MovementName === 'DEPARTURE'
  );
  let start = 0;
  let end = dailyTemp[id].Movements.length;

  if (departureIdx >= 0 && arrivalIdx >= 0) {
    if (moveIdx < departureIdx && moveIdx > arrivalIdx) {
      start = arrivalIdx;
      end = departureIdx;
    } else if (moveIdx < departureIdx && departureIdx < arrivalIdx) {
      end = departureIdx;
    } else if (moveIdx > departureIdx && departureIdx < arrivalIdx) {
      start = arrivalIdx;
    }
  }
  for (let i = start; i < end; i++) {
    let item = copyObject(ItemMov);
    if (dailyTemp[id].Movements[i].MovementName === 'DRIVING')
      dailyTemp[id].Movements[i].Item = item;
  }
  return dailyTemp;
};
//untuk mendapatkan item dari driving
//daily : Array daily program yang lama
//id: index dari daily prgram yang sedang dirubah saat ini
export const checkDriving = (daily, id) => {
  let dailyTemp = copyObject(daily);
  let item = null;
  for (let i = 0; i < dailyTemp[id].Movements.length; i++)
    if (dailyTemp[id].Movements[i].MovementName === 'DRIVING') {
      if (dailyTemp[id].Movements[i].Item.ServiceItemId !== null) {
        item = dailyTemp[id].Movements[i].Item;
        break;
      }
    }
  return item;
};

export const addLeaveAco = (daily, movementList, item, id, moveIdx) => {
  //for set datetime
  let dailyTemp = copyObject(daily);
  let newMovement = copyObject(Movement);
  let move = movementList.filter(item => item.Name === 'LEAVEACCOMMODATION');
  newMovement.DateTime = dailyTemp[id].Movements[moveIdx].DateTime;
  newMovement.SeqNumber = null;
  newMovement.MovementName = move[0].Name;
  newMovement.MovementDescription = move[0].Description;
  newMovement.Duration = 0;
  newMovement.ServiceItemId = move[0].ServiceItemId;
  newMovement.Destination = dailyTemp[id].Movements[moveIdx].Destination;
  newMovement.DestinationName =
    dailyTemp[id].Movements[moveIdx].DestinationName;
  newMovement.OriginDestination =
    dailyTemp[id].Movements[moveIdx].OriginDestination;
  newMovement.Item = addItemList(item);
  return newMovement;
};

export const addReaturnAco = (daily, movementsList, item, id, moveIdx) => {
  //for set datetime
  let dailyTemp = copyObject(daily);
  let newMovement = copyObject(Movement);
  let move = movementsList.filter(item => item.Name === 'RETURNACCOMMODATION');
  newMovement.DateTime = dailyTemp[id].Movements[moveIdx].DateTime;
  newMovement.SeqNumber = null;
  newMovement.MovementName = move[0].Name;
  newMovement.MovementDescription = move[0].Description;
  newMovement.Duration = 0;
  newMovement.ServiceItemId = move[0].ServiceItemId;
  newMovement.Destination = dailyTemp[id].Movements[moveIdx].Destination;
  newMovement.DestinationName =
    dailyTemp[id].Movements[moveIdx].DestinationName;
  newMovement.OriginDestination =
    dailyTemp[id].Movements[moveIdx].OriginDestination;
  newMovement.Item = addItemList(item);
  return newMovement;
};
export const addCheckInAco = (movements, item) => {
  //for set datetime
  let newMovement = copyObject(Movement);
  let move = movements.filter(item => item.Name === 'CHECKIN');
  newMovement.DateTime = item ? item.AccommodationSummary.CheckIn : null;
  newMovement.SeqNumber = null;
  newMovement.MovementName = move[0].Name;
  newMovement.MovementDescription = move[0].Description;
  newMovement.Duration = 0;
  newMovement.ServiceItemId = move[0].ServiceItemId;
  newMovement.Destination = item.City.Name ? item.City.Id : null;
  newMovement.DestinationName = item.City.Name ? item.City.Name : null;
  newMovement.OriginDestination = item.City.Name ? item.City.Id : null;
  newMovement.Item = addItemHotel(
    newMovement.Destination,
    item ? item.AccommodationSummary : null,
    'CHECKIN'
  );
  if (item.NextDay) newMovement.TypeMovement = false;

  return newMovement;
};
export const addCheckOutAco = (movements, item) => {
  //for set datetime
  let newMovement = copyObject(Movement);
  let move = movements.filter(item => item.Name === 'CHECKOUT');
  newMovement.DateTime = item.AccommodationSummary.CheckOut;
  newMovement.SeqNumber = null;
  newMovement.MovementName = move[0].Name;
  newMovement.MovementDescription = move[0].Description;
  newMovement.Duration = 0;
  newMovement.ServiceItemId = move[0].ServiceItemId;
  newMovement.Destination = item.City ? item.City.Id : null;
  newMovement.DestinationName = item.City ? item.City.Name : null;
  newMovement.OriginDestination = item.City.Name ? item.City.Id : null;
  newMovement.Item = addItemHotel(
    newMovement.Destination,
    item.AccommodationSummary,
    'CHECKOUT'
  );
  return newMovement;
};

export const addEatFreeTime = (
  daily,
  id,
  moveIdx,
  item,
  resto,
  typeMovement,
  ActivityData
) => {
  //for set datetime
  let dailyTemp = copyObject(daily);
  let newMovement = copyObject(Movement);
  newMovement.DateTime = ActivityData.Startime;
  newMovement.SeqNumber = moveIdx + 1;
  newMovement.MovementName = typeMovement.Name;
  newMovement.MovementDescription = typeMovement.Description;
  newMovement.Duration = ActivityData.Duration;
  newMovement.ServiceItemId = typeMovement.ServiceItemId;
  newMovement.Destination = dailyTemp[id].Movements[moveIdx].Destination;
  if (typeMovement.Name === 'FREETIME') {
    newMovement.DestinationName =
      dailyTemp[id].Movements[moveIdx].DestinationName;
    newMovement.OriginDestination =
      dailyTemp[id].Movements[moveIdx].OriginDestination;
  } else {
    newMovement.DestinationName = resto
      ? resto.AddressObject
        ? resto.AddressObject.City
          ? resto.AddressObject.City.Name
          : null
        : null
      : null;
    newMovement.OriginDestination = resto
      ? resto.AddressObject
        ? resto.AddressObject.City
          ? resto.AddressObject.City.Id
          : null
        : null
      : null;
  }
  newMovement.Note = ActivityData.Note;
  newMovement.Item =
    resto !== undefined && resto !== null
      ? addItemResto(resto)
      : addItemList(item);

  return newMovement;
};
export const addItemList = par => {
  let item = copyObject(ItemMov);
  if (par !== null) {
    item.Name = par.Name;
    item.Desc = par.Desc;
    item.ServiceType = par.ServiceType;
    item.ImageUrl = par.ImageUrl;
    item.ImageName = par.ImageName;
    item.ServiceItemId = par.ServiceItemId;
    item.Address = par.Address;
    item.PlaceId = par.PlaceId;
    item.CityId = par.CityId;
    item.ProfileDesc = par.ProfileDesc;
    if (par.Hours) {
      item.Hours = par.Hours;
    }
  }
  return item;
};
export const addItemResto = resto => {
  let item = copyObject(ItemMov);
  item.Name = resto.Name;
  item.Desc = resto.MenuClass;
  item.ServiceItemId = resto.ServiceItemId;
  item.Address = resto.Address;
  item.ServiceType = resto.TypeOfMenu;
  item.ImageUrl = resto.ImageUrl;
  item.ImageName = resto.ImageName;
  item.CityId = resto.CityId;
  item.ProfileDesc = resto.Description;
  return item;
};
export const addItemAtt = (par, act) => {
  //for attraction
  let item = copyObject(ItemMov);
  if (par !== null) {
    item.Name = par.Name;
    item.Desc = par.Description;
    item.ServiceType = par.AttractionCategory;
    item.ImageUrl = par.ImageUrl;
    item.ImageName = par.ImageName;
    item.ServiceItemId = par.ServiceItemId;
    item.Address = par.AddressObject;
    item.OperationEndTime = act.OperationEndTime;
    item.OperationStartTime = act.OperationStartTime;
    item.IsSolidStartTime = act.IsSolidStartTime;
  }
  return item;
};
export const virtualReturn = (daily, movements, item, id, moveIdx) => {
  //for set datetime
  let dailyTemp = copyObject(daily);
  let newMovement = copyObject(Movement);
  let move = movements.filter(
    item => item.Name === 'VIRTUALRETURNACCOMMODATION'
  );
  newMovement.DateTime = dailyTemp[id].Movements[moveIdx].DateTime;
  newMovement.SeqNumber = null;
  newMovement.MovementName = move[0].Name;
  newMovement.MovementDescription = move[0].Description;
  newMovement.Duration = 0;
  newMovement.ServiceItemId = move[0].ServiceItemId;
  newMovement.Destination = dailyTemp[id].Movements[moveIdx].Destination;
  newMovement.DestinationName =
    dailyTemp[id].Movements[moveIdx].DestinationName;
  newMovement.OriginDestination =
    dailyTemp[id].Movements[moveIdx].OriginDestination;
  newMovement.Item = addItemList(item);
  newMovement.TypeVirtual = true;
  return newMovement;
};
export const virtualLeave = (daily, movementlist, item, id, moveIdx) => {
  //for set datetime
  let dailyTemp = copyObject(daily);
  let newMovement = copyObject(Movement);
  let move = movementlist.filter(
    item => item.Name === 'VIRTUALLEAVEACCOMMODATION'
  );
  newMovement.DateTime = dailyTemp[id].Movements[moveIdx].DateTime;
  newMovement.SeqNumber = null;
  newMovement.MovementName = move[0].Name;
  newMovement.MovementDescription = move[0].Description;
  newMovement.Duration = 0;
  newMovement.ServiceItemId = move[0].ServiceItemId;
  newMovement.Destination = dailyTemp[id].Movements[moveIdx].Destination;
  newMovement.DestinationName =
    dailyTemp[id].Movements[moveIdx].DestinationName;
  newMovement.OriginDestination =
    dailyTemp[id].Movements[moveIdx].OriginDestination;
  newMovement.TypeVirtual = true;
  newMovement.Item = addItemList(item);
  return newMovement;
};
export const addStartEndDays2 = (
  SummaryProgram,
  DailyProgramTemp,
  Departure,
  Arrival,
  MovementList
) => {
  let dpTemp = copyObject(DailyProgramTemp);
  let idx = 0;
  let SP = SummaryProgram.filter(
    item => item.AccommodationSummary !== undefined
  );
  dpTemp.map((daily, i) => {
    let chIn = false;
    let chOut = false;
    daily.Movements.map(move => {
      if (move.MovementName === 'CHECKIN') chIn = true;
      else if (move.MovementName === 'CHECKOUT') chOut = true;
      return move;
    });
    let dp = daily.Movements;
    let dpLength = dp.length - 1;
    if (i === 0) {
      if (!chIn && !chOut && dp[dpLength].MovementName !== 'DAYEND')
        daily.Movements.push(
          addDayEndDriving(
            addCheckInAco(MovementList, SP[idx]),
            convertToStringDate(Arrival.Date) + 'T23:59:59',
            MovementList
          )
        );
      else if (chIn && !chOut && dp[dpLength].MovementName !== 'DAYEND')
        daily.Movements.push(
          addDayEnd(
            addCheckInAco(MovementList, SP[idx]),
            SP[idx].AccommodationSummary.CheckIn,
            MovementList
          )
        );
      else if (
        chIn &&
        chOut &&
        dp[dpLength].MovementName !== 'DAYEND' &&
        SP[idx + 1]
      ) {
        idx++;
        if (
          dpTemp[i + 1]
            ? dpTemp[i + 1].Movements[0]
              ? dpTemp[i + 1].Movements[0].MovementName !== 'DEPARTURE'
              : true
            : true
        ) {
          daily.Movements.push(
            addDayEndDriving(
              addCheckInAco(MovementList, SP[idx]),
              convertToStringDate(SP[idx - 1].Date) + 'T23:59:59',
              MovementList
            )
          );
        }
      }
    } else if (i === dpTemp.length - 1) {
      if (
        daily.Movements[0].MovementName !== 'CHECKIN' &&
        dp[0].MovementName !== 'DAYSTART'
      )
        if (daily.Movements[0].MovementName !== 'DEPARTURE')
          daily.Movements.splice(
            0,
            0,
            addDayStart(
              addCheckInAco(MovementList, SP[idx]),
              SP[idx].AccommodationSummary.CheckOut,
              MovementList
            )
          );
    } else {
      let chIn = false;
      let chOut = false;
      daily.Movements.map(move => {
        if (move.MovementName === 'CHECKIN') chIn = true;
        else if (move.MovementName === 'CHECKOUT') chOut = true;
        return move;
      });
      if (
        chIn &&
        chOut &&
        dp[0].MovementName !== 'DAYSTART' &&
        dp[0].MovementName !== 'DEPARTURE'
      ) {
        daily.Movements.splice(
          0,
          0,
          addDayStart(
            addCheckInAco(MovementList, SP[idx]),
            daily.Movements[0].DateTime,
            MovementList
          )
        );
        idx++;
        if (
          SP[idx] !== undefined &&
          dp[dp.length - 1].MovementName !== 'DAYEND'
        ) {
          daily.Movements.push(
            addDayEnd(
              addCheckInAco(MovementList, SP[idx]),
              daily.Movements[daily.Movements.length - 1].DateTime,
              MovementList
            )
          );
        } else if (dp[dp.length - 1].MovementName !== 'DAYEND') {
          daily.Movements.push(
            addDayEndDriving(
              setAirportMovement(Departure, MovementList, 'DEPARTURE'),
              convertToStringDate(SP[idx - 1].LeavingDate) + 'T23:59:59',
              MovementList
            )
          );
        }
      } else if (
        chIn &&
        chOut &&
        dp[0].MovementName === 'DAYSTART' &&
        dp[dpLength].MovementName === 'DAYEND'
      ) {
        idx++;
      } else if (chIn && !chOut && dp[dpLength].MovementName !== 'DAYEND') {
        daily.Movements.push(
          addDayEnd(
            addCheckInAco(MovementList, SP[idx]),
            SP[idx].AccommodationSummary.CheckIn,
            MovementList
          )
        );
      } else if (!chIn && chOut && dp[0].MovementName !== 'DAYSTART') {
        daily.Movements.splice(
          0,
          0,
          addDayStart(
            addCheckInAco(MovementList, SP[idx]),
            daily.Movements[0].DateTime,
            MovementList
          )
        );
        idx++;
        if (
          SP[idx] !== undefined &&
          (dpTemp[i + 1]
            ? dpTemp[i + 1].Movements[0]
              ? dpTemp[i + 1].Movements[0].MovementName !== 'DEPARTURE'
              : true
            : true)
        )
          daily.Movements.push(
            addDayEndDriving(
              addCheckInAco(MovementList, SP[idx]),
              convertToStringDate(SP[idx - 1].LeavingDate) + 'T23:59:59',
              MovementList
            )
          );
        else if (
          dpTemp[i + 1]
            ? dpTemp[i + 1].Movements[0]
              ? dpTemp[i + 1].Movements[0].MovementName !== 'DEPARTURE'
              : true
            : true
        ) {
          daily.Movements.push(
            addDayEndDriving(
              setAirportMovement(Departure, MovementList, 'DEPARTURE'),
              convertToStringDate(SP[idx - 1].LeavingDate) + 'T23:59:59',
              MovementList
            )
          );
        }
      } else {
        if (daily.Movements.length === 0) {
          daily.Movements.splice(
            0,
            0,
            addDayStartDriving(
              addCheckInAco(MovementList, SP[idx]),
              convertToStringDate(SP[idx].Date) + 'T08:00:00',
              MovementList
            )
          );
          daily.Movements.push(
            addDayEndDriving(
              addCheckInAco(MovementList, SP[idx]),
              convertToStringDate(SP[idx].Date) + 'T20:00:00',
              MovementList
            )
          );
        } else {
          if (dp[0].MovementName !== 'DAYSTART')
            if (dp[0].MovementName !== 'CHECKIN')
              if (dp[0].MovementName !== 'DEPARTURE')
                daily.Movements.splice(
                  0,
                  0,
                  addDayStartDriving(
                    addCheckInAco(MovementList, SP[idx]),
                    daily.Movements[0].DateTime,
                    MovementList
                  )
                );
          if (dp[dpLength].MovementName !== 'DAYEND') {
            if (
              dpTemp[i + 1]
                ? dpTemp[i + 1].Movements[0]
                  ? dpTemp[i + 1].Movements[0].MovementName !== 'DEPARTURE'
                  : true
                : true
            )
              daily.Movements.push(
                addDayEndDriving(
                  addCheckInAco(MovementList, SP[idx]),
                  daily.Movements[daily.Movements.length - 1].DateTime,
                  MovementList
                )
              );
          }
        }
      }
    }
    return daily;
  });
  return dpTemp;
};

//////////////////////// Masuk Pak Eko ////////////////////////////////////////////

export const generateFreeTimeDP = (daily, dayIdx, MovementList) => {
  for (let j = 1; j < daily[dayIdx].Movements.length - 1; j++) {
    let tgl = daily[dayIdx].Movements[j - 1].DateTime;
    let durasi = daily[dayIdx].Movements[j - 1].Duration;
    let tglFree = SumSecond(tgl, durasi);
    if (daily[dayIdx].Movements[j].DateTime !== tglFree) {
      let a = tglFree;
      let b = daily[dayIdx].Movements[j].DateTime;
      // let dur = getNumberOfSecond(a, b);
      let dur = Math.abs(getNumberOfSecond(a, b));
      daily[dayIdx].Movements.splice(
        j,
        0,
        addFreeTime(
          MovementList,
          tglFree,
          daily[dayIdx].Movements[j],
          dur,
          false
        )
      );
      j++;
    }
  }
  return daily;
};

export const sequentialNumberinMovementByDays = (daily, dayIdx) => {
  let dailyTemp = copyObject(daily);
  dailyTemp.map((days, idx) => {
    if (idx === dayIdx) {
      days.Movements.map((obj, i) => {
        obj.SeqNumber = i + 1;
        return obj;
      });
    }
    return days;
  });
  // dailyTemp[dayIdx].Movements.map((obj, i) => {
  //   obj.SeqNumber = i + 1;
  // });
  return dailyTemp;
};

export const AddDriving = (daily, id, moveIdx, MovementList) => {
  let item = checkDriving(daily, id);
  let typeMov = MovementList.filter(item => item.Name === 'DRIVING');
  let obj = copyObject(Movement);
  obj.DateTime = daily[id].Movements[moveIdx].DateTime;
  obj.SeqNumber = moveIdx + 1;
  obj.MovementName = typeMov[0].Name;
  obj.MovementDescription = typeMov[0].Description;
  obj.ServiceItemId = typeMov[0].ServiceItemId;
  obj.Destination = daily[id].Movements[moveIdx].Destination;
  obj.DestinationName = daily[id].Movements[moveIdx].DestinationName;
  obj.OriginDestination = daily[id].Movements[moveIdx].OriginDestination;
  obj.Item = addItemList(item);
  obj.Duration = 0;
  if (daily) return obj;
};
export const AddDriving2 = (daily, id, moveIdx, MovementList) => {
  let item = checkDriving(daily, id);
  let move = MovementList.filter(item => item.Name === 'DRIVING');
  let obj = copyObject(Movement);
  let date = SumSecond(
    daily[id].Movements[moveIdx - 1].DateTime,
    daily[id].Movements[moveIdx - 1].Duration
  );
  obj.DateTime = date;
  obj.SeqNumber = moveIdx + 1;
  obj.MovementName = move[0].Name;
  obj.MovementDescription = move[0].Description;
  obj.ServiceItemId = move[0].ServiceItemId;
  obj.Destination = daily[id].Movements[moveIdx].Destination;
  obj.DestinationName = daily[id].Movements[moveIdx].DestinationName;
  obj.OriginDestination = daily[id].Movements[moveIdx].OriginDestination;
  obj.Item = addItemList(item);
  obj.Duration = 0;
  return obj;
};
export const addMovement = (daily, par, item, activity, id, moveIdx) => {
  //for set datetime
  let obj = copyObject(Movement);
  obj.DateTime = activity.Startime;
  obj.SeqNumber = null;
  obj.MovementName = par.Name;
  obj.MovementDescription = par.Description;
  obj.Duration = activity.Duration;
  obj.ServiceItemId = par.ServiceItemId;
  obj.Destination = daily[id].Movements[moveIdx].Destination;
  // obj.DestinationName = daily[id].Movements[moveIdx].DestinationName;
  if (daily[id].Movements[moveIdx].TypeVirtual) obj.TypeVirtual = true;
  if (par.Name === 'EAT') {
    obj.OriginDestination = daily[id].Movements[moveIdx].OriginDestination;
    obj.Note = activity.Note;
  }
  if (par.Name === 'RECREATION') {
    // obj.DestinationName = item.Cities ? item.Cities[0].Name : null;
    obj.DestinationName = item.AddressObject
      ? item.AddressObject.City
        ? item.AddressObject.City.Name
        : null
      : null;
    obj.OriginDestination = item.AddressObject
      ? item.AddressObject.City
        ? item.AddressObject.City.Id
        : null
      : null;
    obj.Item = addItemAtt(item, activity);
  } else if (par.Name === 'FREETIME') {
    obj.DestinationName = daily[id].Movements[moveIdx].DestinationName;
    obj.OriginDestination = daily[id].Movements[moveIdx].OriginDestination;
    obj.Item = addItemList(item);
    obj.Note = activity.Note;
  } else obj.Item = addItemList(item);
  return obj;
};

export const addDayStart = (par, date, MovementList) => {
  let move = MovementList.filter(item => item.Name === 'DAYSTART');
  let obj = copyObject(Movement);
  obj.Id = null;
  obj.Destination = par.Destination;
  obj.DestinationName = par.DestinationName;
  obj.OriginDestination = par.OriginDestination;
  obj.DateTime = date;
  obj.Duration = 0;
  obj.Item = par.Item;
  obj.MovementDescription = move[0].Description;
  obj.MovementName = move[0].Name;
  obj.Note = '';
  obj.SeqNumber = 1;
  obj.ServiceItemId = move[0].ServiceItemId;
  return obj;
};
export const addDayEnd = (par, date, MovementList) => {
  let obj = copyObject(Movement);
  let move = MovementList.filter(item => item.Name === 'DAYEND');
  obj.Id = null;
  obj.Destination = par.Destination;
  obj.DestinationName = par.DestinationName;
  obj.OriginDestination = par.OriginDestination;
  obj.DateTime = date;
  obj.Duration = 0;
  obj.Item = par.Item;
  obj.MovementDescription = move[0].Description;
  obj.MovementName = move[0].Name;
  obj.Note = '';
  obj.SeqNumber = 2;
  obj.ServiceItemId = move[0].ServiceItemId;
  return obj;
};
export const addDayEndDriving = (par, date, MovementList) => {
  let obj = copyObject(Movement);
  let move = MovementList.filter(item => item.Name === 'DAYEND');
  obj.Id = null;
  obj.Destination = par.Destination;
  obj.DestinationName = par.DestinationName;
  obj.OriginDestination = par.OriginDestination;
  obj.DateTime = date;
  obj.Duration = 0;
  obj.Item = par.Item;
  obj.MovementDescription = move[0].Description;
  obj.MovementName = move[0].Name;
  obj.Note = '';
  obj.SeqNumber = 2;
  obj.ServiceItemId = move[0].ServiceItemId;
  return obj;
};
export const addDayStartDriving = (par, date, MovementList) => {
  let obj = copyObject(Movement);
  let move = MovementList.filter(item => item.Name === 'DAYSTART');
  obj.Id = null;
  obj.Destination = par.Destination;
  obj.DestinationName = par.DestinationName;
  obj.OriginDestination = par.OriginDestination;
  obj.DateTime = date;
  obj.Duration = 0;
  obj.Item = par.Item;
  obj.MovementDescription = move[0].Description;
  obj.MovementName = move[0].Name;
  obj.Note = '';
  obj.SeqNumber = 2;
  obj.ServiceItemId = move[0].ServiceItemId;
  return obj;
};

export const getDrivingArray = dailyProgram => {
  let drivingArray = [];
  // eslint-disable-next-line
  dailyProgram.map((daily, day) => {
    // eslint-disable-next-line
    daily.Movements.map((move, i) => {
      if (move.MovementName === 'DRIVING') {
        let fromType = '';
        let toType = '';
        let from = '';
        let to = '';
        if (daily.Movements[i - 1] && daily.Movements[i - 1].Item) {
          if (daily.Movements[i - 1].Item.ServiceItemId) {
            fromType = 'ID';
            from = daily.Movements[i - 1].Item.ServiceItemId;
          } else {
            fromType = 'ADDRESS';
            from = daily.Movements[i - 1].Item.Address;
          }
        }
        if (daily.Movements[i + 1] && daily.Movements[i + 1].Item) {
          if (daily.Movements[i + 1].Item.ServiceItemId) {
            toType = 'ID';
            to = daily.Movements[i + 1].Item.ServiceItemId;
          } else {
            toType = 'ADDRESS';
            to = daily.Movements[i + 1].Item.Address;
          }
        }
        drivingArray.push({
          day: day,
          seqNumber: move.SeqNumber,
          from: from,
          to: to,
          duration: move.Duration,
          fromType: fromType,
          toType: toType,
          index: i,
        });
      }
    });
  });
  return drivingArray;
};

export const isAccommodationEmpty = summaryProgram => {
  let sP = copyObject(summaryProgram);
  let value = false;
  // eslint-disable-next-line
  sP.map(item => {
    // eslint-disable-next-line
    if (item.hasOwnProperty('AccommodationSummary')) {
      if (item.AccommodationSummary.ServiceItemId === null) value = true;
    }
  });
  return value;
};

export const mappingDuration = (durationMap, dailyProgram) => {
  let dp = copyObject(dailyProgram);
  return dp.map((item, i) => {
    let obj = durationMap.find(obj => obj.day === i);
    if (obj) {
      item.Movements[obj.index].Duration = obj.duration;
    }
    return item;
  });
};

export const setPlaceInArrivalDepartureByHeadLine = (
  HeadlineProgram,
  places,
  cityList
) => {
  let headLineProg = copyObject(HeadlineProgram);
  let mainProgLength = headLineProg.MainPrograms.length;
  let mainProg = headLineProg.MainPrograms;

  headLineProg.Departures[
    headLineProg.Departures.length - 1
  ] = changeArrivalDep(
    headLineProg,
    0,
    cityList,
    places,
    headLineProg.Departures[headLineProg.Departures.length - 1],
    'Arrival'
  );
  headLineProg.Returns[0] = changeArrivalDep(
    headLineProg,
    mainProgLength - 1,
    cityList,
    places,
    headLineProg.Returns[0],
    'Departure'
  );
  headLineProg.MainPrograms = mainProg.map((main, i) => {
    // eslint-disable-next-line
    if (main.hasOwnProperty('TransferType')) {
      let type =
        main.TransferType === 'Movement_arrival' ? 'Arrival' : 'Departure';
      let idx = main.TransferType === 'Movement_arrival' ? i + 1 : i - 1;
      main = changeArrivalDep(headLineProg, idx, cityList, places, main, type);
    }
    return main;
  });
  return headLineProg;
};
export const changeArrivalDep = (
  headLineProg,
  indexMp,
  cities,
  places,
  departureArrival,
  type
) => {
  let accomRegion = headLineProg.MainPrograms[indexMp].Region;
  let changes = isNeedChangeArrivalDeparture(
    departureArrival,
    headLineProg.MainPrograms,
    indexMp,
    type
  )
    ? changeDestinationArivalDeparture(
        departureArrival,
        cities,
        findPlaceByRegion(places, headLineProg.MainPrograms[indexMp].Region),
        accomRegion
      )
    : departureArrival;
  return changes;
};

export const findCityById = (cities, id) => {
  return cities.find(item => item.Id === id);
};
export const findPlaceByRegion = (places, region) => {
  let newPlace = places.find(
    item => item.region.toUpperCase() === region.toUpperCase()
  );
  return newPlace ? newPlace.data : [];
};
// menambahkan aktivitas pada daily program
//DailyProgram : Array daily program yang lama
//id: index dari daily prgram yang sedang dirubah saat ini
//moveIdx: index dari movement saat ini
//TypeMovement : object dari movment yang dipih
//itemMovement: object dari excursion and resto, klo freetime kirim aja null
//activityData: object activty data
export const editActivityData = (
  dailyTemp,
  dayIdx,
  moveIdx,
  TypeMovement,
  itemMovement,
  activityData
) => {
  let newDp = copyObject(dailyTemp);
  let newActivity = addMovement(
    dailyTemp,
    TypeMovement,
    itemMovement,
    activityData,
    dayIdx,
    moveIdx
  );
  newDp[dayIdx].Movements[moveIdx] = newActivity;
  newDp = fixVirtualMovements(newDp, dayIdx);
  newDp = fixDrivingDestination(newDp);
  return newDp;
};

//delete Connection Flight
//arrival : object Arrival
//oldSP : array of object SummaryProgram,
//index : index dari connection flight departure
export const delConectionFlight = (arrival, oldSP, index) => {
  let newSP = copyObject(oldSP);
  for (
    let j = 0;
    j < 2;
    j++ // harus di delete arifalnya
  )
    newSP.splice(index, 1);

  return setDateSummaryProgram(arrival, newSP);
};
//Delete Activity
//id : id dari daily program
//movIdx : id dari daily movments
//oldDP : array of object dari
//setelah fungsi ini selesai, panggil fungsi fixafterDelete()
export const deleteActivity = (dayIdx, movIdx, oldDP) => {
  let newDP = copyObject(oldDP);
  if (checkMovIdxDel(dayIdx, movIdx, oldDP)) {
    let j = 5;
    while (j) {
      newDP[dayIdx].Movements.splice(movIdx - 2, 1);
      j--;
    }
    if (newDP[dayIdx].Movements[movIdx - 4] !== undefined)
      if (
        newDP[dayIdx].Movements[movIdx - 4].MovementName === 'FREETIMELOCKED' &&
        newDP[dayIdx].Movements[movIdx - 3].MovementName !== 'CHECKIN'
      )
        newDP[dayIdx].Movements.splice(movIdx - 4, 1);
  } else {
    let j = 2;
    while (j) {
      newDP[dayIdx].Movements.splice(movIdx - 1, 1);
      j--;
    }
    if (
      newDP[dayIdx].Movements[movIdx].MovementName ===
      'VIRTUALRETURNACCOMMODATION'
    ) {
      newDP[dayIdx].Movements[movIdx].Item =
        newDP[dayIdx].Movements[movIdx - 2].Item;
      if (
        newDP[dayIdx].Movements[movIdx + 1].MovementName === 'VIRTUALCHECKOUT'
      )
        newDP[dayIdx].Movements[movIdx + 1].Item =
          newDP[dayIdx].Movements[movIdx].Item;
    }
  }
  newDP = fixDrivingDestination(newDP);
  return newDP;
};

export const checkMovIdxDel = (dayIdx, movIdx, oldDP) => {
  let newDP = copyObject(oldDP);
  return (newDP[dayIdx].Movements[movIdx - 2].MovementName ===
    'LEAVEACCOMMODATION' &&
    newDP[dayIdx].Movements[movIdx + 2].MovementName ===
      'RETURNACCOMMODATION') ||
    (newDP[dayIdx].Movements[movIdx - 2].MovementName ===
      'VIRTUALLEAVEACCOMMODATION' &&
      newDP[dayIdx].Movements[movIdx + 2].MovementName ===
        'VIRTUALRETURNACCOMMODATION')
    ? true
    : false;
};

export const setRoomAllocationSummaryProg = (headlineProgram, room) => {
  let headLineProg = copyObject(headlineProgram);
  let main = headLineProg.MainPrograms;
  main = main.map(item => {
    // eslint-disable-next-line
    if (item.hasOwnProperty('AccommodationSummary')) {
      item.AccommodationSummary.Allocations.ChildExtraBedPax =
        room.ChildExtraBedQty;
      item.AccommodationSummary.Allocations.ChildSharingRoomPax =
        room.ChildSharingRoomQty;
      item.AccommodationSummary.Allocations.ChildSingleRoomPax =
        room.ChildSingleRoomQty;
      item.AccommodationSummary.Allocations.ExtraBedPax = room.ExtraBedQty;
      item.AccommodationSummary.Allocations.NoBedPax =
        room.BabyCrib + room.NoBed;
      item.AccommodationSummary.Allocations.SharingBedPax = room.SharingBedQty;
      item.AccommodationSummary.Allocations.SharingRoomPax =
        room.SharingRoomQty;
      item.AccommodationSummary.Allocations.SingleRoomPax = room.SingleRoomQty;
    }
    return item;
  });
  headLineProg.MainPrograms = main;
  return headLineProg;
};

export const isEarlyCheck = dateTime => {
  return new Date(dateTime).getTime() <
    new Date(convertToStringDate(dateTime) + 'T14:00:00')
    ? true
    : false;
};

export const isOverlapping = (headlineProgram, type, index) => {
  let result;
  let Main = headlineProgram.MainPrograms;
  if (type === 'firstArrival') {
    let arrival = headlineProgram.Departures[0];
    let accom = Main[0];
    result =
      new Date(arrival.Date).getTime() >
      new Date(accom.AccommodationSummary.CheckIn).getTime()
        ? true
        : false;
  } else if (type === 'lastDeparture') {
    let departure = headlineProgram.Returns[0];
    let accom = Main[Main.length - 1];
    result =
      new Date(departure.Date).getTime() <
      new Date(accom.AccommodationSummary.CheckOut).getTime()
        ? true
        : false;
  } else {
    // eslint-disable-next-line
    if (Main[index].hasOwnProperty('AccommodationSummary')) {
      let accom = Main[index];
      let prevObj = Main[index - 1]
        ? Main[index - 1]
        : headlineProgram.Departures[0];
      let nextObj = Main[index + 1]
        ? Main[index + 1]
        : headlineProgram.Returns[0];
      result =
        new Date(
          // eslint-disable-next-line
          prevObj.hasOwnProperty('AccommodationSummary')
            ? prevObj.AccommodationSummary.CheckOut
            : prevObj.Date
        ).getTime() > new Date(accom.AccommodationSummary.CheckIn).getTime()
          ? true
          : new Date(
              // eslint-disable-next-line
              nextObj.hasOwnProperty('AccommodationSummary')
                ? nextObj.AccommodationSummary.CheckIn
                : nextObj.Date
            ).getTime() <
            new Date(accom.AccommodationSummary.CheckOut).getTime()
          ? true
          : new Date(accom.AccommodationSummary.CheckIn).getTime() >
            new Date(accom.AccommodationSummary.CheckOut).getTime()
          ? true
          : false;
    } else {
      let airport = Main[index];
      let prevObj = Main[index - 1];
      let nextObj = Main[index + 1];
      result =
        new Date(
          // eslint-disable-next-line
          prevObj.hasOwnProperty('AccommodationSummary')
            ? prevObj.AccommodationSummary.CheckOut
            : prevObj.Date
        ).getTime() > new Date(airport.Date).getTime()
          ? true
          : new Date(
              // eslint-disable-next-line
              nextObj.hasOwnProperty('AccommodationSummary')
                ? nextObj.AccommodationSummary.CheckIn
                : nextObj.Date
            ).getTime() < new Date(airport.Date).getTime()
          ? true
          : false;
    }
  }
  return result;
};
export const isHeadLineConflict = headlineProgram => {
  let Main = headlineProgram.MainPrograms;
  let arrival = headlineProgram.Departures[0];
  let departure = headlineProgram.Returns[0];
  let result = false;
  if (
    new Date(arrival.Date).getTime() >
    new Date(Main[0].AccommodationSummary.CheckIn).getTime()
  )
    result = true;
  if (
    new Date(departure.Date).getTime() <
    new Date(Main[Main.length - 1].AccommodationSummary.CheckOut).getTime()
  )
    result = true;
  Main.map((item, i) => {
    // eslint-disable-next-line
    if (item.hasOwnProperty('AccommodationSummary')) {
      let accom = Main[i];
      let prevObj = Main[i - 1] ? Main[i - 1] : headlineProgram.Departures[0];
      let nextObj = Main[i + 1] ? Main[i + 1] : headlineProgram.Returns[0];
      result =
        new Date(
          // eslint-disable-next-line
          prevObj.hasOwnProperty('AccommodationSummary')
            ? prevObj.AccommodationSummary.CheckOut
            : prevObj.Date
        ).getTime() > new Date(accom.AccommodationSummary.CheckIn).getTime()
          ? true
          : new Date(
              // eslint-disable-next-line
              nextObj.hasOwnProperty('AccommodationSummary')
                ? nextObj.AccommodationSummary.CheckIn
                : nextObj.Date
            ).getTime() <
            new Date(accom.AccommodationSummary.CheckOut).getTime()
          ? true
          : new Date(accom.AccommodationSummary.CheckIn).getTime() >
            new Date(accom.AccommodationSummary.CheckOut).getTime()
          ? true
          : result;
    } else {
      let airport = Main[i];
      let prevObj = Main[i - 1];
      let nextObj = Main[i + 1];
      result =
        new Date(
          // eslint-disable-next-line
          prevObj.hasOwnProperty('AccommodationSummary')
            ? prevObj.AccommodationSummary.CheckOut
            : prevObj.Date
        ).getTime() > new Date(airport.Date).getTime()
          ? true
          : new Date(
              // eslint-disable-next-line
              nextObj.hasOwnProperty('AccommodationSummary')
                ? nextObj.AccommodationSummary.CheckIn
                : nextObj.Date
            ).getTime() < new Date(airport.Date).getTime()
          ? true
          : result;
    }
    return item;
  });
  return result;
};

export const isExcursionTimeOver = excursion => {
  let excursionStartTime = getTime(excursion.DateTime);
  let excursionEndTime = getTime(
    SumSecond(excursion.DateTime, excursion.Duration)
  );
  let operationStartTime = getTime(
    convertToStringDate(excursion.DateTime) +
      convertToStringTime(excursion.Item.OperationStartTime)
  );
  let operationEndTime = getTime(
    convertToStringDate(excursion.DateTime) +
      convertToStringTime(excursion.Item.OperationEndTime)
  );
  if (
    excursionStartTime < operationStartTime ||
    excursionEndTime > operationEndTime ||
    excursionStartTime > operationEndTime ||
    excursionEndTime < operationStartTime
  ) {
    return true;
  } else return false;
};

export const validateDailyProgramExcursionTime = dailyProgram => {
  let result = false;
  dailyProgram.map(daily => {
    daily.Movements.map(item => {
      if (item.MovementName === 'RECREATION' && item.Item.OperationStartTime) {
        if (isExcursionTimeOver(item)) {
          result = true;
        }
      }
      return item;
    });
    return daily;
  });
  return result;
};

export const changeTimeSummaryProgramAfterAddActivity = (
  dailyProgram,
  summaryProgram
) => {
  let newSP = copyObject(summaryProgram);
  let daily = copyObject(dailyProgram);
  for (let i = 0; i < newSP.length; i++) {
    // eslint-disable-next-line
    if (newSP[i].hasOwnProperty('AccommodationSummary')) {
      let objDP = daily.find(
        item =>
          convertToStringDate(item.Date) === convertToStringDate(newSP[i].Date)
      );
      let checkin = objDP
        ? objDP.Movements.find(
            item =>
              item.MovementName === 'CHECKIN' &&
              item.Item.ServiceItemId ===
                newSP[i].AccommodationSummary.ServiceItemId
          )
        : null;
      if (
        checkin &&
        newSP[i].AccommodationSummary.CheckIn !== checkin.DateTime
      ) {
        newSP[i].AccommodationSummary.CheckIn = checkin.DateTime;
      }
      let objDP2 = daily.find(
        item =>
          convertToStringDate(item.Date) ===
          convertToStringDate(newSP[i].LeavingDate)
      );
      let checkout = objDP2
        ? objDP2.Movements.find(
            item =>
              item.MovementName === 'CHECKOUT' &&
              item.Item.ServiceItemId ===
                newSP[i].AccommodationSummary.ServiceItemId
          )
        : null;
      if (
        checkout &&
        newSP[i].AccommodationSummary.CheckOut !== checkout.DateTime
      ) {
        newSP[i].AccommodationSummary.CheckOut = checkout.DateTime;
      }
    }
  }
  return newSP;
};

export const isAccommodationTimeNeedChange = (dailyProgram, summaryProgram) => {
  let newSP = copyObject(summaryProgram);
  let daily = copyObject(dailyProgram);
  let isNeedChange = false;
  for (let i = 0; i < newSP.length; i++) {
    // eslint-disable-next-line
    if (newSP[i].hasOwnProperty('AccommodationSummary')) {
      let objDP = daily.find(
        item =>
          convertToStringDate(item.Date) === convertToStringDate(newSP[i].Date)
      );
      let checkin = objDP
        ? objDP.Movements.find(item => item.MovementName === 'CHECKIN')
        : null;
      if (
        checkin &&
        newSP[i].AccommodationSummary.CheckIn !== checkin.DateTime
      ) {
        isNeedChange = true;
      }

      let objDP2 = daily.find(
        item =>
          convertToStringDate(item.Date) ===
          convertToStringDate(newSP[i].LeavingDate)
      );
      let checkout = objDP2
        ? objDP2.Movements.find(item => item.MovementName === 'CHECKOUT')
        : null;
      if (
        checkout &&
        newSP[i].AccommodationSummary.CheckOut !== checkout.DateTime
      ) {
        isNeedChange = true;
      }
    }
  }
  return isNeedChange;
};

export const isThereActivity = (dailyProgram, date) => {
  let result = false;
  let data = dailyProgram.find(
    item => convertToStringDate(item.Date) === convertToStringDate(date)
  );
  if (data) {
    data.Movements.map(move => {
      if (['RECREATION', 'FREETIME', 'EAT'].indexOf(move.MovementName) !== -1) {
        result = true;
      }
      return move;
    });
  }
  return result;
};

export const initialSetCustomPackage = (hlProgram, roomAllocation) => {
  let headlineProgram = { ...hlProgram };
  headlineProgram.Departures = [
    setInitialAirport([], null, 1, 'Arrival', new Date(), 'Custom'),
  ];
  headlineProgram.MainPrograms = [setSummaryProgram(null, roomAllocation)];
  headlineProgram.Returns = [
    setInitialAirport(
      [],
      null,
      2,
      'Departure',
      headlineProgram.MainPrograms[0].LeavingDate,
      'Custom'
    ),
  ];

  // let dP = dailyProgramNull(
  //   headlineProgram.Departures[0],
  //   headlineProgram.MainPrograms,
  //   headlineProgram.Returns[0]
  // );
  let dP = generateInitialDailyProgram(headlineProgram);
  let result = { headlineProgram: headlineProgram, dailyProgram: dP };
  return result;
};

export const nextDayFlight = (headlineProgram, index, data, type) => {
  let newHeadline = { ...headlineProgram };
  let newDate = data.NextDay ? SubDays(data.Date, 1) : SumDays(data.Date, 1);
  if (type === 'lastDeparture') {
    let newDeparture = { ...newHeadline.Returns[0] };
    newDeparture.Date =
      convertToStringDate(newDate) + convertToStringTime(newDeparture.Date);
    newDeparture.NextDay = !newDeparture.NextDay;
    newHeadline.Returns[0] = newDeparture;
  } else if (type === 'connectionFlight') {
    newHeadline.MainPrograms = setDateSummaryProgramByIndex(
      newHeadline.MainPrograms,
      newHeadline.Departures[0],
      index,
      newDate
    );
    newHeadline.Returns[0] = setDateDeparture(
      newHeadline.Returns[0],
      newHeadline.MainPrograms
    );
  }
  return newHeadline;
};
//=========helper nextday=========
export const helperNextDay = (headlineProgram, index, data, type) => {
  let newHeadline = { ...headlineProgram };
  let newDate = data.NextDay ? SubDays(data.Date, 1) : SumDays(data.Date, 1);
  if (type === 'lastDeparture') {
    let newDeparture = { ...newHeadline.Returns[0] };
    newDeparture.Date =
      convertToStringDate(newDate) + convertToStringTime(newDeparture.Date);
    newDeparture.NextDay = !newDeparture.NextDay;
    newHeadline.Returns[0] = newDeparture;
    newHeadline.Returns = setReturnsDate(
      newHeadline.Returns,
      newHeadline.MainPrograms
    );
  } else if (type === 'lastArrival') {
    let newArrival = { ...newHeadline.Returns[index] };
    newArrival.Date =
      convertToStringDate(newDate) + convertToStringTime(newArrival.Date);
    newArrival.NextDay = !newArrival.NextDay;
    newHeadline.Returns[index] = newArrival;
  } else if (type === 'connectionFlight') {
    newHeadline.MainPrograms = setDateSummaryProgramByIndex(
      newHeadline.MainPrograms,
      newHeadline.Departures[0],
      index,
      newDate
    );
    newHeadline.Returns = setReturnsDate(
      newHeadline.Returns,
      newHeadline.MainPrograms
    );
  } else if (type === 'firstArrival') {
    let firstArrival = { ...newHeadline.Departures[index] };
    firstArrival.Date =
      convertToStringDate(newDate) + convertToStringTime(firstArrival.Date);
    firstArrival.NextDay = !firstArrival.NextDay;
    newHeadline.Departures[index] = firstArrival;

    newHeadline.MainPrograms = setDateSummaryProgram(
      firstArrival,
      newHeadline.MainPrograms
    );
    newHeadline.Returns = setReturnsDate(
      newHeadline.Returns,
      newHeadline.MainPrograms
    );
  }
  return newHeadline;
};
export const setArrivalTime = (value, type, headlineProgram, index) => {
  let headLineProg = { ...headlineProgram };
  if (type === 'firstArrival') {
    headLineProg.Departures[0] = changeTimeArrivalDeparture(
      headLineProg.Departures[0],
      `${convertDateFormat(
        headLineProg.Departures[0].Date,
        'YYYY-MM-DD'
      )}T${value}:00`
    );
  } else if (type === 'lastDeparture') {
    headLineProg.Returns[0] = changeTimeArrivalDeparture(
      headLineProg.Returns[0],
      `${convertDateFormat(
        headLineProg.Returns[0].Date,
        'YYYY-MM-DD'
      )}T${value}:00`
    );
  } else {
    headLineProg.MainPrograms[index] = changeTimeArrivalDeparture(
      headLineProg.MainPrograms[index],
      `${convertDateFormat(
        headLineProg.MainPrograms[index].Date,
        'YYYY-MM-DD'
      )}T${value}:00`
    );
  }
  return headLineProg;
};
//digunakan ketika edit excursion/activity untuk memperbaiki item pada virtual movement
export const fixVirtualMovements = (dailyProgram, dayIndex) => {
  return dailyProgram.map((daily, i) => {
    if (i === dayIndex) {
      daily.Movements.map((move, j) => {
        if (move.MovementName === 'VIRTUALCHECKIN') {
          move.Item = daily.Movements[j - 1].Item;
        } else if (move.MovementName === 'VIRTUALLEAVEACCOMMODATION') {
          move.Item = daily.Movements[j - 1].Item;
        } else if (move.MovementName === 'VIRTUALRETURNACCOMMODATION') {
          move.Item = daily.Movements[j - 2].Item;
        } else if (move.MovementName === 'VIRTUALCHECKOUT') {
          move.Item = daily.Movements[j - 1].Item;
        }
        return move;
      });
    }
    return daily;
  });
};
//update leave and return accommodation
export const fixLeaveReturnAccomodation = dailyProgram => {
  return dailyProgram.map(daily => {
    daily.Movements.map((move, i) => {
      if (move.MovementName === 'LEAVEACCOMMODATION') {
        move.Item = daily.Movements[i - 1].Item;
        move.DestinationName = daily.Movements[i - 1].DestinationName;
        move.OriginDestination = daily.Movements[i - 1].OriginDestination;
        move.Destination = daily.Movements[i - 1].Destination;
      } else if (move.MovementName === 'RETURNACCOMMODATION') {
        move.Item = daily.Movements[i + 1].Item;
        move.DestinationName = daily.Movements[i + 1].DestinationName;
        move.Destination = daily.Movements[i + 1].Destination;
        move.OriginDestination = daily.Movements[i + 1].OriginDestination;
      }
      return move;
    });
    return daily;
  });
};

//this function is to check is transporttion have valid type with the duration
export const isTransportValid = (movements, transport) => {
  if (
    transport.Item.ServiceItemId && //jika bukan transportation dengan
    ['Transfer_with_guide', 'Transfer'].indexOf(transport.Item.ServiceType) !==
      -1
  ) {
    let drivings = movements.filter(
      item =>
        item.Item.ServiceItemId === transport.Item.ServiceItemId &&
        item.Destination === transport.Destination
    ); // ambil transport yang service item id ny sama dan destinasinya sama
    // eslint-disable-next-line
    let lastDriving =
      drivings.reverse().find(
        e =>
          // eslint-disable-next-line
          e.Duration != 0
      ) || drivings[drivings.length - 1];
    let itemDuration = transport.Item.Hours * 3600;
    let endDateTime = SumSecond(lastDriving.DateTime, lastDriving.Duration);
    let drivingDuration = getNumberOfSecond(drivings[0].DateTime, endDateTime);
    if (itemDuration > drivingDuration) return false;
    else return true;
  } else {
    return false;
  }
};

export const initialSetReadyPackage = (
  headlineProgram,
  dailyPrograms,
  cityList,
  type,
  roomAllocations,
  movementList,
  isFixedPackage
) => {
  headlineProgram = copyObject(headlineProgram);
  dailyPrograms = copyObject(dailyPrograms);
  headlineProgram.MainPrograms = setSummaryProgramReady(
    headlineProgram.Departures[0],
    headlineProgram.MainPrograms,
    cityList,
    roomAllocations,
    type
  );
  headlineProgram.Departures = [
    setInitialAirport(
      headlineProgram.Departures[0],
      cityList.find(item => item.Id === headlineProgram.Departures[0].City.Id),
      1,
      'Arrival',
      new Date(),
      // type !== 'FixedDateVariable' ? new Date() : headlineProgram.Departures[0].Date,
      type
    ),
  ];
  headlineProgram.Returns = [
    setInitialAirport(
      headlineProgram.Returns[0],
      cityList.find(item => item.Id === headlineProgram.Returns[0].City.Id),
      2,
      'Departure',
      isFixedPackage
        ? headlineProgram.MainPrograms.reverse().reduce((acc, e) => {
            if (acc === null || acc === undefined) {
              acc = e.AccommodationSummary
                ? e.AccommodationSummary.CheckOut
                : null;
            }
            return acc;
          }, null)
        : headlineProgram.MainPrograms[headlineProgram.MainPrograms.length - 1]
            .AccommodationSummary.CheckOut,
      type
    ),
  ];
  dailyPrograms = setTourGuideInDailyProgram(dailyPrograms);
  // let dP = dailyPrograms;
  let dP = isFixedPackage
    ? dailyPrograms
    : dailyGenerate4(
        headlineProgram.Departures,
        headlineProgram.Returns,
        headlineProgram.MainPrograms,
        dailyPrograms,
        movementList
      );
  return {
    headlineProgram,
    dailyPrograms: dP,
  };
};

export const getPlaces = (region, places) => {
  if (region) {
    let tempPlaces = places.find(item => item.region === region);
    let place = tempPlaces ? tempPlaces.data : null;
    if (!place) {
      let allPlaces = places.reduce((result, current) => {
        result = result.concat(current.data);
        return result;
      }, []);
      place = allPlaces.filter(item => item.Region.Id === region);
    }
    return place;
  } else return [];
};

// validasi airport pada first
export const isValidAirport = headLineProg => {
  let departures = headLineProg.Departures;
  let returns = headLineProg.Returns;
  let result =
    departures[0].PlaceId &&
    departures[departures.length - 1].PlaceId &&
    returns[0].PlaceId &&
    returns[returns.length - 1].PlaceId;
  return result;
};

export const transportValidation = dailyProgram => {
  let result = false;
  dailyProgram.map(daily => {
    daily.Movements.map(move => {
      if (move.MovementName === 'DRIVING') {
        let valid = isTransportValid(daily.Movements, move);
        if (valid) {
          result = valid;
        }
      }
      return move;
    });
    return daily;
  });
  return result;
};

export const isAccommodationConflict = headlineProgram => {
  let Main = headlineProgram.MainPrograms;
  let arrival = headlineProgram.Departures[0];
  let departure = headlineProgram.Returns[0];
  let result = false;
  if (
    new Date(arrival.Date).getTime() >
    new Date(Main[0].AccommodationSummary.CheckIn).getTime()
  )
    result = true;
  if (
    new Date(departure.Date).getTime() <
    new Date(Main[Main.length - 1].AccommodationSummary.CheckOut).getTime()
  )
    result = true;
  Main.map((item, i) => {
    // eslint-disable-next-line
    if (item.hasOwnProperty('AccommodationSummary')) {
      let accom = Main[i];
      let prevObj = Main[i - 1] ? Main[i - 1] : headlineProgram.Departures[0];
      let nextObj = Main[i + 1] ? Main[i + 1] : headlineProgram.Returns[0];
      result =
        new Date(
          // eslint-disable-next-line
          prevObj.hasOwnProperty('AccommodationSummary')
            ? prevObj.AccommodationSummary.CheckOut
            : prevObj.Date
        ).getTime() > new Date(accom.AccommodationSummary.CheckIn).getTime()
          ? true
          : new Date(
              // eslint-disable-next-line
              nextObj.hasOwnProperty('AccommodationSummary')
                ? nextObj.AccommodationSummary.CheckIn
                : nextObj.Date
            ).getTime() <
            new Date(accom.AccommodationSummary.CheckOut).getTime()
          ? true
          : new Date(accom.AccommodationSummary.CheckIn).getTime() >
            new Date(accom.AccommodationSummary.CheckOut).getTime()
          ? true
          : result;
    } else {
      let airport = Main[i];
      let prevObj = Main[i - 1];
      let nextObj = Main[i + 1];
      result =
        new Date(
          // eslint-disable-next-line
          prevObj.hasOwnProperty('AccommodationSummary')
            ? prevObj.AccommodationSummary.CheckOut
            : prevObj.Date
        ).getTime() > new Date(airport.Date).getTime()
          ? true
          : new Date(
              // eslint-disable-next-line
              nextObj.hasOwnProperty('AccommodationSummary')
                ? nextObj.AccommodationSummary.CheckIn
                : nextObj.Date
            ).getTime() < new Date(airport.Date).getTime()
          ? true
          : result;
    }
    return item;
  });
  return result;
};

const deleteDayendNextDeparture = dailyProgram => {
  let dpTemp = copyObject(dailyProgram);
  dpTemp.map((daily, i) => {
    let nextDay = dpTemp[i + 1];
    if (
      nextDay
        ? nextDay.Movements[0]
          ? nextDay.Movements[0].MovementName === 'DEPARTURE'
          : false
        : false
    ) {
      if (
        daily.Movements[0]
          ? daily.Movements[0].MovementName === 'DAYEND'
          : false
      ) {
        dpTemp[i].Movements.pop();
      }
    }
    return dpTemp;
  });
  return dpTemp;
};

export const validateDailyProgramOverloadTime = dailyProgram => {
  let result = false;
  dailyProgram.some(daily => {
    let date = daily.Date;
    daily.Movements.some(move => {
      const isOverload =
        diffDate(
          convertDateFormat(date, 'YYYY-MM-DD'),
          convertDateFormat(move.DateTime, 'YYYY-MM-DD'),
          'days'
        ) !== 0;
      if (
        isOverload &&
        [EAT, RECREATION, FREETIME].indexOf(move.MovementName) !== -1
      ) {
        result = isOverload;
      }
      return result;
    });
    return result;
  });
  return result;
};

export const fixFlightTime = dailyProgram => {
  dailyProgram.map((daily, i) => {
    if (daily.MovementName === 'FLIGHTTIME') {
      daily.Item.PlaceId = dailyProgram[i - 1].Item.PlaceId;
    }
    return daily;
  });
  return dailyProgram;
};

export const fixDayStartDayEndAddress = dailyProgram => {
  dailyProgram.map(daily => {
    daily.Movements.map(move => {
      if (['DAYSTART', 'DAYEND'].indexOf(move.MovementName) !== -1) {
        let pivot = daily.Movements.find(
          item =>
            ['CHECKIN', 'CHECKOUT'].indexOf(item.MovementName) !== -1 &&
            // eslint-disable-next-line
            item.Item.ServiceItemId == move.Item.ServiceItemId
        );
        if (pivot) {
          move.Item.Address = pivot.Item.Address;
        }
      }
      return move;
    });
    return daily;
  });
  return dailyProgram;
};

export const fixDrivingDestination = dailyProgram => {
  let dailyTemp = copyObject(dailyProgram);
  dailyTemp.map(daily => {
    daily.Movements.map((move, iMove) => {
      if (['DRIVING'].indexOf(move.MovementName) !== -1) {
        let prevMove = daily.Movements[iMove - 1];
        move.Destination = prevMove ? prevMove.Destination : move.Destination;
        move.DestinationName = prevMove
          ? prevMove.DestinationName
          : move.DestinationName;
        move.OriginDestination = prevMove
          ? prevMove.OriginDestination
          : move.OriginDestination;
      }
      return move;
    });
    return daily;
  });
  return dailyTemp;
};

export const fixDailyAfterMovement = (dp, dayIdx, moveIdx) => {
  let curMovements = dp[dayIdx].Movements;
  for (var i = moveIdx + 1; i < curMovements.length; i++) {
    let curMoveName = curMovements[i].MovementName;
    if (
      curMoveName === 'DEPARTURE' ||
      (curMoveName === 'RECREATION' && curMovements[i].Item.IsSolidStartTime)
    )
      break;
    let newMoveDateTime = SumSecond(
      curMovements[i - 1].DateTime,
      curMovements[i - 1].Duration
    );
    if (
      curMoveName === 'FREETIMELOCKED' &&
      curMovements[i + 1].MovementName === 'DEPARTURE'
    ) {
      let nextDate = curMovements[i + 1].DateTime;
      if (new Date(newMoveDateTime) <= new Date(nextDate)) {
        let newDuration = getNumberOfSecond(newMoveDateTime, nextDate);
        curMovements[i].Duration = newDuration;
        curMovements[i].DateTime = newMoveDateTime;
      } else {
        curMovements[i].Duration = 0;
        curMovements[i].DateTime = nextDate;
      }
    } else {
      curMovements[i].DateTime = newMoveDateTime;
    }
  }
  dp[dayIdx].Movements = curMovements;
  return dp;
};

export const isBeforeDeparture = (dailyProgram, dayIdx, moveIdx) => {
  let departureIdx = dailyProgram[dayIdx].Movements.findIndex(
    item => item.MovementName === 'DEPARTURE'
  );
  if (departureIdx !== -1) {
    return departureIdx > moveIdx ? true : false;
  } else return false;
};

// this is for additional services validation in tour transaction
export const additionalServicesValidation = data => {
  let isError = false;
  let nolQty = data.find(item => item.Quantity <= 0);
  if (nolQty) isError = true;
  return isError;
};

export const destructureAdditionalServices = data => {
  let dest = data.reduce((res, cur) => {
    let obj = {
      Amount: cur.Amount,
      CurrencyId: cur.Currency,
      AdditionalServiceCountryId: cur.OriginalCountryId,
      IsMandatory: cur.IsMandatory,
      Quantity: cur.Quantity,
      IsInfantCount: cur.IsInfantCount,
    };
    res = res.concat(obj);
    return res;
  }, []);
  return dest;
};

export const destructureAdditionalServicesTemplate = data => {
  let dest = data.reduce((res, cur) => {
    let obj = {
      // Amount: cur.Amount,
      // CurrencyId: cur.Currency,
      AdditionalServiceCountryId: cur.OriginalCountryId,
      IsMandatory: cur.IsMandatory,
      Quantity: cur.Quantity,
      IsInfantCount: cur.IsInfantCount,
    };
    res = res.concat(obj);
    return res;
  }, []);
  return dest;
};
