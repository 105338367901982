import moment from 'moment';
import {
  getHourAndMinute,
  convertSecondToHour,
  convertHourToSecond,
} from '../../../../../components/genericFunction/moment';
const setAddressObject = (state, emailAddress, phoneNumber) => {
  const {
    Area,
    City,
    Region,
    Country,
    Address1,
    Address2,
    Address3,
    Landmark,
  } = state;
  let addressObject = {
    CountryId: Country ? Country.Id : '',
    RegionId: Region ? Region.Id : '',
    CityId: City ? City.Id : '',
    AreaId: Area ? Area.Id : '',
    Address1: Address1 ? Address1 : '',
    Address2: Address2 ? Address2 : '',
    Address3: Address3 ? Address3 : '',
    Email: emailAddress ? emailAddress : '',
    PhoneNbr: phoneNumber ? phoneNumber : '',
    Landmark: Landmark ? Landmark : '',
  };
  return addressObject;
};

const setExcursionObject = state => {
  const {
    IsSolidDuration,
    IsSolidStartTime,
    LongDescription,
    Description,
    Name,
    AttractionType,
    AttractionCategory,
    OperationEndTime,
    OperationStartTime,
    OptimumDuration,
    ServiceItemId,

    ObjectImages,
  } = state;
  let excursion = {
    IsSolidDuration: IsSolidDuration,
    IsSolidStartTime: IsSolidStartTime,
    OperationStartTime: getHourAndMinute(moment(OperationStartTime)),
    OperationEndTime: getHourAndMinute(moment(OperationEndTime)),
    OptimumDuration: convertSecondToHour(OptimumDuration),
    Description: Description,
    ServiceItemId: ServiceItemId,
    LongDescription: LongDescription,
    Name: Name,
    AttractionType: AttractionType ? AttractionType.Id : '',
    AttractionCategory: AttractionCategory ? AttractionCategory : '',
    ObjectImages: ObjectImages,
  };
  return excursion;
};

const setPriceListData = price => {
  let data = {
    Id: price.Id,
    CutOffDay: price.CutOffDay,
    MinimumPax: price.CalculationBase.Code,
    Cost: price.Cost,
    StartDate: moment(price.StartDate),
    EndDate: moment(price.EndDate),
    UomId: price.UomId,
    TaxTypeId: price.TaxTypeId,
    Currency: price.Currency,
    Rank: price.Rank,
    ServiceItemId: price.ServiceItemId,
    ServicePriceType: price.ServiceType,
    StartDateError: '',
    EndDateError: '',
    Description: price.Description,
  };
  return data;
};

const setDataToPost = state => {
  const { addressObject, excursion, accessCity } = state;
  const {
    Address1,
    Address2,
    Address3,
    Landmark,
    RegionId,
    CityId,
    AreaId,
    PhoneNbr,
    Email,
  } = addressObject;
  const {
    IsSolidDuration,
    IsSolidStartTime,
    OperationStartTime,
    OptimumDuration,
    OperationEndTime,
    Name,
    Description,
    LongDescription,
    AttractionCategory,
    AttractionType,
    ServiceItemId,
    ObjectImages,
  } = excursion;
  let listImage = [];
  let city = accessCity.map(accCity => {
    let newCity = {
      CityId: accCity.Id,
    };
    return newCity;
  });
  if (ObjectImages.length > 0) {
    listImage = ObjectImages.map(image => {
      let newImage = {
        ImageId: image.Id,
        ImageContent: image.ImageContent ? image.ImageContent : '',
        ImageName: image.ImageName,
        IsPrimaryImage: image.IsPrimaryImage,
      };
      return newImage;
    });
  } else {
    listImage = [];
  }
  let data = {
    Qty: 1,
    AttractionObjectCities: city,
    ServiceItemId: ServiceItemId,
    CityId: CityId,
    AddressObject: {
      Address1: Address1,
      Address2: Address2,
      Address3: Address3,
      Landmark: Landmark,
      PostalCode: '',
      AreaId: AreaId,
    },
    BillingAddress: {
      Address1: Address1,
      Address2: Address2,
      Address3: Address3,
      Landmark: Landmark,
      PostalCode: '',
      AreaId: AreaId,
    },
    AttractionObjectImages: listImage,
    Name: Name,
    Address: Address1,
    CompanyId: 1,
    ImageName: '',
    Description: Description,
    LongDescription: LongDescription,
    ImageContent: '',
    AttractionTypeId: AttractionType,
    RegionID: RegionId,
    OptimumDuration: convertHourToSecond(OptimumDuration),
    OperationStartTime: OperationStartTime,
    OperationEndTime: OperationEndTime,
    IsSolidStartTime: IsSolidStartTime,
    IsSolidDuration: IsSolidDuration,
    AttractionCategory: AttractionCategory,
    PhoneNbr: PhoneNbr,
    EmailAddress: Email,
  };
  return data;
};
const setPriceToPost = (state, serviceItemId) => {
  const {
    Id,
    MinimumPax,
    CutOffDay,
    Cost,
    StartDate,
    EndDate,
    Currency,
    ServicePriceType,
    Description,
  } = state;
  let price = {
    Id: Id,
    CutOffDay: CutOffDay,
    MinimumPax: MinimumPax,
    Cost: Cost,
    StartDate: StartDate,
    EndDate: EndDate,
    UomId: 'PCS',
    TaxTypeId: 0,
    Currency: Currency,
    Rank: 1,
    ServiceItemId: serviceItemId,
    ServicePriceType: ServicePriceType,
    Description: Description,
  };
  return price;
};
export {
  setAddressObject,
  setExcursionObject,
  setPriceListData,
  setDataToPost,
  setPriceToPost,
};
