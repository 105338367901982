// Data Transfer Logs
const GET_DATA_TRANSFER_LOGS = 'GET_DATA_TRANSFER_LOGS';
const GET_DATA_TRANSFER_LOGS_REJECTED = 'GET_DATA_TRANSFER_LOGS_REJECTED';
const GET_DATA_TRANSFER_LOGS_PENDING = 'GET_DATA_TRANSFER_LOGS_PENDING';
const GET_DATA_TRANSFER_LOGS_FULFILLED = 'GET_DATA_TRANSFER_LOGS_FULFILLED';

const GET_DATA_TRANSFER_LOGS_BY_ID = 'GET_DATA_TRANSFER_LOGS_BY_ID';
const GET_DATA_TRANSFER_LOGS_BY_ID_REJECTED =
  'GET_DATA_TRANSFER_LOGS_BY_ID_REJECTED';
const GET_DATA_TRANSFER_LOGS_BY_ID_PENDING =
  'GET_DATA_TRANSFER_LOGS_BY_ID_PENDING';
const GET_DATA_TRANSFER_LOGS_BY_ID_FULFILLED =
  'GET_DATA_TRANSFER_LOGS_BY_ID_FULFILLED';

const POST_DATA_TRANSFER_LOGS = 'POST_DATA_TRANSFER_LOGS';
const POST_DATA_TRANSFER_LOGS_REJECTED = 'POST_DATA_TRANSFER_LOGS_REJECTED';
const POST_DATA_TRANSFER_LOGS_PENDING = 'POST_DATA_TRANSFER_LOGS_PENDING';
const POST_DATA_TRANSFER_LOGS_FULFILLED = 'POST_DATA_TRANSFER_LOGS_FULFILLED';

const PUT_DATA_TRANSFER_LOGS = 'PUT_DATA_TRANSFER_LOGS';
const PUT_DATA_TRANSFER_LOGS_REJECTED = 'PUT_DATA_TRANSFER_LOGS_REJECTED';
const PUT_DATA_TRANSFER_LOGS_PENDING = 'PUT_DATA_TRANSFER_LOGS_PENDING';
const PUT_DATA_TRANSFER_LOGS_FULFILLED = 'PUT_DATA_TRANSFER_LOGS_FULFILLED';

const DELETE_DATA_TRANSFER_LOGS = 'DELETE_DATA_TRANSFER_LOGS';
const DELETE_DATA_TRANSFER_LOGS_REJECTED = 'DELETE_DATA_TRANSFER_LOGS_REJECTED';
const DELETE_DATA_TRANSFER_LOGS_PENDING = 'DELETE_DATA_TRANSFER_LOGS_PENDING';
const DELETE_DATA_TRANSFER_LOGS_FULFILLED =
  'DELETE_DATA_TRANSFER_LOGS_FULFILLED';

const GET_DATA_TRANSFER_LOGS_BY_RANGE = 'GET_DATA_TRANSFER_LOGS_BY_RANGE';
const GET_DATA_TRANSFER_LOGS_BY_RANGE_REJECTED =
  'GET_DATA_TRANSFER_LOGS_BY_RANGE_REJECTED';
const GET_DATA_TRANSFER_LOGS_BY_RANGE_PENDING =
  'GET_DATA_TRANSFER_LOGS_BY_RANGE_PENDING';
const GET_DATA_TRANSFER_LOGS_BY_RANGE_FULFILLED =
  'GET_DATA_TRANSFER_LOGS_BY_RANGE_FULFILLED';

const RESET_DATA_TRANSFER_LOGS = 'RESET_DATA_TRANSFER_LOGS';
const RESET_DATA_TRANSFER_LOGS_BY_ID = 'RESET_DATA_TRANSFER_LOGS_BY_ID';

const GET_DATA_TRANSFER_LOGS_BY_REFERENCE_ID =
  'GET_DATA_TRANSFER_LOGS_BY_REFERENCE_ID';
const GET_DATA_TRANSFER_LOGS_BY_REFERENCE_ID_REJECTED =
  'GET_DATA_TRANSFER_LOGS_BY_REFERENCE_ID_REJECTED';
const GET_DATA_TRANSFER_LOGS_BY_REFERENCE_ID_PENDING =
  'GET_DATA_TRANSFER_LOGS_BY_REFERENCE_ID_PENDING';
const GET_DATA_TRANSFER_LOGS_BY_REFERENCE_ID_FULFILLED =
  'GET_DATA_TRANSFER_LOGS_BY_REFERENCE_ID_FULFILLED';

export {
  //Data Transfer Logs
  GET_DATA_TRANSFER_LOGS,
  GET_DATA_TRANSFER_LOGS_REJECTED,
  GET_DATA_TRANSFER_LOGS_PENDING,
  GET_DATA_TRANSFER_LOGS_FULFILLED,
  GET_DATA_TRANSFER_LOGS_BY_ID,
  GET_DATA_TRANSFER_LOGS_BY_ID_REJECTED,
  GET_DATA_TRANSFER_LOGS_BY_ID_PENDING,
  GET_DATA_TRANSFER_LOGS_BY_ID_FULFILLED,
  POST_DATA_TRANSFER_LOGS,
  POST_DATA_TRANSFER_LOGS_REJECTED,
  POST_DATA_TRANSFER_LOGS_PENDING,
  POST_DATA_TRANSFER_LOGS_FULFILLED,
  PUT_DATA_TRANSFER_LOGS,
  PUT_DATA_TRANSFER_LOGS_REJECTED,
  PUT_DATA_TRANSFER_LOGS_PENDING,
  PUT_DATA_TRANSFER_LOGS_FULFILLED,
  DELETE_DATA_TRANSFER_LOGS,
  DELETE_DATA_TRANSFER_LOGS_REJECTED,
  DELETE_DATA_TRANSFER_LOGS_PENDING,
  DELETE_DATA_TRANSFER_LOGS_FULFILLED,
  GET_DATA_TRANSFER_LOGS_BY_RANGE,
  GET_DATA_TRANSFER_LOGS_BY_RANGE_REJECTED,
  GET_DATA_TRANSFER_LOGS_BY_RANGE_PENDING,
  GET_DATA_TRANSFER_LOGS_BY_RANGE_FULFILLED,
  RESET_DATA_TRANSFER_LOGS,
  RESET_DATA_TRANSFER_LOGS_BY_ID,
  GET_DATA_TRANSFER_LOGS_BY_REFERENCE_ID,
  GET_DATA_TRANSFER_LOGS_BY_REFERENCE_ID_REJECTED,
  GET_DATA_TRANSFER_LOGS_BY_REFERENCE_ID_PENDING,
  GET_DATA_TRANSFER_LOGS_BY_REFERENCE_ID_FULFILLED,
};
