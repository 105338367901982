const GET_CURRENCIES = 'GET_CURRENCIES';
const GET_CURRENCIES_FULFILLED = 'GET_CURRENCIES_FULFILLED';
const GET_CURRENCIES_PENDING = 'GET_CURRENCIES_PENDING';
const GET_CURRENCIES_REJECTED = 'GET_CURRENCIES_REJECTED';

// //LOGIN
// const LOGIN = 'LOGIN';
// const LOGOUT = 'LOGOUT';
// const RESET_LOGIN = 'RESET_STATUS_LOGIN';
// const LOGIN_FULFILLED = 'LOGIN_FULFILLED';
// const LOGIN_PENDING = 'LOGIN_PENDING';
// const LOGIN_REJECTED = 'LOGIN_REJECTED';

//Company
const GET_PENDING_COMPANY_FOR_APPROVAL = 'GET_PENDING_COMPANY_FOR_APPROVAL';
const RESET_STATUS_COMPANY = 'RESET_STATUS_COMPANY';
const GET_PENDING_COMPANY_FOR_APPROVAL_FULFILLED =
  'GET_PENDING_COMPANY_FOR_APPROVAL_FULFILLED';
const GET_PENDING_COMPANY_FOR_APPROVAL_PENDING =
  'GET_PENDING_COMPANY_FOR_APPROVAL_PENDING';
const GET_PENDING_COMPANY_FOR_APPROVAL_REJECTED =
  'GET_PENDING_COMPANY_FOR_APPROVAL_REJECTED';

//--------------------ACCEPT && REJECT COMPANY REGISTRATION--------------
const POST_ACCEPT_COMPANY_REGISTRATION = 'POST_ACCEPT_COMPANY_REGISTRATION';
const POST_ACCEPT_COMPANY_REGISTRATION_FULFILLED =
  'POST_ACCEPT_COMPANY_REGISTRATION_FULFILLED';
const POST_ACCEPT_COMPANY_REGISTRATION_PENDING =
  'POST_ACCEPT_COMPANY_REGISTRATION_PENDING';
const POST_ACCEPT_COMPANY_REGISTRATION_REJECTED =
  'POST_ACCEPT_COMPANY_REGISTRATION_REJECTED';
const POST_REJECT_COMPANY_REGISTRATION = 'POST_REJECT_COMPANY_REGISTRATION';
const POST_REJECT_COMPANY_REGISTRATION_FULFILLED =
  'POST_REJECT_COMPANY_REGISTRATION_FULFILLED';
const POST_REJECT_COMPANY_REGISTRATION_PENDING =
  'POST_REJECT_COMPANY_REGISTRATION_PENDING';
const POST_REJECT_COMPANY_REGISTRATION_REJECTED =
  'POST_REJECT_COMPANY_REGISTRATION_REJECTED';
const DELETE_COMPANIES_BY_ID = 'DELETE_COMPANIES_BY_ID';
const DELETE_COMPANIES_BY_ID_FULFILLED = 'DELETE_COMPANIES_BY_ID_FULFILLED';
const DELETE_COMPANIES_BY_ID_PENDING = 'DELETE_COMPANIES_BY_ID_PENDING';
const DELETE_COMPANIES_BY_ID_REJECTED = 'DELETE_COMPANIES_BY_ID_REJECTED';
const GET_COMPANY_COMMISSIONS = 'GET_COMPANY_COMMISSIONS';
const GET_COMPANY_COMMISSIONS_FULFILLED = 'GET_COMPANY_COMMISSIONS_FULFILLED';
const GET_COMPANY_COMMISSIONS_PENDING = 'GET_COMPANY_COMMISSIONS_PENDING';
const GET_COMPANY_COMMISSIONS_REJECTED = 'GET_COMPANY_COMMISSIONS_REJECTED';
const GET_COMPANY_COMMISSION_BY_ID = 'GET_COMPANY_COMMISSION_BY_ID';
const GET_COMPANY_COMMISSION_BY_ID_FULFILLED =
  'GET_COMPANY_COMMISSION_BY_ID_FULFILLED';
const GET_COMPANY_COMMISSION_BY_ID_PENDING =
  'GET_COMPANY_COMMISSION_BY_ID_PENDING';
const GET_COMPANY_COMMISSION_BY_ID_REJECTED =
  'GET_COMPANY_COMMISSION_BY_ID_REJECTED';
const POST_COMPANY_COMMISSION = 'POST_COMPANY_COMMISSION';
const POST_COMPANY_COMMISSION_FULFILLED = 'POST_COMPANY_COMMISSION_FULFILLED';
const POST_COMPANY_COMMISSION_PENDING = 'POST_COMPANY_COMMISSION_PENDING';
const POST_COMPANY_COMMISSION_REJECTED = 'POST_COMPANY_COMMISSION_REJECTED';
const PUT_COMPANY_COMMISSION = 'PUT_COMPANY_COMMISSION';
const PUT_COMPANY_COMMISSION_FULFILLED = 'PUT_COMPANY_COMMISSION_FULFILLED';
const PUT_COMPANY_COMMISSION_PENDING = 'PUT_COMPANY_COMMISSION_PENDING';
const PUT_COMPANY_COMMISSION_REJECTED = 'PUT_COMPANY_COMMISSION_REJECTED';
const DELETE_COMPANY_COMMISSIONS = 'DELETE_COMPANY_COMMISSIONS';
const DELETE_COMPANY_COMMISSIONS_FULFILLED =
  'DELETE_COMPANY_COMMISSIONS_FULFILLED';
const DELETE_COMPANY_COMMISSIONS_PENDING = 'DELETE_COMPANY_COMMISSIONS_PENDING';
const DELETE_COMPANY_COMMISSIONS_REJECTED =
  'DELETE_COMPANY_COMMISSIONS_REJECTED';
const GET_COMPANIES_BY_TYPE = 'GET_COMPANIES_BY_TYPE';
const GET_COMPANIES_BY_TYPE_FULFILLED = 'GET_COMPANIES_BY_TYPE_FULFILLED';
const GET_COMPANIES_BY_TYPE_PENDING = 'GET_COMPANIES_BY_TYPE_PENDING';
const GET_COMPANIES_BY_TYPE_REJECTED = 'GET_COMPANIES_BY_TYPE_REJECTED';

//Package
const GET_PUBLISHED_PACKAGE = 'GET_PUBLISHED_PACKAGE';
const RESET_STATUS_PACKAGE = 'RESET_STATUS_PACKAGE';
const GET_PUBLISHED_PACKAGE_FULFILLED = 'GET_PUBLISHED_PACKAGE_FULFILLED';
const GET_PUBLISHED_PACKAGE_PENDING = 'GET_PUBLISHED_PACKAGE_PENDING';
const GET_PUBLISHED_PACKAGE_REJECTED = 'GET_PUBLISHED_PACKAGE_REJECTED';
const RESET_ACTIVITY_PACKAGE = 'RESET_ACTIVITY_PACKAGE';

//TourTransaction
const GET_TRANSACTION = 'GET_TRANSACTION';
const RESET_STATUS_TRANSACTION = 'RESET_STATUS_TRANSACTION';
const GET_TRANSACTION_FULFILLED = 'GET_TRANSACTION_FULFILLED';
const GET_TRANSACTION_PENDING = 'GET_TRANSACTION_PENDING';
const GET_TRANSACTION_REJECTED = 'GET_TRANSACTION_REJECTED';

//-----------APPROVAL && CANCEL && CONFIRM COMPANY REGISTRATION--------------
const POST_STATUS_TRANSACTION = 'POST_STATUS_TRANSACTION';
const POST_STATUS_TRANSACTION_FULFILLED = 'POST_STATUS_TRANSACTION_FULFILLED';
const POST_STATUS_TRANSACTION_PENDING = 'POST_STATUS_TRANSACTION_PENDING';
const POST_STATUS_TRANSACTION_REJECTED = 'POST_STATUS_TRANSACTION_REJECTED';

//UserInfo
const GETUSERINFO = 'GETUSERINFO';
const RESET_STATUS_USER = 'RESET_STATUS_USER';
const GETUSERINFO_FULFILLED = 'GETUSERINFO_FULFILLED';
const GETUSERINFO_PENDING = 'GETUSERINFO_PENDING';
const GETUSERINFO_REJECTED = 'GETUSERINFO_REJECTED';

//Payment History
const GET_PAYMENT_HISTORY = 'GET_PAYMENT_HISTORY';
const RESET_STATUS_PAYMENT_HISTORY = 'RESET_STATUS_PAYMENT_HISTORY';
const GET_PAYMENT_HISTORY_FULFILLED = 'GET_PAYMENT_HISTORY_FULFILLED';
const GET_PAYMENT_HISTORY_PENDING = 'GET_PAYMENT_HISTORY_PENDING';
const GET_PAYMENT_HISTORY_REJECTED = 'GET_PAYMENT_HISTORY_REJECTED';

//Accomodation List
const GET_ACCOMODATION_LIST = 'GET_ACCOMODATION_LIST';
const RESET_STATUS_ACCOMODATION = 'RESET_STATUS_ACCOMODATION';
const GET_ACCOMODATION_LIST_FULFILLED = 'GET_ACCOMODATION_LIST_FULFILLED';
const GET_ACCOMODATION_LIST_PENDING = 'GET_ACCOMODATION_LIST_PENDING';
const GET_ACCOMODATION_LIST_REJECTED = 'GET_ACCOMODATION_LIST_REJECTED';

//Excursion
const GET_EXCURSION_PROFILES = 'GET_EXCURSION_PROFILES';
const GET_EXCURSION_PROFILES_FULFILLED = 'GET_EXCURSION_PROFILES_FULFILLED';
const GET_EXCURSION_PROFILES_PENDING = 'GET_EXCURSION_PROFILES_PENDING';
const GET_EXCURSION_PROFILES_REJECTED = 'GET_EXCURSION_PROFILES_REJECTED';
const GET_EXCURSION_PROFILE = 'GET_EXCURSION_PROFILE';
const GET_EXCURSION_PROFILE_REJECTED = 'GET_EXCURSION_PROFILE_REJECTED';
const GET_EXCURSION_PROFILE_PENDING = 'GET_EXCURSION_PROFILE_PENDING';
const GET_EXCURSION_PROFILE_FULFILLED = 'GET_EXCURSION_PROFILE_FULFILLED';
const RESET_STATUS_EXCURSION = 'RESET_STATUS_EXCURSION';
const GET_EXCURSION_TYPE = 'GET_EXCURSION_TYPE';
const GET_EXCURSION_TYPE_PENDING = 'GET_EXCURSION_TYPE_PENDING';
const GET_EXCURSION_TYPE_FULFILLED = 'GET_EXCURSION_TYPE_FULFILLED';
const GET_EXCURSION_TYPE_REJECTED = 'GET_EXCURSION_TYPE_REJECTED';
const POST_EXCURSION_PROFILE = 'POST_EXCURSION_PROFILE';
const POST_EXCURSION_PROFILE_PENDING = 'POST_EXCURSION_PROFILE_PENDING';
const POST_EXCURSION_PROFILE_FULFILLED = 'POST_EXCURSION_PROFILE_FULFILLED';
const POST_EXCURSION_PROFILE_REJECTED = 'POST_EXCURSION_PROFILE_REJECTED';
const PUT_EXCURSION_PROFILE = 'PUT_EXCURSION_PROFILE';
const PUT_EXCURSION_PROFILE_PENDING = 'PUT_EXCURSION_PROFILE_PENDING';
const PUT_EXCURSION_PROFILE_FULFILLED = 'PUT_EXCURSION_PROFILE_FULFILLED';
const PUT_EXCURSION_PROFILE_REJECTED = 'PUT_EXCURSION_PROFILE_REJECTED';
const GET_EXCURSION_PRICE_TYPE = 'GET_EXCURSION_PRICE_TYPE';
const GET_EXCURSION_PRICE_TYPE_PENDING = 'GET_EXCURSION_PRICE_TYPE_PENDING';
const GET_EXCURSION_PRICE_TYPE_FULFILLED = 'GET_EXCURSION_PRICE_TYPE_FULFILLED';
const GET_EXCURSION_PRICE_TYPE_REJECTED = 'GET_EXCURSION_PRICE_TYPE_REJECTED';
const GET_ATTRACTION_ESTIMATE_PRICE = 'GET_ATTRACTION_ESTIMATE_PRICE';
const GET_ATTRACTION_ESTIMATE_PRICE_FULFILLED =
  'GET_ATTRACTION_ESTIMATE_PRICE_FULFILLED';
const GET_ATTRACTION_ESTIMATE_PRICE_PENDING =
  'GET_ATTRACTION_ESTIMATE_PRICE_PENDING';
const GET_ATTRACTION_ESTIMATE_PRICE_REJECTED =
  'GET_ATTRACTION_ESTIMATE_PRICE_REJECTED';

//User Access
const GET_USER_ACCESS_LIST = 'GET_USER_LIST';
const GET_USER_ACCESS_LIST_FULFILLED = 'GET_USER_LIST_FULFILLED';
const GET_USER_ACCESS_LIST_PENDING = 'GET_USER_LIST_PENDING';
const GET_USER_ACCESS_LIST_REJECTED = 'GET_USER_LIST_REJECTED';

const GET_USER_ROLES = 'GET_USER_ROLES';
const GET_USER_ROLES_FULFILLED = 'GET_USER_ROLES_FULFILLED';
const GET_USER_ROLES_PENDING = 'GET_USER_ROLES_PENDING';
const GET_USER_ROLES_REJECTED = 'GET_USER_ROLES_REJECTED';
//Locked User List
const GET_LOCKED_USER_LIST = 'GET_LOCKED_USER_LIST';
const GET_LOCKED_USER_LIST_FULFILLED = 'GET_LOCKED_USER_LIST_FULFILLED';
const GET_LOCKED_USER_LIST_PENDING = 'GET_LOCKED_USER_LIST_PENDING';
const GET_LOCKED_USER_LIST_REJECTED = 'GET_LOCKED_USER_LIST_REJECTED';
const RESET_STATUS_USER_ACCESS = 'RESET_STATUS_USER_ACCESS';
//Exchange Rate
const GET_EXCHANGE_RATE_LIST = 'GET_EXCHANGE_RATE_LIST';
const GET_EXCHANGE_RATE_LIST_FULFILLED = 'GET_EXCHANGE_RATE_LIST_FULFILLED';
const GET_EXCHANGE_RATE_LIST_PENDING = 'GET_EXCHANGE_RATE_LIST_PENDING';
const GET_EXCHANGE_RATE_LIST_REJECTED = 'GET_EXCHANGE_RATE_LIST_REJECTED';
const RESET_STATUS_EXCHANGE_RATE = 'RESET_STATUS_EXCHANGE_RATE';
//Transaction By Status ON HOLD
const GET_TRANSACTION_BY_STATUS_ON_HOLD_LIST =
  'GET_TRANSACTION_BY_STATUS_ON_HOLD_LIST';
const GET_TRANSACTION_BY_STATUS_ON_HOLD_LIST_FULFILLED =
  'GET_TRANSACTION_BY_STATUS_ON_HOLD_LIST_FULFILLED';
const GET_TRANSACTION_BY_STATUS_ON_HOLD_LIST_PENDING =
  'GET_TRANSACTION_BY_STATUS_ON_HOLD_LIST_PENDING';
const GET_TRANSACTION_BY_STATUS_ON_HOLD_LIST_REJECTED =
  'GET_TRANSACTION_BY_STATUS_ON_HOLD_LIST_REJECTED';
const RESET_STATUS_TRANSACTION_BY_STATUS_ON_HOLD =
  'RESET_STATUS_TRANSACTION_BY_STATUS_ON_HOLD';
//Transaction By Status DP
const GET_TRANSACTION_BY_STATUS_CREATED_LIST =
  'GET_TRANSACTION_BY_STATUS_CREATED_LIST';
const GET_TRANSACTION_BY_STATUS_CREATED_LIST_FULFILLED =
  'GET_TRANSACTION_BY_STATUS_CREATED_LIST_FULFILLED';
const GET_TRANSACTION_BY_STATUS_CREATED_LIST_PENDING =
  'GET_TRANSACTION_BY_STATUS_CREATED_LIST_PENDING';
const GET_TRANSACTION_BY_STATUS_CREATED_LIST_REJECTED =
  'GET_TRANSACTION_BY_STATUS_CREATED_LIST_REJECTED';
const RESET_STATUS_TRANSACTION_BY_STATUS_CREATED =
  'RESET_STATUS_TRANSACTION_BY_STATUS_CREATED';

//Transaction By Status CANCEL
const GET_TRANSACTION_BY_STATUS_CANCEL_LIST =
  'GET_TRANSACTION_BY_STATUS_CANCEL_LIST';
const GET_TRANSACTION_BY_STATUS_CANCEL_LIST_FULFILLED =
  'GET_TRANSACTION_BY_STATUS_CANCEL_LIST_FULFILLED';
const GET_TRANSACTION_BY_STATUS_CANCEL_LIST_PENDING =
  'GET_TRANSACTION_BY_STATUS_CANCEL_LIST_PENDING';
const GET_TRANSACTION_BY_STATUS_CANCEL_LIST_REJECTED =
  'GET_TRANSACTION_BY_STATUS_CANCEL_LIST_REJECTED';
//Transaction By Status COMPLETE
const GET_TRANSACTION_BY_STATUS_COMPLETE_LIST =
  'GET_TRANSACTION_BY_STATUS_COMPLETE_LIST';
const GET_TRANSACTION_BY_STATUS_COMPLETE_LIST_FULFILLED =
  'GET_TRANSACTION_BY_STATUS_COMPLETE_LIST_FULFILLED';
const GET_TRANSACTION_BY_STATUS_COMPLETE_LIST_PENDING =
  'GET_TRANSACTION_BY_STATUS_COMPLETE_LIST_PENDING';
const GET_TRANSACTION_BY_STATUS_COMPLETE_LIST_REJECTED =
  'GET_TRANSACTION_BY_STATUS_COMPLETE_LIST_REJECTED';
const RESET_STATUS_TRANSACTION_BY_STATUS_COMPLETE =
  'RESET_STATUS_TRANSACTION_BY_STATUS_COMPLETE';
//Transaction List
const GET_TRANSACTION_BY_PERIODE = 'GET_TRANSACTION_BY_PERIODE';
const GET_TRANSACTION_BY_PERIODE_PENDING = 'GET_TRANSACTION_BY_PERIODE_PENDING';
const GET_TRANSACTION_BY_PERIODE_FULFILLED =
  'GET_TRANSACTION_BY_PERIODE_FULFILLED';
const GET_TRANSACTION_BY_PERIODE_REJECTED =
  'GET_TRANSACTION_BY_PERIODE_REJECTED';
const GET_TRANSACTION_BY_ID = 'GET_TRANSACTION_BY_ID';
const GET_TRANSACTION_BY_ID_REJECTED = 'GET_TRANSACTION_BY_ID_REJECTED';
const GET_TRANSACTION_BY_ID_FULFILLED = 'GET_TRANSACTION_BY_ID_FULFILLED';
const GET_TRANSACTION_BY_ID_PENDING = 'GET_TRANSACTION_BY_ID_PENDING';
const GET_TRANSACTION_PAYABLE = 'GET_TRANSACTION_PAYABLE';
const GET_TRANSACTION_PAYABLE_FULFILLED = 'GET_TRANSACTION_PAYABLE_FULFILLED';
const GET_TRANSACTION_PAYABLE_PENDING = 'GET_TRANSACTION_PAYABLE_PENDING';
const GET_TRANSACTION_PAYABLE_REJECTED = 'GET_TRANSACTION_PAYABLE_REJECTED';
//Accomodation By Profile Id
const GET_ACCOMODATION_BY_PROFILE_ID = 'GET_ACCOMODATION_BY_PROFILE_ID';
const GET_ACCOMODATION_BY_PROFILE_ID_FULFILLED =
  'GET_ACCOMODATION_BY_PROFILE_ID_FULFILLED';
const GET_ACCOMODATION_BY_PROFILE_ID_PENDING =
  'GET_ACCOMODATION_BY_PROFILE_ID_PENDING';
const GET_ACCOMODATION_BY_PROFILE_ID_REJECTED =
  'GET_ACCOMODATION_BY_PROFILE_ID_REJECTED';

//Accomodation By Profile Id
const GET_ACCOMODATION_TYPES = 'GET_ACCOMODATION_TYPES';
const GET_ACCOMODATION_TYPES_FULFILLED = 'GET_ACCOMODATION_TYPES_FULFILLED';
const GET_ACCOMODATION_TYPES_PENDING = 'GET_ACCOMODATION_TYPES_PENDING';
const GET_ACCOMODATION_TYPES_REJECTED = 'GET_ACCOMODATION_TYPES_REJECTED';

//Accomodation Location
const GET_ACCOMODATION_LOCATION = 'GET_ACCOMODATION_LOCATION';
const GET_ACCOMODATION_LOCATION_FULFILLED =
  'GET_ACCOMODATION_LOCATION_FULFILLED';
const GET_ACCOMODATION_LOCATION_PENDING = 'GET_ACCOMODATION_LOCATION_PENDING';
const GET_ACCOMODATION_LOCATION_REJECTED = 'GET_ACCOMODATION_LOCATION_REJECTED';

//Accomodation Facilities
const GET_ACCOMODATION_FACILITIES = 'GET_ACCOMODATION_FACILITIES';
const GET_ACCOMODATION_FACILITIES_FULFILLED =
  'GET_ACCOMODATION_FACILITIES_FULFILLED';
const GET_ACCOMODATION_FACILITIES_PENDING =
  'GET_ACCOMODATION_FACILITIES_PENDING';
const GET_ACCOMODATION_FACILITIES_REJECTED =
  'GET_ACCOMODATION_FACILITIES_REJECTED';

//Accomodation Item By Profile Id
const GET_ACCOMODATION_ITEM_BY_PROFILE_ID =
  'GET_ACCOMODATION_ITEM_BY_PROFILE_ID';
const GET_ACCOMODATION_ITEM_BY_PROFILE_ID_FULFILLED =
  'GET_ACCOMODATION_ITEM_BY_PROFILE_ID_FULFILLED';
const GET_ACCOMODATION_ITEM_BY_PROFILE_ID_PENDING =
  'GET_ACCOMODATION_ITEM_BY_PROFILE_ID_PENDING';
const GET_ACCOMODATION_ITEM_BY_PROFILE_ID_REJECTED =
  'GET_ACCOMODATION_ITEM_BY_PROFILE_ID_REJECTED';

//Accomodation Item By Service Type ID
const GET_ACCOMODATION_ITEM_BY_SERVICE_TYPE_ID =
  'GET_ACCOMODATION_ITEM_BY_SERVICE_TYPE_ID';
const GET_ACCOMODATION_ITEM_BY_SERVICE_TYPE_ID_FULFILLED =
  'GET_ACCOMODATION_ITEM_BY_SERVICE_TYPE_ID_FULFILLED';
const GET_ACCOMODATION_ITEM_BY_SERVICE_TYPE_ID_PENDING =
  'GET_ACCOMODATION_ITEM_BY_SERVICE_TYPE_ID_PENDING';
const GET_ACCOMODATION_ITEM_BY_SERVICE_TYPE_ID_REJECTED =
  'GET_ACCOMODATION_ITEM_BY_SERVICE_TYPE_ID_REJECTED';

//Accomodation By Profile Id
const GET_ACCOMODATION_ITEM_TYPES = 'GET_ACCOMODATION_ITEM_TYPES';
const GET_ACCOMODATION_ITEM_TYPES_FULFILLED =
  'GET_ACCOMODATION_ITEM_TYPES_FULFILLED';
const GET_ACCOMODATION_ITEM_TYPES_PENDING =
  'GET_ACCOMODATION_ITEM_TYPES_PENDING';
const GET_ACCOMODATION_ITEM_TYPES_REJECTED =
  'GET_ACCOMODATION_ITEM_TYPES_REJECTED';

//Accomodation Item Service Type List
const GET_ACCOMODATION_ITEM_SERVICE_TYPE_LIST =
  'GET_ACCOMODATION_ITEM_SERVICE_TYPE_LIST';
const GET_ACCOMODATION_ITEM_SERVICE_TYPE_LIST_FULFILLED =
  'GET_ACCOMODATION_ITEM_SERVICE_TYPE_LIST_FULFILLED';
const GET_ACCOMODATION_ITEM_SERVICE_TYPE_LIST_PENDING =
  'GET_ACCOMODATION_ITEM_SERVICE_TYPE_LIST_PENDING';
const GET_ACCOMODATION_ITEM_SERVICE_TYPE_LIST_REJECTED =
  'GET_ACCOMODATION_ITEM_SERVICE_TYPE_LIST_REJECTED';

//Tour Type
const GET_TOUR_TYPE = 'GET_TOUR_TYPE';
const GET_TOUR_TYPE_FULFILLED = 'GET_TOUR_TYPE_FULFILLED';
const GET_TOUR_TYPE_PENDING = 'GET_TOUR_TYPE_PENDING';
const GET_TOUR_TYPE_REJECTED = 'GET_TOUR_TYPE_REJECTED';

//Tour Pax Type
const GET_TOUR_PAX_TYPE = 'GET_TOUR_PAX_TYPE';
const GET_TOUR_PAX_TYPE_FULFILLED = 'GET_TOUR_PAX_TYPE_FULFILLED';
const GET_TOUR_PAX_TYPE_PENDING = 'GET_TOUR_PAX_TYPE_PENDING';
const GET_TOUR_PAX_TYPE_REJECTED = 'GET_TOUR_PAX_TYPE_REJECTED';

//Get Country
const GET_COUNTRY = 'GET_COUNTRY';
const GET_COUNTRY_FULFILLED = 'GET_COUNTRY_FULFILLED';
const GET_COUNTRY_PENDING = 'GET_COUNTRY_PENDING';
const GET_COUNTRY_REJECTED = 'GET_COUNTRY_REJECTED';
const RESET_COUNTRY = 'RESET_COUNTRY';
// Get Region By Country
const GET_REGION_BY_COUNTRY = 'GET_REGION_BY_COUNTRY';
const GET_REGION_BY_COUNTRY_FULFILLED = 'GET_REGION_BY_COUNTRY_FULFILLED';
const GET_REGION_BY_COUNTRY_PENDING = 'GET_REGION_BY_COUNTRY_PENDING';
const GET_REGION_BY_COUNTRY_REJECTED = 'GET_REGION_BY_COUNTRY_REJECTED';
const RESET_REGION = 'RESET_REGION';
//Get City By Region
const GET_CITY_BY_REGION = 'GET_CITY_BY_REGION';
const GET_CITY_BY_REGION_FULFILLED = 'GET_CITY_BY_REGION_FULFILLED';
const GET_CITY_BY_REGION_PENDING = 'GET_CITY_BY_REGION_PENDING';
const GET_CITY_BY_REGION_REJECTED = 'GET_CITY_BY_REGION_REJECTED';
const GET_DEST_CITY_BY_REGION = 'GET_DEST_CITY_BY_REGION';
const GET_DEST_CITY_BY_REGION_PENDING = 'GET_DEST_CITY_BY_REGION_PENDING';
const GET_DEST_CITY_BY_REGION_FULFILLED = 'GET_DEST_CITY_BY_REGION_FULFILLED';
const GET_DEST_CITY_BY_REGION_REJECTED = 'GET_DEST_CITY_BY_REGION_REJECTED';
const GET_ORIGIN_CITY_BY_REGION = 'GET_ORIGIN_CITY_BY_REGION';
const GET_ORIGIN_CITY_BY_REGION_PENDING = 'GET_ORIGIN_CITY_BY_REGION_PENDING';
const GET_ORIGIN_CITY_BY_REGION_FULFILLED =
  'GET_ORIGIN_CITY_BY_REGION_FULFILLED';
const GET_ORIGIN_CITY_BY_REGION_REJECTED = 'GET_ORIGIN_CITY_BY_REGION_REJECTED';
const RESET_CITY = 'RESET_CITY';
//Get Area By City
const GET_AREA_BY_CITY = 'GET_AREA_BY_CITY';
const GET_AREA_BY_CITY_FULFILLED = 'GET_AREA_BY_CITY_FULFILLED';
const GET_AREA_BY_CITY_PENDING = 'GET_AREA_BY_CITY_PENDING';
const GET_AREA_BY_CITY_REJECTED = 'GET_AREA_BY_CITY_REJECTED';
const RESET_AREA = 'RESET_AREA';
//Post Accomodation
const POST_ACCOMODATION = 'POST_ACCOMODATION';
const POST_ACCOMODATION_FULFILLED = 'POST_ACCOMODATION_FULFILLED';
const POST_ACCOMODATION_PENDING = 'POST_ACCOMODATION_PENDING';
const POST_ACCOMODATION_REJECTED = 'POST_ACCOMODATION_REJECTED';

//Delete Accomodation By Profile Id
const DELETE_ACCOMODATION_BY_PROFILE_ID = 'DELETE_ACCOMODATION_BY_PROFILE_ID';
const DELETE_ACCOMODATION_BY_PROFILE_ID_FULFILLED =
  'DELETE_ACCOMODATION_BY_PROFILE_ID_FULFILLED';
const DELETE_ACCOMODATION_BY_PROFILE_ID_PENDING =
  'DELETE_ACCOMODATION_BY_PROFILE_ID_PENDING';
const DELETE_ACCOMODATION_BY_PROFILE_ID_REJECTED =
  'DELETE_ACCOMODATION_BY_PROFILE_ID_REJECTED';
const GET_ACCOMODATION_ITEM_BY_PROFILE_ID_AND_REQUEST_DATE =
  'GET_ACCOMODATION_ITEM_BY_PROFILE_ID_AND_REQUEST_DATE';
const GET_ACCOMODATION_ITEM_BY_PROFILE_ID_AND_REQUEST_DATE_PENDING =
  'GET_ACCOMODATION_ITEM_BY_PROFILE_ID_AND_REQUEST_DATE_PENDING';
const GET_ACCOMODATION_ITEM_BY_PROFILE_ID_AND_REQUEST_DATE_FULFILLED =
  'GET_ACCOMODATION_ITEM_BY_PROFILE_ID_AND_REQUEST_DATE_FULFILLED';
const GET_ACCOMODATION_ITEM_BY_PROFILE_ID_AND_REQUEST_DATE_REJECTED =
  'GET_ACCOMODATION_ITEM_BY_PROFILE_ID_AND_REQUEST_DATE_REJECTED';
//Get Template Accomodation
const GET_TEMPLATE_ACCOMODATION = 'GET_TEMPLATE_ACCOMODATION';
const GET_TEMPLATE_ACCOMODATION_FULFILLED =
  'GET_TEMPLATE_ACCOMODATION_FULFILLED';
const GET_TEMPLATE_ACCOMODATION_PENDING = 'GET_TEMPLATE_ACCOMODATION_PENDING';
const GET_TEMPLATE_ACCOMODATION_REJECTED = 'GET_TEMPLATE_ACCOMODATION_REJECTED';

//Get export Accomodation
const GET_EXPORT_ACCOMODATION = 'GET_EXPORT_ACCOMODATION';
const GET_EXPORT_ACCOMODATION_FULFILLED = 'GET_EXPORT_ACCOMODATION_FULFILLED';
const GET_EXPORT_ACCOMODATION_PENDING = 'GET_EXPORT_ACCOMODATION_PENDING';
const GET_EXPORT_ACCOMODATION_REJECTED = 'GET_EXPORT_ACCOMODATION_REJECTED';

//Put Accomodation
const PUT_ACCOMODATION = 'PUT_ACCOMODATION';
const PUT_ACCOMODATION_FULFILLED = 'PUT_ACCOMODATION_FULFILLED';
const PUT_ACCOMODATION_PENDING = 'PUT_ACCOMODATION_PENDING';
const PUT_ACCOMODATION_REJECTED = 'PUT_ACCOMODATION_REJECTED';
const PUT_ACCOMODATION_ITEM = 'PUT_ACCOMODATION_ITEM';
const PUT_ACCOMODATION_ITEM_PENDING = 'PUT_ACCOMODATION_ITEM_PENDING';
const PUT_ACCOMODATION_ITEM_FULFILLED = 'PUT_ACCOMODATION_ITEM_FULFILLED';
const PUT_ACCOMODATION_ITEM_REJECTED = 'PUT_ACCOMODATION_ITEM_REJECTED';
const POST_ACCOMODATION_ITEM = 'POST_ACCOMODATION_ITEM';
const POST_ACCOMODATION_ITEM_PENDING = 'POST_ACCOMODATION_ITEM_PENDING';
const POST_ACCOMODATION_ITEM_FULFILLED = 'POST_ACCOMODATION_ITEM_FULFILLED';
const POST_ACCOMODATION_ITEM_REJECTED = 'POST_ACCOMODATION_ITEM_REJECTED';

//GET RESTAURANT LIST
const GET_RESTAURANT_LIST = 'GET_RESTAURANT_LIST';
const GET_RESTAURANT_LIST_FULFILLED = 'GET_RESTAURANT_LIST_FULFILLED';
const GET_RESTAURANT_LIST_PENDING = 'GET_RESTAURANT_LIST_PENDING';
const GET_RESTAURANT_LIST_REJECTED = 'GET_RESTAURANT_LIST_REJECTED';
const RESET_RESTAURANT = 'RESET_RESTAURANT';

//GET RESTAURANT BY PROFILE ID
const GET_RESTAURANT_BY_PROFILE_ID = 'GET_RESTAURANT_BY_PROFILE_ID';
const GET_RESTAURANT_BY_PROFILE_ID_FULFILLED =
  'GET_RESTAURANT_BY_PROFILE_ID_FULFILLED';
const GET_RESTAURANT_BY_PROFILE_ID_PENDING =
  'GET_RESTAURANT_BY_PROFILE_ID_PENDING';
const GET_RESTAURANT_BY_PROFILE_ID_REJECTED =
  'GET_RESTAURANT_BY_PROFILE_ID_REJECTED';

//GET RESTAURANT ITEM BY PROFILE ID
const GET_RESTAURANT_ITEM_BY_PROFILE_ID = 'GET_RESTAURANT_ITEM_BY_PROFILE_ID';
const GET_RESTAURANT_ITEM_BY_PROFILE_ID_FULFILLED =
  'GET_RESTAURANT_ITEM_BY_PROFILE_ID_FULFILLED';
const GET_RESTAURANT_ITEM_BY_PROFILE_ID_PENDING =
  'GET_RESTAURANT_ITEM_BY_PROFILE_ID_PENDING';
const GET_RESTAURANT_ITEM_BY_PROFILE_ID_REJECTED =
  'GET_RESTAURANT_ITEM_BY_PROFILE_ID_REJECTED';

//DELETE RESTAURANT BY PROFILE ID
const DELETE_RESTAURANT_BY_PROFILE_ID = 'DELETE_RESTAURANT_BY_PROFILE_ID';
const DELETE_RESTAURANT_BY_PROFILE_ID_FULFILLED =
  'DELETE_RESTAURANT_BY_PROFILE_ID_FULFILLED';
const DELETE_RESTAURANT_BY_PROFILE_ID_PENDING =
  'DELETE_RESTAURANT_BY_PROFILE_ID_PENDING';
const DELETE_RESTAURANT_BY_PROFILE_ID_REJECTED =
  'DELETE_RESTAURANT_BY_PROFILE_ID_REJECTED';

//GET RESTAURANT CLASS
const GET_RESTAURANT_CLASS = 'GET_RESTAURANT_CLASS';
const GET_RESTAURANT_CLASS_FULFILLED = 'GET_RESTAURANT_CLASS_FULFILLED';
const GET_RESTAURANT_CLASS_PENDING = 'GET_RESTAURANT_CLASS_PENDING';
const GET_RESTAURANT_CLASS_REJECTED = 'GET_RESTAURANT_CLASS_REJECTED';
const GET_RESTAURANT_CATEGORY = 'GET_RESTAURANT_CATEGORY';
const GET_RESTAURANT_CATEGORY_FULFILLED = 'GET_RESTAURANT_CATEGORY_FULFILLED';
const GET_RESTAURANT_CATEGORY_PENDING = 'GET_RESTAURANT_CATEGORY_PENDING';
const GET_RESTAURANT_CATEGORY_REJECRED = 'GET_RESTAURANT_CATEGORY_REJECTED';

//GET RESTAURANT TYPE
const GET_RESTAURANT_TYPE = 'GET_RESTAURANT_TYPE';
const GET_RESTAURANT_TYPE_FULFILLED = 'GET_RESTAURANT_TYPE_FULFILLED';
const GET_RESTAURANT_TYPE_PENDING = 'GET_RESTAURANT_TYPE_PENDING';
const GET_RESTAURANT_TYPE_REJECTED = 'GET_RESTAURANT_TYPE_REJECTED';

// SERVICE PRICE
const GET_SERVICE_PRICE = 'GET_SERVICE_PRICE';
const GET_SERVICE_PRICE_PENDING = 'GET_SERVICE_PRICE_PENDING';
const GET_SERVICE_PRICE_FULFILLED = 'GET_SERVICE_PRICE_FULFILLED';
const GET_SERVICE_PRICE_REJECTED = 'GET_SERVICE_PRICE_REJECTED';
const POST_SERVICE_PRICE = 'POST_SERVICE_PRICE';
const POST_SERVICE_PRICE_PENDING = 'POST_SERVICE_PRICE_PENDING';
const POST_SERVICE_PRICE_FULFILLED = 'POST_SERVICE_PRICE_FULFILLED';
const POST_SERVICE_PRICE_REJECTED = 'POST_SERVICE_PRICE_REJECTED';
const PUT_SERVICE_PRICE = 'PUT_SERVICE_PRICE';
const PUT_SERVICE_PRICE_PENDING = 'PUT_SERVICE_PRICE_PENDING';
const PUT_SERVICE_PRICE_FULFILLED = 'PUT_SERVICE_PRICE_FULFILLED';
const PUT_SERVICE_PRICE_REJECTED = 'PUT_SERVICE_PRICE_REJECTED';
const RESET_STATUS_SERVICE_PRICE = 'RESET_STATUS_SERVICE_PRICE';
const GET_SERVICE_PRICE_TYPE = 'GET_SERVICE_PRICE_TYPE';
const GET_SERVICE_PRICE_TYPE_PENDING = 'GET_SERVICE_PRICE_TYPE_PENDING';
const GET_SERVICE_PRICE_TYPE_FULFILLED = 'GET_SERVICE_PRICE_TYPE_FULFILLED';
const GET_SERVICE_PRICE_TYPE_REJECTED = 'GET_SERVICE_PRICE_TYPE_REJECTED';
const DELETE_SERVICE_PRICE = 'DELETE_SERVICE_PRICE';
const DELETE_SERVICE_PRICE_PENDING = 'DELETE_SERVICE_PRICE_PENDING';
const DELETE_SERVICE_PRICE_FULFILLED = 'DELETE_SERVICE_PRICE_FULFILLED';
const DELETE_SERVICE_PRICE_REJECTED = 'DELETE_SERVICE_PRICE_REJECTED';

//Get Place
const GET_PLACE_LIST = 'GET_PLACE_LIST';
const GET_PLACE_LIST_FULFILLED = 'GET_PLACE_LIST_FULFILLED';
const GET_PLACE_LIST_PENDING = 'GET_PLACE_LIST_PENDING';
const GET_PLACE_LIST_REJECTED = 'GET_PLACE_LIST_REJECTED';
const RESET_PLACE = 'RESET_PLACE';
// Get Region By Country
const GET_REGION_LIST = 'GET_REGION_LIST';
const GET_REGION_LIST_FULFILLED = 'GET_REGION_LIST_FULFILLED';
const GET_REGION_LIST_PENDING = 'GET_REGION_LIST_PENDING';
const GET_REGION_LIST_REJECTED = 'GET_REGION_LIST_REJECTED';
//Get City By Region
const GET_CITY_LIST = 'GET_CITY_LIST';
const GET_CITY_LIST_FULFILLED = 'GET_CITY_LIST_FULFILLED';
const GET_CITY_LIST_PENDING = 'GET_CITY_LIST_PENDING';
const GET_CITY_LIST_REJECTED = 'GET_CITY_LIST_REJECTED';
//Get Area By City
const GET_AREA_LIST = 'GET_AREA_LIST';
const GET_AREA_LIST_FULFILLED = 'GET_AREA_LIST_FULFILLED';
const GET_AREA_LIST_PENDING = 'GET_AREA_LIST_PENDING';
const GET_AREA_LIST_REJECTED = 'GET_AREA_LIST_REJECTED';

//Get Country
const GET_COUNTRY_LIST = 'GET_COUNTRY_LIST';
const GET_COUNTRY_LIST_FULFILLED = 'GET_COUNTRY_LIST_FULFILLED';
const GET_COUNTRY_LIST_PENDING = 'GET_COUNTRY_LIST_PENDING';
const GET_COUNTRY_LIST_REJECTED = 'GET_COUNTRY_LIST_REJECTED';

//Delete Place
const DELETE_PLACE = 'DELETE_PLACE';
const DELETE_PLACE_FULFILLED = 'DELETE_PLACE_FULFILLED';
const DELETE_PLACE_PENDING = 'DELETE_PLACE_PENDING';
const DELETE_PLACE_REJECTED = 'DELETE_PLACE_REJECTED';
// Delete Region By Country
const DELETE_REGION = 'DELETE_REGION';
const DELETE_REGION_FULFILLED = 'DELETE_REGION_FULFILLED';
const DELETE_REGION_PENDING = 'DELETE_REGION_PENDING';
const DELETE_REGION_REJECTED = 'DELETE_REGION_REJECTED';
//Delete City By Region
const DELETE_CITY = 'DELETE_CITY';
const DELETE_CITY_FULFILLED = 'DELETE_CITY_FULFILLED';
const DELETE_CITY_PENDING = 'DELETE_CITY_PENDING';
const DELETE_CITY_REJECTED = 'DELETE_CITY_REJECTED';
//Delete Area By City
const DELETE_AREA = 'DELETE_AREA';
const DELETE_AREA_FULFILLED = 'DELETE_AREA_FULFILLED';
const DELETE_AREA_PENDING = 'DELETE_AREA_PENDING';
const DELETE_AREA_REJECTED = 'DELETE_AREA_REJECTED';

//Delete Country
const DELETE_COUNTRY = 'DELETE_COUNTRY';
const DELETE_COUNTRY_FULFILLED = 'DELETE_COUNTRY_FULFILLED';
const DELETE_COUNTRY_PENDING = 'DELETE_COUNTRY_PENDING';
const DELETE_COUNTRY_REJECTED = 'DELETE_COUNTRY_REJECTED';

//Get By ID => Place
const GET_PLACE_BY_ID = 'GET_PLACE_BY_ID';
const GET_PLACE_BY_ID_FULFILLED = 'GET_PLACE_BY_ID_FULFILLED';
const GET_PLACE_BY_ID_PENDING = 'GET_PLACE_BY_ID_PENDING';
const GET_PLACE_BY_ID_REJECTED = 'GET_PLACE_BY_ID_REJECTED';
// Get By ID => Region By Country
const GET_REGION_BY_ID = 'GET_REGION_BY_ID';
const GET_REGION_BY_ID_FULFILLED = 'GET_REGION_BY_ID_FULFILLED';
const GET_REGION_BY_ID_PENDING = 'GET_REGION_BY_ID_PENDING';
const GET_REGION_BY_ID_REJECTED = 'GET_REGION_BY_ID_REJECTED';
//Get By ID => City By Region
const GET_CITY_BY_ID = 'GET_CITY_BY_ID';
const GET_CITY_BY_ID_FULFILLED = 'GET_CITY_BY_ID_FULFILLED';
const GET_CITY_BY_ID_PENDING = 'GET_CITY_BY_ID_PENDING';
const GET_CITY_BY_ID_REJECTED = 'GET_CITY_BY_ID_REJECTED';
//Get By ID => Area By City
const GET_AREA_BY_ID = 'GET_AREA_BY_ID';
const GET_AREA_BY_ID_FULFILLED = 'GET_AREA_BY_ID_FULFILLED';
const GET_AREA_BY_ID_PENDING = 'GET_AREA_BY_ID_PENDING';
const GET_AREA_BY_ID_REJECTED = 'GET_AREA_BY_ID_REJECTED';

//Get By ID => Country
const GET_COUNTRY_BY_ID = 'GET_COUNTRY_BY_ID';
const GET_COUNTRY_BY_ID_FULFILLED = 'GET_COUNTRY_BY_ID_FULFILLED';
const GET_COUNTRY_BY_ID_PENDING = 'GET_COUNTRY_BY_ID_PENDING';
const GET_COUNTRY_BY_ID_REJECTED = 'GET_COUNTRY_BY_ID_REJECTED';

//POST AREA
const POST_AREA = 'POST_AREA';
const POST_AREA_FULFILLED = 'POST_AREA_FULFILLED';
const POST_AREA_PENDING = 'POST_AREA_PENDING';
const POST_AREA_REJECTED = 'POST_AREA_REJECTED';

//PUT AREA
const PUT_AREA = 'PUT_AREA';
const PUT_AREA_FULFILLED = 'PUT_AREA_FULFILLED';
const PUT_AREA_PENDING = 'PUT_AREA_PENDING';
const PUT_AREA_REJECTED = 'PUT_AREA_REJECTED';

//POST REGION
const POST_REGION = 'POST_REGION';
const POST_REGION_FULFILLED = 'POST_REGION_FULFILLED';
const POST_REGION_PENDING = 'POST_REGION_PENDING';
const POST_REGION_REJECTED = 'POST_REGION_REJECTED';

//PUT REGION
const PUT_REGION = 'PUT_REGION';
const PUT_REGION_FULFILLED = 'PUT_REGION_FULFILLED';
const PUT_REGION_PENDING = 'PUT_REGION_PENDING';
const PUT_REGION_REJECTED = 'PUT_REGION_REJECTED';

//POST CITY
const POST_CITY = 'POST_CITY';
const POST_CITY_FULFILLED = 'POST_CITY_FULFILLED';
const POST_CITY_PENDING = 'POST_CITY_PENDING';
const POST_CITY_REJECTED = 'POST_CITY_REJECTED';

//PUT CITY
const PUT_CITY = 'PUT_CITY';
const PUT_CITY_FULFILLED = 'PUT_CITY_FULFILLED';
const PUT_CITY_PENDING = 'PUT_CITY_PENDING';
const PUT_CITY_REJECTED = 'PUT_CITY_REJECTED';

//POST COUNTRY
const POST_COUNTRY = 'POST_COUNTRY';
const POST_COUNTRY_FULFILLED = 'POST_COUNTRY_FULFILLED';
const POST_COUNTRY_PENDING = 'POST_COUNTRY_PENDING';
const POST_COUNTRY_REJECTED = 'POST_COUNTRY_REJECTED';

//PUT COUNTRY
const PUT_COUNTRY = 'PUT_COUNTRY';
const PUT_COUNTRY_FULFILLED = 'PUT_COUNTRY_FULFILLED';
const PUT_COUNTRY_PENDING = 'PUT_COUNTRY_PENDING';
const PUT_COUNTRY_REJECTED = 'PUT_COUNTRY_REJECTED';

//POST PLACE
const POST_PLACE = 'POST_PLACE';
const POST_PLACE_FULFILLED = 'POST_PLACE_FULFILLED';
const POST_PLACE_PENDING = 'POST_PLACE_PENDING';
const POST_PLACE_REJECTED = 'POST_PLACE_REJECTED';

//PUT PLACE
const PUT_PLACE = 'PUT_PLACE';
const PUT_PLACE_FULFILLED = 'PUT_PLACE_FULFILLED';
const PUT_PLACE_PENDING = 'PUT_PLACE_PENDING';
const PUT_PLACE_REJECTED = 'PUT_PLACE_REJECTED';

//PUT PLACE
const GET_PLACE_TYPE = 'GET_PLACE_TYPE';
const GET_PLACE_TYPE_FULFILLED = 'GET_PLACE_TYPE_FULFILLED';
const GET_PLACE_TYPE_PENDING = 'GET_PLACE_TYPE_PENDING';
const GET_PLACE_TYPE_REJECTED = 'GET_PLACE_TYPE_REJECTED';

//POST RESTAURANT_PROFILE
const POST_RESTAURANT_PROFILE = 'POST_RESTAURANT_PROFILE';
const POST_RESTAURANT_PROFILE_FULFILLED = 'POST_RESTAURANT_PROFILE_FULFILLED';
const POST_RESTAURANT_PROFILE_PENDING = 'POST_RESTAURANT_PROFILE_PENDING';
const POST_RESTAURANT_PROFILE_REJECTED = 'POST_RESTAURANT_PROFILE_REJECTED';

//PUT RESTAURANT_PROFILE
const PUT_RESTAURANT_PROFILE = 'PUT_RESTAURANT_PROFILE';
const PUT_RESTAURANT_PROFILE_FULFILLED = 'PUT_RESTAURANT_PROFILE_FULFILLED';
const PUT_RESTAURANT_PROFILE_PENDING = 'PUT_RESTAURANT_PROFILE_PENDING';
const PUT_RESTAURANT_PROFILE_REJECTED = 'PUT_RESTAURANT_PROFILE_REJECTED';

const GET_RESTAURANT_ITEM = 'GET_RESTAURANT_ITEM';
const GET_RESTAURANT_ITEM_PENDING = 'GET_RESTAURANT_ITEM_PENDING';
const GET_RESTAURANT_ITEM_FULFILLED = 'GET_RESTAURANT_ITEM_FULFILLED';
const GET_RESTAURANT_ITEM_REJECRED = 'GET_RESTAURANT_ITEM_REJECTED';
const GET_RESTAURANT_ITEM_BY_ID = 'GET_RESTAURANT_ITEM_BY_ID';
const GET_RESTAURANT_ITEM_BY_ID_PENDING = 'GET_RESTAURANT_ITEM_BY_ID_PENDING';
const GET_RESTAURANT_ITEM_BY_ID_FULFILLED =
  'GET_RESTAURANT_ITEM_BY_ID_FULFILLED';
const GET_RESTAURANT_ITEM_BY_ID_REJECRED = 'GET_RESTAURANT_ITEM_BY_ID_REJECTED';
const POST_RESTAURANT_ITEM = 'POST_RESTAURANT_ITEM';
const POST_RESTAURANT_ITEM_PENDING = 'POST_RESTAURANT_ITEM_PENDING';
const POST_RESTAURANT_ITEM_FULFILLED = 'POST_RESTAURANT_ITEM_FULFILLED';
const POST_RESTAURANT_ITEM_REJECTED = 'POST_RESTAURANT_ITEM_REJECTED';
const PUT_RESTAURANT_ITEM = 'PUT_RESTAURANT_ITEM';
const PUT_RESTAURANT_ITEM_PENDING = 'PUT_RESTAURANT_ITEM_PENDING';
const PUT_RESTAURANT_ITEM_FULFILLED = 'PUT_RESTAURANT_ITEM_FULFILLED';
const PUT_RESTAURANT_ITEM_REJECTED = 'PUT_RESTAURANT_ITEM_REJECTED';

//GET REVENUE_RANGE
const GET_CUSTOM_ENUM_BY_CATAGORIES_REVENUE_RANGE =
  'GET_CUSTOM_ENUM_BY_CATAGORIES_REVENUE_RANGE';
const GET_CUSTOM_ENUM_BY_CATAGORIES_REVENUE_RANGE_FULFILLED =
  'GET_CUSTOM_ENUM_BY_CATAGORIES_REVENUE_RANGE_FULFILLED';
const GET_CUSTOM_ENUM_BY_CATAGORIES_REVENUE_RANGE_PENDING =
  'GET_CUSTOM_ENUM_BY_CATAGORIES_REVENUE_RANGE_PENDING';
const GET_CUSTOM_ENUM_BY_CATAGORIES_REVENUE_RANGE_REJECTED =
  'GET_CUSTOM_ENUM_BY_CATAGORIES_REVENUE_RANGE_REJECTED';
const RESET_CUSTOM_ENUM = 'RESET_CUSTOM_ENUM';

//GET REVENUE_RANGE
const GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_BRANCH =
  'GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_BRANCH';
const GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_BRANCH_FULFILLED =
  'GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_BRANCH_FULFILLED';
const GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_BRANCH_PENDING =
  'GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_BRANCH_PENDING';
const GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_BRANCH_REJECTED =
  'GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_BRANCH_REJECTED';

//GET REVENUE_RANGE
const GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_EMPLOYE =
  'GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_EMPLOYE';
const GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_EMPLOYE_FULFILLED =
  'GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_EMPLOYE_FULFILLED';
const GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_EMPLOYE_PENDING =
  'GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_EMPLOYE_PENDING';
const GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_EMPLOYE_REJECTED =
  'GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_EMPLOYE_REJECTED';

//PUT CITY_GROUP_DETAIL
const PUT_CITY_GROUP_DETAIL = 'PUT_CITY_GROUP_DETAIL';
const PUT_CITY_GROUP_DETAIL_FULFILLED = 'PUT_CITY_GROUP_DETAIL_FULFILLED';
const PUT_CITY_GROUP_DETAIL_PENDING = 'PUT_CITY_GROUP_DETAIL_PENDING';
const PUT_CITY_GROUP_DETAIL_REJECTED = 'PUT_CITY_GROUP_DETAIL_REJECTED';

// GET PAYMENT_METHODE
const GET_PAYMENT_METHOD = 'GET_PAYMENT_METHOD';
const GET_PAYMENT_METHOD_FULFILLED = 'GET_PAYMENT_METHOD_FULFILLED';
const GET_PAYMENT_METHOD_PENDING = 'GET_PAYMENT_METHOD_PENDING';
const GET_PAYMENT_METHOD_REJECTED = 'GET_PAYMENT_METHOD_REJECTED';

//POST PREVIEW ACCOMODATION ITEM
const POST_UPLOAD_PREVIEW_ACCOMODATION_ITEM =
  'POST_UPLOAD_PREVIEW_ACCOMODATION_ITEM';
const POST_UPLOAD_PREVIEW_ACCOMODATION_ITEM_FULFILLED =
  'POST_UPLOAD_PREVIEW_ACCOMODATION_ITEM_FULFILLED';
const POST_UPLOAD_PREVIEW_ACCOMODATION_ITEM_PENDING =
  'POST_UPLOAD_PREVIEW_ACCOMODATION_ITEM_PENDING';
const POST_UPLOAD_PREVIEW_ACCOMODATION_ITEM_REJECTED =
  'POST_UPLOAD_PREVIEW_ACCOMODATION_ITEM_REJECTED';

//POST COMMIT ACCOMODATION ITEM
const POST_UPLOAD_COMMIT_ACCOMODATION_ITEM =
  'POST_UPLOAD_COMMIT_ACCOMODATION_ITEM';
const POST_UPLOAD_COMMIT_ACCOMODATION_ITEM_FULFILLED =
  'POST_UPLOAD_COMMIT_ACCOMODATION_ITEM_FULFILLED';
const POST_UPLOAD_COMMIT_ACCOMODATION_ITEM_PENDING =
  'POST_UPLOAD_COMMIT_ACCOMODATION_ITEM_PENDING';
const POST_UPLOAD_COMMIT_ACCOMODATION_ITEM_REJECTED =
  'POST_UPLOAD_COMMIT_ACCOMODATION_ITEM_REJECTED';

//POST PREVIEW RESTAURANT_ITEM
const POST_UPLOAD_PREVIEW_RESTAURANT_ITEM =
  'POST_UPLOAD_PREVIEW_RESTAURANT_ITEM';
const POST_UPLOAD_PREVIEW_RESTAURANT_ITEM_FULFILLED =
  'POST_UPLOAD_PREVIEW_RESTAURANT_ITEM_FULFILLED';
const POST_UPLOAD_PREVIEW_RESTAURANT_ITEM_PENDING =
  'POST_UPLOAD_PREVIEW_RESTAURANT_ITEM_PENDING';
const POST_UPLOAD_PREVIEW_RESTAURANT_ITEM_REJECTED =
  'POST_UPLOAD_PREVIEW_RESTAURANT_ITEM_REJECTED';

//POST COMMIT RESTAURANT_ITEM
const POST_UPLOAD_COMMIT_RESTAURANT_ITEM = 'POST_UPLOAD_COMMIT_RESTAURANT_ITEM';
const POST_UPLOAD_COMMIT_RESTAURANT_ITEM_FULFILLED =
  'POST_UPLOAD_COMMIT_RESTAURANT_ITEM_FULFILLED';
const POST_UPLOAD_COMMIT_RESTAURANT_ITEM_PENDING =
  'POST_UPLOAD_COMMIT_RESTAURANT_ITEM_PENDING';
const POST_UPLOAD_COMMIT_RESTAURANT_ITEM_REJECTED =
  'POST_UPLOAD_COMMIT_RESTAURANT_ITEM_REJECTED';

//POST PREVIEW RESTAURANT_PRICE
const POST_UPLOAD_PREVIEW_RESTAURANT_PRICE =
  'POST_UPLOAD_PREVIEW_RESTAURANT_PRICE';
const POST_UPLOAD_PREVIEW_RESTAURANT_PRICE_FULFILLED =
  'POST_UPLOAD_PREVIEW_RESTAURANT_PRICE_FULFILLED';
const POST_UPLOAD_PREVIEW_RESTAURANT_PRICE_PENDING =
  'POST_UPLOAD_PREVIEW_RESTAURANT_PRICE_PENDING';
const POST_UPLOAD_PREVIEW_RESTAURANT_PRICE_REJECTED =
  'POST_UPLOAD_PREVIEW_RESTAURANT_PRICE_REJECTED';

//POST COMMIT RESTAURANT_PRICE
const POST_UPLOAD_COMMIT_RESTAURANT_PRICE =
  'POST_UPLOAD_COMMIT_RESTAURANT_PRICE';
const POST_UPLOAD_COMMIT_RESTAURANT_PRICE_FULFILLED =
  'POST_UPLOAD_COMMIT_RESTAURANT_PRICE_FULFILLED';
const POST_UPLOAD_COMMIT_RESTAURANT_PRICE_PENDING =
  'POST_UPLOAD_COMMIT_RESTAURANT_PRICE_PENDING';
const POST_UPLOAD_COMMIT_RESTAURANT_PRICE_REJECTED =
  'POST_UPLOAD_COMMIT_RESTAURANT_PRICE_REJECTED';

//POST PREVIEW EXCURSION_PRICE
const POST_UPLOAD_PREVIEW_EXCURSION_PRICE =
  'POST_UPLOAD_PREVIEW_EXCURSION_PRICE';
const POST_UPLOAD_PREVIEW_EXCURSION_PRICE_FULFILLED =
  'POST_UPLOAD_PREVIEW_EXCURSION_PRICE_FULFILLED';
const POST_UPLOAD_PREVIEW_EXCURSION_PRICE_PENDING =
  'POST_UPLOAD_PREVIEW_EXCURSION_PRICE_PENDING';
const POST_UPLOAD_PREVIEW_EXCURSION_PRICE_REJECTED =
  'POST_UPLOAD_PREVIEW_EXCURSION_PRICE_REJECTED';

//POST COMMIT EXCURSION_PRICE
const POST_UPLOAD_COMMIT_EXCURSION_PRICE = 'POST_UPLOAD_COMMIT_EXCURSION_PRICE';
const POST_UPLOAD_COMMIT_EXCURSION_PRICE_FULFILLED =
  'POST_UPLOAD_COMMIT_EXCURSION_PRICE_FULFILLED';
const POST_UPLOAD_COMMIT_EXCURSION_PRICE_PENDING =
  'POST_UPLOAD_COMMIT_EXCURSION_PRICE_PENDING';
const POST_UPLOAD_COMMIT_EXCURSION_PRICE_REJECTED =
  'POST_UPLOAD_COMMIT_EXCURSION_PRICE_REJECTED';

const GET_EXCURSION_OBJECT_EXPORT_DATA = 'GET_EXCURSION_OBJECT_EXPORT_DATA';
const GET_EXCURSION_OBJECT_EXPORT_DATA_FULFILLED =
  'GET_EXCURSION_OBJECT_EXPORT_DATA_FULFILLED';
const GET_EXCURSION_OBJECT_EXPORT_DATA_PENDING =
  'GET_EXCURSION_OBJECT_EXPORT_DATA_PENDING';
const GET_EXCURSION_OBJECT_EXPORT_DATA_REJECTED =
  'GET_EXCURSION_OBJECT_EXPORT_DATA_REJECTED';

const GET_EXCURSION_PRICE_EXPORT_DATA = 'GET_EXCURSION_PRICE_EXPORT_DATA';
const GET_EXCURSION_PRICE_EXPORT_DATA_FULFILLED =
  'GET_EXCURSION_PRICE_EXPORT_DATA_FULFILLED';
const GET_EXCURSION_PRICE_EXPORT_DATA_PENDING =
  'GET_EXCURSION_PRICE_EXPORT_DATA_PENDING';
const GET_EXCURSION_PRICE_EXPORT_DATA_REJECTED =
  'GET_EXCURSION_PRICE_EXPORT_DATA_REJECTED';

const GET_RESTAURANT_ITEM_EXPORT_DATA = 'GET_RESTAURANT_ITEM_EXPORT_DATA';
const GET_RESTAURANT_ITEM_EXPORT_DATA_FULFILLED =
  'GET_RESTAURANT_ITEM_EXPORT_DATA_FULFILLED';
const GET_RESTAURANT_ITEM_EXPORT_DATA_PENDING =
  'GET_RESTAURANT_ITEM_EXPORT_DATA_PENDING';
const GET_RESTAURANT_ITEM_EXPORT_DATA_REJECTED =
  'GET_RESTAURANT_ITEM_EXPORT_DATA_REJECTED';

const GET_RESTAURANT_ITEM_PRICE_EXPORT_DATA =
  'GET_RESTAURANT_ITEM_PRICE_EXPORT_DATA';
const GET_RESTAURANT_ITEM_PRICE_EXPORT_DATA_FULFILLED =
  'GET_RESTAURANT_ITEM_PRICE_EXPORT_DATA_FULFILLED';
const GET_RESTAURANT_ITEM_PRICE_EXPORT_DATA_PENDING =
  'GET_RESTAURANT_ITEM_PRICE_EXPORT_DATA_PENDING';
const GET_RESTAURANT_ITEM_PRICE_EXPORT_DATA_REJECTED =
  'GET_RESTAURANT_ITEM_PRICE_EXPORT_DATA_REJECTED';

const GET_ACCOMODATION_ITEM_EXPORT_DATA = 'GET_ACCOMODATION_ITEM_EXPORT_DATA';
const GET_ACCOMODATION_ITEM_EXPORT_DATA_FULFILLED =
  'GET_ACCOMODATION_ITEM_EXPORT_DATA_FULFILLED';
const GET_ACCOMODATION_ITEM_EXPORT_DATA_PENDING =
  'GET_ACCOMODATION_ITEM_EXPORT_DATA_PENDING';
const GET_ACCOMODATION_ITEM_EXPORT_DATA_REJECTED =
  'GET_ACCOMODATION_ITEM_EXPORT_DATA_REJECTED';

const GET_TEMPLATE_EXCURSION_OBJECT_DATA = 'GET_TEMPLATE_EXCURSION_OBJECT_DATA';
const GET_TEMPLATE_EXCURSION_OBJECT_DATA_FULFILLED =
  'GET_TEMPLATE_EXCURSION_OBJECT_DATA_FULFILLED';
const GET_TEMPLATE_EXCURSION_OBJECT_DATA_PENDING =
  'GET_TEMPLATE_EXCURSION_OBJECT_DATA_PENDING';
const GET_TEMPLATE_EXCURSION_OBJECT_DATA_REJECTED =
  'GET_TEMPLATE_EXCURSION_OBJECT_DATA_REJECTED';

const GET_TEMPLATE_EXCURSION_PRICE_DATA = 'GET_TEMPLATE_EXCURSION_PRICE_DATA';
const GET_TEMPLATE_EXCURSION_PRICE_DATA_FULFILLED =
  'GET_TEMPLATE_EXCURSION_PRICE_DATA_FULFILLED';
const GET_TEMPLATE_EXCURSION_PRICE_DATA_PENDING =
  'GET_TEMPLATE_EXCURSION_PRICE_DATA_PENDING';
const GET_TEMPLATE_EXCURSION_PRICE_DATA_REJECTED =
  'GET_TEMPLATE_EXCURSION_PRICE_DATA_REJECTED';

const GET_TEMPLATE_RESTAURANT_ITEM_DATA = 'GET_TEMPLATE_RESTAURANT_ITEM_DATA';
const GET_TEMPLATE_RESTAURANT_ITEM_DATA_FULFILLED =
  'GET_TEMPLATE_RESTAURANT_ITEM_DATA_FULFILLED';
const GET_TEMPLATE_RESTAURANT_ITEM_DATA_PENDING =
  'GET_TEMPLATE_RESTAURANT_ITEM_DATA_PENDING';
const GET_TEMPLATE_RESTAURANT_ITEM_DATA_REJECTED =
  'GET_TEMPLATE_RESTAURANT_ITEM_DATA_REJECTED';

const GET_TEMPLATE_RESTAURANT_ITEM_PRICE_DATA =
  'GET_TEMPLATE_RESTAURANT_ITEM_PRICE_DATA';
const GET_TEMPLATE_RESTAURANT_ITEM_PRICE_DATA_FULFILLED =
  'GET_TEMPLATE_RESTAURANT_ITEM_PRICE_DATA_FULFILLED';
const GET_TEMPLATE_RESTAURANT_ITEM_PRICE_DATA_PENDING =
  'GET_TEMPLATE_RESTAURANT_ITEM_PRICE_DATA_PENDING';
const GET_TEMPLATE_RESTAURANT_ITEM_PRICE_DATA_REJECTED =
  'GET_TEMPLATE_RESTAURANT_ITEM_PRICE_DATA_REJECTED';

const GET_TEMPLATE_RESTAURANT_PROFILE_DATA =
  'GET_TEMPLATE_RESTAURANT_PROFILE_DATA';
const GET_TEMPLATE_RESTAURANT_PROFILE_DATA_FULFILLED =
  'GET_TEMPLATE_RESTAURANT_PROFILE_DATA_FULFILLED';
const GET_TEMPLATE_RESTAURANT_PROFILE_DATA_PENDING =
  'GET_TEMPLATE_RESTAURANT_PROFILE_DATA_PENDING';
const GET_TEMPLATE_RESTAURANT_PROFILE_DATA_REJECTED =
  'GET_TEMPLATE_RESTAURANT_PROFILE_DATA_REJECTED';

const GET_TEMPLATE_ACCOMODATION_ITEM_DATA =
  'GET_TEMPLATE_ACCOMODATION_ITEM_DATA';
const GET_TEMPLATE_ACCOMODATION_ITEM_DATA_FULFILLED =
  'GET_TEMPLATE_ACCOMODATION_ITEM_DATA_FULFILLED';
const GET_TEMPLATE_ACCOMODATION_ITEM_DATA_PENDING =
  'GET_TEMPLATE_ACCOMODATION_ITEM_DATA_PENDING';
const GET_TEMPLATE_ACCOMODATION_ITEM_DATA_REJECTED =
  'GET_TEMPLATE_ACCOMODATION_ITEM_DATA_REJECTED';

const GET_ACCOMMODATION_BY_CITY = 'GET_ACCOMMODATION_BY_CITY';
const GET_ACCOMMODATION_BY_CITY_PENDING = 'GET_ACCOMMODATION_BY_CITY_PENDING';
const GET_ACCOMMODATION_BY_CITY_FULFILLED =
  'GET_ACCOMMODATION_BY_CITY_FULFILLED';
const GET_ACCOMMODATION_BY_CITY_REJECTED = 'GET_ACCOMMODATION_BY_CITY_REJECTED';

const DELETE_PAYMENT_METHOD_IN_COUNTRY = 'DELETE_PAYMENT_METHOD_IN_COUNTRY';
const DELETE_PAYMENT_METHOD_IN_COUNTRY_FULFILLED =
  'DELETE_PAYMENT_METHOD_IN_COUNTRY_FULFILLED';
const DELETE_PAYMENT_METHOD_IN_COUNTRY_PENDING =
  'DELETE_PAYMENT_METHOD_IN_COUNTRY_PENDING';
const DELETE_PAYMENT_METHOD_IN_COUNTRY_REJECTED =
  'DELETE_PAYMENT_METHOD_IN_COUNTRY_REJECTED';

const GET_PAYMENT_METHOD_IN_COUNTRY = 'GET_PAYMENT_METHOD_IN_COUNTRY';
const GET_PAYMENT_METHOD_IN_COUNTRY_FULFILLED =
  'GET_PAYMENT_METHOD_IN_COUNTRY_FULFILLED';
const GET_PAYMENT_METHOD_IN_COUNTRY_PENDING =
  'GET_PAYMENT_METHOD_IN_COUNTRY_PENDING';
const GET_PAYMENT_METHOD_IN_COUNTRY_REJECTED =
  'GET_PAYMENT_METHOD_IN_COUNTRY_REJECTED';

const POST_PAYMENT_METHOD_IN_COUNTRY = 'POST_PAYMENT_METHOD_IN_COUNTRY';
const POST_PAYMENT_METHOD_IN_COUNTRY_FULFILLED =
  'POST_PAYMENT_METHOD_IN_COUNTRY_FULFILLED';
const POST_PAYMENT_METHOD_IN_COUNTRY_PENDING =
  'POST_PAYMENT_METHOD_IN_COUNTRY_PENDING';
const POST_PAYMENT_METHOD_IN_COUNTRY_REJECTED =
  'POST_PAYMENT_METHOD_IN_COUNTRY_REJECTED';

const GET_IMAGE_ALL = 'GET_IMAGE_ALL';
const GET_IMAGE_ALL_FULFILLED = 'GET_IMAGE_ALL_FULFILLED';
const GET_IMAGE_ALL_PENDING = 'GET_IMAGE_ALL_PENDING';
const GET_IMAGE_ALL_REJECTED = 'GET_IMAGE_ALL_REJECTED';
const RESET_IMAGE = 'RESET_IMAGE';

const GET_IMAGE_ALL_BY_ID = 'GET_IMAGE_ALL_BY_ID';
const GET_IMAGE_ALL_BY_ID_FULFILLED = 'GET_IMAGE_ALL_BY_ID_FULFILLED';
const GET_IMAGE_ALL_BY_ID_PENDING = 'GET_IMAGE_ALL_BY_ID_PENDING';
const GET_IMAGE_ALL_BY_ID_REJECTED = 'GET_IMAGE_ALL_BY_ID_REJECTED';

const POST_IMAGE = 'POST_IMAGE_';
const POST_IMAGE_FULFILLED = 'POST_IMAGE_FULFILLED';
const POST_IMAGE_PENDING = 'POST_IMAGE_PENDING';
const POST_IMAGE_REJECTED = 'POST_IMAGE_REJECTED';

const PUT_IMAGE = 'PUT_IMAGE';
const PUT_IMAGE_FULFILLED = 'PUT_IMAGE_FULFILLED';
const PUT_IMAGE_PENDING = 'PUT_IMAGE_PENDING';
const PUT_IMAGE_REJECTED = 'PUT_IMAGE_REJECTED';

const DELETE_IMAGE = 'DELETE_IMAGE';
const DELETE_IMAGE_FULFILLED = 'DELETE_IMAGE_FULFILLED';
const DELETE_IMAGE_PENDING = 'DELETE_IMAGE_PENDING';
const DELETE_IMAGE_REJECTED = 'DELETE_IMAGE_REJECTED';

//POST_READY_PACKAGE
const POST_READY_PACKAGE = 'POST_READY_PACKAGE';
const POST_READY_PACKAGE_FULFILLED = 'POST_READY_PACKAGE_FULFILLED';
const POST_READY_PACKAGE_PENDING = 'POST_READY_PACKAGE_PENDING';
const POST_READY_PACKAGE_REJECTED = 'POST_READY_PACKAGE_REJECTED';
const RESET_READY_PACKAGE = 'RESET_READY_PACKAGE';

//GET_READY_PACKAGE
const GET_READY_PACKAGE = 'GET_READY_PACKAGE';
const GET_READY_PACKAGE_FULFILLED = 'GET_READY_PACKAGE_FULFILLED';
const GET_READY_PACKAGE_PENDING = 'GET_READY_PACKAGE_PENDING';
const GET_READY_PACKAGE_REJECTED = 'GET_READY_PACKAGE_REJECTED';

//GET_READY_PACKAGE
const GET_READY_PACKAGE_DETAIL = 'GET_READY_PACKAGE_DETAIL';
const GET_READY_PACKAGE_DETAIL_FULFILLED = 'GET_READY_PACKAGE_DETAIL_FULFILLED';
const GET_READY_PACKAGE_DETAIL_PENDING = 'GET_READY_PACKAGE_DETAIL_PENDING';
const GET_READY_PACKAGE_DETAIL_REJECTED = 'GET_READY_PACKAGE_DETAIL_REJECTED';

//GET_PACKAGE_BY_TYPE_AND_ID
const GET_PACKAGE_BY_TYPE_AND_ID = 'GET_PACKAGE_BY_TYPE_AND_ID';
const GET_PACKAGE_BY_TYPE_AND_ID_FULFILLED =
  'GET_PACKAGE_BY_TYPE_AND_ID_FULFILLED';
const GET_PACKAGE_BY_TYPE_AND_ID_PENDING = 'GET_PACKAGE_BY_TYPE_AND_ID_PENDING';
const GET_PACKAGE_BY_TYPE_AND_ID_REJECTED =
  'GET_PACKAGE_BY_TYPE_AND_ID_REJECTED';

//GET_PAYMENT_METHOD_BY_COUNTRY_ID
const GET_PAYMENT_METHOD_BY_COUNTRY_ID = 'GET_PAYMENT_METHOD_BY_COUNTRY_ID';
const GET_PAYMENT_METHOD_BY_COUNTRY_ID_FULFILLED =
  'GET_PAYMENT_METHOD_BY_COUNTRY_ID_FULFILLED';
const GET_PAYMENT_METHOD_BY_COUNTRY_ID_PENDING =
  'GET_PAYMENT_METHOD_BY_COUNTRY_ID_PENDING';
const GET_PAYMENT_METHOD_BY_COUNTRY_ID_REJECTED =
  'GET_PAYMENT_METHOD_BY_COUNTRY_ID_REJECTED';

const GET_FIXED_PACKAGE = 'GET_FIXED_PACKAGE';
const GET_FIXED_PACKAGE_PENDING = 'GET_FIXED_PACKAGE_PENDING';
const GET_FIXED_PACKAGE_FULFILLED = 'GET_FIXED_PACKAGE_FULFILLED';
const GET_FIXED_PACKAGE_REJECTED = 'GET_FIXED_PACKAGE_REJECTED';
const GET_PACKAGES = 'GET_PACKAGES';
const GET_PACKAGES_PENDING = 'GET_PACKAGES_PENDING';
const GET_PACKAGES_FULFILLED = 'GET_PACKAGES_FULFILLED';
const GET_PACKAGES_REJECTED = 'GET_PACKAGES_REJECTED';
const POST_PACKAGE = 'POST_PACKAGE';
const POST_PACKAGE_PENDING = 'POST_PACKAGE_PENDING';
const POST_PACKAGE_FULFILLED = 'POST_PACKAGE_FULFILLED';
const POST_PACKAGE_REJECTED = 'POST_PACKAGE_REJECTED';
const PUT_PACKAGE = 'PUT_PACKAGE';
const PUT_PACKAGE_PENDING = 'PUT_PACKAGE_PENDING';
const PUT_PACKAGE_FULFILLED = 'PUT_PACKAGE_FULFILLED';
const PUT_PACKAGE_REJECTED = 'PUT_PACKAGE_REJECTED';
//Get City By Region
const GET_MASTER_REFERENCE_ACCOMODATION_FACILITIES =
  'GET_MASTER_REFERENCE_ACCOMODATION_FACILITIES';
const GET_MASTER_REFERENCE_ACCOMODATION_FACILITIES_FULFILLED =
  'GET_MASTER_REFERENCE_ACCOMODATION_FACILITIES_FULFILLED';
const GET_MASTER_REFERENCE_ACCOMODATION_FACILITIES_PENDING =
  'GET_MASTER_REFERENCE_ACCOMODATION_FACILITIES_PENDING';
const GET_MASTER_REFERENCE_ACCOMODATION_FACILITIES_REJECTED =
  'GET_MASTER_REFERENCE_ACCOMODATION_FACILITIES_REJECTED';
const RESET_MASTER_REFERENCE = 'RESET_MASTER_REFERENCE';

//Delete
const DELETE_MASTER_REFERENCE_ACCOMODATION_FACILITIES =
  'DELETE_MASTER_REFERENCE_ACCOMODATION_FACILITIES';
const DELETE_MASTER_REFERENCE_ACCOMODATION_FACILITIES_FULFILLED =
  'DELETE_MASTER_REFERENCE_ACCOMODATION_FACILITIES_FULFILLED';
const DELETE_MASTER_REFERENCE_ACCOMODATION_FACILITIES_PENDING =
  'DELETE_MASTER_REFERENCE_ACCOMODATION_FACILITIES_PENDING';
const DELETE_MASTER_REFERENCE_ACCOMODATION_FACILITIES_REJECTED =
  'DELETE_MASTER_REFERENCE_ACCOMODATION_FACILITIES_REJECTED';

//POST MASTER_REFERENCE_ACCOMODATION_FACILITIES
const POST_MASTER_REFERENCE_ACCOMODATION_FACILITIES =
  'POST_MASTER_REFERENCE_ACCOMODATION_FACILITIES';
const POST_MASTER_REFERENCE_ACCOMODATION_FACILITIES_FULFILLED =
  'POST_MASTER_REFERENCE_ACCOMODATION_FACILITIES_FULFILLED';
const POST_MASTER_REFERENCE_ACCOMODATION_FACILITIES_PENDING =
  'POST_MASTER_REFERENCE_ACCOMODATION_FACILITIES_PENDING';
const POST_MASTER_REFERENCE_ACCOMODATION_FACILITIES_REJECTED =
  'POST_MASTER_REFERENCE_ACCOMODATION_FACILITIES_REJECTED';

//PUT MASTER_REFERENCE_ACCOMODATION_FACILITIES
const PUT_MASTER_REFERENCE_ACCOMODATION_FACILITIES =
  'PUT_MASTER_REFERENCE_ACCOMODATION_FACILITIES';
const PUT_MASTER_REFERENCE_ACCOMODATION_FACILITIES_FULFILLED =
  'PUT_MASTER_REFERENCE_ACCOMODATION_FACILITIES_FULFILLED';
const PUT_MASTER_REFERENCE_ACCOMODATION_FACILITIES_PENDING =
  'PUT_MASTER_REFERENCE_ACCOMODATION_FACILITIES_PENDING';
const PUT_MASTER_REFERENCE_ACCOMODATION_FACILITIES_REJECTED =
  'PUT_MASTER_REFERENCE_ACCOMODATION_FACILITIES_REJECTED';

//Get City By Region
const GET_MASTER_REFERENCE_ACCOMODATION_TYPE =
  'GET_MASTER_REFERENCE_ACCOMODATION_TYPE';
const GET_MASTER_REFERENCE_ACCOMODATION_TYPE_FULFILLED =
  'GET_MASTER_REFERENCE_ACCOMODATION_TYPE_FULFILLED';
const GET_MASTER_REFERENCE_ACCOMODATION_TYPE_PENDING =
  'GET_MASTER_REFERENCE_ACCOMODATION_TYPE_PENDING';
const GET_MASTER_REFERENCE_ACCOMODATION_TYPE_REJECTED =
  'GET_MASTER_REFERENCE_ACCOMODATION_TYPE_REJECTED';

//Delete
const DELETE_MASTER_REFERENCE_ACCOMODATION_TYPE =
  'DELETE_MASTER_REFERENCE_ACCOMODATION_TYPE';
const DELETE_MASTER_REFERENCE_ACCOMODATION_TYPE_FULFILLED =
  'DELETE_MASTER_REFERENCE_ACCOMODATION_TYPE_FULFILLED';
const DELETE_MASTER_REFERENCE_ACCOMODATION_TYPE_PENDING =
  'DELETE_MASTER_REFERENCE_ACCOMODATION_TYPE_PENDING';
const DELETE_MASTER_REFERENCE_ACCOMODATION_TYPE_REJECTED =
  'DELETE_MASTER_REFERENCE_ACCOMODATION_TYPE_REJECTED';

//POST MASTER_REFERENCE_ACCOMODATION_TYPE
const POST_MASTER_REFERENCE_ACCOMODATION_TYPE =
  'POST_MASTER_REFERENCE_ACCOMODATION_TYPE';
const POST_MASTER_REFERENCE_ACCOMODATION_TYPE_FULFILLED =
  'POST_MASTER_REFERENCE_ACCOMODATION_TYPE_FULFILLED';
const POST_MASTER_REFERENCE_ACCOMODATION_TYPE_PENDING =
  'POST_MASTER_REFERENCE_ACCOMODATION_TYPE_PENDING';
const POST_MASTER_REFERENCE_ACCOMODATION_TYPE_REJECTED =
  'POST_MASTER_REFERENCE_ACCOMODATION_TYPE_REJECTED';

//PUT MASTER_REFERENCE_ACCOMODATION_TYPE
const PUT_MASTER_REFERENCE_ACCOMODATION_TYPE =
  'PUT_MASTER_REFERENCE_ACCOMODATION_TYPE';
const PUT_MASTER_REFERENCE_ACCOMODATION_TYPE_FULFILLED =
  'PUT_MASTER_REFERENCE_ACCOMODATION_TYPE_FULFILLED';
const PUT_MASTER_REFERENCE_ACCOMODATION_TYPE_PENDING =
  'PUT_MASTER_REFERENCE_ACCOMODATION_TYPE_PENDING';
const PUT_MASTER_REFERENCE_ACCOMODATION_TYPE_REJECTED =
  'PUT_MASTER_REFERENCE_ACCOMODATION_TYPE_REJECTED';

//POST DEMO_MANUAL_PAYMENT
const POST_DEMO_MANUAL_PAYMENT = 'POST_DEMO_MANUAL_PAYMENT';
const POST_DEMO_MANUAL_PAYMENT_FULFILLED = 'POST_DEMO_MANUAL_PAYMENT_FULFILLED';
const POST_DEMO_MANUAL_PAYMENT_PENDING = 'POST_DEMO_MANUAL_PAYMENT_PENDING';
const POST_DEMO_MANUAL_PAYMENT_REJECTED = 'POST_DEMO_MANUAL_PAYMENT_REJECTED';

//POST MANUAL_PAYMENT
const POST_MANUAL_PAYMENT = 'POST_MANUAL_PAYMENT';
const POST_MANUAL_PAYMENT_FULFILLED = 'POST_MANUAL_PAYMENT_FULFILLED';
const POST_MANUAL_PAYMENT_PENDING = 'POST_MANUAL_PAYMENT_PENDING';
const POST_MANUAL_PAYMENT_REJECTED = 'POST_MANUAL_PAYMENT_REJECTED';

//POST MANUAL_PAYMENT
const GET_PAYABLE_SUPPLEMENT_ITEM = 'GET_PAYABLE_SUPPLEMENT_ITEM';
const GET_PAYABLE_SUPPLEMENT_ITEM_FULFILLED =
  'GET_PAYABLE_SUPPLEMENT_ITEM_FULFILLED';
const GET_PAYABLE_SUPPLEMENT_ITEM_PENDING =
  'GET_PAYABLE_SUPPLEMENT_ITEM_PENDING';
const GET_PAYABLE_SUPPLEMENT_ITEM_REJECTED =
  'GET_PAYABLE_SUPPLEMENT_ITEM_REJECTED';

//----------- MASTER_REFERENCE
const GET_MASTER_REFERENCE_ACCOMODATION_LOCATION =
  'GET_MASTER_REFERENCE_ACCOMODATION_LOCATION';
const GET_MASTER_REFERENCE_ACCOMODATION_LOCATION_FULFILLED =
  'GET_MASTER_REFERENCE_ACCOMODATION_LOCATION_FULFILLED';
const GET_MASTER_REFERENCE_ACCOMODATION_LOCATION_PENDING =
  'GET_MASTER_REFERENCE_ACCOMODATION_LOCATION_PENDING';
const GET_MASTER_REFERENCE_ACCOMODATION_LOCATION_REJECTED =
  'GET_MASTER_REFERENCE_ACCOMODATION_LOCATION_REJECTED';

const DELETE_MASTER_REFERENCE_ACCOMODATION_LOCATION =
  'DELETE_MASTER_REFERENCE_ACCOMODATION_LOCATION';
const DELETE_MASTER_REFERENCE_ACCOMODATION_LOCATION_FULFILLED =
  'DELETE_MASTER_REFERENCE_ACCOMODATION_LOCATION_FULFILLED';
const DELETE_MASTER_REFERENCE_ACCOMODATION_LOCATION_PENDING =
  'DELETE_MASTER_REFERENCE_ACCOMODATION_LOCATION_PENDING';
const DELETE_MASTER_REFERENCE_ACCOMODATION_LOCATION_REJECTED =
  'DELETE_MASTER_REFERENCE_ACCOMODATION_LOCATION_REJECTED';

const POST_MASTER_REFERENCE_ACCOMODATION_LOCATION =
  'POST_MASTER_REFERENCE_ACCOMODATION_LOCATION';
const POST_MASTER_REFERENCE_ACCOMODATION_LOCATION_FULFILLED =
  'POST_MASTER_REFERENCE_ACCOMODATION_LOCATION_FULFILLED';
const POST_MASTER_REFERENCE_ACCOMODATION_LOCATION_PENDING =
  'POST_MASTER_REFERENCE_ACCOMODATION_LOCATION_PENDING';
const POST_MASTER_REFERENCE_ACCOMODATION_LOCATION_REJECTED =
  'POST_MASTER_REFERENCE_ACCOMODATION_LOCATION_REJECTED';

const PUT_MASTER_REFERENCE_ACCOMODATION_LOCATION =
  'PUT_MASTER_REFERENCE_ACCOMODATION_LOCATION';
const PUT_MASTER_REFERENCE_ACCOMODATION_LOCATION_FULFILLED =
  'PUT_MASTER_REFERENCE_ACCOMODATION_LOCATION_FULFILLED';
const PUT_MASTER_REFERENCE_ACCOMODATION_LOCATION_PENDING =
  'PUT_MASTER_REFERENCE_ACCOMODATION_LOCATION_PENDING';
const PUT_MASTER_REFERENCE_ACCOMODATION_LOCATION_REJECTED =
  'PUT_MASTER_REFERENCE_ACCOMODATION_LOCATION_REJECTED';

const GET_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE =
  'GET_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE';
const GET_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_FULFILLED =
  'GET_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_FULFILLED';
const GET_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_PENDING =
  'GET_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_PENDING';
const GET_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_REJECTED =
  'GET_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_REJECTED';

const DELETE_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE =
  'DELETE_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE';
const DELETE_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_FULFILLED =
  'DELETE_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_FULFILLED';
const DELETE_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_PENDING =
  'DELETE_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_PENDING';
const DELETE_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_REJECTED =
  'DELETE_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_REJECTED';

const POST_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE =
  'POST_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE';
const POST_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_FULFILLED =
  'POST_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_FULFILLED';
const POST_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_PENDING =
  'POST_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_PENDING';
const POST_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_REJECTED =
  'POST_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_REJECTED';

const PUT_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE =
  'PUT_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE';
const PUT_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_FULFILLED =
  'PUT_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_FULFILLED';
const PUT_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_PENDING =
  'PUT_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_PENDING';
const PUT_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_REJECTED =
  'PUT_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_REJECTED';

const GET_MASTER_REFERENCE_ACCOMODATION_RATING =
  'GET_MASTER_REFERENCE_ACCOMODATION_RATING';
const GET_MASTER_REFERENCE_ACCOMODATION_RATING_FULFILLED =
  'GET_MASTER_REFERENCE_ACCOMODATION_RATING_FULFILLED';
const GET_MASTER_REFERENCE_ACCOMODATION_RATING_PENDING =
  'GET_MASTER_REFERENCE_ACCOMODATION_RATING_PENDING';
const GET_MASTER_REFERENCE_ACCOMODATION_RATING_REJECTED =
  'GET_MASTER_REFERENCE_ACCOMODATION_RATING_REJECTED';

const DELETE_MASTER_REFERENCE_ACCOMODATION_RATING =
  'DELETE_MASTER_REFERENCE_ACCOMODATION_RATING';
const DELETE_MASTER_REFERENCE_ACCOMODATION_RATING_FULFILLED =
  'DELETE_MASTER_REFERENCE_ACCOMODATION_RATING_FULFILLED';
const DELETE_MASTER_REFERENCE_ACCOMODATION_RATING_PENDING =
  'DELETE_MASTER_REFERENCE_ACCOMODATION_RATING_PENDING';
const DELETE_MASTER_REFERENCE_ACCOMODATION_RATING_REJECTED =
  'DELETE_MASTER_REFERENCE_ACCOMODATION_RATING_REJECTED';

const POST_MASTER_REFERENCE_ACCOMODATION_RATING =
  'POST_MASTER_REFERENCE_ACCOMODATION_RATING';
const POST_MASTER_REFERENCE_ACCOMODATION_RATING_FULFILLED =
  'POST_MASTER_REFERENCE_ACCOMODATION_RATING_FULFILLED';
const POST_MASTER_REFERENCE_ACCOMODATION_RATING_PENDING =
  'POST_MASTER_REFERENCE_ACCOMODATION_RATING_PENDING';
const POST_MASTER_REFERENCE_ACCOMODATION_RATING_REJECTED =
  'POST_MASTER_REFERENCE_ACCOMODATION_RATING_REJECTED';

const PUT_MASTER_REFERENCE_ACCOMODATION_RATING =
  'PUT_MASTER_REFERENCE_ACCOMODATION_RATING';
const PUT_MASTER_REFERENCE_ACCOMODATION_RATING_FULFILLED =
  'PUT_MASTER_REFERENCE_ACCOMODATION_RATING_FULFILLED';
const PUT_MASTER_REFERENCE_ACCOMODATION_RATING_PENDING =
  'PUT_MASTER_REFERENCE_ACCOMODATION_RATING_PENDING';
const PUT_MASTER_REFERENCE_ACCOMODATION_RATING_REJECTED =
  'PUT_MASTER_REFERENCE_ACCOMODATION_RATING_REJECTED';

const GET_MASTER_REFERENCE_EXCURSION_CATEGORIES =
  'GET_MASTER_REFERENCE_EXCURSION_CATEGORIES';
const GET_MASTER_REFERENCE_EXCURSION_CATEGORIES_FULFILLED =
  'GET_MASTER_REFERENCE_EXCURSION_CATEGORIES_FULFILLED';
const GET_MASTER_REFERENCE_EXCURSION_CATEGORIES_PENDING =
  'GET_MASTER_REFERENCE_EXCURSION_CATEGORIES_PENDING';
const GET_MASTER_REFERENCE_EXCURSION_CATEGORIES_REJECTED =
  'GET_MASTER_REFERENCE_EXCURSION_CATEGORIES_REJECTED';

const DELETE_MASTER_REFERENCE_EXCURSION_CATEGORIES =
  'DELETE_MASTER_REFERENCE_EXCURSION_CATEGORIES';
const DELETE_MASTER_REFERENCE_EXCURSION_CATEGORIES_FULFILLED =
  'DELETE_MASTER_REFERENCE_EXCURSION_CATEGORIES_FULFILLED';
const DELETE_MASTER_REFERENCE_EXCURSION_CATEGORIES_PENDING =
  'DELETE_MASTER_REFERENCE_EXCURSION_CATEGORIES_PENDING';
const DELETE_MASTER_REFERENCE_EXCURSION_CATEGORIES_REJECTED =
  'DELETE_MASTER_REFERENCE_EXCURSION_CATEGORIES_REJECTED';

const POST_MASTER_REFERENCE_EXCURSION_CATEGORIES =
  'POST_MASTER_REFERENCE_EXCURSION_CATEGORIES';
const POST_MASTER_REFERENCE_EXCURSION_CATEGORIES_FULFILLED =
  'POST_MASTER_REFERENCE_EXCURSION_CATEGORIES_FULFILLED';
const POST_MASTER_REFERENCE_EXCURSION_CATEGORIES_PENDING =
  'POST_MASTER_REFERENCE_EXCURSION_CATEGORIES_PENDING';
const POST_MASTER_REFERENCE_EXCURSION_CATEGORIES_REJECTED =
  'POST_MASTER_REFERENCE_EXCURSION_CATEGORIES_REJECTED';

const PUT_MASTER_REFERENCE_EXCURSION_CATEGORIES =
  'PUT_MASTER_REFERENCE_EXCURSION_CATEGORIES';
const PUT_MASTER_REFERENCE_EXCURSION_CATEGORIES_FULFILLED =
  'PUT_MASTER_REFERENCE_EXCURSION_CATEGORIES_FULFILLED';
const PUT_MASTER_REFERENCE_EXCURSION_CATEGORIES_PENDING =
  'PUT_MASTER_REFERENCE_EXCURSION_CATEGORIES_PENDING';
const PUT_MASTER_REFERENCE_EXCURSION_CATEGORIES_REJECTED =
  'PUT_MASTER_REFERENCE_EXCURSION_CATEGORIES_REJECTED';

const GET_MASTER_REFERENCE_EXCURSION_TYPE =
  'GET_MASTER_REFERENCE_EXCURSION_TYPE';
const GET_MASTER_REFERENCE_EXCURSION_TYPE_FULFILLED =
  'GET_MASTER_REFERENCE_EXCURSION_TYPE_FULFILLED';
const GET_MASTER_REFERENCE_EXCURSION_TYPE_PENDING =
  'GET_MASTER_REFERENCE_EXCURSION_TYPE_PENDING';
const GET_MASTER_REFERENCE_EXCURSION_TYPE_REJECTED =
  'GET_MASTER_REFERENCE_EXCURSION_TYPE_REJECTED';

const DELETE_MASTER_REFERENCE_EXCURSION_TYPE =
  'DELETE_MASTER_REFERENCE_EXCURSION_TYPE';
const DELETE_MASTER_REFERENCE_EXCURSION_TYPE_FULFILLED =
  'DELETE_MASTER_REFERENCE_EXCURSION_TYPE_FULFILLED';
const DELETE_MASTER_REFERENCE_EXCURSION_TYPE_PENDING =
  'DELETE_MASTER_REFERENCE_EXCURSION_TYPE_PENDING';
const DELETE_MASTER_REFERENCE_EXCURSION_TYPE_REJECTED =
  'DELETE_MASTER_REFERENCE_EXCURSION_TYPE_REJECTED';

const POST_MASTER_REFERENCE_EXCURSION_TYPE =
  'POST_MASTER_REFERENCE_EXCURSION_TYPE';
const POST_MASTER_REFERENCE_EXCURSION_TYPE_FULFILLED =
  'POST_MASTER_REFERENCE_EXCURSION_TYPE_FULFILLED';
const POST_MASTER_REFERENCE_EXCURSION_TYPE_PENDING =
  'POST_MASTER_REFERENCE_EXCURSION_TYPE_PENDING';
const POST_MASTER_REFERENCE_EXCURSION_TYPE_REJECTED =
  'POST_MASTER_REFERENCE_EXCURSION_TYPE_REJECTED';

const PUT_MASTER_REFERENCE_EXCURSION_TYPE =
  'PUT_MASTER_REFERENCE_EXCURSION_TYPE';
const PUT_MASTER_REFERENCE_EXCURSION_TYPE_FULFILLED =
  'PUT_MASTER_REFERENCE_EXCURSION_TYPE_FULFILLED';
const PUT_MASTER_REFERENCE_EXCURSION_TYPE_PENDING =
  'PUT_MASTER_REFERENCE_EXCURSION_TYPE_PENDING';
const PUT_MASTER_REFERENCE_EXCURSION_TYPE_REJECTED =
  'PUT_MASTER_REFERENCE_EXCURSION_TYPE_REJECTED';

//----
const GET_MASTER_REFERENCE_CURRENCIES = 'GET_MASTER_REFERENCE_CURRENCIES';
const GET_MASTER_REFERENCE_CURRENCIES_FULFILLED =
  'GET_MASTER_REFERENCE_CURRENCIES_FULFILLED';
const GET_MASTER_REFERENCE_CURRENCIES_PENDING =
  'GET_MASTER_REFERENCE_CURRENCIES_PENDING';
const GET_MASTER_REFERENCE_CURRENCIES_REJECTED =
  'GET_MASTER_REFERENCE_CURRENCIES_REJECTED';

const DELETE_MASTER_REFERENCE_CURRENCIES = 'DELETE_MASTER_REFERENCE_CURRENCIES';
const DELETE_MASTER_REFERENCE_CURRENCIES_FULFILLED =
  'DELETE_MASTER_REFERENCE_CURRENCIES_FULFILLED';
const DELETE_MASTER_REFERENCE_CURRENCIES_PENDING =
  'DELETE_MASTER_REFERENCE_CURRENCIES_PENDING';
const DELETE_MASTER_REFERENCE_CURRENCIES_REJECTED =
  'DELETE_MASTER_REFERENCE_CURRENCIES_REJECTED';

const POST_MASTER_REFERENCE_CURRENCIES = 'POST_MASTER_REFERENCE_CURRENCIES';
const POST_MASTER_REFERENCE_CURRENCIES_FULFILLED =
  'POST_MASTER_REFERENCE_CURRENCIES_FULFILLED';
const POST_MASTER_REFERENCE_CURRENCIES_PENDING =
  'POST_MASTER_REFERENCE_CURRENCIES_PENDING';
const POST_MASTER_REFERENCE_CURRENCIES_REJECTED =
  'POST_MASTER_REFERENCE_CURRENCIES_REJECTED';

const PUT_MASTER_REFERENCE_CURRENCIES = 'PUT_MASTER_REFERENCE_CURRENCIES';
const PUT_MASTER_REFERENCE_CURRENCIES_FULFILLED =
  'PUT_MASTER_REFERENCE_CURRENCIES_FULFILLED';
const PUT_MASTER_REFERENCE_CURRENCIES_PENDING =
  'PUT_MASTER_REFERENCE_CURRENCIES_PENDING';
const PUT_MASTER_REFERENCE_CURRENCIES_REJECTED =
  'PUT_MASTER_REFERENCE_CURRENCIES_REJECTED';

//----------------------
const GET_MASTER_REFERENCE_GUEST_CATEGORIES =
  'GET_MASTER_REFERENCE_GUEST_CATEGORIES';
const GET_MASTER_REFERENCE_GUEST_CATEGORIES_FULFILLED =
  'GET_MASTER_REFERENCE_GUEST_CATEGORIES_FULFILLED';
const GET_MASTER_REFERENCE_GUEST_CATEGORIES_PENDING =
  'GET_MASTER_REFERENCE_GUEST_CATEGORIES_PENDING';
const GET_MASTER_REFERENCE_GUEST_CATEGORIES_REJECTED =
  'GET_MASTER_REFERENCE_GUEST_CATEGORIES_REJECTED';

const DELETE_MASTER_REFERENCE_GUEST_CATEGORIES =
  'DELETE_MASTER_REFERENCE_GUEST_CATEGORIES';
const DELETE_MASTER_REFERENCE_GUEST_CATEGORIES_FULFILLED =
  'DELETE_MASTER_REFERENCE_GUEST_CATEGORIES_FULFILLED';
const DELETE_MASTER_REFERENCE_GUEST_CATEGORIES_PENDING =
  'DELETE_MASTER_REFERENCE_GUEST_CATEGORIES_PENDING';
const DELETE_MASTER_REFERENCE_GUEST_CATEGORIES_REJECTED =
  'DELETE_MASTER_REFERENCE_GUEST_CATEGORIES_REJECTED';

const POST_MASTER_REFERENCE_GUEST_CATEGORIES =
  'POST_MASTER_REFERENCE_GUEST_CATEGORIES';
const POST_MASTER_REFERENCE_GUEST_CATEGORIES_FULFILLED =
  'POST_MASTER_REFERENCE_GUEST_CATEGORIES_FULFILLED';
const POST_MASTER_REFERENCE_GUEST_CATEGORIES_PENDING =
  'POST_MASTER_REFERENCE_GUEST_CATEGORIES_PENDING';
const POST_MASTER_REFERENCE_GUEST_CATEGORIES_REJECTED =
  'POST_MASTER_REFERENCE_GUEST_CATEGORIES_REJECTED';

const PUT_MASTER_REFERENCE_GUEST_CATEGORIES =
  'PUT_MASTER_REFERENCE_GUEST_CATEGORIES';
const PUT_MASTER_REFERENCE_GUEST_CATEGORIES_FULFILLED =
  'PUT_MASTER_REFERENCE_GUEST_CATEGORIES_FULFILLED';
const PUT_MASTER_REFERENCE_GUEST_CATEGORIES_PENDING =
  'PUT_MASTER_REFERENCE_GUEST_CATEGORIES_PENDING';
const PUT_MASTER_REFERENCE_GUEST_CATEGORIES_REJECTED =
  'PUT_MASTER_REFERENCE_GUEST_CATEGORIES_REJECTED';

//---------
const GET_MASTER_REFERENCE_GUEST_IDENTITY_TYPE =
  'GET_MASTER_REFERENCE_GUEST_IDENTITY_TYPE';
const GET_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_FULFILLED =
  'GET_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_FULFILLED';
const GET_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_PENDING =
  'GET_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_PENDING';
const GET_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_REJECTED =
  'GET_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_REJECTED';

const DELETE_MASTER_REFERENCE_GUEST_IDENTITY_TYPE =
  'DELETE_MASTER_REFERENCE_GUEST_IDENTITY_TYPE';
const DELETE_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_FULFILLED =
  'DELETE_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_FULFILLED';
const DELETE_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_PENDING =
  'DELETE_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_PENDING';
const DELETE_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_REJECTED =
  'DELETE_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_REJECTED';

const POST_MASTER_REFERENCE_GUEST_IDENTITY_TYPE =
  'POST_MASTER_REFERENCE_GUEST_IDENTITY_TYPE';
const POST_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_FULFILLED =
  'POST_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_FULFILLED';
const POST_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_PENDING =
  'POST_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_PENDING';
const POST_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_REJECTED =
  'POST_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_REJECTED';

const PUT_MASTER_REFERENCE_GUEST_IDENTITY_TYPE =
  'PUT_MASTER_REFERENCE_GUEST_IDENTITY_TYPE';
const PUT_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_FULFILLED =
  'PUT_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_FULFILLED';
const PUT_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_PENDING =
  'PUT_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_PENDING';
const PUT_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_REJECTED =
  'PUT_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_REJECTED';

const GET_MASTER_REFERENCE_GUEST_TITLE = 'GET_MASTER_REFERENCE_GUEST_TITLE';
const GET_MASTER_REFERENCE_GUEST_TITLE_FULFILLED =
  'GET_MASTER_REFERENCE_GUEST_TITLE_FULFILLED';
const GET_MASTER_REFERENCE_GUEST_TITLE_PENDING =
  'GET_MASTER_REFERENCE_GUEST_TITLE_PENDING';
const GET_MASTER_REFERENCE_GUEST_TITLE_REJECTED =
  'GET_MASTER_REFERENCE_GUEST_TITLE_REJECTED';

const DELETE_MASTER_REFERENCE_GUEST_TITLE =
  'DELETE_MASTER_REFERENCE_GUEST_TITLE';
const DELETE_MASTER_REFERENCE_GUEST_TITLE_FULFILLED =
  'DELETE_MASTER_REFERENCE_GUEST_TITLE_FULFILLED';
const DELETE_MASTER_REFERENCE_GUEST_TITLE_PENDING =
  'DELETE_MASTER_REFERENCE_GUEST_TITLE_PENDING';
const DELETE_MASTER_REFERENCE_GUEST_TITLE_REJECTED =
  'DELETE_MASTER_REFERENCE_GUEST_TITLE_REJECTED';

const POST_MASTER_REFERENCE_GUEST_TITLE = 'POST_MASTER_REFERENCE_GUEST_TITLE';
const POST_MASTER_REFERENCE_GUEST_TITLE_FULFILLED =
  'POST_MASTER_REFERENCE_GUEST_TITLE_FULFILLED';
const POST_MASTER_REFERENCE_GUEST_TITLE_PENDING =
  'POST_MASTER_REFERENCE_GUEST_TITLE_PENDING';
const POST_MASTER_REFERENCE_GUEST_TITLE_REJECTED =
  'POST_MASTER_REFERENCE_GUEST_TITLE_REJECTED';

const PUT_MASTER_REFERENCE_GUEST_TITLE = 'PUT_MASTER_REFERENCE_GUEST_TITLE';
const PUT_MASTER_REFERENCE_GUEST_TITLE_FULFILLED =
  'PUT_MASTER_REFERENCE_GUEST_TITLE_FULFILLED';
const PUT_MASTER_REFERENCE_GUEST_TITLE_PENDING =
  'PUT_MASTER_REFERENCE_GUEST_TITLE_PENDING';
const PUT_MASTER_REFERENCE_GUEST_TITLE_REJECTED =
  'PUT_MASTER_REFERENCE_GUEST_TITLE_REJECTED';

const GET_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION =
  'GET_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION';
const GET_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_FULFILLED =
  'GET_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_FULFILLED';
const GET_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_PENDING =
  'GET_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_PENDING';
const GET_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_REJECTED =
  'GET_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_REJECTED';

const DELETE_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION =
  'DELETE_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION';
const DELETE_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_FULFILLED =
  'DELETE_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_FULFILLED';
const DELETE_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_PENDING =
  'DELETE_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_PENDING';
const DELETE_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_REJECTED =
  'DELETE_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_REJECTED';

const POST_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION =
  'POST_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION';
const POST_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_FULFILLED =
  'POST_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_FULFILLED';
const POST_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_PENDING =
  'POST_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_PENDING';
const POST_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_REJECTED =
  'POST_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_REJECTED';

const PUT_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION =
  'PUT_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION';
const PUT_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_FULFILLED =
  'PUT_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_FULFILLED';
const PUT_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_PENDING =
  'PUT_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_PENDING';
const PUT_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_REJECTED =
  'PUT_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_REJECTED';

const GET_MASTER_REFERENCE = 'GET_MASTER_REFERENCE';
const GET_MASTER_REFERENCE_FULFILLED = 'GET_MASTER_REFERENCE_FULFILLED';
const GET_MASTER_REFERENCE_PENDING = 'GET_MASTER_REFERENCE_PENDING';
const GET_MASTER_REFERENCE_REJECTED = 'GET_MASTER_REFERENCE_REJECTED';

const DELETE_MASTER_REFERENCE = 'DELETE_MASTER_REFERENCE';
const DELETE_MASTER_REFERENCE_FULFILLED = 'DELETE_MASTER_REFERENCE_FULFILLED';
const DELETE_MASTER_REFERENCE_PENDING = 'DELETE_MASTER_REFERENCE_PENDING';
const DELETE_MASTER_REFERENCE_REJECTED = 'DELETE_MASTER_REFERENCE_REJECTED';

const POST_MASTER_REFERENCE = 'POST_MASTER_REFERENCE';
const POST_MASTER_REFERENCE_FULFILLED = 'POST_MASTER_REFERENCE_FULFILLED';
const POST_MASTER_REFERENCE_PENDING = 'POST_MASTER_REFERENCE_PENDING';
const POST_MASTER_REFERENCE_REJECTED = 'POST_MASTER_REFERENCE_REJECTED';

const PUT_MASTER_REFERENCE = 'PUT_MASTER_REFERENCE';
const PUT_MASTER_REFERENCE_FULFILLED = 'PUT_MASTER_REFERENCE_FULFILLED';
const PUT_MASTER_REFERENCE_PENDING = 'PUT_MASTER_REFERENCE_PENDING';
const PUT_MASTER_REFERENCE_REJECTED = 'PUT_MASTER_REFERENCE_REJECTED';

//============================================================
const POST_UNLOCK_USER = 'POST_UNLOCK_USER';
const POST_UNLOCK_USER_FULFILLED = 'POST_UNLOCK_USER_FULFILLED';
const POST_UNLOCK_USER_PENDING = 'POST_UNLOCK_USER_PENDING';
const POST_UNLOCK_USER_REJECTED = 'POST_UNLOCK_USER_REJECTED';

const GET_TRANSACTION_PAYMENT_TERM_BY_TRANSACTION_ID =
  'GET_TRANSACTION_PAYMENT_TERM_BY_TRANSACTION_ID';
const GET_TRANSACTION_PAYMENT_TERM_BY_TRANSACTION_ID_FULFILLED =
  'GET_TRANSACTION_PAYMENT_TERM_BY_TRANSACTION_ID_FULFILLED';
const GET_TRANSACTION_PAYMENT_TERM_BY_TRANSACTION_ID_PENDING =
  'GET_TRANSACTION_PAYMENT_TERM_BY_TRANSACTION_ID_PENDING';
const GET_TRANSACTION_PAYMENT_TERM_BY_TRANSACTION_ID_REJECTED =
  'GET_TRANSACTION_PAYMENT_TERM_BY_TRANSACTION_ID_REJECTED';

const GET_USER_PROFILE_BY_USER_ID = 'GET_USER_PROFILE_BY_USER_ID';
const GET_USER_PROFILE_BY_USER_ID_FULFILLED =
  'GET_USER_PROFILE_BY_USER_ID_FULFILLED';
const GET_USER_PROFILE_BY_USER_ID_PENDING =
  'GET_USER_PROFILE_BY_USER_ID_PENDING';
const GET_USER_PROFILE_BY_USER_ID_REJECTED =
  'GET_USER_PROFILE_BY_USER_ID_REJECTED';

const POST_RESET_PASSWORD = 'POST_RESET_PASSWORD';
const POST_RESET_PASSWORD_FULFILLED = 'POST_RESET_PASSWORD_FULFILLED';
const POST_RESET_PASSWORD_PENDING = 'POST_RESET_PASSWORD_PENDING';
const POST_RESET_PASSWORD_REJECTED = 'POST_RESET_PASSWORD_REJECTED';

// GET PAYMENT_METHOD_CHOICE_LIST
const GET_PAYMENT_METHOD_CHOICE_LIST = 'GET_PAYMENT_METHOD_CHOICE_LIST';
const GET_PAYMENT_METHOD_CHOICE_LIST_FULFILLED =
  'GET_PAYMENT_METHOD_CHOICE_LIST_FULFILLED';
const GET_PAYMENT_METHOD_CHOICE_LIST_PENDING =
  'GET_PAYMENT_METHOD_CHOICE_LIST_PENDING';
const GET_PAYMENT_METHOD_CHOICE_LIST_REJECTED =
  'GET_PAYMENT_METHOD_CHOICE_LIST_REJECTED';

//GET EXPORT_RESTAURANT_PROFILE
const GET_EXPORT_RESTAURANT_PROFILE = 'GET_EXPORT_RESTAURANT_PROFILE';
const GET_EXPORT_RESTAURANT_PROFILE_FULFILLED =
  'GET_EXPORT_RESTAURANT_PROFILE_FULFILLED';
const GET_EXPORT_RESTAURANT_PROFILE_PENDING =
  'GET_EXPORT_RESTAURANT_PROFILE_PENDING';
const GET_EXPORT_RESTAURANT_PROFILE_REJECTED =
  'GET_EXPORT_RESTAURANT_PROFILE_REJECTED';

//POST _IMAGE_ACCOMODATION_PROFILE
const POST_UPLOAD_IMAGE_ACCOMODATION_PROFILE =
  'POST_UPLOAD_IMAGE_ACCOMODATION_PROFILE';
const POST_UPLOAD_IMAGE_ACCOMODATION_PROFILE_FULFILLED =
  'POST_UPLOAD_IMAGE_ACCOMODATION_PROFILE_FULFILLED';
const POST_UPLOAD_IMAGE_ACCOMODATION_PROFILE_PENDING =
  'POST_UPLOAD_IMAGE_ACCOMODATION_PROFILE_PENDING';
const POST_UPLOAD_IMAGE_ACCOMODATION_PROFILE_REJECTED =
  'POST_UPLOAD_IMAGE_ACCOMODATION_PROFILE_REJECTED';

//POST _IMAGE_EXCURSION_OBJECT
const POST_UPLOAD_IMAGE_EXCURSION_OBJECT = 'POST_UPLOAD_IMAGE_EXCURSION_OBJECT';
const POST_UPLOAD_IMAGE_EXCURSION_OBJECT_FULFILLED =
  'POST_UPLOAD_IMAGE_EXCURSION_OBJECT_FULFILLED';
const POST_UPLOAD_IMAGE_EXCURSION_OBJECT_PENDING =
  'POST_UPLOAD_IMAGE_EXCURSION_OBJECT_PENDING';
const POST_UPLOAD_IMAGE_EXCURSION_OBJECT_REJECTED =
  'POST_UPLOAD_IMAGE_EXCURSION_OBJECT_REJECTED';

//POST _IMAGE_ACCOMODATION_ITEM
const POST_UPLOAD_IMAGE_ACCOMODATION_ITEM =
  'POST_UPLOAD_IMAGE_ACCOMODATION_ITEM';
const POST_UPLOAD_IMAGE_ACCOMODATION_ITEM_FULFILLED =
  'POST_UPLOAD_IMAGE_ACCOMODATION_ITEM_FULFILLED';
const POST_UPLOAD_IMAGE_ACCOMODATION_ITEM_PENDING =
  'POST_UPLOAD_IMAGE_ACCOMODATION_ITEM_PENDING';
const POST_UPLOAD_IMAGE_ACCOMODATION_ITEM_REJECTED =
  'POST_UPLOAD_IMAGE_ACCOMODATION_ITEM_REJECTED';

//POST _IMAGE_RESTAURANT_ITEM
const POST_UPLOAD_IMAGE_RESTAURANT_ITEM = 'POST_UPLOAD_IMAGE_RESTAURANT_ITEM';
const POST_UPLOAD_IMAGE_RESTAURANT_ITEM_FULFILLED =
  'POST_UPLOAD_IMAGE_RESTAURANT_ITEM_FULFILLED';
const POST_UPLOAD_IMAGE_RESTAURANT_ITEM_PENDING =
  'POST_UPLOAD_IMAGE_RESTAURANT_ITEM_PENDING';
const POST_UPLOAD_IMAGE_RESTAURANT_ITEM_REJECTED =
  'POST_UPLOAD_IMAGE_RESTAURANT_ITEM_REJECTED';

//Accomodation Item
const GET_ACCOMODATION_ITEM_ALL = 'GET_ACCOMODATION_ITEM_ALL';
const GET_ACCOMODATION_ITEM_ALL_FULFILLED =
  'GET_ACCOMODATION_ITEM_ALL_FULFILLED';
const GET_ACCOMODATION_ITEM_ALL_PENDING = 'GET_ACCOMODATION_ITEM_ALL_PENDING';
const GET_ACCOMODATION_ITEM_ALL_REJECTED = 'GET_ACCOMODATION_ITEM_ALL_REJECTED';

//GET RESTAURANT_ITEM
const GET_RESTAURANT_ITEM_ALL = 'GET_RESTAURANT_ITEM_ALL';
const GET_RESTAURANT_ITEM_ALL_FULFILLED = 'GET_RESTAURANT_ITEM_ALL_FULFILLED';
const GET_RESTAURANT_ITEM_ALL_PENDING = 'GET_RESTAURANT_ITEM_ALL_PENDING';
const GET_RESTAURANT_ITEM_ALL_REJECTED = 'GET_RESTAURANT_ITEM_ALL_REJECTED';

//GET RESTAURANT_ITEM
const POST_UPLOAD_IMAGE_RESTAURANT_PROFILE =
  'POST_UPLOAD_IMAGE_RESTAURANT_PROFILE';
const POST_UPLOAD_IMAGE_RESTAURANT_PROFILE_FULFILLED =
  'POST_UPLOAD_IMAGE_RESTAURANT_PROFILE_FULFILLED';
const POST_UPLOAD_IMAGE_RESTAURANT_PROFILE_PENDING =
  'POST_UPLOAD_IMAGE_RESTAURANT_PROFILE_PENDING';
const POST_UPLOAD_IMAGE_RESTAURANT_PROFILE_REJECTED =
  'POST_UPLOAD_IMAGE_RESTAURANT_PROFILE_REJECTED';

//POST_USER
const POST_USER = 'POST_USER';
const POST_USER_FULFILLED = 'POST_USER_FULFILLED';
const POST_USER_PENDING = 'POST_USER_PENDING';
const POST_USER_REJECTED = 'POST_USER_REJECTED';

//POST POST_USER_PROFILE
const POST_USER_PROFILE = 'POST_USER_PROFILE';
const POST_USER_PROFILE_FULFILLED = 'POST_USER_PROFILE_FULFILLED';
const POST_USER_PROFILE_PENDING = 'POST_USER_PROFILE_PENDING';
const POST_USER_PROFILE_REJECTED = 'POST_USER_PROFILE_REJECTED';

//POST _IMAGE_RESTAURANT_PROFILE
const POST_TOUR_TRANSACTION_CONFIRMATION = 'POST_TOUR_TRANSACTION_CONFIRMATION';
const POST_TOUR_TRANSACTION_CONFIRMATION_FULFILLED =
  'POST_TOUR_TRANSACTION_CONFIRMATION_FULFILLED';
const POST_TOUR_TRANSACTION_CONFIRMATION_PENDING =
  'POST_TOUR_TRANSACTION_CONFIRMATION_PENDING';
const POST_TOUR_TRANSACTION_CONFIRMATION_REJECTED =
  'POST_TOUR_TRANSACTION_CONFIRMATION_REJECTED';

//POST TOP_UP_PAYMENT_GATEWAY
const POST_TOP_UP_PAYMENT_GATEWAY = 'POST_TOP_UP_PAYMENT_GATEWAY';
const POST_TOP_UP_PAYMENT_GATEWAY_FULFILLED =
  'POST_TOP_UP_PAYMENT_GATEWAY_FULFILLED';
const POST_TOP_UP_PAYMENT_GATEWAY_PENDING =
  'POST_TOP_UP_PAYMENT_GATEWAY_PENDING';
const POST_TOP_UP_PAYMENT_GATEWAY_REJECTED =
  'POST_TOP_UP_PAYMENT_GATEWAY_REJECTED';

//GET USER_ROLE_LIST
const GET_USER_ROLE_LIST = 'GET_USER_ROLE_LIST';
const GET_USER_ROLE_LIST_FULFILLED = 'GET_USER_ROLE_LIST_FULFILLED';
const GET_USER_ROLE_LIST_PENDING = 'GET_USER_ROLE_LIST_PENDING';
const GET_USER_ROLE_LIST_REJECTED = 'GET_USER_ROLE_LIST_REJECTED';

//GET USER_NOT_EMPLOYEE
const GET_USER_NOT_EMPLOYEE = 'GET_USER_NOT_EMPLOYEE';
const GET_USER_NOT_EMPLOYEE_FULFILLED = 'GET_USER_NOT_EMPLOYEE_FULFILLED';
const GET_USER_NOT_EMPLOYEE_PENDING = 'GET_USER_NOT_EMPLOYEE_PENDING';
const GET_USER_NOT_EMPLOYEE_REJECTED = 'GET_USER_NOT_EMPLOYEE_REJECTED';

//PUT PUT_USER_PROFILE
const PUT_USER_PROFILE = 'PUT_USER_PROFILE';
const PUT_USER_PROFILE_FULFILLED = 'PUT_USER_PROFILE_FULFILLED';
const PUT_USER_PROFILE_PENDING = 'PUT_USER_PROFILE_PENDING';
const PUT_USER_PROFILE_REJECTED = 'PUT_USER_PROFILE_REJECTED';

//PUT GET_USER_ROLE_BY_USER_ID
const GET_USER_ROLE_BY_USER_ID = 'GET_USER_ROLE_BY_USER_ID';
const GET_USER_ROLE_BY_USER_ID_FULFILLED = 'GET_USER_ROLE_BY_USER_ID_FULFILLED';
const GET_USER_ROLE_BY_USER_ID_PENDING = 'GET_USER_ROLE_BY_USER_ID_PENDING';
const GET_USER_ROLE_BY_USER_ID_REJECTED = 'GET_USER_ROLE_BY_USER_ID_REJECTED';

//PUT POST_ADD_NEW_ROLE_BY_USER_ID
const POST_ADD_NEW_ROLE_BY_USER_ID = 'POST_ADD_NEW_ROLE_BY_USER_ID';
const POST_ADD_NEW_ROLE_BY_USER_ID_FULFILLED =
  'POST_ADD_NEW_ROLE_BY_USER_ID_FULFILLED';
const POST_ADD_NEW_ROLE_BY_USER_ID_PENDING =
  'POST_ADD_NEW_ROLE_BY_USER_ID_PENDING';
const POST_ADD_NEW_ROLE_BY_USER_ID_REJECTED =
  'POST_ADD_NEW_ROLE_BY_USER_ID_REJECTED';

//PUT DELETE_ROLE_BY_USER_ID
const DELETE_ROLE_BY_USER_ID = 'DELETE_ROLE_BY_USER_ID';
const DELETE_ROLE_BY_USER_ID_FULFILLED = 'DELETE_ROLE_BY_USER_ID_FULFILLED';
const DELETE_ROLE_BY_USER_ID_PENDING = 'DELETE_ROLE_BY_USER_ID_PENDING';
const DELETE_ROLE_BY_USER_ID_REJECTED = 'DELETE_ROLE_BY_USER_ID_REJECTED';

//GET_INVOICE_STATUS_USING_PAYMENT_GATEWAY
const GET_INVOICE_STATUS_USING_PAYMENT_GATEWAY =
  'GET_INVOICE_STATUS_USING_PAYMENT_GATEWAY';
const GET_INVOICE_STATUS_USING_PAYMENT_GATEWAY_FULFILLED =
  'GET_INVOICE_STATUS_USING_PAYMENT_GATEWAY_FULFILLED';
const GET_INVOICE_STATUS_USING_PAYMENT_GATEWAY_PENDING =
  'GET_INVOICE_STATUS_USING_PAYMENT_GATEWAY_PENDING';
const GET_INVOICE_STATUS_USING_PAYMENT_GATEWAY_REJECTED =
  'GET_INVOICE_STATUS_USING_PAYMENT_GATEWAY_REJECTED';

//GET_SPECIAL_ADJUSMENT_BY_TRANSACTION_ID
const GET_SPECIAL_ADJUSMENT_BY_TRANSACTION_ID =
  'GET_SPECIAL_ADJUSMENT_BY_TRANSACTION_ID';
const GET_SPECIAL_ADJUSMENT_BY_TRANSACTION_ID_FULFILLED =
  'GET_SPECIAL_ADJUSMENT_BY_TRANSACTION_ID_FULFILLED';
const GET_SPECIAL_ADJUSMENT_BY_TRANSACTION_ID_PENDING =
  'GET_SPECIAL_ADJUSMENT_BY_TRANSACTION_ID_PENDING';
const GET_SPECIAL_ADJUSMENT_BY_TRANSACTION_ID_REJECTED =
  'GET_SPECIAL_ADJUSMENT_BY_TRANSACTION_ID_REJECTED';

//GET_TOUR_TRANSACTION_BILLED_BY_TRANSACTION_ID
const GET_TOUR_TRANSACTION_BILLED_BY_TRANSACTION_ID =
  'GET_TOUR_TRANSACTION_BILLED_BY_TRANSACTION_ID';
const GET_TOUR_TRANSACTION_BILLED_BY_TRANSACTION_ID_FULFILLED =
  'GET_TOUR_TRANSACTION_BILLED_BY_TRANSACTION_ID_FULFILLED';
const GET_TOUR_TRANSACTION_BILLED_BY_TRANSACTION_ID_PENDING =
  'GET_TOUR_TRANSACTION_BILLED_BY_TRANSACTION_ID_PENDING';
const GET_TOUR_TRANSACTION_BILLED_BY_TRANSACTION_ID_REJECTED =
  'GET_TOUR_TRANSACTION_BILLED_BY_TRANSACTION_ID_REJECTED';

//GET_TOUR_TRANSACTION_BILLS_BY_TRANSACTION_ID
const GET_TOUR_TRANSACTION_BILLS_BY_TRANSACTION_ID =
  'GET_TOUR_TRANSACTION_BILLS_BY_TRANSACTION_ID';
const GET_TOUR_TRANSACTION_BILLS_BY_TRANSACTION_ID_FULFILLED =
  'GET_TOUR_TRANSACTION_BILLS_BY_TRANSACTION_ID_FULFILLED';
const GET_TOUR_TRANSACTION_BILLS_BY_TRANSACTION_ID_PENDING =
  'GET_TOUR_TRANSACTION_BILLS_BY_TRANSACTION_ID_PENDING';
const GET_TOUR_TRANSACTION_BILLS_BY_TRANSACTION_ID_REJECTED =
  'GET_TOUR_TRANSACTION_BILLS_BY_TRANSACTION_ID_REJECTED';

const GET_EXCURSION_PROFILE_BY_CITY = 'GET_EXCURSION_PROFILE_BY_CITY';
const GET_EXCURSION_PROFILE_BY_CITY_FULFILLED =
  'GET_EXCURSION_PROFILE_BY_CITY_FULFILLED';
const GET_EXCURSION_PROFILE_BY_CITY_PENDING =
  'GET_EXCURSION_PROFILE_BY_CITY_PENDING';
const GET_EXCURSION_PROFILE_BY_CITY_REJECTED =
  'GET_EXCURSION_PROFILE_BY_CITY_REJECTED';
const GET_ACTIVITY_LIST = 'GET_ACTIVITY_LIST';
const GET_ACTIVITY_LIST_PENDING = 'GET_ACTIVITY_LIST_PENDING';
const GET_ACTIVITY_LIST_FULFILLED = 'GET_ACTIVITY_LIST_FULFILLED';
const GET_ACTIVITY_LIST_REJECTED = 'GET_ACTIVITY_LIST_REJECTED';
const GET_AIRPORT_BY_CITY = 'GET_AIRPORT_BY_CITY';
const GET_AIRPORT_BY_CITY_PENDING = 'GET_AIRPORT_BY_CITY_PENDING';
const GET_AIRPORT_BY_CITY_FULFILLED = 'GET_AIRPORT_BY_CITY_FULFILLED';
const GET_AIRPORT_BY_CITY_REJECTED = 'GET_AIRPORT_BY_CITY_REJECTED';
const GET_TOUR_OPERATOR_PROFILE = 'GET_TOUR_OPERATOR_PROFILE';
const GET_TOUR_OPERATOR_PROFILE_PENDING = 'GET_TOUR_OPERATOR_PROFILE_PENDING';
const GET_TOUR_OPERATOR_PROFILE_FULFILLED =
  'GET_TOUR_OPERATOR_PROFILE_FULFILLED';
const GET_TOUR_OPERATOR_PROFILE_REJECTED = 'GET_TOUR_OPERATOR_PROFILE_REJECTED';
const GET_DURATION_ID_TO_ID = 'GET_DURATION_ID_TO_ID';
const GET_DURATION_ID_TO_ID_PENDING = 'GET_DURATION_ID_TO_ID_PENDING';
const GET_DURATION_ID_TO_ID_FULFILLED = 'GET_DURATION_ID_TO_ID_FULFILLED';
const GET_DURATION_ID_TO_ID_REJECTED = 'GET_DURATION_ID_TO_ID_REJECTED';
const GET_DURATION_ID_TO_ADDRESS = 'GET_DURATION_ID_TO_ADDRESS';
const GET_DURATION_ID_TO_ADDRESS_PENDING = 'GET_DURATION_ID_TO_ADDRESS_PENDING';
const GET_DURATION_ID_TO_ADDRESS_FULFILLED =
  'GET_DURATION_ID_TO_ADDRESS_FULFILLED';
const GET_DURATION_ID_TO_ADDRESS_REJECTED =
  'GET_DURATION_ID_TO_ADDRESS_REJECTED';
const GET_DURATION_ADDRESS_TO_ID = 'GET_DURATION_ADDRESS_TO_ID';
const GET_DURATION_ADDRESS_TO_ID_PENDING = 'GET_DURATION_ADDRESS_TO_ID_PENDING';
const GET_DURATION_ADDRESS_TO_ID_FULFILLED =
  'GET_DURATION_ADDRESS_TO_ID_FULFILLED';
const GET_DURATION_ADDRESS_TO_ID_REJECTED =
  'GET_DURATION_ADDRESS_TO_ID_REJECTED';

const GET_DURATION_ADDRESS_TO_ADDRESS = 'GET_DURATION_ADDRESS_TO_ADDRESS';
const GET_DURATION_ADDRESS_TO_ADDRESS_PENDING =
  'GET_DURATION_ADDRESS_TO_ADDRESS_PENDING';
const GET_DURATION_ADDRESS_TO_ADDRESS_FULFILLED =
  'GET_DURATION_ADDRESS_TO_ADDRESS_FULFILLED';
const GET_DURATION_ADDRESS_TO_ADDRESS_REJECTED =
  'GET_DURATION_ADDRESS_TO_ADDRESS_REJECTED';

const GET_USER_ROLE_BY_BEARER = 'GET_USER_ROLE_BY_BEARER';
const GET_USER_ROLE_BY_BEARER_FULFILLED = 'GET_USER_ROLE_BY_BEARER_FULFILLED';
const GET_USER_ROLE_BY_BEARER_PENDING = 'GET_USER_ROLE_BY_BEARER_PENDING';
const GET_USER_ROLE_BY_BEARER_REJECTED = 'GET_USER_ROLE_BY_BEARER_REJECTED';
const GET_RESTAURANT_BY_CITY_DATE_PAX = 'GET_RESTAURANT_BY_CITY_DATE_PAX';
const GET_RESTAURANT_BY_CITY_DATE_PAX_PENDING =
  'GET_RESTAURANT_BY_CITY_DATE_PAX_PENDING';
const GET_RESTAURANT_BY_CITY_DATE_PAX_FULFILLED =
  'GET_RESTAURANT_BY_CITY_DATE_PAX_FULFILLED';
const GET_RESTAURANT_BY_CITY_DATE_PAX_REJECTED =
  'GET_RESTAURANT_BY_CITY_DATE_PAX_REJECTED';

const GET_MASTER_REFERENCE_MENU_TYPE_LIST =
  'GET_MASTER_REFERENCE_MENU_TYPE_LIST';
const GET_MASTER_REFERENCE_MENU_TYPE_LIST_PENDING =
  'GET_MASTER_REFERENCE_MENU_TYPE_LIST_PENDING';
const GET_MASTER_REFERENCE_MENU_TYPE_LIST_FULFILLED =
  'GET_MASTER_REFERENCE_MENU_TYPE_LIST_FULFILLED';
const GET_MASTER_REFERENCE_MENU_TYPE_LIST_REJECTED =
  'GET_MASTER_REFERENCE_MENU_TYPE_LIST_REJECTED';
const GET_MENU_CLASS = 'GET_MENU_CLASS';
const GET_MENU_CLASS_PENDING = 'GET_MENU_CLASS_PENDING';
const GET_MENU_CLASS_FULFILLED = 'GET_MENU_CLASS_FULFILLED';
const GET_MENU_CLASS_REJECTED = 'GET_MENU_CLASS_REJECTED';
const POST_PUBLISH_PACKAGE = 'POST_PUBLISH_PACKAGE';
const POST_PUBLISH_PACKAGE_PENDING = 'POST_PUBLISH_PACKAGE_PENDING';
const POST_PUBLISH_PACKAGE_FULFILLED = 'POST_PUBLISH_PACKAGE_FULFILLED';
const POST_PUBLISH_PACKAGE_REJECTED = 'POST_PUBLISH_PACKAGE_REJECTED';
//Transaction By Status ON HOLD
const GET_TRANSACTION_ALL_BY_STATUS_ON_HOLD_LIST =
  'GET_TRANSACTION_ALL_BY_STATUS_ON_HOLD_LIST';
const GET_TRANSACTION_ALL_BY_STATUS_ON_HOLD_LIST_FULFILLED =
  'GET_TRANSACTION_ALL_BY_STATUS_ON_HOLD_LIST_FULFILLED';
const GET_TRANSACTION_ALL_BY_STATUS_ON_HOLD_LIST_PENDING =
  'GET_TRANSACTION_ALL_BY_STATUS_ON_HOLD_LIST_PENDING';
const GET_TRANSACTION_ALL_BY_STATUS_ON_HOLD_LIST_REJECTED =
  'GET_TRANSACTION_ALL_BY_STATUS_ON_HOLD_LIST_REJECTED';
//Transaction By Status DP
const GET_TRANSACTION_ALL_BY_STATUS_AFTER_DP_LIST =
  'GET_TRANSACTION_ALL_BY_STATUS_AFTER_DP_LIST';
const GET_TRANSACTION_ALL_BY_STATUS_AFTER_DP_LIST_FULFILLED =
  'GET_TRANSACTION_ALL_BY_STATUS_AFTER_DP_LIST_FULFILLED';
const GET_TRANSACTION_ALL_BY_STATUS_AFTER_DP_LIST_PENDING =
  'GET_TRANSACTION_ALL_BY_STATUS_AFTER_DP_LIST_PENDING';
const GET_TRANSACTION_ALL_BY_STATUS_AFTER_DP_LIST_REJECTED =
  'GET_TRANSACTION_ALL_BY_STATUS_AFTER_DP_LIST_REJECTED';

//Transaction By Status CANCEL
const GET_TRANSACTION_ALL_BY_STATUS_CANCEL_LIST =
  'GET_TRANSACTION_ALL_BY_STATUS_CANCEL_LIST';
const GET_TRANSACTION_ALL_BY_STATUS_CANCEL_LIST_FULFILLED =
  'GET_TRANSACTION_ALL_BY_STATUS_CANCEL_LIST_FULFILLED';
const GET_TRANSACTION_ALL_BY_STATUS_CANCEL_LIST_PENDING =
  'GET_TRANSACTION_ALL_BY_STATUS_CANCEL_LIST_PENDING';
const GET_TRANSACTION_ALL_BY_STATUS_CANCEL_LIST_REJECTED =
  'GET_TRANSACTION_ALL_BY_STATUS_CANCEL_LIST_REJECTED';

//Transaction By Status CREATED
const GET_TRANSACTION_ALL_BY_STATUS_CREATED_LIST =
  'GET_TRANSACTION_ALL_BY_STATUS_CREATED_LIST';
const GET_TRANSACTION_ALL_BY_STATUS_CREATED_LIST_FULFILLED =
  'GET_TRANSACTION_ALL_BY_STATUS_CREATED_LIST_FULFILLED';
const GET_TRANSACTION_ALL_BY_STATUS_CREATED_LIST_PENDING =
  'GET_TRANSACTION_ALL_BY_STATUS_CREATED_LIST_PENDING';
const GET_TRANSACTION_ALL_BY_STATUS_CREATED_LIST_REJECTED =
  'GET_TRANSACTION_ALL_BY_STATUS_CREATED_LIST_REJECTED';

//Transaction By Status COMPLETE
const GET_TRANSACTION_ALL_BY_STATUS_COMPLETE_LIST =
  'GET_TRANSACTION_ALL_BY_STATUS_COMPLETE_LIST';
const GET_TRANSACTION_ALL_BY_STATUS_COMPLETE_LIST_FULFILLED =
  'GET_TRANSACTION_ALL_BY_STATUS_COMPLETE_LIST_FULFILLED';
const GET_TRANSACTION_ALL_BY_STATUS_COMPLETE_LIST_PENDING =
  'GET_TRANSACTION_ALL_BY_STATUS_COMPLETE_LIST_PENDING';
const GET_TRANSACTION_ALL_BY_STATUS_COMPLETE_LIST_REJECTED =
  'GET_TRANSACTION_ALL_BY_STATUS_COMPLETE_LIST_REJECTED';

const POST_SPECIAL_ADJUSMENT = 'POST_SPECIAL_ADJUSMENT';
const POST_SPECIAL_ADJUSMENT_FULFILLED = 'POST_SPECIAL_ADJUSMENT_FULFILLED';
const POST_SPECIAL_ADJUSMENT_PENDING = 'POST_SPECIAL_ADJUSMENT_PENDING';
const POST_SPECIAL_ADJUSMENT_REJECTED = 'POST_SPECIAL_ADJUSMENT_REJECTED';

const EDIT_SPECIAL_ADJUSMENT = 'EDIT_SPECIAL_ADJUSMENT';
const EDIT_SPECIAL_ADJUSMENT_FULFILLED = 'EDIT_SPECIAL_ADJUSMENT_FULFILLED';
const EDIT_SPECIAL_ADJUSMENT_PENDING = 'EDIT_SPECIAL_ADJUSMENT_PENDING';
const EDIT_SPECIAL_ADJUSMENT_REJECTED = 'EDIT_SPECIAL_ADJUSMENT_REJECTED';

const DELETE_SPECIAL_ADJUSMENT = 'DELETE_SPECIAL_ADJUSMENT';
const DELETE_SPECIAL_ADJUSMENT_FULFILLED = 'DELETE_SPECIAL_ADJUSMENT_FULFILLED';
const DELETE_SPECIAL_ADJUSMENT_PENDING = 'DELETE_SPECIAL_ADJUSMENT_PENDING';
const DELETE_SPECIAL_ADJUSMENT_REJECTED = 'DELETE_SPECIAL_ADJUSMENT_REJECTED';

const GET_SCHEDULE_TOUR_TRANSACTION = 'GET_SCHEDULE_TOUR_TRANSACTION';
const GET_SCHEDULE_TOUR_TRANSACTION_FULFILLED =
  'GET_SCHEDULE_TOUR_TRANSACTION_FULFILLED';
const GET_SCHEDULE_TOUR_TRANSACTION_PENDING =
  'GET_SCHEDULE_TOUR_TRANSACTION_PENDING';
const GET_SCHEDULE_TOUR_TRANSACTION_REJECTED =
  'GET_SCHEDULE_TOUR_TRANSACTION_REJECTED';

const POST_SET_PRIMARY_IMAGE = 'POST_SET_PRIMARY_IMAGE';
const POST_SET_PRIMARY_IMAGE_FULFILLED = 'POST_SET_PRIMARY_IMAGE_FULFILLED';
const POST_SET_PRIMARY_IMAGE_PENDING = 'POST_SET_PRIMARY_IMAGE_PENDING';
const POST_SET_PRIMARY_IMAGE_REJECTED = 'POST_SET_PRIMARY_IMAGE_REJECTED';

const GET_INVOICE_BY_TRANSACTION_ID = 'GET_INVOICE_BY_TRANSACTION_ID';
const GET_INVOICE_BY_TRANSACTION_ID_FULFILLED =
  'GET_INVOICE_BY_TRANSACTION_ID_FULFILLED';
const GET_INVOICE_BY_TRANSACTION_ID_PENDING =
  'GET_INVOICE_BY_TRANSACTION_ID_PENDING';
const GET_INVOICE_BY_TRANSACTION_ID_REJECTED =
  'GET_INVOICE_BY_TRANSACTION_ID_REJECTED';
//get transaction history by status
const GET_TRANSACTION_ALL_BY_STATUS = 'GET_TRANSACTION_ALL_BY_STATUS';
const GET_TRANSACTION_ALL_BY_STATUS_FULFILLED =
  'GET_TRANSACTION_ALL_BY_STATUS_FULFILLED';
const GET_TRANSACTION_ALL_BY_STATUS_PENDING =
  'GET_TRANSACTION_ALL_BY_STATUS_PENDING';
const GET_TRANSACTION_ALL_BY_STATUS_REJECTED =
  'GET_TRANSACTION_ALL_BY_STATUS_REJECTED';

const GET_GROUP_TYPE_LIST = 'GET_GROUP_TYPE_LIST';
const GET_GROUP_TYPE_LIST_FULFILLED = 'GET_GROUP_TYPE_LIST_FULFILLED';
const GET_GROUP_TYPE_LIST_PENDING = 'GET_GROUP_TYPE_LIST_PENDING';
const GET_GROUP_TYPE_LIST_REJECTED = 'GET_GROUP_TYPE_LIST_REJECTED';

const GET_IDENTITY_TYPE = 'GET_IDENTITY_TYPE';
const GET_IDENTITY_TYPE_FULFILLED = 'GET_IDENTITY_TYPE_FULFILLED';
const GET_IDENTITY_TYPE_PENDING = 'GET_IDENTITY_TYPE_PENDING';
const GET_IDENTITY_TYPE_REJECTED = 'GET_IDENTITY_TYPE_REJECTED';
const GET_GUEST_TITLE_TYPE = 'GET_GUEST_TITLE_TYPE';
const GET_GUEST_TITLE_TYPE_FULFILLED = 'GET_GUEST_TITLE_TYPE_FULFILLED';
const GET_GUEST_TITLE_TYPE_PENDING = 'GET_GUEST_TITLE_TYPE_PENDING';
const GET_GUEST_TITLE_TYPE_REJECTED = 'GET_GUEST_TITLE_TYPE_REJECTED';

const DELETE_PACKAGE = 'DELETE_PACKAGE';
const DELETE_PACKAGE_PENDING = 'DELETE_PACKAGE_PENDING';
const DELETE_PACKAGE_FULFILLED = 'DELETE_PACKAGE_FULFILLED';
const DELETE_PACKAGE_REJECTED = 'DELETE_PACKAGE_REJECTED';
const COPY_PACKAGE = 'COPY_PACKAGE';
const COPY_PACKAGE_PENDING = 'COPY_PACKAGE_PENDING';
const COPY_PACKAGE_FULFILLED = 'COPY_PACKAGE_FULFILLED';
const COPY_PACKAGE_REJECTED = 'COPY_PACKAGE_REJECTED';
const POST_EXCHANGE_RATE = 'POST_EXCHANGE_RATE';
const POST_EXCHANGE_RATE_PENDING = 'POST_EXCHANGE_RATE_PENDING';
const POST_EXCHANGE_RATE_FULFILLED = 'POST_EXCHANGE_RATE_FULFILLED';
const POST_EXCHANGE_RATE_REJECTED = 'POST_EXCHANGE_RATE_REJECTED';
const PUT_EXCHANGE_RATE = 'PUT_EXCHANGE_RATE';
const PUT_EXCHANGE_RATE_PENDING = 'PUT_EXCHANGE_RATE_PENDING';
const PUT_EXCHANGE_RATE_FULFILLED = 'PUT_EXCHANGE_RATE_FULFILLED';
const PUT_EXCHANGE_RATE_REJECTED = 'PUT_EXCHANGE_RATE_REJECTED';
const UPLOAD_BROCHURE_PACKAGE = 'UPLOAD_BROCHURE_PACKAGE';
const UPLOAD_BROCHURE_PACKAGE_PENDING = 'UPLOAD_BROCHURE_PACKAGE_PENDING';
const UPLOAD_BROCHURE_PACKAGE_FULFILLED = 'UPLOAD_BROCHURE_PACKAGE_FULFILLED';
const UPLOAD_BROCHURE_PACKAGE_REJECTED = 'UPLOAD_BROCHURE_PACKAGE_REJECTED';
const PUT_GENERAL_INFO_PACKAGE = 'PUT_GENERAL_INFO_PACKAGE';
const PUT_GENERAL_INFO_PACKAGE_PENDING = 'PUT_GENERAL_INFO_PACKAGE_PENDING';
const PUT_GENERAL_INFO_PACKAGE_FULFILLED = 'PUT_GENERAL_INFO_PACKAGE_FULFILLED';
const PUT_GENERAL_INFO_PACKAGE_REJECTED = 'PUT_GENERAL_INFO_PACKAGE_REJECTED';

const GET_INVOICE_BY_COMPANY_CODE = 'GET_INVOICE_BY_COMPANY_CODE';
const GET_INVOICE_BY_COMPANY_CODE_FULFILLED =
  'GET_INVOICE_BY_COMPANY_CODE_FULFILLED';
const GET_INVOICE_BY_COMPANY_CODE_PENDING =
  'GET_INVOICE_BY_COMPANY_CODE_PENDING';
const GET_INVOICE_BY_COMPANY_CODE_REJECTED =
  'GET_INVOICE_BY_COMPANY_CODE_REJECTED';

const GET_PRINT_PAYMENT_RECEIPT = 'GET_PRINT_PAYMENT_RECEIPT';
const GET_PRINT_PAYMENT_RECEIPT_FULFILLED =
  'GET_PRINT_PAYMENT_RECEIPT_FULFILLED';
const GET_PRINT_PAYMENT_RECEIPT_PENDING = 'GET_PRINT_PAYMENT_RECEIPT_PENDING';
const GET_PRINT_PAYMENT_RECEIPT_REJECTED = 'GET_PRINT_PAYMENT_RECEIPT_REJECTED';

const DELETE_ACCOMODATION_ITEM = 'DELETE_ACCOMODATION_ITEM';
const DELETE_ACCOMODATION_ITEM_PENDING = 'DELETE_ACCOMODATION_ITEM_PENDING';
const DELETE_ACCOMODATION_ITEM_FULFILLED = 'DELETE_ACCOMODATION_ITEM_FULFILLED';
const DELETE_ACCOMODATION_ITEM_REJECTED = 'DELETE_ACCOMODATION_ITEM_REJECTED';

const POST_EDIT_QUOTATION = 'POST_EDIT_QUOTATION';
const POST_EDIT_QUOTATION_PENDING = 'POST_EDIT_QUOTATION_PENDING';
const POST_EDIT_QUOTATION_FULFILLED = 'POST_EDIT_QUOTATION_FULFILLED';
const POST_EDIT_QUOTATION_REJECTED = 'POST_EDIT_QUOTATION_REJECTED';

const POST_ACCEPT_QUOTATION = 'POST_ACCEPT_QUOTATION';
const POST_ACCEPT_QUOTATION_PENDING = 'POST_ACCEPT_QUOTATION_PENDING';
const POST_ACCEPT_QUOTATION_FULFILLED = 'POST_ACCEPT_QUOTATION_FULFILLED';
const POST_ACCEPT_QUOTATION_REJECTED = 'POST_ACCEPT_QUOTATION_REJECTED';
const GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID =
  'GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID';
const GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID_FULFILLED =
  'GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID_FULFILLED';
const GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID_PENDING =
  'GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID_PENDING';
const GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID_REJECTED =
  'GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID_REJECTED';

const GET_TOUR_OPERATOR_PROFILE_BY_CODE = 'GET_TOUR_OPERATOR_PROFILE_BY_CODE';
const GET_TOUR_OPERATOR_PROFILE_BY_CODE_PENDING =
  'GET_TOUR_OPERATOR_PROFILE_BY_CODE_PENDING';
const GET_TOUR_OPERATOR_PROFILE_BY_CODE_FULFILLED =
  'GET_TOUR_OPERATOR_PROFILE_BY_CODE_FULFILLED';
const GET_TOUR_OPERATOR_PROFILE_BY_CODE_REJECTED =
  'GET_TOUR_OPERATOR_PROFILE_BY_CODE_REJECTED';
const RESET_STATUS_GET_TOUR_OPERATOR_BY_CODE =
  'RESET_STATUS_GET_TOUR_OPERATOR_BY_CODE';
const GET_TOUR_OPERATOR_PROFILE_DETAIL_BY_ID =
  'GET_TOUR_OPERATOR_PROFILE_DETAIL_BY_ID';
const GET_TOUR_OPERATOR_PROFILE_DETAIL_BY_ID_PENDING =
  'GET_TOUR_OPERATOR_PROFILE_DETAIL_BY_ID_PENDING';
const GET_TOUR_OPERATOR_PROFILE_DETAIL_BY_ID_FULFILLED =
  'GET_TOUR_OPERATOR_PROFILE_DETAIL_BY_ID_FULFILLED';
const GET_TOUR_OPERATOR_PROFILE_DETAIL_BY_ID_REJECTED =
  'GET_TOUR_OPERATOR_PROFILE_DETAIL_BY_ID_REJECTED';
const DELETE_TOUR_OPERATOR = 'DELETE_TOUR_OPERATOR';
const DELETE_TOUR_OPERATOR_PENDING = 'DELETE_TOUR_OPERATOR_PENDING';
const DELETE_TOUR_OPERATOR_FULFILLED = 'DELETE_TOUR_OPERATOR_FULFILLED';
const DELETE_TOUR_OPERATOR_REJECTED = 'DELETE_TOUR_OPERATOR_REJECTED';
const RESET_STATUS_DELETE_TOUR_OPERATOR = 'RESET_STATUS_DELETE_TOUR_OPERATOR';
const PUT_TOUR_OPERATOR = 'PUT_TOUR_OPERATOR';
const PUT_TOUR_OPERATOR_PENDING = 'PUT_TOUR_OPERATOR_PENDING';
const PUT_TOUR_OPERATOR_FULFILLED = 'PUT_TOUR_OPERATOR_FULFILLED';
const PUT_TOUR_OPERATOR_REJECTED = 'PUT_TOUR_OPERATOR_REJECTED';
const GET_TOUR_OPERATOR_PROFILE_BY_ID = 'GET_TOUR_OPERATOR_PROFILE_BY_ID';
const GET_TOUR_OPERATOR_PROFILE_BY_ID_PENDING =
  'GET_TOUR_OPERATOR_PROFILE_BY_ID_PENDING';
const GET_TOUR_OPERATOR_PROFILE_BY_ID_FULFILLED =
  'GET_TOUR_OPERATOR_PROFILE_BY_ID_FULFILLED';
const GET_TOUR_OPERATOR_PROFILE_BY_ID_REJECTED =
  'GET_TOUR_OPERATOR_PROFILE_BY_ID_REJECTED';

const GET_MASTER_REFERENCE_RESTAURANT_PROFILE_SPECIALIZATIONS_LIST =
  'GET_MASTER_REFERENCE_RESTAURANT_PROFILE_SPECIALIZATIONS_LIST';
const GET_MASTER_REFERENCE_RESTAURANT_PROFILE_SPECIALIZATIONS_LIST_PENDING =
  'GET_MASTER_REFERENCE_RESTAURANT_PROFILE_SPECIALIZATIONS_LIST_PENDING';
const GET_MASTER_REFERENCE_RESTAURANT_PROFILE_SPECIALIZATIONS_LIST_FULFILLED =
  'GET_MASTER_REFERENCE_RESTAURANT_PROFILE_SPECIALIZATIONS_LIST_FULFILLED';
const GET_MASTER_REFERENCE_RESTAURANT_PROFILE_SPECIALIZATIONS_LIST_REJECTED =
  'GET_MASTER_REFERENCE_RESTAURANT_PROFILE_SPECIALIZATIONS_LIST_REJECTED';
const GET_RESTAURANT_RATING = 'GET_RESTAURANT_RATING';
const GET_RESTAURANT_RATING_PENDING = 'GET_RESTAURANT_RATING_PENDING';
const GET_RESTAURANT_RATING_FULFILLED = 'GET_RESTAURANT_RATING_FULFILLED';
const GET_RESTAURANT_RATING_REJECTED = 'GET_RESTAURANT_RATING_REJECTED';
const DELETE_EXCURSION_OBJECT = 'DELETE_EXCURSION_OBJECT';
const DELETE_EXCURSION_OBJECT_PENDING = 'DELETE_EXCURSION_OBJECT_PENDING';
const DELETE_EXCURSION_OBJECT_FULFILLED = 'DELETE_EXCURSION_OBJECT_FULFILLED';
const DELETE_EXCURSION_OBJECT_REJECTED = 'DELETE_EXCURSION_OBJECT_REJECTED';

const DELETE_RESTAURANT_ITEM_BY_ID = 'DELETE_RESTAURANT_ITEM_BY_ID';
const DELETE_RESTAURANT_ITEM_BY_ID_FULFILLED =
  'DELETE_RESTAURANT_ITEM_BY_ID_FULFILLED';
const DELETE_RESTAURANT_ITEM_BY_ID_PENDING =
  'DELETE_RESTAURANT_ITEM_BY_ID_PENDING';
const DELETE_RESTAURANT_ITEM_BY_ID_REJECTED =
  'DELETE_RESTAURANT_ITEM_BY_ID_REJECTED';

const DELETE_EXCHANGE_RATE = 'DELETE_EXCHANGE_RATE';
const DELETE_EXCHANGE_RATE_PENDING = 'DELETE_EXCHANGE_RATE_PENDING';
const DELETE_EXCHANGE_RATE_FULFILLED = 'DELETE_EXCHANGE_RATE_FULFILLED';
const DELETE_EXCHANGE_RATE_REJECTED = 'DELETE_EXCHANGE_RATE_REJECTED';
const DELETE_CONTRACTING_FILE = 'DELETE_CONTRACTING_FILE';
const DELETE_CONTRACTING_FILE_FULFILLED = 'DELETE_CONTRACTING_FILE_FULFILLED';
const DELETE_CONTRACTING_FILE_PENDING = 'DELETE_CONTRACTING_FILE_PENDING';
const DELETE_CONTRACTING_FILE_REJECTED = 'DELETE_CONTRACTING_FILE_REJECTED';

const POST_CONTRACTING_FILE = 'POST_CONTRACTING_FILE';
const POST_CONTRACTING_FILE_FULFILLED = 'POST_CONTRACTING_FILE_FULFILLED';
const POST_CONTRACTING_FILE_PENDING = 'POST_CONTRACTING_FILE_PENDING';
const POST_CONTRACTING_FILE_REJECTED = 'POST_CONTRACTING_FILE_REJECTED';
const POST_JOIN_TOUR_ON_BE_HALF = 'POST_JOIN_TOUR_ON_BE_HALF';
const POST_JOIN_TOUR_ON_BE_HALF_FULFILLED =
  'POST_JOIN_TOUR_ON_BE_HALF_FULFILLED';
const POST_JOIN_TOUR_ON_BE_HALF_PENDING = 'POST_JOIN_TOUR_ON_BE_HALF_PENDING';
const POST_JOIN_TOUR_ON_BE_HALF_REJECTED = 'POST_JOIN_TOUR_ON_BE_HALF_REJECTED';

const GET_ACCOMMODATION_WITH_FILTER = 'GET_ACCOMMODATION_WITH_FILTER';
const GET_ACCOMMODATION_WITH_FILTER_FULFILLED =
  'GET_ACCOMMODATION_WITH_FILTER_FULFILLED';
const GET_ACCOMMODATION_WITH_FILTER_PENDING =
  'GET_ACCOMMODATION_WITH_FILTER_PENDING';
const GET_ACCOMMODATION_WITH_FILTER_REJECTED =
  'GET_ACCOMMODATION_WITH_FILTER_REJECTED';
const GET_LIST_TOUR_OPERATOR = 'GET_LIST_TOUR_OPERATOR';
const GET_LIST_TOUR_OPERATOR_FULFILLED = 'GET_LIST_TOUR_OPERATOR_FULFILLED';
const GET_LIST_TOUR_OPERATOR_PENDING = 'GET_LIST_TOUR_OPERATOR_PENDING';
const GET_LIST_TOUR_OPERATOR_REJECTED = 'GET_LIST_TOUR_OPERATOR_REJECTED';
const RESET_AIRPORT = 'RESET_AIRPORT';
const POST_DEMO_PRICE = 'POST_DEMO_PRICE';
const POST_DEMO_PRICE_FULFILLED = 'POST_DEMO_PRICE_FULFILLED';
const POST_DEMO_PRICE_PENDING = 'POST_DEMO_PRICE_PENDING';
const POST_DEMO_PRICE_REJECTED = 'POST_DEMO_PRICE_REJECTED';

const POST_CREATE_TOUR_TRANSACTION = 'POST_CREATE_TOUR_TRANSACTION';
const POST_CREATE_TOUR_TRANSACTION_FULFILLED =
  'POST_CREATE_TOUR_TRANSACTION_FULFILLED';
const POST_CREATE_TOUR_TRANSACTION_PENDING =
  'POST_CREATE_TOUR_TRANSACTION_PENDING';
const POST_CREATE_TOUR_TRANSACTION_REJECTED =
  'POST_CREATE_TOUR_TRANSACTION_REJECTED';

const POST_DEMO_JOIN_TOUR = 'POST_DEMO_JOIN_TOUR';
const POST_DEMO_JOIN_TOUR_FULFILLED = 'POST_DEMO_JOIN_TOUR_FULFILLED';
const POST_DEMO_JOIN_TOUR_PENDING = 'POST_DEMO_JOIN_TOUR_PENDING';
const POST_DEMO_JOIN_TOUR_REJECTED = 'POST_DEMO_JOIN_TOUR_REJECTED';

const GET_PAYMENT_RECEIPT_BY_TRANSACTION_ID =
  'GET_PAYMENT_RECEIPT_BY_TRANSACTION_ID';
const GET_PAYMENT_RECEIPT_BY_TRANSACTION_ID_FULFILLED =
  'GET_PAYMENT_RECEIPT_BY_TRANSACTION_ID_FULFILLED';
const GET_PAYMENT_RECEIPT_BY_TRANSACTION_ID_PENDING =
  'GET_PAYMENT_RECEIPT_BY_TRANSACTION_ID_PENDING';
const GET_PAYMENT_RECEIPT_BY_TRANSACTION_ID_REJECTED =
  'GET_PAYMENT_RECEIPT_BY_TRANSACTION_ID_REJECTED';

const GET_ALL_NOT_ALLOWED_COUNTRY_FOR_COMPANY =
  'GET_ALL_NOT_ALLOWED_COUNTRY_FOR_COMPANY';
const GET_ALL_NOT_ALLOWED_COUNTRY_FOR_COMPANY_FULFILLED =
  'GET_ALL_NOT_ALLOWED_COUNTRY_FOR_COMPANY_FULFILLED';
const GET_ALL_NOT_ALLOWED_COUNTRY_FOR_COMPANY_PENDING =
  'GET_ALL_NOT_ALLOWED_COUNTRY_FOR_COMPANY_PENDING';
const GET_ALL_NOT_ALLOWED_COUNTRY_FOR_COMPANY_REJECTED =
  'GET_ALL_NOT_ALLOWED_COUNTRY_FOR_COMPANY_REJECTED';

const POST_NOT_ALLOWED_COUNTRY_FOR_COMPANY =
  'POST_NOT_ALLOWED_COUNTRY_FOR_COMPANY';
const POST_NOT_ALLOWED_COUNTRY_FOR_COMPANY_FULFILLED =
  'POST_NOT_ALLOWED_COUNTRY_FOR_COMPANY_FULFILLED';
const POST_NOT_ALLOWED_COUNTRY_FOR_COMPANY_PENDING =
  'POST_NOT_ALLOWED_COUNTRY_FOR_COMPANY_PENDING';
const POST_NOT_ALLOWED_COUNTRY_FOR_COMPANY_REJECTED =
  'POST_NOT_ALLOWED_COUNTRY_FOR_COMPANY_REJECTED';

const PUT_NOT_ALLOWED_COUNTRY_FOR_COMPANY =
  'PUT_NOT_ALLOWED_COUNTRY_FOR_COMPANY';
const PUT_NOT_ALLOWED_COUNTRY_FOR_COMPANY_FULFILLED =
  'PUT_NOT_ALLOWED_COUNTRY_FOR_COMPANY_FULFILLED';
const PUT_NOT_ALLOWED_COUNTRY_FOR_COMPANY_PENDING =
  'PUT_NOT_ALLOWED_COUNTRY_FOR_COMPANY_PENDING';
const PUT_NOT_ALLOWED_COUNTRY_FOR_COMPANY_REJECTED =
  'PUT_NOT_ALLOWED_COUNTRY_FOR_COMPANY_REJECTED';

const GET_NOT_ALLOWED_COUNTRY_FOR_COMPANY_BY_COMPANY_CODE =
  'GET_NOT_ALLOWED_COUNTRY_FOR_COMPANY_BY_COMPANY_CODE';
const GET_NOT_ALLOWED_COUNTRY_FOR_COMPANY_BY_COMPANY_CODE_FULFILLED =
  'GET_NOT_ALLOWED_COUNTRY_FOR_COMPANY_BY_COMPANY_CODE_FULFILLED';
const GET_NOT_ALLOWED_COUNTRY_FOR_COMPANY_BY_COMPANY_CODE_PENDING =
  'GET_NOT_ALLOWED_COUNTRY_FOR_COMPANY_BY_COMPANY_CODE_PENDING';
const GET_NOT_ALLOWED_COUNTRY_FOR_COMPANY_BY_COMPANY_CODE_REJECTED =
  'GET_NOT_ALLOWED_COUNTRY_FOR_COMPANY_BY_COMPANY_CODE_REJECTED';

const GET_ROOM_ALLOTMENT_BY_PROFILE_ID = 'GET_ROOM_ALLOTMENT_BY_PROFILE_ID';
const GET_ROOM_ALLOTMENT_BY_PROFILE_ID_FULFILLED =
  'GET_ROOM_ALLOTMENT_BY_PROFILE_ID_FULFILLED';
const GET_ROOM_ALLOTMENT_BY_PROFILE_ID_PENDING =
  'GET_ROOM_ALLOTMENT_BY_PROFILE_ID_PENDING';
const GET_ROOM_ALLOTMENT_BY_PROFILE_ID_REJECTED =
  'GET_ROOM_ALLOTMENT_BY_PROFILE_ID_REJECTED';
const POST_ROOM_ALLOTMENT_DAY = 'POST_ROOM_ALLOTMENT_DAY';
const POST_ROOM_ALLOTMENT_DAY_FULFILLED = 'POST_ROOM_ALLOTMENT_DAY_FULFILLED';
const POST_ROOM_ALLOTMENT_DAY_PENDING = 'POST_ROOM_ALLOTMENT_DAY_PENDING';
const POST_ROOM_ALLOTMENT_DAY_REJECTED = 'POST_ROOM_ALLOTMENT_DAY_REJECTED';

const POST_IMAGE_TOUR_OPERATOR = 'POST_IMAGE_TOUR_OPERATOR';
const POST_IMAGE_TOUR_OPERATOR_FULFILLED = 'POST_IMAGE_TOUR_OPERATOR_FULFILLED';
const POST_IMAGE_TOUR_OPERATOR_PENDING = 'POST_IMAGE_TOUR_OPERATOR_PENDING';
const POST_IMAGE_TOUR_OPERATOR_REJECTED = 'POST_IMAGE_TOUR_OPERATOR_REJECTED';

const POST_TOUR_OPERATOR = 'POST_TOUR_OPERATOR';
const POST_TOUR_OPERATOR_FULFILLED = 'POST_TOUR_OPERATOR_FULFILLED';
const POST_TOUR_OPERATOR_PENDING = 'POST_TOUR_OPERATOR_PENDING';
const POST_TOUR_OPERATOR_REJECTED = 'POST_TOUR_OPERATOR_REJECTED';

const DELETE_IMAGE_TOUR_OPERATOR = 'DELETE_IMAGE_TOUR_OPERATOR';
const DELETE_IMAGE_TOUR_OPERATOR_FULFILLED =
  'DELETE_IMAGE_TOUR_OPERATOR_FULFILLED';
const DELETE_IMAGE_TOUR_OPERATOR_PENDING = 'DELETE_IMAGE_TOUR_OPERATOR_PENDING';
const DELETE_IMAGE_TOUR_OPERATOR_REJECTED =
  'DELETE_IMAGE_TOUR_OPERATOR_REJECTED';
const POST_ROOM_ALLOTMENT_PERIOD = 'POST_ROOM_ALLOTMENT_PERIOD';
const POST_ROOM_ALLOTMENT_PERIOD_FULFILLED =
  'POST_ROOM_ALLOTMENT_PERIOD_FULFILLED';
const POST_ROOM_ALLOTMENT_PERIOD_PENDING = 'POST_ROOM_ALLOTMENT_PERIOD_PENDING';
const POST_ROOM_ALLOTMENT_PERIOD_REJECTED =
  'POST_ROOM_ALLOTMENT_PERIOD_REJECTED';

const GET_REQUEST_ROOM_ALLOTMENT = 'GET_REQUEST_ROOM_ALLOTMENT';
const GET_REQUEST_ROOM_ALLOTMENT_FULFILLED =
  'GET_REQUEST_ROOM_ALLOTMENT_FULFILLED';
const GET_REQUEST_ROOM_ALLOTMENT_PENDING = 'GET_REQUEST_ROOM_ALLOTMENT_PENDING';
const GET_REQUEST_ROOM_ALLOTMENT_REJECTED =
  'GET_REQUEST_ROOM_ALLOTMENT_REJECTED';
const POST_CONFIRM_ROOM_ALLOTMENT = 'POST_CONFIRM_ROOM_ALLOTMENT';
const POST_CONFIRM_ROOM_ALLOTMENT_FULFILLED =
  'POST_CONFIRM_ROOM_ALLOTMENT_FULFILLED';
const POST_CONFIRM_ROOM_ALLOTMENT_PENDING =
  'POST_CONFIRM_ROOM_ALLOTMENT_PENDING';
const POST_CONFIRM_ROOM_ALLOTMENT_REJECTED =
  'POST_CONFIRM_ROOM_ALLOTMENT_REJECTED';
const GET_LIST_CONFIRM_ROOM_ALLOTMENTS = 'GET_LIST_CONFIRM_ROOM_ALLOTMENTS';
const GET_LIST_CONFIRM_ROOM_ALLOTMENTS_FULFILLED =
  'GET_LIST_CONFIRM_ROOM_ALLOTMENTS_FULFILLED';
const GET_LIST_CONFIRM_ROOM_ALLOTMENTS_PENDING =
  'GET_LIST_CONFIRM_ROOM_ALLOTMENTS_PENDING';
const GET_LIST_CONFIRM_ROOM_ALLOTMENTS_REJECTED =
  'GET_LIST_CONFIRM_ROOM_ALLOTMENTS_REJECTED';
const GET_RESEND_REQUEST_CONFIRMATION = 'GET_RESEND_REQUEST_CONFIRMATION';
const GET_RESEND_REQUEST_CONFIRMATION_FULFILLED =
  'GET_RESEND_REQUEST_CONFIRMATION_FULFILLED';
const GET_RESEND_REQUEST_CONFIRMATION_PENDING =
  'GET_RESEND_REQUEST_CONFIRMATION_PENDING';
const GET_RESEND_REQUEST_CONFIRMATION_REJECTED =
  'GET_RESEND_REQUEST_CONFIRMATION_REJECTED';

const GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID =
  'GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID';
const GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID_FULFILLED =
  'GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID_FULFILLED';
const GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID_PENDING =
  'GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID_PENDING';
const GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID_REJECTED =
  'GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID_REJECTED';
const GET_STOCK_ROOM_ALLOTMENT = 'GET_STOCK_ROOM_ALLOTMENT';
const GET_STOCK_ROOM_ALLOTMENT_FULFILLED = 'GET_STOCK_ROOM_ALLOTMENT_FULFILLED';
const GET_STOCK_ROOM_ALLOTMENT_PENDING = 'GET_STOCK_ROOM_ALLOTMENT_PENDING';
const GET_STOCK_ROOM_ALLOTMENT_REJECTED = 'GET_STOCK_ROOM_ALLOTMENT_REJECTED';
const GET_EXPORT_STOCK_ROOM_ALLOTMENT = 'GET_EXPORT_STOCK_ROOM_ALLOTMENT';
const GET_EXPORT_STOCK_ROOM_ALLOTMENT_FULFILLED =
  'GET_EXPORT_STOCK_ROOM_ALLOTMENT_FULFILLED';
const GET_EXPORT_STOCK_ROOM_ALLOTMENT_PENDING =
  'GET_EXPORT_STOCK_ROOM_ALLOTMENT_PENDING';
const GET_EXPORT_STOCK_ROOM_ALLOTMENT_REJECTED =
  'GET_EXPORT_STOCK_ROOM_ALLOTMENT_REJECTED';

const GET_SALES_REPORT = 'GET_SALES_REPORT';
const GET_SALES_REPORT_FULFILLED = 'GET_SALES_REPORT_FULFILLED';
const GET_SALES_REPORT_PENDING = 'GET_SALES_REPORT_PENDING';
const GET_SALES_REPORT_REJECTED = 'GET_SALES_REPORT_REJECTED';
const GET_TRANSACTION_INVOICE_WITH_STATUS =
  'GET_TRANSACTION_INVOICE_WITH_STATUS';
const GET_TRANSACTION_INVOICE_WITH_STATUS_FULFILLED =
  'GET_TRANSACTION_INVOICE_WITH_STATUS_FULFILLED';
const GET_TRANSACTION_INVOICE_WITH_STATUS_PENDING =
  'GET_TRANSACTION_INVOICE_WITH_STATUS_PENDING';
const GET_TRANSACTION_INVOICE_WITH_STATUS_REJECTED =
  'GET_TRANSACTION_INVOICE_WITH_STATUS_REJECTED';
const POST_COMMISSION_PAYMENT = 'POST_COMMISSION_PAYMENT';
const POST_COMMISSION_PAYMENT_FULFILLED = 'POST_COMMISSION_PAYMENT_FULFILLED';
const POST_COMMISSION_PAYMENT_PENDING = 'POST_COMMISSION_PAYMENT_PENDING';
const POST_COMMISSION_PAYMENT_REJECTED = 'POST_COMMISSION_PAYMENT_REJECTED';

const GET_TOUR_OPERATOR_EMAIL_SETTING_ALL =
  'GET_TOUR_OPERATOR_EMAIL_SETTING_ALL';
const GET_TOUR_OPERATOR_EMAIL_SETTING_ALL_FULFILLED =
  'GET_TOUR_OPERATOR_EMAIL_SETTING_ALL_FULFILLED';
const GET_TOUR_OPERATOR_EMAIL_SETTING_ALL_PENDING =
  'GET_TOUR_OPERATOR_EMAIL_SETTING_ALL_PENDING';
const GET_TOUR_OPERATOR_EMAIL_SETTING_ALL_REJECTED =
  'GET_TOUR_OPERATOR_EMAIL_SETTING_ALL_REJECTED';
const GET_TOUR_OPERATOR_EMAIL_SETTING_BY_ID =
  'GET_TOUR_OPERATOR_EMAIL_SETTING_BY_ID';
const GET_TOUR_OPERATOR_EMAIL_SETTING_BY_ID_FULFILLED =
  'GET_TOUR_OPERATOR_EMAIL_SETTING_BY_ID_FULFILLED';
const GET_TOUR_OPERATOR_EMAIL_SETTING_BY_ID_PENDING =
  'GET_TOUR_OPERATOR_EMAIL_SETTING_BY_ID_PENDING';
const GET_TOUR_OPERATOR_EMAIL_SETTING_BY_ID_REJECTED =
  'GET_TOUR_OPERATOR_EMAIL_SETTING_BY_ID_REJECTED';
const POST_TOUR_OPERATOR_EMAIL_SETTING = 'POST_TOUR_OPERATOR_EMAIL_SETTING';
const POST_TOUR_OPERATOR_EMAIL_SETTING_FULFILLED =
  'POST_TOUR_OPERATOR_EMAIL_SETTING_FULFILLED';
const POST_TOUR_OPERATOR_EMAIL_SETTING_PENDING =
  'POST_TOUR_OPERATOR_EMAIL_SETTING_PENDING';
const POST_TOUR_OPERATOR_EMAIL_SETTING_REJECTED =
  'POST_TOUR_OPERATOR_EMAIL_SETTING_REJECTED';
const PUT_TOUR_OPERATOR_EMAIL_SETTING = 'PUT_TOUR_OPERATOR_EMAIL_SETTING';
const PUT_TOUR_OPERATOR_EMAIL_SETTING_FULFILLED =
  'PUT_TOUR_OPERATOR_EMAIL_SETTING_FULFILLED';
const PUT_TOUR_OPERATOR_EMAIL_SETTING_PENDING =
  'PUT_TOUR_OPERATOR_EMAIL_SETTING_PENDING';
const PUT_TOUR_OPERATOR_EMAIL_SETTING_REJECTED =
  'PUT_TOUR_OPERATOR_EMAIL_SETTING_REJECTED';
const DELETE_TOUR_OPERATOR_EMAIL_SETTING = 'DELETE_TOUR_OPERATOR_EMAIL_SETTING';
const DELETE_TOUR_OPERATOR_EMAIL_SETTING_FULFILLED =
  'DELETE_TOUR_OPERATOR_EMAIL_SETTING_FULFILLED';
const DELETE_TOUR_OPERATOR_EMAIL_SETTING_PENDING =
  'DELETE_TOUR_OPERATOR_EMAIL_SETTING_PENDING';
const DELETE_TOUR_OPERATOR_EMAIL_SETTING_REJECTED =
  'DELETE_TOUR_OPERATOR_EMAIL_SETTING_REJECTED';
const RESET_TOUR_OPERATOR_EMAIL_SETTING_STATUS =
  'RESET_TOUR_OPERATOR_EMAIL_SETTING_STATUS';

const GET_TOUR_OPERATOR_EMAIL_SETTING_BY_OPERATOR =
  'GET_TOUR_OPERATOR_EMAIL_SETTING_BY_OPERATOR';
const GET_TOUR_OPERATOR_EMAIL_SETTING_BY_OPERATOR_FULFILLED =
  'GET_TOUR_OPERATOR_EMAIL_SETTING_BY_OPERATOR_FULFILLED';
const GET_TOUR_OPERATOR_EMAIL_SETTING_BY_OPERATOR_PENDING =
  'GET_TOUR_OPERATOR_EMAIL_SETTING_BY_OPERATOR_PENDING';
const GET_TOUR_OPERATOR_EMAIL_SETTING_BY_OPERATOR_REJECTED =
  'GET_TOUR_OPERATOR_EMAIL_SETTING_BY_OPERATOR_REJECTED';

const GET_TOUR_TRANSACTIONS_COMMISSION_PAYMENT =
  'GET_TOUR_TRANSACTIONS_COMMISSION_PAYMENT';
const GET_TOUR_TRANSACTIONS_COMMISSION_PAYMENT_FULFILLED =
  'GET_TOUR_TRANSACTIONS_COMMISSION_PAYMENT_FULFILLED';
const GET_TOUR_TRANSACTIONS_COMMISSION_PAYMENT_PENDING =
  'GET_TOUR_TRANSACTIONS_COMMISSION_PAYMENT_PENDING';
const GET_TOUR_TRANSACTIONS_COMMISSION_PAYMENT_REJECTED =
  'GET_TOUR_TRANSACTIONS_COMMISSION_PAYMENT_REJECTED';

//Get City In Country
const GET_CITY_IN_COUNTRY = 'GET_CITY_IN_COUNTRY';
const GET_CITY_IN_COUNTRY_FULFILLED = 'GET_CITY_IN_COUNTRY_FULFILLED';
const GET_CITY_IN_COUNTRY_PENDING = 'GET_CITY_IN_COUNTRY_PENDING';
const GET_CITY_IN_COUNTRY_REJECTED = 'GET_CITY_IN_COUNTRY_REJECTED';

const POST_RESEND_DATA_TOUR_TRANSACTION = 'POST_RESEND_DATA_TOUR_TRANSACTION';
const POST_RESEND_DATA_TOUR_TRANSACTION_REJECTED =
  'POST_RESEND_DATA_TOUR_TRANSACTION_REJECTED';
const POST_RESEND_DATA_TOUR_TRANSACTION_PENDING =
  'POST_RESEND_DATA_TOUR_TRANSACTION_PENDING';
const POST_RESEND_DATA_TOUR_TRANSACTION_FULFILLED =
  'POST_RESEND_DATA_TOUR_TRANSACTION_FULFILLED';

const GET_PACKAGE_TRANSACTION_HISTORY = 'GET_PACKAGE_TRANSACTION_HISTORY';
const GET_PACKAGE_TRANSACTION_HISTORY_FULFILLED =
  'GET_PACKAGE_TRANSACTION_HISTORY_FULFILLED';
const GET_PACKAGE_TRANSACTION_HISTORY_PENDING =
  'GET_PACKAGE_TRANSACTION_HISTORY_PENDING';
const GET_PACKAGE_TRANSACTION_HISTORY_REJECTED =
  'GET_PACKAGE_TRANSACTION_HISTORY_REJECTED';

const GET_ALL_SHARED_PACKAGE = 'GET_ALL_SHARED_PACKAGE';
const GET_ALL_SHARED_PACKAGE_FULFILLED = 'GET_ALL_SHARED_PACKAGE_FULFILLED';
const GET_ALL_SHARED_PACKAGE_PENDING = 'GET_ALL_SHARED_PACKAGE_PENDING';
const GET_ALL_SHARED_PACKAGE_REJECTED = 'GET_ALL_SHARED_PACKAGE_REJECTED';

const GET_FILTER_SHARED_PACKAGE = 'GET_FILTER_SHARED_PACKAGE';
const GET_FILTER_SHARED_PACKAGE_FULFILLED =
  'GET_FILTER_SHARED_PACKAGE_FULFILLED';
const GET_FILTER_SHARED_PACKAGE_PENDING = 'GET_FILTER_SHARED_PACKAGE_PENDING';
const GET_FILTER_SHARED_PACKAGE_REJECTED = 'GET_FILTER_SHARED_PACKAGE_REJECTED';

const GET_SHARED_PACKAGE_BY_ID = 'GET_SHARED_PACKAGE_BY_ID';
const GET_SHARED_PACKAGE_BY_ID_FULFILLED = 'GET_SHARED_PACKAGE_BY_ID_FULFILLED';
const GET_SHARED_PACKAGE_BY_ID_PENDING = 'GET_SHARED_PACKAGE_BY_ID_PENDING';
const GET_SHARED_PACKAGE_BY_ID_REJECTED = 'GET_SHARED_PACKAGE_BY_ID_REJECTED';

const GET_USER_PROFILE = 'GET_USER_PROFILE';
const GET_USER_PROFILE_PENDING = 'GET_USER_PROFILE_PENDING';
const GET_USER_PROFILE_FULFILLED = 'GET_USER_PROFILE_FULFILLED';
const GET_USER_PROFILE_REJECTED = 'GET_USER_PROFILE_REJECTED';

const PUT_USER_PROFILE_BY_BEARER = 'PUT_USER_PROFILE_BY_BEARER';
const PUT_USER_PROFILE_BY_BEARER_PENDING = 'PUT_USER_PROFILE_BY_BEARER_PENDING';
const PUT_USER_PROFILE_BY_BEARER_FULFILLED =
  'PUT_USER_PROFILE_BY_BEARER_FULFILLED';
const PUT_USER_PROFILE_BY_BEARER_REJECTED =
  'PUT_USER_PROFILE_BY_BEARER_REJECTED';

const POST_CHANGE_PASSWORD = 'POST_CHANGE_PASSWORD';
const POST_CHANGE_PASSWORD_PENDING = 'POST_CHANGE_PASSWORD_PENDING';
const POST_CHANGE_PASSWORD_REJECTED = 'POST_CHANGE_PASSWORD_REJECTED';
const POST_CHANGE_PASSWORD_FULFILLED = 'POST_CHANGE_PASSWORD_FULFILLED';

const GET_USER_PROFILE_ONLY_BY_USER_ID = 'GET_USER_PROFILE_ONLY_BY_USER_ID';
const GET_USER_PROFILE_ONLY_BY_USER_ID_FULFILLED =
  'GET_USER_PROFILE_ONLY_BY_USER_ID_FULFILLED';
const GET_USER_PROFILE_ONLY_BY_USER_ID_PENDING =
  'GET_USER_PROFILE_ONLY_BY_USER_ID_PENDING';
const GET_USER_PROFILE_ONLY_BY_USER_ID_REJECTED =
  'GET_USER_PROFILE_ONLY_BY_USER_ID_REJECTED';

const GET_DOWNLOAD_SCHEDULE_BY_TRANSACTION_ID =
  'GET_DOWNLOAD_SCHEDULE_BY_TRANSACTION_ID';
const GET_DOWNLOAD_SCHEDULE_BY_TRANSACTION_ID_FULFILLED =
  'GET_DOWNLOAD_SCHEDULE_BY_TRANSACTION_ID_FULFILLED';
const GET_DOWNLOAD_SCHEDULE_BY_TRANSACTION_ID_PENDING =
  'GET_DOWNLOAD_SCHEDULE_BY_TRANSACTION_ID_PENDING';
const GET_DOWNLOAD_SCHEDULE_BY_TRANSACTION_ID_REJECTED =
  'GET_DOWNLOAD_SCHEDULE_BY_TRANSACTION_ID_REJECTED';

// const GET_DOWNLOAD_BROCHURE_BY_TRANSACTION_ID =
//   'GET_DOWNLOAD_BROCHURE_BY_TRANSACTION_ID';
// const GET_DOWNLOAD_BROCHURE_BY_TRANSACTION_ID_FULFILLED =
//   'GET_DOWNLOAD_BROCHURE_BY_TRANSACTION_ID_FULFILLED';
// const GET_DOWNLOAD_BROCHURE_BY_TRANSACTION_ID_PENDING =
//   'GET_DOWNLOAD_BROCHURE_BY_TRANSACTION_ID_PENDING';
// const GET_DOWNLOAD_BROCHURE_BY_TRANSACTION_ID_REJECTED =
//   'GET_DOWNLOAD_BROCHURE_BY_TRANSACTION_ID_REJECTED';

const GET_INVOICE_HISTORY_BY_TRANSACTION_ID =
  'GET_INVOICE_HISTORY_BY_TRANSACTION_ID';
const GET_INVOICE_HISTORY_BY_TRANSACTION_ID_FULFILLED =
  'GET_INVOICE_HISTORY_BY_TRANSACTION_ID_FULFILLED';
const GET_INVOICE_HISTORY_BY_TRANSACTION_ID_PENDING =
  'GET_INVOICE_HISTORY_BY_TRANSACTION_ID_PENDING';
const GET_INVOICE_HISTORY_BY_TRANSACTION_ID_REJECTED =
  'GET_INVOICE_HISTORY_BY_TRANSACTION_ID_REJECTED';

const GET_DOWNLOAD_VOUCHER_CONFIRMATION_BY_TRANSACTION_ID =
  'GET_DOWNLOAD_VOUCHER_CONFIRMATION_BY_TRANSACTION_ID';
const GET_DOWNLOAD_VOUCHER_CONFIRMATION_BY_TRANSACTION_ID_FULFILLED =
  'GET_DOWNLOAD_VOUCHER_CONFIRMATION_BY_TRANSACTION_ID_FULFILLED';
const GET_DOWNLOAD_VOUCHER_CONFIRMATION_BY_TRANSACTION_ID_PENDING =
  'GET_DOWNLOAD_VOUCHER_CONFIRMATION_BY_TRANSACTION_ID_PENDING';
const GET_DOWNLOAD_VOUCHER_CONFIRMATION_BY_TRANSACTION_ID_REJECTED =
  'GET_DOWNLOAD_VOUCHER_CONFIRMATION_BY_TRANSACTION_ID_REJECTED';

const POST_ADDITIONAL_ITEMS = 'POST_ADDITIONAL_ITEMS';
const POST_ADDITIONAL_ITEMS_FULFILLED = 'POST_ADDITIONAL_ITEMS_FULFILLED';
const POST_ADDITIONAL_ITEMS_PENDING = 'POST_ADDITIONAL_ITEMS_PENDING';
const POST_ADDITIONAL_ITEMS_REJECTED = 'POST_ADDITIONAL_ITEMS_REJECTED';

const GET_PACKAGES_VARIABLE_DATE = 'GET_PACKAGES_VARIABLE_DATE';
const GET_PACKAGES_VARIABLE_DATE_PENDING = 'GET_PACKAGES_VARIABLE_DATE_PENDING';
const GET_PACKAGES_VARIABLE_DATE_FULFILLED =
  'GET_PACKAGES_VARIABLE_DATE_FULFILLED';
const GET_PACKAGES_VARIABLE_DATE_REJECTED =
  'GET_PACKAGES_VARIABLE_DATE_REJECTED';
const PUT_ADDITIONAL_INFO_PACKAGE_OPERATION =
  'PUT_ADDITIONAL_INFO_PACKAGE_OPERATION';
const PUT_ADDITIONAL_INFO_PACKAGE_OPERATION_PENDING =
  'PUT_ADDITIONAL_INFO_PACKAGE_OPERATION_PENDING';
const PUT_ADDITIONAL_INFO_PACKAGE_OPERATION_FULFILLED =
  'PUT_ADDITIONAL_INFO_PACKAGE_OPERATION_FULFILLED';
const PUT_ADDITIONAL_INFO_PACKAGE_OPERATION_REJECTED =
  'PUT_ADDITIONAL_INFO_PACKAGE_OPERATION_REJECTED';

const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE';
const GET_PARTNER_EMPLOYEE = 'GET_PARTNER_EMPLOYEE';
const GET_PARTNER_EMPLOYEE_FULFILLED = 'GET_PARTNER_EMPLOYEE_FULFILLED';
const GET_PARTNER_EMPLOYEE_PENDING = 'GET_PARTNER_EMPLOYEE_PENDING';
const GET_PARTNER_EMPLOYEE_REJECTED = 'GET_PARTNER_EMPLOYEE_REJECTED';

const GET_EXPORT_DATA_ITEM_BY_ID = 'GET_EXPORT_DATA_ITEM_BY_ID';
const GET_EXPORT_DATA_ITEM_BY_ID_FULFILLED =
  'GET_EXPORT_DATA_ITEM_BY_ID_FULFILLED';
const GET_EXPORT_DATA_ITEM_BY_ID_PENDING = 'GET_EXPORT_DATA_ITEM_BY_ID_PENDING';
const GET_EXPORT_DATA_ITEM_BY_ID_REJECTED =
  'GET_EXPORT_DATA_ITEM_BY_ID_REJECTED';
const GET_PLACE_BY_PLACE_TYPE_ID = 'GET_PLACE_BY_PLACE_TYPE_ID';
const GET_PLACE_BY_PLACE_TYPE_ID_PENDING = 'GET_PLACE_BY_PLACE_TYPE_ID_PENDING';
const GET_PLACE_BY_PLACE_TYPE_ID_FULFILLED =
  'GET_PLACE_BY_PLACE_TYPE_ID_FULFILLED';
const GET_PLACE_BY_PLACE_TYPE_ID_REJECTED =
  'GET_PLACE_BY_PLACE_TYPE_ID_REJECTED';

const GET_COMPANY_PROFILE = 'GET_COMPANY_PROFILE';
const GET_COMPANY_PROFILE_FULFILLED = 'GET_COMPANY_PROFILE_FULFILLED';
const GET_COMPANY_PROFILE_PENDING = 'GET_COMPANY_PROFILE_PENDING';
const GET_COMPANY_PROFILE_REJECTED = 'GET_COMPANY_PROFILE_REJECTED';
const GET_ADDITIONAL_ITEM_TYPE = 'GET_ADDITIONAL_ITEM_TYPE';
const GET_ADDITIONAL_ITEM_TYPE_FULFILLED = 'GET_ADDITIONAL_ITEM_TYPE_FULFILLED';
const GET_ADDITIONAL_ITEM_TYPE_PENDING = 'GET_ADDITIONAL_ITEM_TYPE_PENDING';
const GET_ADDITIONAL_ITEM_TYPE_REJECTED = 'GET_ADDITIONAL_ITEM_TYPE_REJECTED';
const RESET_ADDITIONAL_ITEM_TYPE = 'ADDITIONAL_ITEM_TYPE';

const POST_EMAIL_SEND_CONFIRMATION = 'POST_EMAIL_SEND_CONFIRMATION';
const POST_EMAIL_SEND_CONFIRMATION_FULFILLED =
  'POST_EMAIL_SEND_CONFIRMATION_FULFILLED';
const POST_EMAIL_SEND_CONFIRMATION_PENDING =
  'POST_EMAIL_SEND_CONFIRMATION_PENDING';
const POST_EMAIL_SEND_CONFIRMATION_REJECTED =
  'POST_EMAIL_SEND_CONFIRMATION_REJECTED';

const GET_RESOURCE_BOOKING_BY_PERIODE = 'GET_RESOURCE_BOOKING_BY_PERIODE';
const GET_RESOURCE_BOOKING_BY_PERIODE_FULFILLED =
  'GET_RESOURCE_BOOKING_BY_PERIODE_FULFILLED';
const GET_RESOURCE_BOOKING_BY_PERIODE_PENDING =
  'GET_RESOURCE_BOOKING_BY_PERIODE_PENDING';
const GET_RESOURCE_BOOKING_BY_PERIODE_REJECTED =
  'GET_RESOURCE_BOOKING_BY_PERIODE_REJECTED';

const GET_RESOURCE_BOOKING_LIST_URL = 'GET_RESOURCE_BOOKING_LIST_URL';
const GET_RESOURCE_BOOKING_LIST_URL_URL_FULFILLED =
  'GET_RESOURCE_BOOKING_LIST_URL_URL_FULFILLED';
const GET_RESOURCE_BOOKING_LIST_URL_PENDING =
  'GET_RESOURCE_BOOKING_LIST_URL_PENDING';
const GET_RESOURCE_BOOKING_LIST_URL_REJECTED =
  'GET_RESOURCE_BOOKING_LIST_URL_REJECTED';

const PUT_PAY_RESOURCE_BOOKING_URL = 'PUT_PAY_RESOURCE_BOOKING_URL';
const PUT_PAY_RESOURCE_BOOKING_URL_FULFILLED =
  'PUT_PAY_RESOURCE_BOOKING_URL_FULFILLED';
const PUT_PAY_RESOURCE_BOOKING_URL_PENDING =
  'PUT_PAY_RESOURCE_BOOKING_URL_PENDING';
const PUT_PAY_RESOURCE_BOOKING_URL_REJECTED =
  'PUT_PAY_RESOURCE_BOOKING_URL_REJECTED';

const GET_RESTAURANT_ESTIMATE_PRICE = 'GET_RESTAURANT_ESTIMATE_PRICE';
const GET_RESTAURANT_ESTIMATE_PRICE_FULFILLED =
  'GET_RESTAURANT_ESTIMATE_PRICE_FULFILLED';
const GET_RESTAURANT_ESTIMATE_PRICE_PENDING =
  'GET_RESTAURANT_ESTIMATE_PRICE_PENDING';
const GET_RESTAURANT_ESTIMATE_PRICE_REJECTED =
  'GET_RESTAURANT_ESTIMATE_PRICE_REJECTED';

const GET_TRANSACTION_HISTORY_BY_TRANSACTION =
  'GET_TRANSACTION_HISTORY_BY_TRANSACTION';
const GET_TRANSACTION_HISTORY_BY_TRANSACTION_FULFILLED =
  'GET_TRANSACTION_HISTORY_BY_TRANSACTION_FULFILLED';
const GET_TRANSACTION_HISTORY_BY_TRANSACTION_PENDING =
  'GET_TRANSACTION_HISTORY_BY_TRANSACTION_PENDING';
const GET_TRANSACTION_HISTORY_BY_TRANSACTION_REJECTED =
  'GET_TRANSACTION_HISTORY_BY_TRANSACTION_REJECTED';

const GET_DOWNLOAD_SCHEDULE_BY_BOOKING_TEMPLATE =
  'GET_DOWNLOAD_SCHEDULE_BY_BOOKING_TEMPLATE';
const GET_DOWNLOAD_SCHEDULE_BY_BOOKING_TEMPLATE_FULFILLED =
  'GET_DOWNLOAD_SCHEDULE_BY_BOOKING_TEMPLATE_FULFILLED';
const GET_DOWNLOAD_SCHEDULE_BY_BOOKING_TEMPLATE_PENDING =
  'GET_DOWNLOAD_SCHEDULE_BY_BOOKING_TEMPLATE_PENDING';
const GET_DOWNLOAD_SCHEDULE_BY_BOOKING_TEMPLATE_REJECTED =
  'GET_DOWNLOAD_SCHEDULE_BY_BOOKING_TEMPLATE_REJECTED';

export {
  GET_CURRENCIES,
  GET_CURRENCIES_FULFILLED,
  GET_CURRENCIES_PENDING,
  GET_CURRENCIES_REJECTED,
  //Login
  // LOGIN,
  // LOGOUT,
  // RESET_LOGIN,
  // LOGIN_FULFILLED,
  // LOGIN_PENDING,
  // LOGIN_REJECTED,
  //Company
  GET_PENDING_COMPANY_FOR_APPROVAL,
  RESET_STATUS_COMPANY,
  GET_PENDING_COMPANY_FOR_APPROVAL_FULFILLED,
  GET_PENDING_COMPANY_FOR_APPROVAL_PENDING,
  GET_PENDING_COMPANY_FOR_APPROVAL_REJECTED,
  POST_ACCEPT_COMPANY_REGISTRATION,
  POST_ACCEPT_COMPANY_REGISTRATION_FULFILLED,
  POST_ACCEPT_COMPANY_REGISTRATION_PENDING,
  POST_ACCEPT_COMPANY_REGISTRATION_REJECTED,
  POST_REJECT_COMPANY_REGISTRATION,
  POST_REJECT_COMPANY_REGISTRATION_FULFILLED,
  POST_REJECT_COMPANY_REGISTRATION_PENDING,
  POST_REJECT_COMPANY_REGISTRATION_REJECTED,
  //Published
  GET_PUBLISHED_PACKAGE,
  RESET_ACTIVITY_PACKAGE,
  RESET_STATUS_PACKAGE,
  GET_PUBLISHED_PACKAGE_FULFILLED,
  GET_PUBLISHED_PACKAGE_PENDING,
  GET_PUBLISHED_PACKAGE_REJECTED,
  //Tour Transaction
  GET_TRANSACTION,
  RESET_STATUS_TRANSACTION,
  GET_TRANSACTION_FULFILLED,
  GET_TRANSACTION_PENDING,
  GET_TRANSACTION_REJECTED,
  POST_STATUS_TRANSACTION,
  POST_STATUS_TRANSACTION_FULFILLED,
  POST_STATUS_TRANSACTION_PENDING,
  POST_STATUS_TRANSACTION_REJECTED,
  GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID,
  GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID_FULFILLED,
  GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID_PENDING,
  GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID_REJECTED,
  POST_JOIN_TOUR_ON_BE_HALF,
  POST_JOIN_TOUR_ON_BE_HALF_FULFILLED,
  POST_JOIN_TOUR_ON_BE_HALF_PENDING,
  POST_JOIN_TOUR_ON_BE_HALF_REJECTED,
  //UserInfo
  GETUSERINFO,
  RESET_STATUS_USER,
  GETUSERINFO_FULFILLED,
  GETUSERINFO_PENDING,
  GETUSERINFO_REJECTED,
  //PAYMENT HISTORY
  GET_PAYMENT_HISTORY,
  GET_PAYMENT_HISTORY_FULFILLED,
  GET_PAYMENT_HISTORY_PENDING,
  GET_PAYMENT_HISTORY_REJECTED,
  RESET_STATUS_PAYMENT_HISTORY,
  //Accomodation List
  GET_ACCOMODATION_LIST,
  GET_ACCOMODATION_LIST_FULFILLED,
  GET_ACCOMODATION_LIST_PENDING,
  GET_ACCOMODATION_LIST_REJECTED,
  RESET_STATUS_ACCOMODATION,
  //Excursion
  GET_EXCURSION_PROFILES,
  GET_EXCURSION_PROFILES_FULFILLED,
  GET_EXCURSION_PROFILES_PENDING,
  GET_EXCURSION_PROFILES_REJECTED,
  GET_EXCURSION_PROFILE,
  GET_EXCURSION_PROFILE_FULFILLED,
  GET_EXCURSION_PROFILE_PENDING,
  GET_EXCURSION_PROFILE_REJECTED,
  GET_EXCURSION_TYPE,
  GET_EXCURSION_TYPE_PENDING,
  GET_EXCURSION_TYPE_FULFILLED,
  GET_EXCURSION_TYPE_REJECTED,
  GET_EXCURSION_PRICE_TYPE,
  GET_EXCURSION_PRICE_TYPE_PENDING,
  GET_EXCURSION_PRICE_TYPE_FULFILLED,
  GET_EXCURSION_PRICE_TYPE_REJECTED,
  POST_EXCURSION_PROFILE,
  POST_EXCURSION_PROFILE_PENDING,
  POST_EXCURSION_PROFILE_FULFILLED,
  POST_EXCURSION_PROFILE_REJECTED,
  RESET_STATUS_EXCURSION,
  PUT_EXCURSION_PROFILE,
  PUT_EXCURSION_PROFILE_PENDING,
  PUT_EXCURSION_PROFILE_FULFILLED,
  PUT_EXCURSION_PROFILE_REJECTED,
  //User List
  GET_LOCKED_USER_LIST,
  GET_LOCKED_USER_LIST_FULFILLED,
  GET_LOCKED_USER_LIST_PENDING,
  GET_LOCKED_USER_LIST_REJECTED,
  RESET_STATUS_USER_ACCESS,
  //Locked User List
  GET_USER_ACCESS_LIST,
  GET_USER_ACCESS_LIST_FULFILLED,
  GET_USER_ACCESS_LIST_PENDING,
  GET_USER_ACCESS_LIST_REJECTED,
  GET_USER_ROLES,
  GET_USER_ROLES_PENDING,
  GET_USER_ROLES_FULFILLED,
  GET_USER_ROLES_REJECTED,
  //Exchange Rate
  GET_EXCHANGE_RATE_LIST,
  GET_EXCHANGE_RATE_LIST_FULFILLED,
  GET_EXCHANGE_RATE_LIST_PENDING,
  GET_EXCHANGE_RATE_LIST_REJECTED,
  RESET_STATUS_EXCHANGE_RATE,
  //Trancation By Status On Hold
  GET_TRANSACTION_BY_STATUS_ON_HOLD_LIST,
  GET_TRANSACTION_BY_STATUS_ON_HOLD_LIST_FULFILLED,
  GET_TRANSACTION_BY_STATUS_ON_HOLD_LIST_PENDING,
  GET_TRANSACTION_BY_STATUS_ON_HOLD_LIST_REJECTED,
  RESET_STATUS_TRANSACTION_BY_STATUS_ON_HOLD,
  //Trancation By Status DP
  GET_TRANSACTION_BY_STATUS_CREATED_LIST,
  GET_TRANSACTION_BY_STATUS_CREATED_LIST_FULFILLED,
  GET_TRANSACTION_BY_STATUS_CREATED_LIST_PENDING,
  GET_TRANSACTION_BY_STATUS_CREATED_LIST_REJECTED,
  RESET_STATUS_TRANSACTION_BY_STATUS_CREATED,
  //Trancation By Status Complete
  GET_TRANSACTION_BY_STATUS_COMPLETE_LIST,
  GET_TRANSACTION_BY_STATUS_COMPLETE_LIST_FULFILLED,
  GET_TRANSACTION_BY_STATUS_COMPLETE_LIST_PENDING,
  GET_TRANSACTION_BY_STATUS_COMPLETE_LIST_REJECTED,
  RESET_STATUS_TRANSACTION_BY_STATUS_COMPLETE,
  //transaction
  GET_TRANSACTION_BY_PERIODE,
  GET_TRANSACTION_BY_PERIODE_PENDING,
  GET_TRANSACTION_BY_PERIODE_FULFILLED,
  GET_TRANSACTION_BY_PERIODE_REJECTED,
  GET_TRANSACTION_BY_ID,
  GET_TRANSACTION_BY_ID_REJECTED,
  GET_TRANSACTION_BY_ID_PENDING,
  GET_TRANSACTION_BY_ID_FULFILLED,
  GET_TRANSACTION_PAYABLE,
  GET_TRANSACTION_PAYABLE_FULFILLED,
  GET_TRANSACTION_PAYABLE_PENDING,
  GET_TRANSACTION_PAYABLE_REJECTED,
  //Accomodation By Profile Id
  GET_ACCOMODATION_BY_PROFILE_ID,
  GET_ACCOMODATION_BY_PROFILE_ID_FULFILLED,
  GET_ACCOMODATION_BY_PROFILE_ID_PENDING,
  GET_ACCOMODATION_BY_PROFILE_ID_REJECTED,
  //accomodation Types
  GET_ACCOMODATION_TYPES,
  GET_ACCOMODATION_TYPES_FULFILLED,
  GET_ACCOMODATION_TYPES_PENDING,
  GET_ACCOMODATION_TYPES_REJECTED,
  // accomodation Location
  GET_ACCOMODATION_LOCATION,
  GET_ACCOMODATION_LOCATION_PENDING,
  GET_ACCOMODATION_LOCATION_FULFILLED,
  GET_ACCOMODATION_LOCATION_REJECTED,
  //accomodation Facilities
  GET_ACCOMODATION_FACILITIES,
  GET_ACCOMODATION_FACILITIES_FULFILLED,
  GET_ACCOMODATION_FACILITIES_PENDING,
  GET_ACCOMODATION_FACILITIES_REJECTED,
  //accomodation Item By Profile Id
  GET_ACCOMODATION_ITEM_BY_PROFILE_ID,
  GET_ACCOMODATION_ITEM_BY_PROFILE_ID_FULFILLED,
  GET_ACCOMODATION_ITEM_BY_PROFILE_ID_PENDING,
  GET_ACCOMODATION_ITEM_BY_PROFILE_ID_REJECTED,
  //accomodation Item By Service Item Id
  GET_ACCOMODATION_ITEM_BY_SERVICE_TYPE_ID,
  GET_ACCOMODATION_ITEM_BY_SERVICE_TYPE_ID_FULFILLED,
  GET_ACCOMODATION_ITEM_BY_SERVICE_TYPE_ID_PENDING,
  GET_ACCOMODATION_ITEM_BY_SERVICE_TYPE_ID_REJECTED,
  // accomodation Item Types
  GET_ACCOMODATION_ITEM_TYPES,
  GET_ACCOMODATION_ITEM_TYPES_FULFILLED,
  GET_ACCOMODATION_ITEM_TYPES_PENDING,
  GET_ACCOMODATION_ITEM_TYPES_REJECTED,
  //accomodation Item Service Type List
  GET_ACCOMODATION_ITEM_SERVICE_TYPE_LIST,
  GET_ACCOMODATION_ITEM_SERVICE_TYPE_LIST_FULFILLED,
  GET_ACCOMODATION_ITEM_SERVICE_TYPE_LIST_PENDING,
  GET_ACCOMODATION_ITEM_SERVICE_TYPE_LIST_REJECTED,
  //Tour Pax Type
  GET_TOUR_PAX_TYPE,
  GET_TOUR_PAX_TYPE_FULFILLED,
  GET_TOUR_PAX_TYPE_PENDING,
  GET_TOUR_PAX_TYPE_REJECTED,
  //TOUR TYPE
  GET_TOUR_TYPE,
  GET_TOUR_TYPE_FULFILLED,
  GET_TOUR_TYPE_PENDING,
  GET_TOUR_TYPE_REJECTED,
  //Country
  GET_COUNTRY,
  GET_COUNTRY_FULFILLED,
  GET_COUNTRY_PENDING,
  GET_COUNTRY_REJECTED,
  RESET_COUNTRY,
  //Region By Country
  GET_REGION_BY_COUNTRY,
  GET_REGION_BY_COUNTRY_FULFILLED,
  GET_REGION_BY_COUNTRY_PENDING,
  GET_REGION_BY_COUNTRY_REJECTED,
  RESET_REGION,
  //City By Region
  GET_CITY_BY_REGION,
  GET_CITY_BY_REGION_FULFILLED,
  GET_CITY_BY_REGION_PENDING,
  GET_CITY_BY_REGION_REJECTED,
  RESET_CITY,
  //Area By City
  GET_AREA_BY_CITY,
  GET_AREA_BY_CITY_FULFILLED,
  GET_AREA_BY_CITY_PENDING,
  GET_AREA_BY_CITY_REJECTED,
  RESET_AREA,
  //Post Accomodation
  POST_ACCOMODATION,
  POST_ACCOMODATION_FULFILLED,
  POST_ACCOMODATION_PENDING,
  POST_ACCOMODATION_REJECTED,
  //DELETE ACCOMODATION
  DELETE_ACCOMODATION_BY_PROFILE_ID,
  DELETE_ACCOMODATION_BY_PROFILE_ID_FULFILLED,
  DELETE_ACCOMODATION_BY_PROFILE_ID_PENDING,
  DELETE_ACCOMODATION_BY_PROFILE_ID_REJECTED,
  //TEMPLATE ACCOMODATION
  GET_TEMPLATE_ACCOMODATION,
  GET_TEMPLATE_ACCOMODATION_FULFILLED,
  GET_TEMPLATE_ACCOMODATION_PENDING,
  GET_TEMPLATE_ACCOMODATION_REJECTED,
  //EXPORT ACCOMODATION
  GET_EXPORT_ACCOMODATION,
  GET_EXPORT_ACCOMODATION_FULFILLED,
  GET_EXPORT_ACCOMODATION_PENDING,
  GET_EXPORT_ACCOMODATION_REJECTED,
  //Put Accomodation Profile
  PUT_ACCOMODATION,
  PUT_ACCOMODATION_FULFILLED,
  PUT_ACCOMODATION_PENDING,
  PUT_ACCOMODATION_REJECTED,
  //RESTAURANT
  GET_RESTAURANT_LIST,
  GET_RESTAURANT_LIST_FULFILLED,
  GET_RESTAURANT_LIST_PENDING,
  GET_RESTAURANT_LIST_REJECTED,
  RESET_RESTAURANT,
  //GET RESTAURANT BY PROFILE ID
  GET_RESTAURANT_BY_PROFILE_ID,
  GET_RESTAURANT_BY_PROFILE_ID_FULFILLED,
  GET_RESTAURANT_BY_PROFILE_ID_PENDING,
  GET_RESTAURANT_BY_PROFILE_ID_REJECTED,
  //GET RESTAURANT ITEM BY PROFILE ID
  GET_RESTAURANT_ITEM_BY_PROFILE_ID,
  GET_RESTAURANT_ITEM_BY_PROFILE_ID_FULFILLED,
  GET_RESTAURANT_ITEM_BY_PROFILE_ID_PENDING,
  GET_RESTAURANT_ITEM_BY_PROFILE_ID_REJECTED,
  //DELETE RESTAURANT BY PROFILE ID
  DELETE_RESTAURANT_BY_PROFILE_ID,
  DELETE_RESTAURANT_BY_PROFILE_ID_FULFILLED,
  DELETE_RESTAURANT_BY_PROFILE_ID_PENDING,
  DELETE_RESTAURANT_BY_PROFILE_ID_REJECTED,
  //GET RESTAURANT CLASS
  GET_RESTAURANT_CLASS,
  GET_RESTAURANT_CLASS_FULFILLED,
  GET_RESTAURANT_CLASS_PENDING,
  GET_RESTAURANT_CLASS_REJECTED,
  GET_RESTAURANT_CATEGORY,
  GET_RESTAURANT_CATEGORY_FULFILLED,
  GET_RESTAURANT_CATEGORY_PENDING,
  GET_RESTAURANT_CATEGORY_REJECRED,
  //GET RESTAURANT TYPE
  GET_RESTAURANT_TYPE,
  GET_RESTAURANT_TYPE_FULFILLED,
  GET_RESTAURANT_TYPE_PENDING,
  GET_RESTAURANT_TYPE_REJECTED,
  GET_SERVICE_PRICE,
  GET_SERVICE_PRICE_PENDING,
  GET_SERVICE_PRICE_FULFILLED,
  GET_SERVICE_PRICE_REJECTED,
  POST_SERVICE_PRICE,
  POST_SERVICE_PRICE_PENDING,
  POST_SERVICE_PRICE_FULFILLED,
  POST_SERVICE_PRICE_REJECTED,
  PUT_SERVICE_PRICE,
  PUT_SERVICE_PRICE_PENDING,
  PUT_SERVICE_PRICE_REJECTED,
  PUT_SERVICE_PRICE_FULFILLED,
  GET_SERVICE_PRICE_TYPE,
  GET_SERVICE_PRICE_TYPE_FULFILLED,
  GET_SERVICE_PRICE_TYPE_PENDING,
  GET_SERVICE_PRICE_TYPE_REJECTED,
  RESET_STATUS_SERVICE_PRICE,
  DELETE_SERVICE_PRICE,
  DELETE_SERVICE_PRICE_PENDING,
  DELETE_SERVICE_PRICE_FULFILLED,
  DELETE_SERVICE_PRICE_REJECTED,
  PUT_ACCOMODATION_ITEM,
  PUT_ACCOMODATION_ITEM_FULFILLED,
  PUT_ACCOMODATION_ITEM_PENDING,
  PUT_ACCOMODATION_ITEM_REJECTED,
  POST_ACCOMODATION_ITEM,
  POST_ACCOMODATION_ITEM_FULFILLED,
  POST_ACCOMODATION_ITEM_PENDING,
  POST_ACCOMODATION_ITEM_REJECTED,
  // GET PLACE LIST
  GET_PLACE_LIST,
  GET_PLACE_LIST_FULFILLED,
  GET_PLACE_LIST_PENDING,
  GET_PLACE_LIST_REJECTED,
  RESET_PLACE,
  // GET REGION LIST
  GET_REGION_LIST,
  GET_REGION_LIST_FULFILLED,
  GET_REGION_LIST_PENDING,
  GET_REGION_LIST_REJECTED,
  // GET CITY LIST
  GET_CITY_LIST,
  GET_CITY_LIST_FULFILLED,
  GET_CITY_LIST_PENDING,
  GET_CITY_LIST_REJECTED,
  //GET AREA LIST
  GET_AREA_LIST,
  GET_AREA_LIST_FULFILLED,
  GET_AREA_LIST_PENDING,
  GET_AREA_LIST_REJECTED,
  //GET COUNTRY
  GET_COUNTRY_LIST,
  GET_COUNTRY_LIST_FULFILLED,
  GET_COUNTRY_LIST_PENDING,
  GET_COUNTRY_LIST_REJECTED,
  //DELETE PLACE
  DELETE_PLACE,
  DELETE_PLACE_FULFILLED,
  DELETE_PLACE_PENDING,
  DELETE_PLACE_REJECTED,
  //DELETE AREA
  DELETE_AREA,
  DELETE_AREA_FULFILLED,
  DELETE_AREA_PENDING,
  DELETE_AREA_REJECTED,
  //DELETE CITY
  DELETE_CITY,
  DELETE_CITY_FULFILLED,
  DELETE_CITY_PENDING,
  DELETE_CITY_REJECTED,
  //DELETE REGION
  DELETE_REGION,
  DELETE_REGION_FULFILLED,
  DELETE_REGION_PENDING,
  DELETE_REGION_REJECTED,
  //DELETE COUNTRY
  DELETE_COUNTRY,
  DELETE_COUNTRY_FULFILLED,
  DELETE_COUNTRY_PENDING,
  DELETE_COUNTRY_REJECTED,
  //GET BY ID PLACE
  GET_PLACE_BY_ID,
  GET_PLACE_BY_ID_FULFILLED,
  GET_PLACE_BY_ID_PENDING,
  GET_PLACE_BY_ID_REJECTED,
  //GET BY ID REGION
  GET_REGION_BY_ID,
  GET_REGION_BY_ID_FULFILLED,
  GET_REGION_BY_ID_PENDING,
  GET_REGION_BY_ID_REJECTED,
  //GET BY ID CITY
  GET_CITY_BY_ID,
  GET_CITY_BY_ID_FULFILLED,
  GET_CITY_BY_ID_PENDING,
  GET_CITY_BY_ID_REJECTED,
  //GET BY ID AREA
  GET_AREA_BY_ID,
  GET_AREA_BY_ID_FULFILLED,
  GET_AREA_BY_ID_PENDING,
  GET_AREA_BY_ID_REJECTED,
  //GET BY ID COUNTRY
  GET_COUNTRY_BY_ID,
  GET_COUNTRY_BY_ID_FULFILLED,
  GET_COUNTRY_BY_ID_PENDING,
  GET_COUNTRY_BY_ID_REJECTED,
  //POST AREA
  POST_AREA,
  POST_AREA_FULFILLED,
  POST_AREA_PENDING,
  POST_AREA_REJECTED,
  //PUT AREA
  PUT_AREA,
  PUT_AREA_FULFILLED,
  PUT_AREA_PENDING,
  PUT_AREA_REJECTED,
  //POST REGION
  POST_REGION,
  POST_REGION_FULFILLED,
  POST_REGION_PENDING,
  POST_REGION_REJECTED,
  //PUT REGION
  PUT_REGION,
  PUT_REGION_FULFILLED,
  PUT_REGION_PENDING,
  PUT_REGION_REJECTED,
  //POST CITY
  POST_CITY,
  POST_CITY_FULFILLED,
  POST_CITY_PENDING,
  POST_CITY_REJECTED,
  //PUT CITY
  PUT_CITY,
  PUT_CITY_FULFILLED,
  PUT_CITY_PENDING,
  PUT_CITY_REJECTED,
  //POST COUNTRY
  POST_COUNTRY,
  POST_COUNTRY_FULFILLED,
  POST_COUNTRY_PENDING,
  POST_COUNTRY_REJECTED,
  //PUT COUNTRY
  PUT_COUNTRY,
  PUT_COUNTRY_FULFILLED,
  PUT_COUNTRY_PENDING,
  PUT_COUNTRY_REJECTED,
  //POST PLACE
  POST_PLACE,
  POST_PLACE_FULFILLED,
  POST_PLACE_PENDING,
  POST_PLACE_REJECTED,
  //PUT PLACE
  PUT_PLACE,
  PUT_PLACE_FULFILLED,
  PUT_PLACE_PENDING,
  PUT_PLACE_REJECTED,
  //GET PLACE TYPE
  GET_PLACE_TYPE,
  GET_PLACE_TYPE_FULFILLED,
  GET_PLACE_TYPE_PENDING,
  GET_PLACE_TYPE_REJECTED,
  // POST RESTAURANT PROFILE
  POST_RESTAURANT_PROFILE,
  POST_RESTAURANT_PROFILE_FULFILLED,
  POST_RESTAURANT_PROFILE_PENDING,
  POST_RESTAURANT_PROFILE_REJECTED,
  //PUT RESTAURANT PROFILE
  PUT_RESTAURANT_PROFILE,
  PUT_RESTAURANT_PROFILE_FULFILLED,
  PUT_RESTAURANT_PROFILE_PENDING,
  PUT_RESTAURANT_PROFILE_REJECTED,
  GET_RESTAURANT_ITEM,
  GET_RESTAURANT_ITEM_PENDING,
  GET_RESTAURANT_ITEM_FULFILLED,
  GET_RESTAURANT_ITEM_REJECRED,
  GET_RESTAURANT_ITEM_BY_ID,
  GET_RESTAURANT_ITEM_BY_ID_PENDING,
  GET_RESTAURANT_ITEM_BY_ID_FULFILLED,
  GET_RESTAURANT_ITEM_BY_ID_REJECRED,
  POST_RESTAURANT_ITEM,
  POST_RESTAURANT_ITEM_PENDING,
  POST_RESTAURANT_ITEM_FULFILLED,
  POST_RESTAURANT_ITEM_REJECTED,
  PUT_RESTAURANT_ITEM,
  PUT_RESTAURANT_ITEM_PENDING,
  PUT_RESTAURANT_ITEM_FULFILLED,
  PUT_RESTAURANT_ITEM_REJECTED,
  GET_CUSTOM_ENUM_BY_CATAGORIES_REVENUE_RANGE,
  GET_CUSTOM_ENUM_BY_CATAGORIES_REVENUE_RANGE_FULFILLED,
  GET_CUSTOM_ENUM_BY_CATAGORIES_REVENUE_RANGE_PENDING,
  GET_CUSTOM_ENUM_BY_CATAGORIES_REVENUE_RANGE_REJECTED,
  GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_BRANCH,
  GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_BRANCH_FULFILLED,
  GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_BRANCH_PENDING,
  GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_BRANCH_REJECTED,
  GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_EMPLOYE,
  GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_EMPLOYE_FULFILLED,
  GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_EMPLOYE_PENDING,
  GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_EMPLOYE_REJECTED,
  RESET_CUSTOM_ENUM,
  PUT_CITY_GROUP_DETAIL,
  PUT_CITY_GROUP_DETAIL_FULFILLED,
  PUT_CITY_GROUP_DETAIL_PENDING,
  PUT_CITY_GROUP_DETAIL_REJECTED,
  GET_PAYMENT_METHOD,
  GET_PAYMENT_METHOD_FULFILLED,
  GET_PAYMENT_METHOD_PENDING,
  GET_PAYMENT_METHOD_REJECTED,
  POST_UPLOAD_PREVIEW_ACCOMODATION_ITEM,
  POST_UPLOAD_PREVIEW_ACCOMODATION_ITEM_FULFILLED,
  POST_UPLOAD_PREVIEW_ACCOMODATION_ITEM_PENDING,
  POST_UPLOAD_PREVIEW_ACCOMODATION_ITEM_REJECTED,
  POST_UPLOAD_COMMIT_ACCOMODATION_ITEM,
  POST_UPLOAD_COMMIT_ACCOMODATION_ITEM_FULFILLED,
  POST_UPLOAD_COMMIT_ACCOMODATION_ITEM_PENDING,
  POST_UPLOAD_COMMIT_ACCOMODATION_ITEM_REJECTED,
  POST_UPLOAD_PREVIEW_RESTAURANT_ITEM,
  POST_UPLOAD_PREVIEW_RESTAURANT_ITEM_FULFILLED,
  POST_UPLOAD_PREVIEW_RESTAURANT_ITEM_PENDING,
  POST_UPLOAD_PREVIEW_RESTAURANT_ITEM_REJECTED,
  POST_UPLOAD_COMMIT_RESTAURANT_ITEM,
  POST_UPLOAD_COMMIT_RESTAURANT_ITEM_FULFILLED,
  POST_UPLOAD_COMMIT_RESTAURANT_ITEM_PENDING,
  POST_UPLOAD_COMMIT_RESTAURANT_ITEM_REJECTED,
  POST_UPLOAD_PREVIEW_RESTAURANT_PRICE,
  POST_UPLOAD_PREVIEW_RESTAURANT_PRICE_FULFILLED,
  POST_UPLOAD_PREVIEW_RESTAURANT_PRICE_PENDING,
  POST_UPLOAD_PREVIEW_RESTAURANT_PRICE_REJECTED,
  POST_UPLOAD_COMMIT_RESTAURANT_PRICE,
  POST_UPLOAD_COMMIT_RESTAURANT_PRICE_FULFILLED,
  POST_UPLOAD_COMMIT_RESTAURANT_PRICE_PENDING,
  POST_UPLOAD_COMMIT_RESTAURANT_PRICE_REJECTED,
  POST_UPLOAD_PREVIEW_EXCURSION_PRICE,
  POST_UPLOAD_PREVIEW_EXCURSION_PRICE_FULFILLED,
  POST_UPLOAD_PREVIEW_EXCURSION_PRICE_PENDING,
  POST_UPLOAD_PREVIEW_EXCURSION_PRICE_REJECTED,
  POST_UPLOAD_COMMIT_EXCURSION_PRICE,
  POST_UPLOAD_COMMIT_EXCURSION_PRICE_FULFILLED,
  POST_UPLOAD_COMMIT_EXCURSION_PRICE_PENDING,
  POST_UPLOAD_COMMIT_EXCURSION_PRICE_REJECTED,
  GET_EXCURSION_OBJECT_EXPORT_DATA,
  GET_EXCURSION_OBJECT_EXPORT_DATA_FULFILLED,
  GET_EXCURSION_OBJECT_EXPORT_DATA_PENDING,
  GET_EXCURSION_OBJECT_EXPORT_DATA_REJECTED,
  GET_EXCURSION_PRICE_EXPORT_DATA,
  GET_EXCURSION_PRICE_EXPORT_DATA_FULFILLED,
  GET_EXCURSION_PRICE_EXPORT_DATA_PENDING,
  GET_EXCURSION_PRICE_EXPORT_DATA_REJECTED,
  GET_RESTAURANT_ITEM_EXPORT_DATA,
  GET_RESTAURANT_ITEM_EXPORT_DATA_FULFILLED,
  GET_RESTAURANT_ITEM_EXPORT_DATA_PENDING,
  GET_RESTAURANT_ITEM_EXPORT_DATA_REJECTED,
  GET_RESTAURANT_ITEM_PRICE_EXPORT_DATA,
  GET_RESTAURANT_ITEM_PRICE_EXPORT_DATA_FULFILLED,
  GET_RESTAURANT_ITEM_PRICE_EXPORT_DATA_PENDING,
  GET_RESTAURANT_ITEM_PRICE_EXPORT_DATA_REJECTED,
  GET_ACCOMODATION_ITEM_EXPORT_DATA,
  GET_ACCOMODATION_ITEM_EXPORT_DATA_FULFILLED,
  GET_ACCOMODATION_ITEM_EXPORT_DATA_PENDING,
  GET_ACCOMODATION_ITEM_EXPORT_DATA_REJECTED,
  GET_TEMPLATE_EXCURSION_OBJECT_DATA,
  GET_TEMPLATE_EXCURSION_OBJECT_DATA_FULFILLED,
  GET_TEMPLATE_EXCURSION_OBJECT_DATA_PENDING,
  GET_TEMPLATE_EXCURSION_OBJECT_DATA_REJECTED,
  GET_TEMPLATE_EXCURSION_PRICE_DATA,
  GET_TEMPLATE_EXCURSION_PRICE_DATA_FULFILLED,
  GET_TEMPLATE_EXCURSION_PRICE_DATA_PENDING,
  GET_TEMPLATE_EXCURSION_PRICE_DATA_REJECTED,
  GET_TEMPLATE_RESTAURANT_ITEM_DATA,
  GET_TEMPLATE_RESTAURANT_ITEM_DATA_FULFILLED,
  GET_TEMPLATE_RESTAURANT_ITEM_DATA_PENDING,
  GET_TEMPLATE_RESTAURANT_ITEM_DATA_REJECTED,
  GET_TEMPLATE_RESTAURANT_ITEM_PRICE_DATA,
  GET_TEMPLATE_RESTAURANT_ITEM_PRICE_DATA_FULFILLED,
  GET_TEMPLATE_RESTAURANT_ITEM_PRICE_DATA_PENDING,
  GET_TEMPLATE_RESTAURANT_ITEM_PRICE_DATA_REJECTED,
  GET_TEMPLATE_RESTAURANT_PROFILE_DATA,
  GET_TEMPLATE_RESTAURANT_PROFILE_DATA_FULFILLED,
  GET_TEMPLATE_RESTAURANT_PROFILE_DATA_PENDING,
  GET_TEMPLATE_RESTAURANT_PROFILE_DATA_REJECTED,
  GET_TEMPLATE_ACCOMODATION_ITEM_DATA,
  GET_TEMPLATE_ACCOMODATION_ITEM_DATA_FULFILLED,
  GET_TEMPLATE_ACCOMODATION_ITEM_DATA_PENDING,
  GET_TEMPLATE_ACCOMODATION_ITEM_DATA_REJECTED,
  GET_ACCOMMODATION_BY_CITY,
  GET_ACCOMMODATION_BY_CITY_PENDING,
  GET_ACCOMMODATION_BY_CITY_FULFILLED,
  GET_ACCOMMODATION_BY_CITY_REJECTED,
  DELETE_PAYMENT_METHOD_IN_COUNTRY,
  DELETE_PAYMENT_METHOD_IN_COUNTRY_FULFILLED,
  DELETE_PAYMENT_METHOD_IN_COUNTRY_PENDING,
  DELETE_PAYMENT_METHOD_IN_COUNTRY_REJECTED,
  GET_PAYMENT_METHOD_IN_COUNTRY,
  GET_PAYMENT_METHOD_IN_COUNTRY_FULFILLED,
  GET_PAYMENT_METHOD_IN_COUNTRY_PENDING,
  GET_PAYMENT_METHOD_IN_COUNTRY_REJECTED,
  POST_PAYMENT_METHOD_IN_COUNTRY,
  POST_PAYMENT_METHOD_IN_COUNTRY_FULFILLED,
  POST_PAYMENT_METHOD_IN_COUNTRY_PENDING,
  POST_PAYMENT_METHOD_IN_COUNTRY_REJECTED,
  GET_IMAGE_ALL,
  GET_IMAGE_ALL_FULFILLED,
  GET_IMAGE_ALL_PENDING,
  GET_IMAGE_ALL_REJECTED,
  GET_IMAGE_ALL_BY_ID,
  GET_IMAGE_ALL_BY_ID_FULFILLED,
  GET_IMAGE_ALL_BY_ID_PENDING,
  GET_IMAGE_ALL_BY_ID_REJECTED,
  POST_IMAGE,
  POST_IMAGE_FULFILLED,
  POST_IMAGE_PENDING,
  POST_IMAGE_REJECTED,
  PUT_IMAGE,
  PUT_IMAGE_FULFILLED,
  PUT_IMAGE_PENDING,
  PUT_IMAGE_REJECTED,
  DELETE_IMAGE,
  DELETE_IMAGE_FULFILLED,
  DELETE_IMAGE_PENDING,
  DELETE_IMAGE_REJECTED,
  RESET_IMAGE,
  POST_READY_PACKAGE,
  POST_READY_PACKAGE_FULFILLED,
  POST_READY_PACKAGE_PENDING,
  POST_READY_PACKAGE_REJECTED,
  RESET_READY_PACKAGE,
  GET_READY_PACKAGE_DETAIL,
  GET_READY_PACKAGE_DETAIL_FULFILLED,
  GET_READY_PACKAGE_DETAIL_PENDING,
  GET_READY_PACKAGE_DETAIL_REJECTED,
  GET_READY_PACKAGE,
  GET_READY_PACKAGE_FULFILLED,
  GET_READY_PACKAGE_REJECTED,
  GET_READY_PACKAGE_PENDING,
  GET_PACKAGE_BY_TYPE_AND_ID,
  GET_PACKAGE_BY_TYPE_AND_ID_FULFILLED,
  GET_PACKAGE_BY_TYPE_AND_ID_PENDING,
  GET_PACKAGE_BY_TYPE_AND_ID_REJECTED,
  GET_PAYMENT_METHOD_BY_COUNTRY_ID,
  GET_PAYMENT_METHOD_BY_COUNTRY_ID_FULFILLED,
  GET_PAYMENT_METHOD_BY_COUNTRY_ID_PENDING,
  GET_PAYMENT_METHOD_BY_COUNTRY_ID_REJECTED,
  GET_DEST_CITY_BY_REGION,
  GET_DEST_CITY_BY_REGION_PENDING,
  GET_DEST_CITY_BY_REGION_FULFILLED,
  GET_DEST_CITY_BY_REGION_REJECTED,
  GET_ORIGIN_CITY_BY_REGION,
  GET_ORIGIN_CITY_BY_REGION_PENDING,
  GET_ORIGIN_CITY_BY_REGION_REJECTED,
  GET_ORIGIN_CITY_BY_REGION_FULFILLED,
  GET_FIXED_PACKAGE,
  GET_FIXED_PACKAGE_FULFILLED,
  GET_FIXED_PACKAGE_PENDING,
  GET_FIXED_PACKAGE_REJECTED,
  GET_PACKAGES,
  GET_PACKAGES_FULFILLED,
  GET_PACKAGES_PENDING,
  GET_PACKAGES_REJECTED,
  GET_MASTER_REFERENCE_ACCOMODATION_FACILITIES,
  GET_MASTER_REFERENCE_ACCOMODATION_FACILITIES_FULFILLED,
  GET_MASTER_REFERENCE_ACCOMODATION_FACILITIES_PENDING,
  GET_MASTER_REFERENCE_ACCOMODATION_FACILITIES_REJECTED,
  RESET_MASTER_REFERENCE,
  DELETE_MASTER_REFERENCE_ACCOMODATION_FACILITIES,
  DELETE_MASTER_REFERENCE_ACCOMODATION_FACILITIES_FULFILLED,
  DELETE_MASTER_REFERENCE_ACCOMODATION_FACILITIES_PENDING,
  DELETE_MASTER_REFERENCE_ACCOMODATION_FACILITIES_REJECTED,
  POST_MASTER_REFERENCE_ACCOMODATION_FACILITIES,
  POST_MASTER_REFERENCE_ACCOMODATION_FACILITIES_FULFILLED,
  POST_MASTER_REFERENCE_ACCOMODATION_FACILITIES_PENDING,
  POST_MASTER_REFERENCE_ACCOMODATION_FACILITIES_REJECTED,
  PUT_MASTER_REFERENCE_ACCOMODATION_FACILITIES,
  PUT_MASTER_REFERENCE_ACCOMODATION_FACILITIES_FULFILLED,
  PUT_MASTER_REFERENCE_ACCOMODATION_FACILITIES_PENDING,
  PUT_MASTER_REFERENCE_ACCOMODATION_FACILITIES_REJECTED,
  GET_MASTER_REFERENCE_ACCOMODATION_TYPE,
  GET_MASTER_REFERENCE_ACCOMODATION_TYPE_FULFILLED,
  GET_MASTER_REFERENCE_ACCOMODATION_TYPE_PENDING,
  GET_MASTER_REFERENCE_ACCOMODATION_TYPE_REJECTED,
  DELETE_MASTER_REFERENCE_ACCOMODATION_TYPE,
  DELETE_MASTER_REFERENCE_ACCOMODATION_TYPE_FULFILLED,
  DELETE_MASTER_REFERENCE_ACCOMODATION_TYPE_PENDING,
  DELETE_MASTER_REFERENCE_ACCOMODATION_TYPE_REJECTED,
  POST_MASTER_REFERENCE_ACCOMODATION_TYPE,
  POST_MASTER_REFERENCE_ACCOMODATION_TYPE_FULFILLED,
  POST_MASTER_REFERENCE_ACCOMODATION_TYPE_PENDING,
  POST_MASTER_REFERENCE_ACCOMODATION_TYPE_REJECTED,
  PUT_MASTER_REFERENCE_ACCOMODATION_TYPE,
  PUT_MASTER_REFERENCE_ACCOMODATION_TYPE_FULFILLED,
  PUT_MASTER_REFERENCE_ACCOMODATION_TYPE_PENDING,
  PUT_MASTER_REFERENCE_ACCOMODATION_TYPE_REJECTED,
  POST_DEMO_MANUAL_PAYMENT,
  POST_DEMO_MANUAL_PAYMENT_FULFILLED,
  POST_DEMO_MANUAL_PAYMENT_PENDING,
  POST_DEMO_MANUAL_PAYMENT_REJECTED,
  POST_MANUAL_PAYMENT,
  POST_MANUAL_PAYMENT_FULFILLED,
  POST_MANUAL_PAYMENT_PENDING,
  POST_MANUAL_PAYMENT_REJECTED,
  GET_PAYABLE_SUPPLEMENT_ITEM,
  GET_PAYABLE_SUPPLEMENT_ITEM_FULFILLED,
  GET_PAYABLE_SUPPLEMENT_ITEM_PENDING,
  GET_PAYABLE_SUPPLEMENT_ITEM_REJECTED,
  GET_MASTER_REFERENCE_ACCOMODATION_LOCATION,
  GET_MASTER_REFERENCE_ACCOMODATION_LOCATION_FULFILLED,
  GET_MASTER_REFERENCE_ACCOMODATION_LOCATION_PENDING,
  GET_MASTER_REFERENCE_ACCOMODATION_LOCATION_REJECTED,
  DELETE_MASTER_REFERENCE_ACCOMODATION_LOCATION,
  DELETE_MASTER_REFERENCE_ACCOMODATION_LOCATION_FULFILLED,
  DELETE_MASTER_REFERENCE_ACCOMODATION_LOCATION_PENDING,
  DELETE_MASTER_REFERENCE_ACCOMODATION_LOCATION_REJECTED,
  POST_MASTER_REFERENCE_ACCOMODATION_LOCATION,
  POST_MASTER_REFERENCE_ACCOMODATION_LOCATION_FULFILLED,
  POST_MASTER_REFERENCE_ACCOMODATION_LOCATION_PENDING,
  POST_MASTER_REFERENCE_ACCOMODATION_LOCATION_REJECTED,
  PUT_MASTER_REFERENCE_ACCOMODATION_LOCATION,
  PUT_MASTER_REFERENCE_ACCOMODATION_LOCATION_FULFILLED,
  PUT_MASTER_REFERENCE_ACCOMODATION_LOCATION_PENDING,
  PUT_MASTER_REFERENCE_ACCOMODATION_LOCATION_REJECTED,
  GET_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE,
  GET_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_FULFILLED,
  GET_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_PENDING,
  GET_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_REJECTED,
  DELETE_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE,
  DELETE_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_FULFILLED,
  DELETE_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_PENDING,
  DELETE_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_REJECTED,
  POST_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE,
  POST_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_FULFILLED,
  POST_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_PENDING,
  POST_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_REJECTED,
  PUT_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE,
  PUT_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_FULFILLED,
  PUT_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_PENDING,
  PUT_MASTER_REFERENCE_ACCOMODATION_ITEM_TYPE_REJECTED,
  GET_MASTER_REFERENCE_ACCOMODATION_RATING,
  GET_MASTER_REFERENCE_ACCOMODATION_RATING_FULFILLED,
  GET_MASTER_REFERENCE_ACCOMODATION_RATING_PENDING,
  GET_MASTER_REFERENCE_ACCOMODATION_RATING_REJECTED,
  DELETE_MASTER_REFERENCE_ACCOMODATION_RATING,
  DELETE_MASTER_REFERENCE_ACCOMODATION_RATING_FULFILLED,
  DELETE_MASTER_REFERENCE_ACCOMODATION_RATING_PENDING,
  DELETE_MASTER_REFERENCE_ACCOMODATION_RATING_REJECTED,
  POST_MASTER_REFERENCE_ACCOMODATION_RATING,
  POST_MASTER_REFERENCE_ACCOMODATION_RATING_FULFILLED,
  POST_MASTER_REFERENCE_ACCOMODATION_RATING_PENDING,
  POST_MASTER_REFERENCE_ACCOMODATION_RATING_REJECTED,
  PUT_MASTER_REFERENCE_ACCOMODATION_RATING,
  PUT_MASTER_REFERENCE_ACCOMODATION_RATING_FULFILLED,
  PUT_MASTER_REFERENCE_ACCOMODATION_RATING_PENDING,
  PUT_MASTER_REFERENCE_ACCOMODATION_RATING_REJECTED,
  GET_MASTER_REFERENCE_EXCURSION_CATEGORIES,
  GET_MASTER_REFERENCE_EXCURSION_CATEGORIES_FULFILLED,
  GET_MASTER_REFERENCE_EXCURSION_CATEGORIES_PENDING,
  GET_MASTER_REFERENCE_EXCURSION_CATEGORIES_REJECTED,
  DELETE_MASTER_REFERENCE_EXCURSION_CATEGORIES,
  DELETE_MASTER_REFERENCE_EXCURSION_CATEGORIES_FULFILLED,
  DELETE_MASTER_REFERENCE_EXCURSION_CATEGORIES_PENDING,
  DELETE_MASTER_REFERENCE_EXCURSION_CATEGORIES_REJECTED,
  POST_MASTER_REFERENCE_EXCURSION_CATEGORIES,
  POST_MASTER_REFERENCE_EXCURSION_CATEGORIES_FULFILLED,
  POST_MASTER_REFERENCE_EXCURSION_CATEGORIES_PENDING,
  POST_MASTER_REFERENCE_EXCURSION_CATEGORIES_REJECTED,
  PUT_MASTER_REFERENCE_EXCURSION_CATEGORIES,
  PUT_MASTER_REFERENCE_EXCURSION_CATEGORIES_FULFILLED,
  PUT_MASTER_REFERENCE_EXCURSION_CATEGORIES_PENDING,
  PUT_MASTER_REFERENCE_EXCURSION_CATEGORIES_REJECTED,
  GET_MASTER_REFERENCE_EXCURSION_TYPE,
  GET_MASTER_REFERENCE_EXCURSION_TYPE_FULFILLED,
  GET_MASTER_REFERENCE_EXCURSION_TYPE_PENDING,
  GET_MASTER_REFERENCE_EXCURSION_TYPE_REJECTED,
  DELETE_MASTER_REFERENCE_EXCURSION_TYPE,
  DELETE_MASTER_REFERENCE_EXCURSION_TYPE_FULFILLED,
  DELETE_MASTER_REFERENCE_EXCURSION_TYPE_PENDING,
  DELETE_MASTER_REFERENCE_EXCURSION_TYPE_REJECTED,
  POST_MASTER_REFERENCE_EXCURSION_TYPE,
  POST_MASTER_REFERENCE_EXCURSION_TYPE_FULFILLED,
  POST_MASTER_REFERENCE_EXCURSION_TYPE_PENDING,
  POST_MASTER_REFERENCE_EXCURSION_TYPE_REJECTED,
  PUT_MASTER_REFERENCE_EXCURSION_TYPE,
  PUT_MASTER_REFERENCE_EXCURSION_TYPE_FULFILLED,
  PUT_MASTER_REFERENCE_EXCURSION_TYPE_PENDING,
  PUT_MASTER_REFERENCE_EXCURSION_TYPE_REJECTED,
  GET_MASTER_REFERENCE_CURRENCIES,
  GET_MASTER_REFERENCE_CURRENCIES_FULFILLED,
  GET_MASTER_REFERENCE_CURRENCIES_PENDING,
  GET_MASTER_REFERENCE_CURRENCIES_REJECTED,
  DELETE_MASTER_REFERENCE_CURRENCIES,
  DELETE_MASTER_REFERENCE_CURRENCIES_FULFILLED,
  DELETE_MASTER_REFERENCE_CURRENCIES_PENDING,
  DELETE_MASTER_REFERENCE_CURRENCIES_REJECTED,
  POST_MASTER_REFERENCE_CURRENCIES,
  POST_MASTER_REFERENCE_CURRENCIES_FULFILLED,
  POST_MASTER_REFERENCE_CURRENCIES_PENDING,
  POST_MASTER_REFERENCE_CURRENCIES_REJECTED,
  PUT_MASTER_REFERENCE_CURRENCIES,
  PUT_MASTER_REFERENCE_CURRENCIES_FULFILLED,
  PUT_MASTER_REFERENCE_CURRENCIES_PENDING,
  PUT_MASTER_REFERENCE_CURRENCIES_REJECTED,
  GET_MASTER_REFERENCE_GUEST_CATEGORIES,
  GET_MASTER_REFERENCE_GUEST_CATEGORIES_FULFILLED,
  GET_MASTER_REFERENCE_GUEST_CATEGORIES_PENDING,
  GET_MASTER_REFERENCE_GUEST_CATEGORIES_REJECTED,
  DELETE_MASTER_REFERENCE_GUEST_CATEGORIES,
  DELETE_MASTER_REFERENCE_GUEST_CATEGORIES_FULFILLED,
  DELETE_MASTER_REFERENCE_GUEST_CATEGORIES_PENDING,
  DELETE_MASTER_REFERENCE_GUEST_CATEGORIES_REJECTED,
  POST_MASTER_REFERENCE_GUEST_CATEGORIES,
  POST_MASTER_REFERENCE_GUEST_CATEGORIES_FULFILLED,
  POST_MASTER_REFERENCE_GUEST_CATEGORIES_PENDING,
  POST_MASTER_REFERENCE_GUEST_CATEGORIES_REJECTED,
  PUT_MASTER_REFERENCE_GUEST_CATEGORIES,
  PUT_MASTER_REFERENCE_GUEST_CATEGORIES_FULFILLED,
  PUT_MASTER_REFERENCE_GUEST_CATEGORIES_PENDING,
  PUT_MASTER_REFERENCE_GUEST_CATEGORIES_REJECTED,
  GET_MASTER_REFERENCE_GUEST_IDENTITY_TYPE,
  GET_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_FULFILLED,
  GET_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_PENDING,
  GET_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_REJECTED,
  DELETE_MASTER_REFERENCE_GUEST_IDENTITY_TYPE,
  DELETE_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_FULFILLED,
  DELETE_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_PENDING,
  DELETE_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_REJECTED,
  POST_MASTER_REFERENCE_GUEST_IDENTITY_TYPE,
  POST_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_FULFILLED,
  POST_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_PENDING,
  POST_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_REJECTED,
  PUT_MASTER_REFERENCE_GUEST_IDENTITY_TYPE,
  PUT_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_FULFILLED,
  PUT_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_PENDING,
  PUT_MASTER_REFERENCE_GUEST_IDENTITY_TYPE_REJECTED,
  GET_MASTER_REFERENCE_GUEST_TITLE,
  GET_MASTER_REFERENCE_GUEST_TITLE_FULFILLED,
  GET_MASTER_REFERENCE_GUEST_TITLE_PENDING,
  GET_MASTER_REFERENCE_GUEST_TITLE_REJECTED,
  DELETE_MASTER_REFERENCE_GUEST_TITLE,
  DELETE_MASTER_REFERENCE_GUEST_TITLE_FULFILLED,
  DELETE_MASTER_REFERENCE_GUEST_TITLE_PENDING,
  DELETE_MASTER_REFERENCE_GUEST_TITLE_REJECTED,
  POST_MASTER_REFERENCE_GUEST_TITLE,
  POST_MASTER_REFERENCE_GUEST_TITLE_FULFILLED,
  POST_MASTER_REFERENCE_GUEST_TITLE_PENDING,
  POST_MASTER_REFERENCE_GUEST_TITLE_REJECTED,
  PUT_MASTER_REFERENCE_GUEST_TITLE,
  PUT_MASTER_REFERENCE_GUEST_TITLE_FULFILLED,
  PUT_MASTER_REFERENCE_GUEST_TITLE_PENDING,
  PUT_MASTER_REFERENCE_GUEST_TITLE_REJECTED,
  GET_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION,
  GET_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_FULFILLED,
  GET_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_PENDING,
  GET_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_REJECTED,
  DELETE_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION,
  DELETE_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_FULFILLED,
  DELETE_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_PENDING,
  DELETE_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_REJECTED,
  POST_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION,
  POST_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_FULFILLED,
  POST_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_PENDING,
  POST_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_REJECTED,
  PUT_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION,
  PUT_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_FULFILLED,
  PUT_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_PENDING,
  PUT_MASTER_REFERENCE_IDENTITY_TYPE_CONFIGURATION_REJECTED,
  POST_UNLOCK_USER,
  POST_UNLOCK_USER_FULFILLED,
  POST_UNLOCK_USER_PENDING,
  POST_UNLOCK_USER_REJECTED,
  GET_MASTER_REFERENCE,
  GET_MASTER_REFERENCE_FULFILLED,
  GET_MASTER_REFERENCE_PENDING,
  GET_MASTER_REFERENCE_REJECTED,
  DELETE_MASTER_REFERENCE,
  DELETE_MASTER_REFERENCE_FULFILLED,
  DELETE_MASTER_REFERENCE_PENDING,
  DELETE_MASTER_REFERENCE_REJECTED,
  POST_MASTER_REFERENCE,
  POST_MASTER_REFERENCE_FULFILLED,
  POST_MASTER_REFERENCE_PENDING,
  POST_MASTER_REFERENCE_REJECTED,
  PUT_MASTER_REFERENCE,
  PUT_MASTER_REFERENCE_FULFILLED,
  PUT_MASTER_REFERENCE_PENDING,
  PUT_MASTER_REFERENCE_REJECTED,
  GET_TRANSACTION_PAYMENT_TERM_BY_TRANSACTION_ID,
  GET_TRANSACTION_PAYMENT_TERM_BY_TRANSACTION_ID_FULFILLED,
  GET_TRANSACTION_PAYMENT_TERM_BY_TRANSACTION_ID_PENDING,
  GET_TRANSACTION_PAYMENT_TERM_BY_TRANSACTION_ID_REJECTED,
  GET_USER_PROFILE_BY_USER_ID,
  GET_USER_PROFILE_BY_USER_ID_FULFILLED,
  GET_USER_PROFILE_BY_USER_ID_PENDING,
  GET_USER_PROFILE_BY_USER_ID_REJECTED,
  POST_RESET_PASSWORD,
  POST_RESET_PASSWORD_FULFILLED,
  POST_RESET_PASSWORD_PENDING,
  POST_RESET_PASSWORD_REJECTED,
  GET_PAYMENT_METHOD_CHOICE_LIST,
  GET_PAYMENT_METHOD_CHOICE_LIST_FULFILLED,
  GET_PAYMENT_METHOD_CHOICE_LIST_PENDING,
  GET_PAYMENT_METHOD_CHOICE_LIST_REJECTED,
  GET_EXPORT_RESTAURANT_PROFILE,
  GET_EXPORT_RESTAURANT_PROFILE_FULFILLED,
  GET_EXPORT_RESTAURANT_PROFILE_PENDING,
  GET_EXPORT_RESTAURANT_PROFILE_REJECTED,
  POST_UPLOAD_IMAGE_ACCOMODATION_PROFILE,
  POST_UPLOAD_IMAGE_ACCOMODATION_PROFILE_FULFILLED,
  POST_UPLOAD_IMAGE_ACCOMODATION_PROFILE_PENDING,
  POST_UPLOAD_IMAGE_ACCOMODATION_PROFILE_REJECTED,
  POST_UPLOAD_IMAGE_EXCURSION_OBJECT,
  POST_UPLOAD_IMAGE_EXCURSION_OBJECT_FULFILLED,
  POST_UPLOAD_IMAGE_EXCURSION_OBJECT_PENDING,
  POST_UPLOAD_IMAGE_EXCURSION_OBJECT_REJECTED,
  POST_UPLOAD_IMAGE_ACCOMODATION_ITEM,
  POST_UPLOAD_IMAGE_ACCOMODATION_ITEM_FULFILLED,
  POST_UPLOAD_IMAGE_ACCOMODATION_ITEM_PENDING,
  POST_UPLOAD_IMAGE_ACCOMODATION_ITEM_REJECTED,
  POST_UPLOAD_IMAGE_RESTAURANT_ITEM,
  POST_UPLOAD_IMAGE_RESTAURANT_ITEM_FULFILLED,
  POST_UPLOAD_IMAGE_RESTAURANT_ITEM_PENDING,
  POST_UPLOAD_IMAGE_RESTAURANT_ITEM_REJECTED,
  GET_ACCOMODATION_ITEM_ALL,
  GET_ACCOMODATION_ITEM_ALL_FULFILLED,
  GET_ACCOMODATION_ITEM_ALL_PENDING,
  GET_ACCOMODATION_ITEM_ALL_REJECTED,
  GET_RESTAURANT_ITEM_ALL,
  GET_RESTAURANT_ITEM_ALL_FULFILLED,
  GET_RESTAURANT_ITEM_ALL_PENDING,
  GET_RESTAURANT_ITEM_ALL_REJECTED,
  POST_UPLOAD_IMAGE_RESTAURANT_PROFILE,
  POST_UPLOAD_IMAGE_RESTAURANT_PROFILE_FULFILLED,
  POST_UPLOAD_IMAGE_RESTAURANT_PROFILE_PENDING,
  POST_UPLOAD_IMAGE_RESTAURANT_PROFILE_REJECTED,
  POST_USER,
  POST_USER_FULFILLED,
  POST_USER_PENDING,
  POST_USER_REJECTED,
  POST_USER_PROFILE,
  POST_USER_PROFILE_FULFILLED,
  POST_USER_PROFILE_PENDING,
  POST_USER_PROFILE_REJECTED,
  POST_TOUR_TRANSACTION_CONFIRMATION,
  POST_TOUR_TRANSACTION_CONFIRMATION_FULFILLED,
  POST_TOUR_TRANSACTION_CONFIRMATION_PENDING,
  POST_TOUR_TRANSACTION_CONFIRMATION_REJECTED,
  POST_TOP_UP_PAYMENT_GATEWAY,
  POST_TOP_UP_PAYMENT_GATEWAY_FULFILLED,
  POST_TOP_UP_PAYMENT_GATEWAY_PENDING,
  POST_TOP_UP_PAYMENT_GATEWAY_REJECTED,
  GET_USER_ROLE_LIST,
  GET_USER_ROLE_LIST_FULFILLED,
  GET_USER_ROLE_LIST_PENDING,
  GET_USER_ROLE_LIST_REJECTED,
  GET_USER_NOT_EMPLOYEE,
  GET_USER_NOT_EMPLOYEE_FULFILLED,
  GET_USER_NOT_EMPLOYEE_PENDING,
  GET_USER_NOT_EMPLOYEE_REJECTED,
  PUT_USER_PROFILE,
  PUT_USER_PROFILE_FULFILLED,
  PUT_USER_PROFILE_PENDING,
  PUT_USER_PROFILE_REJECTED,
  GET_USER_ROLE_BY_USER_ID,
  GET_USER_ROLE_BY_USER_ID_FULFILLED,
  GET_USER_ROLE_BY_USER_ID_PENDING,
  GET_USER_ROLE_BY_USER_ID_REJECTED,
  POST_ADD_NEW_ROLE_BY_USER_ID,
  POST_ADD_NEW_ROLE_BY_USER_ID_FULFILLED,
  POST_ADD_NEW_ROLE_BY_USER_ID_PENDING,
  POST_ADD_NEW_ROLE_BY_USER_ID_REJECTED,
  DELETE_ROLE_BY_USER_ID,
  DELETE_ROLE_BY_USER_ID_FULFILLED,
  DELETE_ROLE_BY_USER_ID_PENDING,
  DELETE_ROLE_BY_USER_ID_REJECTED,
  GET_INVOICE_STATUS_USING_PAYMENT_GATEWAY,
  GET_INVOICE_STATUS_USING_PAYMENT_GATEWAY_FULFILLED,
  GET_INVOICE_STATUS_USING_PAYMENT_GATEWAY_PENDING,
  GET_INVOICE_STATUS_USING_PAYMENT_GATEWAY_REJECTED,
  GET_SPECIAL_ADJUSMENT_BY_TRANSACTION_ID,
  GET_SPECIAL_ADJUSMENT_BY_TRANSACTION_ID_REJECTED,
  GET_SPECIAL_ADJUSMENT_BY_TRANSACTION_ID_PENDING,
  GET_SPECIAL_ADJUSMENT_BY_TRANSACTION_ID_FULFILLED,
  GET_TOUR_TRANSACTION_BILLED_BY_TRANSACTION_ID,
  GET_TOUR_TRANSACTION_BILLED_BY_TRANSACTION_ID_FULFILLED,
  GET_TOUR_TRANSACTION_BILLED_BY_TRANSACTION_ID_PENDING,
  GET_TOUR_TRANSACTION_BILLED_BY_TRANSACTION_ID_REJECTED,
  GET_TOUR_TRANSACTION_BILLS_BY_TRANSACTION_ID,
  GET_TOUR_TRANSACTION_BILLS_BY_TRANSACTION_ID_FULFILLED,
  GET_TOUR_TRANSACTION_BILLS_BY_TRANSACTION_ID_PENDING,
  GET_TOUR_TRANSACTION_BILLS_BY_TRANSACTION_ID_REJECTED,
  GET_EXCURSION_PROFILE_BY_CITY,
  GET_EXCURSION_PROFILE_BY_CITY_PENDING,
  GET_EXCURSION_PROFILE_BY_CITY_FULFILLED,
  GET_EXCURSION_PROFILE_BY_CITY_REJECTED,
  GET_ACTIVITY_LIST,
  GET_ACTIVITY_LIST_PENDING,
  GET_ACTIVITY_LIST_FULFILLED,
  GET_ACTIVITY_LIST_REJECTED,
  GET_AIRPORT_BY_CITY,
  GET_AIRPORT_BY_CITY_PENDING,
  GET_AIRPORT_BY_CITY_FULFILLED,
  GET_AIRPORT_BY_CITY_REJECTED,
  GET_TOUR_OPERATOR_PROFILE,
  GET_TOUR_OPERATOR_PROFILE_PENDING,
  GET_TOUR_OPERATOR_PROFILE_FULFILLED,
  GET_TOUR_OPERATOR_PROFILE_REJECTED,
  POST_PACKAGE,
  POST_PACKAGE_PENDING,
  POST_PACKAGE_FULFILLED,
  POST_PACKAGE_REJECTED,
  PUT_PACKAGE,
  PUT_PACKAGE_PENDING,
  PUT_PACKAGE_FULFILLED,
  PUT_PACKAGE_REJECTED,
  GET_DURATION_ID_TO_ID,
  GET_DURATION_ID_TO_ID_FULFILLED,
  GET_DURATION_ID_TO_ID_PENDING,
  GET_DURATION_ID_TO_ID_REJECTED,
  GET_DURATION_ID_TO_ADDRESS,
  GET_DURATION_ID_TO_ADDRESS_FULFILLED,
  GET_DURATION_ID_TO_ADDRESS_PENDING,
  GET_DURATION_ID_TO_ADDRESS_REJECTED,
  GET_DURATION_ADDRESS_TO_ID,
  GET_DURATION_ADDRESS_TO_ID_FULFILLED,
  GET_DURATION_ADDRESS_TO_ID_REJECTED,
  GET_DURATION_ADDRESS_TO_ID_PENDING,
  GET_DURATION_ADDRESS_TO_ADDRESS,
  GET_DURATION_ADDRESS_TO_ADDRESS_FULFILLED,
  GET_DURATION_ADDRESS_TO_ADDRESS_REJECTED,
  GET_DURATION_ADDRESS_TO_ADDRESS_PENDING,
  GET_ACCOMODATION_ITEM_BY_PROFILE_ID_AND_REQUEST_DATE,
  GET_ACCOMODATION_ITEM_BY_PROFILE_ID_AND_REQUEST_DATE_PENDING,
  GET_ACCOMODATION_ITEM_BY_PROFILE_ID_AND_REQUEST_DATE_FULFILLED,
  GET_ACCOMODATION_ITEM_BY_PROFILE_ID_AND_REQUEST_DATE_REJECTED,
  GET_USER_ROLE_BY_BEARER,
  GET_USER_ROLE_BY_BEARER_FULFILLED,
  GET_USER_ROLE_BY_BEARER_PENDING,
  GET_USER_ROLE_BY_BEARER_REJECTED,
  GET_RESTAURANT_BY_CITY_DATE_PAX,
  GET_RESTAURANT_BY_CITY_DATE_PAX_PENDING,
  GET_RESTAURANT_BY_CITY_DATE_PAX_FULFILLED,
  GET_RESTAURANT_BY_CITY_DATE_PAX_REJECTED,
  GET_MASTER_REFERENCE_MENU_TYPE_LIST,
  GET_MASTER_REFERENCE_MENU_TYPE_LIST_PENDING,
  GET_MASTER_REFERENCE_MENU_TYPE_LIST_FULFILLED,
  GET_MASTER_REFERENCE_MENU_TYPE_LIST_REJECTED,
  GET_MENU_CLASS,
  GET_MENU_CLASS_PENDING,
  GET_MENU_CLASS_FULFILLED,
  GET_MENU_CLASS_REJECTED,
  POST_PUBLISH_PACKAGE,
  POST_PUBLISH_PACKAGE_PENDING,
  POST_PUBLISH_PACKAGE_FULFILLED,
  POST_PUBLISH_PACKAGE_REJECTED,
  GET_TRANSACTION_ALL_BY_STATUS_ON_HOLD_LIST,
  GET_TRANSACTION_ALL_BY_STATUS_ON_HOLD_LIST_FULFILLED,
  GET_TRANSACTION_ALL_BY_STATUS_ON_HOLD_LIST_PENDING,
  GET_TRANSACTION_ALL_BY_STATUS_ON_HOLD_LIST_REJECTED,
  GET_TRANSACTION_ALL_BY_STATUS_AFTER_DP_LIST,
  GET_TRANSACTION_ALL_BY_STATUS_AFTER_DP_LIST_FULFILLED,
  GET_TRANSACTION_ALL_BY_STATUS_AFTER_DP_LIST_PENDING,
  GET_TRANSACTION_ALL_BY_STATUS_AFTER_DP_LIST_REJECTED,
  GET_TRANSACTION_ALL_BY_STATUS_CANCEL_LIST,
  GET_TRANSACTION_ALL_BY_STATUS_CANCEL_LIST_FULFILLED,
  GET_TRANSACTION_ALL_BY_STATUS_CANCEL_LIST_PENDING,
  GET_TRANSACTION_ALL_BY_STATUS_CANCEL_LIST_REJECTED,
  GET_TRANSACTION_ALL_BY_STATUS_CREATED_LIST,
  GET_TRANSACTION_ALL_BY_STATUS_CREATED_LIST_FULFILLED,
  GET_TRANSACTION_ALL_BY_STATUS_CREATED_LIST_PENDING,
  GET_TRANSACTION_ALL_BY_STATUS_CREATED_LIST_REJECTED,
  GET_TRANSACTION_ALL_BY_STATUS_COMPLETE_LIST,
  GET_TRANSACTION_ALL_BY_STATUS_COMPLETE_LIST_FULFILLED,
  GET_TRANSACTION_ALL_BY_STATUS_COMPLETE_LIST_PENDING,
  GET_TRANSACTION_ALL_BY_STATUS_COMPLETE_LIST_REJECTED,
  GET_TRANSACTION_BY_STATUS_CANCEL_LIST,
  GET_TRANSACTION_BY_STATUS_CANCEL_LIST_FULFILLED,
  GET_TRANSACTION_BY_STATUS_CANCEL_LIST_PENDING,
  GET_TRANSACTION_BY_STATUS_CANCEL_LIST_REJECTED,
  POST_SPECIAL_ADJUSMENT,
  POST_SPECIAL_ADJUSMENT_FULFILLED,
  POST_SPECIAL_ADJUSMENT_PENDING,
  POST_SPECIAL_ADJUSMENT_REJECTED,
  EDIT_SPECIAL_ADJUSMENT,
  EDIT_SPECIAL_ADJUSMENT_FULFILLED,
  EDIT_SPECIAL_ADJUSMENT_PENDING,
  EDIT_SPECIAL_ADJUSMENT_REJECTED,
  DELETE_SPECIAL_ADJUSMENT,
  DELETE_SPECIAL_ADJUSMENT_FULFILLED,
  DELETE_SPECIAL_ADJUSMENT_PENDING,
  DELETE_SPECIAL_ADJUSMENT_REJECTED,
  GET_SCHEDULE_TOUR_TRANSACTION,
  GET_SCHEDULE_TOUR_TRANSACTION_FULFILLED,
  GET_SCHEDULE_TOUR_TRANSACTION_PENDING,
  GET_SCHEDULE_TOUR_TRANSACTION_REJECTED,
  POST_SET_PRIMARY_IMAGE,
  POST_SET_PRIMARY_IMAGE_FULFILLED,
  POST_SET_PRIMARY_IMAGE_PENDING,
  POST_SET_PRIMARY_IMAGE_REJECTED,
  GET_INVOICE_BY_TRANSACTION_ID,
  GET_INVOICE_BY_TRANSACTION_ID_FULFILLED,
  GET_INVOICE_BY_TRANSACTION_ID_PENDING,
  GET_INVOICE_BY_TRANSACTION_ID_REJECTED,
  GET_TRANSACTION_ALL_BY_STATUS,
  GET_TRANSACTION_ALL_BY_STATUS_PENDING,
  GET_TRANSACTION_ALL_BY_STATUS_REJECTED,
  GET_TRANSACTION_ALL_BY_STATUS_FULFILLED,
  GET_GROUP_TYPE_LIST,
  GET_GROUP_TYPE_LIST_FULFILLED,
  GET_GROUP_TYPE_LIST_PENDING,
  GET_GROUP_TYPE_LIST_REJECTED,
  GET_IDENTITY_TYPE,
  GET_IDENTITY_TYPE_FULFILLED,
  GET_IDENTITY_TYPE_PENDING,
  GET_IDENTITY_TYPE_REJECTED,
  GET_GUEST_TITLE_TYPE,
  GET_GUEST_TITLE_TYPE_FULFILLED,
  GET_GUEST_TITLE_TYPE_PENDING,
  GET_GUEST_TITLE_TYPE_REJECTED,
  DELETE_PACKAGE,
  DELETE_PACKAGE_PENDING,
  DELETE_PACKAGE_FULFILLED,
  DELETE_PACKAGE_REJECTED,
  COPY_PACKAGE,
  COPY_PACKAGE_PENDING,
  COPY_PACKAGE_FULFILLED,
  COPY_PACKAGE_REJECTED,
  POST_EXCHANGE_RATE,
  POST_EXCHANGE_RATE_PENDING,
  POST_EXCHANGE_RATE_FULFILLED,
  POST_EXCHANGE_RATE_REJECTED,
  PUT_EXCHANGE_RATE,
  PUT_EXCHANGE_RATE_PENDING,
  PUT_EXCHANGE_RATE_FULFILLED,
  PUT_EXCHANGE_RATE_REJECTED,
  UPLOAD_BROCHURE_PACKAGE,
  UPLOAD_BROCHURE_PACKAGE_PENDING,
  UPLOAD_BROCHURE_PACKAGE_REJECTED,
  UPLOAD_BROCHURE_PACKAGE_FULFILLED,
  GET_INVOICE_BY_COMPANY_CODE,
  GET_INVOICE_BY_COMPANY_CODE_FULFILLED,
  GET_INVOICE_BY_COMPANY_CODE_PENDING,
  GET_INVOICE_BY_COMPANY_CODE_REJECTED,
  GET_PRINT_PAYMENT_RECEIPT,
  GET_PRINT_PAYMENT_RECEIPT_FULFILLED,
  GET_PRINT_PAYMENT_RECEIPT_PENDING,
  GET_PRINT_PAYMENT_RECEIPT_REJECTED,
  PUT_GENERAL_INFO_PACKAGE,
  PUT_GENERAL_INFO_PACKAGE_PENDING,
  PUT_GENERAL_INFO_PACKAGE_FULFILLED,
  PUT_GENERAL_INFO_PACKAGE_REJECTED,
  DELETE_ACCOMODATION_ITEM,
  DELETE_ACCOMODATION_ITEM_PENDING,
  DELETE_ACCOMODATION_ITEM_FULFILLED,
  DELETE_ACCOMODATION_ITEM_REJECTED,
  POST_EDIT_QUOTATION,
  POST_EDIT_QUOTATION_PENDING,
  POST_EDIT_QUOTATION_FULFILLED,
  POST_EDIT_QUOTATION_REJECTED,
  POST_ACCEPT_QUOTATION,
  POST_ACCEPT_QUOTATION_PENDING,
  POST_ACCEPT_QUOTATION_FULFILLED,
  POST_ACCEPT_QUOTATION_REJECTED,
  GET_TOUR_OPERATOR_PROFILE_BY_CODE,
  GET_TOUR_OPERATOR_PROFILE_BY_CODE_PENDING,
  GET_TOUR_OPERATOR_PROFILE_BY_CODE_FULFILLED,
  GET_TOUR_OPERATOR_PROFILE_BY_CODE_REJECTED,
  RESET_STATUS_GET_TOUR_OPERATOR_BY_CODE,
  GET_TOUR_OPERATOR_PROFILE_DETAIL_BY_ID,
  GET_TOUR_OPERATOR_PROFILE_DETAIL_BY_ID_PENDING,
  GET_TOUR_OPERATOR_PROFILE_DETAIL_BY_ID_FULFILLED,
  GET_TOUR_OPERATOR_PROFILE_DETAIL_BY_ID_REJECTED,
  DELETE_TOUR_OPERATOR,
  DELETE_TOUR_OPERATOR_PENDING,
  DELETE_TOUR_OPERATOR_FULFILLED,
  DELETE_TOUR_OPERATOR_REJECTED,
  RESET_STATUS_DELETE_TOUR_OPERATOR,
  PUT_TOUR_OPERATOR,
  PUT_TOUR_OPERATOR_PENDING,
  PUT_TOUR_OPERATOR_FULFILLED,
  PUT_TOUR_OPERATOR_REJECTED,
  GET_TOUR_OPERATOR_PROFILE_BY_ID,
  GET_TOUR_OPERATOR_PROFILE_BY_ID_PENDING,
  GET_TOUR_OPERATOR_PROFILE_BY_ID_FULFILLED,
  GET_TOUR_OPERATOR_PROFILE_BY_ID_REJECTED,
  DELETE_COMPANIES_BY_ID,
  DELETE_COMPANIES_BY_ID_FULFILLED,
  DELETE_COMPANIES_BY_ID_PENDING,
  DELETE_COMPANIES_BY_ID_REJECTED,
  GET_MASTER_REFERENCE_RESTAURANT_PROFILE_SPECIALIZATIONS_LIST,
  GET_MASTER_REFERENCE_RESTAURANT_PROFILE_SPECIALIZATIONS_LIST_PENDING,
  GET_MASTER_REFERENCE_RESTAURANT_PROFILE_SPECIALIZATIONS_LIST_FULFILLED,
  GET_MASTER_REFERENCE_RESTAURANT_PROFILE_SPECIALIZATIONS_LIST_REJECTED,
  GET_RESTAURANT_RATING,
  GET_RESTAURANT_RATING_PENDING,
  GET_RESTAURANT_RATING_FULFILLED,
  GET_RESTAURANT_RATING_REJECTED,
  DELETE_EXCURSION_OBJECT,
  DELETE_EXCURSION_OBJECT_PENDING,
  DELETE_EXCURSION_OBJECT_FULFILLED,
  DELETE_EXCURSION_OBJECT_REJECTED,
  DELETE_RESTAURANT_ITEM_BY_ID,
  DELETE_RESTAURANT_ITEM_BY_ID_FULFILLED,
  DELETE_RESTAURANT_ITEM_BY_ID_PENDING,
  DELETE_RESTAURANT_ITEM_BY_ID_REJECTED,
  DELETE_EXCHANGE_RATE,
  DELETE_EXCHANGE_RATE_PENDING,
  DELETE_EXCHANGE_RATE_FULFILLED,
  DELETE_EXCHANGE_RATE_REJECTED,
  DELETE_CONTRACTING_FILE,
  DELETE_CONTRACTING_FILE_FULFILLED,
  DELETE_CONTRACTING_FILE_PENDING,
  DELETE_CONTRACTING_FILE_REJECTED,
  POST_CONTRACTING_FILE,
  POST_CONTRACTING_FILE_FULFILLED,
  POST_CONTRACTING_FILE_PENDING,
  POST_CONTRACTING_FILE_REJECTED,
  GET_ACCOMMODATION_WITH_FILTER,
  GET_ACCOMMODATION_WITH_FILTER_FULFILLED,
  GET_ACCOMMODATION_WITH_FILTER_PENDING,
  GET_ACCOMMODATION_WITH_FILTER_REJECTED,
  GET_LIST_TOUR_OPERATOR,
  GET_LIST_TOUR_OPERATOR_FULFILLED,
  GET_LIST_TOUR_OPERATOR_PENDING,
  GET_LIST_TOUR_OPERATOR_REJECTED,
  RESET_AIRPORT,
  POST_DEMO_PRICE,
  POST_DEMO_PRICE_FULFILLED,
  POST_DEMO_PRICE_PENDING,
  POST_DEMO_PRICE_REJECTED,
  POST_CREATE_TOUR_TRANSACTION,
  POST_CREATE_TOUR_TRANSACTION_FULFILLED,
  POST_CREATE_TOUR_TRANSACTION_PENDING,
  POST_CREATE_TOUR_TRANSACTION_REJECTED,
  POST_DEMO_JOIN_TOUR,
  POST_DEMO_JOIN_TOUR_FULFILLED,
  POST_DEMO_JOIN_TOUR_PENDING,
  POST_DEMO_JOIN_TOUR_REJECTED,
  GET_PAYMENT_RECEIPT_BY_TRANSACTION_ID,
  GET_PAYMENT_RECEIPT_BY_TRANSACTION_ID_FULFILLED,
  GET_PAYMENT_RECEIPT_BY_TRANSACTION_ID_PENDING,
  GET_PAYMENT_RECEIPT_BY_TRANSACTION_ID_REJECTED,
  GET_ALL_NOT_ALLOWED_COUNTRY_FOR_COMPANY,
  GET_ALL_NOT_ALLOWED_COUNTRY_FOR_COMPANY_FULFILLED,
  GET_ALL_NOT_ALLOWED_COUNTRY_FOR_COMPANY_PENDING,
  GET_ALL_NOT_ALLOWED_COUNTRY_FOR_COMPANY_REJECTED,
  POST_NOT_ALLOWED_COUNTRY_FOR_COMPANY,
  POST_NOT_ALLOWED_COUNTRY_FOR_COMPANY_FULFILLED,
  POST_NOT_ALLOWED_COUNTRY_FOR_COMPANY_PENDING,
  POST_NOT_ALLOWED_COUNTRY_FOR_COMPANY_REJECTED,
  PUT_NOT_ALLOWED_COUNTRY_FOR_COMPANY,
  PUT_NOT_ALLOWED_COUNTRY_FOR_COMPANY_FULFILLED,
  PUT_NOT_ALLOWED_COUNTRY_FOR_COMPANY_PENDING,
  PUT_NOT_ALLOWED_COUNTRY_FOR_COMPANY_REJECTED,
  GET_NOT_ALLOWED_COUNTRY_FOR_COMPANY_BY_COMPANY_CODE,
  GET_NOT_ALLOWED_COUNTRY_FOR_COMPANY_BY_COMPANY_CODE_PENDING,
  GET_NOT_ALLOWED_COUNTRY_FOR_COMPANY_BY_COMPANY_CODE_REJECTED,
  GET_NOT_ALLOWED_COUNTRY_FOR_COMPANY_BY_COMPANY_CODE_FULFILLED,
  GET_ROOM_ALLOTMENT_BY_PROFILE_ID,
  GET_ROOM_ALLOTMENT_BY_PROFILE_ID_FULFILLED,
  GET_ROOM_ALLOTMENT_BY_PROFILE_ID_PENDING,
  GET_ROOM_ALLOTMENT_BY_PROFILE_ID_REJECTED,
  POST_ROOM_ALLOTMENT_DAY,
  POST_ROOM_ALLOTMENT_DAY_FULFILLED,
  POST_ROOM_ALLOTMENT_DAY_PENDING,
  POST_ROOM_ALLOTMENT_DAY_REJECTED,
  POST_IMAGE_TOUR_OPERATOR,
  POST_IMAGE_TOUR_OPERATOR_FULFILLED,
  POST_IMAGE_TOUR_OPERATOR_PENDING,
  POST_IMAGE_TOUR_OPERATOR_REJECTED,
  POST_TOUR_OPERATOR,
  POST_TOUR_OPERATOR_FULFILLED,
  POST_TOUR_OPERATOR_PENDING,
  POST_TOUR_OPERATOR_REJECTED,
  DELETE_IMAGE_TOUR_OPERATOR,
  DELETE_IMAGE_TOUR_OPERATOR_FULFILLED,
  DELETE_IMAGE_TOUR_OPERATOR_PENDING,
  DELETE_IMAGE_TOUR_OPERATOR_REJECTED,
  POST_ROOM_ALLOTMENT_PERIOD,
  POST_ROOM_ALLOTMENT_PERIOD_FULFILLED,
  POST_ROOM_ALLOTMENT_PERIOD_PENDING,
  POST_ROOM_ALLOTMENT_PERIOD_REJECTED,
  GET_REQUEST_ROOM_ALLOTMENT,
  GET_REQUEST_ROOM_ALLOTMENT_FULFILLED,
  GET_REQUEST_ROOM_ALLOTMENT_PENDING,
  GET_REQUEST_ROOM_ALLOTMENT_REJECTED,
  POST_CONFIRM_ROOM_ALLOTMENT,
  POST_CONFIRM_ROOM_ALLOTMENT_FULFILLED,
  POST_CONFIRM_ROOM_ALLOTMENT_PENDING,
  POST_CONFIRM_ROOM_ALLOTMENT_REJECTED,
  GET_LIST_CONFIRM_ROOM_ALLOTMENTS,
  GET_LIST_CONFIRM_ROOM_ALLOTMENTS_FULFILLED,
  GET_LIST_CONFIRM_ROOM_ALLOTMENTS_PENDING,
  GET_LIST_CONFIRM_ROOM_ALLOTMENTS_REJECTED,
  GET_RESEND_REQUEST_CONFIRMATION,
  GET_RESEND_REQUEST_CONFIRMATION_FULFILLED,
  GET_RESEND_REQUEST_CONFIRMATION_PENDING,
  GET_RESEND_REQUEST_CONFIRMATION_REJECTED,
  GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID,
  GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID_FULFILLED,
  GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID_PENDING,
  GET_LIST_ROOM_ALLOTMENT_BY_PROFILE_ID_REJECTED,
  GET_STOCK_ROOM_ALLOTMENT,
  GET_STOCK_ROOM_ALLOTMENT_FULFILLED,
  GET_STOCK_ROOM_ALLOTMENT_PENDING,
  GET_STOCK_ROOM_ALLOTMENT_REJECTED,
  GET_EXPORT_STOCK_ROOM_ALLOTMENT,
  GET_EXPORT_STOCK_ROOM_ALLOTMENT_FULFILLED,
  GET_EXPORT_STOCK_ROOM_ALLOTMENT_PENDING,
  GET_EXPORT_STOCK_ROOM_ALLOTMENT_REJECTED,
  GET_SALES_REPORT,
  GET_SALES_REPORT_FULFILLED,
  GET_SALES_REPORT_PENDING,
  GET_SALES_REPORT_REJECTED,
  GET_COMPANY_COMMISSIONS,
  GET_COMPANY_COMMISSIONS_FULFILLED,
  GET_COMPANY_COMMISSIONS_PENDING,
  GET_COMPANY_COMMISSIONS_REJECTED,
  GET_COMPANY_COMMISSION_BY_ID,
  GET_COMPANY_COMMISSION_BY_ID_FULFILLED,
  GET_COMPANY_COMMISSION_BY_ID_PENDING,
  GET_COMPANY_COMMISSION_BY_ID_REJECTED,
  POST_COMPANY_COMMISSION,
  POST_COMPANY_COMMISSION_FULFILLED,
  POST_COMPANY_COMMISSION_PENDING,
  POST_COMPANY_COMMISSION_REJECTED,
  PUT_COMPANY_COMMISSION,
  PUT_COMPANY_COMMISSION_FULFILLED,
  PUT_COMPANY_COMMISSION_PENDING,
  PUT_COMPANY_COMMISSION_REJECTED,
  DELETE_COMPANY_COMMISSIONS,
  DELETE_COMPANY_COMMISSIONS_FULFILLED,
  DELETE_COMPANY_COMMISSIONS_PENDING,
  DELETE_COMPANY_COMMISSIONS_REJECTED,
  GET_TRANSACTION_INVOICE_WITH_STATUS,
  GET_TRANSACTION_INVOICE_WITH_STATUS_FULFILLED,
  GET_TRANSACTION_INVOICE_WITH_STATUS_PENDING,
  GET_TRANSACTION_INVOICE_WITH_STATUS_REJECTED,
  POST_COMMISSION_PAYMENT,
  POST_COMMISSION_PAYMENT_FULFILLED,
  POST_COMMISSION_PAYMENT_PENDING,
  POST_COMMISSION_PAYMENT_REJECTED,
  GET_TOUR_OPERATOR_EMAIL_SETTING_ALL,
  GET_TOUR_OPERATOR_EMAIL_SETTING_ALL_FULFILLED,
  GET_TOUR_OPERATOR_EMAIL_SETTING_ALL_PENDING,
  GET_TOUR_OPERATOR_EMAIL_SETTING_ALL_REJECTED,
  GET_TOUR_OPERATOR_EMAIL_SETTING_BY_ID,
  GET_TOUR_OPERATOR_EMAIL_SETTING_BY_ID_FULFILLED,
  GET_TOUR_OPERATOR_EMAIL_SETTING_BY_ID_PENDING,
  GET_TOUR_OPERATOR_EMAIL_SETTING_BY_ID_REJECTED,
  POST_TOUR_OPERATOR_EMAIL_SETTING,
  POST_TOUR_OPERATOR_EMAIL_SETTING_FULFILLED,
  POST_TOUR_OPERATOR_EMAIL_SETTING_PENDING,
  POST_TOUR_OPERATOR_EMAIL_SETTING_REJECTED,
  PUT_TOUR_OPERATOR_EMAIL_SETTING,
  PUT_TOUR_OPERATOR_EMAIL_SETTING_FULFILLED,
  PUT_TOUR_OPERATOR_EMAIL_SETTING_PENDING,
  PUT_TOUR_OPERATOR_EMAIL_SETTING_REJECTED,
  DELETE_TOUR_OPERATOR_EMAIL_SETTING,
  DELETE_TOUR_OPERATOR_EMAIL_SETTING_FULFILLED,
  DELETE_TOUR_OPERATOR_EMAIL_SETTING_PENDING,
  DELETE_TOUR_OPERATOR_EMAIL_SETTING_REJECTED,
  RESET_TOUR_OPERATOR_EMAIL_SETTING_STATUS,
  GET_TOUR_OPERATOR_EMAIL_SETTING_BY_OPERATOR,
  GET_TOUR_OPERATOR_EMAIL_SETTING_BY_OPERATOR_FULFILLED,
  GET_TOUR_OPERATOR_EMAIL_SETTING_BY_OPERATOR_PENDING,
  GET_TOUR_OPERATOR_EMAIL_SETTING_BY_OPERATOR_REJECTED,
  GET_TOUR_TRANSACTIONS_COMMISSION_PAYMENT,
  GET_TOUR_TRANSACTIONS_COMMISSION_PAYMENT_FULFILLED,
  GET_TOUR_TRANSACTIONS_COMMISSION_PAYMENT_PENDING,
  GET_TOUR_TRANSACTIONS_COMMISSION_PAYMENT_REJECTED,
  GET_CITY_IN_COUNTRY,
  GET_CITY_IN_COUNTRY_FULFILLED,
  GET_CITY_IN_COUNTRY_PENDING,
  GET_CITY_IN_COUNTRY_REJECTED,
  POST_RESEND_DATA_TOUR_TRANSACTION,
  POST_RESEND_DATA_TOUR_TRANSACTION_REJECTED,
  POST_RESEND_DATA_TOUR_TRANSACTION_PENDING,
  POST_RESEND_DATA_TOUR_TRANSACTION_FULFILLED,
  GET_PACKAGE_TRANSACTION_HISTORY,
  GET_PACKAGE_TRANSACTION_HISTORY_FULFILLED,
  GET_PACKAGE_TRANSACTION_HISTORY_PENDING,
  GET_PACKAGE_TRANSACTION_HISTORY_REJECTED,
  GET_ALL_SHARED_PACKAGE,
  GET_ALL_SHARED_PACKAGE_FULFILLED,
  GET_ALL_SHARED_PACKAGE_PENDING,
  GET_ALL_SHARED_PACKAGE_REJECTED,
  GET_FILTER_SHARED_PACKAGE,
  GET_FILTER_SHARED_PACKAGE_FULFILLED,
  GET_FILTER_SHARED_PACKAGE_PENDING,
  GET_FILTER_SHARED_PACKAGE_REJECTED,
  GET_SHARED_PACKAGE_BY_ID,
  GET_SHARED_PACKAGE_BY_ID_FULFILLED,
  GET_SHARED_PACKAGE_BY_ID_PENDING,
  GET_SHARED_PACKAGE_BY_ID_REJECTED,
  GET_USER_PROFILE,
  GET_USER_PROFILE_PENDING,
  GET_USER_PROFILE_FULFILLED,
  GET_USER_PROFILE_REJECTED,
  PUT_USER_PROFILE_BY_BEARER,
  PUT_USER_PROFILE_BY_BEARER_PENDING,
  PUT_USER_PROFILE_BY_BEARER_FULFILLED,
  PUT_USER_PROFILE_BY_BEARER_REJECTED,
  POST_CHANGE_PASSWORD,
  POST_CHANGE_PASSWORD_PENDING,
  POST_CHANGE_PASSWORD_FULFILLED,
  POST_CHANGE_PASSWORD_REJECTED,
  GET_USER_PROFILE_ONLY_BY_USER_ID,
  GET_USER_PROFILE_ONLY_BY_USER_ID_FULFILLED,
  GET_USER_PROFILE_ONLY_BY_USER_ID_PENDING,
  GET_USER_PROFILE_ONLY_BY_USER_ID_REJECTED,
  GET_DOWNLOAD_SCHEDULE_BY_TRANSACTION_ID,
  GET_DOWNLOAD_SCHEDULE_BY_TRANSACTION_ID_FULFILLED,
  GET_DOWNLOAD_SCHEDULE_BY_TRANSACTION_ID_PENDING,
  GET_DOWNLOAD_SCHEDULE_BY_TRANSACTION_ID_REJECTED,
  // GET_DOWNLOAD_BROCHURE_BY_TRANSACTION_ID,
  // GET_DOWNLOAD_BROCHURE_BY_TRANSACTION_ID_FULFILLED,
  // GET_DOWNLOAD_BROCHURE_BY_TRANSACTION_ID_PENDING,
  // GET_DOWNLOAD_BROCHURE_BY_TRANSACTION_ID_REJECTED,
  GET_INVOICE_HISTORY_BY_TRANSACTION_ID,
  GET_INVOICE_HISTORY_BY_TRANSACTION_ID_FULFILLED,
  GET_INVOICE_HISTORY_BY_TRANSACTION_ID_PENDING,
  GET_INVOICE_HISTORY_BY_TRANSACTION_ID_REJECTED,
  GET_DOWNLOAD_VOUCHER_CONFIRMATION_BY_TRANSACTION_ID,
  GET_DOWNLOAD_VOUCHER_CONFIRMATION_BY_TRANSACTION_ID_FULFILLED,
  GET_DOWNLOAD_VOUCHER_CONFIRMATION_BY_TRANSACTION_ID_PENDING,
  GET_DOWNLOAD_VOUCHER_CONFIRMATION_BY_TRANSACTION_ID_REJECTED,
  POST_ADDITIONAL_ITEMS,
  POST_ADDITIONAL_ITEMS_FULFILLED,
  POST_ADDITIONAL_ITEMS_PENDING,
  POST_ADDITIONAL_ITEMS_REJECTED,
  GET_PACKAGES_VARIABLE_DATE,
  GET_PACKAGES_VARIABLE_DATE_PENDING,
  GET_PACKAGES_VARIABLE_DATE_FULFILLED,
  GET_PACKAGES_VARIABLE_DATE_REJECTED,
  RESET_ERROR_MESSAGE,
  GET_EXPORT_DATA_ITEM_BY_ID,
  GET_EXPORT_DATA_ITEM_BY_ID_FULFILLED,
  GET_EXPORT_DATA_ITEM_BY_ID_PENDING,
  GET_EXPORT_DATA_ITEM_BY_ID_REJECTED,
  GET_PARTNER_EMPLOYEE,
  GET_PARTNER_EMPLOYEE_FULFILLED,
  GET_PARTNER_EMPLOYEE_PENDING,
  GET_PARTNER_EMPLOYEE_REJECTED,
  GET_PLACE_BY_PLACE_TYPE_ID,
  GET_PLACE_BY_PLACE_TYPE_ID_PENDING,
  GET_PLACE_BY_PLACE_TYPE_ID_FULFILLED,
  GET_PLACE_BY_PLACE_TYPE_ID_REJECTED,
  //
  GET_COMPANY_PROFILE,
  GET_COMPANY_PROFILE_FULFILLED,
  GET_COMPANY_PROFILE_PENDING,
  GET_COMPANY_PROFILE_REJECTED,
  GET_ADDITIONAL_ITEM_TYPE,
  GET_ADDITIONAL_ITEM_TYPE_FULFILLED,
  GET_ADDITIONAL_ITEM_TYPE_PENDING,
  GET_ADDITIONAL_ITEM_TYPE_REJECTED,
  RESET_ADDITIONAL_ITEM_TYPE,
  POST_EMAIL_SEND_CONFIRMATION,
  POST_EMAIL_SEND_CONFIRMATION_FULFILLED,
  POST_EMAIL_SEND_CONFIRMATION_PENDING,
  POST_EMAIL_SEND_CONFIRMATION_REJECTED,
  PUT_ADDITIONAL_INFO_PACKAGE_OPERATION,
  PUT_ADDITIONAL_INFO_PACKAGE_OPERATION_PENDING,
  PUT_ADDITIONAL_INFO_PACKAGE_OPERATION_FULFILLED,
  PUT_ADDITIONAL_INFO_PACKAGE_OPERATION_REJECTED,
  GET_RESOURCE_BOOKING_BY_PERIODE,
  GET_RESOURCE_BOOKING_BY_PERIODE_FULFILLED,
  GET_RESOURCE_BOOKING_BY_PERIODE_PENDING,
  GET_RESOURCE_BOOKING_BY_PERIODE_REJECTED,
  GET_RESOURCE_BOOKING_LIST_URL,
  GET_RESOURCE_BOOKING_LIST_URL_URL_FULFILLED,
  GET_RESOURCE_BOOKING_LIST_URL_PENDING,
  GET_RESOURCE_BOOKING_LIST_URL_REJECTED,
  GET_COMPANIES_BY_TYPE,
  GET_COMPANIES_BY_TYPE_FULFILLED,
  GET_COMPANIES_BY_TYPE_PENDING,
  GET_COMPANIES_BY_TYPE_REJECTED,
  PUT_PAY_RESOURCE_BOOKING_URL,
  PUT_PAY_RESOURCE_BOOKING_URL_FULFILLED,
  PUT_PAY_RESOURCE_BOOKING_URL_PENDING,
  PUT_PAY_RESOURCE_BOOKING_URL_REJECTED,
  GET_ATTRACTION_ESTIMATE_PRICE,
  GET_ATTRACTION_ESTIMATE_PRICE_FULFILLED,
  GET_ATTRACTION_ESTIMATE_PRICE_PENDING,
  GET_ATTRACTION_ESTIMATE_PRICE_REJECTED,
  GET_RESTAURANT_ESTIMATE_PRICE,
  GET_RESTAURANT_ESTIMATE_PRICE_FULFILLED,
  GET_RESTAURANT_ESTIMATE_PRICE_PENDING,
  GET_RESTAURANT_ESTIMATE_PRICE_REJECTED,
  GET_TRANSACTION_HISTORY_BY_TRANSACTION_REJECTED,
  GET_TRANSACTION_HISTORY_BY_TRANSACTION_PENDING,
  GET_TRANSACTION_HISTORY_BY_TRANSACTION_FULFILLED,
  GET_TRANSACTION_HISTORY_BY_TRANSACTION,
  GET_DOWNLOAD_SCHEDULE_BY_BOOKING_TEMPLATE,
  GET_DOWNLOAD_SCHEDULE_BY_BOOKING_TEMPLATE_FULFILLED,
  GET_DOWNLOAD_SCHEDULE_BY_BOOKING_TEMPLATE_PENDING,
  GET_DOWNLOAD_SCHEDULE_BY_BOOKING_TEMPLATE_REJECTED,
};
