const DEDUCTION = 'deduction';
const ADDITION = 'addition';

export const convertDataPostSpecialAdjustment = specialAdjusments => {
  return {
    AdditionalItems: specialAdjusments.reduce((acc, e) => {
      let data = {
        ...e,
        ActionType: e.IsDelete ? 'Delete' : e.ActionType,
        AdditionalItemType: undefined,
        TotalCost: undefined,
      };
      if (data.ActionType !== 'Not_Set') {
        acc.push(data);
      }
      return acc;
    }, []),
  };
};

export const convertAfterGetSpecialAdjustment = (
  specialAdjusments,
  tourTransactionId
) => {
  return specialAdjusments.map((e, i) => ({
    ...e,
    Idx: i,
    TourTransactionId: tourTransactionId,
    ActionType: 'Not_Set',
    IsDelete: false,
    IsHiddenData: e.IsHiddenData,
    PriceEffect: e.UnitCost < 0 ? DEDUCTION : ADDITION,
    AdditionalItemType: e.AdditionalItemType
      ? e.AdditionalItemType
      : { Value: '', Text: '-' },
  }));
};
