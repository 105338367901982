import apiClient from '../apiClient';
import {
  GET_ADDITIONAL_SERVICES_LIST_URL,
  GET_ADDITIONAL_SERVICE_BY_ID_URL,
  PUT_ADDITIONAL_SERVICE,
  POST_ADDITIONAL_SERVICE,
  DELETE_ADDITIONAL_SERVICE,
  GET_ADDITIONAL_SERVICES_LIST_IN_TRANSACTION_URL,
  GET_ADDITIONAL_SERVICE_TYPE_URL,
  GET_RESTRICTED_COUNTRY_BY_ADDITIONAL_SERVICE_TYPE_URL,
} from '../apiUrl';

const getAdditionalServicesListApi = () => {
  return apiClient.get(GET_ADDITIONAL_SERVICES_LIST_URL);
};

const getAdditionalServiceByIdApi = id => {
  return apiClient.get(GET_ADDITIONAL_SERVICE_BY_ID_URL(id));
};

const putAdditionalServiceApi = (id, data) => {
  return apiClient.put(PUT_ADDITIONAL_SERVICE(id), data);
};

const postAdditionalServiceApi = data => {
  return apiClient.post(POST_ADDITIONAL_SERVICE, data);
};

const deleteAdditionalServiceApi = id => {
  return apiClient.delete(DELETE_ADDITIONAL_SERVICE(id));
};

// get additional services list in transaction
const getAdditionalServicesListTransactionApi = (
  origin,
  destination,
  currency
) => {
  return apiClient.get(
    GET_ADDITIONAL_SERVICES_LIST_IN_TRANSACTION_URL(
      origin,
      destination,
      currency
    )
  );
};

const getAdditionalServiceTypeApi = () => {
  return apiClient.get(GET_ADDITIONAL_SERVICE_TYPE_URL);
};

const getRestrictedCountryByAdditionalServiceTypeApi = additionalServiceTypeId => {
  return apiClient.get(
    GET_RESTRICTED_COUNTRY_BY_ADDITIONAL_SERVICE_TYPE_URL(
      additionalServiceTypeId
    )
  );
};

export {
  getAdditionalServicesListApi,
  getAdditionalServiceByIdApi,
  putAdditionalServiceApi,
  postAdditionalServiceApi,
  deleteAdditionalServiceApi,
  getAdditionalServicesListTransactionApi,
  getAdditionalServiceTypeApi,
  getRestrictedCountryByAdditionalServiceTypeApi,
};
