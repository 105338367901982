const HeaderTabelAccomodation = [
  'Accommodation Name',
  'Type',
  'Rating',
  'Region',
  'City',
  'Country',
  'Phone Number',
  'Action',
];

const HeaderTabelExcursion = [
  'Excursion Name',
  'Address',
  'Type',
  'Open Hour',
  'Close Hour',
  'Min Hour',
  'Category',
  'Action',
];
// const HeaderTabelUserAccess = [
//   'Company ID',
//   'Username',
//   'Roles',
//   'Email',
//   'Action',
// ];
const HeaderTabelPendingCompany = [
  'Company Name',
  'Email',
  'Address',
  'Company Business',
  'Action',
];
const HeaderTabelPublishPackage = [
  'Tour Name',
  'Total Booking',
  'Total Confirm',
  'Seat Available',
  'Country',
  'Start Date',
  'Duration Days',
];
const HeaderTabelBookingConfirmation = [
  'Booking Number',
  'Tour Name ',
  'Type',
  'Total Guest',
  'Country',
  'Must Paid On',
  'Status',
  'Paid',
  'Action',
];
const HeaderTabelOnHoldTransaction = [
  'Booking Name',
  'Tour Name',
  'Company Name',
  'Total Guest',
  'List Item Not Available',
  'Action',
];
const HeaderTabelTransactionDownPayment = [
  'Booking  Name',
  'Tour Name',
  'Company Name',
  'First Payment',
  'Special Adjustment',
  'Action',
];
const HeaderTabelCompleteTransaction = [
  'Booking Number',
  'Tour Name',
  'Company Name',
  'Status',
  'Tour Date',
  'Total Price',
  'Already Paid',
];
const HeaderTabelPaymentHistory = [
  'Booking Number',
  'Booking Name',
  'Company Name',
  'Description',
  'Cost',
  'Payment Date',
];

const HeaderTabelExchangeRate = [
  'Effective Date',
  'Currency',
  'Value',
  'Action',
];

// const HeaderTabelSystemMargin = [
//   'Valid',
//   'Minimum Cost',
//   'Percentage (%)',
//   'Action',
// ];

const HeaderTabelAccomodationItemList = [
  'Item Name',
  'Description',
  'Type',
  'Extra Bed Capacity',
  'Minimum Night',
  'Action',
];
const HeaderTablePaymentPackage = [
  'Percentage (%)',
  'Due Date',
  'Description',
  'Lock Guest',
  'Action',
];
const HeaderTableAccommodationPackage = [
  'Accommodation',
  'Destination',
  'Check-in Date',
  'Check-out Date',
  'Check-in Time',
  'Check-out Time',
  'Action',
];
const HeaderTableAdditionalItem = [
  'Additional Name',
  'Description',
  'Price',
  'Mandatory',
  'Deduction',
  'Action',
];
const HeaderTableImageAccomodationProfile = [
  'File Name',
  'Thumbnail',
  'Action',
];
const HeaderTableImage = ['File Name', 'Thumbnail', 'Action'];
const HeaderTableAccessibleCity = ['City', 'Region', 'Action'];
const HeaderTableModalccessibleCity = ['No', 'City', 'Select'];
const HeaderTableFacilitiesAccomodationProfile = ['Facilities Name', 'Action'];

const HeaderTableLocationAccomodationProfile = [
  'Location Description',
  'Action',
];

const HeaderTableLocationAccomodationItemPrice = [
  'Service Type',
  'Rank Type',
  'Rank Value',
  'Minimum Night',
  'Start Date',
  'End Date',
  'Cut off Date',
  'Cut off Day',
  'Action',
];

const HeaderTransportationUnitPrice = [
  'Origin Region',
  'Origin City',
  'Dest Region',
  'Dest City',
  'Action',
];
const HeaderSupplierPriceDetails = ['Price', 'Minimum Pax', 'Action'];

const HeaderRestaurantPriceDetails = ['Price', 'Minimum Pax', 'Action'];
const HeaderRestaurantList = [
  'Restaurant Name',
  'Address',
  'Category',
  'Speciality',
  'Open Hour',
  'Close Hour',
  'Action',
];
const HeaderExcurtionPrice = [
  'Type',
  'Description',
  'Min. Pax',
  'Price',
  'Start Date',
  'End Date',
  'Cutt Of Day',
  'Action',
];

const HeaderAccommodationPrice = [
  'Rank Type',
  'Rank Value',
  'Start Date',
  'End Date',
  'Cutt Of Date',
  'Cutt Of Day',
  'Promotion',
  'Action',
];
// const HeaderRestaurantPrice = [
//   'Type',
//   'Description',
//   'Min. Pax',
//   'Currency',
//   'Price',
//   'Start Date',
//   'End Date',
//   'Cutt Of Day',
//   'Action',
// ];
const HeaderRestaurantPrice = [
  'Type',
  // 'Rank Value',
  // 'Min. Pax',
  'Start Date',
  'End Of Date',
  // 'Cut Of Day',
  'Price',
  'Action',
];
const HeaderRestaurantItemList = [
  'Item Name',
  'Description',
  'Menu Type',
  'Menu Class ',
  'Food Presentation',
  'Action',
];

const HeaderTableAccesibleCityRestaurant = ['No', 'City', 'Action'];
const HeaderTableAreaList = ['ID', 'Area Name', 'City', 'Action'];
const HeaderTableCityList = [
  'ID',
  'City Name',
  'Region Name',
  'Country',
  'UTC',
  'Action',
];
const HeaderTableRegionList = ['ID', 'Region Name', 'Country', 'Action'];
const HeaderTablePlaceList = [
  'ID',
  'Place Name',
  'Code',
  'City',
  'Type',
  'Action',
];
const HeaderTableCountryList = ['Code', 'Country', 'Tour Relevant', 'Action'];

const HeaderTableCustomerProfileHistoryTransaction = [
  'Booking Number',
  'Title Package',
  'Type',
  'Total Guest',
  'Status',
  'Total Price',
  'Total Paid',
  'Action',
];

const HeaderTableCustomerProfileUserData = [
  'Username',
  'Name',
  'Phone Number',
  'Email',
  'Address',
  'Status',
  'Action',
];

const HeaderBalanceDetailTransaction = [
  'Date',
  'Transaction Type',
  'Amount',
  'Description',
];
const HeaderUserRole = ['Username', 'Roles', 'Email', 'Action'];
const HeaderTableCityGroup = ['Group Name', 'City', 'Action'];
const HeaderTableMasterDataAccomodation = [
  'Name',
  'Type',
  'Segment',
  'Address',
  'Telephone',
  'Email',
  'Description',
  'Overview',
];
const HeaderTableMasterDataTransportation = [
  'Name ',
  'Code ',
  'Company Code',
  'Type Name',
  'Seat Type',
  'Rating',
  'Currency',
  'Price',
  'Start Date',
  'End Date',
  'Rank',
  'ServiceType',
  'Hours',
  'Description',
];
const HeaderTableMasterDataRestaurant = [
  'Name',
  'Company',
  'Price Unit',
  'Currency',
  'Pax',
  'Price',
  'Star Date',
  'End Date',
];
const HeaderTableMasterDataExcursion = [
  // 'Name',
  // 'Address',
  // 'Telpon',
  // 'Email',
  // 'Company ID',
  // 'Type Name',
  // 'City Name',
  // 'City',
  // 'Region',
  // 'Optimum Duration',
  // 'Operation Start Time',
  // 'Operation End Time',
  // 'Solid Duration',
  // 'Solid Start Time',
  // 'Package Category',
  'Excursion Name',
  'Address',
  'Type',
  'Open Hour',
  'Close Hour',
  'Optimum Duration',
  'Category',
];

const HeaderUploadMasterImage = [
  'Object/Item Name',
  'Reference Object',
  'Images File Name',
  'Action',
];

const HeaderTableMasterDataAccomodationItem = [
  'Name',
  'Item Description',
  'IsPromo',
  'Company ID',
  'Accommodation Item Type',
  'Currency',
  'Price',
  'Start Date',
  'End Date',
  'Cutt Off Day',
  'Cut Off Date',
  'Minimum Nights',
  'Rank',
  'Service Type',
];

const HeaderTableMasterDataRestaurantItem = [
  // 'Name',
  // 'Company',
  // 'Price Unit',
  // 'Currency',
  // 'Pax',
  // 'Price',
  // 'Star Date',
  // 'End Date',
  'Name',
  'Company',
  'Description',
  'Menu Class',
  'Menu Category',
  'Menu Type',
  'Minimum Pax',
  'Cut Off Date',
];
const HeaderTableMasterDataTransportationUnit = [
  'Name',
  'Code',
  'Company Code',
  'Type Name',
  'Seat Type',
  'Rating',
  'Currency',
  'Price',
  'Start Date',
  'End Date',
  'Rank',
  'ServiceType',
  'Hours',
  'Description',
];

const HeaderTableMasterDataExcursionPrice = [
  'Name',
  'Company Code',
  'Currency',
  'Price Unit',
  'Pax',
  'Price',
  'Start Date',
  'End Date',
];

const HeaderTableSeriesPackage = [
  'Id',
  'Tour Name',
  'Total Booking',
  'Total Confirm',
  'Seat Available',
  'Destination',
  'Start Date',
  'End Date',
  // 'Action',
];
const HeaderTableReadyPackage = [
  'Id',
  'Tour Name',
  'Fix Price ',
  'Destination',
  'Start Date',
  'End Date',
  // 'Action',
];

const HeaderUploadMasterData = ['ID', 'Name', 'Action'];
const HeaderUploadMasterDataWithDuplicate = [
  'ID',
  'Name',
  'Duplicate',
  'Action',
];
const HeaderUploadMasterDataWithDescription = [
  'ID',
  'Name',
  'Description',
  'Action',
];
const HeaderUploadMasterDataIdentityTypeConfiguration = [
  'ID',
  'Country',
  'Identity Type',
  'Action',
];
const HeaderUploadMasterDataCustomEnumeration = [
  'ID',
  'Category',
  'Value',
  'Action',
];

const HeaderUploadMasterDataLanguage = ['ID', 'English Name', 'Local Name'];

const HeaderAddtionalInfoReadyPackage = ['Sub Title', 'Action'];

const HeaderPaymentMethod = ['Country Name', 'Payment Method Name', 'Action'];

const HeaderAdditionalItemDetail = [
  'Additional Item',
  'Actual Paid',
  'Guest',
  'Price/pax',
  'Sub Total',
];

const HeaderSpecialAdjusment = [
  'Type',
  'Description',
  'Hide Adjustment',
  'Type Price',
  'Quantity',
  'Currency',
  'Unit Cost',
  'Action',
];

const HeaderBookingConfirmation = [
  'Booking Number',
  'Tour Name ',
  'Company name',
  'Status',
  'Total Price',
  'Total Paid',
  'Payment Receipt',
  'Action',
];

const HeaderTableInvoicesCompanyProfile = [
  'Invoices Number',
  'Booking Number',
  'Status',
  'Issue Date ',
  'Last Modified Date',
  'Total Price',
  'Total Paid',
  'Action',
];

const HeaderTabelTourOperatorProfile = [
  'Tour Operator Name',
  'Telephone',
  'Email',
  'City',
  'Address',
  'Action',
];
const HeaderCustomerOnBehalf = [
  'Username',
  'Name',
  'Phone No.',
  'Email ',
  'Action',
];

const HeaderTableContractingFileAccomodationProfile = [
  'File Name',
  'Valid Period',
  'Description',
  'Action',
];

const HeaderTableNotAllowedCountryForCompany = [
  'Company ID',
  'Company Name',
  'Country Not Allowed',
  'Action',
];

const HeaderTablePaymentScheme = [
  'Scheme ID',
  'Scheme Name',
  'Scheme Description',
  'Action',
];

const HeaderTablePaymentSchemeDetail = [
  'Description',
  'Percentage',
  'Interval Day',
  'Lock Guest',
  'Action',
];

const HeaderTableCompanyCommission = [
  'Booking Number',
  'Booking Name',
  'status',
  'Company Name',
  'Description',
  'Start Date',
  'End Date',
  'Cashback Value',
  'Cashback Status',
];
export {
  HeaderTabelAccomodation,
  HeaderTabelExcursion,
  HeaderTabelOnHoldTransaction,
  HeaderTabelPaymentHistory,
  HeaderTabelPendingCompany,
  HeaderTabelPublishPackage,
  HeaderTabelTransactionDownPayment,
  // HeaderTabelUserAccess,
  HeaderTabelCompleteTransaction,
  HeaderTabelBookingConfirmation,
  HeaderTabelExchangeRate,
  // HeaderTabelSystemMargin,
  HeaderTabelAccomodationItemList,
  HeaderTableImageAccomodationProfile,
  HeaderTableFacilitiesAccomodationProfile,
  HeaderTableLocationAccomodationProfile,
  HeaderTableLocationAccomodationItemPrice,
  HeaderTransportationUnitPrice,
  HeaderRestaurantList,
  HeaderRestaurantItemList,
  HeaderTableAccesibleCityRestaurant,
  HeaderTableAreaList,
  HeaderTableCityList,
  HeaderTableRegionList,
  HeaderTablePlaceList,
  HeaderTableCountryList,
  HeaderTableCustomerProfileHistoryTransaction,
  HeaderTableCustomerProfileUserData,
  HeaderTableCityGroup,
  HeaderBalanceDetailTransaction,
  HeaderTableImage,
  HeaderTableAccessibleCity,
  HeaderExcurtionPrice,
  HeaderAccommodationPrice,
  HeaderRestaurantPrice,
  HeaderSupplierPriceDetails,
  HeaderTableMasterDataAccomodation,
  HeaderTableMasterDataTransportation,
  HeaderTableMasterDataRestaurant,
  HeaderTableMasterDataExcursion,
  HeaderUploadMasterImage,
  HeaderTableMasterDataAccomodationItem,
  HeaderTableMasterDataRestaurantItem,
  HeaderTableMasterDataTransportationUnit,
  HeaderTableMasterDataExcursionPrice,
  HeaderTableReadyPackage,
  HeaderAddtionalInfoReadyPackage,
  HeaderTablePaymentPackage,
  HeaderTableAdditionalItem,
  HeaderUserRole,
  HeaderTableModalccessibleCity,
  HeaderUploadMasterData,
  HeaderPaymentMethod,
  HeaderUploadMasterDataWithDescription,
  HeaderUploadMasterDataIdentityTypeConfiguration,
  HeaderUploadMasterDataCustomEnumeration,
  HeaderTableAccommodationPackage,
  HeaderAdditionalItemDetail,
  HeaderSpecialAdjusment,
  HeaderRestaurantPriceDetails,
  HeaderBookingConfirmation,
  HeaderTableInvoicesCompanyProfile,
  HeaderTabelTourOperatorProfile,
  HeaderCustomerOnBehalf,
  HeaderTableContractingFileAccomodationProfile,
  HeaderTableNotAllowedCountryForCompany,
  HeaderTablePaymentScheme,
  HeaderTablePaymentSchemeDetail,
  HeaderTableCompanyCommission,
  HeaderUploadMasterDataLanguage,
  HeaderTableSeriesPackage,
  HeaderUploadMasterDataWithDuplicate,
};
