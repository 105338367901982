import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';
// import styles from './styles';
const ModalInformation = props => {
  const {
    size,
    open,
    close,
    content,
    modalHeader,
    contentButton,
    onClickButton,
  } = props;
  return (
    <Modal size={size} open={open} onClose={close}>
      <Modal.Header>{modalHeader}</Modal.Header>
      <Modal.Content>{content}</Modal.Content>
      <Modal.Actions>
        <Button
          // style={styles.bgGold}
          positive
          content={contentButton}
          onClick={onClickButton}
        />
      </Modal.Actions>
    </Modal>
  );
};

ModalInformation.propTypes = {
  icon: PropTypes.string,
  placeholder: PropTypes.string,
  iconPosition: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.string,
  open: PropTypes.bool,
  close: PropTypes.func,
  content: PropTypes.string,
  modalHeader: PropTypes.string,
  contentButton: PropTypes.string,
  onClickButton: PropTypes.func,
};
export default ModalInformation;
