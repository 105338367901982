import React from 'react';
import { Dimmer } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getCountryAction,
  resetCountryAction,
} from '../shared/actions/countryAction';
import {
  resetNotAllowedCountryAction,
  getAllAllowedForCompanyAction,
  getAllowedForCompanyByCompanyCodeAction,
  postAllowedForCompanyAction,
  putAllowedForCompanyAction,
} from './notAllowedCompanyAction';
import Loader from '../../components/loader';
import NotAllowedCountryList from './components/NotAllowedCountryList';
import ModalAllowedForCompany from './components/ModalNotAllowedCountryForCompany';
import ModalAlert from '../../components/modal/modalAlert';
class NotAllowedForCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notAllowedCountryForCompanyList: [],
      countryList: [],
    };
  }
  static propTypes = {
    loading: PropTypes.bool,
    getAllAllowedForCompanyAction: PropTypes.func,
    getAllNotAllowedCountryForCountryStatus: PropTypes.string,
    getAllNotAllowedCountryForCountry: PropTypes.array,
    resetNotAllowedCountryAction: PropTypes.func,
    errorMessage: PropTypes.object,
    resetCountryAction: PropTypes.func,
    getCountryAction: PropTypes.func,
    getCountryStatus: PropTypes.string,
    getAllowedForCompanyByCompanyCodeAction: PropTypes.func,
    getCountry: PropTypes.func,
    loadingGetCountry: PropTypes.bool,
  };

  componentDidMount() {
    const { getAllAllowedForCompanyAction } = this.props;
    getAllAllowedForCompanyAction();
  }

  componentDidUpdate() {
    const {
      getAllNotAllowedCountryForCountryStatus,
      getAllNotAllowedCountryForCountry,
      getCountryStatus,
      getCountry,
      resetNotAllowedCountryAction,
      resetCountryAction,
      getAllowedForCompanyByCompanyCodeAction,
    } = this.props;
    const { CompanyCode } = this.state;
    if (getAllNotAllowedCountryForCountryStatus === 'success') {
      this.setState({
        notAllowedCountryForCompanyList: getAllNotAllowedCountryForCountry,
      });
      resetNotAllowedCountryAction();
      resetCountryAction();
    } else if (getAllNotAllowedCountryForCountryStatus === 'failed') {
      this.setState({
        failedCity: true,
        headerAlert: 'Failed',
        contentAlert: this.props.errorMessage,
      });
      resetNotAllowedCountryAction();
      resetCountryAction();
    }
    if (getCountryStatus === 'success') {
      this.setState({
        countryList: getCountry.filter(item => item.IsCountryRelevant === true),
      });
      resetNotAllowedCountryAction();
      resetCountryAction();
      getAllowedForCompanyByCompanyCodeAction(CompanyCode);
      //this.setState({ openModalNotAllowed: true });
    }
  }

  editNotAllowedCountry = (e, { id }) => {
    const { getCountryAction } = this.props;
    this.setState({ CompanyCode: id });
    getCountryAction();
  };
  handleCloseModalAllowed = () => {
    this.setState({ openModalNotAllowed: false });
  };
  handleOpenModalAllowed = () => {
    this.setState({ openModalNotAllowed: true });
  };
  handleReloadPage = () => {
    const { getAllAllowedForCompanyAction } = this.props;
    getAllAllowedForCompanyAction();
    this.handleMessage('Success', 'Save Successfully');
  };
  handleClose = () => {
    this.setState({
      openModal: false,
    });
  };
  handleMessageError = () => {
    this.handleMessage('Failed', 'Failed to save');
  };
  handleMessage = (header, message) => {
    this.setState({
      openModalNotAllowed: false,
      openModal: true,
      headerAlert: header,
      contentAlert: message,
    });
  };
  render() {
    const { loading, loadingGetCountry } = this.props;
    const {
      notAllowedCountryForCompanyList,
      openModalNotAllowed,
      countryList,
      CompanyCode,
      openModal,
      headerAlert,
      contentAlert,
    } = this.state;
    return (
      <div>
        <Dimmer.Dimmable blurring dimmed={loading}>
          <Loader loading={loading || loadingGetCountry} />
          <NotAllowedCountryList
            data={notAllowedCountryForCompanyList}
            type="notAllowedCountry"
            filterBy="notAllowedCompany"
            buttonEdit={this.editNotAllowedCountry}
          />
          <ModalAllowedForCompany
            isOpen={openModalNotAllowed}
            onClose={this.handleCloseModalAllowed}
            countryList={countryList}
            CompanyCode={CompanyCode}
            reloadPage={this.handleReloadPage}
            handleOpenModalAllowed={this.handleOpenModalAllowed}
            handleMessageError={this.handleMessageError}
          />
          <ModalAlert
            openModal={openModal}
            handleClose={this.handleClose}
            header={headerAlert}
            content={contentAlert}
            nameButton="OK"
          />
        </Dimmer.Dimmable>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    putNotAllowedCountryForCompanyStatus:
      state.NotAllowedCountryStore.putNotAllowedCountryForCompanyStatus,
    postNotAllowedCountryForCompanyStatus:
      state.NotAllowedCountryStore.postNotAllowedCountryForCompanyStatus,
    getAllNotAllowedCountryForCountryStatus:
      state.NotAllowedCountryStore.getAllNotAllowedCountryForCountryStatus,
    putNotAllowedCountryForCompany:
      state.NotAllowedCountryStore.putNotAllowedCountryForCompany,
    postNotAllowedCountryForCompany:
      state.NotAllowedCountryStore.postNotAllowedCountryForCompany,
    getAllNotAllowedCountryForCountry:
      state.NotAllowedCountryStore.getAllNotAllowedCountryForCountry,
    // getCountry: state.NotAllowedCountryStore.getCountry,
    // getCountryStatus: state.NotAllowedCountryStore.getCountryStatus,
    getCountry: state.CountryStore.getCountry,
    getCountryStatus: state.CountryStore.getCountryStatus,
    loading: state.NotAllowedCountryStore.loading,
    errorMessage: state.NotAllowedCountryStore.errors,
    loadingGetCountry: state.CountryStore.loading,
  };
}
export default connect(
  mapStateToProps,
  {
    getAllAllowedForCompanyAction,
    getAllowedForCompanyByCompanyCodeAction,
    postAllowedForCompanyAction,
    putAllowedForCompanyAction,
    resetNotAllowedCountryAction,
    resetCountryAction,
    getCountryAction,
  }
)(NotAllowedForCompany);
