import React from 'react';
import { Collapse } from 'react-collapse';
import { Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const messageErrors = props => {
  let { isErrors, listErrors } = props;
  return (
    <Collapse isOpened={isErrors}>
      <Message
        error
        style={{
          border: '1px solid rgb(244, 67, 54)',
          background: 'rgba(244, 67, 54, 0.4)',
        }}
        list={listErrors}
      />
    </Collapse>
  );
};

messageErrors.propTypes = {
  isErrors: PropTypes.bool,
  listErrors: PropTypes.array,
};
export default messageErrors;
