import React from 'react';
import { Dimmer } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getPaymentSchemeByIdAction,
  getPaymentSchemeAction,
  resetCustomerProfileAction,
  putPaymentSchemeAction,
} from '../shared/actions/customerProfileAction';
import Loader from './../../components/loader';
import PaymentSchemeList from './components/PaymentSchemeList';
import ModalConfirm from '../../components/modal/modalConfirmationTwoButton';
import ModalAlert from '../shared/components/modal/ModalAlert';
import { paymentTermScheme } from './constants/pathName';
class PaymentScheme extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentSchemeList: [],
    };
  }
  static propTypes = {
    getPaymentSchemeListStatus: PropTypes.string,
    getPaymentSchemeList: PropTypes.array,
    resetCustomerProfileAction: PropTypes.func,
    getPaymentSchemeAction: PropTypes.func,
    errorMessage: PropTypes.object,
    loading: PropTypes.bool,
    putPaymentSchemeAction: PropTypes.func,
  };

  componentDidMount() {
    this.firstRender();
  }

  firstRender = () => {
    const { getPaymentSchemeAction } = this.props;
    getPaymentSchemeAction();
  };

  componentDidUpdate() {
    const {
      getPaymentSchemeListStatus,
      getPaymentSchemeList,
      resetCustomerProfileAction,
      //getPaymentSchemeAction,
      errorMessage,
    } = this.props;
    if (getPaymentSchemeListStatus === 'success') {
      this.setState({
        paymentSchemeList: getPaymentSchemeList,
      });
      resetCustomerProfileAction();
    } else if (getPaymentSchemeListStatus === 'failed') {
      this.setState({
        failedCity: true,
        headerAlert: 'Failed',
        contentAlert: errorMessage,
      });
      resetCustomerProfileAction();
    }
  }
  buttonNew = () => {
    // eslint-disable-next-line
    this.props.history.push(`${paymentTermScheme}/0`);
  };
  buttonEdit = (e, { id }) => {
    this.props.history.push(`${paymentTermScheme}/${id}`);
  };
  buttonLock = () => {
    const { dataLock } = this.state;
    let isLock = {
      Id: dataLock.Id,
      Type: dataLock.Type,
      Description: dataLock.Description,
      IsLock: true,
    };
    this.props
      .putPaymentSchemeAction(dataLock.Id, isLock)
      .then(() => {
        this.firstRender();
        this.openAlert('Success', 'Lock payment scheme successfully');
      })
      .catch(() => this.openAlert('Failed', 'Payment scheme failed to lock'));
    this.setState({
      openConfirm: false,
    });
  };

  confirmLockPayment = (e, { id }) => {
    this.setState({
      openConfirm: true,
      messageConfirm: 'Are you sure to lock scheme ' + id.Type + '?',
      dataLock: id,
    });
  };
  handleCloseConfirm = () => {
    this.setState({
      openConfirm: false,
    });
  };
  handleClose = () => {
    this.setState({
      openModal: false,
    });
  };

  openAlert = (header, content) => {
    this.setState({
      openModal: true,
      contentAlert: content,
      headerAlert: header,
    });
  };
  render() {
    const { loading } = this.props;
    const {
      paymentSchemeList,
      headerAlert,
      contentAlert,
      openModal,
      openConfirm,
      messageConfirm,
    } = this.state;
    return (
      <div>
        <Dimmer.Dimmable blurring dimmed={loading}>
          <Loader loading={loading} />
          <PaymentSchemeList
            data={paymentSchemeList}
            type="paymentScheme"
            filterBy="schemeName"
            buttonEdit={this.buttonEdit}
            buttonNew={this.buttonNew}
            buttonLock={this.confirmLockPayment}
          />
          <ModalAlert
            openModal={openModal}
            handleClose={this.handleClose}
            header={headerAlert}
            content={contentAlert}
            nameButton="OK"
          />
          <ModalConfirm
            openModal={openConfirm}
            size="mini"
            close={this.handleCloseConfirm}
            confirm={this.buttonLock}
            message={messageConfirm}
          />
        </Dimmer.Dimmable>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    postPaymentSchemeStatus: state.CustomerProfileStore.postPaymentSchemeStatus,
    postPaymentScheme: state.CustomerProfileStore.postPaymentScheme,
    putPaymentSchemeStatus: state.CustomerProfileStore.putPaymentSchemeStatus,
    putPaymentScheme: state.CustomerProfileStore.putPaymentScheme,
    loading: state.CustomerProfileStore.loading,
    errorMessage: state.CustomerProfileStore.errors.global,
    getPaymentSchemeListStatus:
      state.CustomerProfileStore.getPaymentSchemeListStatus,
    getPaymentSchemeList: state.CustomerProfileStore.getPaymentSchemeList,
  };
}
export default connect(
  mapStateToProps,
  {
    getPaymentSchemeByIdAction,
    getPaymentSchemeAction,
    resetCustomerProfileAction,
    putPaymentSchemeAction,
  }
)(PaymentScheme);
