const styles = {
  noPaddingTop: {
    paddingTop: '0px',
  },
  noPaddingBottom: {
    paddingBottom: '0px',
  },
  paddingTop10px: {
    paddingTop: '10px',
  },
  noPaddingMarginLeft3em: {
    padding: '0px',
    marginLeft: '3em',
  },
  noMargin: {
    margin: '3px',
  },
  collapse: {
    background: '#fefefe',
    color: '#38af95',
    padding: '0px',
    border: 'none',
  },
  marginDailyProgramDays: {
    marginLeft: '3em',
  },
  marginContentInsideCard: {
    margin: '1em',
  },
  marginHeader: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  styleCard: {
    border: 'none',
    marginTop: '2em',
  },
};

export default styles;
