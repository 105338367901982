import { TOUR_OPERATOR_EMAIL_SETTING_URL } from '../apiUrl';
import apiClient from '../apiClient';
const getTourOperatorEmailSettingAllApi = () => {
  return apiClient.get(TOUR_OPERATOR_EMAIL_SETTING_URL);
};
const getTourOperatorEmailSettingByIdApi = id => {
  return apiClient.get(`${TOUR_OPERATOR_EMAIL_SETTING_URL}/byId/${id}`);
};
const postTourOperatorEmailSettingApi = data => {
  return apiClient.post(`${TOUR_OPERATOR_EMAIL_SETTING_URL}/add`, data);
};
const putTourOperatorEmailSettingApi = (id, data) => {
  return apiClient.put(`${TOUR_OPERATOR_EMAIL_SETTING_URL}/edit/${id}`, data);
};
const deleteTourOperatorEmailSettingApi = id => {
  return apiClient.delete(`${TOUR_OPERATOR_EMAIL_SETTING_URL}/delete/${id}`);
};
const getTourOperatorEmailSettingByIdTourOperatorApi = id => {
  return apiClient.get(
    `${TOUR_OPERATOR_EMAIL_SETTING_URL}/getByTourOperatorProfile/${id}`
  );
};

export {
  getTourOperatorEmailSettingAllApi,
  getTourOperatorEmailSettingByIdApi,
  postTourOperatorEmailSettingApi,
  putTourOperatorEmailSettingApi,
  deleteTourOperatorEmailSettingApi,
  getTourOperatorEmailSettingByIdTourOperatorApi,
};
