import {
  GET_COUNTRY,
  RESET_COUNTRY,
  GET_COUNTRY_LIST,
  GET_COUNTRY_BY_ID,
} from '../constants/actionTypes';
import getCountryApi from '../../../api/country/getCountryApi';
import getCountryListApi from '../../../api/country/getCountryListApi';
import getCountryByIdApi from '../../../api/country/getCountryByIdApi';

const getCountryListAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_COUNTRY,
      payload: getCountryApi(),
    });
  };
};

const resetCountryAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_COUNTRY,
    });
  };
};
const getCountryAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_COUNTRY_LIST,
      payload: getCountryListApi(),
    });
  };
};

const getCountryByIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_COUNTRY_BY_ID,
      payload: getCountryByIdApi(Id),
    });
  };
};

export {
  getCountryListAction,
  resetCountryAction,
  getCountryAction,
  getCountryByIdAction,
};
