const restautantProfileIdField = [
  'Name',
  'Description',
  'RatingId',
  'RestaurantType',
  'OperationStartTime',
  'OperationEndTime',
];
const addressObjectField = [
  'Address1',
  'CityId',
  'CountryId',
  'RegionId',
  'AreaId',
  'PhoneNbr',
  'Email',
];
export { restautantProfileIdField, addressObjectField };
