import apiClient from '../apiClient';
import { GET_PRINT_PAYMENT_RECEIPT_URL } from '../apiUrl';

const getPrintPaymentReceiptTransactionApi = (transactionId, invoiceCode) => {
  return apiClient.get(
    GET_PRINT_PAYMENT_RECEIPT_URL(transactionId, invoiceCode)
  );
};

export default getPrintPaymentReceiptTransactionApi;
