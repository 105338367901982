const setCustomerData = data => {
  const {
    Name,
    ImageName,
    ImageUrl,
    PaymentSchemeType,
    IsAllowedOwnCountryTour,
    CountryId,
    Address,
    Email,
    Telephone,
    FaxNbr,
    TradelicenseNbr,
    AssociationNumber,
    IATANbr,
    EmployeeRange,
    BranchRange,
    RevenueRange,
    Code,
    Currency,
    CompanyLogo,
  } = data;
  let customerProfileData = {
    CompanyId: Code ? Code : '',
    Name: Name ? Name : '',
    Address: Address ? Address : '',
    Telephone: Telephone ? Telephone : '',
    Email: Email ? Email : '',
    FaxNbr: FaxNbr ? FaxNbr : '',
    IATANbr: IATANbr ? IATANbr : '',
    TradeLicenseNbr: TradelicenseNbr ? TradelicenseNbr : '',
    AssociationNumber: AssociationNumber ? AssociationNumber : '',
    EmployeeRangeId: EmployeeRange && EmployeeRange.Id ? EmployeeRange.Id : '',
    BranchRangeId: BranchRange && BranchRange.Id ? BranchRange.Id : '',
    RevenueRangeId: RevenueRange && RevenueRange.Id ? RevenueRange.Id : '',
    CountryId: CountryId ? CountryId : '',
    CurrencyId: Currency ? Currency : '',
    CompanyLogo: {
      ImageContent: CompanyLogo ? CompanyLogo.ImageUrl : '',
      ImageName: CompanyLogo ? CompanyLogo.ImageName : '',
      ImagePreview: CompanyLogo ? CompanyLogo.ImageUrl : '',
    },
    IsAllowedOwnCountryTour: IsAllowedOwnCountryTour
      ? IsAllowedOwnCountryTour
      : false,
    ImageName: ImageName ? ImageName : '',
    ImageContent: ImageUrl ? ImageUrl : '',
    ImagePreview: ImageUrl ? ImageUrl : '',
    PaymentSchemeId: PaymentSchemeType
      ? PaymentSchemeType.Id
        ? PaymentSchemeType.Id
        : ''
      : '',
    CompanyBusiness: 2,
  };

  return customerProfileData;
};

export { setCustomerData };
