import React, { Component } from 'react';
import { Table, Input, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { HeaderBalanceDetailTransaction } from '../headerTabel';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../scripts/pagination';
import FilterData from '../../../scripts/filterDataTable';
import Pagination from '../../common/pagination';

class tableBalanceDetailHistory extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
    };
  }
  static propTypes = {
    data: PropTypes.array,
  };
  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  render() {
    const props = this.props;
    const { searchInput, activePage } = this.state;
    let filterData = FilterData(this.props.listData, 'CashFlow', searchInput);
    let pagedData = datasetPagination(filterData, 3);
    let listData = pagedData[activePage - 1];

    let { numbersFrom, numbersTo } = getNumberFromTo(listData, activePage, 3);
    return (
      <div>
        <Header as="h2" content="History Transaction" />
        <Input
          icon="search"
          placeholder="search by transaction type"
          name="searchInput"
          value={this.state.searchInput}
          onChange={this.handleChange}
        />
        <Table basic="very" selectable fixed>
          <Table.Header>
            <Table.Row>
              {HeaderBalanceDetailTransaction.map((headTableContent, index) => {
                return (
                  <Table.HeaderCell key={index}>
                    {headTableContent}
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {listData &&
              listData.map((historyTransaction, idx) => {
                return (
                  <Table.Row key={idx}>
                    <Table.Cell>
                      {moment(historyTransaction.DateTime).format(
                        'DD MMMM YYYY'
                      )}
                    </Table.Cell>
                    <Table.Cell>{historyTransaction.CashFlowType}</Table.Cell>
                    <Table.Cell>
                      {'(' +
                        historyTransaction.CurrencyId +
                        ')           ' +
                        new Intl.NumberFormat('de-DE').format(
                          historyTransaction.Value
                        )}
                    </Table.Cell>
                    <Table.Cell> {historyTransaction.Description}</Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        {/* {pagedData &&
          pagedData.length > 1 && ( */}
        {/* <Table.Footer> */}
        <Pagination
          // totalPages={pagedData.length}
          // onPageChange={this.pageChange}
          // activePage={this.state.activePage}
          // style={{ float: 'right' }}
          pagedData={pagedData}
          numbersTo={numbersTo}
          numbersFrom={numbersFrom}
          data={props.listData}
          pageChange={this.pageChange}
          activePage={this.state.activePage}
        />
        {/* </Table.Footer> */}
        {/* )} */}
        {/* {pagedData &&
          pagedData.length > 1 && (
            <p>
              Showing {numbersTo} to {numbersFrom} of
              {this.props.listData.length}
            </p>
          )} */}
      </div>
    );
  }
}

tableBalanceDetailHistory.propTypes = {
  listData: PropTypes.array,
};
export default tableBalanceDetailHistory;
