import {
  GET_CUSTOMER_PROFILE_LIST,
  RESET_STATUS_CUSTOMER_PROFILE,
  GET_CUSTOMER_PROFILE_BY_CODE,
  PUT_CUSTOMER_PROFILE,
  // GET_HISTORY_TRANSACTION_BY_CODE,
  GET_USER_DATA_BY_CODE,
  GET_BALANCE_HISTORY_BY_CODE,
  POST_BALANCE_DETAIL,
  GET_PAYMENT_SCHEME,
  POST_CUSTOMER_PROFILE,
  GET_USER_PROFILE_ACCOUNT_MANAGER,
  GET_USER_PROFILE_ACCOUNT_MANAGER_BY_COMPANY,
  POST_CREATE_CUSTOMER_ON_BE_HALF,
  POST_PAYMENT_SCHEME,
  PUT_PAYMENT_SCHEME,
  GET_PAYMENT_SCHEME_BY_ID,
  GET_PAYMENT_SCHEME_DETAIL_BY_ID,
  PUT_PAYMENT_SCHEME_DETAIL,
  POST_PAYMENT_SCHEME_DETAIL,
  DELETE_PAYMENT_SCHEME_DETAIL,
  DELETE_USER_PROFILE,
  POST_USER_PROFILE_ACTIVATE,
  GET_CUSTOMER_PROFILE_FOR_SALES,
  GET_USER_DATA_BY_CODE_FOR_SALES,
  GET_LIST_TOTAL_EMPLOYEE,
} from '../constants/actionTypes';
import {
  getCustomerProfileListApi,
  getCustomerProfileListForSalesApi,
} from '../../../api/customerProfile/getCustomerProfileListApi';
import {
  getCustomerProfileByCodeApi,
  getCustomerProfileByCodeManageBySalesApi,
} from '../../../api/customerProfile/getCustomerProfileByCodeApi';
import putCustomerProfileApi from '../../../api/customerProfile/putCustomerProfileApi';
import {
  getUserDataByCodeApi,
  getUserDataByCodeForSalesApi,
} from '../../../api/customerProfile/getUserDataByCodeApi';
// import getHistoryTransactionByCodeApi from '../../../api/customerProfile/getHistoryTransactionByCodeApi';
import getBalanceHistoryByCompanyCodeApi from '../../../api/customerProfile/getBalanceHistoryByCompanyCodeApi';
import postBalanceDetailApi from '../../../api/customerProfile/postBalanceDetailApi';
import getPaymentSchemeApi from '../../../api/customerProfile/getPaymentSchemeApi';
import postCustomerProfileApi from '../../../api/customerProfile/postCustomerProfileApi';
import getAccountManagerApi from '../../../api/customerProfile/getAccountManagerApi';
import getAccountManagerByCompanyApi from '../../../api/customerProfile/getAccountManagerByCompanyApi';
import {
  postCreateCustomerOnBehalfApi,
  getListTotalEmployeeApi,
} from '../../../api/customerProfile/customerProfileApi';
import postPaymentSchemeApi from '../../../api/paymentScheme/postPaymentSchemeApi';
import putPaymentSchemeApi from '../../../api/paymentScheme/putPaymentSchemeApi';
import getPaymentSchemeByIdApi from '../../../api/paymentScheme/getPaymentSchemeByIdApi';
import getPaymentSchemeDetailByIdApi from '../../../api/paymentScheme/getPaymentSchemeDetailByIdApi';
import putPaymentSchemeDetailApi from '../../../api/paymentScheme/putPaymentSchemeDetailApi';
import postPaymentSchemeDetailApi from '../../../api/paymentScheme/postPaymentSchemeDetailApi';
import deletePaymentSchemeDetailApi from '../../../api/paymentScheme/deletePaymentSchemeDetailApi';
import deleteUserProfileApi from '../../../api/customerProfile/deleteUserProfile';
import postUserProfileActivateApi from '../../../api/customerProfile/postUserProfileActivate';

const getCustomerProfileListAction = type => {
  return dispatch => {
    return dispatch({
      type: GET_CUSTOMER_PROFILE_LIST,
      payload: getCustomerProfileListApi(type),
    });
  };
};

const getCustomerProfileListForSalesAction = type => {
  return dispatch => {
    return dispatch({
      type: GET_CUSTOMER_PROFILE_FOR_SALES,
      payload: getCustomerProfileListForSalesApi(type),
    });
  };
};

const resetCustomerProfileAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_STATUS_CUSTOMER_PROFILE,
    });
  };
};

const getCustomerProfileByCodeAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_CUSTOMER_PROFILE_BY_CODE,
      payload: getCustomerProfileByCodeApi(Id),
    });
  };
};

const getCustomerProfileByCodeManageBySalesAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_CUSTOMER_PROFILE_BY_CODE,
      payload: getCustomerProfileByCodeManageBySalesApi(Id),
    });
  };
};

const putCustomerProfileAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_CUSTOMER_PROFILE,
      payload: putCustomerProfileApi(id, data),
    });
  };
};

// const getHistoryTransactionByCodeAction = Id => {
//   return dispatch => {
//     return dispatch({
//       type: GET_HISTORY_TRANSACTION_BY_CODE,
//       payload: getHistoryTransactionByCodeApi(Id),
//     });
//   };
// };

const getUserDataByCodeAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_USER_DATA_BY_CODE,
      payload: getUserDataByCodeApi(Id),
    });
  };
};
const getUserDataByCodeForSalesAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_USER_DATA_BY_CODE_FOR_SALES,
      payload: getUserDataByCodeForSalesApi(Id),
    });
  };
};
const getBalanceHistoryByCompanyCodeAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_BALANCE_HISTORY_BY_CODE,
      payload: getBalanceHistoryByCompanyCodeApi(Id),
    });
  };
};

const postBalanceDetailAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_BALANCE_DETAIL,
      payload: postBalanceDetailApi(data),
    });
  };
};

const getPaymentSchemeAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_PAYMENT_SCHEME,
      payload: getPaymentSchemeApi(),
    });
  };
};

const postCustomerProfileAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_CUSTOMER_PROFILE,
      payload: postCustomerProfileApi(data),
    });
  };
};

const getAccountManagerAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_USER_PROFILE_ACCOUNT_MANAGER,
      payload: getAccountManagerApi(),
    });
  };
};

const getAccountManagerByCompanyAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_USER_PROFILE_ACCOUNT_MANAGER_BY_COMPANY,
      payload: getAccountManagerByCompanyApi(),
    });
  };
};

const postCreateCustomerOnBehalfAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_CREATE_CUSTOMER_ON_BE_HALF,
      payload: postCreateCustomerOnBehalfApi(data),
    });
  };
};

const postPaymentSchemeAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_PAYMENT_SCHEME,
      payload: postPaymentSchemeApi(data),
    });
  };
};

const putPaymentSchemeAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_PAYMENT_SCHEME,
      payload: putPaymentSchemeApi(id, data),
    });
  };
};

const getPaymentSchemeByIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_PAYMENT_SCHEME_BY_ID,
      payload: getPaymentSchemeByIdApi(Id),
    });
  };
};

const postPaymentSchemeDetailAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_PAYMENT_SCHEME_DETAIL,
      payload: postPaymentSchemeDetailApi(data),
    });
  };
};

const putPaymentSchemeDetailAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_PAYMENT_SCHEME_DETAIL,
      payload: putPaymentSchemeDetailApi(id, data),
    });
  };
};

const getPaymentSchemeDetailByIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_PAYMENT_SCHEME_DETAIL_BY_ID,
      payload: getPaymentSchemeDetailByIdApi(Id),
    });
  };
};

const deletePaymentSchemeDetailAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: DELETE_PAYMENT_SCHEME_DETAIL,
      payload: deletePaymentSchemeDetailApi(id, data),
    });
  };
};

const deleteUserProfileByIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: DELETE_USER_PROFILE,
      payload: deleteUserProfileApi(Id),
    });
  };
};

const postUserProfileActivateAction = Id => {
  return dispatch => {
    return dispatch({
      type: POST_USER_PROFILE_ACTIVATE,
      payload: postUserProfileActivateApi(Id),
    });
  };
};

const getListTotalEmployeeAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_LIST_TOTAL_EMPLOYEE,
      payload: getListTotalEmployeeApi(),
    });
  };
};
export {
  getCustomerProfileListAction,
  resetCustomerProfileAction,
  putCustomerProfileAction,
  getCustomerProfileByCodeAction,
  // getHistoryTransactionByCodeAction,
  getUserDataByCodeAction,
  getBalanceHistoryByCompanyCodeAction,
  postBalanceDetailAction,
  getPaymentSchemeAction,
  postCustomerProfileAction,
  getAccountManagerAction,
  getAccountManagerByCompanyAction,
  postCreateCustomerOnBehalfAction,
  postPaymentSchemeAction,
  putPaymentSchemeAction,
  getPaymentSchemeByIdAction,
  postPaymentSchemeDetailAction,
  putPaymentSchemeDetailAction,
  getPaymentSchemeDetailByIdAction,
  deletePaymentSchemeDetailAction,
  deleteUserProfileByIdAction,
  postUserProfileActivateAction,
  getCustomerProfileListForSalesAction,
  getUserDataByCodeForSalesAction,
  getListTotalEmployeeAction,
  getCustomerProfileByCodeManageBySalesAction,
};
