import React from 'react';
import { Table, Button, Input, Checkbox } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import InputWithLabel from '../../../shared/components/common/input/InputWithLabel';

const TableCellComponentMasterReference = props => {
  let idxPagination = props.idxPagination;
  let masterData = props.masterData;
  return props.triggerTabelCell === 1 ? (
    <Table.Row key={idxPagination}>
      <Table.Cell>
        <Input
          selection
          search
          placeholder={masterData.Id}
          name={'Id,' + idxPagination}
          onChange={props.handleChangeMasterData}
          value={masterData.Id}
          disabled={masterData.disabled}
          maxLength={props.maxLength}
        />
      </Table.Cell>
      <Table.Cell>
        <Input
          placeholder="Name"
          name={'Name,' + idxPagination}
          type="text"
          onChange={props.handleChangeMasterData}
          value={masterData.Name || masterData.Description}
        />
      </Table.Cell>
      <Table.Cell>
        <Input
          name={
            (masterData.Description ? 'Description,' : 'Capacity,') +
            idxPagination
          }
          type="text"
          onChange={props.handleChangeMasterData}
          value={masterData.Description || masterData.Capacity}
        />
      </Table.Cell>
      <Table.Cell>
        <Button
          id={masterData.Id}
          negative
          content="Delete"
          onClick={props.deleteDataReference}
        />
      </Table.Cell>
    </Table.Row>
  ) : props.triggerTabelCell === 2 ? (
    <Table.Row key={idxPagination}>
      <Table.Cell>
        <Input
          selection
          search
          placeholder={masterData.Id}
          name={'Id,' + idxPagination}
          onChange={props.handleChangeMasterData}
          value={masterData.Id}
          disabled={masterData.disabled}
        />
      </Table.Cell>
      <Table.Cell>
        <InputWithLabel
          type="select"
          placeholder="Country Id"
          name={'CountryId,' + idxPagination}
          handleChange={props.handleChangeMasterData}
          value={masterData.CountryId}
          options={props.listCountry}
          loading={props.loadingCountry}
        />
      </Table.Cell>
      <Table.Cell>
        <Input
          placeholder="Identity"
          name={'IdentityType,' + idxPagination}
          type="text"
          onChange={props.handleChangeMasterData}
          value={masterData.IdentityType}
        />
      </Table.Cell>
      <Table.Cell>
        <Button
          id={masterData.Id}
          negative
          content="Delete"
          onClick={props.deleteDataReference}
        />
      </Table.Cell>
    </Table.Row>
  ) : props.triggerTabelCell === 3 ? (
    <Table.Row key={idxPagination}>
      <Table.Cell>
        <Input
          selection
          search
          placeholder={masterData.Id}
          name={'Id,' + idxPagination}
          onChange={props.handleChangeMasterData}
          value={masterData.Id}
          disabled={masterData.disabled}
        />
      </Table.Cell>
      <Table.Cell>
        <Input
          placeholder="Category"
          name={'Category,' + idxPagination}
          type="text"
          onChange={props.handleChangeMasterData}
          value={masterData.Category}
        />
      </Table.Cell>
      <Table.Cell>
        <Input
          placeholder="Value"
          name={'Value,' + idxPagination}
          type="text"
          onChange={props.handleChangeMasterData}
          value={masterData.Value}
        />
      </Table.Cell>
      <Table.Cell>
        <Button
          id={masterData.Id}
          negative
          content="Delete"
          onClick={props.deleteDataReference}
        />
      </Table.Cell>
    </Table.Row>
  ) : props.triggerTabelCell === 4 ? (
    <Table.Row key={idxPagination}>
      <Table.Cell>
        <Input
          selection
          search
          placeholder={masterData.Id}
          name={'Id,' + idxPagination}
          onChange={props.handleChangeMasterData}
          value={masterData.Id}
          disabled={true}
          maxLength={props.maxLength}
        />
      </Table.Cell>
      <Table.Cell>
        <Input
          placeholder="English Name"
          name={'EnglishName,' + idxPagination}
          type="text"
          onChange={props.handleChangeMasterData}
          value={masterData.EnglishName}
        />
      </Table.Cell>
      <Table.Cell>
        <Input
          placeholder="Local Name"
          name={'LocalName,' + idxPagination}
          type="text"
          onChange={props.handleChangeMasterData}
          value={masterData.LocalName}
        />
      </Table.Cell>
      <Table.Cell>
        <Button
          id={masterData.Id}
          negative
          content="Delete"
          onClick={props.deleteDataReference}
        />
      </Table.Cell>
    </Table.Row>
  ) : props.triggerTabelCell === 6 ? (
    <Table.Row key={idxPagination}>
      <Table.Cell>
        <Input
          selection
          search
          placeholder="Job Code"
          name={'Code,' + idxPagination}
          onChange={props.handleChangeMasterData}
          value={masterData.Code}
          disabled={masterData.disabled}
          maxLength={props.maxLength}
        />
      </Table.Cell>
      <Table.Cell>
        <Input
          placeholder="Job Title"
          name={'Name,' + idxPagination}
          type="text"
          onChange={props.handleChangeMasterData}
          value={masterData.Name}
        />
      </Table.Cell>
      <Table.Cell>
        <Input
          placeholder="Job Position Level"
          name={'Level,' + idxPagination}
          type="text"
          onChange={props.handleChangeMasterData}
          value={masterData.Level}
        />
      </Table.Cell>
      <Table.Cell>
        <Button
          id={masterData.Id}
          negative
          content="Delete"
          onClick={props.deleteDataReference}
        />
      </Table.Cell>
    </Table.Row>
  ) : props.triggerTabelCell === 8 ? (
    <Table.Row key={idxPagination}>
      <Table.Cell>
        <Input
          selection
          search
          placeholder={masterData.Id}
          name={'Id,' + idxPagination}
          onChange={props.handleChangeMasterData}
          value={masterData.Id}
          disabled={masterData.disabled}
        />
      </Table.Cell>
      <Table.Cell>
        <Input
          placeholder="Name"
          name={'TypeName,' + idxPagination}
          type="text"
          onChange={props.handleChangeMasterData}
          value={masterData.TypeName}
        />
      </Table.Cell>
      <Table.Cell>
        <Checkbox
          name={'IsRestrictedCountry,' + idxPagination}
          onChange={props.handleChangeMasterData}
          checked={!masterData.IsRestrictedCountry}
        />
      </Table.Cell>
      <Table.Cell>
        <Button
          id={masterData.Id}
          negative
          content="Delete"
          onClick={props.deleteDataReference}
        />
      </Table.Cell>
    </Table.Row>
  ) : (
    <Table.Row key={idxPagination}>
      <Table.Cell>
        <Input
          selection
          search
          placeholder={masterData.Id}
          name={'Id,' + idxPagination}
          onChange={props.handleChangeMasterData}
          value={masterData.Id}
          disabled={masterData.disabled}
        />
      </Table.Cell>
      <Table.Cell>
        <Input
          placeholder="Name"
          name={'Name,' + idxPagination}
          type="text"
          onChange={props.handleChangeMasterData}
          value={masterData.Name}
        />
      </Table.Cell>

      <Table.Cell>
        <Button
          id={masterData.Id}
          negative
          content="Delete"
          onClick={props.deleteDataReference}
        />
      </Table.Cell>
    </Table.Row>
  );
};

TableCellComponentMasterReference.propTypes = {
  deleteDataReference: PropTypes.func,
  triggerTabelCell: PropTypes.number,
  masterData: PropTypes.object,
  idxPagination: PropTypes.number,
  handleChangeMasterData: PropTypes.func,
  maxLength: PropTypes.string,
  listCountry: PropTypes.array,
  loadingCountry: PropTypes.bool,
};

export default TableCellComponentMasterReference;
