import apiClient from '../apiClient';
import { POST_SET_PRIMARY_TRANSPORTATION_UNIT_HEADER_URL } from '../apiUrl';

const postSetPrimaryImageTransportaionUnitHeaderApi = (id, data) => {
  return apiClient.post(
    POST_SET_PRIMARY_TRANSPORTATION_UNIT_HEADER_URL(id),
    data
  );
};

export default postSetPrimaryImageTransportaionUnitHeaderApi;
