import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import InputWithLabel from '../../../../../../components/input/inputWithLabel';
// import LineDivider from '../common/lineDivider';
import styles from '../../Styles';

const FormRegisterCompany = props => {
  const {
    handleChange,
    companyName,
    companyCountry,
    companyTelephone,
    companyAddress,
    companyCurrency,
    countryOptions,
    currencyOptions,
    loading,
    validate,
  } = props;

  return (
    <div>
      <div>
        <h4 style={styles.h4}>
          <strong>Travel Agent Data</strong>
        </h4>
      </div>
      <div>
        <hr />
      </div>
      <Form>
        <InputWithLabel
          label="Company Name"
          placeholder="Company Name"
          inline
          required
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={handleChange}
          value={companyName.data}
          name="companyName"
          validate={validate}
        />
        <InputWithLabel
          label="Country"
          placeholder="Select Country"
          inline
          required
          options={countryOptions}
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={handleChange}
          value={companyCountry.data}
          name="companyCountry"
          type="select"
          loading={loading}
          validate={validate}
        />
        <InputWithLabel
          label="Company Telephone"
          placeholder="Company Telephone"
          inline
          required
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={handleChange}
          value={companyTelephone.data}
          name="companyTelephone"
          validate={validate}
          type="number"
        />
        <InputWithLabel
          label="Company Address"
          placeholder="Company Address"
          inline
          required
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={handleChange}
          value={companyAddress.data}
          name="companyAddress"
          type="textArea"
          validate={validate}
        />
        <InputWithLabel
          label="Based Currency"
          placeholder="Select Type"
          inline
          required
          options={currencyOptions}
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={handleChange}
          value={companyCurrency.data}
          name="companyCurrency"
          type="select"
          loading={loading}
          validate={validate}
        />
      </Form>
    </div>
  );
};

FormRegisterCompany.propTypes = {
  handleChange: PropTypes.func,
  companyName: PropTypes.string,
  companyCountry: PropTypes.string,
  companyTelephone: PropTypes.string,
  companyAddress: PropTypes.string,
  companyCurrency: PropTypes.string,
  countryOptions: PropTypes.array,
  currencyOptions: PropTypes.array,
  validate: PropTypes.object,
  loading: PropTypes.bool,
};

export default FormRegisterCompany;
