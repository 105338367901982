import React, { Component } from 'react';
import { Table, Grid, Header, Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../scripts/pagination';
import FilterData from '../../../scripts/filterDataTable';
import IndexPagination from '../../../components/genericFunction/indexPagination';
import TableCellMasterDataReference from './table/TableCellComponentMasterReference';
import InputWithLabel from '../../../components/input/inputWithLabel';
import Pagination from '../../../components/common/pagination';
import styles from '../Styles';
class SegmentMasterDataReference extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
  }
  static propTypes = {
    data: PropTypes.array,
  };

  handleChange = (e, { value }) => {
    this.props.handleChangeSearch(value);
  };
  pageChange = (e, { activePage }) => {
    this.props.pageChange(activePage);
  };
  render() {
    let filterData = FilterData(
      this.props.listData,
      'MasterRefrence',
      this.props.searchInput
    );
    let pagedData = datasetPagination(filterData, 10);
    let listData = pagedData[this.props.activePage - 1];
    const props = this.props;

    let { numbersFrom, numbersTo } = getNumberFromTo(
      listData,
      props.activePage,
      10
    );
    return (
      <div>
        <Grid>
          <Grid.Row columns="equal">
            <Grid.Column>
              {props.triggerTabelCell && (
                <Form>
                  <InputWithLabel
                    inline={false}
                    type="text"
                    label="Search"
                    name="Search"
                    handleChange={this.handleChange}
                    value={this.props.searchInput}
                  />
                </Form>
              )}
            </Grid.Column>
            <Grid.Column />
            <Grid.Column />
          </Grid.Row>
          <Grid.Row columns="equal">
            <Grid.Column>
              {props.triggerTabelCell ? (
                <Table basic="very" selectable>
                  {/* <div style={{ overflow: 'auto' }}> */}
                  <Table.Header>
                    <Table.Row>
                      {this.props.headerTableMasterData.map(
                        (headTableContent, index) => {
                          return (
                            <Table.HeaderCell key={index}>
                              {headTableContent}
                            </Table.HeaderCell>
                          );
                        }
                      )}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {listData &&
                      listData.map((masterData, idx) => {
                        const idxPagination = IndexPagination(
                          10,
                          this.props.activePage,
                          idx
                        );
                        return (
                          <TableCellMasterDataReference
                            key={idxPagination}
                            triggerTabelCell={props.triggerTabelCell}
                            idxPagination={idxPagination}
                            masterData={masterData}
                            deleteDataReference={props.deleteDataReference}
                            handleChangeMasterData={
                              props.handleChangeMasterData
                            }
                            maxLength={props.maxLength}
                            listCountry={props.listCountry}
                            loadingCountry={props.loadingCountry}
                          />
                        );
                      })}
                  </Table.Body>
                  {!listData || (listData && listData.length < 1) ? (
                    <Table.Row>
                      <Table.Cell colSpan="6" textAlign="center">
                        <Header as="h3" style={styles.headerStyle}>
                          No data found
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {/* </div> */}
                </Table>
              ) : (
                <Header as="h3" style={styles.headerStyle}>
                  Please Choose Menu
                </Header>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div>
          <Pagination
            pagedData={pagedData}
            numbersTo={numbersTo}
            numbersFrom={numbersFrom}
            data={props.listData}
            pageChange={this.pageChange}
            activePage={this.props.activePage}
          />
        </div>
      </div>
    );
  }
}

SegmentMasterDataReference.propTypes = {
  listData: PropTypes.array,
  addMasterReference: PropTypes.func,
  handleChangeMasterData: PropTypes.func,
  deleteDataReference: PropTypes.func,
  headerTableMasterData: PropTypes.array,
  triggerTabelCell: PropTypes.number,
  getMasterReferenceStatus: PropTypes.string,
  handleChangeSearch: PropTypes.func,
  searchInput: PropTypes.string,
  activePage: PropTypes.number,
  pageChange: PropTypes.func,
  maxLength: PropTypes.string,
  listCountry: PropTypes.array,
  loadingCountry: PropTypes.bool,
};
export default SegmentMasterDataReference;
