import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Segment, Grid, Button, Form, Label } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from './styles';
import InputWithLabel from '../input/inputWithLabel';
import ModalSetRoomAllotment from '../modal/modalSetRoomAllotment';
import { convertDateFormat } from '../../scripts/moment';
import {
  setMonth,
  setDayDate,
  selectEndDayDate,
  setRoomAllotment,
  changeAllotment,
} from '../../scripts/roomAllotment';
import { datasetPagination } from '../../scripts/pagination';
import {
  getRoomAllotmentByProfileId,
  postRoomAllotmentDay,
} from './../../pages/shared/actions/accommodationAction';
// } from './../../actions/accomodationAction';
// import Loader from './../loader/loader';
import ModalAlert from './../modal/modalAlert';
import { Redirect } from 'react-router-dom';

let TODAY = new Date();

export const setDate = () => {
  for (var i = 0; i < 12; i++) {
    let months = convertDateFormat(new Date(), 'DD MMM YYYY');
    let setYearMonthDate = new Date(2019, 0, 1);
    let changeDate = new Date(setYearMonthDate.setDate(2));
    let month = changeDate.getMonth();
    let datex = new Date(changeDate.setMonth(month + i));
    let result = { year: datex.getFullYear(), month: months[datex.getMonth()] };
    return result;
  }
};

class segmentRoomAllotment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      actionType: '',
      openModalAllotment: false,
      quantity: 0,
      accomodationName: '',
      month: [],
      selectedMonth: null,
      dates: [],
      activePage: 1,
      roomAllotments: [],
      allotment: 0,
      activePageMonth: 1,
      linkGoTo: null,
      reload: false,
    };
  }

  componentDidMount() {
    // const { idProfile, accomodationItem } = this.props;
    this.initialRoomAllotment(TODAY);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.isNeedUpdateRoomAllotment === true) {
      this.getRoomAllotmentByProfileId();
    }
  }

  // ======================== GET ROOM ALLOTMENT =========================
  getRoomAllotmentByProfileId = () => {
    this.props
      .getRoomAllotmentByProfileId(
        this.props.idProfile,
        convertDateFormat(this.state.dates[0], 'YYYY-MM-DD'),
        convertDateFormat(selectEndDayDate(this.state.dates), 'YYYY-MM-DD')
      )
      .then(() =>
        this.setRoomAllotment(
          this.props.roomAllotments,
          this.state.dates,
          this.props.defaultReleaseDays
        )
      )
      .catch(() =>
        this.setState({
          ...this.state,
          modalAlertOpen: true,
          modalAlertHeader: 'Failed',
          modalAlertMessage: 'Failed Get Room Allotment',
        })
      );
  };

  // ====================== END GET ROOM ALLOTMENT =======================
  // ====================== INITIAL ROOM ALLOTMENT =======================
  initialRoomAllotment = async today => {
    let month = await setMonth(today, 24); // ganti jadi 24 bulan
    let selectedMonth = month.length !== 0 ? month[0] : null;
    let dates = (await selectedMonth)
      ? setDayDate(selectedMonth.startDate)
      : [];
    this.setState(
      {
        ...this.state,
        month,
        selectedMonth,
        dates,
      },
      () => this.getRoomAllotmentByProfileId()
    );
  };
  // =================== END INITIAL ROOM ALLOTMENT =======================
  setRoomAllotment = async (roomAllotments, dates, defaultReleaseDays) => {
    let newRoomAllotments = await setRoomAllotment(
      roomAllotments,
      dates,
      defaultReleaseDays
    );
    this.setState({ ...this.state, roomAllotments: newRoomAllotments });
  };
  // =========================== Month ===================================
  handleMonth = (e, { name, value }) => {
    this.initialDates(name, value);
  };
  initialDates = (name, value) => {
    let dates = setDayDate(value.startDate);
    this.setState(
      {
        ...this.state,
        [name]: value,
        dates,
        activePage: 1,
      },
      () => this.getRoomAllotmentByProfileId()
    );
  };
  renderBtnGroup = (month, selectedMonth) => {
    return month.map((item, i) => {
      return (
        <Grid.Column key={i} style={{ padding: '0px 2px' }}>
          <Button
            fluid
            value={item}
            name="selectedMonth"
            style={
              item.month === selectedMonth.month
                ? styles.buttonAdmin
                : styles.buttonBorderBlack
            }
            onClick={this.handleMonth}
          >
            {item.year} <br />
            {item.month}
          </Button>
        </Grid.Column>
      );
    });
  };
  // =========================== END Month ===================================
  // ============================ Dates =======================================
  renderHeaderDate = dates => {
    return dates.map((item, i) => {
      return (
        <Grid.Column textAlign="center" key={i} style={styles.noPadding}>
          <div style={item ? styles.calender50 : styles.calender50withHeight}>
            {item && convertDateFormat(item, 'ddd')}
          </div>
          <div style={item ? styles.calender20 : styles.calender20withHeight}>
            {item && convertDateFormat(item, 'DD')}
          </div>
        </Grid.Column>
      );
    });
  };
  // ============================ END Dates =======================================
  // ============================== Room ========================================
  handleChangeAllotment = e => {
    let { name, value } = e.target;
    const roomAllotments = [...this.state.roomAllotments];
    let splitName = name.split('_');
    let newRoomAllotments = changeAllotment(
      roomAllotments,
      splitName[0],
      splitName[1],
      value
    );
    this.setState({ ...this.state, roomAllotments: newRoomAllotments });
  };
  handleAllotmentDay = e => {
    let { name, value } = e.target;
    let splitName = name.split('_');
    let data = {
      ServiceItemId: parseInt(splitName[0], 10),
      Date: splitName[1],
      ReleaseDays: splitName[3] === 'true' ? parseInt(splitName[2], 10) : null,
      Qty: parseInt(value, 10),
    };
    this.postRoomAllotmentDay(data);
  };
  postRoomAllotmentDay = data => {
    this.props
      .postRoomAllotmentDay(data)
      // .then(() => this.getRoomAllotmentByProfileId())
      .catch(error => {
        this.setState(
          {
            ...this.state,
            modalAlertOpen: true,
            modalAlertMessage: error.response.data.Message,
            modalAlertHeader: 'Failed',
            reload: true,
          },
          () =>
            this.setRoomAllotment(
              this.props.roomAllotments,
              this.state.dates,
              this.props.defaultReleaseDays
            )
        );
      });
  };

  renderRooms = (roomAllotments, activePage, disablePage) => {
    return roomAllotments.map((item, i) => {
      return (
        <Grid.Row key={'room' + i} style={{ padding: '5px 0px' }}>
          <Grid.Column verticalAlign="middle" textAlign="right" width={3}>
            {item.ServiceItemName} {item.IsPromo ? ' (Promo Item)' : ''}
          </Grid.Column>
          <Grid.Column width={1} />
          <Grid.Column width={11}>
            <Grid>
              <Grid.Row columns={7}>
                {this.renderAllotment(
                  item.ServiceItemId,
                  item.RoomAllotments,
                  activePage,
                  disablePage
                )}
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column width={1} />
        </Grid.Row>
      );
    });
  };
  renderAllotment = (idRoom, dates, activePage, disablePage) => {
    let pagedData = datasetPagination(dates, 7);
    let showDates = pagedData.length !== 0 ? pagedData[activePage - 1] : [];
    return showDates.map((item, i) => {
      return (
        <Grid.Column key={'date' + i} style={{ padding: '0px 2px' }}>
          <Segment
            style={
              item
                ? item.IsSpecialPeriod
                  ? styles.inputAllotmentSpecial
                  : styles.inputAllotmentDefault
                : styles.disableInputAllotment
            }
          >
            {/* style={
              item ? styles.inputAllotmentSpecial : styles.disableInputAllotment
            } */}
            {/*  */}
            {item && (
              <Label
                title="Special period"
                empty
                circular
                size="mini"
                style={
                  item.IsSpecialPeriod
                    ? { backgroundColor: '#ff9800' }
                    : { backgroundColor: 'transparent' }
                }
              />
            )}
            {item && (
              <InputWithLabel
                fluid
                size="mini"
                type="text"
                name={`${idRoom}_${item.DateTime}_${item.ReleaseDays}_${item.IsSpecialPeriod}`}
                handleBlur={this.handleAllotmentDay}
                value={item.Qty}
                handleChange={this.handleChangeAllotment}
                disabled={disablePage}
                inputStyles={{ margin: '0px 15px' }}
                loading={
                  this.props.loadingGetRoomAllotment ||
                  this.props.loadingPostRoomAllotmentDay
                }
              />
            )}
            {item && (
              <Label
                basic
                style={{
                  fontSize: '12px',
                  border: 'none',
                  width: '100%',
                  padding: '0px',
                  background: 'rgba(56,175,149,0)',
                  textAlign: 'center',
                }}
              >
                {item.IsSpecialPeriod && `${item.ReleaseDays} Days`}
              </Label>
            )}
            {/* {item && (
              <Label
                basic
                style={{
                  fontSize: '12px',
                  border: 'none',
                  width: '100%',
                  padding: '0px',
                  background: 'rgba(56,175,149,0)',
                  textAlign: 'center',
                }}
              >
                {item.Qty !== 0 && `${item.StockLeft} Left`}
              </Label>
            )} */}
            {/* fontSize: '12px',
                  border: 'none',
                  width: '100%',
                  padding: '0px' */}
          </Segment>
        </Grid.Column>
      );
    });
  };
  // ============================== END Room ========================================

  closeModal = () => {
    this.setState({ ...this.state, openModalAllotment: false });
  };
  openModalAllotment = (e, { name }) => {
    this.setState({
      ...this.state,
      openModalAllotment: true,
      actionType: name,
    });
  };

  changePage = (e, { name, value }) => {
    this.setState({ ...this.state, [name]: value }, () => {
      if (name === 'activePageMonth') {
        const selectedIndex = value === 1 ? 0 : 12;
        this.initialDates('selectedMonth', this.state.month[selectedIndex]);
      }
    });
  };
  gotoCheckAvailability = () =>
    this.setState({
      ...this.state,
      linkGoTo: `/Accommodation-Avaibility/${this.props.idProfile}`,
    });
  handleCloseAlert = () => {
    if (this.state.reload) {
      this.getRoomAllotmentByProfileId();
      this.setState({ ...this.state, modalAlertOpen: false, reload: false });
    } else {
      this.setState({ ...this.state, modalAlertOpen: false });
    }
  };

  render() {
    const {
      // loadingGetRoomAllotment,
      // loadingPostRoomAllotmentDay,
      accomodationProfile,
      disablePage,
    } = this.props;
    const {
      openModalAllotment,
      actionType,
      month,
      selectedMonth,
      dates,
      activePage,
      roomAllotments,
      modalAlertOpen,
      modalAlertHeader,
      modalAlertMessage,
      activePageMonth,
      linkGoTo,
    } = this.state;
    if (linkGoTo !== null) {
      return <Redirect to={linkGoTo} />;
    }
    let pagedMonth = datasetPagination(month, 12);
    let showMonth =
      pagedMonth.length !== 0 ? pagedMonth[activePageMonth - 1] : [];
    let pagedData = datasetPagination(dates, 7);
    let showDates = pagedData.length !== 0 ? pagedData[activePage - 1] : [];
    return (
      <Segment style={styles.noBorder}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={4}>
              <Form>
                <InputWithLabel
                  label="Default Release Day"
                  type="number"
                  inline={false}
                  fluid={false}
                  value={accomodationProfile.DefaultReleaseDays}
                  name="DefaultReleaseDays"
                  handleChange={this.props.handleChangeAccommodation}
                  disabled={disablePage}
                  handleBlur={this.props.handleBlurDefaultReleaseDay}
                />
              </Form>
            </Grid.Column>
            <Grid.Column
              style={{ textAlign: 'right', margin: '20px 0px' }}
              width={12}
            >
              <Button onClick={this.gotoCheckAvailability} primary>
                Check Availability Room
              </Button>
              <Button
                onClick={this.openModalAllotment}
                name="SETALLOTMENT"
                primary
                disabled={disablePage}
              >
                Set Room Allotment
              </Button>
              <Button
                onClick={this.openModalAllotment}
                name="SETRELEASEDATE"
                primary
                disabled={disablePage}
              >
                Set Release Day Special Periode
              </Button>
            </Grid.Column>
          </Grid.Row>
          {/* kalender perbulan */}
          <Grid.Row>
            <Grid.Column width={1}>
              <Button
                primary
                icon="angle left"
                disabled={activePageMonth === 1}
                onClick={this.changePage}
                value={activePageMonth - 1}
                name="activePageMonth"
              />
            </Grid.Column>
            <Grid.Column width={14}>
              <Grid>
                <Grid.Row columns={12}>
                  {this.renderBtnGroup(showMonth, selectedMonth)}
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column width={1}>
              <Button
                primary
                icon="angle right"
                disabled={pagedMonth.length === activePageMonth}
                onClick={this.changePage}
                value={activePageMonth + 1}
                name="activePageMonth"
              />
            </Grid.Column>
          </Grid.Row>
          {/* end kalender perbulan */}
          <Grid.Row>
            <Grid.Column width={3} textAlign="right">
              <div style={{ margin: '5px 0px 5px 0px' }}>
                <strong>Day</strong>
              </div>
              <div style={{ margin: '2px 0px 2px 0px' }}>
                <strong>Date</strong>
              </div>
            </Grid.Column>
            <Grid.Column width={1}>
              <Button
                primary
                disabled={activePage === 1}
                onClick={this.changePage}
                value={activePage - 1}
                name="activePage"
                icon="angle left"
                style={{ margin: '8px 0px' }}
              />
            </Grid.Column>
            <Grid.Column width={11}>
              <Grid style={{ width: '690px' }}>
                <Grid.Row columns={7} style={{ textAlign: 'center' }}>
                  {this.renderHeaderDate(showDates)}
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column width={1} style={styles.noPadding}>
              <Button
                primary
                disabled={pagedData.length === activePage}
                onClick={this.changePage}
                value={activePage + 1}
                name="activePage"
                icon="angle right"
                style={{ margin: '8px 0px' }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            {/* , overflow: 'auto', maxHeight: 700 */}
            <Grid
              style={{
                margin: '0px',
                width: '100%',
                overflow: 'auto',
                maxHeight: '250px',
              }}
            >
              {/* <Loader
                active={loadingGetRoomAllotment || loadingPostRoomAllotmentDay}
                inverted={true}
              /> */}
              {this.renderRooms(roomAllotments, activePage, disablePage)}
            </Grid>
          </Grid.Row>
        </Grid>
        <ModalSetRoomAllotment
          openModal={openModalAllotment}
          handleClose={this.closeModal}
          actionType={actionType}
          accomodationProfile={this.props.accomodationProfile.Name}
          accomodationItem={this.state.roomAllotments}
          getRoomAllotmentByProfileId={this.getRoomAllotmentByProfileId}
        />
        <ModalAlert
          openModal={modalAlertOpen}
          handleClose={this.handleCloseAlert}
          header={modalAlertHeader}
          content={modalAlertMessage}
          nameButton="OK"
        />
      </Segment>
    );
  }
}
segmentRoomAllotment.propTypes = {
  accomodationProfile: PropTypes.object,
  idProfile: PropTypes.number,
  getRoomAllotmentByProfileId: PropTypes.func,
  roomAllotments: PropTypes.array,
  postRoomAllotmentDay: PropTypes.func,
  loadingGetRoomAllotment: PropTypes.bool,
  loadingPostRoomAllotmentDay: PropTypes.bool,
  handleChangeAccommodation: PropTypes.func,
  disablePage: PropTypes.bool,
  isNeedUpdateRoomAllotment: PropTypes.bool,
  defaultReleaseDays: PropTypes.number,
  handleBlurDefaultReleaseDay: PropTypes.func,
};
function mapStateToProps(state) {
  return {
    loadingGetRoomAllotment: state.AccomodationStore.loadingGetRoomAllotment,
    roomAllotments: state.AccomodationStore.roomAllotments,
    loadingPostRoomAllotmentDay:
      state.AccomodationStore.loadingPostRoomAllotmentDay,
    isNeedUpdateRoomAllotment:
      state.AccomodationStore.isNeedUpdateRoomAllotment,
  };
}
export default connect(mapStateToProps, {
  getRoomAllotmentByProfileId,
  postRoomAllotmentDay,
})(segmentRoomAllotment);
