import React from 'react';
import { Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import InputAmount from '../../../../../../components/input/inputAmount';
import styles from '../../Styles';
const FormRoomAllocationFixed = props => {
  let {
    roomAllocation,
    prices,
    statusTransaction,
    handleIncrease,
    handleDecrease,
    handleChange,
  } = props;
  let isEditPackage = statusTransaction === 'edit';
  let disabledSharingRoomQty = !isEditPackage
    ? prices.SharingRoomPrice === 0
    : roomAllocation.SharingRoomQty === 0;
  let disabledSingleRoomQty = !isEditPackage
    ? prices.SingleRoomPrice === 0
    : roomAllocation.SingleRoomQty === 0;
  let disabledExtraBedQty = !isEditPackage
    ? prices.AdultExtraBedPrice === 0
    : roomAllocation.ExtraBedQty === 0;
  let disabledChildExtraBedQty = !isEditPackage
    ? prices.ChildExtraBedPrice === 0
    : roomAllocation.ChildExtraBedQty === 0;
  let disabledSharingBedQty = !isEditPackage
    ? prices.SharingBedPrice === 0
    : roomAllocation.SharingBedQty === 0;
  let disabledNoBed = !isEditPackage
    ? prices.NoBedPrice === 0
    : roomAllocation.NoBed === 0;
  return (
    <Form>
      <Form.Group widths="equal">
        <Form.Field style={styles.paddingRight20}>
          <InputAmount
            label="Sharing Room"
            labelStyle={styles.textAlignCenter}
            inputStyle={styles.borderBottom}
            name="SharingRoomQty"
            objectname="roomAllocation"
            value={roomAllocation.SharingRoomQty}
            handleIncrease={handleIncrease}
            handleDecrease={handleDecrease}
            handleOnChange={handleChange}
            disabled={disabledSharingRoomQty}
          />
        </Form.Field>
        <Form.Field style={styles.paddingRight20}>
          <InputAmount
            label="Single Room"
            labelStyle={styles.textAlignCenter}
            inputStyle={styles.borderBottom}
            name="SingleRoomQty"
            objectname="roomAllocation"
            value={roomAllocation.SingleRoomQty}
            handleIncrease={handleIncrease}
            handleDecrease={handleDecrease}
            handleOnChange={handleChange}
            disabled={disabledSingleRoomQty}
          />
        </Form.Field>
        <Form.Field style={styles.paddingRight20}>
          <InputAmount
            label="Extra Bed(Adult)"
            labelStyle={styles.textAlignCenter}
            inputStyle={styles.borderBottom}
            name="ExtraBedQty"
            objectname="roomAllocation"
            value={roomAllocation.ExtraBedQty}
            handleIncrease={handleIncrease}
            handleDecrease={handleDecrease}
            handleOnChange={handleChange}
            disabled={disabledExtraBedQty}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field style={styles.paddingRight20}>
          <InputAmount
            label="Extra Bed(Child)"
            labelStyle={styles.textAlignCenter}
            inputStyle={styles.borderBottom}
            name="ChildExtraBedQty"
            objectname="roomAllocation"
            value={roomAllocation.ChildExtraBedQty}
            handleIncrease={handleIncrease}
            handleDecrease={handleDecrease}
            handleOnChange={handleChange}
            disabled={disabledChildExtraBedQty}
          />
        </Form.Field>
        <Form.Field style={styles.paddingRight20}>
          <InputAmount
            label="Sharing with parents"
            labelStyle={styles.textAlignCenter}
            inputStyle={styles.borderBottom}
            name="SharingBedQty"
            objectname="roomAllocation"
            value={roomAllocation.SharingBedQty}
            handleIncrease={handleIncrease}
            handleDecrease={handleDecrease}
            handleOnChange={handleChange}
            disabled={disabledSharingBedQty}
          />
        </Form.Field>
        <Form.Field style={styles.paddingRight20}>
          <InputAmount
            label="Baby Crib/No Bed"
            labelStyle={styles.textAlignCenter}
            inputStyle={styles.borderBottom}
            name="NoBed"
            objectname="roomAllocation"
            value={roomAllocation.NoBed}
            handleIncrease={handleIncrease}
            handleDecrease={handleDecrease}
            handleOnChange={handleChange}
            disabled={disabledNoBed}
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
};
FormRoomAllocationFixed.propTypes = {
  roomAllocation: PropTypes.object,
  prices: PropTypes.object,
  statusTransaction: PropTypes.string,
  handleIncrease: PropTypes.func,
  handleDecrease: PropTypes.func,
  handleChange: PropTypes.func,
};
export default FormRoomAllocationFixed;
