const menus = [
  { value: 'price', title: 'Price' },
  { value: 'commision', title: 'Commision' },
  { value: 'paymentTerms', title: 'Payment Terms' },
  { value: 'additionalItem', title: 'Additional Item' },
  { value: 'accommodation', title: 'Accommodation' },
  { value: 'dialyProgram', title: 'Daily Program' },
  { value: 'transactionHistory', title: 'Transaction History' },
];

const menuVariableDate = [
  { value: 'price', title: 'Price' },
  { value: 'accommodation', title: 'Accommodation' },
  { value: 'dialyProgram', title: 'Daily Program' },
  { value: 'transactionHistory', title: 'Transaction History' },
];

// export default menus;
export { menuVariableDate, menus };
