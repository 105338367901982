import apiClient from './apiClient';
import { GET_RESORCE_BOOKING_BY_TRANSACTION_NUMBER_URL } from './apiUrl';

const getResourceBookingByTransactionNumberApi = (
  start,
  end,
  createStart,
  createEnd,
  status
) => {
  return apiClient.get(
    GET_RESORCE_BOOKING_BY_TRANSACTION_NUMBER_URL(
      start,
      end,
      createStart,
      createEnd,
      status
    )
  );
};

export { getResourceBookingByTransactionNumberApi };
