import React, { Component } from 'react';
import { Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { HeaderTabelAccomodationItemList } from '../../../../../components/table/headerTabel';
// import ButtonActionDetailList from '../../../../../components/common/buttonEditAndDelete';
// import { Link } from 'react-router-dom';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../../../scripts/pagination';
import FilterData from '../../../../../scripts/filterDataTable';
import ButtonGroupSupplier from '../../../../../components/common/ButtonGroupSupplier';
import Pagination from '../../../../../components/common/pagination';
import TableItem from './TableItem';
class tableAccomodationItemList extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
    };
  }
  static propTypes = {
    data: PropTypes.array,
  };
  handleChange = (e, { name, value }) => this.setState({ [name]: value });
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  render() {
    let filterData = FilterData(
      this.props.listData.sort(
        (a, b) =>
          Number(a.AccommodationType.Capacity) -
          Number(b.AccommodationType.Capacity)
      ),
      'Name',
      this.state.searchInput
    );
    let pagedData = datasetPagination(filterData, 3);
    let listData = pagedData[this.state.activePage - 1];
    const props = this.props;

    let { numbersFrom, numbersTo } = getNumberFromTo(
      listData,
      this.state.activePage,
      3
    );
    return (
      <div>
        <Input
          icon="search"
          placeholder="search"
          name="searchInput"
          value={this.state.searchInput}
          onChange={this.handleChange}
        />
        <ButtonGroupSupplier
          buttonNew={props.handleNewAccomodation}
          butttonExport={props.butttonExport}
          buttonUploadData={props.buttonUploadData}
          buttonDownloadTemplate={props.buttonDownloadTemplate}
          disablePage={this.props.disablePage}
        />
        <TableItem
          header={HeaderTabelAccomodationItemList}
          listData={this.props.listData}
          handleClickEdit={this.props.handleClickEdit}
          handleClickDelete={this.props.handleClickDelete}
          disablePage={this.props.disablePage}
        />
        <Pagination
          pagedData={pagedData}
          numbersTo={numbersTo}
          numbersFrom={numbersFrom}
          data={props.listData}
          pageChange={this.pageChange}
          activePage={this.state.activePage}
        />
      </div>
    );
  }
}

tableAccomodationItemList.propTypes = {
  listData: PropTypes.array,
  handleClickEdit: PropTypes.func,
  handleClickDelete: PropTypes.func,
  disablePage: PropTypes.bool,
  handleNewAccomodation: PropTypes.func,
  butttonExport: PropTypes.func,
  buttonUploadData: PropTypes.func,
  buttonDownloadTemplate: PropTypes.func,
};
export default tableAccomodationItemList;
