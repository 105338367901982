import React from 'react';
import { Grid, Segment, Divider } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import GeneralInformation from './GeneralInfoAccommodationProfile';
import TabAccommodationItem from '../../../shared/components/tab/TabItem';
import ButtonBackAndSave from '../../../../components/common/buttonBackAndSave';
import AddingDataWithTable from '../../../../components/globalListVew/addingDataComponent';
import {
  headerTableFacilitiesAccomodationProfile,
  headerTableImageAccomodationProfile,
  headerTableLocationAccomodationProfile,
  headerTableContractingFileAccomodationProfile,
} from '../constant/tableHeader';
import RatingAndReservationLimit from './LocationAndReservation';
import ContactInformation from '../../../shared/components/contactInfo/ContactInfo';
import ImageListComponent from '../../../../components/image/image';
import ContractingFile from './AccommodationContractingFile';
import HeaderTitle from '../../../../components/common/headerTitle';
const accomodationList = props => (
  <div>
    <Segment width={15} style={{ marginTop: '30px' }}>
      <Grid columns={2} padded="vertically">
        <Grid.Column>
          {/* Kiri */}
          <HeaderTitle title={props.title} />
          <GeneralInformation
            handleChange={props.handleChange}
            accomodationProfileData={props.accomodationProfileData}
            listType={props.listType}
            listTourType={props.listTourType}
            listTourPaxType={props.listTourPaxType}
            errors={props.errors}
            disablePage={props.disablePage}
            onChangeCheckboxPriority={props.onChangeCheckboxPriority}
          />
          <ContactInformation
            handleChange={props.handleChangeDropdown}
            onChangeDropdown={props.handleChangeDropdown}
            contactObject={
              props.accomodationProfileData.accomodationProfileId
                .AddressObjectRequest
            }
            errors={props.errors}
            listCountry={props.listCountry}
            listRegion={props.listRegion}
            listCity={props.listCity}
            listArea={props.listArea}
            disablePage={props.disablePage}
          />
        </Grid.Column>
        <Grid.Column>
          <ButtonBackAndSave
            save={props.saveAccomodation}
            backPage={props.backPage}
            nameBack="Back"
            nameSave={props.contentButton}
          />
          <ImageListComponent
            addData={props.modalItem}
            buttonContent="Add Image"
            headerContent="Image"
            deleteData={props.deleteImage}
            headerTable={headerTableImageAccomodationProfile}
            listData={props.listImage}
            profileImageList={props.ProfileImages}
            //openDetail={}
            idButton="Image"
            openDetail={props.openDetail}
            handleChangePrimaryImage={props.handleChangePrimaryImage}
            disablePage={props.disablePage}
            pattern="Accommodations/"
            activePage={props.activePageImage}
            pageChange={props.pageChangeImage}
            pagedData={props.pageImage}
          />
          <AddingDataWithTable
            addData={props.modalItem}
            buttonContent="Add Features"
            headerContent="Features"
            deleteData={props.deleteData}
            headerTable={headerTableFacilitiesAccomodationProfile}
            listData={
              props.accomodationProfileData.accomodationProfileId.Facilities
                ? props.accomodationProfileData.accomodationProfileId.Facilities
                : []
            }
            idButton="Features"
            disablePage={props.disablePage}
            listAddingData={props.listDataFacilities}
            pageAddingData={props.pageFacilities}
            activePage={props.activePageFeatures}
            pageChange={props.pageChangeFeatures}
            addingDataList={props.Facilities}
          />
          <AddingDataWithTable
            addData={props.modalItem}
            buttonContent="Add Location"
            headerContent="Location"
            deleteData={props.deleteData}
            headerTable={headerTableLocationAccomodationProfile}
            listData={
              props.accomodationProfileData.accomodationProfileId.LocationId
                ? props.accomodationProfileData.accomodationProfileId.LocationId
                : []
            }
            idButton="Location"
            disablePage={props.disablePage}
            listAddingData={props.listDataLocation}
            pageAddingData={props.pageLocation}
            activePage={props.activePageLocation}
            pageChange={props.pageChangeLocation}
            addingDataList={props.LocationId}
          />
          <RatingAndReservationLimit
            handleChange={props.handleChange}
            accomodationProfileData={
              props.accomodationProfileData.accomodationProfileId
            }
            rating={props.rating}
            errors={props.errors}
            disablePage={props.disablePage}
          />
          <ContractingFile
            contractingFileList={props.ProfileContracts}
            openModalContractingFile={props.openModalContractingFile}
            buttonContent="Add File"
            headerContent="Contracting File"
            deleteContracting={props.deleteContracting}
            viewContracting={props.viewContracting}
            // downloadContracting={props.downloadContracting}
            headerTable={headerTableContractingFileAccomodationProfile}
            idButton="ContractingFile"
            disablePage={props.disablePage}
            listAddingData={props.listDataContracting}
            pageAddingData={props.pageContracting}
            activePage={props.activePageContract}
            pageChange={props.pageChangeContract}
          />
        </Grid.Column>
      </Grid>
      <Divider />
      <TabAccommodationItem
        handleNewAccomodation={props.handleNewAccomodation}
        listAccomodationItemByProfilId={props.listAccomodationItemByProfilId}
        butttonExport={props.butttonExport}
        buttonUploadData={props.buttonUploadData}
        buttonDownloadTemplate={props.buttonDownloadTemplate}
        handleClickDelete={props.handleClickDelete}
        handleClickEdit={props.handleClickEdit}
        tabMenu={props.tabMenu}
      />
    </Segment>
  </div>
);

accomodationList.propTypes = {
  Facilities: PropTypes.array,
  LocationId: PropTypes.array,
  ProfileContracts: PropTypes.array,
  ProfileImages: PropTypes.array,
  handleChange: PropTypes.func,
  accomodationProfileData: PropTypes.object,
  listType: PropTypes.array,
  listTourType: PropTypes.array,
  listTourPaxType: PropTypes.array,
  handleNewAccomodation: PropTypes.func,
  listAccomodationItemByProfilId: PropTypes.array,
  modalItem: PropTypes.func,
  listCountry: PropTypes.array,
  handleChangeDropdown: PropTypes.func,
  saveAccomodation: PropTypes.func,
  backPage: PropTypes.func,
  listRegion: PropTypes.array,
  listCity: PropTypes.array,
  listArea: PropTypes.array,
  addLocation: PropTypes.func,
  rating: PropTypes.array,
  deleteData: PropTypes.func,
  butttonExport: PropTypes.func,
  buttonUploadData: PropTypes.func,
  buttonDownloadTemplate: PropTypes.func,
  title: PropTypes.string,
  openDetail: PropTypes.func,
  handleChangePrimaryImage: PropTypes.func,
  errors: PropTypes.object,
  contentButton: PropTypes.string,
  disablePage: PropTypes.bool,
  handleClickDelete: PropTypes.func,
  handleClickEdit: PropTypes.func,
  deleteImage: PropTypes.func,
  activePage: PropTypes.number,
  pageChange: PropTypes.func,
  listDataLocation: PropTypes.array,
  pageLocation: PropTypes.array,
  listDataFacilities: PropTypes.array,
  pageFacilities: PropTypes.array,
  listImage: PropTypes.array,
  pageImage: PropTypes.array,
  openModalContractingFile: PropTypes.func,
  deleteContracting: PropTypes.func,
  viewContracting: PropTypes.func,
  downloadContracting: PropTypes.func,
  listDataContracting: PropTypes.array,
  pageContracting: PropTypes.func,
  activePageImage: PropTypes.number,
  pageChangeImage: PropTypes.func,
  activePageFeatures: PropTypes.number,
  pageChangeFeatures: PropTypes.func,
  activePageLocation: PropTypes.number,
  pageChangeLocation: PropTypes.func,
  activePageContract: PropTypes.number,
  pageChangeContract: PropTypes.func,
  tabMenu: PropTypes.array,
  onChangeCheckboxPriority: PropTypes.func,
};
export default accomodationList;
