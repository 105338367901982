const styles = {
  marginTopUploadImage: {
    marginTop: '1em',
  },
  segmentCompanyDetail: {
    position: 'absolute',
    top: '-25px',
    background: '#38af95',
    color: 'white',
  },
};

export default styles;
