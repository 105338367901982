import apiClient from './apiClient';
import {
  GET_IDENTITY_TYPE_URL,
  GET_GUEST_TITLE_TYPE_URL,
  POST_REMOVE_GUEST_LIST,
} from './apiUrl';

const getIdentityTypeApi = () => {
  return apiClient.get(GET_IDENTITY_TYPE_URL);
};
const getGuestTitleTypeApi = () => {
  return apiClient.get(GET_GUEST_TITLE_TYPE_URL);
};

const postRemoveGuestListApi = (id, data) => {
  return apiClient.post(POST_REMOVE_GUEST_LIST(id), data);
};

export { getIdentityTypeApi, getGuestTitleTypeApi, postRemoveGuestListApi };
