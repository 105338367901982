import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Header,
  Form,
  Segment,
  Icon,
  Button,
  Container,
  Checkbox,
  Message,
} from 'semantic-ui-react';
// import Input from './../input/inputWithLabel';
import PropTypes from 'prop-types';
import styles from '../../../../../assets/styles/styleCreateTourTransaction';
// import ModalPackageList from '../modal/modalPackageList';
import InputWithLabel from '../../../../../components/input/inputWithLabel';
// import InputAmount from '../input/inputAmount';
import CollapseButton from '../../../../../components/button/collapseButton';
import { Collapse } from 'react-collapse';
import SegmentCustomerOnBehalf from './segment/SegmentCustomerOnBehalf';
import SegmentCommision from './segment/SegmentCommision';
// import SegmentAdditionalItem from './segment/SegmentAdditionalItem';
import SegmentTourNote from './segment/SegmentTourNote';
import SegmentCancellationReason from './segment/SegmentCancellationReason';
import TablePaxArrangement from './table/TablePaxArrangement';
import {
  getTourTypeAction,
  getTourPaxTypeAction,
  getDownloadScheduleByTransactionIdAction,
  getDownloadVoucherConfirmationByTransactionIdAction,
} from '../../../../../actions/tourTransactionAction';
import options from '../../../../../components/genericFunction/selectOptionConverter';
import ModalPaxArrangement from './modal/ModalPaxArrangement';
import {
  changeDestinationSummaryProgram,
  setPlaceInArrivalDepartureByHeadLine,
} from '../../../../../scripts/itineraryBuilder/dailyProgram';
import { getAirportAction } from '../../../../../actions/airportAction';
import {
  validationGeneralInform,
  isGroupCapacityValid,
  // isValidGeneralInformation,
} from '../../../../../scripts/validation';
import AdditionalInfo from './segment/AdditionalInformation';
import Image from '../../../../../components/image/image';
import ModalImage from '../../../../../components/modal/modalImage';
import { convertDateFormat, addDate } from '../../../../../scripts/moment';
import { datasetPagination } from '../../../../../scripts/pagination';
import InputDate from '../../../../../components/input/inputDate';
import {
  getInvoiceAgentApi,
  getInvoiceCustomerApi,
} from '../../../../../api/tourTransaction/getInvoiceCustomerAndAgentApi';
// import moment from 'moment';
import AdditionalServices from '../../../../readyPackage/readyPackageDetail/components/additionalService/SegmentAdditionalServices';
import TableRoomAllocationPrice from './table/TableRoomAllocationPrice';
// import ButtonIcon from './../button/buttonWithIcon';
// import ModalDetailFixedPackage from './../modal/modalDetailFixedPackage';
// import SegmentSpecialAdjustment from './../segment/segmentSpecialAdjustment';
// const availableDay = [
//   { key: 0, value: 0, text: 'Monday' },
//   { key: 1, value: 1, text: 'Tuesday' },
//   { key: 2, value: 2, text: 'Wednesday' },
//   { key: 3, value: 3, text: 'Thursday' },
//   { key: 4, value: 4, text: 'Friday' },
//   { key: 5, value: 5, text: 'Saturday' },
//   { key: 6, value: 6, text: 'Sunday' },
// ];

const groupCapacity = [
  { key: 'SMALL', value: 'Small', text: 'Small Group' },
  { key: 'LARGE', value: 'Large', text: 'Large Group' },
];
let textStatus = [
  { key: 'Booking_created', value: 'Created' },
  {
    key: 'Booking_hold',
    value: 'Created (On Hold)',
  },
  {
    key: 'Booking_DP_Confirmed',
    value: 'DP confirm',
  },
  {
    key: 'Booking_SP_Confirmed',
    value: 'SP confirm',
  },
  {
    key: 'Booking_In_Payment',
    value: 'In Payment',
  },
  { key: 'Booking_Confirmed', value: 'Confirmed' },
  {
    key: 'Booking_Completed',
    value: 'Completed',
  },
  {
    key: 'Booking_Cancelled',
    value: 'Cancelled',
  },
  {
    key: 'Booking_draft',
    value: 'Quotation',
  },
];
// const FILL = 'Please fill ';
// const CHOOSE = 'Please choose ';
class GeneralInformation extends Component {
  state = {
    openCollapse: true,
    openModalPaxArrangement: false,
    openModalDetail: false,
    dataForImage: {
      contentModal: '',
      openModalImage: false,
      indexActive: 0,
      listStepImage: [
        {
          Tittle: 'Choose Image',
          Active: true,
        },
        {
          Tittle: 'Select Thumbnail Image',
          Active: false,
        },
        {
          Tittle: 'Edit Thumbnail Image',
          Active: false,
        },
      ],
      imagesModal: [],
      image: {},
      activePageImage: 1,
    },
    // additionalServices: [],
  };
  componentDidMount = () => {
    this.props.getTourTypeAction();
    this.props.getTourPaxTypeAction();
  };

  handleCollapse = (e, { name, value }) =>
    this.setState({ ...this.state, [name]: !value });

  validationTourInformation = async () => {
    // ----------------- validation general inforation ------------------------
    let { tourInformation, currentPackage, guests, errors } = this.props;
    let newErrors = { isError: false, data: errors };
    if (currentPackage.type !== 'Fixed') {
      newErrors = await validationGeneralInform(
        tourInformation,
        tourInformation.firstDestination,
        errors
      );
      newErrors = {
        ...newErrors,
        data: await isGroupCapacityValid(
          newErrors.data,
          guests,
          tourInformation.groupCapacity
        ),
      };
    }
    await this.props.setErrorsGeneralInform(newErrors.data);
    // await this.setState({ ...this.state, errors: newErrors.data });
    return newErrors.isError;
    // ----------------- end validation general inforation ------------------------
  };

  handleModalPaxArrangement = async () => {
    let isError = await this.validationTourInformation();
    this.setState({
      ...this.state,
      // errors: newErrors.data,
      openModalPaxArrangement: isError
        ? false
        : !this.state.openModalPaxArrangement,
    });
  };

  handleOnchange = async (e, { name, value, objectname }) => {
    // add validation
    await this.props.handleGeneralInfo(name, value, objectname);
    name === 'firstDestination' &&
      (await this.setFirstDestination(name, value, objectname));
    let errors = { ...this.props.errors };
    const { tourInformation, guests } = this.props;
    let isError = value ? false : true;
    if (['splitCommision', 'printCommision', 'tourNote'].indexOf(name) === -1) {
      errors[name].error = isError;
    }
    if (name === 'groupCapacity') {
      errors = await isGroupCapacityValid(
        errors,
        guests,
        tourInformation.groupCapacity
      );
    }
    this.props.setErrorsGeneralInform(errors);
  };
  setFirstDestination = async (name, value, objectname) => {
    let city = this.props.cityInCountry.find(item => item.Id === value);
    let headLineProg = { ...this.props.headlineProgram };
    //eslint-disable-next-line
    headLineProg.MainPrograms = await changeDestinationSummaryProgram(
      headLineProg.MainPrograms,
      0,
      city
    );
    await this.props.handleGeneralInfo(name, value, objectname);
    let context = this;
    await this.props.getAirportAction(city.Region.Id).then(res => {
      if (res.value.data.length > 0) {
        context.setPlace(headLineProg);
      } else {
        context.props.setheadlineProgram(headLineProg);
      }
    });
    await this.props.setAccommodationOnChangeIndex(0);
  };
  setPlace = async headline => {
    const { placeByRegion, cityInCountry } = this.props;
    let newHeadline = await setPlaceInArrivalDepartureByHeadLine(
      headline,
      placeByRegion,
      cityInCountry
    );
    this.props.setheadlineProgram(newHeadline);
  };
  handleModalDetail = () =>
    this.setState({
      ...this.state,
      openModalDetail: !this.state.openModalDetail,
    });
  convertStatus = (status, isAcceptedQuotation) => {
    let newStatus = textStatus.filter(e => e.key === status)[0];
    newStatus =
      isAcceptedQuotation === true
        ? { value: 'Quotation Accepted' }
        : isAcceptedQuotation === false
        ? { value: 'Quotation Draft' }
        : newStatus;
    return newStatus ? newStatus.value : '-';
  };
  // ========================= HANDLE UPLOAD IMAGE ===================================
  deleteImage = async id => {
    let images = [...this.props.images];
    images.splice(id, 1);
    let dataForImage = {
      ...this.state.dataForImage,
      activePageImage: 1,
    };
    await this.setState({ ...this.state, dataForImage });
    this.props.handleImages('images', images);
  };

  openDetail = () => {
    let { listStepImage, contentModal } = { ...this.state.dataForImage };
    let { images } = this.props;
    listStepImage.map((step, i) => {
      step['Active'] = i === 0 ? true : false;
      return listStepImage;
    });
    let imagesModal = [...images];
    contentModal = 'Image';
    let dataForImage = {
      contentModal: contentModal,
      openModalImage: true,
      indexActive: 0,
      listStepImage: listStepImage,
      imagesModal: imagesModal,
    };
    this.setState({
      ...this.state,
      dataForImage,
    });
  };
  changeImage = e => {
    let imageObject = [...this.state.dataForImage.imagesModal];
    e.map(base => {
      let image = {
        Id: 0,
        ImageContent: base.base64.split(',')[1],
        ImagePreview: base.base64,
        ImageName: base.file.name.substring(0, 50),
        IsPrimaryImage: false,
      };
      return imageObject.push(image);
    });
    let dataForImage = { ...this.state.dataForImage, imagesModal: imageObject };
    this.setState({
      ...this.state,
      dataForImage,
    });
  };
  addImage = async () => {
    let images = [...this.props.images];
    let listStepImage = [...this.state.listStepImage];
    listStepImage.map((step, i) => {
      step['Active'] = i === 0 ? true : false;
      return listStepImage;
    });
    let dataForImage = {
      ...this.state.dataForImage,
      openModalImage: false,
      listStepImage: listStepImage,
      indexActive: 0,
    };
    await this.setState({
      ...this.state,
      dataForImage,
    });
    this.props.handleImages('images', images);
  };
  handleClose = () => {
    let dataForImage = { ...this.state.dataForImage, openModalImage: false };
    this.setState({
      ...this.state,
      dataForImage,
    });
  };
  handleNext = async () => {
    let { indexActive, images, imagesModal, listStepImage } = {
      ...this.state.dataForImage,
    };
    indexActive = indexActive + 1;
    listStepImage.map((step, i) => {
      step['Active'] = i === indexActive ? true : false;
      return step;
    });
    if (indexActive === 2) {
      let listImage = await imagesModal.filter(img => img.IsPrimaryImage);
      let image = listImage ? (listImage.length > 0 ? listImage[0] : {}) : {};
      let dataForImage = await {
        ...this.state.dataForImage,
        image,
      };
      this.setState({ ...this.state, dataForImage });
    }
    if (indexActive === 3) {
      images = [...imagesModal];
      let dataForImage = {
        ...this.state.dataForImage,
        indexActive: 0,
        openModalImage: false,
      };
      await this.setState({ ...this.state, dataForImage });
      await this.props.handleImages('images', images);
    }
    let dataForImage = {
      ...this.state.dataForImage,
      listStepImage: listStepImage,
      indexActive: indexActive,
    };
    this.setState({ ...this.state, dataForImage });
  };
  handleBackStep = () => {
    let indexActive = this.state.dataForImage.indexActive;
    indexActive = indexActive - 1;
    let listStepImage = [...this.state.dataForImage.listStepImage];
    listStepImage.map((step, i) => {
      step['Active'] = i === indexActive ? true : false;
      return listStepImage;
    });
    let dataForImage = {
      ...this.state.dataForImage,
      listStepImage: listStepImage,
      indexActive: indexActive,
    };
    this.setState({ ...this.state, dataForImage });
  };
  handleChangePrimaryImageModal = (e, { name }) => {
    let listImage = [...this.state.dataForImage.imagesModal];
    let newList = [];
    listImage.map((img, i) => {
      img['IsPrimaryImage'] = i === name ? true : false;
      return newList.push(img);
    });
    let dataForImage = {
      ...this.state.dataForImage,
      imagesModal: newList,
    };
    this.setState({ ...this.state, dataForImage });
  };
  handleChangePrimaryImage = (e, { name }) => {
    let listImage = [...this.props.images];
    let newList = [];
    listImage.map((img, i) => {
      img['IsPrimaryImage'] = i === name ? true : false;
      return newList.push(img);
    });
    this.props.handleImages('images', newList);
  };
  pageChangeImage = (e, { activePage }) => {
    let dataForImage = {
      ...this.state.dataForImage,
      activePageImage: activePage,
    };
    this.setState({ ...this.state, dataForImage });
  };
  // ========================= END HANDLE UPLOAD IMAGE ================================
  // addFoc = async val => {
  //   await this.setState({ ...this.state, foc: val });
  //   this.props.addFocAmount(val);
  //   // console.log(this.state.foc);
  // };

  handleClickDownloadSchedule = () => {
    const {
      currentPackage,
      getDownloadScheduleByTransactionIdAction,
    } = this.props;
    getDownloadScheduleByTransactionIdAction(currentPackage.id).then(res =>
      window.open(res.value.data)
    );
  };

  handleDownloadFile = (dataUrl, filename) => {
    let link = document.createElement('a');
    link.download = filename;
    link.href = dataUrl;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  handleClickDownloadInvoice = (e, { name }) => {
    const { id, tourInformation } = this.props;
    if (name === 'agent') {
      getInvoiceAgentApi(id).then(result => {
        this.handleDownloadFile(result.data, tourInformation.tourName);
      });
    } else {
      getInvoiceCustomerApi(id).then(result => {
        this.handleDownloadFile(result.data, tourInformation.tourName);
      });
    }
  };

  handleClickDownloadVoucherInformation = () => {
    const {
      currentPackage,
      getDownloadVoucherConfirmationByTransactionIdAction,
    } = this.props;
    getDownloadVoucherConfirmationByTransactionIdAction(
      currentPackage.id
    ).then(res => window.open(res.value.data));
  };

  // ========================= additional services ================================
  // setAdditionalServicesList = data => {
  //   this.setState({ ...this.state, additionalServices: data });
  // };

  render() {
    let { openCollapse, openModalPaxArrangement, dataForImage } = this.state;
    let {
      currentPackage,
      supplements,
      tourInformation,
      listTourType,
      listTourPaxType,
      loadingTourTransaction,
      // loadingCityGroup,
      guests,
      guestList,
      roomAllocation,
      priceRoom,
      headlineProgram,
      handleGuestAndRoom,
      commission,
      commissionsPackage,
      // handleSupplements,
      paymentTerms,
      nowPayment,
      packageDetail,
      errors,
      isPackageMangement,
      additionalInfo,
      images,
      foc,
      isPublished,
      // ready fix price
      handleChangeIsFixPrice,
      handleSetDate,
      availableDate,
      isTourTransactionReadyFixedPrice,
      //end
      // tourNote,
      cancellationReason,
      cancellationReasonNote,
      isOperation,
    } = this.props;
    let isFixedPackage = currentPackage.type === 'Fixed';
    // let isReadyFixPrice = currentPackage.type === 'FixedDateVariable';
    let isQuotation = currentPackage.type === 'Quotation';
    let isCreatePackage = currentPackage.status === 'create';
    let totalAllocation = guests.adults + guests.childs + guests.infants;
    let isAcceptedQuotation = packageDetail
      ? packageDetail.BookingDetailSum.Quotation
        ? packageDetail.BookingDetailSum.Quotation.IsAccepted
          ? true
          : false
        : undefined
      : undefined;
    let statusPackage = packageDetail
      ? packageDetail.BookingDetailSum
        ? packageDetail.BookingDetailSum.Status
        : ''
      : '';
    let status = isCreatePackage
      ? '-'
      : this.convertStatus(statusPackage, isAcceptedQuotation);
    let createdDate = packageDetail
      ? packageDetail.BookingDetailSum
        ? packageDetail.BookingDetailSum.CreatedDate
        : '-'
      : '-';
    let isShowDownloadInformation = status === '-' || isQuotation;
    // ======================= images =================================
    let pagedDataImages = images ? datasetPagination(images, 3) : [];
    // ===================== end images ===============================
    // pengaturan autorisasi
    let isShowAgentInvoice = !isOperation;
    let isShowCustomerInvoice = !isOperation;
    return (
      <Segment raised fluid style={styles.styleCard}>
        <div style={styles.marginContentInsideCard}>
          <Grid verticalAlign="middle">
            <Grid.Row style={styles.noPaddingBottom}>
              <Grid.Column width={14}>
                <Header as="h3" style={styles.marginHeader}>
                  <Icon name="clipboard list" style={{ color: '#38af95' }} />
                  General Information
                </Header>
              </Grid.Column>
              <Grid.Column width={2} textAlign="left">
                <CollapseButton
                  isCollapse={openCollapse}
                  handleCollapse={this.handleCollapse}
                  name="openCollapse"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Collapse isOpened={openCollapse} style={{ margin: '0px' }}>
            <Grid columns={2}>
              {/* <Grid.Row style={{ paddingBottom: '0px', width: '100%' }}> */}
              <Grid.Column>
                {!isPackageMangement && (
                  <Grid style={{ width: '100%' }}>
                    <Grid.Column width={6}>Tour type</Grid.Column>
                    <Grid.Column width={10}>
                      :{' '}
                      {currentPackage.type === 'Quotation'
                        ? 'Custom'
                        : currentPackage.type === 'FixedDateVariable'
                        ? 'Ready Fixed Price'
                        : currentPackage.type === 'Fixed'
                        ? 'Series'
                        : currentPackage.type}{' '}
                      Package
                    </Grid.Column>
                    <Grid.Column width={6} style={styles.noPaddingTop}>
                      {isAcceptedQuotation ? 'Quotation No' : 'Booking No'}
                    </Grid.Column>
                    <Grid.Column width={10} style={styles.noPaddingTop}>
                      :{' '}
                      {currentPackage.status === 'create'
                        ? '-'
                        : currentPackage.id}
                    </Grid.Column>
                    {isAcceptedQuotation && (
                      <>
                        <Grid.Column width={6} style={styles.noPaddingTop}>
                          Booking No
                        </Grid.Column>
                        <Grid.Column width={10} style={styles.noPaddingTop}>
                          :{' '}
                          {currentPackage.status === 'create'
                            ? '-'
                            : packageDetail &&
                              packageDetail.BookingDetailSum.Quotation &&
                              packageDetail.BookingDetailSum.Quotation
                                .TourTransactionId}
                        </Grid.Column>
                      </>
                    )}
                    {isFixedPackage && (
                      <Grid.Column width={6} style={styles.noPaddingTop}>
                        Tour Package Name
                      </Grid.Column>
                    )}
                    {isFixedPackage && (
                      <Grid.Column width={10} style={styles.noPaddingTop}>
                        :{' '}
                        {packageDetail
                          ? packageDetail.BookingDetailSum.Title
                          : '-'}
                        {/* {isFixedPackage && (
                        <ButtonIcon
                          iconName="search"
                          size="small"
                          style={{
                            color: '#2962ff',
                            padding: '0px',
                            margin: '0px',
                            background: 'white',
                          }}
                          handleOnClick={this.handleModalDetail}
                        />
                      )} */}
                      </Grid.Column>
                    )}
                    <Grid.Column width={6} style={styles.noPaddingTop}>
                      Status
                    </Grid.Column>
                    <Grid.Column width={10} style={styles.noPaddingTop}>
                      : {status}
                    </Grid.Column>
                    <Grid.Column width={6} style={styles.noPaddingTop}>
                      {status !== '-' && 'Creation Date'}
                    </Grid.Column>
                    <Grid.Column width={10} style={styles.noPaddingTop}>
                      {status !== '-' &&
                        `: ${convertDateFormat(createdDate, 'DD MMM YYYY')}`}
                    </Grid.Column>
                    {/* {status !== '-' && (
                      <Grid>
                        <Grid.Column width={6} style={styles.noPaddingTop}>
                          Creation Date
                        </Grid.Column>
                        <Grid.Column width={10} style={styles.noPaddingTop}>
                          : {convertDateFormat(createdDate, 'DD/MM/YYYY')}
                        </Grid.Column>
                      </Grid>
                    )} */}
                  </Grid>
                )}

                {/* <p style={{ margin: '10px 0px 0px 0px' }}>
                  Tour type: {currentPackage.type} Package
                </p>
                <p style={styles.noMargin}>
                  Booking No:{' '}
                  {currentPackage.status === 'create' ? '-' : currentPackage.id}
                </p> */}

                {/* <Grid style={{ width: '100%' }}>
                  <Grid.Column width={6}>Voucher confirmation</Grid.Column>
                  <Grid.Column width={10}>
                    <Button circular icon="download" primary />
                  </Grid.Column>
                  <Grid.Column width={6}>Schedule</Grid.Column>
                  <Grid.Column width={10}>
                    <Button circular icon="download" primary />
                  </Grid.Column>
                </Grid> */}
                <Header as="h4">Tour Information</Header>
                {currentPackage.type === 'FixedDateVariable' && (
                  <React.Fragment>
                    {tourInformation.minPaxReducer ? (
                      <Message color="yellow">
                        <Icon name="warning circle" color="yellow" /> Minimum
                        pax for this package:
                        <strong> {tourInformation.minPax} pax</strong>
                      </Message>
                    ) : null}
                  </React.Fragment>
                )}
                <Form>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <InputWithLabel
                        inline={false}
                        label="Tour Name"
                        type="text"
                        fluid={true}
                        required
                        disabled={
                          isFixedPackage ||
                          currentPackage.disabledEdit ||
                          isPublished ||
                          isOperation
                        }
                        name="tourName"
                        value={tourInformation.tourName}
                        placeholder="Tour Name"
                        handleChange={this.handleOnchange}
                        objectname="tourInformation"
                        validate={errors}
                        loading={loadingTourTransaction}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <InputWithLabel
                        inline={false}
                        label="First Destination"
                        type="select"
                        fluid={true}
                        required
                        disabled={
                          isFixedPackage ||
                          currentPackage.disabledEdit ||
                          isPublished ||
                          isOperation
                        }
                        name="firstDestination"
                        value={tourInformation.firstDestination}
                        options={this.props.optionCityInCountry}
                        placeholder="Select First Destination"
                        // loading={loadingCityGroup}
                        handleChange={this.handleOnchange}
                        objectname="tourInformation"
                        validate={errors}
                      />
                    </Form.Field>
                    {isTourTransactionReadyFixedPrice && (
                      <Form.Field width={11}>
                        <InputDate
                          label="Start tour date"
                          placeholder="Start tour date"
                          value={tourInformation.startTour}
                          setDate={handleSetDate}
                          labelStyle={{
                            display: 'block',
                            fontWeight: 'bold',
                            marginBottom: '4px',
                          }}
                          name="startTour"
                          disabled={
                            isFixedPackage ||
                            currentPackage.disabledEdit ||
                            isPublished ||
                            isOperation
                          }
                          includeDates={availableDate}
                        />
                      </Form.Field>
                    )}
                  </Form.Group>
                  {isPackageMangement && (
                    <Form.Group widths="equal">
                      <Form.Field>
                        <InputWithLabel
                          inline={false}
                          required
                          label="Description"
                          type="textArea"
                          fluid={true}
                          disabled={
                            isFixedPackage ||
                            currentPackage.disabledEdit ||
                            isOperation
                          }
                          name="description"
                          value={tourInformation.description}
                          placeholder="Description Package"
                          handleChange={this.handleOnchange}
                          objectname="tourInformation"
                          validate={errors}
                        />
                      </Form.Field>
                    </Form.Group>
                  )}
                  {isPackageMangement && (
                    <Form>
                      <Form.Group>
                        <Form.Field>
                          <Checkbox
                            label="Fixed price package"
                            name="isReadyFixPrice"
                            objectName="currentPackage"
                            // value={true}
                            checked={currentPackage.isReadyFixPrice === true}
                            onChange={handleChangeIsFixPrice}
                            disabled={
                              currentPackage.status !== 'create' || isOperation
                            }
                          />
                        </Form.Field>
                      </Form.Group>

                      {currentPackage.isReadyFixPrice && (
                        <Form.Field>
                          <Form.Checkbox
                            label={{ children: 'Allotment Needed?' }}
                            checked={tourInformation.isRoomAllotmetNeeded}
                            onChange={handleChangeIsFixPrice}
                            // objectname="tourInformation"
                            name="isRoomAllotmetNeeded"
                            value={!tourInformation.isRoomAllotmetNeeded}
                            disabled={isOperation}
                          />
                          <InputWithLabel
                            label="Minimum Pax"
                            type="number"
                            required
                            multiple
                            name="minPax"
                            value={tourInformation.minPax}
                            placeholder="Minimum Pax"
                            // disabled={
                            //   isFixedPackage ||
                            //   currentPackage.disabledEdit ||
                            //   isPublished
                            // }
                            handleChange={this.handleOnchange}
                            objectname="tourInformation"
                            validate={errors}
                          />
                        </Form.Field>
                      )}
                    </Form>
                  )}
                  {isPackageMangement && (
                    <Form.Group widths="equal">
                      <Form.Field>
                        <InputDate
                          label="Active date"
                          placeholder="Start tour date"
                          value={tourInformation.activeDate}
                          setDate={handleSetDate}
                          labelStyle={{
                            display: 'block',
                            fontWeight: 'bold',
                            marginBottom: '4px',
                          }}
                          name="activeDate"
                          disabled={
                            !currentPackage.isReadyFixPrice || isOperation
                          }
                          minDate={new Date()}
                        />
                      </Form.Field>
                      <Form.Field>
                        <InputDate
                          label="Expired date"
                          placeholder="Expired date"
                          value={tourInformation.expiredDate}
                          setDate={handleSetDate}
                          labelStyle={{
                            display: 'block',
                            fontWeight: 'bold',
                            marginBottom: '4px',
                          }}
                          name="expiredDate"
                          validate={errors}
                          minDate={addDate(new Date(), 1, 'd')}
                          disabled={isOperation}
                        />
                      </Form.Field>
                    </Form.Group>
                  )}
                  <Form.Group widths="equal">
                    {!isPackageMangement && !isFixedPackage && (
                      <Form.Field>
                        <InputWithLabel
                          label="Group Capacity"
                          type="select"
                          fluid={true}
                          required
                          name="groupCapacity"
                          value={tourInformation.groupCapacity}
                          options={groupCapacity}
                          placeholder="Amount of Pax"
                          disabled={
                            isFixedPackage ||
                            currentPackage.disabledEdit ||
                            isOperation
                          }
                          handleChange={this.handleOnchange}
                          objectname="tourInformation"
                          validate={errors}
                          loading={loadingTourTransaction}
                        />
                      </Form.Field>
                    )}
                    <Form.Field>
                      <InputWithLabel
                        label="Tour Type"
                        type="select"
                        fluid={true}
                        required
                        name="tourType"
                        value={tourInformation.tourType}
                        placeholder="Tour Type"
                        options={listTourType}
                        loading={loadingTourTransaction}
                        disabled={
                          isFixedPackage ||
                          currentPackage.disabledEdit ||
                          isPublished ||
                          isOperation
                        }
                        handleChange={this.handleOnchange}
                        objectname="tourInformation"
                        validate={errors}
                      />
                    </Form.Field>
                    <Form.Field>
                      <InputWithLabel
                        label="Tour Category"
                        type="select"
                        required
                        name="tourCategory"
                        value={tourInformation.tourCategory}
                        placeholder="Tour Category"
                        options={listTourPaxType}
                        loading={loadingTourTransaction}
                        disabled={
                          isFixedPackage ||
                          currentPackage.disabledEdit ||
                          isPublished ||
                          isOperation
                        }
                        handleChange={this.handleOnchange}
                        objectname="tourInformation"
                        validate={errors}
                      />
                    </Form.Field>
                  </Form.Group>
                </Form>
                {!isPackageMangement
                  ? totalAllocation > 0 && (
                      <TablePaxArrangement
                        guests={guests}
                        roomAllocation={roomAllocation}
                        packageType={currentPackage.type}
                        foc={foc}
                      />
                    )
                  : null}
                {!isOperation &&
                  !isPackageMangement &&
                  (currentPackage.disabledEdit || (
                    <ModalPaxArrangement
                      open={openModalPaxArrangement}
                      handleModal={this.handleModalPaxArrangement}
                      guests={guests}
                      guestList={guestList}
                      roomAllocation={roomAllocation}
                      currentPackage={currentPackage}
                      priceRoom={priceRoom}
                      headlineProgram={headlineProgram}
                      paymentTerms={paymentTerms}
                      nowPayment={nowPayment}
                      supplements={supplements}
                      handleGuestAndRoom={handleGuestAndRoom}
                      groupCapacity={tourInformation.groupCapacity}
                      foc={foc}
                      addFoc={this.props.addFocAmount}
                      isReadyFixPrice={
                        currentPackage.type === 'FixedDateVariable'
                      }
                      minPaxReducer={tourInformation.minPaxReducer}
                    />
                  ))}
              </Grid.Column>
              {isPackageMangement ? (
                <Grid.Column>
                  <AdditionalInfo
                    additionalInfoList={additionalInfo}
                    handleAdditionalInfo={this.props.handleAdditionalInfo}
                    deletedAdditionalInformation={
                      this.props.deletedAdditionalInformation
                    }
                    tableName="Additional Info"
                    isEVoucher={false}
                    disabled={isOperation}
                  />
                  <AdditionalInfo
                    additionalInfoList={additionalInfo}
                    handleAdditionalInfo={this.props.handleAdditionalInfo}
                    deletedAdditionalInformation={
                      this.props.deletedAdditionalInformation
                    }
                    tableName="Additional Info For E-Voucher"
                    isEVoucher={true}
                    disabled={isOperation && isPublished}
                  />
                  {/* <AdditionalServices /> */}
                  <AdditionalServices
                    headlineProgram={headlineProgram}
                    selectedAdditionalServices={this.props.additionalServices}
                    setAdditionalServices={this.props.setAdditionalServicesList}
                  />
                  <Image
                    headerContent="Image"
                    buttonContent="Add Image"
                    deleteData={this.deleteImage}
                    listData={images}
                    openDetail={this.openDetail}
                    handleChangePrimaryImage={this.handleChangePrimaryImage}
                    addData={this.openDetail}
                    pattern="BookingTemplateImages/"
                    pagedData={pagedDataImages}
                    pageChange={this.pageChangeImage}
                    activePage={dataForImage.activePageImage}
                    handleDeleteImages={this.props.handleDeleteImages}
                    disablePage={isOperation}
                  />
                  <ModalImage
                    openModal={dataForImage.openModalImage}
                    handleChange={this.changeImage}
                    addImage={this.addImage}
                    handleClose={this.handleClose}
                    header="Upload Image"
                    image={dataForImage.imagesModal}
                    listStepImage={dataForImage.listStepImage}
                    handleNext={this.handleNext}
                    handleBackStep={this.handleBackStep}
                    indexActive={dataForImage.indexActive}
                    handleChangePrimaryImage={
                      this.handleChangePrimaryImageModal
                    }
                    imageRatio={dataForImage.image}
                  />
                </Grid.Column>
              ) : (
                <Grid.Column>
                  <SegmentCustomerOnBehalf
                    currentPackage={currentPackage}
                    handleOnbehalf={this.props.handleOnbehalf}
                    onBehalf={this.props.onBehalf}
                    isQuotation={isQuotation}
                  />
                  {!isShowDownloadInformation && (
                    <div style={{ marginTop: '1em' }}>
                      <Header as="h4">Download Form Information</Header>
                      <Grid columns={2}>
                        {status === 'Confirmed' && (
                          <Grid.Row style={{ paddingBottom: '0px' }}>
                            <Grid.Column>
                              <Container
                                textAlign="left"
                                style={{ marginTop: '0.5em' }}
                              >
                                Voucher confirmation
                              </Container>
                            </Grid.Column>
                            <Grid.Column>
                              <Button
                                icon="download"
                                primary
                                circular
                                onClick={
                                  this.handleClickDownloadVoucherInformation
                                }
                              />
                            </Grid.Column>
                          </Grid.Row>
                        )}

                        <Grid.Row style={{ paddingBottom: '0px' }}>
                          <Grid.Column>
                            <Container
                              textAlign="left"
                              style={{ marginTop: '0.5em' }}
                            >
                              Schedule
                            </Container>
                          </Grid.Column>
                          <Grid.Column>
                            <Button
                              icon="download"
                              primary
                              circular
                              onClick={this.handleClickDownloadSchedule}
                            />
                          </Grid.Column>
                        </Grid.Row>
                        {isShowAgentInvoice && (
                          <Grid.Row style={{ paddingBottom: '0px' }}>
                            <Grid.Column>
                              <Container
                                textAlign="left"
                                style={{ marginTop: '0.5em' }}
                              >
                                Agent Invoice
                              </Container>
                            </Grid.Column>
                            <Grid.Column>
                              <Button
                                icon="download"
                                primary
                                circular
                                onClick={this.handleClickDownloadInvoice}
                                name="agent"
                              />
                            </Grid.Column>
                          </Grid.Row>
                        )}

                        {isShowCustomerInvoice && (
                          <Grid.Row style={{ paddingBottom: '0px' }}>
                            <Grid.Column>
                              <Container
                                textAlign="left"
                                style={{ marginTop: '0.5em' }}
                              >
                                Customer Invoice
                              </Container>
                            </Grid.Column>
                            <Grid.Column>
                              <Button
                                icon="download"
                                primary
                                circular
                                onClick={this.handleClickDownloadInvoice}
                                name="customer"
                              />
                            </Grid.Column>
                          </Grid.Row>
                        )}
                      </Grid>
                    </div>
                  )}

                  {isFixedPackage && isCreatePackage && (
                    <div>
                      <SegmentCommision
                        commissions={commissionsPackage}
                        splitCommision={commission.splitCommision}
                        printCommision={commission.printCommision}
                        handleChangeRadio={this.handleOnchange}
                        currency={
                          packageDetail
                            ? packageDetail.BookingDetailSum.CurrencyId
                              ? packageDetail.BookingDetailSum.CurrencyId
                              : priceRoom
                              ? priceRoom.CurrencyId
                              : ''
                            : priceRoom
                            ? priceRoom.CurrencyId
                            : ''
                        }
                      />
                      {/* <SegmentAdditionalItem
                        listData={supplements}
                        guests={guests}
                        paymentTerms={paymentTerms}
                        nowPayment={nowPayment}
                        handleSupplements={handleSupplements}
                      /> */}
                    </div>
                  )}
                  <div style={{ marginTop: '1em', paddingTop: '20px' }}>
                    <Header as="h4">Note</Header>
                    {status === 'Cancelled' ? (
                      <Grid.Row style={{ marginTop: '-1.3em' }}>
                        <SegmentCancellationReason
                          cancellationReason={cancellationReason}
                          cancellationReasonNote={cancellationReasonNote}
                          currentPackage={currentPackage}
                        />
                      </Grid.Row>
                    ) : (
                      <Grid.Row style={{ marginTop: '-1.5em' }}>
                        <SegmentTourNote
                          // tourNote={tourNote}
                          tourNote={tourInformation.tourNote}
                          currentPackage={currentPackage}
                          isQuotation={isQuotation}
                          loadingTourTransaction={loadingTourTransaction}
                          handleOnchange={this.handleOnchange}
                        />
                      </Grid.Row>
                    )}
                  </div>

                  {(currentPackage.type === 'FixedDateVariable' ||
                    currentPackage.type === 'Fixed') &&
                  currentPackage.status === 'create' ? (
                    <div style={{ marginTop: '1em', paddingTop: '20px' }}>
                      <Header as="h4">Room Allocation Price</Header>
                      <TableRoomAllocationPrice priceRoom={priceRoom} />
                    </div>
                  ) : (
                    ''
                  )}
                </Grid.Column>
              )}
            </Grid>
          </Collapse>
        </div>
        {/* <ModalDetailFixedPackage
          closeModal={this.handleModalDetail}
          open={openModalDetail}
          packageDetail={packageDetail}
        /> */}
      </Segment>
    );
  }
}
GeneralInformation.propTypes = {
  id: PropTypes.string,
  handlePackage: PropTypes.func,
  typeTransaction: PropTypes.string,
  selectedPackage: PropTypes.object,
  packages: PropTypes.array,
  loadingPackage: PropTypes.bool,
  currentPackage: PropTypes.object,
  supplements: PropTypes.array,
  tourInformation: PropTypes.object,
  cityInCountry: PropTypes.array,
  optionCityInCountry: PropTypes.array,
  getTourTypeAction: PropTypes.func,
  listTourType: PropTypes.array,
  loadingTourTransaction: PropTypes.bool,
  // loadingCityGroup: PropTypes.bool,
  getTourPaxTypeAction: PropTypes.func,
  listTourPaxType: PropTypes.array,
  handleGeneralInfo: PropTypes.func,
  guests: PropTypes.object,
  guestList: PropTypes.array,
  roomAllocation: PropTypes.object,
  priceRoom: PropTypes.object,
  headlineProgram: PropTypes.object,
  handleGuestAndRoom: PropTypes.func,
  commission: PropTypes.object,
  commissionsPackage: PropTypes.array,
  handleSupplements: PropTypes.func,
  paymentTerms: PropTypes.array,
  nowPayment: PropTypes.object,
  handleOnbehalf: PropTypes.func,
  onBehalf: PropTypes.object,
  setAccommodationOnChangeIndex: PropTypes.func,
  setheadlineProgram: PropTypes.func,
  placeByRegion: PropTypes.array,
  getAirportAction: PropTypes.func,
  packageDetail: PropTypes.func,
  errors: PropTypes.object,
  setErrorsGeneralInform: PropTypes.func,
  isPackageMangement: PropTypes.bool,
  additionalInfo: PropTypes.array,
  handleAdditionalInfo: PropTypes.func,
  images: PropTypes.array,
  handleImages: PropTypes.func,
  foc: PropTypes.object,
  addFocAmount: PropTypes.func,
  getDownloadScheduleByTransactionIdAction: PropTypes.func,
  getDownloadVoucherConfirmationByTransactionIdAction: PropTypes.func,
  deletedAdditionalInformation: PropTypes.func,
  handleDeleteImages: PropTypes.func,
  isPublished: PropTypes.bool,
  handleChangeIsFixPrice: PropTypes.func,
  handleSetDate: PropTypes.func,
  availableDate: PropTypes.array,
  isTourTransactionReadyFixedPrice: PropTypes.bool,
  tourNote: PropTypes.object,
  cancellationReason: PropTypes.object,
  cancellationReasonNote: PropTypes.object,
  additionalServices: PropTypes.array,
  setAdditionalServicesList: PropTypes.func,
  isOperation: PropTypes.bool,
};

const mapStateToProps = state => {
  let listTourType = state.TourTransactionStore.listTourType.map(e =>
    options(e.Id, e.Id, e.Name)
  );
  let listTourPaxType = state.TourTransactionStore.listTourPaxType.map(e =>
    options(e.Id, e.Id, e.Name)
  );
  return {
    listTourType,
    listTourPaxType,
    loadingTourTransaction: state.TourTransactionStore.loading,
    placeByRegion: state.AirportStore.places,
  };
};

export default connect(mapStateToProps, {
  getTourTypeAction,
  getTourPaxTypeAction,
  getAirportAction,
  getDownloadScheduleByTransactionIdAction,
  getDownloadVoucherConfirmationByTransactionIdAction,
})(GeneralInformation);

// export default generalInformation;
