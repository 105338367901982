import React from 'react';
// import { Dimmer, Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  FLIGHTTIME,
  FREETIME,
  QUEUETIME,
  FREETIMELOCKED,
  RECREATION,
  EAT,
} from '../../../../shared/components/tourTransaction/dailyProgram/constant/activityType';
const DRIVING = 'DRIVING';
const ARRIVAL = 'ARRIVAL';
const CHECKIN = 'CHECKIN';
const DAYEND = 'DAYEND';
const DAYSTART = 'DAYSTART';
const DEPARTURE = 'DEPARTURE';
const CONFIG_SHOW_DESC = [
  {
    code: ARRIVAL,
    style: 1,
    location: 'Desc',
  },
  {
    code: DEPARTURE,
    style: 1,
    location: 'Desc',
  },
  {
    code: CHECKIN,
    style: 1,
    location: 'Name',
  },
  {
    code: DAYEND,
    style: 1,
    location: null,
  },
  {
    code: DAYSTART,
    style: 1,
    location: null,
  },
  {
    code: DRIVING,
    style: 2,
  },
  {
    code: FLIGHTTIME,
    style: 3,
  },
  {
    code: FREETIME,
    style: 4,
  },
  {
    code: QUEUETIME,
    style: 4,
  },
  {
    code: FREETIMELOCKED,
    style: 4,
  },
  {
    code: RECREATION,
    style: 4,
  },
  {
    code: EAT,
    style: 4,
  },
];
const ShowDescription = props => {
  const { movement } = props;
  const style = CONFIG_SHOW_DESC.find(e => e.code === movement.MovementName);
  if (style) {
    if (style.style === 1) {
      return (
        <span>
          , at
          {movement.Item !== {} && style.location
            ? ` ${movement.Item[style.location]}, ${movement.DestinationName}`
            : ` ${movement.DestinationName}`}
        </span>
      );
    } else if (style.style === 2) {
      return (
        <span>
          , Durations {movement.DurationText ? movement.DurationText : 0} at{' '}
          {movement.DestinationName}
        </span>
      );
    } else if (style.style === 3) {
      return (
        <span>
          , Durations {movement.DurationText}{' '}
          {movement.Note ? '- ' + movement.Note : ''}
        </span>
      );
    } else if (style.style === 4) {
      return (
        <span>
          , at{' '}
          {movement.Item !== {}
            ? movement.Item.Name + ' - ' || '-'
            : movement.Destination + ' - ' || '-'}
          {movement.DurationText}
        </span>
      );
    }
  } else {
    return (
      <span>
        , at{' '}
        {movement.Item !== {}
          ? movement.Item.Name || '-'
          : movement.Destination || '-'}
      </span>
    );
  }
};
ShowDescription.propTypes = {
  movement: PropTypes.object,
};
export default ShowDescription;
