import React from 'react';
import { connect } from 'react-redux';
import { Segment, Icon, Grid, Label } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import GeneralInformation from '../../shared/components/tourTransaction/generalInformation/GeneralInformation';
import {
  setAdditionalItem,
  setAdditionalPrice,
} from '../../../scripts/additionalItem';
import {
  setTourInformation,
  // setGuests,
  // setRoomAllocation,
  // setCommision,
  getNowPaymentTerm,
  getPaymentTerms,
  // setOnBehalf,
} from '../../../components/genericFunction/convertPackage';
import {
  getActivityListAction,
  resetActivityListAction,
  getTourOperatorProfileAction,
} from '../../../actions/packageAction';
import {
  getCityInCountry,
  resetCityAction,
} from '../../shared/actions/cityAction';
import { getCurrenciesAction } from '../../../actions/currenciesAction';
import {
  getTourTypeAction,
  getTourOperatorByIdAction,
} from '../../../actions/tourTransactionAction';
import {
  getSupplementItemByFixedPackageIdAction,
  resetStatusTransactionAction,
  getInvoiceByCompanyCodeAction,
  getPrintPaymentReceiptTransactionAction,
} from '../../../actions/tourTransactionAction';
import {
  // initialSetCustomPackage,
  // initialSetReadyPackage,
  // dailyGenerate4,
  dailyGenerateCopy,
} from '../../../scripts/itineraryBuilder/dailyProgram';
import { getAirportAction } from '../../../actions/airportAction';
import { changeGuest } from '../../../scripts/itineraryBuilder/guest';
import SegmentDestinationsSummary from '../../shared/components/tourTransaction/destinationSummary/SegmentDestinationsSummary';
import SegmentDailyProgram from '../../shared/components/tourTransaction/dailyProgram/TourItinerary';
import Loader from '../../../components/loader/loader';
import { getFixedPackage } from '../../../actions/packageAction';
import AllButtonAction from './components/button/AllButtonActionReadyPackage';
import TabMenu from '../../../components/tab/TabMenuOnlyHeader';
// import SegmentTourOperator from '../../shared/components/tourTransaction/tourOperator/segment/TourOperatorSegment';
import getPackageTransactionHistory from '../../../actions/packageTransactionHistoryAction';
import TabTransactionHistory from '../../shared/components/tab/TabTransactionHistory';
// import TabPricReadyPackageFixedPrice from './components/tabPrice/TabPriceReadyPackageFixedPrice';
import TabPeriodDate from './components/periodDate/SegmentPeriodDate';
import {
  MENU_SUMMARY,
  MENU_DAILY,
  // MENU_PRICE,
  MENU_CUSTOM_PRICE,
  MENU_HISTORY,
  // MENUS,
  MENUFULL,
  MENU_PERIOD,
  MENUS_WITH_TIER_PRICE,
  // MENU_DAY_AVAILABLE,
} from './constant/tabMenuTitle';
import { initialGuest, initialRoom } from './constant/initialData';
import {
  inputValidation,
  dateValidation,
} from './services/validation/validation';
import { convertDateFormat } from '../../../scripts/itineraryBuilder/timeHelper';
import { addDate } from '../../../scripts/moment';

import {
  initialSetCustomPackage,
  initialSetReadyPackage,
} from '../../../scripts/itineraryBuilder/itineraryBuilder';
import { getPlaceByPlaceTypeIdAction } from '../../../actions/airportAction';
import {
  updateAdditionalServiceAfterChangeDestination,
  initialAdditionalServices,
} from '../../shared/services/additionalServices/processData';
import TabTierPriceBasedPax from './components/tabPrice/TabTierPriceBasedPax';
import { initialCustomPrices } from './scripts/scriptTierPrice';
const FILL = 'Please fill ';
const CHOOSE = 'Please choose ';
class ReadyPackageDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPackage: {
        type: '',
        id: 0,
        status: '',
        disabledEdit: false,
        isReadyFixPrice: false,
      },
      supplements: [],
      tourInformation: {
        tourName: '',
        firstDestination: '',
        groupCapacity: 'SMALL',
        tourType: '',
        tourCategory: '',
        description: '',
        expiredDate: convertDateFormat(
          addDate(new Date(), 1, 'd'),
          'YYYY-MM-DD'
        ),
        activeDate: convertDateFormat(new Date(), 'YYYY-MM-DD'),
        isRoomAllotmetNeeded: false,
        minPax: '',
        // availableDay: [0, 1, 2, 3, 4, 5, 6],
      },
      guests: initialGuest,
      guestList: [],
      price: {},
      // onBehalf: {},
      headlineProgram: {
        Departures: [],
        MainPrograms: [],
        Returns: [],
      },
      commission: {
        splitCommision: null,
        printCommision: 0,
      },
      commissionsPackage: [],
      errors: {
        firstName: { error: false, message: '' },
        lastName: { error: false, message: '' },
        identityNbr: { error: false, message: '' },
        identityType: { error: false, message: '' },
        guestTitle: { error: false, message: '' },
        countryId: { error: false, message: '' },
        summaryProgramErrorrs: [],
      },
      dailyProgram: [],
      roomAllocation: initialRoom,
      tourOperator: null,
      firstDestination: null,
      accommodationChangeIndex: null,
      paymentTerm: [],
      invoiceList: [],
      requestDemoPrice: {},
      demoPrice: null,
      availablePax: 0,
      packageDetail: null,
      errorGeneralInformation: {
        tourName: { error: false, message: FILL + 'tour name' },
        groupCapacity: { error: false, message: CHOOSE + 'group capacity' },
        tourType: { error: false, message: CHOOSE + 'tour type' },
        tourCategory: { error: false, message: CHOOSE + 'tour category' },
        firstDestination: { error: false, message: FILL + 'first destination' },
        description: { error: false, message: FILL + 'destination' },
        expiredDate: { error: false, message: FILL + 'expired date' },
        minPax: { error: false, message: FILL + 'minimum pax' },
        // availableDay: { error: false, message: CHOOSE + 'available day' },
      },
      isPublished: false,
      additionalInfo: [],
      images: [],
      tabActive: MENU_SUMMARY,
      // ready fix price
      listPeriod: [],
      packagePrice: {
        SingleRoomPrice: 0,
        SharingRoomPrice: 0,
        ChildExtraBedPrice: 0,
        AdultExtraBedPrice: 0,
        SharingBedPrice: 0,
        NoBedPrice: 0,
        CurrencyId: 'IDR',
      },
      customPrices: {
        Prices: [],
        SystemMargin: 0,
        TourOperatorMargin: 0,
        IsTourOperatorFixedPackage: false,
      },
      packageHistoryList: [],
      deletedAdditionalInformation: [],
      deletedImages: [],
      tourNote: '',
      // end
      additionalServices: [],
    };
  }
  componentDidMount = () => {
    const { TypePackage, TypeTransaction, Id } = this.props.match.params;
    this.setPageReady(TypePackage, TypeTransaction, Id);
    // const { TypeTransaction, Id } = this.props.match.params;
    // this.setPageReady(TypeTransaction, Id);
    if (this.props.placeByType) {
      this.props.getPlaceByPlaceTypeIdAction(1);
    }
  };

  // componentDidMount = () => {
  //   const { TypePackage, TypeTransaction, Id } = this.props.match.params;
  //   // eslint-disable-next-line
  //   Promise.all([
  //     this.props.getTourOperatorProfileAction(),
  //     this.props.getCityInCountry(),
  //     this.props.getCurrenciesAction(),
  //     this.props.getTourTypeAction(),
  //     this.props.getActivityListAction(),
  //   ]).then(() => {
  //     if (TypeTransaction === 'create') {
  //       let initial = initialSetCustomPackage(
  //         this.state.headlineProgram,
  //         this.state.roomAllocation
  //       );
  //       let currentPackage = {
  //         type: 'Ready',
  //         id: Id,
  //         status: TypeTransaction,
  //         disabledEdit: false,
  //         isReadyFixPrice: false,
  //       };
  //       this.setState({
  //         headlineProgram: initial.headlineProgram,
  //         dailyProgram: initial.dailyProgram,
  //         currentPackage,
  //       });
  //     } else {
  //       this.getPackage(TypePackage, TypeTransaction, Id);
  //     }
  //   });
  // };

  setPageReady = (TypePackage, TypeTransaction, Id) => {
    // eslint-disable-next-line
    Promise.all([
      this.props.getTourOperatorProfileAction(),
      this.props.getCityInCountry(),
      this.props.getCurrenciesAction(),
      this.props.getTourTypeAction(),
      this.props.getActivityListAction(),
    ]).then(() => {
      if (TypeTransaction === 'create') {
        let initial = initialSetCustomPackage(
          this.state.headlineProgram,
          this.state.roomAllocation
        );

        let currentPackage = {
          type: 'Ready',
          id: Id,
          status: TypeTransaction,
          disabledEdit: false,
          isReadyFixPrice: false,
        };
        this.setState({
          headlineProgram: initial.headlineProgram,
          dailyProgram: initial.dailyProgram,
          currentPackage,
        });
      } else {
        this.getPackage(TypePackage, TypeTransaction, Id);
      }
    });
  };

  getPackage = (TypePackage, TypeTransaction, Id) => {
    // eslint-disable-next-line
    this.props.getFixedPackage(TypePackage, Id).then(async () => {
      await this.setPackage(this.props.transactionDetail);
      await this.getTourOperator(
        this.props.transactionDetail.BookingDetailSum.TourOperatorProfileId
      );
      await this.props.getPackageTransactionHistory(Id).then(res => {
        this.setState({
          ...this.state,
          // isThereTransactionHistory: res.value.data.length > 0 ? true : false,
          packageHistoryList: res.value.data,
        });
      });
      this.setState({
        ...this.state,
        demoPrice: this.props.transactionDetail,
      });
    });
    // }
    let currentPackage = {
      type: TypePackage === 'FixedDateVariable' ? 'FixedDateVariable' : 'Ready',
      id: Id,
      status: TypeTransaction,
      disabledEdit: false,
      isReadyFixPrice: TypePackage === 'FixedDateVariable' ? true : false,
    };
    this.setState({
      ...this.state,
      currentPackage,
    });
  };
  setPackage = packageDetail => {
    let { currentPackage } = this.state;
    let { type, status } = currentPackage;
    let {
      HeadlineProgram,
      DailyPrograms,
      BookingDetailSum,
      IsPublished,
      Descriptions,
      Images,
      CustomPrices,
      IsTourOperatorFixedPackage,
      BaseMarginPercentage,
      TourOperatorMarginPercentage,
    } = packageDetail;
    let { activityList, cityInCountry } = this.props;
    let isCreate = status === 'create';
    let isReadyFixPrice = type === 'FixedDateVariable';
    let tourInformation = setTourInformation(packageDetail);
    tourInformation.description = packageDetail.BookingDetailSum.Description;
    tourInformation.expiredDate = packageDetail.BookingDetailSum.ExpiredOn;
    tourInformation.isRoomAllotmetNeeded =
      packageDetail.BookingDetailSum.IsAllotment;
    tourInformation.expiredDate = packageDetail.BookingDetailSum.ExpiredOn;
    tourInformation.groupCapacity = 'SMALL';
    tourInformation.activeDate = packageDetail.ActiveDate;
    let guests = initialGuest;
    // let onBehalf = setOnBehalf(packageDetail, status);
    let guestList = isCreate ? [] : packageDetail.TourGuestSum;
    let roomAllocation = initialRoom;
    // setRoomAllocation(packageDetail, type, status);
    if (type !== 'Similar') {
      guestList = packageDetail.TourGuestSum;
    }

    let newHeadline =
      type !== 'Fixed'
        ? initialSetReadyPackage(
            packageDetail,
            status !== 'create' ? 'Quotation' : type,
            cityInCountry,
            roomAllocation,
            isReadyFixPrice
          )
        : null;
    let newDp =
      type !== 'Fixed' && !isReadyFixPrice
        ? dailyGenerateCopy(
            newHeadline.Departures,
            newHeadline.Returns,
            newHeadline.MainPrograms,
            DailyPrograms,
            activityList,
            this.props.cityInCountry
          )
        : null;
    // initialSetReadyPackage(
    //   HeadlineProgram,
    //   DailyPrograms,
    //   cityInCountry,
    //   status !== 'create' ? 'Quotation' : type,
    //   roomAllocation,
    //   activityList
    // );
    this.getPlaceByRegion(
      newHeadline ? newHeadline.MainPrograms : HeadlineProgram.MainPrograms
    );
    // this.props.getInvoiceByCompanyCodeAction(onBehalf.customerCode).then(res =>
    //   this.setState({
    //     invoiceList: res.value.data.filter(
    //       // eslint-disable-next-line
    //       data => data.TourTransactionId == currentPackage.id
    //     ),
    //   })
    // );
    let packagePrice = isReadyFixPrice ? packageDetail.Prices : {};
    let customPrices = isReadyFixPrice
      ? initialCustomPrices({
          stateCustomPrices: this.state.customPrices,
          Prices: CustomPrices,
          IsTourOperatorFixedPackage,
          BaseMarginPercentage,
          TourOperatorMarginPercentage,
        })
      : // { ...this.state.customPrices, Prices: packageDetail.CustomPrices || [] }
        this.state.customPrices;
    // let commission = setCommision(packageDetail, type, status);
    // let commissionsPackage =
    //   isFixedPackage && isCreate ? packageDetail.Commissions : [];
    // let availablePax = isFixedPackage
    //   ? packageDetail.BookingDetailSum.FixedPackage.MinimumGuest -
    //     packageDetail.BookingDetailSum.FixedPackage.ConfirmedGuest
    //   : 0;
    let periodDate = isReadyFixPrice
      ? packageDetail.BookingDetailSum.PeriodeDates
      : [];

    // additional services
    let additionalServices = initialAdditionalServices(
      packageDetail.AdditionalServices
    );
    // end additional services

    this.setState({
      ...this.state,
      tourInformation,
      guests,
      guestList,
      // onBehalf,
      roomAllocation,
      packagePrice,
      customPrices,
      // commission,
      // commissionsPackage,
      // price,
      // commission,
      // commissionsPackage,
      headlineProgram: newHeadline ? newHeadline : HeadlineProgram,
      dailyProgram: newDp ? newDp : DailyPrograms,
      paymentTerm: BookingDetailSum.PaymentTerms,
      // availablePax,
      bookingStatus: BookingDetailSum.Status,
      packageDetail,
      isPublished: IsPublished,
      additionalInfo: Descriptions.map(e =>
        e.SubTitle.includes('FOR_VOUCHER')
          ? {
              ...e,
              SubTitle: e.SubTitle.replace('FOR_VOUCHER ', ''),
              ForVoucher: true,
            }
          : { ...e }
      ),
      images: Images,
      listPeriod: periodDate,
      additionalServices,
    });
  };
  getPlaceByRegion = summaryProgram => {
    // eslint-disable-next-line
    summaryProgram.map(item => {
      // eslint-disable-next-line
      if (item.hasOwnProperty('AccommodationSummary')) {
        item.Region && this.props.getAirportAction(item.Region);
      }
      return item;
    });
  };
  // -------------------Handle general information ---------------
  setSupplementState = packageDetail => {
    let supplements = setAdditionalItem(
      this.props.supplements,
      this.state.currentPackage.id
    );
    let nowPayment = getNowPaymentTerm(packageDetail);
    let paymentTerms = getPaymentTerms(packageDetail);
    supplements = setAdditionalPrice(
      supplements,
      paymentTerms,
      nowPayment.PaymentPercentage
    );
    this.setState({
      ...this.state,
      supplements,
      nowPayment,
      paymentTerms,
    });
  };

  handleGeneralInfo = (name, value, objectname) => {
    let newData = this.state[objectname];
    newData = { ...newData, [name]: value };
    this.setState({ ...this.state, [objectname]: newData });
  };

  handleGuestAndRoom = (guests, room, guestList, headline, supplements) =>
    this.setState({
      ...this.state,
      guests,
      roomAllocation: room,
      guestList,
      headlineProgram: headline,
      supplements,
    });

  handleSupplements = supplements =>
    this.setState({
      ...this.state,
      supplements,
    });

  handleErrorsGeneralInform = errorGeneralInformation =>
    this.setState({ ...this.state, errorGeneralInformation });

  handleAdditionalInfo = (name, value) =>
    this.setState({ ...this.state, [name]: value });

  handleImages = (name, value) =>
    this.setState({ ...this.state, [name]: value });

  // -------------------End Handle general information ---------------
  //  =========== handle guest form ====================
  handleChangeGuestList = (e, { name, value, objectname }) => {
    let { guestList } = this.state;
    let newGuestList = changeGuest(name, guestList, objectname, value);
    this.setState({
      ...this.state,
      guestList: newGuestList,
    });
  };
  //  =========== end handle guest form ====================
  setDailyProgram = setDailyProgram => {
    this.setState({
      dailyProgram: setDailyProgram,
    });
  };

  //  =========== handle destination summary form ====================
  setheadlineProgram = headlineProgram => {
    let additionalServices = updateAdditionalServiceAfterChangeDestination(
      headlineProgram,
      this.state.additionalServices
    );
    this.setState({
      ...this.state,
      headlineProgram,
      additionalServices,
    });
  };
  //  =========== end handle destination summary form ====================
  //  =========== handle tour operator ====================
  getTourOperator = id => {
    this.props
      .getTourOperatorByIdAction(id)
      .then(() => this.setTourOperator(this.props.tourOperatorById));
  };

  setTourOperator = tourOperator =>
    this.setState({
      ...this.state,
      tourOperator,
    });

  setReqDemoPrice = requestDemoPrice =>
    this.setState({
      ...this.state,
      requestDemoPrice,
    });

  setDemoPrice = demoPrice => this.setState({ ...this.state, demoPrice });
  //  =========== end handle tour operator ====================

  //  =========== end handle accommodation ====================
  setAccommodationOnChangeIndex = index =>
    this.setState({
      ...this.state,
      accommodationChangeIndex: index,
    });
  //  =========== end handle tour operator ====================
  handleReloadPage = () => {
    window.location.reload();
  };
  handleTab = (e, { value }) => {
    this.setState({ ...this.state, tabActive: value });
  };

  checkGeneralInformation = () => {
    const {
      tourInformation,
      errorGeneralInformation,
      currentPackage,
    } = this.state;
    let generalInfoValidation = inputValidation(
      tourInformation,
      errorGeneralInformation,
      currentPackage.isReadyFixPrice
    );
    this.handleErrorsGeneralInform(generalInfoValidation);
    return generalInfoValidation.isError;
  };

  //============ handle action if raedy package already published ========

  handleDeleteImages = value => {
    const { deletedImages } = this.state;
    value === 'clear'
      ? this.setState({ deletedImages: [] })
      : deletedImages.push(value);
  };
  deletedAdditionalInformation = value => {
    const { deletedAdditionalInformation } = this.state;
    value === 'clear'
      ? this.setState({ deletedAdditionalInformation: [] })
      : deletedAdditionalInformation.push(value);
  };
  //======================================================================
  // ready fix price
  handleChangeIsFixPrice = (e, { name }) => {
    let currentPackage = { ...this.state.currentPackage };
    let tourInformation = { ...this.state.tourInformation };
    if (name === 'isReadyFixPrice') {
      currentPackage['isReadyFixPrice'] = !currentPackage.isReadyFixPrice;
      this.setState({
        ...this.state,
        currentPackage,
      });
    } else {
      tourInformation[
        'isRoomAllotmetNeeded'
      ] = !tourInformation.isRoomAllotmetNeeded;
      this.setState({
        ...this.state,
        tourInformation,
      });
    }
  };

  handleSetDate = (date, name) => {
    let tourInformation = { ...this.state.tourInformation };
    tourInformation[name] = convertDateFormat(date, 'YYYY-MM-DD');
    this.setState({
      ...this.state,
      tourInformation,
    });
    let err = dateValidation(
      tourInformation.activeDate,
      tourInformation.expiredDate
    );
    this.setState({
      errorGeneralInformation: {
        ...this.state.errorGeneralInformation,
        expiredDate: {
          error: err,
          message: ' Expired date must be after active ',
        },
      },
    });
    // this.checkGeneralInformation();
  };

  setvariableDateList = list => {
    this.setState({ listPeriod: list });
  };

  setPackagePriceAction = packagePrice => {
    this.setState({ packagePrice: packagePrice });
  };
  handleSetCustomPrices = customPrices =>
    this.setState({
      ...this.state,
      customPrices,
    });
  // end

  //============ additional services ============
  setAdditionalServicesList = data => {
    this.setState({ ...this.state, additionalServices: data });
  };

  // removeDuplicates = (myArr, prop) => {
  //   return myArr.filter((obj, pos, arr) => {
  //     return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
  //   });
  // };

  // getArrayCountryDestination = data => {
  //   let dataCity = data.map(item => {
  //     return {
  //       id: item.City.Country.Id,
  //     };
  //   });
  //   let uniqueCity = this.removeDuplicates(dataCity, 'id');
  //   return uniqueCity;
  // };

  // getAdditionalServiceAfterDestinationChange = (addService, destination) => {
  //   let addServ = [...addService];
  //   addServ = addServ.map(item => {
  //     return {
  //       ...item,
  //       isNotDeletedDestinationCountry: false,
  //     };
  //   });
  //   destination.forEach(dest => {
  //     addService.forEach((service, serviceIdx) => {
  //       if (dest.id === service.DestinationCountryId) {
  //         addServ[serviceIdx].isNotDeletedDestinationCountry = true;
  //       }
  //     });
  //   });
  //   let newSelectedAdditionalService = addServ.filter(
  //     item => item.isNotDeletedDestinationCountry
  //   );
  //   return newSelectedAdditionalService;
  // };

  // updateAdditionalServiceAfterChangeDestination = headlineProgram => {
  //   const { additionalServices } = this.state;
  //   let headlineCity =
  //     headlineProgram &&
  //     headlineProgram.MainPrograms.filter(item => item.City !== null);

  //   let arrayDestination = this.getArrayCountryDestination(
  //     headlineCity && headlineCity
  //   );
  //   let data = this.getAdditionalServiceAfterDestinationChange(
  //     additionalServices,
  //     arrayDestination
  //   );
  //   // this.setState({ ...this.state, additionalServices: data });
  //   return data;
  // };

  //============ end additional services ============

  render() {
    const {
      dailyProgram,
      currentPackage,
      headlineProgram,
      errorGeneralInformation,
      tabActive,
      // tourOperator,
      deletedAdditionalInformation,
      deletedImages,
      // tourOperator,
      // ready fix price
      listPeriod,
      packagePrice,
      customPrices,
      // availableDay,
      //end
    } = this.state;
    const {
      activityList,
      loadingGetPackage,
      isOperation,
      isSales,
      isAdministrator,
    } = this.props;
    let menusTab =
      this.state.currentPackage.status !== 'create'
        ? isOperation
          ? MENUFULL.filter(e => e.value !== 'price')
          : MENUS_WITH_TIER_PRICE
        : MENUS_WITH_TIER_PRICE;

    return (
      <Segment style={{ marginTop: '30px' }}>
        <Loader active={loadingGetPackage} inverted={true} />
        <Label
          as="h3"
          size="huge"
          style={{
            position: 'absolute',
            top: '-25px',
            background: '#38af95',
            color: 'white',
          }}
        >
          <Icon name="clipboard list" /> Ready Package
        </Label>
        <Grid style={{ marginTop: '3px' }}>
          <Grid.Row fluid>
            <Grid.Column width={16}>
              <AllButtonAction
                currentPackage={this.state.currentPackage}
                isPublished={this.state.isPublished}
                // isPublished={this.props.transactionDetail.IsPublished}
                disabledEdit={currentPackage.disabledEdit}
                dailyProgram={dailyProgram}
                generalInformation={this.state.tourInformation}
                headlineProgram={this.state.headlineProgram}
                images={this.state.images}
                additionalInfo={this.state.additionalInfo}
                tourOperator={this.state.tourOperator}
                //validation
                errorGeneralInformation={errorGeneralInformation}
                checkGeneralInformation={this.checkGeneralInformation}
                setPageReady={this.setPageReady}
                //============ handle ready package if already published
                deletedAdditionalInformation={deletedAdditionalInformation}
                deletedAdditionalInformationFunc={
                  this.deletedAdditionalInformation
                }
                deletedImages={deletedImages}
                handleDeleteImages={this.handleDeleteImages}
                //ready fix price
                listPeriod={listPeriod}
                packagePrice={packagePrice}
                customPrices={customPrices}
                //end
                additionalServices={this.state.additionalServices}
                isOperation={isOperation}
              />
              <GeneralInformation
                isOperation={isOperation}
                currentPackage={this.state.currentPackage}
                tourInformation={this.state.tourInformation}
                supplements={this.state.supplements}
                optionCityInCountry={this.props.optionCityInCountry}
                cityInCountry={this.props.cityInCountry}
                // loadingCityGroup={this.props.loadingCityGroup}
                guests={this.state.guests}
                guestList={this.state.guestList}
                roomAllocation={this.state.roomAllocation}
                priceRoom={this.state.price}
                headlineProgram={this.state.headlineProgram}
                handleGuestAndRoom={this.handleGuestAndRoom}
                commission={this.state.commission}
                commissionsPackage={this.state.commissionsPackage}
                handleGeneralInfo={this.handleGeneralInfo}
                handleSupplements={this.handleSupplements}
                paymentTerms={this.state.paymentTerms}
                nowPayment={this.state.nowPayment}
                handleOnbehalf={this.handleOnbehalf}
                setAccommodationOnChangeIndex={
                  this.setAccommodationOnChangeIndex
                }
                setheadlineProgram={this.setheadlineProgram}
                packageDetail={this.state.packageDetail}
                errors={errorGeneralInformation}
                setErrorsGeneralInform={this.handleErrorsGeneralInform}
                isPackageMangement={true}
                isPublished={this.state.isPublished}
                additionalInfo={this.state.additionalInfo}
                handleAdditionalInfo={this.handleAdditionalInfo}
                images={this.state.images}
                handleImages={this.handleImages}
                checkGeneralInformation={this.checkGeneralInformation}
                //============ handle ready package if already published
                deletedAdditionalInformation={this.deletedAdditionalInformation}
                handleDeleteImages={this.handleDeleteImages}
                //ready fix price
                // isReadyFixPrice={isReadyFixPrice}
                handleChangeIsFixPrice={this.handleChangeIsFixPrice}
                handleSetDate={this.handleSetDate}
                tourNote={this.state.tourNote}
                //end
                additionalServices={this.state.additionalServices}
                setAdditionalServicesList={this.setAdditionalServicesList}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <TabMenu
                menus={menusTab}
                tabActive={tabActive}
                handleTab={this.handleTab}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            fluid
            style={tabActive !== MENU_SUMMARY ? { display: 'none' } : {}}
          >
            <Grid.Column width={16}>
              <SegmentDestinationsSummary
                headlineProgram={this.state.headlineProgram}
                dailyProgram={this.state.dailyProgram}
                setDailyProgram={this.setDailyProgram}
                setheadlineProgram={this.setheadlineProgram}
                handleGeneralInfo={this.handleGeneralInfo}
                currentPackage={currentPackage}
                tourInformation={this.state.tourInformation}
                guests={this.state.guests}
                errors={errorGeneralInformation}
                setErrorsGeneralInform={this.handleErrorsGeneralInform}
                isPackageMangement={true}
                roomAllocation={this.state.roomAllocation}
                checkGeneralInformation={this.checkGeneralInformation}
                // isPublished={this.props.transactionDetail.IsPublished}
                isPublished={this.state.isPublished}
                isOperation={isOperation}
                isSales={isSales}
                isAdministrator={isAdministrator}
                // isReadyFixedPrice={true}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={tabActive !== MENU_DAILY ? { display: 'none' } : {}}>
            <Grid.Column width={16}>
              <SegmentDailyProgram
                dailyProgram={dailyProgram}
                headlineProgram={headlineProgram}
                setDailyProgram={this.setDailyProgram}
                typePackage={
                  currentPackage.disabledEdit ? 'Fixed' : currentPackage.type
                }
                movementList={activityList}
                setheadlineProgram={this.setheadlineProgram}
                guests={this.state.guests}
                isPublished={this.props.transactionDetail.IsPublished || false}
                currentPackage={currentPackage}
                // isPackageMangement={true}
                isPackageManagemenEdit={true}
                // isReadyFixedPrice={this.state.currentPackage.isReadyFixPrice}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            style={tabActive === MENU_CUSTOM_PRICE ? {} : { display: 'none' }}
          >
            <Grid.Column width={16}>
              {this.state.currentPackage.isReadyFixPrice ? (
                <TabTierPriceBasedPax
                  handleSetCustomPrices={this.handleSetCustomPrices}
                  isPublished={this.state.isPublished}
                  currencies={this.props.currencies}
                  customPrices={customPrices}
                />
              ) : (
                'this prices is only for the fixed price package'
              )}
            </Grid.Column>
          </Grid.Row>

          {/* hide karena sudah menggunakan tier price */}
          {/* <Grid.Row
          style={
            tabActive === MENU_PRICE &&
            !this.state.currentPackage.isReadyFixPrice
              ? {}
              : { display: 'none' }
          }
          >
            <Grid.Column width={16}>
              <TabPricReadyPackageFixedPrice
                currentPackage={this.state.currentPackage}
                packagePrice={packagePrice}
                setPackagePriceAction={this.setPackagePriceAction}
                isPublished={this.state.isPublished}
                currencies={this.props.currencies}
              />
            </Grid.Column>
          </Grid.Row> */}
          <Grid.Row
            style={tabActive !== MENU_PERIOD ? { display: 'none' } : {}}
          >
            <Grid.Column width={16}>
              <TabPeriodDate
                currentPackage={this.state.currentPackage}
                listPeriod={listPeriod}
                setvariableDateList={this.setvariableDateList}
                headlineProgram={headlineProgram}
                isPublished={this.props.transactionDetail.IsPublished}
                tourInformation={this.state.tourInformation}
                disabled={isOperation}
              />
            </Grid.Column>
          </Grid.Row>
          {this.state.currentPackage.status !== 'create' && (
            <Grid.Row
              style={tabActive !== MENU_HISTORY ? { display: 'none' } : {}}
            >
              <Grid.Column width={16}>
                <TabTransactionHistory
                  historyList={this.props.packageHistoryList}
                  currentPackage={currentPackage}
                />
              </Grid.Column>
            </Grid.Row>
          )}

          <Grid.Row textAlign="center">
            <Grid.Column width={16} />
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

ReadyPackageDetail.propTypes = {
  getFixedPackage: PropTypes.func,
  getSupplementItemByFixedPackageIdAction: PropTypes.func,
  supplements: PropTypes.array,
  getCityInCountry: PropTypes.func,
  cityInCountry: PropTypes.array,
  loadingCityGroup: PropTypes.bool,
  transactionDetail: PropTypes.object,
  getActivityListAction: PropTypes.func,
  getActivityListStatus: PropTypes.string,
  activityList: PropTypes.array,
  resetActivityListAction: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      Id: PropTypes.number,
      type: PropTypes.string,
      TypeTransaction: PropTypes.string,
      PackageType: PropTypes.string,
      TypePackage: PropTypes.string,
    }),
  }),
  listTransactionDetailsStatus: PropTypes.string,
  resetStatusTransactionAction: PropTypes.func,
  listTransactionDetails: PropTypes.object,
  places: PropTypes.array,
  listAccommodation: PropTypes.array,
  listAccommodationItem: PropTypes.array,
  loadingAccommodation: PropTypes.bool,
  optionCityInCountry: PropTypes.array,
  getAirportAction: PropTypes.func,
  getTourOperatorProfileAction: PropTypes.func,
  getCurrenciesAction: PropTypes.func,
  getTourTypeAction: PropTypes.func,
  resetCityAction: PropTypes.func,
  getCityInCountryStatus: PropTypes.string,
  getTourOperatorByIdAction: PropTypes.func,
  tourOperatorById: PropTypes.object,
  loadingTourOperator: PropTypes.bool,
  getInvoiceByCompanyCodeAction: PropTypes.func,
  loadingDemoPrice: PropTypes.bool,
  loadingJoinTourOnBeHalf: PropTypes.bool,
  loadingPostDemoJoinTour: PropTypes.bool,
  loadingTourTransaction: PropTypes.bool,
  loadingCreateTour: PropTypes.bool,
  loadingGetPackage: PropTypes.bool,
  getPackageTransactionHistory: PropTypes.func,
  packageHistoryList: PropTypes.array,
  placeByType: PropTypes.array,
  getPlaceByPlaceTypeIdAction: PropTypes.func,
  currencies: PropTypes.array,
  isOperation: PropTypes.bool,
  isSales: PropTypes.bool,
  isAdministrator: PropTypes.bool,
};

const mapStateToProps = state => {
  let cityInCountry = state.CityStore.getCityInCountryList;
  let optionCityInCountry = cityInCountry.map((city, i) => {
    return {
      key: i,
      text: city.Name + ', ' + city.Region.Name + ', ' + city.Country.Name,
      value: city.Id,
    };
  });
  return {
    supplements: state.TourTransactionStore.supplementItemByFixedPackageId,
    cityInCountry,
    transactionDetail: state.PackageStore.fixedPackage,
    loadingReadyPackage: state.ReadyPackageStore.loading,
    loadingTourTransaction: state.TourTransactionStore.loading,
    // loadingCityGroup: state.CityGroupStore.loading,
    getActivityListStatus: state.PackageStore.getActivityListStatus,
    activityList: state.PackageStore.activityList,
    listTransactionDetailsStatus:
      state.TourTransactionStore.listTransactionDetailsStatus,
    listTransactionDetails: state.TourTransactionStore.listTransactionDetails,
    places: state.AirportStore.places,
    listAccommodationItem: state.AccomodationStore.getAccomodationByProfilIdAndDate.map(
      (item, i) => {
        return { key: i, text: item.Name, value: item.ServiceItemId };
      }
    ),
    loadingAccommodation: state.AccomodationStore.loading,
    optionCityInCountry,
    getCityInCountryStatus: state.CityStore.getCityInCountryStatus,
    tourOperatorById: state.TourTransactionStore.getTourOperatorById,
    loadingTourOperator: state.TourTransactionStore.loadingTourOperator,
    getRestaurantByCityDateAndPaxStatus:
      state.RestaurantStore.getRestaurantByCityDateAndPaxStatus,
    getExcurtionProfileByCity: state.ExcursionStore.getExcurtionProfileByCity,
    getExcurtionProfileByCityStatus:
      state.ExcursionStore.getExcurtionProfileByCityStatus,
    restaurantByCityDateAndPax:
      state.RestaurantStore.restaurantByCityDateAndPax,
    loadingExcursion: state.ExcursionStore.loading,
    loadingDemoPrice: state.TourTransactionStore.loadingPostDemoPrice,
    loadingJoinTourOnBeHalf: state.TourTransactionStore.loadingJoinTourOnBeHalf,
    loadingPostDemoJoinTour: state.TourTransactionStore.loadingPostDemoJoinTour,
    loadingCreateTour: state.TourTransactionStore.loadingCreateTour,
    loadingGetPackage: state.PackageStore.loading,
    packageHistoryList: state.PackageHistoryStore.packageTransactionHistoryList,
    placeByType: state.AirportStore.placeByType,
    currencies: state.currenciesStore.currencies.map((cur, i) => {
      return { key: i, text: cur.Id, value: cur.Id };
    }),
    isOperation: state.UserAccessStore.isOperation,
    isSales: state.UserAccessStore.isSales,
    isAdministrator: state.UserAccessStore.isAdministrator,
  };
};
export default connect(mapStateToProps, {
  getFixedPackage,
  getSupplementItemByFixedPackageIdAction,
  getCityInCountry,
  getActivityListAction,
  resetActivityListAction,
  resetStatusTransactionAction,
  getAirportAction,
  getTourOperatorProfileAction,
  getCurrenciesAction,
  getTourTypeAction,
  resetCityAction,
  getTourOperatorByIdAction,
  getInvoiceByCompanyCodeAction,
  getPrintPaymentReceiptTransactionAction,
  getPackageTransactionHistory,
  // getPackageTransactionHistory,
  getPlaceByPlaceTypeIdAction,
})(ReadyPackageDetail);
