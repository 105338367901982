import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../../../scripts/pagination';
import { headerTableResourceBookingFilter } from '../../constant/headerTable';
import Pagination from '../../../../../components/common/pagination';
import { searchFilterResourceBooking } from '../../services/filterData';
import { Link } from 'react-router-dom';
import IndexPagination from '../../../../../components/genericFunction/indexPagination';
// import styles from '../../styles';

const TableListResourceBookingFilter = props => {
  const {
    data,
    activePage,
    pageChange,
    searchInput,
    resourceBookingTypeFilter,
    // handleClickId,
  } = props;

  let filterData = searchFilterResourceBooking(data ? data : [], searchInput);
  let pagedData = datasetPagination(filterData, 5);
  let listData = pagedData[activePage - 1];
  let { numbersFrom, numbersTo } = getNumberFromTo(listData, activePage, 5);

  return (
    <div className="container-table">
      <Table
        // basic="very"
        striped
        selectable
        columns={16}
        style={{ width: '140%' }}
      >
        <Table.Header>
          <Table.Row>
            {headerTableResourceBookingFilter.map((header, idx) => {
              return <Table.HeaderCell key={idx}>{header}</Table.HeaderCell>;
            })}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {listData ? (
            listData.map((item, idx) => {
              return (
                <Table.Row key={IndexPagination(5, activePage, idx)}>
                  <Table.Cell>
                    <Link
                      to={`/Resource-Booking-Detail/${item.ResourceBookingId}`}
                    >
                      {item.ResourceBookingId}
                    </Link>
                    {/* <Button
                      style={styles.buttonLink}
                      onClick={handleClickId}
                      value={{
                        startDate: item.Date,
                        endDate: item.EndDate,
                        id: item.Id,
                        resourceBookingId: item.ResourceBookingId,
                      }}
                    >
                      {item.Id}
                    </Button> */}
                  </Table.Cell>
                  <Table.Cell>
                    <Link
                      to={`/tour-transaction/edit/${item.PackageType}/${item.TourTransactionCode}`}
                    >
                      {item.TourTransactionCode}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>{item.ResourceBooking}</Table.Cell>
                  <Table.Cell>{item.ResourceName}</Table.Cell>
                  <Table.Cell>{item.ResourceType}</Table.Cell>
                  <Table.Cell>{item.DueDate}</Table.Cell>
                  <Table.Cell>{item.TotalQty}</Table.Cell>
                  <Table.Cell>{item.PaymentStatus}</Table.Cell>
                  <Table.Cell>{item.ResourceStatus}</Table.Cell>
                  <Table.Cell>{item.Status}</Table.Cell>
                  <Table.Cell>{item.Date}</Table.Cell>
                  <Table.Cell>{item.EndDate}</Table.Cell>
                  <Table.Cell>{item.ResourceOwnerShip}</Table.Cell>
                </Table.Row>
              );
            })
          ) : resourceBookingTypeFilter.length ? (
            <Table.Row>
              <Table.Cell colSpan={13} textAlign="center">
                No Data
              </Table.Cell>
            </Table.Row>
          ) : (
            <Table.Row>
              <Table.Cell colSpan={13} textAlign="center">
                Please Select Resource Booking Type To Filter
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      {listData && (
        <Pagination
          pagedData={pagedData}
          numbersTo={numbersTo}
          numbersFrom={numbersFrom}
          data={data}
          pageChange={pageChange}
          activePage={activePage}
        />
      )}
    </div>
  );
};

TableListResourceBookingFilter.propTypes = {
  data: PropTypes.array,
  activePage: PropTypes.number,
  pageChange: PropTypes.func,
  searchInput: PropTypes.string,
  resourceBookingTypeFilter: PropTypes.array,
  handleClickId: PropTypes.func,
};

export default TableListResourceBookingFilter;
