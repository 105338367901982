import React from 'react';
import { connect } from 'react-redux';
import { logoutAction, resetStatusLoginAction } from '../loginAction';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

class Logout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkStatus: false,
    };
  }
  static propTypes = {
    logoutAction: PropTypes.func,
    logoutStatus: PropTypes.string,
    resetStatusLoginAction: PropTypes.func,
  };

  componentDidMount = () => {
    this.props.logoutAction();
  };
  componentDidUpdate = nextProps => {
    if (nextProps.logoutStatus === 'success') {
      nextProps.resetStatusLoginAction();
      this.setState({ checkStatus: true });
      return true;
    }
    return true;
  };

  render() {
    if (this.state.checkStatus) {
      return <Redirect to="Login" />;
    } else {
      return <Redirect to="/" />;
    }
  }
}
function mapStateToProps(state) {
  return {
    logoutStatus: state.LoginStore.logoutAdminStatus,
  };
}
export default connect(
  mapStateToProps,
  { logoutAction, resetStatusLoginAction }
)(Logout);
