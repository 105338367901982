import React, { Component } from 'react';
import { Button, Modal, Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Loader from '../../../../components/loader/loaderInModal';
import InputWithLabel from '../common/input/InputWithLabel';

const SEND_OPTION = [
  {
    key: 1,
    value: 1,
    text: 'Yes, send email to travel agent',
  },
  {
    key: 2,
    value: 2,
    text: 'No, do not send the email',
  },
];

const defaultValue = {
  isSend: { error: false, message: '' },
};
class ModalConfirmationAlert extends Component {
  state = {
    choose: null,
    errorChoose: {
      isSend: { error: false, message: '' },
    },
  };

  handleChangeOption = (e, { value }) => {
    let { errorChoose } = this.state;
    if (value === '') {
      errorChoose.isSend.message = 'Please choose send email or not';
      errorChoose.isSend.error = true;
    } else {
      errorChoose.isSend.message = '';
      errorChoose.isSend.error = false;
    }
    this.setState({ choose: value, errorChoose }, () => {
      this.props.handleSendManualEmail(value);
    });
  };

  handleConfirm = () => {
    const { choose, errorChoose } = this.state;
    const { isChooseSendEmail } = this.props;
    if (typeof isChooseSendEmail !== 'undefined' && isChooseSendEmail) {
      if (choose === '' || choose === null) {
        errorChoose.isSend.message = 'Please choose send email or not';
        errorChoose.isSend.error = true;
        this.setState({ errorChoose });
      } else {
        this.props.confirm();
      }
    } else {
      this.props.confirm();
    }
  };

  handleClose = () => {
    this.setState({ errorChoose: defaultValue }, () => {
      this.props.close();
    });
  };

  render() {
    const {
      openModal,
      size,
      // confirm,
      close,
      message,
      nameConfirm,
      contentOk,
      contentNo,
      header,
      loading,
      isChooseSendEmail,
    } = this.props;
    return (
      <Modal
        size={size ? size : 'mini'}
        open={openModal}
        onClose={close}
        closeOnDimmerClick={false}
        dimmer="blurring"
      >
        <Loader loading={loading} />
        <Modal.Header>{header ? header : 'Confirmation'}</Modal.Header>
        <Modal.Content>
          <h5>{message}</h5>
          {typeof isChooseSendEmail !== 'undefined' && isChooseSendEmail && (
            <>
              <Form>
                <Form.Field>
                  <p>Do you want to send email notification to travel agent?</p>
                  <InputWithLabel
                    type="select"
                    name="isSend"
                    // label="Want to send email to travel agent?"
                    placeholder="Choose..."
                    options={SEND_OPTION}
                    handleChange={this.handleChangeOption}
                    validate={this.state.errorChoose}
                  />
                </Form.Field>
              </Form>
            </>
          )}
        </Modal.Content>
        <Modal.Actions style={{ textAlign: 'right' }}>
          <Button
            positive
            name={nameConfirm}
            content={contentOk ? contentOk : 'Yes'}
            onClick={this.handleConfirm} //confirm
          />
          <Button
            negative
            content={contentNo ? contentNo : 'No'}
            onClick={this.handleClose} //close
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
ModalConfirmationAlert.propTypes = {
  openModal: PropTypes.bool,
  size: PropTypes.string,
  close: PropTypes.func,
  confirm: PropTypes.func,
  message: PropTypes.string,
  nameConfirm: PropTypes.string,
  contentOk: PropTypes.string,
  contentNo: PropTypes.string,
  header: PropTypes.string,
  loading: PropTypes.bool,
  isChooseSendEmail: PropTypes.bool,
  handleSendManualEmail: PropTypes.func,
};
export default ModalConfirmationAlert;
