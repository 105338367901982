import React, { Component } from 'react';
import { Table, Checkbox, Pagination, Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { datasetPagination } from '../../../../../scripts/pagination';
import FilterData from '../../../../../scripts/filterDataTable';
//import { indexPagination } from '../../genericFunction/moment';
class tableModalAccomodationProfile extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
      pageLength: 5,
    };
  }
  static propTypes = {
    data: PropTypes.array,
  };
  handleChange = (e, { name, value }) => this.setState({ [name]: value });
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  render() {
    let filterData = FilterData(
      this.props.listData,
      'Name',
      this.state.searchInput
    );
    const { searchInput } = this.state;
    let pagedData = datasetPagination(filterData, this.state.pageLength);
    let listData = pagedData[this.state.activePage - 1];
    return (
      <div>
        <Input
          icon="search"
          placeholder="search"
          name="searchInput"
          value={searchInput}
          onChange={this.handleChange}
        />
        <Table basic="very" selectable fixed>
          <Table.Body>
            {listData &&
              listData.map((data, idx) => {
                return (
                  <Table.Row key={idx}>
                    <Table.Cell style={{ width: '5em' }}>
                      <Checkbox
                        id={data.Name}
                        name={data.Id}
                        //key={indexPagination(idx, activePage, pageLength)}
                        onChange={this.props.onChangeItem}
                        checked={data.Status}
                        style={{ marginLeft: '1.5em' }}
                      />
                    </Table.Cell>
                    <Table.Cell>{data.Name}</Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
          {pagedData &&
            pagedData.length > 1 && (
              <Table.Footer>
                <Pagination
                  totalPages={pagedData.length}
                  onPageChange={this.pageChange}
                  activePage={this.state.activePage}
                />
              </Table.Footer>
            )}
        </Table>
      </div>
    );
  }
}

tableModalAccomodationProfile.propTypes = {
  listData: PropTypes.array,
  onChangeItem: PropTypes.func,
};
export default tableModalAccomodationProfile;
