import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Table } from 'semantic-ui-react';
import ButtonClose from '../../../../../../components/button/buttonCloseModal';
import Loader from '../../../../../../components/loader/loaderInModal';
import { formatCurrency } from '../../../../../../components/genericFunction/convertFormat';
class ModalListTourOperator extends Component {
  handlePickTourOperator = (e, { selectedTourOperator }) => {
    let tourOperator = selectedTourOperator
      ? selectedTourOperator.TourOperatorProfile
      : null;
    this.props.setTourOperator(tourOperator);
    this.props.handleClose();
  };

  render() {
    let {
      openModal,
      handleClose,
      loadingListTourOperator,
      listTourOperator,
      isReadyFixedPrice,
    } = this.props;
    return (
      <Modal inverted open={openModal} onClose={handleClose} size="small">
        <Modal.Header>
          Tour Operator <ButtonClose onClose={handleClose} />
        </Modal.Header>
        <Modal.Content>
          <Loader loading={loadingListTourOperator} />
          <Table basic="very" selectable fixed>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Telephone</Table.HeaderCell>
                {isReadyFixedPrice || (
                  <Table.HeaderCell>Price Offer</Table.HeaderCell>
                )}
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.listTourOperator.map((item, idx) => {
                return (
                  <Table.Row key={idx}>
                    <Table.Cell>{item.TourProfileName}</Table.Cell>
                    <Table.Cell>
                      {' '}
                      {item.TourOperatorProfile
                        ? item.TourOperatorProfile.Email
                        : '-'}
                    </Table.Cell>
                    <Table.Cell>
                      {item.TourOperatorProfile
                        ? item.TourOperatorProfile.Telephone
                        : null}
                    </Table.Cell>
                    {isReadyFixedPrice || (
                      <Table.Cell>
                        {`${item.CurrencyId} ${formatCurrency(
                          item.TotalPrice
                        )}`}
                      </Table.Cell>
                    )}

                    <Table.Cell>
                      <Button
                        content="Pick"
                        primary
                        onClick={this.handlePickTourOperator}
                        listTourOperator={listTourOperator}
                        selectedTourOperator={item}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Modal.Content>
      </Modal>
    );
  }
}
ModalListTourOperator.propTypes = {
  openModal: PropTypes.bool,
  handleClose: PropTypes.func,
  loadingListTourOperator: PropTypes.bool,
  listTourOperator: PropTypes.array,
  setTourOperator: PropTypes.func,
  isReadyFixedPrice: PropTypes.bool,
};

export default ModalListTourOperator;
