import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Segment, Grid, Header, Icon } from 'semantic-ui-react';
import CollapseButton from '../../../../../components/button/collapseButton';
import styles from './styles';
import { Collapse } from 'react-collapse';
import Loader from '../../../../../components/loader/loader';
import TableSpecialAdjustment from './components/TableSpecialAdjustment';

const SpecialAdjustment = props => {
  // ========================= initial =========================
  const { loading } = props;
  const [openCollapse, setOpenCollapse] = useState(false);

  const getAdditionalItemTypeList = useSelector(
    state => state.AdditionalItemStore.getAdditionalItemTypeList
  );

  // ========================= handle =========================
  const handleCollapse = () => setOpenCollapse(!openCollapse);

  return (
    <Segment>
      <div style={{ margin: '1em' }}>
        <Grid verticalAlign="middle">
          <Grid.Row style={styles.noPaddingBottom}>
            <Grid.Column width={14}>
              <Header as="h3">
                <Icon name="money" style={{ color: '#38af95' }} />
                Special Adjustment
              </Header>
            </Grid.Column>
            <Grid.Column width={2} textAlign="left">
              <CollapseButton
                isCollapse={openCollapse}
                handleCollapse={handleCollapse}
                name="openCollapse"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Collapse isOpened={openCollapse} style={{ margin: '0px' }}>
          <Grid>
            {loading ? (
              <Loader active={true} inline={true} />
            ) : (
              <Grid.Row>
                <Grid.Column width={16}>
                  <TableSpecialAdjustment
                    listData={props.specialAdjusments}
                    currencies={props.currencies}
                    handleSpecialAdjustment={props.handleSpecialAdjustment}
                    CurrencyId={props.CurrencyId}
                    currentPackage={props.currentPackage}
                    statusPackage={props.statusPackage}
                    additionalItemTypeList={getAdditionalItemTypeList}
                    typePackage={props.typePackage}
                    idTransaction={props.currentPackage.id}
                    isSales={props.isSales}
                    isAdministrator={props.isAdministrator}
                    isOperation={props.isOperation}
                    isAcceptedQuotation={props.isAcceptedQuotation}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Collapse>
      </div>
    </Segment>
  );
};

SpecialAdjustment.propTypes = {
  loading: PropTypes.bool,
  typePackage: PropTypes.string,
  getInvoiceByCompanyCodeStatus: PropTypes.string,
  getInvoiceByCompanyCode: PropTypes.array,
  transactionId: PropTypes.number,
  tourTransactionsCommission: PropTypes.array,
  statusPackage: PropTypes.string,
  specialAdjusments: PropTypes.array,
  currencies: PropTypes.array,
  handleSpecialAdjustment: PropTypes.func,
  CurrencyId: PropTypes.string,
  currentPackage: PropTypes.object,
  packageType: PropTypes.string,
  getAdditionalItemTypeList: PropTypes.array,
  changeSpecialAdjustment: PropTypes.func,
  isSales: PropTypes.bool,
  isAdministrator: PropTypes.bool,
  isOperation: PropTypes.bool,
  isAcceptedQuotation: PropTypes.bool,
};

export default SpecialAdjustment;
