import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ActionButton from '../../../components/common/buttonEditAndDelete';
const TablePlaceList = props => (
  <Table basic="very" selectable fixed>
    <Table.Header>
      <Table.Row>
        {props.headerTable.map((headTableContent, index) => {
          return (
            <Table.HeaderCell key={index}>{headTableContent}</Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {props.listData ? (
        props.listData.map((place, idx) => {
          return (
            <Table.Row key={idx}>
              <Table.Cell component="th" scope="row" width={1}>
                {place.Id}
              </Table.Cell>
              <Table.Cell>{place.Name}</Table.Cell>
              <Table.Cell>{place.Code}</Table.Cell>
              <Table.Cell>{place.CityId}</Table.Cell>
              <Table.Cell>{place.PlaceType}</Table.Cell>
              <ActionButton
                id={place.Id}
                handleClickEdit={props.butttonEdit}
                handleClickDelete={props.buttonDelete}
                name1={place.Name}
                name2={place.Name}
                content1="Edit"
                content2="Delete"
              />
            </Table.Row>
          );
        })
      ) : (
        <Table.Row>
          <Table.Cell colSpan="6" textAlign="center">
            No Data
          </Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  </Table>
);

TablePlaceList.propTypes = {
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  actionButton: PropTypes.func,
  butttonEdit: PropTypes.func,
  buttonDelete: PropTypes.func,
};
export default TablePlaceList;
