import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
const loader = props => {
  return props.inline ? (
    <Loader active={props.active} inline="centered" />
  ) : (
    <Dimmer
      active={props.active}
      inverted={props.inverted ? props.inverted : false}
      page={props.page ? props.page : false}
    >
      <Loader indeterminate>{props.description}</Loader>
    </Dimmer>
  );
};

loader.propTypes = {
  active: PropTypes.bool,
  description: PropTypes.string,
  page: PropTypes.bool,
  inverted: PropTypes.bool,
  inline: PropTypes.bool,
};
export default loader;
