import React from 'react';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const ButtonGroupSupplier = props => {
  return (
    <div style={props.style}>
      {props.isSupplierList && (
        <Button
          icon="file outline"
          positive
          floated="right"
          onClick={props.buttonExportItem}
          disabled={props.disablePage}
          content="Export Item"
        />
      )}

      <Button
        icon="file outline"
        positive
        floated="right"
        onClick={props.butttonExport}
        disabled={props.disablePage}
        content={props.isSupplierList ? 'Export Profile' : 'Export'}
      >
        {/* <Icon name="file outline" />  */}
      </Button>
      <Button
        icon="download"
        primary
        floated="right"
        onClick={props.buttonDownloadTemplate}
        disabled={props.disablePage}
        content="Download Template"
      />
      <Button
        icon="upload"
        primary
        floated="right"
        onClick={props.buttonUploadData}
        disabled={props.disablePage}
        content="Upload File"
      />
      <Button
        icon="add"
        positive
        floated="right"
        onClick={props.buttonNew}
        disabled={props.disablePage}
        content="Add New"
      />
    </div>
  );
};
ButtonGroupSupplier.propTypes = {
  buttonNew: PropTypes.func,
  butttonExport: PropTypes.func,
  buttonUploadData: PropTypes.func,
  buttonDownloadTemplate: PropTypes.func,
  style: PropTypes.object,
  disablePage: PropTypes.bool,
  buttonExportItem: PropTypes.func,
  isSupplierList: PropTypes.bool,
};

export default ButtonGroupSupplier;
