import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form, Header, Image } from 'semantic-ui-react';
const ModalCompanyRegistration = props => {
  return (
    <Modal
      dimmer="blurring"
      inverted
      open={props.open}
      onClose={props.handleCloseModalDetail}
      closeIcon
    >
      <Modal.Header>Company Registration Detail </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group widths={2}>
            <Form.Field>
              <Header as="h4">Travel Agent Data</Header>
              <Form.Input
                type="text"
                label="Company Name"
                value={props.data ? props.data.CompanyDetail.Name : ''}
                readOnly
              />
              <Form.Group widths={2} style={{ marginTop: '10px' }}>
                <Form.Input
                  type="text"
                  label="Company Code"
                  value={props.data ? props.data.CompanyDetail.Code : ''}
                  readOnly
                />
                <Form.Input
                  type="text"
                  label="Company Telephone"
                  value={props.data ? props.data.CompanyDetail.Telephone : ''}
                  readOnly
                />
              </Form.Group>
              <Form.Group widths={2}>
                <Form.Input
                  type="text"
                  label="Country"
                  value={
                    props.data ? props.data.CompanyDetail.Country.Name : ''
                  }
                  readOnly
                />
                <Form.Input
                  type="text"
                  label="Company Email"
                  value={props.data ? props.data.CompanyDetail.Email : '-'}
                  readOnly
                />
              </Form.Group>
              <Form.Input
                type="text"
                label="Company Address"
                value={props.data ? props.data.CompanyDetail.Address : ''}
                readOnly
              />
            </Form.Field>
            <Form.Field>
              <Header as="h4">Travel Agent Data</Header>
              {/* -------------------- */}
              <Form.Input
                type="text"
                label="Trade License"
                value={
                  props.data ? props.data.CompanyDetail.TradelicenseNbr : ''
                }
                readOnly
              />
              <Form.Group widths={2} style={{ marginTop: '10px' }}>
                <Form.Input
                  type="text"
                  label="Number of Employees"
                  value={
                    props.data
                      ? props.data.CompanyDetail.EmployeeRange.Value
                      : ''
                  }
                  readOnly
                />
                <Form.Input
                  type="text"
                  label="Total Sub Agents / Branch"
                  value={
                    props.data ? props.data.CompanyDetail.BranchRange.Value : ''
                  }
                  readOnly
                />
              </Form.Group>
              <Form.Group widths={2} style={{ marginTop: '10px' }}>
                <Form.Input
                  type="text"
                  label="Based Currency"
                  value={props.data ? props.data.CompanyDetail.Currency : ''}
                  readOnly
                />
                <Form.Input
                  type="text"
                  label="Last Year Revenue"
                  value={
                    props.data
                      ? props.data.CompanyDetail.RevenueRange.Value
                      : ''
                  }
                  readOnly
                />
              </Form.Group>
              <Form.Group widths={2} style={{ marginTop: '10px' }}>
                <Form.Input
                  type="text"
                  label="IATA Number"
                  value={props.data ? props.data.CompanyDetail.IATANbr : ''}
                  readOnly
                />
                <Form.Input
                  type="text"
                  label="Association Number"
                  value={
                    props.data ? props.data.CompanyDetail.AssociationNumber : ''
                  }
                  readOnly
                />
              </Form.Group>
            </Form.Field>
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Field>
              <Header as="h5">User Data</Header>
              <Form.Input
                type="text"
                label="Username"
                value={props.data ? props.data.Username : ''}
                readOnly
              />
              <Form.Group widths={2} style={{ marginTop: '10px' }}>
                <Form.Input
                  type="text"
                  label="First Name"
                  value={props.data ? props.data.FirstName : ''}
                  readOnly
                />
                <Form.Input
                  type="text"
                  label="Last Name"
                  value={props.data ? props.data.LastName : ''}
                  readOnly
                />
              </Form.Group>
              <Form.Group widths={2} style={{ marginTop: '10px' }}>
                <Form.Input
                  type="text"
                  label="Email"
                  value={props.data ? props.data.Email : ''}
                  readOnly
                />
                <Form.Input
                  type="text"
                  label="User Phone Number"
                  value={props.data ? props.data.Telephone : ''}
                  readOnly
                />
              </Form.Group>
            </Form.Field>
            <Form.Field>
              <Header as="h5">Trade License Image</Header>
              <Image
                src={
                  props.data
                    ? props.data.CompanyDetail.ImageUrl
                      ? props.data.CompanyDetail.ImageUrl
                      : require('../../../../assets/Icons/no_image.jpg')
                    : null
                }
                size="medium"
              />
            </Form.Field>
          </Form.Group>
        </Form>
        <Modal.Actions style={{ textAlign: 'right' }}>
          <Button
            name={1}
            value={props.data ? props.data.UserId : ''}
            positive
            onClick={props.actionCompanyRegistration}
            content="Approve"
          />
          <Button
            name={2}
            value={props.data ? props.data.UserId : ''}
            negative
            onClick={props.actionCompanyRegistration}
            content="Reject"
          />
          <Button
            style={{ marginLeft: '5px' }}
            onClick={props.handleCloseModalDetail}
            content="Close"
            primary
          />
        </Modal.Actions>
      </Modal.Content>
    </Modal>
  );
};

ModalCompanyRegistration.propTypes = {
  open: PropTypes.bool,
  handleModalDetail: PropTypes.func,
  data: PropTypes.object,
  actionCompanyRegistration: PropTypes.func,
  handleCloseModalDetail: PropTypes.func,
};
export default ModalCompanyRegistration;
