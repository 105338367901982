import React, { useState, useEffect } from 'react'; //useEffect
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SegmentTransactionDetailForFinance from './components/SegmentTransactionDetailForFinance';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import getTransactionByIdApi from '../../../api/getTransactionByIdApi';
import {
  getInvoiceAgentApi,
  //getInvoiceCustomerApi,
} from '../../../api/tourTransaction/getInvoiceCustomerAndAgentApi';
import useApiCallForDetail from './hooks/useApiCall';
import {
  getPayableSupplemetItemAction,
  getSpecialAdjusmentByTransactionIdAction,
  getTourTransactioBillsAction,
  getTransactionHistoryByTransaction,
} from '../../../actions/tourTransactionAction';
import ModalPaymentTransaction from '../../shared/components/modal/ModalPaymentTransaction';
// import { getUserRoleByBearerAction } from '../../../actions/userAccessAction';
// import { checkUser } from '../../../scripts/authorization';

const TransactionDetailForFinance = props => {
  //=======================initial=======================
  const [changePage, setChangePage] = useState({
    isChangePage: false,
    link: '',
  });
  const [openPay, setOpenPay] = useState(false);
  const [historyDetailLoading, historyDetailData] = useApiCallForDetail(
    getTransactionByIdApi,
    props.match.params.Id
  );
  //========== End initial state =========
  // ========== Action ===============
  const dispatch = useDispatch();
  const getPayableSupplemetItem = param =>
    dispatch(getPayableSupplemetItemAction(param));
  const getSpecialAdjusmentByTransactionId = param =>
    dispatch(getSpecialAdjusmentByTransactionIdAction(param));
  const getTourTransactionBills = param =>
    dispatch(getTourTransactioBillsAction(param));
  const getTransactionHistoryByTransactionAction = param =>
    dispatch(getTransactionHistoryByTransaction(param));
  // const getUserRoleByBearer = param =>
  //   dispatch(getUserRoleByBearerAction(param));
  // ========== End action ===========
  // ========== Reducer ========
  const getPayableSupplemetItemReducer = useSelector(
    state => state.TourTransactionStore.getPayableSupplemetItem
  );
  const getSpecialAdjusmentByTransactionIdReducer = useSelector(
    state => state.TourTransactionStore.getSpecialAdjusmentByTransactionId
  );
  const getTourTransactionBillsReducer = useSelector(
    state => state.TourTransactionStore.getTourTransactionBills
  );
  const loadingTourTransactionReducer = useSelector(
    state => state.TourTransactionStore.loading
  );
  const listTransactionHistoryByTransaction = useSelector(
    state => state.TourTransactionStore.listTransactionHistoryByTransaction
  );
  // const getUserRoleByBearerReducer = useSelector(
  //   state => state.UserAccessStore.getUserRoleByBearer
  // );
  // End reducer

  const handleDownloadFile = (dataUrl, filename) => {
    let link = document.createElement('a');
    link.download = filename;
    link.href = dataUrl;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadInvoice = () => {
    getInvoiceAgentApi(props.match.params.Id).then(result => {
      handleDownloadFile(result.data, historyDetailData.BookingDetailSum.Title);
    });
  };
  useEffect(() => {
    getPayableSupplemetItem(props.match.params.Id);
    getSpecialAdjusmentByTransactionId(props.match.params.Id);
    getTourTransactionBills(props.match.params.Id);
    getTransactionHistoryByTransactionAction(props.match.params.Id);
    // getUserRoleByBearer().then(() => {
    //   let authorization = checkUser(props.userRoles.Roles);
    //   this.setState({ ...this.state, isFinance: authorization.isFinance });
    // });
    // eslint-disable-next-line
  }, []);

  const handleOpenModalPay = () => {
    setOpenPay(true);
  };
  const handleCloseModalPayment = () => {
    setOpenPay(false);
  };
  const handleReloadPage = () => {
    window.location.reload();
  };
  const handleBack = () => {
    setChangePage({
      isChangePage: true,
      link: `/tour-transaction`,
    });
  };
  if (changePage.isChangePage) {
    return <Redirect to={changePage.link} />;
  } else
    return (
      <div>
        <SegmentTransactionDetailForFinance
          contentDetail={historyDetailData}
          loading={historyDetailLoading || loadingTourTransactionReducer}
          handleOpenModalPay={handleOpenModalPay}
          handleBack={handleBack}
          getSpecialAdjusmentByTransactionId={
            getSpecialAdjusmentByTransactionIdReducer
          }
          getPayableSupplemetItem={getPayableSupplemetItemReducer}
          getTourTransactionBills={getTourTransactionBillsReducer}
          listHistoryTransaction={listTransactionHistoryByTransaction}
          handleClickDownloadInvoice={handleDownloadInvoice}
        />
        <ModalPaymentTransaction
          open={openPay}
          handleClose={handleCloseModalPayment}
          reloadPage={handleReloadPage}
          transactionId={props.match.params.Id}
        />
      </div>
    );
};

TransactionDetailForFinance.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.string,
  }),
  userRoles: PropTypes.object,
};

export default withRouter(React.memo(TransactionDetailForFinance));
