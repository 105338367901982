import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Form } from 'semantic-ui-react';
import Input from '../../../components/input/inputWithLabel';
import InputDate from '../../../components/input/inputDate';
import { getTourOperatorProfileByCodeAction } from '../../../actions/packageAction';
import options from '../../../scripts/convertOption';
import { getMasterReferenceAction } from '../../shared/actions/uploadMasterDataReferenceAction';
import { getCompaniesByType } from '../../../actions/companyAction';
import ModalAlert from '../../shared/components/modal/ModalAlert';
import { diffDate, convertDateFormat } from '../../../scripts/moment';
import ModalSupplier from './ModalSupplier';
import {
  TRANSPORTATION,
  ATTRACTION,
  RESTAURANT,
  ACCOMMODATION,
} from '../scripts/supplier';

const GeneralInformationResourceDetail = props => {
  const [modalAlert, setModalAlert] = useState({
    isOpen: false,
    header: '',
    message: '',
  });
  const [companies, setCompanies] = useState([]);
  const [modalSupplier, setModalSupplier] = useState({ isOpen: false });
  // ========== end state ============
  // ========== action ===============
  const dispatch = useDispatch();
  const getTourOperatorProfileByCode = param =>
    dispatch(getTourOperatorProfileByCodeAction(param));
  const getCompaniesByTypeAction = param => dispatch(getCompaniesByType(param));
  const getMasterReference = param => dispatch(getMasterReferenceAction(param));
  // ========== end action ===========
  // ========== reducer ==============
  const tourOperatorListByCode = useSelector(state =>
    state.PackageStore.tourOperatorListByCode.map(e =>
      options(e.Id, e.Id, e.Name)
    )
  );
  const loadingGetTourOperator = useSelector(
    state => state.PackageStore.loading
  );
  const loadingGetCurrencies = useSelector(
    state => state.MasterDataReferenceStore.loading
  );
  const currencies = useSelector(state => {
    let data = state.MasterDataReferenceStore.getMasterReferenceList;
    return data ? data.map(e => options(e.Id, e.Id, e.Description)) : [];
  });

  const loadingGetCompanies = useSelector(
    state => state.CompanyStore.loadingGetCompanies
  );
  const companiesReducer = useSelector(state =>
    state.CompanyStore.companies.map(e => options(e.Code, e.Code, e.Name))
  );
  const loadingGetResourceTypeEnum = useSelector(
    state => state.ResourceBooking.loadingGetResourceTypeEnum
  );
  let listResourceTypeEnum = useSelector(state =>
    state.ResourceBooking.listResourceTypeEnum.map(e =>
      options(`${e.Value}-${e.Text}`, `${e.Value}-${e.Text}`, e.Text)
    )
  );
  // ========== end reducer ==========
  useEffect(() => {
    getCompaniesByTypeAction('Partner');
    getMasterReference('Currencies');
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (companies.length === 0 && companiesReducer.length !== 0) {
      companiesReducer.unshift({
        key: 0,
        value: 0,
        text: 'Touress',
      });
      setCompanies(companiesReducer);
    }
    // eslint-disable-next-line
  }, [companiesReducer]);
  const openModalAlert = (header, message) => {
    setModalAlert({
      isOpen: true,
      header: header,
      message: message,
    });
  };
  const closeModalAlert = () => {
    setModalAlert({
      isOpen: false,
      header: '',
      message: '',
    });
  };

  const handleResource = async (e, { name, value, readOnly }) => {
    if (!readOnly) {
      if (name === 'UnitCost') {
        value = value ? parseInt(value.replace(/\D/g, ''), 10) : 0;
      } else if (name === 'CompanyId') {
        if (value === 0) {
          props.updateResource({ ...resource, TourOperatorProfileId: '' });
        } else {
          getTourOperatorProfileByCode(value).catch(() =>
            openModalAlert('Failed', 'Failed get tour operator')
          );
        }
      }
      if (name === 'ResourceType') {
        if (value.split('-')[0] === 'Additional Service') {
          let typeValue = value.split('-')[0];
          let typeText = value.split('-')[1];
          await props.updateResource({
            ...resource,
            [name]: value,
            ResourceName: `${typeValue} Type - ${typeText}`,
          });
        } else {
          props.updateResource({
            ...resource,
            [name]: value,
            ResourceName: '',
            Description: '',
          });
        }
      } else props.updateResource({ ...resource, [name]: value });
    }
  };
  const handleClickResourceName = e => {
    if (
      !e.target.readOnly &&
      [TRANSPORTATION, ACCOMMODATION, ATTRACTION, RESTAURANT].indexOf(
        resource.ResourceType.split('-')[0]
      ) !== -1
    ) {
      setModalSupplier({ isOpen: true });
    }
  };
  // complete date berisi data waktu yang lengkap. untuk keperluan hit api
  const handleDate = (date, name) => {
    props.updateResource({
      ...resource,
      [name]: date,
      EndDate:
        name === 'Date' && diffDate(date, resource.EndDate, 'd') < 0
          ? date
          : name === 'EndDate'
          ? date
          : resource.EndDate,
      CompleteDate:
        name === 'Date'
          ? convertDateFormat(date, 'YYYY-MM-DDThh:mm:ss')
          : resource.CompleteDate,
      CompleteEndDate:
        name === 'EndDate'
          ? convertDateFormat(date, 'YYYY-MM-DDThh:mm:ss')
          : resource.CompleteDate,
    });
  };
  // ====================== handle modal supplier =======================
  const closeModalSupplier = () => {
    setModalSupplier({ isOpen: false });
  };
  // ====================== handle modal supplier =======================
  const filterListResourceType = () => {
    listResourceTypeEnum =
      mode !== 'Add'
        ? listResourceTypeEnum.filter(e => {
            return (
              [
                ACCOMMODATION,
                ATTRACTION,
                RESTAURANT,
                TRANSPORTATION,
                'Others',
                'Additional Service',
              ].indexOf(e.value.split('-')[0]) !== -1
            );
          })
        : listResourceTypeEnum.filter(
            e =>
              ['Others', 'Additional Service'].indexOf(
                e.value.split('-')[0]
              ) !== -1
          );
    return listResourceTypeEnum;
  };
  let {
    resource,
    errors,
    mode,
    isDisabled,
    dataStartDateEndDate,
    handleChooseSupplier,
  } = props;
  let isReissue = mode === 'ReIssue';
  let resourceType = resource.ResourceType.split('-')[0];
  resourceType =
    resourceType === 'Additional Service'
      ? resource.ResourceType
      : resourceType;
  listResourceTypeEnum = filterListResourceType();
  return (
    <Grid.Row>
      <Grid.Column width={8}>
        <Form>
          <Input
            type="select"
            name="ResourceType"
            label="Type"
            value={resource.ResourceType}
            readonly={!resource.IsAdjutment || isDisabled || isReissue}
            disabled={mode === 'Detail'}
            loading={loadingGetResourceTypeEnum}
            options={listResourceTypeEnum}
            validate={errors}
            handleChange={handleResource}
          />
          <Input
            type="text"
            label="Resource Name"
            placeholder="resource name"
            value={resource.ResourceName}
            name="ResourceName"
            handleChange={handleResource}
            validate={errors}
            readonly={
              resourceType === 'Additional Service' ||
              (mode === 'ReIssue' ? false : !resource.IsAdjutment || isDisabled)
            }
            disabled={mode === 'Detail'}
            handleClick={handleClickResourceName}
          />
          <Input
            type="textArea"
            label="Description"
            placeholder="Description"
            value={resource.Description}
            name="Description"
            handleChange={handleResource}
            validate={errors}
            readonly={
              !resource.IsAdjutment ||
              isDisabled ||
              [TRANSPORTATION, ACCOMMODATION, ATTRACTION, RESTAURANT].indexOf(
                resource.ResourceType.split('-')[0]
              ) !== -1
            }
            disabled={mode === 'Detail'}
          />
          <Form.Group widths="equal">
            <Input
              type="select"
              label="Currency"
              placeholder="Currency"
              value={resource.Currency}
              options={currencies}
              name="Currency"
              handleChange={handleResource}
              validate={errors}
              loading={loadingGetCurrencies}
              readonly={
                mode === 'ReIssue' ? false : !resource.IsAdjutment || isDisabled
              }
              disabled={mode === 'Detail'}
            />
            <Input
              type="text"
              label="Estimated price per qty"
              placeholder="Estimated price per qty"
              value={resource.UnitCost.toLocaleString('id')}
              name="UnitCost"
              handleChange={handleResource}
              validate={errors}
              readonly={
                mode === 'ReIssue' ? false : !resource.IsAdjutment || isDisabled
              }
              disabled={mode === 'Detail'}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <InputDate
                label="Due Date"
                name="DueDate"
                value={resource.DueDate}
                setDate={handleDate}
                labelStyle={{
                  display: 'block',
                  fontWeight: 'bold',
                  marginBottom: '4px',
                }}
                readonly={!resource.IsAdjutment || isDisabled}
                disabled={mode === 'Detail'}
              />
            </Form.Field>
            <Input
              type="number"
              label="Qty"
              placeholder="Qty"
              value={resource.Qty}
              name="Qty"
              handleChange={handleResource}
              validate={errors}
              readonly={!resource.IsAdjutment || isDisabled || isReissue}
              disabled={mode === 'Detail'}
            />
          </Form.Group>
          <Form.Group widths="2">
            <Form.Field>
              <InputDate
                label="Start Date"
                name="Date"
                value={resource.Date}
                setDate={handleDate}
                labelStyle={{
                  display: 'block',
                  fontWeight: 'bold',
                  marginBottom: '4px',
                }}
                minDate={
                  resourceType === 'Others' ||
                  resourceType === 'Additional Service'
                    ? ''
                    : dataStartDateEndDate
                    ? dataStartDateEndDate.startDate
                    : ''
                }
                maxDate={
                  dataStartDateEndDate ? dataStartDateEndDate.endDate : ''
                }
                readonly={!resource.IsAdjutment || isDisabled || isReissue}
                disabled={mode === 'Detail'}
              />
            </Form.Field>
            <Form.Field>
              <InputDate
                label="End Date"
                name="EndDate"
                value={resource.EndDate}
                setDate={handleDate}
                labelStyle={{
                  display: 'block',
                  fontWeight: 'bold',
                  marginBottom: '4px',
                }}
                minDate={
                  resourceType === 'Others' ||
                  resourceType === 'Additional Service'
                    ? ''
                    : resource.Date || ''
                }
                maxDate={
                  dataStartDateEndDate ? dataStartDateEndDate.endDate : ''
                }
                readonly={!resource.IsAdjutment || isDisabled || isReissue}
                disabled={mode === 'Detail'}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Input
              type="select"
              label="Ownership"
              placeholder="Touress"
              value={resource.CompanyId}
              name="CompanyId"
              handleChange={handleResource}
              validate={errors}
              options={companies}
              loading={loadingGetCompanies}
              readonly={!resource.IsAdjutment || isDisabled}
              disabled={mode === 'Detail'}
            />
            <Input
              type="select"
              label="Tour Operator"
              placeholder="Tour Operator"
              value={resource.TourOperatorProfileId}
              name="TourOperatorProfileId"
              handleChange={handleResource}
              validate={errors}
              options={tourOperatorListByCode}
              disabled={resource.CompanyId === 0 || mode === 'Detail'}
              loading={loadingGetTourOperator}
              readonly={isDisabled || !resource.IsAdjutment}
            />
          </Form.Group>
        </Form>
      </Grid.Column>
      <Grid.Column width={8}>
        {mode !== 'Add' ? (
          <Form>
            <Input
              type="text"
              label="Resource Status"
              placeholder="resource status"
              value={resource.ResourceStatus}
              readonly
              disabled={mode === 'Detail'}
            />
            <Input
              type="text"
              label="Payment Status"
              placeholder="Payment status"
              value={resource.PaymentStatus}
              readonly
              disabled={mode === 'Detail'}
            />
            <Input
              type="text"
              label="Actual Cost"
              placeholder="Payment status"
              value={
                resource.ActualCost
                  ? resource.ActualCost.toLocaleString('id')
                  : 0
              }
              readonly
              disabled={mode === 'Detail'}
            />
            <Input
              type="number"
              label="Qty Ready to Use"
              placeholder="Qty Ready to Use"
              value={resource.QtyReadyToUse}
              name="QtyReadyToUse"
              handleChange={handleResource}
              validate={errors}
              disabled={mode === 'Detail'}
              readonly={!isDisabled || mode === 'Canceled'}
            />
          </Form>
        ) : null}
      </Grid.Column>
      <ModalAlert
        openModal={modalAlert.isOpen}
        handleClose={closeModalAlert}
        header={modalAlert.header}
        content={modalAlert.message}
      />
      <ModalSupplier
        openModal={modalSupplier.isOpen}
        handleClose={closeModalSupplier}
        resource={resource}
        handleChooseSupplier={handleChooseSupplier}
      />
    </Grid.Row>
  );
};

GeneralInformationResourceDetail.propTypes = {
  resource: PropTypes.object,
  updateResource: PropTypes.func,
  errors: PropTypes.object,
  mode: PropTypes.string,
  isDisabled: PropTypes.bool,
  dataStartDateEndDate: PropTypes.object,
  handleChooseSupplier: PropTypes.func,
};

export default GeneralInformationResourceDetail;
