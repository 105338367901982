import { copyObject } from '../../../scripts/itineraryBuilder/dailyProgram';
import { dateNow, convertDateFormat } from './../../../scripts/moment';

let errors = {
  name: {
    error: false,
    message: '',
  },
  qty: {
    error: false,
    message: '',
  },
  pricePerQty: {
    error: false,
    message: '',
  },
  date: {
    error: false,
    message: '',
  },
  note: {
    error: false,
    message: '',
  },
  totalCost: {
    error: false,
    message: '',
  },
};
export const ERRORS_BOOKING_ITEM = {
  Qty: {
    error: false,
    message: '',
  },
  Note: {
    error: false,
    message: '',
  },
};
export const validationAddResource = resource => {
  let newErrors = copyObject(errors);
  let isError = false;
  if (resource.name === '') {
    isError = true;
    newErrors.name.error = true;
    newErrors.name.message = "Field can't be empty!";
  }
  if (resource.qty === 0) {
    isError = true;
    newErrors.qty.error = true;
    newErrors.qty.message = "Field can't be empty!";
  }
  if (resource.pricePerQty === 0) {
    isError = true;
    newErrors.pricePerQty.error = true;
    newErrors.pricePerQty.message = "Field can't be empty!";
  }
  if (resource.note === '') {
    isError = true;
    newErrors.note.error = true;
    newErrors.note.message = "Field can't be empty!";
  }
  if (resource.totalCost === '') {
    isError = true;
    newErrors.totalCost.error = true;
    newErrors.totalCost.message = "Field can't be empty!";
  }
  return { isError, errors: newErrors };
};

export const validationAddBookingItem = (
  bookingItem,
  listBookingItem,
  totalQty
) => {
  let newErrors = copyObject(ERRORS_BOOKING_ITEM);
  let qtyMax = getMaxQty(totalQty, listBookingItem, bookingItem);
  let isError = false;
  if (parseInt(bookingItem.Qty) === 0 || parseInt(bookingItem.Qty) < 0) {
    isError = true;
    newErrors.Qty.error = true;
    newErrors.Qty.message =
      parseInt(bookingItem.Qty) < 0
        ? 'Qty cannot be smaller than 0'
        : "Field can't be empty!";
  }
  if (qtyMax < parseInt(bookingItem.Qty, 10)) {
    isError = true;
    newErrors.Qty.error = true;
    newErrors.Qty.message = 'Qty cannot be more than ' + qtyMax;
  }
  return {
    isError,
    errors: newErrors,
  };
};

export const getMaxQty = (totalQty, listBookingItem, bookingItem) => {
  let totalQtyBookingItem = listBookingItem
    .filter(e => !e.IsDelete)
    .reduce((acc, e, idx) => {
      if (bookingItem && bookingItem.Idx !== idx)
        acc = acc + parseInt(e.Qty, 10);
      return acc;
    }, 0);
  return totalQty - totalQtyBookingItem;
};
export const totalQtyBookingItem = listBookingItem => {
  return listBookingItem
    .filter(e => !e.IsDelete)
    .reduce((acc, e) => {
      acc = acc + parseInt(e.Qty, 10);
      return acc;
    }, 0);
};

export const ERRORS_PAYMENT_REQUEST = {
  Value: {
    error: false,
    message: '',
  },
  DueDate: {
    error: false,
    message: '',
  },
  Note: {
    error: false,
    message: '',
  },
  Currency: {
    error: false,
    message: '',
  },
  Priority: {
    error: false,
    message: '',
  },
};
export const DEFAULT_PAYMENT_REQUEST = {
  Currency: '',
  Date: convertDateFormat(dateNow, 'YYYY-MM-DDThh:mm:ss'),
  DueDate: convertDateFormat(dateNow, 'YYYY-MM-DDThh:mm:ss'),
  Id: '',
  Note: '',
  PaymentStatus: 'UNPAID',
  Value: 50000,
};
export const validationPaymentReq = paymentReq => {
  let newErrors = copyObject(ERRORS_PAYMENT_REQUEST);
  let isError = false;
  if (parseInt(paymentReq.Value, 10) === 0) {
    isError = true;
    newErrors.Value.error = true;
    newErrors.Value.message = "Field can't be empty!";
  }
  if (paymentReq.Currency === '') {
    isError = true;
    newErrors.Currency.error = true;
    newErrors.Currency.message = "Field can't be empty!";
  }
  if (paymentReq.Priority === '') {
    isError = true;
    newErrors.Priority.error = true;
    newErrors.Priority.message = "Field can't be empty!";
  }
  return { isError, errors: newErrors };
};
