import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Menu } from 'semantic-ui-react';
import MenuItem from './menuItem';
import AccordionMenuLevel2 from './accordionMenuLevel2';
import Styles from './styles';

const accordionContent = props => {
  return (
    <Accordion.Content active={props.activeIndex === props.index} as="a">
      {props.menu.map((menu, index) => {
        return menu.menu ? (
          <AccordionMenuLevel2
            activeIndex={props.activeIndexLevel2}
            index={menu.index}
            handleClick={props.handleClickLevel2}
            title={menu.menu}
            menu={menu.submenu}
            dropdownStyle={Styles.iconDropdown}
          />
        ) : (
          <Menu.Item key={index} as="a">
            <MenuItem
              link={menu.link}
              icon={menu.icon}
              title={menu.title}
              handleClickConfirm={props.handleClickConfirm}
              name={menu.name}
            />
          </Menu.Item>
        );
      })}
    </Accordion.Content>
  );
};

accordionContent.propTypes = {
  menu: PropTypes.array,
  activeIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  handleClickConfirm: PropTypes.func,
  handleClick: PropTypes.func,
  activeIndexLevel2: PropTypes.number,
  handleClickLevel2: PropTypes.func,
};

export default accordionContent;
