import React, { Component } from 'react';
import { Table, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import Pagination from '../common/pagination';
import { getNumberFromTo } from '../../scripts/pagination';
class tableSupplierPrice extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
      pageLength: 3,
      status: false,
    };
  }
  static propTypes = {
    data: PropTypes.array,
  };
  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  setDefaultActivePage = () => {
    this.setState({
      status: false,
      activePage: 1,
    });
  };
  render() {
    const props = this.props;

    let { numbersFrom, numbersTo } = getNumberFromTo(
      props.listData,
      props.activePage,
      3
    );
    return (
      <Table striped style={{ width: '71em' }}>
        <Table.Header>
          <Table.Row>
            {props.headerTable.map((headTableContent, index) => {
              return (
                <Table.HeaderCell key={index} textAlign="center">
                  {headTableContent}
                </Table.HeaderCell>
              );
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {props.listData ? (
            props.listData.map((margin, idx) => {
              return (
                <Table.Row key={idx}>
                  <Table.Cell>{margin.Type}</Table.Cell>
                  <Table.Cell>{margin.Description}</Table.Cell>
                  <Table.Cell>{margin.MinPax}</Table.Cell>
                  <Table.Cell textAlign="right">
                    {margin.Currency +
                      '           ' +
                      new Intl.NumberFormat('de-DE').format(margin.Cost)}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(margin.StartDate).format('DD MMMM YYYY')}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(margin.EndDate).format('DD MMMM YYYY')}
                  </Table.Cell>
                  <Table.Cell>{margin.CutOffDay}</Table.Cell>
                  <Table.Cell>
                    <Button
                      name={idx}
                      positive
                      content="Edit"
                      onClick={props.handleEditPriceDetail}
                      id={margin.Id}
                    />
                    <Button
                      name={idx}
                      negative
                      content="Delete"
                      onClick={props.deletePrice}
                      value={margin.Id}
                      id={margin.Id}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell colSpan="8" textAlign="center">
                No Price Recorded
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
        <Pagination
          pagedData={props.pagedData}
          numbersTo={numbersTo}
          numbersFrom={numbersFrom}
          data={props.listData}
          pageChange={this.props.pageChange}
          activePage={this.props.activePage}
        />
      </Table>
    );
  }
}

tableSupplierPrice.propTypes = {
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  servicePriceType: PropTypes.array,
  currenciesList: PropTypes.array,
  handleChangePrice: PropTypes.func,
  handleChangePrices: PropTypes.func,
  deletePrice: PropTypes.func,
  placeholderType: PropTypes.string,
  handleEditPriceDetail: PropTypes.func,
  pagedData: PropTypes.array,
  activePage: PropTypes.string,
  pageChange: PropTypes.func,
};
export default tableSupplierPrice;
