import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../../../scripts/pagination';
import {
  HeaderTableAdditionalItem,
  HeaderTableAdditionalItemWithoutPrice,
} from '../../constant/HeaderTable';
import Loader from '../../../../../components/loader/loaderInModal';
import Pagination from '../../../../../components/common/pagination';
class TableAdditionalItem extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
      pageLength: 3,
    };
  }
  static propTypes = {
    data: PropTypes.array,
  };
  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  filterData = () => {
    const data = this.props.listData;
    return data.filter(item => item.Qty > 0);
  };
  render() {
    let pagedData = datasetPagination(this.filterData(), 3);
    let listData = pagedData[this.state.activePage - 1];
    const props = this.props;
    let { isHidePrice } = props;
    let { numbersFrom, numbersTo } = getNumberFromTo(
      listData,
      this.state.activePage,
      3
    );
    let headerTable = isHidePrice
      ? HeaderTableAdditionalItemWithoutPrice
      : HeaderTableAdditionalItem;
    return (
      <div>
        <Loader loading={props.loading} />
        <Table basic="very" selectable fixed>
          <Table.Header>
            <Table.Row>
              {headerTable.map((headTableContent, index) => {
                return (
                  <Table.HeaderCell key={index}>
                    {headTableContent}
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {listData ? (
              listData.map((data, idx) => {
                return (
                  data.Qty > 0 && (
                    <Table.Row key={idx}>
                      <Table.Cell>{data.Description}</Table.Cell>
                      <Table.Cell>{data.IsMandatory ? 'Yes' : 'No'}</Table.Cell>
                      <Table.Cell>
                        {data.IsInfantCount ? 'Yes' : 'No'}
                      </Table.Cell>
                      <Table.Cell>{data.Qty}</Table.Cell>
                      {isHidePrice || (
                        <Table.Cell>
                          {data.CurrencyId +
                            '           ' +
                            new Intl.NumberFormat('de-DE').format(
                              data.TotalCost
                            )}
                        </Table.Cell>
                      )}
                    </Table.Row>
                  )
                );
              })
            ) : (
              <Table.Row>
                <Table.Cell colSpan={headerTable.length} textAlign="center">
                  No Data
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        {/* {pagedData &&
            pagedData.length > 1 && (
              <Table.Footer> */}
        <Pagination
          // totalPages={pagedData.length}
          // onPageChange={this.pageChange}
          // activePage={this.state.activePage}
          pagedData={pagedData}
          numbersTo={numbersTo}
          numbersFrom={numbersFrom}
          data={props.listData}
          pageChange={this.pageChange}
          activePage={this.state.activePage}
        />
        {/* </Table.Footer>
            )} */}
      </div>
    );
  }
}

TableAdditionalItem.propTypes = {
  listData: PropTypes.array,
  handleChoose: PropTypes.func,
  loadingGetUserByCode: PropTypes.bool,
  isHidePrice: PropTypes.bool,
  loading: PropTypes.bool,
};
export default TableAdditionalItem;
