import { copyObject } from '../../../scripts/itineraryBuilder/itineraryBuilder';
const TRANSPORTATION = 'Transportation';
const ACCOMMODATION = 'Accommodation';
const ATTRACTION = 'Attraction';
const RESTAURANT = 'Restaurant';
const SUPPLIER = {
  CityId: '',
  From: '',
  To: '',
  Profile: null,
  Item: null,
  Service: null,
  Duration: null,
  showList: false,
};

const ERRORS_SUPPLIER = {
  CityId: {
    error: false,
    message: '',
  },
  From: {
    error: false,
    message: '',
  },
  To: {
    error: false,
    message: '',
  },
};

const validationSupplier = data => {
  let { supplier, supplierType } = data;
  let newErrors = copyObject(ERRORS_SUPPLIER);
  let isError = false;
  if (supplierType === TRANSPORTATION && supplier.From === '') {
    isError = true;
    newErrors.From.error = true;
    newErrors.From.message = "Field can't be empty!";
  }
  if (supplierType === TRANSPORTATION && supplier.To === '') {
    isError = true;
    newErrors.To.error = true;
    newErrors.To.message = "Field can't be empty!";
  }
  if (supplierType !== TRANSPORTATION && supplier.CityId === '') {
    isError = true;
    newErrors.CityId.error = true;
    newErrors.CityId.message = "Field can't be empty!";
  }
  return { isError, errors: newErrors };
};
const dataApiForGetAccommodation = (supplier, resource, allotment) => {
  return {
    cityId: supplier.CityId,
    ratingId: '',
    areaId: '',
    locationId: '',
    typeId: '',
    facilityId: '',
    promoOnly: false,
    requestedDate: resource.CompleteDate,
    useExtraBed: allotment.ExtraBedQty > 0,
    useChildExtraBed: allotment.ChildExtraBedQty > 0,
    useSharingBed: allotment.SharingBedQty > 0,
    sharingRoomPax: allotment.SharingRoomQty,
    singleRoomPax: allotment.SingleRoomQty,
    checkOutDate: resource.CompleteEndDate,
    bodyParam: demoPrice(allotment),
  };
};
const dataApiForGetAccommodationItem = (supplier, resource, allotment) => {
  return {
    profileId: supplier.Profile.Id,
    requestedDate: resource.CompleteDate,
    endDate: resource.CompleteEndDate,
    useExtraBed: allotment.ExtraBedQty > 0,
    useChildExtraBed: allotment.ChildExtraBedQty > 0,
    useSharingBed: allotment.SharingBedQty > 0,
    sharingRoomPax: allotment.SharingRoomQty,
    singleRoomPax: allotment.SingleRoomQty,
    bodyParam: demoPrice(allotment),
  };
};
const dataApiForGetAttraction = (supplier, resource, allotment) => {
  return {
    CityId: supplier.CityId,
    AttractionTypeId: '',
    RequestedDate: resource.CompleteDate,
    Pax: getTotalPaxFromAllotment(allotment),
    bodyParam: demoPrice(allotment),
  };
};
const dataApiForGetRestaurant = (supplier, resource, allotment) => {
  return {
    CityId: supplier.CityId,
    RatingIds: '',
    LocationIds: '',
    SpecializationId: '',
    MenuClassId: '',
    FacilityIds: '',
    RequestedDate: resource.CompleteDate,
    Pax: getTotalPaxFromAllotment(allotment),
    bodyParam: demoPrice(allotment),
  };
};
const dataApiForGetTransportation = (supplier, resource, allotment) => {
  return {
    fromCity: supplier.From,
    toCities: supplier.To,
    ratingId: '',
    seatTypeId: '',
    typeId: '',
    requestedDate: resource.CompleteDate,
    bodyParam: demoPrice(allotment),
  };
};
const getTotalPaxFromAllotment = allotment => {
  return (
    allotment.SharingRoomQty +
    allotment.ChildSharingRoomQty +
    allotment.SingleRoomQty +
    allotment.ChildSingleRoomQty +
    allotment.ExtraBedQty +
    allotment.ChildExtraBedQty +
    allotment.SharingBedQty +
    allotment.BabyCrib +
    allotment.NoBed
  );
};
const demoPrice = allotment => {
  return {
    RoomAllocation: allotment,
    AdditionalItems: [],
    Attractions: [],
    Transportations: [],
    Accommodations: [],
    Restaurants: [],
    TourGuides: [],
    AdditionalServices: [],
    Movements: [],
    FlightTickets: [],
    IsQuotation: false,
  };
};
export {
  SUPPLIER,
  ERRORS_SUPPLIER,
  TRANSPORTATION,
  ACCOMMODATION,
  ATTRACTION,
  RESTAURANT,
  validationSupplier,
  dataApiForGetAccommodation,
  dataApiForGetAccommodationItem,
  dataApiForGetAttraction,
  dataApiForGetRestaurant,
  dataApiForGetTransportation,
};
