import React from 'react';
import { Grid, Divider, Segment, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import GeneralInformation from './GeneralInformationCompanyProfile';
import ContactInformation from './ContactInformationCompanyProfile';
import SegmentImagesAndLogoCustomer from './ImagesCompanyProfile';
import TabCompanyProfile from './TabCompanyProfile';
import LoadingPage from '../../../../components/modal/loaderModal';
import HeaderTitle from '../../../../components/common/headerTitle';

const CustomerProfileDetail = props => (
  <Segment style={{ marginTop: '30px' }}>
    <HeaderTitle title={props.contentHeader} iconName="briefcase" />
    <Grid>
      <LoadingPage loading={props.loading} />
      <Grid.Row>
        <Grid.Column floated="right" width={3}>
          <Button negative onClick={props.buttonBack} content="Back" />
          <Button
            primary
            onClick={props.buttonEdit}
            content={props.contentButton}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <Grid columns={2} padded="vertically">
      <Grid.Column>
        <GeneralInformation
          contentDetail={props.contentDetail}
          currenciesListOptions={props.currenciesListOptions}
          handleChange={props.handleChange}
          disabled={props.disabled}
          errorInformation={props.errorInformation}
          accommodationOptions={props.accommodationOptions}
          loading={props.loadingAccommodation}
        />
        <ContactInformation
          disabled={props.disabled}
          handleChange={props.handleChange}
          errorInformation={props.errorInformation}
          contentDetail={props.contentDetail}
          countryListOptions={props.countryListOptions}
        />
      </Grid.Column>
      <Grid.Column>
        <SegmentImagesAndLogoCustomer
          contentDetail={props.contentDetail}
          contentCompanyLogo={props.contentDetail.CompanyLogo}
          handleChange={props.handleChange}
          disabled={props.disabled}
          handleChangeImage={props.handleChangeImage}
        />
      </Grid.Column>
    </Grid>
    <Divider />
    {!props.isNew && (
      <TabCompanyProfile
        data={props.employeeSupplierList}
        companyId={props.contentDetail.CompanyId}
        isNew={props.isNew}
        disabled={props.disabled}
      />
    )}
  </Segment>
);

CustomerProfileDetail.propTypes = {
  isNew: PropTypes.bool,
  employeeSupplierList: PropTypes.array,
  contentDetail: PropTypes.object,
  handleChange: PropTypes.func,
  handleChangeImage: PropTypes.func,
  buttonBack: PropTypes.func,
  buttonEdit: PropTypes.func,
  currenciesListOptions: PropTypes.array,
  disabled: PropTypes.bool,
  countryListOptions: PropTypes.array,
  contentButton: PropTypes.string,
  errorInformation: PropTypes.object,
  loading: PropTypes.bool,
  contentHeader: PropTypes.string,
  accommodationOptions: PropTypes.array,
  loadingAccommodation: PropTypes.bool,
};

export default CustomerProfileDetail;
