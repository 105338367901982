import React from 'react';
import { Table, TableCell, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ActionButton from '../../../shared/components/common/button/ActionButtonList';
import { Link } from 'react-router-dom';
import moment from 'moment';
const TableRestaurantList = props => (
  <Table basic="very" selectable>
    <Table.Header>
      <Table.Row>
        {props.headerTable.map((headTableContent, index) => {
          return (
            <Table.HeaderCell key={index}>{headTableContent}</Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {props.listData && props.listData.length > 0 ? (
        props.listData.map((data, idx) => {
          return (
            <Table.Row key={idx}>
              <Table.Cell>
                <Link to={`/Restaurant-Profile/Detail/${data.Id}`}>
                  {data.Name}
                </Link>
              </Table.Cell>
              <Table.Cell>{data.Address}</Table.Cell>
              {/* <Table.Cell>{data.Type}</Table.Cell> */}
              {/* <Table.Cell>
                {data.ProfileSpecializations && data.ProfileSpecializations.Text
                  ? data.ProfileSpecializations.Text
                  : ''}
              </Table.Cell> */}
              <Table.Cell>
                {data.RestaurantType ? data.RestaurantType.Name : ''}
              </Table.Cell>
              <Table.Cell>
                {data.SpecializationId ? data.SpecializationId : ''}
              </Table.Cell>

              <Table.Cell>
                {data.OperationStartTime === ''
                  ? ''
                  : moment(data.OperationStartTime).format('HH:mm')}
              </Table.Cell>
              <Table.Cell>
                {data.OperationEndTime === ''
                  ? ''
                  : moment(data.OperationEndTime).format('HH:mm')}
              </Table.Cell>
              {props.useInResourceBookingList ? (
                <Table.Cell>
                  {data.EstimatedTotalPrice.CurrencyId}{' '}
                  {data.EstimatedTotalPrice.Price.toLocaleString('id')}
                </Table.Cell>
              ) : null}
              {props.useInResourceBookingList ? (
                <TableCell>
                  <Button
                    content="See Menus"
                    primary
                    value={data}
                    onClick={props.handleChooseProfileForRBL}
                  />
                </TableCell>
              ) : (
                <ActionButton
                  id={data.Id}
                  handleClickEdit={props.butttonEdit}
                  handleClickDelete={props.buttonDelete}
                  name1="Edit"
                  name2="Delete"
                  content1="Edit"
                  content2="Delete"
                  value={data.Id}
                />
              )}
            </Table.Row>
          );
        })
      ) : (
        <Table.Row>
          <Table.Cell colSpan={props.headerTable.length} textAlign="center">
            No Data
          </Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  </Table>
);

TableRestaurantList.propTypes = {
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  butttonEdit: PropTypes.func,
  buttonDelete: PropTypes.func,
  useInResourceBookingList: PropTypes.bool,
  handleChooseProfileForRBL: PropTypes.func,
};
export default TableRestaurantList;
