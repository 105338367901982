export const RESET_STATUS_EXCURSION_PAGE = 'RESET_STATUS_EXCURSION_PAGE';

export const POST_EXCURSION_PROFILE = 'POST_EXCURSION_PROFILE';
export const POST_EXCURSION_PROFILE_PENDING = 'POST_EXCURSION_PROFILE_PENDING';
export const POST_EXCURSION_PROFILE_FULFILLED =
  'POST_EXCURSION_PROFILE_FULFILLED';
export const POST_EXCURSION_PROFILE_REJECTED =
  'POST_EXCURSION_PROFILE_REJECTED';

// SERVICE PRICE
export const GET_SERVICE_PRICE = 'GET_SERVICE_PRICE';
export const GET_SERVICE_PRICE_PENDING = 'GET_SERVICE_PRICE_PENDING';
export const GET_SERVICE_PRICE_FULFILLED = 'GET_SERVICE_PRICE_FULFILLED';
export const GET_SERVICE_PRICE_REJECTED = 'GET_SERVICE_PRICE_REJECTED';

export const GET_EXCURSION_PRICE_TYPE = 'GET_EXCURSION_PRICE_TYPE';
export const GET_EXCURSION_PRICE_TYPE_PENDING =
  'GET_EXCURSION_PRICE_TYPE_PENDING';
export const GET_EXCURSION_PRICE_TYPE_FULFILLED =
  'GET_EXCURSION_PRICE_TYPE_FULFILLED';
export const GET_EXCURSION_PRICE_TYPE_REJECTED =
  'GET_EXCURSION_PRICE_TYPE_REJECTED';

export const GET_EXCURSION_OBJECT_EXPORT_DATA =
  'GET_EXCURSION_OBJECT_EXPORT_DATA';
export const GET_EXCURSION_OBJECT_EXPORT_DATA_FULFILLED =
  'GET_EXCURSION_OBJECT_EXPORT_DATA_FULFILLED';
export const GET_EXCURSION_OBJECT_EXPORT_DATA_PENDING =
  'GET_EXCURSION_OBJECT_EXPORT_DATA_PENDING';
export const GET_EXCURSION_OBJECT_EXPORT_DATA_REJECTED =
  'GET_EXCURSION_OBJECT_EXPORT_DATA_REJECTED';

export const GET_EXCURSION_PRICE_EXPORT_DATA =
  'GET_EXCURSION_PRICE_EXPORT_DATA';
export const GET_EXCURSION_PRICE_EXPORT_DATA_FULFILLED =
  'GET_EXCURSION_PRICE_EXPORT_DATA_FULFILLED';
export const GET_EXCURSION_PRICE_EXPORT_DATA_PENDING =
  'GET_EXCURSION_PRICE_EXPORT_DATA_PENDING';
export const GET_EXCURSION_PRICE_EXPORT_DATA_REJECTED =
  'GET_EXCURSION_PRICE_EXPORT_DATA_REJECTED';

export const PUT_EXCURSION_PROFILE = 'PUT_EXCURSION_PROFILE';
export const PUT_EXCURSION_PROFILE_PENDING = 'PUT_EXCURSION_PROFILE_PENDING';
export const PUT_EXCURSION_PROFILE_FULFILLED =
  'PUT_EXCURSION_PROFILE_FULFILLED';
export const PUT_EXCURSION_PROFILE_REJECTED = 'PUT_EXCURSION_PROFILE_REJECTED';

export const DELETE_EXCURSION_OBJECT = 'DELETE_EXCURSION_OBJECT';
export const DELETE_EXCURSION_OBJECT_PENDING =
  'DELETE_EXCURSION_OBJECT_PENDING';
export const DELETE_EXCURSION_OBJECT_FULFILLED =
  'DELETE_EXCURSION_OBJECT_FULFILLED';
export const DELETE_EXCURSION_OBJECT_REJECTED =
  'DELETE_EXCURSION_OBJECT_REJECTED';

//POST _IMAGE_EXCURSION_OBJECT
export const POST_UPLOAD_IMAGE_EXCURSION_OBJECT =
  'POST_UPLOAD_IMAGE_EXCURSION_OBJECT';
export const POST_UPLOAD_IMAGE_EXCURSION_OBJECT_FULFILLED =
  'POST_UPLOAD_IMAGE_EXCURSION_OBJECT_FULFILLED';
export const POST_UPLOAD_IMAGE_EXCURSION_OBJECT_PENDING =
  'POST_UPLOAD_IMAGE_EXCURSION_OBJECT_PENDING';
export const POST_UPLOAD_IMAGE_EXCURSION_OBJECT_REJECTED =
  'POST_UPLOAD_IMAGE_EXCURSION_OBJECT_REJECTED';
