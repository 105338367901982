export const setTypeEnum = data => {
  let newData = data.map(item => {
    let val = item.Value.replace('_', ' ');
    if (val === item.Text) {
      return {
        type: item.Value,
        key: item.Text,
        value: item.Text,
        text: item.Text,
      };
    } else {
      return {
        type: item.Value,
        key: item.Text,
        value: item.Text,
        text: `${item.Value} - ${item.Text}`,
      };
    }
  });

  return newData;
};

// 0: { Value: "Attraction", Text: "Attraction" }
// 1: { Value: "Transportation", Text: "Transportation" }
// 2: { Value: "Accommodation", Text: "Accommodation" }
// 3: { Value: "Restaurant", Text: "Restaurant" }
// 4: { Value: "TourGuide", Text: "TourGuide" }
// 5: { Value: "MeetingRoom", Text: "MeetingRoom" }
// 6: { Value: "Flight_Ticket", Text: "Flight Ticket" }
// 7: { Value: "Others", Text: "Others" }
// 8: { Value: "Additional Service", Text: "Tip and Entertain" }
// 9: { Value: "Additional Service", Text: "Mandatory Services" }
// 10: { Value: "Additional Service", Text: "Non Tip" }
// 11: { Value: "Additional Service", Text: "Visa" }
// 12: { Value: "Supplement Item", Text: "Supplement Item" }
