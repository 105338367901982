import * as types from '../constants/actionTypes';

const initialState = {
  getCountry: [],
  getCountryStatus: '',
  getCountryList: [],
  getCountryListStatus: '',
  errors: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_COUNTRY:
      return { ...state };
    case types.GET_COUNTRY_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getCountryListStatus: 'failed',
      };
    case types.GET_COUNTRY_PENDING:
      return { ...state, loading: true };
    case types.GET_COUNTRY_FULFILLED:
      return {
        ...state,
        getCountryList: action.payload.data,
        loading: false,
        getCountryListStatus: 'success',
      };
    case types.RESET_COUNTRY:
      return {
        ...state,
        getCountryListStatus: '',
        getCountryStatus: '',
      };

    case types.GET_COUNTRY_LIST:
      return { ...state };
    case types.GET_COUNTRY_LIST_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getCountryStatus: 'failed',
      };
    case types.GET_COUNTRY_LIST_PENDING:
      return { ...state, loading: true };
    case types.GET_COUNTRY_LIST_FULFILLED:
      return {
        ...state,
        getCountry: action.payload.data,
        loading: false,
        getCountryStatus: 'success',
      };

    default:
      return state;
  }
};

export default reducer;
