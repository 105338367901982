import React from 'react';
import { Grid, Pagination } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ShowingDataLabel from './TotalDataTableLabel';
import styles from './styles';

const pagination = props => {
  return (
    <Grid verticalAlign="middle" style={styles.marginTop5}>
      <Grid.Row>
        <Grid.Column width={5}>
          <ShowingDataLabel
            pagedData={props.pagedData}
            numbersTo={props.numbersTo}
            numbersFrom={props.numbersFrom}
            data={props.data}
          />
        </Grid.Column>
        <Grid.Column width={11}>
          {props.pagedData && props.pagedData.length > 1 && (
            <Pagination
              totalPages={props.pagedData.length}
              onPageChange={props.pageChange}
              activePage={props.activePage}
              floated="right"
            />
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
pagination.propTypes = {
  pagedData: PropTypes.array,
  numbersTo: PropTypes.number,
  numbersFrom: PropTypes.number,
  data: PropTypes.array,
  pageChange: PropTypes.func,
  activePage: PropTypes.number,
};

export default pagination;
