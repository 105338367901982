export const searchFilterResourceBooking = (data, searchInput) => {
  // console.log(data);
  return data.filter(item => {
    return (
      // eslint-disable-next-line
      // (item.Id !== null && item.Id == searchInput) ||
      // eslint-disable-next-line
      (item.ResourceBookingId !== null &&
        // eslint-disable-next-line
        item.ResourceBookingId == searchInput) ||
      (item.TourTransactionCode !== null &&
        item.TourTransactionCode.toLowerCase().indexOf(
          searchInput.toLowerCase()
        ) !== -1) ||
      (item.ResourceBooking !== null &&
        item.ResourceBooking.toLowerCase().indexOf(
          searchInput.toLowerCase()
        ) !== -1) ||
      (item.ResourceName !== null &&
        item.ResourceName.toLowerCase().indexOf(searchInput.toLowerCase()) !==
          -1) ||
      (item.DueDate !== null &&
        item.DueDate.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1)
    );
  });
};
