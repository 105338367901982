import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header } from 'semantic-ui-react';
import { DoubleTextFieldWithError } from '../../../../components/common/fieldWithError';

const GeneralInformationBalanceDetail = props => {
  return (
    <div>
      <Grid columns="equal">
        <Grid.Row stretched>
          <Grid.Column width={15}>
            <Header as="h4" content="General Information" />
            <DoubleTextFieldWithError
              placeholder1="Company ID"
              placeholder2="Company Name"
              name1="CompanyId"
              name2="Name"
              title1="Company ID"
              title2="Company Name"
              handleChange={props.handleChange}
              data1={props.contentDetail.CompanyCode}
              data2={props.contentDetail.Name}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

GeneralInformationBalanceDetail.propTypes = {
  handleChange: PropTypes.func,
  contentDetail: PropTypes.shape({
    Name: PropTypes.string,
    CompanyCode: PropTypes.string,
  }),
  handleSelect: PropTypes.func,
};
export default GeneralInformationBalanceDetail;
