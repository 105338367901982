import React from 'react';
import PropTypes from 'prop-types';
// import { Grid, Form } from 'semantic-ui-react';
// import InputWithLabel from '../../../../components/input/inputWithLabel';
import { TexfieldWithDropdownFluidOnPage } from '../../../../components/common/fieldWithError';
import { StylesTextfieldWithError } from '../../../../components/common/styleCommon';

const LocationAndReservation = props => {
  return (
    <div>
      <TexfieldWithDropdownFluidOnPage
        requiredInput={true}
        requiredDropdown={true}
        type="number"
        placeholder1="Room Reservation Limit"
        placeholder2="Rating"
        name1="NeedApprovalThreshold"
        name2="RatingId"
        title1="Room Reservation Limit"
        title2="Rating"
        handleChange={props.handleChange}
        handleChangeDropdown={props.handleChange}
        option={props.rating}
        valueDropDown={props.accomodationProfileData.RatingId}
        data={props.accomodationProfileData.NeedApprovalThreshold}
        error2={props.errors.NeedApprovalThreshold}
        error1={props.errors.RatingId}
        styleDropdown={StylesTextfieldWithError.doubleTextFieldRight}
        styleInput={StylesTextfieldWithError.doubleTextField}
        disable={props.disablePage}
      />
      {/* <React.Fragment>
        <Grid columns={2} padded="vertically">
          <Grid.Column>
            <Form>
              <InputWithLabel
                required
                inline={false}
                label="Room Reservation Limit"
                name="NeedApprovalThreshold"
                placeholder="Room Reservation Limit"
                type="number"
                value={props.accomodationProfileData.NeedApprovalThreshold}
                handleChange={props.handleChange}
                validate={props.errors.NeedApprovalThreshold}
                disabled={props.disablePage}
              />
            </Form>
          </Grid.Column>
          <Grid.Column>
            <Form>
              <InputWithLabel
                required
                label="Rating"
                name="RatingId"
                placeholder="Rating"
                type="select"
                handleChange={props.handleChange}
                options={props.rating}
                value={props.accomodationProfileData.RatingId}
                validate={props.errors.RatingId}
                disabled={props.disablePage}
              />
            </Form>
          </Grid.Column>
        </Grid>
      </React.Fragment> */}
    </div>
  );
};

LocationAndReservation.propTypes = {
  handleChange: PropTypes.func,
  accomodationProfileData: PropTypes.object,
  rating: PropTypes.number,
  errors: PropTypes.shape({
    NeedApprovalThreshold: PropTypes.string,
    RatingId: PropTypes.string,
  }),
  disablePage: PropTypes.bool,
};
export default LocationAndReservation;
