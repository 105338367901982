import apiClient from './apiClient';
import {
  GET_GENERATE_RESOURCE_BOOKING_URL,
  GET_DOWNLOAD_RESOURCE_BOOKING_DETAIL_URL,
  PUT_RESOURCE_BOOKING_DETAIL_UPDATE_URL,
  GET_RESOURCE_BOOKING_WITH_SUMMARY_URL,
  GET_DOWNLAOD_RESOURCE_BOOKING_DETAIL_DOCUMENT_URL,
  DELETE_RESOURCE_BOOKING_COLLECTION_URL,
  GET_RESOURCE_LIST_URL,
  POST_RESOURCE,
  PUT_RESOURCE,
  GET_RESOURCE_DETAIL_BY_ID_URL,
  GET_RESOURCE_BOOKING_RESERVATION_BY_ID_URL,
  POST_RESOURCE_BOOKING_RESERVATION_URL,
  PUT_RESOURCE_BOOKING_RESERVATION_URL,
  DELETE_RESOURCE_BOOKING_RESERVATION_URL,
  POST_RESOURCE_BOOKING_RESERVATION_PAYMENT_URL,
  PUT_RESOURCE_BOOKING_RESERVATION_PAYMENT_URL,
  PUT_READY_TO_USE_QTY_URL,
  PUT_PAY_RESOURCE_BOOKING_API,
  POST_CANCEL_RESOURCE_URL,
  GET_RESOURCE_TYPE_ENUM_URL,
  POST_RESOURCE_BOOKING_BY_TYPE_URL,
} from './apiUrl';

const getGenerateResouceBookingApi = param => {
  return apiClient.get(
    //'/ResourceBookings/Generate?tourTransactionCode=UR1PAF&bookingTemplateIdRef=0&resourceBookingType=0'
    `${GET_GENERATE_RESOURCE_BOOKING_URL}?tourTransactionCode=${param.tourTransactionCode}&bookingTemplateIdRef=${param.bookingTemplateIdRef}&resourceBookingType=${param.resourceBookingType}`
  );
};

const getDownloadResourceBookingDetailApi = id => {
  return apiClient.get(
    `${GET_DOWNLOAD_RESOURCE_BOOKING_DETAIL_URL}?resourceBookingId=${id}`
  );
};

const putResourceBookingDetailUpdateApi = id => {
  return apiClient.get(`${PUT_RESOURCE_BOOKING_DETAIL_UPDATE_URL}?id=${id}`);
};
const getResourceBookingWithSummaryApi = id => {
  return apiClient.get(`${GET_RESOURCE_BOOKING_WITH_SUMMARY_URL}/${id}`);
};
const getDownloadResouceBookingDetailDocumentApi = id => {
  return apiClient.get(
    `${GET_DOWNLAOD_RESOURCE_BOOKING_DETAIL_DOCUMENT_URL}?resourceBookingDetailCollectionId=${id}`
  );
};
const deleteResourceBookingCollectionApi = id => {
  return apiClient.delete(
    `${DELETE_RESOURCE_BOOKING_COLLECTION_URL}?resourceBookingColletionId=${id}`
  );
};
const getResourceListApi = id => {
  return apiClient.get(`${GET_RESOURCE_LIST_URL}/${id}`);
};
const postResourceApi = param => {
  return apiClient.post(POST_RESOURCE, param);
};
const putResourceApi = param => {
  return apiClient.put(`${PUT_RESOURCE}?id=${param.Id}`, param);
};
const getResourceDetailByIdApi = id => {
  return apiClient.get(`${GET_RESOURCE_DETAIL_BY_ID_URL}/${id}`);
};
const getResourceBookingReservationByResourceIdApi = id => {
  return apiClient.get(`${GET_RESOURCE_BOOKING_RESERVATION_BY_ID_URL}/${id}`);
};
const postResourceBookingReservationApi = param => {
  return apiClient.post(POST_RESOURCE_BOOKING_RESERVATION_URL, param);
};
const putResourceBookingReservationApi = param => {
  return apiClient.put(
    `${PUT_RESOURCE_BOOKING_RESERVATION_URL}?id=${param.Id}`,
    param
  );
};
const deleteResourceBookingReservationApi = id => {
  return apiClient.delete(
    `${DELETE_RESOURCE_BOOKING_RESERVATION_URL}?id=${id}`
  );
};
const postResourceBookingReservationPaymentApi = param => {
  return apiClient.post(POST_RESOURCE_BOOKING_RESERVATION_PAYMENT_URL, param);
};
const putResourceBookingReservationPaymentApi = param => {
  return apiClient.put(
    `${PUT_RESOURCE_BOOKING_RESERVATION_PAYMENT_URL}?id=${param.Id}`,
    param
  );
};
const putReadyToUseQtyApi = param => {
  return apiClient.put(`${PUT_READY_TO_USE_QTY_URL}?id=${param.Id}`, param);
};

const putPayResourceBookingApi = data => {
  return apiClient.put(PUT_PAY_RESOURCE_BOOKING_API, data);
};

const postCancelResourceApi = param => {
  return apiClient.post(
    `${POST_CANCEL_RESOURCE_URL}/${param.ResourceBookingDetailCollectionId}`,
    param
  );
};

const getResourceTypeEunumApi = () => {
  return apiClient.get(GET_RESOURCE_TYPE_ENUM_URL);
};

const postResourceBookingByTypeApi = data => {
  return apiClient.post(POST_RESOURCE_BOOKING_BY_TYPE_URL, data);
};

export {
  putResourceApi,
  getGenerateResouceBookingApi,
  getDownloadResourceBookingDetailApi,
  putResourceBookingDetailUpdateApi,
  getResourceBookingWithSummaryApi,
  getDownloadResouceBookingDetailDocumentApi,
  deleteResourceBookingCollectionApi,
  getResourceListApi,
  postResourceApi,
  getResourceDetailByIdApi,
  getResourceBookingReservationByResourceIdApi,
  postResourceBookingReservationApi,
  putResourceBookingReservationApi,
  deleteResourceBookingReservationApi,
  postResourceBookingReservationPaymentApi,
  putReadyToUseQtyApi,
  putPayResourceBookingApi,
  putResourceBookingReservationPaymentApi,
  postCancelResourceApi,
  getResourceTypeEunumApi,
  postResourceBookingByTypeApi,
};
