import * as types from '../actions/actionTypes';

const initialState = {
  getRestaurantList: [],
  getRestaurantListStatus: '',
  getRestaurantByProfileId: {},
  getRestaurantByProfileIdStatus: '',
  getRestaurantItemByProfileId: [],
  getRestaurantItemByProfileIdStatus: '',
  deleteRestaurantByProfileIdStatus: '',
  getRestaurantClass: [],
  getRestaurantClassStatus: '',
  getRestaurantCategory: [],
  getRestaurantCategoryStatus: '',
  getRestaurantType: [],
  getRestaurantTypeStatus: '',
  postRestaurant: [],
  postRestaurantStatus: '',
  putRestaurantStatus: '',
  getRestaurantItemExport: [],
  getRestaurantProfileExport: '',
  getRestaurantItemTemplate: [],
  getRestaurantItemExportStatus: '',
  getRestaurantProfileExportStatus: '',
  getRestaurantItemTemplateStatus: '',
  getRestaurantPriceExport: '',
  getRestaurantPriceTemplate: '',
  getRestaurantPriceExportStatus: '',
  getRestaurantPriceTemplateStatus: '',
  getRestaurantProfileTemplate: [],
  getRestaurantProfileTemplateStatus: '',
  restaurantItem: {},
  getRestaurantItemStatus: '',
  postRestaurantItemStatus: '',
  putRestaurantItemStatus: '',
  postUploadImageRestaurantItemStatus: '',
  postUploadImageRestaurantItem: [],
  getRestaurantItemAll: [],
  getRestaurantItemAllStatus: '',
  postUploadImageRestaurantProfileStatus: '',
  postUploadImageRestaurantProfile: [],
  restaurantByCityDateAndPax: [],
  getRestaurantByCityDateAndPaxStatus: '',
  errors: {},
  dataRestaurantItem: {},
  menuType: [],
  menuTypeStatus: '',
  menuClasses: [],
  menuClassesStatus: '',
  postPrimaryImageStatus: '',
  restaurantSpecializations: [],
  restaurantProfileSpecializations: [],
  getRestaurantProfileSpecializationsStatus: '',
  getRestaurantRatingStatus: '',
  restaurantRating: [],
  deleteRestaurantItemByIdStatus: '',
  getExportDataItemById: '',
  getExportDataItemByIdStatus: null,
  restaurantEstimatePrice: [],
  loadingGetRestaurantEstimatePrice: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_RESTAURANT_LIST:
      return { ...state };
    case types.GET_RESTAURANT_LIST_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getRestaurantListStatus: 'failed',
      };
    case types.GET_RESTAURANT_LIST_PENDING:
      return { ...state, loading: true };
    case types.GET_RESTAURANT_LIST_FULFILLED:
      return {
        ...state,
        getRestaurantList: action.payload.data,
        loading: false,
        getRestaurantListStatus: 'success',
      };
    case types.GET_RESTAURANT_BY_PROFILE_ID:
      return { ...state };
    case types.GET_RESTAURANT_BY_PROFILE_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getRestaurantByProfileIdStatus: 'failed',
      };
    case types.GET_RESTAURANT_BY_PROFILE_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_RESTAURANT_BY_PROFILE_ID_FULFILLED:
      return {
        ...state,
        getRestaurantByProfileId: action.payload.data,
        loading: false,
        getRestaurantByProfileIdStatus: 'success',
      };
    case types.GET_RESTAURANT_ITEM_BY_PROFILE_ID:
      return { ...state };
    case types.GET_RESTAURANT_ITEM_BY_PROFILE_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getRestaurantItemByProfileIdStatus: 'failed',
      };
    case types.GET_RESTAURANT_ITEM_BY_PROFILE_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_RESTAURANT_ITEM_BY_PROFILE_ID_FULFILLED:
      return {
        ...state,
        getRestaurantItemByProfileId: action.payload.data,
        loading: false,
        getRestaurantItemByProfileIdStatus: 'success',
      };

    case types.DELETE_RESTAURANT_BY_PROFILE_ID:
      return { ...state };
    case types.DELETE_RESTAURANT_BY_PROFILE_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        deleteRestaurantByProfileIdStatus: 'failed',
      };
    case types.DELETE_RESTAURANT_BY_PROFILE_ID_PENDING:
      return { ...state, loading: true };
    case types.DELETE_RESTAURANT_BY_PROFILE_ID_FULFILLED:
      return {
        ...state,
        loading: false,
        deleteRestaurantByProfileIdStatus: 'success',
      };
    case types.DELETE_RESTAURANT_ITEM_BY_ID:
      return { ...state };
    case types.DELETE_RESTAURANT_ITEM_BY_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        deleteRestaurantItemByIdStatus: 'failed',
      };
    case types.DELETE_RESTAURANT_ITEM_BY_ID_PENDING:
      return { ...state, loading: true };
    case types.DELETE_RESTAURANT_ITEM_BY_ID_FULFILLED:
      return {
        ...state,
        loading: false,
        deleteRestaurantItemByIdStatus: 'success',
      };
    case types.GET_RESTAURANT_CLASS:
      return { ...state };
    case types.GET_RESTAURANT_CLASS_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
      };
    case types.GET_RESTAURANT_CLASS_PENDING:
      return { ...state, loading: true };
    case types.GET_RESTAURANT_CLASS_FULFILLED:
      return {
        ...state,
        getRestaurantClass: action.payload.data,
        getRestaurantClassStatus: 'success',
      };

    case types.GET_RESTAURANT_TYPE:
      return { ...state };
    case types.GET_RESTAURANT_TYPE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
      };
    case types.GET_RESTAURANT_TYPE_PENDING:
      return { ...state, loading: true };
    case types.GET_RESTAURANT_TYPE_FULFILLED:
      return {
        ...state,
        getRestaurantType: action.payload.data,
      };
    case types.POST_RESTAURANT_PROFILE:
      return { ...state };
    case types.POST_RESTAURANT_PROFILE_REJECTED:
      return {
        ...state,
        errors: action.payload.response.data.Message,
        postRestaurantStatus: 'failed',
      };
    case types.POST_RESTAURANT_PROFILE_PENDING:
      return { ...state, loading: true };
    case types.POST_RESTAURANT_PROFILE_FULFILLED:
      return {
        ...state,
        loading: false,
        postRestaurant: action.payload.data,
        postRestaurantStatus: 'success',
      };
    case types.PUT_RESTAURANT_PROFILE:
      return { ...state };
    case types.PUT_RESTAURANT_PROFILE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        putRestaurantStatus: 'failed',
      };
    case types.PUT_RESTAURANT_PROFILE_PENDING:
      return { ...state, loading: true };
    case types.PUT_RESTAURANT_PROFILE_FULFILLED:
      return {
        ...state,
        loading: false,
        putRestaurantStatus: 'success',
      };

    case types.GET_TEMPLATE_RESTAURANT_ITEM_DATA:
      return { ...state };
    case types.GET_TEMPLATE_RESTAURANT_ITEM_DATA_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getRestaurantItemTemplateStatus: 'failed',
      };
    case types.GET_TEMPLATE_RESTAURANT_ITEM_DATA_PENDING:
      return { ...state, loading: true };
    case types.GET_TEMPLATE_RESTAURANT_ITEM_DATA_FULFILLED:
      return {
        ...state,
        getRestaurantItemTemplate: action.payload.data,
        loading: false,
        getRestaurantItemTemplateStatus: 'success',
      };
    case types.GET_RESTAURANT_ITEM_EXPORT_DATA:
      return { ...state };
    case types.GET_RESTAURANT_ITEM_EXPORT_DATA_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getRestaurantItemExportStatus: 'failed',
      };
    case types.GET_RESTAURANT_ITEM_EXPORT_DATA_PENDING:
      return { ...state, loading: true };
    case types.GET_RESTAURANT_ITEM_EXPORT_DATA_FULFILLED:
      return {
        ...state,
        getRestaurantItemExport: action.payload.data,
        loading: false,
        getRestaurantItemExportStatus: 'success',
      };
    //--------------------------
    case types.GET_TEMPLATE_RESTAURANT_ITEM_PRICE_DATA:
      return { ...state };
    case types.GET_TEMPLATE_RESTAURANT_ITEM_PRICE_DATA_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getRestaurantPriceTemplateStatus: 'failed',
      };
    case types.GET_TEMPLATE_RESTAURANT_ITEM_PRICE_DATA_PENDING:
      return { ...state, loading: true };
    case types.GET_TEMPLATE_RESTAURANT_ITEM_PRICE_DATA_FULFILLED:
      return {
        ...state,
        getRestaurantPriceTemplate: action.payload.data,
        loading: false,
        getRestaurantPriceTemplateStatus: 'success',
      };
    case types.GET_RESTAURANT_ITEM_PRICE_EXPORT_DATA:
      return { ...state };
    case types.GET_RESTAURANT_ITEM_PRICE_EXPORT_DATA_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getRestaurantPriceExportStatus: 'failed',
      };
    case types.GET_RESTAURANT_ITEM_PRICE_EXPORT_DATA_PENDING:
      return { ...state, loading: true };
    case types.GET_RESTAURANT_ITEM_PRICE_EXPORT_DATA_FULFILLED:
      return {
        ...state,
        getRestaurantPriceExport: action.payload.data,
        loading: false,
        getRestaurantPriceExportStatus: 'success',
      };
    case types.GET_TEMPLATE_RESTAURANT_PROFILE_DATA:
      return { ...state };
    case types.GET_TEMPLATE_RESTAURANT_PROFILE_DATA_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getRestaurantProfileTemplateStatus: 'failed',
      };
    case types.GET_TEMPLATE_RESTAURANT_PROFILE_DATA_PENDING:
      return { ...state, loading: true };
    case types.GET_TEMPLATE_RESTAURANT_PROFILE_DATA_FULFILLED:
      return {
        ...state,
        getRestaurantProfileTemplate: action.payload.data,
        loading: false,
        getRestaurantProfileTemplateStatus: 'success',
      };
    case types.GET_RESTAURANT_CATEGORY: {
      return { ...state };
    }
    case types.GET_RESTAURANT_CATEGORY_FULFILLED: {
      return {
        ...state,
        loading: false,
        getRestaurantCategory: action.payload.data,
        getRestaurantCategoryStatus: 'success',
      };
    }
    case types.GET_RESTAURANT_CATEGORY_PENDING: {
      return { ...state, loading: true };
    }
    case types.GET_RESTAURANT_CATEGORY_REJECRED: {
      return {
        ...state,
        loading: false,
        getRestaurantCategoryStatus: 'failed',
      };
    }
    case types.GET_RESTAURANT_ITEM_BY_ID: {
      return { ...state };
    }
    case types.GET_RESTAURANT_ITEM_BY_ID_PENDING: {
      return { ...state, loading: true };
    }
    case types.GET_RESTAURANT_ITEM_BY_ID_FULFILLED: {
      return {
        ...state,
        loading: false,
        restaurantItem: action.payload.data,
        getRestaurantItemStatus: 'success',
      };
    }
    case types.GET_RESTAURANT_ITEM_BY_ID_REJECRED: {
      return { ...state, getRestaurantItemStatus: 'failed', loading: false };
    }
    case types.PUT_RESTAURANT_ITEM: {
      return { ...state };
    }
    case types.PUT_RESTAURANT_ITEM_PENDING: {
      return { ...state, loading: true };
    }
    case types.PUT_RESTAURANT_ITEM_FULFILLED: {
      return { ...state, loading: false, putRestaurantItemStatus: 'success' };
    }
    case types.PUT_RESTAURANT_ITEM_REJECTED: {
      return {
        ...state,
        loading: false,
        putRestaurantItemStatus: 'failed',
        errors: action,
      };
    }
    case types.POST_RESTAURANT_ITEM: {
      return { ...state };
    }
    case types.POST_RESTAURANT_ITEM_PENDING: {
      return { ...state, loading: true };
    }
    case types.POST_RESTAURANT_ITEM_FULFILLED: {
      return {
        ...state,
        loading: false,
        postRestaurantItemStatus: 'success',
        dataRestaurantItem: action.payload.data,
      };
    }
    case types.POST_RESTAURANT_ITEM_REJECTED: {
      return { ...state, loading: false, postRestaurantItemStatus: 'failed' };
    }
    case types.GET_EXPORT_RESTAURANT_PROFILE:
      return { ...state };
    case types.GET_EXPORT_RESTAURANT_PROFILE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getRestaurantProfileExportStatus: 'failed',
      };
    case types.GET_EXPORT_RESTAURANT_PROFILE_PENDING:
      return { ...state, loading: true };
    case types.GET_EXPORT_RESTAURANT_PROFILE_FULFILLED:
      return {
        ...state,
        getRestaurantProfileExport: action.payload.data,
        loading: false,
        getRestaurantProfileExportStatus: 'success',
      };

    case types.POST_UPLOAD_IMAGE_RESTAURANT_ITEM:
      return { ...state };
    case types.POST_UPLOAD_IMAGE_RESTAURANT_ITEM_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        postUploadImageRestaurantItemStatus: 'failed',
      };
    case types.POST_UPLOAD_IMAGE_RESTAURANT_ITEM_PENDING:
      return { ...state, loading: true };
    case types.POST_UPLOAD_IMAGE_RESTAURANT_ITEM_FULFILLED:
      return {
        ...state,
        postUploadImageRestaurantItemStatus: 'success',
        postUploadImageRestaurantItem: action.payload.data,
        loading: false,
      };

    case types.GET_RESTAURANT_ITEM_ALL:
      return { ...state };
    case types.GET_RESTAURANT_ITEM_ALL_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getRestaurantItemAllStatus: 'failed',
      };
    case types.GET_RESTAURANT_ITEM_ALL_PENDING:
      return { ...state, loading: true };
    case types.GET_RESTAURANT_ITEM_ALL_FULFILLED:
      return {
        ...state,
        getRestaurantItemAll: action.payload.data,
        loading: false,
        getRestaurantItemAllStatus: 'success',
      };

    case types.POST_UPLOAD_IMAGE_RESTAURANT_PROFILE:
      return { ...state };
    case types.POST_UPLOAD_IMAGE_RESTAURANT_PROFILE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        postUploadImageRestaurantProfileStatus: 'failed',
      };
    case types.POST_UPLOAD_IMAGE_RESTAURANT_PROFILE_PENDING:
      return { ...state, loading: true };
    case types.POST_UPLOAD_IMAGE_RESTAURANT_PROFILE_FULFILLED:
      return {
        ...state,
        postUploadImageRestaurantProfileStatus: 'success',
        postUploadImageRestaurantProfile: action.payload.data,
        loading: false,
      };
    case types.GET_RESTAURANT_BY_CITY_DATE_PAX: {
      return { ...state };
    }
    case types.GET_RESTAURANT_BY_CITY_DATE_PAX_PENDING: {
      return { ...state, loading: true, loadingFilter: true };
    }
    case types.GET_RESTAURANT_BY_CITY_DATE_PAX_FULFILLED: {
      return {
        ...state,
        loading: false,
        getRestaurantByCityDateAndPaxStatus: 'success',
        restaurantByCityDateAndPax: action.payload.data,
        loadingFilter: false,
      };
    }
    case types.GET_RESTAURANT_BY_CITY_DATE_PAX_REJECTED: {
      return {
        ...state,
        getRestaurantByCityDateAndPaxStatus: 'failed',
        loading: false,
        loadingFilter: false,
      };
    }
    case types.RESET_RESTAURANT:
      return {
        ...state,
        getRestaurantItemStatus: '',
        getRestaurantListStatus: '',
        deleteRestaurantByProfileIdStatus: '',
        getRestaurantByProfileIdStatus: '',
        getRestaurantItemByProfileIdStatus: '',
        getRestaurantItemExportStatus: '',
        getRestaurantItemTemplateStatus: '',
        getRestaurantPriceExportStatus: '',
        getRestaurantPriceTemplateStatus: '',
        getRestaurantProfileTemplateStatus: '',
        postRestaurantStatus: '',
        putRestaurantStatus: '',
        postRestaurantItemStatus: '',
        getRestaurantClassStatus: '',
        putRestaurantItemStatus: '',
        getRestaurantProfileExportStatus: '',
        postUploadImageRestaurantItemStatus: '',
        getRestaurantItemAllStatus: '',
        postUploadImageRestaurantProfileStatus: '',
        getRestaurantByCityDateAndPaxStatus: '',
        postPrimaryImageStatus: '',
        getRestaurantProfileSpecializationsStatus: '',
        getRestaurantRatingStatus: '',
        deleteRestaurantItemByIdStatus: '',
        getExportDataItemByIdStatus: null,
      };
    case types.GET_MASTER_REFERENCE_MENU_TYPE_LIST:
      return { ...state };
    case types.GET_MASTER_REFERENCE_MENU_TYPE_LIST_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        menuTypeStatus: 'failed',
      };
    case types.GET_MASTER_REFERENCE_MENU_TYPE_LIST_PENDING:
      return { ...state, loading: true };
    case types.GET_MASTER_REFERENCE_MENU_TYPE_LIST_FULFILLED:
      return {
        ...state,
        menuType: action.payload.data,
        loading: false,
        menuTypeStatus: 'success',
      };
    case types.GET_MENU_CLASS:
      return { ...state };
    case types.GET_MENU_CLASS_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        menuClassesStatus: 'failed',
      };
    case types.GET_MENU_CLASS_PENDING:
      return { ...state, loading: true };
    case types.GET_MENU_CLASS_FULFILLED:
      return {
        ...state,
        menuClasses: action.payload.data,
        loading: false,
        menuClassesStatus: 'success',
      };

    case types.POST_SET_PRIMARY_IMAGE:
      return { ...state };
    case types.POST_SET_PRIMARY_IMAGE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        postPrimaryImageStatus: 'failed',
      };
    case types.POST_SET_PRIMARY_IMAGE_PENDING:
      return { ...state, loading: true };
    case types.POST_SET_PRIMARY_IMAGE_FULFILLED:
      return {
        ...state,
        loading: false,
        postPrimaryImageStatus: 'success',
      };
    case types.GET_MASTER_REFERENCE_RESTAURANT_PROFILE_SPECIALIZATIONS_LIST:
      return { ...state };
    case types.GET_MASTER_REFERENCE_RESTAURANT_PROFILE_SPECIALIZATIONS_LIST_PENDING:
      return { ...state, loading: true };
    case types.GET_MASTER_REFERENCE_RESTAURANT_PROFILE_SPECIALIZATIONS_LIST_FULFILLED:
      return {
        ...state,
        loading: false,
        restaurantSpecializations: action.payload.data,
        getRestaurantProfileSpecializationsStatus: 'success',
      };
    case types.GET_MASTER_REFERENCE_RESTAURANT_PROFILE_SPECIALIZATIONS_LIST_REJECTED:
      return {
        ...state,
        loading: false,
        getRestaurantProfileSpecializationsStatus: 'failed',
      };
    case types.GET_RESTAURANT_RATING:
      return { ...state };
    case types.GET_RESTAURANT_RATING_PENDING:
      return { ...state, loading: true };
    case types.GET_RESTAURANT_RATING_FULFILLED:
      return {
        ...state,
        loading: false,
        restaurantRating: action.payload.data,
        getRestaurantRatingStatus: 'success',
      };
    case types.GET_RESTAURANT_RATING_REJECTED:
      return {
        ...state,
        loading: false,
        getRestaurantRatingStatus: 'failed',
      };

    case types.GET_EXPORT_DATA_ITEM_BY_ID:
      return { ...state };
    case types.GET_EXPORT_DATA_ITEM_BY_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getExportDataItemByIdStatus: false,
      };
    case types.GET_EXPORT_DATA_ITEM_BY_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_EXPORT_DATA_ITEM_BY_ID_FULFILLED:
      return {
        ...state,
        getExportDataItemById: action.payload.data,
        loading: false,
        getExportDataItemByIdStatus: true,
      };
    case types.GET_RESTAURANT_ESTIMATE_PRICE:
      return { ...state };
    case types.GET_RESTAURANT_ESTIMATE_PRICE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loadingGetRestaurantEstimatePrice: false,
        restaurantEstimatePrice: [],
      };
    case types.GET_RESTAURANT_ESTIMATE_PRICE_PENDING:
      return { ...state, loadingGetRestaurantEstimatePrice: true };
    case types.GET_RESTAURANT_ESTIMATE_PRICE_FULFILLED:
      return {
        ...state,
        restaurantEstimatePrice: action.payload.data,
        loadingGetRestaurantEstimatePrice: false,
      };

    default:
      return state;
  }
};

export default reducer;
