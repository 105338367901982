import React from 'react';
import { Image, Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import UploadButton from '../../../../components/common/uploadImageButton';
import styles from '../styles';
const ImageCustomerAndCompanyLogo = props => (
  <React.Fragment>
    <Grid>
      <Grid.Row>
        <Grid.Column width={16} textAlign="center">
          <Image
            src={
              props.companyLogo ||
              require('../../../../assets/Icons/no_image.jpg')
            }
            size="small"
            centered
          />
          <UploadButton
            onImageSelect={props.handleChangeImage}
            label={props.contentLogo}
            buttonStyle={styles.marginTopUploadImage}
            disabled={props.disabled}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </React.Fragment>
);

ImageCustomerAndCompanyLogo.propTypes = {
  companyImage: PropTypes.string,
  companyLogo: PropTypes.string,
  modalImage: PropTypes.func,
  contentLogo: PropTypes.string,
  contentImage: PropTypes.string,
  disabled: PropTypes.bool,
  handleChangeImage: PropTypes.func,
};
export default ImageCustomerAndCompanyLogo;
