import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import MainPageList from './mainPageList';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../scripts/pagination';
import FilterData from '../../../scripts/filterDataTable';
import CustomerProfileList from '../../customer/customerList/components/ListCustomerProfile';

class PartnerProfileList extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
    };
  }
  static propTypes = {
    data: PropTypes.array,
  };
  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  handleChangeDate = (value, name) => {
    this.setState({ [name]: value, activePage: 1 });
  };
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  render() {
    let {
      data,
      filterBy,
      buttonNew,
      buttonEdit,
      buttonDelete,
      isHideButtonDelete,
      isFinance,
    } = this.props;
    let { searchInput, activePage } = this.state;
    let filterData = FilterData(data, filterBy, searchInput);
    let pagedData = datasetPagination(filterData, 7);
    let listData = pagedData[this.state.activePage - 1];

    let { numbersFrom, numbersTo } = getNumberFromTo(
      listData,
      this.state.activePage,
      7
    );
    let handleChange =
      this.props.filterBy === 'FormatedDate'
        ? this.handleChangeDate
        : this.props.filterBy === 'exchangeRate'
        ? this.handleChangeDate
        : this.handleChange;

    return (
      <CustomerProfileList
        listData={listData}
        pagedData={pagedData}
        title={'Partner Profile'}
        pageChange={this.pageChange}
        activePage={activePage}
        searchInput={searchInput}
        handleChange={handleChange}
        data={data}
        buttonNew={buttonNew}
        buttonEdit={buttonEdit}
        type="partnerProfile"
        numbersFrom={numbersFrom}
        numbersTo={numbersTo}
        buttonDelete={buttonDelete}
        isHideButtonDelete={isHideButtonDelete}
        isFinance={isFinance}
      />
    );
  }
}

PartnerProfileList.propTypes = {
  listData: PropTypes.array,
  pagedData: PropTypes.array,
  title: PropTypes.string,
  data: PropTypes.array,
  buttonNew: PropTypes.func,
  buttonEdit: PropTypes.func,
  buttonDelete: PropTypes.func,
  filterBy: PropTypes.string,
  isHideButtonDelete: PropTypes.bool,
  isFinance: PropTypes.bool,
};

export default PartnerProfileList;
