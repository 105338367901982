export const HeaderTableCommissionHistory = [
  'Created Date',
  'Paid Amount',
  'Must Be Paid',
  'Already Paid',
  'Action',
];

export const HeaderTableInvoiceTourTransaction = [
  'Invoice No.',
  'Booking No.',
  'Status',
  'Issue Date',
  'Last Modified',
  'Total Price',
  'Total Paid',
  'Action',
];

export const HeaderPaymentTermDetail = [
  'Description',
  'Percentage',
  'Amount',
  'Actual Paid',
  'Expiry Date',
  'Pay Date',
  'Status',
];

export const HeaderTableAdditionalItem = [
  'Item',
  'Mandatory',
  'Infant Count',
  'Quantity',
  'Amount',
];

export const HeaderTableAdditionalItemWithoutPrice = [
  'Item',
  'Mandatory',
  'Infant Count',
  'Quantity',
];

export const HeaderTableSpecialAdjusment = [
  'Type',
  'Description',
  'Hide Adjustment',
  'Operator',
  'Quantity',
  'Unit Cost',
  'Total Cost',
  'Action',
];

export const HeaderTableSpecialAdjusmentWithoutCost = [
  'Type',
  'Description',
  'Hide Adjustment',
  'Operator',
  'Quantity',
];
