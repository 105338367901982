const FilterData = (data, filterBy, searchInput) => {
  return filterBy === 'ObjectType'
    ? data.filter(data => {
        return (
          data.ObjectType.toLowerCase().indexOf(searchInput.toLowerCase()) !==
          -1
        );
      })
    : filterBy === 'Id'
      ? data.filter(data => {
          return data.Id.toString().indexOf(searchInput.toString()) !== -1;
        })
      : null;
};

export default FilterData;
