import {
  GET_DATA_TRANSFER_LOGS,
  GET_DATA_TRANSFER_LOGS_BY_ID,
  POST_DATA_TRANSFER_LOGS,
  PUT_DATA_TRANSFER_LOGS,
  DELETE_DATA_TRANSFER_LOGS,
  GET_DATA_TRANSFER_LOGS_BY_RANGE,
  RESET_DATA_TRANSFER_LOGS,
  RESET_DATA_TRANSFER_LOGS_BY_ID,
  GET_DATA_TRANSFER_LOGS_BY_REFERENCE_ID,
} from '../dataTransferLog/constants/actionTypes';
import getDataTransferLogsApi from '../../api/dataTransferLogs/getDataTransferLogsApi';
import getDataTransferLogsByIdApi from '../../api/dataTransferLogs/getDataTransferLogsByIdApi';
import postDataTransferLogsApi from '../../api/dataTransferLogs/postDataTransferLogsApi';
import putDataTransferLogsApi from '../../api/dataTransferLogs/putDataTransferLogsApi';
import deleteDataTransferLogsApi from '../../api/dataTransferLogs/deleteDataTransferLogsApi';
import getDataTransferLogsByRangeApi from '../../api/dataTransferLogs/getDataTransferLogsByRangeApi';
import getDataTransferLogsByReferenceIdApi from '../../api/dataTransferLogs/getDataTransferLogsByReferenceIdApi';

const getDataTransferLogsAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_DATA_TRANSFER_LOGS,
      payload: getDataTransferLogsApi(),
    });
  };
};

const getDataTransferLogsByIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_DATA_TRANSFER_LOGS_BY_ID,
      payload: getDataTransferLogsByIdApi(Id),
    });
  };
};

const postDataTransferLogsAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_DATA_TRANSFER_LOGS,
      payload: postDataTransferLogsApi(data),
    });
  };
};

const putDataTransferLogsAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_DATA_TRANSFER_LOGS,
      payload: putDataTransferLogsApi(id, data),
    });
  };
};

const deleteDataTransferLogsAction = Id => {
  return dispatch => {
    return dispatch({
      type: DELETE_DATA_TRANSFER_LOGS,
      payload: deleteDataTransferLogsApi(Id),
    });
  };
};

const getDataTransferLogsByRangeAction = (fromLine, toLine) => {
  return dispatch => {
    return dispatch({
      type: GET_DATA_TRANSFER_LOGS_BY_RANGE,
      payload: getDataTransferLogsByRangeApi(fromLine, toLine),
    });
  };
};

const resetDataTransferLogsAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_DATA_TRANSFER_LOGS,
    });
  };
};

const resetDataTransferLogsByIdAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_DATA_TRANSFER_LOGS_BY_ID,
    });
  };
};

const getDataTransferLogsByReferenceIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_DATA_TRANSFER_LOGS_BY_REFERENCE_ID,
      payload: getDataTransferLogsByReferenceIdApi(Id),
    });
  };
};

export {
  getDataTransferLogsAction,
  getDataTransferLogsByIdAction,
  postDataTransferLogsAction,
  putDataTransferLogsAction,
  deleteDataTransferLogsAction,
  getDataTransferLogsByRangeAction,
  resetDataTransferLogsAction,
  resetDataTransferLogsByIdAction,
  getDataTransferLogsByReferenceIdAction,
};
