import React, { Component } from 'react';
import { Table, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
// import IndexPagination from '../genericFunction/indexPagination';
// import { Style } from '../../assets/styles/styleAccomodation';
import Pagination from '../common/pagination';
import { getNumberFromTo } from '../../scripts/pagination';
// const tableAddingData = props => {
// const props = this.props;
class tableAddingData extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
    };
  }
  static propTypes = {
    data: PropTypes.array,
  };
  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });

  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };

  render() {
    // let pagedData;

    const props = this.props;
    const totalRow = 3;
    let { numbersFrom, numbersTo } = getNumberFromTo(
      props.listData,
      props.activePage,
      totalRow
    );
    return (
      <div>
        <Table basic="very" selectable fixed>
          <Table.Header>
            <Table.Row>
              {this.props.headerTable.map((headTableContent, index) => {
                return (
                  <Table.HeaderCell key={index}>
                    {headTableContent}
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {props.listData ? (
              props.listData.map((data, idx) => {
                return (
                  <Table.Row key={idx}>
                    <Table.Cell>
                      {data.Name || data.CityId || data.Id}
                    </Table.Cell>
                    {props.headerContent === 'Accessible City' ? (
                      <Table.Cell>
                        {data.Region ? data.Region.Name : ''}
                      </Table.Cell>
                    ) : null}
                    <Table.Cell>
                      <Button
                        content="Delete"
                        negative
                        value={this.props.value} //id={idx}
                        // value={data.Id}
                        id={data.FacilityId || data.LocationId || data.Id}
                        name={idx} //name={data.Id}
                        onClick={this.props.deleteState}
                        disabled={this.props.disabled}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })
            ) : (
              <Table.Row>
                <Table.Cell colSpan="2" textAlign="center">
                  No Data
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        {/* {this.props.pageAddingData &&
      this.props.pageAddingData.length > 1 && (
        <Pagination
          totalPages={this.props.pageAddingData.length}
          onPageChange={this.props.pageChange}
          activePage={this.props.activePage}
          style={{ float: 'right' }}
        />
      )} */}
        <Pagination
          pagedData={props.pagedData}
          numbersTo={numbersTo}
          numbersFrom={numbersFrom}
          data={props.addingDataList}
          pageChange={props.pageChange}
          activePage={props.activePage}
          style={{ float: 'right' }}
        />
      </div>
    );
  }
}

tableAddingData.propTypes = {
  addingDataList: PropTypes.array,
  listData: PropTypes.array,
  deleteState: PropTypes.func,
  headerTable: PropTypes.array,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  activePage: PropTypes.string,
  pageAddingData: PropTypes.array,
  pageChange: PropTypes.func,
  pagedData: PropTypes.array,
  headerContent: PropTypes.string,
};
export default tableAddingData;
