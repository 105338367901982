import React from 'react';
import { Tab } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  resetCustomerProfileAction,
  getCustomerProfileByCodeAction,
  putCustomerProfileAction,
  getPaymentSchemeAction,
  postCustomerProfileAction,
} from '../../shared/actions/customerProfileAction';
import {
  getTourOperatorProfileByCodeAction,
  resetGetTourOperatorByCodeAction,
  deleteTourOperatorAction,
  resetStatusDeleteTourOperatorAction,
} from '../../../actions/packageAction';
import {
  resetCustomEnumAction,
  getCustomEnumByCatagoriesRevenueRangeAction,
  getCustomEnumByCatagoriesTotalBranchAction,
  getCustomEnumByCatagoriesTotalEmployeAction,
} from '../../shared/actions/customEnumAction';
import { getPartnerEmployeeAction } from '../../shared/actions/partnerProfileAction';
import { getCurrenciesAction } from '../../../actions/currenciesAction';
import { getCountryListAction } from '../../shared/actions/countryAction';
import { getCityAction } from '../../shared/actions/cityAction';
// import PartnerProfile from '../components/partnerProfile/partnerProfileDetail';
import PartnerProfile from './components/PartnerProfileDetail';
// import ModalAlert from './../components/modal/modalAlert';
import ModalAlert from '../../shared/components/modal/ModalAlert';
// import ModalImage from '../components/modal/modalAccomodation/modalImage';
import ModalImage from '../../shared/components/modal/accommodation/ModalImage';
import {
  HeaderTabelTourOperatorProfile,
  // HeaderTabelEmployeeList,
} from './constants/HeaderTable';
// import TableListPartnerProfile from '../components/table/tableListPartnerProfile';
import TableListPartnerProfile from './components/TableListPartnerProfile';
// import TableListEmployee from './components/TableListEmployee';
import ModalConfirm from '../../../components/modal/modalConfirmationTwoButton';
import { Redirect } from 'react-router-dom';
import {
  partnerProfileField,
  resetPasswordField,
} from './constants/fieldToValidate';
import handleValidation from '../../shared/services/validation/emptyFieldValidation';
import _ from 'lodash';
import { setCustomerData } from './service/data/setStateCustomerData';
import ModalResetPassword from '../../shared/components/modal/user/ModalResetPassword';

import { getUserDataByCodeAction } from '../../shared/actions/customerProfileAction';
import {
  postUnlockUserAction,
  resetUserAccessAction,
  getUserProfileByUserIdAction,
  postResetPasswordAction,
} from '../../../actions/userAccessAction';

// const dataDummy = [
//   {
//     Username: 'Username',
//     FirstName: 'Firstname',
//     LastName: 'Lastname',
//     Telephone: 'Telephone',
//     Email: 'Email',
//     Address: 'Address',
//     IsInactive: false,
//   },
// ];

class customerProfileDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      codeCustomer: 0,
      customerProfile: {},
      disable: true,
      contentButton: '',
      contentModal: '',
      modalConfirmation: false,
      loadingCustomerProfile: true,
      failedCustomerProfile: false,
      openModalImage: false,
      dataItem: [],
      listGender: [
        { key: 'Male', text: 'Male', value: 'Male' },
        { key: 'Female', text: 'Female', value: 'Female' },
      ],
      errors: {},
      tourOperatorListByCode: [],
      changePage: false,
      gotoLink: null,
      openModalResetPassword: false,
      resetPasswordObject: {
        NewPassword: '',
        ConfirmPassword: '',
      },
      typePassword: 'password',
      passwordIcon: 'eye slash',
      typeConfirmPassword: 'password',
      confirmPasswordIcon: 'eye slash',
      TabMenu: [
        {
          menuItem: 'Tour Operator Profile',
          render: () => (
            <Tab.Pane
              active={true}
              content={
                <TableListPartnerProfile
                  data={this.props.tourOperatorListByCode}
                  headerTable={HeaderTabelTourOperatorProfile}
                  pageChange={this.pageChange}
                  listData={this.props.tourOperatorListByCode}
                  handleClickDetail={this.handleOpenDetail}
                  buttonSetMargin={this.setMarginTourOperator}
                  buttonEdit={this.handleOpenToEdit}
                  buttonDelete={this.deleteConfirmation}
                  addNewTourOperator={this.handleOpenDetail}
                  numbersFrom={this.numbersFrom}
                  numbersTo={this.numbersTo}
                  disable={this.state.disable}
                  contentButton={this.state.contentButton}
                  isFinance={this.props.isFinance}
                />
              }
            />
          ),
        },
        // {
        //   menuItem: 'Employee',
        //   render: () => (
        //     <Tab.Pane
        //       attached={false}
        //       content={
        //         <TableListEmployee
        //           // listData={dataDummy}
        //           listData={this.props.getUserDataByCode}
        //           unlockUser={this.unlockConfrimation}
        //           buttonModalEmployee={this.buttonModalEmployee}
        //           resetPassword={this.resetPassword}
        //           editUser={this.editUser}
        //           // loading={this.props.loading}
        //           // isNewForm={this.state.isNewForm}
        //           disabled={this.state.disable}
        //         />
        //       }
        //     />
        //   ),
        // },
      ],
      companyId: 0,
      nextPage: false,
      operatorId: 0,
    };
  }
  static propTypes = {
    resetCustomerProfileAction: PropTypes.func,
    getCustomerProfileByCodeAction: PropTypes.func,
    putCustomerProfileAction: PropTypes.func,
    getCustomerProfileByCodeStatus: PropTypes.string,
    getCustomerProfileByCode: PropTypes.array,
    resetCustomEnumAction: PropTypes.func,
    getCustomEnumByCatagoriesRevenueRangeAction: PropTypes.func,
    getCatagoriesCustomEnumStatus: PropTypes.string,
    getCatagoriesCustomEnum: PropTypes.array,
    getCustomEnumByCatagoriesTotalBranchAction: PropTypes.func,
    getCustomEnumByCatagoriesTotalEmployeAction: PropTypes.func,
    getCustomEnumByCatagoriesRevenueRange: PropTypes.array,
    getCustomEnumByCatagoriesTotalEmploye: PropTypes.array,
    getCustomEnumByCatagoriesTotalBranch: PropTypes.array,
    getCurrenciesAction: PropTypes.func,
    getCurrencies: PropTypes.array,
    listCountry: PropTypes.array,
    getCountryListAction: PropTypes.func,
    getPaymentSchemeAction: PropTypes.func,
    getPaymentSchemeList: PropTypes.array,
    postCustomerProfileAction: PropTypes.func,
    putCustomerProfileStatus: PropTypes.string,
    postCustomerProfileStatus: PropTypes.string,
    currencies: PropTypes.array,
    getTourOperatorProfileByCodeAction: PropTypes.func,
    tourOperatorListByCode: PropTypes.array,
    getTourOperatorByCodeStatus: PropTypes.string,
    resetGetTourOperatorByCodeAction: PropTypes.func,
    deleteTourOperatorAction: PropTypes.func,
    deleteTourOperatorStatus: PropTypes.string,
    resetStatusDeleteTourOperatorAction: PropTypes.func,
    getCityAction: PropTypes.func,
    listCity: PropTypes.array,
    loadingTourOperator: PropTypes.bool,
    loadingCustomer: PropTypes.bool,
    postCustomerProfile: PropTypes.object,
    numbersFrom: PropTypes.number,
    numbersTo: PropTypes.number,
    listData: PropTypes.array,
    getUserDataByCodeAction: PropTypes.func,
    match: PropTypes.shape({
      params: PropTypes.string,
    }),
    getUserDataByCode: PropTypes.array,
    getUserProfileByUserIdAction: PropTypes.func,
    getUserProfileByUserIdStatus: PropTypes.string,
    getUserProfileByUserId: PropTypes.object,
    resetUserAccessAction: PropTypes.func,
    loadingUserStore: PropTypes.bool,
    postUnlockUserAction: PropTypes.func,
    postResetPasswordAction: PropTypes.func,
    postResetPasswordStatus: PropTypes.string,
    isFinance: PropTypes.bool,
  };
  componentDidMount = () => {
    let { ProfileId } = this.props.match.params;
    this.getCustomerProfileId();
    this.props.getCityAction();
    this.props.getUserDataByCodeAction(ProfileId);
    this.load();
  };
  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  async load(code) {
    if (code) {
      await this.props.getCustomerProfileByCodeAction(code);
      await this.props.getTourOperatorProfileByCodeAction(code);
    } else {
      await this.props.getCustomEnumByCatagoriesRevenueRangeAction();
      await this.props.getCustomEnumByCatagoriesTotalBranchAction();
      await this.props.getCustomEnumByCatagoriesTotalEmployeAction();
      await this.props.getCurrenciesAction();
      await this.props.getCountryListAction();
      await this.props.getPaymentSchemeAction();
    }
  }
  handleOpenDetail = (e, { value }) => {
    let type = value !== 0 ? 'detail' : 'create';
    // eslint-disable-next-line
    let { ProfileId } = this.props.match.params;
    // let { operatorId } = this.state;
    this.setState({
      nextPage: true,
      operatorId: value,
      Type: type,
      contentButton: 'Edit',
      gotoLink: `/tour-operator-detail/${type}/${ProfileId}/${value}`,
    });
  };
  handleOpenToEdit = (e, { value }) => {
    // eslint-disable-next-line
    let { ProfileId } = this.props.match.params;
    // let { operatorId } = this.state;
    this.setState({
      nextPage: true,
      operatorId: value,
      Type: 'edit',
      contentButton: 'Save',
      gotoLink: `/tour-operator-detail/detail/${ProfileId}/${value}`,
    });
  };

  handleClose = () => {
    let { nextPageLink } = this.state;
    if (nextPageLink) {
      // eslint-disable-next-line
      nextPageLink && this.props.history.push(nextPageLink);
      this.setState({
        nextPageLink: '',
        loadingHistoryTransactionCustomer: false,
        modalConfirmation: false,
      });
    } else {
      const { ProfileId } = this.props.match.params;
      this.props.getUserDataByCodeAction(ProfileId);
      this.load(ProfileId);
      this.setState({
        headerAlert: '',
        contentAlert: '',
        modalConfirmation: false,
      });
    }
  };
  handleCloseModalImage = () => this.setState({ openModalImage: false });
  getCustomerProfileId = () => {
    // eslint-disable-next-line
    let { ProfileId, Type } = this.props.match.params;
    if (Type === 'new') {
      this.setState({
        isNewForm: Type === 'new',
        loadingCustomerProfile: false,
        loadingHistoryTransactionCustomer: false,
        loadingUserDataCustomer: false,
        contentButton: 'Save',
        disable: false,
        customerProfile: {
          CompanyId: '',
          Name: '',
          Address: '',
          Telephone: '',
          CountryId: '',
          EmployeeRangeId: '',
          RevenueRangeId: '',
          BranchRangeId: '',
          CurrencyId: '',
          CompanyBusiness: 2,
          ImageContent: '',
          ImagePreview: '',
          CompanyLogo: {
            ImageName: '',
            ImageContent: '',
            ImagePreview: '',
          },
          Email: '',
        },
      });
    } else {
      return this.getDataCustomer(ProfileId, Type);
    }
  };
  getDataCustomer = (code, type) => {
    this.load(code);
    this.setState({
      isNewForm: type === 'new',
      customerProfile: {
        CompanyId: code,
      },
      resetPasswordObject: {
        ...this.state.resetPasswordObject,
        CompanyCode: code,
      },
      contentButton: type === 'detail' ? 'Edit' : 'Save',
      disable: type === 'detail',
    });
    this.props.resetGetTourOperatorByCodeAction();
  };
  componentDidUpdate() {
    if (this.props.getCustomerProfileByCodeStatus === 'success') {
      this.setStateCustomerProfile(this.props.getCustomerProfileByCode);
      this.setState({ loadingCustomerProfile: false });
      this.props.resetCustomerProfileAction();
    } else if (this.props.getCustomerProfileByCodeStatus === 'failed') {
      this.setState({
        failedCustomerProfile: true,
        loadingCustomerProfile: false,
      });
      this.props.resetCustomerProfileAction();
    }
    if (this.props.getCatagoriesCustomEnumStatus === 'success') {
      this.setState({ companyRevenue: this.props.getCatagoriesCustomEnum });
      this.props.resetCustomEnumAction();
      this.props.getCustomEnumByCatagoriesRevenueRangeAction('TOTAL_BRANCH');
    } else if (this.props.getCatagoriesCustomEnumStatus === 'failed') {
      this.setState({
        failedCustomerProfile: true,
        loadingCustomerProfile: false,
      });
      this.props.resetCustomEnumAction();
    }

    if (this.props.postCustomerProfileStatus === 'success') {
      let { Code } = this.props.postCustomerProfile;
      this.setState({
        headerAlert: ' Success',
        contentAlert: ' Success Save Customer Profile',
        modalConfirmation: true,
        loadingCustomerProfile: false,
        nextPageLink: '/Partner-Profile-Detail/detail/' + Code,
      });
      this.props.resetCustomerProfileAction();
    } else if (this.props.postCustomerProfileStatus === 'failed') {
      this.setState({
        headerAlert: ' Failed',
        contentAlert: ' Failed Save Customer Profile',
        modalConfirmation: true,
        loadingCustomerProfile: false,
      });
      this.props.resetCustomerProfileAction();
    }
    if (this.props.putCustomerProfileStatus === 'success') {
      this.setState({
        headerAlert: ' Success',
        contentAlert: ' Success Edit Customer Profile',
        modalConfirmation: true,
        loadingCustomerProfile: false,
        nextPageLink:
          '/Partner-Profile-Detail/detail/' +
          this.state.customerProfile.CompanyId,
      });
      this.getCustomerProfileId();
      this.props.resetCustomerProfileAction();
    } else if (this.props.putCustomerProfileStatus === 'failed') {
      this.setState({
        headerAlert: ' Failed',
        contentAlert: ' Failed Edit Customer Profile',
        modalConfirmation: true,
        loadingCustomerProfile: false,
      });
      this.props.resetCustomerProfileAction();
    }
    if (this.props.getUserProfileByUserIdStatus === 'success') {
      this.setState({
        resetPasswordObject: {
          ...this.state.resetPasswordObject,
          UserId: this.props.getUserProfileByUserId.UserId,
          Username: this.props.getUserProfileByUserId.UserName,
          CompanyCode: this.props.match.params.ProfileId,
        },
        loadingUserProfile: false,
      });
      this.props.resetUserAccessAction();
    } else if (this.props.getUserProfileByUserIdStatus === 'failed') {
      this.setState({
        headerAlert: ' Failed',
        contentAlert: ' The user is Failed Loaded',
        modalConfirmation: true,
      });
      this.props.resetUserAccessAction();
    }
    if (this.props.postResetPasswordStatus === 'success') {
      this.setState({
        headerAlert: ' Success',
        contentAlert: 'New Password successfully ',
        modalConfirmation: true,
        openModalResetPassword: false,
      });
      this.props.resetUserAccessAction();
    } else if (this.props.postResetPasswordStatus === 'failed') {
      this.setState({
        headerAlert: ' Failed',
        contentAlert: ' Failed Change Password',
        modalConfirmation: true,
      });
      this.props.resetUserAccessAction();
    }
    if (this.props.deleteTourOperatorStatus === 'success') {
      this.setState({
        headerAlert: ' Success',
        contentAlert: ' Success Delete Tour Operator',
        modalConfirmation: true,
        loadingCustomerProfile: false,
      });
      this.props.resetStatusDeleteTourOperatorAction();
    } else if (this.props.deleteTourOperatorStatus === 'failed') {
      this.setState({
        headerAlert: ' Failed',
        contentAlert: ' Failed Delete Tour Operator',
        modalConfirmation: true,
        loadingCustomerProfile: false,
      });
      this.props.resetStatusDeleteTourOperatorAction();
    }
  }
  setMarginTourOperator = (e, { value }) => {
    // eslint-disable-next-line
    this.props.history.push(`/System-Margin/TourOperator/${value}`);
  };

  deleteTourOperator = () => {
    const { tourOperatorId } = this.state;
    this.props.deleteTourOperatorAction(tourOperatorId);
    this.setState({
      openConfirm: false,
    });
  };

  deleteConfirmation = (e, { value, name }) => {
    this.setState({
      openConfirm: true,
      contentConfirm: 'Are you sure to delete ' + name + ' ?',
      tourOperatorId: value,
      isDelete: true,
    });
  };

  setStateCustomerProfile = data => {
    let customerProfileData = setCustomerData(data);
    this.setState({
      customerProfile: customerProfileData,
    });
  };

  buttonBack = () => {
    // eslint-disable-next-line
    this.props.history.push('/Partner-Profile');
  };
  openModalImage = () => {
    this.setState({
      openModalImage: true,
    });
  };

  modalImage = (e, { id }) => {
    if (id === 'LicensePicture') {
      this.setState({
        contentModal: 'License Picture',
        openModalImage: true,
        dataItem: [],
        imageUrl: null,
        imageTemp: {
          ImageContent: null,
          ImageName: null,
          ImagePreview: null,
        },
      });
    } else if (id === 'CompanyLogo') {
      this.setState({
        contentModal: 'Company Logo',
        openModalImage: true,
        dataItem: [],
        imageUrl: null,
        imageTemp: {
          ImageContent: null,
          ImageName: null,
          ImagePreview: null,
        },
      });
    } else {
      this.setState({
        contentModal: 'Tour Operator Images',
        openModalImage: true,
        dataItem: [],
        imageUrl: null,
        imageTemp: {
          ImageContent: null,
          ImageName: null,
          ImagePreview: null,
        },
      });
    }
  };

  onChangeImage = e => {
    e.map(base => {
      return this.setState({
        imageTemp: {
          ...this.state.imageTemp,
          ImageContent: base.base64.split(',')[1],
          ImageName: base.file.name,
          ImagePreview: URL.createObjectURL(base.file),
        },
      });
    });
  };

  handleCloseConfirm = () =>
    this.setState({ openConfirm: false, loadingCity: false });

  saveCustomer = () => {
    const { contentButton } = this.state;
    if (contentButton === 'Edit') {
      this.setState({
        contentButton: 'Save',
        disable: false,
      });
    } else {
      const err = this.validateCustomerProfile();
      if (!err) {
        this.setState({
          loadingCustomerProfile: true,
        });
        let { customerProfile, isImageUpdate, isLogoUpdate } = this.state;
        const Id = customerProfile.CompanyId;
        let data = { ...customerProfile };
        if (!isImageUpdate) {
          delete data.ImageName;
          delete data.ImageContent;
          delete data.ImagePreview;
        }
        if (!isLogoUpdate) {
          delete data.CompanyLogo;
        }

        if (Id === '') {
          this.props.postCustomerProfileAction(data);
        } else {
          this.props.putCustomerProfileAction(Id, data);
        }
      }
    }
  };

  validateCustomerProfile = () => {
    let isError = false;
    const errors = handleValidation(
      this.state.customerProfile,
      partnerProfileField
    );

    isError = !_.isEmpty(errors);

    this.setState({
      errors: {
        ...errors,
      },
    });
    return isError;
  };

  onChange = (e, { value, name }) => {
    let customerProfile = { ...this.state.customerProfile };
    customerProfile[name] = value;
    this.setState({ customerProfile: customerProfile }, () =>
      this.validateCustomerProfile()
    );
  };

  setMargin = () => {
    // eslint-disable-next-line
    this.props.history.push(
      `/System-Margin/TourOperator/${this.state.customerProfile.CompanyId}`
    );
  };

  addImage = () => {
    let { contentModal, imageTemp } = this.state;
    let { ImageContent, ImageName, ImagePreview } = imageTemp;
    if (contentModal === 'License Picture') {
      return this.setState({
        customerProfile: {
          ...this.state.customerProfile,
          ImageContent,
          ImageName,
          ImagePreview,
        },
        openModalImage: false,
        isImageUpdate: true,
      });
    } else if (contentModal === 'Company Logo') {
      return this.setState({
        customerProfile: {
          ...this.state.customerProfile,
          CompanyLogo: {
            ImageContent,
            ImageName,
            ImagePreview,
          },
        },
        openModalImage: false,
        isLogoUpdate: true,
      });
    }
  };

  balanceDetail = () => {
    // eslint-disable-next-line
    this.props.history.push(
      `/Balance-Detail/${this.state.customerProfile.CompanyId}`
    );
  };

  // validate = () => {
  //   let isError = false;
  //   const errors = handleValidation(
  //     this.state.customerProfile,
  //     customerProfileField
  //   );
  //   isError = !_.isEmpty(errors);
  //   this.setState({
  //     errors: {
  //       ...errors,
  //     },
  //   });
  //   return isError;
  // };

  handleCloseResetPassword = () =>
    this.setState({
      openModalResetPassword: false,
    });

  handleChangeResetPassword = (e, { value, name }) => {
    const resetPasswordObject = this.state.resetPasswordObject;
    resetPasswordObject[name] = value;
    this.setState({ resetPasswordObject: resetPasswordObject });
    // this.validate();
  };

  onClickPassIcon = (e, { name }) => {
    let icon = `${name}Icon`;
    let show = `${name}Show`;
    if (this.state[show]) {
      this.setState({
        [icon]: 'eye slash',
        [show]: false,
        typePassword: 'password',
      });
    } else {
      this.setState({
        [icon]: 'eye',
        [show]: true,
        typePassword: 'text',
      });
    }
  };
  onClickConfirmPassIcon = (e, { name }) => {
    let icon = `${name}Icon`;
    let show = `${name}Show`;
    if (this.state[show]) {
      this.setState({
        [icon]: 'eye slash',
        [show]: false,
        typeConfirmPassword: 'password',
      });
    } else {
      this.setState({
        [icon]: 'eye',
        [show]: true,
        typeConfirmPassword: 'text',
      });
    }
  };
  // resetPassword = () => {
  resetPassword = (e, { id }) => {
    this.props.getUserProfileByUserIdAction(id);
    this.setState({
      resetPasswordObject: {
        ...this.state.resetPasswordObject,
      },
      openModalResetPassword: true,
    });
  };

  buttonModalEmployee = () => {
    let link = `/Employee/create/${this.state.customerProfile.CompanyId}/0`;
    this.setState({ nextPage: true, gotoLink: link });
  };

  validate = () => {
    let isError = false;
    const { resetPasswordObject } = this.state;
    let error1 = handleValidation(resetPasswordObject, resetPasswordField);
    let errors = { ...error1 };
    isError = !_.isEmpty(errors);
    this.setState({
      errors: {
        ...errors,
      },
    });
    return isError;
  };

  saveResetPassword = () => {
    const err = this.validate();
    let data = this.state.resetPasswordObject;
    if (!err) {
      this.props.postResetPasswordAction(data);
    }
  };

  editUser = (e, { id }) => {
    const { ProfileId } = this.props.match.params;
    let link = `/Employee/Edit/${ProfileId}/${id}`;
    this.setState({ nextPage: true, gotoLink: link });
  };

  handleConfirmUnlock = message => {
    this.setState({
      openConfirm: true,
      contentConfirm: message,
    });
  };

  unlockConfrimation = (e, { id }) => {
    this.handleConfirmUnlock('Are you sure to unlock this user ?  ');
    this.setState({ idToUnlockUser: id });
  };

  handleAlert = (headerAlert, contentAlert) => {
    this.setState({
      modalConfirmation: true,
      headerAlert: headerAlert,
      contentAlert: contentAlert,
    });
  };

  unlockUser = () => {
    const { idToUnlockUser } = this.state;
    this.props
      .postUnlockUserAction(idToUnlockUser)
      .then(res => {
        this.handleCloseConfirm();
        this.handleAlert('Success', res.value.data);
      })
      .catch(err => {
        this.handleCloseConfirm();
        this.handleAlert('Failed', err.response.data.Message);
      });
  };

  handleButtonConfirm = () => {
    const { idToUnlockUser } = this.state;
    if (idToUnlockUser) {
      this.unlockUser();
    } else {
      this.deleteTourOperator();
    }
    // console.log(idToUnlockUser);
    // deleteTourOperator
  };

  render() {
    let {
      modalConfirmation,
      openConfirm,
      contentConfirm,
      nextPage,
      // operatorId,
      // Type,
      passwordIcon,
      typePassword,
      confirmPasswordIcon,
      typeConfirmPassword,
      gotoLink,
    } = this.state;
    const {
      loadingTourOperator,
      loadingCustomer,
      loadingUserStore,
    } = this.props;
    let loading = loadingTourOperator || loadingCustomer || loadingUserStore;
    // let { ProfileId } = this.props.match.params;
    if (nextPage) {
      // eslint-disable-next-line
      return (
        <Redirect
          // to={`/tour-operator-detail/${Type}/${ProfileId}/${operatorId}`}
          to={gotoLink}
        />
      );
    }
    return (
      <div>
        <PartnerProfile
          contentDetail={this.state.customerProfile}
          buttonBack={this.buttonBack}
          balanceDetail={this.balanceDetail}
          setMargin={this.setMargin}
          numberEmploye={this.props.getCustomEnumByCatagoriesTotalEmploye}
          revenueRange={this.props.getCustomEnumByCatagoriesRevenueRange}
          branch={this.props.getCustomEnumByCatagoriesTotalBranch}
          currencies={this.props.getCurrencies}
          handleChange={this.onChange}
          disable={this.state.disable}
          buttonEdit={this.saveCustomer}
          contentButton={this.state.contentButton}
          listCountry={this.props.listCountry}
          getPaymentSchemeList={this.props.getPaymentSchemeList}
          errorInformation={this.state.errors}
          saveCustomer={this.saveCustomer}
          modalImage={
            this.modalImage //handleCheckClick={this.handleCheckClick}
          }
          listCompanyBussiness={this.state.CompanyBussiness}
          tabMenu={this.state.TabMenu}
          loadingCustomerProfile={loading}
          isPartner={true}
          isFinance={this.props.isFinance}
        />
        <ModalAlert
          openModal={modalConfirmation}
          handleClose={this.handleClose}
          header={this.state.headerAlert}
          content={this.state.contentAlert}
          nameButton="OK"
        />
        <ModalImage
          openModal={this.state.openModalImage}
          handleClose={this.handleCloseModalImage}
          content={this.state.contentModal}
          imagePreview={this.state.imageUrl}
          handleChange={this.onChangeImage}
          addImage={this.addImage}
        />

        <ModalConfirm
          openModal={openConfirm}
          size="mini"
          close={this.handleCloseConfirm}
          confirm={this.handleButtonConfirm}
          message={contentConfirm}
          header="Confirmation"
        />
        <ModalResetPassword
          openModal={this.state.openModalResetPassword}
          header="Reset Password"
          handleClose={this.handleCloseResetPassword}
          nameButton="Oke"
          contentDetail={this.state.resetPasswordObject}
          CompanyCode={this.state.customerProfile.CompanyId}
          handleChange={this.handleChangeResetPassword}
          saveResetPassword={this.saveResetPassword}
          errors={this.state.errors}
          loading={loading}
          onClickPassIcon={this.onClickPassIcon}
          onClickConfirmPassIcon={this.onClickConfirmPassIcon}
          passwordIcon={passwordIcon}
          typePassword={typePassword}
          confirmPasswordIcon={confirmPasswordIcon}
          typeConfirmPassword={typeConfirmPassword}
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    getCustomerProfileByCode:
      state.CustomerProfileStore.getCustomerProfileByCode,
    getCustomerProfileByCodeStatus:
      state.CustomerProfileStore.getCustomerProfileByCodeStatus,
    getCustomEnumByCatagoriesTotalEmploye: state.CustomEnumReducerStore.getCustomEnumByCatagoriesTotalEmploye.map(
      type => {
        return { text: type.Value, value: type.Id, key: type.Id };
      }
    ),
    getCustomEnumByCatagoriesTotalBranch: state.CustomEnumReducerStore.getCustomEnumByCatagoriesTotalBranch.map(
      type => {
        return { text: type.Value, value: type.Id, key: type.Id };
      }
    ),
    getCustomEnumByCatagoriesRevenueRange: state.CustomEnumReducerStore.getCustomEnumByCatagoriesRevenueRange.map(
      type => {
        return { text: type.Value, value: type.Id, key: type.Id };
      }
    ),
    getCurrencies: state.currenciesStore.currencies.map(type => {
      return { text: type.Description, value: type.Id, key: type.Id };
    }),
    listCountry: state.CountryStore.getCountryList.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    getPaymentSchemeList: state.CustomerProfileStore.getPaymentSchemeList.map(
      type => {
        return { text: type.Description, value: type.Id, key: type.Id };
      }
    ),
    getTourOperatorByCodeStatus: state.PackageStore.getTourOperatorByCodeStatus,
    tourOperatorListByCode: state.PackageStore.tourOperatorListByCode
      ? state.PackageStore.tourOperatorListByCode
      : [],
    detailTourOperatorById: state.PackageStore.detailTourOperatorById,
    getTourOperatorProfileByIdStatus:
      state.PackageStore.getTourOperatorProfileByIdStatus,
    deleteTourOperatorStatus: state.PackageStore.deleteTourOperatorStatus,
    listCity: state.CityStore.getCity.map(type => {
      return {
        text: type.Name,
        value: type.Id,
        key: type.Id,
        region: type.Region.Id,
      };
    }),
    loadingTourOperator: state.PackageStore.loading,
    loadingCustomer: state.CustomerProfileStore.loading,
    putCustomerProfileStatus:
      state.CustomerProfileStore.putCustomerProfileStatus,
    postCustomerProfileStatus:
      state.CustomerProfileStore.postCustomerProfileStatus,
    postCustomerProfile: state.CustomerProfileStore.postCustomerProfile,
    listPartnerEmployee: state.PartnerProfile.listPartnerEmployee,
    getUserDataByCode: state.CustomerProfileStore.getUserDataByCode,
    loadingUserStore: state.UserAccessStore.loading,
    getUserProfileByUserId: state.UserAccessStore.getUserProfileByUserId,
    getUserProfileByUserIdStatus:
      state.UserAccessStore.getUserProfileByUserIdStatus,
    postResetPasswordStatus: state.UserAccessStore.postResetPasswordStatus,
    isFinance: state.UserAccessStore.isFinance,
  };
}

export default connect(mapStateToProps, {
  resetCustomerProfileAction,
  getCustomerProfileByCodeAction,
  putCustomerProfileAction,
  resetCustomEnumAction,
  getCustomEnumByCatagoriesRevenueRangeAction,
  getCustomEnumByCatagoriesTotalBranchAction,
  getCustomEnumByCatagoriesTotalEmployeAction,
  getCurrenciesAction,
  getCountryListAction,
  getPaymentSchemeAction,
  postCustomerProfileAction,
  getTourOperatorProfileByCodeAction,
  resetGetTourOperatorByCodeAction,
  deleteTourOperatorAction,
  resetStatusDeleteTourOperatorAction,
  getCityAction,
  getPartnerEmployeeAction,
  getUserDataByCodeAction,
  getUserProfileByUserIdAction,
  resetUserAccessAction,
  postUnlockUserAction,
  postResetPasswordAction,
  //==================================
  // deleteImageTourOperatorAction,
})(customerProfileDetailPage);
