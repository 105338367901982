import React from 'react';
import { Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const TotalDataTableLabel = props => {
  return (
    <div style={{ margin: 'auto' }}>
      {props.pagedData &&
        props.pagedData.length > 1 && (
          <Header as="h5">
            {`Showing ${props.numbersTo} to ${props.numbersFrom} of ${
              props.data.length
            }`}
          </Header>
        )}
    </div>
  );
};
TotalDataTableLabel.propTypes = {
  pagedData: PropTypes.array,
  numbersTo: PropTypes.number,
  numbersFrom: PropTypes.number,
  data: PropTypes.array,
};

export default TotalDataTableLabel;
