import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { Modal, Button, Grid, Input, Radio } from 'semantic-ui-react';
const ModalAcceptRoom = props => {
  const {
    openModal,
    size,
    // confirm,
    close,
    nameConfirm,
    contentOk,
    contentNo,
    header,
    requestItemId,
    roomName,
    qty,
    handleConfirm,
    pending,
  } = props;

  const messageConfirm = `Please Input Confirmation Number for
Request Item ID: ${requestItemId}.
${roomName} ${qty} Room`;

  const [check, setCheck] = useState('noNeed');
  const [confirmationNumber, setConfirmationNumber] = useState('');
  const [error, setError] = useState(false);

  const handleClose = () => {
    setCheck('noNeed');
    setConfirmationNumber('');
    setError(false);
    close();
  };

  const handleChange = (e, { value }) => {
    setCheck(confirmationNumber !== '' || value !== '' ? 'need' : '');
    setError(check === 'need' && value === '' ? true : false);
    setConfirmationNumber(value);
  };

  const handleRadioButton = (e, { value }) => {
    if (value === 'noNeed') {
      setError(false);
      setConfirmationNumber('');
    } else {
      setError(true);
    }
    setCheck(value);
  };

  const handleConfirmButton = () => {
    if (check === '') {
      setError(true);
    } else if (!error) {
      let data = {
        RequestItemId: requestItemId,
        ActionType: 0,
        ConfirmationNumber: check === 'need' ? confirmationNumber : null,
        ConfirmationNote: '',
      };
      handleClose();
      handleConfirm(data);
    }
  };

  return (
    <Modal
      size={size ? size : 'mini'}
      open={openModal}
      onClose={handleClose}
      closeOnDimmerClick={true}
      dimmer="blurring"
    >
      <Modal.Header>{header ? header : 'Confirmation Number'}</Modal.Header>
      <Modal.Content>
        <Grid>
          <Grid.Row style={styles.paddingRow}>
            <Grid.Column>
              <h5>{messageConfirm}</h5>
            </Grid.Column>
          </Grid.Row>
          {!pending && (
            <React.Fragment>
              <Grid.Row style={styles.paddingRow}>
                <Grid.Column>
                  <Radio
                    name="isNumberNeed"
                    value="noNeed"
                    label="No Confirmation Number Needed"
                    onClick={handleRadioButton}
                    checked={check === 'noNeed'}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={styles.paddingRow}>
                <Grid.Column>
                  <Radio
                    name="isNumberNeed"
                    value="need"
                    label="Input Confirmation Number"
                    onClick={handleRadioButton}
                    checked={check === 'need'}
                  />
                </Grid.Column>
              </Grid.Row>
            </React.Fragment>
          )}
          <Grid.Row style={styles.paddingRow}>
            <Grid.Column>
              <Input
                placeholder="Confirmation Number"
                fluid
                disabled={check === 'noNeed'}
                name="confirmationNumber"
                value={confirmationNumber}
                onChange={handleChange}
                error={error}
              />
              {error && (
                <span style={styles.bgInfo}>Field can not be empty</span>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions style={styles.alignCenter}>
        <Button
          style={styles.buttonBasicWidth}
          basic
          color="red"
          name={nameConfirm}
          content={contentOk ? contentOk : 'Close'}
          onClick={handleClose}
        />
        <Button
          positive
          content={contentNo ? contentNo : 'Confirm'}
          onClick={handleConfirmButton}
        />
      </Modal.Actions>
    </Modal>
  );
};

ModalAcceptRoom.propTypes = {
  openModal: PropTypes.bool,
  size: PropTypes.string,
  close: PropTypes.func,
  confirm: PropTypes.func,
  message: PropTypes.string,
  nameConfirm: PropTypes.string,
  contentOk: PropTypes.string,
  contentNo: PropTypes.string,
  header: PropTypes.string,
  requestItemId: PropTypes.string,
  roomName: PropTypes.string,
  qty: PropTypes.string,
  handleConfirm: PropTypes.func,
  pending: PropTypes.bool,
};
export default ModalAcceptRoom;
