import {
  GET_CUSTOM_ENUM_BY_CATAGORIES_REVENUE_RANGE,
  GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_BRANCH,
  RESET_CUSTOM_ENUM,
  GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_EMPLOYE,
  GET_PAYMENT_METHOD,
  GET_PAYMENT_METHOD_BY_COUNTRY_ID,
  GET_PAYMENT_METHOD_CHOICE_LIST,
  POST_TOP_UP_PAYMENT_GATEWAY,
} from '../../../actions/actionTypes';
import getCustomEnumByCatagoriesRevenueRangeApi from '../../../api/customEnum/getCustomEnumByCatagoriesRevenueRangeApi';
import getCustomEnumByCatagoriesTotalBranchApi from '../../../api/customEnum/getCustomEnumByCatagoriesTotalBranchApi';
import getCustomEnumByCatagoriesTotaEmployeApi from '../../../api/customEnum/getCustomEnumByCatagoriesTotaEmployeApi';
import getPaymentMethodApi from '../../../api/customEnum/getPaymentMethodApi';

import getPaymentMethodByCountryIdApi from '../../../api/customEnum/getPaymentMethodByCountryIdApi';
import getPaymentMethodChoiceListApi from '../../../api/customEnum/getPaymentMethodChoiceListApi';
import postTopUpPaymentGatewayApi from '../../../api/manualPayment/postTopUpPaymentGatewayApi';

const getCustomEnumByCatagoriesRevenueRangeAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_CUSTOM_ENUM_BY_CATAGORIES_REVENUE_RANGE,
      payload: getCustomEnumByCatagoriesRevenueRangeApi,
    });
  };
};
const getCustomEnumByCatagoriesTotalBranchAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_BRANCH,
      payload: getCustomEnumByCatagoriesTotalBranchApi,
    });
  };
};

const getCustomEnumByCatagoriesTotalEmployeAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_CUSTOM_ENUM_BY_CATAGORIES_TOTAL_EMPLOYE,
      payload: getCustomEnumByCatagoriesTotaEmployeApi,
    });
  };
};
const resetCustomEnumAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_CUSTOM_ENUM,
    });
  };
};

const getPaymentMethodAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_PAYMENT_METHOD,
      payload: getPaymentMethodApi,
    });
  };
};

const getPaymentMethodByCountryIdAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_PAYMENT_METHOD_BY_COUNTRY_ID,
      payload: getPaymentMethodByCountryIdApi(Id),
    });
  };
};

const getPaymentMethodChoiceListAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_PAYMENT_METHOD_CHOICE_LIST,
      payload: getPaymentMethodChoiceListApi,
    });
  };
};

const postTopUpPaymentGatewayAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_TOP_UP_PAYMENT_GATEWAY,
      payload: postTopUpPaymentGatewayApi(data),
    });
  };
};

export {
  getCustomEnumByCatagoriesRevenueRangeAction,
  getCustomEnumByCatagoriesTotalEmployeAction,
  getCustomEnumByCatagoriesTotalBranchAction,
  getPaymentMethodAction,
  resetCustomEnumAction,
  getPaymentMethodByCountryIdAction,
  getPaymentMethodChoiceListAction,
  postTopUpPaymentGatewayAction,
};
