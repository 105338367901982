import * as types from '../actions/actionTypes';

const initialState = {
  listPendingCompanyApproval: [],
  loading: false,
  errors: {},
  pendingCompanyStatus: '',
  postCompanyStatus: '',
  loadingGetCompanyCommissions: false,
  companyCommissions: [],
  loadingGetCompanyCommissionById: false,
  companyCommission: null,
  loadingPostCompanyCommission: false,
  loadingPutCompanyCommission: false,
  loadingDeleteCommission: false,
  getCompanyProfile: {},
  getCompanyProfileStatus: '',
  loadingGetCompanies: false,
  companies: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PENDING_COMPANY_FOR_APPROVAL:
      return { ...state };
    case types.GET_PENDING_COMPANY_FOR_APPROVAL_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        pendingCompanyStatus: 'failed',
      };
    case types.GET_PENDING_COMPANY_FOR_APPROVAL_PENDING:
      return { ...state, loading: true };
    case types.GET_PENDING_COMPANY_FOR_APPROVAL_FULFILLED:
      return {
        ...state,
        listPendingCompanyApproval: action.payload.data,
        loading: false,
        pendingCompanyStatus: 'success',
      };
    case types.POST_ACCEPT_COMPANY_REGISTRATION:
      return { ...state };
    case types.POST_ACCEPT_COMPANY_REGISTRATION_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        postCompanyStatus: 'failed',
      };
    case types.POST_ACCEPT_COMPANY_REGISTRATION_PENDING:
      return { ...state, loading: true };

    case types.POST_ACCEPT_COMPANY_REGISTRATION_FULFILLED:
      return {
        ...state,
        loading: false,
        postCompanyStatus: 'success',
      };
    case types.DELETE_COMPANIES_BY_ID:
      return { ...state };
    case types.DELETE_COMPANIES_BY_ID_PENDING:
      return { ...state, loading: true };
    case types.DELETE_COMPANIES_BY_ID_REJECTED:
      return {
        ...state,
        errors: action.payload.response.data,
        loading: false,
        deleteCompanyStatus: 'failed',
        deleteCompany: {},
      };
    case types.DELETE_COMPANIES_BY_ID_FULFILLED:
      return {
        ...state,
        loading: false,
        postCompanyStatus: 'success',
        deleteCompany: action.payload.data,
      };
    case types.GET_COMPANY_COMMISSIONS:
      return { ...state };
    case types.GET_COMPANY_COMMISSIONS_PENDING:
      return { ...state, loadingGetCompanyCommissions: true };
    case types.GET_COMPANY_COMMISSIONS_REJECTED:
      return {
        ...state,
        errors: action.payload.response.data,
        loadingGetCompanyCommissions: false,
        companyCommissions: [],
      };
    case types.GET_COMPANY_COMMISSIONS_FULFILLED:
      return {
        ...state,
        loadingGetCompanyCommissions: false,
        companyCommissions: action.payload.data,
      };
    case types.GET_COMPANY_COMMISSION_BY_ID:
      return { ...state };
    case types.GET_COMPANY_COMMISSION_BY_ID_PENDING:
      return { ...state, loadingGetCompanyCommissionById: true };
    case types.GET_COMPANY_COMMISSION_BY_ID_REJECTED:
      return {
        ...state,
        errors: action.payload.response.data,
        loadingGetCompanyCommissionById: false,
        companyCommission: null,
      };
    case types.GET_COMPANY_COMMISSION_BY_ID_FULFILLED:
      return {
        ...state,
        loadingGetCompanyCommissionById: false,
        companyCommission: action.payload.data,
      };
    case types.PUT_COMPANY_COMMISSION:
      return { ...state };
    case types.PUT_COMPANY_COMMISSION_PENDING:
      return { ...state, loadingPutCompanyCommission: true };
    case types.PUT_COMPANY_COMMISSION_REJECTED:
      return {
        ...state,
        errors: action.payload.response.data,
        loadingPutCompanyCommission: false,
        companyCommission: null,
      };
    case types.PUT_COMPANY_COMMISSION_FULFILLED:
      return {
        ...state,
        loadingPutCompanyCommission: false,
        companyCommission: action.payload.data,
      };
    case types.POST_COMPANY_COMMISSION:
      return { ...state };
    case types.POST_COMPANY_COMMISSION_PENDING:
      return { ...state, loadingPostCompanyCommission: true };
    case types.POST_COMPANY_COMMISSION_REJECTED:
      return {
        ...state,
        errors: action.payload.response.data,
        loadingPostCompanyCommission: false,
        companyCommission: null,
      };
    case types.POST_COMPANY_COMMISSION_FULFILLED:
      return {
        ...state,
        loadingPostCompanyCommission: false,
        companyCommission: action.payload.data,
      };
    case types.DELETE_COMPANY_COMMISSIONS:
      return { ...state };
    case types.DELETE_COMPANY_COMMISSIONS_PENDING:
      return { ...state, loadingDeleteCommission: true };
    case types.DELETE_COMPANY_COMMISSIONS_REJECTED:
      return {
        ...state,
        errors: action.payload.response.data,
        loadingDeleteCommission: false,
      };
    case types.DELETE_COMPANY_COMMISSIONS_FULFILLED:
      return {
        ...state,
        loadingDeleteCommission: false,
      };
    //
    case types.GET_COMPANY_PROFILE:
      return { ...state };
    case types.GET_COMPANY_PROFILE_PENDING:
      return { ...state };
    case types.GET_COMPANY_PROFILE_REJECTED:
      return {
        ...state,
        errors: action.payload.response.data,
      };
    case types.GET_COMPANY_PROFILE_FULFILLED:
      return {
        ...state,
        getCompanyProfile: action.payload.data,
        getCompanyProfileStatus: 'success',
      };
    //
    case types.RESET_STATUS_COMPANY:
      return {
        ...state,
        pendingCompanyStatus: '',
        postCompanyStatus: '',
      };
    case types.GET_COMPANIES_BY_TYPE:
      return { ...state };
    case types.GET_COMPANIES_BY_TYPE_PENDING:
      return { ...state, loadingGetCompanies: true };
    case types.GET_COMPANIES_BY_TYPE_REJECTED:
      return {
        ...state,
        errors: action.payload.response.data,
        loadingGetCompanies: false,
        companies: [],
      };
    case types.GET_COMPANIES_BY_TYPE_FULFILLED:
      return {
        ...state,
        loadingGetCompanies: false,
        companies: action.payload.data,
      };
    default:
      return state;
  }
};

export default reducer;
