const setDataImage = (state, post, bookingTemplateId) => {
  let data = {};
  post
    ? (data = {
        ImageUrl: state.ImageUrl,
        ImageType: 65,
        BookingTemplateId: bookingTemplateId,
        ImageName: state.ImageName,
        ImageContent: state.ImageContent,
      })
    : (data = {
        ImageUrl: state.ImageUrl,
        ImageType: 65,
        BookingTemplateId: bookingTemplateId,
        ImageName: state.ImageName,
        ImageContent: state.ImageContent,
      });

  return data;
};

export default setDataImage;
