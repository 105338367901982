import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Header,
  Divider,
  Segment,
  Button,
  Label,
  Icon,
} from 'semantic-ui-react';
import Style from '../../../resourceBooking/Styles';
import DescriptionDetailBooking from '../../../shared/components/modal/modalPayment/bookingDetailInformation';
import { generateForComponentBookingDetailInformation } from '../scripts/generateData';
import convertTourPrice from '../../../home/services/data/setTourPrice';
import PaymentTermDetailBooking from '../../../tourTransaction/transactionDetail/components/table/TablePaymentTermTourInDetailBooking';
import TablePackageDetail from '../../../../components/table/tablePackageDetail';
import TablePaymentHistory from './table/TablePaymentHistory';
import { initialAdditionalServices } from '../../../../pages/shared/services/additionalServices/processData';
const SegmentTransactionDetailForFinance = props => {
  const {
    contentDetail,
    handleOpenModalPay,
    handleBack,
    loading,
    getTourTransactionBills,
    listHistoryTransaction,
    handleClickDownloadInvoice,
  } = props;
  let isFixedPackage =
    contentDetail && contentDetail.BookingDetailSum.PackageType === 'Fixed';
  let tourPriceSum = contentDetail
    ? convertTourPrice(contentDetail.TourPriceSum)
    : [];
  let generalInformation = generateForComponentBookingDetailInformation(
    contentDetail
  );
  let supplements =
    isFixedPackage && contentDetail ? contentDetail.TransactionSupplements : [];
  let commissions =
    isFixedPackage && contentDetail
      ? contentDetail.BookingDetailSum.FixedPackage.BookingCommission
          .ApplicableCommission
      : [];
  let splitCommission =
    contentDetail && contentDetail.BookingDetailSum.IsSplitStaffCommission;
  let additionalItems = contentDetail ? contentDetail.AdditionalItems : [];
  let additionalServices = contentDetail
    ? initialAdditionalServices(contentDetail.AdditionalServices)
    : [];
  return (
    <Segment loading={loading} style={{ marginTop: '30px' }}>
      <Label
        as="h3"
        size="huge"
        style={{
          position: 'absolute',
          top: '-25px',
          background: '#38af95',
          color: 'white',
        }}
      >
        <Icon name="clipboard list" /> Tour Transaction
      </Label>
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column style={{ marginTop: '25px' }}>
            <Header as="h4" content="General Information" />
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Button
              style={Style.BorderBlueBackgrounWhite}
              content="Back"
              size="small"
              onClick={handleBack}
            />
            <Button
              primary
              content="Pay"
              size="small"
              onClick={handleOpenModalPay}
              disabled={
                generalInformation.Status === 'Booking_Confirmed' ||
                generalInformation.Status === 'Booking_hold' ||
                generalInformation.Status === 'Booking_Completed' ||
                generalInformation.Status === 'Booking_Cancelled'
                  ? true
                  : false
              }
            />
            <Button
              icon="download"
              content="Agent Invoice"
              primary
              onClick={handleClickDownloadInvoice}
              size="small"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <DescriptionDetailBooking contentDetail={generalInformation} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header as="h4" content="Tour Price" />
          </Grid.Column>
          <Grid.Column width={16}>
            <Divider style={{ borderColor: 'black' }} />
          </Grid.Column>
          <Grid.Column width={16}>
            <TablePackageDetail
              tourPriceSum={tourPriceSum}
              isFixedPackage={isFixedPackage}
              totalTourPrice={generalInformation.TourPrice}
              currency={generalInformation.CurrencyId}
              supplements={supplements}
              commissions={commissions}
              totalPayable={generalInformation.TourPrice}
              splitCommission={splitCommission}
              additionalItem={additionalItems}
              additionalServices={additionalServices}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid.Column style={{ marginTop: '10px' }}>
        <Grid.Row>
          <Grid.Column width={16} style={{ marginTop: '25px' }}>
            <Header as="h4" content="Payment Terms" />
          </Grid.Column>
        </Grid.Row>
        <Divider style={{ borderColor: 'black' }} />
        <PaymentTermDetailBooking
          listData={getTourTransactionBills}
          Currency={
            contentDetail ? contentDetail.BookingDetailSum.CurrencyId : ''
          }
        />
        <Grid.Row>
          <Grid.Column width={16} style={{ marginTop: '25px' }}>
            <Header as="h4" content="Payment History" />
          </Grid.Column>
        </Grid.Row>
        <Divider style={{ borderColor: 'black' }} />
        <Grid.Row>
          <Grid.Column width={16}>
            <TablePaymentHistory
              listHistoryTransaction={listHistoryTransaction}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid.Column>
    </Segment>
  );
};

SegmentTransactionDetailForFinance.propTypes = {
  contentDetail: PropTypes.object,
  handleOpenModalPay: PropTypes.func,
  handleBack: PropTypes.func,
  loading: PropTypes.bool,
  getPayableSupplemetItem: PropTypes.array,
  getSpecialAdjusmentByTransactionId: PropTypes.array,
  getTourTransactionBills: PropTypes.array,
  listHistoryTransaction: PropTypes.array,
  disable: PropTypes.bool,
  handleClickDownloadInvoice: PropTypes.func,
};

export default SegmentTransactionDetailForFinance;
