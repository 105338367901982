const pagination = (data, perPage) => {
  //    let sets = [];

  let chunks = data.length / perPage;

  const page = (i, j, datas) => {
    let dataSet = [...datas];
    if (i < chunks) {
      let x = data.slice(j, j + perPage);
      dataSet = [...dataSet, x];
      return page(i + 1, j + perPage, dataSet);
    } else {
      return dataSet;
    }
  };

  const dataSets = page(0, 0, []);

  return dataSets;
};

export default pagination;
