const partnerProfileField = [
  'Name',
  'Address',
  'Telephone',
  'CountryId',
  'EmployeeRangeId',
  'BranchRangeId',
  'RevenueRangeId',
  'CurrencyId',
  'Email',
  'FaxNbr',
  // 'IATANbr',
  // 'AssociationNumber',
];

const resetPasswordField = ['NewPassword', 'ConfirmPassword'];

export { partnerProfileField, resetPasswordField };
