import React from 'react';
import { connect } from 'react-redux';
import { Segment, Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  GeneralInfoPackage,
  TabPricePackage,
  TabCommissionPackage,
  TabPaymentPackage,
  TabAddittionalItemPacakge,
  TabDailyProgram,
} from './components/createFixedPackage/';
// import {
//   setAdditionalItem,
//   setAdditionalPrice,
// } from '../../scripts/additionalItem';
import {
  setRoomAllocation,
  // getNowPaymentTerm,
  // getPaymentTerms,
} from '../../components/genericFunction/convertPackage';
import {
  getActivityListAction,
  resetActivityListAction,
  getTourOperatorProfileAction,
} from '../../actions/packageAction';
import {
  getCityInCountry,
  resetCityAction,
} from '../shared/actions/cityAction';
import { getCurrenciesAction } from '../../actions/currenciesAction';
import {
  getTourTypeAction,
  getTourOperatorByIdAction,
} from '../../actions/tourTransactionAction';
import {
  getSupplementItemByFixedPackageIdAction,
  resetStatusTransactionAction,
  getInvoiceByCompanyCodeAction,
  getPrintPaymentReceiptTransactionAction,
} from '../../actions/tourTransactionAction';
// import { initialSetReadyPackage } from '../../scripts/itineraryBuilder/dailyProgram';
import { setAccommodationPackageManagement } from '../../scripts/itineraryBuilder/demoPrice';
import { getAirportAction } from '../../actions/airportAction';
// import { changeGuest } from '../../scripts/itineraryBuilder/guest';
import SegmentDestinationsSummary from '../shared/components/tourTransaction/destinationSummary/SegmentDestinationsSummary';
import Loader from '../../components/loader/loader';
import { getFixedPackage } from '../../actions/packageAction';
import AllButtonAction from './components/createFixedPackage/button/AllButtonSeriesPackage';
import TabTourTransaction from '../../components/tab/TabMenuOnlyHeader';
import {
  initialGeneralInfo,
  initialGeneralInfoVariableDate,
} from '../../scripts/convertFixedPackageManagement';
import {
  setOperator,
  setPrice,
  setCost,
  setBrochure,
  setPackageCostFunc,
} from '../../scripts/packageManagement/fixedPackageFunc';
import {
  initialSetFixedPackage,
  fixDateAfterChangeStartDate,
} from '../../scripts/packageManagement/dailyProgramFixed';
import { generateDailyProgramFixed } from '../../scripts/packageManagement/dailyProgramFixed';
import moment from 'moment';
import {
  getTotalDays,
  changeTime,
  addDate,
  convertDateFormat,
  diffDate,
} from '../../scripts/moment';
import getPackageTransactionHistory from '../../actions/packageTransactionHistoryAction';
import TabTransactionHistory from '../shared/components/tab/TabTransactionHistory';
import {
  inputValidation,
  // createAccommodationArray,
  // createArrayMovements,
  // inputValidationVariableDate,
} from './services/validation/validationSeriesPackage';
import HeaderTitle from '../../components/common/headerTitle';
import styles from './styles';
import { menus } from './constant/menus';
import defaultCommission from './constant/defaultCommission';
import {
  changeDepartureReturn,
  findArrivalDeparture,
  generateDailyProgram,
  generateHeadline,
} from '../../scripts/itineraryBuilderCreateFixedPackage/dailyProgramFixedPackage';
import { ARRIVAL } from '../shared/components/tourTransaction/dailyProgram/constant/activityType';
import { getPlaceByIdAction } from './../place/placeAction';
// import TabPeriodVariableDate from './components/createFixedPackage/variableDate/TabPeriodVariableDate';

const FILL = 'Please fill ';
const CHOOSE = 'Please choose ';
// const CHECK = 'Please check ';

// const menus = [
//   { value: 'price', title: 'Price' },
//   { value: 'commision', title: 'Commision' },
//   { value: 'paymentTerms', title: 'Payment Terms' },
//   { value: 'additionalItem', title: 'Additional Item' },
//   { value: 'accommodation', title: 'Accommodation' },
//   { value: 'dialyProgram', title: 'Daily Program' },
//   { value: 'transactionHistory', title: 'Transaction History' },
// ];
// const defaultComission = [
//   {
//     Description: '',
//     Value: 0,
//     Category: 'Agent_Commission',
//   },
//   {
//     Description: '',
//     Value: 0,
//     Category: 'Staff_Commission',
//   },
// ];
// let TODAY = moment(new Date()).format('YYYY-MM-DD');
class FixedPackageDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPackage: {
        type: '',
        id: 0,
        status: '',
        disabledEdit: false,
      },
      supplements: [],
      tourInformation: {
        tourName: '',
        firstDestination: '',
        groupCapacity: 'SMALL',
        tourType: '',
        tourCategory: '',
        description: '',
        expiredDate: convertDateFormat(new Date(), 'YYYY-MM-DDTHH:mm:ss'),
        startDate: convertDateFormat(new Date(), 'YYYY-MM-DDTHH:mm:ss'),
        endDate: convertDateFormat(new Date(), 'YYYY-MM-DDTHH:mm:ss'),
        graceDate: convertDateFormat(new Date(), 'YYYY-MM-DDTHH:mm:ss'),
        // expiredDate: '',
        // startDate: '',
        // endDate: '',
        currency: '',
        minGuest: '',
        maxGuest: '',
        // graceDate: '',
        referenceId: '',
        minPax: '',
        BookingTemplateSharedPaxId: 0,
        destination: '',
        isRoomAllotmetNeeded: false,
        isFeatured: false,
      },
      guests: { adults: 1, childs: 0, infants: 0 },
      guestList: [],
      price: {},
      headlineProgram: {
        Departures: [],
        MainPrograms: [],
        Returns: [],
      },
      errors: {
        firstName: { error: false, message: '' },
        lastName: { error: false, message: '' },
        identityNbr: { error: false, message: '' },
        identityType: { error: false, message: '' },
        guestTitle: { error: false, message: '' },
        countryId: { error: false, message: '' },
        summaryProgramErrorrs: [],
      },
      dailyProgram: [],
      roomAllocation: {
        ChildExtraBedQty: 0,
        ChildSharingRoomQty: 0,
        ChildSingleRoomQty: 0,
        ExtraBedQty: 0,
        BabyCrib: 0,
        NoBed: 0,
        SharingBedQty: 0,
        SharingRoomQty: 1,
        SingleRoomQty: 0,
      },
      tourOperator: {
        TourOperator: '',
        BaseMarginPercentage: 0,
        TourOperatorMarginPercentage: 0,
        IsTourOperatorFixedPackage: false,
      },
      firstDestination: null,
      accommodationChangeIndex: null,
      paymentTerm: [],
      invoiceList: [],
      // requestDemoPrice: {},
      demoPrice: null,
      availablePax: 0,
      packageDetail: null,
      errorGeneralInformation: {
        tourName: { error: false, message: FILL + 'tour name' },
        description: { error: false, message: FILL + 'description' },
        tourType: { error: false, message: CHOOSE + 'tour type' },
        currency: { error: false, message: CHOOSE + 'currency' },
        maxGuest: { error: false, message: FILL + 'max guest' },
        minGuest: { error: false, message: FILL + 'min guest' },
        destination: { error: false, message: CHOOSE + 'destination' },
        startDate: { error: false, message: CHOOSE + 'start date' },
        endDate: {
          error: false,
          message: CHOOSE + 'end date',
        },
        expiredDate: { error: false, message: CHOOSE + 'expired date' },
        graceDate: {
          error: false,
          message: CHOOSE + 'grace date',
        },
        minPax: { error: false, message: FILL + 'minimum pax' },
        referenceId: { error: false, message: FILL + 'minimum pax' },
        isError: false,
        message: '',
      },
      isPublished: false,
      additionalInfo: [],
      images: [],
      //fixedPackage
      tabActive: 'price',
      commissions: defaultCommission,
      packagePrice: {
        SingleRoomPrice: 0,
        SharingRoomPrice: 0,
        ChildExtraBedPrice: 0,
        AdultExtraBedPrice: 0,
        SharingBedPrice: 0,
        NoBedPrice: 0,
      },
      packageCost: {
        SingleRoomPrice: 0,
        SharingRoomPrice: 0,
        ChildExtraBedPrice: 0,
        AdultExtraBedPrice: 0,
        SharingBedPrice: 0,
        NoBedPrice: 0,
      },
      accommodation: [],
      additionalItem: [],
      Brochures: [],
      errorTourOperator: {
        TourOperator: { error: false, message: 'Please choose tour operator' },
        isError: false,
      },
      SharingPax: {},
      isThereTransactionHistory: false,
      deletedAdditionalInformation: [],
      deletedImages: [],
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.isOperation !== this.props.isOperation) {
      this.setState({
        ...this.state,
        tabActive: this.props.isOperation ? 'commision' : 'price',
      });
    }
  }

  componentDidMount = () => {
    // const { TypeTransaction, Id, TypePackage } = this.props.match.params;
    // const { TypeTransaction, Id, TypePackage } = this.props.match.params;
    // this.loadFirstTime(
    //   TypeTransaction,
    //   Id,
    //   Number(Id) > 0 && this.getPackage(TypeTransaction, Id, TypePackage)
    // );
    // this.loadFirstTime(TypeTransaction, Id, getPackage);
    // if (TypePackage === 'FixedDateVariable') {
    //   this.loadFirstTime(
    //     TypeTransaction,
    //     Id,
    //     this.getPackageVariableDate(TypeTransaction, Id, TypePackage)
    //   );
    // } else {
    //   this.loadFirstTime(
    //     TypeTransaction,
    //     Id,
    //     Number(Id) > 0 && this.getPackage(TypeTransaction, Id, TypePackage)
    //   );
    // }

    //====original code===
    const { TypeTransaction, Id } = this.props.match.params;
    this.props.getTourOperatorProfileAction();
    this.setPage(TypeTransaction, Id);
  };

  loadFirstTime = (TypeTransaction, Id, getPackage) => {
    this.props.getTourOperatorProfileAction();
    this.setPage(TypeTransaction, Id, getPackage);
  };
  // loadFirstTime = (TypeTransaction, Id) => {
  //   this.props.getTourOperatorProfileAction();
  //   this.setPage(TypeTransaction, Id);
  // };
  setPage = (TypeTransaction, Id) => {
    // eslint-disable-next-line
    // Promise.all([
    //   this.props.getCityInCountry(),
    //   this.props.getCurrenciesAction(),
    //   this.props.getTourTypeAction(),
    //   this.props.getActivityListAction(),
    // ]).then(() => {
    //   if (TypeTransaction === 'create') {
    //     this.resetAccomDaily(
    //       this.state.headlineProgram,
    //       this.state.roomAllocation
    //     );
    //     let currentPackage = {
    //       ...this.state.currentPackage,
    //       type: 'Fixed',
    //       id: Id,
    //       status: TypeTransaction,
    //       disabledEdit: false,
    //     };
    //     this.setState({
    //       currentPackage,
    //     });
    //   } else {
    //     // eslint-disable-next-line
    //     getPackage;
    //     this.props.getPackageTransactionHistory(Id).then(res => {
    //       this.setState({
    //         ...this.state,
    //         isThereTransactionHistory: res.value.data.length > 0 ? true : false,
    //       });
    //     });
    //   }
    // });
    // eslint-disable-next-line
    Promise.all([
      // this.props.getTourOperatorProfileAction(),
      this.props.getCityInCountry(),
      this.props.getCurrenciesAction(),
      this.props.getTourTypeAction(),
      this.props.getActivityListAction(),
      this.setState({
        ...this.state,
        tabActive: this.props.isOperation ? 'commision' : 'price',
      }),
    ]).then(() => {
      if (TypeTransaction === 'create') {
        this.resetAccomDaily(
          this.state.headlineProgram,
          this.state.roomAllocation
        );
        let currentPackage = {
          ...this.state.currentPackage,
          type: 'Fixed',
          id: Id,
          status: TypeTransaction,
          disabledEdit: false,
        };
        this.setState({
          currentPackage,
        });
      } else {
        this.getPackage(TypeTransaction, Id);
        this.props.getPackageTransactionHistory(Id).then(res => {
          this.setState({
            ...this.state,
            isThereTransactionHistory: res.value.data.length > 0 ? true : false,
          });
        });
      }
    });
  };
  resetAccomDaily = async (headlineProgram, roomAllocation) => {
    let initial = initialSetFixedPackage(headlineProgram, roomAllocation);
    initial.headlineProgram.Departures[0].Date = changeTime(
      initial.headlineProgram.Departures[0].Date,
      0,
      0
    );
    initial.headlineProgram.Returns[0].Date = changeTime(
      initial.headlineProgram.Returns[0].Date,
      23,
      59
    );
    await this.setState({
      headlineProgram: initial.headlineProgram,
      dailyProgram: initial.dailyProgram,
    });
  };

  getPackage = (TypeTransaction, Id) => {
    // eslint-disable-next-line
    this.props
      .getFixedPackage('Fixed', Id)
      .then(async () => {
        await this.setPackage(this.props.transactionDetail);
        // await this.getTourOperator(
        //   this.props.transactionDetail.BookingDetailSum.TourOperatorProfileId
        // );
        this.setState({
          ...this.state,
          demoPrice: this.props.transactionDetail,
        });
      })
      .catch(() => {
        // alert(error.response.data.Message);
      });
    let currentPackage = {
      ...this.state.currentPackage,
      type: 'Fixed',
      id: Id,
      status: TypeTransaction,
      disabledEdit: false,
    };
    this.setState({
      ...this.state,
      currentPackage,
    });
  };

  deletedAdditionalInformation = value => {
    const { deletedAdditionalInformation } = this.state;
    value === 'clear'
      ? this.setState({ deletedAdditionalInformation: [] })
      : deletedAdditionalInformation.push(value);
  };
  setPackage = async packageDetail => {
    let { currentPackage } = this.state;
    let { type, status } = currentPackage;
    let {
      HeadlineProgram,
      DailyPrograms,
      BookingDetailSum,
      IsPublished,
      Descriptions,
      Images,
      Commissions,
      Prices,
      BaseMarginPercentage,
      TourOperatorMarginPercentage,
      IsTourOperatorFixedPackage,
      TourOperator,
      Brochures,
    } = { ...packageDetail };
    let { activityList, cityInCountry, tourInformation } = this.props;
    // let isCreate = status === 'create';
    let isFixedPackage = type === 'Fixed';
    // let isFixedVariableDate = type === 'FixedDateVariable';
    tourInformation = isFixedPackage
      ? initialGeneralInfo(packageDetail, tourInformation)
      : initialGeneralInfoVariableDate(packageDetail, tourInformation);
    let roomAllocation = setRoomAllocation(packageDetail, type, status);
    // let HL = {...HeadlineProgram};
    if (HeadlineProgram.Departures.length === 0) {
      let moveDep = await findArrivalDeparture(DailyPrograms, ARRIVAL, null);
      moveDep && (await this.props.getPlaceByIdAction(moveDep.Item.PlaceId));
      HeadlineProgram = await changeDepartureReturn(
        DailyPrograms,
        HeadlineProgram,
        this.props.getPlaceById,
        ARRIVAL
      );
      // this.props.setheadlineProgram(newMainProg);
    }

    let initial = generateHeadline(
      HeadlineProgram,
      cityInCountry,
      roomAllocation
    );
    // let initial = await initialSetReadyPackage(
    //   HeadlineProgram,
    //   DailyPrograms,
    //   cityInCountry,
    //   status !== 'create' ? 'Quotation' : type,
    //   roomAllocation,
    //   activityList,
    //   true
    // );
    let initialDailyProg = await generateDailyProgram(
      DailyPrograms,
      activityList
    );
    // let initialDailyProg = DailyPrograms;
    // DailyPrograms = await generateDailyProgramFixed(
    //   initial
    //     ? initial.headlineProgram.Departures[0]
    //     : HeadlineProgram.Departures[0],
    //   initial ? initial.headlineProgram.Returns[0] : HeadlineProgram.Returns[0],
    //   initial
    //     ? initial.headlineProgram.MainPrograms
    //     : HeadlineProgram.MainPrograms,
    //   DailyPrograms,
    //   this.props.activityList
    // );
    this.getPlaceByRegion(HeadlineProgram.MainPrograms);
    // let availablePax = isFixedPackage
    //   ? packageDetail.BookingDetailSum.FixedPackage.MinimumGuest -
    //     packageDetail.BookingDetailSum.FixedPackage.ConfirmedGuest
    //   : isFixedVariableDate
    //     ? packageDetail.BookingDetailSum.VariableDatePackage.MinimumGuest -
    //       packageDetail.BookingDetailSum.VariableDatePackage.ConfirmedGuest
    //     : 0;
    let availablePax =
      packageDetail.BookingDetailSum.FixedPackage.MinimumGuest -
      packageDetail.BookingDetailSum.FixedPackage.ConfirmedGuest;
    let accommodation = setAccommodationPackageManagement(
      HeadlineProgram.MainPrograms
    );
    let tourOperator = setOperator(
      TourOperator,
      BaseMarginPercentage,
      TourOperatorMarginPercentage,
      IsTourOperatorFixedPackage
    );
    let packagePrice = setPrice(
      Prices,
      IsTourOperatorFixedPackage,
      BaseMarginPercentage,
      TourOperatorMarginPercentage
    );
    let packageCost = setCost(
      Prices,
      BaseMarginPercentage,
      TourOperatorMarginPercentage,
      IsTourOperatorFixedPackage
    );
    packageCost = IsTourOperatorFixedPackage
      ? setPackageCostFunc(
          packageCost,
          packagePrice,
          tourOperator.BaseMarginPercentage
        )
      : packageCost;
    let tourBrochure = setBrochure(Brochures);
    this.setState({
      ...this.state,
      tourInformation,
      roomAllocation,
      commissions: Commissions,
      paymentTerm: BookingDetailSum.FixedPackage.PaymentTerms,
      // paymentTerm: isFixedVariableDate
      //   ? BookingDetailSum.VariableDatePackage.PaymentTerms
      //   : BookingDetailSum.FixedPackage.PaymentTerms,
      // headlineProgram: initial ? initial.headlineProgram : HeadlineProgram,
      headlineProgram: initial,
      // dailyProgram: DailyPrograms,
      dailyProgram: initialDailyProg,
      availablePax,
      bookingStatus: BookingDetailSum.Status,
      packageDetail,
      isPublished: IsPublished,
      additionalInfo: Descriptions.map(e =>
        e.SubTitle.includes('FOR_VOUCHER')
          ? {
              ...e,
              SubTitle: e.SubTitle.replace('FOR_VOUCHER ', ''),
              ForVoucher: true,
            }
          : { ...e }
      ),
      images: Images,
      additionalItem: this.setDeductionCostAdditionalItem(
        BookingDetailSum.FixedPackage.Suppements
      ),
      accommodation,
      packagePrice,
      tourOperator,
      packageCost,
      Brochures: tourBrochure,
      // variableDatePeriodList: isFixedVariableDate
      //   ? BookingDetailSum.PeriodeDates
      //   : [],
    });
  };
  getPlaceByRegion = summaryProgram => {
    // eslint-disable-next-line
    summaryProgram.map(item => {
      // eslint-disable-next-line
      if (item.hasOwnProperty('AccommodationSummary')) {
        let regionID = this.props.cityInCountryList.find(
          data => data.Region.Name === item.Region
        );
        regionID && this.props.getAirportAction(regionID.Region.Id);
        // item.Region && this.props.getAirportAction(item.Region);
      }
      return item;
    });
  };
  // -------------------Handle general information ---------------
  // setSupplementState = packageDetail => {
  //   let supplements = setAdditionalItem(
  //     this.props.supplements,
  //     this.state.currentPackage.id
  //   );
  //   let nowPayment = getNowPaymentTerm(packageDetail);
  //   let paymentTerms = getPaymentTerms(packageDetail);
  //   supplements = setAdditionalPrice(
  //     supplements,
  //     paymentTerms,
  //     nowPayment.PaymentPercentage
  //   );
  //   this.setState({
  //     ...this.state,
  //     supplements,
  //     nowPayment,
  //     paymentTerms,
  //   });
  // };

  handleGeneralInfo = (name, value, objectname) => {
    let newData = this.state[objectname];
    newData = { ...newData, [name]: value };
    this.setState({ ...this.state, [objectname]: newData });
  };

  // handleGuestAndRoom = (guests, room, guestList, headline, supplements) =>
  //   this.setState({
  //     ...this.state,
  //     guests,
  //     roomAllocation: room,
  //     guestList,
  //     headlineProgram: headline,
  //     supplements,
  //   });

  // handleSupplements = supplements =>
  //   this.setState({
  //     ...this.state,
  //     supplements,
  //   });

  handleErrorsGeneralInform = errorGeneralInformation =>
    this.setState({ ...this.state, errorGeneralInformation });

  handleAdditionalInfo = (name, value) =>
    this.setState({ ...this.state, [name]: value });

  handleImages = (name, value) =>
    this.setState({ ...this.state, [name]: value });

  // -------------------End Handle general information ---------------
  //  =========== handle guest form ====================
  // handleChangeGuestList = (e, { name, value, objectname }) => {
  //   let { guestList } = this.state;
  //   let newGuestList = changeGuest(name, guestList, objectname, value);
  //   this.setState({
  //     ...this.state,
  //     guestList: newGuestList,
  //   });
  // };
  //  =========== end handle guest form ====================
  setDailyProgram = setDailyProgram => {
    let dp = setDailyProgram;
    this.setState({
      ...this.state,
      dailyProgram: dp,
    });
  };

  //  =========== handle destination summary form ====================
  setheadlineProgram = headlineProgram =>
    this.setState({
      ...this.state,
      headlineProgram,
    });
  //  =========== end handle destination summary form ====================
  //  =========== handle tour operator ====================
  getTourOperator = id => {
    this.props
      .getTourOperatorByIdAction(id)
      .then(() => this.setTourOperator(this.props.tourOperatorById));
  };

  setTourOperator = tourOperator =>
    this.setState({
      ...this.state,
      tourOperator,
    });

  handleErrorTourOperator = errorTourOperator =>
    this.setState({ ...this.state, errorTourOperator });

  // setReqDemoPrice = requestDemoPrice =>
  //   this.setState({
  //     ...this.state,
  //     requestDemoPrice,
  //   });

  // setDemoPrice = demoPrice => this.setState({ ...this.state, demoPrice });
  //  =========== end handle tour operator ====================

  //  =========== end handle accommodation ====================
  // setAccommodationOnChangeIndex = index =>
  //   this.setState({
  //     ...this.state,
  //     accommodationChangeIndex: index,
  //   });
  //  =========== end handle tour operator ====================
  // handleReloadPage = () => {
  //   window.location.reload();
  // };

  handleTab = (e, { value }) => {
    this.setState({ tabActive: value });
  };
  handleChangeCommissions = (value, i, name) => {
    let commissions = this.state.commissions;
    commissions[i] = { ...commissions[i], [name]: value };
    this.setState({
      commissions: commissions,
    });
  };

  setPaymentTerm = paymentTerm => {
    this.setState({ paymentTerm: paymentTerm });
  };

  setAddittionalItem = additionalItem => {
    this.setState({ additionalItem: additionalItem });
  };

  setAccommodation = accommodation => {
    this.setState({ accommodation: accommodation });
  };
  setPackagePriceAction = packagePrice => {
    this.setState({ packagePrice: packagePrice });
  };
  setPackageCostAction = packageCost => {
    this.setState({ packageCost: packageCost });
  };
  setTourOperatorAction = tourOperator => {
    this.setState({ tourOperator: tourOperator });
  };

  handleDateHeadlineProgram = async (value, index, name, tourInformation) => {
    // let { headlineProgram, tourInformation, dailyProgram } = this.state;
    let { headlineProgram, dailyProgram } = this.state;
    let newValue = '';
    let date = 'Date';
    let checkIn = 'CheckIn';
    let checkOut = 'CheckOut';
    let totalDays = 'TotalDays';
    let leavingDate = 'LeavingDate';
    let accommodations = headlineProgram.MainPrograms.filter(
      e => e.AccommodationSummary
    );
    let i = accommodations.length - 1;
    let dailyProgramNew = { ...dailyProgram };
    let formatDate = 'YYYY-MM-DDTHH:mm:ss';
    if (name === 'startDate') {
      // untuk menghitung selisih start date yang lama dengan yang baru
      if (tourInformation.startDate !== '') {
        let diffStartDate = diffDate(tourInformation.startDate, value, 'd');
        headlineProgram.Departures.map(e => {
          e.Date = value + 'T00:00:00';
          // convertDateFormat(
          //   addDate(
          //     e.Date,
          //     diffStartDate < 0 ? diffStartDate - 1 : diffStartDate + 1,
          //     'd'
          //   ),
          //   formatDate
          // );
          return e;
        });
        headlineProgram.MainPrograms.map(e => {
          e.Date = convertDateFormat(
            addDate(e.Date, diffStartDate, 'd'),
            formatDate
          );
          if (e.AccommodationSummary !== undefined) {
            newValue = newValue === '' ? e.Date : newValue;
            e.LeavingDate = convertDateFormat(
              addDate(e.LeavingDate, diffStartDate, 'd'),
              formatDate
            );
            e.AccommodationSummary.CheckIn = e.Date;
            e.AccommodationSummary.CheckOut = e.LeavingDate;
            e.AccommodationSummary.DateTime = e.Date;
          }
          return e;
        });
        headlineProgram.Returns.map(e => {
          e.Date = convertDateFormat(
            addDate(e.Date, diffStartDate, 'd'),
            formatDate
          );
          return e;
        });
        // [END] memperbaiki headline
        let dayTotal = diffDate(
          tourInformation.startDate,
          tourInformation.endDate,
          'days'
        );
        tourInformation.startDate = convertDateFormat(value, 'YYYY-MM-DD');
        tourInformation.endDate = convertDateFormat(
          addDate(value, dayTotal, 'd'),
          'YYYY-MM-DD'
        );
      } else {
        newValue = moment(value).format('YYYY-MM-DDT14:00:00');
        headlineProgram.Departures[i] = {
          ...headlineProgram.Departures[i],
          [date]: changeTime(newValue, 0, 0),
        };
        headlineProgram.MainPrograms[i].AccommodationSummary = {
          ...headlineProgram.MainPrograms[i].AccommodationSummary,
          [checkIn]: newValue,
        };
        let dayTotal = headlineProgram.MainPrograms[i].TotalDays;
        let convertLastDate = convertDateFormat(
          addDate(newValue, dayTotal - 1, 'd'),
          'YYYY-MM-DDT12:00:00'
        );
        // tourInformation.endDate = convertDateFormat(
        //   addDate(newValue, dayTotal - 1, 'd'),
        //   'YYYY-MM-DD'
        // );
        tourInformation.startDate = convertDateFormat(value, 'YYYY-MM-DD');
        headlineProgram.MainPrograms[i].AccommodationSummary = {
          ...headlineProgram.MainPrograms[i].AccommodationSummary,
          [checkOut]: convertLastDate,
        };
        headlineProgram.Returns[i] = {
          ...headlineProgram.Returns[i],
          [date]: changeTime(convertLastDate, 23, 59),
        };
      }
      newValue = moment(value).format('YYYY-MM-DDT14:00:00');
      dailyProgramNew = await fixDateAfterChangeStartDate(
        dailyProgram,
        newValue
      );
    } else {
      newValue = moment(value).format('YYYY-MM-DDT12:00:00');
      headlineProgram.Returns[i] = {
        ...headlineProgram.Returns[i],
        [date]: changeTime(newValue, 23, 59),
      };
      if (tourInformation.endDate === '') {
        headlineProgram.MainPrograms[i].AccommodationSummary = {
          ...headlineProgram.MainPrograms[i].AccommodationSummary,
          [checkOut]: newValue,
        };
        headlineProgram.MainPrograms[i] = await {
          ...headlineProgram.MainPrograms[i],
          [totalDays]: getTotalDays(tourInformation.startDate, value, true),
          [date]: moment(tourInformation.startDate).format(
            'YYYY-MM-DDT14:00:00'
          ),
          [leavingDate]: moment(tourInformation.endDate).format(
            'YYYY-MM-DDT12:00:00'
          ),
        };
      }
      // eslint-disable-next-line
      tourInformation.endDate = value;
    }
    this.setState({
      headlineProgram,
      dailyProgram: name === 'startDate' ? dailyProgramNew : dailyProgram,
      tourInformation,
    });
  };

  checkEndDateForReset = headlineProgram => {
    let setDateHeadlineProgram = {
      Departures: [],
      MainPrograms: [],
      Returns: [],
    };
    let checkAccoEmpty =
      headlineProgram.MainPrograms[0].AccommodationSummary
        .AccommodationProfileId === null;
    if (!checkAccoEmpty) {
      let initial = initialSetFixedPackage(
        setDateHeadlineProgram,
        this.state.roomAllocation
      );
      this.setState({
        headlineProgram: initial.headlineProgram,
        dailyProgram: initial.dailyProgram,
      });
    }
  };

  // setObjectToHeadlineProgram = (data, object, value) => {
  //   return (data = {
  //     ...data,
  //     [object]: value,
  //   });
  // };

  addAccommodation = async () => {
    let { headlineProgram, dailyProgram, tourInformation } = this.state;
    headlineProgram.Returns[0].Date = `${tourInformation.endDate}T12:00:00`;
    dailyProgram = await generateDailyProgramFixed(
      headlineProgram.Departures[0],
      headlineProgram.Returns[0],
      headlineProgram.MainPrograms,
      dailyProgram,
      this.props.activityList
    );
    this.setDailyProgram(dailyProgram);
  };

  checkGeneralInformation = () => {
    const {
      tourInformation,
      errorGeneralInformation,
      // currentPackage,
    } = this.state;
    let generalInfoValidation = {};

    generalInfoValidation = inputValidation(
      tourInformation,
      errorGeneralInformation
    );
    this.handleErrorsGeneralInform(generalInfoValidation);
    return generalInfoValidation.isError;
  };
  setBrochures = Brochures => {
    this.setState({ Brochures: Brochures });
  };

  setSharingPaxData = SharingPax => {
    this.setState({ SharingPax });
  };
  // handle change untuk menentukan series package variable x atau bukan
  setCurrentPackage = currentPackage => {
    this.setState({ ...this.state, currentPackage });
  };
  // [END] handle change untuk menentukan series package variable x atau bukan
  // helper untuk mengconvert cost deduction pada additional Item
  setDeductionCostAdditionalItem = data => {
    return data.map(item => {
      item.IsDeduction = item.UnitCost > 0 ? false : true;
      item.UnitCost = Math.abs(item.UnitCost);
      return item;
    });
  };

  handleDeleteImages = value => {
    const { deletedImages } = this.state;
    value === 'clear'
      ? this.setState({ deletedImages: [] })
      : deletedImages.push(value);
  };

  // [END] helper untuk mengconvert cost deduction pada additional Item
  filterMenuTab = (isCreate, isOperation, menus) => {
    if (isCreate) return menus.slice(0, -1);
    else if (isOperation) return menus.filter(e => e.value !== 'price');
    else return menus;
  };
  render() {
    const {
      dailyProgram,
      currentPackage,
      headlineProgram,
      errorGeneralInformation,
      //fixed package
      tabActive,
      packagePrice,
      packageCost,
      tourOperator,
      isPublished,
      roomAllocation,
      isThereTransactionHistory,
      deletedAdditionalInformation,
      deletedImages,
    } = this.state;
    const {
      // activityList,
      // loadingJoinTourOnBeHalf,
      // loadingTourTransaction,
      // loadingCreateTour,
      loadingGetPackage,
      isOperation,
      isSales,
      isUserAdmin,
    } = this.props;
    // let listTab =

    let listTab = this.filterMenuTab(
      currentPackage.status === 'create',
      isOperation,
      menus
    );

    let isDisabled = currentPackage.disabledEdit || isPublished;

    return (
      <Segment style={{ marginTop: '30px' }}>
        <Loader
          active={
            // loadingTourTransaction ||
            // loadingJoinTourOnBeHalf ||
            // loadingCreateTour ||
            loadingGetPackage
          }
          inverted={true}
        />
        <HeaderTitle title="Series Package" />
        <Grid style={{ marginTop: '3px' }}>
          <Grid.Row fluid>
            <Grid.Column width={16}>
              <AllButtonAction
                currentPackage={this.state.currentPackage}
                isPublished={isPublished}
                disabledEdit={currentPackage.disabledEdit}
                dailyProgram={dailyProgram}
                generalInformation={this.state.tourInformation}
                headlineProgram={this.state.headlineProgram}
                images={this.state.images}
                additionalInfo={this.state.additionalInfo}
                tourOperator={this.state.tourOperator}
                commissions={this.state.commissions}
                paymentTerms={this.state.paymentTerm}
                price={
                  tourOperator.IsTourOperatorFixedPackage
                    ? packagePrice
                    : packageCost
                }
                errorGeneralInformation={errorGeneralInformation}
                setErrorsGeneralInform={this.handleErrorsGeneralInform}
                brochures={this.state.Brochures}
                getPackage={this.getPackage}
                listAdditionalItem={this.state.additionalItem}
                handleErrorTourOperator={this.handleErrorTourOperator}
                errorTourOperator={this.state.errorTourOperator}
                SharingPax={this.state.SharingPax}
                setCurrentPackage={this.setCurrentPackage}
                isThereTransactionHistory={isThereTransactionHistory}
                //================================================
                setPage={this.setPage}
                deletedAdditionalInformation={deletedAdditionalInformation}
                deletedAdditionalInformationFunc={
                  this.deletedAdditionalInformation
                }
                deletedImages={deletedImages}
                handleDeleteImages={this.handleDeleteImages}
                isOperation={isOperation}
              />
              <GeneralInfoPackage
                Id={this.props.match.params.Id}
                currentPackage={this.state.currentPackage}
                tourInformation={this.state.tourInformation}
                optionCityInCountry={this.props.optionCityInCountry}
                handleGeneralInfo={this.handleGeneralInfo}
                cityInCountry={this.props.cityInCountry}
                errors={errorGeneralInformation}
                setErrorsGeneralInform={this.handleErrorsGeneralInform}
                additionalInfo={this.state.additionalInfo}
                handleAdditionalInfo={this.handleAdditionalInfo}
                images={this.state.images}
                handleImages={this.handleImages}
                handleDateHeadlineProgram={this.handleDateHeadlineProgram}
                setheadlineProgram={this.setheadlineProgram}
                headlineProgram={headlineProgram}
                setBrochures={this.setBrochures}
                brochures={this.state.Brochures}
                checkGeneralInformation={this.checkGeneralInformation}
                isFixedPackageMangement={true}
                setSharingPaxData={this.setSharingPaxData}
                resetAccomDaily={this.resetAccomDaily}
                roomAllocation={this.state.roomAllocation}
                isPublished={isPublished}
                deletedAdditionalInformation={this.deletedAdditionalInformation}
                handleDeleteImages={this.handleDeleteImages}
                isOperation={isOperation}
                imageOverlayBrochure={this.state.overlayBrochure}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <TabTourTransaction
                menus={listTab}
                tabActive={tabActive}
                handleTab={this.handleTab}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              width={16}
              style={tabActive !== 'dialyProgram' ? styles.displayTab : {}}
            >
              <TabDailyProgram
                dailyProgram={dailyProgram}
                setDailyProgram={this.setDailyProgram}
                headlineProgram={headlineProgram}
                setheadlineProgram={this.setheadlineProgram}
                isPublished={isPublished}
                isDisabled={isDisabled}
                isOperation={isOperation}
              />
              {/* <SegmentDailyProgram
                dailyProgram={dailyProgram}
                headlineProgram={headlineProgram}
                setDailyProgram={this.setDailyProgram}
                typePackage={
                  currentPackage.disabledEdit ? 'Fixed' : currentPackage.type
                }
                movementList={activityList}
                setheadlineProgram={this.setheadlineProgram}
                guests={this.state.guests}
              /> */}
            </Grid.Column>
            <Grid.Column
              width={16}
              style={tabActive !== 'price' ? styles.displayTab : {}}
            >
              <TabPricePackage
                currentPackage={this.state.currentPackage}
                packageDetail={this.props.transactionDetail}
                packagePrice={this.state.packagePrice}
                packageCost={this.state.packageCost}
                tourOperator={this.state.tourOperator}
                setPackagePriceAction={this.setPackagePriceAction}
                setPackageCostAction={this.setPackageCostAction}
                setTourOperatorAction={this.setTourOperatorAction}
                handleErrorTourOperator={this.handleErrorTourOperator}
                errorTourOperator={this.state.errorTourOperator}
                isPublished={isPublished}
              />
            </Grid.Column>
            <Grid.Column
              width={16}
              style={tabActive !== 'commision' ? styles.displayTab : {}}
            >
              <TabCommissionPackage
                handleChangeCommissions={this.handleChangeCommissions}
                commision={this.state.commissions}
                status={this.state.status}
                isPublished={isPublished || isOperation}
              />
            </Grid.Column>
            <Grid.Column
              width={16}
              style={tabActive !== 'paymentTerms' ? styles.displayTab : {}}
            >
              <TabPaymentPackage
                payment={this.state.paymentTerm}
                setPaymentTerm={this.setPaymentTerm}
                isPublished={isPublished}
                tourInformation={this.state.tourInformation}
                isOperation={isOperation}
              />
            </Grid.Column>
            <Grid.Column
              width={16}
              style={tabActive !== 'additionalItem' ? styles.displayTab : {}}
            >
              <TabAddittionalItemPacakge
                listAdditionalItem={this.state.additionalItem}
                setAddittionalItem={this.setAddittionalItem}
                isPublished={isPublished}
                isOperation={isOperation}
              />
            </Grid.Column>
            <Grid.Column
              width={16}
              style={tabActive !== 'accommodation' ? styles.displayTab : {}}
            >
              <SegmentDestinationsSummary
                headlineProgram={this.state.headlineProgram}
                dailyProgram={this.state.dailyProgram}
                setDailyProgram={this.setDailyProgram}
                setheadlineProgram={this.setheadlineProgram}
                handleGeneralInfo={this.handleGeneralInfo}
                currentPackage={currentPackage}
                tourInformation={this.state.tourInformation}
                guests={this.state.guests}
                errors={errorGeneralInformation}
                setErrorsGeneralInform={this.handleErrorsGeneralInform}
                isFixedPackageMangement={true}
                addAccommodation={this.addAccommodation}
                checkGeneralInformation={this.checkGeneralInformation}
                roomAllocation={roomAllocation}
                isPublished={isPublished || isOperation}
                isSales={isSales}
                isAdministrator={isUserAdmin}
              />
            </Grid.Column>
            <Grid.Column
              width={16}
              style={
                tabActive !== 'transactionHistory' ? styles.displayTab : {}
              }
            >
              <TabTransactionHistory
                historyList={this.props.packageHistoryList}
                currentPackage={currentPackage}
              />
            </Grid.Column>
            {/* <Grid.Column
              width={16}
              style={tabActive !== 'period' ? styles.displayTab : {}}
            >
              <TabPeriodVariableDate
                listPeriod={variableDatePeriodList}
                setvariableDateList={this.setvariableDateList}
              />
            </Grid.Column> */}
          </Grid.Row>
          <Grid.Row textAlign="center">
            <Grid.Column width={16} />
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

FixedPackageDetailPage.propTypes = {
  getFixedPackage: PropTypes.func,
  getSupplementItemByFixedPackageIdAction: PropTypes.func,
  supplements: PropTypes.array,
  getCityInCountry: PropTypes.func,
  cityInCountry: PropTypes.array,
  // loadingCityGroup: PropTypes.bool,
  transactionDetail: PropTypes.object,
  getActivityListAction: PropTypes.func,
  getActivityListStatus: PropTypes.string,
  activityList: PropTypes.array,
  resetActivityListAction: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      Id: PropTypes.number,
      type: PropTypes.string,
      TypeTransaction: PropTypes.string,
      PackageType: PropTypes.string,
      TypePackage: PropTypes.string,
    }),
  }),
  listTransactionDetailsStatus: PropTypes.string,
  resetStatusTransactionAction: PropTypes.func,
  listTransactionDetails: PropTypes.object,
  places: PropTypes.array,
  listAccommodation: PropTypes.array,
  listAccommodationItem: PropTypes.array,
  loadingAccommodation: PropTypes.bool,
  optionCityInCountry: PropTypes.array,
  getAirportAction: PropTypes.func,
  getTourOperatorProfileAction: PropTypes.func,
  getCurrenciesAction: PropTypes.func,
  getTourTypeAction: PropTypes.func,
  resetCityAction: PropTypes.func,
  getCityInCountryStatus: PropTypes.string,
  getTourOperatorByIdAction: PropTypes.func,
  tourOperatorById: PropTypes.object,
  loadingTourOperator: PropTypes.bool,
  getInvoiceByCompanyCodeAction: PropTypes.func,
  loadingDemoPrice: PropTypes.bool,
  loadingJoinTourOnBeHalf: PropTypes.bool,
  loadingPostDemoJoinTour: PropTypes.bool,
  loadingTourTransaction: PropTypes.bool,
  loadingCreateTour: PropTypes.bool,
  loadingGetPackage: PropTypes.bool,
  currencies: PropTypes.array,
  getPackageTransactionHistory: PropTypes.func,
  packageHistoryList: PropTypes.array,
  getPlaceById: PropTypes.object,
  getPlaceByIdAction: PropTypes.func,
  cityInCountryList: PropTypes.array,
  tourInformation: PropTypes.object,
  isOperation: PropTypes.bool,
  isSales: PropTypes.bool,
  isUserAdmin: PropTypes.bool,
};

const mapStateToProps = state => {
  let cityInCountry = state.CityStore.getCityInCountryList;
  let optionCityInCountry = cityInCountry.map((city, i) => {
    return {
      key: i,
      text: city.Name + ', ' + city.Region.Name + ', ' + city.Country.Name,
      value: city.Id,
    };
  });
  return {
    supplements: state.TourTransactionStore.supplementItemByFixedPackageId,
    cityInCountry,
    transactionDetail: state.PackageStore.fixedPackage,
    loadingReadyPackage: state.ReadyPackageStore.loading,
    loadingTourTransaction: state.TourTransactionStore.loading,
    // loadingCityGroup: state.CityGroupStore.loading,
    getActivityListStatus: state.PackageStore.getActivityListStatus,
    activityList: state.PackageStore.activityList,
    listTransactionDetailsStatus:
      state.TourTransactionStore.listTransactionDetailsStatus,
    listTransactionDetails: state.TourTransactionStore.listTransactionDetails,
    places: state.AirportStore.places,
    listAccommodationItem: state.AccomodationStore.getAccomodationByProfilIdAndDate.map(
      (item, i) => {
        return { key: i, text: item.Name, value: item.ServiceItemId };
      }
    ),
    loadingAccommodation: state.AccomodationStore.loading,
    optionCityInCountry,
    getCityInCountryStatus: state.CityStore.getCityInCountryStatus,
    tourOperatorById: state.TourTransactionStore.getTourOperatorById,
    loadingTourOperator: state.TourTransactionStore.loadingTourOperator,
    getRestaurantByCityDateAndPaxStatus:
      state.RestaurantStore.getRestaurantByCityDateAndPaxStatus,
    getExcurtionProfileByCity: state.ExcursionStore.getExcurtionProfileByCity,
    getExcurtionProfileByCityStatus:
      state.ExcursionStore.getExcurtionProfileByCityStatus,
    restaurantByCityDateAndPax:
      state.RestaurantStore.restaurantByCityDateAndPax,
    loadingExcursion: state.ExcursionStore.loading,
    loadingDemoPrice: state.TourTransactionStore.loadingPostDemoPrice,
    loadingJoinTourOnBeHalf: state.TourTransactionStore.loadingJoinTourOnBeHalf,
    loadingPostDemoJoinTour: state.TourTransactionStore.loadingPostDemoJoinTour,
    loadingCreateTour: state.TourTransactionStore.loadingCreateTour,
    loadingGetPackage: state.PackageStore.loading,
    packageHistoryList: state.PackageHistoryStore.packageTransactionHistoryList,
    getPlaceById: state.PlaceStore.getPlaceById,
    cityInCountryList: state.CityStore.getCityInCountryList,
    isSales: state.UserAccessStore.isSales,
    isUserAdmin: state.UserAccessStore.isAdministrator,
    isOperation: state.UserAccessStore.isOperation,
  };
};
export default connect(mapStateToProps, {
  getFixedPackage,
  getSupplementItemByFixedPackageIdAction,
  getCityInCountry,
  getActivityListAction,
  resetActivityListAction,
  resetStatusTransactionAction,
  getAirportAction,
  getTourOperatorProfileAction,
  getCurrenciesAction,
  getTourTypeAction,
  resetCityAction,
  getTourOperatorByIdAction,
  getInvoiceByCompanyCodeAction,
  getPrintPaymentReceiptTransactionAction,
  getPackageTransactionHistory,
  getPlaceByIdAction,
})(FixedPackageDetailPage);
