const setHistoryTransaction = state => {
  let destructure = [];
  let data = {};
  state.map(object => {
    data = {
      Code: object.Code ? object.Code : '',
      TourTransactionId: object.TourTransactionId
        ? object.TourTransactionId
        : '',
      InvoiceStatus: object.InvoiceStatus ? object.InvoiceStatus : '',
      ExpiredDate: object.ExpiredDate ? object.ExpiredDate : '',
      LastModifiedDate: object.LastModifiedDate ? object.LastModifiedDate : '',
      Amount: object.Amount ? object.Amount : 0,
      PaidAmount: object.PaidAmount ? object.PaidAmount : 0,
    };
    return destructure.push(data);
  });
  return destructure;
};

export default setHistoryTransaction;
