import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Button, Label } from 'semantic-ui-react';
import ButtonClose from './../button/buttonCloseModal';
import Input from './../input/inputWithLabel';
// import { postConfirmRoomAllotment } from './../../actions/accomodationAction';
import { postConfirmRoomAllotment } from '../../pages/shared/actions/accommodationAction';
import Loader from './../loader/loaderInModal';
import ModalAlert from './modalAlert';
const ACCEPT = 'ACCEPT';
// const DECLINE = 'DECLINE';

class modalConfirmRoomAllotment extends React.Component {
  state = {
    value: '',
    modalAlertOpen: false,
    modalAlertHeader: '',
    modalAlertMessage: '',
  };

  handleChange = (e, { value }) => this.setState({ ...this.state, value });

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({ ...this.state, value: '' });
    }
  }

  handleConfirm = (e, { requestRoomAllotment, isAccept }) => {
    let { value } = this.state;
    let data = {
      TransactionId: requestRoomAllotment.TransactionId,
      Token: requestRoomAllotment.Token,
      ActionType: isAccept ? 0 : 1,
      ConfirmationNumber: isAccept ? value : null,
      ConfirmationNote: isAccept ? null : value,
    };
    this.props
      .postConfirmRoomAllotment(data)
      .then(() => this.props.handleChangePage())
      .catch(() => {
        // this.props.handleChangePage();
        this.openModalAlert('Failed', 'Failed Confirm Room allotment');
      });
  };

  openModalAlert = (header, message) =>
    this.setState({
      ...this.state,
      modalAlertOpen: true,
      modalAlertHeader: header,
      modalAlertMessage: message,
    });

  handleCloseModalAlert = () => {
    this.setState({ ...this.state, modalAlertOpen: false });
  };

  render() {
    const {
      open,
      type,
      handleClose,
      requestRoomAllotment,
      loading,
    } = this.props;
    const {
      value,
      modalAlertOpen,
      modalAlertHeader,
      modalAlertMessage,
    } = this.state;
    const isAccept = type === ACCEPT;
    const header = isAccept ? 'Confirmation Number' : 'Room Not Available';
    const labelInput = isAccept ? 'Please Input Confirmation Number' : 'Note:';
    const placeholderInput = isAccept
      ? 'Confirmation Number'
      : 'The reason rooms are not available';
    const styleInput = isAccept
      ? {
          margin: '0px 10px',
          width: '290px',
        }
      : {
          margin: '0px 10px',
          width: '90%',
        };
    return (
      <Modal
        dimmer="blurring"
        inverted
        open={open}
        onClose={handleClose}
        size="mini"
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          {header}
          <ButtonClose onClose={handleClose} />
        </Modal.Header>
        <Modal.Content>
          <Loader loading={loading} />
          <Label basic style={{ border: 'none' }}>
            {labelInput}
          </Label>
          <Input
            placeholder={placeholderInput}
            value={value}
            name="value"
            type="textArea"
            inputStyles={styleInput}
            handleChange={this.handleChange}
          />
          <ModalAlert
            openModal={modalAlertOpen}
            handleClose={this.handleCloseModalAlert}
            header={modalAlertHeader}
            content={modalAlertMessage}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            positive
            onClick={this.handleConfirm}
            requestRoomAllotment={requestRoomAllotment}
            isAccept={isAccept}
          >
            confirm
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

modalConfirmRoomAllotment.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.string,
  handleClose: PropTypes.func,
  postConfirmRoomAllotment: PropTypes.func,
  requestRoomAllotment: PropTypes.object,
  loading: PropTypes.bool,
  handleChangePage: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    loading: state.AccomodationStore.loadingPostConfirmRoomAllotment,
  };
}
export default connect(
  mapStateToProps,
  {
    postConfirmRoomAllotment,
  }
)(modalConfirmRoomAllotment);
