import moment from 'moment';
import { getHourAndMinute, groupDateAndTime } from './moment';
const reCalculateDate = (startDate, daily, plus) => {
  if (daily.length > 0) {
    let firstDate = daily[0].Date;
    let diffDay = getDiffDays(firstDate, startDate);
    daily.map(item => {
      let newDateTime = addDays(item.Date, diffDay, plus);
      let dates = new Date(newDateTime);
      let time = getHourAndMinute(dates);
      //   let time = item.StartTime + ':00';
      //   let newDates = moment(dates).format('YYYY-MM-DD') + 'Z' + time;
      item.Date = groupDateAndTime(moment(dates).format('YYYY-MM-DD'), time);
      item.Movements.map(movement => {
        let newTime = getHourAndMinute(movement.DateTime);
        movement.DateTime = groupDateAndTime(dates, newTime);
        return movement;
      });
      return item;
    });
    return daily;
  }
};
const reCalculateDateAccommodation = (startDate, accommodation, plus) => {
  if (accommodation.length > 0) {
    let firstDate = accommodation[0].Date;
    let diffDay = getDiffDays(firstDate, startDate);
    accommodation.map(hotel => {
      let date = addDays(hotel.Date, diffDay, plus);
      let dateTime = getHourAndMinute(hotel.Date);
      hotel.Date = groupDateAndTime(date, dateTime);
      let leavingDate = addDays(hotel.LeavingDate, diffDay, plus);
      let leavingTime = getHourAndMinute(hotel.LeavingDate);
      hotel.LeavingDate = groupDateAndTime(leavingDate, leavingTime);
      return hotel;
    });
    return accommodation;
  }
};
const getDiffDays = (day1, day2) => {
  let oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  let firstDate = new Date(moment(day1).format('YYYY-MM-DD'));
  let secondDate = new Date(moment(day2).format('YYYY-MM-DD'));

  let diffDays = Math.round(
    Math.abs((firstDate.getTime() - secondDate.getTime()) / oneDay)
  );
  return diffDays;
};
const addDays = (date, days, type) => {
  let result = new Date(date);
  if (type === '-') {
    result.setDate(result.getDate() - days);
  } else {
    result.setDate(result.getDate() + days);
  }
  let newDate = new Date(result);
  return newDate;
};
const addMonths = (date, days, type) => {
  let result = new Date(date);
  if (type === '-') {
    result.setDate(result.getMonth() - days);
  } else {
    result.setDate(result.getMonth() + days);
  }
  let newDate = new Date(result);
  return newDate;
};
const addZero = i => {
  if (i < 10) {
    i = i.length === 2 ? i : '0' + i;
  }
  return i;
};
const onSortAscend = params => {
  params.sort((a, b) => {
    if (new Date(a.DateTime) > new Date(b.DateTime)) {
      // 'a' should be at higher index
      return 1;
    }

    if (new Date(a.DateTime) < new Date(b.DateTime)) {
      // 'a' should be at lower index
      return -1;
    }
    // TODO should handle if the startTime is equal
    return 0;
  });
  return params;
};
export {
  reCalculateDate,
  addZero,
  addDays,
  getDiffDays,
  reCalculateDateAccommodation,
  onSortAscend,
  addMonths,
};
