import React from 'react';
import { Header, Divider } from 'semantic-ui-react';
import PropTypes from 'prop-types';
//import PaginationTable from '../../../scripts/pagination';
//import FilterData from '../../../scripts/filterDataTable';
import { HeaderTransportationUnitPrice } from '../../../../../components/table/headerTabel';
//import ButtonGroupSupplier from '../../common/ButtonGroupSupplier';
import TableTransportationPrice from './table/tableTransportationPrice';
import ModalTransportationPrice from './modal/modalTransportationPrice';
const TransportationUnit = props => {
  return (
    <div>
      <Divider />
      <Header as="h4" content="Transportation Unit Price" />
      {/* <ButtonGroupSupplier buttonNew={props.addNew} /> */}
      <ModalTransportationPrice
        openModalPrice={props.openModalPrice}
        handleClose={props.handleClose}
        listRegion={props.listRegion}
        listDestCity={props.listDestCity}
        listOriginCity={props.listOriginCity}
        handleChangePrice={props.handleChangePrice}
        addPriceDetails={props.addPriceDetails}
        transportationPrice={props.transportationPrice}
        servicePriceType={props.servicePriceType}
        currencies={props.currencies}
        handleChangeCostPriceDetails={props.handleChangeCostPriceDetails}
        handleChangePriceDetails={props.handleChangePriceDetails}
        addPriceToList={props.addPriceToList}
        handleClickDelete={props.handleClickDelete}
        transportationHeader={props.transportationHeader}
        countryName={props.countryName}
        headerModal={props.headerModal}
        openModalPriceDetail={props.openModalPriceDetail}
        handleEditPriceDetail={props.handleEditPriceDetail}
      />
      <TableTransportationPrice
        headerTable={HeaderTransportationUnitPrice}
        listData={props.listData}
        handleClickEdit={props.handleClickEdit}
        handleClickDelete={props.handleClickDelete}
        disablePage={props.disablePage}
        addNew={props.addNew}
        numbersTo={props.numbersTo}
        numbersFrom={props.numbersFrom}
        //======================================
        activePage={props.activePage}
        setActivePage={props.setActivePage}
      />
    </div>
  );
};

TransportationUnit.propTypes = {
  listData: PropTypes.array,
  addLocation: PropTypes.array,
  searchInput: PropTypes.string,
  handleChange: PropTypes.func,
  addNew: PropTypes.func,
  openModalPrice: PropTypes.bool,
  handleClose: PropTypes.func,
  listRegion: PropTypes.array,
  listOriginCity: PropTypes.array,
  listDestCity: PropTypes.array,
  handleChangePrice: PropTypes.func,
  addPriceDetails: PropTypes.func,
  transportationPrice: PropTypes.object,
  handleClickDelete: PropTypes.func,
  handleClickEdit: PropTypes.func,
  servicePriceType: PropTypes.array,
  currencies: PropTypes.array,
  handleChangeCostPriceDetails: PropTypes.func,
  handleChangePriceDetails: PropTypes.func,
  addPriceToList: PropTypes.func,
  disablePage: PropTypes.bool,
  transportationHeader: PropTypes.object,
  headerModal: PropTypes.string,
  countryName: PropTypes.string,
  openModalPriceDetail: PropTypes.func,
  handleEditPriceDetail: PropTypes.func,
  numbersTo: PropTypes.number,
  numbersFrom: PropTypes.number,
  activePage: PropTypes.number,
  setActivePage: PropTypes.func,
};
export default TransportationUnit;
