const checkUser = code => {
  let autorization = {
    isSales: false,
    isAdministrator: false,
    isOperation: false,
    isFinance: false,
    isFinanceAP: false,
    isFinanceAR: false,
  };
  if (code && code.length !== 0) {
    autorization = code.some(e => e === 'Package Sales')
      ? { ...autorization, isSales: true }
      : code.some(e => e === 'Administrator')
      ? { ...autorization, isAdministrator: true }
      : code.some(e => e === 'Operation')
      ? { ...autorization, isOperation: true }
      : code.some(e => e === 'Finance Admin')
      ? {
          ...autorization,
          isFinance: true,
          isFinanceAP: code.some(e => e === 'FINANCE AP') ? true : false,
          isFinanceAR: code.some(e => e === 'FINANCE AR'),
        }
      : code.some(e => e === 'FINANCE AP')
      ? { ...autorization, isFinanceAP: true }
      : code.some(e => e === 'FINANCE AR')
      ? { ...autorization, isFinanceAR: true }
      : { ...autorization };
  }
  return autorization;
};

export { checkUser };
