import { convertDateFormat } from '../../../components/genericFunction/moment';

const setDetailData = data => {
  //   console.log(data);
  return {
    Id: data.Id,
    ResouceBookingCode: data.ResouceBookingCode,
    ResourceName: data.ResourceName,
    OwnerShip: data.OwnerShip.Text ? 'Touress' : data.OwnerShip.Text,
    Date: data.Date ? convertDateFormat(data.Date, 'DD MMM YYYY') : null,
    DueDate: data.DueDate
      ? convertDateFormat(data.DueDate, 'DD MMM YYYY')
      : null,
    PaymentHistories: data.PaymentHistories.map(payment => {
      return {
        ...payment,
        Id: payment.Id,
        Value: payment.Value,
        Date: convertDateFormat(payment.Date, 'DD MMM YYYY'),
      };
    }),
    Currency: data.Currency ? data.Currency : null,
    TotalPay: data.TotalPay ? data.TotalPay : null,
    BalanceDue: data.BalanceDue ? data.BalanceDue : null,
    Value: data.Value ? data.Value : null,
    PaymentStatus: data.PaymentStatus ? data.PaymentStatus : null,
    Qty: data.Qty ? data.Qty : null,
    StartDate: data.StartDate
      ? convertDateFormat(data.StartDate, 'DD MMM YYYY')
      : null,

    EndDate: data.EndDate
      ? convertDateFormat(data.EndDate, 'DD MMM YYYY')
      : null,
    Description: data.Description ? data.Description : null,
    PhoneNumber: data.PhoneNumber ? data.PhoneNumber : null,
    Email: data.Email ? data.Email : null,
    PaymentDate: data.PaymentDate
      ? convertDateFormat(data.PaymentDate, 'DD MMM YYYY hh:mm A')
      : null,
    Note: data.Note ? data.Note : null,
    Priority: data.Priority ? data.Priority : null,
    PackageNo: data.PackageNo ? data.PackageNo : '',
    PackageName: data.PackageName ? data.PackageName : '',
    TourStartDate: data.TourStartDate
      ? convertDateFormat(data.TourStartDate, 'DD MMM YYYY')
      : null,
    TourEndDate: data.TourEndDate
      ? convertDateFormat(data.TourEndDate, 'DD MMM YYYY')
      : null,
  };
};

export default setDetailData;
