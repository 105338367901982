//Get City By Region
export const GET_CITY_GROUP_LIST = 'GET_CITY_GROUP_LIST';
export const GET_CITY_GROUP_LIST_FULFILLED = 'GET_CITY_GROUP_LIST_FULFILLED';
export const GET_CITY_GROUP_LIST_PENDING = 'GET_CITY_GROUP_LIST_PENDING';
export const GET_CITY_GROUP_LIST_REJECTED = 'GET_CITY_GROUP_LIST_REJECTED';
export const RESET_CITY_GROUP = 'RESET_CITY_GROUP';

//Delete
export const DELETE_CITY_GROUP = 'DELETE_CITY_GROUP';
export const DELETE_CITY_GROUP_FULFILLED = 'DELETE_CITY_GROUP_FULFILLED';
export const DELETE_CITY_GROUP_PENDING = 'DELETE_CITY_GROUP_PENDING';
export const DELETE_CITY_GROUP_REJECTED = 'DELETE_CITY_GROUP_REJECTED';

//Get By ID
export const GET_CITY_GROUP_BY_ID = 'GET_CITY_GROUP_BY_ID';
export const GET_CITY_GROUP_BY_ID_FULFILLED = 'GET_CITY_GROUP_BY_ID_FULFILLED';
export const GET_CITY_GROUP_BY_ID_PENDING = 'GET_CITY_GROUP_BY_ID_PENDING';
export const GET_CITY_GROUP_BY_ID_REJECTED = 'GET_CITY_GROUP_BY_ID_REJECTED';

//POST CITY_GROUP
export const POST_CITY_GROUP = 'POST_CITY_GROUP';
export const POST_CITY_GROUP_FULFILLED = 'POST_CITY_GROUP_FULFILLED';
export const POST_CITY_GROUP_PENDING = 'POST_CITY_GROUP_PENDING';
export const POST_CITY_GROUP_REJECTED = 'POST_CITY_GROUP_REJECTED';

//PUT CITY_GROUP
export const PUT_CITY_GROUP = 'PUT_CITY_GROUP';
export const PUT_CITY_GROUP_FULFILLED = 'PUT_CITY_GROUP_FULFILLED';
export const PUT_CITY_GROUP_PENDING = 'PUT_CITY_GROUP_PENDING';
export const PUT_CITY_GROUP_REJECTED = 'PUT_CITY_GROUP_REJECTED';

//Get City In Country
export const GET_CITY_IN_COUNTRY = 'GET_CITY_IN_COUNTRY';
export const GET_CITY_IN_COUNTRY_FULFILLED = 'GET_CITY_IN_COUNTRY_FULFILLED';
export const GET_CITY_IN_COUNTRY_PENDING = 'GET_CITY_IN_COUNTRY_PENDING';
export const GET_CITY_IN_COUNTRY_REJECTED = 'GET_CITY_IN_COUNTRY_REJECTED';

//POST CITY_GROUP_DETAIL
export const POST_CITY_GROUP_DETAIL = 'POST_CITY_GROUP_DETAIL';
export const POST_CITY_GROUP_DETAIL_FULFILLED =
  'POST_CITY_GROUP_DETAIL_FULFILLED';
export const POST_CITY_GROUP_DETAIL_PENDING = 'POST_CITY_GROUP_DETAIL_PENDING';
export const POST_CITY_GROUP_DETAIL_REJECTED =
  'POST_CITY_GROUP_DETAIL_REJECTED';

//POST CITY_GROUP_DETAIL_FULLADD
export const POST_CITY_GROUP_DETAIL_FULLADD = 'POST_CITY_GROUP_DETAIL_FULLADD';
export const POST_CITY_GROUP_DETAIL_FULLADD_FULFILLED =
  'POST_CITY_GROUP_DETAIL_FULLADD_FULFILLED';
export const POST_CITY_GROUP_DETAIL_FULLADD_PENDING =
  'POST_CITY_GROUP_DETAIL_FULLADD_PENDING';
export const POST_CITY_GROUP_DETAIL_FULLADD_REJECTED =
  'POST_CITY_GROUP_DETAIL_FULLADD_REJECTED';
