const RESET_TOUR_GUIDE = 'RESET_TOUR_GUIDE';
const GET_TOUR_GUIDE = 'GET_TOUR_GUIDE';
const GET_TOUR_GUIDE_FULFILLED = 'GET_TOUR_GUIDE_FULFILLED';
const GET_TOUR_GUIDE_PENDING = 'GET_TOUR_GUIDE_PENDING';
const GET_TOUR_GUIDE_REJECTED = 'GET_TOUR_GUIDE_REJECTED';
const GET_TOUR_GUIDE_BY_ID = 'GET_TOUR_GUIDE_BY_ID';
const GET_TOUR_GUIDE_BY_ID_FULFILLED = 'GET_TOUR_GUIDE_BY_ID_FULFILLED';
const GET_TOUR_GUIDE_BY_ID_PENDING = 'GET_TOUR_GUIDE_BY_ID_PENDING';
const GET_TOUR_GUIDE_BY_ID_REJECTED = 'GET_TOUR_GUIDE_BY_ID_REJECTED';
const POST_TOUR_GUIDE = 'POST_TOUR_GUIDE';
const POST_TOUR_GUIDE_FULFILLED = 'POST_TOUR_GUIDE_FULFILLED';
const POST_TOUR_GUIDE_PENDING = 'POST_TOUR_GUIDE_PENDING';
const POST_TOUR_GUIDE_REJECTED = 'POST_TOUR_GUIDE_REJECTED';
const PUT_TOUR_GUIDE = 'PUT_TOUR_GUIDE';
const PUT_TOUR_GUIDE_FULFILLED = 'PUT_TOUR_GUIDE_FULFILLED';
const PUT_TOUR_GUIDE_PENDING = 'PUT_TOUR_GUIDE_PENDING';
const PUT_TOUR_GUIDE_REJECTED = 'PUT_TOUR_GUIDE_REJECTED';
const DELETE_TOUR_GUIDE = 'DELETE_TOUR_GUIDE';
const DELETE_TOUR_GUIDE_FULFILLED = 'DELETE_TOUR_GUIDE_FULFILLED';
const DELETE_TOUR_GUIDE_PENDING = 'DELETE_TOUR_GUIDE_PENDING';
const DELETE_TOUR_GUIDE_REJECTED = 'DELETE_TOUR_GUIDE_REJECTED';
export {
  GET_TOUR_GUIDE,
  GET_TOUR_GUIDE_FULFILLED,
  GET_TOUR_GUIDE_PENDING,
  GET_TOUR_GUIDE_REJECTED,
  GET_TOUR_GUIDE_BY_ID,
  GET_TOUR_GUIDE_BY_ID_FULFILLED,
  GET_TOUR_GUIDE_BY_ID_PENDING,
  GET_TOUR_GUIDE_BY_ID_REJECTED,
  RESET_TOUR_GUIDE,
  POST_TOUR_GUIDE,
  POST_TOUR_GUIDE_FULFILLED,
  POST_TOUR_GUIDE_PENDING,
  POST_TOUR_GUIDE_REJECTED,
  PUT_TOUR_GUIDE,
  PUT_TOUR_GUIDE_FULFILLED,
  PUT_TOUR_GUIDE_PENDING,
  PUT_TOUR_GUIDE_REJECTED,
  DELETE_TOUR_GUIDE,
  DELETE_TOUR_GUIDE_FULFILLED,
  DELETE_TOUR_GUIDE_PENDING,
  DELETE_TOUR_GUIDE_REJECTED,
};
