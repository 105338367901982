const styles = {
  styleButtonProfilePicture: {
    marginTop: '1em',
    marginLeft: '15em',
  },
  marginImage: {
    marginTop: '1em',
  },
  buttonSave: {
    marginLeft: '83%',
  },
};

export { styles };
