import * as types from '../actions/actionTypes';

const initialState = {
  loading: false,
  userGetUserInfoStatus: '',
  userInfo: [],
  userRoles: [],
  userRole: {},
  getUserRolesStatus: '',
  getUserRoleStatus: '',
  postUser: [],
  postUserStatus: '',
  postUserProfile: [],
  postUserProfileStatus: '',
  getUserRoleListStatus: '',
  getUserRoleList: [],
  getUserListInTableStatus: '',
  getUserListInTable: [],
  errors: {},
  getUserNotEmployee: [],
  getUserNotEmployeeStatus: '',
  putUserProfile: [],
  putUserProfileStatus: '',
  getUserRoleByUserId: [],
  getUserRoleByUserIdStatus: '',
  putRoleByUserId: [],
  putRoleByUserIdStatus: '',
  deleteRoleByUserId: [],
  deleteRoleByUserIdStatus: '',
  getSalesReport: '',
  getSalesReportStatus: '',
  getUserProfile: {},
  getUserProfileStatus: '',
  putUserProfileByBearerStatus: '',
  postChangePasswordStatus: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GETUSERINFO:
      return { ...state };
    case types.GETUSERINFO_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loading: false,
        userGetUserInfoStatus: 'failed',
      };
    case types.GETUSERINFO_PENDING:
      return { ...state, loading: true };
    case types.GETUSERINFO_FULFILLED:
      return {
        ...state,
        userInfo: action.payload.data,
        loading: false,
        userGetUserInfoStatus: 'success',
      };
    case types.RESET_STATUS_USER:
      return {
        ...state,
        userGetUserInfoStatus: '',
        postUserStatus: '',
        postUserProfileStatus: '',
        getUserRoleListStatus: '',
        getUserListInTableStatus: '',
        getUserNotEmployeeStatus: '',
        putUserProfileStatus: '',
        getUserRoleByUserIdStatus: '',
        putRoleByUserIdStatus: '',
        deleteRoleByUserIdStatus: '',
        getSalesReportStatus: '',
        getUserProfileStatus: '',
        putUserProfileByBearerStatus: '',
        postChangePasswordStatus: '',
      };
    case types.POST_USER:
      return { ...state };
    case types.POST_USER_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        postUserStatus: 'failed',
      };
    case types.POST_USER_PENDING:
      return { ...state, loading: true };
    case types.POST_USER_FULFILLED:
      return {
        ...state,
        postUser: action.payload.data,
        loading: false,
        postUserStatus: 'success',
      };

    case types.POST_USER_PROFILE:
      return { ...state };
    case types.POST_USER_PROFILE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        postUserProfileStatus: 'failed',
      };
    case types.POST_USER_PROFILE_PENDING:
      return { ...state, loading: true };
    case types.POST_USER_PROFILE_FULFILLED:
      return {
        ...state,
        postUserProfile: action.payload.data,
        loading: false,
        postUserProfileStatus: 'success',
      };
    case types.GET_USER_ROLE_LIST:
      return { ...state };
    case types.GET_USER_ROLE_LIST_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loading: false,
        getUserRoleListStatus: 'failed',
      };
    case types.GET_USER_ROLE_LIST_PENDING:
      return { ...state, loading: true };
    case types.GET_USER_ROLE_LIST_FULFILLED:
      return {
        ...state,
        getUserRoleList: action.payload.data,
        loading: false,
        getUserRoleListStatus: 'success',
      };
    case types.GET_USER_ROLES:
      return { ...state };
    case types.GET_USER_ROLES_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loading: false,
        getUserListInTableStatus: 'failed',
      };
    case types.GET_USER_ROLES_PENDING:
      return { ...state, loading: true };
    case types.GET_USER_ROLES_FULFILLED:
      return {
        ...state,
        getUserListInTable: action.payload.data,
        loading: false,
        getUserListInTableStatus: 'success',
      };
    case types.GET_USER_NOT_EMPLOYEE:
      return { ...state };
    case types.GET_USER_NOT_EMPLOYEE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loading: false,
        getUserNotEmployeeStatus: 'failed',
      };
    case types.GET_USER_NOT_EMPLOYEE_PENDING:
      return { ...state, loading: true };
    case types.GET_USER_NOT_EMPLOYEE_FULFILLED:
      return {
        ...state,
        getUserNotEmployee: action.payload.data,
        loading: false,
        getUserNotEmployeeStatus: 'success',
      };
    case types.PUT_USER_PROFILE:
      return { ...state };
    case types.PUT_USER_PROFILE_REJECTED:
      return {
        ...state,
        errors: {
          global: Object.values(action.payload.response.data.ModelState),
        },
        loading: false,
        putUserProfileStatus: 'failed',
      };
    case types.PUT_USER_PROFILE_PENDING:
      return { ...state, loading: true };
    case types.PUT_USER_PROFILE_FULFILLED:
      return {
        ...state,
        putUserProfile: action.payload.data,
        loading: false,
        putUserProfileStatus: 'success',
      };

    case types.GET_USER_ROLE_BY_USER_ID:
      return { ...state };
    case types.GET_USER_ROLE_BY_USER_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loading: false,
        getUserRoleByUserIdStatus: 'failed',
      };
    case types.GET_USER_ROLE_BY_USER_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_USER_ROLE_BY_USER_ID_FULFILLED:
      return {
        ...state,
        getUserRoleByUserId: action.payload.data,
        loading: false,
        getUserRoleByUserIdStatus: 'success',
      };

    case types.POST_ADD_NEW_ROLE_BY_USER_ID:
      return { ...state };
    case types.POST_ADD_NEW_ROLE_BY_USER_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        putRoleByUserIdStatus: 'failed',
      };
    case types.POST_ADD_NEW_ROLE_BY_USER_ID_PENDING:
      return { ...state, loading: true };
    case types.POST_ADD_NEW_ROLE_BY_USER_ID_FULFILLED:
      return {
        ...state,
        putRoleByUserId: action.payload.data,
        loading: false,
        putRoleByUserIdStatus: 'success',
      };
    case types.DELETE_ROLE_BY_USER_ID:
      return { ...state };
    case types.DELETE_ROLE_BY_USER_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        deleteRoleByUserIdStatus: 'failed',
      };
    case types.DELETE_ROLE_BY_USER_ID_PENDING:
      return { ...state, loading: true };
    case types.DELETE_ROLE_BY_USER_ID_FULFILLED:
      return {
        ...state,
        deleteRoleByUserId: action.payload.data,
        loading: false,
        deleteRoleByUserIdStatus: 'success',
      };

    case types.GET_SALES_REPORT:
      return { ...state };
    case types.GET_SALES_REPORT_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loading: false,
        getSalesReportStatus: 'failed',
      };
    case types.GET_SALES_REPORT_PENDING:
      return { ...state, loading: true };
    case types.GET_SALES_REPORT_FULFILLED:
      return {
        ...state,
        getSalesReport: action.payload.data,
        loading: false,
        getSalesReportStatus: 'success',
      };

    case types.GET_USER_PROFILE:
      return { ...state };
    case types.GET_USER_PROFILE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        loading: false,
        getUserProfileStatus: 'failed',
      };
    case types.GET_USER_PROFILE_PENDING:
      return { ...state, loading: true };
    case types.GET_USER_PROFILE_FULFILLED:
      return {
        ...state,
        getUserProfile: action.payload.data,
        loading: false,
        getUserProfileStatus: 'success',
      };

    case types.PUT_USER_PROFILE_BY_BEARER:
      return { ...state };
    case types.PUT_USER_PROFILE_BY_BEARER_REJECTED:
      return {
        ...state,
        errors: {
          global: { global: action.payload.message },
        },
        loading: false,
        putUserProfileByBearerStatus: 'failed',
      };
    case types.PUT_USER_PROFILE_BY_BEARER_PENDING:
      return { ...state, loading: true };
    case types.PUT_USER_PROFILE_BY_BEARER_FULFILLED:
      return {
        ...state,
        loading: false,
        putUserProfileByBearerStatus: 'success',
      };

    case types.POST_CHANGE_PASSWORD:
      return { ...state };
    case types.POST_CHANGE_PASSWORD_REJECTED:
      return {
        ...state,
        errors: {
          global: { global: action.payload.message },
        },
        loading: false,
        postChangePasswordStatus: 'failed',
      };
    case types.POST_CHANGE_PASSWORD_PENDING:
      return { ...state, loading: true };
    case types.POST_CHANGE_PASSWORD_FULFILLED:
      return {
        ...state,
        loading: false,
        postChangePasswordStatus: 'success',
      };

    default:
      return state;
  }
};

export default reducer;
