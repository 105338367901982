import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Grid,
  Segment,
  Pagination,
  Header,
  Label,
  Icon,
  Button,
} from 'semantic-ui-react';
import Input from './../../../input/inputWithLabel';
import LoaderModal from './../../../loader/loaderInModal';
// import { ucwords } from './../../../genericFunction/convertWord';
// import Card from './card';
import styles from './../../styles';
// import ImageCard from './../card/imageCard';
// import Facilities from './facilities';
import ModalDetailListRoom from './../modalDetailListRoom';
import { convertDateFormat } from '../../../../scripts/moment';

class listCard extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     activePage: 1,
  //   };
  // }
  state = {
    openModalRoom: false,
    selectedAccommodation: 0,
  };
  static propTypes = {
    data: PropTypes.array,
    locationName: PropTypes.string,
    onClose: PropTypes.func,
    loading: PropTypes.bool,
    handleChange: PropTypes.func,
    searchName: PropTypes.string,
    pageChange: PropTypes.func,
    activePage: PropTypes.number,
    options: PropTypes.array,
    activeSort: PropTypes.string,
    setAccommodationRoom: PropTypes.func,
    accomIndex: PropTypes.number,
    headlineProgram: PropTypes.object,
    openRoomModal: PropTypes.func,
    dataGetAccommodation: PropTypes.object,
    currentPackage: PropTypes.object,
    tourInformation: PropTypes.object,
  };

  pagination = (data, perPage) => {
    let sets = [],
      chunks = data.length / perPage;
    for (let i = 0, j = 0; i < chunks; i++, j += perPage)
      sets[i] = data.slice(j, j + perPage);
    return sets;
  };

  openCloseModal = (e, { value }) => {
    this.openRoomModal(value);
  };
  openRoomModal = value => {
    this.setState({
      ...this.state,
      openModalRoom: true,
      selectedAccommodation: value,
    });
  };

  closeModal = () => {
    this.setState({ openModalRoom: false });
  };
  render() {
    let {
      data,
      onClose,
      loading,
      searchName,
      handleChange,
      pageChange,
      activePage,
      options,
      activeSort,
      setAccommodationRoom,
      accomIndex,
      headlineProgram,
      locationName,
      dataGetAccommodation,
      currentPackage,
    } = this.props;
    // let { activePage } = this.state;
    let pagedData = this.pagination(data, 6);
    let listData = pagedData[activePage - 1];
    // locationName = ucwords(locationName);
    let { openModalRoom, selectedAccommodation } = this.state;
    let isTransactionReadyCustom =
      currentPackage.type === 'Ready' || currentPackage.type === 'Custom';
    return (
      <Container>
        <Grid>
          <Grid.Row>
            <Grid.Column width={16} style={{ marginBottom: '15px' }}>
              <Segment style={styles.segmentSearch}>
                <Input
                  name="searchName"
                  type="input"
                  icon="search"
                  iconPosition="left"
                  inputStyles={styles.inputWithPaddingNine}
                  transparent={true}
                  labelStyles={styles.textTransparent}
                  value={searchName}
                  handleChange={handleChange}
                  placeholder="Search by accommodation name"
                />
              </Segment>
            </Grid.Column>
            <Grid.Column width={8}>
              <Segment style={styles.segmentNoPadding}>
                <Input
                  type="input"
                  icon="map marker alternate"
                  iconPosition="left"
                  inputStyles={styles.inputStyles}
                  label={`${data.length} Accommodation Found`}
                  labelStyles={styles.boldGoldColor}
                  value={locationName}
                  handleChange={this.handleChange}
                  disabled={true}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column width={8}>
              <Segment style={styles.segmentNoPadding}>
                <Input
                  name="activeSort"
                  type="select"
                  inputStyles={{
                    width: '100%',
                  }}
                  label="Sort by:"
                  labelStyles={{ fontWeight: 'bold' }}
                  options={options}
                  value={activeSort}
                  placeholder="Sort accommodation"
                  handleChange={handleChange}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column width={16} style={styles.marginBottom15px}>
              {dataGetAccommodation ? (
                <span>
                  Accommodation <b>from</b>{' '}
                  {convertDateFormat(
                    dataGetAccommodation.requestedDate,
                    'DD MMM YYYY'
                  )}{' '}
                  <b>until</b>{' '}
                  {convertDateFormat(
                    dataGetAccommodation.checkOutDate,
                    'DD MMM YYYY'
                  )}{' '}
                </span>
              ) : null}
            </Grid.Column>
            <Grid.Column width={16} style={{ minHeight: '50px' }}>
              <LoaderModal loading={loading} />
              {listData && !loading
                ? listData.map((data, index) => {
                    let stars = new Array(
                      parseInt(data.AccommodationRating.Id, 10)
                    ).fill(0);
                    return (
                      // <Card
                      //   key={index}
                      //   images={data.ProfileImages}
                      //   image={data.ImageUrl}
                      //   isPromo={data.IsPromo}
                      //   name={data.Name}
                      //   type={data.AccommodationType.Name}
                      //   address={data.Address}
                      //   id={data.Id}
                      //   facilities={data.ProfileFacilities}
                      //   rating={data.AccommodationRating.Id}
                      //   closeModal={onClose}
                      //   setAccommodationRoom={setAccommodationRoom}
                      //   accomIndex={accomIndex}
                      //   headlineProgram={headlineProgram}
                      //   openRoomModal={openRoomModal}
                      // />
                      <div key={index}>
                        <Segment.Group
                          horizontal
                          style={styles.segmentNoPadding}
                        >
                          <Segment style={{ border: 'none' }}>
                            <Header as="h3" style={styles.goldColor}>
                              {data.Name}
                            </Header>
                            {/* <Header as="h6" style={styles.noMarginTop}> */}
                            <Label basic style={styles.label}>
                              {data.AccommodationType
                                ? data.AccommodationType.Name + ' '
                                : ''}
                            </Label>{' '}
                            {data.IsPromo && (
                              <Label basic style={styles.labelPromo}>
                                {'Promo'}
                              </Label>
                            )}{' '}
                            {stars.map((item, index) => (
                              <Icon
                                key={item + index}
                                name="star"
                                color="yellow"
                              />
                            ))}
                            <Grid>
                              <Grid.Row
                                style={styles.paddingTop10pxMarginTop10px}
                              >
                                <Grid.Column width="1">
                                  <Icon
                                    name="map marker alternate"
                                    style={styles.goldColor}
                                  />
                                </Grid.Column>
                                <Grid.Column width="14" style={styles.justify}>
                                  {data.Address}
                                </Grid.Column>
                              </Grid.Row>
                              {isTransactionReadyCustom && (
                                <Grid.Row
                                  style={styles.noMarginPaddingTop5pxBottom10px}
                                >
                                  <Grid.Column width={6}>
                                    {data.IsInstantConfirmation ? (
                                      <Label
                                        basic
                                        style={styles.labelAvailable}
                                      >
                                        <Icon name="badge-check" />
                                        Available
                                      </Label>
                                    ) : (
                                      <Label
                                        basic
                                        style={styles.labelOnRequest}
                                      >
                                        <Icon name="spinner" />
                                        On Request
                                      </Label>
                                    )}
                                  </Grid.Column>
                                </Grid.Row>
                              )}

                              <Grid.Row style={{ paddingTop: '3px' }}>
                                <Grid.Column width="7" floated="right">
                                  <Button
                                    size="small"
                                    primary
                                    onClick={this.openCloseModal}
                                    value={data}
                                    floated="right"
                                  >
                                    SEE ROOM
                                  </Button>
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </Segment>
                        </Segment.Group>
                      </div>
                    );
                  })
                : !loading && (
                    <Header textAlign="center" as="h4">
                      There is no result of accommodation for your destination
                    </Header>
                  )}
            </Grid.Column>
            <Grid.Column textAlign="center" width={16}>
              {pagedData && !loading && pagedData.length > 1 && (
                <Pagination
                  totalPages={pagedData.length}
                  onPageChange={pageChange}
                  activePage={activePage}
                />
              )}
            </Grid.Column>
            <ModalDetailListRoom
              open={openModalRoom}
              onClose={this.closeModal}
              header={selectedAccommodation.Name}
              desc={selectedAccommodation.Name}
              amenities={selectedAccommodation.ProfileFacilities}
              closeModalAccommodation={onClose}
              setAccommodationRoom={setAccommodationRoom}
              selectedAccommodation={selectedAccommodation.Id}
              mainProg={headlineProgram.MainPrograms}
              accomIndex={accomIndex}
              dataGetAccommodation={dataGetAccommodation}
            />
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}
export default listCard;
// getAccomodationItemByProfiledIdAndRequestDateAction
