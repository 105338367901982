import React from 'react';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
const buttonActionTourOperator = props => {
  return (
    <div>
      <Button
        primary
        onClick={props.buttonMargin}
        content={props.contentRightEnd}
        value={props.value}
        name={props.name}
        disabled={props.disable}
      />
      <Button
        positive
        onClick={props.butttonEdit}
        content={props.contentRight}
        value={props.value}
        name={props.name}
        disabled={props.disable}
      />
      <Button
        negative
        onClick={props.buttonDelete}
        content={props.contentLeft}
        value={props.value}
        name={props.name}
        disabled={props.disable}
      />
    </div>
  );
};
buttonActionTourOperator.propTypes = {
  buttonMargin: PropTypes.func,
  butttonEdit: PropTypes.func,
  buttonDelete: PropTypes.func,
  buttonLeftEnd: PropTypes.func,
  contentRightEnd: PropTypes.string,
  contentRight: PropTypes.string,
  contentLeft: PropTypes.string,
  contentLeftEnd: PropTypes.string,
  value: PropTypes.number,
  name: PropTypes.string,
  disable: PropTypes.bool,
};

export default buttonActionTourOperator;
