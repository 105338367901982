import * as types from '../constants/actionTypes';
const initialState = {
  getRegionList: [], //
  getRegionListStatus: '', //
  getRegion: [], //
  getRegionStatus: '', //
  errors: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //
    case types.GET_REGION_BY_COUNTRY:
      return { ...state };
    case types.GET_REGION_BY_COUNTRY_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getRegionListStatus: 'failed',
      };
    case types.GET_REGION_BY_COUNTRY_PENDING:
      return { ...state, loading: true };
    case types.GET_REGION_BY_COUNTRY_FULFILLED:
      return {
        ...state,
        getRegionList: action.payload.data,
        loading: false,
        getRegionListStatus: 'success',
      };
    case types.RESET_REGION:
      return {
        ...state,
        getRegionListStatus: '',
        getRegionStatus: '',
      };
    //
    case types.GET_REGION_LIST:
      return { ...state };
    case types.GET_REGION_LIST_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getRegionStatus: 'failed',
      };
    case types.GET_REGION_LIST_PENDING:
      return { ...state, loading: true };
    case types.GET_REGION_LIST_FULFILLED:
      return {
        ...state,
        getRegion: action.payload.data,
        loading: false,
        getRegionStatus: 'success',
      };

    default:
      return state;
  }
};

export default reducer;
