import apiClient from '../apiClient';
import { GET_RESTAURANT_BY_FILTER_URL } from '../apiUrl';

const getRestaurantByFilterApi = data => {
  return apiClient.get(
    GET_RESTAURANT_BY_FILTER_URL(
      data.cityId,
      data.ratingIds,
      data.locationIds,
      data.specializationId,
      data.menuClassId,
      data.facilityIds,
      data.requestedDate,
      data.pax
    )
  );
};

export default getRestaurantByFilterApi;
