import React from 'react';
import { Table, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const RoomFixedPackage = props => {
  return (
    <Table.Body>
      {props.roomAllocation.SharingRoomQty !== 0 && (
        <Table.Row>
          <Table.Cell>Sharing Room</Table.Cell>
          <Table.Cell>{props.roomAllocation.SharingRoomQty}</Table.Cell>
        </Table.Row>
      )}
      {props.roomAllocation.SingleRoomQty !== 0 && (
        <Table.Row>
          <Table.Cell>Single Room</Table.Cell>
          <Table.Cell>{props.roomAllocation.SingleRoomQty}</Table.Cell>
        </Table.Row>
      )}
      {props.roomAllocation.ExtraBedQty !== 0 && (
        <Table.Row>
          <Table.Cell>Extra bed (Adults)</Table.Cell>
          <Table.Cell>{props.roomAllocation.ExtraBedQty}</Table.Cell>
        </Table.Row>
      )}
      {props.roomAllocation.ChildExtraBedQty !== 0 && (
        <Table.Row>
          <Table.Cell>Extra Bed (Children)</Table.Cell>
          <Table.Cell>{props.roomAllocation.ChildExtraBedQty}</Table.Cell>
        </Table.Row>
      )}
      {props.roomAllocation.SharingBedQty !== 0 && (
        <Table.Row>
          <Table.Cell>Sharing with parents</Table.Cell>
          <Table.Cell>{props.roomAllocation.SharingBedQty}</Table.Cell>
        </Table.Row>
      )}
      {props.roomAllocation.NoBed !== 0 && (
        <Table.Row>
          <Table.Cell>Baby Crib/No Bed</Table.Cell>
          <Table.Cell>{props.roomAllocation.NoBed}</Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  );
};

const RoomCustomPackage = props => {
  return (
    <Table.Body>
      {props.roomAllocation.SharingRoomQty !== 0 && (
        <Table.Row>
          <Table.Cell>Sharing Room (Adults)</Table.Cell>
          <Table.Cell>{props.roomAllocation.SharingRoomQty}</Table.Cell>
        </Table.Row>
      )}
      {props.roomAllocation.SingleRoomQty !== 0 && (
        <Table.Row>
          <Table.Cell>Single Room (Adults)</Table.Cell>
          <Table.Cell>{props.roomAllocation.SingleRoomQty}</Table.Cell>
        </Table.Row>
      )}
      {props.roomAllocation.ExtraBedQty !== 0 && (
        <Table.Row>
          <Table.Cell>Extra bed (Adults)</Table.Cell>
          <Table.Cell>{props.roomAllocation.ExtraBedQty}</Table.Cell>
        </Table.Row>
      )}
      {props.roomAllocation.ChildSharingRoomQty !== 0 && (
        <Table.Row>
          <Table.Cell>Sharing Room (Children)</Table.Cell>
          <Table.Cell>{props.roomAllocation.ChildSharingRoomQty}</Table.Cell>
        </Table.Row>
      )}
      {props.roomAllocation.ChildSingleRoomQty !== 0 && (
        <Table.Row>
          <Table.Cell>Single Room (Children)</Table.Cell>
          <Table.Cell>{props.roomAllocation.ChildSingleRoomQty}</Table.Cell>
        </Table.Row>
      )}
      {props.roomAllocation.ChildExtraBedQty !== 0 && (
        <Table.Row>
          <Table.Cell>Extra bed (Children)</Table.Cell>
          <Table.Cell>{props.roomAllocation.ChildExtraBedQty}</Table.Cell>
        </Table.Row>
      )}
      {props.roomAllocation.SharingBedQty !== 0 && (
        <Table.Row>
          <Table.Cell>Sharing with parent (Children)</Table.Cell>
          <Table.Cell>{props.roomAllocation.SharingBedQty}</Table.Cell>
        </Table.Row>
      )}
      {props.roomAllocation.BabyCrib !== 0 && (
        <Table.Row>
          <Table.Cell>Baby Crib(infants)</Table.Cell>
          <Table.Cell>{props.roomAllocation.BabyCrib}</Table.Cell>
        </Table.Row>
      )}
      {props.roomAllocation.NoBed !== 0 && (
        <Table.Row>
          <Table.Cell>No bed(infants)</Table.Cell>
          <Table.Cell>{props.roomAllocation.NoBed}</Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  );
};

const TablePaxArrangement = props => {
  let { adults, childs, infants } = props.guests;
  let isFixedPackage = props.packageType === 'Fixed';
  return (
    <div>
      <Header as="h4">Pax Arrangement</Header>
      <Table basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Room Type</Table.HeaderCell>
            <Table.HeaderCell>Amount</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {isFixedPackage ? (
          <RoomFixedPackage roomAllocation={props.roomAllocation} />
        ) : (
          <RoomCustomPackage roomAllocation={props.roomAllocation} />
        )}
        <Table.Footer>
          <Table.Row>
            <Table.Cell colSpan="2" style={{ background: 'white' }}>
              <strong>Guests : </strong>
              Adult (
              {props.foc.Qty > 0
                ? adults + ' Including ' + props.foc.Qty + ' FOC'
                : adults}
              ), Children ({childs}
              ), Infant ({infants})
            </Table.Cell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </div>
  );
};

TablePaxArrangement.propTypes = {
  listData: PropTypes.array,
  guests: PropTypes.object,
  roomAllocation: PropTypes.object,
  packageType: PropTypes.string,
  foc: PropTypes.object,
};
RoomFixedPackage.propTypes = {
  roomAllocation: PropTypes.object,
};
RoomCustomPackage.propTypes = {
  roomAllocation: PropTypes.object,
};
export default TablePaxArrangement;
