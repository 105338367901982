import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Form,
  Button,
  Table,
  // Checkbox,
  Segment,
  Header,
  Divider,
} from 'semantic-ui-react';
import InputWithLabel from '../../../../shared/components/common/input/InputWithLabel';
import Pagination from '../../../../../components/common/pagination';
import { getNumberFromTo } from '../../../../../scripts/pagination';
import PaginationScript from '../../../../../components/genericFunction/pagination';
import ModalTierPrice from './ModalTierPrice';
import ModalConfirm from '../../../../../components/modal/modalConfirmationTwoButton';
import { copyObject } from '../../../../../scripts/itineraryBuilder/itineraryBuilder';
import {
  setPackageCostFunc,
  setPackagePriceFunc,
} from '../../../../../scripts/packageManagement/fixedPackageFunc';

const TabTierPriceBasedPax = props => {
  let { customPrices, handleSetCustomPrices, isPublished, currencies } = props;
  let {
    Prices,
    SystemMargin,
    TourOperatorMargin,
    IsTourOperatorFixedPackage,
  } = copyObject(customPrices);
  const [activePage, setActivePage] = useState(1);
  const [modalPrice, setModalPrice] = useState({
    isOpen: false,
    selectedPrice: null,
  });
  const [inputSearch, setInputSearch] = useState('');
  const [modalConfirm, setModalConfirm] = useState({
    isOpen: false,
    header: '',
    message: '',
    value: '',
    type: '',
  });
  // ===== handle price =====
  const pageChange = (e, { activePage }) => {
    setActivePage(activePage);
  };
  const openModalPrice = (e, { value }) =>
    setModalPrice({ isOpen: true, selectedPrice: value });
  const closeModalPrice = () =>
    setModalPrice({ isOpen: false, selectedPrice: null });
  const handleChange = (e, { value }) => setInputSearch(value);
  const handleDelete = (e, { value, name }) =>
    openCloseModalConfirm(
      true,
      'Confirmation',
      'Are you sure want to delete this price?',
      value,
      name
    );
  const confirmModalConfirm = () => {
    let { value, type } = modalConfirm;
    if (type === 'DELETE') {
      handleSetCustomPrices({
        ...customPrices,
        Prices: Prices.map(e =>
          JSON.stringify(copyObject(e)) === JSON.stringify(copyObject(value))
            ? { ...e, IsDelete: true }
            : e
        ),
      });
    }
    openCloseModalConfirm();
  };
  const changeMargin = async (e, { name, value }) => {
    value = !value
      ? 0
      : Number(value) < 0
      ? 0
      : Number(value) > 100
      ? 100
      : value;
    let newPrices = await Prices.map(e => {
      let packagePrice = null;
      if (
        IsTourOperatorFixedPackage ||
        (name === 'IsTourOperatorFixedPackage' && value)
      ) {
        e.Cost = setPackageCostFunc(
          e.Cost,
          e,
          name === 'SystemMargin' ? value : SystemMargin
        );
      } else {
        packagePrice = setPackagePriceFunc(
          e.Cost,
          e,
          name === 'SystemMargin' ? value : SystemMargin,
          name === 'TourOperatorMargin' ? value : TourOperatorMargin
        );
      }
      return {
        ...e,
        ...packagePrice,
      };
    });
    handleSetCustomPrices({
      ...customPrices,
      [name]: value,
      TourOperatorMargin:
        name === 'IsTourOperatorFixedPackage' && value === true
          ? 0
          : name === 'TourOperatorMargin'
          ? value
          : TourOperatorMargin,
      Prices: newPrices,
    });
  };
  // ===== end handle price =====
  const openCloseModalConfirm = (isOpen, header, message, value, type) => {
    isOpen = isOpen === true;
    setModalConfirm({
      isOpen,
      header: isOpen ? header : '',
      message: isOpen ? message : '',
      value: isOpen ? value : '',
      type: isOpen ? type : '',
    });
  };
  let filterPrices = Prices.filter(
    e =>
      !e.IsDelete &&
      (e.MinimumPax.toString().indexOf(inputSearch) !== -1 ||
        e.CurrencyId.toLowerCase().indexOf(inputSearch.toLowerCase()) !== -1 ||
        e.SharingRoomPrice.toString().indexOf(inputSearch) !== -1 ||
        e.Cost.SharingRoomPrice.toString().indexOf(inputSearch) !== -1)
  );
  let pagedData = PaginationScript(filterPrices, 5);
  let listData = pagedData[activePage - 1];
  let { numbersFrom, numbersTo } = getNumberFromTo(listData, activePage, 5);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <Segment>
            <Form>
              <Header as="h5">Margin</Header>
              <Divider />
              <Form.Group widths="equal">
                {/* <Form.Field style={{ verticalAlign: 'bottom' }}>
                  <Checkbox
                    label="Only Use Touress Margin"
                    name="IsTourOperatorFixedPackage"
                    value={!IsTourOperatorFixedPackage}
                    checked={IsTourOperatorFixedPackage}
                    onClick={changeMargin}
                    disabled={isPublished}
                  />
                </Form.Field> */}
                <InputWithLabel
                  type="number"
                  label="Touress Margin"
                  placeholder="Touress Margin"
                  value={SystemMargin}
                  name="SystemMargin"
                  handleChange={changeMargin}
                  disabled={isPublished}
                />
                <InputWithLabel
                  type="number"
                  label="Tour Operator Margin"
                  placeholder="Tour Operator Margin"
                  name="TourOperatorMargin"
                  value={TourOperatorMargin}
                  handleChange={changeMargin}
                  disabled={IsTourOperatorFixedPackage || isPublished}
                />
              </Form.Group>
            </Form>
          </Segment>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={4}>
          <Form>
            <InputWithLabel
              type="text"
              placeholder="Search"
              value={inputSearch}
              handleChange={handleChange}
            />
          </Form>
        </Grid.Column>
        <Grid.Column width={12} textAlign="right">
          <Button
            content="Add Price"
            value={null}
            primary
            onClick={openModalPrice}
            disabled={isPublished}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Table basic="very" selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Minimum pax</Table.HeaderCell>
                <Table.HeaderCell>currency</Table.HeaderCell>
                <Table.HeaderCell>Sharing Room Cost</Table.HeaderCell>
                <Table.HeaderCell>Sharing Room Price</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {listData ? ( //&& listData.length > 0
                listData.map((e, idx) => (
                  <Table.Row key={idx}>
                    <Table.Cell>{e.MinimumPax}</Table.Cell>
                    <Table.Cell>{e.CurrencyId}</Table.Cell>
                    <Table.Cell>
                      {e.Cost ? e.Cost.SharingRoomPrice : 0}
                    </Table.Cell>
                    <Table.Cell>{e.SharingRoomPrice}</Table.Cell>
                    <Table.Cell textAlign="center">
                      <Button
                        content="Edit"
                        positive
                        value={e}
                        onClick={openModalPrice}
                        disabled={isPublished}
                      />
                      <Button
                        content="Delete"
                        negative
                        value={e}
                        name="DELETE"
                        disabled={isPublished}
                        onClick={handleDelete}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colSpan="4" textAlign="center">
                    No data
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
            <Table.Row>
              <Table.HeaderCell colSpan="10">
                <Pagination
                  pagedData={pagedData}
                  numbersTo={numbersTo}
                  numbersFrom={numbersFrom}
                  data={filterPrices}
                  pageChange={pageChange}
                  activePage={activePage}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table>
        </Grid.Column>
      </Grid.Row>
      <ModalTierPrice
        isOpen={modalPrice.isOpen}
        handleClose={closeModalPrice}
        selectedPrice={modalPrice.selectedPrice}
        currencies={currencies}
        customPrices={customPrices}
        handleSetCustomPrices={handleSetCustomPrices}
      />
      <ModalConfirm
        openModal={modalConfirm.isOpen}
        close={openCloseModalConfirm}
        confirm={confirmModalConfirm}
        message={modalConfirm.message}
        header={modalConfirm.header}
      />
    </Grid>
  );
};
TabTierPriceBasedPax.propTypes = {
  customPrices: PropTypes.object,
  handleSetCustomPrices: PropTypes.func,
  isPublished: PropTypes.bool,
  currencies: PropTypes.array,
};
export default React.memo(TabTierPriceBasedPax);
