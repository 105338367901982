import React, { Component } from 'react';
import { Table, Button, Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { HeaderUploadMasterImage } from '../constants/HeaderUploadMasterImage';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../scripts/pagination';
import FilterData from '../../../scripts/filterDataTable';
import InputWithLabel from '../../../components/input/inputWithLabel';
import Pagination from '../../../components/common/pagination';
class tableHistoryTransaction extends Component {
  static propTypes = {
    data: PropTypes.array,
  };
  handleChange = (e, { name, value }) => {
    this.props.handleChangeSearch(name, value);
  };
  pageChange = (e, { activePage }) => {
    this.props.pageChange(activePage);
  };
  render() {
    const {
      valueDropDown,
      disableSearch,
      searchInput,
      searchInputObject,
      activePage,
    } = this.props;
    let filterData = '';
    if (searchInputObject !== '') {
      filterData = FilterData(
        this.props.listData,
        valueDropDown,
        searchInputObject
      );
    } else {
      filterData = FilterData(this.props.listData, 'Name', searchInput);
    }
    let pagedData = datasetPagination(filterData, 10);
    let listData = pagedData[activePage - 1];

    let { numbersFrom, numbersTo } = getNumberFromTo(listData, activePage, 10);
    return (
      <div>
        <Form>
          <Form.Group widths="equal">
            <InputWithLabel
              inline={false}
              label="Search By Object/Item Name"
              type="text"
              name="searchInput"
              value={searchInput}
              placeholder="Search"
              handleChange={this.handleChange}
              disabled={disableSearch.search}
            />
            <InputWithLabel
              inline={false}
              label="Search By Reference Object"
              type="text"
              name="searchInputObject"
              value={searchInputObject}
              placeholder="Search"
              handleChange={this.handleChange}
              disabled={disableSearch.searchObject}
            />
          </Form.Group>
        </Form>
        <Table basic="very" selectable fixed>
          <div style={{ overflow: 'auto' }}>
            <Table.Header>
              <Table.Row>
                {HeaderUploadMasterImage.map((headTableContent, index) => {
                  return (
                    <Table.HeaderCell key={index}>
                      {headTableContent}
                    </Table.HeaderCell>
                  );
                })}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {listData ? (
                listData.map((data, idx) => {
                  return (
                    <Table.Row key={idx} style={{ maxHeight: '1em' }}>
                      <Table.Cell component="th" scope="row" width={2}>
                        {data.Name}
                      </Table.Cell>
                      <Table.Cell width={2}>
                        {valueDropDown === 'AccomodationRoom'
                          ? data.AccommodationProfileName
                            ? data.AccommodationProfileName
                            : '-'
                          : valueDropDown === 'RestaurantMenu'
                          ? data.RestaurantProfileName
                            ? data.RestaurantProfileName
                            : '-'
                          : valueDropDown === 'TransportationUnit'
                          ? data.TransportationTypeName
                            ? data.TransportationTypeName
                            : '-'
                          : '-'}
                      </Table.Cell>

                      <Table.Cell width={8}>
                        {valueDropDown === 'AccomodationProfile'
                          ? data.ProfileImages &&
                            data.ProfileImages.map(url => {
                              return url.ImageName ? url.ImageName + ', ' : '-';
                            })
                          : valueDropDown === 'RestaurantProfile'
                          ? data.RestaurantProfileImages &&
                            data.RestaurantProfileImages.map(url => {
                              return url.ImageName ? url.ImageName + ', ' : '-';
                            })
                          : valueDropDown === 'Excursion'
                          ? data.ObjectImages &&
                            data.ObjectImages.map(url => {
                              return url.ImageName ? url.ImageName + ', ' : '-';
                            })
                          : valueDropDown === 'AccomodationRoom'
                          ? data.ItemImages &&
                            data.ItemImages.map(url => {
                              return url.ImageName ? url.ImageName + ', ' : '-';
                            })
                          : valueDropDown === 'TransportationUnit'
                          ? data.UnitImages &&
                            data.UnitImages.map(url => {
                              return url.ImageName ? url.ImageName + ', ' : '-';
                            })
                          : valueDropDown === 'RestaurantMenu'
                          ? data.RestaurantImages &&
                            data.RestaurantImages.map(url => {
                              return url.ImageName ? url.ImageName + ', ' : '-';
                            })
                          : valueDropDown === 'TransportationProfile'
                          ? data.TransportationProfileImage &&
                            data.TransportationProfileImage.ImageName
                            ? data.TransportationProfileImage.ImageName
                            : '-'
                          : '-'}
                      </Table.Cell>
                      <Table.Cell width={2}>
                        <Button
                          content="Upload Image"
                          onClick={this.props.openModalImage}
                          primary
                          id={data.ServiceItemId || data.Id}
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              ) : (
                <Table.Row>
                  <Table.Cell colSpan="6" textAlign="center">
                    No Data
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>

            <Pagination
              pagedData={pagedData}
              numbersTo={numbersTo}
              numbersFrom={numbersFrom}
              data={this.props.listData}
              pageChange={this.pageChange}
              activePage={activePage}
            />
          </div>
        </Table>
      </div>
    );
  }
}

tableHistoryTransaction.propTypes = {
  listData: PropTypes.array,
  openModalImage: PropTypes.func,
  valueDropDown: PropTypes.string,
  disableSearch: PropTypes.object,
  handleChangeSearch: PropTypes.func,
  pageChange: PropTypes.func,
  searchInput: PropTypes.string,
  searchInputObject: PropTypes.string,
  activePage: PropTypes.number,
};
export default tableHistoryTransaction;
