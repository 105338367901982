import { convertDateFormat, getTotalDays } from './moment';
const initialGeneralInfo = (packageDetail, tourInformation) => {
  let {
    BookingDetailSum,
    GracePeriod,
    MinPax,
    Prices,
    SharedPaxAllotmentPackages,
    IsFeatured,
  } = packageDetail;
  return {
    ...tourInformation,
    tourName: BookingDetailSum.Title,
    description: BookingDetailSum.Description,
    tourType: BookingDetailSum.TourCategory.Id,
    currency: BookingDetailSum.CurrencyId
      ? BookingDetailSum.CurrencyId
      : Prices.CurrencyId,
    maxGuest: BookingDetailSum.FixedPackage
      ? BookingDetailSum.FixedPackage.MaximumGuest
      : 0,
    minGuest: BookingDetailSum.FixedPackage
      ? BookingDetailSum.FixedPackage.MinimumGuest
      : 0,
    destination: BookingDetailSum.City.Id,
    startDate: convertDateFormat(BookingDetailSum.StartDate, 'YYYY-MM-DD'),
    endDate: convertDateFormat(BookingDetailSum.EndDate, 'YYYY-MM-DD'),
    expiredDate: convertDateFormat(BookingDetailSum.ExpiredOn, 'YYYY-MM-DD'),
    graceDate: convertDateFormat(GracePeriod, 'YYYY-MM-DD'),
    minPax: MinPax,
    referenceId: BookingDetailSum.ReferenceId,
    firstDestination: BookingDetailSum.City.Id,
    BookingTemplateSharedPaxId:
      SharedPaxAllotmentPackages.length > 0
        ? SharedPaxAllotmentPackages[0].Id
        : 0,
    isFeatured: IsFeatured,
    // SharingPaxName:
    //   SharedPaxAllotmentPackages.length > 0
    //     ? SharedPaxAllotmentPackages[0].Text
    //     : '',
  };
};

const initialGeneralInfoVariableDate = (packageDetail, tourInformation) => {
  let {
    BookingDetailSum,
    GracePeriod,
    MinPax,
    Prices,
    SharedPaxAllotmentPackages,
  } = packageDetail;
  return {
    ...tourInformation,
    tourName: BookingDetailSum.Title,
    description: BookingDetailSum.Description,
    tourType: BookingDetailSum.TourCategory.Id,
    currency: BookingDetailSum.CurrencyId
      ? BookingDetailSum.CurrencyId
      : Prices.CurrencyId,
    maxGuest: BookingDetailSum.VariableDatePackage
      ? BookingDetailSum.VariableDatePackage.MaximumGuest
      : 0,
    minGuest: BookingDetailSum.VariableDatePackage
      ? BookingDetailSum.VariableDatePackage.MinimumGuest
      : 0,
    destination: BookingDetailSum.City.Id,
    startDate: convertDateFormat(BookingDetailSum.StartDate, 'YYYY-MM-DD'),
    endDate: convertDateFormat(BookingDetailSum.EndDate, 'YYYY-MM-DD'),
    expiredDate: convertDateFormat(BookingDetailSum.ExpiredOn, 'YYYY-MM-DD'),
    graceDate: convertDateFormat(GracePeriod, 'YYYY-MM-DD'),
    minPax: MinPax,
    referenceId: BookingDetailSum.ReferenceId,
    firstDestination: BookingDetailSum.City.Id,
    BookingTemplateSharedPaxId:
      SharedPaxAllotmentPackages.length > 0
        ? SharedPaxAllotmentPackages[0].Id
        : 0,
    isRoomAllotmetNeeded: BookingDetailSum.IsAllotment
      ? BookingDetailSum.IsAllotment
      : false,
    totalDays: getTotalDays(
      BookingDetailSum.StartDate,
      BookingDetailSum.EndDate,
      true
    ),
    // SharingPaxName:
    //   SharedPaxAllotmentPackages.length > 0
    //     ? SharedPaxAllotmentPackages[0].Text
    //     : '',
  };
};

export { initialGeneralInfo, initialGeneralInfoVariableDate };
