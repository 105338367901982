import React from 'react';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
// import styles from './styles';

const collapseButton = props => (
  <Button
    name={props.name}
    value={props.isCollapse}
    icon={!props.isCollapse ? 'chevron down' : 'chevron up'}
    size="massive"
    floated="right"
    style={{
      background: '#fefefe',
      color: '#38af95',
      padding: '0px',
    }}
    onClick={props.handleCollapse}
  />
);

collapseButton.propTypes = {
  isCollapse: PropTypes.bool,
  handleCollapse: PropTypes.func,
  name: PropTypes.string,
};
export default collapseButton;
