import { validatePhone } from '../../../../../components/genericFunction/validation';
const handleValidation = (state, fields) => {
  const validate = {};
  fields.map(field => {
    if (state[field] === '') {
      validate[field] = {
        error: true,
        message: `Field can't be empty!!`,
      };
    } else if (field === 'Email') {
      if (state.Email.indexOf('@' && '.') === -1) {
        validate[field] = {
          error: true,
          message: `Email format not valid!!`,
        };
      }
    } else if (field === 'PhoneNbr') {
      let val = validatePhone(state.PhoneNbr);
      if (val) {
        validate[field] = {
          error: true,
          message: `Phone number format not valid!`,
        };
      } else {
        delete validate[field];
      }
    } else if (field === 'Name') {
      if (state.Name.length > 50) {
        validate[field] = {
          error: true,
          message: `Maximum 50 character`,
        };
      }
    }
    // else if (field === 'OptimumDuration') {
    //   if (state.OptimumDuration === '00:00') {
    //     validate[field] = {
    //       error: true,
    //       message: `Can't be set 00:00`,
    //     };
    //   }
    // }
    else {
      delete validate[field];
    }
    return validate;
  });

  return validate;
};

export default handleValidation;
