import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Button, Popup } from 'semantic-ui-react';
import TableTourGuidePrice from './table/TableTourGuidePrice';
import ModalTourGuidePrice from './modal/ModalTourGuidePrice';
import { connect } from 'react-redux';
import { getServicePriceAction } from '../../../../actions/servicePriceAction';
import setNewDataPrice from '../service/data/setNewDataPrice';
import setDataPrice from '../service/data/setDataPrice';
import setDataSaveModalPrice from '../service/data/setDataSaveModalPrice';
import { getCurrenciesAction } from '../../../../actions/currenciesAction';
import { convertDateFormat } from '../../../../scripts/moment';
import ModalConfirm from '../../../../components/modal/modalConfirmationTwoButton';
import { validationTourGuide } from '../service/validation/validationTourGuide';
import { validationPrice } from '../service/validation/validationPrice';
const FILL = 'Please fill ';
class TourGuidePrice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalPrice: false,
      objectPrice: {},
      errorsModal: {
        Currency: { error: false, message: FILL + 'currency' },
        Hours: { error: false, message: FILL + 'hours' },
        TourGuideLanguage: { error: false, message: FILL + 'language' },
        StartDate: { error: false, message: 'Please check date' },
        EndDate: { error: false, message: 'Please check date' },
      },
    };
  }
  handleModalPrice = () => {
    let { openModalPrice } = this.state;
    let data = setNewDataPrice();
    this.setState({
      openModalPrice: !openModalPrice,
      objectPrice: data,
      indexEdit: -1,
    });
  };

  componentDidMount() {
    this.props.getCurrenciesAction();
  }

  handleEditTourGuidePrice = (e, { value, id, name }) => {
    if (value === 0) {
      this.setState({
        openModalPrice: true,
        objectPrice: this.props.data[id],
        indexEdit: id,
      });
    } else {
      this.props.getServicePriceAction(value).then(async res => {
        this.setState({
          openModalPrice: true,
          objectPrice: setDataPrice(value, res.value.data, name),
          indexEdit: id,
        });
      });
    }
    // .catch(err => this.handleFailedMessage(err.response.data.Message));;
  };

  handleChange = (e, { name, value }) => {
    let { objectPrice, errorsModal } = this.state;
    if (name === 'StartDate' || name === 'EndDate') {
      objectPrice[name] = convertDateFormat(value, 'YYYY-MM-DD');
    } else {
      objectPrice[name] = value;
    }
    this.setState({ objectPrice });
    let validation = validationPrice(objectPrice, errorsModal);
    this.setState({ errorsModal: validation });
  };
  handleChangePrice = (name, value) => {
    let { objectPrice } = this.state;
    objectPrice[name] = value;
    this.setState({ objectPrice });
  };

  returnError = validation => {
    let err =
      validation.Currency.error ||
      validation.Hours.error ||
      validation.TourGuideLanguage.error ||
      validation.StartDate.error ||
      validation.EndDate.error;
    return err;
  };
  handleSavePriceTourDetail = async () => {
    let { indexEdit, objectPrice, errorsModal } = this.state;
    let { tourGuideObject, errorFormTourGuide } = this.props;
    let language = this.props.listLanguage.find(
      data => data.value === objectPrice.TourGuideLanguage
    );
    let data = {
      ...objectPrice,
      Language: language,
    };
    let validation = validationPrice(objectPrice, errorsModal);
    let error = this.returnError(validation);
    if (!error) {
      this.props.handlePushLanguages(indexEdit, setDataSaveModalPrice(data));
      this.setState({ openModalPrice: false });
      let err = validationTourGuide(tourGuideObject, errorFormTourGuide);
      this.props.setErrorFormTourGuide(err);
    }
    this.setState({ errorsModal: validation });
  };

  // validatePrice = () => {
  //   let isError = false;
  //   const { objectPrice, errorsModal } = this.state;
  //   const errors = errorsModal;
  //   if (objectPrice.TourGuideLanguage.length < 1) {
  //     isError = true;
  //     errors.TourGuideLanguage.error = true;
  //     errors.TourGuideLanguage.message = "Language Can't Be Empty";
  //   }
  //   if (objectPrice.Hours === '0' || objectPrice.Hours === 0) {
  //     isError = true;
  //     errors.Hours.error = true;
  //     errors.Hours.message = "Hours Can't Be Empty";
  //   }
  //   if (objectPrice.Currency === '') {
  //     isError = true;
  //     errors.Currency.error = true;
  //     errors.Currency.message = "Currency Can't Be Empty";
  //   }
  //   this.setState({
  //     errorsModal: {
  //       ...errors,
  //     },
  //   });
  //   return isError;
  // };
  confirmDelete = (e, { value, id, name }) => {
    this.setState({
      ServiceItemId: value,
      openConfirm: true,
      messageConfirm: 'Are you sure to delete ' + name + '?',
      languangeName: name,
      indexLanguage: id,
    });
  };

  deletePrice = async () => {
    const { ServiceItemId, indexLanguage } = this.state;
    let { tourGuideObject, errorFormTourGuide, listOfPriceGuide } = this.props;
    const { data } = this.props;
    if (ServiceItemId === 0) {
      let length = indexLanguage - data.length;
      data.splice(indexLanguage, 1);
      listOfPriceGuide.splice(length, 1);
    } else {
      data[indexLanguage].Action = 1;
    }
    let err = validationTourGuide(tourGuideObject, errorFormTourGuide);
    this.props.setErrorFormTourGuide(err);
    this.setState({
      openConfirm: false,
    });
  };

  handleClose = () =>
    this.setState({
      openConfirm: false,
    });

  render() {
    let { openModalPrice, objectPrice } = this.state;
    const { currencies, disabled, data, listLanguage, openPopUp } = this.props;
    return (
      <Grid columns="equal" style={{ marginTop: '0.5em' }}>
        <Grid.Row>
          <Grid.Column>
            <Header as="h3" content="Tour Detail Price" />
          </Grid.Column>
          <Grid.Column />
          <Grid.Column floated="right">
            <Popup
              trigger={
                <Button
                  positive
                  content="Add New Price"
                  onClick={this.handleModalPrice}
                  floated="right"
                  disabled={disabled}
                />
              }
              content="Please fill Language"
              open={openPopUp}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <TableTourGuidePrice
              data={data}
              handleEdit={this.handleEditTourGuidePrice}
              disabled={disabled}
              handleDelete={this.confirmDelete}
            />
          </Grid.Column>
        </Grid.Row>
        <ModalTourGuidePrice
          openModal={openModalPrice}
          handleClose={this.handleModalPrice}
          objectPrice={objectPrice}
          currencies={currencies}
          handleChange={this.handleChange}
          handleChangePrice={this.handleChangePrice}
          handleSave={this.handleSavePriceTourDetail}
          listLanguage={listLanguage}
          errors={this.state.errorsModal}
        />
        <ModalConfirm
          openModal={this.state.openConfirm}
          size="mini"
          close={this.handleClose}
          confirm={this.deletePrice}
          message={this.state.messageConfirm}
        />
      </Grid>
    );
  }
}

TourGuidePrice.propTypes = {
  data: PropTypes.array,
  currencies: PropTypes.array,
  getCurrenciesAction: PropTypes.func,
  getServicePriceAction: PropTypes.func,
  disabled: PropTypes.bool,
  handlePushLanguages: PropTypes.func,
  listLanguage: PropTypes.array,
  openPopUp: PropTypes.bool,
  setErrorFormTourGuide: PropTypes.bool,
};
function mapStateToProps(state) {
  return {
    currencies: state.currenciesStore.currencies.map((cur, i) => {
      return { key: i, text: cur.Id, value: cur.Id };
    }),
  };
}
export default connect(
  mapStateToProps,
  {
    getServicePriceAction,
    getCurrenciesAction,
  }
)(TourGuidePrice);
