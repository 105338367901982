import React from 'react';
import { Table, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import LoaderModal from '../../../../components//modal/loaderModal';
import ActionButton from '../button/ActionButtonBooking';
// import { StyleButton } from '../../../shared/components/common/StylesCommon';
import { Link } from 'react-router-dom';
const TableListOfTransactionDownPayment = props => (
  <Table basic="very" selectable fixed>
    <LoaderModal loading={props.loading} />
    <div style={{ overflow: 'auto' }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell rowSpan="2">Booking Number</Table.HeaderCell>
          <Table.HeaderCell rowSpan="2">Tour Name</Table.HeaderCell>
          <Table.HeaderCell rowSpan="2">Company Name</Table.HeaderCell>
          <Table.HeaderCell colSpan="3" textAlign="center">
            First Payment
          </Table.HeaderCell>
          <Table.HeaderCell rowSpan="2">Special Adjustment</Table.HeaderCell>
          <Table.HeaderCell rowSpan="2">Payment Detail</Table.HeaderCell>
          <Table.HeaderCell rowSpan="2">Action</Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell textAlign="center">Percentage(%) </Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Amount</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Status</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {props.listData ? (
          props.listData.map((downpayment, idx) => {
            return (
              <Table.Row key={idx}>
                <Table.Cell component="th" scope="row" width={1}>
                  {/* <Button
                    onClick={props.openDetailTransaction}
                    id={downpayment.BookingId}
                    content={downpayment.BookingId}
                    style={StyleButton.buttonTransparent}
                  /> */}
                  <Link
                    to={`/tour-transaction/edit/${
                      downpayment.PackageType === 0 ? 'Custom' : 'Fixed'
                    }/${downpayment.BookingId}`}
                  >
                    {downpayment.BookingId}
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  {/* <Link to={`/Transaction-Details/${downpayment.BookingId}`}>
                    {downpayment.BookingName}
                  </Link> */}
                  <Link
                    to={
                      downpayment.PackageType === 0
                        ? `/tour-transaction/edit/Custom/${
                            downpayment.BookingId
                          }`
                        : `/Package-Details/Fixed/${
                            downpayment.BookingTemplateRefId
                          }`
                    }
                  >
                    {downpayment.BookingName}
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  <Link
                    to={`/Customer-Profile-Detail/detail/${
                      downpayment.CompanyCode
                    }`}
                  >
                    {downpayment.CompanyName}
                  </Link>
                </Table.Cell>
                <Table.Cell>{downpayment.FirstPaymentPercentage}</Table.Cell>
                <Table.Cell>
                  {downpayment.Currency +
                    '           ' +
                    new Intl.NumberFormat('de-DE').format(
                      downpayment.FirstPaymentAmount
                    )}
                </Table.Cell>
                <Table.Cell>Waiting</Table.Cell>

                <Table.Cell>
                  <Button
                    primary
                    content="See Detail"
                    onClick={props.openModalSpecialAdjusment}
                    id={downpayment.BookingId || downpayment.Id}
                    name={downpayment.CompanyName}
                    value={downpayment.TransactionStatus}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Button
                    onClick={props.openDetailTransaction}
                    id={downpayment.BookingId}
                    // content={downpayment.BookingId}
                    content=" Payment Detail"
                    // style={StyleButton.buttonTransparent}
                    positive
                  />
                </Table.Cell>
                <ActionButton
                  id={downpayment.BookingId || downpayment.Id}
                  handleClick={props.actionTransaction}
                  value={downpayment.TransactionStatus || downpayment.Status}
                />
              </Table.Row>
            );
          })
        ) : (
          <Table.Row>
            <Table.Cell colSpan="8" textAlign="center">
              No Data
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </div>
  </Table>
);
TableListOfTransactionDownPayment.propTypes = {
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  actionTransaction: PropTypes.func,
  loading: PropTypes.bool,
  openModalSpecialAdjusment: PropTypes.func,
  openDetailTransaction: PropTypes.func,
};
export default TableListOfTransactionDownPayment;
