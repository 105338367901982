const setHistoryTransaction = state => {
  let destructure = [];
  let data = {};
  state.map(object => {
    data = {
      Id: object.Id ? object.Id : '',
      Title: object.Title ? object.Title : '',
      PackageType: object.PackageType ? object.PackageType : '',
      CreatedBy: object.CreatedBy ? object.CreatedBy : {},
      Status: object.Status ? object.Status : '',
      TourTotalPrice: object.FixedPackage
        ? object.FixedPackage.BookingCommission.TotalPriceForCustomer
        : object.TourTotalPrice,
      TotalPayed: object.TotalPayed ? object.TotalPayed : 0,
    };
    return destructure.push(data);
  });
  return destructure;
};

export default setHistoryTransaction;
