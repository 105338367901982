import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const tableHeader = {
  padding: '3px',
  borderBottom: '1px solid #252525',
  borderTop: 'none',
};
const tableBody = {
  padding: '3px',
  border: 'none',
};
const tableNoBorder = {
  border: 'none',
  padding: '3px',
};

const tablePackageDetail = props => {
  let {
    supplements,
    tourPriceSum,
    isFixedPackage,
    splitCommission,
    commissions,
    currency,
    totalTourPrice,
    totalPayable,
    additionalItem,
    additionalServices,
  } = props;
  return (
    <Table style={{ margin: '0px' }} basic="very">
      <Table.Header>
        <Table.Row style={{ padding: '0px' }}>
          <Table.HeaderCell style={tableHeader}>Room Type</Table.HeaderCell>
          <Table.HeaderCell style={tableHeader}>Guest</Table.HeaderCell>
          <Table.HeaderCell style={tableHeader} textAlign="right">
            Price/Pax
          </Table.HeaderCell>
          <Table.HeaderCell style={tableHeader} textAlign="right">
            Sub Total
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {tourPriceSum.length !== 0 &&
          tourPriceSum.map(
            (item, index) =>
              item.data.Pax !== 0 && (
                <Table.Row key={index}>
                  <Table.Cell style={tableHeader}>{item.name}</Table.Cell>
                  <Table.Cell style={tableHeader}>
                    {item.data.Pax || 0}
                  </Table.Cell>
                  <Table.Cell style={tableHeader} textAlign="right">
                    {item.data.PricePerPax
                      ? item.data.Currency +
                        ' ' +
                        item.data.PricePerPax.toLocaleString('ID')
                      : item.data.Currency + ' 0'}
                  </Table.Cell>
                  <Table.Cell style={tableHeader} textAlign="right">
                    {item.data.TotalPrice
                      ? item.data.Currency +
                        ' ' +
                        item.data.TotalPrice.toLocaleString('ID')
                      : item.data.Currency + ' 0'}
                  </Table.Cell>
                </Table.Row>
              )
          )}
        {isFixedPackage && supplements.length !== 0 && (
          <Table.Row>
            <Table.Cell style={tableHeader}>
              <strong>Additional Price</strong>
            </Table.Cell>
            <Table.Cell collspan="3" style={tableNoBorder} />
          </Table.Row>
        )}
        {isFixedPackage &&
          supplements.map(item => {
            let subTotal = item.Price * item.Qty;
            return (
              <Table.Row
                key={
                  item.BookingTemplateSupplementId ||
                  item.bookingTemplateSupplementId
                }
              >
                <Table.Cell style={tableHeader}>
                  {item.Name || item.name}
                </Table.Cell>
                <Table.Cell style={tableHeader}>
                  {item.Qty || item.qty || 0}
                </Table.Cell>
                <Table.Cell style={tableHeader} textAlign="right">
                  {(item.currencyId ? item.currencyId : currency) + ' '}{' '}
                  {item.Price
                    ? item.Price.toLocaleString('ID')
                    : item.currencyId
                    ? item.unitCost.toLocaleString('ID')
                    : 0}
                </Table.Cell>
                <Table.Cell style={tableHeader} textAlign="right">
                  {(item.currencyId ? item.currencyId : currency) + ' '}
                  {subTotal
                    ? subTotal.toLocaleString('ID')
                    : item.currencyId
                    ? item.totalCost.toLocaleString('ID')
                    : 0}
                </Table.Cell>
              </Table.Row>
            );
          })}
        {/* additional service */}
        {additionalServices && additionalServices.length !== 0 && (
          <Table.Row>
            <Table.Cell style={tableHeader}>
              <strong>Additional Services</strong>
            </Table.Cell>
            <Table.Cell colSpan={3} style={tableNoBorder} />
          </Table.Row>
        )}
        {additionalServices &&
          additionalServices.map((service, index) => (
            <Table.Row key={index}>
              <Table.Cell style={tableHeader}>
                {service.AdditionalServiceName}
              </Table.Cell>
              <Table.Cell style={tableHeader}>
                {service.Quantity || 0}
              </Table.Cell>
              <Table.Cell style={tableHeader} textAlign="right">
                <span>
                  {service.Currency + ' '}
                  {service && service.Amount
                    ? service.Amount.toLocaleString('ID')
                    : '-'}
                </span>
              </Table.Cell>
              <Table.Cell style={tableHeader} textAlign="right">
                <span>
                  {service.Currency + ' '}
                  {service
                    ? (service.Quantity * service.Amount).toLocaleString('ID')
                    : '-'}
                </span>
              </Table.Cell>
            </Table.Row>
          ))}
        {/* end additional service */}
        {additionalItem && additionalItem.length !== 0 && (
          <Table.Row>
            <Table.Cell style={tableHeader}>
              <strong>Special Adjustments</strong>
            </Table.Cell>
            <Table.Cell colSpan={3} style={tableNoBorder} />
          </Table.Row>
        )}
        {additionalItem &&
          additionalItem
            .filter(item => !item.IsDelete)
            .map((additional, index) => (
              <Table.Row key={index}>
                <Table.Cell style={tableHeader}>
                  {additional.Description}
                </Table.Cell>
                <Table.Cell style={tableHeader}>
                  {additional.Qty || 0}
                </Table.Cell>
                <Table.Cell style={tableHeader} textAlign="right">
                  <span style={additional.UnitCost < 0 ? { color: 'red' } : {}}>
                    {currency}{' '}
                    {!additional ? null : additional.UnitCost < 0 ? (
                      <span>
                        ({(additional.UnitCost * -1).toLocaleString('id')})
                      </span>
                    ) : (
                      <span> {additional.UnitCost.toLocaleString('id')}</span>
                    )}
                  </span>
                </Table.Cell>
                <Table.Cell style={tableHeader} textAlign="right">
                  <span
                    style={additional.TotalCost < 0 ? { color: 'red' } : {}}
                  >
                    {currency}{' '}
                    {!additional ? null : additional.TotalCost < 0 ? (
                      <span>
                        ({(additional.TotalCost * -1).toLocaleString('id')})
                      </span>
                    ) : (
                      <span> {additional.TotalCost.toLocaleString('id')}</span>
                    )}
                  </span>
                </Table.Cell>
              </Table.Row>
            ))}
        <Table.Row>
          <Table.Cell style={tableNoBorder}>Total Price</Table.Cell>
          <Table.Cell style={tableNoBorder} />
          <Table.Cell style={tableNoBorder} />
          <Table.Cell style={tableNoBorder} textAlign="right">
            {currency + ' ' + totalTourPrice.toLocaleString('ID')}
          </Table.Cell>
        </Table.Row>
        {isFixedPackage && splitCommission !== null && (
          <Table.Row>
            <Table.Cell style={tableHeader}>
              <strong>Commission Detail</strong>
            </Table.Cell>
            <Table.Cell colSpan={3} style={tableNoBorder} />
          </Table.Row>
        )}
        {isFixedPackage && (
          <Table.Row>
            <Table.Cell colSpan={4} style={tableBody}>
              <strong>
                Do you want to split the staff commission?
                {splitCommission ? ' Yes' : ' No'}
              </strong>
            </Table.Cell>
          </Table.Row>
        )}
        {isFixedPackage &&
          commissions &&
          commissions.map((commission, index) => (
            <Table.Row key={index}>
              <Table.Cell style={tableHeader}>
                {commission.Description}
              </Table.Cell>
              <Table.Cell style={tableHeader}>
                {commission.TotalPax || 0}
              </Table.Cell>
              <Table.Cell style={tableHeader} textAlign="right">
                {currency + ' '}
                {commission ? commission.UnitPrice.toLocaleString('ID') : '-'}
              </Table.Cell>
              <Table.Cell style={tableHeader} textAlign="right">
                <span style={{ color: '#f44336' }}>
                  {currency + ' '}
                  {commission
                    ? ' (' + commission.TotalPrice.toLocaleString('ID') + ')'
                    : '(0)'}
                </span>
              </Table.Cell>
            </Table.Row>
          ))}

        {isFixedPackage && (
          <Table.Row>
            <Table.Cell style={tableNoBorder}>
              <strong>Total Payable</strong>
            </Table.Cell>
            <Table.Cell style={tableNoBorder} />
            <Table.Cell style={tableNoBorder} />
            <Table.Cell style={tableNoBorder} textAlign="right">
              {currency + ' ' + totalPayable.toLocaleString('ID')}
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};

tablePackageDetail.propTypes = {
  supplements: PropTypes.array,
  tourPriceSum: PropTypes.array,
  isFixedPackage: PropTypes.bool,
  splitCommission: PropTypes.number,
  commissions: PropTypes.array,
  currency: PropTypes.string,
  totalTourPrice: PropTypes.number,
  totalPayable: PropTypes.number,
  additionalItem: PropTypes.array,
  additionalServices: PropTypes.array,
};
export default tablePackageDetail;
