import apiClient from './apiClient';
import { GET_TRANSACTION_HISTORY_BY_STATUS_URL } from './apiUrl';
const getTransactionHistoryByStatusApi = (
  start,
  end,
  createStart,
  createEnd,
  status
) => {
  return apiClient.get(
    GET_TRANSACTION_HISTORY_BY_STATUS_URL(
      start,
      end,
      createStart,
      createEnd,
      status
    )
  );
};

export default getTransactionHistoryByStatusApi;
