import React from 'react';
import PropTypes from 'prop-types';
// import { getHourAndMinute } from '../../../genericFunction/moment';
import { Dropdown, Icon } from 'semantic-ui-react';
const ButtonDropdown = props => {
  // const data = this.props.activity;
  let {
    dropdowns,
    handleClick,
    title,
    dropdownStyles,
    icon,
    disabled,
    pointing,
    labeled,
    className,
    loading,
  } = props;
  return (
    <Dropdown
      text={title}
      icon={icon}
      pointing={pointing}
      labeled={labeled}
      button
      style={dropdownStyles}
      className={className ? className : 'icon'}
      disabled={disabled}
      loading={loading}
    >
      <Dropdown.Menu style={{ zIndex: '11', margin: '5px 0px' }}>
        {dropdowns.map((item, index) => (
          <Dropdown.Item
            key={'drop' + index}
            name={item.value}
            value={item.type}
            onClick={handleClick}
            icon={item.icon}
          >
            <div style={{ margin: '0px', size: '100%' }}>
              <Icon name={item.icon} />
              {item.title}
            </div>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

ButtonDropdown.propTypes = {
  name: PropTypes.string,
  handleClick: PropTypes.func,
  title: PropTypes.string,
  dropdowns: PropTypes.array,
  dropdownStyles: PropTypes.object,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  pointing: PropTypes.bool,
  labeled: PropTypes.bool,
  className: PropTypes.string,
  loading: PropTypes.bool,
};
export default ButtonDropdown;
