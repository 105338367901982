import React from 'react';
import { Table, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ActionButton from '../../../../../components/common/buttonEditAndDelete';
const tableAccomodationList = props => (
  <Table basic="very" selectable>
    <Table.Header>
      <Table.Row>
        {props.headerTable.map((headTableContent, index) => {
          return (
            <Table.HeaderCell key={index}>{headTableContent}</Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {props.listData && props.listData.length > 0 ? (
        props.listData.map((data, idx) => {
          return props.useInResourceBookingList ? (
            <Table.Row key={idx}>
              <Table.Cell>{data.Name}</Table.Cell>
              <Table.Cell>{data.Description}</Table.Cell>
              <Table.Cell>{data.TransportationTypeName}</Table.Cell>
              <Table.Cell>TransportationRatingName</Table.Cell>
              <Table.Cell>
                {data.EstimatedTotalPrice.CurrencyId}{' '}
                {data.EstimatedTotalPrice.Price.toLocaleString('id')}
              </Table.Cell>
              <Table.Cell>
                <Button
                  content="Choose"
                  primary
                  value={data}
                  onClick={props.handleChooseProfileForRBL}
                />
              </Table.Cell>
            </Table.Row>
          ) : (
            <Table.Row key={idx}>
              <Table.Cell>
                <Link to={`/Transportation-Profile/Detail/${data.Id}`}>
                  {data.Name}
                </Link>
              </Table.Cell>
              {/* <Table.Cell>{data.AddressObject.Address1}</Table.Cell> */}
              {/* <Table.Cell>{data.AddressObject.Address1}</Table.Cell>
              <Table.Cell>{data.AddressObject.Region.Name}</Table.Cell>
              <Table.Cell> {data.AddressObject.City.Name}</Table.Cell> */}
              <Table.Cell>{data.Address}</Table.Cell>
              <Table.Cell>{data.Region.Name}</Table.Cell>
              <Table.Cell>{data.City.Name}</Table.Cell>
              <Table.Cell>{data.Telephone}</Table.Cell>
              <Table.Cell>{data.TransportationProfileCategory.Name}</Table.Cell>
              {props.useInResourceBookingList ? (
                <Table.Cell>
                  {data.EstimatedTotalPrice.CurrencyId}{' '}
                  {data.EstimatedTotalPrice.Price.toLocaleString('id')}
                </Table.Cell>
              ) : null}
              <ActionButton
                id={data.Id}
                handleClickEdit={props.buttonEdit}
                handleClickDelete={props.buttonDelete}
                name1="Edit"
                name2="Delte"
                content1="Edit"
                content2="Delete"
              />
            </Table.Row>
          );
        })
      ) : (
        <Table.Row>
          <Table.Cell colSpan={props.headerTable.length} textAlign="center">
            No Data
          </Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  </Table>
);

tableAccomodationList.propTypes = {
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  buttonEdit: PropTypes.func,
  buttonDelete: PropTypes.func,
  useInResourceBookingList: PropTypes.bool,
  handleChooseProfileForRBL: PropTypes.func,
};
export default tableAccomodationList;
