import apiClient from '../apiClient';
import { GET_MASTER_REFERENCE_RESTAURANT_PROFILE_SPECIALIZATIONS_LIST_URL } from '../apiUrl';

const getRestaurantProfileSpecializationsApi = () => {
  return apiClient.get(
    GET_MASTER_REFERENCE_RESTAURANT_PROFILE_SPECIALIZATIONS_LIST_URL
  );
};

export default getRestaurantProfileSpecializationsApi;
