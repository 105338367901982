import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import styles from './Styles';
import PropTypes from 'prop-types';
// import { convertDateFormat } from './../../scripts/moment';
import { datasetPagination } from '../../../../scripts/pagination';
const AccommodationAvaibility = props => {
  const { roomAllotments, activePage } = props;
  return roomAllotments.map((item, i) => {
    return (
      <Grid.Row key={'room' + i} style={{ padding: '5px 0px' }}>
        <Grid.Column verticalAlign="middle" textAlign="right" width={2}>
          {item.ServiceItemName}
        </Grid.Column>
        <Grid.Column width={14}>
          <Grid>
            <Grid.Row>
              <RenderAllotment
                dates={item.RoomAllotments}
                activePage={activePage}
              />
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    );
  });
};

const RenderAllotment = props => {
  const { dates, activePage } = props;
  let pagedData = datasetPagination(dates, 7);
  let showDates = pagedData.length !== 0 ? pagedData[activePage - 1] : [];
  return showDates.map((item, i) => {
    let style = {
      padding: '5px 0px',
    };
    if (item !== null && item.IsStillValid && item.StockLeft > 0) {
      style['background'] = 'rgba(56,175,149,0.2)';
    } else {
      style['background'] = 'rgba(237,51,51,0.2 )';
      // style['background'] = 'rgba(242,244,51,0.2)';
    }

    return (
      <Grid.Column
        key={'date' + i}
        style={{ padding: '0px 2px', width: '124px' }}
      >
        <Segment style={item || { height: '60px' }}>
          {item && (
            <Grid textAlign="center">
              <Grid.Row columns={1} style={styles.fivePaddingTop}>
                <Grid.Column style={styles.sizeFontAllotment}>
                  Allotments: {item.Qty}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1} style={style}>
                <Grid.Column style={styles.sizeFontAllotment}>
                  {!item.IsStillValid
                    ? 'On Request'
                    : item.StockLeft > 0
                      ? `${item.StockLeft} Available`
                      : 'On Request'}
                  {/* {item.StockLeft > 0 ? item.StockLeft : 'Not'} Available */}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
        </Segment>
      </Grid.Column>
    );
  });
};
AccommodationAvaibility.propTypes = {
  roomAllotments: PropTypes.array,
  activePage: PropTypes.number,
};

export default AccommodationAvaibility;
