// import * as types from '../../../actions/actionTypes';
import * as types from './constant/actionTypes';

const initialState = {
  loading: false,
  getHistoryTransactionByCode: [],
  getHistoryTransactionByCodeStatus: '',
  getSupervisorByCompanyCodePositionId: [],
  getSupervisorByCompanyCodePositionIdStatus: '',
  errors: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_STATUS_CUSTOMER_PROFILE_PAGE:
      return {
        ...state,
        getHistoryTransactionByCodeStatus: '',
      };

    case types.GET_HISTORY_TRANSACTION_BY_CODE:
      return { ...state };
    case types.GET_HISTORY_TRANSACTION_BY_CODE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        getHistoryTransactionByCodeStatus: 'failed',
      };
    case types.GET_HISTORY_TRANSACTION_BY_CODE_PENDING:
      return { ...state, loading: true };
    case types.GET_HISTORY_TRANSACTION_BY_CODE_FULFILLED:
      return {
        ...state,
        getHistoryTransactionByCode: action.payload.data,
        loading: false,
        getHistoryTransactionByCodeStatus: 'success',
      };

    case types.GET_SUPERVISOR_BY_COMPANY_CODE_POSITION_ID:
      return { ...state };
    case types.GET_SUPERVISOR_BY_COMPANY_CODE_POSITION_ID_FULFILLED:
      return {
        ...state,
        getSupervisorByCompanyCodePositionId: action.payload.data,
        loading: false,
        getSupervisorByCompanyCodePositionIdStatus: true,
      };
    case types.GET_SUPERVISOR_BY_COMPANY_CODE_POSITION_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_SUPERVISOR_BY_COMPANY_CODE_POSITION_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.message },
        getSupervisorByCompanyCodePositionIdStatus: false,
      };

    default:
      return state;
  }
};

export default reducer;
