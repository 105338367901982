import moment from 'moment';
const setRestaurantDataObject = (
  data,
  accessibleFromDestructure,
  imageDestructure
) => {
  const {
    Id,
    Name,
    Description,
    LongDescription,
    Address,
    PhoneNbr,
    EmailAddress,
    OperationStartTime,
    OperationEndTime,
    Type,
    SpecialityId,
    AddressObject,
    Rating,
    ProfileSpecializations,
  } = data;
  let restaurantProfiledId = {
    Id: Id ? Id : '',
    Name: Name ? Name : '',
    Description: Description ? Description : '',
    LongDescription: LongDescription ? LongDescription : '',
    Address: Address ? Address : '',
    PhoneNbr: PhoneNbr ? PhoneNbr : '',
    Email: EmailAddress ? EmailAddress : '',
    OperationStartTime:
      OperationStartTime && moment(OperationStartTime).format('HH:mm')
        ? moment(OperationStartTime).format('HH:mm')
        : '11:00',
    OperationEndTime:
      OperationEndTime && moment(OperationEndTime).format('HH:mm')
        ? moment(OperationEndTime).format('HH:mm')
        : '21:00',
    RestaurantType: Type ? Type : '',
    RestaurantSpeciality: SpecialityId ? SpecialityId : '',
    CityId: AddressObject.City.Id ? AddressObject.City.Id : '',
    RegionId: AddressObject.Region.Id ? AddressObject.Region.Id : '',
    CountryId: AddressObject.Country.Id ? AddressObject.Country.Id : '',
    SpecializationId: SpecialityId ? SpecialityId : '',
    AddressObject: {
      Address1: AddressObject.Address1 ? AddressObject.Address1 : '',
      Address2: AddressObject.Address2 ? AddressObject.Address2 : '',
      Address3: AddressObject.Address3 ? AddressObject.Address3 : '',
      AreaId: AddressObject.AreaId ? AddressObject.AreaId : '',
      PostalCode: AddressObject.PostalCode ? AddressObject.PostalCode : '',
      Landmark: AddressObject.Landmark ? AddressObject.Landmark : '',
      CityId: AddressObject.City.Id ? AddressObject.City.Id : '',
      RegionId: AddressObject.Region.Id ? AddressObject.Region.Id : '',
      CountryId: AddressObject.Country.Id ? AddressObject.Country.Id : '',
      PhoneNbr: PhoneNbr ? PhoneNbr : '',
      Phone: PhoneNbr ? PhoneNbr : '',
      Email: EmailAddress ? EmailAddress : '',
    },
    AccessibleFrom: accessibleFromDestructure,
    RestaurantProfileImages: imageDestructure,
    RatingId: Rating && Rating.Id ? Rating.Id : '',
    ProfileSpecializations: ProfileSpecializations,
  };
  return restaurantProfiledId;
};

const setRestaurantRequestData = restaurantProfiledId => {
  const {
    AddressObject,
    CompanyCode,
    RestaurantType,
    AccessibleFrom,
  } = restaurantProfiledId;
  let data = {
    ...restaurantProfiledId,
    CompanyCode: CompanyCode ? CompanyCode : 10000,
    Address: AddressObject.Address1,
    AreaId: AddressObject.AreaId,
    CityId: AddressObject.CityId,
    RegionId: AddressObject.RegionId,
    CountryId: AddressObject.CountryId,
    PhoneNbr: AddressObject.PhoneNbr,
    Email: AddressObject.Email,
    SpecializationId: RestaurantType,
    AccessibleFrom: AccessibleFrom.map(city => {
      return { CityId: city.Id };
    }),
  };
  return data;
};

export { setRestaurantDataObject, setRestaurantRequestData };
