const styles = {
  tabActive: {
    margin: 0,
    padding: '15px',
    fontWeight: 'bold',
    borderBottom: '2px solid black',
    color: 'black',
    borderRadius: '0px',
  },
  tabNonActive: {
    margin: 0,
    padding: '15px',
    fontWeight: 'bold',
    color: '#252525',
    borderBottom: '2px solid lightgray',
    borderRadius: '0px',
  },
};
export default styles;
