export const transportationProfileGeneralField = [
  'Name',
  'TransportationProfileCategoryId',
  'TransportationProfileRatingId',
];

export const transportationProfileContactField = [
  'Address1',
  'PhoneNbr',
  'Email',
  'CityId',
  'RegionId',
  'CountryId',
  'AreaId',
];

// export default transportationProfieldField;
