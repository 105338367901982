import * as types from '../actions/actionTypes';
const initialState = {
  loading: false,
  servicePriceType: [],
  errors: {},
  supplierPriceList: [],
  getSupplierPriceStatus: '',
  postSupplierPriceStatus: '',
  putSupplierPriceStatus: '',
  deleteSupplierPriceStatus: '',
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SERVICE_PRICE_TYPE: {
      return { ...state };
    }
    case types.GET_SERVICE_PRICE_TYPE_PENDING: {
      return { ...state, loading: true };
    }
    case types.GET_SERVICE_PRICE_TYPE_FULFILLED: {
      return {
        ...state,
        loading: false,
        servicePriceType: action.payload.data,
      };
    }
    case types.GET_SERVICE_PRICE_TYPE_REJECTED: {
      return { ...state, loading: false };
    }
    case types.GET_SERVICE_PRICE: {
      return { ...state };
    }
    case types.GET_SERVICE_PRICE_FULFILLED: {
      return {
        ...state,
        loading: false,
        supplierPriceList: action.payload.data,
        getSupplierPriceStatus: 'success',
      };
    }
    case types.GET_SERVICE_PRICE_PENDING: {
      return { ...state, loading: true };
    }
    case types.GET_SERVICE_PRICE_REJECTED: {
      return {
        ...state,
        loading: false,
        getSupplierPriceStatus: 'failed',
      };
    }
    case types.POST_SERVICE_PRICE: {
      return { ...state };
    }
    case types.POST_SERVICE_PRICE_PENDING: {
      return { ...state, loading: true };
    }
    case types.POST_SERVICE_PRICE_FULFILLED: {
      return { ...state, loading: false, postSupplierPriceStatus: 'success' };
    }
    case types.POST_SERVICE_PRICE_REJECTED: {
      return {
        ...state,
        loading: false,
        postSupplierPriceStatus: 'failed',
        errors: { global: action.payload.response.data.Message },
      };
    }
    case types.PUT_SERVICE_PRICE: {
      return { ...state };
    }
    case types.PUT_SERVICE_PRICE_PENDING: {
      return { ...state, loading: true };
    }
    case types.PUT_SERVICE_PRICE_FULFILLED: {
      return { ...state, loading: false, putSupplierPriceStatus: 'success' };
    }
    case types.PUT_SERVICE_PRICE_REJECTED: {
      return {
        ...state,
        loading: false,
        putSupplierPriceStatus: 'failed',
        errors: {
          global: action.payload.response
            ? action.payload.response.data.Message
            : '',
        },
      };
    }
    case types.DELETE_SERVICE_PRICE: {
      return { ...state };
    }
    case types.DELETE_SERVICE_PRICE_PENDING: {
      return { ...state, loading: true };
    }
    case types.DELETE_SERVICE_PRICE_FULFILLED: {
      return { ...state, loading: false, deleteSupplierPriceStatus: 'success' };
    }
    case types.DELETE_SERVICE_PRICE_REJECTED: {
      return { ...state, loading: false, deleteSupplierPriceStatus: 'failed' };
    }
    case types.RESET_STATUS_SERVICE_PRICE: {
      return {
        ...state,
        getSupplierPriceStatus: '',
        deleteSupplierPriceStatus: '',
        postSupplierPriceStatus: '',
        putSupplierPriceStatus: '',
      };
    }
    default:
      return state;
  }
};

export default reducer;
