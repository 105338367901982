import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Segment, Header, Form } from 'semantic-ui-react';
import InputWithLabel from '../../../components/input/inputWithLabel';
import styles from '../styles';

const SegmentRoomDetail = props => {
  const { data } = props;
  return (
    data && (
      // <Segment size="large">
      <Segment>
        <Grid columns={3}>
          <Grid.Row>
            <Grid.Column width={4}>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Header as="h5" content={data.Text} />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>Service Type: {data.ServiceType}</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Header as="h5" icon="bed" content="Room Requested:" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    {data.Qty} Unit {data.AccommodationItemType}
                  </Grid.Column>
                </Grid.Row>
                {data.SharingWithParent.map((item, index) => {
                  return (
                    <Grid.Row key={index}>
                      <Grid.Column>{`${item.Value} Pax Sharing with Parent`}</Grid.Column>
                    </Grid.Row>
                  );
                })}
                {/* <Grid.Row>
                  <Grid.Column>3 Pax Sharing with Parent</Grid.Column>
                </Grid.Row> */}
              </Grid>
            </Grid.Column>
            <Grid.Column width={4}>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Header
                      as="h5"
                      icon="moon outline"
                      content={`Total Night: ${data.TotalNights} Night`}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column style={styles.colorWhite}>
                    Computer Row 4
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column style={styles.colorWhite}>
                    Computer Row 3
                  </Grid.Column>
                </Grid.Row>
                {data.ExtraBed.map((item, index) => {
                  return (
                    <Grid.Row key={index}>
                      <Grid.Column>{`${item.Value} Unit ${item.Text} ${item.AllocationText}`}</Grid.Column>
                    </Grid.Row>
                  );
                })}
                {/* <Grid.Row>
                  <Grid.Column>1 Unit Extrabed Adult</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>1 Unit Extrabed Child</Grid.Column>
                </Grid.Row> */}
              </Grid>
            </Grid.Column>
            <Grid.Column width={8}>
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Header
                      as="h5"
                      icon="calendar alternate outline"
                      content={`Check In Date : ${data.CheckInDate}`}
                    />
                    <Header
                      as="h5"
                      icon="clock outline"
                      content={`Check In Time : ${data.CheckInTime}`}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Header
                      as="h5"
                      icon="calendar alternate outline"
                      content={`Check Out Date : ${data.CheckOutDate}`}
                    />
                    <Header
                      as="h5"
                      icon="clock outline"
                      content={`Check Out Time : ${data.CheckOutTime}`}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Form>
                <InputWithLabel
                  label="Note"
                  placeholder="Note"
                  type="textArea"
                  labelStyles={styles.marginTop1em}
                  readonly=""
                  value={data.ConfirmationNote}
                />
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    )
  );
};

SegmentRoomDetail.propTypes = {
  data: PropTypes.object,
};

export default React.memo(SegmentRoomDetail);
