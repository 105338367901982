import React from 'react';
import { connect } from 'react-redux';
import SegmentCompanyProfile from './components/SegmentCompanyProfileList';
import {
  getCustomerProfileListAction,
  resetCustomerProfileAction,
} from '../../shared/actions/customerProfileAction';
import { deleteCompaniesByIdAction } from '../../../actions/companyAction';
import ModalAlert from '../../shared/components/modal/ModalAlert';
import PropTypes from 'prop-types';
import ModalConfirm from '../../../components/modal/modalConfirmationTwoButton';
class CompanyProfileList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      openModalAlert: false,
      headerAlert: '',
      contentAlert: '',
      openModalConfirm: false,
      message: '',
      activePage: 1,
    };
  }

  static propTypes = {
    statusCustomerProfile: PropTypes.string,
    listCustomerProfile: PropTypes.array,
    resetCustomerProfileAction: PropTypes.func,
    getCustomerProfileListAction: PropTypes.func,
    deleteCompaniesByIdAction: PropTypes.func,
    loadingCustomer: PropTypes.bool,
    loadingCompany: PropTypes.bool,
  };
  componentDidMount = () => {
    this.props.getCustomerProfileListAction('Supplier');
  };
  componentDidUpdate() {
    if (this.props.statusCustomerProfile === 'success') {
      this.setState({
        data: this.props.listCustomerProfile,
      });
      this.props.resetCustomerProfileAction();
    } else if (this.props.statusCustomerProfile === 'failed') {
      this.props.resetCustomerProfileAction();
    }
  }

  confirmDeleteSupplier = (name, value) => {
    this.handleConfirm(` Are sure to delete ${name} ?`);
    this.setState({
      idWantToDelete: value,
      nameWantToDelete: name,
    });
  };

  deleteSupplier = () => {
    let { idWantToDelete, nameWantToDelete } = this.state;
    this.props
      .deleteCompaniesByIdAction(idWantToDelete)
      .then(() => {
        this.handleCloseModalConfirm();
        this.handleAlert('Success', `${nameWantToDelete} successfully deleted`);
        this.setState({ activePage: 1 });
      })
      .catch(err => {
        this.handleAlert('Failed', err.response.data.Message);
      });
  };
  handleCloseAlert = () => {
    if (this.state.headerAlert === 'Success') {
      this.props.getCustomerProfileListAction('Supplier');
    }
    this.setState({ openModalAlert: false });
  };
  handleCloseModalConfirm = () => {
    this.setState({ openModalConfirm: false });
  };

  handleAlert = (headerAlert, contentAlert) => {
    this.setState({
      openModalAlert: true,
      headerAlert: headerAlert,
      contentAlert: contentAlert,
    });
  };
  handleConfirm = message => {
    this.setState({
      openModalConfirm: true,
      message: message,
    });
  };
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  handleSetPage = activePage => {
    this.setState({ activePage });
  };
  render() {
    let { loadingCustomer, loadingCompany } = this.props;
    let {
      data,
      openModalAlert,
      headerAlert,
      contentAlert,
      openModalConfirm,
      message,
      activePage,
    } = this.state;
    return (
      <React.Fragment>
        <SegmentCompanyProfile
          data={data}
          confirmDeleteSupplier={this.confirmDeleteSupplier}
          loading={loadingCustomer || loadingCompany}
          activePage={activePage}
          pageChange={this.pageChange}
          handleSetPage={this.handleSetPage}
        />
        <ModalAlert
          openModal={openModalAlert}
          handleClose={this.handleCloseAlert}
          header={headerAlert}
          content={contentAlert}
          nameButton="OK"
        />
        <ModalConfirm
          openModal={openModalConfirm}
          size="mini"
          close={this.handleCloseModalConfirm}
          confirm={this.deleteSupplier}
          message={message}
          header="Confirmation"
        />
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    listCustomerProfile: state.CustomerProfileStore.getCustomerProfileList,
    statusCustomerProfile:
      state.CustomerProfileStore.getCustomerProfileListStatus,
    loadingCustomer: state.CustomerProfileStore.loadingGetCustomerProfile,
    deleteCompany: state.CompanyStore.deleteCompany,
    errorCompany: state.CompanyStore.errors,
    loadingCompany: state.CompanyStore.loading,
  };
}
export default connect(
  mapStateToProps,
  {
    getCustomerProfileListAction,
    resetCustomerProfileAction,
    deleteCompaniesByIdAction,
  }
)(CompanyProfileList);
