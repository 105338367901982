import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Button, Form, Image, Grid, Checkbox } from 'semantic-ui-react';
import InputDate from './../input/inputDate';
import moment from 'moment';
import ButtonClose from './../button/buttonCloseModal';
import { postCommissionPayment } from './../../actions/tourTransactionAction';
import { putPayResourceBooking } from './../../pages/shared/actions/resourceBookingAction';
import UploadButton from '../common/uploadImageButton';
import InputCurrencyFormat from '../input/inputCurrencyFormat';
import ModalAlert from '../../pages/shared/components/modal/ModalAlert';
import NoImage from '../../assets/Icons/no_image.jpg';
import { copyObject } from '../../scripts/itineraryBuilder/itineraryBuilder';
import ModalConfirm from './modalConfirmationTwoButton';
import Loader from '../loader/loaderInModal';
import InputWithLabel from '../input/inputWithLabel';
// Perbedaan payment request dan selected payment request adalah
// payment request berisi data yang akan di bayarkan oleh finance
// selected payment request berisi data yang harus di bayarkan oleh finance. data ini berasal dari
// admin for operation
const DATA_ERROR_FIELD = {
  AmountPaid: {
    message: '',
    error: false,
  },
};
class modalPaymentRequest extends Component {
  state = {
    paymentRequest: {
      BalanceDue: '',
      // Currency: '',
      PaymentDate: '',
      ImageUrl: '',
      ImageName: '',
      ImageContent: '',
      ImagePreview: '',
      AmountPaid: 0,
    },
    errorField: copyObject(DATA_ERROR_FIELD),
    check: false,
    modalConfirm: {
      isOpen: false,
      header: '',
      message: '',
    },
  };
  // handleChange = (e, { name, value, objectname }) => {
  //   let commissionPayment = { ...this.state[objectname] };
  //   let errors = { ...this.state.errors };
  //   commissionPayment[name] = value;
  //   errors[name].error = value === '';
  //   this.setState({ ...this.state, commissionPayment, errors });
  // };
  componentDidUpdate(prevProps) {
    if (prevProps.openModal !== this.props.openModal) {
      if (this.props.openModal && this.props.selectedPaymentRequest !== null) {
        let {
          BalanceDue,
          Currency,
          Priority,
        } = this.props.selectedPaymentRequest;
        this.setState({
          BalanceDue,
          Currency,
          Priority,
        });
      }
    }
  }

  validationPayment = (paymentRequest, selectedPaymentRequest) => {
    let dataError = {
      AmountPaid: {
        message: '',
        error: false,
      },
    };
    let isError = false;
    if (selectedPaymentRequest.BalanceDue < paymentRequest.AmountPaid) {
      isError = true;
      dataError.AmountPaid.message = "Amount Paid can't more than balance due";
      dataError.AmountPaid.error = true;
    } else if (paymentRequest.AmountPaid === 0) {
      isError = true;
      dataError.AmountPaid.message = "Field can't be empty!";
      dataError.AmountPaid.error = true;
    }
    return { isError, dataError };
  };
  pay = () => {
    let { paymentRequest } = { ...this.state };
    let { selectedPaymentRequest } = this.props;
    let validation = this.validationPayment(
      paymentRequest,
      selectedPaymentRequest
    );
    if (validation.isError) {
      this.setState({ ...this.state, errorField: validation.dataError });
    } else {
      this.setState(
        { ...this.state, errorField: copyObject(DATA_ERROR_FIELD) },
        () => {
          this.openCloseModalConfirm(
            true,
            'Confirmation',
            'Are you sure you want to pay this payment request?'
          );
        }
      );
    }
  };

  openCloseModalConfirm = (isOpen, header, message) => {
    this.setState({
      ...this.state,
      modalConfirm: {
        ...this.state.modalConfirm,
        isOpen: isOpen === true,
        header: isOpen === true ? header : '',
        message: isOpen === true ? message : '',
      },
    });
  };
  handleConfirmModalConfirm = async () => {
    await this.openCloseModalConfirm();
    let { paymentRequest } = this.state;
    let { selectedPaymentRequest } = this.props;
    let newData = {
      // Currency: paymentRequest.Currency,
      // BalanceDue: selectedPaymentRequest.BalanceDue,
      // PaymentDate: paymentRequest.PaymentDate,
      ImageContent: paymentRequest.ImageContent,
      ImageName: paymentRequest.ImageName,
      // ImagePreview: paymentRequest.ImagePreview,
      value: paymentRequest.AmountPaid,
      ResourceBookingReservationPaymentId: selectedPaymentRequest.Id,
    };
    this.props
      .putPayResourceBooking(newData)
      // .postCommissionPayment(data)
      .then(() => {
        this.handleMessageAlert(
          'Successfully',
          'Payment Request Successfully Paid'
        );
        window.location.reload();
      })
      .catch(error =>
        this.handleMessageAlert('Failed', error.response.data.Message)
      );
  };
  handleMessageAlert = (header, message) => {
    this.setState({
      modalAlertOpen: true,
      modalAlertHeader: header,
      modalAlertMessage: message,
    });
  };

  handleChangeImage = e => {
    let paymentRequest = this.state.paymentRequest;
    let reader = new FileReader();
    let files = e.target.files[0];
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      let image = {
        ...paymentRequest,
        ImageName: files.name,
        ImageContent: reader.result.split(',')[1],
        ImagePreview: reader.result,
        //ImageUrl: reader.result.split(',')[1],
      };
      this.setState({
        paymentRequest: image,
      });
    };
  };
  handleChange = async (value, name) => {
    let { paymentRequest } = this.state;
    if (name === 'AmountPaid') {
      paymentRequest = await {
        ...paymentRequest,
        [name]: value,
      };
    } else
      paymentRequest = await {
        ...paymentRequest,
        [name]: moment(value).format('YYYY-MM-DD'),
      };
    this.setState({ paymentRequest });
  };
  handleCheckBox = (e, { value }) => {
    this.setState({ check: !value }, () =>
      this.setState({
        ...this.state,
        paymentRequest: {
          ...this.state.paymentRequest,
          AmountPaid: this.state.check
            ? this.props.selectedPaymentRequest
              ? this.props.selectedPaymentRequest.BalanceDue
              : 0
            : 0,
        },
      })
    );
  };
  handleCloseAlert = () => {
    this.setState({
      modalAlertOpen: false,
    });
    if (this.state.modalAlertHeader === 'Successfully') {
      // let { filterDateRangeFunc, filterByDate } = this.props;
      // if (filterByDate !== undefined) {
      //   filterDateRangeFunc(
      //     filterByDate.tourStartDate,
      //     filterByDate.tourEndDate,
      //     filterByDate.createStartDate,
      //     filterByDate.createEndDate
      //   );
      // }
      this.props.handleClose();
      this.resetModalPay();
    }
  };
  handleClose = () => {
    this.props.handleClose();
    this.resetModalPay();
  };

  resetModalPay = () => {
    this.setState({
      paymentRequest: {
        // Currency: '',
        BalanceDue: '',
        PaymentDate: moment(new Date()).format('YYYY-MM-DD'),
        ImageUrl: '',
        ImageName: '',
        ImageContent: '',
      },
      check: false,
    });
  };
  render() {
    const {
      openModal,
      selectedPaymentRequest,
      loadingPutPayResource,
    } = this.props;
    const {
      paymentRequest,
      modalAlertOpen,
      modalAlertHeader,
      modalAlertMessage,
      check,
      modalConfirm,
      errorField,
    } = this.state;
    return (
      <Modal
        dimmer="blurring"
        inverted
        open={openModal}
        onClose={this.handleClose}
        style={{ width: '30.5em' }}
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          Payment Request
          <ButtonClose onClose={this.handleClose} />
        </Modal.Header>
        <Modal.Content>
          <Loader loading={loadingPutPayResource} />
          <Grid columns={2} padded="vertically">
            <Grid.Column>
              <Form>
                <Form.Field>
                  <InputWithLabel
                    inline={false}
                    label="Currency"
                    type="text"
                    name="Currency"
                    value={
                      selectedPaymentRequest
                        ? selectedPaymentRequest.Currency
                        : ''
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <InputCurrencyFormat
                    value={
                      selectedPaymentRequest
                        ? selectedPaymentRequest.BalanceDue
                        : 0
                    }
                    name="BalanceDue"
                    label="Balance Due"
                    labelStyle={{ fontWeight: 'bold' }}
                    handelChangePrice={this.handleChange}
                    number
                  />
                </Form.Field>
                <Form.Field>
                  <InputCurrencyFormat
                    value={paymentRequest.AmountPaid}
                    name="AmountPaid"
                    label="Amount Paid"
                    labelStyle={{ fontWeight: 'bold' }}
                    handelChangePrice={this.handleChange}
                    number
                    disabled={check}
                    errors={errorField ? errorField.AmountPaid.message : null}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    name="isCheckBoxChecked"
                    value={check}
                    label={{
                      children: 'Full Payment',
                    }}
                    onChange={this.handleCheckBox}
                    checked={check}
                  />
                </Form.Field>
                <Form.Field>
                  <InputDate
                    label="Due Date"
                    name="PaymentDate"
                    value={
                      selectedPaymentRequest
                        ? selectedPaymentRequest.DueDate
                        : ''
                    }
                    labelStyle={{
                      display: 'block',
                      fontWeight: 'bold',
                      marginBottom: '4px',
                    }}
                    readonly={true}
                  />
                  {/* <InputDate
                    label="Date"
                    name="PaymentDate"
                    value={paymentRequest.PaymentDate}
                    inputStyles={{ width: '100%' }}
                    // setDate={this.handleChange}
                    labelStyle={{
                      display: 'block',
                      fontWeight: 'bold',
                      marginBottom: '4px',
                    }}
                    readonly={true}
                  /> */}
                </Form.Field>
                <InputWithLabel
                  inline={false}
                  label="Priority"
                  type="text"
                  name="Priority"
                  value={
                    selectedPaymentRequest &&
                    selectedPaymentRequest.Priority === 'LOW'
                      ? 'Low'
                      : selectedPaymentRequest &&
                        selectedPaymentRequest.Priority === 'MEDIUM'
                      ? 'Medium'
                      : selectedPaymentRequest &&
                        selectedPaymentRequest.Priority === 'HIGH'
                      ? 'High'
                      : null
                  }
                />
              </Form>
            </Grid.Column>
            <Grid.Column>
              <Image
                src={paymentRequest.ImagePreview || NoImage}
                size="small"
                centered
                rounded
                style={{ marginTop: '1em' }}
              />
              <UploadButton
                onImageSelect={this.handleChangeImage}
                label="Payment Proof"
                buttonStyle={{ marginTop: '1em', marginLeft: '1.5em' }}
              />
            </Grid.Column>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button positive content="Pay" onClick={this.pay} />
        </Modal.Actions>
        <ModalAlert
          openModal={modalAlertOpen}
          handleClose={this.handleCloseAlert}
          header={modalAlertHeader}
          content={modalAlertMessage}
        />
        <ModalConfirm
          openModal={modalConfirm.isOpen}
          close={this.openCloseModalConfirm}
          confirm={this.handleConfirmModalConfirm}
          message={modalConfirm.message}
          header={modalConfirm.header}
        />
      </Modal>
    );
  }
}

modalPaymentRequest.propTypes = {
  openModal: PropTypes.bool,
  handleClose: PropTypes.func,
  postCommissionPayment: PropTypes.func,
  putPayResourceBooking: PropTypes.func,
  loadingPostCommission: PropTypes.bool,
  cashBack: PropTypes.number,
  reservationPaymentId: PropTypes.number,
  filterDateRangeFunc: PropTypes.func,
  filterByDate: PropTypes.func,
  handleChange: PropTypes.func,
  selectedPaymentRequest: PropTypes.object,
  loadingPutPayResource: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    loadingPutPayResource: state.ResourceBooking.loadingPutPayResource,
  };
}
export default connect(mapStateToProps, {
  postCommissionPayment,
  putPayResourceBooking,
})(modalPaymentRequest);
