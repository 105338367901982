import React from 'react';
import {
  Table,
  Input,
  Button,
  Checkbox,
  Form,
  // Message,
  // Container,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import InputDate from '../../../../components/input/inputDate';
import { convertDateFormat } from '../../../../scripts/moment';
import moment from 'moment';
// import { StylesTextfieldWithError } from '../../common/styleCommon';
const TablePaymentPackage = props => (
  <Table size="small" striped>
    <Table.Header>
      <Table.Row>
        {props.headerTable &&
          props.headerTable.map((headTableContent, index) => {
            return (
              <Table.HeaderCell textAlign="center" key={index}>
                {headTableContent}
              </Table.HeaderCell>
            );
          })}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {props.listData &&
        props.listData.map((margin, idx) => {
          return (
            <Table.Row key={idx}>
              <Table.Cell textAlign="center">
                <Form>
                  <Form.Field
                    control={Input}
                    // error={props.errors[idx].PaymentPercentage ? true : false}
                    name={{ Name: 'PaymentPercentage', Index: idx }}
                    type="number"
                    onChange={props.handleChangePaymentTerms}
                    value={
                      margin.PaymentPercentage ? margin.PaymentPercentage : ''
                    }
                    placeholder={margin.PaymentPercentage ? '' : '0'}
                    disabled={props.isPublished || props.isOperation}
                  />
                </Form>
                {/* <Container content="" style={StylesTextfieldWithError.Red} /> */}
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Form>
                  <Form.Field
                    // error={props.errors[idx].DueDate ? true : false}
                    control={InputDate}
                    customInput={<Input />}
                    name={{ Name: 'DueDate', Index: idx }}
                    value={margin.DueDate}
                    setDate={props.handleChangePaymentTermsDate}
                    disabled={props.isPublished || props.isOperation}
                    // maxDate={subtractDate(
                    //   props.tourInformation.startDate,
                    //   -1,
                    //   'd'
                    // )}
                    maxDate={moment(props.tourInformation.startDate).subtract(
                      1,
                      'days'
                    )}
                    minDate={convertDateFormat(new Date(), 'YYYY-MM-DD')}
                  />
                  {/* <InputDate
                  label="From"
                  placeholder="From"
                  value={this.state.beginDate}
                  setDate={this.setDate}
                  labelStyle={{
                    display: 'block',
                    fontWeight: 'bold',
                    marginBottom: '4px',
                  }}
                  name={{ Name: 'DueDate', Index: idx }}
                />
                  </Form.Field> */}
                </Form>
                {/* {props.errors[idx].DueDate && (
                  <Message warning>{props.errors[idx].DueDate}</Message>
                )} */}
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Input
                  name={{ Name: 'Description', Index: idx }}
                  type="text"
                  onChange={props.handleChangePaymentTerms}
                  value={margin.Description}
                  disabled={props.isPublished || props.isOperation}
                />
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Checkbox
                  checked={margin.IsLockGuest}
                  name={{ Name: 'IsLockGuest', Index: idx }}
                  onChange={props.handleChangeIsLockGuest}
                  value={margin.Description}
                  disabled={props.isPublished || props.isOperation}
                />
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Button
                  content="Delete"
                  negative
                  name={{ Index: idx }}
                  onClick={props.handleClickDeletePayment}
                  value={margin.Description}
                  id={margin.Id}
                  disabled={props.isPublished || props.isOperation}
                />
              </Table.Cell>
            </Table.Row>
          );
        })}
    </Table.Body>
  </Table>
);

TablePaymentPackage.propTypes = {
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  excurtionTypePrice: PropTypes.array,
  currenciesList: PropTypes.array,
  handleChangePrice: PropTypes.func,
  handleChangePaymentTermsDate: PropTypes.func,
  handleChangePaymentTerms: PropTypes.func,
  handleChangeIsLockGuest: PropTypes.func,
  handleClickDeletePayment: PropTypes.func,
  errors: PropTypes.array,
  status: PropTypes.bool,
  isPublished: PropTypes.bool,
  tourInformation: PropTypes.object,
  isOperation: PropTypes.bool,
};
export default TablePaymentPackage;
