import { convertDateFormat } from '../../../../../scripts/moment';
const setNewDataPrice = () => {
  const data = {
    CutOffDay: 0,
    Hours: 0,
    Cost: 0,
    StartDate: convertDateFormat(new Date(), 'YYYY-MM-DD'),
    EndDate: convertDateFormat(new Date(), 'YYYY-MM-DD'),
    UomId: 'PAX',
    TaxTypeId: 7,
    Currency: '',
    Rank: 1,
    ServiceItemId: 0,
    ServicePriceType: 501,
    MinimumPax: 0,
    Description: '',
    TourGuideLanguage: '',
    LanguageId: '',
    postStatus: true,
    Action: 2,
  };
  return data;
};

export default setNewDataPrice;
