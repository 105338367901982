import React from 'react';
import { Table, Loader } from 'semantic-ui-react';
import CurrencyFormat from 'react-currency-format';
import PropTypes from 'prop-types';
import styles from '../style';
const TotalBalanceByCurrencyTable = props => {
  const { listData, loading } = props;
  return (
    <Table basic="very" selectable fixed>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Currency</Table.HeaderCell>
          <Table.HeaderCell>Balance</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {listData && listData.length > 0 ? (
          listData.map((data, idx) => {
            return (
              <Table.Row key={idx}>
                <Table.Cell component="th" scope="row" width={1}>
                  {data.Currency}
                </Table.Cell>
                <Table.Cell style={styles.textRight}>
                  <CurrencyFormat
                    thousandSeparator={true}
                    decimalScale={2}
                    displayType="text"
                    value={data.Balance}
                    allowNegative={true}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })
        ) : (
          <Table.Row>
            <Table.Cell colSpan="2" textAlign="center">
              {loading ? (
                <Loader style={styles.marginTop20} active={loading}></Loader>
              ) : (
                'No Data'
              )}
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};
TotalBalanceByCurrencyTable.propTypes = {
  listData: PropTypes.array,
  handleAction: PropTypes.func,
  loading: PropTypes.bool,
};
export default TotalBalanceByCurrencyTable;
