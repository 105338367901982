import React from 'react';
import {
  Input,
  Container,
  Form,
  Checkbox,
  Select,
  TextArea,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { StylesTextfieldWithError } from './styleCommon';
import moment from 'moment';
import InputTime from '../input/inputTime';
import InputDate from '../input/inputDate';
import CurrencyFormat from 'react-currency-format';

const TextFieldWithDropdown = props => {
  return (
    <div style={StylesTextfieldWithError.margin}>
      <label style={StylesTextfieldWithError.labelTop}>{props.title1}</label>
      <Input
        placeholder={props.placeholder1}
        style={StylesTextfieldWithError.doubleTextField}
        name={props.name1}
        onChange={props.handleChange}
        type="text"
        value={props.data}
        disabled={props.disable}
      />
      <label style={StylesTextfieldWithError.labelError}>{props.error1}</label>
      <label style={StylesTextfieldWithError.labelTopRight}>
        {props.title2}
      </label>
      <Select
        placeholder={props.placeholder2}
        selection
        search
        style={StylesTextfieldWithError.doubleTextFieldRight}
        name={props.name2}
        options={props.option}
        onChange={props.handleChangeDropdown}
        value={props.valueDropDown}
        disabled={props.disable}
        loading={props.loadingDropdown}
        clearable
      />
      <label style={StylesTextfieldWithError.labelError}>{props.error2}</label>
    </div>
  );
};
TextFieldWithDropdown.propTypes = {
  title1: PropTypes.string,
  title2: PropTypes.string,
  name1: PropTypes.string,
  name2: PropTypes.string,
  handleChange: PropTypes.func,
  handleChangeDropdown: PropTypes.func,
  placeholder1: PropTypes.string,
  placeholder2: PropTypes.string,
  data: PropTypes.string,
  option: PropTypes.array,
  valueDropDown: PropTypes.string,
  error1: PropTypes.string,
  error2: PropTypes.string,
  disable: PropTypes.bool,
  loadingDropdown: PropTypes.bool,
};

const TextFieldWithError = props => {
  return (
    <div style={StylesTextfieldWithError.newMargin}>
      <Form>
        <Form.Field
          required={props.statusRequired}
          label={props.title}
          control={Input}
          placeholder={props.placeholder}
          name={props.name}
          error={props.errors ? true : false}
          onChange={props.handleChange}
          type="text"
          value={props.data}
          disabled={props.disable}
        />
      </Form>
      {/* <Input
        placeholder={props.placeholder}
        style={StylesTextfieldWithError.minHeight}
        name={props.name}
        onChange={props.handleChange}
        type="text"
        value={props.data}
        disabled={props.disable}
      /> */}
      <Container content={props.errors} style={StylesTextfieldWithError.Red} />
    </div>
  );
};
TextFieldWithError.propTypes = {
  title: PropTypes.string,
  statusRequired: PropTypes.bool,
  name: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  errors: PropTypes.string,
  data: PropTypes.string,
  disable: PropTypes.bool,
};
const TextSizeDoubleWithError = props => {
  return (
    <div style={StylesTextfieldWithError.margin}>
      <Container content={props.title} style={StylesTextfieldWithError.bold} />
      <Input
        placeholder={props.placeholder}
        style={StylesTextfieldWithError.sizeDoubleComponent}
        name={props.name}
        onChange={props.handleChange}
        type="text"
        value={props.data}
      />
      <Container content={props.errors} style={StylesTextfieldWithError.Red} />
    </div>
  );
};
TextSizeDoubleWithError.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  errors: PropTypes.string,
  data: PropTypes.string,
};
const TextareaWithError = props => {
  return (
    <div style={StylesTextfieldWithError.margin}>
      <Form>
        <Container content={props.title} style={StylesTextfieldWithError.bold}>
          {props.title}{' '}
          <label style={StylesTextfieldWithError.labelRequired}>
            {props.required === true ? '*' : ''}
          </label>
        </Container>
        <Input
          placeholder={props.placeholder}
          rows={1}
          style={StylesTextfieldWithError.minHeight}
          value={props.data}
          onChange={props.handleChange}
          name={props.name}
          disabled={props.disable}
        />
        <Container
          content={props.errors}
          style={StylesTextfieldWithError.Red}
        />
      </Form>
    </div>
  );
};
TextareaWithError.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  errors: PropTypes.string,
  data: PropTypes.string,
  disable: PropTypes.bool,
  required: PropTypes.bool,
};

const DropdownWithError = props => {
  return (
    <div style={StylesTextfieldWithError.newMargin}>
      <Form>
        <Form.Group>
          <Form.Field
            label={props.title}
            required={props.statusRequired}
            control={Select}
            placeholder={props.placeholder}
            search
            // style={StylesTextfieldWithError.sizeDoubleComponent}
            name={props.name}
            options={props.option}
            onChange={props.handleChange}
            value={props.valueDropDown}
            disabled={props.disable}
            multiple={props.multiple}
            clearable
            validate={props.validate}
          />
          <Form.Field />
        </Form.Group>
      </Form>
      {/* <Container content={props.title} style={StylesTextfieldWithError.bold} />
      <Select
        selection
        placeholder={props.placeholder}
        search
        style={StylesTextfieldWithError.sizeDoubleComponent}
        name={props.name}
        options={props.option}
        onChange={props.handleChange}
        value={props.valueDropDown}
        disabled={props.disable}
        multiple={props.multiple}
        loading={props.loadingDropdown}
      />
      /> */}
      <Container content={props.errors} style={StylesTextfieldWithError.Red} />
    </div>
  );
};
DropdownWithError.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  errors: PropTypes.string,
  option: PropTypes.array,
  valueDropDown: PropTypes.string,
  disable: PropTypes.bool,
  multiple: PropTypes.bool,
  loadingDropdown: PropTypes.bool,
  statusRequired: PropTypes.bool,
  validate: PropTypes.object,
};

const DoubleDropdownWithError = props => {
  return (
    //<div style={StylesTextfieldWithError.margin}>
    <Form>
      <Form.Group>
        <Form.Field required={props.required1} width={8}>
          <label>{props.title1}</label>
          <Select
            selection
            placeholder={props.placeholder1}
            search
            //style={StylesTextfieldWithError.sizeDoubleComponent}
            name={props.name1}
            options={props.option1}
            onChange={props.handleChange}
            value={props.valueDropDown1}
            disabled={props.disable}
            multiple={props.multiple1}
            loading={props.loadingDropdown1}
            clearable
          />
          <span style={StylesTextfieldWithError.spanError}>{props.error1}</span>
        </Form.Field>
        <Form.Field required={props.required2} width={8}>
          <label>{props.title2}</label>
          <Select
            selection
            placeholder={props.placeholder2}
            search
            //style={StylesTextfieldWithError.doubleDropdown}
            name={props.name2}
            options={props.option2}
            onChange={props.handleChange}
            value={props.valueDropDown2}
            disabled={props.disable}
            multiple={props.multiple2}
            loading={props.loadingDropdown2}
            clearable
          />
          <span style={StylesTextfieldWithError.spanError}>{props.error2}</span>
        </Form.Field>
      </Form.Group>
    </Form>
  );
};
DoubleDropdownWithError.propTypes = {
  title1: PropTypes.string,
  statusRequired1: PropTypes.bool,
  statusRequired2: PropTypes.bool,
  title2: PropTypes.string,
  name1: PropTypes.string,
  name2: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder1: PropTypes.string,
  placeholder2: PropTypes.string,
  errors: PropTypes.string,
  option1: PropTypes.array,
  option2: PropTypes.array,
  error1: PropTypes.string,
  error2: PropTypes.string,
  valueDropDown1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueDropDown2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disable: PropTypes.bool,
  multiple1: PropTypes.bool,
  multiple2: PropTypes.bool,
  loadingDropdown1: PropTypes.bool,
  loadingDropdown2: PropTypes.bool,
  statusHide: PropTypes.bool,
  required1: PropTypes.bool,
  required2: PropTypes.bool,
};

const DoubleTextareaWithError = props => {
  return (
    <div style={StylesTextfieldWithError.margin}>
      <Form>
        <label style={StylesTextfieldWithError.labelTop}>{props.title1}</label>
        <Input
          placeholder={props.placeholder1}
          rows={2}
          style={StylesTextfieldWithError.doubleTextArea}
          value={props.data1}
          onChange={props.handleChange}
          name={props.name1}
          disabled={props.disable}
        />
        <label style={StylesTextfieldWithError.labelError}>
          {props.error1}
        </label>
        <label style={StylesTextfieldWithError.labelTopRight}>
          {props.title2}
        </label>
        <Input
          placeholder={props.placeholder2}
          rows={2}
          style={StylesTextfieldWithError.doubleTextAreaRight}
          value={props.data2}
          onChange={props.handleChange}
          name={props.name2}
          disabled={props.disable}
        />
        <label style={StylesTextfieldWithError.labelError}>
          {props.error2}
        </label>
      </Form>
    </div>
  );
};
DoubleTextareaWithError.propTypes = {
  title1: PropTypes.string,
  title2: PropTypes.string,
  name1: PropTypes.string,
  name2: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder1: PropTypes.string,
  placeholder2: PropTypes.string,
  errors: PropTypes.string,
  data1: PropTypes.string,
  data2: PropTypes.string,
  error1: PropTypes.string,
  error2: PropTypes.string,
  disable: PropTypes.bool,
};

const DoubleTextFieldWithError = props => {
  return (
    //<div style={StylesTextfieldWithError.margin}>
    <Form>
      <Form.Group>
        <Form.Field width={8} required={props.required1}>
          <label>{props.title1}</label>
          <Input
            placeholder={props.placeholder1}
            //style={StylesTextfieldWithError.doubleTextField}
            name={props.name1}
            onChange={props.handleChange}
            type={props.type1 || 'text'}
            value={props.data1}
            disabled={props.disable1}
          />
          <span style={StylesTextfieldWithError.spanError}>{props.error1}</span>
        </Form.Field>
        <Form.Field required={props.required2} width={8}>
          <label>{props.title2}</label>
          <Input
            placeholder={props.placeholder2}
            //style={StylesTextfieldWithError.doubleTextFieldRight}
            name={props.name2}
            onChange={props.handleChange}
            type={props.type2 || 'text'}
            value={props.data2}
            disabled={props.disable2}
          />
          {/* <label style={StylesTextfieldWithError.labelError}>
            {props.error2}
          </label> */}
          <span style={StylesTextfieldWithError.spanError}>{props.error2}</span>
        </Form.Field>
      </Form.Group>
    </Form>
  );
};
DoubleTextFieldWithError.propTypes = {
  title1: PropTypes.string,
  title2: PropTypes.string,
  name1: PropTypes.string,
  name2: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder1: PropTypes.string,
  placeholder2: PropTypes.string,
  errors: PropTypes.string,
  data1: PropTypes.string,
  data2: PropTypes.string,
  error1: PropTypes.string,
  error2: PropTypes.string,
  disable1: PropTypes.bool,
  disable2: PropTypes.bool,
  required1: PropTypes.bool,
  required2: PropTypes.bool,
  type1: PropTypes.string,
  type2: PropTypes.string,
};

const DoubleSpecialTextFieldWithError = props => {
  return (
    <div style={StylesTextfieldWithError.newMargin}>
      <Form>
        <Form.Group widths={2}>
          <Form.Field
            label={props.title1}
            control={Input}
            required
            error={props.error1 ? true : false}
            placeholder={props.data1 ? '' : '0'}
            name={props.name1}
            onChange={props.handleChange}
            type={props.type1}
            value={props.data1 ? props.data1 : ''}
            disabled={props.disable1}
          />
          <Form.Field
            label={props.title2}
            control={Input}
            error={props.error2 ? true : false}
            required
            placeholder={props.data2 ? '' : '0'}
            name={props.name2}
            onChange={props.handleChange}
            type={props.type2}
            value={props.data2 ? props.data2 : ''}
            disabled={props.disable2}
          />
        </Form.Group>
        <Form.Group widths={2}>
          <Form.Field>
            <Container
              content={props.error1}
              style={StylesTextfieldWithError.Red}
            />
          </Form.Field>
          <Form.Field>
            <Container
              content={props.error2}
              style={StylesTextfieldWithError.Red}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    </div>
    // {/* <Input
    //   placeholder={props.placeholder1}
    //   style={StylesTextfieldWithError.doubleTextField}
    //   name={props.name1}
    //   onChange={props.handleChange}
    //   type={props.type1}
    //   value={props.data1}
    //   disabled={props.disable}
    // /> */}
    // {/* <label style={StylesTextfieldWithError.labelError}>{props.error1}</label>
    // <label style={StylesTextfieldWithError.labelTopRight}>
    //   {props.title2}
    // </label>
    // {/* <Input
    //   placeholder={props.placeholder2}
    //   style={StylesTextfieldWithError.doubleTextFieldRight}
    //   name={props.name2}
    //   onChange={props.handleChange}
    //   type={props.type2}
    //   value={props.data2}
    //   disabled={props.disable}
    // /> */}
    // <label style={StylesTextfieldWithError.labelError}>{props.error2}</label> */}
  );
};
DoubleSpecialTextFieldWithError.propTypes = {
  title1: PropTypes.string,
  title2: PropTypes.string,
  name1: PropTypes.string,
  name2: PropTypes.string,
  type1: PropTypes.string,
  type2: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder1: PropTypes.string,
  placeholder2: PropTypes.string,
  errors: PropTypes.string,
  data1: PropTypes.string,
  data2: PropTypes.string,
  error1: PropTypes.string,
  error2: PropTypes.string,
  disable1: PropTypes.bool,
  disable2: PropTypes.bool,
};
const TextFieldWithCheckbox = props => {
  return (
    <div style={StylesTextfieldWithError.margin}>
      <Form>
        <label style={StylesTextfieldWithError.labelTop}>{props.title1}</label>
        <Input
          placeholder={props.placeholder1}
          style={StylesTextfieldWithError.doubleTextField}
          name={props.name1}
          disabled
          onChange={props.handleChange}
          type="text"
          value={props.data}
        />
        <Checkbox
          label={props.title2}
          style={StylesTextfieldWithError.doubleDropdown}
          checked={props.value}
          name={props.name2}
          onChange={props.handleChange}
        />
        <Container
          content={props.errors}
          style={StylesTextfieldWithError.Red}
        />
      </Form>
    </div>
  );
};
TextFieldWithCheckbox.propTypes = {
  title1: PropTypes.string,
  title2: PropTypes.string,
  name1: PropTypes.string,
  name2: PropTypes.string,
  handleChange: PropTypes.func,
  handleChangeCheckbox: PropTypes.func,
  placeholder1: PropTypes.string,
  placeholder2: PropTypes.string,
  errors: PropTypes.string,
  data: PropTypes.string,
  value: PropTypes.bool,
  option: PropTypes.array,
  valueDropDown: PropTypes.string,
};
const DoubleDatePickerWithError = props => {
  return (
    <Form>
      <Form.Group widths={2}>
        <Form.Field
          required={props.statusRequired1}
          label={props.title1}
          control={InputDate}
          value={moment(props.data1)}
          name={props.name1}
          error={props.error1 ? true : false}
          customInput={<Input style={StylesTextfieldWithError.newInputDate} />}
          setDate={props.handleChange}
        >
          {/* <InputDate
            value={moment(props.data1)}
            name={props.name1}
            setDate={props.handleChange}
            customInput={
              <Input style={StylesTextfieldWithError.doubleTextField} />
            }
          />*/}
        </Form.Field>
        <Form.Field
          label={props.title2}
          control={InputDate}
          required={props.statusRequired2}
          value={moment(props.data2)}
          name={props.name2}
          error={props.error2 ? true : false}
          setDate={props.handleChange}
          customInput={<Input style={StylesTextfieldWithError.newInputDate} />}
        />
      </Form.Group>
      <Form.Group widths={2}>
        <Form.Field>
          <Container
            content={props.error1}
            style={StylesTextfieldWithError.Red}
          />
        </Form.Field>
        <Form.Field>
          <Container
            content={props.error2}
            style={StylesTextfieldWithError.Red}
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
};
DoubleDatePickerWithError.propTypes = {
  title1: PropTypes.string,
  title2: PropTypes.string,
  name1: PropTypes.string,
  name2: PropTypes.string,
  type1: PropTypes.string,
  type2: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder1: PropTypes.string,
  placeholder2: PropTypes.string,
  errors: PropTypes.string,
  data1: PropTypes.string,
  data2: PropTypes.string,
  error1: PropTypes.string,
  error2: PropTypes.string,
  statusRequired2: PropTypes.bool,
  statusRequired1: PropTypes.bool,
};
const DatePickerAndTimePickerWithError = props => {
  return (
    <Form>
      <Form.Group widths={2}>
        <Form.Field
          label={props.title1}
          control={InputDate}
          value={moment(props.data1)}
          minDate={props.minDate}
          disabled={props.disabled ? true : false}
          maxDate={props.maxDate}
          name={props.name1}
          error={props.error1 ? true : false}
          customInput={<Input style={StylesTextfieldWithError.newInputDate} />}
          setDate={props.handleChange}
        />
        <Form.Field error={props.error2 ? true : false}>
          <InputTime
            labelText={props.title2}
            labelStyles={{
              display: 'block',
              fontWeight: 'bold',
            }}
            inputStyles={StylesTextfieldWithError.newInputDate}
            value={
              props.data1
                ? moment(props.data1).format('HH:mm')
                : moment().format('HH:mm')
            }
            setTime={props.setCheckinTime}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths={2}>
        <Form.Field>
          <Container
            content={props.error1}
            style={StylesTextfieldWithError.Red}
          />
        </Form.Field>
        <Form.Field>
          <Container
            content={props.error2}
            style={StylesTextfieldWithError.Red}
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
};
DatePickerAndTimePickerWithError.propTypes = {
  title1: PropTypes.string,
  title2: PropTypes.string,
  name1: PropTypes.string,
  name2: PropTypes.string,
  type1: PropTypes.string,
  type2: PropTypes.string,
  handleChange: PropTypes.func,
  setCheckinTime: PropTypes.func,
  placeholder1: PropTypes.string,
  placeholder2: PropTypes.string,
  errors: PropTypes.string,
  data1: PropTypes.string,
  data2: PropTypes.string,
  error1: PropTypes.string,
  error2: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  disabled: PropTypes.bool,
};
const TextFieldWithLeftDropdown = props => {
  return (
    //<div style={StylesTextfieldWithError.margin}>
    <Form>
      <Form.Group>
        <Form.Field required={props.required1} width={8}>
          <label>{props.title1}</label>
          <Select
            placeholder={props.placeholder1}
            selection
            search
            name={props.name1}
            options={props.option}
            onChange={props.handleChange}
            value={props.valueDropDown}
            disabled={props.disable}
            //style={StylesTextfieldWithError.doubleTextField}
            loading={props.loadingDropdown}
            clearable
          />
          <span style={StylesTextfieldWithError.spanError}>{props.error1}</span>
        </Form.Field>
        <Form.Field required={props.required2} width={8}>
          <label>{props.title2}</label>
          <Input
            placeholder={props.placeholder2}
            name={props.name2}
            onChange={props.handleChange}
            //style={StylesTextfieldWithError.doubleTextFieldRight}
            type={props.type2 || 'text'}
            value={props.data}
            disabled={props.disable}
          />
          <span style={StylesTextfieldWithError.spanError}>{props.error2}</span>
        </Form.Field>
      </Form.Group>
    </Form>
  );
};
TextFieldWithLeftDropdown.propTypes = {
  title1: PropTypes.string,
  title2: PropTypes.string,
  name1: PropTypes.string,
  name2: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder1: PropTypes.string,
  placeholder2: PropTypes.string,
  data: PropTypes.string,
  option: PropTypes.array,
  valueDropDown: PropTypes.string,
  error1: PropTypes.string,
  error2: PropTypes.string,
  disable: PropTypes.bool,
  loadingDropdown: PropTypes.bool,
  required1: PropTypes.bool,
  required2: PropTypes.bool,
  type2: PropTypes.string,
};
const DoubleFieldWithError = props => {
  return (
    <div style={StylesTextfieldWithError.margin}>
      <label style={StylesTextfieldWithError.labelTop}>{props.title1}</label>
      <Input
        placeholder={props.placeholder1}
        style={StylesTextfieldWithError.doubleTextField}
        name={props.name1}
        onChange={props.handleChange}
        type={props.type1}
        value={props.data1}
        disabled={props.disable1}
      />
      <label style={StylesTextfieldWithError.labelError}>{props.error1}</label>
      <label style={StylesTextfieldWithError.labelTopRight}>
        {props.title2}
      </label>
      <Input
        placeholder={props.placeholder2}
        style={StylesTextfieldWithError.doubleTextFieldRight}
        name={props.name2}
        onChange={props.handleChange}
        type={props.type2}
        value={props.data2}
        disabled={props.disable2}
      />
      <label style={StylesTextfieldWithError.labelError}>{props.error2}</label>
    </div>
  );
};
DoubleFieldWithError.propTypes = {
  title1: PropTypes.string,
  title2: PropTypes.string,
  name1: PropTypes.string,
  name2: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder1: PropTypes.string,
  placeholder2: PropTypes.string,
  errors: PropTypes.string,
  data1: PropTypes.string,
  data2: PropTypes.string,
  error1: PropTypes.string,
  error2: PropTypes.string,
  disable1: PropTypes.bool,
  disable2: PropTypes.bool,
  type1: PropTypes.string,
  type2: PropTypes.string,
};
const DoubleInputTimeWithError = props => {
  return (
    <div style={StylesTextfieldWithError.margin}>
      <label style={StylesTextfieldWithError.labelTop}>{props.title1}</label>
      <InputTime
        labelText="Checkin Time"
        labelStyles={{
          display: 'block',
          fontWeight: 'bold',
          marginBottom: '4px',
        }}
        context={this}
        value={props.checkoutTime}
        setTime={props.setCheckinTime}
      />
      <label style={StylesTextfieldWithError.labelError}>{props.error1}</label>
      <label style={StylesTextfieldWithError.labelTopRight}>
        {props.title2}
      </label>
      <InputTime
        labelText="Checkin Time"
        labelStyles={{
          display: 'block',
          fontWeight: 'bold',
          marginBottom: '4px',
        }}
        context={this}
        value={props.checkoutTime}
        inputStyles={StylesTextfieldWithError.doubleTextField}
        setTime={props.setCheckinTime}
      />
      <label style={StylesTextfieldWithError.labelError}>{props.error2}</label>
    </div>
  );
};
DoubleInputTimeWithError.propTypes = {
  title1: PropTypes.string,
  title2: PropTypes.string,
  name1: PropTypes.string,
  name2: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder1: PropTypes.string,
  placeholder2: PropTypes.string,
  errors: PropTypes.string,
  data1: PropTypes.string,
  data2: PropTypes.string,
  error1: PropTypes.string,
  error2: PropTypes.string,
  disable1: PropTypes.bool,
  disable2: PropTypes.bool,
  type1: PropTypes.string,
  type2: PropTypes.string,
  checkoutTime: PropTypes.func,
  setCheckinTime: PropTypes.func,
};

const TextFieldFluidOnPage = props => {
  return (
    <div style={StylesTextfieldWithError.margin}>
      <Container content={props.title} style={StylesTextfieldWithError.bold}>
        {props.title}{' '}
        <label style={StylesTextfieldWithError.labelRequired}>
          {props.required === true ? '*' : ''}
        </label>
      </Container>
      <Input
        placeholder={props.placeholder}
        //style={StylesTextfieldWithError.minHeight}
        name={props.name}
        onChange={props.handleChange}
        type={props.type}
        value={props.data}
        disabled={props.disable}
        fluid={props.fluid}
        style={props.style}
      />
      <Container content={props.errors} style={StylesTextfieldWithError.Red} />
    </div>
  );
};
TextFieldFluidOnPage.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  errors: PropTypes.string,
  data: PropTypes.string,
  disable: PropTypes.bool,
  fluid: PropTypes.bool,
  type: PropTypes.string,
  required: PropTypes.bool,
  style: PropTypes.string,
};

const TextAreaFluidOnPage = props => {
  return (
    <div style={StylesTextfieldWithError.margin}>
      <Form>
        <Container content={props.title} style={StylesTextfieldWithError.bold}>
          {props.title}{' '}
          <label style={StylesTextfieldWithError.labelRequired}>
            {props.required === true ? '*' : ''}
          </label>
        </Container>
        <TextArea
          placeholder={props.placeholder}
          //style={StylesTextfieldWithError.minHeight}
          name={props.name}
          onChange={props.handleChange}
          type={props.type}
          value={props.data}
          disabled={props.disable}
          fluid={props.fluid}
          rows={props.rows}
          maxLength="255"
          style={props.style}
        />
        <Container
          content={props.errors}
          style={StylesTextfieldWithError.Red}
        />
      </Form>
    </div>
  );
};
TextAreaFluidOnPage.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  errors: PropTypes.string,
  data: PropTypes.string,
  disable: PropTypes.bool,
  fluid: PropTypes.bool,
  type: PropTypes.string,
  rows: PropTypes.number,
  required: PropTypes.bool,
  // style: PropTypes.string,
  style: PropTypes.object,
};

const DropdownFluidOnPage = props => {
  return (
    <div style={StylesTextfieldWithError.margin}>
      <Container content={props.title} style={StylesTextfieldWithError.bold}>
        {props.title}{' '}
        <label style={StylesTextfieldWithError.labelRequired}>
          {props.required === true ? '*' : ''}
        </label>
      </Container>
      <Select
        selection
        placeholder={props.placeholder}
        search
        //style={StylesTextfieldWithError.sizeDoubleComponent}
        name={props.name}
        options={props.option}
        onChange={props.handleChange}
        value={props.valueDropDown}
        disabled={props.disable}
        multiple={props.multiple}
        fluid={props.fluid}
        loading={props.loadingDropdown}
        style={props.style}
        clearable
      />
      <Container content={props.errors} style={StylesTextfieldWithError.Red} />
    </div>
  );
};
DropdownFluidOnPage.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  errors: PropTypes.string,
  option: PropTypes.array,
  valueDropDown: PropTypes.string,
  disable: PropTypes.bool,
  multiple: PropTypes.bool,
  fluid: PropTypes.bool,
  loadingDropdown: PropTypes.bool,
  required: PropTypes.bool,
  style: PropTypes.string,
};

const TexfieldWithDropdownFluidOnPage = props => {
  return (
    <div style={StylesTextfieldWithError.margin}>
      <label style={StylesTextfieldWithError.labelTop}>
        {props.title1}{' '}
        <label style={StylesTextfieldWithError.labelRequired}>
          {props.requiredDropdown === true ? '*' : ''}
        </label>
      </label>
      <Input
        placeholder={props.placeholder1}
        style={props.styleInput}
        name={props.name1}
        onChange={props.handleChange}
        type={props.type}
        value={props.data}
        disabled={props.disable}
      />
      <label style={StylesTextfieldWithError.labelError}>{props.error2}</label>
      <label style={StylesTextfieldWithError.labelTopRight}>
        {props.title2}{' '}
        <label style={StylesTextfieldWithError.labelRequired}>
          {props.requiredInput === true ? '*' : ''}
        </label>
      </label>
      <Select
        placeholder={props.placeholder2}
        selection
        search
        style={props.styleDropdown}
        name={props.name2}
        options={props.option}
        onChange={props.handleChangeDropdown}
        value={props.valueDropDown}
        disabled={props.disable}
        loading={props.loadingDropdown}
        fluid={props.fluidDropdown}
        clearable
      />
      <label style={StylesTextfieldWithError.labelError}>{props.error1}</label>
    </div>
  );
};
TexfieldWithDropdownFluidOnPage.propTypes = {
  title1: PropTypes.string,
  title2: PropTypes.string,
  name1: PropTypes.string,
  name2: PropTypes.string,
  handleChange: PropTypes.func,
  handleChangeDropdown: PropTypes.func,
  placeholder1: PropTypes.string,
  placeholder2: PropTypes.string,
  data: PropTypes.string,
  option: PropTypes.array,
  valueDropDown: PropTypes.string,
  error1: PropTypes.string,
  error2: PropTypes.string,
  disable: PropTypes.bool,
  loadingDropdown: PropTypes.bool,
  required: PropTypes.bool,
  requiredDropdown: PropTypes.bool,
  styleDropdown: PropTypes.string,
  styleInput: PropTypes.string,
  fluidDropdown: PropTypes.bool,
  requiredInput: PropTypes.bool,
  type: PropTypes.string,
};
const DropdownWithTextFieldFluidOnPage = props => {
  return (
    <div style={StylesTextfieldWithError.margin}>
      <label style={StylesTextfieldWithError.labelTop}>
        {props.title1}{' '}
        <label style={StylesTextfieldWithError.labelRequired}>
          {props.requiredDropdown === true ? '*' : ''}
        </label>
      </label>
      <Select
        placeholder={props.placeholder1}
        selection
        search
        style={StylesTextfieldWithError.sizeDoubleComponent}
        name={props.name1}
        options={props.option}
        onChange={props.handleChangeDropdown}
        value={props.valueDropDown}
        disabled={props.disable}
        loading={props.loadingDropdown}
        fluid={props.fluidDropdown}
        clearable
      />
      <label style={StylesTextfieldWithError.labelError}>{props.error1}</label>
      <label style={StylesTextfieldWithError.labelTopRight}>
        {props.title2}{' '}
        <label style={StylesTextfieldWithError.labelRequired}>
          {props.requiredInput === true ? '*' : ''}
        </label>
      </label>
      <Input
        placeholder={props.placeholder2}
        style={props.styleInput}
        name={props.name2}
        onChange={props.handleChange}
        type={props.type}
        value={props.data}
        disabled={props.disable}
      />
      <label style={StylesTextfieldWithError.labelError}>{props.error2}</label>
    </div>
  );
};
DropdownWithTextFieldFluidOnPage.propTypes = {
  title1: PropTypes.string,
  title2: PropTypes.string,
  name1: PropTypes.string,
  name2: PropTypes.string,
  handleChange: PropTypes.func,
  handleChangeDropdown: PropTypes.func,
  placeholder1: PropTypes.string,
  placeholder2: PropTypes.string,
  data: PropTypes.string,
  option: PropTypes.array,
  valueDropDown: PropTypes.string,
  error1: PropTypes.string,
  error2: PropTypes.string,
  disable: PropTypes.bool,
  loadingDropdown: PropTypes.bool,
  required: PropTypes.bool,
  requiredDropdown: PropTypes.bool,
  styleDropdownLeft: PropTypes.string,
  styleInput: PropTypes.string,
  fluidDropdown: PropTypes.bool,
  requiredInput: PropTypes.bool,
  type: PropTypes.string,
};
// const DropdownWithTextFieldFluidOnPage = props => {
//   return (
//     <div style={StylesTextfieldWithError.margin}>
//       <label style={StylesTextfieldWithError.labelTopRight}>
//         {props.title1}{' '}
//         <label style={StylesTextfieldWithError.labelRequired}>
//           {props.requiredDropdown === true ? '*' : ''}
//         </label>
//       </label>
//       <Select
//         placeholder={props.placeholder1}
//         selection
//         search
//         style={props.styleDropdown}
//         name={props.name1}
//         options={props.option}
//         onChange={props.handleChangeDropdown}
//         value={props.valueDropDown}
//         disabled={props.disable}
//         loading={props.loadingDropdown}
//         fluid={props.fluidDropdown}
//         clearable
//       />
//       <label style={StylesTextfieldWithError.labelError}>{props.error1}</label>
//       <label style={StylesTextfieldWithError.labelTop}>
//         {props.title2}{' '}
//         <label style={StylesTextfieldWithError.labelRequired}>
//           {props.requiredInput === true ? '*' : ''}
//         </label>
//       </label>
//       <Input
//         placeholder={props.placeholder2}
//         style={props.styleInput}
//         name={props.name2}
//         onChange={props.handleChange}
//         type={props.type}
//         value={props.data}
//         disabled={props.disable}
//       />
//       <label style={StylesTextfieldWithError.labelError}>{props.error2}</label>
//     </div>
//   );
// };
DropdownWithTextFieldFluidOnPage.propTypes = {
  title1: PropTypes.string,
  title2: PropTypes.string,
  name1: PropTypes.string,
  name2: PropTypes.string,
  handleChange: PropTypes.func,
  handleChangeDropdown: PropTypes.func,
  placeholder1: PropTypes.string,
  placeholder2: PropTypes.string,
  data: PropTypes.string,
  option: PropTypes.array,
  valueDropDown: PropTypes.string,
  error1: PropTypes.string,
  error2: PropTypes.string,
  disable: PropTypes.bool,
  loadingDropdown: PropTypes.bool,
  required: PropTypes.bool,
  requiredDropdown: PropTypes.bool,
  styleDropdown: PropTypes.string,
  styleInput: PropTypes.string,
  fluidDropdown: PropTypes.bool,
  requiredInput: PropTypes.bool,
  type: PropTypes.string,
};
const DoubleDropdownWithErrorOnFluid = props => {
  return (
    <div style={StylesTextfieldWithError.margin}>
      <label style={StylesTextfieldWithError.labelTop}>
        {props.title1}{' '}
        <label style={StylesTextfieldWithError.labelRequired}>
          {props.requiredDropdownLeft === true ? '*' : ''}
        </label>
      </label>
      <Select
        selection
        placeholder={props.placeholder1}
        search
        style={props.styleDropdownLeft}
        name={props.name1}
        options={props.option1}
        onChange={props.handleChange}
        value={props.valueDropDown1}
        disabled={props.disable}
        multiple={props.multiple1}
        loading={props.loadingDropdown1}
        clearable
      />
      <label style={StylesTextfieldWithError.labelError}>{props.error1}</label>
      <label style={StylesTextfieldWithError.labelTopRight}>
        {props.title2}
        <label style={StylesTextfieldWithError.labelRequired}>
          {props.requiredDropdownRight === true ? '*' : ''}
        </label>
      </label>
      <Select
        selection
        placeholder={props.placeholder2}
        search
        style={props.styleDropdownRight}
        name={props.name2}
        options={props.option2}
        onChange={props.handleChange}
        value={props.valueDropDown2}
        disabled={props.disable}
        multiple={props.multiple2}
        loading={props.loadingDropdown2}
        clearable
      />
      <label style={StylesTextfieldWithError.labelError}>{props.error2}</label>
    </div>
  );
};

DoubleDropdownWithErrorOnFluid.propTypes = {
  title1: PropTypes.string,
  title2: PropTypes.string,
  name1: PropTypes.string,
  name2: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder1: PropTypes.string,
  placeholder2: PropTypes.string,
  errors: PropTypes.string,
  option1: PropTypes.array,
  option2: PropTypes.array,
  error1: PropTypes.string,
  error2: PropTypes.string,
  valueDropDown1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueDropDown2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disable: PropTypes.bool,
  multiple1: PropTypes.bool,
  multiple2: PropTypes.bool,
  loadingDropdown1: PropTypes.bool,
  loadingDropdown2: PropTypes.bool,
  requiredDropdownLeft: PropTypes.bool,
  // styleDropdownLeft: PropTypes.bool,
  styleDropdownLeft: PropTypes.object,
  requiredDropdownRight: PropTypes.bool,
  // styleDropdownRight: PropTypes.bool,
  styleDropdownRight: PropTypes.object,
};

const DoubleTextFieldWithErrorOnFluid = props => {
  return (
    <div style={StylesTextfieldWithError.margin}>
      <label style={StylesTextfieldWithError.labelTop}>
        {props.title1}{' '}
        <label style={StylesTextfieldWithError.labelRequired}>
          {props.requiredFieldLeft === true ? '*' : ''}
        </label>
      </label>
      <Input
        placeholder={props.placeholder1}
        //style={StylesTextfieldWithError.doubleTextField}
        style={props.styleFieldLeft}
        name={props.name1}
        onChange={props.handleChange}
        type={props.type1}
        value={props.data1}
        disabled={props.disable1}
      />
      <label style={StylesTextfieldWithError.labelError}>{props.error1}</label>
      <label style={StylesTextfieldWithError.labelTopRight}>
        {props.title2}{' '}
        <label style={StylesTextfieldWithError.labelRequired}>
          {props.requiredFieldRight === true ? '*' : ''}
        </label>
      </label>
      <Input
        placeholder={props.placeholder2}
        //style={StylesTextfieldWithError.doubleTextFieldRight}
        style={props.styleFieldRight}
        name={props.name2}
        onChange={props.handleChange}
        type={props.type2}
        value={props.data2}
        disabled={props.disable2}
      />
      <label style={StylesTextfieldWithError.labelError}>{props.error2}</label>
    </div>
  );
};
DoubleTextFieldWithErrorOnFluid.propTypes = {
  title1: PropTypes.string,
  title2: PropTypes.string,
  name1: PropTypes.string,
  name2: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder1: PropTypes.string,
  placeholder2: PropTypes.string,
  errors: PropTypes.string,
  data1: PropTypes.string,
  data2: PropTypes.string,
  error1: PropTypes.string,
  error2: PropTypes.string,
  disable1: PropTypes.bool,
  disable2: PropTypes.bool,
  requiredFieldLeft: PropTypes.bool,
  requiredFieldRight: PropTypes.bool,
  // styleFieldRight: PropTypes.string,
  // styleFieldLeft: PropTypes.string,
  styleFieldRight: PropTypes.object,
  styleFieldLeft: PropTypes.object,
  type1: PropTypes.string,
  type2: PropTypes.string,
};

const TextFieldWithCheckboxFluidOnPage = props => {
  return (
    <div style={StylesTextfieldWithError.margin}>
      <Form>
        <label style={StylesTextfieldWithError.labelTop}>
          {props.title1}{' '}
          <label style={StylesTextfieldWithError.labelRequired}>
            {props.requiredFieldLeft === true ? '*' : ''}
          </label>
        </label>
        <Input
          placeholder={props.placeholder1}
          style={StylesTextfieldWithError.doubleTextField}
          name={props.name1}
          disabled
          onChange={props.handleChange}
          type={props.type}
          value={props.data}
        />
        <Checkbox
          label={props.title2}
          style={StylesTextfieldWithError.doubleDropdown}
          checked={props.value}
          name={props.name2}
          onChange={props.handleChange}
        />
        <Container
          content={props.errors}
          style={StylesTextfieldWithError.Red}
        />
      </Form>
    </div>
  );
};
TextFieldWithCheckboxFluidOnPage.propTypes = {
  title1: PropTypes.string,
  title2: PropTypes.string,
  name1: PropTypes.string,
  name2: PropTypes.string,
  handleChange: PropTypes.func,
  handleChangeCheckbox: PropTypes.func,
  placeholder1: PropTypes.string,
  placeholder2: PropTypes.string,
  errors: PropTypes.string,
  data: PropTypes.string,
  value: PropTypes.bool,
  option: PropTypes.array,
  valueDropDown: PropTypes.string,
  requiredFieldLeft: PropTypes.bool,
  type: PropTypes.string,
};

const DoubleTextAndNumberFieldWithError = props => {
  return (
    <div style={StylesTextfieldWithError.margin}>
      <label style={StylesTextfieldWithError.labelTop}>{props.title1}</label>
      <Input
        placeholder={props.placeholder1}
        style={StylesTextfieldWithError.doubleTextField}
        name={props.name1}
        onChange={props.handleChange}
        type={props.types1}
        value={props.data1}
        disabled={props.disable1}
      />
      <label style={StylesTextfieldWithError.labelError}>{props.error1}</label>
      <label style={StylesTextfieldWithError.labelTopRight}>
        {props.title2}
      </label>
      <Input
        placeholder={props.placeholder2}
        style={StylesTextfieldWithError.doubleTextFieldRight}
        name={props.name2}
        onChange={props.handleChange}
        type={props.types2}
        value={props.data2}
        disabled={props.disable2}
      />
      <label style={StylesTextfieldWithError.labelError}>{props.error2}</label>
    </div>
  );
};
DoubleTextAndNumberFieldWithError.propTypes = {
  title1: PropTypes.string,
  title2: PropTypes.string,
  name1: PropTypes.string,
  name2: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder1: PropTypes.string,
  placeholder2: PropTypes.string,
  errors: PropTypes.string,
  data1: PropTypes.string,
  data2: PropTypes.string,
  error1: PropTypes.string,
  error2: PropTypes.string,
  disable1: PropTypes.bool,
  disable2: PropTypes.bool,
  types1: PropTypes.string,
  types2: PropTypes.string,
};

const CurrencyAndTextFieldWithError = props => {
  return (
    //<div style={StylesTextfieldWithError.margin}>
    <Form>
      <Form.Group>
        <Form.Field width={8} required={props.required1}>
          <label>{props.title1}</label>
          <CurrencyFormat
            // thousandSeparator={true}
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={2}
            value={props.data1}
            textAlign="right"
            name={props.name1}
            customInput={Input}
            onValueChange={props.handleChange1}
          />
          <span style={StylesTextfieldWithError.spanError}>{props.error1}</span>
        </Form.Field>
        <Form.Field required={props.required2} width={8}>
          <label>{props.title2}</label>
          <Input
            placeholder={props.placeholder2}
            //style={StylesTextfieldWithError.doubleTextFieldRight}
            name={props.name2}
            onChange={props.handleChange2}
            type={props.type2 || 'text'}
            value={props.data2}
            disabled={props.disable2}
          />
          {/* <label style={StylesTextfieldWithError.labelError}>
            {props.error2}
          </label> */}
          <span style={StylesTextfieldWithError.spanError}>{props.error2}</span>
        </Form.Field>
      </Form.Group>
    </Form>
  );
};
CurrencyAndTextFieldWithError.propTypes = {
  title1: PropTypes.string,
  title2: PropTypes.string,
  name1: PropTypes.string,
  name2: PropTypes.string,
  handleChange1: PropTypes.func,
  handleChange2: PropTypes.func,
  placeholder1: PropTypes.string,
  placeholder2: PropTypes.string,
  errors: PropTypes.string,
  data1: PropTypes.string,
  data2: PropTypes.string,
  error1: PropTypes.string,
  error2: PropTypes.string,
  disable1: PropTypes.bool,
  disable2: PropTypes.bool,
  required1: PropTypes.bool,
  required2: PropTypes.bool,
  type1: PropTypes.string,
  type2: PropTypes.string,
};
const TexfieldFluidOnPage = props => {
  return (
    <div>
      <label style={StylesTextfieldWithError.labelTop}>
        {props.title} <label>{props.requiredInput === true ? '*' : ''}</label>
      </label>
      <Input
        placeholder={props.placeholder}
        style={props.styleInput}
        name={props.name}
        onChange={props.handleChange}
        type={props.type}
        value={props.data}
        disabled={props.disable}
      />
    </div>
  );
};
TexfieldFluidOnPage.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  data: PropTypes.string,
  error: PropTypes.string,
  disable: PropTypes.bool,
  required: PropTypes.bool,
  styleInput: PropTypes.string,
  fluidDropdown: PropTypes.bool,
  requiredInput: PropTypes.bool,
  type: PropTypes.string,
};

export {
  TextFieldWithError,
  TextareaWithError,
  DropdownWithError,
  TextFieldWithDropdown,
  DoubleDropdownWithError,
  DoubleTextareaWithError,
  DoubleTextFieldWithError,
  DoubleSpecialTextFieldWithError,
  TextSizeDoubleWithError,
  TextFieldWithCheckbox,
  DoubleDatePickerWithError,
  TextFieldWithLeftDropdown,
  DoubleFieldWithError,
  DoubleInputTimeWithError,
  TextFieldFluidOnPage,
  TextAreaFluidOnPage,
  DropdownFluidOnPage,
  TexfieldWithDropdownFluidOnPage,
  DoubleDropdownWithErrorOnFluid,
  DoubleTextFieldWithErrorOnFluid,
  DatePickerAndTimePickerWithError,
  TextFieldWithCheckboxFluidOnPage,
  DoubleTextAndNumberFieldWithError,
  CurrencyAndTextFieldWithError,
  TexfieldFluidOnPage,
  DropdownWithTextFieldFluidOnPage,
};
