import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

const SegmentDetailCustOnBehalf = props => {
  let { onBehalf } = props;
  return onBehalf.customerName ? (
    <Grid.Row>
      <Grid.Column width={4}>Customer</Grid.Column>
      <Grid.Column width={1}>:</Grid.Column>
      <Grid.Column width={11}>
        <h5>{onBehalf.customerName}</h5>
      </Grid.Column>
      <Grid.Column width={4}>Contact Name</Grid.Column>
      <Grid.Column width={1}>:</Grid.Column>
      <Grid.Column width={11}>
        <h5>
          {onBehalf.firstName} {onBehalf.lastName}
        </h5>
      </Grid.Column>
      <Grid.Column width={4}>Phone no.</Grid.Column>
      <Grid.Column width={1}>:</Grid.Column>
      <Grid.Column width={11}>
        {onBehalf.customerTelephone ? (
          <h5>{onBehalf.customerTelephone}</h5>
        ) : (
          <h5>-</h5>
        )}
        {/* <h5>{onBehalf.customerTelephone}</h5> */}
        {/* <h5>{onBehalf.employeeTelephone}</h5> */}
      </Grid.Column>
      <Grid.Column width={4}>Email</Grid.Column>
      <Grid.Column width={1}>:</Grid.Column>
      <Grid.Column width={11}>
        {onBehalf.customerEmail ? (
          <h5>{onBehalf.customerEmail}</h5>
        ) : (
          <h5>-</h5>
        )}
        {/* <h5>{onBehalf.customerEmail}</h5> */}
        {/* <h5>{onBehalf.employeeEmail}</h5> */}
      </Grid.Column>
    </Grid.Row>
  ) : null;
};

SegmentDetailCustOnBehalf.propTypes = {
  onBehalf: PropTypes.object,
};
export default SegmentDetailCustOnBehalf;
