import React, { Component } from 'react';
import { Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { headerRestaurantItemList } from '../constant/tableHeader';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../../scripts/pagination';
import FilterData from '../../../../scripts/filterDataTable';
import Pagination from '../../../shared/components/common/pagination/Pagination';
import ButtonGroupSupplier from '../../../shared/components/common/button/ButtonGroupSupplierList';
import { Style } from '../../../../assets/styles/styleAccomodation';
import TableItem from './TableItem';

class RestaurantItemTable extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
    };
  }
  static propTypes = {
    data: PropTypes.array,
  };
  handleChange = (e, { name, value }) => this.setState({ [name]: value });
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  render() {
    let filterData = FilterData(
      this.props.listData,
      'Name',
      this.state.searchInput
    );
    let pagedData = datasetPagination(filterData, 3);
    let listData = pagedData[this.state.activePage - 1];
    const props = this.props;

    let { numbersFrom, numbersTo } = getNumberFromTo(
      listData,
      this.state.activePage,
      3
    );
    return (
      <div>
        <Input
          icon="search"
          placeholder="search"
          name="searchInput"
          value={this.state.searchInput}
          onChange={this.handleChange}
        />
        <ButtonGroupSupplier
          buttonNew={props.buttonNew}
          butttonExport={props.butttonExport}
          buttonUploadData={props.buttonUploadData}
          buttonDownloadTemplate={props.buttonDownloadTemplate}
          style={Style.buttonGroupSupplierStyle}
          disablePage={props.disablePage}
        />
        <TableItem
          IdProfile={this.props.idProfile.Id}
          listData={listData}
          header={headerRestaurantItemList}
          handleClickDelete={this.props.handleClickDelete}
          handleClickEdit={this.props.handleClickEdit}
          disablePage={this.props.disablePage}
        />
        {/* {pagedData &&
          pagedData.length > 1 && (
            <Table.Footer> */}
        <Pagination
          // totalPages={pagedData.length}
          // onPageChange={this.pageChange}
          // activePage={this.state.activePage}
          pagedData={pagedData}
          numbersTo={numbersTo}
          numbersFrom={numbersFrom}
          data={props.listData}
          pageChange={this.pageChange}
          activePage={this.state.activePage}
        />
        {/* </Table.Footer>
          )} */}
      </div>
    );
  }
}

RestaurantItemTable.propTypes = {
  listData: PropTypes.array,
  handleClickEdit: PropTypes.func,
  handleClickDelete: PropTypes.func,
  idProfile: PropTypes.object,
  disablePage: PropTypes.bool,
  buttonNew: PropTypes.func,
  butttonExport: PropTypes.func,
  buttonUploadData: PropTypes.func,
  buttonDownloadTemplate: PropTypes.func,
};
export default RestaurantItemTable;
