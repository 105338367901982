import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Table } from 'semantic-ui-react';
import { converNumberToK } from '../../../../scripts/convertNumber';
// import { formatCurrencyWithDecimal } from '../../../../components/genericFunction/convertFormat';

const TableBestCustomer = props => {
  const { report } = props;
  let bestFive = report && report.BestFiveCustomer.slice(0, 5);
  return (
    <>
      <Grid.Row>
        <Grid.Column>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Best 5 Customer</Table.HeaderCell>
                <Table.HeaderCell>Total Gross</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {bestFive ? (
                bestFive.map((item, index) => {
                  let totalGross = item && converNumberToK(item.TotalGross);
                  // totalGross = totalGross.toFixed(2);
                  return (
                    <Table.Row key={index}>
                      <Table.Cell>{item.Name}</Table.Cell>
                      <Table.Cell>{`${totalGross} ${report &&
                        report.Currency}`}</Table.Cell>
                    </Table.Row>
                  );
                })
              ) : (
                <Table.Row>
                  <Table.Cell colSpan="2" textAlign="center">
                    Failed Get
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

TableBestCustomer.propTypes = {
  report: PropTypes.object,
};

export default TableBestCustomer;
