import { convertDateFormat } from '../../../components/genericFunction/moment';

const setDetailData = data => {
  //   console.log(data);
  return {
    BookingNo: data.BookingNo,
    AccommodationItemType: data.AccommodationItemType
      ? data.AccommodationItemType
      : null,
    CheckInDate: data.CheckInDate
      ? convertDateFormat(data.CheckInDate, 'DD MMM YYYY')
      : null,
    CheckInTime: data.CheckInDate
      ? convertDateFormat(data.CheckInDate, 'hh:mm A')
      : null,
    CheckOutDate: data.CheckOutDate
      ? convertDateFormat(data.CheckOutDate, 'DD MMM YYYY')
      : null,
    CheckOutTime: data.CheckOutDate
      ? convertDateFormat(data.CheckOutDate, 'hh:mm A')
      : null,
    ConfirmDate: data.ConfirmDate
      ? convertDateFormat(data.ConfirmDate, 'DD MMM YYYY hh:mm A')
      : null,
    ConfirmationNote: data.ConfirmationNote ? data.ConfirmationNote : null,
    ConfirmationNumber: data.ConfirmationNumber
      ? data.ConfirmationNumber
      : null,
    ExpiredDate: data.ExpiredDate
      ? convertDateFormat(data.ExpiredDate, 'DD MMM YYYY hh:mm A')
      : null,
    FirstName: data.FirstName ? data.FirstName : null,
    LastName: data.LastName ? data.LastName : null,
    OrderDate: data.OrderDate
      ? convertDateFormat(data.OrderDate, 'DD MMMM YYYY')
      : null,
    Qty: data.Qty ? data.Qty : null,
    RequestItemId: data.RequestItemId ? data.RequestItemId : null,
    ServiceType: data.ServiceType ? data.ServiceType : null,
    Status: data.Status ? data.Status : null,
    Text: data.Text ? data.Text : null,
    TotalNights: data.TotalNights ? data.TotalNights : null,
    Value: data.Value ? data.Value : null,
    //
    GuestAdults: data.GuestDetail.Adults ? data.GuestDetail.Adults : null,
    GuestChildrens: data.GuestDetail.Childrens
      ? data.GuestDetail.Childrens
      : null,
    GuestInfants: data.GuestDetail.Infants ? data.GuestDetail.Infants : null,
    TotalGuests:
      data.GuestDetail.Adults.length +
      data.GuestDetail.Childrens.length +
      data.GuestDetail.Infants.length,
    ExtraBed: data.NonRooms.filter(e => e.Text.includes('Extra Bed')),
    SharingWithParent: data.NonRooms.filter(e =>
      e.Text.includes('Sharing Bed')
    ),
  };
};

export default setDetailData;
