import React from 'react';
import { Table, Grid, Button, Loader } from 'semantic-ui-react';
import CurrencyFormat from 'react-currency-format';
import PropTypes from 'prop-types';
import styles from '../style';
const AccountsummaryTable = props => {
  const { listData, loading } = props;
  const printDetail = (e, { value }) => {
    props.handleAction(value.Company.Value);
  };
  return (
    <Table basic="very" selectable fixed>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Company</Table.HeaderCell>
          <Table.HeaderCell>Opening Balance</Table.HeaderCell>
          <Table.HeaderCell>Amount</Table.HeaderCell>
          <Table.HeaderCell>Payments</Table.HeaderCell>
          <Table.HeaderCell>Balance</Table.HeaderCell>
          <Table.HeaderCell>Action</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {listData && listData.length > 0 && !loading ? (
          listData.map((data, idx) => {
            return (
              <Table.Row key={idx}>
                <Table.Cell component="th" scope="row" width={1}>
                  {data.Company.Text}
                </Table.Cell>
                <Table.Cell style={styles.textRight}>
                  <CurrencyFormat
                    thousandSeparator={true}
                    decimalScale={2}
                    displayType="text"
                    value={data.OpeningBalance}
                    allowNegative={true}
                  />
                </Table.Cell>
                <Table.Cell style={styles.textRight}>
                  <CurrencyFormat
                    thousandSeparator={true}
                    decimalScale={2}
                    displayType="text"
                    value={data.Amount}
                    allowNegative={true}
                  />
                </Table.Cell>
                <Table.Cell style={styles.textRight}>
                  <CurrencyFormat
                    thousandSeparator={true}
                    decimalScale={2}
                    displayType="text"
                    value={data.Payments}
                    allowNegative={true}
                  />
                </Table.Cell>
                <Table.Cell style={styles.textRight}>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width="4">{data.Currency}</Grid.Column>
                      <Grid.Column width="12">
                        <CurrencyFormat
                          thousandSeparator={true}
                          decimalScale={2}
                          displayType="text"
                          value={data.Balance}
                          allowNegative={true}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Table.Cell>
                <Table.Cell>
                  <Button primary value={data} onClick={printDetail}>
                    Download Detail
                  </Button>
                </Table.Cell>
              </Table.Row>
            );
          })
        ) : (
          <Table.Row>
            <Table.Cell colSpan="6" textAlign="center">
              {loading ? (
                <Loader style={styles.marginTop20} active={loading}></Loader>
              ) : (
                'No Data'
              )}
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};
AccountsummaryTable.propTypes = {
  listData: PropTypes.array,
  handleAction: PropTypes.func,
  loading: PropTypes.bool,
};
export default AccountsummaryTable;
