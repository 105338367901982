const setDataUserSupplier = state => {
  let destructure = [];
  let data = {};
  state.map(object => {
    data = {
      AccountManager: object.AccountManager ? object.AccountManager : '',
      Address: object.Address ? object.Address : '',
      Email: object.Email ? object.Email : '',
      FirstName: object.FirstName ? object.FirstName : '',
      Id: object.Id ? object.Id : '',
      ImageUrl: object.ImageUrl ? object.ImageUrl : '',
      IsInactive: object.IsInactive ? object.IsInactive : '',
      IsLocked: object.IsLocked ? object.IsLocked : '',
      LastName: object.LastName ? object.LastName : '',
      Roles: object.Roles ? object.Roles : '',
      Telephone: object.Telephone ? object.Telephone : '',
      UserId: object.UserId ? object.UserId : '',
      Username: object.Username ? object.Username : '',
    };
    return destructure.push(data);
  });
  return destructure;
};

export default setDataUserSupplier;
