import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Grid,
  Header,
  // Checkbox,
  // Form,
  // Message,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import ModalAlert from '../../../../../components/modal/modalAlert';
import ModalConfirm from '../../../../../components/modal/modalConfirmationTwoButton';
import {
  postPublishPackageAction,
  copyPackageAction,
  deletePackageAction,
  postPackageAction,
  putPackageAction,
  resetPackageAction,
  uploadBrochurePackageAction,
  getFixedPackage,
  putGeneralInfoPackageAction,
  putAllowedPaxPackageAction,
  deleteBookingTemplateImagesAction,
  putAdditionalInfoOperation,
} from '../../../../../actions/packageAction';
import {
  generateSeriesPackage,
  filterDataPostSeries,
} from '../../../../../scripts/itineraryBuilder/demoPrice';
import { validateDailyProgramOverloadTime } from '../../../../../scripts/itineraryBuilder/dailyProgram';
import {
  GENERAL_INFORMATION,
  ALLOWED_PAX,
} from './../../../constant/objEditPackage';
// import {
//   validateDailyProgramExcursionTime,
//   transportValidation,
// } from '../../../../../scripts/itineraryBuilder/dailyProgram';
import ModalCopyPackage from './../../../../../components/modal/ModalCopyPackage';
import {
  inputValidation,
  priceValidation,
  checkExistingDepartureArrival,
  paymentTermValidation,
  // checkTotalDayAndAccom,
} from '../../../services/validation/validationSeriesPackage';
import { tourOperatorValidation } from '../../../services/validation/validationPrice';
import {
  sharedPaxValidation,
  checkConfirmGuestSharingPax,
} from '../../../services/validation/validationSharingPax';
import _ from 'lodash';
import {
  postAdditionalInfoAction,
  deleteAdditionalInfoAction,
  putAdditionalInfoAction,
} from '../../../../shared/actions/additionalInformationAction';
import SetDataAdditionalInformation from '../../../services/data/setDataAdditionalInformationForAction';
import setDataImage from '../../../services/data/setDataImage';
// import { Collapse } from 'react-collapse';

const styleButtonRed = {
  background: '#ff3d3d',
  color: 'white',
};
const styleButtonBlue = {
  background: '#2962ff',
  color: 'white',
};
const styleButtonYellow = {
  background: '#ff9800',
  color: 'white',
};
class AllButtonActionReadyPackage extends Component {
  state = {
    goTo: false,
    linkGoTo: '',
    openModalAlert: false,
    openModalAlertValidation: false,
    messageModalAlert: [],
    openConfirmation: false,
    messageConfirmation: '',
    isFailed: false,
    openModalPayment: false,
    modalCopyPackage: {
      openModal: false,
      selectedId: null,
      typePackage: null,
      startDate: '',
    },
  };
  handleClickAction = async () => {
    const { currentPackage, isPublished } = this.props;
    const { typeButton } = this.state;
    let validation = {
      isError: false,
      messages: [],
    };
    this.setState({ openConfirmation: false });
    switch (typeButton) {
      case 'back':
        this.setState({
          ...this.state,
          goTo: true,
          linkGoTo: '/PackageList/Fixed',
        });
        break;
      case 'save':
        // if (validateDailyProgramExcursionTime(this.props.dailyProgram)) {
        //   validation.isError = true;
        //   validation.messages.push(
        //     'Please check your Itinerary, something wrong with excursion time'
        //   );
        // }
        // if (transportValidation(this.props.dailyProgram)) {
        //   validation.isError = true;
        //   validation.messages.push(
        //     'Please check your Itinerary, something wrong with transportation'
        //   );
        // }
        if (validation.isError) {
          this.setState({
            ...this.state,
            openModalAlertValidation: validation.isError,
            messageModalAlert: validation.messages,
            openConfirmation: false,
          });
        } else {
          this.handleSaveTransaction();
        }
        break;
      case 'publish':
        if (!isPublished) {
          this.handleSaveTransaction();
          // this.props
          //   .postPublishPackageAction(
          //     'FixedPackage',
          //     'Publish',
          //     currentPackage.id
          //   )
          //   .then(() => {
          //     this.setState({
          //       openModalAlert: true,
          //       contentAlert: 'the package was successfully published',
          //       headerAlert: 'Successfully',
          //       // goTo: true,
          //       // linkGoTo: '/PackageList/Fixed',
          //       savingStatus: true,
          //       idAfterSaving: currentPackage.id,
          //     });
          //   })
          //   .catch(error => {
          //     this.setState({
          //       openModalAlert: true,
          //       contentAlert: error.response.data.Message,
          //       headerAlert: 'Failed',
          //       isFailed: true,
          //     });
          //   });
        } else {
          this.props
            .postPublishPackageAction(
              'FixedPackage',
              'Unpublish',
              currentPackage.id
            )
            .then(() => {
              this.setState({
                openModalAlert: true,
                contentAlert: 'the package was successfully Unpublished',
                headerAlert: 'Successfully',
                // goTo: true,
                // linkGoTo: '/PackageList/Fixed',
                savingStatus: true,
                idAfterSaving: currentPackage.id,
              });
            })
            .catch(error => {
              this.setState({
                openModalAlert: true,
                contentAlert: error.response.data.Message,
                headerAlert: 'Failed',
                isFailed: true,
              });
            });
        }

        break;
      case 'copy':
        this.props
          .copyPackageAction(currentPackage.type, currentPackage.id)
          .then(() =>
            this.setState({
              openModalAlert: true,
              contentAlert: 'the package was successfully copied',
              headerAlert: 'Successfully',
              // goTo: true,
              // linkGoTo: '/PackageList/Fixed',
            })
          )
          .catch(() =>
            this.setState({
              openModalAlert: true,
              contentAlert: 'package failed to copy',
              headerAlert: 'Failed',
              isFailed: true,
            })
          );
        break;
      case 'delete':
        this.props
          .deletePackageAction(currentPackage.id)
          .then(() => {
            this.setState({
              openModalAlert: true,
              contentAlert: 'the package was successfully deleted',
              headerAlert: 'Successfully',
              goTo: true,
              linkGoTo: '/PackageList/Fixed',
            });
          })
          .catch(() =>
            this.setState({
              openModalAlert: true,
              contentAlert: 'package failed to delete',
              headerAlert: 'Failed',
              isFailed: true,
            })
          );
        break;
      case 'edit':
        break;
      default:
        alert('Are you sure create package?');
    }
  };

  messageError = (validation, message) => {
    validation.isError = true;
    validation.messages.push(message);
    return validation;
  };

  validationPages = (error, check, validation, message) => {
    if (error) {
      switch (check) {
        case 'checkErrorInput':
          validation = this.messageError(validation, message);
          break;
        case 'checkPrice':
          validation = this.messageError(validation, message);
          break;
        case 'checkPaymentTerm':
          validation = this.messageError(
            validation,
            'Payment term invalid, please check the payment term'
          );
          break;
        case 'checkTourOperator':
          validation = this.messageError(
            validation,
            'Please choose tour operator'
          );
          break;
        case 'checkAccommodation':
          validation = this.messageError(
            validation,
            'Please choose Accommodation'
          );
          break;
        // case 'isValidTotalDayAndAccom':
        //   validation = this.messageError(
        //     validation,
        //     'Please check again your accommodation and itinerary'
        //   );
        //   break;
        case 'isDailyProgramOverload':
          validation = this.messageError(
            validation,
            'Please check your Itinerary, something wrong with activity time'
          );
          break;
        case 'isValidMinAndMaxGuest':
          validation = this.messageError(
            validation,
            'Please check your min guests and max guests'
          );
          break;
        case 'checkDate':
          validation = this.messageError(validation, message);
          break;
        case 'checkSharingPax':
          validation = this.messageError(validation, message);
          break;
        case 'confirmGuest':
          validation = this.messageError(validation, message);
          break;
        case 'checkDepartureArrival':
          validation = this.messageError(validation, message);
          break;
        case 'checkPublishAndSave':
          validation = this.messageError(validation, '');
          break;
        default:
      }
    }
    return validation;
  };

  handleClickConfirmation = async (e, { name }) => {
    const {
      isPublished,
      currentPackage,
      generalInformation,
      errorGeneralInformation,
      setErrorsGeneralInform,
      price,
      tourOperator,
      paymentTerms,
      headlineProgram,
      errorTourOperator,
      handleErrorTourOperator,
      SharingPax,
      dailyProgram,
    } = this.props;
    let validation = {
      isError: false,
      messages: [],
    };
    let message = '';

    let checkErrorInput = inputValidation(
      generalInformation,
      errorGeneralInformation
    );
    let checkDepartureArrival = await checkExistingDepartureArrival(
      dailyProgram
    );
    let confirmGuest =
      generalInformation.BookingTemplateSharedPaxId !== 0
        ? await checkConfirmGuestSharingPax(
            generalInformation.BookingTemplateSharedPaxId,
            this.props.getFixedPackage
          )
        : false;
    let checkPrice = priceValidation(price);
    // let checkPrice = false;
    let checkTourOperator = tourOperatorValidation(
      tourOperator,
      errorTourOperator
    );
    let checkSharingPax =
      !_.isEmpty(SharingPax) &&
      sharedPaxValidation(generalInformation, SharingPax);
    // check payment term
    let total = paymentTerms.length > 0 && paymentTermValidation(paymentTerms);
    let checkPaymentTerm =
      total < 100 || total > 100 || paymentTerms.length === 0;
    let accommodations = headlineProgram.MainPrograms.filter(
      e => e.AccommodationSummary
    );
    let checkAccommodation =
      accommodations[0].AccommodationSummary.AccommodationProfileId === null;

    let isValidMinAndMaxGuest =
      parseInt(generalInformation.maxGuest, 10) <
      parseInt(generalInformation.minGuest, 10);
    let isDailyProgramOverload = validateDailyProgramOverloadTime(dailyProgram);
    switch (name) {
      case 'back':
        message =
          'Are you sure you left this package? the filled in data will be lost';
        this.openModalConfirmation(message, name);
        break;
      case 'save':
        validation = this.validationPages(
          checkErrorInput.isError,
          'checkErrorInput',
          validation,
          checkErrorInput.message
        );
        validation = this.validationPages(
          confirmGuest,
          'confirmGuest',
          validation,
          'Please change the package that you have selected at sharing pax, because the package already have a confirm guest'
        );
        // validation = this.validationPages(
        //   checkPrice.isError,
        //   'checkPrice',
        //   validation,
        //   checkPrice.message
        // );

        validation = this.validationPages(
          checkPaymentTerm,
          'checkPaymentTerm',
          validation
        );
        validation = this.validationPages(
          checkTourOperator.isError,
          'checkTourOperator',
          validation
        );
        validation = this.validationPages(
          checkAccommodation,
          'checkAccommodation',
          validation
        );
        // validation = this.validationPages(
        //   isValidTotalDayAndAccom,
        //   'isValidTotalDayAndAccom',
        //   validation
        // );
        validation = this.validationPages(
          isValidMinAndMaxGuest,
          'isValidMinAndMaxGuest',
          validation
        );
        validation = this.validationPages(
          isDailyProgramOverload,
          'isDailyProgramOverload',
          validation
        );
        validation = this.validationPages(
          checkDepartureArrival.isError,
          'checkDepartureArrival',
          validation,
          checkDepartureArrival.message
        );
        validation = this.validationPages(
          checkSharingPax.isError,
          'checkSharingPax',
          validation,
          'Please check destination and start date because the data difference with sharing pax package'
        );

        if (validation.isError || errorTourOperator.isError) {
          this.setState({
            ...this.state,
            openModalAlertValidation: validation.isError,
            messageModalAlert: validation.messages,
            openConfirmation: false,
          });
          await setErrorsGeneralInform(checkErrorInput);
          await handleErrorTourOperator(checkTourOperator);
        } else {
          message =
            'Are you sure the data that is filled in is correct? package will be saved';
          this.openModalConfirmation(message, name);
        }

        break;
      case 'publish':
        validation = this.validationPages(
          checkPrice.isError,
          'checkPrice',
          validation,
          checkPrice.message
        );
        if (validation.isError) {
          this.setState({
            ...this.state,
            openModalAlertValidation: validation.isError,
            messageModalAlert: validation.messages,
            openConfirmation: false,
          });
          await setErrorsGeneralInform(checkErrorInput);
        } else {
          isPublished
            ? (message = 'Are you sure to unpublish this package?')
            : (message = 'Are you sure to publish this package?');
          this.openModalConfirmation(message, name);
        }
        break;
      case 'copy':
        {
          let modalCopyPackage = {
            openModal: true,
            selectedId: currentPackage.id,
            // typePackage:
            //   currentPackage.type === 'FixedDateVariable'
            //     ? 'VariableDate'
            //     : 'Fixed',
            typePackage: 'Fixed',
            startDate: generalInformation.startDate,
          };
          this.setState({ ...this.state, modalCopyPackage });
        }
        break;
      case 'delete':
        message = 'Are you sure to delete this package?';
        this.openModalConfirmation(message, name);
        break;
      case 'edit':
        message =
          'Are you sure the data that is filled in is correct? package will be saved';
        this.openModalConfirmation(message, name);
        break;
      default:
        message =
          'Are you sure the data that is filled in is correct? package will be saved';
        this.openModalConfirmation(message, name);
    }
  };

  openModalConfirmation = (message, name) => {
    this.setState({
      openConfirmation: true,
      typeButton: name === 'bookQuotation' ? 'save' : name,
      messageConfirmation: message,
      isBookingQuotation: name === 'bookQuotation',
    });
  };

  handleClose = () => {
    this.setState({
      openConfirmation: false,
      openModalAlert: false,
    });
  };
  handleCloseAlert = () => {
    const { savingStatus, idAfterSaving } = this.state;
    const { getPackage, currentPackage } = this.props;
    !this.state.isFailed && getPackage(currentPackage.type, currentPackage.id);
    if (savingStatus) {
      this.setState(
        {
          ...this.state,
          goTo: true,
          linkGoTo: `/Package-Details/Fixed/edit/${idAfterSaving}`,
          openModalAlert: false,
        },
        () => this.setReloadPage(idAfterSaving)
      );
    } else {
      this.setState({
        ...this.state,
        // goTo: currentPackage.status === 'edit' ? false : this.state.isFailed,
        // linkGoTo: this.state.isFailed ? '/PackageList/Fixed' : '',
        openModalAlert: false,
      });
    }
  };
  setReloadPage = async idAfterSaving => {
    await this.props.setPage('edit', idAfterSaving);
    this.setState({ goTo: false });
  };

  // handleGetPackage = isVariableDate => {
  //   const { getPackage, currentPackage, getPackageVariableDate } = this.props;
  //   isVariableDate
  //     ? getPackageVariableDate(
  //         currentPackage.status,
  //         currentPackage.id,
  //         currentPackage.type
  //       )
  //     : getPackage(currentPackage.type, currentPackage.id, currentPackage.type);
  // };

  handleSaveTransaction = () => {
    const {
      currentPackage,
      dailyProgram,
      generalInformation,
      headlineProgram,
      images,
      additionalInfo,
      tourOperator,
      commissions,
      paymentTerms,
      price,
      brochures,
      listAdditionalItem,
      isPublished,
      listPeriod,
      isOperation,
    } = this.props;
    let brochuresList = [];
    if (brochures.change) {
      brochuresList.push(brochures);
    }
    let daily = generateSeriesPackage(
      currentPackage.id,
      generalInformation,
      'FIXED',
      headlineProgram,
      dailyProgram,
      images,
      additionalInfo,
      tourOperator,
      commissions,
      paymentTerms,
      price,
      brochuresList,
      listAdditionalItem,
      listPeriod
    );
    if (isOperation) {
      this.updateAdditionalInfoEvoucher(currentPackage, additionalInfo);
    } else {
      currentPackage.id > 0
        ? isPublished
          ? this.putGeneralInfo(currentPackage.id, daily)
          : this.putPackage(currentPackage.id, daily)
        : this.postPackage('SaveTemplate', daily);
      if (currentPackage.id > 0) {
        if (brochures.change) {
          this.props.uploadBrochurePackageAction(currentPackage.id, brochures);
        }
      }
    }
    //
    // currentPackage.id > 0
    //   ? console.log(currentPackage.id, daily)
    //   : console.log('SaveReadyPackageTemplate', daily);
  };

  postPackage = async (id, dailyProgram) => {
    const { brochures } = this.props;
    this.props
      .postPackageAction(id, dailyProgram)
      .then(res => {
        this.setState({
          openModalAlert: true,
          contentAlert: `Series package was successfully saved with Id ${res.value.data.BookingDetailSum.Id}`,
          headerAlert: 'Successfully',
          // goTo: true,
          // linkGoTo: '/PackageList/Fixed',
          savingStatus: true,
          idAfterSaving: res.value.data.BookingDetailSum.Id,
        });
        if (brochures.change) {
          brochures.BookingTemplateId = res.value.data.BookingDetailSum.Id;
          this.props.uploadBrochurePackageAction(
            res.value.data.BookingDetailSum.Id,
            brochures
          );
        }
      })
      .catch(error =>
        this.setState({
          openModalAlert: true,
          contentAlert: error.response.data.Message,
          headerAlert: 'Failed',
          isFailed: true,
          linkGoTo: null,
        })
      );
  };

  // putPackage = (id, dailyProgram) => {
  //   const { currentPackage, typeButton } = this.state;
  //   this.props
  //     .putPackageAction(id, dailyProgram)
  //     .then(() => {
  //       this.state.typeButton === 'publish' &&
  //         this.props.postPublishPackageAction(
  //           'FixedPackage',
  //           'Publish',
  //           currentPackage.id
  //         );
  //       this.setState({
  //         openModalAlert: true,
  //         contentAlert: 'the package was successfully saved',
  //         headerAlert: 'Successfully',
  //       });
  //     })
  //     .catch(error =>
  //       this.setState({
  //         openModalAlert: true,
  //         contentAlert: error.response.data.Message,
  //         headerAlert: 'Failed',
  //         isFailed: true,
  //       })
  //     );
  // };
  putPackage = (id, dailyProgram) => {
    const { currentPackage } = this.props;
    const { typeButton } = this.state;
    this.props
      .putPackageAction(id, dailyProgram)
      .then(() => {
        typeButton === 'publish' &&
          this.props.postPublishPackageAction(
            'FixedPackage',
            'Publish',
            currentPackage.id
          );
        this.setState({
          openModalAlert: true,
          contentAlert: 'the package was successfully saved',
          headerAlert: 'Successfully',
          // savingStatus: true,
          // idAfterSaving: currentPackage.id,
        });
      })
      .catch(error =>
        this.setState({
          openModalAlert: true,
          contentAlert: error.response.data.Message,
          headerAlert: 'Failed',
          isFailed: true,
        })
      );
  };

  postPublish = () => {
    const { currentPackage } = this.state;
    this.props
      .postPublishPackageAction('FixedPackage', 'Publish', currentPackage.id)
      .then(() => {
        this.setState({
          openModalAlert: true,
          contentAlert: 'the package was successfully published',
          headerAlert: 'Successfully',
          // goTo: true,
          // linkGoTo: '/PackageList/Fixed',
          savingStatus: true,
          idAfterSaving: currentPackage.id,
        });
      })
      .catch(error => {
        this.setState({
          openModalAlert: true,
          contentAlert: error.response.data.Message,
          headerAlert: 'Failed',
          isFailed: true,
        });
      });
  };
  isPublishedPutPackage = (id, dataPost) => {
    let general = filterDataPostSeries(dataPost, GENERAL_INFORMATION);
    let allowedPax = filterDataPostSeries(dataPost, ALLOWED_PAX);
    // eslint-disable-next-line
    Promise.all([
      this.props.putGeneralInfoPackageAction(id, general),
      this.props.putAllowedPaxPackageAction(id, allowedPax),
    ])
      .then(() => {
        this.setState({
          openModalAlert: true,
          contentAlert: 'the package was successfully update',
          headerAlert: 'Successfully',
        });
      })
      .catch(error =>
        this.setState({
          openModalAlert: true,
          contentAlert: error.response.data.Message,
          headerAlert: 'Failed',
          isFailed: true,
        })
      );
  };

  handleImageAction = () => {
    const {
      brochures,
      currentPackage,
      images,
      deletedImages,
      handleDeleteImages,
    } = this.props;
    brochures.change &&
      this.props.uploadBrochurePackageAction(currentPackage.id, brochures);
    images.map(data => {
      return (
        data.Id === 0 &&
        this.props.uploadBrochurePackageAction(
          currentPackage.id,
          setDataImage(data, true, currentPackage.id)
        )
      );
    });
    deletedImages.length > 0 &&
      deletedImages.map(data =>
        this.props.deleteBookingTemplateImagesAction(data)
      );
    handleDeleteImages('clear');
  };
  updateAdditionalInfoEvoucher = (currentPackage, listAdditionalInfo) => {
    let dataPost = listAdditionalInfo
      ? listAdditionalInfo.reduce((acc, e) => {
          if (e.ForVoucher) {
            acc.push({ ...e, SubTitle: 'FOR_VOUCHER ' + e.SubTitle });
          }
          return acc;
        }, [])
      : [];
    this.props
      .putAdditionalInfoOperation({
        packageType: currentPackage.type,
        id: currentPackage.id,
        data: dataPost,
      })
      .then(() => {
        this.setState({
          openModalAlert: true,
          contentAlert: 'the package was successfully update',
          headerAlert: 'Successfully',
        });
      })
      .catch(error =>
        this.setState({
          openModalAlert: true,
          contentAlert: error.response.data.Message,
          headerAlert: 'Failed',
          isFailed: true,
        })
      );
  };
  putGeneralInfo = (id, dataPost) => {
    let general = filterDataPostSeries(dataPost, GENERAL_INFORMATION);
    let allowedPax = filterDataPostSeries(dataPost, ALLOWED_PAX);

    // eslint-disable-next-line
    Promise.all([
      this.props.putGeneralInfoPackageAction(id, general),
      this.props.putAllowedPaxPackageAction(id, allowedPax),
      this.additionalInformationAction(dataPost.BookingTemplateDescriptions),
      this.handleImageAction(),
    ])
      .then(() => {
        this.setState({
          openModalAlert: true,
          contentAlert: 'the package was successfully update',
          headerAlert: 'Successfully',
        });
      })
      .catch(error =>
        this.setState({
          openModalAlert: true,
          contentAlert: error.response.data.Message,
          headerAlert: 'Failed',
          isFailed: true,
        })
      );
  };

  additionalInformationAction = additionalInfo => {
    const {
      deletedAdditionalInformation,
      deletedAdditionalInformationFunc,
    } = this.props;
    additionalInfo.map(data =>
      data.Id === 0
        ? this.props.postAdditionalInfoAction(
            SetDataAdditionalInformation(
              data,
              true,
              this.props.currentPackage.id
            )
          )
        : this.props.putAdditionalInfoAction(
            data.Id,
            SetDataAdditionalInformation(
              data,
              false,
              this.props.currentPackage.id
            )
          )
    );
    deletedAdditionalInformation.length > 0 &&
      deletedAdditionalInformation.map(data =>
        this.props.deleteAdditionalInfoAction(data)
      );
    deletedAdditionalInformationFunc('clear');
  };
  handleCloseAlertValidation = () =>
    this.setState({
      ...this.state,
      openModalAlertValidation: !this.state.openModalAlertValidation,
    });
  handleCloseCopyPackage = () => {
    let modalCopyPackage = { ...this.state.modalCopyPackage, openModal: false };
    this.setState({ ...this.state, modalCopyPackage });
  };
  // handle change untuk menentukan series package variable x atau bukan
  handleChangeCurrentPackage = (e, { name, objectName, value }) => {
    let currentPackage = { ...this.props[objectName] };
    currentPackage[name] = value;
    this.props.setCurrentPackage(currentPackage);
  };
  handleOnchange = async (e, { name, value, objectname }) => {
    const { generalInformation, errors } = this.props;

    await this.props.handleGeneralInfo(name, value, objectname);

    await this.props.checkGeneralInformation(generalInformation, errors);
  };
  // [END] handle change untuk menentukan series package variable x atau bukan
  render() {
    if (this.state.goTo) {
      return <Redirect to={this.state.linkGoTo} />;
    }
    const {
      openConfirmation,
      messageConfirmation,
      openModalAlert,
      headerAlert,
      contentAlert,
      messageModalAlert,
      modalCopyPackage,
    } = this.state;
    const {
      disabledEdit,
      isPublished,
      currentPackage,
      isThereTransactionHistory,
      // generalInformation,
      isOperation,
    } = this.props;
    let isCreate = currentPackage.status === 'create';
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as="h3" style={{ margin: '10px' }}>
              Status:{' '}
              {currentPackage.status === 'create'
                ? '-'
                : isPublished
                ? 'Publish'
                : 'Draft'}
            </Header>
          </Grid.Column>
          <Grid.Column width={8} textAlign="right">
            <div
            // style={
            //   isPublished
            //     ? { marginLeft: '50.6em' }
            //     : isCreate
            //       ? { marginLeft: '64em' }
            //       : { marginLeft: '44.4em' }
            // }
            >
              <Button
                content="Back"
                style={styleButtonBlue}
                name="back"
                onClick={this.handleClickConfirmation}
              />
              {!isOperation && !isCreate && (
                <Button
                  content={isPublished ? 'Unpublish' : 'Publish'}
                  style={styleButtonYellow}
                  onClick={this.handleClickConfirmation}
                  name="publish"
                  disabled={
                    isPublished && isThereTransactionHistory ? true : false
                  }
                />
              )}
              {!isOperation && !isCreate && (
                <Button
                  content="Copy"
                  positive
                  name="copy"
                  onClick={this.handleClickConfirmation}
                />
              )}
              {isOperation && isPublished ? null : (
                <Button
                  content={disabledEdit ? 'Edit' : 'Save'} //Edit Or Save sent variable from parent to child
                  style={styleButtonBlue}
                  onClick={this.handleClickConfirmation}
                  name="save" //Edit Or Save sent variable from parent to child
                />
              )}
              {!isOperation && !isPublished && !isCreate ? (
                <Button
                  content={'Delete'}
                  style={styleButtonRed}
                  onClick={this.handleClickConfirmation}
                  name="delete"
                />
              ) : null}
              <ModalConfirm
                openModal={openConfirmation}
                size="mini"
                close={this.handleClose}
                confirm={this.handleClickAction}
                message={messageConfirmation}
              />
              <ModalAlert
                openModal={openModalAlert}
                handleClose={this.handleCloseAlert}
                header={headerAlert}
                content={contentAlert}
                nameButton="OK"
              />
              <ModalAlert
                openModal={this.state.openModalAlertValidation}
                handleClose={this.handleCloseAlertValidation}
                header="Failed"
                content={messageModalAlert}
                nameButton="OK"
                isArrayContentAlert={true}
              />
              <ModalCopyPackage
                open={modalCopyPackage.openModal}
                id={modalCopyPackage.selectedId}
                type={modalCopyPackage.typePackage}
                startDate={modalCopyPackage.startDate}
                handleClose={this.handleCloseCopyPackage}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
AllButtonActionReadyPackage.propTypes = {
  currentPackage: PropTypes.object,
  guests: PropTypes.object,
  roomAllocation: PropTypes.object,
  guestList: PropTypes.array,
  commission: PropTypes.object,
  supplements: PropTypes.array,
  postJoinTourOnBehalf: PropTypes.func,
  requestDemoPrice: PropTypes.object,
  postCreateTourTransactionAction: PropTypes.func,
  tourOperator: PropTypes.object,
  onBehalf: PropTypes.object,
  availablePax: PropTypes.number,
  postConfirmTransaction: PropTypes.func,
  postEditQuotationAction: PropTypes.func,
  dailyProgram: PropTypes.object,
  reloadPage: PropTypes.func,
  bookingStatus: PropTypes.string,
  putPackageAction: PropTypes.func,
  putGeneralInfoPackageAction: PropTypes.func,
  putAllowedPaxPackageAction: PropTypes.func,
  postPackageAction: PropTypes.func,
  postPublishPackageAction: PropTypes.func,
  copyPackageAction: PropTypes.func,
  deletePackageAction: PropTypes.func,
  isPublished: PropTypes.bool,
  disabledEdit: PropTypes.bool,
  generalInformation: PropTypes.object,
  headlineProgram: PropTypes.array,
  images: PropTypes.array,
  additionalInfo: PropTypes.array,
  resetPackageAction: PropTypes.func,
  commissions: PropTypes.array,
  paymentTerms: PropTypes.array,
  price: PropTypes.object,
  errorGeneralInformation: PropTypes.object,
  setErrorsGeneralInform: PropTypes.func,
  brochures: PropTypes.object,
  uploadBrochurePackageAction: PropTypes.func,
  getPackage: PropTypes.func,
  listAdditionalItem: PropTypes.array,
  getFixedPackage: PropTypes.func,
  setCurrentPackage: PropTypes.func,
  isThereTransactionHistory: PropTypes.bool,
  setPage: PropTypes.func,
  listPeriod: PropTypes.array,
  getPackageVariableDate: PropTypes.func,
  handleGeneralInfo: PropTypes.func,
  checkGeneralInformation: PropTypes.func,
  postAdditionalInfoAction: PropTypes.func,
  deleteAdditionalInfoAction: PropTypes.func,
  putAdditionalInfoAction: PropTypes.func,
  deletedAdditionalInformation: PropTypes.array,
  deletedAdditionalInformationFunc: PropTypes.func,
  deleteBookingTemplateImagesAction: PropTypes.func,
  handleDeleteImages: PropTypes.func,
  deletedImages: PropTypes.array,
  errorTourOperator: PropTypes.object,
  handleErrorTourOperator: PropTypes.func,
  SharingPax: PropTypes.object,
  isOperation: PropTypes.bool,
  errors: PropTypes.object,
  putAdditionalInfoOperation: PropTypes.func,
};
const mapStateToProps = state => {
  return {
    postPackageStatus: state.PackageStore.postPackageStatus,
    postPackage: state.PackageStore.postPackage,
    putPackageStatus: state.PackageStore.putPackageStatus,
    errorMessage: state.PackageStore.errors,
  };
};

export default connect(
  mapStateToProps,
  {
    postPublishPackageAction,
    copyPackageAction,
    deletePackageAction,
    postPackageAction,
    putPackageAction,
    resetPackageAction,
    uploadBrochurePackageAction,
    getFixedPackage,
    putGeneralInfoPackageAction,
    putAllowedPaxPackageAction,
    postAdditionalInfoAction,
    deleteAdditionalInfoAction,
    putAdditionalInfoAction,
    deleteBookingTemplateImagesAction,
    putAdditionalInfoOperation,
  }
)(AllButtonActionReadyPackage);
