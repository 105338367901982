import React from 'react';
//import Helmet from 'react-helmet';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { Button, Header, Modal, Form, Radio } from 'semantic-ui-react';
import PropTypes from 'prop-types';
//import { rangeDate } from '../../../../scripts/moment';
import moment from 'moment';

class ModalDateRange extends React.Component {
  static defaultProps = {
    numberOfMonths: 2,
  };
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.state = this.getInitialState();
  }
  getInitialState() {
    return {
      from: this.props.dateRange.tourStartDate
        ? moment(this.props.dateRange.tourStartDate, 'YYYY-MM-DD')._d
        : null,
      to: this.props.dateRange.tourEndDate
        ? moment(this.props.dateRange.tourEndDate, 'YYYY-MM-DD')._d
        : this.props.dateRange.tourUntilDate
        ? moment(this.props.dateRange.tourUntilDate, 'YYYY-MM-DD')._d
        : null,
      // value: '1',
      //selectedDate: 'Tour starting date',
    };
  }
  handleDayClick(day) {
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);
  }
  handleResetClick() {
    this.setState(this.getInitialState());
  }
  handleChange = (e, { value }) => {
    const { handleRadioDateRange } = this.props;
    if (value === '1') {
      handleRadioDateRange(value);
    } else {
      handleRadioDateRange(value);
    }
  };
  filterByDate = (e, { id, name }) => {
    if (this.state.from && this.state.to) {
      //this.handleResetClick();
      this.props.actionDateRange(id, name);
    }
  };
  render() {
    //const { isHiddenOptions, defaultFilterName } = this.props;
    const { from, to } = this.state; //selectedDate
    const modifiers = { start: from, end: to };
    const { value, isHiddenOptions } = this.props;
    return (
      <Modal
        dimmer="blurring"
        closeIcon
        inverted
        open={this.props.openModal}
        onClose={this.props.handleClose}
        style={{ width: '43em' }}
      >
        <Header icon="filter" content={this.props.contentHeader} />
        <Modal.Content>
          {!isHiddenOptions && (
            <Form>
              <Form.Group inline>
                <Form.Field
                  control={Radio}
                  label="by tour starting date"
                  value="1"
                  checked={value === '1'}
                  onChange={this.handleChange}
                  // onChange={handleRadioDateRange}
                />
                <Form.Field
                  control={Radio}
                  label="by transaction creation date"
                  value="2"
                  checked={value === '2'}
                  onChange={this.handleChange}
                  // onChange={handleRadioDateRange}
                />
              </Form.Group>
            </Form>
          )}

          <Form>
            <Form.Field>
              {/* <label>
                {isHiddenOptions ? defaultFilterName : selectedDate}
              </label> */}
              <input
                placeholder="Tour Package"
                id="createPackage"
                value={
                  // {rangeDate(from, to)}
                  `${from ? moment(from).format('L') : ''} ${from ? '-' : ''} ${
                    to ? moment(to).format('L') : ''
                  }`
                }
              />
            </Form.Field>
          </Form>

          <div className="RangeExample">
            <p>
              {!from && !to && 'Please select the first day.'}
              {from && !to && 'Please select the last day.'}
            </p>
            <DayPicker
              className="Selectable"
              numberOfMonths={2}
              selectedDays={[from, { from, to }]}
              modifiers={modifiers}
              onDayClick={this.handleDayClick}
            />
            <style>{`
  .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .Selectable .DayPicker-Day {
    border-radius: 0 !important;
  }
  .Selectable .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .Selectable .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
`}</style>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            icon="search"
            content="Search"
            onClick={this.filterByDate}
            id={this.state}
            name={this.props.id}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

ModalDateRange.propTypes = {
  actionDateRange: PropTypes.func,
  id: PropTypes.string,
  openModal: PropTypes.func,
  handleClose: PropTypes.func,
  contentHeader: PropTypes.string,
  dateRange: PropTypes.object,
  filterByDate: PropTypes.object,
  // searchButton: PropTypes.func,
  isHiddenOptions: PropTypes.bool,
  // defaultFilterName: PropTypes.string,
  value: PropTypes.string,
  handleRadioDateRange: PropTypes.func,
};
export default ModalDateRange;
