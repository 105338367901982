import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SegmentExchangeRateList from './SegmentExchangeRate';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../scripts/pagination';
// import FilterData from '../../../scripts/filterDataTable';
import { filterByDateRange } from '../services/helper/filterDate';
import { sortDateDesc } from '../../../scripts/moment';

export default class ExchangeRateList extends Component {
  // constructor(props) {
  //   super(props);
  //   this.currentIndex = 0;
  //   this.state = {
  //     searchInput: '',
  //     activePage: 1,
  //   };
  // }
  static propTypes = {
    data: PropTypes.array,
  };
  // handleChange = (e, { name, value }) =>
  //   this.setState({ [name]: value, activePage: 1 });
  // handleChangeDate = (value, name) => {
  //   this.setState({ [name]: value, activePage: 1 });
  // };
  // pageChange = (e, { activePage }) => {
  //   this.setState({ activePage });
  // };
  render() {
    // let filterData = FilterData(
    //   this.props.data,
    //   this.props.filterBy,
    //   this.props.searchInput
    // );
    let filterData = filterByDateRange(this.props.data, this.props.searchInput);
    let sortedDate = sortDateDesc(filterData, 'EffectiveDate');
    let pagedData = datasetPagination(sortedDate, 7);
    let listData = pagedData[this.props.activePage - 1];

    let { numbersFrom, numbersTo } = getNumberFromTo(
      listData,
      this.props.activePage,
      7
    );
    let handleChange =
      this.props.filterBy === 'FormatedDate'
        ? this.props.handleChangeDate
        : this.props.filterBy === 'exchangeRate'
        ? this.props.handleChangeDate
        : this.props.handleChange;
    return (
      <SegmentExchangeRateList
        listData={listData}
        pagedData={pagedData}
        title={'Exchange Rate'}
        pageChange={this.props.pageChange}
        activePage={this.props.activePage}
        searchInput={this.props.searchInput}
        handleChange={handleChange}
        data={this.props.data}
        openNewRate={this.props.openNewRate}
        header={this.props.header}
        editExchangeRate={this.props.editExchangeRate}
        handleSorting={this.props.handleSorting}
        buttonDelete={this.props.buttonDelete}
        numbersFrom={numbersFrom}
        numbersTo={numbersTo}
        //
        openModalDateRange={this.props.openModalDateRange}
        dateRange={this.props.dateRange}
      />
    );
  }
}

ExchangeRateList.propTypes = {
  data: PropTypes.array,
  type: PropTypes.string,
  filterBy: PropTypes.string,
  buttonNew: PropTypes.func,
  butttonExport: PropTypes.func,
  buttonUploadData: PropTypes.func,
  buttonDownloadTemplate: PropTypes.func,
  butttonEdit: PropTypes.func,
  buttonDelete: PropTypes.func,
  buttonPublished: PropTypes.func,
  buttonCopy: PropTypes.func,
  buttonEdit: PropTypes.func,
  content: PropTypes.object,
  dataPublish: PropTypes.array,
  dataDraft: PropTypes.array,
  unlockUser: PropTypes.func,
  resetPassword: PropTypes.func,
  openNewRate: PropTypes.func,
  editExchangeRate: PropTypes.func,
  buttonSeeDetail: PropTypes.func,
  header: PropTypes.string,
  handleChange: PropTypes.func,
  handleSorting: PropTypes.func,
  buttonBack: PropTypes.func,
  disableButton: PropTypes.bool,
  //
  openModalDateRange: PropTypes.func,
  handleChangeDate: PropTypes.func,
  pageChange: PropTypes.func,
  activePage: PropTypes.number,
  searchInput: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dateRange: PropTypes.object,
};
