import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Button, Popup } from 'semantic-ui-react';
import TableRegionCoverages from './table/TableRegionCoverages';
const RegionCoverages = props => {
  return (
    //<Segment style={{ border: '0px' }} inverted color="red" tertiary>
    <div>
      {/* inverted color='red' tertiary */}
      <Grid columns="equal" style={{ marginTop: '0.5em' }}>
        <Grid.Row>
          <Grid.Column>
            <Header as="h3" content="Region Coverages" />
          </Grid.Column>
          <Grid.Column />
          <Grid.Column floated="right">
            <Popup
              trigger={
                <Button
                  positive
                  content="Add New"
                  onClick={props.openModalRegionCoverages}
                  floated="right"
                  disabled={props.disabled}
                />
              }
              content="Please fill region"
              open={props.openPopUp}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <TableRegionCoverages
              listData={props.listData}
              disabled={props.disabled}
              deleteData={props.deleteData}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

RegionCoverages.propTypes = {
  openModalRegionCoverages: PropTypes.func,
  disabled: PropTypes.bool,
  listData: PropTypes.array,
  deleteData: PropTypes.func,
  openPopUp: PropTypes.bool,
};
export default RegionCoverages;
