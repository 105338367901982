import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';
//import PropTypes from 'prop-types';
import GeneralInformation from '../form/generalInfoTransportationUnit';
//import TransportationUnitList from '../table/tableAccomodationItemList';
//import ImageTransportationUnit from './imageTransportationUnit';
//import ButtonBackAndSave from '../../common/buttonBackAndSave';
import TransportationUnitPrice from './TransportationPrice';
import PropTypes from 'prop-types';
import ButtonBackAndSave from '../../../../../components/common/buttonBackAndSave';
import ImageListComponent from '../../../../../components/image/image';
//import ImageListComponent from '../../image/image';
import { HeaderTableImageAccomodationProfile } from '../../../../../components/table/headerTabel';
import HeaderTitle from '../../../../../components/common/headerTitle';
const TransportationUnitForm = props => (
  <div>
    <Segment style={{ marginTop: '30px' }}>
      <Grid columns={2} padded="vertically">
        <Grid.Column>
          {/* Kiri */}
          <HeaderTitle title={props.title} />
          <GeneralInformation
            transportationHeader={props.transportationHeader}
            rating={props.rating}
            listTransportationType={props.listTransportationType}
            handleChangeUnitHeader={props.handleChangeUnitHeader}
            errors={props.errors}
            disablePage={props.disablePage}
          />
        </Grid.Column>
        <Grid.Column>
          {/* Kanan */}
          <ButtonBackAndSave
            save={props.saveTransportation}
            nameBack="Back"
            nameSave={props.contentButton}
            backPage={props.backPage}
          />
          <ImageListComponent
            addData={props.modalItem}
            buttonContent="Add Image"
            headerContent="Image"
            deleteData={props.deleteData}
            headerTable={HeaderTableImageAccomodationProfile}
            listData={props.listImage}
            idButton="Image"
            openDetail={props.openDetail}
            handleChangePrimaryImage={props.handleChangePrimaryImage}
            activePage={props.activePageImage}
            pageChange={props.pageChangeImage}
            pagedData={props.pageImage}
            disablePage={props.disablePage}
          />
        </Grid.Column>
      </Grid>
      <TransportationUnitPrice
        listData={props.transportationUnitByHeader}
        addNew={props.addNew}
        handleClickEdit={props.handleClickEdit}
        openModalPrice={props.openModalPrice}
        handleClose={props.handleClose}
        listRegion={props.listRegion}
        listOriginCity={props.listOriginCity}
        listDestCity={props.listDestCity}
        handleChangePrice={props.handleChangePrice}
        addPriceDetails={props.addPriceDetails}
        transportationPrice={props.transportationPrice}
        servicePriceType={props.servicePriceType}
        currencies={props.currencies}
        handleChangeCostPriceDetails={props.handleChangeCostPriceDetails}
        handleChangePriceDetails={props.handleChangePriceDetails}
        addPriceToList={props.addPriceToList}
        disablePage={props.disablePage}
        handleClickDelete={props.handleClickDelete}
        transportationHeader={props.transportationHeader}
        countryName={props.countryName}
        headerModal={props.headerModal}
        openModalPriceDetail={props.openModalPriceDetail}
        handleEditPriceDetail={props.handleEditPriceDetail}
        //======================================
        activePage={props.activePage}
        setActivePage={props.setActivePage}
      />
    </Segment>
  </div>
);
TransportationUnitForm.propTypes = {
  transportationHeader: PropTypes.object,
  transportationUnitByHeader: PropTypes.array,
  addNew: PropTypes.func,
  openModalPrice: PropTypes.bool,
  handleClose: PropTypes.func,
  listRegion: PropTypes.array,
  listOriginCity: PropTypes.array,
  listDestCity: PropTypes.array,
  handleChangePrice: PropTypes.func,
  addPriceDetails: PropTypes.func,
  transportationPrice: PropTypes.object,
  handleClickEdit: PropTypes.func,
  servicePriceType: PropTypes.array,
  currencies: PropTypes.array,
  handleChangeCostPriceDetails: PropTypes.func,
  handleChangePriceDetails: PropTypes.func,
  addPriceToList: PropTypes.func,
  saveTransportation: PropTypes.func,
  title: PropTypes.string,
  rating: PropTypes.array,
  listTransportationType: PropTypes.array,
  handleChangeUnitHeader: PropTypes.func,
  errors: PropTypes.object,
  disablePage: PropTypes.bool,
  contentButton: PropTypes.string,
  backPage: PropTypes.func,
  handleClickDelete: PropTypes.func,
  modalItem: PropTypes.func,
  deleteData: PropTypes.func,
  listImage: PropTypes.array,
  openDetail: PropTypes.func,
  handleChangePrimaryImage: PropTypes.func,
  activePageImage: PropTypes.number,
  pageChangeImage: PropTypes.func,
  pageImage: PropTypes.array,
  headerModal: PropTypes.string,
  countryName: PropTypes.string,
  openModalPriceDetail: PropTypes.func,
  handleEditPriceDetail: PropTypes.func,
  activePage: PropTypes.number,
  setActivePage: PropTypes.func,
};
export default TransportationUnitForm;
