import React from 'react';
import { connect } from 'react-redux';
import { Segment, Icon, Grid, Label } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import SegmentGuestList from '../../shared/components/guest/SegmentFormGuest';
import GeneralInformation from '../../shared/components/tourTransaction/generalInformation/GeneralInformation';
import { getReadyPackageByTypeAndIdAction } from '../../../actions/readyPackageAction';
import { getListByCodeConfirmRoomAllotmentAction } from '../../shared/actions/accommodationAction';
import {
  setAdditionalItem,
  setAdditionalPrice,
} from '../../../scripts/additionalItem';
import { SegmentPackageDetail } from '../../../components/segment';
import {
  setTourInformation,
  setGuests,
  setRoomAllocation,
  setCommision,
  getNowPaymentTerm,
  getPaymentTerms,
  setOnBehalf,
} from '../../../components/genericFunction/convertPackage';
import {
  getActivityListAction,
  resetActivityListAction,
  getTourOperatorProfileAction,
} from '../../../actions/packageAction';
import {
  getCityInCountry,
  resetCityAction,
} from '../../shared/actions/cityAction';
import { getCurrenciesAction } from '../../../actions/currenciesAction';
import {
  getTourTypeAction,
  getTourOperatorByIdAction,
} from '../../../actions/tourTransactionAction';
import {
  getTransactionByIdAction,
  getSupplementItemByFixedPackageIdAction,
  resetStatusTransactionAction,
  getInvoiceByCompanyCodeAction,
  getPrintPaymentReceiptTransactionAction,
  getTourTransactionsCommissionPaymentAction, // dipisah
  getInvoiceHistoryByTransactionIdAction,
} from '../../../actions/tourTransactionAction';
import PaymentHistoryAndInvoice from './components/SegmentPaymentList';
import {
  //   // initialSetCustomPackage,
  //   initialSetReadyPackage,
  dailyGenerateCopy,
} from '../../../scripts/itineraryBuilder/dailyProgram';
// import { inputValidation } from '../../../scripts/itineraryBuilder/dailyProgramValidation';
import { inputValidation } from './services/validation/validationgeneralInfo';
import { getAirportAction } from '../../../actions/airportAction'; // dipisah
import SegmentTourOperator from '../../shared/components/tourTransaction/tourOperator/segment/TourOperatorSegment';
import { changeGuest } from '../../../scripts/itineraryBuilder/guest';
import AllButtonTransaction from '../../shared/components/tourTransaction/allButtonTransaction/AllButtonTransaction';
import SegmentDestinationsSummary from '../../shared/components/tourTransaction/destinationSummary/SegmentDestinationsSummary';
import SegmentDailyProgram from '../../shared/components/tourTransaction/dailyProgram/TourItinerary';
import Loader from '../../../components/loader/loader';
import AdditionalInformation from './components/SegmentAdditionalInformation/AdditionalInformation';
import { convertDateFormat } from '../../../components/genericFunction/moment';
import {
  fixDateDailyProgram,
  // setHeadlineFixedPrice,
} from './services/helper/setItineraryAndMainProgramReadyFixPrice';
import setStayPeriod from './services/helper/setStayPeriod';
import {
  initialSetCustomPackage,
  initialSetReadyPackage,
  changeFirstArrivalDate,
} from '../../../scripts/itineraryBuilder/itineraryBuilder';
import { convertAfterGetSpecialAdjustment } from '../../../scripts/specialAdjustment';
import { getPlaceByPlaceTypeIdAction } from '../../../actions/airportAction';
import SegmentAdditionalServices from '../../shared/components/tourTransaction/additionalServices/SegmentAdditionalServices';
import {
  updateAdditionalServiceAfterChangeDestination,
  initialAdditionalServices,
  getNewAdditonalServicesFromAdditionalServiceBookingTemplate,
} from '../../shared/services/additionalServices/processData';
// import { getAdditionalItemTypeAction } from '../../shared/actions/additionalItemAction';
import { getAdditionalItemTypeAction } from '../../shared/actions/additionalItemAction';
import SpecialAdjustment from '../../shared/components/tourTransaction/specialAdjustment/SpecialAdjustment';
import ModalPaymentTransaction from '../../shared/components/modal/ModalPaymentTransaction';
import SegmentAdditionalItem from '../../shared/components/tourTransaction/generalInformation/segment/SegmentAdditionalItem';
import OrderedItems from '../../shared/components/tourTransaction/orderedItems/OrderedItems';
import _ from 'lodash';
import ResourceStatus from '../../shared/components/tourTransaction/bookingItemStatus/ResourceStatus';
import { postRemoveGuestListApi } from '../../../api/guestApi';
import ModalAlert from '../../../components/modal/modalAlert';

const FILL = 'Please fill ';
const CHOOSE = 'Please choose ';
class CreateTransaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPackage: {
        type: '',
        id: 0,
        status: '',
        disabledEdit: false,
        disabled: true,
      },
      supplements: [],
      tourInformation: {
        tourName: '',
        firstDestination: '',
        groupCapacity: '',
        tourType: '',
        tourCategory: '',
        //ready fix prie
        startTour: new Date(),
        expiredOn: new Date(),
        tourNote: '',
      },
      guests: { adults: 0, childs: 0, infants: 0 },
      guestList: [],
      price: {},
      onBehalf: {},
      headlineProgram: {
        Departures: [],
        MainPrograms: [],
        Returns: [],
      },
      commission: {
        splitCommision: null,
        printCommision: 0,
      },
      commissionsPackage: [],
      errors: {
        firstName: { error: false, message: '' },
        lastName: { error: false, message: '' },
        identityNbr: { error: false, message: '' },
        identityType: { error: false, message: '' },
        guestTitle: { error: false, message: '' },
        countryId: { error: false, message: '' },
        summaryProgramErrorrs: [],
      },
      dailyProgram: [],
      roomAllocation: {
        ChildExtraBedQty: 0,
        ChildSharingRoomQty: 0,
        ChildSingleRoomQty: 0,
        ExtraBedQty: 0,
        BabyCrib: 0,
        NoBed: 0,
        SharingBedQty: 0,
        SharingRoomQty: 0,
        SingleRoomQty: 0,
      },
      tourOperator: null,
      firstDestination: null,
      accommodationChangeIndex: null,
      paymentTerm: [],
      invoiceList: [],
      tourTransactionsCommission: [],
      requestDemoPrice: {},
      demoPrice: null,
      availablePax: 0,
      packageDetail: null,
      errorGeneralInformation: {
        tourName: { error: false, message: FILL + 'tour name' },
        groupCapacity: { error: false, message: CHOOSE + 'group capacity' },
        tourType: { error: false, message: CHOOSE + 'tour type' },
        tourCategory: { error: false, message: CHOOSE + 'tour category' },
        firstDestination: { error: false, message: FILL + 'first destination' },
      },
      foc: {
        Qty: 0,
        Description: 'FoC',
      },
      specialAdjusments: [],
      CurrencyId: '',
      itemBookingList: [],
      additionalInfo: [],
      availableDate: [],
      loadingDetail: false,
      // tourNote: '',
      cancellationReasonId: null,
      cancellationReasonNote: '',
      additionalServices: [],
      isChangeSpecialAdjustment: false,
      openModalPayment: false,
      //
      openModalAlert: false,
      headerAlert: '',
      contentAlert: '',
      loadingSaveGuest: false,
    };
  }
  componentDidMount = () => {
    const { TypeTransaction, PackageType, Id } = this.props.match.params;
    TypeTransaction !== 'create' && this.props.getTourOperatorProfileAction();
    this.props.getCityInCountry();
    this.props.getCurrenciesAction();
    this.props.getTourTypeAction();
    // this.props.getTourTransactionsCommissionPaymentAction();
    this.props.getActivityListAction();
    this.props.getAdditionalItemTypeAction();
    if (this.props.placeByType) {
      this.props.getPlaceByPlaceTypeIdAction(1);
    }
    if (PackageType === 'Custom' && TypeTransaction === 'create') {
      let initial = initialSetCustomPackage(
        this.state.headlineProgram,
        this.state.roomAllocation
      );
      let currentPackage = {
        type: PackageType,
        id: Id,
        status: TypeTransaction,
        disabledEdit:
          PackageType !== 'Quotation' && TypeTransaction !== 'create',
        disabled: true,
      };
      this.setState({
        headlineProgram: initial.headlineProgram,
        dailyProgram: initial.dailyProgram,
        currentPackage,
        loadingDetail: false,
      });
    } else {
      this.getPackage(TypeTransaction, PackageType, Id);
    }
  };

  componentDidUpdate = () => {
    const { demoPrice, CurrencyId } = this.state;
    if (
      demoPrice !== null &&
      (CurrencyId === '' ||
        CurrencyId === null ||
        CurrencyId !== demoPrice.BookingDetailSum.CurrencyId)
    ) {
      this.setState({ CurrencyId: demoPrice.BookingDetailSum.CurrencyId });
    }
  };

  getPackage = (TypeTransaction, PackageType, Id) => {
    // eslint-disable-next-line
    if (TypeTransaction === 'create' && PackageType !== 'Similar') {
      this.props.getReadyPackageByTypeAndIdAction(PackageType, Id).then(() => {
        this.setPackage(this.props.packageById);
        if (PackageType === 'Fixed') {
          this.props.packageById.TourOperator &&
            this.getTourOperator(this.props.packageById.TourOperator.Id);
          this.props
            .getSupplementItemByFixedPackageIdAction(Id)
            .then(() => this.setSupplementState(this.props.packageById));
        }
      });
    } else {
      if (PackageType === 'Similar') {
        this.props.getTransactionByIdAction(Id).then(() => {
          this.setPackage(this.props.transactionDetail);
        });
      } else {
        this.props
          .getTransactionByIdAction(Id)
          .then(async () => {
            await this.setPackage(this.props.transactionDetail);
            await this.getTourOperator(
              this.props.transactionDetail.BookingDetailSum
                .TourOperatorProfileId
            );
            this.setState({
              ...this.state,
              demoPrice: this.props.transactionDetail,
            });
          })
          .catch(err => console.log(err));
      }
    }
    let currentPackage = {
      type: PackageType,
      id: Id,
      status: TypeTransaction,
      disabledEdit: PackageType !== 'Quotation' && TypeTransaction !== 'create',
      disabled: true,
    };
    this.setState({
      ...this.state,
      currentPackage,
    });
  };

  setPackage = async packageDetail => {
    let { currentPackage } = this.state;
    // const { Id } = this.props.match.params;
    let { type, status } = currentPackage;
    let {
      HeadlineProgram,
      DailyPrograms,
      BookingDetailSum,
      CancellationReason,
      CancellationReasonNote,
      Prices,
    } = packageDetail;
    let { activityList, cityInCountry } = this.props;
    let isCreate = status === 'create';
    let isFixedPackage = type === 'Fixed';
    let isReadyFixPrice = type === 'FixedDateVariable';
    let tourInformation = setTourInformation(packageDetail);
    tourInformation.minPaxReducer = packageDetail.MinPax;
    let guests = setGuests(packageDetail, status);
    let onBehalf = setOnBehalf(packageDetail, status);
    let guestList = isCreate ? [] : packageDetail.TourGuestSum;
    let roomAllocation = setRoomAllocation(packageDetail, type, status);
    if (type !== 'Similar') {
      guestList = packageDetail.TourGuestSum;
    }
    let newHeadline =
      type !== 'Fixed'
        ? // type === 'Fixed'
          initialSetReadyPackage(
            packageDetail,
            status !== 'create' ? 'Quotation' : type,
            cityInCountry,
            roomAllocation,
            isReadyFixPrice
          )
        : null;
    let newDp =
      type !== 'Fixed' && !isReadyFixPrice && status === 'create'
        ? // type === 'Fixed'
          dailyGenerateCopy(
            newHeadline.Departures,
            newHeadline.Returns,
            newHeadline.MainPrograms,
            DailyPrograms,
            activityList,
            this.props.cityInCountry
          )
        : null;

    this.getPlaceByRegion(HeadlineProgram.MainPrograms);
    // this.props.getInvoiceByCompanyCodeAction(onBehalf.customerCode).then(res =>
    //   this.setState({
    //     invoiceList: res.value.data.filter(
    //       // eslint-disable-next-line
    //       data => data.TourTransactionId == currentPackage.id
    //     ),
    //   })
    // );
    this.props
      .getInvoiceHistoryByTransactionIdAction(currentPackage.id)
      .then(res =>
        this.setState({
          invoiceList: res.value.data,
        })
      );
    this.props
      .getListByCodeConfirmRoomAllotmentAction(currentPackage.id)
      .then(res =>
        this.setState({
          itemBookingList: res.value.data,
        })
      );
    this.getCommissions();
    // this.props
    //   .getTourTransactionsCommissionPaymentAction(this.state.currentPackage.id)
    //   .then(res =>
    //     this.setState({
    //       tourTransactionsCommission: res.value.data,
    //     })
    //   );
    let price =
      isFixedPackage && isCreate
        ? packageDetail.Prices
        : isReadyFixPrice && isCreate
        ? packageDetail.Prices
        : {};
    let commission = setCommision(packageDetail, type, status);
    let commissionsPackage =
      isFixedPackage && isCreate ? packageDetail.Commissions : [];

    let availablePax = isFixedPackage
      ? packageDetail.BookingDetailSum.FixedPackage.MinimumGuest -
        packageDetail.BookingDetailSum.FixedPackage.ConfirmedGuest
      : 0;

    let focObjectIfNull = {
      Qty: 0,
      Description: 'FoC',
    };
    let specialAdjusments = convertAfterGetSpecialAdjustment(
      packageDetail.AdditionalItems,
      currentPackage.id
    );
    let availableDate =
      isReadyFixPrice &&
      isCreate &&
      setStayPeriod(packageDetail.BookingTemplatePeriodDates);

    // additional services
    let additionalServices =
      currentPackage.status === 'edit'
        ? initialAdditionalServices(packageDetail.AdditionalServices)
        : await this.initialSetAdditionalServices(packageDetail);
    currentPackage.disabledEdit =
      currentPackage.type === 'Quotation' &&
      packageDetail.BookingDetailSum.Quotation.IsAccepted
        ? true
        : currentPackage.disabledEdit;
    this.setState(
      {
        ...this.state,
        tourInformation,
        guests,
        guestList,
        onBehalf,
        roomAllocation,
        price,
        commission,
        commissionsPackage,
        headlineProgram: newHeadline ? newHeadline : HeadlineProgram,
        dailyProgram: newDp ? newDp : DailyPrograms,
        paymentTerm: BookingDetailSum.PaymentTerms,
        availablePax,
        bookingStatus: BookingDetailSum.Status,
        packageDetail,
        foc: BookingDetailSum.FoC ? BookingDetailSum.FoC : focObjectIfNull,
        specialAdjusments: type !== 'Similar' ? specialAdjusments : [],
        CurrencyId: BookingDetailSum.CurrencyId
          ? BookingDetailSum.CurrencyId
          : Prices
          ? Prices.CurrencyId
          : BookingDetailSum.CurrencyId,
        additionalInfo: packageDetail
          ? packageDetail.Descriptions
            ? packageDetail.Descriptions
            : packageDetail.BookingTemplateDescriptionBindingModels
          : [],
        availableDate,
        loadingDetail: false,
        cancellationReasonId: CancellationReason
          ? CancellationReason
          : CancellationReasonNote
          ? CancellationReasonNote
          : '-',
        additionalServices,
        currentPackage,
        tourOperator: BookingDetailSum.TourOperatorProfile
          ? {
              ...BookingDetailSum.TourOperatorProfile,
              Id: BookingDetailSum.TourOperatorProfile.TourOperatorProfileId,
            }
          : null,
      },
      () => {
        if (isReadyFixPrice && status === 'create') {
          this.handleSetDate(
            availableDate.length > 0 ? availableDate[0] : new Date(),
            'startTour'
          );
        }
      }
    );
  };
  getPlaceByRegion = summaryProgram => {
    // eslint-disable-next-line
    summaryProgram.map(item => {
      // eslint-disable-next-line
      if (item.hasOwnProperty('AccommodationSummary')) {
        item.Region && this.props.getAirportAction(item.Region);
      }
      return item;
    });
  };
  // -------------------Handle general information ---------------
  setSupplementState = packageDetail => {
    let supplements = setAdditionalItem(
      this.props.supplements,
      this.state.currentPackage.id
    );
    let nowPayment = getNowPaymentTerm(packageDetail);
    let paymentTerms = getPaymentTerms(packageDetail);

    supplements = setAdditionalPrice(
      supplements,
      paymentTerms,
      nowPayment.PaymentPercentage
    );
    this.setState({
      ...this.state,
      supplements,
      nowPayment,
      paymentTerms,
    });
  };

  handleGeneralInfo = (name, value, objectname) => {
    let newData = this.state[objectname];
    newData = { ...newData, [name]: value };
    this.setState({ ...this.state, [objectname]: newData });
  };

  handleGuestAndRoom = (guests, room, guestList, headline, supplements) => {
    this.setState({
      ...this.state,
      guests,
      roomAllocation: room,
      guestList,
      headlineProgram: headline,
      supplements,
    });
    // change the guest quantity in mandatory additional services based on total guests
    this.setGuestInMandatory(guests);
  };

  handleSupplements = supplements =>
    this.setState({
      ...this.state,
      supplements,
    });

  handleOnbehalf = onBehalf => this.setState({ ...this.state, onBehalf });

  handleErrorsGeneralInform = errorGeneralInformation =>
    this.setState({ ...this.state, errorGeneralInformation });

  // -------------------End Handle general information ---------------
  //  =========== handle guest form ====================
  handleChangeGuestList = (e, { name, value, objectname }) => {
    let { guestList } = this.state;
    let newGuestList = changeGuest(name, guestList, objectname, value);
    this.setState({
      ...this.state,
      guestList: newGuestList,
    });
  };

  handleSaveGuest = () => {
    const { guests, guestList, roomAllocation, currentPackage } = this.state;
    this.setState({ loadingSaveGuest: true }, () => {
      let dataToSave = {
        AdultPax: guests.adults,
        ChildPax: guests.childs,
        InfantPax: guests.infants,
        RoomAllocation: roomAllocation,
        Guests: guestList.map(item => {
          return {
            ...item,
            IsActive: true,
          };
        }),
      };
      postRemoveGuestListApi(currentPackage.id, dataToSave)
        .then(() => {
          this.handleOpenAlert(true, 'Success', 'Success save guest');
          this.setState({ loadingSaveGuest: false });
        })
        .catch(() => {
          this.handleOpenAlert(true, 'Failed', 'Failed save guest');
          this.setState({ loadingSaveGuest: false });
        });
    });
  };
  //  =========== end handle guest form ====================
  setDailyProgram = setDailyProgram => {
    this.setState({
      dailyProgram: setDailyProgram,
    });
  };

  //  =========== handle destination summary form ====================
  setheadlineProgram = headlineProgram => {
    let additionalServices = updateAdditionalServiceAfterChangeDestination(
      headlineProgram,
      this.state.additionalServices
    );
    this.setState({
      ...this.state,
      headlineProgram,
      additionalServices,
    });
  };
  //  =========== end handle destination summary form ====================
  //  =========== handle tour operator ====================
  getTourOperator = id => {
    this.props
      .getTourOperatorByIdAction(id)
      .then(() => this.setTourOperator(this.props.tourOperatorById));
  };

  setTourOperator = tourOperator =>
    this.setState({
      ...this.state,
      tourOperator,
    });

  setReqDemoPrice = requestDemoPrice =>
    this.setState({
      ...this.state,
      requestDemoPrice,
    });

  setDemoPrice = demoPrice => this.setState({ ...this.state, demoPrice });
  //  =========== end handle tour operator ====================

  //  =========== end handle accommodation ====================
  setAccommodationOnChangeIndex = index =>
    this.setState({
      ...this.state,
      accommodationChangeIndex: index,
    });
  //  =========== end handle tour operator ====================
  handleReloadPage = () => {
    window.location.reload();
  };
  addFocAmount = (name, value) => {
    const foc = this.state.foc;
    foc[name] = value;
    this.setState({ foc });
  };

  // ========== handle special adjusment ======================
  handleSpecialAdjustment = specialAdjusments => {
    let demoPrice = { ...this.state.demoPrice };
    demoPrice.AdditionalItems = specialAdjusments;
    this.setState({
      ...this.state,
      specialAdjusments,
      isChangeSpecialAdjustment: true,
    });
  };
  // ========== end  handle special adjusment =================

  // ========== handle state current package ==================
  setCurrentPackage = currentPackage => {
    this.setState({ ...this.state, currentPackage });
  };
  // ========== end handle state current package ==================
  getCommissions = () => {
    this.props
      .getTourTransactionsCommissionPaymentAction(this.state.currentPackage.id)
      .then(res =>
        this.setState({
          tourTransactionsCommission: res.value.data,
        })
      );
  };
  checkGeneralInformation = () => {
    const { tourInformation, errorGeneralInformation } = this.state;
    let generalInfoValidation = inputValidation(
      tourInformation,
      errorGeneralInformation
    );
    this.handleErrorsGeneralInform(generalInfoValidation);
    return generalInfoValidation.isError;
  };
  handleSetDate = async (date, name) => {
    let { dailyProgram, headlineProgram } = this.state;
    let contex = this;
    // const { DailyPrograms } = contex.props.fixedPackageById;
    let headlineafterChangeDate = await changeFirstArrivalDate(
      convertDateFormat(date, 'YYYY-MM-DDTHH:mm:ss'),
      headlineProgram
    );
    let dailyProgramAfterChangeDate = await fixDateDailyProgram(
      convertDateFormat(
        headlineafterChangeDate.Departures[0].Date,
        'YYYY-MM-DD'
      ),
      dailyProgram
    );

    // contex.props.setDailyProgram(dailyProgramAfterChangeDate);
    // contex.props.setHeadlineProgram(headlineafterChangeDate);
    contex.setDailyProgram(dailyProgramAfterChangeDate);
    contex.setheadlineProgram(headlineafterChangeDate);
    let tourInformation = { ...this.state.tourInformation };
    tourInformation[name] = convertDateFormat(date, 'YYYY-MM-DD');
    this.setState({
      ...this.state,
      tourInformation,
    });
    // this.setState({
    //   selectedDateForVariableDate: date,
    // });
  };

  // ================== additional services ==================
  setAdditionalServicesList = data => {
    this.setState({ ...this.state, additionalServices: data });
  };

  getAddService = (name, addServicesList) => {
    let addService = addServicesList.find(item => {
      return item.AdditionalServiceId === name; // name === id
    });
    let index = addServicesList.findIndex(item => {
      return item.AdditionalServiceId === name;
    });
    return { addService: addService, index: index };
  };

  handleIncreaseAmount = (e, { name, value }) => {
    let addServicesList = [...this.state.additionalServices];
    let { addService, index } = this.getAddService(name, addServicesList);
    addService.Quantity = value + 1;
    addServicesList[index] = addService;
    this.setState({ ...this.state, additionalServices: addServicesList });
  };

  handleDecreaseAmount = (e, { name, value }) => {
    let addServicesList = [...this.state.additionalServices];
    let { addService, index } = this.getAddService(name, addServicesList);
    if (value - 1 > 0) {
      addService.Quantity = value - 1;
      addServicesList[index] = addService;
      this.setState({ ...this.state, additionalServices: addServicesList });
    }
  };

  handleChangeAmount = (e, { name, value }) => {
    let addServicesList = [...this.state.additionalServices];
    let { addService, index } = this.getAddService(name, addServicesList);
    if (value > 0) {
      addService.Quantity = value;
      addServicesList[index] = addService;
      this.setState({ ...this.state, additionalServices: addServicesList });
    }
  };

  setGuestInMandatory = guests => {
    const { additionalServices } = this.state;
    let additionalServices_newMandatoryQty = additionalServices.map(item => {
      let result = item.IsInfantCount
        ? {
            ...item,
            Quantity: guests.adults + guests.childs + guests.infants,
          }
        : item.IsMandatory
        ? {
            ...item,
            Quantity: guests.adults + guests.childs,
          }
        : {
            ...item,
          };
      return result;
    });
    this.setState({
      ...this.state,
      additionalServices: additionalServices_newMandatoryQty,
    });
  };

  initialSetAdditionalServices = async packageData => {
    const { getCompanyProfile, packageById } = this.props;
    let result = await getNewAdditonalServicesFromAdditionalServiceBookingTemplate(
      packageData.AdditionalServices || [],
      packageById.BookingDetailSum &&
        packageById.BookingDetailSum.PackageType === 'FixedDateVariable'
        ? packageById.Prices.CurrencyId
        : getCompanyProfile
        ? getCompanyProfile.Currency
        : 'IDR'
    );
    let data = initialAdditionalServices(result || []);
    return await data;
  };

  // ================== end additional services ==================

  changeSpecialAdjustment = () => {
    this.setState({
      isChangeSpecialAdjustment: false,
    });
  };

  handleCloseModalPayment = () => {
    this.setState({
      openModalPayment: false,
    });
  };
  handleOpenModalPayement = () => this.setState({ openModalPayment: true });

  handleCloseAlert = () => {
    const { TypeTransaction, PackageType, Id } = this.props.match.params;
    if (this.state.headerAlert === 'Success') {
      this.setState(
        { openModalAlert: false, headerAlert: '', contentAlert: '' },
        () => {
          this.getPackage(TypeTransaction, PackageType, Id);
        }
      );
    } else {
      this.setState({
        openModalAlert: false,
        headerAlert: '',
        contentAlert: '',
      });
    }
  };

  handleOpenAlert = (open, header, content) => {
    this.setState({
      openModalAlert: open,
      headerAlert: header,
      contentAlert: content,
    });
  };

  render() {
    const {
      dailyProgram,
      currentPackage,
      tourTransactionsCommission,
      invoiceList,
      headlineProgram,
      tourOperator,
      requestDemoPrice,
      demoPrice,
      bookingStatus,
      errorGeneralInformation,
      specialAdjusments,
      itemBookingList,
      additionalInfo,
      availableDate,
      loadingDetail,
      cancellationReasonId,
      cancellationReasonNote,
      additionalServices,
      isChangeSpecialAdjustment,
      openModalPayment,
      packageDetail,
      loadingSaveGuest,
    } = this.state;
    const {
      activityList,
      loadingJoinTourOnBeHalf,
      loadingPostDemoJoinTour,
      loadingDemoPrice,
      loadingTourTransaction,
      listTransactionDetails,
      loadingCreateTour,
      isSales,
      isUserAdmin,
      isOperation,
      loadingGetResourceBookingByTransaction,
    } = this.props;
    let isReadyFixedPrice =
      this.state.currentPackage.type === 'FixedDateVariable';
    const { TypeTransaction, PackageType } = this.props.match.params;
    // let isReadyFixedPriceAndSeries =
    //   this.state.currentPackage.type === 'Fixed' ||
    //   ;
    // let loading = loadingTourTransaction || loadingJoinTourOnBeHalf;
    //let statusBooking = listTransactionDetails.BookingDetailSum.Status;
    let loadingRequestDemoPrice =
      currentPackage.type === 'Quotation' &&
      _.isEmpty(requestDemoPrice) &&
      loadingDemoPrice;
    let isAcceptedQuotation =
      packageDetail &&
      packageDetail.BookingDetailSum.Quotation &&
      packageDetail.BookingDetailSum.Quotation.IsAccepted;

    return (
      <Segment style={{ marginTop: '30px' }}>
        <Loader
          active={
            loadingTourTransaction ||
            loadingJoinTourOnBeHalf ||
            loadingCreateTour ||
            loadingDetail ||
            loadingDemoPrice ||
            loadingRequestDemoPrice ||
            loadingGetResourceBookingByTransaction ||
            loadingSaveGuest
          }
          inverted={true}
        />
        <Label
          as="h3"
          size="huge"
          style={{
            position: 'absolute',
            top: '-25px',
            background: '#38af95',
            color: 'white',
          }}
        >
          <Icon name="clipboard list" />{' '}
          {currentPackage.type === 'Quotation'
            ? 'Quotation Detail'
            : 'Tour Transaction'}
        </Label>
        <Grid style={{ marginTop: '3px' }}>
          <Grid.Row>
            <Grid.Column width={16}>
              <AllButtonTransaction
                currentPackage={this.state.currentPackage}
                guests={this.state.guests}
                roomAllocation={this.state.roomAllocation}
                guestList={this.state.guestList}
                commission={this.state.commission}
                supplements={this.state.supplements}
                handleClick={this.handleClickAction}
                requestDemoPrice={requestDemoPrice}
                tourOperator={this.state.tourOperator}
                onBehalf={this.state.onBehalf}
                availablePax={this.state.availablePax}
                dailyProgram={this.state.dailyProgram}
                transactionDetail={listTransactionDetails}
                reloadPage={this.handleReloadPage}
                bookingStatus={bookingStatus}
                foc={this.state.foc}
                specialAdjusments={specialAdjusments}
                setCurrentPackage={this.setCurrentPackage}
                //ready fix price
                tourInformation={this.state.tourInformation}
                isReadyFixedPrice={isReadyFixedPrice}
                tourNote={this.state.tourInformation.tourNote}
                additionalServices={this.state.additionalServices}
                handleOpenModalPayement={this.handleOpenModalPayement}
                disabledDropdown={
                  loadingTourTransaction ||
                  loadingJoinTourOnBeHalf ||
                  loadingCreateTour ||
                  loadingDetail ||
                  loadingDemoPrice ||
                  loadingRequestDemoPrice
                }
              />
            </Grid.Column>
            <Grid.Column width={16}>
              <GeneralInformation
                id={this.props.match.params.Id}
                currentPackage={this.state.currentPackage}
                tourInformation={this.state.tourInformation}
                supplements={this.state.supplements}
                optionCityInCountry={this.props.optionCityInCountry}
                cityInCountry={this.props.cityInCountry}
                // loadingCityGroup={this.props.loadingCityGroup}
                guests={this.state.guests}
                guestList={this.state.guestList}
                roomAllocation={this.state.roomAllocation}
                priceRoom={this.state.price}
                headlineProgram={this.state.headlineProgram}
                handleGuestAndRoom={this.handleGuestAndRoom}
                commission={this.state.commission}
                commissionsPackage={this.state.commissionsPackage}
                handleGeneralInfo={this.handleGeneralInfo}
                handleSupplements={this.handleSupplements}
                paymentTerms={this.state.paymentTerms}
                nowPayment={this.state.nowPayment}
                handleOnbehalf={this.handleOnbehalf}
                onBehalf={this.state.onBehalf}
                setAccommodationOnChangeIndex={
                  this.setAccommodationOnChangeIndex
                }
                setheadlineProgram={this.setheadlineProgram}
                packageDetail={this.state.packageDetail}
                errors={errorGeneralInformation}
                setErrorsGeneralInform={this.handleErrorsGeneralInform}
                foc={this.state.foc}
                addFocAmount={this.addFocAmount}
                // isPackageMangement={
                //   isReadyFixedPrice
                //   ||
                //   this.state.currentPackage.type === 'Fixed'
                // }
                // ready fixed price
                handleSetDate={this.handleSetDate}
                availableDate={availableDate}
                isTourTransactionReadyFixedPrice={isReadyFixedPrice}
                // tourNote={this.state.tourNote}
                //end
                cancellationReason={cancellationReasonId}
                cancellationReasonNote={cancellationReasonNote}
                isOperation={isOperation}
              />
            </Grid.Column>
          </Grid.Row>
          {this.state.currentPackage.type === 'Fixed' || (
            <Grid.Row fluid>
              <Grid.Column width={16}>
                <SegmentDestinationsSummary
                  headlineProgram={this.state.headlineProgram}
                  dailyProgram={this.state.dailyProgram}
                  setDailyProgram={this.setDailyProgram}
                  setheadlineProgram={this.setheadlineProgram}
                  handleGeneralInfo={this.handleGeneralInfo}
                  currentPackage={currentPackage}
                  tourInformation={this.state.tourInformation}
                  guests={this.state.guests}
                  errors={errorGeneralInformation}
                  setErrorsGeneralInform={this.handleErrorsGeneralInform}
                  roomAllocation={this.state.roomAllocation}
                  checkGeneralInformation={this.checkGeneralInformation}
                  isReadyFixedPrice={isReadyFixedPrice}
                  TypeTransaction={TypeTransaction}
                  PackageType={PackageType}
                  // isFixedPackageMangement={isReadyFixedPrice}
                  isSales={isSales}
                  isAdministrator={isUserAdmin}
                  isAcceptedQuotation={isAcceptedQuotation}
                />
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Column width={16}>
              <SegmentDailyProgram
                dailyProgram={dailyProgram}
                headlineProgram={headlineProgram}
                setDailyProgram={this.setDailyProgram}
                typePackage={
                  isAcceptedQuotation
                    ? 'Fixed'
                    : currentPackage.type === 'FixedDateVariable'
                    ? 'Fixed'
                    : currentPackage.type
                }
                movementList={activityList}
                setheadlineProgram={this.setheadlineProgram}
                guests={this.state.guests}
                setTourOperator={this.setTourOperator}
                currentPackage={currentPackage}
                isReadyFixedPrice={isReadyFixedPrice}
              />
            </Grid.Column>
          </Grid.Row>
          {this.state.currentPackage.type === 'Fixed' || (
            <Grid.Row>
              <Grid.Column width={16}>
                <SegmentAdditionalServices
                  selectedAdditionalServices={additionalServices}
                  headlineProgram={headlineProgram}
                  setAdditionalServices={this.setAdditionalServicesList}
                  handleIncrease={this.handleIncreaseAmount}
                  handleDecrease={this.handleDecreaseAmount}
                  currentPackage={this.state.currentPackage}
                  guests={this.state.guestList}
                  companyProfile={this.props.getCompanyProfile}
                  roomPrices={this.props.packageById.Prices}
                  packageById={this.props.packageById}
                  loadingDemoPrice={loadingDemoPrice}
                  handleChangeAmount={this.handleChangeAmount}
                  isAcceptedQuotation={isAcceptedQuotation}
                />
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Column width={16}>
            <SegmentTourOperator
              tourOperator={tourOperator}
              setTourOperator={this.setTourOperator}
              setHeadlineProgram={this.setHeadlineProgram}
              loadingTourOperator={this.props.loadingTourOperator}
              currentPackage={this.state.currentPackage}
              guests={this.state.guests}
              dailyProgram={this.state.dailyProgram}
              headlineProgram={this.state.headlineProgram}
              roomAllocation={this.state.roomAllocation}
              guestList={this.state.guestList}
              generalInformation={this.state.tourInformation}
              setReqDemoPrice={this.setReqDemoPrice}
              onBehalf={this.state.onBehalf}
              setDemoPrice={this.setDemoPrice}
              commission={this.state.commission}
              supplements={this.state.supplements}
              requestDemoPrice={this.state.requestDemoPrice}
              foc={this.state.foc}
              isReadyFixedPrice={isReadyFixedPrice}
              additionalServices={this.state.additionalServices}
              specialAdjusments={this.state.specialAdjusments}
              isChangeSpecialAdjustment={isChangeSpecialAdjustment}
              changeSpecialAdjustment={this.changeSpecialAdjustment}
            />
          </Grid.Column>
          <Grid.Row>
            <Grid.Column width={16}>
              <SegmentGuestList
                currentPackage={currentPackage}
                guests={this.state.guestList}
                errors={this.state.errors}
                handleChange={this.handleChangeGuestList}
                handleSaveGuest={this.handleSaveGuest}
                loading={this.state.loadingSaveGuest}
                statusPackage={
                  packageDetail
                    ? packageDetail.BookingDetailSum
                      ? packageDetail.BookingDetailSum.Status
                      : ''
                    : ''
                }
                isAcceptedQuotation={isAcceptedQuotation}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <SpecialAdjustment
                statusPackage={currentPackage.status}
                typePackage={currentPackage.type}
                tourTransactionsCommission={tourTransactionsCommission}
                specialAdjusments={specialAdjusments}
                currencies={this.props.currencies}
                handleSpecialAdjustment={this.handleSpecialAdjustment}
                CurrencyId={this.state.CurrencyId}
                currentPackage={currentPackage}
                packageType={this.props.match.params.PackageType}
                changeSpecialAdjustment={this.changeSpecialAdjustment}
                isSales={isSales}
                isAdministrator={isUserAdmin}
                isOperation={isOperation}
                isAcceptedQuotation={isAcceptedQuotation}
              />
            </Grid.Column>
          </Grid.Row>
          {currentPackage.status === 'create' &&
            currentPackage.type === 'Fixed' && (
              <Grid.Row>
                <Grid.Column width={16}>
                  <SegmentAdditionalItem
                    listData={this.state.supplements}
                    guests={this.state.guests}
                    paymentTerms={this.state.paymentTerms}
                    nowPayment={this.state.nowPayment}
                    handleSupplements={this.handleSupplements}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
          {(isSales || isUserAdmin) && (
            <Grid.Row>
              <Grid.Column width={16}>
                <SegmentPackageDetail
                  currentPackage={this.state.currentPackage}
                  demoPrice={demoPrice}
                  loading={loadingPostDemoJoinTour || loadingDemoPrice}
                  specialAdjusments={specialAdjusments}
                />
              </Grid.Column>
            </Grid.Row>
          )}

          {/* Resource Booking Detail / Ordered Item */}
          {currentPackage.status === 'edit' && (
            <Grid.Row>
              <Grid.Column width="16">
                <OrderedItems
                  id={this.props.match.params.Id}
                  dailyProgram={dailyProgram}
                />
              </Grid.Column>
            </Grid.Row>
          )}

          {currentPackage.status === 'edit' &&
            currentPackage.type !== 'Quotation' && (
              <Grid.Row>
                <Grid.Column width="16">
                  <ResourceStatus
                    id={this.props.match.params.Id}
                    dailyProgram={dailyProgram}
                  />
                </Grid.Column>
              </Grid.Row>
            )}

          {currentPackage.status !== 'create' &&
            currentPackage.type !== 'Quotation' && (
              <Grid.Row>
                <Grid.Column width={16}>
                  <PaymentHistoryAndInvoice
                    // contentPaymentHistoryList={paymentTerm}
                    contentPaymentHistoryList={
                      this.props.getTourTransactionBills
                    }
                    statusPackage={currentPackage.status}
                    invoiceList={invoiceList}
                    typePackage={currentPackage.type}
                    tourTransactionsCommission={tourTransactionsCommission}
                    specialAdjusments={specialAdjusments}
                    currencies={this.props.currencies}
                    handleSpecialAdjustment={this.handleSpecialAdjustment}
                    CurrencyId={this.state.CurrencyId}
                    currentPackage={currentPackage}
                    getCommissions={this.getCommissions}
                    itemBookingList={itemBookingList}
                    packageType={this.props.match.params.PackageType}
                    changeSpecialAdjustment={this.changeSpecialAdjustment}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
          <Grid.Column width={16}>
            <AdditionalInformation descriptions={additionalInfo} />
          </Grid.Column>

          <Grid.Column width={16}>
            <AllButtonTransaction
              currentPackage={this.state.currentPackage}
              guests={this.state.guests}
              roomAllocation={this.state.roomAllocation}
              guestList={this.state.guestList}
              commission={this.state.commission}
              supplements={this.state.supplements}
              handleClick={this.handleClickAction}
              requestDemoPrice={requestDemoPrice}
              tourOperator={this.state.tourOperator}
              onBehalf={this.state.onBehalf}
              availablePax={this.state.availablePax}
              dailyProgram={this.state.dailyProgram}
              transactionDetail={listTransactionDetails}
              reloadPage={this.handleReloadPage}
              bookingStatus={bookingStatus}
              foc={this.state.foc}
              specialAdjusments={specialAdjusments}
              setCurrentPackage={this.setCurrentPackage}
              additionalServices={this.state.additionalServices}
              //ready fix price
              tourInformation={this.state.tourInformation}
              isReadyFixedPrice={isReadyFixedPrice}
              tourNote={this.state.tourInformation.tourNote}
              handleOpenModalPayement={this.handleOpenModalPayement}
            />
          </Grid.Column>
          <Grid.Row />
        </Grid>
        <ModalPaymentTransaction
          open={openModalPayment}
          handleClose={this.handleCloseModalPayment}
          reloadPage={this.handleReloadPage}
          transactionId={currentPackage.id}
        />
        <ModalAlert
          openModal={this.state.openModalAlert}
          handleClose={this.handleCloseAlert}
          header={this.state.headerAlert}
          content={this.state.contentAlert}
          nameButton="OK"
        />
      </Segment>
    );
  }
}

CreateTransaction.propTypes = {
  getReadyPackageByTypeAndIdAction: PropTypes.func,
  getSupplementItemByFixedPackageIdAction: PropTypes.func,
  supplements: PropTypes.array,
  packageById: PropTypes.object,
  getCityInCountry: PropTypes.func,
  cityInCountry: PropTypes.array,
  // loadingCityGroup: PropTypes.bool,
  getTransactionByIdAction: PropTypes.func,
  transactionDetail: PropTypes.object,
  getActivityListAction: PropTypes.func,
  getActivityListStatus: PropTypes.string,
  activityList: PropTypes.array,
  resetActivityListAction: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      Id: PropTypes.number,
      type: PropTypes.string,
      TypeTransaction: PropTypes.string,
      PackageType: PropTypes.string,
    }),
  }),
  listTransactionDetailsStatus: PropTypes.string,
  resetStatusTransactionAction: PropTypes.func,
  listTransactionDetails: PropTypes.object,
  places: PropTypes.array,
  listAccommodation: PropTypes.array,
  listAccommodationItem: PropTypes.array,
  loadingAccommodation: PropTypes.bool,
  optionCityInCountry: PropTypes.array,
  getAirportAction: PropTypes.func,
  getTourOperatorProfileAction: PropTypes.func,
  getCurrenciesAction: PropTypes.func,
  getTourTypeAction: PropTypes.func,
  resetCityAction: PropTypes.func,
  getCityInCountryStatus: PropTypes.string,
  getTourOperatorByIdAction: PropTypes.func,
  tourOperatorById: PropTypes.object,
  loadingTourOperator: PropTypes.bool,
  getInvoiceByCompanyCodeAction: PropTypes.func,
  loadingDemoPrice: PropTypes.bool,
  loadingJoinTourOnBeHalf: PropTypes.bool,
  loadingPostDemoJoinTour: PropTypes.bool,
  loadingTourTransaction: PropTypes.bool,
  loadingCreateTour: PropTypes.bool,
  getTourTransactionsCommissionPaymentAction: PropTypes.func,
  getInvoiceHistoryByTransactionIdAction: PropTypes.func,
  currencies: PropTypes.array,
  getListByCodeConfirmRoomAllotmentAction: PropTypes.func,
  getTourTransactionBills: PropTypes.array,
  placeByType: PropTypes.array,
  getPlaceByPlaceTypeIdAction: PropTypes.func,
  getCompanyProfile: PropTypes.object,
  getAdditionalItemTypeAction: PropTypes.func,
  getAdditionalItemTypeList: PropTypes.array,
  isSales: PropTypes.bool,
  isUserAdmin: PropTypes.bool,
  isOperation: PropTypes.bool,
  loadingGetResourceBookingByTransaction: PropTypes.bool,
  postDemoPriceStatus: PropTypes.string,
};

const mapStateToProps = state => {
  let cityInCountry = state.CityStore.getCityInCountryList;
  let optionCityInCountry = cityInCountry.map((city, i) => {
    return {
      key: i,
      text: city.Name + ', ' + city.Region.Name + ', ' + city.Country.Name,
      value: city.Id,
    };
  });
  return {
    supplements: state.TourTransactionStore.supplementItemByFixedPackageId,
    packageById: state.ReadyPackageStore.getReadyPackageById,
    cityInCountry,
    transactionDetail: state.TourTransactionStore.listTransactionDetails,
    loadingReadyPackage: state.ReadyPackageStore.loading,
    loadingTourTransaction: state.TourTransactionStore.loading,
    // loadingCityGroup: state.CityGroupStore.loading,
    getActivityListStatus: state.PackageStore.getActivityListStatus,
    activityList: state.PackageStore.activityList,
    listTransactionDetailsStatus:
      state.TourTransactionStore.listTransactionDetailsStatus,
    listTransactionDetails: state.TourTransactionStore.listTransactionDetails,
    places: state.AirportStore.places,
    listAccommodationItem: state.AccomodationStore.getAccomodationByProfilIdAndDate.map(
      (item, i) => {
        return { key: i, text: item.Name, value: item.ServiceItemId };
      }
    ),
    loadingAccommodation: state.AccomodationStore.loading,
    optionCityInCountry,
    getCityInCountryStatus: state.CityStore.getCityInCountryStatus,
    tourOperatorById: state.TourTransactionStore.getTourOperatorById,
    loadingTourOperator: state.TourTransactionStore.loadingTourOperator,
    getRestaurantByCityDateAndPaxStatus:
      state.RestaurantStore.getRestaurantByCityDateAndPaxStatus,
    getExcurtionProfileByCity: state.ExcursionStore.getExcurtionProfileByCity,
    getExcurtionProfileByCityStatus:
      state.ExcursionStore.getExcurtionProfileByCityStatus,
    restaurantByCityDateAndPax:
      state.RestaurantStore.restaurantByCityDateAndPax,
    loadingExcursion: state.ExcursionStore.loading,
    loadingDemoPrice: state.TourTransactionStore.loadingPostDemoPrice,
    loadingJoinTourOnBeHalf: state.TourTransactionStore.loadingJoinTourOnBeHalf,
    loadingPostDemoJoinTour: state.TourTransactionStore.loadingPostDemoJoinTour,
    loadingCreateTour: state.TourTransactionStore.loadingCreateTour,
    currencies: state.currenciesStore.currencies.map(item => {
      return { key: item.Id, text: item.Id, value: item.Id };
    }),
    getTourTransactionBills: state.TourTransactionStore.getTourTransactionBills,
    placeByType: state.AirportStore.placeByType,
    getCompanyProfile: state.CompanyStore.getCompanyProfile,
    isSales: state.UserAccessStore.isSales,
    isUserAdmin: state.UserAccessStore.isAdministrator,
    isOperation: state.UserAccessStore.isOperation,
    loadingGetResourceBookingByTransaction:
      state.ResourceBooking.loadingGetResourceBookingByTransaction,
    postDemoPriceStatus: state.TourTransactionStore.postDemoPriceStatus,
  };
};
export default connect(mapStateToProps, {
  getReadyPackageByTypeAndIdAction,
  getSupplementItemByFixedPackageIdAction,
  getCityInCountry,
  getTransactionByIdAction,
  getActivityListAction,
  resetActivityListAction,
  resetStatusTransactionAction,
  getAirportAction,
  getTourOperatorProfileAction,
  getCurrenciesAction,
  getTourTypeAction,
  resetCityAction,
  getTourOperatorByIdAction,
  getInvoiceByCompanyCodeAction,
  getPrintPaymentReceiptTransactionAction,
  getTourTransactionsCommissionPaymentAction,
  getInvoiceHistoryByTransactionIdAction,
  getListByCodeConfirmRoomAllotmentAction,
  getPlaceByPlaceTypeIdAction,
  getAdditionalItemTypeAction,
})(CreateTransaction);
