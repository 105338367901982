const GOLD_COLOR = '#e6ca6b';
const RED_COLOR = '#f44336';
const BLUE_COLOR = '#6b82e6';
const WHITE_COLOR = '#FFF';
const style = {
  bgGold: { background: GOLD_COLOR },
  buttonNegative: {
    background: RED_COLOR,
    color: WHITE_COLOR,
  },
  buttonPositive: {
    background: BLUE_COLOR,
    color: WHITE_COLOR,
  },
  buttonBasicWidth: {
    fontWeight: 'bold',
    borderWidth: '1px',
    borderStyle: 'solid',
  },
  paddingRow: {
    paddingTop: '7px',
    paddingBottom: '7px',
  },
  bgInfo: { color: '#9f3a38' },
  alignCenter: { textAlign: 'center' },
  alignRight: { textAlign: 'right' },
  modalWidth: { width: '43em' },
  zeroPaddingBottom: { paddingBottom: '0px' },
  marginModalCancellationReason: {
    checkBoxMargin: { marginTop: '1em' },
    cancelButton: { color: '#ba000d' },
    goldButton: { color: '#e6ca6b' },
    marginTop0: { marginTop: '0px' },
    marginTop1: { marginTop: '1px' },
    marginBottom1: { marginBottom: '1px' },
    marginTopMin20: { marginTop: '-20px' },
    borderRadius25: { borderRadius: '25px' },
  },
  scrollableMenu: {
    width: '450px',
    height: '240px',
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
  notScrollableMenu: {
    width: '450px',
    height: '140px',
  },
  modalWidthCancel: {
    width: '450px',
  },
};

export default style;
