import React, { Component } from 'react';
import { Grid, Label, Segment, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
// import { Collapse } from 'react-collapse';
// import CollapseButton from '../../../button/collapseButton';
import styles from './styles';
import { convertDateFormat, isSameDate } from '../../../../../scripts/moment';
import ShowDescription from './ShowDescription';
import ButtonAddActivity from './ButtonAddActivity';
import ButtonEditActivity from './buttonEditActivity';
import {
  FREETIMELOCKED,
  FLIGHTTIME,
} from '../../../../shared/components/tourTransaction/dailyProgram/constant/activityType';
import { Collapse } from 'react-collapse';
import CollapseButton from '../../../../../components/button/collapseButton';
import moment from 'moment';
import MessageOverlap from '../../../../shared/components/tourTransaction/dailyProgram/MessageOverlap';
import { SumSecond } from '../../../../../scripts/itineraryBuilder/timeHelper';
import TimeLineDateSeparator from '../../../../shared/components/tourTransaction/dailyProgram/TimeLineDateSeparator';
// import { ARRIVAL } from '../../../tourTransaction/activityType';
// import TimeLineDailyProgram from './timeLineDailyProgram';
class TimelineFixedPackage extends Component {
  state = {
    openCollapseDay: false,
  };
  handleCollapseDay = (e, { name, value }) =>
    this.setState({ ...this.state, [name]: !value });
  renderSeparatorDay = (
    isActivityInCurrentDate,
    isEndTimeInCurrentDate,
    date,
    duration
  ) => {
    let nextDate = SumSecond(date, duration);
    return (
      isActivityInCurrentDate &&
      !isEndTimeInCurrentDate && <TimeLineDateSeparator date={nextDate} />
    );
  };
  render() {
    const { openCollapseDay } = this.state;
    const {
      data,
      handleOpenModalActivities,
      day,
      // dayFirstCheckIn,
      deleteActivity,
      dailyProgram,
      date,
      isPublished,
    } = this.props;
    return (
      <Grid verticalAlign="middle" key={day}>
        <Grid.Row style={styles.marginDailyProgramDays}>
          <Grid.Column width={14}>
            <Header as="h3" style={{ marginTop: '10px' }}>
              Day {data.Day}, {moment(data.Date).format('DD MMMM YYYY')}
            </Header>
          </Grid.Column>
          <Grid.Column width={2} textAlign="left">
            <CollapseButton
              isCollapse={openCollapseDay}
              handleCollapse={this.handleCollapseDay}
              name="openCollapseDay"
            />
          </Grid.Column>
        </Grid.Row>
        <Collapse isOpened={openCollapseDay} style={{ width: '70em' }}>
          <Segment style={{ border: 'none' }}>
            {data.Movements.length > 0 ? (
              data.Movements.map((movement, i) => {
                let isShowDesc =
                  [FREETIMELOCKED, FLIGHTTIME].indexOf(
                    movement.MovementName
                  ) === -1 || movement.Duration !== 0;
                let isActivityInCurrentDate = isSameDate(
                  movement.DateTime,
                  data.Date
                );
                let isEndTimeInCurrentDate = isSameDate(
                  SumSecond(movement.DateTime, movement.Duration),
                  data.Date
                );
                return (
                  <div key={i}>
                    <Grid verticalAlign="middle">
                      <Grid.Row
                        style={
                          isShowDesc
                            ? styles.marginDailyProgramDays
                            : styles.noPaddingMarginLeft3em
                        }
                      >
                        {isEndTimeInCurrentDate && (
                          <Grid.Column width={16}>
                            <ButtonAddActivity
                              movement={movement}
                              index={i}
                              position="top"
                              activity={data}
                              handleOpenModalActivities={
                                handleOpenModalActivities
                              }
                              day={day}
                              // dayFirstCheckIn={dayFirstCheckIn}
                              dailyProgram={dailyProgram}
                              isPublished={isPublished}
                            />
                          </Grid.Column>
                        )}
                        {isShowDesc && (
                          <Grid.Column width={12}>
                            <Label
                              color="blue"
                              circular
                              content={convertDateFormat(
                                movement.DateTime,
                                'HH.mm'
                              )}
                              size="large"
                            />
                            <span style={{ marginLeft: '1em' }}>
                              <label style={{ fontWeight: 'bold' }}>
                                {movement.MovementDescription}
                              </label>
                            </span>
                            <ShowDescription movement={movement} />
                          </Grid.Column>
                        )}
                        {isShowDesc && (
                          <Grid.Column width={4}>
                            <ButtonEditActivity
                              movement={movement}
                              index={i}
                              activity={data}
                              handleOpenModalActivities={
                                handleOpenModalActivities
                              }
                              day={day}
                              deleteActivity={deleteActivity}
                              isPublished={isPublished}
                            />
                          </Grid.Column>
                        )}

                        {isShowDesc && (
                          <MessageOverlap
                            dateDay={date}
                            dateActivity={movement.DateTime}
                            movementName={movement.MovementName}
                          />
                        )}

                        {isEndTimeInCurrentDate && (
                          <Grid.Column width={16}>
                            <ButtonAddActivity
                              movement={movement}
                              index={i}
                              position="bottom"
                              activity={data}
                              handleOpenModalActivities={
                                handleOpenModalActivities
                              }
                              day={day}
                              // dayFirstCheckIn={dayFirstCheckIn}
                              dailyProgram={dailyProgram}
                              isPublished={isPublished}
                            />
                          </Grid.Column>
                        )}
                      </Grid.Row>
                    </Grid>
                    {this.renderSeparatorDay(
                      isActivityInCurrentDate,
                      isEndTimeInCurrentDate,
                      movement.DateTime,
                      movement.Duration
                    )}
                  </div>
                );
              })
            ) : (
              <ButtonAddActivity
                movement={null}
                index={0}
                position="top"
                activity={data}
                handleOpenModalActivities={handleOpenModalActivities}
                day={day}
                // dayFirstCheckIn={dayFirstCheckIn}
                dailyProgram={dailyProgram}
                isPublished={isPublished}
              />
            )}
          </Segment>
        </Collapse>
      </Grid>
    );
  }
}

TimelineFixedPackage.propTypes = {
  data: PropTypes.object,
  handleOpenModalActivities: PropTypes.func,
  day: PropTypes.number,
  // dayFirstCheckIn: PropTypes.number,
  deleteActivity: PropTypes.func,
  dailyProgram: PropTypes.array,
  date: PropTypes.string,
  isPublished: PropTypes.bool,
};
export default TimelineFixedPackage;
