const GENERAL_INFORMATION = [
  'Id',
  'TourCategoryId',
  'TourPaxTypeId',
  'GroupType',
  'RegionDestinationId',
  'CityDestinationId',
  'Description',
  'ExpiredDate',
  'GracePeriod',
  'ImageName',
  'ImageContent',
  'IsFeatured',
  'ReferenceId',
  'MinPax',
];

const ALLOWED_PAX = ['Id', 'MinimumGuest', 'MaximumGuest'];

export { GENERAL_INFORMATION, ALLOWED_PAX };
