import * as types from './constant/actionTypes';

const initialState = {
  loading: false,
  getSystemMarginList: [],
  postSystemMarginStatus: '',
  putSystemMarginStatus: '',
  getSystemMarginListStatus: '',
  getSystemMarginById: [],
  getSystemMarginByIdStatus: '',
  deleteSystemMarginStatus: '',
  errors: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SYSTEM_MARGIN_LIST:
      return { ...state };
    case types.GET_SYSTEM_MARGIN_LIST_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        getSystemMarginListStatus: 'failed',
      };
    case types.GET_SYSTEM_MARGIN_LIST_PENDING:
      return { ...state, loading: true };
    case types.GET_SYSTEM_MARGIN_LIST_FULFILLED:
      return {
        ...state,
        getSystemMarginList: action.payload.data,
        loading: false,
        getSystemMarginListStatus: 'success',
      };
    case types.RESET_STATUS_SYSTEM_MARGIN:
      return {
        ...state,
        getSystemMarginListStatus: '',
        postSystemMarginStatus: '',
        putSystemMarginStatus: '',
        getSystemMarginByIdStatus: '',
        deleteSystemMarginStatus: '',
      };
    case types.POST_SYSTEM_MARGIN:
      return { ...state };
    case types.POST_SYSTEM_MARGIN_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        postSystemMarginStatus: 'failed',
        loading: false,
      };
    case types.POST_SYSTEM_MARGIN_PENDING:
      return { ...state, loading: true };
    case types.POST_SYSTEM_MARGIN_FULFILLED:
      return {
        ...state,
        loading: false,
        postSystemMarginStatus: 'success',
      };
    case types.PUT_SYSTEM_MARGIN:
      return { ...state };
    case types.PUT_SYSTEM_MARGIN_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        putSystemMarginStatus: 'failed',
        loading: false,
      };
    case types.PUT_SYSTEM_MARGIN_PENDING:
      return { ...state, loading: true };
    case types.PUT_SYSTEM_MARGIN_FULFILLED:
      return {
        ...state,
        loading: false,
        putSystemMarginStatus: 'success',
      };
    case types.GET_SYSTEM_MARGIN_BY_ID:
      return { ...state };
    case types.GET_SYSTEM_MARGIN_BY_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getSystemMarginByIdStatus: 'failed',
        loading: false,
      };
    case types.GET_SYSTEM_MARGIN_BY_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_SYSTEM_MARGIN_BY_ID_FULFILLED:
      return {
        ...state,
        getSystemMarginById: action.payload.data,
        loading: false,
        getSystemMarginByIdStatus: 'success',
      };
    case types.DELETE_SYSTEM_MARGIN:
      return { ...state };
    case types.DELETE_SYSTEM_MARGIN_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        deleteSystemMarginStatus: 'failed',
        loading: false,
      };
    case types.DELETE_SYSTEM_MARGIN_PENDING:
      return { ...state, loading: true };
    case types.DELETE_SYSTEM_MARGIN_FULFILLED:
      return {
        ...state,
        loading: false,
        deleteSystemMarginStatus: 'success',
      };
    default:
      return state;
  }
};

export default reducer;
