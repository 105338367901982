import * as types from './constant/actionTypes';

import {
  POST_UPLOAD_PREVIEW_TRANSPORTATION_PROFILE,
  POST_UPLOAD_PREVIEW_TRANSPORTATION_PROFILE_FULFILLED,
  POST_UPLOAD_PREVIEW_TRANSPORTATION_PROFILE_PENDING,
  POST_UPLOAD_PREVIEW_TRANSPORTATION_PROFILE_REJECTED,
  POST_UPLOAD_COMMIT_TRANSPORTATION_PROFILE,
  POST_UPLOAD_COMMIT_TRANSPORTATION_PROFILE_REJECTED,
  POST_UPLOAD_COMMIT_TRANSPORTATION_PROFILE_PENDING,
  POST_UPLOAD_COMMIT_TRANSPORTATION_PROFILE_FULFILLED,
  POST_UPLOAD_PREVIEW_TRANSPORTATION_UNIT,
  POST_UPLOAD_PREVIEW_TRANSPORTATION_UNIT_FULFILLED,
  POST_UPLOAD_PREVIEW_TRANSPORTATION_UNIT_PENDING,
  POST_UPLOAD_PREVIEW_TRANSPORTATION_UNIT_REJECTED,
  POST_UPLOAD_COMMIT_TRANSPORTATION_UNIT,
  POST_UPLOAD_COMMIT_TRANSPORTATION_UNIT_REJECTED,
  POST_UPLOAD_COMMIT_TRANSPORTATION_UNIT_PENDING,
  POST_UPLOAD_COMMIT_TRANSPORTATION_UNIT_FULFILLED,
} from '../shared/constant/actionTypes';

const initialState = {
  loading: false,
  loadingUploadMasterDataCommit: false,
  listUploadMasterDataPreviewAccomodationProfile: [],
  listUploadMasterDataPreviewAccomodationProfileStatus: '',
  listUploadMasterDataCommitAccomodationProfile: [],
  listUploadMasterDataCommitAccomodationProfileStatus: '',

  listUploadMasterDataCommitExcursionObject: [],
  listUploadMasterDataPreviewExcursionObject: [],
  listUploadMasterDataPreviewExcursionObjectStatus: '',
  listUploadMasterDataCommitExcursionObjectStatus: '',

  listUploadMasterDataCommitTransportationProfile: [],
  listUploadMasterDataPreviewTransportationProfile: [],
  listUploadMasterDataPreviewTransportationProfileStatus: '',
  listUploadMasterDataCommitTransportationProfileStatus: '',

  listUploadMasterDataCommitRestaurantProfile: [],
  listUploadMasterDataPreviewRestaurantProfile: [],
  listUploadMasterDataPreviewRestaurantProfileStatus: '',
  listUploadMasterDataCommitRestaurantProfileStatus: '',

  listUploadMasterDataPreviewAccomodationItem: [],
  listUploadMasterDataPreviewAccomodationItemStatus: '',
  listUploadMasterDataCommitAccomodationItem: [],
  listUploadMasterDataCommitAccomodationItemStatus: '',

  listUploadMasterDataCommitRestaurantItem: [],
  listUploadMasterDataPreviewRestaurantItem: [],
  listUploadMasterDataPreviewRestaurantItemStatus: '',
  listUploadMasterDataCommitRestaurantItemStatus: '',

  listUploadMasterDataCommitTransportationUnit: [],
  listUploadMasterDataPreviewTransportationUnit: [],
  listUploadMasterDataPreviewTransportationUnitStatus: '',
  listUploadMasterDataCommitTransportationUnitStatus: '',

  listUploadMasterDataCommitExcursionPrice: [],
  listUploadMasterDataPreviewExcursionPrice: [],
  listUploadMasterDataPreviewExcursionPriceStatus: '',
  listUploadMasterDataCommitExcursionPriceStatus: '',

  listUploadMasterDataCommitRestaurantPrice: [],
  listUploadMasterDataPreviewRestaurantPrice: [],
  listUploadMasterDataPreviewRestaurantPriceStatus: '',
  listUploadMasterDataCommitRestaurantPriceStatus: '',
  errors: {},
  postUploadCommit: [],
  postUploadCommitStatus: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.POST_UPLOAD_PREVIEW_ACCOMODATION_PROFILE:
      return { ...state };
    case types.POST_UPLOAD_PREVIEW_ACCOMODATION_PROFILE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        listUploadMasterDataPreviewAccomodationProfileStatus: 'failed',
      };
    case types.POST_UPLOAD_PREVIEW_ACCOMODATION_PROFILE_PENDING:
      return { ...state, loading: true };
    case types.POST_UPLOAD_PREVIEW_ACCOMODATION_PROFILE_FULFILLED:
      return {
        ...state,
        listUploadMasterDataPreviewAccomodationProfile: action.payload.data,
        loading: false,
        listUploadMasterDataPreviewAccomodationProfileStatus: 'success',
      };
    // case types.POST_UPLOAD_COMMIT_ACCOMODATION_PROFILE:
    //   return { ...state };
    // case types.POST_UPLOAD_COMMIT_ACCOMODATION_PROFILE_REJECTED:
    //   return {
    //     ...state,
    //     errors: { global: action.payload.response.data.Message },
    //     loading: false,
    //     listUploadMasterDataCommitAccomodationProfileStatus: 'failed',
    //   };
    // case types.POST_UPLOAD_COMMIT_ACCOMODATION_PROFILE_PENDING:
    //   return { ...state, loading: true };
    // case types.POST_UPLOAD_COMMIT_ACCOMODATION_PROFILE_FULFILLED:
    //   return {
    //     ...state,
    //     listUploadMasterDataCommitAccomodationProfile: action.payload.data,
    //     loading: false,
    //     listUploadMasterDataCommitAccomodationProfileStatus: 'success',
    //   };
    case types.POST_UPLOAD_PREVIEW_EXCURSION_OBJECT:
      return { ...state };
    case types.POST_UPLOAD_PREVIEW_EXCURSION_OBJECT_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        listUploadMasterDataPreviewExcursionObjectStatus: 'failed',
      };
    case types.POST_UPLOAD_PREVIEW_EXCURSION_OBJECT_PENDING:
      return { ...state, loading: true };
    case types.POST_UPLOAD_PREVIEW_EXCURSION_OBJECT_FULFILLED:
      return {
        ...state,
        listUploadMasterDataPreviewExcursionObject: action.payload.data,
        loading: false,
        listUploadMasterDataPreviewExcursionObjectStatus: 'success',
      };
    case types.POST_UPLOAD_COMMIT_EXCURSION_OBJECT:
      return { ...state };
    case types.POST_UPLOAD_COMMIT_EXCURSION_OBJECT_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        listUploadMasterDataCommitExcursionObjectStatus: 'failed',
      };
    case types.POST_UPLOAD_COMMIT_EXCURSION_OBJECT_PENDING:
      return { ...state, loading: true };
    case types.POST_UPLOAD_COMMIT_EXCURSION_OBJECT_FULFILLED:
      return {
        ...state,
        listUploadMasterDataCommitExcursionObject: action.payload.data,
        loading: false,
        listUploadMasterDataCommitExcursionObjectStatus: 'success',
      };
    case POST_UPLOAD_PREVIEW_TRANSPORTATION_PROFILE:
      return { ...state };
    case POST_UPLOAD_PREVIEW_TRANSPORTATION_PROFILE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        listUploadMasterDataPreviewTransportationProfileStatus: 'failed',
      };
    case POST_UPLOAD_PREVIEW_TRANSPORTATION_PROFILE_PENDING:
      return { ...state, loading: true };
    case POST_UPLOAD_PREVIEW_TRANSPORTATION_PROFILE_FULFILLED:
      return {
        ...state,
        listUploadMasterDataPreviewTransportationProfile: action.payload.data,
        loading: false,
        listUploadMasterDataPreviewTransportationProfileStatus: 'success',
      };
    case POST_UPLOAD_COMMIT_TRANSPORTATION_PROFILE:
      return { ...state };
    case POST_UPLOAD_COMMIT_TRANSPORTATION_PROFILE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        listUploadMasterDataCommitTransportationProfileStatus: 'failed',
      };
    case POST_UPLOAD_COMMIT_TRANSPORTATION_PROFILE_PENDING:
      return { ...state, loading: true };
    case POST_UPLOAD_COMMIT_TRANSPORTATION_PROFILE_FULFILLED:
      return {
        ...state,
        listUploadMasterDataCommitTransportationProfile: action.payload.data,
        loading: false,
        listUploadMasterDataCommitTransportationProfileStatus: 'success',
      };
    case types.POST_UPLOAD_PREVIEW_RESTAURANT_PROFILE:
      return { ...state };
    case types.POST_UPLOAD_PREVIEW_RESTAURANT_PROFILE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        listUploadMasterDataPreviewRestaurantProfileStatus: 'failed',
      };
    case types.POST_UPLOAD_PREVIEW_RESTAURANT_PROFILE_PENDING:
      return { ...state, loading: true };
    case types.POST_UPLOAD_PREVIEW_RESTAURANT_PROFILE_FULFILLED:
      return {
        ...state,
        listUploadMasterDataPreviewRestaurantProfile: action.payload.data,
        loading: false,
        listUploadMasterDataPreviewRestaurantProfileStatus: 'success',
      };
    case types.POST_UPLOAD_COMMIT_RESTAURANT_PROFILE:
      return { ...state };
    case types.POST_UPLOAD_COMMIT_RESTAURANT_PROFILE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        listUploadMasterDataCommitRestaurantProfileStatus: 'failed',
      };
    case types.POST_UPLOAD_COMMIT_RESTAURANT_PROFILE_PENDING:
      return { ...state, loading: true };
    case types.POST_UPLOAD_COMMIT_RESTAURANT_PROFILE_FULFILLED:
      return {
        ...state,
        listUploadMasterDataCommitRestaurantProfile: action.payload.data,
        loading: false,
        listUploadMasterDataCommitRestaurantProfileStatus: 'success',
      };

    //---------------------------------------------------------------
    case types.POST_UPLOAD_PREVIEW_ACCOMODATION_ITEM:
      return { ...state };
    case types.POST_UPLOAD_PREVIEW_ACCOMODATION_ITEM_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        listUploadMasterDataPreviewAccomodationItemStatus: 'failed',
      };
    case types.POST_UPLOAD_PREVIEW_ACCOMODATION_ITEM_PENDING:
      return { ...state, loading: true };
    case types.POST_UPLOAD_PREVIEW_ACCOMODATION_ITEM_FULFILLED:
      return {
        ...state,
        listUploadMasterDataPreviewAccomodationItem: action.payload.data,
        loading: false,
        listUploadMasterDataPreviewAccomodationItemStatus: 'success',
      };
    case types.POST_UPLOAD_COMMIT_ACCOMODATION_ITEM:
      return { ...state };
    case types.POST_UPLOAD_COMMIT_ACCOMODATION_ITEM_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        listUploadMasterDataCommitAccomodationItemStatus: 'failed',
      };
    case types.POST_UPLOAD_COMMIT_ACCOMODATION_ITEM_PENDING:
      return { ...state, loading: true };
    case types.POST_UPLOAD_COMMIT_ACCOMODATION_ITEM_FULFILLED:
      return {
        ...state,
        listUploadMasterDataCommitAccomodationItem: action.payload.data,
        loading: false,
        listUploadMasterDataCommitAccomodationItemStatus: 'success',
      };

    case types.POST_UPLOAD_PREVIEW_RESTAURANT_ITEM:
      return { ...state };
    case types.POST_UPLOAD_PREVIEW_RESTAURANT_ITEM_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        listUploadMasterDataPreviewRestaurantItemStatus: 'failed',
      };
    case types.POST_UPLOAD_PREVIEW_RESTAURANT_ITEM_PENDING:
      return { ...state, loading: true };
    case types.POST_UPLOAD_PREVIEW_RESTAURANT_ITEM_FULFILLED:
      return {
        ...state,
        listUploadMasterDataPreviewRestaurantItem: action.payload.data,
        loading: false,
        listUploadMasterDataPreviewRestaurantItemStatus: 'success',
      };
    case types.POST_UPLOAD_COMMIT_RESTAURANT_ITEM:
      return { ...state };
    case types.POST_UPLOAD_COMMIT_RESTAURANT_ITEM_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        listUploadMasterDataCommitRestaurantItemStatus: 'failed',
      };
    case types.POST_UPLOAD_COMMIT_RESTAURANT_ITEM_PENDING:
      return { ...state, loading: true };
    case types.POST_UPLOAD_COMMIT_RESTAURANT_ITEM_FULFILLED:
      return {
        ...state,
        listUploadMasterDataCommitRestaurantItem: action.payload.data,
        loading: false,
        listUploadMasterDataCommitRestaurantItemStatus: 'success',
      };

    case POST_UPLOAD_PREVIEW_TRANSPORTATION_UNIT:
      return { ...state };
    case POST_UPLOAD_PREVIEW_TRANSPORTATION_UNIT_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        listUploadMasterDataPreviewTransportationUnitStatus: 'failed',
      };
    case POST_UPLOAD_PREVIEW_TRANSPORTATION_UNIT_PENDING:
      return { ...state, loading: true };
    case POST_UPLOAD_PREVIEW_TRANSPORTATION_UNIT_FULFILLED:
      return {
        ...state,
        listUploadMasterDataPreviewTransportationUnit: action.payload.data,
        loading: false,
        listUploadMasterDataPreviewTransportationUnitStatus: 'success',
      };
    case POST_UPLOAD_COMMIT_TRANSPORTATION_UNIT:
      return { ...state };
    case POST_UPLOAD_COMMIT_TRANSPORTATION_UNIT_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        listUploadMasterDataCommitTransportationUnitStatus: 'failed',
      };
    case POST_UPLOAD_COMMIT_TRANSPORTATION_UNIT_PENDING:
      return { ...state, loading: true };
    case POST_UPLOAD_COMMIT_TRANSPORTATION_UNIT_FULFILLED:
      return {
        ...state,
        listUploadMasterDataCommitTransportationUnit: action.payload.data,
        loading: false,
        listUploadMasterDataCommitTransportationUnitStatus: 'success',
      };
    //---------------------------------------------------------------

    case types.POST_UPLOAD_PREVIEW_EXCURSION_PRICE:
      return { ...state };
    case types.POST_UPLOAD_PREVIEW_EXCURSION_PRICE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        listUploadMasterDataPreviewExcursionPriceStatus: 'failed',
      };
    case types.POST_UPLOAD_PREVIEW_EXCURSION_PRICE_PENDING:
      return { ...state, loading: true };
    case types.POST_UPLOAD_PREVIEW_EXCURSION_PRICE_FULFILLED:
      return {
        ...state,
        listUploadMasterDataPreviewExcursionPrice: action.payload.data,
        loading: false,
        listUploadMasterDataPreviewExcursionPriceStatus: 'success',
      };
    case types.POST_UPLOAD_COMMIT_EXCURSION_PRICE:
      return { ...state };
    case types.POST_UPLOAD_COMMIT_EXCURSION_PRICE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        listUploadMasterDataCommitExcursionPriceStatus: 'failed',
      };
    case types.POST_UPLOAD_COMMIT_EXCURSION_PRICE_PENDING:
      return { ...state, loading: true };
    case types.POST_UPLOAD_COMMIT_EXCURSION_PRICE_FULFILLED:
      return {
        ...state,
        listUploadMasterDataCommitExcursionPrice: action.payload.data,
        loading: false,
        listUploadMasterDataCommitExcursionPriceStatus: 'success',
      };

    case types.POST_UPLOAD_PREVIEW_RESTAURANT_PRICE:
      return { ...state };
    case types.POST_UPLOAD_PREVIEW_RESTAURANT_PRICE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        listUploadMasterDataPreviewRestaurantPriceStatus: 'failed',
      };
    case types.POST_UPLOAD_PREVIEW_RESTAURANT_PRICE_PENDING:
      return { ...state, loading: true };
    case types.POST_UPLOAD_PREVIEW_RESTAURANT_PRICE_FULFILLED:
      return {
        ...state,
        listUploadMasterDataPreviewRestaurantPrice: action.payload.data,
        loading: false,
        listUploadMasterDataPreviewRestaurantPriceStatus: 'success',
      };
    case types.POST_UPLOAD_COMMIT_RESTAURANT_PRICE:
      return { ...state };
    case types.POST_UPLOAD_COMMIT_RESTAURANT_PRICE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loading: false,
        listUploadMasterDataCommitRestaurantPriceStatus: 'failed',
      };
    case types.POST_UPLOAD_COMMIT_RESTAURANT_PRICE_PENDING:
      return { ...state, loading: true };
    case types.POST_UPLOAD_COMMIT_RESTAURANT_PRICE_FULFILLED:
      return {
        ...state,
        listUploadMasterDataCommitRestaurantPrice: action.payload.data,
        loading: false,
        listUploadMasterDataCommitRestaurantPriceStatus: 'success',
      };

    case types.POST_UPLOAD_COMMIT:
      return { ...state };
    case types.POST_UPLOAD_COMMIT_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        loadingUploadMasterDataCommit: false,
        postUploadCommitStatus: 'failed',
      };
    case types.POST_UPLOAD_COMMIT_PENDING:
      return { ...state, loadingUploadMasterDataCommit: true };
    case types.POST_UPLOAD_COMMIT_FULFILLED:
      return {
        ...state,
        postUploadCommit: action.payload.data,
        loadingUploadMasterDataCommit: false,
        postUploadCommitStatus: 'success',
      };

    case types.RESET_UPLOAD_DATA_MASTER:
      return {
        ...state,
        listUploadMasterDataPreviewAccomodationProfileStatus: '',
        listUploadMasterDataCommitAccomodationProfileStatus: '',
        listUploadMasterDataPreviewExcursionObjectStatus: '',
        listUploadMasterDataCommitExcursionObjectStatus: '',
        listUploadMasterDataPreviewTransportationProfileStatus: '',
        listUploadMasterDataCommitTransportationProfileStatus: '',
        listUploadMasterDataPreviewRestaurantProfileStatus: '',
        listUploadMasterDataCommitRestaurantProfileStatus: '',
        listUploadMasterDataPreviewTransportationUnitStatus: '',
        listUploadMasterDataCommitTransportationUnitStatus: '',
        listUploadMasterDataPreviewRestaurantItemStatus: '',
        listUploadMasterDataCommitRestaurantItemStatus: '',
        listUploadMasterDataPreviewAccomodationItemStatus: '',
        listUploadMasterDataCommitAccomodationItemStatus: '',
        listUploadMasterDataPreviewExcursionPriceStatus: '',
        listUploadMasterDataCommitExcursionPriceStatus: '',
        listUploadMasterDataPreviewRestaurantPriceStatus: '',
        listUploadMasterDataCommitRestaurantPriceStatus: '',
        postUploadCommitStatus: '',
      };
    default:
      return state;
  }
};

export default reducer;
