import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DailyProgram from './DailyProgramFixedPackage';
import { Header, Icon, Grid, Segment } from 'semantic-ui-react';
import CollapseButton from '../../../../../components/button/collapseButton';
import { Collapse } from 'react-collapse';
import {
  // getFunctionDP,
  generateDailyProgramAfterCRUD,
  checkFirstaccom,
  deleteActivity,
  changeDepartureReturn,
} from '../../../../../scripts/itineraryBuilderCreateFixedPackage/dailyProgramFixedPackage';
import styles from './styles';
import {
  isAccommodationEmpty,
  isAccommodationTimeNeedChange,
  changeTimeSummaryProgramAfterAddActivity,
} from '../../../../../scripts/itineraryBuilder/dailyProgram';
import {
  DEPARTURE,
  ARRIVAL,
} from '../../../../shared/components/tourTransaction/dailyProgram/constant/activityType';

class tabDailyProgramCreatePackage extends Component {
  state = {
    openCollapse: false,
    transportStore: [],
  };

  handleCollapse = (e, { name, value }) =>
    this.setState({ ...this.state, [name]: !value });

  setDailyProgramAfterAdd = async (
    dailyProgram,
    dayIdx,
    moveIdx,
    move,
    movementList,
    action,
    itemMovement
  ) => {
    dailyProgram = await generateDailyProgramAfterCRUD(
      dailyProgram,
      dayIdx,
      moveIdx,
      move,
      movementList,
      action
    );
    this.props.setDailyProgram(dailyProgram);
    this.changeAccommodation(dailyProgram);
    if ([ARRIVAL, DEPARTURE].indexOf(move.Name) !== -1) {
      this.changeDepartureReturn(dailyProgram, itemMovement, move.Name);
    }
  };
  changeDepartureReturn = (dailyProgram, itemMovement, movementName) => {
    let { headlineProgram } = this.props;
    let newMainProg = changeDepartureReturn(
      dailyProgram,
      headlineProgram,
      itemMovement,
      movementName
    );
    this.props.setheadlineProgram(newMainProg);
  };

  changeAccommodation = dailyProgram => {
    let { headlineProgram } = this.props;
    if (
      isAccommodationTimeNeedChange(dailyProgram, headlineProgram.MainPrograms)
    ) {
      let newMainProg = changeTimeSummaryProgramAfterAddActivity(
        dailyProgram,
        headlineProgram.MainPrograms
      );
      let headline = { ...headlineProgram };
      headline.MainPrograms = newMainProg;
      this.props.setheadlineProgram(headline);
    }
  };

  deleteActivity = async (moveIdx, dayIdx) => {
    let { dailyProgram } = this.props;
    let newDP = deleteActivity(dayIdx, moveIdx, dailyProgram);
    await this.props.setDailyProgram(newDP);
    this.changeAccommodation(newDP);
    // if (
    //   [ARRIVAL, DEPARTURE].indexOf(
    //     dailyProgram[dayIdx].Movements[moveIdx].MovementName
    //   ) !== -1
    // ) {
    //   this.changeDepartureReturn(newDP);
    // }
  };
  render() {
    const { openCollapse } = this.state;
    const {
      dailyProgram,
      headlineProgram,
      isPublished,
      isOperation,
    } = this.props;
    const dayFirstCheckIn = checkFirstaccom(dailyProgram);
    return (
      <Segment raised fluid>
        <div style={styles.marginContentInsideCard}>
          <Grid verticalAlign="middle">
            <Grid.Row style={styles.noPaddingBottom}>
              <Grid.Column width={14}>
                <Header as="h3" style={styles.marginHeader}>
                  <Icon name="clipboard list" style={{ color: '#38af95' }} />
                  Itinerary
                </Header>
              </Grid.Column>
              <Grid.Column width={2} textAlign="left">
                <CollapseButton
                  isCollapse={openCollapse}
                  handleCollapse={this.handleCollapse}
                  name="openCollapse"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Collapse isOpened={openCollapse} style={{ margin: '0px' }}>
            {isAccommodationEmpty(headlineProgram.MainPrograms) === false ? (
              <DailyProgram
                dailyProgram={dailyProgram}
                setDailyProgramAfterAdd={this.setDailyProgramAfterAdd}
                dayFirstCheckIn={dayFirstCheckIn}
                deleteActivity={this.deleteActivity}
                isPublished={isPublished || isOperation}
              />
            ) : (
              <h4 style={{ textAlign: 'center' }}>
                Please complete general information and accommodation
              </h4>
            )}
            {/* {isAccommodationEmpty(props.headlineProgram.MainPrograms) ===
            false ? ( */}
            {/* <DailyProgram
            //   dailyProgram={props.dailyProgram}
            //   setDailyProgramAfterAdd={this.setDailyProgramAfterAdd}
            //   typePackage={props.typePackage}
            //   deleteActivity={this.deleteAct}
            //   deleteTransportItem={this.deleteTransportItem}
            //   setTransportItem={this.setTransportItem}
            //   transportStore={transportStore}
            //   guests={props.guests}
            /> */}
            {/* ) : (
              <h4 style={{ textAlign: 'center' }}>
                Please complete general information and destinations summary
              </h4>
            )} */}
          </Collapse>
        </div>
      </Segment>
    );
  }
}
tabDailyProgramCreatePackage.propTypes = {
  dailyProgram: PropTypes.array,
  setDailyProgram: PropTypes.func,
  headlineProgram: PropTypes.object,
  setheadlineProgram: PropTypes.func,
  isPublished: PropTypes.bool,
  isOperation: PropTypes.bool,
};

export default tabDailyProgramCreatePackage;
