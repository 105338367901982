import * as types from './constants/actionTypes';

const initialState = {
  getDataTransferLogs: [],
  getDataTransferLogsStatus: '',
  deleteDataTransferLogsStatus: '',
  postDataTransferLogsStatus: '',
  putDataTransferLogsStatus: '',
  getDataTransferLogsById: [],
  getDataTransferLogsByIdStatus: '',
  getDataTransferLogsByRange: [],
  getDataTransferLogsByRangeStatus: '',
  getDataTransferLogsByReferenceId: [],
  getDataTransferLogsByReferenceIdStatus: '',
  errors: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_DATA_TRANSFER_LOGS:
      return { ...state };
    case types.GET_DATA_TRANSFER_LOGS_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getDataTransferLogsStatus: 'failed',
      };
    case types.GET_DATA_TRANSFER_LOGS_PENDING:
      return { ...state, loading: true };
    case types.GET_DATA_TRANSFER_LOGS_FULFILLED:
      return {
        ...state,
        getDataTransferLogs: action.payload.data,
        loading: false,
        getDataTransferLogsStatus: 'success',
      };
    case types.GET_DATA_TRANSFER_LOGS_BY_ID:
      return { ...state };
    case types.GET_DATA_TRANSFER_LOGS_BY_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getDataTransferLogsByIdStatus: 'failed',
        loading: false,
      };
    case types.GET_DATA_TRANSFER_LOGS_BY_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_DATA_TRANSFER_LOGS_BY_ID_FULFILLED:
      return {
        ...state,
        getDataTransferLogsById: action.payload.data,
        loading: false,
        getDataTransferLogsByIdStatus: 'success',
      };
    case types.DELETE_DATA_TRANSFER_LOGS:
      return { ...state };
    case types.DELETE_DATA_TRANSFER_LOGS_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        deleteDataTransferLogsStatus: 'failed',
      };
    case types.DELETE_DATA_TRANSFER_LOGS_PENDING:
      return { ...state, loading: true };
    case types.DELETE_DATA_TRANSFER_LOGS_FULFILLED:
      return {
        ...state,
        loading: false,
        deleteDataTransferLogsStatus: 'success',
      };
    case types.POST_DATA_TRANSFER_LOGS:
      return { ...state };
    case types.POST_DATA_TRANSFER_LOGS_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        postDataTransferLogsStatus: 'failed',
      };
    case types.POST_DATA_TRANSFER_LOGS_PENDING:
      return { ...state, loading: true };
    case types.POST_DATA_TRANSFER_LOGS_FULFILLED:
      return {
        ...state,
        loading: false,
        postDataTransferLogsStatus: 'success',
      };
    case types.PUT_DATA_TRANSFER_LOGS:
      return { ...state };
    case types.PUT_DATA_TRANSFER_LOGS_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        putDataTransferLogsStatus: 'failed',
      };
    case types.PUT_DATA_TRANSFER_LOGS_PENDING:
      return { ...state, loading: true };
    case types.PUT_DATA_TRANSFER_LOGS_FULFILLED:
      return {
        ...state,
        loading: false,
        putDataTransferLogsStatus: 'success',
      };
    case types.GET_DATA_TRANSFER_LOGS_BY_RANGE:
      return { ...state };
    case types.GET_DATA_TRANSFER_LOGS_BY_RANGE_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getDataTransferLogsByRangeStatus: 'failed',
        loading: false,
      };
    case types.GET_DATA_TRANSFER_LOGS_BY_RANGE_PENDING:
      return { ...state, loading: true };
    case types.GET_DATA_TRANSFER_LOGS_BY_RANGE_FULFILLED:
      return {
        ...state,
        getDataTransferLogsByRange: action.payload.data,
        loading: false,
        getDataTransferLogsByRangeStatus: 'success',
      };
    case types.GET_DATA_TRANSFER_LOGS_BY_REFERENCE_ID:
      return { ...state };
    case types.GET_DATA_TRANSFER_LOGS_BY_REFERENCE_ID_REJECTED:
      return {
        ...state,
        errors: { global: action.payload.response.data.Message },
        getDataTransferLogsByReferenceIdStatus: 'failed',
        loading: false,
      };
    case types.GET_DATA_TRANSFER_LOGS_BY_REFERENCE_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_DATA_TRANSFER_LOGS_BY_REFERENCE_ID_FULFILLED:
      return {
        ...state,
        getDataTransferLogsByReferenceId: action.payload.data,
        loading: false,
        getDataTransferLogsByReferenceIdStatus: 'success',
      };
    case types.RESET_DATA_TRANSFER_LOGS:
      return {
        ...state,
        getDataTransferLogsStatus: '',
        deleteDataTransferLogsStatus: '',
        postDataTransferLogsStatus: '',
        putDataTransferLogsStatus: '',
        getDataTransferLogsByIdStatus: '',
        getDataTransferLogsByRangeStatus: '',
        getDataTransferLogsByReferenceIdStatus: '',
      };
    case types.RESET_DATA_TRANSFER_LOGS_BY_ID:
      return {
        ...state,
        getDataTransferLogsById: [],
      };
    default:
      return state;
  }
};

export default reducer;
