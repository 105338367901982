import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Grid, TextArea, Form } from 'semantic-ui-react';
import styles from './styles';

const ModalRejectRoom = props => {
  const {
    openModal,
    size,
    // confirm,
    close,
    nameConfirm,
    contentOk,
    contentNo,
    header,
    requestItemId,
    roomName,
    handleReject,
  } = props;

  const messageReject = `Request Item ID: ${requestItemId} for ${roomName} will be rejected. 
Please inform us why this request is rejected`;

  const [confirmationNote, setConfirmationNote] = useState('');
  const [error, setError] = useState(false);

  const handleClose = () => {
    setError(false);
    setConfirmationNote('');
    close();
  };

  const handleChange = (e, { value }) => {
    if (value !== '') {
      setError(false);
    } else if (value === '') {
      setError(true);
    }
    setConfirmationNote(value);
  };

  const handleRejectButton = () => {
    if (confirmationNote === '') {
      setError(true);
    } else {
      let data = {
        RequestItemId: requestItemId,
        ActionType: 1,
        ConfirmationNumber: '',
        ConfirmationNote: confirmationNote,
      };
      handleClose();
      handleReject(data);
    }
  };

  return (
    <Modal
      size={size ? size : 'tiny'}
      open={openModal}
      onClose={close}
      closeOnDimmerClick={true}
      dimmer="blurring"
    >
      <Modal.Header>{header ? header : 'Reject Room Request'}</Modal.Header>
      <Modal.Content>
        <Grid>
          <Grid.Row style={styles.paddingRow}>
            <Grid.Column>
              <h5>{messageReject}</h5>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={styles.paddingRow}>
            <Grid.Column>
              <Form>
                <Form.Field error={error}>
                  <label>Reason</label>
                  <TextArea
                    placeholder="The reason why room not available"
                    fluid
                    value={confirmationNote}
                    onChange={handleChange}
                  />
                  {error && (
                    <span style={styles.bgInfo}>Field can not be empty</span>
                  )}
                </Form.Field>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions style={styles.alignCenter}>
        <Button
          basic
          color="blue"
          name={nameConfirm}
          content={contentOk ? contentOk : 'Close'}
          onClick={handleClose}
          style={styles.buttonBasicWidth}
        />
        <Button
          color="red"
          content={contentNo ? contentNo : 'Reject'}
          onClick={handleRejectButton}
          // onClick={close}
        />
      </Modal.Actions>
    </Modal>
  );
};

ModalRejectRoom.propTypes = {
  openModal: PropTypes.bool,
  size: PropTypes.string,
  close: PropTypes.func,
  confirm: PropTypes.func,
  message: PropTypes.string,
  nameConfirm: PropTypes.string,
  contentOk: PropTypes.string,
  contentNo: PropTypes.string,
  header: PropTypes.string,
  requestItemId: PropTypes.string,
  roomName: PropTypes.string,
  handleReject: PropTypes.func,
};
export default ModalRejectRoom;
