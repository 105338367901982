import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Container } from 'semantic-ui-react';
import moment from 'moment';

const SegmentConfirm = props => {
  const { data } = props;
  return (
    <React.Fragment>
      <Grid.Row>
        <Grid.Column width={4}>
          <Header
            as="h3"
            //            icon="check circle outline"
            content={`Request ID: ${data.RequestItemId}`}
            color="black"
          />
          {/* <Container
            style={{ fontSize: '16px', fontWeight: 'bold' }}
            content={`Request Item ID: ${data.RequestItemId}`}
          /> */}
          <Container
            content={`Request On: ${moment(data.OrderDate).format(
              'D MMM YYYY'
            )} `}
          />
        </Grid.Column>
        <Grid.Column>
          <Header
            as="h3"
            icon="check circle outline"
            content="Confirmed"
            color="green"
          />
          <Container
            content={`Confirm on: ${
              data.ConfirmDate
                ? moment(data.ConfirmDate).format('D MMM YYYY')
                : '-'
            }`}
          />
        </Grid.Column>

        <Grid.Column width={6}>
          <Container
            style={{ marginBottom: '20px' }}
            content={`Confirmation No: ${data.ConfirmationNumber} `}
          />
          <Container content={`Touress Booking No: ${data.BookingNo} `} />
        </Grid.Column>
      </Grid.Row>
    </React.Fragment>
  );
};

SegmentConfirm.propTypes = {
  data: PropTypes.object,
  status: PropTypes.string,
};

export default React.memo(SegmentConfirm);
