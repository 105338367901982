import { convertDateFormat } from '../../../../../scripts/moment'; //src\components\genericFunction\moment.js
const setDataResourceBooking = data => {
  let optionsData = data.map(data => {
    return {
      Id: data.Id ? data.Id : null,
      ResourceBookingCode: data.ResourceBookingCode
        ? data.ResourceBookingCode
        : null,
      Name: data.Name ? data.Name : null,
      PackageType: data.PackageType ? data.PackageType : null,
      StartTour: data.StartTour
        ? convertDateFormat(data.StartTour, 'DD MMMM YYYY')
        : null,
    };
  });
  return optionsData;
};

export default setDataResourceBooking;
