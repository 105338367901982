import React from 'react';
import { Form, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import InputAmount from '../../../../../../components/input/inputAmount';
import styles from '../../Styles';
const FormRoomAllocationCustom = props => {
  let {
    roomAllocation,
    handleIncrease,
    handleDecrease,
    handleChange,
    prices,
  } = props;
  return (
    <Form>
      <Header as="h4">Adults</Header>
      <Form.Group widths="equal">
        <Form.Field style={styles.paddingRight20}>
          <InputAmount
            label="Sharing Room"
            labelStyle={styles.textAlignCenter}
            inputStyle={styles.borderBottom}
            name="SharingRoomQty"
            objectname="roomAllocation"
            value={roomAllocation.SharingRoomQty}
            handleIncrease={handleIncrease}
            handleDecrease={handleDecrease}
            handleOnChange={handleChange}
            disabled={prices && prices.SharingRoomPrice !== 0 ? false : true}
          />
        </Form.Field>
        <Form.Field>
          <InputAmount
            label="Single Room"
            labelStyle={styles.textAlignCenter}
            inputStyle={styles.borderBottom}
            name="SingleRoomQty"
            objectname="roomAllocation"
            value={roomAllocation.SingleRoomQty}
            handleIncrease={handleIncrease}
            handleDecrease={handleDecrease}
            handleOnChange={handleChange}
            disabled={prices && prices.SingleRoomPrice !== 0 ? false : true}
          />
        </Form.Field>
        <Form.Field style={styles.paddingLeft20}>
          <InputAmount
            label="Extra bed"
            labelStyle={styles.textAlignCenter}
            inputStyle={styles.borderBottom}
            name="ExtraBedQty"
            objectname="roomAllocation"
            value={roomAllocation.ExtraBedQty}
            handleIncrease={handleIncrease}
            handleDecrease={handleDecrease}
            handleOnChange={handleChange}
            disabled={prices && prices.AdultExtraBedPrice !== 0 ? false : true}
          />
        </Form.Field>
      </Form.Group>
      <Header as="h4">Children</Header>
      <Form.Group widths="equal">
        <Form.Field style={styles.paddingRight20}>
          <InputAmount
            label="Sharing Room"
            labelStyle={styles.textAlignCenter}
            inputStyle={styles.borderBottom}
            name="ChildSharingRoomQty"
            objectname="roomAllocation"
            value={roomAllocation.ChildSharingRoomQty}
            handleIncrease={handleIncrease}
            handleDecrease={handleDecrease}
            handleOnChange={handleChange}
            disabled={prices && prices.SharingRoomPrice !== 0 ? false : true}
          />
        </Form.Field>
        <Form.Field>
          <InputAmount
            label="Single Room"
            labelStyle={styles.textAlignCenter}
            inputStyle={styles.borderBottom}
            name="ChildSingleRoomQty"
            objectname="roomAllocation"
            value={roomAllocation.ChildSingleRoomQty}
            handleIncrease={handleIncrease}
            handleDecrease={handleDecrease}
            handleOnChange={handleChange}
            disabled={prices && prices.SingleRoomPrice !== 0 ? false : true}
          />
        </Form.Field>
        <Form.Field style={styles.paddingLeft20}>
          <InputAmount
            label="Extra bed"
            labelStyle={styles.textAlignCenter}
            inputStyle={styles.borderBottom}
            name="ChildExtraBedQty"
            objectname="roomAllocation"
            value={roomAllocation.ChildExtraBedQty}
            handleIncrease={handleIncrease}
            handleDecrease={handleDecrease}
            handleOnChange={handleChange}
            disabled={prices && prices.ChildExtraBedPrice !== 0 ? false : true}
          />
        </Form.Field>
      </Form.Group>
      <Header as="h4">Others</Header>
      <Form.Group widths="equal">
        <Form.Field style={styles.paddingRight20}>
          <InputAmount
            label="Baby Crib(infants)"
            labelStyle={styles.textAlignCenter}
            inputStyle={styles.borderBottom}
            name="BabyCrib"
            objectname="roomAllocation"
            value={roomAllocation.BabyCrib}
            handleIncrease={handleIncrease}
            handleDecrease={handleDecrease}
            handleOnChange={handleChange}
            disabled={prices && prices.NoBedPrice !== 0 ? false : true}
          />
        </Form.Field>
        <Form.Field>
          <InputAmount
            label="No bed(infants)"
            labelStyle={styles.textAlignCenter}
            inputStyle={styles.borderBottom}
            name="NoBed"
            objectname="roomAllocation"
            value={roomAllocation.NoBed}
            handleIncrease={handleIncrease}
            handleDecrease={handleDecrease}
            handleOnChange={handleChange}
            disabled={prices && prices.NoBedPrice !== 0 ? false : true}
          />
        </Form.Field>
        <Form.Field style={styles.paddingLeft20}>
          <InputAmount
            label="Sharing with parent"
            labelStyle={styles.textAlignCenter}
            inputStyle={styles.borderBottom}
            name="SharingBedQty"
            objectname="roomAllocation"
            value={roomAllocation.SharingBedQty}
            handleIncrease={handleIncrease}
            handleDecrease={handleDecrease}
            handleOnChange={handleChange}
            disabled={prices && prices.SharingBedPrice !== 0 ? false : true}
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
};
FormRoomAllocationCustom.propTypes = {
  roomAllocation: PropTypes.object,
  handleIncrease: PropTypes.func,
  handleDecrease: PropTypes.func,
  handleChange: PropTypes.func,
  prices: PropTypes.object,
};
export default FormRoomAllocationCustom;
