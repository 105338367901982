import {
  GET_AREA_BY_CITY,
  RESET_AREA,
  GET_AREA_LIST,
} from '../constants/actionTypes';
import getAreaByCityApi from '../../../api/area/getAreaByCityApi';
import getAreaListApi from '../../../api/area/getAreaListApi';

const getAreaListAction = Id => {
  return dispatch => {
    return dispatch({
      type: GET_AREA_BY_CITY,
      payload: getAreaByCityApi(Id),
    });
  };
};

const resetAreaAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_AREA,
    });
  };
};

const getAreaAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_AREA_LIST,
      payload: getAreaListApi(),
    });
  };
};

export { getAreaListAction, resetAreaAction, getAreaAction };
