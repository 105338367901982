import React, { Component, Fragment } from 'react';
import { Table, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Pagination from '../../../../../components/common/pagination';
import IndexPagination from '../../../../../components/genericFunction/indexPagination';
import { convertDateFormat } from '../../../../../components/genericFunction/moment';
import { datasetPagination } from '../../../../../scripts/pagination';

class TablePeriodVariableDate extends Component {
  static propTypes = {
    data: PropTypes.array,
    headerTable: PropTypes.array,
    handleDelete: PropTypes.func,
    activePage: PropTypes.number,
    pageChange: PropTypes.func,
    handleEdit: PropTypes.func,
    isPublished: PropTypes.bool,
    disabled: PropTypes.bool,
  };
  render() {
    let {
      data,
      headerTable,
      handleDelete,
      activePage,
      pageChange,
      handleEdit,
      isPublished,
      disabled,
    } = this.props;
    let pagedData = datasetPagination(data, 3);
    let listData = pagedData[activePage - 1];
    let numbersFrom =
      listData && listData.length
        ? listData.length === 3
          ? activePage * listData.length
          : (activePage - 1) * 3 + listData.length
        : 0;
    let numbersTo = numbersFrom > 0 ? numbersFrom - (listData.length - 1) : 0;
    return (
      <Fragment>
        <Table basic="very" selectable>
          <Table.Header>
            <Table.Row>
              {headerTable &&
                headerTable.map((headTableContent, index) => {
                  return (
                    <Table.HeaderCell key={index} textAlign="center">
                      {headTableContent}
                    </Table.HeaderCell>
                  );
                })}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {listData &&
              listData.map((item, idx) => {
                const idxPagination = IndexPagination(3, activePage, idx);
                return (
                  <Table.Row key={idxPagination}>
                    <Table.Cell textAlign="center">
                      {convertDateFormat(item.StartDate, 'DD MMMM YYYY')}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {convertDateFormat(item.EndDate, 'DD MMMM YYYY')}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {item.AllowedDays.length !== 7
                        ? item.AllowedDays.map((data, i) =>
                            i + 1 < item.AllowedDays.length
                              ? `${data.Text}, `
                              : `${data.Text}`
                          )
                        : 'All Days'}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Button
                        positive
                        content="Edit"
                        onClick={handleEdit}
                        value={idxPagination}
                        disabled={isPublished || disabled}
                      />
                      <Button
                        negative
                        content="Delete"
                        onClick={handleDelete}
                        value={idxPagination}
                        disabled={isPublished || disabled}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        <Pagination
          pagedData={pagedData}
          numbersTo={numbersTo}
          numbersFrom={numbersFrom}
          data={data}
          pageChange={pageChange}
          activePage={activePage}
        />
      </Fragment>
    );
  }
}

export default TablePeriodVariableDate;
