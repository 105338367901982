import React from 'react';
import { Table, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ButtonActions from '../../../components/common/buttonEditAndDelete';
import IndexPagination from '../../../components/genericFunction/indexPagination';
const TableExchangeRate = props => (
  <div>
    <Table basic="very" selectable fixed>
      <Table.Header>
        <Table.Row>
          {props.headerTable.map((headTableContent, index) => {
            return (
              <Table.HeaderCell key={index}>
                {headTableContent}
              </Table.HeaderCell>
            );
          })}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {props.listData &&
          props.listData.map((exchangeRate, idx) => {
            const idxPagination = IndexPagination(7, props.activePage, idx);
            return (
              <Table.Row key={idxPagination}>
                <Table.Cell component="th" scope="row" width={1}>
                  {moment(exchangeRate.EffectiveDate).format('DD MMMM YYYY')}
                </Table.Cell>
                <Table.Cell>
                  {exchangeRate.CurrencyFromId}
                  <Icon fitted name="arrow right" />
                  {exchangeRate.CurrencyToId}
                </Table.Cell>
                <Table.Cell>
                  {exchangeRate.CurrencyToId +
                    '           ' +
                    new Intl.NumberFormat('de-DE').format(exchangeRate.Value)}
                </Table.Cell>
                <Table.Cell>
                  <ButtonActions
                    id={exchangeRate.Id}
                    handleClickEdit={props.editExchangeRate}
                    handleClickDelete={props.deleteExchangeRate}
                    name1={idxPagination}
                    name2={idxPagination}
                    content1="Edit"
                    content2="Delete"
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
      </Table.Body>
    </Table>
  </div>
);

TableExchangeRate.propTypes = {
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  editExchangeRate: PropTypes.func,
  deleteExchangeRate: PropTypes.func,
  activePage: PropTypes.number,
};
export default TableExchangeRate;
