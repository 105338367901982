import React from 'react';
import {
  Modal,
  Form,
  Button,
  //   Message,
  //   Segment,
  //   Grid,
  //   Icon,
  //   Header,
  //   Label,
  //   Button,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
// import Input from '../../../../../components/input/WithLabel';
import Input from '../../common/input/InputWithLabel';
// import InputAmount from '../../../../../components/input/Amount';
import InputAmount from '../../../../../components/input/inputAmount';
import styles from './styles';
class ModalTourGuide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      language: { key: '', value: '', text: '' },
      guideAmount: 1,
      errors: {
        language: { error: false, message: '' },
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open && this.props.open === true) {
      this.handleResetState();
    }
  }
  handleResetState = () => {
    this.setState({
      ...this.state,
      language: {
        key: '',
        value: '',
        text: '',
      },
      guideAmount: 1,
    });
  };

  handleChange = (e, { name, value }) => {
    let val = value;

    if (name === 'language') {
      // eslint-disable-next-line
      val = this.props.listLanguage.find(item => item.value == value);
    }
    // Promise.resolve(this.setState({ ...this.state, [name]: val })).then(() => {
    //   if (name === 'language') {
    //     let errors = this.handleValidate();
    //     this.setState({ ...this.state, errors: errors });
    //   }
    // });
    this.setState({ ...this.state, [name]: val }, () => {
      if (name === 'language') {
        let errors = this.handleValidate();
        this.setState({ ...this.state, errors: errors });
      }
    });
  };

  handleIncrease = (e, { name, value }) =>
    this.setState({ ...this.state, [name]: ++value });

  handleDecrease = (e, { name, value }) => {
    let val = value;
    --val;
    value = val > 0 ? val : value;
    this.setState({ ...this.state, [name]: value });
  };

  handleChangeAmount = (e, { name, value }) =>
    this.setState({ ...this.state, [name]: parseInt(value, 10) });

  handleGetList = () => {
    let { listLanguage, selectedGuide } = this.props;
    if (
      listLanguage.length > 0 &&
      selectedGuide &&
      selectedGuide.tourGuide.length > 0
    ) {
      return listLanguage.filter(this.comparer(selectedGuide.tourGuide));
    } else return listLanguage;
  };

  comparer = otherArray => {
    return current => {
      return (
        otherArray.filter(other => {
          // eslint-disable-next-line
          return other.language.value == current.value;
          // eslint-disable-next-line
        }).length == 0
      );
    };
  };

  handleValidate = () => {
    let { errors, language } = this.state;
    if (!language.value) {
      errors.language.error = true;
      errors.language.message = 'Please Select language';
    } else {
      errors.language.error = false;
      errors.language.message = '';
    }
    return errors;
  };

  handleAddTourGuide = () => {
    const state = this.state;
    let errors = this.handleValidate();
    this.setState({ ...this.state, errors: errors });
    if (!errors.language.error) {
      this.props.addTourGuide(state);
    }
  };
  render() {
    // const { open, onClose } = this.props;
    const { language, guideAmount, errors } = this.state;
    const { open, onClose, data, selectedGuide } = this.props;
    let newListLanguage = this.handleGetList();
    // console.log(language);
    return (
      <Modal open={open} onClose={onClose} size="mini">
        <Modal.Header>
          Add Tour Guide Day in Day {data ? data.Day : null}
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <Input
                type="text"
                label="City"
                inline={false}
                value={selectedGuide ? selectedGuide.CityName : ''}
                disabled
              />
            </Form.Field>
            <Form.Field>
              <Input
                type="select"
                label="Language"
                inline={false}
                options={newListLanguage}
                handleChange={this.handleChange}
                name="language"
                value={language.value}
                placeholder="Select Language"
                validate={errors}
              />
            </Form.Field>
            <Form.Field>
              <InputAmount
                label="Guide Amount"
                value={guideAmount}
                name="guideAmount"
                handleIncrease={this.handleIncrease}
                handleDecrease={this.handleDecrease}
                handleOnChange={this.handleChangeAmount}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            name="buttonAddGuide"
            style={styles.backGroundGold}
            onClick={this.handleAddTourGuide}
            // value={this.state}
          >
            Add
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
ModalTourGuide.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.object,
  listLanguage: PropTypes.array,
  selectedGuide: PropTypes.object,
  addTourGuide: PropTypes.func,
};
export default ModalTourGuide;
