const validateEmptyAirport = headline => {
  const { Departures, MainPrograms, Returns } = headline;
  let isDepAirportEmpty = findEmptyPlace(Departures);
  let isMainAirportEmpty = findEmptyPlace(MainPrograms);
  let isArrAirportEmpty = findEmptyPlace(Returns);
  return isDepAirportEmpty || isMainAirportEmpty || isArrAirportEmpty;
};

const findEmptyPlace = param => {
  return param.find(item => item.TransferType && item.PlaceId === null);
};

export { validateEmptyAirport };
