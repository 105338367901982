import React from 'react';
import { Input, Grid, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import TableCityGroupList from './table/TableCityGroup';
import { HeaderTableCityGroup } from '../../../components/table/headerTabel';
import ButtonWithIcon from '../../../components/button/buttonWithIcon';
import HeaderTitle from '../../../components/common/headerTitle';
import Pagination from '../../../components/common/pagination';
import styles from '../Styles';
const CityGroupList = props => (
  <div>
    <Grid columns="equal" style={styles.gridStyle}>
      <Grid.Row stretched>
        <Grid.Column width={15}>
          <Segment>
            <HeaderTitle title={props.title} />
            <Input
              icon="search"
              placeholder="search"
              name="searchInput"
              value={props.searchInput}
              onChange={props.handleChange}
            />
            <ButtonWithIcon
              text="Add New"
              iconName="add"
              handleOnClick={props.buttonNew}
              positive={true}
              floated="right"
            />
            <TableCityGroupList
              data={props.data}
              headerTable={HeaderTableCityGroup}
              pageChange={props.pageChange}
              listData={props.listData}
              pagedData={props.pagedData}
              butttonEdit={props.butttonEdit}
              buttonDelete={props.buttonDelete}
            />
            <Pagination
              pagedData={props.pagedData}
              numbersTo={props.numbersTo}
              numbersFrom={props.numbersFrom}
              data={props.data}
              pageChange={props.pageChange}
              activePage={props.activePage}
            />
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
);

CityGroupList.propTypes = {
  listData: PropTypes.array,
  pagedData: PropTypes.array,
  title: PropTypes.string,
  pageChange: PropTypes.func,
  activePage: PropTypes.number,
  searchInput: PropTypes.string,
  handleChange: PropTypes.func,
  data: PropTypes.array,
  buttonNew: PropTypes.func,
  butttonEdit: PropTypes.func,
  buttonDelete: PropTypes.func,
  numbersTo: PropTypes.number,
  numbersFrom: PropTypes.number,
};
export default CityGroupList;
