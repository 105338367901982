import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Label, Icon } from 'semantic-ui-react';
// import { Redirect } from 'react-router-dom';
import SegmentRequestDetail from './components/SegmentRequestDetail';
import { withRouter } from 'react-router-dom';
import { getRequestRoomHistoryDetailApi } from '../../api/getRequestRoomHistoryDetailApi';
import useApiCallForDetail from './hooks/useApiCallForDetail';

const RequestRoomHistoryDetail = props => {
  //=======================initial=======================
  const [
    historyDetailLoading,
    historyDetailData,
    loadData,
  ] = useApiCallForDetail(
    getRequestRoomHistoryDetailApi,
    props.match.params.id
  );

  return (
    <div>
      <Segment style={{ marginTop: '30px' }}>
        <Label
          as="h3"
          size="huge"
          style={{
            position: 'absolute',
            top: '-25px',
            background: '#38af95',
            color: 'white',
          }}
        >
          <Icon name="clipboard list" /> Request History Detail
        </Label>
        <SegmentRequestDetail
          data={historyDetailData}
          loading={historyDetailLoading}
          loadData={loadData}
        />
      </Segment>
    </div>
  );
};

RequestRoomHistoryDetail.propTypes = {
  data: PropTypes.array,
  match: PropTypes.shape({
    params: PropTypes.string,
  }),
};

export default withRouter(React.memo(RequestRoomHistoryDetail));
