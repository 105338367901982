import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form, Input, Grid } from 'semantic-ui-react';
import ButtonClose from '../../../../shared/components/common/button/ButtonCloseModal';
import CurrencyFormat from 'react-currency-format';
import InputWithLabel from '../../../../shared/components/common/input/InputWithLabel';
const ModalRestaurantPriceDetail = props => (
  <Modal open={props.openModalPrice} size="tiny">
    <Modal.Header>
      Price for {' ' + props.headerItem}
      <ButtonClose onClose={props.handleClose} />
    </Modal.Header>
    <Modal.Content>
      <Grid columns={2} padded="vertically">
        <Grid.Column>
          <Form>
            <InputWithLabel
              inline={false}
              type="select"
              label="Currency"
              placeholder="Currency"
              name="Currency"
              required
              value={props.data.Currency}
              options={props.currenciesList}
              handleChange={props.handleChangePrice}
              validate={props.errors}
            />
          </Form>
          <Form>
            <InputWithLabel
              inline={false}
              type="number"
              label="Minimum Pax"
              required
              value={props.data.MinPax}
              name="MinPax"
              placeholder="Minimum Pax"
              handleChange={props.handleChangePrice}
              validate={props.errors}
            />
          </Form>
        </Grid.Column>
        <Grid.Column>
          <Form>
            <Form.Field>
              <label>Price</label>
              <CurrencyFormat
                // thousandSeparator={true}
                thousandSeparator={'.'}
                decimalSeparator={','}
                decimalScale={2}
                value={props.data.Cost}
                textAlign="right"
                name={'Cost'}
                id={'Cost'}
                customInput={Input}
                onValueChange={e =>
                  props.handleChangePriceDate(e.value, 'Cost')
                }
              />
              <label style={{ color: 'red' }}>
                {props.errors.Cost ? props.errors.Cost.message : ''}
              </label>
            </Form.Field>
          </Form>
        </Grid.Column>
      </Grid>
    </Modal.Content>
    <Modal.Actions>
      <Button content="Save" positive onClick={props.savePriceDetail} />
    </Modal.Actions>
  </Modal>
);

ModalRestaurantPriceDetail.propTypes = {
  openModalPrice: PropTypes.func,
  headerItem: PropTypes.string,
  handleClose: PropTypes.func,
  content: PropTypes.string,
  data: PropTypes.object,
  servicePriceType: PropTypes.array,
  handleChangePrice: PropTypes.object,
  handleChangePriceDate: PropTypes.object,
  errors: PropTypes.object,
  savePriceDetail: PropTypes.func,
  currenciesList: PropTypes.array,
  headerTransportationUnit: PropTypes.string,
  transportationOriginRegion: PropTypes.string,
  type: PropTypes.bool,
  errorsDate: PropTypes.object,
};

export default ModalRestaurantPriceDetail;
