import React from 'react';
import { Grid, Divider, Segment, Header, Label, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import GeneralInformation from './GeneralInformationBalanceDetail';
import HistoryTransaction from '../../../../components/table/customerProfile/tableBalanceDetailHistory';
import ButtonGroup from '../../../../components/common/buttonGroupThriple';

const BalanceDetailForm = props => (
  <Segment style={{ marginTop: '30px' }}>
    <Label
      as="h3"
      size="huge"
      style={{
        position: 'absolute',
        top: '-25px',
        background: '#38af95',
        color: 'white',
      }}
    >
      <Icon name="money" /> Balance
    </Label>
    <Grid style={{ marginTop: '30px' }}>
      <ButtonGroup
        id1="Back"
        id2="Credit"
        id3="Debit"
        handleClick1={props.buttonBack}
        handleClick2={props.openModalBalanceDetail}
        handleClick3={props.openModalBalanceDetail}
        content1="Back"
        content2="Credit"
        content3="Debit"
      />
      <Grid.Row>
        <Grid.Column width={8}>
          <GeneralInformation contentDetail={props.contentDetail} />
        </Grid.Column>
        <Grid.Column width={8}>
          <Header as="h2" style={{ marginTop: '60px' }}>
            Balance : ({props.contentDetail.Currency}) {'          '}
            {new Intl.NumberFormat('de-DE').format(props.contentDetail.Balance)}
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Divider />
      <Grid.Row>
        <Grid.Column width={16}>
          <HistoryTransaction listData={props.historyTransaction} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
);

BalanceDetailForm.propTypes = {
  historyTransaction: PropTypes.array,
  contentDetail: PropTypes.shape({
    Name: PropTypes.string,
    CompanyCode: PropTypes.number,
    Balance: PropTypes.number,
    Currency: PropTypes.string,
  }),
  buttonBack: PropTypes.func,
  openModalBalanceDetail: PropTypes.func,
};

export default BalanceDetailForm;
