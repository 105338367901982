import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Header, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import ModalAlert from '../../../../../components/modal/modalAlert';
import ModalConfirm from '../../../../../components/modal/modalConfirmationTwoButton';
import {
  postPublishPackageAction,
  copyPackageAction,
  deletePackageAction,
  postPackageAction,
  putPackageAction,
  resetPackageAction,
  uploadBrochurePackageAction,
  putGeneralInfoPackageAction,
  deleteBookingTemplateImagesAction,
  putAdditionalInfoOperation,
} from '../../../../../actions/packageAction';
import {
  generatePackageManagement,
  filterDataPostSeries,
} from '../../../../../scripts/itineraryBuilder/demoPrice';
import {
  validateDailyProgramExcursionTime,
  transportValidation,
  validateDailyProgramOverloadTime,
} from '../../../../../scripts/itineraryBuilder/dailyProgram';
import ModalCopyPackage from './../../../../../components/modal/ModalCopyPackage';
import {
  inputValidation,
  readyFixedPriceValidation,
} from '../../services/validation/validation';
import getPackageTransactionHistory from '../../../../../actions/packageTransactionHistoryAction';
import {
  postAdditionalInfoAction,
  deleteAdditionalInfoAction,
  putAdditionalInfoAction,
} from '../../../../shared/actions/additionalInformationAction';
import SetDataAdditionalInformation from '../../services/data/setDataAdditionalInformationForAction';
import setDataImage from '../../services/data/setDataImage';
import { GENERAL_INFORMATION } from '../../constant/objEditPackage';
import { Collapse } from 'react-collapse';
import { formatCurrency } from '../../../../../components/genericFunction/convertFormat';
import { isCheckInDifferentDate } from '../../../../../scripts/itineraryBuilder/dailyProgramValidation';

const styleButtonRed = {
  background: '#ff3d3d',
  color: 'white',
};
const styleButtonBlue = {
  background: '#2962ff',
  color: 'white',
};
const styleButtonYellow = {
  background: '#ff9800',
  color: 'white',
};
class AllButtonActionReadyPackage extends Component {
  state = {
    goTo: false,
    linkGoTo: '',
    openModalAlert: false,
    openModalAlertValidation: false,
    messageModalAlert: [],
    openConfirmation: false,
    messageConfirmation: '',
    isFailed: false,
    openModalPayment: false,
    modalCopyPackage: {
      openModal: false,
      selectedId: null,
      typePackage: null,
      startDate: '',
    },
    savingStatus: false,
    idAfterSaving: null,
    // isNotValidPrice: false,
  };
  handleClickAction = async () => {
    const { currentPackage, isPublished } = this.props;
    const { typeButton } = this.state;
    let validation = {
      isError: false,
      messages: [],
    };
    this.setState({ openConfirmation: false });
    switch (typeButton) {
      case 'back':
        this.setState({
          ...this.state,
          goTo: true,
          linkGoTo: '/PackageList/Ready',
        });
        break;
      case 'save':
        if (validateDailyProgramExcursionTime(this.props.dailyProgram)) {
          validation.isError = true;
          validation.messages.push(
            'Please check your Itinerary, something wrong with excursion time'
          );
        }
        if (transportValidation(this.props.dailyProgram)) {
          validation.isError = true;
          validation.messages.push(
            'Please check your Itinerary, something wrong with transportation'
          );
        }
        if (validation.isError) {
          this.setState({
            ...this.state,
            openModalAlertValidation: validation.isError,
            messageModalAlert: validation.messages,
            openConfirmation: false,
          });
        } else {
          this.handleSaveTransaction();
        }
        break;
      case 'publish':
        if (!isPublished) {
          this.handleSaveTransaction();

          // this.props
          //     .postPublishPackageAction(
          //       currentPackage.isReadyFixPrice
          //         ? 'VariableDatePackage'
          //         : 'ReadyPackage',
          //       'Publish',
          //       currentPackage.id
          //     )
          //     .then(() => {
          //       this.setState({
          //         openModalAlert: true,
          //         contentAlert: 'the package was successfully published',
          //         headerAlert: 'Successfully',
          //         // goTo: true,
          //         // linkGoTo: '/PackageList/Ready',
          //         savingStatus: true,
          //         idAfterSaving: currentPackage.id,
          //       });
          //     })
          //     .catch(error => {
          //       this.setState({
          //         openModalAlert: true,
          //         contentAlert: error.response.data.Message,
          //         headerAlert: 'Failed',
          //         isFailed: true,
          //       });
          //     })
        } else {
          this.props
            .postPublishPackageAction(
              currentPackage.isReadyFixPrice
                ? 'VariableDatePackage'
                : 'ReadyPackage',
              'Unpublish',
              currentPackage.id
            )
            .then(() => {
              this.setState({
                openModalAlert: true,
                contentAlert: 'the package was successfully Unpublished',
                headerAlert: 'Successfully',
                // goTo: true,
                // linkGoTo: '/PackageList/Ready',
                savingStatus: true,
                idAfterSaving: currentPackage.id,
              });
            })
            .catch(error => {
              this.setState({
                openModalAlert: true,
                contentAlert: error.response.data.Message,
                headerAlert: 'Failed',
                isFailed: true,
              });
            });
        }

        break;
      case 'copy':
        this.props
          .copyPackageAction(currentPackage.type, currentPackage.id)
          .then(() =>
            this.setState({
              openModalAlert: true,
              contentAlert: 'the package was successfully copied',
              headerAlert: 'Successfully',
              goTo: true,
              linkGoTo: '/PackageList/Ready',
            })
          )
          .catch(() =>
            this.setState({
              openModalAlert: true,
              contentAlert: 'package failed to copy',
              headerAlert: 'Failed',
              isFailed: true,
            })
          );
        break;
      case 'delete':
        this.props
          .deletePackageAction(currentPackage.id)
          .then(() => {
            this.setState({
              openModalAlert: true,
              contentAlert: 'the package was successfully deleted',
              headerAlert: 'Successfully',
              goTo: true,
              linkGoTo: '/PackageList/Ready',
            });
          })
          .catch(() =>
            this.setState({
              openModalAlert: true,
              contentAlert: 'package failed to delete',
              headerAlert: 'Failed',
              isFailed: true,
            })
          );
        break;
      case 'edit':
        break;
      default:
        alert('Are you sure create package?');
    }
  };

  validationPages = (error, check, validation, message) => {
    if (error) {
      switch (check) {
        case 'checkErrorInput':
          validation = this.messageError(validation, message);
          break;

        case 'checkAccommodation':
          validation = this.messageError(
            validation,
            'Please choose Accommodation'
          );
          break;

        // case 'checkDepartureArrival':
        //   validation = this.messageError(validation, message);
        //   break;

        default:
      }
    }
    return validation;
  };
  messageError = (validation, message) => {
    validation.isError = true;
    validation.messages.push(message);
    return validation;
  };

  handleErrorSaving = () => {
    const {
      generalInformation,
      checkGeneralInformation,
      errorGeneralInformation,
      headlineProgram,
      isPublished,
      currentPackage,
      customPrices,
    } = this.props;
    let validation = {
      isError: false,
      messages: [],
    };
    let checkErrorInput = inputValidation(
      generalInformation,
      errorGeneralInformation
    );

    let accommodations = headlineProgram.MainPrograms.filter(
      e => e.AccommodationSummary
    );
    let checkAccommodation =
      accommodations[0].AccommodationSummary.AccommodationProfileId === null;
    validation = this.validationPages(
      checkErrorInput.isError,
      'checkErrorInput',
      validation,
      checkErrorInput.message
    );
    validation = this.validationPages(
      checkAccommodation,
      'checkAccommodation',
      validation
    );

    if (validateDailyProgramExcursionTime(this.props.dailyProgram)) {
      validation.isError = true;
      validation.messages.push(
        'Please check your Itinerary, something wrong with excursion time'
      );
    }
    if (transportValidation(this.props.dailyProgram)) {
      validation.isError = true;
      validation.messages.push(
        'Please check your Itinerary, something wrong with transportation'
      );
    }
    if (validateDailyProgramOverloadTime(this.props.dailyProgram)) {
      validation.isError = true;
      validation.messages.push(
        'Please check your Itinerary, something wrong with activity time'
      );
    }

    // if (currentPackage.isReadyFixPrice && !isPublished) {
    if (currentPackage.isReadyFixPrice && isPublished) {
      validation = readyFixedPriceValidation(
        validation,
        this.props.packagePrice,
        this.props.listPeriod,
        isPublished,
        generalInformation.activeDate
      );
    }
    // validation connection flight
    if (isCheckInDifferentDate(this.props.dailyProgram)) {
      validation.isError = true;
      validation.messages.push(
        'Please check your Itinerary, something wrong with check in time'
      );
    }
    // validation custom price
    if (
      currentPackage.isReadyFixPrice &&
      (!customPrices.Prices || customPrices.Prices.length === 0)
    ) {
      validation.isError = true;
      validation.messages.push(
        'please check the price, the price cannot be empty'
      );
    }
    checkGeneralInformation();
    return validation;
  };
  handleClickConfirmation = async (e, { name }) => {
    const {
      isPublished,
      currentPackage,
      generalInformation,
      // errorGeneralInformation,
      // headlineProgram,
      // checkGeneralInformation,
    } = this.props;
    let validation = {
      isError: false,
      messages: [],
    };
    let message = '';
    // let checkErrorInput = inputValidation(
    //   generalInformation,
    //   errorGeneralInformation
    // );

    // let accommodations = headlineProgram.MainPrograms.filter(
    //   e => e.AccommodationSummary
    // );
    // let checkAccommodation =
    //   accommodations[0].AccommodationSummary.AccommodationProfileId === null;
    switch (name) {
      case 'back':
        message =
          'Are you sure you left this package? the filled in data will be lost';
        this.openModalConfirmation(message, name);
        break;
      case 'save':
        // validation = this.validationPages(
        //   checkErrorInput.isError,
        //   'checkErrorInput',
        //   validation,
        //   checkErrorInput.message
        // );
        // validation = this.validationPages(
        //   checkAccommodation,
        //   'checkAccommodation',
        //   validation
        // );

        // if (validateDailyProgramExcursionTime(this.props.dailyProgram)) {
        //   validation.isError = true;
        //   validation.messages.push(
        //     'Please check your Itinerary, something wrong with excursion time'
        //   );
        // }
        // if (transportValidation(this.props.dailyProgram)) {
        //   validation.isError = true;
        //   validation.messages.push(
        //     'Please check your Itinerary, something wrong with transportation'
        //   );
        // }
        // if (validateDailyProgramOverloadTime(this.props.dailyProgram)) {
        //   validation.isError = true;
        //   validation.messages.push(
        //     'Please check your Itinerary, something wrong with activity time'
        //   );
        // }
        // checkGeneralInformation();
        validation = this.handleErrorSaving();
        if (validation.isError) {
          this.setState({
            ...this.state,
            openModalAlertValidation: validation.isError,
            messageModalAlert: validation.messages,
            openConfirmation: false,
          });
        } else {
          message =
            'Are you sure the data that is filled in is correct? package will be saved';
          this.openModalConfirmation(message, name);
        }

        break;
      case 'publish':
        validation =
          currentPackage.isReadyFixPrice &&
          readyFixedPriceValidation(
            validation,
            this.props.packagePrice,
            this.props.listPeriod,
            isPublished,
            generalInformation.activeDate,
            'publish'
          );
        if (validation.isError) {
          this.setState({
            ...this.state,
            openModalAlertValidation: validation.isError,
            messageModalAlert: validation.messages,
            openConfirmation: false,
            // isNotValidPrice: true,
          });
        } else {
          validation = this.handleErrorSaving();
          if (validation.isError) {
            this.setState({
              ...this.state,
              openModalAlertValidation: validation.isError,
              messageModalAlert: validation.messages,
              openConfirmation: false,
            });
          } else {
            isPublished
              ? (message = 'Are you sure to unpublish this package?')
              : currentPackage.isReadyFixPrice
              ? (message = `Are you sure to publish this package with price ${
                  this.props.packagePrice.CurrencyId
                } ${formatCurrency(
                  this.props.packagePrice.SharingRoomPrice
                )} ?`)
              : (message = 'Are you sure to publish this package?');
            this.openModalConfirmation(message, name);
          }
        }
        break;
      case 'copy':
        {
          let modalCopyPackage = {
            openModal: true,
            selectedId: currentPackage.id,
            typePackage: currentPackage.type,
            startDate: generalInformation.startDate,
          };
          this.setState({ ...this.state, modalCopyPackage });
        }
        break;
      case 'delete':
        message = 'Are you sure to delete this package?';
        this.openModalConfirmation(message, name);
        break;
      case 'edit':
        message =
          'Are you sure the data that is filled in is correct? package will be saved';
        this.openModalConfirmation(message, name);
        break;
      default:
        message =
          'Are you sure the data that is filled in is correct? package will be saved';
        this.openModalConfirmation(message, name);
    }
  };

  openModalConfirmation = (message, name) => {
    this.setState({
      openConfirmation: true,
      typeButton: name === 'bookQuotation' ? 'save' : name,
      messageConfirmation: message,
      isBookingQuotation: name === 'bookQuotation',
    });
  };

  handleClose = () => {
    this.setState({
      openConfirmation: false,
      openModalAlert: false,
    });
  };
  handleCloseAlert = () => {
    const { savingStatus, idAfterSaving } = this.state;
    if (savingStatus) {
      this.setState(
        {
          ...this.state,
          goTo: true,
          // linkGoTo: '/PackageList/Ready',
          linkGoTo: `/Package-Details/${
            this.props.currentPackage.isReadyFixPrice
              ? 'FixedDateVariable'
              : 'Ready'
          }/edit/${idAfterSaving}`,
          openModalAlert: false,
        },
        () => this.setReloadPage(idAfterSaving)
      );
    } else {
      this.setState({
        ...this.state,
        // goTo: this.state.isFailed,
        // linkGoTo: this.state.isFailed ? '/PackageList/Ready' : null,
        openModalAlert: false,
      });
    }
  };

  setReloadPage = async () => {
    window.location.reload();
    // await this.props.setPageReady(
    //   this.props.currentPackage.isReadyFixPrice ? 'FixedDateVariable' : 'Ready',
    //   'edit',
    //   idAfterSaving
    // );
    // this.setState({ goTo: false });
  };

  handleSaveTransaction = () => {
    const {
      currentPackage,
      dailyProgram,
      generalInformation,
      headlineProgram,
      images,
      additionalInfo,
      tourOperator,
      isPublished,
      listPeriod,
      packagePrice,
      additionalServices,
      isOperation,
      customPrices,
    } = this.props;
    let {
      tourName,
      tourType,
      firstDestination,
      description,
      expiredDate,
      isRoomAllotmetNeeded,
      activeDate,
      minPax,
      // availableDay,
    } = generalInformation;
    let daily = generatePackageManagement(
      currentPackage.id,
      tourName,
      firstDestination,
      currentPackage.isReadyFixPrice ? 'FixedDateVariable' : 'READY',
      tourType,
      headlineProgram,
      dailyProgram,
      images,
      description,
      additionalInfo,
      // tourOperator ? tourOperator.Id : 3,
      // ready fix price
      expiredDate,
      listPeriod,
      packagePrice,
      isRoomAllotmetNeeded ? isRoomAllotmetNeeded : false,
      activeDate,
      minPax,
      // availableDay
      //end
      tourOperator ? tourOperator.Id : 3,
      additionalServices,
      customPrices
    );

    if (isOperation) {
      this.updateAdditionalInfoEvoucher(currentPackage, additionalInfo);
    } else {
      currentPackage.id > 0
        ? isPublished
          ? this.putGeneralInfo(currentPackage.id, daily)
          : this.putPackage(currentPackage.id, daily)
        : this.postPackage(
            currentPackage.isReadyFixPrice
              ? 'SaveTemplate'
              : 'SaveReadyPackageTemplate',
            daily
          );
    }
    // currentPackage.id > 0
    //   ? console.log(currentPackage.id, daily)
    //   : console.log('SaveReadyPackageTemplate', daily);
  };

  updateAdditionalInfoEvoucher = (currentPackage, listAdditionalInfo) => {
    let dataPost = listAdditionalInfo
      ? listAdditionalInfo.reduce((acc, e) => {
          if (e.ForVoucher) {
            acc.push({ ...e, SubTitle: 'FOR_VOUCHER ' + e.SubTitle });
          }
          return acc;
        }, [])
      : [];
    this.props
      .putAdditionalInfoOperation({
        packageType: currentPackage.type,
        id: currentPackage.id,
        data: dataPost,
      })
      .then(() => {
        this.setState({
          openModalAlert: true,
          contentAlert: 'the package was successfully update',
          headerAlert: 'Successfully',
        });
      })
      .catch(error =>
        this.setState({
          openModalAlert: true,
          contentAlert: error.response.data.Message,
          headerAlert: 'Failed',
          isFailed: true,
        })
      );
  };
  postPackage = (id, dailyProgram) => {
    this.props
      .postPackageAction(id, dailyProgram)
      .then(res =>
        this.setState({
          openModalAlert: true,
          contentAlert: `Ready package was successfully saved with Id ${res.value.data.BookingDetailSum.Id}`,
          headerAlert: 'Successfully',
          savingStatus: true,
          idAfterSaving: res.value.data.BookingDetailSum.Id,
          // isNotValidPrice: false,
          // goTo: true,
          // linkGoTo: '/PackageList/Ready',
        })
      )
      .catch(error =>
        this.setState({
          openModalAlert: true,
          contentAlert: error.response.data
            ? `${error.response.data.Message} ${
                error.response.data.ExceptionMessage
                  ? error.response.data.ExceptionMessage
                  : ''
              }`
            : error.response.message,
          headerAlert: 'Failed',
          isFailed: true,
        })
      );
  };

  putPackage = (id, dailyProgram) => {
    const { currentPackage } = this.props;
    const { typeButton } = this.state;
    this.props
      .putPackageAction(id, dailyProgram)
      .then(() => {
        typeButton === 'publish' &&
          this.props.postPublishPackageAction(
            currentPackage.isReadyFixPrice
              ? 'VariableDatePackage'
              : 'ReadyPackage',
            'Publish',
            currentPackage.id
          );
        this.setState({
          openModalAlert: true,
          contentAlert: 'the package was successfully saved',
          headerAlert: 'Successfully',
          isReloadPage: true,
          savingStatus: true,
          idAfterSaving: currentPackage.id,
        });
      })
      .catch(error =>
        this.setState({
          openModalAlert: true,
          contentAlert: error.response.data.Message,
          headerAlert: 'Failed',
          isFailed: true,
        })
      );
  };

  handleCloseAlertValidation = () =>
    this.setState({
      ...this.state,
      openModalAlertValidation: !this.state.openModalAlertValidation,
    });
  handleCloseCopyPackage = () => {
    let modalCopyPackage = { ...this.state.modalCopyPackage, openModal: false };
    this.setState({ ...this.state, modalCopyPackage });
  };

  //handle action if ready already published

  additionalInformationAction = additionalInfo => {
    const {
      deletedAdditionalInformation,
      deletedAdditionalInformationFunc,
    } = this.props;
    additionalInfo.map(data =>
      data.Id === 0
        ? this.props.postAdditionalInfoAction(
            SetDataAdditionalInformation(
              data,
              true,
              this.props.currentPackage.id
            )
          )
        : this.props.putAdditionalInfoAction(
            data.Id,
            SetDataAdditionalInformation(
              data,
              false,
              this.props.currentPackage.id
            )
          )
    );
    deletedAdditionalInformation.length > 0 &&
      deletedAdditionalInformation.map(data =>
        this.props.deleteAdditionalInfoAction(data)
      );
    deletedAdditionalInformationFunc('clear');
  };

  handleImageAction = () => {
    const {
      currentPackage,
      images,
      deletedImages,
      handleDeleteImages,
    } = this.props;

    images.map(data => {
      return (
        data.Id === 0 &&
        this.props.uploadBrochurePackageAction(
          currentPackage.id,
          setDataImage(data, true, currentPackage.id)
        )
      );
    });
    deletedImages.length > 0 &&
      deletedImages.map(data =>
        this.props.deleteBookingTemplateImagesAction(data)
      );
    handleDeleteImages('clear');
  };
  putGeneralInfo = (id, dataPost) => {
    let general = filterDataPostSeries(dataPost, GENERAL_INFORMATION);
    // eslint-disable-next-line
    Promise.all([
      this.props.putGeneralInfoPackageAction(id, general),
      this.additionalInformationAction(dataPost.BookingTemplateDescriptions),
      this.handleImageAction(),
    ])
      .then(() => {
        this.setState({
          openModalAlert: true,
          contentAlert: 'the package was successfully update',
          headerAlert: 'Successfully',
          savingStatus: true,
          idAfterSaving: id,
          // isNotValidPrice: false,
        });
      })
      .catch(error =>
        this.setState({
          openModalAlert: true,
          contentAlert: error.response.data.Message,
          headerAlert: 'Failed',
          // isFailed: true,
        })
      );
  };
  render() {
    if (this.state.goTo) {
      return <Redirect to={this.state.linkGoTo} />;
    }
    const {
      openConfirmation,
      messageConfirmation,
      openModalAlert,
      headerAlert,
      contentAlert,
      messageModalAlert,
      modalCopyPackage,
      // isNotValidPrice,
    } = this.state;
    const {
      disabledEdit,
      isPublished,
      currentPackage,
      isOperation,
    } = this.props;
    let isCreate = currentPackage.status === 'create';
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as="h3" style={{ margin: '10px' }}>
              Status:{' '}
              {currentPackage.status === 'create'
                ? '-'
                : isPublished
                ? 'Publish'
                : 'Draft'}
            </Header>
            <Collapse isOpened={currentPackage.isSeriesVariableDate === null}>
              <Message error>
                <Message.Header>Choose Package</Message.Header>
                <p>Please choose your package type</p>
              </Message>
            </Collapse>
          </Grid.Column>
          <Grid.Column width={8} textAlign="right">
            <div
            // style={
            //   isPublished
            //     ? { marginLeft: '50.6em' }
            //     : isCreate
            //       ? { marginLeft: '64em' }
            //       : { marginLeft: '44.4em' }
            // }
            >
              <Button
                content="Back"
                style={styleButtonBlue}
                name="back"
                onClick={this.handleClickConfirmation}
              />
              {!isOperation && !isCreate && (
                <Button
                  content={isPublished ? 'Unpublish' : 'Publish'}
                  style={styleButtonYellow}
                  onClick={this.handleClickConfirmation}
                  name="publish"
                  // disabled={isNotValidPrice}
                />
              )}
              {!isOperation && !isCreate && (
                <Button
                  content="Copy"
                  positive
                  name="copy"
                  onClick={this.handleClickConfirmation}
                />
              )}
              {isOperation && isPublished ? null : (
                <Button
                  content={disabledEdit ? 'Edit' : 'Save'} //Edit Or Save sent variable from parent to child
                  style={styleButtonBlue}
                  onClick={this.handleClickConfirmation}
                  name="save" //Edit Or Save sent variable from parent to child
                />
              )}
              {!isOperation && !isPublished && !isCreate ? (
                <Button
                  content={'Delete'}
                  style={styleButtonRed}
                  onClick={this.handleClickConfirmation}
                  name="delete"
                />
              ) : null}
              <ModalConfirm
                openModal={openConfirmation}
                size="mini"
                close={this.handleClose}
                confirm={this.handleClickAction}
                message={messageConfirmation}
              />
              <ModalAlert
                openModal={openModalAlert}
                handleClose={this.handleCloseAlert}
                header={headerAlert}
                content={contentAlert}
                nameButton="OK"
              />
              <ModalAlert
                openModal={this.state.openModalAlertValidation}
                handleClose={this.handleCloseAlertValidation}
                header="Failed"
                content={messageModalAlert}
                nameButton="OK"
                isArrayContentAlert={true}
              />
              <ModalCopyPackage
                open={modalCopyPackage.openModal}
                id={modalCopyPackage.selectedId}
                type={modalCopyPackage.typePackage}
                startDate={modalCopyPackage.startDate}
                handleClose={this.handleCloseCopyPackage}
                isVariableDate={currentPackage.isReadyFixPrice}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
AllButtonActionReadyPackage.propTypes = {
  currentPackage: PropTypes.object,
  guests: PropTypes.object,
  roomAllocation: PropTypes.object,
  guestList: PropTypes.array,
  commission: PropTypes.object,
  supplements: PropTypes.array,
  postJoinTourOnBehalf: PropTypes.func,
  requestDemoPrice: PropTypes.object,
  postCreateTourTransactionAction: PropTypes.func,
  tourOperator: PropTypes.object,
  onBehalf: PropTypes.object,
  availablePax: PropTypes.number,
  postConfirmTransaction: PropTypes.func,
  postEditQuotationAction: PropTypes.func,
  dailyProgram: PropTypes.object,
  reloadPage: PropTypes.func,
  bookingStatus: PropTypes.string,
  putPackageAction: PropTypes.func,
  postPackageAction: PropTypes.func,
  postPublishPackageAction: PropTypes.func,
  copyPackageAction: PropTypes.func,
  deletePackageAction: PropTypes.func,
  isPublished: PropTypes.bool,
  disabledEdit: PropTypes.bool,
  generalInformation: PropTypes.object,
  headlineProgram: PropTypes.array,
  images: PropTypes.array,
  additionalInfo: PropTypes.array,
  resetPackageAction: PropTypes.func,
  setPageReady: PropTypes.func,
  postAdditionalInfoAction: PropTypes.func,
  deleteAdditionalInfoAction: PropTypes.func,
  putAdditionalInfoAction: PropTypes.func,
  deletedAdditionalInformation: PropTypes.array,
  deletedAdditionalInformationFunc: PropTypes.func,
  deleteBookingTemplateImagesAction: PropTypes.func,
  handleDeleteImages: PropTypes.func,
  deletedImages: PropTypes.array,
  uploadBrochurePackageAction: PropTypes.func,
  putGeneralInfoPackageAction: PropTypes.func,
  listPeriod: PropTypes.array,
  packagePrice: PropTypes.object,
  errorGeneralInformation: PropTypes.object,
  checkGeneralInformation: PropTypes.func,
  // errorGeneralInformation: PropTypes.object,
  // checkGeneralInformation: PropTypes.func,
  additionalServices: PropTypes.array,
  isOperation: PropTypes.bool,
  putAdditionalInfoOperation: PropTypes.func,
  customPrices: PropTypes.object,
};
const mapStateToProps = state => {
  return {
    postPackageStatus: state.PackageStore.postPackageStatus,
    postPackage: state.PackageStore.postPackage,
    putPackageStatus: state.PackageStore.putPackageStatus,
    errorMessage: state.PackageStore.errors,
  };
};

export default connect(mapStateToProps, {
  postPublishPackageAction,
  copyPackageAction,
  deletePackageAction,
  postPackageAction,
  putPackageAction,
  resetPackageAction,
  getPackageTransactionHistory,
  //==========================
  postAdditionalInfoAction,
  deleteAdditionalInfoAction,
  putAdditionalInfoAction,
  uploadBrochurePackageAction,
  putGeneralInfoPackageAction,
  deleteBookingTemplateImagesAction,
  putAdditionalInfoOperation,
})(AllButtonActionReadyPackage);
