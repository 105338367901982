//Company
export const GET_PENDING_COMPANY_FOR_APPROVAL =
  'GET_PENDING_COMPANY_FOR_APPROVAL';
export const RESET_STATUS_COMPANY = 'RESET_STATUS_COMPANY';
export const GET_PENDING_COMPANY_FOR_APPROVAL_FULFILLED =
  'GET_PENDING_COMPANY_FOR_APPROVAL_FULFILLED';
export const GET_PENDING_COMPANY_FOR_APPROVAL_PENDING =
  'GET_PENDING_COMPANY_FOR_APPROVAL_PENDING';
export const GET_PENDING_COMPANY_FOR_APPROVAL_REJECTED =
  'GET_PENDING_COMPANY_FOR_APPROVAL_REJECTED';

//--------------------ACCEPT && REJECT COMPANY REGISTRATION--------------
export const POST_ACCEPT_COMPANY_REGISTRATION =
  'POST_ACCEPT_COMPANY_REGISTRATION';
export const POST_ACCEPT_COMPANY_REGISTRATION_FULFILLED =
  'POST_ACCEPT_COMPANY_REGISTRATION_FULFILLED';
export const POST_ACCEPT_COMPANY_REGISTRATION_PENDING =
  'POST_ACCEPT_COMPANY_REGISTRATION_PENDING';
export const POST_ACCEPT_COMPANY_REGISTRATION_REJECTED =
  'POST_ACCEPT_COMPANY_REGISTRATION_REJECTED';
export const POST_REJECT_COMPANY_REGISTRATION =
  'POST_REJECT_COMPANY_REGISTRATION';
export const POST_REJECT_COMPANY_REGISTRATION_FULFILLED =
  'POST_REJECT_COMPANY_REGISTRATION_FULFILLED';
export const POST_REJECT_COMPANY_REGISTRATION_PENDING =
  'POST_REJECT_COMPANY_REGISTRATION_PENDING';
export const POST_REJECT_COMPANY_REGISTRATION_REJECTED =
  'POST_REJECT_COMPANY_REGISTRATION_REJECTED';

//Package
export const GET_PUBLISHED_PACKAGE = 'GET_PUBLISHED_PACKAGE';
export const GET_PUBLISHED_PACKAGE_FULFILLED =
  'GET_PUBLISHED_PACKAGE_FULFILLED';
export const GET_PUBLISHED_PACKAGE_PENDING = 'GET_PUBLISHED_PACKAGE_PENDING';
export const GET_PUBLISHED_PACKAGE_REJECTED = 'GET_PUBLISHED_PACKAGE_REJECTED';

//-----------APPROVAL && CANCEL && CONFIRM COMPANY REGISTRATION--------------
export const POST_STATUS_TRANSACTION = 'POST_STATUS_TRANSACTION';
export const POST_STATUS_TRANSACTION_FULFILLED =
  'POST_STATUS_TRANSACTION_FULFILLED';
export const POST_STATUS_TRANSACTION_PENDING =
  'POST_STATUS_TRANSACTION_PENDING';
export const POST_STATUS_TRANSACTION_REJECTED =
  'POST_STATUS_TRANSACTION_REJECTED';

export const GET_TRANSACTION_BY_STATUS_CREATED_LIST =
  'GET_TRANSACTION_BY_STATUS_CREATED_LIST';
export const GET_TRANSACTION_BY_STATUS_CREATED_LIST_FULFILLED =
  'GET_TRANSACTION_BY_STATUS_CREATED_LIST_FULFILLED';
export const GET_TRANSACTION_BY_STATUS_CREATED_LIST_PENDING =
  'GET_TRANSACTION_BY_STATUS_CREATED_LIST_PENDING';
export const GET_TRANSACTION_BY_STATUS_CREATED_LIST_REJECTED =
  'GET_TRANSACTION_BY_STATUS_CREATED_LIST_REJECTED';

export const GET_TRANSACTION_BY_STATUS_ON_HOLD_LIST =
  'GET_TRANSACTION_BY_STATUS_ON_HOLD_LIST';
export const GET_TRANSACTION_BY_STATUS_ON_HOLD_LIST_FULFILLED =
  'GET_TRANSACTION_BY_STATUS_ON_HOLD_LIST_FULFILLED';
export const GET_TRANSACTION_BY_STATUS_ON_HOLD_LIST_PENDING =
  'GET_TRANSACTION_BY_STATUS_ON_HOLD_LIST_PENDING';
export const GET_TRANSACTION_BY_STATUS_ON_HOLD_LIST_REJECTED =
  'GET_TRANSACTION_BY_STATUS_ON_HOLD_LIST_REJECTED';
export const RESET_STATUS_TRANSACTION_BY_STATUS_ON_HOLD =
  'RESET_STATUS_TRANSACTION_BY_STATUS_ON_HOLD';

//Payment History
export const GET_PAYMENT_HISTORY = 'GET_PAYMENT_HISTORY';
export const RESET_STATUS_PAYMENT_HISTORY = 'RESET_STATUS_PAYMENT_HISTORY';
export const GET_PAYMENT_HISTORY_FULFILLED = 'GET_PAYMENT_HISTORY_FULFILLED';
export const GET_PAYMENT_HISTORY_PENDING = 'GET_PAYMENT_HISTORY_PENDING';
export const GET_PAYMENT_HISTORY_REJECTED = 'GET_PAYMENT_HISTORY_REJECTED';

export const GET_SALES_REPORT = 'GET_SALES_REPORT';
export const GET_SALES_REPORT_FULFILLED = 'GET_SALES_REPORT_FULFILLED';
export const GET_SALES_REPORT_PENDING = 'GET_SALES_REPORT_PENDING';
export const GET_SALES_REPORT_REJECTED = 'GET_SALES_REPORT_REJECTED';
