import { useState, useEffect } from 'react';
import setDataDashboard from '../services/data/setDataDashboard';

const useApiCall = (api, startDate = null, endDate = null) => {
  const getInitialStartDate = () => {
    return startDate;
  };

  const getInitialEndDate = () => {
    return endDate;
  };

  const setInitialData = resultData => {
    let modifiedData = setDataDashboard(resultData);
    return modifiedData;
  };

  const [isLoading, setIsLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState(null);
  const [today, setToday] = useState(getInitialStartDate());
  const [nextMonth, setNextMonth] = useState(getInitialEndDate());

  const fetchApi = (from, to) => {
    setIsLoading(true);
    api(from, to)
      .then(result => {
        let optionsData = setInitialData(result.data);
        setIsLoading(false);
        setFetchedData(optionsData);
      })
      .catch(() => {
        //eslint-disable-next-line
        setIsLoading(false);
      });
  };

  const handleSubmit = (from, to) => {
    setToday(from);
    setNextMonth(to);
    fetchApi(from, to);
  };

  const loadData = () => {
    fetchApi(today, nextMonth);
  };

  useEffect(() => {
    fetchApi(today, nextMonth);
    //eslint-disable-next-line
  }, []);

  return [isLoading, fetchedData, handleSubmit, loadData, today, nextMonth];
};

export default useApiCall;
