import { GET_IDENTITY_TYPE, GET_GUEST_TITLE_TYPE } from './actionTypes';
import { getGuestTitleTypeApi, getIdentityTypeApi } from '../api/guestApi';

const getGuestTitleType = () => {
  return dispatch => {
    return dispatch({
      type: GET_GUEST_TITLE_TYPE,
      payload: getGuestTitleTypeApi(),
    });
  };
};

const getIdentityType = () => {
  return dispatch => {
    return dispatch({
      type: GET_IDENTITY_TYPE,
      payload: getIdentityTypeApi(),
    });
  };
};

export { getGuestTitleType, getIdentityType };
