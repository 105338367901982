import React from 'react';
import { useState } from 'react';
import { Loader } from 'semantic-ui-react';
import AdditionalCostList from './components/AdditionalCostList';
import ModalConfirm from '../../../components/modal/modalConfirmationTwoButton';
import ModalAlert from '../../../components/modal/modalAlert';
import { Redirect } from 'react-router-dom';
import useApiCall from './hooks/useApiCall';
import {
  getAdditionalCostListApi,
  deleteAdditionalCostByCityAndTypeApi,
} from '../../../api/transportationAdditionalCostApi/transportationAdditionalCostApi';
import { setDataList } from './services/setData';
import _ from 'lodash';

const AdditionalCost = () => {
  // --------------------- initial ---------------------
  const [redirect, setRedirect] = useState(false);
  const [additionalCostListLoading, additionalCostList, loadData] = useApiCall(
    getAdditionalCostListApi
  );
  const [activePage, setActivePage] = useState(1);
  const [searchInput, setSearchInput] = useState('');
  // const [detailCost, setDetailCost] = useState({});
  const [goTo, setGoTo] = useState('');
  const [id, setId] = useState({});
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [headerModal, setHeaderModal] = useState('');
  const [contentModal, setContentModal] = useState('');
  const [openModalAlert, setOpenModalAlert] = useState(false);
  const [loadingList, setLoadingList] = useState(false);

  // --------------------- helper ---------------------
  const openConfirmation = (header, content) => {
    setHeaderModal(header);
    setContentModal(content);
    setOpenModalConfirmation(true);
  };

  const closeModal = () => {
    setHeaderModal('');
    setContentModal('');
    setOpenModalConfirmation(false);
    setOpenModalAlert(false);
  };

  const openAlert = (header, content) => {
    setHeaderModal(header);
    setContentModal(content);
    setOpenModalAlert(true);
  };

  // --------------------- handler ---------------------
  const handleAdd = () => {
    setGoTo(`/transportation-additional-cost/detail/`);
    setRedirect(true);
  };

  const handleChangeSearch = (e, { value }) => {
    setSearchInput(value);
  };

  const handlePageChange = (e, { activePage }) => {
    setActivePage(activePage);
  };

  const handleEdit = (e, { value }) => {
    setGoTo(
      `/transportation-additional-cost/detail/${value.origin}/${value.destination}/${value.type}`
    );
    // setDetailCost(value);
    setRedirect(true);
  };

  const handleDelete = (e, { value }) => {
    setId(value);
    openConfirmation(
      'Confirmation',
      'Are you sure want to delete this additional cost?'
    );
  };

  const handleClose = () => {
    closeModal();
  };

  const handleConfirm = () => {
    if (!_.isEmpty(id)) {
      setLoadingList(true);
      closeModal();
      deleteAdditionalCostByCityAndTypeApi(id.origin, id.destination, id.type)
        .then(() => {
          setId({});
          setLoadingList(false);
          openAlert('Success', 'Success delete additional cost');
        })
        .catch(() => {
          setId({});
          setLoadingList(false);
          openAlert('Failed', 'Failed delete additional cost');
        });
    } else {
      closeModal();
      loadData();
    }
  };

  // --------------------- variable / const ---------------------
  let loading = additionalCostListLoading || loadingList;
  let data = setDataList(additionalCostList);

  return redirect ? (
    <Redirect to={goTo} />
  ) : (
    <>
      <Loader loading={loading} />
      <AdditionalCostList
        data={data}
        handleAdd={handleAdd}
        activePage={activePage}
        pageChange={handlePageChange}
        searchInput={searchInput}
        handleChangeSearch={handleChangeSearch}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        loading={loading}
      />
      <ModalConfirm
        openModal={openModalConfirmation}
        size="mini"
        header={headerModal}
        message={contentModal}
        close={handleClose}
        confirm={handleConfirm}
      />
      <ModalAlert
        openModal={openModalAlert}
        handleClose={handleConfirm}
        header={headerModal}
        content={contentModal}
        nameButton="OK"
      />
    </>
  );
};

export default React.memo(AdditionalCost);
