import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Header } from 'semantic-ui-react';
import { DropdownWithError } from '../../../../components/common/fieldWithError';
import InputWithLabel from '../../../../components/input/inputWithLabel';
import ImageAndLogoCustomer from './ImageCustomerAndCompanyLogo';
const PaymentTermCustomer = props => {
  let contentImage = '';
  let contentLogo = '';
  let companyLogo =
    props.contentCompanyLogo && props.contentCompanyLogo.ImagePreview;
  if (props.contentDetail.ImagePreview) {
    contentImage = 'Change Image';
  } else {
    contentImage = 'Add Image';
  }
  if (companyLogo) {
    contentLogo = 'Change Image';
  } else {
    contentLogo = 'Add Image';
  }
  return (
    <div>
      <Grid columns="equal">
        <Grid.Row stretched>
          <Grid.Column width={15}>
            {props.isPartner || (
              <Fragment>
                <Grid columns="equal">
                  <Grid.Row>
                    <Grid.Column>
                      <Header as="h4" className="required-field">
                        Payment Term Scheme
                      </Header>
                      <InputWithLabel
                        placeholder="Payment Scheme"
                        name="PaymentSchemeId"
                        type="select"
                        value={props.contentDetail.PaymentSchemeId}
                        handleChange={props.handleChange}
                        options={props.getPaymentSchemeList}
                        required
                        fluid={true}
                        validate={props.errors}
                        disabled={props.isFinance ? false : props.disable}
                      />
                      {/* <DropdownWithError
                        placeholder="Payment Term"
                        name="PaymentSchemeId"
                        title="Payment Term"
                        handleChange={props.handleChange}
                        option={props.getPaymentSchemeList}
                        disable={props.isFinance ? false : props.disable}
                        valueDropDown={props.contentDetail.PaymentSchemeId}
                        statusRequired
                        validate={props.errors}
                      /> */}
                    </Grid.Column>
                    <Grid.Column>
                      <Header as="h4" content="Account Manager" />
                      <DropdownWithError
                        placeholder="Account Manager"
                        name="AccountManagerId"
                        // title="Payment Term"
                        handleChange={props.handleChange}
                        option={props.accountManagerList}
                        disable={props.isFinance ? true : props.disable}
                        valueDropDown={props.contentDetail.AccountManagerId}
                        statusRequired
                        validate={props.errors}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Fragment>
            )}
            <ImageAndLogoCustomer
              companyImage={props.contentDetail.ImagePreview}
              companyLogo={companyLogo}
              modalImage={props.modalImage}
              contentImage={contentImage}
              contentLogo={contentLogo}
              disable1={props.disable}
              disable2={props.disable}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

PaymentTermCustomer.propTypes = {
  handleChange: PropTypes.func,
  errors: PropTypes.shape({ PaymentSchemeIdError: PropTypes.string }),
  contentDetail: PropTypes.shape({
    CompanyLogo: PropTypes.shape({
      ImageContent: PropTypes.string,
      ImageName: PropTypes.string,
      ImagePreview: PropTypes.string,
    }),
    ImageName: PropTypes.string,
    ImageContent: PropTypes.string,
    ImagePreview: PropTypes.string,
    PaymentSchemeId: PropTypes.string,
    AccountManagerId: PropTypes.string,
  }),
  disable: PropTypes.bool,
  getPaymentSchemeList: PropTypes.array,
  modalImage: PropTypes.func,
  contentCompanyLogo: PropTypes.string,
  isPartner: PropTypes.bool,
  isFinance: PropTypes.bool,
  accountManagerList: PropTypes.array,
};
export default PaymentTermCustomer;
