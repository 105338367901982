import {
  GET_EXCHANGE_RATE_LIST,
  RESET_STATUS_EXCHANGE_RATE,
  POST_EXCHANGE_RATE,
  PUT_EXCHANGE_RATE,
  DELETE_EXCHANGE_RATE,
} from './actionTypes';
import getExchangeRateListApi from '../api/getExchangeRateListApi';
import postExchangeRateApi from '../api/postExchangeRateApi';
import putExchangeRateApi from '../api/putExchangeRateApi';
import { deleteExchangeRateApi } from '../api/exchangeRateApi';
const getExchangeRateListAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_EXCHANGE_RATE_LIST,
      payload: getExchangeRateListApi(),
    });
  };
};

const resetExchangeRateAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_STATUS_EXCHANGE_RATE,
    });
  };
};
const postExchangeRateAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_EXCHANGE_RATE,
      payload: postExchangeRateApi(data),
    });
  };
};
const putExchangeRateAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_EXCHANGE_RATE,
      payload: putExchangeRateApi(id, data),
    });
  };
};

const deleteExchangeRateAction = id => {
  return dispatch => {
    return dispatch({
      type: DELETE_EXCHANGE_RATE,
      payload: deleteExchangeRateApi(id),
    });
  };
};
export {
  getExchangeRateListAction,
  resetExchangeRateAction,
  postExchangeRateAction,
  putExchangeRateAction,
  deleteExchangeRateAction,
};
