import React from 'react';
import { Table, Button, Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const ButtonActionTourTransaction = props => {
  let {
    isOperation,
    isShowCreateSimilar,
    isShowSendEmailTravelAgent,
    isShowEditQuotation,
    isGenerateResource,
    packageType,
    transactionStatus,
    isGreaterThenEndDate,
    isFinance,
    isSales,
  } = props;
  let keys = [
    'Booking_DP_Confirmed',
    'Booking_In_Payment',
    'Booking_created',
    'Booking_hold',
  ];
  let generateResourceStatus = [
    'Booking_DP_Confirmed',
    'Booking_In_Payment',
    'Booking_Confirmed',
    'Booking_Completed',
  ];
  let matching = keys.find(values => values === props.id);
  let resourceStatus = generateResourceStatus.find(
    values => values === transactionStatus
  );
  let isFixed = packageType === 'Fixed';
  return (
    <Table.Cell textAlign="center">
      {props.id === matching && !isFixed && isShowCreateSimilar ? (
        <Popup
          trigger={
            <Button
              icon="copy outline"
              style={{ color: 'green', background: 'transparent' }}
              onClick={props.handleClick}
              id={props.id}
              value={props.value}
              name="createSimilar"
            />
          }
          content="Create Similar"
        />
      ) : props.id === 'Booking_draft' ? (
        !props.isAccepted ? (
          isShowEditQuotation ? (
            <>
              <Popup
                trigger={
                  <Button
                    icon="edit"
                    style={{ color: 'green', background: 'transparent' }}
                    onClick={props.handleClick}
                    id={props.id}
                    value={props.value}
                    name="edit"
                  />
                }
                content="Edit"
              />
              <Popup
                trigger={
                  <Button
                    icon="check"
                    style={{ color: 'blue', background: 'transparent' }}
                    onClick={props.handleClick}
                    id={props.id}
                    value={props.value}
                    name="accept"
                  />
                }
                content="Book Now"
              />
            </>
          ) : (
            '-'
          )
        ) : (
          <>
            {' '}
            <span style={{ textAlign: 'center' }}>Accepted</span>
          </>
        )
      ) : isFixed ? (
        <></>
      ) : isShowCreateSimilar ? (
        <>
          <Popup
            trigger={
              <Button
                icon="copy outline"
                style={{ color: 'green', background: 'transparent' }}
                onClick={props.handleClick}
                id={props.id}
                value={props.value}
                name="createSimilar"
              />
            }
            content="Create Similar"
          />
        </>
      ) : (
        ''
      )}
      {isShowSendEmailTravelAgent && (
        <Popup
          trigger={
            <Button
              icon="send"
              style={{ color: 'green', background: 'transparent' }}
              onClick={props.handleClick}
              id={props.id}
              value={props.idSendEmail}
              name="sendEmail"
            />
          }
          content="Send Email to Travel Agent"
        />
      )}
      {resourceStatus &&
      isGenerateResource &&
      !isFixed &&
      isGreaterThenEndDate ? (
        <Button
          positive
          onClick={props.handleClick}
          id={props.id}
          value={props.value}
          name="generateResource"
          content="Generate Resource"
        />
      ) : !isFixed && isOperation ? (
        'Generated'
      ) : isFinance || isSales ? (
        ''
      ) : (
        '-'
      )}
    </Table.Cell>
  );
};

ButtonActionTourTransaction.propTypes = {
  value: PropTypes.number,
  handleClick: PropTypes.func,
  id: PropTypes.string,
  isAccepted: PropTypes.bool,
  packageType: PropTypes.string,
  idSendEmail: PropTypes.string,
  isShowCreateSimilar: PropTypes.bool,
  isShowSendEmailTravelAgent: PropTypes.bool,
  isShowEditQuotation: PropTypes.bool,
  isGenerateResource: PropTypes.bool,
  transactionStatus: PropTypes.string,
  isOperation: PropTypes.string,
  isFinance: PropTypes.bool,
  isGreaterThenEndDate: PropTypes.bool,
  isSales: PropTypes.bool,
};

export default ButtonActionTourTransaction;
