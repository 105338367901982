import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form, Grid } from 'semantic-ui-react';
import TableSupplierPriceDetails from '../table/tableSupplierPriceDetails';
import { HeaderSupplierPriceDetails } from '../table/headerTabel';
import InputWithLabel from '../input/inputWithLabel';
import InputDate from '../input/inputDate';
const modalSupplierPrice = props => (
  <Modal
    //dimmer="blurring"
    closeIcon
    inverted
    open={props.openModalPrice}
    onClose={props.handleClose}
    size="tiny"
  >
    <Modal.Header>
      {props.accomodationPrice.Status +
        ' price for ' +
        props.itemName +
        ' ' +
        props.servicePriceName}
    </Modal.Header>
    <Modal.Content>
      <h5>{props.content}</h5>
      <Form textAlign="center">
        <Grid columns={2} padded="vertically">
          <Grid.Column>
            <Form>
              <InputWithLabel
                inline={false}
                label="Rank Type"
                placeholder="Rank Type"
                type="select"
                required
                name="RankType"
                value={props.accomodationPrice.RankType}
                options={props.rankType}
                handleChange={props.handleChangePrice}
                validate={props.errors}
              />
            </Form>
            <Form>
              <Form.Field>
                <InputDate
                  label="Start Date"
                  name="StartDate"
                  value={props.accomodationPrice.StartDate}
                  setDate={props.handleChangePriceDate}
                  labelStyle={{
                    display: 'block',
                    fontWeight: 'bold',
                    marginBottom: '4px',
                  }}
                  validate={props.errors}
                  readonly={false}
                />
              </Form.Field>
            </Form>
            <Form>
              <Form.Field>
                <InputDate
                  label="Cut Of Date"
                  name="CutOfDate"
                  value={props.accomodationPrice.CutOfDate}
                  setDate={props.handleChangePriceDate}
                  labelStyle={{
                    display: 'block',
                    fontWeight: 'bold',
                    marginBottom: '4px',
                  }}
                  validate={props.errors}
                  readonly={false}
                />
              </Form.Field>
            </Form>
            <Form>
              <InputWithLabel
                inline={false}
                label="Minimum Night"
                type="type"
                required
                name="MinimumNight"
                value={props.accomodationPrice.MinimumNight}
                placeholder="Minimum Night"
                handleChange={props.handleChangePrice}
                validate={props.errors}
              />
            </Form>
          </Grid.Column>
          <Grid.Column>
            <Form>
              <InputWithLabel
                inline={false}
                label="Rank Value"
                type="type"
                required
                name="RankValue"
                value={props.accomodationPrice.RankValue}
                placeholder="Rank"
                handleChange={props.handleChangePrice}
                validate={props.errors}
                disabled={props.accomodationPrice.RankType < 4 && true}
              />
            </Form>
            <Form>
              <Form.Field>
                <InputDate
                  label="End Date"
                  name="EndDate"
                  value={props.accomodationPrice.EndDate}
                  setDate={props.handleChangePriceDate}
                  labelStyle={{
                    display: 'block',
                    fontWeight: 'bold',
                    marginBottom: '4px',
                  }}
                  validate={props.errors}
                  readonly={false}
                />
              </Form.Field>{' '}
            </Form>
            <Form>
              <InputWithLabel
                inline={false}
                type="number"
                required
                name="CutOfDay"
                label="Cut Of Day"
                value={props.accomodationPrice.CutOfDay}
                placeholder="Cut Of Day"
                handleChange={props.handleChangePrice}
                validate={props.errors}
              />
            </Form>
            <Form>
              <InputWithLabel
                inline={false}
                type="select"
                required
                name="Currency"
                label="Currency"
                value={props.accomodationPrice.Currency}
                placeholder="Currency"
                handleChange={props.handleChangePrice}
                options={props.currenciesList}
                validate={props.errors}
              />{' '}
            </Form>
          </Grid.Column>
        </Grid>
        <Form.Group widths={4}>
          <Form.Field />
          <Form.Field />
          <Form.Field />
          <Form.Field textAlign="right">
            <Form.Button
              positive
              content="Add Price"
              onClick={props.addPriceDetails}
            />
          </Form.Field>
        </Form.Group>
        <TableSupplierPriceDetails
          headerTable={HeaderSupplierPriceDetails}
          listData={props.accomodationPrice.PriceDetails}
          currenciesList={props.currenciesList}
          handleChangeCostPriceDetails={props.handleChangeCostPriceDetails}
          handleChangePriceDetails={props.handleChangePriceDetails}
          deletePrice={props.deletePrice}
        />
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button content="Save" positive onClick={props.addPriceToList} />
    </Modal.Actions>
  </Modal>
);

modalSupplierPrice.propTypes = {
  handleChangeCostPriceDetails: PropTypes.func,
  handleChangePriceDetails: PropTypes.func,
  currenciesList: PropTypes.array,
  openModalPrice: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  content: PropTypes.string,
  addPrice: PropTypes.func,
  handleChangeDropdown: PropTypes.func,
  handleChange: PropTypes.func,
  rankType: PropTypes.array,
  addPriceDetails: PropTypes.func,
  accomodationPrice: PropTypes.shape({
    PriceDetails: PropTypes.array,
    RankValue: PropTypes.number,
    RankType: PropTypes.string,
    StartDate: PropTypes.string,
    EndDate: PropTypes.string,
    CutOfDate: PropTypes.string,
    CutOfDay: PropTypes.number,
    MinimumNight: PropTypes.number,
    Currency: PropTypes.string,
    Status: PropTypes.string,
  }),
  handleChangePrice: PropTypes.func,
  handleChangePriceDate: PropTypes.func,
  addPriceToList: PropTypes.func,
  deletePrice: PropTypes.func,
  errors: PropTypes.object,
  itemName: PropTypes.string,
  servicePriceName: PropTypes.string,
};

export default modalSupplierPrice;
