import React from 'react';
import { Input, Grid, Segment, Icon, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import TableListCustomerProfile from './tabel/TableListCustomerProfile';
import { HeaderTabelCustomerProfile } from '../constant/HeaderTable';
import HeaderTitle from '../../../../components/common/headerTitle';
import Pagination from '../../../../components/common/pagination';
import { connect } from 'react-redux';

const ListCustomerProfile = props => (
  <div>
    <Grid columns="equal">
      <Grid.Row stretched>
        <Grid.Column>
          <Segment style={{ marginTop: '30px' }}>
            <HeaderTitle iconName="users" title={props.title} />
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Input
                    icon="search"
                    placeholder="Search Company Name"
                    name="searchInput"
                    value={props.searchInput}
                    onChange={props.handleChange}
                  />
                </Grid.Column>
                <Grid.Column width={8} textAlign="right">
                  {props.isFinance ? null : props.type === 'customerProfile' ? (
                    <Button onClick={props.buttonNew} positive floated="right">
                      <Icon name="add" />
                      Add New Customer
                    </Button>
                  ) : (
                    <Button onClick={props.buttonNew} positive floated="right">
                      <Icon name="add" />
                      Add New Partner
                    </Button>
                  )}
                </Grid.Column>
              </Grid.Row>
              <TableListCustomerProfile
                data={props.data}
                headerTable={HeaderTabelCustomerProfile}
                pageChange={props.pageChange}
                listData={props.listData}
                pagedData={props.pagedData}
                title={props.title}
                buttonEdit={props.buttonEdit}
                buttonDelete={props.buttonDelete}
                buttonSeeDetail={props.buttonSeeDetail}
                isHideButtonDelete={props.isHideButtonDelete}
                isSales={props.isSales}
                isFinance={props.isFinance}
              />
              <Pagination
                pagedData={props.pagedData}
                numbersTo={props.numbersTo}
                numbersFrom={props.numbersFrom}
                data={props.data}
                pageChange={props.pageChange}
                activePage={props.activePage}
              />
            </Grid>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
);

ListCustomerProfile.propTypes = {
  listData: PropTypes.array,
  pagedData: PropTypes.array,
  title: PropTypes.string,
  pageChange: PropTypes.func,
  activePage: PropTypes.number,
  searchInput: PropTypes.string,
  handleChange: PropTypes.func,
  data: PropTypes.array,
  buttonNew: PropTypes.func,
  buttonEdit: PropTypes.func,
  buttonDelete: PropTypes.func,
  buttonSeeDetail: PropTypes.func,
  type: PropTypes.string,
  numbersFrom: PropTypes.number,
  numbersTo: PropTypes.number,
  isHideButtonDelete: PropTypes.bool,
  isSales: PropTypes.bool,
  isFinance: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    isFinance: state.UserAccessStore.isFinance,
  };
};
export default connect(mapStateToProps)(ListCustomerProfile);
