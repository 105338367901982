import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from 'semantic-ui-react';
import styles from '../styles';
const SegmentFooterDetail = props => {
  const { data, handleOpenConfirm, handleOpenCancel } = props; //handleOpenReject
  const buttonAutoConfirm = () => {
    return (
      <Grid centered>
        <Grid.Row>
          <Button
            size="large"
            name={data.Text}
            id={data.RequestItemId}
            content={data.Qty}
            onClick={handleOpenConfirm}
            style={styles.paddingLeftRight100}
            primary
          >
            Input Confirmation Number
          </Button>
        </Grid.Row>
      </Grid>
    );
  };
  const buttonManualConfirm = () => {
    return (
      <Grid centered>
        <Grid.Row>
          <Button
            positive
            size="large"
            onClick={handleOpenConfirm}
            name={data.Text}
            id={data.RequestItemId}
            content={data.Qty}
          >
            Confirm
          </Button>
          <Button
            negative
            size="large"
            onClick={handleOpenCancel} //handleOpenReject
            name={data.Text}
            id={data.RequestItemId}
            content={data.Qty}
          >
            Reject
          </Button>
        </Grid.Row>
      </Grid>
    );
  };

  return data.Status === 'Pending'
    ? buttonManualConfirm()
    : data.Status === 'Auto_confirmed'
    ? buttonAutoConfirm()
    : '';
};

SegmentFooterDetail.propTypes = {
  data: PropTypes.object,
  status: PropTypes.string,
  handleOpenConfirm: PropTypes.func,
  handleOpenReject: PropTypes.func,
  handleOpenCancel: PropTypes.func,
};

export default React.memo(SegmentFooterDetail);
