import {
  getTime,
  // subtractDate,
  convertDateFormat,
  diffDate,
} from '../../../../scripts/moment';
import { createArrayMovements } from '../../../../scripts/packageManagement/dailyProgramFixed';
const copyObject = obj => {
  let newObj = JSON.stringify(obj);
  return JSON.parse(newObj);
};

export const inputValidation = (data, errorObject) => {
  let newError = copyObject(errorObject);
  let messageEmpty = 'Please check field, some field cannot be empty';
  let messageDate = 'Please check field date , the date invalid';
  let messsageEndDate =
    'Please change end date, the date cannot same or less than the start date';
  let messsageGraceDate =
    'Please change grace date, the date cannot more than the end date';
  let messageExpiredDate =
    'Please change expired date, the date cannot more than the start date';
  newError.tourName.error = data.tourName === '';
  newError.description.error = data.description === '';
  newError.tourType.error = data.tourType === '';
  newError.currency.error = data.currency === '';
  newError.maxGuest.error = data.maxGuest === '';
  newError.minGuest.error = data.minGuest === '';
  newError.destination.error = data.destination === '';
  newError.minPax.error = data.minPax === '';
  newError.startDate.error =
    data.startDate === '' || getTime(data.startDate) > getTime(data.endDate);
  newError.endDate.error =
    data.endDate === '' || getTime(data.endDate) <= getTime(data.startDate);
  newError.expiredDate.error =
    data.expiredDate === '' ||
    getTime(data.expiredDate) > getTime(data.startDate);
  newError.graceDate.error =
    data.graceDate === '' || getTime(data.endDate) < getTime(data.graceDate);

  if (getTime(data.startDate) > getTime(data.endDate)) {
    newError.startDate.message =
      'Please change start date, the date cannot more than end date';
  }
  if (getTime(data.startDate) >= getTime(data.endDate)) {
    newError.endDate.message = messsageEndDate;
  }
  if (getTime(data.endDate) < getTime(data.graceDate)) {
    newError.graceDate.message = messsageGraceDate;
  }
  if (getTime(data.expiredDate) > getTime(data.startDate)) {
    newError.expiredDate.message = messageExpiredDate;
  }
  // newError.endDate.message =
  //   getTime(data.startDate) >= getTime(data.endDate) && messsageEndDate;
  // newError.graceDate.message =
  //   getTime(data.expiredDate) < getTime(data.graceDate) && messsageGraceDate;
  // newError.expiredDate.message =
  //   getTime(data.expiredDate) > getTime(data.startDate) && messageExpiredDate;
  let field =
    newError.tourName.error ||
    newError.description.error ||
    newError.tourType.error ||
    newError.currency.error ||
    newError.maxGuest.error ||
    newError.minGuest.error ||
    newError.destination.error ||
    newError.minPax.error;
  let date =
    newError.startDate.error ||
    newError.endDate.error ||
    newError.expiredDate.error ||
    newError.graceDate.error;
  newError.message = field ? messageEmpty : date ? messageDate : null;
  newError.isError = field || date;
  return newError;
};

export const checkDateValidation = (data, errorObject) => {
  let newError = copyObject(errorObject);
  let messageDate = 'Please check field date , the date invalid';
  let messsageEndDate =
    'Please check end date, the date cannot be less than the start date';
  let messsageGraceDate =
    'Please check grace date, the date cannot more than the expired date';
  let startDate = getTime(data.startDate);
  let endDate = getTime(data.endDate);
  newError.startDate.error = data.startDate === '' || startDate > endDate;
  newError.endDate.error = data.endDate === '' || endDate < startDate;
  newError.endDate.message = startDate >= endDate && messsageEndDate;
  newError.graceDate.message = startDate >= endDate && messsageGraceDate;
  newError.message =
    newError.endDate.error || newError.graceDate.error ? messageDate : null;
  newError.isError =
    newError.startDate.error ||
    newError.endDate.error ||
    newError.expiredDate.error ||
    newError.graceDate.error;
  return newError;
};

export const priceValidation = data => {
  const price = {
    sharingRoom: {
      error: false,
    },
    singleRoom: {
      error: false,
    },
    message: '',
    isError: false,
  };
  let newError = copyObject(price);
  let messageSingle = 'Single Room in tab price cannot be 0';
  let messageSharing = 'Sharing Room in tab price cannot be 0';
  let messageBoth = 'Sharing Room and Single Room in tab price cannot be 0';
  newError.sharingRoom.error = Number(data.SharingRoomPrice) === 0;
  newError.singleRoom.error = Number(data.SingleRoomPrice) === 0;
  newError.message =
    newError.sharingRoom.error && newError.singleRoom.error
      ? messageBoth
      : newError.sharingRoom.error
      ? messageSharing
      : newError.singleRoom.error
      ? messageSingle
      : '';
  newError.isError = newError.sharingRoom.error || newError.singleRoom.error;
  return newError;
};

export const checkDateAccommodationEqualStartDateAndEndDate = (
  tourInformation,
  headline
) => {
  const data = {
    checkOutError: false,
    endDateError: false,
    message: '',
    isError: false,
  };
  let newError = copyObject(data);
  // let lastDay = convertDateFormat(
  //   subtractDate(tourInformation.endDate, 1, 'days'),
  //   'DD	MMMM YYYY'
  // );
  let messageOverCheckOut =
    'Please change check out date because exceeded the tour end date';
  // let messageUnderEndDate =
  //   'Please change check out date because smaller ' + lastDay;
  let endDate = getTime(tourInformation.endDate);
  let checkOut = getTime(
    headline.MainPrograms[headline.MainPrograms.length - 1].LeavingDate
  );
  // let checkOutMin = getTime(subtractDate(tourInformation.endDate, 1, 'days'));
  newError.endDateError = checkOut > endDate;
  // newError.checkOutError = checkOutMin > checkOut;
  newError.message = newError.endDateError ? messageOverCheckOut : '';
  // newError.message = newError.endDateError
  //   ? messageOverCheckOut
  //   : newError.checkOutError
  //     ? messageUnderEndDate
  //     : '';
  newError.isError = newError.checkOutError || newError.endDateError;
  return newError;
};
// const checkDifferences = (departure, arrival) => {
//   return departure
//     .filter(x => !arrival.includes(x))
//     .concat(arrival.filter(a => !departure.includes(a)), []);
// };
export const checkExistingDepartureArrival = async dailyProgram => {
  const data = {
    departureError: false,
    arrivalError: false,
    bothError: false,
    message: '',
    isError: false,
  };
  let newError = copyObject(data);
  let movements = createArrayMovements(dailyProgram);
  let departure = [];
  let arrival = [];
  movements.map(item => {
    if (item.MovementName === 'DEPARTURE') {
      departure.push(item.DayIndex);
    } else if (item.MovementName === 'ARRIVAL') {
      arrival.push(item.DayIndex);
    }
    return item;
  }, []);
  if (departure.length === 0 || arrival.length === 0) {
    newError.bothError = true;
    newError.message = `Please add departure and arrival in daily program, departure and arrival must be a pair`;
  } else if (departure.length !== arrival.length) {
    newError.bothError = true;
    newError.message = `Please check departure and arrival in daily program, departure and arrival must be a pair`;
  }
  // else {
  //   let difference = checkDifferences(departure, arrival);
  //   difference.map(data => {
  //     if (data === 0) {
  //       newError.departureError = true;
  //       newError.message =
  //         'Please check in first day, departure and arrival must be a pair';
  //     } else if (data === movements.length) {
  //       newError.arrivalError = true;
  //       newError.message =
  //         'Please check in last day, departure and arrival must be a pair';
  //     } else {
  //       newError.bothError = true;
  //       newError.message = `Please check in day ${data +
  //         1} , departure and arrival must be a pair`;
  //     }
  //     return data;
  //   });
  // }
  newError.isError =
    newError.bothError || newError.departureError || newError.arrivalError;
  return newError;
};

export const checkTotalDayAndAccom = (mainPrograms, generalInformation) => {
  let accommodations = mainPrograms.filter(e => e.AccommodationSummary);
  let diffDays = diffDate(
    generalInformation.endDate,
    convertDateFormat(
      accommodations[accommodations.length - 1].LeavingDate,
      'YYYY-MM-DD'
    ),
    'day'
  );
  return diffDays < -1;
};

export const paymentTermValidation = data => {
  const total = (accumulator, currentValue) =>
    Number(accumulator.PaymentPercentage) +
    Number(currentValue.PaymentPercentage);
  return data.reduce(total);
};

// export const inputValidationVariableDate = (data, errorObject) => {
//   let newError = copyObject(errorObject);
//   let messageEmpty = 'Please check field, some field cannot be empty';
//   newError.tourName.error = data.tourName === '';
//   newError.description.error = data.description === '';
//   newError.tourType.error = data.tourType === '';
//   newError.currency.error = data.currency === '';

//   newError.destination.error = data.destination === '';
//   newError.minPax.error = data.minPax === '';

//   let field =
//     newError.tourName.error ||
//     newError.description.error ||
//     newError.tourType.error ||
//     newError.currency.error ||
//     newError.destination.error ||
//     newError.minPax.error;

//   newError.message = messageEmpty;
//   newError.isError = field;
//   return newError;
// };
