import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Loader } from 'semantic-ui-react';
import ModalConfirm from '../../components/modal/modalConfirmationTwoButton';
import ModalAlert from '../../components/modal/modalAlert';
import AdditionalServicesList from './components/AdditionalServicesList';
import useApiCall from './hooks/useApiCall';
import {
  getAdditionalServicesListApi,
  deleteAdditionalServiceApi,
} from '../../api/additionalServices/additionalServicesApi';
import { Redirect } from 'react-router-dom';

// import { additionalServicesList } from './constants/dataDummy';

const AdditionalServices = () => {
  // --------------------- initial ---------------------
  // const [loading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [searchInput, setSearchInput] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [id, setId] = useState(0);
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [openModalAlert, setOpenModalAlert] = useState(false);
  const [headerModal, setHeaderModal] = useState('');
  const [contentModal, setContentModal] = useState('');
  const [loadingList, setLoadingList] = useState(false);

  const [
    additionalServicesLoading,
    additionalServicesList,
    // eslint-disable-next-line
    setAdditionalServiceList,
    loadData,
  ] = useApiCall(getAdditionalServicesListApi);

  // --------------------- helper ---------------------
  const openConfirmation = (header, content) => {
    setHeaderModal(header);
    setContentModal(content);
    setOpenModalConfirmation(true);
  };

  const openAlert = (header, content) => {
    setHeaderModal(header);
    setContentModal(content);
    setOpenModalAlert(true);
  };

  const closeModal = () => {
    setHeaderModal('');
    setContentModal('');
    setOpenModalConfirmation(false);
    setOpenModalAlert(false);
  };

  // --------------------- handler ---------------------
  const handleChangeSearch = (e, { value }) => {
    setSearchInput(value);
  };

  const handlePageChange = (e, { activePage }) => {
    setActivePage(activePage);
  };

  const handleAdd = () => {
    setRedirect(true);
  };

  const handleEdit = e => {
    setId(e.target.value);
    setRedirect(true);
  };

  const handleDelete = e => {
    setId(e.target.value);
    openConfirmation(
      'Confirmation',
      'Are you sure want to delete this additional service?'
    );
    // setOpenModalConfirmation(true);
  };

  const handleClose = () => {
    closeModal();
  };

  const handleConfirm = () => {
    if (id) {
      setLoadingList(true);
      closeModal();
      deleteAdditionalServiceApi(id)
        .then(() => {
          setId(0);
          setLoadingList(false);
          openAlert('Success', 'Success delete additional service');
        })
        .catch(() => {
          setId(0);
          setLoadingList(false);
          openAlert('Failed', 'Failed delete additional service');
        });
    } else {
      closeModal();
      loadData();
    }
  };

  let loading = additionalServicesLoading || loadingList;

  return redirect ? (
    <Redirect to={`/additional-services/detail/${id}`} />
  ) : (
    <>
      <Dimmer.Dimmable blurring dimmed={false}>
        <Loader loading={loading} />
        <AdditionalServicesList
          data={additionalServicesList}
          loading={loading}
          activePage={activePage}
          pageChange={handlePageChange}
          searchInput={searchInput}
          handleChangeSearch={handleChangeSearch}
          handleEdit={handleEdit}
          handleAdd={handleAdd}
          handleDelete={handleDelete}
        />
        <ModalConfirm
          openModal={openModalConfirmation}
          size="mini"
          header={headerModal}
          message={contentModal}
          close={handleClose}
          confirm={handleConfirm}
        />
        <ModalAlert
          openModal={openModalAlert}
          handleClose={handleConfirm}
          header={headerModal}
          content={contentModal}
          nameButton="OK"
        />
      </Dimmer.Dimmable>
    </>
  );
};

AdditionalServices.propTypes = {
  loading: PropTypes.bool,
};

export default AdditionalServices;
