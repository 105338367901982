import React from 'react';
import { connect } from 'react-redux';
import {
  getPlaceListAction,
  resetPlaceAction,
  deletePlaceAction,
  getPlaceByIdAction,
  putPlaceAction,
  postPlaceAction,
  getPlaceTypeAction,
} from './placeAction';
import ModalAlert from './../../components/modal/modalAlert';
import PlaceList from './components/PlaceList';
import PropTypes from 'prop-types';
import ModalPlace from './components/ModalPlace';
import { getCityListAction } from '../shared/actions/cityAction';
import { getRegionListAction } from '../shared/actions/regionAction';
import { getCountryListAction } from '../shared/actions/countryAction';
import { getAreaListAction } from '../shared/actions/areaAction';
import ModalConfirm from '../../components/modal/modalConfirmationTwoButton';
import { setPlaceIdObject } from './services/data/setDataState';
import fieldToValidate from './constant/fieldToValidate';
import handleValidation from './services/validation/emptyFieldValidation';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
class placePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      placeList: [],
      placeById: {
        Id: '',
        Name: '',
        Address: '',
        Code: '',
        CityId: '',
        PlaceType: '',
        AddressObject: {
          Address1: '',
          Address2: '',
          Address3: '',
          AreaId: '',
          PostalCode: '',
          LandMark: '',
          Country: '',
          City: '',
          Region: '',
        },
      },
      loadingPlace: true,
      failedPlace: false,
      modalOpen: false,
      modalPlace: false,
      modalConfirmation: false,
      errors: {},
      loadingModal: false,
      openConfirm: false,
      contentConfirm: '',
      changePage: false,
      placeId: '',
    };
  }
  static propTypes = {
    getPlaceListAction: PropTypes.func,
    resetPlaceAction: PropTypes.func,
    getPlaceStatus: PropTypes.string,
    getPlace: PropTypes.array,
    deletePlaceAction: PropTypes.func,
    getPlaceByIdAction: PropTypes.func,
    putPlaceAction: PropTypes.func,
    postPlaceAction: PropTypes.func,
    getPlaceByIdStatus: PropTypes.string,
    deletePlaceStatus: PropTypes.string,
    postPlaceStatus: PropTypes.string,
    putPlaceStatus: PropTypes.string,
    getPlaceById: PropTypes.array,
    getPlaceType: PropTypes.array,
    getRegionListAction: PropTypes.func,
    getCityListAction: PropTypes.func,
    getAreaListAction: PropTypes.func,
    getCountryListAction: PropTypes.func,
    getPlaceTypeAction: PropTypes.func,
    listCountry: PropTypes.array,
    listRegion: PropTypes.array,
    listCity: PropTypes.array,
    listArea: PropTypes.array,
    loading: PropTypes.bool,
    errors: PropTypes.object,
  };

  handleOpen = () => this.setState({ modalOpen: true });
  handleClose = () =>
    this.setState({
      modalOpen: false,
      modalConfirmation: false,
      failedPlace: false,
    });
  handleCloseModal = () => this.setState({ modalPlace: false });
  handleCloseConfirm = () =>
    this.setState({ openConfirm: false, loadingPlace: false });
  componentDidMount = () => {
    this.props.getPlaceListAction();
    this.props.getCountryListAction();
    this.props.getPlaceTypeAction();
  };
  componentDidUpdate() {
    if (this.props.getPlaceStatus === 'success') {
      this.setState({
        placeList: this.props.getPlace,
        loadingPlace: false,
      });
      this.props.resetPlaceAction();
    } else if (this.props.getPlaceStatus === 'failed') {
      this.setState({
        failedPlace: true,
        loadingPlace: false,
        headerAlert: 'Failed',
        contentAlert: this.props.errors.global,
      });
      this.props.resetPlaceAction();
    }

    //------------------------------------------------------
    if (this.props.getPlaceByIdStatus === 'success') {
      this.setStatePlace(this.props.getPlaceById);
      this.setState({
        loadingModal: false,
      });
      this.props.resetPlaceAction();
    } else if (this.props.getPlaceByIdStatus === 'failed') {
      this.props.resetPlaceAction();
    }
    if (this.props.postPlaceStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Saving Place Succesfully',
        modalConfirmation: true,
        loadingModal: false,
        modalPlace: false,
      });
      this.props.resetPlaceAction();
      this.props.getPlaceListAction();
    } else if (this.props.postPlaceStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: 'Saving Place Failed',
        modalConfirmation: true,
        loadingModal: false,
      });
      this.props.resetPlaceAction();
      this.props.getPlaceListAction();
    }
    if (this.props.putPlaceStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Edit Place Succesfully',
        modalConfirmation: true,
        loadingModal: false,
        modalPlace: false,
      });
      this.props.resetPlaceAction();
      this.props.getPlaceListAction();
    } else if (this.props.putPlaceStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: 'Edit Place Failed',
        modalConfirmation: true,
        loadingModal: false,
      });
      this.props.resetPlaceAction();
      this.props.getPlaceListAction();
    }
    if (this.props.deletePlaceStatus === 'success') {
      this.setState({
        headerAlert: 'Success',
        contentAlert: 'Delete Place Succesfully',
        modalConfirmation: true,
        loadingPlace: false,
      });
      this.props.resetPlaceAction();
      this.props.getPlaceListAction();
    } else if (this.props.deletePlaceStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: 'Delete Place Failed',
        modalConfirmation: true,
        loadingPlace: false,
      });
      this.props.resetPlaceAction();
      this.props.getPlaceListAction();
    }
    //------------------------------------------------------
  }
  newPlace = () => {
    this.setState({
      modalPlace: true,
      headerModal: ' Add New Place',
      placeById: {
        Id: '',
        Name: '',
        Address: '',
        Code: '',
        CityId: '',
        PlaceType: '',
        AddressObject: {
          Address1: '',
          Address2: '',
          Address3: '',
          AreaId: '',
          PostalCode: '',
          LandMark: '',
          Country: '',
          City: '',
          Region: '',
        },
      },
      errors: {},
    });
    this.props.getRegionListAction();
    this.props.getCityListAction();
    this.props.getAreaListAction();
  };
  edit = e => {
    this.props.getPlaceByIdAction(e.target.value);
    this.setState({
      modalPlace: true,
      headerModal: ' Edit Place',
      errors: {},
      loadingModal: true,
    });
  };
  delete = (e, { value, name }) => {
    //this.props.deletePlaceAction(e.target.value);
    this.setState({
      openConfirm: true,
      placeId: value,
      contentConfirm: 'Are you sure want to delete ' + name + '?',
    });
  };
  handleConfirm = () => {
    const { placeId } = this.state;
    this.props.deletePlaceAction(placeId);
    this.setState({
      openConfirm: false,
    });
  };
  setStatePlace = data => {
    const { Country, City, Region } = data;
    let placeById = setPlaceIdObject(data);
    this.props.getRegionListAction(Country.Id ? Country.Id : '');
    this.props.getCityListAction(Region.Id ? Region.Id : '');
    this.props.getAreaListAction(City.Id ? City.Id : '');
    this.setState({ placeById: placeById });
  };
  onChange = (e, { value, name }) => {
    const placeById = this.state.placeById;
    placeById[name] = value;
    this.setState({ placeById: placeById });
  };
  onChangeAddress = (e, { value, name }) => {
    const AddresObjectPlace = this.state.placeById.AddressObject;
    AddresObjectPlace[name] = value;
    this.setState({
      AddresObjectPlace: AddresObjectPlace,
    });
    const getRegion = name === 'Country';
    const getCity = name === 'Region';
    const getArea = name === 'City';
    if (getRegion) {
      this.props.getRegionListAction(value);
    } else if (getCity) {
      this.props.getCityListAction(value);
    } else if (getArea) {
      this.props.getAreaListAction(value);
    }
    this.setState({
      placeById: {
        ...this.state.placeById,
        CityId: this.state.placeById.AddressObject.City,
        Address: this.state.placeById.AddressObject.Address1,
      },
    });
  };
  savePlace = () => {
    const err = this.validate();
    if (!err) {
      const Id = this.state.placeById.Id;
      const data = this.state.placeById;
      if (Id === '') {
        this.props.postPlaceAction(data);
      } else {
        this.props.putPlaceAction(Id, data);
      }
      //this.setState({ modalPlace: false });
      this.setState({ loadingModal: true });
    }
  };
  validate = () => {
    let isError = false;
    let { placeById } = this.state;
    let error1 = handleValidation(placeById, fieldToValidate);
    let error2 = handleValidation(placeById.AddressObject, fieldToValidate);
    let errors = { ...error1, ...error2 };
    isError = !_.isEmpty(errors);
    this.setState({
      errors: {
        ...errors,
      },
    });
    return isError;
  };

  handleChangePage = (e, { value }) => {
    this.setState({
      ...this.state,
      changePage: true,
      placeId: value ? value : 'new',
    });
  };
  render() {
    let {
      loadingPlace,
      failedPlace,
      modalConfirmation,
      changePage,
      placeId,
    } = this.state;
    if (changePage) {
      return <Redirect to={`/Place/${placeId}`} />;
    }
    let loading = loadingPlace || this.props.loading;
    let openModal = failedPlace || modalConfirmation;
    return (
      <div>
        <PlaceList
          data={this.state.placeList}
          type="placeList"
          filterBy="Name"
          buttonNew={this.handleChangePage}
          butttonEdit={this.handleChangePage}
          buttonDelete={this.delete}
          loading={loading}
        />
        <ModalAlert
          openModal={openModal}
          handleClose={this.handleClose}
          header={this.state.headerAlert}
          content={this.state.contentAlert}
          nameButton="OK"
        />
        <ModalPlace
          open={this.state.modalPlace}
          handleClose={this.handleCloseModal}
          header={this.state.headerModal}
          handleChange={this.onChange}
          handleChangeAddress={this.onChangeAddress}
          contentField={this.state.placeById}
          getPlaceType={this.props.getPlaceType}
          listCountry={this.props.listCountry}
          listRegion={this.props.listRegion}
          listCity={this.props.listCity}
          listArea={this.props.listArea}
          savePlace={this.savePlace}
          errorInformation={this.state.errors}
          loading={loading}
        />
        <ModalConfirm
          openModal={this.state.openConfirm}
          size="mini"
          close={this.handleCloseConfirm}
          confirm={this.handleConfirm}
          message={this.state.contentConfirm}
          header="Confirmation"
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    getPlace: state.PlaceStore.getPlaceList,
    getPlaceStatus: state.PlaceStore.getPlaceListStatus,
    getPlaceById: state.PlaceStore.getPlaceById,
    getPlaceByIdStatus: state.PlaceStore.getPlaceByIdStatus,
    deletePlaceStatus: state.PlaceStore.deletePlaceStatus,
    putPlaceStatus: state.PlaceStore.putPlaceStatus,
    postPlaceStatus: state.PlaceStore.postPlaceStatus,
    getPlaceType: state.PlaceStore.getPlaceType.map(type => {
      return { text: type, value: type, key: type };
    }),
    listCountry: state.CountryStore.getCountryList.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    listRegion: state.RegionStore.getRegionList.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    listCity: state.CityStore.getCityList.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    listArea: state.AreaStore.getAreaList.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    loading: state.PlaceStore.loading,
    errors: state.PlaceStore.errors,
  };
}
export default connect(
  mapStateToProps,
  {
    getPlaceListAction,
    resetPlaceAction,
    deletePlaceAction,
    getPlaceByIdAction,
    putPlaceAction,
    postPlaceAction,
    getCityListAction,
    getRegionListAction,
    getCountryListAction,
    getAreaListAction,
    getPlaceTypeAction,
  }
)(placePage);
