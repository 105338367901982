import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Divider } from 'semantic-ui-react';
import styles from '../../../../../../assets/styles/styleCreateTourTransaction';
import { convertDateFormat } from '../../../../../../scripts/itineraryBuilder/timeHelper';
import CollapseButton from '../../../../../../components/button/collapseButton';
import { Collapse } from 'react-collapse';

const setResourceStatusName = type => {
  switch (type) {
    case 'CREATED':
      return 'Created';
    case 'PARTIALBOOKING':
      return 'Partial Booking';
    case 'FULLBOOKED':
      return 'Full Booked';
    case 'CANCELED':
      return 'Canceled';
    default:
      return '-';
  }
};

const BookingItemByType = props => {
  const { data } = props;
  const [openCollapseDay, setOpenCollapseDay] = useState(false);

  const handleCollapseDay = () => {
    setOpenCollapseDay(!openCollapseDay);
  };

  return (
    <Grid verticalAlign="middle">
      <Grid.Row style={styles.marginDailyProgramDays}>
        <Grid.Column width={14}>
          <Header as="h3" style={styles.marginHeader1}>
            {data.ResourceTypeName}
          </Header>
        </Grid.Column>
        <Grid.Column width={2} textAlign="left">
          <CollapseButton
            isCollapse={openCollapseDay} //openCollapseDay
            handleCollapse={handleCollapseDay}
            name="openCollapseDay"
          />
        </Grid.Column>
      </Grid.Row>

      <Collapse isOpened={openCollapseDay} style={{ width: '70em' }}>
        <Grid style={styles.marginGridCollapse}>
          <Grid.Row style={styles.marginGridCollapse}>
            <Grid.Column width="6">
              <b>Name</b>
            </Grid.Column>
            <Grid.Column width="4">
              <b>Qty/Available</b>
            </Grid.Column>
            <Grid.Column width="3">
              <b>Due Date</b>
            </Grid.Column>
            <Grid.Column width="3">
              <b>Status</b>
            </Grid.Column>
          </Grid.Row>

          <Divider style={styles.noMarginTopBottom} />

          {data &&
            data.ResourceItems.map((item, idx) => {
              return (
                <Grid.Row
                  key={idx}
                  width={16}
                  style={{ marginLeft: '3em', marginBottom: '0px' }}
                >
                  <Grid.Column width="6">{item.ResourceName} </Grid.Column>
                  <Grid.Column width="4">
                    {item.Qty} Pax{' '}
                    {item.Ready ? `/ ${item.Ready} Available` : ''}{' '}
                  </Grid.Column>
                  <Grid.Column width="3">
                    {convertDateFormat(item.DueDate, 'DD MMMM YYYY')}{' '}
                  </Grid.Column>
                  <Grid.Column width="3">
                    {setResourceStatusName(item.ResourceStatus)}
                  </Grid.Column>
                </Grid.Row>
              );
            })}
        </Grid>
      </Collapse>
    </Grid>
  );
};

BookingItemByType.propTypes = {
  data: PropTypes.array,
};

export default BookingItemByType;
