// import { convertDateFormat } from '../../../../../../scripts/itineraryBuilder/timeHelper';

// const resourceType = [
//   'Accommodation',
//   'Attraction',
//   'Restaurant',
//   'Transportation',
// ];

const setResourceTypeName = type => {
  switch (type) {
    case 'Accommodation':
      return 'Accommodation';
    case 'Attraction':
      return 'Excursion';
    case 'Transportation':
      return 'Transportation';
    case 'Restaurant':
      return 'Restaurant';
    case 'Movement':
      return 'Movement';
    case 'TourGuide':
      return 'Tour Guide';
    case 'MeetingRoom':
      return 'Meeting Room';
    case 'Flight_Ticket':
      return 'Flight Ticket';
    default:
      return 'Others';
  }
};

export const restructureResourceBookingByResourceType = data => {
  let newData = data.reduce((result, current, idx) => {
    let findIndex = result.findIndex(
      res => res.ResourceType === current.ResourceType
    );
    if (idx === 0 || findIndex === -1) {
      let resourceItem = {
        ResourceTypeName: setResourceTypeName(current.ResourceType),
        ResourceType: current.ResourceType,
        ResourceItems: [
          {
            ...current,
          },
        ],
      };
      result.push(resourceItem);
    } else {
      result[findIndex].ResourceItems = result[findIndex].ResourceItems.concat(
        current
      );
    }
    return result;
  }, []);
  return newData;
};
