import React from 'react';
import PropTypes from 'prop-types';

const TotalDataTableLabel = props => {
  return (
    <div>
      {props.pagedData && props.pagedData.length > 1 && (
        <p>
          Showing {props.numbersFrom} to {props.numbersTo} of{' '}
          {props.data.length}
        </p>
      )}
    </div>
  );
};
TotalDataTableLabel.propTypes = {
  pagedData: PropTypes.array,
  numbersTo: PropTypes.number,
  numbersFrom: PropTypes.number,
  data: PropTypes.array,
};

export default TotalDataTableLabel;
