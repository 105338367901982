const setDataNewEmployee = companyId => {
  const data = {
    Id: '',
    UserId: '',
    FirstName: '',
    UserName: '',
    Address: '',
    Email: '',
    LastName: '',
    Gender: '',
    PhoneNbr: '',
    ImageUrl: '',
    AccountManagerProfileId: '',
    CompanyCode: companyId,
    CountryId: 'ID',
    IsInactive: false,
    // IsLocked: false,
    PositionId: '',
    Supervisor: null,
  };

  return data;
};

export default setDataNewEmployee;
