import React from 'react';
import { Menu, Sidebar, Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import AccordionMenu from './accordionMenu';
import Styles from './styles';
import {
  USER_ADMIN,
  PROFILE_MENU,
  USER_OPERATION,
  USER_SALES,
  USER_FINANCE,
  USER_FINANCE_AP,
  USER_FINANCE_AR,
  PROFILE_MENU_ADMIN,
} from './../../routes/linkSidebar';
import ModalConfirmTransaction from '../modal/modalConfirmationTwoButton';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  resetUserAccessAction,
  getUserRoleByBearerAction,
} from '../../actions/userAccessAction';
import Loader from '..//loader';
import { getUserProfileAction } from '../../actions/userAction';
import profilePicture from '../../assets/Icons/photo_profile.png';
import { getCompanyProfileAction } from '../../actions/companyAction';
import { checkUser } from './../../scripts/authorization';

const bgImage = imgUrl => {
  return {
    backgroundImage: `url(${imgUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '50px',
    width: '50px',
    marginLeft: '10px',
    marginRight: '20px',
    flex: 'none',
  };
};

class sidebar extends React.Component {
  state = {
    activeIndex: 0,
    openConfirm: false,
    isSales: null,
    roleOperation: null,
    roleFinance: null,
    roleFinanceAP: null,
    roleFinanceAR: null,
    activeIndexLevel2: 0,
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };
  handleClickLevel2 = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndexLevel2 } = this.state;
    const newIndex = activeIndexLevel2 === index ? -1 : index;
    this.setState({ activeIndexLevel2: newIndex });
  };
  static propTypes = {
    // sales: PropTypes.func,
    getUserRoleByBearerStatus: PropTypes.string,
    getUserRoleByBearer: PropTypes.object,
    resetUserAccessAction: PropTypes.func,
    getUserRoleByBearerAction: PropTypes.func,
    getUserProfileAction: PropTypes.func,
    getUserProfile: PropTypes.object,
    getCompanyProfileAction: PropTypes.func,
  };

  confirmLogout = () => {
    this.setState({
      openConfirm: false,
      statusRedirect: true,
    });
  };
  redirectLogout = () => {
    if (this.state.statusRedirect) {
      this.setState({ statusRedirect: false });
      return <Redirect to="/Logout" />;
    }
  };
  redirectEditProfile = () => {
    if (this.state.statusRedirectProfile) {
      this.setState({ statusRedirectProfile: false });
      return <Redirect to="/My-Account" />;
    }
  };
  redirectMyCompany = () => {
    if (this.state.statusRedirectCompany) {
      this.setState({ statusRedirectCompany: false });
      return <Redirect to="/Partner-Profile-Detail/edit/20000" />;
    }
  };
  handleClickConfirm = e => {
    if (e.target.textContent === 'My Account') {
      this.setState({
        statusRedirectProfile: true,
      });
    } else if (e.target.textContent === 'My Company') {
      this.setState({
        statusRedirectCompany: true,
      });
    } else {
      this.setState({
        openConfirm: true,
        messageConfirm: 'Are you sure to logout ? ',
      });
    }
  };
  handleCloseConfirm = () => {
    this.setState({
      openConfirm: false,
    });
  };
  componentDidMount() {
    this.props.getUserRoleByBearerAction();
    this.props.getUserProfileAction();
    this.props.getCompanyProfileAction();
  }

  componentDidUpdate() {
    if (this.props.getUserRoleByBearerStatus === 'success') {
      this.checkUser(this.props.getUserRoleByBearer.Roles);
      this.props.resetUserAccessAction();
    }
  }
  checkUser = code => {
    let authorization = checkUser(code);
    this.setState({
      ...this.state,
      isSales: authorization.isSales,
      roleOperation: authorization.isOperation,
      roleFinance: authorization.isFinance,
      roleFinanceAP: authorization.isFinanceAP,
      roleFinanceAR: authorization.isFinanceAR,
    });
    // if (code.length !== 0) {
    //   code.some(e => e === 'Package Sales')
    //     ? this.setState({ ...this.state, isSales: true })
    //     : code.some(e => e === 'Administrator') &&
    //       this.setState({ ...this.state, isSales: false });
    // }
  };
  renderSideBar = () => {
    const {
      activeIndex,
      isSales,
      activeIndexLevel2,
      roleOperation,
      roleFinance,
      roleFinanceAR,
      roleFinanceAP,
    } = this.state;
    if (
      isSales === null ||
      roleOperation === null ||
      roleFinance === null ||
      roleFinanceAR === null ||
      roleFinanceAP === null
    ) {
      return <Loader loading={true} />;
    } else if (
      !isSales &&
      !roleOperation &&
      !roleFinance &&
      !roleFinanceAP &&
      !roleFinanceAR
    ) {
      return (
        <div>
          <div>{this.redirectLogout()}</div>
          <div>{this.redirectEditProfile()}</div>
          <div>{this.redirectMyCompany()}</div>
          <Menu.Item as="a">
            <Link to="/Home">
              <Header as="h5" style={Styles.menu}>
                Home
              </Header>
            </Link>
          </Menu.Item>
          <Menu.Item as="a">
            <Link to="/tour-transaction">
              <Header as="h5" style={Styles.menu}>
                Tour Transaction
              </Header>
            </Link>
          </Menu.Item>
          {USER_ADMIN.map((menu, index) => (
            <Menu.Item key={index} style={Styles.topListMenu}>
              <AccordionMenu
                activeIndex={activeIndex}
                index={menu.index}
                handleClick={this.handleClick}
                title={menu.menu}
                menu={menu.submenu}
                dropdownStyle={Styles.iconDropdown}
                activeIndexLevel2={activeIndexLevel2}
                handleClickLevel2={this.handleClickLevel2}
              />
            </Menu.Item>
          ))}
        </div>
      );
    } else if (roleOperation) {
      return (
        <div>
          {this.redirectLogout()} {this.redirectEditProfile()}{' '}
          <Menu.Item as="a">
            <Link to="/Home">
              <Header as="h5" style={Styles.menu}>
                Home
              </Header>
            </Link>
          </Menu.Item>
          {USER_OPERATION.map((menu, index) => (
            <Menu.Item key={index} style={Styles.topListMenu}>
              <AccordionMenu
                activeIndex={activeIndex}
                index={menu.index}
                handleClick={this.handleClick}
                title={menu.menu}
                menu={menu.submenu}
                dropdownStyle={Styles.iconDropdown}
                activeIndexLevel2={activeIndexLevel2}
                handleClickLevel2={this.handleClickLevel2}
              />
            </Menu.Item>
          ))}
        </div>
      );
    } else if (roleFinance) {
      return (
        <div>
          {this.redirectLogout()} {this.redirectEditProfile()}{' '}
          <Menu.Item as="a">
            <Link to="/Home">
              <Header as="h5" style={Styles.menu}>
                Home
              </Header>
            </Link>
          </Menu.Item>
          <Menu.Item as="a">
            <Link to="/tour-transaction">
              <Header as="h5" style={Styles.menu}>
                Tour Transaction
              </Header>
            </Link>
          </Menu.Item>
          {roleFinanceAR && roleFinanceAP
            ? USER_FINANCE.map((menu, index) => (
                <Menu.Item key={index} style={Styles.topListMenu}>
                  <AccordionMenu
                    activeIndex={activeIndex}
                    index={menu.index}
                    handleClick={this.handleClick}
                    title={menu.menu}
                    menu={menu.submenu}
                    dropdownStyle={Styles.iconDropdown}
                    activeIndexLevel2={activeIndexLevel2}
                    handleClickLevel2={this.handleClickLevel2}
                  />
                </Menu.Item>
              ))
            : roleFinanceAR
            ? USER_FINANCE_AR.map((menu, index) => (
                <Menu.Item key={index} style={Styles.topListMenu}>
                  <AccordionMenu
                    activeIndex={activeIndex}
                    index={menu.index}
                    handleClick={this.handleClick}
                    title={menu.menu}
                    menu={menu.submenu}
                    dropdownStyle={Styles.iconDropdown}
                    activeIndexLevel2={activeIndexLevel2}
                    handleClickLevel2={this.handleClickLevel2}
                  />
                </Menu.Item>
              ))
            : roleFinanceAP &&
              USER_FINANCE_AP.map((menu, index) => (
                <Menu.Item key={index} style={Styles.topListMenu}>
                  <AccordionMenu
                    activeIndex={activeIndex}
                    index={menu.index}
                    handleClick={this.handleClick}
                    title={menu.menu}
                    menu={menu.submenu}
                    dropdownStyle={Styles.iconDropdown}
                    activeIndexLevel2={activeIndexLevel2}
                    handleClickLevel2={this.handleClickLevel2}
                  />
                </Menu.Item>
              ))}
        </div>
      );
    } else {
      return (
        <div>
          {this.redirectLogout()} {this.redirectEditProfile()}{' '}
          <Menu.Item as="a">
            <Link to="/Home">
              <Header as="h5" style={Styles.menu}>
                Home
              </Header>
            </Link>
          </Menu.Item>
          <Menu.Item as="a">
            <Link to="/tour-transaction">
              <Header as="h5" style={Styles.menu}>
                Tour Transaction
              </Header>
            </Link>
          </Menu.Item>
          {USER_SALES.map((menu, index) => (
            <Menu.Item key={index} style={Styles.topListMenu}>
              <AccordionMenu
                activeIndex={activeIndex}
                index={menu.index}
                handleClick={this.handleClick}
                title={menu.menu}
                menu={menu.submenu}
                dropdownStyle={Styles.iconDropdown}
                activeIndexLevel2={activeIndexLevel2}
                handleClickLevel2={this.handleClickLevel2}
              />
            </Menu.Item>
          ))}
        </div>
      );
    }
  };

  render() {
    const {
      activeIndex,
      isSales,
      roleOperation,
      roleFinance,
      roleFinanceAR,
      roleFinanceAP,
    } = this.state;
    const { getUserProfile } = this.props;
    let imageUrl = getUserProfile.ImageUrl
      ? getUserProfile.ImageUrl
      : profilePicture;
    return (
      <div>
        <Sidebar
          as={Menu}
          animation="push"
          icon="labeled"
          inverted
          vertical
          visible
          style={Styles.sidebar}
        >
          <Menu.Item style={Styles.headerTopListMenu}>
            <Link to="/Home">
              <Header as="h2" style={Styles.header}>
                Touress Admin
              </Header>
              <hr style={Styles.divider} />
            </Link>
          </Menu.Item>
          <Menu.Item style={Styles.topListMenu}>
            <AccordionMenu
              activeIndex={activeIndex}
              index={1}
              handleClick={this.handleClick}
              // title="Admin"
              title={
                getUserProfile.UserName !== null
                  ? getUserProfile.UserName
                  : 'Admin'
              }
              // icon="photo-profile-Icon round"
              icon={bgImage(imageUrl)}
              menu={
                !isSales &&
                !roleOperation &&
                !roleFinance &&
                !roleFinanceAP &&
                !roleFinanceAR
                  ? PROFILE_MENU_ADMIN
                  : PROFILE_MENU
              }
              dropdownStyle={Styles.iconDropdownProfile}
              handleClickConfirm={this.handleClickConfirm}
            />
            <hr style={Styles.divider} />
          </Menu.Item>
          {this.renderSideBar()}
        </Sidebar>
        <ModalConfirmTransaction
          openModal={this.state.openConfirm}
          size="mini"
          close={this.handleCloseConfirm}
          confirm={this.confirmLogout}
          message={this.state.messageConfirm}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    getUserRoleByBearer: state.UserAccessStore.getUserRoleByBearer,
    getUserRoleByBearerStatus: state.UserAccessStore.getUserRoleByBearerStatus,
    getUserProfile: state.UserStore.getUserProfile,
  };
}
export default connect(mapStateToProps, {
  resetUserAccessAction,
  getUserRoleByBearerAction,
  getUserProfileAction,
  getCompanyProfileAction,
})(sidebar);
//export default sidebar;
