import React from 'react';
import { Dimmer } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import ModalAlert from '../../../components/modal/modalAlert';
import Loader from '../../../components/loader';
import {
  postTransportationProfileAction,
  getTransportationUnitByTransportationUnitIdAction,
  getTransportationCatagoriesAction,
  putTransportationProfileAction,
  // getExportTransportationUnitAction,
  deleteTransportationUnitHeaderAction,
  getExportDataItemByIdAction,
} from '../../../pages/transportation/transportationAction';
import {
  resetTransportationAction,
  getTransportationProfileIdAction,
  postUploadImageTransportationProfileAction,
  getTemplateTransportationUnitAction,
} from '../../shared/actions/transportationAction';
import { getCityListAction } from '../../shared/actions/cityAction';
import { getRegionListAction } from '../../shared/actions/regionAction';
import { getCountryListAction } from '../../shared/actions/countryAction';
import { getAreaListAction } from '../../shared/actions/areaAction';
import TransportationProfile from './components/TransportationProfileForm';
import { rating } from '../../../scripts/rating';
//import ModalImage from '../../../components/modal/modalImage';
import ModalConfirm from '../../../components/modal/modalConfirmationTwoButton';
import {
  transportationProfileGeneralField,
  transportationProfileContactField,
} from './constant/fieldToValidate';
import handleValidation from '../../shared/services/validation/emptyFieldValidation';
import _ from 'lodash';

class TransportationProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transportationProfile: {
        Name: '',
        TransportationProfileCategoryId: '',
        TransportationProfileRatingId: '',
        AddressObject: {
          Address1: '',
          Address2: '',
          Address3: '',
          PostalCode: '',
          LandMark: '',
          PhoneNbr: '',
          Email: '',
          CityId: '',
          RegionId: '',
          AreaId: '',
          CountryId: '',
        },
      },
      TransportationProfileImage: [],
      idTransportationProfile: '',
      imagePreview: null,
      contentModal: '',
      transportationUnitList: [],
      loadingTransportation: true,
      failedTransportation: false,
      loadingTransportationUnit: true,
      failedTransportationUnit: false,
      openModalImage: false,
      errors: {},
      image: [],
      indexActive: 0,
      imageUrl: null,
      disablePage: false,
    };
  }
  static propTypes = {
    postTransportationStatus: PropTypes.string,
    getRegionListAction: PropTypes.func,
    getCityListAction: PropTypes.func,
    getAreaListAction: PropTypes.func,
    getCountryListAction: PropTypes.func,
    listCountry: PropTypes.array,
    listRegion: PropTypes.array,
    listCity: PropTypes.array,
    listArea: PropTypes.array,
    postTransportationProfileAction: PropTypes.func,
    getTransportationProfileIdAction: PropTypes.func,
    resetTransportationAction: PropTypes.func,
    getTransactionByProfileIdStatus: PropTypes.string,
    getTransactionByProfileId: PropTypes.array,
    getTransportationUnitByTransportationUnitIdAction: PropTypes.func,
    listTransportationUnit: PropTypes.array,
    listTransportationUnitStatus: PropTypes.string,
    getTransportationCatagoriesAction: PropTypes.func,
    listTransportationCatagories: PropTypes.array,
    putTransportationProfileAction: PropTypes.func,
    match: PropTypes.shape({
      params: PropTypes.string,
    }),
    getTemplateTransportationUnitAction: PropTypes.func,
    getExportDataItemByIdAction: PropTypes.func,
    postTransportation: PropTypes.array,
    getTransportationUnitTemplateStatus: PropTypes.func,
    getTransportationUnitTemplate: PropTypes.string,
    getTransportationUnitExportStatus: PropTypes.string,
    getTransportationUnitExport: PropTypes.string,
    loadingTransportationStore: PropTypes.bool,
    postUploadImageTransportationProfileAction: PropTypes.func,
    putTransportationStatus: PropTypes.string,
    deleteTransportationUnitHeaderAction: PropTypes.func,
    deleteTransportationHeaderStatus: PropTypes.string,
    errorMessages: PropTypes.string,
    errorMessagesPage: PropTypes.string,
    postUploadImageTransportationProfileStatus: PropTypes.string,
    loadingPostImage: PropTypes.bool,
  };
  onChange = (e, { value, name }) => {
    const transportationProfile = this.state.transportationProfile;
    transportationProfile[name] = value;
    transportationProfile['onChange'] = true;
    this.setState({ transportationProfile: transportationProfile });
    const getRegion = name === 'country';
    const getCity = name === 'region';
    const getArea = name === 'city';
    if (getRegion) {
      this.props.getRegionListAction(value);
    } else if (getCity) {
      this.props.getCityListAction(value);
    } else if (getArea) {
      this.props.getAreaListAction(value);
    }
    this.validate();
  };

  onChangeDropdown = (e, { value, name }) => {
    const addressObject = this.state.transportationProfile.AddressObject;
    addressObject[name] = value;
    this.setState({
      addressObject: addressObject,
      transportationProfile: {
        ...this.state.transportationProfile,
        onChange: true,
      },
    });
    const getRegion = name === 'CountryId';
    const getCity = name === 'RegionId';
    const getArea = name === 'CityId';
    if (getRegion) {
      this.props.getRegionListAction(value);
    } else if (getCity) {
      this.props.getCityListAction(value);
    } else if (getArea) {
      this.props.getAreaListAction(value);
    }
    this.validate();
  };
  handleClose = () =>
    this.setState({
      openModalConfirmation: false,
      failedTransportation: false,
      failedTransportationUnit: false,
      openConfirm: false,
    });
  handleCloseModalImage = () => this.setState({ openModalImage: false });
  componentDidMount = () => {
    this.props.getCountryListAction();
    this.getTransportationProfileId();
    this.props.getTransportationCatagoriesAction();
  };

  getTransportationProfileId = () => {
    const { ProfileId } = this.props.match.params;
    if (ProfileId === undefined) {
      this.setState({
        loadingTransportation: false,
        loadingTransportationUnit: false,
        titleHeader: 'Add Transportation',
        contentButton: 'Save',
        disablePage: false,
      });
    } else {
      return this.getDataTransportation(ProfileId);
    }
  };

  getDataTransportation = id => {
    this.props.getTransportationProfileIdAction(id);
    this.props.getTransportationUnitByTransportationUnitIdAction(id);
    const { Type } = this.props.match.params;
    this.setState({
      idTransportationProfile: id,
      titleHeader:
        Type === 'Detail' ? 'Detail Transportation' : 'Edit Transportation',
      contentButton: Type === 'Detail' ? 'Edit' : 'Save',
      disablePage: Type === 'Detail',
    });
  };

  async load(Id) {
    const { TransportationProfileImage } = this.state;
    await this.props.postUploadImageTransportationProfileAction(
      this.props.postTransportation.Id,
      {
        ImageContent: TransportationProfileImage.ImageContent,
        ImageName: TransportationProfileImage.ImageName,
        TransportationProfileId: this.props.postTransportation.Id,
        IsPrimaryImage: true,
      }
    );
    await this.getDataTransportation(Id);
  }
  componentDidUpdate() {
    if (this.props.getTransactionByProfileIdStatus === 'success') {
      this.setStateTransaportationProfile(this.props.getTransactionByProfileId);
      this.setState({ loadingTransportation: false });
      this.props.resetTransportationAction();
    } else if (this.props.getTransactionByProfileId === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorMessages,
        failedTransportation: true,
        loadingTransportation: false,
      });
      this.props.resetTransportationAction();
    }
    if (this.props.listTransportationUnitStatus === 'success') {
      this.setState({
        transportationUnitList: this.props.listTransportationUnit,
        loadingTransportationUnit: false,
      });
      this.props.resetTransportationAction();
    } else if (this.props.listTransportationUnitStatus === 'failed') {
      this.setState({
        failedTransportationUnit: true,
        loadingTransportationUnit: false,
        failedAccomodation: true,
        headerAlert: 'Failed',
        contentAlert: this.props.errorMessages,
      });
      this.props.resetTransportationAction();
    }
    if (this.props.listTransportationUnitStatus === 'success') {
      this.setState({
        transportationUnitList: this.props.listTransportationUnit,
        loadingTransportationUnit: false,
      });
      this.props.resetTransportationAction();
    } else if (this.props.listTransportationUnitStatus === 'failed') {
      this.setState({
        failedTransportationUnit: true,
        loadingTransportationUnit: false,
        failedAccomodation: true,
        headerAlert: 'Failed',
        contentAlert: this.props.errorMessages,
      });
      this.props.resetTransportationAction();
    }
    if (this.props.postTransportationStatus === 'success') {
      this.setState({
        headerAlert: 'Success Saving ',
        contentAlert: 'Saving Transportation Profile',
        openModalConfirmation: true,
        loadingTransportation: false,
      });

      this.load(this.props.postTransportation.Id);
      //eslint-disable-next-line
      this.props.history.push(
        `/Transportation-Profile/Edit/${this.props.postTransportation.Id}`
      );
      this.componentDidMount();
      this.props.resetTransportationAction();
    } else if (this.props.postTransportationStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorMessages
          ? this.props.errorMessages
          : this.props.errorMessagesPage,
        openModalConfirmation: true,
        failedTransportation: true,
        loadingTransportation: false,
      });
      this.props.resetTransportationAction();
    }

    if (this.props.getTransportationUnitTemplateStatus === 'success') {
      var templateTransportation = this.props.getTransportationUnitTemplate;
      var elemenTemplateTransportation = document.createElement('a');
      var TemplateTransportation = new Blob([templateTransportation], {
        type: 'text/plain',
      });
      elemenTemplateTransportation.href = URL.createObjectURL(
        TemplateTransportation
      );
      elemenTemplateTransportation.download =
        'Transportation Unit Template.txt';
      elemenTemplateTransportation.click();
      this.props.resetTransportationAction();
    } else if (this.props.getTransportationUnitTemplateStatus === 'failed') {
      this.props.resetTransportationAction();
    }

    if (this.props.getTransportationUnitExportStatus) {
      var exportTransportation = this.props.getTransportationUnitExport;
      var elemenExportTransportation = document.createElement('a');
      var ExportTransportation = new Blob([exportTransportation], {
        type: 'text/plain',
      });
      elemenExportTransportation.href = URL.createObjectURL(
        ExportTransportation
      );
      elemenExportTransportation.download = 'Transportation Unit Export.txt';
      elemenExportTransportation.click();
      this.props.resetTransportationAction();
    } else if (this.props.getTransportationUnitExportStatus) {
      this.props.resetTransportationAction();
    }

    if (this.props.putTransportationStatus === 'success') {
      this.setState({
        headerAlert: 'Success Saving ',
        contentAlert: 'Edit Transportation Profile',
        openModalConfirmation: true,
        loadingTransportation: false,
      });
      const { ProfileId } = this.props.match.params;
      this.props.getTransportationProfileIdAction(ProfileId);
      this.props.getTransportationUnitByTransportationUnitIdAction(ProfileId);
      //eslint-disable-next-line
      this.props.history.push(`/Transportation-Profile/Edit/${ProfileId}`);
      this.componentDidMount();
      this.props.resetTransportationAction();
    } else if (this.props.putTransportationStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorMessages
          ? this.props.errorMessages
          : this.props.errorMessagesPage,
        openModalConfirmation: true,
        failedTransportation: true,
        loadingTransportation: false,
      });
      this.props.resetTransportationAction();
    }

    if (this.props.deleteTransportationHeaderStatus === 'success') {
      this.setState({
        headerAlert: 'Success ',
        contentAlert: 'Transportation Unit Deleted',
        openModalConfirmation: true,
        loadingTransportation: false,
      });
      const { ProfileId } = this.props.match.params;
      this.props.getTransportationProfileIdAction(ProfileId);
      this.props.getTransportationUnitByTransportationUnitIdAction(ProfileId);
      //eslint-disable-next-line
      this.props.history.push(`/Transportation-Profile/Edit/${ProfileId}`);
      this.componentDidMount();
      this.props.resetTransportationAction();
    } else if (this.props.deleteTransportationHeaderStatus === 'failed') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: this.props.errorMessages,
        openModalConfirmation: true,
        failedTransportation: true,
        loadingTransportation: false,
      });
      this.props.resetTransportationAction();
    }
    if (this.props.postUploadImageTransportationProfileStatus === 'success') {
      const { ProfileId } = this.props.match.params;
      this.props.getTransportationProfileIdAction(ProfileId);
      this.props.resetTransportationAction();
    }
  }
  validate = () => {
    let isError = false;
    const errors1 = handleValidation(
      this.state.transportationProfile,
      transportationProfileGeneralField
    );
    const errors2 = handleValidation(
      this.state.transportationProfile.AddressObject,
      transportationProfileContactField
    );
    let errors = { ...errors1, ...errors2 };
    // let errors = { ...errors1 };
    isError = !_.isEmpty(errors);
    this.setState({
      errors: errors,
    });
    return isError;
  };

  saveTransportation = (e, { name }) => {
    if (name === 'Edit') {
      this.setState({
        titleHeader: 'Edit Transportation',
        contentButton: 'Save',
        disablePage: false,
      });
    } else {
      const err = this.validate();
      const { AddressObject, CompanyCode } = this.state.transportationProfile;
      const { TransportationProfileImage } = this.state;
      let data = {
        ...this.state.transportationProfile,
        CompanyCode: CompanyCode ? CompanyCode : 10000,
        AreaId: AddressObject.AreaId,
        CityId: AddressObject.CityId,
        RegionId: AddressObject.RegionId,
        CountryId: AddressObject.CountryId,
        Telephone: AddressObject.PhoneNbr,
        Email: AddressObject.Email,
      };

      if (!err) {
        this.setState({
          loadingTransportation: true,
        });
        const Id = this.state.idTransportationProfile;
        if (Id) {
          this.props.putTransportationProfileAction(Id, data);
          if (this.state.isChangeImage) {
            this.props.postUploadImageTransportationProfileAction(
              Id,
              TransportationProfileImage
            );
          }
        } else {
          this.props.postTransportationProfileAction(data);
        }
      }
    }
  };
  setStateTransaportationProfile = data => {
    const {
      Id,
      Name,
      Telephone,
      Email,
      CompanyId,
      TransportationProfileImage,
      AddressObject,
      TransportationProfileCategory,
      TransportationProfileRating,
      ImageUrl,
    } = data;
    let transportationProfile = {
      CompanyId: CompanyId ? CompanyId : '',
      Id: Id ? Id : '',
      Name: Name ? Name : '',
      Address: AddressObject.AddressString ? AddressObject.AddressString : '',
      Telephone: Telephone ? Telephone : '',
      Email: Email ? Email : '',
      ImageUrl: ImageUrl ? ImageUrl : '',
      CityId: AddressObject.City.Id ? AddressObject.City.Id : '',
      RegionId: AddressObject.Region.Id ? AddressObject.Region.Id : '',
      AddressObject: {
        Address1: AddressObject.Address1 ? AddressObject.Address1 : '',
        Address2: AddressObject.Address2 ? AddressObject.Address2 : '',
        Address3: AddressObject.Address3 ? AddressObject.Address3 : '',
        AreaId: AddressObject.AreaId ? AddressObject.AreaId : '',
        PostalCode: AddressObject.PostalCode ? AddressObject.PostalCode : '',
        Landmark: AddressObject.Landmark ? AddressObject.Landmark : '',
        CityId: AddressObject.City.Id ? AddressObject.City.Id : '',
        RegionId: AddressObject.Region.Id ? AddressObject.Region.Id : '',
        CountryId: AddressObject.Country.Id ? AddressObject.Country.Id : '',
        PhoneNbr: Telephone ? Telephone : '',
        Phone: Telephone ? Telephone : '',
        Email: Email ? Email : '',
      },
      TransportationProfileRatingId: String(
        TransportationProfileRating.TransportationProfileRatingId
      )
        ? String(TransportationProfileRating.TransportationProfileRatingId)
        : 0,
      TransportationProfileCategoryId: TransportationProfileCategory.TransportationProfileCategoryId
        ? TransportationProfileCategory.TransportationProfileCategoryId
        : 0,
    };
    let Images = {
      ImageContent: TransportationProfileImage.ImageContent,
      ImageName: TransportationProfileImage.ImageName,
      TransportationProfileId: TransportationProfileImage.ImageId,
      IsPrimaryImage: TransportationProfileImage.IsPrimaryImage,
      ImageUrl: TransportationProfileImage.ImageUrl,
      TinyImageUrl: TransportationProfileImage.TinyImageUrl,
    };
    this.props.getRegionListAction(
      AddressObject.Country.Id ? AddressObject.Country.Id : ''
    );
    this.props.getCityListAction(AddressObject.Region.Id);
    this.props.getAreaListAction(AddressObject.City.Id);
    this.setState({
      transportationProfile: transportationProfile,
      TransportationProfileImage: Images,
      ImageId: TransportationProfileImage.ImageId,
    });
  };
  ButtonNewTransportationUnit = () => {};
  onClickBack = () => {
    // eslint-disable-next-line
    this.props.history.push('/Transportation');
  };
  onClcikTransportationUnit = () => {
    const { Id, AddressObject } = this.state.transportationProfile;
    if (this.state.idTransportationProfile === '') {
      this.setState({
        headerAlert: 'Failed',
        contentAlert: 'Please Save Transportation Profile First',
        openModalConfirmation: true,
      });
    } else {
      // eslint-disable-next-line
      this.props.history.push(
        '/Transportation-Unit/by-ProfileId=' +
          Id +
          '/by-Country=' +
          AddressObject.CountryId
      );
    }
  };
  modalImage = () => {
    this.setState({
      contentModal: 'Change Image',
      openModalImage: true,
      dataItem: [],
      imageUrl: null,
      imageTemp: {
        ImageContent: null,
        ImageName: null,
        ImagePreview: null,
      },
    });
  };
  deleteImage = () => {
    let { idTransportationProfile } = this.state;
    this.setState({
      TransportationProfileImage: {
        ImageId: '',
        ImageContent: '',
        ImagePreview: null,
        ImageName: '',
        IsPrimaryImage: true,
        TransportationProfileId: idTransportationProfile,
      },
      imagePreview: null,
    });
  };
  handleAddImage = e => {
    let { idTransportationProfile, ImageId } = this.state;
    e.map(base => {
      return this.setState({
        TransportationProfileImage: {
          ImageId: ImageId,
          ImageContent: base.base64.split(',')[1],
          ImagePreview: base.base64,
          ImageName: base.file.name,
          IsPrimaryImage: true,
          TransportationProfileId: idTransportationProfile
            ? idTransportationProfile
            : 0,
        },
        transportationProfile: {
          ...this.state.transportationProfile,
          onChange: true,
        },
      });
    });
  };
  handleChangeImage = e => {
    let transportation = this.state.TransportationProfileImage;
    let reader = new FileReader();
    let files = e.target.files[0];
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      let image = {
        ...transportation,
        ImageName: files.name,
        ImageContent: reader.result.split(',')[1],
        ImagePreview: reader.result,
        ImageUrl: reader.result,
      };
      this.setState({
        TransportationProfileImage: image,
        isChangeImage: true,
      });
    };
  };

  ButtonUploadDataTransportationUnit = () => {
    // eslint-disable-next-line
    this.props.history.push(`/Upload-Master-Data/${'TransportationUnit'}`);
  };
  ButtonDownloadTemplateTransportationUnit = () => {
    this.props.getTemplateTransportationUnitAction();
  };
  ButtonExportTransportationUnit = () => {
    const { ProfileId } = this.props.match.params;
    this.props.getExportDataItemByIdAction(
      'TransportationUnitHeaders',
      ProfileId
    );
  };
  buttonEdit = (e, { value }) => {
    const { transportationProfile } = this.state;
    // eslint-disable-next-line
    this.props.history.push(
      `/Transportation-Unit/Edit/by-ProfileId=${transportationProfile.Id}/by-Country=${transportationProfile.AddressObject.CountryId}/by-UnitId=${value}`
    );
  };
  buttonDelete = () => {
    const { deletedId } = this.state;
    this.setState({ loadingTransportation: true, openConfirm: false });
    this.props.deleteTransportationUnitHeaderAction(deletedId);
  };

  handleConfirmDelete = (e, { value, name }) => {
    this.setState({
      openConfirm: true,
      messageConfirm: ' Are you sure to delete transportation ' + name + ' ?',
      deletedId: value,
    });
  };

  render() {
    let {
      loadingTransportation,
      loadingTransportationUnit,
      failedTransportation,
      failedTransportationUnit,
      openModalConfirmation,
      openConfirm,
      messageConfirm,
    } = this.state;
    const { loadingTransportationStore } = this.props;
    let loading =
      loadingTransportation ||
      loadingTransportationUnit ||
      loadingTransportationStore;
    let openModal =
      failedTransportation || failedTransportationUnit || openModalConfirmation;
    if (this.state.redirectUnit) {
      const { idTransportationProfile } = this.state;
      const { CountryId } = this.state.transportationProfile.AddressObject;
      this.setState({ redirectUnit: false });
      return (
        <Redirect
          to={
            '/Transportation-Unit/by-ProfileId=' +
            idTransportationProfile +
            '/by-Country=' +
            CountryId
          }
        />
      );
    }
    return (
      <div>
        <Dimmer.Dimmable blurring dimmed={loading}>
          <Loader loading={loading} />
          <TransportationProfile
            handleChange={this.onChange}
            handleChangeContactInfo={this.onChangeDropdown}
            newTransportationUnit={this.onClcikTransportationUnit}
            listCountry={this.props.listCountry}
            listArea={this.props.listArea}
            listRegion={this.props.listRegion}
            listCity={this.props.listCity}
            rating={rating}
            transportationData={this.state.transportationProfile}
            modalImage={this.modalImage}
            removeImage={this.deleteImage}
            imagePreview={this.state.imagePreview}
            listTransportationUnit={this.state.transportationUnitList}
            listTransportationCatagories={
              this.props.listTransportationCatagories
            }
            saveTransportation={this.saveTransportation}
            backPage={this.onClickBack}
            title={this.state.titleHeader}
            errors={this.state.errors}
            exportTransportationUnit={this.ButtonExportTransportationUnit}
            uploadTransportationUnit={this.ButtonUploadDataTransportationUnit}
            templateTransportationUnit={
              this.ButtonDownloadTemplateTransportationUnit
            }
            loadingTransportation={loadingTransportationStore}
            contentButton={this.state.contentButton}
            disablePage={this.state.disablePage}
            logoTransportation={this.state.TransportationProfileImage}
            buttonEdit={this.buttonEdit}
            //buttonDelete={this.buttonDelete}
            buttonDelete={this.handleConfirmDelete}
            handleChangeImage={this.handleChangeImage}
            loading={loading}
          />
          <ModalAlert
            openModal={openModal}
            handleClose={this.handleClose}
            header={this.state.headerAlert}
            content={this.state.contentAlert}
            nameButton="OK"
          />
          {/* <ModalImage
            openModal={this.state.openModalImage}
            handleClose={this.handleCloseModalImage}
            content={this.state.contentModal}
            imagePreview={this.state.imagePreview}
            handleChange={this.handleAddImage}
            addImage={this.handleCloseModalImage}
            listStepImage={this.state.listStepImage}
          /> */}
          <ModalConfirm
            openModal={openConfirm}
            size="mini"
            close={this.handleClose}
            confirm={this.buttonDelete}
            message={messageConfirm}
          />
        </Dimmer.Dimmable>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    listCountry: state.CountryStore.getCountryList.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    listRegion: state.RegionStore.getRegionList.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    listCity: state.CityStore.getCityList.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    listArea: state.AreaStore.getAreaList.map(type => {
      return { text: type.Name, value: type.Id, key: type.Id };
    }),
    postTransportationStatus:
      state.TransportationPageStore.postTransportationStatus, //Page
    postTransportation: state.TransportationPageStore.postTransportation, //Page
    getTransactionByProfileId:
      state.TransportationStore.getTransportationByProfileId,
    getTransactionByProfileIdStatus:
      state.TransportationStore.getTransportationByProfileIdStatus,
    listTransportationUnit:
      state.TransportationPageStore.getTransportationUnitByProfileId, //Page
    listTransportationUnitStatus:
      state.TransportationPageStore.getTransportationUnitByProfileIdStatus, //Page
    listTransportationCatagories: state.TransportationPageStore.getTransportationCatagoriesList.map(
      type => {
        return { text: type.Name, value: type.Id, key: type.Id };
      }
    ),
    putTransportationStatus:
      state.TransportationPageStore.putTransportationStatus, //Page
    getTransportationUnitExport:
      state.TransportationPageStore.getExportDataItemById, //Page
    getTransportationUnitTemplate:
      state.TransportationStore.getTransportationUnitTemplate,
    getTransportationUnitExportStatus:
      state.TransportationPageStore.getExportDataItemByIdStatus, //Page
    getTransportationUnitTemplateStatus:
      state.TransportationStore.getTransportationUnitTemplateStatus,
    loadingTransportationStore: state.TransportationStore.loading,
    deleteTransportationHeaderStatus:
      state.TransportationPageStore.deleteTransportationHeaderStatus, //Page
    errorMessages: state.TransportationStore.errors.global,
    errorMessagesPage: state.TransportationPageStore.errors.global,
    postUploadImageTransportationProfileStatus:
      state.TransportationPageStore.postUploadImageTransportationProfileStatus,
    loadingPostImage: state.TransportationPageStore.loadingPostImage,
  };
}
export default connect(mapStateToProps, {
  getCityListAction,
  getRegionListAction,
  getCountryListAction,
  getAreaListAction,
  postTransportationProfileAction,
  getTransportationProfileIdAction,
  resetTransportationAction,
  getTransportationUnitByTransportationUnitIdAction,
  getTransportationCatagoriesAction,
  putTransportationProfileAction,
  getTemplateTransportationUnitAction,
  getExportDataItemByIdAction,
  postUploadImageTransportationProfileAction,
  deleteTransportationUnitHeaderAction,
})(TransportationProfilePage);
