import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form, Grid } from 'semantic-ui-react';
import ButtonClose from '../../../../../components/button/buttonCloseModal';
import InputWithLabel from '../../../../../components/input/inputWithLabel';
import CurrencyFormat from 'react-currency-format';
const ModalTourGuidePrice = props => (
  <Modal
    dimmer="blurring"
    inverted
    open={props.openModal}
    onClose={props.handleClose}
    size="small"
    closeOnDimmerClick={false}
  >
    <Modal.Header>
      Tour Guide Price <ButtonClose onClose={props.handleClose} />
    </Modal.Header>
    <Modal.Content>
      <Grid columns={2} padded="vertically">
        <Grid.Column>
          <Form>
            <InputWithLabel
              inline={false}
              label="Language"
              placeholder="Language"
              type="select"
              required
              name="TourGuideLanguage"
              value={props.objectPrice.TourGuideLanguage}
              options={props.listLanguage}
              handleChange={props.handleChange}
              validate={props.errors}
            />
          </Form>
          <Form>
            <InputWithLabel
              inline={false}
              label="Start Date"
              placeholder="Start Date"
              type="date"
              required
              name="StartDate"
              value={props.objectPrice.StartDate}
              handleChange={props.handleChange}
              validate={props.errors}
            />
          </Form>
          <Form>
            <Form.Field>
              <label>Price</label>
              <CurrencyFormat
                // thousandSeparator={true}
                thousandSeparator={'.'}
                decimalSeparator={','}
                decimalScale={2}
                value={props.objectPrice.Cost}
                textAlign="right"
                name={'Cost'}
                id={'Cost'}
                // customInput={Input}
                onValueChange={e => props.handleChangePrice('Cost', e.value)}
              />
            </Form.Field>
          </Form>
          <Form>
            <InputWithLabel
              inline={false}
              type="number"
              label="Hours"
              required
              value={props.objectPrice.Hours}
              name="Hours"
              placeholder="Hours"
              handleChange={props.handleChange}
              validate={props.errors}
            />
          </Form>
        </Grid.Column>
        <Grid.Column>
          <Form>
            <InputWithLabel
              inline={false}
              type="select"
              label="Currency"
              placeholder="Currency"
              name="Currency"
              required
              value={props.objectPrice.Currency}
              options={props.currencies}
              handleChange={props.handleChange}
              validate={props.errors}
            />
          </Form>

          <Form>
            <InputWithLabel
              inline={false}
              label="End Date"
              placeholder="End Date"
              type="date"
              required
              name="EndDate"
              value={props.objectPrice.EndDate}
              handleChange={props.handleChange}
              validate={props.errors}
            />
          </Form>
          <Form>
            <InputWithLabel
              inline={false}
              type="number"
              name="CutOffDay"
              label="Cut Off Day"
              value={props.objectPrice.CutOffDay}
              required
              placeholder="Cut Off Day"
              handleChange={props.handleChange}
              // validate={props.errors}
            />
          </Form>
        </Grid.Column>
      </Grid>
    </Modal.Content>
    <Modal.Actions>
      <Button positive onClick={props.handleSave} content="Save" />
    </Modal.Actions>
  </Modal>
);

ModalTourGuidePrice.propTypes = {
  openModal: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  objectPrice: PropTypes.object,
  nameButton: PropTypes.string,
  isArrayContentAlert: PropTypes.bool,
  currencies: PropTypes.array,
  handleChangePrice: PropTypes.func,
  handleChange: PropTypes.func,
  handleSave: PropTypes.func,
  listLanguage: PropTypes.array,
  errors: PropTypes.object,
};

export default ModalTourGuidePrice;
