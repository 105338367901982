import React from 'react';
import { Table, Checkbox, Pagination } from 'semantic-ui-react';
import ActionButton from '../../../../components/common/buttonPackageInTable';
import {
  HeaderTableReadyPackage,
  HeaderTableSeriesPackage,
} from '../../../../components/table/headerTabel';
import PropTypes from 'prop-types';
import Loader from '../../../../components/loader';
// import { StyleButton } from '../../common/styleCommon';
import { Link } from 'react-router-dom';
import moment from 'moment';
const TablePackageList = props => (
  <div>
    <Loader loading={props.loading} />
    <Table size="small" basic="very">
      <Table.Header>
        <Table.Row>
          {props.isOperation || props.isSales || (
            <Table.HeaderCell textAlign="center">
              <Checkbox
                onChange={props.handleChangeCheck}
                name={{
                  Status: props.listData
                    ? props.listData[0].IsPublished
                      ? true
                      : false
                    : false,
                  Id: 'All',
                }}
              />
            </Table.HeaderCell>
          )}
          {props.typePackage === 'Fixed'
            ? HeaderTableSeriesPackage.map((headTableContent, index) => {
                return (
                  <Table.HeaderCell textAlign="center" key={index}>
                    {headTableContent}
                  </Table.HeaderCell>
                );
              })
            : HeaderTableReadyPackage.map((headTableContent, index) => {
                return (
                  <Table.HeaderCell textAlign="center" key={index}>
                    {headTableContent}
                  </Table.HeaderCell>
                );
              })}
          {props.isSales || (
            <Table.HeaderCell textAlign="center">Action</Table.HeaderCell>
          )}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {props.listData ? (
          props.listData.map((data, idx) => {
            return (
              <Table.Row key={idx}>
                {props.isOperation || props.isSales || (
                  <Table.Cell textAlign="center">
                    <Checkbox
                      key={idx}
                      name={{ Status: data.IsPublished, Id: data.Id }}
                      checked={data.IsCheck ? true : false}
                      onChange={props.handleChangeCheck}
                    />
                  </Table.Cell>
                )}
                <Table.Cell textAlign="center">{data.Id}</Table.Cell>
                <Table.Cell width={4} style={{ maxWidth: '50px' }}>
                  <Link
                    to={
                      props.isOperation
                        ? `/simple-Package-Detail/${data.PackageType}/${data.Id}`
                        : props.isSales
                        ? `/simple-package-information/${data.PackageType}/${data.Id}`
                        : data.PackageType === 'Fixed'
                        ? `/Package-Details/Fixed/edit/${data.Id}`
                        : data.PackageType === 'FixedDateVariable'
                        ? `/Package-Details/FixedDateVariable/edit/${data.Id}`
                        : `/Package-Details/Ready/edit/${data.Id}`
                    }
                  >
                    {data.Title}
                  </Link>
                </Table.Cell>
                {data.PackageType === 'Fixed' || (
                  <Table.Cell textAlign="center">
                    {data.PackageType === 'Ready'
                      ? `No`
                      : data.PackageType === 'FixedDateVariable'
                      ? `Yes`
                      : '-'}
                  </Table.Cell>
                )}
                {props.typePackage === 'Fixed' && (
                  <Table.Cell textAlign="center">
                    {data.PackageType === 'Fixed'
                      ? data.FixedPackage.RegisteringGuest
                      : data.PackageType === 'FixedDateVariable'
                      ? data.VariableDatePackage.RegisteringGuest
                      : 0}
                  </Table.Cell>
                )}
                {props.typePackage === 'Fixed' && (
                  <Table.Cell textAlign="center">
                    {data.PackageType === 'Fixed'
                      ? Number(data.FixedPackage.ConfirmedGuest)
                      : data.PackageType === 'FixedDateVariable'
                      ? Number(data.VariableDatePackage.ConfirmedGuest)
                      : 0}
                  </Table.Cell>
                )}
                {props.typePackage === 'Fixed' && (
                  <Table.Cell textAlign="center">
                    {data.PackageType === 'Fixed'
                      ? Number(data.FixedPackage.MinimumGuest) -
                        Number(data.FixedPackage.ConfirmedGuest)
                      : data.PackageType === 'FixedDateVariable'
                      ? Number(data.VariableDatePackage.MinimumGuest) -
                        Number(data.VariableDatePackage.ConfirmedGuest)
                      : 0}
                  </Table.Cell>
                )}

                <Table.Cell textAlign="center">{data.Destination}</Table.Cell>
                <Table.Cell textAlign="center">
                  {moment(data.StartDate).format('DD/MM/YYYY')}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {moment(data.EndDate).format('DD/MM/YYYY')}
                </Table.Cell>
                {props.isSales || (
                  <Table.Cell width="3" textAlign="center">
                    <ActionButton
                      data={data}
                      popupContent={props.popupContent}
                      buttonDelete={props.deletePackage}
                      buttonPublished={props.postActionPublish}
                      buttonCopy={props.copyPackage}
                      buttonEdit={props.openDetail}
                      statusDelete={
                        data.IsPublished ||
                        (data.PackageType === 'Fixed'
                          ? data.FixedPackage.ConfirmedGuest > 0
                            ? true
                            : false
                          : false)
                      }
                      isSharingRoom={data.SharingRoomPrice === 0}
                      isOperation={props.isOperation}
                      isSales={props.isSales}
                    />
                  </Table.Cell>
                )}
              </Table.Row>
            );
          })
        ) : (
          <Table.Row>
            <Table.Cell colSpan="9" textAlign="center">
              No Data
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
    {props.pagedData && props.pagedData.length > 1 && (
      <Pagination
        totalPages={props.pagedData.length}
        onPageChange={props.pageChange}
        activePage={props.activePage}
        floated="right"
        siblingRange={1}
      />
    )}
    {props.pagedData && props.pagedData.length > 1 && (
      <p>
        Showing {props.numbersTo} to {props.numbersFrom} of
        {props.data.length}
      </p>
    )}
  </div>
);

TablePackageList.propTypes = {
  listData: PropTypes.array,
  headerTable: PropTypes.array,
  buttonEdit: PropTypes.func,
  buttonCopy: PropTypes.func,
  deletePackage: PropTypes.func,
  postActionPublish: PropTypes.func,
  openDetail: PropTypes.func,
  pagedData: PropTypes.array,
  activePage: PropTypes.number,
  pageChange: PropTypes.func,
  handleChangeCheck: PropTypes.func,
  copyPackage: PropTypes.func,
  numbersFrom: PropTypes.number,
  numbersTo: PropTypes.number,
  data: PropTypes.array,
  popupContent: PropTypes.object,
  typePackage: PropTypes.string,
  loading: PropTypes.bool,
  isOperation: PropTypes.bool,
  isSales: PropTypes.bool,
};
export default TablePackageList;
