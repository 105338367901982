import apiClient from '../apiClient';
import {
  GET_TOUR_OPERATOR_PROFILE_BY_ID_URL,
  GET_LIST_TOUR_OPERATOR_URL,
} from '../apiUrl';
// GET_LIST_TOUR_OPERATOR
const getTourOperatorByIdApi = Id => {
  return apiClient.get(GET_TOUR_OPERATOR_PROFILE_BY_ID_URL(Id));
};

const getListTourOperatorApi = data => {
  return apiClient.post(GET_LIST_TOUR_OPERATOR_URL, data);
};

export { getTourOperatorByIdApi, getListTourOperatorApi };
