import moment from 'moment';

const viewDate = par => {
  return moment(par).format('DD MMM YYYY');
};

const viewTime = par => {
  return moment(par).format('hh : mm');
};

//function time
const changeTime = par => {
  let date = moment(par)
    .locale('es')
    .format();
  return date.slice(0, -6);
};

const SumDays = (tgl, days) => {
  let date = moment(tgl)
    .add(days, 'days')
    .locale('es')
    .format();
  return date.slice(0, -6);
};
const SubDays = (tgl, days) => {
  let date = moment(tgl)
    .subtract(days, 'days')
    .locale('es')
    .format();
  return date.slice(0, -6);
};

const SumSecond = (tgl, second) => {
  let date = moment(tgl)
    .add(second, 'seconds')
    .locale('es')
    .format();
  return date.slice(0, -6);
};

const SubstractSecond = (tgl, second) => {
  let date = moment(tgl)
    .subtract(second, 'seconds')
    .locale('es')
    .format();
  return date.slice(0, -6);
};

const getHour = tgl => {
  return moment(tgl).hours();
};

const convertDateFormat = (date, string) => {
  return moment(date).format(string);
};

const convertToStringTime = date => {
  return date.slice(10);
};
const convertToStringDate = date => {
  return date.slice(0, -9);
};

const getNumberOfDays = (startDate, endDate) => {
  let normalyTime = 'T00:00:00';
  startDate = moment(convertToStringDate(startDate) + normalyTime);
  endDate = moment(convertToStringDate(endDate) + normalyTime);
  return endDate.diff(startDate, 'days') + 1;
};

const getNumberOfSecond = (startDate, endDate) => {
  return moment(endDate).diff(moment(startDate), 'seconds');
};

const getTime = date => {
  return new Date(date).getTime();
};
export {
  SubstractSecond,
  SumSecond,
  SubDays,
  SumDays,
  changeTime,
  viewDate,
  viewTime,
  getHour,
  convertDateFormat,
  convertToStringTime,
  convertToStringDate,
  getNumberOfDays,
  getNumberOfSecond,
  getTime,
};
