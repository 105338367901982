const PROFILE_MENU = [
  { title: 'My Account', link: '', icon: 'user', name: 'edit-profile' },
  { title: 'Logout', link: window.location, icon: 'power off', name: 'logout' },
];

const PROFILE_MENU_ADMIN = [
  { title: 'My Account', link: '', icon: 'user', name: 'edit-profile' },
  {
    title: 'My Company',
    link: '',
    icon: 'building',
    name: 'edit-company',
  },
  { title: 'Logout', link: window.location, icon: 'power off', name: 'logout' },
];

const TRANSPORTATION_MENU = [
  { title: 'Transportation', link: '/Transportation' },
  { title: 'Additional Cost', link: '/transportation-additional-cost' },
];

const SUPPLIER_MENU = [
  { title: 'Accommodation', link: '/Accomodation' },
  { title: 'Additional Services', link: '/additional-services' },
  { title: 'Excursion', link: '/Excursion' },
  { title: 'Restaurant', link: '/Restaurant' },
  // { title: 'Transportation', link: '/Transportation' },
  { menu: 'Transportation', submenu: TRANSPORTATION_MENU, index: 2 },
];
const CUSTOMER_MENU = [
  { title: 'Customer Profile', link: '/Customer-Profile' },
];
const PARTNER_MENU = [
  { title: 'Partner Profile', link: '/Partner-Profile' },
  { title: 'Tour Guide', link: '/Tour-Guide' },
  { title: 'Supplier', link: '/company-profile-list' },
];
const PARTNER_MENU_FINANCE = [
  { title: 'Partner Profile', link: '/Partner-Profile' },
];
const ADMINISTRATOR_MENU = [
  { title: 'System Margin', link: '/System-Margin' },
  { title: 'Exchange Rate', link: '/Exchange-Rate' },
  { title: 'User List', link: '/User-List' },
];
// const ADMINISTRATOR_MENU_FINANCE = [
//   { title: 'System Margin', link: '/System-Margin' },
//   { title: 'Exchange Rate', link: '/Exchange-Rate' },
// ];
const PACKAGE_MANAGEMENT_MENU = [
  { title: 'Series Package', link: '/PackageList/Fixed' },
  { title: 'Ready Package', link: '/PackageList/Ready' },
];
const SYSTEM_CONFIGURATION_MENU = [
  {
    title: 'Allowed Country for Companies',
    link: '/Allowed-Country-for-Companies',
  },
  { title: 'Area', link: '/Area' },
  { title: 'City', link: '/City' },
  { title: 'City Group', link: '/City-Group' },
  { title: 'Country', link: '/Country' },
  { title: 'Payment Method', link: '/Payment-Method' },
  { title: 'Payment Terms Scheme', link: '/Payment-Terms-Scheme' },
  { title: 'Place', link: '/Place' },
  { title: 'Region', link: '/Region' },
  { title: 'Upload Master Image', link: '/Upload-Master-Image' },
  { title: 'Master Data Reference', link: '/Master-Data-Reference' },
  { title: 'Data Transfer Logs', link: '/Data-Transfer-Log' },
];

const SYSTEM_CONFIGURATION_MENU_OPERATION = [
  { title: 'Area', link: '/Area' },
  { title: 'City', link: '/City' },
  { title: 'City Group', link: '/City-Group' },
  { title: 'Country', link: '/Country' },
  { title: 'Place', link: '/Place' },
  { title: 'Region', link: '/Region' },
  { title: 'Upload Master Image', link: '/Upload-Master-Image' },
  { title: 'Master Data Reference', link: '/Master-Data-Reference' },
];

const SYSTEM_CONFIGURATION_MENU_FINANCE = [
  { title: 'Payment Terms Scheme', link: '/Payment-Terms-Scheme' },
];

const TOUR_TRANSACTION_OPERATION_MENU = [
  { title: 'Tour Transaction List', link: '/tour-transaction' },
  { title: 'Resource Booking List', link: '/resource-booking' },
];

const FINANCES_MENU = [
  { title: 'Account Statement Detail', link: '/account-statement' },
  { title: 'Account Statement Summary', link: '/account-statement-summary' },
  { title: 'Payment Request List', link: '/request-payment-list' },
];

const FINANCES_AP_MENU = [
  { title: 'Payment Request List', link: '/request-payment-list' },
];

const FINANCES_AR_MENU = [
  { title: 'Account Statement Detail', link: '/account-statement' },
  { title: 'Account Statement Summary', link: '/account-statement-summary' },
];

const USER_ADMIN = [
  { menu: 'Supplier', submenu: SUPPLIER_MENU, index: 2 },
  { menu: 'Customer', submenu: CUSTOMER_MENU, index: 3 },
  { menu: 'Partner', submenu: PARTNER_MENU, index: 4 },
  { menu: 'Administration', submenu: ADMINISTRATOR_MENU, index: 5 },
  { menu: 'Package Management', submenu: PACKAGE_MANAGEMENT_MENU, index: 6 },
  {
    menu: 'System Configuration',
    submenu: SYSTEM_CONFIGURATION_MENU,
    index: 7,
  },
  { menu: 'Finance', submenu: FINANCES_MENU, index: 8 },
];

const USER_OPERATION = [
  {
    menu: 'Tour Transaction',
    submenu: TOUR_TRANSACTION_OPERATION_MENU,
    index: 2,
  },
  { menu: 'Supplier', submenu: SUPPLIER_MENU, index: 3 },
  { menu: 'Package Management', submenu: PACKAGE_MANAGEMENT_MENU, index: 4 },
  {
    menu: 'System Configuration',
    submenu: SYSTEM_CONFIGURATION_MENU_OPERATION,
    index: 5,
  },
];
const USER_SALES = [
  { menu: 'Customer', submenu: CUSTOMER_MENU, index: 3 },
  { menu: 'Package List', submenu: PACKAGE_MANAGEMENT_MENU, index: 4 },
];
const USER_FINANCE = [
  { menu: 'Customer', submenu: CUSTOMER_MENU, index: 3 },
  { menu: 'Partner', submenu: PARTNER_MENU_FINANCE, index: 4 },
  {
    menu: 'System Configuration',
    submenu: SYSTEM_CONFIGURATION_MENU_FINANCE,
    index: 5,
  },
  { menu: 'Finance', submenu: FINANCES_MENU, index: 6 },
];

const USER_FINANCE_AP = [
  { menu: 'Customer', submenu: CUSTOMER_MENU, index: 3 },
  { menu: 'Partner', submenu: PARTNER_MENU_FINANCE, index: 4 },
  {
    menu: 'System Configuration',
    submenu: SYSTEM_CONFIGURATION_MENU_FINANCE,
    index: 5,
  },
  { menu: 'Finance', submenu: FINANCES_AP_MENU, index: 6 },
];

const USER_FINANCE_AR = [
  { menu: 'Customer', submenu: CUSTOMER_MENU, index: 3 },
  { menu: 'Partner', submenu: PARTNER_MENU_FINANCE, index: 4 },
  {
    menu: 'System Configuration',
    submenu: SYSTEM_CONFIGURATION_MENU_FINANCE,
    index: 5,
  },
  { menu: 'Finance', submenu: FINANCES_AR_MENU, index: 6 },
];

export {
  USER_ADMIN,
  PROFILE_MENU,
  USER_OPERATION,
  USER_SALES,
  USER_FINANCE,
  USER_FINANCE_AP,
  USER_FINANCE_AR,
  PROFILE_MENU_ADMIN,
};
