import { GET_CURRENCIES } from './actionTypes';
import getCurrenciesApi from '../api/getCurrenciesApi';

const getCurrenciesAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_CURRENCIES,
      payload: getCurrenciesApi(),
    });
  };
};

export { getCurrenciesAction };
