const headerRestaurantList = [
  'Restaurant Name',
  'Address',
  'Category',
  'Speciality',
  'Open Hour',
  'Close Hour',
  'Action',
];

export { headerRestaurantList };
