import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ButtonClose from '../../../components/button/buttonCloseModal';
import { Modal, Form, Button } from 'semantic-ui-react';
import InputWithLabel from '../../shared/components/common/input/InputWithLabel';
import options from '../../../scripts/convertOption';
import { ACCOMMODATION, TRANSPORTATION } from './../scripts/supplier';
import { copyObject } from '../../../scripts/itineraryBuilder/itineraryBuilder';
const ERRORS = {
  service: {
    error: false,
    message: '',
  },
  duration: {
    error: false,
    message: '',
  },
};
const ModalSupplierService = props => {
  const [service, setService] = useState({ service: '', duration: '' });
  const [errors, setErrors] = useState(copyObject(ERRORS));
  let {
    openModal,
    handleClose,
    accommodationService,
    resourceType,
    transportService,
  } = props;
  useEffect(() => {
    if (props.openModal) {
      setService({
        ...service,
        service:
          resourceType === TRANSPORTATION ? props.selectedTransportService : '',
      });
    }
    // eslint-disable-next-line
  }, [props.openModal]);
  const handleChange = (e, { name, value }) => {
    setService({ ...service, [name]: value });
  };
  const handleSubmit = () => {
    let validation = doValidation();
    if (validation.isError) setErrors(validation.errors);
    else {
      setErrors(ERRORS);
      let completeDataService = {};
      if (resourceType === ACCOMMODATION) {
        completeDataService.service = service.service;
      } else if (resourceType === TRANSPORTATION) {
        let listDuration = transportService.find(
          e => e.ServiceType === service.service
        );
        completeDataService.service = service.service;
        completeDataService.duration = listDuration.Hours.find(
          e => e.ServiceItemId === service.duration
        );
      }
      props.handleChooseService(completeDataService);
    }
  };
  const doValidation = () => {
    let newErrors = copyObject(ERRORS);
    let isError = false;
    if (service.service === '') {
      newErrors.service.error = true;
      newErrors.service.message = "Field can't be empty!";
    }
    if (resourceType === TRANSPORTATION && service.duration === '') {
      newErrors.duration.error = true;
      newErrors.duration.message = "Field can't be empty!";
    }
    return { isError, errors: newErrors };
  };
  let listDuration = transportService.find(
    e => e.ServiceType === service.service
  );
  return (
    <Modal
      dimmer="blurring"
      inverted
      open={openModal}
      onClose={handleClose}
      size="small"
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        Change Resource <ButtonClose onClose={handleClose} />
      </Modal.Header>
      <Modal.Content>
        <Form>
          {resourceType === ACCOMMODATION ? (
            <InputWithLabel
              type="select"
              label="Room Service"
              placeholder="Room Service"
              value={service.service}
              name="service"
              handleChange={handleChange}
              validate={errors}
              options={accommodationService.map(e =>
                options(e, e, e.split('_').join(' '))
              )}
            />
          ) : resourceType === TRANSPORTATION ? (
            <Form.Group widths="equal">
              <InputWithLabel
                type="select"
                label="Service"
                placeholder="Service"
                value={service.service}
                name="service"
                handleChange={handleChange}
                validate={errors}
                options={transportService.map(e =>
                  options(e.ServiceType, e.ServiceType, e.ServiceType)
                )}
                disabled
              />
              <InputWithLabel
                type="select"
                label="Duration"
                placeholder="Duration"
                value={service.duration}
                name="duration"
                handleChange={handleChange}
                validate={errors}
                options={
                  listDuration
                    ? listDuration.Hours.map(e =>
                        options(e.Id, e.ServiceItemId, e.Name)
                      )
                    : []
                }
              />
            </Form.Group>
          ) : null}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Submit" primary onClick={handleSubmit} />
      </Modal.Actions>
    </Modal>
  );
};
ModalSupplierService.propTypes = {
  openModal: PropTypes.bool,
  handleClose: PropTypes.func,
  resourceType: PropTypes.string,
  accommodationService: PropTypes.object,
  handleChooseService: PropTypes.func,
  transportService: PropTypes.array,
  selectedTransportService: PropTypes.string,
};
export default ModalSupplierService;
