import React from 'react';
import { Grid, Segment, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Header from '../../../../components/common/headerTitle';
import GeneralInformationGuide from '../form/GeneralInformationGuide';
import ContactInformationGuide from '../form/ContactInformationGuideDetail';
// import ProfilePicture from '../form/ProfilePicture';
import { styles } from '../../styles';
import { Redirect } from 'react-router-dom';
import SetDataForSave from '../service/data/setDataForSave';
import SegmentTablePrice from './TourGuidePrice';
import { validationTourGuide } from '../service/validation/validationTourGuide';
import setDataForPut from '../service/data/setDataForPut';
import TableRegionCoverages from './RegionCoverages';
import ModalConfirm from '../../../../components/modal/modalConfirmationTwoButton';
import ModalItem from '../../../shared/components/modal/accommodation/ModalItemAccomodationProfile';
// import AddingDataWithTable from '../../../components/globalListVew/addingDataComponent';
// import Pagination from '../../../components/common/pagination';
const FILL = 'Please fill ';
const CHOOSE = 'Please choose ';
class TourGuideDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disablePage: false,
      contentButton: '',
      gotoLink: '',
      changePage: false,
      errorFormTourGuide: {
        FirstName: { error: false, message: FILL + 'first name' },
        LastName: { error: false, message: FILL + 'last name' },
        IdentityNbr: { error: false, message: FILL + 'identity number' },
        NationalityId: { error: false, message: CHOOSE + 'nationality' },
        RegionCoverages: { error: false, message: FILL + 'region coverages' },
        Address1: { error: false, message: FILL + 'address' },
        AreaId: { error: false, message: CHOOSE + 'area' },
        Landmark: { error: false, message: FILL + 'GPS address' },
        EmailAddress: { error: false, message: FILL + 'email' },
        TourGuideLanguage: { error: false, message: FILL + 'language' },
      },
      openModalRegionCoverages: false,
    };
  }
  static propTypes = {
    setDisablePage: PropTypes.func,
    contentButton: PropTypes.string,
    disablePage: PropTypes.bool,
    putTourGuideAction: PropTypes.func,
    postTourGuideAction: PropTypes.func,
    listRegion: PropTypes.array,
    listLanguage: PropTypes.array,
    listOfPriceGuide: PropTypes.array,
    postServicePriceAction: PropTypes.func,
    putServicePriceAction: PropTypes.func,
  };

  saveTourGuide = async () => {
    const {
      setDisablePage,
      contentButton,
      tourGuideObject,
      putTourGuideAction,
      postTourGuideAction,
    } = this.props;
    let { errorFormTourGuide /*deleteRegionCover*/ } = this.state;
    let err = await validationTourGuide(tourGuideObject, errorFormTourGuide);
    let error =
      err.FirstName.error ||
      err.LastName.error ||
      err.IdentityNbr.error ||
      err.NationalityId.error ||
      err.Address1.error ||
      err.AreaId.error ||
      err.Landmark.error ||
      err.EmailAddress.error ||
      err.TourGuideLanguage.error ||
      err.RegionCoverages.error;
    let data;
    if (!error) {
      if (contentButton === 'Edit') {
        setDisablePage();
      } else {
        if (tourGuideObject.Id > 0) {
          data = setDataForPut(tourGuideObject);
          putTourGuideAction(tourGuideObject.Id, data)
            .then(res => this.savePrice(res.value.data))
            .catch(error => {
              this.setState({
                openModalAlert: true,
                contentAlert: error.response.data.Message,
                headerAlert: 'Failed',
                isFailed: true,
              });
            });
        } else {
          data = SetDataForSave(tourGuideObject);
          await postTourGuideAction(data)
            .then(res => this.savePrice(res.value.data))
            .catch(error => {
              this.setState({
                openModalAlert: true,
                contentAlert: error.response.data.Message,
                headerAlert: 'Failed',
                isFailed: true,
              });
            });
        }
      }
    }
    this.setState({
      errorFormTourGuide: err,
    });
  };

  savePrice = async data => {
    data.TourGuideLanguage.map(response => {
      this.props.listOfPriceGuide.map(details => {
        if (details.ServiceItemId === 0) {
          if (response.LanguageId === details.TourGuideLanguage) {
            details.ServiceItemId = response.ServiceItemId;
          }
        }
        return details;
      });
      return response;
    });
    await this.props.listOfPriceGuide.map(
      object =>
        object.postStatus
          ? this.props.postServicePriceAction('TourGuide', object)
          : this.props.putServicePriceAction('TourGuide', object.Id, object)
    );
  };
  backPage = () => {
    let link = `/Tour-Guide`;
    this.setState({ changePage: true, gotoLink: link });
  };
  handleChange = async (e, { name, value }) => {
    const { tourGuideObject } = this.props;
    let { errorFormTourGuide } = this.state;
    await this.props.handleChange(name, value);
    let err = await validationTourGuide(tourGuideObject, errorFormTourGuide);
    this.setState({ errorFormTourGuide: err });
  };

  handleChangeImage = e => {
    let { handleChangeImage } = this.props;
    let reader = new FileReader();
    let files = e.target.files[0];
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      let images = {
        ImageName: files.name,
        ImageContent: reader.result.split(',')[1],
        ImagePreview: reader.result,
        ImageUrl: reader.result.split(',')[1],
      };
      handleChangeImage(images);
    };
  };

  openModalRegionCoverages = () => {
    let { openModalRegionCoverages } = this.state;
    this.setState({ openModalRegionCoverages: !openModalRegionCoverages });
  };
  confirmDelete = (e, { value, id, name }) => {
    this.setState({
      RegionCoveragesId: value,
      openConfirm: true,
      messageConfirm: 'Are you sure to delete ' + name + '?',
      regionName: name,
      indexRegionDelete: id,
    });
  };

  deleteRegionCoverages = () => {
    const { RegionCoveragesId, indexRegionDelete, regionName } = this.state;
    const { tourGuideObject } = this.props;
    let deleteRegionCover = [];
    if (RegionCoveragesId === 0) {
      tourGuideObject.RegionCoverages.splice(indexRegionDelete, 1);
    } else {
      tourGuideObject.RegionCoverages[indexRegionDelete].Action = 1;
      deleteRegionCover.push(RegionCoveragesId);
    }
    let { listRegion } = this.props;
    listRegion.map(data => {
      if (data.Name === regionName) {
        data['Status'] = false;
      }
      return data;
    });
    this.setState({
      openConfirm: false,
      deleteRegionCover,
    });
  };
  handleClose = () =>
    this.setState({
      openConfirm: false,
    });

  handleCloseModalItem = () =>
    this.setState({
      openModalRegionCoverages: false,
    });

  handleCheckRegion = (e, { name, checked }) => {
    let { listRegion } = this.props;
    listRegion.map(data => {
      if (data.Id === name) {
        data['Status'] = checked;
      }
      return data;
    });
    let region = listRegion.reduce((total, state) => {
      if (state.Status === true) {
        total.push({
          RegionId: state.Id,
          RegionName: state.Name,
        });
      }
      return total;
    }, []);
    this.setState({ RegionCoverages: region });
  };

  addState = async () => {
    let { tourGuideObject } = this.props;
    let { RegionCoverages, errorFormTourGuide } = this.state;
    var res = RegionCoverages.filter(
      item1 =>
        !tourGuideObject.RegionCoverages.some(
          item2 =>
            item2.RegionId === item1.RegionId &&
            item2.RegionName === item1.RegionName
        )
    );
    res.map(data =>
      tourGuideObject.RegionCoverages.push({
        Id: 0,
        TourGuideId: tourGuideObject.Id,
        Action: 2,
        RegionId: data.RegionId,
        RegionName: data.RegionName,
      })
    );
    let err = validationTourGuide(tourGuideObject, errorFormTourGuide);
    this.setState({ errorFormTourGuide: err });
    this.setState({ openModalRegionCoverages: false });
  };

  setErrorFormTourGuide = err => {
    this.setState({ errorFormTourGuide: err });
  };
  render() {
    let {
      contentButton,
      disablePage,
      tourGuideObject,
      listCountry,
      listArea,
      listRegion,
      listLanguage,
      handlePushLanguages,
    } = this.props;
    const {
      gotoLink,
      changePage,
      errorFormTourGuide,
      openConfirm,
    } = this.state;
    if (changePage) {
      return <Redirect to={gotoLink} />;
    }
    let listRegionCoverages = listRegion.map(data => {
      tourGuideObject.RegionCoverages.map(match => {
        if (data.Id === match.RegionId) {
          data['Status'] = true;
        }
        return data;
      });
      return data;
    }, []);
    return (
      <Grid style={{ marginTop: '30px' }}>
        <Grid.Row stretched>
          <Grid.Column>
            <Segment>
              <React.Fragment>
                <Button
                  negative
                  onClick={this.backPage}
                  icon="angle left"
                  content="Back"
                  style={styles.buttonSave}
                />
                <Button
                  primary
                  onClick={this.saveTourGuide}
                  content={contentButton}
                />
              </React.Fragment>
              <Header title="Tour Guide Detail" />
              <Grid columns={2} padded="vertically">
                <Grid.Column>
                  <GeneralInformationGuide
                    disabled={disablePage}
                    tourGuideObject={tourGuideObject}
                    handleChange={this.handleChange}
                    listCountry={listCountry}
                    listRegion={listRegion}
                    errorFormTourGuide={errorFormTourGuide}
                  />
                  <ContactInformationGuide
                    disabled={disablePage}
                    tourGuideObject={tourGuideObject}
                    listArea={listArea}
                    handleChange={this.handleChange}
                    errorFormTourGuide={errorFormTourGuide}
                  />
                </Grid.Column>
                <Grid.Column>
                  {/* <ProfilePicture
                    disabled={disablePage}
                    handleChangeImage={this.handleChangeImage}
                    tourGuideProfilePicture={tourGuideProfilePicture}
                  /> */}
                  <TableRegionCoverages
                    disabled={disablePage}
                    listData={tourGuideObject.RegionCoverages}
                    deleteData={this.confirmDelete}
                    openModalRegionCoverages={this.openModalRegionCoverages}
                    openPopUp={errorFormTourGuide.RegionCoverages.error}
                  />
                </Grid.Column>
              </Grid>
              <SegmentTablePrice
                data={tourGuideObject.TourGuideLanguage}
                disabled={disablePage}
                handlePushLanguages={handlePushLanguages}
                listLanguage={listLanguage}
                openPopUp={errorFormTourGuide.TourGuideLanguage.error}
                tourGuideObject={tourGuideObject}
                errorFormTourGuide={errorFormTourGuide}
                setErrorFormTourGuide={this.setErrorFormTourGuide}
                listOfPriceGuide={this.props.listOfPriceGuide}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <ModalConfirm
          openModal={openConfirm}
          size="mini"
          close={this.handleClose}
          confirm={this.deleteRegionCoverages}
          message={this.state.messageConfirm}
        />
        <ModalItem
          openModal={this.state.openModalRegionCoverages}
          handleClose={this.openModalRegionCoverages}
          content={this.state.contentModal}
          data={listRegionCoverages ? listRegionCoverages : []}
          onChangeItem={this.handleCheckRegion}
          addItem={this.addState}
        />
      </Grid>
    );
  }
}

TourGuideDetail.propTypes = {
  tourGuideObject: PropTypes.object,
  listCountry: PropTypes.array,
  listArea: PropTypes.array,
  handleChange: PropTypes.func,
  tourGuideProfilePicture: PropTypes.object,
  handleChangeImage: PropTypes.func,
  handlePushLanguages: PropTypes.func,
};
export default TourGuideDetail;
