import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Icon } from 'semantic-ui-react';
import { StyleModal } from '../../../../assets/styles/size';
const ModalIframePaymentGateway = props => {
  return (
    <Modal
      dimmer="blurring"
      closeIcon
      inverted
      open={props.open}
      //onClose={props.handleClose}
      size="large"
      style={StyleModal.modalXendit}
    >
      <Modal.Header>{props.header}</Modal.Header>
      <Modal.Content>
        <iframe
          title="IframePaymentGateway"
          src={props.url}
          style={{ width: '43em', height: '75em' }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button positive inverted onClick={props.handleDone}>
          <Icon name="save" /> Done
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

ModalIframePaymentGateway.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  handleChange: PropTypes.func,
  url: PropTypes.string,
  handleDone: PropTypes.func,
};
export default ModalIframePaymentGateway;
