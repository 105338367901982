import React from 'react';
import { Table, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { convertDateFormat } from '../../../../scripts/moment';
import Loader from '../../../../components/loader/loader';
import styles from '../../../../components/table/styles';
import { Link } from 'react-router-dom';
const PENDING = 'Pending';
// const UPGRADE_REQUEST = 'Upgrade_request';
const ACCEPTED = 'Accepted';
const DECLINED = 'Declined';
const AUTO_CONFIRMED = 'Auto_confirmed';
// const MANUAL_CONFIRMATION = 'Manual_confirmation';
const convertStatus = item => {
  const { Status, ConfirmationNote, ConfirmationNumber } = item;
  let data = {
    id: Status,
    text: Status ? Status.split('_').join(' ') : '-',
    contentButton: 'null',
    styleText: {},
  };
  switch (Status) {
    case PENDING:
      data.contentButton = 'Re-Request';
      data.styleText = styles.colorYellow;
      data.isConfirm = true;
      data.dataAction = {
        header: 'Confirmation',
        message: 'Are You sure want to re-request this confirmation?',
        post: item,
        type: Status,
      };
      break;
    case DECLINED:
      data.contentButton = 'See Note';
      data.styleText = styles.colorOrangeRed;
      data.isAlert = true;
      data.dataAction = {
        header: 'Note',
        message: ConfirmationNote,
        post: item,
        type: Status,
      };
      break;
    case ACCEPTED:
      data.contentButton = 'Conf No.';
      data.styleText = {};
      data.isAlert = true;
      data.dataAction = {
        header: 'Confirmation Number',
        message: ConfirmationNumber,
        post: item,
        type: Status,
      };
      break;
    case AUTO_CONFIRMED:
      // data.hiddenButton = true;
      data.styleText = {};
      data.contentButton = 'Re-Request';
      data.isConfirm = true;
      data.dataAction = {
        header: 'Confirmation',
        message: 'Are You sure want to re-request this confirmation?',
        post: item,
        type: Status,
      };
      break;
    default:
      data.contentButton = data.text;
      data.styleText = {};
  }
  return data;
};
const TableBookingStatusList = props => {
  const {
    listConfirmRoomAllotment,
    loading,
    handleOpenModalAlert,
    handleOpenModalConfirm,
  } = props;
  return (
    <Table basic="very" selectable style={{ marginTop: '35px' }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Booking Number</Table.HeaderCell>
          <Table.HeaderCell>Supplier Name</Table.HeaderCell>
          <Table.HeaderCell>Item Name</Table.HeaderCell>
          <Table.HeaderCell>Expired Date Order</Table.HeaderCell>
          <Table.HeaderCell>Order Date</Table.HeaderCell>
          <Table.HeaderCell>Quantity</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>Action</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {!loading ? (
          listConfirmRoomAllotment.map((item, idx) => {
            let status = convertStatus(item);
            return (
              <Table.Row key={idx}>
                <Table.Cell>
                  <Link
                    to={`/tour-transaction/edit/${
                      item.PackageType === 'Custom' ? 'Custom' : 'Fixed'
                    }/${item.TransactionId}`}
                  >
                    {item.TransactionId}
                  </Link>
                </Table.Cell>
                <Table.Cell>{item.AccommodationProfileName}</Table.Cell>
                <Table.Cell>{item.AccommodationItemName}</Table.Cell>
                <Table.Cell>
                  {convertDateFormat(item.ExpiredDate, 'DD/MM/YY | HH:mm:ss')}
                </Table.Cell>
                <Table.Cell>
                  {convertDateFormat(item.CheckInDateTime, 'DD/MM/YY') +
                    ' - ' +
                    convertDateFormat(item.CheckOutDateTime, 'DD/MM/YY')}
                </Table.Cell>
                <Table.Cell>{item.Qty} Rooms/Night</Table.Cell>
                <Table.Cell style={status && status.styleText}>
                  {status && status.text}
                </Table.Cell>
                <Table.Cell>
                  {!status.hiddenButton && (
                    <Button
                      content={status && status.contentButton}
                      value={status.dataAction}
                      onClick={
                        status.isConfirm
                          ? handleOpenModalConfirm
                          : status.isAlert && handleOpenModalAlert
                      }
                      primary
                    />
                  )}
                </Table.Cell>
              </Table.Row>
            );
          })
        ) : (
          <Table.Row>
            <Table.Cell colSpan="8" textAlign="center">
              <Loader active={loading} inline={true} />
            </Table.Cell>
          </Table.Row>
        )}
        {!loading &&
          (listConfirmRoomAllotment.length === 0 && (
            <Table.Row>
              <Table.Cell colSpan="8" textAlign="center">
                No Data
              </Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  );
};
TableBookingStatusList.propTypes = {
  listConfirmRoomAllotment: PropTypes.array,
  loading: PropTypes.bool,
  handleOpenModalAlert: PropTypes.func,
  handleOpenModalConfirm: PropTypes.func,
};
export default TableBookingStatusList;
