const GENERAL_INFORMATION = [
  'Id',
  'TourCategoryId',
  'TourPaxTypeId',
  'GroupType',
  'RegionDestinationId',
  'CityDestinationId',
  'Description',
  'ExpiredDate',
  'GracePeriod',
  'ImageName',
  'ImageContent',
  'IsFeatured',
  'ReferenceId',
  'MinPax',
  'ActiveDate',
];

export { GENERAL_INFORMATION };
