import React from 'react';
import { Input, Grid, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import TableExcursionList from './TableExcursionList';
import { HeaderTabelExcursion } from '../../../../components/table/headerTabel';
import ButtonAction from '../../../../components/common/ButtonGroupSupplier';
import HeaderTitle from '../../../../components/common/headerTitle';
import Pagination from '../../../../components/common/pagination';
import styles from './styles';
const ExcursionList = props => (
  <div>
    <Grid columns="equal" style={styles.marginTop30}>
      <Grid.Row stretched>
        <Grid.Column>
          <Segment>
            <HeaderTitle title={props.title} />
            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Input
                    icon="search"
                    placeholder="search"
                    name="searchInput"
                    value={props.searchInput}
                    onChange={props.handleChange}
                  />
                </Grid.Column>
                <Grid.Column width={12}>
                  <ButtonAction
                    buttonNew={props.buttonNew}
                    butttonExport={props.butttonExport}
                    buttonUploadData={props.buttonUploadData}
                    buttonDownloadTemplate={props.buttonDownloadTemplate}
                    buttonExportItem={props.buttonExportItem}
                    isSupplierList={true}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <TableExcursionList
              data={props.data}
              headerTable={HeaderTabelExcursion}
              pageChange={props.pageChange}
              listData={props.listData}
              pagedData={props.pagedData}
              handleClickEdit={props.butttonEdit}
              handleClickDelete={props.buttonDelete}
            />
            <Pagination
              pagedData={props.pagedData}
              numbersTo={props.numbersTo}
              numbersFrom={props.numbersFrom}
              data={props.data}
              pageChange={props.pageChange}
              activePage={props.activePage}
            />
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
);

ExcursionList.propTypes = {
  listData: PropTypes.array,
  pagedData: PropTypes.array,
  title: PropTypes.string,
  pageChange: PropTypes.func,
  activePage: PropTypes.number,
  searchInput: PropTypes.string,
  handleChange: PropTypes.func,
  data: PropTypes.array,
  butttonEdit: PropTypes.func,
  buttonNew: PropTypes.func,
  butttonExport: PropTypes.func,
  buttonUploadData: PropTypes.func,
  buttonDownloadTemplate: PropTypes.func,
  buttonDelete: PropTypes.func,
  handleNewAccomodation: PropTypes.func,
  numbersFrom: PropTypes.number,
  numbersTo: PropTypes.number,
  buttonExportItem: PropTypes.func,
};
export default ExcursionList;
