import React from 'react';
import { connect } from 'react-redux';
import { Image, Grid, Divider, Button } from 'semantic-ui-react';
import ImageRoomConfirmation from '../../assets/images/roomConfirmation.png';
import ImageSuccess from '../../assets/images/success.png';
import { MIN_HEIGTH } from '../../assets/styles/size';
import ModalConfirmRoomAllotment from '../../components/modal/modalConfirmRoomAllotment';
// import { getRequestRoomAllotment } from '../../actions/accomodationAction';
import { getRequestRoomAllotment } from '../../pages/shared/actions/accommodationAction';
import PropTypes from 'prop-types';
import Loader from '../../components/loader/loader';
import { convertDateFormat } from '../../scripts/moment';
// import BookingStatusList from './../components/segment/segmentBookingStatusList';
import ModalAlert from '../../components/modal/modalAlert';
const ACCEPT = 'ACCEPT';
const DECLINE = 'DECLINE';
const AUTO_CONFIRMED = 'Auto_confirmed';

class RoomConfirmation extends React.Component {
  state = {
    modalConfirmOpen: false,
    modalConfirmType: '',
    changePage: false,
    modalAlertOpen: false,
    modalAlertHeader: '',
    modalAlertMessage: '',
  };
  componentDidMount() {
    let { transactionId, token } = this.props.match.params;
    this.props
      .getRequestRoomAllotment(transactionId, token)
      .then(() =>
        this.setState({
          ...this.state,
          modalConfirmType:
            this.props.requestRoomAllotment.Status === 'Accepted'
              ? ACCEPT
              : this.props.requestRoomAllotment.Status === 'Declined'
              ? DECLINE
              : '',
        })
      )
      .catch(() =>
        this.setState({
          ...this.state,
          modalAlertOpen: true,
          modalAlertHeader: 'Failed',
          modalAlertMessage: 'Failed Get Room Confirmation',
        })
      );
  }

  handleOpenAlert = (e, { value }) =>
    this.setState(state => ({
      ...state,
      modalConfirmOpen: !state.modalConfirmOpen,
      modalConfirmType: value,
    }));

  handleClose = () =>
    this.setState({
      ...this.state,
      modalConfirmOpen: !this.state.modalConfirmOpen,
    });

  handleChangePage = () =>
    this.setState({ ...this.state, modalConfirmOpen: false, changePage: true });

  handleCloseAlert = () =>
    this.setState({ ...this.state, modalAlertOpen: false });

  showNoData = () => (
    <Grid.Row style={{ margin: '0px 20px', padding: '0px', color: '#ff3d3d' }}>
      <Grid.Column width={16} textAlign="center">
        No Data
      </Grid.Column>
      <Grid.Column width={16}>
        <Divider fitted />
      </Grid.Column>
    </Grid.Row>
  );

  // handleLeavePage = () => window.close();

  render() {
    let {
      modalConfirmOpen,
      modalConfirmType,
      changePage,
      modalAlertOpen,
      modalAlertHeader,
      modalAlertMessage,
    } = this.state;
    const { loadingGetRequestRoomAllotment, requestRoomAllotment } = this.props;
    const isAutoConfirm =
      requestRoomAllotment && requestRoomAllotment.Status === AUTO_CONFIRMED;
    changePage =
      changePage ||
      (requestRoomAllotment && requestRoomAllotment.Status === 'Accepted') ||
      (requestRoomAllotment && requestRoomAllotment.Status === 'Declined');
    return (
      <div style={{ margin: '0px', minHeight: MIN_HEIGTH }}>
        <Loader
          active={loadingGetRequestRoomAllotment}
          description="Please Wait"
          page={true}
        />
        <Image
          src={!changePage ? ImageRoomConfirmation : ImageSuccess}
          size="large"
          centered
        />
        <Grid style={{ width: '600px', margin: '0 auto' }}>
          <Grid.Row textAlign="center">
            <Grid.Column width={16}>
              <span style={{ fontSize: '30px' }}>
                <b>
                  {!changePage
                    ? 'Room Confirmation Availability'
                    : 'Confirmation Success'}{' '}
                </b>
              </span>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ margin: '0px 20px', padding: '5px 0px' }}>
            <Grid.Column width={16}>
              {!changePage ? (
                <span>Please confirm the order below for:</span>
              ) : (
                <span>
                  You just confirm that order below is{' '}
                  {modalConfirmType === ACCEPT ? (
                    <b style={{ color: '#2962ff' }}>Available</b>
                  ) : (
                    <b style={{ color: '#ff3d3d' }}>Not Available</b>
                  )}
                </span>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ margin: '0px 20px', padding: '0px' }}>
            <Grid.Column width={6}>
              <span>Date</span>
            </Grid.Column>
            <Grid.Column width={10}>
              {requestRoomAllotment ? (
                <span>
                  :{' '}
                  {convertDateFormat(
                    requestRoomAllotment.CheckInDateTime,
                    'DD MMM YYYY'
                  )}{' '}
                  -{' '}
                  {convertDateFormat(
                    requestRoomAllotment.CheckOutDateTime,
                    'DD MMM YYYY'
                  )}
                </span>
              ) : (
                <span>: -</span>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ margin: '0px 20px', padding: '0px' }}>
            <Grid.Column width={6}>
              <span>Total Pax</span>
            </Grid.Column>
            <Grid.Column width={10}>
              {requestRoomAllotment ? (
                <span>: {requestRoomAllotment.Pax + ' Pax'}</span>
              ) : (
                <span>: -</span>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ margin: '0px 20px', padding: '0px' }}>
            <Grid.Column width={6}>
              <span>Check-in Time</span>
            </Grid.Column>
            <Grid.Column width={10}>
              {requestRoomAllotment ? (
                <span>
                  :{' '}
                  {convertDateFormat(
                    requestRoomAllotment.CheckInDateTime,
                    'HH:mm'
                  )}
                </span>
              ) : (
                <span>: -</span>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ margin: '0px 20px', padding: '0px' }}>
            <Grid.Column width={6}>
              <span>Check-Out Time</span>
            </Grid.Column>
            <Grid.Column width={10}>
              {requestRoomAllotment ? (
                <span>
                  :{' '}
                  {convertDateFormat(
                    requestRoomAllotment.CheckOutDateTime,
                    'HH:mm'
                  )}
                </span>
              ) : (
                <span>: -</span>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ margin: '0px 20px', padding: '20px 0px 0px 0px' }}>
            <Grid.Column width={8}>Room Type</Grid.Column>
            <Grid.Column width={4} textAlign="center">
              Amount
            </Grid.Column>
            <Grid.Column width={4} textAlign="center">
              Guests
            </Grid.Column>
            <Grid.Column width={16}>
              <Divider fitted />
            </Grid.Column>
          </Grid.Row>
          {requestRoomAllotment &&
            requestRoomAllotment.Items.map((item, idx) => (
              <Grid.Row
                key={idx}
                style={{ margin: '0px 20px', padding: '0px' }}
              >
                <Grid.Column width={8}>{item && item.Text} </Grid.Column>
                <Grid.Column width={4} textAlign="center">
                  {item && item.Value} Unit
                </Grid.Column>
                <Grid.Column width={4} textAlign="center">
                  {item && item.AllocationText}
                </Grid.Column>
                <Grid.Column width={16}>
                  <Divider fitted />
                </Grid.Column>
              </Grid.Row>
            ))}
          {!requestRoomAllotment && this.showNoData()}
          {requestRoomAllotment &&
            (requestRoomAllotment.Items.length === 0 && this.showNoData())}
          {!changePage ? (
            <Grid.Row
              style={{ margin: '0px 20px', padding: '30px 0px 15px 0px' }}
            >
              <Grid.Column width={16} textAlign="center">
                <span>
                  Please confirm this order immediately before 1x24 hour.
                </span>
                <br />
                {requestRoomAllotment ? (
                  <span style={{ color: '#ff3d3d' }}>
                    {convertDateFormat(
                      requestRoomAllotment.ExpiredDate,
                      'dddd, DD MMMM YYYY'
                    )}{' '}
                    at{' '}
                    {convertDateFormat(
                      requestRoomAllotment.ExpiredDate,
                      'HH:mm'
                    )}
                  </span>
                ) : (
                  <span style={{ color: '#ff3d3d' }}>No Data</span>
                )}
              </Grid.Column>
            </Grid.Row>
          ) : (
            <Grid.Row
              style={{ margin: '0px 20px', padding: '30px 0px 15px 0px' }}
            >
              <Grid.Column width={16} textAlign="center">
                <span>
                  Our reservation team will contact you for this order,
                  <br /> happy to partner with you
                </span>
              </Grid.Column>
            </Grid.Row>
          )}
          {!changePage ? (
            <Grid.Row style={{ margin: '0px 100px', padding: '0px' }}>
              {isAutoConfirm || (
                <Grid.Column width={8} textAlign="right">
                  <Button
                    negative
                    style={{ width: '150px' }}
                    content="Not Available"
                    onClick={this.handleOpenAlert}
                    value={DECLINE}
                    disabled={!requestRoomAllotment}
                  />
                </Grid.Column>
              )}
              <Grid.Column
                width={8}
                textAlign="left"
                style={isAutoConfirm ? { margin: '0 auto' } : {}}
              >
                <Button
                  primary
                  style={{ width: '150px' }}
                  content="Available"
                  onClick={this.handleOpenAlert}
                  value={ACCEPT}
                  disabled={!requestRoomAllotment}
                />
              </Grid.Column>
            </Grid.Row>
          ) : null
          // <Grid.Row style={{ margin: '0px 20px', padding: '0px' }}>
          //   <Grid.Column width={16} textAlign="center">
          //     <Button
          //       primary
          //       style={{ width: '150px' }}
          //       content="Leave this page "
          //       onClick={this.handleLeavePage}
          //     />
          //   </Grid.Column>
          // </Grid.Row>
          }
        </Grid>
        <Grid textAlign="center" style={{ width: '1000px', margin: '0 auto' }}>
          <Grid.Row style={{ padding: '50px 0px 10px 0px' }}>
            <Grid.Column width={16}>
              {/* <span>Please let us know how we can help you by cs.phone</span> */}
            </Grid.Column>
          </Grid.Row>
          {/* =================== alamat di hiiden dulu ================================ */}
          {/* <Grid.Row style={{ padding: '5px 0px 25px 0px' }}>
            <Grid.Column width={2}>
              <span>cs@touress.com</span>
            </Grid.Column>
            <Grid.Column width={1}>
              <span>+6531636895</span>
            </Grid.Column>
            <Grid.Column width={13}>
              <span>
                AXA Tower 45th Fl. Office 49, Kuningan City, Jl. Prof. Dr.
                Satrio Kav. 18 Jakarta Selatan, DKI Jakarta, Indonesia
              </span>
            </Grid.Column>
          </Grid.Row> */}
        </Grid>
        <ModalConfirmRoomAllotment
          open={modalConfirmOpen}
          type={modalConfirmType}
          handleClose={this.handleClose}
          requestRoomAllotment={requestRoomAllotment}
          handleChangePage={this.handleChangePage}
        />
        <ModalAlert
          openModal={modalAlertOpen}
          handleClose={this.handleCloseAlert}
          header={modalAlertHeader}
          content={modalAlertMessage}
        />
        <div style={{ margin: '0px auto' }}>{/* <BookingStatusList /> */}</div>
      </div>
    );
  }
}
RoomConfirmation.propTypes = {
  getRequestRoomAllotment: PropTypes.func,
  loadingGetRequestRoomAllotment: PropTypes.bool,
  requestRoomAllotment: PropTypes.object,
  match: PropTypes.object,
};
function mapStateToProps(state) {
  return {
    loadingGetRequestRoomAllotment:
      state.AccomodationStore.loadingGetRequestRoomAllotment,
    requestRoomAllotment: state.AccomodationStore.requestRoomAllotment,
  };
}
export default connect(
  mapStateToProps,
  {
    getRequestRoomAllotment,
  }
)(RoomConfirmation);
