import * as types from '../actions/actionTypes';

const initialState = {
  loading: false,
  packageTransactionHistoryList: [],
  errors: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PACKAGE_TRANSACTION_HISTORY:
      return { ...state };
    case types.GET_PACKAGE_TRANSACTION_HISTORY_PENDING:
      return { ...state, loading: true };
    case types.GET_PACKAGE_TRANSACTION_HISTORY_FULFILLED:
      return {
        ...state,
        loading: false,
        packageTransactionHistoryList: action.payload.data,
      };
    case types.GET_PACKAGE_TRANSACTION_HISTORY_REJECTED:
      return {
        ...state,
        errors: action.payload.response.data,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
