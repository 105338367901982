const options = (key, value, text) => {
  const list = {};
  list['key'] = key;
  list['value'] = value;
  list['text'] = text;

  return list;
};

export default options;
