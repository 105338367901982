import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Form } from 'semantic-ui-react';
import { StylesTextfieldWithError } from '../../../shared/components/common/StylesCommon';
import InputWithLabel from '../../../shared/components/common/input/InputWithLabel';

const GeneralInformationRestaurantItem = props => {
  return (
    <div>
      <Header as="h3" content="General Information" />
      <Grid columns={2} padded="vertically">
        <Grid.Column>
          <Form>
            <InputWithLabel
              label="Menu Name"
              inline={false}
              type="text"
              placeholder="Menu Name"
              name="Name"
              value={props.restaurantItem.Name}
              handleChange={props.handleChange}
              required
              fluid={true}
              validate={props.errors}
              disabled={props.disablePage}
            />
          </Form>
          <Form>
            <InputWithLabel
              label="Food Presentation"
              inline={false}
              type="select"
              placeholder="Food Presentation"
              name="MenuCategory"
              value={props.restaurantItem.MenuCategory}
              handleChange={props.handleChange}
              required
              fluid={true}
              validate={props.errors}
              options={props.getRestaurantCategory}
              disabled={props.disablePage}
            />
          </Form>
          <Form>
            <InputWithLabel
              label="Description"
              inline={false}
              type="textArea"
              name="Description"
              value={props.restaurantItem.Description}
              handleChange={props.handleChange}
              placeholder="Description"
              required
              fluid={true}
              validate={props.errors}
              inputStyles={StylesTextfieldWithError.widthRestaurantItem}
              disabled={props.disablePage}
            />
          </Form>
        </Grid.Column>
        <Grid.Column>
          <Form>
            <InputWithLabel
              label="Menu Type"
              inline={false}
              type="select"
              name="MenuType"
              value={props.restaurantItem.MenuType}
              handleChange={props.handleChange}
              placeholder="Menu Category"
              required
              fluid={true}
              validate={props.errors}
              options={props.menuType}
              disabled={props.disablePage}
            />
          </Form>
          <Form>
            <InputWithLabel
              label="Menu Class"
              inline={false}
              type="select"
              name="MenuClass"
              value={props.restaurantItem.MenuClass}
              handleChange={props.handleChange}
              placeholder="Menu Class"
              required
              fluid={true}
              validate={props.errors}
              options={props.menuClasses}
              disabled={props.disablePage}
            />
          </Form>
        </Grid.Column>
      </Grid>
    </div>
  );
};
GeneralInformationRestaurantItem.propTypes = {
  itemTypeData: PropTypes.array,
  handleChange: PropTypes.func,
  handleChangeItem: PropTypes.func,
  getRestaurantClass: PropTypes.array,
  getRestaurantType: PropTypes.array,
  getRestaurantCategory: PropTypes.array,
  restaurantItem: PropTypes.shape({
    Description: PropTypes.string,
    Name: PropTypes.string,
    MenuCategory: PropTypes.string,
    MenuType: PropTypes.string,
    MenuClass: PropTypes.string,
    MinimumPax: PropTypes.number,
    ServiceItemId: PropTypes.number,
  }),
  errors: PropTypes.object,
  menuType: PropTypes.array,
  menuClasses: PropTypes.array,
  disablePage: PropTypes.bool,
};
export default GeneralInformationRestaurantItem;
