import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TablePaymentPackage from './TablePaymentPackage';
import { HeaderTablePaymentPackage } from '../../../../components/table/headerTabel';
import { StylesTextfieldWithError } from '../../../../components/common/styleCommon';
import {
  Grid,
  Button,
  Checkbox,
  Input,
  Form,
  Container,
} from 'semantic-ui-react';
import moment from 'moment';
import { addDays } from '../../../../components/genericFunction/itenararyBuilder';
import ModalConfirm from '../../../../components/modal/modalConfirmationTwoButton';

class TabPaymentPackage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openConfrim: false,
      messageConfirm: '',
    };
  }
  handleChangePaymentTermsDate = (value, name) => {
    const { Name, Index } = name;
    // let { paymentPackage, errors, destinationDate } = this.state;
    let { payment, setPaymentTerm } = this.props;
    // if (
    //   new Date(moment().format('YYYY-MM-DD')) >
    //   new Date(moment(value).format('YYYY-MM-DD'))
    // ) {
    //   errors.packagePaymentErrors[Index].DueDate = 'Expired Date';
    // } else if (
    //   new Date(moment(destinationDate.GraceDate).format('YYYY-MM-DD')) <
    //   new Date(moment(value).format('YYYY-MM-DD'))
    // ) {
    //   errors.packagePaymentErrors[Index].DueDate = 'Invalid Date';
    // } else if (Index > 0) {
    //   let date = paymentPackage[Index - 1].DueDate;
    //   if (
    //     moment(date).format('YYYY-MM-DD') > moment(value).format('YYYY-MM-DD')
    //   ) {
    //     errors.packagePaymentErrors[Index].DueDate = 'Invalid Date';
    //   } else {
    //     errors.packagePaymentErrors[Index].DueDate = '';
    //   }
    // } else {
    //   errors.packagePaymentErrors[Index].DueDate = '';
    // }
    payment[Index] = {
      ...payment[Index],
      [Name]: moment(value).format('YYYY-MM-DD'),
    };
    setPaymentTerm(payment);
    // this.setStartTime({ paymentPackage: paymentPackage, errors: errors });
  };

  handleChangePaymentTerms = (e, { name, value }) => {
    const { Name, Index } = name;
    let { payment, setPaymentTerm } = this.props;
    if (Name === 'PaymentPercentage') {
      if (Number(value) < 0) {
        value = 0;
      } else if (Number(value) > 100) {
        value = 100;
      }
    }
    payment[Index] = {
      ...payment[Index],
      [Name]: value,
    };
    setPaymentTerm(payment);
  };

  handleChangeIsLockGuest = (e, { name, checked }) => {
    const { Name, Index } = name;
    let { payment, setPaymentTerm } = this.props;
    payment[Index] = {
      ...payment[Index],
      [Name]: checked,
    };
    setPaymentTerm(payment);
  };
  handleClickDeletePayment = () => {
    const { paymentId, indexPayment } = this.state;
    let { payment, setPaymentTerm } = this.props;
    if (paymentId === 0) {
      payment.splice(indexPayment, 1);
    } else {
      payment.splice(indexPayment, 1);
    }
    this.setState({ openConfrim: false });
    setPaymentTerm(payment);
  };
  addPaymentTerms = () => {
    let { payment, setPaymentTerm } = this.props;
    let paymentObject = {
      PaymentPercentage: 0,
      DueDate: addDays(new Date(), 1, '+'),
      Description: '',
      IsLockGuest: false,
      Sequence: 0,
      IntervalDays: 0,
      IsAfterBookingBased: false,
      Id: 0,
    };
    payment.push(paymentObject);
    setPaymentTerm(payment);
  };
  handleClose = () => {
    this.setState({ openConfrim: false });
  };
  confirmDeletePayment = (e, { name, value, id }) => {
    const { Index } = name;
    this.setState({
      paymentId: id,
      openConfrim: true,
      messageConfirm: 'Are you sure to delete ' + value + '?',
      paymentName: name,
      indexPayment: Index,
    });
  };
  onChangeCheckBox = (e, { checked }) => {
    let { payment, setPaymentTerm } = this.props;
    payment[0].IsAfterBookingBased = checked;
    if (!checked) {
      payment[0].IntervalDays = 0;
    }
    setPaymentTerm(payment);
  };
  render() {
    const props = this.props;
    const { openConfrim, messageConfirm } = this.state;
    const deadline =
      props.payment.length > 0 ? props.payment[0].IntervalDays : 0;
    const disable =
      props.payment.length > 0
        ? props.payment[0].IsAfterBookingBased
          ? false
          : true
        : true;
    const defaultCheck =
      props.payment.length > 0 ? props.payment[0].IsAfterBookingBased : false;
    return (
      <Grid columns="equal">
        <Grid.Row columns="2" style={{ margin: '0px 0px 0px 0px' }}>
          <Grid.Column textAlign="left" width="8">
            <Grid verticalAlign="middle">
              <Grid.Row>
                <Grid.Column width={9}>
                  <Checkbox
                    disabled={
                      props.isOperation
                        ? true
                        : props.payment.length > 0
                        ? false
                        : true || props.isPublished
                    }
                    name={{ Name: 'IsAfterBookingBased', Index: 0 }}
                    label="Deadline Down Payment since booking"
                    onChange={this.onChangeCheckBox}
                    checked={defaultCheck}
                  />
                </Grid.Column>
                <Grid.Column width={5}>
                  <Input
                    onChange={this.handleChangePaymentTerms}
                    disabled={disable || props.isOperation}
                    name={{ Name: 'IntervalDays', Index: 0 }}
                    type="number"
                    value={deadline}
                    fluid
                    label={{
                      content: deadline > 1 ? 'Days' : 'Day',
                    }}
                    labelPosition="right"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column textAlign="right" width="8">
            <Form>
              <Form.Field>
                <Button
                  positive
                  icon="plus"
                  content="Add New"
                  onClick={this.addPaymentTerms}
                  disabled={props.isPublished || props.isOperation}
                />
              </Form.Field>
            </Form>
          </Grid.Column>
          <Form>
            <Container
              // content={props.errors.paymentErrors}
              style={StylesTextfieldWithError.Red}
            />
          </Form>
          <TablePaymentPackage
            listData={props.payment}
            headerTable={HeaderTablePaymentPackage}
            handleChangePaymentTerms={this.handleChangePaymentTerms}
            handleChangePaymentTermsDate={this.handleChangePaymentTermsDate}
            handleChangeIsLockGuest={this.handleChangeIsLockGuest}
            handleClickDeletePayment={this.confirmDeletePayment}
            isPublished={props.isPublished}
            tourInformation={props.tourInformation}
            isOperation={props.isOperation}
            // errors={props.errors.packagePaymentErrors}
            // status={props.status}
          />
        </Grid.Row>
        <ModalConfirm
          openModal={openConfrim}
          size="mini"
          close={this.handleClose}
          confirm={this.handleClickDeletePayment}
          message={messageConfirm}
        />
      </Grid>
    );
  }
}

TabPaymentPackage.propTypes = {
  setPaymentTerm: PropTypes.func,
  payment: PropTypes.array,
  isPublished: PropTypes.bool,
  tourInformation: PropTypes.object,
  isOperation: PropTypes.bool,
};
export default TabPaymentPackage;
