import React, { Component } from 'react';
import { Table, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import IndexPagination from '../../genericFunction/indexPagination';
import Pagination from '../../common/pagination';
import {
  datasetPagination,
  getNumberFromTo,
} from './../../../scripts/pagination';
import Loader from './../../loader/loader';

class tableAddittionalInfo extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
    };
  }
  //   handleChange = (e, { name, value }) =>
  //     this.setState({ [name]: value, activePage: 1 });

  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  handleDeleteAdditionalItem = async (e, { id, value }) => {
    await this.setState({ ...this.state, activePage: 1 });
    this.props.handleDeleteAdditionalItem(id, value);
  };
  additionalInfoDisplay = additionalInfo => {
    const addInfo =
      additionalInfo.indexOf('FOR_VOUCHER') !== -1
        ? additionalInfo.replace('FOR_VOUCHER', '')
        : additionalInfo;
    return addInfo;
  };
  render() {
    const { activePage } = this.state;
    let {
      additionalInfoList,
      openModalAdditonalItem,
      loading,
      disabled,
    } = this.props;
    const dataPerPage = 3;
    let pagedData = datasetPagination(additionalInfoList, dataPerPage);
    let listData = pagedData[activePage - 1];

    let { numbersFrom, numbersTo } = getNumberFromTo(listData, activePage, 3);
    return (
      <div>
        <Table basic="very" selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign="center">Sub Title</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {listData ? (
              listData.map((item, idx) => {
                const idxPagination = IndexPagination(
                  3,
                  this.state.activePage,
                  idx
                );
                return (
                  <Table.Row key={idxPagination}>
                    <Table.Cell textAlign="center">
                      {/* {item.SubTitle} */}
                      {this.additionalInfoDisplay(item.SubTitle)}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Button
                        content="Edit"
                        name={{ Index: idxPagination, Type: 'Edit' }}
                        positive
                        value={item}
                        onClick={openModalAdditonalItem}
                        disabled={disabled}
                      />
                      <Button
                        content="Delete"
                        id={idxPagination}
                        name={{ Index: idxPagination, Type: 'Delete' }}
                        negative
                        onClick={this.handleDeleteAdditionalItem}
                        value={item.Id}
                        disabled={disabled}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })
            ) : (
              <Table.Row>
                <Table.Cell colSpan="2" textAlign="center">
                  {loading ? (
                    <Loader active={loading} inline={true} />
                  ) : (
                    'No Data'
                  )}
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        <Pagination
          pagedData={pagedData}
          numbersTo={numbersTo}
          numbersFrom={numbersFrom}
          data={additionalInfoList}
          pageChange={this.pageChange}
          activePage={activePage}
        />
      </div>
    );
  }
}

tableAddittionalInfo.propTypes = {
  additionalInfoList: PropTypes.array,
  openModalAdditonalItem: PropTypes.func,
  handleDeleteAdditionalItem: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};
export default tableAddittionalInfo;
