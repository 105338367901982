import {
  GETUSERINFO,
  RESET_STATUS_USER,
  GET_USER_ROLES,
  POST_USER,
  POST_USER_PROFILE,
  GET_USER_ROLE_LIST,
  GET_USER_NOT_EMPLOYEE,
  PUT_USER_PROFILE,
  GET_USER_ROLE_BY_USER_ID,
  POST_ADD_NEW_ROLE_BY_USER_ID,
  DELETE_ROLE_BY_USER_ID,
  GET_USER_PROFILE,
  PUT_USER_PROFILE_BY_BEARER,
  POST_CHANGE_PASSWORD,
} from './actionTypes';
import getUserInfoApi from '../api/getUserInfoApi';
import getUserRolesApi from '../api/getUserRolesApi';
import postUserApi from '../api/userProfile/postUserApi';
import postUserProfileApi from '../api/userProfile/postUserProfileApi';
import getUserRoleListApi from '../api/userProfile/getUserRoleListApi';
import getUserNotEmployeeApi from '../api/userProfile/getUserNotEmployeeApi';
import putUserProfileApi from '../api/userProfile/putUserProfileApi';
import getUserRoleByUserIdApi from '../api/userProfile/getUserRoleByUserIdApi';
import putRoleByUserIdApi from '../api/userProfile/putRoleByUserIdApi';
import deleteRoleByUserIdApi from '../api/userProfile/deleteRoleByUserIdApi';
import getUserProfileApi from '../api/userProfile/getUserProfileApi';
import putUserProfileByBearerApi from '../api/userProfile/putUserProfileByBearerApi';
import postChangePasswordApi from '../api/userProfile/postChangePasswordApi';

const getUserInfoAction = () => {
  return dispatch => {
    return dispatch({
      type: GETUSERINFO,
      payload: getUserInfoApi(),
    });
  };
};

const resetUserInfoAction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_STATUS_USER,
    });
  };
};
const getUserRolesAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_USER_ROLES,
      payload: getUserRolesApi(),
    });
  };
};

const postUserAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_USER,
      payload: postUserApi(data),
    });
  };
};

const postUserProfileAction = data => {
  return dispatch => {
    return dispatch({
      type: POST_USER_PROFILE,
      payload: postUserProfileApi(data),
    });
  };
};

const getUserRoleListAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_USER_ROLE_LIST,
      payload: getUserRoleListApi(),
    });
  };
};

const getUserNotEmployeeAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_USER_NOT_EMPLOYEE,
      payload: getUserNotEmployeeApi(),
    });
  };
};

const putUserProfileAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_USER_PROFILE,
      payload: putUserProfileApi(id, data),
    });
  };
};

const getUserRoleByUserIdAction = id => {
  return dispatch => {
    return dispatch({
      type: GET_USER_ROLE_BY_USER_ID,
      payload: getUserRoleByUserIdApi(id),
    });
  };
};

const putRoleByUserIdAction = (userId, role) => {
  return dispatch => {
    return dispatch({
      type: POST_ADD_NEW_ROLE_BY_USER_ID,
      payload: putRoleByUserIdApi(userId, role),
    });
  };
};

const deleteRoleByUserIdAction = userId => {
  return dispatch => {
    return dispatch({
      type: DELETE_ROLE_BY_USER_ID,
      payload: deleteRoleByUserIdApi(userId),
    });
  };
};

const getUserProfileAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_USER_PROFILE,
      payload: getUserProfileApi(),
    });
  };
};

const putUpdateProfileAction = (id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_USER_PROFILE_BY_BEARER,
      payload: putUserProfileByBearerApi(id, data),
    });
  };
};

const postChangePassword = data => {
  return dispatch => {
    return dispatch({
      type: POST_CHANGE_PASSWORD,
      payload: postChangePasswordApi(data),
    });
  };
};

export {
  getUserInfoAction,
  resetUserInfoAction,
  getUserRolesAction,
  postUserAction,
  postUserProfileAction,
  getUserRoleListAction,
  getUserNotEmployeeAction,
  putUserProfileAction,
  getUserRoleByUserIdAction,
  deleteRoleByUserIdAction,
  putRoleByUserIdAction,
  getUserProfileAction,
  putUpdateProfileAction,
  postChangePassword,
};
