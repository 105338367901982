import React, { Component } from 'react';
import { Button, Modal, Message, Header } from 'semantic-ui-react';
// import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import InputAmount from '../../input/inputAmount';
import styles from './Styles';
import { generateGuest } from '../../../../../../scripts/itineraryBuilder/guest';
import { setRoomAllocationSummaryProg } from '../../../../../../scripts/itineraryBuilder/dailyProgram';
import {
  validationTourPax,
  isErrorFormPackage,
  validationAllocationPax,
  isRoomAllocationValid,
  validationMinPax,
} from '../../../../../../scripts/validation';
import MessageError from '../../../../../../components/message/messageErrors';
import FormGuest from '../form/FormGuestQty';
import FormRoomAllocationCustom from '../form/FormRoomAllocationCustom';
import FormRoomAllocationFixed from '../form/FormRoomAllocationFixed';
import FormFreeOfCharge from '../form/FormFreeOfCharge';
import {
  setAdditionalItemAmount,
  setAdditionalPrice,
} from '../../../../../../scripts/additionalItem';

class ModalPaxArrangement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      guests: { adults: 0, childs: 0, infants: 0 },
      foc: {
        Qty: 0,
        Description: 'Foc',
      },
      roomAllocation: {
        ChildExtraBedQty: 0,
        ChildSharingRoomQty: 0,
        ChildSingleRoomQty: 0,
        ExtraBedQty: 0,
        BabyCrib: 0,
        NoBed: 0,
        SharingBedQty: 0,
        SharingRoomQty: 0,
        SingleRoomQty: 0,
      },
      guestList: [],
      loading: false,
      errors: {
        tourPax: { error: false, message: [] },
        roomAllocation: { error: false, message: [] },
        minimumPax: { error: false, message: [] },
      },
    };
  }
  sumValues = obj => {
    return Object.values(obj).reduce((sum, current) => sum + current);
  };
  handleChangeGuestAmount = (e, { name, value, objectname }) => {
    let obj = { ...this.state[objectname] };
    if (value === '') {
      obj[name] = 0;
    } else {
      obj[name] = parseInt(value.replace(/\D/g, ''), 10);
    }
    this.setState({ ...this.state, [objectname]: obj });
  };
  handleIncrease = (e, { name, value, objectname }) => {
    let obj = { ...this.state[objectname] };
    let { addFoc } = this.props;
    let maxFocValue = this.state.guests.adults;
    let newValue = 0;
    objectname === 'foc'
      ? maxFocValue !== 0
        ? (newValue = value + 1 < maxFocValue ? value + 1 : maxFocValue - 1)
        : (newValue = 0)
      : (newValue = 0);
    obj[name] = value + 1;
    // objectname === 'foc' ? (obj[name] = newValue) : (obj[name] = value + 1);
    objectname === 'foc' && addFoc(name, newValue);
    this.setState({
      ...this.state,
      [objectname]: obj,
    });
  };
  handleDecrease = (e, { name, value, objectname }) => {
    let obj = { ...this.state[objectname] };
    let { addFoc } = this.props;
    let objFoc = { ...this.state.foc };
    let focValue = this.state.foc.Qty;
    let newValue = value - 1 < 0 ? 0 : value - 1;
    focValue =
      name === 'adults'
        ? newValue === 0
          ? focValue
          : newValue <= focValue
          ? newValue - 1
          : focValue
        : focValue;
    obj[name] = newValue;
    objectname === 'foc' && addFoc(name, newValue);
    objFoc.Qty = focValue;
    this.setState({ ...this.state, foc: objFoc, [objectname]: obj });
  };

  generateGuest = () => {
    let newGuest = generateGuest(this.state.guestList, this.state.guests);
    this.setState({
      ...this.state,
      guestList: newGuest,
    });
    return newGuest;
  };

  doPaxArrangement = async () => {
    // await this.props.addFoc(this.state.foc);
    this.setState({ ...this.state, loading: true }, () => {
      setTimeout(this.setState({ ...this.state, loading: false }), 3000);
    });
    let isFixedPackage = this.props.currentPackage.type === 'Fixed';
    let headlineProgram = !isFixedPackage
      ? await setRoomAllocationSummaryProg(
          this.props.headlineProgram,
          this.state.roomAllocation
        )
      : this.props.headlineProgram;
    let supplements = isFixedPackage
      ? await setAdditionalPrice(
          setAdditionalItemAmount(this.props.supplements, this.state.guests),
          this.props.paymentTerms,
          this.props.nowPayment.PaymentPercentage
        )
      : [];
    await this.props.handleGuestAndRoom(
      this.state.guests,
      this.state.roomAllocation,
      generateGuest(this.state.guestList, this.state.guests),
      headlineProgram,
      supplements
    );
    this.props.handleModal();
  };

  setPaxArrangement = () => {
    // -------- validation -----------
    const { guests, roomAllocation, errors } = this.state;
    const { currentPackage, minPaxReducer } = this.props;
    const { type } = currentPackage;
    let newErrors = validationTourPax(guests, errors);
    newErrors =
      type === 'Fixed'
        ? isRoomAllocationValid(newErrors, guests, roomAllocation)
        : validationAllocationPax(guests, roomAllocation, newErrors);
    newErrors = validationMinPax(guests, newErrors, minPaxReducer);
    // -------- end validation -----------
    if (isErrorFormPackage(newErrors)) {
      this.setState({ ...this.state, errors: newErrors });
    } else {
      this.setState({ ...this.state, loading: true }, () =>
        this.doPaxArrangement()
      );
    }
  };

  handleOpen = () => {
    this.props.handleModal();
    this.setState({
      ...this.state,
      guestList: this.props.guestList,
      guests: this.props.guests,
      roomAllocation: this.props.roomAllocation,
    });
  };
  render() {
    const {
      open,
      currentPackage,
      priceRoom,
      groupCapacity,
      foc,
      isReadyFixPrice,
    } = this.props;
    let isFixedPackage = currentPackage.type === 'Fixed';
    const { guests, roomAllocation, loading, errors } = this.state;
    const totalGuest = this.sumValues(guests);
    const totalAllocation = this.sumValues(roomAllocation);
    const totalAllocAdult =
      !isFixedPackage &&
      guests.adults -
        (roomAllocation.SingleRoomQty + roomAllocation.SharingRoomQty);
    const totalAllocChild =
      !isFixedPackage &&
      guests.childs -
        (roomAllocation.ChildSingleRoomQty +
          roomAllocation.ChildSharingRoomQty +
          roomAllocation.ChildExtraBedQty +
          roomAllocation.SharingBedQty);
    const totalAllocInfant =
      !isFixedPackage &&
      guests.infants - (roomAllocation.NoBed + roomAllocation.BabyCrib);
    return (
      <div>
        <Modal
          trigger={
            <div style={styles.textCenter}>
              <Button style={styles.backgroundGold}>
                Set Guest and Room Allocation
              </Button>
            </div>
          }
          size="tiny"
          open={open}
          onClose={this.props.handleModal}
          onOpen={this.handleOpen}
        >
          <Modal.Header>Pax Arrangement</Modal.Header>
          <Modal.Content>
            <div style={{ marginLeft: '30px', marginRight: '30px' }}>
              <Header as="h3">Pax Amount</Header>
              <FormGuest
                guests={guests}
                currentPackage={currentPackage}
                handleIncrease={this.handleIncrease}
                handleDecrease={this.handleDecrease}
                handleChange={this.handleChangeGuestAmount}
              />
              {isFixedPackage || (
                <FormFreeOfCharge
                  foc={foc}
                  capacity={groupCapacity}
                  handleIncrease={this.handleIncrease}
                  handleDecrease={this.handleDecrease}
                  handleChange={this.handleChangeGuestAmount}
                />
              )}

              <Header as="h3">Room Allocation</Header>
              {!isFixedPackage && !isReadyFixPrice ? (
                <FormRoomAllocationCustom
                  roomAllocation={roomAllocation}
                  handleIncrease={this.handleIncrease}
                  handleDecrease={this.handleDecrease}
                  handleChange={this.handleChangeGuestAmount}
                  prices={priceRoom}
                />
              ) : (
                <FormRoomAllocationFixed
                  roomAllocation={roomAllocation}
                  prices={priceRoom}
                  statusTransaction={currentPackage.status}
                  handleIncrease={this.handleIncrease}
                  handleDecrease={this.handleDecrease}
                  handleChange={this.handleChangeGuestAmount}
                />
              )}
              {!isFixedPackage && (
                <Message>
                  <strong>
                    You have allocated <span> {totalAllocation}</span> guests
                    out of <span>{totalGuest}</span>
                  </strong>
                  <p>
                    <strong>{totalAllocAdult}</strong> adult(s),{' '}
                    <strong>{totalAllocChild}</strong> child(ren) and{' '}
                    <strong>{totalAllocInfant}</strong> infant(s) remaining
                  </p>
                </Message>
              )}
              <MessageError
                isErrors={
                  errors.tourPax.error
                    ? errors.tourPax.error
                    : errors.roomAllocation.error
                    ? errors.roomAllocation.error
                    : errors.minimumPax.error
                    ? errors.minimumPax.error
                    : false
                }
                listErrors={
                  errors.tourPax.error
                    ? errors.tourPax.message
                    : errors.roomAllocation.error
                    ? errors.roomAllocation.message
                    : errors.minimumPax.message
                    ? errors.minimumPax.message
                    : []
                }
              />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              loading={loading ? true : false}
              primary
              onClick={this.setPaxArrangement}
              content="Ok"
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
ModalPaxArrangement.propTypes = {
  guests: PropTypes.object,
  roomAllocation: PropTypes.object,
  open: PropTypes.bool,
  guestList: PropTypes.array,
  handleModal: PropTypes.func,
  HeadlineProgram: PropTypes.object,
  currentPackage: PropTypes.string,
  priceRoom: PropTypes.object,
  headlineProgram: PropTypes.object,
  handleGuestAndRoom: PropTypes.func,
  paymentTerms: PropTypes.array,
  nowPayment: PropTypes.object,
  supplements: PropTypes.array,
  groupCapacity: PropTypes.object,
  foc: PropTypes.object,
  addFoc: PropTypes.func,
  isReadyFixPrice: PropTypes.bool,
  minPaxReducer: PropTypes.number,
};
export default ModalPaxArrangement;
