const headerTableImageAccomodationProfile = [
  'File Name',
  'Thumbnail',
  'Action',
];

const headerRestaurantPrice = [
  'Type',
  'Start Date',
  'End Of Date',
  'Price',
  'Action',
];

const headerRestaurantPriceDetails = ['Price', 'Minimum Pax', 'Action'];

export {
  headerTableImageAccomodationProfile,
  headerRestaurantPrice,
  headerRestaurantPriceDetails,
};
