const Style = {
  labelTourTransaction: {
    margin: '-1.3em 0em 0em 10em',
    position: 'absolute',
  },
  marginDescriptionTourOperator: {
    marginTop: '1em',
  },
  marginRequiredCustomer: {
    color: 'red',
    marginTop: '-2.8em',
    position: 'absolute',
    marginLeft: '4.9em',
  },
  styleButtonAddCustomer: {
    marginTop: '2.4em',
  },
};

export { Style };
