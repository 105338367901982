import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Grid, Header, Icon, Button, Label } from 'semantic-ui-react';
import ImageCard from './../card/imageCard';
// import HeaderRoomCard from './headerRoomCard';
import Styles from './styles';
// import stylesModal from './../../styles';

const headerRoomCard = props => {
  let {
    handleChange,
    openDesc,
    name,
    type,
    capacity,
    image,
    images,
    isPromo,
    isInstantConfirmation,
  } = props;
  return (
    <Segment.Group horizontal>
      <ImageCard images={images} image={image} isPromo={isPromo} size="300px" />
      {/* <Segment style={bgImage(image ? image : noImage)} /> */}
      <Segment style={{ border: 'none' }}>
        <Grid style={{ margin: '3px 3px 0px 3px' }}>
          <Header as="h3">{name}</Header>
          <Grid.Row style={Styles.noPaddingTop}>
            <Grid.Column width={2}>
              <Icon name="bed" size="large" style={Styles.goldColor} />
            </Grid.Column>
            <Grid.Column width={6}>
              <p>{type}</p>
            </Grid.Column>
            <Grid.Column width={2}>
              <Icon name="user" size="large" style={Styles.goldColor} />
            </Grid.Column>
            <Grid.Column width={6}>
              <p>{capacity} Guest / Room</p>
            </Grid.Column>
          </Grid.Row>
          {/* <Grid.Row style={Styles.noPaddingTop}>
            <Grid.Column width="2">
              <Icon name="user" size="large" style={Styles.goldColor} />
            </Grid.Column>
            <Grid.Column width="14">
              <p>{capacity} Guest / Room</p>
            </Grid.Column>
          </Grid.Row> */}
          <Grid.Row>
            <Grid.Column width={8}>
              {isInstantConfirmation ? (
                <Label basic style={Styles.labelAvailable}>
                  <Icon name="badge-check" />
                  Available
                </Label>
              ) : (
                <Label basic style={Styles.labelOnRequest}>
                  <Icon name="spinner" />
                  On Request
                </Label>
              )}
            </Grid.Column>
            <Grid.Column width={8} floated="right">
              <Button
                size="small"
                floated="right"
                primary
                onClick={handleChange}
              >
                {openDesc ? 'LESS DETAIL' : 'SEE DETAIL'}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Segment.Group>
  );
};
headerRoomCard.propTypes = {
  handleChange: PropTypes.func,
  openDesc: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.string,
  capacity: PropTypes.number,
  image: PropTypes.string,
  images: PropTypes.array,
  isPromo: PropTypes.bool,
  isInstantConfirmation: PropTypes.bool,
};
export default headerRoomCard;
