import apiClient from '../apiClient';
import {
  GET_USER_DATA_BY_CODE_URL,
  GET_USER_DATA_BY_CODE_FOR_SALES_URL,
} from '../apiUrl';

const getUserDataByCodeApi = Id => {
  return apiClient.get(GET_USER_DATA_BY_CODE_URL(Id));
};

const getUserDataByCodeForSalesApi = Id => {
  return apiClient.get(`${GET_USER_DATA_BY_CODE_FOR_SALES_URL}${Id}`);
};
export { getUserDataByCodeApi, getUserDataByCodeForSalesApi };
