import React from 'react';
import PropTypes from 'prop-types';
import { Input, Grid, Segment } from 'semantic-ui-react';
// import TableUserAccessList from '../table/tableUserAccessList';
import TableUserAccessList from './SegmentTableUserList';
import { HeaderTabelUserAccess } from '../constant/TableHeader';
import ButtonWithIcon from '../../../components/button/buttonWithIcon';
import HeaderTitle from '../../../components/common/headerTitle';
import Pagination from '../../../components/common/pagination';
const SegmentUserList = props => (
  <div>
    <Grid columns="equal">
      <Grid.Row stretched>
        <Grid.Column>
          <Segment style={{ marginTop: '30px' }}>
            <HeaderTitle iconName="user" title={props.title} />
            <div style={{ marginBottom: '8px' }}>
              <Input
                icon="search"
                placeholder="search by username"
                name="searchInput"
                value={props.searchInput}
                onChange={props.handleChange}
              />
              <ButtonWithIcon
                text="Add New"
                iconName="add"
                handleOnClick={props.buttonNew}
                positive={true}
                floated="right"
              />
            </div>
            <TableUserAccessList
              data={props.data}
              headerTable={HeaderTabelUserAccess}
              pageChange={props.pageChange}
              listData={props.listData}
              pagedData={props.pagedData}
              unlockUser={props.unlockUser}
              resetPassword={props.resetPassword}
              buttonEdit={props.buttonEdit}
            />
            <Pagination
              pagedData={props.pagedData}
              numbersTo={props.numbersTo}
              numbersFrom={props.numbersFrom}
              data={props.data}
              pageChange={props.pageChange}
              activePage={props.activePage}
            />
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
);

SegmentUserList.propTypes = {
  listData: PropTypes.array,
  pagedData: PropTypes.array,
  title: PropTypes.string,
  pageChange: PropTypes.func,
  activePage: PropTypes.number,
  searchInput: PropTypes.string,
  handleChange: PropTypes.func,
  data: PropTypes.array,
  unlockUser: PropTypes.func,
  resetPassword: PropTypes.func,
  buttonEdit: PropTypes.func,
  buttonNew: PropTypes.func,
  numbersTo: PropTypes.number,
  numbersFrom: PropTypes.number,
};
export default SegmentUserList;
