import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input, Pagination, Grid, Segment } from 'semantic-ui-react';
import TablePublishPackage from './table/TablePublishPackage';
import {
  datasetPagination,
  getNumberFromTo,
} from '../../../scripts/pagination';
import HeaderTitle from '../../shared/components/common/headerSegment/HeaderTitle';
import { getPublishedPackageAction } from '../homeAction';
class PublishPackage extends Component {
  constructor(props) {
    super(props);
    this.currentIndex = 0;
    this.state = {
      searchInput: '',
      activePage: 1,
      headerTable: [
        'Tour Name',
        'Total Booking',
        'Total Confirm',
        'Seat Available',
        'Country',
        'Start Date',
        'Duration Days',
      ],
      publishList: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.actionGetingData();
  }

  actionGetingData = () => {
    this.setState({ loading: true });
    this.props
      .getPublishedPackageAction()
      .then(res =>
        this.setState({ publishList: res.value.data, loading: false })
      )
      .catch(() => {
        this.setState({ loading: false });
      });
  };
  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  filteredData = data =>
    data.filter(data => {
      return (
        data.Title.toLowerCase().indexOf(
          this.state.searchInput.toLowerCase()
        ) !== -1
      );
    });

  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };

  render() {
    const { publishList, activePage, headerTable, loading } = this.state;
    let filterData = this.filteredData(publishList);
    let pagedData = datasetPagination(filterData, 5);
    let listData = pagedData[activePage - 1];
    let { numbersFrom, numbersTo } = getNumberFromTo(
      listData,
      this.state.activePage,
      5
    );
    return (
      <div>
        <Grid columns="equal" style={{ marginTop: '30px' }}>
          <Grid.Row stretched>
            <Grid.Column width={15}>
              <Segment>
                <HeaderTitle title="Publish Package" />
                <Input
                  icon="search"
                  placeholder="search"
                  name="searchInput"
                  value={this.state.searchInput}
                  onChange={this.handleChange}
                />
                <TablePublishPackage
                  data={publishList}
                  headerTable={headerTable}
                  pageChange={this.pageChange}
                  listData={listData}
                  pagedData={pagedData}
                  loading={loading}
                />
                {pagedData &&
                  pagedData.length > 1 && (
                    <Pagination
                      totalPages={pagedData.length}
                      onPageChange={this.pageChange}
                      activePage={activePage}
                      style={{ float: 'right' }}
                    />
                  )}
                {pagedData &&
                  pagedData.length > 1 && (
                    <p>
                      Showing {numbersTo} to {numbersFrom} of
                      {publishList.length}
                    </p>
                  )}
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

PublishPackage.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  getPublishedPackageAction: PropTypes.func,
};
function mapStateToProps() {}

export default connect(
  mapStateToProps,
  {
    getPublishedPackageAction,
  }
)(PublishPackage);
