import React from 'react';
import { Grid, Divider, Segment, Label, Icon, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import GeneralInformation from '../../../shared/components/profile/GeneralInfoProfile';
import ContactInformation from '../../../shared/components/profile/ContactInfoProfile';
//import AccomodationItemList from '../table/tableAccomodationItemList';
import LegalityCustomer from '../../../shared/components/profile/LegalityCustomer';
import PaymentTermCustomer from '../../../shared/components/profile/PaymentTermCustomer';
import CustomerListHistory from '../../../shared/components/tab/TabItem';
// import ButtonGroup from './buttonActionCustomer';
import LoadingPage from '../../../../components/modal/loaderModal';

const CustomerProfileDetail = props => (
  <Segment style={{ marginTop: '30px' }}>
    <Label
      as="h3"
      size="huge"
      style={{
        position: 'absolute',
        top: '-25px',
        background: '#38af95',
        color: 'white',
      }}
    >
      <Icon name="users" /> Customer Profile Detail
    </Label>
    <Grid style={{ marginTop: '30px' }}>
      <LoadingPage loading={props.loadingCustomerProfile} />
      <Grid.Row>
        <Grid.Column width={9} floated="right">
          <Button
            negative
            onClick={props.buttonBack}
            content="Back"
            floated="right"
          />
          {!props.isHideSave && (
            <Button
              positive
              onClick={props.buttonEdit}
              content={props.contentButton}
              floated="right"
            />
          )}
          {!props.isHideMargin && (
            <Button
              primary
              onClick={props.setMargin}
              content="Set Margin"
              disabled={props.isNewForm}
              floated="right"
            />
          )}
          {!props.isHideCommission && (
            <Button
              primary
              content="Set Commission"
              onClick={props.setCommission}
              disabled={props.isNewForm}
              floated="right"
            />
          )}
          {!props.isHideBalance && (
            <Button
              primary
              onClick={props.balanceDetail}
              content="Balance Detail"
              disabled={props.isNewForm}
              floated="right"
            />
          )}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <GeneralInformation
            contentDetail={props.contentDetail}
            numberEmploye={props.numberEmploye}
            branch={props.branch}
            revenueRange={props.revenueRange}
            currencies={props.currencies}
            handleChange={props.handleChange}
            disable={props.disable}
            errorInformation={props.errorInformation}
            typeCustomer={props.typeCustomer}
            accountManagerList={props.accountManagerList}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <LegalityCustomer
            contentDetail={props.contentDetail}
            handleChange={props.handleChange}
            disable={props.disable}
            errorInformation={props.errorInformation}
            handleCheckClick={props.handleCheckClick}
            listCompanyBussiness={props.listCompanyBussiness}
            //listCustomerLevel={props.listCustomerLevel}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <ContactInformation
            contentDetail={props.contentDetail}
            handleChange={props.handleChange}
            disable={props.disable}
            listCountry={props.listCountry}
            errorInformation={props.errorInformation}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <PaymentTermCustomer
            contentDetail={props.contentDetail}
            contentCompanyLogo={props.contentDetail.CompanyLogo}
            handleChange={props.handleChange}
            disable={props.disable}
            getPaymentSchemeList={props.getPaymentSchemeList}
            errors={props.errorInformation}
            modalImage={props.modalImage}
            isFinance={props.isFinance}
            accountManagerList={props.accountManagerList}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <Divider />
    <CustomerListHistory
      historyTransaction={props.historyTransaction}
      userData={props.userData}
      unlockUser={props.unlockUser}
      tabMenu={props.tabMenu}
    />
  </Segment>
);

CustomerProfileDetail.propTypes = {
  listCompanyBussiness: PropTypes.array,
  //listCustomerLevel: PropTypes.array,
  historyTransaction: PropTypes.array,
  userData: PropTypes.array,
  balanceDetail: PropTypes.func,
  buttonBack: PropTypes.func,
  setMargin: PropTypes.func,
  saveCustomer: PropTypes.func,
  contentDetail: PropTypes.shape({
    Name: PropTypes.string,
    Address: PropTypes.string,
    Telephone: PropTypes.string,
    Email: PropTypes.string,
    FaxNbr: PropTypes.string,
    IATANbr: PropTypes.string,
    TradeLicenseNbr: PropTypes.string,
    AssociationNbr: PropTypes.string,
    EmployeeRangeId: PropTypes.string,
    BranchRangeId: PropTypes.string,
    RevenueRangeId: PropTypes.string,
    CountryId: PropTypes.string,
    /*  CompanyLogo: PropTypes.shape({
      ImageContent: PropTypes.string,
      ImageName: PropTypes.string,
    }), */
    CompanyLogo: PropTypes.object,
    IsAllowedOwnCountryTour: PropTypes.string,
    ImageName: PropTypes.string,
    ImageContent: PropTypes.string,
    PaymentSchemeId: PropTypes.string,
    CompanyId: PropTypes.string,
    AccountManagerId: PropTypes.string,
  }),
  errorInformation: PropTypes.shape({
    NameError: PropTypes.string,
    AddressError: PropTypes.string,
    TelephoneError: PropTypes.string,
    CountryIdError: PropTypes.string,
    EmployeeRangeIdError: PropTypes.string,
    RevenueRangeIdError: PropTypes.string,
    BranchRangeIdError: PropTypes.string,
    CurrencyIdError: PropTypes.string,
    PaymentSchemeIdError: PropTypes.string,
  }),
  numberEmploye: PropTypes.array,
  revenueRange: PropTypes.array,
  branch: PropTypes.array,
  currencies: PropTypes.array,
  handleChange: PropTypes.func,
  disable: PropTypes.bool,
  buttonEdit: PropTypes.bool,
  contentButton: PropTypes.string,
  listCountry: PropTypes.array,
  getPaymentSchemeList: PropTypes.array,
  handleCheckClick: PropTypes.func,
  modalImage: PropTypes.func,
  unlockUser: PropTypes.func,
  tabMenu: PropTypes.array,
  loadingCustomerProfile: PropTypes.bool,
  isNewForm: PropTypes.bool,
  setCommission: PropTypes.func,
  typeCustomer: PropTypes.string,
  accountManagerList: PropTypes.array,
  isHideSave: PropTypes.bool,
  isHideBalance: PropTypes.bool,
  isHideMargin: PropTypes.bool,
  isHideCommission: PropTypes.bool,
  isFinance: PropTypes.bool,
};

export default CustomerProfileDetail;
