import React from 'react';
import PropTypes from 'prop-types';
import { Header, Accordion, Icon } from 'semantic-ui-react';
import styles from './styles';

const accordionTitle = props => (
  <Accordion.Title
    active={props.activeIndex === props.index}
    index={props.index}
    onClick={props.handleClick}
    style={
      props.level === 2 ? styles.accordionTitleLevel2 : styles.accordionTitle
    }
  >
    <Icon name="dropdown" style={props.dropdownStyle} />
    <Header as="h5" style={styles.menu}>
      {props.icon ? (
        // <Icon className={props.icon} style={styles.iconProfile} />
        <Icon className="round" style={props.icon} />
      ) : null}
      {props.title}
    </Header>
  </Accordion.Title>
);
accordionTitle.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  icon: PropTypes.string,
  dropdownStyle: PropTypes.object,
  level: PropTypes.number,
};
export default accordionTitle;
