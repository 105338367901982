import apiClient from '../apiClient';
import {
  GET_MASTER_REFERENCE_URL,
  GET_CANCELLATION_REASON_BY_TYPES_REFERENCE_URL,
} from '../apiUrl';

const getMasterDataReferenceApi = params => {
  return apiClient.get(GET_MASTER_REFERENCE_URL(params));
};

const getCancellationReasonByTypeReferenceApi = type => {
  return apiClient.get(GET_CANCELLATION_REASON_BY_TYPES_REFERENCE_URL(type));
};

export { getMasterDataReferenceApi, getCancellationReasonByTypeReferenceApi };
