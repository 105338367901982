export const RESET_NOT_ALLOWED_COUNTRY_FOR_COMPANY =
  'RESET_NOT_ALLOWED_COUNTRY_FOR_COMPANY';

export const GET_ALL_NOT_ALLOWED_COUNTRY_FOR_COMPANY =
  'GET_ALL_NOT_ALLOWED_COUNTRY_FOR_COMPANY';
export const GET_ALL_NOT_ALLOWED_COUNTRY_FOR_COMPANY_FULFILLED =
  'GET_ALL_NOT_ALLOWED_COUNTRY_FOR_COMPANY_FULFILLED';
export const GET_ALL_NOT_ALLOWED_COUNTRY_FOR_COMPANY_PENDING =
  'GET_ALL_NOT_ALLOWED_COUNTRY_FOR_COMPANY_PENDING';
export const GET_ALL_NOT_ALLOWED_COUNTRY_FOR_COMPANY_REJECTED =
  'GET_ALL_NOT_ALLOWED_COUNTRY_FOR_COMPANY_REJECTED';

export const POST_NOT_ALLOWED_COUNTRY_FOR_COMPANY =
  'POST_NOT_ALLOWED_COUNTRY_FOR_COMPANY';
export const POST_NOT_ALLOWED_COUNTRY_FOR_COMPANY_FULFILLED =
  'POST_NOT_ALLOWED_COUNTRY_FOR_COMPANY_FULFILLED';
export const POST_NOT_ALLOWED_COUNTRY_FOR_COMPANY_PENDING =
  'POST_NOT_ALLOWED_COUNTRY_FOR_COMPANY_PENDING';
export const POST_NOT_ALLOWED_COUNTRY_FOR_COMPANY_REJECTED =
  'POST_NOT_ALLOWED_COUNTRY_FOR_COMPANY_REJECTED';

export const PUT_NOT_ALLOWED_COUNTRY_FOR_COMPANY =
  'PUT_NOT_ALLOWED_COUNTRY_FOR_COMPANY';
export const PUT_NOT_ALLOWED_COUNTRY_FOR_COMPANY_FULFILLED =
  'PUT_NOT_ALLOWED_COUNTRY_FOR_COMPANY_FULFILLED';
export const PUT_NOT_ALLOWED_COUNTRY_FOR_COMPANY_PENDING =
  'PUT_NOT_ALLOWED_COUNTRY_FOR_COMPANY_PENDING';
export const PUT_NOT_ALLOWED_COUNTRY_FOR_COMPANY_REJECTED =
  'PUT_NOT_ALLOWED_COUNTRY_FOR_COMPANY_REJECTED';

export const GET_NOT_ALLOWED_COUNTRY_FOR_COMPANY_BY_COMPANY_CODE =
  'GET_NOT_ALLOWED_COUNTRY_FOR_COMPANY_BY_COMPANY_CODE';
export const GET_NOT_ALLOWED_COUNTRY_FOR_COMPANY_BY_COMPANY_CODE_FULFILLED =
  'GET_NOT_ALLOWED_COUNTRY_FOR_COMPANY_BY_COMPANY_CODE_FULFILLED';
export const GET_NOT_ALLOWED_COUNTRY_FOR_COMPANY_BY_COMPANY_CODE_PENDING =
  'GET_NOT_ALLOWED_COUNTRY_FOR_COMPANY_BY_COMPANY_CODE_PENDING';
export const GET_NOT_ALLOWED_COUNTRY_FOR_COMPANY_BY_COMPANY_CODE_REJECTED =
  'GET_NOT_ALLOWED_COUNTRY_FOR_COMPANY_BY_COMPANY_CODE_REJECTED';
