import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TablePeriodVariableDate from './TablePeriodDate';
import ModalDatePeriod from './ModalPeriodDate';
// import HeaderTablePeriod from '../../../constant/headerTable';
import { Button, Grid, Message, Icon } from 'semantic-ui-react';
import ModalConfirm from '../../../../../components/modal/modalConfirmationTwoButton';
import ModalAlert from '../../../../../components/modal/modalAlert';
import { convertDateFormat, getTotalDays } from '../../../../../scripts/moment';
import { periodValidation } from '../../services/validation/periodValidation';
import { getTime } from '../../../../../scripts/itineraryBuilder/timeHelper';
// import moment from 'moment';
class TabPeriodVariableDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openConfirm: false,
      messageConfirm: '',
      openModalDate: false,
      // listPeriod: [],
      activePage: 1,
      openAlert: false,
      headerAlert: '',
      contentAlert: '',
      headerTablePeriod: ['Start Date', 'End Date', 'Available Day', 'Action'],
      // objectPeriod: null,
      // onEdit: false,
      // indexEdit: null,
    };
  }

  confirmDelete = (e, { value }) => {
    this.setState({
      openConfirm: true,
      messageConfirm: 'Are you sure to delete this periode ',
      IndexWantToDelete: value,
    });
  };
  handleModalDate = () => {
    const { openModalDate } = this.state;
    this.setState({
      openModalDate: !openModalDate,
      objectPeriod: null,
      onEdit: false,
      indexEdit: null,
    });
  };
  handleCloseConfirm = () => {
    this.setState({ openConfirm: false });
  };
  handleDateRange = (id, allowedDays) => {
    const { from, to } = id;
    const listTemp = [...this.props.listPeriod];
    const { indexEdit } = this.state;
    let isAlready = false;
    const { headlineProgram } = this.props;
    let err = Object.values(allowedDays).some(element => element === true);
    if (getTime(from) < getTime(this.props.tourInformation.activeDate)) {
      this.setState({
        openAlert: true,
        contentAlert: `period is not less than the active date ${convertDateFormat(
          this.props.tourInformation.activeDate,
          'DD MMM YYYY'
        )}`,
        headerAlert: 'Information',
      });
    } else if (!err) {
      this.setState({
        openAlert: true,
        contentAlert: 'Choose at least one day',
        headerAlert: 'Information',
      });
    } else if (
      getTotalDays(from, to, true) >
      getTotalDays(
        headlineProgram.Departures[0].Date,
        headlineProgram.Returns[0].Date,
        true
      )
    ) {
      if (listTemp.length > 0) {
        listTemp.map((data, i) => {
          if (!isAlready) {
            isAlready =
              i !== indexEdit &&
              periodValidation(from, to, data.StartDate, data.EndDate);
            if (isAlready) {
              this.setState({
                openAlert: true,
                contentAlert: 'Period already exists ',
                headerAlert: 'Information',
              });
            }
          }
          return isAlready;
        });

        if (!err) {
          this.setState({
            openAlert: true,
            contentAlert: 'Choose at least one day',
            headerAlert: 'Information',
          });
        } else {
          !isAlready && this.setDateToList(from, to, allowedDays, listTemp);
        }
      } else {
        this.setDateToList(from, to, allowedDays, listTemp);
      }
    } else {
      this.setState({
        openAlert: true,
        contentAlert: 'Period must exceed the total days',
        headerAlert: 'Information',
      });
    }
  };

  setDateToList = (from, to, allowedDays, listTemp) => {
    const { onEdit, indexEdit } = this.state;
    const data = {
      StartDate: convertDateFormat(from, 'YYYY-MM-DD'),
      EndDate: convertDateFormat(to, 'YYYY-MM-DD'),
      AllowedDays: this.convertDay(Object.values(allowedDays)),
    };
    if (onEdit) {
      listTemp[indexEdit] = data;
    } else {
      listTemp.push(data);
    }
    this.props.setvariableDateList(listTemp);
    // this.handleModalDate();
    this.setState({
      openModalDate: false,
      onEdit: false,
      indexEdit: null,
    });
  };

  convertDay = data => {
    let objArr = data.map((data, i) => {
      return data && { Day: i, Text: this.handleDayConvert(i) };
    });
    return objArr.filter(isDayObject => isDayObject);
  };

  handleDelete = () => {
    const { IndexWantToDelete } = this.state;
    const listTemp = [...this.props.listPeriod];
    listTemp.splice(IndexWantToDelete, 1);
    this.props.setvariableDateList(listTemp);
    this.handleCloseConfirm();
    this.setState({ activePage: 1 });
  };
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  handleCloseAlert = () => {
    this.setState({
      openAlert: false,
      headerAlert: '',
      contentAlert: '',
    });
  };

  handleDayConvert = day => {
    switch (day) {
      case 0:
        return 'Sunday';
      case 1:
        return 'Monday';
      case 2:
        return 'Tuesday';
      case 3:
        return 'Wednesday';
      case 4:
        return 'Thursday';
      case 5:
        return 'Friday';
      case 6:
        return 'Saturday';
      default:
        break;
    }
  };
  handleEdit = (e, { value }) => {
    let objectPeriod = this.props.listPeriod[value];
    this.setState({
      openModalDate: true,
      objectPeriod,
      onEdit: true,
      indexEdit: value,
    });
  };

  checkingAllowedDays = allowedDay => {
    return Object.values(allowedDay).some(element => element === true);
  };
  render() {
    const {
      openConfirm,
      openModalDate,
      messageConfirm,
      activePage,
      openAlert,
      headerAlert,
      contentAlert,
      headerTablePeriod,
      objectPeriod,
      onEdit,
    } = this.state;
    const { listPeriod, currentPackage, isPublished, disabled } = this.props;
    return (
      <Grid divided="vertically">
        <Grid.Row columns={2}>
          <Grid.Column>
            <Message warning compact>
              <Icon circular name="info" size="small" />
              The period chosen is the date of stay
            </Message>
          </Grid.Column>
          <Grid.Column>
            <Button
              positive
              content="Add periode"
              icon="plus"
              onClick={this.handleModalDate}
              floated="right"
              disabled={
                !currentPackage.isReadyFixPrice || isPublished || disabled
              }
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={1}>
          <TablePeriodVariableDate
            data={listPeriod}
            headerTable={headerTablePeriod}
            handleDelete={this.confirmDelete}
            activePage={activePage}
            pageChange={this.pageChange}
            handleEdit={this.handleEdit}
            isPublished={isPublished}
            disabled={disabled}
          />
          <ModalDatePeriod
            openModal={openModalDate}
            handleClose={this.handleModalDate}
            actionDateRange={this.handleDateRange}
            contentHeader="Select Period"
            isReadyFixPrice={
              currentPackage.type === 'FixedDateVariable' ||
              currentPackage.isReadyFixPrice
            }
            objectPeriod={objectPeriod}
            onEdit={onEdit}
          />
          <ModalConfirm
            openModal={openConfirm}
            size="mini"
            close={this.handleCloseConfirm}
            confirm={this.handleDelete}
            message={messageConfirm}
          />
          <ModalAlert
            openModal={openAlert}
            size="mini"
            handleClose={this.handleCloseAlert}
            header={headerAlert}
            content={contentAlert}
          />
        </Grid.Row>
      </Grid>
    );
  }
}

TabPeriodVariableDate.propTypes = {
  setvariableDateList: PropTypes.func,
  listPeriod: PropTypes.array,
  currentPackage: PropTypes.object,
  availableDay: PropTypes.object,
  headlineProgram: PropTypes.object,
  isPublished: PropTypes.bool,
  tourInformation: PropTypes.object,
};
export default TabPeriodVariableDate;

// if (onEdit) {
//   if (
//     moment(convertDateFormat(from, 'YYYY-MM-DD')).isSame(
//       convertDateFormat(data.StartDate, 'YYYY-MM-DD')
//     ) &&
//     moment(convertDateFormat(to, 'YYYY-MM-DD')).isSame(
//       convertDateFormat(data.EndDate, 'YYYY-MM-DD')
//     )
//   ) {
//     this.setDateToList(from, to, allowedDays, listTemp);
//   }
// } else {
//   this.setState({
//     openAlert: true,
//     contentAlert: 'Period already exists ',
//     headerAlert: 'Information',
//   });
// }
// this.setState({
//   openAlert: true,
//   contentAlert: 'Period already exists ',
//   headerAlert: 'Information',
// });
